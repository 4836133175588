import { newPageInfo } from "@hex-insights/router";
import { reportCardsTermPageInfo, ReportCardsTermPageRouteParams } from "../pageinfo";

export type ReportCardsTermIndexPageRouteParams = ReportCardsTermPageRouteParams;

export const reportCardsTermIndexPageInfo = newPageInfo({
	name: "Report Cards Term Index Page",
	title: "",
	description: "Report cards term overview.",
	exact: true,
	to: reportCardsTermPageInfo.to,
	path: reportCardsTermPageInfo.path,
	parent: reportCardsTermPageInfo,
});
