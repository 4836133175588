import React from "react";
import {
	addTimeToDate,
	Button,
	ClassNameProps,
	Column,
	compareNumbersAsc,
	compareObjects,
	Conditional,
	dateTrunc,
	Else,
	englishCommaJoinStrings,
	formatDateTime,
	Heading,
	Icon,
	If,
	isAfter,
	isSameTime,
	makeClassName,
	Modal,
	ModalProps,
	Paragraph,
	Row,
	sort,
	stringToLocalDate,
	StyleProps,
	toGrammaticalNumber,
	toLocalDateString,
	useCount,
	useToggle,
} from "@hex-insights/core";
import { Table } from "@hex-insights/tables";
import { useCurrentUser } from "../../Contexts";
import {
	CalendarEventOrderField,
	Employee,
	getEventColor,
	isDOBOnDate,
	MealMenuIndexQuery,
	NoteFormat,
	NoteNoteType,
	NoteOrderField,
	NoteStudentNotesListQuery,
	OrderDirection,
	StudentFilterInput,
	useBirthdaysQuery,
	useCalendarEventIndexQuery,
	useDailyQuotationQuery,
	useMealMenuIndexQuery,
	useNoteStudentNotesListQuery,
} from "../../Utilities";
import { BigDateDisplay } from "../BigDateDisplay";
import { MealMenuForm } from "../Forms";
import { HorizontalScrollingContainer } from "../HorizontalScrollingContainer";
import { HR } from "../HR";
import { MealIcon } from "../IconAddon";
import { StudentLink } from "../Links";
import { NoteModal } from "../NoteModal";
import { PersonIcon } from "../PersonIcon";
import { getCalendarEventFiltersForUserForDateRange } from "../SmallEventCalendar";
import { Tile } from "../Tile";
import { AttendanceTile } from "./Attendance";
import styles from "./styles.module.css";

export * from "./Attendance";

export type DailyReportProps = {
	teacherID?: Employee["id"];
	noAttendance?: boolean;
} & Partial<ClassNameProps & StyleProps>;

export function DailyReport({ teacherID, noAttendance = false, className, style }: DailyReportProps) {
	const { isModalOpen, toggleIsModalOpen } = Modal.useToggle(false);

	const { data: birthdaysData } = useCurrentBirthdays(true);
	const numBirthdays = birthdaysData?.personConnectionByBirthday.edges.length ?? 0;

	const { data: eventsData } = useCurrentCalendarEvents();
	const numEvents = eventsData?.calendarEventConnection.edges.length ?? 0;

	const { data: notesData } = useCurrentNotes(true, teacherID);
	const numNotes = notesData?.noteConnection.edges.length ?? 0;

	return (
		<React.Fragment>
			<Row justify="spaced-start" className={className} style={style}>
				<div style={{ flexGrow: 1 }}>
					<Button onClick={toggleIsModalOpen} style={{ width: "100%", height: "100%", padding: 0, border: "none" }}>
						<Row justify="center" align="center" className={styles["main-block"]}>
							<span className={styles["your-daily-report"]}>Your Daily Report</span>
							<span className={styles["updated-at"]}>Updated: Just Now</span>
							<Column justify="spaced-start" className={styles["tiles-background"]}>
								<Row justify="spaced-start" style={{ flexGrow: 0.5 }}>
									<div className={styles["tiles-background__tile"]} style={{ flexGrow: 0.2 }}></div>
									<Column justify="spaced-start" style={{ flexGrow: 0.45 }}>
										<div className={styles["tiles-background__tile"]} style={{ flexGrow: 0.5 }}></div>
										<div className={styles["tiles-background__tile"]} style={{ flexGrow: 0.5 }}></div>
									</Column>
									<div className={styles["tiles-background__tile"]} style={{ flexGrow: 0.35 }}></div>
								</Row>
								<Row justify="spaced-start" style={{ flexGrow: 0.5 }}>
									<div className={styles["tiles-background__tile"]} style={{ flexGrow: 0.8 }}></div>
									<div className={styles["tiles-background__tile"]} style={{ flexGrow: 0.2 }}></div>
								</Row>
							</Column>
						</Row>
					</Button>
				</div>

				<Column>
					<Row justify="spaced-start" style={{ flexGrow: 1 }}>
						<StatButton
							quantity={numEvents}
							label="Event"
							backgroundColor="var(--verita-blue)"
							IconComponent={Icon.Calendar}
							onClick={toggleIsModalOpen}
							style={{ flexGrow: 0.33 }}
						/>
						<StatButton
							quantity={numBirthdays}
							label="Birthday"
							backgroundColor="#ed8e11"
							IconComponent={Icon.Gift}
							onClick={toggleIsModalOpen}
							style={{ flexGrow: 0.33 }}
						/>
						<StatButton
							quantity={numNotes}
							label="Note"
							backgroundColor="var(--verita-colors-yellow)"
							IconComponent={Icon.File}
							onClick={toggleIsModalOpen}
							style={{ flexGrow: 0.33 }}
						/>
					</Row>

					<div>
						<Button
							variant="tertiary"
							size="small"
							onClick={toggleIsModalOpen}
							className={styles["view-full-report-button"]}
						>
							View Full Report
						</Button>
					</div>
				</Column>
			</Row>

			<Modal.If condition={isModalOpen}>
				<DailyReportModal teacherID={teacherID} noAttendance={noAttendance} onClose={toggleIsModalOpen} />
			</Modal.If>
		</React.Fragment>
	);
}

type StatButtonProps = {
	quantity: number;
	label: string;
	backgroundColor: string;
	IconComponent: (props: { size: string } & Partial<ClassNameProps & StyleProps>) => React.ReactElement;
	onClick: () => void;
} & Partial<StyleProps>;

function StatButton({ quantity, label, backgroundColor, IconComponent, onClick, style }: StatButtonProps) {
	const hasItems = quantity > 0;

	return (
		<Button onClick={onClick} className={styles["stat-button"]} style={style}>
			<Column justify="spaced-start" verticalSpacing="0.5rem" align="center">
				<span
					className={styles["stat-button__icon-container"]}
					style={{
						opacity: hasItems ? 1 : 0.4,
						color: "#fff",
						backgroundColor: backgroundColor,
					}}
				>
					<IconComponent size="1.75rem" className={styles["stat-button__icon"]} />
				</span>
				<span className={styles["stat-button__text"]} style={{ opacity: hasItems ? 1 : 0.5 }}>
					{toGrammaticalNumber(label, quantity, true)}
				</span>
			</Column>
		</Button>
	);
}

type DailyReportModalProps = {
	teacherID?: Employee["id"];
	noAttendance?: boolean;
} & Pick<ModalProps, "ifRef" | "onClose">;

function DailyReportModal({ teacherID, noAttendance = false, ifRef, onClose }: DailyReportModalProps) {
	return (
		<Modal
			ifRef={ifRef}
			onClose={onClose}
			size="large"
			style={{
				backgroundColor: "transparent",
				width: "95vw",
				height: "90vh",
				borderRadius: 0,
				boxShadow: "none",
			}}
		>
			<Modal.Body style={{ height: "100%", padding: "0.25rem", borderRadius: 0 }}>
				<Column justify="spaced-start" style={{ height: "100%" }}>
					<Row justify="spaced-start">
						<Tile style={{ height: "100%", flexGrow: 1 }}>
							<Tile.Body style={{ height: "100%" }}>
								<Row justify="spaced-start" align="center" style={{ height: "100%" }}>
									<BigDateDisplay />

									<HR color="#ddd" style={{ height: "80%" }} />

									<CalendarEventsList style={{ flexGrow: 1 }} />
								</Row>
							</Tile.Body>
						</Tile>

						<BirthdaysTile style={{ maxWidth: "30%" }} />
					</Row>

					<Row justify="spaced-start" style={{ flexGrow: 1, minHeight: 0 }}>
						<NotesTile teacherID={teacherID} style={{ flexGrow: 1 }} />

						<If condition={!noAttendance}>
							<AttendanceTile style={{ flexShrink: 0, width: "15rem" }} />
						</If>
					</Row>

					<Row justify="spaced-start" style={{ height: "8.5rem" }}>
						<DailyQuotation style={{ flexGrow: 1 }} />

						<MealMenuTile style={{ width: "30%", flexShrink: 0 }} />
					</Row>
				</Column>
			</Modal.Body>
		</Modal>
	);
}

function BirthdaysTile({ style }: Partial<StyleProps>) {
	const { loading, data } = useCurrentBirthdays(false);
	const sortedEdges = sort(
		data?.personConnectionByBirthday.edges ?? [],
		compareObjects(
			[(e) => new Date(e.node.dateOfBirth!).getMonth(), (e) => new Date(e.node.dateOfBirth!).getDate()],
			compareNumbersAsc(),
		),
	);

	const yearMS = 1000 * 60 * 60 * 24 * 365.24;

	return (
		<Tile style={style}>
			<Conditional>
				<If condition={loading}>
					<Tile.Body>
						<Row justify="center" align="center" style={{ height: "100%" }}>
							Checking for birthdays...
						</Row>
					</Tile.Body>
				</If>
				<If condition={sortedEdges.length === 0}>
					<Tile.Body>
						<Row justify="center" align="center" style={{ height: "100%" }}>
							No birthdays today
						</Row>
					</Tile.Body>
				</If>
				<Else>
					<Tile.Header>
						<Heading level={3} noMargin style={{ textAlign: "center" }}>
							Happy Birthday!
						</Heading>
					</Tile.Header>
					<Tile.Body style={{ width: "100%", padding: "0.75rem 1rem" }}>
						<HorizontalScrollingContainer>
							<Row justify="spaced-start">
								{sortedEdges.map((e) => {
									const dob = stringToLocalDate(e.node.dateOfBirth!, "day");
									const today = new Date();
									const tomorrow = addTimeToDate(today, [1, "day"]);
									const nextDay = addTimeToDate(today, [2, "days"]);
									let dayLabel = "";
									if (isDOBOnDate(dob, today)) {
										dayLabel = "Today";
									} else if (isDOBOnDate(dob, tomorrow)) {
										dayLabel = "Tomorrow";
									} else if (isDOBOnDate(dob, nextDay)) {
										dayLabel = formatDateTime(nextDay, "dddd");
									}

									const sharedContent = (
										<Column key={e.node.id} justify="spaced-start" verticalSpacing="0.25rem" align="center">
											<PersonIcon person={e.node} withName imageSize="3rem" style={{ flexShrink: 0 }} />
											<span
												style={{
													color: dayLabel === "Today" ? "var(--verita-colors-orange)" : "#777",
													textAlign: "center",
												}}
											>
												{dayLabel}
											</span>
										</Column>
									);

									if (e.node.student) {
										const age = Math.round(
											(new Date().getTime() - new Date(e.node.dateOfBirth ?? 0).getTime()) / yearMS,
										);

										return (
											<StudentLink key={e.node.id} instance={e.node.student} className="link--no-text-decoration">
												<Column align="center" style={{ whiteSpace: "nowrap" }}>
													{sharedContent}
													<span
														style={{
															color: "#777",
															textAlign: "center",
														}}
													>
														{age} years old
													</span>
												</Column>
											</StudentLink>
										);
									}

									return sharedContent;
								})}
							</Row>
						</HorizontalScrollingContainer>
					</Tile.Body>
				</Else>
			</Conditional>
		</Tile>
	);
}

function CalendarEventsList({ style }: Partial<StyleProps>) {
	const { loading, data } = useCurrentCalendarEvents();

	if (loading) {
		return (
			<Column justify="center" style={style}>
				<span>Loading events...</span>
			</Column>
		);
	}

	if ((data?.calendarEventConnection.edges.length ?? 0) === 0) {
		return (
			<Column justify="center" style={style}>
				<span>No events today</span>
			</Column>
		);
	}

	return (
		<Column
			justify="spaced-start"
			verticalSpacing="0.25rem"
			style={{ ...style, maxHeight: "7rem", overflow: "scroll", paddingRight: "1rem" }}
		>
			{data?.calendarEventConnection.edges.map((e) => {
				const eventColor = getEventColor({ ...e.node, groups: e.node.sharingUserGroups });

				return (
					<div style={{ position: "relative" }}>
						<Row
							key={e.node.id}
							justify="spaced-start"
							align="center"
							style={{ paddingRight: "0.5rem", position: "relative", zIndex: 1 }}
						>
							<div
								style={{
									color: "#fff",
									backgroundColor: eventColor,
									padding: "0.25rem 0.5rem",
									borderRadius: "0.5rem",
								}}
							>
								{e.node.isAllDay ? "All Day" : formatDateTime(e.node.startTime, "HH:mm")}
							</div>
							<span>{e.node.name}</span>
						</Row>
						<div
							style={{
								backgroundColor: eventColor,
								opacity: 0.25,
								width: "100%",
								height: "100%",
								borderRadius: "0.5rem",
								position: "absolute",
								top: 0,
								left: 0,
								zIndex: 0,
							}}
						></div>
					</div>
				);
			})}
		</Column>
	);
}

const mealLabels = ["Breakfast", "Lunch", "Snack"];

type MealMenuTileProps = Partial<ClassNameProps & StyleProps>;

function MealMenuTile({ className, style }: MealMenuTileProps) {
	const { data } = useMealMenuIndexQuery({
		variables: {
			filters: {
				menuDateEQ: toLocalDateString(new Date()),
			},
		},
	});

	const { isModalOpen, toggleIsModalOpen } = Modal.useToggle(false);
	const { isOn: isVegetarian, toggle: toggleIsVegetarian } = useToggle(false);
	const {
		count: mealIndex,
		increment: incrementMealIndex,
		decrement: decrementMealIndex,
	} = useCount(getInitialMealIndex, { mod: mealLabels.length });

	if (!data || data.mealMenuConnection.edges.length === 0) {
		return null;
	}

	const mealMenu = data.mealMenuConnection.edges[0].node;
	const mealLabel = mealLabels[mealIndex];
	const meal = getMeal(mealMenu, isVegetarian, mealLabel);

	return (
		<Tile className={className} style={{ overflow: "hidden", height: "100%", ...style }}>
			<Tile.Header style={{ padding: "0.5rem 0.75rem 0" }}>
				<Row justify="space-between" align="center">
					<MealIcon size="1.5rem" />

					<Heading level={3} noMargin>
						{mealLabel}
					</Heading>

					<Button
						variant={isVegetarian ? "success" : "tertiary"}
						size="small"
						title={isVegetarian ? "Show non-vegetarian" : "Show vegetarian"}
						onClick={toggleIsVegetarian}
					>
						VG
					</Button>
				</Row>
			</Tile.Header>
			<Tile.Body style={{ height: "100%", padding: "0.25rem 0.5rem", overflow: "hidden" }}>
				<Row
					justify="spaced-start"
					horizontalSpacing="0.25rem"
					align="center"
					style={{ height: "100%", overflow: "hidden" }}
				>
					<Button
						variant="link"
						onClick={decrementMealIndex}
						disabled={mealIndex === 0}
						title="Show previous meal"
						style={{ padding: "0.25rem" }}
					>
						<Icon.ChevronLeft size="1.25rem" style={{ display: "block" }} />
					</Button>

					<Paragraph className={styles["meal-menu__description"]}>{meal}</Paragraph>

					<Button
						variant="link"
						onClick={incrementMealIndex}
						disabled={mealIndex === mealLabels.length - 1}
						title="Show next meal"
						style={{ padding: "0.25rem" }}
					>
						<Icon.ChevronRight size="1.25rem" style={{ display: "block" }} />
					</Button>
				</Row>
			</Tile.Body>
			<Tile.Footer style={{ padding: 0 }}>
				<Row justify="center" align="center">
					<Button variant="link" size="small" onClick={toggleIsModalOpen}>
						View Menu
					</Button>
				</Row>

				<Modal.If condition={isModalOpen}>
					<MealMenuModal mealMenu={mealMenu} onClose={toggleIsModalOpen} />
				</Modal.If>
			</Tile.Footer>
		</Tile>
	);
}

function getInitialMealIndex() {
	const now = new Date();

	const snackStartTime = new Date();
	snackStartTime.setHours(13, 30, 0, 0);
	if (isAfter(now, snackStartTime)) {
		return mealLabels.indexOf("Snack");
	}

	const lunchStartTime = new Date();
	lunchStartTime.setHours(11, 0, 0, 0);
	if (isAfter(now, lunchStartTime)) {
		return mealLabels.indexOf("Lunch");
	}

	return mealLabels.indexOf("Breakfast");
}

function getMeal(
	mealMenu: MealMenuIndexQuery["mealMenuConnection"]["edges"][0]["node"],
	isVegetarian: boolean,
	mealLabel: string,
) {
	switch (mealLabel) {
		case "Snack":
			return isVegetarian ? mealMenu.vegetarianSnack || mealMenu.snack : mealMenu.snack;
		case "Lunch":
			return isVegetarian ? mealMenu.vegetarianLunch || mealMenu.lunch : mealMenu.lunch;
		default:
			return isVegetarian ? mealMenu.vegetarianBreakfast || mealMenu.breakfast : mealMenu.breakfast;
	}
}

type MealMenuModalProps = {
	mealMenu: MealMenuForm.ControlledReadOnlyProps["mealMenu"];
} & Pick<ModalProps, "ifRef" | "onClose">;

function MealMenuModal({ mealMenu, ifRef, onClose }: MealMenuModalProps) {
	return (
		<Modal ifRef={ifRef} onClose={onClose} style={{ width: "fit-content", maxWidth: "90vw" }}>
			<Modal.Body>
				<MealMenuForm.ControlledReadOnly mealMenu={mealMenu} />
			</Modal.Body>
		</Modal>
	);
}

const notesColumns = ["icon", "type", "students", "submittedAt", "submittedBy"];
const notesColumnWidths = {
	icon: "2.5rem",
	type: "10rem",
	students: "2fr",
	submittedAt: "7rem", // wider to fit heading
	by: "1fr",
};

function NotesTile({ teacherID, style }: { teacherID?: Employee["id"] } & Partial<StyleProps>) {
	const { loading, data } = useCurrentNotes(false, teacherID);
	const today = new Date();
	const todaysNotes: NoteStudentNotesListQuery["noteConnection"]["edges"][0]["node"][] = [];
	const previousNotes: NoteStudentNotesListQuery["noteConnection"]["edges"][0]["node"][] = [];
	if (data) {
		for (let i = 0; i < data.noteConnection.edges.length; i++) {
			const note = data.noteConnection.edges[i].node;
			if (isSameTime(note.createdAt, today, "day")) {
				todaysNotes.push(note);
			} else {
				previousNotes.push(note);
			}
		}
	}

	return (
		<Tile style={style}>
			<Tile.Header>
				<Heading level={3} noMargin>
					Today's Reports
				</Heading>
				<HR color="#ddd" style={{ width: "80%" }} />
			</Tile.Header>
			<Tile.Body style={{ height: "100%", overflow: "hidden" }}>
				<div style={{ height: "100%", overflow: "scroll" }}>
					<Table
						columns={notesColumns}
						columnWidths={notesColumnWidths}
						style={{ width: "100%", "--table__header---top": 0 } as React.CSSProperties}
					>
						<Table.Header style={{ zIndex: 10 }}>
							<Table.Row>
								<Table.Heading column="icon"> </Table.Heading>
								<Table.Heading column="type">Type</Table.Heading>
								<Table.Heading column="students">Students</Table.Heading>
								<Table.Heading column="submittedAt" style={{ whiteSpace: "nowrap" }}>
									Submitted At
								</Table.Heading>
								<Table.Heading column="submittedBy">By</Table.Heading>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							<Conditional>
								<If condition={loading && !data}>
									<Table.Row>
										<Table.Cell column="icon" colspan={notesColumns.length}>
											Loading...
										</Table.Cell>
									</Table.Row>
								</If>
								<If condition={todaysNotes.length === 0}>
									<Table.Row>
										<Table.Cell
											column="icon"
											colspan={notesColumns.length}
											style={{ textAlign: "center", color: "#777" }}
										>
											No reports today
										</Table.Cell>
									</Table.Row>
								</If>
								<Else>
									{todaysNotes.map((e) => (
										<NoteRow key={e.id} note={e} />
									))}
								</Else>
							</Conditional>
							<If condition={previousNotes.length > 0}>
								<Table.Row>
									<Table.Cell
										column="icon"
										colspan={notesColumns.length}
										style={{ paddingTop: "1rem", paddingBottom: "1rem" }}
									>
										<strong>Yesterday's Reports</strong>
									</Table.Cell>
								</Table.Row>
								{previousNotes.map((e) => (
									<NoteRow key={e.id} note={e} />
								))}
							</If>
						</Table.Body>
					</Table>
				</div>
			</Tile.Body>
		</Tile>
	);
}

type NoteRowProps = {
	note: NoteStudentNotesListQuery["noteConnection"]["edges"][0]["node"];
};

function NoteRow({ note }: NoteRowProps) {
	const { isModalOpen, toggleIsModalOpen } = Modal.useToggle(false);

	return (
		<Table.Row
			onClick={toggleIsModalOpen}
			style={{ "--table__row--hover---background-color": "#f4f4f4", cursor: "pointer" } as React.CSSProperties}
		>
			<Table.Cell column="icon" style={{ padding: 0 }}>
				<Button variant="link" onClick={() => {}} style={{ width: "100%", height: "100%", padding: 0 }}>
					<Row justify="center" align="center" style={{ height: "100%" }}>
						<Icon.Eye size="1rem" style={{ display: "block" }} />
					</Row>
				</Button>
			</Table.Cell>
			<Table.Cell column="type" oneLine>
				{NoteFormat.Fields.noteType(note.noteType)}
			</Table.Cell>
			<Table.Cell column="students" oneLine>
				{englishCommaJoinStrings(note.students.map((e) => e.person.name))}
			</Table.Cell>
			<Table.Cell column="submittedAt" oneLine style={{ textAlign: "center" }}>
				{formatDateTime(note.createdAt, "HH:mm")}
			</Table.Cell>
			<Table.Cell column="submittedBy" oneLine style={{ textAlign: "center" }}>
				{note.author?.person?.name}
			</Table.Cell>

			<Modal.If condition={isModalOpen}>
				<NoteModal noteType={note.noteType} noteID={note.id} onClose={toggleIsModalOpen} />
			</Modal.If>
		</Table.Row>
	);
}

const quotationGradients = [
	"linear-gradient(120deg, #12a3e344, #48c71244)",
	"linear-gradient(120deg, #d19e5e44, #a32d5b44)",
	"linear-gradient(120deg, #50b0bc44, #9c314e44)",
	"linear-gradient(120deg, #80069944, #f0e04644)",
	"linear-gradient(120deg, #ef9e5f44, #f0e57044)",
	"linear-gradient(120deg, #ea5b7444, #745a7444)",
	"linear-gradient(120deg, #aa52c744, #11e5bd44)",
	"linear-gradient(120deg, #abfa0944, #f7219b44)",
	"linear-gradient(120deg, #dec2ad44, #ab0b4344)",
	"linear-gradient(120deg, #70f98644, #ae4d8544)",
	"linear-gradient(120deg, #ffcdab44, #8ccecc44)",
	"linear-gradient(120deg, #72fdb844, #e6fd6044)",
	"linear-gradient(120deg, #14d4b244, #2e82c644)",
];

type DailyQuotationProps = Partial<ClassNameProps & StyleProps>;

function DailyQuotation({ className, style }: DailyQuotationProps) {
	const { data } = useDailyQuotationQuery({ variables: { date: toLocalDateString(new Date()) } });
	const quotation = data?.dailyQuotation;
	const backgroundImage = quotation
		? quotationGradients[Number(quotation?.id ?? 0) % quotationGradients.length]
		: "transparent";

	return (
		<Column
			justify="spaced-center"
			verticalSpacing="0.25rem"
			align="center"
			className={makeClassName(styles["daily-quotation"], className)}
			style={style}
		>
			{!!quotation && (
				<React.Fragment>
					<Paragraph className={styles["daily-quotation__body"]}>"{quotation.body.trim()}"</Paragraph>
					<span className={styles["daily-quotation__attribution"]}>{quotation.attribution}</span>
				</React.Fragment>
			)}
			<div className={styles["daily-quotation__background"]} style={{ backgroundImage }}></div>
		</Column>
	);
}

function useCurrentCalendarEvents() {
	const today = new Date();
	const { user } = useCurrentUser();
	return useCalendarEventIndexQuery({
		variables: {
			filters: getCalendarEventFiltersForUserForDateRange(user.id, today, today),
			order: { field: CalendarEventOrderField.StartTime, direction: OrderDirection.Asc },
		},
	});
}

function useCurrentBirthdays(todayOnly: boolean) {
	return useBirthdaysQuery({
		variables: {
			dates: todayOnly
				? [toLocalDateString(new Date())]
				: [
						toLocalDateString(new Date()),
						toLocalDateString(addTimeToDate(new Date(), [1, "day"])),
						toLocalDateString(addTimeToDate(new Date(), [2, "days"])),
				  ],
			filters: [
				{
					hasEmployee: true,
				},
				{
					hasStudent: true,
				},
			],
		},
	});
}

function useCurrentNotes(todayOnly: boolean, teacherID?: Employee["id"]) {
	const today = dateTrunc(new Date(), "day").toISOString();

	const teacherStudentFilters: StudentFilterInput[] = [
		{
			courseSectionStudentEnrollments: [
				{
					courseSection: [
						{
							term: [{ startDateLTE: today, endDateGTE: today }],
							courseSectionTeacherEnrollments: [{ teacherIDEQ: teacherID }],
						},
					],
				},
			],
		},
		{
			homeRoomSectionStudentEnrollments: [
				{
					homeRoomSection: [
						{
							term: [{ startDateLTE: today, endDateGTE: today }],
							homeRoomSectionTeacherEnrollments: [{ teacherIDEQ: teacherID }],
						},
					],
				},
			],
		},
	];

	return useNoteStudentNotesListQuery({
		variables: {
			filters: {
				noteTypeIn: [
					NoteNoteType.Academic,
					NoteNoteType.Behavior,
					NoteNoteType.Financial,
					NoteNoteType.General,
					NoteNoteType.Health,
					NoteNoteType.Incident,
					NoteNoteType.Injury,
					NoteNoteType.SocialEmotional,
				],
				createdAtGTE: todayOnly ? today : addTimeToDate(today, [-1, "day"]).toISOString(),
				createdAtLT: addTimeToDate(today, [1, "day"]).toISOString(),
				students: teacherID ? teacherStudentFilters : undefined,
			},
			order: { field: NoteOrderField.CreatedAt, direction: OrderDirection.Desc },
		},
		fetchPolicy: "cache-and-network",
	});
}
