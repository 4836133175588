import { IndexForms } from "@hex-insights/app-modules";
import { TypeFilterObject } from "@hex-insights/core";

export type StandardFilterFormValues = {}; // TODO define quick filters

export type FormValues = StandardFilterFormValues & IndexForms.SearchFormValues & IndexForms.FilterFormValues;

export const initialStandardFilters: StandardFilterFormValues = {};

export const initial: FormValues = {
	...initialStandardFilters,
	...IndexForms.initialSearchFormValues,
	...IndexForms.initialFilterFormValues,
};

export function getInitial(): FormValues {
	return initial;
}

export const quickFiltersTypeFilterObject: TypeFilterObject<StandardFilterFormValues> = {};

export const typeFilterObject: TypeFilterObject<FormValues> = {
	...quickFiltersTypeFilterObject,
	...IndexForms.searchFormValuesFilterObject,
	...IndexForms.filterFormValuesFilterObject,
};

export const advancedFilterInformation: IndexForms.AdvancedFilterInformation = {
	fieldOptions: [
		{ value: "pickupTimeMinutes" },
		{ value: "pickupLocation" },
		{ value: "dropoffTimeMinutes" },
		{ value: "dropoffLocation" },
	],
	fieldComparisonOptions: {
		pickupTimeMinutes: [...IndexForms.numberComparisonOptions, ...IndexForms.nullableComparisonOptions],
		pickupLocation: IndexForms.stringComparisonOptions,
		dropoffTimeMinutes: [...IndexForms.numberComparisonOptions, ...IndexForms.nullableComparisonOptions],
		dropoffLocation: IndexForms.stringComparisonOptions,
	},
	fieldComparisonTranslations: {
		pickupTimeMinutes: { ...IndexForms.numberComparisonTranslations, ...IndexForms.nullableComparisonTranslations },
		pickupLocation: IndexForms.stringComparisonTranslations,
		dropoffTimeMinutes: {
			...IndexForms.numberComparisonTranslations,
			...IndexForms.nullableComparisonTranslations,
		},
		dropoffLocation: IndexForms.stringComparisonTranslations,
	},
};
