import {
	CaseStyle,
	convertCase,
	dateDiff,
	formatDateTime,
	stringToLocalDate,
	toGrammaticalNumber,
	toLocalDateString,
} from "@hex-insights/core";
import { Person } from "../../../GraphQL";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the name field of the Person model.
 */
export function name(value: Person["name"]): string {
	return toString(value);
}

/**
 * Formats the firstName field of the Person model.
 */
export function firstName(value: Person["firstName"]): string {
	return toString(value);
}

/**
 * Formats the lastName field of the Person model.
 */
export function lastName(value: Person["lastName"]): string {
	return toString(value);
}

/**
 * Formats the nickname field of the Person model.
 */
export function nickname(value: Person["nickname"]): string {
	return toString(value);
}

/**
 * Formats the alternateNames field of the Person model.
 */
export function alternateNames(value: Person["alternateNames"]): string {
	return toString(value);
}

/**
 * Formats the imageURL field of the Person model.
 */
export function imageURL(value: Person["imageURL"]): string {
	return toString(value);
}

/**
 * Formats the gender field of the Person model.
 */
export function gender(value: Person["gender"]): string {
	return toString(value);
}

/**
 * Formats the dateOfBirth field of the Person model.
 */
export function dateOfBirth(value: Person["dateOfBirth"] | null): string {
	if (value === null) {
		return toString(value);
	}
	return formatDateTime(stringToLocalDate(value, "day"), "D MMM YYYY");
}

/**
 * Formats the age of the Person model (computed based on the date of birth).
 */
export function age(dob: Person["dateOfBirth"] | null): string {
	if (dob === null) {
		return toString(dob);
	}
	const years = dateDiff(toLocalDateString(new Date()), dob, "year");
	return `${toGrammaticalNumber("year", years, true)} old`;
}

/**
 * Formats the nationality field of the Person model.
 */
export function nationality(value: Person["nationality"]): string {
	return toString(value);
}

/**
 * Formats the primaryLanguage field of the Person model.
 */
export function primaryLanguage(value: Person["primaryLanguage"]): string {
	return toString(value);
}

/**
 * Formats the englishLanguageFluency field of the Person model.
 */
export function englishLanguageFluency(value: Person["englishLanguageFluency"] | null): string {
	if (value === null) {
		return toString(value);
	}
	return convertCase(value, CaseStyle.Title);
}
