import React from "react";
import { useSync, useUpdateEffect } from "@hex-insights/core";
import styles from "./styles.module.css";

// TODO move to hex-core

export function useLazyTooltip(onOpen: () => void, isReady: boolean) {
	const [isOpenInternal, setIsOpenInternal] = React.useState(false);
	const onOpenChange = React.useCallback((isOpen: boolean) => {
		setIsOpenInternal(isOpen);
	}, []);

	React.useEffect(() => {
		if (isOpenInternal) {
			onOpen();
		}
	}, [isOpenInternal, onOpen]);

	const [showTooltip, setShowTooltip] = React.useState(isReady);
	const className = React.useMemo(() => {
		if (!showTooltip) {
			return styles["tooltip--hidden"];
		}
		return undefined;
	}, [showTooltip]);

	const { syncID, synchronize } = useSync();
	useUpdateEffect(() => {
		synchronize();
		if (isReady) {
			window.setTimeout(() => setShowTooltip(true), 0);
		}
	}, [isReady, synchronize]);

	return { onOpenChange, contentSyncID: syncID, className };
}
