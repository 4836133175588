import React from "react";
import { ClassNameProps } from "@hex-insights/core";
import { DateTimeField, FieldDisplayArgs, MultiSelectField, RadioField, SelectField } from "@hex-insights/forms";
import {
	ParentSelect,
	ParentSelectQueryVariables,
	PaymentFormValues,
	PaymentLineItemSelect,
	useParentSelectLazyQuery,
	usePaymentLineItemSelectLazyQuery,
} from "../../../../Utilities";
import { ParentLink, PaymentLineItemLink } from "../../../Links";
import { TextAreaField } from "../../../TextAreaField";
import { BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the Payment model.
 */
type FieldProps<K extends keyof PaymentFormValues.Base = keyof PaymentFormValues.Base> = BaseFieldProps<
	Pick<PaymentFormValues.Base, K>
>;

/**
 * Generic props for fields of the Payment model that only appear in the detail form.
 */
type DetailFieldProps<K extends keyof PaymentFormValues.Detail = keyof PaymentFormValues.Detail> = BaseFieldProps<
	Pick<PaymentFormValues.Detail, K>
>;

/**
 * Renders a field component for the `createdDate` field of the Payment model.
 */
export function CreatedDate({ formState }: FieldProps<"createdDate">) {
	return <DateTimeField formState={formState} name="createdDate" precision="day" />;
}

/**
 * Renders a field component for the `paymentMethod` field of the Payment model.
 */
export function PaymentMethod({ formState }: FieldProps<"paymentMethod">) {
	return (
		<RadioField
			formState={formState}
			name="paymentMethod"
			options={PaymentFormValues.paymentMethodOptions}
			blankValue={null}
		/>
	);
}

/**
 * Renders a field component for the `notes` field of the Payment model.
 */
export function Notes({ formState, className }: FieldProps<"notes"> & Partial<ClassNameProps>) {
	return <TextAreaField formState={formState} name="notes" optional className={className} />;
}

export type ParentProps = FieldProps<"parentID"> & {
	currentParent?: ParentSelect.ModelForOption | null;
	queryVariables?: ParentSelectQueryVariables;
};

/**
 * Renders a field component for the `parent` edge of the Payment model.
 */
export function Parent({ formState, currentParent, queryVariables }: ParentProps) {
	const [loadOptions, { loading, data }] = useParentSelectLazyQuery({ variables: queryVariables });
	React.useEffect(() => {
		if (formState.formEditing.parentID) {
			loadOptions();
		}
	}, [formState.formEditing.parentID, loadOptions]);
	const options = React.useMemo(
		() => ParentSelect.toOptions(data?.parentConnection.edges, currentParent),
		[data, currentParent],
	);

	return (
		<SelectField
			formState={formState}
			name="parentID"
			isLoading={loading}
			options={options}
			display={displayParent}
			blankValue={null}
		/>
	);
}

function displayParent({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <ParentLink instance={{ id }}>{formattedValue}</ParentLink>;
}

export type PaymentLineItemsProps = DetailFieldProps<"paymentLineItemIDs"> & {
	currentPaymentLineItems?: PaymentLineItemSelect.ModelForOption[];
};

/**
 * Renders a field component for the `paymentLineItems` edge of the Payment model.
 */
export function PaymentLineItems({ formState, currentPaymentLineItems }: PaymentLineItemsProps) {
	const [loadOptions, { loading, data }] = usePaymentLineItemSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.paymentLineItemIDs) {
			loadOptions();
		}
	}, [formState.formEditing.paymentLineItemIDs, loadOptions]);
	const options = React.useMemo(
		() => PaymentLineItemSelect.toMultiOptions(data?.paymentLineItemConnection.edges, currentPaymentLineItems),
		[data, currentPaymentLineItems],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="paymentLineItemIDs"
			isLoading={loading}
			options={options}
			displayInstance={displayPaymentLineItemInstance}
		/>
	);
}

function displayPaymentLineItemInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <PaymentLineItemLink instance={{ id }}>{formattedValue}</PaymentLineItemLink>;
}
