import { IndexForms } from "@hex-insights/app-modules";
import { CaseStyle, convertCase } from "@hex-insights/core";
import { OrderDirection, RoomOrderField } from "../../GraphQL";

export type FormValues = IndexForms.OrderFormValues<RoomOrderField | null>;

export const initial: FormValues = {
	field: RoomOrderField.Name,
	direction: OrderDirection.Asc,
};

/**
 * Options for the "field" field in the order form of the Room model.
 */
export const fieldOptions = [
	{ label: "ID", value: null },
	{ label: convertCase(RoomOrderField.Name.toLowerCase(), CaseStyle.Title), value: RoomOrderField.Name },
	{ label: convertCase(RoomOrderField.Number.toLowerCase(), CaseStyle.Title), value: RoomOrderField.Number },
];

/**
 * Array of values for the "field" field in the order form of the Room model.
 */
export const fieldValues = fieldOptions.map((e) => e.value);

/**
 * Mapping from order fields of the Room model to their corresponding order type.
 */
export const fieldTypeMapping = {
	[RoomOrderField.Name]: IndexForms.OrderFieldType.String,
	[RoomOrderField.Number]: IndexForms.OrderFieldType.String,
};

/**
 * Mapping from order fields of the Room model to their corresponding field name.
 */
export const orderFieldToModelField = {
	[RoomOrderField.Name]: "name",
	[RoomOrderField.Number]: "number",
};

/**
 * Mapping from field names of the Room model to their corresponding order field.
 */
export const modelFieldToOrderField = {
	name: RoomOrderField.Name,
	number: RoomOrderField.Number,
};
