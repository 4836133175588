import React from "react";
import { SlotInfo, View } from "react-big-calendar";
import { DateCallback } from "react-calendar";
import {
	addTimeToDate,
	Button,
	ClassNameProps,
	Column,
	dateTrunc,
	dateTruncMonth,
	getLastDayInMonth,
	Heading,
	Icon,
	If,
	isArray,
	makeClassName,
	Modal,
	ModalProps,
	RequiredKeys,
	Row,
	StyleProps,
	toLocalDateString,
	useToggle,
	useUpdatingRef,
} from "@hex-insights/core";
import { anyFieldChanged } from "@hex-insights/forms";
import { PermissionCheckMethod, usePermissionCheck } from "@hex-insights/permissioning";
import { useCurrentUser } from "../../Contexts";
import {
	CalendarEvent,
	CalendarEventFormState,
	CalendarEventFormValues,
	CalendarEventMutation,
	CalendarEventOrderField,
	getFirstDayInWeek,
	getLastDayInWeek,
	OrderDirection,
	permissions,
	useCalendarEventIndexQuery,
} from "../../Utilities";
import { BigCalendar, CalendarItem } from "../BigCalendar";
import { EventDetailModal } from "../EventDetailModal";
import { CalendarEventForm } from "../Forms";
import {
	getCalendarEventFiltersForUserForDateRange,
	SmallEventCalendar,
	useSmallEventCalendarDates,
} from "../SmallEventCalendar";
import { Tile } from "../Tile";
import styles from "./styles.module.css";

const createEventPermissions = [permissions.CalendarEvent.Create.Admin, permissions.CalendarEvent.Create.Basic];

export type EventCalendarTileProps = Partial<ClassNameProps & StyleProps>;

export function EventCalendarTile({ className, style }: EventCalendarTileProps) {
	const { value, firstOfMonth, lastOfMonth, onActiveStartDateChange, onClickMonth, onChange } =
		useSmallEventCalendarDates();

	const { user } = useCurrentUser();
	const { loading, data } = useCalendarEventIndexQuery({
		variables: {
			filters: getCalendarEventFiltersForUserForDateRange(user.id, firstOfMonth, lastOfMonth),
			order: { field: CalendarEventOrderField.StartTime, direction: OrderDirection.Asc },
		},
	});
	const events = data?.calendarEventConnection.edges.map((e) => ({
		...e.node,
		groups: e.node.sharingUserGroups,
	}));

	const { isOn: isFullCalendarOpen, toggle: toggleIsFullCalendarOpen } = useToggle(false);
	const {
		isOn: isNewEventModalOpen,
		toggle: toggleIsNewEventModalOpen,
		setIsOn: setIsNewEventModalOpen,
	} = useToggle(false);
	const dateForNewEvent = new Date(toLocalDateString(value));

	const hasCreatePermission = usePermissionCheck({
		requiredPermissions: createEventPermissions,
		checkMethod: PermissionCheckMethod.Or,
	});
	React.useEffect(() => {
		if (!hasCreatePermission) {
			setIsNewEventModalOpen(false);
		}
	}, [hasCreatePermission, setIsNewEventModalOpen]);

	const onClickDay = React.useCallback<DateCallback>(
		(_, event) => {
			const isDoubleClick = event.detail >= 2;
			if (isDoubleClick && hasCreatePermission) {
				setIsNewEventModalOpen(true);
			}
		},
		[hasCreatePermission, setIsNewEventModalOpen],
	);

	return (
		<Tile className={makeClassName(className, styles["calendar-tile"])} style={{ ...style, position: "relative" }}>
			<Tile.Body style={{ flexGrow: 1, height: "100%" }}>
				<div style={{ position: "absolute", top: "1rem", left: "1.5rem", zIndex: 10 }}>
					<Button
						variant="link"
						size="small"
						onClick={toggleIsFullCalendarOpen}
						title="Expand to Full Calendar"
						className={styles["expand-full-calendar-button"]}
					>
						<Icon.Maximize2
							color="var(--verita-blue)"
							size="0.9rem"
							className={styles["expand-full-calendar-button__icon"]}
						/>
					</Button>
				</div>

				<Column justify="space-between" style={{ height: "100%", overflow: "scroll" }}>
					<SmallEventCalendar
						value={value}
						onActiveStartDateChange={onActiveStartDateChange}
						onClickDay={onClickDay}
						onClickMonth={onClickMonth}
						onChange={onChange}
						noSecondaryArrows
						isLoading={loading}
						withBirthdays
						events={events}
						className={styles["calendar-tile__calendar"]}
					/>

					<If condition={hasCreatePermission}>
						<Row justify="center" className={styles["create-event-row"]}>
							<Button variant="primary" size="small" onClick={toggleIsNewEventModalOpen}>
								Create Event
							</Button>
						</Row>
					</If>
				</Column>

				<Modal.If condition={isFullCalendarOpen}>
					<FullCalendarModal initialDate={value} onClose={toggleIsFullCalendarOpen} />
				</Modal.If>

				<Modal.If condition={isNewEventModalOpen}>
					<NewEventModal
						initialStartDate={dateForNewEvent}
						initialEndDate={dateForNewEvent}
						initialIsAllDay
						onClose={toggleIsNewEventModalOpen}
					/>
				</Modal.If>
			</Tile.Body>
		</Tile>
	);
}

type FullCalendarModalProps = {
	initialDate?: Date;
} & Pick<ModalProps, "ifRef" | "onClose">;

function FullCalendarModal({ initialDate = new Date(), ifRef, onClose }: FullCalendarModalProps) {
	const [date, setDate] = React.useState(initialDate);
	const dateRef = useUpdatingRef(date);
	const [startDate, setStartDate] = React.useState(() => getFirstDayInWeek(dateTruncMonth(date)).toDate());
	const [endDate, setEndDate] = React.useState(() => getLastDayInWeek(getLastDayInMonth(date)).toDate());

	const { user } = useCurrentUser();
	const { data } = useCalendarEventIndexQuery({
		variables: {
			filters: getCalendarEventFiltersForUserForDateRange(user.id, startDate, endDate),
			order: { field: CalendarEventOrderField.StartTime, direction: OrderDirection.Asc },
		},
	});

	const calendarItems = React.useMemo<CalendarItem[]>(() => {
		if (!data) {
			return [];
		}
		return data.calendarEventConnection.edges.map(({ node }) => ({
			title: node.name,
			allDay: node.isAllDay,
			start: new Date(node.startTime),
			end: new Date(node.endTime),
			original: { ...node, groups: node.sharingUserGroups },
		}));
	}, [data]);

	const onRangeChange = React.useCallback<
		(
			range:
				| Date[]
				| {
						start: Date;
						end: Date;
				  },
			view?: View | undefined,
		) => void
	>((newRange) => {
		let newStartDate;
		let newEndDate;
		if (isArray(newRange)) {
			newStartDate = newRange[0];
			newEndDate = newRange[newRange.length - 1];
		} else {
			newStartDate = newRange.start;
			newEndDate = newRange.end;
		}
		setStartDate(newStartDate);
		setEndDate(newEndDate ?? newStartDate);
	}, []);

	const [selectedEvent, setSelectedEvent] = React.useState<Pick<CalendarEvent, "id" | "name"> | null>(null);

	const [view, setView] = React.useState<View>("month");
	const viewRef = useUpdatingRef(view);

	const lastWheelEventTimeRef = React.useRef(0);
	const lastWheelDeltaRef = React.useRef(0);

	const onWheel = React.useCallback(
		(event: React.WheelEvent) => {
			if (viewRef.current !== "month") {
				return;
			}

			const now = performance.now();
			const hasTimeGap = now - lastWheelEventTimeRef.current >= 2000;
			const isSameDirection = Math.sign(event.deltaY) === Math.sign(lastWheelDeltaRef.current);
			const isInertia = !hasTimeGap && isSameDirection;
			if (isInertia) {
				return;
			}
			lastWheelDeltaRef.current = event.deltaY;
			lastWheelEventTimeRef.current = now;

			const direction = Math.sign(event.deltaY);
			const newDate = dateTrunc(
				addTimeToDate(dateTrunc(dateRef.current, "month"), [direction, "month"]),
				"month",
			).toDate();
			setDate(newDate);
			setStartDate(getFirstDayInWeek(newDate).toDate());
			setEndDate(getLastDayInWeek(getLastDayInMonth(newDate)).toDate());
		},
		[viewRef, dateRef],
	);

	const [newEventDates, setNewEventDates] = React.useState<{ start: Date; end: Date; isAllDay: boolean } | null>(null);
	const onDoubleClickSlot = React.useCallback(
		(info: SlotInfo) => {
			if (info.action !== "doubleClick") {
				return;
			}
			const isMonthView = viewRef.current === "month";
			const start = isMonthView ? new Date(toLocalDateString(info.start)) : info.start;
			const end = isMonthView ? new Date(toLocalDateString(info.end)) : info.end;
			setNewEventDates({
				start: start,
				end: isMonthView ? start : end,
				isAllDay: isMonthView,
			});
		},
		[viewRef],
	);
	const onCloseNewEventModal = React.useCallback(() => {
		setNewEventDates(null);
	}, []);

	return (
		<Modal
			ifRef={ifRef}
			onClose={onClose}
			style={{ width: "90vw", maxWidth: "none", height: "90vh", maxHeight: "none" }}
		>
			<Modal.Body style={{ height: "100%", overflow: "hidden" }}>
				<div onWheel={onWheel} style={{ height: "100%", overflow: "hidden" }}>
					<BigCalendar
						date={date}
						onNavigate={(newDate) => setDate(newDate)}
						defaultView="month"
						view={view}
						onView={setView}
						events={calendarItems}
						onSelectEvent={(e) => setSelectedEvent((e as any).original)}
						onSelectSlot={onDoubleClickSlot}
						onRangeChange={onRangeChange}
						containerStyle={{ height: "100%" }}
						selectable
					/>
				</div>

				{selectedEvent !== null && (
					<EventDetailModal calendarEvent={selectedEvent} onClose={() => setSelectedEvent(null)} />
				)}

				{newEventDates !== null && (
					<NewEventModal
						initialStartDate={newEventDates.start}
						initialEndDate={newEventDates.end}
						initialIsAllDay={newEventDates.isAllDay}
						onClose={onCloseNewEventModal}
					/>
				)}
			</Modal.Body>
		</Modal>
	);
}

type NewEventModalProps = {
	initialStartDate: Date;
	initialEndDate: Date;
	initialIsAllDay?: boolean;
} & RequiredKeys<Pick<ModalProps, "ifRef" | "onClose">, "onClose">;

function NewEventModal({
	initialStartDate,
	initialEndDate,
	initialIsAllDay = false,
	ifRef,
	onClose,
}: NewEventModalProps) {
	const initialFormValues = React.useMemo(
		() => ({
			startTime: initialStartDate.toISOString(),
			endTime: initialEndDate.toISOString(),
			isAllDay: initialIsAllDay,
		}),
		[initialStartDate, initialEndDate, initialIsAllDay],
	);
	const formState = CalendarEventFormState.useCreateFormState(initialFormValues);

	const create = CalendarEventMutation.useCreate();

	const applyCreate = React.useCallback(
		async (formValues: CalendarEventFormValues.Create) => {
			const { errors } = await create(formValues);
			return errors;
		},
		[create],
	);

	return (
		<Modal ifRef={ifRef} onClose={onClose} confirmOnClose={anyFieldChanged(formState)} style={{ width: "fit-content" }}>
			<Modal.Header>
				<Heading level={2} noMargin>
					New Event
				</Heading>
			</Modal.Header>
			<Modal.Body>
				<div style={{ width: "fit-content", padding: "0 1rem" }}>
					<CalendarEventForm.Create formState={formState} applyCreate={applyCreate} onSuccess={onClose} />
				</div>
			</Modal.Body>
		</Modal>
	);
}
