import React from "react";
import { ApplyCreateFunction, onCreateSubmit } from "@hex-insights/app-modules";
import { Column, formatDateTime, jsxJoin, Row } from "@hex-insights/core";
import {
	DateTimeField,
	Form,
	FormState,
	FormSubmitFunction,
	Label,
	RadioField,
	SubmissionStatus,
	SubmitButton,
} from "@hex-insights/forms";
import { Employee, Maybe, Note, NoteFormState, NoteFormValues, Person, Student, User } from "../../../../../Utilities";
import { StudentLink } from "../../../../Links";
import { TextAreaField, TextAreaFieldDisplay } from "../../../../TextAreaField";
import * as Field from "../Field";
import styles from "../styles.module.css";

export type ControlledCreateProps = {
	applyCreate: ApplyCreateFunction<NoteFormValues.Incident.Create>;
	onSuccess: () => void;
};

export function ControlledCreate(props: ControlledCreateProps) {
	const formState = NoteFormState.Incident.useCreateFormState();

	return <Create formState={formState} {...props} />;
}

export type CreateProps = ControlledCreateProps & {
	formState: FormState<NoteFormValues.Incident.Create>;
};

/**
 * Renders the create form of the Note model for an annoucement-type note using the given form state.
 */
export function Create({ formState, applyCreate, onSuccess }: CreateProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<NoteFormValues.Incident.Create>>(
		async ({ formValues }) => {
			return onCreateSubmit(formValues, applyCreate);
		},
		[applyCreate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	return (
		<Form
			name="note.create.incident"
			formState={formState}
			onSubmit={formState.onSubmitWrapper(onSubmit)}
			className={styles["note-form"]}
		>
			<Column justify="spaced-start" verticalSpacing="2rem">
				<Field.Students formState={formState} label="Students Involved" />

				<TextAreaField formState={formState} name="body" label="Describe the incident" />

				<DateTimeField
					formState={formState}
					name="incidentTime"
					label="What day and time did this incident happen?"
					precision="minute"
					optional
				/>

				<RadioField
					formState={formState}
					name="incidentLocation"
					label="Where did the incident happen?"
					options={NoteFormValues.schoolLocationOptions}
					optional
				/>

				<TextAreaField
					formState={formState}
					name="actionsTaken"
					label="Describe what actions have been taken in response to this incident"
					optional
				/>

				<TextAreaField
					formState={formState}
					name="suggestionsForFuture"
					label="What suggestions do you have to deal with or avoid this type of incident in the future?"
					optional
				/>

				<Row justify="flex-end" style={{ position: "sticky", bottom: "1rem" }}>
					<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
						Submit
					</SubmitButton>
				</Row>
			</Column>
		</Form>
	);
}

// TODO cleanup
export type ReadOnlyProps = {
	note: Pick<Note, "body" | "details" | "createdAt"> & {
		author: Maybe<
			Pick<User, "id" | "email"> & {
				person?: Maybe<
					Pick<Person, "name"> & {
						employee?: Maybe<Pick<Employee, "id">>;
					}
				>;
			}
		>;
		students: (Pick<Student, "id"> & { person: Pick<Student["person"], "id" | "name"> })[];
	};
};

export function ReadOnly({ note }: ReadOnlyProps) {
	const noteDetails = JSON.parse(note.details);

	return (
		<Column justify="spaced-start" verticalSpacing="2rem" className={styles["note-form"]}>
			<Row justify="spaced-start" align="center">
				<span>
					<Label>Student</Label>:
				</span>
				<span>
					{jsxJoin(
						note.students.map((e) => <StudentLink key={e.id} instance={e} />),
						", ",
					)}
				</span>
			</Row>

			<Column>
				<Label>Incident Description</Label>
				<TextAreaFieldDisplay value="" formattedValue={note.body} />
			</Column>

			<Row justify="spaced-start" align="center">
				<Column align="center">
					<Label>Incident Time</Label>
					<span>
						{noteDetails.incidentTime
							? formatDateTime(noteDetails.incidentTime, "HH:mm [on] D MMMM YYYY")
							: "Not given"}
					</span>
				</Column>

				<Column align="center">
					<Label>Incident Location</Label>
					<span>{noteDetails.incidentLocation ? noteDetails.incidentLocation : "Not given"}</span>
				</Column>
			</Row>

			<Column>
				<Label>Describe what actions have been taken in response to this incident</Label>
				<TextAreaFieldDisplay value="" formattedValue={noteDetails.actionsTaken || "Not given"} />
			</Column>

			<Column>
				<Label>What suggestions do you have to deal with or avoid this type of incident in the future?</Label>
				<TextAreaFieldDisplay value="" formattedValue={noteDetails.suggestionsForFuture || "Not given"} />
			</Column>
		</Column>
	);
}
