import { getArrayDiff, NullPartial, omitKeys, toLocalDateString } from "@hex-insights/core";
import { Note, NoteCreateInput, NoteDetailQuery, NoteNoteType, NoteUpdateInput, User } from "../../GraphQL";
import { NoteFormValues } from "../ModelFormValues";
import * as NoteAttachmentFormConversion from "./note-attachment";

export function academicToCreateFormValues(
	formValues: NoteFormValues.Academic.Create,
): NoteFormValues.Academic.CreateInput {
	return {
		noteType: NoteNoteType.Academic,
		body: formValues.body,
		details: JSON.stringify(omitKeys(formValues, ["studentIDs", "body"])),
		studentIDs: formValues.studentIDs,
		isPublic: true,
	};
}

export function announcementToCreateFormValues(
	formValues: NoteFormValues.Announcement.Create,
): NoteFormValues.Announcement.CreateInput {
	return {
		noteType: NoteNoteType.Announcement,
		title: formValues.title,
		body: formValues.body,
		startDate: formValues.startDate,
		endDate: formValues.endDate,
		attachments: formValues.attachments,
		isPublic: true,
	};
}

export function attendanceToCreateFormValues(
	formValues: NoteFormValues.Attendance.Create,
): NoteFormValues.Attendance.CreateInput {
	return {
		noteType: NoteNoteType.Attendance,
		body: formValues.body,
		startDate: formValues.startDate,
		endDate: formValues.endDate,
		isPublic: true,
		studentIDs: formValues.studentIDs,
	};
}

export type AttendanceNoteForFormValues = Pick<Note, "body" | "createdAt" | "startDate" | "endDate"> & {
	author: Pick<User, "id"> | null;
	students: Pick<Note["students"][0], "id">[];
};

export function noteToAttendanceFormValues(note: AttendanceNoteForFormValues): NoteFormValues.Attendance.Detail {
	return {
		body: note.body,
		createdAt: note.createdAt,
		authorID: note.author?.id ?? null,
		startDate: note.startDate,
		endDate: note.endDate,
		isMultiDay: note.startDate !== note.endDate && note.endDate !== null,
		studentIDs: note.students.map((e) => e.id),
	};
}

export function behaviorToCreateFormValues(
	formValues: NoteFormValues.Behavior.Create,
): NoteFormValues.Behavior.CreateInput {
	return {
		noteType: NoteNoteType.Behavior,
		body: formValues.body,
		details: JSON.stringify(omitKeys(formValues, ["studentIDs", "body"])),
		startDate: formValues.incidentTime !== null ? toLocalDateString(formValues.incidentTime) : null,
		endDate: formValues.incidentTime !== null ? toLocalDateString(formValues.incidentTime) : null,
		isPublic: true,
		studentIDs: formValues.studentIDs,
		attachments: [], // TODO
	};
}

export function financialToCreateFormValues(
	formValues: NoteFormValues.Financial.Create,
): NoteFormValues.Financial.CreateInput {
	return {
		noteType: NoteNoteType.Financial,
		body: formValues.body,
		isPublic: true,
		studentIDs: formValues.studentIDs,
	};
}

export type FinancialNoteForFormValues = Pick<Note, "body" | "createdAt"> & {
	author: Pick<User, "id"> | null;
	students: Pick<Note["students"][0], "id">[];
};

export function noteToFinancialFormValues(note: FinancialNoteForFormValues): NoteFormValues.Financial.Detail {
	return {
		studentIDs: note.students.map((e) => e.id),
		body: note.body,
		createdAt: note.createdAt,
		authorID: note.author?.id ?? null,
	};
}

export function generalToCreateFormValues(
	formValues: NoteFormValues.General.Create,
): NoteFormValues.General.CreateInput {
	return {
		noteType: NoteNoteType.General,
		body: formValues.body,
		isPublic: formValues.isPublic,
		calendarEventIDs: [],
		courseSectionIDs: [],
		employeeIDs: [],
		homeRoomSectionIDs: [],
		studentIDs: formValues.studentIDs,
		parentIDs: [],
		sharingUserIDs: formValues.sharingUserIDs,
	};
}

export type GeneralNoteForFormValues = Pick<Note, "body" | "isPublic" | "createdAt"> & {
	author: Pick<User, "id"> | null;
	students: Pick<Note["students"][0], "id">[];
	sharingUsers: Pick<Note["sharingUsers"][0], "id">[];
};

export function noteToGeneralFormValues(note: GeneralNoteForFormValues): NoteFormValues.General.Detail {
	return {
		studentIDs: note.students.map((e) => e.id),
		body: note.body,
		isPublic: note.isPublic,
		sharingUserIDs: note.sharingUsers.map((e) => e.id),
		createdAt: note.createdAt,
		authorID: note.author?.id ?? null,
	};
}

export function healthToCreateFormValues(formValues: NoteFormValues.Health.Create): NoteFormValues.Health.CreateInput {
	return {
		noteType: NoteNoteType.Health,
		body: formValues.body,
		studentIDs: formValues.studentIDs,
	};
}

export function incidentToCreateFormValues(
	formValues: NoteFormValues.Incident.Create,
): NoteFormValues.Incident.CreateInput {
	return {
		noteType: NoteNoteType.Incident,
		body: formValues.body,
		details: JSON.stringify(omitKeys(formValues, ["studentIDs", "body"])),
		startDate: formValues.incidentTime !== null ? toLocalDateString(formValues.incidentTime) : null,
		endDate: formValues.incidentTime !== null ? toLocalDateString(formValues.incidentTime) : null,
		isPublic: true,
		studentIDs: formValues.studentIDs,
	};
}

export function injuryToCreateFormValues(formValues: NoteFormValues.Injury.Create): NoteFormValues.Injury.CreateInput {
	return {
		noteType: NoteNoteType.Injury,
		body: formValues.body,
		details: JSON.stringify(omitKeys(formValues, ["studentIDs", "body"])),
		startDate: formValues.incidentTime !== null ? toLocalDateString(formValues.incidentTime) : null,
		endDate: formValues.incidentTime !== null ? toLocalDateString(formValues.incidentTime) : null,
		isPublic: true,
		studentIDs: formValues.studentIDs,
	};
}

export function socialEmotionalToCreateFormValues(
	formValues: NoteFormValues.SocialEmotional.Create,
): NoteFormValues.SocialEmotional.CreateInput {
	return {
		...academicToCreateFormValues(formValues),
		noteType: NoteNoteType.SocialEmotional,
	};
}

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the Note model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(formValues: NoteFormValues.PartialCreate): NoteCreateInput {
	const inputFormValues = omitKeys(formValues, ["isTimeDependent", "attachments"]);
	const input: NullPartial<NoteCreateInput> = { ...inputFormValues };
	if (formValues.attachments) {
		input.attachments = formValues.attachments.map((e) => NoteAttachmentFormConversion.toGQLCreateInNoteInput(e));
	}
	return input as NoteCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the Note model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<NoteFormValues.Detail>,
	initialFormValues: Partial<NoteFormValues.Detail>,
): NoteUpdateInput {
	const inputFormValues = omitKeys(formValues, [
		"noteType",
		"createdAt",
		"isTimeDependent",
		"authorID",
		"calendarEventIDs",
		"courseSectionIDs",
		"employeeIDs",
		"homeRoomSectionIDs",
		"parentIDs",
		"studentIDs",
		"sharingUserIDs",
	]);
	const input: NoteUpdateInput = { ...inputFormValues };

	if (formValues.startDate === null) {
		input.clearStartDate = true;
	}

	if (formValues.endDate === null) {
		input.clearEndDate = true;
	}

	if (formValues.calendarEventIDs !== undefined) {
		const { added, removed } = getArrayDiff(initialFormValues.calendarEventIDs ?? [], formValues.calendarEventIDs);
		input.addCalendarEventIDs = added;
		input.removeCalendarEventIDs = removed;
	}

	if (formValues.courseSectionIDs !== undefined) {
		const { added, removed } = getArrayDiff(initialFormValues.courseSectionIDs ?? [], formValues.courseSectionIDs);
		input.addCourseSectionIDs = added;
		input.removeCourseSectionIDs = removed;
	}

	if (formValues.employeeIDs !== undefined) {
		const { added, removed } = getArrayDiff(initialFormValues.employeeIDs ?? [], formValues.employeeIDs);
		input.addEmployeeIDs = added;
		input.removeEmployeeIDs = removed;
	}

	if (formValues.homeRoomSectionIDs !== undefined) {
		const { added, removed } = getArrayDiff(initialFormValues.homeRoomSectionIDs ?? [], formValues.homeRoomSectionIDs);
		input.addHomeRoomSectionIDs = added;
		input.removeHomeRoomSectionIDs = removed;
	}

	if (formValues.parentIDs !== undefined) {
		const { added, removed } = getArrayDiff(initialFormValues.parentIDs ?? [], formValues.parentIDs);
		input.addParentIDs = added;
		input.removeParentIDs = removed;
	}

	if (formValues.studentIDs !== undefined) {
		const { added, removed } = getArrayDiff(initialFormValues.studentIDs ?? [], formValues.studentIDs);
		input.addStudentIDs = added;
		input.removeStudentIDs = removed;
	}

	if (formValues.sharingUserIDs !== undefined) {
		const { added, removed } = getArrayDiff(initialFormValues.sharingUserIDs ?? [], formValues.sharingUserIDs);
		input.addSharingUserIDs = added;
		input.removeSharingUserIDs = removed;
	}

	return input;
}

/**
 * Converts a Note instance to its corresponding detail form values.
 *
 * @param note The instance to convert.
 */
export function toFormValues(note: NoteDetailQuery["note"]): NoteFormValues.Detail {
	return {
		noteType: note.noteType,
		title: note.title,
		body: note.body,
		details: note.details,
		createdAt: note.createdAt,
		isTimeDependent: note.isTimeDependent,
		startDate: note.startDate,
		endDate: note.endDate,
		isPublic: note.isPublic,
		authorID: note.author?.id ?? null,
		calendarEventIDs: note.calendarEvents.map((e) => e.id),
		courseSectionIDs: note.courseSections.map((e) => e.id),
		employeeIDs: note.employees.map((e) => e.id),
		homeRoomSectionIDs: note.homeRoomSections.map((e) => e.id),
		parentIDs: note.parents.map((e) => e.id),
		studentIDs: note.students.map((e) => e.id),
		sharingUserIDs: note.sharingUsers.map((e) => e.id),
	};
}
