import React from "react";
import { ApolloCache, FetchResult, gql } from "@apollo/client";
import {
	CourseSection,
	graphTypeNames,
	LearningObjective,
	LessonPlan,
	LessonPlanCreateInput,
	LessonPlanCreateMutation,
	LessonPlanDeleteMutation,
	LessonPlanDetailDocument,
	LessonPlanDetailQuery,
	LessonPlanDetailQueryVariables,
	LessonPlanUpdateInput,
	LessonPlanUpdateMutation,
	useLessonPlanCreateMutation,
	useLessonPlanDeleteMutation,
	useLessonPlanUpdateMutation,
} from "../../GraphQL";
import { LessonPlanFormConversion } from "../ModelFormConversion";
import { LessonPlanFormValues } from "../ModelFormValues";

/**
 * Returns a `create` function for the LessonPlan model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the LessonPlan model.
 */
export function useCreate() {
	const [createLessonPlan] = useLessonPlanCreateMutation();

	return React.useCallback(
		async (formValues: LessonPlanFormValues.Create) => {
			const input = LessonPlanFormConversion.toGQLCreateInput(formValues);
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createLessonPlan({ variables: { input }, update: updateCache });

			return { data: data?.createLessonPlan ?? null, errors: errors ?? null };
		},
		[createLessonPlan],
	);
}

/**
 * Returns an `update` function for the LessonPlan model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the LessonPlan model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: LessonPlan["id"]) {
	const [updateLessonPlan] = useLessonPlanUpdateMutation();

	return React.useCallback(
		async (changedFormValues: Partial<LessonPlanFormValues.Detail>, initialFormValues: LessonPlanFormValues.Detail) => {
			const input = LessonPlanFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

			const { data, errors } = await updateLessonPlan({ variables: { id, input }, update: updateCache });

			return { data: data?.updateLessonPlan ?? null, errors: errors ?? null };
		},
		[updateLessonPlan, id],
	);
}

/**
 * Returns a `del` function for the LessonPlan model.
 *
 * @param id The ID of the instance to delete.
 */
export function useDelete(id: LessonPlan["id"]) {
	const [deleteLessonPlan] = useLessonPlanDeleteMutation();

	return React.useCallback(async () => {
		const updateCache = getUpdateCacheForDelete(id);

		const { data, errors } = await deleteLessonPlan({ variables: { id }, update: updateCache });

		return { data: data?.deleteLessonPlan ?? false, errors: errors ?? null };
	}, [deleteLessonPlan, id]);
}

function getUpdateCacheForCreate(input: LessonPlanCreateInput) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<LessonPlanCreateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const createdObject = result.data.createLessonPlan;

		cache.writeQuery<LessonPlanDetailQuery, LessonPlanDetailQueryVariables>({
			query: LessonPlanDetailDocument,
			data: { lessonPlan: createdObject },
			variables: { id: createdObject.id },
		});

		if (input.courseSectionID) {
			addToLessonPlansOfCourseSectionCache(cache, input.courseSectionID, createdObject);
		}

		if (input.learningObjectiveIDs) {
			for (let i = 0; i < input.learningObjectiveIDs.length; i++) {
				addToLessonPlansOfLearningObjectiveCache(cache, input.learningObjectiveIDs[i], createdObject);
			}
		}

		cache.evict({ id: "ROOT_QUERY", fieldName: "lessonPlanConnection" });
	};
}

function getUpdateCacheForUpdate(input: LessonPlanUpdateInput, initialFormValues: LessonPlanFormValues.Detail) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<LessonPlanUpdateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const updatedObject = result.data.updateLessonPlan;

		if (initialFormValues.courseSectionID && input.courseSectionID) {
			removeFromLessonPlansOfCourseSectionCache(cache, initialFormValues.courseSectionID, updatedObject);
		}
		if (input.courseSectionID) {
			addToLessonPlansOfCourseSectionCache(cache, input.courseSectionID, updatedObject);
		}

		if (input.addLearningObjectiveIDs) {
			for (let i = 0; i < input.addLearningObjectiveIDs.length; i++) {
				addToLessonPlansOfLearningObjectiveCache(cache, input.addLearningObjectiveIDs[i], updatedObject);
			}
		}
		if (input.removeLearningObjectiveIDs) {
			for (let i = 0; i < input.removeLearningObjectiveIDs.length; i++) {
				removeFromLessonPlansOfLearningObjectiveCache(cache, input.removeLearningObjectiveIDs[i], updatedObject);
			}
		}
	};
}

function getUpdateCacheForDelete(id: LessonPlan["id"]) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<LessonPlanDeleteMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (!result.data?.deleteLessonPlan) {
			return;
		}

		cache.evict({ id: cache.identify({ id, __typename: graphTypeNames.LessonPlan }) });
		cache.evict({ id: "ROOT_QUERY", fieldName: "lessonPlanConnection" });
		cache.gc();
	};
}

function addToLessonPlansOfCourseSectionCache(
	cache: ApolloCache<any>,
	targetID: CourseSection["id"],
	object: Pick<LessonPlan, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.CourseSection }),
		fields: {
			lessonPlans: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromLessonPlansOfCourseSectionCache(
	cache: ApolloCache<any>,
	targetID: CourseSection["id"],
	object: Pick<LessonPlan, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.CourseSection }),
		fields: {
			lessonPlans: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function addToLessonPlansOfLearningObjectiveCache(
	cache: ApolloCache<any>,
	targetID: LearningObjective["id"],
	object: Pick<LessonPlan, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.LearningObjective }),
		fields: {
			lessonPlans: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromLessonPlansOfLearningObjectiveCache(
	cache: ApolloCache<any>,
	targetID: LearningObjective["id"],
	object: Pick<LessonPlan, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.LearningObjective }),
		fields: {
			lessonPlans: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function toCacheRef(cache: ApolloCache<any>, object: Pick<LessonPlan, "id">) {
	return cache.writeFragment({
		fragment: gql`
			fragment LessonPlanRef on LessonPlan {
				id
			}
		`,
		data: object,
	});
}
