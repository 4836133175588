import React from "react";
import { ChildrenProps, ClassNameProps, StyleProps, useSync } from "@hex-insights/core";

export type ScaledTextProps = {
	maxFontSize?: number;
} & Partial<ClassNameProps & StyleProps & ChildrenProps>;

export function ScaledText({ maxFontSize = 12, className, style, children }: ScaledTextProps) {
	const outerRef = React.useRef<HTMLDivElement | null>(null);
	const innerRef = React.useRef<HTMLDivElement | null>(null);
	const [fontSize, setFontSize] = React.useState(maxFontSize);
	const [isSettled, setIsSettled] = React.useState(false);
	const prevChildren = React.useRef(children);
	const isFullySettled = isSettled && prevChildren.current === children;
	React.useEffect(() => {
		prevChildren.current = children;
	}, [children]);

	const { syncID, synchronize } = useSync();

	React.useEffect(() => {
		setFontSize(maxFontSize);
		setIsSettled(false);
		synchronize();
	}, [maxFontSize, synchronize, children]);

	React.useEffect(() => {
		if (outerRef.current === null || innerRef.current === null) {
			synchronize();
			return;
		}

		if (!contentOverflows(outerRef.current)) {
			setIsSettled(true);
			return;
		}

		setFontSize((prev) => prev - 1);
	}, [synchronize, syncID, fontSize]);

	return (
		<div
			ref={outerRef}
			className={className}
			style={{ fontSize: `${fontSize}px`, ...style, opacity: isFullySettled ? 1 : 0, overflow: "hidden" }}
		>
			<div ref={innerRef}>{children}</div>
		</div>
	);
}

function contentOverflows({
	scrollWidth,
	clientWidth,
	scrollHeight,
	clientHeight,
}: Pick<HTMLElement, "scrollWidth" | "clientWidth" | "scrollHeight" | "clientHeight">) {
	return scrollWidth > clientWidth || scrollHeight > clientHeight;
}
