import { IndexForms } from "@hex-insights/app-modules";
import { TypeFilterObject } from "@hex-insights/core";
import { EnrollmentApplicationParentFormValues } from "../ModelFormValues";

export type StandardFilterFormValues = {}; // TODO define quick filters

export type FormValues = StandardFilterFormValues & IndexForms.SearchFormValues & IndexForms.FilterFormValues;

export const initialStandardFilters: StandardFilterFormValues = {};

export const initial: FormValues = {
	...initialStandardFilters,
	...IndexForms.initialSearchFormValues,
	...IndexForms.initialFilterFormValues,
};

export function getInitial(): FormValues {
	return initial;
}

export const quickFiltersTypeFilterObject: TypeFilterObject<StandardFilterFormValues> = {};

export const typeFilterObject: TypeFilterObject<FormValues> = {
	...quickFiltersTypeFilterObject,
	...IndexForms.searchFormValuesFilterObject,
	...IndexForms.filterFormValuesFilterObject,
};

export const advancedFilterInformation: IndexForms.AdvancedFilterInformation = {
	fieldOptions: [
		{ value: "formIdentifier" },
		{ value: "firstName" },
		{ value: "lastName" },
		{ value: "nationality" },
		{ value: "occupation" },
		{ value: "relationshipType" },
		{ value: "relationshipTypeCustom" },
		{ value: "isPrimaryContact" },
		{ value: "livesWithStudent" },
		{ value: "isAuthorizedForPickup" },
		{ value: "isEmergencyContact" },
		{ value: "isFinancialContact" },
		{ value: "addressSameAsFormIdentifier" },
		{ value: "addressLine1" },
		{ value: "addressLine2" },
		{ value: "city" },
		{ value: "stateOrProvince" },
		{ value: "postalCode" },
		{ value: "country" },
		{ value: "homePhone" },
		{ value: "workPhone" },
		{ value: "mobilePhone" },
		{ value: "email" },
	],
	fieldComparisonOptions: {
		formIdentifier: IndexForms.stringComparisonOptions,
		firstName: IndexForms.stringComparisonOptions,
		lastName: IndexForms.stringComparisonOptions,
		nationality: IndexForms.stringComparisonOptions,
		occupation: IndexForms.stringComparisonOptions,
		relationshipType: IndexForms.enumComparisonOptions,
		relationshipTypeCustom: IndexForms.stringComparisonOptions,
		isPrimaryContact: IndexForms.booleanComparisonOptions,
		livesWithStudent: IndexForms.booleanComparisonOptions,
		isAuthorizedForPickup: IndexForms.booleanComparisonOptions,
		isEmergencyContact: IndexForms.booleanComparisonOptions,
		isFinancialContact: IndexForms.booleanComparisonOptions,
		addressSameAsFormIdentifier: IndexForms.stringComparisonOptions,
		addressLine1: IndexForms.stringComparisonOptions,
		addressLine2: IndexForms.stringComparisonOptions,
		city: IndexForms.stringComparisonOptions,
		stateOrProvince: IndexForms.stringComparisonOptions,
		postalCode: IndexForms.stringComparisonOptions,
		country: IndexForms.stringComparisonOptions,
		homePhone: IndexForms.stringComparisonOptions,
		workPhone: IndexForms.stringComparisonOptions,
		mobilePhone: IndexForms.stringComparisonOptions,
		email: IndexForms.stringComparisonOptions,
	},
	fieldComparisonTranslations: {
		formIdentifier: IndexForms.stringComparisonTranslations,
		firstName: IndexForms.stringComparisonTranslations,
		lastName: IndexForms.stringComparisonTranslations,
		nationality: IndexForms.stringComparisonTranslations,
		occupation: IndexForms.stringComparisonTranslations,
		relationshipType: IndexForms.getRadioComparisonTranslations(
			EnrollmentApplicationParentFormValues.relationshipTypeOptions,
		),
		relationshipTypeCustom: IndexForms.stringComparisonTranslations,
		isPrimaryContact: IndexForms.booleanComparisonTranslations,
		livesWithStudent: IndexForms.booleanComparisonTranslations,
		isAuthorizedForPickup: IndexForms.booleanComparisonTranslations,
		isEmergencyContact: IndexForms.booleanComparisonTranslations,
		isFinancialContact: IndexForms.booleanComparisonTranslations,
		addressSameAsFormIdentifier: IndexForms.stringComparisonTranslations,
		addressLine1: IndexForms.stringComparisonTranslations,
		addressLine2: IndexForms.stringComparisonTranslations,
		city: IndexForms.stringComparisonTranslations,
		stateOrProvince: IndexForms.stringComparisonTranslations,
		postalCode: IndexForms.stringComparisonTranslations,
		country: IndexForms.stringComparisonTranslations,
		homePhone: IndexForms.stringComparisonTranslations,
		workPhone: IndexForms.stringComparisonTranslations,
		mobilePhone: IndexForms.stringComparisonTranslations,
		email: IndexForms.stringComparisonTranslations,
	},
};
