import { RadioInputOption } from "@hex-insights/forms";
import { Student, StudentDocument, User, UserGroup } from "../../GraphQL";

export type Base = {
	name: StudentDocument["name"];
	description: StudentDocument["description"];
	file: File | null;
	isPublic: StudentDocument["isPublic"];
	sharingUserGroupIDs: UserGroup["id"][];
	studentID: Student["id"] | null;
};

export type Create = Base;

export type Detail = Base & {
	createdAt: StudentDocument["createdAt"] | null;
	authorID: User["id"] | null;
};

export const initialCreate: Create = {
	name: "",
	description: "",
	file: null,
	isPublic: false,
	sharingUserGroupIDs: [],
	studentID: null,
};

export const isPublicOptions: RadioInputOption<boolean>[] = [
	{ value: true, label: "Public" },
	{ value: false, label: "Private" },
];
