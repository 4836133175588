import React from "react";
import { addTimeToDate, toLocalDateString } from "@hex-insights/core";
import { useRouteParams } from "@hex-insights/router";
import {
	getDateRange,
	LessonPlanOrderField,
	OrderDirection,
	useLessonPlanIndexQuery,
} from "@hex-insights/verita.shared";
import { LessonPlansWeekCourseSectionPageRouteParams } from "./pageinfo";

export function usePageWeekLessonPlanIndexQuery() {
	const { courseSectionID } = useRouteParams<LessonPlansWeekCourseSectionPageRouteParams>();

	const { startDate, endDate } = usePageDateBounds();

	return useLessonPlanIndexQuery({
		variables: {
			filters: { courseSectionIDEQ: courseSectionID, courseDateGTE: startDate, courseDateLTE: endDate },
			order: { field: LessonPlanOrderField.CourseDate, direction: OrderDirection.Asc },
		},
	});
}

export function usePageDateBounds() {
	const { date } = useRouteParams<LessonPlansWeekCourseSectionPageRouteParams>();

	return {
		startDate: toLocalDateString(date),
		endDate: toLocalDateString(addTimeToDate(date, [4, "days"])),
	};
}

export function usePageDateRange() {
	const { startDate, endDate } = usePageDateBounds();
	return React.useMemo(() => getDateRange(startDate, endDate), [startDate, endDate]);
}
