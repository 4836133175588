import { IndexForms } from "@hex-insights/app-modules";
import { CaseStyle, convertCase } from "@hex-insights/core";
import { DocumentResourceFolderOrderField, OrderDirection } from "../../GraphQL";

export type FormValues = IndexForms.OrderFormValues<DocumentResourceFolderOrderField | null>;

export const initial: FormValues = {
	field: DocumentResourceFolderOrderField.Name,
	direction: OrderDirection.Asc,
};

/**
 * Options for the "field" field in the order form of the DocumentResourceFolder model.
 */
export const fieldOptions = [
	{ label: "ID", value: null },
	{
		label: convertCase(DocumentResourceFolderOrderField.Name.toLowerCase(), CaseStyle.Title),
		value: DocumentResourceFolderOrderField.Name,
	},
	{
		label: convertCase(DocumentResourceFolderOrderField.Category.toLowerCase(), CaseStyle.Title),
		value: DocumentResourceFolderOrderField.Category,
	},
];

/**
 * Array of values for the "field" field in the order form of the DocumentResourceFolder model.
 */
export const fieldValues = fieldOptions.map((e) => e.value);

/**
 * Mapping from order fields of the DocumentResourceFolder model to their corresponding order type.
 */
export const fieldTypeMapping = {
	[DocumentResourceFolderOrderField.Name]: IndexForms.OrderFieldType.String,
	[DocumentResourceFolderOrderField.Category]: IndexForms.OrderFieldType.String,
};

/**
 * Mapping from order fields of the DocumentResourceFolder model to their corresponding field name.
 */
export const orderFieldToModelField = {
	[DocumentResourceFolderOrderField.Name]: "name",
	[DocumentResourceFolderOrderField.Category]: "category",
};

/**
 * Mapping from field names of the DocumentResourceFolder model to their corresponding order field.
 */
export const modelFieldToOrderField = {
	name: DocumentResourceFolderOrderField.Name,
	category: DocumentResourceFolderOrderField.Category,
};
