import React from "react";
import { Button, formatDateTime, Icon, Modal, Row } from "@hex-insights/core";
import { NoteModal, StudentNoteFormModal } from "../../../../Components";
import { NoteFormat, NoteNoteType, NoteStudentNotesListQuery, Student } from "../../../../Utilities";

export function HealthNoteDisplay({ note }: { note: NoteStudentNotesListQuery["noteConnection"]["edges"][0]["node"] }) {
	const { isModalOpen, toggleIsModalOpen } = Modal.useToggle(false);

	return (
		<div style={{ height: "100%" }}>
			<Button
				onClick={toggleIsModalOpen}
				style={{
					backgroundColor: "#e5eaf6",
					maxWidth: "10rem",
					height: "100%",
					padding: "0.25rem 0.5rem",
					borderRadius: "0.5rem",
				}}
			>
				<Row justify="spaced-start" align="center" horizontalSpacing="0.25rem">
					<Icon.File size="1.25rem" style={{ flexShrink: 0 }} />
					<div
						style={{
							whiteSpace: "nowrap",
							overflow: "hidden",
							textOverflow: "ellipsis",
						}}
					>
						{formatDateTime(note.createdAt, "D MMM")}: {NoteFormat.Fields.noteType(note.noteType)}
					</div>
				</Row>
			</Button>

			<Modal.If condition={isModalOpen}>
				<NoteModal noteID={note.id} noteType={note.noteType} onClose={toggleIsModalOpen} />
			</Modal.If>
		</div>
	);
}

const academicsNoteTypes = [NoteNoteType.Health, NoteNoteType.Incident, NoteNoteType.Injury];

export type NewHealthNoteControllerProps = {
	studentID: Student["id"];
};

export function NewHealthNoteController({ studentID }: NewHealthNoteControllerProps) {
	const { isModalOpen, toggleIsModalOpen } = Modal.useToggle(false);

	return (
		<React.Fragment>
			<div>
				<Button variant="tertiary" size="small" onClick={toggleIsModalOpen}>
					<Row
						justify="spaced-start"
						align="center"
						horizontalSpacing="0.25rem"
						style={{ whiteSpace: "nowrap", width: "100%", height: "100%" }}
					>
						<Icon.FilePlus size="1.25rem" style={{ flexShrink: 0 }} /> New Report
					</Row>
				</Button>
			</div>

			<Modal.If condition={isModalOpen}>
				<StudentNoteFormModal studentID={studentID} noteTypes={academicsNoteTypes} onClose={toggleIsModalOpen} />
			</Modal.If>
		</React.Fragment>
	);
}
