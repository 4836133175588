import { Models } from "@hex-insights/app-modules";
import { Relationship, RelationshipMutation } from "../../../../Utilities";

export type RelationshipDeleteButtonProps = Omit<Models.DeleteButtonProps, "deleteInstance"> & {
	instanceID: Relationship["id"];
};

/**
 * A delete button for the Relationship model.
 */
export function RelationshipDeleteButton({
	instanceID,
	confirmationContent = "Are you sure you want to remove this relationship?",
	children = "Remove Relationship",
	...props
}: RelationshipDeleteButtonProps) {
	const del = RelationshipMutation.useDelete(instanceID);

	return (
		<Models.DeleteButton deleteInstance={del} confirmationContent={confirmationContent} {...props}>
			{children}
		</Models.DeleteButton>
	);
}
