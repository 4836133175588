import { PaymentLineItem } from "../../../GraphQL";
import { formatPrice } from "../../money";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the amount field of the PaymentLineItem model.
 */
export function amount(value: PaymentLineItem["amount"] | null): string {
	return formatPrice(value);
}
