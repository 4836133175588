import { newPageInfo } from "@hex-insights/router";
import { Term } from "@hex-insights/verita.shared";
import { reportCardsPageInfo, ReportCardsPageRouteParams } from "../pageinfo";

export type ReportCardsTermPageRouteParams = ReportCardsPageRouteParams & {
	termID: string;
};

type TermForTitle = Pick<Term, "name">;

export const reportCardsTermPageInfo = newPageInfo({
	name: "Report Cards Term View",
	title: (e: TermForTitle) => e.name,
	description: "Term report cards page.",
	exact: false,
	to: (termID: Term["id"]) => reportCardsPageInfo.to + `/${termID}`,
	path: reportCardsPageInfo.path + "/:termID(\\d+)",
	parent: reportCardsPageInfo,
});
