import React from "react";
import { useApolloClient } from "@apollo/client";
import { FieldInputOption } from "@hex-insights/forms";
import { PhoneNumberFormat } from "../../Format";
import {
	graphTypeNames,
	Maybe,
	PhoneNumber,
	PhoneNumberCreateMutation,
	PhoneNumberNameFragment,
	PhoneNumberSelectDocument,
	PhoneNumberSelectQuery,
	PhoneNumberSelectQueryVariables,
} from "../../GraphQL";

/**
 * A slim version of the PhoneNumber model for use in select fields.
 */
export type ModelForOption = Pick<PhoneNumber, "id"> & PhoneNumberNameFragment;

/**
 * A slim version of the PhoneNumber model edge for use in select fields.
 */
export type EdgeForOption = {
	node: ModelForOption;
};

/**
 * Converts the given `edges` to options to be given to a radio or select field.
 *
 * @param edges The edges to convert to options.
 * @param current The current instance for the field. Used to display when `edges` is blank (e.g. with lazy loading).
 */
export function toOptions(edges?: EdgeForOption[], current?: Maybe<ModelForOption>) {
	if (edges === undefined) {
		if (!(current === null || current === undefined)) {
			return [toOption(current)];
		}
		return [];
	}
	return edges.map((e) => toOption(e.node));
}

/**
 * Converts the given `edges` to options to be given to a multi-select field.
 *
 * @param edges The edges to convert to options.
 * @param current The current instances for the field. Used to display when `edges` is blank (e.g. with lazy loading).
 */
export function toMultiOptions(edges?: EdgeForOption[], currents?: ModelForOption[]) {
	if (edges === undefined) {
		if (currents !== undefined) {
			return currents.map(toOption);
		}
		return [];
	}
	return edges.map((e) => toOption(e.node));
}

/**
 * Converts a single PhoneNumber instance to an option to be given to a radio, select, or
 * multi-select field.
 *
 * @param instance The instance to convert to an option.
 */
export function toOption(instance: ModelForOption): FieldInputOption<PhoneNumber["id"]> {
	return {
		label: PhoneNumberFormat.name(instance),
		value: instance.id,
	};
}

/**
 * Returns an `addToCache` function that accepts a single PhoneNumber instance and adds it to the
 * cache for the select query. Useful when allowing creating of PhoneNumber instance in other
 * models' forms.
 */
export function useAddToCache() {
	const client = useApolloClient();

	return React.useCallback(
		(phoneNumber: PhoneNumberCreateMutation["createPhoneNumber"]) => {
			const cacheResult = client.readQuery<PhoneNumberSelectQuery, PhoneNumberSelectQueryVariables>({
				query: PhoneNumberSelectDocument,
			});
			client.writeQuery<PhoneNumberSelectQuery, PhoneNumberSelectQueryVariables>({
				query: PhoneNumberSelectDocument,
				data: {
					phoneNumberConnection: {
						...cacheResult?.phoneNumberConnection,
						edges: [
							...(cacheResult?.phoneNumberConnection.edges ?? []),
							{
								__typename: graphTypeNames.PhoneNumberEdge,
								node: phoneNumber,
							},
						],
					},
				},
			});
		},
		[client],
	);
}
