import { IndexForms } from "@hex-insights/app-modules";
import { booleanTypeFilter, nullableDateStringFilter, TypeFilterObject } from "@hex-insights/core";

export type StandardFilterFormValues = {
	hasPaid: boolean;
	hasNotPaid: boolean;
	is20232024: boolean;
	createdDateGTE: string | null;
	paymentDueDateLTE: string | null;
};

export type FormValues = StandardFilterFormValues & IndexForms.SearchFormValues & IndexForms.FilterFormValues;

export const initialStandardFilters: StandardFilterFormValues = {
	hasPaid: false,
	hasNotPaid: false,
	is20232024: true,
	createdDateGTE: null,
	paymentDueDateLTE: null,
};

export const initial: FormValues = {
	...initialStandardFilters,
	...IndexForms.initialSearchFormValues,
	...IndexForms.initialFilterFormValues,
};

export function getInitial(initialFormValues?: Partial<FormValues>): FormValues {
	return { ...initial, ...initialFormValues };
}

export const standardFiltersTypeFilterObject: TypeFilterObject<StandardFilterFormValues> = {
	hasPaid: booleanTypeFilter(),
	hasNotPaid: booleanTypeFilter(),
	is20232024: booleanTypeFilter(),
	createdDateGTE: nullableDateStringFilter(),
	paymentDueDateLTE: nullableDateStringFilter(),
};

export const typeFilterObject: TypeFilterObject<FormValues> = {
	...standardFiltersTypeFilterObject,
	...IndexForms.searchFormValuesFilterObject,
	...IndexForms.filterFormValuesFilterObject,
};

export const advancedFilterInformation: IndexForms.AdvancedFilterInformation = {
	fieldOptions: [{ value: "createdDate" }, { value: "hasPaidFee" }, { value: "isVoided" }],
	fieldComparisonOptions: {
		createdDate: IndexForms.dateComparisonOptions,
		hasPaidFee: IndexForms.booleanComparisonOptions,
		isVoided: IndexForms.booleanComparisonOptions,
	},
	fieldComparisonTranslations: {
		createdDate: IndexForms.dateComparisonTranslations,
		hasPaidFee: IndexForms.booleanComparisonTranslations,
		isVoided: IndexForms.booleanComparisonTranslations,
	},
};
