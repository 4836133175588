import { Models } from "@hex-insights/app-modules";
import { DistributiveOmit } from "@hex-insights/core";
import { Address, AddressFormat, AddressNameFragment } from "../../../../Utilities";

type ModelForLink = Pick<Address, "id"> & AddressNameFragment;

export type AddressLinkProps = DistributiveOmit<Models.LinkProps<ModelForLink>, "pageInfoKey" | "format">;

/**
 * Renders a link to the given Address instance.
 */
export function AddressLink(props: AddressLinkProps) {
	return <Models.Link<ModelForLink> pageInfoKey="Address" format={AddressFormat.name} {...props} />;
}
