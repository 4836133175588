import { IndexForms } from "@hex-insights/app-modules";
import { CaseStyle, convertCase } from "@hex-insights/core";
import { OrderDirection, PersonOrderField } from "../../GraphQL";

export type FormValues = IndexForms.OrderFormValues<PersonOrderField | null>;

export const initial: FormValues = {
	field: PersonOrderField.FirstName,
	direction: OrderDirection.Asc,
};

/**
 * Options for the "field" field in the order form of the Person model.
 */
export const fieldOptions = [
	{ label: "ID", value: null },
	{
		label: convertCase(PersonOrderField.FirstName.toLowerCase(), CaseStyle.Title),
		value: PersonOrderField.FirstName,
	},
	{ label: convertCase(PersonOrderField.LastName.toLowerCase(), CaseStyle.Title), value: PersonOrderField.LastName },
	{ label: convertCase(PersonOrderField.Nickname.toLowerCase(), CaseStyle.Title), value: PersonOrderField.Nickname },
	{ label: convertCase(PersonOrderField.Gender.toLowerCase(), CaseStyle.Title), value: PersonOrderField.Gender },
	{
		label: convertCase(PersonOrderField.DateOfBirth.toLowerCase(), CaseStyle.Title),
		value: PersonOrderField.DateOfBirth,
	},
	{
		label: convertCase(PersonOrderField.Nationality.toLowerCase(), CaseStyle.Title),
		value: PersonOrderField.Nationality,
	},
	{
		label: convertCase(PersonOrderField.PrimaryLanguage.toLowerCase(), CaseStyle.Title),
		value: PersonOrderField.PrimaryLanguage,
	},
	{
		label: convertCase(PersonOrderField.EnglishLanguageFluency.toLowerCase(), CaseStyle.Title),
		value: PersonOrderField.EnglishLanguageFluency,
	},
];

/**
 * Array of values for the "field" field in the order form of the Person model.
 */
export const fieldValues = fieldOptions.map((e) => e.value);

/**
 * Mapping from order fields of the Person model to their corresponding order type.
 */
export const fieldTypeMapping = {
	[PersonOrderField.FirstName]: IndexForms.OrderFieldType.String,
	[PersonOrderField.LastName]: IndexForms.OrderFieldType.String,
	[PersonOrderField.Nickname]: IndexForms.OrderFieldType.String,
	[PersonOrderField.Gender]: IndexForms.OrderFieldType.String,
	[PersonOrderField.DateOfBirth]: IndexForms.OrderFieldType.Time,
	[PersonOrderField.Nationality]: IndexForms.OrderFieldType.String,
	[PersonOrderField.PrimaryLanguage]: IndexForms.OrderFieldType.String,
	[PersonOrderField.EnglishLanguageFluency]: IndexForms.OrderFieldType.String,
};

/**
 * Mapping from order fields of the Person model to their corresponding field name.
 */
export const orderFieldToModelField = {
	[PersonOrderField.FirstName]: "firstName",
	[PersonOrderField.LastName]: "lastName",
	[PersonOrderField.Nickname]: "nickname",
	[PersonOrderField.Gender]: "gender",
	[PersonOrderField.DateOfBirth]: "dateOfBirth",
	[PersonOrderField.Nationality]: "nationality",
	[PersonOrderField.PrimaryLanguage]: "primaryLanguage",
	[PersonOrderField.EnglishLanguageFluency]: "englishLanguageFluency",
};

/**
 * Mapping from field names of the Person model to their corresponding order field.
 */
export const modelFieldToOrderField = {
	firstName: PersonOrderField.FirstName,
	lastName: PersonOrderField.LastName,
	nickname: PersonOrderField.Nickname,
	gender: PersonOrderField.Gender,
	dateOfBirth: PersonOrderField.DateOfBirth,
	nationality: PersonOrderField.Nationality,
	primaryLanguage: PersonOrderField.PrimaryLanguage,
	englishLanguageFluency: PersonOrderField.EnglishLanguageFluency,
};
