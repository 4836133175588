import { pickKeys } from "@hex-insights/core";
import { RadioInputOption } from "@hex-insights/forms";
import {
	EnrollmentApplication,
	EnrollmentApplicationStudent,
	EnrollmentApplicationStudentCurrentGradeLevel,
	EnrollmentApplicationStudentEnglishLanguageFluency,
	EnrollmentApplicationStudentGender,
	EnrollmentApplicationStudentGradeLevelApplyingFor,
	EnrollmentApplicationSubmission,
} from "../../GraphQL";

export type Base = {
	formIdentifier: EnrollmentApplicationStudent["formIdentifier"];
	image: File | null;
	firstName: EnrollmentApplicationStudent["firstName"];
	lastName: EnrollmentApplicationStudent["lastName"];
	nickname: EnrollmentApplicationStudent["nickname"];
	dateOfBirth: EnrollmentApplicationStudent["dateOfBirth"] | null;
	gender: EnrollmentApplicationStudent["gender"] | null;
	genderCustom: EnrollmentApplicationStudent["genderCustom"];
	nationality: EnrollmentApplicationStudent["nationality"];
	primaryLanguage: EnrollmentApplicationStudent["primaryLanguage"];
	englishLanguageFluency: EnrollmentApplicationStudent["englishLanguageFluency"] | null;
	currentGradeLevel: EnrollmentApplicationStudent["currentGradeLevel"] | null;
	gradeLevelApplyingFor: EnrollmentApplicationStudent["gradeLevelApplyingFor"] | null;
	hasPreviousSchooling: EnrollmentApplicationStudent["hasPreviousSchooling"];
	previousSchoolInformation: EnrollmentApplicationStudent["previousSchoolInformation"];
	previousSchoolLocation: EnrollmentApplicationStudent["previousSchoolLocation"];
	hasLearningDifficulties: EnrollmentApplicationStudent["hasLearningDifficulties"];
	learningDifficultiesDescription: EnrollmentApplicationStudent["learningDifficultiesDescription"];
	hasMedicalPhysicalExceptionalities: EnrollmentApplicationStudent["hasMedicalPhysicalExceptionalities"];
	medicalPhysicalExceptionalitiesDescription: EnrollmentApplicationStudent["medicalPhysicalExceptionalitiesDescription"];
	hasBehaviorDisciplineChallenges: EnrollmentApplicationStudent["hasBehaviorDisciplineChallenges"];
	behaviorDisciplineChallengesDescription: EnrollmentApplicationStudent["behaviorDisciplineChallengesDescription"];
	additionalInformation: EnrollmentApplicationStudent["additionalInformation"];
	enrollmentApplicationID: EnrollmentApplication["id"] | null;
	enrollmentApplicationSubmissionID: EnrollmentApplicationSubmission["id"] | null;
};

export type Create = Base;

export type EnrollmentApplicationCreate = Omit<
	Create,
	"nationality" | "primaryLanguage" | "enrollmentApplicationID" | "enrollmentApplicationSubmissionID"
> & {
	nationalityList: string[];
	primaryLanguageList: string[];
	interviewMeetingTime: string | null;
};

export type EnrollmentApplicationCreateBasic = Pick<
	EnrollmentApplicationCreate,
	| "image"
	| "firstName"
	| "lastName"
	| "nickname"
	| "dateOfBirth"
	| "gender"
	| "genderCustom"
	| "nationalityList"
	| "primaryLanguageList"
	| "englishLanguageFluency"
	| "currentGradeLevel"
	| "gradeLevelApplyingFor"
	| "hasPreviousSchooling"
	| "previousSchoolInformation"
	| "previousSchoolLocation"
>;

export type EnrollmentApplicationCreatePersonality = Pick<
	EnrollmentApplicationCreate,
	| "hasLearningDifficulties"
	| "learningDifficultiesDescription"
	| "hasMedicalPhysicalExceptionalities"
	| "medicalPhysicalExceptionalitiesDescription"
	| "hasBehaviorDisciplineChallenges"
	| "behaviorDisciplineChallengesDescription"
	| "additionalInformation"
>;

export type EnrollmentApplicationCreateInterview = Pick<EnrollmentApplicationCreate, "interviewMeetingTime">;

export type Detail = Base & {
	name: EnrollmentApplicationStudent["name"];
};

export const initialCreate: Create = {
	formIdentifier: "",
	image: null,
	firstName: "",
	lastName: "",
	nickname: "",
	dateOfBirth: null,
	gender: null,
	genderCustom: "",
	nationality: "",
	primaryLanguage: "",
	englishLanguageFluency: null,
	currentGradeLevel: null,
	gradeLevelApplyingFor: null,
	hasPreviousSchooling: false,
	previousSchoolInformation: "",
	previousSchoolLocation: "",
	hasLearningDifficulties: false,
	learningDifficultiesDescription: "",
	hasMedicalPhysicalExceptionalities: false,
	medicalPhysicalExceptionalitiesDescription: "",
	hasBehaviorDisciplineChallenges: false,
	behaviorDisciplineChallengesDescription: "",
	additionalInformation: "",
	enrollmentApplicationID: null,
	enrollmentApplicationSubmissionID: null,
};

export const initialEnrollmentApplicationCreateBasic: EnrollmentApplicationCreateBasic = {
	...pickKeys(initialCreate, [
		"image",
		"firstName",
		"lastName",
		"nickname",
		"dateOfBirth",
		"gender",
		"genderCustom",
		"englishLanguageFluency",
		"currentGradeLevel",
		"gradeLevelApplyingFor",
		"hasPreviousSchooling",
		"previousSchoolInformation",
		"previousSchoolLocation",
	]),
	nationalityList: [],
	primaryLanguageList: [],
};

export const initialEnrollmentApplicationCreatePersonality: EnrollmentApplicationCreatePersonality = pickKeys(
	initialCreate,
	[
		"hasLearningDifficulties",
		"learningDifficultiesDescription",
		"hasMedicalPhysicalExceptionalities",
		"medicalPhysicalExceptionalitiesDescription",
		"hasBehaviorDisciplineChallenges",
		"behaviorDisciplineChallengesDescription",
		"additionalInformation",
	],
);

export const initialEnrollmentApplicationCreateInterview: EnrollmentApplicationCreateInterview = {
	interviewMeetingTime: null,
};

export const initialEnrollmentApplicationCreate: EnrollmentApplicationCreate = {
	formIdentifier: initialCreate.formIdentifier,
	...initialEnrollmentApplicationCreateBasic,
	...initialEnrollmentApplicationCreatePersonality,
	...initialEnrollmentApplicationCreateInterview,
};

export const currentGradeLevelOptions = [
	{ value: EnrollmentApplicationStudentCurrentGradeLevel.None, label: "None/Unknown" },
	{ value: EnrollmentApplicationStudentCurrentGradeLevel.Nursery, label: "Nursery (2½-4 years)" },
	{ value: EnrollmentApplicationStudentCurrentGradeLevel.Reception, label: "Reception (4-5 years)" },
	{ value: EnrollmentApplicationStudentCurrentGradeLevel.Year1, label: "Year 1 (5-6 years)" },
	{ value: EnrollmentApplicationStudentCurrentGradeLevel.Year2, label: "Year 2 (6-7 years)" },
	{ value: EnrollmentApplicationStudentCurrentGradeLevel.Year3, label: "Year 3 (7-8 years)" },
	{ value: EnrollmentApplicationStudentCurrentGradeLevel.Year4, label: "Year 4 (8-9 years)" },
	{ value: EnrollmentApplicationStudentCurrentGradeLevel.Year5, label: "Year 5 (9-10 years)" },
	{ value: EnrollmentApplicationStudentCurrentGradeLevel.Year6, label: "Year 6 (10-11 years)" },
	{ value: EnrollmentApplicationStudentCurrentGradeLevel.Year7, label: "Year 7 (11-12 years)" },
];

export const englishLanguageFluencyOptions = [
	{ value: EnrollmentApplicationStudentEnglishLanguageFluency.None },
	{ value: EnrollmentApplicationStudentEnglishLanguageFluency.Basic },
	{ value: EnrollmentApplicationStudentEnglishLanguageFluency.Average },
	{ value: EnrollmentApplicationStudentEnglishLanguageFluency.Excellent },
];

export const genderOptions = [
	{ value: EnrollmentApplicationStudentGender.Female },
	{ value: EnrollmentApplicationStudentGender.Male },
	{ value: EnrollmentApplicationStudentGender.Other },
];

export const gradeLevelApplyingForOptions = [
	{ value: EnrollmentApplicationStudentGradeLevelApplyingFor.Nursery, label: "Nursery (2½-4 years)" },
	{ value: EnrollmentApplicationStudentGradeLevelApplyingFor.Reception, label: "Reception (4-5 years)" },
	{ value: EnrollmentApplicationStudentGradeLevelApplyingFor.Year1, label: "Year 1 (5-6 years)" },
	{ value: EnrollmentApplicationStudentGradeLevelApplyingFor.Year2, label: "Year 2 (6-7 years)" },
	{ value: EnrollmentApplicationStudentGradeLevelApplyingFor.Year3, label: "Year 3 (7-8 years)" },
	{ value: EnrollmentApplicationStudentGradeLevelApplyingFor.Year4, label: "Year 4 (8-9 years)" },
	{ value: EnrollmentApplicationStudentGradeLevelApplyingFor.Year5, label: "Year 5 (9-10 years)" },
	{ value: EnrollmentApplicationStudentGradeLevelApplyingFor.Year6, label: "Year 6 (10-11 years)" },
	{ value: EnrollmentApplicationStudentGradeLevelApplyingFor.Year7, label: "Year 7 (11-12 years)" },
	{ value: EnrollmentApplicationStudentGradeLevelApplyingFor.Year8, label: "Year 8 (12-13 years)" },
];

export const yesNoOptions: RadioInputOption<boolean>[] = [
	{ value: true, label: "Yes" },
	{ value: false, label: "No" },
];
