import { IndexForms } from "@hex-insights/app-modules";
import { CaseStyle, convertCase } from "@hex-insights/core";
import { ExpenseCategoryOrderField, OrderDirection } from "../../GraphQL";

export type FormValues = IndexForms.OrderFormValues<ExpenseCategoryOrderField | null>;

export const initial: FormValues = {
	field: ExpenseCategoryOrderField.Name,
	direction: OrderDirection.Asc,
};

/**
 * Options for the "field" field in the order form of the ExpenseCategory model.
 */
export const fieldOptions = [
	{ label: "ID", value: null },
	{
		label: convertCase(ExpenseCategoryOrderField.Name.toLowerCase(), CaseStyle.Title),
		value: ExpenseCategoryOrderField.Name,
	},
];

/**
 * Array of values for the "field" field in the order form of the ExpenseCategory model.
 */
export const fieldValues = fieldOptions.map((e) => e.value);

/**
 * Mapping from order fields of the ExpenseCategory model to their corresponding order type.
 */
export const fieldTypeMapping = {
	[ExpenseCategoryOrderField.Name]: IndexForms.OrderFieldType.String,
};

/**
 * Mapping from order fields of the ExpenseCategory model to their corresponding field name.
 */
export const orderFieldToModelField = {
	[ExpenseCategoryOrderField.Name]: "name",
};

/**
 * Mapping from field names of the ExpenseCategory model to their corresponding order field.
 */
export const modelFieldToOrderField = {
	name: ExpenseCategoryOrderField.Name,
};
