import { formatDateTime } from "@hex-insights/core";
import { Invoice } from "../../../GraphQL";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the invoiceNumber field of the Invoice model.
 */
export function invoiceNumber(value: Invoice["invoiceNumber"]): string {
	return toString(value);
}

/**
 * Formats the createdDate field of the Invoice model.
 */
export function createdDate(value: Invoice["createdDate"] | null): string {
	if (value === null) {
		return toString(value);
	}
	return formatDateTime(value, "MMMM Do, YYYY");
}

/**
 * Formats the dueDate field of the Invoice model.
 */
export function dueDate(value: Invoice["dueDate"] | null): string {
	if (value === null) {
		return toString(value);
	}
	return formatDateTime(value, "MMMM Do, YYYY");
}

/**
 * Formats the description field of the Invoice model.
 */
export function description(value: Invoice["description"]): string {
	return toString(value);
}
