import { Heading } from "@hex-insights/core";
import { InternalLink, useActivePageRegistration } from "@hex-insights/router";
import { Main } from "@hex-insights/verita.shared";
import { reportCardsTermPageInfo } from "../Term/pageinfo";
import { reportCardsIndexPageInfo } from "./pageinfo";

const currentTermID = "279172874240";

export function ReportCardsIndexPage() {
	useActivePageRegistration(reportCardsIndexPageInfo);

	return (
		<Main>
			<Heading level={1}>Report Cards</Heading>

			<InternalLink to={reportCardsTermPageInfo.to(currentTermID)}>Current Term</InternalLink>
		</Main>
	);
}
