import React from "react";
import { Button, Conditional, Icon, If, Modal, Row } from "@hex-insights/core";
import { FinancialNoteFormModal, GeneralNoteFormModal } from "../../../../../Components";
import { NoteNoteType, Student } from "../../../../../Utilities";
import styles from "./styles.module.css";

type NewSimpleNoteControllerProps = {
	studentID: Student["id"];
	noteType: NoteNoteType.General | NoteNoteType.Financial;
};

export function NewSimpleNoteController({ studentID, noteType }: NewSimpleNoteControllerProps) {
	const { isModalOpen, toggleIsModalOpen } = Modal.useToggle(false);

	return (
		<React.Fragment>
			<div>
				<Button onClick={toggleIsModalOpen} className={styles["new-general-note-button"]} title="Write a note">
					<Row justify="center" align="center" style={{ width: "100%", height: "100%" }}>
						<Icon.FilePlus size="3rem" className={styles["new-general-note-button__icon"]} />
					</Row>
				</Button>
			</div>

			<If condition={isModalOpen}>
				<Conditional>
					<Modal.If condition={noteType === NoteNoteType.General}>
						<GeneralNoteFormModal studentID={studentID} body="" setBody={doNothing} onClose={toggleIsModalOpen} />
					</Modal.If>
					<Modal.If condition={noteType === NoteNoteType.Financial}>
						<FinancialNoteFormModal studentID={studentID} body="" setBody={doNothing} onClose={toggleIsModalOpen} />
					</Modal.If>
				</Conditional>
			</If>
		</React.Fragment>
	);
}

function doNothing() {}
