import { Models } from "@hex-insights/app-modules";
import { Note, NoteMutation } from "../../../../Utilities";

export type NoteDeleteButtonProps = Omit<Models.DeleteButtonProps, "deleteInstance"> & {
	instanceID: Note["id"];
};

/**
 * A delete button for the Note model.
 */
export function NoteDeleteButton({
	instanceID,
	confirmationContent = "Are you sure you want to delete this note?",
	children = "Delete Note",
	...props
}: NoteDeleteButtonProps) {
	const del = NoteMutation.useDelete(instanceID);

	return (
		<Models.DeleteButton deleteInstance={del} confirmationContent={confirmationContent} {...props}>
			{children}
		</Models.DeleteButton>
	);
}
