import { CaseStyle, convertCase } from "@hex-insights/core";
import { Note } from "../../../GraphQL";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the noteType field of the Note model.
 */
export function noteType(value: Note["noteType"] | null): string {
	if (value === null) {
		return toString(value);
	}
	return convertCase(value, CaseStyle.Title);
}

/**
 * Formats the title field of the Note model.
 */
export function title(value: Note["title"]): string {
	return toString(value);
}

/**
 * Formats the body field of the Note model.
 */
export function body(value: Note["body"]): string {
	return toString(value);
}

/**
 * Formats the createdAt field of the Note model.
 */
export function createdAt(value: Note["createdAt"] | null): string {
	return toString(value);
}

/**
 * Formats the isTimeDependent field of the Note model.
 */
export function isTimeDependent(value: Note["isTimeDependent"]): string {
	return toString(value);
}

/**
 * Formats the startDate field of the Note model.
 */
export function startDate(value: Note["startDate"] | null): string {
	return toString(value);
}

/**
 * Formats the endDate field of the Note model.
 */
export function endDate(value: Note["endDate"] | null): string {
	return toString(value);
}
