import { omitKeys, toLocalDateString } from "@hex-insights/core";
import { Parent, Payment, PaymentLineItem, PaymentPaymentMethod } from "../../GraphQL";
import * as PaymentLineItemFormValues from "./payment-line-item";

export type Base = {
	parentID: Parent["id"] | null;
	createdDate: Payment["createdDate"] | null;
	paymentMethod: Payment["paymentMethod"] | null;
	notes: Payment["notes"];
};

export type Create = Base & {
	paymentLineItems: Omit<PaymentLineItemFormValues.Create, "paymentID">[];
};

export type Detail = Base & {
	paymentLineItemIDs: PaymentLineItem["id"][];
};

export const blankPaymentLineItem: Omit<PaymentLineItemFormValues.Create, "paymentID"> = omitKeys(
	PaymentLineItemFormValues.initialCreate,
	["paymentID"],
);

export const initialCreate: Create = {
	parentID: null,
	createdDate: null,
	paymentMethod: null,
	notes: "",
	paymentLineItems: [blankPaymentLineItem],
};

export function getInitialCreate(): Create {
	return {
		...initialCreate,
		createdDate: toLocalDateString(new Date()),
	};
}

export const paymentMethodOptions = [
	{ value: PaymentPaymentMethod.BankTransfer },
	{ value: PaymentPaymentMethod.Cash },
	{ value: PaymentPaymentMethod.Check },
	{ value: PaymentPaymentMethod.CreditCard },
];
