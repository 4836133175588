import React from "react";
import { ApplyCreateFunction, ApplyUpdateFunction, onCreateSubmit, onUpdateSubmit } from "@hex-insights/app-modules";
import { Button, Column, Heading, HeadingLevel, If, Row } from "@hex-insights/core";
import {
	anyFieldEditing,
	everyFieldEditing,
	FieldIf,
	Form,
	FormState,
	FormSubmitFunction,
	FormType,
	Label,
	SubmissionStatus,
	SubmitButton,
	useInternalField,
} from "@hex-insights/forms";
import {
	PersonFormValues,
	StudentDetailQuery,
	StudentFormConversion,
	StudentFormState,
	StudentFormValues,
	useClassYearIndexQuery,
} from "../../../../Utilities";
import { PersonForm } from "../Person";
import * as Field from "./Field";

const previousSchoolConditionalFields: (keyof Pick<
	StudentFormValues.Base,
	"previousSchoolInformation" | "previousSchoolLocation"
>)[] = ["previousSchoolInformation", "previousSchoolLocation"];

export type ControlledCreateProps = {
	applyCreate: ApplyCreateFunction<StudentFormValues.Create>;
	onSuccess: () => void;
	headingLevel?: HeadingLevel;
};

/**
 * Renders the create form of the Student model using an internally managed form state.
 */
export function ControlledCreate(props: ControlledCreateProps) {
	const formState = StudentFormState.useCreateFormState();

	return <Create formState={formState} {...props} />;
}

export type CreateProps = ControlledCreateProps & {
	formState: FormState<StudentFormValues.Create>;
};

/**
 * Renders the create form of the Student model using the given form state.
 */
export function Create({ formState, applyCreate, onSuccess, headingLevel = 2 }: CreateProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<StudentFormValues.Create>>(
		async ({ formValues }) => {
			return onCreateSubmit(formValues, applyCreate);
		},
		[applyCreate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	return (
		<Form name="student.create" formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)}>
			<Column justify="spaced-start">
				<Field.Person formState={formState} formType={FormType.Create} />
				<ClassYearSelect formState={formState} />
				<Field.GraduatingYear formState={formState} formType={FormType.Create} />
				<Field.Tuition formState={formState} formType={FormType.Create} />

				<Heading level={headingLevel} noMargin>
					Previous Schooling
				</Heading>
				<Field.HasPreviousSchooling formState={formState} formType={FormType.Create} />
				<FieldIf
					formState={formState}
					names={previousSchoolConditionalFields}
					condition={formState.formValues.hasPreviousSchooling}
				>
					<Field.PreviousSchoolInformation formState={formState} formType={FormType.Create} />
					<Field.PreviousSchoolLocation formState={formState} formType={FormType.Create} />
				</FieldIf>

				<Heading level={headingLevel} noMargin>
					Health Information
				</Heading>
				<Field.Allergies formState={formState} formType={FormType.Create} />
				<Field.Asthma formState={formState} formType={FormType.Create} />
				<Field.Medication formState={formState} formType={FormType.Create} />
				<Field.MedicationSchedule formState={formState} formType={FormType.Create} />
				<Field.DietaryRestrictions formState={formState} formType={FormType.Create} />
				<Field.HealthNotes formState={formState} formType={FormType.Create} />
				<Field.DoctorName formState={formState} formType={FormType.Create} />
				<Field.DoctorPhoneNumber formState={formState} formType={FormType.Create} />
				<Field.EmergencyContactInformation formState={formState} formType={FormType.Create} />

				<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
					Submit
				</SubmitButton>
			</Column>
		</Form>
	);
}

export type ControlledCreateWithPersonProps = {
	applyCreate: ApplyCreateFunction<StudentFormValues.CreateWithPerson>;
	onSuccess: () => void;
	headingLevel?: HeadingLevel;
};

/**
 * Renders the create form of the Student model using an internally managed form state.
 */
export function ControlledCreateWithPerson(props: ControlledCreateWithPersonProps) {
	const formState = StudentFormState.useCreateWithPersonFormState();

	return <CreateWithPerson formState={formState} {...props} />;
}

const hiddenPersonCreateFields: (keyof PersonFormValues.CreateInOther)[] = [
	"addressIDs",
	"emailAddresses",
	"phoneNumbers",
	"userID",
];

const hiddenPersonDemographicFields: (keyof Pick<PersonFormValues.CreateInOther, "occupation">)[] = ["occupation"];

export type CreateWithPersonProps = ControlledCreateWithPersonProps & {
	formState: FormState<StudentFormValues.CreateWithPerson>;
};

export function CreateWithPerson({ formState, applyCreate, onSuccess, headingLevel = 2 }: CreateWithPersonProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<StudentFormValues.CreateWithPerson>>(
		async ({ formValues }) => {
			return onCreateSubmit(formValues, applyCreate);
		},
		[applyCreate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	useInternalField(formState, hiddenPersonCreateFields);

	return (
		<Form name="employee.create.withPerson" formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)}>
			<Column justify="spaced-start">
				<PersonForm.GeneralCreateFields formState={formState} headingLevel={headingLevel} />
				<PersonForm.DemographicCreateFields
					formState={formState}
					headingLevel={headingLevel}
					hiddenFields={hiddenPersonDemographicFields}
				/>

				<Heading level={headingLevel} noMargin>
					Student Details
				</Heading>
				<ClassYearSelect formState={formState} />
				<Field.GraduatingYear formState={formState} formType={FormType.Create} />
				<Field.Tuition formState={formState} formType={FormType.Create} />

				<Heading level={headingLevel} noMargin>
					Previous Schooling
				</Heading>
				<Field.HasPreviousSchooling formState={formState} formType={FormType.Create} />
				<FieldIf
					formState={formState}
					names={previousSchoolConditionalFields}
					condition={formState.formValues.hasPreviousSchooling}
				>
					<Field.PreviousSchoolInformation formState={formState} formType={FormType.Create} />
					<Field.PreviousSchoolLocation formState={formState} formType={FormType.Create} />
				</FieldIf>

				<Heading level={headingLevel} noMargin>
					Health Information
				</Heading>
				<Field.Allergies formState={formState} formType={FormType.Create} />
				<Field.Asthma formState={formState} formType={FormType.Create} />
				<Field.Medication formState={formState} formType={FormType.Create} />
				<Field.MedicationSchedule formState={formState} formType={FormType.Create} />
				<Field.DietaryRestrictions formState={formState} formType={FormType.Create} />
				<Field.HealthNotes formState={formState} formType={FormType.Create} />
				<Field.DoctorName formState={formState} formType={FormType.Create} />
				<Field.DoctorPhoneNumber formState={formState} formType={FormType.Create} />
				<Field.EmergencyContactInformation formState={formState} formType={FormType.Create} />

				<Row justify="flex-end">
					<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
						Submit
					</SubmitButton>
				</Row>
			</Column>
		</Form>
	);
}

type ClassYearSelectProps = {
	formState: FormState<Pick<StudentFormValues.Create, "graduatingYear">>;
};

function ClassYearSelect({ formState }: ClassYearSelectProps) {
	const { loading, data } = useClassYearIndexQuery();

	if (loading) {
		return <div>Loading...</div>;
	}

	const yearsFromGraduation = getYearsFromGraduation(formState.formValues.graduatingYear ?? 0);

	return (
		<Column style={{ width: "var(--general__field---width)" }}>
			<Label>Current Class Year</Label>
			<Row justify="spaced-start" overflow="wrap">
				{data?.classYearConnection.edges.map((e) => {
					const graduatingYear = getGraduatingYear(e.node.yearsFromGraduation);
					return (
						<Button
							key={e.node.id}
							variant={e.node.yearsFromGraduation === yearsFromGraduation ? "primary" : "tertiary"}
							size="small"
							onClick={() => formState.formSetFunctions.graduatingYear(graduatingYear)}
							style={{ marginBottom: "0.5rem" }}
						>
							{e.node.name}
						</Button>
					);
				})}
			</Row>
		</Column>
	);
}

function getGraduatingYear(yearsFromGraduation: number) {
	const today = new Date();
	return today.getFullYear() + yearsFromGraduation + (today.getMonth() >= 6 ? 1 : 0);
}

function getYearsFromGraduation(graduatingYear: number) {
	const today = new Date();
	return graduatingYear - today.getFullYear() - (today.getMonth() >= 6 ? 1 : 0);
}

export type ControlledDetailProps = {
	student: StudentDetailQuery["student"];
	applyUpdate: ApplyUpdateFunction<StudentFormValues.Detail>;
	onSuccess: () => void;
	headingLevel?: HeadingLevel;
};

/**
 * Renders the detail form of the Student model using an internally managed form state.
 */
export function ControlledDetail(props: ControlledDetailProps) {
	const initialFormValues = React.useMemo(() => StudentFormConversion.toFormValues(props.student), [props.student]);
	const formState = StudentFormState.useDetailFormState({ initialFormValues });

	return <Detail formState={formState} {...props} />;
}

export type DetailProps = ControlledDetailProps & {
	formState: FormState<StudentFormValues.Detail>;
};

/**
 * Renders the detail form of the Student model using the given form state.
 */
export function Detail({ formState, student, applyUpdate, onSuccess, headingLevel = 2 }: DetailProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<StudentFormValues.Detail>>(
		async (formState) => {
			return onUpdateSubmit(formState, applyUpdate);
		},
		[applyUpdate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	const { anyEditing, everyEditing } = React.useMemo(() => {
		return {
			anyEditing: anyFieldEditing({
				formEditing: formState.formEditing,
				formSubFormStates: formState.formSubFormStates,
			}),
			everyEditing: everyFieldEditing({
				formEditing: formState.formEditing,
				formSubFormStates: formState.formSubFormStates,
			}),
		};
	}, [formState.formEditing, formState.formSubFormStates]);

	return (
		<Form name={`student.detail.${student.id}`} formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)}>
			<Column justify="spaced-start">
				<Row justify="spaced-start" horizontalSpacing="0.75rem">
					<If condition={!everyEditing}>
						<Button variant="tertiary" size="small" onClick={() => formState.setFormEditing(true)}>
							Edit
						</Button>
					</If>
					<If condition={anyEditing}>
						<Button variant="tertiary" size="small" onClick={() => formState.setFormEditing(false)}>
							Stop Editing
						</Button>
					</If>
				</Row>

				<Field.Person formState={formState} formType={FormType.Update} id={student.id} currentPerson={student.person} />
				<Field.StudentIDNumber formState={formState} formType={FormType.Update} id={student.id} />
				<Field.GraduatingYear formState={formState} formType={FormType.Update} id={student.id} />
				<Field.Tuition
					formState={formState}
					formType={FormType.Update}
					id={student.id}
					currentTuition={student.tuition}
				/>

				<Heading level={headingLevel} noMargin>
					Previous Schooling
				</Heading>
				<Field.HasPreviousSchooling formState={formState} formType={FormType.Update} id={student.id} />
				<FieldIf
					formState={formState}
					names={previousSchoolConditionalFields}
					condition={formState.formValues.hasPreviousSchooling}
				>
					<Field.PreviousSchoolInformation formState={formState} formType={FormType.Update} id={student.id} />
					<Field.PreviousSchoolLocation formState={formState} formType={FormType.Update} id={student.id} />
				</FieldIf>

				<Heading level={headingLevel} noMargin>
					Health Information
				</Heading>
				<Field.Allergies formState={formState} formType={FormType.Update} id={student.id} />
				<Field.Asthma formState={formState} formType={FormType.Update} id={student.id} />
				<Field.Medication formState={formState} formType={FormType.Update} id={student.id} />
				<Field.MedicationSchedule formState={formState} formType={FormType.Update} id={student.id} />
				<Field.DietaryRestrictions formState={formState} formType={FormType.Update} id={student.id} />
				<Field.HealthNotes formState={formState} formType={FormType.Update} id={student.id} />
				<Field.DoctorName formState={formState} formType={FormType.Update} id={student.id} />
				<Field.DoctorPhoneNumber formState={formState} formType={FormType.Update} id={student.id} />
				<Field.EmergencyContactInformation formState={formState} formType={FormType.Update} id={student.id} />
			</Column>
		</Form>
	);
}

export type ControlledDetailWithPersonProps = {
	student: StudentDetailQuery["student"];
	applyUpdate: ApplyUpdateFunction<StudentFormValues.DetailWithPerson>;
	onSuccess: () => void;
	headingLevel?: HeadingLevel;
};

/**
 * Renders the detail form of the Student model using an internally managed form state.
 */
export function ControlledDetailWithPerson(props: ControlledDetailWithPersonProps) {
	const initialFormValues = React.useMemo(
		() => StudentFormConversion.toFormValuesWithPerson(props.student),
		[props.student],
	);
	const formState = StudentFormState.useDetailWithPersonFormState({ initialFormValues });

	return <DetailWithPerson formState={formState} {...props} />;
}

const hiddenPersonDetailFields: (keyof PersonFormValues.DetailInOther)[] = [
	"addressIDs",
	"emailAddressIDs",
	"phoneNumberIDs",
	"userID",
];

export type DetailWithPersonProps = ControlledDetailWithPersonProps & {
	formState: FormState<StudentFormValues.DetailWithPerson>;
};

/**
 * Renders the detail form of the Student model using the given form state.
 */
export function DetailWithPerson({
	formState,
	student,
	applyUpdate,
	onSuccess,
	headingLevel = 2,
}: DetailWithPersonProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<StudentFormValues.DetailWithPerson>>(
		async (formState) => {
			return onUpdateSubmit(formState, applyUpdate);
		},
		[applyUpdate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	const { anyEditing, everyEditing } = React.useMemo(() => {
		return {
			anyEditing: anyFieldEditing({
				formEditing: formState.formEditing,
				formSubFormStates: formState.formSubFormStates,
			}),
			everyEditing: everyFieldEditing({
				formEditing: formState.formEditing,
				formSubFormStates: formState.formSubFormStates,
			}),
		};
	}, [formState.formEditing, formState.formSubFormStates]);

	useInternalField(formState, hiddenPersonDetailFields);

	return (
		<Form name={`student.detail.${student.id}`} formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)}>
			<Column justify="spaced-start">
				<Row justify="spaced-start" horizontalSpacing="0.75rem">
					<If condition={!everyEditing}>
						<Button variant="tertiary" size="small" onClick={() => formState.setFormEditing(true)}>
							Edit
						</Button>
					</If>
					<If condition={anyEditing}>
						<Button variant="tertiary" size="small" onClick={() => formState.setFormEditing(false)}>
							Stop Editing
						</Button>
					</If>
				</Row>

				<PersonForm.GeneralDetailFields person={student.person} formState={formState} headingLevel={headingLevel} />
				<PersonForm.DemographicDetailFields
					person={student.person}
					formState={formState}
					headingLevel={headingLevel}
					hiddenFields={hiddenPersonDemographicFields}
				/>

				<Heading level={headingLevel} noMargin>
					Student Details
				</Heading>
				<Field.StudentIDNumber formState={formState} formType={FormType.Update} id={student.id} />
				<Field.GraduatingYear formState={formState} formType={FormType.Update} id={student.id} />
				<Field.Tuition
					formState={formState}
					formType={FormType.Update}
					id={student.id}
					currentTuition={student.tuition}
				/>

				<Heading level={headingLevel} noMargin>
					Previous Schooling
				</Heading>
				<Field.HasPreviousSchooling formState={formState} formType={FormType.Update} id={student.id} />
				<FieldIf
					formState={formState}
					names={previousSchoolConditionalFields}
					condition={formState.formValues.hasPreviousSchooling}
				>
					<Field.PreviousSchoolInformation formState={formState} formType={FormType.Update} id={student.id} />
					<Field.PreviousSchoolLocation formState={formState} formType={FormType.Update} id={student.id} />
				</FieldIf>

				<Heading level={headingLevel} noMargin>
					Health Information
				</Heading>
				<Field.Allergies formState={formState} formType={FormType.Update} id={student.id} />
				<Field.Asthma formState={formState} formType={FormType.Update} id={student.id} />
				<Field.Medication formState={formState} formType={FormType.Update} id={student.id} />
				<Field.MedicationSchedule formState={formState} formType={FormType.Update} id={student.id} />
				<Field.DietaryRestrictions formState={formState} formType={FormType.Update} id={student.id} />
				<Field.HealthNotes formState={formState} formType={FormType.Update} id={student.id} />
				<Field.DoctorName formState={formState} formType={FormType.Update} id={student.id} />
				<Field.DoctorPhoneNumber formState={formState} formType={FormType.Update} id={student.id} />
				<Field.EmergencyContactInformation formState={formState} formType={FormType.Update} id={student.id} />
			</Column>
		</Form>
	);
}

export type ControlledReadOnlyProps = {
	student: StudentDetailQuery["student"];
	headingLevel?: HeadingLevel;
};

/**
 * Renders a read-only detail form of the Student model using an internally managed form state.
 */
export function ControlledReadOnly(props: ControlledReadOnlyProps) {
	const initialFormValues = React.useMemo(() => StudentFormConversion.toFormValues(props.student), [props.student]);
	const formState = StudentFormState.useReadOnlyFormState({ initialFormValues });

	return <ReadOnly formState={formState} {...props} />;
}

export type ReadOnlyProps = ControlledReadOnlyProps & {
	formState: FormState<StudentFormValues.Detail>;
};

/**
 * Renders a read-only detail form of the Student model using the given form state.
 */
export function ReadOnly({ formState, student, headingLevel = 2 }: ReadOnlyProps) {
	return (
		<Column justify="spaced-start">
			<Field.Person formState={formState} formType={FormType.Update} id={student.id} currentPerson={student.person} />
			<Field.StudentIDNumber formState={formState} formType={FormType.Update} id={student.id} />
			<Field.GraduatingYear formState={formState} formType={FormType.Update} id={student.id} />
			<Field.Tuition
				formState={formState}
				formType={FormType.Update}
				id={student.id}
				currentTuition={student.tuition}
			/>

			<Heading level={headingLevel} noMargin>
				Previous Schooling
			</Heading>
			<Field.HasPreviousSchooling formState={formState} formType={FormType.Update} id={student.id} />
			<FieldIf
				formState={formState}
				names={previousSchoolConditionalFields}
				condition={formState.formValues.hasPreviousSchooling}
			>
				<Field.PreviousSchoolInformation formState={formState} formType={FormType.Update} id={student.id} />
				<Field.PreviousSchoolLocation formState={formState} formType={FormType.Update} id={student.id} />
			</FieldIf>

			<Heading level={headingLevel} noMargin>
				Health Information
			</Heading>
			<Field.Allergies formState={formState} formType={FormType.Update} id={student.id} />
			<Field.Asthma formState={formState} formType={FormType.Update} id={student.id} />
			<Field.Medication formState={formState} formType={FormType.Update} id={student.id} />
			<Field.MedicationSchedule formState={formState} formType={FormType.Update} id={student.id} />
			<Field.DietaryRestrictions formState={formState} formType={FormType.Update} id={student.id} />
			<Field.HealthNotes formState={formState} formType={FormType.Update} id={student.id} />
			<Field.DoctorName formState={formState} formType={FormType.Update} id={student.id} />
			<Field.DoctorPhoneNumber formState={formState} formType={FormType.Update} id={student.id} />
			<Field.EmergencyContactInformation formState={formState} formType={FormType.Update} id={student.id} />
		</Column>
	);
}
