import { Address, Person } from "../../GraphQL";

export type Base = {
	addressLine1: Address["addressLine1"];
	addressLine2: Address["addressLine2"];
	city: Address["city"];
	postalCode: Address["postalCode"];
	stateOrProvince: Address["stateOrProvince"];
	country: Address["country"];
};

export type Create = Base;

export type Detail = Base & {
	shortAddress: Address["shortAddress"];
	fullAddress: Address["fullAddress"];
	personIDs: Person["id"][];
};

export const initialCreate: Create = {
	addressLine1: "",
	addressLine2: "",
	city: "",
	postalCode: "",
	stateOrProvince: "",
	country: "",
};
