import React from "react";
import { ApolloCache, FetchResult, gql } from "@apollo/client";
import {
	Contract,
	ContractCreateInput,
	ContractCreateMutation,
	ContractDeleteMutation,
	ContractDetailDocument,
	ContractDetailQuery,
	ContractDetailQueryVariables,
	ContractInstallment,
	ContractUpdateInput,
	ContractUpdateMutation,
	graphTypeNames,
	ServiceSubscription,
	Student,
	useContractCreateMutation,
	useContractDeleteMutation,
	useContractUpdateMutation,
} from "../../GraphQL";
import { ContractFormConversion } from "../ModelFormConversion";
import { ContractFormValues } from "../ModelFormValues";

/**
 * Returns a `create` function for the Contract model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the Contract model.
 */
export function useCreate() {
	const [createContract] = useContractCreateMutation();

	return React.useCallback(
		async (formValues: ContractFormValues.Create) => {
			const input = ContractFormConversion.toGQLCreateInput(formValues);
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createContract({ variables: { input }, update: updateCache });

			return { data: data?.createContract ?? null, errors: errors ?? null };
		},
		[createContract],
	);
}

/**
 * Returns an `update` function for the Contract model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the Contract model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: Contract["id"]) {
	const [updateContract] = useContractUpdateMutation();

	return React.useCallback(
		async (changedFormValues: Partial<ContractFormValues.Detail>, initialFormValues: ContractFormValues.Detail) => {
			const input = ContractFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

			const { data, errors } = await updateContract({ variables: { id, input }, update: updateCache });

			return { data: data?.updateContract ?? null, errors: errors ?? null };
		},
		[updateContract, id],
	);
}

/**
 * Returns a `del` function for the Contract model.
 *
 * @param id The ID of the instance to delete.
 */
export function useDelete(id: Contract["id"]) {
	const [deleteContract] = useContractDeleteMutation();

	return React.useCallback(async () => {
		const updateCache = getUpdateCacheForDelete(id);

		const { data, errors } = await deleteContract({ variables: { id }, update: updateCache });

		return { data: data?.deleteContract ?? false, errors: errors ?? null };
	}, [deleteContract, id]);
}

function getUpdateCacheForCreate(input: ContractCreateInput) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<ContractCreateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const createdObject = result.data.createContract;

		cache.writeQuery<ContractDetailQuery, ContractDetailQueryVariables>({
			query: ContractDetailDocument,
			data: { contract: createdObject },
			variables: { id: createdObject.id },
		});

		if (input.contractInstallmentIDs) {
			for (let i = 0; i < input.contractInstallmentIDs.length; i++) {
				addToContractOfContractInstallmentCache(cache, input.contractInstallmentIDs[i], createdObject);
			}
		}

		if (input.serviceSubscriptionIDs) {
			for (let i = 0; i < input.serviceSubscriptionIDs.length; i++) {
				addToContractOfServiceSubscriptionCache(cache, input.serviceSubscriptionIDs[i], createdObject);
			}
		}

		if (input.studentID) {
			addToContractsOfStudentCache(cache, input.studentID, createdObject);
		}

		cache.evict({ id: "ROOT_QUERY", fieldName: "contractConnection" });
	};
}

function getUpdateCacheForUpdate(input: ContractUpdateInput, initialFormValues: ContractFormValues.Detail) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<ContractUpdateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const updatedObject = result.data.updateContract;

		if (input.addContractInstallmentIDs) {
			for (let i = 0; i < input.addContractInstallmentIDs.length; i++) {
				addToContractOfContractInstallmentCache(cache, input.addContractInstallmentIDs[i], updatedObject);
			}
		}
		if (input.removeContractInstallmentIDs) {
			for (let i = 0; i < input.removeContractInstallmentIDs.length; i++) {
				removeFromContractOfContractInstallmentCache(cache, input.removeContractInstallmentIDs[i], updatedObject);
			}
		}

		if (input.addServiceSubscriptionIDs) {
			for (let i = 0; i < input.addServiceSubscriptionIDs.length; i++) {
				addToContractOfServiceSubscriptionCache(cache, input.addServiceSubscriptionIDs[i], updatedObject);
			}
		}
		if (input.removeServiceSubscriptionIDs) {
			for (let i = 0; i < input.removeServiceSubscriptionIDs.length; i++) {
				removeFromContractOfServiceSubscriptionCache(cache, input.removeServiceSubscriptionIDs[i], updatedObject);
			}
		}

		if (initialFormValues.studentID && input.studentID) {
			removeFromContractsOfStudentCache(cache, initialFormValues.studentID, updatedObject);
		}
		if (input.studentID) {
			addToContractsOfStudentCache(cache, input.studentID, updatedObject);
		}
	};
}

function getUpdateCacheForDelete(id: Contract["id"]) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<ContractDeleteMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (!result.data?.deleteContract) {
			return;
		}

		cache.evict({ id: cache.identify({ id, __typename: graphTypeNames.Contract }) });
		cache.evict({ id: "ROOT_QUERY", fieldName: "contractConnection" });
		cache.gc();
	};
}

function addToContractOfContractInstallmentCache(
	cache: ApolloCache<any>,
	targetID: ContractInstallment["id"],
	object: Pick<Contract, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.ContractInstallment }),
		fields: {
			contract: () => objectRef,
			contractID: () => object.id,
		},
	});
}

function removeFromContractOfContractInstallmentCache(
	cache: ApolloCache<any>,
	targetID: ContractInstallment["id"],
	_object: Pick<Contract, "id">,
) {
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.ContractInstallment }),
		fields: {
			contract: () => null,
			contractID: () => null,
		},
	});
}

function addToContractOfServiceSubscriptionCache(
	cache: ApolloCache<any>,
	targetID: ServiceSubscription["id"],
	object: Pick<Contract, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.ServiceSubscription }),
		fields: {
			contract: () => objectRef,
			contractID: () => object.id,
		},
	});
}

function removeFromContractOfServiceSubscriptionCache(
	cache: ApolloCache<any>,
	targetID: ServiceSubscription["id"],
	_object: Pick<Contract, "id">,
) {
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.ServiceSubscription }),
		fields: {
			contract: () => null,
			contractID: () => null,
		},
	});
}

function addToContractsOfStudentCache(cache: ApolloCache<any>, targetID: Student["id"], object: Pick<Contract, "id">) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Student }),
		fields: {
			contracts: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromContractsOfStudentCache(
	cache: ApolloCache<any>,
	targetID: Student["id"],
	object: Pick<Contract, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Student }),
		fields: {
			contracts: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function toCacheRef(cache: ApolloCache<any>, object: Pick<Contract, "id">) {
	return cache.writeFragment({
		fragment: gql`
			fragment ContractRef on Contract {
				id
			}
		`,
		data: object,
	});
}
