// TODO move to core
import {
	addTimeToDate,
	DateTime,
	dateTrunc,
	isDayJS,
	isString,
	stringToLocalDate,
	toLocalDateString,
} from "@hex-insights/core";

// Needs a better name that's more general than just DOB.
export function isDOBOnDate(dob: DateTime, date: DateTime) {
	dob = toDateForDOB(dob);
	date = toDateForDOB(date);

	const isOnDate = dob.getMonth() === date.getMonth() && dob.getDate() === date.getDate();
	if (date.getMonth() === 1 && date.getDate() === 28 && !isLeapYear(date.getFullYear())) {
		return isOnDate || (dob.getMonth() === 1 && dob.getDate() === 29);
	}
	return isOnDate;
}

function toDateForDOB(date: DateTime) {
	if (isString(date)) {
		return stringToLocalDate(date, "day");
	}
	if (isDayJS(date)) {
		return date.toDate();
	}
	return date;
}

function isLeapYear(year: number) {
	return year % 400 === 0 || (year % 4 === 0 && year % 100 !== 0);
}

/**
 * Returns the time of the given date (or current date by default) expressed in minutes.
 *
 * @note Uses the current timezone.
 *
 * @example
 * ```
 * getDayMinutes(new Date("2022-08-15T15:32:10.500Z")) // 932.175 (when in UTC)
 * ```
 */
export function getDayMinutes(date = new Date()) {
	return date.getHours() * 60 + date.getMinutes() + date.getSeconds() / 60 + date.getMilliseconds() / 60_000;
}

/**
 * Converts the given number of minutes to a date object attached to the given date (or current date by default).
 *
 * @note Uses the current timezone.
 *
 * @example
 * ```
 * minutesToTime(915, new Date("2022-08-15T00:00:00Z")) // date: 2022-08-15T15:15:00Z (when in UTC)
 * ```
 */
export function minutesToTime(minutes: number, date = new Date()) {
	const h = Math.floor(minutes / 60);
	const m = minutes % 60;
	date.setHours(h, m, 0, 0);
	return date;
}

/**
 * Converts the given weekday number to a date in the same week as the given date (using the current date by default).
 */
export function dowToDate(dow: number, weekDate = new Date()) {
	const sunday = dateTrunc(weekDate, "week").toDate();
	return addTimeToDate(sunday, [dow, "days"]);
}

export function getDateRange(startDate: DateTime, endDate: DateTime) {
	const dateRange = [];
	let date = toLocalDateString(startDate);
	while (date <= endDate) {
		dateRange.push(date);
		date = addTimeToDate(date, [1, "day"]).toISOString();
	}
	return dateRange;
}

export function isLocalDateISOString(date: DateTime): date is string {
	if (!isString(date)) {
		return false;
	}
	return !!date.match(/^\d{4}-\d{2}-\d{2}T00:00:00(\.000)?Z/);
}

export function getFirstDayInWeek(date: DateTime) {
	const dateDate = toDate(date);
	return addTimeToDate(dateDate, [-1 * dateDate.getDay(), "days"]);
}

export function getLastDayInWeek(date: DateTime) {
	const dateDate = toDate(date);
	return addTimeToDate(dateDate, [6 - dateDate.getDay(), "days"]);
}

// TODO replace with toDayJS when moved to core
function toDate(date: DateTime): Date {
	if (isDayJS(date)) {
		return date.toDate();
	}
	if (isString(date)) {
		return new Date(date);
	}
	return date;
}
