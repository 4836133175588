import React from "react";
import { Button, ClassNameProps, Conditional, Else, Grid, Icon, If, StyleProps } from "@hex-insights/core";
import { useRouteParams } from "@hex-insights/router";
import {
	DocumentResourceIndexQuery,
	DocumentResourceOrderField,
	OrderDirection,
	Tile,
	useDocumentResourceIndexQuery,
} from "@hex-insights/verita.shared";
import { LessonPlansWeekCourseSectionPageRouteParams } from "../../pageinfo";
import { DocumentResourceDisplay } from "./DocumentResourceDisplay";

export type CourseResourcePreviewTileProps = Partial<ClassNameProps & StyleProps>;

export function CourseResourcePreviewTile({ className, style }: CourseResourcePreviewTileProps) {
	const { courseSectionID } = useRouteParams<LessonPlansWeekCourseSectionPageRouteParams>();
	const { data } = useDocumentResourceIndexQuery({
		variables: {
			filters: {
				documentResourceFolders: [
					{
						courses: [{ courseSections: [{ idEQ: courseSectionID }] }],
					},
					{
						courseSections: [{ idEQ: courseSectionID }],
					},
				],
			},
			order: { field: DocumentResourceOrderField.Name, direction: OrderDirection.Asc },
		},
	});

	const [previewedDocumentResource, setPreviewedDocumentResource] = React.useState<
		DocumentResourceIndexQuery["documentResourceConnection"]["edges"][0]["node"] | null
	>(null);

	React.useEffect(() => {
		if (!data) {
			return;
		}
		if (data.documentResourceConnection.edges.length === 1) {
			setPreviewedDocumentResource(data.documentResourceConnection.edges[0].node);
		}
	}, [data]);

	return (
		<Tile className={className} style={{ ...style, position: "relative" }}>
			<Tile.Body>
				<Conditional>
					<If condition={previewedDocumentResource !== null}>
						{previewedDocumentResource !== null && (
							<Conditional>
								<If condition={previewedDocumentResource.isFile}>
									<embed
										src={previewedDocumentResource.fileURL}
										style={{
											width: "100%",
											height: "100%",
											borderRadius: "var(--general__tile---border-radius)",
										}}
									/>
								</If>
								<Else>
									<iframe
										src={previewedDocumentResource.resourceURL}
										frameBorder="0"
										style={{
											width: "100%",
											height: "100%",
											borderRadius: "var(--general__tile---border-radius)",
										}}
									/>
								</Else>
							</Conditional>
						)}

						<div style={{ position: "absolute", top: "0.25rem", right: "0.25rem", zIndex: 1 }}>
							<Button onClick={() => setPreviewedDocumentResource(null)}>
								<Icon.X size="1rem" style={{ display: "block" }} />
							</Button>
						</div>
					</If>
					<Else>
						<Grid columns={6}>
							{data?.documentResourceConnection.edges.map((e) => (
								<DocumentResourceDisplay
									key={e.node.id}
									documentResource={e.node}
									onClick={() => setPreviewedDocumentResource(e.node)}
								/>
							))}
						</Grid>
					</Else>
				</Conditional>
			</Tile.Body>
		</Tile>
	);
}
