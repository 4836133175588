import { Route, useActivePageRegistration, useRouteMatch } from "@hex-insights/router";
import { PageRouteSwitch } from "../../Components";
import { ReportCardsIndexPage } from "./IndexPage";
import { reportCardsIndexPageInfo } from "./IndexPage/pageinfo";
import { reportCardsPageInfo } from "./pageinfo";
import { ReportCardsTermPage } from "./Term";
import { reportCardsTermPageInfo, ReportCardsTermPageRouteParams } from "./Term/pageinfo";

export function ReportCardsPage() {
	useActivePageRegistration(reportCardsPageInfo);

	return (
		<PageRouteSwitch>
			<Route {...reportCardsIndexPageInfo.routeProps}>
				<ReportCardsIndexPage />
			</Route>
			<Route {...reportCardsTermPageInfo.routeProps}>
				<ReportCardsTermPage
					key={useRouteMatch<ReportCardsTermPageRouteParams>(reportCardsTermPageInfo.path as string)?.params.termID}
				/>
			</Route>
		</PageRouteSwitch>
	);
}
