import React from "react";
import { Authentication } from "@hex-insights/app-modules";
import { Column, Conditional, Else, Heading, If, Paragraph, Row } from "@hex-insights/core";
import { Form, PasswordField, SubmitButton } from "@hex-insights/forms";
import { InternalLink, useActivePageRegistration, useHistory } from "@hex-insights/router";
import { serverRequest } from "@hex-insights/verita.shared";
import { Main } from "../../../Components";
import { AuthenticationContext } from "../../../Contexts";
import { mainServerAppName, projectName } from "../../../settings";
import { loginPageInfo } from "../Login/pageinfo";
import { recoverPageInfo } from "../Recover/pageinfo";
import { setPasswordPageInfo } from "./pageinfo";
import styles from "../authentication.module.css";

const expectedTokenIssuer = `${projectName}: ${mainServerAppName}`;

export function SetPasswordPage() {
	useActivePageRegistration(setPasswordPageInfo);

	const { email, token, tokenErr, submissionErr, onSubmissionError } = Authentication.useSetPasswordToken({
		expectedTokenIssuer,
	});

	const { push: pushHistory } = useHistory();
	const onSuccess = React.useCallback(() => {
		pushHistory("/");
	}, [pushHistory]);

	return (
		<Main className={styles["auth-page__main"]}>
			<Column justify="spaced-center" align="center" className={styles["auth-page__content-container"]}>
				<Heading level={1}>Set Password</Heading>

				<div>
					<Conditional>
						<If condition={tokenErr !== null || submissionErr !== null}>
							<Paragraph>
								{tokenErr ?? submissionErr}{" "}
								<InternalLink to={{ pathname: recoverPageInfo.to, state: { formValues: { email } } }}>
									Request a new link
								</InternalLink>
								.
							</Paragraph>
						</If>

						<Else>
							<Paragraph>Set password for: {email}</Paragraph>

							<SetPasswordForm
								email={email}
								token={token?.raw ?? ""}
								onSubmissionError={onSubmissionError}
								onSuccess={onSuccess}
							/>
						</Else>
					</Conditional>
				</div>
			</Column>
		</Main>
	);
}

type SetPasswordFormProps = {
	email: string;
	token: string;
	onSubmissionError: () => void;
	onSuccess: () => void;
};

function SetPasswordForm({ email, token, onSubmissionError, onSuccess }: SetPasswordFormProps) {
	const requestSetPassword = React.useCallback(
		(formValues: Authentication.SetPasswordFormValues) => {
			return serverRequest("/auth/set-password", {
				method: "POST",
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(formValues),
			});
		},
		[token],
	);

	const { formState, onSubmit } = Authentication.useSetPassword({
		AuthenticationContext,
		requestSetPassword,
		onSubmissionError,
		onSuccess,
		authDelayMS: 50,
	});

	return (
		<Form formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)} noNotifications noNavigationPrompt>
			<Column justify="spaced-start">
				<PasswordField
					formState={formState}
					name="newPassword"
					minLength={8}
					autoFocus
					allowVisibility
					withStrengthEstimator
					maxHashInputLength={72}
				/>

				<Row justify="space-between" align="flex-end">
					<InternalLink to={{ pathname: loginPageInfo.to, state: { formValues: { email } } }}>
						Back to Login
					</InternalLink>

					<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
						Set Password
					</SubmitButton>
				</Row>
			</Column>
		</Form>
	);
}
