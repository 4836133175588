import React from "react";
import { FieldDisplayArgs, SelectField } from "@hex-insights/forms";
import { MealPlanFormValues, StudentSelect, useStudentSelectLazyQuery } from "../../../../Utilities";
import { StudentLink } from "../../../Links";
import { TextAreaField } from "../../../TextAreaField";
import { BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the MealPlan model.
 */
type FieldProps<K extends keyof MealPlanFormValues.Base = keyof MealPlanFormValues.Base> = BaseFieldProps<
	Pick<MealPlanFormValues.Base, K>
>;

/**
 * Renders a field component for the `notes` field of the MealPlan model.
 */
export function Notes({ formState }: FieldProps<"notes">) {
	return <TextAreaField formState={formState} name="notes" optional />;
}

export type StudentProps = FieldProps<"studentID"> & {
	currentStudent?: StudentSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `student` edge of the MealPlan model.
 */
export function Student({ formState, currentStudent }: StudentProps) {
	const [loadOptions, { loading, data }] = useStudentSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.studentID) {
			loadOptions();
		}
	}, [formState.formEditing.studentID, loadOptions]);
	const options = React.useMemo(
		() => StudentSelect.toOptions(data?.studentConnection.edges, currentStudent),
		[data, currentStudent],
	);

	return (
		<SelectField
			formState={formState}
			name="studentID"
			isLoading={loading}
			options={options}
			display={displayStudent}
			blankValue={null}
		/>
	);
}

function displayStudent({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <StudentLink instance={{ id }}>{formattedValue}</StudentLink>;
}
