import React from "react";
import { DefaultContextValue } from "@hex-insights/core";
import { InternalLinkProps, PageInfo } from "@hex-insights/router";
import { Student } from "../../../../Utilities";

export type PageContextValue = {
	overviewPageInfo?: PageInfo<any, (id: Student["id"]) => InternalLinkProps["to"], any>;
	academicsPageInfo?: PageInfo<any, (id: Student["id"]) => InternalLinkProps["to"], any>;
	financialsPageInfo?: PageInfo<any, (id: Student["id"]) => InternalLinkProps["to"], any>;
	familyAndHealthPageInfo?: PageInfo<any, (id: Student["id"]) => InternalLinkProps["to"], any>;
};

export const PageContext = React.createContext<PageContextValue>(
	new DefaultContextValue("StudentHub.Detail.PageContext") as any,
);
