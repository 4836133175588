import { Models } from "@hex-insights/app-modules";
import { DistributiveOmit } from "@hex-insights/core";
import { Employee, EmployeeFormat, EmployeeNameFragment } from "../../../../Utilities";

type ModelForLink = Pick<Employee, "id"> & EmployeeNameFragment;

export type EmployeeLinkProps = DistributiveOmit<Models.LinkProps<ModelForLink>, "pageInfoKey" | "format">;

/**
 * Renders a link to the given Employee instance.
 */
export function EmployeeLink(props: EmployeeLinkProps) {
	return <Models.Link<ModelForLink> pageInfoKey="Employee" format={EmployeeFormat.name} {...props} />;
}
