import { Heading, Paragraph, Row } from "@hex-insights/core";
import { InternalLink } from "@hex-insights/router";
import { Main, Tile } from "@hex-insights/verita.shared";
import { logoutPageInfo } from "../Authentication/Logout/pageinfo";

export function EmployeesOnlyPage() {
	return (
		<Main>
			<Row justify="center" align="center" style={{ height: "calc(100vh - 2 * var(--main---padding-top))" }}>
				<Tile>
					<Tile.Header>
						<Heading level={1} noMargin>
							Employees Only
						</Heading>
					</Tile.Header>

					<Tile.Body>
						<Paragraph>This site is for employees of Verita International School only.</Paragraph>

						<Paragraph>
							<InternalLink to={logoutPageInfo.to}>Click here to Logout</InternalLink>
						</Paragraph>
					</Tile.Body>
				</Tile>
			</Row>
		</Main>
	);
}
