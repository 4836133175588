import { Models } from "@hex-insights/app-modules";
import { ContractInstallment, ContractInstallmentMutation } from "../../../../Utilities";

export type ContractInstallmentDeleteButtonProps = Omit<Models.DeleteButtonProps, "deleteInstance"> & {
	instanceID: ContractInstallment["id"];
};

/**
 * A delete button for the ContractInstallment model.
 */
export function ContractInstallmentDeleteButton({
	instanceID,
	confirmationContent = "Are you sure you want to delete this contract installment?",
	children = "Delete Contract Installment",
	...props
}: ContractInstallmentDeleteButtonProps) {
	const del = ContractInstallmentMutation.useDelete(instanceID);

	return (
		<Models.DeleteButton deleteInstance={del} confirmationContent={confirmationContent} {...props}>
			{children}
		</Models.DeleteButton>
	);
}
