import { Route, useActivePageRegistration, useRouteMatch, useRouteParams } from "@hex-insights/router";
import { useCourseSectionDetailQuery } from "@hex-insights/verita.shared";
import { PageRouteSwitch } from "../../../../Components";
import { ReportCardsTermCourseSectionIndexPage } from "./IndexPage";
import { reportCardsTermCourseSectionIndexPageInfo } from "./IndexPage/pageinfo";
import { reportCardsTermCourseSectionPageInfo, ReportCardsTermCourseSectionPageRouteParams } from "./pageinfo";
import { ReportCardsTermCourseSectionStudentPage } from "./Student";
import {
	reportCardsTermCourseSectionStudentPageInfo,
	ReportCardsTermCourseSectionStudentPageRouteParams,
} from "./Student/pageinfo";

export function ReportCardsTermCourseSectionPage() {
	const { courseSectionID } = useRouteParams<ReportCardsTermCourseSectionPageRouteParams>();
	const { data } = useCourseSectionDetailQuery({ variables: { id: courseSectionID } });

	useActivePageRegistration(reportCardsTermCourseSectionPageInfo, data?.courseSection.name ?? "");

	return (
		<PageRouteSwitch>
			<Route {...reportCardsTermCourseSectionIndexPageInfo.routeProps}>
				<ReportCardsTermCourseSectionIndexPage />
			</Route>
			<Route {...reportCardsTermCourseSectionStudentPageInfo.routeProps}>
				<ReportCardsTermCourseSectionStudentPage
					key={
						useRouteMatch<ReportCardsTermCourseSectionStudentPageRouteParams>(
							reportCardsTermCourseSectionStudentPageInfo.path as string,
						)?.params.studentID
					}
				/>
			</Route>
		</PageRouteSwitch>
	);
}
