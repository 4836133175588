import { Models } from "@hex-insights/app-modules";
import { DistributiveOmit } from "@hex-insights/core";
import { ReportCard, ReportCardFormat, ReportCardNameFragment } from "../../../../Utilities";

type ModelForLink = Pick<ReportCard, "id"> & ReportCardNameFragment;

export type ReportCardLinkProps = DistributiveOmit<Models.LinkProps<ModelForLink>, "pageInfoKey" | "format">;

/**
 * Renders a link to the given ReportCard instance.
 */
export function ReportCardLink(props: ReportCardLinkProps) {
	return <Models.Link<ModelForLink> pageInfoKey="ReportCard" format={ReportCardFormat.name} {...props} />;
}
