import { getArrayDiff, NullPartial, omitKeys } from "@hex-insights/core";
import {
	PaymentCreateInput,
	PaymentDetailQuery,
	PaymentLineItemInPaymentCreateInput,
	PaymentUpdateInput,
} from "../../GraphQL";
import { PaymentFormValues } from "../ModelFormValues";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the Payment model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(formValues: PaymentFormValues.Create): PaymentCreateInput {
	const input: NullPartial<PaymentCreateInput> = {
		...formValues,
		paymentLineItems: formValues.paymentLineItems.map(paymentLineItemToNestedGQLCreateInput),
	};
	return input as PaymentCreateInput;
}

function paymentLineItemToNestedGQLCreateInput(
	formValues: PaymentFormValues.Create["paymentLineItems"][0],
): PaymentLineItemInPaymentCreateInput {
	const input: NullPartial<PaymentLineItemInPaymentCreateInput> = { ...formValues };
	return input as PaymentLineItemInPaymentCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the Payment model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<PaymentFormValues.Detail>,
	initialFormValues: PaymentFormValues.Detail,
): PaymentUpdateInput {
	const inputFormValues = omitKeys(formValues, ["paymentLineItemIDs"]);
	const input: PaymentUpdateInput = { ...inputFormValues };

	if (formValues.paymentLineItemIDs !== undefined) {
		const { added, removed } = getArrayDiff(initialFormValues.paymentLineItemIDs, formValues.paymentLineItemIDs);
		input.addPaymentLineItemIDs = added;
		input.removePaymentLineItemIDs = removed;
	}

	return input;
}

/**
 * Converts a Payment instance to its corresponding detail form values.
 *
 * @param payment The instance to convert.
 */
export function toFormValues(payment: PaymentDetailQuery["payment"]): PaymentFormValues.Detail {
	return {
		createdDate: payment.createdDate,
		paymentMethod: payment.paymentMethod,
		notes: payment.notes,
		parentID: payment.parent?.id ?? null,
		paymentLineItemIDs: payment.paymentLineItems.map((e) => e.id),
	};
}
