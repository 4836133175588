import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { Button, ButtonProps, ButtonVariant } from "@hex-insights/core";
import { FormState } from "@hex-insights/forms";
import { EnrollmentInvitationFilterFormValues, EnrollmentInvitationResponse } from "../../../../Utilities";

export type EnrollmentInvitationSearchFormProps = Pick<IndexForms.SearchFormProps, "formState">;

/**
 * Renders the search form of the EnrollmentInvitation model.
 */
export function EnrollmentInvitationSearchForm(props: EnrollmentInvitationSearchFormProps) {
	return <IndexForms.SearchForm {...props} />;
}

export type EnrollmentInvitationFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, EnrollmentInvitationFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the EnrollmentInvitation model.
 */
export function EnrollmentInvitationFilterFormController(props: EnrollmentInvitationFilterFormControllerProps) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={EnrollmentInvitationFilterFormValues.advancedFilterInformation}
		/>
	);
}

// TODO define QuickFilters component and add to `EnrollmentInvitationFilterFormController`

const allResponses = [
	EnrollmentInvitationResponse.Yes,
	EnrollmentInvitationResponse.Unknown,
	EnrollmentInvitationResponse.No,
];

export type EnrollmentInvitationResponseFilterButtonsProps = {
	responses?: EnrollmentInvitationResponse[];
	formState: FormState<Pick<EnrollmentInvitationFilterFormValues.FormValues, "responseIn">>;
	buttonProps?: Omit<ButtonProps, "variant" | "onClick" | "children">;
};

export function EnrollmentInvitationResponseFilterButtons({
	responses = allResponses,
	formState,
	buttonProps,
}: EnrollmentInvitationResponseFilterButtonsProps) {
	return (
		<React.Fragment>
			{responses.map((response) => (
				<Button
					key={response}
					{...buttonProps}
					variant={
						formState.formValues.responseIn.includes(response) ? getButtonVariantForResponse(response) : "tertiary"
					}
					onClick={() => {
						formState.formValues.responseIn.includes(response)
							? formState.formSetFunctions.responseIn((prev) => prev.filter((e) => e !== response))
							: formState.formSetFunctions.responseIn((prev) => [...prev, response]);
					}}
				>
					{getButtonTextForResponse(response)}
				</Button>
			))}
		</React.Fragment>
	);
}

function getButtonVariantForResponse(response: EnrollmentInvitationResponse): ButtonVariant {
	switch (response) {
		case EnrollmentInvitationResponse.Yes:
			return "success";
		case EnrollmentInvitationResponse.Unknown:
			return "primary";
		case EnrollmentInvitationResponse.No:
			return "danger";
	}
}

function getButtonTextForResponse(response: EnrollmentInvitationResponse) {
	switch (response) {
		case EnrollmentInvitationResponse.Yes:
			return "Responded Yes";
		case EnrollmentInvitationResponse.Unknown:
			return "No Response";
		case EnrollmentInvitationResponse.No:
			return "Responded No";
	}
}
