import { getArrayDiff, NullPartial, omitKeys } from "@hex-insights/core";
import { StudentDocumentCreateInput, StudentDocumentDetailQuery, StudentDocumentUpdateInput } from "../../GraphQL";
import { StudentDocumentFormValues } from "../ModelFormValues";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the StudentDocument model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(formValues: StudentDocumentFormValues.Create): StudentDocumentCreateInput {
	const input: NullPartial<StudentDocumentCreateInput> = { ...formValues };
	return input as StudentDocumentCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the StudentDocument model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<StudentDocumentFormValues.Detail>,
	initialFormValues: StudentDocumentFormValues.Detail,
): StudentDocumentUpdateInput {
	const inputFormValues = omitKeys(formValues, ["createdAt", "authorID", "sharingUserGroupIDs"]);
	const input: StudentDocumentUpdateInput = { ...inputFormValues };

	if (formValues.sharingUserGroupIDs !== undefined) {
		const { added, removed } = getArrayDiff(initialFormValues.sharingUserGroupIDs, formValues.sharingUserGroupIDs);
		input.addSharingUserGroupIDs = added;
		input.removeSharingUserGroupIDs = removed;
	}

	return input;
}

/**
 * Converts a StudentDocument instance to its corresponding detail form values.
 *
 * @param studentDocument The instance to convert.
 */
export function toFormValues(
	studentDocument: StudentDocumentDetailQuery["studentDocument"],
): StudentDocumentFormValues.Detail {
	return {
		name: studentDocument.name,
		description: studentDocument.description,
		file: studentDocument.fileURL !== "" ? new File([], "file") : null,
		isPublic: studentDocument.isPublic,
		createdAt: studentDocument.createdAt,
		authorID: studentDocument.author?.id ?? null,
		sharingUserGroupIDs: studentDocument.sharingUserGroups.map((e) => e.id),
		studentID: studentDocument.student?.id ?? null,
	};
}
