import React from "react";
import { Column, Heading } from "@hex-insights/core";
import { FormType, useFormState } from "@hex-insights/forms";
import { BasicTable } from "@hex-insights/tables";
import { formatPrice, InvoiceLineItemFormat, PaymentDetailQuery, PaymentFormConversion } from "../../Utilities";
import { PaymentField } from "../Forms";
import { InvoiceLink } from "../Links";

export type PaymentDisplayProps = {
	payment: PaymentDetailQuery["payment"];
};

export function PaymentDisplay({ payment }: PaymentDisplayProps) {
	const initialFormValues = React.useMemo(() => PaymentFormConversion.toFormValues(payment), [payment]);
	const formState = useFormState({ initialFormValues, formType: FormType.View });

	const totalLineItemAmount = payment.paymentLineItems.reduce((a, e) => a + e.amount, 0);

	return (
		<Column justify="spaced-start">
			<PaymentField.CreatedDate formState={formState} />
			<PaymentField.PaymentMethod formState={formState} />
			<PaymentField.Notes formState={formState} />

			<Heading level={3} noMargin>
				Line Items
			</Heading>
			<BasicTable>
				<BasicTable.Header>
					<BasicTable.Row>
						<BasicTable.Heading>Invoice</BasicTable.Heading>
						<BasicTable.Heading>Amount</BasicTable.Heading>
					</BasicTable.Row>
				</BasicTable.Header>
				<BasicTable.Body>
					{payment.paymentLineItems.map((e) => (
						<BasicTable.Row key={e.id}>
							<BasicTable.Cell>
								<InvoiceLink instance={e.invoice} />
							</BasicTable.Cell>
							<BasicTable.Cell numeric>{InvoiceLineItemFormat.Fields.amount(e.amount)}</BasicTable.Cell>
						</BasicTable.Row>
					))}
				</BasicTable.Body>
				<BasicTable.Footer>
					<BasicTable.Row>
						<BasicTable.Cell style={{ fontWeight: "bold" }}>Total</BasicTable.Cell>
						<BasicTable.Cell numeric style={{ fontWeight: "bold", borderTop: "1px solid #ddd" }}>
							{formatPrice(totalLineItemAmount)}
						</BasicTable.Cell>
					</BasicTable.Row>
				</BasicTable.Footer>
			</BasicTable>
		</Column>
	);
}
