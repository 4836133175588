import { IndexForms } from "@hex-insights/app-modules";
import { CaseStyle, convertCase } from "@hex-insights/core";
import { OrderDirection, ReportCardOrderField } from "../../GraphQL";

export type FormValues = IndexForms.OrderFormValues<ReportCardOrderField | null>;

export const initial: FormValues = {
	field: ReportCardOrderField.IssueDate,
	direction: OrderDirection.Asc,
};

/**
 * Options for the "field" field in the order form of the ReportCard model.
 */
export const fieldOptions = [
	{ label: "ID", value: null },
	{
		label: convertCase(ReportCardOrderField.IssueDate.toLowerCase(), CaseStyle.Title),
		value: ReportCardOrderField.IssueDate,
	},
];

/**
 * Array of values for the "field" field in the order form of the ReportCard model.
 */
export const fieldValues = fieldOptions.map((e) => e.value);

/**
 * Mapping from order fields of the ReportCard model to their corresponding order type.
 */
export const fieldTypeMapping = {
	[ReportCardOrderField.IssueDate]: IndexForms.OrderFieldType.Time,
};

/**
 * Mapping from order fields of the ReportCard model to their corresponding field name.
 */
export const orderFieldToModelField = {
	[ReportCardOrderField.IssueDate]: "issueDate",
};

/**
 * Mapping from field names of the ReportCard model to their corresponding order field.
 */
export const modelFieldToOrderField = {
	issueDate: ReportCardOrderField.IssueDate,
};
