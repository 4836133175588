import React from "react";
import { BooleanField, FieldDisplayArgs, NumberField, SelectField } from "@hex-insights/forms";
import {
	BusPlanFormat,
	BusPlanFormValues,
	BusSelect,
	StudentSelect,
	useBusSelectLazyQuery,
	useStudentSelectLazyQuery,
} from "../../../../Utilities";
import { BusLink, StudentLink } from "../../../Links";
import { TextAreaField } from "../../../TextAreaField";
import { TimeInput } from "../../../TimeInput";
import { BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the BusPlan model.
 */
type FieldProps<K extends keyof BusPlanFormValues.Base = keyof BusPlanFormValues.Base> = BaseFieldProps<
	Pick<BusPlanFormValues.Base, K>
>;

/**
 * Renders a field component for the `hasPickup` field of the BusPlan model.
 */
export function HasPickup({ formState }: FieldProps<"hasPickup">) {
	return <BooleanField formState={formState} name="hasPickup" label="With Pickup" />;
}

/**
 * Renders a field component for the `pickupTimeMinutes` field of the BusPlan model.
 */
export function PickupTimeMinutes({ formState }: FieldProps<"pickupTimeMinutes">) {
	return (
		<NumberField
			formState={formState}
			name="pickupTimeMinutes"
			label="Pickup Time"
			optional
			validationUnit={1}
			min={0}
			max={1439}
			Input={TimeInput}
			format={BusPlanFormat.Fields.pickupTimeMinutes}
		/>
	);
}

/**
 * Renders a field component for the `pickupLocation` field of the BusPlan model.
 */
export function PickupLocation({ formState }: FieldProps<"pickupLocation">) {
	return <TextAreaField formState={formState} name="pickupLocation" optional />;
}

/**
 * Renders a field component for the `hasDropoff` field of the BusPlan model.
 */
export function HasDropoff({ formState }: FieldProps<"hasDropoff">) {
	return <BooleanField formState={formState} name="hasDropoff" label="With Dropoff" />;
}

/**
 * Renders a field component for the `dropoffTimeMinutes` field of the BusPlan model.
 */
export function DropoffTimeMinutes({ formState }: FieldProps<"dropoffTimeMinutes">) {
	return (
		<NumberField
			formState={formState}
			name="dropoffTimeMinutes"
			label="Dropoff Time"
			optional
			validationUnit={1}
			min={0}
			max={1439}
			Input={TimeInput}
			format={BusPlanFormat.Fields.dropoffTimeMinutes}
		/>
	);
}

/**
 * Renders a field component for the `dropoffLocation` field of the BusPlan model.
 */
export function DropoffLocation({ formState }: FieldProps<"dropoffLocation">) {
	return <TextAreaField formState={formState} name="dropoffLocation" optional />;
}

export type DropoffBusProps = FieldProps<"dropoffBusID"> & {
	currentDropoffBus?: BusSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `dropoffBus` edge of the BusPlan model.
 */
export function DropoffBus({ formState, currentDropoffBus }: DropoffBusProps) {
	const [loadOptions, { loading, data }] = useBusSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.dropoffBusID) {
			loadOptions();
		}
	}, [formState.formEditing.dropoffBusID, loadOptions]);
	const options = React.useMemo(
		() => BusSelect.toOptions(data?.busConnection.edges, currentDropoffBus),
		[data, currentDropoffBus],
	);

	return (
		<SelectField
			formState={formState}
			name="dropoffBusID"
			isLoading={loading}
			options={options}
			optional
			display={displayDropoffBus}
			blankValue={null}
		/>
	);
}

function displayDropoffBus({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <BusLink instance={{ id }}>{formattedValue}</BusLink>;
}

export type PickupBusProps = FieldProps<"pickupBusID"> & {
	currentPickupBus?: BusSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `pickupBus` edge of the BusPlan model.
 */
export function PickupBus({ formState, currentPickupBus }: PickupBusProps) {
	const [loadOptions, { loading, data }] = useBusSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.pickupBusID) {
			loadOptions();
		}
	}, [formState.formEditing.pickupBusID, loadOptions]);
	const options = React.useMemo(
		() => BusSelect.toOptions(data?.busConnection.edges, currentPickupBus),
		[data, currentPickupBus],
	);

	return (
		<SelectField
			formState={formState}
			name="pickupBusID"
			isLoading={loading}
			options={options}
			optional
			display={displayPickupBus}
			blankValue={null}
		/>
	);
}

function displayPickupBus({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <BusLink instance={{ id }}>{formattedValue}</BusLink>;
}

export type StudentProps = FieldProps<"studentID"> & {
	currentStudent?: StudentSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `student` edge of the BusPlan model.
 */
export function Student({ formState, currentStudent }: StudentProps) {
	const [loadOptions, { loading, data }] = useStudentSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.studentID) {
			loadOptions();
		}
	}, [formState.formEditing.studentID, loadOptions]);
	const options = React.useMemo(
		() => StudentSelect.toOptions(data?.studentConnection.edges, currentStudent),
		[data, currentStudent],
	);

	return (
		<SelectField
			formState={formState}
			name="studentID"
			isLoading={loading}
			options={options}
			display={displayStudent}
			blankValue={null}
		/>
	);
}

function displayStudent({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <StudentLink instance={{ id }}>{formattedValue}</StudentLink>;
}
