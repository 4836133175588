import React from "react";
import { Authentication } from "@hex-insights/app-modules";
import { NavigationPrompt, Route } from "@hex-insights/router";
import {
	EmployeeContext,
	EmployeeContextValue,
	EmployeeWrapperContext,
	EmployeeWrapperContextManager,
	UserContextManager,
} from "@hex-insights/verita.shared";
import { Footer, Header, PageRouteSwitch, TrustedDeviceController } from "./Components";
import { AuthenticationContext } from "./Contexts";
import { AccountDisabledPage } from "./Pages/Authentication/AccountDisabled";
import { LoginPage } from "./Pages/Authentication/Login";
import { loginPageInfo } from "./Pages/Authentication/Login/pageinfo";
import { LogoutPage } from "./Pages/Authentication/Logout";
import { logoutPageInfo } from "./Pages/Authentication/Logout/pageinfo";
import { MFALoginPage } from "./Pages/Authentication/MFALogin";
import { RecoverPage } from "./Pages/Authentication/Recover";
import { recoverPageInfo } from "./Pages/Authentication/Recover/pageinfo";
import { SetPasswordPage } from "./Pages/Authentication/SetPassword";
import { setPasswordPageInfo } from "./Pages/Authentication/SetPassword/pageinfo";
import { SplashPage } from "./Pages/Authentication/Splash";
import { EmployeesOnlyPage } from "./Pages/EmployeesOnly";
import { HomePage } from "./Pages/Home";
import { homePageInfo } from "./Pages/Home/pageinfo";
import { LessonPlansPage } from "./Pages/LessonPlans";
import { lessonPlansPageInfo } from "./Pages/LessonPlans/pageinfo";
import { ReportCardsPage } from "./Pages/ReportCards";
import { reportCardsPageInfo } from "./Pages/ReportCards/pageinfo";
import { SchedulePage } from "./Pages/Schedule";
import { schedulePageInfo } from "./Pages/Schedule/pageinfo";
import { StudentHubPage } from "./Pages/Students";
import { studentHubPageInfo } from "./Pages/Students/pageinfo";

export function App() {
	return (
		<EmployeeWrapperContextManager>
			<BaseApp />
		</EmployeeWrapperContextManager>
	);
}

export function BaseApp() {
	const { status } = React.useContext(AuthenticationContext);

	switch (status) {
		case Authentication.AuthenticationStatus.Loading:
			return <SplashPage />;
		case Authentication.AuthenticationStatus.Disabled:
			return <AppDisabled />;
		case Authentication.AuthenticationStatus.MFARequired:
			return <AppMFALogin />;
		case Authentication.AuthenticationStatus.Unauthenticated:
			return <AppUnauthenticated />;
		default:
			return <AppAuthenticated />;
	}
}

function AppAuthenticated() {
	const { user } = React.useContext(AuthenticationContext);
	const { isLoading, employee } = React.useContext(EmployeeWrapperContext);
	const contextValue = React.useMemo(() => ({ employee }), [employee]);

	if (isLoading) {
		return <SplashPage />;
	}

	if (!employee) {
		return <EmployeesOnlyPage />;
	}

	return (
		<UserContextManager user={user!}>
			<EmployeeContext.Provider value={contextValue as EmployeeContextValue}>
				<NavigationPrompt
					message={(targetLocation) =>
						targetLocation.pathname === logoutPageInfo.to ? "Are you sure you want to log out?" : true
					}
				/>

				<Header />

				<PageRouteSwitch>
					<Route {...homePageInfo.routeProps}>
						<HomePage />
					</Route>
					<Route {...schedulePageInfo.routeProps}>
						<SchedulePage />
					</Route>
					<Route {...lessonPlansPageInfo.routeProps}>
						<LessonPlansPage />
					</Route>
					<Route {...reportCardsPageInfo.routeProps}>
						<ReportCardsPage />
					</Route>
					<Route {...studentHubPageInfo.routeProps}>
						<StudentHubPage />
					</Route>
					<Route {...logoutPageInfo.routeProps}>
						<LogoutPage />
					</Route>
				</PageRouteSwitch>

				<TrustedDeviceController />

				<Footer />
			</EmployeeContext.Provider>
		</UserContextManager>
	);
}

function AppUnauthenticated() {
	return (
		<PageRouteSwitch>
			<Route {...recoverPageInfo.routeProps}>
				<RecoverPage />
			</Route>
			<Route {...setPasswordPageInfo.routeProps}>
				<SetPasswordPage />
			</Route>
			<Route {...logoutPageInfo.routeProps}>
				<LogoutPage />
			</Route>
			<Route {...loginPageInfo.routeProps}>
				<LoginPage />
			</Route>
		</PageRouteSwitch>
	);
}

function AppMFALogin() {
	return (
		<PageRouteSwitch>
			<Route {...logoutPageInfo}>
				<LogoutPage />
			</Route>
			<Route>
				<MFALoginPage />
			</Route>
		</PageRouteSwitch>
	);
}

function AppDisabled() {
	return (
		<PageRouteSwitch>
			<Route {...logoutPageInfo}>
				<LogoutPage />
			</Route>
			<Route>
				<AccountDisabledPage />
			</Route>
		</PageRouteSwitch>
	);
}
