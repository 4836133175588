export type GraphTypeNames = {
	AcademicYear: "AcademicYear";
	AcademicYearConnection: "AcademicYearConnection";
	AcademicYearEdge: "AcademicYearEdge";
	AcademicYearTuition: "AcademicYearTuition";
	AcademicYearTuitionConnection: "AcademicYearTuitionConnection";
	AcademicYearTuitionEdge: "AcademicYearTuitionEdge";
	Address: "Address";
	AddressConnection: "AddressConnection";
	AddressEdge: "AddressEdge";
	BillingSetupSubmission: "BillingSetupSubmission";
	BillingSetupSubmissionConnection: "BillingSetupSubmissionConnection";
	BillingSetupSubmissionEdge: "BillingSetupSubmissionEdge";
	Bus: "Bus";
	BusConnection: "BusConnection";
	BusEdge: "BusEdge";
	BusPlan: "BusPlan";
	BusPlanConnection: "BusPlanConnection";
	BusPlanEdge: "BusPlanEdge";
	CalendarEvent: "CalendarEvent";
	CalendarEventConnection: "CalendarEventConnection";
	CalendarEventEdge: "CalendarEventEdge";
	Campus: "Campus";
	CampusConnection: "CampusConnection";
	CampusEdge: "CampusEdge";
	ClassYear: "ClassYear";
	ClassYearConnection: "ClassYearConnection";
	ClassYearEdge: "ClassYearEdge";
	Contract: "Contract";
	ContractConnection: "ContractConnection";
	ContractEdge: "ContractEdge";
	ContractInstallment: "ContractInstallment";
	ContractInstallmentConnection: "ContractInstallmentConnection";
	ContractInstallmentEdge: "ContractInstallmentEdge";
	Course: "Course";
	CourseConnection: "CourseConnection";
	CourseEdge: "CourseEdge";
	CourseAttendanceRecord: "CourseAttendanceRecord";
	CourseAttendanceRecordConnection: "CourseAttendanceRecordConnection";
	CourseAttendanceRecordEdge: "CourseAttendanceRecordEdge";
	CourseSection: "CourseSection";
	CourseSectionConnection: "CourseSectionConnection";
	CourseSectionEdge: "CourseSectionEdge";
	CourseSectionPeriod: "CourseSectionPeriod";
	CourseSectionPeriodConnection: "CourseSectionPeriodConnection";
	CourseSectionPeriodEdge: "CourseSectionPeriodEdge";
	Discount: "Discount";
	DiscountConnection: "DiscountConnection";
	DiscountEdge: "DiscountEdge";
	DocumentResource: "DocumentResource";
	DocumentResourceConnection: "DocumentResourceConnection";
	DocumentResourceEdge: "DocumentResourceEdge";
	DocumentResourceFolder: "DocumentResourceFolder";
	DocumentResourceFolderConnection: "DocumentResourceFolderConnection";
	DocumentResourceFolderEdge: "DocumentResourceFolderEdge";
	DocumentResourceUserViewRecord: "DocumentResourceUserViewRecord";
	DocumentResourceUserViewRecordConnection: "DocumentResourceUserViewRecordConnection";
	DocumentResourceUserViewRecordEdge: "DocumentResourceUserViewRecordEdge";
	EmailAddress: "EmailAddress";
	EmailAddressConnection: "EmailAddressConnection";
	EmailAddressEdge: "EmailAddressEdge";
	Employee: "Employee";
	EmployeeConnection: "EmployeeConnection";
	EmployeeEdge: "EmployeeEdge";
	EnrollmentApplication: "EnrollmentApplication";
	EnrollmentApplicationConnection: "EnrollmentApplicationConnection";
	EnrollmentApplicationEdge: "EnrollmentApplicationEdge";
	EnrollmentApplicationNote: "EnrollmentApplicationNote";
	EnrollmentApplicationNoteConnection: "EnrollmentApplicationNoteConnection";
	EnrollmentApplicationNoteEdge: "EnrollmentApplicationNoteEdge";
	EnrollmentApplicationParent: "EnrollmentApplicationParent";
	EnrollmentApplicationParentConnection: "EnrollmentApplicationParentConnection";
	EnrollmentApplicationParentEdge: "EnrollmentApplicationParentEdge";
	EnrollmentApplicationStudent: "EnrollmentApplicationStudent";
	EnrollmentApplicationStudentConnection: "EnrollmentApplicationStudentConnection";
	EnrollmentApplicationStudentEdge: "EnrollmentApplicationStudentEdge";
	EnrollmentApplicationSubmission: "EnrollmentApplicationSubmission";
	EnrollmentApplicationSubmissionConnection: "EnrollmentApplicationSubmissionConnection";
	EnrollmentApplicationSubmissionEdge: "EnrollmentApplicationSubmissionEdge";
	EnrollmentApplicationUserViewRecord: "EnrollmentApplicationUserViewRecord";
	EnrollmentApplicationUserViewRecordConnection: "EnrollmentApplicationUserViewRecordConnection";
	EnrollmentApplicationUserViewRecordEdge: "EnrollmentApplicationUserViewRecordEdge";
	EnrollmentInvitation: "EnrollmentInvitation";
	EnrollmentInvitationConnection: "EnrollmentInvitationConnection";
	EnrollmentInvitationEdge: "EnrollmentInvitationEdge";
	Expense: "Expense";
	ExpenseConnection: "ExpenseConnection";
	ExpenseEdge: "ExpenseEdge";
	ExpenseBudget: "ExpenseBudget";
	ExpenseBudgetConnection: "ExpenseBudgetConnection";
	ExpenseBudgetEdge: "ExpenseBudgetEdge";
	ExpenseCategory: "ExpenseCategory";
	ExpenseCategoryConnection: "ExpenseCategoryConnection";
	ExpenseCategoryEdge: "ExpenseCategoryEdge";
	HomeRoom: "HomeRoom";
	HomeRoomConnection: "HomeRoomConnection";
	HomeRoomEdge: "HomeRoomEdge";
	HomeRoomSection: "HomeRoomSection";
	HomeRoomSectionConnection: "HomeRoomSectionConnection";
	HomeRoomSectionEdge: "HomeRoomSectionEdge";
	Invoice: "Invoice";
	InvoiceConnection: "InvoiceConnection";
	InvoiceEdge: "InvoiceEdge";
	InvoiceLineItem: "InvoiceLineItem";
	InvoiceLineItemConnection: "InvoiceLineItemConnection";
	InvoiceLineItemEdge: "InvoiceLineItemEdge";
	LearningObjective: "LearningObjective";
	LearningObjectiveConnection: "LearningObjectiveConnection";
	LearningObjectiveEdge: "LearningObjectiveEdge";
	LearningObjectiveMark: "LearningObjectiveMark";
	LearningObjectiveMarkConnection: "LearningObjectiveMarkConnection";
	LearningObjectiveMarkEdge: "LearningObjectiveMarkEdge";
	LessonPlan: "LessonPlan";
	LessonPlanConnection: "LessonPlanConnection";
	LessonPlanEdge: "LessonPlanEdge";
	LoginRecord: "LoginRecord";
	LoginRecordConnection: "LoginRecordConnection";
	LoginRecordEdge: "LoginRecordEdge";
	MFAFactor: "MFAFactor";
	MFAFactorConnection: "MFAFactorConnection";
	MFAFactorEdge: "MFAFactorEdge";
	MFAInvalidOTPAttempt: "MFAInvalidOTPAttempt";
	MFAInvalidOTPAttemptConnection: "MFAInvalidOTPAttemptConnection";
	MFAInvalidOTPAttemptEdge: "MFAInvalidOTPAttemptEdge";
	MealMenu: "MealMenu";
	MealMenuConnection: "MealMenuConnection";
	MealMenuEdge: "MealMenuEdge";
	MealPlan: "MealPlan";
	MealPlanConnection: "MealPlanConnection";
	MealPlanEdge: "MealPlanEdge";
	Note: "Note";
	NoteConnection: "NoteConnection";
	NoteEdge: "NoteEdge";
	NoteAttachment: "NoteAttachment";
	NoteAttachmentConnection: "NoteAttachmentConnection";
	NoteAttachmentEdge: "NoteAttachmentEdge";
	NoteComment: "NoteComment";
	NoteCommentConnection: "NoteCommentConnection";
	NoteCommentEdge: "NoteCommentEdge";
	NoteReaction: "NoteReaction";
	NoteReactionConnection: "NoteReactionConnection";
	NoteReactionEdge: "NoteReactionEdge";
	Parent: "Parent";
	ParentConnection: "ParentConnection";
	ParentEdge: "ParentEdge";
	Payment: "Payment";
	PaymentConnection: "PaymentConnection";
	PaymentEdge: "PaymentEdge";
	PaymentLineItem: "PaymentLineItem";
	PaymentLineItemConnection: "PaymentLineItemConnection";
	PaymentLineItemEdge: "PaymentLineItemEdge";
	Permission: "Permission";
	PermissionConnection: "PermissionConnection";
	PermissionEdge: "PermissionEdge";
	PermissionGroup: "PermissionGroup";
	PermissionGroupConnection: "PermissionGroupConnection";
	PermissionGroupEdge: "PermissionGroupEdge";
	Person: "Person";
	PersonConnection: "PersonConnection";
	PersonEdge: "PersonEdge";
	PhoneNumber: "PhoneNumber";
	PhoneNumberConnection: "PhoneNumberConnection";
	PhoneNumberEdge: "PhoneNumberEdge";
	Quotation: "Quotation";
	QuotationConnection: "QuotationConnection";
	QuotationEdge: "QuotationEdge";
	Relationship: "Relationship";
	RelationshipConnection: "RelationshipConnection";
	RelationshipEdge: "RelationshipEdge";
	ReportCard: "ReportCard";
	ReportCardConnection: "ReportCardConnection";
	ReportCardEdge: "ReportCardEdge";
	Room: "Room";
	RoomConnection: "RoomConnection";
	RoomEdge: "RoomEdge";
	SchoolAttendanceRecord: "SchoolAttendanceRecord";
	SchoolAttendanceRecordConnection: "SchoolAttendanceRecordConnection";
	SchoolAttendanceRecordEdge: "SchoolAttendanceRecordEdge";
	SchoolStudentEnrollment: "SchoolStudentEnrollment";
	SchoolStudentEnrollmentConnection: "SchoolStudentEnrollmentConnection";
	SchoolStudentEnrollmentEdge: "SchoolStudentEnrollmentEdge";
	Service: "Service";
	ServiceConnection: "ServiceConnection";
	ServiceEdge: "ServiceEdge";
	ServiceSubscription: "ServiceSubscription";
	ServiceSubscriptionConnection: "ServiceSubscriptionConnection";
	ServiceSubscriptionEdge: "ServiceSubscriptionEdge";
	Session: "Session";
	SessionConnection: "SessionConnection";
	SessionEdge: "SessionEdge";
	Student: "Student";
	StudentConnection: "StudentConnection";
	StudentEdge: "StudentEdge";
	StudentDocument: "StudentDocument";
	StudentDocumentConnection: "StudentDocumentConnection";
	StudentDocumentEdge: "StudentDocumentEdge";
	Term: "Term";
	TermConnection: "TermConnection";
	TermEdge: "TermEdge";
	TrustedDevice: "TrustedDevice";
	TrustedDeviceConnection: "TrustedDeviceConnection";
	TrustedDeviceEdge: "TrustedDeviceEdge";
	Tuition: "Tuition";
	TuitionConnection: "TuitionConnection";
	TuitionEdge: "TuitionEdge";
	User: "User";
	UserConnection: "UserConnection";
	UserEdge: "UserEdge";
	UserGroup: "UserGroup";
	UserGroupConnection: "UserGroupConnection";
	UserGroupEdge: "UserGroupEdge";
};

/**
 * Stores the type name for each model for use in local GraphQL data (as the `__typename` field).
 */
export const graphTypeNames: GraphTypeNames = {
	AcademicYear: "AcademicYear",
	AcademicYearConnection: "AcademicYearConnection",
	AcademicYearEdge: "AcademicYearEdge",
	AcademicYearTuition: "AcademicYearTuition",
	AcademicYearTuitionConnection: "AcademicYearTuitionConnection",
	AcademicYearTuitionEdge: "AcademicYearTuitionEdge",
	Address: "Address",
	AddressConnection: "AddressConnection",
	AddressEdge: "AddressEdge",
	BillingSetupSubmission: "BillingSetupSubmission",
	BillingSetupSubmissionConnection: "BillingSetupSubmissionConnection",
	BillingSetupSubmissionEdge: "BillingSetupSubmissionEdge",
	Bus: "Bus",
	BusConnection: "BusConnection",
	BusEdge: "BusEdge",
	BusPlan: "BusPlan",
	BusPlanConnection: "BusPlanConnection",
	BusPlanEdge: "BusPlanEdge",
	CalendarEvent: "CalendarEvent",
	CalendarEventConnection: "CalendarEventConnection",
	CalendarEventEdge: "CalendarEventEdge",
	Campus: "Campus",
	CampusConnection: "CampusConnection",
	CampusEdge: "CampusEdge",
	ClassYear: "ClassYear",
	ClassYearConnection: "ClassYearConnection",
	ClassYearEdge: "ClassYearEdge",
	Contract: "Contract",
	ContractConnection: "ContractConnection",
	ContractEdge: "ContractEdge",
	ContractInstallment: "ContractInstallment",
	ContractInstallmentConnection: "ContractInstallmentConnection",
	ContractInstallmentEdge: "ContractInstallmentEdge",
	Course: "Course",
	CourseConnection: "CourseConnection",
	CourseEdge: "CourseEdge",
	CourseAttendanceRecord: "CourseAttendanceRecord",
	CourseAttendanceRecordConnection: "CourseAttendanceRecordConnection",
	CourseAttendanceRecordEdge: "CourseAttendanceRecordEdge",
	CourseSection: "CourseSection",
	CourseSectionConnection: "CourseSectionConnection",
	CourseSectionEdge: "CourseSectionEdge",
	CourseSectionPeriod: "CourseSectionPeriod",
	CourseSectionPeriodConnection: "CourseSectionPeriodConnection",
	CourseSectionPeriodEdge: "CourseSectionPeriodEdge",
	Discount: "Discount",
	DiscountConnection: "DiscountConnection",
	DiscountEdge: "DiscountEdge",
	DocumentResource: "DocumentResource",
	DocumentResourceConnection: "DocumentResourceConnection",
	DocumentResourceEdge: "DocumentResourceEdge",
	DocumentResourceFolder: "DocumentResourceFolder",
	DocumentResourceFolderConnection: "DocumentResourceFolderConnection",
	DocumentResourceFolderEdge: "DocumentResourceFolderEdge",
	DocumentResourceUserViewRecord: "DocumentResourceUserViewRecord",
	DocumentResourceUserViewRecordConnection: "DocumentResourceUserViewRecordConnection",
	DocumentResourceUserViewRecordEdge: "DocumentResourceUserViewRecordEdge",
	EmailAddress: "EmailAddress",
	EmailAddressConnection: "EmailAddressConnection",
	EmailAddressEdge: "EmailAddressEdge",
	Employee: "Employee",
	EmployeeConnection: "EmployeeConnection",
	EmployeeEdge: "EmployeeEdge",
	EnrollmentApplication: "EnrollmentApplication",
	EnrollmentApplicationConnection: "EnrollmentApplicationConnection",
	EnrollmentApplicationEdge: "EnrollmentApplicationEdge",
	EnrollmentApplicationNote: "EnrollmentApplicationNote",
	EnrollmentApplicationNoteConnection: "EnrollmentApplicationNoteConnection",
	EnrollmentApplicationNoteEdge: "EnrollmentApplicationNoteEdge",
	EnrollmentApplicationParent: "EnrollmentApplicationParent",
	EnrollmentApplicationParentConnection: "EnrollmentApplicationParentConnection",
	EnrollmentApplicationParentEdge: "EnrollmentApplicationParentEdge",
	EnrollmentApplicationStudent: "EnrollmentApplicationStudent",
	EnrollmentApplicationStudentConnection: "EnrollmentApplicationStudentConnection",
	EnrollmentApplicationStudentEdge: "EnrollmentApplicationStudentEdge",
	EnrollmentApplicationSubmission: "EnrollmentApplicationSubmission",
	EnrollmentApplicationSubmissionConnection: "EnrollmentApplicationSubmissionConnection",
	EnrollmentApplicationSubmissionEdge: "EnrollmentApplicationSubmissionEdge",
	EnrollmentApplicationUserViewRecord: "EnrollmentApplicationUserViewRecord",
	EnrollmentApplicationUserViewRecordConnection: "EnrollmentApplicationUserViewRecordConnection",
	EnrollmentApplicationUserViewRecordEdge: "EnrollmentApplicationUserViewRecordEdge",
	EnrollmentInvitation: "EnrollmentInvitation",
	EnrollmentInvitationConnection: "EnrollmentInvitationConnection",
	EnrollmentInvitationEdge: "EnrollmentInvitationEdge",
	Expense: "Expense",
	ExpenseConnection: "ExpenseConnection",
	ExpenseEdge: "ExpenseEdge",
	ExpenseBudget: "ExpenseBudget",
	ExpenseBudgetConnection: "ExpenseBudgetConnection",
	ExpenseBudgetEdge: "ExpenseBudgetEdge",
	ExpenseCategory: "ExpenseCategory",
	ExpenseCategoryConnection: "ExpenseCategoryConnection",
	ExpenseCategoryEdge: "ExpenseCategoryEdge",
	HomeRoom: "HomeRoom",
	HomeRoomConnection: "HomeRoomConnection",
	HomeRoomEdge: "HomeRoomEdge",
	HomeRoomSection: "HomeRoomSection",
	HomeRoomSectionConnection: "HomeRoomSectionConnection",
	HomeRoomSectionEdge: "HomeRoomSectionEdge",
	Invoice: "Invoice",
	InvoiceConnection: "InvoiceConnection",
	InvoiceEdge: "InvoiceEdge",
	InvoiceLineItem: "InvoiceLineItem",
	InvoiceLineItemConnection: "InvoiceLineItemConnection",
	InvoiceLineItemEdge: "InvoiceLineItemEdge",
	LearningObjective: "LearningObjective",
	LearningObjectiveConnection: "LearningObjectiveConnection",
	LearningObjectiveEdge: "LearningObjectiveEdge",
	LearningObjectiveMark: "LearningObjectiveMark",
	LearningObjectiveMarkConnection: "LearningObjectiveMarkConnection",
	LearningObjectiveMarkEdge: "LearningObjectiveMarkEdge",
	LessonPlan: "LessonPlan",
	LessonPlanConnection: "LessonPlanConnection",
	LessonPlanEdge: "LessonPlanEdge",
	LoginRecord: "LoginRecord",
	LoginRecordConnection: "LoginRecordConnection",
	LoginRecordEdge: "LoginRecordEdge",
	MFAFactor: "MFAFactor",
	MFAFactorConnection: "MFAFactorConnection",
	MFAFactorEdge: "MFAFactorEdge",
	MFAInvalidOTPAttempt: "MFAInvalidOTPAttempt",
	MFAInvalidOTPAttemptConnection: "MFAInvalidOTPAttemptConnection",
	MFAInvalidOTPAttemptEdge: "MFAInvalidOTPAttemptEdge",
	MealMenu: "MealMenu",
	MealMenuConnection: "MealMenuConnection",
	MealMenuEdge: "MealMenuEdge",
	MealPlan: "MealPlan",
	MealPlanConnection: "MealPlanConnection",
	MealPlanEdge: "MealPlanEdge",
	Note: "Note",
	NoteConnection: "NoteConnection",
	NoteEdge: "NoteEdge",
	NoteAttachment: "NoteAttachment",
	NoteAttachmentConnection: "NoteAttachmentConnection",
	NoteAttachmentEdge: "NoteAttachmentEdge",
	NoteComment: "NoteComment",
	NoteCommentConnection: "NoteCommentConnection",
	NoteCommentEdge: "NoteCommentEdge",
	NoteReaction: "NoteReaction",
	NoteReactionConnection: "NoteReactionConnection",
	NoteReactionEdge: "NoteReactionEdge",
	Parent: "Parent",
	ParentConnection: "ParentConnection",
	ParentEdge: "ParentEdge",
	Payment: "Payment",
	PaymentConnection: "PaymentConnection",
	PaymentEdge: "PaymentEdge",
	PaymentLineItem: "PaymentLineItem",
	PaymentLineItemConnection: "PaymentLineItemConnection",
	PaymentLineItemEdge: "PaymentLineItemEdge",
	Permission: "Permission",
	PermissionConnection: "PermissionConnection",
	PermissionEdge: "PermissionEdge",
	PermissionGroup: "PermissionGroup",
	PermissionGroupConnection: "PermissionGroupConnection",
	PermissionGroupEdge: "PermissionGroupEdge",
	Person: "Person",
	PersonConnection: "PersonConnection",
	PersonEdge: "PersonEdge",
	PhoneNumber: "PhoneNumber",
	PhoneNumberConnection: "PhoneNumberConnection",
	PhoneNumberEdge: "PhoneNumberEdge",
	Quotation: "Quotation",
	QuotationConnection: "QuotationConnection",
	QuotationEdge: "QuotationEdge",
	Relationship: "Relationship",
	RelationshipConnection: "RelationshipConnection",
	RelationshipEdge: "RelationshipEdge",
	ReportCard: "ReportCard",
	ReportCardConnection: "ReportCardConnection",
	ReportCardEdge: "ReportCardEdge",
	Room: "Room",
	RoomConnection: "RoomConnection",
	RoomEdge: "RoomEdge",
	SchoolAttendanceRecord: "SchoolAttendanceRecord",
	SchoolAttendanceRecordConnection: "SchoolAttendanceRecordConnection",
	SchoolAttendanceRecordEdge: "SchoolAttendanceRecordEdge",
	SchoolStudentEnrollment: "SchoolStudentEnrollment",
	SchoolStudentEnrollmentConnection: "SchoolStudentEnrollmentConnection",
	SchoolStudentEnrollmentEdge: "SchoolStudentEnrollmentEdge",
	Service: "Service",
	ServiceConnection: "ServiceConnection",
	ServiceEdge: "ServiceEdge",
	ServiceSubscription: "ServiceSubscription",
	ServiceSubscriptionConnection: "ServiceSubscriptionConnection",
	ServiceSubscriptionEdge: "ServiceSubscriptionEdge",
	Session: "Session",
	SessionConnection: "SessionConnection",
	SessionEdge: "SessionEdge",
	Student: "Student",
	StudentConnection: "StudentConnection",
	StudentEdge: "StudentEdge",
	StudentDocument: "StudentDocument",
	StudentDocumentConnection: "StudentDocumentConnection",
	StudentDocumentEdge: "StudentDocumentEdge",
	Term: "Term",
	TermConnection: "TermConnection",
	TermEdge: "TermEdge",
	TrustedDevice: "TrustedDevice",
	TrustedDeviceConnection: "TrustedDeviceConnection",
	TrustedDeviceEdge: "TrustedDeviceEdge",
	Tuition: "Tuition",
	TuitionConnection: "TuitionConnection",
	TuitionEdge: "TuitionEdge",
	User: "User",
	UserConnection: "UserConnection",
	UserEdge: "UserEdge",
	UserGroup: "UserGroup",
	UserGroupConnection: "UserGroupConnection",
	UserGroupEdge: "UserGroupEdge",
};
