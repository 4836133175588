import { getArrayDiff, NullPartial, omitKeys } from "@hex-insights/core";
import {
	InvoiceCreateInput,
	InvoiceDetailQuery,
	InvoiceLineItemInInvoiceCreateInput,
	InvoiceUpdateInput,
} from "../../GraphQL";
import { InvoiceFormValues } from "../ModelFormValues";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the Invoice model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(formValues: InvoiceFormValues.Create): InvoiceCreateInput {
	const input: NullPartial<InvoiceCreateInput> = {
		...formValues,
		invoiceLineItems: formValues.invoiceLineItems.map(invoiceLineItemToNestedGQLCreateInput),
	};
	return input as InvoiceCreateInput;
}

function invoiceLineItemToNestedGQLCreateInput(
	formValues: InvoiceFormValues.Create["invoiceLineItems"][0],
): InvoiceLineItemInInvoiceCreateInput {
	const input: NullPartial<InvoiceLineItemInInvoiceCreateInput> = { ...formValues };
	return input as InvoiceLineItemInInvoiceCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the Invoice model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<InvoiceFormValues.Detail>,
	initialFormValues: InvoiceFormValues.Detail,
): InvoiceUpdateInput {
	const inputFormValues = omitKeys(formValues, ["invoiceNumber", "invoiceLineItemIDs", "paymentLineItemIDs"]);
	const input: InvoiceUpdateInput = { ...inputFormValues };

	if (formValues.invoiceLineItemIDs !== undefined) {
		const { added, removed } = getArrayDiff(initialFormValues.invoiceLineItemIDs, formValues.invoiceLineItemIDs);
		input.addInvoiceLineItemIDs = added;
		input.removeInvoiceLineItemIDs = removed;
	}

	if (formValues.paymentLineItemIDs !== undefined) {
		const { added, removed } = getArrayDiff(initialFormValues.paymentLineItemIDs, formValues.paymentLineItemIDs);
		input.addPaymentLineItemIDs = added;
		input.removePaymentLineItemIDs = removed;
	}

	return input;
}

/**
 * Converts a Invoice instance to its corresponding detail form values.
 *
 * @param invoice The instance to convert.
 */
export function toFormValues(invoice: InvoiceDetailQuery["invoice"]): InvoiceFormValues.Detail {
	return {
		invoiceNumber: invoice.invoiceNumber,
		createdDate: invoice.createdDate,
		dueDate: invoice.dueDate,
		description: invoice.description,
		invoiceLineItemIDs: invoice.invoiceLineItems.map((e) => e.id),
		paymentLineItemIDs: invoice.paymentLineItems.map((e) => e.id),
		contractInstallmentID: invoice.contractInstallment?.id ?? null,
	};
}
