import React from "react";
import { GlobalSearch } from "@hex-insights/app-modules";
import {
	Button,
	Column,
	Icon,
	Modal,
	Row,
	Section,
	Tooltip,
	useClickOutListener,
	useOpenToggle,
} from "@hex-insights/core";
import { useFormState } from "@hex-insights/forms";
import { SearchResultFilterInput, Student } from "../../Utilities";
import { BigIcon } from "../BigIcon";
import { StudentDocumentCreateModal } from "../StudentDocument";
import { StudentNoteFormModal } from "../StudentNote";
import styles from "./styles.module.css";

type SearchQueryArgs = {
	filters: SearchResultFilterInput;
};

type UseSearch = (
	search: string,
	categories: GlobalSearch.SearchCategories,
	queryArgs: SearchQueryArgs,
) => ReturnType<typeof GlobalSearch.useSearch>;

export type StudentsQuickActionsProps = {
	useSearch: UseSearch;
};

export function StudentsQuickActions({ useSearch }: StudentsQuickActionsProps) {
	return (
		<Row justify="spaced-start">
			<Section style={{ flexGrow: 1 }}>
				<Section.Body style={{ padding: 0 }}>
					<Row justify="spaced-start" align="center">
						<StudentSearch useSearch={useSearch} />

						<StudentRecordCreateButton />
					</Row>
				</Section.Body>
			</Section>
		</Row>
	);
}

export type StudentRecordCreateButtonProps = {
	studentID?: Student["id"];
};

export function StudentRecordCreateButton({ studentID }: StudentRecordCreateButtonProps) {
	const { isOpen, toggleIsOpen, close } = useOpenToggle();
	const ref = React.useRef<HTMLDivElement | null>(null);
	useClickOutListener(ref.current, close);

	return (
		<div ref={ref}>
			<Tooltip.Container
				isControlled
				isOpen={isOpen}
				side="bottom"
				style={{ "--tooltip---background-color": "#fff" } as React.CSSProperties}
			>
				<Button
					onClick={toggleIsOpen}
					title="Write a note or upload a document"
					style={{ padding: 0, border: 0, display: "block" }}
				>
					<BigIcon name="plus" size={48} style={{ display: "block" }} />
				</Button>

				<Tooltip isInteractable className={styles["button-dropdown-tooltip"]}>
					<Tooltip.Body style={{ padding: 0 }}>
						<Column className={styles["button-dropdown-column"]}>
							<NoteController studentID={studentID} />
							<DocumentController studentID={studentID} />
						</Column>
					</Tooltip.Body>
				</Tooltip>
			</Tooltip.Container>
		</div>
	);
}

type NoteControllerProps = {
	studentID?: Student["id"];
};

function NoteController({ studentID }: NoteControllerProps) {
	const { isModalOpen, toggleIsModalOpen } = Modal.useToggle(false);

	return (
		<React.Fragment>
			<Button
				onClick={toggleIsModalOpen}
				style={{
					fontSize: "1.25rem",
					backgroundColor: "var(--light-blue)",
					color: "var(--verita-blue)",
					borderColor: "var(--verita-blue)",
				}}
			>
				<Row justify="spaced-start" horizontalSpacing="0.5rem" align="center" style={{ height: "100%" }}>
					<Icon.Edit size="1.25rem" /> Note
				</Row>
			</Button>

			<Modal.If condition={isModalOpen}>
				<StudentNoteFormModal studentID={studentID} onClose={toggleIsModalOpen} />
			</Modal.If>
		</React.Fragment>
	);
}

type DocumentControllerProps = {
	studentID?: Student["id"];
};

function DocumentController({ studentID }: DocumentControllerProps) {
	const { isModalOpen, toggleIsModalOpen } = Modal.useToggle(false);

	return (
		<React.Fragment>
			<Button
				onClick={toggleIsModalOpen}
				style={{
					fontSize: "1.25rem",
					backgroundColor: "var(--light-blue)",
					color: "var(--verita-blue)",
					borderColor: "var(--verita-blue)",
				}}
			>
				<Row justify="spaced-start" horizontalSpacing="0.5rem" align="center" style={{ height: "100%" }}>
					<Icon.UploadCloud size="1.25rem" /> Document
				</Row>
			</Button>

			<Modal.If condition={isModalOpen}>
				<StudentDocumentCreateModal studentID={studentID} onClose={toggleIsModalOpen} />
			</Modal.If>
		</React.Fragment>
	);
}

type StudentSearchFormValues = {
	search: string;
	includePages: boolean;
	includeRecords: boolean;
};

const initialStudentSearchFormValues: StudentSearchFormValues = {
	search: "",
	includePages: false,
	includeRecords: true,
};

const studentSearchDefaultQueryArgs = { filters: { modelNameIn: ["Student"] } };

type StudentSearchProps = {
	useSearch: UseSearch;
};

function StudentSearch({ useSearch }: StudentSearchProps) {
	const formState = useFormState({ initialFormValues: initialStudentSearchFormValues });
	const { isLoading, results } = useSearch(
		formState.formValues.search,
		formState.formValues,
		studentSearchDefaultQueryArgs,
	);

	return (
		<div className={styles["quick-actions__search-container"]} style={{ flexGrow: 1 }}>
			<GlobalSearch.Basic.SearchBar
				isLoading={isLoading}
				results={results}
				formState={formState}
				loadingElement={<GlobalSearch.Basic.SearchResultLoading />}
				noResultsElement={<GlobalSearch.Basic.NoSearchResults search={formState.formValues.search} />}
				displayResult={GlobalSearch.Basic.SearchResultDisplay}
				hotkeyIconClassName={styles["quick-actions__search__hot-key-icon"]}
				Footer={() => null}
				className={styles["quick-actions__search"]}
				inputClassName={styles["quick-actions__search__input"]}
			/>
		</div>
	);
}
