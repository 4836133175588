import React from "react";
import {
	EmployeeContext,
	useTeacherCurrentPeriod,
	useTeacherScheduleForDateQuery,
	useTeacherScheduleForDateWithLessonPlansQuery,
} from "@hex-insights/verita.shared";

export function useCurrentScheduleQuery() {
	return useCurrentTeacherScheduleForDateQuery(new Date());
}

export function useCurrentTeacherScheduleForDateQuery(date: Date) {
	const { employee } = React.useContext(EmployeeContext);
	return useTeacherScheduleForDateQuery(employee.id, date);
}

export function useCurrentPeriod(includeNext = false) {
	const { employee } = React.useContext(EmployeeContext);
	return useTeacherCurrentPeriod(employee.id, includeNext);
}

export function useCurrentScheduleWithLessonPlansQuery() {
	return useCurrentTeacherScheduleForDateWithLessonPlansQuery(new Date());
}

export function useCurrentTeacherScheduleForDateWithLessonPlansQuery(date: Date) {
	const { employee } = React.useContext(EmployeeContext);
	return useTeacherScheduleForDateWithLessonPlansQuery(employee.id, date);
}
