import { NullPartial } from "@hex-insights/core";
import { MealMenuCreateInput, MealMenuDetailQuery, MealMenuUpdateInput } from "../../GraphQL";
import { MealMenuFormValues } from "../ModelFormValues";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the MealMenu model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(formValues: MealMenuFormValues.Create): MealMenuCreateInput {
	const input: NullPartial<MealMenuCreateInput> = { ...formValues };
	return input as MealMenuCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the MealMenu model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<MealMenuFormValues.Detail>,
	_initialFormValues: MealMenuFormValues.Detail,
): MealMenuUpdateInput {
	const input: MealMenuUpdateInput = { ...formValues };
	return input;
}

/**
 * Converts a MealMenu instance to its corresponding detail form values.
 *
 * @param mealMenu The instance to convert.
 */
export function toFormValues(mealMenu: MealMenuDetailQuery["mealMenu"]): MealMenuFormValues.Detail {
	return {
		menuDate: mealMenu.menuDate,
		breakfast: mealMenu.breakfast,
		lunch: mealMenu.lunch,
		snack: mealMenu.snack,
		vegetarianBreakfast: mealMenu.vegetarianBreakfast,
		vegetarianLunch: mealMenu.vegetarianLunch,
		vegetarianSnack: mealMenu.vegetarianSnack,
	};
}
