import React from "react";
import { GraphQLError } from "graphql";
import { ApolloCache, FetchResult } from "@apollo/client";
import { convertGraphQLErrorsToSubmissionErrors } from "@hex-insights/app-modules";
import { getObjectEntries } from "@hex-insights/core";
import { FormValues, submissionFailure, submissionSuccess } from "@hex-insights/forms";
import {
	CourseAttendanceRecordFormConversion,
	CourseAttendanceRecordFormValues,
	CourseAttendanceRecordMutation,
	CourseSectionFormConversion,
	CourseSectionFormValues,
	CourseSectionMutation,
	ExpenseBudgetFormConversion,
	ExpenseBudgetFormValues,
	ExpenseBudgetMutation,
	HomeRoomSectionFormConversion,
	HomeRoomSectionFormValues,
	HomeRoomSectionMutation,
	LearningObjectiveMarkFormValues,
	MealMenuFormConversion,
	MealMenuFormValues,
	MealMenuMutation,
	ServiceSubscriptionFormConversion,
	ServiceSubscriptionFormValues,
	ServiceSubscriptionMutation,
} from "./Forms";
import {
	CourseAttendanceRecordsCreateBulkMutation,
	CourseSectionsCreateBulkMutation,
	ExpenseBudgetsCreateBulkMutation,
	HomeRoomSectionsCreateBulkMutation,
	LearningObjectiveMark,
	LearningObjectiveMarkValue,
	MealMenuCreateBulkMutation,
	ServiceSubscriptionsCreateBulkMutation,
	useCourseAttendanceRecordsCreateBulkMutation,
	useCourseSectionsCreateBulkMutation,
	useExpenseBudgetsCreateBulkMutation,
	useHomeRoomSectionsCreateBulkMutation,
	useLearningObjectiveMarkUpdateBulkValuesMutation,
	useMealMenuCreateBulkMutation,
	useServiceSubscriptionsCreateBulkMutation,
} from "./GraphQL/graphql-gen";

export type ApplyCreateBulkFunction<FV extends FormValues> = (
	formValues: FV[],
) => Promise<Readonly<GraphQLError[]> | null>;

export async function onCreateBulkSubmit<FV extends FormValues>(
	formValuesSets: FV[],
	applyCreate: ApplyCreateBulkFunction<FV>,
) {
	const errors = await applyCreate(formValuesSets);

	if (errors === null) {
		return submissionSuccess();
	}

	return submissionFailure(convertGraphQLErrorsToSubmissionErrors<FV>(errors));
}

export function useExpenseBudgetsBulkCreate() {
	const [createExpenseBudgets] = useExpenseBudgetsCreateBulkMutation();

	return React.useCallback(
		async (formValuesSets: ExpenseBudgetFormValues.Create[]) => {
			const inputs = formValuesSets.map((formValues) => ExpenseBudgetFormConversion.toGQLCreateInput(formValues));
			const updateCacheFns = inputs.map((input) => ExpenseBudgetMutation.getUpdateCacheForCreate(input));
			const updateCache = (
				cache: ApolloCache<any>,
				result: Omit<
					FetchResult<ExpenseBudgetsCreateBulkMutation, Record<string, any>, Record<string, any>>,
					"context"
				>,
			) => {
				const edges = result.data?.createBulkExpenseBudgets.edges ?? [];
				for (let i = 0; i < edges.length; i++) {
					const obj = edges[i].node;
					updateCacheFns[i](cache, { data: { createExpenseBudget: obj } });
				}
			};

			const { data, errors } = await createExpenseBudgets({ variables: { inputs }, update: updateCache });

			return { data: data?.createBulkExpenseBudgets ?? null, errors: errors ?? null };
		},
		[createExpenseBudgets],
	);
}

export function useHomeRoomSectionsBulkCreate() {
	const [createHomeRoomSections] = useHomeRoomSectionsCreateBulkMutation();

	return React.useCallback(
		async (formValuesSets: HomeRoomSectionFormValues.Create[]) => {
			const inputs = formValuesSets.map((formValues) => HomeRoomSectionFormConversion.toGQLCreateInput(formValues));
			const updateCacheFns = inputs.map((input) => HomeRoomSectionMutation.getUpdateCacheForCreate(input));
			const updateCache = (
				cache: ApolloCache<any>,
				result: Omit<
					FetchResult<HomeRoomSectionsCreateBulkMutation, Record<string, any>, Record<string, any>>,
					"context"
				>,
			) => {
				const edges = result.data?.createBulkHomeRoomSections.edges ?? [];
				for (let i = 0; i < edges.length; i++) {
					const obj = edges[i].node;
					updateCacheFns[i](cache, { data: { createHomeRoomSection: obj } });
				}
			};

			const { data, errors } = await createHomeRoomSections({ variables: { inputs }, update: updateCache });

			return { data: data?.createBulkHomeRoomSections ?? null, errors: errors ?? null };
		},
		[createHomeRoomSections],
	);
}

export function useCourseSectionsBulkCreate() {
	const [createCourseSections] = useCourseSectionsCreateBulkMutation();

	return React.useCallback(
		async (formValuesSets: CourseSectionFormValues.Create[]) => {
			const inputs = formValuesSets.map((formValues) => CourseSectionFormConversion.toGQLCreateInput(formValues));
			const updateCacheFns = inputs.map((input) => CourseSectionMutation.getUpdateCacheForCreate(input));
			const updateCache = (
				cache: ApolloCache<any>,
				result: Omit<
					FetchResult<CourseSectionsCreateBulkMutation, Record<string, any>, Record<string, any>>,
					"context"
				>,
			) => {
				const edges = result.data?.createBulkCourseSections.edges ?? [];
				for (let i = 0; i < edges.length; i++) {
					const obj = edges[i].node;
					updateCacheFns[i](cache, { data: { createCourseSection: obj } });
				}
			};

			const { data, errors } = await createCourseSections({ variables: { inputs }, update: updateCache });

			return { data: data?.createBulkCourseSections ?? null, errors: errors ?? null };
		},
		[createCourseSections],
	);
}

export function useCourseAttendanceRecordsBulkCreate() {
	const [createCourseAttendanceRecords] = useCourseAttendanceRecordsCreateBulkMutation();

	return React.useCallback(
		async (formValuesSets: CourseAttendanceRecordFormValues.Create[]) => {
			const inputs = formValuesSets.map((formValues) =>
				CourseAttendanceRecordFormConversion.toGQLCreateInput(formValues),
			);
			const updateCacheFns = inputs.map((input) => CourseAttendanceRecordMutation.getUpdateCacheForCreate(input));
			const updateCache = (
				cache: ApolloCache<any>,
				result: Omit<
					FetchResult<CourseAttendanceRecordsCreateBulkMutation, Record<string, any>, Record<string, any>>,
					"context"
				>,
			) => {
				const edges = result.data?.createBulkCourseAttendanceRecords.edges ?? [];
				for (let i = 0; i < edges.length; i++) {
					const obj = edges[i].node;
					updateCacheFns[i](cache, { data: { createCourseAttendanceRecord: obj } });
				}
			};

			const { data, errors } = await createCourseAttendanceRecords({
				variables: { inputs },
				update: updateCache,
			});

			return { data: data?.createBulkCourseAttendanceRecords ?? null, errors: errors ?? null };
		},
		[createCourseAttendanceRecords],
	);
}

type LearningObjectiveMarkValueFormValues = Record<
	LearningObjectiveMark["id"],
	LearningObjectiveMarkFormValues.Detail["value"]
>;

export function useLearningObjectiveMarkValuesBulkUpdate() {
	const [updateLearningObjectiveMarkValues] = useLearningObjectiveMarkUpdateBulkValuesMutation();

	return React.useCallback(
		async (
			changedFormValues: Partial<LearningObjectiveMarkValueFormValues>,
			_initialFormValues: LearningObjectiveMarkValueFormValues,
		) => {
			const developingIDs: LearningObjectiveMark["id"][] = [];
			const meetingIDs: LearningObjectiveMark["id"][] = [];
			const exceedingIDs: LearningObjectiveMark["id"][] = [];
			const clearIDs: LearningObjectiveMark["id"][] = [];

			const idValuePairs = getObjectEntries(changedFormValues);
			for (let i = 0; i < idValuePairs.length; i++) {
				const [id, value] = idValuePairs[i];
				if (value === LearningObjectiveMarkValue.Developing) {
					developingIDs.push(id);
				}
				if (value === LearningObjectiveMarkValue.Meeting) {
					meetingIDs.push(id);
				}
				if (value === LearningObjectiveMarkValue.Exceeding) {
					exceedingIDs.push(id);
				}
				if (value === null) {
					clearIDs.push(id);
				}
			}

			if (developingIDs.length === 0) {
				developingIDs.push("-1");
			}
			if (meetingIDs.length === 0) {
				meetingIDs.push("-1");
			}
			if (exceedingIDs.length === 0) {
				exceedingIDs.push("-1");
			}
			if (clearIDs.length === 0) {
				clearIDs.push("-1");
			}

			// Doesn't need to update cache because edges aren't updated

			const { data, errors } = await updateLearningObjectiveMarkValues({
				variables: {
					developingIDs,
					meetingIDs,
					exceedingIDs,
					clearIDs,
				},
			});

			return { data, errors: errors ?? null };
		},
		[updateLearningObjectiveMarkValues],
	);
}

export function useServiceSubscriptionBulkCreate() {
	const [createServiceSubscriptions] = useServiceSubscriptionsCreateBulkMutation();

	return React.useCallback(
		async (formValuesSets: ServiceSubscriptionFormValues.Create[]) => {
			const inputs = formValuesSets.map((formValues) => ServiceSubscriptionFormConversion.toGQLCreateInput(formValues));
			const updateCacheFns = inputs.map((input) => ServiceSubscriptionMutation.getUpdateCacheForCreate(input));
			const updateCache = (
				cache: ApolloCache<any>,
				result: Omit<
					FetchResult<ServiceSubscriptionsCreateBulkMutation, Record<string, any>, Record<string, any>>,
					"context"
				>,
			) => {
				const edges = result.data?.createBulkServiceSubscriptions.edges ?? [];
				for (let i = 0; i < edges.length; i++) {
					const obj = edges[i].node;
					updateCacheFns[i](cache, { data: { createServiceSubscription: obj } });
				}
			};

			const { data, errors } = await createServiceSubscriptions({
				variables: { inputs },
				update: updateCache,
			});

			return { data: data?.createBulkServiceSubscriptions ?? null, errors: errors ?? null };
		},
		[createServiceSubscriptions],
	);
}

export function useMealMenuBulkCreate() {
	const [createMealMenus] = useMealMenuCreateBulkMutation();

	return React.useCallback(
		async (formValuesSets: MealMenuFormValues.Create[]) => {
			const inputs = formValuesSets.map((formValues) => MealMenuFormConversion.toGQLCreateInput(formValues));
			const updateCacheFns = inputs.map((input) => MealMenuMutation.getUpdateCacheForCreate(input));
			const updateCache = (
				cache: ApolloCache<any>,
				result: Omit<FetchResult<MealMenuCreateBulkMutation, Record<string, any>, Record<string, any>>, "context">,
			) => {
				const edges = result.data?.createBulkMealMenus.edges ?? [];
				for (let i = 0; i < edges.length; i++) {
					const obj = edges[i].node;
					updateCacheFns[i](cache, { data: { createMealMenu: obj } });
				}
			};

			const { data, errors } = await createMealMenus({ variables: { inputs }, update: updateCache });

			return { data: data?.createBulkMealMenus ?? null, errors: errors ?? null };
		},
		[createMealMenus],
	);
}
