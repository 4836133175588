import React from "react";
import { onUpdateSubmit } from "@hex-insights/app-modules";
import {
	Button,
	Column,
	Conditional,
	Else,
	Grid,
	Heading,
	Icon,
	If,
	joinStrings,
	List,
	Modal,
	ModalProps,
	RequiredKeys,
	Row,
	Section,
	StyleProps,
	Tooltip,
	useClickOutListener,
	useOpenToggle,
	useSync,
	useToggle,
} from "@hex-insights/core";
import {
	anyFieldChanged,
	Form,
	FormSubmitFunction,
	FormType,
	SubmissionStatus,
	useFormState,
} from "@hex-insights/forms";
import { ExternalLink } from "@hex-insights/router";
import {
	AddressLink,
	HorizontalScrollingContainer,
	ParentForm,
	ParentLink,
	PersonIcon,
	RelationshipDeleteButton,
	RelationshipForm,
	RelationshipLink,
	ScaledText,
	StudentField,
	StudentLink,
	Tile,
} from "../../../../Components";
import {
	NoteNoteType,
	NoteOrderField,
	OrderDirection,
	Parent,
	ParentFormValues,
	ParentMutation,
	Relationship,
	RelationshipFormat,
	RelationshipFormState,
	RelationshipFormValues,
	RelationshipMutation,
	Student,
	StudentDetailQuery,
	StudentFormConversion,
	StudentFormValues,
	StudentMutation,
	StudentParentListQuery,
	useNoteStudentNotesListQuery,
	useParentDetailQuery,
	useRelationshipDetailQuery,
	useStudentAddressListQuery,
	useStudentDetailQuery,
	useStudentParentListQuery,
	useStudentSiblingListQuery,
} from "../../../../Utilities";
import { IconTileHeader } from "../Components";
import { HealthNoteDisplay, NewHealthNoteController } from "./HealthNotes";
import styles from "./styles.module.css";

export type MainProps = {
	studentID: Student["id"];
};

export function Main({ studentID }: MainProps) {
	return (
		<Column justify="spaced-start" style={{ height: "100%", minHeight: 0 }}>
			<Tile style={{ flexGrow: 1, height: "100%", overflow: "hidden" }}>
				<Tile.Body style={{ height: "100%", overflow: "scroll" }}>
					<Column style={{ height: "100%" }}>
						<Parents studentID={studentID} />
						<Row justify="spaced-start" style={{ width: "100%", height: "100%", overflow: "hidden" }}>
							<Siblings studentID={studentID} />
							<Addresses studentID={studentID} />
						</Row>
					</Column>
				</Tile.Body>
			</Tile>
			<HealthNotesSection studentID={studentID} />
		</Column>
	);
}

function Parents({ studentID }: { studentID: Student["id"] }) {
	const { loading, data } = useStudentParentListQuery({ variables: { filters: { studentIDEQ: studentID } } });

	if (loading) {
		return <span>Loading...</span>;
	}

	if (!data) {
		return null;
	}

	return (
		<Section>
			<Section.Body style={{ padding: 0 }}>
				<HorizontalScrollingContainer>
					<Row justify="spaced-start" horizontalSpacing="1.25rem">
						{data.relationshipConnection.edges.map((e) => (
							<ParentCard key={e.node.id} relationship={e.node} style={{ flexShrink: 0, width: "25rem" }} />
						))}
					</Row>
				</HorizontalScrollingContainer>
			</Section.Body>
			<Section.Footer>
				<RelationshipCreateController studentID={studentID} />
			</Section.Footer>
		</Section>
	);
}

type ParentCardProps = {
	relationship: StudentParentListQuery["relationshipConnection"]["edges"][0]["node"];
} & Partial<StyleProps>;

function ParentCard({ relationship, style }: ParentCardProps) {
	const { isModalOpen, toggleIsModalOpen } = Modal.useToggle(false);

	return (
		<Section className={styles["parent-card"]} style={style}>
			<Section.Header className={styles["parent-card__header"]}>
				<RelationshipCardHeaderContent relationship={relationship} />
			</Section.Header>

			<Section.Body className={styles["parent-card__body"]}>
				<Column justify="space-between" style={{ height: "100%" }}>
					<RelationshipCardBodyIconStats relationship={relationship} />

					<ParentCardContactMethods relationship={relationship} toggleIsModalOpen={toggleIsModalOpen} />

					<Modal.If condition={isModalOpen}>
						<RelationshipDetailModal relationship={relationship} onClose={toggleIsModalOpen} />
					</Modal.If>
				</Column>
			</Section.Body>
		</Section>
	);
}

export type ParentCardContactMethodsProps = {
	relationship: StudentParentListQuery["relationshipConnection"]["edges"][0]["node"];
	toggleIsModalOpen: () => void;
};

export function ParentCardContactMethods({ relationship, toggleIsModalOpen }: ParentCardContactMethodsProps) {
	return (
		<Row
			justify="space-between"
			style={{ "--external-link---text-decoration": "none", padding: "0 0.5rem" } as React.CSSProperties}
		>
			<Conditional>
				<If condition={relationship.parent.person.phoneNumbers.length > 0}>
					{relationship.parent.person.phoneNumbers.length > 0 && (
						<Row justify="spaced-start" horizontalSpacing="0.5rem" align="center">
							<ExternalLink href={`tel:${relationship.parent.person.phoneNumbers[0].phoneNumber}`}>
								<Row justify="spaced-start" horizontalSpacing="0.25rem" align="center">
									<Icon.Phone size="0.9rem" />
									{relationship.parent.person.phoneNumbers[0].phoneNumber}
								</Row>
							</ExternalLink>

							<If condition={relationship.parent.person.phoneNumbers.length > 1}>
								<Button
									variant="link"
									onClick={toggleIsModalOpen}
									style={{ whiteSpace: "nowrap", padding: 0, border: "none" }}
								>
									+{relationship.parent.person.phoneNumbers.length - 1}
								</Button>
							</If>
						</Row>
					)}
				</If>
				<Else>
					<span></span>
				</Else>
			</Conditional>
			<Conditional>
				<If condition={relationship.parent.person.emailAddresses.length > 0}>
					{relationship.parent.person.emailAddresses.length > 0 && (
						<Row justify="spaced-start" horizontalSpacing="0.5rem" align="center">
							<ExternalLink href={`mailto:${relationship.parent.person.emailAddresses[0].email}`}>
								<Row justify="spaced-start" horizontalSpacing="0.25rem" align="center">
									<Icon.Mail size="0.9rem" />
									{relationship.parent.person.emailAddresses[0].email}
								</Row>
							</ExternalLink>

							<If condition={relationship.parent.person.emailAddresses.length > 1}>
								<Button
									variant="link"
									onClick={toggleIsModalOpen}
									style={{ whiteSpace: "nowrap", padding: 0, border: "none" }}
								>
									+{relationship.parent.person.emailAddresses.length - 1}
								</Button>
							</If>
						</Row>
					)}
				</If>
				<Else>
					<span></span>
				</Else>
			</Conditional>
		</Row>
	);
}

export type RelationshipDetailModalProps = {
	relationship: StudentParentListQuery["relationshipConnection"]["edges"][0]["node"];
} & Pick<ModalProps, "ifRef" | "onClose">;

export function RelationshipDetailModal({ relationship, ifRef, onClose }: RelationshipDetailModalProps) {
	return (
		<Modal ifRef={ifRef} onClose={onClose} className={styles["parent-card"]}>
			<Modal.Header className={styles["parent-card__header"]}>
				<RelationshipCardHeaderContent relationship={relationship} />
			</Modal.Header>
			<Modal.Body className={styles["parent-card__body"]}>
				<Column justify="space-between" style={{ height: "100%" }}>
					<RelationshipCardBodyIconStats relationship={relationship} />

					<Row
						justify="space-between"
						style={{ "--external-link---text-decoration": "none", padding: "0 0.5rem" } as React.CSSProperties}
					>
						<Column>
							<Row
								justify="spaced-start"
								horizontalSpacing="0.25rem"
								align="center"
								style={{ borderBottom: "1px solid #ccc" }}
							>
								<Icon.Phone size="0.9rem" /> Phone
							</Row>

							<List style={{ listStyleType: "none" }}>
								<Conditional>
									<If condition={relationship.parent.person.phoneNumbers.length > 0}>
										{relationship.parent.person.phoneNumbers.map((e) => (
											<List.Item key={e.id}>
												<ExternalLink href={`tel:${e.phoneNumber}`}>{e.phoneNumber}</ExternalLink>
											</List.Item>
										))}
									</If>
									<Else>None found</Else>
								</Conditional>
							</List>
						</Column>

						<Column>
							<Row
								justify="spaced-start"
								horizontalSpacing="0.25rem"
								align="center"
								style={{ borderBottom: "1px solid #ccc" }}
							>
								<Icon.Mail size="0.9rem" /> Email
							</Row>

							<List style={{ listStyleType: "none" }}>
								<Conditional>
									<If condition={relationship.parent.person.emailAddresses.length > 0}>
										{relationship.parent.person.emailAddresses.map((e) => (
											<List.Item key={e.id}>
												<ExternalLink href={`mailto:${e.email}`}>{e.email}</ExternalLink>
											</List.Item>
										))}
									</If>
									<Else>None found</Else>
								</Conditional>
							</List>
						</Column>
					</Row>
				</Column>
			</Modal.Body>
		</Modal>
	);
}

type RelationshipCardHeaderContentProps = {
	relationship: Pick<
		StudentParentListQuery["relationshipConnection"]["edges"][0]["node"],
		"id" | "parent" | "relationshipType" | "isPrimary"
	>;
};

function RelationshipCardHeaderContent({ relationship }: RelationshipCardHeaderContentProps) {
	return (
		<Row justify="spaced-start">
			<PersonIcon
				person={relationship.parent.person}
				imageSize="4rem"
				style={{
					position: "absolute",
					top: "calc(-1 * var(--border-width))",
					left: "calc(-1 * var(--border-width))",
				}}
			/>

			<Column
				justify="space-between"
				style={{
					width: "100%",
					height: "3.25rem",
					marginLeft: "4rem",
					padding: "0.25rem 0",
				}}
			>
				<Row justify="space-between">
					<RelationshipLink
						instance={relationship}
						style={
							{
								"--internal-link---text-decoration": "none",
								color: "var(--verita-blue)",
							} as React.CSSProperties
						}
					>
						{RelationshipFormat.Fields.relationshipType(relationship.relationshipType)}
					</RelationshipLink>

					{relationship.isPrimary && <span style={{ color: "var(--verita-blue)" }}>Primary</span>}
				</Row>

				<Row style={{ position: "relative" }}>
					<ParentLink
						instance={relationship.parent}
						style={
							{
								"--internal-link---text-decoration": "none",
								width: "fit-content",
							} as React.CSSProperties
						}
					>
						<ScaledText
							maxFontSize={24}
							style={{
								width: "100%",
								height: "2.25rem",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
							}}
						>
							{relationship.parent.person.name}
						</ScaledText>
					</ParentLink>

					<RelationshipEditor relationship={relationship} />
				</Row>
			</Column>
		</Row>
	);
}

type RelationshipEditorProps = {
	relationship: Pick<Relationship, "id"> & {
		parent: Pick<Relationship["parent"], "id">;
	};
};

function RelationshipEditor({ relationship }: RelationshipEditorProps) {
	const { isOpen, toggleIsOpen, close } = useOpenToggle(false);
	const ref = React.useRef<HTMLDivElement | null>(null);
	useClickOutListener(ref.current, close);

	const { isOn: isRelationshipModalOpen, toggle: toggleIsRelationshipModalOpen } = useToggle(false);
	const { isOn: isParentModalOpen, toggle: toggleIsParentModalOpen } = useToggle(false);

	return (
		<Row justify="center" align="center" className={styles["parent-card__edit-button-container"]}>
			<div ref={ref}>
				<Tooltip.Container
					isControlled
					isOpen={isOpen}
					side="bottom"
					style={{ "--tooltip---background-color": "#fff" } as React.CSSProperties}
				>
					<Button
						variant="link"
						size="small"
						onClick={toggleIsOpen}
						title="Edit"
						className={styles["parent-card__edit-button"]}
					>
						<Icon.Settings size="0.9rem" />
					</Button>

					<Tooltip isInteractable>
						<Tooltip.Body style={{ padding: 0 }}>
							<Column className={styles["button-dropdown-column"]}>
								<Button variant="tertiary" size="small" onClick={toggleIsRelationshipModalOpen}>
									Edit Relationship
								</Button>
								<Button variant="tertiary" size="small" onClick={toggleIsParentModalOpen}>
									Edit Parent
								</Button>
							</Column>
						</Tooltip.Body>
					</Tooltip>
				</Tooltip.Container>
			</div>

			<Modal.If condition={isRelationshipModalOpen}>
				<RelationshipEditModal relationshipID={relationship.id} onClose={toggleIsRelationshipModalOpen} />
			</Modal.If>
			<Modal.If condition={isParentModalOpen}>
				<ParentEditModal parentID={relationship.parent.id} onClose={toggleIsParentModalOpen} />
			</Modal.If>
		</Row>
	);
}

type ParentEditModalProps = {
	parentID: Parent["id"];
} & RequiredKeys<Pick<ModalProps, "ifRef" | "onClose">, "onClose">;

function ParentEditModal({ parentID, ifRef, onClose }: ParentEditModalProps) {
	const { loading, data } = useParentDetailQuery({ variables: { id: parentID } });

	const update = ParentMutation.useUpdateWithPerson(parentID);
	const applyUpdate = React.useCallback(
		async (
			changedFormValues: Partial<ParentFormValues.DetailWithPerson>,
			initialFormValues: ParentFormValues.DetailWithPerson,
		) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<Modal ifRef={ifRef} onClose={onClose}>
			<Modal.Body>
				<Conditional>
					<If condition={loading}>Loading...</If>
					<Else>
						{data && (
							<ParentForm.ControlledDetailWithPerson
								parent={data.parent}
								applyUpdate={applyUpdate}
								onSuccess={onClose}
							/>
						)}
					</Else>
				</Conditional>
			</Modal.Body>
		</Modal>
	);
}

type RelationshipEditModalProps = {
	relationshipID: Relationship["id"];
} & RequiredKeys<Pick<ModalProps, "ifRef" | "onClose">, "onClose">;

function RelationshipEditModal({ relationshipID, ifRef, onClose }: RelationshipEditModalProps) {
	const { loading, data } = useRelationshipDetailQuery({ variables: { id: relationshipID } });

	const update = RelationshipMutation.useUpdate(relationshipID);
	const applyUpdate = React.useCallback(
		async (
			changedFormValues: Partial<RelationshipFormValues.Detail>,
			initialFormValues: RelationshipFormValues.Detail,
		) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<Modal ifRef={ifRef} onClose={onClose}>
			<Modal.Body>
				<Conditional>
					<If condition={loading}>Loading...</If>
					<Else>
						{data && (
							<RelationshipForm.ControlledDetail
								relationship={data.relationship}
								applyUpdate={applyUpdate}
								onSuccess={onClose}
							/>
						)}
					</Else>
				</Conditional>
			</Modal.Body>
			<Modal.Footer>
				<Row justify="center" align="center">
					<RelationshipDeleteButton instanceID={relationshipID} onSuccess={onClose} size="small" />
				</Row>
			</Modal.Footer>
		</Modal>
	);
}

type RelationshipCardBodyIconStatsProps = {
	relationship: Pick<
		StudentParentListQuery["relationshipConnection"]["edges"][0]["node"],
		"isFinancialContact" | "isEmergencyContact" | "isAuthorizedForPickup" | "livesWithStudent"
	>;
};

function RelationshipCardBodyIconStats({ relationship }: RelationshipCardBodyIconStatsProps) {
	return (
		<Grid columns={2} style={{ marginBottom: "0.5rem" }}>
			<If condition={relationship.isFinancialContact}>
				<Row justify="spaced-start" horizontalSpacing="0.25rem" align="center">
					<Icon.DollarSign size="0.9rem" /> Financial
				</Row>
			</If>
			<If condition={relationship.isEmergencyContact}>
				<Row justify="spaced-start" horizontalSpacing="0.25rem" align="center">
					<Icon.AlertTriangle size="0.9rem" /> Emergency
				</Row>
			</If>
			<If condition={relationship.isAuthorizedForPickup}>
				<Row justify="spaced-start" horizontalSpacing="0.25rem" align="center">
					<Icon.Truck size="0.9rem" /> Pick Up Authorized
				</Row>
			</If>
			<If condition={relationship.livesWithStudent}>
				<Row justify="spaced-start" horizontalSpacing="0.25rem" align="center">
					<Icon.Home size="0.9rem" /> Lives With Student
				</Row>
			</If>
		</Grid>
	);
}

export type RelationshipCreateControllerProps = {
	studentID: Student["id"];
};

export function RelationshipCreateController({ studentID }: RelationshipCreateControllerProps) {
	const { isModalOpen, toggleIsModalOpen } = Modal.useToggle(false);

	return (
		<React.Fragment>
			<Button
				variant="link"
				size="small"
				onClick={toggleIsModalOpen}
				style={{ "--button--link--hover-focus---background-color": "#f4f4f4" } as React.CSSProperties}
			>
				<Row justify="spaced-start" horizontalSpacing="0.25rem" align="center">
					<Icon.Plus size="1rem" style={{ display: "block" }} /> Add Relative
				</Row>
			</Button>

			<Modal.If condition={isModalOpen}>
				<RelationshipCreateModal studentID={studentID} onClose={toggleIsModalOpen} />
			</Modal.If>
		</React.Fragment>
	);
}

type RelationshipCreateModalProps = {
	studentID: Student["id"];
} & RequiredKeys<Pick<ModalProps, "ifRef" | "onClose">, "onClose">;

function RelationshipCreateModal({ studentID, ifRef, onClose }: RelationshipCreateModalProps) {
	const initialFormValues = React.useMemo(() => ({ studentID }), [studentID]);
	const formState = RelationshipFormState.useCreateFormState(initialFormValues);

	const create = RelationshipMutation.useCreate();

	const applyCreate = React.useCallback(
		async (formValues: RelationshipFormValues.Create) => {
			const { errors } = await create(formValues);
			return errors;
		},
		[create],
	);

	return (
		<Modal ifRef={ifRef} onClose={onClose} confirmOnClose={anyFieldChanged(formState)} style={{ width: "fit-content" }}>
			<Modal.Header>
				<Heading level={2} noMargin>
					New Relationship
				</Heading>
			</Modal.Header>
			<Modal.Body>
				<RelationshipForm.Create formState={formState} applyCreate={applyCreate} onSuccess={onClose} />
			</Modal.Body>
		</Modal>
	);
}

function Siblings({ studentID }: { studentID: Student["id"] }) {
	const { loading, data } = useStudentSiblingListQuery({ variables: { studentID } });

	return (
		<Section style={{ flexGrow: 0, flexShrink: 0, height: "100%", overflow: "hidden" }}>
			<Section.Header style={{ padding: 0 }}>
				<Heading level={3} noMargin>
					Siblings
				</Heading>
			</Section.Header>
			<Section.Body style={{ height: "100%", padding: "0.25rem 0.5rem", overflow: "scroll" }}>
				<Conditional>
					<If condition={loading}>Loading...</If>
					<If condition={data?.studentConnection.edges.length === 0}>No siblings at Verita.</If>
					<Else>
						<Column justify="spaced-start">
							{data?.studentConnection.edges.map((e) => (
								<StudentLink
									key={e.node.id}
									instance={e.node}
									className="link--no-text-decoration"
									style={{
										fontSize: "1.1rem",
									}}
								>
									<Row justify="spaced-start" horizontalSpacing="0.5rem" align="center">
										<PersonIcon person={e.node.person} studentID={e.node.id} imageSize="1.25rem" />

										<span
											style={{
												width: "100%",
												whiteSpace: "nowrap",
												textOverflow: "ellipsis",
												overflow: "hidden",
											}}
										>
											{e.node.person.name}
										</span>
									</Row>
								</StudentLink>
							))}
						</Column>
					</Else>
				</Conditional>
			</Section.Body>
		</Section>
	);
}

function Addresses({ studentID }: { studentID: Student["id"] }) {
	const { loading, data } = useStudentAddressListQuery({ variables: { studentID } });

	return (
		<Section style={{ flexGrow: 1, width: "100%", overflow: "hidden" }}>
			<Section.Header style={{ padding: 0 }}>
				<Heading level={3} noMargin>
					Addresses
				</Heading>
			</Section.Header>
			<Section.Body>
				<Conditional>
					<If condition={loading}>Loading...</If>
					<If condition={data?.addressConnection.edges.length === 0}>No address information found.</If>
					<Else>
						<HorizontalScrollingContainer>
							<Row justify="spaced-start">
								{data?.addressConnection.edges.map((e) => (
									<Row key={e.node.id} className={styles["address-card"]}>
										<Column className={styles["address-card__header"]} justify="center" align="center">
											<Icon.Home style={{ color: "var(--verita-blue)" }} />
										</Column>

										<Column className={styles["address-card__body"]}>
											<AddressLink
												instance={{ id: e.node.id }}
												style={
													{
														fontSize: "1.5rem",
														whiteSpace: "nowrap",
														"--internal-link---text-decoration": "none",
													} as React.CSSProperties
												}
											>
												{e.node.addressLine1}
											</AddressLink>
											<If condition={e.node.addressLine2 !== ""}>
												<span style={{ whiteSpace: "nowrap" }}>{e.node.addressLine2}</span>
											</If>
											<span style={{ whiteSpace: "nowrap" }}>{e.node.city}</span>
											<span style={{ whiteSpace: "nowrap" }}>
												{joinStrings([e.node.stateOrProvince, e.node.postalCode], ", ")}
											</span>
											<span style={{ whiteSpace: "nowrap" }}>{e.node.country}</span>
										</Column>
									</Row>
								))}
							</Row>
						</HorizontalScrollingContainer>
					</Else>
				</Conditional>
			</Section.Body>
		</Section>
	);
}

function HealthNotesSection({ studentID }: { studentID: Student["id"] }) {
	const { data } = useNoteStudentNotesListQuery({
		variables: {
			filters: {
				noteTypeIn: [NoteNoteType.Health, NoteNoteType.Incident, NoteNoteType.Injury],
				students: [{ idEQ: studentID }],
			},
			order: { field: NoteOrderField.CreatedAt, direction: OrderDirection.Desc },
		},
	});

	return (
		<Tile>
			<Tile.Body>
				<Row justify="spaced-start" style={{ height: "100%" }}>
					<HorizontalScrollingContainer className={styles["notes-scrolling-container"]}>
						<Row justify="spaced-start" style={{ height: "100%" }}>
							{data?.noteConnection.edges.map((e) => (
								<HealthNoteDisplay key={e.node.id} note={e.node} />
							))}
						</Row>
					</HorizontalScrollingContainer>
					<Row>
						<NewHealthNoteController studentID={studentID} />
					</Row>
				</Row>
			</Tile.Body>
		</Tile>
	);
}

export type LeftColumnProps = {
	studentID: Student["id"];
};

export function LeftColumn({ studentID }: LeftColumnProps) {
	const { data } = useStudentDetailQuery({ variables: { id: studentID } });

	return (
		<Tile style={{ flexGrow: 1, height: "100%", overflow: "hidden" }}>
			<IconTileHeader Icon={Icon.Heart}>Health Information</IconTileHeader>
			<Tile.Body style={{ height: "100%", overflow: "scroll" }}>
				{!!data && <FamilyAndHealthLeftColumnContent student={data.student} />}
			</Tile.Body>
		</Tile>
	);
}

function FamilyAndHealthLeftColumnContent({ student }: { student: StudentDetailQuery["student"] }) {
	const { syncID, synchronize } = useSync();

	return <FamilyAndHealthLeftColumnContentForm key={syncID} student={student} onSuccess={synchronize} />;
}

type FamilyAndHealthLeftColumnContentFormProps = {
	student: StudentDetailQuery["student"];
	onSuccess: () => void;
};

function FamilyAndHealthLeftColumnContentForm({ student, onSuccess }: FamilyAndHealthLeftColumnContentFormProps) {
	const initialFormValues = StudentFormConversion.toHealthFormValues(student);
	const formState = useFormState({ initialFormValues, formType: FormType.Update });

	const update = StudentMutation.useUpdate(student.id);
	const applyUpdate = React.useCallback(
		async (
			changedFormValues: Partial<StudentFormValues.Detail.Health>,
			initialFormValues: StudentFormValues.Detail.Health,
		) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);
	const onSubmit = React.useCallback<FormSubmitFunction<StudentFormValues.Detail.Health>>(
		async (formState) => {
			return onUpdateSubmit(formState, applyUpdate);
		},
		[applyUpdate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	return (
		<Form formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)}>
			<Column justify="spaced-start" style={{ "--label__optional---color": "transparent" } as React.CSSProperties}>
				<StudentField.Allergies formState={formState} formType={FormType.Update} id={student.id} />
				<StudentField.Asthma formState={formState} formType={FormType.Update} id={student.id} />
				<StudentField.Medication formState={formState} formType={FormType.Update} id={student.id} />
				<StudentField.MedicationSchedule formState={formState} formType={FormType.Update} id={student.id} />
				<StudentField.DietaryRestrictions formState={formState} formType={FormType.Update} id={student.id} />
				<StudentField.HealthNotes formState={formState} formType={FormType.Update} id={student.id} />
				<StudentField.DoctorName formState={formState} formType={FormType.Update} id={student.id} />
				<StudentField.DoctorPhoneNumber formState={formState} formType={FormType.Update} id={student.id} />
				<StudentField.EmergencyContactInformation formState={formState} formType={FormType.Update} id={student.id} />
			</Column>
		</Form>
	);
}
