import React from "react";
import { ApolloCache, FetchResult, gql } from "@apollo/client";
import {
	Address,
	AddressCreateInput,
	AddressCreateMutation,
	AddressDeleteMutation,
	AddressDetailDocument,
	AddressDetailQuery,
	AddressDetailQueryVariables,
	AddressUpdateInput,
	AddressUpdateMutation,
	graphTypeNames,
	Person,
	useAddressCreateMutation,
	useAddressDeleteMutation,
	useAddressUpdateMutation,
} from "../../GraphQL";
import { AddressFormConversion } from "../ModelFormConversion";
import { AddressFormValues } from "../ModelFormValues";

/**
 * Returns a `create` function for the Address model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the Address model.
 */
export function useCreate() {
	const [createAddress] = useAddressCreateMutation();

	return React.useCallback(
		async (formValues: AddressFormValues.Create) => {
			const input = AddressFormConversion.toGQLCreateInput(formValues);
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createAddress({ variables: { input }, update: updateCache });

			return { data: data?.createAddress ?? null, errors: errors ?? null };
		},
		[createAddress],
	);
}

/**
 * Returns an `update` function for the Address model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the Address model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: Address["id"]) {
	const [updateAddress] = useAddressUpdateMutation();

	return React.useCallback(
		async (changedFormValues: Partial<AddressFormValues.Detail>, initialFormValues: AddressFormValues.Detail) => {
			const input = AddressFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

			const { data, errors } = await updateAddress({ variables: { id, input }, update: updateCache });

			return { data: data?.updateAddress ?? null, errors: errors ?? null };
		},
		[updateAddress, id],
	);
}

/**
 * Returns a `del` function for the Address model.
 *
 * @param id The ID of the instance to delete.
 */
export function useDelete(id: Address["id"]) {
	const [deleteAddress] = useAddressDeleteMutation();

	return React.useCallback(async () => {
		const updateCache = getUpdateCacheForDelete(id);

		const { data, errors } = await deleteAddress({ variables: { id }, update: updateCache });

		return { data: data?.deleteAddress ?? false, errors: errors ?? null };
	}, [deleteAddress, id]);
}

function getUpdateCacheForCreate(input: AddressCreateInput) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<AddressCreateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const createdObject = result.data.createAddress;

		cache.writeQuery<AddressDetailQuery, AddressDetailQueryVariables>({
			query: AddressDetailDocument,
			data: { address: createdObject },
			variables: { id: createdObject.id },
		});

		if (input.personIDs) {
			for (let i = 0; i < input.personIDs.length; i++) {
				addToAddressesOfPersonCache(cache, input.personIDs[i], createdObject);
			}
		}

		cache.evict({ id: "ROOT_QUERY", fieldName: "addressConnection" });
	};
}

function getUpdateCacheForUpdate(input: AddressUpdateInput, _initialFormValues: AddressFormValues.Detail) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<AddressUpdateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const updatedObject = result.data.updateAddress;

		if (input.addPersonIDs) {
			for (let i = 0; i < input.addPersonIDs.length; i++) {
				addToAddressesOfPersonCache(cache, input.addPersonIDs[i], updatedObject);
			}
		}
		if (input.removePersonIDs) {
			for (let i = 0; i < input.removePersonIDs.length; i++) {
				removeFromAddressesOfPersonCache(cache, input.removePersonIDs[i], updatedObject);
			}
		}
	};
}

function getUpdateCacheForDelete(id: Address["id"]) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<AddressDeleteMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (!result.data?.deleteAddress) {
			return;
		}

		cache.evict({ id: cache.identify({ id, __typename: graphTypeNames.Address }) });
		cache.evict({ id: "ROOT_QUERY", fieldName: "addressConnection" });
		cache.gc();
	};
}

function addToAddressesOfPersonCache(cache: ApolloCache<any>, targetID: Person["id"], object: Pick<Address, "id">) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Person }),
		fields: {
			addresses: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromAddressesOfPersonCache(
	cache: ApolloCache<any>,
	targetID: Person["id"],
	object: Pick<Address, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Person }),
		fields: {
			addresses: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function toCacheRef(cache: ApolloCache<any>, object: Pick<Address, "id">) {
	return cache.writeFragment({
		fragment: gql`
			fragment AddressRef on Address {
				id
			}
		`,
		data: object,
	});
}
