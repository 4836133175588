import React from "react";
import {
	Button,
	ChildrenProps,
	Column,
	Conditional,
	Else,
	Heading,
	If,
	Modal,
	ModalProps,
	RequiredKeys,
	Row,
	StyleProps,
	useToggle,
} from "@hex-insights/core";
import { EditMode, FormType, useFormState } from "@hex-insights/forms";
import { useRouteParams } from "@hex-insights/router";
import {
	LessonPlanDetailQuery,
	LessonPlanFormConversion,
	LessonPlanFormValues,
	LessonPlanMutation,
	TextAreaFieldDisplay,
	Tile,
} from "@hex-insights/verita.shared";
import {
	LessonPlanFormControlledCreate,
	LessonPlanFormDetail,
} from "../../../../LessonPlans/Week/CourseSection/Day/Form";
import { ScheduleDayCoursePeriodPageRouteParams } from "../pageinfo";
import { usePageCourseSectionPeriodQuery } from "../utils";
import styles from "./styles.module.css";

export type MainTileProps = Partial<StyleProps>;

export function MainTile({ style }: MainTileProps) {
	const { loading, data } = usePageCourseSectionPeriodQuery();
	const lessonPlan = data?.courseSectionPeriod.courseSection.lessonPlans[0] ?? null;
	const hasLessonPlan = lessonPlan !== null;

	// Modal open state separated to allow for form clean up in create after submission
	const { isOn: isCreateLessonPlanModalOpen, toggle: toggleIsCreateLessonPlanModalOpen } = useToggle(false);
	const { isOn: isUpdateLessonPlanModalOpen, toggle: toggleIsUpdateLessonPlanModalOpen } = useToggle(false);

	return (
		<Tile className={styles["main-tile"]} style={style}>
			<Tile.Body>
				<Column style={{ height: "100%" }}>
					<Row justify="spaced-start" align="center">
						<Heading level={3} noMargin>
							Lesson Plan
						</Heading>

						<Button
							variant="link"
							onClick={hasLessonPlan ? toggleIsUpdateLessonPlanModalOpen : toggleIsCreateLessonPlanModalOpen}
							style={{ padding: 0 }}
						>
							{hasLessonPlan ? "Edit" : "Create"}
						</Button>
					</Row>

					<Modal.If condition={isCreateLessonPlanModalOpen}>
						<LessonPlanCreateModal onClose={toggleIsCreateLessonPlanModalOpen} />
					</Modal.If>
					<If condition={isUpdateLessonPlanModalOpen}>
						{!!lessonPlan && (
							<LessonPlanUpdateModal lessonPlan={lessonPlan} onClose={toggleIsUpdateLessonPlanModalOpen} />
						)}
					</If>

					<Conditional>
						<If condition={loading}>Loading...</If>
						<If condition={!hasLessonPlan}>No Lesson Plan</If>
						<Else>
							{!!lessonPlan && (
								<Column justify="space-between" style={{ height: "100%", padding: "0 0.25rem" }}>
									<Row justify="space-between">
										<Column justify="spaced-start" style={{ flexGrow: 1, padding: "0 0.25rem", margin: "0.25rem" }}>
											<div className={styles["lesson-description"]} style={{ marginRight: "0.25rem" }}>
												<TextAreaFieldDisplay
													value=""
													formattedValue={lessonPlan.lessonDescription}
													className={styles["field"]}
													style={{ flexGrow: 1, padding: "0 0.25rem" }}
												/>
											</div>

											<If condition={lessonPlan.requiredResourcesAndMaterials !== ""}>
												<Column>
													<Label>Resources &amp; Materials</Label>
													<span className={styles["field"]}>{lessonPlan.requiredResourcesAndMaterials}</span>
												</Column>
											</If>

											<If condition={lessonPlan.notes !== ""}>
												<Column>
													<Label>Notes</Label>
													<TextAreaFieldDisplay
														value=""
														formattedValue={lessonPlan.notes}
														className={styles["field"]}
													/>
												</Column>
											</If>
										</Column>

										<Column justify="spaced-start" style={{ width: "35%", padding: "0 0.25rem" }}>
											<Column>
												<Label>Building Toward</Label>

												<Column justify="spaced-start">
													<Conditional>
														<If condition={lessonPlan.learningObjectives.length === 0}>
															<span style={{ color: "#777" }}>None selected</span>
														</If>
														<Else>
															{lessonPlan.learningObjectives.map((learningObjective, i) => (
																<Row
																	key={learningObjective.id}
																	justify="spaced-start"
																	className={
																		styles["learning-objective"] +
																		" " +
																		(lessonPlan.learningObjectives.length > 1
																			? styles["learning-objective--multiple"]
																			: "")
																	}
																>
																	<If condition={lessonPlan.learningObjectives.length > 1}>
																		<span className={styles["learning-objective__number"]}>
																			<span className={styles["learning-objective__number__content"]}>{i + 1}</span>
																		</span>
																	</If>

																	<Column>
																		<span className={styles["learning-objective__category"]}>
																			{learningObjective.category}
																		</span>
																		<span>{learningObjective.description}</span>
																	</Column>
																</Row>
															))}
														</Else>
													</Conditional>
												</Column>
											</Column>

											<If condition={lessonPlan.assessmentDescription !== ""}>
												<Column>
													<Label>Assessment</Label>
													<TextAreaFieldDisplay
														value=""
														formattedValue={lessonPlan.assessmentDescription}
														className={styles["field"]}
													/>
												</Column>
											</If>
										</Column>
									</Row>

									<Column>
										{/* <Heading level={4} noMargin>
											Stations
										</Heading> */}

										<Row justify="spaced-start">
											<Column justify="center" className={styles["station-description"]}>
												<span className={styles["station-description__number"]}>1</span>
												<TextAreaFieldDisplay
													value=""
													formattedValue={lessonPlan.stationOneDescription}
													className={styles["field"]}
												/>
											</Column>
											<Column justify="center" className={styles["station-description"]}>
												<span className={styles["station-description__number"]}>2</span>
												<TextAreaFieldDisplay
													value=""
													formattedValue={lessonPlan.stationTwoDescription}
													className={styles["field"]}
												/>
											</Column>
											<Column justify="center" className={styles["station-description"]}>
												<span className={styles["station-description__number"]}>3</span>
												<TextAreaFieldDisplay
													value=""
													formattedValue={lessonPlan.stationThreeDescription}
													className={styles["field"]}
												/>
											</Column>
										</Row>
									</Column>

									{/* <Row justify="spaced-start">
										<Column>
											<Label>Differentiation</Label>
											<TextAreaFieldDisplay
												value=""
												formattedValue={lessonPlan.differentiationDescription}
												className={styles["field"]}
											/>
										</Column>
									</Row> */}
								</Column>
							)}
						</Else>
					</Conditional>
				</Column>
			</Tile.Body>
		</Tile>
	);
}

function Label({ children }: ChildrenProps) {
	return <span className={styles["label"]}>{children}</span>;
}

// TODO confirm on close modals

type LessonPlanCreateModalProps = RequiredKeys<Pick<ModalProps, "ifRef" | "onClose">, "onClose">;

function LessonPlanCreateModal({ ifRef, onClose }: LessonPlanCreateModalProps) {
	const { date } = useRouteParams<ScheduleDayCoursePeriodPageRouteParams>();
	const dateISO = date + "T00:00:00.000Z";
	const { data } = usePageCourseSectionPeriodQuery();
	const courseSectionID = data!.courseSectionPeriod.courseSection.id;

	const create = LessonPlanMutation.useCreate();
	const applyCreate = React.useCallback(
		async (formValues: LessonPlanFormValues.Create) => {
			const { errors } = await create({ ...formValues, courseSectionID, courseDate: dateISO });
			return errors;
		},
		[create, courseSectionID, dateISO],
	);

	return (
		<Modal ifRef={ifRef} onClose={onClose} style={{ overflow: "hidden" }}>
			<Modal.Body style={{ height: "100%", overflow: "scroll" }}>
				<LessonPlanFormControlledCreate
					courseSectionID={courseSectionID}
					applyCreate={applyCreate}
					onSuccess={onClose}
					formNameSuffix={`.${courseSectionID}.${dateISO}`}
				/>
			</Modal.Body>
		</Modal>
	);
}

type LessonPlanUpdateModalProps = {
	lessonPlan: Omit<LessonPlanDetailQuery["lessonPlan"], "courseSection">;
} & RequiredKeys<Pick<ModalProps, "ifRef" | "onClose">, "onClose">;

function LessonPlanUpdateModal({ lessonPlan, ifRef, onClose }: LessonPlanUpdateModalProps) {
	const { data } = usePageCourseSectionPeriodQuery();

	const fullLessonPlan = React.useMemo(
		() => ({ ...lessonPlan, courseSection: data!.courseSectionPeriod.courseSection }),
		[lessonPlan, data],
	);
	const initialFormValuesRef = React.useRef(LessonPlanFormConversion.toFormValues(fullLessonPlan));
	const formState = useFormState({
		initialFormValues: initialFormValuesRef.current,
		formType: FormType.Update,
		editMode: EditMode.WriteOnly,
	});

	const update = LessonPlanMutation.useUpdate(lessonPlan.id);
	const applyUpdate = React.useCallback(
		async (changedFormValues: Partial<LessonPlanFormValues.Detail>, initialFormValues: LessonPlanFormValues.Detail) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<Modal ifRef={ifRef} onClose={onClose} style={{ overflow: "hidden" }}>
			<Modal.Body style={{ height: "100%", overflow: "scroll" }}>
				<LessonPlanFormDetail
					formState={formState}
					lessonPlan={fullLessonPlan}
					applyUpdate={applyUpdate}
					onSuccess={onClose}
					noChangesDialog
				/>
			</Modal.Body>
		</Modal>
	);
}
