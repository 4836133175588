import { Models } from "@hex-insights/app-modules";
import { DistributiveOmit } from "@hex-insights/core";
import { UserGroup, UserGroupFormat, UserGroupNameFragment } from "../../../../Utilities";

type ModelForLink = Pick<UserGroup, "id"> & UserGroupNameFragment;

export type UserGroupLinkProps = DistributiveOmit<Models.LinkProps<ModelForLink>, "pageInfoKey" | "format">;

/**
 * Renders a link to the given UserGroup instance.
 */
export function UserGroupLink(props: UserGroupLinkProps) {
	return <Models.Link<ModelForLink> pageInfoKey="UserGroup" format={UserGroupFormat.name} {...props} />;
}
