import { ParentNameFragment } from "../../../GraphQL";
import * as PersonFormat from "../Person";
import * as Fields from "./fields";

export { Fields };

/**
 * Formats the full name of the given instance.
 */
export function name(instance: ParentNameFragment) {
	return PersonFormat.name(instance.person);
}
