import { Column, ColumnProps, makeClassName, Row, RowProps } from "@hex-insights/core";
import styles from "./styles.module.css";

// TODO move to core as subcomponents of `<Form>`, e.g. `<Form.ButtonsRow>`

export type FormActionRowProps = RowProps;

export function FormActionRow({ justify = "spaced-end", className, ...props }: FormActionRowProps) {
	return <Row justify={justify} className={makeClassName(styles["form__action-row"], className)} {...props} />;
}

export type FormColumnProps = ColumnProps;

export function FormColumn({ justify = "spaced-start", className, ...props }: FormColumnProps) {
	return <Column justify={justify} className={makeClassName(styles["form__column"], className)} {...props} />;
}
