import React from "react";
import {
	Button,
	Conditional,
	Else,
	Heading,
	If,
	Modal,
	ModalProps,
	RequiredKeys,
	Row,
	useToggle,
} from "@hex-insights/core";
import { PermissionCheckMethod, PermissionRequired } from "@hex-insights/permissioning";
import { BusPlanDeleteButton, BusPlanForm } from "../../../../Components";
import {
	BusPlan,
	BusPlanFormState,
	BusPlanFormValues,
	BusPlanMutation,
	permissions,
	Student,
	useBusPlanDetailQuery,
} from "../../../../Utilities";
import { useStudentBusPlanQuery } from "../Utilities";
import styles from "./styles.module.css";

export type StudentBusPlanProps = {
	studentID: Student["id"];
};

export function StudentBusPlan({ studentID }: StudentBusPlanProps) {
	const { loading: loadingBusPlanData, data: busPlanData } = useStudentBusPlanQuery(studentID);
	const busPlan =
		busPlanData && busPlanData.busPlanConnection.edges.length > 0 ? busPlanData.busPlanConnection.edges[0].node : null;
	const hasBusPlan = busPlan !== null;

	const { isOn: isCreateModalOpen, toggle: toggleIsCreateModalOpen } = useToggle(false);
	const { isOn: isDetailModalOpen, toggle: toggleIsDetailModalOpen } = useToggle(false);

	return (
		<React.Fragment>
			<Row justify="space-between" align="center" className={styles["general-info-section__item"]}>
				<span className={styles["general-info-section__item__label"]}>Bus</span>
				<span className={styles["general-info-section__item__value"]}>
					<Conditional>
						<If condition={loadingBusPlanData}>...</If>
						<If condition={!hasBusPlan || (!busPlan.hasPickup && !busPlan.hasDropoff)}>
							<Button
								variant="link"
								onClick={toggleIsCreateModalOpen}
								title="Click to add a bus plan"
								className={styles["general-info-section__button--link"]}
							>
								No Bus
							</Button>
						</If>
						<If
							condition={Boolean(
								busPlan?.hasPickup && busPlan?.hasDropoff && busPlan.pickupBus?.id === busPlan.dropoffBus?.id,
							)}
						>
							<Button
								variant="link"
								onClick={toggleIsDetailModalOpen}
								title="Click to view and edit"
								className={styles["general-info-section__button--link"]}
							>
								{busPlan && busPlan.pickupBus && busPlan.pickupBus.name}
							</Button>
						</If>
						<If condition={Boolean(busPlan?.hasPickup && !busPlan?.hasDropoff)}>
							<Button
								variant="link"
								onClick={toggleIsDetailModalOpen}
								title="Click to view and edit"
								className={styles["general-info-section__button--link"]}
							>
								{busPlan && busPlan.pickupBus && busPlan.pickupBus.name}
							</Button>
						</If>
						<If condition={Boolean(!busPlan?.hasPickup && busPlan?.hasDropoff)}>
							<Button
								variant="link"
								onClick={toggleIsDetailModalOpen}
								title="Click to view and edit"
								className={styles["general-info-section__button--link"]}
							>
								{busPlan && busPlan.dropoffBus && busPlan.dropoffBus.name}
							</Button>
						</If>
						<Else>
							<Button
								variant="link"
								onClick={toggleIsDetailModalOpen}
								title="Click to view and edit"
								className={styles["general-info-section__button--link"]}
							>
								{busPlan && busPlan.pickupBus && busPlan.pickupBus.name},{" "}
								{busPlan && busPlan.dropoffBus && busPlan.dropoffBus.name}
							</Button>
						</Else>
					</Conditional>
				</span>
			</Row>

			<Modal.If condition={isCreateModalOpen}>
				<BusPlanCreateModal studentID={studentID} onClose={toggleIsCreateModalOpen} />
			</Modal.If>
			<Modal.If condition={isDetailModalOpen}>
				<BusPlanDetailModal busPlanID={busPlan?.id ?? "0"} onClose={toggleIsDetailModalOpen} />
			</Modal.If>
		</React.Fragment>
	);
}

type BusPlanCreateModalProps = {
	studentID: Student["id"];
} & RequiredKeys<Pick<ModalProps, "ifRef" | "onClose">, "onClose">;

function BusPlanCreateModal({ studentID, ifRef, onClose }: BusPlanCreateModalProps) {
	const initialFormValues = React.useMemo(() => ({ studentID }), [studentID]);
	const formState = BusPlanFormState.useCreateFormState(initialFormValues);

	const create = BusPlanMutation.useCreate();

	const applyCreate = React.useCallback(
		async (formValues: BusPlanFormValues.Create) => {
			const { errors } = await create(formValues);
			return errors;
		},
		[create],
	);

	return (
		<Modal ifRef={ifRef} onClose={onClose} style={{ width: "fit-content", minWidth: "20rem", maxWidth: "90vw" }}>
			<Modal.Header>
				<Heading level={2} noMargin>
					Add Bus Plan
				</Heading>
			</Modal.Header>
			<Modal.Body>
				<BusPlanForm.Create formState={formState} applyCreate={applyCreate} onSuccess={onClose} />
			</Modal.Body>
		</Modal>
	);
}

const deleteBusPlanPermissions = [permissions.BusPlan.Delete.Admin, permissions.BusPlan.Delete.Basic];

type BusPlanDetailModalProps = {
	busPlanID: BusPlan["id"];
} & RequiredKeys<Pick<ModalProps, "ifRef" | "onClose">, "onClose">;

function BusPlanDetailModal({ busPlanID, ifRef, onClose }: BusPlanDetailModalProps) {
	const { loading, data } = useBusPlanDetailQuery({ variables: { id: busPlanID } });
	const busPlan = data?.busPlan;

	const update = BusPlanMutation.useUpdate(busPlanID);
	const applyUpdate = React.useCallback(
		async (changedFormValues: Partial<BusPlanFormValues.Detail>, initialFormValues: BusPlanFormValues.Detail) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<Modal ifRef={ifRef} onClose={onClose} style={{ width: "fit-content", minWidth: "20rem", maxWidth: "90vw" }}>
			<Modal.Header>
				<Heading level={2} noMargin>
					Bus Plan
				</Heading>
			</Modal.Header>
			<Modal.Body>
				<Conditional>
					<If condition={loading}>Loading...</If>
					<Else>
						{busPlan && (
							<BusPlanForm.ControlledDetail busPlan={busPlan} applyUpdate={applyUpdate} onSuccess={onClose} />
						)}
					</Else>
				</Conditional>
			</Modal.Body>
			<If condition={!!busPlan}>
				<PermissionRequired requiredPermissions={deleteBusPlanPermissions} checkMethod={PermissionCheckMethod.Or}>
					<Modal.Footer>
						<Row justify="center" align="center">
							<BusPlanDeleteButton instanceID={busPlanID} onSuccess={onClose} size="small" />
						</Row>
					</Modal.Footer>
				</PermissionRequired>
			</If>
		</Modal>
	);
}
