import { Row } from "@hex-insights/core";
import { NavLink } from "@hex-insights/router";
import { Tile } from "../../../../../Components";
import styles from "./styles.module.css";

export type NavProps = {
	links: [string, boolean, string][];
};

export function Nav({ links }: NavProps) {
	return (
		<Tile>
			<Tile.Body style={{ padding: "0.5rem 0.75rem" }}>
				<Row justify="space-between">
					{links.map(([name, exact, to]) => (
						<NavLink
							key={to}
							exact={exact}
							to={to}
							className={styles["nav-link"]}
							activeClassName={styles["nav-link--location-active"]}
						>
							{name}
						</NavLink>
					))}
				</Row>
			</Tile.Body>
		</Tile>
	);
}
