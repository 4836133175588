import { IndexForms } from "@hex-insights/app-modules";
import { CaseStyle, convertCase } from "@hex-insights/core";
import { ClassYearOrderField, OrderDirection } from "../../GraphQL";

export type FormValues = IndexForms.OrderFormValues<ClassYearOrderField | null>;

export const initial: FormValues = {
	field: ClassYearOrderField.Name,
	direction: OrderDirection.Asc,
};

/**
 * Options for the "field" field in the order form of the ClassYear model.
 */
export const fieldOptions = [
	{ label: "ID", value: null },
	{ label: convertCase(ClassYearOrderField.Name.toLowerCase(), CaseStyle.Title), value: ClassYearOrderField.Name },
	{
		label: convertCase(ClassYearOrderField.YearsFromGraduation.toLowerCase(), CaseStyle.Title),
		value: ClassYearOrderField.YearsFromGraduation,
	},
];

/**
 * Array of values for the "field" field in the order form of the ClassYear model.
 */
export const fieldValues = fieldOptions.map((e) => e.value);

/**
 * Mapping from order fields of the ClassYear model to their corresponding order type.
 */
export const fieldTypeMapping = {
	[ClassYearOrderField.Name]: IndexForms.OrderFieldType.String,
	[ClassYearOrderField.YearsFromGraduation]: IndexForms.OrderFieldType.Number,
};

/**
 * Mapping from order fields of the ClassYear model to their corresponding field name.
 */
export const orderFieldToModelField = {
	[ClassYearOrderField.Name]: "name",
	[ClassYearOrderField.YearsFromGraduation]: "yearsFromGraduation",
};

/**
 * Mapping from field names of the ClassYear model to their corresponding order field.
 */
export const modelFieldToOrderField = {
	name: ClassYearOrderField.Name,
	yearsFromGraduation: ClassYearOrderField.YearsFromGraduation,
};
