import React from "react";
import { ChildrenProps, DefaultContextValue, isString, useRequiredContext } from "@hex-insights/core";
import { FormSubmissionResult, submissionFailure, submissionSuccess } from "@hex-insights/forms";
import { serverRequest } from "../../Utilities";
import * as EnrollmentApplicationFormValues from "./form";

export type EnrollmentApplicationFormContextValue = {
	formValues: EnrollmentApplicationFormValues.Full;
	setFormValues: (action: React.SetStateAction<EnrollmentApplicationFormValues.Full>) => void;
	submit: (
		formValues: EnrollmentApplicationFormValues.Full,
	) => Promise<FormSubmissionResult<EnrollmentApplicationFormValues.Full>>;
};

export const EnrollmentApplicationFormContext = React.createContext<EnrollmentApplicationFormContextValue>(
	new DefaultContextValue("EnrollmentApplicationFormContext") as any,
);

const requestSizeLimit = 10_000_000;

export type EnrollmentApplicationFormContextManagerProps = Partial<ChildrenProps>;

export function EnrollmentApplicationFormContextManager({ children }: EnrollmentApplicationFormContextManagerProps) {
	const [formValues, setFormValues] = React.useState(EnrollmentApplicationFormValues.initialFull);

	const submit = React.useCallback(async (formValues: EnrollmentApplicationFormValues.Full) => {
		const preparedStudents = [];
		let totalImageSize = 0;
		for (let i = 0; i < formValues.students.length; i++) {
			const student = { ...formValues.students[i] };
			if (student.image !== null) {
				const imageData = await readAsDataURL(student.image);
				const imgSize = student.image.size;
				student.image = {
					filename: student.image.name,
					file: imageData,
					contentType: student.image.type,
					size: imgSize,
				} as any;
				totalImageSize += imgSize;
			}
			preparedStudents.push({
				...student,
				nationality: student.nationalityList.join(" "),
				primaryLanguage: student.primaryLanguageList.join(" "),
			});
		}

		if (totalImageSize > requestSizeLimit) {
			return submissionFailure({
				_: [
					"The attached image files are too large to upload. Please go back and use smaller images or remove images.",
				],
			});
		}

		const preparedParents = [];
		for (let i = 0; i < formValues.parents.length; i++) {
			const parent = { ...formValues.parents[i] };
			preparedParents.push({
				...parent,
				nationality: parent.nationalityList.join(" "),
			});
		}

		const preparedFormValues = {
			students: preparedStudents,
			parents: preparedParents,
			...formValues.dataConsent,
			...formValues.signature,
			...formValues.metaData,
		};
		try {
			const response = await serverRequest("/admissions/apply", {
				method: "POST",
				body: JSON.stringify(preparedFormValues),
			});
			if (response.ok) {
				return submissionSuccess();
			}
		} catch (err) {}
		return submissionFailure({
			_: ["There was an error submitting your application, please wait a moment and try again."],
		});
	}, []);

	const contextValue = React.useMemo(
		() => ({
			formValues,
			setFormValues,
			submit,
		}),
		[formValues, submit],
	);

	return (
		<EnrollmentApplicationFormContext.Provider value={contextValue}>
			{children}
		</EnrollmentApplicationFormContext.Provider>
	);
}

async function readAsDataURL(file: File) {
	const reader = new FileReader();
	reader.readAsDataURL(file);
	return new Promise((resolve, reject) => {
		reader.onload = () => {
			const cleanResult = isString(reader.result) ? reader.result.replace(/^data:\w+\/\w*;base64,/, "") : "";
			resolve(cleanResult);
		};
		reader.onerror = (err) => reject(err);
	});
}

export function useEnrollmentApplicationFormContext() {
	return useRequiredContext(EnrollmentApplicationFormContext, "useEnrollmentApplicationFormContext");
}
