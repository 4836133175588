import React from "react";
import {
	addTimeToDate,
	Column,
	Conditional,
	Else,
	formatDateTime,
	Heading,
	If,
	Row,
	stringToLocalDate,
} from "@hex-insights/core";
import { InternalLink, useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import {
	CalendarEventOrderField,
	getCalendarEventFiltersForUserForDateRange,
	OrderDirection,
	SmallEventCalendar,
	Tile,
	useCalendarEventIndexQuery,
	useCurrentUser,
} from "@hex-insights/verita.shared";
import { LargeDayCoursesCalendarAgenda, Main } from "../../../../Components";
import { usePageDate } from "../utils";
import { scheduleDayIndexPageInfo, ScheduleDayIndexPageRouteParams } from "./pageinfo";
import styles from "./styles.module.css";

function ScheduleDayIndexPageWrapper() {
	const { date } = useRouteParams<ScheduleDayIndexPageRouteParams>();
	return <ScheduleDayIndexPage key={date} />;
}

export { ScheduleDayIndexPageWrapper as ScheduleDayIndexPage };

function ScheduleDayIndexPage() {
	useActivePageRegistration(scheduleDayIndexPageInfo);

	return (
		<Main>
			<Column
				justify="spaced-start"
				style={{
					height: "calc(100vh - var(--header---height) - 2 * var(--main---padding-top))",
				}}
			>
				<HeaderTile />

				<Row justify="spaced-start" style={{ flexGrow: 1, height: "100%", minHeight: 0 }}>
					<CalendarTile />
					<EventsTile />
				</Row>
			</Column>
		</Main>
	);
}

function HeaderTile() {
	const { date } = useRouteParams<ScheduleDayIndexPageRouteParams>();

	return (
		<Tile className={styles["header-tile"]}>
			<Tile.Body>
				<Row justify="center" align="center">
					<InternalLink
						to={scheduleDayIndexPageInfo.to(formatDateTime(addTimeToDate(date, [-1, "day"]), "YYYY-MM-DD"))}
						className={`${styles["header-tile__link"]} ${styles["header-tile__link--back"]}`}
						style={{ "--internal-link---text-decoration": "none" } as React.CSSProperties}
					>
						<Row justify="spaced-start" horizontalSpacing="0.25rem" align="center">
							Previous Day
						</Row>
					</InternalLink>

					<Heading level={1} noMargin style={{ lineHeight: 1 }}>
						{formatDateTime(stringToLocalDate(date, "day"), "dddd, D MMMM YYYY")}
					</Heading>

					<InternalLink
						to={scheduleDayIndexPageInfo.to(formatDateTime(addTimeToDate(date, [1, "day"]), "YYYY-MM-DD"))}
						className={`${styles["header-tile__link"]} ${styles["header-tile__link--forward"]}`}
						style={{ "--internal-link---text-decoration": "none" } as React.CSSProperties}
					>
						<Row justify="spaced-start" horizontalSpacing="0.25rem" align="center">
							Next Day
						</Row>
					</InternalLink>
				</Row>
			</Tile.Body>
		</Tile>
	);
}

function CalendarTile() {
	const { date } = usePageDate();

	return (
		<Tile style={{ flexGrow: 1, height: "100%" }}>
			<Tile.Body style={{ height: "100%", overflow: "scroll" }}>
				<LargeDayCoursesCalendarAgenda date={date} />
			</Tile.Body>
		</Tile>
	);
}

function EventsTile() {
	const { date } = usePageDate();

	const { user } = useCurrentUser();
	const { loading, data } = useCalendarEventIndexQuery({
		variables: {
			filters: getCalendarEventFiltersForUserForDateRange(user.id, date, date),
			order: { field: CalendarEventOrderField.StartTime, direction: OrderDirection.Asc },
		},
	});
	const events = data?.calendarEventConnection.edges.map((e) => ({
		...e.node,
		groups: e.node.sharingUserGroups,
	}));

	return (
		<Tile style={{ width: "20rem", maxWidth: "50%", height: "100%" }}>
			<Tile.Header>
				<Heading level={2} noMargin>
					Events
				</Heading>
			</Tile.Header>
			<Tile.Body style={{ height: "100%", overflow: "scroll" }}>
				<Conditional>
					<If condition={loading}>Loading...</If>
					<If condition={(events?.length ?? 0) === 0}>No Events on this Day</If>
					<Else>
						<Column justify="spaced-start" verticalSpacing="0.25rem">
							{events?.map((e) => (
								<SmallEventCalendar.EventListItem key={e.id} calendarEvent={e} />
							))}
						</Column>
					</Else>
				</Conditional>
			</Tile.Body>
		</Tile>
	);
}
