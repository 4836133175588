import { ClassNameProps, StyleProps } from "@hex-insights/core";

export type BusIconProps = {
	size?: string;
} & Partial<ClassNameProps & StyleProps>;

export function BusIcon({ size, className, style }: BusIconProps) {
	return (
		<svg
			className={className}
			style={{ width: size, height: size, ...style }}
			viewBox="0 0 512 512"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M321.422 361.17c1.777 57.815 85.716 57.83 87.5 0-1.775-57.814-85.714-57.83-87.5 0ZM85.174 361.17c1.778 57.815 85.716 57.83 87.5 0-1.775-57.816-85.714-57.83-87.5 0Z"></path>
			<path d="M465.836 159.876c3.042-27.35-15.296-52.364-43.75-52.413h-271.25a43.654 43.654 0 0 0-43.75 43.75v105h-.088L61.761 275.55a26.296 26.296 0 0 0-15.925 24.238v48.3a13.092 13.092 0 0 0 13.125 13.125c3.383-92.864 136.584-92.822 140 0h96.25c3.383-92.864 136.584-92.822 140 0h17.5a13.107 13.107 0 0 0 13.125-13.125c.008.004-.007-188.198 0-188.212ZM190.21 256.213h-56.875v-96.25h56.875Zm83.125 0H216.46v-96.25h56.875Zm83.125 0h-56.875v-96.25h56.875Zm83.125 0H382.71v-96.25h56.875Z"></path>
		</svg>
	);
}

export type MealIconProps = {
	size?: string;
} & Partial<ClassNameProps & StyleProps>;

export function MealIcon({ size, className, style }: MealIconProps) {
	return (
		<svg
			className={className}
			style={{ width: size, height: size, ...style }}
			viewBox="0 0 512 512"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M463.098 278.878V47a17.095 17.095 0 0 0-17.089-17.098h-.028a74.072 74.072 0 0 0-74.08 74.089V237A17.096 17.096 0 0 0 389 254.098h39.902v22.795H427a45.651 45.651 0 0 0-45.598 45.598V436.5a45.598 45.598 0 0 0 91.196 0V294a16.963 16.963 0 0 0-9.5-15.122ZM265.5 29.902a64.662 64.662 0 0 0-64.598 64.589v64.607a64.493 64.493 0 0 0 47.5 62.01v59.185a45.606 45.606 0 0 0-28.5 42.198V436.5a45.598 45.598 0 0 0 91.196 0V322.49a45.606 45.606 0 0 0-28.5-42.197v-59.185a64.493 64.493 0 0 0 47.5-62.01V94.49A64.662 64.662 0 0 0 265.5 29.9ZM151.5 29.902A17.096 17.096 0 0 0 134.402 47v77.893h-13.304V47a17.098 17.098 0 0 0-34.196 0v77.893H73.598V47a17.098 17.098 0 0 0-34.196 0v104.5a64.493 64.493 0 0 0 47.5 62.01v66.783a45.606 45.606 0 0 0-28.5 42.198V436.5a45.598 45.598 0 0 0 91.196 0V322.49a45.606 45.606 0 0 0-28.5-42.197V213.51a64.493 64.493 0 0 0 47.5-62.01V47A17.096 17.096 0 0 0 151.5 29.902Z"></path>
		</svg>
	);
}
