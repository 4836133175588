import React from "react";
import { EditMode, FormType, useFormState, ValidationDisplayPolicy } from "@hex-insights/forms";
import { NoteFormValues } from "../ModelFormValues";

export namespace Academic {
	/**
	 * Returns a `formState` object for use in the create form for an Academic type Note.
	 */
	export function useCreateFormState(initialFormValues?: Partial<NoteFormValues.Academic.Create>) {
		const fullInitialFormValues = React.useMemo(
			() => ({ ...NoteFormValues.Academic.initialCreate, ...initialFormValues }),
			[initialFormValues],
		);

		return useFormState({
			initialFormValues: fullInitialFormValues,
			formType: FormType.Create,
			validationDisplayPolicy: ValidationDisplayPolicy.failureOnly,
		});
	}
}

export namespace Announcement {
	/**
	 * Returns a `formState` object for use in the create form for an Announcement type Note.
	 */
	export function useCreateFormState(initialFormValues?: Partial<NoteFormValues.Announcement.Create>) {
		const fullInitialFormValues = React.useMemo(
			() => ({ ...NoteFormValues.Announcement.initialCreate, ...initialFormValues }),
			[initialFormValues],
		);

		return useFormState({
			initialFormValues: fullInitialFormValues,
			formType: FormType.Create,
			validationDisplayPolicy: ValidationDisplayPolicy.failureOnly,
		});
	}
}

export namespace Attendance {
	/**
	 * Returns a `formState` object for use in the create form for an Attendance type Note.
	 */
	export function useCreateFormState(initialFormValues?: Partial<NoteFormValues.Attendance.Create>) {
		const fullInitialFormValues = React.useMemo(
			() => ({
				...NoteFormValues.Attendance.initialCreate,
				...initialFormValues,
			}),
			[initialFormValues],
		);

		return useFormState({
			initialFormValues: fullInitialFormValues,
			formType: FormType.Create,
			validationDisplayPolicy: ValidationDisplayPolicy.failureOnly,
		});
	}

	export function useDetailFormState({ initialFormValues }: { initialFormValues: NoteFormValues.Attendance.Detail }) {
		return useFormState({
			initialFormValues: initialFormValues,
			formType: FormType.Update,
		});
	}
}

export namespace Behavior {
	/**
	 * Returns a `formState` object for use in the create form for an Behavior type Note.
	 */
	export function useCreateFormState(initialFormValues?: Partial<NoteFormValues.Behavior.Create>) {
		const fullInitialFormValues = React.useMemo(
			() => ({ ...NoteFormValues.Behavior.initialCreate, ...initialFormValues }),
			[initialFormValues],
		);

		return useFormState({
			initialFormValues: fullInitialFormValues,
			formType: FormType.Create,
			validationDisplayPolicy: ValidationDisplayPolicy.failureOnly,
		});
	}
}

export namespace Financial {
	/**
	 * Returns a `formState` object for use in the create form for a Financial type Note.
	 */
	export function useCreateFormState(initialFormValues?: Partial<NoteFormValues.Financial.Create>) {
		const fullInitialFormValues = React.useMemo(
			() => ({ ...NoteFormValues.Financial.initialCreate, ...initialFormValues }),
			[initialFormValues],
		);

		return useFormState({
			initialFormValues: fullInitialFormValues,
			formType: FormType.Create,
			validationDisplayPolicy: ValidationDisplayPolicy.failureOnly,
		});
	}

	export function useDetailFormState({ initialFormValues }: { initialFormValues: NoteFormValues.Financial.Detail }) {
		return useFormState({
			initialFormValues: initialFormValues,
			formType: FormType.Update,
		});
	}
}

export namespace General {
	/**
	 * Returns a `formState` object for use in the create form for a General type Note.
	 */
	export function useCreateFormState(initialFormValues?: Partial<NoteFormValues.General.Create>) {
		const fullInitialFormValues = React.useMemo(
			() => ({ ...NoteFormValues.General.initialCreate, ...initialFormValues }),
			[initialFormValues],
		);

		return useFormState({
			initialFormValues: fullInitialFormValues,
			formType: FormType.Create,
			validationDisplayPolicy: ValidationDisplayPolicy.failureOnly,
		});
	}

	export function useDetailFormState({ initialFormValues }: { initialFormValues: NoteFormValues.General.Detail }) {
		return useFormState({
			initialFormValues: initialFormValues,
			formType: FormType.Update,
		});
	}

	export function useReadOnlyFormState({ initialFormValues }: { initialFormValues: NoteFormValues.General.Detail }) {
		return useFormState({
			initialFormValues: initialFormValues,
			editMode: EditMode.ReadOnly,
		});
	}
}

export namespace Health {
	/**
	 * Returns a `formState` object for use in the create form for a Health type Note.
	 */
	export function useCreateFormState(initialFormValues?: Partial<NoteFormValues.Health.Create>) {
		const fullInitialFormValues = React.useMemo(
			() => ({ ...NoteFormValues.Health.initialCreate, ...initialFormValues }),
			[initialFormValues],
		);

		return useFormState({
			initialFormValues: fullInitialFormValues,
			formType: FormType.Create,
			validationDisplayPolicy: ValidationDisplayPolicy.failureOnly,
		});
	}
}

export namespace Incident {
	/**
	 * Returns a `formState` object for use in the create form for an Incident type Note.
	 */
	export function useCreateFormState(initialFormValues?: Partial<NoteFormValues.Incident.Create>) {
		const fullInitialFormValues = React.useMemo(
			() => ({ ...NoteFormValues.Incident.initialCreate, ...initialFormValues }),
			[initialFormValues],
		);

		return useFormState({
			initialFormValues: fullInitialFormValues,
			formType: FormType.Create,
			validationDisplayPolicy: ValidationDisplayPolicy.failureOnly,
		});
	}
}

export namespace Injury {
	/**
	 * Returns a `formState` object for use in the create form for an Injury type Note.
	 */
	export function useCreateFormState(initialFormValues?: Partial<NoteFormValues.Injury.Create>) {
		const fullInitialFormValues = React.useMemo(
			() => ({ ...NoteFormValues.Injury.initialCreate, ...initialFormValues }),
			[initialFormValues],
		);

		return useFormState({
			initialFormValues: fullInitialFormValues,
			formType: FormType.Create,
			validationDisplayPolicy: ValidationDisplayPolicy.failureOnly,
		});
	}
}

export namespace SocialEmotional {
	/**
	 * Returns a `formState` object for use in the create form for an SocialEmotional type Note.
	 */
	export function useCreateFormState(initialFormValues?: Partial<NoteFormValues.SocialEmotional.Create>) {
		const fullInitialFormValues = React.useMemo(
			() => ({ ...NoteFormValues.SocialEmotional.initialCreate, ...initialFormValues }),
			[initialFormValues],
		);

		return useFormState({
			initialFormValues: fullInitialFormValues,
			formType: FormType.Create,
			validationDisplayPolicy: ValidationDisplayPolicy.failureOnly,
		});
	}
}

/**
 * Returns a `formState` object for use in the create form for the Note model.
 */
export function useCreateFormState(initialFormValues?: Partial<NoteFormValues.Create>) {
	const fullInitialFormValues = React.useMemo(
		() => ({ ...initialFormValues, ...NoteFormValues.initialCreate }),
		[initialFormValues],
	);

	return useFormState({
		initialFormValues: fullInitialFormValues,
		formType: FormType.Create,
	});
}

const detailFieldEditModes = {
	createdAt: EditMode.ReadOnly,
	attachmentIDs: EditMode.ReadOnly,
	authorID: EditMode.ReadOnly,
	commentIDs: EditMode.ReadOnly,
	reactionIDs: EditMode.ReadOnly,
};

/**
 * Returns a `formState` object for use in the detail form of the Note model.
 *
 * @param initialFormValues The initial values for the form. Generally from
 * {@link NoteFormConversion.toFormValues}.
 */
export function useDetailFormState({ initialFormValues }: { initialFormValues: NoteFormValues.Detail }) {
	return useFormState({
		initialFormValues: initialFormValues,
		formType: FormType.Update,
		fieldEditModes: detailFieldEditModes,
	});
}

/**
 * Returns a read-only `formState` object for use in the detail form of the Note model.
 *
 * @param initialFormValues The initial values for the form. Generally from
 * {@link NoteFormConversion.toFormValues}.
 */
export function useReadOnlyFormState({ initialFormValues }: { initialFormValues: NoteFormValues.Detail }) {
	return useFormState({
		initialFormValues: initialFormValues,
		editMode: EditMode.ReadOnly,
	});
}
