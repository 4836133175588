import { NullPartial } from "@hex-insights/core";
import { DocumentResourceUserViewRecordCreateInput, DocumentResourceUserViewRecordDetailQuery } from "../../GraphQL";
import { DocumentResourceUserViewRecordFormValues } from "../ModelFormValues";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the DocumentResourceUserViewRecord model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(
	formValues: DocumentResourceUserViewRecordFormValues.Create,
): DocumentResourceUserViewRecordCreateInput {
	const input: NullPartial<DocumentResourceUserViewRecordCreateInput> = { ...formValues };
	return input as DocumentResourceUserViewRecordCreateInput;
}

/**
 * Converts a DocumentResourceUserViewRecord instance to its corresponding detail form values.
 *
 * @param documentResourceUserViewRecord The instance to convert.
 */
export function toFormValues(
	documentResourceUserViewRecord: DocumentResourceUserViewRecordDetailQuery["documentResourceUserViewRecord"],
): DocumentResourceUserViewRecordFormValues.Detail {
	return {
		viewedAt: documentResourceUserViewRecord.viewedAt,
		documentResourceID: documentResourceUserViewRecord.documentResource?.id ?? null,
		userID: documentResourceUserViewRecord.user?.id ?? null,
	};
}
