import React from "react";
import { ApolloCache, FetchResult, gql } from "@apollo/client";
import {
	graphTypeNames,
	Parent,
	Relationship,
	RelationshipCreateInput,
	RelationshipCreateMutation,
	RelationshipDeleteMutation,
	RelationshipDetailDocument,
	RelationshipDetailQuery,
	RelationshipDetailQueryVariables,
	RelationshipUpdateInput,
	RelationshipUpdateMutation,
	Student,
	useRelationshipCreateMutation,
	useRelationshipDeleteMutation,
	useRelationshipUpdateMutation,
} from "../../GraphQL";
import { RelationshipFormConversion } from "../ModelFormConversion";
import { RelationshipFormValues } from "../ModelFormValues";

/**
 * Returns a `create` function for the Relationship model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the Relationship model.
 */
export function useCreate() {
	const [createRelationship] = useRelationshipCreateMutation();

	return React.useCallback(
		async (formValues: RelationshipFormValues.Create) => {
			const input = RelationshipFormConversion.toGQLCreateInput(formValues);
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createRelationship({ variables: { input }, update: updateCache });

			return { data: data?.createRelationship ?? null, errors: errors ?? null };
		},
		[createRelationship],
	);
}

/**
 * Returns an `update` function for the Relationship model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the Relationship model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: Relationship["id"]) {
	const [updateRelationship] = useRelationshipUpdateMutation();

	return React.useCallback(
		async (
			changedFormValues: Partial<RelationshipFormValues.Detail>,
			initialFormValues: RelationshipFormValues.Detail,
		) => {
			const input = RelationshipFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

			const { data, errors } = await updateRelationship({ variables: { id, input }, update: updateCache });

			return { data: data?.updateRelationship ?? null, errors: errors ?? null };
		},
		[updateRelationship, id],
	);
}

/**
 * Returns a `del` function for the Relationship model.
 *
 * @param id The ID of the instance to delete.
 */
export function useDelete(id: Relationship["id"]) {
	const [deleteRelationship] = useRelationshipDeleteMutation();

	return React.useCallback(async () => {
		const updateCache = getUpdateCacheForDelete(id);

		const { data, errors } = await deleteRelationship({ variables: { id }, update: updateCache });

		return { data: data?.deleteRelationship ?? false, errors: errors ?? null };
	}, [deleteRelationship, id]);
}

function getUpdateCacheForCreate(input: RelationshipCreateInput) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<RelationshipCreateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const createdObject = result.data.createRelationship;

		cache.writeQuery<RelationshipDetailQuery, RelationshipDetailQueryVariables>({
			query: RelationshipDetailDocument,
			data: { relationship: createdObject },
			variables: { id: createdObject.id },
		});

		if (input.parentID) {
			addToRelationshipsOfParentCache(cache, input.parentID, createdObject);
		}

		if (input.studentID) {
			addToRelationshipsOfStudentCache(cache, input.studentID, createdObject);
		}

		cache.evict({ id: "ROOT_QUERY", fieldName: "relationshipConnection" });
	};
}

function getUpdateCacheForUpdate(input: RelationshipUpdateInput, initialFormValues: RelationshipFormValues.Detail) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<RelationshipUpdateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const updatedObject = result.data.updateRelationship;

		if (initialFormValues.parentID && input.parentID) {
			removeFromRelationshipsOfParentCache(cache, initialFormValues.parentID, updatedObject);
		}
		if (input.parentID) {
			addToRelationshipsOfParentCache(cache, input.parentID, updatedObject);
		}

		if (initialFormValues.studentID && input.studentID) {
			removeFromRelationshipsOfStudentCache(cache, initialFormValues.studentID, updatedObject);
		}
		if (input.studentID) {
			addToRelationshipsOfStudentCache(cache, input.studentID, updatedObject);
		}
	};
}

function getUpdateCacheForDelete(id: Relationship["id"]) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<RelationshipDeleteMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (!result.data?.deleteRelationship) {
			return;
		}

		cache.evict({ id: cache.identify({ id, __typename: graphTypeNames.Relationship }) });
		cache.evict({ id: "ROOT_QUERY", fieldName: "relationshipConnection" });
		cache.gc();
	};
}

function addToRelationshipsOfParentCache(
	cache: ApolloCache<any>,
	targetID: Parent["id"],
	object: Pick<Relationship, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Parent }),
		fields: {
			relationships: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromRelationshipsOfParentCache(
	cache: ApolloCache<any>,
	targetID: Parent["id"],
	object: Pick<Relationship, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Parent }),
		fields: {
			relationships: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function addToRelationshipsOfStudentCache(
	cache: ApolloCache<any>,
	targetID: Student["id"],
	object: Pick<Relationship, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Student }),
		fields: {
			relationships: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromRelationshipsOfStudentCache(
	cache: ApolloCache<any>,
	targetID: Student["id"],
	object: Pick<Relationship, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Student }),
		fields: {
			relationships: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function toCacheRef(cache: ApolloCache<any>, object: Pick<Relationship, "id">) {
	return cache.writeFragment({
		fragment: gql`
			fragment RelationshipRef on Relationship {
				id
			}
		`,
		data: object,
	});
}
