import { ChildrenProps, ClassNameProps, makeClassName, StyleProps } from "@hex-insights/core";
import styles from "./styles.module.css";

export type FieldSetProps = Partial<ClassNameProps & StyleProps & ChildrenProps>;

export function FieldSet({ className, style, children }: FieldSetProps) {
	return (
		<fieldset className={className} style={{ border: "none", ...style }}>
			{children}
		</fieldset>
	);
}

type FieldSetSubcomponentProps = Partial<ClassNameProps & StyleProps & ChildrenProps>;

FieldSet.Header = function ({ className, style, children }: FieldSetSubcomponentProps) {
	return (
		<div className={makeClassName(styles["field-set__header"], className)} style={style}>
			{children}
		</div>
	);
};

FieldSet.Body = function ({ className, style, children }: FieldSetSubcomponentProps) {
	return (
		<div className={makeClassName(styles["field-set__body"], className)} style={style}>
			{children}
		</div>
	);
};

FieldSet.Footer = function ({ className, style, children }: FieldSetSubcomponentProps) {
	return (
		<div className={makeClassName(styles["field-set__footer"], className)} style={style}>
			{children}
		</div>
	);
};
