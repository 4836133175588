import { Parent, Relationship, RelationshipRelationshipType, Student } from "../../GraphQL";

export type Base = {
	relationshipType: Relationship["relationshipType"] | null;
	isPrimary: Relationship["isPrimary"];
	isFinancialContact: Relationship["isFinancialContact"];
	isEmergencyContact: Relationship["isEmergencyContact"];
	isAuthorizedForPickup: Relationship["isAuthorizedForPickup"];
	livesWithStudent: Relationship["livesWithStudent"];
	parentID: Parent["id"] | null;
	studentID: Student["id"] | null;
};

export type Create = Base;

export type Detail = Base;

export const initialCreate: Create = {
	relationshipType: null,
	isPrimary: false,
	isFinancialContact: false,
	isEmergencyContact: false,
	isAuthorizedForPickup: false,
	livesWithStudent: false,
	parentID: null,
	studentID: null,
};

export const relationshipTypeOptions = [
	{ value: RelationshipRelationshipType.Father },
	{ value: RelationshipRelationshipType.Mother },
	{ value: RelationshipRelationshipType.Other },
];
