import {
	Conditional,
	Else,
	formatDateTime,
	Heading,
	If,
	Modal,
	ModalProps,
	relativeTime,
	RequiredKeys,
	Row,
} from "@hex-insights/core";
import { usePermissions } from "@hex-insights/permissioning";
import { useCurrentUser } from "../../Contexts";
import { Note, NoteDetailQuery, NoteFormat, NoteNoteType, permissions, useNoteDetailQuery } from "../../Utilities";
import { NoteDeleteButton } from "../DeleteButtons";
import { NoteForm } from "../Forms";
import { EmployeeLink } from "../Links";
import { EmployeePreviewTooltip, PersonIcon } from "../PersonIcon";

export const reportNoteTypes = new Set<NoteNoteType | null>([
	NoteNoteType.Academic,
	NoteNoteType.Behavior,
	NoteNoteType.Incident,
	NoteNoteType.Injury,
	NoteNoteType.SocialEmotional,
]);

export type NoteModalProps = {
	noteID: Note["id"];
	noteType?: NoteNoteType | null;
} & RequiredKeys<Pick<ModalProps, "ifRef" | "onClose">, "onClose">;

export function NoteModal({ noteID, noteType, ifRef, onClose }: NoteModalProps) {
	const { loading, data } = useNoteDetailQuery({ variables: { id: noteID } });
	const note = data?.note ?? null;

	return (
		<Modal ifRef={ifRef} onClose={onClose}>
			<Modal.Header>
				<Heading level={2} noMargin>
					<Conditional>
						<If condition={!!noteType}>
							{!!noteType &&
								`${NoteFormat.Fields.noteType(noteType)} ${reportNoteTypes.has(noteType) ? "Report" : "Note"}`}
						</If>
						<Else>Note</Else>
					</Conditional>
				</Heading>
			</Modal.Header>
			<Modal.Body>
				<Conditional>
					<If condition={loading}>Loading...</If>
					<Else>
						{!!note && (
							<Conditional>
								<If condition={note.noteType === NoteNoteType.Academic}>
									<NoteForm.Academic.ReadOnly note={note} />
								</If>
								<If condition={note.noteType === NoteNoteType.Attendance}>
									<NoteForm.Attendance.ReadOnly note={note} />
								</If>
								<If condition={note.noteType === NoteNoteType.Behavior}>
									<NoteForm.Behavior.ReadOnly note={note} />
								</If>
								<If condition={note.noteType === NoteNoteType.Financial}>
									<NoteForm.Financial.ReadOnly note={note} />
								</If>
								<If condition={note.noteType === NoteNoteType.General}>
									<NoteForm.General.ControlledReadOnly note={note} />
								</If>
								<If condition={note.noteType === NoteNoteType.Health}>
									<NoteForm.Health.ReadOnly note={note} />
								</If>
								<If condition={note.noteType === NoteNoteType.Incident}>
									<NoteForm.Incident.ReadOnly note={note} />
								</If>
								<If condition={note.noteType === NoteNoteType.Injury}>
									<NoteForm.Injury.ReadOnly note={note} />
								</If>
								<If condition={note.noteType === NoteNoteType.SocialEmotional}>
									<NoteForm.SocialEmotional.ReadOnly note={note} />
								</If>
							</Conditional>
						)}
					</Else>
				</Conditional>
			</Modal.Body>
			{!!note && <NoteModalFooter note={note} onClose={onClose} />}
		</Modal>
	);
}

type NoteModalFooterProps = {
	note: NoteDetailQuery["note"];
	onClose: () => void;
};

function NoteModalFooter({ note, onClose }: NoteModalFooterProps) {
	const { user } = useCurrentUser();
	const isAuthor = !!note.author && !!user && note.author.id === user.id;
	const { permissions: userPermissions } = usePermissions();

	return (
		<Modal.Footer>
			<Row justify="space-between" align="center">
				<span title={formatDateTime(note.createdAt, "HH:mm [on] D MMMM, YYYY")}>{relativeTime(note.createdAt)}</span>

				<If
					condition={
						userPermissions[permissions.Note.Delete.Admin] ||
						(isAuthor && userPermissions[permissions.Note.Delete.Basic])
					}
				>
					<NoteDeleteButton instanceID={note.id} onSuccess={onClose} size="small" />
				</If>

				{note.author && note.author.person && (
					<Row justify="spaced-start">
						<PersonIcon person={note.author.person} imageSize="1.5rem" />
						{note.author.person.employee ? (
							<EmployeePreviewTooltip id={note.author.person.employee.id} style={{ display: "inline" }}>
								<EmployeeLink instance={{ id: note.author.person.employee.id, person: note.author.person }} />
							</EmployeePreviewTooltip>
						) : (
							note.author.person.name
						)}
					</Row>
				)}
			</Row>
		</Modal.Footer>
	);
}
