import { omitKeys } from "@hex-insights/core";
import { ContractInstallment, Invoice, InvoiceLineItem, PaymentLineItem } from "../../GraphQL";
import * as InvoiceLineItemFormValues from "./invoice-line-item";

export type Base = {
	contractInstallmentID: ContractInstallment["id"] | null;
	createdDate: Invoice["createdDate"] | null;
	dueDate: Invoice["dueDate"] | null;
	description: Invoice["description"];
};

export type Create = Base & {
	invoiceLineItems: Omit<InvoiceLineItemFormValues.Create, "invoiceID">[];
};

export type Detail = Base & {
	invoiceNumber: Invoice["invoiceNumber"];
	invoiceLineItemIDs: InvoiceLineItem["id"][];
	paymentLineItemIDs: PaymentLineItem["id"][];
};

export const blankInvoiceLineItem: Omit<InvoiceLineItemFormValues.Create, "invoiceID"> = omitKeys(
	InvoiceLineItemFormValues.initialCreate,
	["invoiceID"],
);

export const initialCreate: Create = {
	contractInstallmentID: null,
	createdDate: null,
	dueDate: null,
	description: "",
	invoiceLineItems: [blankInvoiceLineItem],
};
