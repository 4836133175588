import React from "react";
import { ApolloCache, FetchResult, gql } from "@apollo/client";
import {
	graphTypeNames,
	MealPlan,
	MealPlanCreateInput,
	MealPlanCreateMutation,
	MealPlanDeleteMutation,
	MealPlanDetailDocument,
	MealPlanDetailQuery,
	MealPlanDetailQueryVariables,
	MealPlanUpdateInput,
	MealPlanUpdateMutation,
	Student,
	useMealPlanCreateMutation,
	useMealPlanDeleteMutation,
	useMealPlanUpdateMutation,
} from "../../GraphQL";
import { MealPlanFormConversion } from "../ModelFormConversion";
import { MealPlanFormValues } from "../ModelFormValues";

/**
 * Returns a `create` function for the MealPlan model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the MealPlan model.
 */
export function useCreate() {
	const [createMealPlan] = useMealPlanCreateMutation();

	return React.useCallback(
		async (formValues: MealPlanFormValues.Create) => {
			const input = MealPlanFormConversion.toGQLCreateInput(formValues);
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createMealPlan({ variables: { input }, update: updateCache });

			return { data: data?.createMealPlan ?? null, errors: errors ?? null };
		},
		[createMealPlan],
	);
}

/**
 * Returns an `update` function for the MealPlan model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the MealPlan model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: MealPlan["id"]) {
	const [updateMealPlan] = useMealPlanUpdateMutation();

	return React.useCallback(
		async (changedFormValues: Partial<MealPlanFormValues.Detail>, initialFormValues: MealPlanFormValues.Detail) => {
			const input = MealPlanFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

			const { data, errors } = await updateMealPlan({ variables: { id, input }, update: updateCache });

			return { data: data?.updateMealPlan ?? null, errors: errors ?? null };
		},
		[updateMealPlan, id],
	);
}

/**
 * Returns a `del` function for the MealPlan model.
 *
 * @param id The ID of the instance to delete.
 */
export function useDelete(id: MealPlan["id"]) {
	const [deleteMealPlan] = useMealPlanDeleteMutation();

	return React.useCallback(async () => {
		const updateCache = getUpdateCacheForDelete(id);

		const { data, errors } = await deleteMealPlan({ variables: { id }, update: updateCache });

		return { data: data?.deleteMealPlan ?? false, errors: errors ?? null };
	}, [deleteMealPlan, id]);
}

function getUpdateCacheForCreate(input: MealPlanCreateInput) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<MealPlanCreateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const createdObject = result.data.createMealPlan;

		cache.writeQuery<MealPlanDetailQuery, MealPlanDetailQueryVariables>({
			query: MealPlanDetailDocument,
			data: { mealPlan: createdObject },
			variables: { id: createdObject.id },
		});

		if (input.studentID) {
			addToMealPlansOfStudentCache(cache, input.studentID, createdObject);
		}

		cache.evict({ id: "ROOT_QUERY", fieldName: "mealPlanConnection" });
	};
}

function getUpdateCacheForUpdate(input: MealPlanUpdateInput, initialFormValues: MealPlanFormValues.Detail) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<MealPlanUpdateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const updatedObject = result.data.updateMealPlan;

		if (initialFormValues.studentID && input.studentID) {
			removeFromMealPlansOfStudentCache(cache, initialFormValues.studentID, updatedObject);
		}
		if (input.studentID) {
			addToMealPlansOfStudentCache(cache, input.studentID, updatedObject);
		}
	};
}

function getUpdateCacheForDelete(id: MealPlan["id"]) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<MealPlanDeleteMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (!result.data?.deleteMealPlan) {
			return;
		}

		cache.evict({ id: cache.identify({ id, __typename: graphTypeNames.MealPlan }) });
		cache.evict({ id: "ROOT_QUERY", fieldName: "mealPlanConnection" });
		cache.gc();
	};
}

function addToMealPlansOfStudentCache(cache: ApolloCache<any>, targetID: Student["id"], object: Pick<MealPlan, "id">) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Student }),
		fields: {
			mealPlans: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromMealPlansOfStudentCache(
	cache: ApolloCache<any>,
	targetID: Student["id"],
	object: Pick<MealPlan, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Student }),
		fields: {
			mealPlans: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function toCacheRef(cache: ApolloCache<any>, object: Pick<MealPlan, "id">) {
	return cache.writeFragment({
		fragment: gql`
			fragment MealPlanRef on MealPlan {
				id
			}
		`,
		data: object,
	});
}
