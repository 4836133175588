import { newPageInfo } from "@hex-insights/router";
import { studentHubDetailPageInfo, StudentHubDetailPageRouteParams } from "../pageinfo";

export type StudentHubDetailAcademicsPageRouteParams = StudentHubDetailPageRouteParams;

export const studentHubDetailAcademicsPageInfo = newPageInfo({
	title: "Academics",
	description: "Academics page for a student.",
	exact: true,
	to: (id: Parameters<typeof studentHubDetailPageInfo.to>[0]) => studentHubDetailPageInfo.to(id) + "/academics",
	path: studentHubDetailPageInfo.path + "/academics",
	parent: studentHubDetailPageInfo,
});
