import React from "react";
import { FieldDisplayArgs, NumberField, SelectField, TextField } from "@hex-insights/forms";
import {
	DiscountFormat,
	DiscountFormValues,
	ServiceSubscriptionSelect,
	useServiceSubscriptionSelectLazyQuery,
} from "../../../../Utilities";
import { ServiceSubscriptionLink } from "../../../Links";
import { BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the Discount model.
 */
type FieldProps<K extends keyof DiscountFormValues.Base = keyof DiscountFormValues.Base> = BaseFieldProps<
	Pick<DiscountFormValues.Base, K>
>;

/**
 * Renders a field component for the `amount` field of the Discount model.
 */
export function Amount({ formState }: FieldProps<"amount">) {
	return (
		<NumberField
			formState={formState}
			name="amount"
			validationUnit={1}
			min={0}
			unit={100}
			format={DiscountFormat.Fields.amount}
		/>
	);
}

/**
 * Renders a field component for the `description` field of the Discount model.
 */
export function Description({ formState }: FieldProps<"description">) {
	return <TextField formState={formState} name="description" />;
}

export type ServiceSubscriptionProps = FieldProps<"serviceSubscriptionID"> & {
	currentServiceSubscription?: ServiceSubscriptionSelect.ModelForOption | null;
	disabled?: boolean;
};

/**
 * Renders a field component for the `serviceSubscription` edge of the Discount model.
 */
export function ServiceSubscription({ formState, currentServiceSubscription, disabled }: ServiceSubscriptionProps) {
	const [loadOptions, { loading, data }] = useServiceSubscriptionSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.serviceSubscriptionID) {
			loadOptions();
		}
	}, [formState.formEditing.serviceSubscriptionID, loadOptions]);
	const options = React.useMemo(
		() => ServiceSubscriptionSelect.toOptions(data?.serviceSubscriptionConnection.edges, currentServiceSubscription),
		[data, currentServiceSubscription],
	);

	return (
		<SelectField
			formState={formState}
			name="serviceSubscriptionID"
			isLoading={loading}
			options={options}
			display={displayServiceSubscription}
			blankValue={null}
			disabled={disabled}
		/>
	);
}

function displayServiceSubscription({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <ServiceSubscriptionLink instance={{ id }}>{formattedValue}</ServiceSubscriptionLink>;
}
