import { newPageInfo } from "@hex-insights/router";
import { CourseSection, Student } from "@hex-insights/verita.shared";
import { reportCardsTermCourseSectionPageInfo, ReportCardsTermCourseSectionPageRouteParams } from "../pageinfo";

export type ReportCardsTermCourseSectionStudentPageRouteParams = ReportCardsTermCourseSectionPageRouteParams & {
	studentID: string;
};

type CourseSectionForTitle = Pick<CourseSection, "name">;

type ParentToParameters = Parameters<typeof reportCardsTermCourseSectionPageInfo.to>;

export const reportCardsTermCourseSectionStudentPageInfo = newPageInfo({
	name: "Report Cards Term Course Section Student View",
	title: (e: CourseSectionForTitle) => e.name,
	description: "Student course section report cards page.",
	exact: true,
	to: (termID: ParentToParameters[0], courseSectionID: ParentToParameters[1], studentID: Student["id"]) =>
		reportCardsTermCourseSectionPageInfo.to(termID, courseSectionID) + `/${studentID}`,
	path: reportCardsTermCourseSectionPageInfo.path + "/:studentID(\\d+)",
	parent: reportCardsTermCourseSectionPageInfo,
});
