import { toLocalDateString } from "@hex-insights/core";
import {
	getYearsFromGraduationForGraduationYear,
	HomeRoomSection,
	Student,
	useBusPlanIndexQuery,
	useCampusIndexQuery,
	useClassYearIndexQuery,
	useEmployeeIndexQuery,
	useHomeRoomSectionWithHomeRoomListQuery,
	useMealPlanIndexQuery,
	useStudentDetailQuery,
} from "../../../../Utilities";

export function useStudentClassYearsQuery(studentID: Student["id"]) {
	const { data } = useStudentDetailQuery({ variables: { id: studentID } });
	const student = data!.student;

	return useClassYearIndexQuery({
		variables: {
			filters: {
				yearsFromGraduationEQ: getYearsFromGraduationForGraduationYear(student.graduatingYear),
			},
		},
	});
}

export function useStudentCurrentHomeRoomSectionsQuery(studentID: Student["id"]) {
	const currentDate = toLocalDateString(new Date());

	return useHomeRoomSectionWithHomeRoomListQuery({
		variables: {
			filters: {
				term: [
					{
						startDateLTE: currentDate,
						endDateGTE: currentDate,
					},
				],
				homeRoomSectionStudentEnrollments: [{ studentIDEQ: studentID }],
			},
		},
	});
}

export function useStudentTeachersQuery(studentID: Student["id"]) {
	const today = toLocalDateString(new Date());

	return useEmployeeIndexQuery({
		variables: {
			filters: {
				homeRoomSectionTeacherEnrollments: [
					{
						homeRoomSection: [
							{
								homeRoomSectionStudentEnrollments: [{ studentIDEQ: studentID }],
								term: [{ startDateLTE: today, endDateGTE: today }],
							},
						],
					},
				],
			},
		},
	});
}

export function useStudentCampusesQuery(studentID: Student["id"]) {
	return useCampusIndexQuery({
		variables: {
			filters: {
				rooms: [
					{
						courseSectionPeriods: [
							{
								courseSection: [
									{
										courseSectionStudentEnrollments: [{ studentIDEQ: studentID }],
									},
								],
							},
						],
					},
					{
						homeRoomSections: [
							{
								homeRoomSectionStudentEnrollments: [{ studentIDEQ: studentID }],
							},
						],
					},
				],
			},
		},
	});
}

export function useStudentBusPlanQuery(studentID: Student["id"]) {
	return useBusPlanIndexQuery({
		variables: {
			filters: {
				student: [{ idEQ: studentID }],
			},
		},
	});
}

export function useStudentMealPlanQuery(studentID: Student["id"]) {
	return useMealPlanIndexQuery({
		variables: {
			filters: {
				student: [{ idEQ: studentID }],
			},
		},
	});
}

export function getHomeRoomSectionLabel(
	homeRoomSection: Pick<HomeRoomSection, "name"> & { homeRoom: Pick<HomeRoomSection["homeRoom"], "name"> },
) {
	return (
		homeRoomSection.name.startsWith(homeRoomSection.homeRoom.name)
			? homeRoomSection.name.slice(homeRoomSection.homeRoom.name.length)
			: homeRoomSection.name
	).trim();
}
