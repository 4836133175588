import { formatDateTime, Heading } from "@hex-insights/core";
import { useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import { Tile } from "@hex-insights/verita.shared";
import { LessonPlanFormControlledCreate } from "../Day/Form";
import { LessonPlansWeekCourseSectionPageRouteParams } from "../pageinfo";
import { lessonPlansWeekCourseSectionIndexPageInfo } from "./pageinfo";

export function LessonPlansCourseSectionWeekIndexPage() {
	useActivePageRegistration(lessonPlansWeekCourseSectionIndexPageInfo);

	const { courseSectionID, date: weekStartDate } = useRouteParams<LessonPlansWeekCourseSectionPageRouteParams>();

	return (
		<Tile style={{ width: "32rem" }}>
			<Tile.Header>
				<Heading level={2} noMargin>
					{formatDateTime(weekStartDate, "dddd, D MMM YYYY")}
				</Heading>
			</Tile.Header>

			<Tile.Body style={{ height: "100%", overflowY: "scroll" }}>
				<LessonPlanFormControlledCreate
					courseSectionID={courseSectionID}
					applyCreate={doNothingApplyCreate}
					onSuccess={doNothingOnSuccess}
					formNameSuffix="index"
				/>
			</Tile.Body>
		</Tile>
	);
}

async function doNothingApplyCreate() {
	return null;
}

function doNothingOnSuccess() {}
