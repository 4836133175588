import React from "react";
import { ApplyCreateFunction, ApplyUpdateFunction, onCreateSubmit, onUpdateSubmit } from "@hex-insights/app-modules";
import {
	Button,
	Column,
	Conditional,
	Else,
	formatDateTime,
	Heading,
	HeadingLevel,
	If,
	Row,
	Section,
	toGrammaticalNumber,
} from "@hex-insights/core";
import {
	anyFieldEditing,
	everyFieldEditing,
	Form,
	FormState,
	FormSubmitFunction,
	FormType,
	SubFormField,
	SubmissionStatus,
	SubmitButton,
	useInternalField,
} from "@hex-insights/forms";
import { BasicTable } from "@hex-insights/tables";
import {
	ContractDetailQuery,
	ContractFormConversion,
	ContractFormState,
	ContractFormValues,
	ContractInstallmentFormValues,
	ServiceSubscriptionFormat,
	ServiceSubscriptionFormValues,
	useServiceSelectQuery,
} from "../../../../Utilities";
import { FormActionRow, FormColumn } from "../../../FormAddon";
import { HR } from "../../../HR";
import { ContractInstallmentForm } from "../ContractInstallment";
import { ServiceSubscriptionForm } from "../ServiceSubscription";
import * as Field from "./Field";
import styles from "./styles.module.css";

export type ControlledCreateProps = {
	applyCreate: ApplyCreateFunction<ContractFormValues.Create>;
	onSuccess: () => void;
	headingLevel?: HeadingLevel;
	formNameSuffix?: string;
};

/**
 * Renders the create form of the Contract model using an internally managed form state.
 */
export function ControlledCreate(props: ControlledCreateProps) {
	const formState = ContractFormState.useCreateFormState();

	return <Create formState={formState} {...props} />;
}

export type CreateProps = ControlledCreateProps & {
	formState: FormState<ContractFormValues.Create>;
};

/**
 * Renders the create form of the Contract model using the given form state.
 */
export function Create({ formState, applyCreate, onSuccess, headingLevel = 2, formNameSuffix = "" }: CreateProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<ContractFormValues.Create>>(
		async ({ formValues }) => {
			return onCreateSubmit(formValues, applyCreate);
		},
		[applyCreate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	const totalPrice = formState.formValues.serviceSubscriptions.reduce((a, e) => {
		const discountTotal = e.discounts.reduce((a, e) => a + (e.amount ?? 0), 0);
		return a + (e.price ?? 0) - discountTotal;
	}, 0);

	return (
		<Form
			name={"contract.create" + formNameSuffix}
			formState={formState}
			onSubmit={formState.onSubmitWrapper(onSubmit)}
		>
			<FormColumn>
				<Field.Student formState={formState} formType={FormType.Create} />
				<Field.AgreementDate formState={formState} formType={FormType.Create} />
				<Field.Status formState={formState} formType={FormType.Create} />

				<Heading level={headingLevel} noMargin>
					Services
				</Heading>
				<SubFormField
					formState={formState}
					name="serviceSubscriptions"
					label="Services"
					formattedBlank=""
					dividerElement={<div style={{ marginBottom: "0.5rem" }} />}
					minItems={1}
					blankItem={ServiceSubscriptionFormValues.initialCreateInContract}
					className={styles["create__service-subscriptions"]}
				>
					{ServiceSubscriptionForm.CreateInContract}
					{({ onClick, disabled }) => (
						<Row justify="center" style={{ padding: "0.5rem 0" }}>
							<Button variant="secondary" onClick={onClick} disabled={disabled}>
								Add Service
							</Button>
						</Row>
					)}
				</SubFormField>

				<div style={{ fontSize: "1.2rem" }}>
					Total Contract Price: <strong>{ServiceSubscriptionFormat.Fields.price(totalPrice)}</strong>
				</div>

				<Heading level={headingLevel} noMargin>
					Payment Schedule
				</Heading>
				<Field.PaymentScheduleType
					formState={formState}
					formType={FormType.Create}
					className={styles["payment-schedule-type"]}
				/>
				<SubFormField
					formState={formState}
					name="contractInstallments"
					label="Installments"
					formattedBlank=""
					minItems={1}
					blankItem={ContractInstallmentFormValues.initialCreateInContract}
					className={styles["create__contract-installments"]}
				>
					{(props) => (
						<ContractInstallmentForm.CreateInContract
							{...props}
							portionDisabled={
								formState.formValues.paymentScheduleType !== ContractFormValues.PaymentScheduleType.Custom
							}
						/>
					)}
					{({ onClick, disabled }) => (
						<If condition={formState.formValues.paymentScheduleType === ContractFormValues.PaymentScheduleType.Custom}>
							<div style={{ paddingLeft: "1rem" }}>
								<Button variant="secondary" size="small" onClick={onClick} disabled={disabled}>
									Add Installment
								</Button>
							</div>
						</If>
					)}
				</SubFormField>

				<HR color="#ddd" style={{ width: "80%", margin: "0.5rem auto" }} />

				<ContractReview headingLevel={headingLevel} formState={formState} totalPrice={totalPrice} />

				<FormActionRow>
					<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
						Submit
					</SubmitButton>
				</FormActionRow>
			</FormColumn>
		</Form>
	);
}

type ContractReviewProps = {
	headingLevel: HeadingLevel;
	formState: FormState<ContractFormValues.Create>;
	totalPrice: number;
};

function ContractReview({ headingLevel, formState, totalPrice }: ContractReviewProps) {
	const { data: servicesData } = useServiceSelectQuery();

	const serviceSubscriptions = formState.formValues.serviceSubscriptions.filter((e) => e.serviceID !== null);

	const totalPortion = formState.formValues.contractInstallments.reduce((a, e) => a + (e.portion ?? 0), 0);

	return (
		<Section>
			<Section.Header>
				<Heading level={headingLevel} noMargin>
					Review
				</Heading>
			</Section.Header>
			<Section.Body style={{ fontSize: "1.2rem" }}>
				<Column justify="spaced-start">
					<div>
						Services Included:
						<Conditional>
							<If condition={serviceSubscriptions.length === 0}> None added</If>
							<Else>
								<BasicTable>
									<BasicTable.Body>
										{serviceSubscriptions.map((ss, i) => {
											if (!servicesData || ss.serviceID === null) {
												return null;
											}
											const serviceName = servicesData.serviceConnection.edges.find((e) => e.node.id === ss.serviceID)
												?.node.name;

											const totalPrice = (ss.price ?? 0) - ss.discounts.reduce((a, e) => a + (e.amount ?? 0), 0);
											return (
												<BasicTable.Row key={i}>
													<BasicTable.Cell>{i + 1}.</BasicTable.Cell>
													<BasicTable.Cell>{serviceName}:</BasicTable.Cell>
													<BasicTable.Cell>{ServiceSubscriptionFormat.Fields.price(totalPrice)}</BasicTable.Cell>
												</BasicTable.Row>
											);
										})}
									</BasicTable.Body>
								</BasicTable>
							</Else>
						</Conditional>
					</div>

					<div>
						For a total price of: <strong>{ServiceSubscriptionFormat.Fields.price(totalPrice)}</strong>
					</div>

					<div>
						<div>
							Paid in: {toGrammaticalNumber("payment", formState.formValues.contractInstallments.length, true)}:
						</div>

						<BasicTable>
							<BasicTable.Body>
								{formState.formValues.contractInstallments.map((installment, i) => (
									<BasicTable.Row key={i}>
										<BasicTable.Cell>{i + 1}.</BasicTable.Cell>
										<BasicTable.Cell>
											{installment.installmentDate
												? formatDateTime(installment.installmentDate, "D MMM YYYY")
												: "[No Date]"}
											:
										</BasicTable.Cell>
										<BasicTable.Cell>
											{ServiceSubscriptionFormat.Fields.price((totalPrice * (installment.portion ?? 0)) / totalPortion)}
										</BasicTable.Cell>
										<BasicTable.Cell>
											({installment.portion ?? 0}/{totalPortion})
										</BasicTable.Cell>
									</BasicTable.Row>
								))}
							</BasicTable.Body>
						</BasicTable>
					</div>
				</Column>
			</Section.Body>
		</Section>
	);
}

export type ControlledDetailProps = {
	contract: ContractDetailQuery["contract"];
	applyUpdate: ApplyUpdateFunction<ContractFormValues.Detail>;
	onSuccess: () => void;
};

/**
 * Renders the detail form of the Contract model using an internally managed form state.
 */
export function ControlledDetail(props: ControlledDetailProps) {
	const initialFormValues = React.useMemo(() => ContractFormConversion.toFormValues(props.contract), [props.contract]);
	const formState = ContractFormState.useDetailFormState({ initialFormValues });

	return <Detail formState={formState} {...props} />;
}

export type DetailProps = ControlledDetailProps & {
	formState: FormState<ContractFormValues.Detail>;
};

/**
 * Renders the detail form of the Contract model using the given form state.
 */
export function Detail({ formState, contract, applyUpdate, onSuccess }: DetailProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<ContractFormValues.Detail>>(
		async (formState) => {
			return onUpdateSubmit(formState, applyUpdate);
		},
		[applyUpdate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	const { anyEditing, everyEditing } = React.useMemo(() => {
		return {
			anyEditing: anyFieldEditing({
				formEditing: formState.formEditing,
				formSubFormStates: formState.formSubFormStates,
			}),
			everyEditing: everyFieldEditing({
				formEditing: formState.formEditing,
				formSubFormStates: formState.formSubFormStates,
			}),
		};
	}, [formState.formEditing, formState.formSubFormStates]);

	return (
		<Form name={`contract.detail.${contract.id}`} formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)}>
			<FormColumn>
				<Row justify="spaced-start" horizontalSpacing="0.75rem">
					<If condition={!everyEditing}>
						<Button variant="tertiary" size="small" onClick={() => formState.setFormEditing(true)}>
							Edit
						</Button>
					</If>
					<If condition={anyEditing}>
						<Button variant="tertiary" size="small" onClick={() => formState.setFormEditing(false)}>
							Stop Editing
						</Button>
					</If>
				</Row>

				<Field.Student
					formState={formState}
					formType={FormType.Update}
					id={contract.id}
					currentStudent={contract.student}
				/>
				<Field.AgreementDate formState={formState} formType={FormType.Update} id={contract.id} />
				<Field.Status formState={formState} formType={FormType.Update} id={contract.id} />
				<Field.ContractInstallments
					formState={formState}
					formType={FormType.Update}
					id={contract.id}
					currentContractInstallments={contract.contractInstallments}
				/>
				<Field.ServiceSubscriptions
					formState={formState}
					formType={FormType.Update}
					id={contract.id}
					currentServiceSubscriptions={contract.serviceSubscriptions}
				/>
			</FormColumn>
		</Form>
	);
}

export type ControlledDetailSlimProps = {
	contract: Pick<ContractDetailQuery["contract"], "id" | "agreementDate" | "status" | "student">;
	applyUpdate: ApplyUpdateFunction<ContractFormValues.Detail>;
	onSuccess: () => void;
};

/**
 * Renders the detail form of the Contract model using an internally managed form state.
 */
export function ControlledDetailSlim(props: ControlledDetailSlimProps) {
	const initialFormValues = React.useMemo(
		() =>
			ContractFormConversion.toFormValues({
				...props.contract,
				contractInstallments: [],
				serviceSubscriptions: [],
			}),
		[props.contract],
	);
	const formState = ContractFormState.useDetailFormState({ initialFormValues });

	return <DetailSlim formState={formState} {...props} />;
}

const slimHiddenFields: (keyof ContractFormValues.Detail)[] = ["contractInstallmentIDs", "serviceSubscriptionIDs"];

export type DetailSlimProps = ControlledDetailSlimProps & {
	formState: FormState<ContractFormValues.Detail>;
};

/**
 * Renders the detail form of the Contract model using the given form state.
 */
export function DetailSlim({ formState, contract, applyUpdate, onSuccess }: DetailSlimProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<ContractFormValues.Detail>>(
		async (formState) => {
			return onUpdateSubmit(formState, applyUpdate);
		},
		[applyUpdate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	const { anyEditing, everyEditing } = React.useMemo(() => {
		return {
			anyEditing: anyFieldEditing({
				formEditing: formState.formEditing,
				formSubFormStates: formState.formSubFormStates,
			}),
			everyEditing: everyFieldEditing({
				formEditing: formState.formEditing,
				formSubFormStates: formState.formSubFormStates,
			}),
		};
	}, [formState.formEditing, formState.formSubFormStates]);

	useInternalField(formState, slimHiddenFields);

	return (
		<Form
			name={`contract.detail.${contract.id}.fieldsOnly`}
			formState={formState}
			onSubmit={formState.onSubmitWrapper(onSubmit)}
		>
			<FormColumn>
				<Row justify="spaced-start" horizontalSpacing="0.75rem">
					<If condition={!everyEditing}>
						<Button variant="tertiary" size="small" onClick={() => formState.setFormEditing(true)}>
							Edit
						</Button>
					</If>
					<If condition={anyEditing}>
						<Button variant="tertiary" size="small" onClick={() => formState.setFormEditing(false)}>
							Stop Editing
						</Button>
					</If>
				</Row>

				<Row justify="spaced-start" align="spaced-start" overflow="wrap">
					<Field.Student
						formState={formState}
						formType={FormType.Update}
						id={contract.id}
						currentStudent={contract.student}
					/>
					<Field.AgreementDate formState={formState} formType={FormType.Update} id={contract.id} />
				</Row>
				<Field.Status formState={formState} formType={FormType.Update} id={contract.id} />
			</FormColumn>
		</Form>
	);
}

export type ControlledReadOnlyProps = {
	contract: ContractDetailQuery["contract"];
};

/**
 * Renders a read-only detail form of the Contract model using an internally managed form state.
 */
export function ControlledReadOnly(props: ControlledReadOnlyProps) {
	const initialFormValues = React.useMemo(() => ContractFormConversion.toFormValues(props.contract), [props.contract]);
	const formState = ContractFormState.useReadOnlyFormState({ initialFormValues });

	return <ReadOnly formState={formState} {...props} />;
}

export type ReadOnlyProps = ControlledReadOnlyProps & {
	formState: FormState<ContractFormValues.Detail>;
};

/**
 * Renders a read-only detail form of the Contract model using the given form state.
 */
export function ReadOnly({ formState, contract }: ReadOnlyProps) {
	return (
		<FormColumn>
			<Field.Student
				formState={formState}
				formType={FormType.Update}
				id={contract.id}
				currentStudent={contract.student}
			/>
			<Field.AgreementDate formState={formState} formType={FormType.Update} id={contract.id} />
			<Field.Status formState={formState} formType={FormType.Update} id={contract.id} />
			<Field.ContractInstallments
				formState={formState}
				formType={FormType.Update}
				id={contract.id}
				currentContractInstallments={contract.contractInstallments}
			/>
			<Field.ServiceSubscriptions
				formState={formState}
				formType={FormType.Update}
				id={contract.id}
				currentServiceSubscriptions={contract.serviceSubscriptions}
			/>
		</FormColumn>
	);
}
