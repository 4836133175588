import { omitKeys } from "@hex-insights/core";
import { EmailAddress, Person } from "../../GraphQL";

export type Base = {
	email: EmailAddress["email"];
	personID: Person["id"] | null;
};

export type Create = Base;

export type Detail = Base;

export const initialCreate: Create = {
	email: "",
	personID: null,
};

export type CreateInPerson = Omit<Create, "personID">;

export const initialCreateInPerson: CreateInPerson = omitKeys(initialCreate, ["personID"]);
