import React from "react";
import { ApolloCache, FetchResult, gql } from "@apollo/client";
import {
	CalendarEvent,
	CourseSection,
	Employee,
	graphTypeNames,
	HomeRoomSection,
	Note,
	NoteCreateInput,
	NoteCreateMutation,
	NoteDeleteMutation,
	NoteDetailDocument,
	NoteDetailQuery,
	NoteDetailQueryVariables,
	NoteUpdateInput,
	NoteUpdateMutation,
	Parent,
	Student,
	useNoteCreateMutation,
	useNoteDeleteMutation,
	useNoteUpdateMutation,
} from "../../GraphQL";
import { NoteFormConversion } from "../ModelFormConversion";
import { NoteFormValues } from "../ModelFormValues";

/**
 * Returns a `create` function for the Note model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the Note model.
 */
export function useCreate() {
	const [createNote] = useNoteCreateMutation();

	return React.useCallback(
		async (formValues: NoteFormValues.PartialCreate) => {
			const input = NoteFormConversion.toGQLCreateInput(formValues);
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createNote({ variables: { input }, update: updateCache });

			return { data: data?.createNote ?? null, errors: errors ?? null };
		},
		[createNote],
	);
}

/**
 * Returns an `update` function for the Note model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the Note model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: Note["id"]) {
	const [updateNote] = useNoteUpdateMutation();

	return React.useCallback(
		async (changedFormValues: Partial<NoteFormValues.Detail>, initialFormValues: Partial<NoteFormValues.Detail>) => {
			const input = NoteFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

			const { data, errors } = await updateNote({ variables: { id, input }, update: updateCache });

			return { data: data?.updateNote ?? null, errors: errors ?? null };
		},
		[updateNote, id],
	);
}

/**
 * Returns a `del` function for the Note model.
 *
 * @param id The ID of the instance to delete.
 */
export function useDelete(id: Note["id"]) {
	const [deleteNote] = useNoteDeleteMutation();

	return React.useCallback(async () => {
		const updateCache = getUpdateCacheForDelete(id);

		const { data, errors } = await deleteNote({ variables: { id }, update: updateCache });

		return { data: data?.deleteNote ?? false, errors: errors ?? null };
	}, [deleteNote, id]);
}

function getUpdateCacheForCreate(input: NoteCreateInput) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<NoteCreateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const createdObject = result.data.createNote;

		cache.writeQuery<NoteDetailQuery, NoteDetailQueryVariables>({
			query: NoteDetailDocument,
			data: { note: createdObject },
			variables: { id: createdObject.id },
		});

		if (input.calendarEventIDs) {
			for (let i = 0; i < input.calendarEventIDs.length; i++) {
				addToNotesOfCalendarEventCache(cache, input.calendarEventIDs[i], createdObject);
			}
		}

		if (input.courseSectionIDs) {
			for (let i = 0; i < input.courseSectionIDs.length; i++) {
				addToNotesOfCourseSectionCache(cache, input.courseSectionIDs[i], createdObject);
			}
		}

		if (input.employeeIDs) {
			for (let i = 0; i < input.employeeIDs.length; i++) {
				addToNotesOfEmployeeCache(cache, input.employeeIDs[i], createdObject);
			}
		}

		if (input.homeRoomSectionIDs) {
			for (let i = 0; i < input.homeRoomSectionIDs.length; i++) {
				addToNotesOfHomeRoomSectionCache(cache, input.homeRoomSectionIDs[i], createdObject);
			}
		}

		if (input.parentIDs) {
			for (let i = 0; i < input.parentIDs.length; i++) {
				addToNotesOfParentCache(cache, input.parentIDs[i], createdObject);
			}
		}

		if (input.studentIDs) {
			for (let i = 0; i < input.studentIDs.length; i++) {
				addToNotesOfStudentCache(cache, input.studentIDs[i], createdObject);
			}
		}

		cache.evict({ id: "ROOT_QUERY", fieldName: "noteConnection" });
	};
}

function getUpdateCacheForUpdate(input: NoteUpdateInput, _initialFormValues: Partial<NoteFormValues.Detail>) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<NoteUpdateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const updatedObject = result.data.updateNote;

		if (input.addCalendarEventIDs) {
			for (let i = 0; i < input.addCalendarEventIDs.length; i++) {
				addToNotesOfCalendarEventCache(cache, input.addCalendarEventIDs[i], updatedObject);
			}
		}
		if (input.removeCalendarEventIDs) {
			for (let i = 0; i < input.removeCalendarEventIDs.length; i++) {
				removeFromNotesOfCalendarEventCache(cache, input.removeCalendarEventIDs[i], updatedObject);
			}
		}

		if (input.addCourseSectionIDs) {
			for (let i = 0; i < input.addCourseSectionIDs.length; i++) {
				addToNotesOfCourseSectionCache(cache, input.addCourseSectionIDs[i], updatedObject);
			}
		}
		if (input.removeCourseSectionIDs) {
			for (let i = 0; i < input.removeCourseSectionIDs.length; i++) {
				removeFromNotesOfCourseSectionCache(cache, input.removeCourseSectionIDs[i], updatedObject);
			}
		}

		if (input.addEmployeeIDs) {
			for (let i = 0; i < input.addEmployeeIDs.length; i++) {
				addToNotesOfEmployeeCache(cache, input.addEmployeeIDs[i], updatedObject);
			}
		}
		if (input.removeEmployeeIDs) {
			for (let i = 0; i < input.removeEmployeeIDs.length; i++) {
				removeFromNotesOfEmployeeCache(cache, input.removeEmployeeIDs[i], updatedObject);
			}
		}

		if (input.addHomeRoomSectionIDs) {
			for (let i = 0; i < input.addHomeRoomSectionIDs.length; i++) {
				addToNotesOfHomeRoomSectionCache(cache, input.addHomeRoomSectionIDs[i], updatedObject);
			}
		}
		if (input.removeHomeRoomSectionIDs) {
			for (let i = 0; i < input.removeHomeRoomSectionIDs.length; i++) {
				removeFromNotesOfHomeRoomSectionCache(cache, input.removeHomeRoomSectionIDs[i], updatedObject);
			}
		}

		if (input.addParentIDs) {
			for (let i = 0; i < input.addParentIDs.length; i++) {
				addToNotesOfParentCache(cache, input.addParentIDs[i], updatedObject);
			}
		}
		if (input.removeParentIDs) {
			for (let i = 0; i < input.removeParentIDs.length; i++) {
				removeFromNotesOfParentCache(cache, input.removeParentIDs[i], updatedObject);
			}
		}

		if (input.addStudentIDs) {
			for (let i = 0; i < input.addStudentIDs.length; i++) {
				addToNotesOfStudentCache(cache, input.addStudentIDs[i], updatedObject);
			}
		}
		if (input.removeStudentIDs) {
			for (let i = 0; i < input.removeStudentIDs.length; i++) {
				removeFromNotesOfStudentCache(cache, input.removeStudentIDs[i], updatedObject);
			}
		}
	};
}

function getUpdateCacheForDelete(id: Note["id"]) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<NoteDeleteMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (!result.data?.deleteNote) {
			return;
		}

		cache.evict({ id: cache.identify({ id, __typename: graphTypeNames.Note }) });
		cache.evict({ id: "ROOT_QUERY", fieldName: "noteConnection" });
		cache.gc();
	};
}

function addToNotesOfCalendarEventCache(
	cache: ApolloCache<any>,
	targetID: CalendarEvent["id"],
	object: Pick<Note, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.CalendarEvent }),
		fields: {
			notes: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromNotesOfCalendarEventCache(
	cache: ApolloCache<any>,
	targetID: CalendarEvent["id"],
	object: Pick<Note, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.CalendarEvent }),
		fields: {
			notes: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function addToNotesOfCourseSectionCache(
	cache: ApolloCache<any>,
	targetID: CourseSection["id"],
	object: Pick<Note, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.CourseSection }),
		fields: {
			notes: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromNotesOfCourseSectionCache(
	cache: ApolloCache<any>,
	targetID: CourseSection["id"],
	object: Pick<Note, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.CourseSection }),
		fields: {
			notes: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function addToNotesOfEmployeeCache(cache: ApolloCache<any>, targetID: Employee["id"], object: Pick<Note, "id">) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Employee }),
		fields: {
			notes: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromNotesOfEmployeeCache(cache: ApolloCache<any>, targetID: Employee["id"], object: Pick<Note, "id">) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Employee }),
		fields: {
			notes: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function addToNotesOfHomeRoomSectionCache(
	cache: ApolloCache<any>,
	targetID: HomeRoomSection["id"],
	object: Pick<Note, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.HomeRoomSection }),
		fields: {
			notes: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromNotesOfHomeRoomSectionCache(
	cache: ApolloCache<any>,
	targetID: HomeRoomSection["id"],
	object: Pick<Note, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.HomeRoomSection }),
		fields: {
			notes: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function addToNotesOfParentCache(cache: ApolloCache<any>, targetID: Parent["id"], object: Pick<Note, "id">) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Parent }),
		fields: {
			notes: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromNotesOfParentCache(cache: ApolloCache<any>, targetID: Parent["id"], object: Pick<Note, "id">) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Parent }),
		fields: {
			notes: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function addToNotesOfStudentCache(cache: ApolloCache<any>, targetID: Student["id"], object: Pick<Note, "id">) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Student }),
		fields: {
			notes: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromNotesOfStudentCache(cache: ApolloCache<any>, targetID: Student["id"], object: Pick<Note, "id">) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Student }),
		fields: {
			notes: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function toCacheRef(cache: ApolloCache<any>, object: Pick<Note, "id">) {
	return cache.writeFragment({
		fragment: gql`
			fragment NoteRef on Note {
				id
			}
		`,
		data: object,
	});
}
