import { IndexForms } from "@hex-insights/app-modules";
import { CaseStyle, convertCase } from "@hex-insights/core";
import { OrderDirection, SessionOrderField } from "../../GraphQL";

export type FormValues = IndexForms.OrderFormValues<SessionOrderField | null>;

export const initial: FormValues = {
	field: SessionOrderField.CreatedAt,
	direction: OrderDirection.Asc,
};

/**
 * Options for the "field" field in the order form of the Session model.
 */
export const fieldOptions = [
	{ label: "ID", value: null },
	{
		label: convertCase(SessionOrderField.CreatedAt.toLowerCase(), CaseStyle.Title),
		value: SessionOrderField.CreatedAt,
	},
	{
		label: convertCase(SessionOrderField.UpdatedAt.toLowerCase(), CaseStyle.Title),
		value: SessionOrderField.UpdatedAt,
	},
	{
		label: convertCase(SessionOrderField.ExpiresAt.toLowerCase(), CaseStyle.Title),
		value: SessionOrderField.ExpiresAt,
	},
];

/**
 * Array of values for the "field" field in the order form of the Session model.
 */
export const fieldValues = fieldOptions.map((e) => e.value);

/**
 * Mapping from order fields of the Session model to their corresponding order type.
 */
export const fieldTypeMapping = {
	[SessionOrderField.CreatedAt]: IndexForms.OrderFieldType.Time,
	[SessionOrderField.UpdatedAt]: IndexForms.OrderFieldType.Time,
	[SessionOrderField.ExpiresAt]: IndexForms.OrderFieldType.Time,
};

/**
 * Mapping from order fields of the Session model to their corresponding field name.
 */
export const orderFieldToModelField = {
	[SessionOrderField.CreatedAt]: "createdAt",
	[SessionOrderField.UpdatedAt]: "updatedAt",
	[SessionOrderField.ExpiresAt]: "expiresAt",
};

/**
 * Mapping from field names of the Session model to their corresponding order field.
 */
export const modelFieldToOrderField = {
	createdAt: SessionOrderField.CreatedAt,
	updatedAt: SessionOrderField.UpdatedAt,
	expiresAt: SessionOrderField.ExpiresAt,
};
