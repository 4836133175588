import React from "react";
import { FieldDisplayArgs, RadioField, SelectField, TextField } from "@hex-insights/forms";
import { PersonSelect, PhoneNumberFormValues, usePersonSelectLazyQuery } from "../../../../Utilities";
import { PersonLink } from "../../../Links";
import { BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the PhoneNumber model.
 */
type FieldProps<K extends keyof PhoneNumberFormValues.Base = keyof PhoneNumberFormValues.Base> = BaseFieldProps<
	Pick<PhoneNumberFormValues.Base, K>
>;

/**
 * Renders a field component for the `phoneNumber` field of the PhoneNumber model.
 */
export function PhoneNumber({ formState }: FieldProps<"phoneNumber">) {
	return <TextField formState={formState} name="phoneNumber" />;
}

/**
 * Renders a field component for the `phoneType` field of the PhoneNumber model.
 */
export function PhoneType({ formState }: FieldProps<"phoneType">) {
	return (
		<RadioField
			formState={formState}
			name="phoneType"
			options={PhoneNumberFormValues.phoneTypeOptions}
			blankValue={null}
		/>
	);
}

export type PersonProps = FieldProps<"personID"> & {
	currentPerson?: PersonSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `person` edge of the PhoneNumber model.
 */
export function Person({ formState, currentPerson }: PersonProps) {
	const [loadOptions, { loading, data }] = usePersonSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.personID) {
			loadOptions();
		}
	}, [formState.formEditing.personID, loadOptions]);
	const options = React.useMemo(
		() => PersonSelect.toOptions(data?.personConnection.edges, currentPerson),
		[data, currentPerson],
	);

	return (
		<SelectField
			formState={formState}
			name="personID"
			isLoading={loading}
			options={options}
			display={displayPerson}
			blankValue={null}
		/>
	);
}

function displayPerson({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <PersonLink instance={{ id }}>{formattedValue}</PersonLink>;
}
