import React from "react";
import { ApolloCache, FetchResult, gql } from "@apollo/client";
import {
	Contract,
	Discount,
	graphTypeNames,
	InvoiceLineItem,
	Service,
	ServiceSubscription,
	ServiceSubscriptionCreateInput,
	ServiceSubscriptionCreateMutation,
	ServiceSubscriptionDeleteMutation,
	ServiceSubscriptionDetailDocument,
	ServiceSubscriptionDetailQuery,
	ServiceSubscriptionDetailQueryVariables,
	ServiceSubscriptionUpdateInput,
	ServiceSubscriptionUpdateMutation,
	useServiceSubscriptionCreateMutation,
	useServiceSubscriptionDeleteMutation,
	useServiceSubscriptionUpdateMutation,
} from "../../GraphQL";
import { ServiceSubscriptionFormConversion } from "../ModelFormConversion";
import { ServiceSubscriptionFormValues } from "../ModelFormValues";

/**
 * Returns a `create` function for the ServiceSubscription model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the ServiceSubscription model.
 */
export function useCreate() {
	const [createServiceSubscription] = useServiceSubscriptionCreateMutation();

	return React.useCallback(
		async (formValues: ServiceSubscriptionFormValues.Create) => {
			const input = ServiceSubscriptionFormConversion.toGQLCreateInput(formValues);
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createServiceSubscription({ variables: { input }, update: updateCache });

			return { data: data?.createServiceSubscription ?? null, errors: errors ?? null };
		},
		[createServiceSubscription],
	);
}

/**
 * Returns an `update` function for the ServiceSubscription model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the ServiceSubscription model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: ServiceSubscription["id"]) {
	const [updateServiceSubscription] = useServiceSubscriptionUpdateMutation();

	return React.useCallback(
		async (
			changedFormValues: Partial<ServiceSubscriptionFormValues.Detail>,
			initialFormValues: ServiceSubscriptionFormValues.Detail,
		) => {
			const input = ServiceSubscriptionFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

			const { data, errors } = await updateServiceSubscription({ variables: { id, input }, update: updateCache });

			return { data: data?.updateServiceSubscription ?? null, errors: errors ?? null };
		},
		[updateServiceSubscription, id],
	);
}

/**
 * Returns a `del` function for the ServiceSubscription model.
 *
 * @param id The ID of the instance to delete.
 */
export function useDelete(id: ServiceSubscription["id"]) {
	const [deleteServiceSubscription] = useServiceSubscriptionDeleteMutation();

	return React.useCallback(async () => {
		const updateCache = getUpdateCacheForDelete(id);

		const { data, errors } = await deleteServiceSubscription({ variables: { id }, update: updateCache });

		return { data: data?.deleteServiceSubscription ?? false, errors: errors ?? null };
	}, [deleteServiceSubscription, id]);
}

export function getUpdateCacheForCreate(input: ServiceSubscriptionCreateInput) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<ServiceSubscriptionCreateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const createdObject = result.data.createServiceSubscription;

		cache.writeQuery<ServiceSubscriptionDetailQuery, ServiceSubscriptionDetailQueryVariables>({
			query: ServiceSubscriptionDetailDocument,
			data: { serviceSubscription: createdObject },
			variables: { id: createdObject.id },
		});

		if (input.contractID) {
			addToServiceSubscriptionsOfContractCache(cache, input.contractID, createdObject);
		}

		if (input.discountIDs) {
			for (let i = 0; i < input.discountIDs.length; i++) {
				addToServiceSubscriptionOfDiscountCache(cache, input.discountIDs[i], createdObject);
			}
		}

		if (input.invoiceLineItemIDs) {
			for (let i = 0; i < input.invoiceLineItemIDs.length; i++) {
				addToServiceSubscriptionOfInvoiceLineItemCache(cache, input.invoiceLineItemIDs[i], createdObject);
			}
		}

		if (input.serviceID) {
			addToServiceSubscriptionsOfServiceCache(cache, input.serviceID, createdObject);
		}

		cache.evict({ id: "ROOT_QUERY", fieldName: "serviceSubscriptionConnection" });
	};
}

function getUpdateCacheForUpdate(
	input: ServiceSubscriptionUpdateInput,
	initialFormValues: ServiceSubscriptionFormValues.Detail,
) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<ServiceSubscriptionUpdateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const updatedObject = result.data.updateServiceSubscription;

		if (initialFormValues.contractID && input.contractID) {
			removeFromServiceSubscriptionsOfContractCache(cache, initialFormValues.contractID, updatedObject);
		}
		if (input.contractID) {
			addToServiceSubscriptionsOfContractCache(cache, input.contractID, updatedObject);
		}

		if (input.addDiscountIDs) {
			for (let i = 0; i < input.addDiscountIDs.length; i++) {
				addToServiceSubscriptionOfDiscountCache(cache, input.addDiscountIDs[i], updatedObject);
			}
		}
		if (input.removeDiscountIDs) {
			for (let i = 0; i < input.removeDiscountIDs.length; i++) {
				removeFromServiceSubscriptionOfDiscountCache(cache, input.removeDiscountIDs[i], updatedObject);
			}
		}

		if (input.addInvoiceLineItemIDs) {
			for (let i = 0; i < input.addInvoiceLineItemIDs.length; i++) {
				addToServiceSubscriptionOfInvoiceLineItemCache(cache, input.addInvoiceLineItemIDs[i], updatedObject);
			}
		}
		if (input.removeInvoiceLineItemIDs) {
			for (let i = 0; i < input.removeInvoiceLineItemIDs.length; i++) {
				removeFromServiceSubscriptionOfInvoiceLineItemCache(cache, input.removeInvoiceLineItemIDs[i], updatedObject);
			}
		}

		if (initialFormValues.serviceID && input.serviceID) {
			removeFromServiceSubscriptionsOfServiceCache(cache, initialFormValues.serviceID, updatedObject);
		}
		if (input.serviceID) {
			addToServiceSubscriptionsOfServiceCache(cache, input.serviceID, updatedObject);
		}
	};
}

function getUpdateCacheForDelete(id: ServiceSubscription["id"]) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<ServiceSubscriptionDeleteMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (!result.data?.deleteServiceSubscription) {
			return;
		}

		cache.evict({ id: cache.identify({ id, __typename: graphTypeNames.ServiceSubscription }) });
		cache.evict({ id: "ROOT_QUERY", fieldName: "serviceSubscriptionConnection" });
		cache.gc();
	};
}

function addToServiceSubscriptionsOfContractCache(
	cache: ApolloCache<any>,
	targetID: Contract["id"],
	object: Pick<ServiceSubscription, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Contract }),
		fields: {
			serviceSubscriptions: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromServiceSubscriptionsOfContractCache(
	cache: ApolloCache<any>,
	targetID: Contract["id"],
	object: Pick<ServiceSubscription, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Contract }),
		fields: {
			serviceSubscriptions: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function addToServiceSubscriptionOfDiscountCache(
	cache: ApolloCache<any>,
	targetID: Discount["id"],
	object: Pick<ServiceSubscription, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Discount }),
		fields: {
			serviceSubscription: () => objectRef,
			serviceSubscriptionID: () => object.id,
		},
	});
}

function removeFromServiceSubscriptionOfDiscountCache(
	cache: ApolloCache<any>,
	targetID: Discount["id"],
	_object: Pick<ServiceSubscription, "id">,
) {
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Discount }),
		fields: {
			serviceSubscription: () => null,
			serviceSubscriptionID: () => null,
		},
	});
}

function addToServiceSubscriptionOfInvoiceLineItemCache(
	cache: ApolloCache<any>,
	targetID: InvoiceLineItem["id"],
	object: Pick<ServiceSubscription, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.InvoiceLineItem }),
		fields: {
			serviceSubscription: () => objectRef,
			serviceSubscriptionID: () => object.id,
		},
	});
}

function removeFromServiceSubscriptionOfInvoiceLineItemCache(
	cache: ApolloCache<any>,
	targetID: InvoiceLineItem["id"],
	_object: Pick<ServiceSubscription, "id">,
) {
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.InvoiceLineItem }),
		fields: {
			serviceSubscription: () => null,
			serviceSubscriptionID: () => null,
		},
	});
}

function addToServiceSubscriptionsOfServiceCache(
	cache: ApolloCache<any>,
	targetID: Service["id"],
	object: Pick<ServiceSubscription, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Service }),
		fields: {
			serviceSubscriptions: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromServiceSubscriptionsOfServiceCache(
	cache: ApolloCache<any>,
	targetID: Service["id"],
	object: Pick<ServiceSubscription, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Service }),
		fields: {
			serviceSubscriptions: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function toCacheRef(cache: ApolloCache<any>, object: Pick<ServiceSubscription, "id">) {
	return cache.writeFragment({
		fragment: gql`
			fragment ServiceSubscriptionRef on ServiceSubscription {
				id
			}
		`,
		data: object,
	});
}
