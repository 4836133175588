import { formatDateTime, stringToLocalDate } from "@hex-insights/core";
import { newPageInfo } from "@hex-insights/router";
import { schedulePageInfo, SchedulePageRouteParams } from "../pageinfo";

export type ScheduleDayPageRouteParams = SchedulePageRouteParams & {
	date: string;
};

export const scheduleDayPageInfo = newPageInfo({
	name: "Schedule View",
	title: (date: string) => formatDateTime(stringToLocalDate(date, "day"), "D MMMM YYYY"),
	description: "View schedule for a day.",
	exact: false,
	to: (date: string) => schedulePageInfo.to + `/${formatDateTime(stringToLocalDate(date, "day"), "YYYY-MM-DD")}`,
	path: schedulePageInfo.path + "/:date(\\d{4}-\\d{2}-\\d{2})",
	parent: schedulePageInfo,
});
