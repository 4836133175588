import { ClassYear } from "../../../GraphQL";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the name field of the ClassYear model.
 */
export function name(value: ClassYear["name"]): string {
	return toString(value);
}

/**
 * Formats the yearsFromGraduation field of the ClassYear model.
 */
export function yearsFromGraduation(value: ClassYear["yearsFromGraduation"] | null): string {
	return toString(value);
}
