import { IndexForms } from "@hex-insights/app-modules";
import { CaseStyle, convertCase } from "@hex-insights/core";
import { EmailAddressOrderField, OrderDirection } from "../../GraphQL";

export type FormValues = IndexForms.OrderFormValues<EmailAddressOrderField | null>;

export const initial: FormValues = {
	field: EmailAddressOrderField.Email,
	direction: OrderDirection.Asc,
};

/**
 * Options for the "field" field in the order form of the EmailAddress model.
 */
export const fieldOptions = [
	{ label: "ID", value: null },
	{
		label: convertCase(EmailAddressOrderField.Email.toLowerCase(), CaseStyle.Title),
		value: EmailAddressOrderField.Email,
	},
];

/**
 * Array of values for the "field" field in the order form of the EmailAddress model.
 */
export const fieldValues = fieldOptions.map((e) => e.value);

/**
 * Mapping from order fields of the EmailAddress model to their corresponding order type.
 */
export const fieldTypeMapping = {
	[EmailAddressOrderField.Email]: IndexForms.OrderFieldType.String,
};

/**
 * Mapping from order fields of the EmailAddress model to their corresponding field name.
 */
export const orderFieldToModelField = {
	[EmailAddressOrderField.Email]: "email",
};

/**
 * Mapping from field names of the EmailAddress model to their corresponding order field.
 */
export const modelFieldToOrderField = {
	email: EmailAddressOrderField.Email,
};
