import { Student } from "../../../GraphQL";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the studentIDNumber field of the Student model.
 */
export function studentIDNumber(value: Student["studentIDNumber"]): string {
	return toString(value);
}

/**
 * Formats the graduatingYear field of the Student model.
 */
export function graduatingYear(value: Student["graduatingYear"] | null): string {
	return toString(value);
}

/**
 * Formats the hasPreviousSchooling field of the Student model.
 */
export function hasPreviousSchooling(value: Student["hasPreviousSchooling"]): string {
	return value ? "Yes" : "No";
}

/**
 * Formats the previousSchoolInformation field of the Student model.
 */
export function previousSchoolInformation(value: Student["previousSchoolInformation"]): string {
	return toString(value);
}

/**
 * Formats the previousSchoolLocation field of the Student model.
 */
export function previousSchoolLocation(value: Student["previousSchoolLocation"]): string {
	return toString(value);
}

/**
 * Formats the allergies field of the Student model.
 */
export function allergies(value: Student["allergies"]): string {
	return toString(value);
}

/**
 * Formats the asthma field of the Student model.
 */
export function asthma(value: Student["asthma"] | null): string {
	return toString(value);
}

/**
 * Formats the medication field of the Student model.
 */
export function medication(value: Student["medication"]): string {
	return toString(value);
}

/**
 * Formats the medicationSchedule field of the Student model.
 */
export function medicationSchedule(value: Student["medicationSchedule"]): string {
	return toString(value);
}

/**
 * Formats the dietaryRestrictions field of the Student model.
 */
export function dietaryRestrictions(value: Student["dietaryRestrictions"]): string {
	return toString(value);
}

/**
 * Formats the healthNotes field of the Student model.
 */
export function healthNotes(value: Student["healthNotes"]): string {
	return toString(value);
}

/**
 * Formats the doctorName field of the Student model.
 */
export function doctorName(value: Student["doctorName"]): string {
	return toString(value);
}

/**
 * Formats the doctorPhoneNumber field of the Student model.
 */
export function doctorPhoneNumber(value: Student["doctorPhoneNumber"]): string {
	return toString(value);
}

/**
 * Formats the emergencyContactInformation field of the Student model.
 */
export function emergencyContactInformation(value: Student["emergencyContactInformation"]): string {
	return toString(value);
}
