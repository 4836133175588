import { Column, getObjectEntries, Grid, Heading, List, Row, Section } from "@hex-insights/core";
import { InternalLink, useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import {
	Main,
	StudentCourseSectionTeacherReportCardsCourseSectionOverviewListQuery,
	Tile,
	useStudentCourseSectionTeacherReportCardsCourseSectionOverviewListQuery,
} from "@hex-insights/verita.shared";
import { reportCardsTermCourseSectionStudentPageInfo } from "../Student/pageinfo";
import {
	reportCardsTermCourseSectionIndexPageInfo,
	ReportCardsTermCourseSectionIndexPageRouteParams,
} from "./pageinfo";

type LearningObjectiveInQuery =
	StudentCourseSectionTeacherReportCardsCourseSectionOverviewListQuery["courseSection"]["course"]["learningObjectives"][0];

export function ReportCardsTermCourseSectionIndexPage() {
	useActivePageRegistration(reportCardsTermCourseSectionIndexPageInfo);

	const { courseSectionID } = useRouteParams<ReportCardsTermCourseSectionIndexPageRouteParams>();
	const { data } = useStudentCourseSectionTeacherReportCardsCourseSectionOverviewListQuery({
		variables: { courseSectionID },
	});
	const courseSection = data?.courseSection;

	const learningObjectivesCategories: Record<string, LearningObjectiveInQuery[]> = {};
	if (courseSection) {
		for (let i = 0; i < courseSection.course.learningObjectives.length; i++) {
			const objective = courseSection.course.learningObjectives[i];
			if (!learningObjectivesCategories[objective.category]) {
				learningObjectivesCategories[objective.category] = [];
			}
			learningObjectivesCategories[objective.category].push(objective);
		}
	}

	return (
		<Main>
			<Row
				justify="spaced-start"
				style={{
					height: "calc(100vh - var(--header---height) - 2 * var(--main---padding-top))",
				}}
			>
				<Tile style={{ flexGrow: 1 }}>
					<Tile.Header>
						<Heading level={1} noMargin>
							{data?.courseSection.name}
						</Heading>
					</Tile.Header>
					<Tile.Body>
						<Grid columns={4} gap="1rem">
							{courseSection?.courseSectionStudentEnrollments.map(({ student }) => (
								<StudentItem key={student.id} student={student} />
							))}
						</Grid>
					</Tile.Body>
				</Tile>

				<Tile>
					<Tile.Header>
						<Heading level={2} noMargin>
							Learning Objectives
						</Heading>
					</Tile.Header>
					<Tile.Body>
						{getObjectEntries(learningObjectivesCategories).map(([category, objectives]) => (
							<Section key={category}>
								<Section.Header>
									<Heading level={3} noMargin>
										{category}
									</Heading>
								</Section.Header>
								<Section.Body>
									<List>
										{objectives.map((e) => (
											<List.Item key={e.id}>{e.description}</List.Item>
										))}
									</List>
								</Section.Body>
							</Section>
						))}
					</Tile.Body>
				</Tile>
			</Row>
		</Main>
	);
}

type StudentInQuery =
	StudentCourseSectionTeacherReportCardsCourseSectionOverviewListQuery["courseSection"]["courseSectionStudentEnrollments"][0]["student"];

// TODO unite with StudentItem in Term Index Page

type StudentItemProps = {
	student: StudentInQuery;
};

function StudentItem({ student }: StudentItemProps) {
	const { termID, courseSectionID } = useRouteParams<ReportCardsTermCourseSectionIndexPageRouteParams>();

	const reportCard = student.reportCards.length > 0 ? student.reportCards[0] : null;
	let marksCompleted = 0;
	let totalMarks = 0;
	if (reportCard) {
		for (let i = 0; i < reportCard.learningObjectiveMarks.length; i++) {
			const mark = reportCard.learningObjectiveMarks[i];
			if (mark.value !== null) {
				marksCompleted++;
			}
			totalMarks++;
		}
	}

	const areMarksComplete = marksCompleted === totalMarks;

	return (
		<Column align="center">
			<Row justify="spaced-start">
				<span>{student.person.name}</span>
				<span style={{ color: areMarksComplete ? "var(--success-color)" : "var(--danger-color)" }}>
					{marksCompleted} / {totalMarks}
				</span>
			</Row>

			<span>
				<InternalLink to={reportCardsTermCourseSectionStudentPageInfo.to(termID, courseSectionID, student.id)}>
					{areMarksComplete ? "Review Marks" : "Complete Marks"}
				</InternalLink>
			</span>
		</Column>
	);
}
