import React from "react";
import {
	addTimeToDate,
	Button,
	Column,
	dateTrunc,
	formatDateTime,
	getISOTZOffset,
	getTimeZoneName,
	isSameTime,
	isSameTimeOrBefore,
	Row,
	toLocalDateString,
} from "@hex-insights/core";
import { FormState, FormType, requiredTextValidator, useFieldState } from "@hex-insights/forms";
import {
	EnrollmentApplicationStudentFormValues,
	EnrollmentApplicationStudentGradeLevelApplyingFor,
	serverURL,
} from "../../../../Utilities";
import { SmallCalendar } from "../../../SmallCalendar";
import styles from "./interview-meeting-time.module.css";

export type InterviewMeetingTimeProps = {
	formState: FormState<
		Pick<EnrollmentApplicationStudentFormValues.EnrollmentApplicationCreateInterview, "interviewMeetingTime">
	>;
	gradeLevelApplyingFor: EnrollmentApplicationStudentFormValues.EnrollmentApplicationCreate["gradeLevelApplyingFor"];
};

export function InterviewMeetingTime({ formState, gradeLevelApplyingFor }: InterviewMeetingTimeProps) {
	const immediateValidation = React.useMemo(() => requiredTextValidator(true), []);
	const fieldState = useFieldState<
		EnrollmentApplicationStudentFormValues.EnrollmentApplicationCreateInterview,
		EnrollmentApplicationStudentFormValues.EnrollmentApplicationCreateInterview["interviewMeetingTime"]
	>({ formState, name: "interviewMeetingTime", validationOptions: { immediateValidation } });

	const [value, setValue] = React.useState(() => {
		if (FormType.isUpdate(formState.formType) && fieldState.value) {
			return new Date(fieldState.value);
		}

		let initialDate = addTimeToDate(new Date(), [1, "day"]).toDate();
		const disabledDays = getDisabledDaysForGradeLevel(gradeLevelApplyingFor);
		while (disabledDays.has(initialDate.getDay())) {
			initialDate = addTimeToDate(initialDate, [1, "day"]).toDate();
		}
		return initialDate;
	});
	const onChange = React.useCallback((newValue: Date) => {
		setValue((prev) => (isSameTime(prev, newValue, "day") ? prev : newValue));
	}, []);

	const today = dateTrunc(new Date(), "day");
	const minDate = addTimeToDate(today, [1, "day"]).toDate();
	const maxDate = addTimeToDate(today, [6, "weeks"]).toDate();

	return (
		<Row justify="spaced-start" className={styles["interview-meeting-time"]}>
			<Column justify="spaced-start">
				<SmallCalendar
					value={value}
					onChange={onChange}
					noSecondaryArrows
					tileDisabled={getIsTileDisabled(gradeLevelApplyingFor)}
					tileClassName={styles["interview-meeting-time__calendar__tile"]}
					minDate={minDate}
					maxDate={maxDate}
					minDetail="month"
				/>
				<span style={{ fontSize: "0.9rem" }}>This meeting will take 45 minutes.</span>
			</Column>
			<Column justify="spaced-start" className={styles["interview-meeting-time__right-column"]}>
				<Column justify="spaced-start" verticalSpacing="0.25rem">
					<strong>What time works best?</strong>
					<span style={{ fontSize: "0.9rem" }}>
						<span style={{ color: "#777" }}>Time Zone:</span> {getTimeZoneName()} UTC {getISOTZOffset(value)}
					</span>
					{fieldState.value !== null && (
						<span style={{ fontSize: "0.9rem" }}>
							<span style={{ color: "#777" }}>Selected:</span>{" "}
							{formatDateTime(fieldState.value, "dddd, D MMM [at] HH:mm")}
						</span>
					)}
				</Column>
				<MeetingTimes
					key={toLocalDateString(value)}
					gradeLevelApplyingFor={gradeLevelApplyingFor}
					date={value}
					value={fieldState.value}
					setValue={fieldState.setValue}
				/>
			</Column>
		</Row>
	);
}

const calendarDisabledDays = {
	earlyYears: new Set([0, 6]),
	primarySchool: new Set([0, 1, 6]),
};

function getDisabledDaysForGradeLevel(
	gradeLevelApplyingFor: EnrollmentApplicationStudentFormValues.EnrollmentApplicationCreate["gradeLevelApplyingFor"],
) {
	switch (gradeLevelApplyingFor) {
		case EnrollmentApplicationStudentGradeLevelApplyingFor.Nursery:
		case EnrollmentApplicationStudentGradeLevelApplyingFor.Reception:
			return calendarDisabledDays.earlyYears;
		default:
			return calendarDisabledDays.primarySchool;
	}
}

function getIsTileDisabled(
	gradeLevelApplyingFor: EnrollmentApplicationStudentFormValues.EnrollmentApplicationCreate["gradeLevelApplyingFor"],
) {
	switch (gradeLevelApplyingFor) {
		case EnrollmentApplicationStudentGradeLevelApplyingFor.Nursery:
		case EnrollmentApplicationStudentGradeLevelApplyingFor.Reception:
			return isEarlyYearsTileDisabled;
		default:
			return isPrimarySchoolTileDisabled;
	}
}

function isEarlyYearsTileDisabled({ date }: { date: Date }) {
	return calendarDisabledDays.earlyYears.has(date.getDay()) || isSameTimeOrBefore(date, new Date(), "day");
}

function isPrimarySchoolTileDisabled({ date }: { date: Date }) {
	return calendarDisabledDays.primarySchool.has(date.getDay()) || isSameTimeOrBefore(date, new Date(), "day");
}

type MeetingTimesProps = {
	gradeLevelApplyingFor: EnrollmentApplicationStudentFormValues.EnrollmentApplicationCreate["gradeLevelApplyingFor"];
	date: Date;
	value: EnrollmentApplicationStudentFormValues.EnrollmentApplicationCreateInterview["interviewMeetingTime"];
	setValue: (time: string) => void;
};

function MeetingTimes({ gradeLevelApplyingFor, date, value, setValue }: MeetingTimesProps) {
	const [isLoading, setIsLoading] = React.useState(false);
	const [times, setTimes] = React.useState<string[]>([]);

	React.useEffect(() => {
		(async () => {
			setIsLoading(true);
			const dateISO = toLocalDateString(date);
			const response = await fetch(
				serverURL(`/admissions/available-meeting-times?gradeLevelApplyingFor=${gradeLevelApplyingFor}&date=${dateISO}`),
			);
			if (response.ok) {
				const data = await response.json();
				setTimes(data.data.times);
			}
			setIsLoading(false);
		})();
	}, [date, gradeLevelApplyingFor]);

	if (isLoading) {
		return (
			<Column justify="center" align="center" className={styles["interview-meeting-time__times__no-results"]}>
				<span>Checking for available times...</span>
			</Column>
		);
	}
	if (times.length === 0) {
		return (
			<Column justify="center" align="center" className={styles["interview-meeting-time__times__no-results"]}>
				<span>No times available on this day.</span>
			</Column>
		);
	}
	return (
		<Column justify="spaced-start" verticalSpacing="0.5rem" className={styles["interview-meeting-time__times"]}>
			{times.map((time) => (
				<Button
					key={time}
					variant={time === value ? "primary" : "tertiary"}
					onClick={() => setValue(time)}
					style={{ width: "100%" }}
				>
					{formatDateTime(time, "HH:mm")}
				</Button>
			))}
		</Column>
	);
}
