import React from "react";
import {
	addTimeToDate,
	ClassNameProps,
	Column,
	Conditional,
	dateTrunc,
	Else,
	formatDateTime,
	Heading,
	Icon,
	If,
	mappedArray,
	Row,
	Section,
	StyleProps,
} from "@hex-insights/core";
import { InternalLink } from "@hex-insights/router";
import {
	CourseSectionFormat,
	CourseSectionPeriodFormat,
	CourseSectionPeriodTeacherDailyScheduleWithLessonPlansQuery,
	HorizontalScrollingContainer,
	TextAreaFieldDisplay,
	useCourseSectionPeriodProgress,
} from "@hex-insights/verita.shared";
import { useCurrentTeacherScheduleForDateWithLessonPlansQuery } from "../../../../Utilities";
import { lessonPlansWeekCourseSectionDayPageInfo } from "../../../LessonPlans/Week/CourseSection/Day/pageinfo";
import { scheduleDayCoursePeriodPageInfo } from "../../../Schedule/Day/CoursePeriod/pageinfo";
import { schedulePageInfo } from "../../../Schedule/pageinfo";
import { Attendance } from "./Attendance";
import styles from "./courses.module.css";

export type CoursesProps = Partial<ClassNameProps & StyleProps>;

export function Courses({ className, style }: CoursesProps) {
	const date = new Date();
	const { loading, data } = useCurrentTeacherScheduleForDateWithLessonPlansQuery(date);

	return (
		<Section className={className} style={style}>
			<Section.Header>
				<Row justify="space-between" align="center">
					<Row justify="spaced-start" horizontalSpacing="1.25rem" align="center">
						<Heading level={2} noMargin style={{ width: "fit-content" }}>
							My Courses
						</Heading>

						<InternalLink to={schedulePageInfo.to} className={styles["schedule-link"]}>
							View Schedule <Icon.ArrowRight className={styles["schedule-link__icon"]} size="0.75rem" />
						</InternalLink>
					</Row>

					<Attendance />
				</Row>
			</Section.Header>

			<Section.Body style={{ padding: 0 }}>
				<HorizontalScrollingContainer style={{ width: "100%" }}>
					<Row justify="spaced-start" style={{ width: "100%" }}>
						<Row justify="spaced-start" style={{ padding: "0.75rem 1rem 1rem 0.25rem" }}>
							<Conditional>
								<If condition={loading}>
									{mappedArray(6, (i) => (
										<CourseItemLoading key={i} />
									))}
								</If>
								<Else>
									{data?.courseSectionPeriodConnection.edges.map((e) => (
										<CourseItem key={e.node.id} period={e.node} />
									))}
								</Else>
							</Conditional>
						</Row>
					</Row>
				</HorizontalScrollingContainer>
			</Section.Body>
		</Section>
	);
}

type CourseItemProps = {
	period: CourseSectionPeriodTeacherDailyScheduleWithLessonPlansQuery["courseSectionPeriodConnection"]["edges"][0]["node"];
};

function CourseItem({ period }: CourseItemProps) {
	const date = new Date();

	const { isCurrentlyActive } = useCourseSectionPeriodProgress(period);

	const hasLessonPlan = period.courseSection.lessonPlans.length > 0;

	const courseName = CourseSectionFormat.name(period.courseSection);

	return (
		<Column
			align="center"
			className={styles["course-item"]}
			style={
				{
					"--course-theme-color": CourseSectionFormat.color(period.courseSection),
				} as React.CSSProperties
			}
		>
			<div className={styles["course-item__title-placeholder"]}>{courseName}</div>
			<div className={styles["course-item__title"]}>{courseName}</div>
			<div className={styles["course-item__lesson-description"]}>
				<TextAreaFieldDisplay
					value=""
					formattedValue={hasLessonPlan ? period.courseSection.lessonPlans[0].lessonDescription : "No Lesson Plan"}
				/>
				<div className={styles["course-item__lesson-description__overflow-fade"]}></div>
			</div>
			<Row
				justify={hasLessonPlan ? "space-between" : "flex-end"}
				align="center"
				className={styles["course-item__bottom-content"]}
			>
				<If condition={hasLessonPlan}>
					<Column justify="center" style={{ position: "relative", width: "fit-content", height: "fit-content" }}>
						<Icon.BookOpen />
					</Column>
				</If>

				<span className={styles["course-item__time"]}>
					{isCurrentlyActive ? "Now" : CourseSectionPeriodFormat.Fields.startTimeMinutes(period.startTimeMinutes)}
				</span>
			</Row>
			<If condition={!hasLessonPlan}>
				<InternalLink
					to={lessonPlansWeekCourseSectionDayPageInfo.to(
						formatDateTime(addTimeToDate(dateTrunc(date, "week"), [1, "day"]), "YYYY-MM-DD"),
						period.courseSection.id,
						date.getDay(),
					)}
					className={styles["course-item__action-link"] + " " + styles["course-item__lesson-plan-link"]}
				>
					Add Plan <Icon.ArrowRight className={styles["course-item__action-link__icon"]} size="0.6rem" />
				</InternalLink>
			</If>
			<InternalLink
				to={scheduleDayCoursePeriodPageInfo.to(formatDateTime(date, "YYYY-MM-DD"), period.id)}
				className={styles["course-item__action-link"] + " " + styles["course-item__course-link"]}
			>
				View Course <Icon.ArrowRight className={styles["course-item__action-link__icon"]} size="0.6rem" />
			</InternalLink>
		</Column>
	);
}

function CourseItemLoading() {
	return (
		<div
			className={styles["course-item"]}
			style={
				{
					"--course-theme-color": "#eee",
				} as React.CSSProperties
			}
		></div>
	);
}
