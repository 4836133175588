import React from "react";
import { ApolloCache, FetchResult, gql } from "@apollo/client";
import {
	Address,
	EmailAddress,
	Employee,
	graphTypeNames,
	Parent,
	Person,
	PersonCreateInput,
	PersonCreateMutation,
	PersonDeleteMutation,
	PersonDetailDocument,
	PersonDetailQuery,
	PersonDetailQueryVariables,
	PersonUpdateInput,
	PersonUpdateMutation,
	PhoneNumber,
	Student,
	usePersonCreateMutation,
	usePersonDeleteMutation,
	usePersonUpdateMutation,
	User,
} from "../../GraphQL";
import { PersonFormConversion } from "../ModelFormConversion";
import { PersonFormValues } from "../ModelFormValues";

/**
 * Returns a `create` function for the Person model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the Person model.
 */
export function useCreate() {
	const [createPerson] = usePersonCreateMutation();

	return React.useCallback(
		async (formValues: PersonFormValues.Create) => {
			const input = PersonFormConversion.toGQLCreateInput(formValues);
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createPerson({ variables: { input }, update: updateCache });

			return { data: data?.createPerson ?? null, errors: errors ?? null };
		},
		[createPerson],
	);
}

/**
 * Returns an `update` function for the Person model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the Person model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: Person["id"]) {
	const [updatePerson] = usePersonUpdateMutation();

	return React.useCallback(
		async (changedFormValues: Partial<PersonFormValues.Detail>, initialFormValues: PersonFormValues.Detail) => {
			const input = PersonFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

			const { data, errors } = await updatePerson({ variables: { id, input }, update: updateCache });

			return { data: data?.updatePerson ?? null, errors: errors ?? null };
		},
		[updatePerson, id],
	);
}

/**
 * Returns a `del` function for the Person model.
 *
 * @param id The ID of the instance to delete.
 */
export function useDelete(id: Person["id"]) {
	const [deletePerson] = usePersonDeleteMutation();

	return React.useCallback(async () => {
		const updateCache = getUpdateCacheForDelete(id);

		const { data, errors } = await deletePerson({ variables: { id }, update: updateCache });

		return { data: data?.deletePerson ?? false, errors: errors ?? null };
	}, [deletePerson, id]);
}

function getUpdateCacheForCreate(input: PersonCreateInput) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<PersonCreateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const createdObject = result.data.createPerson;

		cache.writeQuery<PersonDetailQuery, PersonDetailQueryVariables>({
			query: PersonDetailDocument,
			data: { person: createdObject },
			variables: { id: createdObject.id },
		});

		if (input.addressIDs) {
			for (let i = 0; i < input.addressIDs.length; i++) {
				addToPeopleOfAddressCache(cache, input.addressIDs[i], createdObject);
			}
		}

		if (input.emailAddressIDs) {
			for (let i = 0; i < input.emailAddressIDs.length; i++) {
				addToPersonOfEmailAddressCache(cache, input.emailAddressIDs[i], createdObject);
			}
		}

		if (input.employeeID) {
			addToPersonOfEmployeeCache(cache, input.employeeID, createdObject);
		}

		if (input.parentID) {
			addToPersonOfParentCache(cache, input.parentID, createdObject);
		}

		if (input.phoneNumberIDs) {
			for (let i = 0; i < input.phoneNumberIDs.length; i++) {
				addToPersonOfPhoneNumberCache(cache, input.phoneNumberIDs[i], createdObject);
			}
		}

		if (input.studentID) {
			addToPersonOfStudentCache(cache, input.studentID, createdObject);
		}

		if (input.userID) {
			addToPersonOfUserCache(cache, input.userID, createdObject);
		}

		cache.evict({ id: "ROOT_QUERY", fieldName: "personConnection" });
	};
}

export function getUpdateCacheForUpdate(input: PersonUpdateInput, initialFormValues: Partial<PersonFormValues.Detail>) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<PersonUpdateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const updatedObject = result.data.updatePerson;

		if (input.addAddressIDs) {
			for (let i = 0; i < input.addAddressIDs.length; i++) {
				addToPeopleOfAddressCache(cache, input.addAddressIDs[i], updatedObject);
			}
		}
		if (input.removeAddressIDs) {
			for (let i = 0; i < input.removeAddressIDs.length; i++) {
				removeFromPeopleOfAddressCache(cache, input.removeAddressIDs[i], updatedObject);
			}
		}

		if (input.addEmailAddressIDs) {
			for (let i = 0; i < input.addEmailAddressIDs.length; i++) {
				addToPersonOfEmailAddressCache(cache, input.addEmailAddressIDs[i], updatedObject);
			}
		}
		if (input.removeEmailAddressIDs) {
			for (let i = 0; i < input.removeEmailAddressIDs.length; i++) {
				removeFromPersonOfEmailAddressCache(cache, input.removeEmailAddressIDs[i], updatedObject);
			}
		}

		if (initialFormValues.employeeID && (input.employeeID || input.clearEmployee)) {
			removeFromPersonOfEmployeeCache(cache, initialFormValues.employeeID, updatedObject);
		}
		if (input.employeeID) {
			addToPersonOfEmployeeCache(cache, input.employeeID, updatedObject);
		}

		if (initialFormValues.parentID && (input.parentID || input.clearParent)) {
			removeFromPersonOfParentCache(cache, initialFormValues.parentID, updatedObject);
		}
		if (input.parentID) {
			addToPersonOfParentCache(cache, input.parentID, updatedObject);
		}

		if (input.addPhoneNumberIDs) {
			for (let i = 0; i < input.addPhoneNumberIDs.length; i++) {
				addToPersonOfPhoneNumberCache(cache, input.addPhoneNumberIDs[i], updatedObject);
			}
		}
		if (input.removePhoneNumberIDs) {
			for (let i = 0; i < input.removePhoneNumberIDs.length; i++) {
				removeFromPersonOfPhoneNumberCache(cache, input.removePhoneNumberIDs[i], updatedObject);
			}
		}

		if (initialFormValues.studentID && (input.studentID || input.clearStudent)) {
			removeFromPersonOfStudentCache(cache, initialFormValues.studentID, updatedObject);
		}
		if (input.studentID) {
			addToPersonOfStudentCache(cache, input.studentID, updatedObject);
		}

		if (initialFormValues.userID && (input.userID || input.clearUser)) {
			removeFromPersonOfUserCache(cache, initialFormValues.userID, updatedObject);
		}
		if (input.userID) {
			addToPersonOfUserCache(cache, input.userID, updatedObject);
		}
	};
}

function getUpdateCacheForDelete(id: Person["id"]) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<PersonDeleteMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (!result.data?.deletePerson) {
			return;
		}

		cache.evict({ id: cache.identify({ id, __typename: graphTypeNames.Person }) });
		cache.evict({ id: "ROOT_QUERY", fieldName: "personConnection" });
		cache.gc();
	};
}

function addToPeopleOfAddressCache(cache: ApolloCache<any>, targetID: Address["id"], object: Pick<Person, "id">) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Address }),
		fields: {
			people: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromPeopleOfAddressCache(cache: ApolloCache<any>, targetID: Address["id"], object: Pick<Person, "id">) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Address }),
		fields: {
			people: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function addToPersonOfEmailAddressCache(
	cache: ApolloCache<any>,
	targetID: EmailAddress["id"],
	object: Pick<Person, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.EmailAddress }),
		fields: {
			person: () => objectRef,
			personID: () => object.id,
		},
	});
}

function removeFromPersonOfEmailAddressCache(
	cache: ApolloCache<any>,
	targetID: EmailAddress["id"],
	_object: Pick<Person, "id">,
) {
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.EmailAddress }),
		fields: {
			person: () => null,
			personID: () => null,
		},
	});
}

function addToPersonOfEmployeeCache(cache: ApolloCache<any>, targetID: Employee["id"], object: Pick<Person, "id">) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Employee }),
		fields: {
			person: () => objectRef,
			personID: () => object.id,
		},
	});
}

function removeFromPersonOfEmployeeCache(
	cache: ApolloCache<any>,
	targetID: Employee["id"],
	_object: Pick<Person, "id">,
) {
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Employee }),
		fields: {
			person: () => null,
			personID: () => null,
		},
	});
}

function addToPersonOfParentCache(cache: ApolloCache<any>, targetID: Parent["id"], object: Pick<Person, "id">) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Parent }),
		fields: {
			person: () => objectRef,
			personID: () => object.id,
		},
	});
}

function removeFromPersonOfParentCache(cache: ApolloCache<any>, targetID: Parent["id"], _object: Pick<Person, "id">) {
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Parent }),
		fields: {
			person: () => null,
			personID: () => null,
		},
	});
}

function addToPersonOfPhoneNumberCache(
	cache: ApolloCache<any>,
	targetID: PhoneNumber["id"],
	object: Pick<Person, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.PhoneNumber }),
		fields: {
			person: () => objectRef,
			personID: () => object.id,
		},
	});
}

function removeFromPersonOfPhoneNumberCache(
	cache: ApolloCache<any>,
	targetID: PhoneNumber["id"],
	_object: Pick<Person, "id">,
) {
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.PhoneNumber }),
		fields: {
			person: () => null,
			personID: () => null,
		},
	});
}

function addToPersonOfStudentCache(cache: ApolloCache<any>, targetID: Student["id"], object: Pick<Person, "id">) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Student }),
		fields: {
			person: () => objectRef,
			personID: () => object.id,
		},
	});
}

function removeFromPersonOfStudentCache(cache: ApolloCache<any>, targetID: Student["id"], _object: Pick<Person, "id">) {
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Student }),
		fields: {
			person: () => null,
			personID: () => null,
		},
	});
}

function addToPersonOfUserCache(cache: ApolloCache<any>, targetID: User["id"], object: Pick<Person, "id">) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.User }),
		fields: {
			person: () => objectRef,
		},
	});
}

function removeFromPersonOfUserCache(cache: ApolloCache<any>, targetID: User["id"], _object: Pick<Person, "id">) {
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.User }),
		fields: {
			person: () => null,
		},
	});
}

function toCacheRef(cache: ApolloCache<any>, object: Pick<Person, "id">) {
	return cache.writeFragment({
		fragment: gql`
			fragment PersonRef on Person {
				id
			}
		`,
		data: object,
	});
}
