import React from "react";
import { Heading, Modal, ModalProps, RequiredKeys } from "@hex-insights/core";
import { anyFieldChanged } from "@hex-insights/forms";
import { Student, StudentDocumentFormState, StudentDocumentFormValues, StudentDocumentMutation } from "../../Utilities";
import { StudentDocumentForm } from "../Forms";

export type StudentDocumentCreateModalProps = {
	studentID?: Student["id"];
} & RequiredKeys<Pick<ModalProps, "ifRef" | "onClose">, "onClose">;

export function StudentDocumentCreateModal({ studentID, ifRef, onClose }: StudentDocumentCreateModalProps) {
	const initialFormValues = React.useMemo(() => ({ studentID: studentID ?? null }), [studentID]);
	const formState = StudentDocumentFormState.useCreateFormState(initialFormValues);

	const create = StudentDocumentMutation.useCreate();
	const applyCreate = React.useCallback(
		async (formValues: StudentDocumentFormValues.Create) => {
			const { errors } = await create(formValues);
			return errors;
		},
		[create],
	);

	return (
		<Modal
			ifRef={ifRef}
			onClose={onClose}
			confirmOnClose={anyFieldChanged(formState)}
			style={{ width: "fit-content", minWidth: "none" }}
		>
			<Modal.Header>
				<Heading level={2} noMargin>
					Upload Document
				</Heading>
			</Modal.Header>
			<Modal.Body>
				<StudentDocumentForm.Create formState={formState} applyCreate={applyCreate} onSuccess={onClose} />
			</Modal.Body>
		</Modal>
	);
}
