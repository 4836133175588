import { makeClassName, Section, SectionProps, SectionSubcomponentProps } from "@hex-insights/core";
import styles from "./styles.module.css";

export type TileProps = SectionProps;

export const Tile = ({ className, ...props }: TileProps) => {
	return <Section className={makeClassName(styles["tile"], className)} {...props} />;
};

Tile.Header = function ({
	isPrimary = false,
	className,
	...props
}: SectionSubcomponentProps & { isPrimary?: boolean }) {
	return (
		<Section.Header
			className={makeClassName(styles["tile__header"], isPrimary ? styles["tile__header--primary"] : "", className)}
			{...props}
		/>
	);
};

Tile.Body = function (props: SectionSubcomponentProps) {
	return <Section.Body {...props} />;
};

Tile.Footer = function ({ className, ...props }: SectionSubcomponentProps) {
	return <Section.Footer className={makeClassName(styles["tile__footer"], className)} {...props} />;
};
