import { Models } from "@hex-insights/app-modules";
import { DistributiveOmit } from "@hex-insights/core";
import { Relationship, RelationshipFormat, RelationshipNameFragment } from "../../../../Utilities";

type ModelForLink = Pick<Relationship, "id"> & RelationshipNameFragment;

export type RelationshipLinkProps = DistributiveOmit<Models.LinkProps<ModelForLink>, "pageInfoKey" | "format">;

/**
 * Renders a link to the given Relationship instance.
 */
export function RelationshipLink(props: RelationshipLinkProps) {
	return <Models.Link<ModelForLink> pageInfoKey="Relationship" format={RelationshipFormat.name} {...props} />;
}
