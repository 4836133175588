import { InvoiceLineItem } from "../../../GraphQL";
import { formatPrice } from "../../money";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the lineItemNumber field of the InvoiceLineItem model.
 */
export function lineItemNumber(value: InvoiceLineItem["lineItemNumber"] | null): string {
	return toString(value);
}

/**
 * Formats the amount field of the InvoiceLineItem model.
 */
export function amount(value: InvoiceLineItem["amount"] | null): string {
	return formatPrice(value);
}

/**
 * Formats the description field of the InvoiceLineItem model.
 */
export function description(value: InvoiceLineItem["description"]): string {
	return toString(value);
}
