import {
	LoginRecord,
	MFAFactor,
	MFAInvalidOTPAttempt,
	PermissionGroup,
	Person,
	Session,
	TrustedDevice,
	User,
	UserGroup,
	UserStatus,
} from "../../GraphQL";

export type Base = {
	email: User["email"];
	permissionGroupIDs: PermissionGroup["id"][];
	personID: Person["id"] | null;
	userGroupIDs: UserGroup["id"][];
};

export type Create = Base;

export type Detail = Base & {
	status: User["status"] | null;
	passwordUpdatedAt: User["passwordUpdatedAt"] | null;
	loginRecordIDs: LoginRecord["id"][];
	mfaFactorID: MFAFactor["id"] | null;
	mfaInvalidOTPAttemptIDs: MFAInvalidOTPAttempt["id"][];
	sessionIDs: Session["id"][];
	trustedDeviceIDs: TrustedDevice["id"][];
};

export const initialCreate: Create = {
	email: "",
	personID: null,
	permissionGroupIDs: [],
	userGroupIDs: [],
};

export const statusOptions = [
	{ value: UserStatus.Active },
	{ value: UserStatus.Created },
	{ value: UserStatus.Disabled },
	{ value: UserStatus.Invited },
];
