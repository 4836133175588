import { IndexForms } from "@hex-insights/app-modules";
import { CaseStyle, convertCase } from "@hex-insights/core";
import { NoteCommentOrderField, OrderDirection } from "../../GraphQL";

export type FormValues = IndexForms.OrderFormValues<NoteCommentOrderField | null>;

export const initial: FormValues = {
	field: NoteCommentOrderField.CreatedAt,
	direction: OrderDirection.Asc,
};

/**
 * Options for the "field" field in the order form of the NoteComment model.
 */
export const fieldOptions = [
	{ label: "ID", value: null },
	{
		label: convertCase(NoteCommentOrderField.CreatedAt.toLowerCase(), CaseStyle.Title),
		value: NoteCommentOrderField.CreatedAt,
	},
];

/**
 * Array of values for the "field" field in the order form of the NoteComment model.
 */
export const fieldValues = fieldOptions.map((e) => e.value);

/**
 * Mapping from order fields of the NoteComment model to their corresponding order type.
 */
export const fieldTypeMapping = {
	[NoteCommentOrderField.CreatedAt]: IndexForms.OrderFieldType.Time,
};

/**
 * Mapping from order fields of the NoteComment model to their corresponding field name.
 */
export const orderFieldToModelField = {
	[NoteCommentOrderField.CreatedAt]: "createdAt",
};

/**
 * Mapping from field names of the NoteComment model to their corresponding order field.
 */
export const modelFieldToOrderField = {
	createdAt: NoteCommentOrderField.CreatedAt,
};
