import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import {
	asArray,
	Button,
	Column,
	Conditional,
	Else,
	englishCommaJoinStrings,
	filterUnique,
	Grid,
	Heading,
	If,
	Modal,
	ModalProps,
	Paragraph,
	Row,
	Section,
	shallowCopyArray,
	shuffle,
	StyleProps,
	toGrammaticalNumber,
	toLocalDateString,
} from "@hex-insights/core";
import {
	BigDateDisplay,
	DailyReport,
	Employee,
	EmployeeContext,
	getCalendarEventFiltersForUserForDateRange,
	HomeRoom,
	HomeRoomFilterInput,
	HomeRoomSection,
	HomeRoomSectionFilterInput,
	PersonIcon,
	StudentFilterFormState,
	StudentFilterFormUtils,
	StudentFilterInput,
	StudentLink,
	StudentListModal,
	StudentListQuery,
	useBirthdaysQuery,
	useCalendarEventIndexQuery,
	useCurrentUser,
	useStudentListQuery,
} from "@hex-insights/verita.shared";
import { useCurrentTeacherScheduleForDateWithLessonPlansQuery } from "../../../../Utilities";
import { Courses } from "./Courses";

export type MainTileProps = Partial<StyleProps>;

export function MainTile({ style }: MainTileProps) {
	const { employee } = React.useContext(EmployeeContext);

	return (
		<Section style={style}>
			<Section.Body style={{ padding: "0.5rem 0 0" }}>
				<Column justify="spaced-start" style={{ height: "100%" }}>
					<Row justify="spaced-start" align="center" style={{ minHeight: 0 }}>
						<BigDateDisplay />
						<DailyReport teacherID={employee.id} noAttendance style={{ flexGrow: 1 }} />
					</Row>

					<Row justify="space-between" align="center" style={{ flexGrow: 1 }}>
						<Summary />
						<Students style={{ height: "fit-content" }} />
					</Row>

					<Courses />
				</Column>
			</Section.Body>
		</Section>
	);
}

function Summary() {
	const today = new Date();
	const { loading: loadingSchedule, data: scheduleData } = useCurrentTeacherScheduleForDateWithLessonPlansQuery(today);
	const numCourses = scheduleData?.courseSectionPeriodConnection.edges.length ?? 0;
	const numCoursesWithoutLessonPlan =
		scheduleData?.courseSectionPeriodConnection.edges.filter((e) => e.node.courseSection.lessonPlans.length === 0)
			.length ?? 0;

	const { user } = useCurrentUser();
	const { data: eventsData } = useCalendarEventIndexQuery({
		variables: { filters: getCalendarEventFiltersForUserForDateRange(user.id, today, today) },
	});
	const numEvents = eventsData?.calendarEventConnection.edges.length ?? 0;

	const { employee } = React.useContext(EmployeeContext);
	const { data: birthdaysData } = useBirthdaysQuery({
		variables: {
			dates: [toLocalDateString(new Date())],
			filters: [
				{
					hasStudent: true,
					student: getFiltersForStudentsOfTeacher(employee.id),
				},
			],
		},
	});
	const numBirthdays = birthdaysData?.personConnectionByBirthday.edges.length ?? 0;

	return (
		<Section>
			<Section.Header>
				<Heading level={2} noMargin>
					Today
				</Heading>
			</Section.Header>
			<Section.Body style={{ fontSize: "1.25rem" }}>
				<Paragraph>
					<Conditional>
						<If condition={loadingSchedule}>Loading...</If>
						<Else>
							<Conditional>
								<If condition={numCourses > 0}>Today you have {toGrammaticalNumber("course", numCourses, true)}.</If>
								<Else>Today you don't have any courses.</Else>
							</Conditional>

							<If condition={numEvents > 0}>
								{" "}
								There {toGrammaticalNumber("is", numEvents)} {toGrammaticalNumber("event", numEvents, true)} happening
								on campus.
							</If>
						</Else>
					</Conditional>
				</Paragraph>

				<If condition={numCourses > 0}>
					<Paragraph>
						<Conditional>
							<If condition={numCoursesWithoutLessonPlan === 0}>Your lesson plans are all ready to go for the day.</If>
							<Else>
								{numCoursesWithoutLessonPlan} of your courses {toGrammaticalNumber("is", numCoursesWithoutLessonPlan)}{" "}
								in need of a lesson plan.
							</Else>
						</Conditional>
					</Paragraph>
				</If>

				<If condition={numBirthdays > 0}>
					<Paragraph>
						Today is{" "}
						{englishCommaJoinStrings(
							birthdaysData?.personConnectionByBirthday.edges.map((e) => e.node.name + "'s") ?? [],
						)}{" "}
						birthday!
					</Paragraph>
				</If>
			</Section.Body>
		</Section>
	);
}

function Students({ style }: Partial<StyleProps>) {
	const { employee } = React.useContext(EmployeeContext);
	const { loading, data } = useStudentListQuery({
		variables: {
			filters: getFiltersForStudentsOfTeacher(employee.id),
		},
	});
	const { homeRoomIDs, homeRoomSectionIDs } = React.useMemo(() => {
		const homeRoomIDs = [];
		const homeRoomSectionIDs = [];

		if (data) {
			for (let i = 0; i < data.studentConnection.edges.length; i++) {
				const student = data.studentConnection.edges[i].node;
				for (let j = 0; j < student.homeRoomSectionStudentEnrollments.length; j++) {
					const homeRoomSection = student.homeRoomSectionStudentEnrollments[j].homeRoomSection;
					homeRoomIDs.push(homeRoomSection.homeRoom.id);
					homeRoomSectionIDs.push(homeRoomSection.id);
				}
			}
		}

		return { homeRoomIDs: filterUnique(homeRoomIDs), homeRoomSectionIDs: filterUnique(homeRoomSectionIDs) };
	}, [data]);
	const previewEdgesRef = React.useRef<StudentListQuery["studentConnection"]["edges"]>([]);
	if (previewEdgesRef.current.length === 0 && data) {
		previewEdgesRef.current = shuffle(data.studentConnection.edges);
	}

	const { isModalOpen, toggleIsModalOpen } = Modal.useToggle(false);

	return (
		<Column
			style={{
				flexShrink: 0,
				backgroundColor: "#f2f9ff",
				padding: "0.5rem 1rem 0.25rem",
				borderRadius: "2.5rem",
				...style,
			}}
		>
			<Button onClick={toggleIsModalOpen} style={{ padding: 0, border: 0 }}>
				<Heading level={2} noMargin>
					My Students
				</Heading>
			</Button>

			<Column justify="spaced-start" align="center">
				<div>
					<Grid columns={4} gap="0.5rem">
						{previewEdgesRef.current.slice(0, 8).map((e) => (
							<StudentLink instance={e.node} key={e.node.id} className="link--no-text-decoration">
								<PersonIcon person={e.node.person} studentID={e.node.id} withTooltipPreview imageSize="3rem" />
							</StudentLink>
						))}
					</Grid>
				</div>

				<Button variant="link" size="small" onClick={toggleIsModalOpen} disabled={loading} style={{ padding: 0 }}>
					View Students
				</Button>
			</Column>

			<Modal.If condition={isModalOpen}>
				<StudentsModal homeRoomIDs={homeRoomIDs} homeRoomSectionIDs={homeRoomSectionIDs} onClose={toggleIsModalOpen} />
			</Modal.If>
		</Column>
	);
}

function getFiltersForStudentsOfTeacher(
	teacherID: Employee["id"],
	otherFilterInputs?: StudentFilterInput[],
): StudentFilterInput[] {
	const today = toLocalDateString(new Date());

	if (!otherFilterInputs) {
		return [
			{
				courseSectionStudentEnrollments: [
					{
						courseSection: [
							{
								term: [{ startDateLTE: today, endDateGTE: today }],
								courseSectionTeacherEnrollments: [{ teacherIDEQ: teacherID }],
							},
						],
					},
				],
			},
			{
				homeRoomSectionStudentEnrollments: [
					{
						homeRoomSection: [
							{
								term: [{ startDateLTE: today, endDateGTE: today }],
								homeRoomSectionTeacherEnrollments: [{ teacherIDEQ: teacherID }],
							},
						],
					},
				],
			},
		];
	}

	const filterInputs = shallowCopyArray(otherFilterInputs);
	const originalLength = filterInputs.length;
	for (let i = 0; i < originalLength; i++) {
		filterInputs.push({
			...filterInputs[i],
			courseSectionStudentEnrollments:
				(filterInputs[i].courseSectionStudentEnrollments?.length ?? 0) > 0
					? filterInputs[i].courseSectionStudentEnrollments?.map((enrollmentFilter) => ({
							...enrollmentFilter,
							courseSection:
								(enrollmentFilter.courseSection?.length ?? 0) > 0
									? enrollmentFilter.courseSection!.map((courseSectionFilter) => ({
											...courseSectionFilter,
											term: [{ startDateLTE: today, endDateGTE: today }],
											courseSectionTeacherEnrollments: [{ teacherIDEQ: teacherID }],
									  }))
									: [
											{
												term: [{ startDateLTE: today, endDateGTE: today }],
												courseSectionTeacherEnrollments: [{ teacherIDEQ: teacherID }],
											},
									  ],
					  }))
					: [
							{
								courseSection: [
									{
										term: [{ startDateLTE: today, endDateGTE: today }],
										courseSectionTeacherEnrollments: [{ teacherIDEQ: teacherID }],
									},
								],
							},
					  ],
		});
		filterInputs[i] = {
			...filterInputs[i],
			homeRoomSectionStudentEnrollments:
				(filterInputs[i].homeRoomSectionStudentEnrollments?.length ?? 0) >= 1
					? filterInputs[i].homeRoomSectionStudentEnrollments?.map((enrollmentFilter) => ({
							...enrollmentFilter,
							homeRoomSection:
								(enrollmentFilter.homeRoomSection?.length ?? 0) > 0
									? enrollmentFilter.homeRoomSection!.map((homeRoomSectionFilter) => ({
											...homeRoomSectionFilter,
											term: [{ startDateLTE: today, endDateGTE: today }],
											homeRoomSectionTeacherEnrollments: [{ teacherIDEQ: teacherID }],
									  }))
									: [
											{
												term: [{ startDateLTE: today, endDateGTE: today }],
												homeRoomSectionTeacherEnrollments: [{ teacherIDEQ: teacherID }],
											},
									  ],
					  }))
					: [
							{
								homeRoomSection: [
									{
										term: [{ startDateLTE: today, endDateGTE: today }],
										homeRoomSectionTeacherEnrollments: [{ teacherIDEQ: teacherID }],
									},
								],
							},
					  ],
		};
	}
	return filterInputs;
}

type StudentsModalProps = {
	homeRoomIDs: HomeRoom["id"][];
	homeRoomSectionIDs: HomeRoomSection["id"][];
} & Pick<ModalProps, "ifRef" | "onClose">;

function StudentsModal({ homeRoomIDs, homeRoomSectionIDs, ...props }: StudentsModalProps) {
	const filterFormState = StudentFilterFormState.useFormStateWithoutQueryStateSync();
	const filterInputs = IndexForms.useFilterInput(filterFormState.formValues, StudentFilterFormUtils.toFilterInputs);

	const { employee } = React.useContext(EmployeeContext);
	const { loading, data } = useStudentListQuery({
		variables: {
			filters: getFiltersForStudentsOfTeacher(employee.id, asArray(filterInputs)),
		},
	});

	const homeRoomFilterInputs = React.useMemo<HomeRoomFilterInput>(() => ({ idIn: homeRoomIDs }), [homeRoomIDs]);
	const homeRoomSectionFilterInputs = React.useMemo<HomeRoomSectionFilterInput>(
		() => ({ idIn: homeRoomSectionIDs }),
		[homeRoomSectionIDs],
	);

	return (
		<StudentListModal
			title="My Students"
			filterFormState={filterFormState}
			isLoading={loading}
			edges={data?.studentConnection.edges}
			noCampusFilters
			noHomeRoomFilters={homeRoomIDs.length < 2}
			homeRoomFilterInputs={homeRoomFilterInputs}
			homeRoomSectionFilterInputs={homeRoomSectionFilterInputs}
			{...props}
		/>
	);
}
