import { EnrollmentApplicationSubmission } from "../../GraphQL";

export type Base = {
	signatureName: EnrollmentApplicationSubmission["signatureName"];
	signatureDate: EnrollmentApplicationSubmission["signatureDate"] | null;
	source: EnrollmentApplicationSubmission["source"];
};

export type Create = Base & {
	doesConsentToDataUse: EnrollmentApplicationSubmission["doesConsentToDataUse"] | null;
};

export type Detail = Base & {
	doesConsentToDataUse: EnrollmentApplicationSubmission["doesConsentToDataUse"];
	createdAt: EnrollmentApplicationSubmission["createdAt"] | null;
};

export const initialCreate: Create = {
	doesConsentToDataUse: null,
	signatureName: "",
	signatureDate: null,
	source: "",
};
