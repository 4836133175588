import React from "react";
import {
	Button,
	ChildrenProps,
	ClassNameProps,
	DefaultContextValue,
	Icon,
	makeClassName,
	Row,
	SectionSubcomponentProps,
	StyleProps,
} from "@hex-insights/core";
import { Tile } from "../Tile";
import styles from "./styles.module.css";

type ExpandingTileContextValue = {
	onExpandClick: () => void;
};

const ExpandingTileContext = React.createContext<ExpandingTileContextValue>(
	new DefaultContextValue("ExpandingTileContext") as any,
);

type ExpandingTileProps = {
	backgroundColor?: string;
	onExpandClick: () => void;
} & Partial<ClassNameProps & StyleProps & ChildrenProps>;

export const ExpandingTile = ({
	backgroundColor = "#fff",
	onExpandClick,
	className,
	style,
	children,
}: ExpandingTileProps) => {
	const contextValue = React.useMemo(() => ({ onExpandClick }), [onExpandClick]);

	return (
		<Tile
			className={makeClassName(className, styles["expanding-tile"])}
			style={
				{
					"--background-color": backgroundColor,
					"--box-shadow": style?.boxShadow,
					...style,
				} as React.CSSProperties
			}
		>
			<ExpandingTileContext.Provider value={contextValue}>{children}</ExpandingTileContext.Provider>
		</Tile>
	);
};

ExpandingTile.Body = ({ className, children, ...props }: SectionSubcomponentProps) => {
	const { onExpandClick } = React.useContext(ExpandingTileContext);

	return (
		<Tile.Body className={makeClassName(styles["expanding-tile__body"], className)} {...props}>
			<Button onClick={onExpandClick} className={styles["expanding-tile__expand-button"]}>
				<Row justify="center" align="center" style={{ width: "100%", height: "100%" }}>
					<Icon.ChevronUp />
				</Row>
			</Button>

			<div className={styles["expanding-tile__body__content"]}>{children}</div>
		</Tile.Body>
	);
};
