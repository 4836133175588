import { CourseSection } from "../../../GraphQL";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the name field of the CourseSection model.
 */
export function name(value: CourseSection["name"]): string {
	return toString(value);
}

/**
 * Formats the description field of the CourseSection model.
 */
export function description(value: CourseSection["description"]): string {
	return toString(value);
}
