import { ChildrenProps, ClassNameProps, makeClassName, StyleProps, useMediaQueries } from "@hex-insights/core";
import styles from "./styles.module.css";

type NavigationFooterProps = Partial<ClassNameProps & StyleProps & ChildrenProps>;

export function NavigationFooter({ className, style, children }: NavigationFooterProps) {
	return (
		<div className={makeClassName(styles["navigation-footer"], className)} style={style}>
			{children}
		</div>
	);
}

const mediumButtonMediaQuery = "(min-height: 700px)";

NavigationFooter.useResponsiveButtonSize = function () {
	const matches = useMediaQueries([mediumButtonMediaQuery]);
	return matches[mediumButtonMediaQuery] ? "medium" : "small";
};
