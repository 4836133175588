import React from "react";
import { EditMode, FormType, useFormState, UseFormStateArgs } from "@hex-insights/forms";
import { ServiceSubscriptionFormValues } from "../ModelFormValues";

/**
 * Returns a `formState` object for use in the create form for the ServiceSubscription model.
 */
export function useCreateFormState() {
	return useFormState({
		initialFormValues: ServiceSubscriptionFormValues.initialCreate,
		formType: FormType.Create,
	});
}

export function useCreateBulkForContractFormState(
	contractID: ServiceSubscriptionFormValues.CreateBulkForContract["contractID"] = null,
) {
	return useFormState({
		initialFormValues: React.useMemo(
			() => ({ ...ServiceSubscriptionFormValues.initialCreateBulkForContract, contractID }),
			[contractID],
		),
		formType: FormType.Create,
	});
}

const detailFieldEditModes = {
	contractID: EditMode.ReadOnly,
	discountIDs: EditMode.ReadOnly,
};

export type UseDetailFormStateArgs = UseFormStateArgs<ServiceSubscriptionFormValues.Detail>;

/**
 * Returns a `formState` object for use in the detail form of the ServiceSubscription model.
 *
 * @param initialFormValues The initial values for the form. Generally from
 * {@link ServiceSubscriptionFormConversion.toFormValues}.
 */
export function useDetailFormState({
	initialFormValues,
	formType = FormType.Update,
	fieldEditModes = detailFieldEditModes,
	...args
}: UseDetailFormStateArgs) {
	return useFormState({ initialFormValues, formType, fieldEditModes, ...args });
}

/**
 * Returns a read-only `formState` object for use in the detail form of the ServiceSubscription model.
 *
 * @param initialFormValues The initial values for the form. Generally from
 * {@link ServiceSubscriptionFormConversion.toFormValues}.
 */
export function useReadOnlyFormState({
	initialFormValues,
}: {
	initialFormValues: ServiceSubscriptionFormValues.Detail;
}) {
	return useFormState({
		initialFormValues: initialFormValues,
		editMode: EditMode.ReadOnly,
	});
}
