import { Route, useActivePageRegistration, useRouteMatch, useRouteParams } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { LessonPlansWeekCourseSectionPage } from "./CourseSection";
import {
	lessonPlansWeekCourseSectionPageInfo,
	LessonPlansWeekCourseSectionPageRouteParams,
} from "./CourseSection/pageinfo";
import { LessonPlansWeekIndexPage } from "./IndexPage";
import { lessonPlansWeekIndexPageInfo } from "./IndexPage/pageinfo";
import { lessonPlansWeekPageInfo, LessonPlansWeekPageRouteParams } from "./pageinfo";

export function LessonPlansWeekPage() {
	const { date } = useRouteParams<LessonPlansWeekPageRouteParams>();
	useActivePageRegistration(lessonPlansWeekPageInfo, lessonPlansWeekPageInfo.title(date));

	return (
		<PageRouteSwitch>
			<Route {...lessonPlansWeekIndexPageInfo.routeProps}>
				<LessonPlansWeekIndexPage />
			</Route>
			<Route {...lessonPlansWeekCourseSectionPageInfo.routeProps}>
				<LessonPlansWeekCourseSectionPage
					key={
						useRouteMatch<LessonPlansWeekCourseSectionPageRouteParams>(
							lessonPlansWeekCourseSectionPageInfo.path as string,
						)?.params.courseSectionID
					}
				/>
			</Route>
		</PageRouteSwitch>
	);
}
