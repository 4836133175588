import { CaseStyle, convertCase, formatDateTime } from "@hex-insights/core";
import { Contract } from "../../../GraphQL";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the agreementDate field of the Contract model.
 */
export function agreementDate(value: Contract["agreementDate"] | null): string {
	if (!value) {
		return toString(value);
	}
	return formatDateTime(value, "D MMM YYYY");
}

/**
 * Formats the status field of the Contract model.
 */
export function status(value: Contract["status"] | null): string {
	if (value === null) {
		return toString(value);
	}
	return convertCase(value, CaseStyle.Title);
}
