import { newPageInfo } from "@hex-insights/router";
import { lessonPlansWeekCourseSectionPageInfo, LessonPlansWeekCourseSectionPageRouteParams } from "../pageinfo";

export type LessonPlansWeekCourseSectionIndexPageRouteParams = LessonPlansWeekCourseSectionPageRouteParams;

export const lessonPlansWeekCourseSectionIndexPageInfo = newPageInfo({
	name: "Course Section Lesson Plan Weekly View Index",
	title: "",
	description: "Weekly view of lesson plans to view, create, and edit lesson plans.",
	exact: true,
	to: lessonPlansWeekCourseSectionPageInfo.to,
	path: lessonPlansWeekCourseSectionPageInfo.path,
	parent: lessonPlansWeekCourseSectionPageInfo,
});
