import { Models } from "@hex-insights/app-modules";
import { Discount, DiscountMutation } from "../../../../Utilities";

export type DiscountDeleteButtonProps = Omit<Models.DeleteButtonProps, "deleteInstance"> & {
	instanceID: Discount["id"];
};

/**
 * A delete button for the Discount model.
 */
export function DiscountDeleteButton({
	instanceID,
	confirmationContent = "Are you sure you want to delete this discount?",
	children = "Delete Discount",
	...props
}: DiscountDeleteButtonProps) {
	const del = DiscountMutation.useDelete(instanceID);

	return (
		<Models.DeleteButton deleteInstance={del} confirmationContent={confirmationContent} {...props}>
			{children}
		</Models.DeleteButton>
	);
}
