import { NullPartial } from "@hex-insights/core";
import {
	PhoneNumberCreateInput,
	PhoneNumberDetailQuery,
	PhoneNumberInPersonCreateInput,
	PhoneNumberUpdateInput,
} from "../../GraphQL";
import { PhoneNumberFormValues } from "../ModelFormValues";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the PhoneNumber model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(formValues: PhoneNumberFormValues.Create): PhoneNumberCreateInput {
	const input: NullPartial<PhoneNumberCreateInput> = { ...formValues };
	return input as PhoneNumberCreateInput;
}

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the PhoneNumber model in the Person
 * model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInPersonInput(
	formValues: PhoneNumberFormValues.CreateInPerson,
): PhoneNumberInPersonCreateInput {
	const input: NullPartial<PhoneNumberInPersonCreateInput> = { ...formValues };
	return input as PhoneNumberInPersonCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the PhoneNumber model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<PhoneNumberFormValues.Detail>,
	_initialFormValues: PhoneNumberFormValues.Detail,
): PhoneNumberUpdateInput {
	const input: PhoneNumberUpdateInput = { ...formValues };
	return input;
}

/**
 * Converts a PhoneNumber instance to its corresponding detail form values.
 *
 * @param phoneNumber The instance to convert.
 */
export function toFormValues(phoneNumber: PhoneNumberDetailQuery["phoneNumber"]): PhoneNumberFormValues.Detail {
	return {
		phoneNumber: phoneNumber.phoneNumber,
		phoneType: phoneNumber.phoneType,
		personID: phoneNumber.person?.id ?? null,
	};
}
