import React from "react";
import {
	abbreviateNumber,
	Button,
	CaseStyle,
	ChildrenProps,
	Column,
	compareObjects,
	compareStringsAsc,
	Conditional,
	convertCase,
	count,
	dateTrunc,
	Else,
	formatDateTime,
	formatPercentage,
	getObjectValues,
	Heading,
	Icon,
	If,
	isAfter,
	isBefore,
	isSameTimeOrBefore,
	List,
	makeClassName,
	Modal,
	ModalProps,
	RequiredKeys,
	Row,
	StyleProps,
	toGrammaticalNumber,
	toLocalDateString,
	useToggle,
} from "@hex-insights/core";
import { anyFieldChanged, EditMode } from "@hex-insights/forms";
import { BasicTable } from "@hex-insights/tables";
import {
	Contract,
	ContractFormat,
	ContractFormState,
	ContractFormValues,
	ContractInstallmentDetailQuery,
	ContractInstallmentFormConversion,
	ContractInstallmentFormState,
	ContractInstallmentFormValues,
	ContractInstallmentMutation,
	ContractMutation,
	ContractOrderField,
	ContractStatus,
	Discount,
	DiscountDetailQuery,
	DiscountFormat,
	DiscountFormConversion,
	DiscountFormState,
	DiscountFormValues,
	DiscountMutation,
	formatPrice,
	Invoice,
	InvoiceOrderField,
	OrderDirection,
	ServiceSubscription,
	ServiceSubscriptionFormat,
	ServiceSubscriptionFormConversion,
	ServiceSubscriptionFormState,
	ServiceSubscriptionFormValues,
	ServiceSubscriptionMutation,
	Student,
	StudentContractsPaymentProgressListQuery,
	StudentContractsServiceSubscriptionsListQuery,
	StudentInvoicesListQuery,
	useContractPaymentScheduleListQuery,
	useInvoiceDetailQuery,
	usePaymentLineItemIndexQuery,
	useServiceSubscriptionBulkCreate,
	useStudentContractDetailQuery,
	useStudentContractsPaymentProgressListQuery,
	useStudentContractsServiceSubscriptionsListQuery,
	useStudentDetailQuery,
	useStudentInvoicesListQuery,
} from "../../Utilities";
import {
	ContractInstallmentDeleteButton,
	DiscountDeleteButton,
	ServiceSubscriptionDeleteButton,
} from "../DeleteButtons";
import { ContractForm, ContractInstallmentForm, DiscountForm, ServiceSubscriptionForm } from "../Forms";
import { HorizontalScrollingContainer } from "../HorizontalScrollingContainer";
import { InvoiceDisplay } from "../InvoiceDisplay";
import { ScaledText } from "../ScaledText";
import { Tile } from "../Tile";
import styles from "./styles.module.css";

type InvoiceInQuery = StudentInvoicesListQuery["invoiceConnection"]["edges"][0]["node"];
type InvoiceInQueryWithTotals = InvoiceInQuery & {
	invoiceTotal: number;
	paymentTotal: number;
};

export type NextPaymentTileProps = {
	studentID: Student["id"];
};

export function NextPaymentTile({ studentID }: NextPaymentTileProps) {
	const { loading: loadingContracts, data: contractData } = useContractPaymentScheduleListQuery({
		variables: {
			filters: { studentIDEQ: studentID, statusEQ: ContractStatus.Active },
		},
	});

	const nextInstallment = React.useMemo(() => {
		if (!contractData || contractData.contractConnection.edges.length === 0) {
			return null;
		}

		let nextInstallment: { dueDate: string; amount: number } | null = null;

		for (let i = 0; i < contractData.contractConnection.edges.length; i++) {
			const contract = contractData.contractConnection.edges[i].node;
			const totalContractPrice = contract.serviceSubscriptions.reduce((a, e) => {
				const discountTotal = e.discounts.reduce((a, e) => a + e.amount, 0);
				return a + e.price - discountTotal;
			}, 0);
			const totalPortions = contract.contractInstallments.reduce((a, e) => a + e.portion, 0);

			for (let j = 0; j < contract.contractInstallments.length; j++) {
				const installment = contract.contractInstallments[j];
				if (
					isAfter(installment.installmentDate, new Date()) &&
					(nextInstallment === null || isBefore(installment.installmentDate, nextInstallment.dueDate))
				) {
					nextInstallment = {
						dueDate: installment.installmentDate,
						amount: totalContractPrice * (installment.portion / totalPortions),
					};
				}
			}
		}

		return nextInstallment;
	}, [contractData]);

	const { loading: loadingInvoices, data: invoicesData } = useStudentInvoicesListQuery({
		variables: {
			filters: {
				contractInstallment: [
					{
						contract: [
							{
								statusEQ: ContractStatus.Active,
								studentIDEQ: studentID,
							},
						],
					},
				],
			},
			order: { field: InvoiceOrderField.DueDate, direction: OrderDirection.Asc },
		},
	});

	const { nextInvoice, overdueInvoices } = React.useMemo(() => {
		let nextInvoice: InvoiceInQueryWithTotals | null = null;
		const overdueInvoices: InvoiceInQueryWithTotals[] = [];

		if (!invoicesData) {
			return { nextInvoice, overdueInvoices };
		}

		const today = dateTrunc(new Date(), "day");

		for (let i = 0; i < invoicesData.invoiceConnection.edges.length; i++) {
			const invoice = invoicesData.invoiceConnection.edges[i].node;

			const invoiceTotal = invoice.invoiceLineItems.reduce((a, e) => a + e.amount, 0);
			const paymentTotal = invoice.paymentLineItems.reduce((a, e) => a + e.amount, 0);
			const isNotFullyPaid = paymentTotal < invoiceTotal;

			if (isNotFullyPaid && isAfter(today, invoice.dueDate)) {
				overdueInvoices.push({ ...invoice, invoiceTotal, paymentTotal });
			}

			if (nextInvoice === null && isNotFullyPaid && isSameTimeOrBefore(today, invoice.dueDate)) {
				nextInvoice = { ...invoice, invoiceTotal, paymentTotal };
			}
		}

		return { nextInvoice, overdueInvoices };
	}, [invoicesData]);

	return (
		<Tile>
			<Tile.Header>
				<Heading level={2} noMargin style={{ lineHeight: 1 }}>
					Next Payment
				</Heading>
			</Tile.Header>
			<Tile.Body style={{ paddingTop: "0.5rem" }}>
				<Column justify="spaced-start">
					<Conditional>
						<If condition={loadingContracts || loadingInvoices}>Loading...</If>
						<Else>
							<Conditional>
								<If condition={!!nextInvoice}>
									{!!nextInvoice && (
										<Row justify="spaced-start" align="center">
											<Column style={{ flexGrow: 1 }}>
												<span style={{ fontSize: "1.25rem", fontWeight: "bold" }}>
													{formatPrice(nextInvoice.invoiceTotal - nextInvoice.paymentTotal)}
												</span>
												<If condition={nextInvoice.paymentTotal > 0}>
													<span style={{ color: "#888", fontSize: "0.9rem" }}>
														Remaining of {formatPrice(nextInvoice.invoiceTotal)}&nbsp;total
													</span>
												</If>
											</Column>

											<div>
												<div
													style={{
														color: "var(--verita-blue)",
														backgroundColor: "var(--light-blue)",
														whiteSpace: "nowrap",
														width: "fit-content",
														padding: "0.5rem 1rem",
														borderRadius: "5rem",
													}}
												>
													Due on {formatDateTime(nextInvoice.dueDate, "D MMMM YYYY")}
												</div>
											</div>
										</Row>
									)}
								</If>
								<If condition={nextInstallment !== null}>
									{!!nextInstallment && (
										<Row justify="spaced-start" align="center">
											<Column style={{ flexGrow: 1 }}>
												<span style={{ fontSize: "1.25rem", fontWeight: "bold" }}>
													{formatPrice(nextInstallment.amount)}
												</span>
											</Column>

											<div>
												<div
													style={{
														color: "var(--verita-blue)",
														backgroundColor: "var(--light-blue)",
														whiteSpace: "nowrap",
														width: "fit-content",
														padding: "0.5rem 1rem",
														borderRadius: "5rem",
													}}
												>
													Due on {formatDateTime(nextInstallment.dueDate, "D MMMM YYYY")}
												</div>
											</div>
										</Row>
									)}
								</If>
								<Else>No upcoming payments.</Else>
							</Conditional>
							<If condition={overdueInvoices.length > 0}>
								<OverdueInvoicesController invoices={overdueInvoices} />
							</If>
						</Else>
					</Conditional>
				</Column>
			</Tile.Body>
		</Tile>
	);
}

type OverdueInvoicesController = {
	invoices: InvoiceInQuery[];
};

function OverdueInvoicesController({ invoices }: OverdueInvoicesController) {
	const { isModalOpen, toggleIsModalOpen } = Modal.useToggle(false);

	const [invoiceIndex, setInvoiceIndex] = React.useState(0);

	const numInvoices = invoices.length;
	const onPrev = React.useCallback(() => {
		setInvoiceIndex((prev) => Math.max(prev - 1, 0));
	}, []);
	const onNext = React.useCallback(() => {
		setInvoiceIndex((prev) => Math.min(prev + 1, numInvoices - 1));
	}, [numInvoices]);

	const invoice = invoices[invoiceIndex];

	return (
		<React.Fragment>
			<Button
				variant="danger"
				onClick={toggleIsModalOpen}
				style={{
					color: "#811",
					backgroundColor: "#8113",
					borderColor: "#8113",
					width: "100%",
					borderRadius: "10rem",
				}}
			>
				<Row justify="space-between" align="center">
					<Icon.AlertCircle size="1.25rem" style={{ display: "block" }} />
					{toGrammaticalNumber("Overdue Invoice", invoices.length, true)}
					<div style={{ width: "1.25rem" }}></div>
				</Row>
			</Button>

			<Modal.If condition={isModalOpen}>
				<Modal onClose={toggleIsModalOpen}>
					<Modal.Header>
						<Heading level={2} noMargin>
							Overdue Invoices
						</Heading>
					</Modal.Header>
					<Modal.Body>
						<Row justify="spaced-start" horizontalSpacing="2rem" align="center">
							<Heading level={3} noMargin>
								Invoice {invoice.invoiceNumber}
							</Heading>

							<If condition={numInvoices > 1}>
								<Row justify="spaced-start">
									<Button variant="link" onClick={onPrev} disabled={invoiceIndex === 0} style={{ padding: 0 }}>
										Previous
									</Button>
									<Button
										variant="link"
										onClick={onNext}
										disabled={invoiceIndex === numInvoices - 1}
										style={{ padding: 0 }}
									>
										Next
									</Button>
								</Row>
							</If>
						</Row>
						<ControlledInvoiceDisplay key={invoice.id} invoiceID={invoice.id} />
					</Modal.Body>
				</Modal>
			</Modal.If>
		</React.Fragment>
	);
}

type ControlledInvoiceDisplayProps = {
	invoiceID: Invoice["id"];
};

function ControlledInvoiceDisplay({ invoiceID }: ControlledInvoiceDisplayProps) {
	const { loading, data } = useInvoiceDetailQuery({ variables: { id: invoiceID } });

	if (loading) {
		return <span>Loading...</span>;
	}

	if (!data) {
		return <span>There was a problem loading this invoice, please refresh the page and try again.</span>;
	}

	return <InvoiceDisplay invoice={data.invoice} />;
}

export type PaymentProgressTileProps = {
	studentID: Student["id"];
};

export function PaymentProgressTile({ studentID }: PaymentProgressTileProps) {
	const { data: contractsData } = useStudentContractsServiceSubscriptionsListQuery({
		variables: { filters: { studentIDEQ: studentID, statusEQ: ContractStatus.Active } },
	});
	const { data: paymentData } = usePaymentLineItemIndexQuery({
		variables: {
			filters: {
				invoice: [
					{
						contractInstallment: [
							{
								contract: [
									{
										statusEQ: ContractStatus.Active,
										studentIDEQ: studentID,
									},
								],
							},
						],
					},
				],
			},
		},
	});

	const { contractsTotal, paymentTotal } = React.useMemo(() => {
		let contractsTotal = 0;
		let paymentTotal = 0;

		if (contractsData) {
			for (let i = 0; i < contractsData.contractConnection.edges.length; i++) {
				contractsTotal += getContractTotalPrice(contractsData.contractConnection.edges[i].node);
			}
		}

		if (paymentData) {
			for (let i = 0; i < paymentData.paymentLineItemConnection.edges.length; i++) {
				const paymentLineItem = paymentData.paymentLineItemConnection.edges[i].node;
				paymentTotal += paymentLineItem.amount;
			}
		}

		return { contractsTotal, paymentTotal };
	}, [contractsData, paymentData]);

	const chartPercentage = contractsTotal === 0 ? 0 : paymentTotal / contractsTotal;

	return (
		<Tile style={{ position: "relative" }}>
			<Tile.Header style={{ position: "relative", zIndex: 1 }}>
				<Heading level={2} noMargin style={{ lineHeight: 1 }}>
					Payment Progress
				</Heading>
			</Tile.Header>
			<Tile.Body style={{ "--section__body---padding": "1rem" } as React.CSSProperties}>
				<Column justify="spaced-start" verticalSpacing="0.25rem">
					<div
						style={{
							backgroundImage: `conic-gradient(from 0deg at center, var(--success-color) 0 calc(${chartPercentage} * 100%), #eee 0 100%)`,
							width: "5rem",
							height: "5rem",
							borderRadius: "50%",
							position: "absolute",
							top: "0.5rem",
							right: "0.5rem",
							zIndex: 0,
						}}
					>
						<div
							style={{
								backgroundColor: "#fff",
								width: "80%",
								height: "80%",
								borderRadius: "50%",
								position: "absolute",
								top: "50%",
								left: "50%",
								transform: "translate(-50%, -50%)",
							}}
						>
							<ScaledText
								maxFontSize={24}
								style={{
									textAlign: "center",
									width: "100%",
									position: "absolute",
									top: "50%",
									left: "50%",
									transform: "translate(-50%, -50%)",
								}}
							>
								{formatPercentage(paymentTotal / contractsTotal, { precision: 0 })}
							</ScaledText>
						</div>
					</div>

					<Column justify="spaced-start">
						<div style={{ fontSize: "1.5rem", position: "relative", zIndex: 1 }}>
							{formatPrice(paymentTotal)} of {formatPrice(contractsTotal)}
						</div>
					</Column>

					<ContractsInstallmentsDisplay studentID={studentID} />
				</Column>
			</Tile.Body>
		</Tile>
	);
}

type ContractsInstallmentsDisplayProps = {
	studentID: Student["id"];
};

function ContractsInstallmentsDisplay({ studentID }: ContractsInstallmentsDisplayProps) {
	const { data } = useStudentContractsPaymentProgressListQuery({
		variables: { filters: { studentIDEQ: studentID, statusEQ: ContractStatus.Active } },
	});

	const mergedInstallments = React.useMemo(() => {
		if (!data) {
			return [];
		}
		const installmentsObj: Record<string, { key: string; installmentDate: string; amount: number }> = {};
		for (let i = 0; i < data.contractConnection.edges.length; i++) {
			const contract = data.contractConnection.edges[i].node;
			const totalPrice = getContractTotalPrice(contract);
			const totalPortion = contract.contractInstallments.reduce((a, e) => a + (e.portion ?? 0), 0);

			for (let j = 0; j < contract.contractInstallments.length; j++) {
				const installment = contract.contractInstallments[j];
				if (!Object.keys(installmentsObj).includes(installment.installmentDate)) {
					installmentsObj[installment.installmentDate] = {
						key: installment.id,
						installmentDate: installment.installmentDate,
						amount: 0,
					};
				}
				installmentsObj[installment.installmentDate].amount += (totalPrice * installment.portion) / totalPortion;
			}
		}

		return getObjectValues(installmentsObj).sort(compareObjects("installmentDate", compareStringsAsc()));
	}, [data]);

	if (mergedInstallments.length === 0) {
		return null;
	}

	return (
		<Column>
			<span>Due Dates</span>
			<HorizontalScrollingContainer>
				<Row justify="spaced-start" horizontalSpacing="0.25rem">
					{mergedInstallments.map((installment) => (
						<Column
							key={installment.key}
							align="center"
							className={makeClassName(
								styles["contract-installment"],
								isAfter(installment.installmentDate, new Date(), "day") ? styles["contract-installment--future"] : "",
							)}
						>
							<span style={{ whiteSpace: "nowrap" }}>{formatDateTime(installment.installmentDate, "D MMM 'YY")}</span>
							{/* TODO abbreviateMoney */}
							<span>{abbreviateNumber(installment.amount / 100)}</span>
						</Column>
					))}
				</Row>
			</HorizontalScrollingContainer>
		</Column>
	);
}

const displayedContractStatusSet = new Set([ContractStatus.Active, ContractStatus.Draft]);

export type ContractsTileProps = {
	studentID: Student["id"];
};

export function ContractsTile({ studentID }: ContractsTileProps) {
	const { loading, data } = useStudentContractsServiceSubscriptionsListQuery({
		variables: {
			filters: {
				studentIDEQ: studentID,
			},
			order: {
				field: ContractOrderField.AgreementDate,
				direction: OrderDirection.Desc,
			},
		},
	});

	const displayedData = !data
		? undefined
		: {
				contractConnection: {
					edges: data.contractConnection.edges.filter((e) => displayedContractStatusSet.has(e.node.status)),
				},
		  };
	const numOtherContracts = count(
		data?.contractConnection.edges ?? [],
		(e) => !displayedContractStatusSet.has(e.node.status),
	);

	const {
		isModalOpen: isNewContractModalOpen,
		toggleIsModalOpen: toggleIsNewContractModalOpen,
		closeModal: closeNewContractModal,
	} = Modal.useToggle(false);

	const { isModalOpen: isFullModalOpen, toggleIsModalOpen: toggleIsFullModalOpen } = Modal.useToggle(false);

	return (
		<Tile>
			<Tile.Header>
				<Row justify="space-between">
					<Heading level={2} noMargin style={{ lineHeight: 1 }}>
						Billing
					</Heading>

					<If condition={!!displayedData && displayedData.contractConnection.edges.length > 0}>
						<Button variant="primary" size="small" onClick={toggleIsNewContractModalOpen}>
							+ New Contract
						</Button>
					</If>
				</Row>
			</Tile.Header>
			<Tile.Body style={{ paddingTop: "0.5rem", paddingLeft: "1rem", paddingRight: "1rem" }}>
				<Conditional>
					<If condition={loading}>Loading...</If>
					<Else>
						<Conditional>
							<If condition={!!displayedData && displayedData.contractConnection.edges.length === 0}>
								<Row justify="center" style={{ padding: "0.5rem 0" }}>
									<Button variant="primary" onClick={toggleIsNewContractModalOpen}>
										Set Up Contract
									</Button>
								</Row>
							</If>
							<Else>
								<List className={styles["billing-item-list"] + " " + styles["billing-item-list--compact"]}>
									{displayedData?.contractConnection.edges.map(({ node: contract }) => (
										<ContractBillingItemsListItem key={contract.id} contract={contract} studentID={studentID} />
									))}
								</List>
							</Else>
						</Conditional>

						<If condition={numOtherContracts > 0}>
							<Row justify="center">
								<Button
									variant="link"
									size="small"
									onClick={toggleIsFullModalOpen}
									style={{ paddingTop: 0, paddingBottom: 0 }}
								>
									View All ({numOtherContracts} Hidden)
								</Button>
							</Row>
						</If>
					</Else>
				</Conditional>

				<Modal.If condition={isFullModalOpen}>
					<Modal onClose={toggleIsFullModalOpen} style={{ minWidth: "10rem", width: "fit-content" }}>
						<Modal.Header>
							<Heading.H2 noMargin>Contracts</Heading.H2>
						</Modal.Header>
						<Modal.Body>
							<List className={styles["billing-item-list"] + " " + styles["billing-item-list--compact"]}>
								{data?.contractConnection.edges.map(({ node: contract }) => (
									<ContractBillingItemsListItem key={contract.id} contract={contract} studentID={studentID} />
								))}
							</List>
						</Modal.Body>
					</Modal>
				</Modal.If>

				<Modal.If condition={isNewContractModalOpen}>
					<NewContractModal
						studentID={studentID}
						onClose={toggleIsNewContractModalOpen}
						onSuccess={closeNewContractModal}
					/>
				</Modal.If>
			</Tile.Body>
		</Tile>
	);
}

type ContractBillingItemsListItemProps = {
	contract: StudentContractsServiceSubscriptionsListQuery["contractConnection"]["edges"][0]["node"];
	studentID: Student["id"];
};

function ContractBillingItemsListItem({ contract, studentID }: ContractBillingItemsListItemProps) {
	const { isModalOpen, toggleIsModalOpen } = Modal.useToggle(false);

	return (
		<List.Item>
			<Button
				onClick={toggleIsModalOpen}
				className={makeClassName(styles["billing-item-button"], styles["billing-item-button--agreement"])}
			>
				<Row justify="spaced-start" align="center" horizontalSpacing="0.5rem">
					<Icon.Briefcase size="1.25rem" />

					<div style={{ fontSize: "1.1rem" }}>
						Agreement on {ContractFormat.Fields.agreementDate(contract.agreementDate)}{" "}
					</div>
					<If condition={contract.status !== ContractStatus.Active}>
						<span
							className={makeClassName(
								styles["contract-billing-item__status"],
								styles[`contract-billing-item__status--${convertCase(contract.status, CaseStyle.Kebab)}`],
							)}
						>
							{ContractFormat.Fields.status(contract.status)}
						</span>
					</If>

					<div title="Click to view" className={styles["billing-item-button__show-icon-container"]}>
						<Icon.Eye size="1rem" className={styles["billing-item-button__show-icon"]} style={{ display: "block" }} />
					</div>
				</Row>
			</Button>
			<List className={styles["billing-item-list"] + " " + styles["billing-item-list--compact"]}>
				{contract.serviceSubscriptions.map((ss) => (
					<List.Item key={ss.id}>
						<ServiceSubscriptionListItem serviceSubscription={ss} contract={contract} />
					</List.Item>
				))}
			</List>

			<Modal.If condition={isModalOpen}>
				<ContractModal contractID={contract.id} studentID={studentID} onClose={toggleIsModalOpen} />
			</Modal.If>
		</List.Item>
	);
}

export type ContractModalProps = {
	contractID: Contract["id"];
	studentID: Student["id"];
} & RequiredKeys<Pick<ModalProps, "ifRef" | "onClose">, "onClose">;

export function ContractModal({ contractID, studentID, ifRef, onClose }: ContractModalProps) {
	const { data: studentData } = useStudentDetailQuery({ variables: { id: studentID } });
	const student = studentData?.student;
	const { loading, data } = useStudentContractDetailQuery({ variables: { id: contractID } });
	const contract = data?.contract;
	const isEditable = ContractFormValues.isContractEditable(contract?.status);
	const contractWithStudent = React.useMemo(() => {
		if (!(contract && student)) {
			return null;
		}
		return { ...contract, student: student ?? null };
	}, [contract, student]);

	const {
		isOn: isServiceSubscriptionModalOpen,
		toggle: toggleIsServiceSubscriptionModalOpen,
		setIsOn: setIsServiceSubscriptionModalOpen,
	} = useToggle(false);
	const onServiceSubscriptionSuccess = React.useCallback(() => {
		setIsServiceSubscriptionModalOpen(false);
	}, [setIsServiceSubscriptionModalOpen]);

	const totalPrice = contract ? getContractTotalPrice(contract) : 0;
	const totalPortion = contract ? contract.contractInstallments.reduce((a, e) => a + e.portion, 0) : 0;

	const update = ContractMutation.useUpdate(contractID);
	const applyUpdate = React.useCallback(
		async (changedFormValues: Partial<ContractFormValues.Detail>, initialFormValues: ContractFormValues.Detail) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<React.Fragment>
			<Modal ifRef={ifRef} onClose={onClose} style={{ width: "fit-content", maxWidth: "90vw", minWidth: "40vw" }}>
				<If condition={!loading}>
					<Modal.Header>
						<Heading level={2} noMargin>
							Agreement on {ContractFormat.Fields.agreementDate(contract?.agreementDate ?? null)}
						</Heading>
					</Modal.Header>
				</If>
				<Modal.Body>
					<Conditional>
						<If condition={loading}>Loading...</If>
						<Else>
							{!!(contract && contractWithStudent) && (
								<Column justify="spaced-start">
									<ContractForm.ControlledDetailSlim
										contract={contractWithStudent}
										applyUpdate={applyUpdate}
										onSuccess={onClose}
									/>

									<Heading level={3} noMargin>
										Services Included
									</Heading>
									<List className={styles["billing-item-list"]}>
										{contract.serviceSubscriptions.map((ss) => (
											<List.Item key={ss.id}>
												<ServiceSubscriptionListItem serviceSubscription={ss} contract={contract} />
											</List.Item>
										))}
									</List>
									<If condition={isEditable}>
										<div style={{ paddingLeft: "1rem" }}>
											<Button variant="secondary" size="small" onClick={toggleIsServiceSubscriptionModalOpen}>
												Add Billing Item
											</Button>
										</div>
									</If>

									<Heading level={2} noMargin>
										Payment Schedule
									</Heading>
									<Conditional>
										<If condition={contract.contractInstallments.length === 0}>No installments</If>
										<Else>
											<BasicTable
												style={{
													fontSize: "1.1rem",
													paddingLeft: "1rem",
													width: "fit-content",
												}}
											>
												<BasicTable.Body>
													{contract.contractInstallments.map((installment) => (
														<ContractInstallmentTableRow
															key={installment.id}
															contract={contract}
															installment={installment}
															totalPortion={totalPortion}
															totalPrice={totalPrice}
														/>
													))}
												</BasicTable.Body>
											</BasicTable>
											<If condition={isEditable}>
												<div style={{ paddingLeft: "1rem" }}>
													<ContractInstallmentCreateController contractID={contract.id} />
												</div>
											</If>
										</Else>
									</Conditional>
								</Column>
							)}
						</Else>
					</Conditional>
				</Modal.Body>
			</Modal>

			<Modal.If condition={isServiceSubscriptionModalOpen}>
				<NewServiceSubscriptionsModal
					studentID={studentID}
					contractID={contractID}
					onClose={toggleIsServiceSubscriptionModalOpen}
					onSuccess={onServiceSubscriptionSuccess}
				/>
			</Modal.If>
		</React.Fragment>
	);
}

type ServiceSubscriptionListItemProps = {
	serviceSubscription: StudentContractsServiceSubscriptionsListQuery["contractConnection"]["edges"][0]["node"]["serviceSubscriptions"][0];
	contract: Pick<Contract, "id" | "agreementDate" | "status">;
};

function ServiceSubscriptionListItem({ serviceSubscription, contract }: ServiceSubscriptionListItemProps) {
	const totalPrice = getServiceSubscriptionTotalPrice(serviceSubscription);

	const { isOn: isOpen, toggle: toggleIsOpen } = useToggle(false);

	return (
		<React.Fragment>
			<Button
				onClick={toggleIsOpen}
				className={makeClassName(styles["billing-item-button"], styles["billing-item-button--service"])}
			>
				<Row justify="spaced-start" align="center" horizontalSpacing="0.5rem">
					<span style={{ width: "fit-content", display: "inline-block" }}>
						{serviceSubscription.service.name} ({formatPrice(totalPrice)})
					</span>

					<div title="Click to view" className={styles["billing-item-button__show-icon-container"]}>
						<Icon.Eye size="1rem" className={styles["billing-item-button__show-icon"]} style={{ display: "block" }} />
					</div>
				</Row>
			</Button>

			<Modal.If condition={isOpen}>
				<ServiceSubscriptionModal
					serviceSubscription={serviceSubscription}
					contract={contract}
					onClose={toggleIsOpen}
				/>
			</Modal.If>
		</React.Fragment>
	);
}

type ServiceSubscriptionModalProps = ServiceSubscriptionListItemProps &
	RequiredKeys<Pick<ModalProps, "ifRef" | "onClose">, "onClose">;

function ServiceSubscriptionModal({
	serviceSubscription: serviceSubscriptionProp,
	contract,
	ifRef,
	onClose,
}: ServiceSubscriptionModalProps) {
	const isEditable = ContractFormValues.isContractEditable(contract.status);

	const serviceSubscription = React.useMemo(
		() => ({ ...serviceSubscriptionProp, contract }),
		[serviceSubscriptionProp, contract],
	);
	const discountTotal = serviceSubscription.discounts.reduce((a, e) => a + e.amount, 0);

	const initialFormValues = React.useMemo(
		() => ServiceSubscriptionFormConversion.toFormValues(serviceSubscription),
		[serviceSubscription],
	);
	const formState = ServiceSubscriptionFormState.useDetailFormState({
		initialFormValues,
		editMode: isEditable ? undefined : EditMode.ReadOnly,
	});

	const update = ServiceSubscriptionMutation.useUpdate(serviceSubscription.id);
	const applyUpdate = React.useCallback(
		async (
			changedFormValues: Partial<ServiceSubscriptionFormValues.Detail>,
			initialFormValues: ServiceSubscriptionFormValues.Detail,
		) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<Modal ifRef={ifRef} onClose={onClose} confirmOnClose={anyFieldChanged(formState)}>
			<Modal.Header>
				<Heading level={2} noMargin>
					{serviceSubscription.service.name}
				</Heading>
			</Modal.Header>
			<Modal.Body>
				<Column justify="spaced-start">
					<ServiceSubscriptionForm.Detail
						serviceSubscription={serviceSubscription}
						formState={formState}
						applyUpdate={applyUpdate}
						onSuccess={onClose}
					/>

					<DisplayField label="Discounts">
						<Column justify="spaced-start">
							<Conditional>
								<If condition={serviceSubscription.discounts.length > 0}>
									<Column
										justify="spaced-start"
										verticalSpacing="0.25rem"
										style={{
											width: "fit-content",
											padding: "0.25rem 0.75rem",
											border: "1px solid #eee",
											borderRadius: "0.5rem",
										}}
									>
										{serviceSubscription.discounts.map((e) => (
											<DiscountDisplay key={e.id} discount={e} serviceSubscriptionID={serviceSubscription.id} />
										))}
									</Column>
								</If>
								<Else>No Discounts</Else>
							</Conditional>

							<If condition={isEditable}>
								<DiscountCreateController serviceSubscriptionID={serviceSubscription.id} />
							</If>
						</Column>
					</DisplayField>

					<Row justify="spaced-start">
						<DisplayField label="Base Price">
							{ServiceSubscriptionFormat.Fields.price(formState.formValues.price)}
						</DisplayField>

						<DisplayField label="Discount Total">{DiscountFormat.Fields.amount(discountTotal)}</DisplayField>

						<DisplayField label="Final Price">
							{ServiceSubscriptionFormat.Fields.price((formState.formValues.price ?? 0) - discountTotal)}
						</DisplayField>
					</Row>
				</Column>
			</Modal.Body>
			<If condition={isEditable}>
				<Modal.Footer>
					<Row justify="center">
						<ServiceSubscriptionDeleteButton instanceID={serviceSubscription.id} onSuccess={onClose} size="small" />
					</Row>
				</Modal.Footer>
			</If>
		</Modal>
	);
}

type DiscountDisplayProps = {
	discount: Pick<Discount, "id" | "description" | "amount">;
	serviceSubscriptionID: ServiceSubscription["id"];
};

function DiscountDisplay({ discount, serviceSubscriptionID }: DiscountDisplayProps) {
	const { isModalOpen, toggleIsModalOpen } = Modal.useToggle(false);

	const discountWithServiceSubscription = React.useMemo(
		() => ({ ...discount, serviceSubscription: { id: serviceSubscriptionID } }),
		[discount, serviceSubscriptionID],
	);

	return (
		<Row justify="spaced-start" align="flex-start">
			<DisplayField label="Description">{discount.description}</DisplayField>
			<DisplayField label="Amount">{DiscountFormat.Fields.amount(discount.amount)}</DisplayField>
			<div style={{ marginTop: "1rem" }}>
				<Button variant="tertiary" size="small" onClick={toggleIsModalOpen} title="Edit this discount">
					<Icon.Edit size="1rem" style={{ display: "block" }} />
				</Button>
			</div>
			<Modal.If condition={isModalOpen}>
				<DiscountEditModal discount={discountWithServiceSubscription} onClose={toggleIsModalOpen} />
			</Modal.If>
		</Row>
	);
}

type DiscountEditModalProps = {
	discount: DiscountDetailQuery["discount"];
} & RequiredKeys<Pick<ModalProps, "ifRef" | "onClose">, "onClose">;

function DiscountEditModal({ discount, ifRef, onClose }: DiscountEditModalProps) {
	const initialFormValues = React.useMemo(() => DiscountFormConversion.toFormValues(discount), [discount]);
	const formState = DiscountFormState.useDetailFormState({ initialFormValues, initialIsEditing: true });

	const update = DiscountMutation.useUpdate(discount.id);
	const applyUpdate = React.useCallback(
		async (changedFormValues: Partial<DiscountFormValues.Detail>, initialFormValues: DiscountFormValues.Detail) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<Modal ifRef={ifRef} onClose={onClose} confirmOnClose={anyFieldChanged(formState)} style={{ width: "fit-content" }}>
			<Modal.Header>
				<Heading level={2} noMargin>
					Edit Discount
				</Heading>
			</Modal.Header>
			<Modal.Body>
				<DiscountForm.Detail discount={discount} formState={formState} applyUpdate={applyUpdate} onSuccess={onClose} />
			</Modal.Body>
			<Modal.Footer>
				<Row justify="center">
					<DiscountDeleteButton instanceID={discount.id} onSuccess={onClose} size="small" />
				</Row>
			</Modal.Footer>
		</Modal>
	);
}

type DiscountCreateControllerProps = Pick<DiscountCreateModalProps, "serviceSubscriptionID">;

function DiscountCreateController({ serviceSubscriptionID }: DiscountCreateControllerProps) {
	const { isOn: isOpen, toggle: toggleIsOpen } = useToggle(false);

	return (
		<React.Fragment>
			<Button variant="secondary" size="small" onClick={toggleIsOpen}>
				Add Discount
			</Button>

			<Modal.If condition={isOpen}>
				<DiscountCreateModal serviceSubscriptionID={serviceSubscriptionID} onClose={toggleIsOpen} />
			</Modal.If>
		</React.Fragment>
	);
}

type DiscountCreateModalProps = {
	serviceSubscriptionID: ServiceSubscription["id"];
} & RequiredKeys<Pick<ModalProps, "ifRef" | "onClose">, "onClose">;

function DiscountCreateModal({ serviceSubscriptionID, ifRef, onClose }: DiscountCreateModalProps) {
	const initialFormValues = React.useMemo(() => ({ serviceSubscriptionID }), [serviceSubscriptionID]);
	const formState = DiscountFormState.useCreateFormState(initialFormValues);

	const create = DiscountMutation.useCreate();
	const applyCreate = React.useCallback(
		async (formValues: DiscountFormValues.Create) => {
			const { errors } = await create(formValues);
			return errors;
		},
		[create],
	);

	return (
		<Modal ifRef={ifRef} onClose={onClose} confirmOnClose={anyFieldChanged(formState)} style={{ width: "fit-content" }}>
			<Modal.Header>
				<Heading level={2} noMargin>
					New Discount
				</Heading>
			</Modal.Header>
			<Modal.Body>
				<DiscountForm.Create
					formState={formState}
					applyCreate={applyCreate}
					onSuccess={onClose}
					disableServiceSubscription
				/>
			</Modal.Body>
		</Modal>
	);
}

type ContractInstallmentTableRowProps = {
	installment: StudentContractsPaymentProgressListQuery["contractConnection"]["edges"][0]["node"]["contractInstallments"][0];
	contract: Pick<Contract, "id" | "agreementDate" | "status">;
	totalPrice: number;
	totalPortion: number;
};

function ContractInstallmentTableRow({
	installment,
	contract,
	totalPrice,
	totalPortion,
}: ContractInstallmentTableRowProps) {
	const { isOn: isOpen, toggle: toggleIsOpen } = useToggle(false);

	const isEditable = ContractFormValues.isContractEditable(contract.status);
	const installmentWithContract = React.useMemo(() => ({ ...installment, contract }), [installment, contract]);

	return (
		<BasicTable.Row>
			<BasicTable.Cell>{formatDateTime(installment.installmentDate, "D MMMM YYYY")}</BasicTable.Cell>
			<BasicTable.Cell>
				<strong>
					{ServiceSubscriptionFormat.Fields.price((totalPrice * (installment.portion ?? 0)) / totalPortion)}
				</strong>
			</BasicTable.Cell>
			<If condition={isEditable}>
				<BasicTable.Cell>
					<Button variant="link" size="small" onClick={toggleIsOpen}>
						Edit
					</Button>
				</BasicTable.Cell>
			</If>

			<Modal.If condition={isOpen}>
				<ContractInstallmentEditModal contractInstallment={installmentWithContract} onClose={toggleIsOpen} />
			</Modal.If>
		</BasicTable.Row>
	);
}

type ContractInstallmentEditModalProps = {
	contractInstallment: ContractInstallmentDetailQuery["contractInstallment"];
} & RequiredKeys<Pick<ModalProps, "ifRef" | "onClose">, "onClose">;

function ContractInstallmentEditModal({ contractInstallment, ifRef, onClose }: ContractInstallmentEditModalProps) {
	const initialFormValues = React.useMemo(
		() => ContractInstallmentFormConversion.toFormValues(contractInstallment),
		[contractInstallment],
	);
	const formState = ContractInstallmentFormState.useDetailFormState({ initialFormValues });

	const update = ContractInstallmentMutation.useUpdate(contractInstallment.id);
	const applyUpdate = React.useCallback(
		async (
			changedFormValues: Partial<ContractInstallmentFormValues.Detail>,
			initialFormValues: ContractInstallmentFormValues.Detail,
		) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<Modal ifRef={ifRef} onClose={onClose} confirmOnClose={anyFieldChanged(formState)} style={{ width: "fit-content" }}>
			<Modal.Header>
				<Heading level={2} noMargin>
					Edit Contract Installment
				</Heading>
			</Modal.Header>
			<Modal.Body>
				<ContractInstallmentForm.Detail
					contractInstallment={contractInstallment}
					formState={formState}
					applyUpdate={applyUpdate}
					onSuccess={onClose}
					hideInvoice
				/>
			</Modal.Body>
			<Modal.Footer>
				<Row justify="center">
					<ContractInstallmentDeleteButton instanceID={contractInstallment.id} onSuccess={onClose} size="small" />
				</Row>
			</Modal.Footer>
		</Modal>
	);
}

type ContractInstallmentCreateControllerProps = {
	contractID: Contract["id"];
};

function ContractInstallmentCreateController({ contractID }: ContractInstallmentCreateControllerProps) {
	const { isOn: isOpen, toggle: toggleIsOpen } = useToggle(false);

	return (
		<React.Fragment>
			<Button variant="secondary" size="small" onClick={toggleIsOpen}>
				<Row justify="center" align="center">
					Add Installment
				</Row>
			</Button>

			<Modal.If condition={isOpen}>
				<ContractInstallmentCreateModal contractID={contractID} onClose={toggleIsOpen} />
			</Modal.If>
		</React.Fragment>
	);
}

type ContractInstallmentCreateModalProps = {
	contractID: Contract["id"];
} & RequiredKeys<Pick<ModalProps, "ifRef" | "onClose">, "onClose">;

function ContractInstallmentCreateModal({ contractID, ifRef, onClose }: ContractInstallmentCreateModalProps) {
	const initialFormValues = React.useMemo(() => ({ contractID }), [contractID]);
	const formState = ContractInstallmentFormState.useCreateFormState(initialFormValues);

	const create = ContractInstallmentMutation.useCreate();
	const applyCreate = React.useCallback(
		async (formValues: ContractInstallmentFormValues.Create) => {
			const { errors } = await create(formValues);
			return errors;
		},
		[create],
	);

	return (
		<Modal ifRef={ifRef} onClose={onClose} confirmOnClose={anyFieldChanged(formState)} style={{ width: "fit-content" }}>
			<Modal.Header>
				<Heading level={2} noMargin>
					New Contract Installment
				</Heading>
			</Modal.Header>
			<Modal.Body>
				<ContractInstallmentForm.Create
					formState={formState}
					applyCreate={applyCreate}
					onSuccess={onClose}
					disableContract
				/>
			</Modal.Body>
		</Modal>
	);
}

type DisplayFieldProps = {
	label: string;
} & Partial<StyleProps & ChildrenProps>;

function DisplayField({ label, style, children }: DisplayFieldProps) {
	return (
		<Column style={style}>
			<span style={{ fontSize: "0.9rem" }}>{label}</span>
			<div style={{ fontSize: "1.1rem" }}>{children}</div>
		</Column>
	);
}

type ServiceSubscriptionPriceInfo = Pick<ServiceSubscription, "price"> & {
	discounts: Pick<ServiceSubscription["discounts"][0], "amount">[];
};

function getContractTotalPrice(contract: { serviceSubscriptions: ServiceSubscriptionPriceInfo[] }) {
	let total = 0;
	for (let i = 0; i < contract.serviceSubscriptions.length; i++) {
		total += getServiceSubscriptionTotalPrice(contract.serviceSubscriptions[i]);
	}
	return total;
}

function getServiceSubscriptionTotalPrice(serviceSubscription: ServiceSubscriptionPriceInfo) {
	return serviceSubscription.price - serviceSubscription.discounts.reduce((a, e) => a + e.amount, 0);
}

type NewContractModalProps = {
	studentID: Student["id"];
	onSuccess: () => void;
} & Pick<ModalProps, "ifRef" | "onClose">;

function NewContractModal({ studentID, onSuccess, ifRef, onClose }: NewContractModalProps) {
	const initialFormValues = React.useMemo(
		() => ({ studentID, agreementDate: toLocalDateString(new Date()) }),
		[studentID],
	);
	const formState = ContractFormState.useCreateFormState(initialFormValues);

	const create = ContractMutation.useCreate();
	const applyCreate = React.useCallback(
		async (formValues: ContractFormValues.Create) => {
			const { errors } = await create(formValues);
			return errors;
		},
		[create],
	);

	return (
		<Modal
			ifRef={ifRef}
			onClose={onClose}
			confirmOnClose={anyFieldChanged(formState)}
			style={{ width: "fit-content", maxWidth: "90vw" }}
		>
			<Modal.Body>
				<ContractForm.Create
					formState={formState}
					applyCreate={applyCreate}
					onSuccess={onSuccess}
					formNameSuffix={`.${studentID}`}
				/>
			</Modal.Body>
		</Modal>
	);
}

type NewServiceSubscriptionsModalProps = {
	studentID: Student["id"];
	contractID: Contract["id"];
	onSuccess: () => void;
} & Pick<ModalProps, "ifRef" | "onClose">;

function NewServiceSubscriptionsModal({
	studentID,
	contractID,
	onSuccess,
	ifRef,
	onClose,
}: NewServiceSubscriptionsModalProps) {
	const create = useServiceSubscriptionBulkCreate();
	const applyCreate = React.useCallback(
		async (formValues: ServiceSubscriptionFormValues.CreateBulkForContract) => {
			const formValuesSets: ServiceSubscriptionFormValues.Create[] = [];
			for (let i = 0; i < formValues.serviceSubscriptions.length; i++) {
				formValuesSets.push({ ...formValues.serviceSubscriptions[i], contractID: formValues.contractID });
			}
			const { errors } = await create(formValuesSets);
			return errors;
		},
		[create],
	);

	const formState = ServiceSubscriptionFormState.useCreateBulkForContractFormState(studentID);
	const { contractID: setContractID } = formState.formSetFunctions;
	React.useEffect(() => {
		setContractID(contractID);
	}, [contractID, setContractID]);

	return (
		<Modal
			ifRef={ifRef}
			onClose={onClose}
			confirmOnClose={anyFieldChanged(formState)}
			style={{ width: "fit-content", "--modal---max-width": "none" } as React.CSSProperties}
		>
			<Modal.Header>
				<Heading level={2} noMargin>
					New Billing Items
				</Heading>
			</Modal.Header>
			<Modal.Body>
				<ServiceSubscriptionForm.CreateBulkForContract
					applyCreate={applyCreate}
					onSuccess={onSuccess}
					formState={formState}
					formNameSuffix={`.${studentID}.${contractID}`}
					contractIDDisabled
				/>
			</Modal.Body>
		</Modal>
	);
}
