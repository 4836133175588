import React from "react";
import {
	Button,
	Column,
	formatDateTime,
	Heading,
	If,
	makeClassName,
	Row,
	stringToLocalDate,
	useUpdateEffect,
} from "@hex-insights/core";
import {
	InternalLink,
	Route,
	RouteSwitch,
	useActivePageRegistration,
	useHistory,
	useLocation,
	useRouteMatch,
	useRouteParams,
} from "@hex-insights/router";
import {
	CourseSectionFormat,
	getWeekDateRangeForCourseSection,
	LessonPlanIndexQuery,
	Main,
	ScaledText,
	Tile,
	useCourseSectionDetailQuery,
} from "@hex-insights/verita.shared";
import { CourseResourcePreviewTile, CourseResourcesTile } from "./Components";
import { LessonPlansCourseSectionWeekDayPage } from "./Day";
import {
	lessonPlansWeekCourseSectionDayPageInfo,
	LessonPlansWeekCourseSectionDayPageRouteParams,
} from "./Day/pageinfo";
import { LessonPlansCourseSectionWeekIndexPage } from "./IndexPage";
import { lessonPlansWeekCourseSectionIndexPageInfo } from "./IndexPage/pageinfo";
import { lessonPlansWeekCourseSectionPageInfo, LessonPlansWeekCourseSectionPageRouteParams } from "./pageinfo";
import { usePageWeekLessonPlanIndexQuery } from "./utils";
import styles from "./styles.module.css";

export function LessonPlansWeekCourseSectionPage() {
	const { courseSectionID } = useRouteParams<LessonPlansWeekCourseSectionPageRouteParams>();
	const { data: courseSectionData } = useCourseSectionDetailQuery({ variables: { id: courseSectionID } });
	useActivePageRegistration(
		lessonPlansWeekCourseSectionPageInfo,
		courseSectionData ? lessonPlansWeekCourseSectionPageInfo.title(courseSectionData.courseSection) : "",
	);

	const [overlayState, setOverlayState] = React.useState({ show: false, nextTo: "", nextText: "" });

	const onNextReady = React.useCallback((nextTo: string, nextText: string) => {
		setOverlayState({ show: true, nextTo, nextText });
	}, []);

	const onCloseOverlay = React.useCallback(() => {
		setOverlayState({ show: false, nextTo: "", nextText: "" });
	}, []);

	const history = useHistory();
	const onClickNext = React.useCallback(() => {
		history.push(overlayState.nextTo);
		onCloseOverlay();
	}, [history, overlayState, onCloseOverlay]);

	const { pathname } = useLocation();
	useUpdateEffect(() => {
		onCloseOverlay();
	}, [pathname, onCloseOverlay]);

	return (
		<Main>
			<Column
				justify="spaced-start"
				style={{
					height: "calc(100vh - var(--header---height) - 2 * var(--main---padding-top))",
				}}
			>
				<HeaderTile />

				<Row
					justify="spaced-start"
					style={{ width: "100%", height: "100%", flexGrow: 1, minHeight: 0, position: "relative" }}
				>
					<RouteSwitch>
						<Route {...lessonPlansWeekCourseSectionIndexPageInfo.routeProps}>
							<Row justify="center" align="center" className={styles["index-blur-overlay"]}>
								<div className={styles["index-blur-overlay__content"]}>Select a day above to get started</div>
							</Row>

							<LessonPlansCourseSectionWeekIndexPage />
						</Route>
						<Route {...lessonPlansWeekCourseSectionDayPageInfo.routeProps}>
							<LessonPlansCourseSectionWeekDayPage
								key={
									useRouteMatch<LessonPlansWeekCourseSectionDayPageRouteParams>(
										lessonPlansWeekCourseSectionDayPageInfo.path as string,
									)?.params.dow
								}
								onNextReady={onNextReady}
							/>
						</Route>
					</RouteSwitch>

					<Column justify="spaced-start" style={{ width: "100%", minWidth: 0, height: "100%", position: "relative" }}>
						<If condition={overlayState.show}>
							<Row justify="center" align="center" className={styles["next-overlay"]}>
								<Row justify="spaced-start" align="center" className={styles["next-overlay__content"]}>
									<Button variant="tertiary" onClick={onCloseOverlay}>
										Close
									</Button>

									<Button variant="success" onClick={onClickNext}>
										{overlayState.nextText}
									</Button>
								</Row>
							</Row>
						</If>

						<CourseResourcePreviewTile style={{ flexGrow: 1, height: "100%", overflow: "scroll" }} />
						<CourseResourcesTile />
					</Column>
				</Row>
			</Column>
		</Main>
	);
}

function HeaderTile() {
	const { courseSectionID, date: weekStartDate } = useRouteParams<LessonPlansWeekCourseSectionPageRouteParams>();
	const { loading: loadingCourseSectionData, data: courseSectionData } = useCourseSectionDetailQuery({
		variables: { id: courseSectionID },
	});

	const { loading: loadingLessonPlanData, data: lessonPlansData } = usePageWeekLessonPlanIndexQuery();
	const dateLessonPlanMap = React.useMemo(() => {
		if (!lessonPlansData) {
			return {};
		}
		const map = {};
		for (let i = 0; i < lessonPlansData.lessonPlanConnection.edges.length; i++) {
			const lessonPlan = lessonPlansData.lessonPlanConnection.edges[i].node;
			map[lessonPlan.courseDate] = lessonPlan;
		}
		return map;
	}, [lessonPlansData]);

	const dateRange = React.useMemo(() => {
		if (!courseSectionData) {
			return [];
		}
		const { dateRange } = getWeekDateRangeForCourseSection(
			courseSectionData.courseSection.courseSectionPeriods,
			stringToLocalDate(weekStartDate, "day"),
		);
		return dateRange;
	}, [weekStartDate, courseSectionData]);

	return (
		<Tile>
			<Tile.Body style={{ padding: "0.5rem 1.5rem" }}>
				<Row justify="spaced-start" align="center">
					<Column>
						<Heading level={1} noMargin>
							<ScaledText maxFontSize={32} style={{ width: "15rem", maxHeight: "4rem" }}>
								{loadingCourseSectionData || !courseSectionData
									? "Loading..."
									: CourseSectionFormat.name(courseSectionData.courseSection)}
							</ScaledText>
						</Heading>

						<span>Week of {formatDateTime(stringToLocalDate(weekStartDate + "T00:00:00Z", "day"), "D MMMM YYYY")}</span>
					</Column>

					<Row className={styles["header-tile__lesson-plans-row"]}>
						{dateRange.map((e) => (
							<HeaderLessonPlan
								key={e}
								date={e}
								isLoading={loadingLessonPlanData}
								lessonPlan={dateLessonPlanMap[e] ?? null}
							/>
						))}
					</Row>
				</Row>
			</Tile.Body>
		</Tile>
	);
}

type HeaderLessonPlanProps = {
	date: string;
	isLoading: boolean;
	lessonPlan: LessonPlanIndexQuery["lessonPlanConnection"]["edges"][0]["node"] | null;
};

function HeaderLessonPlan({ date }: HeaderLessonPlanProps) {
	const { courseSectionID, date: weekStartDate } = useRouteParams<LessonPlansWeekCourseSectionPageRouteParams>();

	const lessonPlanDOW = new Date(date).getDay();
	const currentPageDOW = Number(
		useRouteMatch<LessonPlansWeekCourseSectionDayPageRouteParams>(
			lessonPlansWeekCourseSectionDayPageInfo.path as string,
		)?.params.dow ?? -1,
	);
	const isActive = lessonPlanDOW === currentPageDOW;

	const dayPageTo = lessonPlansWeekCourseSectionDayPageInfo.to(weekStartDate, courseSectionID, lessonPlanDOW);

	return (
		<Column
			justify="space-between"
			align="center"
			className={makeClassName(
				styles["header-tile__lesson-plan"],
				isActive ? styles["header-tile__lesson-plan--active"] : "",
			)}
		>
			<InternalLink to={dayPageTo} className={styles["header-tile__lesson-plan__heading"]}>
				<span>{formatDateTime(date, "dddd")}</span>
			</InternalLink>
		</Column>
	);
}
