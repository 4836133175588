import React from "react";
import { ClassNameProps, useUpdateEffect } from "@hex-insights/core";
import {
	DateTimeField,
	FieldDisplayArgs,
	FormType,
	MultiSelectField,
	RadioButtonsInput,
	RadioField,
	SelectField,
} from "@hex-insights/forms";
import {
	ContractFormValues,
	ContractInstallmentFormValues,
	ContractInstallmentSelect,
	ContractStatus,
	ServiceSubscriptionSelect,
	StudentSelect,
	useContractInstallmentSelectLazyQuery,
	useServiceSubscriptionSelectLazyQuery,
	useStudentSelectLazyQuery,
} from "../../../../Utilities";
import { ContractInstallmentLink, ServiceSubscriptionLink, StudentLink } from "../../../Links";
import { BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the Contract model.
 */
type FieldProps<K extends keyof ContractFormValues.Base = keyof ContractFormValues.Base> = BaseFieldProps<
	Pick<ContractFormValues.Base, K>
>;

/**
 * Generic props for fields of the Contract model that only appear in the create form.
 */
type CreateFieldProps<K extends keyof ContractFormValues.Create = keyof ContractFormValues.Create> = BaseFieldProps<
	Pick<ContractFormValues.Create, K>
>;

/**
 * Generic props for fields of the Contract model that only appear in the detail form.
 */
type DetailFieldProps<K extends keyof ContractFormValues.Detail = keyof ContractFormValues.Detail> = BaseFieldProps<
	Pick<ContractFormValues.Detail, K>
>;

/**
 * Renders a field component for the `agreementDate` field of the Contract model.
 */
export function AgreementDate({ formState }: DetailFieldProps<"agreementDate">) {
	return <DateTimeField formState={formState} name="agreementDate" precision="day" />;
}

/**
 * Renders a field component for the `status` field of the Contract model.
 */
export function Status({ formState }: DetailFieldProps<"status">) {
	const wasInitiallyDraft = formState.initialFormValues.status === ContractStatus.Draft;
	const options = wasInitiallyDraft ? ContractFormValues.statusOptions : ContractFormValues.statusOptionsAfterDraft;
	let hint = "The status of the contract controls how billing is shown and if changes can be made.";
	if (wasInitiallyDraft) {
		hint +=
			" A contract becomes locked to further changes once it is moved out of Draft status. Once a contract has been moved out of Draft status, it cannot be moved back.";
	}

	return (
		<RadioField
			formState={formState}
			name="status"
			hint={hint}
			options={options}
			blankValue={null}
			optional={FormType.isCreate(formState.formType)}
		/>
	);
}

/**
 * Renders a field component for the `paymentScheduleType` field of the Contract model.
 */
export function PaymentScheduleType({
	formState,
	className,
}: CreateFieldProps<"paymentScheduleType" | "contractInstallments"> & Partial<ClassNameProps>) {
	const { paymentScheduleType } = formState.formValues;
	const { contractInstallments: setContractInstallments } = formState.formSetFunctions;

	useUpdateEffect(() => {
		switch (paymentScheduleType) {
			case ContractFormValues.PaymentScheduleType.FourPayments:
				setContractInstallments([
					ContractInstallmentFormValues.initialCreateInContract,
					ContractInstallmentFormValues.initialCreateInContract,
					ContractInstallmentFormValues.initialCreateInContract,
					ContractInstallmentFormValues.initialCreateInContract,
				]);
				break;
			case ContractFormValues.PaymentScheduleType.TwoPayments:
				setContractInstallments([
					ContractInstallmentFormValues.initialCreateInContract,
					ContractInstallmentFormValues.initialCreateInContract,
				]);
				break;
			case ContractFormValues.PaymentScheduleType.OnePayment:
				setContractInstallments([ContractInstallmentFormValues.initialCreateInContract]);
				break;
		}
	}, [paymentScheduleType, setContractInstallments]);

	return (
		<RadioField
			formState={formState}
			name="paymentScheduleType"
			options={ContractFormValues.paymentScheduleTypeOptions}
			blankValue={null}
			noClear
			Input={RadioButtonsInput}
			className={className}
		/>
	);
}

export type ContractInstallmentsProps = DetailFieldProps<"contractInstallmentIDs"> & {
	currentContractInstallments?: ContractInstallmentSelect.ModelForOption[];
};

/**
 * Renders a field component for the `contractInstallments` edge of the Contract model.
 */
export function ContractInstallments({ formState, currentContractInstallments }: ContractInstallmentsProps) {
	const [loadOptions, { loading, data }] = useContractInstallmentSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.contractInstallmentIDs) {
			loadOptions();
		}
	}, [formState.formEditing.contractInstallmentIDs, loadOptions]);
	const options = React.useMemo(
		() =>
			ContractInstallmentSelect.toMultiOptions(data?.contractInstallmentConnection.edges, currentContractInstallments),
		[data, currentContractInstallments],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="contractInstallmentIDs"
			isLoading={loading}
			options={options}
			displayInstance={displayContractInstallmentInstance}
		/>
	);
}

function displayContractInstallmentInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <ContractInstallmentLink instance={{ id }}>{formattedValue}</ContractInstallmentLink>;
}

export type ServiceSubscriptionsProps = DetailFieldProps<"serviceSubscriptionIDs"> & {
	currentServiceSubscriptions?: ServiceSubscriptionSelect.ModelForOption[];
};

/**
 * Renders a field component for the `serviceSubscriptions` edge of the Contract model.
 */
export function ServiceSubscriptions({ formState, currentServiceSubscriptions }: ServiceSubscriptionsProps) {
	const [loadOptions, { loading, data }] = useServiceSubscriptionSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.serviceSubscriptionIDs) {
			loadOptions();
		}
	}, [formState.formEditing.serviceSubscriptionIDs, loadOptions]);
	const options = React.useMemo(
		() =>
			ServiceSubscriptionSelect.toMultiOptions(data?.serviceSubscriptionConnection.edges, currentServiceSubscriptions),
		[data, currentServiceSubscriptions],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="serviceSubscriptionIDs"
			isLoading={loading}
			options={options}
			displayInstance={displayServiceSubscriptionInstance}
		/>
	);
}

function displayServiceSubscriptionInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <ServiceSubscriptionLink instance={{ id }}>{formattedValue}</ServiceSubscriptionLink>;
}

export type StudentProps = FieldProps<"studentID"> & {
	currentStudent?: StudentSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `student` edge of the Contract model.
 */
export function Student({ formState, currentStudent }: StudentProps) {
	const [loadOptions, { loading, data }] = useStudentSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.studentID) {
			loadOptions();
		}
	}, [formState.formEditing.studentID, loadOptions]);
	const options = React.useMemo(
		() => StudentSelect.toOptions(data?.studentConnection.edges, currentStudent),
		[data, currentStudent],
	);

	return (
		<SelectField
			formState={formState}
			name="studentID"
			isLoading={loading}
			options={options}
			display={displayStudent}
			blankValue={null}
		/>
	);
}

function displayStudent({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <StudentLink instance={{ id }}>{formattedValue}</StudentLink>;
}
