import { getArrayDiff, NullPartial, omitKeys } from "@hex-insights/core";
import { CalendarEventCreateInput, CalendarEventDetailQuery, CalendarEventUpdateInput } from "../../GraphQL";
import { CalendarEventFormValues } from "../ModelFormValues";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the CalendarEvent model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(formValues: CalendarEventFormValues.Create): CalendarEventCreateInput {
	const input: NullPartial<CalendarEventCreateInput> = { ...formValues };
	return input as CalendarEventCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the CalendarEvent model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<CalendarEventFormValues.Detail>,
	initialFormValues: CalendarEventFormValues.Detail,
): CalendarEventUpdateInput {
	const inputFormValues = omitKeys(formValues, ["createdAt", "authorID", "sharingUserGroupIDs"]);
	const input: CalendarEventUpdateInput = { ...inputFormValues };

	if (formValues.sharingUserGroupIDs !== undefined) {
		const { added, removed } = getArrayDiff(initialFormValues.sharingUserGroupIDs, formValues.sharingUserGroupIDs);
		input.addSharingUserGroupIDs = added;
		input.removeSharingUserGroupIDs = removed;
	}

	return input;
}

/**
 * Converts a CalendarEvent instance to its corresponding detail form values.
 *
 * @param calendarEvent The instance to convert.
 */
export function toFormValues(calendarEvent: CalendarEventDetailQuery["calendarEvent"]): CalendarEventFormValues.Detail {
	return {
		name: calendarEvent.name,
		description: calendarEvent.description,
		isAllDay: calendarEvent.isAllDay,
		startTime: calendarEvent.startTime,
		endTime: calendarEvent.endTime,
		isPublic: calendarEvent.isPublic,
		createdAt: calendarEvent.createdAt,
		authorID: calendarEvent.author?.id ?? null,
		sharingUserGroupIDs: calendarEvent.sharingUserGroups.map((e) => e.id),
	};
}
