import { IndexForms } from "@hex-insights/app-modules";
import { CaseStyle, convertCase } from "@hex-insights/core";
import { EnrollmentApplicationParentOrderField, OrderDirection } from "../../GraphQL";

export type FormValues = IndexForms.OrderFormValues<EnrollmentApplicationParentOrderField | null>;

export const initial: FormValues = {
	field: EnrollmentApplicationParentOrderField.FirstName,
	direction: OrderDirection.Asc,
};

/**
 * Options for the "field" field in the order form of the EnrollmentApplicationParent model.
 */
export const fieldOptions = [
	{ label: "ID", value: null },
	{
		label: convertCase(EnrollmentApplicationParentOrderField.FirstName.toLowerCase(), CaseStyle.Title),
		value: EnrollmentApplicationParentOrderField.FirstName,
	},
	{
		label: convertCase(EnrollmentApplicationParentOrderField.LastName.toLowerCase(), CaseStyle.Title),
		value: EnrollmentApplicationParentOrderField.LastName,
	},
	{
		label: convertCase(EnrollmentApplicationParentOrderField.Nationality.toLowerCase(), CaseStyle.Title),
		value: EnrollmentApplicationParentOrderField.Nationality,
	},
	{
		label: convertCase(EnrollmentApplicationParentOrderField.Occupation.toLowerCase(), CaseStyle.Title),
		value: EnrollmentApplicationParentOrderField.Occupation,
	},
	{
		label: convertCase(EnrollmentApplicationParentOrderField.RelationshipType.toLowerCase(), CaseStyle.Title),
		value: EnrollmentApplicationParentOrderField.RelationshipType,
	},
	{
		label: convertCase(EnrollmentApplicationParentOrderField.RelationshipTypeCustom.toLowerCase(), CaseStyle.Title),
		value: EnrollmentApplicationParentOrderField.RelationshipTypeCustom,
	},
	{
		label: convertCase(EnrollmentApplicationParentOrderField.IsPrimaryContact.toLowerCase(), CaseStyle.Title),
		value: EnrollmentApplicationParentOrderField.IsPrimaryContact,
	},
	{
		label: convertCase(EnrollmentApplicationParentOrderField.LivesWithStudent.toLowerCase(), CaseStyle.Title),
		value: EnrollmentApplicationParentOrderField.LivesWithStudent,
	},
	{
		label: convertCase(EnrollmentApplicationParentOrderField.IsAuthorizedForPickup.toLowerCase(), CaseStyle.Title),
		value: EnrollmentApplicationParentOrderField.IsAuthorizedForPickup,
	},
	{
		label: convertCase(EnrollmentApplicationParentOrderField.IsEmergencyContact.toLowerCase(), CaseStyle.Title),
		value: EnrollmentApplicationParentOrderField.IsEmergencyContact,
	},
	{
		label: convertCase(EnrollmentApplicationParentOrderField.IsFinancialContact.toLowerCase(), CaseStyle.Title),
		value: EnrollmentApplicationParentOrderField.IsFinancialContact,
	},
];

/**
 * Array of values for the "field" field in the order form of the EnrollmentApplicationParent model.
 */
export const fieldValues = fieldOptions.map((e) => e.value);

/**
 * Mapping from order fields of the EnrollmentApplicationParent model to their corresponding order type.
 */
export const fieldTypeMapping = {
	[EnrollmentApplicationParentOrderField.FirstName]: IndexForms.OrderFieldType.String,
	[EnrollmentApplicationParentOrderField.LastName]: IndexForms.OrderFieldType.String,
	[EnrollmentApplicationParentOrderField.Nationality]: IndexForms.OrderFieldType.String,
	[EnrollmentApplicationParentOrderField.Occupation]: IndexForms.OrderFieldType.String,
	[EnrollmentApplicationParentOrderField.RelationshipType]: IndexForms.OrderFieldType.String,
	[EnrollmentApplicationParentOrderField.RelationshipTypeCustom]: IndexForms.OrderFieldType.String,
	[EnrollmentApplicationParentOrderField.IsPrimaryContact]: IndexForms.OrderFieldType.Boolean,
	[EnrollmentApplicationParentOrderField.LivesWithStudent]: IndexForms.OrderFieldType.Boolean,
	[EnrollmentApplicationParentOrderField.IsAuthorizedForPickup]: IndexForms.OrderFieldType.Boolean,
	[EnrollmentApplicationParentOrderField.IsEmergencyContact]: IndexForms.OrderFieldType.Boolean,
	[EnrollmentApplicationParentOrderField.IsFinancialContact]: IndexForms.OrderFieldType.Boolean,
};

/**
 * Mapping from order fields of the EnrollmentApplicationParent model to their corresponding field name.
 */
export const orderFieldToModelField = {
	[EnrollmentApplicationParentOrderField.FirstName]: "firstName",
	[EnrollmentApplicationParentOrderField.LastName]: "lastName",
	[EnrollmentApplicationParentOrderField.Nationality]: "nationality",
	[EnrollmentApplicationParentOrderField.Occupation]: "occupation",
	[EnrollmentApplicationParentOrderField.RelationshipType]: "relationshipType",
	[EnrollmentApplicationParentOrderField.RelationshipTypeCustom]: "relationshipTypeCustom",
	[EnrollmentApplicationParentOrderField.IsPrimaryContact]: "isPrimaryContact",
	[EnrollmentApplicationParentOrderField.LivesWithStudent]: "livesWithStudent",
	[EnrollmentApplicationParentOrderField.IsAuthorizedForPickup]: "isAuthorizedForPickup",
	[EnrollmentApplicationParentOrderField.IsEmergencyContact]: "isEmergencyContact",
	[EnrollmentApplicationParentOrderField.IsFinancialContact]: "isFinancialContact",
};

/**
 * Mapping from field names of the EnrollmentApplicationParent model to their corresponding order field.
 */
export const modelFieldToOrderField = {
	firstName: EnrollmentApplicationParentOrderField.FirstName,
	lastName: EnrollmentApplicationParentOrderField.LastName,
	nationality: EnrollmentApplicationParentOrderField.Nationality,
	occupation: EnrollmentApplicationParentOrderField.Occupation,
	relationshipType: EnrollmentApplicationParentOrderField.RelationshipType,
	relationshipTypeCustom: EnrollmentApplicationParentOrderField.RelationshipTypeCustom,
	isPrimaryContact: EnrollmentApplicationParentOrderField.IsPrimaryContact,
	livesWithStudent: EnrollmentApplicationParentOrderField.LivesWithStudent,
	isAuthorizedForPickup: EnrollmentApplicationParentOrderField.IsAuthorizedForPickup,
	isEmergencyContact: EnrollmentApplicationParentOrderField.IsEmergencyContact,
	isFinancialContact: EnrollmentApplicationParentOrderField.IsFinancialContact,
};
