import { IndexForms } from "@hex-insights/app-modules";
import { OrderDirection } from "../../GraphQL";

export type FormValues = IndexForms.OrderFormValues;

export const initial: FormValues = {
	field: null,
	direction: OrderDirection.Asc,
};

/**
 * Options for the "field" field in the order form of the NoteAttachment model.
 */
export const fieldOptions = [{ label: "ID", value: null }];

/**
 * Array of values for the "field" field in the order form of the NoteAttachment model.
 */
export const fieldValues = fieldOptions.map((e) => e.value);

/**
 * Mapping from order fields of the NoteAttachment model to their corresponding order type.
 */
export const fieldTypeMapping = {};

/**
 * Mapping from order fields of the NoteAttachment model to their corresponding field name.
 */
export const orderFieldToModelField = {};

/**
 * Mapping from field names of the NoteAttachment model to their corresponding order field.
 */
export const modelFieldToOrderField = {};
