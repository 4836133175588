import React from "react";
import {
	Button,
	Conditional,
	Else,
	Heading,
	Icon,
	If,
	Modal,
	ModalProps,
	RequiredKeys,
	Row,
	Tooltip,
	useToggle,
} from "@hex-insights/core";
import { PermissionCheckMethod, PermissionRequired } from "@hex-insights/permissioning";
import { MealPlanDeleteButton, MealPlanForm, TextAreaFieldDisplay } from "../../../../Components";
import {
	MealPlan,
	MealPlanFormState,
	MealPlanFormValues,
	MealPlanMutation,
	permissions,
	Student,
	useMealPlanDetailQuery,
} from "../../../../Utilities";
import { useStudentMealPlanQuery } from "../Utilities";
import styles from "./styles.module.css";

type StudentMealPlanProps = {
	studentID: Student["id"];
};

export function StudentMealPlan({ studentID }: StudentMealPlanProps) {
	const { loading: loadingMealPlanData, data: mealPlanData } = useStudentMealPlanQuery(studentID);
	const hasMealPlan = (mealPlanData && mealPlanData.mealPlanConnection.edges.length > 0) ?? false;
	const mealPlan = mealPlanData && hasMealPlan ? mealPlanData.mealPlanConnection.edges[0].node : null;

	const { isOn: isCreateModalOpen, toggle: toggleIsCreateModalOpen } = useToggle(false);
	const { isOn: isDetailModalOpen, toggle: toggleIsDetailModalOpen } = useToggle(false);

	return (
		<React.Fragment>
			<Row justify="space-between" align="center" className={styles["general-info-section__item"]}>
				<span className={styles["general-info-section__item__label"]}>Meal Plan</span>
				<span className={styles["general-info-section__item__value"]}>
					<Conditional>
						<If condition={loadingMealPlanData}>...</If>
						<Else>
							<Conditional>
								<If condition={hasMealPlan}>
									{mealPlan && (
										<Button
											variant="link"
											onClick={toggleIsDetailModalOpen}
											title="Click to view and edit"
											className={styles["general-info-section__button--link"]}
										>
											<Row justify="spaced-end" align="center">
												Yes
												<If condition={mealPlan?.notes !== ""}>
													<NoteTooltip
														header="Meal Plan Notes"
														body={<TextAreaFieldDisplay value="" formattedValue={mealPlan?.notes ?? ""} />}
													/>
												</If>
											</Row>
										</Button>
									)}
								</If>
								<Else>
									<Button
										variant="link"
										onClick={toggleIsCreateModalOpen}
										title="Click to add a meal plan"
										className={styles["general-info-section__button--link"]}
									>
										No
									</Button>
								</Else>
							</Conditional>
						</Else>
					</Conditional>
				</span>
			</Row>

			<Modal.If condition={isCreateModalOpen}>
				<MealPlanCreateModal studentID={studentID} onClose={toggleIsCreateModalOpen} />
			</Modal.If>
			<Modal.If condition={isDetailModalOpen}>
				<MealPlanDetailModal mealPlanID={mealPlan?.id ?? "0"} onClose={toggleIsDetailModalOpen} />
			</Modal.If>
		</React.Fragment>
	);
}

type NoteTooltipProps = {
	header?: React.ReactNode | React.ReactNode[];
	body: React.ReactNode | React.ReactNode[];
};

function NoteTooltip({ header, body }: NoteTooltipProps) {
	return (
		<Tooltip.Container style={{ textAlign: "left", fontWeight: "normal" }}>
			<Tooltip>
				{!!header && <Tooltip.Header>{header}</Tooltip.Header>}
				<Tooltip.Body style={{ minWidth: "12rem" }}>{body}</Tooltip.Body>
			</Tooltip>

			<Icon.AlertCircle size="1rem" style={{ color: "var(--danger-color)" }} />
		</Tooltip.Container>
	);
}

type MealPlanCreateModalProps = {
	studentID: Student["id"];
} & RequiredKeys<Pick<ModalProps, "ifRef" | "onClose">, "onClose">;

function MealPlanCreateModal({ studentID, ifRef, onClose }: MealPlanCreateModalProps) {
	const initialFormValues = React.useMemo(() => ({ studentID }), [studentID]);
	const formState = MealPlanFormState.useCreateFormState(initialFormValues);

	const create = MealPlanMutation.useCreate();

	const applyCreate = React.useCallback(
		async (formValues: MealPlanFormValues.Create) => {
			const { errors } = await create(formValues);
			return errors;
		},
		[create],
	);

	return (
		<Modal ifRef={ifRef} onClose={onClose} style={{ width: "fit-content", minWidth: "20rem", maxWidth: "90vw" }}>
			<Modal.Header>
				<Heading level={2} noMargin>
					Add Meal Plan
				</Heading>
			</Modal.Header>
			<Modal.Body>
				<MealPlanForm.Create formState={formState} applyCreate={applyCreate} onSuccess={onClose} />
			</Modal.Body>
		</Modal>
	);
}

const deleteMealPlanPermissions = [permissions.MealPlan.Delete.Admin, permissions.MealPlan.Delete.Basic];

type MealPlanDetailModalProps = {
	mealPlanID: MealPlan["id"];
} & RequiredKeys<Pick<ModalProps, "ifRef" | "onClose">, "onClose">;

function MealPlanDetailModal({ mealPlanID, ifRef, onClose }: MealPlanDetailModalProps) {
	const { loading, data } = useMealPlanDetailQuery({ variables: { id: mealPlanID } });
	const mealPlan = data?.mealPlan;

	const update = MealPlanMutation.useUpdate(mealPlanID);
	const applyUpdate = React.useCallback(
		async (changedFormValues: Partial<MealPlanFormValues.Detail>, initialFormValues: MealPlanFormValues.Detail) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<Modal ifRef={ifRef} onClose={onClose} style={{ width: "fit-content", minWidth: "20rem", maxWidth: "90vw" }}>
			<Modal.Header>
				<Heading level={2} noMargin>
					Meal Plan
				</Heading>
			</Modal.Header>
			<Modal.Body>
				<Conditional>
					<If condition={loading}>Loading...</If>
					<Else>
						{mealPlan && (
							<MealPlanForm.ControlledDetail mealPlan={mealPlan} applyUpdate={applyUpdate} onSuccess={onClose} />
						)}
					</Else>
				</Conditional>
			</Modal.Body>
			<If condition={!!mealPlan}>
				<PermissionRequired requiredPermissions={deleteMealPlanPermissions} checkMethod={PermissionCheckMethod.Or}>
					<Modal.Footer>
						<Row justify="center" align="center">
							<MealPlanDeleteButton instanceID={mealPlanID} onSuccess={onClose} size="small" />
						</Row>
					</Modal.Footer>
				</PermissionRequired>
			</If>
		</Modal>
	);
}
