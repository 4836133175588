import React from "react";
import { Authentication } from "@hex-insights/app-modules";
import { Column, Heading, If, Paragraph, Row } from "@hex-insights/core";
import { Form, SubmissionStatus, SubmitButton, TextField } from "@hex-insights/forms";
import { InternalLink, useActivePageRegistration } from "@hex-insights/router";
import { requestAuthenticationInfo, serverRequest } from "@hex-insights/verita.shared";
import { Main } from "../../../Components";
import { AuthenticationContext } from "../../../Contexts";
import { mfaLoginPageInfo } from "./pageinfo";
import styles from "../authentication.module.css";

export function MFALoginPage() {
	useActivePageRegistration(mfaLoginPageInfo);

	const onSuccess = React.useCallback(() => {}, []);

	return (
		<Main className={styles["auth-page__main"]}>
			<Column justify="spaced-center" align="center" className={styles["auth-page__content-container"]}>
				<Heading level={1}>Multi-Factor Authentication</Heading>

				<div>
					<LoginForm onSuccess={onSuccess} />
				</div>
			</Column>
		</Main>
	);
}

type LoginFormProps = {
	onSuccess: () => void;
};

function LoginForm({ onSuccess }: LoginFormProps) {
	const { formState, onSubmit } = Authentication.useMFALogin({
		AuthenticationContext,
		requestMFALogin,
		onSuccess,
	});

	return (
		<Form formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)} noNotifications noNavigationPrompt>
			<Column justify="spaced-start">
				<If condition={SubmissionStatus.isFailure(formState.submissionStatus)}>
					<Paragraph>{formState.formSubmissionErrors._.join(" ")}</Paragraph>
				</If>

				<TextField formState={formState} name="token" label="MFA Code" autoFocus />

				<Row justify="space-between" align="flex-end">
					<InternalLink to="/logout">Cancel</InternalLink>

					<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
						Login
					</SubmitButton>
				</Row>

				<RequestOTPButton />
			</Column>
		</Form>
	);
}

function requestMFALogin(formValues: Authentication.MFALoginFormValues) {
	return serverRequest("/auth/mfa/login", {
		method: "POST",
		body: JSON.stringify(formValues),
	});
}

function RequestOTPButton() {
	return (
		<Authentication.RequestOTPButton requestAuthenticationInfo={requestAuthenticationInfo} requestOTP={requestOTP} />
	);
}

async function requestOTP() {
	const response = await serverRequest("/auth/mfa/request-otp", { method: "POST" });
	return response.ok;
}
