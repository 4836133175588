import { newPageInfo } from "@hex-insights/router";
import { reportCardsPageInfo, ReportCardsPageRouteParams } from "../pageinfo";

export type ReportCardsIndexPageRouteParams = ReportCardsPageRouteParams;

export const reportCardsIndexPageInfo = newPageInfo({
	name: "Report Cards Index Page",
	title: "",
	description: "Report cards overview.",
	exact: true,
	to: reportCardsPageInfo.to,
	path: reportCardsPageInfo.path,
	parent: reportCardsPageInfo,
});
