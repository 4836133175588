import { Course, CourseNameFragment, CourseSubject } from "../../../GraphQL";
import * as HomeRoomFormat from "../HomeRoom";
import * as Fields from "./fields";

export { Fields };

export const courseSubjectColors = {
	[CourseSubject.Art]: "var(--verita-colors-pink)",
	[CourseSubject.Core]: "var(--verita-colors-orange)",
	[CourseSubject.Ell]: "var(--verita-colors-yellow)",
	[CourseSubject.ForeignLanguage]: "var(--verita-colors-yellow)",
	[CourseSubject.Ipc]: "var(--verita-colors-green)",
	[CourseSubject.LanguageArts]: "var(--verita-colors-yellow)",
	[CourseSubject.Math]: "var(--verita-colors-teal)",
	[CourseSubject.Music]: "var(--verita-colors-pink)",
	[CourseSubject.Pe]: "var(--verita-colors-pink)",
	[CourseSubject.Reading]: "var(--verita-colors-yellow)",
	[CourseSubject.Science]: "var(--verita-colors-teal)",
	[CourseSubject.SelTime]: "var(--verita-colors-pink)",
	[CourseSubject.Technology]: "var(--verita-colors-teal)",
};

export const specialtySubjects = new Set([
	CourseSubject.Art,
	CourseSubject.ForeignLanguage,
	CourseSubject.Pe,
	CourseSubject.Technology,
]);

/**
 * Formats the full name of the given instance.
 */
export function name(instance: CourseNameFragment) {
	if (specialtySubjects.has(instance.subject)) {
		return nameWithHomeRoomName(instance);
	}
	return Fields.name(instance.name);
}

export function nameWithHomeRoomName(instance: CourseNameFragment) {
	return `${Fields.name(instance.name)} (${HomeRoomFormat.name(instance.homeRoom)})`;
}

export function color(instance: Pick<Course, "subject">) {
	return courseSubjectColors[instance.subject];
}
