import { Column, formatDateTime, Heading, Row } from "@hex-insights/core";
import { ScaledText } from "../ScaledText";

export type BigDateDisplayProps = {
	date?: Date;
};

export function BigDateDisplay({ date = new Date() }: BigDateDisplayProps) {
	return (
		<Heading level={1} noMargin style={{ color: "var(--verita-blue)", height: "100%", overflow: "visible" }}>
			<Column>
				<ScaledText style={{ lineHeight: 1, width: "9.5rem", height: "2rem" }} maxFontSize={40}>
					{formatDateTime(date, "dddd")}
				</ScaledText>
				<Row align="center" style={{ width: "100%", height: "5rem" }}>
					<ScaledText
						style={{
							writingMode: "vertical-lr",
							lineHeight: 1,
							color: "#aaa",
							height: "100%",
							width: "1.5rem",
						}}
						maxFontSize={24}
					>
						{formatDateTime(date, "MMMM")}
					</ScaledText>
					<div
						style={{
							fontSize: "8rem",
							lineHeight: 1,
							opacity: 0.2,
							paddingTop: "0.5rem",
						}}
					>
						{formatDateTime(date, "D")}
					</div>
				</Row>
			</Column>
		</Heading>
	);
}
