import { ClassNameProps, StyleProps } from "@hex-insights/core";

type BigIconName = "plus" | "notepad" | "image";

type BigIconProps = {
	name: BigIconName;
} & SpecificBigIconProps;

export function BigIcon({ name, ...props }: BigIconProps) {
	switch (name) {
		case "plus":
			return <BigIconPlus {...props} />;
		case "notepad":
			return <BigIconNotepad {...props} />;
		case "image":
			return <BigIconImage {...props} />;
		default:
			return null;
	}
}

type SpecificBigIconProps = {
	size?: number;
	width?: number;
	height?: number;
} & Partial<ClassNameProps & StyleProps>;

function BigIconPlus({ size = 24, width, height, style, className }: SpecificBigIconProps) {
	return (
		<svg
			version="1.1"
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
			style={size || width || height ? { ...style, width: width ?? size, height: height ?? size } : style}
		>
			<path
				d="M12 1C5.9 1 1 5.9 1 12s4.9 11 11 11 11-4.9 11-11S18.1 1 12 1zm5 13h-3v3c0 1.1-.9 2-2 2s-2-.9-2-2v-3H7c-1.1 0-2-.9-2-2s.9-2 2-2h3V7c0-1.1.9-2 2-2s2 .9 2 2v3h3c1.1 0 2 .9 2 2s-.9 2-2 2z"
				fill="#59d992"
			></path>
		</svg>
	);
}

function BigIconNotepad({ size = 24, width, height, style, className }: SpecificBigIconProps) {
	return (
		<svg
			version="1.1"
			viewBox="0 0 32 32"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
			style={size || width || height ? { ...style, width: width ?? size, height: height ?? size } : style}
		>
			<path
				d="M21.7 4.94V3.65A1.65 1.65 0 0 0 20.06 2h-14a1.65 1.65 0 0 0-1.65 1.65v19.76a1.65 1.65 0 0 0 1.65 1.65h2.23V8.94a4 4 0 0 1 4-4Z"
				fill="var(--verita-colors-yellow)"
			></path>
			<path
				d="M25.59 6.94h-13.3a2 2 0 0 0-2 2V28a2 2 0 0 0 2 2h13.3a2 2 0 0 0 2-2V8.94a2 2 0 0 0-2-2Zm-.82 12.53h-4.83v4.83a1 1 0 0 1-2 0v-4.83h-4.83a1 1 0 0 1 0-2h4.83v-4.83a1 1 0 0 1 .75-1 .69.69 0 0 1 .25 0 1 1 0 0 1 1 1v4.83h4.83a1 1 0 0 1 0 2Z"
				fill="var(--verita-colors-yellow)"
			></path>
		</svg>
	);
}

function BigIconImage({ size = 24, width, height, style, className }: SpecificBigIconProps) {
	return (
		<svg
			version="1.1"
			viewBox="0 0 512 512"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
			style={size || width || height ? { ...style, width: width ?? size, height: height ?? size } : style}
		>
			<path
				d="M152 120c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.5-48-48-48zm295.1-88h-384C28.65 32-.01 60.65-.01 96v320c0 35.35 28.65 64 63.1 64h384c35.35 0 64-28.65 64-64V96c.01-35.35-27.79-64-63.99-64zm16 377.3L326.3 223.4c-2.5-4.6-8.2-7.4-14.3-7.4-6.113 0-11.82 2.768-15.21 7.379l-106.6 144.1-37.09-46.1c-3.441-4.279-8.934-6.809-14.77-6.809-5.842 0-11.33 2.529-14.78 6.809l-75.52 93.81c0-.03 0 .03 0 0L47.99 96c0-8.822 7.178-16 16-16h384c8.822 0 16 7.178 16 16v313.3z"
				fill="#ed8e11"
			></path>
		</svg>
	);
}
