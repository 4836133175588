import { ClassNameProps, StyleProps } from "@hex-insights/core";

export type HRProps = {
	color?: string;
	size?: string;
} & Partial<ClassNameProps & StyleProps>;

export function HR({ color = "#888", size = "1px", className, style }: HRProps) {
	return (
		<hr
			className={className}
			style={{
				borderBottom: 0,
				borderRight: 0,
				borderTop: `${size} solid ${color}`,
				borderLeft: `${size} solid ${color}`,
				borderColor: color,
				padding: 0,
				display: "block",
				...style,
			}}
		/>
	);
}
