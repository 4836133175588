import { IndexForms } from "@hex-insights/app-modules";
import { CaseStyle, convertCase } from "@hex-insights/core";
import { MealMenuOrderField, OrderDirection } from "../../GraphQL";

export type FormValues = IndexForms.OrderFormValues<MealMenuOrderField | null>;

export const initial: FormValues = {
	field: MealMenuOrderField.MenuDate,
	direction: OrderDirection.Desc,
};

/**
 * Options for the "field" field in the order form of the MealMenu model.
 */
export const fieldOptions = [
	{ label: "ID", value: null },
	{
		label: convertCase(MealMenuOrderField.MenuDate.toLowerCase(), CaseStyle.Title),
		value: MealMenuOrderField.MenuDate,
	},
];

/**
 * Array of values for the "field" field in the order form of the MealMenu model.
 */
export const fieldValues = fieldOptions.map((e) => e.value);

/**
 * Mapping from order fields of the MealMenu model to their corresponding order type.
 */
export const fieldTypeMapping = {
	[MealMenuOrderField.MenuDate]: IndexForms.OrderFieldType.Time,
};

/**
 * Mapping from order fields of the MealMenu model to their corresponding field name.
 */
export const orderFieldToModelField = {
	[MealMenuOrderField.MenuDate]: "menuDate",
};

/**
 * Mapping from field names of the MealMenu model to their corresponding order field.
 */
export const modelFieldToOrderField = {
	menuDate: MealMenuOrderField.MenuDate,
};
