import { IndexForms } from "@hex-insights/app-modules";
import { TypeFilterObject } from "@hex-insights/core";
import { UserFormValues } from "../ModelFormValues";

export type StandardFilterFormValues = {}; // TODO define quick filters

export type FormValues = StandardFilterFormValues & IndexForms.SearchFormValues & IndexForms.FilterFormValues;

export const initialStandardFilters: StandardFilterFormValues = {};

export const initial: FormValues = {
	...initialStandardFilters,
	...IndexForms.initialSearchFormValues,
	...IndexForms.initialFilterFormValues,
};

export function getInitial(): FormValues {
	return initial;
}

export const quickFiltersTypeFilterObject: TypeFilterObject<StandardFilterFormValues> = {};

export const typeFilterObject: TypeFilterObject<FormValues> = {
	...quickFiltersTypeFilterObject,
	...IndexForms.searchFormValuesFilterObject,
	...IndexForms.filterFormValuesFilterObject,
};

export const advancedFilterInformation: IndexForms.AdvancedFilterInformation = {
	fieldOptions: [{ value: "email" }, { value: "status" }, { value: "passwordUpdatedAt" }],
	fieldComparisonOptions: {
		email: IndexForms.stringComparisonOptions,
		status: IndexForms.enumComparisonOptions,
		passwordUpdatedAt: IndexForms.timeComparisonOptions,
	},
	fieldComparisonTranslations: {
		email: IndexForms.stringComparisonTranslations,
		status: IndexForms.getRadioComparisonTranslations(UserFormValues.statusOptions),
		passwordUpdatedAt: IndexForms.timeComparisonTranslations,
	},
};
