import React from "react";
import {
	addTimeToDate,
	Button,
	Column,
	Conditional,
	Else,
	formatDateTime,
	Heading,
	Icon,
	If,
	jsxJoin,
	makeClassName,
	mappedArray,
	Modal,
	ModalProps,
	RequiredKeys,
	Row,
	Section,
	stringToLocalDate,
	StyleProps,
	Tooltip,
	useToggle,
} from "@hex-insights/core";
import { anyFieldChanged } from "@hex-insights/forms";
import { InternalLink } from "@hex-insights/router";
import {
	CampusLink,
	ClassYearLink,
	CourseSectionLink,
	EmployeeLink,
	HomeRoomLink,
	HomeRoomSectionLink,
	ParentLink,
	PersonIcon,
	PersonIconProps,
	RoomLink,
	ScaledText,
	SquareImageContainer,
	StackedScrollRow,
	StudentDocumentForm,
	StudentDocumentModal,
	StudentEnrollmentStatusBadge,
	StudentForm,
	Tile,
	Timeline,
	usePaginatedStudentTimelineQuery,
} from "../../../../Components";
import { StaticURLs } from "../../../../StaticURLs";
import {
	isDOBOnDate,
	NoteNoteType,
	NoteOrderField,
	OrderDirection,
	Person,
	PersonFormat,
	RelationshipFormat,
	RelationshipOrderField,
	Student,
	StudentDetailQuery,
	StudentDocumentFormState,
	StudentDocumentFormValues,
	StudentDocumentMutation,
	StudentDocumentOrderField,
	StudentDocumentStudentListQuery,
	StudentFormConversion,
	StudentFormState,
	StudentFormValues,
	StudentMutation,
	StudentParentListQuery,
	useStudentCurrentPeriod,
	useStudentDetailQuery,
	useStudentDocumentStudentListQuery,
	useStudentNotesListQuery,
	useStudentParentListQuery,
} from "../../../../Utilities";
import { AttendanceController, IconTileHeader, NewSimpleNoteController, SimpleNoteDisplay } from "../Components";
import { PageContext } from "../Context";
import { RelationshipCreateController, RelationshipDetailModal } from "../FamilyAndHealthPage";
import {
	AttendanceStatus,
	getHomeRoomSectionLabel,
	useCurrentSchoolAttendance,
	useStudentCampusesQuery,
	useStudentClassYearsQuery,
	useStudentCurrentHomeRoomSectionsQuery,
	useStudentTeachersQuery,
} from "../Utilities";
import { StudentBusPlan } from "./BusPlan";
import { StudentMealPlan } from "./MealPlan";
import styles from "./styles.module.css";

export type MainProps = {
	studentID: Student["id"];
};

export function Main({ studentID }: MainProps) {
	return (
		<React.Fragment>
			<GeneralNotesSection studentID={studentID} />
			<TimelineTile studentID={studentID} />
			<DocumentsTile studentID={studentID} />
		</React.Fragment>
	);
}

function GeneralNotesSection({ studentID }: { studentID: Student["id"] }) {
	const { data } = useStudentNotesListQuery({
		variables: {
			filters: { noteTypeEQ: NoteNoteType.General, students: [{ idEQ: studentID }] },
			order: { field: NoteOrderField.CreatedAt, direction: OrderDirection.Desc },
		},
	});

	return (
		<Section style={{ flexShrink: 0, width: "100%", overflow: "hidden" }}>
			<Section.Body style={{ padding: 0, width: "100%" }}>
				<Row justify="space-between" align="stretch">
					<Row justify="spaced-start" style={{ marginRight: "0.75rem", overflow: "hidden" }}>
						<StackedScrollRow justify="spaced-start">
							{data?.noteConnection.edges.map((e) => (
								<SimpleNoteDisplay key={e.node.id} note={e.node} />
							))}
						</StackedScrollRow>
						<NewSimpleNoteController studentID={studentID} noteType={NoteNoteType.General} />
					</Row>
					<StudentImages />
				</Row>
			</Section.Body>
		</Section>
	);
}

const imgURLs = [
	StaticURLs.Images.veritaPosts1,
	StaticURLs.Images.veritaPosts2,
	StaticURLs.Images.veritaPosts3,
	StaticURLs.Images.veritaPosts4,
	StaticURLs.Images.veritaPosts5,
];

function StudentImages() {
	const numImages = 5;

	return (
		<StackedScrollRow justify="spaced-start" align="center" style={{ minWidth: "7rem", flexShrink: 1 }}>
			{mappedArray(numImages, (i) => (
				<SquareImageContainer
					src={imgURLs[i % imgURLs.length]}
					key={i}
					size="6rem"
					style={{ flexShrink: 0, borderRadius: "1.5rem" }}
				/>
			))}
		</StackedScrollRow>
	);
}

function TimelineTile({ studentID }: { studentID: Student["id"] }) {
	const { data, networkStatus, refetch, hasMore, onLoadMoreClick } = usePaginatedStudentTimelineQuery({
		studentID,
	});

	return (
		<Tile
			className="timeline__scrolling-container__container"
			style={{ flexGrow: 1, height: "100%", overflow: "hidden" }}
		>
			<Tile.Body className="timeline__scrolling-container" style={{ height: "100%", overflow: "scroll" }}>
				<Timeline data={data?.studentTimeline} networkStatus={networkStatus} refetch={refetch} />

				<Timeline.LoadMoreButton hasMore={hasMore} networkStatus={networkStatus} onClick={onLoadMoreClick} />
			</Tile.Body>
		</Tile>
	);
}

function DocumentsTile({ studentID }: { studentID: Student["id"] }) {
	const { data } = useStudentDocumentStudentListQuery({
		variables: {
			filters: { studentIDEQ: studentID },
			order: { field: StudentDocumentOrderField.CreatedAt, direction: OrderDirection.Desc },
		},
	});

	if (!data || data.studentDocumentConnection.edges.length === 0) {
		return null;
	}

	return (
		<Tile>
			<Tile.Body>
				<Row justify="spaced-start" align="center">
					<Row justify="spaced-start" style={{ flexGrow: 1, width: "100%", overflow: "scroll" }}>
						{data?.studentDocumentConnection.edges.map((e) => (
							<DocumentDisplay key={e.node.id} studentDocument={e.node} />
						))}
					</Row>

					<DocumentController studentID={studentID} />
				</Row>
			</Tile.Body>
		</Tile>
	);
}

type DocumentDisplayProps = {
	studentDocument: StudentDocumentStudentListQuery["studentDocumentConnection"]["edges"][0]["node"];
};

function DocumentDisplay({ studentDocument }: DocumentDisplayProps) {
	const { isModalOpen, toggleIsModalOpen } = Modal.useToggle(false);

	const isImage = studentDocument.fileContentType.startsWith("image");
	const DocumentIcon = isImage ? Icon.Image : Icon.File;

	return (
		<React.Fragment>
			<Button
				onClick={toggleIsModalOpen}
				className={
					styles["student-document-display"] +
					" " +
					(isImage ? styles["student-document-display--image"] : styles["student-document-display--file"])
				}
			>
				<Row justify="spaced-start" horizontalSpacing="0.25rem" align="center">
					<DocumentIcon size="1.25rem" style={{ flexShrink: 0 }} />
					<span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
						{studentDocument.name}
					</span>
				</Row>
			</Button>

			<Modal.If condition={isModalOpen}>
				<StudentDocumentModal
					id={studentDocument.id}
					url={studentDocument.fileURL}
					contentType={studentDocument.fileContentType}
					onClose={toggleIsModalOpen}
				/>
			</Modal.If>
		</React.Fragment>
	);
}

function DocumentController({ studentID }: { studentID: Student["id"] }) {
	const { isModalOpen, toggleIsModalOpen } = Modal.useToggle(false);

	return (
		<React.Fragment>
			<Button variant="tertiary" size="small" onClick={toggleIsModalOpen}>
				<Row justify="spaced-start" horizontalSpacing="0.5rem" align="center" style={{ height: "100%" }}>
					<Icon.UploadCloud size="1.25rem" /> Upload
				</Row>
			</Button>

			<Modal.If condition={isModalOpen}>
				<StudentDocumentCreateModal studentID={studentID} onClose={toggleIsModalOpen} />
			</Modal.If>
		</React.Fragment>
	);
}

type StudentDocumentCreateModalProps = {
	studentID: Student["id"];
} & RequiredKeys<Pick<ModalProps, "ifRef" | "onClose">, "onClose">;

function StudentDocumentCreateModal({ studentID, ifRef, onClose }: StudentDocumentCreateModalProps) {
	const initialFormValues = React.useMemo(() => ({ studentID }), [studentID]);
	const formState = StudentDocumentFormState.useCreateFormState(initialFormValues);

	const create = StudentDocumentMutation.useCreate();
	const applyCreate = React.useCallback(
		async (formValues: StudentDocumentFormValues.Create) => {
			const { errors } = await create(formValues);
			return errors;
		},
		[create],
	);

	return (
		<Modal
			ifRef={ifRef}
			onClose={onClose}
			confirmOnClose={anyFieldChanged(formState)}
			style={{ width: "fit-content", minWidth: "none" }}
		>
			<Modal.Header>
				<Heading level={2} noMargin>
					Upload Document
				</Heading>
			</Modal.Header>
			<Modal.Body>
				<StudentDocumentForm.Create formState={formState} applyCreate={applyCreate} onSuccess={onClose} />
			</Modal.Body>
		</Modal>
	);
}

export type GeneralInfoSectionProps = {
	studentID: Student["id"];
	isExpanded?: boolean;
};

export function GeneralInfoSection({ studentID, isExpanded = false }: GeneralInfoSectionProps) {
	const { data } = useStudentDetailQuery({ variables: { id: studentID } });
	const student = data!.student;

	return (
		<React.Fragment>
			<Tile
				style={{ position: "relative", marginBottom: "0.25rem" }}
				className={makeClassName(
					styles["general-info-section"],
					isExpanded ? styles["general-info-section--overview-page"] : styles["general-info-section--other-pages"],
				)}
			>
				<Tile.Header
					className={makeClassName(
						styles["general-info-section__header"],
						isExpanded ? styles["general-info-section__header--overview-page"] : "",
					)}
				>
					<ProfileImage person={student.person} isExpanded={isExpanded} />
					<ProfileName person={student.person} />
				</Tile.Header>

				<If condition={isExpanded}>
					<GeneralInfoSectionBody student={student} />
				</If>
			</Tile>

			<If condition={isExpanded}>
				<ContactsTile studentID={studentID} />
			</If>
		</React.Fragment>
	);
}

type GeneralInfoSectionBodyProps = {
	student: StudentDetailQuery["student"];
};

function GeneralInfoSectionBody({ student }: GeneralInfoSectionBodyProps) {
	const studentID = student.id;

	const { loading: loadingClassYearData, data: classYearData } = useStudentClassYearsQuery(studentID);
	const { loading: loadingCampusData, data: campusData } = useStudentCampusesQuery(studentID);
	const { loading: loadingHomeRoomSectionsData, data: homeRoomSectionsData } =
		useStudentCurrentHomeRoomSectionsQuery(studentID);
	const { loading: loadingTeacherData, data: teacherData } = useStudentTeachersQuery(studentID);

	const homeRoomSection = homeRoomSectionsData?.homeRoomSectionConnection.edges[0]?.node ?? null;
	const homeRoomSectionLabel = homeRoomSection ? getHomeRoomSectionLabel(homeRoomSection) : "";

	return (
		<Tile.Body className={styles["general-info-section__body"]}>
			<Editor studentID={studentID} style={{ position: "absolute", top: "0.5rem", right: "0.5rem" }} />

			<Row
				justify="space-around"
				align="center"
				style={{
					marginLeft: "6rem",
					marginBottom: "2rem",
				}}
			>
				<StudentEnrollmentStatusBadge studentID={studentID} />
				<AttendanceController studentID={studentID} />
			</Row>

			<Column justify="spaced-start" horizontalSpacing="0.5rem">
				<StudentBirthday name={student.person.nickname || student.person.firstName} dob={student.person.dateOfBirth} />

				<Column justify="spaced-start" verticalSpacing="0.25rem">
					{(loadingCampusData || (campusData?.campusConnection.edges.length ?? 0) > 0) && (
						<Row justify="space-between" align="center" className={styles["general-info-section__item"]}>
							<span className={styles["general-info-section__item__label"]}>Campus</span>
							<span className={styles["general-info-section__item__value"]}>
								<Conditional>
									<If condition={loadingCampusData}>...</If>
									<Else>
										{campusData &&
											jsxJoin(
												campusData.campusConnection.edges.map((e) => <CampusLink key={e.node.id} instance={e.node} />),
												", ",
											)}
									</Else>
								</Conditional>
							</span>
						</Row>
					)}

					<Row justify="space-between" align="center" className={styles["general-info-section__item"]}>
						<span className={styles["general-info-section__item__label"]}>
							{homeRoomSection !== null ? "Home Room" : "Year"}
						</span>
						<span className={styles["general-info-section__item__value"]}>
							<Conditional>
								<If condition={loadingHomeRoomSectionsData || loadingClassYearData}>...</If>
								<Else>
									<Conditional>
										<If condition={homeRoomSection != null}>
											{homeRoomSection != null && <HomeRoomLink instance={homeRoomSection.homeRoom} />}
										</If>
										<If condition={!!classYearData}>
											{classYearData && classYearData.classYearConnection.edges.length > 0 && (
												<ClassYearLink instance={classYearData.classYearConnection.edges[0].node} />
											)}
										</If>
										<Else>None</Else>
									</Conditional>

									{homeRoomSectionLabel !== "" && homeRoomSection != null && (
										<React.Fragment>
											,{" "}
											<HomeRoomSectionLink instance={homeRoomSection}>
												Section {homeRoomSectionLabel}
											</HomeRoomSectionLink>
										</React.Fragment>
									)}
								</Else>
							</Conditional>
						</span>
					</Row>

					{(loadingTeacherData || (teacherData?.employeeConnection.edges.length ?? 0) > 0) && (
						<Row justify="space-between" align="center" className={styles["general-info-section__item"]}>
							<span className={styles["general-info-section__item__label"]}>
								Teacher
								{(teacherData?.employeeConnection.edges.length ?? 0) > 1 ? "s" : ""}
							</span>
							<span className={styles["general-info-section__item__value"]}>
								<Conditional>
									<If condition={loadingTeacherData}>...</If>
									<Else>
										{teacherData &&
											jsxJoin(
												teacherData.employeeConnection.edges.map((e) => (
													<EmployeeLink key={e.node.id} instance={e.node} />
												)),
												", ",
											)}
									</Else>
								</Conditional>
							</span>
						</Row>
					)}
				</Column>

				<Column justify="spaced-start" verticalSpacing="0.25rem">
					<Row justify="space-between" align="center" className={styles["general-info-section__item"]}>
						<span className={styles["general-info-section__item__label"]}>Age</span>
						<span className={styles["general-info-section__item__value"]}>
							{student.person.dateOfBirth
								? `${PersonFormat.Fields.age(student.person.dateOfBirth)} (${formatDateTime(
										stringToLocalDate(student.person.dateOfBirth, "day"),
										"D/M/YYYY",
								  )})`
								: "Unknown"}
						</span>
					</Row>

					<Row justify="space-between" align="center" className={styles["general-info-section__item"]}>
						<span className={styles["general-info-section__item__label"]}>Gender</span>
						<span className={styles["general-info-section__item__value"]}>{student.person.gender}</span>
					</Row>
				</Column>

				<Column justify="spaced-start" verticalSpacing="0.25rem">
					<Row justify="space-between" align="center" className={styles["general-info-section__item"]}>
						<span className={styles["general-info-section__item__label"]}>Nationality</span>
						<span className={styles["general-info-section__item__value"]}>{student.person.nationality}</span>
					</Row>

					<Row justify="space-between" align="center" className={styles["general-info-section__item"]}>
						<span className={styles["general-info-section__item__label"]}>Primary Language</span>
						<span className={styles["general-info-section__item__value"]}>{student.person.primaryLanguage}</span>
					</Row>
				</Column>

				<Column justify="spaced-start" verticalSpacing="0.25rem">
					<StudentBusPlan studentID={studentID} />
					<StudentMealPlan studentID={studentID} />

					<CurrentLocation studentID={studentID} />
				</Column>
			</Column>
		</Tile.Body>
	);
}

type CurrentLocationProps = {
	studentID: Student["id"];
};

function CurrentLocation({ studentID }: CurrentLocationProps) {
	const { schoolAttendanceStatus } = useCurrentSchoolAttendance(studentID);
	const { loading: loadingCurrentPeriod, currentPeriod } = useStudentCurrentPeriod(studentID);

	if (!(loadingCurrentPeriod || currentPeriod)) {
		return null;
	}

	return (
		<Row justify="space-between" align="center" className={styles["general-info-section__item"]}>
			<span className={styles["general-info-section__item__label"]}>Current Location</span>
			<span className={styles["general-info-section__item__value"]}>
				<Conditional>
					<If condition={loadingCurrentPeriod}>...</If>
					<Else>
						{currentPeriod && (
							<React.Fragment>
								<If
									condition={
										schoolAttendanceStatus === AttendanceStatus.Present ||
										schoolAttendanceStatus === AttendanceStatus.Absent
									}
								>
									<Tooltip.Container style={{ display: "inline-block", marginRight: "0.25rem" }}>
										<Tooltip>
											<Tooltip.Body style={{ textAlign: "start", width: "9rem" }}>
												Student is currently marked as{" "}
												{schoolAttendanceStatus === AttendanceStatus.Present ? "present" : "absent"}.
											</Tooltip.Body>
										</Tooltip>

										<Conditional>
											<If condition={schoolAttendanceStatus === AttendanceStatus.Present}>
												<Icon.Check size="1rem" style={{ color: "var(--success-color)", display: "block" }} />
											</If>
											<Else>
												<Icon.X size="1rem" style={{ color: "var(--danger-color)", display: "block" }} />
											</Else>
										</Conditional>
									</Tooltip.Container>
								</If>
								<CourseSectionLink instance={currentPeriod.courseSection}>
									{currentPeriod.courseSection.name}
								</CourseSectionLink>{" "}
								(
								<RoomLink instance={currentPeriod.room} />)
							</React.Fragment>
						)}
					</Else>
				</Conditional>
			</span>
		</Row>
	);
}

type ProfileNameProps = {
	person: Pick<Person, "name">;
};

function ProfileName({ person }: ProfileNameProps) {
	return (
		<div style={{ backgroundColor: "var(--light-blue)", padding: "0.5rem 0", borderRadius: "1rem" }}>
			<Heading level={1} noMargin className={styles["general-info-section__header__title"]}>
				<Row justify="flex-end" align="center" className={styles["general-info-section__header__title__row"]}>
					<ScaledText
						maxFontSize={32}
						style={{
							width: "calc(100% - var(--profile-image--full---image-size))",
							maxHeight: "var(--title---height)",
						}}
					>
						{person.name}
					</ScaledText>
				</Row>
			</Heading>
		</div>
	);
}

type ProfileImageProps = Pick<PersonIconProps, "person"> & {
	isExpanded?: boolean;
};

function ProfileImage({ person, isExpanded = false }: ProfileImageProps) {
	return (
		<PersonIcon
			person={person}
			imageSize={isExpanded ? "var(--profile-image--full---image-size)" : "var(--profile-image--small---image-size)"}
			expandable
			className={styles["student-person-icon"]}
			imageContainerClassName={styles["student-person-icon__image-container"]}
		/>
	);
}

function Editor({ studentID, style }: { studentID: Student["id"] } & Partial<StyleProps>) {
	const { isOn: isOpen, toggle: toggleIsOpen } = useToggle(false);

	return (
		<div style={style}>
			<Button variant="link" onClick={toggleIsOpen} style={{ padding: 0, border: "none", display: "block" }}>
				<Icon.Settings size="1.25rem" style={{ display: "block" }} />
			</Button>

			<Modal.If condition={isOpen}>
				<EditFormModal studentID={studentID} onClose={toggleIsOpen} />
			</Modal.If>
		</div>
	);
}

type EditFormModalProps = {
	studentID: Student["id"];
} & RequiredKeys<Pick<ModalProps, "ifRef" | "onClose">, "onClose">;

function EditFormModal({ studentID, ifRef, onClose }: EditFormModalProps) {
	const { data } = useStudentDetailQuery({ variables: { id: studentID } });
	const student = data!.student;

	const update = StudentMutation.useUpdateWithPerson(studentID);
	const applyUpdate = React.useCallback(
		async (
			changedFormValues: Partial<StudentFormValues.DetailWithPerson>,
			initialFormValues: StudentFormValues.DetailWithPerson,
		) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);
	const initialFormValues = React.useMemo(() => StudentFormConversion.toFormValuesWithPerson(student), [student]);
	const formState = StudentFormState.useDetailWithPersonFormState({ initialFormValues });

	return (
		<Modal ifRef={ifRef} onClose={onClose} confirmOnClose={anyFieldChanged(formState)}>
			<Modal.Header>
				<Heading level={2} noMargin>
					Student Details
				</Heading>
			</Modal.Header>
			<Modal.Body>
				<StudentForm.DetailWithPerson
					student={student}
					formState={formState}
					applyUpdate={applyUpdate}
					onSuccess={onClose}
				/>
			</Modal.Body>
		</Modal>
	);
}

type StudentBirthdayProps = {
	name: string;
	dob: Student["person"]["dateOfBirth"];
};

function StudentBirthday({ name, dob }: StudentBirthdayProps) {
	if (dob === null) {
		return null;
	}
	if (isDOBOnDate(dob, new Date())) {
		return (
			<Row justify="center">
				<Row justify="spaced-start" horizontalSpacing="0.25rem" align="center" className={styles["student-birthday"]}>
					<Icon.Gift size="1rem" /> {name}'s birthday is today!
				</Row>
			</Row>
		);
	}
	if (isDOBOnDate(dob, addTimeToDate(new Date(), [1, "day"]))) {
		return (
			<Row justify="center">
				<Row justify="spaced-start" horizontalSpacing="0.25rem" align="center" className={styles["student-birthday"]}>
					<Icon.Gift size="1rem" /> {name}'s birthday is tomorrow!
				</Row>
			</Row>
		);
	}
	for (let i = 0; i < 7; i++) {
		if (isDOBOnDate(dob, addTimeToDate(new Date(), [i + 1, "days"]))) {
			return (
				<Row justify="center">
					<Row justify="spaced-start" horizontalSpacing="0.25rem" align="center" className={styles["student-birthday"]}>
						<Icon.Gift size="1rem" /> {name}'s birthday is coming up!
					</Row>
				</Row>
			);
		}
	}
	return null;
}

function ContactsTile({ studentID }: { studentID: Student["id"] }) {
	const { loading, data } = useStudentParentListQuery({
		variables: {
			filters: { studentIDEQ: studentID },
			order: { field: RelationshipOrderField.IsPrimary, direction: OrderDirection.Desc },
		},
	});
	const hasPrimaryContacts = data?.relationshipConnection.edges.some((e) => e.node.isPrimary) ?? false;

	const { familyAndHealthPageInfo } = React.useContext(PageContext);

	return (
		<Tile>
			<IconTileHeader Icon={Icon.Users}>{hasPrimaryContacts ? "Primary Contacts" : "Contacts"}</IconTileHeader>
			<Tile.Body style={{ paddingTop: "0.25rem", paddingBottom: !!familyAndHealthPageInfo ? "0.25rem" : undefined }}>
				<Conditional>
					<If condition={loading}>Loading contacts...</If>
					<If condition={data?.relationshipConnection.edges.length === 0}>
						<Row justify="center">
							<RelationshipCreateController studentID={studentID} />
						</Row>
					</If>
					<Else>
						<Column justify="spaced-start" verticalSpacing="0.5rem">
							{data?.relationshipConnection.edges
								.filter((e) => (hasPrimaryContacts ? e.node.isPrimary : true))
								.slice(0, 2)
								.map((e) => (
									<Contact key={e.node.id} relationship={e.node} />
								))}
						</Column>
					</Else>
				</Conditional>
			</Tile.Body>
			{!!familyAndHealthPageInfo && (
				<Tile.Footer style={{ paddingTop: "0.25rem", paddingBottom: "0.5rem" }}>
					<Row justify="flex-end">
						<InternalLink to={familyAndHealthPageInfo.to(studentID)} style={{ fontSize: "0.9rem" }}>
							<Row justify="spaced-start" horizontalSpacing="0.25rem" align="center">
								View All <Icon.ArrowRight size="0.9rem" />
							</Row>
						</InternalLink>
					</Row>
				</Tile.Footer>
			)}
		</Tile>
	);
}

type ContactProps = {
	relationship: StudentParentListQuery["relationshipConnection"]["edges"][0]["node"];
};

function Contact({ relationship }: ContactProps) {
	const { isModalOpen, toggleIsModalOpen } = Modal.useToggle(false);

	return (
		<Column justify="spaced-start" verticalSpacing="0.1rem" className={styles["contact"]}>
			<Row justify="space-between" align="flex-end">
				<span style={{ fontSize: "1.25rem" }}>
					<ParentLink instance={relationship.parent} />
				</span>
				<span style={{ color: "#888" }}>
					{RelationshipFormat.Fields.relationshipType(relationship.relationshipType)}
				</span>
			</Row>

			<Row justify="center">
				<Button variant="link" size="small" onClick={toggleIsModalOpen} style={{ paddingTop: 0, paddingBottom: 0 }}>
					View Contact Details
				</Button>
			</Row>

			<Modal.If condition={isModalOpen}>
				<RelationshipDetailModal relationship={relationship} onClose={toggleIsModalOpen} />
			</Modal.If>
		</Column>
	);
}
