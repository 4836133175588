import React from "react";
import { ApplyCreateFunction, onCreateSubmit } from "@hex-insights/app-modules";
import { Column, Conditional, Else, formatDateTime, If, jsxJoin, List, Row } from "@hex-insights/core";
import {
	BooleanField,
	DateTimeField,
	FieldIf,
	Form,
	FormState,
	FormSubmitFunction,
	Label,
	Legend,
	RadioField,
	SubmissionStatus,
	SubmitButton,
	TextField,
} from "@hex-insights/forms";
import { Employee, Maybe, Note, NoteFormState, NoteFormValues, Person, Student, User } from "../../../../../Utilities";
import { FieldSet } from "../../../../FieldSet";
import { StudentLink } from "../../../../Links";
import { TextAreaField, TextAreaFieldDisplay } from "../../../../TextAreaField";
import * as Field from "../Field";
import styles from "../styles.module.css";

export type ControlledCreateProps = {
	applyCreate: ApplyCreateFunction<NoteFormValues.Behavior.Create>;
	onSuccess: () => void;
};

export function ControlledCreate(props: ControlledCreateProps) {
	const formState = NoteFormState.Behavior.useCreateFormState();

	return <Create formState={formState} {...props} />;
}

export type CreateProps = ControlledCreateProps & {
	formState: FormState<NoteFormValues.Behavior.Create>;
};

/**
 * Renders the create form of the Note model for an annoucement-type note using the given form state.
 */
export function Create({ formState, applyCreate, onSuccess }: CreateProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<NoteFormValues.Behavior.Create>>(
		async ({ formValues }) => {
			return onCreateSubmit(formValues, applyCreate);
		},
		[applyCreate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	return (
		<Form
			name="note.create.behavior"
			formState={formState}
			onSubmit={formState.onSubmitWrapper(onSubmit)}
			className={styles["note-form"]}
		>
			<Column justify="spaced-start" verticalSpacing="2rem">
				<Field.Students formState={formState} label="Students Involved" />

				<TextAreaField formState={formState} name="body" label="Incident Description" />

				<DateTimeField
					formState={formState}
					name="incidentTime"
					label="What day and time did this incident happen?"
					precision="minute"
					optional
				/>

				<RadioField
					formState={formState}
					name="incidentLocation"
					label="Where did the incident happen?"
					options={NoteFormValues.schoolLocationOptions}
					optional
				/>

				{/* Required */}
				<FieldSet>
					<FieldSet.Header>
						<Legend>Describe Incident</Legend>
					</FieldSet.Header>

					<FieldSet.Body>
						<Column justify="spaced-start" verticalSpacing="0.25rem">
							<BooleanField
								formState={formState}
								name="isIncidentPhysicalContactAggression"
								label="Physical Contact/Aggression"
							/>
							<BooleanField
								formState={formState}
								name="isIncidentFightingPhysicalAggression"
								label="Fighting/Physical Aggression"
							/>
							<BooleanField
								formState={formState}
								name="isIncidentVerbalAbuseDisrespectingStudent"
								label="Verbal Abuse/Disrespecting Student"
							/>
							<BooleanField formState={formState} name="isIncidentDisrepectingTeacher" label="Disrepecting Teacher" />
							<BooleanField formState={formState} name="isIncidentDisruption" label="Disruption" />
							<BooleanField
								formState={formState}
								name="isIncidentDefianceOrNonCompliance"
								label="Defiance/Non-Compliance"
							/>
							<BooleanField formState={formState} name="isIncidentOther" label="Other" />
							<FieldIf formState={formState} name="incidentOther" condition={formState.formValues.isIncidentOther}>
								<TextField formState={formState} name="incidentOther" label="Describe" />
							</FieldIf>
						</Column>
					</FieldSet.Body>
				</FieldSet>

				{/* Optional */}
				<FieldSet>
					<FieldSet.Header>
						<Legend>
							<Conditional>
								<If condition={formState.formValues.studentIDs.length <= 1}>
									What was the student's possible motivation for doing this?
								</If>
								<Else>What were the students' possible motivations for doing this?</Else>
							</Conditional>
						</Legend>
					</FieldSet.Header>

					<FieldSet.Body>
						<Column justify="spaced-start" verticalSpacing="0.25rem">
							<BooleanField formState={formState} name="isMotivationAttentionSeeking" label="Attention seeking" />
							<BooleanField formState={formState} name="isMotivationReaction" label="Reaction" />
							<BooleanField formState={formState} name="isMotivationAnger" label="Anger" />
							<BooleanField formState={formState} name="isMotivationFrustration" label="Frustration" />
							<BooleanField formState={formState} name="isMotivationHabit" label="Habit" />
							<BooleanField formState={formState} name="isMotivationLackOfSelfContol" label="Lack of Self-Contol" />
							<BooleanField formState={formState} name="isMotivationOther" label="Other" />
							<FieldIf formState={formState} name="motivationOther" condition={formState.formValues.isMotivationOther}>
								<TextField formState={formState} name="motivationOther" label="Describe" />
							</FieldIf>
						</Column>
					</FieldSet.Body>
				</FieldSet>

				{/* Optional */}
				<FieldSet>
					<FieldSet.Header>
						<Legend>Suggested Consequence?</Legend>
					</FieldSet.Header>

					<FieldSet.Body>
						<Column justify="spaced-start" verticalSpacing="0.25rem">
							<BooleanField
								formState={formState}
								name="isConsequenceRecommendationDirectorIntervention"
								label="Director Intervention (One-Time)"
							/>
							<BooleanField
								formState={formState}
								name="isConsequenceRecommendationSENOrSELIntervention"
								label="SEN/SEL Intervention (One-Time)"
							/>
							<BooleanField
								formState={formState}
								name="isConsequenceRecommendationStudentServiceTeamIntervention"
								label="Student Service Team Intervention (Coordinated School-Wide Solution)"
							/>
							<BooleanField
								formState={formState}
								name="isConsequenceRecommendationParentContactFromReporter"
								label="Parent Contact (From the Staff Member Reporting the Incident)"
							/>
							<BooleanField
								formState={formState}
								name="isConsequenceRecommendationParentContactFromSchoolDirector"
								label="Parent Contact (From the School Director)"
							/>
							<BooleanField formState={formState} name="isConsequenceRecommendationOther" label="Other" />
							<FieldIf
								formState={formState}
								name="consequenceRecommendationOther"
								condition={formState.formValues.isConsequenceRecommendationOther}
							>
								<TextField formState={formState} name="consequenceRecommendationOther" label="Describe" />
							</FieldIf>
						</Column>
					</FieldSet.Body>
				</FieldSet>

				<TextAreaField formState={formState} name="additionalComments" optional />

				<Row justify="flex-end" style={{ position: "sticky", bottom: "1rem" }}>
					<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
						Submit
					</SubmitButton>
				</Row>
			</Column>
		</Form>
	);
}

// TODO cleanup
export type ReadOnlyProps = {
	note: Pick<Note, "body" | "details" | "createdAt"> & {
		author: Maybe<
			Pick<User, "id" | "email"> & {
				person?: Maybe<
					Pick<Person, "name"> & {
						employee?: Maybe<Pick<Employee, "id">>;
					}
				>;
			}
		>;
		students: (Pick<Student, "id"> & { person: Pick<Student["person"], "id" | "name"> })[];
	};
};

export function ReadOnly({ note }: ReadOnlyProps) {
	const noteDetails = JSON.parse(note.details);

	return (
		<Column justify="spaced-start" verticalSpacing="2rem" className={styles["note-form"]}>
			<Row justify="spaced-start" align="center">
				<span>
					<Label>Student</Label>:
				</span>
				<span>
					{jsxJoin(
						note.students.map((e) => <StudentLink key={e.id} instance={e} />),
						", ",
					)}
				</span>
			</Row>

			<Column>
				<Label>Incident Description</Label>
				<TextAreaFieldDisplay value="" formattedValue={note.body} />
			</Column>

			<Row justify="spaced-start" align="center">
				<Column align="center">
					<Label>Incident Time</Label>
					<span>
						{noteDetails.incidentTime
							? formatDateTime(noteDetails.incidentTime, "HH:mm [on] D MMMM YYYY")
							: "Not given"}
					</span>
				</Column>

				<Column align="center">
					<Label>Incident Location</Label>
					<span>{noteDetails.incidentLocation ? noteDetails.incidentLocation : "Not given"}</span>
				</Column>
			</Row>

			<FieldSet>
				<FieldSet.Header>
					<Legend>Incident Description</Legend>
				</FieldSet.Header>

				<FieldSet.Body>
					<Conditional>
						<If
							condition={
								noteDetails.isIncidentPhysicalContactAggression ||
								noteDetails.isIncidentFightingPhysicalAggression ||
								noteDetails.isIncidentVerbalAbuseDisrespectingStudent ||
								noteDetails.isIncidentDisrepectingTeacher ||
								noteDetails.isIncidentDisruption ||
								noteDetails.isIncidentDefianceOrNonCompliance ||
								noteDetails.isIncidentOther
							}
						>
							<List>
								<If condition={noteDetails.isIncidentPhysicalContactAggression}>
									<List.Item>Physical Contact/Aggression</List.Item>
								</If>
								<If condition={noteDetails.isIncidentFightingPhysicalAggression}>
									<List.Item>Fighting/Physical Aggression</List.Item>
								</If>
								<If condition={noteDetails.isIncidentVerbalAbuseDisrespectingStudent}>
									<List.Item>Verbal Abuse/Disrespecting Student</List.Item>
								</If>
								<If condition={noteDetails.isIncidentDisrepectingTeacher}>
									<List.Item>Disrepecting Teacher</List.Item>
								</If>
								<If condition={noteDetails.isIncidentDisruption}>
									<List.Item>Disruption</List.Item>
								</If>
								<If condition={noteDetails.isIncidentDefianceOrNonCompliance}>
									<List.Item>Defiance/Non-Compliance</List.Item>
								</If>
								<If condition={noteDetails.isIncidentOther}>
									<List.Item>Other: {noteDetails.incidentOther}</List.Item>
								</If>
							</List>
						</If>
						<Else>
							<span>None Given</span>
						</Else>
					</Conditional>
				</FieldSet.Body>
			</FieldSet>

			<FieldSet>
				<FieldSet.Header>
					<Legend>Student's possible motivations</Legend>
				</FieldSet.Header>

				<FieldSet.Body>
					<Conditional>
						<If
							condition={
								noteDetails.isMotivationAttentionSeeking ||
								noteDetails.isMotivationReaction ||
								noteDetails.isMotivationAnger ||
								noteDetails.isMotivationFrustration ||
								noteDetails.isMotivationHabit ||
								noteDetails.isMotivationLackOfSelfContol ||
								noteDetails.isMotivationOther
							}
						>
							<List>
								<If condition={noteDetails.isMotivationAttentionSeeking}>
									<List.Item>Attention seeking</List.Item>
								</If>
								<If condition={noteDetails.isMotivationReaction}>
									<List.Item>Reaction</List.Item>
								</If>
								<If condition={noteDetails.isMotivationAnger}>
									<List.Item>Anger</List.Item>
								</If>
								<If condition={noteDetails.isMotivationFrustration}>
									<List.Item>Frustration</List.Item>
								</If>
								<If condition={noteDetails.isMotivationHabit}>
									<List.Item>Habit</List.Item>
								</If>
								<If condition={noteDetails.isMotivationLackOfSelfContol}>
									<List.Item>Lack of Self-Contol</List.Item>
								</If>
								<If condition={noteDetails.isMotivationOther}>
									<List.Item>Other: {noteDetails.motivationOther}</List.Item>
								</If>
							</List>
						</If>
						<Else>
							<span>None Given</span>
						</Else>
					</Conditional>
				</FieldSet.Body>
			</FieldSet>

			<FieldSet>
				<FieldSet.Header>
					<Legend>Suggested consequences</Legend>
				</FieldSet.Header>

				<FieldSet.Body>
					<Conditional>
						<If
							condition={
								noteDetails.isConsequenceRecommendationDirectorIntervention ||
								noteDetails.isConsequenceRecommendationSENOrSELIntervention ||
								noteDetails.isConsequenceRecommendationStudentServiceTeamIntervention ||
								noteDetails.isConsequenceRecommendationParentContactFromReporter ||
								noteDetails.isConsequenceRecommendationParentContactFromSchoolDirector ||
								noteDetails.isConsequenceRecommendationOther
							}
						>
							<List>
								<If condition={noteDetails.isConsequenceRecommendationDirectorIntervention}>
									<List.Item>Director Intervention (One-Time)</List.Item>
								</If>
								<If condition={noteDetails.isConsequenceRecommendationSENOrSELIntervention}>
									<List.Item>SEN/SEL Intervention (One-Time)</List.Item>
								</If>
								<If condition={noteDetails.isConsequenceRecommendationStudentServiceTeamIntervention}>
									<List.Item>Student Service Team Intervention (Coordinated School-Wide Solution)</List.Item>
								</If>
								<If condition={noteDetails.isConsequenceRecommendationParentContactFromReporter}>
									<List.Item>Parent Contact (From the Staff Member Reporting the Incident)</List.Item>
								</If>
								<If condition={noteDetails.isConsequenceRecommendationParentContactFromSchoolDirector}>
									<List.Item>Parent Contact (From the School Director)</List.Item>
								</If>
								<If condition={noteDetails.isConsequenceRecommendationOther}>
									<List.Item>Other: {noteDetails.consequenceRecommendationOther}</List.Item>
								</If>
							</List>
						</If>
						<Else>
							<span>None Given</span>
						</Else>
					</Conditional>
				</FieldSet.Body>
			</FieldSet>

			<Column>
				<Label>Additional Comments</Label>
				<TextAreaFieldDisplay
					value=""
					formattedValue={noteDetails.additionalComments !== "" ? noteDetails.additionalComments : "None Given"}
				/>
			</Column>
		</Column>
	);
}
