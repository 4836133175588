import { getUUID, isString } from "@hex-insights/core";
import * as Log from "@hex-insights/log";

const appBaseURL = process.env.REACT_APP_APP_BASE_URL ?? "";
const adminPortalBaseURL = process.env.REACT_APP_ADMIN_PORTAL_BASE_URL ?? "";
const teacherPortalBaseURL = process.env.REACT_APP_TEACHER_PORTAL_BASE_URL ?? "";
const enrollmentApplicationAppBaseURL = process.env.REACT_APP_ENROLLMENT_APPLICATION_APP_BASE_URL ?? "";
// const serverBaseURL = process.env.REACT_APP_SERVER_BASE_URL ?? "";
const serverCustomBaseURL = process.env.REACT_APP_SERVER_CUSTOM_BASE_URL ?? "";
const staticBaseURL = process.env.REACT_APP_STATIC_BASE_URL ?? "";

/**
 * Adds the given path to the base app URL to form a full URL.
 *
 * @param path The resource path on the server.
 */
export function appURL(path: string) {
	return appBaseURL + path;
}

export function adminPortalURL(path = "") {
	return adminPortalBaseURL + path;
}

export function teacherPortalURL(path = "") {
	return teacherPortalBaseURL + path;
}

export function enrollmentApplicationAppURL(path = "") {
	return enrollmentApplicationAppBaseURL + path;
}

/**
 * Adds the given path to the base server URL to form a full URL.
 *
 * @param path The resource path on the server.
 */
export function serverURL(path: string) {
	// TODO switch based on client host
	return serverCustomBaseURL + path;
}

/**
 * Adds the given path to the base static URL to form a full URL.
 *
 * @param path The resource path on the static server.
 */
export function staticURL(path: string) {
	return staticBaseURL + path;
}

/**
 * Wraps the {@link request} function and adds the app base URL to the given `resource`.
 *
 * @param resource An absolute path to an app resource.
 * @param init Options for the request.
 */
export async function appRequest(resource: string, init?: RequestInit) {
	return request(appURL(resource), init);
}

/**
 * Wraps the {@link request} function and adds the server base URL to the given `resource` and sets `credentials` to
 * "include" on the request.
 *
 * @param resource An absolute path to a resource on the server.
 * @param init Options for the request.
 */
export async function serverRequest(resource: string, init?: RequestInit) {
	return request(serverURL(resource), { ...init, credentials: "include" });
}

/**
 * Wraps the {@link request} function and adds the static base URL to the given `resource`.
 *
 * @param resource An absolute path to a static resource.
 * @param init Options for the request.
 */
export async function staticRequest(resource: string, init?: RequestInit) {
	return request(staticURL(resource), init);
}

/**
 * Wraps the global `fetch` function and adds logging functionality. For common requests, {@link appRequest},
 * {@link serverRequest}, or {@link staticRequest} should be used.
 *
 * @param resource The resouce to fetch.
 * @param init Options for the request.
 */
export async function request(resource: RequestInfo, init?: RequestInit) {
	const requestID = getUUID();
	const startTime = performance.now();

	const result = await fetch(resource, {
		...init,
		headers: {
			...init?.headers,
			"X-Hex-Request-ID": requestID,
		},
	});

	const durationMS = Math.round(performance.now() - startTime);
	Log.withFields({
		requestID,
		requestURL: isString(resource) ? resource : resource.url,
		requestDurationMS: durationMS,
		responseStatus: result.status,
	}).info("HTTP request");

	return result;
}
