import { Models } from "@hex-insights/app-modules";
import { DistributiveOmit } from "@hex-insights/core";
import { HomeRoomSection, HomeRoomSectionFormat, HomeRoomSectionNameFragment } from "../../../../Utilities";

type ModelForLink = Pick<HomeRoomSection, "id"> & HomeRoomSectionNameFragment;

export type HomeRoomSectionLinkProps = DistributiveOmit<Models.LinkProps<ModelForLink>, "pageInfoKey" | "format">;

/**
 * Renders a link to the given HomeRoomSection instance.
 */
export function HomeRoomSectionLink(props: HomeRoomSectionLinkProps) {
	return <Models.Link<ModelForLink> pageInfoKey="HomeRoomSection" format={HomeRoomSectionFormat.name} {...props} />;
}
