import React from "react";
import { EditMode, FormType, useFormState } from "@hex-insights/forms";
import { LessonPlanFormValues } from "../ModelFormValues";

/**
 * Returns a `formState` object for use in the create form for the LessonPlan model.
 */
export function useCreateFormState(initialFormValues?: Partial<LessonPlanFormValues.Create>) {
	const initialFormValuesRef = React.useRef({ ...LessonPlanFormValues.initialCreate, ...initialFormValues });
	return useFormState({
		initialFormValues: initialFormValuesRef.current,
		formType: FormType.Create,
	});
}

/**
 * Returns a `formState` object for use in the detail form of the LessonPlan model.
 *
 * @param initialFormValues The initial values for the form. Generally from
 * {@link LessonPlanFormConversion.toFormValues}.
 */
export function useDetailFormState({ initialFormValues }: { initialFormValues: LessonPlanFormValues.Detail }) {
	return useFormState({
		initialFormValues: initialFormValues,
		formType: FormType.Update,
	});
}

/**
 * Returns a read-only `formState` object for use in the detail form of the LessonPlan model.
 *
 * @param initialFormValues The initial values for the form. Generally from
 * {@link LessonPlanFormConversion.toFormValues}.
 */
export function useReadOnlyFormState({ initialFormValues }: { initialFormValues: LessonPlanFormValues.Detail }) {
	return useFormState({
		initialFormValues: initialFormValues,
		editMode: EditMode.ReadOnly,
	});
}
