import React from "react";
import { ClassNameProps, StyleProps } from "@hex-insights/core";
import { DateTimeField, FieldDisplayArgs, MultiSelectField, SelectField, TextField } from "@hex-insights/forms";
import {
	ContractInstallmentSelect,
	InvoiceFormValues,
	InvoiceLineItemSelect,
	PaymentLineItemSelect,
	useContractInstallmentSelectLazyQuery,
	useInvoiceLineItemSelectLazyQuery,
	usePaymentLineItemSelectLazyQuery,
} from "../../../../Utilities";
import { ContractInstallmentLink, InvoiceLineItemLink, PaymentLineItemLink } from "../../../Links";
import { TextAreaField } from "../../../TextAreaField";
import { BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the Invoice model.
 */
type FieldProps<K extends keyof InvoiceFormValues.Base = keyof InvoiceFormValues.Base> = BaseFieldProps<
	Pick<InvoiceFormValues.Base, K>
>;

/**
 * Generic props for fields of the Invoice model that only appear in the detail form.
 */
type DetailFieldProps<K extends keyof InvoiceFormValues.Detail = keyof InvoiceFormValues.Detail> = BaseFieldProps<
	Pick<InvoiceFormValues.Detail, K>
>;

/**
 * Renders a field component for the `invoiceNumber` field of the Invoice model.
 */
export function InvoiceNumber({ formState }: DetailFieldProps<"invoiceNumber">) {
	return <TextField formState={formState} name="invoiceNumber" />;
}

/**
 * Renders a field component for the `createdDate` field of the Invoice model.
 */
export function CreatedDate({ formState, disabled }: FieldProps<"createdDate"> & { disabled?: boolean }) {
	return <DateTimeField formState={formState} name="createdDate" precision="day" disabled={disabled} />;
}

/**
 * Renders a field component for the `dueDate` field of the Invoice model.
 */
export function DueDate({ formState, disabled }: FieldProps<"dueDate"> & { disabled?: boolean }) {
	return <DateTimeField formState={formState} name="dueDate" precision="day" disabled={disabled} />;
}

/**
 * Renders a field component for the `description` field of the Invoice model.
 */
export function Description({
	formState,
	className,
	style,
}: FieldProps<"description"> & Partial<ClassNameProps & StyleProps>) {
	return <TextAreaField formState={formState} name="description" optional className={className} style={style} />;
}

export type InvoiceLineItemsProps = DetailFieldProps<"invoiceLineItemIDs"> & {
	currentInvoiceLineItems?: InvoiceLineItemSelect.ModelForOption[];
};

/**
 * Renders a field component for the `invoiceLineItems` edge of the Invoice model.
 */
export function InvoiceLineItems({ formState, currentInvoiceLineItems }: InvoiceLineItemsProps) {
	const [loadOptions, { loading, data }] = useInvoiceLineItemSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.invoiceLineItemIDs) {
			loadOptions();
		}
	}, [formState.formEditing.invoiceLineItemIDs, loadOptions]);
	const options = React.useMemo(
		() => InvoiceLineItemSelect.toMultiOptions(data?.invoiceLineItemConnection.edges, currentInvoiceLineItems),
		[data, currentInvoiceLineItems],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="invoiceLineItemIDs"
			isLoading={loading}
			options={options}
			displayInstance={displayInvoiceLineItemInstance}
		/>
	);
}

function displayInvoiceLineItemInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <InvoiceLineItemLink instance={{ id }}>{formattedValue}</InvoiceLineItemLink>;
}

export type PaymentLineItemsProps = DetailFieldProps<"paymentLineItemIDs"> & {
	currentPaymentLineItems?: PaymentLineItemSelect.ModelForOption[];
};

/**
 * Renders a field component for the `paymentLineItems` edge of the Invoice model.
 */
export function PaymentLineItems({ formState, currentPaymentLineItems }: PaymentLineItemsProps) {
	const [loadOptions, { loading, data }] = usePaymentLineItemSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.paymentLineItemIDs) {
			loadOptions();
		}
	}, [formState.formEditing.paymentLineItemIDs, loadOptions]);
	const options = React.useMemo(
		() => PaymentLineItemSelect.toMultiOptions(data?.paymentLineItemConnection.edges, currentPaymentLineItems),
		[data, currentPaymentLineItems],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="paymentLineItemIDs"
			isLoading={loading}
			options={options}
			displayInstance={displayPaymentLineItemInstance}
		/>
	);
}

function displayPaymentLineItemInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <PaymentLineItemLink instance={{ id }}>{formattedValue}</PaymentLineItemLink>;
}

export type ContractInstallmentProps = FieldProps<"contractInstallmentID"> & {
	currentContractInstallment?: ContractInstallmentSelect.ModelForOption | null;
	disabled?: boolean;
};

/**
 * Renders a field component for the `contractInstallment` edge of the Invoice model.
 */
export function ContractInstallment({ formState, currentContractInstallment, disabled }: ContractInstallmentProps) {
	const [loadOptions, { loading, data }] = useContractInstallmentSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.contractInstallmentID) {
			loadOptions();
		}
	}, [formState.formEditing.contractInstallmentID, loadOptions]);
	const options = React.useMemo(
		() => ContractInstallmentSelect.toOptions(data?.contractInstallmentConnection.edges, currentContractInstallment),
		[data, currentContractInstallment],
	);

	return (
		<SelectField
			formState={formState}
			name="contractInstallmentID"
			isLoading={loading}
			options={options}
			display={displayContractInstallment}
			blankValue={null}
			disabled={disabled}
		/>
	);
}

function displayContractInstallment({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <ContractInstallmentLink instance={{ id }}>{formattedValue}</ContractInstallmentLink>;
}
