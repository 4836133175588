import { IndexForms } from "@hex-insights/app-modules";
import { CaseStyle, convertCase } from "@hex-insights/core";
import { OrderDirection, PaymentLineItemOrderField } from "../../GraphQL";

export type FormValues = IndexForms.OrderFormValues<PaymentLineItemOrderField | null>;

export const initial: FormValues = {
	field: PaymentLineItemOrderField.Amount,
	direction: OrderDirection.Asc,
};

/**
 * Options for the "field" field in the order form of the PaymentLineItem model.
 */
export const fieldOptions = [
	{ label: "ID", value: null },
	{
		label: convertCase(PaymentLineItemOrderField.Amount.toLowerCase(), CaseStyle.Title),
		value: PaymentLineItemOrderField.Amount,
	},
];

/**
 * Array of values for the "field" field in the order form of the PaymentLineItem model.
 */
export const fieldValues = fieldOptions.map((e) => e.value);

/**
 * Mapping from order fields of the PaymentLineItem model to their corresponding order type.
 */
export const fieldTypeMapping = {
	[PaymentLineItemOrderField.Amount]: IndexForms.OrderFieldType.Number,
};

/**
 * Mapping from order fields of the PaymentLineItem model to their corresponding field name.
 */
export const orderFieldToModelField = {
	[PaymentLineItemOrderField.Amount]: "amount",
};

/**
 * Mapping from field names of the PaymentLineItem model to their corresponding order field.
 */
export const modelFieldToOrderField = {
	amount: PaymentLineItemOrderField.Amount,
};
