import { IndexForms } from "@hex-insights/app-modules";
import { CaseStyle, convertCase } from "@hex-insights/core";
import { CalendarEventOrderField, OrderDirection } from "../../GraphQL";

export type FormValues = IndexForms.OrderFormValues<CalendarEventOrderField | null>;

export const initial: FormValues = {
	field: CalendarEventOrderField.Name,
	direction: OrderDirection.Asc,
};

/**
 * Options for the "field" field in the order form of the CalendarEvent model.
 */
export const fieldOptions = [
	{ label: "ID", value: null },
	{
		label: convertCase(CalendarEventOrderField.Name.toLowerCase(), CaseStyle.Title),
		value: CalendarEventOrderField.Name,
	},
	{
		label: convertCase(CalendarEventOrderField.StartTime.toLowerCase(), CaseStyle.Title),
		value: CalendarEventOrderField.StartTime,
	},
	{
		label: convertCase(CalendarEventOrderField.EndTime.toLowerCase(), CaseStyle.Title),
		value: CalendarEventOrderField.EndTime,
	},
	{
		label: convertCase(CalendarEventOrderField.IsPublic.toLowerCase(), CaseStyle.Title),
		value: CalendarEventOrderField.IsPublic,
	},
	{
		label: convertCase(CalendarEventOrderField.CreatedAt.toLowerCase(), CaseStyle.Title),
		value: CalendarEventOrderField.CreatedAt,
	},
];

/**
 * Array of values for the "field" field in the order form of the CalendarEvent model.
 */
export const fieldValues = fieldOptions.map((e) => e.value);

/**
 * Mapping from order fields of the CalendarEvent model to their corresponding order type.
 */
export const fieldTypeMapping = {
	[CalendarEventOrderField.Name]: IndexForms.OrderFieldType.String,
	[CalendarEventOrderField.StartTime]: IndexForms.OrderFieldType.Time,
	[CalendarEventOrderField.EndTime]: IndexForms.OrderFieldType.Time,
	[CalendarEventOrderField.IsPublic]: IndexForms.OrderFieldType.Boolean,
	[CalendarEventOrderField.CreatedAt]: IndexForms.OrderFieldType.Time,
};

/**
 * Mapping from order fields of the CalendarEvent model to their corresponding field name.
 */
export const orderFieldToModelField = {
	[CalendarEventOrderField.Name]: "name",
	[CalendarEventOrderField.StartTime]: "startTime",
	[CalendarEventOrderField.EndTime]: "endTime",
	[CalendarEventOrderField.IsPublic]: "isPublic",
	[CalendarEventOrderField.CreatedAt]: "createdAt",
};

/**
 * Mapping from field names of the CalendarEvent model to their corresponding order field.
 */
export const modelFieldToOrderField = {
	name: CalendarEventOrderField.Name,
	startTime: CalendarEventOrderField.StartTime,
	endTime: CalendarEventOrderField.EndTime,
	isPublic: CalendarEventOrderField.IsPublic,
	createdAt: CalendarEventOrderField.CreatedAt,
};
