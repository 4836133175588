import { IndexForms } from "@hex-insights/app-modules";
import { asArray, booleanTypeFilter, TypeFilterObject, valuesTypeFilter } from "@hex-insights/core";
import { EnrollmentApplicationStatus, EnrollmentApplicationStudentGradeLevelApplyingFor } from "../../GraphQL";
import { EnrollmentApplicationFormValues } from "../ModelFormValues";

export type StandardFilterFormValues = {
	statusIn: EnrollmentApplicationStatus[];
	isNew: boolean;
	isSeen: boolean;
	interviewMeetingInFuture: boolean;
	interviewMeetingInPast: boolean;
	gradeLevelApplyingForIn: EnrollmentApplicationStudentGradeLevelApplyingFor[];
};

export type FormValues = StandardFilterFormValues & IndexForms.SearchFormValues & IndexForms.FilterFormValues;

export const initialStandardFilters: StandardFilterFormValues = {
	statusIn: [],
	isNew: false,
	isSeen: false,
	interviewMeetingInFuture: false,
	interviewMeetingInPast: false,
	gradeLevelApplyingForIn: [],
};

export const initial: FormValues = {
	...initialStandardFilters,
	...IndexForms.initialSearchFormValues,
	...IndexForms.initialFilterFormValues,
};

export function getInitial(): FormValues {
	return initial;
}

const statusTypeFilter = valuesTypeFilter(
	[EnrollmentApplicationStatus.Approved, EnrollmentApplicationStatus.Pending, EnrollmentApplicationStatus.Rejected],
	null,
);

const gradeLevelApplyingForTypeFilter = valuesTypeFilter(
	[
		EnrollmentApplicationStudentGradeLevelApplyingFor.Nursery,
		EnrollmentApplicationStudentGradeLevelApplyingFor.Reception,
		EnrollmentApplicationStudentGradeLevelApplyingFor.Year1,
		EnrollmentApplicationStudentGradeLevelApplyingFor.Year2,
		EnrollmentApplicationStudentGradeLevelApplyingFor.Year3,
		EnrollmentApplicationStudentGradeLevelApplyingFor.Year4,
		EnrollmentApplicationStudentGradeLevelApplyingFor.Year5,
		EnrollmentApplicationStudentGradeLevelApplyingFor.Year6,
		EnrollmentApplicationStudentGradeLevelApplyingFor.Year7,
		EnrollmentApplicationStudentGradeLevelApplyingFor.Year8,
	],
	null,
);

export const quickFiltersTypeFilterObject: TypeFilterObject<StandardFilterFormValues> = {
	statusIn: (value: any) => asArray(value).filter((e) => statusTypeFilter(e) !== null),
	isNew: booleanTypeFilter(),
	isSeen: booleanTypeFilter(),
	interviewMeetingInFuture: booleanTypeFilter(),
	interviewMeetingInPast: booleanTypeFilter(),
	gradeLevelApplyingForIn: (value: any) => asArray(value).filter((e) => gradeLevelApplyingForTypeFilter(e) !== null),
};

export const typeFilterObject: TypeFilterObject<FormValues> = {
	...quickFiltersTypeFilterObject,
	...IndexForms.searchFormValuesFilterObject,
	...IndexForms.filterFormValuesFilterObject,
};

export const advancedFilterInformation: IndexForms.AdvancedFilterInformation = {
	fieldOptions: [{ value: "createdAt" }, { value: "status" }, { value: "interviewMeetingTime" }],
	fieldComparisonOptions: {
		createdAt: IndexForms.timeComparisonOptions,
		status: IndexForms.enumComparisonOptions,
		interviewMeetingTime: IndexForms.timeComparisonOptions,
	},
	fieldComparisonTranslations: {
		createdAt: IndexForms.timeComparisonTranslations,
		status: IndexForms.getRadioComparisonTranslations(EnrollmentApplicationFormValues.statusOptions),
		interviewMeetingTime: IndexForms.timeComparisonTranslations,
	},
};
