import React from "react";
import { ApolloCache, FetchResult, gql } from "@apollo/client";
import {
	CalendarEvent,
	CalendarEventCreateInput,
	CalendarEventCreateMutation,
	CalendarEventDeleteMutation,
	CalendarEventDetailDocument,
	CalendarEventDetailQuery,
	CalendarEventDetailQueryVariables,
	CalendarEventUpdateInput,
	CalendarEventUpdateMutation,
	graphTypeNames,
	Note,
	useCalendarEventCreateMutation,
	useCalendarEventDeleteMutation,
	useCalendarEventUpdateMutation,
	UserGroup,
} from "../../GraphQL";
import { CalendarEventFormConversion } from "../ModelFormConversion";
import { CalendarEventFormValues } from "../ModelFormValues";

/**
 * Returns a `create` function for the CalendarEvent model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the CalendarEvent model.
 */
export function useCreate() {
	const [createCalendarEvent] = useCalendarEventCreateMutation();

	return React.useCallback(
		async (formValues: CalendarEventFormValues.Create) => {
			const input = CalendarEventFormConversion.toGQLCreateInput(formValues);
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createCalendarEvent({ variables: { input }, update: updateCache });

			return { data: data?.createCalendarEvent ?? null, errors: errors ?? null };
		},
		[createCalendarEvent],
	);
}

/**
 * Returns an `update` function for the CalendarEvent model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the CalendarEvent model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: CalendarEvent["id"]) {
	const [updateCalendarEvent] = useCalendarEventUpdateMutation();

	return React.useCallback(
		async (
			changedFormValues: Partial<CalendarEventFormValues.Detail>,
			initialFormValues: CalendarEventFormValues.Detail,
		) => {
			const input = CalendarEventFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

			const { data, errors } = await updateCalendarEvent({ variables: { id, input }, update: updateCache });

			return { data: data?.updateCalendarEvent ?? null, errors: errors ?? null };
		},
		[updateCalendarEvent, id],
	);
}

/**
 * Returns a `del` function for the CalendarEvent model.
 *
 * @param id The ID of the instance to delete.
 */
export function useDelete(id: CalendarEvent["id"]) {
	const [deleteCalendarEvent] = useCalendarEventDeleteMutation();

	return React.useCallback(async () => {
		const updateCache = getUpdateCacheForDelete(id);

		const { data, errors } = await deleteCalendarEvent({ variables: { id }, update: updateCache });

		return { data: data?.deleteCalendarEvent ?? false, errors: errors ?? null };
	}, [deleteCalendarEvent, id]);
}

function getUpdateCacheForCreate(input: CalendarEventCreateInput) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<CalendarEventCreateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const createdObject = result.data.createCalendarEvent;

		cache.writeQuery<CalendarEventDetailQuery, CalendarEventDetailQueryVariables>({
			query: CalendarEventDetailDocument,
			data: { calendarEvent: createdObject },
			variables: { id: createdObject.id },
		});

		if (input.noteIDs) {
			for (let i = 0; i < input.noteIDs.length; i++) {
				addToCalendarEventsOfNoteCache(cache, input.noteIDs[i], createdObject);
			}
		}

		if (input.sharingUserGroupIDs) {
			for (let i = 0; i < input.sharingUserGroupIDs.length; i++) {
				addToSharedCalendarEventsOfUserGroupCache(cache, input.sharingUserGroupIDs[i], createdObject);
			}
		}

		cache.evict({ id: "ROOT_QUERY", fieldName: "calendarEventConnection" });
	};
}

function getUpdateCacheForUpdate(input: CalendarEventUpdateInput, _initialFormValues: CalendarEventFormValues.Detail) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<CalendarEventUpdateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const updatedObject = result.data.updateCalendarEvent;

		if (input.addNoteIDs) {
			for (let i = 0; i < input.addNoteIDs.length; i++) {
				addToCalendarEventsOfNoteCache(cache, input.addNoteIDs[i], updatedObject);
			}
		}
		if (input.removeNoteIDs) {
			for (let i = 0; i < input.removeNoteIDs.length; i++) {
				removeFromCalendarEventsOfNoteCache(cache, input.removeNoteIDs[i], updatedObject);
			}
		}

		if (input.addSharingUserGroupIDs) {
			for (let i = 0; i < input.addSharingUserGroupIDs.length; i++) {
				addToSharedCalendarEventsOfUserGroupCache(cache, input.addSharingUserGroupIDs[i], updatedObject);
			}
		}
		if (input.removeSharingUserGroupIDs) {
			for (let i = 0; i < input.removeSharingUserGroupIDs.length; i++) {
				removeFromSharedCalendarEventsOfUserGroupCache(cache, input.removeSharingUserGroupIDs[i], updatedObject);
			}
		}
	};
}

function getUpdateCacheForDelete(id: CalendarEvent["id"]) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<CalendarEventDeleteMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (!result.data?.deleteCalendarEvent) {
			return;
		}

		cache.evict({ id: cache.identify({ id, __typename: graphTypeNames.CalendarEvent }) });
		cache.evict({ id: "ROOT_QUERY", fieldName: "calendarEventConnection" });
		cache.gc();
	};
}

function addToCalendarEventsOfNoteCache(
	cache: ApolloCache<any>,
	targetID: Note["id"],
	object: Pick<CalendarEvent, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Note }),
		fields: {
			calendarEvents: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromCalendarEventsOfNoteCache(
	cache: ApolloCache<any>,
	targetID: Note["id"],
	object: Pick<CalendarEvent, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Note }),
		fields: {
			calendarEvents: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function addToSharedCalendarEventsOfUserGroupCache(
	cache: ApolloCache<any>,
	targetID: UserGroup["id"],
	object: Pick<CalendarEvent, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.UserGroup }),
		fields: {
			sharedCalendarEvents: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromSharedCalendarEventsOfUserGroupCache(
	cache: ApolloCache<any>,
	targetID: UserGroup["id"],
	object: Pick<CalendarEvent, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.UserGroup }),
		fields: {
			sharedCalendarEvents: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function toCacheRef(cache: ApolloCache<any>, object: Pick<CalendarEvent, "id">) {
	return cache.writeFragment({
		fragment: gql`
			fragment CalendarEventRef on CalendarEvent {
				id
			}
		`,
		data: object,
	});
}
