import { ClassYear, Course, CourseSection, CourseSubject, HomeRoom, LearningObjective } from "../../GraphQL";

export type Base = {
	name: Course["name"];
	description: Course["description"];
	subject: Course["subject"] | null;
	studentCapacity: Course["studentCapacity"] | null;
	classYearIDs: ClassYear["id"][];
	homeRoomID: HomeRoom["id"] | null;
};

export type Create = Base;

export type Detail = Base & {
	learningObjectiveIDs: LearningObjective["id"][];
	courseSectionIDs: CourseSection["id"][];
};

export const initialCreate: Create = {
	name: "",
	description: "",
	subject: null,
	studentCapacity: null,
	classYearIDs: [],
	homeRoomID: null,
};

export const subjectOptions = [
	{ value: CourseSubject.Art },
	{ value: CourseSubject.Core },
	{ value: CourseSubject.Ell, label: "ELL" },
	{ value: CourseSubject.ForeignLanguage },
	{ value: CourseSubject.Ipc, label: "IPC/IEYC" },
	{ value: CourseSubject.LanguageArts },
	{ value: CourseSubject.Math },
	{ value: CourseSubject.Music },
	{ value: CourseSubject.Pe, label: "PE" },
	{ value: CourseSubject.Reading },
	{ value: CourseSubject.Science },
	{ value: CourseSubject.SelTime, label: "SEL Time" },
	{ value: CourseSubject.Technology },
];
