import React from "react";
import { If, makeClassName, useUpdateEffect, useUpdatingRef } from "@hex-insights/core";
import {
	DateTimeField,
	FieldDisplayArgs,
	FileField,
	FileFieldValue,
	invalid,
	MultiSelectField,
	RadioField,
	SelectField,
	TextField,
	valid,
} from "@hex-insights/forms";
import {
	EnrollmentApplicationSelect,
	EnrollmentApplicationStudentCurrentGradeLevel,
	EnrollmentApplicationStudentFormat,
	EnrollmentApplicationStudentFormValues,
	EnrollmentApplicationStudentGradeLevelApplyingFor,
	EnrollmentApplicationSubmissionSelect,
	useEnrollmentApplicationSelectLazyQuery,
	useEnrollmentApplicationSubmissionSelectLazyQuery,
} from "../../../../Utilities";
import { EnrollmentApplicationLink, EnrollmentApplicationSubmissionLink } from "../../../Links";
import { RadioRowInput } from "../../../RadioRowInput";
import { TextAreaField } from "../../../TextAreaField";
import { countryOptions, languageOptions } from "../options";
import { FutureBaseFieldProps } from "../Shared";
import styles from "./field.module.css";

export { InterviewMeetingTime } from "./InterviewMeetingTime";

/**
 * Generic props for fields of the EnrollmentApplicationStudent model.
 */
type FieldProps<
	K extends keyof EnrollmentApplicationStudentFormValues.Base = keyof EnrollmentApplicationStudentFormValues.Base,
> = FutureBaseFieldProps<Pick<EnrollmentApplicationStudentFormValues.Base, K>>;

/**
 * Generic props for fields of the EnrollmentApplicationStudent model that only appear in the detail form.
 */
type DetailFieldProps<
	K extends keyof EnrollmentApplicationStudentFormValues.Detail = keyof EnrollmentApplicationStudentFormValues.Detail,
> = FutureBaseFieldProps<Pick<EnrollmentApplicationStudentFormValues.Detail, K>>;

/**
 * Renders a field component for the `image` field of the EnrollmentApplicationStudent model.
 */
export function Image({ formState, ...props }: FieldProps<"image">) {
	return <FileField formState={formState} name="image" optional {...props} immediateValidation={validateImageSize} />;
}

const maxUploadSize = 10_000_000;

function validateImageSize(value: FileFieldValue) {
	if (value === null) {
		return valid();
	}
	if (value.size > maxUploadSize) {
		return invalid("Image file is too large, please try a smaller image file.");
	}
	return valid();
}

/**
 * Renders a field component for the `name` field of the EnrollmentApplicationStudent model.
 */
export function Name({ formState, ...props }: DetailFieldProps<"name">) {
	return <TextField formState={formState} name="name" {...props} />;
}

/**
 * Renders a field component for the `firstName` field of the EnrollmentApplicationStudent model.
 */
export function FirstName({ formState, ...props }: FieldProps<"firstName">) {
	return <TextField formState={formState} name="firstName" {...props} />;
}

/**
 * Renders a field component for the `lastName` field of the EnrollmentApplicationStudent model.
 */
export function LastName({ formState, ...props }: FieldProps<"lastName">) {
	return <TextField formState={formState} name="lastName" {...props} />;
}

/**
 * Renders a field component for the `nickname` field of the EnrollmentApplicationStudent model.
 */
export function Nickname({ formState, ...props }: FieldProps<"nickname">) {
	return <TextField formState={formState} name="nickname" optional {...props} />;
}

/**
 * Renders a field component for the `dateOfBirth` field of the EnrollmentApplicationStudent model.
 */
export function DateOfBirth({ formState, placeholder = "[locale]", ...props }: FieldProps<"dateOfBirth">) {
	return (
		<div
			className={makeClassName(
				styles["date-of-birth"],
				formState.formEditing.dateOfBirth ? styles["date-of-birth--editing"] : "",
			)}
		>
			<DateTimeField
				formState={formState}
				name="dateOfBirth"
				placeholder={placeholder}
				precision="day"
				withPreview
				{...props}
			/>
			<If condition={formState.formValues.dateOfBirth !== null}>
				<span className={styles["date-of-birth__age"]}>
					Age: {EnrollmentApplicationStudentFormat.age(formState.formValues)}
				</span>
			</If>
		</div>
	);
}

/**
 * Renders a field component for the `gender` field of the EnrollmentApplicationStudent model.
 */
export function Gender({ formState, ...props }: FieldProps<"gender">) {
	return (
		<RadioField
			formState={formState}
			name="gender"
			options={EnrollmentApplicationStudentFormValues.genderOptions}
			blankValue={null}
			optional
			Input={RadioRowInput}
			{...props}
		/>
	);
}

/**
 * Renders a field component for the `genderCustom` field of the EnrollmentApplicationStudent model.
 */
export function GenderCustom({ formState, ...props }: FieldProps<"genderCustom">) {
	return <TextField formState={formState} name="genderCustom" label="Gender (Other)" optional {...props} />;
}

/**
 * Renders a field component for the `nationality` field of the EnrollmentApplicationStudent model.
 */
export function Nationality({ formState, ...props }: FieldProps<"nationality">) {
	return <TextField formState={formState} name="nationality" {...props} />;
}

/**
 * Renders a field component for the `nationalityList` field of the EnrollmentApplicationStudent model.
 */
export function NationalityList({
	formState,
	label = "Nationality",
	...props
}: FutureBaseFieldProps<{ nationalityList: string[] }>) {
	return (
		<MultiSelectField formState={formState} name="nationalityList" label={label} options={countryOptions} {...props} />
	);
}

/**
 * Renders a field component for the `primaryLanguage` field of the EnrollmentApplicationStudent model.
 */
export function PrimaryLanguage({ formState, ...props }: FieldProps<"primaryLanguage">) {
	return <TextField formState={formState} name="primaryLanguage" optional {...props} />;
}

/**
 * Renders a field component for the `primaryLanguageList` field of the EnrollmentApplicationStudent model.
 */
export function PrimaryLanguageList({
	formState,
	label = "Primary Language",
	...props
}: FutureBaseFieldProps<{ primaryLanguageList: string[] }>) {
	return (
		<MultiSelectField
			formState={formState}
			name="primaryLanguageList"
			label={label}
			options={languageOptions}
			{...props}
		/>
	);
}

/**
 * Renders a field component for the `englishLanguageFluency` field of the EnrollmentApplicationStudent model.
 */
export function EnglishLanguageFluency({ formState, ...props }: FieldProps<"englishLanguageFluency">) {
	return (
		<SelectField
			formState={formState}
			name="englishLanguageFluency"
			options={EnrollmentApplicationStudentFormValues.englishLanguageFluencyOptions}
			blankValue={null}
			optional
			{...props}
		/>
	);
}

const nextGradeLevel = {
	[EnrollmentApplicationStudentCurrentGradeLevel.Nursery]: EnrollmentApplicationStudentGradeLevelApplyingFor.Reception,
	[EnrollmentApplicationStudentCurrentGradeLevel.Reception]: EnrollmentApplicationStudentGradeLevelApplyingFor.Year1,
	[EnrollmentApplicationStudentCurrentGradeLevel.Year1]: EnrollmentApplicationStudentGradeLevelApplyingFor.Year2,
	[EnrollmentApplicationStudentCurrentGradeLevel.Year2]: EnrollmentApplicationStudentGradeLevelApplyingFor.Year3,
	[EnrollmentApplicationStudentCurrentGradeLevel.Year3]: EnrollmentApplicationStudentGradeLevelApplyingFor.Year4,
	[EnrollmentApplicationStudentCurrentGradeLevel.Year4]: EnrollmentApplicationStudentGradeLevelApplyingFor.Year5,
	[EnrollmentApplicationStudentCurrentGradeLevel.Year5]: EnrollmentApplicationStudentGradeLevelApplyingFor.Year6,
	[EnrollmentApplicationStudentCurrentGradeLevel.Year6]: EnrollmentApplicationStudentGradeLevelApplyingFor.Year7,
	[EnrollmentApplicationStudentCurrentGradeLevel.Year7]: EnrollmentApplicationStudentGradeLevelApplyingFor.Year8,
};

/**
 * Renders a field component for the `currentGradeLevel` field of the EnrollmentApplicationStudent model.
 */
export function CurrentGradeLevel({ formState, ...props }: FieldProps<"currentGradeLevel" | "gradeLevelApplyingFor">) {
	const {
		formValues: { currentGradeLevel },
		formSetFunctions: { gradeLevelApplyingFor: setGradeLevelApplyingFor },
	} = formState;
	const setGradeLevelApplyingForRef = useUpdatingRef(setGradeLevelApplyingFor);
	useUpdateEffect(() => {
		if (currentGradeLevel) {
			const next = nextGradeLevel[currentGradeLevel];
			if (next) {
				setGradeLevelApplyingForRef.current(nextGradeLevel[currentGradeLevel]);
			}
		}
	}, [currentGradeLevel, setGradeLevelApplyingForRef]);

	return (
		<SelectField
			formState={formState}
			name="currentGradeLevel"
			options={EnrollmentApplicationStudentFormValues.currentGradeLevelOptions}
			blankValue={null}
			{...props}
		/>
	);
}

/**
 * Renders a field component for the `gradeLevelApplyingFor` field of the EnrollmentApplicationStudent model.
 */
export function GradeLevelApplyingFor({ formState, ...props }: FieldProps<"gradeLevelApplyingFor">) {
	return (
		<SelectField
			formState={formState}
			name="gradeLevelApplyingFor"
			options={EnrollmentApplicationStudentFormValues.gradeLevelApplyingForOptions}
			blankValue={null}
			{...props}
		/>
	);
}

/**
 * Renders a field component for the `hasPreviousSchooling` field of the EnrollmentApplicationStudent model.
 */
export function HasPreviousSchooling({ formState, ...props }: FieldProps<"hasPreviousSchooling">) {
	return (
		<RadioField
			formState={formState}
			name="hasPreviousSchooling"
			label="Has Had Previous Schooling?"
			options={EnrollmentApplicationStudentFormValues.yesNoOptions}
			noToolbar
			Input={RadioRowInput}
			{...props}
		/>
	);
}

/**
 * Renders a field component for the `previousSchoolInformation` field of the EnrollmentApplicationStudent model.
 */
export function PreviousSchoolInformation({ formState, ...props }: FieldProps<"previousSchoolInformation">) {
	return (
		<TextField
			formState={formState}
			name="previousSchoolInformation"
			label="Previous School Name"
			optional
			{...props}
		/>
	);
}

/**
 * Renders a field component for the `previousSchoolLocation` field of the EnrollmentApplicationStudent model.
 */
export function PreviousSchoolLocation({ formState, ...props }: FieldProps<"previousSchoolLocation">) {
	return <TextField formState={formState} name="previousSchoolLocation" optional {...props} />;
}

/**
 * Renders a field component for the `hasLearningDifficulties` field of the EnrollmentApplicationStudent model.
 */
export function HasLearningDifficulties({ formState, ...props }: FieldProps<"hasLearningDifficulties">) {
	return (
		<RadioField
			formState={formState}
			name="hasLearningDifficulties"
			label="Has your child experienced any learning difficulties at home or at school?"
			options={EnrollmentApplicationStudentFormValues.yesNoOptions}
			noToolbar
			Input={RadioRowInput}
			{...props}
		/>
	);
}

/**
 * Renders a field component for the `learningDifficultiesDescription` field of the EnrollmentApplicationStudent model.
 */
export function LearningDifficultiesDescription({
	formState,
	...props
}: FieldProps<"learningDifficultiesDescription">) {
	return <TextAreaField formState={formState} name="learningDifficultiesDescription" {...props} />;
}

/**
 * Renders a field component for the `hasMedicalPhysicalExceptionalities` field of the EnrollmentApplicationStudent model.
 */
export function HasMedicalPhysicalExceptionalities({
	formState,
	...props
}: FieldProps<"hasMedicalPhysicalExceptionalities">) {
	return (
		<RadioField
			formState={formState}
			name="hasMedicalPhysicalExceptionalities"
			label="Does your child have any medical or physical exceptionalities that may impact his/her learning?"
			options={EnrollmentApplicationStudentFormValues.yesNoOptions}
			noToolbar
			Input={RadioRowInput}
			{...props}
		/>
	);
}

/**
 * Renders a field component for the `medicalPhysicalExceptionalitiesDescription` field of the EnrollmentApplicationStudent model.
 */
export function MedicalPhysicalExceptionalitiesDescription({
	formState,
	...props
}: FieldProps<"medicalPhysicalExceptionalitiesDescription">) {
	return <TextAreaField formState={formState} name="medicalPhysicalExceptionalitiesDescription" {...props} />;
}

/**
 * Renders a field component for the `hasBehaviorDisciplineChallenges` field of the EnrollmentApplicationStudent model.
 */
export function HasBehaviorDisciplineChallenges({
	formState,
	...props
}: FieldProps<"hasBehaviorDisciplineChallenges">) {
	return (
		<RadioField
			formState={formState}
			name="hasBehaviorDisciplineChallenges"
			label="Has your child experienced any behaviour or discipline challenges at home or school?"
			options={EnrollmentApplicationStudentFormValues.yesNoOptions}
			noToolbar
			Input={RadioRowInput}
			{...props}
		/>
	);
}

/**
 * Renders a field component for the `behaviorDisciplineChallengesDescription` field of the EnrollmentApplicationStudent model.
 */
export function BehaviorDisciplineChallengesDescription({
	formState,
	...props
}: FieldProps<"behaviorDisciplineChallengesDescription">) {
	return <TextAreaField formState={formState} name="behaviorDisciplineChallengesDescription" {...props} />;
}

/**
 * Renders a field component for the `additionalInformation` field of the EnrollmentApplicationStudent model.
 */
export function AdditionalInformation({ formState, ...props }: FieldProps<"additionalInformation">) {
	return (
		<TextAreaField
			formState={formState}
			name="additionalInformation"
			label="Please add anything else you would like to share about your child"
			optional
			{...props}
		/>
	);
}

export type EnrollmentApplicationProps = FieldProps<"enrollmentApplicationID"> & {
	currentEnrollmentApplication?: EnrollmentApplicationSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `enrollmentApplication` edge of the EnrollmentApplicationStudent model.
 */
export function EnrollmentApplication({
	formState,
	currentEnrollmentApplication,
	...props
}: EnrollmentApplicationProps) {
	const [loadOptions, { loading, data }] = useEnrollmentApplicationSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.enrollmentApplicationID) {
			loadOptions();
		}
	}, [formState.formEditing.enrollmentApplicationID, loadOptions]);
	const options = React.useMemo(
		() =>
			EnrollmentApplicationSelect.toOptions(data?.enrollmentApplicationConnection.edges, currentEnrollmentApplication),
		[data, currentEnrollmentApplication],
	);

	return (
		<SelectField
			formState={formState}
			name="enrollmentApplicationID"
			isLoading={loading}
			options={options}
			optional
			display={displayEnrollmentApplication}
			blankValue={null}
			{...props}
		/>
	);
}

function displayEnrollmentApplication({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <EnrollmentApplicationLink instance={{ id }}>{formattedValue}</EnrollmentApplicationLink>;
}

export type EnrollmentApplicationSubmissionProps = FieldProps<"enrollmentApplicationSubmissionID"> & {
	currentEnrollmentApplicationSubmission?: EnrollmentApplicationSubmissionSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `enrollmentApplicationSubmission` edge of the EnrollmentApplicationStudent model.
 */
export function EnrollmentApplicationSubmission({
	formState,
	currentEnrollmentApplicationSubmission,
	...props
}: EnrollmentApplicationSubmissionProps) {
	const [loadOptions, { loading, data }] = useEnrollmentApplicationSubmissionSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.enrollmentApplicationSubmissionID) {
			loadOptions();
		}
	}, [formState.formEditing.enrollmentApplicationSubmissionID, loadOptions]);
	const options = React.useMemo(
		() =>
			EnrollmentApplicationSubmissionSelect.toOptions(
				data?.enrollmentApplicationSubmissionConnection.edges,
				currentEnrollmentApplicationSubmission,
			),
		[data, currentEnrollmentApplicationSubmission],
	);

	return (
		<SelectField
			formState={formState}
			name="enrollmentApplicationSubmissionID"
			isLoading={loading}
			options={options}
			display={displayEnrollmentApplicationSubmission}
			blankValue={null}
			{...props}
		/>
	);
}

function displayEnrollmentApplicationSubmission({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <EnrollmentApplicationSubmissionLink instance={{ id }}>{formattedValue}</EnrollmentApplicationSubmissionLink>;
}
