import React from "react";
import { ApplyCreateFunction, onCreateSubmit } from "@hex-insights/app-modules";
import { Column, Conditional, Else, If, jsxJoin, List, Paragraph, Row } from "@hex-insights/core";
import {
	BooleanField,
	FieldIf,
	Form,
	FormState,
	FormSubmitFunction,
	Label,
	Legend,
	SubmissionStatus,
	SubmitButton,
	TextField,
} from "@hex-insights/forms";
import { Employee, Maybe, Note, NoteFormState, NoteFormValues, Person, Student, User } from "../../../../../Utilities";
import { FieldSet } from "../../../../FieldSet";
import { StudentLink } from "../../../../Links";
import { TextAreaField, TextAreaFieldDisplay } from "../../../../TextAreaField";
import * as Field from "../Field";
import styles from "../styles.module.css";

export type ControlledCreateProps = {
	applyCreate: ApplyCreateFunction<NoteFormValues.Academic.Create>;
	onSuccess: () => void;
};

export function ControlledCreate(props: ControlledCreateProps) {
	const formState = NoteFormState.Academic.useCreateFormState();

	return <Create formState={formState} {...props} />;
}
export type CreateProps = ControlledCreateProps & {
	formState: FormState<NoteFormValues.Academic.Create>;
};

/**
 * Renders the create form of the Note model for an annoucement-type note using the given form state.
 */
export function Create({ formState, applyCreate, onSuccess }: CreateProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<NoteFormValues.Academic.Create>>(
		async ({ formValues }) => {
			return onCreateSubmit(formValues, applyCreate);
		},
		[applyCreate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	return (
		<Form
			name="note.create.academic"
			formState={formState}
			onSubmit={formState.onSubmitWrapper(onSubmit)}
			className={styles["note-form"]}
		>
			<Column justify="spaced-start" verticalSpacing="2rem">
				<Field.Students formState={formState} />

				<TextAreaField formState={formState} name="body" label="Issue Description" />

				{/* Required */}
				<FieldSet>
					<FieldSet.Header>
						<Legend>What is the nature of the issue?</Legend>
					</FieldSet.Header>

					<FieldSet.Body>
						<Column justify="spaced-start" verticalSpacing="0.25rem">
							<BooleanField formState={formState} name="isIssueNatureLearningDifficulty" label="Learning difficulty" />
							<BooleanField formState={formState} name="isIssueNatureAttentionIssue" label="Attention Issue" />
							<BooleanField formState={formState} name="isIssueNatureFocusIssue" label="Focus Issue" />
							<BooleanField
								formState={formState}
								name="isIssueNatureEmotionalOrVulnerableIssue"
								label="Emotional or Vulnerable Issue"
							/>
							<BooleanField formState={formState} name="isIssueNatureOther" label="Other" />
							<FieldIf
								formState={formState}
								name="issueNatureOther"
								condition={formState.formValues.isIssueNatureOther}
							>
								<TextField formState={formState} name="issueNatureOther" label="Describe" />
							</FieldIf>
						</Column>
					</FieldSet.Body>
				</FieldSet>

				{/* Optional */}
				<FieldSet>
					<FieldSet.Header>
						<Legend>What is your suggested solution?</Legend>
					</FieldSet.Header>

					<FieldSet.Body>
						<Column justify="spaced-start" verticalSpacing="0.25rem">
							<BooleanField
								formState={formState}
								name="isSolutionRecommendationDifferentiatedLearning"
								label="Differentiated Learning"
							/>
							<BooleanField
								formState={formState}
								name="isSolutionRecommendationIndividualLearningPlan"
								label="Individual Learning Plan (ILP)"
							/>
							<BooleanField formState={formState} name="isSolutionRecommendationTutoring" label="Tutoring" />
							<BooleanField
								formState={formState}
								name="isSolutionRecommendationProfessionalSupport"
								label="Professional support"
							/>
							<BooleanField
								formState={formState}
								name="isSolutionRecommendationInClassSupport"
								label="In-Class Support"
							/>
							<BooleanField formState={formState} name="isSolutionRecommendationOther" label="Other" />
							<FieldIf
								formState={formState}
								name="solutionRecommendationOther"
								condition={formState.formValues.isSolutionRecommendationOther}
							>
								<TextField formState={formState} name="solutionRecommendationOther" label="Describe" />
							</FieldIf>
						</Column>
					</FieldSet.Body>
				</FieldSet>

				{/* <Column justify="spaced-start">
					<RadioField
						formState={formState}
						name="isInterestedInMeeting"
						label="Do you want to meet with Demy to discuss?"
						options={NoteFormValues.booleanOptions}
						blankValue={null}
					/>

					<If condition={formState.formValues.isInterestedInMeeting === true}>
						<Paragraph>You can expect a response from MS. Demy in 24 hours.</Paragraph>
					</If>
				</Column> */}

				<Row justify="flex-end" style={{ position: "sticky", bottom: "1rem" }}>
					<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
						Submit
					</SubmitButton>
				</Row>
			</Column>
		</Form>
	);
}

// TODO cleanup
export type ReadOnlyProps = {
	note: Pick<Note, "body" | "details" | "createdAt"> & {
		author: Maybe<
			Pick<User, "id" | "email"> & {
				person?: Maybe<
					Pick<Person, "name"> & {
						employee?: Maybe<Pick<Employee, "id">>;
					}
				>;
			}
		>;
		students: (Pick<Student, "id"> & { person: Pick<Student["person"], "id" | "name"> })[];
	};
};

export function ReadOnly({ note }: ReadOnlyProps) {
	const noteDetails = JSON.parse(note.details);

	return (
		<Column justify="spaced-start" verticalSpacing="2rem" className={styles["note-form"]}>
			<Row justify="spaced-start" align="center">
				<span>
					<Label>Student</Label>:
				</span>
				<span>
					{jsxJoin(
						note.students.map((e) => <StudentLink key={e.id} instance={e} />),
						", ",
					)}
				</span>
			</Row>

			<Column>
				<Label>Issue Description</Label>
				<TextAreaFieldDisplay value="" formattedValue={note.body} />
			</Column>

			<FieldSet>
				<FieldSet.Header>
					<Legend>What is the nature of the issue?</Legend>
				</FieldSet.Header>

				<FieldSet.Body>
					<Conditional>
						<If
							condition={
								noteDetails.isIssueNatureLearningDifficulty ||
								noteDetails.isIssueNatureAttentionIssue ||
								noteDetails.isIssueNatureFocusIssue ||
								noteDetails.isIssueNatureEmotionalOrVulnerableIssue ||
								noteDetails.isIssueNatureOther
							}
						>
							<List>
								<If condition={noteDetails.isIssueNatureLearningDifficulty}>
									<List.Item>Learning difficulty</List.Item>
								</If>
								<If condition={noteDetails.isIssueNatureAttentionIssue}>
									<List.Item>Attention Issue</List.Item>
								</If>
								<If condition={noteDetails.isIssueNatureFocusIssue}>
									<List.Item>Focus Issue</List.Item>
								</If>
								<If condition={noteDetails.isIssueNatureEmotionalOrVulnerableIssue}>
									<List.Item>Emotional or Vulnerable Issue</List.Item>
								</If>
								<If condition={noteDetails.isIssueNatureOther}>
									<List.Item>Other: {noteDetails.issueNatureOther}</List.Item>
								</If>
							</List>
						</If>
						<Else>
							<span>None Given</span>
						</Else>
					</Conditional>
				</FieldSet.Body>
			</FieldSet>

			<div>
				<Label>Please briefly describe the issue</Label>
				<TextAreaFieldDisplay value="" formattedValue={noteDetails.description} />
			</div>

			{/* Optional */}
			<FieldSet>
				<FieldSet.Header>
					<Legend>What is your suggested solution?</Legend>
				</FieldSet.Header>

				<FieldSet.Body>
					<Conditional>
						<If
							condition={
								noteDetails.isSolutionRecommendationDifferentiatedLearning ||
								noteDetails.isSolutionRecommendationIndividualLearningPlan ||
								noteDetails.isSolutionRecommendationTutoring ||
								noteDetails.isSolutionRecommendationProfessionalSupport ||
								noteDetails.isSolutionRecommendationInClassSupport ||
								noteDetails.isSolutionRecommendationOther
							}
						>
							<List>
								<If condition={noteDetails.isSolutionRecommendationDifferentiatedLearning}>
									<List.Item>Differentiated Learning</List.Item>
								</If>
								<If condition={noteDetails.isSolutionRecommendationIndividualLearningPlan}>
									<List.Item>Individual Learning Plan (ILP)</List.Item>
								</If>
								<If condition={noteDetails.isSolutionRecommendationTutoring}>
									<List.Item>Tutoring" /</List.Item>
								</If>
								<If condition={noteDetails.isSolutionRecommendationProfessionalSupport}>
									<List.Item>Professional support</List.Item>
								</If>
								<If condition={noteDetails.isSolutionRecommendationInClassSupport}>
									<List.Item>In-Class Support</List.Item>
								</If>
								<If condition={noteDetails.isSolutionRecommendationOther}>
									<List.Item>Other</List.Item>
								</If>
							</List>
						</If>
						<Else>
							<span>None Given</span>
						</Else>
					</Conditional>
				</FieldSet.Body>
			</FieldSet>

			<If condition={noteDetails.isInterestedInMeeting}>
				<Paragraph>* Indicated wants to meet with Demy to discuss.</Paragraph>
			</If>
		</Column>
	);
}
