import React from "react";
import { pickKeys, useUUID } from "@hex-insights/core";
import { FormState, useInternalField } from "@hex-insights/forms";
import {
	EnrollmentApplicationParentFormValues,
	EnrollmentApplicationStudentFormValues,
	EnrollmentApplicationSubmissionFormValues,
} from "../../Utilities";

export type Full = {
	students: StudentFormValues[];
	parents: ParentFormValues[];
	dataConsent: DataConsentFormValues;
	signature: SignatureFormValues;
	metaData: MetaDataFormValues;
};

// TODO siblings

export type StudentFormValues = EnrollmentApplicationStudentFormValues.EnrollmentApplicationCreate;

export type StudentViewFormValues = Omit<StudentFormValues, "image"> & {
	imageURL: string;
};

export const initialStudentFormValues: StudentFormValues =
	EnrollmentApplicationStudentFormValues.initialEnrollmentApplicationCreate;

export type ParentFormValues = EnrollmentApplicationParentFormValues.EnrollmentApplicationCreate;

export const initialParentFormValues: ParentFormValues =
	EnrollmentApplicationParentFormValues.initialEnrollmentApplicationCreate;

export type DataConsentFormValues = Pick<EnrollmentApplicationSubmissionFormValues.Create, "doesConsentToDataUse">;

export const initialDataConsentFormValues: DataConsentFormValues = pickKeys(
	EnrollmentApplicationSubmissionFormValues.initialCreate,
	["doesConsentToDataUse"],
);

export type SignatureFormValues = Pick<
	EnrollmentApplicationSubmissionFormValues.Create,
	"signatureName" | "signatureDate"
>;

export const initialSignatureFormValues: SignatureFormValues = pickKeys(
	EnrollmentApplicationSubmissionFormValues.initialCreate,
	["signatureName", "signatureDate"],
);

export type MetaDataFormValues = Pick<EnrollmentApplicationSubmissionFormValues.Create, "source">;

export const initialMetaDataFormValues: MetaDataFormValues = pickKeys(
	EnrollmentApplicationSubmissionFormValues.initialCreate,
	["source"],
);

export const initialFull: Full = {
	students: [],
	parents: [],
	dataConsent: initialDataConsentFormValues,
	signature: initialSignatureFormValues,
	metaData: initialMetaDataFormValues,
};

export function useFormIdentifier<FV extends { formIdentifier: string }>(formState: FormState<FV>) {
	const id = useUUID();
	useInternalField(formState, "formIdentifier");
	const { formIdentifier: setFormIdentifier } = formState.formSetFunctions;
	React.useEffect(() => {
		setFormIdentifier(id);
	}, [id, setFormIdentifier]);
}
