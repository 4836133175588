import React from "react";
import { ApplyCreateFunction } from "@hex-insights/app-modules";
import { Button, Column, getObjectValues, Heading, Modal, ModalProps, useToggle } from "@hex-insights/core";
import {
	DateTimeField,
	FieldDisplayArgs,
	FileField,
	FormType,
	MultiSelectField,
	RadioField,
	SelectField,
	TextField,
} from "@hex-insights/forms";
import {
	AddressCreateMutation,
	AddressFormState,
	AddressFormValues,
	AddressMutation,
	AddressSelect,
	EmailAddressCreateMutation,
	EmailAddressFormState,
	EmailAddressFormValues,
	EmailAddressMutation,
	EmailAddressSelect,
	EmployeeSelect,
	ParentSelect,
	PersonFormValues,
	PhoneNumberCreateMutation,
	PhoneNumberFormState,
	PhoneNumberFormValues,
	PhoneNumberMutation,
	PhoneNumberSelect,
	StudentSelect,
	useAddressSelectLazyQuery,
	useEmailAddressSelectLazyQuery,
	useEmployeeSelectLazyQuery,
	useParentSelectLazyQuery,
	usePhoneNumberSelectLazyQuery,
	UserSelect,
	useStudentSelectLazyQuery,
	useUserSelectLazyQuery,
} from "../../../../Utilities";
import {
	AddressLink,
	EmailAddressLink,
	EmployeeLink,
	ParentLink,
	PhoneNumberLink,
	StudentLink,
	UserLink,
} from "../../../Links";
import { AddressForm } from "../Address";
import { EmailAddressForm } from "../EmailAddress";
import { PhoneNumberForm } from "../PhoneNumber";
import { BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the Person model.
 */
type FieldProps<K extends keyof PersonFormValues.Base = keyof PersonFormValues.Base> = BaseFieldProps<
	Pick<PersonFormValues.Base, K>
>;

/**
 * Generic props for fields of the Person model that only appear in the detail form.
 */
type DetailFieldProps<K extends keyof PersonFormValues.Detail = keyof PersonFormValues.Detail> = BaseFieldProps<
	Pick<PersonFormValues.Detail, K>
>;

/**
 * Renders a field component for the `name` field of the Person model.
 */
export function Name({ formState }: DetailFieldProps<"name">) {
	return <TextField formState={formState} name="name" />;
}

/**
 * Renders a field component for the `firstName` field of the Person model.
 */
export function FirstName({ formState }: FieldProps<"firstName">) {
	return <TextField formState={formState} name="firstName" />;
}

/**
 * Renders a field component for the `lastName` field of the Person model.
 */
export function LastName({ formState }: FieldProps<"lastName">) {
	return <TextField formState={formState} name="lastName" />;
}

/**
 * Renders a field component for the `nickname` field of the Person model.
 */
export function Nickname({ formState }: FieldProps<"nickname">) {
	return <TextField formState={formState} name="nickname" optional />;
}

/**
 * Renders a field component for the `alternateNames` field of the Person model.
 */
export function AlternateNames({ formState }: FieldProps<"alternateNames">) {
	return <TextField formState={formState} name="alternateNames" optional />;
}

/**
 * Renders a field component for the `image` field of the Person model.
 */
export function Image({ formState }: FieldProps<"image">) {
	return <FileField formState={formState} name="image" optional />;
}

/**
 * Renders a field component for the `gender` field of the Person model.
 */
export function Gender({ formState }: FieldProps<"gender">) {
	return <TextField formState={formState} name="gender" optional />;
}

/**
 * Renders a field component for the `dateOfBirth` field of the Person model.
 */
export function DateOfBirth({ formState }: FieldProps<"dateOfBirth">) {
	return <DateTimeField formState={formState} name="dateOfBirth" optional precision="day" />;
}

/**
 * Renders a field component for the `occupation` field of the Person model.
 */
export function Occupation({ formState }: FieldProps<"occupation">) {
	return <TextField formState={formState} name="occupation" optional />;
}

/**
 * Renders a field component for the `nationality` field of the Person model.
 */
export function Nationality({ formState }: FieldProps<"nationality">) {
	return <TextField formState={formState} name="nationality" optional />;
}

/**
 * Renders a field component for the `primaryLanguage` field of the Person model.
 */
export function PrimaryLanguage({ formState }: FieldProps<"primaryLanguage">) {
	return <TextField formState={formState} name="primaryLanguage" optional />;
}

/**
 * Renders a field component for the `englishLanguageFluency` field of the Person model.
 */
export function EnglishLanguageFluency({
	formState,
	formType = FormType.Update,
}: FieldProps<"englishLanguageFluency">) {
	return (
		<RadioField
			formState={formState}
			name="englishLanguageFluency"
			options={PersonFormValues.englishLanguageFluencyOptions}
			blankValue={null}
			optional={FormType.isCreate(formType)}
		/>
	);
}

export type AddressesProps = FieldProps<"addressIDs"> & {
	currentAddresses?: AddressSelect.ModelForOption[];
};

/**
 * Renders a field component for the `addresses` edge of the Person model.
 */
export function Addresses({ formState, currentAddresses }: AddressesProps) {
	const [loadOptions, { loading, data }] = useAddressSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.addressIDs) {
			loadOptions();
		}
	}, [formState.formEditing.addressIDs, loadOptions]);
	const options = React.useMemo(
		() => AddressSelect.toMultiOptions(data?.addressConnection.edges, currentAddresses),
		[data, currentAddresses],
	);

	const addToCache = AddressSelect.useAddToCache();
	const { addressIDs: setAddressIDs } = formState.formSetFunctions;
	const onCreate = React.useCallback(
		(address: AddressCreateMutation["createAddress"]) => {
			addToCache(address);
			setAddressIDs((prev) => [...prev, address.id]);
		},
		[addToCache, setAddressIDs],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="addressIDs"
			isLoading={loading}
			options={options}
			displayInstance={displayAddressInstance}
			footerElement={<CreateAddress onCreate={onCreate} />}
		/>
	);
}

function displayAddressInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <AddressLink instance={{ id }}>{formattedValue}</AddressLink>;
}

type CreateAddressProps = {
	onCreate: (data: AddressCreateMutation["createAddress"]) => void;
};

function CreateAddress({ onCreate }: CreateAddressProps) {
	const { isOn: isOpen, toggle: toggleIsOpen, setIsOn: setIsOpen } = useToggle(false);

	const create = AddressMutation.useCreate();

	const applyCreate = React.useCallback(
		async (formValues: AddressFormValues.Create) => {
			const { data, errors } = await create(formValues);
			if (data !== null) {
				onCreate(data);
			}
			return errors;
		},
		[create, onCreate],
	);

	const onSuccess = React.useCallback(() => setIsOpen(false), [setIsOpen]);

	return (
		<React.Fragment>
			<Button variant="link" size="small" onClick={toggleIsOpen}>
				Add New Address
			</Button>

			<Modal.If condition={isOpen}>
				<AddressCreateModal onClose={toggleIsOpen} applyCreate={applyCreate} onSuccess={onSuccess} />
			</Modal.If>
		</React.Fragment>
	);
}

type AddressCreateModalProps = {
	applyCreate: ApplyCreateFunction<AddressFormValues.Create>;
	onSuccess: () => void;
} & Pick<ModalProps, "ifRef" | "onClose">;

function AddressCreateModal({ applyCreate, onSuccess, ifRef, onClose }: AddressCreateModalProps) {
	const formState = AddressFormState.useCreateFormState();

	const anyFieldsChanged = React.useMemo(() => {
		return getObjectValues(formState.formChanged).some((e) => e);
	}, [formState.formChanged]);

	return (
		<Modal
			ifRef={ifRef}
			onClose={onClose}
			confirmOnClose={anyFieldsChanged}
			style={{ width: "fit-content", minWidth: "none" }}
		>
			<Modal.Header>
				<Heading level={2} noMargin>
					New Address
				</Heading>
			</Modal.Header>
			<Modal.Body>
				<AddressForm.Create formState={formState} applyCreate={applyCreate} onSuccess={onSuccess} />
			</Modal.Body>
		</Modal>
	);
}

export type EmailAddressesProps = DetailFieldProps<"emailAddressIDs"> & {
	currentEmailAddresses?: EmailAddressSelect.ModelForOption[];
};

/**
 * Renders a field component for the `emailAddresses` edge of the Person model.
 */
export function EmailAddresses({ formState, currentEmailAddresses }: EmailAddressesProps) {
	const [loadOptions, { loading, data }] = useEmailAddressSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.emailAddressIDs) {
			loadOptions();
		}
	}, [formState.formEditing.emailAddressIDs, loadOptions]);
	const options = React.useMemo(
		() => EmailAddressSelect.toMultiOptions(data?.emailAddressConnection.edges, currentEmailAddresses),
		[data, currentEmailAddresses],
	);

	const addToCache = EmailAddressSelect.useAddToCache();
	const { emailAddressIDs: setEmailAddressIDs } = formState.formSetFunctions;
	const onCreate = React.useCallback(
		(emailAddress: EmailAddressCreateMutation["createEmailAddress"]) => {
			addToCache(emailAddress);
			setEmailAddressIDs((prev) => [...prev, emailAddress.id]);
		},
		[addToCache, setEmailAddressIDs],
	);

	return (
		<Column justify="spaced-start" verticalSpacing="0.25rem" align="flex-end" style={{ width: "fit-content" }}>
			<MultiSelectField
				formState={formState}
				name="emailAddressIDs"
				isLoading={loading}
				options={options}
				displayInstance={displayEmailAddressInstance}
				footerElement={<CreateEmailAddress onCreate={onCreate} />}
			/>
			<CreateEmailAddress onCreate={onCreate} />
		</Column>
	);
}

function displayEmailAddressInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <EmailAddressLink instance={{ id }}>{formattedValue}</EmailAddressLink>;
}

type CreateEmailAddressProps = {
	onCreate: (data: EmailAddressCreateMutation["createEmailAddress"]) => void;
};

function CreateEmailAddress({ onCreate }: CreateEmailAddressProps) {
	const { isOn: isOpen, toggle: toggleIsOpen, setIsOn: setIsOpen } = useToggle(false);

	const create = EmailAddressMutation.useCreateInPerson();
	const applyCreate = React.useCallback(
		async (formValues: EmailAddressFormValues.CreateInPerson) => {
			const { data, errors } = await create(formValues);
			if (data !== null) {
				onCreate(data);
			}
			return errors;
		},
		[create, onCreate],
	);

	const onSuccess = React.useCallback(() => setIsOpen(false), [setIsOpen]);

	return (
		<React.Fragment>
			<Button variant="link" size="small" onClick={toggleIsOpen}>
				Add New Email Address
			</Button>

			<Modal.If condition={isOpen}>
				<EmailAddressCreateModal onClose={toggleIsOpen} applyCreate={applyCreate} onSuccess={onSuccess} />
			</Modal.If>
		</React.Fragment>
	);
}

type EmailAddressCreateModalProps = {
	applyCreate: ApplyCreateFunction<EmailAddressFormValues.CreateInPerson>;
	onSuccess: () => void;
} & Pick<ModalProps, "ifRef" | "onClose">;

function EmailAddressCreateModal({ applyCreate, onSuccess, ifRef, onClose }: EmailAddressCreateModalProps) {
	const formState = EmailAddressFormState.useCreateInPersonFormState();

	const anyFieldsChanged = React.useMemo(() => {
		return getObjectValues(formState.formChanged).some((e) => e);
	}, [formState.formChanged]);

	return (
		<Modal
			ifRef={ifRef}
			onClose={onClose}
			confirmOnClose={anyFieldsChanged}
			style={{ width: "fit-content", minWidth: "none" }}
		>
			<Modal.Header>
				<Heading level={2} noMargin>
					New Email Address
				</Heading>
			</Modal.Header>
			<Modal.Body>
				<EmailAddressForm.CreateInPerson formState={formState} applyCreate={applyCreate} onSuccess={onSuccess} />
			</Modal.Body>
		</Modal>
	);
}

export type EmployeeProps = DetailFieldProps<"employeeID"> & {
	currentEmployee?: EmployeeSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `employee` edge of the Person model.
 */
export function Employee({ formState, currentEmployee }: EmployeeProps) {
	const [loadOptions, { loading, data }] = useEmployeeSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.employeeID) {
			loadOptions();
		}
	}, [formState.formEditing.employeeID, loadOptions]);
	const options = React.useMemo(
		() => EmployeeSelect.toOptions(data?.employeeConnection.edges, currentEmployee),
		[data, currentEmployee],
	);

	return (
		<SelectField
			formState={formState}
			name="employeeID"
			label="Employee Record"
			isLoading={loading}
			options={options}
			optional
			display={displayEmployee}
			blankValue={null}
		/>
	);
}

function displayEmployee({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <EmployeeLink instance={{ id }}>{formattedValue}</EmployeeLink>;
}

export type ParentProps = DetailFieldProps<"parentID"> & {
	currentParent?: ParentSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `parent` edge of the Person model.
 */
export function Parent({ formState, currentParent }: ParentProps) {
	const [loadOptions, { loading, data }] = useParentSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.parentID) {
			loadOptions();
		}
	}, [formState.formEditing.parentID, loadOptions]);
	const options = React.useMemo(
		() => ParentSelect.toOptions(data?.parentConnection.edges, currentParent),
		[data, currentParent],
	);

	return (
		<SelectField
			formState={formState}
			name="parentID"
			label="Parent Record"
			isLoading={loading}
			options={options}
			optional
			display={displayParent}
			blankValue={null}
		/>
	);
}

function displayParent({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <ParentLink instance={{ id }}>{formattedValue}</ParentLink>;
}

export type PhoneNumbersProps = DetailFieldProps<"phoneNumberIDs"> & {
	currentPhoneNumbers?: PhoneNumberSelect.ModelForOption[];
};

/**
 * Renders a field component for the `phoneNumbers` edge of the Person model.
 */
export function PhoneNumbers({ formState, currentPhoneNumbers }: PhoneNumbersProps) {
	const [loadOptions, { loading, data }] = usePhoneNumberSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.phoneNumberIDs) {
			loadOptions();
		}
	}, [formState.formEditing.phoneNumberIDs, loadOptions]);
	const options = React.useMemo(
		() => PhoneNumberSelect.toMultiOptions(data?.phoneNumberConnection.edges, currentPhoneNumbers),
		[data, currentPhoneNumbers],
	);

	const addToCache = PhoneNumberSelect.useAddToCache();
	const { phoneNumberIDs: setPhoneNumberIDs } = formState.formSetFunctions;
	const onCreate = React.useCallback(
		(phoneNumber: PhoneNumberCreateMutation["createPhoneNumber"]) => {
			addToCache(phoneNumber);
			setPhoneNumberIDs((prev) => [...prev, phoneNumber.id]);
		},
		[addToCache, setPhoneNumberIDs],
	);

	return (
		<Column justify="spaced-start" verticalSpacing="0.25rem" align="flex-end" style={{ width: "fit-content" }}>
			<MultiSelectField
				formState={formState}
				name="phoneNumberIDs"
				isLoading={loading}
				options={options}
				displayInstance={displayPhoneNumberInstance}
				footerElement={<CreatePhoneNumber onCreate={onCreate} />}
			/>
			<CreatePhoneNumber onCreate={onCreate} />
		</Column>
	);
}

function displayPhoneNumberInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <PhoneNumberLink instance={{ id }}>{formattedValue}</PhoneNumberLink>;
}

type CreatePhoneNumberProps = {
	onCreate: (data: PhoneNumberCreateMutation["createPhoneNumber"]) => void;
};

function CreatePhoneNumber({ onCreate }: CreatePhoneNumberProps) {
	const { isOn: isOpen, toggle: toggleIsOpen, setIsOn: setIsOpen } = useToggle(false);

	const create = PhoneNumberMutation.useCreateInPerson();
	const applyCreate = React.useCallback(
		async (formValues: PhoneNumberFormValues.CreateInPerson) => {
			const { data, errors } = await create(formValues);
			if (data !== null) {
				onCreate(data);
			}
			return errors;
		},
		[create, onCreate],
	);

	const onSuccess = React.useCallback(() => setIsOpen(false), [setIsOpen]);

	return (
		<React.Fragment>
			<Button variant="link" size="small" onClick={toggleIsOpen}>
				Add New Phone Number
			</Button>

			<Modal.If condition={isOpen}>
				<PhoneNumberCreateModal onClose={toggleIsOpen} applyCreate={applyCreate} onSuccess={onSuccess} />
			</Modal.If>
		</React.Fragment>
	);
}

type PhoneNumberCreateModalProps = {
	applyCreate: ApplyCreateFunction<PhoneNumberFormValues.CreateInPerson>;
	onSuccess: () => void;
} & Pick<ModalProps, "ifRef" | "onClose">;

function PhoneNumberCreateModal({ applyCreate, onSuccess, ifRef, onClose }: PhoneNumberCreateModalProps) {
	const formState = PhoneNumberFormState.useCreateInPersonFormState();

	const anyFieldsChanged = React.useMemo(() => {
		return getObjectValues(formState.formChanged).some((e) => e);
	}, [formState.formChanged]);

	return (
		<Modal
			ifRef={ifRef}
			onClose={onClose}
			confirmOnClose={anyFieldsChanged}
			style={{ width: "fit-content", minWidth: "none" }}
		>
			<Modal.Header>
				<Heading level={2} noMargin>
					New Phone Number
				</Heading>
			</Modal.Header>
			<Modal.Body>
				<PhoneNumberForm.CreateInPerson formState={formState} applyCreate={applyCreate} onSuccess={onSuccess} />
			</Modal.Body>
		</Modal>
	);
}

export type StudentProps = DetailFieldProps<"studentID"> & {
	currentStudent?: StudentSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `student` edge of the Person model.
 */
export function Student({ formState, currentStudent }: StudentProps) {
	const [loadOptions, { loading, data }] = useStudentSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.studentID) {
			loadOptions();
		}
	}, [formState.formEditing.studentID, loadOptions]);
	const options = React.useMemo(
		() => StudentSelect.toOptions(data?.studentConnection.edges, currentStudent),
		[data, currentStudent],
	);

	return (
		<SelectField
			formState={formState}
			name="studentID"
			label="Student Record"
			isLoading={loading}
			options={options}
			optional
			display={displayStudent}
			blankValue={null}
		/>
	);
}

function displayStudent({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <StudentLink instance={{ id }}>{formattedValue}</StudentLink>;
}

export type UserProps = FieldProps<"userID"> & {
	currentUser?: UserSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `user` edge of the Person model.
 */
export function User({ formState, currentUser }: UserProps) {
	const [loadOptions, { loading, data }] = useUserSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.userID) {
			loadOptions();
		}
	}, [formState.formEditing.userID, loadOptions]);
	const options = React.useMemo(
		() => UserSelect.toOptions(data?.userConnection.edges, currentUser),
		[data, currentUser],
	);

	return (
		<SelectField
			formState={formState}
			name="userID"
			label="User Account"
			isLoading={loading}
			options={options}
			optional
			display={displayUser}
			blankValue={null}
		/>
	);
}

function displayUser({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <UserLink instance={{ id }}>{formattedValue}</UserLink>;
}
