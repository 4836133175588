import React from "react";
import { DistributiveOmit } from "@hex-insights/core";
import { EditMode, FormType, useFormState, UseFormStateArgs } from "@hex-insights/forms";
import { SchoolStudentEnrollmentFormValues } from "../ModelFormValues";

export type UseCreateFormStateArgs = DistributiveOmit<
	UseFormStateArgs<SchoolStudentEnrollmentFormValues.Create>,
	"initialFormValues"
> & {
	initialFormValues?: Partial<SchoolStudentEnrollmentFormValues.Create>;
};

/**
 * Returns a `formState` object for use in the create form for the SchoolStudentEnrollment model.
 */
export function useCreateFormState({
	initialFormValues,
	formType = FormType.Create,
	...args
}: UseCreateFormStateArgs = {}) {
	return useFormState({
		initialFormValues: React.useMemo(
			() => ({ ...SchoolStudentEnrollmentFormValues.initialCreate, ...initialFormValues }),
			[initialFormValues],
		),
		formType,
		...args,
	});
}

export type UseDetailFormStateArgs = UseFormStateArgs<SchoolStudentEnrollmentFormValues.Detail>;

const detailFieldEditModes = {
	createdDate: EditMode.ReadOnly,
	academicYearID: EditMode.ReadOnly,
	studentID: EditMode.ReadOnly,
};

/**
 * Returns a `formState` object for use in the detail form of the SchoolStudentEnrollment model.
 *
 * @param initialFormValues The initial values for the form. Generally from
 * {@link SchoolStudentEnrollmentFormConversion.toFormValues}.
 */
export function useDetailFormState({
	initialFormValues,
	formType = FormType.Update,
	fieldEditModes = detailFieldEditModes,
	...args
}: UseDetailFormStateArgs) {
	return useFormState({ initialFormValues, formType, fieldEditModes, ...args });
}

export type UseReadOnlyFormStateArgs = UseFormStateArgs<SchoolStudentEnrollmentFormValues.Detail>;

/**
 * Returns a read-only `formState` object for use in the detail form of the SchoolStudentEnrollment model.
 *
 * @param initialFormValues The initial values for the form. Generally from
 * {@link SchoolStudentEnrollmentFormConversion.toFormValues}.
 */
export function useReadOnlyFormState({
	initialFormValues,
	formType = FormType.View,
	...args
}: UseReadOnlyFormStateArgs) {
	return useFormState({ initialFormValues, formType, ...args });
}
