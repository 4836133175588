import { newPageInfo } from "@hex-insights/router";
import { rootPageInfo, RootPageRouteParams } from "../../root.pageinfo";

export type LessonPlansPageRouteParams = RootPageRouteParams;

export const lessonPlansPageInfo = newPageInfo({
	title: "Lesson Plans",
	description: "Lesson plan overview and creation.",
	exact: false,
	to: "/lesson-plans",
	path: "/lesson-plans",
	parent: rootPageInfo,
});
