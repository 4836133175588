import { IndexForms } from "@hex-insights/app-modules";
import { CaseStyle, convertCase } from "@hex-insights/core";
import { DocumentResourceUserViewRecordOrderField, OrderDirection } from "../../GraphQL";

export type FormValues = IndexForms.OrderFormValues<DocumentResourceUserViewRecordOrderField | null>;

export const initial: FormValues = {
	field: DocumentResourceUserViewRecordOrderField.ViewedAt,
	direction: OrderDirection.Asc,
};

/**
 * Options for the "field" field in the order form of the DocumentResourceUserViewRecord model.
 */
export const fieldOptions = [
	{ label: "ID", value: null },
	{
		label: convertCase(DocumentResourceUserViewRecordOrderField.ViewedAt.toLowerCase(), CaseStyle.Title),
		value: DocumentResourceUserViewRecordOrderField.ViewedAt,
	},
];

/**
 * Array of values for the "field" field in the order form of the DocumentResourceUserViewRecord model.
 */
export const fieldValues = fieldOptions.map((e) => e.value);

/**
 * Mapping from order fields of the DocumentResourceUserViewRecord model to their corresponding order type.
 */
export const fieldTypeMapping = {
	[DocumentResourceUserViewRecordOrderField.ViewedAt]: IndexForms.OrderFieldType.Time,
};

/**
 * Mapping from order fields of the DocumentResourceUserViewRecord model to their corresponding field name.
 */
export const orderFieldToModelField = {
	[DocumentResourceUserViewRecordOrderField.ViewedAt]: "viewedAt",
};

/**
 * Mapping from field names of the DocumentResourceUserViewRecord model to their corresponding order field.
 */
export const modelFieldToOrderField = {
	viewedAt: DocumentResourceUserViewRecordOrderField.ViewedAt,
};
