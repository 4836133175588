import { Route, useActivePageRegistration, useRouteMatch, useRouteParams } from "@hex-insights/router";
import { useTermDetailQuery } from "@hex-insights/verita.shared";
import { PageRouteSwitch } from "../../../Components";
import { ReportCardsTermCourseSectionPage } from "./CourseSection";
import {
	reportCardsTermCourseSectionPageInfo,
	ReportCardsTermCourseSectionPageRouteParams,
} from "./CourseSection/pageinfo";
import { ReportCardsTermIndexPage } from "./IndexPage";
import { reportCardsTermIndexPageInfo } from "./IndexPage/pageinfo";
import { reportCardsTermPageInfo, ReportCardsTermPageRouteParams } from "./pageinfo";

export function ReportCardsTermPage() {
	const { termID } = useRouteParams<ReportCardsTermPageRouteParams>();
	const { data } = useTermDetailQuery({ variables: { id: termID } });

	useActivePageRegistration(reportCardsTermPageInfo, data ? data.term.name : "");

	return (
		<PageRouteSwitch>
			<Route {...reportCardsTermIndexPageInfo.routeProps}>
				<ReportCardsTermIndexPage />
			</Route>
			<Route {...reportCardsTermCourseSectionPageInfo.routeProps}>
				<ReportCardsTermCourseSectionPage
					key={
						useRouteMatch<ReportCardsTermCourseSectionPageRouteParams>(
							reportCardsTermCourseSectionPageInfo.path as string,
						)?.params.courseSectionID
					}
				/>
			</Route>
		</PageRouteSwitch>
	);
}
