import { Models } from "@hex-insights/app-modules";
import { DistributiveOmit } from "@hex-insights/core";
import { EmailAddress, EmailAddressFormat, EmailAddressNameFragment } from "../../../../Utilities";

type ModelForLink = Pick<EmailAddress, "id"> & EmailAddressNameFragment;

export type EmailAddressLinkProps = DistributiveOmit<Models.LinkProps<ModelForLink>, "pageInfoKey" | "format">;

/**
 * Renders a link to the given EmailAddress instance.
 */
export function EmailAddressLink(props: EmailAddressLinkProps) {
	return <Models.Link<ModelForLink> pageInfoKey="EmailAddress" format={EmailAddressFormat.name} {...props} />;
}
