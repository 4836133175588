import { addTimeToDate, dateTrunc, formatDateTime } from "@hex-insights/core";
import { newPageInfo } from "@hex-insights/router";
import { lessonPlansPageInfo, LessonPlansPageRouteParams } from "../pageinfo";

export type LessonPlansWeekPageRouteParams = LessonPlansPageRouteParams & {
	date: string;
};

export const lessonPlansWeekPageInfo = newPageInfo({
	name: "Lesson Plans",
	title: (date: string) => formatDateTime(toMondayStart(date), "D MMMM YYYY"),
	description: "Lesson plans overview.",
	exact: false,
	to: (date: string) => lessonPlansPageInfo.to + `/${formatDateTime(toMondayStart(date), "YYYY-MM-DD")}`,
	path: lessonPlansPageInfo.path + "/:date(\\d{4}-\\d{2}-\\d{2})",
	parent: lessonPlansPageInfo,
});

function toMondayStart(date: string) {
	return addTimeToDate(dateTrunc(date, "week"), [1, "day"]);
}
