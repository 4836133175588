// Code generated by @hex-insights/frontend-generator; DO NOT EDIT.
import { loginPageInfo } from "./Pages/Authentication/Login/pageinfo";
import { logoutPageInfo } from "./Pages/Authentication/Logout/pageinfo";
import { mfaLoginPageInfo } from "./Pages/Authentication/MFALogin/pageinfo";
import { recoverPageInfo } from "./Pages/Authentication/Recover/pageinfo";
import { setPasswordPageInfo } from "./Pages/Authentication/SetPassword/pageinfo";
import { homePageInfo } from "./Pages/Home/pageinfo";
import { lessonPlansPageInfo } from "./Pages/LessonPlans/pageinfo";
import { lessonPlansWeekCourseSectionDayPageInfo } from "./Pages/LessonPlans/Week/CourseSection/Day/pageinfo";
import { lessonPlansWeekCourseSectionIndexPageInfo } from "./Pages/LessonPlans/Week/CourseSection/IndexPage/pageinfo";
import { lessonPlansWeekCourseSectionPageInfo } from "./Pages/LessonPlans/Week/CourseSection/pageinfo";
import { lessonPlansWeekIndexPageInfo } from "./Pages/LessonPlans/Week/IndexPage/pageinfo";
import { lessonPlansWeekPageInfo } from "./Pages/LessonPlans/Week/pageinfo";
import { reportCardsIndexPageInfo } from "./Pages/ReportCards/IndexPage/pageinfo";
import { reportCardsPageInfo } from "./Pages/ReportCards/pageinfo";
import { reportCardsTermCourseSectionIndexPageInfo } from "./Pages/ReportCards/Term/CourseSection/IndexPage/pageinfo";
import { reportCardsTermCourseSectionPageInfo } from "./Pages/ReportCards/Term/CourseSection/pageinfo";
import { reportCardsTermCourseSectionStudentPageInfo } from "./Pages/ReportCards/Term/CourseSection/Student/pageinfo";
import { reportCardsTermIndexPageInfo } from "./Pages/ReportCards/Term/IndexPage/pageinfo";
import { reportCardsTermPageInfo } from "./Pages/ReportCards/Term/pageinfo";
import { scheduleDayCoursePeriodPageInfo } from "./Pages/Schedule/Day/CoursePeriod/pageinfo";
import { scheduleDayIndexPageInfo } from "./Pages/Schedule/Day/IndexPage/pageinfo";
import { scheduleDayPageInfo } from "./Pages/Schedule/Day/pageinfo";
import { schedulePageInfo } from "./Pages/Schedule/pageinfo";
import { studentHubDetailAcademicsPageInfo } from "./Pages/Students/DetailPage/AcademicsPage/pageinfo";
import { studentHubDetailFamilyAndHealthPageInfo } from "./Pages/Students/DetailPage/FamilyAndHealth/pageinfo";
import { studentHubDetailOverviewPageInfo } from "./Pages/Students/DetailPage/OverviewPage/pageinfo";
import { studentHubDetailPageInfo } from "./Pages/Students/DetailPage/pageinfo";
import { studentHubPageInfo } from "./Pages/Students/pageinfo";
import { rootPageInfo } from "./root.pageinfo";

export const allPages = [
	loginPageInfo,
	logoutPageInfo,
	mfaLoginPageInfo,
	recoverPageInfo,
	setPasswordPageInfo,
	homePageInfo,
	lessonPlansWeekCourseSectionDayPageInfo,
	lessonPlansWeekCourseSectionIndexPageInfo,
	lessonPlansWeekCourseSectionPageInfo,
	lessonPlansWeekIndexPageInfo,
	lessonPlansWeekPageInfo,
	lessonPlansPageInfo,
	reportCardsIndexPageInfo,
	reportCardsTermCourseSectionIndexPageInfo,
	reportCardsTermCourseSectionStudentPageInfo,
	reportCardsTermCourseSectionPageInfo,
	reportCardsTermIndexPageInfo,
	reportCardsTermPageInfo,
	reportCardsPageInfo,
	scheduleDayCoursePeriodPageInfo,
	scheduleDayIndexPageInfo,
	scheduleDayPageInfo,
	schedulePageInfo,
	studentHubDetailAcademicsPageInfo,
	studentHubDetailFamilyAndHealthPageInfo,
	studentHubDetailOverviewPageInfo,
	studentHubDetailPageInfo,
	studentHubPageInfo,
	rootPageInfo,
];
