import React from "react";
import { ApolloCache, FetchResult, gql } from "@apollo/client";
import {
	BusPlan,
	Contract,
	CourseAttendanceRecord,
	graphTypeNames,
	MealPlan,
	Note,
	Person,
	Relationship,
	ReportCard,
	SchoolAttendanceRecord,
	Student,
	StudentCreateInput,
	StudentCreateMutation,
	StudentDeleteMutation,
	StudentDetailDocument,
	StudentDetailQuery,
	StudentDetailQueryVariables,
	StudentDocument,
	StudentUpdateInput,
	StudentUpdateMutation,
	StudentUpdateWithPersonMutation,
	Tuition,
	useStudentCreateMutation,
	useStudentDeleteMutation,
	useStudentUpdateMutation,
	useStudentUpdateWithPersonMutation,
} from "../../GraphQL";
import { StudentFormConversion } from "../ModelFormConversion";
import { StudentFormValues } from "../ModelFormValues";
import * as PersonMutation from "./person";

/**
 * Returns a `create` function for the Student model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the Student model.
 */
export function useCreate() {
	const [createStudent] = useStudentCreateMutation();

	return React.useCallback(
		async (formValues: StudentFormValues.Create) => {
			const input = StudentFormConversion.toGQLCreateInput(formValues);
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createStudent({ variables: { input }, update: updateCache });

			return { data: data?.createStudent ?? null, errors: errors ?? null };
		},
		[createStudent],
	);
}

/**
 * Returns a `create` function for the Student model with a nested Person model. The `create` function translates the
 * given `formValues` to the GraphQL create input for the Student model.
 */
export function useCreateWithPerson() {
	const [createStudent] = useStudentCreateMutation();

	return React.useCallback(
		async (formValues: StudentFormValues.CreateWithPerson) => {
			const input = StudentFormConversion.toGQLCreateWithPersonInput(formValues);
			// TODO update person cache
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createStudent({ variables: { input }, update: updateCache });

			return { data: data?.createStudent ?? null, errors: errors ?? null };
		},
		[createStudent],
	);
}

/**
 * Returns an `update` function for the Student model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the Student model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: Student["id"]) {
	const [updateStudent] = useStudentUpdateMutation();

	return React.useCallback(
		async (
			changedFormValues: Partial<StudentFormValues.Detail>,
			initialFormValues: Partial<StudentFormValues.Detail>,
		) => {
			const input = StudentFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

			const { data, errors } = await updateStudent({ variables: { id, input }, update: updateCache });

			return { data: data?.updateStudent ?? null, errors: errors ?? null };
		},
		[updateStudent, id],
	);
}

export function useUpdateWithPerson(id: Student["id"]) {
	const [updateStudent] = useStudentUpdateWithPersonMutation();

	return React.useCallback(
		async (
			changedFormValues: Partial<StudentFormValues.DetailWithPerson>,
			initialFormValues: Partial<StudentFormValues.DetailWithPerson>,
		) => {
			const [input, personInput] = StudentFormConversion.toGQLUpdateWithPersonInput(
				changedFormValues,
				initialFormValues,
			);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);
			const updatePersonCache = PersonMutation.getUpdateCacheForUpdate(personInput, initialFormValues);
			const update = (
				cache: ApolloCache<any>,
				result: Omit<FetchResult<StudentUpdateWithPersonMutation, Record<string, any>, Record<string, any>>, "context">,
			) => {
				updateCache(cache, {
					...result,
					data: result.data ? { updateStudent: result.data.updateStudentWithPerson } : undefined,
				});
				updatePersonCache(cache, {
					...result,
					data: result.data ? { updatePerson: result.data.updateStudentWithPerson.person } : undefined,
				});
			};

			const { data, errors } = await updateStudent({ variables: { id, input, personInput }, update });

			return { data: data?.updateStudentWithPerson ?? null, errors: errors ?? null };
		},
		[updateStudent, id],
	);
}

/**
 * Returns a `del` function for the Student model.
 *
 * @param id The ID of the instance to delete.
 */
export function useDelete(id: Student["id"]) {
	const [deleteStudent] = useStudentDeleteMutation();

	return React.useCallback(async () => {
		const updateCache = getUpdateCacheForDelete(id);

		const { data, errors } = await deleteStudent({ variables: { id }, update: updateCache });

		return { data: data?.deleteStudent ?? false, errors: errors ?? null };
	}, [deleteStudent, id]);
}

function getUpdateCacheForCreate(input: StudentCreateInput) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<StudentCreateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const createdObject = result.data.createStudent;

		cache.writeQuery<StudentDetailQuery, StudentDetailQueryVariables>({
			query: StudentDetailDocument,
			data: { student: createdObject },
			variables: { id: createdObject.id },
		});

		if (input.busPlanIDs) {
			for (let i = 0; i < input.busPlanIDs.length; i++) {
				addToStudentOfBusPlanCache(cache, input.busPlanIDs[i], createdObject);
			}
		}

		if (input.contractIDs) {
			for (let i = 0; i < input.contractIDs.length; i++) {
				addToStudentOfContractCache(cache, input.contractIDs[i], createdObject);
			}
		}

		if (input.courseAttendanceRecordIDs) {
			for (let i = 0; i < input.courseAttendanceRecordIDs.length; i++) {
				addToStudentOfCourseAttendanceRecordCache(cache, input.courseAttendanceRecordIDs[i], createdObject);
			}
		}

		if (input.mealPlanIDs) {
			for (let i = 0; i < input.mealPlanIDs.length; i++) {
				addToStudentOfMealPlanCache(cache, input.mealPlanIDs[i], createdObject);
			}
		}

		if (input.noteIDs) {
			for (let i = 0; i < input.noteIDs.length; i++) {
				addToStudentsOfNoteCache(cache, input.noteIDs[i], createdObject);
			}
		}

		if (input.personID) {
			addToStudentOfPersonCache(cache, input.personID, createdObject);
		}

		if (input.relationshipIDs) {
			for (let i = 0; i < input.relationshipIDs.length; i++) {
				addToStudentOfRelationshipCache(cache, input.relationshipIDs[i], createdObject);
			}
		}

		if (input.reportCardIDs) {
			for (let i = 0; i < input.reportCardIDs.length; i++) {
				addToStudentOfReportCardCache(cache, input.reportCardIDs[i], createdObject);
			}
		}

		if (input.schoolAttendanceRecordIDs) {
			for (let i = 0; i < input.schoolAttendanceRecordIDs.length; i++) {
				addToStudentOfSchoolAttendanceRecordCache(cache, input.schoolAttendanceRecordIDs[i], createdObject);
			}
		}

		if (input.studentDocumentIDs) {
			for (let i = 0; i < input.studentDocumentIDs.length; i++) {
				addToStudentOfStudentDocumentCache(cache, input.studentDocumentIDs[i], createdObject);
			}
		}

		if (input.tuitionID) {
			addToStudentsOfTuitionCache(cache, input.tuitionID, createdObject);
		}

		cache.evict({ id: "ROOT_QUERY", fieldName: "studentConnection" });
	};
}

function getUpdateCacheForUpdate(input: StudentUpdateInput, initialFormValues: Partial<StudentFormValues.Detail>) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<StudentUpdateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const updatedObject = result.data.updateStudent;

		if (input.addBusPlanIDs) {
			for (let i = 0; i < input.addBusPlanIDs.length; i++) {
				addToStudentOfBusPlanCache(cache, input.addBusPlanIDs[i], updatedObject);
			}
		}
		if (input.removeBusPlanIDs) {
			for (let i = 0; i < input.removeBusPlanIDs.length; i++) {
				removeFromStudentOfBusPlanCache(cache, input.removeBusPlanIDs[i], updatedObject);
			}
		}

		if (input.addContractIDs) {
			for (let i = 0; i < input.addContractIDs.length; i++) {
				addToStudentOfContractCache(cache, input.addContractIDs[i], updatedObject);
			}
		}
		if (input.removeContractIDs) {
			for (let i = 0; i < input.removeContractIDs.length; i++) {
				removeFromStudentOfContractCache(cache, input.removeContractIDs[i], updatedObject);
			}
		}

		if (input.addCourseAttendanceRecordIDs) {
			for (let i = 0; i < input.addCourseAttendanceRecordIDs.length; i++) {
				addToStudentOfCourseAttendanceRecordCache(cache, input.addCourseAttendanceRecordIDs[i], updatedObject);
			}
		}
		if (input.removeCourseAttendanceRecordIDs) {
			for (let i = 0; i < input.removeCourseAttendanceRecordIDs.length; i++) {
				removeFromStudentOfCourseAttendanceRecordCache(cache, input.removeCourseAttendanceRecordIDs[i], updatedObject);
			}
		}

		if (input.addMealPlanIDs) {
			for (let i = 0; i < input.addMealPlanIDs.length; i++) {
				addToStudentOfMealPlanCache(cache, input.addMealPlanIDs[i], updatedObject);
			}
		}
		if (input.removeMealPlanIDs) {
			for (let i = 0; i < input.removeMealPlanIDs.length; i++) {
				removeFromStudentOfMealPlanCache(cache, input.removeMealPlanIDs[i], updatedObject);
			}
		}

		if (input.addNoteIDs) {
			for (let i = 0; i < input.addNoteIDs.length; i++) {
				addToStudentsOfNoteCache(cache, input.addNoteIDs[i], updatedObject);
			}
		}
		if (input.removeNoteIDs) {
			for (let i = 0; i < input.removeNoteIDs.length; i++) {
				removeFromStudentsOfNoteCache(cache, input.removeNoteIDs[i], updatedObject);
			}
		}

		if (initialFormValues.personID && input.personID) {
			removeFromStudentOfPersonCache(cache, initialFormValues.personID, updatedObject);
		}
		if (input.personID) {
			addToStudentOfPersonCache(cache, input.personID, updatedObject);
		}

		if (input.addRelationshipIDs) {
			for (let i = 0; i < input.addRelationshipIDs.length; i++) {
				addToStudentOfRelationshipCache(cache, input.addRelationshipIDs[i], updatedObject);
			}
		}
		if (input.removeRelationshipIDs) {
			for (let i = 0; i < input.removeRelationshipIDs.length; i++) {
				removeFromStudentOfRelationshipCache(cache, input.removeRelationshipIDs[i], updatedObject);
			}
		}

		if (input.addReportCardIDs) {
			for (let i = 0; i < input.addReportCardIDs.length; i++) {
				addToStudentOfReportCardCache(cache, input.addReportCardIDs[i], updatedObject);
			}
		}
		if (input.removeReportCardIDs) {
			for (let i = 0; i < input.removeReportCardIDs.length; i++) {
				removeFromStudentOfReportCardCache(cache, input.removeReportCardIDs[i], updatedObject);
			}
		}

		if (input.addSchoolAttendanceRecordIDs) {
			for (let i = 0; i < input.addSchoolAttendanceRecordIDs.length; i++) {
				addToStudentOfSchoolAttendanceRecordCache(cache, input.addSchoolAttendanceRecordIDs[i], updatedObject);
			}
		}
		if (input.removeSchoolAttendanceRecordIDs) {
			for (let i = 0; i < input.removeSchoolAttendanceRecordIDs.length; i++) {
				removeFromStudentOfSchoolAttendanceRecordCache(cache, input.removeSchoolAttendanceRecordIDs[i], updatedObject);
			}
		}

		if (input.addStudentDocumentIDs) {
			for (let i = 0; i < input.addStudentDocumentIDs.length; i++) {
				addToStudentOfStudentDocumentCache(cache, input.addStudentDocumentIDs[i], updatedObject);
			}
		}
		if (input.removeStudentDocumentIDs) {
			for (let i = 0; i < input.removeStudentDocumentIDs.length; i++) {
				removeFromStudentOfStudentDocumentCache(cache, input.removeStudentDocumentIDs[i], updatedObject);
			}
		}

		if (initialFormValues.tuitionID && (input.tuitionID || input.clearTuition)) {
			removeFromStudentsOfTuitionCache(cache, initialFormValues.tuitionID, updatedObject);
		}
		if (input.tuitionID) {
			addToStudentsOfTuitionCache(cache, input.tuitionID, updatedObject);
		}
	};
}

function getUpdateCacheForDelete(id: Student["id"]) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<StudentDeleteMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (!result.data?.deleteStudent) {
			return;
		}

		cache.evict({ id: cache.identify({ id, __typename: graphTypeNames.Student }) });
		cache.evict({ id: "ROOT_QUERY", fieldName: "studentConnection" });
		cache.gc();
	};
}

function addToStudentOfBusPlanCache(cache: ApolloCache<any>, targetID: BusPlan["id"], object: Pick<Student, "id">) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.BusPlan }),
		fields: {
			student: () => objectRef,
			studentID: () => object.id,
		},
	});
}

function removeFromStudentOfBusPlanCache(
	cache: ApolloCache<any>,
	targetID: BusPlan["id"],
	_object: Pick<Student, "id">,
) {
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.BusPlan }),
		fields: {
			student: () => null,
			studentID: () => null,
		},
	});
}

function addToStudentOfContractCache(cache: ApolloCache<any>, targetID: Contract["id"], object: Pick<Student, "id">) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Contract }),
		fields: {
			student: () => objectRef,
			studentID: () => object.id,
		},
	});
}

function removeFromStudentOfContractCache(
	cache: ApolloCache<any>,
	targetID: Contract["id"],
	_object: Pick<Student, "id">,
) {
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Contract }),
		fields: {
			student: () => null,
			studentID: () => null,
		},
	});
}

function addToStudentOfCourseAttendanceRecordCache(
	cache: ApolloCache<any>,
	targetID: CourseAttendanceRecord["id"],
	object: Pick<Student, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.CourseAttendanceRecord }),
		fields: {
			student: () => objectRef,
			studentID: () => object.id,
		},
	});
}

function removeFromStudentOfCourseAttendanceRecordCache(
	cache: ApolloCache<any>,
	targetID: CourseAttendanceRecord["id"],
	_object: Pick<Student, "id">,
) {
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.CourseAttendanceRecord }),
		fields: {
			student: () => null,
			studentID: () => null,
		},
	});
}

function addToStudentOfMealPlanCache(cache: ApolloCache<any>, targetID: MealPlan["id"], object: Pick<Student, "id">) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.MealPlan }),
		fields: {
			student: () => objectRef,
			studentID: () => object.id,
		},
	});
}

function removeFromStudentOfMealPlanCache(
	cache: ApolloCache<any>,
	targetID: MealPlan["id"],
	_object: Pick<Student, "id">,
) {
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.MealPlan }),
		fields: {
			student: () => null,
			studentID: () => null,
		},
	});
}

function addToStudentsOfNoteCache(cache: ApolloCache<any>, targetID: Note["id"], object: Pick<Student, "id">) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Note }),
		fields: {
			students: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromStudentsOfNoteCache(cache: ApolloCache<any>, targetID: Note["id"], object: Pick<Student, "id">) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Note }),
		fields: {
			students: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function addToStudentOfPersonCache(cache: ApolloCache<any>, targetID: Person["id"], object: Pick<Student, "id">) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Person }),
		fields: {
			student: () => objectRef,
		},
	});
}

function removeFromStudentOfPersonCache(cache: ApolloCache<any>, targetID: Person["id"], _object: Pick<Student, "id">) {
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Person }),
		fields: {
			student: () => null,
		},
	});
}

function addToStudentOfRelationshipCache(
	cache: ApolloCache<any>,
	targetID: Relationship["id"],
	object: Pick<Student, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Relationship }),
		fields: {
			student: () => objectRef,
			studentID: () => object.id,
		},
	});
}

function removeFromStudentOfRelationshipCache(
	cache: ApolloCache<any>,
	targetID: Relationship["id"],
	_object: Pick<Student, "id">,
) {
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Relationship }),
		fields: {
			student: () => null,
			studentID: () => null,
		},
	});
}

function addToStudentOfReportCardCache(
	cache: ApolloCache<any>,
	targetID: ReportCard["id"],
	object: Pick<Student, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.ReportCard }),
		fields: {
			student: () => objectRef,
			studentID: () => object.id,
		},
	});
}

function removeFromStudentOfReportCardCache(
	cache: ApolloCache<any>,
	targetID: ReportCard["id"],
	_object: Pick<Student, "id">,
) {
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.ReportCard }),
		fields: {
			student: () => null,
			studentID: () => null,
		},
	});
}

function addToStudentOfSchoolAttendanceRecordCache(
	cache: ApolloCache<any>,
	targetID: SchoolAttendanceRecord["id"],
	object: Pick<Student, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.SchoolAttendanceRecord }),
		fields: {
			student: () => objectRef,
			studentID: () => object.id,
		},
	});
}

function removeFromStudentOfSchoolAttendanceRecordCache(
	cache: ApolloCache<any>,
	targetID: SchoolAttendanceRecord["id"],
	_object: Pick<Student, "id">,
) {
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.SchoolAttendanceRecord }),
		fields: {
			student: () => null,
			studentID: () => null,
		},
	});
}

function addToStudentOfStudentDocumentCache(
	cache: ApolloCache<any>,
	targetID: StudentDocument["id"],
	object: Pick<Student, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.StudentDocument }),
		fields: {
			student: () => objectRef,
			studentID: () => object.id,
		},
	});
}

function removeFromStudentOfStudentDocumentCache(
	cache: ApolloCache<any>,
	targetID: StudentDocument["id"],
	_object: Pick<Student, "id">,
) {
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.StudentDocument }),
		fields: {
			student: () => null,
			studentID: () => null,
		},
	});
}

function addToStudentsOfTuitionCache(cache: ApolloCache<any>, targetID: Tuition["id"], object: Pick<Student, "id">) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Tuition }),
		fields: {
			students: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromStudentsOfTuitionCache(
	cache: ApolloCache<any>,
	targetID: Tuition["id"],
	object: Pick<Student, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Tuition }),
		fields: {
			students: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function toCacheRef(cache: ApolloCache<any>, object: Pick<Student, "id">) {
	return cache.writeFragment({
		fragment: gql`
			fragment StudentRef on Student {
				id
			}
		`,
		data: object,
	});
}
