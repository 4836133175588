import React from "react";
import { ApplyCreateFunction, ApplyUpdateFunction, onCreateSubmit, onUpdateSubmit } from "@hex-insights/app-modules";
import {
	Button,
	Column,
	formatDateTime,
	Heading,
	HeadingLevel,
	If,
	Row,
	stringToLocalDate,
	toHeadingLevel,
} from "@hex-insights/core";
import {
	anyFieldEditing,
	everyFieldEditing,
	FieldDisplayArgs,
	Form,
	FormState,
	FormSubmitFunction,
	FormType,
	SubmissionStatus,
	SubmitButton,
} from "@hex-insights/forms";
import {
	LessonPlanDetailQuery,
	LessonPlanFormConversion,
	LessonPlanFormState,
	LessonPlanFormValues,
} from "../../../../Utilities";
import * as Field from "./Field";

export type ControlledCreateProps = {
	applyCreate: ApplyCreateFunction<LessonPlanFormValues.Create>;
	onSuccess: () => void;
};

/**
 * Renders the create form of the LessonPlan model using an internally managed form state.
 */
export function ControlledCreate(props: ControlledCreateProps) {
	const formState = LessonPlanFormState.useCreateFormState();

	return <Create formState={formState} {...props} />;
}

export type CreateProps = ControlledCreateProps & {
	formState: FormState<LessonPlanFormValues.Create>;
};

/**
 * Renders the create form of the LessonPlan model using the given form state.
 */
export function Create({ formState, applyCreate, onSuccess }: CreateProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<LessonPlanFormValues.Create>>(
		async ({ formValues }) => {
			return onCreateSubmit(formValues, applyCreate);
		},
		[applyCreate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	return (
		<Form name="lessonPlan.create" formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)}>
			<Column justify="spaced-start">
				<Field.CourseDate formState={formState} formType={FormType.Create} />
				<Field.LessonDescription formState={formState} formType={FormType.Create} />
				<Field.RequiredResourcesAndMaterials formState={formState} formType={FormType.Create} />
				<Field.StationOneDescription formState={formState} formType={FormType.Create} />
				<Field.StationTwoDescription formState={formState} formType={FormType.Create} />
				<Field.StationThreeDescription formState={formState} formType={FormType.Create} />
				<Field.DifferentiationDescription formState={formState} formType={FormType.Create} />
				<Field.AssessmentDescription formState={formState} formType={FormType.Create} />
				<Field.Notes formState={formState} formType={FormType.Create} />
				<Field.CourseSection formState={formState} formType={FormType.Create} />
				<Field.LearningObjectives formState={formState} formType={FormType.Create} />

				<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
					Submit
				</SubmitButton>
			</Column>
		</Form>
	);
}

export type ControlledDetailProps = {
	lessonPlan: LessonPlanDetailQuery["lessonPlan"];
	applyUpdate: ApplyUpdateFunction<LessonPlanFormValues.Detail>;
	onSuccess: () => void;
};

/**
 * Renders the detail form of the LessonPlan model using an internally managed form state.
 */
export function ControlledDetail(props: ControlledDetailProps) {
	const initialFormValues = React.useMemo(
		() => LessonPlanFormConversion.toFormValues(props.lessonPlan),
		[props.lessonPlan],
	);
	const formState = LessonPlanFormState.useDetailFormState({ initialFormValues });

	return <Detail formState={formState} {...props} />;
}

export type DetailProps = ControlledDetailProps & {
	formState: FormState<LessonPlanFormValues.Detail>;
};

/**
 * Renders the detail form of the LessonPlan model using the given form state.
 */
export function Detail({ formState, lessonPlan, applyUpdate, onSuccess }: DetailProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<LessonPlanFormValues.Detail>>(
		async (formState) => {
			return onUpdateSubmit(formState, applyUpdate);
		},
		[applyUpdate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	const { anyEditing, everyEditing } = React.useMemo(() => {
		return {
			anyEditing: anyFieldEditing({
				formEditing: formState.formEditing,
				formSubFormStates: formState.formSubFormStates,
			}),
			everyEditing: everyFieldEditing({
				formEditing: formState.formEditing,
				formSubFormStates: formState.formSubFormStates,
			}),
		};
	}, [formState.formEditing, formState.formSubFormStates]);

	return (
		<Form
			name={`lessonPlan.detail.${lessonPlan.id}`}
			formState={formState}
			onSubmit={formState.onSubmitWrapper(onSubmit)}
		>
			<Column justify="spaced-start">
				<Row justify="spaced-start" horizontalSpacing="0.75rem">
					<If condition={!everyEditing}>
						<Button variant="tertiary" size="small" onClick={() => formState.setFormEditing(true)}>
							Edit
						</Button>
					</If>
					<If condition={anyEditing}>
						<Button variant="tertiary" size="small" onClick={() => formState.setFormEditing(false)}>
							Stop Editing
						</Button>
					</If>
				</Row>

				<Field.CourseDate formState={formState} formType={FormType.Update} id={lessonPlan.id} />
				<Field.LessonDescription formState={formState} formType={FormType.Update} id={lessonPlan.id} />
				<Field.RequiredResourcesAndMaterials formState={formState} formType={FormType.Update} id={lessonPlan.id} />
				<Field.StationOneDescription formState={formState} formType={FormType.Update} id={lessonPlan.id} />
				<Field.StationTwoDescription formState={formState} formType={FormType.Update} id={lessonPlan.id} />
				<Field.StationThreeDescription formState={formState} formType={FormType.Update} id={lessonPlan.id} />
				<Field.DifferentiationDescription formState={formState} formType={FormType.Update} id={lessonPlan.id} />
				<Field.AssessmentDescription formState={formState} formType={FormType.Update} id={lessonPlan.id} />
				<Field.Notes formState={formState} formType={FormType.Update} id={lessonPlan.id} />
				<Field.CourseSection
					formState={formState}
					formType={FormType.Update}
					id={lessonPlan.id}
					currentCourseSection={lessonPlan.courseSection}
				/>
				<Field.LearningObjectives
					formState={formState}
					formType={FormType.Update}
					id={lessonPlan.id}
					currentLearningObjectives={lessonPlan.learningObjectives}
				/>
			</Column>
		</Form>
	);
}

export type ControlledReadOnlyProps = {
	lessonPlan: LessonPlanDetailQuery["lessonPlan"];
};

/**
 * Renders a read-only detail form of the LessonPlan model using an internally managed form state.
 */
export function ControlledReadOnly(props: ControlledReadOnlyProps) {
	const initialFormValues = React.useMemo(
		() => LessonPlanFormConversion.toFormValues(props.lessonPlan),
		[props.lessonPlan],
	);
	const formState = LessonPlanFormState.useReadOnlyFormState({ initialFormValues });

	return <ReadOnly formState={formState} {...props} />;
}

export type ReadOnlyProps = ControlledReadOnlyProps & {
	formState: FormState<LessonPlanFormValues.Detail>;
};

/**
 * Renders a read-only detail form of the LessonPlan model using the given form state.
 */
export function ReadOnly({ formState, lessonPlan }: ReadOnlyProps) {
	return (
		<Column justify="spaced-start">
			<Field.CourseDate formState={formState} formType={FormType.Update} id={lessonPlan.id} />
			<Field.LessonDescription formState={formState} formType={FormType.Update} id={lessonPlan.id} />
			<Field.RequiredResourcesAndMaterials formState={formState} formType={FormType.Update} id={lessonPlan.id} />
			<Field.StationOneDescription formState={formState} formType={FormType.Update} id={lessonPlan.id} />
			<Field.StationTwoDescription formState={formState} formType={FormType.Update} id={lessonPlan.id} />
			<Field.StationThreeDescription formState={formState} formType={FormType.Update} id={lessonPlan.id} />
			<Field.DifferentiationDescription formState={formState} formType={FormType.Update} id={lessonPlan.id} />
			<Field.AssessmentDescription formState={formState} formType={FormType.Update} id={lessonPlan.id} />
			<Field.Notes formState={formState} formType={FormType.Update} id={lessonPlan.id} />
			<Field.CourseSection
				formState={formState}
				formType={FormType.Update}
				id={lessonPlan.id}
				currentCourseSection={lessonPlan.courseSection}
			/>
			<Field.LearningObjectives
				formState={formState}
				formType={FormType.Update}
				id={lessonPlan.id}
				currentLearningObjectives={lessonPlan.learningObjectives}
			/>
		</Column>
	);
}

export type ControlledPreviewReadOnlyProps = {
	lessonPlan: LessonPlanDetailQuery["lessonPlan"];
	headingLevel?: HeadingLevel;
};

/**
 * Renders a read-only detail form of the LessonPlan model using an internally managed form state.
 */
export function ControlledPreviewReadOnly(props: ControlledPreviewReadOnlyProps) {
	const initialFormValues = React.useMemo(
		() => LessonPlanFormConversion.toFormValues(props.lessonPlan),
		[props.lessonPlan],
	);
	const formState = LessonPlanFormState.useReadOnlyFormState({ initialFormValues });

	return <PreviewReadOnly formState={formState} {...props} />;
}

export type PreviewReadOnlyProps = ControlledPreviewReadOnlyProps & {
	formState: FormState<LessonPlanFormValues.Detail>;
};

/**
 * Renders a read-only detail form of the LessonPlan model using the given form state.
 */
export function PreviewReadOnly({ formState, lessonPlan, headingLevel = 3 }: PreviewReadOnlyProps) {
	return (
		<Column justify="spaced-start">
			<Heading level={headingLevel} noMargin>
				{formatDateTime(stringToLocalDate(formState.formValues.courseDate, "day") ?? new Date(), "ddd, D MMM")}
			</Heading>

			<div>
				<Column justify="spaced-start">
					<Field.LessonDescription formState={formState} formType={FormType.Update} id={lessonPlan.id} />
					<Field.LearningObjectives
						formState={formState}
						formType={FormType.Update}
						id={lessonPlan.id}
						currentLearningObjectives={lessonPlan.learningObjectives}
						displayInstance={displayLearningObjectiveInstance}
					/>
				</Column>
			</div>

			<If
				condition={
					formState.formValues.stationOneDescription !== "" ||
					formState.formValues.stationTwoDescription !== "" ||
					formState.formValues.stationThreeDescription !== ""
				}
			>
				<div>
					<Heading level={toHeadingLevel(headingLevel + 1)} noMargin>
						Stations
					</Heading>
					<If condition={formState.formValues.stationOneDescription !== ""}>
						<Field.StationOneDescription formState={formState} formType={FormType.Update} id={lessonPlan.id} />
					</If>

					<If condition={formState.formValues.stationTwoDescription !== ""}>
						<Field.StationTwoDescription formState={formState} formType={FormType.Update} id={lessonPlan.id} />
					</If>
					<If condition={formState.formValues.stationThreeDescription !== ""}>
						<Field.StationThreeDescription formState={formState} formType={FormType.Update} id={lessonPlan.id} />
					</If>
				</div>
			</If>

			<If condition={formState.formValues.differentiationDescription !== ""}>
				<div>
					<Heading level={(headingLevel + 1) as HeadingLevel} noMargin>
						Differentiation
					</Heading>
					<Field.DifferentiationDescription formState={formState} formType={FormType.Update} id={lessonPlan.id} />
				</div>
			</If>

			<If condition={formState.formValues.assessmentDescription !== ""}>
				<div>
					<Heading level={(headingLevel + 1) as HeadingLevel} noMargin>
						Assessment
					</Heading>
					<Field.AssessmentDescription formState={formState} formType={FormType.Update} id={lessonPlan.id} />
				</div>
			</If>

			<If condition={formState.formValues.requiredResourcesAndMaterials !== ""}>
				<div>
					<Heading level={(headingLevel + 1) as HeadingLevel} noMargin>
						Resources &amp; Materials
					</Heading>
					<Field.RequiredResourcesAndMaterials formState={formState} formType={FormType.Update} id={lessonPlan.id} />
				</div>
			</If>

			<If condition={formState.formValues.notes !== ""}>
				<div>
					<Heading level={(headingLevel + 1) as HeadingLevel} noMargin>
						Notes
					</Heading>
					<Field.Notes formState={formState} formType={FormType.Update} id={lessonPlan.id} />
				</div>
			</If>
		</Column>
	);
}

function displayLearningObjectiveInstance({ formattedValue }: FieldDisplayArgs<string | null>) {
	return formattedValue;
}
