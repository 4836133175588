import { CourseAttendanceRecord, CourseSectionPeriod, Student } from "../../GraphQL";

export type Base = {
	attendanceDate: CourseAttendanceRecord["attendanceDate"] | null;
	isPresent: CourseAttendanceRecord["isPresent"];
	isLate: CourseAttendanceRecord["isLate"];
	isExcused: CourseAttendanceRecord["isExcused"];
	notes: CourseAttendanceRecord["notes"];
	courseSectionPeriodID: CourseSectionPeriod["id"] | null;
	studentID: Student["id"] | null;
};

export type Create = Base;

export type Detail = Base;

export const initialCreate: Create = {
	attendanceDate: null,
	isPresent: true,
	isLate: false,
	isExcused: false,
	notes: "",
	courseSectionPeriodID: null,
	studentID: null,
};

export const isPresentOptions = [
	{ value: true, label: "Present" },
	{ value: false, label: "Absent" },
];

export const isLateOptions = [
	{ value: false, label: "On-Time" },
	{ value: true, label: "Late" },
];

export const isExcusedOptions = [
	{ value: false, label: "Unexcused" },
	{ value: true, label: "Excused" },
];
