import { IndexForms } from "@hex-insights/app-modules";
import { CaseStyle, convertCase } from "@hex-insights/core";
import { CourseSectionPeriodOrderField, OrderDirection } from "../../GraphQL";

export type FormValues = IndexForms.OrderFormValues<CourseSectionPeriodOrderField | null>;

export const initial: FormValues = {
	field: CourseSectionPeriodOrderField.DayOfWeek,
	direction: OrderDirection.Asc,
};

/**
 * Options for the "field" field in the order form of the CourseSectionPeriod model.
 */
export const fieldOptions = [
	{ label: "ID", value: null },
	{
		label: convertCase(CourseSectionPeriodOrderField.DayOfWeek.toLowerCase(), CaseStyle.Title),
		value: CourseSectionPeriodOrderField.DayOfWeek,
	},
	{
		label: convertCase(CourseSectionPeriodOrderField.StartTimeMinutes.toLowerCase(), CaseStyle.Title),
		value: CourseSectionPeriodOrderField.StartTimeMinutes,
	},
	{
		label: convertCase(CourseSectionPeriodOrderField.EndTimeMinutes.toLowerCase(), CaseStyle.Title),
		value: CourseSectionPeriodOrderField.EndTimeMinutes,
	},
];

/**
 * Array of values for the "field" field in the order form of the CourseSectionPeriod model.
 */
export const fieldValues = fieldOptions.map((e) => e.value);

/**
 * Mapping from order fields of the CourseSectionPeriod model to their corresponding order type.
 */
export const fieldTypeMapping = {
	[CourseSectionPeriodOrderField.DayOfWeek]: IndexForms.OrderFieldType.Number,
	[CourseSectionPeriodOrderField.StartTimeMinutes]: IndexForms.OrderFieldType.Number,
	[CourseSectionPeriodOrderField.EndTimeMinutes]: IndexForms.OrderFieldType.Number,
};

/**
 * Mapping from order fields of the CourseSectionPeriod model to their corresponding field name.
 */
export const orderFieldToModelField = {
	[CourseSectionPeriodOrderField.DayOfWeek]: "dayOfWeek",
	[CourseSectionPeriodOrderField.StartTimeMinutes]: "startTimeMinutes",
	[CourseSectionPeriodOrderField.EndTimeMinutes]: "endTimeMinutes",
};

/**
 * Mapping from field names of the CourseSectionPeriod model to their corresponding order field.
 */
export const modelFieldToOrderField = {
	dayOfWeek: CourseSectionPeriodOrderField.DayOfWeek,
	startTimeMinutes: CourseSectionPeriodOrderField.StartTimeMinutes,
	endTimeMinutes: CourseSectionPeriodOrderField.EndTimeMinutes,
};
