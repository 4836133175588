import { Redirect, Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../Components";
import { StudentHubDetailPage } from "./DetailPage";
import { studentHubDetailPageInfo } from "./DetailPage/pageinfo";
import { studentHubPageInfo } from "./pageinfo";

export function StudentHubPage() {
	useActivePageRegistration(studentHubPageInfo);

	return (
		<PageRouteSwitch>
			<Route exact path={studentHubPageInfo.path}>
				<Redirect to="/" />
			</Route>
			<Route {...studentHubDetailPageInfo.routeProps}>
				<StudentHubDetailPage />
			</Route>
		</PageRouteSwitch>
	);
}
