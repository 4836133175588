// Code generated by @hex-insights/frontend-generator; DO NOT EDIT.

export const permissions = {
	User: {
		Create: { Admin: "User.Create.Admin", Basic: "User.Create.Basic" },
		View: { Admin: "User.View.Admin", Basic: "User.View.Basic" },
		Update: { Admin: "User.Update.Admin", Basic: "User.Update.Basic" },
		Delete: { Admin: "User.Delete.Admin", Basic: "User.Delete.Basic" },
	},
	Permission: {
		Create: { Admin: "Permission.Create.Admin", Basic: "Permission.Create.Basic" },
		View: { Admin: "Permission.View.Admin", Basic: "Permission.View.Basic" },
		Update: { Admin: "Permission.Update.Admin", Basic: "Permission.Update.Basic" },
		Delete: { Admin: "Permission.Delete.Admin", Basic: "Permission.Delete.Basic" },
	},
	PermissionGroup: {
		Create: { Admin: "PermissionGroup.Create.Admin", Basic: "PermissionGroup.Create.Basic" },
		View: { Admin: "PermissionGroup.View.Admin", Basic: "PermissionGroup.View.Basic" },
		Update: { Admin: "PermissionGroup.Update.Admin", Basic: "PermissionGroup.Update.Basic" },
		Delete: { Admin: "PermissionGroup.Delete.Admin", Basic: "PermissionGroup.Delete.Basic" },
	},
	LoginRecord: {
		Create: { Admin: "LoginRecord.Create.Admin", Basic: "LoginRecord.Create.Basic" },
		View: { Admin: "LoginRecord.View.Admin", Basic: "LoginRecord.View.Basic" },
		Update: { Admin: "LoginRecord.Update.Admin", Basic: "LoginRecord.Update.Basic" },
		Delete: { Admin: "LoginRecord.Delete.Admin", Basic: "LoginRecord.Delete.Basic" },
	},
	MFAFactor: {
		Create: { Admin: "MFAFactor.Create.Admin", Basic: "MFAFactor.Create.Basic" },
		View: { Admin: "MFAFactor.View.Admin", Basic: "MFAFactor.View.Basic" },
		Update: { Admin: "MFAFactor.Update.Admin", Basic: "MFAFactor.Update.Basic" },
		Delete: { Admin: "MFAFactor.Delete.Admin", Basic: "MFAFactor.Delete.Basic" },
	},
	MFAInvalidOTPAttempt: {
		Create: { Admin: "MFAInvalidOTPAttempt.Create.Admin", Basic: "MFAInvalidOTPAttempt.Create.Basic" },
		View: { Admin: "MFAInvalidOTPAttempt.View.Admin", Basic: "MFAInvalidOTPAttempt.View.Basic" },
		Update: { Admin: "MFAInvalidOTPAttempt.Update.Admin", Basic: "MFAInvalidOTPAttempt.Update.Basic" },
		Delete: { Admin: "MFAInvalidOTPAttempt.Delete.Admin", Basic: "MFAInvalidOTPAttempt.Delete.Basic" },
	},
	Session: {
		Create: { Admin: "Session.Create.Admin", Basic: "Session.Create.Basic" },
		View: { Admin: "Session.View.Admin", Basic: "Session.View.Basic" },
		Update: { Admin: "Session.Update.Admin", Basic: "Session.Update.Basic" },
		Delete: { Admin: "Session.Delete.Admin", Basic: "Session.Delete.Basic" },
	},
	TrustedDevice: {
		Create: { Admin: "TrustedDevice.Create.Admin", Basic: "TrustedDevice.Create.Basic" },
		View: { Admin: "TrustedDevice.View.Admin", Basic: "TrustedDevice.View.Basic" },
		Update: { Admin: "TrustedDevice.Update.Admin", Basic: "TrustedDevice.Update.Basic" },
		Delete: { Admin: "TrustedDevice.Delete.Admin", Basic: "TrustedDevice.Delete.Basic" },
	},
	Address: {
		Create: { Admin: "Address.Create.Admin", Basic: "Address.Create.Basic" },
		View: { Admin: "Address.View.Admin", Basic: "Address.View.Basic" },
		Update: { Admin: "Address.Update.Admin", Basic: "Address.Update.Basic" },
		Delete: { Admin: "Address.Delete.Admin", Basic: "Address.Delete.Basic" },
	},
	Campus: {
		Create: { Admin: "Campus.Create.Admin", Basic: "Campus.Create.Basic" },
		View: { Admin: "Campus.View.Admin", Basic: "Campus.View.Basic" },
		Update: { Admin: "Campus.Update.Admin", Basic: "Campus.Update.Basic" },
		Delete: { Admin: "Campus.Delete.Admin", Basic: "Campus.Delete.Basic" },
	},
	ClassYear: {
		Create: { Admin: "ClassYear.Create.Admin", Basic: "ClassYear.Create.Basic" },
		View: { Admin: "ClassYear.View.Admin", Basic: "ClassYear.View.Basic" },
		Update: { Admin: "ClassYear.Update.Admin", Basic: "ClassYear.Update.Basic" },
		Delete: { Admin: "ClassYear.Delete.Admin", Basic: "ClassYear.Delete.Basic" },
	},
	Course: {
		Create: { Admin: "Course.Create.Admin", Basic: "Course.Create.Basic" },
		View: { Admin: "Course.View.Admin", Basic: "Course.View.Basic" },
		Update: { Admin: "Course.Update.Admin", Basic: "Course.Update.Basic" },
		Delete: { Admin: "Course.Delete.Admin", Basic: "Course.Delete.Basic" },
	},
	CourseSection: {
		Create: { Admin: "CourseSection.Create.Admin", Basic: "CourseSection.Create.Basic" },
		View: { Admin: "CourseSection.View.Admin", Basic: "CourseSection.View.Basic" },
		Update: { Admin: "CourseSection.Update.Admin", Basic: "CourseSection.Update.Basic" },
		Delete: { Admin: "CourseSection.Delete.Admin", Basic: "CourseSection.Delete.Basic" },
	},
	CourseSectionPeriod: {
		Create: { Admin: "CourseSectionPeriod.Create.Admin", Basic: "CourseSectionPeriod.Create.Basic" },
		View: { Admin: "CourseSectionPeriod.View.Admin", Basic: "CourseSectionPeriod.View.Basic" },
		Update: { Admin: "CourseSectionPeriod.Update.Admin", Basic: "CourseSectionPeriod.Update.Basic" },
		Delete: { Admin: "CourseSectionPeriod.Delete.Admin", Basic: "CourseSectionPeriod.Delete.Basic" },
	},
	Discount: {
		Create: { Admin: "Discount.Create.Admin", Basic: "Discount.Create.Basic" },
		View: { Admin: "Discount.View.Admin", Basic: "Discount.View.Basic" },
		Update: { Admin: "Discount.Update.Admin", Basic: "Discount.Update.Basic" },
		Delete: { Admin: "Discount.Delete.Admin", Basic: "Discount.Delete.Basic" },
	},
	EmailAddress: {
		Create: { Admin: "EmailAddress.Create.Admin", Basic: "EmailAddress.Create.Basic" },
		View: { Admin: "EmailAddress.View.Admin", Basic: "EmailAddress.View.Basic" },
		Update: { Admin: "EmailAddress.Update.Admin", Basic: "EmailAddress.Update.Basic" },
		Delete: { Admin: "EmailAddress.Delete.Admin", Basic: "EmailAddress.Delete.Basic" },
	},
	Employee: {
		Create: { Admin: "Employee.Create.Admin", Basic: "Employee.Create.Basic" },
		View: { Admin: "Employee.View.Admin", Basic: "Employee.View.Basic" },
		Update: { Admin: "Employee.Update.Admin", Basic: "Employee.Update.Basic" },
		Delete: { Admin: "Employee.Delete.Admin", Basic: "Employee.Delete.Basic" },
	},
	HomeRoom: {
		Create: { Admin: "HomeRoom.Create.Admin", Basic: "HomeRoom.Create.Basic" },
		View: { Admin: "HomeRoom.View.Admin", Basic: "HomeRoom.View.Basic" },
		Update: { Admin: "HomeRoom.Update.Admin", Basic: "HomeRoom.Update.Basic" },
		Delete: { Admin: "HomeRoom.Delete.Admin", Basic: "HomeRoom.Delete.Basic" },
	},
	HomeRoomSection: {
		Create: { Admin: "HomeRoomSection.Create.Admin", Basic: "HomeRoomSection.Create.Basic" },
		View: { Admin: "HomeRoomSection.View.Admin", Basic: "HomeRoomSection.View.Basic" },
		Update: { Admin: "HomeRoomSection.Update.Admin", Basic: "HomeRoomSection.Update.Basic" },
		Delete: { Admin: "HomeRoomSection.Delete.Admin", Basic: "HomeRoomSection.Delete.Basic" },
	},
	Invoice: {
		Create: { Admin: "Invoice.Create.Admin", Basic: "Invoice.Create.Basic" },
		View: { Admin: "Invoice.View.Admin", Basic: "Invoice.View.Basic" },
		Update: { Admin: "Invoice.Update.Admin", Basic: "Invoice.Update.Basic" },
		Delete: { Admin: "Invoice.Delete.Admin", Basic: "Invoice.Delete.Basic" },
	},
	InvoiceLineItem: {
		Create: { Admin: "InvoiceLineItem.Create.Admin", Basic: "InvoiceLineItem.Create.Basic" },
		View: { Admin: "InvoiceLineItem.View.Admin", Basic: "InvoiceLineItem.View.Basic" },
		Update: { Admin: "InvoiceLineItem.Update.Admin", Basic: "InvoiceLineItem.Update.Basic" },
		Delete: { Admin: "InvoiceLineItem.Delete.Admin", Basic: "InvoiceLineItem.Delete.Basic" },
	},
	LearningObjective: {
		Create: { Admin: "LearningObjective.Create.Admin", Basic: "LearningObjective.Create.Basic" },
		View: { Admin: "LearningObjective.View.Admin", Basic: "LearningObjective.View.Basic" },
		Update: { Admin: "LearningObjective.Update.Admin", Basic: "LearningObjective.Update.Basic" },
		Delete: { Admin: "LearningObjective.Delete.Admin", Basic: "LearningObjective.Delete.Basic" },
	},
	LearningObjectiveMark: {
		Create: { Admin: "LearningObjectiveMark.Create.Admin", Basic: "LearningObjectiveMark.Create.Basic" },
		View: { Admin: "LearningObjectiveMark.View.Admin", Basic: "LearningObjectiveMark.View.Basic" },
		Update: { Admin: "LearningObjectiveMark.Update.Admin", Basic: "LearningObjectiveMark.Update.Basic" },
		Delete: { Admin: "LearningObjectiveMark.Delete.Admin", Basic: "LearningObjectiveMark.Delete.Basic" },
	},
	Parent: {
		Create: { Admin: "Parent.Create.Admin", Basic: "Parent.Create.Basic" },
		View: { Admin: "Parent.View.Admin", Basic: "Parent.View.Basic" },
		Update: { Admin: "Parent.Update.Admin", Basic: "Parent.Update.Basic" },
		Delete: { Admin: "Parent.Delete.Admin", Basic: "Parent.Delete.Basic" },
	},
	Payment: {
		Create: { Admin: "Payment.Create.Admin", Basic: "Payment.Create.Basic" },
		View: { Admin: "Payment.View.Admin", Basic: "Payment.View.Basic" },
		Update: { Admin: "Payment.Update.Admin", Basic: "Payment.Update.Basic" },
		Delete: { Admin: "Payment.Delete.Admin", Basic: "Payment.Delete.Basic" },
	},
	PaymentLineItem: {
		Create: { Admin: "PaymentLineItem.Create.Admin", Basic: "PaymentLineItem.Create.Basic" },
		View: { Admin: "PaymentLineItem.View.Admin", Basic: "PaymentLineItem.View.Basic" },
		Update: { Admin: "PaymentLineItem.Update.Admin", Basic: "PaymentLineItem.Update.Basic" },
		Delete: { Admin: "PaymentLineItem.Delete.Admin", Basic: "PaymentLineItem.Delete.Basic" },
	},
	Person: {
		Create: { Admin: "Person.Create.Admin", Basic: "Person.Create.Basic" },
		View: { Admin: "Person.View.Admin", Basic: "Person.View.Basic" },
		Update: { Admin: "Person.Update.Admin", Basic: "Person.Update.Basic" },
		Delete: { Admin: "Person.Delete.Admin", Basic: "Person.Delete.Basic" },
	},
	PhoneNumber: {
		Create: { Admin: "PhoneNumber.Create.Admin", Basic: "PhoneNumber.Create.Basic" },
		View: { Admin: "PhoneNumber.View.Admin", Basic: "PhoneNumber.View.Basic" },
		Update: { Admin: "PhoneNumber.Update.Admin", Basic: "PhoneNumber.Update.Basic" },
		Delete: { Admin: "PhoneNumber.Delete.Admin", Basic: "PhoneNumber.Delete.Basic" },
	},
	Relationship: {
		Create: { Admin: "Relationship.Create.Admin", Basic: "Relationship.Create.Basic" },
		View: { Admin: "Relationship.View.Admin", Basic: "Relationship.View.Basic" },
		Update: { Admin: "Relationship.Update.Admin", Basic: "Relationship.Update.Basic" },
		Delete: { Admin: "Relationship.Delete.Admin", Basic: "Relationship.Delete.Basic" },
	},
	ReportCard: {
		Create: { Admin: "ReportCard.Create.Admin", Basic: "ReportCard.Create.Basic" },
		View: { Admin: "ReportCard.View.Admin", Basic: "ReportCard.View.Basic" },
		Update: { Admin: "ReportCard.Update.Admin", Basic: "ReportCard.Update.Basic" },
		Delete: { Admin: "ReportCard.Delete.Admin", Basic: "ReportCard.Delete.Basic" },
	},
	Room: {
		Create: { Admin: "Room.Create.Admin", Basic: "Room.Create.Basic" },
		View: { Admin: "Room.View.Admin", Basic: "Room.View.Basic" },
		Update: { Admin: "Room.Update.Admin", Basic: "Room.Update.Basic" },
		Delete: { Admin: "Room.Delete.Admin", Basic: "Room.Delete.Basic" },
	},
	Student: {
		Create: { Admin: "Student.Create.Admin", Basic: "Student.Create.Basic" },
		View: { Admin: "Student.View.Admin", Basic: "Student.View.Basic" },
		Update: { Admin: "Student.Update.Admin", Basic: "Student.Update.Basic" },
		Delete: { Admin: "Student.Delete.Admin", Basic: "Student.Delete.Basic" },
	},
	Term: {
		Create: { Admin: "Term.Create.Admin", Basic: "Term.Create.Basic" },
		View: { Admin: "Term.View.Admin", Basic: "Term.View.Basic" },
		Update: { Admin: "Term.Update.Admin", Basic: "Term.Update.Basic" },
		Delete: { Admin: "Term.Delete.Admin", Basic: "Term.Delete.Basic" },
	},
	Tuition: {
		Create: { Admin: "Tuition.Create.Admin", Basic: "Tuition.Create.Basic" },
		View: { Admin: "Tuition.View.Admin", Basic: "Tuition.View.Basic" },
		Update: { Admin: "Tuition.Update.Admin", Basic: "Tuition.Update.Basic" },
		Delete: { Admin: "Tuition.Delete.Admin", Basic: "Tuition.Delete.Basic" },
	},
	LessonPlan: {
		Create: { Admin: "LessonPlan.Create.Admin", Basic: "LessonPlan.Create.Basic" },
		View: { Admin: "LessonPlan.View.Admin", Basic: "LessonPlan.View.Basic" },
		Update: { Admin: "LessonPlan.Update.Admin", Basic: "LessonPlan.Update.Basic" },
		Delete: { Admin: "LessonPlan.Delete.Admin", Basic: "LessonPlan.Delete.Basic" },
	},
	CourseAttendanceRecord: {
		Create: { Admin: "CourseAttendanceRecord.Create.Admin", Basic: "CourseAttendanceRecord.Create.Basic" },
		View: { Admin: "CourseAttendanceRecord.View.Admin", Basic: "CourseAttendanceRecord.View.Basic" },
		Update: { Admin: "CourseAttendanceRecord.Update.Admin", Basic: "CourseAttendanceRecord.Update.Basic" },
		Delete: { Admin: "CourseAttendanceRecord.Delete.Admin", Basic: "CourseAttendanceRecord.Delete.Basic" },
	},
	SchoolAttendanceRecord: {
		Create: { Admin: "SchoolAttendanceRecord.Create.Admin", Basic: "SchoolAttendanceRecord.Create.Basic" },
		View: { Admin: "SchoolAttendanceRecord.View.Admin", Basic: "SchoolAttendanceRecord.View.Basic" },
		Update: { Admin: "SchoolAttendanceRecord.Update.Admin", Basic: "SchoolAttendanceRecord.Update.Basic" },
		Delete: { Admin: "SchoolAttendanceRecord.Delete.Admin", Basic: "SchoolAttendanceRecord.Delete.Basic" },
	},
	Service: {
		Create: { Admin: "Service.Create.Admin", Basic: "Service.Create.Basic" },
		View: { Admin: "Service.View.Admin", Basic: "Service.View.Basic" },
		Update: { Admin: "Service.Update.Admin", Basic: "Service.Update.Basic" },
		Delete: { Admin: "Service.Delete.Admin", Basic: "Service.Delete.Basic" },
	},
	ServiceSubscription: {
		Create: { Admin: "ServiceSubscription.Create.Admin", Basic: "ServiceSubscription.Create.Basic" },
		View: { Admin: "ServiceSubscription.View.Admin", Basic: "ServiceSubscription.View.Basic" },
		Update: { Admin: "ServiceSubscription.Update.Admin", Basic: "ServiceSubscription.Update.Basic" },
		Delete: { Admin: "ServiceSubscription.Delete.Admin", Basic: "ServiceSubscription.Delete.Basic" },
	},
	Note: {
		Create: { Admin: "Note.Create.Admin", Basic: "Note.Create.Basic" },
		View: { Admin: "Note.View.Admin", Basic: "Note.View.Basic" },
		Update: { Admin: "Note.Update.Admin", Basic: "Note.Update.Basic" },
		Delete: { Admin: "Note.Delete.Admin", Basic: "Note.Delete.Basic" },
	},
	NoteAttachment: {
		Create: { Admin: "NoteAttachment.Create.Admin", Basic: "NoteAttachment.Create.Basic" },
		View: { Admin: "NoteAttachment.View.Admin", Basic: "NoteAttachment.View.Basic" },
		Update: { Admin: "NoteAttachment.Update.Admin", Basic: "NoteAttachment.Update.Basic" },
		Delete: { Admin: "NoteAttachment.Delete.Admin", Basic: "NoteAttachment.Delete.Basic" },
	},
	NoteComment: {
		Create: { Admin: "NoteComment.Create.Admin", Basic: "NoteComment.Create.Basic" },
		View: { Admin: "NoteComment.View.Admin", Basic: "NoteComment.View.Basic" },
		Update: { Admin: "NoteComment.Update.Admin", Basic: "NoteComment.Update.Basic" },
		Delete: { Admin: "NoteComment.Delete.Admin", Basic: "NoteComment.Delete.Basic" },
	},
	NoteReaction: {
		Create: { Admin: "NoteReaction.Create.Admin", Basic: "NoteReaction.Create.Basic" },
		View: { Admin: "NoteReaction.View.Admin", Basic: "NoteReaction.View.Basic" },
		Update: { Admin: "NoteReaction.Update.Admin", Basic: "NoteReaction.Update.Basic" },
		Delete: { Admin: "NoteReaction.Delete.Admin", Basic: "NoteReaction.Delete.Basic" },
	},
	CalendarEvent: {
		Create: { Admin: "CalendarEvent.Create.Admin", Basic: "CalendarEvent.Create.Basic" },
		View: { Admin: "CalendarEvent.View.Admin", Basic: "CalendarEvent.View.Basic" },
		Update: { Admin: "CalendarEvent.Update.Admin", Basic: "CalendarEvent.Update.Basic" },
		Delete: { Admin: "CalendarEvent.Delete.Admin", Basic: "CalendarEvent.Delete.Basic" },
	},
	Expense: {
		Create: { Admin: "Expense.Create.Admin", Basic: "Expense.Create.Basic" },
		View: { Admin: "Expense.View.Admin", Basic: "Expense.View.Basic" },
		Update: { Admin: "Expense.Update.Admin", Basic: "Expense.Update.Basic" },
		Delete: { Admin: "Expense.Delete.Admin", Basic: "Expense.Delete.Basic" },
	},
	ExpenseBudget: {
		Create: { Admin: "ExpenseBudget.Create.Admin", Basic: "ExpenseBudget.Create.Basic" },
		View: { Admin: "ExpenseBudget.View.Admin", Basic: "ExpenseBudget.View.Basic" },
		Update: { Admin: "ExpenseBudget.Update.Admin", Basic: "ExpenseBudget.Update.Basic" },
		Delete: { Admin: "ExpenseBudget.Delete.Admin", Basic: "ExpenseBudget.Delete.Basic" },
	},
	ExpenseCategory: {
		Create: { Admin: "ExpenseCategory.Create.Admin", Basic: "ExpenseCategory.Create.Basic" },
		View: { Admin: "ExpenseCategory.View.Admin", Basic: "ExpenseCategory.View.Basic" },
		Update: { Admin: "ExpenseCategory.Update.Admin", Basic: "ExpenseCategory.Update.Basic" },
		Delete: { Admin: "ExpenseCategory.Delete.Admin", Basic: "ExpenseCategory.Delete.Basic" },
	},
	DocumentResource: {
		Create: { Admin: "DocumentResource.Create.Admin", Basic: "DocumentResource.Create.Basic" },
		View: { Admin: "DocumentResource.View.Admin", Basic: "DocumentResource.View.Basic" },
		Update: { Admin: "DocumentResource.Update.Admin", Basic: "DocumentResource.Update.Basic" },
		Delete: { Admin: "DocumentResource.Delete.Admin", Basic: "DocumentResource.Delete.Basic" },
	},
	DocumentResourceFolder: {
		Create: { Admin: "DocumentResourceFolder.Create.Admin", Basic: "DocumentResourceFolder.Create.Basic" },
		View: { Admin: "DocumentResourceFolder.View.Admin", Basic: "DocumentResourceFolder.View.Basic" },
		Update: { Admin: "DocumentResourceFolder.Update.Admin", Basic: "DocumentResourceFolder.Update.Basic" },
		Delete: { Admin: "DocumentResourceFolder.Delete.Admin", Basic: "DocumentResourceFolder.Delete.Basic" },
	},
	DocumentResourceUserViewRecord: {
		Create: {
			Admin: "DocumentResourceUserViewRecord.Create.Admin",
			Basic: "DocumentResourceUserViewRecord.Create.Basic",
		},
		View: { Admin: "DocumentResourceUserViewRecord.View.Admin", Basic: "DocumentResourceUserViewRecord.View.Basic" },
		Update: {
			Admin: "DocumentResourceUserViewRecord.Update.Admin",
			Basic: "DocumentResourceUserViewRecord.Update.Basic",
		},
		Delete: {
			Admin: "DocumentResourceUserViewRecord.Delete.Admin",
			Basic: "DocumentResourceUserViewRecord.Delete.Basic",
		},
	},
	Bus: {
		Create: { Admin: "Bus.Create.Admin", Basic: "Bus.Create.Basic" },
		View: { Admin: "Bus.View.Admin", Basic: "Bus.View.Basic" },
		Update: { Admin: "Bus.Update.Admin", Basic: "Bus.Update.Basic" },
		Delete: { Admin: "Bus.Delete.Admin", Basic: "Bus.Delete.Basic" },
	},
	BusPlan: {
		Create: { Admin: "BusPlan.Create.Admin", Basic: "BusPlan.Create.Basic" },
		View: { Admin: "BusPlan.View.Admin", Basic: "BusPlan.View.Basic" },
		Update: { Admin: "BusPlan.Update.Admin", Basic: "BusPlan.Update.Basic" },
		Delete: { Admin: "BusPlan.Delete.Admin", Basic: "BusPlan.Delete.Basic" },
	},
	MealPlan: {
		Create: { Admin: "MealPlan.Create.Admin", Basic: "MealPlan.Create.Basic" },
		View: { Admin: "MealPlan.View.Admin", Basic: "MealPlan.View.Basic" },
		Update: { Admin: "MealPlan.Update.Admin", Basic: "MealPlan.Update.Basic" },
		Delete: { Admin: "MealPlan.Delete.Admin", Basic: "MealPlan.Delete.Basic" },
	},
	Contract: {
		Create: { Admin: "Contract.Create.Admin", Basic: "Contract.Create.Basic" },
		View: { Admin: "Contract.View.Admin", Basic: "Contract.View.Basic" },
		Update: { Admin: "Contract.Update.Admin", Basic: "Contract.Update.Basic" },
		Delete: { Admin: "Contract.Delete.Admin", Basic: "Contract.Delete.Basic" },
	},
	ContractInstallment: {
		Create: { Admin: "ContractInstallment.Create.Admin", Basic: "ContractInstallment.Create.Basic" },
		View: { Admin: "ContractInstallment.View.Admin", Basic: "ContractInstallment.View.Basic" },
		Update: { Admin: "ContractInstallment.Update.Admin", Basic: "ContractInstallment.Update.Basic" },
		Delete: { Admin: "ContractInstallment.Delete.Admin", Basic: "ContractInstallment.Delete.Basic" },
	},
	UserGroup: {
		Create: { Admin: "UserGroup.Create.Admin", Basic: "UserGroup.Create.Basic" },
		View: { Admin: "UserGroup.View.Admin", Basic: "UserGroup.View.Basic" },
		Update: { Admin: "UserGroup.Update.Admin", Basic: "UserGroup.Update.Basic" },
		Delete: { Admin: "UserGroup.Delete.Admin", Basic: "UserGroup.Delete.Basic" },
	},
	EnrollmentApplication: {
		Create: { Admin: "EnrollmentApplication.Create.Admin", Basic: "EnrollmentApplication.Create.Basic" },
		View: { Admin: "EnrollmentApplication.View.Admin", Basic: "EnrollmentApplication.View.Basic" },
		Update: { Admin: "EnrollmentApplication.Update.Admin", Basic: "EnrollmentApplication.Update.Basic" },
		Delete: { Admin: "EnrollmentApplication.Delete.Admin", Basic: "EnrollmentApplication.Delete.Basic" },
	},
	EnrollmentApplicationSubmission: {
		Create: {
			Admin: "EnrollmentApplicationSubmission.Create.Admin",
			Basic: "EnrollmentApplicationSubmission.Create.Basic",
		},
		View: { Admin: "EnrollmentApplicationSubmission.View.Admin", Basic: "EnrollmentApplicationSubmission.View.Basic" },
		Update: {
			Admin: "EnrollmentApplicationSubmission.Update.Admin",
			Basic: "EnrollmentApplicationSubmission.Update.Basic",
		},
		Delete: {
			Admin: "EnrollmentApplicationSubmission.Delete.Admin",
			Basic: "EnrollmentApplicationSubmission.Delete.Basic",
		},
	},
	Quotation: {
		Create: { Admin: "Quotation.Create.Admin", Basic: "Quotation.Create.Basic" },
		View: { Admin: "Quotation.View.Admin", Basic: "Quotation.View.Basic" },
		Update: { Admin: "Quotation.Update.Admin", Basic: "Quotation.Update.Basic" },
		Delete: { Admin: "Quotation.Delete.Admin", Basic: "Quotation.Delete.Basic" },
	},
	MealMenu: {
		Create: { Admin: "MealMenu.Create.Admin", Basic: "MealMenu.Create.Basic" },
		View: { Admin: "MealMenu.View.Admin", Basic: "MealMenu.View.Basic" },
		Update: { Admin: "MealMenu.Update.Admin", Basic: "MealMenu.Update.Basic" },
		Delete: { Admin: "MealMenu.Delete.Admin", Basic: "MealMenu.Delete.Basic" },
	},
	EnrollmentApplicationUserViewRecord: {
		Create: {
			Admin: "EnrollmentApplicationUserViewRecord.Create.Admin",
			Basic: "EnrollmentApplicationUserViewRecord.Create.Basic",
		},
		View: {
			Admin: "EnrollmentApplicationUserViewRecord.View.Admin",
			Basic: "EnrollmentApplicationUserViewRecord.View.Basic",
		},
		Update: {
			Admin: "EnrollmentApplicationUserViewRecord.Update.Admin",
			Basic: "EnrollmentApplicationUserViewRecord.Update.Basic",
		},
		Delete: {
			Admin: "EnrollmentApplicationUserViewRecord.Delete.Admin",
			Basic: "EnrollmentApplicationUserViewRecord.Delete.Basic",
		},
	},
	StudentDocument: {
		Create: { Admin: "StudentDocument.Create.Admin", Basic: "StudentDocument.Create.Basic" },
		View: { Admin: "StudentDocument.View.Admin", Basic: "StudentDocument.View.Basic" },
		Update: { Admin: "StudentDocument.Update.Admin", Basic: "StudentDocument.Update.Basic" },
		Delete: { Admin: "StudentDocument.Delete.Admin", Basic: "StudentDocument.Delete.Basic" },
	},
	CourseSectionStudentEnrollment: {
		Create: {
			Admin: "CourseSectionStudentEnrollment.Create.Admin",
			Basic: "CourseSectionStudentEnrollment.Create.Basic",
		},
		View: { Admin: "CourseSectionStudentEnrollment.View.Admin", Basic: "CourseSectionStudentEnrollment.View.Basic" },
		Update: {
			Admin: "CourseSectionStudentEnrollment.Update.Admin",
			Basic: "CourseSectionStudentEnrollment.Update.Basic",
		},
		Delete: {
			Admin: "CourseSectionStudentEnrollment.Delete.Admin",
			Basic: "CourseSectionStudentEnrollment.Delete.Basic",
		},
	},
	CourseSectionTeacherEnrollment: {
		Create: {
			Admin: "CourseSectionTeacherEnrollment.Create.Admin",
			Basic: "CourseSectionTeacherEnrollment.Create.Basic",
		},
		View: { Admin: "CourseSectionTeacherEnrollment.View.Admin", Basic: "CourseSectionTeacherEnrollment.View.Basic" },
		Update: {
			Admin: "CourseSectionTeacherEnrollment.Update.Admin",
			Basic: "CourseSectionTeacherEnrollment.Update.Basic",
		},
		Delete: {
			Admin: "CourseSectionTeacherEnrollment.Delete.Admin",
			Basic: "CourseSectionTeacherEnrollment.Delete.Basic",
		},
	},
	HomeRoomSectionStudentEnrollment: {
		Create: {
			Admin: "HomeRoomSectionStudentEnrollment.Create.Admin",
			Basic: "HomeRoomSectionStudentEnrollment.Create.Basic",
		},
		View: {
			Admin: "HomeRoomSectionStudentEnrollment.View.Admin",
			Basic: "HomeRoomSectionStudentEnrollment.View.Basic",
		},
		Update: {
			Admin: "HomeRoomSectionStudentEnrollment.Update.Admin",
			Basic: "HomeRoomSectionStudentEnrollment.Update.Basic",
		},
		Delete: {
			Admin: "HomeRoomSectionStudentEnrollment.Delete.Admin",
			Basic: "HomeRoomSectionStudentEnrollment.Delete.Basic",
		},
	},
	HomeRoomSectionTeacherEnrollment: {
		Create: {
			Admin: "HomeRoomSectionTeacherEnrollment.Create.Admin",
			Basic: "HomeRoomSectionTeacherEnrollment.Create.Basic",
		},
		View: {
			Admin: "HomeRoomSectionTeacherEnrollment.View.Admin",
			Basic: "HomeRoomSectionTeacherEnrollment.View.Basic",
		},
		Update: {
			Admin: "HomeRoomSectionTeacherEnrollment.Update.Admin",
			Basic: "HomeRoomSectionTeacherEnrollment.Update.Basic",
		},
		Delete: {
			Admin: "HomeRoomSectionTeacherEnrollment.Delete.Admin",
			Basic: "HomeRoomSectionTeacherEnrollment.Delete.Basic",
		},
	},
	AcademicYear: {
		Create: { Admin: "AcademicYear.Create.Admin", Basic: "AcademicYear.Create.Basic" },
		View: { Admin: "AcademicYear.View.Admin", Basic: "AcademicYear.View.Basic" },
		Update: { Admin: "AcademicYear.Update.Admin", Basic: "AcademicYear.Update.Basic" },
		Delete: { Admin: "AcademicYear.Delete.Admin", Basic: "AcademicYear.Delete.Basic" },
	},
	SchoolStudentEnrollment: {
		Create: { Admin: "SchoolStudentEnrollment.Create.Admin", Basic: "SchoolStudentEnrollment.Create.Basic" },
		View: { Admin: "SchoolStudentEnrollment.View.Admin", Basic: "SchoolStudentEnrollment.View.Basic" },
		Update: { Admin: "SchoolStudentEnrollment.Update.Admin", Basic: "SchoolStudentEnrollment.Update.Basic" },
		Delete: { Admin: "SchoolStudentEnrollment.Delete.Admin", Basic: "SchoolStudentEnrollment.Delete.Basic" },
	},
	BillingSetupSubmission: {
		Create: { Admin: "BillingSetupSubmission.Create.Admin", Basic: "BillingSetupSubmission.Create.Basic" },
		View: { Admin: "BillingSetupSubmission.View.Admin", Basic: "BillingSetupSubmission.View.Basic" },
		Update: { Admin: "BillingSetupSubmission.Update.Admin", Basic: "BillingSetupSubmission.Update.Basic" },
		Delete: { Admin: "BillingSetupSubmission.Delete.Admin", Basic: "BillingSetupSubmission.Delete.Basic" },
	},
	EnrollmentApplicationStudent: {
		Create: { Admin: "EnrollmentApplicationStudent.Create.Admin", Basic: "EnrollmentApplicationStudent.Create.Basic" },
		View: { Admin: "EnrollmentApplicationStudent.View.Admin", Basic: "EnrollmentApplicationStudent.View.Basic" },
		Update: { Admin: "EnrollmentApplicationStudent.Update.Admin", Basic: "EnrollmentApplicationStudent.Update.Basic" },
		Delete: { Admin: "EnrollmentApplicationStudent.Delete.Admin", Basic: "EnrollmentApplicationStudent.Delete.Basic" },
	},
	EnrollmentApplicationParent: {
		Create: { Admin: "EnrollmentApplicationParent.Create.Admin", Basic: "EnrollmentApplicationParent.Create.Basic" },
		View: { Admin: "EnrollmentApplicationParent.View.Admin", Basic: "EnrollmentApplicationParent.View.Basic" },
		Update: { Admin: "EnrollmentApplicationParent.Update.Admin", Basic: "EnrollmentApplicationParent.Update.Basic" },
		Delete: { Admin: "EnrollmentApplicationParent.Delete.Admin", Basic: "EnrollmentApplicationParent.Delete.Basic" },
	},
	EnrollmentApplicationNote: {
		Create: { Admin: "EnrollmentApplicationNote.Create.Admin", Basic: "EnrollmentApplicationNote.Create.Basic" },
		View: { Admin: "EnrollmentApplicationNote.View.Admin", Basic: "EnrollmentApplicationNote.View.Basic" },
		Update: { Admin: "EnrollmentApplicationNote.Update.Admin", Basic: "EnrollmentApplicationNote.Update.Basic" },
		Delete: { Admin: "EnrollmentApplicationNote.Delete.Admin", Basic: "EnrollmentApplicationNote.Delete.Basic" },
	},
	IntegrationToken: {
		Create: { Admin: "IntegrationToken.Create.Admin", Basic: "IntegrationToken.Create.Basic" },
		View: { Admin: "IntegrationToken.View.Admin", Basic: "IntegrationToken.View.Basic" },
		Update: { Admin: "IntegrationToken.Update.Admin", Basic: "IntegrationToken.Update.Basic" },
		Delete: { Admin: "IntegrationToken.Delete.Admin", Basic: "IntegrationToken.Delete.Basic" },
	},
	AcademicYearTuition: {
		Create: { Admin: "AcademicYearTuition.Create.Admin", Basic: "AcademicYearTuition.Create.Basic" },
		View: { Admin: "AcademicYearTuition.View.Admin", Basic: "AcademicYearTuition.View.Basic" },
		Update: { Admin: "AcademicYearTuition.Update.Admin", Basic: "AcademicYearTuition.Update.Basic" },
		Delete: { Admin: "AcademicYearTuition.Delete.Admin", Basic: "AcademicYearTuition.Delete.Basic" },
	},
	EnrollmentInvitation: {
		Create: { Admin: "EnrollmentInvitation.Create.Admin", Basic: "EnrollmentInvitation.Create.Basic" },
		View: { Admin: "EnrollmentInvitation.View.Admin", Basic: "EnrollmentInvitation.View.Basic" },
		Update: { Admin: "EnrollmentInvitation.Update.Admin", Basic: "EnrollmentInvitation.Update.Basic" },
		Delete: { Admin: "EnrollmentInvitation.Delete.Admin", Basic: "EnrollmentInvitation.Delete.Basic" },
	},
};
