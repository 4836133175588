import React from "react";
import { ApolloCache, FetchResult, gql } from "@apollo/client";
import {
	CourseAttendanceRecord,
	CourseAttendanceRecordCreateInput,
	CourseAttendanceRecordCreateMutation,
	CourseAttendanceRecordDeleteMutation,
	CourseAttendanceRecordDetailDocument,
	CourseAttendanceRecordDetailQuery,
	CourseAttendanceRecordDetailQueryVariables,
	CourseAttendanceRecordUpdateInput,
	CourseAttendanceRecordUpdateMutation,
	CourseSectionPeriod,
	graphTypeNames,
	Student,
	useCourseAttendanceRecordCreateMutation,
	useCourseAttendanceRecordDeleteMutation,
	useCourseAttendanceRecordUpdateMutation,
} from "../../GraphQL";
import { CourseAttendanceRecordFormConversion } from "../ModelFormConversion";
import { CourseAttendanceRecordFormValues } from "../ModelFormValues";

/**
 * Returns a `create` function for the CourseAttendanceRecord model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the CourseAttendanceRecord model.
 */
export function useCreate() {
	const [createCourseAttendanceRecord] = useCourseAttendanceRecordCreateMutation();

	return React.useCallback(
		async (formValues: CourseAttendanceRecordFormValues.Create) => {
			const input = CourseAttendanceRecordFormConversion.toGQLCreateInput(formValues);
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createCourseAttendanceRecord({ variables: { input }, update: updateCache });

			return { data: data?.createCourseAttendanceRecord ?? null, errors: errors ?? null };
		},
		[createCourseAttendanceRecord],
	);
}

/**
 * Returns an `update` function for the CourseAttendanceRecord model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the CourseAttendanceRecord model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: CourseAttendanceRecord["id"]) {
	const [updateCourseAttendanceRecord] = useCourseAttendanceRecordUpdateMutation();

	return React.useCallback(
		async (
			changedFormValues: Partial<CourseAttendanceRecordFormValues.Detail>,
			initialFormValues: CourseAttendanceRecordFormValues.Detail,
		) => {
			const input = CourseAttendanceRecordFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

			const { data, errors } = await updateCourseAttendanceRecord({
				variables: { id, input },
				update: updateCache,
			});

			return { data: data?.updateCourseAttendanceRecord ?? null, errors: errors ?? null };
		},
		[updateCourseAttendanceRecord, id],
	);
}

/**
 * Returns a `del` function for the CourseAttendanceRecord model.
 *
 * @param id The ID of the instance to delete.
 */
export function useDelete(id: CourseAttendanceRecord["id"]) {
	const [deleteCourseAttendanceRecord] = useCourseAttendanceRecordDeleteMutation();

	return React.useCallback(async () => {
		const updateCache = getUpdateCacheForDelete(id);

		const { data, errors } = await deleteCourseAttendanceRecord({ variables: { id }, update: updateCache });

		return { data: data?.deleteCourseAttendanceRecord ?? false, errors: errors ?? null };
	}, [deleteCourseAttendanceRecord, id]);
}

export function getUpdateCacheForCreate(input: CourseAttendanceRecordCreateInput) {
	return (
		cache: ApolloCache<any>,
		result: Omit<
			FetchResult<CourseAttendanceRecordCreateMutation, Record<string, any>, Record<string, any>>,
			"context"
		>,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const createdObject = result.data.createCourseAttendanceRecord;

		cache.writeQuery<CourseAttendanceRecordDetailQuery, CourseAttendanceRecordDetailQueryVariables>({
			query: CourseAttendanceRecordDetailDocument,
			data: { courseAttendanceRecord: createdObject },
			variables: { id: createdObject.id },
		});

		if (input.courseSectionPeriodID) {
			addToCourseAttendanceRecordsOfCourseSectionPeriodCache(cache, input.courseSectionPeriodID, createdObject);
		}

		if (input.studentID) {
			addToCourseAttendanceRecordsOfStudentCache(cache, input.studentID, createdObject);
		}

		cache.evict({ id: "ROOT_QUERY", fieldName: "courseAttendanceRecordConnection" });
	};
}

function getUpdateCacheForUpdate(
	input: CourseAttendanceRecordUpdateInput,
	initialFormValues: CourseAttendanceRecordFormValues.Detail,
) {
	return (
		cache: ApolloCache<any>,
		result: Omit<
			FetchResult<CourseAttendanceRecordUpdateMutation, Record<string, any>, Record<string, any>>,
			"context"
		>,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const updatedObject = result.data.updateCourseAttendanceRecord;

		if (initialFormValues.courseSectionPeriodID && input.courseSectionPeriodID) {
			removeFromCourseAttendanceRecordsOfCourseSectionPeriodCache(
				cache,
				initialFormValues.courseSectionPeriodID,
				updatedObject,
			);
		}
		if (input.courseSectionPeriodID) {
			addToCourseAttendanceRecordsOfCourseSectionPeriodCache(cache, input.courseSectionPeriodID, updatedObject);
		}

		if (initialFormValues.studentID && input.studentID) {
			removeFromCourseAttendanceRecordsOfStudentCache(cache, initialFormValues.studentID, updatedObject);
		}
		if (input.studentID) {
			addToCourseAttendanceRecordsOfStudentCache(cache, input.studentID, updatedObject);
		}
	};
}

function getUpdateCacheForDelete(id: CourseAttendanceRecord["id"]) {
	return (
		cache: ApolloCache<any>,
		result: Omit<
			FetchResult<CourseAttendanceRecordDeleteMutation, Record<string, any>, Record<string, any>>,
			"context"
		>,
	) => {
		if (!result.data?.deleteCourseAttendanceRecord) {
			return;
		}

		cache.evict({ id: cache.identify({ id, __typename: graphTypeNames.CourseAttendanceRecord }) });
		cache.evict({ id: "ROOT_QUERY", fieldName: "courseAttendanceRecordConnection" });
		cache.gc();
	};
}

function addToCourseAttendanceRecordsOfCourseSectionPeriodCache(
	cache: ApolloCache<any>,
	targetID: CourseSectionPeriod["id"],
	object: Pick<CourseAttendanceRecord, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.CourseSectionPeriod }),
		fields: {
			courseAttendanceRecords: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromCourseAttendanceRecordsOfCourseSectionPeriodCache(
	cache: ApolloCache<any>,
	targetID: CourseSectionPeriod["id"],
	object: Pick<CourseAttendanceRecord, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.CourseSectionPeriod }),
		fields: {
			courseAttendanceRecords: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function addToCourseAttendanceRecordsOfStudentCache(
	cache: ApolloCache<any>,
	targetID: Student["id"],
	object: Pick<CourseAttendanceRecord, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Student }),
		fields: {
			courseAttendanceRecords: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromCourseAttendanceRecordsOfStudentCache(
	cache: ApolloCache<any>,
	targetID: Student["id"],
	object: Pick<CourseAttendanceRecord, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Student }),
		fields: {
			courseAttendanceRecords: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function toCacheRef(cache: ApolloCache<any>, object: Pick<CourseAttendanceRecord, "id">) {
	return cache.writeFragment({
		fragment: gql`
			fragment CourseAttendanceRecordRef on CourseAttendanceRecord {
				id
			}
		`,
		data: object,
	});
}
