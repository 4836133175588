import { useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import { StudentHub } from "@hex-insights/verita.shared";
import { studentHubDetailFamilyAndHealthPageInfo, StudentHubDetailFamilyAndHealthPageRouteParams } from "./pageinfo";

export function FamilyAndHealthMain() {
	useActivePageRegistration(studentHubDetailFamilyAndHealthPageInfo);

	const { id } = useRouteParams<StudentHubDetailFamilyAndHealthPageRouteParams>();

	return <StudentHub.Detail.FamilyAndHealth.Main studentID={id} />;
}

export function FamilyAndHealthLeftColumn() {
	const { id } = useRouteParams<StudentHubDetailFamilyAndHealthPageRouteParams>();

	return <StudentHub.Detail.FamilyAndHealth.LeftColumn studentID={id} />;
}
