import React from "react";
import { EditMode, FormType, useFormState } from "@hex-insights/forms";
import { MealMenuFormValues } from "../ModelFormValues";

/**
 * Returns a `formState` object for use in the create form for the MealMenu model.
 */
export function useCreateFormState() {
	return useFormState({
		initialFormValues: MealMenuFormValues.initialCreate,
		formType: FormType.Create,
	});
}

export function useBulkCreateFormState(initialFormValues?: Partial<MealMenuFormValues.BulkCreate>) {
	return useFormState({
		initialFormValues: React.useMemo(
			() => ({ ...MealMenuFormValues.initialBulkCreate, ...initialFormValues }),
			[initialFormValues],
		),
		formType: FormType.Create,
	});
}

/**
 * Returns a `formState` object for use in the detail form of the MealMenu model.
 *
 * @param initialFormValues The initial values for the form. Generally from
 * {@link MealMenuFormConversion.toFormValues}.
 */
export function useDetailFormState({ initialFormValues }: { initialFormValues: MealMenuFormValues.Detail }) {
	return useFormState({
		initialFormValues: initialFormValues,
		formType: FormType.Update,
	});
}

/**
 * Returns a read-only `formState` object for use in the detail form of the MealMenu model.
 *
 * @param initialFormValues The initial values for the form. Generally from
 * {@link MealMenuFormConversion.toFormValues}.
 */
export function useReadOnlyFormState({ initialFormValues }: { initialFormValues: MealMenuFormValues.Detail }) {
	return useFormState({
		initialFormValues: initialFormValues,
		editMode: EditMode.ReadOnly,
	});
}
