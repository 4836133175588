import React from "react";
import { Icon, Row, toLocalDateString } from "@hex-insights/core";
import { InternalLink } from "@hex-insights/router";
import { useCourseAttendanceRecordIndexLazyQuery } from "@hex-insights/verita.shared";
import { useCurrentPeriod } from "../../../../Utilities";
import { scheduleDayCoursePeriodPageInfo } from "../../../Schedule/Day/CoursePeriod/pageinfo";
import styles from "./attendance.module.css";

export function Attendance() {
	const { currentPeriod } = useCurrentPeriod(true);
	const currentPeriodID = currentPeriod?.id;
	const [load, { data }] = useCourseAttendanceRecordIndexLazyQuery({
		variables: {
			filters: {
				attendanceDateEQ: toLocalDateString(new Date()),
				courseSectionPeriodIDEQ: currentPeriodID,
			},
		},
	});
	React.useEffect(() => {
		if (currentPeriodID) {
			load();
		}
	}, [currentPeriodID, load]);
	const hasAttendance = (data?.courseAttendanceRecordConnection.edges.length ?? 0) > 0;

	if (!(currentPeriod && data)) {
		return null;
	}

	let content = null;

	if (hasAttendance) {
		content = (
			<Row
				justify="spaced-start"
				horizontalSpacing="0.25rem"
				align="center"
				className={styles["attendance-status"] + " " + styles["attendance-status--success"]}
			>
				<Icon.Check size="1rem" /> Attendance Taken
			</Row>
		);
	} else {
		content = (
			<Row
				justify="spaced-start"
				horizontalSpacing="0.25rem"
				align="center"
				className={styles["attendance-status"] + " " + styles["attendance-status--failure"]}
			>
				<Icon.X size="1rem" /> Attendance Not Taken
			</Row>
		);
	}

	return (
		<InternalLink
			to={scheduleDayCoursePeriodPageInfo.to(toLocalDateString(new Date()), currentPeriod.id)}
			className="link--no-text-decoration"
		>
			{content}
		</InternalLink>
	);
}
