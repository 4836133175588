import { SchoolAttendanceRecord, Student } from "../../GraphQL";

export type Base = {
	checkInTime: SchoolAttendanceRecord["checkInTime"] | null;
	checkOutTime: SchoolAttendanceRecord["checkOutTime"] | null;
	isPresent: SchoolAttendanceRecord["isPresent"];
	isLate: SchoolAttendanceRecord["isLate"];
	isExcused: SchoolAttendanceRecord["isExcused"];
	notes: SchoolAttendanceRecord["notes"];
	studentID: Student["id"] | null;
};

export type Create = Base;

export type Detail = Base;

export const initialCreate: Create = {
	checkInTime: null,
	checkOutTime: null,
	isPresent: true,
	isLate: false,
	isExcused: false,
	notes: "",
	studentID: null,
};

export const isPresentOptions = [
	{ value: true, label: "Present" },
	{ value: false, label: "Absent" },
];

export const isLateOptions = [
	{ value: false, label: "On-Time" },
	{ value: true, label: "Late" },
];

export const isExcusedOptions = [
	{ value: false, label: "Unexcused" },
	{ value: true, label: "Excused" },
];
