import { IndexForms } from "@hex-insights/app-modules";
import { TypeFilterObject } from "@hex-insights/core";
import { DocumentResourceFolderFormValues } from "../ModelFormValues";

export type StandardFilterFormValues = {}; // TODO define quick filters

export type FormValues = StandardFilterFormValues & IndexForms.SearchFormValues & IndexForms.FilterFormValues;

export const initialStandardFilters: StandardFilterFormValues = {};

export const initial: FormValues = {
	...initialStandardFilters,
	...IndexForms.initialSearchFormValues,
	...IndexForms.initialFilterFormValues,
};

export function getInitial(): FormValues {
	return initial;
}

export const quickFiltersTypeFilterObject: TypeFilterObject<StandardFilterFormValues> = {};

export const typeFilterObject: TypeFilterObject<FormValues> = {
	...quickFiltersTypeFilterObject,
	...IndexForms.searchFormValuesFilterObject,
	...IndexForms.filterFormValuesFilterObject,
};

export const advancedFilterInformation: IndexForms.AdvancedFilterInformation = {
	fieldOptions: [
		{ value: "name" },
		{ value: "description" },
		{ value: "category" },
		{ value: "isPublic" },
		{ value: "createdAt" },
	],
	fieldComparisonOptions: {
		name: IndexForms.stringComparisonOptions,
		description: IndexForms.stringComparisonOptions,
		category: IndexForms.enumComparisonOptions,
		isPublic: IndexForms.booleanComparisonOptions,
		createdAt: IndexForms.timeComparisonOptions,
	},
	fieldComparisonTranslations: {
		name: IndexForms.stringComparisonTranslations,
		description: IndexForms.stringComparisonTranslations,
		category: IndexForms.getRadioComparisonTranslations(DocumentResourceFolderFormValues.categoryOptions),
		isPublic: IndexForms.booleanComparisonTranslations,
		createdAt: IndexForms.timeComparisonTranslations,
	},
};
