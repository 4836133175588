import { omitKeys } from "@hex-insights/core";
import { Campus, Employee, EmployeeRole, Person } from "../../GraphQL";
import * as PersonFormValues from "./person";

export type Base = {
	email: Employee["email"];
	jobTitle: Employee["jobTitle"];
	role: Employee["role"] | null;
	startDate: Employee["startDate"] | null;
	endDate: Employee["endDate"] | null;
	campusIDs: Campus["id"][];
	personID: Person["id"] | null;
};

export type Create = Base;

export type Detail = Base;

export const initialCreate: Create = {
	email: "",
	jobTitle: "",
	role: null,
	startDate: null,
	endDate: null,
	campusIDs: [],
	personID: null,
};

export const roleOptions = [
	{ value: EmployeeRole.Teacher },
	{ value: EmployeeRole.GeneralAdmin },
	{ value: EmployeeRole.EducationAdmin },
	{ value: EmployeeRole.PrincipalAdmin },
	{ value: EmployeeRole.Financial },
];

export type CreateWithPerson = Omit<Create, "personID"> & PersonFormValues.CreateInOther;

export const initialCreateWithPerson: CreateWithPerson = {
	...PersonFormValues.initialCreateInOther,
	...omitKeys(initialCreate, ["personID"]),
};

export function omitPersonCreateFormValues(
	formValues: CreateWithPerson,
): Omit<CreateWithPerson, keyof PersonFormValues.CreateInOther> {
	return omitKeys(formValues, PersonFormValues.createInOtherKeys);
}

export type DetailWithPerson = Omit<Detail, "personID"> & PersonFormValues.DetailInOther;

export function omitPersonDetailFormValues<T extends Partial<DetailWithPerson>>(
	formValues: T,
): Omit<T, keyof PersonFormValues.DetailInOther> {
	return omitKeys(formValues, PersonFormValues.detailInOtherKeys);
}
