import React from "react";
import { ClassNameProps, makeClassName, StyleProps } from "@hex-insights/core";
import styles from "./styles.module.css";

type ProgressBarBackgroundProps = {
	progress: number;
	refreshIntervalMS: number;
} & Partial<ClassNameProps> &
	Partial<StyleProps>;

export function ProgressBarBackground({ progress, refreshIntervalMS, className, style }: ProgressBarBackgroundProps) {
	return (
		<div
			className={makeClassName(styles["progress-bar-background"], className)}
			style={
				{
					...style,
					"--progress": `${progress * 100}%`,
					"--refresh-interval": `${refreshIntervalMS}ms`,
				} as React.CSSProperties
			}
		/>
	);
}
