import { newPageInfo } from "@hex-insights/router";
import { rootPageInfo, RootPageRouteParams } from "../../root.pageinfo";

export type ReportCardsPageRouteParams = RootPageRouteParams;

export const reportCardsPageInfo = newPageInfo({
	title: "Report Cards",
	description: "Report card overview and creation.",
	exact: false,
	to: "/report-cards",
	path: "/report-cards",
	parent: rootPageInfo,
});
