// TODO handling for multiple groups

const publicEventColor = "var(--verita-blue)";
const personalEventColor = "#ad001a";

export function getEventColor(event: { isPublic: boolean; groups?: { color: string }[] }) {
	let eventColor = event.isPublic ? publicEventColor : personalEventColor;
	if (event.groups && event.groups?.length > 0) {
		eventColor = event.groups[0].color;
	}
	return eventColor;
}

export function getEventGroupName(event: { isPublic: boolean; groups?: { name: string }[] }) {
	let groupName = event.isPublic ? "Everyone" : "Personal";
	if (event.groups && event.groups?.length > 0) {
		groupName = event.groups[0].name;
	}
	return groupName;
}
