import React from "react";
import { ApolloCache, FetchResult, gql } from "@apollo/client";
import {
	Discount,
	DiscountCreateInput,
	DiscountCreateMutation,
	DiscountDeleteMutation,
	DiscountDetailDocument,
	DiscountDetailQuery,
	DiscountDetailQueryVariables,
	DiscountUpdateInput,
	DiscountUpdateMutation,
	graphTypeNames,
	ServiceSubscription,
	useDiscountCreateMutation,
	useDiscountDeleteMutation,
	useDiscountUpdateMutation,
} from "../../GraphQL";
import { DiscountFormConversion } from "../ModelFormConversion";
import { DiscountFormValues } from "../ModelFormValues";

/**
 * Returns a `create` function for the Discount model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the Discount model.
 */
export function useCreate() {
	const [createDiscount] = useDiscountCreateMutation();

	return React.useCallback(
		async (formValues: DiscountFormValues.Create) => {
			const input = DiscountFormConversion.toGQLCreateInput(formValues);
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createDiscount({ variables: { input }, update: updateCache });

			return { data: data?.createDiscount ?? null, errors: errors ?? null };
		},
		[createDiscount],
	);
}

/**
 * Returns an `update` function for the Discount model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the Discount model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: Discount["id"]) {
	const [updateDiscount] = useDiscountUpdateMutation();

	return React.useCallback(
		async (changedFormValues: Partial<DiscountFormValues.Detail>, initialFormValues: DiscountFormValues.Detail) => {
			const input = DiscountFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

			const { data, errors } = await updateDiscount({ variables: { id, input }, update: updateCache });

			return { data: data?.updateDiscount ?? null, errors: errors ?? null };
		},
		[updateDiscount, id],
	);
}

/**
 * Returns a `del` function for the Discount model.
 *
 * @param id The ID of the instance to delete.
 */
export function useDelete(id: Discount["id"]) {
	const [deleteDiscount] = useDiscountDeleteMutation();

	return React.useCallback(async () => {
		const updateCache = getUpdateCacheForDelete(id);

		const { data, errors } = await deleteDiscount({ variables: { id }, update: updateCache });

		return { data: data?.deleteDiscount ?? false, errors: errors ?? null };
	}, [deleteDiscount, id]);
}

function getUpdateCacheForCreate(input: DiscountCreateInput) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<DiscountCreateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const createdObject = result.data.createDiscount;

		cache.writeQuery<DiscountDetailQuery, DiscountDetailQueryVariables>({
			query: DiscountDetailDocument,
			data: { discount: createdObject },
			variables: { id: createdObject.id },
		});

		if (input.serviceSubscriptionID) {
			addToDiscountsOfServiceSubscriptionCache(cache, input.serviceSubscriptionID, createdObject);
		}

		cache.evict({ id: "ROOT_QUERY", fieldName: "discountConnection" });
	};
}

function getUpdateCacheForUpdate(input: DiscountUpdateInput, initialFormValues: DiscountFormValues.Detail) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<DiscountUpdateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const updatedObject = result.data.updateDiscount;

		if (initialFormValues.serviceSubscriptionID && input.serviceSubscriptionID) {
			removeFromDiscountsOfServiceSubscriptionCache(cache, initialFormValues.serviceSubscriptionID, updatedObject);
		}
		if (input.serviceSubscriptionID) {
			addToDiscountsOfServiceSubscriptionCache(cache, input.serviceSubscriptionID, updatedObject);
		}
	};
}

function getUpdateCacheForDelete(id: Discount["id"]) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<DiscountDeleteMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (!result.data?.deleteDiscount) {
			return;
		}

		cache.evict({ id: cache.identify({ id, __typename: graphTypeNames.Discount }) });
		cache.evict({ id: "ROOT_QUERY", fieldName: "discountConnection" });
		cache.gc();
	};
}

function addToDiscountsOfServiceSubscriptionCache(
	cache: ApolloCache<any>,
	targetID: ServiceSubscription["id"],
	object: Pick<Discount, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.ServiceSubscription }),
		fields: {
			discounts: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromDiscountsOfServiceSubscriptionCache(
	cache: ApolloCache<any>,
	targetID: ServiceSubscription["id"],
	object: Pick<Discount, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.ServiceSubscription }),
		fields: {
			discounts: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function toCacheRef(cache: ApolloCache<any>, object: Pick<Discount, "id">) {
	return cache.writeFragment({
		fragment: gql`
			fragment DiscountRef on Discount {
				id
			}
		`,
		data: object,
	});
}
