import { RadioInputOption } from "@hex-insights/forms";
import {
	CalendarEvent,
	CourseSection,
	Employee,
	HomeRoomSection,
	Note,
	NoteNoteType,
	Parent,
	Student,
	User,
} from "../../GraphQL";
import * as NoteAttachmentFormValues from "./note-attachment";

export type Base = {
	noteType: Note["noteType"] | null;
	title: Note["title"];
	body: Note["body"];
	details: Note["details"];
	isTimeDependent: Note["isTimeDependent"];
	startDate: Note["startDate"] | null;
	endDate: Note["endDate"] | null;
	isPublic: Note["isPublic"];
	calendarEventIDs: CalendarEvent["id"][];
	courseSectionIDs: CourseSection["id"][];
	employeeIDs: Employee["id"][];
	homeRoomSectionIDs: HomeRoomSection["id"][];
	studentIDs: Student["id"][];
	parentIDs: Parent["id"][];
	sharingUserIDs: User["id"][];
};

export type Create = Base & {
	attachments: NoteAttachmentFormValues.CreateInNote[];
};

export type CreateRequiredField = "noteType" | "body";
export type CreateRequired = Pick<Create, CreateRequiredField>;
export type CreateOptional = Omit<Create, CreateRequiredField>;
export type PartialCreate = CreateRequired & Partial<CreateOptional>;

type FullCreate = Create;

export type Detail = Base & {
	createdAt: Note["createdAt"] | null;
	authorID: User["id"] | null;
};

export const initialCreate: Create = {
	noteType: null,
	title: "",
	body: "",
	details: "",
	isTimeDependent: false,
	startDate: null,
	endDate: null,
	isPublic: false,
	calendarEventIDs: [],
	courseSectionIDs: [],
	employeeIDs: [],
	homeRoomSectionIDs: [],
	studentIDs: [],
	parentIDs: [],
	attachments: [],
	sharingUserIDs: [],
};

export const noteTypeOptions = [
	{ value: NoteNoteType.Academic },
	{ value: NoteNoteType.Announcement },
	{ value: NoteNoteType.Attendance },
	{ value: NoteNoteType.Behavior },
	{ value: NoteNoteType.Financial },
	{ value: NoteNoteType.General },
	{ value: NoteNoteType.Health },
	{ value: NoteNoteType.Incident },
	{ value: NoteNoteType.Injury },
	{ value: NoteNoteType.Post },
];

// Differentiation

export namespace Academic {
	export type CreateInput = Pick<FullCreate, "noteType" | "body" | "details" | "isPublic" | "studentIDs">;
	export type Create = {
		studentIDs: Student["id"][];
		body: string;
		isIssueNatureLearningDifficulty: boolean;
		isIssueNatureAttentionIssue: boolean;
		isIssueNatureFocusIssue: boolean;
		isIssueNatureEmotionalOrVulnerableIssue: boolean;
		isIssueNatureOther: boolean;
		issueNatureOther: string;
		isSolutionRecommendationDifferentiatedLearning: boolean;
		isSolutionRecommendationIndividualLearningPlan: boolean;
		isSolutionRecommendationTutoring: boolean;
		isSolutionRecommendationProfessionalSupport: boolean;
		isSolutionRecommendationInClassSupport: boolean;
		isSolutionRecommendationOther: boolean;
		solutionRecommendationOther: string;
		// isInterestedInMeeting: boolean | null;
	};

	export const initialCreate: Create = {
		studentIDs: [],
		body: "",
		isIssueNatureLearningDifficulty: false,
		isIssueNatureAttentionIssue: false,
		isIssueNatureFocusIssue: false,
		isIssueNatureEmotionalOrVulnerableIssue: false,
		isIssueNatureOther: false,
		issueNatureOther: "",
		isSolutionRecommendationDifferentiatedLearning: false,
		isSolutionRecommendationIndividualLearningPlan: false,
		isSolutionRecommendationTutoring: false,
		isSolutionRecommendationProfessionalSupport: false,
		isSolutionRecommendationInClassSupport: false,
		isSolutionRecommendationOther: false,
		solutionRecommendationOther: "",
		// isInterestedInMeeting: false,
	};
}

export namespace Announcement {
	export type CreateInput = Pick<
		FullCreate,
		"noteType" | "title" | "body" | "startDate" | "endDate" | "isPublic" | "attachments"
	>;
	export type Create = Omit<CreateInput, "noteType" | "isPublic"> & Pick<FullCreate, "isTimeDependent">;

	export const initialCreate: Create = {
		title: "",
		body: "",
		isTimeDependent: false,
		startDate: null,
		endDate: null,
		attachments: [],
	};
}

export namespace Attendance {
	export type CreateInput = Pick<FullCreate, "noteType" | "body" | "startDate" | "endDate" | "isPublic" | "studentIDs">;

	export type Base = Pick<CreateInput, "body" | "startDate" | "endDate" | "studentIDs"> & {
		isMultiDay: boolean;
	};

	export type Create = Base;

	export type Detail = Base & {
		createdAt: Note["createdAt"] | null;
		authorID: User["id"] | null;
	};

	export const initialCreate: Create = {
		body: "",
		isMultiDay: false,
		startDate: null,
		endDate: null,
		studentIDs: [],
	};
}

export namespace Behavior {
	export type CreateInput = Pick<
		FullCreate,
		"noteType" | "body" | "details" | "startDate" | "endDate" | "isPublic" | "studentIDs" | "attachments"
	>;
	export type Create = {
		studentIDs: FullCreate["studentIDs"];
		body: string;
		incidentTime: string | null;
		incidentLocation: SchoolLocation | null;
		isIncidentPhysicalContactAggression: boolean;
		isIncidentFightingPhysicalAggression: boolean;
		isIncidentVerbalAbuseDisrespectingStudent: boolean;
		isIncidentDisrepectingTeacher: boolean;
		isIncidentDisruption: boolean;
		isIncidentDefianceOrNonCompliance: boolean;
		isIncidentOther: boolean;
		incidentOther: string;
		isMotivationAttentionSeeking: boolean;
		isMotivationReaction: boolean;
		isMotivationAnger: boolean;
		isMotivationFrustration: boolean;
		isMotivationHabit: boolean;
		isMotivationLackOfSelfContol: boolean;
		isMotivationOther: boolean;
		motivationOther: string;
		isConsequenceRecommendationDirectorIntervention: boolean;
		isConsequenceRecommendationSENOrSELIntervention: boolean;
		isConsequenceRecommendationStudentServiceTeamIntervention: boolean;
		isConsequenceRecommendationParentContactFromReporter: boolean;
		isConsequenceRecommendationParentContactFromSchoolDirector: boolean;
		isConsequenceRecommendationOther: boolean;
		consequenceRecommendationOther: string;
		additionalComments: string;
	};

	export const initialCreate: Create = {
		studentIDs: [],
		body: "",
		incidentTime: null,
		incidentLocation: null,
		isIncidentPhysicalContactAggression: false,
		isIncidentFightingPhysicalAggression: false,
		isIncidentVerbalAbuseDisrespectingStudent: false,
		isIncidentDisrepectingTeacher: false,
		isIncidentDisruption: false,
		isIncidentDefianceOrNonCompliance: false,
		isIncidentOther: false,
		incidentOther: "",
		isMotivationAttentionSeeking: false,
		isMotivationReaction: false,
		isMotivationAnger: false,
		isMotivationFrustration: false,
		isMotivationHabit: false,
		isMotivationLackOfSelfContol: false,
		isMotivationOther: false,
		motivationOther: "",
		isConsequenceRecommendationDirectorIntervention: false,
		isConsequenceRecommendationSENOrSELIntervention: false,
		isConsequenceRecommendationStudentServiceTeamIntervention: false,
		isConsequenceRecommendationParentContactFromReporter: false,
		isConsequenceRecommendationParentContactFromSchoolDirector: false,
		isConsequenceRecommendationOther: false,
		consequenceRecommendationOther: "",
		additionalComments: "",
	};
}

export namespace Financial {
	export type CreateInput = Pick<FullCreate, "noteType" | "isPublic" | "studentIDs" | "body">;
	export type Base = {
		studentIDs: Student["id"][];
		body: string;
	};

	export type Create = Base;

	// Connections managed externally depending on where note is created.

	export type Detail = Base & {
		createdAt: Note["createdAt"] | null;
		authorID: User["id"] | null;
	};

	export const initialCreate: Create = {
		studentIDs: [],
		body: "",
	};
}

export namespace General {
	export type CreateInput = Pick<
		FullCreate,
		| "noteType"
		| "body"
		| "isPublic"
		| "calendarEventIDs"
		| "courseSectionIDs"
		| "employeeIDs"
		| "homeRoomSectionIDs"
		| "studentIDs"
		| "parentIDs"
		| "sharingUserIDs"
	>;

	export type Base = {
		studentIDs: Student["id"][];
		body: string;
		isPublic: boolean;
		sharingUserIDs: User["id"][];
	};

	export type Create = Base;

	export type Detail = Base & {
		createdAt: Note["createdAt"] | null;
		authorID: User["id"] | null;
	};

	export const initialCreate: Create = {
		studentIDs: [],
		body: "",
		isPublic: false,
		sharingUserIDs: [],
	};
}

export namespace Health {
	export type CreateInput = Pick<FullCreate, "noteType" | "body" | "studentIDs">;
	export type Create = {
		studentIDs: Student["id"][];
		body: string;
	};

	export const initialCreate: Create = {
		studentIDs: [],
		body: "",
	};
}

export namespace Incident {
	export type CreateInput = Pick<
		FullCreate,
		"noteType" | "body" | "details" | "startDate" | "endDate" | "isPublic" | "studentIDs"
	>;
	export type Create = {
		studentIDs: FullCreate["studentIDs"];
		body: string;
		incidentTime: string | null;
		incidentLocation: SchoolLocation | null;
		actionsTaken: string;
		suggestionsForFuture: string;
	};

	export const initialCreate: Create = {
		studentIDs: [],
		body: "",
		incidentTime: null,
		incidentLocation: null,
		actionsTaken: "",
		suggestionsForFuture: "",
	};
}

export namespace Injury {
	export type CreateInput = Pick<
		FullCreate,
		"noteType" | "body" | "details" | "startDate" | "endDate" | "isPublic" | "studentIDs"
	>;
	export type Create = {
		studentIDs: FullCreate["studentIDs"];
		body: string;
		incidentTime: string | null;
		incidentLocation: SchoolLocation | null;
		wereOtherStudentsCauseOfInjury: boolean | null;
		// addBehaviorNote: boolean | null;
		causeOfInjury: string;
		medicalAttentionProvided: string;
		adultWhoProvidedMedicalAttention: string;
		isActionTakenHomeroomTeachersNotified: boolean;
		isActionTakenParentNotifiedBySeesaw: boolean;
		isActionTakenParentNotifiedByPhone: boolean;
		isActionTakenParentsRequestedToCollectStudent: boolean;
		isActionTakenParentNotificationNotNecessary: boolean;
		isActionTakenOther: boolean;
		actionTakenOther: string;
	};

	export const initialCreate: Create = {
		studentIDs: [],
		body: "",
		incidentTime: null,
		incidentLocation: null,
		wereOtherStudentsCauseOfInjury: null,
		// addBehaviorNote: null,
		causeOfInjury: "",
		medicalAttentionProvided: "",
		adultWhoProvidedMedicalAttention: "",
		isActionTakenHomeroomTeachersNotified: false,
		isActionTakenParentNotifiedBySeesaw: false,
		isActionTakenParentNotifiedByPhone: false,
		isActionTakenParentsRequestedToCollectStudent: false,
		isActionTakenParentNotificationNotNecessary: false,
		isActionTakenOther: false,
		actionTakenOther: "",
	};
}

export namespace SocialEmotional {
	// Same as Academic for now
	export type CreateInput = Academic.CreateInput;
	export type Create = Academic.Create;

	export const initialCreate = Academic.initialCreate;
}

export enum SchoolLocation {
	Classroom = "Classroom",
	Hallways = "Hallways",
	Cafeteria = "Cafeteria",
	Playground = "Playground",
	Garden = "Garden",
	SpecialtyClass = "Specialty Class",
	Transportation = "Transportation",
}

export const schoolLocationOptions: RadioInputOption<SchoolLocation | null>[] = [
	{ value: SchoolLocation.Classroom },
	{ value: SchoolLocation.Hallways },
	{ value: SchoolLocation.Cafeteria },
	{ value: SchoolLocation.Playground },
	{ value: SchoolLocation.Garden },
	{ value: SchoolLocation.SpecialtyClass },
	{ value: SchoolLocation.Transportation },
];

export const booleanOptions: RadioInputOption<boolean | null>[] = [
	{ label: "Yes", value: true },
	{ label: "No", value: false },
];

export const isPublicOptions: RadioInputOption<boolean>[] = [
	{ value: true, label: "Public" },
	{ value: false, label: "Private" },
];
