import { EditMode, FormType, useFormState } from "@hex-insights/forms";
import { EnrollmentApplicationFormValues } from "../ModelFormValues";

/**
 * Returns a `formState` object for use in the create form for the EnrollmentApplication model.
 */
export function useCreateFormState() {
	return useFormState({
		initialFormValues: EnrollmentApplicationFormValues.initialCreate,
		formType: FormType.Create,
	});
}

const detailFieldEditModes = {
	createdAt: EditMode.ReadOnly,
	submissionStudentID: EditMode.ReadOnly,
	enrollmentApplicationSubmissionID: EditMode.ReadOnly,
};

/**
 * Returns a `formState` object for use in the detail form of the EnrollmentApplication model.
 *
 * @param initialFormValues The initial values for the form. Generally from
 * {@link EnrollmentApplicationFormConversion.toFormValues}.
 */
export function useDetailFormState({
	initialFormValues,
}: {
	initialFormValues: EnrollmentApplicationFormValues.Detail;
}) {
	return useFormState({
		initialFormValues: initialFormValues,
		formType: FormType.Update,
		fieldEditModes: detailFieldEditModes,
	});
}

/**
 * Returns a read-only `formState` object for use in the detail form of the EnrollmentApplication model.
 *
 * @param initialFormValues The initial values for the form. Generally from
 * {@link EnrollmentApplicationFormConversion.toFormValues}.
 */
export function useReadOnlyFormState({
	initialFormValues,
}: {
	initialFormValues: EnrollmentApplicationFormValues.Detail;
}) {
	return useFormState({
		initialFormValues: initialFormValues,
		editMode: EditMode.ReadOnly,
	});
}
