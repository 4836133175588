import React from "react";
import {
	Button,
	ClassNameProps,
	Column,
	compareObjects,
	compareWithQueryDesc,
	Conditional,
	Else,
	getArrayDiff,
	Grid,
	Heading,
	Icon,
	If,
	List,
	makeObjectFromArrayMap,
	matchQuery,
	Modal,
	ModalProps,
	NotificationContainer,
	RequiredKeys,
	Row,
	Section,
	StyleProps,
	useLazyRef,
	useNotifications,
	useToggle,
} from "@hex-insights/core";
import { FieldIf, TextField, useFormState, ValidationDisplayPolicy } from "@hex-insights/forms";
import { useRouteParams } from "@hex-insights/router";
import {
	CourseAttendanceRecord,
	CourseAttendanceRecordField,
	CourseAttendanceRecordFormValues,
	CourseSectionPeriod,
	PersonIcon,
	PersonIconProps,
	Student,
	StudentNoteFormModal,
	Tile,
	useCourseAttendanceRecordsBulkCreate,
	useCourseAttendanceRecordUpdateMutation,
	useCourseSectionPeriodProgress,
} from "@hex-insights/verita.shared";
import { ScheduleDayCoursePeriodPageRouteParams } from "./pageinfo";
import { usePageCourseSectionPeriodAttendanceQuery, usePageCourseSectionPeriodQuery } from "./utils";
import styles from "./students.module.css";

export type StudentsTileProps = Partial<ClassNameProps & StyleProps>;

export function StudentsTile({ className, style }: StudentsTileProps) {
	const { data } = usePageCourseSectionPeriodQuery();
	const courseSectionPeriod = data!.courseSectionPeriod;
	const students = courseSectionPeriod.courseSection.courseSectionStudentEnrollments.map((e) => e.student) ?? [];

	const { isCurrentlyActive } = useCourseSectionPeriodProgress(courseSectionPeriod);

	const { loading: loadingAttendanceData, data: attendanceData } = usePageCourseSectionPeriodAttendanceQuery();
	const hasAttendanceRecords = (attendanceData?.courseAttendanceRecordConnection.edges.length ?? 0) > 0;
	const studentAttedanceRecordMap = React.useMemo(() => {
		if (!attendanceData) {
			return {};
		}
		return makeObjectFromArrayMap(attendanceData.courseAttendanceRecordConnection.edges, (e) => [
			e.node.studentID,
			e.node,
		]);
	}, [attendanceData]);

	return (
		<Tile className={className} style={style}>
			<Tile.Header>
				<Heading level={2} noMargin>
					Students
				</Heading>
			</Tile.Header>
			<Tile.Body style={{ height: "100%", paddingTop: 0, overflow: "hidden" }}>
				<Column style={{ height: "100%" }}>
					<If condition={students.length > 0 && !loadingAttendanceData && !hasAttendanceRecords}>
						<StudentAttendance />
					</If>

					<Column style={{ height: "100%", overflow: "scroll" }}>
						<List className={styles["student-tile__list"]} style={{ flexGrow: 1 }}>
							{students.map((e) => {
								const isPresent = studentAttedanceRecordMap[e.id]?.isPresent ?? false;
								let attendanceStatusColor = "transparent";
								let attendanceStatusTitle: string | undefined;
								if (hasAttendanceRecords) {
									attendanceStatusColor = isPresent ? "var(--success-color)" : "var(--danger-color)";
									attendanceStatusTitle = isPresent ? "Present" : "Absent";
								}

								return (
									<List.Item key={e.id} className={styles["student-tile__list__item"]}>
										<Row justify="space-between" align="center" className={styles["student-tile__list__item__row"]}>
											<Row justify="spaced-start" align="center" style={{ marginRight: "0.25rem" }}>
												<div style={{ position: "relative" }}>
													<PersonIcon person={e.person} imageSize="1.75rem" />
													<div
														title={attendanceStatusTitle}
														style={{
															backgroundColor: attendanceStatusColor,
															width: "0.5rem",
															height: "0.5rem",
															borderRadius: "50%",
															position: "absolute",
															bottom: 0,
															right: 0,
														}}
													></div>
												</div>
												<span>{e.person.name}</span>
											</Row>
											<Row
												justify="spaced-start"
												horizontalSpacing="0.25rem"
												align="center"
												className={styles["student-tile__list__item__action-buttons-row"]}
											>
												<If condition={isPresent}>
													<NoteController student={e} />
												</If>

												{hasAttendanceRecords && !isPresent && isCurrentlyActive && (
													<MarkAsPresent student={e} attendanceRecordID={studentAttedanceRecordMap[e.id].id} />
												)}
											</Row>
										</Row>
									</List.Item>
								);
							})}
						</List>
					</Column>
				</Column>
			</Tile.Body>
		</Tile>
	);
}

type NoteControllerProps = {
	student: Pick<Student, "id"> & {
		person: Pick<Student["person"], "id" | "name">;
	};
};

function NoteController({ student }: NoteControllerProps) {
	const { isOn: isOpen, toggle: toggleIsOpen } = useToggle();

	return (
		<React.Fragment>
			<Button size="small" onClick={toggleIsOpen} title={`Write a note about ${student.person.name}`}>
				<Column>
					<Icon.Edit size="1.25rem" />
				</Column>
			</Button>

			<Modal.If condition={isOpen}>
				<StudentNoteFormModal studentID={student.id} onClose={toggleIsOpen} />
			</Modal.If>
		</React.Fragment>
	);
}

type MarkAsPresentProps = {
	student: Pick<Student, "id"> & {
		person: Pick<Student["person"], "id" | "name">;
	};
	attendanceRecordID: CourseAttendanceRecord["id"];
};

function MarkAsPresent({ student, attendanceRecordID }: MarkAsPresentProps) {
	// TODO handling for undo/confirm

	const [update, { loading }] = useCourseAttendanceRecordUpdateMutation();
	const onClick = React.useCallback(async () => {
		update({ variables: { id: attendanceRecordID, input: { isPresent: true } } });
	}, [update, attendanceRecordID]);

	return (
		<Button size="small" onClick={onClick} title={`Mark ${student.person.name} as present`} disabled={loading}>
			<Column>{loading ? <Icon.Loader size="1.25rem" /> : <Icon.UserPlus size="1.25rem" />}</Column>
		</Button>
	);
}

function StudentAttendance() {
	const { date } = useRouteParams<ScheduleDayCoursePeriodPageRouteParams>();
	const { data } = usePageCourseSectionPeriodQuery();
	const courseSectionPeriod = data!.courseSectionPeriod;
	const courseSection = courseSectionPeriod.courseSection;
	const students = React.useMemo(
		() => courseSection.courseSectionStudentEnrollments.map((e) => e.student),
		[courseSection.courseSectionStudentEnrollments],
	);

	const prevStudentIDsRef = useLazyRef(() => students.map((e) => e.id));
	const [formValuesSets, setFormValuesSets] = React.useState(() =>
		students.map((e) => makeCourseAttendanceRecordFormValues(courseSectionPeriod.id, e.id, date + "T00:00:00Z")),
	);

	React.useEffect(() => {
		const { added, removed } = getArrayDiff(
			prevStudentIDsRef.current,
			students.map((e) => e.id),
		);

		setFormValuesSets((prev) => [
			...prev.filter((e) => !removed.includes(e.studentID ?? "")),
			...added.map((e) => makeCourseAttendanceRecordFormValues(courseSectionPeriod.id, e, date + "T00:00:00Z")),
		]);
	}, [prevStudentIDsRef, students, courseSectionPeriod.id, date]);

	const { isCurrentlyActive } = useCourseSectionPeriodProgress(courseSectionPeriod);
	const { isOn: isAttendanceModalOpen, toggle: toggleIsAttendanceModalOpen } = useToggle(isCurrentlyActive);

	const [isDone, setIsDone] = React.useState(false);
	const onSuccess = React.useCallback(() => setIsDone(true), []);

	if (isDone) {
		return null;
	}

	return (
		<Row justify="center" style={{ padding: "0.5rem 0" }}>
			<Button variant="danger" onClick={toggleIsAttendanceModalOpen}>
				Take Attendance
			</Button>

			<Modal.If condition={isAttendanceModalOpen}>
				<AttendanceModal
					formValuesSets={formValuesSets}
					setFormValuesSets={setFormValuesSets}
					onClose={toggleIsAttendanceModalOpen}
					onSuccess={onSuccess}
				/>
			</Modal.If>
		</Row>
	);
}

function makeCourseAttendanceRecordFormValues(
	courseSectionPeriodID: CourseSectionPeriod["id"],
	studentID: Student["id"],
	attendanceDate: string,
): CourseAttendanceRecordFormValues.Create {
	return {
		courseSectionPeriodID,
		studentID,
		attendanceDate,
		isPresent: true,
		isLate: false,
		isExcused: false,
		notes: "",
	};
}

type AttendanceModalSearchFormValues = {
	search: string;
};

const initialAttendanceModalSearchFormValues: AttendanceModalSearchFormValues = {
	search: "",
};

type AttendanceModalProps = {
	formValuesSets: CourseAttendanceRecordFormValues.Create[];
	setFormValuesSets: React.Dispatch<React.SetStateAction<CourseAttendanceRecordFormValues.Create[]>>;
	onSuccess: () => void;
} & RequiredKeys<Pick<ModalProps, "ifRef" | "onClose">, "onClose">;

function AttendanceModal({ formValuesSets, setFormValuesSets, onSuccess, ifRef, onClose }: AttendanceModalProps) {
	const searchFormState = useFormState({
		initialFormValues: initialAttendanceModalSearchFormValues,
		validationDisplayPolicy: ValidationDisplayPolicy.none,
	});
	const { search: searchValue } = searchFormState.formValues;

	const { data } = usePageCourseSectionPeriodQuery();
	const students = data!.courseSectionPeriod.courseSection.courseSectionStudentEnrollments.map((e) => e.student);
	const formValuesMap = React.useMemo(
		() => makeObjectFromArrayMap(formValuesSets, (e) => [e.studentID ?? "", e]),
		[formValuesSets],
	);

	const filteredStudents = React.useMemo(() => {
		const filter = matchQuery(searchValue);
		const compare = compareObjects<{ person: { name: string } }>(
			(e) => e.person.name,
			compareWithQueryDesc(searchValue),
		);
		return students.filter((e) => filter(e.person.name)).sort(compare);
	}, [searchValue, students]);

	const numStudentsPresent = formValuesSets.filter((e) => e.isPresent).length;
	const totalStudents = formValuesSets.length;

	const { isOn: isOnReview, toggle: toggleIsOnReview } = useToggle(true);

	const [isSubmitting, setIsSubmitting] = React.useState(false);
	const { addNotification } = useNotifications();
	const create = useCourseAttendanceRecordsBulkCreate();
	const onSubmit = React.useCallback(async () => {
		setIsSubmitting(true);
		const { errors } = await create(formValuesSets);
		if (!errors) {
			addNotification(
				(props) => (
					<NotificationContainer {...props} variant="success" size="small">
						Attendance Recorded <span style={{ width: "1rem", display: "inline-block" }}></span>
					</NotificationContainer>
				),
				2500,
			);
			onClose();
			onSuccess();
		} else {
			addNotification(
				(props) => (
					<NotificationContainer {...props} variant="danger" size="small">
						Error recording attendance, please try again later.{" "}
						<span style={{ width: "1rem", display: "inline-block" }}></span>
					</NotificationContainer>
				),
				2500,
			);
			setIsSubmitting(false);
		}
	}, [create, formValuesSets, addNotification, onClose, onSuccess]);

	return (
		<Modal
			ifRef={ifRef}
			onClose={onClose}
			disableClose={isSubmitting}
			style={{ width: "80vw", maxWidth: "none", height: "90vh" }}
		>
			<Modal.Header>
				<Heading level={2} noMargin>
					Attendance
				</Heading>
			</Modal.Header>
			<Modal.Body style={{ height: "100%", overflow: "hidden" }}>
				<Conditional>
					<If condition={!isOnReview}>
						<Column justify="spaced-start" style={{ height: "100%", overflow: "hidden" }}>
							<Row justify="space-between" align="center">
								<TextField formState={searchFormState} name="search" />

								<span style={{ fontSize: "1.5rem" }}>
									Students Present: {numStudentsPresent}/{totalStudents}
								</span>
							</Row>

							<Section style={{ height: "100%", overflow: "hidden" }}>
								<Section.Body style={{ height: "100%", overflow: "scroll" }}>
									<Grid columns={5} gap="0.5rem">
										{filteredStudents.map((e) => (
											<AttendanceStudentItem
												key={e.id}
												student={e}
												formValues={formValuesMap[e.id]}
												setFormValuesSets={setFormValuesSets}
											/>
										))}
									</Grid>
								</Section.Body>
							</Section>
						</Column>
					</If>

					<Else>
						<ConfirmationPreview formValuesSets={formValuesSets} />
					</Else>
				</Conditional>
			</Modal.Body>

			<Modal.Footer>
				<Conditional>
					<If condition={!isOnReview}>
						<Row justify="flex-end">
							<Button variant="primary" onClick={toggleIsOnReview}>
								Continue
							</Button>
						</Row>
					</If>

					<Else>
						<Row justify="space-between">
							<Button variant="secondary" onClick={toggleIsOnReview} disabled={isSubmitting}>
								Edit Attendance
							</Button>

							<Button variant="primary" onClick={onSubmit} isLoading={isSubmitting}>
								Confirm and Submit
							</Button>
						</Row>
					</Else>
				</Conditional>
			</Modal.Footer>
		</Modal>
	);
}

type AttendanceStudentItemProps = {
	student: { id: Student["id"]; person: PersonIconProps["person"] };
	formValues: CourseAttendanceRecordFormValues.Create;
	setFormValuesSets: React.Dispatch<React.SetStateAction<CourseAttendanceRecordFormValues.Create[]>>;
};

function AttendanceStudentItem({
	student,
	formValues: initialFormValues,
	setFormValuesSets,
}: AttendanceStudentItemProps) {
	const setFormValues = React.useCallback(
		(fv: CourseAttendanceRecordFormValues.Create) => {
			setFormValuesSets((prev) => prev.map((e) => (e.studentID === fv.studentID ? fv : e)));
		},
		[setFormValuesSets],
	);

	const formState = useFormState({ initialFormValues });
	const { formValues } = formState;
	React.useEffect(() => {
		setFormValues(formValues);
	}, [setFormValues, formValues]);
	const { isPresent, isLate } = formState.formValues;
	const { isPresent: setIsPresent, isLate: setIsLate } = formState.formSetFunctions;

	const { isOn: isFullModalOpen, toggle: toggleIsFullModalOpen } = useToggle(false);

	return (
		<div onClick={toggleIsFullModalOpen}>
			<Column
				className={
					styles["attendance-form__student-item"] +
					" " +
					styles[`attendance-form__student-item--${isPresent ? "present" : "absent"}`]
				}
			>
				<span className={styles["attendance-form__student-item__presence-status"]}>
					{isPresent ? "Present" : "Absent"}
				</span>

				<If condition={isLate}>
					<span className={styles["attendance-form__student-item__late-status"]}>Late</span>
				</If>

				<PersonIcon person={student.person} withName imageSize="3rem" />

				<Column>
					<span style={{ fontSize: "0.8rem" }}>Mark as:</span>
					<Row justify="space-around">
						<Button
							variant="secondary"
							size="small"
							onClick={(e) => {
								e.stopPropagation();
								setIsPresent((prev) => !prev);
								setIsLate(false);
							}}
						>
							{isPresent ? "Absent" : "Present"}
						</Button>

						<If condition={isPresent}>
							<Button
								variant="tertiary"
								size="small"
								onClick={(e) => {
									e.stopPropagation();
									setIsLate((prev) => !prev);
								}}
							>
								{isLate ? "On-Time" : "Late"}
							</Button>
						</If>
					</Row>
				</Column>
			</Column>

			<Modal.If condition={isFullModalOpen}>
				<Modal onClose={toggleIsFullModalOpen}>
					<Modal.Header>
						<Heading level={3} noMargin>
							{student.person.name}
						</Heading>
					</Modal.Header>
					<Modal.Body>
						<Column justify="spaced-start">
							<CourseAttendanceRecordField.IsPresent formState={formState} />

							<FieldIf condition={isPresent} formState={formState} name="isLate">
								<CourseAttendanceRecordField.IsLate formState={formState} />
							</FieldIf>

							<FieldIf condition={!isPresent || isLate} formState={formState} name="isExcused">
								<CourseAttendanceRecordField.IsExcused formState={formState} />
							</FieldIf>

							<CourseAttendanceRecordField.Notes formState={formState} />
						</Column>
					</Modal.Body>
				</Modal>
			</Modal.If>
		</div>
	);
}

type ConfirmationPreviewProps = {
	formValuesSets: CourseAttendanceRecordFormValues.Create[];
};

function ConfirmationPreview({ formValuesSets }: ConfirmationPreviewProps) {
	const { data } = usePageCourseSectionPeriodQuery();
	const students = data!.courseSectionPeriod.courseSection.courseSectionStudentEnrollments.map((e) => e.student);
	const studentsMap = React.useMemo(() => makeObjectFromArrayMap(students, (e) => [e.id, e]), [students]);

	const presentFormValuesSets = formValuesSets.filter((e) => e.isPresent && !e.isLate);
	const lateFormValuesSets = formValuesSets.filter((e) => e.isPresent && e.isLate);
	const absentFormValuesSets = formValuesSets.filter((e) => !e.isPresent);

	return (
		<Column justify="spaced-start" style={{ height: "100%", overflow: "scroll" }}>
			<If condition={absentFormValuesSets.length > 0}>
				<Section>
					<Section.Header>
						<Heading level={3} noMargin>
							Absent: {absentFormValuesSets.length}
						</Heading>
					</Section.Header>
					<Section.Body>
						<Grid columns={5} gap="1rem">
							{absentFormValuesSets.map((e) => (
								<PersonIcon
									key={e.studentID}
									person={studentsMap[e.studentID ?? ""].person}
									withName
									imageSize="3rem"
								/>
							))}
						</Grid>
					</Section.Body>
				</Section>
			</If>

			<If condition={lateFormValuesSets.length > 0}>
				<Section>
					<Section.Header>
						<Heading level={3} noMargin>
							Late: {lateFormValuesSets.length}
						</Heading>
					</Section.Header>
					<Section.Body>
						<Grid columns={5} gap="1rem">
							{lateFormValuesSets.map((e) => (
								<PersonIcon
									key={e.studentID}
									person={studentsMap[e.studentID ?? ""].person}
									withName
									imageSize="3rem"
								/>
							))}
						</Grid>
					</Section.Body>
				</Section>
			</If>

			<If condition={presentFormValuesSets.length > 0}>
				<Section>
					<Section.Header>
						<Heading level={3} noMargin>
							Present: {presentFormValuesSets.length} / {formValuesSets.length}
						</Heading>
					</Section.Header>
					<Section.Body>
						<Grid columns={5} gap="1rem">
							{presentFormValuesSets.map((e) => (
								<PersonIcon
									key={e.studentID}
									person={studentsMap[e.studentID ?? ""].person}
									withName
									imageSize="3rem"
								/>
							))}
						</Grid>
					</Section.Body>
				</Section>
			</If>
		</Column>
	);
}
