import React from "react";
import { Cell, Tooltip as ChartTooltip, Pie, PieChart, ResponsiveContainer } from "recharts";
import { IndexForms } from "@hex-insights/app-modules";
import { defaultPieProps } from "@hex-insights/charts";
import {
	addTimeToDate,
	Button,
	cartesianProduct,
	Column,
	Conditional,
	count,
	dateTrunc,
	Else,
	englishCommaJoinStrings,
	filterAndSortByQuery,
	formatDateTime,
	Grid,
	Heading,
	If,
	Modal,
	ModalProps,
	relativeTime,
	RequiredKeys,
	Row,
	Section,
	stringToLocalDate,
	StyleProps,
	toGrammaticalNumber,
	toLocalDateString,
} from "@hex-insights/core";
import { anyFieldChanged, FormState, TextField, useFormState, ValidationDisplayPolicy } from "@hex-insights/forms";
import { EmployeeWrapperContext } from "../../../Contexts";
import {
	NoteFilterInput,
	NoteNoteType,
	NoteOrderField,
	OrderDirection,
	SchoolAttendanceRecordDailyReportListQuery,
	SchoolAttendanceRecordFormState,
	SchoolAttendanceRecordFormValues,
	SchoolAttendanceRecordMutation,
	StudentFilterFormState,
	StudentFilterFormUtils,
	StudentFilterFormValues,
	useNoteStudentNotesSlimListQuery,
	useSchoolAttendanceRecordDailyReportListQuery,
	useSchoolAttendanceRecordDetailQuery,
} from "../../../Utilities";
import { SchoolAttendanceRecordForm, StudentCampusFilterButtons, StudentHomeRoomFilterButtons } from "../../Forms";
import { HR } from "../../HR";
import { EmployeeLink } from "../../Links";
import { EmployeePreviewTooltip, PersonIcon } from "../../PersonIcon";
import { TextAreaFieldDisplay } from "../../TextAreaField";
import { Tile } from "../../Tile";
import styles from "./styles.module.css";

type SchoolAttendanceRecordIndexQueryNode =
	SchoolAttendanceRecordDailyReportListQuery["studentConnection"]["edges"][0]["node"];

export function AttendanceTile({ style }: Partial<StyleProps>) {
	const { employee } = React.useContext(EmployeeWrapperContext);
	const campusIDs = employee?.campuses.map((e) => e.id) ?? [];
	const { data } = useSchoolAttendanceRecordDailyReportListQuery({
		variables: {
			filters: StudentFilterFormUtils.toFilterInputs({
				...StudentFilterFormValues.initial,
				hasHomeRoomSectionStudentEnrollments: true,
				campusIDs,
			}),
			attendanceFilters: {
				checkInTimeGTE: toLocalDateString(new Date()),
				checkInTimeLT: toLocalDateString(addTimeToDate(new Date(), [1, "day"])),
			},
		},
	});

	const { presentList, absentList, notRecordedList } = React.useMemo(
		() => partitionStudentSchoolAttendance(data?.studentConnection.edges),
		[data],
	);
	const chartData = [
		{
			name: "Present",
			value: presentList.length,
		},
		{
			name: "Absent",
			value: absentList.length,
		},
		{
			name: "Not Taken",
			value: notRecordedList.length,
		},
	];

	const { isModalOpen, toggleIsModalOpen } = Modal.useToggle(false);

	return (
		<React.Fragment>
			<div style={style} onClick={toggleIsModalOpen}>
				<Tile style={{ height: "100%" }}>
					<Tile.Header>
						<Heading level={3} noMargin style={{ textAlign: "center" }}>
							Attendance
						</Heading>
						<HR color="#ddd" style={{ width: "80%", margin: "0 auto" }} />
					</Tile.Header>
					<Tile.Body style={{ padding: "0.5rem 0.75rem", overflowY: "scroll" }}>
						<Column justify="spaced-center" style={{ width: "100%", height: "100%" }}>
							<Column justify="center" style={{ flexGrow: 1 }}>
								<div style={{ width: "100%", height: "10rem" }}>
									<AttendanceChart data={chartData} />
								</div>
							</Column>
							<Row justify="spaced-center" align="center" style={{ width: "100%" }}>
								<Column align="center" style={{ width: "100%" }}>
									<span style={{ fontSize: "0.9rem", color: "#777" }}>Present</span>
									<span style={{ fontSize: "2rem" }}>{presentList.length}</span>
								</Column>

								<Column align="center" style={{ width: "100%" }}>
									<span style={{ fontSize: "0.9rem", color: "#777" }}>Absent</span>
									<span style={{ fontSize: "2rem" }}>{absentList.length}</span>
								</Column>

								<Column align="center" style={{ width: "100%" }}>
									<span style={{ fontSize: "0.9rem", color: "#777", whiteSpace: "nowrap" }}>Not Taken</span>
									<span style={{ fontSize: "2rem" }}>{notRecordedList.length}</span>
								</Column>
							</Row>

							<Row justify="center">
								<Button variant="link" size="small" onClick={() => {}}>
									View Students
								</Button>
							</Row>
						</Column>
					</Tile.Body>
				</Tile>
			</div>

			<Modal.If condition={isModalOpen}>
				<SchoolAttendanceOverviewModal onClose={toggleIsModalOpen} />
			</Modal.If>
		</React.Fragment>
	);
}

const colors = {
	Present: "#58cf30",
	Absent: "#de4133",
	"Not Taken": "#eee",
};

type AttendanceChartProps = {
	data: { name: string; value: number }[];
};

function AttendanceChart({ data }: AttendanceChartProps) {
	return (
		<ResponsiveContainer width="100%" height="100%">
			<PieChart>
				<ChartTooltip isAnimationActive={false} />

				<Pie {...defaultPieProps} data={data} nameKey="name" dataKey="value" label={false}>
					{data.map((e) => (
						<Cell key={e.name} fill={colors[e.name]} />
					))}
				</Pie>
			</PieChart>
		</ResponsiveContainer>
	);
}

const gridResponsiveColumns = {
	0: 1,
	500: 2,
	800: 3,
	1000: 4,
	1200: 5,
};

type SearchFormValues = {
	search: string;
};

const initialSearchFormValues: SearchFormValues = {
	search: "",
};

export type SchoolAttendanceOverviewModalProps = Pick<ModalProps, "ifRef" | "onClose">;

export function SchoolAttendanceOverviewModal({ ifRef, onClose }: SchoolAttendanceOverviewModalProps) {
	const { employee } = React.useContext(EmployeeWrapperContext);
	const campusIDs = employee?.campuses.map((e) => e.id);
	const initialFilterFormValues = React.useMemo<Partial<StudentFilterFormValues.FormValues>>(
		() => ({ hasHomeRoomSectionStudentEnrollments: true, campusIDs: campusIDs ?? [] }),
		[campusIDs],
	);
	const searchFormState = useFormState({
		initialFormValues: initialSearchFormValues,
		validationDisplayPolicy: ValidationDisplayPolicy.none,
	});
	const filterFormState = StudentFilterFormState.useFormStateWithoutQueryStateSync(initialFilterFormValues);
	const filterInputs = IndexForms.useFilterInput(filterFormState.formValues, StudentFilterFormUtils.toFilterInputs);

	const { loading, data } = useSchoolAttendanceRecordDailyReportListQuery({
		variables: {
			filters: filterInputs,
			attendanceFilters: {
				checkInTimeGTE: toLocalDateString(new Date()),
				checkInTimeLT: toLocalDateString(addTimeToDate(new Date(), [1, "day"])),
			},
		},
	});

	const { search } = searchFormState.formValues;
	const filteredEdges = React.useMemo(() => {
		if (!data) {
			return [];
		}
		return filterAndSortByQuery(search, data.studentConnection.edges, (e) => e.node.person.name);
	}, [data, search]);

	const { presentList, absentList, notRecordedList } = React.useMemo(
		() => partitionStudentSchoolAttendance(filteredEdges),
		[filteredEdges],
	);

	const numPresent = presentList.length;
	const numLate = count(presentList, (e) => e.schoolAttendanceRecords[0].isLate);
	const numAbsent = absentList.length;
	const numNotRecorded = notRecordedList.length;
	const totalStudents = presentList.length + absentList.length + notRecordedList.length;

	// modal controlled at top-level (here) instead of in the <StudentAttendanceIcon> component because when the create
	// action finishes the <StudentAttendanceIcon> unmounts, not allowing for proper cleanup
	const [activeModal, setActiveModal] = React.useState<
		["create" | "detail" | null, SchoolAttendanceRecordIndexQueryNode]
	>([null, { id: "", person: { id: "", name: "", imageURL: "" }, schoolAttendanceRecords: [] }]);
	const openModal = React.useCallback((student: SchoolAttendanceRecordIndexQueryNode) => {
		const hasAttendanceRecords = student.schoolAttendanceRecords.length > 0;
		setActiveModal(hasAttendanceRecords ? ["detail", student] : ["create", student]);
	}, []);
	const closeModal = React.useCallback(() => {
		setActiveModal((prev) => [null, prev[1]]); // student preserved for unmount animation
	}, []);

	return (
		<Modal size="large" ifRef={ifRef} onClose={onClose} style={{ maxWidth: "80vw" }}>
			<Modal.Header>
				<Heading level={2} noMargin>
					Attendance
				</Heading>
			</Modal.Header>
			<Modal.Body>
				<Column justify="spaced-start" align="center">
					<form>
						<Column justify="spaced-start">
							<TextField formState={searchFormState} name="search" autoFocus />
							<AttendanceStudentFilters filterFormState={filterFormState} />
						</Column>
					</form>

					<StudentAttendanceStats
						numPresent={numPresent}
						numLate={numLate}
						numAbsent={numAbsent}
						numNotRecorded={numNotRecorded}
					/>

					<div>
						<Conditional>
							<If condition={loading}>Loading...</If>
							<Else>
								<If condition={absentList.length > 0}>
									<Section>
										<Section.Header>
											<Heading level={3} noMargin>
												Absent: {absentList.length}
											</Heading>
										</Section.Header>
										<Section.Body>
											<Grid responsiveColumns={gridResponsiveColumns} gap="1rem">
												{absentList.map((e) => (
													<StudentAttendanceIcon key={e.id} student={e} openModal={openModal} />
												))}
											</Grid>
										</Section.Body>
									</Section>
								</If>
								<If condition={presentList.length > 0}>
									<Section>
										<Section.Header>
											<Heading level={3} noMargin>
												Present: {presentList.length} / {totalStudents}
											</Heading>
										</Section.Header>
										<Section.Body>
											<Grid responsiveColumns={gridResponsiveColumns} gap="1rem">
												{presentList.map((e) => (
													<StudentAttendanceIcon key={e.id} student={e} openModal={openModal} />
												))}
											</Grid>
										</Section.Body>
									</Section>
								</If>
								<If condition={notRecordedList.length > 0}>
									<Section>
										<Section.Header>
											<Heading level={3} noMargin>
												Not Taken: {notRecordedList.length}
											</Heading>
										</Section.Header>
										<Section.Body>
											<Grid responsiveColumns={gridResponsiveColumns} gap="1rem">
												{notRecordedList.map((e) => (
													<StudentAttendanceIcon key={e.id} student={e} openModal={openModal} />
												))}
											</Grid>
										</Section.Body>
									</Section>
								</If>
							</Else>
						</Conditional>
					</div>
				</Column>

				<Modal.If condition={activeModal[0] === "create"}>
					{<StudentAttendanceCreateModal student={activeModal[1]} onClose={closeModal} />}
				</Modal.If>
				<Modal.If condition={activeModal[0] === "detail"}>
					{<StudentAttendanceDetailModal student={activeModal[1]} onClose={closeModal} />}
				</Modal.If>
			</Modal.Body>
		</Modal>
	);
}

type AttendanceStudentFiltersProps = {
	filterFormState: FormState<StudentFilterFormValues.FormValues>;
};

function AttendanceStudentFilters({ filterFormState }: AttendanceStudentFiltersProps) {
	return (
		<Row justify="spaced-start" align="spaced-start" overflow="wrap">
			<StudentCampusFilterButtons formState={filterFormState} />
			<HR color="#eee" style={{ height: "45px" }} />
			<StudentHomeRoomFilterButtons formState={filterFormState} />
		</Row>
	);
}

type StudentAttendanceStatsProps = {
	numPresent: number;
	numLate: number;
	numAbsent: number;
	numNotRecorded: number;
};

function StudentAttendanceStats({ numPresent, numLate, numAbsent, numNotRecorded }: StudentAttendanceStatsProps) {
	return (
		<Row justify="spaced-center" horizontalSpacing="0.5rem" align="center">
			<Column justify="spaced-start" align="center" verticalSpacing="0.25rem" className={styles["attendance-stat"]}>
				<span className={styles["attendance-stats__label"]}>Present</span>
				<span className={styles["attendance-stats__value"]}>{numPresent}</span>
			</Column>
			<Column justify="spaced-start" align="center" verticalSpacing="0.25rem" className={styles["attendance-stat"]}>
				<span className={styles["attendance-stats__label"]}>Late</span>
				<span className={styles["attendance-stats__value"]}>{numLate}</span>
			</Column>
			<Column justify="spaced-start" align="center" verticalSpacing="0.25rem" className={styles["attendance-stat"]}>
				<span className={styles["attendance-stats__label"]}>Absent</span>
				<span className={styles["attendance-stats__value"]}>{numAbsent}</span>
			</Column>
			<Column justify="spaced-start" align="center" verticalSpacing="0.25rem" className={styles["attendance-stat"]}>
				<span className={styles["attendance-stats__label"]}>Not Taken</span>
				<span className={styles["attendance-stats__value"]}>{numNotRecorded}</span>
			</Column>
			<AttendanceNotesController />
		</Row>
	);
}

function AttendanceNotesController() {
	const { isModalOpen, toggleIsModalOpen } = Modal.useToggle(false);

	const { loading, data } = useNoteStudentNotesSlimListQuery({
		variables: {
			filters: getAttendanceNoteFilters(),
			order: { field: NoteOrderField.CreatedAt, direction: OrderDirection.Desc },
		},
	});
	const numNotes = data?.noteConnection.edges.length ?? 0;

	return (
		<React.Fragment>
			<Button
				onClick={toggleIsModalOpen}
				isLoading={loading}
				disabled={numNotes === 0}
				className={styles["attendance-notes__button"]}
			>
				<Column justify="spaced-start" align="center" verticalSpacing="0.25rem" className={styles["attendance-stat"]}>
					<span className={styles["attendance-stats__label"]}>Attendance {toGrammaticalNumber("Note", numNotes)}</span>
					<span className={styles["attendance-stats__value"]}>{numNotes}</span>
				</Column>
			</Button>

			<Modal.If condition={isModalOpen}>
				<Modal onClose={toggleIsModalOpen}>
					<Modal.Header>
						<Heading.H2 noMargin>Attendance Notes</Heading.H2>
					</Modal.Header>
					<Modal.Body>
						<Column justify="spaced-start">
							{data?.noteConnection.edges.map(({ node: note }) => {
								let dateLabel = "";
								if (note.startDate) {
									dateLabel = `For ${formatDateTime(stringToLocalDate(note.startDate, "day"), "dddd, D MMM YYYY")}`;
								}
								if (note.startDate !== note.endDate && note.endDate !== null) {
									dateLabel += ` to ${formatDateTime(stringToLocalDate(note.endDate, "day"), "dddd, D MMM YYYY")}`;
								}

								return (
									<Section key={note.id} className={styles["attendance-notes__note"]}>
										<Section.Header className={styles["attendance-notes__note__header"]}>
											<strong>Note about {englishCommaJoinStrings(note.students.map((e) => e.person.name))}</strong>
										</Section.Header>
										<Section.Body className={styles["attendance-notes__note__body"]}>
											<Column justify="spaced-start" verticalSpacing="0.25rem">
												<If condition={dateLabel !== ""}>
													<span className={styles["attendance-notes__note__date"]}>{dateLabel}</span>
												</If>

												<TextAreaFieldDisplay value="" formattedValue={note.body} />
											</Column>
										</Section.Body>
										<Section.Footer className={styles["attendance-notes__note__footer"]}>
											<Row justify="space-between">
												<span title={formatDateTime(note.createdAt, "HH:mm [on] D MMMM, YYYY")}>
													{relativeTime(note.createdAt)}
												</span>

												{note.author && note.author.person && (
													<Row justify="spaced-start">
														<PersonIcon person={note.author.person} imageSize="1.25rem" />
														{note.author.person.employee ? (
															<EmployeePreviewTooltip id={note.author.person.employee.id} style={{ display: "inline" }}>
																<EmployeeLink
																	instance={{
																		id: note.author.person.employee.id,
																		person: note.author.person,
																	}}
																/>
															</EmployeePreviewTooltip>
														) : (
															note.author.person.name
														)}
													</Row>
												)}
											</Row>
										</Section.Footer>
									</Section>
								);
							})}
						</Column>
					</Modal.Body>
				</Modal>
			</Modal.If>
		</React.Fragment>
	);
}

// copied from packages/shared/src/Hubs/Student/Detail/AcademicsPage/index.tsx
function getAttendanceNoteFilters() {
	const filters: NoteFilterInput[] = [
		{
			noteTypeEQ: NoteNoteType.Attendance,
		},
	];

	const date = dateTrunc(new Date(), "day");
	const startDateStr = toLocalDateString(date);
	const endDateStr = toLocalDateString(addTimeToDate(date, [1, "day"]));
	const timeFilters: NoteFilterInput[] = [
		{
			startDateGTE: startDateStr,
			startDateLT: endDateStr,
		},
		{
			endDateGTE: startDateStr,
			endDateLT: endDateStr,
		},
		{
			startDateLT: startDateStr,
			endDateGTE: endDateStr,
		},
	];

	return cartesianProduct(filters, timeFilters).map((e) => ({ ...e[0], ...e[1] }));
}

type StudentAttendanceIconProps = {
	student: SchoolAttendanceRecordIndexQueryNode;
	openModal: (student: SchoolAttendanceRecordIndexQueryNode) => void;
};

function StudentAttendanceIcon({ student, openModal }: StudentAttendanceIconProps) {
	const onClick = React.useCallback(() => {
		openModal(student);
	}, [openModal, student]);

	return (
		<React.Fragment>
			<Button onClick={onClick} style={{ width: "100%", padding: 0, border: "none" }}>
				<Row justify="center">
					<PersonIcon
						person={student.person}
						withName
						studentID={student.id}
						withTooltipPreview
						style={{ width: "100%" }}
					/>
				</Row>
			</Button>
		</React.Fragment>
	);
}

type StudentAttendanceCreateModalProps = {
	student: SchoolAttendanceRecordIndexQueryNode;
} & RequiredKeys<Pick<ModalProps, "ifRef" | "onClose">, "onClose">;

function StudentAttendanceCreateModal({ student, ifRef, onClose }: StudentAttendanceCreateModalProps) {
	const initialFormValues = React.useMemo(
		() => ({ checkInTime: new Date().toISOString(), studentID: student.id }),
		[student.id],
	);
	const formState = SchoolAttendanceRecordFormState.useCreateFormState(initialFormValues);

	const create = SchoolAttendanceRecordMutation.useCreate();
	const applyCreate = React.useCallback(
		async (formValues: SchoolAttendanceRecordFormValues.Create) => {
			const { errors } = await create(formValues);
			return errors;
		},
		[create],
	);

	return (
		<Modal
			ifRef={ifRef}
			onClose={onClose}
			confirmOnClose={anyFieldChanged(formState)}
			style={{ width: "fit-content", minWidth: "var(--general__field---width)" }}
		>
			<Modal.Header>
				<Heading level={2} noMargin>
					{student.person.name}
				</Heading>
			</Modal.Header>
			<Modal.Body>
				<SchoolAttendanceRecordForm.Create formState={formState} applyCreate={applyCreate} onSuccess={onClose} />
			</Modal.Body>
		</Modal>
	);
}

type StudentAttendanceDetailModalProps = {
	student: SchoolAttendanceRecordIndexQueryNode;
} & RequiredKeys<Pick<ModalProps, "ifRef" | "onClose">, "onClose">;

function StudentAttendanceDetailModal({ student, ifRef, onClose }: StudentAttendanceDetailModalProps) {
	const schoolAttendanceRecordID = student.schoolAttendanceRecords[0].id;
	const { data } = useSchoolAttendanceRecordDetailQuery({ variables: { id: schoolAttendanceRecordID } });

	const update = SchoolAttendanceRecordMutation.useUpdate(schoolAttendanceRecordID);
	const applyUpdate = React.useCallback(
		async (
			changedFormValues: Partial<SchoolAttendanceRecordFormValues.Detail>,
			initialFormValues: SchoolAttendanceRecordFormValues.Detail,
		) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<Modal ifRef={ifRef} onClose={onClose} style={{ width: "fit-content", minWidth: "var(--general__field---width)" }}>
			<Modal.Header>
				<Heading level={2} noMargin>
					{student.person.name}
				</Heading>
			</Modal.Header>
			<Modal.Body>
				{data ? (
					<SchoolAttendanceRecordForm.ControlledDetail
						schoolAttendanceRecord={data.schoolAttendanceRecord}
						applyUpdate={applyUpdate}
						onSuccess={onClose}
					/>
				) : (
					"Loading..."
				)}
			</Modal.Body>
		</Modal>
	);
}

export function partitionStudentSchoolAttendance(
	edges?: SchoolAttendanceRecordDailyReportListQuery["studentConnection"]["edges"],
) {
	const lists = {
		presentList: [] as SchoolAttendanceRecordIndexQueryNode[],
		absentList: [] as SchoolAttendanceRecordIndexQueryNode[],
		notRecordedList: [] as SchoolAttendanceRecordIndexQueryNode[],
	};
	if (!edges) {
		return lists;
	}

	for (let i = 0; i < edges.length; i++) {
		const node = edges[i].node;
		if (node.schoolAttendanceRecords.length === 0) {
			lists.notRecordedList.push(node);
		} else {
			if (node.schoolAttendanceRecords[0].isPresent) {
				lists.presentList.push(node);
			} else {
				lists.absentList.push(node);
			}
		}
	}

	return lists;
}
