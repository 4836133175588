import React from "react";
import { DistributiveOmit } from "@hex-insights/core";
import { EditMode, FormType, useFormState, UseFormStateArgs } from "@hex-insights/forms";
import { EnrollmentApplicationNoteFormValues } from "../ModelFormValues";

export type UseCreateFormStateArgs = DistributiveOmit<
	UseFormStateArgs<EnrollmentApplicationNoteFormValues.Create>,
	"initialFormValues"
> & {
	initialFormValues?: Partial<EnrollmentApplicationNoteFormValues.Create>;
};

/**
 * Returns a `formState` object for use in the create form for the EnrollmentApplicationNote model.
 */
export function useCreateFormState({
	initialFormValues,
	formType = FormType.Create,
	...args
}: UseCreateFormStateArgs = {}) {
	return useFormState({
		initialFormValues: React.useMemo(
			() => ({ ...EnrollmentApplicationNoteFormValues.initialCreate, ...initialFormValues }),
			[initialFormValues],
		),
		formType,
		...args,
	});
}

const detailFieldEditModes = {
	createdAt: EditMode.ReadOnly,
	authorID: EditMode.ReadOnly,
};

export type UseDetailFormStateArgs = UseFormStateArgs<EnrollmentApplicationNoteFormValues.Detail>;

/**
 * Returns a `formState` object for use in the detail form of the EnrollmentApplicationNote model.
 *
 * @param initialFormValues The initial values for the form. Generally from
 * {@link EnrollmentApplicationNoteFormConversion.toFormValues}.
 */
export function useDetailFormState({
	initialFormValues,
	formType = FormType.Update,
	fieldEditModes = detailFieldEditModes,
	...args
}: UseDetailFormStateArgs) {
	return useFormState({ initialFormValues, formType, fieldEditModes, ...args });
}

export type UseReadOnlyFormStateArgs = UseFormStateArgs<EnrollmentApplicationNoteFormValues.Detail>;

/**
 * Returns a read-only `formState` object for use in the detail form of the EnrollmentApplicationNote model.
 *
 * @param initialFormValues The initial values for the form. Generally from
 * {@link EnrollmentApplicationNoteFormConversion.toFormValues}.
 */
export function useReadOnlyFormState({
	initialFormValues,
	formType = FormType.View,
	...args
}: UseReadOnlyFormStateArgs) {
	return useFormState({ initialFormValues, formType, ...args });
}
