import React from "react";
import { ApolloCache, FetchResult, gql } from "@apollo/client";
import {
	Bus,
	BusPlan,
	BusPlanCreateInput,
	BusPlanCreateMutation,
	BusPlanDeleteMutation,
	BusPlanDetailDocument,
	BusPlanDetailQuery,
	BusPlanDetailQueryVariables,
	BusPlanUpdateInput,
	BusPlanUpdateMutation,
	graphTypeNames,
	Student,
	useBusPlanCreateMutation,
	useBusPlanDeleteMutation,
	useBusPlanUpdateMutation,
} from "../../GraphQL";
import { BusPlanFormConversion } from "../ModelFormConversion";
import { BusPlanFormValues } from "../ModelFormValues";

/**
 * Returns a `create` function for the BusPlan model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the BusPlan model.
 */
export function useCreate() {
	const [createBusPlan] = useBusPlanCreateMutation();

	return React.useCallback(
		async (formValues: BusPlanFormValues.Create) => {
			const input = BusPlanFormConversion.toGQLCreateInput(formValues);
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createBusPlan({ variables: { input }, update: updateCache });

			return { data: data?.createBusPlan ?? null, errors: errors ?? null };
		},
		[createBusPlan],
	);
}

/**
 * Returns an `update` function for the BusPlan model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the BusPlan model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: BusPlan["id"]) {
	const [updateBusPlan] = useBusPlanUpdateMutation();

	return React.useCallback(
		async (changedFormValues: Partial<BusPlanFormValues.Detail>, initialFormValues: BusPlanFormValues.Detail) => {
			const input = BusPlanFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

			const { data, errors } = await updateBusPlan({ variables: { id, input }, update: updateCache });

			return { data: data?.updateBusPlan ?? null, errors: errors ?? null };
		},
		[updateBusPlan, id],
	);
}

/**
 * Returns a `del` function for the BusPlan model.
 *
 * @param id The ID of the instance to delete.
 */
export function useDelete(id: BusPlan["id"]) {
	const [deleteBusPlan] = useBusPlanDeleteMutation();

	return React.useCallback(async () => {
		const updateCache = getUpdateCacheForDelete(id);

		const { data, errors } = await deleteBusPlan({ variables: { id }, update: updateCache });

		return { data: data?.deleteBusPlan ?? false, errors: errors ?? null };
	}, [deleteBusPlan, id]);
}

function getUpdateCacheForCreate(input: BusPlanCreateInput) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<BusPlanCreateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const createdObject = result.data.createBusPlan;

		cache.writeQuery<BusPlanDetailQuery, BusPlanDetailQueryVariables>({
			query: BusPlanDetailDocument,
			data: { busPlan: createdObject },
			variables: { id: createdObject.id },
		});

		if (input.dropoffBusID) {
			addToDropoffBusPlansOfBusCache(cache, input.dropoffBusID, createdObject);
		}

		if (input.pickupBusID) {
			addToPickupBusPlansOfBusCache(cache, input.pickupBusID, createdObject);
		}

		if (input.studentID) {
			addToBusPlansOfStudentCache(cache, input.studentID, createdObject);
		}

		cache.evict({ id: "ROOT_QUERY", fieldName: "busPlanConnection" });
	};
}

function getUpdateCacheForUpdate(input: BusPlanUpdateInput, initialFormValues: BusPlanFormValues.Detail) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<BusPlanUpdateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const updatedObject = result.data.updateBusPlan;

		if (initialFormValues.dropoffBusID && (input.dropoffBusID || input.clearDropoffBus)) {
			removeFromDropoffBusPlansOfBusCache(cache, initialFormValues.dropoffBusID, updatedObject);
		}
		if (input.dropoffBusID) {
			addToDropoffBusPlansOfBusCache(cache, input.dropoffBusID, updatedObject);
		}

		if (initialFormValues.pickupBusID && (input.pickupBusID || input.clearPickupBus)) {
			removeFromPickupBusPlansOfBusCache(cache, initialFormValues.pickupBusID, updatedObject);
		}
		if (input.pickupBusID) {
			addToPickupBusPlansOfBusCache(cache, input.pickupBusID, updatedObject);
		}

		if (initialFormValues.studentID && input.studentID) {
			removeFromBusPlansOfStudentCache(cache, initialFormValues.studentID, updatedObject);
		}
		if (input.studentID) {
			addToBusPlansOfStudentCache(cache, input.studentID, updatedObject);
		}
	};
}

function getUpdateCacheForDelete(id: BusPlan["id"]) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<BusPlanDeleteMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (!result.data?.deleteBusPlan) {
			return;
		}

		cache.evict({ id: cache.identify({ id, __typename: graphTypeNames.BusPlan }) });
		cache.evict({ id: "ROOT_QUERY", fieldName: "busPlanConnection" });
		cache.gc();
	};
}

function addToDropoffBusPlansOfBusCache(cache: ApolloCache<any>, targetID: Bus["id"], object: Pick<BusPlan, "id">) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Bus }),
		fields: {
			dropoffBusPlans: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromDropoffBusPlansOfBusCache(
	cache: ApolloCache<any>,
	targetID: Bus["id"],
	object: Pick<BusPlan, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Bus }),
		fields: {
			dropoffBusPlans: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function addToPickupBusPlansOfBusCache(cache: ApolloCache<any>, targetID: Bus["id"], object: Pick<BusPlan, "id">) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Bus }),
		fields: {
			pickupBusPlans: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromPickupBusPlansOfBusCache(cache: ApolloCache<any>, targetID: Bus["id"], object: Pick<BusPlan, "id">) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Bus }),
		fields: {
			pickupBusPlans: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function addToBusPlansOfStudentCache(cache: ApolloCache<any>, targetID: Student["id"], object: Pick<BusPlan, "id">) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Student }),
		fields: {
			busPlans: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromBusPlansOfStudentCache(
	cache: ApolloCache<any>,
	targetID: Student["id"],
	object: Pick<BusPlan, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Student }),
		fields: {
			busPlans: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function toCacheRef(cache: ApolloCache<any>, object: Pick<BusPlan, "id">) {
	return cache.writeFragment({
		fragment: gql`
			fragment BusPlanRef on BusPlan {
				id
			}
		`,
		data: object,
	});
}
