import { Contract, ContractInstallment, ContractStatus, ServiceSubscription, Student } from "../../GraphQL";
import * as ContractInstallmentFormValues from "./contract-installment";
import * as ServiceSubscriptionFormValues from "./service-subscription";

export enum PaymentScheduleType {
	FourPayments,
	TwoPayments,
	OnePayment,
	Custom,
}

export type Base = {
	studentID: Student["id"] | null;
	agreementDate: Contract["agreementDate"] | null;
	status: Contract["status"] | null;
};

export type Create = Base & {
	paymentScheduleType: PaymentScheduleType | null;
	contractInstallments: ContractInstallmentFormValues.CreateInContract[];
	serviceSubscriptions: ServiceSubscriptionFormValues.CreateInContract[];
};

export type Detail = Base & {
	contractInstallmentIDs: ContractInstallment["id"][];
	serviceSubscriptionIDs: ServiceSubscription["id"][];
};

export const initialCreate: Create = {
	agreementDate: null,
	status: ContractStatus.Draft,
	paymentScheduleType: PaymentScheduleType.FourPayments,
	contractInstallments: [
		ContractInstallmentFormValues.initialCreateInContract,
		ContractInstallmentFormValues.initialCreateInContract,
		ContractInstallmentFormValues.initialCreateInContract,
		ContractInstallmentFormValues.initialCreateInContract,
	],
	serviceSubscriptions: [ServiceSubscriptionFormValues.initialCreateInContract],
	studentID: null,
};

export const statusOptions = [
	{
		value: ContractStatus.Draft,
		label: "Draft",
		hint: "Draft contracts can be edited and do not show up in billing for a student until marked as Active.",
	},
	{
		value: ContractStatus.Active,
		label: "Active",
		hint: "Marking a contract as Active will generate (but not send) all invoices for the contract.",
	},
	{
		value: ContractStatus.Complete,
		label: "Complete",
		hint: "Marking a contract as Complete will cause the contract to be displayed less prominently (treated as archived).",
	},
	{
		value: ContractStatus.Void,
		label: "Void",
		hint: "Marking a contract as Void will archive the contract and cancel all future invoices.",
	},
];

export const statusOptionsAfterDraft = statusOptions.map((e) => ({ ...e, disabled: e.value === ContractStatus.Draft }));

export const paymentScheduleTypeOptions = [
	{ value: PaymentScheduleType.FourPayments, label: "Four Payments" },
	{ value: PaymentScheduleType.TwoPayments, label: "Two Payments" },
	{ value: PaymentScheduleType.OnePayment, label: "Single Payment" },
	{ value: PaymentScheduleType.Custom, label: "Custom" },
];

export function isContractEditable(status?: ContractStatus) {
	return status === ContractStatus.Draft;
}
