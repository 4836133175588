import React from "react";
import { FieldDisplayArgs, MultiSelectField, TextField } from "@hex-insights/forms";
import { AddressFormValues, PersonSelect, usePersonSelectLazyQuery } from "../../../../Utilities";
import { PersonLink } from "../../../Links";
import { BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the Address model.
 */
type FieldProps<K extends keyof AddressFormValues.Base = keyof AddressFormValues.Base> = BaseFieldProps<
	Pick<AddressFormValues.Base, K>
>;

/**
 * Generic props for fields of the Address model that only appear in the detail form.
 */
type DetailFieldProps<K extends keyof AddressFormValues.Detail = keyof AddressFormValues.Detail> = BaseFieldProps<
	Pick<AddressFormValues.Detail, K>
>;

/**
 * Renders a field component for the `shortAddress` field of the Address model.
 */
export function ShortAddress({ formState }: DetailFieldProps<"shortAddress">) {
	return <TextField formState={formState} name="shortAddress" />;
}

/**
 * Renders a field component for the `fullAddress` field of the Address model.
 */
export function FullAddress({ formState }: DetailFieldProps<"fullAddress">) {
	return <TextField formState={formState} name="fullAddress" />;
}

/**
 * Renders a field component for the `addressLine1` field of the Address model.
 */
export function AddressLine1({ formState }: FieldProps<"addressLine1">) {
	return <TextField formState={formState} name="addressLine1" />;
}

/**
 * Renders a field component for the `addressLine2` field of the Address model.
 */
export function AddressLine2({ formState }: FieldProps<"addressLine2">) {
	return <TextField formState={formState} name="addressLine2" optional />;
}

/**
 * Renders a field component for the `city` field of the Address model.
 */
export function City({ formState }: FieldProps<"city">) {
	return <TextField formState={formState} name="city" />;
}

/**
 * Renders a field component for the `postalCode` field of the Address model.
 */
export function PostalCode({ formState }: FieldProps<"postalCode">) {
	return <TextField formState={formState} name="postalCode" />;
}

/**
 * Renders a field component for the `stateOrProvince` field of the Address model.
 */
export function StateOrProvince({ formState }: FieldProps<"stateOrProvince">) {
	return <TextField formState={formState} name="stateOrProvince" optional />;
}

/**
 * Renders a field component for the `country` field of the Address model.
 */
export function Country({ formState }: FieldProps<"country">) {
	return <TextField formState={formState} name="country" />;
}

export type PeopleProps = DetailFieldProps<"personIDs"> & {
	currentPeople?: PersonSelect.ModelForOption[];
};

/**
 * Renders a field component for the `people` edge of the Address model.
 */
export function People({ formState, currentPeople }: PeopleProps) {
	const [loadOptions, { loading, data }] = usePersonSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.personIDs) {
			loadOptions();
		}
	}, [formState.formEditing.personIDs, loadOptions]);
	const options = React.useMemo(
		() => PersonSelect.toMultiOptions(data?.personConnection.edges, currentPeople),
		[data, currentPeople],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="personIDs"
			isLoading={loading}
			options={options}
			displayInstance={displayPersonInstance}
		/>
	);
}

function displayPersonInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <PersonLink instance={{ id }}>{formattedValue}</PersonLink>;
}
