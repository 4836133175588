import React from "react";
import {
	Button,
	ClassNameProps,
	Column,
	Conditional,
	Else,
	forceUseHookDependencies,
	Icon,
	If,
	makeClassName,
	StyleProps,
	useSync,
	useUpdateEffect,
} from "@hex-insights/core";
import { DocumentResource } from "@hex-insights/verita.shared";
import styles from "./styles.module.css";

// TODO copied from Admin Portal and Components (with onClick handling added)

export type DocumentResourceDisplayProps = {
	documentResource: Pick<
		DocumentResource,
		"id" | "name" | "description" | "imageURL" | "isFile" | "fileURL" | "fileContentType" | "resourceURL"
	>;
	onClick: () => void;
} & Partial<ClassNameProps & StyleProps>;

export function DocumentResourceDisplay({ documentResource, onClick }: DocumentResourceDisplayProps) {
	const { name, description } = documentResource;

	const documentType = getDocumentType(documentResource);

	return (
		<React.Fragment>
			<Button
				onClick={onClick}
				className={makeClassName(
					styles["document-resource-display"],
					styles[`document-resource-display--${documentType}`],
				)}
			>
				<Column
					justify="spaced-start"
					verticalSpacing="0.25rem"
					align="center"
					className={styles["document-resource-display__column"]}
				>
					<ImageContainer src={getImageURL(documentResource)} documentType={documentType} />
					<span
						title={name + (description !== "" ? "\n\n" + description : "")}
						className={styles["document-resource-display__name"]}
					>
						{name}
					</span>
				</Column>
			</Button>
		</React.Fragment>
	);
}

function getImageURL(documentResource: Pick<DocumentResource, "imageURL" | "fileURL" | "fileContentType">) {
	if (documentResource.imageURL !== "") {
		return documentResource.imageURL;
	}
	if (documentResource.fileContentType.startsWith("image")) {
		return documentResource.fileURL;
	}
	return "";
}

type DocumentType = "resource" | "image" | "file";

function getDocumentType(documentResource: Pick<DocumentResource, "isFile" | "fileContentType">): DocumentType {
	if (!documentResource.isFile) {
		return "resource";
	}
	if (documentResource.fileContentType.startsWith("image")) {
		return "image";
	}
	return "file";
}

type ImageContainerProps = {
	src: string;
	documentType: DocumentType;
};

function ImageContainer({ src, documentType }: ImageContainerProps) {
	const ref = React.useRef<HTMLImageElement | null>(null);

	const { syncID, synchronize } = useSync();

	const imageShape = React.useMemo(() => {
		forceUseHookDependencies(syncID);
		if (!ref.current) {
			return "wide";
		}
		const { width, height } = ref.current.getBoundingClientRect();
		return width >= height ? "wide" : "tall";
	}, [syncID]);

	const [hasImageError, setHasImageError] = React.useState(false);
	useUpdateEffect(() => {
		setHasImageError(false);
	}, [src]);

	const hasImage = src !== "" && !hasImageError;

	return (
		<div className={styles["image-container"] + " " + styles[`image-container--${imageShape}`]}>
			<Conditional>
				<If condition={hasImage}>
					<img
						ref={ref}
						src={src}
						onLoad={synchronize}
						onError={() => setHasImageError(true)}
						style={{ borderRadius: "0.3rem" }}
					/>
				</If>
				<Else>
					<Column justify="center" align="center" className={styles["image-placeholder"]}>
						<Conditional>
							<If condition={documentType === "resource"}>
								<Icon.Link size="3rem" />
							</If>
							<If condition={documentType === "image"}>
								<Icon.Image size="3rem" />
							</If>
							<If condition={documentType === "file"}>
								<Icon.FileText size="3rem" />
							</If>
						</Conditional>
					</Column>
				</Else>
			</Conditional>
		</div>
	);
}
