import { IndexForms } from "@hex-insights/app-modules";
import { TypeFilterObject } from "@hex-insights/core";

export type StandardFilterFormValues = {}; // TODO define quick filters

export type FormValues = StandardFilterFormValues & IndexForms.SearchFormValues & IndexForms.FilterFormValues;

export const initialStandardFilters: StandardFilterFormValues = {};

export const initial: FormValues = {
	...initialStandardFilters,
	...IndexForms.initialSearchFormValues,
	...IndexForms.initialFilterFormValues,
};

export function getInitial(): FormValues {
	return initial;
}

export const quickFiltersTypeFilterObject: TypeFilterObject<StandardFilterFormValues> = {};

export const typeFilterObject: TypeFilterObject<FormValues> = {
	...quickFiltersTypeFilterObject,
	...IndexForms.searchFormValuesFilterObject,
	...IndexForms.filterFormValuesFilterObject,
};

export const advancedFilterInformation: IndexForms.AdvancedFilterInformation = {
	fieldOptions: [
		{ value: "courseDate" },
		{ value: "lessonDescription" },
		{ value: "requiredResourcesAndMaterials" },
		{ value: "stationOneDescription" },
		{ value: "stationTwoDescription" },
		{ value: "stationThreeDescription" },
		{ value: "differentiationDescription" },
		{ value: "assessmentDescription" },
		{ value: "notes" },
	],
	fieldComparisonOptions: {
		courseDate: IndexForms.dateComparisonOptions,
		lessonDescription: IndexForms.stringComparisonOptions,
		requiredResourcesAndMaterials: IndexForms.stringComparisonOptions,
		stationOneDescription: IndexForms.stringComparisonOptions,
		stationTwoDescription: IndexForms.stringComparisonOptions,
		stationThreeDescription: IndexForms.stringComparisonOptions,
		differentiationDescription: IndexForms.stringComparisonOptions,
		assessmentDescription: IndexForms.stringComparisonOptions,
		notes: IndexForms.stringComparisonOptions,
	},
	fieldComparisonTranslations: {
		courseDate: IndexForms.dateComparisonTranslations,
		lessonDescription: IndexForms.stringComparisonTranslations,
		requiredResourcesAndMaterials: IndexForms.stringComparisonTranslations,
		stationOneDescription: IndexForms.stringComparisonTranslations,
		stationTwoDescription: IndexForms.stringComparisonTranslations,
		stationThreeDescription: IndexForms.stringComparisonTranslations,
		differentiationDescription: IndexForms.stringComparisonTranslations,
		assessmentDescription: IndexForms.stringComparisonTranslations,
		notes: IndexForms.stringComparisonTranslations,
	},
};
