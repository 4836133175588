import React from "react";
import { NetworkStatus } from "@apollo/client";
import {
	addTimeToDate,
	Button,
	CaseStyle,
	ChildrenProps,
	ClassNameProps,
	Column,
	Conditional,
	convertCase,
	dateDiff,
	DateTime,
	dateTrunc,
	Else,
	formatDateTime,
	Heading,
	HeadingProps,
	Icon,
	If,
	isSameTime,
	jsxEnglishCommaJoin,
	makeClassName,
	Modal,
	relativeTime,
	Row,
	stringToLocalDate,
	StyleProps,
	toGrammaticalNumber,
	toLocalDateString,
	useSync,
	useTimeout,
	useUpdateEffect,
} from "@hex-insights/core";
import { StaticURLs } from "../../StaticURLs";
import {
	formatPrice,
	NoteFormat,
	NoteNoteType,
	PaymentFormat,
	TimelineItem,
	TimelineObjectType,
	TimelineQuery,
	useInvoiceDetailLazyQuery,
	usePaymentDetailLazyQuery,
} from "../../Utilities";
import { ContractModal } from "../Financials";
import { InvoiceDisplay } from "../InvoiceDisplay";
import {
	ContractLink,
	EmployeeLink,
	InvoiceLink,
	ParentLink,
	PaymentLink,
	ReportCardLink,
	StudentLink,
	TermLink,
} from "../Links";
import { NoteModal, reportNoteTypes } from "../NoteModal";
import { PaymentDisplay } from "../PaymentDisplay";
import { EmployeePreviewTooltip, PersonIcon, StudentPreviewTooltip } from "../PersonIcon";
import { StudentDocumentModal } from "../StudentDocumentModal";
import "./styles.css";
import styles from "./styles.module.css";

export * from "./utilities";

export type TimelineProps = {
	data?: TimelineQuery["timeline"];
} & TimelineRefreshButtonProps &
	Partial<ClassNameProps & StyleProps>;

export function Timeline({ data, networkStatus, refetch, className, style }: TimelineProps) {
	const today = toLocalDateString(new Date());
	let hasTodaySeparator = false;
	let hasThisWeekSeparator = false;

	const itemGroups = [];
	let currentGroup = [];
	if (data) {
		for (let i = 0; i < data.length; i++) {
			const item = data[i];

			const headingProps: ({ key: any } & Omit<HeadingProps, "className">)[] = [];
			const prevEventDate = i > 0 ? data[i - 1].eventDate : today;
			if (!hasTodaySeparator && isSameTime(item.eventDate, today, "day")) {
				headingProps.push({
					key: "day",
					level: 4,
					children: "Today",
				});
				hasTodaySeparator = true;
			} else {
				if (!isSameTime(item.eventDate, prevEventDate, "year")) {
					headingProps.push(
						{
							key: "year",
							level: 2,
							children: formatDateTime(stringToLocalDate(item.eventDate, "day"), "YYYY"),
						},
						{
							key: "month",
							level: 3,
							children: formatDateTime(stringToLocalDate(item.eventDate, "day"), "MMMM"),
						},
					);
				} else if (!isSameTime(item.eventDate, prevEventDate, "month")) {
					headingProps.push({
						key: "month",
						level: 3,
						children: formatDateTime(stringToLocalDate(item.eventDate, "day"), "MMMM"),
					});
				}
				if (
					!isSameTime(item.eventDate, prevEventDate, "week") &&
					(weekDiff(today, item.eventDate) <= 3 || isSameTime(item.eventDate, today, "month"))
				) {
					headingProps.push({
						key: "week",
						level: 4,
						children: getWeekSeparatorText(today, item.eventDate),
					});
				} else if (dateDiff(today, item.eventDate, "day") === 1 && !isSameTime(item.eventDate, prevEventDate, "day")) {
					headingProps.push({
						key: "day",
						level: 4,
						children: "Yesterday",
					});
				} else if (
					!hasThisWeekSeparator &&
					isSameTime(item.eventDate, today, "week") &&
					dateDiff(today, item.eventDate, "day") > 1
				) {
					headingProps.push({
						key: "day",
						level: 4,
						children: "This Week",
					});
					hasThisWeekSeparator = true;
				}
			}

			if (headingProps.length > 0 && currentGroup.length > 0) {
				itemGroups.push(currentGroup);
				currentGroup = [];
			}

			const headings = headingProps.map((e, i) => (
				<Heading
					{...e}
					className={makeClassName(
						styles["timeline__time-separator"],
						i === headingProps.length - 1 ? styles["timeline__time-separator--sticky"] : "",
					)}
				/>
			));
			currentGroup.push(...headings);
			currentGroup.push(<Timeline.Item key={timelineItemKey(item)} item={item} />);
		}
	}
	if (currentGroup.length > 0) {
		itemGroups.push(currentGroup);
	}

	return (
		<Column className={makeClassName(styles["timeline"], className)} style={style}>
			<Timeline.MenuBar>
				<Timeline.RefreshButton networkStatus={networkStatus} refetch={refetch} />
			</Timeline.MenuBar>

			<Column className={styles["timeline__content"]}>
				{itemGroups.map((e, i) => (
					<div key={i} className={styles["timeline__group"]}>
						{e}
					</div>
				))}
			</Column>
		</Column>
	);
}

function getWeekSeparatorText(today: string, eventDate: string) {
	const numWeeks = weekDiff(today, eventDate);

	if (numWeeks === 1) {
		return "Last Week";
	}

	return `${toGrammaticalNumber("Week", numWeeks, true)} Ago`;
}

function weekDiff(end: DateTime, start: DateTime) {
	return dateDiff(dateTrunc(end, "week"), dateTrunc(start, "week"), "week");
}

export type TimelineMenuBar = Partial<ClassNameProps & StyleProps & ChildrenProps>;

Timeline.MenuBar = function ({ className, style, children }: TimelineMenuBar) {
	return (
		<Row justify="spaced-end" className={makeClassName(styles["menu-bar"], className)} style={style}>
			{children}
		</Row>
	);
};

export type TimelineRefreshButtonProps = {
	networkStatus: NetworkStatus;
	refetch: () => void;
};

Timeline.RefreshButton = function ({ networkStatus, refetch }: TimelineRefreshButtonProps) {
	const isFetching = networkStatus === NetworkStatus.loading || networkStatus === NetworkStatus.refetch;

	const [lastUpdatedAt, setLastUpdatedAt] = React.useState(() => new Date());

	const { syncID, synchronize } = useSync();
	const { setTimeout } = useTimeout();
	React.useEffect(() => {
		const timeSinceLastUpdate = new Date().getTime() - lastUpdatedAt.getTime();
		let timeoutMS = 0;
		if (timeSinceLastUpdate < 60_000) {
			timeoutMS = 10_000;
		} else if (timeSinceLastUpdate < 3_600_000) {
			timeoutMS = 60_000;
		} else {
			timeoutMS = 3_600_000;
		}
		setTimeout(() => synchronize(), timeoutMS);
	}, [syncID, lastUpdatedAt, setTimeout, synchronize]);

	useUpdateEffect(() => {
		if (networkStatus === NetworkStatus.ready) {
			setLastUpdatedAt(new Date());
		}
	}, [networkStatus]);

	const onClick = React.useCallback(() => {
		refetch();
	}, [refetch]);

	const text = networkStatus === NetworkStatus.loading ? "Loading" : "Refresh";

	return (
		<Button
			variant="tertiary"
			size="small"
			onClick={onClick}
			disabled={isFetching}
			title={`Last updated ${relativeTime(lastUpdatedAt)}`}
			className={isFetching ? styles["refresh-button--fetching"] : ""}
		>
			<Row justify="spaced-start" horizontalSpacing="0.25rem" align="center">
				<Icon.RefreshCw size="0.8rem" className={styles["refresh-button__icon"]} /> {text}
			</Row>
		</Button>
	);
};

const loadMoreLoadingNetworkStatuses = new Set([NetworkStatus.fetchMore, NetworkStatus.loading, NetworkStatus.refetch]);

export type TimelineLoadMoreButtonProps = {
	hasMore: boolean;
	networkStatus: NetworkStatus;
	onClick: () => void;
} & Partial<ClassNameProps & StyleProps>;

Timeline.LoadMoreButton = function ({ hasMore, networkStatus, onClick }: TimelineLoadMoreButtonProps) {
	if (!hasMore || networkStatus === NetworkStatus.loading) {
		return null;
	}

	return (
		<Row justify="center">
			<Button variant="link" onClick={onClick} isLoading={loadMoreLoadingNetworkStatuses.has(networkStatus)}>
				Load More
			</Button>
		</Row>
	);
};

export type TimelineItemProps = {
	item: TimelineItem;
} & Partial<ClassNameProps & StyleProps>;

Timeline.Item = function (props: TimelineItemProps) {
	switch (props.item.objectType) {
		case TimelineObjectType.Contract:
			return <ContractTimelineItem {...props} />;
		case TimelineObjectType.ReportCard:
			return <ReportCardTimelineItem {...props} />;
		case TimelineObjectType.Term:
			return <TermTimelineItem {...props} />;
		case TimelineObjectType.Invoice:
			return <InvoiceTimelineItem {...props} />;
		case TimelineObjectType.Payment:
			return <PaymentTimelineItem {...props} />;
		case TimelineObjectType.Note:
			return <NoteTimelineItem {...props} />;
		case TimelineObjectType.StudentDocument:
			return <StudentDocumentTimelineItem {...props} />;
		default:
			return null;
	}
};

const timelineItemIconSize = "1.5rem";

function ContractTimelineItem({ item, className, style }: TimelineItemProps) {
	const { isModalOpen, toggleIsModalOpen } = Modal.useToggle(false);

	return (
		<React.Fragment>
			<TimelineItemLayout
				icon={
					<PersonIcon
						person={{ name: item.actorName, imageURL: item.actorImageURL }}
						imageSize={timelineItemIconSize}
					/>
				}
				title={
					<span>
						<StudentLink instance={{ id: item.actorID }}>{item.actorName}</StudentLink> signed{" "}
						<ContractLink instance={{ id: item.objectID }}>an agreement</ContractLink>
					</span>
				}
				body={`Agreement dated ${formatDateTime(item.eventDate, "D MMMM YYYY")}.`}
				date={item.eventDate}
				onViewClick={toggleIsModalOpen}
				className={className}
				style={style}
			/>

			<Modal.If condition={isModalOpen}>
				<ContractModal contractID={item.objectID} studentID={item.actorID} onClose={toggleIsModalOpen} />
			</Modal.If>
		</React.Fragment>
	);
}

function ReportCardTimelineItem({ item, className, style }: TimelineItemProps) {
	const { isModalOpen, toggleIsModalOpen } = Modal.useToggle(false);

	return (
		<React.Fragment>
			<TimelineItemLayout
				title={
					<span>
						Verita published <ReportCardLink instance={{ id: item.objectID }}>a report card</ReportCardLink>
					</span>
				}
				date={item.eventDate}
				time={item.eventTime}
				// onViewClick={toggleIsModalOpen}
				className={className}
				style={style}
			/>

			<Modal.If condition={isModalOpen}>
				<Modal onClose={toggleIsModalOpen}>
					<Modal.Body>REPORT CARD PREVIEW</Modal.Body>
				</Modal>
			</Modal.If>
		</React.Fragment>
	);
}

function TermTimelineItem({ item, className, style }: TimelineItemProps) {
	return (
		<TimelineItemLayout
			title={
				<span>
					<StudentLink instance={{ id: item.actorID }}>{item.actorName}</StudentLink> started the{" "}
					<TermLink instance={{ id: item.objectID, name: item.objectName }} /> term
				</span>
			}
			date={item.eventDate}
			time={item.eventTime}
			className={className}
			style={style}
		/>
	);
}

function InvoiceTimelineItem({ item, className, style }: TimelineItemProps) {
	const { isModalOpen, toggleIsModalOpen } = Modal.useToggle(false);

	const [load, { loading, data }] = useInvoiceDetailLazyQuery({ variables: { id: item.objectID } });
	React.useEffect(() => {
		if (isModalOpen) {
			load();
		}
	}, [isModalOpen, load]);

	return (
		<React.Fragment>
			<TimelineItemLayout
				title={
					<span>
						Verita sent an <InvoiceLink instance={{ id: item.objectID }}>invoice</InvoiceLink> for{" "}
						<StudentPreviewTooltip id={item.objectDetails?.studentID} style={{ display: "inline" }}>
							<StudentLink instance={{ id: item.objectDetails?.studentID }}>
								{item.objectDetails?.studentName}
							</StudentLink>
						</StudentPreviewTooltip>
					</span>
				}
				date={item.eventDate}
				time={item.eventTime}
				body={`Verita sent an invoice for ${formatPrice(item.objectDetails?.totalAmount)}.`}
				onViewClick={toggleIsModalOpen}
				className={className}
				style={style}
			/>

			<Modal.If condition={isModalOpen}>
				<Modal onClose={toggleIsModalOpen}>
					<Modal.Header>
						<Heading level={2} noMargin>
							{loading ? "Loading..." : `Invoice ${data?.invoice.invoiceNumber}`}
						</Heading>
					</Modal.Header>
					<Modal.Body>
						<Conditional>
							<If condition={loading}>Loading...</If>
							<Else>{data && <InvoiceDisplay invoice={data.invoice} />}</Else>
						</Conditional>
					</Modal.Body>
				</Modal>
			</Modal.If>
		</React.Fragment>
	);
}

function PaymentTimelineItem({ item, className, style }: TimelineItemProps) {
	const { isModalOpen, toggleIsModalOpen } = Modal.useToggle(false);

	const [load, { loading, data }] = usePaymentDetailLazyQuery({ variables: { id: item.objectID } });
	React.useEffect(() => {
		if (isModalOpen) {
			load();
		}
	}, [isModalOpen, load]);

	return (
		<React.Fragment>
			<TimelineItemLayout
				icon={<PersonIcon person={{ name: item.actorName, imageURL: "" }} imageSize={timelineItemIconSize} />}
				title={
					<span>
						<ParentLink instance={{ id: item.actorID }}>{item.actorName}</ParentLink> submitted a{" "}
						<PaymentLink instance={{ id: item.objectID }}>payment</PaymentLink>
					</span>
				}
				date={item.eventDate}
				time={item.eventTime}
				body={`Payment submitted for ${formatPrice(item.objectDetails?.totalAmount)}.`}
				onViewClick={toggleIsModalOpen}
				className={className}
				style={style}
			/>

			<Modal.If condition={isModalOpen}>
				<Modal onClose={toggleIsModalOpen}>
					<Modal.Header>
						<Heading level={2} noMargin>
							{loading
								? "Loading..."
								: `Payment on ${PaymentFormat.Fields.createdDate(data?.payment.createdDate ?? null)}`}
						</Heading>
					</Modal.Header>
					<Modal.Body>
						<Conditional>
							<If condition={loading}>Loading...</If>
							<Else>{data && <PaymentDisplay payment={data.payment} />}</Else>
						</Conditional>
					</Modal.Body>
				</Modal>
			</Modal.If>
		</React.Fragment>
	);
}

const noteTypeArticle = {
	[NoteNoteType.Academic]: "an",
	[NoteNoteType.Announcement]: "an",
	[NoteNoteType.Attendance]: "an",
	[NoteNoteType.Behavior]: "a",
	[NoteNoteType.Financial]: "a",
	[NoteNoteType.General]: "a",
	[NoteNoteType.Health]: "a",
	[NoteNoteType.Incident]: "an",
	[NoteNoteType.Injury]: "an",
	[NoteNoteType.Post]: "a",
	[NoteNoteType.SocialEmotional]: "a",
	"": "a",
};

function NoteTimelineItem({ item, className, style }: TimelineItemProps) {
	const { isModalOpen, toggleIsModalOpen } = Modal.useToggle(false);

	const noteType = (item.objectDetails?.noteType ?? null) as NoteNoteType | null;

	return (
		<React.Fragment>
			<TimelineItemLayout
				icon={
					<PersonIcon
						person={{ name: item.actorName, imageURL: item.actorImageURL }}
						imageSize={timelineItemIconSize}
					/>
				}
				title={
					<span>
						<EmployeePreviewTooltip id={item.actorID} style={{ display: "inline" }}>
							<EmployeeLink instance={{ id: item.actorID }}>{item.actorName || "[Unknown]"}</EmployeeLink>
						</EmployeePreviewTooltip>{" "}
						wrote {noteTypeArticle[noteType ?? ""]} {convertCase(NoteFormat.Fields.noteType(noteType), CaseStyle.Plain)}{" "}
						{reportNoteTypes.has(noteType) ? "report" : "note"} about{" "}
						{jsxEnglishCommaJoin(
							item.objectDetails?.students.map((e: any) => (
								<StudentPreviewTooltip key={e.id} id={e.id} style={{ display: "inline" }}>
									<StudentLink instance={e} />
								</StudentPreviewTooltip>
							)),
						)}
					</span>
				}
				body={
					<span style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", width: "100%" }}>
						{item.objectDetails?.body}
					</span>
				}
				date={item.eventDate}
				time={item.eventTime}
				onViewClick={toggleIsModalOpen}
				className={className}
				style={style}
			/>

			<Modal.If condition={isModalOpen}>
				<NoteModal noteID={item.objectID} noteType={noteType} onClose={toggleIsModalOpen} />
			</Modal.If>
		</React.Fragment>
	);
}

function StudentDocumentTimelineItem({ item, className, style }: TimelineItemProps) {
	const { isModalOpen, toggleIsModalOpen } = Modal.useToggle(false);

	const description = item.objectDetails?.description ?? "";
	const isImage = item.objectDetails?.fileContentType.startsWith("image") ?? false;
	const fileURL = item.objectDetails?.fileURL ?? "";
	const fileContentType = item.objectDetails?.fileContentType ?? "";
	const student = item.objectDetails?.student;

	return (
		<React.Fragment>
			<TimelineItemLayout
				icon={
					<PersonIcon
						person={{ name: item.actorName, imageURL: item.actorImageURL }}
						imageSize={timelineItemIconSize}
					/>
				}
				title={
					<span>
						<EmployeePreviewTooltip id={item.actorID} style={{ display: "inline" }}>
							<EmployeeLink instance={{ id: item.actorID }}>{item.actorName || "[Unknown]"}</EmployeeLink>
						</EmployeePreviewTooltip>{" "}
						uploaded {isImage ? "an image" : "a file"} for{" "}
						<StudentPreviewTooltip key={student.id} id={student.id} style={{ display: "inline" }}>
							<StudentLink instance={student} />
						</StudentPreviewTooltip>
					</span>
				}
				body={
					<span style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", width: "100%" }}>
						{item.objectName}
						<If condition={description !== ""}>: {description}</If>
					</span>
				}
				date={item.eventDate}
				time={item.eventTime}
				onViewClick={toggleIsModalOpen}
				className={className}
				style={style}
			/>

			<Modal.If condition={isModalOpen}>
				<StudentDocumentModal
					id={item.objectID}
					url={fileURL}
					contentType={fileContentType}
					onClose={toggleIsModalOpen}
				/>
			</Modal.If>
		</React.Fragment>
	);
}

type TimelineItemLayoutProps = {
	icon?: React.ReactNode;
	title: React.ReactNode;
	date: string;
	time?: string | null;
	body?: React.ReactNode;
	onViewClick?: () => void;
} & Partial<ClassNameProps & StyleProps>;

function TimelineItemLayout({ icon, title, date, time, body, onViewClick, className, style }: TimelineItemLayoutProps) {
	return (
		<Row justify="spaced-start" className={makeClassName(styles["timeline__item"], className)} style={style}>
			<div className={styles["timeline__item__point"]}></div>

			<Column>
				{icon ?? (
					<div
						style={{
							width: timelineItemIconSize,
							height: timelineItemIconSize,
						}}
					>
						<img src={StaticURLs.Images.veritaWingsLogoGradient} style={{ width: "auto", height: "100%" }} />
					</div>
				)}
			</Column>

			<Column justify="spaced-start" verticalSpacing="0.5rem" style={{ flexGrow: 1, width: "100%", minWidth: 0 }}>
				<Row justify="space-between">
					{title}

					<span style={{ color: "#777", whiteSpace: "nowrap" }}>{formatTimelineItemDate(date, time)}</span>
				</Row>

				<div onClick={onViewClick}>
					<Row justify="space-between" align="center" className={styles["timeline__item__body-expand-container"]}>
						<div className={styles["timeline__item__body-container"]}>{body}</div>

						{!!onViewClick && (
							<div className={styles["timeline__item__expand-button-container"]}>
								<Button variant="link" size="small" style={{ padding: 0 }} onClick={() => {}}>
									<Row justify="spaced-start" horizontalSpacing="0.25rem" align="center">
										<Icon.Eye size="1rem" /> View
									</Row>
								</Button>
							</div>
						)}
					</Row>
				</div>
			</Column>
		</Row>
	);
}

function formatTimelineItemDate(date: string, time?: string | null) {
	if (time) {
		if (new Date().getTime() - new Date(time).getTime() < 12 * 60 * 60 * 1000) {
			return relativeTime(time);
		}
	}

	const today = toLocalDateString(new Date());
	const yesterday = toLocalDateString(addTimeToDate(new Date(), [-1, "day"]));
	switch (date) {
		case today:
			return "Today";
		case yesterday:
			return "Yesterday";
		default:
			return formatDateTime(date, "D/M/YYYY");
	}
}

export function timelineItemKey(item: Pick<TimelineItem, "actorID" | "objectID">) {
	return item.actorID + "-" + item.objectID;
}
