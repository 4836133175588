import React from "react";
import { ApplyCreateFunction, ApplyUpdateFunction, onCreateSubmit, onUpdateSubmit } from "@hex-insights/app-modules";
import { Button, Column, If, Row } from "@hex-insights/core";
import {
	anyFieldEditing,
	everyFieldEditing,
	Form,
	FormState,
	FormSubmitFunction,
	FormType,
	SubmissionStatus,
	SubmitButton,
} from "@hex-insights/forms";
import {
	RelationshipDetailQuery,
	RelationshipFormConversion,
	RelationshipFormState,
	RelationshipFormValues,
} from "../../../../Utilities";
import * as Field from "./Field";

export type ControlledCreateProps = {
	applyCreate: ApplyCreateFunction<RelationshipFormValues.Create>;
	onSuccess: () => void;
};

/**
 * Renders the create form of the Relationship model using an internally managed form state.
 */
export function ControlledCreate(props: ControlledCreateProps) {
	const formState = RelationshipFormState.useCreateFormState();

	return <Create formState={formState} {...props} />;
}

export type CreateProps = ControlledCreateProps & {
	formState: FormState<RelationshipFormValues.Create>;
};

/**
 * Renders the create form of the Relationship model using the given form state.
 */
export function Create({ formState, applyCreate, onSuccess }: CreateProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<RelationshipFormValues.Create>>(
		async ({ formValues }) => {
			return onCreateSubmit(formValues, applyCreate);
		},
		[applyCreate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	return (
		<Form name="relationship.create" formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)}>
			<Column justify="spaced-start" style={{ width: "fit-content" }}>
				<Field.Parent formState={formState} formType={FormType.Create} />
				<Field.RelationshipType formState={formState} formType={FormType.Create} />
				<Field.Student formState={formState} formType={FormType.Create} />
				<Field.IsPrimary formState={formState} formType={FormType.Create} />
				<Field.IsFinancialContact formState={formState} formType={FormType.Create} />
				<Field.IsEmergencyContact formState={formState} formType={FormType.Create} />
				<Field.IsAuthorizedForPickup formState={formState} formType={FormType.Create} />
				<Field.LivesWithStudent formState={formState} formType={FormType.Create} />

				<Row justify="flex-end">
					<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
						Submit
					</SubmitButton>
				</Row>
			</Column>
		</Form>
	);
}

export type ControlledDetailProps = {
	relationship: RelationshipDetailQuery["relationship"];
	applyUpdate: ApplyUpdateFunction<RelationshipFormValues.Detail>;
	onSuccess: () => void;
};

/**
 * Renders the detail form of the Relationship model using an internally managed form state.
 */
export function ControlledDetail(props: ControlledDetailProps) {
	const initialFormValues = React.useMemo(
		() => RelationshipFormConversion.toFormValues(props.relationship),
		[props.relationship],
	);
	const formState = RelationshipFormState.useDetailFormState({ initialFormValues });

	return <Detail formState={formState} {...props} />;
}

export type DetailProps = ControlledDetailProps & {
	formState: FormState<RelationshipFormValues.Detail>;
};

/**
 * Renders the detail form of the Relationship model using the given form state.
 */
export function Detail({ formState, relationship, applyUpdate, onSuccess }: DetailProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<RelationshipFormValues.Detail>>(
		async (formState) => {
			return onUpdateSubmit(formState, applyUpdate);
		},
		[applyUpdate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	const { anyEditing, everyEditing } = React.useMemo(() => {
		return {
			anyEditing: anyFieldEditing({
				formEditing: formState.formEditing,
				formSubFormStates: formState.formSubFormStates,
			}),
			everyEditing: everyFieldEditing({
				formEditing: formState.formEditing,
				formSubFormStates: formState.formSubFormStates,
			}),
		};
	}, [formState.formEditing, formState.formSubFormStates]);

	return (
		<Form
			name={`relationship.detail.${relationship.id}`}
			formState={formState}
			onSubmit={formState.onSubmitWrapper(onSubmit)}
		>
			<Column justify="spaced-start">
				<Row justify="spaced-start" horizontalSpacing="0.75rem">
					<If condition={!everyEditing}>
						<Button variant="tertiary" size="small" onClick={() => formState.setFormEditing(true)}>
							Edit
						</Button>
					</If>
					<If condition={anyEditing}>
						<Button variant="tertiary" size="small" onClick={() => formState.setFormEditing(false)}>
							Stop Editing
						</Button>
					</If>
				</Row>

				<Field.Parent
					formState={formState}
					formType={FormType.Update}
					id={relationship.id}
					currentParent={relationship.parent}
				/>
				<Field.RelationshipType formState={formState} formType={FormType.Update} id={relationship.id} />
				<Field.Student
					formState={formState}
					formType={FormType.Update}
					id={relationship.id}
					currentStudent={relationship.student}
				/>
				<Field.IsPrimary formState={formState} formType={FormType.Update} id={relationship.id} />
				<Field.IsFinancialContact formState={formState} formType={FormType.Update} id={relationship.id} />
				<Field.IsEmergencyContact formState={formState} formType={FormType.Update} id={relationship.id} />
				<Field.IsAuthorizedForPickup formState={formState} formType={FormType.Update} id={relationship.id} />
				<Field.LivesWithStudent formState={formState} formType={FormType.Update} id={relationship.id} />
			</Column>
		</Form>
	);
}

export type ControlledReadOnlyProps = {
	relationship: RelationshipDetailQuery["relationship"];
};

/**
 * Renders a read-only detail form of the Relationship model using an internally managed form state.
 */
export function ControlledReadOnly(props: ControlledReadOnlyProps) {
	const initialFormValues = React.useMemo(
		() => RelationshipFormConversion.toFormValues(props.relationship),
		[props.relationship],
	);
	const formState = RelationshipFormState.useReadOnlyFormState({ initialFormValues });

	return <ReadOnly formState={formState} {...props} />;
}

export type ReadOnlyProps = ControlledReadOnlyProps & {
	formState: FormState<RelationshipFormValues.Detail>;
};

/**
 * Renders a read-only detail form of the Relationship model using the given form state.
 */
export function ReadOnly({ formState, relationship }: ReadOnlyProps) {
	return (
		<Column justify="spaced-start">
			<Field.Parent
				formState={formState}
				formType={FormType.Update}
				id={relationship.id}
				currentParent={relationship.parent}
			/>
			<Field.RelationshipType formState={formState} formType={FormType.Update} id={relationship.id} />
			<Field.Student
				formState={formState}
				formType={FormType.Update}
				id={relationship.id}
				currentStudent={relationship.student}
			/>
			<Field.IsPrimary formState={formState} formType={FormType.Update} id={relationship.id} />
			<Field.IsFinancialContact formState={formState} formType={FormType.Update} id={relationship.id} />
			<Field.IsEmergencyContact formState={formState} formType={FormType.Update} id={relationship.id} />
			<Field.IsAuthorizedForPickup formState={formState} formType={FormType.Update} id={relationship.id} />
			<Field.LivesWithStudent formState={formState} formType={FormType.Update} id={relationship.id} />
		</Column>
	);
}
