import React from "react";
import { ApplyCreateFunction, onCreateSubmit } from "@hex-insights/app-modules";
import { Column, Conditional, Else, formatDateTime, If, jsxJoin, List, Row } from "@hex-insights/core";
import {
	BooleanField,
	DateTimeField,
	FieldIf,
	Form,
	FormState,
	FormSubmitFunction,
	Label,
	Legend,
	RadioField,
	SubmissionStatus,
	SubmitButton,
	TextField,
} from "@hex-insights/forms";
import { Employee, Maybe, Note, NoteFormState, NoteFormValues, Person, Student, User } from "../../../../../Utilities";
import { FieldSet } from "../../../../FieldSet";
import { StudentLink } from "../../../../Links";
import { TextAreaField, TextAreaFieldDisplay } from "../../../../TextAreaField";
import * as Field from "../Field";
import styles from "../styles.module.css";

export type ControlledCreateProps = {
	applyCreate: ApplyCreateFunction<NoteFormValues.Injury.Create>;
	onSuccess: () => void;
};

export function ControlledCreate(props: ControlledCreateProps) {
	const formState = NoteFormState.Injury.useCreateFormState();

	return <Create formState={formState} {...props} />;
}

export type CreateProps = ControlledCreateProps & {
	formState: FormState<NoteFormValues.Injury.Create>;
};

/**
 * Renders the create form of the Note model for an annoucement-type note using the given form state.
 */
export function Create({ formState, applyCreate, onSuccess }: CreateProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<NoteFormValues.Injury.Create>>(
		async ({ formValues }) => {
			return onCreateSubmit(formValues, applyCreate);
		},
		[applyCreate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	return (
		<Form
			name="note.create.injury"
			formState={formState}
			onSubmit={formState.onSubmitWrapper(onSubmit)}
			className={styles["note-form"]}
		>
			<Column justify="spaced-start" verticalSpacing="2rem">
				<Field.Students formState={formState} label="Students Injured" />

				<TextAreaField formState={formState} name="body" label="Describe the injury" />

				<DateTimeField
					formState={formState}
					name="incidentTime"
					label="What day and time did this injury happen?"
					precision="minute"
					optional
				/>

				<RadioField
					formState={formState}
					name="incidentLocation"
					label="Where did the injury happen?"
					options={NoteFormValues.schoolLocationOptions}
					optional
				/>

				<FieldSet>
					<FieldSet.Body style={{ padding: 0 }}>
						<Column justify="spaced-start">
							<RadioField
								formState={formState}
								name="wereOtherStudentsCauseOfInjury"
								label="Were other students the cause of the injury?"
								options={NoteFormValues.booleanOptions}
								optional
							/>
							{/* <FieldIf
								formState={formState}
								name="addBehaviorNote"
								condition={formState.formValues.wereOtherStudentsCauseOfInjury === true}
							>
								<RadioField
									formState={formState}
									name="addBehaviorNote"
									label="Would you like to submit a behavior report after submitting this report?"
									options={NoteFormValues.booleanOptions}
									optional
								/>
							</FieldIf> */}
							<TextAreaField
								formState={formState}
								name="causeOfInjury"
								label="What was the cause of the injury?"
								optional
							/>
						</Column>
					</FieldSet.Body>
				</FieldSet>

				<FieldSet>
					<FieldSet.Body style={{ padding: 0 }}>
						<Column justify="spaced-start">
							<TextAreaField
								formState={formState}
								name="medicalAttentionProvided"
								label="Describe medical attention provided"
								optional
							/>
							<TextField
								formState={formState}
								name="adultWhoProvidedMedicalAttention"
								label="Name of attending adult (who provided medical attention)"
								optional
							/>
						</Column>
					</FieldSet.Body>
				</FieldSet>

				{/* Required */}
				<FieldSet>
					<FieldSet.Header>
						<Label>Subsequent actions (select all relevant actions taken)</Label>
					</FieldSet.Header>

					<FieldSet.Body>
						<Column justify="spaced-start" verticalSpacing="0.25rem">
							<BooleanField
								formState={formState}
								name="isActionTakenHomeroomTeachersNotified"
								label="Homeroom Teachers Notified"
							/>
							<BooleanField
								formState={formState}
								name="isActionTakenParentNotifiedBySeesaw"
								label="Parent Notified by Seesaw"
							/>
							<BooleanField
								formState={formState}
								name="isActionTakenParentNotifiedByPhone"
								label="Parent Notified by Phone"
							/>
							<BooleanField
								formState={formState}
								name="isActionTakenParentsRequestedToCollectStudent"
								label="Parents Requested to Collect Student"
							/>
							<BooleanField
								formState={formState}
								name="isActionTakenParentNotificationNotNecessary"
								label="Parent Notification Not Necessary"
							/>
							<BooleanField formState={formState} name="isActionTakenOther" label="Other" />
							<FieldIf
								formState={formState}
								name="actionTakenOther"
								condition={formState.formValues.isActionTakenOther}
							>
								<TextField formState={formState} name="actionTakenOther" label="Describe" />
							</FieldIf>
						</Column>
					</FieldSet.Body>
				</FieldSet>

				<Row justify="flex-end" style={{ position: "sticky", bottom: "1rem" }}>
					<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
						Submit
					</SubmitButton>
				</Row>
			</Column>
		</Form>
	);
}

// TODO cleanup
export type ReadOnlyProps = {
	note: Pick<Note, "body" | "details" | "createdAt"> & {
		author: Maybe<
			Pick<User, "id" | "email"> & {
				person?: Maybe<
					Pick<Person, "name"> & {
						employee?: Maybe<Pick<Employee, "id">>;
					}
				>;
			}
		>;
		students: (Pick<Student, "id"> & { person: Pick<Student["person"], "id" | "name"> })[];
	};
};

export function ReadOnly({ note }: ReadOnlyProps) {
	const noteDetails = JSON.parse(note.details);

	return (
		<Column justify="spaced-start" verticalSpacing="2rem" className={styles["note-form"]}>
			<Row justify="spaced-start" align="center">
				<span>
					<Label>Student</Label>:
				</span>
				<span>
					{jsxJoin(
						note.students.map((e) => <StudentLink key={e.id} instance={e} />),
						", ",
					)}
				</span>
			</Row>

			<Column>
				<Label>Injury Description</Label>
				<TextAreaFieldDisplay value="" formattedValue={note.body} />
			</Column>

			<Row justify="spaced-start" align="center">
				<Column align="center">
					<Label>Injury Time</Label>
					<span>
						{noteDetails.incidentTime
							? formatDateTime(noteDetails.incidentTime, "HH:mm [on] D MMMM YYYY")
							: "Not given"}
					</span>
				</Column>

				<Column align="center">
					<Label>Injury Location</Label>
					<span>{noteDetails.incidentLocation || "Not given"}</span>
				</Column>
			</Row>

			<Column>
				<Label>Were other students the cause of the injury?</Label>
				<span>
					<Conditional>
						<If condition={noteDetails.wereOtherStudentsCauseOfInjury === true}>Yes</If>
						<If condition={noteDetails.wereOtherStudentsCauseOfInjury === false}>No</If>
						<Else>Not given</Else>
					</Conditional>
				</span>
			</Column>

			<Column>
				<Label>What was the cause of the injury?</Label>
				<TextAreaFieldDisplay value="" formattedValue={noteDetails.causeOfInjury || "Not given"} />
			</Column>

			<Column>
				<Label>Describe medical attention provided</Label>
				<TextAreaFieldDisplay value="" formattedValue={noteDetails.medicalAttentionProvided || "Not given"} />
			</Column>

			<Column>
				<Label>Name of attending adult (who provided medical attention)</Label>
				<TextAreaFieldDisplay value="" formattedValue={noteDetails.adultWhoProvidedMedicalAttention || "Not given"} />
			</Column>

			<FieldSet>
				<FieldSet.Header>
					<Legend>Subsequent actions</Legend>
				</FieldSet.Header>

				<FieldSet.Body>
					<Conditional>
						<If
							condition={
								noteDetails.isActionTakenHomeroomTeachersNotified ||
								noteDetails.isActionTakenParentNotifiedBySeesaw ||
								noteDetails.isActionTakenParentNotifiedByPhone ||
								noteDetails.isActionTakenParentsRequestedToCollectStudent ||
								noteDetails.isActionTakenParentNotificationNotNecessary ||
								noteDetails.isActionTakenOther
							}
						>
							<List>
								<If condition={noteDetails.isActionTakenHomeroomTeachersNotified}>
									<List.Item>Homeroom Teachers Notified</List.Item>
								</If>
								<If condition={noteDetails.isActionTakenParentNotifiedBySeesaw}>
									<List.Item>Parent Notified by Seesaw</List.Item>
								</If>
								<If condition={noteDetails.isActionTakenParentNotifiedByPhone}>
									<List.Item>Parent Notified by Phone</List.Item>
								</If>
								<If condition={noteDetails.isActionTakenParentsRequestedToCollectStudent}>
									<List.Item>Parents Requested to Collect Student</List.Item>
								</If>
								<If condition={noteDetails.isActionTakenParentNotificationNotNecessary}>
									<List.Item>Parent Notification Not Necessary</List.Item>
								</If>
								<If condition={noteDetails.isActionTakenOther}>
									<List.Item>Other: {noteDetails.actionTakenOther}</List.Item>
								</If>
							</List>
						</If>
						<Else>
							<span>None Given</span>
						</Else>
					</Conditional>
				</FieldSet.Body>
			</FieldSet>
		</Column>
	);
}
