import { Models } from "@hex-insights/app-modules";
import { DistributiveOmit } from "@hex-insights/core";
import { Student, StudentFormat, StudentNameFragment } from "../../../../Utilities";

type ModelForLink = Pick<Student, "id"> & StudentNameFragment;

export type StudentLinkProps = DistributiveOmit<Models.LinkProps<ModelForLink>, "pageInfoKey" | "format">;

/**
 * Renders a link to the given Student instance.
 */
export function StudentLink(props: StudentLinkProps) {
	return <Models.Link<ModelForLink> pageInfoKey="Student" format={StudentFormat.name} {...props} />;
}
