import React from "react";
import { Column, formatInt, Row, StyleProps, toLocalDateString } from "@hex-insights/core";
import { InternalLink, useActivePageRegistration } from "@hex-insights/router";
import {
	DocumentResourceOrderField,
	EventCalendarTile,
	OrderDirection,
	ProgressBarTile,
	StudentsQuickActions,
	Tile,
	useDocumentResourceIndexQuery,
} from "@hex-insights/verita.shared";
import { Main, ResourcesTile, ResourcesTileProps } from "../../Components";
import { useSearch } from "../../Components/GlobalSearch/use-search";
import { AuthenticationContext } from "../../Contexts";
import { useCurrentPeriod } from "../../Utilities";
import { scheduleDayCoursePeriodPageInfo } from "../Schedule/Day/CoursePeriod/pageinfo";
import { MainTile, ScheduleTile } from "./Components";
import { homePageInfo } from "./pageinfo";
import styles from "./styles.module.css";

export function HomePage() {
	useActivePageRegistration(homePageInfo);

	return (
		<Main>
			<Row
				justify="spaced-start"
				style={{
					height: "calc(100vh - var(--header---height) - 2 * var(--main---padding-top))",
				}}
			>
				<Column
					justify="spaced-start"
					style={{
						flexGrow: 1,
						minWidth: 0,
					}}
				>
					<MainTile style={{ flexGrow: 1 }} />
					<HomeResourcesTile />
				</Column>

				<Column
					justify="spaced-start"
					style={{
						width: "30%",
						maxWidth: "25rem",
						flexShrink: 0,
						backgroundColor: "#f2f9ff",
						borderRadius: "var(--general__tile---border-radius)",
						padding: "1rem",
					}}
				>
					<StudentsQuickActions useSearch={useSearch} />
					<EventCalendarTile style={{ flexGrow: 1, height: "100%", overflow: "hidden", boxShadow: "none" }} />
					<ScheduleTile style={{ flexShrink: 0 }} />
					<CurrentCourseTile style={{ flexShrink: 0 }} />
				</Column>
			</Row>
		</Main>
	);
}

type HomeResourcesTileProps = Pick<ResourcesTileProps, "className" | "style">;

function HomeResourcesTile({ className, style }: HomeResourcesTileProps) {
	const { user } = React.useContext(AuthenticationContext);
	const { loading, data } = useDocumentResourceIndexQuery({
		variables: {
			filters: {
				bookmarkingUsers: [{ idEQ: user?.id }],
			},
			order: { field: DocumentResourceOrderField.Name, direction: OrderDirection.Asc },
		},
	});

	return (
		<ResourcesTile
			isLoading={loading}
			edges={data?.documentResourceConnection.edges}
			backgroundColor="#f2f9ff"
			className={className}
			style={{ boxShadow: "none", ...style }}
		/>
	);
}

type CurrentCourseTileProps = Partial<StyleProps>;

function CurrentCourseTile({ style }: CurrentCourseTileProps) {
	const { currentPeriod, courseTimeProgressAnimationTarget, refreshIntervalMS, minutesToStart, minutesToEnd } =
		useCurrentPeriod(true);

	if (!currentPeriod) {
		return null;
	}

	return (
		<InternalLink
			to={scheduleDayCoursePeriodPageInfo.to(toLocalDateString(new Date()), currentPeriod.id)}
			className={styles["current-course-tile__container-link"]}
			style={style}
		>
			<ProgressBarTile
				progress={courseTimeProgressAnimationTarget}
				refreshIntervalMS={refreshIntervalMS}
				className={styles["current-course-tile"]}
			>
				<Tile.Body style={{ height: "100%" }}>
					<Row justify="center" style={{ height: "100%" }}>
						<Column justify="center" align="center" style={{ width: "100%", overflow: "hidden" }}>
							<span className={styles["current-course-tile__course-name"]}>{currentPeriod.courseSection.name}</span>
							<span className={styles["current-course-tile__time"]}>{getTimeText(minutesToStart, minutesToEnd)}</span>
							<span className={styles["current-course-tile__hover-text"]}>Jump to Class</span>
						</Column>
					</Row>
				</Tile.Body>
			</ProgressBarTile>
		</InternalLink>
	);
}

function getTimeText(minutesToStart: number, minutesToEnd: number) {
	if (minutesToStart < 0) {
		return `${formatInt(minutesToEnd)} min remaining`;
	}
	if (minutesToStart < 1) {
		return "starts in less than 1 min";
	}
	return `starts in ${formatInt(minutesToStart)} min`;
}
