import React from "react";
import {
	DateTimeField,
	FieldDisplayArgs,
	FieldDisplayFunction,
	MultiSelectField,
	SelectField,
	TextField,
} from "@hex-insights/forms";
import {
	CourseSectionSelect,
	LearningObjectiveSelect,
	LearningObjectiveSelectQueryVariables,
	LessonPlanFormValues,
	useCourseSectionSelectLazyQuery,
	useLearningObjectiveSelectLazyQuery,
} from "../../../../Utilities";
import { CourseSectionLink, LearningObjectiveLink } from "../../../Links";
import { TextAreaField } from "../../../TextAreaField";
import { BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the LessonPlan model.
 */
type FieldProps<K extends keyof LessonPlanFormValues.Base = keyof LessonPlanFormValues.Base> = BaseFieldProps<
	Pick<LessonPlanFormValues.Base, K>
>;

/**
 * Renders a field component for the `courseDate` field of the LessonPlan model.
 */
export function CourseDate({ formState }: FieldProps<"courseDate">) {
	return <DateTimeField formState={formState} name="courseDate" precision="day" />;
}

/**
 * Renders a field component for the `lessonDescription` field of the LessonPlan model.
 */
export function LessonDescription({ formState, autoFocus }: FieldProps<"lessonDescription"> & { autoFocus?: boolean }) {
	return <TextAreaField formState={formState} name="lessonDescription" minRows={1} autoFocus={autoFocus} />;
}

/**
 * Renders a field component for the `requiredResourcesAndMaterials` field of the LessonPlan model.
 */
export function RequiredResourcesAndMaterials({ formState }: FieldProps<"requiredResourcesAndMaterials">) {
	return <TextField formState={formState} name="requiredResourcesAndMaterials" optional />;
}

/**
 * Renders a field component for the `stationOneDescription` field of the LessonPlan model.
 */
export function StationOneDescription({ formState }: FieldProps<"stationOneDescription">) {
	return <TextAreaField formState={formState} name="stationOneDescription" minRows={1} optional />;
}

/**
 * Renders a field component for the `stationTwoDescription` field of the LessonPlan model.
 */
export function StationTwoDescription({ formState }: FieldProps<"stationTwoDescription">) {
	return <TextAreaField formState={formState} name="stationTwoDescription" minRows={1} optional />;
}

/**
 * Renders a field component for the `stationThreeDescription` field of the LessonPlan model.
 */
export function StationThreeDescription({ formState }: FieldProps<"stationThreeDescription">) {
	return <TextAreaField formState={formState} name="stationThreeDescription" minRows={1} optional />;
}

/**
 * Renders a field component for the `differentiationDescription` field of the LessonPlan model.
 */
export function DifferentiationDescription({ formState }: FieldProps<"differentiationDescription">) {
	return <TextAreaField formState={formState} name="differentiationDescription" minRows={1} optional />;
}

/**
 * Renders a field component for the `assessmentDescription` field of the LessonPlan model.
 */
export function AssessmentDescription({ formState }: FieldProps<"assessmentDescription">) {
	return <TextAreaField formState={formState} name="assessmentDescription" minRows={1} optional />;
}

/**
 * Renders a field component for the `notes` field of the LessonPlan model.
 */
export function Notes({ formState }: FieldProps<"notes">) {
	return <TextAreaField formState={formState} name="notes" minRows={1} optional />;
}

export type CourseSectionProps = FieldProps<"courseSectionID"> & {
	currentCourseSection?: CourseSectionSelect.ModelForOption | null;
	displayInstance?: FieldDisplayFunction<LessonPlanFormValues.Base["courseSectionID"]>;
};

/**
 * Renders a field component for the `courseSection` edge of the LessonPlan model.
 */
export function CourseSection({
	formState,
	currentCourseSection,
	displayInstance = displayCourseSection,
}: CourseSectionProps) {
	const [loadOptions, { loading, data }] = useCourseSectionSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.courseSectionID) {
			loadOptions();
		}
	}, [formState.formEditing.courseSectionID, loadOptions]);
	const options = React.useMemo(
		() => CourseSectionSelect.toOptions(data?.courseSectionConnection.edges, currentCourseSection),
		[data, currentCourseSection],
	);

	return (
		<SelectField
			formState={formState}
			name="courseSectionID"
			isLoading={loading}
			options={options}
			display={displayInstance}
			blankValue={null}
		/>
	);
}

function displayCourseSection({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <CourseSectionLink instance={{ id }}>{formattedValue}</CourseSectionLink>;
}

export type LearningObjectivesProps = FieldProps<"learningObjectiveIDs"> & {
	currentLearningObjectives?: LearningObjectiveSelect.ModelForOption[];
	displayInstance?: FieldDisplayFunction<LessonPlanFormValues.Base["learningObjectiveIDs"][0]>;
	queryVariables?: LearningObjectiveSelectQueryVariables;
};

/**
 * Renders a field component for the `learningObjectives` edge of the LessonPlan model.
 */
export function LearningObjectives({
	formState,
	currentLearningObjectives,
	displayInstance = displayLearningObjectiveInstance,
	queryVariables,
}: LearningObjectivesProps) {
	const [loadOptions, { loading, data }] = useLearningObjectiveSelectLazyQuery({ variables: queryVariables });
	React.useEffect(() => {
		if (formState.formEditing.learningObjectiveIDs) {
			loadOptions();
		}
	}, [formState.formEditing.learningObjectiveIDs, loadOptions]);
	const options = React.useMemo(
		() => LearningObjectiveSelect.toMultiOptions(data?.learningObjectiveConnection.edges, currentLearningObjectives),
		[data, currentLearningObjectives],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="learningObjectiveIDs"
			isLoading={loading}
			options={options}
			displayInstance={displayInstance}
		/>
	);
}

function displayLearningObjectiveInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <LearningObjectiveLink instance={{ id }}>{formattedValue}</LearningObjectiveLink>;
}
