import { formatDateTime } from "@hex-insights/core";
import { dowToDate, minutesToTime } from "../../../date-time";
import { CourseSectionPeriod } from "../../../GraphQL";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the dayOfWeek field of the CourseSectionPeriod model.
 */
export function dayOfWeek(value: CourseSectionPeriod["dayOfWeek"] | null): string {
	if (value === null) {
		return toString(value);
	}
	return formatDateTime(dowToDate(value), "dddd");
}

/**
 * Formats the startTimeMinutes field of the CourseSectionPeriod model.
 */
export function startTimeMinutes(value: CourseSectionPeriod["startTimeMinutes"] | null): string {
	return formatDateTime(minutesToTime(value ?? 0), "HH:mm");
}

/**
 * Formats the endTimeMinutes field of the CourseSectionPeriod model.
 */
export function endTimeMinutes(value: CourseSectionPeriod["endTimeMinutes"] | null): string {
	return formatDateTime(minutesToTime(value ?? 0), "HH:mm");
}
