import React from "react";
import { ApplyCreateFunction, ApplyUpdateFunction, onCreateSubmit, onUpdateSubmit } from "@hex-insights/app-modules";
import {
	addTimeToDate,
	Button,
	Column,
	compareStringsDesc,
	formatDateTime,
	Icon,
	If,
	Row,
	stringToLocalDate,
	toLocalDateString,
} from "@hex-insights/core";
import {
	anyFieldEditing,
	everyFieldEditing,
	Form,
	FormState,
	FormSubmitFunction,
	FormType,
	SubFormField,
	SubFormRenderProps,
	SubmissionStatus,
	SubmitButton,
} from "@hex-insights/forms";
import {
	MealMenuDetailQuery,
	MealMenuFormConversion,
	MealMenuFormState,
	MealMenuFormValues,
} from "../../../../Utilities";
import { FormActionRow, FormColumn } from "../../../FormAddon";
import { HR } from "../../../HR";
import * as Field from "./Field";
import styles from "./styles.module.css";

export type ControlledCreateProps = {
	applyCreate: ApplyCreateFunction<MealMenuFormValues.Create>;
	onSuccess: () => void;
};

/**
 * Renders the create form of the MealMenu model using an internally managed form state.
 */
export function ControlledCreate(props: ControlledCreateProps) {
	const formState = MealMenuFormState.useCreateFormState();

	return <Create formState={formState} {...props} />;
}

export type CreateProps = ControlledCreateProps & {
	formState: FormState<MealMenuFormValues.Create>;
};

/**
 * Renders the create form of the MealMenu model using the given form state.
 */
export function Create({ formState, applyCreate, onSuccess }: CreateProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<MealMenuFormValues.Create>>(
		async ({ formValues }) => {
			return onCreateSubmit(formValues, applyCreate);
		},
		[applyCreate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	return (
		<Form name="mealMenu.create" formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)}>
			<FormColumn>
				<Field.MenuDate formState={formState} formType={FormType.Create} />
				<Row justify="spaced-start" align="spaced-start" overflow="wrap">
					<Field.Breakfast formState={formState} formType={FormType.Create} />
					<Field.Lunch formState={formState} formType={FormType.Create} />
					<Field.Snack formState={formState} formType={FormType.Create} />
				</Row>
				<Row justify="spaced-start" align="spaced-start" overflow="wrap">
					<Field.VegetarianBreakfast formState={formState} formType={FormType.Create} />
					<Field.VegetarianLunch formState={formState} formType={FormType.Create} />
					<Field.VegetarianSnack formState={formState} formType={FormType.Create} />
				</Row>

				<FormActionRow>
					<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
						Submit
					</SubmitButton>
				</FormActionRow>
			</FormColumn>
		</Form>
	);
}

export type ControlledBulkCreateProps = {
	applyCreate: ApplyCreateFunction<MealMenuFormValues.BulkCreate>;
	onSuccess: () => void;
};

export function ControlledBulkCreate(props: ControlledBulkCreateProps) {
	const initialFormValues = React.useMemo(
		() => ({
			mealMenus: [{ ...MealMenuFormValues.initialCreate, menuDate: nextWeekday(toLocalDateString(new Date()), true) }],
		}),
		[],
	);
	const formState = MealMenuFormState.useBulkCreateFormState(initialFormValues);

	return <BulkCreate formState={formState} {...props} />;
}

export type BulkCreateProps = ControlledBulkCreateProps & {
	formState: FormState<MealMenuFormValues.BulkCreate>;
};

export function BulkCreate({ formState, applyCreate, onSuccess }: BulkCreateProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<MealMenuFormValues.BulkCreate>>(
		async ({ formValues }) => {
			return onCreateSubmit(formValues, applyCreate);
		},
		[applyCreate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	const { mealMenus } = formState.formValues;
	const blankItem = React.useMemo<MealMenuFormValues.Create>(() => {
		let menuDate = toLocalDateString(new Date());
		if (mealMenus.length > 0) {
			const lastDate = mealMenus.map((e) => e.menuDate ?? "0").sort(compareStringsDesc())[0];
			menuDate = nextWeekday(lastDate);
		}
		return { ...MealMenuFormValues.initialCreate, menuDate };
	}, [mealMenus]);

	return (
		<Form name="mealMenu.bulk-create" formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)}>
			<FormColumn>
				<SubFormField
					formState={formState}
					name="mealMenus"
					label="Days"
					dividerElement={<HR color="#eee" style={{ margin: "0.75rem auto 0.5rem", width: "calc(100% - 3rem)" }} />}
					blankItem={blankItem}
					className={styles["meal-menus-field-set-array"]}
				>
					{BulkCreateItem}
					{({ onClick, disabled }) => (
						<Row justify="center" style={{ padding: "0.5rem 0" }}>
							<Button variant="secondary" onClick={onClick} disabled={disabled}>
								Add Day
							</Button>
						</Row>
					)}
				</SubFormField>

				<FormActionRow>
					<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
						Submit
					</SubmitButton>
				</FormActionRow>
			</FormColumn>
		</Form>
	);
}

/**
 * @param date The date as an ISO string.
 *
 * @returns An ISO string.
 */
function nextWeekday(date: string, includeGiven = false) {
	let localDate = stringToLocalDate(date, "day");
	if (!includeGiven) {
		localDate = addTimeToDate(localDate, [1, "day"]).toDate();
	}
	while (localDate.getDay() === 0 || localDate.getDay() === 6) {
		localDate = addTimeToDate(localDate, [1, "day"]).toDate();
	}
	return toLocalDateString(localDate);
}

function BulkCreateItem({ formState, onRemoveClick }: SubFormRenderProps<MealMenuFormValues.Create>) {
	return (
		<Row
			justify="spaced-start"
			align="spaced-start"
			overflow="wrap"
			className={styles["meal-menus-field-set-array__item"]}
		>
			<Column justify="spaced-start" align="center">
				<Field.MenuDate formState={formState} formType={FormType.Create} />
				{formState.formValues.menuDate !== null && (
					<span>{formatDateTime(stringToLocalDate(formState.formValues.menuDate, "day"), "dddd D MMM")}</span>
				)}
				<Button size="small" variant="tertiary" onClick={onRemoveClick}>
					<Row justify="spaced-start" horizontalSpacing="0.25rem" align="center">
						<Icon.Trash size="1rem" /> Remove Day
					</Row>
				</Button>
			</Column>
			<Column justify="spaced-start">
				<Row justify="spaced-start" align="spaced-start" overflow="wrap">
					<Field.Breakfast formState={formState} formType={FormType.Create} />
					<Field.Lunch formState={formState} formType={FormType.Create} />
					<Field.Snack formState={formState} formType={FormType.Create} />
				</Row>
				<Row justify="spaced-start" align="spaced-start" overflow="wrap">
					<Field.VegetarianBreakfast formState={formState} formType={FormType.Create} />
					<Field.VegetarianLunch formState={formState} formType={FormType.Create} />
					<Field.VegetarianSnack formState={formState} formType={FormType.Create} />
				</Row>
			</Column>
		</Row>
	);
}

export type ControlledDetailProps = {
	mealMenu: MealMenuDetailQuery["mealMenu"];
	applyUpdate: ApplyUpdateFunction<MealMenuFormValues.Detail>;
	onSuccess: () => void;
};

/**
 * Renders the detail form of the MealMenu model using an internally managed form state.
 */
export function ControlledDetail(props: ControlledDetailProps) {
	const initialFormValues = React.useMemo(() => MealMenuFormConversion.toFormValues(props.mealMenu), [props.mealMenu]);
	const formState = MealMenuFormState.useDetailFormState({ initialFormValues });

	return <Detail formState={formState} {...props} />;
}

export type DetailProps = ControlledDetailProps & {
	formState: FormState<MealMenuFormValues.Detail>;
};

/**
 * Renders the detail form of the MealMenu model using the given form state.
 */
export function Detail({ formState, mealMenu, applyUpdate, onSuccess }: DetailProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<MealMenuFormValues.Detail>>(
		async (formState) => {
			return onUpdateSubmit(formState, applyUpdate);
		},
		[applyUpdate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	const { anyEditing, everyEditing } = React.useMemo(() => {
		return {
			anyEditing: anyFieldEditing({
				formEditing: formState.formEditing,
				formSubFormStates: formState.formSubFormStates,
			}),
			everyEditing: everyFieldEditing({
				formEditing: formState.formEditing,
				formSubFormStates: formState.formSubFormStates,
			}),
		};
	}, [formState.formEditing, formState.formSubFormStates]);

	return (
		<Form name={`mealMenu.detail.${mealMenu.id}`} formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)}>
			<FormColumn>
				<Row justify="spaced-start" horizontalSpacing="0.75rem">
					<If condition={!everyEditing}>
						<Button variant="tertiary" size="small" onClick={() => formState.setFormEditing(true)}>
							Edit
						</Button>
					</If>
					<If condition={anyEditing}>
						<Button variant="tertiary" size="small" onClick={() => formState.setFormEditing(false)}>
							Stop Editing
						</Button>
					</If>
				</Row>

				<Field.MenuDate formState={formState} formType={FormType.Update} id={mealMenu.id} />
				<Row justify="spaced-start" align="spaced-start" overflow="wrap">
					<Field.Breakfast formState={formState} formType={FormType.Update} id={mealMenu.id} />
					<Field.Lunch formState={formState} formType={FormType.Update} id={mealMenu.id} />
					<Field.Snack formState={formState} formType={FormType.Update} id={mealMenu.id} />
				</Row>
				<Row justify="spaced-start" align="spaced-start" overflow="wrap">
					<Field.VegetarianBreakfast formState={formState} formType={FormType.Update} id={mealMenu.id} />
					<Field.VegetarianLunch formState={formState} formType={FormType.Update} id={mealMenu.id} />
					<Field.VegetarianSnack formState={formState} formType={FormType.Update} id={mealMenu.id} />
				</Row>
			</FormColumn>
		</Form>
	);
}

export type ControlledReadOnlyProps = {
	mealMenu: MealMenuDetailQuery["mealMenu"];
};

/**
 * Renders a read-only detail form of the MealMenu model using an internally managed form state.
 */
export function ControlledReadOnly(props: ControlledReadOnlyProps) {
	const initialFormValues = React.useMemo(() => MealMenuFormConversion.toFormValues(props.mealMenu), [props.mealMenu]);
	const formState = MealMenuFormState.useReadOnlyFormState({ initialFormValues });

	return <ReadOnly formState={formState} {...props} />;
}

export type ReadOnlyProps = ControlledReadOnlyProps & {
	formState: FormState<MealMenuFormValues.Detail>;
};

/**
 * Renders a read-only detail form of the MealMenu model using the given form state.
 */
export function ReadOnly({ formState, mealMenu }: ReadOnlyProps) {
	return (
		<FormColumn>
			<Field.MenuDate formState={formState} formType={FormType.Update} id={mealMenu.id} />
			<Row justify="spaced-start" align="spaced-start" overflow="wrap">
				<Field.Breakfast formState={formState} formType={FormType.Update} id={mealMenu.id} />
				<Field.Lunch formState={formState} formType={FormType.Update} id={mealMenu.id} />
				<Field.Snack formState={formState} formType={FormType.Update} id={mealMenu.id} />
			</Row>
			<Row justify="spaced-start" align="spaced-start" overflow="wrap">
				<Field.VegetarianBreakfast formState={formState} formType={FormType.Update} id={mealMenu.id} />
				<Field.VegetarianLunch formState={formState} formType={FormType.Update} id={mealMenu.id} />
				<Field.VegetarianSnack formState={formState} formType={FormType.Update} id={mealMenu.id} />
			</Row>
		</FormColumn>
	);
}
