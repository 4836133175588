import { compareNumbersAsc, filterUnique, toLocalDateString } from "@hex-insights/core";
import { dowToDate } from "./date-time";
import { CourseSectionPeriod } from "./GraphQL";

export function getWeekDateRangeForCourseSection(periods: Pick<CourseSectionPeriod, "dayOfWeek">[], date = new Date()) {
	const days = filterUnique(periods.map((e) => e.dayOfWeek)).sort(compareNumbersAsc());
	const dateRange = days.map((e) => toLocalDateString(dowToDate(e, date)));
	const startDate = dateRange[0];
	const endDate = dateRange[dateRange.length - 1];
	return { dateRange, startDate, endDate };
}
