import { Models } from "@hex-insights/app-modules";
import { DistributiveOmit } from "@hex-insights/core";
import { ClassYear, ClassYearFormat, ClassYearNameFragment } from "../../../../Utilities";

type ModelForLink = Pick<ClassYear, "id"> & ClassYearNameFragment;

export type ClassYearLinkProps = DistributiveOmit<Models.LinkProps<ModelForLink>, "pageInfoKey" | "format">;

/**
 * Renders a link to the given ClassYear instance.
 */
export function ClassYearLink(props: ClassYearLinkProps) {
	return <Models.Link<ModelForLink> pageInfoKey="ClassYear" format={ClassYearFormat.name} {...props} />;
}
