import React from "react";
import { ChildrenProps, DefaultContextValue, useRequiredContext } from "@hex-insights/core";
import { User } from "../Utilities";

export type UserContextValue = {
	user: Pick<User, "id">;
};

export const UserContext = React.createContext<UserContextValue>(new DefaultContextValue("UserContext") as any);

export type UserContextManagerProps = {
	user: UserContextValue["user"];
} & ChildrenProps;

export function UserContextManager({ user, children }: UserContextManagerProps) {
	const contextValue: UserContextValue = React.useMemo(() => {
		return { user };
	}, [user]);

	return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
}

export function useCurrentUser() {
	return useRequiredContext(UserContext, "useCurrentUser");
}
