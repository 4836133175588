import { getArrayDiff, NullPartial, omitKeys } from "@hex-insights/core";
import {
	ServiceSubscriptionCreateInput,
	ServiceSubscriptionDetailQuery,
	ServiceSubscriptionInContractCreateInput,
	ServiceSubscriptionUpdateInput,
} from "../../GraphQL";
import { ServiceSubscriptionFormValues } from "../ModelFormValues";
import * as DiscountFormConversion from "./discount";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the ServiceSubscription model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(formValues: ServiceSubscriptionFormValues.Create): ServiceSubscriptionCreateInput {
	const input: NullPartial<ServiceSubscriptionCreateInput> = {
		...formValues,
		discounts: formValues.discounts.map(DiscountFormConversion.toGQLCreateInServiceSubscriptionInput),
	};
	return input as ServiceSubscriptionCreateInput;
}

export function toGQLCreateInContractInput(
	formValues: ServiceSubscriptionFormValues.CreateInContract,
): ServiceSubscriptionInContractCreateInput {
	const input: NullPartial<ServiceSubscriptionInContractCreateInput> = {
		...formValues,
		discounts: formValues.discounts.map(DiscountFormConversion.toGQLCreateInServiceSubscriptionInput),
	};
	return input as ServiceSubscriptionInContractCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the ServiceSubscription model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<ServiceSubscriptionFormValues.Detail>,
	initialFormValues: ServiceSubscriptionFormValues.Detail,
): ServiceSubscriptionUpdateInput {
	const inputFormValues = omitKeys(formValues, ["discountIDs"]);
	const input: ServiceSubscriptionUpdateInput = { ...inputFormValues };

	if (formValues.discountIDs !== undefined) {
		const { added, removed } = getArrayDiff(initialFormValues.discountIDs, formValues.discountIDs);
		input.addDiscountIDs = added;
		input.removeDiscountIDs = removed;
	}

	return input;
}

/**
 * Converts a ServiceSubscription instance to its corresponding detail form values.
 *
 * @param serviceSubscription The instance to convert.
 */
export function toFormValues(
	serviceSubscription: ServiceSubscriptionDetailQuery["serviceSubscription"],
): ServiceSubscriptionFormValues.Detail {
	return {
		price: serviceSubscription.price,
		contractID: serviceSubscription.contract?.id ?? null,
		discountIDs: serviceSubscription.discounts.map((e) => e.id),
		serviceID: serviceSubscription.service?.id ?? null,
	};
}
