import { omitKeys } from "@hex-insights/core";
import { Contract, Discount, Service, ServiceSubscription } from "../../GraphQL";
import * as DiscountFormValues from "./discount";

export type Base = {
	contractID: Contract["id"] | null;
	price: ServiceSubscription["price"] | null;
	serviceID: Service["id"] | null;
};

export type Create = Base & {
	discounts: DiscountFormValues.CreateInServiceSubscription[];
};

export type Detail = Base & {
	discountIDs: Discount["id"][];
};

export const initialCreate: Create = {
	contractID: null,
	price: null,
	serviceID: null,
	discounts: [],
};

export type CreateInContract = Omit<Create, "contractID">;

export const initialCreateInContract = omitKeys(initialCreate, ["contractID"]);

export type CreateBulkForContract = Pick<Create, "contractID"> & {
	serviceSubscriptions: Omit<Create, "contractID">[];
};

export const initialCreateBulkForContractItem: CreateBulkForContract["serviceSubscriptions"][0] = omitKeys(
	initialCreate,
	["contractID"],
);

export const initialCreateBulkForContract: CreateBulkForContract = {
	contractID: null,
	serviceSubscriptions: [initialCreateBulkForContractItem],
};
