import React from "react";
import {
	Button,
	Column,
	Heading,
	Icon,
	If,
	Modal,
	ModalProps,
	RequiredKeys,
	Row,
	toLocalDateString,
	Tooltip,
} from "@hex-insights/core";
import { anyFieldChanged, useFormState } from "@hex-insights/forms";
import {
	NoteFormConversion,
	NoteFormState,
	NoteFormValues,
	NoteMutation,
	NoteNoteType,
	Student,
} from "../../Utilities";
import { NoteField, NoteForm } from "../Forms";
import { TextAreaField } from "../TextAreaField";
import styles from "./styles.module.css";

type StudentNoteFormValues = {
	studentID: Student["id"] | null;
	body: string;
};

const initialStudentNoteFormValues: StudentNoteFormValues = { studentID: null, body: "" };

export type StudentNoteFormModalProps = {
	studentID?: Student["id"] | null;
	noteTypes?: NoteNoteType[];
} & RequiredKeys<Pick<ModalProps, "ifRef" | "onClose">, "onClose">;

export function StudentNoteFormModal({ studentID = null, noteTypes, ifRef, onClose }: StudentNoteFormModalProps) {
	const noteTypeSet = new Set(noteTypes);
	const allNoteTypes = !noteTypes || noteTypes.length === 0;
	const singleNoteType = noteTypes && noteTypes.length === 1;

	const [noteType, setNoteType] = React.useState<NoteNoteType | null>(singleNoteType ? noteTypes[0] : null);
	const onBack = React.useMemo(() => {
		if (singleNoteType) {
			return null;
		}
		return () => setNoteType(null);
	}, [singleNoteType]);

	const initialFormValues = React.useMemo(() => ({ ...initialStudentNoteFormValues, studentID }), [studentID]);
	const formState = useFormState({ initialFormValues });

	if (noteType === null) {
		const buttonsDisabled = formState.formValues.studentID === null;

		return (
			<Modal
				ifRef={ifRef}
				onClose={onClose}
				confirmOnClose={anyFieldChanged(formState)}
				className={styles["note-modal"]}
				style={{ width: "fit-content" }}
			>
				<Modal.Body style={{ paddingLeft: "2rem", paddingRight: "2rem" }}>
					<Column justify="spaced-start">
						<NoteField.Student formState={formState} />
						<TextAreaField formState={formState} name="body" label="Note" minRows={3} optional />

						<span style={{ fontWeight: "bold" }}>What type of note/report is this?</span>

						<Column justify="spaced-start" verticalSpacing="1.25rem" align="center">
							<If condition={allNoteTypes || noteTypeSet.has(NoteNoteType.General)}>
								<Column justify="spaced-start" verticalSpacing="0.25rem" align="center" style={{ width: "100%" }}>
									<If condition={allNoteTypes || noteTypeSet.has(NoteNoteType.General)}>
										<Button
											size="large"
											onClick={() => setNoteType(NoteNoteType.General)}
											disabled={buttonsDisabled}
											className={styles["note-type-button"]}
										>
											General
											<Tooltip.Container className={styles["note-type-button__info-tooltip"]}>
												<Tooltip>
													<Tooltip.Body>General</Tooltip.Body>
												</Tooltip>

												<Icon.Info size="1.25rem" style={{ display: "block" }} />
											</Tooltip.Container>
										</Button>
									</If>
								</Column>
							</If>

							<If
								condition={
									allNoteTypes ||
									noteTypeSet.has(NoteNoteType.Academic) ||
									noteTypeSet.has(NoteNoteType.Behavior) ||
									noteTypeSet.has(NoteNoteType.SocialEmotional)
								}
							>
								<Column justify="spaced-start" verticalSpacing="0.25rem" align="center" style={{ width: "100%" }}>
									<If condition={allNoteTypes || noteTypeSet.has(NoteNoteType.Academic)}>
										<Button
											size="large"
											onClick={() => setNoteType(NoteNoteType.Academic)}
											disabled={buttonsDisabled}
											className={styles["note-type-button"]}
										>
											Academic
											<Tooltip.Container className={styles["note-type-button__info-tooltip"]}>
												<Tooltip>
													<Tooltip.Body>Academic</Tooltip.Body>
												</Tooltip>

												<Icon.Info size="1.25rem" style={{ display: "block" }} />
											</Tooltip.Container>
										</Button>
									</If>
									<If condition={allNoteTypes || noteTypeSet.has(NoteNoteType.Behavior)}>
										<Button
											size="large"
											onClick={() => setNoteType(NoteNoteType.Behavior)}
											disabled={buttonsDisabled}
											className={styles["note-type-button"]}
										>
											Behavior
											<Tooltip.Container className={styles["note-type-button__info-tooltip"]}>
												<Tooltip>
													<Tooltip.Body>Behavior</Tooltip.Body>
												</Tooltip>

												<Icon.Info size="1.25rem" style={{ display: "block" }} />
											</Tooltip.Container>
										</Button>
									</If>
									<If condition={allNoteTypes || noteTypeSet.has(NoteNoteType.SocialEmotional)}>
										<Button
											size="large"
											onClick={() => setNoteType(NoteNoteType.SocialEmotional)}
											disabled={buttonsDisabled}
											className={styles["note-type-button"]}
										>
											Social Emotional
											<Tooltip.Container className={styles["note-type-button__info-tooltip"]}>
												<Tooltip>
													<Tooltip.Body>Social Emotional</Tooltip.Body>
												</Tooltip>

												<Icon.Info size="1.25rem" style={{ display: "block" }} />
											</Tooltip.Container>
										</Button>
									</If>
								</Column>
							</If>

							<If
								condition={
									allNoteTypes ||
									noteTypeSet.has(NoteNoteType.Health) ||
									noteTypeSet.has(NoteNoteType.Incident) ||
									noteTypeSet.has(NoteNoteType.Injury) ||
									noteTypeSet.has(NoteNoteType.Attendance)
								}
							>
								<Column justify="spaced-start" verticalSpacing="0.25rem" align="center" style={{ width: "100%" }}>
									<If condition={allNoteTypes || noteTypeSet.has(NoteNoteType.Health)}>
										<Button
											size="large"
											onClick={() => setNoteType(NoteNoteType.Health)}
											disabled={buttonsDisabled}
											className={styles["note-type-button"]}
										>
											Health
											<Tooltip.Container className={styles["note-type-button__info-tooltip"]}>
												<Tooltip>
													<Tooltip.Body>Health</Tooltip.Body>
												</Tooltip>

												<Icon.Info size="1.25rem" style={{ display: "block" }} />
											</Tooltip.Container>
										</Button>
									</If>
									<If condition={allNoteTypes || noteTypeSet.has(NoteNoteType.Incident)}>
										<Button
											size="large"
											onClick={() => setNoteType(NoteNoteType.Incident)}
											disabled={buttonsDisabled}
											className={styles["note-type-button"]}
										>
											Incident
											<Tooltip.Container className={styles["note-type-button__info-tooltip"]}>
												<Tooltip>
													<Tooltip.Body>Incident</Tooltip.Body>
												</Tooltip>

												<Icon.Info size="1.25rem" style={{ display: "block" }} />
											</Tooltip.Container>
										</Button>
									</If>
									<If condition={allNoteTypes || noteTypeSet.has(NoteNoteType.Injury)}>
										<Button
											size="large"
											onClick={() => setNoteType(NoteNoteType.Injury)}
											disabled={buttonsDisabled}
											className={styles["note-type-button"]}
										>
											Injury
											<Tooltip.Container className={styles["note-type-button__info-tooltip"]}>
												<Tooltip>
													<Tooltip.Body>Injury</Tooltip.Body>
												</Tooltip>

												<Icon.Info size="1.25rem" style={{ display: "block" }} />
											</Tooltip.Container>
										</Button>
									</If>
									<If condition={allNoteTypes || noteTypeSet.has(NoteNoteType.Attendance)}>
										<Button
											size="large"
											onClick={() => setNoteType(NoteNoteType.Attendance)}
											disabled={buttonsDisabled}
											className={styles["note-type-button"]}
										>
											Attendance
											<Tooltip.Container className={styles["note-type-button__info-tooltip"]}>
												<Tooltip>
													<Tooltip.Body>Attendance</Tooltip.Body>
												</Tooltip>

												<Icon.Info size="1.25rem" style={{ display: "block" }} />
											</Tooltip.Container>
										</Button>
									</If>
								</Column>
							</If>
						</Column>
					</Column>
				</Modal.Body>
			</Modal>
		);
	}

	if (noteType === NoteNoteType.Academic) {
		return (
			<AcademicNoteFormModal
				studentID={formState.formValues.studentID}
				body={formState.formValues.body}
				setBody={formState.formSetFunctions.body}
				onClose={onClose}
				onBack={onBack}
			/>
		);
	}
	if (noteType === NoteNoteType.Attendance) {
		return (
			<AttendanceNoteFormModal
				studentID={formState.formValues.studentID}
				body={formState.formValues.body}
				setBody={formState.formSetFunctions.body}
				onClose={onClose}
				onBack={onBack}
			/>
		);
	}
	if (noteType === NoteNoteType.Behavior) {
		return (
			<BehaviorNoteFormModal
				studentID={formState.formValues.studentID}
				body={formState.formValues.body}
				setBody={formState.formSetFunctions.body}
				onClose={onClose}
				onBack={onBack}
			/>
		);
	}
	if (noteType === NoteNoteType.General) {
		return (
			<GeneralNoteFormModal
				studentID={formState.formValues.studentID}
				body={formState.formValues.body}
				setBody={formState.formSetFunctions.body}
				onClose={onClose}
				onBack={onBack}
			/>
		);
	}
	if (noteType === NoteNoteType.Health) {
		return (
			<HealthNoteFormModal
				studentID={formState.formValues.studentID}
				body={formState.formValues.body}
				setBody={formState.formSetFunctions.body}
				onClose={onClose}
				onBack={onBack}
			/>
		);
	}
	if (noteType === NoteNoteType.Incident) {
		return (
			<IncidentNoteFormModal
				studentID={formState.formValues.studentID}
				body={formState.formValues.body}
				setBody={formState.formSetFunctions.body}
				onClose={onClose}
				onBack={onBack}
			/>
		);
	}
	if (noteType === NoteNoteType.Injury) {
		return (
			<InjuryNoteFormModal
				studentID={formState.formValues.studentID}
				body={formState.formValues.body}
				setBody={formState.formSetFunctions.body}
				onClose={onClose}
				onBack={onBack}
			/>
		);
	}
	if (noteType === NoteNoteType.SocialEmotional) {
		return (
			<SocialEmotionalNoteFormModal
				studentID={formState.formValues.studentID}
				body={formState.formValues.body}
				setBody={formState.formSetFunctions.body}
				onClose={onClose}
				onBack={onBack}
			/>
		);
	}

	return null;
}

type SpecificAcademicNoteFormModalProps = {
	studentID: Student["id"] | null;
	body: string;
	setBody: (action: React.SetStateAction<string>) => void;
	onBack?: (() => void) | null;
} & RequiredKeys<Pick<ModalProps, "ifRef" | "onClose">, "onClose">;

function AcademicNoteFormModal({
	studentID,
	body,
	setBody,
	onBack,
	ifRef,
	onClose,
}: SpecificAcademicNoteFormModalProps) {
	const initialFormValues = React.useMemo(
		() => ({ studentIDs: studentID ? [studentID] : [], body }),
		[studentID, body],
	);
	const formState = NoteFormState.Academic.useCreateFormState(initialFormValues);

	React.useEffect(() => {
		setBody(formState.formValues.body);
	}, [formState.formValues.body, setBody]);

	const create = NoteMutation.useCreate();

	const applyCreate = React.useCallback(
		async (formValues: NoteFormValues.Academic.Create) => {
			const inputFormValues = NoteFormConversion.academicToCreateFormValues(formValues);
			const { errors } = await create(inputFormValues);
			return errors;
		},
		[create],
	);

	const onSuccess = React.useCallback(() => {
		onClose();
	}, [onClose]);

	return (
		<Modal ifRef={ifRef} onClose={onClose} confirmOnClose style={{ width: "fit-content" }}>
			<Modal.Header>
				<Row justify="spaced-start">
					{!!onBack && (
						<Button variant="link" onClick={onBack} style={{ color: "#fff" }}>
							&lt; Back
						</Button>
					)}
					<Heading level={2} noMargin>
						Academic Report
					</Heading>
				</Row>
			</Modal.Header>
			<Modal.Body style={{ paddingLeft: "2rem", paddingRight: "2rem" }}>
				<NoteForm.Academic.Create formState={formState} applyCreate={applyCreate} onSuccess={onSuccess} />
			</Modal.Body>
		</Modal>
	);
}

function AttendanceNoteFormModal({
	studentID,
	body,
	setBody,
	onBack,
	ifRef,
	onClose,
}: SpecificAcademicNoteFormModalProps) {
	const initialFormValues = React.useMemo(
		() => ({ startDate: toLocalDateString(new Date()), studentIDs: studentID ? [studentID] : [], body }),
		[studentID, body],
	);
	const formState = NoteFormState.Attendance.useCreateFormState(initialFormValues);

	React.useEffect(() => {
		setBody(formState.formValues.body);
	}, [formState.formValues.body, setBody]);

	const create = NoteMutation.useCreate();

	const applyCreate = React.useCallback(
		async (formValues: NoteFormValues.Attendance.Create) => {
			const inputFormValues = NoteFormConversion.attendanceToCreateFormValues(formValues);
			const { errors } = await create(inputFormValues);
			return errors;
		},
		[create],
	);

	const onSuccess = React.useCallback(() => {
		onClose();
	}, [onClose]);

	return (
		<Modal ifRef={ifRef} onClose={onClose} confirmOnClose style={{ width: "fit-content" }}>
			<Modal.Header>
				<Row justify="spaced-start">
					{!!onBack && (
						<Button variant="link" onClick={onBack} style={{ color: "#fff" }}>
							&lt; Back
						</Button>
					)}
					<Heading level={2} noMargin>
						Attendance Note
					</Heading>
				</Row>
			</Modal.Header>
			<Modal.Body style={{ paddingLeft: "2rem", paddingRight: "2rem" }}>
				<NoteForm.Attendance.Create formState={formState} applyCreate={applyCreate} onSuccess={onSuccess} />
			</Modal.Body>
		</Modal>
	);
}

function BehaviorNoteFormModal({
	studentID,
	body,
	setBody,
	onBack,
	ifRef,
	onClose,
}: SpecificAcademicNoteFormModalProps) {
	const initialFormValues = React.useMemo(
		() => ({ studentIDs: studentID ? [studentID] : [], body }),
		[studentID, body],
	);
	const formState = NoteFormState.Behavior.useCreateFormState(initialFormValues);

	React.useEffect(() => {
		setBody(formState.formValues.body);
	}, [formState.formValues.body, setBody]);

	const create = NoteMutation.useCreate();

	const applyCreate = React.useCallback(
		async (formValues: NoteFormValues.Behavior.Create) => {
			const inputFormValues = NoteFormConversion.behaviorToCreateFormValues(formValues);
			const { errors } = await create(inputFormValues);
			return errors;
		},
		[create],
	);

	const onSuccess = React.useCallback(() => {
		onClose();
	}, [onClose]);

	return (
		<Modal ifRef={ifRef} onClose={onClose} confirmOnClose style={{ width: "fit-content" }}>
			<Modal.Header>
				<Row justify="spaced-start">
					{!!onBack && (
						<Button variant="link" onClick={onBack} style={{ color: "#fff" }}>
							&lt; Back
						</Button>
					)}
					<Heading level={2} noMargin>
						Behavior Report
					</Heading>
				</Row>
			</Modal.Header>
			<Modal.Body style={{ paddingLeft: "2rem", paddingRight: "2rem" }}>
				<NoteForm.Behavior.Create formState={formState} applyCreate={applyCreate} onSuccess={onSuccess} />
			</Modal.Body>
		</Modal>
	);
}

export function FinancialNoteFormModal({
	studentID,
	body,
	setBody,
	onBack,
	ifRef,
	onClose,
}: SpecificAcademicNoteFormModalProps) {
	const initialFormValues = React.useMemo(
		() => ({ studentIDs: studentID ? [studentID] : [], body }),
		[studentID, body],
	);
	const formState = NoteFormState.Financial.useCreateFormState(initialFormValues);

	React.useEffect(() => {
		setBody(formState.formValues.body);
	}, [formState.formValues.body, setBody]);

	const create = NoteMutation.useCreate();

	const applyCreate = React.useCallback(
		async (formValues: NoteFormValues.Financial.Create) => {
			const inputFormValues = NoteFormConversion.financialToCreateFormValues(formValues);
			const { errors } = await create(inputFormValues);
			return errors;
		},
		[create],
	);

	const onSuccess = React.useCallback(() => {
		onClose();
	}, [onClose]);

	return (
		<Modal ifRef={ifRef} onClose={onClose} confirmOnClose style={{ width: "fit-content" }}>
			<Modal.Header>
				<Row justify="spaced-start">
					{!!onBack && (
						<Button variant="link" onClick={onBack} style={{ color: "#fff" }}>
							&lt; Back
						</Button>
					)}
					<Heading level={2} noMargin>
						Financial Note
					</Heading>
				</Row>
			</Modal.Header>
			<Modal.Body style={{ paddingLeft: "2rem", paddingRight: "2rem" }}>
				<NoteForm.Financial.Create formState={formState} applyCreate={applyCreate} onSuccess={onSuccess} />
			</Modal.Body>
		</Modal>
	);
}

export function GeneralNoteFormModal({
	studentID,
	body,
	setBody,
	onBack,
	ifRef,
	onClose,
}: SpecificAcademicNoteFormModalProps) {
	const initialFormValues = React.useMemo(
		() => ({ studentIDs: studentID ? [studentID] : [], body }),
		[studentID, body],
	);
	const formState = NoteFormState.General.useCreateFormState(initialFormValues);

	React.useEffect(() => {
		setBody(formState.formValues.body);
	}, [formState.formValues.body, setBody]);

	const create = NoteMutation.useCreate();

	const applyCreate = React.useCallback(
		async (formValues: NoteFormValues.General.Create) => {
			const inputFormValues = NoteFormConversion.generalToCreateFormValues(formValues);
			const { errors } = await create(inputFormValues);
			return errors;
		},
		[create],
	);

	const onSuccess = React.useCallback(() => {
		onClose();
	}, [onClose]);

	return (
		<Modal ifRef={ifRef} onClose={onClose} confirmOnClose style={{ width: "fit-content" }}>
			<Modal.Header>
				<Row justify="spaced-start">
					{!!onBack && (
						<Button variant="link" onClick={onBack} style={{ color: "#fff" }}>
							&lt; Back
						</Button>
					)}
					<Heading level={2} noMargin>
						General Note
					</Heading>
				</Row>
			</Modal.Header>
			<Modal.Body style={{ paddingLeft: "2rem", paddingRight: "2rem" }}>
				<NoteForm.General.Create formState={formState} applyCreate={applyCreate} onSuccess={onSuccess} />
			</Modal.Body>
		</Modal>
	);
}

function HealthNoteFormModal({ studentID, body, setBody, onBack, ifRef, onClose }: SpecificAcademicNoteFormModalProps) {
	const initialFormValues = React.useMemo(
		() => ({ studentIDs: studentID ? [studentID] : [], body }),
		[studentID, body],
	);
	const formState = NoteFormState.Health.useCreateFormState(initialFormValues);

	React.useEffect(() => {
		setBody(formState.formValues.body);
	}, [formState.formValues.body, setBody]);

	const create = NoteMutation.useCreate();

	const applyCreate = React.useCallback(
		async (formValues: NoteFormValues.Health.Create) => {
			const inputFormValues = NoteFormConversion.healthToCreateFormValues(formValues);
			const { errors } = await create(inputFormValues);
			return errors;
		},
		[create],
	);

	const onSuccess = React.useCallback(() => {
		onClose();
	}, [onClose]);

	return (
		<Modal ifRef={ifRef} onClose={onClose} confirmOnClose style={{ width: "fit-content" }}>
			<Modal.Header>
				<Row justify="spaced-start">
					{!!onBack && (
						<Button variant="link" onClick={onBack} style={{ color: "#fff" }}>
							&lt; Back
						</Button>
					)}
					<Heading level={2} noMargin>
						Health Note
					</Heading>
				</Row>
			</Modal.Header>
			<Modal.Body style={{ paddingLeft: "2rem", paddingRight: "2rem" }}>
				<NoteForm.Health.Create formState={formState} applyCreate={applyCreate} onSuccess={onSuccess} />
			</Modal.Body>
		</Modal>
	);
}

function IncidentNoteFormModal({
	studentID,
	body,
	setBody,
	onBack,
	ifRef,
	onClose,
}: SpecificAcademicNoteFormModalProps) {
	const initialFormValues = React.useMemo(
		() => ({ studentIDs: studentID ? [studentID] : [], body }),
		[studentID, body],
	);
	const formState = NoteFormState.Incident.useCreateFormState(initialFormValues);

	React.useEffect(() => {
		setBody(formState.formValues.body);
	}, [formState.formValues.body, setBody]);

	const create = NoteMutation.useCreate();

	const applyCreate = React.useCallback(
		async (formValues: NoteFormValues.Incident.Create) => {
			const inputFormValues = NoteFormConversion.incidentToCreateFormValues(formValues);
			const { errors } = await create(inputFormValues);
			return errors;
		},
		[create],
	);

	const onSuccess = React.useCallback(() => {
		onClose();
	}, [onClose]);

	return (
		<Modal ifRef={ifRef} onClose={onClose} confirmOnClose style={{ width: "fit-content" }}>
			<Modal.Header>
				<Row justify="spaced-start">
					{!!onBack && (
						<Button variant="link" onClick={onBack} style={{ color: "#fff" }}>
							&lt; Back
						</Button>
					)}
					<Heading level={2} noMargin>
						Incident Note
					</Heading>
				</Row>
			</Modal.Header>
			<Modal.Body style={{ paddingLeft: "2rem", paddingRight: "2rem" }}>
				<NoteForm.Incident.Create formState={formState} applyCreate={applyCreate} onSuccess={onSuccess} />
			</Modal.Body>
		</Modal>
	);
}

function InjuryNoteFormModal({ studentID, body, setBody, onBack, ifRef, onClose }: SpecificAcademicNoteFormModalProps) {
	const initialFormValues = React.useMemo(
		() => ({ studentIDs: studentID ? [studentID] : [], body }),
		[studentID, body],
	);
	const formState = NoteFormState.Injury.useCreateFormState(initialFormValues);

	React.useEffect(() => {
		setBody(formState.formValues.body);
	}, [formState.formValues.body, setBody]);

	const create = NoteMutation.useCreate();

	const applyCreate = React.useCallback(
		async (formValues: NoteFormValues.Injury.Create) => {
			const inputFormValues = NoteFormConversion.injuryToCreateFormValues(formValues);
			const { errors } = await create(inputFormValues);
			return errors;
		},
		[create],
	);

	const onSuccess = React.useCallback(() => {
		onClose();
	}, [onClose]);

	return (
		<Modal ifRef={ifRef} onClose={onClose} confirmOnClose style={{ width: "fit-content" }}>
			<Modal.Header>
				<Row justify="spaced-start">
					{!!onBack && (
						<Button variant="link" onClick={onBack} style={{ color: "#fff" }}>
							&lt; Back
						</Button>
					)}
					<Heading level={2} noMargin>
						Injury Note
					</Heading>
				</Row>
			</Modal.Header>
			<Modal.Body style={{ paddingLeft: "2rem", paddingRight: "2rem" }}>
				<NoteForm.Injury.Create formState={formState} applyCreate={applyCreate} onSuccess={onSuccess} />
			</Modal.Body>
		</Modal>
	);
}

function SocialEmotionalNoteFormModal({
	studentID,
	body,
	setBody,
	onBack,
	ifRef,
	onClose,
}: SpecificAcademicNoteFormModalProps) {
	const initialFormValues = React.useMemo(
		() => ({ studentIDs: studentID ? [studentID] : [], body }),
		[studentID, body],
	);
	const formState = NoteFormState.SocialEmotional.useCreateFormState(initialFormValues);

	React.useEffect(() => {
		setBody(formState.formValues.body);
	}, [formState.formValues.body, setBody]);

	const create = NoteMutation.useCreate();

	const applyCreate = React.useCallback(
		async (formValues: NoteFormValues.SocialEmotional.Create) => {
			const inputFormValues = NoteFormConversion.socialEmotionalToCreateFormValues(formValues);
			const { errors } = await create(inputFormValues);
			return errors;
		},
		[create],
	);

	const onSuccess = React.useCallback(() => {
		onClose();
	}, [onClose]);

	return (
		<Modal ifRef={ifRef} onClose={onClose} confirmOnClose style={{ width: "fit-content" }}>
			<Modal.Header>
				<Row justify="spaced-start">
					{!!onBack && (
						<Button variant="link" onClick={onBack} style={{ color: "#fff" }}>
							&lt; Back
						</Button>
					)}
					<Heading level={2} noMargin>
						Social Emotional Report
					</Heading>
				</Row>
			</Modal.Header>
			<Modal.Body style={{ paddingLeft: "2rem", paddingRight: "2rem" }}>
				<NoteForm.SocialEmotional.Create formState={formState} applyCreate={applyCreate} onSuccess={onSuccess} />
			</Modal.Body>
		</Modal>
	);
}
