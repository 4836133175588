import React from "react";
import { ChildrenProps, ClassNameProps, makeClassName, StyleProps, useToggle } from "@hex-insights/core";
import styles from "./styles.module.css";

export type VerticallyExpandingContainerProps = {
	containerRef: React.MutableRefObject<HTMLDivElement | null>;
} & Partial<ClassNameProps & StyleProps & ChildrenProps>;

export function VerticallyExpandingContainer({
	containerRef,
	className,
	style,
	children,
}: VerticallyExpandingContainerProps) {
	return (
		<div ref={containerRef} className={makeClassName(styles["container"], className)} style={style}>
			{children}
		</div>
	);
}

export function useVerticallyExpandingElement({ onOpen, onClose }: { onOpen?: () => void; onClose?: () => void } = {}) {
	const { isOn: isExpanded, toggle: toggleIsExpanded } = useToggle(false);

	const containerRef = React.useRef<HTMLDivElement | null>(null);
	const [expandedHeight, setExpandedHeight] = React.useState("auto");

	const onOpenClick = React.useCallback(() => {
		toggleIsExpanded();
		if (onOpen) {
			onOpen();
		}
		if (containerRef.current === null || containerRef.current.previousSibling === null) {
			return;
		}

		const { bottom } = containerRef.current.getBoundingClientRect();
		const { top } = (containerRef.current.previousSibling as Element).getBoundingClientRect();
		setExpandedHeight(`${bottom - top}px`);
	}, [toggleIsExpanded, onOpen]);

	const onCloseClick = React.useCallback(() => {
		toggleIsExpanded();
		if (onClose) {
			onClose();
		}
	}, [toggleIsExpanded, onClose]);

	const expandingClassName = makeClassName(styles["expanding"], isExpanded ? styles["expanding--expanded"] : "");
	const expandingStyle = { "--expanded-height": expandedHeight } as React.CSSProperties;

	return { containerRef, isExpanded, onOpenClick, onCloseClick, expandingClassName, expandingStyle };
}
