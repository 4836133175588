import React from "react";
import { ApolloCache, FetchResult, gql } from "@apollo/client";
import {
	graphTypeNames,
	Person,
	PhoneNumber,
	PhoneNumberCreateInput,
	PhoneNumberCreateMutation,
	PhoneNumberDeleteMutation,
	PhoneNumberDetailDocument,
	PhoneNumberDetailQuery,
	PhoneNumberDetailQueryVariables,
	PhoneNumberUpdateInput,
	PhoneNumberUpdateMutation,
	usePhoneNumberCreateMutation,
	usePhoneNumberDeleteMutation,
	usePhoneNumberUpdateMutation,
} from "../../GraphQL";
import { PhoneNumberFormConversion } from "../ModelFormConversion";
import { PhoneNumberFormValues } from "../ModelFormValues";

/**
 * Returns a `create` function for the PhoneNumber model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the PhoneNumber model.
 */
export function useCreate() {
	const [createPhoneNumber] = usePhoneNumberCreateMutation();

	return React.useCallback(
		async (formValues: PhoneNumberFormValues.Create) => {
			const input = PhoneNumberFormConversion.toGQLCreateInput(formValues);
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createPhoneNumber({ variables: { input }, update: updateCache });

			return { data: data?.createPhoneNumber ?? null, errors: errors ?? null };
		},
		[createPhoneNumber],
	);
}

export function useCreateInPerson() {
	const [createPhoneNumber] = usePhoneNumberCreateMutation();

	return React.useCallback(
		async (formValues: PhoneNumberFormValues.CreateInPerson) => {
			const input = PhoneNumberFormConversion.toGQLCreateInput({ ...formValues, personID: null });
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createPhoneNumber({ variables: { input }, update: updateCache });

			return { data: data?.createPhoneNumber ?? null, errors: errors ?? null };
		},
		[createPhoneNumber],
	);
}

/**
 * Returns an `update` function for the PhoneNumber model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the PhoneNumber model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: PhoneNumber["id"]) {
	const [updatePhoneNumber] = usePhoneNumberUpdateMutation();

	return React.useCallback(
		async (
			changedFormValues: Partial<PhoneNumberFormValues.Detail>,
			initialFormValues: PhoneNumberFormValues.Detail,
		) => {
			const input = PhoneNumberFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

			const { data, errors } = await updatePhoneNumber({ variables: { id, input }, update: updateCache });

			return { data: data?.updatePhoneNumber ?? null, errors: errors ?? null };
		},
		[updatePhoneNumber, id],
	);
}

/**
 * Returns a `del` function for the PhoneNumber model.
 *
 * @param id The ID of the instance to delete.
 */
export function useDelete(id: PhoneNumber["id"]) {
	const [deletePhoneNumber] = usePhoneNumberDeleteMutation();

	return React.useCallback(async () => {
		const updateCache = getUpdateCacheForDelete(id);

		const { data, errors } = await deletePhoneNumber({ variables: { id }, update: updateCache });

		return { data: data?.deletePhoneNumber ?? false, errors: errors ?? null };
	}, [deletePhoneNumber, id]);
}

function getUpdateCacheForCreate(input: PhoneNumberCreateInput) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<PhoneNumberCreateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const createdObject = result.data.createPhoneNumber;

		cache.writeQuery<PhoneNumberDetailQuery, PhoneNumberDetailQueryVariables>({
			query: PhoneNumberDetailDocument,
			data: { phoneNumber: createdObject },
			variables: { id: createdObject.id },
		});

		if (input.personID) {
			addToPhoneNumbersOfPersonCache(cache, input.personID, createdObject);
		}

		cache.evict({ id: "ROOT_QUERY", fieldName: "phoneNumberConnection" });
	};
}

function getUpdateCacheForUpdate(input: PhoneNumberUpdateInput, initialFormValues: PhoneNumberFormValues.Detail) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<PhoneNumberUpdateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const updatedObject = result.data.updatePhoneNumber;

		if (initialFormValues.personID && input.personID) {
			removeFromPhoneNumbersOfPersonCache(cache, initialFormValues.personID, updatedObject);
		}
		if (input.personID) {
			addToPhoneNumbersOfPersonCache(cache, input.personID, updatedObject);
		}
	};
}

function getUpdateCacheForDelete(id: PhoneNumber["id"]) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<PhoneNumberDeleteMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (!result.data?.deletePhoneNumber) {
			return;
		}

		cache.evict({ id: cache.identify({ id, __typename: graphTypeNames.PhoneNumber }) });
		cache.evict({ id: "ROOT_QUERY", fieldName: "phoneNumberConnection" });
		cache.gc();
	};
}

function addToPhoneNumbersOfPersonCache(
	cache: ApolloCache<any>,
	targetID: Person["id"],
	object: Pick<PhoneNumber, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Person }),
		fields: {
			phoneNumbers: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromPhoneNumbersOfPersonCache(
	cache: ApolloCache<any>,
	targetID: Person["id"],
	object: Pick<PhoneNumber, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Person }),
		fields: {
			phoneNumbers: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function toCacheRef(cache: ApolloCache<any>, object: Pick<PhoneNumber, "id">) {
	return cache.writeFragment({
		fragment: gql`
			fragment PhoneNumberRef on PhoneNumber {
				id
			}
		`,
		data: object,
	});
}
