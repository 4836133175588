import { pickKeys } from "@hex-insights/core";
import {
	Address,
	EmailAddress,
	Employee,
	Parent,
	Person,
	PersonEnglishLanguageFluency,
	PhoneNumber,
	Student,
	User,
} from "../../GraphQL";
import * as EmailAddressFormValues from "./email-address";
import * as PhoneNumberFormValues from "./phone-number";

export type Base = {
	firstName: Person["firstName"];
	lastName: Person["lastName"];
	nickname: Person["nickname"];
	alternateNames: Person["alternateNames"];
	image: File | null;
	gender: Person["gender"];
	dateOfBirth: Person["dateOfBirth"] | null;
	occupation: Person["occupation"];
	nationality: Person["nationality"];
	primaryLanguage: Person["primaryLanguage"];
	englishLanguageFluency: Person["englishLanguageFluency"] | null;
	addressIDs: Address["id"][];
	userID: User["id"] | null;
};

export type Create = Base & {
	emailAddresses: EmailAddressFormValues.CreateInPerson[];
	phoneNumbers: PhoneNumberFormValues.CreateInPerson[];
};

export type Detail = Base & {
	name: Person["name"];
	emailAddressIDs: EmailAddress["id"][];
	phoneNumberIDs: PhoneNumber["id"][];
	employeeID: Employee["id"] | null;
	studentID: Student["id"] | null;
	parentID: Parent["id"] | null;
};

export const initialCreate: Create = {
	firstName: "",
	lastName: "",
	nickname: "",
	alternateNames: "",
	image: null,
	gender: "",
	dateOfBirth: null,
	occupation: "",
	nationality: "",
	primaryLanguage: "",
	englishLanguageFluency: null,
	addressIDs: [],
	emailAddresses: [],
	phoneNumbers: [],
	userID: null,
};

export const englishLanguageFluencyOptions = [
	{ value: PersonEnglishLanguageFluency.None },
	{ value: PersonEnglishLanguageFluency.Basic },
	{ value: PersonEnglishLanguageFluency.Average },
	{ value: PersonEnglishLanguageFluency.Excellent },
];

export type CreateInOther = Omit<Create, "employeeID" | "parentID" | "studentID">;

export const initialCreateInOther: CreateInOther = initialCreate;

export const createInOtherKeys: (keyof CreateInOther)[] = [
	"firstName",
	"lastName",
	"nickname",
	"alternateNames",
	"image",
	"gender",
	"dateOfBirth",
	"occupation",
	"nationality",
	"primaryLanguage",
	"englishLanguageFluency",
	"addressIDs",
	"emailAddresses",
	"phoneNumbers",
	"userID",
];

export function pickCreateInOtherFormValues<FV extends CreateInOther>(formValues: FV): CreateInOther {
	return pickKeys(formValues, createInOtherKeys);
}

export type DetailInOther = Omit<Detail, "employeeID" | "parentID" | "studentID">;

export const detailInOtherKeys: (keyof DetailInOther)[] = [
	"firstName",
	"lastName",
	"nickname",
	"alternateNames",
	"image",
	"gender",
	"dateOfBirth",
	"occupation",
	"nationality",
	"primaryLanguage",
	"englishLanguageFluency",
	"addressIDs",
	"userID",
	"name",
	"emailAddressIDs",
	"phoneNumberIDs",
];

export function pickDetailInOtherFormValues<FV extends Partial<DetailInOther>>(
	formValues: FV,
): Pick<FV, keyof DetailInOther> {
	return pickKeys(formValues, detailInOtherKeys);
}
