import React from "react";
import {
	Button,
	ChildrenProps,
	ClassNameProps,
	Column,
	Icon,
	makeClassName,
	Row,
	StyleProps,
	useEventListener,
} from "@hex-insights/core";
import styles from "./styles.module.css";

export type HorizontalScrollingContainerProps = {
	backgroundColor?: string;
} & Partial<ClassNameProps & StyleProps & ChildrenProps>;

export function HorizontalScrollingContainer({
	backgroundColor = "#fff",
	className,
	style,
	children,
}: HorizontalScrollingContainerProps) {
	const ref = React.useRef<HTMLDivElement | null>(null);

	const onScrollLeftClick = React.useCallback(() => {
		if (!ref.current) {
			return;
		}
		ref.current.scrollLeft -= ref.current.getBoundingClientRect().width * 0.75;
	}, []);
	const onScrollRightClick = React.useCallback(() => {
		if (!ref.current) {
			return;
		}
		ref.current.scrollLeft += ref.current.getBoundingClientRect().width * 0.75;
	}, []);

	const [isFullLeft, setIsFullLeft] = React.useState(true);
	const [isFullRight, setIsFullRight] = React.useState(true);
	const calculateBounds = React.useCallback(() => {
		if (!ref.current) {
			return;
		}
		setIsFullLeft(ref.current.scrollLeft === 0);
		setIsFullRight(ref.current.scrollLeft + ref.current.clientWidth === ref.current.scrollWidth);
	}, []);
	useEventListener(ref.current, "scroll", calculateBounds);
	React.useEffect(() => {
		window.setTimeout(calculateBounds, 0);
	}, [calculateBounds, children]);

	return (
		<div className={makeClassName(styles["position-wrapper"], className)} style={style}>
			<div
				ref={ref}
				className={makeClassName(
					styles["horizontal-scrolling-container"],
					"no-scroll-bar",
					isFullLeft ? styles["horizontal-scrolling-container--full-left"] : "",
					isFullRight ? styles["horizontal-scrolling-container--full-right"] : "",
				)}
				style={{ "--background-color": backgroundColor } as React.CSSProperties}
			>
				<Row justify="spaced-start" align="center">
					<div className={styles["scroll-button-container"] + " " + styles["scroll-button-container--left"]}>
						<Button variant="link" onClick={onScrollLeftClick} className={styles["scroll-button"]}>
							<Column justify="center" className={styles["scroll-button__column"]}>
								<div className={styles["scroll-button__icon-container"]}>
									<Icon.ChevronLeft size="1.5rem" className={styles["scroll-button__icon"]} />
								</div>
							</Column>
						</Button>
					</div>

					{children}

					<div className={styles["scroll-button-container"] + " " + styles["scroll-button-container--right"]}>
						<Button variant="link" onClick={onScrollRightClick} className={styles["scroll-button"]}>
							<Column justify="center" className={styles["scroll-button__column"]}>
								<div className={styles["scroll-button__icon-container"]}>
									<Icon.ChevronRight size="1.5rem" className={styles["scroll-button__icon"]} />
								</div>
							</Column>
						</Button>
					</div>
				</Row>
			</div>
		</div>
	);
}
