import { formatDateTime, stringToLocalDate } from "@hex-insights/core";
import { Term } from "../../../GraphQL";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the name field of the Term model.
 */
export function name(value: Term["name"]): string {
	return toString(value);
}

/**
 * Formats the startDate field of the Term model.
 */
export function startDate(value: Term["startDate"] | null): string {
	if (value === null) {
		return toString(value);
	}
	return formatDateTime(stringToLocalDate(value, "day"), "D MMM YYYY");
}

/**
 * Formats the endDate field of the Term model.
 */
export function endDate(value: Term["endDate"] | null): string {
	if (value === null) {
		return toString(value);
	}
	return formatDateTime(stringToLocalDate(value, "day"), "D MMM YYYY");
}
