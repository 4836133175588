import { staticURL } from "../Utilities";

export const leslieWolper = staticURL("/images/leslie-wolper.png");
export const veritaEarlyYearsCampusBlob = staticURL("/images/verita-early-years-campus-blob.png");
export const veritaPlants = staticURL("/images/verita-plants.png");
export const veritaPlantsCropped = staticURL("/images/verita-plants-cropped.png");
export const veritaPosts1 = staticURL("/images/verita-posts-1.png");
export const veritaPosts2 = staticURL("/images/verita-posts-2.png");
export const veritaPosts3 = staticURL("/images/verita-posts-3.png");
export const veritaPosts4 = staticURL("/images/verita-posts-4.png");
export const veritaPosts5 = staticURL("/images/verita-posts-5.png");
export const veritaPosts6 = staticURL("/images/verita-posts-6.png");
export const veritaPosts7 = staticURL("/images/verita-posts-7.png");
export const veritaPrimarySchoolCampusBlob = staticURL("/images/verita-primary-school-campus-blob.png");
export const veritaWingsLogoGradient = staticURL("/images/verita-wings-logo--gradient.png");
export const veritaWingsLogoWhite = staticURL("/images/verita-wings-logo--white.png");
export const veritaWingsLogoAndWordmark = staticURL("/images/verita-wings-logo-and-wordmark.png");
