import React from "react";
import { Column, Conditional, Else, Heading, If, Paragraph, Row } from "@hex-insights/core";
import { FormType, Label, useFormState } from "@hex-insights/forms";
import { BasicTable } from "@hex-insights/tables";
import {
	formatPrice,
	InvoiceDetailQuery,
	InvoiceFormConversion,
	InvoiceLineItemFormat,
	PaymentFormat,
	PaymentLineItemFormat,
} from "../../Utilities";
import { InvoiceField } from "../Forms";

export type InvoiceDisplayProps = {
	invoice: InvoiceDetailQuery["invoice"];
};

export function InvoiceDisplay({ invoice }: InvoiceDisplayProps) {
	const initialFormValues = React.useMemo(() => InvoiceFormConversion.toFormValues(invoice), [invoice]);
	const formState = useFormState({ initialFormValues, formType: FormType.View });

	const totalLineItemAmount = invoice.invoiceLineItems.reduce((a, e) => a + e.amount, 0);
	const totalPaymentAmount = invoice.paymentLineItems.reduce((a, e) => a + e.amount, 0);

	return (
		<Column justify="spaced-start">
			{/* TODO */}
			{/* <InvoiceField.Student formState={formState} currentStudent={invoice.student} /> */}
			<Row justify="spaced-start">
				<InvoiceField.CreatedDate formState={formState} />
				<InvoiceField.DueDate formState={formState} />
			</Row>
			<Row justify="space-between">
				<Column>
					<Label>Total Due</Label>
					<span style={{ padding: "0 0.5rem", fontSize: "1.25rem" }}>{formatPrice(totalLineItemAmount)}</span>
				</Column>
				<Column>
					<Label>Total Paid</Label>
					<span style={{ padding: "0 0.5rem", fontSize: "1.25rem" }}>{formatPrice(totalPaymentAmount)}</span>
				</Column>
				<Column>
					<Label>Amount Outstanding</Label>
					<span style={{ padding: "0 0.5rem", fontSize: "1.25rem" }}>
						{formatPrice(totalLineItemAmount - totalPaymentAmount)}
					</span>
				</Column>
			</Row>
			<InvoiceField.Description formState={formState} style={{ width: "100%" }} />

			<Heading level={3} noMargin>
				Line Items
			</Heading>
			<BasicTable>
				<BasicTable.Header>
					<BasicTable.Row>
						<BasicTable.Heading>Description</BasicTable.Heading>
						<BasicTable.Heading>Amount</BasicTable.Heading>
					</BasicTable.Row>
				</BasicTable.Header>
				<BasicTable.Body>
					{invoice.invoiceLineItems.map((e) => (
						<BasicTable.Row key={e.id}>
							<BasicTable.Cell>{e.description}</BasicTable.Cell>
							<BasicTable.Cell numeric>{InvoiceLineItemFormat.Fields.amount(e.amount)}</BasicTable.Cell>
						</BasicTable.Row>
					))}
				</BasicTable.Body>
				<BasicTable.Footer>
					<BasicTable.Row>
						<BasicTable.Cell style={{ fontWeight: "bold" }}>Total</BasicTable.Cell>
						<BasicTable.Cell numeric style={{ fontWeight: "bold", borderTop: "1px solid #ddd" }}>
							{formatPrice(totalLineItemAmount)}
						</BasicTable.Cell>
					</BasicTable.Row>
				</BasicTable.Footer>
			</BasicTable>

			<Heading level={3} noMargin>
				Payments
			</Heading>
			<Conditional>
				<If condition={invoice.paymentLineItems.length > 0}>
					<BasicTable>
						<BasicTable.Header>
							<BasicTable.Row>
								<BasicTable.Heading>Date</BasicTable.Heading>
								<BasicTable.Heading>Payment Method</BasicTable.Heading>
								<BasicTable.Heading>Amount</BasicTable.Heading>
							</BasicTable.Row>
						</BasicTable.Header>
						<BasicTable.Body>
							{invoice.paymentLineItems.map((e) => (
								<BasicTable.Row key={e.id}>
									<BasicTable.Cell>{PaymentFormat.Fields.createdDate(e.payment.createdDate)}</BasicTable.Cell>
									<BasicTable.Cell>{PaymentFormat.Fields.paymentMethod(e.payment.paymentMethod)}</BasicTable.Cell>
									<BasicTable.Cell numeric>{PaymentLineItemFormat.Fields.amount(e.amount)}</BasicTable.Cell>
								</BasicTable.Row>
							))}
						</BasicTable.Body>
						<BasicTable.Footer>
							<BasicTable.Row>
								<BasicTable.Cell style={{ fontWeight: "bold" }}>Total</BasicTable.Cell>
								<BasicTable.Cell></BasicTable.Cell>
								<BasicTable.Cell numeric style={{ fontWeight: "bold", borderTop: "1px solid #ddd" }}>
									{formatPrice(totalPaymentAmount)}
								</BasicTable.Cell>
							</BasicTable.Row>
						</BasicTable.Footer>
					</BasicTable>
				</If>
				<Else>
					<Paragraph>No payments made yet.</Paragraph>
				</Else>
			</Conditional>
		</Column>
	);
}
