import { useActivePageRegistration, useRouteMatch, useRouteParams } from "@hex-insights/router";
import { StudentHub } from "@hex-insights/verita.shared";
import { studentHubDetailOverviewPageInfo, StudentHubDetailOverviewPageRouteParams } from "./pageinfo";

export function OverviewMain() {
	const { id } = useRouteParams<StudentHubDetailOverviewPageRouteParams>();
	useActivePageRegistration(studentHubDetailOverviewPageInfo);

	return <StudentHub.Detail.Overview.Main studentID={id} />;
}

export function GeneralInfoSection() {
	const { id } = useRouteParams<StudentHubDetailOverviewPageRouteParams>();
	const isOverview = !!useRouteMatch(studentHubDetailOverviewPageInfo.routeProps);

	return <StudentHub.Detail.Overview.GeneralInfoSection studentID={id} isExpanded={isOverview} />;
}
