import React from "react";
import {
	addTimeToDate,
	Button,
	Column,
	formatDateTime,
	Heading,
	Icon,
	Row,
	StyleProps,
	toLocalDateString,
} from "@hex-insights/core";
import { InternalLink } from "@hex-insights/router";
import { Tile, useVerticallyExpandingElement, VerticallyExpandingContainer } from "@hex-insights/verita.shared";
import { SlimDayCoursesCalendarAgenda } from "../../../../Components";
import { scheduleDayPageInfo } from "../../../Schedule/Day/pageinfo";

export type ScheduleTileProps = Partial<StyleProps>;

export function ScheduleTile({ style }: ScheduleTileProps) {
	const [date, setDate] = React.useState(new Date());

	const { containerRef, isExpanded, onOpenClick, onCloseClick, expandingClassName, expandingStyle } =
		useVerticallyExpandingElement({
			onClose: React.useCallback(() => setDate(new Date()), []),
		});

	const onPreviousDayClick = React.useCallback(() => {
		setDate((prev) => addTimeToDate(prev, [-1, "day"]).toDate());
	}, []);

	const onNextDayClick = React.useCallback(() => {
		setDate((prev) => addTimeToDate(prev, [1, "day"]).toDate());
	}, []);

	return (
		<VerticallyExpandingContainer containerRef={containerRef} style={style}>
			<Tile style={{ opacity: isExpanded ? 0 : 1, boxShadow: "none" }}>
				<Tile.Body style={{ padding: 0 }}>
					<Button variant="link" onClick={onOpenClick} style={{ width: "100%", padding: "0.65rem 0" }}>
						View Course Schedule
					</Button>
				</Tile.Body>
			</Tile>

			<Tile className={expandingClassName} style={{ ...expandingStyle, boxShadow: "none" }}>
				<Tile.Header>
					<Heading level={3} noMargin style={{ textAlign: "center" }}>
						{formatDateTime(date, "ddd, D MMM YYYY")}
					</Heading>
				</Tile.Header>
				<Tile.Body style={{ height: "100%", overflow: "scroll" }}>
					<Column>
						<Row justify="spaced-center" align="center" style={{ marginBottom: "0.25rem" }}>
							<Button variant="link" onClick={onPreviousDayClick} style={{ padding: 0 }}>
								<Icon.ArrowLeft size="0.75rem" /> Prev
							</Button>

							<InternalLink to={scheduleDayPageInfo.to(toLocalDateString(date))} style={{ textAlign: "center" }}>
								Go to Full Schedule
							</InternalLink>

							<Button variant="link" onClick={onNextDayClick} style={{ padding: 0 }}>
								Next <Icon.ArrowRight size="0.75rem" />
							</Button>
						</Row>

						<SlimDayCoursesCalendarAgenda date={date} />
					</Column>
				</Tile.Body>
				<Tile.Footer style={{ padding: 0 }}>
					<Button variant="link" onClick={onCloseClick} style={{ width: "100%", padding: "0.65rem 0" }}>
						Close
					</Button>
				</Tile.Footer>
			</Tile>
		</VerticallyExpandingContainer>
	);
}
