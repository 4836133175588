import { IndexForms } from "@hex-insights/app-modules";
import { CaseStyle, convertCase } from "@hex-insights/core";
import { NoteOrderField, OrderDirection } from "../../GraphQL";

export type FormValues = IndexForms.OrderFormValues<NoteOrderField | null>;

export const initial: FormValues = {
	field: NoteOrderField.CreatedAt,
	direction: OrderDirection.Desc,
};

/**
 * Options for the "field" field in the order form of the Note model.
 */
export const fieldOptions = [
	{ label: "ID", value: null },
	{ label: convertCase(NoteOrderField.NoteType.toLowerCase(), CaseStyle.Title), value: NoteOrderField.NoteType },
	{ label: convertCase(NoteOrderField.CreatedAt.toLowerCase(), CaseStyle.Title), value: NoteOrderField.CreatedAt },
	{ label: convertCase(NoteOrderField.StartDate.toLowerCase(), CaseStyle.Title), value: NoteOrderField.StartDate },
	{ label: convertCase(NoteOrderField.EndDate.toLowerCase(), CaseStyle.Title), value: NoteOrderField.EndDate },
];

/**
 * Array of values for the "field" field in the order form of the Note model.
 */
export const fieldValues = fieldOptions.map((e) => e.value);

/**
 * Mapping from order fields of the Note model to their corresponding order type.
 */
export const fieldTypeMapping = {
	[NoteOrderField.NoteType]: IndexForms.OrderFieldType.String,
	[NoteOrderField.CreatedAt]: IndexForms.OrderFieldType.Time,
	[NoteOrderField.StartDate]: IndexForms.OrderFieldType.Time,
	[NoteOrderField.EndDate]: IndexForms.OrderFieldType.Time,
};

/**
 * Mapping from order fields of the Note model to their corresponding field name.
 */
export const orderFieldToModelField = {
	[NoteOrderField.NoteType]: "noteType",
	[NoteOrderField.CreatedAt]: "createdAt",
	[NoteOrderField.StartDate]: "startDate",
	[NoteOrderField.EndDate]: "endDate",
};

/**
 * Mapping from field names of the Note model to their corresponding order field.
 */
export const modelFieldToOrderField = {
	noteType: NoteOrderField.NoteType,
	createdAt: NoteOrderField.CreatedAt,
	startDate: NoteOrderField.StartDate,
	endDate: NoteOrderField.EndDate,
};
