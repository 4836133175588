import { newPageInfo } from "@hex-insights/router";
import { scheduleDayPageInfo, ScheduleDayPageRouteParams } from "../pageinfo";

export type ScheduleDayIndexPageRouteParams = ScheduleDayPageRouteParams;

export const scheduleDayIndexPageInfo = newPageInfo({
	name: "Day Index Page",
	title: "",
	description: "Overview of a single day.",
	exact: true,
	to: scheduleDayPageInfo.to,
	path: scheduleDayPageInfo.path,
	parent: scheduleDayPageInfo,
});
