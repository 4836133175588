import { Models } from "@hex-insights/app-modules";
import { DistributiveOmit } from "@hex-insights/core";
import { Service, ServiceFormat, ServiceNameFragment } from "../../../../Utilities";

type ModelForLink = Pick<Service, "id"> & ServiceNameFragment;

export type ServiceLinkProps = DistributiveOmit<Models.LinkProps<ModelForLink>, "pageInfoKey" | "format">;

/**
 * Renders a link to the given Service instance.
 */
export function ServiceLink(props: ServiceLinkProps) {
	return <Models.Link<ModelForLink> pageInfoKey="Service" format={ServiceFormat.name} {...props} />;
}
