import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { FormType, useFormState as useBaseFormState, ValidationDisplayPolicy } from "@hex-insights/forms";
import { StudentFilterFormValues } from "../ModelFilterFormValues";

/**
 * Returns a `formState` object for use in the filter form of the Student model.
 */
export function useFormState(defaultFormValues?: Partial<StudentFilterFormValues.FormValues>) {
	return IndexForms.useFilterFormStateWithQueryStateSync(
		React.useMemo(() => StudentFilterFormValues.getInitial(defaultFormValues), [defaultFormValues]),
		StudentFilterFormValues.typeFilterObject,
	);
}

export function useFormStateWithoutQueryStateSync(initialFormValues?: Partial<StudentFilterFormValues.FormValues>) {
	return useBaseFormState({
		initialFormValues: React.useMemo(() => StudentFilterFormValues.getInitial(initialFormValues), [initialFormValues]),
		formType: FormType.Standing,
		validationDisplayPolicy: ValidationDisplayPolicy.none,
	});
}
