import { ClassNameProps, StyleProps } from "@hex-insights/core";
import { useRouteParams } from "@hex-insights/router";
import { DocumentResourceOrderField, OrderDirection, useDocumentResourceIndexQuery } from "@hex-insights/verita.shared";
import { ResourcesTile } from "../../../../../../Components";
import { LessonPlansWeekCourseSectionPageRouteParams } from "../../pageinfo";

export type CourseResourcesTileProps = Partial<ClassNameProps & StyleProps>;

export function CourseResourcesTile(props: CourseResourcesTileProps) {
	const { courseSectionID } = useRouteParams<LessonPlansWeekCourseSectionPageRouteParams>();
	const { loading, data } = useDocumentResourceIndexQuery({
		variables: {
			filters: {
				documentResourceFolders: [
					{
						courses: [{ courseSections: [{ idEQ: courseSectionID }] }],
					},
					{
						courseSections: [{ idEQ: courseSectionID }],
					},
				],
			},
			order: { field: DocumentResourceOrderField.Name, direction: OrderDirection.Asc },
		},
	});

	return <ResourcesTile isLoading={loading} edges={data?.documentResourceConnection.edges} {...props} />;
}
