import { Route, useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import { PageRouteSwitch } from "../../../Components";
import { ScheduleDayCoursePeriodPage } from "./CoursePeriod";
import { scheduleDayCoursePeriodPageInfo } from "./CoursePeriod/pageinfo";
import { ScheduleDayIndexPage } from "./IndexPage";
import { scheduleDayIndexPageInfo } from "./IndexPage/pageinfo";
import { scheduleDayPageInfo, ScheduleDayPageRouteParams } from "./pageinfo";

export function ScheduleDayPage() {
	const { date } = useRouteParams<ScheduleDayPageRouteParams>();
	useActivePageRegistration(scheduleDayPageInfo, scheduleDayPageInfo.title(date));

	return (
		<PageRouteSwitch>
			<Route {...scheduleDayIndexPageInfo.routeProps}>
				<ScheduleDayIndexPage />
			</Route>
			<Route {...scheduleDayCoursePeriodPageInfo.routeProps}>
				<ScheduleDayCoursePeriodPage />
			</Route>
		</PageRouteSwitch>
	);
}
