import { EditMode, FormType, useFormState } from "@hex-insights/forms";
import { BusFormValues } from "../ModelFormValues";

/**
 * Returns a `formState` object for use in the create form for the Bus model.
 */
export function useCreateFormState() {
	return useFormState({
		initialFormValues: BusFormValues.initialCreate,
		formType: FormType.Create,
	});
}

const detailFieldEditModes = {
	dropoffBusPlanIDs: EditMode.ReadOnly,
	pickupBusPlanIDs: EditMode.ReadOnly,
};

/**
 * Returns a `formState` object for use in the detail form of the Bus model.
 *
 * @param initialFormValues The initial values for the form. Generally from
 * {@link BusFormConversion.toFormValues}.
 */
export function useDetailFormState({ initialFormValues }: { initialFormValues: BusFormValues.Detail }) {
	return useFormState({
		initialFormValues: initialFormValues,
		formType: FormType.Update,
		fieldEditModes: detailFieldEditModes,
	});
}

/**
 * Returns a read-only `formState` object for use in the detail form of the Bus model.
 *
 * @param initialFormValues The initial values for the form. Generally from
 * {@link BusFormConversion.toFormValues}.
 */
export function useReadOnlyFormState({ initialFormValues }: { initialFormValues: BusFormValues.Detail }) {
	return useFormState({
		initialFormValues: initialFormValues,
		editMode: EditMode.ReadOnly,
	});
}
