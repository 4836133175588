import React from "react";
import { forceUseHookDependencies, formatInt, toLocalDateString, useInterval, useSync } from "@hex-insights/core";
import { getDayMinutes } from "./date-time";
import {
	CourseSectionPeriod,
	Employee,
	HomeRoomSection,
	Student,
	useCourseSectionPeriodHomeRoomSectionDailyScheduleQuery,
	useCourseSectionPeriodStudentDailyScheduleQuery,
	useCourseSectionPeriodTeacherDailyScheduleQuery,
	useCourseSectionPeriodTeacherDailyScheduleWithLessonPlansQuery,
} from "./GraphQL";

export function useStudentScheduleQuery(studentID: Student["id"]) {
	return useStudentScheduleForDateQuery(studentID, new Date());
}

export function useStudentScheduleForDateQuery(studentID: Student["id"], date: Date) {
	return useCourseSectionPeriodStudentDailyScheduleQuery({
		variables: {
			studentID,
			date: toLocalDateString(date),
			dateDOW: date.getDay(),
		},
	});
}

export function useStudentCurrentPeriod(studentID: Student["id"], includeNext = false) {
	const { loading, data } = useStudentScheduleQuery(studentID);
	const currentPeriodInfo = useCurrentPeriodProgress(data, includeNext);
	return {
		loading,
		...currentPeriodInfo,
	};
}

export function useTeacherScheduleQuery(teacherID: Employee["id"]) {
	return useTeacherScheduleForDateQuery(teacherID, new Date());
}

export function useTeacherScheduleForDateQuery(teacherID: Employee["id"], date: Date) {
	return useCourseSectionPeriodTeacherDailyScheduleQuery({
		variables: {
			teacherID,
			date: toLocalDateString(date),
			dateDOW: date.getDay(),
		},
	});
}

export function useTeacherCurrentPeriod(teacherID: Employee["id"], includeNext = false) {
	const { loading, data } = useTeacherScheduleQuery(teacherID);
	const currentPeriodInfo = useCurrentPeriodProgress(data, includeNext);
	return {
		loading,
		...currentPeriodInfo,
	};
}

export function useTeacherScheduleWithLessonPlansQuery(teacherID: Employee["id"]) {
	return useTeacherScheduleForDateWithLessonPlansQuery(teacherID, new Date());
}

export function useTeacherScheduleForDateWithLessonPlansQuery(teacherID: Employee["id"], date: Date) {
	return useCourseSectionPeriodTeacherDailyScheduleWithLessonPlansQuery({
		variables: {
			teacherID,
			date: toLocalDateString(date),
			dateDOW: date.getDay(),
		},
	});
}

export function useHomeRoomSectionScheduleQuery(homeRoomSectionID: HomeRoomSection["id"]) {
	return useHomeRoomSectionScheduleForDateQuery(homeRoomSectionID, new Date());
}

export function useHomeRoomSectionScheduleForDateQuery(homeRoomSectionID: HomeRoomSection["id"], date: Date) {
	return useCourseSectionPeriodHomeRoomSectionDailyScheduleQuery({
		variables: {
			homeRoomSectionID,
			dateDOW: date.getDay(),
		},
	});
}

export function useHomeRoomSectionCurrentPeriod(homeRoomSectionID: HomeRoomSection["id"], includeNext = false) {
	const { loading, data } = useHomeRoomSectionScheduleQuery(homeRoomSectionID);
	const currentPeriodInfo = useCurrentPeriodProgress(data, includeNext);
	return {
		loading,
		...currentPeriodInfo,
	};
}

export function useCurrentPeriodProgress<
	T extends ScheduleCourseSectionPeriod & Pick<CourseSectionPeriod, "dayOfWeek">,
>(data: ScheduleCourseSectionPeriodConnection<T> | undefined, includeNext = false) {
	const { syncID, synchronize } = useSync();
	const currentPeriod = React.useMemo(() => {
		forceUseHookDependencies(syncID);
		if (includeNext) {
			return getCurrentOrNextCourseSectionPeriod(data);
		}
		return getCurrentCourseSectionPeriod(data);
	}, [syncID, data, includeNext]);
	const progress = useCourseSectionPeriodProgress(currentPeriod);
	const { minutesToEnd } = progress;
	React.useEffect(() => {
		if (minutesToEnd < 0) {
			synchronize();
		}
	}, [minutesToEnd, synchronize]);

	return { currentPeriod, ...progress };
}

const refreshIntervalMS = 15000;
const refreshIntervalM = 0.25;

type ScheduleCourseSectionPeriod = Pick<CourseSectionPeriod, "id" | "startTimeMinutes" | "endTimeMinutes">;

type ScheduleCourseSectionPeriodConnection<T extends ScheduleCourseSectionPeriod> = {
	courseSectionPeriodConnection: {
		edges: {
			node: T;
		}[];
	};
};

export function getCurrentOrNextCourseSectionPeriod<T extends ScheduleCourseSectionPeriod>(
	data: ScheduleCourseSectionPeriodConnection<T> | undefined,
) {
	if (!data) {
		return null;
	}

	const currentMinutes = getDayMinutes();
	const edges = data.courseSectionPeriodConnection.edges;
	for (let i = 0; i < edges.length; i++) {
		const period = edges[i].node;
		if (
			(period.startTimeMinutes <= currentMinutes && period.endTimeMinutes > currentMinutes) ||
			period.startTimeMinutes > currentMinutes // assumes edges are ordered
		) {
			return period;
		}
	}
	return null;
}

export function getCurrentCourseSectionPeriod<T extends ScheduleCourseSectionPeriod>(
	data: ScheduleCourseSectionPeriodConnection<T> | undefined,
) {
	if (!data) {
		return null;
	}

	const currentMinutes = getDayMinutes();
	const edges = data.courseSectionPeriodConnection.edges;
	for (let i = 0; i < edges.length; i++) {
		const period = edges[i].node;
		if (period.startTimeMinutes <= currentMinutes && period.endTimeMinutes > currentMinutes) {
			return period;
		}
	}
	return null;
}

export function getPreviousAndNextCourseSectionPeriods<T extends ScheduleCourseSectionPeriod>(
	data: ScheduleCourseSectionPeriodConnection<T> | undefined,
	periodID: CourseSectionPeriod["id"] | null,
) {
	if (!(data && periodID)) {
		return { previous: null, next: null };
	}

	const edges = data.courseSectionPeriodConnection.edges;
	for (let i = 0; i < edges.length; i++) {
		const period = edges[i].node;
		if (period.id === periodID) {
			return {
				previous: i > 0 ? edges[i - 1].node : null,
				next: i < edges.length - 1 ? edges[i + 1].node : null,
			};
		}
	}
	return { previous: null, next: null };
}

export function useCourseSectionPeriodProgress(
	period: Pick<CourseSectionPeriod, "dayOfWeek" | "startTimeMinutes" | "endTimeMinutes"> | null,
) {
	return useIntervalMemo(
		() => {
			if (!period || new Date().getDay() !== period.dayOfWeek) {
				return {
					minutesToStart: 0,
					minutesToEnd: 0,
					isActiveOnDay: false,
					isCurrentlyActive: false,
					courseTimeProgress: 0,
					courseTimeProgressAnimationTarget: 0,
					refreshIntervalMS,
				};
			}

			const currentTimeInMinutes = getDayMinutes();
			const minutesPassed = currentTimeInMinutes - period.startTimeMinutes;
			const courseDuration = period.endTimeMinutes - period.startTimeMinutes;
			const minutesToEnd = courseDuration - minutesPassed;
			const courseTimeProgress = minutesPassed / courseDuration;
			const courseTimeProgressAnimationTarget = (minutesPassed + refreshIntervalM) / courseDuration;
			return {
				minutesToStart: -1 * minutesPassed,
				minutesToEnd,
				isActiveOnDay: true,
				isCurrentlyActive: courseTimeProgress >= 0 && courseTimeProgress <= 1,
				courseTimeProgress,
				courseTimeProgressAnimationTarget,
				refreshIntervalMS,
			};
		},
		refreshIntervalMS,
		[period],
	);
}

// TODO move to core
export function useIntervalMemo<T>(fn: () => T, intervalMS: number, deps: React.DependencyList) {
	const { syncID, synchronize } = useSync();
	const { setInterval } = useInterval();
	React.useEffect(() => {
		setInterval(synchronize, intervalMS);
	}, [setInterval, synchronize, intervalMS]);

	return React.useMemo(() => {
		forceUseHookDependencies(syncID);
		return fn();
	}, [syncID, ...deps]); // eslint-disable-line
}

export function formatMinutesDiff(minutes: number) {
	if (minutes === 0) {
		return "now";
	}
	if (minutes > 0) {
		if (minutes < 1) {
			return "in less than 1 min";
		}
		return `in ${formatInt(minutes)} min`;
	}
	if (minutes < 0) {
		if (minutes > -1) {
			return "just now";
		}
		return `${formatInt(Math.abs(minutes))} min ago`;
	}
	return "";
}
