import React from "react";
import { ApplyCreateFunction, ApplyUpdateFunction, onCreateSubmit, onUpdateSubmit } from "@hex-insights/app-modules";
import { Column, Heading, If, Row } from "@hex-insights/core";
import {
	anyFieldChanged,
	FieldDisplayArgs,
	Form,
	FormState,
	FormSubmitFunction,
	FormType,
	SubmissionStatus,
	SubmitButton,
	useInternalField,
} from "@hex-insights/forms";
import {
	CourseSection,
	LessonPlanDetailQuery,
	LessonPlanField,
	LessonPlanFormConversion,
	LessonPlanFormState,
	LessonPlanFormValues,
} from "@hex-insights/verita.shared";
import { Collapsible } from "../Components";
import styles from "./styles.module.css";

const controlledFields: (keyof LessonPlanFormValues.Base)[] = ["courseDate", "courseSectionID"];

// TODO clean up: copied from shared

export type LessonPlanFormControlledCreateProps = {
	courseSectionID: CourseSection["id"];
	applyCreate: ApplyCreateFunction<LessonPlanFormValues.Create>;
	onSuccess: () => void;
	formNameSuffix?: string;
};

/**
 * Renders the create form of the LessonPlan model using an internally managed form state.
 */
export function LessonPlanFormControlledCreate({ courseSectionID, ...props }: LessonPlanFormControlledCreateProps) {
	const formState = LessonPlanFormState.useCreateFormState({ courseSectionID });

	return <LessonPlanFormCreate formState={formState} {...props} />;
}

export type LessonPlanFormCreateProps = Omit<LessonPlanFormControlledCreateProps, "courseSectionID"> & {
	formState: FormState<LessonPlanFormValues.Create>;
};

/**
 * Renders the create form of the LessonPlan model using the given form state.
 */
export function LessonPlanFormCreate({
	formState,
	applyCreate,
	onSuccess,
	formNameSuffix = "",
}: LessonPlanFormCreateProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<LessonPlanFormValues.Create>>(
		async ({ formValues }) => {
			return onCreateSubmit(formValues, applyCreate);
		},
		[applyCreate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	useInternalField(formState, controlledFields);

	return (
		<Form
			name={"lessonPlan.create" + formNameSuffix}
			formState={formState}
			onSubmit={formState.onSubmitWrapper(onSubmit)}
		>
			<Column justify="spaced-start">
				<div>
					<Column justify="spaced-start">
						<LessonPlanField.LessonDescription formState={formState} formType={FormType.Create} autoFocus />
						<LessonPlanField.LearningObjectives
							formState={formState}
							formType={FormType.Create}
							queryVariables={{
								filters: {
									course: [{ courseSections: [{ idEQ: formState.formValues.courseSectionID }] }],
								},
							}}
						/>
					</Column>
				</div>

				<div>
					<Heading level={3} noMargin>
						Stations
					</Heading>

					<Column justify="spaced-start">
						<LessonPlanField.StationOneDescription formState={formState} formType={FormType.Create} />
						<LessonPlanField.StationTwoDescription formState={formState} formType={FormType.Create} />
						<LessonPlanField.StationThreeDescription formState={formState} formType={FormType.Create} />
					</Column>
				</div>

				<Collapsible
					displayedContent={
						<Heading level={3} noMargin>
							Differentiation
						</Heading>
					}
					expandButtonContent="Add"
					collapseButtonContent="Hide"
					buttonSize="small"
				>
					<LessonPlanField.DifferentiationDescription formState={formState} formType={FormType.Create} />
				</Collapsible>

				<Collapsible
					displayedContent={
						<Heading level={3} noMargin>
							Assessment
						</Heading>
					}
					expandButtonContent="Add"
					collapseButtonContent="Hide"
					buttonSize="small"
				>
					<LessonPlanField.AssessmentDescription formState={formState} formType={FormType.Create} />
				</Collapsible>

				<Collapsible
					displayedContent={
						<Heading level={3} noMargin>
							Resources &amp; Materials
						</Heading>
					}
					expandButtonContent="Add"
					collapseButtonContent="Hide"
					buttonSize="small"
				>
					<LessonPlanField.RequiredResourcesAndMaterials formState={formState} formType={FormType.Create} />
				</Collapsible>

				<Collapsible
					displayedContent={
						<Heading level={3} noMargin>
							Notes
						</Heading>
					}
					expandButtonContent="Add"
					collapseButtonContent="Hide"
					buttonSize="small"
				>
					<LessonPlanField.Notes formState={formState} formType={FormType.Create} />
				</Collapsible>

				<Row className={styles["floating-submit-button-container"]}>
					<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
						Submit
					</SubmitButton>
				</Row>
			</Column>
		</Form>
	);
}

export type LessonPlanFormControlledDetailProps = {
	lessonPlan: LessonPlanDetailQuery["lessonPlan"];
	applyUpdate: ApplyUpdateFunction<LessonPlanFormValues.Detail>;
	onSuccess: () => void;
};

/**
 * Renders the detail form of the LessonPlan model using an internally managed form state.
 */
export function LessonPlanFormControlledDetail(props: LessonPlanFormControlledDetailProps) {
	const initialFormValues = React.useMemo(
		() => LessonPlanFormConversion.toFormValues(props.lessonPlan),
		[props.lessonPlan],
	);
	const formState = LessonPlanFormState.useDetailFormState({ initialFormValues });

	return <LessonPlanFormDetail formState={formState} {...props} />;
}

export type LessonPlanFormDetailProps = LessonPlanFormControlledDetailProps & {
	formState: FormState<LessonPlanFormValues.Detail>;
	noChangesDialog?: boolean;
};

/**
 * Renders the detail form of the LessonPlan model using the given form state.
 */
export function LessonPlanFormDetail({
	formState,
	lessonPlan,
	applyUpdate,
	onSuccess,
	noChangesDialog = false,
}: LessonPlanFormDetailProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<LessonPlanFormValues.Detail>>(
		async (formState) => {
			return onUpdateSubmit(formState, applyUpdate);
		},
		[applyUpdate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	useInternalField(formState, controlledFields);

	const anyChanged = React.useMemo(() => anyFieldChanged(formState), [formState]);

	return (
		<Form
			name={`lessonPlan.detail.${lessonPlan.id}`}
			formState={formState}
			onSubmit={formState.onSubmitWrapper(onSubmit)}
			noChangesDialog={noChangesDialog}
		>
			<Column justify="spaced-start">
				<div>
					<Column justify="spaced-start">
						<LessonPlanField.LessonDescription formState={formState} formType={FormType.Update} id={lessonPlan.id} />
						<LessonPlanField.LearningObjectives
							formState={formState}
							formType={FormType.Update}
							id={lessonPlan.id}
							currentLearningObjectives={lessonPlan.learningObjectives}
							displayInstance={displayLearningObjectiveInstance}
							queryVariables={{
								filters: {
									course: [{ courseSections: [{ idEQ: formState.formValues.courseSectionID }] }],
								},
							}}
						/>
					</Column>
				</div>

				<div>
					<Heading level={3} noMargin>
						Stations
					</Heading>
					<Column justify="spaced-start">
						<LessonPlanField.StationOneDescription
							formState={formState}
							formType={FormType.Update}
							id={lessonPlan.id}
						/>
						<LessonPlanField.StationTwoDescription
							formState={formState}
							formType={FormType.Update}
							id={lessonPlan.id}
						/>
						<LessonPlanField.StationThreeDescription
							formState={formState}
							formType={FormType.Update}
							id={lessonPlan.id}
						/>
					</Column>
				</div>

				<div>
					<Heading level={3} noMargin>
						Differentiation
					</Heading>
					<LessonPlanField.DifferentiationDescription
						formState={formState}
						formType={FormType.Update}
						id={lessonPlan.id}
					/>
				</div>

				<div>
					<Heading level={3} noMargin>
						Assessment
					</Heading>
					<LessonPlanField.AssessmentDescription formState={formState} formType={FormType.Update} id={lessonPlan.id} />
				</div>

				<div>
					<Heading level={3} noMargin>
						Resources &amp; Materials
					</Heading>
					<LessonPlanField.RequiredResourcesAndMaterials
						formState={formState}
						formType={FormType.Update}
						id={lessonPlan.id}
					/>
				</div>

				<div>
					<Heading level={3} noMargin>
						Notes
					</Heading>
					<LessonPlanField.Notes formState={formState} formType={FormType.Update} id={lessonPlan.id} />
				</div>

				<If condition={anyChanged}>
					<Row className={styles["floating-submit-button-container"]}>
						<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
							Save Changes
						</SubmitButton>
					</Row>
				</If>
			</Column>
		</Form>
	);
}

function displayLearningObjectiveInstance({ formattedValue }: FieldDisplayArgs<string | null>) {
	return formattedValue;
}
