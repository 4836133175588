import { Models } from "@hex-insights/app-modules";
import { DistributiveOmit } from "@hex-insights/core";
import { Discount, DiscountFormat, DiscountNameFragment } from "../../../../Utilities";

type ModelForLink = Pick<Discount, "id"> & DiscountNameFragment;

export type DiscountLinkProps = DistributiveOmit<Models.LinkProps<ModelForLink>, "pageInfoKey" | "format">;

/**
 * Renders a link to the given Discount instance.
 */
export function DiscountLink(props: DiscountLinkProps) {
	return <Models.Link<ModelForLink> pageInfoKey="Discount" format={DiscountFormat.name} {...props} />;
}
