import { Service } from "../../../GraphQL";
import { formatPrice } from "../../money";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the name field of the Service model.
 */
export function name(value: Service["name"]): string {
	return toString(value);
}

/**
 * Formats the description field of the Service model.
 */
export function description(value: Service["description"]): string {
	return toString(value);
}

/**
 * Formats the price field of the Service model.
 */
export function price(value: Service["price"] | null): string {
	return formatPrice(value);
}
