import { EditMode, FormType, useFormState } from "@hex-insights/forms";
import { ParentFormValues } from "../ModelFormValues";
import { detailFieldEditModes as personDetailFieldEditModes } from "./person";

/**
 * Returns a `formState` object for use in the create form for the Parent model.
 */
export function useCreateFormState() {
	return useFormState({
		initialFormValues: ParentFormValues.initialCreate,
		formType: FormType.Create,
	});
}

export function useCreateWithPersonFormState() {
	return useFormState({
		initialFormValues: ParentFormValues.initialCreateWithPerson,
		formType: FormType.Create,
	});
}

const detailFieldEditModes = {
	paymentIDs: EditMode.ReadOnly,
	relationshipIDs: EditMode.ReadOnly,
};

/**
 * Returns a `formState` object for use in the detail form of the Parent model.
 *
 * @param initialFormValues The initial values for the form. Generally from
 * {@link ParentFormConversion.toFormValues}.
 */
export function useDetailFormState({ initialFormValues }: { initialFormValues: ParentFormValues.Detail }) {
	return useFormState({
		initialFormValues: initialFormValues,
		formType: FormType.Update,
		fieldEditModes: detailFieldEditModes,
	});
}

export function useDetailWithPersonFormState({
	initialFormValues,
}: {
	initialFormValues: ParentFormValues.DetailWithPerson;
}) {
	return useFormState({
		initialFormValues,
		formType: FormType.Update,
		fieldEditModes: { ...detailFieldEditModes, ...personDetailFieldEditModes },
	});
}

/**
 * Returns a read-only `formState` object for use in the detail form of the Parent model.
 *
 * @param initialFormValues The initial values for the form. Generally from
 * {@link ParentFormConversion.toFormValues}.
 */
export function useReadOnlyFormState({ initialFormValues }: { initialFormValues: ParentFormValues.Detail }) {
	return useFormState({
		initialFormValues: initialFormValues,
		editMode: EditMode.ReadOnly,
	});
}
