import { Models } from "@hex-insights/app-modules";
import { DistributiveOmit } from "@hex-insights/core";
import { Tuition } from "../../../../Utilities";

type ModelForLink = Pick<Tuition, "id" | "name">;

export type TuitionLinkProps = DistributiveOmit<Models.LinkProps<ModelForLink>, "pageInfoKey" | "format">;

/**
 * Renders a link to the given Tuition instance.
 */
export function TuitionLink(props: TuitionLinkProps) {
	return <Models.Link pageInfoKey="Tuition" format={format} {...props} />;
}

function format(instance: ModelForLink) {
	return instance.name;
}
