import { IndexForms } from "@hex-insights/app-modules";
import { TypeFilterObject } from "@hex-insights/core";

export type StandardFilterFormValues = {}; // TODO define quick filters

export type FormValues = StandardFilterFormValues & IndexForms.SearchFormValues & IndexForms.FilterFormValues;

export const initialStandardFilters: StandardFilterFormValues = {};

export const initial: FormValues = {
	...initialStandardFilters,
	...IndexForms.initialSearchFormValues,
	...IndexForms.initialFilterFormValues,
};

export function getInitial(): FormValues {
	return initial;
}

export const quickFiltersTypeFilterObject: TypeFilterObject<StandardFilterFormValues> = {};

export const typeFilterObject: TypeFilterObject<FormValues> = {
	...quickFiltersTypeFilterObject,
	...IndexForms.searchFormValuesFilterObject,
	...IndexForms.filterFormValuesFilterObject,
};

export const advancedFilterInformation: IndexForms.AdvancedFilterInformation = {
	fieldOptions: [
		{ value: "name" },
		{ value: "description" },
		{ value: "rawImageURL" },
		{ value: "rawFileURL" },
		{ value: "resourceURL" },
	],
	fieldComparisonOptions: {
		name: IndexForms.stringComparisonOptions,
		description: IndexForms.stringComparisonOptions,
		rawImageURL: IndexForms.stringComparisonOptions,
		rawFileURL: IndexForms.stringComparisonOptions,
		resourceURL: IndexForms.stringComparisonOptions,
	},
	fieldComparisonTranslations: {
		name: IndexForms.stringComparisonTranslations,
		description: IndexForms.stringComparisonTranslations,
		rawImageURL: IndexForms.stringComparisonTranslations,
		rawFileURL: IndexForms.stringComparisonTranslations,
		resourceURL: IndexForms.stringComparisonTranslations,
	},
};
