import { newPageInfo } from "@hex-insights/router";
import { lessonPlansWeekCourseSectionPageInfo, LessonPlansWeekCourseSectionPageRouteParams } from "../pageinfo";

export type LessonPlansWeekCourseSectionDayPageRouteParams = LessonPlansWeekCourseSectionPageRouteParams & {
	dow: string;
};

const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

export const lessonPlansWeekCourseSectionDayPageInfo = newPageInfo({
	name: "Course Section Lesson Plan Day View",
	title: (dow: number | string) => daysOfWeek[Number(dow)] ?? "Unknown",
	description: "Weekly view of lesson plans to view, create, and edit lesson plans.",
	exact: false,
	to: (
		weekDate: Parameters<typeof lessonPlansWeekCourseSectionPageInfo.to>[0],
		id: Parameters<typeof lessonPlansWeekCourseSectionPageInfo.to>[1],
		dow: number | string,
	) => lessonPlansWeekCourseSectionPageInfo.to(weekDate, id) + `/${dow}`,
	path: lessonPlansWeekCourseSectionPageInfo.path + "/:dow(\\d+)",
	parent: lessonPlansWeekCourseSectionPageInfo,
});
