import { Models } from "@hex-insights/app-modules";
import { CalendarEvent, CalendarEventMutation } from "../../../../Utilities";

export type CalendarEventDeleteButtonProps = Omit<Models.DeleteButtonProps, "deleteInstance"> & {
	instanceID: CalendarEvent["id"];
};

/**
 * A delete button for the CalendarEvent model.
 */
export function CalendarEventDeleteButton({
	instanceID,
	confirmationContent = "Are you sure you want to delete this calendar event?",
	children = "Delete Event",
	...props
}: CalendarEventDeleteButtonProps) {
	const del = CalendarEventMutation.useDelete(instanceID);

	return (
		<Models.DeleteButton deleteInstance={del} confirmationContent={confirmationContent} {...props}>
			{children}
		</Models.DeleteButton>
	);
}
