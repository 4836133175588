import { formatDateTime } from "@hex-insights/core";
import { Redirect, Route, useActivePageRegistration, useRouteMatch } from "@hex-insights/router";
import { getFirstDayInWeek } from "@hex-insights/verita.shared";
import { PageRouteSwitch } from "../../Components";
import { lessonPlansPageInfo } from "./pageinfo";
import { LessonPlansWeekPage } from "./Week";
import { lessonPlansWeekPageInfo, LessonPlansWeekPageRouteParams } from "./Week/pageinfo";

export function LessonPlansPage() {
	useActivePageRegistration(lessonPlansPageInfo);

	return (
		<PageRouteSwitch>
			<Route exact path={lessonPlansPageInfo.path}>
				<Redirect to={lessonPlansWeekPageInfo.to(formatDateTime(getFirstDayInWeek(new Date()), "YYYY-MM-DD"))} />
			</Route>
			<Route {...lessonPlansWeekPageInfo.routeProps}>
				<LessonPlansWeekPage
					key={useRouteMatch<LessonPlansWeekPageRouteParams>(lessonPlansWeekPageInfo.path as string)?.params.date}
				/>
			</Route>
		</PageRouteSwitch>
	);
}
