import { NullPartial, omitKeys } from "@hex-insights/core";
import {
	NoteAttachmentCreateInput,
	NoteAttachmentDetailQuery,
	NoteAttachmentInNoteCreateInput,
	NoteAttachmentUpdateInput,
} from "../../GraphQL";
import { NoteAttachmentFormValues } from "../ModelFormValues";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the NoteAttachment model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(formValues: NoteAttachmentFormValues.Create): NoteAttachmentCreateInput {
	const inputFormValues = omitKeys(formValues, ["isFile"]);
	const input: NullPartial<NoteAttachmentCreateInput> = { ...inputFormValues };
	return input as NoteAttachmentCreateInput;
}

export function toGQLCreateInNoteInput(
	formValues: NoteAttachmentFormValues.CreateInNote,
): NoteAttachmentInNoteCreateInput {
	const inputFormValues = omitKeys(formValues, ["isFile"]);
	const input: NullPartial<NoteAttachmentInNoteCreateInput> = { ...inputFormValues };
	return input as NoteAttachmentInNoteCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the NoteAttachment model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<NoteAttachmentFormValues.Detail>,
	_initialFormValues: NoteAttachmentFormValues.Detail,
): NoteAttachmentUpdateInput {
	const inputFormValues = omitKeys(formValues, ["isFile", "url", "noteID"]);
	const input: NoteAttachmentUpdateInput = { ...inputFormValues };

	if (formValues.file === null) {
		input.clearFile = true;
	}

	return input;
}

/**
 * Converts a NoteAttachment instance to its corresponding detail form values.
 *
 * @param noteAttachment The instance to convert.
 */
export function toFormValues(
	noteAttachment: NoteAttachmentDetailQuery["noteAttachment"],
): NoteAttachmentFormValues.Detail {
	return {
		order: noteAttachment.order,
		isFile: noteAttachment.isFile,
		file: noteAttachment.fileURL !== "" ? new File([], "file") : null,
		linkURL: noteAttachment.linkURL,
		url: noteAttachment.url,
		noteID: noteAttachment.note?.id ?? null,
	};
}
