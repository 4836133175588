import Calendar, { CalendarProps } from "react-calendar";
import { formatDateTime, isSameTime, makeClassName } from "@hex-insights/core";
import "./styles.css";
import styles from "./styles.module.css";

export type SmallCalendarProps = Omit<CalendarProps, "className" | "tileClassName"> & {
	noSecondaryArrows?: boolean;
	className?: string;
	tileClassName?: string;
};

export function SmallCalendar({
	noSecondaryArrows = false,
	formatMonthYear = defaultFormatMonthYear,
	className,
	tileClassName,
	...props
}: SmallCalendarProps) {
	return (
		<Calendar
			{...props}
			className={makeClassName(
				styles["verita-small-calendar"],
				"verita-small-calendar",
				noSecondaryArrows ? "verita-small-calendar--no-secondary-arrows" : "",
				className,
			)}
			formatMonthYear={formatMonthYear}
			tileClassName={makeClassName(styles["verita-small-calendar__tile"], tileClassName)}
		/>
	);
}

function defaultFormatMonthYear(_locale: string, date: Date) {
	if (isSameTime(date, new Date(), "year")) {
		return formatDateTime(date, "MMMM");
	}
	return formatDateTime(date, "MMMM YYYY");
}
