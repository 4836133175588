import { Models } from "@hex-insights/app-modules";
import { DistributiveOmit } from "@hex-insights/core";
import { Room, RoomFormat, RoomNameFragment } from "../../../../Utilities";

type ModelForLink = Pick<Room, "id"> & RoomNameFragment;

export type RoomLinkProps = DistributiveOmit<Models.LinkProps<ModelForLink>, "pageInfoKey" | "format">;

/**
 * Renders a link to the given Room instance.
 */
export function RoomLink(props: RoomLinkProps) {
	return <Models.Link<ModelForLink> pageInfoKey="Room" format={RoomFormat.name} {...props} />;
}
