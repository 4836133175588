import { getArrayDiff, NullPartial, omitKeys } from "@hex-insights/core";
import { ParentCreateInput, ParentDetailQuery, ParentUpdateInput, PersonUpdateInput } from "../../GraphQL";
import { ParentFormValues, PersonFormValues } from "../ModelFormValues";
import * as PersonFormConversion from "./person";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the Parent model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(formValues: ParentFormValues.Create): ParentCreateInput {
	const input: NullPartial<ParentCreateInput> = { ...formValues };
	return input as ParentCreateInput;
}

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the Parent model with nested
 * Person form values.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateWithPersonInput(formValues: ParentFormValues.CreateWithPerson): ParentCreateInput {
	const inputFormValues = ParentFormValues.omitPersonCreateFormValues(formValues);
	const input: NullPartial<ParentCreateInput> = { ...inputFormValues };
	const personFormValues = PersonFormValues.pickCreateInOtherFormValues(formValues);
	input.person = PersonFormConversion.toGQLCreateInOtherInput(personFormValues);
	return input as ParentCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the Parent model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<ParentFormValues.Detail>,
	initialFormValues: Partial<ParentFormValues.Detail>,
): ParentUpdateInput {
	const inputFormValues = omitKeys(formValues, ["paymentIDs", "relationshipIDs"]);
	const input: ParentUpdateInput = { ...inputFormValues };

	if (formValues.paymentIDs !== undefined) {
		const { added, removed } = getArrayDiff(initialFormValues.paymentIDs ?? [], formValues.paymentIDs);
		input.addPaymentIDs = added;
		input.removePaymentIDs = removed;
	}

	if (formValues.relationshipIDs !== undefined) {
		const { added, removed } = getArrayDiff(initialFormValues.relationshipIDs ?? [], formValues.relationshipIDs);
		input.addRelationshipIDs = added;
		input.removeRelationshipIDs = removed;
	}

	return input;
}

export function toGQLUpdateWithPersonInput(
	formValues: Partial<ParentFormValues.DetailWithPerson>,
	initialFormValues: Partial<ParentFormValues.DetailWithPerson>,
): [ParentUpdateInput, PersonUpdateInput] {
	const inputFormValues = ParentFormValues.omitPersonDetailFormValues(formValues);
	const initialInputFormValues = ParentFormValues.omitPersonDetailFormValues(initialFormValues);
	const input = toGQLUpdateInput(inputFormValues, initialInputFormValues);
	const personFormValues = PersonFormValues.pickDetailInOtherFormValues(formValues);
	const initialPersonFormValues = PersonFormValues.pickDetailInOtherFormValues(initialFormValues);
	const personInput = PersonFormConversion.toGQLUpdateInput(personFormValues, initialPersonFormValues);
	return [input, personInput];
}

/**
 * Converts a Parent instance to its corresponding detail form values.
 *
 * @param parent The instance to convert.
 */
export function toFormValues(parent: ParentDetailQuery["parent"]): ParentFormValues.Detail {
	return {
		paymentIDs: parent.payments.map((e) => e.id),
		personID: parent.person?.id ?? null,
		relationshipIDs: parent.relationships.map((e) => e.id),
	};
}

export function toFormValuesWithPerson(parent: ParentDetailQuery["parent"]): ParentFormValues.DetailWithPerson {
	return {
		...omitKeys(toFormValues(parent), ["personID"]),
		...PersonFormConversion.toFormValuesInOther(parent.person),
	};
}
