import { IndexForms } from "@hex-insights/app-modules";
import { TypeFilterObject } from "@hex-insights/core";

export type StandardFilterFormValues = {}; // TODO define standard filters

export type FormValues = StandardFilterFormValues & IndexForms.SearchFormValues & IndexForms.FilterFormValues;

export const initialStandardFilters: StandardFilterFormValues = {};

export const initial: FormValues = {
	...initialStandardFilters,
	...IndexForms.initialSearchFormValues,
	...IndexForms.initialFilterFormValues,
};

export function getInitial(initialFormValues?: Partial<FormValues>): FormValues {
	return { ...initial, ...initialFormValues };
}

export const standardFiltersTypeFilterObject: TypeFilterObject<StandardFilterFormValues> = {};

export const typeFilterObject: TypeFilterObject<FormValues> = {
	...standardFiltersTypeFilterObject,
	...IndexForms.searchFormValuesFilterObject,
	...IndexForms.filterFormValuesFilterObject,
};

export const advancedFilterInformation: IndexForms.AdvancedFilterInformation = {
	fieldOptions: [
		{ value: "numPayments" },
		{ value: "isInterestedInBusPlan" },
		{ value: "isInterestedInMealPlan" },
		{ value: "authorName" },
		{ value: "authorEmail" },
		{ value: "authorPhone" },
	],
	fieldComparisonOptions: {
		numPayments: IndexForms.numberComparisonOptions,
		isInterestedInBusPlan: IndexForms.booleanComparisonOptions,
		isInterestedInMealPlan: IndexForms.booleanComparisonOptions,
		authorName: IndexForms.stringComparisonOptions,
		authorEmail: IndexForms.stringComparisonOptions,
		authorPhone: IndexForms.stringComparisonOptions,
	},
	fieldComparisonTranslations: {
		numPayments: IndexForms.numberComparisonTranslations,
		isInterestedInBusPlan: IndexForms.booleanComparisonTranslations,
		isInterestedInMealPlan: IndexForms.booleanComparisonTranslations,
		authorName: IndexForms.stringComparisonTranslations,
		authorEmail: IndexForms.stringComparisonTranslations,
		authorPhone: IndexForms.stringComparisonTranslations,
	},
};
