import React from "react";
import { ApplyCreateFunction, ApplyUpdateFunction, onCreateSubmit, onUpdateSubmit } from "@hex-insights/app-modules";
import { Button, Collapsible, Column, Icon, If, Row, StyleProps } from "@hex-insights/core";
import {
	anyFieldEditing,
	everyFieldEditing,
	FieldIf,
	Form,
	FormState,
	FormSubmitFunction,
	FormType,
	SubmissionStatus,
	SubmitButton,
} from "@hex-insights/forms";
import {
	Note,
	NoteFormConversion,
	NoteFormState,
	NoteFormValues,
	StudentWithIDAndName,
	UserWithName,
} from "../../../../../Utilities";
import { TextAreaField } from "../../../../TextAreaField";
import * as Field from "../Field";
import styles from "../styles.module.css";

export type ControlledCreateProps = {
	applyCreate: ApplyCreateFunction<NoteFormValues.General.Create>;
	onSuccess: () => void;
} & Partial<StyleProps>;

export function ControlledCreate(props: ControlledCreateProps) {
	const formState = NoteFormState.General.useCreateFormState();

	return <Create formState={formState} {...props} />;
}

export type CreateProps = ControlledCreateProps & {
	formState: FormState<NoteFormValues.General.Create>;
};

/**
 * Renders the create form of the Note model for an annoucement-type note using the given form state.
 */
export function Create({ formState, applyCreate, onSuccess, style }: CreateProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<NoteFormValues.General.Create>>(
		async ({ formValues }) => {
			return onCreateSubmit(formValues, applyCreate);
		},
		[applyCreate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	return (
		<Form
			name="note.create.general"
			formState={formState}
			onSubmit={formState.onSubmitWrapper(onSubmit)}
			className={styles["note-form"]}
			style={style}
		>
			<Column justify="spaced-start" verticalSpacing="2rem">
				<Field.Students formState={formState} formType={FormType.Create} minItems={1} />
				<TextAreaField formState={formState} name="body" label="Note" minRows={3} />
				<Column justify="spaced-start">
					<Field.IsPublic formState={formState} formType={FormType.Create} />
					<FieldIf formState={formState} name="sharingUserIDs" condition={!formState.formValues.isPublic}>
						<Field.SharingUsers formState={formState} formType={FormType.Create} />
					</FieldIf>
				</Column>

				<Row justify="flex-end">
					<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
						Submit
					</SubmitButton>
				</Row>
			</Column>
		</Form>
	);
}

export type ControlledDetailProps = {
	note: NoteFormConversion.GeneralNoteForFormValues &
		Pick<Note, "id"> & {
			author: UserWithName | null;
			students: StudentWithIDAndName[];
			sharingUsers: UserWithName[];
		};
	applyUpdate: ApplyUpdateFunction<NoteFormValues.General.Detail>;
	onSuccess: () => void;
} & Partial<StyleProps>;

/**
 * Renders the detail form of the Note model using an internally managed form state.
 */
export function ControlledDetail(props: ControlledDetailProps) {
	const initialFormValues = React.useMemo(() => NoteFormConversion.noteToGeneralFormValues(props.note), [props.note]);
	const formState = NoteFormState.General.useDetailFormState({ initialFormValues });

	return <Detail formState={formState} {...props} />;
}

export type DetailProps = ControlledDetailProps & {
	formState: FormState<NoteFormValues.General.Detail>;
};

/**
 * Renders the detail form of the Note model using the given form state.
 */
export function Detail({ formState, note, applyUpdate, onSuccess, style }: DetailProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<NoteFormValues.General.Detail>>(
		async (formState) => {
			return onUpdateSubmit(formState, applyUpdate);
		},
		[applyUpdate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	const { anyEditing, everyEditing } = React.useMemo(() => {
		return {
			anyEditing: anyFieldEditing({
				formEditing: formState.formEditing,
				formSubFormStates: formState.formSubFormStates,
			}),
			everyEditing: everyFieldEditing({
				formEditing: formState.formEditing,
				formSubFormStates: formState.formSubFormStates,
			}),
		};
	}, [formState.formEditing, formState.formSubFormStates]);

	const sharingButtonContent = (
		<Row justify="spaced-start" horizontalSpacing="0.25rem" align="center">
			<Icon.Users size="1rem" /> Sharing: {formState.formValues.isPublic ? "Public" : "Private"}
		</Row>
	);

	return (
		<Form
			name={`note.detail.${note.id}`}
			formState={formState}
			onSubmit={formState.onSubmitWrapper(onSubmit)}
			className={styles["note-form"]}
			style={style}
		>
			<Column justify="spaced-start" verticalSpacing="2rem">
				<Row justify="spaced-start" horizontalSpacing="0.75rem">
					<If condition={!everyEditing}>
						<Button variant="tertiary" size="small" onClick={() => formState.setFormEditing(true)}>
							Edit
						</Button>
					</If>
					<If condition={anyEditing}>
						<Button variant="tertiary" size="small" onClick={() => formState.setFormEditing(false)}>
							Stop Editing
						</Button>
					</If>
				</Row>

				<Field.Students
					formState={formState}
					formType={FormType.Update}
					id={note.id}
					currentStudents={note.students}
					minItems={1}
				/>
				<TextAreaField formState={formState} name="body" label="Note" minRows={3} />
				<Collapsible
					isCollapsedInitially
					collapseButtonContent={sharingButtonContent}
					expandButtonContent={sharingButtonContent}
					buttonSize="small"
				>
					<Field.IsPublic formState={formState} formType={FormType.Update} id={note.id} />
					<FieldIf formState={formState} name="sharingUserIDs" condition={!formState.formValues.isPublic}>
						<Field.SharingUsers
							formState={formState}
							formType={FormType.Update}
							id={note.id}
							currentSharingUsers={note.sharingUsers}
						/>
					</FieldIf>
				</Collapsible>

				<div style={{ display: "none" }}>
					<Field.Author formState={formState} formType={FormType.Update} id={note.id} currentAuthor={note.author} />
					<Field.CreatedAt formState={formState} formType={FormType.Update} id={note.id} />
				</div>
			</Column>
		</Form>
	);
}

// TODO cleanup
export type ControlledReadOnlyProps = {
	note: ControlledDetailProps["note"];
};

export function ControlledReadOnly({ note }: ControlledReadOnlyProps) {
	const initialFormValues = React.useMemo(() => NoteFormConversion.noteToGeneralFormValues(note), [note]);
	const formState = NoteFormState.General.useReadOnlyFormState({ initialFormValues });

	const sharingButtonContent = (
		<Row justify="spaced-start" horizontalSpacing="0.25rem" align="center">
			<Icon.Users size="1rem" /> Sharing: {formState.formValues.isPublic ? "Public" : "Private"}
		</Row>
	);

	return (
		<Column justify="spaced-start" verticalSpacing="2rem" className={styles["note-form"]}>
			<Field.Students
				formState={formState}
				formType={FormType.Update}
				id={note.id}
				currentStudents={note.students}
				minItems={1}
			/>
			<TextAreaField formState={formState} name="body" label="Note" />
			<Collapsible
				isCollapsedInitially
				collapseButtonContent={sharingButtonContent}
				expandButtonContent={sharingButtonContent}
				buttonSize="small"
			>
				<Field.IsPublic formState={formState} formType={FormType.Update} id={note.id} />
				<FieldIf formState={formState} name="sharingUserIDs" condition={!formState.formValues.isPublic}>
					<Field.SharingUsers
						formState={formState}
						formType={FormType.Update}
						id={note.id}
						currentSharingUsers={note.sharingUsers}
					/>
				</FieldIf>
			</Collapsible>
		</Column>
	);
}
