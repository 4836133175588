import { Invoice, Payment, PaymentLineItem } from "../../GraphQL";

export type Base = {
	amount: PaymentLineItem["amount"] | null;
	invoiceID: Invoice["id"] | null;
	paymentID: Payment["id"] | null;
};

export type Create = Base;

export type Detail = Base;

export const initialCreate: Create = {
	amount: null,
	invoiceID: null,
	paymentID: null,
};
