import { IndexForms } from "@hex-insights/app-modules";
import { CaseStyle, convertCase } from "@hex-insights/core";
import { AcademicYearTuitionOrderField, OrderDirection } from "../../GraphQL";

export type FormValues = IndexForms.OrderFormValues<AcademicYearTuitionOrderField | null>;

export const initial: FormValues = {
	field: AcademicYearTuitionOrderField.NurseryPrice,
	direction: OrderDirection.Asc,
};

/**
 * Options for the "field" field in the order form of the AcademicYearTuition model.
 */
export const fieldOptions = [
	{ label: "ID", value: null },
	{
		label: convertCase(AcademicYearTuitionOrderField.NurseryPrice.toLowerCase(), CaseStyle.Title),
		value: AcademicYearTuitionOrderField.NurseryPrice,
	},
	{
		label: convertCase(AcademicYearTuitionOrderField.ReceptionPrice.toLowerCase(), CaseStyle.Title),
		value: AcademicYearTuitionOrderField.ReceptionPrice,
	},
	{
		label: convertCase(AcademicYearTuitionOrderField.PrimaryPrice.toLowerCase(), CaseStyle.Title),
		value: AcademicYearTuitionOrderField.PrimaryPrice,
	},
	{
		label: convertCase(AcademicYearTuitionOrderField.SecondaryPrice.toLowerCase(), CaseStyle.Title),
		value: AcademicYearTuitionOrderField.SecondaryPrice,
	},
];

/**
 * Array of values for the "field" field in the order form of the AcademicYearTuition model.
 */
export const fieldValues = fieldOptions.map((e) => e.value);

/**
 * Mapping from order fields of the AcademicYearTuition model to their corresponding order type.
 */
export const fieldTypeMapping = {
	[AcademicYearTuitionOrderField.NurseryPrice]: IndexForms.OrderFieldType.Number,
	[AcademicYearTuitionOrderField.ReceptionPrice]: IndexForms.OrderFieldType.Number,
	[AcademicYearTuitionOrderField.PrimaryPrice]: IndexForms.OrderFieldType.Number,
	[AcademicYearTuitionOrderField.SecondaryPrice]: IndexForms.OrderFieldType.Number,
};

/**
 * Mapping from order fields of the AcademicYearTuition model to their corresponding field name.
 */
export const orderFieldToModelField = {
	[AcademicYearTuitionOrderField.NurseryPrice]: "nurseryPrice",
	[AcademicYearTuitionOrderField.ReceptionPrice]: "receptionPrice",
	[AcademicYearTuitionOrderField.PrimaryPrice]: "primaryPrice",
	[AcademicYearTuitionOrderField.SecondaryPrice]: "secondaryPrice",
};

/**
 * Mapping from field names of the AcademicYearTuition model to their corresponding order field.
 */
export const modelFieldToOrderField = {
	nurseryPrice: AcademicYearTuitionOrderField.NurseryPrice,
	receptionPrice: AcademicYearTuitionOrderField.ReceptionPrice,
	primaryPrice: AcademicYearTuitionOrderField.PrimaryPrice,
	secondaryPrice: AcademicYearTuitionOrderField.SecondaryPrice,
};
