import React from "react";
import { ApplyCreateFunction, ApplyUpdateFunction, onCreateSubmit, onUpdateSubmit } from "@hex-insights/app-modules";
import { Button, Column, Icon, If, Row } from "@hex-insights/core";
import {
	anyFieldEditing,
	everyFieldEditing,
	Form,
	FormState,
	FormSubmitFunction,
	FormType,
	SubFormField,
	SubFormRenderProps,
	SubmissionStatus,
	SubmitButton,
} from "@hex-insights/forms";
import {
	DiscountFormat,
	DiscountFormValues,
	ServiceSubscriptionDetailQuery,
	ServiceSubscriptionFormat,
	ServiceSubscriptionFormConversion,
	ServiceSubscriptionFormState,
	ServiceSubscriptionFormValues,
	useServiceSelectQuery,
} from "../../../../Utilities";
import { FormActionRow, FormColumn } from "../../../FormAddon";
import { DiscountForm } from "../Discount";
import * as Field from "./Field";
import styles from "./styles.module.css";

export type ControlledCreateProps = {
	applyCreate: ApplyCreateFunction<ServiceSubscriptionFormValues.Create>;
	onSuccess: () => void;
};

/**
 * Renders the create form of the ServiceSubscription model using an internally managed form state.
 */
export function ControlledCreate(props: ControlledCreateProps) {
	const formState = ServiceSubscriptionFormState.useCreateFormState();

	return <Create formState={formState} {...props} />;
}

export type CreateProps = ControlledCreateProps & {
	formState: FormState<ServiceSubscriptionFormValues.Create>;
	formNameSuffix?: string;
};

/**
 * Renders the create form of the ServiceSubscription model using the given form state.
 */
export function Create({ formState, applyCreate, onSuccess, formNameSuffix = "" }: CreateProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<ServiceSubscriptionFormValues.Create>>(
		async ({ formValues }) => {
			return onCreateSubmit(formValues, applyCreate);
		},
		[applyCreate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	return (
		<Form
			name={"serviceSubscription.create" + formNameSuffix}
			formState={formState}
			onSubmit={formState.onSubmitWrapper(onSubmit)}
		>
			<FormColumn>
				<Field.Contract formState={formState} formType={FormType.Create} />
				<CreateFields formState={formState} />

				<FormActionRow>
					<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
						Submit
					</SubmitButton>
				</FormActionRow>
			</FormColumn>
		</Form>
	);
}

export type CreateBulkForContractProps = {
	applyCreate: ApplyCreateFunction<ServiceSubscriptionFormValues.CreateBulkForContract>;
	onSuccess: () => void;
	formState: FormState<ServiceSubscriptionFormValues.CreateBulkForContract>;
	formNameSuffix?: string;
	contractIDDisabled?: boolean;
};

export function CreateBulkForContract({
	formState,
	applyCreate,
	onSuccess,
	formNameSuffix,
	contractIDDisabled,
}: CreateBulkForContractProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<ServiceSubscriptionFormValues.CreateBulkForContract>>(
		async ({ formValues }) => {
			return onCreateSubmit(formValues, applyCreate);
		},
		[applyCreate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	return (
		<Form
			name={"serviceSubscription.create.bulk" + formNameSuffix}
			formState={formState}
			onSubmit={formState.onSubmitWrapper(onSubmit)}
		>
			<Column justify="spaced-start">
				<Field.Contract formState={formState} disabled={contractIDDisabled} />
				<SubFormField
					formState={formState}
					name="serviceSubscriptions"
					label="Services"
					formattedBlank=""
					dividerElement={<div style={{ marginBottom: "0.5rem" }} />}
					minItems={1}
					blankItem={ServiceSubscriptionFormValues.initialCreateInContract}
					className={styles["create-bulk__service-subscriptions"]}
				>
					{CreateInContract}
					{({ onClick, disabled }) => (
						<Row justify="center" style={{ padding: "0.5rem 0" }}>
							<Button variant="secondary" onClick={onClick} disabled={disabled}>
								Add Service
							</Button>
						</Row>
					)}
				</SubFormField>

				<Row
					justify="flex-end"
					style={{ width: "fit-content", marginLeft: "auto", position: "sticky", bottom: "1rem", zIndex: 10 }}
				>
					<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
						Submit
					</SubmitButton>
				</Row>
			</Column>
		</Form>
	);
}

export type CreateInContractProps = SubFormRenderProps<ServiceSubscriptionFormValues.CreateInContract>;

export function CreateInContract({ formState, onRemoveClick }: CreateInContractProps) {
	return (
		<Column
			justify="spaced-start"
			style={{
				padding: "0.25rem 0.5rem",
				border: "1px solid #ddd",
				borderRadius: "var(--general__tile---border-radius)",
			}}
		>
			<Row justify="center">
				<Button variant="tertiary" size="small" onClick={onRemoveClick}>
					<Row justify="spaced-start" horizontalSpacing="0.25rem" align="center">
						<Icon.Trash size="1rem" style={{ display: "block" }} /> Remove Service
					</Row>
				</Button>
			</Row>

			<CreateFields formState={formState} />
		</Column>
	);
}

function CreateFields({
	formState,
}: {
	formState: FormState<Omit<ServiceSubscriptionFormValues.Create, "contractID">>;
}) {
	const { data } = useServiceSelectQuery();
	const selectedServicePrice =
		data?.serviceConnection.edges.find(({ node }) => node.id === formState.formValues.serviceID)?.node.price ?? null;
	const { price: setPrice } = formState.formSetFunctions;
	React.useEffect(() => {
		setPrice(selectedServicePrice);
	}, [setPrice, selectedServicePrice]);

	const discountTotal = formState.formValues.discounts.reduce((a, e) => a + (e.amount ?? 0), 0);
	const finalPrice = (formState.formValues.price ?? 0) - discountTotal;

	return (
		<Column justify="spaced-start">
			<Row justify="spaced-start">
				<Field.Service formState={formState} formType={FormType.Create} />
				<Field.Price formState={formState} formType={FormType.Create} />
			</Row>
			<SubFormField
				formState={formState}
				name="discounts"
				formattedBlank=""
				blankItem={DiscountFormValues.initialCreateInServiceSubscription}
				className={styles["create__discounts"]}
			>
				{DiscountFields}
				{({ onClick, disabled }) => (
					<Row justify="space-between" style={{ padding: "0 1rem" }}>
						<Button variant="secondary" size="small" onClick={onClick} disabled={disabled}>
							Add Discount
						</Button>

						<If condition={discountTotal > 0}>
							<span style={{ fontSize: "1rem" }}>
								Discount Total: <strong>{DiscountFormat.Fields.amount(discountTotal)}</strong>
							</span>
						</If>
					</Row>
				)}
			</SubFormField>
			<Row justify="center" style={{ fontSize: "1rem" }}>
				<span>
					Final Price: <strong>{ServiceSubscriptionFormat.Fields.price(finalPrice)}</strong>
				</span>
			</Row>
		</Column>
	);
}

function DiscountFields(props: SubFormRenderProps<DiscountFormValues.CreateInServiceSubscription>) {
	return <DiscountForm.CreateInServiceSubscription {...props} className={styles["create__discounts__item"]} />;
}

export type ControlledDetailProps = {
	serviceSubscription: ServiceSubscriptionDetailQuery["serviceSubscription"];
	applyUpdate: ApplyUpdateFunction<ServiceSubscriptionFormValues.Detail>;
	onSuccess: () => void;
};

/**
 * Renders the detail form of the ServiceSubscription model using an internally managed form state.
 */
export function ControlledDetail(props: ControlledDetailProps) {
	const initialFormValues = React.useMemo(
		() => ServiceSubscriptionFormConversion.toFormValues(props.serviceSubscription),
		[props.serviceSubscription],
	);
	const formState = ServiceSubscriptionFormState.useDetailFormState({ initialFormValues });

	return <Detail formState={formState} {...props} />;
}

export type DetailProps = ControlledDetailProps & {
	formState: FormState<ServiceSubscriptionFormValues.Detail>;
};

/**
 * Renders the detail form of the ServiceSubscription model using the given form state.
 */
export function Detail({ formState, serviceSubscription, applyUpdate, onSuccess }: DetailProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<ServiceSubscriptionFormValues.Detail>>(
		async (formState) => {
			return onUpdateSubmit(formState, applyUpdate);
		},
		[applyUpdate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	const { anyEditing, everyEditing } = React.useMemo(() => {
		return {
			anyEditing: anyFieldEditing({
				formEditing: formState.formEditing,
				formSubFormStates: formState.formSubFormStates,
			}),
			everyEditing: everyFieldEditing({
				formEditing: formState.formEditing,
				formSubFormStates: formState.formSubFormStates,
			}),
		};
	}, [formState.formEditing, formState.formSubFormStates]);

	return (
		<Form
			name={`serviceSubscription.detail.${serviceSubscription.id}`}
			formState={formState}
			onSubmit={formState.onSubmitWrapper(onSubmit)}
		>
			<Column justify="spaced-start">
				<Row justify="spaced-start" horizontalSpacing="0.75rem">
					<If condition={!everyEditing}>
						<Button variant="tertiary" size="small" onClick={() => formState.setFormEditing(true)}>
							Edit
						</Button>
					</If>
					<If condition={anyEditing}>
						<Button variant="tertiary" size="small" onClick={() => formState.setFormEditing(false)}>
							Stop Editing
						</Button>
					</If>
				</Row>

				<Field.Contract
					formState={formState}
					formType={FormType.Update}
					id={serviceSubscription.id}
					currentContract={serviceSubscription.contract}
				/>
				<Field.Service
					formState={formState}
					formType={FormType.Update}
					id={serviceSubscription.id}
					currentService={serviceSubscription.service}
				/>
				<Field.Price formState={formState} formType={FormType.Update} id={serviceSubscription.id} />
				<div style={{ display: "none" }}>
					<Field.Discounts
						formState={formState}
						formType={FormType.Update}
						id={serviceSubscription.id}
						currentDiscounts={serviceSubscription.discounts}
					/>
				</div>
			</Column>
		</Form>
	);
}

export type ControlledReadOnlyProps = {
	serviceSubscription: ServiceSubscriptionDetailQuery["serviceSubscription"];
};

/**
 * Renders a read-only detail form of the ServiceSubscription model using an internally managed form state.
 */
export function ControlledReadOnly(props: ControlledReadOnlyProps) {
	const initialFormValues = React.useMemo(
		() => ServiceSubscriptionFormConversion.toFormValues(props.serviceSubscription),
		[props.serviceSubscription],
	);
	const formState = ServiceSubscriptionFormState.useReadOnlyFormState({ initialFormValues });

	return <ReadOnly formState={formState} {...props} />;
}

export type ReadOnlyProps = ControlledReadOnlyProps & {
	formState: FormState<ServiceSubscriptionFormValues.Detail>;
};

/**
 * Renders a read-only detail form of the ServiceSubscription model using the given form state.
 */
export function ReadOnly({ formState, serviceSubscription }: ReadOnlyProps) {
	return (
		<Column justify="spaced-start">
			<Field.Price formState={formState} formType={FormType.Update} id={serviceSubscription.id} />
			<Field.Discounts
				formState={formState}
				formType={FormType.Update}
				id={serviceSubscription.id}
				currentDiscounts={serviceSubscription.discounts}
			/>
			<Field.Service
				formState={formState}
				formType={FormType.Update}
				id={serviceSubscription.id}
				currentService={serviceSubscription.service}
			/>
			<Field.Contract
				formState={formState}
				formType={FormType.Update}
				id={serviceSubscription.id}
				currentContract={serviceSubscription.contract}
			/>
		</Column>
	);
}
