import { IndexForms } from "@hex-insights/app-modules";
import { TypeFilterObject } from "@hex-insights/core";
import { RelationshipFormValues } from "../ModelFormValues";

export type StandardFilterFormValues = {}; // TODO define quick filters

export type FormValues = StandardFilterFormValues & IndexForms.FilterFormValues;

export const initialStandardFilters: StandardFilterFormValues = {};

export const initial: FormValues = {
	...initialStandardFilters,
	...IndexForms.initialFilterFormValues,
};

export function getInitial(): FormValues {
	return initial;
}

export const quickFiltersTypeFilterObject: TypeFilterObject<StandardFilterFormValues> = {};

export const typeFilterObject: TypeFilterObject<FormValues> = {
	...quickFiltersTypeFilterObject,
	...IndexForms.filterFormValuesFilterObject,
};

export const advancedFilterInformation: IndexForms.AdvancedFilterInformation = {
	fieldOptions: [
		{ value: "relationshipType" },
		{ value: "isPrimary" },
		{ value: "isFinancialContact" },
		{ value: "isEmergencyContact" },
		{ value: "isAuthorizedForPickup" },
		{ value: "livesWithStudent" },
	],
	fieldComparisonOptions: {
		relationshipType: IndexForms.enumComparisonOptions,
		isPrimary: IndexForms.booleanComparisonOptions,
		isFinancialContact: IndexForms.booleanComparisonOptions,
		isEmergencyContact: IndexForms.booleanComparisonOptions,
		isAuthorizedForPickup: IndexForms.booleanComparisonOptions,
		livesWithStudent: IndexForms.booleanComparisonOptions,
	},
	fieldComparisonTranslations: {
		relationshipType: IndexForms.getRadioComparisonTranslations(RelationshipFormValues.relationshipTypeOptions),
		isPrimary: IndexForms.booleanComparisonTranslations,
		isFinancialContact: IndexForms.booleanComparisonTranslations,
		isEmergencyContact: IndexForms.booleanComparisonTranslations,
		isAuthorizedForPickup: IndexForms.booleanComparisonTranslations,
		livesWithStudent: IndexForms.booleanComparisonTranslations,
	},
};
