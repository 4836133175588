import { newPageInfo } from "@hex-insights/router";
import { rootPageInfo } from "../../../root.pageinfo";

export const logoutPageInfo = newPageInfo({
	title: "Logout",
	description: "Logging out.",
	exact: true,
	path: "/logout",
	to: "/logout",
	parent: rootPageInfo,
	noSearch: true,
});
