import React from "react";
import { Formats } from "react-big-calendar";
import { dateTrunc, formatDateTime, makeClassName } from "@hex-insights/core";
import { BigCalendar, BigCalendarProps } from "../BigCalendar";
import "./styles.css";

export * from "./utils";

const formats: Formats = {
	dayFormat: (date) => {
		return formatDateTime(date, "dddd");
	},
};

export type WeeklyCourseScheduleCalendarProps = Omit<
	BigCalendarProps,
	"views" | "defaultView" | "min" | "max" | "getNow" | "formats"
>;

export function WeeklyCourseScheduleCalendar({ containerClassName, ...props }: WeeklyCourseScheduleCalendarProps) {
	const { minDate, maxDate } = React.useMemo(() => {
		const minDate = new Date();
		minDate.setHours(7, 0, 0, 0);
		const maxDate = new Date();
		maxDate.setHours(19, 0, 0, 0);
		return { minDate, maxDate };
	}, []);

	return (
		<BigCalendar
			views={["work_week"]}
			defaultView="work_week"
			min={minDate}
			max={maxDate}
			getNow={getNow}
			formats={formats}
			containerClassName={makeClassName("term-setup-schedule-view__calendar", containerClassName)}
			{...props}
		/>
	);
}

/**
 * Makes the calendar see Sunday as "now" so that current time/day is not hightlighted in the calendar.
 */
function getNow() {
	return dateTrunc(new Date(), "week").toDate();
}
