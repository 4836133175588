import React from "react";
import { ChildrenProps, DefaultContextValue, omitKeys } from "@hex-insights/core";
import { Employee, useEmployeeContextQuery } from "../../Utilities";

export type EmployeeWrapperContextValue = {
	isLoading: boolean;
	employee:
		| (Pick<Employee, "id" | "email" | "jobTitle" | "role"> & {
				person: Pick<Employee["person"], "id" | "name" | "nickname">;
				campuses: Pick<Employee["campuses"][0], "id" | "name">[];
		  })
		| null;
};

export const EmployeeWrapperContext = React.createContext<EmployeeWrapperContextValue>(
	new DefaultContextValue("EmployeeWrapperContext") as any,
);

export type EmployeeWrapperContextManagerProps = Partial<ChildrenProps>;

/**
 * Fetches the current user's employee information. Meant to wrap the entire `<App>` component so that fetching can
 * happen concurrently with auth user fetching.
 */
export function EmployeeWrapperContextManager({ children }: EmployeeWrapperContextManagerProps) {
	const { loading, data, error } = useEmployeeContextQuery();
	const hasError = !!error;

	const contextValue = React.useMemo(() => {
		if (loading || hasError) {
			return { isLoading: true, employee: null };
		}
		if (!data || !data.viewer.person || !data.viewer.person.employee) {
			return { isLoading: false, employee: null };
		}
		const employee = {
			...data.viewer.person.employee,
			person: {
				...omitKeys(data.viewer.person, ["employee"]),
			},
		};
		return {
			isLoading: false,
			employee: employee,
		};
	}, [loading, data, hasError]);

	return <EmployeeWrapperContext.Provider value={contextValue}>{children}</EmployeeWrapperContext.Provider>;
}

export type EmployeeContextValue = {
	employee: Exclude<EmployeeWrapperContextValue["employee"], null>;
};

export const EmployeeContext = React.createContext<EmployeeContextValue>(
	new DefaultContextValue("EmployeeContext") as any,
);
