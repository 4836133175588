import { getArrayDiff, NullPartial, omitKeys } from "@hex-insights/core";
import { LessonPlanCreateInput, LessonPlanDetailQuery, LessonPlanUpdateInput } from "../../GraphQL";
import { LessonPlanFormValues } from "../ModelFormValues";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the LessonPlan model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(formValues: LessonPlanFormValues.Create): LessonPlanCreateInput {
	const input: NullPartial<LessonPlanCreateInput> = { ...formValues };
	return input as LessonPlanCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the LessonPlan model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<LessonPlanFormValues.Detail>,
	initialFormValues: LessonPlanFormValues.Detail,
): LessonPlanUpdateInput {
	const inputFormValues = omitKeys(formValues, ["learningObjectiveIDs"]);
	const input: LessonPlanUpdateInput = { ...inputFormValues };

	if (formValues.learningObjectiveIDs !== undefined) {
		const { added, removed } = getArrayDiff(initialFormValues.learningObjectiveIDs, formValues.learningObjectiveIDs);
		input.addLearningObjectiveIDs = added;
		input.removeLearningObjectiveIDs = removed;
	}

	return input;
}

/**
 * Converts a LessonPlan instance to its corresponding detail form values.
 *
 * @param lessonPlan The instance to convert.
 */
export function toFormValues(lessonPlan: LessonPlanDetailQuery["lessonPlan"]): LessonPlanFormValues.Detail {
	return {
		courseDate: lessonPlan.courseDate,
		lessonDescription: lessonPlan.lessonDescription,
		requiredResourcesAndMaterials: lessonPlan.requiredResourcesAndMaterials,
		stationOneDescription: lessonPlan.stationOneDescription,
		stationTwoDescription: lessonPlan.stationTwoDescription,
		stationThreeDescription: lessonPlan.stationThreeDescription,
		differentiationDescription: lessonPlan.differentiationDescription,
		assessmentDescription: lessonPlan.assessmentDescription,
		notes: lessonPlan.notes,
		courseSectionID: lessonPlan.courseSection?.id ?? null,
		learningObjectiveIDs: lessonPlan.learningObjectives.map((e) => e.id),
	};
}
