import {
	addTimeToDate,
	Column,
	Conditional,
	dateTrunc,
	Else,
	formatDateTime,
	Heading,
	If,
	Modal,
	ModalProps,
	Row,
	stringToLocalDate,
} from "@hex-insights/core";
import { CourseSection, LessonPlan, useLessonPlanDetailQuery } from "../../Utilities";
import { LessonPlanForm } from "../Forms";
import styles from "./styles.module.css";

export type LessonPlansWeekCourseSectionPreviewModalProps = {
	courseSectionName: CourseSection["name"];
	date: Date;
	dates: string[];
	dateLessonPlanIDMap: Record<string, LessonPlan["id"] | null>;
} & Pick<ModalProps, "ifRef" | "onClose">;

export function LessonPlansWeekCourseSectionPreviewModal({
	courseSectionName,
	date,
	dates,
	dateLessonPlanIDMap,
	ifRef,
	onClose,
}: LessonPlansWeekCourseSectionPreviewModalProps) {
	return (
		<Modal
			size="large"
			ifRef={ifRef}
			onClose={onClose}
			className={styles["lesson-plans-week-course-section-preview-modal"]}
		>
			<Modal.Header>
				<Heading.H2 noMargin>
					{courseSectionName}: Week of{" "}
					{formatDateTime(addTimeToDate(dateTrunc(dates[0] ?? date, "week"), [1, "day"]), "D MMMM YYYY")}
				</Heading.H2>
			</Modal.Header>

			<Modal.Body style={{ width: "100%", overflow: "scroll" }}>
				<Row justify="spaced-start" className={styles["lesson-plans-week-course-section-preview-modal__items-row"]}>
					{dates.map((date) => (
						<LessonPlanPreview key={date} date={date} lessonPlanID={dateLessonPlanIDMap[date] ?? null} />
					))}
				</Row>
			</Modal.Body>
		</Modal>
	);
}

export type LessonPlanPreviewProps = {
	date: string;
	lessonPlanID: LessonPlan["id"] | null;
};

export function LessonPlanPreview({ date, lessonPlanID }: LessonPlanPreviewProps) {
	if (!lessonPlanID) {
		return (
			<Column className={styles["lesson-plans-week-course-section-preview-modal__item"]}>
				<Heading.H4 noMargin>{formatDateTime(stringToLocalDate(date, "day") ?? new Date(), "ddd, D MMM")}</Heading.H4>

				<span style={{ color: "#777" }}>No lesson plan</span>
			</Column>
		);
	}

	return <LessonPlanPreviewWithLessonPlan lessonPlanID={lessonPlanID} />;
}

type LessonPlanPreviewWithLessonPlanProps = {
	lessonPlanID: LessonPlan["id"];
};

function LessonPlanPreviewWithLessonPlan({ lessonPlanID }: LessonPlanPreviewWithLessonPlanProps) {
	const { loading, data } = useLessonPlanDetailQuery({ variables: { id: lessonPlanID } });

	return (
		<Column className={styles["lesson-plans-week-course-section-preview-modal__item"]}>
			<Conditional>
				<If condition={loading}>Loading...</If>
				<If condition={!data}>No lesson plan</If>
				<Else>
					{!!data && (
						<Column justify="spaced-start">
							<LessonPlanForm.ControlledPreviewReadOnly lessonPlan={data.lessonPlan} headingLevel={4} />
						</Column>
					)}
				</Else>
			</Conditional>
		</Column>
	);
}
