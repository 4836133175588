import React from "react";
import { ApplyCreateFunction, onCreateSubmit } from "@hex-insights/app-modules";
import { Column, Row, StyleProps } from "@hex-insights/core";
import { Form, FormState, FormSubmitFunction, SubmissionStatus, SubmitButton } from "@hex-insights/forms";
import { Employee, Maybe, Note, NoteFormState, NoteFormValues, Person, Student, User } from "../../../../../Utilities";
import { TextAreaField, TextAreaFieldDisplay } from "../../../../TextAreaField";
import * as Field from "../Field";
import styles from "../styles.module.css";

export type ControlledCreateProps = {
	applyCreate: ApplyCreateFunction<NoteFormValues.Health.Create>;
	onSuccess: () => void;
} & Partial<StyleProps>;

export function ControlledCreate(props: ControlledCreateProps) {
	const formState = NoteFormState.Health.useCreateFormState();

	return <Create formState={formState} {...props} />;
}

export type CreateProps = ControlledCreateProps & {
	formState: FormState<NoteFormValues.Health.Create>;
};

/**
 * Renders the create form of the Note model for a financial-type note using the given form state.
 */
export function Create({ formState, applyCreate, onSuccess, style }: CreateProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<NoteFormValues.Health.Create>>(
		async ({ formValues }) => {
			return onCreateSubmit(formValues, applyCreate);
		},
		[applyCreate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	return (
		<Form
			name="note.create.financial"
			formState={formState}
			onSubmit={formState.onSubmitWrapper(onSubmit)}
			className={styles["note-form"]}
			style={style}
		>
			<Column justify="spaced-start" verticalSpacing="2rem">
				<Column justify="spaced-start">
					<Field.Students formState={formState} />
					<TextAreaField formState={formState} name="body" label="Note" minRows={3} />
				</Column>

				<Row justify="flex-end" style={{ position: "sticky", bottom: "1rem" }}>
					<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
						Submit
					</SubmitButton>
				</Row>
			</Column>
		</Form>
	);
}

export type ReadOnlyProps = {
	note: Pick<Note, "body" | "createdAt"> & {
		author: Maybe<
			Pick<User, "id" | "email"> & {
				person?: Maybe<
					Pick<Person, "name"> & {
						employee?: Maybe<Pick<Employee, "id">>;
					}
				>;
			}
		>;
		students: (Pick<Student, "id"> & { person: Pick<Student["person"], "name"> })[];
	};
};

export function ReadOnly({ note }: ReadOnlyProps) {
	return (
		<Column justify="spaced-start" verticalSpacing="2rem" className={styles["note-form"]}>
			<TextAreaFieldDisplay value="" formattedValue={note.body} />
		</Column>
	);
}
