// Code generated by @hex-insights/frontend-generator; DO NOT EDIT.
import { FormState } from "@hex-insights/forms";
import { SearchResultFilterInput } from "@hex-insights/verita.shared";

export type ModelNameFiltersFormValues = {};

export const initialModelNameFiltersFormValues: ModelNameFiltersFormValues = {};

export function checkAllModels(_formState: FormState<ModelNameFiltersFormValues>) {}

export function uncheckAllModels(_formState: FormState<ModelNameFiltersFormValues>) {}

type ModelNameFilterInputsProps = {
	formState: FormState<ModelNameFiltersFormValues>;
};

export function ModelNameFilterInputs(_: ModelNameFilterInputsProps) {
	return null;
}

export const allModelNamesFilterInput = {
	modelNameIn: [],
};

export function convertModelNameFilterFormValuesToFilterInput(_formValues: ModelNameFiltersFormValues) {
	const modelNames: SearchResultFilterInput["modelNameIn"] = [];

	if (modelNames.length === 0) {
		return allModelNamesFilterInput.modelNameIn;
	}

	return modelNames;
}
