import { NullPartial } from "@hex-insights/core";
import { MealPlanCreateInput, MealPlanDetailQuery, MealPlanUpdateInput } from "../../GraphQL";
import { MealPlanFormValues } from "../ModelFormValues";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the MealPlan model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(formValues: MealPlanFormValues.Create): MealPlanCreateInput {
	const input: NullPartial<MealPlanCreateInput> = { ...formValues };
	return input as MealPlanCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the MealPlan model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<MealPlanFormValues.Detail>,
	_initialFormValues: MealPlanFormValues.Detail,
): MealPlanUpdateInput {
	const input: MealPlanUpdateInput = { ...formValues };
	return input;
}

/**
 * Converts a MealPlan instance to its corresponding detail form values.
 *
 * @param mealPlan The instance to convert.
 */
export function toFormValues(mealPlan: MealPlanDetailQuery["mealPlan"]): MealPlanFormValues.Detail {
	return {
		notes: mealPlan.notes,
		studentID: mealPlan.student?.id ?? null,
	};
}
