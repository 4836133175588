import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import {
	Column,
	compareObjects,
	compareStringsAsc,
	Conditional,
	Else,
	filterAndSortByQuery,
	getObjectValues,
	Grid,
	Heading,
	If,
	Modal,
	ModalProps,
	Row,
	Section,
} from "@hex-insights/core";
import { FormState, TextField, useFormState, ValidationDisplayPolicy } from "@hex-insights/forms";
import {
	HomeRoom,
	HomeRoomFilterInput,
	HomeRoomSectionFilterInput,
	StudentFilterFormState,
	StudentFilterFormUtils,
	StudentFilterFormValues,
	StudentListQuery,
	useStudentListQuery,
} from "../../Utilities";
import { StudentCampusFilterButtons, StudentHomeRoomFilterButtons } from "../Forms";
import { HR } from "../HR";
import { StudentLink } from "../Links";
import { PersonIcon } from "../PersonIcon";

export type ControlledStudentListModalProps = {
	initialFilterFormValues?: Partial<StudentFilterFormValues.FormValues>;
} & Omit<StudentListModalProps, "filterFormState" | "isLoading" | "edges">;

export function ControlledStudentListModal({ initialFilterFormValues, ...props }: ControlledStudentListModalProps) {
	const filterFormState = StudentFilterFormState.useFormStateWithoutQueryStateSync(initialFilterFormValues);
	const filterInputs = IndexForms.useFilterInput(filterFormState.formValues, StudentFilterFormUtils.toFilterInputs);
	const { loading, data } = useStudentListQuery({ variables: { filters: filterInputs } });

	return (
		<StudentListModal
			filterFormState={filterFormState}
			isLoading={loading}
			edges={data?.studentConnection.edges}
			{...props}
		/>
	);
}

type SearchFormValues = {
	search: string;
};

const initialSearchFormValues: SearchFormValues = {
	search: "",
};

const gridResponsiveColumns = {
	0: 1,
	500: 2,
	800: 3,
	1000: 4,
	1200: 5,
};

type StudentListFilterFormValues = Pick<
	StudentFilterFormValues.FormValues,
	"campusIDs" | "homeRoomIDs" | "homeRoomSectionIDs"
>;

export type StudentListModalProps = {
	title: React.ReactNode;
	filterFormState?: FormState<StudentListFilterFormValues>;
	isLoading?: boolean;
	edges: StudentListQuery["studentConnection"]["edges"] | undefined;
	noCampusFilters?: boolean;
	noHomeRoomFilters?: boolean;
	homeRoomFilterInputs?: HomeRoomFilterInput | HomeRoomFilterInput[];
	homeRoomSectionFilterInputs?: HomeRoomSectionFilterInput | HomeRoomSectionFilterInput[];
} & Pick<ModalProps, "ifRef" | "onClose">;

export function StudentListModal({
	title,
	filterFormState,
	isLoading = false,
	edges,
	noCampusFilters = false,
	noHomeRoomFilters = false,
	homeRoomFilterInputs,
	homeRoomSectionFilterInputs,
	ifRef,
	onClose,
}: StudentListModalProps) {
	const searchFormState = useFormState({
		initialFormValues: initialSearchFormValues,
		validationDisplayPolicy: ValidationDisplayPolicy.none,
	});

	const { search: searchValue } = searchFormState.formValues;
	const filteredStudentEdges = React.useMemo(() => {
		if (!edges) {
			return [];
		}
		return filterAndSortByQuery(searchValue, edges, (e) => e.node.person.name);
	}, [searchValue, edges]);

	const homeRoomGroups = React.useMemo(() => {
		const homeRoomGroupsObj: Record<
			HomeRoom["id"],
			Pick<HomeRoom, "id" | "name"> & { students: StudentListQuery["studentConnection"]["edges"][0]["node"][] }
		> = {};
		for (let i = 0; i < filteredStudentEdges.length; i++) {
			const student = filteredStudentEdges[i].node;
			const homeRoom = student.homeRoomSectionStudentEnrollments[0]?.homeRoomSection.homeRoom ?? {
				id: "other",
				name: "Other",
			};
			if (!(homeRoom.id in homeRoomGroupsObj)) {
				homeRoomGroupsObj[homeRoom.id] = { ...homeRoom, students: [] };
			}
			homeRoomGroupsObj[homeRoom.id].students.push(student);
		}
		return getObjectValues(homeRoomGroupsObj).sort(compareObjects("name", compareStringsAsc()));
	}, [filteredStudentEdges]);

	return (
		<Modal ifRef={ifRef} onClose={onClose} style={{ width: "80vw", maxWidth: "none", height: "90vh" }}>
			<Modal.Header>
				<Heading.H2 noMargin>{title}</Heading.H2>
			</Modal.Header>
			<Modal.Body style={{ height: "100%", overflow: "hidden" }}>
				<Column justify="spaced-start" style={{ height: "100%", overflow: "hidden" }}>
					<Column justify="spaced-start">
						<TextField formState={searchFormState} name="search" autoFocus />
						{!!filterFormState && (
							<Row justify="spaced-start" align="spaced-start" overflow="wrap">
								<If condition={!noCampusFilters}>
									<StudentCampusFilterButtons formState={filterFormState} />
								</If>
								<If condition={!noCampusFilters && !noHomeRoomFilters}>
									<HR color="#eee" style={{ height: "45px" }} />
								</If>
								<If condition={!noHomeRoomFilters}>
									<StudentHomeRoomFilterButtons
										filters={homeRoomFilterInputs}
										sectionFilters={homeRoomSectionFilterInputs}
										formState={filterFormState}
									/>
								</If>
							</Row>
						)}
					</Column>

					<Conditional>
						<If condition={isLoading}>Loading...</If>
						<Else>
							<Section style={{ height: "100%", overflow: "hidden" }}>
								<Section.Body style={{ height: "100%", overflow: "scroll" }}>
									{homeRoomGroups.map((group, i) => (
										<div key={group.id}>
											<If condition={homeRoomGroups.length > 1}>
												<Heading.H3
													style={{
														marginTop: i === 0 ? 0 : undefined,
														marginBottom: "0.5rem",
													}}
												>
													{group.name}
												</Heading.H3>
											</If>
											<Grid responsiveColumns={gridResponsiveColumns} gap="0.5rem">
												{group.students.map((student) => (
													<StudentLink key={student.id} instance={student} className="link--no-text-decoration">
														<PersonIcon person={student.person} withName withTooltipPreview studentID={student.id} />
													</StudentLink>
												))}
											</Grid>
										</div>
									))}
								</Section.Body>
							</Section>
						</Else>
					</Conditional>
				</Column>
			</Modal.Body>
		</Modal>
	);
}
