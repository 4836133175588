import { addTimeToDate, toLocalDateString } from "@hex-insights/core";
import { SchoolAttendanceRecordDetailQuery, Student, useSchoolAttendanceRecordIndexQuery } from "../../../../Utilities";

export enum AttendanceStatus {
	Present,
	Absent,
	Unknown,
}

export function useCurrentSchoolAttendance(studentID: Student["id"]) {
	const { loading, data } = useSchoolAttendanceRecordIndexQuery({
		variables: {
			filters: {
				studentIDEQ: studentID,
				checkInTimeGTE: toLocalDateString(new Date()),
				checkInTimeLT: toLocalDateString(addTimeToDate(new Date(), [1, "day"])),
			},
		},
	});

	let status = AttendanceStatus.Unknown;
	let record: SchoolAttendanceRecordDetailQuery["schoolAttendanceRecord"] | null = null;
	if (data && data.schoolAttendanceRecordConnection.edges.length > 0) {
		record = data.schoolAttendanceRecordConnection.edges[0].node;
		status = record.isPresent ? AttendanceStatus.Present : AttendanceStatus.Absent;
	}

	return { loading, schoolAttendanceRecord: record, schoolAttendanceStatus: status };
}
