import { newPageInfo } from "@hex-insights/router";
import { Student } from "@hex-insights/verita.shared";
import { studentHubPageInfo, StudentHubPageRouteParams } from "../pageinfo";

export type StudentHubDetailPageRouteParams = StudentHubPageRouteParams & {
	id: string;
};

type StudentForRouteTitle = Pick<Student, "id"> & { person: Pick<Student["person"], "name"> };

export const studentHubDetailPageInfo = newPageInfo({
	name: "Student Detail",
	title: (e: StudentForRouteTitle) => e.person.name,
	description: "Detail page for a student.",
	exact: false,
	to: (id: Student["id"]) => studentHubPageInfo.to + `/${id}`,
	path: studentHubPageInfo.path + "/:id(\\d+)",
	parent: studentHubPageInfo,
});
