import { newPageInfo } from "@hex-insights/router";
import { lessonPlansWeekPageInfo, LessonPlansWeekPageRouteParams } from "../pageinfo";

export type LessonPlansWeekIndexPageRouteParams = LessonPlansWeekPageRouteParams;

export const lessonPlansWeekIndexPageInfo = newPageInfo({
	name: "Week Index Page",
	title: "",
	description: "Overview of a week.",
	exact: true,
	to: lessonPlansWeekPageInfo.to,
	path: lessonPlansWeekPageInfo.path,
	parent: lessonPlansWeekPageInfo,
});
