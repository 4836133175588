import { Room } from "../../../GraphQL";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the name field of the Room model.
 */
export function name(value: Room["name"]): string {
	return toString(value);
}

/**
 * Formats the number field of the Room model.
 */
export function number(value: Room["number"]): string {
	return toString(value);
}
