import { useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import { StudentHub } from "@hex-insights/verita.shared";
import { studentHubDetailAcademicsPageInfo, StudentHubDetailAcademicsPageRouteParams } from "./pageinfo";

export function AcademicsMain() {
	useActivePageRegistration(studentHubDetailAcademicsPageInfo);

	const { id } = useRouteParams<StudentHubDetailAcademicsPageRouteParams>();

	return <StudentHub.Detail.Academics.Main studentID={id} />;
}

export function AcademicsLeftColumn() {
	const { id } = useRouteParams<StudentHubDetailAcademicsPageRouteParams>();

	return <StudentHub.Detail.Academics.LeftColumn studentID={id} />;
}
