import { IndexForms } from "@hex-insights/app-modules";
import { CaseStyle, convertCase } from "@hex-insights/core";
import { OrderDirection, UserOrderField } from "../../GraphQL";

export type FormValues = IndexForms.OrderFormValues<UserOrderField | null>;

export const initial: FormValues = {
	field: UserOrderField.Email,
	direction: OrderDirection.Asc,
};

/**
 * Options for the "field" field in the order form of the User model.
 */
export const fieldOptions = [
	{ label: "ID", value: null },
	{ label: convertCase(UserOrderField.Email.toLowerCase(), CaseStyle.Title), value: UserOrderField.Email },
	{ label: convertCase(UserOrderField.Status.toLowerCase(), CaseStyle.Title), value: UserOrderField.Status },
	{
		label: convertCase(UserOrderField.PasswordUpdatedAt.toLowerCase(), CaseStyle.Title),
		value: UserOrderField.PasswordUpdatedAt,
	},
];

/**
 * Array of values for the "field" field in the order form of the User model.
 */
export const fieldValues = fieldOptions.map((e) => e.value);

/**
 * Mapping from order fields of the User model to their corresponding order type.
 */
export const fieldTypeMapping = {
	[UserOrderField.Email]: IndexForms.OrderFieldType.String,
	[UserOrderField.Status]: IndexForms.OrderFieldType.String,
	[UserOrderField.PasswordUpdatedAt]: IndexForms.OrderFieldType.Time,
};

/**
 * Mapping from order fields of the User model to their corresponding field name.
 */
export const orderFieldToModelField = {
	[UserOrderField.Email]: "email",
	[UserOrderField.Status]: "status",
	[UserOrderField.PasswordUpdatedAt]: "passwordUpdatedAt",
};

/**
 * Mapping from field names of the User model to their corresponding order field.
 */
export const modelFieldToOrderField = {
	email: UserOrderField.Email,
	status: UserOrderField.Status,
	passwordUpdatedAt: UserOrderField.PasswordUpdatedAt,
};
