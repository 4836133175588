import { EnrollmentInvitation, EnrollmentInvitationResponse, Student } from "../../GraphQL";

export type Base = {
	response: EnrollmentInvitation["response"] | null;
	studentID: Student["id"] | null;
};

export type Create = Base;

export type Detail = Base & {
	createdAt: EnrollmentInvitation["createdAt"] | null;
};

export const initialCreate: Create = {
	response: null,
	studentID: null,
};

export const responseOptions = [
	{ value: EnrollmentInvitationResponse.No },
	{ value: EnrollmentInvitationResponse.Unknown, label: "No Response" },
	{ value: EnrollmentInvitationResponse.Yes },
];
