import React from "react";
import { stringToLocalDate } from "@hex-insights/core";
import { useRouteParams } from "@hex-insights/router";
import { ScheduleDayPageRouteParams } from "./pageinfo";

export function usePageDate() {
	const { date: dateString } = useRouteParams<ScheduleDayPageRouteParams>();
	const date = React.useMemo(() => stringToLocalDate(dateString, "day"), [dateString]);
	return { date, dateISO: dateString + "T00:00:00.000Z" };
}
