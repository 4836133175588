import { IndexForms } from "@hex-insights/app-modules";
import { TypeFilterObject } from "@hex-insights/core";
import { EnrollmentApplicationStudentFormValues } from "../ModelFormValues";

export type StandardFilterFormValues = {}; // TODO define quick filters

export type FormValues = StandardFilterFormValues & IndexForms.SearchFormValues & IndexForms.FilterFormValues;

export const initialStandardFilters: StandardFilterFormValues = {};

export const initial: FormValues = {
	...initialStandardFilters,
	...IndexForms.initialSearchFormValues,
	...IndexForms.initialFilterFormValues,
};

export function getInitial(): FormValues {
	return initial;
}

export const quickFiltersTypeFilterObject: TypeFilterObject<StandardFilterFormValues> = {};

export const typeFilterObject: TypeFilterObject<FormValues> = {
	...quickFiltersTypeFilterObject,
	...IndexForms.searchFormValuesFilterObject,
	...IndexForms.filterFormValuesFilterObject,
};

export const advancedFilterInformation: IndexForms.AdvancedFilterInformation = {
	fieldOptions: [
		{ value: "firstName" },
		{ value: "lastName" },
		{ value: "nickname" },
		{ value: "dateOfBirth" },
		{ value: "gender" },
		{ value: "genderCustom" },
		{ value: "nationality" },
		{ value: "primaryLanguage" },
		{ value: "englishLanguageFluency" },
		{ value: "currentGradeLevel" },
		{ value: "gradeLevelApplyingFor" },
		{ value: "hasPreviousSchooling" },
		{ value: "previousSchoolInformation" },
		{ value: "previousSchoolLocation" },
		{ value: "hasLearningDifficulties" },
		{ value: "learningDifficultiesDescription" },
		{ value: "hasMedicalPhysicalExceptionalities" },
		{ value: "medicalPhysicalExceptionalitiesDescription" },
		{ value: "hasBehaviorDisciplineChallenges" },
		{ value: "behaviorDisciplineChallengesDescription" },
		{ value: "additionalInformation" },
	],
	fieldComparisonOptions: {
		firstName: IndexForms.stringComparisonOptions,
		lastName: IndexForms.stringComparisonOptions,
		nickname: IndexForms.stringComparisonOptions,
		dateOfBirth: IndexForms.dateComparisonOptions,
		gender: IndexForms.enumComparisonOptions,
		genderCustom: IndexForms.stringComparisonOptions,
		nationality: IndexForms.stringComparisonOptions,
		primaryLanguage: IndexForms.stringComparisonOptions,
		englishLanguageFluency: IndexForms.enumComparisonOptions,
		currentGradeLevel: [...IndexForms.enumComparisonOptions, ...IndexForms.nullableComparisonOptions],
		gradeLevelApplyingFor: [...IndexForms.enumComparisonOptions, ...IndexForms.nullableComparisonOptions],
		hasPreviousSchooling: IndexForms.booleanComparisonOptions,
		previousSchoolInformation: IndexForms.stringComparisonOptions,
		previousSchoolLocation: IndexForms.stringComparisonOptions,
		hasLearningDifficulties: IndexForms.booleanComparisonOptions,
		learningDifficultiesDescription: IndexForms.stringComparisonOptions,
		hasMedicalPhysicalExceptionalities: IndexForms.booleanComparisonOptions,
		medicalPhysicalExceptionalitiesDescription: IndexForms.stringComparisonOptions,
		hasBehaviorDisciplineChallenges: IndexForms.booleanComparisonOptions,
		behaviorDisciplineChallengesDescription: IndexForms.stringComparisonOptions,
		additionalInformation: IndexForms.stringComparisonOptions,
	},
	fieldComparisonTranslations: {
		firstName: IndexForms.stringComparisonTranslations,
		lastName: IndexForms.stringComparisonTranslations,
		nickname: IndexForms.stringComparisonTranslations,
		dateOfBirth: IndexForms.dateComparisonTranslations,
		gender: IndexForms.getRadioComparisonTranslations(EnrollmentApplicationStudentFormValues.genderOptions),
		genderCustom: IndexForms.stringComparisonTranslations,
		nationality: IndexForms.stringComparisonTranslations,
		primaryLanguage: IndexForms.stringComparisonTranslations,
		englishLanguageFluency: IndexForms.getRadioComparisonTranslations(
			EnrollmentApplicationStudentFormValues.englishLanguageFluencyOptions,
		),
		currentGradeLevel: {
			...IndexForms.getRadioComparisonTranslations(EnrollmentApplicationStudentFormValues.currentGradeLevelOptions),
			...IndexForms.nullableComparisonTranslations,
		},
		gradeLevelApplyingFor: {
			...IndexForms.getRadioComparisonTranslations(EnrollmentApplicationStudentFormValues.gradeLevelApplyingForOptions),
			...IndexForms.nullableComparisonTranslations,
		},
		hasPreviousSchooling: IndexForms.booleanComparisonTranslations,
		previousSchoolInformation: IndexForms.stringComparisonTranslations,
		previousSchoolLocation: IndexForms.stringComparisonTranslations,
		hasLearningDifficulties: IndexForms.booleanComparisonTranslations,
		learningDifficultiesDescription: IndexForms.stringComparisonTranslations,
		hasMedicalPhysicalExceptionalities: IndexForms.booleanComparisonTranslations,
		medicalPhysicalExceptionalitiesDescription: IndexForms.stringComparisonTranslations,
		hasBehaviorDisciplineChallenges: IndexForms.booleanComparisonTranslations,
		behaviorDisciplineChallengesDescription: IndexForms.stringComparisonTranslations,
		additionalInformation: IndexForms.stringComparisonTranslations,
	},
};
