import { Invoice, InvoiceNameFragment } from "../../../GraphQL";
import { formatPrice } from "../../money";
import * as Fields from "./fields";

export { Fields };

/**
 * Formats the full name of the given instance.
 */
export function name(instance: InvoiceNameFragment) {
	return Fields.invoiceNumber(instance.invoiceNumber);
}

export type InvoiceLongNameFragment = Pick<Invoice, "invoiceNumber" | "dueDate"> & {
	invoiceLineItems: Pick<Invoice["invoiceLineItems"][0], "amount">[];
};

export function longName(instance: InvoiceLongNameFragment) {
	const totalStr = formatPrice(instance.invoiceLineItems.reduce((a, e) => a + e.amount, 0));

	return `#${Fields.invoiceNumber(instance.invoiceNumber)}: ${Fields.dueDate(instance.dueDate)} (${totalStr})`;
}
