import { addTimeToDate, dateTrunc, toLocalDateString } from "@hex-insights/core";
import {
	CourseSection,
	CourseSectionFilterInput,
	CourseSectionFormat,
	Employee,
	HomeRoomSection,
	minutesToTime,
	Student,
} from "../../Utilities";
import { CalendarItem } from "../BigCalendar";

export function getEmployeeWeeklyCourseScheduleFilters(
	employeeID: Employee["id"],
	date = new Date(),
): CourseSectionFilterInput | CourseSectionFilterInput[] {
	return {
		courseSectionTeacherEnrollments: [{ teacherIDEQ: employeeID }],
		term: [
			{
				startDateLTE: toLocalDateString(date),
				endDateGTE: toLocalDateString(date),
			},
		],
	};
}

export function getStudentWeeklyCourseScheduleFilters(
	studentID: Student["id"],
	date = new Date(),
): CourseSectionFilterInput | CourseSectionFilterInput[] {
	return {
		courseSectionStudentEnrollments: [{ studentIDEQ: studentID }],
		term: [
			{
				startDateLTE: toLocalDateString(date),
				endDateGTE: toLocalDateString(date),
			},
		],
	};
}

export function getHomeRoomSectionWeeklyCourseScheduleFilters(
	homeRoomSectionID: HomeRoomSection["id"],
): CourseSectionFilterInput | CourseSectionFilterInput[] {
	return {
		homeRoomSectionIDEQ: homeRoomSectionID,
	};
}

type CourseSectionSchedule = Pick<CourseSection, "id" | "name"> & {
	course: Pick<CourseSection["course"], "id" | "subject">;
	homeRoomSection: Pick<CourseSection["homeRoomSection"], "id" | "name">;
	courseSectionPeriods: Pick<
		CourseSection["courseSectionPeriods"][0],
		"dayOfWeek" | "startTimeMinutes" | "endTimeMinutes"
	>[];
};

export function courseSectionsToCalendarEvents(courseSections: CourseSectionSchedule[]) {
	const weekDays = getWeekDays();

	const events: CalendarItem[] = [];
	for (let i = 0; i < courseSections.length; i++) {
		events.push(...courseSectionToCalendarEvents(courseSections[i], weekDays));
	}

	return events;
}

export function courseSectionEdgesToCalendarEvents(edges: { node: CourseSectionSchedule }[]) {
	const weekDays = getWeekDays();

	const events: CalendarItem[] = [];
	for (let i = 0; i < edges.length; i++) {
		events.push(...courseSectionToCalendarEvents(edges[i].node, weekDays));
	}

	return events;
}

const weekDaysList = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

export function courseSectionToCalendarEvents(courseSection: CourseSectionSchedule, weekDays: WeekDays) {
	const events: CalendarItem[] = [];

	for (let i = 0; i < courseSection.courseSectionPeriods.length; i++) {
		const period = courseSection.courseSectionPeriods[i];
		const weekDay = weekDays[weekDaysList[period.dayOfWeek]];

		events.push({
			title: CourseSectionFormat.name(courseSection),
			start: minutesToTime(period.startTimeMinutes, new Date(weekDay)),
			end: minutesToTime(period.endTimeMinutes, new Date(weekDay)),
			original: {
				groups: [{ color: CourseSectionFormat.color(courseSection) }],
			},
		});
	}

	return events;
}

type WeekDays = {
	monday: Date;
	tuesday: Date;
	wednesday: Date;
	thursday: Date;
	friday: Date;
};

function getWeekDays(): WeekDays {
	const today = new Date();
	today.setHours(0, 0, 0, 0);
	const sunday = dateTrunc(today, "week").toDate();

	return {
		monday: addTimeToDate(sunday, [1, "day"]).toDate(),
		tuesday: addTimeToDate(sunday, [2, "days"]).toDate(),
		wednesday: addTimeToDate(sunday, [3, "days"]).toDate(),
		thursday: addTimeToDate(sunday, [4, "days"]).toDate(),
		friday: addTimeToDate(sunday, [5, "days"]).toDate(),
	};
}
