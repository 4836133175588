import { Models } from "@hex-insights/app-modules";
import { DistributiveOmit } from "@hex-insights/core";
import { Invoice, InvoiceFormat, InvoiceNameFragment } from "../../../../Utilities";

type ModelForLink = Pick<Invoice, "id"> & InvoiceNameFragment;

export type InvoiceLinkProps = DistributiveOmit<Models.LinkProps<ModelForLink>, "pageInfoKey" | "format">;

/**
 * Renders a link to the given Invoice instance.
 */
export function InvoiceLink(props: InvoiceLinkProps) {
	return <Models.Link<ModelForLink> pageInfoKey="Invoice" format={InvoiceFormat.name} {...props} />;
}
