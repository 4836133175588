import React from "react";
import {
	addTimeToDate,
	Button,
	cartesianProduct,
	Column,
	Conditional,
	DateTime,
	Else,
	formatPercentage,
	Grid,
	Heading,
	If,
	jsxJoin,
	Modal,
	omitKeys,
	Row,
	Section,
	toGrammaticalNumber,
	toLocalDateString,
	useToggle,
} from "@hex-insights/core";
import {
	CampusLink,
	CourseSectionLink,
	CoursesScheduleTile,
	EmployeeLink,
	getStudentWeeklyCourseScheduleFilters,
	HomeRoomLink,
	HomeRoomSectionLink,
	HorizontalScrollingContainer,
	HR,
	NoteForm,
	PersonIcon,
	SchoolAttendanceRecordForm,
	SmallCalendar,
	SmallEventCalendar,
	StudentEnrollmentStatusBadge,
	StudentNoteFormModal,
	Tile,
	useSmallEventCalendarDates,
} from "../../../../Components";
import {
	CourseSectionFormat,
	datedItemsToDateMap,
	Employee,
	Note,
	NoteFilterInput,
	NoteFormValues,
	NoteMutation,
	NoteNoteType,
	NoteOrderField,
	OrderDirection,
	PersonFormat,
	rangeDatedItemsToDateMap,
	SchoolAttendanceRecord,
	SchoolAttendanceRecordFormValues,
	SchoolAttendanceRecordMutation,
	SchoolAttendanceRecordStudentProfileListQuery,
	Student,
	StudentFormat,
	useEmployeeIndexQuery,
	useNoteDetailLazyQuery,
	useNoteStudentNotesListQuery,
	useSchoolAttendanceRecordDetailLazyQuery,
	useSchoolAttendanceRecordStudentProfileListQuery,
	useSectionsActiveForStudentListQuery,
	useStudentAttendanceNoteSlimListQuery,
	useStudentCurrentPeriod,
	useStudentDetailQuery,
	useStudentScheduleForDateQuery,
} from "../../../../Utilities";
import { getHomeRoomSectionLabel, useStudentCampusesQuery } from "../Utilities";
import { AcademicsNoteDisplay, NewAcademicsNoteController } from "./AcademicsNotes";
import styles from "./styles.module.css";

export type MainProps = {
	studentID: Student["id"];
};

export function Main({ studentID }: MainProps) {
	return (
		<React.Fragment>
			<AcademicsSummaryTile studentID={studentID} />
			<AcademicsMainTile studentID={studentID} />
			<AcademicsNotesSection studentID={studentID} />
		</React.Fragment>
	);
}

function AcademicsSummaryTile({ studentID }: { studentID: Student["id"] }) {
	const today = toLocalDateString(new Date());
	const { loading: loadingCampusData, data: campusData } = useStudentCampusesQuery(studentID);
	const { loading: loadingSectionsData, data: sectionsData } = useSectionsActiveForStudentListQuery({
		variables: { studentID, activeDate: today },
	});
	const { loading: loadingHomeRoomTeachers, data: homeRoomTeachersData } = useEmployeeIndexQuery({
		variables: {
			filters: {
				homeRoomSectionTeacherEnrollments: [
					{
						homeRoomSection: [
							{
								homeRoomSectionStudentEnrollments: [{ studentIDEQ: studentID }],
								term: [{ startDateLTE: today, endDateGTE: today }],
							},
						],
					},
				],
			},
		},
	});
	const homeRoomTeacherIDs = homeRoomTeachersData
		? new Set(homeRoomTeachersData.employeeConnection.edges.map((e) => e.node.id))
		: new Set<Employee["id"]>();
	const { loading: loadingCourseTeachers, data: courseTeachersData } = useEmployeeIndexQuery({
		variables: {
			filters: {
				courseSectionTeacherEnrollments: [
					{
						courseSection: [
							{
								courseSectionStudentEnrollments: [{ studentIDEQ: studentID }],
								term: [{ startDateLTE: today, endDateGTE: today }],
							},
						],
					},
				],
			},
		},
	});

	const hasHomeRoomSectionLabels =
		sectionsData?.student.homeRoomSectionStudentEnrollments.some(
			(e) => getHomeRoomSectionLabel(e.homeRoomSection) !== "",
		) ?? false;

	return (
		<Tile style={{ "--internal-link---text-decoration": "none" } as React.CSSProperties}>
			<Tile.Body style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }}>
				<Row justify="space-between" align="center">
					<Column align="center" style={{ flexGrow: 1 }}>
						<StudentEnrollmentStatusBadge studentID={studentID} />
					</Column>

					<Row justify="center">
						<HR color="#ccc" style={{ height: "3rem", margin: "0 1.5rem" }} />
					</Row>

					<Row justify="space-around" horizontalSpacing="8%" align="center" style={{ flexGrow: 1 }}>
						<Column align="center" style={{ flexGrow: 1 }}>
							<span style={{ fontSize: "0.9rem", color: "#888", whiteSpace: "nowrap" }}>Campus</span>
							<span style={{ fontSize: "1.1rem" }}>
								<Conditional>
									<If condition={loadingCampusData}>...</If>
									<Else>
										{campusData && campusData.campusConnection.edges.length > 0 && (
											<CampusLink instance={campusData.campusConnection.edges[0].node} />
										)}
									</Else>
								</Conditional>
							</span>
						</Column>

						<Column align="center" style={{ flexGrow: 1 }}>
							<span style={{ fontSize: "0.9rem", color: "#888", whiteSpace: "nowrap" }}>Home Room</span>
							<span style={{ fontSize: "1.1rem" }}>
								<Conditional>
									<If condition={loadingSectionsData}>Loading...</If>
									<Else>
										{jsxJoin(
											sectionsData?.student.homeRoomSectionStudentEnrollments.map(({ homeRoomSection }) => (
												<HomeRoomLink key={homeRoomSection.id} instance={homeRoomSection.homeRoom} />
											)) ?? [],
											",",
										)}
									</Else>
								</Conditional>
							</span>
						</Column>

						<If condition={hasHomeRoomSectionLabels}>
							<Column align="center" style={{ flexGrow: 1 }}>
								<span style={{ fontSize: "0.9rem", color: "#888", whiteSpace: "nowrap" }}>Section</span>
								<span style={{ fontSize: "1.1rem" }}>
									<Conditional>
										<If condition={loadingSectionsData}>Loading...</If>
										<Else>
											{jsxJoin(
												sectionsData?.student.homeRoomSectionStudentEnrollments.map(({ homeRoomSection }) => (
													<HomeRoomSectionLink key={homeRoomSection.id} instance={{ id: homeRoomSection.id }}>
														{getHomeRoomSectionLabel(homeRoomSection)}
													</HomeRoomSectionLink>
												)) ?? [],
												",",
											)}
										</Else>
									</Conditional>
								</span>
							</Column>
						</If>
					</Row>

					<Row justify="center">
						<HR color="#ccc" style={{ height: "3rem", margin: "0 1.5rem" }} />
					</Row>

					<Column align="center" style={{ flexGrow: 1 }}>
						<span style={{ fontSize: "0.9rem", color: "#888", whiteSpace: "nowrap" }}>Teachers</span>
						<span style={{ fontSize: "1.1rem" }}>
							<Conditional>
								<If condition={loadingHomeRoomTeachers || loadingCourseTeachers}>Loading...</If>
								<Else>
									<Row justify="spaced-start">
										{homeRoomTeachersData?.employeeConnection.edges.map((e) => (
											<EmployeeLink key={e.node.id} instance={e.node}>
												<PersonIcon person={e.node.person} imageSize="2rem" />
											</EmployeeLink>
										))}
										{courseTeachersData?.employeeConnection.edges.map((e) =>
											homeRoomTeacherIDs.has(e.node.id) ? null : (
												<EmployeeLink key={e.node.id} instance={e.node}>
													<PersonIcon person={e.node.person} imageSize="2rem" />
												</EmployeeLink>
											),
										)}
									</Row>
								</Else>
							</Conditional>
						</span>
					</Column>
				</Row>
			</Tile.Body>
		</Tile>
	);
}

const coursesResponsiveColumns = {
	0: 1,
	800: 2,
	1000: 3,
	1200: 4,
	1400: 5,
};

function AcademicsMainTile({ studentID }: { studentID: Student["id"] }) {
	const { data } = useStudentDetailQuery({ variables: { id: studentID } });
	const student = data!.student;
	const { loading: loadingSectionsData, data: sectionsData } = useSectionsActiveForStudentListQuery({
		variables: { studentID, activeDate: toLocalDateString(new Date()) },
	});

	return (
		<Tile style={{ flexGrow: 1, height: "100%", overflow: "hidden scroll" }}>
			<Tile.Body style={{ height: "100%" }}>
				<Row justify="spaced-start" style={{ height: "100%" }}>
					<Section style={{ flexGrow: 1, width: "100%", overflow: "scroll" }}>
						<Section.Header style={{ padding: 0 }}>
							<Heading level={3} noMargin>
								Courses
							</Heading>
						</Section.Header>
						<Section.Body style={{ padding: 0 }}>
							<Conditional>
								<If condition={loadingSectionsData}>Loading...</If>
								<Else>
									<Grid responsiveColumns={coursesResponsiveColumns} gap="0.75rem">
										{sectionsData?.student.courseSectionStudentEnrollments.map(({ courseSection }) => (
											<CourseSectionLink
												key={courseSection.id}
												instance={courseSection}
												className={styles["course-section-link"]}
												style={{
													backgroundColor: CourseSectionFormat.color(courseSection),
												}}
											>
												<span className={styles["course-section-link__text"]}>{courseSection.name}</span>
											</CourseSectionLink>
										))}
									</Grid>
								</Else>
							</Conditional>
						</Section.Body>
					</Section>

					<AcademicSnapshot student={student} />
				</Row>
			</Tile.Body>
		</Tile>
	);
}

type AcademicSnapshotProps = {
	student: Pick<Student, "hasPreviousSchooling" | "previousSchoolInformation" | "previousSchoolLocation"> & {
		person: Pick<Student["person"], "primaryLanguage" | "englishLanguageFluency">;
	};
};

function AcademicSnapshot({ student }: AcademicSnapshotProps) {
	return (
		<Section className={styles["academic-snapshot"]} style={{ flexShrink: 0, maxWidth: "15rem" }}>
			<Section.Header className={styles["academic-snapshot__header"]}>
				<Heading level={3} noMargin style={{ textAlign: "center", color: "var(--purple)" }}>
					Snapshot
				</Heading>
			</Section.Header>
			<Section.Body className={styles["academic-snapshot__body"]}>
				<Column justify="space-between" style={{ height: "100%" }}>
					<Column justify="spaced-start">
						<Column align="center" style={{ flexGrow: 2 }}>
							<span style={{ fontSize: "0.9rem", color: "#888" }}>Primary Language</span>
							<span style={{ fontSize: "1.1rem" }}>
								{PersonFormat.Fields.primaryLanguage(student.person.primaryLanguage)}
							</span>
						</Column>
						<Column align="center" style={{ flexGrow: 2 }}>
							<span style={{ fontSize: "0.9rem", color: "#888" }}>English Language Fluency</span>
							<span style={{ fontSize: "1.1rem" }}>
								{PersonFormat.Fields.englishLanguageFluency(student.person.englishLanguageFluency)}
							</span>
						</Column>
						<Column align="center" style={{ flexGrow: 2 }}>
							<span style={{ fontSize: "0.9rem", color: "#888" }}>Previous Schooling</span>
							<span style={{ fontSize: "1.1rem" }}>
								{StudentFormat.Fields.hasPreviousSchooling(student.hasPreviousSchooling)}
							</span>
						</Column>
						<Column align="center" style={{ flexGrow: 2 }}>
							<span style={{ fontSize: "0.9rem", color: "#888" }}>Previous School Information</span>
							<span style={{ fontSize: "1.1rem" }}>
								{student.previousSchoolInformation !== ""
									? StudentFormat.Fields.previousSchoolInformation(student.previousSchoolInformation)
									: "No Information"}
							</span>
						</Column>
						<Column align="center" style={{ flexGrow: 2 }}>
							<span style={{ fontSize: "0.9rem", color: "#888" }}>Previous School Location</span>
							<span style={{ fontSize: "1.1rem" }}>
								{student.previousSchoolLocation !== ""
									? StudentFormat.Fields.previousSchoolLocation(student.previousSchoolLocation)
									: "No Information"}
							</span>
						</Column>
					</Column>

					<div className={styles["academic-snapshot__button-container"]}>
						<Button className={styles["academic-snapshot__button"]} onClick={() => {}}>
							Generate Snapshot
						</Button>
					</div>
				</Column>
			</Section.Body>
		</Section>
	);
}

function AcademicsNotesSection({ studentID }: { studentID: Student["id"] }) {
	const { data } = useNoteStudentNotesListQuery({
		variables: {
			filters: {
				noteTypeIn: [NoteNoteType.Academic, NoteNoteType.Behavior, NoteNoteType.SocialEmotional],
				students: [{ idEQ: studentID }],
			},
			order: { field: NoteOrderField.CreatedAt, direction: OrderDirection.Desc },
		},
	});

	return (
		<Tile>
			<Tile.Body>
				<Row justify="spaced-start" style={{ height: "100%" }}>
					<HorizontalScrollingContainer className={styles["notes-scrolling-container"]}>
						<Row justify="spaced-start" style={{ height: "100%" }}>
							{data?.noteConnection.edges.map((e) => (
								<AcademicsNoteDisplay key={e.node.id} note={e.node} />
							))}
						</Row>
					</HorizontalScrollingContainer>
					<Row>
						<NewAcademicsNoteController studentID={studentID} />
					</Row>
				</Row>
			</Tile.Body>
		</Tile>
	);
}

export type LeftColumnProps = {
	studentID: Student["id"];
};

export function LeftColumn({ studentID }: LeftColumnProps) {
	return (
		<Column justify="spaced-start" style={{ flexGrow: 1 }}>
			<AttendanceTile studentID={studentID} />
			<ScheduleTile studentID={studentID} />
		</Column>
	);
}

function AttendanceTile({ studentID }: { studentID: Student["id"] }) {
	const { value, firstOfMonth, lastOfMonth, onActiveStartDateChange, onClickMonth, onChange } =
		useSmallEventCalendarDates();

	const { loading: loadingAttendance, data: attendanceData } = useSchoolAttendanceRecordStudentProfileListQuery({
		variables: {
			studentID,
			startTime: toLocalDateString(firstOfMonth),
			endTime: toLocalDateString(lastOfMonth),
		},
	});
	const attendanceDateMap = React.useMemo(() => {
		if (!attendanceData) {
			return {};
		}
		return datedItemsToDateMap(attendanceData.schoolAttendanceRecordConnection.edges, "checkInTime", false);
	}, [attendanceData]);

	const { loading: loadingNotes, data: notesData } = useStudentAttendanceNoteSlimListQuery({
		variables: { filters: getAttendanceNoteFilters(studentID, firstOfMonth, lastOfMonth) },
	});
	const notesDateMap = React.useMemo(() => {
		if (!notesData) {
			return {};
		}
		const notes = notesData.noteConnection.edges.map((e) => ({
			...e.node,
			endDate: e.node.endDate ?? e.node.startDate,
		}));
		return rangeDatedItemsToDateMap(notes, "startDate", "endDate", true);
	}, [notesData]);

	const valueISO = toLocalDateString(value);
	const attendanceOnSelectedDate = value !== null ? attendanceDateMap[valueISO] ?? [] : [];
	const notesOnSelectedDate = value !== null ? notesDateMap[valueISO] ?? [] : [];

	return (
		<Tile style={{ flexGrow: 1 }}>
			<Tile.Body style={{ height: "100%", overflow: "hidden" }}>
				<Column justify="spaced-start" style={{ height: "100%", overflow: "scroll" }}>
					<AttendanceStats data={attendanceData} />
					<SmallCalendar
						value={value}
						onActiveStartDateChange={onActiveStartDateChange}
						onClickMonth={onClickMonth}
						onChange={onChange}
						noSecondaryArrows
						tileContent={({ date }) =>
							attendanceData ? (
								<AttendanceCalendarTileContent
									numNotes={(notesDateMap[toLocalDateString(date)] ?? []).length}
									schoolAttendanceRecords={attendanceDateMap[toLocalDateString(date)] ?? []}
								/>
							) : null
						}
					/>

					<AttendanceListItems
						studentID={studentID}
						attendanceRecords={attendanceOnSelectedDate}
						notes={notesOnSelectedDate}
						isLoading={loadingAttendance || loadingNotes}
					/>
				</Column>
			</Tile.Body>
		</Tile>
	);
}

function getAttendanceNoteFilters(studentID: Student["id"], startDate: DateTime, endDate: DateTime) {
	const filters: NoteFilterInput[] = [
		{
			students: [{ idEQ: studentID }],
			noteTypeEQ: NoteNoteType.Attendance,
		},
	];

	const startDateStr = toLocalDateString(startDate);
	const endDateStr = toLocalDateString(addTimeToDate(endDate, [1, "day"]));
	const timeFilters: NoteFilterInput[] = [
		{
			startDateGTE: startDateStr,
			startDateLT: endDateStr,
		},
		{
			endDateGTE: startDateStr,
			endDateLT: endDateStr,
		},
		{
			startDateLT: startDateStr,
			endDateGTE: endDateStr,
		},
	];

	return cartesianProduct(filters, timeFilters).map((e) => ({ ...e[0], ...e[1] }));
}

type AttendanceStatsProps = {
	data: SchoolAttendanceRecordStudentProfileListQuery | undefined;
};

function AttendanceStats({ data }: AttendanceStatsProps) {
	const stats = React.useMemo(() => {
		const stats = { daysPresent: 0, daysAbsent: 0, daysLate: 0, attendanceRate: NaN };
		if (!data) {
			return stats;
		}
		for (let i = 0; i < data.schoolAttendanceRecordConnection.edges.length; i++) {
			const record = data.schoolAttendanceRecordConnection.edges[i].node;
			if (record.isPresent) {
				stats.daysPresent++;
				if (record.isLate) {
					stats.daysLate++;
				}
			} else {
				stats.daysAbsent++;
			}
		}
		stats.attendanceRate = stats.daysPresent / (stats.daysPresent + stats.daysAbsent);
		stats.attendanceRate -= stats.daysLate * 0.01;
		return stats;
	}, [data]);

	return (
		<Column justify="spaced-start" align="center">
			<AttendanceStat
				label="Attendance Rate"
				value={formatPercentage(stats.attendanceRate, {
					precision: 0,
				})}
				status={getAttendanceRateStatus(stats.attendanceRate)}
			/>
			<Row justify="spaced-start" align="spaced-start" overflow="wrap">
				<AttendanceStat
					label="Days Absent"
					value={stats.daysAbsent}
					status={stats.daysAbsent > 0 ? "danger" : "plain"}
				/>
				<AttendanceStat label="Days Late" value={stats.daysLate} status={stats.daysLate > 0 ? "warning" : "plain"} />
			</Row>
		</Column>
	);
}

type AttendanceStatProps = {
	label: string;
	value: string | number;
	status: "success" | "warning" | "danger" | "plain";
};

function AttendanceStat({ label, value, status }: AttendanceStatProps) {
	return (
		<span className={styles["attendance-stat"] + " " + styles[`attendance-stat--${status}`]}>
			{label}: {value}
		</span>
	);
}

function getAttendanceRateStatus(attendanceRate: number) {
	if (isNaN(attendanceRate)) {
		return "plain";
	}
	if (attendanceRate >= 0.8) {
		return "success";
	}
	if (attendanceRate >= 0.5) {
		return "warning";
	}
	return "danger";
}

type AttendanceCalendarTileContentProps = {
	numNotes: number;
} & AttendanceCalendarStatusIconProps;

function AttendanceCalendarTileContent({ numNotes, schoolAttendanceRecords }: AttendanceCalendarTileContentProps) {
	return (
		<Row justify="spaced-center" horizontalSpacing="0.1rem" align="center">
			<If condition={schoolAttendanceRecords.length > 0}>
				<AttendanceCalendarStatusIcon schoolAttendanceRecords={schoolAttendanceRecords} />
			</If>
			<If condition={numNotes > 0}>
				<div
					className={styles["attendance-calendar__icon"]}
					style={{ backgroundColor: "var(--verita-blue)" }}
					title={toGrammaticalNumber("Note", numNotes)}
				/>
			</If>
			<If condition={schoolAttendanceRecords.length === 0 && numNotes === 0}>
				<div className={styles["attendance-calendar__icon"]} />
			</If>
		</Row>
	);
}

type DayAttendanceStatus = "present" | "late" | "absent" | "none";

type AttendanceCalendarStatusIconProps = {
	schoolAttendanceRecords: SchoolAttendanceRecordStudentProfileListQuery["schoolAttendanceRecordConnection"]["edges"][0]["node"][];
};

function AttendanceCalendarStatusIcon({ schoolAttendanceRecords }: AttendanceCalendarStatusIconProps) {
	const status = React.useMemo<DayAttendanceStatus>(() => {
		if (schoolAttendanceRecords.length === 0) {
			return "none";
		}

		let hasPresent = false;
		for (let i = 0; i < schoolAttendanceRecords.length; i++) {
			if (schoolAttendanceRecords[i].isLate) {
				return "late";
			}
			if (schoolAttendanceRecords[i].isPresent) {
				hasPresent = true;
			}
		}
		if (hasPresent) {
			return "present";
		}
		return "absent";
	}, [schoolAttendanceRecords]);

	return (
		<div
			className={styles["attendance-calendar__icon"]}
			style={{ backgroundColor: getAttendanceStatusBackgroundColor(status) }}
			title={getAttendanceStatusTitle(status)}
		></div>
	);
}

function getAttendanceStatusBackgroundColor(status: DayAttendanceStatus) {
	switch (status) {
		case "present":
			return "var(--success-color)";
		case "late":
			return "var(--warning-color)";
		case "absent":
			return "var(--danger-color)";
		default:
			return "transparent";
	}
}

function getAttendanceStatusTitle(status: DayAttendanceStatus) {
	switch (status) {
		case "present":
			return "Present";
		case "late":
			return "Late";
		case "absent":
			return "Absent";
		default:
			return undefined;
	}
}

type AttendanceListItemsProps = {
	studentID: Student["id"];
	attendanceRecords: Pick<
		SchoolAttendanceRecord,
		"id" | "isPresent" | "isLate" | "notes" | "checkInTime" | "checkOutTime"
	>[];
	notes: Pick<Note, "id" | "body" | "startDate" | "endDate">[];
	isLoading: boolean;
};

function AttendanceListItems({ studentID, attendanceRecords, notes, isLoading }: AttendanceListItemsProps) {
	return (
		<Column justify="spaced-start" align="center" style={{ flexGrow: 1, width: "100%", minWidth: 0 }}>
			<Column justify="spaced-start" verticalSpacing="0.15rem" style={{ flexGrow: 1, width: "100%", minWidth: 0 }}>
				<Conditional>
					<If condition={isLoading}>
						<Row justify="center">
							<span>Loading...</span>
						</Row>
					</If>
					<Else>
						{attendanceRecords.map((e) => (
							<AttendanceRecordCalendarEventListItem key={e.id} schoolAttendanceRecord={e} />
						))}
						{notes.map((e) => (
							<AttendanceNoteCalendarEventListItem key={e.id} note={e} />
						))}
					</Else>
				</Conditional>
			</Column>

			<AttendanceNoteCreator studentID={studentID} />
		</Column>
	);
}

type AttendanceRecordCalendarEventListItemProps = {
	schoolAttendanceRecord: Pick<
		SchoolAttendanceRecord,
		"id" | "isPresent" | "isLate" | "notes" | "checkInTime" | "checkOutTime"
	>;
};

function AttendanceRecordCalendarEventListItem({ schoolAttendanceRecord }: AttendanceRecordCalendarEventListItemProps) {
	const { isModalOpen, toggleIsModalOpen, closeModal } = Modal.useToggle(false);

	const [loadDetail, { loading, data }] = useSchoolAttendanceRecordDetailLazyQuery({
		variables: { id: schoolAttendanceRecord.id },
	});
	React.useEffect(() => {
		if (isModalOpen) {
			loadDetail();
		}
	}, [isModalOpen, loadDetail]);

	const update = SchoolAttendanceRecordMutation.useUpdate(schoolAttendanceRecord.id);
	const applyUpdate = React.useCallback(
		async (
			changedFormValues: Partial<SchoolAttendanceRecordFormValues.Detail>,
			initialFormValues: SchoolAttendanceRecordFormValues.Detail,
		) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<React.Fragment>
			<SmallEventCalendar.EventListItem
				calendarEvent={attendanceRecordToCalendarEvent(schoolAttendanceRecord)}
				onClick={toggleIsModalOpen}
			/>

			<Modal.If condition={isModalOpen}>
				<Modal onClose={toggleIsModalOpen} style={{ width: "fit-content", minWidth: "var(--general__field---width)" }}>
					<Modal.Body>
						<Conditional>
							<If condition={loading}>Loading...</If>
							<Else>
								{data && (
									<SchoolAttendanceRecordForm.ControlledDetail
										schoolAttendanceRecord={data.schoolAttendanceRecord}
										applyUpdate={applyUpdate}
										onSuccess={closeModal}
									/>
								)}
							</Else>
						</Conditional>
					</Modal.Body>
				</Modal>
			</Modal.If>
		</React.Fragment>
	);
}

function attendanceRecordToCalendarEvent(
	record: Pick<SchoolAttendanceRecord, "id" | "isPresent" | "isLate" | "notes" | "checkInTime" | "checkOutTime">,
) {
	const attendanceStatus = getAttendanceStatusForRecord(record);

	return {
		id: record.id,
		name: attendanceStatus + (record.notes !== "" ? "*" : ""),
		description: record.notes,
		isAllDay: false,
		startTime: record.checkInTime,
		endTime: record.checkOutTime,
		groups: [
			{
				name: "Attendance",
				color: getAttendanceStatusBackgroundColor(attendanceStatus.toLowerCase() as DayAttendanceStatus),
			},
		],
		isPublic: false,
	};
}

function getAttendanceStatusForRecord(record: Pick<SchoolAttendanceRecord, "isPresent" | "isLate">) {
	if (!record.isPresent) {
		return "Absent";
	}
	if (record.isLate) {
		return "Late";
	}
	return "Present";
}

type AttendanceNoteCalendarEventListItemProps = {
	note: Pick<Note, "id" | "body" | "startDate" | "endDate">;
};

function AttendanceNoteCalendarEventListItem({ note }: AttendanceNoteCalendarEventListItemProps) {
	const { isModalOpen, toggleIsModalOpen, closeModal } = Modal.useToggle(false);

	const [loadDetail, { loading, data }] = useNoteDetailLazyQuery({
		variables: { id: note.id },
	});
	React.useEffect(() => {
		if (isModalOpen) {
			loadDetail();
		}
	}, [isModalOpen, loadDetail]);

	const update = NoteMutation.useUpdate(note.id);
	const applyUpdate = React.useCallback(
		async (
			changedFormValues: Partial<NoteFormValues.Attendance.Detail>,
			initialFormValues: NoteFormValues.Attendance.Detail,
		) => {
			// TODO better translation support
			const { errors } = await update(
				omitKeys(changedFormValues, ["isMultiDay"]),
				omitKeys(initialFormValues, ["isMultiDay"]),
			);
			return errors;
		},
		[update],
	);

	return (
		<React.Fragment>
			<SmallEventCalendar.EventListItem
				calendarEvent={attendanceNoteToCalendarEvent(note)}
				onClick={toggleIsModalOpen}
			/>

			<Modal.If condition={isModalOpen}>
				<Modal onClose={toggleIsModalOpen} style={{ width: "fit-content", minWidth: "var(--general__field---width)" }}>
					<Modal.Body>
						<Conditional>
							<If condition={loading}>Loading...</If>
							<Else>
								{data && (
									<NoteForm.Attendance.ControlledDetail
										note={data.note}
										applyUpdate={applyUpdate}
										onSuccess={closeModal}
									/>
								)}
							</Else>
						</Conditional>
					</Modal.Body>
				</Modal>
			</Modal.If>
		</React.Fragment>
	);
}

function attendanceNoteToCalendarEvent(note: Pick<Note, "id" | "body" | "startDate" | "endDate">) {
	return {
		id: note.id,
		name: note.body,
		description: "",
		isAllDay: true,
		startTime: note.startDate ?? "2000-01-01T00:00:0Z",
		endTime: note.endDate ?? note.startDate ?? "2000-01-01T00:00:0Z",
		isPublic: false,
		groups: [{ name: "Attendance Notes", color: "var(--verita-blue)" }],
	};
}

const attendanceNoteTypes = [NoteNoteType.Attendance];

type AttendanceNoteCreatorProps = {
	studentID: Student["id"];
};

function AttendanceNoteCreator({ studentID }: AttendanceNoteCreatorProps) {
	const { isOn: isOpen, toggle: toggleIsOpen } = useToggle(false);

	return (
		<React.Fragment>
			<Button variant="link" size="small" onClick={toggleIsOpen}>
				Write Attendance Note
			</Button>

			<Modal.If condition={isOpen}>
				<StudentNoteFormModal onClose={toggleIsOpen} noteTypes={attendanceNoteTypes} studentID={studentID} />
			</Modal.If>
		</React.Fragment>
	);
}

function ScheduleTile({ studentID }: { studentID: Student["id"] }) {
	return (
		<CoursesScheduleTile
			id={studentID}
			useCurrentPeriod={useStudentCurrentPeriod}
			useScheduleForDateQuery={useStudentScheduleForDateQuery}
			getWeeklyScheduleFilters={getStudentWeeklyCourseScheduleFilters}
		/>
	);
}
