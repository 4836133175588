import { CaseStyle, convertCase, formatDateTime } from "@hex-insights/core";
import { Payment } from "../../../GraphQL";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the paymentMethod field of the Note model.
 */
export function paymentMethod(value: Payment["paymentMethod"] | null): string {
	if (value === null) {
		return toString(value);
	}
	return convertCase(value, CaseStyle.Title);
}

/**
 * Formats the createdDate field of the Payment model.
 */
export function createdDate(value: Payment["createdDate"] | null): string {
	if (value === null) {
		return toString(value);
	}
	return formatDateTime(value, "MMMM Do, YYYY");
}

/**
 * Formats the notes field of the Payment model.
 */
export function notes(value: Payment["notes"]): string {
	return toString(value);
}
