import { IndexForms } from "@hex-insights/app-modules";
import { CaseStyle, convertCase } from "@hex-insights/core";
import { CampusOrderField, OrderDirection } from "../../GraphQL";

export type FormValues = IndexForms.OrderFormValues<CampusOrderField | null>;

export const initial: FormValues = {
	field: CampusOrderField.Name,
	direction: OrderDirection.Asc,
};

/**
 * Options for the "field" field in the order form of the Campus model.
 */
export const fieldOptions = [
	{ label: "ID", value: null },
	{ label: convertCase(CampusOrderField.Name.toLowerCase(), CaseStyle.Title), value: CampusOrderField.Name },
	{
		label: convertCase(CampusOrderField.AddressLine1.toLowerCase(), CaseStyle.Title),
		value: CampusOrderField.AddressLine1,
	},
	{
		label: convertCase(CampusOrderField.AddressLine2.toLowerCase(), CaseStyle.Title),
		value: CampusOrderField.AddressLine2,
	},
	{
		label: convertCase(CampusOrderField.PostalCode.toLowerCase(), CaseStyle.Title),
		value: CampusOrderField.PostalCode,
	},
	{ label: convertCase(CampusOrderField.City.toLowerCase(), CaseStyle.Title), value: CampusOrderField.City },
	{
		label: convertCase(CampusOrderField.StateOrProvince.toLowerCase(), CaseStyle.Title),
		value: CampusOrderField.StateOrProvince,
	},
	{ label: convertCase(CampusOrderField.Country.toLowerCase(), CaseStyle.Title), value: CampusOrderField.Country },
];

/**
 * Array of values for the "field" field in the order form of the Campus model.
 */
export const fieldValues = fieldOptions.map((e) => e.value);

/**
 * Mapping from order fields of the Campus model to their corresponding order type.
 */
export const fieldTypeMapping = {
	[CampusOrderField.Name]: IndexForms.OrderFieldType.String,
	[CampusOrderField.AddressLine1]: IndexForms.OrderFieldType.String,
	[CampusOrderField.AddressLine2]: IndexForms.OrderFieldType.String,
	[CampusOrderField.PostalCode]: IndexForms.OrderFieldType.String,
	[CampusOrderField.City]: IndexForms.OrderFieldType.String,
	[CampusOrderField.StateOrProvince]: IndexForms.OrderFieldType.String,
	[CampusOrderField.Country]: IndexForms.OrderFieldType.String,
};

/**
 * Mapping from order fields of the Campus model to their corresponding field name.
 */
export const orderFieldToModelField = {
	[CampusOrderField.Name]: "name",
	[CampusOrderField.AddressLine1]: "addressLine1",
	[CampusOrderField.AddressLine2]: "addressLine2",
	[CampusOrderField.PostalCode]: "postalCode",
	[CampusOrderField.City]: "city",
	[CampusOrderField.StateOrProvince]: "stateOrProvince",
	[CampusOrderField.Country]: "country",
};

/**
 * Mapping from field names of the Campus model to their corresponding order field.
 */
export const modelFieldToOrderField = {
	name: CampusOrderField.Name,
	addressLine1: CampusOrderField.AddressLine1,
	addressLine2: CampusOrderField.AddressLine2,
	postalCode: CampusOrderField.PostalCode,
	city: CampusOrderField.City,
	stateOrProvince: CampusOrderField.StateOrProvince,
	country: CampusOrderField.Country,
};
