import React from "react";
import { Column, Grid, Heading, makeObjectFromArrayMap, Row, Section } from "@hex-insights/core";
import { InternalLink, useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import {
	CourseSection,
	EmployeeContext,
	LearningObjective,
	Main,
	Student,
	StudentCourseSectionTeacherReportCardsTermOverviewByCoursesListQuery,
	Tile,
	useStudentCourseSectionTeacherReportCardsTermOverviewByCoursesListQuery,
	useTermDetailQuery,
} from "@hex-insights/verita.shared";
import { reportCardsTermCourseSectionPageInfo } from "../CourseSection/pageinfo";
import { reportCardsTermCourseSectionStudentPageInfo } from "../CourseSection/Student/pageinfo";
import { reportCardsTermIndexPageInfo, ReportCardsTermIndexPageRouteParams } from "./pageinfo";

export function ReportCardsTermIndexPage() {
	useActivePageRegistration(reportCardsTermIndexPageInfo);

	const { termID } = useRouteParams<ReportCardsTermIndexPageRouteParams>();
	const { data: termData } = useTermDetailQuery({ variables: { id: termID } });
	const term = termData?.term;

	const { employee } = React.useContext(EmployeeContext);
	const { data } = useStudentCourseSectionTeacherReportCardsTermOverviewByCoursesListQuery({
		variables: { termID, teacherID: employee.id },
	});

	const studentMap = makeObjectFromArrayMap(data?.studentConnection.edges ?? [], (e) => [e.node.id, e.node]);
	const courseSectionLearningObjectiveIDSetMap = makeObjectFromArrayMap(
		data?.courseSectionConnection.edges ?? [],
		({ node }) => [node.id, new Set(node.course.learningObjectives.map((e) => e.id))],
	);

	return (
		<Main>
			<Tile>
				<Tile.Header>
					<Heading level={1} noMargin>
						{term?.name}
					</Heading>
				</Tile.Header>
				<Tile.Body>
					{data?.courseSectionConnection.edges.map(({ node }) => (
						<CourseSectionSection
							key={node.id}
							courseSection={node}
							studentMap={studentMap}
							learningObjectiveIDSet={courseSectionLearningObjectiveIDSetMap[node.id]}
						/>
					))}
				</Tile.Body>
			</Tile>
		</Main>
	);
}

type CourseSectionInQuery =
	StudentCourseSectionTeacherReportCardsTermOverviewByCoursesListQuery["courseSectionConnection"]["edges"][0]["node"];
type StudentInQuery =
	StudentCourseSectionTeacherReportCardsTermOverviewByCoursesListQuery["studentConnection"]["edges"][0]["node"];

type CourseSectionSectionProps = {
	courseSection: CourseSectionInQuery;
	studentMap: Record<Student["id"], StudentInQuery>;
	learningObjectiveIDSet: Set<LearningObjective["id"]>;
};

function CourseSectionSection({ courseSection, studentMap, learningObjectiveIDSet }: CourseSectionSectionProps) {
	const { termID } = useRouteParams<ReportCardsTermIndexPageRouteParams>();

	return (
		<Section>
			<Section.Header>
				<Row justify="spaced-start" align="center">
					<Heading level={2} noMargin>
						{courseSection.name}
					</Heading>

					<InternalLink to={reportCardsTermCourseSectionPageInfo.to(termID, courseSection.id)}>
						Review Marks &gt;
					</InternalLink>
				</Row>
			</Section.Header>
			<Section.Body>
				<Grid columns={5} gap="1rem">
					{courseSection.courseSectionStudentEnrollments.map(({ student }) => (
						<StudentItem
							key={student.id}
							student={studentMap[student.id]}
							courseSectionID={courseSection.id}
							learningObjectiveIDSet={learningObjectiveIDSet}
						/>
					))}
				</Grid>
			</Section.Body>
		</Section>
	);
}

type StudentItemProps = {
	student: StudentInQuery;
	courseSectionID: CourseSection["id"];
	learningObjectiveIDSet: Set<LearningObjective["id"]>;
};

function StudentItem({ student, courseSectionID, learningObjectiveIDSet }: StudentItemProps) {
	const { termID } = useRouteParams<ReportCardsTermIndexPageRouteParams>();

	const reportCard = student.reportCards.length > 0 ? student.reportCards[0] : null;
	let marksCompleted = 0;
	let totalMarks = 0;
	if (reportCard) {
		for (let i = 0; i < reportCard.learningObjectiveMarks.length; i++) {
			const mark = reportCard.learningObjectiveMarks[i];
			if (!learningObjectiveIDSet.has(mark.learningObjectiveID)) {
				continue;
			}
			if (mark.value !== null) {
				marksCompleted++;
			}
			totalMarks++;
		}
	}

	const areMarksComplete = marksCompleted === totalMarks;

	return (
		<Column align="center">
			<Row justify="spaced-start">
				<span>{student.person.name}</span>
				<span style={{ color: areMarksComplete ? "var(--success-color)" : "var(--danger-color)" }}>
					{marksCompleted} / {totalMarks}
				</span>
			</Row>

			<span>
				<InternalLink to={reportCardsTermCourseSectionStudentPageInfo.to(termID, courseSectionID, student.id)}>
					{areMarksComplete ? "Review Marks" : "Complete Marks"}
				</InternalLink>
			</span>
		</Column>
	);
}
