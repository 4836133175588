import { dateDiff } from "@hex-insights/core";
import { ClassYear, Term } from "./GraphQL";

const graduationMonth = 5;

export function getGraduationYearForClassYearAndTerm(
	classYear: Pick<ClassYear, "yearsFromGraduation"> | null,
	term: Pick<Term, "startDate"> | null,
) {
	if (!(classYear && term)) {
		return 0;
	}
	const termStartDate = new Date(term.startDate);
	const nextGraduationYear =
		termStartDate.getMonth() > graduationMonth ? termStartDate.getFullYear() + 1 : termStartDate.getFullYear();
	return nextGraduationYear + classYear.yearsFromGraduation;
}

export function getYearsFromGraduationForGraduationYear(graduationYear: number, relativeDate = new Date()) {
	const graduationDate = new Date(graduationYear, graduationMonth, 1);
	return Math.floor(dateDiff(graduationDate, relativeDate, "month") / 12);
}
