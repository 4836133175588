import { IndexForms } from "@hex-insights/app-modules";
import { asArray, asString, nullableBooleanTypeFilter, TypeFilterObject } from "@hex-insights/core";
import { Campus, Course, Employee, HomeRoom } from "../../GraphQL";
import { StudentFormValues } from "../ModelFormValues";

export type StandardFilterFormValues = {
	campusIDs: Campus["id"][];
	hasHomeRoomSectionStudentEnrollments: boolean | null;
	homeRoomIDs: HomeRoom["id"][];
	homeRoomSectionIDs: HomeRoom["id"][];
	courseIDs: Course["id"][];
	teacherIDs: Employee["id"][];
	hasMealPlans: boolean | null;
	hasBusPlans: boolean | null;
};

export type FormValues = StandardFilterFormValues & IndexForms.SearchFormValues & IndexForms.FilterFormValues;

export const initialStandardFilters: StandardFilterFormValues = {
	campusIDs: [],
	hasHomeRoomSectionStudentEnrollments: true,
	homeRoomIDs: [],
	homeRoomSectionIDs: [],
	courseIDs: [],
	teacherIDs: [],
	hasMealPlans: null,
	hasBusPlans: null,
};

export const initial: FormValues = {
	...initialStandardFilters,
	...IndexForms.initialSearchFormValues,
	...IndexForms.initialFilterFormValues,
};

export function getInitial(initialFormValues?: Partial<FormValues>): FormValues {
	return { ...initial, ...initialFormValues };
}

export const quickFiltersTypeFilterObject: TypeFilterObject<StandardFilterFormValues> = {
	campusIDs: (value: any) => asArray(value).map((e) => asString(e)),
	hasHomeRoomSectionStudentEnrollments: nullableBooleanTypeFilter(),
	homeRoomIDs: (value: any) => asArray(value).map((e) => asString(e)),
	homeRoomSectionIDs: (value: any) => asArray(value).map((e) => asString(e)),
	courseIDs: (value: any) => asArray(value).map((e) => asString(e)),
	teacherIDs: (value: any) => asArray(value).map((e) => asString(e)),
	hasMealPlans: nullableBooleanTypeFilter(),
	hasBusPlans: nullableBooleanTypeFilter(),
};

export const typeFilterObject: TypeFilterObject<FormValues> = {
	...quickFiltersTypeFilterObject,
	...IndexForms.searchFormValuesFilterObject,
	...IndexForms.filterFormValuesFilterObject,
};

export const advancedFilterInformation: IndexForms.AdvancedFilterInformation = {
	fieldOptions: [
		{ value: "graduatingYear" },
		{ value: "hasPreviousSchooling" },
		{ value: "previousSchoolInformation" },
		{ value: "previousSchoolLocation" },
		{ value: "allergies" },
		{ value: "asthma" },
		{ value: "medication" },
		{ value: "medicationSchedule" },
		{ value: "dietaryRestrictions" },
		{ value: "healthNotes" },
		{ value: "doctorName" },
		{ value: "doctorPhoneNumber" },
		{ value: "emergencyContactInformation" },
	],
	fieldComparisonOptions: {
		graduatingYear: IndexForms.numberComparisonOptions,
		hasPreviousSchooling: IndexForms.booleanComparisonOptions,
		previousSchoolInformation: IndexForms.stringComparisonOptions,
		previousSchoolLocation: IndexForms.stringComparisonOptions,
		allergies: IndexForms.stringComparisonOptions,
		asthma: IndexForms.enumComparisonOptions,
		medication: IndexForms.stringComparisonOptions,
		medicationSchedule: IndexForms.stringComparisonOptions,
		dietaryRestrictions: IndexForms.stringComparisonOptions,
		healthNotes: IndexForms.stringComparisonOptions,
		doctorName: IndexForms.stringComparisonOptions,
		doctorPhoneNumber: IndexForms.stringComparisonOptions,
		emergencyContactInformation: IndexForms.stringComparisonOptions,
	},
	fieldComparisonTranslations: {
		graduatingYear: IndexForms.numberComparisonTranslations,
		hasPreviousSchooling: IndexForms.booleanComparisonTranslations,
		previousSchoolInformation: IndexForms.stringComparisonTranslations,
		previousSchoolLocation: IndexForms.stringComparisonTranslations,
		allergies: IndexForms.stringComparisonTranslations,
		asthma: IndexForms.getRadioComparisonTranslations(StudentFormValues.asthmaOptions),
		medication: IndexForms.stringComparisonTranslations,
		medicationSchedule: IndexForms.stringComparisonTranslations,
		dietaryRestrictions: IndexForms.stringComparisonTranslations,
		healthNotes: IndexForms.stringComparisonTranslations,
		doctorName: IndexForms.stringComparisonTranslations,
		doctorPhoneNumber: IndexForms.stringComparisonTranslations,
		emergencyContactInformation: IndexForms.stringComparisonTranslations,
	},
};
