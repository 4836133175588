import { NullPartial } from "@hex-insights/core";
import {
	SchoolAttendanceRecordCreateInput,
	SchoolAttendanceRecordDetailQuery,
	SchoolAttendanceRecordUpdateInput,
} from "../../GraphQL";
import { SchoolAttendanceRecordFormValues } from "../ModelFormValues";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the SchoolAttendanceRecord model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(
	formValues: SchoolAttendanceRecordFormValues.Create,
): SchoolAttendanceRecordCreateInput {
	const input: NullPartial<SchoolAttendanceRecordCreateInput> = { ...formValues };
	return input as SchoolAttendanceRecordCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the SchoolAttendanceRecord model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<SchoolAttendanceRecordFormValues.Detail>,
	_initialFormValues: SchoolAttendanceRecordFormValues.Detail,
): SchoolAttendanceRecordUpdateInput {
	const input: SchoolAttendanceRecordUpdateInput = { ...formValues };

	if (formValues.checkOutTime === null) {
		input.clearCheckOutTime = true;
	}

	return input;
}

/**
 * Converts a SchoolAttendanceRecord instance to its corresponding detail form values.
 *
 * @param schoolAttendanceRecord The instance to convert.
 */
export function toFormValues(
	schoolAttendanceRecord: SchoolAttendanceRecordDetailQuery["schoolAttendanceRecord"],
): SchoolAttendanceRecordFormValues.Detail {
	return {
		checkInTime: schoolAttendanceRecord.checkInTime,
		checkOutTime: schoolAttendanceRecord.checkOutTime,
		isPresent: schoolAttendanceRecord.isPresent,
		isLate: schoolAttendanceRecord.isLate,
		isExcused: schoolAttendanceRecord.isExcused,
		notes: schoolAttendanceRecord.notes,
		studentID: schoolAttendanceRecord.student?.id ?? null,
	};
}
