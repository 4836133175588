import {
	ChildrenProps,
	ClassNameProps,
	Column,
	Conditional,
	Else,
	formatInt,
	If,
	Paragraph,
	Row,
	StyleProps,
} from "@hex-insights/core";
import {
	CourseSectionPeriod,
	CourseSectionPeriodFormat,
	getDayMinutes,
	useCourseSectionPeriodProgress,
	useIntervalMemo,
} from "../../Utilities";
import { ProgressBarBackground } from "../ProgressBarBackground";

type CourseSectionPeriodForBubble = Pick<
	CourseSectionPeriod,
	"id" | "dayOfWeek" | "startTimeMinutes" | "endTimeMinutes"
> & {
	courseSection: Pick<CourseSectionPeriod["courseSection"], "name">;
	room: Pick<CourseSectionPeriod["room"], "name">;
};

export type CoursesCalendarAgendaBubbleContainerProps = {
	courseSectionPeriod: CourseSectionPeriodForBubble;
	date: Date;
} & Partial<ClassNameProps & StyleProps & ChildrenProps>;

function DefaultBubbleContainer({ className, style, children }: CoursesCalendarAgendaBubbleContainerProps) {
	return (
		<div className={className} style={style}>
			{children}
		</div>
	);
}

export type DayCoursesCalendarAgendaProps = {
	isLoading?: boolean;
	edges: { node: CourseSectionPeriodForBubble }[] | undefined;
	date: Date;
	BubbleContainer?: (props: CoursesCalendarAgendaBubbleContainerProps) => JSX.Element;
};

export function SlimDayCoursesCalendarAgenda({
	isLoading = false,
	edges,
	date,
	BubbleContainer = DefaultBubbleContainer,
}: DayCoursesCalendarAgendaProps) {
	return (
		<Conditional>
			<If condition={isLoading}>Loading...</If>
			<If condition={edges?.length === 0}>
				<Paragraph>No courses to show for this day.</Paragraph>
			</If>
			<Else>
				<Column justify="spaced-start">
					{edges?.map((e) => (
						<CourseSectionPeriodBubble
							key={e.node.id}
							courseSectionPeriod={e.node}
							date={date}
							isSlim
							Container={BubbleContainer}
						/>
					))}
				</Column>
			</Else>
		</Conditional>
	);
}

export function LargeDayCoursesCalendarAgenda({
	isLoading = false,
	edges,
	date,
	BubbleContainer = DefaultBubbleContainer,
}: DayCoursesCalendarAgendaProps) {
	return (
		<Conditional>
			<If condition={isLoading}>Loading...</If>
			<If condition={(edges?.length ?? 0) === 0}>
				<Paragraph>No courses to show for this day.</Paragraph>
			</If>
			<Else>
				<Column justify="spaced-start">
					{edges?.map((e) => (
						<CourseSectionPeriodBubble
							key={e.node.id}
							courseSectionPeriod={e.node}
							date={date}
							Container={BubbleContainer}
						/>
					))}
				</Column>
			</Else>
		</Conditional>
	);
}

type CourseSectionPeriodBubbleProps = {
	courseSectionPeriod: CourseSectionPeriodForBubble;
	date: Date;
	isSlim?: boolean;
	Container: (props: CoursesCalendarAgendaBubbleContainerProps) => JSX.Element;
};

function CourseSectionPeriodBubble({
	courseSectionPeriod,
	date,
	isSlim = false,
	Container,
}: CourseSectionPeriodBubbleProps) {
	const isCurrent = useIntervalMemo(
		() => {
			const currentTimeInMinutes = getDayMinutes();
			return (
				new Date().toDateString() === date.toDateString() &&
				courseSectionPeriod.startTimeMinutes <= currentTimeInMinutes &&
				currentTimeInMinutes < courseSectionPeriod.endTimeMinutes
			);
		},
		15 * 1000,
		[],
	);
	const { minutesToEnd, courseTimeProgressAnimationTarget, refreshIntervalMS } =
		useCourseSectionPeriodProgress(courseSectionPeriod);

	if (isSlim) {
		return (
			<CourseSectionPeriodBubbleSlimDisplay
				courseSectionPeriod={courseSectionPeriod}
				date={date}
				isCurrent={isCurrent}
				minutesToEnd={minutesToEnd}
				Container={Container}
			/>
		);
	}

	return (
		<CourseSectionPeriodBubbleFullDisplay
			courseSectionPeriod={courseSectionPeriod}
			date={date}
			isCurrent={isCurrent}
			minutesToEnd={minutesToEnd}
			courseTimeProgressAnimationTarget={courseTimeProgressAnimationTarget}
			refreshIntervalMS={refreshIntervalMS}
			Container={Container}
		/>
	);
}

type CourseSectionPeriodBubbleSlimDisplayProps = Pick<
	CourseSectionPeriodBubbleProps,
	"courseSectionPeriod" | "date" | "Container"
> & {
	isCurrent: boolean;
	minutesToEnd: number;
};

function CourseSectionPeriodBubbleSlimDisplay({
	courseSectionPeriod,
	isCurrent,
	minutesToEnd,
	date,
	Container,
}: CourseSectionPeriodBubbleSlimDisplayProps) {
	return (
		<Container
			courseSectionPeriod={courseSectionPeriod}
			date={date}
			style={{
				fontWeight: isCurrent ? "bold" : "normal",
				border: `${isCurrent ? "2px" : "1px"} solid ${isCurrent ? "var(--verita-blue)" : "#000"}`,
				borderRadius: "var(--general__tile---border-radius)",
				textDecoration: "none",
			}}
		>
			<Row justify="center" align="center">
				<Column align="center">
					<span>{courseSectionPeriod.courseSection.name}</span>
					<span style={{ fontSize: "0.8rem" }}>
						{isCurrent
							? `${formatInt(minutesToEnd)} min remaining`
							: `${CourseSectionPeriodFormat.Fields.startTimeMinutes(
									courseSectionPeriod.startTimeMinutes,
							  )}-${CourseSectionPeriodFormat.Fields.endTimeMinutes(courseSectionPeriod.endTimeMinutes)}`}
					</span>
				</Column>
			</Row>
		</Container>
	);
}

type CourseSectionPeriodBubbleFullDisplayProps = CourseSectionPeriodBubbleSlimDisplayProps & {
	courseTimeProgressAnimationTarget: number;
	refreshIntervalMS: number;
};

function CourseSectionPeriodBubbleFullDisplay({
	courseSectionPeriod,
	isCurrent,
	minutesToEnd,
	courseTimeProgressAnimationTarget,
	refreshIntervalMS,
	date,
	Container,
}: CourseSectionPeriodBubbleFullDisplayProps) {
	return (
		<Container
			courseSectionPeriod={courseSectionPeriod}
			date={date}
			style={{
				fontWeight: isCurrent ? "bold" : "normal",
				border: `${isCurrent ? "2px" : "1px"} solid ${isCurrent ? "var(--verita-blue)" : "#000"}`,
				borderRadius: "var(--general__tile---border-radius)",
				textDecoration: "none",
				position: "relative",
				overflow: "hidden",
			}}
		>
			{isCurrent && (
				<ProgressBarBackground progress={courseTimeProgressAnimationTarget} refreshIntervalMS={refreshIntervalMS} />
			)}

			<Row justify="center" align="center" style={{ padding: "1rem", position: "relative", zIndex: "1" }}>
				<Column align="center">
					<span style={{ position: "absolute", top: "0.5rem", left: "1.5rem" }}>{courseSectionPeriod.room.name}</span>

					<span style={{ fontSize: "1.5rem" }}>{courseSectionPeriod.courseSection.name}</span>
					<span>
						{isCurrent
							? `${formatInt(minutesToEnd)} min remaining`
							: `${CourseSectionPeriodFormat.Fields.startTimeMinutes(
									courseSectionPeriod.startTimeMinutes,
							  )}-${CourseSectionPeriodFormat.Fields.endTimeMinutes(courseSectionPeriod.endTimeMinutes)}`}
					</span>
				</Column>
			</Row>
		</Container>
	);
}
