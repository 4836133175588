import React from "react";
import { Row } from "@hex-insights/core";
import { NumberFieldValue, NumberInput, NumberInputProps } from "@hex-insights/forms";

export type TimeInputProps = Omit<NumberInputProps, "unit" | "step" | "placeholder">;

export function TimeInput({
	id,
	name,
	value,
	setValue,
	onFocus,
	onBlur,
	autoFocus,
	disabled,
	className,
}: TimeInputProps) {
	const setValueFromHours = React.useCallback(
		(action: React.SetStateAction<NumberFieldValue>) => {
			setValue((prev) => {
				const [prevHours, prevMinutes] = splitHoursAndMinutes(prev);
				const nextHours = typeof action === "function" ? action(prevHours) : action;
				return joinHoursAndMinutes(nextHours, prevMinutes);
			});
		},
		[setValue],
	);
	const setValueFromMinutes = React.useCallback(
		(action: React.SetStateAction<NumberFieldValue>) => {
			setValue((prev) => {
				const [prevHours, prevMinutes] = splitHoursAndMinutes(prev);
				const nextMinutes = typeof action === "function" ? action(prevMinutes) : action;
				return joinHoursAndMinutes(prevHours, nextMinutes);
			});
		},
		[setValue],
	);

	const [hours, minutes] = splitHoursAndMinutes(value);

	return (
		<Row align="center">
			<NumberInput
				id={id ? id + "__hours" : undefined}
				name={name ? name + "__hours" : undefined}
				value={hours}
				setValue={setValueFromHours}
				onFocus={onFocus}
				onBlur={onBlur}
				autoFocus={autoFocus}
				disabled={disabled}
				className={className}
			/>
			<span style={{ padding: "0 0.25rem" }}>:</span>
			<NumberInput
				id={id ? id + "__minutes" : undefined}
				name={name ? name + "__minutes" : undefined}
				value={minutes}
				setValue={setValueFromMinutes}
				onFocus={onFocus}
				onBlur={onBlur}
				disabled={disabled}
				className={className}
			/>
		</Row>
	);
}

function splitHoursAndMinutes(value: NumberFieldValue): [NumberFieldValue, NumberFieldValue] {
	if (value === null) {
		return [null, null];
	}

	const hours = Math.floor(value / 60);
	const minutes = value % 60;
	return [hours, minutes];
}

function joinHoursAndMinutes(hours: NumberFieldValue, minutes: NumberFieldValue) {
	if (hours === null && minutes === null) {
		return null;
	}
	return (hours ?? 0) * 60 + (minutes ?? 0);
}
