import React from "react";
import { ApolloCache, FetchResult, gql } from "@apollo/client";
import {
	EmailAddress,
	EmailAddressCreateInput,
	EmailAddressCreateMutation,
	EmailAddressDeleteMutation,
	EmailAddressDetailDocument,
	EmailAddressDetailQuery,
	EmailAddressDetailQueryVariables,
	EmailAddressUpdateInput,
	EmailAddressUpdateMutation,
	graphTypeNames,
	Person,
	useEmailAddressCreateMutation,
	useEmailAddressDeleteMutation,
	useEmailAddressUpdateMutation,
} from "../../GraphQL";
import { EmailAddressFormConversion } from "../ModelFormConversion";
import { EmailAddressFormValues } from "../ModelFormValues";

/**
 * Returns a `create` function for the EmailAddress model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the EmailAddress model.
 */
export function useCreate() {
	const [createEmailAddress] = useEmailAddressCreateMutation();

	return React.useCallback(
		async (formValues: EmailAddressFormValues.Create) => {
			const input = EmailAddressFormConversion.toGQLCreateInput(formValues);
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createEmailAddress({ variables: { input }, update: updateCache });

			return { data: data?.createEmailAddress ?? null, errors: errors ?? null };
		},
		[createEmailAddress],
	);
}

export function useCreateInPerson() {
	const [createEmailAddress] = useEmailAddressCreateMutation();

	return React.useCallback(
		async (formValues: EmailAddressFormValues.CreateInPerson) => {
			const input = EmailAddressFormConversion.toGQLCreateInput({ ...formValues, personID: null });
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createEmailAddress({ variables: { input }, update: updateCache });

			return { data: data?.createEmailAddress ?? null, errors: errors ?? null };
		},
		[createEmailAddress],
	);
}

/**
 * Returns an `update` function for the EmailAddress model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the EmailAddress model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: EmailAddress["id"]) {
	const [updateEmailAddress] = useEmailAddressUpdateMutation();

	return React.useCallback(
		async (
			changedFormValues: Partial<EmailAddressFormValues.Detail>,
			initialFormValues: EmailAddressFormValues.Detail,
		) => {
			const input = EmailAddressFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

			const { data, errors } = await updateEmailAddress({ variables: { id, input }, update: updateCache });

			return { data: data?.updateEmailAddress ?? null, errors: errors ?? null };
		},
		[updateEmailAddress, id],
	);
}

/**
 * Returns a `del` function for the EmailAddress model.
 *
 * @param id The ID of the instance to delete.
 */
export function useDelete(id: EmailAddress["id"]) {
	const [deleteEmailAddress] = useEmailAddressDeleteMutation();

	return React.useCallback(async () => {
		const updateCache = getUpdateCacheForDelete(id);

		const { data, errors } = await deleteEmailAddress({ variables: { id }, update: updateCache });

		return { data: data?.deleteEmailAddress ?? false, errors: errors ?? null };
	}, [deleteEmailAddress, id]);
}

function getUpdateCacheForCreate(input: EmailAddressCreateInput) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<EmailAddressCreateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const createdObject = result.data.createEmailAddress;

		cache.writeQuery<EmailAddressDetailQuery, EmailAddressDetailQueryVariables>({
			query: EmailAddressDetailDocument,
			data: { emailAddress: createdObject },
			variables: { id: createdObject.id },
		});

		if (input.personID) {
			addToEmailAddressesOfPersonCache(cache, input.personID, createdObject);
		}

		cache.evict({ id: "ROOT_QUERY", fieldName: "emailAddressConnection" });
	};
}

function getUpdateCacheForUpdate(input: EmailAddressUpdateInput, initialFormValues: EmailAddressFormValues.Detail) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<EmailAddressUpdateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const updatedObject = result.data.updateEmailAddress;

		if (initialFormValues.personID && input.personID) {
			removeFromEmailAddressesOfPersonCache(cache, initialFormValues.personID, updatedObject);
		}
		if (input.personID) {
			addToEmailAddressesOfPersonCache(cache, input.personID, updatedObject);
		}
	};
}

function getUpdateCacheForDelete(id: EmailAddress["id"]) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<EmailAddressDeleteMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (!result.data?.deleteEmailAddress) {
			return;
		}

		cache.evict({ id: cache.identify({ id, __typename: graphTypeNames.EmailAddress }) });
		cache.evict({ id: "ROOT_QUERY", fieldName: "emailAddressConnection" });
		cache.gc();
	};
}

function addToEmailAddressesOfPersonCache(
	cache: ApolloCache<any>,
	targetID: Person["id"],
	object: Pick<EmailAddress, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Person }),
		fields: {
			emailAddresses: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromEmailAddressesOfPersonCache(
	cache: ApolloCache<any>,
	targetID: Person["id"],
	object: Pick<EmailAddress, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Person }),
		fields: {
			emailAddresses: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function toCacheRef(cache: ApolloCache<any>, object: Pick<EmailAddress, "id">) {
	return cache.writeFragment({
		fragment: gql`
			fragment EmailAddressRef on EmailAddress {
				id
			}
		`,
		data: object,
	});
}
