import { newPageInfo } from "@hex-insights/router";
import { rootPageInfo } from "../../root.pageinfo";

export type StudentHubPageRouteParams = {};

export const studentHubPageInfo = newPageInfo({
	title: "Students",
	description: "View information on students.",
	exact: false,
	to: "/students",
	path: "/students",
	parent: rootPageInfo,
});
