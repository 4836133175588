import React from "react";
import { ApplyCreateFunction, ApplyUpdateFunction, onCreateSubmit, onUpdateSubmit } from "@hex-insights/app-modules";
import { Button, Column, If, Row } from "@hex-insights/core";
import {
	anyFieldEditing,
	everyFieldEditing,
	Form,
	FormState,
	FormSubmitFunction,
	FormType,
	SubmissionStatus,
	SubmitButton,
} from "@hex-insights/forms";
import {
	EmailAddressDetailQuery,
	EmailAddressFormConversion,
	EmailAddressFormState,
	EmailAddressFormValues,
} from "../../../../Utilities";
import { FormActionRow, FormColumn } from "../../../FormAddon";
import * as Field from "./Field";

export type ControlledCreateProps = {
	applyCreate: ApplyCreateFunction<EmailAddressFormValues.Create>;
	onSuccess: () => void;
};

/**
 * Renders the create form of the EmailAddress model using an internally managed form state.
 */
export function ControlledCreate(props: ControlledCreateProps) {
	const formState = EmailAddressFormState.useCreateFormState();

	return <Create formState={formState} {...props} />;
}

export type CreateProps = ControlledCreateProps & {
	formState: FormState<EmailAddressFormValues.Create>;
};

/**
 * Renders the create form of the EmailAddress model using the given form state.
 */
export function Create({ formState, applyCreate, onSuccess }: CreateProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<EmailAddressFormValues.Create>>(
		async ({ formValues }) => {
			return onCreateSubmit(formValues, applyCreate);
		},
		[applyCreate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	return (
		<Form name="emailAddress.create" formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)}>
			<Column justify="spaced-start">
				<Field.Email formState={formState} formType={FormType.Create} />
				<Field.Person formState={formState} formType={FormType.Create} />

				<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
					Submit
				</SubmitButton>
			</Column>
		</Form>
	);
}

export type CreateInPersonProps = {
	applyCreate: ApplyCreateFunction<EmailAddressFormValues.CreateInPerson>;
	onSuccess: () => void;
	formState: FormState<EmailAddressFormValues.CreateInPerson>;
};

export function CreateInPerson({ formState, applyCreate, onSuccess }: CreateInPersonProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<EmailAddressFormValues.CreateInPerson>>(
		async ({ formValues }) => {
			return onCreateSubmit(formValues, applyCreate);
		},
		[applyCreate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	return (
		<Form name="emailAddress.create" formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)}>
			<FormColumn justify="spaced-start">
				<CreateInPersonFields formState={formState} />

				<FormActionRow>
					<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
						Submit
					</SubmitButton>
				</FormActionRow>
			</FormColumn>
		</Form>
	);
}

type CreateInPersonFieldsProps = {
	formState: FormState<EmailAddressFormValues.CreateInPerson>;
};

export function CreateInPersonFields({ formState }: CreateInPersonFieldsProps) {
	return (
		<Column justify="spaced-start">
			<Field.Email formState={formState} formType={FormType.Create} />
		</Column>
	);
}

export type ControlledDetailProps = {
	emailAddress: EmailAddressDetailQuery["emailAddress"];
	applyUpdate: ApplyUpdateFunction<EmailAddressFormValues.Detail>;
	onSuccess: () => void;
};

/**
 * Renders the detail form of the EmailAddress model using an internally managed form state.
 */
export function ControlledDetail(props: ControlledDetailProps) {
	const initialFormValues = React.useMemo(
		() => EmailAddressFormConversion.toFormValues(props.emailAddress),
		[props.emailAddress],
	);
	const formState = EmailAddressFormState.useDetailFormState({ initialFormValues });

	return <Detail formState={formState} {...props} />;
}

export type DetailProps = ControlledDetailProps & {
	formState: FormState<EmailAddressFormValues.Detail>;
};

/**
 * Renders the detail form of the EmailAddress model using the given form state.
 */
export function Detail({ formState, emailAddress, applyUpdate, onSuccess }: DetailProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<EmailAddressFormValues.Detail>>(
		async (formState) => {
			return onUpdateSubmit(formState, applyUpdate);
		},
		[applyUpdate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	const { anyEditing, everyEditing } = React.useMemo(() => {
		return {
			anyEditing: anyFieldEditing({
				formEditing: formState.formEditing,
				formSubFormStates: formState.formSubFormStates,
			}),
			everyEditing: everyFieldEditing({
				formEditing: formState.formEditing,
				formSubFormStates: formState.formSubFormStates,
			}),
		};
	}, [formState.formEditing, formState.formSubFormStates]);

	return (
		<Form
			name={`emailAddress.detail.${emailAddress.id}`}
			formState={formState}
			onSubmit={formState.onSubmitWrapper(onSubmit)}
		>
			<Column justify="spaced-start">
				<Row justify="spaced-start" horizontalSpacing="0.75rem">
					<If condition={!everyEditing}>
						<Button variant="tertiary" size="small" onClick={() => formState.setFormEditing(true)}>
							Edit
						</Button>
					</If>
					<If condition={anyEditing}>
						<Button variant="tertiary" size="small" onClick={() => formState.setFormEditing(false)}>
							Stop Editing
						</Button>
					</If>
				</Row>

				<Field.Email formState={formState} formType={FormType.Update} id={emailAddress.id} />
				<Field.Person
					formState={formState}
					formType={FormType.Update}
					id={emailAddress.id}
					currentPerson={emailAddress.person}
				/>
			</Column>
		</Form>
	);
}

export type ControlledReadOnlyProps = {
	emailAddress: EmailAddressDetailQuery["emailAddress"];
};

/**
 * Renders a read-only detail form of the EmailAddress model using an internally managed form state.
 */
export function ControlledReadOnly(props: ControlledReadOnlyProps) {
	const initialFormValues = React.useMemo(
		() => EmailAddressFormConversion.toFormValues(props.emailAddress),
		[props.emailAddress],
	);
	const formState = EmailAddressFormState.useReadOnlyFormState({ initialFormValues });

	return <ReadOnly formState={formState} {...props} />;
}

export type ReadOnlyProps = ControlledReadOnlyProps & {
	formState: FormState<EmailAddressFormValues.Detail>;
};

/**
 * Renders a read-only detail form of the EmailAddress model using the given form state.
 */
export function ReadOnly({ formState, emailAddress }: ReadOnlyProps) {
	return (
		<Column justify="spaced-start">
			<Field.Email formState={formState} formType={FormType.Update} id={emailAddress.id} />
			<Field.Person
				formState={formState}
				formType={FormType.Update}
				id={emailAddress.id}
				currentPerson={emailAddress.person}
			/>
		</Column>
	);
}
