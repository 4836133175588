import { formatDateTime } from "@hex-insights/core";
import { minutesToTime } from "../../../date-time";
import { BusPlan } from "../../../GraphQL";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the hasPickup field of the BusPlan model.
 */
export function hasPickup(value: BusPlan["hasPickup"]): string {
	return value ? "Yes" : "No";
}

/**
 * Formats the pickupTimeMinutes field of the BusPlan model.
 */
export function pickupTimeMinutes(value: BusPlan["pickupTimeMinutes"] | null): string {
	return formatDateTime(minutesToTime(value ?? 0), "HH:mm");
}

/**
 * Formats the pickupLocation field of the BusPlan model.
 */
export function pickupLocation(value: BusPlan["pickupLocation"]): string {
	return toString(value);
}

/**
 * Formats the hasDropoff field of the BusPlan model.
 */
export function hasDropoff(value: BusPlan["hasDropoff"]): string {
	return value ? "Yes" : "No";
}

/**
 * Formats the dropoffTimeMinutes field of the BusPlan model.
 */
export function dropoffTimeMinutes(value: BusPlan["dropoffTimeMinutes"] | null): string {
	return formatDateTime(minutesToTime(value ?? 0), "HH:mm");
}

/**
 * Formats the dropoffLocation field of the BusPlan model.
 */
export function dropoffLocation(value: BusPlan["dropoffLocation"]): string {
	return toString(value);
}
