import React from "react";
import { ApolloCache, FetchResult, gql } from "@apollo/client";
import {
	graphTypeNames,
	Student,
	StudentDocument,
	StudentDocumentCreateInput,
	StudentDocumentCreateMutation,
	StudentDocumentDeleteMutation,
	StudentDocumentDetailDocument,
	StudentDocumentDetailQuery,
	StudentDocumentDetailQueryVariables,
	StudentDocumentUpdateInput,
	StudentDocumentUpdateMutation,
	UserGroup,
	useStudentDocumentCreateMutation,
	useStudentDocumentDeleteMutation,
	useStudentDocumentUpdateMutation,
} from "../../GraphQL";
import { StudentDocumentFormConversion } from "../ModelFormConversion";
import { StudentDocumentFormValues } from "../ModelFormValues";

/**
 * Returns a `create` function for the StudentDocument model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the StudentDocument model.
 */
export function useCreate() {
	const [createStudentDocument] = useStudentDocumentCreateMutation();

	return React.useCallback(
		async (formValues: StudentDocumentFormValues.Create) => {
			const input = StudentDocumentFormConversion.toGQLCreateInput(formValues);
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createStudentDocument({ variables: { input }, update: updateCache });

			return { data: data?.createStudentDocument ?? null, errors: errors ?? null };
		},
		[createStudentDocument],
	);
}

/**
 * Returns an `update` function for the StudentDocument model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the StudentDocument model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: StudentDocument["id"]) {
	const [updateStudentDocument] = useStudentDocumentUpdateMutation();

	return React.useCallback(
		async (
			changedFormValues: Partial<StudentDocumentFormValues.Detail>,
			initialFormValues: StudentDocumentFormValues.Detail,
		) => {
			const input = StudentDocumentFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

			const { data, errors } = await updateStudentDocument({ variables: { id, input }, update: updateCache });

			return { data: data?.updateStudentDocument ?? null, errors: errors ?? null };
		},
		[updateStudentDocument, id],
	);
}

/**
 * Returns a `del` function for the StudentDocument model.
 *
 * @param id The ID of the instance to delete.
 */
export function useDelete(id: StudentDocument["id"]) {
	const [deleteStudentDocument] = useStudentDocumentDeleteMutation();

	return React.useCallback(async () => {
		const updateCache = getUpdateCacheForDelete(id);

		const { data, errors } = await deleteStudentDocument({ variables: { id }, update: updateCache });

		return { data: data?.deleteStudentDocument ?? false, errors: errors ?? null };
	}, [deleteStudentDocument, id]);
}

function getUpdateCacheForCreate(input: StudentDocumentCreateInput) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<StudentDocumentCreateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const createdObject = result.data.createStudentDocument;

		cache.writeQuery<StudentDocumentDetailQuery, StudentDocumentDetailQueryVariables>({
			query: StudentDocumentDetailDocument,
			data: { studentDocument: createdObject },
			variables: { id: createdObject.id },
		});

		if (input.sharingUserGroupIDs) {
			for (let i = 0; i < input.sharingUserGroupIDs.length; i++) {
				addToSharedStudentDocumentsOfUserGroupCache(cache, input.sharingUserGroupIDs[i], createdObject);
			}
		}

		if (input.studentID) {
			addToStudentDocumentsOfStudentCache(cache, input.studentID, createdObject);
		}

		cache.evict({ id: "ROOT_QUERY", fieldName: "studentDocumentConnection" });
	};
}

function getUpdateCacheForUpdate(
	input: StudentDocumentUpdateInput,
	initialFormValues: StudentDocumentFormValues.Detail,
) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<StudentDocumentUpdateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const updatedObject = result.data.updateStudentDocument;

		if (input.addSharingUserGroupIDs) {
			for (let i = 0; i < input.addSharingUserGroupIDs.length; i++) {
				addToSharedStudentDocumentsOfUserGroupCache(cache, input.addSharingUserGroupIDs[i], updatedObject);
			}
		}
		if (input.removeSharingUserGroupIDs) {
			for (let i = 0; i < input.removeSharingUserGroupIDs.length; i++) {
				removeFromSharedStudentDocumentsOfUserGroupCache(cache, input.removeSharingUserGroupIDs[i], updatedObject);
			}
		}

		if (initialFormValues.studentID && input.studentID) {
			removeFromStudentDocumentsOfStudentCache(cache, initialFormValues.studentID, updatedObject);
		}
		if (input.studentID) {
			addToStudentDocumentsOfStudentCache(cache, input.studentID, updatedObject);
		}
	};
}

function getUpdateCacheForDelete(id: StudentDocument["id"]) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<StudentDocumentDeleteMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (!result.data?.deleteStudentDocument) {
			return;
		}

		cache.evict({ id: cache.identify({ id, __typename: graphTypeNames.StudentDocument }) });
		cache.evict({ id: "ROOT_QUERY", fieldName: "studentDocumentConnection" });
		cache.gc();
	};
}

function addToSharedStudentDocumentsOfUserGroupCache(
	cache: ApolloCache<any>,
	targetID: UserGroup["id"],
	object: Pick<StudentDocument, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.UserGroup }),
		fields: {
			sharedStudentDocuments: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromSharedStudentDocumentsOfUserGroupCache(
	cache: ApolloCache<any>,
	targetID: UserGroup["id"],
	object: Pick<StudentDocument, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.UserGroup }),
		fields: {
			sharedStudentDocuments: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function addToStudentDocumentsOfStudentCache(
	cache: ApolloCache<any>,
	targetID: Student["id"],
	object: Pick<StudentDocument, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Student }),
		fields: {
			studentDocuments: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromStudentDocumentsOfStudentCache(
	cache: ApolloCache<any>,
	targetID: Student["id"],
	object: Pick<StudentDocument, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Student }),
		fields: {
			studentDocuments: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function toCacheRef(cache: ApolloCache<any>, object: Pick<StudentDocument, "id">) {
	return cache.writeFragment({
		fragment: gql`
			fragment StudentDocumentRef on StudentDocument {
				id
			}
		`,
		data: object,
	});
}
