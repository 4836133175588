import { LearningObjectiveNameFragment } from "../../../GraphQL";
import * as Fields from "./fields";

export { Fields };

/**
 * Formats the full name of the given instance.
 */
export function name(instance: LearningObjectiveNameFragment) {
	return `${Fields.category(instance.category)}: ${Fields.description(instance.description)}`;
}
