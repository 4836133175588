import React from "react";
import TextareaAutosize from "react-textarea-autosize";
import { ClassNameProps, makeClassName, StyleProps } from "@hex-insights/core";
import {
	FieldDisplayArgs,
	FormValues,
	TextField,
	TextFieldInputProps,
	TextFieldProps,
	TextFieldValue,
} from "@hex-insights/forms";
import styles from "./styles.module.css";

export type TextAreaFieldProps<FV extends FormValues> = TextFieldProps<FV> & {
	minRows?: number;
};

export function TextAreaField<FV extends FormValues>({ className, minRows, ...props }: TextAreaFieldProps<FV>) {
	return (
		<TextField
			Input={React.useCallback(
				(props: TextFieldInputProps) => (
					<TextAreaInput minRows={minRows} {...props} />
				),
				[minRows],
			)}
			display={TextAreaFieldDisplay}
			className={makeClassName(styles["text-area-field"], className)}
			{...props}
		/>
	);
}

type TextAreaInputProps = {
	// /** The type of the text input. */
	// type?: TextAreaInputType;
	/** The ID of the input. */
	id?: string;
	/** The name of the input. */
	name?: string;
	/** The value of the input. */
	value: TextFieldValue;
	/** A callback function to update the input value when a change happens. */
	setValue: (value: TextFieldValue) => void;
	/** A callback function to call when the input becomes focused. */
	onFocus?: () => void;
	/** A callback function to call when focus is removed from the input. */
	onBlur?: () => void;
	/** Placeholder text to display when the value is blank. */
	placeholder?: string;
	/** Auto-complete option for the input (supports the usual HTML values). */
	autoComplete?: string;
	/** Indicates if the input should be auto-focused. */
	autoFocus?: boolean;
	/** Indicates if the input is disabled. */
	disabled?: boolean;
	minRows?: number;
} & Partial<ClassNameProps>;

/**
 * Wraps the HTML `<input>` element and creates an `onChange` function using the given `setValue` function. Requires the
 * input to be a controlled input.
 */
function TextAreaInput({
	id,
	name,
	value,
	setValue,
	onFocus,
	onBlur,
	placeholder,
	autoComplete,
	autoFocus = false,
	disabled = false,
	minRows = 2,
	className,
}: TextAreaInputProps) {
	const onChange = React.useCallback(
		(event: React.ChangeEvent<HTMLTextAreaElement>) => {
			setValue(event.target.value);
		},
		[setValue],
	);

	return (
		<TextareaAutosize
			id={id ?? name}
			name={name}
			value={value}
			onChange={onChange}
			onFocus={onFocus}
			onBlur={onBlur}
			placeholder={placeholder}
			autoComplete={autoComplete}
			autoFocus={autoFocus}
			disabled={disabled}
			minRows={minRows}
			className={makeClassName(styles["text-area"], className)}
		/>
	);
}

export function TextAreaFieldDisplay({
	formattedValue,
	className,
	style,
}: FieldDisplayArgs<TextFieldValue> & Partial<ClassNameProps & StyleProps>) {
	return (
		<pre className={makeClassName(styles["text-area-display"], className)} style={style}>
			{formattedValue}
		</pre>
	);
}
