import { NullPartial } from "@hex-insights/core";
import {
	EmailAddressCreateInput,
	EmailAddressDetailQuery,
	EmailAddressInPersonCreateInput,
	EmailAddressUpdateInput,
} from "../../GraphQL";
import { EmailAddressFormValues } from "../ModelFormValues";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the EmailAddress model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(formValues: EmailAddressFormValues.Create): EmailAddressCreateInput {
	const input: NullPartial<EmailAddressCreateInput> = { ...formValues };
	return input as EmailAddressCreateInput;
}

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the EmailAddress model in the
 * Person model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInPersonInput(
	formValues: EmailAddressFormValues.CreateInPerson,
): EmailAddressInPersonCreateInput {
	const input: NullPartial<EmailAddressInPersonCreateInput> = { ...formValues };
	return input as EmailAddressInPersonCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the EmailAddress model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<EmailAddressFormValues.Detail>,
	_initialFormValues: EmailAddressFormValues.Detail,
): EmailAddressUpdateInput {
	const input: EmailAddressUpdateInput = { ...formValues };
	return input;
}

/**
 * Converts a EmailAddress instance to its corresponding detail form values.
 *
 * @param emailAddress The instance to convert.
 */
export function toFormValues(emailAddress: EmailAddressDetailQuery["emailAddress"]): EmailAddressFormValues.Detail {
	return {
		email: emailAddress.email,
		personID: emailAddress.person?.id ?? null,
	};
}
