import { ServiceSubscription } from "../../../GraphQL";
import { formatPrice } from "../../money";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the price field of the ServiceSubscription model.
 */
export function price(value: ServiceSubscription["price"] | null): string {
	return formatPrice(value);
}
