import { Invoice, InvoiceLineItem, ServiceSubscription } from "../../GraphQL";

export type Base = {
	lineItemNumber: InvoiceLineItem["lineItemNumber"] | null;
	amount: InvoiceLineItem["amount"] | null;
	description: InvoiceLineItem["description"];
	invoiceID: Invoice["id"] | null;
	serviceSubscriptionID: ServiceSubscription["id"] | null;
};

export type Create = Base;

export type Detail = Base;

export const initialCreate: Create = {
	lineItemNumber: 0,
	amount: null,
	description: "",
	invoiceID: null,
	serviceSubscriptionID: null,
};
