import { formatDateTime } from "@hex-insights/core";
import { Redirect, Route, useActivePageRegistration } from "@hex-insights/router";
import { PageRouteSwitch } from "../../Components";
import { ScheduleDayPage } from "./Day";
import { scheduleDayPageInfo } from "./Day/pageinfo";
import { schedulePageInfo } from "./pageinfo";

export function SchedulePage() {
	useActivePageRegistration(schedulePageInfo);

	return (
		<PageRouteSwitch>
			<Route exact path={schedulePageInfo.path}>
				<Redirect to={scheduleDayPageInfo.to(formatDateTime(new Date(), "YYYY-MM-DD"))} />
			</Route>
			<Route {...scheduleDayPageInfo.routeProps}>
				<ScheduleDayPage />
			</Route>
		</PageRouteSwitch>
	);
}
