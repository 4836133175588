// Code generated by @hex-insights/frontend-generator; DO NOT EDIT.

export const titleCaseModelNames = {
	AcademicYear: "Academic Year",
	AcademicYearTuition: "Academic Year Tuition",
	Address: "Address",
	BillingSetupSubmission: "Billing Setup Submission",
	Bus: "Bus",
	BusPlan: "Bus Plan",
	CalendarEvent: "Calendar Event",
	Campus: "Campus",
	ClassYear: "Class Year",
	Contract: "Contract",
	ContractInstallment: "Contract Installment",
	Course: "Course",
	CourseAttendanceRecord: "Course Attendance Record",
	CourseSection: "Course Section",
	CourseSectionPeriod: "Course Section Period",
	Discount: "Discount",
	DocumentResource: "Document Resource",
	DocumentResourceFolder: "Document Resource Folder",
	DocumentResourceUserViewRecord: "Document Resource User View Record",
	EmailAddress: "Email Address",
	Employee: "Employee",
	EnrollmentApplication: "Enrollment Application",
	EnrollmentApplicationNote: "Enrollment Application Note",
	EnrollmentApplicationParent: "Enrollment Application Parent",
	EnrollmentApplicationStudent: "Enrollment Application Student",
	EnrollmentApplicationSubmission: "Enrollment Application Submission",
	EnrollmentApplicationUserViewRecord: "Enrollment Application User View Record",
	EnrollmentInvitation: "Enrollment Invitation",
	Expense: "Expense",
	ExpenseBudget: "Expense Budget",
	ExpenseCategory: "Expense Category",
	HomeRoom: "Home Room",
	HomeRoomSection: "Home Room Section",
	Invoice: "Invoice",
	InvoiceLineItem: "Invoice Line Item",
	LearningObjective: "Learning Objective",
	LearningObjectiveMark: "Learning Objective Mark",
	LessonPlan: "Lesson Plan",
	LoginRecord: "Login Record",
	MFAFactor: "MFA Factor",
	MFAInvalidOTPAttempt: "MFA Invalid OTP Attempt",
	MealMenu: "Meal Menu",
	MealPlan: "Meal Plan",
	Note: "Note",
	NoteAttachment: "Note Attachment",
	NoteComment: "Note Comment",
	NoteReaction: "Note Reaction",
	Parent: "Parent",
	Payment: "Payment",
	PaymentLineItem: "Payment Line Item",
	Permission: "Permission",
	PermissionGroup: "Permission Group",
	Person: "Person",
	PhoneNumber: "Phone Number",
	Quotation: "Quotation",
	Relationship: "Relationship",
	ReportCard: "Report Card",
	Room: "Room",
	SchoolAttendanceRecord: "School Attendance Record",
	SchoolStudentEnrollment: "School Student Enrollment",
	Service: "Service",
	ServiceSubscription: "Service Subscription",
	Session: "Session",
	Student: "Student",
	StudentDocument: "Student Document",
	Term: "Term",
	TrustedDevice: "Trusted Device",
	Tuition: "Tuition",
	User: "User",
	UserGroup: "User Group",
};
