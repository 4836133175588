import React from "react";
import { ApplyCreateFunction } from "@hex-insights/app-modules";
import { Button, Column, getObjectValues, Heading, If, Modal, ModalProps, useToggle } from "@hex-insights/core";
import { BooleanField, FieldDisplayArgs, FormType, RadioField, SelectField } from "@hex-insights/forms";
import {
	ParentCreateMutation,
	ParentFormState,
	ParentFormValues,
	ParentMutation,
	ParentSelect,
	RelationshipFormValues,
	StudentSelect,
	useParentSelectLazyQuery,
	useStudentSelectLazyQuery,
} from "../../../../Utilities";
import { ParentLink, StudentLink } from "../../../Links";
import { ParentForm } from "../Parent";
import { BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the Relationship model.
 */
type FieldProps<K extends keyof RelationshipFormValues.Base = keyof RelationshipFormValues.Base> = BaseFieldProps<
	Pick<RelationshipFormValues.Base, K>
>;

/**
 * Renders a field component for the `relationshipType` field of the Relationship model.
 */
export function RelationshipType({ formState }: FieldProps<"relationshipType">) {
	return (
		<RadioField
			formState={formState}
			name="relationshipType"
			options={RelationshipFormValues.relationshipTypeOptions}
			blankValue={null}
		/>
	);
}

/**
 * Renders a field component for the `isPrimary` field of the Relationship model.
 */
export function IsPrimary({ formState }: FieldProps<"isPrimary">) {
	return <BooleanField formState={formState} name="isPrimary" label="Primary Contact" format={yesNoFormat} />;
}

/**
 * Renders a field component for the `isFinancialContact` field of the Relationship model.
 */
export function IsFinancialContact({ formState }: FieldProps<"isFinancialContact">) {
	return (
		<BooleanField formState={formState} name="isFinancialContact" label="Financial Contact" format={yesNoFormat} />
	);
}

/**
 * Renders a field component for the `isEmergencyContact` field of the Relationship model.
 */
export function IsEmergencyContact({ formState }: FieldProps<"isEmergencyContact">) {
	return (
		<BooleanField formState={formState} name="isEmergencyContact" label="Emergency Contact" format={yesNoFormat} />
	);
}

/**
 * Renders a field component for the `isAuthorizedForPickup` field of the Relationship model.
 */
export function IsAuthorizedForPickup({ formState }: FieldProps<"isAuthorizedForPickup">) {
	return (
		<BooleanField
			formState={formState}
			name="isAuthorizedForPickup"
			label="Authorized for Pickup"
			format={yesNoFormat}
		/>
	);
}

/**
 * Renders a field component for the `livesWithStudent` field of the Relationship model.
 */
export function LivesWithStudent({ formState }: FieldProps<"livesWithStudent">) {
	return <BooleanField formState={formState} name="livesWithStudent" format={yesNoFormat} />;
}

function yesNoFormat(value: boolean) {
	return value ? "Yes" : "No";
}

export type ParentProps = FieldProps<"parentID"> & {
	currentParent?: ParentSelect.ModelForOption | null;
};

export function Parent({ formState, currentParent }: ParentProps) {
	const [loadOptions, { loading, data }] = useParentSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.parentID) {
			loadOptions();
		}
	}, [formState.formEditing.parentID, loadOptions]);
	const options = React.useMemo(
		() => ParentSelect.toOptions(data?.parentConnection.edges, currentParent),
		[data, currentParent],
	);

	const addToCache = ParentSelect.useAddToCache();
	const { parentID: setParentID } = formState.formSetFunctions;
	const onCreate = React.useCallback(
		(parent: ParentCreateMutation["createParent"]) => {
			addToCache(parent);
			setParentID(parent.id);
		},
		[addToCache, setParentID],
	);

	return (
		<Column justify="spaced-start" verticalSpacing="0.25rem" align="flex-end" style={{ width: "fit-content" }}>
			<SelectField
				formState={formState}
				name="parentID"
				isLoading={loading}
				options={options}
				blankValue={null}
				display={displayParent}
				footerElement={<CreateParent onCreate={onCreate} />}
			/>
			<If condition={FormType.isCreate(formState.formType)}>
				<CreateParent onCreate={onCreate} />
			</If>
		</Column>
	);
}

function displayParent({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <ParentLink instance={{ id }}>{formattedValue}</ParentLink>;
}

type CreateParentProps = {
	onCreate: (data: ParentCreateMutation["createParent"]) => void;
};

function CreateParent({ onCreate }: CreateParentProps) {
	const { isOn: isOpen, toggle: toggleIsOpen, setIsOn: setIsOpen } = useToggle(false);

	const create = ParentMutation.useCreateWithPerson();

	const applyCreate = React.useCallback(
		async (formValues: ParentFormValues.CreateWithPerson) => {
			const { data, errors } = await create(formValues);
			if (data !== null) {
				onCreate(data);
			}
			return errors;
		},
		[create, onCreate],
	);

	const onSuccess = React.useCallback(() => setIsOpen(false), [setIsOpen]);

	return (
		<React.Fragment>
			<Button variant="link" size="small" onClick={toggleIsOpen}>
				Create New Parent
			</Button>

			<Modal.If condition={isOpen}>
				<ParentCreateModal onClose={toggleIsOpen} applyCreate={applyCreate} onSuccess={onSuccess} />
			</Modal.If>
		</React.Fragment>
	);
}

type ParentCreateModalProps = {
	applyCreate: ApplyCreateFunction<ParentFormValues.CreateWithPerson>;
	onSuccess: () => void;
} & Pick<ModalProps, "ifRef" | "onClose">;

function ParentCreateModal({ applyCreate, onSuccess, ifRef, onClose }: ParentCreateModalProps) {
	const formState = ParentFormState.useCreateWithPersonFormState();

	const anyFieldsChanged = React.useMemo(() => {
		return getObjectValues(formState.formChanged).some((e) => e);
	}, [formState.formChanged]);

	return (
		<Modal ifRef={ifRef} onClose={onClose} confirmOnClose={anyFieldsChanged} style={{ width: "fit-content" }}>
			<Modal.Header>
				<Heading level={2} noMargin>
					New Parent
				</Heading>
			</Modal.Header>

			<Modal.Body>
				<div onKeyDown={(e) => console.log(e.target)}>
					<ParentForm.CreateWithPerson formState={formState} applyCreate={applyCreate} onSuccess={onSuccess} />
				</div>
			</Modal.Body>
		</Modal>
	);
}

export type StudentProps = FieldProps<"studentID"> & {
	currentStudent?: StudentSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `student` edge of the Relationship model.
 */
export function Student({ formState, currentStudent }: StudentProps) {
	const [loadOptions, { loading, data }] = useStudentSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.studentID) {
			loadOptions();
		}
	}, [formState.formEditing.studentID, loadOptions]);
	const options = React.useMemo(
		() => StudentSelect.toOptions(data?.studentConnection.edges, currentStudent),
		[data, currentStudent],
	);

	return (
		<SelectField
			formState={formState}
			name="studentID"
			isLoading={loading}
			options={options}
			display={displayStudent}
			blankValue={null}
		/>
	);
}

function displayStudent({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <StudentLink instance={{ id }}>{formattedValue}</StudentLink>;
}
