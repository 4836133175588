import { NullPartial } from "@hex-insights/core";
import {
	DiscountCreateInput,
	DiscountDetailQuery,
	DiscountInServiceSubscriptionCreateInput,
	DiscountUpdateInput,
} from "../../GraphQL";
import { DiscountFormValues } from "../ModelFormValues";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the Discount model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(formValues: DiscountFormValues.Create): DiscountCreateInput {
	const input: NullPartial<DiscountCreateInput> = { ...formValues };
	return input as DiscountCreateInput;
}

export function toGQLCreateInServiceSubscriptionInput(
	formValues: DiscountFormValues.CreateInServiceSubscription,
): DiscountInServiceSubscriptionCreateInput {
	const input: NullPartial<DiscountInServiceSubscriptionCreateInput> = { ...formValues };
	return input as DiscountInServiceSubscriptionCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the Discount model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<DiscountFormValues.Detail>,
	_initialFormValues: DiscountFormValues.Detail,
): DiscountUpdateInput {
	const input: DiscountUpdateInput = { ...formValues };
	return input;
}

/**
 * Converts a Discount instance to its corresponding detail form values.
 *
 * @param discount The instance to convert.
 */
export function toFormValues(discount: DiscountDetailQuery["discount"]): DiscountFormValues.Detail {
	return {
		amount: discount.amount,
		description: discount.description,
		serviceSubscriptionID: discount.serviceSubscription?.id ?? null,
	};
}
