import { IndexForms } from "@hex-insights/app-modules";
import { TypeFilterObject } from "@hex-insights/core";

export type StandardFilterFormValues = {}; // TODO define quick filters

export type FormValues = StandardFilterFormValues & IndexForms.FilterFormValues;

export const initialStandardFilters: StandardFilterFormValues = {};

export const initial: FormValues = {
	...initialStandardFilters,
	...IndexForms.initialFilterFormValues,
};

export function getInitial(): FormValues {
	return initial;
}

export const quickFiltersTypeFilterObject: TypeFilterObject<StandardFilterFormValues> = {};

export const typeFilterObject: TypeFilterObject<FormValues> = {
	...quickFiltersTypeFilterObject,
	...IndexForms.filterFormValuesFilterObject,
};

export const advancedFilterInformation: IndexForms.AdvancedFilterInformation = {
	fieldOptions: [{ value: "dayOfWeek" }, { value: "startTimeMinutes" }, { value: "endTimeMinutes" }],
	fieldComparisonOptions: {
		dayOfWeek: IndexForms.numberComparisonOptions,
		startTimeMinutes: IndexForms.numberComparisonOptions,
		endTimeMinutes: IndexForms.numberComparisonOptions,
	},
	fieldComparisonTranslations: {
		dayOfWeek: IndexForms.numberComparisonTranslations,
		startTimeMinutes: IndexForms.numberComparisonTranslations,
		endTimeMinutes: IndexForms.numberComparisonTranslations,
	},
};
