import { newPageInfo } from "@hex-insights/router";
import { studentHubDetailPageInfo, StudentHubDetailPageRouteParams } from "../pageinfo";

export type StudentHubDetailFamilyAndHealthPageRouteParams = StudentHubDetailPageRouteParams;

export const studentHubDetailFamilyAndHealthPageInfo = newPageInfo({
	title: "Family & Health",
	description: "Family and health page for a student.",
	exact: true,
	to: (id: Parameters<typeof studentHubDetailPageInfo.to>[0]) => studentHubDetailPageInfo.to(id) + "/family-and-health",
	path: studentHubDetailPageInfo.path + "/family-and-health",
	parent: studentHubDetailPageInfo,
});
