import { CourseSection, LearningObjective, LessonPlan } from "../../GraphQL";

export type Base = {
	courseDate: LessonPlan["courseDate"] | null;
	lessonDescription: LessonPlan["lessonDescription"];
	requiredResourcesAndMaterials: LessonPlan["requiredResourcesAndMaterials"];
	stationOneDescription: LessonPlan["stationOneDescription"];
	stationTwoDescription: LessonPlan["stationTwoDescription"];
	stationThreeDescription: LessonPlan["stationThreeDescription"];
	differentiationDescription: LessonPlan["differentiationDescription"];
	assessmentDescription: LessonPlan["assessmentDescription"];
	notes: LessonPlan["notes"];
	courseSectionID: CourseSection["id"] | null;
	learningObjectiveIDs: LearningObjective["id"][];
};

export type Create = Base;

export type Detail = Base;

export const initialCreate: Create = {
	courseDate: null,
	lessonDescription: "",
	requiredResourcesAndMaterials: "",
	stationOneDescription: "",
	stationTwoDescription: "",
	stationThreeDescription: "",
	differentiationDescription: "",
	assessmentDescription: "",
	notes: "",
	courseSectionID: null,
	learningObjectiveIDs: [],
};
