import { DateTimeField } from "@hex-insights/forms";
import { MealMenuFormValues } from "../../../../Utilities";
import { TextAreaField } from "../../../TextAreaField";
import { BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the MealMenu model.
 */
type FieldProps<K extends keyof MealMenuFormValues.Base = keyof MealMenuFormValues.Base> = BaseFieldProps<
	Pick<MealMenuFormValues.Base, K>
>;

/**
 * Renders a field component for the `menuDate` field of the MealMenu model.
 */
export function MenuDate({ formState }: FieldProps<"menuDate">) {
	return <DateTimeField formState={formState} name="menuDate" precision="day" />;
}

/**
 * Renders a field component for the `breakfast` field of the MealMenu model.
 */
export function Breakfast({ formState }: FieldProps<"breakfast">) {
	return <TextAreaField formState={formState} name="breakfast" />;
}

/**
 * Renders a field component for the `lunch` field of the MealMenu model.
 */
export function Lunch({ formState }: FieldProps<"lunch">) {
	return <TextAreaField formState={formState} name="lunch" />;
}

/**
 * Renders a field component for the `snack` field of the MealMenu model.
 */
export function Snack({ formState }: FieldProps<"snack">) {
	return <TextAreaField formState={formState} name="snack" />;
}

/**
 * Renders a field component for the `vegetarianBreakfast` field of the MealMenu model.
 */
export function VegetarianBreakfast({ formState }: FieldProps<"vegetarianBreakfast">) {
	return <TextAreaField formState={formState} name="vegetarianBreakfast" optional />;
}

/**
 * Renders a field component for the `vegetarianLunch` field of the MealMenu model.
 */
export function VegetarianLunch({ formState }: FieldProps<"vegetarianLunch">) {
	return <TextAreaField formState={formState} name="vegetarianLunch" optional />;
}

/**
 * Renders a field component for the `vegetarianSnack` field of the MealMenu model.
 */
export function VegetarianSnack({ formState }: FieldProps<"vegetarianSnack">) {
	return <TextAreaField formState={formState} name="vegetarianSnack" optional />;
}
