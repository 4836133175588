import { IndexForms } from "@hex-insights/app-modules";
import { CaseStyle, convertCase } from "@hex-insights/core";
import { OrderDirection, RelationshipOrderField } from "../../GraphQL";

export type FormValues = IndexForms.OrderFormValues<RelationshipOrderField | null>;

export const initial: FormValues = {
	field: RelationshipOrderField.RelationshipType,
	direction: OrderDirection.Asc,
};

/**
 * Options for the "field" field in the order form of the Relationship model.
 */
export const fieldOptions = [
	{ label: "ID", value: null },
	{
		label: convertCase(RelationshipOrderField.RelationshipType.toLowerCase(), CaseStyle.Title),
		value: RelationshipOrderField.RelationshipType,
	},
	{
		label: convertCase(RelationshipOrderField.IsPrimary.toLowerCase(), CaseStyle.Title),
		value: RelationshipOrderField.IsPrimary,
	},
	{
		label: convertCase(RelationshipOrderField.IsFinancialContact.toLowerCase(), CaseStyle.Title),
		value: RelationshipOrderField.IsFinancialContact,
	},
	{
		label: convertCase(RelationshipOrderField.IsEmergencyContact.toLowerCase(), CaseStyle.Title),
		value: RelationshipOrderField.IsEmergencyContact,
	},
	{
		label: convertCase(RelationshipOrderField.IsAuthorizedForPickup.toLowerCase(), CaseStyle.Title),
		value: RelationshipOrderField.IsAuthorizedForPickup,
	},
	{
		label: convertCase(RelationshipOrderField.LivesWithStudent.toLowerCase(), CaseStyle.Title),
		value: RelationshipOrderField.LivesWithStudent,
	},
];

/**
 * Array of values for the "field" field in the order form of the Relationship model.
 */
export const fieldValues = fieldOptions.map((e) => e.value);

/**
 * Mapping from order fields of the Relationship model to their corresponding order type.
 */
export const fieldTypeMapping = {
	[RelationshipOrderField.RelationshipType]: IndexForms.OrderFieldType.String,
	[RelationshipOrderField.IsPrimary]: IndexForms.OrderFieldType.Boolean,
	[RelationshipOrderField.IsFinancialContact]: IndexForms.OrderFieldType.Boolean,
	[RelationshipOrderField.IsEmergencyContact]: IndexForms.OrderFieldType.Boolean,
	[RelationshipOrderField.IsAuthorizedForPickup]: IndexForms.OrderFieldType.Boolean,
	[RelationshipOrderField.LivesWithStudent]: IndexForms.OrderFieldType.Boolean,
};

/**
 * Mapping from order fields of the Relationship model to their corresponding field name.
 */
export const orderFieldToModelField = {
	[RelationshipOrderField.RelationshipType]: "relationshipType",
	[RelationshipOrderField.IsPrimary]: "isPrimary",
	[RelationshipOrderField.IsFinancialContact]: "isFinancialContact",
	[RelationshipOrderField.IsEmergencyContact]: "isEmergencyContact",
	[RelationshipOrderField.IsAuthorizedForPickup]: "isAuthorizedForPickup",
	[RelationshipOrderField.LivesWithStudent]: "livesWithStudent",
};

/**
 * Mapping from field names of the Relationship model to their corresponding order field.
 */
export const modelFieldToOrderField = {
	relationshipType: RelationshipOrderField.RelationshipType,
	isPrimary: RelationshipOrderField.IsPrimary,
	isFinancialContact: RelationshipOrderField.IsFinancialContact,
	isEmergencyContact: RelationshipOrderField.IsEmergencyContact,
	isAuthorizedForPickup: RelationshipOrderField.IsAuthorizedForPickup,
	livesWithStudent: RelationshipOrderField.LivesWithStudent,
};
