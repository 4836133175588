import { IndexForms } from "@hex-insights/app-modules";
import { asArray, TypeFilterObject, valuesTypeFilter } from "@hex-insights/core";
import { EnrollmentInvitationResponse } from "../../GraphQL";
import { EnrollmentInvitationFormValues } from "../ModelFormValues";

export type QuickFilterFormValues = {
	responseIn: EnrollmentInvitationResponse[];
};

export type FormValues = QuickFilterFormValues & IndexForms.SearchFormValues & IndexForms.FilterFormValues;

export const initialQuickFilters: QuickFilterFormValues = {
	responseIn: [EnrollmentInvitationResponse.No, EnrollmentInvitationResponse.Unknown],
};

export const initial: FormValues = {
	...initialQuickFilters,
	...IndexForms.initialSearchFormValues,
	...IndexForms.initialFilterFormValues,
};

export function getInitial(): FormValues {
	return initial;
}

const responseTypeFilter = valuesTypeFilter(
	[EnrollmentInvitationResponse.Yes, EnrollmentInvitationResponse.No, EnrollmentInvitationResponse.Unknown],
	null,
);

export const quickFiltersTypeFilterObject: TypeFilterObject<QuickFilterFormValues> = {
	responseIn: (value: any) => asArray(value).filter((e) => responseTypeFilter(e) !== null),
};

export const typeFilterObject: TypeFilterObject<FormValues> = {
	...quickFiltersTypeFilterObject,
	...IndexForms.searchFormValuesFilterObject,
	...IndexForms.filterFormValuesFilterObject,
};

export const advancedFilterInformation: IndexForms.AdvancedFilterInformation = {
	fieldOptions: [{ value: "createdAt" }, { value: "response" }],
	fieldComparisonOptions: {
		createdAt: IndexForms.timeComparisonOptions,
		response: IndexForms.enumComparisonOptions,
	},
	fieldComparisonTranslations: {
		createdAt: IndexForms.timeComparisonTranslations,
		response: IndexForms.getRadioComparisonTranslations(EnrollmentInvitationFormValues.responseOptions),
	},
};
