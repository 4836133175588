import { newPageInfo } from "@hex-insights/router";
import { CourseSection } from "@hex-insights/verita.shared";
import { reportCardsTermPageInfo, ReportCardsTermPageRouteParams } from "../pageinfo";

export type ReportCardsTermCourseSectionPageRouteParams = ReportCardsTermPageRouteParams & {
	courseSectionID: string;
};

type CourseSectionForTitle = Pick<CourseSection, "name">;

export const reportCardsTermCourseSectionPageInfo = newPageInfo({
	name: "Report Cards Term Course Section View",
	title: (e: CourseSectionForTitle) => e.name,
	description: "Course section report cards page.",
	exact: false,
	to: (termID: Parameters<typeof reportCardsTermPageInfo.to>[0], courseSectionID: CourseSection["id"]) =>
		reportCardsTermPageInfo.to(termID) + `/course-section/${courseSectionID}`,
	path: reportCardsTermPageInfo.path + "/course-section/:courseSectionID(\\d+)",
	parent: reportCardsTermPageInfo,
});
