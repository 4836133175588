import React from "react";
import { FieldDisplayArgs, SelectField, TextField } from "@hex-insights/forms";
import { EmailAddressFormValues, PersonSelect, usePersonSelectLazyQuery, validateEmail } from "../../../../Utilities";
import { PersonLink } from "../../../Links";
import { BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the EmailAddress model.
 */
type FieldProps<K extends keyof EmailAddressFormValues.Base = keyof EmailAddressFormValues.Base> = BaseFieldProps<
	Pick<EmailAddressFormValues.Base, K>
>;

/**
 * Renders a field component for the `email` field of the EmailAddress model.
 */
export function Email({ formState }: FieldProps<"email">) {
	return <TextField formState={formState} name="email" immediateValidation={validateEmail} />;
}

export type PersonProps = FieldProps<"personID"> & {
	currentPerson?: PersonSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `person` edge of the EmailAddress model.
 */
export function Person({ formState, currentPerson }: PersonProps) {
	const [loadOptions, { loading, data }] = usePersonSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.personID) {
			loadOptions();
		}
	}, [formState.formEditing.personID, loadOptions]);
	const options = React.useMemo(
		() => PersonSelect.toOptions(data?.personConnection.edges, currentPerson),
		[data, currentPerson],
	);

	return (
		<SelectField
			formState={formState}
			name="personID"
			isLoading={loading}
			options={options}
			display={displayPerson}
			blankValue={null}
		/>
	);
}

function displayPerson({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <PersonLink instance={{ id }}>{formattedValue}</PersonLink>;
}
