import React from "react";
import {
	addTimeToDate,
	Button,
	Conditional,
	Else,
	If,
	isSameTime,
	Row,
	stringToLocalDate,
	toLocalDateString,
	useSync,
	useUpdatingRef,
} from "@hex-insights/core";
import { everyFieldEditing } from "@hex-insights/forms";
import { useActivePageRegistration, useHistory, useRouteParams } from "@hex-insights/router";
import {
	CourseSection,
	getWeekDateRangeForCourseSection,
	LessonPlan,
	LessonPlanFormConversion,
	LessonPlanFormState,
	LessonPlanFormValues,
	LessonPlanMutation,
	Tile,
	useCourseSectionDetailQuery,
	useLessonPlanDetailQuery,
} from "@hex-insights/verita.shared";
import { lessonPlansWeekIndexPageInfo } from "../../IndexPage/pageinfo";
import { usePageWeekLessonPlanIndexQuery } from "../utils";
import { LessonPlanFormControlledDetailProps, LessonPlanFormCreate, LessonPlanFormDetail } from "./Form";
import { lessonPlansWeekCourseSectionDayPageInfo, LessonPlansWeekCourseSectionDayPageRouteParams } from "./pageinfo";
import styles from "./styles.module.css";

export type LessonPlansCourseSectionWeekDayPageProps = {
	onNextReady: (nextTo: string, nextText: string) => void;
};

export function LessonPlansCourseSectionWeekDayPage({ onNextReady }: LessonPlansCourseSectionWeekDayPageProps) {
	const {
		courseSectionID,
		date: weekStartDate,
		dow,
	} = useRouteParams<LessonPlansWeekCourseSectionDayPageRouteParams>();
	useActivePageRegistration(
		lessonPlansWeekCourseSectionDayPageInfo,
		lessonPlansWeekCourseSectionDayPageInfo.title(dow),
	);

	const date = addTimeToDate(weekStartDate, [Number(dow) - 1, "days"]); // minus one because weekStartDate is monday
	const dateISO = toLocalDateString(date);

	const { data: indexData } = usePageWeekLessonPlanIndexQuery();
	const hasIndexData = !!indexData;
	const lessonPlan =
		indexData?.lessonPlanConnection.edges.find((e) => isSameTime(e.node.courseDate, dateISO, "day"))?.node ?? null;
	const hasLessonPlan = lessonPlan !== null;

	const [startedInCreate, setStartedInCreate] = React.useState(false);
	React.useEffect(() => {
		if (hasIndexData && !hasLessonPlan) {
			setStartedInCreate(true);
		}
	}, [hasIndexData, hasLessonPlan]);
	const nextDetailsRef = useUpdatingRef(useNextToAndText());
	React.useEffect(() => {
		if (startedInCreate && hasLessonPlan) onNextReady(nextDetailsRef.current.nextTo, nextDetailsRef.current.nextText);
	}, [startedInCreate, hasLessonPlan, onNextReady, nextDetailsRef]);

	return (
		<Tile style={{ minWidth: "var(--general__field---width)", height: "100%", flexShrink: 0, position: "relative" }}>
			<Tile.Body style={{ height: "100%", overflowY: "scroll" }}>
				<Conditional>
					<If condition={!hasIndexData}>Loading...</If>
					<If condition={hasLessonPlan}>
						<EditContent id={lessonPlan?.id ?? ""} wasJustCreated={startedInCreate} />
					</If>
					<Else>
						<CreateContent courseSectionID={courseSectionID} date={dateISO} />
					</Else>
				</Conditional>
			</Tile.Body>
		</Tile>
	);
}

type EditContentProps = {
	id: LessonPlan["id"];
	wasJustCreated: boolean;
};

function EditContent({ id, wasJustCreated }: EditContentProps) {
	const { loading, data, error } = useLessonPlanDetailQuery({ variables: { id } });
	const lessonPlan = data?.lessonPlan;

	const update = LessonPlanMutation.useUpdate(id);
	const { syncID, synchronize } = useSync();
	const applyUpdate = React.useCallback(
		async (changedFormValues: Partial<LessonPlanFormValues.Detail>, initialFormValues: LessonPlanFormValues.Detail) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	const [hasBeenSubmitted, setHasBeenSubmitted] = React.useState(false);

	const onSubmit = React.useCallback(() => {
		setHasBeenSubmitted(true);
		synchronize();
	}, [synchronize]);

	return (
		<Conditional>
			<If condition={loading}>Loading...</If>
			<If condition={error !== undefined}>Error loading Lesson Plan</If>
			<Else>
				{!!lessonPlan && (
					<LessonPlanDetailFormInTile
						key={syncID}
						lessonPlan={lessonPlan}
						applyUpdate={applyUpdate}
						onSuccess={onSubmit}
						hasBeenSubmitted={hasBeenSubmitted || wasJustCreated}
					/>
				)}
			</Else>
		</Conditional>
	);
}

type LessonPlanDetailFormInTileProps = LessonPlanFormControlledDetailProps & {
	hasBeenSubmitted: boolean;
};

function LessonPlanDetailFormInTile({
	lessonPlan,
	applyUpdate,
	onSuccess,
	hasBeenSubmitted,
}: LessonPlanDetailFormInTileProps) {
	const initialFormValues = React.useMemo(() => LessonPlanFormConversion.toFormValues(lessonPlan), [lessonPlan]);
	const formState = LessonPlanFormState.useDetailFormState({ initialFormValues });

	const everyEditing = React.useMemo(
		() =>
			everyFieldEditing({
				formEditing: formState.formEditing,
				formSubFormStates: formState.formSubFormStates,
			}),
		[formState.formEditing, formState.formSubFormStates],
	);

	const history = useHistory();
	const { nextTo, nextText } = useNextToAndText();

	return (
		<React.Fragment>
			<div style={{ position: "absolute", top: "3.1rem", right: "1.5rem", zIndex: 10 }}>
				<Conditional>
					<If condition={everyEditing}>
						<Button
							variant="tertiary"
							size="small"
							onClick={() => formState.setFormEditing(false)}
							style={{ backgroundColor: "#fff" }}
						>
							Stop Editing
						</Button>
					</If>
					<Else>
						<Button
							variant="tertiary"
							size="small"
							onClick={() => formState.setFormEditing(true)}
							style={{ backgroundColor: "#fff" }}
						>
							Edit
						</Button>
					</Else>
				</Conditional>
			</div>

			<LessonPlanFormDetail
				formState={formState}
				lessonPlan={lessonPlan}
				applyUpdate={applyUpdate}
				onSuccess={onSuccess}
				noChangesDialog
			/>

			<If condition={hasBeenSubmitted}>
				<Row className={styles["floating-submit-button-container"]} style={{ zIndex: 11 }}>
					<Button variant="success" onClick={() => history.push(nextTo)}>
						{nextText}
					</Button>
				</Row>
			</If>
		</React.Fragment>
	);
}

function useNextToAndText() {
	const {
		courseSectionID,
		date: weekStartDate,
		dow,
	} = useRouteParams<LessonPlansWeekCourseSectionDayPageRouteParams>();
	const { data } = useCourseSectionDetailQuery({ variables: { id: courseSectionID } });

	const { dateRange } = getWeekDateRangeForCourseSection(
		data?.courseSection.courseSectionPeriods ?? [],
		stringToLocalDate(weekStartDate, "day"),
	);

	const courseSectionDOWs = dateRange.map((e) => stringToLocalDate(e, "day").getDay());
	const currentDOWIndex = courseSectionDOWs.indexOf(Number(dow));
	const isLastDay = currentDOWIndex === -1 || currentDOWIndex === courseSectionDOWs.length - 1;

	const nextTo = isLastDay
		? lessonPlansWeekIndexPageInfo.to(weekStartDate)
		: lessonPlansWeekCourseSectionDayPageInfo.to(
				weekStartDate,
				courseSectionID,
				courseSectionDOWs[currentDOWIndex + 1],
		  );
	const nextText = isLastDay ? "Back to Overview" : "Move to Next Day";

	return { nextTo, nextText };
}

type CreateContentProps = {
	courseSectionID: CourseSection["id"];
	date: string;
};

function CreateContent({ courseSectionID, date }: CreateContentProps) {
	const create = LessonPlanMutation.useCreate();
	const createdIDRef = React.useRef<string | null>(null);

	const applyCreate = React.useCallback(
		async (formValues: LessonPlanFormValues.Create) => {
			const { data, errors } = await create({ ...formValues, courseSectionID, courseDate: date });
			createdIDRef.current = data?.id ?? null;
			return errors;
		},
		[create, courseSectionID, date],
	);

	const onSuccess = React.useCallback(() => {}, []);

	const formState = LessonPlanFormState.useCreateFormState({ courseSectionID });

	return (
		<LessonPlanFormCreate
			formState={formState}
			applyCreate={applyCreate}
			onSuccess={onSuccess}
			formNameSuffix={`.${courseSectionID}.${date}`}
		/>
	);
}
