import { NullPartial } from "@hex-insights/core";
import {
	ContractInstallmentCreateInput,
	ContractInstallmentDetailQuery,
	ContractInstallmentInContractCreateInput,
	ContractInstallmentUpdateInput,
} from "../../GraphQL";
import { ContractInstallmentFormValues } from "../ModelFormValues";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the ContractInstallment model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(formValues: ContractInstallmentFormValues.Create): ContractInstallmentCreateInput {
	const input: NullPartial<ContractInstallmentCreateInput> = { ...formValues };
	return input as ContractInstallmentCreateInput;
}

export function toGQLCreateInContractInput(
	formValues: ContractInstallmentFormValues.CreateInContract,
): ContractInstallmentInContractCreateInput {
	const input: NullPartial<ContractInstallmentInContractCreateInput> = { ...formValues };
	return input as ContractInstallmentInContractCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the ContractInstallment model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<ContractInstallmentFormValues.Detail>,
	_initialFormValues: ContractInstallmentFormValues.Detail,
): ContractInstallmentUpdateInput {
	const input: ContractInstallmentUpdateInput = { ...formValues };
	return input;
}

/**
 * Converts a ContractInstallment instance to its corresponding detail form values.
 *
 * @param contractInstallment The instance to convert.
 */
export function toFormValues(
	contractInstallment: ContractInstallmentDetailQuery["contractInstallment"],
): ContractInstallmentFormValues.Detail {
	return {
		installmentDate: contractInstallment.installmentDate,
		portion: contractInstallment.portion,
		contractID: contractInstallment.contract?.id ?? null,
		invoiceID: contractInstallment.invoice?.id ?? null,
	};
}
