import React from "react";
import {
	ClassNameProps,
	Column,
	compareObjects,
	compareWithQueryDesc,
	Conditional,
	Else,
	Grid,
	Heading,
	If,
	makeClassName,
	matchQuery,
	Modal,
	ModalProps,
	Row,
	StyleProps,
} from "@hex-insights/core";
import { TextField, useFormState, ValidationDisplayPolicy } from "@hex-insights/forms";
import {
	DocumentResourceIndexQuery,
	DocumentResourceOrderField,
	ExpandingTile,
	HorizontalScrollingContainer,
	OrderDirection,
	useDocumentResourceIndexQuery,
} from "@hex-insights/verita.shared";
import { DocumentResourceDisplay } from "./DocumentResourceDisplay";
import styles from "./styles.module.css";

export type ResourcesTileProps = {
	isLoading?: boolean;
	edges: DocumentResourceIndexQuery["documentResourceConnection"]["edges"] | undefined;
	backgroundColor?: string;
} & Partial<ClassNameProps & StyleProps>;

export function ResourcesTile({
	isLoading = false,
	edges,
	backgroundColor = "#fff",
	className,
	style,
}: ResourcesTileProps) {
	const { isModalOpen, toggleIsModalOpen } = Modal.useToggle(false);

	return (
		<ExpandingTile
			onExpandClick={toggleIsModalOpen}
			backgroundColor={backgroundColor}
			className={makeClassName(className, styles["document-resources-tile"])}
			style={style}
		>
			<ExpandingTile.Body className={styles["document-resources-tile__body"]}>
				<Conditional>
					<If condition={isLoading}>Loading...</If>
					<Else>
						<Conditional>
							<If condition={(edges?.length ?? 0) === 0}>No resources to show</If>
							<Else>
								<HorizontalScrollingContainer backgroundColor={backgroundColor}>
									<Row justify="spaced-start">
										{edges?.map((e) => (
											<DocumentResourceDisplay key={e.node.id} documentResource={e.node} />
										))}
									</Row>
								</HorizontalScrollingContainer>
							</Else>
						</Conditional>

						<Modal.If condition={isModalOpen}>
							<ResourcesModal onClose={toggleIsModalOpen} />
						</Modal.If>
					</Else>
				</Conditional>
			</ExpandingTile.Body>
		</ExpandingTile>
	);
}

const initialModalFormValues = { search: "" };

type ResourcesModalProps = Pick<ModalProps, "ifRef" | "onClose">;

function ResourcesModal({ ifRef, onClose }: ResourcesModalProps) {
	const { data } = useDocumentResourceIndexQuery({
		variables: {
			order: { field: DocumentResourceOrderField.Name, direction: OrderDirection.Asc },
		},
	});

	const formState = useFormState({
		initialFormValues: initialModalFormValues,
		validationDisplayPolicy: ValidationDisplayPolicy.none,
	});
	const { search } = formState.formValues;

	const filteredEdges = React.useMemo(() => {
		if (!data) {
			return [];
		}

		const getValue = (e: DocumentResourceIndexQuery["documentResourceConnection"]["edges"][0]) =>
			e.node.name + " " + e.node.description;
		const filter = matchQuery(search);
		const compare = compareObjects(getValue, compareWithQueryDesc(search));
		return data.documentResourceConnection.edges.filter((e) => filter(getValue(e))).sort(compare);
	}, [data, search]);

	return (
		<Modal ifRef={ifRef} onClose={onClose} style={{ height: "90vh", maxHeight: "none" }}>
			<Modal.Header>
				<Heading level={2} noMargin>
					Resources
				</Heading>
			</Modal.Header>
			<Modal.Body style={{ height: "100%", overflow: "hidden" }}>
				<Column justify="spaced-start" verticalSpacing="1.5rem" style={{ height: "100%" }}>
					<div>
						<TextField formState={formState} name="search" autoFocus />
					</div>

					<Grid columns={5} gap="1rem" style={{ maxHeight: "100%", overflow: "scroll" }}>
						{filteredEdges.map((e) => (
							<DocumentResourceDisplay key={e.node.id} documentResource={e.node} />
						))}
					</Grid>
				</Column>
			</Modal.Body>
		</Modal>
	);
}
