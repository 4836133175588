import { LearningObjective } from "../../../GraphQL";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the category field of the LearningObjective model.
 */
export function category(value: LearningObjective["category"]): string {
	return toString(value);
}

/**
 * Formats the description field of the LearningObjective model.
 */
export function description(value: LearningObjective["description"]): string {
	return toString(value);
}

/**
 * Formats the isActive field of the LearningObjective model.
 */
export function isActive(value: LearningObjective["isActive"]): string {
	return toString(value);
}
