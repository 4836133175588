import { Models } from "@hex-insights/app-modules";
import { DistributiveOmit } from "@hex-insights/core";
import { Bus, BusFormat, BusNameFragment } from "../../../../Utilities";

type ModelForLink = Pick<Bus, "id"> & BusNameFragment;

export type BusLinkProps = DistributiveOmit<Models.LinkProps<ModelForLink>, "pageInfoKey" | "format">;

/**
 * Renders a link to the given Bus instance.
 */
export function BusLink(props: BusLinkProps) {
	return <Models.Link<ModelForLink> pageInfoKey="Bus" format={BusFormat.name} {...props} />;
}
