import { HomeRoom } from "../../../GraphQL";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the name field of the HomeRoom model.
 */
export function name(value: HomeRoom["name"]): string {
	return toString(value);
}

/**
 * Formats the description field of the HomeRoom model.
 */
export function description(value: HomeRoom["description"]): string {
	return toString(value);
}

/**
 * Formats the studentCapacity field of the HomeRoom model.
 */
export function studentCapacity(value: HomeRoom["studentCapacity"] | null): string {
	return toString(value);
}
