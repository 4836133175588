import { newPageInfo } from "@hex-insights/router";
import { CourseSection, CourseSectionPeriod } from "@hex-insights/verita.shared";
import { scheduleDayPageInfo, ScheduleDayPageRouteParams } from "../pageinfo";

export type ScheduleDayCoursePeriodPageRouteParams = ScheduleDayPageRouteParams & {
	courseSectionPeriodID: string;
};

type CourseSectionForTitle = Pick<CourseSection, "name">;

export const scheduleDayCoursePeriodPageInfo = newPageInfo({
	name: "Teacher Courses Schedule Course View",
	title: (e: CourseSectionForTitle) => e.name,
	description: "View course for a day.",
	exact: false,
	to: (date: Parameters<typeof scheduleDayPageInfo.to>[0], courseSectionPeriodID: CourseSectionPeriod["id"]) =>
		scheduleDayPageInfo.to(date) + `/${courseSectionPeriodID}`,
	path: scheduleDayPageInfo.path + "/:courseSectionPeriodID(\\d+)",
	parent: scheduleDayPageInfo,
});
