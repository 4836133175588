import React from "react";
import { makeClassName } from "@hex-insights/core";
import { Tile, TileProps } from "../Tile";
import styles from "./styles.module.css";

type ProgressBarTileProps = TileProps & {
	progress: number;
	refreshIntervalMS: number;
};

export function ProgressBarTile({ progress, refreshIntervalMS, className, style, ...props }: ProgressBarTileProps) {
	return (
		<Tile
			{...props}
			className={makeClassName(styles["progress-bar-tile"], className)}
			style={
				{
					...style,
					"--progress": `${progress * 100}%`,
					"--refresh-interval": `${refreshIntervalMS}ms`,
				} as React.CSSProperties
			}
		/>
	);
}
