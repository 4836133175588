import { Models } from "@hex-insights/app-modules";
import { DistributiveOmit } from "@hex-insights/core";
import { LearningObjective, LearningObjectiveFormat, LearningObjectiveNameFragment } from "../../../../Utilities";

type ModelForLink = Pick<LearningObjective, "id"> & LearningObjectiveNameFragment;

export type LearningObjectiveLinkProps = DistributiveOmit<Models.LinkProps<ModelForLink>, "pageInfoKey" | "format">;

/**
 * Renders a link to the given LearningObjective instance.
 */
export function LearningObjectiveLink(props: LearningObjectiveLinkProps) {
	return <Models.Link<ModelForLink> pageInfoKey="LearningObjective" format={LearningObjectiveFormat.name} {...props} />;
}
