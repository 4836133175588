import { Campus } from "../../../GraphQL";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the name field of the Campus model.
 */
export function name(value: Campus["name"]): string {
	return toString(value);
}

/**
 * Formats the addressLine1 field of the Campus model.
 */
export function addressLine1(value: Campus["addressLine1"]): string {
	return toString(value);
}

/**
 * Formats the addressLine2 field of the Campus model.
 */
export function addressLine2(value: Campus["addressLine2"]): string {
	return toString(value);
}

/**
 * Formats the postalCode field of the Campus model.
 */
export function postalCode(value: Campus["postalCode"]): string {
	return toString(value);
}

/**
 * Formats the city field of the Campus model.
 */
export function city(value: Campus["city"]): string {
	return toString(value);
}

/**
 * Formats the stateOrProvince field of the Campus model.
 */
export function stateOrProvince(value: Campus["stateOrProvince"]): string {
	return toString(value);
}

/**
 * Formats the country field of the Campus model.
 */
export function country(value: Campus["country"]): string {
	return toString(value);
}
