import React from "react";
import { makeClassName, Row, RowProps } from "@hex-insights/core";
import styles from "./styles.module.css";

export function StackedScrollRow({ className, children, ...props }: Omit<RowProps, "overflow">) {
	const numChildren = React.Children.toArray(children).length;

	return (
		<Row
			{...props}
			className={makeClassName(styles["stacked-scroll-row"], "no-scroll-bar", className)}
			overflow="scroll"
		>
			{React.Children.map(children, (e, i) =>
				React.isValidElement(e)
					? React.cloneElement(e as any, {
							style: { ...e.props.style, position: "sticky", right: 0, zIndex: numChildren - i },
					  })
					: e,
			)}
		</Row>
	);
}
