import React from "react";
import { ApplyCreateFunction, ApplyUpdateFunction, onCreateSubmit, onUpdateSubmit } from "@hex-insights/app-modules";
import { Button, Column, If, Row, StyleProps } from "@hex-insights/core";
import {
	anyFieldEditing,
	everyFieldEditing,
	Form,
	FormState,
	FormSubmitFunction,
	FormType,
	SubmissionStatus,
	SubmitButton,
} from "@hex-insights/forms";
import {
	Employee,
	Maybe,
	Note,
	NoteFormConversion,
	NoteFormState,
	NoteFormValues,
	Person,
	Student,
	StudentWithIDAndName,
	User,
	UserWithName,
} from "../../../../../Utilities";
import { TextAreaField, TextAreaFieldDisplay } from "../../../../TextAreaField";
import * as Field from "../Field";
import styles from "../styles.module.css";

export type ControlledCreateProps = {
	applyCreate: ApplyCreateFunction<NoteFormValues.Financial.Create>;
	onSuccess: () => void;
} & Partial<StyleProps>;

export function ControlledCreate(props: ControlledCreateProps) {
	const formState = NoteFormState.Financial.useCreateFormState();

	return <Create formState={formState} {...props} />;
}

export type CreateProps = ControlledCreateProps & {
	formState: FormState<NoteFormValues.Financial.Create>;
};

/**
 * Renders the create form of the Note model for a financial-type note using the given form state.
 */
export function Create({ formState, applyCreate, onSuccess, style }: CreateProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<NoteFormValues.Financial.Create>>(
		async ({ formValues }) => {
			return onCreateSubmit(formValues, applyCreate);
		},
		[applyCreate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	return (
		<Form
			name="note.create.financial"
			formState={formState}
			onSubmit={formState.onSubmitWrapper(onSubmit)}
			className={styles["note-form"]}
			style={style}
		>
			<Column justify="spaced-start" verticalSpacing="2rem">
				<Field.Students formState={formState} formType={FormType.Create} minItems={1} />
				<TextAreaField formState={formState} name="body" label="Note" minRows={3} />

				<Row justify="flex-end">
					<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
						Submit
					</SubmitButton>
				</Row>
			</Column>
		</Form>
	);
}

export type ControlledDetailProps = {
	note: NoteFormConversion.FinancialNoteForFormValues &
		Pick<Note, "id"> & {
			author: UserWithName | null;
			students: StudentWithIDAndName[];
		};
	applyUpdate: ApplyUpdateFunction<NoteFormValues.Financial.Detail>;
	onSuccess: () => void;
} & Partial<StyleProps>;

/**
 * Renders the detail form of the Note model using an internally managed form state.
 */
export function ControlledDetail(props: ControlledDetailProps) {
	const initialFormValues = React.useMemo(() => NoteFormConversion.noteToFinancialFormValues(props.note), [props.note]);
	const formState = NoteFormState.Financial.useDetailFormState({ initialFormValues });

	return <Detail formState={formState} {...props} />;
}

export type DetailProps = ControlledDetailProps & {
	formState: FormState<NoteFormValues.Financial.Detail>;
};

/**
 * Renders the detail form of the Note model using the given form state.
 */
export function Detail({ formState, note, applyUpdate, onSuccess, style }: DetailProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<NoteFormValues.Financial.Detail>>(
		async (formState) => {
			return onUpdateSubmit(formState, applyUpdate);
		},
		[applyUpdate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	const { anyEditing, everyEditing } = React.useMemo(() => {
		return {
			anyEditing: anyFieldEditing({
				formEditing: formState.formEditing,
				formSubFormStates: formState.formSubFormStates,
			}),
			everyEditing: everyFieldEditing({
				formEditing: formState.formEditing,
				formSubFormStates: formState.formSubFormStates,
			}),
		};
	}, [formState.formEditing, formState.formSubFormStates]);

	return (
		<Form
			name={`note.detail.${note.id}`}
			formState={formState}
			onSubmit={formState.onSubmitWrapper(onSubmit)}
			className={styles["note-form"]}
			style={style}
		>
			<Column justify="spaced-start" verticalSpacing="2rem">
				<Row justify="spaced-start" horizontalSpacing="0.75rem">
					<If condition={!everyEditing}>
						<Button variant="tertiary" size="small" onClick={() => formState.setFormEditing(true)}>
							Edit
						</Button>
					</If>
					<If condition={anyEditing}>
						<Button variant="tertiary" size="small" onClick={() => formState.setFormEditing(false)}>
							Stop Editing
						</Button>
					</If>
				</Row>

				<Field.Students
					formState={formState}
					formType={FormType.Update}
					id={note.id}
					currentStudents={note.students}
					minItems={1}
				/>
				<TextAreaField formState={formState} name="body" label="Note" minRows={3} />

				<div style={{ display: "none" }}>
					<Field.Author formState={formState} formType={FormType.Update} id={note.id} currentAuthor={note.author} />
					<Field.CreatedAt formState={formState} formType={FormType.Update} id={note.id} />
				</div>
			</Column>
		</Form>
	);
}

export type ReadOnlyProps = {
	note: Pick<Note, "body" | "createdAt"> & {
		author: Maybe<
			Pick<User, "id" | "email"> & {
				person?: Maybe<
					Pick<Person, "name"> & {
						employee?: Maybe<Pick<Employee, "id">>;
					}
				>;
			}
		>;
		students: (Pick<Student, "id"> & { person: Pick<Student["person"], "name"> })[];
	};
};

export function ReadOnly({ note }: ReadOnlyProps) {
	return (
		<Column justify="spaced-start" verticalSpacing="2rem" className={styles["note-form"]}>
			<TextAreaFieldDisplay value="" formattedValue={note.body} />
		</Column>
	);
}
