import React from "react";
import {
	Column,
	formatDateTime,
	If,
	Modal,
	ModalProps,
	relativeTime,
	RequiredKeys,
	Row,
	StyleProps,
} from "@hex-insights/core";
import { anyFieldChanged } from "@hex-insights/forms";
import { usePermissions } from "@hex-insights/permissioning";
import { NoteDeleteButton, NoteForm, PersonIcon } from "../../../../../Components";
import { useCurrentUser } from "../../../../../Contexts";
import {
	Maybe,
	Note,
	NoteFormConversion,
	NoteFormState,
	NoteFormValues,
	NoteMutation,
	NoteNoteType,
	permissions,
	Person,
	StudentNotesListQuery,
	User,
} from "../../../../../Utilities";
import styles from "./styles.module.css";

type NoteIndexItem = StudentNotesListQuery["noteConnection"]["edges"][0]["node"];

export type SimpleNoteDisplayProps = {
	note: NoteIndexItem;
} & Partial<StyleProps>;

export function SimpleNoteDisplay({ note, style }: SimpleNoteDisplayProps) {
	const { isModalOpen, toggleIsModalOpen } = Modal.useToggle(false);

	return (
		<div onClick={toggleIsModalOpen} style={style}>
			<Column className={styles["simple-note"]}>
				<div className={styles["simple-note__body"]}>
					{note.body}
					<div className={styles["simple-note__body__overflow-overlay"]}></div>
				</div>
				<Row justify="space-between">
					<span className={styles["simple-note__timestamp"]}>{relativeTime(note.createdAt)}</span>
					{note.author && note.author.person && <PersonIcon person={note.author.person} imageSize="1.5rem" />}
				</Row>
			</Column>

			<Modal.If condition={isModalOpen}>
				<SimpleNoteModal note={note} onClose={toggleIsModalOpen} />
			</Modal.If>
		</div>
	);
}

// TODO replace with NoteModal

type SimpleNoteModalProps = {
	note: NoteIndexItem;
} & RequiredKeys<Pick<ModalProps, "ifRef" | "onClose">, "onClose">;

function SimpleNoteModal({ note, ifRef, onClose }: SimpleNoteModalProps) {
	if (note.noteType === NoteNoteType.Financial) {
		return <FinancialNoteModal note={note} ifRef={ifRef} onClose={onClose} />;
	}
	if (note.noteType === NoteNoteType.General) {
		return <GeneralNoteModal note={note} ifRef={ifRef} onClose={onClose} />;
	}
	return null;
}

type FinancialNoteModalProps = {
	note: NoteIndexItem;
} & RequiredKeys<Pick<ModalProps, "ifRef" | "onClose">, "onClose">;

function FinancialNoteModal({ note, ifRef, onClose }: FinancialNoteModalProps) {
	const initialFormValues = NoteFormConversion.noteToFinancialFormValues(note);
	const formState = NoteFormState.Financial.useDetailFormState({ initialFormValues });

	const update = NoteMutation.useUpdate(note.id);
	const applyUpdate = React.useCallback(
		async (
			changedFormValues: Partial<NoteFormValues.Financial.Detail>,
			initialFormValues: NoteFormValues.Financial.Detail,
		) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	const onSuccess = React.useCallback(() => {
		onClose();
	}, [onClose]);

	return (
		<Modal ifRef={ifRef} onClose={onClose} confirmOnClose={anyFieldChanged(formState)}>
			<Modal.Body>
				<NoteForm.Financial.Detail
					formState={formState}
					note={note}
					applyUpdate={applyUpdate}
					onSuccess={onSuccess}
					style={{ "--text-field---width": "100%" } as React.CSSProperties}
				/>
			</Modal.Body>
			<NoteModalFooter note={note} onDelete={onClose} />
		</Modal>
	);
}

type GeneralNoteModalProps = {
	note: NoteIndexItem;
} & RequiredKeys<Pick<ModalProps, "ifRef" | "onClose">, "onClose">;

function GeneralNoteModal({ note, ifRef, onClose }: GeneralNoteModalProps) {
	const initialFormValues = NoteFormConversion.noteToGeneralFormValues(note);
	const formState = NoteFormState.General.useDetailFormState({ initialFormValues });

	const update = NoteMutation.useUpdate(note.id);
	const applyUpdate = React.useCallback(
		async (
			changedFormValues: Partial<NoteFormValues.General.Detail>,
			initialFormValues: NoteFormValues.General.Detail,
		) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	const onSuccess = React.useCallback(() => {
		onClose();
	}, [onClose]);

	return (
		<Modal ifRef={ifRef} onClose={onClose} confirmOnClose={anyFieldChanged(formState)}>
			<Modal.Body>
				<NoteForm.General.Detail
					formState={formState}
					note={note}
					applyUpdate={applyUpdate}
					onSuccess={onSuccess}
					style={{ "--text-field---width": "100%" } as React.CSSProperties}
				/>
			</Modal.Body>
			<NoteModalFooter note={note} onDelete={onClose} />
		</Modal>
	);
}

type NoteModalFooterProps = {
	note: Pick<Note, "id" | "createdAt"> & {
		author?: Maybe<
			Pick<User, "id"> & {
				person: Maybe<Pick<Person, "name" | "imageURL">>;
			}
		>;
	};
	onDelete: () => void;
};

function NoteModalFooter({ note, onDelete }: NoteModalFooterProps) {
	const { user } = useCurrentUser();
	const isAuthor = !!note.author && !!user && note.author.id === user.id;
	const { permissions: userPermissions } = usePermissions();

	return (
		<Modal.Footer>
			<Row justify="space-between" align="center">
				<span title={formatDateTime(note.createdAt, "HH:mm [on] D MMMM, YYYY")}>{relativeTime(note.createdAt)}</span>

				<If
					condition={
						userPermissions[permissions.Note.Delete.Admin] ||
						(isAuthor && userPermissions[permissions.Note.Delete.Basic])
					}
				>
					<NoteDeleteButton instanceID={note.id} onSuccess={onDelete} size="small" />
				</If>

				{note.author && note.author.person && (
					<Row justify="spaced-start">
						<PersonIcon person={note.author.person} imageSize="1.5rem" />
						{note.author.person.name}
					</Row>
				)}
			</Row>
		</Modal.Footer>
	);
}
