import React from "react";
import { Button, CollapsibleProps as CoreCollapsibleProps, makeClassName, Row, useToggle } from "@hex-insights/core";
import styles from "./styles.module.css";

// TODO add back to Core

export type CollapsibleProps = CoreCollapsibleProps & {
	displayedContent?: React.ReactNode;
};

export function Collapsible({
	displayedContent,
	isCollapsedInitially = true,
	isControlled = false,
	isCollapsed: isCollapsedProp,
	toggleIsCollapsed: toggleIsCollapsedProp,
	expandButtonContent = "Expand",
	collapseButtonContent = "Collapse",
	buttonVariant = "tertiary",
	buttonSize,
	buttonClassName,
	disabled = false,
	withAbsolutePosition = false,
	className,
	children,
}: CollapsibleProps) {
	const { isOn: isCollapsedState, toggle: toggleIsCollapsedState } = useToggle(isCollapsedInitially);

	const isCollapsed = isControlled ? isCollapsedProp! : isCollapsedState;
	const toggleIsCollapsed = isControlled ? toggleIsCollapsedProp! : toggleIsCollapsedState;

	const fullClassName = makeClassName(
		styles["collapsible"],
		isCollapsed ? styles["collapsible--collapsed"] : styles["collapsible--expanded"],
		withAbsolutePosition ? styles["collapsible--with-absolute-position"] : "",
		className,
	);
	const fullButtonClassName = makeClassName(styles["collapsible__button"], buttonClassName);

	return (
		<div className={fullClassName}>
			<Row justify="space-between" align="center">
				{displayedContent}
				<Button
					variant={buttonVariant}
					size={buttonSize}
					disabled={disabled}
					onClick={toggleIsCollapsed}
					className={fullButtonClassName}
				>
					{isCollapsed ? expandButtonContent : collapseButtonContent}
				</Button>
			</Row>

			<div className={styles["collapsible__content"]}>{children}</div>
		</div>
	);
}
