import { getArrayDiff, NullPartial, omitKeys } from "@hex-insights/core";
import { AddressCreateInput, AddressDetailQuery, AddressUpdateInput } from "../../GraphQL";
import { AddressFormValues } from "../ModelFormValues";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the Address model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(formValues: AddressFormValues.Create): AddressCreateInput {
	const input: NullPartial<AddressCreateInput> = { ...formValues };
	return input as AddressCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the Address model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<AddressFormValues.Detail>,
	initialFormValues: AddressFormValues.Detail,
): AddressUpdateInput {
	const inputFormValues = omitKeys(formValues, ["shortAddress", "fullAddress", "personIDs"]);
	const input: AddressUpdateInput = { ...inputFormValues };

	if (formValues.personIDs !== undefined) {
		const { added, removed } = getArrayDiff(initialFormValues.personIDs, formValues.personIDs);
		input.addPersonIDs = added;
		input.removePersonIDs = removed;
	}

	return input;
}

/**
 * Converts a Address instance to its corresponding detail form values.
 *
 * @param address The instance to convert.
 */
export function toFormValues(address: AddressDetailQuery["address"]): AddressFormValues.Detail {
	return {
		shortAddress: address.shortAddress,
		fullAddress: address.fullAddress,
		addressLine1: address.addressLine1,
		addressLine2: address.addressLine2,
		city: address.city,
		postalCode: address.postalCode,
		stateOrProvince: address.stateOrProvince,
		country: address.country,
		personIDs: address.people.map((e) => e.id),
	};
}
