import { IndexForms } from "@hex-insights/app-modules";
import { TypeFilterObject } from "@hex-insights/core";

export type StandardFilterFormValues = {}; // TODO define quick filters

export type FormValues = StandardFilterFormValues & IndexForms.FilterFormValues;

export const initialStandardFilters: StandardFilterFormValues = {};

export const initial: FormValues = {
	...initialStandardFilters,
	...IndexForms.initialFilterFormValues,
};

export function getInitial(): FormValues {
	return initial;
}

export const quickFiltersTypeFilterObject: TypeFilterObject<StandardFilterFormValues> = {};

export const typeFilterObject: TypeFilterObject<FormValues> = {
	...quickFiltersTypeFilterObject,
	...IndexForms.filterFormValuesFilterObject,
};

export const advancedFilterInformation: IndexForms.AdvancedFilterInformation = {
	fieldOptions: [
		{ value: "createdAt" },
		{ value: "updatedAt" },
		{ value: "expiresAt" },
		{ value: "longestInactiveSec" },
		{ value: "isVerified" },
		{ value: "isCurrent" },
	],
	fieldComparisonOptions: {
		createdAt: IndexForms.timeComparisonOptions,
		updatedAt: IndexForms.timeComparisonOptions,
		expiresAt: IndexForms.timeComparisonOptions,
		longestInactiveSec: IndexForms.numberComparisonOptions,
		isVerified: IndexForms.booleanComparisonOptions,
		isCurrent: IndexForms.booleanComparisonOptions,
	},
	fieldComparisonTranslations: {
		createdAt: IndexForms.timeComparisonTranslations,
		updatedAt: IndexForms.timeComparisonTranslations,
		expiresAt: IndexForms.timeComparisonTranslations,
		longestInactiveSec: IndexForms.numberComparisonTranslations,
		isVerified: IndexForms.booleanComparisonTranslations,
		isCurrent: IndexForms.booleanComparisonTranslations,
	},
};
