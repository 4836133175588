import { omitKeys } from "@hex-insights/core";
import { Discount, ServiceSubscription } from "../../GraphQL";

export type Base = {
	amount: Discount["amount"] | null;
	description: Discount["description"];
	serviceSubscriptionID: ServiceSubscription["id"] | null;
};

export type Create = Base;

export type Detail = Base;

export const initialCreate: Create = {
	amount: null,
	description: "",
	serviceSubscriptionID: null,
};

export type CreateInServiceSubscription = Omit<Create, "serviceSubscriptionID">;

export const initialCreateInServiceSubscription = omitKeys(initialCreate, ["serviceSubscriptionID"]);
