import React from "react";
import {
	Button,
	Column,
	Conditional,
	Else,
	formatDateTime,
	Icon,
	If,
	Modal,
	ModalProps,
	RequiredKeys,
	Row,
	Tooltip,
} from "@hex-insights/core";
import { anyFieldChanged } from "@hex-insights/forms";
import { SchoolAttendanceRecordForm } from "../../../../../Components";
import {
	SchoolAttendanceRecord,
	SchoolAttendanceRecordFormState,
	SchoolAttendanceRecordFormValues,
	SchoolAttendanceRecordMutation,
	Student,
	useSchoolAttendanceRecordDetailQuery,
} from "../../../../../Utilities";
import { AttendanceStatus, useCurrentSchoolAttendance } from "../../Utilities";

export function AttendanceController({ studentID }: { studentID: Student["id"] }) {
	const { schoolAttendanceStatus: status, schoolAttendanceRecord: record } = useCurrentSchoolAttendance(studentID);

	const { isModalOpen, toggleIsModalOpen } = Modal.useToggle(false);

	return (
		<React.Fragment>
			<Tooltip.Container side="bottom">
				<Button onClick={toggleIsModalOpen} style={{ padding: 0, border: "none" }}>
					<Conditional>
						<If condition={status === AttendanceStatus.Present}>
							<Row
								justify="spaced-start"
								horizontalSpacing="0.25rem"
								align="center"
								style={{ color: "var(--success-color)" }}
							>
								<Icon.Check size="1rem" /> Present
							</Row>
						</If>
						<If condition={status === AttendanceStatus.Absent}>
							<Row
								justify="spaced-start"
								horizontalSpacing="0.25rem"
								align="center"
								style={{ color: "var(--danger-color)" }}
							>
								<Icon.X size="1rem" /> Absent
							</Row>
						</If>
						<Else>No Attendance</Else>
					</Conditional>
				</Button>

				<Tooltip>
					<Tooltip.Body>
						<Column align="center">
							<span style={{ whiteSpace: "nowrap" }}>
								Attendance today ({formatDateTime(new Date(), "dddd, D MMM")})
							</span>
							<span>Click to edit</span>
						</Column>
					</Tooltip.Body>
				</Tooltip>
			</Tooltip.Container>

			{/* TODO support for transition with Conditional and Else */}
			<Modal.If condition={isModalOpen && record !== null}>
				<AttendanceDetailModal recordID={record?.id ?? ""} onClose={toggleIsModalOpen} />
			</Modal.If>
			<Modal.If condition={isModalOpen && record === null}>
				<AttendanceCreateModal studentID={studentID} onClose={toggleIsModalOpen} />
			</Modal.If>
		</React.Fragment>
	);
}

type AttendanceCreateModalProps = {
	studentID: Student["id"];
} & RequiredKeys<Pick<ModalProps, "ifRef" | "onClose">, "onClose">;

function AttendanceCreateModal({ studentID, ifRef, onClose }: AttendanceCreateModalProps) {
	const initialFormValues = React.useMemo(() => ({ checkInTime: new Date().toISOString(), studentID }), [studentID]);
	const formState = SchoolAttendanceRecordFormState.useCreateFormState(initialFormValues);

	const create = SchoolAttendanceRecordMutation.useCreate();
	const applyCreate = React.useCallback(
		async (formValues: SchoolAttendanceRecordFormValues.Create) => {
			const { errors } = await create(formValues);
			return errors;
		},
		[create],
	);

	return (
		<Modal
			ifRef={ifRef}
			onClose={onClose}
			confirmOnClose={anyFieldChanged(formState)}
			style={{ width: "fit-content", minWidth: "var(--general__field---width)" }}
		>
			<Modal.Body>
				<SchoolAttendanceRecordForm.Create formState={formState} applyCreate={applyCreate} onSuccess={onClose} />
			</Modal.Body>
		</Modal>
	);
}

type AttendanceDetailModalProps = {
	recordID: SchoolAttendanceRecord["id"];
} & RequiredKeys<Pick<ModalProps, "ifRef" | "onClose">, "onClose">;

function AttendanceDetailModal({ recordID, ifRef, onClose }: AttendanceDetailModalProps) {
	const { data } = useSchoolAttendanceRecordDetailQuery({ variables: { id: recordID } });

	const update = SchoolAttendanceRecordMutation.useUpdate(recordID);
	const applyUpdate = React.useCallback(
		async (
			changedFormValues: Partial<SchoolAttendanceRecordFormValues.Detail>,
			initialFormValues: SchoolAttendanceRecordFormValues.Detail,
		) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<Modal ifRef={ifRef} onClose={onClose} style={{ width: "fit-content", minWidth: "var(--general__field---width)" }}>
			<Modal.Body>
				{data ? (
					<SchoolAttendanceRecordForm.ControlledDetail
						schoolAttendanceRecord={data.schoolAttendanceRecord}
						applyUpdate={applyUpdate}
						onSuccess={onClose}
					/>
				) : (
					"Loading..."
				)}
			</Modal.Body>
		</Modal>
	);
}
