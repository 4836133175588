import { Models } from "@hex-insights/app-modules";
import { DistributiveOmit } from "@hex-insights/core";
import { Parent, ParentFormat, ParentNameFragment } from "../../../../Utilities";

type ModelForLink = Pick<Parent, "id"> & ParentNameFragment;

export type ParentLinkProps = DistributiveOmit<Models.LinkProps<ModelForLink>, "pageInfoKey" | "format">;

/**
 * Renders a link to the given Parent instance.
 */
export function ParentLink(props: ParentLinkProps) {
	return <Models.Link<ModelForLink> pageInfoKey="Parent" format={ParentFormat.name} {...props} />;
}
