import { IndexForms } from "@hex-insights/app-modules";
import { CaseStyle, convertCase } from "@hex-insights/core";
import { DiscountOrderField, OrderDirection } from "../../GraphQL";

export type FormValues = IndexForms.OrderFormValues<DiscountOrderField | null>;

export const initial: FormValues = {
	field: DiscountOrderField.Amount,
	direction: OrderDirection.Asc,
};

/**
 * Options for the "field" field in the order form of the Discount model.
 */
export const fieldOptions = [
	{ label: "ID", value: null },
	{ label: convertCase(DiscountOrderField.Amount.toLowerCase(), CaseStyle.Title), value: DiscountOrderField.Amount },
];

/**
 * Array of values for the "field" field in the order form of the Discount model.
 */
export const fieldValues = fieldOptions.map((e) => e.value);

/**
 * Mapping from order fields of the Discount model to their corresponding order type.
 */
export const fieldTypeMapping = {
	[DiscountOrderField.Amount]: IndexForms.OrderFieldType.Number,
};

/**
 * Mapping from order fields of the Discount model to their corresponding field name.
 */
export const orderFieldToModelField = {
	[DiscountOrderField.Amount]: "amount",
};

/**
 * Mapping from field names of the Discount model to their corresponding order field.
 */
export const modelFieldToOrderField = {
	amount: DiscountOrderField.Amount,
};
