import { formatDateTime } from "@hex-insights/core";
import { InternalLink } from "@hex-insights/router";
import {
	CoursesCalendarAgendaBubbleContainerProps,
	LargeDayCoursesCalendarAgenda as SharedLargeDayCoursesCalendarAgenda,
	SlimDayCoursesCalendarAgenda as SharedSlimDayCoursesCalendarAgenda,
} from "@hex-insights/verita.shared";
import { scheduleDayCoursePeriodPageInfo } from "../../Pages/Schedule/Day/CoursePeriod/pageinfo";
import { useCurrentTeacherScheduleForDateQuery } from "../../Utilities";

export type DayCoursesCalendarAgendaProps = {
	date: Date;
};

export function SlimDayCoursesCalendarAgenda({ date }: DayCoursesCalendarAgendaProps) {
	const { loading, data } = useCurrentTeacherScheduleForDateQuery(date);

	return (
		<SharedSlimDayCoursesCalendarAgenda
			isLoading={loading}
			edges={data?.courseSectionPeriodConnection.edges}
			date={date}
			BubbleContainer={BubbleContainer}
		/>
	);
}

export function LargeDayCoursesCalendarAgenda({ date }: DayCoursesCalendarAgendaProps) {
	const { loading, data } = useCurrentTeacherScheduleForDateQuery(date);

	return (
		<SharedLargeDayCoursesCalendarAgenda
			isLoading={loading}
			edges={data?.courseSectionPeriodConnection.edges}
			date={date}
			BubbleContainer={BubbleContainer}
		/>
	);
}

function BubbleContainer({
	courseSectionPeriod,
	date,
	className,
	style,
	children,
}: CoursesCalendarAgendaBubbleContainerProps) {
	return (
		<InternalLink
			to={scheduleDayCoursePeriodPageInfo.to(formatDateTime(date, "YYYY-MM-DD"), courseSectionPeriod.id)}
			className={className}
			style={style}
		>
			{children}
		</InternalLink>
	);
}
