import React from "react";
import { DateTimeField, FieldDisplayArgs, RadioButtonsInput, RadioField, SelectField } from "@hex-insights/forms";
import {
	CourseAttendanceRecordFormValues,
	CourseSectionPeriodSelect,
	StudentSelect,
	useCourseSectionPeriodSelectLazyQuery,
	useStudentSelectLazyQuery,
} from "../../../../Utilities";
import { CourseSectionPeriodLink, StudentLink } from "../../../Links";
import { TextAreaField } from "../../../TextAreaField";
import { BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the CourseAttendanceRecord model.
 */
type FieldProps<K extends keyof CourseAttendanceRecordFormValues.Base = keyof CourseAttendanceRecordFormValues.Base> =
	BaseFieldProps<Pick<CourseAttendanceRecordFormValues.Base, K>>;

/**
 * Renders a field component for the `attendanceDate` field of the CourseAttendanceRecord model.
 */
export function AttendanceDate({ formState }: FieldProps<"attendanceDate">) {
	return <DateTimeField formState={formState} name="attendanceDate" precision="day" />;
}

/**
 * Renders a field component for the `isPresent` field of the CourseAttendanceRecord model.
 */
export function IsPresent({ formState }: FieldProps<"isPresent">) {
	return (
		<RadioField
			formState={formState}
			name="isPresent"
			label="Status"
			options={CourseAttendanceRecordFormValues.isPresentOptions}
			Input={RadioButtonsInput}
		/>
	);
}

/**
 * Renders a field component for the `isLate` field of the CourseAttendanceRecord model.
 */
export function IsLate({ formState }: FieldProps<"isLate">) {
	return (
		<RadioField
			formState={formState}
			name="isLate"
			label="Timing"
			options={CourseAttendanceRecordFormValues.isLateOptions}
			Input={RadioButtonsInput}
		/>
	);
}

/**
 * Renders a field component for the `isExcused` field of the CourseAttendanceRecord model.
 */
export function IsExcused({ formState }: FieldProps<"isExcused">) {
	return (
		<RadioField
			formState={formState}
			name="isExcused"
			label="Absence/Late Excuse"
			options={CourseAttendanceRecordFormValues.isExcusedOptions}
			Input={RadioButtonsInput}
		/>
	);
}

/**
 * Renders a field component for the `notes` field of the CourseAttendanceRecord model.
 */
export function Notes({ formState }: FieldProps<"notes">) {
	return <TextAreaField formState={formState} name="notes" optional />;
}

export type CourseSectionPeriodProps = FieldProps<"courseSectionPeriodID"> & {
	currentCourseSectionPeriod?: CourseSectionPeriodSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `courseSectionPeriod` edge of the CourseAttendanceRecord model.
 */
export function CourseSectionPeriod({ formState, currentCourseSectionPeriod }: CourseSectionPeriodProps) {
	const [loadOptions, { loading, data }] = useCourseSectionPeriodSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.courseSectionPeriodID) {
			loadOptions();
		}
	}, [formState.formEditing.courseSectionPeriodID, loadOptions]);
	const options = React.useMemo(
		() => CourseSectionPeriodSelect.toOptions(data?.courseSectionPeriodConnection.edges, currentCourseSectionPeriod),
		[data, currentCourseSectionPeriod],
	);

	return (
		<SelectField
			formState={formState}
			name="courseSectionPeriodID"
			isLoading={loading}
			options={options}
			display={displayCourseSectionPeriod}
			blankValue={null}
		/>
	);
}

function displayCourseSectionPeriod({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <CourseSectionPeriodLink instance={{ id }}>{formattedValue}</CourseSectionPeriodLink>;
}

export type StudentProps = FieldProps<"studentID"> & {
	currentStudent?: StudentSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `student` edge of the CourseAttendanceRecord model.
 */
export function Student({ formState, currentStudent }: StudentProps) {
	const [loadOptions, { loading, data }] = useStudentSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.studentID) {
			loadOptions();
		}
	}, [formState.formEditing.studentID, loadOptions]);
	const options = React.useMemo(
		() => StudentSelect.toOptions(data?.studentConnection.edges, currentStudent),
		[data, currentStudent],
	);

	return (
		<SelectField
			formState={formState}
			name="studentID"
			isLoading={loading}
			options={options}
			display={displayStudent}
			blankValue={null}
		/>
	);
}

function displayStudent({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <StudentLink instance={{ id }}>{formattedValue}</StudentLink>;
}
