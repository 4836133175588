import React from "react";
import { ApolloCache, FetchResult, gql } from "@apollo/client";
import {
	graphTypeNames,
	Parent,
	ParentCreateInput,
	ParentCreateMutation,
	ParentDeleteMutation,
	ParentDetailDocument,
	ParentDetailQuery,
	ParentDetailQueryVariables,
	ParentUpdateInput,
	ParentUpdateMutation,
	ParentUpdateWithPersonMutation,
	Payment,
	Person,
	Relationship,
	useParentCreateMutation,
	useParentDeleteMutation,
	useParentUpdateMutation,
	useParentUpdateWithPersonMutation,
} from "../../GraphQL";
import { ParentFormConversion } from "../ModelFormConversion";
import { ParentFormValues } from "../ModelFormValues";
import * as PersonMutation from "./person";

/**
 * Returns a `create` function for the Parent model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the Parent model.
 */
export function useCreate() {
	const [createParent] = useParentCreateMutation();

	return React.useCallback(
		async (formValues: ParentFormValues.Create) => {
			const input = ParentFormConversion.toGQLCreateInput(formValues);
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createParent({ variables: { input }, update: updateCache });

			return { data: data?.createParent ?? null, errors: errors ?? null };
		},
		[createParent],
	);
}

/**
 * Returns a `create` function for the Parent model with a nested Person model. The `create` function translates the
 * given `formValues` to the GraphQL create input for the Parent model.
 */
export function useCreateWithPerson() {
	const [createParent] = useParentCreateMutation();

	return React.useCallback(
		async (formValues: ParentFormValues.CreateWithPerson) => {
			const input = ParentFormConversion.toGQLCreateWithPersonInput(formValues);
			// TODO update person cache
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createParent({ variables: { input }, update: updateCache });

			return { data: data?.createParent ?? null, errors: errors ?? null };
		},
		[createParent],
	);
}

/**
 * Returns an `update` function for the Parent model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the Parent model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: Parent["id"]) {
	const [updateParent] = useParentUpdateMutation();

	return React.useCallback(
		async (
			changedFormValues: Partial<ParentFormValues.Detail>,
			initialFormValues: Partial<ParentFormValues.Detail>,
		) => {
			const input = ParentFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

			const { data, errors } = await updateParent({ variables: { id, input }, update: updateCache });

			return { data: data?.updateParent ?? null, errors: errors ?? null };
		},
		[updateParent, id],
	);
}

export function useUpdateWithPerson(id: Parent["id"]) {
	const [updateParent] = useParentUpdateWithPersonMutation();

	return React.useCallback(
		async (
			changedFormValues: Partial<ParentFormValues.DetailWithPerson>,
			initialFormValues: Partial<ParentFormValues.DetailWithPerson>,
		) => {
			const [input, personInput] = ParentFormConversion.toGQLUpdateWithPersonInput(
				changedFormValues,
				initialFormValues,
			);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);
			const updatePersonCache = PersonMutation.getUpdateCacheForUpdate(personInput, initialFormValues);
			const update = (
				cache: ApolloCache<any>,
				result: Omit<FetchResult<ParentUpdateWithPersonMutation, Record<string, any>, Record<string, any>>, "context">,
			) => {
				updateCache(cache, {
					...result,
					data: result.data ? { updateParent: result.data.updateParentWithPerson } : undefined,
				});
				updatePersonCache(cache, {
					...result,
					data: result.data ? { updatePerson: result.data.updateParentWithPerson.person } : undefined,
				});
			};

			const { data, errors } = await updateParent({ variables: { id, input, personInput }, update });

			return { data: data?.updateParentWithPerson ?? null, errors: errors ?? null };
		},
		[updateParent, id],
	);
}

/**
 * Returns a `del` function for the Parent model.
 *
 * @param id The ID of the instance to delete.
 */
export function useDelete(id: Parent["id"]) {
	const [deleteParent] = useParentDeleteMutation();

	return React.useCallback(async () => {
		const updateCache = getUpdateCacheForDelete(id);

		const { data, errors } = await deleteParent({ variables: { id }, update: updateCache });

		return { data: data?.deleteParent ?? false, errors: errors ?? null };
	}, [deleteParent, id]);
}

function getUpdateCacheForCreate(input: ParentCreateInput) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<ParentCreateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const createdObject = result.data.createParent;

		cache.writeQuery<ParentDetailQuery, ParentDetailQueryVariables>({
			query: ParentDetailDocument,
			data: { parent: createdObject },
			variables: { id: createdObject.id },
		});

		if (input.paymentIDs) {
			for (let i = 0; i < input.paymentIDs.length; i++) {
				addToParentOfPaymentCache(cache, input.paymentIDs[i], createdObject);
			}
		}

		if (input.personID) {
			addToParentOfPersonCache(cache, input.personID, createdObject);
		}

		if (input.relationshipIDs) {
			for (let i = 0; i < input.relationshipIDs.length; i++) {
				addToParentOfRelationshipCache(cache, input.relationshipIDs[i], createdObject);
			}
		}

		cache.evict({ id: "ROOT_QUERY", fieldName: "parentConnection" });
	};
}

function getUpdateCacheForUpdate(input: ParentUpdateInput, initialFormValues: Partial<ParentFormValues.Detail>) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<ParentUpdateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const updatedObject = result.data.updateParent;

		if (input.addPaymentIDs) {
			for (let i = 0; i < input.addPaymentIDs.length; i++) {
				addToParentOfPaymentCache(cache, input.addPaymentIDs[i], updatedObject);
			}
		}
		if (input.removePaymentIDs) {
			for (let i = 0; i < input.removePaymentIDs.length; i++) {
				removeFromParentOfPaymentCache(cache, input.removePaymentIDs[i], updatedObject);
			}
		}

		if (initialFormValues.personID && input.personID) {
			removeFromParentOfPersonCache(cache, initialFormValues.personID, updatedObject);
		}
		if (input.personID) {
			addToParentOfPersonCache(cache, input.personID, updatedObject);
		}

		if (input.addRelationshipIDs) {
			for (let i = 0; i < input.addRelationshipIDs.length; i++) {
				addToParentOfRelationshipCache(cache, input.addRelationshipIDs[i], updatedObject);
			}
		}
		if (input.removeRelationshipIDs) {
			for (let i = 0; i < input.removeRelationshipIDs.length; i++) {
				removeFromParentOfRelationshipCache(cache, input.removeRelationshipIDs[i], updatedObject);
			}
		}
	};
}

function getUpdateCacheForDelete(id: Parent["id"]) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<ParentDeleteMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (!result.data?.deleteParent) {
			return;
		}

		cache.evict({ id: cache.identify({ id, __typename: graphTypeNames.Parent }) });
		cache.evict({ id: "ROOT_QUERY", fieldName: "parentConnection" });
		cache.gc();
	};
}

function addToParentOfPaymentCache(cache: ApolloCache<any>, targetID: Payment["id"], object: Pick<Parent, "id">) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Payment }),
		fields: {
			parent: () => objectRef,
			parentID: () => object.id,
		},
	});
}

function removeFromParentOfPaymentCache(cache: ApolloCache<any>, targetID: Payment["id"], _object: Pick<Parent, "id">) {
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Payment }),
		fields: {
			parent: () => null,
			parentID: () => null,
		},
	});
}

function addToParentOfPersonCache(cache: ApolloCache<any>, targetID: Person["id"], object: Pick<Parent, "id">) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Person }),
		fields: {
			parent: () => objectRef,
		},
	});
}

function removeFromParentOfPersonCache(cache: ApolloCache<any>, targetID: Person["id"], _object: Pick<Parent, "id">) {
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Person }),
		fields: {
			parent: () => null,
		},
	});
}

function addToParentOfRelationshipCache(
	cache: ApolloCache<any>,
	targetID: Relationship["id"],
	object: Pick<Parent, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Relationship }),
		fields: {
			parent: () => objectRef,
			parentID: () => object.id,
		},
	});
}

function removeFromParentOfRelationshipCache(
	cache: ApolloCache<any>,
	targetID: Relationship["id"],
	_object: Pick<Parent, "id">,
) {
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Relationship }),
		fields: {
			parent: () => null,
			parentID: () => null,
		},
	});
}

function toCacheRef(cache: ApolloCache<any>, object: Pick<Parent, "id">) {
	return cache.writeFragment({
		fragment: gql`
			fragment ParentRef on Parent {
				id
			}
		`,
		data: object,
	});
}
