import { IndexForms } from "@hex-insights/app-modules";
import { TypeFilterObject } from "@hex-insights/core";
import { NoteFormValues } from "../ModelFormValues";

export type StandardFilterFormValues = {}; // TODO define quick filters

export type FormValues = StandardFilterFormValues & IndexForms.SearchFormValues & IndexForms.FilterFormValues;

export const initialStandardFilters: StandardFilterFormValues = {};

export const initial: FormValues = {
	...initialStandardFilters,
	...IndexForms.initialSearchFormValues,
	...IndexForms.initialFilterFormValues,
};

export function getInitial(): FormValues {
	return initial;
}

export const quickFiltersTypeFilterObject: TypeFilterObject<StandardFilterFormValues> = {};

export const typeFilterObject: TypeFilterObject<FormValues> = {
	...quickFiltersTypeFilterObject,
	...IndexForms.searchFormValuesFilterObject,
	...IndexForms.filterFormValuesFilterObject,
};

export const advancedFilterInformation: IndexForms.AdvancedFilterInformation = {
	fieldOptions: [
		{ value: "noteType" },
		{ value: "title" },
		{ value: "body" },
		{ value: "createdAt" },
		{ value: "startDate" },
		{ value: "endDate" },
	],
	fieldComparisonOptions: {
		noteType: IndexForms.enumComparisonOptions,
		title: IndexForms.stringComparisonOptions,
		body: IndexForms.stringComparisonOptions,
		createdAt: IndexForms.timeComparisonOptions,
		startDate: [...IndexForms.dateComparisonOptions, ...IndexForms.nullableComparisonOptions],
		endDate: [...IndexForms.dateComparisonOptions, ...IndexForms.nullableComparisonOptions],
	},
	fieldComparisonTranslations: {
		noteType: IndexForms.getRadioComparisonTranslations(NoteFormValues.noteTypeOptions),
		title: IndexForms.stringComparisonTranslations,
		body: IndexForms.stringComparisonTranslations,
		createdAt: IndexForms.timeComparisonTranslations,
		startDate: { ...IndexForms.dateComparisonTranslations, ...IndexForms.nullableComparisonTranslations },
		endDate: { ...IndexForms.dateComparisonTranslations, ...IndexForms.nullableComparisonTranslations },
	},
};
