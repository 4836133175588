import { newPageInfo } from "@hex-insights/router";
import { rootPageInfo } from "../../root.pageinfo";

export type SchedulePageRouteParams = {};

export const schedulePageInfo = newPageInfo({
	name: "Schedule View",
	title: "Schedule",
	description: "View schedule for a day.",
	exact: false,
	to: "/schedule",
	path: "/schedule",
	parent: rootPageInfo,
});
