import { IndexForms } from "@hex-insights/app-modules";
import { CaseStyle, convertCase } from "@hex-insights/core";
import { LearningObjectiveOrderField, OrderDirection } from "../../GraphQL";

export type FormValues = IndexForms.OrderFormValues<LearningObjectiveOrderField | null>;

export const initial: FormValues = {
	field: LearningObjectiveOrderField.Category,
	direction: OrderDirection.Asc,
};

/**
 * Options for the "field" field in the order form of the LearningObjective model.
 */
export const fieldOptions = [
	{ label: "ID", value: null },
	{
		label: convertCase(LearningObjectiveOrderField.Category.toLowerCase(), CaseStyle.Title),
		value: LearningObjectiveOrderField.Category,
	},
	{
		label: convertCase(LearningObjectiveOrderField.Description.toLowerCase(), CaseStyle.Title),
		value: LearningObjectiveOrderField.Description,
	},
	{
		label: convertCase(LearningObjectiveOrderField.IsActive.toLowerCase(), CaseStyle.Title),
		value: LearningObjectiveOrderField.IsActive,
	},
];

/**
 * Array of values for the "field" field in the order form of the LearningObjective model.
 */
export const fieldValues = fieldOptions.map((e) => e.value);

/**
 * Mapping from order fields of the LearningObjective model to their corresponding order type.
 */
export const fieldTypeMapping = {
	[LearningObjectiveOrderField.Category]: IndexForms.OrderFieldType.String,
	[LearningObjectiveOrderField.Description]: IndexForms.OrderFieldType.String,
	[LearningObjectiveOrderField.IsActive]: IndexForms.OrderFieldType.Boolean,
};

/**
 * Mapping from order fields of the LearningObjective model to their corresponding field name.
 */
export const orderFieldToModelField = {
	[LearningObjectiveOrderField.Category]: "category",
	[LearningObjectiveOrderField.Description]: "description",
	[LearningObjectiveOrderField.IsActive]: "isActive",
};

/**
 * Mapping from field names of the LearningObjective model to their corresponding order field.
 */
export const modelFieldToOrderField = {
	category: LearningObjectiveOrderField.Category,
	description: LearningObjectiveOrderField.Description,
	isActive: LearningObjectiveOrderField.IsActive,
};
