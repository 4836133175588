import { Models } from "@hex-insights/app-modules";
import { StudentDocument, StudentDocumentMutation } from "../../../../Utilities";

export type StudentDocumentDeleteButtonProps = Omit<Models.DeleteButtonProps, "deleteInstance"> & {
	instanceID: StudentDocument["id"];
};

/**
 * A delete button for the StudentDocument model.
 */
export function StudentDocumentDeleteButton({
	instanceID,
	confirmationContent = "Are you sure you want to delete this resource?",
	children = "Delete Resource",
	...props
}: StudentDocumentDeleteButtonProps) {
	const del = StudentDocumentMutation.useDelete(instanceID);

	return (
		<Models.DeleteButton deleteInstance={del} confirmationContent={confirmationContent} {...props}>
			{children}
		</Models.DeleteButton>
	);
}
