import { IndexForms } from "@hex-insights/app-modules";
import { CaseStyle, convertCase } from "@hex-insights/core";
import { OrderDirection, SchoolAttendanceRecordOrderField } from "../../GraphQL";

export type FormValues = IndexForms.OrderFormValues<SchoolAttendanceRecordOrderField | null>;

export const initial: FormValues = {
	field: SchoolAttendanceRecordOrderField.CheckInTime,
	direction: OrderDirection.Desc,
};

/**
 * Options for the "field" field in the order form of the SchoolAttendanceRecord model.
 */
export const fieldOptions = [
	{ label: "ID", value: null },
	{
		label: convertCase(SchoolAttendanceRecordOrderField.CheckInTime.toLowerCase(), CaseStyle.Title),
		value: SchoolAttendanceRecordOrderField.CheckInTime,
	},
	{
		label: convertCase(SchoolAttendanceRecordOrderField.CheckOutTime.toLowerCase(), CaseStyle.Title),
		value: SchoolAttendanceRecordOrderField.CheckOutTime,
	},
	{
		label: convertCase(SchoolAttendanceRecordOrderField.IsPresent.toLowerCase(), CaseStyle.Title),
		value: SchoolAttendanceRecordOrderField.IsPresent,
	},
	{
		label: convertCase(SchoolAttendanceRecordOrderField.IsLate.toLowerCase(), CaseStyle.Title),
		value: SchoolAttendanceRecordOrderField.IsLate,
	},
	{
		label: convertCase(SchoolAttendanceRecordOrderField.IsExcused.toLowerCase(), CaseStyle.Title),
		value: SchoolAttendanceRecordOrderField.IsExcused,
	},
];

/**
 * Array of values for the "field" field in the order form of the SchoolAttendanceRecord model.
 */
export const fieldValues = fieldOptions.map((e) => e.value);

/**
 * Mapping from order fields of the SchoolAttendanceRecord model to their corresponding order type.
 */
export const fieldTypeMapping = {
	[SchoolAttendanceRecordOrderField.CheckInTime]: IndexForms.OrderFieldType.Time,
	[SchoolAttendanceRecordOrderField.CheckOutTime]: IndexForms.OrderFieldType.Time,
	[SchoolAttendanceRecordOrderField.IsPresent]: IndexForms.OrderFieldType.Boolean,
	[SchoolAttendanceRecordOrderField.IsLate]: IndexForms.OrderFieldType.Boolean,
	[SchoolAttendanceRecordOrderField.IsExcused]: IndexForms.OrderFieldType.Boolean,
};

/**
 * Mapping from order fields of the SchoolAttendanceRecord model to their corresponding field name.
 */
export const orderFieldToModelField = {
	[SchoolAttendanceRecordOrderField.CheckInTime]: "checkInTime",
	[SchoolAttendanceRecordOrderField.CheckOutTime]: "checkOutTime",
	[SchoolAttendanceRecordOrderField.IsPresent]: "isPresent",
	[SchoolAttendanceRecordOrderField.IsLate]: "isLate",
	[SchoolAttendanceRecordOrderField.IsExcused]: "isExcused",
};

/**
 * Mapping from field names of the SchoolAttendanceRecord model to their corresponding order field.
 */
export const modelFieldToOrderField = {
	checkInTime: SchoolAttendanceRecordOrderField.CheckInTime,
	checkOutTime: SchoolAttendanceRecordOrderField.CheckOutTime,
	isPresent: SchoolAttendanceRecordOrderField.IsPresent,
	isLate: SchoolAttendanceRecordOrderField.IsLate,
	isExcused: SchoolAttendanceRecordOrderField.IsExcused,
};
