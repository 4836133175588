import React from "react";
import {
	Button,
	ClassNameProps,
	Column,
	Conditional,
	formatDateTime,
	formatInt,
	Heading,
	Icon,
	If,
	mod,
	Row,
	StyleProps,
} from "@hex-insights/core";
import { InternalLink, useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import {
	Course,
	CourseSection,
	DocumentResourceOrderField,
	getPreviousAndNextCourseSectionPeriods,
	minutesToTime,
	OrderDirection,
	ProgressBarTile,
	Tile,
	useCourseSectionPeriodProgress,
	useDocumentResourceIndexQuery,
} from "@hex-insights/verita.shared";
import { Main, ResourcesTile } from "../../../../Components";
import { useCurrentTeacherScheduleForDateQuery } from "../../../../Utilities";
import { usePageDate } from "../utils";
import { MainTile } from "./MainTile";
import { scheduleDayCoursePeriodPageInfo, ScheduleDayCoursePeriodPageRouteParams } from "./pageinfo";
import { StudentsTile } from "./Students";
import { usePageCourseSectionPeriodQuery } from "./utils";
import styles from "./styles.module.css";

function ScheduleDayCoursePeriodPageWrapper() {
	const { date, courseSectionPeriodID } = useRouteParams<ScheduleDayCoursePeriodPageRouteParams>();
	return <ScheduleDayCoursePeriodPage key={date + "," + courseSectionPeriodID} />;
}

export { ScheduleDayCoursePeriodPageWrapper as ScheduleDayCoursePeriodPage };

function ScheduleDayCoursePeriodPage() {
	const { date } = usePageDate();

	const { loading, data, error } = usePageCourseSectionPeriodQuery();
	const courseSection = data?.courseSectionPeriod.courseSection;

	useActivePageRegistration(
		scheduleDayCoursePeriodPageInfo,
		!!courseSection ? scheduleDayCoursePeriodPageInfo.title(courseSection) : "",
	);

	if (loading) {
		return <Main>Loading...</Main>;
	}

	if (error || !courseSection) {
		return <Main>There was a problem loading this course period.</Main>;
	}

	if (data.courseSectionPeriod.dayOfWeek !== date.getDay()) {
		return <DateMismatch />;
	}

	return (
		<Main>
			<Column
				justify="spaced-start"
				style={{ height: "calc(100vh - var(--header---height) - 2 * var(--main---padding-top))" }}
			>
				<CourseHeader />

				<Row justify="spaced-start" style={{ flexGrow: 1, height: "100%", minHeight: 0 }}>
					<Column justify="spaced-start" style={{ flexGrow: 1, width: "100%", minWidth: 0, height: "100%" }}>
						<MainTile style={{ flexGrow: 1 }} />
						<CoursePeriodResourcesTile courseID={courseSection.course.id} courseSectionID={courseSection.id} />
					</Column>

					<Column style={{ flexShrink: 0, height: "100%" }}>
						<StudentsTile style={{ height: "100%", overflow: "hidden" }} />
					</Column>
				</Row>
			</Column>
		</Main>
	);
}

function DateMismatch() {
	// TODO
	return <Main>Looks like something went wrong</Main>;
}

function CourseHeader() {
	const { data } = usePageCourseSectionPeriodQuery();

	const courseSectionPeriod = data!.courseSectionPeriod ?? null;

	const { date, dateISO } = usePageDate();
	const { data: scheduleData } = useCurrentTeacherScheduleForDateQuery(date);
	const { previous: previousPeriod, next: nextPeriod } = getPreviousAndNextCourseSectionPeriods(
		scheduleData,
		courseSectionPeriod?.id,
	);

	const { minutesToStart, minutesToEnd, isActiveOnDay, courseTimeProgressAnimationTarget, refreshIntervalMS } =
		useCourseSectionPeriodProgress(courseSectionPeriod);

	if (!courseSectionPeriod) {
		return null;
	}

	return (
		<ProgressBarTile
			progress={courseTimeProgressAnimationTarget}
			refreshIntervalMS={refreshIntervalMS}
			className={styles["course-header"]}
		>
			<Tile.Body>
				<Row justify="center" align="center">
					<If condition={!!previousPeriod}>
						<InternalLink
							to={scheduleDayCoursePeriodPageInfo.to(dateISO, previousPeriod?.id ?? "0")}
							className={`${styles["course-header__link"]} ${styles["course-header__link--back"]}`}
						>
							{previousPeriod?.courseSection.name}
						</InternalLink>
					</If>

					<Heading level={1} noMargin style={{ lineHeight: 1 }}>
						{courseSectionPeriod.courseSection.name}
					</Heading>

					<CourseHeaderTime
						startTimeMinutes={courseSectionPeriod.startTimeMinutes}
						endTimeMinutes={courseSectionPeriod.endTimeMinutes}
						minutesToStart={minutesToStart}
						minutesToEnd={minutesToEnd}
						isActiveOnDay={isActiveOnDay}
						style={{ position: "absolute", bottom: 0, left: "50%", transform: "translateX(-50%)" }}
					/>

					<If condition={!!nextPeriod}>
						<InternalLink
							to={scheduleDayCoursePeriodPageInfo.to(dateISO, nextPeriod?.id ?? "0")}
							className={`${styles["course-header__link"]} ${styles["course-header__link--forward"]}`}
						>
							{nextPeriod?.courseSection.name}
						</InternalLink>
					</If>
				</Row>
			</Tile.Body>
		</ProgressBarTile>
	);
}

type CourseHeaderTimeProps = {
	startTimeMinutes: number;
	endTimeMinutes: number;
	minutesToStart: number;
	minutesToEnd: number;
	isActiveOnDay: boolean;
} & Partial<StyleProps>;

function CourseHeaderTime({
	startTimeMinutes,
	endTimeMinutes,
	minutesToStart,
	minutesToEnd,
	isActiveOnDay,
	style,
}: CourseHeaderTimeProps) {
	const isBeforeStart = minutesToStart > 0;
	const isAfterEnd = minutesToEnd < 0;
	const viewOptions = React.useMemo(() => {
		if (!isActiveOnDay) {
			return ["clock-times"];
		}
		if (isBeforeStart) {
			return ["start-diff", "clock-times"];
		}
		if (isAfterEnd) {
			return ["end-diff", "clock-times"];
		}
		return ["end-diff", "start-diff", "clock-times"];
	}, [isActiveOnDay, isBeforeStart, isAfterEnd]);
	const numViewOptions = viewOptions.length;

	const [viewOptionIndex, setViewOptionIndex] = React.useState(0);
	React.useEffect(() => {
		setViewOptionIndex(0);
	}, [viewOptions]);
	const onClick = React.useCallback(() => {
		setViewOptionIndex((prev) => mod(prev + 1, numViewOptions));
	}, [numViewOptions]);

	const view = viewOptions[viewOptionIndex];

	return (
		<div style={style}>
			<Button variant="link" size="small" onClick={onClick} style={{ padding: 0 }}>
				<Row justify="spaced-start" horizontalSpacing="0.25rem" align="center">
					<Icon.Clock size="0.8rem" />

					<Conditional>
						<If condition={view === "end-diff"}>{formatMinutesToEnd(minutesToEnd)}</If>
						<If condition={view === "start-diff"}>{formatMinutesToStart(minutesToStart)}</If>
						<If condition={view === "clock-times"}>
							{formatDateTime(minutesToTime(startTimeMinutes), "HH:mm")} to{" "}
							{formatDateTime(minutesToTime(endTimeMinutes), "HH:mm")}
						</If>
					</Conditional>
				</Row>
			</Button>
		</div>
	);
}

function formatMinutesToStart(minutesToStart: number) {
	const isBeforeStart = minutesToStart > 0;
	if (isBeforeStart) {
		return `Starts in ${formatInt(minutesToStart)} min`;
	}
	return `Started ${formatInt(Math.abs(minutesToStart))} min ago`;
}

function formatMinutesToEnd(minutesToEnd: number) {
	const isAfterEnd = minutesToEnd < 0;
	if (isAfterEnd) {
		return `Ended ${formatInt(Math.abs(minutesToEnd))} min ago`;
	}
	return `${formatInt(minutesToEnd)} min remaining`;
}

export type CoursePeriodResourcesTileProps = {
	courseID: Course["id"];
	courseSectionID: CourseSection["id"];
} & Partial<ClassNameProps & StyleProps>;

export function CoursePeriodResourcesTile({ courseID, courseSectionID, ...props }: CoursePeriodResourcesTileProps) {
	const { loading, data } = useDocumentResourceIndexQuery({
		variables: {
			filters: {
				documentResourceFolders: [
					{
						courses: [{ idEQ: courseID }],
					},
					{
						courseSections: [{ idEQ: courseSectionID }],
					},
				],
			},
			order: { field: DocumentResourceOrderField.Name, direction: OrderDirection.Asc },
		},
	});

	return <ResourcesTile isLoading={loading} edges={data?.documentResourceConnection.edges} {...props} />;
}
