import { IndexForms } from "@hex-insights/app-modules";
import { TypeFilterObject } from "@hex-insights/core";
import { PersonFormValues } from "../ModelFormValues";

export type StandardFilterFormValues = {}; // TODO define quick filters

export type FormValues = StandardFilterFormValues & IndexForms.SearchFormValues & IndexForms.FilterFormValues;

export const initialStandardFilters: StandardFilterFormValues = {};

export const initial: FormValues = {
	...initialStandardFilters,
	...IndexForms.initialSearchFormValues,
	...IndexForms.initialFilterFormValues,
};

export function getInitial(): FormValues {
	return initial;
}

export const quickFiltersTypeFilterObject: TypeFilterObject<StandardFilterFormValues> = {};

export const typeFilterObject: TypeFilterObject<FormValues> = {
	...quickFiltersTypeFilterObject,
	...IndexForms.searchFormValuesFilterObject,
	...IndexForms.filterFormValuesFilterObject,
};

export const advancedFilterInformation: IndexForms.AdvancedFilterInformation = {
	fieldOptions: [
		{ value: "firstName" },
		{ value: "lastName" },
		{ value: "nickname" },
		{ value: "alternateNames" },
		{ value: "rawImageURL" },
		{ value: "gender" },
		{ value: "dateOfBirth" },
		{ value: "nationality" },
		{ value: "primaryLanguage" },
		{ value: "englishLanguageFluency" },
	],
	fieldComparisonOptions: {
		firstName: IndexForms.stringComparisonOptions,
		lastName: IndexForms.stringComparisonOptions,
		nickname: IndexForms.stringComparisonOptions,
		alternateNames: IndexForms.stringComparisonOptions,
		rawImageURL: IndexForms.stringComparisonOptions,
		gender: IndexForms.stringComparisonOptions,
		dateOfBirth: [...IndexForms.dateComparisonOptions, ...IndexForms.nullableComparisonOptions],
		nationality: IndexForms.stringComparisonOptions,
		primaryLanguage: IndexForms.stringComparisonOptions,
		englishLanguageFluency: IndexForms.enumComparisonOptions,
	},
	fieldComparisonTranslations: {
		firstName: IndexForms.stringComparisonTranslations,
		lastName: IndexForms.stringComparisonTranslations,
		nickname: IndexForms.stringComparisonTranslations,
		alternateNames: IndexForms.stringComparisonTranslations,
		rawImageURL: IndexForms.stringComparisonTranslations,
		gender: IndexForms.stringComparisonTranslations,
		dateOfBirth: { ...IndexForms.dateComparisonTranslations, ...IndexForms.nullableComparisonTranslations },
		nationality: IndexForms.stringComparisonTranslations,
		primaryLanguage: IndexForms.stringComparisonTranslations,
		englishLanguageFluency: IndexForms.getRadioComparisonTranslations(PersonFormValues.englishLanguageFluencyOptions),
	},
};
