import { IndexForms } from "@hex-insights/app-modules";
import { CaseStyle, convertCase } from "@hex-insights/core";
import { EmployeeOrderField, OrderDirection } from "../../GraphQL";

export type FormValues = IndexForms.OrderFormValues<EmployeeOrderField | null>;

export const initial: FormValues = {
	field: EmployeeOrderField.Email,
	direction: OrderDirection.Asc,
};

/**
 * Options for the "field" field in the order form of the Employee model.
 */
export const fieldOptions = [
	{ label: "ID", value: null },
	{ label: convertCase(EmployeeOrderField.Email.toLowerCase(), CaseStyle.Title), value: EmployeeOrderField.Email },
	{
		label: convertCase(EmployeeOrderField.JobTitle.toLowerCase(), CaseStyle.Title),
		value: EmployeeOrderField.JobTitle,
	},
	{
		label: convertCase(EmployeeOrderField.Role.toLowerCase(), CaseStyle.Title),
		value: EmployeeOrderField.Role,
	},
	{
		label: convertCase(EmployeeOrderField.StartDate.toLowerCase(), CaseStyle.Title),
		value: EmployeeOrderField.StartDate,
	},
	{
		label: convertCase(EmployeeOrderField.EndDate.toLowerCase(), CaseStyle.Title),
		value: EmployeeOrderField.EndDate,
	},
];

/**
 * Array of values for the "field" field in the order form of the Employee model.
 */
export const fieldValues = fieldOptions.map((e) => e.value);

/**
 * Mapping from order fields of the Employee model to their corresponding order type.
 */
export const fieldTypeMapping = {
	[EmployeeOrderField.Email]: IndexForms.OrderFieldType.String,
	[EmployeeOrderField.JobTitle]: IndexForms.OrderFieldType.String,
	[EmployeeOrderField.Role]: IndexForms.OrderFieldType.String,
	[EmployeeOrderField.StartDate]: IndexForms.OrderFieldType.Time,
	[EmployeeOrderField.EndDate]: IndexForms.OrderFieldType.Time,
};

/**
 * Mapping from order fields of the Employee model to their corresponding field name.
 */
export const orderFieldToModelField = {
	[EmployeeOrderField.Email]: "email",
	[EmployeeOrderField.JobTitle]: "jobTitle",
	[EmployeeOrderField.Role]: "role",
	[EmployeeOrderField.StartDate]: "startDate",
	[EmployeeOrderField.EndDate]: "endDate",
};

/**
 * Mapping from field names of the Employee model to their corresponding order field.
 */
export const modelFieldToOrderField = {
	email: EmployeeOrderField.Email,
	jobTitle: EmployeeOrderField.JobTitle,
	role: EmployeeOrderField.Role,
	startDate: EmployeeOrderField.StartDate,
	endDate: EmployeeOrderField.EndDate,
};
