import React from "react";
import { ApplyCreateFunction, ApplyUpdateFunction, onCreateSubmit, onUpdateSubmit } from "@hex-insights/app-modules";
import { Button, Column, Icon, If, Row } from "@hex-insights/core";
import {
	anyFieldEditing,
	everyFieldEditing,
	Form,
	FormState,
	FormSubmitFunction,
	FormType,
	SubFormRenderProps,
	SubmissionStatus,
	SubmitButton,
	useInternalField,
} from "@hex-insights/forms";
import {
	ContractInstallmentDetailQuery,
	ContractInstallmentFormConversion,
	ContractInstallmentFormState,
	ContractInstallmentFormValues,
} from "../../../../Utilities";
import * as Field from "./Field";

export type ControlledCreateProps = {
	applyCreate: ApplyCreateFunction<ContractInstallmentFormValues.Create>;
	onSuccess: () => void;
	disableContract?: boolean;
};

/**
 * Renders the create form of the ContractInstallment model using an internally managed form state.
 */
export function ControlledCreate(props: ControlledCreateProps) {
	const formState = ContractInstallmentFormState.useCreateFormState();

	return <Create formState={formState} {...props} />;
}

export type CreateProps = ControlledCreateProps & {
	formState: FormState<ContractInstallmentFormValues.Create>;
};

/**
 * Renders the create form of the ContractInstallment model using the given form state.
 */
export function Create({ formState, applyCreate, onSuccess, disableContract }: CreateProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<ContractInstallmentFormValues.Create>>(
		async ({ formValues }) => {
			return onCreateSubmit(formValues, applyCreate);
		},
		[applyCreate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	return (
		<Form name="contractInstallment.create" formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)}>
			<Column justify="spaced-start">
				<Field.Contract formState={formState} formType={FormType.Create} disabled={disableContract} />
				<Field.InstallmentDate formState={formState} formType={FormType.Create} />
				<Field.Portion formState={formState} formType={FormType.Create} />

				<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
					Submit
				</SubmitButton>
			</Column>
		</Form>
	);
}

export type CreateInContractProps = SubFormRenderProps<ContractInstallmentFormValues.CreateInContract> & {
	portionDisabled?: boolean;
};

export function CreateInContract({ formState, onRemoveClick, portionDisabled }: CreateInContractProps) {
	return (
		<Row justify="spaced-start" align="flex-end" style={{ marginBottom: "0.5rem" }}>
			<Field.InstallmentDate formState={formState} formType={FormType.Create} />
			<Field.Portion formState={formState} formType={FormType.Create} disabled={portionDisabled} />

			<Button
				variant="tertiary"
				size="small"
				onClick={onRemoveClick}
				disabled={formState.disabled}
				style={{ marginBottom: "0.5rem" }}
			>
				<Icon.Trash size="1rem" style={{ display: "block" }} />
			</Button>
		</Row>
	);
}

export type ControlledDetailProps = {
	contractInstallment: ContractInstallmentDetailQuery["contractInstallment"];
	applyUpdate: ApplyUpdateFunction<ContractInstallmentFormValues.Detail>;
	onSuccess: () => void;
	/** Note: cannot change on an instance of this component. */
	hideInvoice?: boolean;
};

/**
 * Renders the detail form of the ContractInstallment model using an internally managed form state.
 */
export function ControlledDetail(props: ControlledDetailProps) {
	const initialFormValues = React.useMemo(
		() => ContractInstallmentFormConversion.toFormValues(props.contractInstallment),
		[props.contractInstallment],
	);
	const formState = ContractInstallmentFormState.useDetailFormState({ initialFormValues });

	return <Detail formState={formState} {...props} />;
}

export type DetailProps = ControlledDetailProps & {
	formState: FormState<ContractInstallmentFormValues.Detail>;
};

/**
 * Renders the detail form of the ContractInstallment model using the given form state.
 */
export function Detail({ formState, contractInstallment, applyUpdate, onSuccess, hideInvoice }: DetailProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<ContractInstallmentFormValues.Detail>>(
		async (formState) => {
			return onUpdateSubmit(formState, applyUpdate);
		},
		[applyUpdate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	const { anyEditing, everyEditing } = React.useMemo(() => {
		return {
			anyEditing: anyFieldEditing({
				formEditing: formState.formEditing,
				formSubFormStates: formState.formSubFormStates,
			}),
			everyEditing: everyFieldEditing({
				formEditing: formState.formEditing,
				formSubFormStates: formState.formSubFormStates,
			}),
		};
	}, [formState.formEditing, formState.formSubFormStates]);

	if (hideInvoice) {
		useInternalField(formState, "invoiceID");
	}

	return (
		<Form
			name={`contractInstallment.detail.${contractInstallment.id}`}
			formState={formState}
			onSubmit={formState.onSubmitWrapper(onSubmit)}
		>
			<Column justify="spaced-start">
				<Row justify="spaced-start" horizontalSpacing="0.75rem">
					<If condition={!everyEditing}>
						<Button variant="tertiary" size="small" onClick={() => formState.setFormEditing(true)}>
							Edit
						</Button>
					</If>
					<If condition={anyEditing}>
						<Button variant="tertiary" size="small" onClick={() => formState.setFormEditing(false)}>
							Stop Editing
						</Button>
					</If>
				</Row>

				<Field.Contract
					formState={formState}
					formType={FormType.Update}
					id={contractInstallment.id}
					currentContract={contractInstallment.contract}
				/>
				<Field.InstallmentDate formState={formState} formType={FormType.Update} id={contractInstallment.id} />
				<Field.Portion formState={formState} formType={FormType.Update} id={contractInstallment.id} />
				<If condition={!hideInvoice}>
					<Field.Invoice formState={formState} formType={FormType.Update} id={contractInstallment.id} />
				</If>
			</Column>
		</Form>
	);
}

export type ControlledReadOnlyProps = {
	contractInstallment: ContractInstallmentDetailQuery["contractInstallment"];
};

/**
 * Renders a read-only detail form of the ContractInstallment model using an internally managed form state.
 */
export function ControlledReadOnly(props: ControlledReadOnlyProps) {
	const initialFormValues = React.useMemo(
		() => ContractInstallmentFormConversion.toFormValues(props.contractInstallment),
		[props.contractInstallment],
	);
	const formState = ContractInstallmentFormState.useReadOnlyFormState({ initialFormValues });

	return <ReadOnly formState={formState} {...props} />;
}

export type ReadOnlyProps = ControlledReadOnlyProps & {
	formState: FormState<ContractInstallmentFormValues.Detail>;
};

/**
 * Renders a read-only detail form of the ContractInstallment model using the given form state.
 */
export function ReadOnly({ formState, contractInstallment }: ReadOnlyProps) {
	return (
		<Column justify="spaced-start">
			<Field.Contract
				formState={formState}
				formType={FormType.Update}
				id={contractInstallment.id}
				currentContract={contractInstallment.contract}
			/>
			<Field.InstallmentDate formState={formState} formType={FormType.Update} id={contractInstallment.id} />
			<Field.Portion formState={formState} formType={FormType.Update} id={contractInstallment.id} />
		</Column>
	);
}
