import React from "react";
import { ApplyCreateFunction, ApplyUpdateFunction, onCreateSubmit, onUpdateSubmit } from "@hex-insights/app-modules";
import { Button, HeadingLevel, If, Row } from "@hex-insights/core";
import {
	anyFieldEditing,
	everyFieldEditing,
	Form,
	FormState,
	FormSubmitFunction,
	FormType,
	SubmissionStatus,
	SubmitButton,
	useInternalField,
} from "@hex-insights/forms";
import {
	ParentDetailQuery,
	ParentFormConversion,
	ParentFormState,
	ParentFormValues,
	PersonFormValues,
} from "../../../../Utilities";
import { FormActionRow, FormColumn } from "../../../FormAddon";
import { PersonForm } from "../Person";
import * as Field from "./Field";

export type ControlledCreateProps = {
	applyCreate: ApplyCreateFunction<ParentFormValues.Create>;
	onSuccess: () => void;
};

/**
 * Renders the create form of the Parent model using an internally managed form state.
 */
export function ControlledCreate(props: ControlledCreateProps) {
	const formState = ParentFormState.useCreateFormState();

	return <Create formState={formState} {...props} />;
}

export type CreateProps = ControlledCreateProps & {
	formState: FormState<ParentFormValues.Create>;
};

/**
 * Renders the create form of the Parent model using the given form state.
 */
export function Create({ formState, applyCreate, onSuccess }: CreateProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<ParentFormValues.Create>>(
		async ({ formValues }) => {
			return onCreateSubmit(formValues, applyCreate);
		},
		[applyCreate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	return (
		<Form name="parent.create" formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)}>
			<FormColumn>
				<Field.Person formState={formState} formType={FormType.Create} />

				<FormActionRow>
					<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
						Submit
					</SubmitButton>
				</FormActionRow>
			</FormColumn>
		</Form>
	);
}

export type ControlledCreateWithPersonProps = {
	applyCreate: ApplyCreateFunction<ParentFormValues.CreateWithPerson>;
	onSuccess: () => void;
	headingLevel?: HeadingLevel;
};

/**
 * Renders the create form of the Parent model using an internally managed form state.
 */
export function ControlledCreateWithPerson(props: ControlledCreateWithPersonProps) {
	const formState = ParentFormState.useCreateWithPersonFormState();

	return <CreateWithPerson formState={formState} {...props} />;
}

export type CreateWithPersonProps = ControlledCreateWithPersonProps & {
	formState: FormState<ParentFormValues.CreateWithPerson>;
};

export function CreateWithPerson({ formState, applyCreate, onSuccess, headingLevel = 2 }: CreateWithPersonProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<ParentFormValues.CreateWithPerson>>(
		async ({ formValues }) => {
			return onCreateSubmit(formValues, applyCreate);
		},
		[applyCreate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	return (
		<Form name="employee.create.withPerson" formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)}>
			<FormColumn>
				<PersonForm.GeneralCreateFields formState={formState} headingLevel={headingLevel} />
				<PersonForm.ContactCreateFields formState={formState} headingLevel={headingLevel} />
				<PersonForm.DemographicCreateFields formState={formState} headingLevel={headingLevel} />
				<PersonForm.LinkedRecordsCreateFields formState={formState} headingLevel={headingLevel} />

				<FormActionRow>
					<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
						Submit
					</SubmitButton>
				</FormActionRow>
			</FormColumn>
		</Form>
	);
}

export type ControlledDetailProps = {
	parent: ParentDetailQuery["parent"];
	applyUpdate: ApplyUpdateFunction<ParentFormValues.Detail>;
	onSuccess: () => void;
};

/**
 * Renders the detail form of the Parent model using an internally managed form state.
 */
export function ControlledDetail(props: ControlledDetailProps) {
	const initialFormValues = React.useMemo(() => ParentFormConversion.toFormValues(props.parent), [props.parent]);
	const formState = ParentFormState.useDetailFormState({ initialFormValues });

	return <Detail formState={formState} {...props} />;
}

export type DetailProps = ControlledDetailProps & {
	formState: FormState<ParentFormValues.Detail>;
};

/**
 * Renders the detail form of the Parent model using the given form state.
 */
export function Detail({ formState, parent, applyUpdate, onSuccess }: DetailProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<ParentFormValues.Detail>>(
		async (formState) => {
			return onUpdateSubmit(formState, applyUpdate);
		},
		[applyUpdate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	const { anyEditing, everyEditing } = React.useMemo(() => {
		return {
			anyEditing: anyFieldEditing({
				formEditing: formState.formEditing,
				formSubFormStates: formState.formSubFormStates,
			}),
			everyEditing: everyFieldEditing({
				formEditing: formState.formEditing,
				formSubFormStates: formState.formSubFormStates,
			}),
		};
	}, [formState.formEditing, formState.formSubFormStates]);

	return (
		<Form name={`parent.detail.${parent.id}`} formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)}>
			<FormColumn>
				<Row justify="spaced-start" horizontalSpacing="0.75rem">
					<If condition={!everyEditing}>
						<Button variant="tertiary" size="small" onClick={() => formState.setFormEditing(true)}>
							Edit
						</Button>
					</If>
					<If condition={anyEditing}>
						<Button variant="tertiary" size="small" onClick={() => formState.setFormEditing(false)}>
							Stop Editing
						</Button>
					</If>
				</Row>

				<Field.Person formState={formState} formType={FormType.Update} id={parent.id} currentPerson={parent.person} />
				<Field.Relationships
					formState={formState}
					formType={FormType.Update}
					id={parent.id}
					currentRelationships={parent.relationships}
				/>
				<Field.Payments
					formState={formState}
					formType={FormType.Update}
					id={parent.id}
					currentPayments={parent.payments}
				/>
			</FormColumn>
		</Form>
	);
}

export type ControlledDetailWithPersonProps = {
	parent: ParentDetailQuery["parent"];
	applyUpdate: ApplyUpdateFunction<ParentFormValues.DetailWithPerson>;
	onSuccess: () => void;
	headingLevel?: HeadingLevel;
};

/**
 * Renders the detail form of the Parent model using an internally managed form state.
 */
export function ControlledDetailWithPerson(props: ControlledDetailWithPersonProps) {
	const initialFormValues = React.useMemo(
		() => ParentFormConversion.toFormValuesWithPerson(props.parent),
		[props.parent],
	);
	const formState = ParentFormState.useDetailWithPersonFormState({ initialFormValues });

	return <DetailWithPerson formState={formState} {...props} />;
}

const hiddenPersonDetailFields: (keyof PersonFormValues.DetailInOther)[] = ["userID"];

export type DetailWithPersonProps = ControlledDetailWithPersonProps & {
	formState: FormState<ParentFormValues.DetailWithPerson>;
};

/**
 * Renders the detail form of the Parent model using the given form state.
 */
export function DetailWithPerson({
	formState,
	parent,
	applyUpdate,
	onSuccess,
	headingLevel = 2,
}: DetailWithPersonProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<ParentFormValues.DetailWithPerson>>(
		async (formState) => {
			return onUpdateSubmit(formState, applyUpdate);
		},
		[applyUpdate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	const { anyEditing, everyEditing } = React.useMemo(() => {
		return {
			anyEditing: anyFieldEditing({
				formEditing: formState.formEditing,
				formSubFormStates: formState.formSubFormStates,
			}),
			everyEditing: everyFieldEditing({
				formEditing: formState.formEditing,
				formSubFormStates: formState.formSubFormStates,
			}),
		};
	}, [formState.formEditing, formState.formSubFormStates]);

	useInternalField(formState, hiddenPersonDetailFields);

	return (
		<Form name={`parent.detail.${parent.id}`} formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)}>
			<FormColumn>
				<Row justify="spaced-start" horizontalSpacing="0.75rem">
					<If condition={!everyEditing}>
						<Button variant="tertiary" size="small" onClick={() => formState.setFormEditing(true)}>
							Edit
						</Button>
					</If>
					<If condition={anyEditing}>
						<Button variant="tertiary" size="small" onClick={() => formState.setFormEditing(false)}>
							Stop Editing
						</Button>
					</If>
				</Row>

				<PersonForm.GeneralDetailFields formState={formState} person={parent.person} headingLevel={headingLevel} />
				<PersonForm.ContactDetailFields formState={formState} person={parent.person} headingLevel={headingLevel} />
				<PersonForm.DemographicDetailFields formState={formState} person={parent.person} headingLevel={headingLevel} />
				<Field.Relationships
					formState={formState}
					formType={FormType.Update}
					id={parent.id}
					currentRelationships={parent.relationships}
				/>
				<Field.Payments
					formState={formState}
					formType={FormType.Update}
					id={parent.id}
					currentPayments={parent.payments}
				/>
			</FormColumn>
		</Form>
	);
}

export type ControlledReadOnlyProps = {
	parent: ParentDetailQuery["parent"];
};

/**
 * Renders a read-only detail form of the Parent model using an internally managed form state.
 */
export function ControlledReadOnly(props: ControlledReadOnlyProps) {
	const initialFormValues = React.useMemo(() => ParentFormConversion.toFormValues(props.parent), [props.parent]);
	const formState = ParentFormState.useReadOnlyFormState({ initialFormValues });

	return <ReadOnly formState={formState} {...props} />;
}

export type ReadOnlyProps = ControlledReadOnlyProps & {
	formState: FormState<ParentFormValues.Detail>;
};

/**
 * Renders a read-only detail form of the Parent model using the given form state.
 */
export function ReadOnly({ formState, parent }: ReadOnlyProps) {
	return (
		<FormColumn>
			<Field.Person formState={formState} formType={FormType.Update} id={parent.id} currentPerson={parent.person} />
			<Field.Relationships
				formState={formState}
				formType={FormType.Update}
				id={parent.id}
				currentRelationships={parent.relationships}
			/>
			<Field.Payments
				formState={formState}
				formType={FormType.Update}
				id={parent.id}
				currentPayments={parent.payments}
			/>
		</FormColumn>
	);
}
