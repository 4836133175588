import { NullPartial } from "@hex-insights/core";
import { RelationshipCreateInput, RelationshipDetailQuery, RelationshipUpdateInput } from "../../GraphQL";
import { RelationshipFormValues } from "../ModelFormValues";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the Relationship model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(formValues: RelationshipFormValues.Create): RelationshipCreateInput {
	const input: NullPartial<RelationshipCreateInput> = { ...formValues };
	return input as RelationshipCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the Relationship model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<RelationshipFormValues.Detail>,
	_initialFormValues: RelationshipFormValues.Detail,
): RelationshipUpdateInput {
	const input: RelationshipUpdateInput = { ...formValues };
	return input;
}

/**
 * Converts a Relationship instance to its corresponding detail form values.
 *
 * @param relationship The instance to convert.
 */
export function toFormValues(relationship: RelationshipDetailQuery["relationship"]): RelationshipFormValues.Detail {
	return {
		relationshipType: relationship.relationshipType,
		isAuthorizedForPickup: relationship.isAuthorizedForPickup,
		isEmergencyContact: relationship.isEmergencyContact,
		isFinancialContact: relationship.isFinancialContact,
		isPrimary: relationship.isPrimary,
		livesWithStudent: relationship.livesWithStudent,
		parentID: relationship.parent?.id ?? null,
		studentID: relationship.student?.id ?? null,
	};
}
