import React from "react";
import {
	DateTimeField,
	FieldDisplayArgs,
	FileField,
	FormType,
	MultiSelectField,
	RadioButtonsInput,
	RadioField,
	SelectField,
	TextField,
} from "@hex-insights/forms";
import {
	StudentDocumentFormValues,
	StudentSelect,
	UserGroupSelect,
	UserSelect,
	useStudentSelectLazyQuery,
	useUserGroupSelectLazyQuery,
	useUserSelectLazyQuery,
} from "../../../../Utilities";
import { StudentLink, UserGroupLink, UserLink } from "../../../Links";
import { TextAreaField } from "../../../TextAreaField";
import { BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the StudentDocument model.
 */
type FieldProps<K extends keyof StudentDocumentFormValues.Base = keyof StudentDocumentFormValues.Base> = BaseFieldProps<
	Pick<StudentDocumentFormValues.Base, K>
>;

/**
 * Generic props for fields of the StudentDocument model that only appear in the detail form.
 */
type DetailFieldProps<K extends keyof StudentDocumentFormValues.Detail = keyof StudentDocumentFormValues.Detail> =
	BaseFieldProps<Pick<StudentDocumentFormValues.Detail, K>>;

/**
 * Renders a field component for the `name` field of the StudentDocument model.
 */
export function Name({ formState }: FieldProps<"name">) {
	return <TextField formState={formState} name="name" />;
}

/**
 * Renders a field component for the `description` field of the StudentDocument model.
 */
export function Description({ formState }: FieldProps<"description">) {
	return <TextAreaField formState={formState} name="description" optional />;
}

/**
 * Renders a field component for the `file` field of the StudentDocument model.
 */
export function File({ formState }: FieldProps<"file">) {
	return <FileField formState={formState} name="file" />;
}

/**
 * Renders a field component for the `isPublic` field of the StudentDocument model.
 */
export function IsPublic({ formState }: FieldProps<"isPublic">) {
	return (
		<RadioField
			formState={formState}
			name="isPublic"
			label="Sharing"
			options={StudentDocumentFormValues.isPublicOptions}
			noClear
			Input={RadioButtonsInput}
			hint="Public documents can be viewed by anyone. Private documents can only be viewed by the creator and those who have been given access through a group."
		/>
	);
}

/**
 * Renders a field component for the `createdAt` field of the StudentDocument model.
 */
export function CreatedAt({ formState, formType = FormType.Update }: DetailFieldProps<"createdAt">) {
	return (
		<DateTimeField formState={formState} name="createdAt" optional={FormType.isCreate(formType)} precision="minute" />
	);
}

export type AuthorProps = DetailFieldProps<"authorID"> & {
	currentAuthor?: UserSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `author` edge of the StudentDocument model.
 */
export function Author({ formState, currentAuthor }: AuthorProps) {
	const [loadOptions, { loading, data }] = useUserSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.authorID) {
			loadOptions();
		}
	}, [formState.formEditing.authorID, loadOptions]);
	const options = React.useMemo(
		() => UserSelect.toOptions(data?.userConnection.edges, currentAuthor),
		[data, currentAuthor],
	);

	return (
		<SelectField
			formState={formState}
			name="authorID"
			isLoading={loading}
			options={options}
			optional
			display={displayAuthor}
			blankValue={null}
		/>
	);
}

function displayAuthor({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <UserLink instance={{ id }}>{formattedValue}</UserLink>;
}

export type SharingUserGroupsProps = FieldProps<"sharingUserGroupIDs"> & {
	currentSharingUserGroups?: UserGroupSelect.ModelForOption[];
};

/**
 * Renders a field component for the `sharingUserGroups` edge of the StudentDocument model.
 */
export function SharingUserGroups({ formState, currentSharingUserGroups }: SharingUserGroupsProps) {
	const [loadOptions, { loading, data }] = useUserGroupSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.sharingUserGroupIDs) {
			loadOptions();
		}
	}, [formState.formEditing.sharingUserGroupIDs, loadOptions]);
	const options = React.useMemo(
		() => UserGroupSelect.toMultiOptions(data?.userGroupConnection.edges, currentSharingUserGroups),
		[data, currentSharingUserGroups],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="sharingUserGroupIDs"
			isLoading={loading}
			options={options}
			displayInstance={displaySharingUserGroupInstance}
		/>
	);
}

function displaySharingUserGroupInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <UserGroupLink instance={{ id }}>{formattedValue}</UserGroupLink>;
}

export type StudentProps = FieldProps<"studentID"> & {
	currentStudent?: StudentSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `student` edge of the StudentDocument model.
 */
export function Student({ formState, currentStudent }: StudentProps) {
	const [loadOptions, { loading, data }] = useStudentSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.studentID) {
			loadOptions();
		}
	}, [formState.formEditing.studentID, loadOptions]);
	const options = React.useMemo(
		() => StudentSelect.toOptions(data?.studentConnection.edges, currentStudent),
		[data, currentStudent],
	);

	return (
		<SelectField
			formState={formState}
			name="studentID"
			isLoading={loading}
			options={options}
			display={displayStudent}
			blankValue={null}
		/>
	);
}

function displayStudent({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <StudentLink instance={{ id }}>{formattedValue}</StudentLink>;
}
