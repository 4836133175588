import { Models } from "@hex-insights/app-modules";
import { DistributiveOmit } from "@hex-insights/core";
import { Payment, PaymentFormat, PaymentNameFragment } from "../../../../Utilities";

type ModelForLink = Pick<Payment, "id"> & PaymentNameFragment;

export type PaymentLinkProps = DistributiveOmit<Models.LinkProps<ModelForLink>, "pageInfoKey" | "format">;

/**
 * Renders a link to the given Payment instance.
 */
export function PaymentLink(props: PaymentLinkProps) {
	return <Models.Link<ModelForLink> pageInfoKey="Payment" format={PaymentFormat.name} {...props} />;
}
