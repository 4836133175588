import { PaymentNameFragment } from "../../../GraphQL";
import * as Fields from "./fields";

export { Fields };

/**
 * Formats the full name of the given instance.
 */
export function name(instance: PaymentNameFragment) {
	return instance.id;
}
