import { getArrayDiff, NullPartial, omitKeys } from "@hex-insights/core";
import { ContractCreateInput, ContractDetailQuery, ContractUpdateInput } from "../../GraphQL";
import { ContractFormValues } from "../ModelFormValues";
import * as ContractInstallmentFormConversion from "./contract-installment";
import * as ServiceSubscriptionFormConversion from "./service-subscription";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the Contract model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(formValues: ContractFormValues.Create): ContractCreateInput {
	const inputFormValues = omitKeys(formValues, ["paymentScheduleType"]);
	const input: NullPartial<ContractCreateInput> = {
		...inputFormValues,
		serviceSubscriptions: formValues.serviceSubscriptions.map(
			ServiceSubscriptionFormConversion.toGQLCreateInContractInput,
		),
		contractInstallments: formValues.contractInstallments.map(
			ContractInstallmentFormConversion.toGQLCreateInContractInput,
		),
	};
	return input as ContractCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the Contract model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<ContractFormValues.Detail>,
	initialFormValues: ContractFormValues.Detail,
): ContractUpdateInput {
	const inputFormValues = omitKeys(formValues, ["contractInstallmentIDs", "serviceSubscriptionIDs"]);
	const input: ContractUpdateInput = { ...inputFormValues };

	if (formValues.contractInstallmentIDs !== undefined) {
		const { added, removed } = getArrayDiff(
			initialFormValues.contractInstallmentIDs,
			formValues.contractInstallmentIDs,
		);
		input.addContractInstallmentIDs = added;
		input.removeContractInstallmentIDs = removed;
	}

	if (formValues.serviceSubscriptionIDs !== undefined) {
		const { added, removed } = getArrayDiff(
			initialFormValues.serviceSubscriptionIDs,
			formValues.serviceSubscriptionIDs,
		);
		input.addServiceSubscriptionIDs = added;
		input.removeServiceSubscriptionIDs = removed;
	}

	return input;
}

/**
 * Converts a Contract instance to its corresponding detail form values.
 *
 * @param contract The instance to convert.
 */
export function toFormValues(contract: ContractDetailQuery["contract"]): ContractFormValues.Detail {
	return {
		agreementDate: contract.agreementDate,
		status: contract.status,
		contractInstallmentIDs: contract.contractInstallments.map((e) => e.id),
		serviceSubscriptionIDs: contract.serviceSubscriptions.map((e) => e.id),
		studentID: contract.student?.id ?? null,
	};
}
