import React from "react";
import { addTimeToDate, dateTrunc, toLocalDateString } from "@hex-insights/core";
import { EmployeeContext, useCourseSectionTeacherLessonPlanOverviewListQuery } from "@hex-insights/verita.shared";

export function useCurrentLessonPlanOverviewQuery() {
	return useCurrentTeacherLessonPlanOverviewForDateQuery(new Date());
}

export function useCurrentTeacherLessonPlanOverviewForDateQuery(date: Date) {
	const { employee } = React.useContext(EmployeeContext);

	const startDate = addTimeToDate(dateTrunc(date, "week"), [1, "day"]);
	const endDate = addTimeToDate(startDate, [7, "days"]);

	return useCourseSectionTeacherLessonPlanOverviewListQuery({
		variables: {
			teacherID: employee.id,
			startDate: toLocalDateString(startDate),
			endDate: toLocalDateString(endDate),
		},
	});
}
