import React from "react";
import {
	addTimeToDate,
	Button,
	ClassNameProps,
	Column,
	Conditional,
	Else,
	formatDateTime,
	formatInt,
	Heading,
	Icon,
	If,
	Modal,
	ModalProps,
	Row,
	StyleProps,
} from "@hex-insights/core";
import { CourseSectionFilterInput, Scalars, useWeeklyCourseScheduleQuery } from "../../Utilities";
import { DayCoursesCalendarAgendaProps, SlimDayCoursesCalendarAgenda } from "../CoursesCalendarAgenda";
import { ProgressBarTile } from "../ProgressBarTile";
import { Tile } from "../Tile";
import { useVerticallyExpandingElement, VerticallyExpandingContainer } from "../VerticallyExpandingElement";
import { courseSectionEdgesToCalendarEvents, WeeklyCourseScheduleCalendar } from "../WeeklyCourseScheduleCalendar";
import styles from "./styles.module.css";

export type CoursesScheduleTileProps<ID extends Scalars["ID"]> = {
	id: ID;
	useCurrentPeriod: (id: ID) => {
		currentPeriod: { courseSection: { name: string } } | null;
		courseTimeProgressAnimationTarget: number;
		refreshIntervalMS: number;
		minutesToEnd: number;
	};
} & Pick<FullScheduleProps<ID>, "useScheduleForDateQuery"> &
	Pick<WeeklyScheduleModalProps<ID>, "getWeeklyScheduleFilters"> &
	Partial<ClassNameProps & StyleProps>;

export function CoursesScheduleTile<ID extends Scalars["ID"]>({
	id,
	useCurrentPeriod,
	useScheduleForDateQuery,
	getWeeklyScheduleFilters,
	className,
	style,
}: CoursesScheduleTileProps<ID>) {
	const { currentPeriod, courseTimeProgressAnimationTarget, refreshIntervalMS, minutesToEnd } = useCurrentPeriod(id);

	const { containerRef, isExpanded, onOpenClick, onCloseClick, expandingClassName, expandingStyle } =
		useVerticallyExpandingElement();

	return (
		<VerticallyExpandingContainer containerRef={containerRef} className={className} style={style}>
			<ProgressBarTile
				progress={courseTimeProgressAnimationTarget}
				refreshIntervalMS={refreshIntervalMS}
				style={
					{
						width: "100%",
						boxShadow: isExpanded ? "none" : undefined,
						transition: "300ms ease",
						"--section__body---padding": "1rem",
					} as React.CSSProperties
				}
			>
				<Tile.Body>
					<div onClick={onOpenClick} style={{ width: "100%", height: "100%", cursor: "pointer" }}>
						<Column justify="space-between" align="center" style={{ height: "100%" }}>
							<Conditional>
								<If condition={!currentPeriod}>
									<span style={{ fontSize: "1.25rem" }}>View Daily Schedule</span>
								</If>
								<Else>
									<span style={{ fontSize: "0.9rem" }}>Current Class</span>
									<span style={{ fontSize: "1.25rem" }}>{currentPeriod?.courseSection.name}</span>
									<span style={{ fontSize: "0.9rem" }}>{formatInt(minutesToEnd)} min remaining</span>
								</Else>
							</Conditional>
						</Column>
					</div>
				</Tile.Body>
			</ProgressBarTile>

			<FullSchedule
				id={id}
				useScheduleForDateQuery={useScheduleForDateQuery}
				getWeeklyScheduleFilters={getWeeklyScheduleFilters}
				onClose={onCloseClick}
				className={expandingClassName}
				style={expandingStyle}
			/>
		</VerticallyExpandingContainer>
	);
}

type FullScheduleProps<ID extends Scalars["ID"]> = {
	id: ID;
	useScheduleForDateQuery: (
		id: ID,
		date: Date,
	) => {
		loading: boolean;
		data: { courseSectionPeriodConnection: { edges: DayCoursesCalendarAgendaProps["edges"] } } | undefined;
	};
	onClose: () => void;
} & Pick<WeeklyScheduleModalProps<ID>, "getWeeklyScheduleFilters"> &
	Partial<ClassNameProps & StyleProps>;

function FullSchedule<ID extends Scalars["ID"]>({
	id,
	useScheduleForDateQuery,
	getWeeklyScheduleFilters,
	onClose,
	className,
	style,
}: FullScheduleProps<ID>) {
	const [date, setDate] = React.useState(() => new Date());

	const onPrevClick = React.useCallback(() => {
		setDate((prev) => addTimeToDate(prev, [-1, "day"]).toDate());
	}, []);

	const onNextClick = React.useCallback(() => {
		setDate((prev) => addTimeToDate(prev, [1, "day"]).toDate());
	}, []);

	const { loading, data } = useScheduleForDateQuery(id, date);

	const { isModalOpen, toggleIsModalOpen } = Modal.useToggle(false);

	return (
		<Tile className={className} style={style}>
			<Tile.Header>
				<Row justify="space-between" align="center">
					<Heading level={2} noMargin>
						Daily Schedule
					</Heading>

					<Button variant="link" size="small" onClick={toggleIsModalOpen}>
						View Weekly
					</Button>
				</Row>
			</Tile.Header>
			<Tile.Body style={{ height: "100%", minHeight: 0 }}>
				<Column style={{ height: "100%", overflow: "hidden" }}>
					<Column justify="spaced-start" style={{ flexGrow: 1, height: "100%", overflow: "scroll" }}>
						<Row justify="space-between">
							<Button variant="tertiary" size="small" onClick={onPrevClick}>
								<Row justify="spaced-start" horizontalSpacing="0.25rem" align="center">
									<Icon.ChevronLeft size="1rem" /> Previous
								</Row>
							</Button>
							<span>{formatDateTime(date, "ddd, D MMM")}</span>
							<Button variant="tertiary" size="small" onClick={onNextClick}>
								<Row justify="spaced-start" horizontalSpacing="0.25rem" align="center">
									Next <Icon.ChevronRight size="1rem" />
								</Row>
							</Button>
						</Row>

						<SlimDayCoursesCalendarAgenda
							isLoading={loading}
							edges={data?.courseSectionPeriodConnection.edges}
							date={date}
						/>
					</Column>

					<Row justify="center">
						<Button variant="link" size="small" onClick={onClose} style={{ width: "100%" }}>
							Close
						</Button>
					</Row>

					<Modal.If condition={isModalOpen}>
						<WeeklyScheduleModal
							id={id}
							date={date}
							getWeeklyScheduleFilters={getWeeklyScheduleFilters}
							onClose={toggleIsModalOpen}
						/>
					</Modal.If>
				</Column>
			</Tile.Body>
		</Tile>
	);
}

type WeeklyScheduleModalProps<ID extends Scalars["ID"]> = {
	id: ID;
	date: Date;
	getWeeklyScheduleFilters: (id: ID, date: Date) => CourseSectionFilterInput | CourseSectionFilterInput[];
} & Pick<ModalProps, "ifRef" | "onClose">;

function WeeklyScheduleModal<ID extends Scalars["ID"]>({
	id,
	date,
	getWeeklyScheduleFilters,
	ifRef,
	onClose,
}: WeeklyScheduleModalProps<ID>) {
	const { data } = useWeeklyCourseScheduleQuery({ variables: { filters: getWeeklyScheduleFilters(id, date) } });

	const events = React.useMemo(() => {
		if (!data) {
			return [];
		}
		return courseSectionEdgesToCalendarEvents(data.courseSectionConnection.edges);
	}, [data]);

	return (
		<Modal ifRef={ifRef} onClose={onClose} className={styles["weekly-schedule-modal"]}>
			<Modal.Body>
				<WeeklyCourseScheduleCalendar events={events} containerStyle={{ height: "100%" }} />
			</Modal.Body>
		</Modal>
	);
}
