import React from "react";
import { ApolloCache, FetchResult, gql } from "@apollo/client";
import {
	DocumentResource,
	DocumentResourceUserViewRecord,
	DocumentResourceUserViewRecordCreateInput,
	DocumentResourceUserViewRecordCreateMutation,
	DocumentResourceUserViewRecordDeleteMutation,
	DocumentResourceUserViewRecordDetailDocument,
	DocumentResourceUserViewRecordDetailQuery,
	DocumentResourceUserViewRecordDetailQueryVariables,
	graphTypeNames,
	useDocumentResourceUserViewRecordCreateMutation,
	useDocumentResourceUserViewRecordDeleteMutation,
} from "../../GraphQL";
import { DocumentResourceUserViewRecordFormConversion } from "../ModelFormConversion";
import { DocumentResourceUserViewRecordFormValues } from "../ModelFormValues";

/**
 * Returns a `create` function for the DocumentResourceUserViewRecord model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the DocumentResourceUserViewRecord model.
 */
export function useCreate() {
	const [createDocumentResourceUserViewRecord] = useDocumentResourceUserViewRecordCreateMutation();

	return React.useCallback(
		async (formValues: DocumentResourceUserViewRecordFormValues.Create) => {
			const input = DocumentResourceUserViewRecordFormConversion.toGQLCreateInput(formValues);
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createDocumentResourceUserViewRecord({
				variables: { input },
				update: updateCache,
			});

			return { data: data?.createDocumentResourceUserViewRecord ?? null, errors: errors ?? null };
		},
		[createDocumentResourceUserViewRecord],
	);
}

/**
 * Returns a `del` function for the DocumentResourceUserViewRecord model.
 *
 * @param id The ID of the instance to delete.
 */
export function useDelete(id: DocumentResourceUserViewRecord["id"]) {
	const [deleteDocumentResourceUserViewRecord] = useDocumentResourceUserViewRecordDeleteMutation();

	return React.useCallback(async () => {
		const updateCache = getUpdateCacheForDelete(id);

		const { data, errors } = await deleteDocumentResourceUserViewRecord({ variables: { id }, update: updateCache });

		return { data: data?.deleteDocumentResourceUserViewRecord ?? false, errors: errors ?? null };
	}, [deleteDocumentResourceUserViewRecord, id]);
}

function getUpdateCacheForCreate(input: DocumentResourceUserViewRecordCreateInput) {
	return (
		cache: ApolloCache<any>,
		result: Omit<
			FetchResult<DocumentResourceUserViewRecordCreateMutation, Record<string, any>, Record<string, any>>,
			"context"
		>,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const createdObject = result.data.createDocumentResourceUserViewRecord;

		cache.writeQuery<DocumentResourceUserViewRecordDetailQuery, DocumentResourceUserViewRecordDetailQueryVariables>({
			query: DocumentResourceUserViewRecordDetailDocument,
			data: { documentResourceUserViewRecord: createdObject },
			variables: { id: createdObject.id },
		});

		if (input.documentResourceID) {
			addToDocumentResourceUserViewRecordsOfDocumentResourceCache(cache, input.documentResourceID, createdObject);
		}

		cache.evict({ id: "ROOT_QUERY", fieldName: "documentResourceUserViewRecordConnection" });
	};
}

function getUpdateCacheForDelete(id: DocumentResourceUserViewRecord["id"]) {
	return (
		cache: ApolloCache<any>,
		result: Omit<
			FetchResult<DocumentResourceUserViewRecordDeleteMutation, Record<string, any>, Record<string, any>>,
			"context"
		>,
	) => {
		if (!result.data?.deleteDocumentResourceUserViewRecord) {
			return;
		}

		cache.evict({ id: cache.identify({ id, __typename: graphTypeNames.DocumentResourceUserViewRecord }) });
		cache.evict({ id: "ROOT_QUERY", fieldName: "documentResourceUserViewRecordConnection" });
		cache.gc();
	};
}

function addToDocumentResourceUserViewRecordsOfDocumentResourceCache(
	cache: ApolloCache<any>,
	targetID: DocumentResource["id"],
	object: Pick<DocumentResourceUserViewRecord, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.DocumentResource }),
		fields: {
			documentResourceUserViewRecords: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function toCacheRef(cache: ApolloCache<any>, object: Pick<DocumentResourceUserViewRecord, "id">) {
	return cache.writeFragment({
		fragment: gql`
			fragment DocumentResourceUserViewRecordRef on DocumentResourceUserViewRecord {
				id
			}
		`,
		data: object,
	});
}
