import { NullPartial, omitKeys, pickKeys } from "@hex-insights/core";
import { PersonUpdateInput, StudentCreateInput, StudentDetailQuery, StudentUpdateInput } from "../../GraphQL";
import { PersonFormValues, StudentFormValues } from "../ModelFormValues";
import * as PersonFormConversion from "./person";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the Student model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(formValues: StudentFormValues.Create): StudentCreateInput {
	const input: NullPartial<StudentCreateInput> = { ...formValues };
	return input as StudentCreateInput;
}

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the Student model with nested
 * Person form values.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateWithPersonInput(formValues: StudentFormValues.CreateWithPerson): StudentCreateInput {
	const inputFormValues = StudentFormValues.omitPersonCreateFormValues(formValues);
	const input: NullPartial<StudentCreateInput> = toGQLCreateInput({ ...inputFormValues, personID: null });
	const personFormValues = PersonFormValues.pickCreateInOtherFormValues(formValues);
	input.person = PersonFormConversion.toGQLCreateInOtherInput(personFormValues);
	return input as StudentCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the Student model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<StudentFormValues.Detail>,
	_initialFormValues: Partial<StudentFormValues.Detail>,
): StudentUpdateInput {
	const inputFormValues = omitKeys(formValues, ["studentIDNumber"]);
	const input: StudentUpdateInput = { ...inputFormValues };

	if (formValues.tuitionID === null) {
		input.tuitionID = null;
		input.clearTuition = true;
	}

	return input;
}

export function toGQLUpdateWithPersonInput(
	formValues: Partial<StudentFormValues.DetailWithPerson>,
	initialFormValues: Partial<StudentFormValues.DetailWithPerson>,
): [StudentUpdateInput, PersonUpdateInput] {
	const inputFormValues = StudentFormValues.omitPersonDetailFormValues(formValues);
	const initialInputFormValues = StudentFormValues.omitPersonDetailFormValues(initialFormValues);
	const input = toGQLUpdateInput(inputFormValues, initialInputFormValues);
	const personFormValues = PersonFormValues.pickDetailInOtherFormValues(formValues);
	const initialPersonFormValues = PersonFormValues.pickDetailInOtherFormValues(initialFormValues);
	const personInput = PersonFormConversion.toGQLUpdateInput(personFormValues, initialPersonFormValues);
	return [input, personInput];
}

/**
 * Converts a Student instance to its corresponding detail form values.
 *
 * @param student The instance to convert.
 */
export function toFormValues(student: StudentDetailQuery["student"]): StudentFormValues.Detail {
	return {
		personID: student.person?.id ?? null,
		studentIDNumber: student.studentIDNumber,
		graduatingYear: student.graduatingYear,
		hasPreviousSchooling: student.hasPreviousSchooling,
		previousSchoolInformation: student.previousSchoolInformation,
		previousSchoolLocation: student.previousSchoolLocation,
		allergies: student.allergies,
		asthma: student.asthma,
		medication: student.medication,
		medicationSchedule: student.medicationSchedule,
		dietaryRestrictions: student.dietaryRestrictions,
		healthNotes: student.healthNotes,
		doctorName: student.doctorName,
		doctorPhoneNumber: student.doctorPhoneNumber,
		emergencyContactInformation: student.emergencyContactInformation,
		tuitionID: student.tuition?.id ?? null,
	};
}

export function toFormValuesWithPerson(student: StudentDetailQuery["student"]): StudentFormValues.DetailWithPerson {
	return {
		...omitKeys(toFormValues(student), ["personID"]),
		...PersonFormConversion.toFormValuesInOther(student.person),
	};
}

export function toHealthFormValues(student: StudentDetailQuery["student"]): StudentFormValues.Detail.Health {
	return pickKeys(toFormValues(student), [
		"allergies",
		"asthma",
		"medication",
		"medicationSchedule",
		"dietaryRestrictions",
		"healthNotes",
		"doctorName",
		"doctorPhoneNumber",
		"emergencyContactInformation",
	]);
}
