import { IndexForms } from "@hex-insights/app-modules";
import { CaseStyle, convertCase } from "@hex-insights/core";
import { ExpenseBudgetOrderField, OrderDirection } from "../../GraphQL";

export type FormValues = IndexForms.OrderFormValues<ExpenseBudgetOrderField | null>;

export const initial: FormValues = {
	field: ExpenseBudgetOrderField.BudgetMonth,
	direction: OrderDirection.Asc,
};

/**
 * Options for the "field" field in the order form of the ExpenseBudget model.
 */
export const fieldOptions = [
	{ label: "ID", value: null },
	{
		label: convertCase(ExpenseBudgetOrderField.BudgetMonth.toLowerCase(), CaseStyle.Title),
		value: ExpenseBudgetOrderField.BudgetMonth,
	},
	{
		label: convertCase(ExpenseBudgetOrderField.Amount.toLowerCase(), CaseStyle.Title),
		value: ExpenseBudgetOrderField.Amount,
	},
];

/**
 * Array of values for the "field" field in the order form of the ExpenseBudget model.
 */
export const fieldValues = fieldOptions.map((e) => e.value);

/**
 * Mapping from order fields of the ExpenseBudget model to their corresponding order type.
 */
export const fieldTypeMapping = {
	[ExpenseBudgetOrderField.BudgetMonth]: IndexForms.OrderFieldType.Time,
	[ExpenseBudgetOrderField.Amount]: IndexForms.OrderFieldType.Number,
};

/**
 * Mapping from order fields of the ExpenseBudget model to their corresponding field name.
 */
export const orderFieldToModelField = {
	[ExpenseBudgetOrderField.BudgetMonth]: "budgetMonth",
	[ExpenseBudgetOrderField.Amount]: "amount",
};

/**
 * Mapping from field names of the ExpenseBudget model to their corresponding order field.
 */
export const modelFieldToOrderField = {
	budgetMonth: ExpenseBudgetOrderField.BudgetMonth,
	amount: ExpenseBudgetOrderField.Amount,
};
