import { Models } from "@hex-insights/app-modules";
import { DistributiveOmit } from "@hex-insights/core";
import { ServiceSubscription, ServiceSubscriptionFormat, ServiceSubscriptionNameFragment } from "../../../../Utilities";

type ModelForLink = Pick<ServiceSubscription, "id"> & ServiceSubscriptionNameFragment;

export type ServiceSubscriptionLinkProps = DistributiveOmit<Models.LinkProps<ModelForLink>, "pageInfoKey" | "format">;

/**
 * Renders a link to the given ServiceSubscription instance.
 */
export function ServiceSubscriptionLink(props: ServiceSubscriptionLinkProps) {
	return (
		<Models.Link<ModelForLink> pageInfoKey="ServiceSubscription" format={ServiceSubscriptionFormat.name} {...props} />
	);
}
