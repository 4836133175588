import React from "react";
import { ApplyUpdateFunction, onUpdateSubmit } from "@hex-insights/app-modules";
import {
	Column,
	compareNumbersAsc,
	compareObjects,
	Conditional,
	Else,
	getObjectEntries,
	getObjectKeys,
	Heading,
	If,
	makeObjectFromArrayMap,
	Row,
	useSync,
} from "@hex-insights/core";
import {
	Form,
	FormSubmitFunction,
	RadioButtonsInput,
	RadioField,
	SubmissionStatus,
	SubmitButton,
	useFormState,
	ValidationDisplayPolicy,
} from "@hex-insights/forms";
import { useActivePageRegistration, useRouteParams } from "@hex-insights/router";
import { BasicTable } from "@hex-insights/tables";
import {
	LearningObjectiveMark,
	LearningObjectiveMarkFormValues,
	Main,
	ReportCardStudentCourseSectionListQuery,
	Tile,
	useCourseSectionDetailQuery,
	useLearningObjectiveMarkValuesBulkUpdate,
	useReportCardStudentCourseSectionListQuery,
	useStudentDetailQuery,
} from "@hex-insights/verita.shared";
import {
	reportCardsTermCourseSectionStudentPageInfo,
	ReportCardsTermCourseSectionStudentPageRouteParams,
} from "./pageinfo";
import styles from "./styles.module.css";

export function ReportCardsTermCourseSectionStudentPage() {
	const { courseSectionID, studentID } = useRouteParams<ReportCardsTermCourseSectionStudentPageRouteParams>();
	const { data: studentData } = useStudentDetailQuery({ variables: { id: studentID } });
	const { data: courseSectionData } = useCourseSectionDetailQuery({ variables: { id: courseSectionID } });
	useActivePageRegistration(reportCardsTermCourseSectionStudentPageInfo, studentData?.student.person.name ?? "");

	const { loading, data } = useReportCardStudentCourseSectionListQuery({ variables: { studentID, courseSectionID } });

	return (
		<Main>
			<Tile>
				<Tile.Header>
					<Column>
						<Heading level={1} noMargin>
							{studentData?.student.person.name}
						</Heading>
						<strong>{courseSectionData?.courseSection.name}</strong>
					</Column>
				</Tile.Header>
				<Tile.Body>
					<Conditional>
						<If condition={loading}>Loading...</If>
						<Else>
							{data && <LearningObjectiveMarksFormController reportCard={data.reportCardConnection.edges[0].node} />}
						</Else>
					</Conditional>
				</Tile.Body>
			</Tile>
		</Main>
	);
}

type ReportCardInQuery = ReportCardStudentCourseSectionListQuery["reportCardConnection"]["edges"][0]["node"];
type LearningObjectiveMarkInQuery = ReportCardInQuery["learningObjectiveMarks"][0];

type LearningObjectiveMarkValueFormValues = Record<
	LearningObjectiveMark["id"],
	LearningObjectiveMarkFormValues.Detail["value"]
>;

type LearningObjectiveMarksFormControllerProps = {
	reportCard: ReportCardInQuery;
};

function LearningObjectiveMarksFormController({ reportCard }: LearningObjectiveMarksFormControllerProps) {
	const update = useLearningObjectiveMarkValuesBulkUpdate();
	const { syncID, synchronize } = useSync();
	const applyUpdate = React.useCallback(
		async (
			changedFormValues: Partial<LearningObjectiveMarkValueFormValues>,
			initialFormValues: LearningObjectiveMarkValueFormValues,
		) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<LearningObjectiveMarksForm
			key={syncID}
			reportCard={reportCard}
			applyUpdate={applyUpdate}
			onSuccess={synchronize}
		/>
	);
}

type LearningObjectiveMarksFormProps = LearningObjectiveMarksFormControllerProps & {
	applyUpdate: ApplyUpdateFunction<LearningObjectiveMarkValueFormValues>;
	onSuccess: () => void;
};

function LearningObjectiveMarksForm({ reportCard, applyUpdate, onSuccess }: LearningObjectiveMarksFormProps) {
	const { courseSectionID, studentID } = useRouteParams<ReportCardsTermCourseSectionStudentPageRouteParams>();

	const learningObjectiveMarksCategories: Record<string, LearningObjectiveMarkInQuery[]> = {};
	for (let i = 0; i < reportCard.learningObjectiveMarks.length; i++) {
		const mark = reportCard.learningObjectiveMarks[i];
		if (!learningObjectiveMarksCategories[mark.learningObjective.category]) {
			learningObjectiveMarksCategories[mark.learningObjective.category] = [];
		}
		learningObjectiveMarksCategories[mark.learningObjective.category].push(mark);
	}
	const categories = getObjectKeys(learningObjectiveMarksCategories);
	for (let i = 0; i < categories.length; i++) {
		learningObjectiveMarksCategories[categories[i]].sort(compareObjects("id", compareNumbersAsc()));
	}

	const initialFormValues = React.useMemo<LearningObjectiveMarkValueFormValues>(
		() => makeObjectFromArrayMap(reportCard.learningObjectiveMarks, (e) => [e.id, e.value]),
		[reportCard.learningObjectiveMarks],
	);
	const formState = useFormState({ initialFormValues, validationDisplayPolicy: ValidationDisplayPolicy.none });

	const onSubmit = React.useCallback<FormSubmitFunction<LearningObjectiveMarkValueFormValues>>(
		async (formState) => {
			return onUpdateSubmit(formState, applyUpdate);
		},
		[applyUpdate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	console.log(formState);

	return (
		<div>
			<Form
				formState={formState}
				name={`report-card.${studentID}.${courseSectionID}`}
				onSubmit={formState.onSubmitWrapper(onSubmit)}
			>
				<BasicTable>
					<BasicTable.Body>
						{getObjectEntries(learningObjectiveMarksCategories).map(([category, objectives]) => (
							<React.Fragment key={category}>
								<BasicTable.Row>
									<BasicTable.Cell>
										<strong>{category}</strong>
									</BasicTable.Cell>
								</BasicTable.Row>
								{objectives.map((e) => (
									<BasicTable.Row key={e.id}>
										<BasicTable.Cell>{e.learningObjective.description}</BasicTable.Cell>
										<BasicTable.Cell>
											<RadioField
												formState={formState}
												name={e.id}
												label={e.learningObjective.description}
												options={LearningObjectiveMarkFormValues.valueOptions}
												blankValue={null}
												optional
												Input={RadioButtonsInput}
												className={styles["learning-objective-mark__field"]}
											/>
										</BasicTable.Cell>
									</BasicTable.Row>
								))}
							</React.Fragment>
						))}
					</BasicTable.Body>
				</BasicTable>

				<Row>
					<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
						Save
					</SubmitButton>
				</Row>
			</Form>
		</div>
	);
}
