import { Icon } from "@hex-insights/core";
import { Header as SharedHeader, HeaderProps as SharedHeaderProps } from "@hex-insights/verita.shared";
import { logoutPageInfo } from "../../Pages/Authentication/Logout/pageinfo";
import { homePageInfo } from "../../Pages/Home/pageinfo";
import { lessonPlansPageInfo } from "../../Pages/LessonPlans/pageinfo";
import { schedulePageInfo } from "../../Pages/Schedule/pageinfo";

const links: SharedHeaderProps["links"] = [
	[<Icon.Home size="1.3rem" strokeWidth="2.5px" style={{ display: "block" }} />, "Home", homePageInfo],
	[<Icon.Calendar size="1.3rem" strokeWidth="2.5px" style={{ display: "block" }} />, "Schedule", schedulePageInfo],
	[
		<Icon.BookOpen size="1.3rem" strokeWidth="2.5px" style={{ display: "block" }} />,
		"Lesson Plans",
		lessonPlansPageInfo,
	],
	[<Icon.LogOut size="1.3rem" strokeWidth="2.5px" style={{ display: "block" }} />, "Logout", logoutPageInfo],
];

export function Header() {
	return <SharedHeader homePageInfo={homePageInfo} links={links} />;
}
