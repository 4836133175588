import React from "react";
import { ApplyCreateFunction, ApplyUpdateFunction, onCreateSubmit, onUpdateSubmit } from "@hex-insights/app-modules";
import { Button, Column, Heading, If, Row, Section } from "@hex-insights/core";
import {
	anyFieldEditing,
	everyFieldEditing,
	FieldIf,
	Form,
	FormState,
	FormSubmitFunction,
	FormType,
	SubmissionStatus,
	SubmitButton,
} from "@hex-insights/forms";
import { BusPlanDetailQuery, BusPlanFormConversion, BusPlanFormState, BusPlanFormValues } from "../../../../Utilities";
import * as Field from "./Field";

const pickupFieldNames: (keyof BusPlanFormValues.Base)[] = ["pickupBusID", "pickupTimeMinutes", "pickupLocation"];
const dropoffFieldNames: (keyof BusPlanFormValues.Base)[] = ["dropoffBusID", "dropoffTimeMinutes", "dropoffLocation"];

export type ControlledCreateProps = {
	applyCreate: ApplyCreateFunction<BusPlanFormValues.Create>;
	onSuccess: () => void;
};

/**
 * Renders the create form of the BusPlan model using an internally managed form state.
 */
export function ControlledCreate(props: ControlledCreateProps) {
	const formState = BusPlanFormState.useCreateFormState();

	return <Create formState={formState} {...props} />;
}

export type CreateProps = ControlledCreateProps & {
	formState: FormState<BusPlanFormValues.Create>;
};

/**
 * Renders the create form of the BusPlan model using the given form state.
 */
export function Create({ formState, applyCreate, onSuccess }: CreateProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<BusPlanFormValues.Create>>(
		async ({ formValues }) => {
			return onCreateSubmit(formValues, applyCreate);
		},
		[applyCreate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	return (
		<Form name="busPlan.create" formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)}>
			<Column justify="spaced-start" style={{ width: "fit-content" }}>
				<Field.Student formState={formState} formType={FormType.Create} />

				<Row justify="spaced-start" horizontalSpacing="1.5rem" overflow="wrap">
					<Section>
						<Section.Header>
							<Heading level={2} noMargin>
								Pick Up
							</Heading>
						</Section.Header>
						<Section.Body style={{ padding: 0 }}>
							<Column justify="spaced-start">
								<Field.HasPickup formState={formState} formType={FormType.Create} />
								<FieldIf formState={formState} names={pickupFieldNames} condition={formState.formValues.hasPickup}>
									<Field.PickupBus formState={formState} formType={FormType.Create} />
									<Field.PickupTimeMinutes formState={formState} formType={FormType.Create} />
									<Field.PickupLocation formState={formState} formType={FormType.Create} />
								</FieldIf>
							</Column>
						</Section.Body>
					</Section>

					<Section>
						<Section.Header>
							<Heading level={2} noMargin>
								Drop Off
							</Heading>
						</Section.Header>
						<Section.Body style={{ padding: 0 }}>
							<Column justify="spaced-start">
								<Field.HasDropoff formState={formState} formType={FormType.Create} />
								<FieldIf formState={formState} names={dropoffFieldNames} condition={formState.formValues.hasDropoff}>
									<Field.DropoffBus formState={formState} formType={FormType.Create} />
									<Field.DropoffTimeMinutes formState={formState} formType={FormType.Create} />
									<Field.DropoffLocation formState={formState} formType={FormType.Create} />
								</FieldIf>
							</Column>
						</Section.Body>
					</Section>
				</Row>

				<Row justify="flex-end">
					<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
						Submit
					</SubmitButton>
				</Row>
			</Column>
		</Form>
	);
}

export type ControlledDetailProps = {
	busPlan: BusPlanDetailQuery["busPlan"];
	applyUpdate: ApplyUpdateFunction<BusPlanFormValues.Detail>;
	onSuccess: () => void;
};

/**
 * Renders the detail form of the BusPlan model using an internally managed form state.
 */
export function ControlledDetail(props: ControlledDetailProps) {
	const initialFormValues = React.useMemo(() => BusPlanFormConversion.toFormValues(props.busPlan), [props.busPlan]);
	const formState = BusPlanFormState.useDetailFormState({ initialFormValues });

	return <Detail formState={formState} {...props} />;
}

export type DetailProps = ControlledDetailProps & {
	formState: FormState<BusPlanFormValues.Detail>;
};

/**
 * Renders the detail form of the BusPlan model using the given form state.
 */
export function Detail({ formState, busPlan, applyUpdate, onSuccess }: DetailProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<BusPlanFormValues.Detail>>(
		async (formState) => {
			return onUpdateSubmit(formState, applyUpdate);
		},
		[applyUpdate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	const { anyEditing, everyEditing } = React.useMemo(() => {
		return {
			anyEditing: anyFieldEditing({
				formEditing: formState.formEditing,
				formSubFormStates: formState.formSubFormStates,
			}),
			everyEditing: everyFieldEditing({
				formEditing: formState.formEditing,
				formSubFormStates: formState.formSubFormStates,
			}),
		};
	}, [formState.formEditing, formState.formSubFormStates]);

	return (
		<Form name={`busPlan.detail.${busPlan.id}`} formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)}>
			<Column justify="spaced-start">
				<Row justify="spaced-start" horizontalSpacing="0.75rem">
					<If condition={!everyEditing}>
						<Button variant="tertiary" size="small" onClick={() => formState.setFormEditing(true)}>
							Edit
						</Button>
					</If>
					<If condition={anyEditing}>
						<Button variant="tertiary" size="small" onClick={() => formState.setFormEditing(false)}>
							Stop Editing
						</Button>
					</If>
				</Row>

				<Field.Student
					formState={formState}
					formType={FormType.Update}
					id={busPlan.id}
					currentStudent={busPlan.student}
				/>

				<Row justify="spaced-start" horizontalSpacing="1.5rem" overflow="wrap">
					<Section>
						<Section.Header>
							<Heading level={2} noMargin>
								Pick Up
							</Heading>
						</Section.Header>
						<Section.Body style={{ padding: 0 }}>
							<Column justify="spaced-start">
								<Field.HasPickup formState={formState} formType={FormType.Update} id={busPlan.id} />
								<FieldIf formState={formState} names={pickupFieldNames} condition={formState.formValues.hasPickup}>
									<Field.PickupBus
										formState={formState}
										formType={FormType.Update}
										id={busPlan.id}
										currentPickupBus={busPlan.pickupBus}
									/>
									<Field.PickupTimeMinutes formState={formState} formType={FormType.Update} id={busPlan.id} />
									<Field.PickupLocation formState={formState} formType={FormType.Update} id={busPlan.id} />
								</FieldIf>
							</Column>
						</Section.Body>
					</Section>

					<Section>
						<Section.Header>
							<Heading level={2} noMargin>
								Drop Off
							</Heading>
						</Section.Header>
						<Section.Body style={{ padding: 0 }}>
							<Column justify="spaced-start">
								<Field.HasDropoff formState={formState} formType={FormType.Update} id={busPlan.id} />
								<FieldIf formState={formState} names={dropoffFieldNames} condition={formState.formValues.hasDropoff}>
									<Field.DropoffBus
										formState={formState}
										formType={FormType.Update}
										id={busPlan.id}
										currentDropoffBus={busPlan.dropoffBus}
									/>
									<Field.DropoffTimeMinutes formState={formState} formType={FormType.Update} id={busPlan.id} />
									<Field.DropoffLocation formState={formState} formType={FormType.Update} id={busPlan.id} />
								</FieldIf>
							</Column>
						</Section.Body>
					</Section>
				</Row>
			</Column>
		</Form>
	);
}

export type ControlledReadOnlyProps = {
	busPlan: BusPlanDetailQuery["busPlan"];
};

/**
 * Renders a read-only detail form of the BusPlan model using an internally managed form state.
 */
export function ControlledReadOnly(props: ControlledReadOnlyProps) {
	const initialFormValues = React.useMemo(() => BusPlanFormConversion.toFormValues(props.busPlan), [props.busPlan]);
	const formState = BusPlanFormState.useReadOnlyFormState({ initialFormValues });

	return <ReadOnly formState={formState} {...props} />;
}

export type ReadOnlyProps = ControlledReadOnlyProps & {
	formState: FormState<BusPlanFormValues.Detail>;
};

/**
 * Renders a read-only detail form of the BusPlan model using the given form state.
 */
export function ReadOnly({ formState, busPlan }: ReadOnlyProps) {
	return (
		<Column justify="spaced-start">
			<Field.Student
				formState={formState}
				formType={FormType.Update}
				id={busPlan.id}
				currentStudent={busPlan.student}
			/>

			<Row justify="spaced-start" horizontalSpacing="1.5rem" overflow="wrap">
				<Section>
					<Section.Header>
						<Heading level={2} noMargin>
							Pick Up
						</Heading>
					</Section.Header>
					<Section.Body style={{ padding: 0 }}>
						<Column justify="spaced-start">
							<Field.HasPickup formState={formState} formType={FormType.Update} id={busPlan.id} />
							<FieldIf formState={formState} names={pickupFieldNames} condition={formState.formValues.hasPickup}>
								<Field.PickupBus
									formState={formState}
									formType={FormType.Update}
									id={busPlan.id}
									currentPickupBus={busPlan.pickupBus}
								/>
								<Field.PickupTimeMinutes formState={formState} formType={FormType.Update} id={busPlan.id} />
								<Field.PickupLocation formState={formState} formType={FormType.Update} id={busPlan.id} />
							</FieldIf>
						</Column>
					</Section.Body>
				</Section>

				<Section>
					<Section.Header>
						<Heading level={2} noMargin>
							Drop Off
						</Heading>
					</Section.Header>
					<Section.Body style={{ padding: 0 }}>
						<Column justify="spaced-start">
							<Field.HasDropoff formState={formState} formType={FormType.Update} id={busPlan.id} />
							<FieldIf formState={formState} names={dropoffFieldNames} condition={formState.formValues.hasDropoff}>
								<Field.DropoffBus
									formState={formState}
									formType={FormType.Update}
									id={busPlan.id}
									currentDropoffBus={busPlan.dropoffBus}
								/>
								<Field.DropoffTimeMinutes formState={formState} formType={FormType.Update} id={busPlan.id} />
								<Field.DropoffLocation formState={formState} formType={FormType.Update} id={busPlan.id} />
							</FieldIf>
						</Column>
					</Section.Body>
				</Section>
			</Row>
		</Column>
	);
}
