import { omitKeys } from "@hex-insights/core";
import { Contract, ContractInstallment, Invoice } from "../../GraphQL";

export type Base = {
	installmentDate: ContractInstallment["installmentDate"] | null;
	portion: ContractInstallment["portion"] | null;
	contractID: Contract["id"] | null;
};

export type Create = Base;

export type Detail = Base & {
	invoiceID: Invoice["id"] | null;
};

export const initialCreate: Create = {
	installmentDate: null,
	portion: 1,
	contractID: null,
};

export type CreateInContract = Omit<Create, "contractID">;

export const initialCreateInContract = omitKeys(initialCreate, ["contractID"]);
