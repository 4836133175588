import { newPageInfo } from "@hex-insights/router";
import { rootPageInfo } from "../../../root.pageinfo";

export const recoverPageInfo = newPageInfo({
	title: "Recover Account",
	description: "Request a password reset link.",
	exact: true,
	path: "/recover",
	to: "/recover",
	parent: rootPageInfo,
	noSearch: true,
});
