import { IndexForms } from "@hex-insights/app-modules";
import { CaseStyle, convertCase } from "@hex-insights/core";
import { AddressOrderField, OrderDirection } from "../../GraphQL";

export type FormValues = IndexForms.OrderFormValues<AddressOrderField | null>;

export const initial: FormValues = {
	field: AddressOrderField.AddressLine1,
	direction: OrderDirection.Asc,
};

/**
 * Options for the "field" field in the order form of the Address model.
 */
export const fieldOptions = [
	{ label: "ID", value: null },
	{
		label: convertCase(AddressOrderField.AddressLine1.toLowerCase(), CaseStyle.Title),
		value: AddressOrderField.AddressLine1,
	},
	{
		label: convertCase(AddressOrderField.AddressLine2.toLowerCase(), CaseStyle.Title),
		value: AddressOrderField.AddressLine2,
	},
	{ label: convertCase(AddressOrderField.City.toLowerCase(), CaseStyle.Title), value: AddressOrderField.City },
	{
		label: convertCase(AddressOrderField.PostalCode.toLowerCase(), CaseStyle.Title),
		value: AddressOrderField.PostalCode,
	},
	{
		label: convertCase(AddressOrderField.StateOrProvince.toLowerCase(), CaseStyle.Title),
		value: AddressOrderField.StateOrProvince,
	},
	{ label: convertCase(AddressOrderField.Country.toLowerCase(), CaseStyle.Title), value: AddressOrderField.Country },
];

/**
 * Array of values for the "field" field in the order form of the Address model.
 */
export const fieldValues = fieldOptions.map((e) => e.value);

/**
 * Mapping from order fields of the Address model to their corresponding order type.
 */
export const fieldTypeMapping = {
	[AddressOrderField.AddressLine1]: IndexForms.OrderFieldType.String,
	[AddressOrderField.AddressLine2]: IndexForms.OrderFieldType.String,
	[AddressOrderField.City]: IndexForms.OrderFieldType.String,
	[AddressOrderField.PostalCode]: IndexForms.OrderFieldType.String,
	[AddressOrderField.StateOrProvince]: IndexForms.OrderFieldType.String,
	[AddressOrderField.Country]: IndexForms.OrderFieldType.String,
};

/**
 * Mapping from order fields of the Address model to their corresponding field name.
 */
export const orderFieldToModelField = {
	[AddressOrderField.AddressLine1]: "addressLine1",
	[AddressOrderField.AddressLine2]: "addressLine2",
	[AddressOrderField.City]: "city",
	[AddressOrderField.PostalCode]: "postalCode",
	[AddressOrderField.StateOrProvince]: "stateOrProvince",
	[AddressOrderField.Country]: "country",
};

/**
 * Mapping from field names of the Address model to their corresponding order field.
 */
export const modelFieldToOrderField = {
	addressLine1: AddressOrderField.AddressLine1,
	addressLine2: AddressOrderField.AddressLine2,
	city: AddressOrderField.City,
	postalCode: AddressOrderField.PostalCode,
	stateOrProvince: AddressOrderField.StateOrProvince,
	country: AddressOrderField.Country,
};
