import moment from "moment";
import { Calendar, CalendarProps, Event, momentLocalizer } from "react-big-calendar";
import { makeClassName } from "@hex-insights/core";
import { getEventColor } from "../../Utilities";
import "./styles.css";

const localizer = momentLocalizer(moment);

export type CalendarItem = Event & {
	original?: OriginalCalendarItem;
};

export type OriginalCalendarItem = {
	isPublic?: boolean;
	groups: { color: string }[];
};

export type BigCalendarProps = {
	events?: CalendarItem[];
	containerClassName?: string;
	containerStyle?: React.CSSProperties;
} & Omit<CalendarProps, "localizer" | "events">;

export function BigCalendar({
	scrollToTime = new Date(),
	containerClassName,
	containerStyle,
	...props
}: BigCalendarProps) {
	return (
		<div className={makeClassName("verita-big-calendar", containerClassName)} style={containerStyle}>
			<Calendar localizer={localizer} scrollToTime={scrollToTime} eventPropGetter={getEventStyle} {...props} />
		</div>
	);
}

function getEventStyle(event: Event) {
	const originalEvent = (event as any).original as OriginalCalendarItem;
	const eventColor = getEventColor(
		originalEvent ? { isPublic: originalEvent.isPublic ?? false, groups: originalEvent.groups } : { isPublic: true },
	);
	// TODO handling for text color
	return {
		style: {
			backgroundColor: eventColor,
		},
	};
}
