import React from "react";
import { DateTimeField, FieldDisplayArgs, NumberField, SelectField } from "@hex-insights/forms";
import {
	ContractInstallmentFormValues,
	ContractSelect,
	InvoiceSelect,
	useContractSelectLazyQuery,
	useInvoiceSelectLazyQuery,
} from "../../../../Utilities";
import { ContractLink, InvoiceLink } from "../../../Links";
import { BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the ContractInstallment model.
 */
type FieldProps<K extends keyof ContractInstallmentFormValues.Base = keyof ContractInstallmentFormValues.Base> =
	BaseFieldProps<Pick<ContractInstallmentFormValues.Base, K>>;

/**
 * Generic props for fields of the ContractInstallment model that only appear in the detail form.
 */
type DetailFieldProps<
	K extends keyof ContractInstallmentFormValues.Detail = keyof ContractInstallmentFormValues.Detail,
> = BaseFieldProps<Pick<ContractInstallmentFormValues.Detail, K>>;

/**
 * Renders a field component for the `installmentDate` field of the ContractInstallment model.
 */
export function InstallmentDate({ formState }: FieldProps<"installmentDate">) {
	return <DateTimeField formState={formState} name="installmentDate" precision="day" />;
}

/**
 * Renders a field component for the `portion` field of the ContractInstallment model.
 */
export function Portion({ formState, disabled }: FieldProps<"portion"> & { disabled?: boolean }) {
	return (
		<NumberField
			formState={formState}
			name="portion"
			label="Relative Portion"
			hint="The portion of total payment to be made on this date. The fraction of payment is relative to the total portion for all other installments on this contract."
			validationUnit={1}
			min={0}
			disabled={disabled}
		/>
	);
}

export type ContractProps = FieldProps<"contractID"> & {
	currentContract?: ContractSelect.ModelForOption | null;
	disabled?: boolean;
};

/**
 * Renders a field component for the `contract` edge of the ContractInstallment model.
 */
export function Contract({ formState, currentContract, disabled }: ContractProps) {
	const [loadOptions, { loading, data }] = useContractSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.contractID) {
			loadOptions();
		}
	}, [formState.formEditing.contractID, loadOptions]);
	const options = React.useMemo(
		() => ContractSelect.toOptions(data?.contractConnection.edges, currentContract),
		[data, currentContract],
	);

	return (
		<SelectField
			formState={formState}
			name="contractID"
			isLoading={loading}
			options={options}
			display={displayContract}
			blankValue={null}
			disabled={disabled}
		/>
	);
}

function displayContract({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <ContractLink instance={{ id }}>{formattedValue}</ContractLink>;
}

export type InvoiceProps = DetailFieldProps<"invoiceID"> & {
	currentInvoice?: InvoiceSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `invoice` edge of the InvoiceLineItem model.
 */
export function Invoice({ formState, currentInvoice }: InvoiceProps) {
	const [loadOptions, { loading, data }] = useInvoiceSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.invoiceID) {
			loadOptions();
		}
	}, [formState.formEditing.invoiceID, loadOptions]);
	const options = React.useMemo(
		() => InvoiceSelect.toOptions(data?.invoiceConnection.edges, currentInvoice),
		[data, currentInvoice],
	);

	return (
		<SelectField
			formState={formState}
			name="invoiceID"
			isLoading={loading}
			options={options}
			display={displayInvoice}
			blankValue={null}
			optional
		/>
	);
}

function displayInvoice({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <InvoiceLink instance={{ id }}>{formattedValue}</InvoiceLink>;
}
