import React from "react";
import {
	FieldDisplayArgs,
	FormType,
	NumberField,
	RadioButtonsInput,
	RadioField,
	SelectField,
	TextField,
} from "@hex-insights/forms";
import {
	PersonSelect,
	StudentFormValues,
	TuitionSelect,
	usePersonSelectLazyQuery,
	useTuitionSelectLazyQuery,
} from "../../../../Utilities";
import { PersonLink, TuitionLink } from "../../../Links";
import { TextAreaField } from "../../../TextAreaField";
import { BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the Student model.
 */
type FieldProps<K extends keyof StudentFormValues.Base = keyof StudentFormValues.Base> = BaseFieldProps<
	Pick<StudentFormValues.Base, K>
>;

/**
 * Generic props for fields of the Student model that only appear in the detail form.
 */
type DetailFieldProps<K extends keyof StudentFormValues.Detail = keyof StudentFormValues.Detail> = BaseFieldProps<
	Pick<StudentFormValues.Detail, K>
>;

/**
 * Renders a field component for the `studentIDNumber` field of the Student model.
 */
export function StudentIDNumber({ formState }: DetailFieldProps<"studentIDNumber">) {
	return <TextField formState={formState} name="studentIDNumber" />;
}

/**
 * Renders a field component for the `graduatingYear` field of the Student model.
 */
export function GraduatingYear({ formState }: FieldProps<"graduatingYear">) {
	return <NumberField formState={formState} name="graduatingYear" validationUnit={1} />;
}

/**
 * Renders a field component for the `hasPreviousSchooling` field of the Student model.
 */
export function HasPreviousSchooling({ formState }: FieldProps<"hasPreviousSchooling">) {
	return (
		<RadioField
			formState={formState}
			name="hasPreviousSchooling"
			options={StudentFormValues.hasPreviousSchoolingOptions}
			noClear
			Input={RadioButtonsInput}
		/>
	);
}

/**
 * Renders a field component for the `previousSchoolInformation` field of the Student model.
 */
export function PreviousSchoolInformation({ formState }: FieldProps<"previousSchoolInformation">) {
	return <TextField formState={formState} name="previousSchoolInformation" optional />;
}

/**
 * Renders a field component for the `previousSchoolLocation` field of the Student model.
 */
export function PreviousSchoolLocation({ formState }: FieldProps<"previousSchoolLocation">) {
	return <TextField formState={formState} name="previousSchoolLocation" optional />;
}

/**
 * Renders a field component for the `allergies` field of the Student model.
 */
export function Allergies({ formState }: FieldProps<"allergies">) {
	return <TextField formState={formState} name="allergies" optional />;
}

/**
 * Renders a field component for the `asthma` field of the Student model.
 */
export function Asthma({ formState, formType = FormType.Update }: FieldProps<"asthma">) {
	return (
		<RadioField
			formState={formState}
			name="asthma"
			options={StudentFormValues.asthmaOptions}
			blankValue={null}
			optional={FormType.isCreate(formType)}
		/>
	);
}

/**
 * Renders a field component for the `medication` field of the Student model.
 */
export function Medication({ formState }: FieldProps<"medication">) {
	return <TextField formState={formState} name="medication" optional />;
}

/**
 * Renders a field component for the `medicationSchedule` field of the Student model.
 */
export function MedicationSchedule({ formState }: FieldProps<"medicationSchedule">) {
	return <TextField formState={formState} name="medicationSchedule" optional />;
}

/**
 * Renders a field component for the `dietaryRestrictions` field of the Student model.
 */
export function DietaryRestrictions({ formState }: FieldProps<"dietaryRestrictions">) {
	return <TextField formState={formState} name="dietaryRestrictions" optional />;
}

/**
 * Renders a field component for the `healthNotes` field of the Student model.
 */
export function HealthNotes({ formState }: FieldProps<"healthNotes">) {
	return <TextAreaField formState={formState} name="healthNotes" optional />;
}

/**
 * Renders a field component for the `doctorName` field of the Student model.
 */
export function DoctorName({ formState }: FieldProps<"doctorName">) {
	return <TextField formState={formState} name="doctorName" optional />;
}

/**
 * Renders a field component for the `doctorPhoneNumber` field of the Student model.
 */
export function DoctorPhoneNumber({ formState }: FieldProps<"doctorPhoneNumber">) {
	return <TextField formState={formState} name="doctorPhoneNumber" optional />;
}

/**
 * Renders a field component for the `emergencyContactInformation` field of the Student model.
 */
export function EmergencyContactInformation({ formState }: FieldProps<"emergencyContactInformation">) {
	return <TextAreaField formState={formState} name="emergencyContactInformation" optional />;
}

export type PersonProps = FieldProps<"personID"> & {
	currentPerson?: PersonSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `person` edge of the Student model.
 */
export function Person({ formState, currentPerson }: PersonProps) {
	const [loadOptions, { loading, data }] = usePersonSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.personID) {
			loadOptions();
		}
	}, [formState.formEditing.personID, loadOptions]);
	const options = React.useMemo(
		() => PersonSelect.toOptions(data?.personConnection.edges, currentPerson),
		[data, currentPerson],
	);

	return (
		<SelectField
			formState={formState}
			name="personID"
			label="Person Record"
			isLoading={loading}
			options={options}
			display={displayPerson}
			blankValue={null}
		/>
	);
}

function displayPerson({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <PersonLink instance={{ id }}>{formattedValue}</PersonLink>;
}

export type TuitionProps = FieldProps<"tuitionID"> & {
	currentTuition?: TuitionSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `tuition` edge of the Student model.
 */
export function Tuition({ formState, currentTuition }: TuitionProps) {
	const [loadOptions, { loading, data }] = useTuitionSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.tuitionID) {
			loadOptions();
		}
	}, [formState.formEditing.tuitionID, loadOptions]);
	const options = React.useMemo(
		() => TuitionSelect.toOptions(data?.tuitionConnection.edges, currentTuition),
		[data, currentTuition],
	);

	return (
		<SelectField
			formState={formState}
			name="tuitionID"
			isLoading={loading}
			options={options}
			optional
			display={displayTuition}
			blankValue={null}
		/>
	);
}

function displayTuition({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <TuitionLink instance={{ id }}>{formattedValue}</TuitionLink>;
}
