import React from "react";
import {
	Button,
	Conditional,
	Drawer,
	DrawerProps,
	Else,
	Heading,
	Icon,
	If,
	Modal,
	ModalProps,
	RequiredKeys,
	Row,
	useToggle,
} from "@hex-insights/core";
import { PermissionRequired } from "@hex-insights/permissioning";
import { ExternalLink } from "@hex-insights/router";
import {
	permissions,
	StudentDocument,
	StudentDocumentFormValues,
	StudentDocumentMutation,
	useStudentDocumentDetailQuery,
} from "../../Utilities";
import { StudentDocumentDeleteButton } from "../DeleteButtons";
import { StudentDocumentForm } from "../Forms";
import styles from "./styles.module.css";

export type StudentDocumentModalProps = {
	id: StudentDocument["id"];
	url: string;
	contentType: string;
} & Pick<ModalProps, "ifRef" | "onClose">;

export function StudentDocumentModal({ id, url, contentType, ifRef, onClose }: StudentDocumentModalProps) {
	const { isOn: isEditModalOpen, toggle: toggleIsEditModalOpen } = useToggle(false);

	const isImage = contentType.startsWith("image");

	const random = React.useMemo(() => Math.random(), []);

	return (
		<Modal ifRef={ifRef} onClose={onClose} className={styles["student-document-modal"]}>
			<Modal.Body>
				<Conditional>
					<If condition={isImage}>
						<ExternalLink href={url}>
							{/* TODO image component */}
							<div style={{ width: "fit-content", height: "100%", margin: "0 auto" }}>
								<img
									src={url}
									className={styles["student-document-modal__display"]}
									style={{ width: "auto", height: "100%" }}
								/>
							</div>
						</ExternalLink>
					</If>
					<Else>
						<embed
							src={url + "?" + random} // for Safari bug where is content missing on remount
							type={contentType}
							className={styles["student-document-modal__display"]}
						/>
					</Else>
				</Conditional>
			</Modal.Body>
			<Modal.Footer>
				<Row justify="space-between">
					<Button variant="link" size="small" onClick={toggleIsEditModalOpen}>
						<Row justify="spaced-start" horizontalSpacing="0.25rem">
							<Icon.Settings size="1rem" /> Settings
						</Row>
					</Button>
					<Drawer.If condition={isEditModalOpen}>
						<EditDrawer studentDocumentID={id} onClose={toggleIsEditModalOpen} />
					</Drawer.If>

					<ExternalLink href={url}>
						<Row justify="spaced-start" horizontalSpacing="0.1rem" align="center">
							<span>View Externally</span> <Icon.ArrowUpRight size="1rem" />
						</Row>
					</ExternalLink>
				</Row>
			</Modal.Footer>
		</Modal>
	);
}

type EditDrawerProps = {
	studentDocumentID: StudentDocument["id"];
} & RequiredKeys<Pick<DrawerProps, "ifRef" | "onClose">, "onClose">;

function EditDrawer({ studentDocumentID, ifRef, onClose }: EditDrawerProps) {
	const { loading, data } = useStudentDocumentDetailQuery({ variables: { id: studentDocumentID } });
	const studentDocument = data?.studentDocument;

	const update = StudentDocumentMutation.useUpdate(studentDocumentID);
	const applyUpdate = React.useCallback(
		async (
			changedFormValues: Partial<StudentDocumentFormValues.Detail>,
			initialFormValues: StudentDocumentFormValues.Detail,
		) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<Drawer
			ifRef={ifRef}
			onClose={onClose}
			style={{ width: "fit-content", minWidth: "calc(var(--general__field---width) + 3rem)" }}
		>
			<Drawer.Header>
				<Heading level={2} noMargin>
					{studentDocument?.name ?? "Loading..."}
				</Heading>
			</Drawer.Header>
			<Drawer.Body>
				<Conditional>
					<If condition={loading}>Loading...</If>
					<Else>
						{studentDocument && (
							<StudentDocumentForm.ControlledDetail
								studentDocument={studentDocument}
								applyUpdate={applyUpdate}
								onSuccess={onClose}
							/>
						)}
					</Else>
				</Conditional>
			</Drawer.Body>

			<PermissionRequired requiredPermission={permissions.StudentDocument.Delete.Admin}>
				<Drawer.Footer>
					<Row justify="center" align="center">
						<StudentDocumentDeleteButton instanceID={studentDocumentID} onSuccess={onClose} size="small" />
					</Row>
				</Drawer.Footer>
			</PermissionRequired>
		</Drawer>
	);
}
