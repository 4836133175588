import { newPageInfo } from "@hex-insights/router";
import { studentHubDetailPageInfo, StudentHubDetailPageRouteParams } from "../pageinfo";

export type StudentHubDetailOverviewPageRouteParams = StudentHubDetailPageRouteParams;

export const studentHubDetailOverviewPageInfo = newPageInfo({
	name: "Student Detail Overview",
	title: "",
	description: "Detail overview page for a student.",
	exact: true,
	to: studentHubDetailPageInfo.to,
	path: studentHubDetailPageInfo.path,
	parent: studentHubDetailPageInfo,
});
