import React from "react";
import { ApplyCreateFunction, ApplyUpdateFunction, onCreateSubmit, onUpdateSubmit } from "@hex-insights/app-modules";
import { Button, Column, Heading, HeadingLevel, Icon, If, Row } from "@hex-insights/core";
import {
	anyFieldEditing,
	everyFieldEditing,
	FieldIf,
	Form,
	FormState,
	FormSubmitFunction,
	FormType,
	SubFormField,
	SubmissionStatus,
	SubmitButton,
} from "@hex-insights/forms";
import {
	EmailAddressFormValues,
	PersonDetailQuery,
	PersonFormConversion,
	PersonFormState,
	PersonFormValues,
	PhoneNumberFormValues,
} from "../../../../Utilities";
import { EmailAddressForm } from "../EmailAddress";
import { PhoneNumberForm } from "../PhoneNumber";
import * as Field from "./Field";

export type ControlledCreateProps = {
	applyCreate: ApplyCreateFunction<PersonFormValues.Create>;
	onSuccess: () => void;
	headingLevel?: HeadingLevel;
};

/**
 * Renders the create form of the Person model using an internally managed form state.
 */
export function ControlledCreate(props: ControlledCreateProps) {
	const formState = PersonFormState.useCreateFormState();

	return <Create formState={formState} {...props} />;
}

export type CreateProps = ControlledCreateProps & {
	formState: FormState<PersonFormValues.Create>;
};

/**
 * Renders the create form of the Person model using the given form state.
 */
export function Create({ formState, applyCreate, onSuccess, headingLevel = 2 }: CreateProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<PersonFormValues.Create>>(
		async ({ formValues }) => {
			return onCreateSubmit(formValues, applyCreate);
		},
		[applyCreate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	return (
		<Form name="person.create" formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)}>
			<Column justify="spaced-start">
				<GeneralCreateFields formState={formState} headingLevel={headingLevel} />
				<DemographicCreateFields formState={formState} headingLevel={headingLevel} />
				<ContactCreateFields formState={formState} headingLevel={headingLevel} />
				<LinkedRecordsCreateFields formState={formState} headingLevel={headingLevel} />

				<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
					Submit
				</SubmitButton>
			</Column>
		</Form>
	);
}

export type FormCreateFieldsProps<K extends keyof PersonFormValues.Create> = {
	formState: FormState<Pick<PersonFormValues.Create, K>>;
	headingLevel?: HeadingLevel;
	hiddenFields?: K[];
};

export function GeneralCreateFields({
	formState,
	hiddenFields,
}: FormCreateFieldsProps<"firstName" | "lastName" | "nickname" | "alternateNames" | "image">) {
	const hiddenFieldSet = new Set(hiddenFields);

	return (
		<Column justify="spaced-start">
			<FieldIf formState={formState} name="firstName" condition={!hiddenFieldSet.has("firstName")}>
				<Field.FirstName formState={formState} formType={FormType.Create} />
			</FieldIf>
			<FieldIf formState={formState} name="lastName" condition={!hiddenFieldSet.has("lastName")}>
				<Field.LastName formState={formState} formType={FormType.Create} />
			</FieldIf>
			<FieldIf formState={formState} name="nickname" condition={!hiddenFieldSet.has("nickname")}>
				<Field.Nickname formState={formState} formType={FormType.Create} />
			</FieldIf>
			<FieldIf formState={formState} name="alternateNames" condition={!hiddenFieldSet.has("alternateNames")}>
				<Field.AlternateNames formState={formState} formType={FormType.Create} />
			</FieldIf>
			<FieldIf formState={formState} name="image" condition={!hiddenFieldSet.has("image")}>
				<Field.Image formState={formState} formType={FormType.Create} />
			</FieldIf>
		</Column>
	);
}

export function DemographicCreateFields({
	formState,
	headingLevel = 2,
	hiddenFields,
}: FormCreateFieldsProps<
	"gender" | "dateOfBirth" | "occupation" | "nationality" | "primaryLanguage" | "englishLanguageFluency"
>) {
	const hiddenFieldSet = new Set(hiddenFields);

	return (
		<Column justify="spaced-start">
			<Heading level={headingLevel} noMargin>
				Demographic Information
			</Heading>
			<FieldIf formState={formState} name="gender" condition={!hiddenFieldSet.has("gender")}>
				<Field.Gender formState={formState} formType={FormType.Create} />
			</FieldIf>
			<FieldIf formState={formState} name="dateOfBirth" condition={!hiddenFieldSet.has("dateOfBirth")}>
				<Field.DateOfBirth formState={formState} formType={FormType.Create} />
			</FieldIf>
			<FieldIf formState={formState} name="occupation" condition={!hiddenFieldSet.has("occupation")}>
				<Field.Occupation formState={formState} formType={FormType.Create} />
			</FieldIf>
			<FieldIf formState={formState} name="nationality" condition={!hiddenFieldSet.has("nationality")}>
				<Field.Nationality formState={formState} formType={FormType.Create} />
			</FieldIf>
			<FieldIf formState={formState} name="primaryLanguage" condition={!hiddenFieldSet.has("primaryLanguage")}>
				<Field.PrimaryLanguage formState={formState} formType={FormType.Create} />
			</FieldIf>
			<FieldIf
				formState={formState}
				name="englishLanguageFluency"
				condition={!hiddenFieldSet.has("englishLanguageFluency")}
			>
				<Field.EnglishLanguageFluency formState={formState} formType={FormType.Create} />
			</FieldIf>
		</Column>
	);
}

export function ContactCreateFields({
	formState,
	headingLevel = 2,
	hiddenFields,
}: FormCreateFieldsProps<"addressIDs" | "emailAddresses" | "phoneNumbers">) {
	const hiddenFieldSet = new Set(hiddenFields);

	return (
		<Column justify="spaced-start">
			<Heading level={headingLevel} noMargin>
				Contact Information
			</Heading>
			<FieldIf formState={formState} name="addressIDs" condition={!hiddenFieldSet.has("addressIDs")}>
				<Field.Addresses formState={formState} formType={FormType.Create} />
			</FieldIf>
			<FieldIf formState={formState} name="emailAddresses" condition={!hiddenFieldSet.has("emailAddresses")}>
				<SubFormField
					formState={formState}
					name="emailAddresses"
					blankItem={EmailAddressFormValues.initialCreateInPerson}
					dividerElement={<div style={{ marginBottom: "0.75rem" }} />}
				>
					{({ formState, onRemoveClick }) => (
						<Row justify="spaced-start">
							<EmailAddressForm.CreateInPersonFields formState={formState} />
							<Button
								variant="tertiary"
								size="small"
								onClick={onRemoveClick}
								disabled={formState.disabled}
								style={{ marginTop: "2rem" }}
							>
								<Icon.Trash size="1rem" style={{ display: "block" }} />
							</Button>
						</Row>
					)}
					{({ onClick, disabled }) => (
						<Row justify="center" style={{ marginTop: "0.75rem" }}>
							<Button variant="tertiary" size="small" onClick={onClick} disabled={disabled}>
								Add Email Address
							</Button>
						</Row>
					)}
				</SubFormField>
			</FieldIf>
			<FieldIf formState={formState} name="phoneNumbers" condition={!hiddenFieldSet.has("phoneNumbers")}>
				<SubFormField
					formState={formState}
					name="phoneNumbers"
					blankItem={PhoneNumberFormValues.initialCreateInPerson}
					dividerElement={<div style={{ marginBottom: "0.75rem" }} />}
				>
					{({ formState, onRemoveClick }) => (
						<Row justify="spaced-start">
							<PhoneNumberForm.CreateInPersonFields formState={formState} />
							<Button
								variant="tertiary"
								size="small"
								onClick={onRemoveClick}
								disabled={formState.disabled}
								style={{ marginTop: "2rem" }}
							>
								<Icon.Trash size="1rem" style={{ display: "block" }} />
							</Button>
						</Row>
					)}
					{({ onClick, disabled }) => (
						<Row justify="center" style={{ marginTop: "0.75rem" }}>
							<Button variant="tertiary" size="small" onClick={onClick} disabled={disabled}>
								Add Phone Number
							</Button>
						</Row>
					)}
				</SubFormField>
			</FieldIf>
		</Column>
	);
}

export function LinkedRecordsCreateFields({
	formState,
	headingLevel = 2,
	hiddenFields,
}: FormCreateFieldsProps<"userID">) {
	const hiddenFieldSet = new Set(hiddenFields);

	return (
		<Column justify="spaced-start">
			<Heading level={headingLevel} noMargin>
				Linked Records
			</Heading>
			<FieldIf formState={formState} name="userID" condition={!hiddenFieldSet.has("userID")}>
				<Field.User formState={formState} formType={FormType.Create} />
			</FieldIf>
		</Column>
	);
}

export type ControlledDetailProps = {
	person: PersonDetailQuery["person"];
	applyUpdate: ApplyUpdateFunction<PersonFormValues.Detail>;
	onSuccess: () => void;
	headingLevel?: HeadingLevel;
};

/**
 * Renders the detail form of the Person model using an internally managed form state.
 */
export function ControlledDetail(props: ControlledDetailProps) {
	const initialFormValues = React.useMemo(() => PersonFormConversion.toFormValues(props.person), [props.person]);
	const formState = PersonFormState.useDetailFormState({ initialFormValues });

	return <Detail formState={formState} {...props} />;
}

export type DetailProps = ControlledDetailProps & {
	formState: FormState<PersonFormValues.Detail>;
};

/**
 * Renders the detail form of the Person model using the given form state.
 */
export function Detail({ formState, person, applyUpdate, onSuccess, headingLevel = 2 }: DetailProps) {
	const onSubmit = React.useCallback<FormSubmitFunction<PersonFormValues.Detail>>(
		async (formState) => {
			return onUpdateSubmit(formState, applyUpdate);
		},
		[applyUpdate],
	);

	React.useEffect(() => {
		if (SubmissionStatus.isSuccess(formState.submissionStatus)) {
			onSuccess();
		}
	}, [formState.submissionStatus, onSuccess]);

	const { anyEditing, everyEditing } = React.useMemo(() => {
		return {
			anyEditing: anyFieldEditing({
				formEditing: formState.formEditing,
				formSubFormStates: formState.formSubFormStates,
			}),
			everyEditing: everyFieldEditing({
				formEditing: formState.formEditing,
				formSubFormStates: formState.formSubFormStates,
			}),
		};
	}, [formState.formEditing, formState.formSubFormStates]);

	return (
		<Form name={`person.detail.${person.id}`} formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)}>
			<Column justify="spaced-start">
				<Row justify="spaced-start" horizontalSpacing="0.75rem">
					<If condition={!everyEditing}>
						<Button variant="tertiary" size="small" onClick={() => formState.setFormEditing(true)}>
							Edit
						</Button>
					</If>
					<If condition={anyEditing}>
						<Button variant="tertiary" size="small" onClick={() => formState.setFormEditing(false)}>
							Stop Editing
						</Button>
					</If>
				</Row>

				<GeneralDetailFields formState={formState} headingLevel={headingLevel} person={person} />
				<DemographicDetailFields formState={formState} headingLevel={headingLevel} person={person} />
				<ContactDetailFields formState={formState} headingLevel={headingLevel} person={person} />
				<LinkedRecordsDetailFields formState={formState} headingLevel={headingLevel} person={person} />
			</Column>
		</Form>
	);
}

export type FormDetailFieldsProps<K extends keyof PersonFormValues.Detail> = {
	person: PersonDetailQuery["person"];
	formState: FormState<Pick<PersonFormValues.Detail, K>>;
	headingLevel?: HeadingLevel;
	hiddenFields?: K[];
};

export function GeneralDetailFields({
	person,
	formState,
	hiddenFields,
}: FormDetailFieldsProps<"name" | "firstName" | "lastName" | "nickname" | "alternateNames" | "image">) {
	const hiddenFieldSet = new Set(hiddenFields);

	return (
		<Column justify="spaced-start">
			<FieldIf formState={formState} name="name" condition={!hiddenFieldSet.has("name")}>
				<Field.Name formState={formState} formType={FormType.Update} id={person.id} />
			</FieldIf>
			<FieldIf formState={formState} name="firstName" condition={!hiddenFieldSet.has("firstName")}>
				<Field.FirstName formState={formState} formType={FormType.Update} id={person.id} />
			</FieldIf>
			<FieldIf formState={formState} name="lastName" condition={!hiddenFieldSet.has("lastName")}>
				<Field.LastName formState={formState} formType={FormType.Update} id={person.id} />
			</FieldIf>
			<FieldIf formState={formState} name="nickname" condition={!hiddenFieldSet.has("nickname")}>
				<Field.Nickname formState={formState} formType={FormType.Update} id={person.id} />
			</FieldIf>
			<FieldIf formState={formState} name="alternateNames" condition={!hiddenFieldSet.has("alternateNames")}>
				<Field.AlternateNames formState={formState} formType={FormType.Update} id={person.id} />
			</FieldIf>
			<FieldIf formState={formState} name="image" condition={!hiddenFieldSet.has("image")}>
				<Field.Image formState={formState} formType={FormType.Update} id={person.id} />
			</FieldIf>
		</Column>
	);
}

export function DemographicDetailFields({
	person,
	formState,
	headingLevel = 2,
	hiddenFields,
}: FormDetailFieldsProps<
	"gender" | "dateOfBirth" | "occupation" | "nationality" | "primaryLanguage" | "englishLanguageFluency"
>) {
	const hiddenFieldSet = new Set(hiddenFields);

	return (
		<Column justify="spaced-start">
			<Heading level={headingLevel} noMargin>
				Demographic Information
			</Heading>
			<FieldIf formState={formState} name="gender" condition={!hiddenFieldSet.has("gender")}>
				<Field.Gender formState={formState} formType={FormType.Update} id={person.id} />
			</FieldIf>
			<FieldIf formState={formState} name="dateOfBirth" condition={!hiddenFieldSet.has("dateOfBirth")}>
				<Field.DateOfBirth formState={formState} formType={FormType.Update} id={person.id} />
			</FieldIf>
			<FieldIf formState={formState} name="occupation" condition={!hiddenFieldSet.has("occupation")}>
				<Field.Occupation formState={formState} formType={FormType.Update} id={person.id} />
			</FieldIf>
			<FieldIf formState={formState} name="nationality" condition={!hiddenFieldSet.has("nationality")}>
				<Field.Nationality formState={formState} formType={FormType.Update} id={person.id} />
			</FieldIf>
			<FieldIf formState={formState} name="primaryLanguage" condition={!hiddenFieldSet.has("primaryLanguage")}>
				<Field.PrimaryLanguage formState={formState} formType={FormType.Update} id={person.id} />
			</FieldIf>
			<FieldIf
				formState={formState}
				name="englishLanguageFluency"
				condition={!hiddenFieldSet.has("englishLanguageFluency")}
			>
				<Field.EnglishLanguageFluency formState={formState} formType={FormType.Update} id={person.id} />
			</FieldIf>
		</Column>
	);
}

export function ContactDetailFields({
	person,
	formState,
	headingLevel = 2,
	hiddenFields,
}: FormDetailFieldsProps<"addressIDs" | "emailAddressIDs" | "phoneNumberIDs">) {
	const hiddenFieldSet = new Set(hiddenFields);

	return (
		<Column justify="spaced-start">
			<Heading level={headingLevel} noMargin>
				Contact Information
			</Heading>
			<FieldIf formState={formState} name="addressIDs" condition={!hiddenFieldSet.has("addressIDs")}>
				<Field.Addresses
					formState={formState}
					formType={FormType.Update}
					id={person.id}
					currentAddresses={person.addresses}
				/>
			</FieldIf>
			<FieldIf formState={formState} name="emailAddressIDs" condition={!hiddenFieldSet.has("emailAddressIDs")}>
				<Field.EmailAddresses
					formState={formState}
					formType={FormType.Update}
					id={person.id}
					currentEmailAddresses={person.emailAddresses}
				/>
			</FieldIf>
			<FieldIf formState={formState} name="phoneNumberIDs" condition={!hiddenFieldSet.has("phoneNumberIDs")}>
				<Field.PhoneNumbers
					formState={formState}
					formType={FormType.Update}
					id={person.id}
					currentPhoneNumbers={person.phoneNumbers}
				/>
			</FieldIf>
		</Column>
	);
}

export function LinkedRecordsDetailFields({
	person,
	formState,
	headingLevel = 2,
	hiddenFields,
}: FormDetailFieldsProps<"employeeID" | "studentID" | "parentID" | "userID">) {
	const hiddenFieldSet = new Set(hiddenFields);

	return (
		<Column justify="spaced-start">
			<Heading level={headingLevel} noMargin>
				Linked Records
			</Heading>
			<FieldIf formState={formState} name="employeeID" condition={!hiddenFieldSet.has("employeeID")}>
				<Field.Employee
					formState={formState}
					formType={FormType.Update}
					id={person.id}
					currentEmployee={person.employee}
				/>
			</FieldIf>
			<FieldIf formState={formState} name="studentID" condition={!hiddenFieldSet.has("studentID")}>
				<Field.Student
					formState={formState}
					formType={FormType.Update}
					id={person.id}
					currentStudent={person.student}
				/>
			</FieldIf>
			<FieldIf formState={formState} name="parentID" condition={!hiddenFieldSet.has("parentID")}>
				<Field.Parent formState={formState} formType={FormType.Update} id={person.id} currentParent={person.parent} />
			</FieldIf>
			<FieldIf formState={formState} name="userID" condition={!hiddenFieldSet.has("userID")}>
				<Field.User formState={formState} formType={FormType.Update} id={person.id} currentUser={person.user} />
			</FieldIf>
		</Column>
	);
}

export type ControlledReadOnlyProps = {
	person: PersonDetailQuery["person"];
	headingLevel?: HeadingLevel;
};

/**
 * Renders a read-only detail form of the Person model using an internally managed form state.
 */
export function ControlledReadOnly(props: ControlledReadOnlyProps) {
	const initialFormValues = React.useMemo(() => PersonFormConversion.toFormValues(props.person), [props.person]);
	const formState = PersonFormState.useReadOnlyFormState({ initialFormValues });

	return <ReadOnly formState={formState} {...props} />;
}

export type ReadOnlyProps = ControlledReadOnlyProps & {
	formState: FormState<PersonFormValues.Detail>;
};

/**
 * Renders a read-only detail form of the Person model using the given form state.
 */
export function ReadOnly({ formState, person, headingLevel = 2 }: ReadOnlyProps) {
	return (
		<Column justify="spaced-start">
			<Field.Name formState={formState} formType={FormType.Update} id={person.id} />
			<Field.FirstName formState={formState} formType={FormType.Update} id={person.id} />
			<Field.LastName formState={formState} formType={FormType.Update} id={person.id} />
			<Field.Nickname formState={formState} formType={FormType.Update} id={person.id} />
			<Field.AlternateNames formState={formState} formType={FormType.Update} id={person.id} />

			<Heading level={headingLevel} noMargin>
				Demographic Information
			</Heading>
			<Field.Gender formState={formState} formType={FormType.Update} id={person.id} />
			<Field.DateOfBirth formState={formState} formType={FormType.Update} id={person.id} />
			<Field.Occupation formState={formState} formType={FormType.Update} id={person.id} />
			<Field.Nationality formState={formState} formType={FormType.Update} id={person.id} />
			<Field.PrimaryLanguage formState={formState} formType={FormType.Update} id={person.id} />
			<Field.EnglishLanguageFluency formState={formState} formType={FormType.Update} id={person.id} />

			<Heading level={headingLevel} noMargin>
				Contact Information
			</Heading>
			<Field.Addresses
				formState={formState}
				formType={FormType.Update}
				id={person.id}
				currentAddresses={person.addresses}
			/>
			<Field.EmailAddresses
				formState={formState}
				formType={FormType.Update}
				id={person.id}
				currentEmailAddresses={person.emailAddresses}
			/>
			<Field.PhoneNumbers
				formState={formState}
				formType={FormType.Update}
				id={person.id}
				currentPhoneNumbers={person.phoneNumbers}
			/>

			<Heading level={headingLevel} noMargin>
				Linked Records
			</Heading>
			<Field.Employee
				formState={formState}
				formType={FormType.Update}
				id={person.id}
				currentEmployee={person.employee}
			/>
			<Field.Student formState={formState} formType={FormType.Update} id={person.id} currentStudent={person.student} />
			<Field.Parent formState={formState} formType={FormType.Update} id={person.id} currentParent={person.parent} />
			<Field.User formState={formState} formType={FormType.Update} id={person.id} currentUser={person.user} />
		</Column>
	);
}
