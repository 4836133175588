import { IndexForms } from "@hex-insights/app-modules";
import { TypeFilterObject } from "@hex-insights/core";

export type QuickFilterFormValues = {}; // TODO define quick filters

export type FormValues = QuickFilterFormValues & IndexForms.FilterFormValues;

export const initialQuickFilters: QuickFilterFormValues = {};

export const initial: FormValues = {
	...initialQuickFilters,
	...IndexForms.initialFilterFormValues,
};

export function getInitial(): FormValues {
	return initial;
}

export const quickFiltersTypeFilterObject: TypeFilterObject<QuickFilterFormValues> = {};

export const typeFilterObject: TypeFilterObject<FormValues> = {
	...quickFiltersTypeFilterObject,
	...IndexForms.filterFormValuesFilterObject,
};

export const advancedFilterInformation: IndexForms.AdvancedFilterInformation = {
	fieldOptions: [
		{ value: "year" },
		{ value: "nurseryPrice" },
		{ value: "receptionPrice" },
		{ value: "primaryPrice" },
		{ value: "secondaryPrice" },
	],
	fieldComparisonOptions: {
		year: IndexForms.numberComparisonOptions,
		nurseryPrice: IndexForms.numberComparisonOptions,
		receptionPrice: IndexForms.numberComparisonOptions,
		primaryPrice: IndexForms.numberComparisonOptions,
		secondaryPrice: IndexForms.numberComparisonOptions,
	},
	fieldComparisonTranslations: {
		year: IndexForms.numberComparisonTranslations,
		nurseryPrice: IndexForms.numberComparisonTranslations,
		receptionPrice: IndexForms.numberComparisonTranslations,
		primaryPrice: IndexForms.numberComparisonTranslations,
		secondaryPrice: IndexForms.numberComparisonTranslations,
	},
};
