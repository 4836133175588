import { Models } from "@hex-insights/app-modules";
import { DistributiveOmit } from "@hex-insights/core";
import { Contract, ContractFormat, ContractNameFragment } from "../../../../Utilities";

type ModelForLink = Pick<Contract, "id"> & ContractNameFragment;

export type ContractLinkProps = DistributiveOmit<Models.LinkProps<ModelForLink>, "pageInfoKey" | "format">;

/**
 * Renders a link to the given Contract instance.
 */
export function ContractLink(props: ContractLinkProps) {
	return <Models.Link<ModelForLink> pageInfoKey="Contract" format={ContractFormat.name} {...props} />;
}
