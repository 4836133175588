import React from "react";
import { useUpdatingRef } from "@hex-insights/core";
import { Student, TimelineFilterInput, useStudentTimelineQuery, useTimelineQuery } from "../../Utilities";

export type UsePaginatedTimelineQueryArgs = {
	filter?: Omit<TimelineFilterInput, "limit" | "offset">;
	limit?: number;
};

export function usePaginatedTimelineQuery({ filter, limit = 25 }: UsePaginatedTimelineQueryArgs = {}) {
	const [lastOffset, setLastOffset] = React.useState(0);
	const [hasMore, setHasMore] = React.useState(true);

	const { fetchMore, ...queryResult } = useTimelineQuery({
		variables: {
			filter: {
				...filter,
				limit,
			},
		},
		notifyOnNetworkStatusChange: true,
		fetchPolicy: "cache-and-network",
	});

	const { data } = queryResult;
	React.useEffect(() => {
		if (!data) {
			return;
		}
		if (data.timeline.length < limit) {
			setHasMore(false);
		}
	}, [data, limit]);

	const filterRef = useUpdatingRef(filter);

	const onLoadMoreClick = React.useCallback(() => {
		const offset = lastOffset + limit;
		setLastOffset(offset);
		fetchMore({
			variables: {
				filter: {
					...filterRef.current,
					limit,
					offset,
				},
			},
			updateQuery: (previousQueryResult, { fetchMoreResult }) => {
				if (fetchMoreResult.timeline.length < limit) {
					setHasMore(false);
				}
				return {
					...previousQueryResult,
					timeline: [...previousQueryResult.timeline, ...fetchMoreResult.timeline],
				};
			},
		});
	}, [lastOffset, limit, fetchMore, filterRef]);

	return { ...queryResult, hasMore, onLoadMoreClick };
}

export type UsePaginatedStudentTimelineQueryArgs = UsePaginatedTimelineQueryArgs & {
	studentID: Student["id"];
};

export function usePaginatedStudentTimelineQuery({
	studentID,
	filter,
	limit = 25,
}: UsePaginatedStudentTimelineQueryArgs) {
	const [lastOffset, setLastOffset] = React.useState(0);
	const [hasMore, setHasMore] = React.useState(true);

	const { fetchMore, ...queryResult } = useStudentTimelineQuery({
		variables: {
			studentID,
			filter: {
				...filter,
				limit,
			},
		},
		notifyOnNetworkStatusChange: true,
		fetchPolicy: "cache-and-network",
	});

	const { data } = queryResult;
	React.useEffect(() => {
		if (!data) {
			return;
		}
		if (data.studentTimeline.length < limit) {
			setHasMore(false);
		}
	}, [data, limit]);

	const filterRef = useUpdatingRef(filter);

	const onLoadMoreClick = React.useCallback(() => {
		const offset = lastOffset + limit;
		setLastOffset(offset);
		fetchMore({
			variables: {
				filter: {
					...filterRef.current,
					limit,
					offset,
				},
			},
			updateQuery: (previousQueryResult, { fetchMoreResult }) => {
				if (fetchMoreResult.studentTimeline.length < limit) {
					setHasMore(false);
				}
				return {
					...previousQueryResult,
					studentTimeline: [...previousQueryResult.studentTimeline, ...fetchMoreResult.studentTimeline],
				};
			},
		});
	}, [lastOffset, limit, fetchMore, filterRef]);

	return { ...queryResult, hasMore, onLoadMoreClick };
}
