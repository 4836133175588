import { RadioInputOption } from "@hex-insights/forms";
import {
	Course,
	CourseSection,
	DocumentResource,
	DocumentResourceFolder,
	DocumentResourceFolderCategory,
	HomeRoom,
	HomeRoomSection,
	User,
	UserGroup,
} from "../../GraphQL";

export type Base = {
	name: DocumentResourceFolder["name"];
	description: DocumentResourceFolder["description"];
	category: DocumentResourceFolder["category"] | null;
	isPublic: DocumentResourceFolder["isPublic"];
	parentDocumentResourceFolderID: DocumentResourceFolder["id"] | null;
	homeRoomIDs: HomeRoom["id"][];
	homeRoomSectionIDs: HomeRoomSection["id"][];
	courseIDs: Course["id"][];
	courseSectionIDs: CourseSection["id"][];
	sharingUserGroupIDs: UserGroup["id"][];
	sharingUserIDs: User["id"][];
};

export type Create = Base;

export type Detail = Base & {
	childDocumentResourceFolderIDs: DocumentResourceFolder["id"][];
	documentResourceIDs: DocumentResource["id"][];
	createdAt: DocumentResourceFolder["createdAt"] | null;
	authorID: User["id"] | null;
};

export const initialCreate: Create = {
	name: "",
	description: "",
	category: null,
	isPublic: false,
	parentDocumentResourceFolderID: null,
	homeRoomIDs: [],
	homeRoomSectionIDs: [],
	courseIDs: [],
	courseSectionIDs: [],
	sharingUserGroupIDs: [],
	sharingUserIDs: [],
};

export const categoryOptions = [
	{ value: DocumentResourceFolderCategory.Academic },
	{ value: DocumentResourceFolderCategory.Administrative },
	{ value: DocumentResourceFolderCategory.Financial },
	{ value: DocumentResourceFolderCategory.General },
];

export const isPublicOptions: RadioInputOption<boolean>[] = [
	{ value: true, label: "Public" },
	{ value: false, label: "Private" },
];
