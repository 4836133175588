import React from "react";
import { EditMode, FormType, useFormState } from "@hex-insights/forms";
import { CalendarEventFormValues } from "../ModelFormValues";

/**
 * Returns a `formState` object for use in the create form for the CalendarEvent model.
 */
export function useCreateFormState(initialFormValues?: Partial<CalendarEventFormValues.Create>) {
	return useFormState({
		initialFormValues: React.useMemo(
			() => ({ ...CalendarEventFormValues.initialCreate, ...initialFormValues }),
			[initialFormValues],
		),
		formType: FormType.Create,
	});
}

const detailFieldEditModes = {
	createdAt: EditMode.ReadOnly,
	authorID: EditMode.ReadOnly,
};

/**
 * Returns a `formState` object for use in the detail form of the CalendarEvent model.
 *
 * @param initialFormValues The initial values for the form. Generally from
 * {@link CalendarEventFormConversion.toFormValues}.
 */
export function useDetailFormState({ initialFormValues }: { initialFormValues: CalendarEventFormValues.Detail }) {
	return useFormState({
		initialFormValues: initialFormValues,
		formType: FormType.Update,
		fieldEditModes: detailFieldEditModes,
	});
}

/**
 * Returns a read-only `formState` object for use in the detail form of the CalendarEvent model.
 *
 * @param initialFormValues The initial values for the form. Generally from
 * {@link CalendarEventFormConversion.toFormValues}.
 */
export function useReadOnlyFormState({ initialFormValues }: { initialFormValues: CalendarEventFormValues.Detail }) {
	return useFormState({
		initialFormValues: initialFormValues,
		editMode: EditMode.ReadOnly,
	});
}
