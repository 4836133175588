import { Models } from "@hex-insights/app-modules";
import { DistributiveOmit } from "@hex-insights/core";
import { CourseSection, CourseSectionFormat, CourseSectionNameFragment } from "../../../../Utilities";

type ModelForLink = Pick<CourseSection, "id"> & CourseSectionNameFragment;

export type CourseSectionLinkProps = DistributiveOmit<Models.LinkProps<ModelForLink>, "pageInfoKey" | "format">;

/**
 * Renders a link to the given CourseSection instance.
 */
export function CourseSectionLink(props: CourseSectionLinkProps) {
	return <Models.Link<ModelForLink> pageInfoKey="CourseSection" format={CourseSectionFormat.name} {...props} />;
}
