import { MealPlan, Student } from "../../GraphQL";

export type Base = {
	notes: MealPlan["notes"];
	studentID: Student["id"] | null;
};

export type Create = Base;

export type Detail = Base;

export const initialCreate: Create = {
	notes: "",
	studentID: null,
};
