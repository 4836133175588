import { Models } from "@hex-insights/app-modules";
import { DistributiveOmit } from "@hex-insights/core";
import { HomeRoom, HomeRoomFormat, HomeRoomNameFragment } from "../../../../Utilities";

type ModelForLink = Pick<HomeRoom, "id"> & HomeRoomNameFragment;

export type HomeRoomLinkProps = DistributiveOmit<Models.LinkProps<ModelForLink>, "pageInfoKey" | "format">;

/**
 * Renders a link to the given HomeRoom instance.
 */
export function HomeRoomLink(props: HomeRoomLinkProps) {
	return <Models.Link<ModelForLink> pageInfoKey="HomeRoom" format={HomeRoomFormat.name} {...props} />;
}
