import { Authentication } from "@hex-insights/app-modules";
import { requestAuthenticationInfo, serverRequest } from "@hex-insights/verita.shared";

export function TrustedDeviceController() {
	return (
		<Authentication.TrustedDeviceController
			requestAuthenticationInfo={requestAuthenticationInfo}
			requestCreateTrustedDevice={requestCreateTrustedDevice}
		/>
	);
}

async function requestCreateTrustedDevice(formValues: Authentication.TrustDeviceCreateFormValues) {
	const response = await serverRequest("/auth/trust/device", { method: "POST", body: JSON.stringify(formValues) });
	return response.ok;
}
