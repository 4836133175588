import { getArrayDiff, NullPartial, omitKeys } from "@hex-insights/core";
import {
	PersonCreateInput,
	PersonDetailQuery,
	PersonInEmployeeCreateInput,
	PersonInParentCreateInput,
	PersonInStudentCreateInput,
	PersonUpdateInput,
} from "../../GraphQL";
import { PersonFormValues } from "../ModelFormValues";
import * as EmailAddressFormConversion from "./email-address";
import * as PhoneNumberFormConversion from "./phone-number";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the Person model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(formValues: PersonFormValues.Create): PersonCreateInput {
	const inputFormValues = omitKeys(formValues, ["emailAddresses", "phoneNumbers"]);
	const input: NullPartial<PersonCreateInput> = { ...inputFormValues };
	if (formValues.emailAddresses) {
		input.emailAddresses = formValues.emailAddresses.map((e) => EmailAddressFormConversion.toGQLCreateInPersonInput(e));
	}
	if (formValues.phoneNumbers) {
		input.phoneNumbers = formValues.phoneNumbers.map((e) => PhoneNumberFormConversion.toGQLCreateInPersonInput(e));
	}
	return input as PersonCreateInput;
}

// TODO reuse logic for email and phone

type PersonInOtherCreateInput = PersonInEmployeeCreateInput | PersonInStudentCreateInput | PersonInParentCreateInput;

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the Person model in the Employee
 * model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInOtherInput(formValues: PersonFormValues.CreateInOther): PersonInOtherCreateInput {
	const inputFormValues = omitKeys(formValues, ["emailAddresses", "phoneNumbers"]);
	const input: NullPartial<PersonInOtherCreateInput> = { ...inputFormValues };
	if (formValues.emailAddresses) {
		input.emailAddresses = formValues.emailAddresses.map((e) => EmailAddressFormConversion.toGQLCreateInPersonInput(e));
	}
	if (formValues.phoneNumbers) {
		input.phoneNumbers = formValues.phoneNumbers.map((e) => PhoneNumberFormConversion.toGQLCreateInPersonInput(e));
	}
	return input as PersonInOtherCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the Person model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<PersonFormValues.Detail>,
	initialFormValues: Partial<PersonFormValues.Detail>,
): PersonUpdateInput {
	const inputFormValues = omitKeys(formValues, ["addressIDs", "emailAddressIDs", "phoneNumberIDs"]);
	const input: PersonUpdateInput = { ...inputFormValues };

	if (formValues.image === null) {
		input.clearImage = true;
	}

	if (formValues.dateOfBirth === null) {
		input.clearDateOfBirth = true;
	}

	if (formValues.employeeID === null) {
		input.employeeID = null;
		input.clearEmployee = true;
	}

	if (formValues.parentID === null) {
		input.parentID = null;
		input.clearParent = true;
	}

	if (formValues.studentID === null) {
		input.studentID = null;
		input.clearStudent = true;
	}

	if (formValues.userID === null) {
		input.userID = null;
		input.clearUser = true;
	}

	if (formValues.addressIDs !== undefined) {
		const { added, removed } = getArrayDiff(initialFormValues.addressIDs ?? [], formValues.addressIDs);
		input.addAddressIDs = added;
		input.removeAddressIDs = removed;
	}

	if (formValues.emailAddressIDs !== undefined) {
		const { added, removed } = getArrayDiff(initialFormValues.emailAddressIDs ?? [], formValues.emailAddressIDs);
		input.addEmailAddressIDs = added;
		input.removeEmailAddressIDs = removed;
	}

	if (formValues.phoneNumberIDs !== undefined) {
		const { added, removed } = getArrayDiff(initialFormValues.phoneNumberIDs ?? [], formValues.phoneNumberIDs);
		input.addPhoneNumberIDs = added;
		input.removePhoneNumberIDs = removed;
	}

	return input;
}

/**
 * Converts a Person instance to its corresponding detail form values.
 *
 * @param person The instance to convert.
 */
export function toFormValues(person: PersonDetailQuery["person"]): PersonFormValues.Detail {
	return {
		name: person.name,
		firstName: person.firstName,
		lastName: person.lastName,
		nickname: person.nickname,
		alternateNames: person.alternateNames,
		image: person.imageURL !== "" ? new File([], "image") : null,
		gender: person.gender,
		dateOfBirth: person.dateOfBirth,
		occupation: person.occupation,
		nationality: person.nationality,
		primaryLanguage: person.primaryLanguage,
		englishLanguageFluency: person.englishLanguageFluency,
		addressIDs: person.addresses.map((e) => e.id),
		emailAddressIDs: person.emailAddresses.map((e) => e.id),
		employeeID: person.employee?.id ?? null,
		parentID: person.parent?.id ?? null,
		phoneNumberIDs: person.phoneNumbers.map((e) => e.id),
		studentID: person.student?.id ?? null,
		userID: person.user?.id ?? null,
	};
}

export function toFormValuesInOther(person: PersonDetailQuery["person"]): PersonFormValues.DetailInOther {
	return omitKeys(toFormValues(person), ["employeeID", "parentID", "studentID"]);
}
