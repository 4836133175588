import { User } from "../../../GraphQL";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the email field of the User model.
 */
export function email(value: User["email"]): string {
	return toString(value);
}

/**
 * Formats the status field of the User model.
 */
export function status(value: User["status"] | null): string {
	return toString(value);
}

/**
 * Formats the passwordUpdatedAt field of the User model.
 */
export function passwordUpdatedAt(value: User["passwordUpdatedAt"] | null): string {
	return toString(value);
}
