import React from "react";
import { ApolloCache, FetchResult, gql } from "@apollo/client";
import {
	Contract,
	ContractInstallment,
	ContractInstallmentCreateInput,
	ContractInstallmentCreateMutation,
	ContractInstallmentDeleteMutation,
	ContractInstallmentDetailDocument,
	ContractInstallmentDetailQuery,
	ContractInstallmentDetailQueryVariables,
	ContractInstallmentUpdateInput,
	ContractInstallmentUpdateMutation,
	graphTypeNames,
	Invoice,
	useContractInstallmentCreateMutation,
	useContractInstallmentDeleteMutation,
	useContractInstallmentUpdateMutation,
} from "../../GraphQL";
import { ContractInstallmentFormConversion } from "../ModelFormConversion";
import { ContractInstallmentFormValues } from "../ModelFormValues";

/**
 * Returns a `create` function for the ContractInstallment model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the ContractInstallment model.
 */
export function useCreate() {
	const [createContractInstallment] = useContractInstallmentCreateMutation();

	return React.useCallback(
		async (formValues: ContractInstallmentFormValues.Create) => {
			const input = ContractInstallmentFormConversion.toGQLCreateInput(formValues);
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createContractInstallment({ variables: { input }, update: updateCache });

			return { data: data?.createContractInstallment ?? null, errors: errors ?? null };
		},
		[createContractInstallment],
	);
}

/**
 * Returns an `update` function for the ContractInstallment model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the ContractInstallment model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: ContractInstallment["id"]) {
	const [updateContractInstallment] = useContractInstallmentUpdateMutation();

	return React.useCallback(
		async (
			changedFormValues: Partial<ContractInstallmentFormValues.Detail>,
			initialFormValues: ContractInstallmentFormValues.Detail,
		) => {
			const input = ContractInstallmentFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

			const { data, errors } = await updateContractInstallment({ variables: { id, input }, update: updateCache });

			return { data: data?.updateContractInstallment ?? null, errors: errors ?? null };
		},
		[updateContractInstallment, id],
	);
}

/**
 * Returns a `del` function for the ContractInstallment model.
 *
 * @param id The ID of the instance to delete.
 */
export function useDelete(id: ContractInstallment["id"]) {
	const [deleteContractInstallment] = useContractInstallmentDeleteMutation();

	return React.useCallback(async () => {
		const updateCache = getUpdateCacheForDelete(id);

		const { data, errors } = await deleteContractInstallment({ variables: { id }, update: updateCache });

		return { data: data?.deleteContractInstallment ?? false, errors: errors ?? null };
	}, [deleteContractInstallment, id]);
}

function getUpdateCacheForCreate(input: ContractInstallmentCreateInput) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<ContractInstallmentCreateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const createdObject = result.data.createContractInstallment;

		cache.writeQuery<ContractInstallmentDetailQuery, ContractInstallmentDetailQueryVariables>({
			query: ContractInstallmentDetailDocument,
			data: { contractInstallment: createdObject },
			variables: { id: createdObject.id },
		});

		if (input.contractID) {
			addToContractInstallmentsOfContractCache(cache, input.contractID, createdObject);
		}

		if (input.invoiceID) {
			addToContractInstallmentOfInvoiceCache(cache, input.invoiceID, createdObject);
		}

		cache.evict({ id: "ROOT_QUERY", fieldName: "contractInstallmentConnection" });
	};
}

function getUpdateCacheForUpdate(
	input: ContractInstallmentUpdateInput,
	initialFormValues: ContractInstallmentFormValues.Detail,
) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<ContractInstallmentUpdateMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const updatedObject = result.data.updateContractInstallment;

		if (initialFormValues.contractID && input.contractID) {
			removeFromContractInstallmentsOfContractCache(cache, initialFormValues.contractID, updatedObject);
		}
		if (input.contractID) {
			addToContractInstallmentsOfContractCache(cache, input.contractID, updatedObject);
		}

		if (initialFormValues.invoiceID && (input.invoiceID || input.clearInvoice)) {
			removeFromContractInstallmentOfInvoiceCache(cache, initialFormValues.invoiceID, updatedObject);
		}
		if (input.invoiceID) {
			addToContractInstallmentOfInvoiceCache(cache, input.invoiceID, updatedObject);
		}
	};
}

function getUpdateCacheForDelete(id: ContractInstallment["id"]) {
	return (
		cache: ApolloCache<any>,
		result: Omit<FetchResult<ContractInstallmentDeleteMutation, Record<string, any>, Record<string, any>>, "context">,
	) => {
		if (!result.data?.deleteContractInstallment) {
			return;
		}

		cache.evict({ id: cache.identify({ id, __typename: graphTypeNames.ContractInstallment }) });
		cache.evict({ id: "ROOT_QUERY", fieldName: "contractInstallmentConnection" });
		cache.gc();
	};
}

function addToContractInstallmentsOfContractCache(
	cache: ApolloCache<any>,
	targetID: Contract["id"],
	object: Pick<ContractInstallment, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Contract }),
		fields: {
			contractInstallments: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromContractInstallmentsOfContractCache(
	cache: ApolloCache<any>,
	targetID: Contract["id"],
	object: Pick<ContractInstallment, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Contract }),
		fields: {
			contractInstallments: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function addToContractInstallmentOfInvoiceCache(
	cache: ApolloCache<any>,
	targetID: Invoice["id"],
	object: Pick<ContractInstallment, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Invoice }),
		fields: {
			contractInstallment: () => objectRef,
			contractInstallmentID: () => object.id,
		},
	});
}

function removeFromContractInstallmentOfInvoiceCache(
	cache: ApolloCache<any>,
	targetID: Invoice["id"],
	_object: Pick<ContractInstallment, "id">,
) {
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Invoice }),
		fields: {
			contractInstallment: () => null,
			contractInstallmentID: () => null,
		},
	});
}

function toCacheRef(cache: ApolloCache<any>, object: Pick<ContractInstallment, "id">) {
	return cache.writeFragment({
		fragment: gql`
			fragment ContractInstallmentRef on ContractInstallment {
				id
			}
		`,
		data: object,
	});
}
