import { EmailAddress } from "../../../GraphQL";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the email field of the EmailAddress model.
 */
export function email(value: EmailAddress["email"]): string {
	return toString(value);
}
