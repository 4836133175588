import React from "react";
import { CalendarTileProperties, DateCallback, OnChangeDateCallback, ViewCallback } from "react-calendar";
import {
	addTimeToDate,
	Button,
	cartesianProduct,
	Column,
	compareObjects,
	compareStringsAsc,
	Conditional,
	DateTime,
	dateTrunc,
	Else,
	englishCommaJoinStrings,
	formatDateTime,
	getLastDayInMonth,
	getObjectValues,
	Heading,
	Icon,
	If,
	isSameTime,
	Row,
	toGrammaticalNumber,
	toLocalDateString,
	Tooltip,
	useToggle,
} from "@hex-insights/core";
import {
	CalendarEvent,
	CalendarEventFilterInput,
	getEventColor,
	getEventGroupName,
	rangeDatedItemsToDateMap,
	useBirthdaysQuery,
	User,
} from "../../Utilities";
import { EventDetailModal } from "../EventDetailModal";
import { SmallCalendar, SmallCalendarProps } from "../SmallCalendar";
import { TextAreaFieldDisplay } from "../TextAreaField";
import styles from "./styles.module.css";

export type SmallCalendarItemGroup = {
	name: string;
	color: string;
};

export type SmallEventCalendarItem = Pick<
	CalendarEvent,
	"id" | "name" | "description" | "isAllDay" | "startTime" | "isPublic"
> & {
	endTime: CalendarEvent["endTime"] | null;
	groups?: SmallCalendarItemGroup[];
};

type SmallEventCalendarItemEdge = { node: SmallEventCalendarItem };

export type SmallEventCalendarProps = Omit<SmallCalendarProps, "value" | "tileContent"> & {
	value: Date;
	isLoading?: boolean;
	events?: SmallEventCalendarItem[] | SmallEventCalendarItemEdge[];
	onClickEvent?: (event: Pick<CalendarEvent, "id" | "name">) => void;
	numEventsInList?: number;
	withBirthdays?: boolean;
	containerClassName?: string;
	containerStyle?: React.CSSProperties;
};

export function SmallEventCalendar({
	value,
	isLoading,
	events,
	onClickEvent,
	numEventsInList = 3,
	withBirthdays = false,
	containerClassName,
	containerStyle,
	...props
}: SmallEventCalendarProps) {
	const dateEventMap = React.useMemo<Record<string, SmallEventCalendarItem[]>>(() => {
		if (!events) {
			return {};
		}
		return rangeDatedItemsToDateMap(events, "startTime", "endTime", (e) => e.isAllDay);
	}, [events]);

	const valueISO = toLocalDateString(value);
	const { data: birthdaysData } = useBirthdaysQuery({
		variables: {
			dates: [valueISO],
			filters: [
				{
					hasEmployee: true,
				},
				{
					hasStudent: true,
				},
			],
		},
	});

	const eventsOnSelectedDate = React.useMemo(() => {
		const events = dateEventMap[valueISO] ?? [];
		if (!withBirthdays || !birthdaysData || birthdaysData.personConnectionByBirthday.edges.length === 0) {
			return events;
		}
		const birthdaysEvent: SmallEventCalendarItem = {
			id: `birthdays-${valueISO}`,
			name: `${englishCommaJoinStrings(
				birthdaysData.personConnectionByBirthday.edges.map((e) => e.node.name + "'s"),
			)} birthday!`,
			description: "",
			isAllDay: true,
			startTime: valueISO,
			endTime: valueISO,
			isPublic: false,
			groups: [{ name: "Birthdays", color: "var(--verita-colors-orange)" }],
		};
		return [birthdaysEvent, ...events];
	}, [valueISO, dateEventMap, withBirthdays, birthdaysData]);

	const { isOn: isFullEventListShown, toggle: toggleIsFullEventListShown } = useToggle(false);

	const tileContent = React.useCallback(
		({ date, view }: CalendarTileProperties) => {
			if (view !== "month") {
				return null;
			}
			return <CalendarTileContent events={dateEventMap[toLocalDateString(date)] ?? []} />;
		},
		[dateEventMap],
	);

	const [selectedEvent, setSelectedEvent] = React.useState<Pick<CalendarEvent, "id" | "name"> | null>(null);
	const onSelectEvent = React.useCallback(
		(event: Pick<CalendarEvent, "id" | "name">) => {
			if (event.id.startsWith("birthday")) {
				return;
			}
			if (onClickEvent) {
				onClickEvent(event);
				return;
			}
			setSelectedEvent(event);
		},
		[onClickEvent],
	);

	return (
		<Column
			justify="spaced-start"
			className={containerClassName}
			style={
				{
					...containerStyle,
					"--num-events-in-list": numEventsInList,
					position: "relative",
					zIndex: 1,
				} as React.CSSProperties
			}
		>
			<Row justify="center" style={{ width: "100%" }}>
				<SmallCalendar value={value} tileContent={tileContent} {...props} />
			</Row>

			<div style={{ flexGrow: 1, width: "100%", height: "100%" }}>
				<Conditional>
					<If condition={isLoading ?? false}>
						<Row justify="center" align="center" className={styles["small-event-calendar__event-list-filler"]}>
							<span>Loading...</span>
						</Row>
					</If>
					<If condition={eventsOnSelectedDate.length === 0}>
						<Row justify="center" align="center" className={styles["small-event-calendar__event-list-filler"]}>
							<span>No Events to Show</span>
						</Row>
					</If>
					<Else>
						<Column justify="spaced-start" verticalSpacing="0.15rem" style={{ width: "100%", minWidth: 0 }}>
							<Conditional>
								<If condition={eventsOnSelectedDate.length <= numEventsInList}>
									{eventsOnSelectedDate.map((e) => (
										<SmallEventCalendar.EventListItem key={e.id} calendarEvent={e} onClick={() => onSelectEvent(e)} />
									))}
								</If>
								<Else>
									{eventsOnSelectedDate.slice(0, numEventsInList - 1).map((e) => (
										<SmallEventCalendar.EventListItem key={e.id} calendarEvent={e} onClick={() => onSelectEvent(e)} />
									))}
									<Row justify="center">
										<Button variant="link" onClick={toggleIsFullEventListShown} style={{ padding: 0 }}>
											+ {eventsOnSelectedDate.length - (numEventsInList - 1)} More
										</Button>
									</Row>
								</Else>
							</Conditional>
						</Column>

						<If condition={isFullEventListShown}>
							<Column justify="spaced-start" align="center" className={styles["small-event-calendar__full-event-list"]}>
								<Heading level={3} noMargin>
									{formatDateTime(value, "ddd, D MMM YYYY")}
								</Heading>

								<div className={styles["small-event-calendar__full-event-list__list__wrapper"]}>
									<Column
										justify="spaced-start"
										verticalSpacing="0.15rem"
										className={styles["small-event-calendar__full-event-list__list"]}
									>
										{eventsOnSelectedDate.map((e) => (
											<SmallEventCalendar.EventListItem key={e.id} calendarEvent={e} onClick={() => onSelectEvent(e)} />
										))}
									</Column>
								</div>

								<Row justify="center">
									<Button variant="link" onClick={toggleIsFullEventListShown} style={{ padding: 0 }}>
										Close
									</Button>
								</Row>
							</Column>
						</If>
					</Else>
				</Conditional>

				{selectedEvent !== null && (
					<EventDetailModal calendarEvent={selectedEvent} onClose={() => setSelectedEvent(null)} />
				)}
			</div>
		</Column>
	);
}

type CalendarTileContentProps = {
	events: Pick<SmallEventCalendarItem, "isPublic" | "groups">[];
};

function CalendarTileContent({ events }: CalendarTileContentProps) {
	const numEvents = events.length;

	const groups = React.useMemo(() => {
		const groupsMap: Record<string, SmallCalendarItemGroup> = {};
		for (let i = 0; i < events.length; i++) {
			const event = events[i];
			const groupName = getEventGroupName(event);
			const groupColor = getEventColor(event);
			const groupKey = `${groupName}-${groupColor}`;
			groupsMap[groupKey] = { name: groupName, color: groupColor };
		}
		return getObjectValues(groupsMap).sort(compareObjects("name", compareStringsAsc()));
	}, [events]);

	const numGroups = groups.length;
	const maxGroups = 3;
	const hasAdditionalGroups = numGroups > maxGroups;
	const displayedGroups = hasAdditionalGroups ? groups.slice(0, maxGroups - 1) : groups;

	return (
		<div title={toGrammaticalNumber("Event", numEvents, true)}>
			<Row justify="spaced-center" horizontalSpacing="0.1rem" align="center">
				<Conditional>
					<If condition={numGroups > 0}>
						{displayedGroups.map((e, i) => (
							<div
								key={i}
								className={styles["small-event-calendar__event-icon"]}
								style={{ backgroundColor: e.color }}
							/>
						))}
					</If>
					<Else>
						<div className={styles["small-event-calendar__event-icon"]} />
					</Else>
				</Conditional>
				<If condition={hasAdditionalGroups}>
					<Icon.Plus size="0.75rem" style={{ color: "#aaa" }} />
				</If>
			</Row>
		</div>
	);
}

type SmallEventCalendarEventListItemProps = {
	calendarEvent: SmallEventCalendarItem;
	onClick?: () => void;
};

SmallEventCalendar.EventListItem = function ({ calendarEvent, onClick }: SmallEventCalendarEventListItemProps) {
	// TODO time formatting for multi-day non-allDay events

	const eventColor = getEventColor(calendarEvent);
	const groupName = getEventGroupName(calendarEvent);
	return (
		<Tooltip.Container
			openDelayMS={350}
			className={styles["small-event-calendar__event-list-item__tooltip-container"]}
			style={{ "--event-color": eventColor } as React.CSSProperties}
		>
			<div onClick={onClick}>
				<Row justify="space-between" className={styles["small-event-calendar__event-list-item"]}>
					<div className={styles["small-event-calendar__event-list-item__background"]}></div>
					<span className={styles["small-event-calendar__event-list-item__name"]}>{calendarEvent.name}</span>
					<span className={styles["small-event-calendar__event-list-item__time"]}>
						<Conditional>
							<If condition={calendarEvent.isAllDay}>All Day</If>
							<Else>
								{formatDateTime(calendarEvent.startTime, "HH:mm")}{" "}
								{calendarEvent.endTime && ` to ${formatDateTime(calendarEvent.endTime, "HH:mm")}`}
							</Else>
						</Conditional>
					</span>
				</Row>
			</div>

			<Tooltip className={styles["small-event-calendar__event-list-item__tooltip"]}>
				<Tooltip.Header className={styles["small-event-calendar__event-list-item__tooltip__header"]}>
					<div
						style={{
							fontSize: "1.1rem",
							fontWeight: "bold",
							borderLeft: "2px solid var(--event-color)",
							padding: "0.25rem 0.75rem",
							position: "relative",
						}}
					>
						{calendarEvent.name}

						<div
							style={{
								backgroundColor: "var(--event-color)",
								opacity: 0.25,
								width: "100%",
								height: "100%",
								borderTopRightRadius: "0.25rem",
								borderBottomRightRadius: "0.25rem",
								position: "absolute",
								top: 0,
								left: 0,
								zIndex: -1,
							}}
						></div>
					</div>
				</Tooltip.Header>
				<Tooltip.Body className={styles["small-event-calendar__event-list-item__tooltip__body"]}>
					<Column justify="spaced-start" verticalSpacing="0.25rem">
						<span style={{ color: "#777", paddingLeft: "0.25rem" }}>
							<Conditional>
								<If condition={calendarEvent.isAllDay}>All Day</If>
								<Else>
									{formatDateTime(calendarEvent.startTime, "HH:mm")}
									{calendarEvent.endTime && `to ${formatDateTime(calendarEvent.endTime, "HH:mm")}`}
								</Else>
							</Conditional>
						</span>

						<TextAreaFieldDisplay value="" formattedValue={calendarEvent.description} />

						<Conditional>
							<If condition={calendarEvent.isPublic || (calendarEvent.groups?.length ?? 0) === 0}>
								<Row justify="spaced-start" horizontalSpacing="0.25rem" align="center">
									<div
										style={{
											backgroundColor: "var(--event-color)",
											width: "0.8rem",
											height: "0.8rem",
											borderRadius: "0.2rem",
										}}
									></div>
									<span>{groupName}</span>
								</Row>
							</If>
							<Else>
								{calendarEvent.groups?.map((e) => (
									<Row key={e.name} justify="spaced-start" horizontalSpacing="0.25rem" align="center">
										<div
											style={{
												backgroundColor: e.color,
												width: "0.8rem",
												height: "0.8rem",
												borderRadius: "0.2rem",
											}}
										></div>
										<span>{e.name}</span>
									</Row>
								))}
							</Else>
						</Conditional>
					</Column>
				</Tooltip.Body>
			</Tooltip>
		</Tooltip.Container>
	);
};

const monthNavActions = new Set(["onChange", "prev2", "prev", "next", "next2"]);

export function useSmallEventCalendarDates(initialDate = new Date()) {
	const [activeStartDate, setActiveStartDate] = React.useState(initialDate);
	const onActiveStartDateChange = React.useCallback<ViewCallback>(({ action, activeStartDate }) => {
		if (!monthNavActions.has(action)) {
			return;
		}
		setActiveStartDate(activeStartDate);
	}, []);
	const onClickMonth = React.useCallback<DateCallback>((date) => {
		setActiveStartDate(date);
	}, []);

	const [value, setValue] = React.useState(initialDate);
	const onChange = React.useCallback<OnChangeDateCallback>((date) => {
		setValue(date);
	}, []);

	const firstOfMonth = dateTrunc(activeStartDate, "month").toDate();
	const lastOfMonth = dateTrunc(getLastDayInMonth(firstOfMonth), "day").toDate();

	const firstOfMonthStr = firstOfMonth.toISOString();
	React.useEffect(() => {
		const today = new Date();
		if (isSameTime(firstOfMonthStr, today, "month")) {
			setValue(today);
		} else {
			setValue(new Date(firstOfMonthStr));
		}
	}, [firstOfMonthStr]);

	return { value, firstOfMonth, lastOfMonth, onActiveStartDateChange, onClickMonth, onChange };
}

export function getCalendarEventFiltersForUserForDateRange(
	userID: User["id"],
	startDate: DateTime,
	endDate: DateTime,
): CalendarEventFilterInput[] {
	const userFilters: CalendarEventFilterInput[] = [
		{
			authorIDEQ: userID,
		},
		{
			isPublicEQ: true,
		},
		{
			sharingUserGroups: [{ users: [{ idEQ: userID }] }],
		},
	];

	const startTimeStr = toLocalDateString(startDate);
	const endTimeStr = toLocalDateString(addTimeToDate(endDate, [1, "day"]));
	const timeFilters: CalendarEventFilterInput[] = [
		{
			startTimeGTE: startTimeStr,
			startTimeLT: endTimeStr,
		},
		{
			endTimeGTE: startTimeStr,
			endTimeLT: endTimeStr,
		},
		{
			startTimeLT: startTimeStr,
			endTimeGTE: endTimeStr,
		},
	];

	return cartesianProduct(userFilters, timeFilters).map((e) => ({ ...e[0], ...e[1] }));
}
