import { omitKeys } from "@hex-insights/core";
import { RadioInputOption } from "@hex-insights/forms";
import { Person, Student, StudentAsthma, Tuition } from "../../GraphQL";
import * as PersonFormValues from "./person";

export type Base = {
	personID: Person["id"] | null;
	graduatingYear: Student["graduatingYear"] | null;
	hasPreviousSchooling: Student["hasPreviousSchooling"];
	previousSchoolInformation: Student["previousSchoolInformation"];
	previousSchoolLocation: Student["previousSchoolLocation"];
	allergies: Student["allergies"];
	asthma: Student["asthma"];
	medication: Student["medication"];
	medicationSchedule: Student["medicationSchedule"];
	dietaryRestrictions: Student["dietaryRestrictions"];
	healthNotes: Student["healthNotes"];
	doctorName: Student["doctorName"];
	doctorPhoneNumber: Student["doctorPhoneNumber"];
	emergencyContactInformation: Student["emergencyContactInformation"];
	tuitionID: Tuition["id"] | null;
};

export type Create = Base;

export type Detail = Base & {
	studentIDNumber: Student["studentIDNumber"];
};

export const initialCreate: Create = {
	personID: null,
	graduatingYear: null,
	hasPreviousSchooling: false,
	previousSchoolInformation: "",
	previousSchoolLocation: "",
	allergies: "",
	asthma: StudentAsthma.None,
	medication: "",
	medicationSchedule: "",
	dietaryRestrictions: "",
	healthNotes: "",
	doctorName: "",
	doctorPhoneNumber: "",
	emergencyContactInformation: "",
	tuitionID: null,
};

export const asthmaOptions = [
	{ value: StudentAsthma.None },
	{ value: StudentAsthma.Mild },
	{ value: StudentAsthma.Moderate },
	{ value: StudentAsthma.Severe },
];

export const hasPreviousSchoolingOptions: RadioInputOption<boolean>[] = [
	{ value: true, label: "Yes" },
	{ value: false, label: "No" },
];

export type CreateWithPerson = Omit<Create, "personID"> & PersonFormValues.CreateInOther;

export const initialCreateWithPerson: CreateWithPerson = {
	...PersonFormValues.initialCreateInOther,
	...omitKeys(initialCreate, ["personID"]),
};

export function omitPersonCreateFormValues(
	formValues: CreateWithPerson,
): Omit<CreateWithPerson, keyof PersonFormValues.CreateInOther> {
	return omitKeys(formValues, PersonFormValues.createInOtherKeys);
}

export namespace Detail {
	export type Health = Pick<
		Detail,
		| "allergies"
		| "asthma"
		| "medication"
		| "medicationSchedule"
		| "dietaryRestrictions"
		| "healthNotes"
		| "doctorName"
		| "doctorPhoneNumber"
		| "emergencyContactInformation"
	>;
}

export type DetailWithPerson = Omit<Detail, "personID"> & PersonFormValues.DetailInOther;

export function omitPersonDetailFormValues<T extends Partial<DetailWithPerson>>(
	formValues: T,
): Omit<T, keyof PersonFormValues.DetailInOther> {
	return omitKeys(formValues, PersonFormValues.detailInOtherKeys);
}
