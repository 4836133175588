import { Models } from "@hex-insights/app-modules";
import { DistributiveOmit } from "@hex-insights/core";
import { PhoneNumber, PhoneNumberFormat, PhoneNumberNameFragment } from "../../../../Utilities";

type ModelForLink = Pick<PhoneNumber, "id"> & PhoneNumberNameFragment;

export type PhoneNumberLinkProps = DistributiveOmit<Models.LinkProps<ModelForLink>, "pageInfoKey" | "format">;

/**
 * Renders a link to the given PhoneNumber instance.
 */
export function PhoneNumberLink(props: PhoneNumberLinkProps) {
	return <Models.Link<ModelForLink> pageInfoKey="PhoneNumber" format={PhoneNumberFormat.name} {...props} />;
}
