import { ChildrenProps, Heading, Row, StyleProps } from "@hex-insights/core";
import { Tile } from "../../../../../Components";

export type IconTileHeaderProps = {
	Icon: (props: StyleProps) => JSX.Element;
} & Partial<ChildrenProps>;

export function IconTileHeader({ Icon, children }: IconTileHeaderProps) {
	return (
		<Tile.Header style={{ padding: "0.2rem 0.5rem 0 0.2rem" }}>
			<Row justify="spaced-start" align="center">
				<div
					style={{
						backgroundColor: "var(--light-blue)",
						width: "2.5rem",
						height: "2.5rem",
						borderRadius: "50%",
						position: "relative",
					}}
				>
					<Icon
						style={{
							color: "var(--verita-blue)",
							position: "absolute",
							top: "50%",
							left: "50%",
							transform: "translate(-50%, -50%)",
						}}
					/>
				</div>

				<Heading
					level={2}
					noMargin
					style={{
						fontSize: "1.1rem",
						textAlign: "center",
						backgroundColor: "var(--light-blue)",
						flexGrow: 1,
						borderRadius: "5rem",
					}}
				>
					{children}
				</Heading>
			</Row>
		</Tile.Header>
	);
}
