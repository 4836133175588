import { IndexForms } from "@hex-insights/app-modules";
import { TypeFilterObject } from "@hex-insights/core";
import { EmployeeFormValues } from "../ModelFormValues";

export type StandardFilterFormValues = {}; // TODO define quick filters

export type FormValues = StandardFilterFormValues & IndexForms.SearchFormValues & IndexForms.FilterFormValues;

export const initialStandardFilters: StandardFilterFormValues = {};

export const initial: FormValues = {
	...initialStandardFilters,
	...IndexForms.initialSearchFormValues,
	...IndexForms.initialFilterFormValues,
};

export function getInitial(): FormValues {
	return initial;
}

export const quickFiltersTypeFilterObject: TypeFilterObject<StandardFilterFormValues> = {};

export const typeFilterObject: TypeFilterObject<FormValues> = {
	...quickFiltersTypeFilterObject,
	...IndexForms.searchFormValuesFilterObject,
	...IndexForms.filterFormValuesFilterObject,
};

export const advancedFilterInformation: IndexForms.AdvancedFilterInformation = {
	fieldOptions: [
		{ value: "email" },
		{ value: "jobTitle" },
		{ value: "role" },
		{ value: "startDate" },
		{ value: "endDate" },
	],
	fieldComparisonOptions: {
		email: IndexForms.stringComparisonOptions,
		jobTitle: IndexForms.stringComparisonOptions,
		role: IndexForms.enumComparisonOptions,
		startDate: IndexForms.dateComparisonOptions,
		endDate: [...IndexForms.dateComparisonOptions, ...IndexForms.nullableComparisonOptions],
	},
	fieldComparisonTranslations: {
		email: IndexForms.stringComparisonTranslations,
		jobTitle: IndexForms.stringComparisonTranslations,
		role: IndexForms.getRadioComparisonTranslations(EmployeeFormValues.roleOptions),
		startDate: IndexForms.dateComparisonTranslations,
		endDate: { ...IndexForms.dateComparisonTranslations, ...IndexForms.nullableComparisonTranslations },
	},
};
