import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { StandardFiltersProps } from "@hex-insights/app-modules/dist/Features/IndexForms";
import { Button, ButtonProps, ButtonVariant, Column } from "@hex-insights/core";
import { FormState, MultiSelectField } from "@hex-insights/forms";
import {
	EnrollmentApplicationFilterFormValues,
	EnrollmentApplicationFormat,
	EnrollmentApplicationStatus,
	EnrollmentApplicationStudentFormValues,
} from "../../../../Utilities";

export type EnrollmentApplicationSearchFormProps = Pick<IndexForms.SearchFormProps, "formState">;

/**
 * Renders the search form of the EnrollmentApplication model.
 */
export function EnrollmentApplicationSearchForm(props: EnrollmentApplicationSearchFormProps) {
	return <IndexForms.SearchForm {...props} />;
}

export type EnrollmentApplicationFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, EnrollmentApplicationFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the EnrollmentApplication model.
 */
export function EnrollmentApplicationFilterFormController(props: EnrollmentApplicationFilterFormControllerProps) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={EnrollmentApplicationFilterFormValues.advancedFilterInformation}
			StandardFilters={StandardFilters}
		/>
	);
}

const statusOptions = [
	{ value: EnrollmentApplicationStatus.Approved },
	{ value: EnrollmentApplicationStatus.Pending },
	{ value: EnrollmentApplicationStatus.Rejected },
];

function StandardFilters({ formState }: StandardFiltersProps<EnrollmentApplicationFilterFormValues.FormValues>) {
	return (
		<Column justify="spaced-start">
			<MultiSelectField formState={formState} name="statusIn" label="Status" options={statusOptions} />
			<MultiSelectField
				formState={formState}
				name="gradeLevelApplyingForIn"
				label="Grade Applying For"
				options={EnrollmentApplicationStudentFormValues.gradeLevelApplyingForOptions}
			/>
		</Column>
	);
}

const allStatuses = [
	EnrollmentApplicationStatus.Approved,
	EnrollmentApplicationStatus.Pending,
	EnrollmentApplicationStatus.Rejected,
];

export type EnrollmentApplicationStatusFilterButtonsProps = {
	statuses?: EnrollmentApplicationStatus[];
	formState: FormState<Pick<EnrollmentApplicationFilterFormValues.FormValues, "statusIn">>;
	buttonProps?: Omit<ButtonProps, "variant" | "onClick" | "children">;
};

export function EnrollmentApplicationStatusFilterButtons({
	statuses = allStatuses,
	formState,
	buttonProps,
}: EnrollmentApplicationStatusFilterButtonsProps) {
	return (
		<React.Fragment>
			{statuses.map((status) => (
				<Button
					key={status}
					{...buttonProps}
					variant={formState.formValues.statusIn.includes(status) ? getButtonVariantForStatus(status) : "tertiary"}
					onClick={() => {
						formState.formValues.statusIn.includes(status)
							? formState.formSetFunctions.statusIn((prev) => prev.filter((e) => e !== status))
							: formState.formSetFunctions.statusIn((prev) => [...prev, status]);
					}}
				>
					{EnrollmentApplicationFormat.Fields.status(status)}
				</Button>
			))}
		</React.Fragment>
	);
}

function getButtonVariantForStatus(status: EnrollmentApplicationStatus): ButtonVariant {
	switch (status) {
		case EnrollmentApplicationStatus.Approved:
			return "success";
		case EnrollmentApplicationStatus.Pending:
			return "warning";
		case EnrollmentApplicationStatus.Rejected:
			return "danger";
	}
}

export type EnrollmentApplicationSeenStatusFilterButtonsProps = {
	formState: FormState<Pick<EnrollmentApplicationFilterFormValues.FormValues, "isNew" | "isSeen">>;
	buttonProps?: Omit<ButtonProps, "variant" | "onClick" | "children">;
};

export function EnrollmentApplicationSeenStatusFilterButtons({
	formState,
	buttonProps,
}: EnrollmentApplicationSeenStatusFilterButtonsProps) {
	return (
		<React.Fragment>
			<Button
				{...buttonProps}
				variant={formState.formValues.isNew ? "primary" : "tertiary"}
				onClick={() => formState.formSetFunctions.isNew((prev) => !prev)}
			>
				New
			</Button>
			<Button
				{...buttonProps}
				variant={formState.formValues.isSeen ? "primary" : "tertiary"}
				onClick={() => formState.formSetFunctions.isSeen((prev) => !prev)}
			>
				Seen
			</Button>
		</React.Fragment>
	);
}

export type EnrollmentApplicationInterviewMeetingTimeFilterButtonsProps = {
	formState: FormState<
		Pick<EnrollmentApplicationFilterFormValues.FormValues, "interviewMeetingInFuture" | "interviewMeetingInPast">
	>;
	buttonProps?: Omit<ButtonProps, "variant" | "onClick" | "children">;
};

export function EnrollmentApplicationInterviewMeetingTimeFilterButtons({
	formState,
	buttonProps,
}: EnrollmentApplicationInterviewMeetingTimeFilterButtonsProps) {
	return (
		<React.Fragment>
			<Button
				{...buttonProps}
				variant={formState.formValues.interviewMeetingInFuture ? "primary" : "tertiary"}
				onClick={() => formState.formSetFunctions.interviewMeetingInFuture((prev) => !prev)}
			>
				Upcoming
			</Button>
			<Button
				{...buttonProps}
				variant={formState.formValues.interviewMeetingInPast ? "primary" : "tertiary"}
				onClick={() => formState.formSetFunctions.interviewMeetingInPast((prev) => !prev)}
			>
				Past
			</Button>
		</React.Fragment>
	);
}
