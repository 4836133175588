import { IndexForms } from "@hex-insights/app-modules";
import { TypeFilterObject } from "@hex-insights/core";
import { NoteReactionFormValues } from "../ModelFormValues";

export type StandardFilterFormValues = {}; // TODO define quick filters

export type FormValues = StandardFilterFormValues & IndexForms.FilterFormValues;

export const initialStandardFilters: StandardFilterFormValues = {};

export const initial: FormValues = {
	...initialStandardFilters,
	...IndexForms.initialFilterFormValues,
};

export function getInitial(): FormValues {
	return initial;
}

export const quickFiltersTypeFilterObject: TypeFilterObject<StandardFilterFormValues> = {};

export const typeFilterObject: TypeFilterObject<FormValues> = {
	...quickFiltersTypeFilterObject,
	...IndexForms.filterFormValuesFilterObject,
};

export const advancedFilterInformation: IndexForms.AdvancedFilterInformation = {
	fieldOptions: [{ value: "reactionType" }, { value: "createdAt" }],
	fieldComparisonOptions: {
		reactionType: IndexForms.enumComparisonOptions,
		createdAt: IndexForms.timeComparisonOptions,
	},
	fieldComparisonTranslations: {
		reactionType: IndexForms.getRadioComparisonTranslations(NoteReactionFormValues.reactionTypeOptions),
		createdAt: IndexForms.timeComparisonTranslations,
	},
};
