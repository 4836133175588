import { RadioInputOption } from "@hex-insights/forms";
import { CalendarEvent, User, UserGroup } from "../../GraphQL";

export type Base = {
	name: CalendarEvent["name"];
	description: CalendarEvent["description"];
	isAllDay: CalendarEvent["isAllDay"];
	startTime: CalendarEvent["startTime"] | null;
	endTime: CalendarEvent["endTime"] | null;
	isPublic: CalendarEvent["isPublic"];
	sharingUserGroupIDs: UserGroup["id"][];
};

export type Create = Base;

export type Detail = Base & {
	createdAt: CalendarEvent["createdAt"] | null;
	authorID: User["id"] | null;
};

export const initialCreate: Create = {
	name: "",
	description: "",
	isAllDay: false,
	startTime: null,
	endTime: null,
	isPublic: false,
	sharingUserGroupIDs: [],
};

export const isPublicOptions: RadioInputOption<boolean>[] = [
	{ value: true, label: "Public" },
	{ value: false, label: "Private" },
];
