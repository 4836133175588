import { Bus, BusPlan, Student } from "../../GraphQL";

export type Base = {
	studentID: Student["id"] | null;
	hasPickup: BusPlan["hasPickup"];
	pickupBusID: Bus["id"] | null;
	pickupTimeMinutes: BusPlan["pickupTimeMinutes"] | null;
	pickupLocation: BusPlan["pickupLocation"];
	hasDropoff: BusPlan["hasDropoff"];
	dropoffBusID: Bus["id"] | null;
	dropoffTimeMinutes: BusPlan["dropoffTimeMinutes"] | null;
	dropoffLocation: BusPlan["dropoffLocation"];
};

export type Create = Base;

export type Detail = Base;

export const initialCreate: Create = {
	studentID: null,
	hasPickup: true,
	pickupBusID: null,
	pickupTimeMinutes: null,
	pickupLocation: "",
	hasDropoff: true,
	dropoffBusID: null,
	dropoffTimeMinutes: null,
	dropoffLocation: "",
};
