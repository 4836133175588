import { Models } from "@hex-insights/app-modules";
import { MealPlan, MealPlanMutation } from "../../../../Utilities";

export type MealPlanDeleteButtonProps = Omit<Models.DeleteButtonProps, "deleteInstance"> & {
	instanceID: MealPlan["id"];
};

/**
 * A delete button for the MealPlan model.
 */
export function MealPlanDeleteButton({
	instanceID,
	confirmationContent = "Are you sure you want to delete this meal plan?",
	children = "Delete Meal Plan",
	...props
}: MealPlanDeleteButtonProps) {
	const del = MealPlanMutation.useDelete(instanceID);

	return (
		<Models.DeleteButton deleteInstance={del} confirmationContent={confirmationContent} {...props}>
			{children}
		</Models.DeleteButton>
	);
}
