import { IndexForms } from "@hex-insights/app-modules";
import { omitKeys } from "@hex-insights/core";
import { FormState } from "@hex-insights/forms";
import { StudentFilterInput } from "../../GraphQL";
import { StudentFilterFormValues } from "../ModelFilterFormValues";

/**
 * Counts the number of active filters for the Student model.
 *
 * @param formValues The form values of the filter form.
 */
export function countActiveFilters(formValues: StudentFilterFormValues.FormValues) {
	let numStandardFilters = 0;
	if (formValues.campusIDs.length > 0) {
		numStandardFilters++;
	}
	if (formValues.hasHomeRoomSectionStudentEnrollments !== null) {
		numStandardFilters++;
	}
	if (formValues.homeRoomIDs.length > 0) {
		numStandardFilters++;
	}
	if (formValues.homeRoomSectionIDs.length > 0) {
		numStandardFilters++;
	}
	if (formValues.courseIDs.length > 0) {
		numStandardFilters++;
	}
	if (formValues.teacherIDs.length > 0) {
		numStandardFilters++;
	}
	if (formValues.hasMealPlans !== null) {
		numStandardFilters++;
	}
	if (formValues.hasBusPlans !== null) {
		numStandardFilters++;
	}

	const numAdvancedFilters = IndexForms.countActiveAdvancedFilters(
		formValues,
		StudentFilterFormValues.advancedFilterInformation.fieldComparisonTranslations,
	);

	return numStandardFilters + numAdvancedFilters;
}

/**
 * Clears the values of the filter form for the Student model.
 *
 * @param formState The form state of the filter form.
 */
export function clearFilters(formState: FormState<StudentFilterFormValues.FormValues>) {
	formState.formClearFunctions.campusIDs();
	formState.formSetFunctions.hasHomeRoomSectionStudentEnrollments(null); // clear func sets to `false`
	formState.formClearFunctions.homeRoomIDs();
	formState.formClearFunctions.homeRoomSectionIDs();
	formState.formClearFunctions.courseIDs();
	formState.formClearFunctions.teacherIDs();
	formState.formClearFunctions.hasMealPlans();
	formState.formClearFunctions.hasBusPlans();

	formState.formClearFunctions.filterSets();
}

/**
 * Resets the values of the filter form for the Student model.
 *
 * @param formState The form state of the filter form.
 */
export function resetFilters(formState: FormState<StudentFilterFormValues.FormValues>) {
	formState.formResetFunctions.campusIDs();
	formState.formResetFunctions.hasHomeRoomSectionStudentEnrollments();
	formState.formResetFunctions.homeRoomIDs();
	formState.formResetFunctions.homeRoomSectionIDs();
	formState.formResetFunctions.courseIDs();
	formState.formResetFunctions.teacherIDs();
	formState.formResetFunctions.hasMealPlans();
	formState.formResetFunctions.hasBusPlans();

	formState.formResetFunctions.filterSets();
}

/**
 * Converts the form values of the filter form for the Student model to the correct GraphQL
 * filter input type.
 *
 * @param formValues The form values of the filter form.
 */
export function toFilterInputs(
	formValues: StudentFilterFormValues.FormValues,
): StudentFilterInput | StudentFilterInput[] {
	const convertableFormValues = omitKeys(formValues, [
		"campusIDs",
		"homeRoomIDs",
		"homeRoomSectionIDs",
		"courseIDs",
		"teacherIDs",
	]);
	const baseFilterInput = IndexForms.toFilterInput(
		convertableFormValues,
		StudentFilterFormValues.advancedFilterInformation.fieldComparisonTranslations,
	) as StudentFilterInput;

	const filterInput: StudentFilterInput = {
		search: baseFilterInput.search,
		and: [{ ...baseFilterInput, search: "" }],
	};

	// TODO term handling (currently active)

	if (formValues.campusIDs.length > 0) {
		filterInput.and!.push({
			or: [
				{
					homeRoomSectionStudentEnrollments: [{ homeRoomSection: [{ room: [{ campusIDIn: formValues.campusIDs }] }] }],
				},
				{
					courseSectionStudentEnrollments: [
						{ courseSection: [{ courseSectionPeriods: [{ room: [{ campusIDIn: formValues.campusIDs }] }] }] },
					],
				},
			],
		});
	}

	if (formValues.homeRoomIDs.length > 0) {
		filterInput.and!.push({
			homeRoomSectionStudentEnrollments: [{ homeRoomSection: [{ homeRoomIDIn: formValues.homeRoomIDs }] }],
		});
	}

	if (formValues.homeRoomSectionIDs.length > 0) {
		filterInput.and!.push({
			homeRoomSectionStudentEnrollments: [{ homeRoomSectionIDIn: formValues.homeRoomSectionIDs }],
		});
	}

	if (formValues.courseIDs.length > 0) {
		filterInput.and!.push({
			courseSectionStudentEnrollments: [{ courseSection: [{ courseIDIn: formValues.courseIDs }] }],
		});
	}

	if (formValues.teacherIDs.length > 0) {
		filterInput.and!.push({
			or: [
				{
					courseSectionStudentEnrollments: [
						{ courseSection: [{ courseSectionTeacherEnrollments: [{ teacherIDIn: formValues.teacherIDs }] }] },
					],
				},
				{
					homeRoomSectionStudentEnrollments: [
						{ homeRoomSection: [{ homeRoomSectionTeacherEnrollments: [{ teacherIDIn: formValues.teacherIDs }] }] },
					],
				},
			],
		});
	}

	const filterInputs = [filterInput];
	if (formValues.search !== "") {
		filterInputs.push({
			...filterInput,
			search: "",
			person: [{ search: formValues.search }],
		});
	}

	return filterInputs;
}
