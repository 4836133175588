import { Models } from "@hex-insights/app-modules";
import { DistributiveOmit } from "@hex-insights/core";
import { User, UserFormat, UserNameFragment } from "../../../../Utilities";

type ModelForLink = Pick<User, "id"> & UserNameFragment;

export type UserLinkProps = DistributiveOmit<Models.LinkProps<ModelForLink>, "pageInfoKey" | "format">;

/**
 * Renders a link to the given User instance.
 */
export function UserLink(props: UserLinkProps) {
	return <Models.Link<ModelForLink> pageInfoKey="User" format={UserFormat.name} {...props} />;
}
