import React from "react";
import {
	ChildrenProps,
	ClassNameProps,
	Column,
	Conditional,
	englishCommaJoinStrings,
	forceUseHookDependencies,
	If,
	makeClassName,
	Modal,
	Row,
	StyleProps,
	toLocalDateString,
	Tooltip,
	useSync,
	useUpdateEffect,
} from "@hex-insights/core";
import {
	CourseSectionFormat,
	Employee,
	EmployeePreviewQuery,
	Person,
	PersonFormat,
	Student,
	StudentPreviewQuery,
	useEmployeePreviewLazyQuery,
	useLazyTooltip,
	useStudentPreviewLazyQuery,
} from "../../Utilities";
import { ScaledText } from "../ScaledText";
import { StudentEnrollmentStatusBadge } from "../StudentEnrollmentStatusBadge";
import styles from "./styles.module.css";

export type PersonIconProps = {
	person: Pick<Person, "name" | "imageURL">;
	withName?: boolean;
	withTooltipPreview?: boolean;
	// TODO types (conditionally required)
	studentID?: Student["id"];
	employeeID?: Employee["id"];
	imageSize?: string;
	imageContainerClassName?: string;
	imageContainerStyle?: React.CSSProperties;
	expandable?: boolean;
} & Partial<ClassNameProps> &
	Partial<StyleProps>;

export function PersonIcon({
	person,
	withName = false,
	withTooltipPreview = false,
	studentID,
	employeeID,
	imageSize = "4rem",
	imageContainerClassName,
	imageContainerStyle,
	expandable = false,
	className,
	style,
}: PersonIconProps) {
	// imageURL state used to keep in step with hasImageError and hasLoaded. Specifically to address issues when this
	// component is rendered initially without an imageURL, then is given an imageURL (e.g. after loading done) and the
	// image is already cached so the update effect and onLoad run on the same render. Ex: in the header.
	const [imageURL, setImageURL] = React.useState(person.imageURL);
	const [hasImageError, setHasImageError] = React.useState(false);
	const [hasLoaded, setHasLoaded] = React.useState(false);
	const onLoad = React.useCallback(() => {
		setHasLoaded(true);
	}, []);

	const hasImage = imageURL !== "" && !hasImageError;
	const hasLoadedImage = hasImage && hasLoaded;

	useUpdateEffect(() => {
		setHasImageError(false);
		setHasLoaded(false);
		setImageURL(person.imageURL);
	}, [person.imageURL]);

	const { Wrapper, id } = React.useMemo(() => {
		if (!withTooltipPreview) {
			return { Wrapper: NoTooltipWrapper, id: "" };
		}
		if (studentID) {
			return { Wrapper: StudentPreviewTooltip, id: studentID };
		}
		if (employeeID) {
			return { Wrapper: EmployeePreviewTooltip, id: employeeID };
		}
		return { Wrapper: NoTooltipWrapper, id: "" };
	}, [withTooltipPreview, studentID, employeeID]);

	const { isModalOpen, toggleIsModalOpen, closeModal } = Modal.useToggle(false);
	React.useEffect(() => {
		if (!expandable) {
			closeModal();
		}
	}, [expandable, closeModal]);

	return (
		<div
			onClick={expandable ? toggleIsModalOpen : undefined}
			className={className}
			style={
				{
					...style,
					cursor: expandable ? "pointer" : undefined,
					"--image---size": imageSize,
				} as React.CSSProperties
			}
		>
			<Column justify="spaced-start" verticalSpacing="0.25rem" align="center">
				<Wrapper id={id}>
					<div
						className={makeClassName(
							styles["person-icon__image-container"],
							!hasLoadedImage ? styles["person-icon__image-container--no-image"] : "",
							styles["image-container"],
							imageContainerClassName,
						)}
						style={{ ...imageContainerStyle, backgroundColor: getBackgroundColor(person.name) }}
					>
						{hasImage && (
							<ImageContainer
								key={imageURL}
								src={imageURL}
								alt={person.name}
								onLoad={onLoad}
								onError={() => setHasImageError(true)}
								className={styles["person-icon__image"]}
							/>
						)}
						{!hasLoadedImage && <span>{getInitials(person.name)}</span>}
					</div>
				</Wrapper>
				{withName && <span className={styles["person-icon__name"]}>{person.name}</span>}
			</Column>

			<Modal.If condition={isModalOpen}>
				<Modal size="large" onClose={toggleIsModalOpen} style={{ backgroundColor: "transparent", boxShadow: "none" }}>
					<Modal.Body>
						<PersonIcon person={person} imageSize="90vmin" />
					</Modal.Body>
				</Modal>
			</Modal.If>
		</div>
	);
}

function NoTooltipWrapper({ children }: StudentPreviewTooltipProps) {
	return <React.Fragment>{children}</React.Fragment>;
}

function getInitials(name: string) {
	if (name.length === 0) {
		return "";
	}

	const names = name.split(/\s+/);
	if (names.length === 1) {
		return names[0].charAt(0);
	}
	return `${names[0].charAt(0)}${names[names.length - 1].charAt(0)}`;
}

const backgroundColors = [
	"var(--verita-colors-pink)",
	"var(--verita-colors-green)",
	"var(--verita-colors-yellow)",
	"var(--verita-colors-teal)",
];

function getBackgroundColor(name: string) {
	if (name === "") {
		return backgroundColors[0];
	}
	const charCode = name.charCodeAt(0);
	return backgroundColors[charCode % backgroundColors.length];
}

// TODO unite and make single image component

type ImageContainerProps = {
	src: string;
	alt?: string;
	onLoad?: () => void;
	onError?: () => void;
} & Partial<ClassNameProps>;

function ImageContainer({ src, alt, onLoad, onError, className }: ImageContainerProps) {
	const ref = React.useRef<HTMLImageElement | null>(null);

	const { syncID, synchronize } = useSync();

	const fullOnLoad = React.useCallback(() => {
		synchronize();
		if (onLoad) {
			onLoad();
		}
	}, [synchronize, onLoad]);

	const imageShape = React.useMemo(() => {
		forceUseHookDependencies(syncID);
		if (!ref.current) {
			return "wide";
		}
		const { width, height } = ref.current.getBoundingClientRect();
		return width >= height ? "wide" : "tall";
	}, [syncID]);

	return (
		<div className={makeClassName(styles["image-container"], styles[`image-container--${imageShape}`], className)}>
			<img ref={ref} src={src} alt={alt} onLoad={fullOnLoad} onError={onError} />
		</div>
	);
}

// Student Preview

export type StudentPreviewTooltipProps = {
	id: Student["id"];
} & Partial<ClassNameProps & StyleProps & ChildrenProps>;

export function StudentPreviewTooltip({ id, className, style, children }: StudentPreviewTooltipProps) {
	const [load, { data }] = useStudentPreviewLazyQuery({ variables: { id, date: toLocalDateString(new Date()) } });
	const { onOpenChange, contentSyncID, className: tooltipClassName } = useLazyTooltip(load, !!data);

	return (
		<Tooltip.Container onOpenChange={onOpenChange} contentSyncID={contentSyncID} className={className} style={style}>
			<Tooltip className={makeClassName(styles["person-preview-tooltip"], tooltipClassName)}>
				<Tooltip.Body>{!!data && <StudentPreview student={data.student} />}</Tooltip.Body>
			</Tooltip>

			{children}
		</Tooltip.Container>
	);
}

type StudentPreviewProps = {
	student: StudentPreviewQuery["student"];
};

function StudentPreview({ student }: StudentPreviewProps) {
	return (
		<Column
			justify="spaced-start"
			verticalSpacing="0.25rem"
			style={{ color: "var(--general---color)", fontSize: "1rem" }}
		>
			<Row justify="spaced-start">
				<PersonIcon person={student.person} />

				<Column>
					<ScaledText maxFontSize={24} style={{ width: "12rem", height: "2rem" }}>
						<span style={{ color: "var(--general---color)" }}>{student.person.name}</span>
					</ScaledText>

					<Row justify="space-between">
						<StudentEnrollmentStatusBadge studentID={student.id} />
						{student.homeRoomSectionStudentEnrollments.length > 0 && (
							<div className={styles["person-preview__pill"]}>
								{student.homeRoomSectionStudentEnrollments[0].homeRoomSection.name}
							</div>
						)}
					</Row>
				</Column>
			</Row>

			<Column justify="spaced-start" verticalSpacing="0.25rem">
				<Row justify="spaced-start" horizontalSpacing="0.25rem">
					<If condition={student.person.gender !== ""}>
						<div className={styles["person-preview__pill"]}>{student.person.gender}</div>
					</If>

					<span className={styles["person-preview__pill"]} style={{ flexGrow: 1 }}>
						{student.person.dateOfBirth ? PersonFormat.Fields.age(student.person.dateOfBirth) : "Unknown age"}
					</span>
				</Row>

				<Row justify="spaced-start" horizontalSpacing="0.25rem">
					<If condition={student.person.nationality !== ""}>
						<div
							className={styles["person-preview__pill"] + " " + styles["person-preview__one-line"]}
							style={{ flexGrow: 1 }}
						>
							{student.person.nationality}
						</div>
					</If>
				</Row>
			</Column>
		</Column>
	);
}

// Employee Preview

export type EmployeePreviewTooltipProps = {
	id: Employee["id"];
} & Partial<ClassNameProps & StyleProps & ChildrenProps>;

export function EmployeePreviewTooltip({ id, className, style, children }: EmployeePreviewTooltipProps) {
	const [load, { data }] = useEmployeePreviewLazyQuery({ variables: { id, date: toLocalDateString(new Date()) } });
	const { onOpenChange, contentSyncID, className: tooltipClassName } = useLazyTooltip(load, !!data);

	return (
		<Tooltip.Container onOpenChange={onOpenChange} contentSyncID={contentSyncID} className={className} style={style}>
			<Tooltip className={makeClassName(styles["person-preview-tooltip"], tooltipClassName)}>
				<Tooltip.Body>{!!data && <EmployeePreview employee={data.employee} />}</Tooltip.Body>
			</Tooltip>

			{children}
		</Tooltip.Container>
	);
}

type EmployeePreviewProps = {
	employee: EmployeePreviewQuery["employee"];
};

function EmployeePreview({ employee }: EmployeePreviewProps) {
	return (
		<Column
			justify="spaced-start"
			verticalSpacing="0.25rem"
			style={{ color: "var(--general---color)", fontSize: "1rem" }}
		>
			<Row justify="spaced-start">
				<PersonIcon person={employee.person} />

				<Column>
					<ScaledText maxFontSize={24} style={{ width: "12rem", height: "2rem" }}>
						<span style={{ color: "var(--general---color)" }}>{employee.person.name}</span>
					</ScaledText>

					<div className={styles["person-preview__one-line"]}>{employee.jobTitle || employee.role}</div>
				</Column>
			</Row>

			<Column justify="spaced-start" verticalSpacing="0.25rem">
				<Conditional>
					<If condition={employee.homeRoomSectionTeacherEnrollments.length > 0}>
						<div className={styles["person-preview__pill"] + " " + styles["person-preview__one-line"]}>
							{englishCommaJoinStrings(employee.homeRoomSectionTeacherEnrollments.map((e) => e.homeRoomSection.name))}
						</div>
					</If>
					<If condition={employee.courseSectionTeacherEnrollments.length > 0}>
						<div className={styles["person-preview__pill"] + " " + styles["person-preview__one-line"]}>
							{englishCommaJoinStrings(
								employee.courseSectionTeacherEnrollments.map((e) =>
									CourseSectionFormat.nameWithHomeRoomName(e.courseSection),
								),
							)}
						</div>
					</If>
				</Conditional>

				<div className={styles["person-preview__pill"] + " " + styles["person-preview__one-line"]}>
					{englishCommaJoinStrings(employee.campuses.map((e) => e.name))}
				</div>
			</Column>
		</Column>
	);
}
