import React from "react";
import {
	Column,
	Conditional,
	Else,
	formatDateTime,
	Heading,
	If,
	isSameTime,
	Modal,
	ModalProps,
	RequiredKeys,
	Row,
} from "@hex-insights/core";
import { Label } from "@hex-insights/forms";
import { PermissionCheckMethod, PermissionRequired } from "@hex-insights/permissioning";
import {
	CalendarEvent,
	CalendarEventFormValues,
	CalendarEventMutation,
	permissions,
	useCalendarEventDetailQuery,
} from "../../Utilities";
import { CalendarEventDeleteButton } from "../DeleteButtons";
import { CalendarEventForm } from "../Forms/ModelForms/CalendarEvent";

// more specific import to avoid dependency cycle

const deleteCalendarEventPermissions = [permissions.CalendarEvent.Delete.Admin, permissions.CalendarEvent.Delete.Basic];

export type EventDetailModalProps = {
	calendarEvent: Pick<CalendarEvent, "id" | "name">;
} & RequiredKeys<Pick<ModalProps, "ifRef" | "onClose">, "onClose">;

export function EventDetailModal({ calendarEvent, ifRef, onClose }: EventDetailModalProps) {
	const { loading, data } = useCalendarEventDetailQuery({ variables: { id: calendarEvent.id } });

	const update = CalendarEventMutation.useUpdate(calendarEvent.id);
	const applyUpdate = React.useCallback(
		async (
			changedFormValues: Partial<CalendarEventFormValues.Detail>,
			initialFormValues: CalendarEventFormValues.Detail,
		) => {
			const { errors } = await update(changedFormValues, initialFormValues);
			return errors;
		},
		[update],
	);

	return (
		<Modal ifRef={ifRef} onClose={onClose}>
			<Modal.Header>
				<Heading level={2} noMargin>
					{calendarEvent.name}
				</Heading>
			</Modal.Header>
			<Modal.Body>
				<Conditional>
					<If condition={loading}>Loading...</If>
					<Else>
						{!!data && (
							<Column justify="spaced-start">
								<Column>
									<Label>Time</Label>
									<span style={{ fontSize: "1.25rem" }}>{getEventTimeText(data.calendarEvent)}</span>
								</Column>

								<CalendarEventForm.ControlledDetail
									calendarEvent={data.calendarEvent}
									applyUpdate={applyUpdate}
									onSuccess={onClose}
								/>
							</Column>
						)}
					</Else>
				</Conditional>
			</Modal.Body>
			<PermissionRequired requiredPermissions={deleteCalendarEventPermissions} checkMethod={PermissionCheckMethod.Or}>
				<Modal.Footer>
					<Row justify="center" align="center">
						<CalendarEventDeleteButton instanceID={calendarEvent.id} onSuccess={onClose} size="small" />
					</Row>
				</Modal.Footer>
			</PermissionRequired>
		</Modal>
	);
}

function getEventTimeText(calendarEvent: Pick<CalendarEvent, "startTime" | "endTime" | "isAllDay">) {
	const isSingleDay = isSameTime(calendarEvent.startTime, calendarEvent.endTime, "day");

	if (calendarEvent.isAllDay) {
		if (isSingleDay) {
			return `All Day on ${formatDateTime(calendarEvent.startTime, "dddd, D MMMM")}`;
		}
		return `All Day from ${formatDateTime(calendarEvent.startTime, "dddd, D MMMM")} to ${formatDateTime(
			calendarEvent.endTime,
			"dddd, D MMMM",
		)}`;
	}
	if (isSingleDay) {
		return `${formatDateTime(calendarEvent.startTime, "HH:mm")} to ${formatDateTime(
			calendarEvent.endTime,
			"HH:mm",
		)} on ${formatDateTime(calendarEvent.startTime, "dddd, D MMMM")}`;
	}
	return `${formatDateTime(calendarEvent.startTime, "dddd, D MMMM [at] HH:mm")} to ${formatDateTime(
		calendarEvent.endTime,
		"dddd, D MMMM [at] HH:mm",
	)}`;
}
