import { IndexForms } from "@hex-insights/app-modules";
import { TypeFilterObject } from "@hex-insights/core";

export type StandardFilterFormValues = {}; // TODO define quick filters

export type FormValues = StandardFilterFormValues & IndexForms.SearchFormValues & IndexForms.FilterFormValues;

export const initialStandardFilters: StandardFilterFormValues = {};

export const initial: FormValues = {
	...initialStandardFilters,
	...IndexForms.initialSearchFormValues,
	...IndexForms.initialFilterFormValues,
};

export function getInitial(): FormValues {
	return initial;
}

export const quickFiltersTypeFilterObject: TypeFilterObject<StandardFilterFormValues> = {};

export const typeFilterObject: TypeFilterObject<FormValues> = {
	...quickFiltersTypeFilterObject,
	...IndexForms.searchFormValuesFilterObject,
	...IndexForms.filterFormValuesFilterObject,
};

export const advancedFilterInformation: IndexForms.AdvancedFilterInformation = {
	fieldOptions: [
		{ value: "attendanceDate" },
		{ value: "isPresent" },
		{ value: "isLate" },
		{ value: "isExcused" },
		{ value: "notes" },
	],
	fieldComparisonOptions: {
		attendanceDate: IndexForms.dateComparisonOptions,
		isPresent: IndexForms.booleanComparisonOptions,
		isLate: IndexForms.booleanComparisonOptions,
		isExcused: IndexForms.booleanComparisonOptions,
		notes: IndexForms.stringComparisonOptions,
	},
	fieldComparisonTranslations: {
		attendanceDate: IndexForms.dateComparisonTranslations,
		isPresent: IndexForms.booleanComparisonTranslations,
		isLate: IndexForms.booleanComparisonTranslations,
		isExcused: IndexForms.booleanComparisonTranslations,
		notes: IndexForms.stringComparisonTranslations,
	},
};
