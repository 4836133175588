import { IndexForms } from "@hex-insights/app-modules";
import { CaseStyle, convertCase } from "@hex-insights/core";
import { BillingSetupSubmissionOrderField, OrderDirection } from "../../GraphQL";

export type FormValues = IndexForms.OrderFormValues<BillingSetupSubmissionOrderField | null>;

export const initial: FormValues = {
	field: BillingSetupSubmissionOrderField.NumPayments,
	direction: OrderDirection.Asc,
};

/**
 * Options for the "field" field in the order form of the BillingSetupSubmission model.
 */
export const fieldOptions = [
	{ label: "ID", value: null },
	{
		label: convertCase(BillingSetupSubmissionOrderField.NumPayments.toLowerCase(), CaseStyle.Title),
		value: BillingSetupSubmissionOrderField.NumPayments,
	},
	{
		label: convertCase(BillingSetupSubmissionOrderField.IsInterestedInBusPlan.toLowerCase(), CaseStyle.Title),
		value: BillingSetupSubmissionOrderField.IsInterestedInBusPlan,
	},
	{
		label: convertCase(BillingSetupSubmissionOrderField.IsInterestedInMealPlan.toLowerCase(), CaseStyle.Title),
		value: BillingSetupSubmissionOrderField.IsInterestedInMealPlan,
	},
	{
		label: convertCase(BillingSetupSubmissionOrderField.AuthorName.toLowerCase(), CaseStyle.Title),
		value: BillingSetupSubmissionOrderField.AuthorName,
	},
	{
		label: convertCase(BillingSetupSubmissionOrderField.AuthorEmail.toLowerCase(), CaseStyle.Title),
		value: BillingSetupSubmissionOrderField.AuthorEmail,
	},
	{
		label: convertCase(BillingSetupSubmissionOrderField.AuthorPhone.toLowerCase(), CaseStyle.Title),
		value: BillingSetupSubmissionOrderField.AuthorPhone,
	},
];

/**
 * Array of values for the "field" field in the order form of the BillingSetupSubmission model.
 */
export const fieldValues = fieldOptions.map((e) => e.value);

/**
 * Mapping from order fields of the BillingSetupSubmission model to their corresponding order type.
 */
export const fieldTypeMapping = {
	[BillingSetupSubmissionOrderField.NumPayments]: IndexForms.OrderFieldType.Number,
	[BillingSetupSubmissionOrderField.IsInterestedInBusPlan]: IndexForms.OrderFieldType.Boolean,
	[BillingSetupSubmissionOrderField.IsInterestedInMealPlan]: IndexForms.OrderFieldType.Boolean,
	[BillingSetupSubmissionOrderField.AuthorName]: IndexForms.OrderFieldType.String,
	[BillingSetupSubmissionOrderField.AuthorEmail]: IndexForms.OrderFieldType.String,
	[BillingSetupSubmissionOrderField.AuthorPhone]: IndexForms.OrderFieldType.String,
};

/**
 * Mapping from order fields of the BillingSetupSubmission model to their corresponding field name.
 */
export const orderFieldToModelField = {
	[BillingSetupSubmissionOrderField.NumPayments]: "numPayments",
	[BillingSetupSubmissionOrderField.IsInterestedInBusPlan]: "isInterestedInBusPlan",
	[BillingSetupSubmissionOrderField.IsInterestedInMealPlan]: "isInterestedInMealPlan",
	[BillingSetupSubmissionOrderField.AuthorName]: "authorName",
	[BillingSetupSubmissionOrderField.AuthorEmail]: "authorEmail",
	[BillingSetupSubmissionOrderField.AuthorPhone]: "authorPhone",
};

/**
 * Mapping from field names of the BillingSetupSubmission model to their corresponding order field.
 */
export const modelFieldToOrderField = {
	numPayments: BillingSetupSubmissionOrderField.NumPayments,
	isInterestedInBusPlan: BillingSetupSubmissionOrderField.IsInterestedInBusPlan,
	isInterestedInMealPlan: BillingSetupSubmissionOrderField.IsInterestedInMealPlan,
	authorName: BillingSetupSubmissionOrderField.AuthorName,
	authorEmail: BillingSetupSubmissionOrderField.AuthorEmail,
	authorPhone: BillingSetupSubmissionOrderField.AuthorPhone,
};
