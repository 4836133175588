import { useRouteParams } from "@hex-insights/router";
import {
	useCourseAttendanceRecordListForCoursePeriodQuery,
	useCourseSectionPeriodTeacherDetailQuery,
} from "@hex-insights/verita.shared";
import { ScheduleDayCoursePeriodPageRouteParams } from "./pageinfo";

export function usePageCourseSectionPeriodQuery() {
	const { courseSectionPeriodID, date } = useRouteParams<ScheduleDayCoursePeriodPageRouteParams>();
	return useCourseSectionPeriodTeacherDetailQuery({
		variables: { id: courseSectionPeriodID, date: date + "T00:00:00Z" },
	});
}

export function usePageCourseSectionPeriodAttendanceQuery() {
	const { courseSectionPeriodID, date } = useRouteParams<ScheduleDayCoursePeriodPageRouteParams>();
	return useCourseAttendanceRecordListForCoursePeriodQuery({
		variables: { courseSectionPeriodID, date: date + "T00:00:00Z" },
	});
}
