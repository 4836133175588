import React from "react";
import { FieldDisplayArgs, MultiSelectField, NumberField, SelectField } from "@hex-insights/forms";
import {
	ContractSelect,
	DiscountSelect,
	ServiceSelect,
	ServiceSubscriptionFormat,
	ServiceSubscriptionFormValues,
	useContractSelectLazyQuery,
	useDiscountSelectLazyQuery,
	useServiceSelectLazyQuery,
} from "../../../../Utilities";
import { ContractLink, DiscountLink, ServiceLink } from "../../../Links";
import { BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the ServiceSubscription model.
 */
type FieldProps<K extends keyof ServiceSubscriptionFormValues.Base = keyof ServiceSubscriptionFormValues.Base> =
	BaseFieldProps<Pick<ServiceSubscriptionFormValues.Base, K>>;

/**
 * Generic props for fields of the ServiceSubscription model that only appear in the detail form.
 */
type DetailFieldProps<
	K extends keyof ServiceSubscriptionFormValues.Detail = keyof ServiceSubscriptionFormValues.Detail,
> = BaseFieldProps<Pick<ServiceSubscriptionFormValues.Detail, K>>;

/**
 * Renders a field component for the `price` field of the ServiceSubscription model.
 */
export function Price({ formState }: FieldProps<"price">) {
	return (
		<NumberField
			formState={formState}
			name="price"
			hint="The base price of the service excluding any discounts."
			validationUnit={1}
			min={0}
			unit={100}
			format={ServiceSubscriptionFormat.Fields.price}
		/>
	);
}

export type DiscountsProps = DetailFieldProps<"discountIDs"> & {
	currentDiscounts?: DiscountSelect.ModelForOption[];
};

/**
 * Renders a field component for the `discounts` edge of the ServiceSubscription model.
 */
export function Discounts({ formState, currentDiscounts }: DiscountsProps) {
	const [loadOptions, { loading, data }] = useDiscountSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.discountIDs) {
			loadOptions();
		}
	}, [formState.formEditing.discountIDs, loadOptions]);
	const options = React.useMemo(
		() => DiscountSelect.toMultiOptions(data?.discountConnection.edges, currentDiscounts),
		[data, currentDiscounts],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="discountIDs"
			isLoading={loading}
			options={options}
			displayInstance={displayDiscountInstance}
		/>
	);
}

function displayDiscountInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <DiscountLink instance={{ id }}>{formattedValue}</DiscountLink>;
}

export type ServiceProps = FieldProps<"serviceID"> & {
	currentService?: ServiceSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `service` edge of the ServiceSubscription model.
 */
export function Service({ formState, currentService }: ServiceProps) {
	const [loadOptions, { loading, data }] = useServiceSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.serviceID) {
			loadOptions();
		}
	}, [formState.formEditing.serviceID, loadOptions]);
	const options = React.useMemo(
		() => ServiceSelect.toOptions(data?.serviceConnection.edges, currentService),
		[data, currentService],
	);

	return (
		<SelectField
			formState={formState}
			name="serviceID"
			isLoading={loading}
			options={options}
			display={displayService}
			blankValue={null}
		/>
	);
}

function displayService({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <ServiceLink instance={{ id }}>{formattedValue}</ServiceLink>;
}

export type ContractProps = FieldProps<"contractID"> & {
	currentContract?: ContractSelect.ModelForOption | null;
	disabled?: boolean;
};

/**
 * Renders a field component for the `contract` edge of the ServiceSubscription model.
 */
export function Contract({ formState, currentContract, disabled }: ContractProps) {
	const [loadOptions, { loading, data }] = useContractSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.contractID) {
			loadOptions();
		}
	}, [formState.formEditing.contractID, loadOptions]);
	const options = React.useMemo(
		() => ContractSelect.toOptions(data?.contractConnection.edges, currentContract),
		[data, currentContract],
	);

	return (
		<SelectField
			formState={formState}
			name="contractID"
			isLoading={loading}
			options={options}
			display={displayContract}
			blankValue={null}
			disabled={disabled}
		/>
	);
}

function displayContract({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <ContractLink instance={{ id }}>{formattedValue}</ContractLink>;
}
