import { IndexForms } from "@hex-insights/app-modules";
import { CaseStyle, convertCase } from "@hex-insights/core";
import { CourseAttendanceRecordOrderField, OrderDirection } from "../../GraphQL";

export type FormValues = IndexForms.OrderFormValues<CourseAttendanceRecordOrderField | null>;

export const initial: FormValues = {
	field: CourseAttendanceRecordOrderField.AttendanceDate,
	direction: OrderDirection.Desc,
};

/**
 * Options for the "field" field in the order form of the CourseAttendanceRecord model.
 */
export const fieldOptions = [
	{ label: "ID", value: null },
	{
		label: convertCase(CourseAttendanceRecordOrderField.AttendanceDate.toLowerCase(), CaseStyle.Title),
		value: CourseAttendanceRecordOrderField.AttendanceDate,
	},
	{
		label: convertCase(CourseAttendanceRecordOrderField.IsPresent.toLowerCase(), CaseStyle.Title),
		value: CourseAttendanceRecordOrderField.IsPresent,
	},
	{
		label: convertCase(CourseAttendanceRecordOrderField.IsLate.toLowerCase(), CaseStyle.Title),
		value: CourseAttendanceRecordOrderField.IsLate,
	},
	{
		label: convertCase(CourseAttendanceRecordOrderField.IsExcused.toLowerCase(), CaseStyle.Title),
		value: CourseAttendanceRecordOrderField.IsExcused,
	},
];

/**
 * Array of values for the "field" field in the order form of the CourseAttendanceRecord model.
 */
export const fieldValues = fieldOptions.map((e) => e.value);

/**
 * Mapping from order fields of the CourseAttendanceRecord model to their corresponding order type.
 */
export const fieldTypeMapping = {
	[CourseAttendanceRecordOrderField.AttendanceDate]: IndexForms.OrderFieldType.Time,
	[CourseAttendanceRecordOrderField.IsPresent]: IndexForms.OrderFieldType.Boolean,
	[CourseAttendanceRecordOrderField.IsLate]: IndexForms.OrderFieldType.Boolean,
	[CourseAttendanceRecordOrderField.IsExcused]: IndexForms.OrderFieldType.Boolean,
};

/**
 * Mapping from order fields of the CourseAttendanceRecord model to their corresponding field name.
 */
export const orderFieldToModelField = {
	[CourseAttendanceRecordOrderField.AttendanceDate]: "attendanceDate",
	[CourseAttendanceRecordOrderField.IsPresent]: "isPresent",
	[CourseAttendanceRecordOrderField.IsLate]: "isLate",
	[CourseAttendanceRecordOrderField.IsExcused]: "isExcused",
};

/**
 * Mapping from field names of the CourseAttendanceRecord model to their corresponding order field.
 */
export const modelFieldToOrderField = {
	attendanceDate: CourseAttendanceRecordOrderField.AttendanceDate,
	isPresent: CourseAttendanceRecordOrderField.IsPresent,
	isLate: CourseAttendanceRecordOrderField.IsLate,
	isExcused: CourseAttendanceRecordOrderField.IsExcused,
};
