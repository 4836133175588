import { newPageInfo } from "@hex-insights/router";

export type RootPageRouteParams = {};

export const rootPageInfo = newPageInfo({
	title: "Verita Teacher Portal",
	description: "",
	exact: false,
	to: "/",
	path: "/",
	parent: null,
});
