import { IndexForms } from "@hex-insights/app-modules";
import { CaseStyle, convertCase } from "@hex-insights/core";
import { OrderDirection, StudentOrderField } from "../../GraphQL";

export type FormValues = IndexForms.OrderFormValues<StudentOrderField | null>;

export const initial: FormValues = {
	field: null,
	direction: OrderDirection.Asc,
};

/**
 * Options for the "field" field in the order form of the Student model.
 */
export const fieldOptions = [
	{ label: "ID", value: null },
	{
		label: convertCase(StudentOrderField.GraduatingYear.toLowerCase(), CaseStyle.Title),
		value: StudentOrderField.GraduatingYear,
	},
	{
		label: convertCase(StudentOrderField.HasPreviousSchooling.toLowerCase(), CaseStyle.Title),
		value: StudentOrderField.HasPreviousSchooling,
	},
];

/**
 * Array of values for the "field" field in the order form of the Student model.
 */
export const fieldValues = fieldOptions.map((e) => e.value);

/**
 * Mapping from order fields of the Student model to their corresponding order type.
 */
export const fieldTypeMapping = {
	[StudentOrderField.GraduatingYear]: IndexForms.OrderFieldType.Number,
	[StudentOrderField.HasPreviousSchooling]: IndexForms.OrderFieldType.Boolean,
};

/**
 * Mapping from order fields of the Student model to their corresponding field name.
 */
export const orderFieldToModelField = {
	[StudentOrderField.GraduatingYear]: "graduatingYear",
	[StudentOrderField.HasPreviousSchooling]: "hasPreviousSchooling",
};

/**
 * Mapping from field names of the Student model to their corresponding order field.
 */
export const modelFieldToOrderField = {
	graduatingYear: StudentOrderField.GraduatingYear,
	hasPreviousSchooling: StudentOrderField.HasPreviousSchooling,
};
