import React from "react";
import { ApolloCache, FetchResult, gql } from "@apollo/client";
import {
	graphTypeNames,
	SchoolAttendanceRecord,
	SchoolAttendanceRecordCreateInput,
	SchoolAttendanceRecordCreateMutation,
	SchoolAttendanceRecordDeleteMutation,
	SchoolAttendanceRecordDetailDocument,
	SchoolAttendanceRecordDetailQuery,
	SchoolAttendanceRecordDetailQueryVariables,
	SchoolAttendanceRecordUpdateInput,
	SchoolAttendanceRecordUpdateMutation,
	Student,
	useSchoolAttendanceRecordCreateMutation,
	useSchoolAttendanceRecordDeleteMutation,
	useSchoolAttendanceRecordUpdateMutation,
} from "../../GraphQL";
import { SchoolAttendanceRecordFormConversion } from "../ModelFormConversion";
import { SchoolAttendanceRecordFormValues } from "../ModelFormValues";

/**
 * Returns a `create` function for the SchoolAttendanceRecord model. The `create` function translates the given
 * `formValues` to the GraphQL create input for the SchoolAttendanceRecord model.
 */
export function useCreate() {
	const [createSchoolAttendanceRecord] = useSchoolAttendanceRecordCreateMutation();

	return React.useCallback(
		async (formValues: SchoolAttendanceRecordFormValues.Create) => {
			const input = SchoolAttendanceRecordFormConversion.toGQLCreateInput(formValues);
			const updateCache = getUpdateCacheForCreate(input);

			const { data, errors } = await createSchoolAttendanceRecord({ variables: { input }, update: updateCache });

			return { data: data?.createSchoolAttendanceRecord ?? null, errors: errors ?? null };
		},
		[createSchoolAttendanceRecord],
	);
}

/**
 * Returns an `update` function for the SchoolAttendanceRecord model. The `update` function translates the given
 * `formValues` to the GraphQL update input for the SchoolAttendanceRecord model.
 *
 * @param id The ID of the instance to update.
 */
export function useUpdate(id: SchoolAttendanceRecord["id"]) {
	const [updateSchoolAttendanceRecord] = useSchoolAttendanceRecordUpdateMutation();

	return React.useCallback(
		async (
			changedFormValues: Partial<SchoolAttendanceRecordFormValues.Detail>,
			initialFormValues: SchoolAttendanceRecordFormValues.Detail,
		) => {
			const input = SchoolAttendanceRecordFormConversion.toGQLUpdateInput(changedFormValues, initialFormValues);
			const updateCache = getUpdateCacheForUpdate(input, initialFormValues);

			const { data, errors } = await updateSchoolAttendanceRecord({
				variables: { id, input },
				update: updateCache,
			});

			return { data: data?.updateSchoolAttendanceRecord ?? null, errors: errors ?? null };
		},
		[updateSchoolAttendanceRecord, id],
	);
}

/**
 * Returns a `del` function for the SchoolAttendanceRecord model.
 *
 * @param id The ID of the instance to delete.
 */
export function useDelete(id: SchoolAttendanceRecord["id"]) {
	const [deleteSchoolAttendanceRecord] = useSchoolAttendanceRecordDeleteMutation();

	return React.useCallback(async () => {
		const updateCache = getUpdateCacheForDelete(id);

		const { data, errors } = await deleteSchoolAttendanceRecord({ variables: { id }, update: updateCache });

		return { data: data?.deleteSchoolAttendanceRecord ?? false, errors: errors ?? null };
	}, [deleteSchoolAttendanceRecord, id]);
}

function getUpdateCacheForCreate(input: SchoolAttendanceRecordCreateInput) {
	return (
		cache: ApolloCache<any>,
		result: Omit<
			FetchResult<SchoolAttendanceRecordCreateMutation, Record<string, any>, Record<string, any>>,
			"context"
		>,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const createdObject = result.data.createSchoolAttendanceRecord;

		cache.writeQuery<SchoolAttendanceRecordDetailQuery, SchoolAttendanceRecordDetailQueryVariables>({
			query: SchoolAttendanceRecordDetailDocument,
			data: { schoolAttendanceRecord: createdObject },
			variables: { id: createdObject.id },
		});

		if (input.studentID) {
			addToSchoolAttendanceRecordsOfStudentCache(cache, input.studentID, createdObject);
		}

		cache.evict({ id: "ROOT_QUERY", fieldName: "schoolAttendanceRecordConnection" });
	};
}

function getUpdateCacheForUpdate(
	input: SchoolAttendanceRecordUpdateInput,
	initialFormValues: SchoolAttendanceRecordFormValues.Detail,
) {
	return (
		cache: ApolloCache<any>,
		result: Omit<
			FetchResult<SchoolAttendanceRecordUpdateMutation, Record<string, any>, Record<string, any>>,
			"context"
		>,
	) => {
		if (result.data === null || result.data === undefined) {
			return;
		}

		const updatedObject = result.data.updateSchoolAttendanceRecord;

		if (initialFormValues.studentID && input.studentID) {
			removeFromSchoolAttendanceRecordsOfStudentCache(cache, initialFormValues.studentID, updatedObject);
		}
		if (input.studentID) {
			addToSchoolAttendanceRecordsOfStudentCache(cache, input.studentID, updatedObject);
		}
	};
}

function getUpdateCacheForDelete(id: SchoolAttendanceRecord["id"]) {
	return (
		cache: ApolloCache<any>,
		result: Omit<
			FetchResult<SchoolAttendanceRecordDeleteMutation, Record<string, any>, Record<string, any>>,
			"context"
		>,
	) => {
		if (!result.data?.deleteSchoolAttendanceRecord) {
			return;
		}

		cache.evict({ id: cache.identify({ id, __typename: graphTypeNames.SchoolAttendanceRecord }) });
		cache.evict({ id: "ROOT_QUERY", fieldName: "schoolAttendanceRecordConnection" });
		cache.gc();
	};
}

function addToSchoolAttendanceRecordsOfStudentCache(
	cache: ApolloCache<any>,
	targetID: Student["id"],
	object: Pick<SchoolAttendanceRecord, "id">,
) {
	const objectRef = toCacheRef(cache, object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Student }),
		fields: {
			schoolAttendanceRecords: (cachedValue) => [...cachedValue, objectRef],
		},
	});
}

function removeFromSchoolAttendanceRecordsOfStudentCache(
	cache: ApolloCache<any>,
	targetID: Student["id"],
	object: Pick<SchoolAttendanceRecord, "id">,
) {
	const cacheID = cache.identify(object);
	cache.modify({
		id: cache.identify({ id: targetID, __typename: graphTypeNames.Student }),
		fields: {
			schoolAttendanceRecords: (cachedValue) => cachedValue.filter((e: any) => e.__ref !== cacheID),
		},
	});
}

function toCacheRef(cache: ApolloCache<any>, object: Pick<SchoolAttendanceRecord, "id">) {
	return cache.writeFragment({
		fragment: gql`
			fragment SchoolAttendanceRecordRef on SchoolAttendanceRecord {
				id
			}
		`,
		data: object,
	});
}
