import { CaseStyle, convertCase } from "@hex-insights/core";
import { Relationship } from "../../../GraphQL";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the relationshipType field of the Relationship model.
 */
export function relationshipType(value: Relationship["relationshipType"] | null): string {
	if (value === null) {
		return toString(value);
	}
	return convertCase(value, CaseStyle.Title);
}

/**
 * Formats the isPrimary field of the Relationship model.
 */
export function isPrimary(value: Relationship["isPrimary"]): string {
	return toString(value);
}

/**
 * Formats the isFinancialContact field of the Relationship model.
 */
export function isFinancialContact(value: Relationship["isFinancialContact"]): string {
	return toString(value);
}

/**
 * Formats the isEmergencyContact field of the Relationship model.
 */
export function isEmergencyContact(value: Relationship["isEmergencyContact"]): string {
	return toString(value);
}

/**
 * Formats the isAuthorizedForPickup field of the Relationship model.
 */
export function isAuthorizedForPickup(value: Relationship["isAuthorizedForPickup"]): string {
	return toString(value);
}

/**
 * Formats the livesWithStudent field of the Relationship model.
 */
export function livesWithStudent(value: Relationship["livesWithStudent"]): string {
	return toString(value);
}
