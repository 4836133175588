import { UserGroup } from "../../../GraphQL";
import { toString } from "../shared";

// Note: the functions defined in this file can be passed to model field components for consistent formatting.

/**
 * Formats the name field of the UserGroup model.
 */
export function name(value: UserGroup["name"]): string {
	return toString(value);
}

/**
 * Formats the description field of the UserGroup model.
 */
export function description(value: UserGroup["description"]): string {
	return toString(value);
}

/**
 * Formats the imageURL field of the Person model.
 */
export function imageURL(value: UserGroup["imageURL"]): string {
	return toString(value);
}

/**
 * Formats the color field of the UserGroup model.
 */
export function color(value: UserGroup["color"]): string {
	return toString(value);
}
