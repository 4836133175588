import { Authentication } from "@hex-insights/app-modules";
import { serverRequest } from "./network";

export async function requestAuthenticationInfo(): Promise<Authentication.AuthenticationInfo> {
	const response = await serverRequest("/auth/info");
	if (response.ok) {
		const data = await response.json();
		return data.data;
	}
	return Authentication.blankAuthenticationInfo;
}
