import { NullPartial } from "@hex-insights/core";
import {
	CourseAttendanceRecordCreateInput,
	CourseAttendanceRecordDetailQuery,
	CourseAttendanceRecordUpdateInput,
} from "../../GraphQL";
import { CourseAttendanceRecordFormValues } from "../ModelFormValues";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the CourseAttendanceRecord model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(
	formValues: CourseAttendanceRecordFormValues.Create,
): CourseAttendanceRecordCreateInput {
	const input: NullPartial<CourseAttendanceRecordCreateInput> = { ...formValues };
	return input as CourseAttendanceRecordCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the CourseAttendanceRecord model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<CourseAttendanceRecordFormValues.Detail>,
	_initialFormValues: CourseAttendanceRecordFormValues.Detail,
): CourseAttendanceRecordUpdateInput {
	const input: CourseAttendanceRecordUpdateInput = { ...formValues };
	return input;
}

/**
 * Converts a CourseAttendanceRecord instance to its corresponding detail form values.
 *
 * @param courseAttendanceRecord The instance to convert.
 */
export function toFormValues(
	courseAttendanceRecord: CourseAttendanceRecordDetailQuery["courseAttendanceRecord"],
): CourseAttendanceRecordFormValues.Detail {
	return {
		attendanceDate: courseAttendanceRecord.attendanceDate,
		isPresent: courseAttendanceRecord.isPresent,
		isLate: courseAttendanceRecord.isLate,
		isExcused: courseAttendanceRecord.isExcused,
		notes: courseAttendanceRecord.notes,
		courseSectionPeriodID: courseAttendanceRecord.courseSectionPeriod?.id ?? null,
		studentID: courseAttendanceRecord.student?.id ?? null,
	};
}
