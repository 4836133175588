import { ContractNameFragment } from "../../../GraphQL";
import * as Fields from "./fields";

export { Fields };

/**
 * Formats the full name of the given instance.
 */
export function name(instance: ContractNameFragment) {
	return Fields.agreementDate(instance.agreementDate);
}
