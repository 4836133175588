import { newPageInfo } from "@hex-insights/router";
import { CourseSection, CourseSectionFormat } from "@hex-insights/verita.shared";
import { lessonPlansWeekPageInfo, LessonPlansWeekPageRouteParams } from "../pageinfo";

export type LessonPlansWeekCourseSectionPageRouteParams = LessonPlansWeekPageRouteParams & {
	courseSectionID: string;
};

type CourseSectionForTitle = Pick<CourseSection, "name"> & {
	course: Pick<CourseSection["course"], "id" | "subject">;
	homeRoomSection: Pick<CourseSection["homeRoomSection"], "id" | "name">;
};

export const lessonPlansWeekCourseSectionPageInfo = newPageInfo({
	name: "Lesson plan course section view.",
	title: (e: CourseSectionForTitle) => CourseSectionFormat.name(e),
	description: "Course section lesson plans page.",
	exact: false,
	to: (date: string, id: CourseSection["id"]) => lessonPlansWeekPageInfo.to(date) + `/${id}`,
	path: lessonPlansWeekPageInfo.path + "/:courseSectionID(\\d+)",
	parent: lessonPlansWeekPageInfo,
});
