import { IndexForms } from "@hex-insights/app-modules";
import { TypeFilterObject } from "@hex-insights/core";

export type StandardFilterFormValues = {}; // TODO define quick filters

export type FormValues = StandardFilterFormValues & IndexForms.SearchFormValues & IndexForms.FilterFormValues;

export const initialStandardFilters: StandardFilterFormValues = {};

export const initial: FormValues = {
	...initialStandardFilters,
	...IndexForms.initialSearchFormValues,
	...IndexForms.initialFilterFormValues,
};

export function getInitial(): FormValues {
	return initial;
}

export const quickFiltersTypeFilterObject: TypeFilterObject<StandardFilterFormValues> = {};

export const typeFilterObject: TypeFilterObject<FormValues> = {
	...quickFiltersTypeFilterObject,
	...IndexForms.searchFormValuesFilterObject,
	...IndexForms.filterFormValuesFilterObject,
};

export const advancedFilterInformation: IndexForms.AdvancedFilterInformation = {
	fieldOptions: [
		{ value: "menuDate" },
		{ value: "breakfast" },
		{ value: "lunch" },
		{ value: "snack" },
		{ value: "vegetarianBreakfast" },
		{ value: "vegetarianLunch" },
		{ value: "vegetarianSnack" },
	],
	fieldComparisonOptions: {
		menuDate: IndexForms.dateComparisonOptions,
		breakfast: IndexForms.stringComparisonOptions,
		lunch: IndexForms.stringComparisonOptions,
		snack: IndexForms.stringComparisonOptions,
		vegetarianBreakfast: IndexForms.stringComparisonOptions,
		vegetarianLunch: IndexForms.stringComparisonOptions,
		vegetarianSnack: IndexForms.stringComparisonOptions,
	},
	fieldComparisonTranslations: {
		menuDate: IndexForms.dateComparisonTranslations,
		breakfast: IndexForms.stringComparisonTranslations,
		lunch: IndexForms.stringComparisonTranslations,
		snack: IndexForms.stringComparisonTranslations,
		vegetarianBreakfast: IndexForms.stringComparisonTranslations,
		vegetarianLunch: IndexForms.stringComparisonTranslations,
		vegetarianSnack: IndexForms.stringComparisonTranslations,
	},
};
