import { ClassNameProps, Icon, Row, StyleProps, toLocalDateString } from "@hex-insights/core";
import { Student, useSectionsActiveForStudentListQuery } from "../../Utilities";

type StudentEnrollmentStatusBadgeProps = {
	studentID: Student["id"];
} & Partial<ClassNameProps & StyleProps>;

export function StudentEnrollmentStatusBadge({ studentID, className, style }: StudentEnrollmentStatusBadgeProps) {
	const { loading: loadingSectionsData, data: sectionsData } = useSectionsActiveForStudentListQuery({
		variables: { studentID, activeDate: toLocalDateString(new Date()) },
	});
	// TODO currently enrolled handling
	const isEnrolled =
		sectionsData &&
		(sectionsData.student.homeRoomSectionStudentEnrollments.length > 0 ||
			sectionsData.student.courseSectionStudentEnrollments.length > 0);

	if (isEnrolled) {
		return (
			<Row
				justify="spaced-start"
				horizontalSpacing="0.25rem"
				align="center"
				className={className}
				style={{
					fontSize: "1rem",
					color: "var(--success-color)",
					...style,
				}}
			>
				<Icon.CheckCircle size="1rem" /> Enrolled
			</Row>
		);
	}

	return <div style={{ fontSize: "1rem", color: "#777", opacity: loadingSectionsData ? 0 : 1 }}>Not Enrolled</div>;
}
