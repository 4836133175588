import { LearningObjective, LearningObjectiveMark, LearningObjectiveMarkValue, ReportCard } from "../../GraphQL";

export type Base = {
	value: LearningObjectiveMark["value"] | null;
	learningObjectiveID: LearningObjective["id"] | null;
	reportCardID: ReportCard["id"] | null;
};

export type Create = Base;

export type Detail = Base;

export const initialCreate: Create = {
	value: null,
	learningObjectiveID: null,
	reportCardID: null,
};

export const valueOptions = [
	{ value: LearningObjectiveMarkValue.Developing },
	{ value: LearningObjectiveMarkValue.Meeting },
	{ value: LearningObjectiveMarkValue.Exceeding },
];
