import React from "react";
import {
	addTimeToDate,
	DateTime,
	dateTrunc,
	isBoolean,
	isSameTime,
	KeyWithValueType,
	stringToLocalDate,
	toLocalDateString,
} from "@hex-insights/core";
import { getDateRange } from "./date-time";

export function useWeekBounds() {
	const currentWeekStartDate = dateTrunc(new Date(), "week").toDate();
	const [startDate, setStartDate] = React.useState(currentWeekStartDate);
	const endDate = addTimeToDate(startDate, [6, "days"]);

	const onPrevWeekClick = React.useCallback(() => {
		setStartDate((prev) => addTimeToDate(prev, [-7, "days"]).toDate());
	}, []);
	const onCurrentWeekClick = React.useCallback(() => {
		setStartDate(currentWeekStartDate);
	}, [currentWeekStartDate]);
	const onNextWeekClick = React.useCallback(() => {
		setStartDate((prev) => addTimeToDate(prev, [7, "days"]).toDate());
	}, []);

	const isCurrentWeek = isSameTime(startDate, currentWeekStartDate, "day");

	return { startDate, endDate, isCurrentWeek, onPrevWeekClick, onCurrentWeekClick, onNextWeekClick };
}

/**
 * Converts a list of items (or item edges) with start and end times (either as time or day) to a map of dates to list
 * of items for that date.
 */
export function rangeDatedItemsToDateMap<T extends Record<any, any>>(
	items: (T | ObjectEdge<T>)[],
	startFieldName: KeyWithValueType<T, DateTime | null>,
	endFieldName: KeyWithValueType<T, DateTime | null>,
	isDayPrecision: ((item: T) => boolean) | boolean,
) {
	const isFixedDayPrecision = isBoolean(isDayPrecision);

	const dateItemsMap = {};
	for (let i = 0; i < items.length; i++) {
		const itemOrItemNode = items[i];
		const item = isObjectEdge(itemOrItemNode) ? itemOrItemNode.node : itemOrItemNode;

		let dateRange = [];
		if (isFixedDayPrecision ? isDayPrecision : isDayPrecision(item)) {
			dateRange = getDateRange(item[startFieldName], item[endFieldName]);
		} else {
			const startDate = dateTrunc(item[startFieldName], "day");
			const endDate = dateTrunc(item[endFieldName], "day");
			if (isSameTime(startDate, endDate)) {
				dateRange = [toLocalDateString(startDate)];
			} else {
				dateRange = getDateRange(dateTrunc(item[startFieldName], "day"), dateTrunc(item[endFieldName], "day"));
			}
		}

		for (let j = 0; j < dateRange.length; j++) {
			const date = dateToDateMapKey(dateRange[j]);
			if (!dateItemsMap[date]) {
				dateItemsMap[date] = [];
			}
			dateItemsMap[date].push(item);
		}
	}

	return dateItemsMap;
}

export function dateToDateMapKey(date: string) {
	return toLocalDateString(stringToLocalDate(date, "day"));
}

/**
 * Converts a list of items (or item edges) with a time field (either as time or day) to a map of dates to list of items
 * for that date.
 */
export function datedItemsToDateMap<T extends Record<any, any>>(
	items: (T | ObjectEdge<T>)[],
	dateFieldName: KeyWithValueType<T, DateTime | null>,
	isDayPrecision: ((item: T) => boolean) | boolean,
) {
	const isFixedDayPrecision = isBoolean(isDayPrecision);

	const dateItemsMap: Record<string, T[]> = {};
	for (let i = 0; i < items.length; i++) {
		const itemOrItemNode = items[i];
		const item = isObjectEdge(itemOrItemNode) ? itemOrItemNode.node : itemOrItemNode;

		let date = "";
		if (isFixedDayPrecision ? isDayPrecision : isDayPrecision(item)) {
			date = item[dateFieldName];
		} else {
			date = toLocalDateString(item[dateFieldName]);
		}

		if (!dateItemsMap[date]) {
			dateItemsMap[date] = [];
		}
		dateItemsMap[date].push(item);
	}

	return dateItemsMap;
}

type ObjectEdge<T extends Record<any, any>> = { node: T };

function isObjectEdge<T extends Record<any, any>>(e: T | ObjectEdge<T>): e is ObjectEdge<T> {
	return e.hasOwnProperty("node");
}
