import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: string;
	String: string;
	Boolean: boolean;
	Int: number;
	Float: number;
	Bytes: string;
	Cursor: string;
	Map: Record<string, any>;
	Time: string;
	Upload: File;
};

export type AcademicYear = Node & {
	__typename?: "AcademicYear";
	billingSetupSubmissions: Array<BillingSetupSubmission>;
	id: Scalars["ID"];
	name: Scalars["String"];
	schoolStudentEnrollments: Array<SchoolStudentEnrollment>;
	terms: Array<Term>;
};

export type AcademicYearBillingSetupSubmissionsArgs = {
	filters?: InputMaybe<Array<BillingSetupSubmissionFilterInput>>;
	order?: InputMaybe<BillingSetupSubmissionOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type AcademicYearSchoolStudentEnrollmentsArgs = {
	filters?: InputMaybe<Array<SchoolStudentEnrollmentFilterInput>>;
	order?: InputMaybe<SchoolStudentEnrollmentOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type AcademicYearTermsArgs = {
	filters?: InputMaybe<Array<TermFilterInput>>;
	order?: InputMaybe<TermOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type AcademicYearConnection = {
	__typename?: "AcademicYearConnection";
	edges: Array<AcademicYearEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type AcademicYearCreateInput = {
	billingSetupSubmissionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	billingSetupSubmissions?: InputMaybe<Array<BillingSetupSubmissionInAcademicYearCreateInput>>;
	name: Scalars["String"];
	schoolStudentEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	schoolStudentEnrollments?: InputMaybe<Array<SchoolStudentEnrollmentInAcademicYearCreateInput>>;
	termIDs?: InputMaybe<Array<Scalars["ID"]>>;
	terms?: InputMaybe<Array<TermInAcademicYearCreateInput>>;
};

export type AcademicYearEdge = {
	__typename?: "AcademicYearEdge";
	cursor: Scalars["Cursor"];
	node: AcademicYear;
};

export type AcademicYearFilterInput = {
	and?: InputMaybe<Array<AcademicYearFilterInput>>;
	billingSetupSubmissions?: InputMaybe<Array<BillingSetupSubmissionFilterInput>>;
	hasBillingSetupSubmissions?: InputMaybe<Scalars["Boolean"]>;
	hasSchoolStudentEnrollments?: InputMaybe<Scalars["Boolean"]>;
	hasTerms?: InputMaybe<Scalars["Boolean"]>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	nameContains?: InputMaybe<Scalars["String"]>;
	nameEQ?: InputMaybe<Scalars["String"]>;
	nameHasPrefix?: InputMaybe<Scalars["String"]>;
	nameHasSuffix?: InputMaybe<Scalars["String"]>;
	nameIn?: InputMaybe<Array<Scalars["String"]>>;
	nameNEQ?: InputMaybe<Scalars["String"]>;
	nameNotContains?: InputMaybe<Scalars["String"]>;
	nameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	nameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	nameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	not?: InputMaybe<AcademicYearFilterInput>;
	or?: InputMaybe<Array<AcademicYearFilterInput>>;
	schoolStudentEnrollments?: InputMaybe<Array<SchoolStudentEnrollmentFilterInput>>;
	search?: InputMaybe<Scalars["String"]>;
	terms?: InputMaybe<Array<TermFilterInput>>;
};

export type AcademicYearHistory = Node & {
	__typename?: "AcademicYearHistory";
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	name: Maybe<Scalars["String"]>;
	originalID: Maybe<Scalars["ID"]>;
};

export type AcademicYearHistoryConnection = {
	__typename?: "AcademicYearHistoryConnection";
	edges: Array<AcademicYearHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type AcademicYearHistoryEdge = {
	__typename?: "AcademicYearHistoryEdge";
	cursor: Scalars["Cursor"];
	node: AcademicYearHistory;
};

export type AcademicYearHistoryFilterInput = {
	and?: InputMaybe<Array<AcademicYearHistoryFilterInput>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	nameContains?: InputMaybe<Scalars["String"]>;
	nameEQ?: InputMaybe<Scalars["String"]>;
	nameHasPrefix?: InputMaybe<Scalars["String"]>;
	nameHasSuffix?: InputMaybe<Scalars["String"]>;
	nameIn?: InputMaybe<Array<Scalars["String"]>>;
	nameIsNull?: InputMaybe<Scalars["Boolean"]>;
	nameNEQ?: InputMaybe<Scalars["String"]>;
	nameNotContains?: InputMaybe<Scalars["String"]>;
	nameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	nameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	nameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	not?: InputMaybe<AcademicYearHistoryFilterInput>;
	or?: InputMaybe<Array<AcademicYearHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	search?: InputMaybe<Scalars["String"]>;
};

export type AcademicYearHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<AcademicYearHistoryOrderField>;
};

export enum AcademicYearHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type AcademicYearOrder = {
	direction: OrderDirection;
	field?: InputMaybe<AcademicYearOrderField>;
};

export enum AcademicYearOrderField {
	Name = "NAME",
}

export type AcademicYearTuition = Node & {
	__typename?: "AcademicYearTuition";
	id: Scalars["ID"];
	nurseryPrice: Scalars["Int"];
	primaryPrice: Scalars["Int"];
	receptionPrice: Scalars["Int"];
	secondaryPrice: Scalars["Int"];
	tuition: Tuition;
	tuitionID: Scalars["ID"];
	year: Scalars["Int"];
};

export type AcademicYearTuitionConnection = {
	__typename?: "AcademicYearTuitionConnection";
	edges: Array<AcademicYearTuitionEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type AcademicYearTuitionCreateInput = {
	nurseryPrice: Scalars["Int"];
	primaryPrice: Scalars["Int"];
	receptionPrice: Scalars["Int"];
	secondaryPrice: Scalars["Int"];
	tuitionID: Scalars["ID"];
	year: Scalars["Int"];
};

export type AcademicYearTuitionEdge = {
	__typename?: "AcademicYearTuitionEdge";
	cursor: Scalars["Cursor"];
	node: AcademicYearTuition;
};

export type AcademicYearTuitionFilterInput = {
	and?: InputMaybe<Array<AcademicYearTuitionFilterInput>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<AcademicYearTuitionFilterInput>;
	nurseryPriceEQ?: InputMaybe<Scalars["Int"]>;
	nurseryPriceGT?: InputMaybe<Scalars["Int"]>;
	nurseryPriceGTE?: InputMaybe<Scalars["Int"]>;
	nurseryPriceIn?: InputMaybe<Array<Scalars["Int"]>>;
	nurseryPriceLT?: InputMaybe<Scalars["Int"]>;
	nurseryPriceLTE?: InputMaybe<Scalars["Int"]>;
	nurseryPriceNEQ?: InputMaybe<Scalars["Int"]>;
	nurseryPriceNotIn?: InputMaybe<Array<Scalars["Int"]>>;
	or?: InputMaybe<Array<AcademicYearTuitionFilterInput>>;
	primaryPriceEQ?: InputMaybe<Scalars["Int"]>;
	primaryPriceGT?: InputMaybe<Scalars["Int"]>;
	primaryPriceGTE?: InputMaybe<Scalars["Int"]>;
	primaryPriceIn?: InputMaybe<Array<Scalars["Int"]>>;
	primaryPriceLT?: InputMaybe<Scalars["Int"]>;
	primaryPriceLTE?: InputMaybe<Scalars["Int"]>;
	primaryPriceNEQ?: InputMaybe<Scalars["Int"]>;
	primaryPriceNotIn?: InputMaybe<Array<Scalars["Int"]>>;
	receptionPriceEQ?: InputMaybe<Scalars["Int"]>;
	receptionPriceGT?: InputMaybe<Scalars["Int"]>;
	receptionPriceGTE?: InputMaybe<Scalars["Int"]>;
	receptionPriceIn?: InputMaybe<Array<Scalars["Int"]>>;
	receptionPriceLT?: InputMaybe<Scalars["Int"]>;
	receptionPriceLTE?: InputMaybe<Scalars["Int"]>;
	receptionPriceNEQ?: InputMaybe<Scalars["Int"]>;
	receptionPriceNotIn?: InputMaybe<Array<Scalars["Int"]>>;
	secondaryPriceEQ?: InputMaybe<Scalars["Int"]>;
	secondaryPriceGT?: InputMaybe<Scalars["Int"]>;
	secondaryPriceGTE?: InputMaybe<Scalars["Int"]>;
	secondaryPriceIn?: InputMaybe<Array<Scalars["Int"]>>;
	secondaryPriceLT?: InputMaybe<Scalars["Int"]>;
	secondaryPriceLTE?: InputMaybe<Scalars["Int"]>;
	secondaryPriceNEQ?: InputMaybe<Scalars["Int"]>;
	secondaryPriceNotIn?: InputMaybe<Array<Scalars["Int"]>>;
	tuition?: InputMaybe<Array<TuitionFilterInput>>;
	tuitionIDEQ?: InputMaybe<Scalars["ID"]>;
	tuitionIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	tuitionIDNEQ?: InputMaybe<Scalars["ID"]>;
	tuitionIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	yearEQ?: InputMaybe<Scalars["Int"]>;
	yearGT?: InputMaybe<Scalars["Int"]>;
	yearGTE?: InputMaybe<Scalars["Int"]>;
	yearIn?: InputMaybe<Array<Scalars["Int"]>>;
	yearLT?: InputMaybe<Scalars["Int"]>;
	yearLTE?: InputMaybe<Scalars["Int"]>;
	yearNEQ?: InputMaybe<Scalars["Int"]>;
	yearNotIn?: InputMaybe<Array<Scalars["Int"]>>;
};

export type AcademicYearTuitionHistory = Node & {
	__typename?: "AcademicYearTuitionHistory";
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	nurseryPrice: Maybe<Scalars["Int"]>;
	originalID: Maybe<Scalars["ID"]>;
	primaryPrice: Maybe<Scalars["Int"]>;
	receptionPrice: Maybe<Scalars["Int"]>;
	secondaryPrice: Maybe<Scalars["Int"]>;
	tuitionID: Maybe<Scalars["ID"]>;
	year: Maybe<Scalars["Int"]>;
};

export type AcademicYearTuitionHistoryConnection = {
	__typename?: "AcademicYearTuitionHistoryConnection";
	edges: Array<AcademicYearTuitionHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type AcademicYearTuitionHistoryEdge = {
	__typename?: "AcademicYearTuitionHistoryEdge";
	cursor: Scalars["Cursor"];
	node: AcademicYearTuitionHistory;
};

export type AcademicYearTuitionHistoryFilterInput = {
	and?: InputMaybe<Array<AcademicYearTuitionHistoryFilterInput>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<AcademicYearTuitionHistoryFilterInput>;
	nurseryPriceEQ?: InputMaybe<Scalars["Int"]>;
	nurseryPriceGT?: InputMaybe<Scalars["Int"]>;
	nurseryPriceGTE?: InputMaybe<Scalars["Int"]>;
	nurseryPriceIn?: InputMaybe<Array<Scalars["Int"]>>;
	nurseryPriceIsNull?: InputMaybe<Scalars["Boolean"]>;
	nurseryPriceLT?: InputMaybe<Scalars["Int"]>;
	nurseryPriceLTE?: InputMaybe<Scalars["Int"]>;
	nurseryPriceNEQ?: InputMaybe<Scalars["Int"]>;
	nurseryPriceNotIn?: InputMaybe<Array<Scalars["Int"]>>;
	or?: InputMaybe<Array<AcademicYearTuitionHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	primaryPriceEQ?: InputMaybe<Scalars["Int"]>;
	primaryPriceGT?: InputMaybe<Scalars["Int"]>;
	primaryPriceGTE?: InputMaybe<Scalars["Int"]>;
	primaryPriceIn?: InputMaybe<Array<Scalars["Int"]>>;
	primaryPriceIsNull?: InputMaybe<Scalars["Boolean"]>;
	primaryPriceLT?: InputMaybe<Scalars["Int"]>;
	primaryPriceLTE?: InputMaybe<Scalars["Int"]>;
	primaryPriceNEQ?: InputMaybe<Scalars["Int"]>;
	primaryPriceNotIn?: InputMaybe<Array<Scalars["Int"]>>;
	receptionPriceEQ?: InputMaybe<Scalars["Int"]>;
	receptionPriceGT?: InputMaybe<Scalars["Int"]>;
	receptionPriceGTE?: InputMaybe<Scalars["Int"]>;
	receptionPriceIn?: InputMaybe<Array<Scalars["Int"]>>;
	receptionPriceIsNull?: InputMaybe<Scalars["Boolean"]>;
	receptionPriceLT?: InputMaybe<Scalars["Int"]>;
	receptionPriceLTE?: InputMaybe<Scalars["Int"]>;
	receptionPriceNEQ?: InputMaybe<Scalars["Int"]>;
	receptionPriceNotIn?: InputMaybe<Array<Scalars["Int"]>>;
	secondaryPriceEQ?: InputMaybe<Scalars["Int"]>;
	secondaryPriceGT?: InputMaybe<Scalars["Int"]>;
	secondaryPriceGTE?: InputMaybe<Scalars["Int"]>;
	secondaryPriceIn?: InputMaybe<Array<Scalars["Int"]>>;
	secondaryPriceIsNull?: InputMaybe<Scalars["Boolean"]>;
	secondaryPriceLT?: InputMaybe<Scalars["Int"]>;
	secondaryPriceLTE?: InputMaybe<Scalars["Int"]>;
	secondaryPriceNEQ?: InputMaybe<Scalars["Int"]>;
	secondaryPriceNotIn?: InputMaybe<Array<Scalars["Int"]>>;
	tuitionIDEQ?: InputMaybe<Scalars["ID"]>;
	tuitionIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	tuitionIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	tuitionIDNEQ?: InputMaybe<Scalars["ID"]>;
	tuitionIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	yearEQ?: InputMaybe<Scalars["Int"]>;
	yearGT?: InputMaybe<Scalars["Int"]>;
	yearGTE?: InputMaybe<Scalars["Int"]>;
	yearIn?: InputMaybe<Array<Scalars["Int"]>>;
	yearIsNull?: InputMaybe<Scalars["Boolean"]>;
	yearLT?: InputMaybe<Scalars["Int"]>;
	yearLTE?: InputMaybe<Scalars["Int"]>;
	yearNEQ?: InputMaybe<Scalars["Int"]>;
	yearNotIn?: InputMaybe<Array<Scalars["Int"]>>;
};

export type AcademicYearTuitionHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<AcademicYearTuitionHistoryOrderField>;
};

export enum AcademicYearTuitionHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type AcademicYearTuitionInTuitionCreateInput = {
	nurseryPrice: Scalars["Int"];
	primaryPrice: Scalars["Int"];
	receptionPrice: Scalars["Int"];
	secondaryPrice: Scalars["Int"];
	year: Scalars["Int"];
};

export type AcademicYearTuitionOrder = {
	direction: OrderDirection;
	field?: InputMaybe<AcademicYearTuitionOrderField>;
};

export enum AcademicYearTuitionOrderField {
	NurseryPrice = "NURSERY_PRICE",
	PrimaryPrice = "PRIMARY_PRICE",
	ReceptionPrice = "RECEPTION_PRICE",
	SecondaryPrice = "SECONDARY_PRICE",
}

export type AcademicYearTuitionUpdateBulkInput = {
	nurseryPrice?: InputMaybe<Scalars["Int"]>;
	primaryPrice?: InputMaybe<Scalars["Int"]>;
	receptionPrice?: InputMaybe<Scalars["Int"]>;
	secondaryPrice?: InputMaybe<Scalars["Int"]>;
	tuitionID?: InputMaybe<Scalars["ID"]>;
	year?: InputMaybe<Scalars["Int"]>;
};

export type AcademicYearTuitionUpdateInput = {
	nurseryPrice?: InputMaybe<Scalars["Int"]>;
	primaryPrice?: InputMaybe<Scalars["Int"]>;
	receptionPrice?: InputMaybe<Scalars["Int"]>;
	secondaryPrice?: InputMaybe<Scalars["Int"]>;
	tuitionID?: InputMaybe<Scalars["ID"]>;
	year?: InputMaybe<Scalars["Int"]>;
};

export type AcademicYearUpdateBulkInput = {
	addBillingSetupSubmissionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addSchoolStudentEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addTermIDs?: InputMaybe<Array<Scalars["ID"]>>;
	name?: InputMaybe<Scalars["String"]>;
	removeBillingSetupSubmissionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeSchoolStudentEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeTermIDs?: InputMaybe<Array<Scalars["ID"]>>;
};

export type AcademicYearUpdateInput = {
	addBillingSetupSubmissionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addSchoolStudentEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addTermIDs?: InputMaybe<Array<Scalars["ID"]>>;
	name?: InputMaybe<Scalars["String"]>;
	removeBillingSetupSubmissionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeSchoolStudentEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeTermIDs?: InputMaybe<Array<Scalars["ID"]>>;
};

export type Address = Node & {
	__typename?: "Address";
	addressLine1: Scalars["String"];
	addressLine2: Scalars["String"];
	city: Scalars["String"];
	country: Scalars["String"];
	fullAddress: Scalars["String"];
	id: Scalars["ID"];
	people: Array<Person>;
	postalCode: Scalars["String"];
	shortAddress: Scalars["String"];
	stateOrProvince: Scalars["String"];
};

export type AddressPeopleArgs = {
	filters?: InputMaybe<Array<PersonFilterInput>>;
	order?: InputMaybe<PersonOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type AddressConnection = {
	__typename?: "AddressConnection";
	edges: Array<AddressEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type AddressCreateInput = {
	addressLine1: Scalars["String"];
	addressLine2?: InputMaybe<Scalars["String"]>;
	city: Scalars["String"];
	country: Scalars["String"];
	personIDs?: InputMaybe<Array<Scalars["ID"]>>;
	postalCode: Scalars["String"];
	stateOrProvince?: InputMaybe<Scalars["String"]>;
};

export type AddressEdge = {
	__typename?: "AddressEdge";
	cursor: Scalars["Cursor"];
	node: Address;
};

export type AddressFilterInput = {
	addressLine1Contains?: InputMaybe<Scalars["String"]>;
	addressLine1EQ?: InputMaybe<Scalars["String"]>;
	addressLine1HasPrefix?: InputMaybe<Scalars["String"]>;
	addressLine1HasSuffix?: InputMaybe<Scalars["String"]>;
	addressLine1In?: InputMaybe<Array<Scalars["String"]>>;
	addressLine1NEQ?: InputMaybe<Scalars["String"]>;
	addressLine1NotContains?: InputMaybe<Scalars["String"]>;
	addressLine1NotHasPrefix?: InputMaybe<Scalars["String"]>;
	addressLine1NotHasSuffix?: InputMaybe<Scalars["String"]>;
	addressLine1NotIn?: InputMaybe<Array<Scalars["String"]>>;
	addressLine2Contains?: InputMaybe<Scalars["String"]>;
	addressLine2EQ?: InputMaybe<Scalars["String"]>;
	addressLine2HasPrefix?: InputMaybe<Scalars["String"]>;
	addressLine2HasSuffix?: InputMaybe<Scalars["String"]>;
	addressLine2In?: InputMaybe<Array<Scalars["String"]>>;
	addressLine2NEQ?: InputMaybe<Scalars["String"]>;
	addressLine2NotContains?: InputMaybe<Scalars["String"]>;
	addressLine2NotHasPrefix?: InputMaybe<Scalars["String"]>;
	addressLine2NotHasSuffix?: InputMaybe<Scalars["String"]>;
	addressLine2NotIn?: InputMaybe<Array<Scalars["String"]>>;
	and?: InputMaybe<Array<AddressFilterInput>>;
	cityContains?: InputMaybe<Scalars["String"]>;
	cityEQ?: InputMaybe<Scalars["String"]>;
	cityHasPrefix?: InputMaybe<Scalars["String"]>;
	cityHasSuffix?: InputMaybe<Scalars["String"]>;
	cityIn?: InputMaybe<Array<Scalars["String"]>>;
	cityNEQ?: InputMaybe<Scalars["String"]>;
	cityNotContains?: InputMaybe<Scalars["String"]>;
	cityNotHasPrefix?: InputMaybe<Scalars["String"]>;
	cityNotHasSuffix?: InputMaybe<Scalars["String"]>;
	cityNotIn?: InputMaybe<Array<Scalars["String"]>>;
	countryContains?: InputMaybe<Scalars["String"]>;
	countryEQ?: InputMaybe<Scalars["String"]>;
	countryHasPrefix?: InputMaybe<Scalars["String"]>;
	countryHasSuffix?: InputMaybe<Scalars["String"]>;
	countryIn?: InputMaybe<Array<Scalars["String"]>>;
	countryNEQ?: InputMaybe<Scalars["String"]>;
	countryNotContains?: InputMaybe<Scalars["String"]>;
	countryNotHasPrefix?: InputMaybe<Scalars["String"]>;
	countryNotHasSuffix?: InputMaybe<Scalars["String"]>;
	countryNotIn?: InputMaybe<Array<Scalars["String"]>>;
	hasPeople?: InputMaybe<Scalars["Boolean"]>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<AddressFilterInput>;
	or?: InputMaybe<Array<AddressFilterInput>>;
	people?: InputMaybe<Array<PersonFilterInput>>;
	postalCodeContains?: InputMaybe<Scalars["String"]>;
	postalCodeEQ?: InputMaybe<Scalars["String"]>;
	postalCodeHasPrefix?: InputMaybe<Scalars["String"]>;
	postalCodeHasSuffix?: InputMaybe<Scalars["String"]>;
	postalCodeIn?: InputMaybe<Array<Scalars["String"]>>;
	postalCodeNEQ?: InputMaybe<Scalars["String"]>;
	postalCodeNotContains?: InputMaybe<Scalars["String"]>;
	postalCodeNotHasPrefix?: InputMaybe<Scalars["String"]>;
	postalCodeNotHasSuffix?: InputMaybe<Scalars["String"]>;
	postalCodeNotIn?: InputMaybe<Array<Scalars["String"]>>;
	search?: InputMaybe<Scalars["String"]>;
	stateOrProvinceContains?: InputMaybe<Scalars["String"]>;
	stateOrProvinceEQ?: InputMaybe<Scalars["String"]>;
	stateOrProvinceHasPrefix?: InputMaybe<Scalars["String"]>;
	stateOrProvinceHasSuffix?: InputMaybe<Scalars["String"]>;
	stateOrProvinceIn?: InputMaybe<Array<Scalars["String"]>>;
	stateOrProvinceNEQ?: InputMaybe<Scalars["String"]>;
	stateOrProvinceNotContains?: InputMaybe<Scalars["String"]>;
	stateOrProvinceNotHasPrefix?: InputMaybe<Scalars["String"]>;
	stateOrProvinceNotHasSuffix?: InputMaybe<Scalars["String"]>;
	stateOrProvinceNotIn?: InputMaybe<Array<Scalars["String"]>>;
};

export type AddressHistory = Node & {
	__typename?: "AddressHistory";
	addressLine1: Maybe<Scalars["String"]>;
	addressLine2: Maybe<Scalars["String"]>;
	city: Maybe<Scalars["String"]>;
	country: Maybe<Scalars["String"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	originalID: Maybe<Scalars["ID"]>;
	postalCode: Maybe<Scalars["String"]>;
	stateOrProvince: Maybe<Scalars["String"]>;
};

export type AddressHistoryConnection = {
	__typename?: "AddressHistoryConnection";
	edges: Array<AddressHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type AddressHistoryEdge = {
	__typename?: "AddressHistoryEdge";
	cursor: Scalars["Cursor"];
	node: AddressHistory;
};

export type AddressHistoryFilterInput = {
	addressLine1Contains?: InputMaybe<Scalars["String"]>;
	addressLine1EQ?: InputMaybe<Scalars["String"]>;
	addressLine1HasPrefix?: InputMaybe<Scalars["String"]>;
	addressLine1HasSuffix?: InputMaybe<Scalars["String"]>;
	addressLine1In?: InputMaybe<Array<Scalars["String"]>>;
	addressLine1IsNull?: InputMaybe<Scalars["Boolean"]>;
	addressLine1NEQ?: InputMaybe<Scalars["String"]>;
	addressLine1NotContains?: InputMaybe<Scalars["String"]>;
	addressLine1NotHasPrefix?: InputMaybe<Scalars["String"]>;
	addressLine1NotHasSuffix?: InputMaybe<Scalars["String"]>;
	addressLine1NotIn?: InputMaybe<Array<Scalars["String"]>>;
	addressLine2Contains?: InputMaybe<Scalars["String"]>;
	addressLine2EQ?: InputMaybe<Scalars["String"]>;
	addressLine2HasPrefix?: InputMaybe<Scalars["String"]>;
	addressLine2HasSuffix?: InputMaybe<Scalars["String"]>;
	addressLine2In?: InputMaybe<Array<Scalars["String"]>>;
	addressLine2IsNull?: InputMaybe<Scalars["Boolean"]>;
	addressLine2NEQ?: InputMaybe<Scalars["String"]>;
	addressLine2NotContains?: InputMaybe<Scalars["String"]>;
	addressLine2NotHasPrefix?: InputMaybe<Scalars["String"]>;
	addressLine2NotHasSuffix?: InputMaybe<Scalars["String"]>;
	addressLine2NotIn?: InputMaybe<Array<Scalars["String"]>>;
	and?: InputMaybe<Array<AddressHistoryFilterInput>>;
	cityContains?: InputMaybe<Scalars["String"]>;
	cityEQ?: InputMaybe<Scalars["String"]>;
	cityHasPrefix?: InputMaybe<Scalars["String"]>;
	cityHasSuffix?: InputMaybe<Scalars["String"]>;
	cityIn?: InputMaybe<Array<Scalars["String"]>>;
	cityIsNull?: InputMaybe<Scalars["Boolean"]>;
	cityNEQ?: InputMaybe<Scalars["String"]>;
	cityNotContains?: InputMaybe<Scalars["String"]>;
	cityNotHasPrefix?: InputMaybe<Scalars["String"]>;
	cityNotHasSuffix?: InputMaybe<Scalars["String"]>;
	cityNotIn?: InputMaybe<Array<Scalars["String"]>>;
	countryContains?: InputMaybe<Scalars["String"]>;
	countryEQ?: InputMaybe<Scalars["String"]>;
	countryHasPrefix?: InputMaybe<Scalars["String"]>;
	countryHasSuffix?: InputMaybe<Scalars["String"]>;
	countryIn?: InputMaybe<Array<Scalars["String"]>>;
	countryIsNull?: InputMaybe<Scalars["Boolean"]>;
	countryNEQ?: InputMaybe<Scalars["String"]>;
	countryNotContains?: InputMaybe<Scalars["String"]>;
	countryNotHasPrefix?: InputMaybe<Scalars["String"]>;
	countryNotHasSuffix?: InputMaybe<Scalars["String"]>;
	countryNotIn?: InputMaybe<Array<Scalars["String"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<AddressHistoryFilterInput>;
	or?: InputMaybe<Array<AddressHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	postalCodeContains?: InputMaybe<Scalars["String"]>;
	postalCodeEQ?: InputMaybe<Scalars["String"]>;
	postalCodeHasPrefix?: InputMaybe<Scalars["String"]>;
	postalCodeHasSuffix?: InputMaybe<Scalars["String"]>;
	postalCodeIn?: InputMaybe<Array<Scalars["String"]>>;
	postalCodeIsNull?: InputMaybe<Scalars["Boolean"]>;
	postalCodeNEQ?: InputMaybe<Scalars["String"]>;
	postalCodeNotContains?: InputMaybe<Scalars["String"]>;
	postalCodeNotHasPrefix?: InputMaybe<Scalars["String"]>;
	postalCodeNotHasSuffix?: InputMaybe<Scalars["String"]>;
	postalCodeNotIn?: InputMaybe<Array<Scalars["String"]>>;
	search?: InputMaybe<Scalars["String"]>;
	stateOrProvinceContains?: InputMaybe<Scalars["String"]>;
	stateOrProvinceEQ?: InputMaybe<Scalars["String"]>;
	stateOrProvinceHasPrefix?: InputMaybe<Scalars["String"]>;
	stateOrProvinceHasSuffix?: InputMaybe<Scalars["String"]>;
	stateOrProvinceIn?: InputMaybe<Array<Scalars["String"]>>;
	stateOrProvinceIsNull?: InputMaybe<Scalars["Boolean"]>;
	stateOrProvinceNEQ?: InputMaybe<Scalars["String"]>;
	stateOrProvinceNotContains?: InputMaybe<Scalars["String"]>;
	stateOrProvinceNotHasPrefix?: InputMaybe<Scalars["String"]>;
	stateOrProvinceNotHasSuffix?: InputMaybe<Scalars["String"]>;
	stateOrProvinceNotIn?: InputMaybe<Array<Scalars["String"]>>;
};

export type AddressHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<AddressHistoryOrderField>;
};

export enum AddressHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type AddressOrder = {
	direction: OrderDirection;
	field?: InputMaybe<AddressOrderField>;
};

export enum AddressOrderField {
	AddressLine1 = "ADDRESS_LINE_1",
	AddressLine2 = "ADDRESS_LINE_2",
	City = "CITY",
	Country = "COUNTRY",
	PostalCode = "POSTAL_CODE",
	StateOrProvince = "STATE_OR_PROVINCE",
}

export type AddressUpdateBulkInput = {
	addPersonIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addressLine1?: InputMaybe<Scalars["String"]>;
	addressLine2?: InputMaybe<Scalars["String"]>;
	city?: InputMaybe<Scalars["String"]>;
	country?: InputMaybe<Scalars["String"]>;
	postalCode?: InputMaybe<Scalars["String"]>;
	removePersonIDs?: InputMaybe<Array<Scalars["ID"]>>;
	stateOrProvince?: InputMaybe<Scalars["String"]>;
};

export type AddressUpdateInput = {
	addPersonIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addressLine1?: InputMaybe<Scalars["String"]>;
	addressLine2?: InputMaybe<Scalars["String"]>;
	city?: InputMaybe<Scalars["String"]>;
	country?: InputMaybe<Scalars["String"]>;
	postalCode?: InputMaybe<Scalars["String"]>;
	removePersonIDs?: InputMaybe<Array<Scalars["ID"]>>;
	stateOrProvince?: InputMaybe<Scalars["String"]>;
};

export type AdmissionsApplicationDailyCountsReport = {
	__typename?: "AdmissionsApplicationDailyCountsReport";
	data: Array<AdmissionsApplicationDailyCountsReportPoint>;
};

export type AdmissionsApplicationDailyCountsReportFilterInput = {
	endDate: Scalars["Time"];
	startDate: Scalars["Time"];
};

export type AdmissionsApplicationDailyCountsReportPoint = {
	__typename?: "AdmissionsApplicationDailyCountsReportPoint";
	date: Scalars["Time"];
	value: Scalars["Int"];
};

export type AdmissionsApplicationsByGradeReport = {
	__typename?: "AdmissionsApplicationsByGradeReport";
	data: Array<AdmissionsApplicationsByGradeReportPoint>;
};

export type AdmissionsApplicationsByGradeReportPoint = {
	__typename?: "AdmissionsApplicationsByGradeReportPoint";
	grade: Scalars["String"];
	value: Scalars["Int"];
};

export type AdmissionsEnrollmentsByGradeReport = {
	__typename?: "AdmissionsEnrollmentsByGradeReport";
	data: Array<AdmissionsEnrollmentsByGradeReportPoint>;
};

export type AdmissionsEnrollmentsByGradeReportPoint = {
	__typename?: "AdmissionsEnrollmentsByGradeReportPoint";
	grade: Scalars["String"];
	newEnrollmentValue: Scalars["Int"];
	reenrollmentValue: Scalars["Int"];
	value: Scalars["Int"];
};

export type AdmissionsReenrollmentsByGradeReport = {
	__typename?: "AdmissionsReenrollmentsByGradeReport";
	data: Array<AdmissionsReenrollmentsByGradeReportPoint>;
};

export type AdmissionsReenrollmentsByGradeReportPoint = {
	__typename?: "AdmissionsReenrollmentsByGradeReportPoint";
	enrolledValue: Scalars["Int"];
	grade: Scalars["String"];
	reenrolledValue: Scalars["Int"];
};

export type BillingSetupSubmission = Node & {
	__typename?: "BillingSetupSubmission";
	academicYear: AcademicYear;
	academicYearID: Scalars["ID"];
	authorEmail: Scalars["String"];
	authorName: Scalars["String"];
	authorPhone: Scalars["String"];
	id: Scalars["ID"];
	isInterestedInBusPlan: Scalars["Boolean"];
	isInterestedInMealPlan: Scalars["Boolean"];
	numPayments: Scalars["Int"];
	student: Student;
	studentID: Scalars["ID"];
};

export type BillingSetupSubmissionConnection = {
	__typename?: "BillingSetupSubmissionConnection";
	edges: Array<BillingSetupSubmissionEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type BillingSetupSubmissionCreateInput = {
	academicYearID: Scalars["ID"];
	authorEmail: Scalars["String"];
	authorName: Scalars["String"];
	authorPhone?: InputMaybe<Scalars["String"]>;
	isInterestedInBusPlan: Scalars["Boolean"];
	isInterestedInMealPlan: Scalars["Boolean"];
	numPayments: Scalars["Int"];
	studentID: Scalars["ID"];
};

export type BillingSetupSubmissionEdge = {
	__typename?: "BillingSetupSubmissionEdge";
	cursor: Scalars["Cursor"];
	node: BillingSetupSubmission;
};

export type BillingSetupSubmissionFilterInput = {
	academicYear?: InputMaybe<Array<AcademicYearFilterInput>>;
	academicYearIDEQ?: InputMaybe<Scalars["ID"]>;
	academicYearIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	academicYearIDNEQ?: InputMaybe<Scalars["ID"]>;
	academicYearIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	and?: InputMaybe<Array<BillingSetupSubmissionFilterInput>>;
	authorEmailContains?: InputMaybe<Scalars["String"]>;
	authorEmailEQ?: InputMaybe<Scalars["String"]>;
	authorEmailHasPrefix?: InputMaybe<Scalars["String"]>;
	authorEmailHasSuffix?: InputMaybe<Scalars["String"]>;
	authorEmailIn?: InputMaybe<Array<Scalars["String"]>>;
	authorEmailNEQ?: InputMaybe<Scalars["String"]>;
	authorEmailNotContains?: InputMaybe<Scalars["String"]>;
	authorEmailNotHasPrefix?: InputMaybe<Scalars["String"]>;
	authorEmailNotHasSuffix?: InputMaybe<Scalars["String"]>;
	authorEmailNotIn?: InputMaybe<Array<Scalars["String"]>>;
	authorNameContains?: InputMaybe<Scalars["String"]>;
	authorNameEQ?: InputMaybe<Scalars["String"]>;
	authorNameHasPrefix?: InputMaybe<Scalars["String"]>;
	authorNameHasSuffix?: InputMaybe<Scalars["String"]>;
	authorNameIn?: InputMaybe<Array<Scalars["String"]>>;
	authorNameNEQ?: InputMaybe<Scalars["String"]>;
	authorNameNotContains?: InputMaybe<Scalars["String"]>;
	authorNameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	authorNameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	authorNameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	authorPhoneContains?: InputMaybe<Scalars["String"]>;
	authorPhoneEQ?: InputMaybe<Scalars["String"]>;
	authorPhoneHasPrefix?: InputMaybe<Scalars["String"]>;
	authorPhoneHasSuffix?: InputMaybe<Scalars["String"]>;
	authorPhoneIn?: InputMaybe<Array<Scalars["String"]>>;
	authorPhoneNEQ?: InputMaybe<Scalars["String"]>;
	authorPhoneNotContains?: InputMaybe<Scalars["String"]>;
	authorPhoneNotHasPrefix?: InputMaybe<Scalars["String"]>;
	authorPhoneNotHasSuffix?: InputMaybe<Scalars["String"]>;
	authorPhoneNotIn?: InputMaybe<Array<Scalars["String"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	isInterestedInBusPlanEQ?: InputMaybe<Scalars["Boolean"]>;
	isInterestedInBusPlanNEQ?: InputMaybe<Scalars["Boolean"]>;
	isInterestedInMealPlanEQ?: InputMaybe<Scalars["Boolean"]>;
	isInterestedInMealPlanNEQ?: InputMaybe<Scalars["Boolean"]>;
	not?: InputMaybe<BillingSetupSubmissionFilterInput>;
	numPaymentsEQ?: InputMaybe<Scalars["Int"]>;
	numPaymentsGT?: InputMaybe<Scalars["Int"]>;
	numPaymentsGTE?: InputMaybe<Scalars["Int"]>;
	numPaymentsIn?: InputMaybe<Array<Scalars["Int"]>>;
	numPaymentsLT?: InputMaybe<Scalars["Int"]>;
	numPaymentsLTE?: InputMaybe<Scalars["Int"]>;
	numPaymentsNEQ?: InputMaybe<Scalars["Int"]>;
	numPaymentsNotIn?: InputMaybe<Array<Scalars["Int"]>>;
	or?: InputMaybe<Array<BillingSetupSubmissionFilterInput>>;
	search?: InputMaybe<Scalars["String"]>;
	student?: InputMaybe<Array<StudentFilterInput>>;
	studentIDEQ?: InputMaybe<Scalars["ID"]>;
	studentIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	studentIDNEQ?: InputMaybe<Scalars["ID"]>;
	studentIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type BillingSetupSubmissionHistory = Node & {
	__typename?: "BillingSetupSubmissionHistory";
	academicYearID: Maybe<Scalars["ID"]>;
	authorEmail: Maybe<Scalars["String"]>;
	authorName: Maybe<Scalars["String"]>;
	authorPhone: Maybe<Scalars["String"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	isInterestedInBusPlan: Maybe<Scalars["Boolean"]>;
	isInterestedInMealPlan: Maybe<Scalars["Boolean"]>;
	numPayments: Maybe<Scalars["Int"]>;
	originalID: Maybe<Scalars["ID"]>;
	studentID: Maybe<Scalars["ID"]>;
};

export type BillingSetupSubmissionHistoryConnection = {
	__typename?: "BillingSetupSubmissionHistoryConnection";
	edges: Array<BillingSetupSubmissionHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type BillingSetupSubmissionHistoryEdge = {
	__typename?: "BillingSetupSubmissionHistoryEdge";
	cursor: Scalars["Cursor"];
	node: BillingSetupSubmissionHistory;
};

export type BillingSetupSubmissionHistoryFilterInput = {
	academicYearIDEQ?: InputMaybe<Scalars["ID"]>;
	academicYearIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	academicYearIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	academicYearIDNEQ?: InputMaybe<Scalars["ID"]>;
	academicYearIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	and?: InputMaybe<Array<BillingSetupSubmissionHistoryFilterInput>>;
	authorEmailContains?: InputMaybe<Scalars["String"]>;
	authorEmailEQ?: InputMaybe<Scalars["String"]>;
	authorEmailHasPrefix?: InputMaybe<Scalars["String"]>;
	authorEmailHasSuffix?: InputMaybe<Scalars["String"]>;
	authorEmailIn?: InputMaybe<Array<Scalars["String"]>>;
	authorEmailIsNull?: InputMaybe<Scalars["Boolean"]>;
	authorEmailNEQ?: InputMaybe<Scalars["String"]>;
	authorEmailNotContains?: InputMaybe<Scalars["String"]>;
	authorEmailNotHasPrefix?: InputMaybe<Scalars["String"]>;
	authorEmailNotHasSuffix?: InputMaybe<Scalars["String"]>;
	authorEmailNotIn?: InputMaybe<Array<Scalars["String"]>>;
	authorNameContains?: InputMaybe<Scalars["String"]>;
	authorNameEQ?: InputMaybe<Scalars["String"]>;
	authorNameHasPrefix?: InputMaybe<Scalars["String"]>;
	authorNameHasSuffix?: InputMaybe<Scalars["String"]>;
	authorNameIn?: InputMaybe<Array<Scalars["String"]>>;
	authorNameIsNull?: InputMaybe<Scalars["Boolean"]>;
	authorNameNEQ?: InputMaybe<Scalars["String"]>;
	authorNameNotContains?: InputMaybe<Scalars["String"]>;
	authorNameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	authorNameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	authorNameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	authorPhoneContains?: InputMaybe<Scalars["String"]>;
	authorPhoneEQ?: InputMaybe<Scalars["String"]>;
	authorPhoneHasPrefix?: InputMaybe<Scalars["String"]>;
	authorPhoneHasSuffix?: InputMaybe<Scalars["String"]>;
	authorPhoneIn?: InputMaybe<Array<Scalars["String"]>>;
	authorPhoneIsNull?: InputMaybe<Scalars["Boolean"]>;
	authorPhoneNEQ?: InputMaybe<Scalars["String"]>;
	authorPhoneNotContains?: InputMaybe<Scalars["String"]>;
	authorPhoneNotHasPrefix?: InputMaybe<Scalars["String"]>;
	authorPhoneNotHasSuffix?: InputMaybe<Scalars["String"]>;
	authorPhoneNotIn?: InputMaybe<Array<Scalars["String"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	isInterestedInBusPlanEQ?: InputMaybe<Scalars["Boolean"]>;
	isInterestedInBusPlanIsNull?: InputMaybe<Scalars["Boolean"]>;
	isInterestedInBusPlanNEQ?: InputMaybe<Scalars["Boolean"]>;
	isInterestedInMealPlanEQ?: InputMaybe<Scalars["Boolean"]>;
	isInterestedInMealPlanIsNull?: InputMaybe<Scalars["Boolean"]>;
	isInterestedInMealPlanNEQ?: InputMaybe<Scalars["Boolean"]>;
	not?: InputMaybe<BillingSetupSubmissionHistoryFilterInput>;
	numPaymentsEQ?: InputMaybe<Scalars["Int"]>;
	numPaymentsGT?: InputMaybe<Scalars["Int"]>;
	numPaymentsGTE?: InputMaybe<Scalars["Int"]>;
	numPaymentsIn?: InputMaybe<Array<Scalars["Int"]>>;
	numPaymentsIsNull?: InputMaybe<Scalars["Boolean"]>;
	numPaymentsLT?: InputMaybe<Scalars["Int"]>;
	numPaymentsLTE?: InputMaybe<Scalars["Int"]>;
	numPaymentsNEQ?: InputMaybe<Scalars["Int"]>;
	numPaymentsNotIn?: InputMaybe<Array<Scalars["Int"]>>;
	or?: InputMaybe<Array<BillingSetupSubmissionHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	search?: InputMaybe<Scalars["String"]>;
	studentIDEQ?: InputMaybe<Scalars["ID"]>;
	studentIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	studentIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	studentIDNEQ?: InputMaybe<Scalars["ID"]>;
	studentIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type BillingSetupSubmissionHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<BillingSetupSubmissionHistoryOrderField>;
};

export enum BillingSetupSubmissionHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type BillingSetupSubmissionInAcademicYearCreateInput = {
	authorEmail: Scalars["String"];
	authorName: Scalars["String"];
	authorPhone?: InputMaybe<Scalars["String"]>;
	isInterestedInBusPlan: Scalars["Boolean"];
	isInterestedInMealPlan: Scalars["Boolean"];
	numPayments: Scalars["Int"];
	studentID: Scalars["ID"];
};

export type BillingSetupSubmissionInStudentCreateInput = {
	academicYearID: Scalars["ID"];
	authorEmail: Scalars["String"];
	authorName: Scalars["String"];
	authorPhone?: InputMaybe<Scalars["String"]>;
	isInterestedInBusPlan: Scalars["Boolean"];
	isInterestedInMealPlan: Scalars["Boolean"];
	numPayments: Scalars["Int"];
};

export type BillingSetupSubmissionOrder = {
	direction: OrderDirection;
	field?: InputMaybe<BillingSetupSubmissionOrderField>;
};

export enum BillingSetupSubmissionOrderField {
	AuthorEmail = "AUTHOR_EMAIL",
	AuthorName = "AUTHOR_NAME",
	AuthorPhone = "AUTHOR_PHONE",
	IsInterestedInBusPlan = "IS_INTERESTED_IN_BUS_PLAN",
	IsInterestedInMealPlan = "IS_INTERESTED_IN_MEAL_PLAN",
	NumPayments = "NUM_PAYMENTS",
}

export type BillingSetupSubmissionUpdateBulkInput = {
	academicYearID?: InputMaybe<Scalars["ID"]>;
	authorEmail?: InputMaybe<Scalars["String"]>;
	authorName?: InputMaybe<Scalars["String"]>;
	authorPhone?: InputMaybe<Scalars["String"]>;
	isInterestedInBusPlan?: InputMaybe<Scalars["Boolean"]>;
	isInterestedInMealPlan?: InputMaybe<Scalars["Boolean"]>;
	numPayments?: InputMaybe<Scalars["Int"]>;
	studentID?: InputMaybe<Scalars["ID"]>;
};

export type BillingSetupSubmissionUpdateInput = {
	academicYearID?: InputMaybe<Scalars["ID"]>;
	authorEmail?: InputMaybe<Scalars["String"]>;
	authorName?: InputMaybe<Scalars["String"]>;
	authorPhone?: InputMaybe<Scalars["String"]>;
	isInterestedInBusPlan?: InputMaybe<Scalars["Boolean"]>;
	isInterestedInMealPlan?: InputMaybe<Scalars["Boolean"]>;
	numPayments?: InputMaybe<Scalars["Int"]>;
	studentID?: InputMaybe<Scalars["ID"]>;
};

export type Bus = Node & {
	__typename?: "Bus";
	description: Scalars["String"];
	dropoffBusPlans: Array<BusPlan>;
	id: Scalars["ID"];
	name: Scalars["String"];
	pickupBusPlans: Array<BusPlan>;
};

export type BusDropoffBusPlansArgs = {
	filters?: InputMaybe<Array<BusPlanFilterInput>>;
	order?: InputMaybe<BusPlanOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type BusPickupBusPlansArgs = {
	filters?: InputMaybe<Array<BusPlanFilterInput>>;
	order?: InputMaybe<BusPlanOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type BusConnection = {
	__typename?: "BusConnection";
	edges: Array<BusEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type BusCreateInput = {
	description?: InputMaybe<Scalars["String"]>;
	dropoffBusPlanIDs?: InputMaybe<Array<Scalars["ID"]>>;
	dropoffBusPlans?: InputMaybe<Array<BusPlanInDropoffBusCreateInput>>;
	name: Scalars["String"];
	pickupBusPlanIDs?: InputMaybe<Array<Scalars["ID"]>>;
	pickupBusPlans?: InputMaybe<Array<BusPlanInPickupBusCreateInput>>;
};

export type BusEdge = {
	__typename?: "BusEdge";
	cursor: Scalars["Cursor"];
	node: Bus;
};

export type BusFilterInput = {
	and?: InputMaybe<Array<BusFilterInput>>;
	descriptionContains?: InputMaybe<Scalars["String"]>;
	descriptionEQ?: InputMaybe<Scalars["String"]>;
	descriptionHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionIn?: InputMaybe<Array<Scalars["String"]>>;
	descriptionNEQ?: InputMaybe<Scalars["String"]>;
	descriptionNotContains?: InputMaybe<Scalars["String"]>;
	descriptionNotHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionNotHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionNotIn?: InputMaybe<Array<Scalars["String"]>>;
	dropoffBusPlans?: InputMaybe<Array<BusPlanFilterInput>>;
	hasDropoffBusPlans?: InputMaybe<Scalars["Boolean"]>;
	hasPickupBusPlans?: InputMaybe<Scalars["Boolean"]>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	nameContains?: InputMaybe<Scalars["String"]>;
	nameEQ?: InputMaybe<Scalars["String"]>;
	nameHasPrefix?: InputMaybe<Scalars["String"]>;
	nameHasSuffix?: InputMaybe<Scalars["String"]>;
	nameIn?: InputMaybe<Array<Scalars["String"]>>;
	nameNEQ?: InputMaybe<Scalars["String"]>;
	nameNotContains?: InputMaybe<Scalars["String"]>;
	nameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	nameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	nameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	not?: InputMaybe<BusFilterInput>;
	or?: InputMaybe<Array<BusFilterInput>>;
	pickupBusPlans?: InputMaybe<Array<BusPlanFilterInput>>;
	search?: InputMaybe<Scalars["String"]>;
};

export type BusHistory = Node & {
	__typename?: "BusHistory";
	description: Maybe<Scalars["String"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	name: Maybe<Scalars["String"]>;
	originalID: Maybe<Scalars["ID"]>;
};

export type BusHistoryConnection = {
	__typename?: "BusHistoryConnection";
	edges: Array<BusHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type BusHistoryEdge = {
	__typename?: "BusHistoryEdge";
	cursor: Scalars["Cursor"];
	node: BusHistory;
};

export type BusHistoryFilterInput = {
	and?: InputMaybe<Array<BusHistoryFilterInput>>;
	descriptionContains?: InputMaybe<Scalars["String"]>;
	descriptionEQ?: InputMaybe<Scalars["String"]>;
	descriptionHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionIn?: InputMaybe<Array<Scalars["String"]>>;
	descriptionIsNull?: InputMaybe<Scalars["Boolean"]>;
	descriptionNEQ?: InputMaybe<Scalars["String"]>;
	descriptionNotContains?: InputMaybe<Scalars["String"]>;
	descriptionNotHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionNotHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionNotIn?: InputMaybe<Array<Scalars["String"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	nameContains?: InputMaybe<Scalars["String"]>;
	nameEQ?: InputMaybe<Scalars["String"]>;
	nameHasPrefix?: InputMaybe<Scalars["String"]>;
	nameHasSuffix?: InputMaybe<Scalars["String"]>;
	nameIn?: InputMaybe<Array<Scalars["String"]>>;
	nameIsNull?: InputMaybe<Scalars["Boolean"]>;
	nameNEQ?: InputMaybe<Scalars["String"]>;
	nameNotContains?: InputMaybe<Scalars["String"]>;
	nameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	nameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	nameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	not?: InputMaybe<BusHistoryFilterInput>;
	or?: InputMaybe<Array<BusHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	search?: InputMaybe<Scalars["String"]>;
};

export type BusHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<BusHistoryOrderField>;
};

export enum BusHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type BusOrder = {
	direction: OrderDirection;
	field?: InputMaybe<BusOrderField>;
};

export enum BusOrderField {
	Name = "NAME",
}

export type BusPlan = Node & {
	__typename?: "BusPlan";
	dropoffBus: Maybe<Bus>;
	dropoffBusID: Maybe<Scalars["ID"]>;
	dropoffLocation: Scalars["String"];
	dropoffTimeMinutes: Maybe<Scalars["Int"]>;
	hasDropoff: Scalars["Boolean"];
	hasPickup: Scalars["Boolean"];
	id: Scalars["ID"];
	pickupBus: Maybe<Bus>;
	pickupBusID: Maybe<Scalars["ID"]>;
	pickupLocation: Scalars["String"];
	pickupTimeMinutes: Maybe<Scalars["Int"]>;
	student: Student;
	studentID: Scalars["ID"];
};

export type BusPlanConnection = {
	__typename?: "BusPlanConnection";
	edges: Array<BusPlanEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type BusPlanCreateInput = {
	dropoffBusID?: InputMaybe<Scalars["ID"]>;
	dropoffLocation?: InputMaybe<Scalars["String"]>;
	dropoffTimeMinutes?: InputMaybe<Scalars["Int"]>;
	pickupBusID?: InputMaybe<Scalars["ID"]>;
	pickupLocation?: InputMaybe<Scalars["String"]>;
	pickupTimeMinutes?: InputMaybe<Scalars["Int"]>;
	studentID: Scalars["ID"];
};

export type BusPlanEdge = {
	__typename?: "BusPlanEdge";
	cursor: Scalars["Cursor"];
	node: BusPlan;
};

export type BusPlanFilterInput = {
	and?: InputMaybe<Array<BusPlanFilterInput>>;
	dropoffBus?: InputMaybe<Array<BusFilterInput>>;
	dropoffBusIDEQ?: InputMaybe<Scalars["ID"]>;
	dropoffBusIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	dropoffBusIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	dropoffBusIDNEQ?: InputMaybe<Scalars["ID"]>;
	dropoffBusIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	dropoffLocationContains?: InputMaybe<Scalars["String"]>;
	dropoffLocationEQ?: InputMaybe<Scalars["String"]>;
	dropoffLocationHasPrefix?: InputMaybe<Scalars["String"]>;
	dropoffLocationHasSuffix?: InputMaybe<Scalars["String"]>;
	dropoffLocationIn?: InputMaybe<Array<Scalars["String"]>>;
	dropoffLocationNEQ?: InputMaybe<Scalars["String"]>;
	dropoffLocationNotContains?: InputMaybe<Scalars["String"]>;
	dropoffLocationNotHasPrefix?: InputMaybe<Scalars["String"]>;
	dropoffLocationNotHasSuffix?: InputMaybe<Scalars["String"]>;
	dropoffLocationNotIn?: InputMaybe<Array<Scalars["String"]>>;
	dropoffTimeMinutesEQ?: InputMaybe<Scalars["Int"]>;
	dropoffTimeMinutesGT?: InputMaybe<Scalars["Int"]>;
	dropoffTimeMinutesGTE?: InputMaybe<Scalars["Int"]>;
	dropoffTimeMinutesIn?: InputMaybe<Array<Scalars["Int"]>>;
	dropoffTimeMinutesIsNull?: InputMaybe<Scalars["Boolean"]>;
	dropoffTimeMinutesLT?: InputMaybe<Scalars["Int"]>;
	dropoffTimeMinutesLTE?: InputMaybe<Scalars["Int"]>;
	dropoffTimeMinutesNEQ?: InputMaybe<Scalars["Int"]>;
	dropoffTimeMinutesNotIn?: InputMaybe<Array<Scalars["Int"]>>;
	hasDropoffBus?: InputMaybe<Scalars["Boolean"]>;
	hasPickupBus?: InputMaybe<Scalars["Boolean"]>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<BusPlanFilterInput>;
	or?: InputMaybe<Array<BusPlanFilterInput>>;
	pickupBus?: InputMaybe<Array<BusFilterInput>>;
	pickupBusIDEQ?: InputMaybe<Scalars["ID"]>;
	pickupBusIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	pickupBusIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	pickupBusIDNEQ?: InputMaybe<Scalars["ID"]>;
	pickupBusIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	pickupLocationContains?: InputMaybe<Scalars["String"]>;
	pickupLocationEQ?: InputMaybe<Scalars["String"]>;
	pickupLocationHasPrefix?: InputMaybe<Scalars["String"]>;
	pickupLocationHasSuffix?: InputMaybe<Scalars["String"]>;
	pickupLocationIn?: InputMaybe<Array<Scalars["String"]>>;
	pickupLocationNEQ?: InputMaybe<Scalars["String"]>;
	pickupLocationNotContains?: InputMaybe<Scalars["String"]>;
	pickupLocationNotHasPrefix?: InputMaybe<Scalars["String"]>;
	pickupLocationNotHasSuffix?: InputMaybe<Scalars["String"]>;
	pickupLocationNotIn?: InputMaybe<Array<Scalars["String"]>>;
	pickupTimeMinutesEQ?: InputMaybe<Scalars["Int"]>;
	pickupTimeMinutesGT?: InputMaybe<Scalars["Int"]>;
	pickupTimeMinutesGTE?: InputMaybe<Scalars["Int"]>;
	pickupTimeMinutesIn?: InputMaybe<Array<Scalars["Int"]>>;
	pickupTimeMinutesIsNull?: InputMaybe<Scalars["Boolean"]>;
	pickupTimeMinutesLT?: InputMaybe<Scalars["Int"]>;
	pickupTimeMinutesLTE?: InputMaybe<Scalars["Int"]>;
	pickupTimeMinutesNEQ?: InputMaybe<Scalars["Int"]>;
	pickupTimeMinutesNotIn?: InputMaybe<Array<Scalars["Int"]>>;
	search?: InputMaybe<Scalars["String"]>;
	student?: InputMaybe<Array<StudentFilterInput>>;
	studentIDEQ?: InputMaybe<Scalars["ID"]>;
	studentIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	studentIDNEQ?: InputMaybe<Scalars["ID"]>;
	studentIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type BusPlanHistory = Node & {
	__typename?: "BusPlanHistory";
	dropoffBusID: Maybe<Scalars["ID"]>;
	dropoffLocation: Maybe<Scalars["String"]>;
	dropoffTimeMinutes: Maybe<Scalars["Int"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	originalID: Maybe<Scalars["ID"]>;
	pickupBusID: Maybe<Scalars["ID"]>;
	pickupLocation: Maybe<Scalars["String"]>;
	pickupTimeMinutes: Maybe<Scalars["Int"]>;
	studentID: Maybe<Scalars["ID"]>;
};

export type BusPlanHistoryConnection = {
	__typename?: "BusPlanHistoryConnection";
	edges: Array<BusPlanHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type BusPlanHistoryEdge = {
	__typename?: "BusPlanHistoryEdge";
	cursor: Scalars["Cursor"];
	node: BusPlanHistory;
};

export type BusPlanHistoryFilterInput = {
	and?: InputMaybe<Array<BusPlanHistoryFilterInput>>;
	dropoffBusIDEQ?: InputMaybe<Scalars["ID"]>;
	dropoffBusIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	dropoffBusIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	dropoffBusIDNEQ?: InputMaybe<Scalars["ID"]>;
	dropoffBusIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	dropoffLocationContains?: InputMaybe<Scalars["String"]>;
	dropoffLocationEQ?: InputMaybe<Scalars["String"]>;
	dropoffLocationHasPrefix?: InputMaybe<Scalars["String"]>;
	dropoffLocationHasSuffix?: InputMaybe<Scalars["String"]>;
	dropoffLocationIn?: InputMaybe<Array<Scalars["String"]>>;
	dropoffLocationIsNull?: InputMaybe<Scalars["Boolean"]>;
	dropoffLocationNEQ?: InputMaybe<Scalars["String"]>;
	dropoffLocationNotContains?: InputMaybe<Scalars["String"]>;
	dropoffLocationNotHasPrefix?: InputMaybe<Scalars["String"]>;
	dropoffLocationNotHasSuffix?: InputMaybe<Scalars["String"]>;
	dropoffLocationNotIn?: InputMaybe<Array<Scalars["String"]>>;
	dropoffTimeMinutesEQ?: InputMaybe<Scalars["Int"]>;
	dropoffTimeMinutesGT?: InputMaybe<Scalars["Int"]>;
	dropoffTimeMinutesGTE?: InputMaybe<Scalars["Int"]>;
	dropoffTimeMinutesIn?: InputMaybe<Array<Scalars["Int"]>>;
	dropoffTimeMinutesIsNull?: InputMaybe<Scalars["Boolean"]>;
	dropoffTimeMinutesLT?: InputMaybe<Scalars["Int"]>;
	dropoffTimeMinutesLTE?: InputMaybe<Scalars["Int"]>;
	dropoffTimeMinutesNEQ?: InputMaybe<Scalars["Int"]>;
	dropoffTimeMinutesNotIn?: InputMaybe<Array<Scalars["Int"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<BusPlanHistoryFilterInput>;
	or?: InputMaybe<Array<BusPlanHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	pickupBusIDEQ?: InputMaybe<Scalars["ID"]>;
	pickupBusIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	pickupBusIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	pickupBusIDNEQ?: InputMaybe<Scalars["ID"]>;
	pickupBusIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	pickupLocationContains?: InputMaybe<Scalars["String"]>;
	pickupLocationEQ?: InputMaybe<Scalars["String"]>;
	pickupLocationHasPrefix?: InputMaybe<Scalars["String"]>;
	pickupLocationHasSuffix?: InputMaybe<Scalars["String"]>;
	pickupLocationIn?: InputMaybe<Array<Scalars["String"]>>;
	pickupLocationIsNull?: InputMaybe<Scalars["Boolean"]>;
	pickupLocationNEQ?: InputMaybe<Scalars["String"]>;
	pickupLocationNotContains?: InputMaybe<Scalars["String"]>;
	pickupLocationNotHasPrefix?: InputMaybe<Scalars["String"]>;
	pickupLocationNotHasSuffix?: InputMaybe<Scalars["String"]>;
	pickupLocationNotIn?: InputMaybe<Array<Scalars["String"]>>;
	pickupTimeMinutesEQ?: InputMaybe<Scalars["Int"]>;
	pickupTimeMinutesGT?: InputMaybe<Scalars["Int"]>;
	pickupTimeMinutesGTE?: InputMaybe<Scalars["Int"]>;
	pickupTimeMinutesIn?: InputMaybe<Array<Scalars["Int"]>>;
	pickupTimeMinutesIsNull?: InputMaybe<Scalars["Boolean"]>;
	pickupTimeMinutesLT?: InputMaybe<Scalars["Int"]>;
	pickupTimeMinutesLTE?: InputMaybe<Scalars["Int"]>;
	pickupTimeMinutesNEQ?: InputMaybe<Scalars["Int"]>;
	pickupTimeMinutesNotIn?: InputMaybe<Array<Scalars["Int"]>>;
	search?: InputMaybe<Scalars["String"]>;
	studentIDEQ?: InputMaybe<Scalars["ID"]>;
	studentIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	studentIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	studentIDNEQ?: InputMaybe<Scalars["ID"]>;
	studentIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type BusPlanHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<BusPlanHistoryOrderField>;
};

export enum BusPlanHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type BusPlanInDropoffBusCreateInput = {
	dropoffLocation?: InputMaybe<Scalars["String"]>;
	dropoffTimeMinutes?: InputMaybe<Scalars["Int"]>;
	pickupBusID?: InputMaybe<Scalars["ID"]>;
	pickupLocation?: InputMaybe<Scalars["String"]>;
	pickupTimeMinutes?: InputMaybe<Scalars["Int"]>;
	studentID: Scalars["ID"];
};

export type BusPlanInPickupBusCreateInput = {
	dropoffBusID?: InputMaybe<Scalars["ID"]>;
	dropoffLocation?: InputMaybe<Scalars["String"]>;
	dropoffTimeMinutes?: InputMaybe<Scalars["Int"]>;
	pickupLocation?: InputMaybe<Scalars["String"]>;
	pickupTimeMinutes?: InputMaybe<Scalars["Int"]>;
	studentID: Scalars["ID"];
};

export type BusPlanInStudentCreateInput = {
	dropoffBusID?: InputMaybe<Scalars["ID"]>;
	dropoffLocation?: InputMaybe<Scalars["String"]>;
	dropoffTimeMinutes?: InputMaybe<Scalars["Int"]>;
	pickupBusID?: InputMaybe<Scalars["ID"]>;
	pickupLocation?: InputMaybe<Scalars["String"]>;
	pickupTimeMinutes?: InputMaybe<Scalars["Int"]>;
};

export type BusPlanOrder = {
	direction: OrderDirection;
	field?: InputMaybe<BusPlanOrderField>;
};

export enum BusPlanOrderField {
	DropoffTimeMinutes = "DROPOFF_TIME_MINUTES",
	PickupTimeMinutes = "PICKUP_TIME_MINUTES",
}

export type BusPlanUpdateBulkInput = {
	clearDropoffBus?: InputMaybe<Scalars["Boolean"]>;
	clearDropoffTimeMinutes?: InputMaybe<Scalars["Boolean"]>;
	clearPickupBus?: InputMaybe<Scalars["Boolean"]>;
	clearPickupTimeMinutes?: InputMaybe<Scalars["Boolean"]>;
	dropoffBusID?: InputMaybe<Scalars["ID"]>;
	dropoffLocation?: InputMaybe<Scalars["String"]>;
	dropoffTimeMinutes?: InputMaybe<Scalars["Int"]>;
	pickupBusID?: InputMaybe<Scalars["ID"]>;
	pickupLocation?: InputMaybe<Scalars["String"]>;
	pickupTimeMinutes?: InputMaybe<Scalars["Int"]>;
	studentID?: InputMaybe<Scalars["ID"]>;
};

export type BusPlanUpdateInput = {
	clearDropoffBus?: InputMaybe<Scalars["Boolean"]>;
	clearDropoffTimeMinutes?: InputMaybe<Scalars["Boolean"]>;
	clearPickupBus?: InputMaybe<Scalars["Boolean"]>;
	clearPickupTimeMinutes?: InputMaybe<Scalars["Boolean"]>;
	dropoffBusID?: InputMaybe<Scalars["ID"]>;
	dropoffLocation?: InputMaybe<Scalars["String"]>;
	dropoffTimeMinutes?: InputMaybe<Scalars["Int"]>;
	pickupBusID?: InputMaybe<Scalars["ID"]>;
	pickupLocation?: InputMaybe<Scalars["String"]>;
	pickupTimeMinutes?: InputMaybe<Scalars["Int"]>;
	studentID?: InputMaybe<Scalars["ID"]>;
};

export type BusUpdateBulkInput = {
	addDropoffBusPlanIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addPickupBusPlanIDs?: InputMaybe<Array<Scalars["ID"]>>;
	description?: InputMaybe<Scalars["String"]>;
	name?: InputMaybe<Scalars["String"]>;
	removeDropoffBusPlanIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removePickupBusPlanIDs?: InputMaybe<Array<Scalars["ID"]>>;
};

export type BusUpdateInput = {
	addDropoffBusPlanIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addPickupBusPlanIDs?: InputMaybe<Array<Scalars["ID"]>>;
	description?: InputMaybe<Scalars["String"]>;
	name?: InputMaybe<Scalars["String"]>;
	removeDropoffBusPlanIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removePickupBusPlanIDs?: InputMaybe<Array<Scalars["ID"]>>;
};

export type CalendarEvent = Node & {
	__typename?: "CalendarEvent";
	author: Maybe<User>;
	authorID: Maybe<Scalars["ID"]>;
	createdAt: Scalars["Time"];
	description: Scalars["String"];
	endTime: Scalars["Time"];
	id: Scalars["ID"];
	isAllDay: Scalars["Boolean"];
	isPublic: Scalars["Boolean"];
	name: Scalars["String"];
	notes: Array<Note>;
	sharingUserGroups: Array<UserGroup>;
	startTime: Scalars["Time"];
};

export type CalendarEventNotesArgs = {
	filters?: InputMaybe<Array<NoteFilterInput>>;
	order?: InputMaybe<NoteOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type CalendarEventSharingUserGroupsArgs = {
	filters?: InputMaybe<Array<UserGroupFilterInput>>;
	order?: InputMaybe<UserGroupOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type CalendarEventConnection = {
	__typename?: "CalendarEventConnection";
	edges: Array<CalendarEventEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type CalendarEventCreateInput = {
	description?: InputMaybe<Scalars["String"]>;
	endTime: Scalars["Time"];
	isAllDay?: InputMaybe<Scalars["Boolean"]>;
	isPublic?: InputMaybe<Scalars["Boolean"]>;
	name: Scalars["String"];
	noteIDs?: InputMaybe<Array<Scalars["ID"]>>;
	sharingUserGroupIDs?: InputMaybe<Array<Scalars["ID"]>>;
	startTime: Scalars["Time"];
};

export type CalendarEventEdge = {
	__typename?: "CalendarEventEdge";
	cursor: Scalars["Cursor"];
	node: CalendarEvent;
};

export type CalendarEventFilterInput = {
	and?: InputMaybe<Array<CalendarEventFilterInput>>;
	author?: InputMaybe<Array<UserFilterInput>>;
	authorIDEQ?: InputMaybe<Scalars["ID"]>;
	authorIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	authorIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	authorIDNEQ?: InputMaybe<Scalars["ID"]>;
	authorIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	createdAtEQ?: InputMaybe<Scalars["Time"]>;
	createdAtGT?: InputMaybe<Scalars["Time"]>;
	createdAtGTE?: InputMaybe<Scalars["Time"]>;
	createdAtIn?: InputMaybe<Array<Scalars["Time"]>>;
	createdAtLT?: InputMaybe<Scalars["Time"]>;
	createdAtLTE?: InputMaybe<Scalars["Time"]>;
	createdAtNEQ?: InputMaybe<Scalars["Time"]>;
	createdAtNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	descriptionContains?: InputMaybe<Scalars["String"]>;
	descriptionEQ?: InputMaybe<Scalars["String"]>;
	descriptionHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionIn?: InputMaybe<Array<Scalars["String"]>>;
	descriptionNEQ?: InputMaybe<Scalars["String"]>;
	descriptionNotContains?: InputMaybe<Scalars["String"]>;
	descriptionNotHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionNotHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionNotIn?: InputMaybe<Array<Scalars["String"]>>;
	endTimeEQ?: InputMaybe<Scalars["Time"]>;
	endTimeGT?: InputMaybe<Scalars["Time"]>;
	endTimeGTE?: InputMaybe<Scalars["Time"]>;
	endTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	endTimeLT?: InputMaybe<Scalars["Time"]>;
	endTimeLTE?: InputMaybe<Scalars["Time"]>;
	endTimeNEQ?: InputMaybe<Scalars["Time"]>;
	endTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	hasAuthor?: InputMaybe<Scalars["Boolean"]>;
	hasNotes?: InputMaybe<Scalars["Boolean"]>;
	hasSharingUserGroups?: InputMaybe<Scalars["Boolean"]>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	isAllDayEQ?: InputMaybe<Scalars["Boolean"]>;
	isAllDayNEQ?: InputMaybe<Scalars["Boolean"]>;
	isPublicEQ?: InputMaybe<Scalars["Boolean"]>;
	isPublicNEQ?: InputMaybe<Scalars["Boolean"]>;
	nameContains?: InputMaybe<Scalars["String"]>;
	nameEQ?: InputMaybe<Scalars["String"]>;
	nameHasPrefix?: InputMaybe<Scalars["String"]>;
	nameHasSuffix?: InputMaybe<Scalars["String"]>;
	nameIn?: InputMaybe<Array<Scalars["String"]>>;
	nameNEQ?: InputMaybe<Scalars["String"]>;
	nameNotContains?: InputMaybe<Scalars["String"]>;
	nameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	nameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	nameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	not?: InputMaybe<CalendarEventFilterInput>;
	notes?: InputMaybe<Array<NoteFilterInput>>;
	or?: InputMaybe<Array<CalendarEventFilterInput>>;
	search?: InputMaybe<Scalars["String"]>;
	sharingUserGroups?: InputMaybe<Array<UserGroupFilterInput>>;
	startTimeEQ?: InputMaybe<Scalars["Time"]>;
	startTimeGT?: InputMaybe<Scalars["Time"]>;
	startTimeGTE?: InputMaybe<Scalars["Time"]>;
	startTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	startTimeLT?: InputMaybe<Scalars["Time"]>;
	startTimeLTE?: InputMaybe<Scalars["Time"]>;
	startTimeNEQ?: InputMaybe<Scalars["Time"]>;
	startTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
};

export type CalendarEventHistory = Node & {
	__typename?: "CalendarEventHistory";
	authorID: Maybe<Scalars["ID"]>;
	createdAt: Maybe<Scalars["Time"]>;
	description: Maybe<Scalars["String"]>;
	endTime: Maybe<Scalars["Time"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	isAllDay: Maybe<Scalars["Boolean"]>;
	isPublic: Maybe<Scalars["Boolean"]>;
	name: Maybe<Scalars["String"]>;
	originalID: Maybe<Scalars["ID"]>;
	startTime: Maybe<Scalars["Time"]>;
};

export type CalendarEventHistoryConnection = {
	__typename?: "CalendarEventHistoryConnection";
	edges: Array<CalendarEventHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type CalendarEventHistoryEdge = {
	__typename?: "CalendarEventHistoryEdge";
	cursor: Scalars["Cursor"];
	node: CalendarEventHistory;
};

export type CalendarEventHistoryFilterInput = {
	and?: InputMaybe<Array<CalendarEventHistoryFilterInput>>;
	authorIDEQ?: InputMaybe<Scalars["ID"]>;
	authorIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	authorIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	authorIDNEQ?: InputMaybe<Scalars["ID"]>;
	authorIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	createdAtEQ?: InputMaybe<Scalars["Time"]>;
	createdAtGT?: InputMaybe<Scalars["Time"]>;
	createdAtGTE?: InputMaybe<Scalars["Time"]>;
	createdAtIn?: InputMaybe<Array<Scalars["Time"]>>;
	createdAtIsNull?: InputMaybe<Scalars["Boolean"]>;
	createdAtLT?: InputMaybe<Scalars["Time"]>;
	createdAtLTE?: InputMaybe<Scalars["Time"]>;
	createdAtNEQ?: InputMaybe<Scalars["Time"]>;
	createdAtNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	descriptionContains?: InputMaybe<Scalars["String"]>;
	descriptionEQ?: InputMaybe<Scalars["String"]>;
	descriptionHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionIn?: InputMaybe<Array<Scalars["String"]>>;
	descriptionIsNull?: InputMaybe<Scalars["Boolean"]>;
	descriptionNEQ?: InputMaybe<Scalars["String"]>;
	descriptionNotContains?: InputMaybe<Scalars["String"]>;
	descriptionNotHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionNotHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionNotIn?: InputMaybe<Array<Scalars["String"]>>;
	endTimeEQ?: InputMaybe<Scalars["Time"]>;
	endTimeGT?: InputMaybe<Scalars["Time"]>;
	endTimeGTE?: InputMaybe<Scalars["Time"]>;
	endTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	endTimeIsNull?: InputMaybe<Scalars["Boolean"]>;
	endTimeLT?: InputMaybe<Scalars["Time"]>;
	endTimeLTE?: InputMaybe<Scalars["Time"]>;
	endTimeNEQ?: InputMaybe<Scalars["Time"]>;
	endTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	isAllDayEQ?: InputMaybe<Scalars["Boolean"]>;
	isAllDayIsNull?: InputMaybe<Scalars["Boolean"]>;
	isAllDayNEQ?: InputMaybe<Scalars["Boolean"]>;
	isPublicEQ?: InputMaybe<Scalars["Boolean"]>;
	isPublicIsNull?: InputMaybe<Scalars["Boolean"]>;
	isPublicNEQ?: InputMaybe<Scalars["Boolean"]>;
	nameContains?: InputMaybe<Scalars["String"]>;
	nameEQ?: InputMaybe<Scalars["String"]>;
	nameHasPrefix?: InputMaybe<Scalars["String"]>;
	nameHasSuffix?: InputMaybe<Scalars["String"]>;
	nameIn?: InputMaybe<Array<Scalars["String"]>>;
	nameIsNull?: InputMaybe<Scalars["Boolean"]>;
	nameNEQ?: InputMaybe<Scalars["String"]>;
	nameNotContains?: InputMaybe<Scalars["String"]>;
	nameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	nameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	nameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	not?: InputMaybe<CalendarEventHistoryFilterInput>;
	or?: InputMaybe<Array<CalendarEventHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	search?: InputMaybe<Scalars["String"]>;
	startTimeEQ?: InputMaybe<Scalars["Time"]>;
	startTimeGT?: InputMaybe<Scalars["Time"]>;
	startTimeGTE?: InputMaybe<Scalars["Time"]>;
	startTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	startTimeIsNull?: InputMaybe<Scalars["Boolean"]>;
	startTimeLT?: InputMaybe<Scalars["Time"]>;
	startTimeLTE?: InputMaybe<Scalars["Time"]>;
	startTimeNEQ?: InputMaybe<Scalars["Time"]>;
	startTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
};

export type CalendarEventHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<CalendarEventHistoryOrderField>;
};

export enum CalendarEventHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type CalendarEventInAuthorCreateInput = {
	description?: InputMaybe<Scalars["String"]>;
	endTime: Scalars["Time"];
	isAllDay?: InputMaybe<Scalars["Boolean"]>;
	isPublic?: InputMaybe<Scalars["Boolean"]>;
	name: Scalars["String"];
	noteIDs?: InputMaybe<Array<Scalars["ID"]>>;
	sharingUserGroupIDs?: InputMaybe<Array<Scalars["ID"]>>;
	startTime: Scalars["Time"];
};

export type CalendarEventOrder = {
	direction: OrderDirection;
	field?: InputMaybe<CalendarEventOrderField>;
};

export enum CalendarEventOrderField {
	CreatedAt = "CREATED_AT",
	EndTime = "END_TIME",
	IsPublic = "IS_PUBLIC",
	Name = "NAME",
	StartTime = "START_TIME",
}

export type CalendarEventSharedCalendarEventUserGroupSharingUserGroupHistory = Node & {
	__typename?: "CalendarEventSharedCalendarEventUserGroupSharingUserGroupHistory";
	calendarEventID: Maybe<Scalars["ID"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	userGroupID: Maybe<Scalars["ID"]>;
};

export type CalendarEventSharedCalendarEventUserGroupSharingUserGroupHistoryConnection = {
	__typename?: "CalendarEventSharedCalendarEventUserGroupSharingUserGroupHistoryConnection";
	edges: Array<CalendarEventSharedCalendarEventUserGroupSharingUserGroupHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type CalendarEventSharedCalendarEventUserGroupSharingUserGroupHistoryEdge = {
	__typename?: "CalendarEventSharedCalendarEventUserGroupSharingUserGroupHistoryEdge";
	cursor: Scalars["Cursor"];
	node: CalendarEventSharedCalendarEventUserGroupSharingUserGroupHistory;
};

export type CalendarEventSharedCalendarEventUserGroupSharingUserGroupHistoryFilterInput = {
	and?: InputMaybe<Array<CalendarEventSharedCalendarEventUserGroupSharingUserGroupHistoryFilterInput>>;
	calendarEventIDEQ?: InputMaybe<Scalars["ID"]>;
	calendarEventIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	calendarEventIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	calendarEventIDNEQ?: InputMaybe<Scalars["ID"]>;
	calendarEventIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<CalendarEventSharedCalendarEventUserGroupSharingUserGroupHistoryFilterInput>;
	or?: InputMaybe<Array<CalendarEventSharedCalendarEventUserGroupSharingUserGroupHistoryFilterInput>>;
	userGroupIDEQ?: InputMaybe<Scalars["ID"]>;
	userGroupIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	userGroupIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	userGroupIDNEQ?: InputMaybe<Scalars["ID"]>;
	userGroupIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type CalendarEventSharedCalendarEventUserGroupSharingUserGroupHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<CalendarEventSharedCalendarEventUserGroupSharingUserGroupHistoryOrderField>;
};

export enum CalendarEventSharedCalendarEventUserGroupSharingUserGroupHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type CalendarEventUpdateBulkInput = {
	addNoteIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addSharingUserGroupIDs?: InputMaybe<Array<Scalars["ID"]>>;
	description?: InputMaybe<Scalars["String"]>;
	endTime?: InputMaybe<Scalars["Time"]>;
	isAllDay?: InputMaybe<Scalars["Boolean"]>;
	isPublic?: InputMaybe<Scalars["Boolean"]>;
	name?: InputMaybe<Scalars["String"]>;
	removeNoteIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeSharingUserGroupIDs?: InputMaybe<Array<Scalars["ID"]>>;
	startTime?: InputMaybe<Scalars["Time"]>;
};

export type CalendarEventUpdateInput = {
	addNoteIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addSharingUserGroupIDs?: InputMaybe<Array<Scalars["ID"]>>;
	description?: InputMaybe<Scalars["String"]>;
	endTime?: InputMaybe<Scalars["Time"]>;
	isAllDay?: InputMaybe<Scalars["Boolean"]>;
	isPublic?: InputMaybe<Scalars["Boolean"]>;
	name?: InputMaybe<Scalars["String"]>;
	removeNoteIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeSharingUserGroupIDs?: InputMaybe<Array<Scalars["ID"]>>;
	startTime?: InputMaybe<Scalars["Time"]>;
};

export type Campus = Node & {
	__typename?: "Campus";
	addressLine1: Scalars["String"];
	addressLine2: Scalars["String"];
	city: Scalars["String"];
	country: Scalars["String"];
	employees: Array<Employee>;
	id: Scalars["ID"];
	name: Scalars["String"];
	postalCode: Scalars["String"];
	rooms: Array<Room>;
	stateOrProvince: Scalars["String"];
};

export type CampusEmployeesArgs = {
	filters?: InputMaybe<Array<EmployeeFilterInput>>;
	order?: InputMaybe<EmployeeOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type CampusRoomsArgs = {
	filters?: InputMaybe<Array<RoomFilterInput>>;
	order?: InputMaybe<RoomOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type CampusConnection = {
	__typename?: "CampusConnection";
	edges: Array<CampusEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type CampusCreateInput = {
	addressLine1: Scalars["String"];
	addressLine2?: InputMaybe<Scalars["String"]>;
	city: Scalars["String"];
	country: Scalars["String"];
	employeeIDs?: InputMaybe<Array<Scalars["ID"]>>;
	name: Scalars["String"];
	postalCode: Scalars["String"];
	roomIDs?: InputMaybe<Array<Scalars["ID"]>>;
	rooms?: InputMaybe<Array<RoomInCampusCreateInput>>;
	stateOrProvince?: InputMaybe<Scalars["String"]>;
};

export type CampusEdge = {
	__typename?: "CampusEdge";
	cursor: Scalars["Cursor"];
	node: Campus;
};

export type CampusFilterInput = {
	addressLine1Contains?: InputMaybe<Scalars["String"]>;
	addressLine1EQ?: InputMaybe<Scalars["String"]>;
	addressLine1HasPrefix?: InputMaybe<Scalars["String"]>;
	addressLine1HasSuffix?: InputMaybe<Scalars["String"]>;
	addressLine1In?: InputMaybe<Array<Scalars["String"]>>;
	addressLine1NEQ?: InputMaybe<Scalars["String"]>;
	addressLine1NotContains?: InputMaybe<Scalars["String"]>;
	addressLine1NotHasPrefix?: InputMaybe<Scalars["String"]>;
	addressLine1NotHasSuffix?: InputMaybe<Scalars["String"]>;
	addressLine1NotIn?: InputMaybe<Array<Scalars["String"]>>;
	addressLine2Contains?: InputMaybe<Scalars["String"]>;
	addressLine2EQ?: InputMaybe<Scalars["String"]>;
	addressLine2HasPrefix?: InputMaybe<Scalars["String"]>;
	addressLine2HasSuffix?: InputMaybe<Scalars["String"]>;
	addressLine2In?: InputMaybe<Array<Scalars["String"]>>;
	addressLine2NEQ?: InputMaybe<Scalars["String"]>;
	addressLine2NotContains?: InputMaybe<Scalars["String"]>;
	addressLine2NotHasPrefix?: InputMaybe<Scalars["String"]>;
	addressLine2NotHasSuffix?: InputMaybe<Scalars["String"]>;
	addressLine2NotIn?: InputMaybe<Array<Scalars["String"]>>;
	and?: InputMaybe<Array<CampusFilterInput>>;
	cityContains?: InputMaybe<Scalars["String"]>;
	cityEQ?: InputMaybe<Scalars["String"]>;
	cityHasPrefix?: InputMaybe<Scalars["String"]>;
	cityHasSuffix?: InputMaybe<Scalars["String"]>;
	cityIn?: InputMaybe<Array<Scalars["String"]>>;
	cityNEQ?: InputMaybe<Scalars["String"]>;
	cityNotContains?: InputMaybe<Scalars["String"]>;
	cityNotHasPrefix?: InputMaybe<Scalars["String"]>;
	cityNotHasSuffix?: InputMaybe<Scalars["String"]>;
	cityNotIn?: InputMaybe<Array<Scalars["String"]>>;
	countryContains?: InputMaybe<Scalars["String"]>;
	countryEQ?: InputMaybe<Scalars["String"]>;
	countryHasPrefix?: InputMaybe<Scalars["String"]>;
	countryHasSuffix?: InputMaybe<Scalars["String"]>;
	countryIn?: InputMaybe<Array<Scalars["String"]>>;
	countryNEQ?: InputMaybe<Scalars["String"]>;
	countryNotContains?: InputMaybe<Scalars["String"]>;
	countryNotHasPrefix?: InputMaybe<Scalars["String"]>;
	countryNotHasSuffix?: InputMaybe<Scalars["String"]>;
	countryNotIn?: InputMaybe<Array<Scalars["String"]>>;
	employees?: InputMaybe<Array<EmployeeFilterInput>>;
	hasEmployees?: InputMaybe<Scalars["Boolean"]>;
	hasRooms?: InputMaybe<Scalars["Boolean"]>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	nameContains?: InputMaybe<Scalars["String"]>;
	nameEQ?: InputMaybe<Scalars["String"]>;
	nameHasPrefix?: InputMaybe<Scalars["String"]>;
	nameHasSuffix?: InputMaybe<Scalars["String"]>;
	nameIn?: InputMaybe<Array<Scalars["String"]>>;
	nameNEQ?: InputMaybe<Scalars["String"]>;
	nameNotContains?: InputMaybe<Scalars["String"]>;
	nameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	nameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	nameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	not?: InputMaybe<CampusFilterInput>;
	or?: InputMaybe<Array<CampusFilterInput>>;
	postalCodeContains?: InputMaybe<Scalars["String"]>;
	postalCodeEQ?: InputMaybe<Scalars["String"]>;
	postalCodeHasPrefix?: InputMaybe<Scalars["String"]>;
	postalCodeHasSuffix?: InputMaybe<Scalars["String"]>;
	postalCodeIn?: InputMaybe<Array<Scalars["String"]>>;
	postalCodeNEQ?: InputMaybe<Scalars["String"]>;
	postalCodeNotContains?: InputMaybe<Scalars["String"]>;
	postalCodeNotHasPrefix?: InputMaybe<Scalars["String"]>;
	postalCodeNotHasSuffix?: InputMaybe<Scalars["String"]>;
	postalCodeNotIn?: InputMaybe<Array<Scalars["String"]>>;
	rooms?: InputMaybe<Array<RoomFilterInput>>;
	search?: InputMaybe<Scalars["String"]>;
	stateOrProvinceContains?: InputMaybe<Scalars["String"]>;
	stateOrProvinceEQ?: InputMaybe<Scalars["String"]>;
	stateOrProvinceHasPrefix?: InputMaybe<Scalars["String"]>;
	stateOrProvinceHasSuffix?: InputMaybe<Scalars["String"]>;
	stateOrProvinceIn?: InputMaybe<Array<Scalars["String"]>>;
	stateOrProvinceNEQ?: InputMaybe<Scalars["String"]>;
	stateOrProvinceNotContains?: InputMaybe<Scalars["String"]>;
	stateOrProvinceNotHasPrefix?: InputMaybe<Scalars["String"]>;
	stateOrProvinceNotHasSuffix?: InputMaybe<Scalars["String"]>;
	stateOrProvinceNotIn?: InputMaybe<Array<Scalars["String"]>>;
};

export type CampusHistory = Node & {
	__typename?: "CampusHistory";
	addressLine1: Maybe<Scalars["String"]>;
	addressLine2: Maybe<Scalars["String"]>;
	city: Maybe<Scalars["String"]>;
	country: Maybe<Scalars["String"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	name: Maybe<Scalars["String"]>;
	originalID: Maybe<Scalars["ID"]>;
	postalCode: Maybe<Scalars["String"]>;
	stateOrProvince: Maybe<Scalars["String"]>;
};

export type CampusHistoryConnection = {
	__typename?: "CampusHistoryConnection";
	edges: Array<CampusHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type CampusHistoryEdge = {
	__typename?: "CampusHistoryEdge";
	cursor: Scalars["Cursor"];
	node: CampusHistory;
};

export type CampusHistoryFilterInput = {
	addressLine1Contains?: InputMaybe<Scalars["String"]>;
	addressLine1EQ?: InputMaybe<Scalars["String"]>;
	addressLine1HasPrefix?: InputMaybe<Scalars["String"]>;
	addressLine1HasSuffix?: InputMaybe<Scalars["String"]>;
	addressLine1In?: InputMaybe<Array<Scalars["String"]>>;
	addressLine1IsNull?: InputMaybe<Scalars["Boolean"]>;
	addressLine1NEQ?: InputMaybe<Scalars["String"]>;
	addressLine1NotContains?: InputMaybe<Scalars["String"]>;
	addressLine1NotHasPrefix?: InputMaybe<Scalars["String"]>;
	addressLine1NotHasSuffix?: InputMaybe<Scalars["String"]>;
	addressLine1NotIn?: InputMaybe<Array<Scalars["String"]>>;
	addressLine2Contains?: InputMaybe<Scalars["String"]>;
	addressLine2EQ?: InputMaybe<Scalars["String"]>;
	addressLine2HasPrefix?: InputMaybe<Scalars["String"]>;
	addressLine2HasSuffix?: InputMaybe<Scalars["String"]>;
	addressLine2In?: InputMaybe<Array<Scalars["String"]>>;
	addressLine2IsNull?: InputMaybe<Scalars["Boolean"]>;
	addressLine2NEQ?: InputMaybe<Scalars["String"]>;
	addressLine2NotContains?: InputMaybe<Scalars["String"]>;
	addressLine2NotHasPrefix?: InputMaybe<Scalars["String"]>;
	addressLine2NotHasSuffix?: InputMaybe<Scalars["String"]>;
	addressLine2NotIn?: InputMaybe<Array<Scalars["String"]>>;
	and?: InputMaybe<Array<CampusHistoryFilterInput>>;
	cityContains?: InputMaybe<Scalars["String"]>;
	cityEQ?: InputMaybe<Scalars["String"]>;
	cityHasPrefix?: InputMaybe<Scalars["String"]>;
	cityHasSuffix?: InputMaybe<Scalars["String"]>;
	cityIn?: InputMaybe<Array<Scalars["String"]>>;
	cityIsNull?: InputMaybe<Scalars["Boolean"]>;
	cityNEQ?: InputMaybe<Scalars["String"]>;
	cityNotContains?: InputMaybe<Scalars["String"]>;
	cityNotHasPrefix?: InputMaybe<Scalars["String"]>;
	cityNotHasSuffix?: InputMaybe<Scalars["String"]>;
	cityNotIn?: InputMaybe<Array<Scalars["String"]>>;
	countryContains?: InputMaybe<Scalars["String"]>;
	countryEQ?: InputMaybe<Scalars["String"]>;
	countryHasPrefix?: InputMaybe<Scalars["String"]>;
	countryHasSuffix?: InputMaybe<Scalars["String"]>;
	countryIn?: InputMaybe<Array<Scalars["String"]>>;
	countryIsNull?: InputMaybe<Scalars["Boolean"]>;
	countryNEQ?: InputMaybe<Scalars["String"]>;
	countryNotContains?: InputMaybe<Scalars["String"]>;
	countryNotHasPrefix?: InputMaybe<Scalars["String"]>;
	countryNotHasSuffix?: InputMaybe<Scalars["String"]>;
	countryNotIn?: InputMaybe<Array<Scalars["String"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	nameContains?: InputMaybe<Scalars["String"]>;
	nameEQ?: InputMaybe<Scalars["String"]>;
	nameHasPrefix?: InputMaybe<Scalars["String"]>;
	nameHasSuffix?: InputMaybe<Scalars["String"]>;
	nameIn?: InputMaybe<Array<Scalars["String"]>>;
	nameIsNull?: InputMaybe<Scalars["Boolean"]>;
	nameNEQ?: InputMaybe<Scalars["String"]>;
	nameNotContains?: InputMaybe<Scalars["String"]>;
	nameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	nameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	nameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	not?: InputMaybe<CampusHistoryFilterInput>;
	or?: InputMaybe<Array<CampusHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	postalCodeContains?: InputMaybe<Scalars["String"]>;
	postalCodeEQ?: InputMaybe<Scalars["String"]>;
	postalCodeHasPrefix?: InputMaybe<Scalars["String"]>;
	postalCodeHasSuffix?: InputMaybe<Scalars["String"]>;
	postalCodeIn?: InputMaybe<Array<Scalars["String"]>>;
	postalCodeIsNull?: InputMaybe<Scalars["Boolean"]>;
	postalCodeNEQ?: InputMaybe<Scalars["String"]>;
	postalCodeNotContains?: InputMaybe<Scalars["String"]>;
	postalCodeNotHasPrefix?: InputMaybe<Scalars["String"]>;
	postalCodeNotHasSuffix?: InputMaybe<Scalars["String"]>;
	postalCodeNotIn?: InputMaybe<Array<Scalars["String"]>>;
	search?: InputMaybe<Scalars["String"]>;
	stateOrProvinceContains?: InputMaybe<Scalars["String"]>;
	stateOrProvinceEQ?: InputMaybe<Scalars["String"]>;
	stateOrProvinceHasPrefix?: InputMaybe<Scalars["String"]>;
	stateOrProvinceHasSuffix?: InputMaybe<Scalars["String"]>;
	stateOrProvinceIn?: InputMaybe<Array<Scalars["String"]>>;
	stateOrProvinceIsNull?: InputMaybe<Scalars["Boolean"]>;
	stateOrProvinceNEQ?: InputMaybe<Scalars["String"]>;
	stateOrProvinceNotContains?: InputMaybe<Scalars["String"]>;
	stateOrProvinceNotHasPrefix?: InputMaybe<Scalars["String"]>;
	stateOrProvinceNotHasSuffix?: InputMaybe<Scalars["String"]>;
	stateOrProvinceNotIn?: InputMaybe<Array<Scalars["String"]>>;
};

export type CampusHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<CampusHistoryOrderField>;
};

export enum CampusHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type CampusOrder = {
	direction: OrderDirection;
	field?: InputMaybe<CampusOrderField>;
};

export enum CampusOrderField {
	AddressLine1 = "ADDRESS_LINE_1",
	AddressLine2 = "ADDRESS_LINE_2",
	City = "CITY",
	Country = "COUNTRY",
	Name = "NAME",
	PostalCode = "POSTAL_CODE",
	StateOrProvince = "STATE_OR_PROVINCE",
}

export type CampusUpdateBulkInput = {
	addEmployeeIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addRoomIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addressLine1?: InputMaybe<Scalars["String"]>;
	addressLine2?: InputMaybe<Scalars["String"]>;
	city?: InputMaybe<Scalars["String"]>;
	country?: InputMaybe<Scalars["String"]>;
	name?: InputMaybe<Scalars["String"]>;
	postalCode?: InputMaybe<Scalars["String"]>;
	removeEmployeeIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeRoomIDs?: InputMaybe<Array<Scalars["ID"]>>;
	stateOrProvince?: InputMaybe<Scalars["String"]>;
};

export type CampusUpdateInput = {
	addEmployeeIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addRoomIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addressLine1?: InputMaybe<Scalars["String"]>;
	addressLine2?: InputMaybe<Scalars["String"]>;
	city?: InputMaybe<Scalars["String"]>;
	country?: InputMaybe<Scalars["String"]>;
	name?: InputMaybe<Scalars["String"]>;
	postalCode?: InputMaybe<Scalars["String"]>;
	removeEmployeeIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeRoomIDs?: InputMaybe<Array<Scalars["ID"]>>;
	stateOrProvince?: InputMaybe<Scalars["String"]>;
};

export type ClassYear = Node & {
	__typename?: "ClassYear";
	courses: Array<Course>;
	homeRooms: Array<HomeRoom>;
	id: Scalars["ID"];
	name: Scalars["String"];
	schoolStudentEnrollments: Array<SchoolStudentEnrollment>;
	yearsFromGraduation: Scalars["Int"];
};

export type ClassYearCoursesArgs = {
	filters?: InputMaybe<Array<CourseFilterInput>>;
	order?: InputMaybe<CourseOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type ClassYearHomeRoomsArgs = {
	filters?: InputMaybe<Array<HomeRoomFilterInput>>;
	order?: InputMaybe<HomeRoomOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type ClassYearSchoolStudentEnrollmentsArgs = {
	filters?: InputMaybe<Array<SchoolStudentEnrollmentFilterInput>>;
	order?: InputMaybe<SchoolStudentEnrollmentOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type ClassYearConnection = {
	__typename?: "ClassYearConnection";
	edges: Array<ClassYearEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type ClassYearCourseHistory = Node & {
	__typename?: "ClassYearCourseHistory";
	classYearID: Maybe<Scalars["ID"]>;
	courseID: Maybe<Scalars["ID"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
};

export type ClassYearCourseHistoryConnection = {
	__typename?: "ClassYearCourseHistoryConnection";
	edges: Array<ClassYearCourseHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type ClassYearCourseHistoryEdge = {
	__typename?: "ClassYearCourseHistoryEdge";
	cursor: Scalars["Cursor"];
	node: ClassYearCourseHistory;
};

export type ClassYearCourseHistoryFilterInput = {
	and?: InputMaybe<Array<ClassYearCourseHistoryFilterInput>>;
	classYearIDEQ?: InputMaybe<Scalars["ID"]>;
	classYearIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	classYearIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	classYearIDNEQ?: InputMaybe<Scalars["ID"]>;
	classYearIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	courseIDEQ?: InputMaybe<Scalars["ID"]>;
	courseIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	courseIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	courseIDNEQ?: InputMaybe<Scalars["ID"]>;
	courseIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<ClassYearCourseHistoryFilterInput>;
	or?: InputMaybe<Array<ClassYearCourseHistoryFilterInput>>;
};

export type ClassYearCourseHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<ClassYearCourseHistoryOrderField>;
};

export enum ClassYearCourseHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type ClassYearCreateInput = {
	courseIDs?: InputMaybe<Array<Scalars["ID"]>>;
	homeRoomIDs?: InputMaybe<Array<Scalars["ID"]>>;
	homeRooms?: InputMaybe<Array<HomeRoomInClassYearCreateInput>>;
	name: Scalars["String"];
	schoolStudentEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	schoolStudentEnrollments?: InputMaybe<Array<SchoolStudentEnrollmentInClassYearCreateInput>>;
	yearsFromGraduation: Scalars["Int"];
};

export type ClassYearEdge = {
	__typename?: "ClassYearEdge";
	cursor: Scalars["Cursor"];
	node: ClassYear;
};

export type ClassYearFilterInput = {
	and?: InputMaybe<Array<ClassYearFilterInput>>;
	courses?: InputMaybe<Array<CourseFilterInput>>;
	hasCourses?: InputMaybe<Scalars["Boolean"]>;
	hasHomeRooms?: InputMaybe<Scalars["Boolean"]>;
	hasSchoolStudentEnrollments?: InputMaybe<Scalars["Boolean"]>;
	homeRooms?: InputMaybe<Array<HomeRoomFilterInput>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	nameContains?: InputMaybe<Scalars["String"]>;
	nameEQ?: InputMaybe<Scalars["String"]>;
	nameHasPrefix?: InputMaybe<Scalars["String"]>;
	nameHasSuffix?: InputMaybe<Scalars["String"]>;
	nameIn?: InputMaybe<Array<Scalars["String"]>>;
	nameNEQ?: InputMaybe<Scalars["String"]>;
	nameNotContains?: InputMaybe<Scalars["String"]>;
	nameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	nameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	nameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	not?: InputMaybe<ClassYearFilterInput>;
	or?: InputMaybe<Array<ClassYearFilterInput>>;
	schoolStudentEnrollments?: InputMaybe<Array<SchoolStudentEnrollmentFilterInput>>;
	search?: InputMaybe<Scalars["String"]>;
	yearsFromGraduationEQ?: InputMaybe<Scalars["Int"]>;
	yearsFromGraduationGT?: InputMaybe<Scalars["Int"]>;
	yearsFromGraduationGTE?: InputMaybe<Scalars["Int"]>;
	yearsFromGraduationIn?: InputMaybe<Array<Scalars["Int"]>>;
	yearsFromGraduationLT?: InputMaybe<Scalars["Int"]>;
	yearsFromGraduationLTE?: InputMaybe<Scalars["Int"]>;
	yearsFromGraduationNEQ?: InputMaybe<Scalars["Int"]>;
	yearsFromGraduationNotIn?: InputMaybe<Array<Scalars["Int"]>>;
};

export type ClassYearHistory = Node & {
	__typename?: "ClassYearHistory";
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	name: Maybe<Scalars["String"]>;
	originalID: Maybe<Scalars["ID"]>;
	yearsFromGraduation: Maybe<Scalars["Int"]>;
};

export type ClassYearHistoryConnection = {
	__typename?: "ClassYearHistoryConnection";
	edges: Array<ClassYearHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type ClassYearHistoryEdge = {
	__typename?: "ClassYearHistoryEdge";
	cursor: Scalars["Cursor"];
	node: ClassYearHistory;
};

export type ClassYearHistoryFilterInput = {
	and?: InputMaybe<Array<ClassYearHistoryFilterInput>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	nameContains?: InputMaybe<Scalars["String"]>;
	nameEQ?: InputMaybe<Scalars["String"]>;
	nameHasPrefix?: InputMaybe<Scalars["String"]>;
	nameHasSuffix?: InputMaybe<Scalars["String"]>;
	nameIn?: InputMaybe<Array<Scalars["String"]>>;
	nameIsNull?: InputMaybe<Scalars["Boolean"]>;
	nameNEQ?: InputMaybe<Scalars["String"]>;
	nameNotContains?: InputMaybe<Scalars["String"]>;
	nameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	nameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	nameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	not?: InputMaybe<ClassYearHistoryFilterInput>;
	or?: InputMaybe<Array<ClassYearHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	search?: InputMaybe<Scalars["String"]>;
	yearsFromGraduationEQ?: InputMaybe<Scalars["Int"]>;
	yearsFromGraduationGT?: InputMaybe<Scalars["Int"]>;
	yearsFromGraduationGTE?: InputMaybe<Scalars["Int"]>;
	yearsFromGraduationIn?: InputMaybe<Array<Scalars["Int"]>>;
	yearsFromGraduationIsNull?: InputMaybe<Scalars["Boolean"]>;
	yearsFromGraduationLT?: InputMaybe<Scalars["Int"]>;
	yearsFromGraduationLTE?: InputMaybe<Scalars["Int"]>;
	yearsFromGraduationNEQ?: InputMaybe<Scalars["Int"]>;
	yearsFromGraduationNotIn?: InputMaybe<Array<Scalars["Int"]>>;
};

export type ClassYearHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<ClassYearHistoryOrderField>;
};

export enum ClassYearHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type ClassYearOrder = {
	direction: OrderDirection;
	field?: InputMaybe<ClassYearOrderField>;
};

export enum ClassYearOrderField {
	Name = "NAME",
	YearsFromGraduation = "YEARS_FROM_GRADUATION",
}

export type ClassYearUpdateBulkInput = {
	addCourseIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addHomeRoomIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addSchoolStudentEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	name?: InputMaybe<Scalars["String"]>;
	removeCourseIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeHomeRoomIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeSchoolStudentEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	yearsFromGraduation?: InputMaybe<Scalars["Int"]>;
};

export type ClassYearUpdateInput = {
	addCourseIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addHomeRoomIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addSchoolStudentEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	name?: InputMaybe<Scalars["String"]>;
	removeCourseIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeHomeRoomIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeSchoolStudentEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	yearsFromGraduation?: InputMaybe<Scalars["Int"]>;
};

export type Contract = Node & {
	__typename?: "Contract";
	agreementDate: Scalars["Time"];
	contractInstallments: Array<ContractInstallment>;
	id: Scalars["ID"];
	serviceSubscriptions: Array<ServiceSubscription>;
	status: ContractStatus;
	student: Student;
	studentID: Scalars["ID"];
};

export type ContractContractInstallmentsArgs = {
	filters?: InputMaybe<Array<ContractInstallmentFilterInput>>;
	order?: InputMaybe<ContractInstallmentOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type ContractServiceSubscriptionsArgs = {
	filters?: InputMaybe<Array<ServiceSubscriptionFilterInput>>;
	order?: InputMaybe<ServiceSubscriptionOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type ContractConnection = {
	__typename?: "ContractConnection";
	edges: Array<ContractEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type ContractCreateInput = {
	agreementDate?: InputMaybe<Scalars["Time"]>;
	contractInstallmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	contractInstallments?: InputMaybe<Array<ContractInstallmentInContractCreateInput>>;
	serviceSubscriptionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	serviceSubscriptions?: InputMaybe<Array<ServiceSubscriptionInContractCreateInput>>;
	status?: InputMaybe<ContractStatus>;
	studentID: Scalars["ID"];
};

export type ContractEdge = {
	__typename?: "ContractEdge";
	cursor: Scalars["Cursor"];
	node: Contract;
};

export type ContractFilterInput = {
	agreementDateEQ?: InputMaybe<Scalars["Time"]>;
	agreementDateGT?: InputMaybe<Scalars["Time"]>;
	agreementDateGTE?: InputMaybe<Scalars["Time"]>;
	agreementDateIn?: InputMaybe<Array<Scalars["Time"]>>;
	agreementDateLT?: InputMaybe<Scalars["Time"]>;
	agreementDateLTE?: InputMaybe<Scalars["Time"]>;
	agreementDateNEQ?: InputMaybe<Scalars["Time"]>;
	agreementDateNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	and?: InputMaybe<Array<ContractFilterInput>>;
	contractInstallments?: InputMaybe<Array<ContractInstallmentFilterInput>>;
	hasContractInstallments?: InputMaybe<Scalars["Boolean"]>;
	hasServiceSubscriptions?: InputMaybe<Scalars["Boolean"]>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<ContractFilterInput>;
	or?: InputMaybe<Array<ContractFilterInput>>;
	serviceSubscriptions?: InputMaybe<Array<ServiceSubscriptionFilterInput>>;
	statusEQ?: InputMaybe<ContractStatus>;
	statusIn?: InputMaybe<Array<ContractStatus>>;
	statusNEQ?: InputMaybe<ContractStatus>;
	statusNotIn?: InputMaybe<Array<ContractStatus>>;
	student?: InputMaybe<Array<StudentFilterInput>>;
	studentIDEQ?: InputMaybe<Scalars["ID"]>;
	studentIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	studentIDNEQ?: InputMaybe<Scalars["ID"]>;
	studentIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type ContractHistory = Node & {
	__typename?: "ContractHistory";
	agreementDate: Maybe<Scalars["Time"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	originalID: Maybe<Scalars["ID"]>;
	status: Maybe<ContractHistoryStatus>;
	studentID: Maybe<Scalars["ID"]>;
};

export type ContractHistoryConnection = {
	__typename?: "ContractHistoryConnection";
	edges: Array<ContractHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type ContractHistoryEdge = {
	__typename?: "ContractHistoryEdge";
	cursor: Scalars["Cursor"];
	node: ContractHistory;
};

export type ContractHistoryFilterInput = {
	agreementDateEQ?: InputMaybe<Scalars["Time"]>;
	agreementDateGT?: InputMaybe<Scalars["Time"]>;
	agreementDateGTE?: InputMaybe<Scalars["Time"]>;
	agreementDateIn?: InputMaybe<Array<Scalars["Time"]>>;
	agreementDateIsNull?: InputMaybe<Scalars["Boolean"]>;
	agreementDateLT?: InputMaybe<Scalars["Time"]>;
	agreementDateLTE?: InputMaybe<Scalars["Time"]>;
	agreementDateNEQ?: InputMaybe<Scalars["Time"]>;
	agreementDateNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	and?: InputMaybe<Array<ContractHistoryFilterInput>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<ContractHistoryFilterInput>;
	or?: InputMaybe<Array<ContractHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	statusEQ?: InputMaybe<ContractHistoryStatus>;
	statusIn?: InputMaybe<Array<ContractHistoryStatus>>;
	statusIsNull?: InputMaybe<Scalars["Boolean"]>;
	statusNEQ?: InputMaybe<ContractHistoryStatus>;
	statusNotIn?: InputMaybe<Array<ContractHistoryStatus>>;
	studentIDEQ?: InputMaybe<Scalars["ID"]>;
	studentIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	studentIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	studentIDNEQ?: InputMaybe<Scalars["ID"]>;
	studentIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type ContractHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<ContractHistoryOrderField>;
};

export enum ContractHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export enum ContractHistoryStatus {
	Active = "active",
	Complete = "complete",
	Draft = "draft",
	Void = "void",
}

export type ContractInStudentCreateInput = {
	agreementDate?: InputMaybe<Scalars["Time"]>;
	contractInstallmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	contractInstallments?: InputMaybe<Array<ContractInstallmentInContractCreateInput>>;
	serviceSubscriptionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	serviceSubscriptions?: InputMaybe<Array<ServiceSubscriptionInContractCreateInput>>;
	status?: InputMaybe<ContractStatus>;
};

export type ContractInstallment = Node & {
	__typename?: "ContractInstallment";
	contract: Contract;
	contractID: Scalars["ID"];
	id: Scalars["ID"];
	installmentDate: Scalars["Time"];
	invoice: Maybe<Invoice>;
	portion: Scalars["Int"];
};

export type ContractInstallmentConnection = {
	__typename?: "ContractInstallmentConnection";
	edges: Array<ContractInstallmentEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type ContractInstallmentCreateInput = {
	contractID: Scalars["ID"];
	installmentDate: Scalars["Time"];
	invoice?: InputMaybe<InvoiceInContractInstallmentCreateInput>;
	invoiceID?: InputMaybe<Scalars["ID"]>;
	portion: Scalars["Int"];
};

export type ContractInstallmentEdge = {
	__typename?: "ContractInstallmentEdge";
	cursor: Scalars["Cursor"];
	node: ContractInstallment;
};

export type ContractInstallmentFilterInput = {
	and?: InputMaybe<Array<ContractInstallmentFilterInput>>;
	contract?: InputMaybe<Array<ContractFilterInput>>;
	contractIDEQ?: InputMaybe<Scalars["ID"]>;
	contractIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	contractIDNEQ?: InputMaybe<Scalars["ID"]>;
	contractIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	hasInvoice?: InputMaybe<Scalars["Boolean"]>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	installmentDateEQ?: InputMaybe<Scalars["Time"]>;
	installmentDateGT?: InputMaybe<Scalars["Time"]>;
	installmentDateGTE?: InputMaybe<Scalars["Time"]>;
	installmentDateIn?: InputMaybe<Array<Scalars["Time"]>>;
	installmentDateLT?: InputMaybe<Scalars["Time"]>;
	installmentDateLTE?: InputMaybe<Scalars["Time"]>;
	installmentDateNEQ?: InputMaybe<Scalars["Time"]>;
	installmentDateNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	invoice?: InputMaybe<Array<InvoiceFilterInput>>;
	not?: InputMaybe<ContractInstallmentFilterInput>;
	or?: InputMaybe<Array<ContractInstallmentFilterInput>>;
	portionEQ?: InputMaybe<Scalars["Int"]>;
	portionGT?: InputMaybe<Scalars["Int"]>;
	portionGTE?: InputMaybe<Scalars["Int"]>;
	portionIn?: InputMaybe<Array<Scalars["Int"]>>;
	portionLT?: InputMaybe<Scalars["Int"]>;
	portionLTE?: InputMaybe<Scalars["Int"]>;
	portionNEQ?: InputMaybe<Scalars["Int"]>;
	portionNotIn?: InputMaybe<Array<Scalars["Int"]>>;
};

export type ContractInstallmentHistory = Node & {
	__typename?: "ContractInstallmentHistory";
	contractID: Maybe<Scalars["ID"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	installmentDate: Maybe<Scalars["Time"]>;
	originalID: Maybe<Scalars["ID"]>;
	portion: Maybe<Scalars["Int"]>;
};

export type ContractInstallmentHistoryConnection = {
	__typename?: "ContractInstallmentHistoryConnection";
	edges: Array<ContractInstallmentHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type ContractInstallmentHistoryEdge = {
	__typename?: "ContractInstallmentHistoryEdge";
	cursor: Scalars["Cursor"];
	node: ContractInstallmentHistory;
};

export type ContractInstallmentHistoryFilterInput = {
	and?: InputMaybe<Array<ContractInstallmentHistoryFilterInput>>;
	contractIDEQ?: InputMaybe<Scalars["ID"]>;
	contractIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	contractIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	contractIDNEQ?: InputMaybe<Scalars["ID"]>;
	contractIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	installmentDateEQ?: InputMaybe<Scalars["Time"]>;
	installmentDateGT?: InputMaybe<Scalars["Time"]>;
	installmentDateGTE?: InputMaybe<Scalars["Time"]>;
	installmentDateIn?: InputMaybe<Array<Scalars["Time"]>>;
	installmentDateIsNull?: InputMaybe<Scalars["Boolean"]>;
	installmentDateLT?: InputMaybe<Scalars["Time"]>;
	installmentDateLTE?: InputMaybe<Scalars["Time"]>;
	installmentDateNEQ?: InputMaybe<Scalars["Time"]>;
	installmentDateNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	not?: InputMaybe<ContractInstallmentHistoryFilterInput>;
	or?: InputMaybe<Array<ContractInstallmentHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	portionEQ?: InputMaybe<Scalars["Int"]>;
	portionGT?: InputMaybe<Scalars["Int"]>;
	portionGTE?: InputMaybe<Scalars["Int"]>;
	portionIn?: InputMaybe<Array<Scalars["Int"]>>;
	portionIsNull?: InputMaybe<Scalars["Boolean"]>;
	portionLT?: InputMaybe<Scalars["Int"]>;
	portionLTE?: InputMaybe<Scalars["Int"]>;
	portionNEQ?: InputMaybe<Scalars["Int"]>;
	portionNotIn?: InputMaybe<Array<Scalars["Int"]>>;
};

export type ContractInstallmentHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<ContractInstallmentHistoryOrderField>;
};

export enum ContractInstallmentHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type ContractInstallmentInContractCreateInput = {
	installmentDate: Scalars["Time"];
	invoice?: InputMaybe<InvoiceInContractInstallmentCreateInput>;
	invoiceID?: InputMaybe<Scalars["ID"]>;
	portion: Scalars["Int"];
};

export type ContractInstallmentInInvoiceCreateInput = {
	contractID: Scalars["ID"];
	installmentDate: Scalars["Time"];
	portion: Scalars["Int"];
};

export type ContractInstallmentOrder = {
	direction: OrderDirection;
	field?: InputMaybe<ContractInstallmentOrderField>;
};

export enum ContractInstallmentOrderField {
	InstallmentDate = "INSTALLMENT_DATE",
	Portion = "PORTION",
}

export type ContractInstallmentUpdateBulkInput = {
	contractID?: InputMaybe<Scalars["ID"]>;
	installmentDate?: InputMaybe<Scalars["Time"]>;
	portion?: InputMaybe<Scalars["Int"]>;
};

export type ContractInstallmentUpdateInput = {
	clearInvoice?: InputMaybe<Scalars["Boolean"]>;
	contractID?: InputMaybe<Scalars["ID"]>;
	installmentDate?: InputMaybe<Scalars["Time"]>;
	invoiceID?: InputMaybe<Scalars["ID"]>;
	portion?: InputMaybe<Scalars["Int"]>;
};

export type ContractOrder = {
	direction: OrderDirection;
	field?: InputMaybe<ContractOrderField>;
};

export enum ContractOrderField {
	AgreementDate = "AGREEMENT_DATE",
	Status = "STATUS",
}

export enum ContractStatus {
	Active = "active",
	Complete = "complete",
	Draft = "draft",
	Void = "void",
}

export type ContractUpdateBulkInput = {
	addContractInstallmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addServiceSubscriptionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	agreementDate?: InputMaybe<Scalars["Time"]>;
	removeContractInstallmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeServiceSubscriptionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	status?: InputMaybe<ContractStatus>;
	studentID?: InputMaybe<Scalars["ID"]>;
};

export type ContractUpdateInput = {
	addContractInstallmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addServiceSubscriptionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	agreementDate?: InputMaybe<Scalars["Time"]>;
	removeContractInstallmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeServiceSubscriptionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	status?: InputMaybe<ContractStatus>;
	studentID?: InputMaybe<Scalars["ID"]>;
};

export type Course = Node & {
	__typename?: "Course";
	classYears: Array<ClassYear>;
	courseSections: Array<CourseSection>;
	description: Scalars["String"];
	documentResourceFolders: Array<DocumentResourceFolder>;
	homeRoom: HomeRoom;
	homeRoomID: Scalars["ID"];
	id: Scalars["ID"];
	learningObjectives: Array<LearningObjective>;
	name: Scalars["String"];
	studentCapacity: Maybe<Scalars["Int"]>;
	subject: CourseSubject;
};

export type CourseClassYearsArgs = {
	filters?: InputMaybe<Array<ClassYearFilterInput>>;
	order?: InputMaybe<ClassYearOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type CourseCourseSectionsArgs = {
	filters?: InputMaybe<Array<CourseSectionFilterInput>>;
	order?: InputMaybe<CourseSectionOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type CourseDocumentResourceFoldersArgs = {
	filters?: InputMaybe<Array<DocumentResourceFolderFilterInput>>;
	order?: InputMaybe<DocumentResourceFolderOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type CourseLearningObjectivesArgs = {
	filters?: InputMaybe<Array<LearningObjectiveFilterInput>>;
	order?: InputMaybe<LearningObjectiveOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type CourseAttendanceRecord = Node & {
	__typename?: "CourseAttendanceRecord";
	attendanceDate: Scalars["Time"];
	courseSectionPeriod: CourseSectionPeriod;
	courseSectionPeriodID: Scalars["ID"];
	id: Scalars["ID"];
	isExcused: Scalars["Boolean"];
	isLate: Scalars["Boolean"];
	isPresent: Scalars["Boolean"];
	notes: Scalars["String"];
	student: Student;
	studentID: Scalars["ID"];
};

export type CourseAttendanceRecordConnection = {
	__typename?: "CourseAttendanceRecordConnection";
	edges: Array<CourseAttendanceRecordEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type CourseAttendanceRecordCreateInput = {
	attendanceDate: Scalars["Time"];
	courseSectionPeriodID: Scalars["ID"];
	isExcused?: InputMaybe<Scalars["Boolean"]>;
	isLate?: InputMaybe<Scalars["Boolean"]>;
	isPresent?: InputMaybe<Scalars["Boolean"]>;
	notes?: InputMaybe<Scalars["String"]>;
	studentID: Scalars["ID"];
};

export type CourseAttendanceRecordEdge = {
	__typename?: "CourseAttendanceRecordEdge";
	cursor: Scalars["Cursor"];
	node: CourseAttendanceRecord;
};

export type CourseAttendanceRecordFilterInput = {
	and?: InputMaybe<Array<CourseAttendanceRecordFilterInput>>;
	attendanceDateEQ?: InputMaybe<Scalars["Time"]>;
	attendanceDateGT?: InputMaybe<Scalars["Time"]>;
	attendanceDateGTE?: InputMaybe<Scalars["Time"]>;
	attendanceDateIn?: InputMaybe<Array<Scalars["Time"]>>;
	attendanceDateLT?: InputMaybe<Scalars["Time"]>;
	attendanceDateLTE?: InputMaybe<Scalars["Time"]>;
	attendanceDateNEQ?: InputMaybe<Scalars["Time"]>;
	attendanceDateNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	courseSectionPeriod?: InputMaybe<Array<CourseSectionPeriodFilterInput>>;
	courseSectionPeriodIDEQ?: InputMaybe<Scalars["ID"]>;
	courseSectionPeriodIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	courseSectionPeriodIDNEQ?: InputMaybe<Scalars["ID"]>;
	courseSectionPeriodIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	isExcusedEQ?: InputMaybe<Scalars["Boolean"]>;
	isExcusedNEQ?: InputMaybe<Scalars["Boolean"]>;
	isLateEQ?: InputMaybe<Scalars["Boolean"]>;
	isLateNEQ?: InputMaybe<Scalars["Boolean"]>;
	isPresentEQ?: InputMaybe<Scalars["Boolean"]>;
	isPresentNEQ?: InputMaybe<Scalars["Boolean"]>;
	not?: InputMaybe<CourseAttendanceRecordFilterInput>;
	notesContains?: InputMaybe<Scalars["String"]>;
	notesEQ?: InputMaybe<Scalars["String"]>;
	notesHasPrefix?: InputMaybe<Scalars["String"]>;
	notesHasSuffix?: InputMaybe<Scalars["String"]>;
	notesIn?: InputMaybe<Array<Scalars["String"]>>;
	notesNEQ?: InputMaybe<Scalars["String"]>;
	notesNotContains?: InputMaybe<Scalars["String"]>;
	notesNotHasPrefix?: InputMaybe<Scalars["String"]>;
	notesNotHasSuffix?: InputMaybe<Scalars["String"]>;
	notesNotIn?: InputMaybe<Array<Scalars["String"]>>;
	or?: InputMaybe<Array<CourseAttendanceRecordFilterInput>>;
	search?: InputMaybe<Scalars["String"]>;
	student?: InputMaybe<Array<StudentFilterInput>>;
	studentIDEQ?: InputMaybe<Scalars["ID"]>;
	studentIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	studentIDNEQ?: InputMaybe<Scalars["ID"]>;
	studentIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type CourseAttendanceRecordHistory = Node & {
	__typename?: "CourseAttendanceRecordHistory";
	attendanceDate: Maybe<Scalars["Time"]>;
	courseSectionPeriodID: Maybe<Scalars["ID"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	isExcused: Maybe<Scalars["Boolean"]>;
	isLate: Maybe<Scalars["Boolean"]>;
	isPresent: Maybe<Scalars["Boolean"]>;
	notes: Maybe<Scalars["String"]>;
	originalID: Maybe<Scalars["ID"]>;
	studentID: Maybe<Scalars["ID"]>;
};

export type CourseAttendanceRecordHistoryConnection = {
	__typename?: "CourseAttendanceRecordHistoryConnection";
	edges: Array<CourseAttendanceRecordHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type CourseAttendanceRecordHistoryEdge = {
	__typename?: "CourseAttendanceRecordHistoryEdge";
	cursor: Scalars["Cursor"];
	node: CourseAttendanceRecordHistory;
};

export type CourseAttendanceRecordHistoryFilterInput = {
	and?: InputMaybe<Array<CourseAttendanceRecordHistoryFilterInput>>;
	attendanceDateEQ?: InputMaybe<Scalars["Time"]>;
	attendanceDateGT?: InputMaybe<Scalars["Time"]>;
	attendanceDateGTE?: InputMaybe<Scalars["Time"]>;
	attendanceDateIn?: InputMaybe<Array<Scalars["Time"]>>;
	attendanceDateIsNull?: InputMaybe<Scalars["Boolean"]>;
	attendanceDateLT?: InputMaybe<Scalars["Time"]>;
	attendanceDateLTE?: InputMaybe<Scalars["Time"]>;
	attendanceDateNEQ?: InputMaybe<Scalars["Time"]>;
	attendanceDateNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	courseSectionPeriodIDEQ?: InputMaybe<Scalars["ID"]>;
	courseSectionPeriodIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	courseSectionPeriodIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	courseSectionPeriodIDNEQ?: InputMaybe<Scalars["ID"]>;
	courseSectionPeriodIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	isExcusedEQ?: InputMaybe<Scalars["Boolean"]>;
	isExcusedIsNull?: InputMaybe<Scalars["Boolean"]>;
	isExcusedNEQ?: InputMaybe<Scalars["Boolean"]>;
	isLateEQ?: InputMaybe<Scalars["Boolean"]>;
	isLateIsNull?: InputMaybe<Scalars["Boolean"]>;
	isLateNEQ?: InputMaybe<Scalars["Boolean"]>;
	isPresentEQ?: InputMaybe<Scalars["Boolean"]>;
	isPresentIsNull?: InputMaybe<Scalars["Boolean"]>;
	isPresentNEQ?: InputMaybe<Scalars["Boolean"]>;
	not?: InputMaybe<CourseAttendanceRecordHistoryFilterInput>;
	notesContains?: InputMaybe<Scalars["String"]>;
	notesEQ?: InputMaybe<Scalars["String"]>;
	notesHasPrefix?: InputMaybe<Scalars["String"]>;
	notesHasSuffix?: InputMaybe<Scalars["String"]>;
	notesIn?: InputMaybe<Array<Scalars["String"]>>;
	notesIsNull?: InputMaybe<Scalars["Boolean"]>;
	notesNEQ?: InputMaybe<Scalars["String"]>;
	notesNotContains?: InputMaybe<Scalars["String"]>;
	notesNotHasPrefix?: InputMaybe<Scalars["String"]>;
	notesNotHasSuffix?: InputMaybe<Scalars["String"]>;
	notesNotIn?: InputMaybe<Array<Scalars["String"]>>;
	or?: InputMaybe<Array<CourseAttendanceRecordHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	search?: InputMaybe<Scalars["String"]>;
	studentIDEQ?: InputMaybe<Scalars["ID"]>;
	studentIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	studentIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	studentIDNEQ?: InputMaybe<Scalars["ID"]>;
	studentIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type CourseAttendanceRecordHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<CourseAttendanceRecordHistoryOrderField>;
};

export enum CourseAttendanceRecordHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type CourseAttendanceRecordInCourseSectionPeriodCreateInput = {
	attendanceDate: Scalars["Time"];
	isExcused?: InputMaybe<Scalars["Boolean"]>;
	isLate?: InputMaybe<Scalars["Boolean"]>;
	isPresent?: InputMaybe<Scalars["Boolean"]>;
	notes?: InputMaybe<Scalars["String"]>;
	studentID: Scalars["ID"];
};

export type CourseAttendanceRecordInStudentCreateInput = {
	attendanceDate: Scalars["Time"];
	courseSectionPeriodID: Scalars["ID"];
	isExcused?: InputMaybe<Scalars["Boolean"]>;
	isLate?: InputMaybe<Scalars["Boolean"]>;
	isPresent?: InputMaybe<Scalars["Boolean"]>;
	notes?: InputMaybe<Scalars["String"]>;
};

export type CourseAttendanceRecordOrder = {
	direction: OrderDirection;
	field?: InputMaybe<CourseAttendanceRecordOrderField>;
};

export enum CourseAttendanceRecordOrderField {
	AttendanceDate = "ATTENDANCE_DATE",
	IsExcused = "IS_EXCUSED",
	IsLate = "IS_LATE",
	IsPresent = "IS_PRESENT",
}

export type CourseAttendanceRecordUpdateBulkInput = {
	attendanceDate?: InputMaybe<Scalars["Time"]>;
	courseSectionPeriodID?: InputMaybe<Scalars["ID"]>;
	isExcused?: InputMaybe<Scalars["Boolean"]>;
	isLate?: InputMaybe<Scalars["Boolean"]>;
	isPresent?: InputMaybe<Scalars["Boolean"]>;
	notes?: InputMaybe<Scalars["String"]>;
	studentID?: InputMaybe<Scalars["ID"]>;
};

export type CourseAttendanceRecordUpdateInput = {
	attendanceDate?: InputMaybe<Scalars["Time"]>;
	courseSectionPeriodID?: InputMaybe<Scalars["ID"]>;
	isExcused?: InputMaybe<Scalars["Boolean"]>;
	isLate?: InputMaybe<Scalars["Boolean"]>;
	isPresent?: InputMaybe<Scalars["Boolean"]>;
	notes?: InputMaybe<Scalars["String"]>;
	studentID?: InputMaybe<Scalars["ID"]>;
};

export type CourseConnection = {
	__typename?: "CourseConnection";
	edges: Array<CourseEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type CourseCreateInput = {
	classYearIDs?: InputMaybe<Array<Scalars["ID"]>>;
	courseSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	courseSections?: InputMaybe<Array<CourseSectionInCourseCreateInput>>;
	description?: InputMaybe<Scalars["String"]>;
	documentResourceFolderIDs?: InputMaybe<Array<Scalars["ID"]>>;
	homeRoomID: Scalars["ID"];
	learningObjectiveIDs?: InputMaybe<Array<Scalars["ID"]>>;
	learningObjectives?: InputMaybe<Array<LearningObjectiveInCourseCreateInput>>;
	name: Scalars["String"];
	studentCapacity?: InputMaybe<Scalars["Int"]>;
	subject: CourseSubject;
};

export type CourseEdge = {
	__typename?: "CourseEdge";
	cursor: Scalars["Cursor"];
	node: Course;
};

export type CourseFilterInput = {
	and?: InputMaybe<Array<CourseFilterInput>>;
	classYears?: InputMaybe<Array<ClassYearFilterInput>>;
	courseSections?: InputMaybe<Array<CourseSectionFilterInput>>;
	descriptionContains?: InputMaybe<Scalars["String"]>;
	descriptionEQ?: InputMaybe<Scalars["String"]>;
	descriptionHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionIn?: InputMaybe<Array<Scalars["String"]>>;
	descriptionNEQ?: InputMaybe<Scalars["String"]>;
	descriptionNotContains?: InputMaybe<Scalars["String"]>;
	descriptionNotHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionNotHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionNotIn?: InputMaybe<Array<Scalars["String"]>>;
	documentResourceFolders?: InputMaybe<Array<DocumentResourceFolderFilterInput>>;
	hasClassYears?: InputMaybe<Scalars["Boolean"]>;
	hasCourseSections?: InputMaybe<Scalars["Boolean"]>;
	hasDocumentResourceFolders?: InputMaybe<Scalars["Boolean"]>;
	hasLearningObjectives?: InputMaybe<Scalars["Boolean"]>;
	homeRoom?: InputMaybe<Array<HomeRoomFilterInput>>;
	homeRoomIDEQ?: InputMaybe<Scalars["ID"]>;
	homeRoomIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	homeRoomIDNEQ?: InputMaybe<Scalars["ID"]>;
	homeRoomIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	learningObjectives?: InputMaybe<Array<LearningObjectiveFilterInput>>;
	nameContains?: InputMaybe<Scalars["String"]>;
	nameEQ?: InputMaybe<Scalars["String"]>;
	nameHasPrefix?: InputMaybe<Scalars["String"]>;
	nameHasSuffix?: InputMaybe<Scalars["String"]>;
	nameIn?: InputMaybe<Array<Scalars["String"]>>;
	nameNEQ?: InputMaybe<Scalars["String"]>;
	nameNotContains?: InputMaybe<Scalars["String"]>;
	nameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	nameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	nameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	not?: InputMaybe<CourseFilterInput>;
	or?: InputMaybe<Array<CourseFilterInput>>;
	search?: InputMaybe<Scalars["String"]>;
	studentCapacityEQ?: InputMaybe<Scalars["Int"]>;
	studentCapacityGT?: InputMaybe<Scalars["Int"]>;
	studentCapacityGTE?: InputMaybe<Scalars["Int"]>;
	studentCapacityIn?: InputMaybe<Array<Scalars["Int"]>>;
	studentCapacityIsNull?: InputMaybe<Scalars["Boolean"]>;
	studentCapacityLT?: InputMaybe<Scalars["Int"]>;
	studentCapacityLTE?: InputMaybe<Scalars["Int"]>;
	studentCapacityNEQ?: InputMaybe<Scalars["Int"]>;
	studentCapacityNotIn?: InputMaybe<Array<Scalars["Int"]>>;
	subjectEQ?: InputMaybe<CourseSubject>;
	subjectIn?: InputMaybe<Array<CourseSubject>>;
	subjectNEQ?: InputMaybe<CourseSubject>;
	subjectNotIn?: InputMaybe<Array<CourseSubject>>;
};

export type CourseHistory = Node & {
	__typename?: "CourseHistory";
	description: Maybe<Scalars["String"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	homeRoomID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	name: Maybe<Scalars["String"]>;
	originalID: Maybe<Scalars["ID"]>;
	studentCapacity: Maybe<Scalars["Int"]>;
	subject: Maybe<CourseHistorySubject>;
};

export type CourseHistoryConnection = {
	__typename?: "CourseHistoryConnection";
	edges: Array<CourseHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type CourseHistoryEdge = {
	__typename?: "CourseHistoryEdge";
	cursor: Scalars["Cursor"];
	node: CourseHistory;
};

export type CourseHistoryFilterInput = {
	and?: InputMaybe<Array<CourseHistoryFilterInput>>;
	descriptionContains?: InputMaybe<Scalars["String"]>;
	descriptionEQ?: InputMaybe<Scalars["String"]>;
	descriptionHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionIn?: InputMaybe<Array<Scalars["String"]>>;
	descriptionIsNull?: InputMaybe<Scalars["Boolean"]>;
	descriptionNEQ?: InputMaybe<Scalars["String"]>;
	descriptionNotContains?: InputMaybe<Scalars["String"]>;
	descriptionNotHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionNotHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionNotIn?: InputMaybe<Array<Scalars["String"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	homeRoomIDEQ?: InputMaybe<Scalars["ID"]>;
	homeRoomIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	homeRoomIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	homeRoomIDNEQ?: InputMaybe<Scalars["ID"]>;
	homeRoomIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	nameContains?: InputMaybe<Scalars["String"]>;
	nameEQ?: InputMaybe<Scalars["String"]>;
	nameHasPrefix?: InputMaybe<Scalars["String"]>;
	nameHasSuffix?: InputMaybe<Scalars["String"]>;
	nameIn?: InputMaybe<Array<Scalars["String"]>>;
	nameIsNull?: InputMaybe<Scalars["Boolean"]>;
	nameNEQ?: InputMaybe<Scalars["String"]>;
	nameNotContains?: InputMaybe<Scalars["String"]>;
	nameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	nameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	nameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	not?: InputMaybe<CourseHistoryFilterInput>;
	or?: InputMaybe<Array<CourseHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	search?: InputMaybe<Scalars["String"]>;
	studentCapacityEQ?: InputMaybe<Scalars["Int"]>;
	studentCapacityGT?: InputMaybe<Scalars["Int"]>;
	studentCapacityGTE?: InputMaybe<Scalars["Int"]>;
	studentCapacityIn?: InputMaybe<Array<Scalars["Int"]>>;
	studentCapacityIsNull?: InputMaybe<Scalars["Boolean"]>;
	studentCapacityLT?: InputMaybe<Scalars["Int"]>;
	studentCapacityLTE?: InputMaybe<Scalars["Int"]>;
	studentCapacityNEQ?: InputMaybe<Scalars["Int"]>;
	studentCapacityNotIn?: InputMaybe<Array<Scalars["Int"]>>;
	subjectEQ?: InputMaybe<CourseHistorySubject>;
	subjectIn?: InputMaybe<Array<CourseHistorySubject>>;
	subjectIsNull?: InputMaybe<Scalars["Boolean"]>;
	subjectNEQ?: InputMaybe<CourseHistorySubject>;
	subjectNotIn?: InputMaybe<Array<CourseHistorySubject>>;
};

export type CourseHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<CourseHistoryOrderField>;
};

export enum CourseHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export enum CourseHistorySubject {
	Art = "art",
	Core = "core",
	Ell = "ell",
	ForeignLanguage = "foreign_language",
	Ipc = "ipc",
	LanguageArts = "language_arts",
	Math = "math",
	Music = "music",
	Pe = "pe",
	Reading = "reading",
	Science = "science",
	SelTime = "sel_time",
	Technology = "technology",
}

export type CourseInHomeRoomCreateInput = {
	classYearIDs?: InputMaybe<Array<Scalars["ID"]>>;
	courseSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	courseSections?: InputMaybe<Array<CourseSectionInCourseCreateInput>>;
	description?: InputMaybe<Scalars["String"]>;
	documentResourceFolderIDs?: InputMaybe<Array<Scalars["ID"]>>;
	learningObjectiveIDs?: InputMaybe<Array<Scalars["ID"]>>;
	learningObjectives?: InputMaybe<Array<LearningObjectiveInCourseCreateInput>>;
	name: Scalars["String"];
	studentCapacity?: InputMaybe<Scalars["Int"]>;
	subject: CourseSubject;
};

export type CourseOrder = {
	direction: OrderDirection;
	field?: InputMaybe<CourseOrderField>;
};

export enum CourseOrderField {
	Name = "NAME",
	StudentCapacity = "STUDENT_CAPACITY",
	Subject = "SUBJECT",
}

export type CourseSection = Node & {
	__typename?: "CourseSection";
	course: Course;
	courseID: Scalars["ID"];
	courseSectionPeriods: Array<CourseSectionPeriod>;
	courseSectionStudentEnrollments: Array<CourseSectionStudentEnrollment>;
	courseSectionTeacherEnrollments: Array<CourseSectionTeacherEnrollment>;
	description: Scalars["String"];
	documentResourceFolders: Array<DocumentResourceFolder>;
	homeRoomSection: HomeRoomSection;
	homeRoomSectionID: Scalars["ID"];
	id: Scalars["ID"];
	lessonPlans: Array<LessonPlan>;
	name: Scalars["String"];
	notes: Array<Note>;
	term: Term;
	termID: Scalars["ID"];
};

export type CourseSectionCourseSectionPeriodsArgs = {
	filters?: InputMaybe<Array<CourseSectionPeriodFilterInput>>;
	order?: InputMaybe<CourseSectionPeriodOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type CourseSectionCourseSectionStudentEnrollmentsArgs = {
	filters?: InputMaybe<Array<CourseSectionStudentEnrollmentFilterInput>>;
	order?: InputMaybe<CourseSectionStudentEnrollmentOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type CourseSectionCourseSectionTeacherEnrollmentsArgs = {
	filters?: InputMaybe<Array<CourseSectionTeacherEnrollmentFilterInput>>;
	order?: InputMaybe<CourseSectionTeacherEnrollmentOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type CourseSectionDocumentResourceFoldersArgs = {
	filters?: InputMaybe<Array<DocumentResourceFolderFilterInput>>;
	order?: InputMaybe<DocumentResourceFolderOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type CourseSectionLessonPlansArgs = {
	filters?: InputMaybe<Array<LessonPlanFilterInput>>;
	order?: InputMaybe<LessonPlanOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type CourseSectionNotesArgs = {
	filters?: InputMaybe<Array<NoteFilterInput>>;
	order?: InputMaybe<NoteOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type CourseSectionConnection = {
	__typename?: "CourseSectionConnection";
	edges: Array<CourseSectionEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type CourseSectionCreateInput = {
	courseID: Scalars["ID"];
	courseSectionPeriodIDs?: InputMaybe<Array<Scalars["ID"]>>;
	courseSectionPeriods?: InputMaybe<Array<CourseSectionPeriodInCourseSectionCreateInput>>;
	courseSectionStudentEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	courseSectionStudentEnrollments?: InputMaybe<Array<CourseSectionStudentEnrollmentInCourseSectionCreateInput>>;
	courseSectionTeacherEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	courseSectionTeacherEnrollments?: InputMaybe<Array<CourseSectionTeacherEnrollmentInCourseSectionCreateInput>>;
	description?: InputMaybe<Scalars["String"]>;
	documentResourceFolderIDs?: InputMaybe<Array<Scalars["ID"]>>;
	homeRoomSectionID: Scalars["ID"];
	lessonPlanIDs?: InputMaybe<Array<Scalars["ID"]>>;
	lessonPlans?: InputMaybe<Array<LessonPlanInCourseSectionCreateInput>>;
	name: Scalars["String"];
	noteIDs?: InputMaybe<Array<Scalars["ID"]>>;
	termID: Scalars["ID"];
};

export type CourseSectionEdge = {
	__typename?: "CourseSectionEdge";
	cursor: Scalars["Cursor"];
	node: CourseSection;
};

export type CourseSectionFilterInput = {
	and?: InputMaybe<Array<CourseSectionFilterInput>>;
	course?: InputMaybe<Array<CourseFilterInput>>;
	courseIDEQ?: InputMaybe<Scalars["ID"]>;
	courseIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	courseIDNEQ?: InputMaybe<Scalars["ID"]>;
	courseIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	courseSectionPeriods?: InputMaybe<Array<CourseSectionPeriodFilterInput>>;
	courseSectionStudentEnrollments?: InputMaybe<Array<CourseSectionStudentEnrollmentFilterInput>>;
	courseSectionTeacherEnrollments?: InputMaybe<Array<CourseSectionTeacherEnrollmentFilterInput>>;
	descriptionContains?: InputMaybe<Scalars["String"]>;
	descriptionEQ?: InputMaybe<Scalars["String"]>;
	descriptionHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionIn?: InputMaybe<Array<Scalars["String"]>>;
	descriptionNEQ?: InputMaybe<Scalars["String"]>;
	descriptionNotContains?: InputMaybe<Scalars["String"]>;
	descriptionNotHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionNotHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionNotIn?: InputMaybe<Array<Scalars["String"]>>;
	documentResourceFolders?: InputMaybe<Array<DocumentResourceFolderFilterInput>>;
	hasCourseSectionPeriods?: InputMaybe<Scalars["Boolean"]>;
	hasCourseSectionStudentEnrollments?: InputMaybe<Scalars["Boolean"]>;
	hasCourseSectionTeacherEnrollments?: InputMaybe<Scalars["Boolean"]>;
	hasDocumentResourceFolders?: InputMaybe<Scalars["Boolean"]>;
	hasLessonPlans?: InputMaybe<Scalars["Boolean"]>;
	hasNotes?: InputMaybe<Scalars["Boolean"]>;
	homeRoomSection?: InputMaybe<Array<HomeRoomSectionFilterInput>>;
	homeRoomSectionIDEQ?: InputMaybe<Scalars["ID"]>;
	homeRoomSectionIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	homeRoomSectionIDNEQ?: InputMaybe<Scalars["ID"]>;
	homeRoomSectionIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	lessonPlans?: InputMaybe<Array<LessonPlanFilterInput>>;
	nameContains?: InputMaybe<Scalars["String"]>;
	nameEQ?: InputMaybe<Scalars["String"]>;
	nameHasPrefix?: InputMaybe<Scalars["String"]>;
	nameHasSuffix?: InputMaybe<Scalars["String"]>;
	nameIn?: InputMaybe<Array<Scalars["String"]>>;
	nameNEQ?: InputMaybe<Scalars["String"]>;
	nameNotContains?: InputMaybe<Scalars["String"]>;
	nameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	nameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	nameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	not?: InputMaybe<CourseSectionFilterInput>;
	notes?: InputMaybe<Array<NoteFilterInput>>;
	or?: InputMaybe<Array<CourseSectionFilterInput>>;
	search?: InputMaybe<Scalars["String"]>;
	term?: InputMaybe<Array<TermFilterInput>>;
	termIDEQ?: InputMaybe<Scalars["ID"]>;
	termIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	termIDNEQ?: InputMaybe<Scalars["ID"]>;
	termIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type CourseSectionHistory = Node & {
	__typename?: "CourseSectionHistory";
	courseID: Maybe<Scalars["ID"]>;
	description: Maybe<Scalars["String"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	homeRoomSectionID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	name: Maybe<Scalars["String"]>;
	originalID: Maybe<Scalars["ID"]>;
	termID: Maybe<Scalars["ID"]>;
};

export type CourseSectionHistoryConnection = {
	__typename?: "CourseSectionHistoryConnection";
	edges: Array<CourseSectionHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type CourseSectionHistoryEdge = {
	__typename?: "CourseSectionHistoryEdge";
	cursor: Scalars["Cursor"];
	node: CourseSectionHistory;
};

export type CourseSectionHistoryFilterInput = {
	and?: InputMaybe<Array<CourseSectionHistoryFilterInput>>;
	courseIDEQ?: InputMaybe<Scalars["ID"]>;
	courseIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	courseIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	courseIDNEQ?: InputMaybe<Scalars["ID"]>;
	courseIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	descriptionContains?: InputMaybe<Scalars["String"]>;
	descriptionEQ?: InputMaybe<Scalars["String"]>;
	descriptionHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionIn?: InputMaybe<Array<Scalars["String"]>>;
	descriptionIsNull?: InputMaybe<Scalars["Boolean"]>;
	descriptionNEQ?: InputMaybe<Scalars["String"]>;
	descriptionNotContains?: InputMaybe<Scalars["String"]>;
	descriptionNotHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionNotHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionNotIn?: InputMaybe<Array<Scalars["String"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	homeRoomSectionIDEQ?: InputMaybe<Scalars["ID"]>;
	homeRoomSectionIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	homeRoomSectionIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	homeRoomSectionIDNEQ?: InputMaybe<Scalars["ID"]>;
	homeRoomSectionIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	nameContains?: InputMaybe<Scalars["String"]>;
	nameEQ?: InputMaybe<Scalars["String"]>;
	nameHasPrefix?: InputMaybe<Scalars["String"]>;
	nameHasSuffix?: InputMaybe<Scalars["String"]>;
	nameIn?: InputMaybe<Array<Scalars["String"]>>;
	nameIsNull?: InputMaybe<Scalars["Boolean"]>;
	nameNEQ?: InputMaybe<Scalars["String"]>;
	nameNotContains?: InputMaybe<Scalars["String"]>;
	nameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	nameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	nameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	not?: InputMaybe<CourseSectionHistoryFilterInput>;
	or?: InputMaybe<Array<CourseSectionHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	search?: InputMaybe<Scalars["String"]>;
	termIDEQ?: InputMaybe<Scalars["ID"]>;
	termIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	termIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	termIDNEQ?: InputMaybe<Scalars["ID"]>;
	termIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type CourseSectionHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<CourseSectionHistoryOrderField>;
};

export enum CourseSectionHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type CourseSectionInCourseCreateInput = {
	courseSectionPeriodIDs?: InputMaybe<Array<Scalars["ID"]>>;
	courseSectionPeriods?: InputMaybe<Array<CourseSectionPeriodInCourseSectionCreateInput>>;
	courseSectionStudentEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	courseSectionStudentEnrollments?: InputMaybe<Array<CourseSectionStudentEnrollmentInCourseSectionCreateInput>>;
	courseSectionTeacherEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	courseSectionTeacherEnrollments?: InputMaybe<Array<CourseSectionTeacherEnrollmentInCourseSectionCreateInput>>;
	description?: InputMaybe<Scalars["String"]>;
	documentResourceFolderIDs?: InputMaybe<Array<Scalars["ID"]>>;
	homeRoomSectionID: Scalars["ID"];
	lessonPlanIDs?: InputMaybe<Array<Scalars["ID"]>>;
	lessonPlans?: InputMaybe<Array<LessonPlanInCourseSectionCreateInput>>;
	name: Scalars["String"];
	noteIDs?: InputMaybe<Array<Scalars["ID"]>>;
	termID: Scalars["ID"];
};

export type CourseSectionInHomeRoomSectionCreateInput = {
	courseID: Scalars["ID"];
	courseSectionPeriodIDs?: InputMaybe<Array<Scalars["ID"]>>;
	courseSectionPeriods?: InputMaybe<Array<CourseSectionPeriodInCourseSectionCreateInput>>;
	courseSectionStudentEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	courseSectionStudentEnrollments?: InputMaybe<Array<CourseSectionStudentEnrollmentInCourseSectionCreateInput>>;
	courseSectionTeacherEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	courseSectionTeacherEnrollments?: InputMaybe<Array<CourseSectionTeacherEnrollmentInCourseSectionCreateInput>>;
	description?: InputMaybe<Scalars["String"]>;
	documentResourceFolderIDs?: InputMaybe<Array<Scalars["ID"]>>;
	lessonPlanIDs?: InputMaybe<Array<Scalars["ID"]>>;
	lessonPlans?: InputMaybe<Array<LessonPlanInCourseSectionCreateInput>>;
	name: Scalars["String"];
	noteIDs?: InputMaybe<Array<Scalars["ID"]>>;
	termID: Scalars["ID"];
};

export type CourseSectionInTermCreateInput = {
	courseID: Scalars["ID"];
	courseSectionPeriodIDs?: InputMaybe<Array<Scalars["ID"]>>;
	courseSectionPeriods?: InputMaybe<Array<CourseSectionPeriodInCourseSectionCreateInput>>;
	courseSectionStudentEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	courseSectionStudentEnrollments?: InputMaybe<Array<CourseSectionStudentEnrollmentInCourseSectionCreateInput>>;
	courseSectionTeacherEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	courseSectionTeacherEnrollments?: InputMaybe<Array<CourseSectionTeacherEnrollmentInCourseSectionCreateInput>>;
	description?: InputMaybe<Scalars["String"]>;
	documentResourceFolderIDs?: InputMaybe<Array<Scalars["ID"]>>;
	homeRoomSectionID: Scalars["ID"];
	lessonPlanIDs?: InputMaybe<Array<Scalars["ID"]>>;
	lessonPlans?: InputMaybe<Array<LessonPlanInCourseSectionCreateInput>>;
	name: Scalars["String"];
	noteIDs?: InputMaybe<Array<Scalars["ID"]>>;
};

export type CourseSectionOrder = {
	direction: OrderDirection;
	field?: InputMaybe<CourseSectionOrderField>;
};

export enum CourseSectionOrderField {
	Name = "NAME",
}

export type CourseSectionPeriod = Node & {
	__typename?: "CourseSectionPeriod";
	courseAttendanceRecords: Array<CourseAttendanceRecord>;
	courseSection: CourseSection;
	courseSectionID: Scalars["ID"];
	dayOfWeek: Scalars["Int"];
	endTimeMinutes: Scalars["Int"];
	id: Scalars["ID"];
	room: Room;
	roomID: Scalars["ID"];
	startTimeMinutes: Scalars["Int"];
};

export type CourseSectionPeriodCourseAttendanceRecordsArgs = {
	filters?: InputMaybe<Array<CourseAttendanceRecordFilterInput>>;
	order?: InputMaybe<CourseAttendanceRecordOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type CourseSectionPeriodConnection = {
	__typename?: "CourseSectionPeriodConnection";
	edges: Array<CourseSectionPeriodEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type CourseSectionPeriodCreateInput = {
	courseAttendanceRecordIDs?: InputMaybe<Array<Scalars["ID"]>>;
	courseAttendanceRecords?: InputMaybe<Array<CourseAttendanceRecordInCourseSectionPeriodCreateInput>>;
	courseSectionID: Scalars["ID"];
	dayOfWeek: Scalars["Int"];
	endTimeMinutes: Scalars["Int"];
	roomID: Scalars["ID"];
	startTimeMinutes: Scalars["Int"];
};

export type CourseSectionPeriodEdge = {
	__typename?: "CourseSectionPeriodEdge";
	cursor: Scalars["Cursor"];
	node: CourseSectionPeriod;
};

export type CourseSectionPeriodFilterInput = {
	and?: InputMaybe<Array<CourseSectionPeriodFilterInput>>;
	courseAttendanceRecords?: InputMaybe<Array<CourseAttendanceRecordFilterInput>>;
	courseSection?: InputMaybe<Array<CourseSectionFilterInput>>;
	courseSectionIDEQ?: InputMaybe<Scalars["ID"]>;
	courseSectionIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	courseSectionIDNEQ?: InputMaybe<Scalars["ID"]>;
	courseSectionIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	dayOfWeekEQ?: InputMaybe<Scalars["Int"]>;
	dayOfWeekGT?: InputMaybe<Scalars["Int"]>;
	dayOfWeekGTE?: InputMaybe<Scalars["Int"]>;
	dayOfWeekIn?: InputMaybe<Array<Scalars["Int"]>>;
	dayOfWeekLT?: InputMaybe<Scalars["Int"]>;
	dayOfWeekLTE?: InputMaybe<Scalars["Int"]>;
	dayOfWeekNEQ?: InputMaybe<Scalars["Int"]>;
	dayOfWeekNotIn?: InputMaybe<Array<Scalars["Int"]>>;
	endTimeMinutesEQ?: InputMaybe<Scalars["Int"]>;
	endTimeMinutesGT?: InputMaybe<Scalars["Int"]>;
	endTimeMinutesGTE?: InputMaybe<Scalars["Int"]>;
	endTimeMinutesIn?: InputMaybe<Array<Scalars["Int"]>>;
	endTimeMinutesLT?: InputMaybe<Scalars["Int"]>;
	endTimeMinutesLTE?: InputMaybe<Scalars["Int"]>;
	endTimeMinutesNEQ?: InputMaybe<Scalars["Int"]>;
	endTimeMinutesNotIn?: InputMaybe<Array<Scalars["Int"]>>;
	hasCourseAttendanceRecords?: InputMaybe<Scalars["Boolean"]>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<CourseSectionPeriodFilterInput>;
	or?: InputMaybe<Array<CourseSectionPeriodFilterInput>>;
	room?: InputMaybe<Array<RoomFilterInput>>;
	roomIDEQ?: InputMaybe<Scalars["ID"]>;
	roomIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	roomIDNEQ?: InputMaybe<Scalars["ID"]>;
	roomIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	startTimeMinutesEQ?: InputMaybe<Scalars["Int"]>;
	startTimeMinutesGT?: InputMaybe<Scalars["Int"]>;
	startTimeMinutesGTE?: InputMaybe<Scalars["Int"]>;
	startTimeMinutesIn?: InputMaybe<Array<Scalars["Int"]>>;
	startTimeMinutesLT?: InputMaybe<Scalars["Int"]>;
	startTimeMinutesLTE?: InputMaybe<Scalars["Int"]>;
	startTimeMinutesNEQ?: InputMaybe<Scalars["Int"]>;
	startTimeMinutesNotIn?: InputMaybe<Array<Scalars["Int"]>>;
};

export type CourseSectionPeriodHistory = Node & {
	__typename?: "CourseSectionPeriodHistory";
	courseSectionID: Maybe<Scalars["ID"]>;
	dayOfWeek: Maybe<Scalars["Int"]>;
	endTimeMinutes: Maybe<Scalars["Int"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	originalID: Maybe<Scalars["ID"]>;
	roomID: Maybe<Scalars["ID"]>;
	startTimeMinutes: Maybe<Scalars["Int"]>;
};

export type CourseSectionPeriodHistoryConnection = {
	__typename?: "CourseSectionPeriodHistoryConnection";
	edges: Array<CourseSectionPeriodHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type CourseSectionPeriodHistoryEdge = {
	__typename?: "CourseSectionPeriodHistoryEdge";
	cursor: Scalars["Cursor"];
	node: CourseSectionPeriodHistory;
};

export type CourseSectionPeriodHistoryFilterInput = {
	and?: InputMaybe<Array<CourseSectionPeriodHistoryFilterInput>>;
	courseSectionIDEQ?: InputMaybe<Scalars["ID"]>;
	courseSectionIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	courseSectionIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	courseSectionIDNEQ?: InputMaybe<Scalars["ID"]>;
	courseSectionIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	dayOfWeekEQ?: InputMaybe<Scalars["Int"]>;
	dayOfWeekGT?: InputMaybe<Scalars["Int"]>;
	dayOfWeekGTE?: InputMaybe<Scalars["Int"]>;
	dayOfWeekIn?: InputMaybe<Array<Scalars["Int"]>>;
	dayOfWeekIsNull?: InputMaybe<Scalars["Boolean"]>;
	dayOfWeekLT?: InputMaybe<Scalars["Int"]>;
	dayOfWeekLTE?: InputMaybe<Scalars["Int"]>;
	dayOfWeekNEQ?: InputMaybe<Scalars["Int"]>;
	dayOfWeekNotIn?: InputMaybe<Array<Scalars["Int"]>>;
	endTimeMinutesEQ?: InputMaybe<Scalars["Int"]>;
	endTimeMinutesGT?: InputMaybe<Scalars["Int"]>;
	endTimeMinutesGTE?: InputMaybe<Scalars["Int"]>;
	endTimeMinutesIn?: InputMaybe<Array<Scalars["Int"]>>;
	endTimeMinutesIsNull?: InputMaybe<Scalars["Boolean"]>;
	endTimeMinutesLT?: InputMaybe<Scalars["Int"]>;
	endTimeMinutesLTE?: InputMaybe<Scalars["Int"]>;
	endTimeMinutesNEQ?: InputMaybe<Scalars["Int"]>;
	endTimeMinutesNotIn?: InputMaybe<Array<Scalars["Int"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<CourseSectionPeriodHistoryFilterInput>;
	or?: InputMaybe<Array<CourseSectionPeriodHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	roomIDEQ?: InputMaybe<Scalars["ID"]>;
	roomIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	roomIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	roomIDNEQ?: InputMaybe<Scalars["ID"]>;
	roomIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	startTimeMinutesEQ?: InputMaybe<Scalars["Int"]>;
	startTimeMinutesGT?: InputMaybe<Scalars["Int"]>;
	startTimeMinutesGTE?: InputMaybe<Scalars["Int"]>;
	startTimeMinutesIn?: InputMaybe<Array<Scalars["Int"]>>;
	startTimeMinutesIsNull?: InputMaybe<Scalars["Boolean"]>;
	startTimeMinutesLT?: InputMaybe<Scalars["Int"]>;
	startTimeMinutesLTE?: InputMaybe<Scalars["Int"]>;
	startTimeMinutesNEQ?: InputMaybe<Scalars["Int"]>;
	startTimeMinutesNotIn?: InputMaybe<Array<Scalars["Int"]>>;
};

export type CourseSectionPeriodHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<CourseSectionPeriodHistoryOrderField>;
};

export enum CourseSectionPeriodHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type CourseSectionPeriodInCourseSectionCreateInput = {
	courseAttendanceRecordIDs?: InputMaybe<Array<Scalars["ID"]>>;
	courseAttendanceRecords?: InputMaybe<Array<CourseAttendanceRecordInCourseSectionPeriodCreateInput>>;
	dayOfWeek: Scalars["Int"];
	endTimeMinutes: Scalars["Int"];
	roomID: Scalars["ID"];
	startTimeMinutes: Scalars["Int"];
};

export type CourseSectionPeriodInRoomCreateInput = {
	courseAttendanceRecordIDs?: InputMaybe<Array<Scalars["ID"]>>;
	courseAttendanceRecords?: InputMaybe<Array<CourseAttendanceRecordInCourseSectionPeriodCreateInput>>;
	courseSectionID: Scalars["ID"];
	dayOfWeek: Scalars["Int"];
	endTimeMinutes: Scalars["Int"];
	startTimeMinutes: Scalars["Int"];
};

export type CourseSectionPeriodOrder = {
	direction: OrderDirection;
	field?: InputMaybe<CourseSectionPeriodOrderField>;
};

export enum CourseSectionPeriodOrderField {
	DayOfWeek = "DAY_OF_WEEK",
	EndTimeMinutes = "END_TIME_MINUTES",
	StartTimeMinutes = "START_TIME_MINUTES",
}

export type CourseSectionPeriodUpdateBulkInput = {
	addCourseAttendanceRecordIDs?: InputMaybe<Array<Scalars["ID"]>>;
	courseSectionID?: InputMaybe<Scalars["ID"]>;
	dayOfWeek?: InputMaybe<Scalars["Int"]>;
	endTimeMinutes?: InputMaybe<Scalars["Int"]>;
	removeCourseAttendanceRecordIDs?: InputMaybe<Array<Scalars["ID"]>>;
	roomID?: InputMaybe<Scalars["ID"]>;
	startTimeMinutes?: InputMaybe<Scalars["Int"]>;
};

export type CourseSectionPeriodUpdateInput = {
	addCourseAttendanceRecordIDs?: InputMaybe<Array<Scalars["ID"]>>;
	courseSectionID?: InputMaybe<Scalars["ID"]>;
	dayOfWeek?: InputMaybe<Scalars["Int"]>;
	endTimeMinutes?: InputMaybe<Scalars["Int"]>;
	removeCourseAttendanceRecordIDs?: InputMaybe<Array<Scalars["ID"]>>;
	roomID?: InputMaybe<Scalars["ID"]>;
	startTimeMinutes?: InputMaybe<Scalars["Int"]>;
};

export type CourseSectionStudentEnrollment = Node & {
	__typename?: "CourseSectionStudentEnrollment";
	courseSection: CourseSection;
	courseSectionID: Scalars["ID"];
	earlyEndDate: Maybe<Scalars["Time"]>;
	id: Scalars["ID"];
	lateStartDate: Maybe<Scalars["Time"]>;
	student: Student;
	studentID: Scalars["ID"];
};

export type CourseSectionStudentEnrollmentConnection = {
	__typename?: "CourseSectionStudentEnrollmentConnection";
	edges: Array<CourseSectionStudentEnrollmentEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type CourseSectionStudentEnrollmentCreateInput = {
	courseSectionID: Scalars["ID"];
	earlyEndDate?: InputMaybe<Scalars["Time"]>;
	lateStartDate?: InputMaybe<Scalars["Time"]>;
	studentID: Scalars["ID"];
};

export type CourseSectionStudentEnrollmentEdge = {
	__typename?: "CourseSectionStudentEnrollmentEdge";
	cursor: Scalars["Cursor"];
	node: CourseSectionStudentEnrollment;
};

export type CourseSectionStudentEnrollmentFilterInput = {
	and?: InputMaybe<Array<CourseSectionStudentEnrollmentFilterInput>>;
	courseSection?: InputMaybe<Array<CourseSectionFilterInput>>;
	courseSectionIDEQ?: InputMaybe<Scalars["ID"]>;
	courseSectionIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	courseSectionIDNEQ?: InputMaybe<Scalars["ID"]>;
	courseSectionIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	earlyEndDateEQ?: InputMaybe<Scalars["Time"]>;
	earlyEndDateGT?: InputMaybe<Scalars["Time"]>;
	earlyEndDateGTE?: InputMaybe<Scalars["Time"]>;
	earlyEndDateIn?: InputMaybe<Array<Scalars["Time"]>>;
	earlyEndDateIsNull?: InputMaybe<Scalars["Boolean"]>;
	earlyEndDateLT?: InputMaybe<Scalars["Time"]>;
	earlyEndDateLTE?: InputMaybe<Scalars["Time"]>;
	earlyEndDateNEQ?: InputMaybe<Scalars["Time"]>;
	earlyEndDateNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	lateStartDateEQ?: InputMaybe<Scalars["Time"]>;
	lateStartDateGT?: InputMaybe<Scalars["Time"]>;
	lateStartDateGTE?: InputMaybe<Scalars["Time"]>;
	lateStartDateIn?: InputMaybe<Array<Scalars["Time"]>>;
	lateStartDateIsNull?: InputMaybe<Scalars["Boolean"]>;
	lateStartDateLT?: InputMaybe<Scalars["Time"]>;
	lateStartDateLTE?: InputMaybe<Scalars["Time"]>;
	lateStartDateNEQ?: InputMaybe<Scalars["Time"]>;
	lateStartDateNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	not?: InputMaybe<CourseSectionStudentEnrollmentFilterInput>;
	or?: InputMaybe<Array<CourseSectionStudentEnrollmentFilterInput>>;
	student?: InputMaybe<Array<StudentFilterInput>>;
	studentIDEQ?: InputMaybe<Scalars["ID"]>;
	studentIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	studentIDNEQ?: InputMaybe<Scalars["ID"]>;
	studentIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type CourseSectionStudentEnrollmentHistory = Node & {
	__typename?: "CourseSectionStudentEnrollmentHistory";
	courseSectionID: Maybe<Scalars["ID"]>;
	earlyEndDate: Maybe<Scalars["Time"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	lateStartDate: Maybe<Scalars["Time"]>;
	originalID: Maybe<Scalars["ID"]>;
	studentID: Maybe<Scalars["ID"]>;
};

export type CourseSectionStudentEnrollmentHistoryConnection = {
	__typename?: "CourseSectionStudentEnrollmentHistoryConnection";
	edges: Array<CourseSectionStudentEnrollmentHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type CourseSectionStudentEnrollmentHistoryEdge = {
	__typename?: "CourseSectionStudentEnrollmentHistoryEdge";
	cursor: Scalars["Cursor"];
	node: CourseSectionStudentEnrollmentHistory;
};

export type CourseSectionStudentEnrollmentHistoryFilterInput = {
	and?: InputMaybe<Array<CourseSectionStudentEnrollmentHistoryFilterInput>>;
	courseSectionIDEQ?: InputMaybe<Scalars["ID"]>;
	courseSectionIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	courseSectionIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	courseSectionIDNEQ?: InputMaybe<Scalars["ID"]>;
	courseSectionIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	earlyEndDateEQ?: InputMaybe<Scalars["Time"]>;
	earlyEndDateGT?: InputMaybe<Scalars["Time"]>;
	earlyEndDateGTE?: InputMaybe<Scalars["Time"]>;
	earlyEndDateIn?: InputMaybe<Array<Scalars["Time"]>>;
	earlyEndDateIsNull?: InputMaybe<Scalars["Boolean"]>;
	earlyEndDateLT?: InputMaybe<Scalars["Time"]>;
	earlyEndDateLTE?: InputMaybe<Scalars["Time"]>;
	earlyEndDateNEQ?: InputMaybe<Scalars["Time"]>;
	earlyEndDateNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	lateStartDateEQ?: InputMaybe<Scalars["Time"]>;
	lateStartDateGT?: InputMaybe<Scalars["Time"]>;
	lateStartDateGTE?: InputMaybe<Scalars["Time"]>;
	lateStartDateIn?: InputMaybe<Array<Scalars["Time"]>>;
	lateStartDateIsNull?: InputMaybe<Scalars["Boolean"]>;
	lateStartDateLT?: InputMaybe<Scalars["Time"]>;
	lateStartDateLTE?: InputMaybe<Scalars["Time"]>;
	lateStartDateNEQ?: InputMaybe<Scalars["Time"]>;
	lateStartDateNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	not?: InputMaybe<CourseSectionStudentEnrollmentHistoryFilterInput>;
	or?: InputMaybe<Array<CourseSectionStudentEnrollmentHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	studentIDEQ?: InputMaybe<Scalars["ID"]>;
	studentIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	studentIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	studentIDNEQ?: InputMaybe<Scalars["ID"]>;
	studentIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type CourseSectionStudentEnrollmentHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<CourseSectionStudentEnrollmentHistoryOrderField>;
};

export enum CourseSectionStudentEnrollmentHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type CourseSectionStudentEnrollmentInCourseSectionCreateInput = {
	earlyEndDate?: InputMaybe<Scalars["Time"]>;
	lateStartDate?: InputMaybe<Scalars["Time"]>;
	studentID: Scalars["ID"];
};

export type CourseSectionStudentEnrollmentInStudentCreateInput = {
	courseSectionID: Scalars["ID"];
	earlyEndDate?: InputMaybe<Scalars["Time"]>;
	lateStartDate?: InputMaybe<Scalars["Time"]>;
};

export type CourseSectionStudentEnrollmentOrder = {
	direction: OrderDirection;
	field?: InputMaybe<CourseSectionStudentEnrollmentOrderField>;
};

export enum CourseSectionStudentEnrollmentOrderField {
	EarlyEndDate = "EARLY_END_DATE",
	LateStartDate = "LATE_START_DATE",
}

export type CourseSectionStudentEnrollmentUpdateBulkInput = {
	clearEarlyEndDate?: InputMaybe<Scalars["Boolean"]>;
	clearLateStartDate?: InputMaybe<Scalars["Boolean"]>;
	courseSectionID?: InputMaybe<Scalars["ID"]>;
	earlyEndDate?: InputMaybe<Scalars["Time"]>;
	lateStartDate?: InputMaybe<Scalars["Time"]>;
	studentID?: InputMaybe<Scalars["ID"]>;
};

export type CourseSectionStudentEnrollmentUpdateInput = {
	clearEarlyEndDate?: InputMaybe<Scalars["Boolean"]>;
	clearLateStartDate?: InputMaybe<Scalars["Boolean"]>;
	courseSectionID?: InputMaybe<Scalars["ID"]>;
	earlyEndDate?: InputMaybe<Scalars["Time"]>;
	lateStartDate?: InputMaybe<Scalars["Time"]>;
	studentID?: InputMaybe<Scalars["ID"]>;
};

export type CourseSectionTeacherEnrollment = Node & {
	__typename?: "CourseSectionTeacherEnrollment";
	courseSection: CourseSection;
	courseSectionID: Scalars["ID"];
	earlyEndDate: Maybe<Scalars["Time"]>;
	id: Scalars["ID"];
	lateStartDate: Maybe<Scalars["Time"]>;
	teacher: Employee;
	teacherID: Scalars["ID"];
};

export type CourseSectionTeacherEnrollmentConnection = {
	__typename?: "CourseSectionTeacherEnrollmentConnection";
	edges: Array<CourseSectionTeacherEnrollmentEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type CourseSectionTeacherEnrollmentCreateInput = {
	courseSectionID: Scalars["ID"];
	earlyEndDate?: InputMaybe<Scalars["Time"]>;
	lateStartDate?: InputMaybe<Scalars["Time"]>;
	teacherID: Scalars["ID"];
};

export type CourseSectionTeacherEnrollmentEdge = {
	__typename?: "CourseSectionTeacherEnrollmentEdge";
	cursor: Scalars["Cursor"];
	node: CourseSectionTeacherEnrollment;
};

export type CourseSectionTeacherEnrollmentFilterInput = {
	and?: InputMaybe<Array<CourseSectionTeacherEnrollmentFilterInput>>;
	courseSection?: InputMaybe<Array<CourseSectionFilterInput>>;
	courseSectionIDEQ?: InputMaybe<Scalars["ID"]>;
	courseSectionIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	courseSectionIDNEQ?: InputMaybe<Scalars["ID"]>;
	courseSectionIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	earlyEndDateEQ?: InputMaybe<Scalars["Time"]>;
	earlyEndDateGT?: InputMaybe<Scalars["Time"]>;
	earlyEndDateGTE?: InputMaybe<Scalars["Time"]>;
	earlyEndDateIn?: InputMaybe<Array<Scalars["Time"]>>;
	earlyEndDateIsNull?: InputMaybe<Scalars["Boolean"]>;
	earlyEndDateLT?: InputMaybe<Scalars["Time"]>;
	earlyEndDateLTE?: InputMaybe<Scalars["Time"]>;
	earlyEndDateNEQ?: InputMaybe<Scalars["Time"]>;
	earlyEndDateNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	lateStartDateEQ?: InputMaybe<Scalars["Time"]>;
	lateStartDateGT?: InputMaybe<Scalars["Time"]>;
	lateStartDateGTE?: InputMaybe<Scalars["Time"]>;
	lateStartDateIn?: InputMaybe<Array<Scalars["Time"]>>;
	lateStartDateIsNull?: InputMaybe<Scalars["Boolean"]>;
	lateStartDateLT?: InputMaybe<Scalars["Time"]>;
	lateStartDateLTE?: InputMaybe<Scalars["Time"]>;
	lateStartDateNEQ?: InputMaybe<Scalars["Time"]>;
	lateStartDateNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	not?: InputMaybe<CourseSectionTeacherEnrollmentFilterInput>;
	or?: InputMaybe<Array<CourseSectionTeacherEnrollmentFilterInput>>;
	teacher?: InputMaybe<Array<EmployeeFilterInput>>;
	teacherIDEQ?: InputMaybe<Scalars["ID"]>;
	teacherIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	teacherIDNEQ?: InputMaybe<Scalars["ID"]>;
	teacherIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type CourseSectionTeacherEnrollmentHistory = Node & {
	__typename?: "CourseSectionTeacherEnrollmentHistory";
	courseSectionID: Maybe<Scalars["ID"]>;
	earlyEndDate: Maybe<Scalars["Time"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	lateStartDate: Maybe<Scalars["Time"]>;
	originalID: Maybe<Scalars["ID"]>;
	teacherID: Maybe<Scalars["ID"]>;
};

export type CourseSectionTeacherEnrollmentHistoryConnection = {
	__typename?: "CourseSectionTeacherEnrollmentHistoryConnection";
	edges: Array<CourseSectionTeacherEnrollmentHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type CourseSectionTeacherEnrollmentHistoryEdge = {
	__typename?: "CourseSectionTeacherEnrollmentHistoryEdge";
	cursor: Scalars["Cursor"];
	node: CourseSectionTeacherEnrollmentHistory;
};

export type CourseSectionTeacherEnrollmentHistoryFilterInput = {
	and?: InputMaybe<Array<CourseSectionTeacherEnrollmentHistoryFilterInput>>;
	courseSectionIDEQ?: InputMaybe<Scalars["ID"]>;
	courseSectionIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	courseSectionIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	courseSectionIDNEQ?: InputMaybe<Scalars["ID"]>;
	courseSectionIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	earlyEndDateEQ?: InputMaybe<Scalars["Time"]>;
	earlyEndDateGT?: InputMaybe<Scalars["Time"]>;
	earlyEndDateGTE?: InputMaybe<Scalars["Time"]>;
	earlyEndDateIn?: InputMaybe<Array<Scalars["Time"]>>;
	earlyEndDateIsNull?: InputMaybe<Scalars["Boolean"]>;
	earlyEndDateLT?: InputMaybe<Scalars["Time"]>;
	earlyEndDateLTE?: InputMaybe<Scalars["Time"]>;
	earlyEndDateNEQ?: InputMaybe<Scalars["Time"]>;
	earlyEndDateNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	lateStartDateEQ?: InputMaybe<Scalars["Time"]>;
	lateStartDateGT?: InputMaybe<Scalars["Time"]>;
	lateStartDateGTE?: InputMaybe<Scalars["Time"]>;
	lateStartDateIn?: InputMaybe<Array<Scalars["Time"]>>;
	lateStartDateIsNull?: InputMaybe<Scalars["Boolean"]>;
	lateStartDateLT?: InputMaybe<Scalars["Time"]>;
	lateStartDateLTE?: InputMaybe<Scalars["Time"]>;
	lateStartDateNEQ?: InputMaybe<Scalars["Time"]>;
	lateStartDateNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	not?: InputMaybe<CourseSectionTeacherEnrollmentHistoryFilterInput>;
	or?: InputMaybe<Array<CourseSectionTeacherEnrollmentHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	teacherIDEQ?: InputMaybe<Scalars["ID"]>;
	teacherIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	teacherIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	teacherIDNEQ?: InputMaybe<Scalars["ID"]>;
	teacherIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type CourseSectionTeacherEnrollmentHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<CourseSectionTeacherEnrollmentHistoryOrderField>;
};

export enum CourseSectionTeacherEnrollmentHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type CourseSectionTeacherEnrollmentInCourseSectionCreateInput = {
	earlyEndDate?: InputMaybe<Scalars["Time"]>;
	lateStartDate?: InputMaybe<Scalars["Time"]>;
	teacherID: Scalars["ID"];
};

export type CourseSectionTeacherEnrollmentInTeacherCreateInput = {
	courseSectionID: Scalars["ID"];
	earlyEndDate?: InputMaybe<Scalars["Time"]>;
	lateStartDate?: InputMaybe<Scalars["Time"]>;
};

export type CourseSectionTeacherEnrollmentOrder = {
	direction: OrderDirection;
	field?: InputMaybe<CourseSectionTeacherEnrollmentOrderField>;
};

export enum CourseSectionTeacherEnrollmentOrderField {
	EarlyEndDate = "EARLY_END_DATE",
	LateStartDate = "LATE_START_DATE",
}

export type CourseSectionTeacherEnrollmentUpdateBulkInput = {
	clearEarlyEndDate?: InputMaybe<Scalars["Boolean"]>;
	clearLateStartDate?: InputMaybe<Scalars["Boolean"]>;
	courseSectionID?: InputMaybe<Scalars["ID"]>;
	earlyEndDate?: InputMaybe<Scalars["Time"]>;
	lateStartDate?: InputMaybe<Scalars["Time"]>;
	teacherID?: InputMaybe<Scalars["ID"]>;
};

export type CourseSectionTeacherEnrollmentUpdateInput = {
	clearEarlyEndDate?: InputMaybe<Scalars["Boolean"]>;
	clearLateStartDate?: InputMaybe<Scalars["Boolean"]>;
	courseSectionID?: InputMaybe<Scalars["ID"]>;
	earlyEndDate?: InputMaybe<Scalars["Time"]>;
	lateStartDate?: InputMaybe<Scalars["Time"]>;
	teacherID?: InputMaybe<Scalars["ID"]>;
};

export type CourseSectionUpdateBulkInput = {
	addCourseSectionPeriodIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addCourseSectionStudentEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addCourseSectionTeacherEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addDocumentResourceFolderIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addLessonPlanIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addNoteIDs?: InputMaybe<Array<Scalars["ID"]>>;
	courseID?: InputMaybe<Scalars["ID"]>;
	description?: InputMaybe<Scalars["String"]>;
	homeRoomSectionID?: InputMaybe<Scalars["ID"]>;
	name?: InputMaybe<Scalars["String"]>;
	removeCourseSectionPeriodIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeCourseSectionStudentEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeCourseSectionTeacherEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeDocumentResourceFolderIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeLessonPlanIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeNoteIDs?: InputMaybe<Array<Scalars["ID"]>>;
	termID?: InputMaybe<Scalars["ID"]>;
};

export type CourseSectionUpdateInput = {
	addCourseSectionPeriodIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addCourseSectionStudentEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addCourseSectionTeacherEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addDocumentResourceFolderIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addLessonPlanIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addNoteIDs?: InputMaybe<Array<Scalars["ID"]>>;
	courseID?: InputMaybe<Scalars["ID"]>;
	description?: InputMaybe<Scalars["String"]>;
	homeRoomSectionID?: InputMaybe<Scalars["ID"]>;
	name?: InputMaybe<Scalars["String"]>;
	removeCourseSectionPeriodIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeCourseSectionStudentEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeCourseSectionTeacherEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeDocumentResourceFolderIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeLessonPlanIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeNoteIDs?: InputMaybe<Array<Scalars["ID"]>>;
	termID?: InputMaybe<Scalars["ID"]>;
};

export enum CourseSubject {
	Art = "art",
	Core = "core",
	Ell = "ell",
	ForeignLanguage = "foreign_language",
	Ipc = "ipc",
	LanguageArts = "language_arts",
	Math = "math",
	Music = "music",
	Pe = "pe",
	Reading = "reading",
	Science = "science",
	SelTime = "sel_time",
	Technology = "technology",
}

export type CourseUpdateBulkInput = {
	addClassYearIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addCourseSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addDocumentResourceFolderIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addLearningObjectiveIDs?: InputMaybe<Array<Scalars["ID"]>>;
	clearStudentCapacity?: InputMaybe<Scalars["Boolean"]>;
	description?: InputMaybe<Scalars["String"]>;
	homeRoomID?: InputMaybe<Scalars["ID"]>;
	name?: InputMaybe<Scalars["String"]>;
	removeClassYearIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeCourseSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeDocumentResourceFolderIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeLearningObjectiveIDs?: InputMaybe<Array<Scalars["ID"]>>;
	studentCapacity?: InputMaybe<Scalars["Int"]>;
	subject?: InputMaybe<CourseSubject>;
};

export type CourseUpdateInput = {
	addClassYearIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addCourseSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addDocumentResourceFolderIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addLearningObjectiveIDs?: InputMaybe<Array<Scalars["ID"]>>;
	clearStudentCapacity?: InputMaybe<Scalars["Boolean"]>;
	description?: InputMaybe<Scalars["String"]>;
	homeRoomID?: InputMaybe<Scalars["ID"]>;
	name?: InputMaybe<Scalars["String"]>;
	removeClassYearIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeCourseSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeDocumentResourceFolderIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeLearningObjectiveIDs?: InputMaybe<Array<Scalars["ID"]>>;
	studentCapacity?: InputMaybe<Scalars["Int"]>;
	subject?: InputMaybe<CourseSubject>;
};

export type DeleteBulkResponse = {
	__typename?: "DeleteBulkResponse";
	ids: Array<Scalars["Int"]>;
	totalCount: Scalars["Int"];
};

export type Discount = Node & {
	__typename?: "Discount";
	amount: Scalars["Int"];
	description: Scalars["String"];
	id: Scalars["ID"];
	serviceSubscription: ServiceSubscription;
	serviceSubscriptionID: Scalars["ID"];
};

export type DiscountConnection = {
	__typename?: "DiscountConnection";
	edges: Array<DiscountEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type DiscountCreateInput = {
	amount: Scalars["Int"];
	description: Scalars["String"];
	serviceSubscriptionID: Scalars["ID"];
};

export type DiscountEdge = {
	__typename?: "DiscountEdge";
	cursor: Scalars["Cursor"];
	node: Discount;
};

export type DiscountFilterInput = {
	amountEQ?: InputMaybe<Scalars["Int"]>;
	amountGT?: InputMaybe<Scalars["Int"]>;
	amountGTE?: InputMaybe<Scalars["Int"]>;
	amountIn?: InputMaybe<Array<Scalars["Int"]>>;
	amountLT?: InputMaybe<Scalars["Int"]>;
	amountLTE?: InputMaybe<Scalars["Int"]>;
	amountNEQ?: InputMaybe<Scalars["Int"]>;
	amountNotIn?: InputMaybe<Array<Scalars["Int"]>>;
	and?: InputMaybe<Array<DiscountFilterInput>>;
	descriptionContains?: InputMaybe<Scalars["String"]>;
	descriptionEQ?: InputMaybe<Scalars["String"]>;
	descriptionHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionIn?: InputMaybe<Array<Scalars["String"]>>;
	descriptionNEQ?: InputMaybe<Scalars["String"]>;
	descriptionNotContains?: InputMaybe<Scalars["String"]>;
	descriptionNotHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionNotHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionNotIn?: InputMaybe<Array<Scalars["String"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<DiscountFilterInput>;
	or?: InputMaybe<Array<DiscountFilterInput>>;
	search?: InputMaybe<Scalars["String"]>;
	serviceSubscription?: InputMaybe<Array<ServiceSubscriptionFilterInput>>;
	serviceSubscriptionIDEQ?: InputMaybe<Scalars["ID"]>;
	serviceSubscriptionIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	serviceSubscriptionIDNEQ?: InputMaybe<Scalars["ID"]>;
	serviceSubscriptionIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type DiscountHistory = Node & {
	__typename?: "DiscountHistory";
	amount: Maybe<Scalars["Int"]>;
	description: Maybe<Scalars["String"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	originalID: Maybe<Scalars["ID"]>;
	serviceSubscriptionID: Maybe<Scalars["ID"]>;
};

export type DiscountHistoryConnection = {
	__typename?: "DiscountHistoryConnection";
	edges: Array<DiscountHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type DiscountHistoryEdge = {
	__typename?: "DiscountHistoryEdge";
	cursor: Scalars["Cursor"];
	node: DiscountHistory;
};

export type DiscountHistoryFilterInput = {
	amountEQ?: InputMaybe<Scalars["Int"]>;
	amountGT?: InputMaybe<Scalars["Int"]>;
	amountGTE?: InputMaybe<Scalars["Int"]>;
	amountIn?: InputMaybe<Array<Scalars["Int"]>>;
	amountIsNull?: InputMaybe<Scalars["Boolean"]>;
	amountLT?: InputMaybe<Scalars["Int"]>;
	amountLTE?: InputMaybe<Scalars["Int"]>;
	amountNEQ?: InputMaybe<Scalars["Int"]>;
	amountNotIn?: InputMaybe<Array<Scalars["Int"]>>;
	and?: InputMaybe<Array<DiscountHistoryFilterInput>>;
	descriptionContains?: InputMaybe<Scalars["String"]>;
	descriptionEQ?: InputMaybe<Scalars["String"]>;
	descriptionHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionIn?: InputMaybe<Array<Scalars["String"]>>;
	descriptionIsNull?: InputMaybe<Scalars["Boolean"]>;
	descriptionNEQ?: InputMaybe<Scalars["String"]>;
	descriptionNotContains?: InputMaybe<Scalars["String"]>;
	descriptionNotHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionNotHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionNotIn?: InputMaybe<Array<Scalars["String"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<DiscountHistoryFilterInput>;
	or?: InputMaybe<Array<DiscountHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	search?: InputMaybe<Scalars["String"]>;
	serviceSubscriptionIDEQ?: InputMaybe<Scalars["ID"]>;
	serviceSubscriptionIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	serviceSubscriptionIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	serviceSubscriptionIDNEQ?: InputMaybe<Scalars["ID"]>;
	serviceSubscriptionIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type DiscountHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<DiscountHistoryOrderField>;
};

export enum DiscountHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type DiscountInServiceSubscriptionCreateInput = {
	amount: Scalars["Int"];
	description: Scalars["String"];
};

export type DiscountOrder = {
	direction: OrderDirection;
	field?: InputMaybe<DiscountOrderField>;
};

export enum DiscountOrderField {
	Amount = "AMOUNT",
}

export type DiscountUpdateBulkInput = {
	amount?: InputMaybe<Scalars["Int"]>;
	description?: InputMaybe<Scalars["String"]>;
	serviceSubscriptionID?: InputMaybe<Scalars["ID"]>;
};

export type DiscountUpdateInput = {
	amount?: InputMaybe<Scalars["Int"]>;
	description?: InputMaybe<Scalars["String"]>;
	serviceSubscriptionID?: InputMaybe<Scalars["ID"]>;
};

export type DocumentResource = Node & {
	__typename?: "DocumentResource";
	author: Maybe<User>;
	authorID: Maybe<Scalars["ID"]>;
	bookmarkingUsers: Array<User>;
	createdAt: Scalars["Time"];
	description: Scalars["String"];
	documentResourceFolders: Array<DocumentResourceFolder>;
	documentResourceUserViewRecords: Array<DocumentResourceUserViewRecord>;
	fileContentType: Scalars["String"];
	fileURL: Scalars["String"];
	hasImage: Scalars["Boolean"];
	id: Scalars["ID"];
	imageContentType: Scalars["String"];
	imageURL: Scalars["String"];
	isFile: Scalars["Boolean"];
	name: Scalars["String"];
	resourceURL: Scalars["String"];
};

export type DocumentResourceBookmarkingUsersArgs = {
	filters?: InputMaybe<Array<UserFilterInput>>;
	order?: InputMaybe<UserOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type DocumentResourceDocumentResourceFoldersArgs = {
	filters?: InputMaybe<Array<DocumentResourceFolderFilterInput>>;
	order?: InputMaybe<DocumentResourceFolderOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type DocumentResourceDocumentResourceUserViewRecordsArgs = {
	filters?: InputMaybe<Array<DocumentResourceUserViewRecordFilterInput>>;
	order?: InputMaybe<DocumentResourceUserViewRecordOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type DocumentResourceBookmarkedDocumentResourceUserBookmarkingUserHistory = Node & {
	__typename?: "DocumentResourceBookmarkedDocumentResourceUserBookmarkingUserHistory";
	documentResourceID: Maybe<Scalars["ID"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	userID: Maybe<Scalars["ID"]>;
};

export type DocumentResourceBookmarkedDocumentResourceUserBookmarkingUserHistoryConnection = {
	__typename?: "DocumentResourceBookmarkedDocumentResourceUserBookmarkingUserHistoryConnection";
	edges: Array<DocumentResourceBookmarkedDocumentResourceUserBookmarkingUserHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type DocumentResourceBookmarkedDocumentResourceUserBookmarkingUserHistoryEdge = {
	__typename?: "DocumentResourceBookmarkedDocumentResourceUserBookmarkingUserHistoryEdge";
	cursor: Scalars["Cursor"];
	node: DocumentResourceBookmarkedDocumentResourceUserBookmarkingUserHistory;
};

export type DocumentResourceBookmarkedDocumentResourceUserBookmarkingUserHistoryFilterInput = {
	and?: InputMaybe<Array<DocumentResourceBookmarkedDocumentResourceUserBookmarkingUserHistoryFilterInput>>;
	documentResourceIDEQ?: InputMaybe<Scalars["ID"]>;
	documentResourceIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	documentResourceIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	documentResourceIDNEQ?: InputMaybe<Scalars["ID"]>;
	documentResourceIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<DocumentResourceBookmarkedDocumentResourceUserBookmarkingUserHistoryFilterInput>;
	or?: InputMaybe<Array<DocumentResourceBookmarkedDocumentResourceUserBookmarkingUserHistoryFilterInput>>;
	userIDEQ?: InputMaybe<Scalars["ID"]>;
	userIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	userIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	userIDNEQ?: InputMaybe<Scalars["ID"]>;
	userIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type DocumentResourceBookmarkedDocumentResourceUserBookmarkingUserHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<DocumentResourceBookmarkedDocumentResourceUserBookmarkingUserHistoryOrderField>;
};

export enum DocumentResourceBookmarkedDocumentResourceUserBookmarkingUserHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type DocumentResourceConnection = {
	__typename?: "DocumentResourceConnection";
	edges: Array<DocumentResourceEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type DocumentResourceCreateInput = {
	bookmarkingUserIDs?: InputMaybe<Array<Scalars["ID"]>>;
	description?: InputMaybe<Scalars["String"]>;
	documentResourceFolderIDs?: InputMaybe<Array<Scalars["ID"]>>;
	documentResourceUserViewRecords?: InputMaybe<Array<DocumentResourceUserViewRecordInDocumentResourceCreateInput>>;
	file?: InputMaybe<Scalars["Upload"]>;
	image?: InputMaybe<Scalars["Upload"]>;
	name: Scalars["String"];
	resourceURL?: InputMaybe<Scalars["String"]>;
};

export type DocumentResourceEdge = {
	__typename?: "DocumentResourceEdge";
	cursor: Scalars["Cursor"];
	node: DocumentResource;
};

export type DocumentResourceFilterInput = {
	and?: InputMaybe<Array<DocumentResourceFilterInput>>;
	author?: InputMaybe<Array<UserFilterInput>>;
	authorIDEQ?: InputMaybe<Scalars["ID"]>;
	authorIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	authorIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	authorIDNEQ?: InputMaybe<Scalars["ID"]>;
	authorIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	bookmarkingUsers?: InputMaybe<Array<UserFilterInput>>;
	createdAtEQ?: InputMaybe<Scalars["Time"]>;
	createdAtGT?: InputMaybe<Scalars["Time"]>;
	createdAtGTE?: InputMaybe<Scalars["Time"]>;
	createdAtIn?: InputMaybe<Array<Scalars["Time"]>>;
	createdAtLT?: InputMaybe<Scalars["Time"]>;
	createdAtLTE?: InputMaybe<Scalars["Time"]>;
	createdAtNEQ?: InputMaybe<Scalars["Time"]>;
	createdAtNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	descriptionContains?: InputMaybe<Scalars["String"]>;
	descriptionEQ?: InputMaybe<Scalars["String"]>;
	descriptionHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionIn?: InputMaybe<Array<Scalars["String"]>>;
	descriptionNEQ?: InputMaybe<Scalars["String"]>;
	descriptionNotContains?: InputMaybe<Scalars["String"]>;
	descriptionNotHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionNotHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionNotIn?: InputMaybe<Array<Scalars["String"]>>;
	documentResourceFolders?: InputMaybe<Array<DocumentResourceFolderFilterInput>>;
	documentResourceUserViewRecords?: InputMaybe<Array<DocumentResourceUserViewRecordFilterInput>>;
	fileContentTypeContains?: InputMaybe<Scalars["String"]>;
	fileContentTypeEQ?: InputMaybe<Scalars["String"]>;
	fileContentTypeHasPrefix?: InputMaybe<Scalars["String"]>;
	fileContentTypeHasSuffix?: InputMaybe<Scalars["String"]>;
	fileContentTypeIn?: InputMaybe<Array<Scalars["String"]>>;
	fileContentTypeNEQ?: InputMaybe<Scalars["String"]>;
	fileContentTypeNotContains?: InputMaybe<Scalars["String"]>;
	fileContentTypeNotHasPrefix?: InputMaybe<Scalars["String"]>;
	fileContentTypeNotHasSuffix?: InputMaybe<Scalars["String"]>;
	fileContentTypeNotIn?: InputMaybe<Array<Scalars["String"]>>;
	fileIsNull?: InputMaybe<Scalars["Boolean"]>;
	hasAuthor?: InputMaybe<Scalars["Boolean"]>;
	hasBookmarkingUsers?: InputMaybe<Scalars["Boolean"]>;
	hasDocumentResourceFolders?: InputMaybe<Scalars["Boolean"]>;
	hasDocumentResourceUserViewRecords?: InputMaybe<Scalars["Boolean"]>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	imageContentTypeContains?: InputMaybe<Scalars["String"]>;
	imageContentTypeEQ?: InputMaybe<Scalars["String"]>;
	imageContentTypeHasPrefix?: InputMaybe<Scalars["String"]>;
	imageContentTypeHasSuffix?: InputMaybe<Scalars["String"]>;
	imageContentTypeIn?: InputMaybe<Array<Scalars["String"]>>;
	imageContentTypeNEQ?: InputMaybe<Scalars["String"]>;
	imageContentTypeNotContains?: InputMaybe<Scalars["String"]>;
	imageContentTypeNotHasPrefix?: InputMaybe<Scalars["String"]>;
	imageContentTypeNotHasSuffix?: InputMaybe<Scalars["String"]>;
	imageContentTypeNotIn?: InputMaybe<Array<Scalars["String"]>>;
	imageIsNull?: InputMaybe<Scalars["Boolean"]>;
	nameContains?: InputMaybe<Scalars["String"]>;
	nameEQ?: InputMaybe<Scalars["String"]>;
	nameHasPrefix?: InputMaybe<Scalars["String"]>;
	nameHasSuffix?: InputMaybe<Scalars["String"]>;
	nameIn?: InputMaybe<Array<Scalars["String"]>>;
	nameNEQ?: InputMaybe<Scalars["String"]>;
	nameNotContains?: InputMaybe<Scalars["String"]>;
	nameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	nameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	nameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	not?: InputMaybe<DocumentResourceFilterInput>;
	or?: InputMaybe<Array<DocumentResourceFilterInput>>;
	resourceURLContains?: InputMaybe<Scalars["String"]>;
	resourceURLEQ?: InputMaybe<Scalars["String"]>;
	resourceURLHasPrefix?: InputMaybe<Scalars["String"]>;
	resourceURLHasSuffix?: InputMaybe<Scalars["String"]>;
	resourceURLIn?: InputMaybe<Array<Scalars["String"]>>;
	resourceURLNEQ?: InputMaybe<Scalars["String"]>;
	resourceURLNotContains?: InputMaybe<Scalars["String"]>;
	resourceURLNotHasPrefix?: InputMaybe<Scalars["String"]>;
	resourceURLNotHasSuffix?: InputMaybe<Scalars["String"]>;
	resourceURLNotIn?: InputMaybe<Array<Scalars["String"]>>;
	search?: InputMaybe<Scalars["String"]>;
};

export type DocumentResourceFolder = Node & {
	__typename?: "DocumentResourceFolder";
	author: Maybe<User>;
	authorID: Maybe<Scalars["ID"]>;
	category: DocumentResourceFolderCategory;
	childDocumentResourceFolders: Array<DocumentResourceFolder>;
	courseSections: Array<CourseSection>;
	courses: Array<Course>;
	createdAt: Scalars["Time"];
	description: Scalars["String"];
	documentResources: Array<DocumentResource>;
	homeRoomSections: Array<HomeRoomSection>;
	homeRooms: Array<HomeRoom>;
	id: Scalars["ID"];
	isPublic: Scalars["Boolean"];
	name: Scalars["String"];
	parentDocumentResourceFolder: Maybe<DocumentResourceFolder>;
	parentDocumentResourceFolderID: Maybe<Scalars["ID"]>;
	sharingUserGroups: Array<UserGroup>;
	sharingUsers: Array<User>;
};

export type DocumentResourceFolderChildDocumentResourceFoldersArgs = {
	filters?: InputMaybe<Array<DocumentResourceFolderFilterInput>>;
	order?: InputMaybe<DocumentResourceFolderOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type DocumentResourceFolderCourseSectionsArgs = {
	filters?: InputMaybe<Array<CourseSectionFilterInput>>;
	order?: InputMaybe<CourseSectionOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type DocumentResourceFolderCoursesArgs = {
	filters?: InputMaybe<Array<CourseFilterInput>>;
	order?: InputMaybe<CourseOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type DocumentResourceFolderDocumentResourcesArgs = {
	filters?: InputMaybe<Array<DocumentResourceFilterInput>>;
	order?: InputMaybe<DocumentResourceOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type DocumentResourceFolderHomeRoomSectionsArgs = {
	filters?: InputMaybe<Array<HomeRoomSectionFilterInput>>;
	order?: InputMaybe<HomeRoomSectionOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type DocumentResourceFolderHomeRoomsArgs = {
	filters?: InputMaybe<Array<HomeRoomFilterInput>>;
	order?: InputMaybe<HomeRoomOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type DocumentResourceFolderSharingUserGroupsArgs = {
	filters?: InputMaybe<Array<UserGroupFilterInput>>;
	order?: InputMaybe<UserGroupOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type DocumentResourceFolderSharingUsersArgs = {
	filters?: InputMaybe<Array<UserFilterInput>>;
	order?: InputMaybe<UserOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export enum DocumentResourceFolderCategory {
	Academic = "academic",
	Administrative = "administrative",
	Financial = "financial",
	General = "general",
}

export type DocumentResourceFolderConnection = {
	__typename?: "DocumentResourceFolderConnection";
	edges: Array<DocumentResourceFolderEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type DocumentResourceFolderCourseHistory = Node & {
	__typename?: "DocumentResourceFolderCourseHistory";
	courseID: Maybe<Scalars["ID"]>;
	documentResourceFolderID: Maybe<Scalars["ID"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
};

export type DocumentResourceFolderCourseHistoryConnection = {
	__typename?: "DocumentResourceFolderCourseHistoryConnection";
	edges: Array<DocumentResourceFolderCourseHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type DocumentResourceFolderCourseHistoryEdge = {
	__typename?: "DocumentResourceFolderCourseHistoryEdge";
	cursor: Scalars["Cursor"];
	node: DocumentResourceFolderCourseHistory;
};

export type DocumentResourceFolderCourseHistoryFilterInput = {
	and?: InputMaybe<Array<DocumentResourceFolderCourseHistoryFilterInput>>;
	courseIDEQ?: InputMaybe<Scalars["ID"]>;
	courseIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	courseIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	courseIDNEQ?: InputMaybe<Scalars["ID"]>;
	courseIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	documentResourceFolderIDEQ?: InputMaybe<Scalars["ID"]>;
	documentResourceFolderIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	documentResourceFolderIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	documentResourceFolderIDNEQ?: InputMaybe<Scalars["ID"]>;
	documentResourceFolderIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<DocumentResourceFolderCourseHistoryFilterInput>;
	or?: InputMaybe<Array<DocumentResourceFolderCourseHistoryFilterInput>>;
};

export type DocumentResourceFolderCourseHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<DocumentResourceFolderCourseHistoryOrderField>;
};

export enum DocumentResourceFolderCourseHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type DocumentResourceFolderCourseSectionHistory = Node & {
	__typename?: "DocumentResourceFolderCourseSectionHistory";
	courseSectionID: Maybe<Scalars["ID"]>;
	documentResourceFolderID: Maybe<Scalars["ID"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
};

export type DocumentResourceFolderCourseSectionHistoryConnection = {
	__typename?: "DocumentResourceFolderCourseSectionHistoryConnection";
	edges: Array<DocumentResourceFolderCourseSectionHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type DocumentResourceFolderCourseSectionHistoryEdge = {
	__typename?: "DocumentResourceFolderCourseSectionHistoryEdge";
	cursor: Scalars["Cursor"];
	node: DocumentResourceFolderCourseSectionHistory;
};

export type DocumentResourceFolderCourseSectionHistoryFilterInput = {
	and?: InputMaybe<Array<DocumentResourceFolderCourseSectionHistoryFilterInput>>;
	courseSectionIDEQ?: InputMaybe<Scalars["ID"]>;
	courseSectionIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	courseSectionIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	courseSectionIDNEQ?: InputMaybe<Scalars["ID"]>;
	courseSectionIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	documentResourceFolderIDEQ?: InputMaybe<Scalars["ID"]>;
	documentResourceFolderIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	documentResourceFolderIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	documentResourceFolderIDNEQ?: InputMaybe<Scalars["ID"]>;
	documentResourceFolderIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<DocumentResourceFolderCourseSectionHistoryFilterInput>;
	or?: InputMaybe<Array<DocumentResourceFolderCourseSectionHistoryFilterInput>>;
};

export type DocumentResourceFolderCourseSectionHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<DocumentResourceFolderCourseSectionHistoryOrderField>;
};

export enum DocumentResourceFolderCourseSectionHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type DocumentResourceFolderCreateInput = {
	category?: InputMaybe<DocumentResourceFolderCategory>;
	childDocumentResourceFolderIDs?: InputMaybe<Array<Scalars["ID"]>>;
	childDocumentResourceFolders?: InputMaybe<Array<DocumentResourceFolderInParentDocumentResourceFolderCreateInput>>;
	courseIDs?: InputMaybe<Array<Scalars["ID"]>>;
	courseSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	description?: InputMaybe<Scalars["String"]>;
	documentResourceIDs?: InputMaybe<Array<Scalars["ID"]>>;
	homeRoomIDs?: InputMaybe<Array<Scalars["ID"]>>;
	homeRoomSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	isPublic?: InputMaybe<Scalars["Boolean"]>;
	name: Scalars["String"];
	parentDocumentResourceFolderID?: InputMaybe<Scalars["ID"]>;
	sharingUserGroupIDs?: InputMaybe<Array<Scalars["ID"]>>;
	sharingUserIDs?: InputMaybe<Array<Scalars["ID"]>>;
};

export type DocumentResourceFolderDocumentResourceHistory = Node & {
	__typename?: "DocumentResourceFolderDocumentResourceHistory";
	documentResourceFolderID: Maybe<Scalars["ID"]>;
	documentResourceID: Maybe<Scalars["ID"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
};

export type DocumentResourceFolderDocumentResourceHistoryConnection = {
	__typename?: "DocumentResourceFolderDocumentResourceHistoryConnection";
	edges: Array<DocumentResourceFolderDocumentResourceHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type DocumentResourceFolderDocumentResourceHistoryEdge = {
	__typename?: "DocumentResourceFolderDocumentResourceHistoryEdge";
	cursor: Scalars["Cursor"];
	node: DocumentResourceFolderDocumentResourceHistory;
};

export type DocumentResourceFolderDocumentResourceHistoryFilterInput = {
	and?: InputMaybe<Array<DocumentResourceFolderDocumentResourceHistoryFilterInput>>;
	documentResourceFolderIDEQ?: InputMaybe<Scalars["ID"]>;
	documentResourceFolderIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	documentResourceFolderIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	documentResourceFolderIDNEQ?: InputMaybe<Scalars["ID"]>;
	documentResourceFolderIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	documentResourceIDEQ?: InputMaybe<Scalars["ID"]>;
	documentResourceIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	documentResourceIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	documentResourceIDNEQ?: InputMaybe<Scalars["ID"]>;
	documentResourceIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<DocumentResourceFolderDocumentResourceHistoryFilterInput>;
	or?: InputMaybe<Array<DocumentResourceFolderDocumentResourceHistoryFilterInput>>;
};

export type DocumentResourceFolderDocumentResourceHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<DocumentResourceFolderDocumentResourceHistoryOrderField>;
};

export enum DocumentResourceFolderDocumentResourceHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type DocumentResourceFolderEdge = {
	__typename?: "DocumentResourceFolderEdge";
	cursor: Scalars["Cursor"];
	node: DocumentResourceFolder;
};

export type DocumentResourceFolderFilterInput = {
	and?: InputMaybe<Array<DocumentResourceFolderFilterInput>>;
	author?: InputMaybe<Array<UserFilterInput>>;
	authorIDEQ?: InputMaybe<Scalars["ID"]>;
	authorIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	authorIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	authorIDNEQ?: InputMaybe<Scalars["ID"]>;
	authorIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	categoryEQ?: InputMaybe<DocumentResourceFolderCategory>;
	categoryIn?: InputMaybe<Array<DocumentResourceFolderCategory>>;
	categoryNEQ?: InputMaybe<DocumentResourceFolderCategory>;
	categoryNotIn?: InputMaybe<Array<DocumentResourceFolderCategory>>;
	childDocumentResourceFolders?: InputMaybe<Array<DocumentResourceFolderFilterInput>>;
	courseSections?: InputMaybe<Array<CourseSectionFilterInput>>;
	courses?: InputMaybe<Array<CourseFilterInput>>;
	createdAtEQ?: InputMaybe<Scalars["Time"]>;
	createdAtGT?: InputMaybe<Scalars["Time"]>;
	createdAtGTE?: InputMaybe<Scalars["Time"]>;
	createdAtIn?: InputMaybe<Array<Scalars["Time"]>>;
	createdAtLT?: InputMaybe<Scalars["Time"]>;
	createdAtLTE?: InputMaybe<Scalars["Time"]>;
	createdAtNEQ?: InputMaybe<Scalars["Time"]>;
	createdAtNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	descriptionContains?: InputMaybe<Scalars["String"]>;
	descriptionEQ?: InputMaybe<Scalars["String"]>;
	descriptionHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionIn?: InputMaybe<Array<Scalars["String"]>>;
	descriptionNEQ?: InputMaybe<Scalars["String"]>;
	descriptionNotContains?: InputMaybe<Scalars["String"]>;
	descriptionNotHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionNotHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionNotIn?: InputMaybe<Array<Scalars["String"]>>;
	documentResources?: InputMaybe<Array<DocumentResourceFilterInput>>;
	hasAuthor?: InputMaybe<Scalars["Boolean"]>;
	hasChildDocumentResourceFolders?: InputMaybe<Scalars["Boolean"]>;
	hasCourseSections?: InputMaybe<Scalars["Boolean"]>;
	hasCourses?: InputMaybe<Scalars["Boolean"]>;
	hasDocumentResources?: InputMaybe<Scalars["Boolean"]>;
	hasHomeRoomSections?: InputMaybe<Scalars["Boolean"]>;
	hasHomeRooms?: InputMaybe<Scalars["Boolean"]>;
	hasParentDocumentResourceFolder?: InputMaybe<Scalars["Boolean"]>;
	hasSharingUserGroups?: InputMaybe<Scalars["Boolean"]>;
	hasSharingUsers?: InputMaybe<Scalars["Boolean"]>;
	homeRoomSections?: InputMaybe<Array<HomeRoomSectionFilterInput>>;
	homeRooms?: InputMaybe<Array<HomeRoomFilterInput>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	isPublicEQ?: InputMaybe<Scalars["Boolean"]>;
	isPublicNEQ?: InputMaybe<Scalars["Boolean"]>;
	nameContains?: InputMaybe<Scalars["String"]>;
	nameEQ?: InputMaybe<Scalars["String"]>;
	nameHasPrefix?: InputMaybe<Scalars["String"]>;
	nameHasSuffix?: InputMaybe<Scalars["String"]>;
	nameIn?: InputMaybe<Array<Scalars["String"]>>;
	nameNEQ?: InputMaybe<Scalars["String"]>;
	nameNotContains?: InputMaybe<Scalars["String"]>;
	nameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	nameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	nameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	not?: InputMaybe<DocumentResourceFolderFilterInput>;
	or?: InputMaybe<Array<DocumentResourceFolderFilterInput>>;
	parentDocumentResourceFolder?: InputMaybe<Array<DocumentResourceFolderFilterInput>>;
	parentDocumentResourceFolderIDEQ?: InputMaybe<Scalars["ID"]>;
	parentDocumentResourceFolderIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	parentDocumentResourceFolderIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	parentDocumentResourceFolderIDNEQ?: InputMaybe<Scalars["ID"]>;
	parentDocumentResourceFolderIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	search?: InputMaybe<Scalars["String"]>;
	sharingUserGroups?: InputMaybe<Array<UserGroupFilterInput>>;
	sharingUsers?: InputMaybe<Array<UserFilterInput>>;
};

export type DocumentResourceFolderHistory = Node & {
	__typename?: "DocumentResourceFolderHistory";
	authorID: Maybe<Scalars["ID"]>;
	category: Maybe<DocumentResourceFolderHistoryCategory>;
	createdAt: Maybe<Scalars["Time"]>;
	description: Maybe<Scalars["String"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	isPublic: Maybe<Scalars["Boolean"]>;
	name: Maybe<Scalars["String"]>;
	originalID: Maybe<Scalars["ID"]>;
	parentDocumentResourceFolderID: Maybe<Scalars["ID"]>;
};

export enum DocumentResourceFolderHistoryCategory {
	Academic = "academic",
	Administrative = "administrative",
	Financial = "financial",
	General = "general",
}

export type DocumentResourceFolderHistoryConnection = {
	__typename?: "DocumentResourceFolderHistoryConnection";
	edges: Array<DocumentResourceFolderHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type DocumentResourceFolderHistoryEdge = {
	__typename?: "DocumentResourceFolderHistoryEdge";
	cursor: Scalars["Cursor"];
	node: DocumentResourceFolderHistory;
};

export type DocumentResourceFolderHistoryFilterInput = {
	and?: InputMaybe<Array<DocumentResourceFolderHistoryFilterInput>>;
	authorIDEQ?: InputMaybe<Scalars["ID"]>;
	authorIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	authorIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	authorIDNEQ?: InputMaybe<Scalars["ID"]>;
	authorIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	categoryEQ?: InputMaybe<DocumentResourceFolderHistoryCategory>;
	categoryIn?: InputMaybe<Array<DocumentResourceFolderHistoryCategory>>;
	categoryIsNull?: InputMaybe<Scalars["Boolean"]>;
	categoryNEQ?: InputMaybe<DocumentResourceFolderHistoryCategory>;
	categoryNotIn?: InputMaybe<Array<DocumentResourceFolderHistoryCategory>>;
	createdAtEQ?: InputMaybe<Scalars["Time"]>;
	createdAtGT?: InputMaybe<Scalars["Time"]>;
	createdAtGTE?: InputMaybe<Scalars["Time"]>;
	createdAtIn?: InputMaybe<Array<Scalars["Time"]>>;
	createdAtIsNull?: InputMaybe<Scalars["Boolean"]>;
	createdAtLT?: InputMaybe<Scalars["Time"]>;
	createdAtLTE?: InputMaybe<Scalars["Time"]>;
	createdAtNEQ?: InputMaybe<Scalars["Time"]>;
	createdAtNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	descriptionContains?: InputMaybe<Scalars["String"]>;
	descriptionEQ?: InputMaybe<Scalars["String"]>;
	descriptionHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionIn?: InputMaybe<Array<Scalars["String"]>>;
	descriptionIsNull?: InputMaybe<Scalars["Boolean"]>;
	descriptionNEQ?: InputMaybe<Scalars["String"]>;
	descriptionNotContains?: InputMaybe<Scalars["String"]>;
	descriptionNotHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionNotHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionNotIn?: InputMaybe<Array<Scalars["String"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	isPublicEQ?: InputMaybe<Scalars["Boolean"]>;
	isPublicIsNull?: InputMaybe<Scalars["Boolean"]>;
	isPublicNEQ?: InputMaybe<Scalars["Boolean"]>;
	nameContains?: InputMaybe<Scalars["String"]>;
	nameEQ?: InputMaybe<Scalars["String"]>;
	nameHasPrefix?: InputMaybe<Scalars["String"]>;
	nameHasSuffix?: InputMaybe<Scalars["String"]>;
	nameIn?: InputMaybe<Array<Scalars["String"]>>;
	nameIsNull?: InputMaybe<Scalars["Boolean"]>;
	nameNEQ?: InputMaybe<Scalars["String"]>;
	nameNotContains?: InputMaybe<Scalars["String"]>;
	nameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	nameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	nameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	not?: InputMaybe<DocumentResourceFolderHistoryFilterInput>;
	or?: InputMaybe<Array<DocumentResourceFolderHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	parentDocumentResourceFolderIDEQ?: InputMaybe<Scalars["ID"]>;
	parentDocumentResourceFolderIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	parentDocumentResourceFolderIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	parentDocumentResourceFolderIDNEQ?: InputMaybe<Scalars["ID"]>;
	parentDocumentResourceFolderIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	search?: InputMaybe<Scalars["String"]>;
};

export type DocumentResourceFolderHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<DocumentResourceFolderHistoryOrderField>;
};

export enum DocumentResourceFolderHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type DocumentResourceFolderHomeRoomHistory = Node & {
	__typename?: "DocumentResourceFolderHomeRoomHistory";
	documentResourceFolderID: Maybe<Scalars["ID"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	homeRoomID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
};

export type DocumentResourceFolderHomeRoomHistoryConnection = {
	__typename?: "DocumentResourceFolderHomeRoomHistoryConnection";
	edges: Array<DocumentResourceFolderHomeRoomHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type DocumentResourceFolderHomeRoomHistoryEdge = {
	__typename?: "DocumentResourceFolderHomeRoomHistoryEdge";
	cursor: Scalars["Cursor"];
	node: DocumentResourceFolderHomeRoomHistory;
};

export type DocumentResourceFolderHomeRoomHistoryFilterInput = {
	and?: InputMaybe<Array<DocumentResourceFolderHomeRoomHistoryFilterInput>>;
	documentResourceFolderIDEQ?: InputMaybe<Scalars["ID"]>;
	documentResourceFolderIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	documentResourceFolderIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	documentResourceFolderIDNEQ?: InputMaybe<Scalars["ID"]>;
	documentResourceFolderIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	homeRoomIDEQ?: InputMaybe<Scalars["ID"]>;
	homeRoomIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	homeRoomIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	homeRoomIDNEQ?: InputMaybe<Scalars["ID"]>;
	homeRoomIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<DocumentResourceFolderHomeRoomHistoryFilterInput>;
	or?: InputMaybe<Array<DocumentResourceFolderHomeRoomHistoryFilterInput>>;
};

export type DocumentResourceFolderHomeRoomHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<DocumentResourceFolderHomeRoomHistoryOrderField>;
};

export enum DocumentResourceFolderHomeRoomHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type DocumentResourceFolderHomeRoomSectionHistory = Node & {
	__typename?: "DocumentResourceFolderHomeRoomSectionHistory";
	documentResourceFolderID: Maybe<Scalars["ID"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	homeRoomSectionID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
};

export type DocumentResourceFolderHomeRoomSectionHistoryConnection = {
	__typename?: "DocumentResourceFolderHomeRoomSectionHistoryConnection";
	edges: Array<DocumentResourceFolderHomeRoomSectionHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type DocumentResourceFolderHomeRoomSectionHistoryEdge = {
	__typename?: "DocumentResourceFolderHomeRoomSectionHistoryEdge";
	cursor: Scalars["Cursor"];
	node: DocumentResourceFolderHomeRoomSectionHistory;
};

export type DocumentResourceFolderHomeRoomSectionHistoryFilterInput = {
	and?: InputMaybe<Array<DocumentResourceFolderHomeRoomSectionHistoryFilterInput>>;
	documentResourceFolderIDEQ?: InputMaybe<Scalars["ID"]>;
	documentResourceFolderIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	documentResourceFolderIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	documentResourceFolderIDNEQ?: InputMaybe<Scalars["ID"]>;
	documentResourceFolderIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	homeRoomSectionIDEQ?: InputMaybe<Scalars["ID"]>;
	homeRoomSectionIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	homeRoomSectionIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	homeRoomSectionIDNEQ?: InputMaybe<Scalars["ID"]>;
	homeRoomSectionIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<DocumentResourceFolderHomeRoomSectionHistoryFilterInput>;
	or?: InputMaybe<Array<DocumentResourceFolderHomeRoomSectionHistoryFilterInput>>;
};

export type DocumentResourceFolderHomeRoomSectionHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<DocumentResourceFolderHomeRoomSectionHistoryOrderField>;
};

export enum DocumentResourceFolderHomeRoomSectionHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type DocumentResourceFolderInAuthorCreateInput = {
	category?: InputMaybe<DocumentResourceFolderCategory>;
	childDocumentResourceFolderIDs?: InputMaybe<Array<Scalars["ID"]>>;
	childDocumentResourceFolders?: InputMaybe<Array<DocumentResourceFolderInParentDocumentResourceFolderCreateInput>>;
	courseIDs?: InputMaybe<Array<Scalars["ID"]>>;
	courseSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	description?: InputMaybe<Scalars["String"]>;
	documentResourceIDs?: InputMaybe<Array<Scalars["ID"]>>;
	homeRoomIDs?: InputMaybe<Array<Scalars["ID"]>>;
	homeRoomSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	isPublic?: InputMaybe<Scalars["Boolean"]>;
	name: Scalars["String"];
	parentDocumentResourceFolderID?: InputMaybe<Scalars["ID"]>;
	sharingUserGroupIDs?: InputMaybe<Array<Scalars["ID"]>>;
	sharingUserIDs?: InputMaybe<Array<Scalars["ID"]>>;
};

export type DocumentResourceFolderInParentDocumentResourceFolderCreateInput = {
	category?: InputMaybe<DocumentResourceFolderCategory>;
	childDocumentResourceFolderIDs?: InputMaybe<Array<Scalars["ID"]>>;
	childDocumentResourceFolders?: InputMaybe<Array<DocumentResourceFolderInParentDocumentResourceFolderCreateInput>>;
	courseIDs?: InputMaybe<Array<Scalars["ID"]>>;
	courseSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	description?: InputMaybe<Scalars["String"]>;
	documentResourceIDs?: InputMaybe<Array<Scalars["ID"]>>;
	homeRoomIDs?: InputMaybe<Array<Scalars["ID"]>>;
	homeRoomSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	isPublic?: InputMaybe<Scalars["Boolean"]>;
	name: Scalars["String"];
	sharingUserGroupIDs?: InputMaybe<Array<Scalars["ID"]>>;
	sharingUserIDs?: InputMaybe<Array<Scalars["ID"]>>;
};

export type DocumentResourceFolderOrder = {
	direction: OrderDirection;
	field?: InputMaybe<DocumentResourceFolderOrderField>;
};

export enum DocumentResourceFolderOrderField {
	Category = "CATEGORY",
	CreatedAt = "CREATED_AT",
	Name = "NAME",
}

export type DocumentResourceFolderSharedDocumentResourceFolderUserGroupSharingUserGroupHistory = Node & {
	__typename?: "DocumentResourceFolderSharedDocumentResourceFolderUserGroupSharingUserGroupHistory";
	documentResourceFolderID: Maybe<Scalars["ID"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	userGroupID: Maybe<Scalars["ID"]>;
};

export type DocumentResourceFolderSharedDocumentResourceFolderUserGroupSharingUserGroupHistoryConnection = {
	__typename?: "DocumentResourceFolderSharedDocumentResourceFolderUserGroupSharingUserGroupHistoryConnection";
	edges: Array<DocumentResourceFolderSharedDocumentResourceFolderUserGroupSharingUserGroupHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type DocumentResourceFolderSharedDocumentResourceFolderUserGroupSharingUserGroupHistoryEdge = {
	__typename?: "DocumentResourceFolderSharedDocumentResourceFolderUserGroupSharingUserGroupHistoryEdge";
	cursor: Scalars["Cursor"];
	node: DocumentResourceFolderSharedDocumentResourceFolderUserGroupSharingUserGroupHistory;
};

export type DocumentResourceFolderSharedDocumentResourceFolderUserGroupSharingUserGroupHistoryFilterInput = {
	and?: InputMaybe<
		Array<DocumentResourceFolderSharedDocumentResourceFolderUserGroupSharingUserGroupHistoryFilterInput>
	>;
	documentResourceFolderIDEQ?: InputMaybe<Scalars["ID"]>;
	documentResourceFolderIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	documentResourceFolderIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	documentResourceFolderIDNEQ?: InputMaybe<Scalars["ID"]>;
	documentResourceFolderIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<DocumentResourceFolderSharedDocumentResourceFolderUserGroupSharingUserGroupHistoryFilterInput>;
	or?: InputMaybe<Array<DocumentResourceFolderSharedDocumentResourceFolderUserGroupSharingUserGroupHistoryFilterInput>>;
	userGroupIDEQ?: InputMaybe<Scalars["ID"]>;
	userGroupIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	userGroupIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	userGroupIDNEQ?: InputMaybe<Scalars["ID"]>;
	userGroupIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type DocumentResourceFolderSharedDocumentResourceFolderUserGroupSharingUserGroupHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<DocumentResourceFolderSharedDocumentResourceFolderUserGroupSharingUserGroupHistoryOrderField>;
};

export enum DocumentResourceFolderSharedDocumentResourceFolderUserGroupSharingUserGroupHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type DocumentResourceFolderSharedDocumentResourceFolderUserSharingUserHistory = Node & {
	__typename?: "DocumentResourceFolderSharedDocumentResourceFolderUserSharingUserHistory";
	documentResourceFolderID: Maybe<Scalars["ID"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	userID: Maybe<Scalars["ID"]>;
};

export type DocumentResourceFolderSharedDocumentResourceFolderUserSharingUserHistoryConnection = {
	__typename?: "DocumentResourceFolderSharedDocumentResourceFolderUserSharingUserHistoryConnection";
	edges: Array<DocumentResourceFolderSharedDocumentResourceFolderUserSharingUserHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type DocumentResourceFolderSharedDocumentResourceFolderUserSharingUserHistoryEdge = {
	__typename?: "DocumentResourceFolderSharedDocumentResourceFolderUserSharingUserHistoryEdge";
	cursor: Scalars["Cursor"];
	node: DocumentResourceFolderSharedDocumentResourceFolderUserSharingUserHistory;
};

export type DocumentResourceFolderSharedDocumentResourceFolderUserSharingUserHistoryFilterInput = {
	and?: InputMaybe<Array<DocumentResourceFolderSharedDocumentResourceFolderUserSharingUserHistoryFilterInput>>;
	documentResourceFolderIDEQ?: InputMaybe<Scalars["ID"]>;
	documentResourceFolderIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	documentResourceFolderIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	documentResourceFolderIDNEQ?: InputMaybe<Scalars["ID"]>;
	documentResourceFolderIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<DocumentResourceFolderSharedDocumentResourceFolderUserSharingUserHistoryFilterInput>;
	or?: InputMaybe<Array<DocumentResourceFolderSharedDocumentResourceFolderUserSharingUserHistoryFilterInput>>;
	userIDEQ?: InputMaybe<Scalars["ID"]>;
	userIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	userIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	userIDNEQ?: InputMaybe<Scalars["ID"]>;
	userIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type DocumentResourceFolderSharedDocumentResourceFolderUserSharingUserHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<DocumentResourceFolderSharedDocumentResourceFolderUserSharingUserHistoryOrderField>;
};

export enum DocumentResourceFolderSharedDocumentResourceFolderUserSharingUserHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type DocumentResourceFolderUpdateBulkInput = {
	addChildDocumentResourceFolderIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addCourseIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addCourseSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addDocumentResourceIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addHomeRoomIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addHomeRoomSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addSharingUserGroupIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addSharingUserIDs?: InputMaybe<Array<Scalars["ID"]>>;
	category?: InputMaybe<DocumentResourceFolderCategory>;
	clearParentDocumentResourceFolder?: InputMaybe<Scalars["Boolean"]>;
	description?: InputMaybe<Scalars["String"]>;
	isPublic?: InputMaybe<Scalars["Boolean"]>;
	name?: InputMaybe<Scalars["String"]>;
	parentDocumentResourceFolderID?: InputMaybe<Scalars["ID"]>;
	removeChildDocumentResourceFolderIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeCourseIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeCourseSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeDocumentResourceIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeHomeRoomIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeHomeRoomSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeSharingUserGroupIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeSharingUserIDs?: InputMaybe<Array<Scalars["ID"]>>;
};

export type DocumentResourceFolderUpdateInput = {
	addChildDocumentResourceFolderIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addCourseIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addCourseSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addDocumentResourceIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addHomeRoomIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addHomeRoomSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addSharingUserGroupIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addSharingUserIDs?: InputMaybe<Array<Scalars["ID"]>>;
	category?: InputMaybe<DocumentResourceFolderCategory>;
	clearParentDocumentResourceFolder?: InputMaybe<Scalars["Boolean"]>;
	description?: InputMaybe<Scalars["String"]>;
	isPublic?: InputMaybe<Scalars["Boolean"]>;
	name?: InputMaybe<Scalars["String"]>;
	parentDocumentResourceFolderID?: InputMaybe<Scalars["ID"]>;
	removeChildDocumentResourceFolderIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeCourseIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeCourseSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeDocumentResourceIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeHomeRoomIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeHomeRoomSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeSharingUserGroupIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeSharingUserIDs?: InputMaybe<Array<Scalars["ID"]>>;
};

export type DocumentResourceHistory = Node & {
	__typename?: "DocumentResourceHistory";
	authorID: Maybe<Scalars["ID"]>;
	createdAt: Maybe<Scalars["Time"]>;
	description: Maybe<Scalars["String"]>;
	fileContentType: Maybe<Scalars["String"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	imageContentType: Maybe<Scalars["String"]>;
	name: Maybe<Scalars["String"]>;
	originalID: Maybe<Scalars["ID"]>;
	resourceURL: Maybe<Scalars["String"]>;
};

export type DocumentResourceHistoryConnection = {
	__typename?: "DocumentResourceHistoryConnection";
	edges: Array<DocumentResourceHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type DocumentResourceHistoryEdge = {
	__typename?: "DocumentResourceHistoryEdge";
	cursor: Scalars["Cursor"];
	node: DocumentResourceHistory;
};

export type DocumentResourceHistoryFilterInput = {
	and?: InputMaybe<Array<DocumentResourceHistoryFilterInput>>;
	authorIDEQ?: InputMaybe<Scalars["ID"]>;
	authorIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	authorIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	authorIDNEQ?: InputMaybe<Scalars["ID"]>;
	authorIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	createdAtEQ?: InputMaybe<Scalars["Time"]>;
	createdAtGT?: InputMaybe<Scalars["Time"]>;
	createdAtGTE?: InputMaybe<Scalars["Time"]>;
	createdAtIn?: InputMaybe<Array<Scalars["Time"]>>;
	createdAtIsNull?: InputMaybe<Scalars["Boolean"]>;
	createdAtLT?: InputMaybe<Scalars["Time"]>;
	createdAtLTE?: InputMaybe<Scalars["Time"]>;
	createdAtNEQ?: InputMaybe<Scalars["Time"]>;
	createdAtNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	descriptionContains?: InputMaybe<Scalars["String"]>;
	descriptionEQ?: InputMaybe<Scalars["String"]>;
	descriptionHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionIn?: InputMaybe<Array<Scalars["String"]>>;
	descriptionIsNull?: InputMaybe<Scalars["Boolean"]>;
	descriptionNEQ?: InputMaybe<Scalars["String"]>;
	descriptionNotContains?: InputMaybe<Scalars["String"]>;
	descriptionNotHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionNotHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionNotIn?: InputMaybe<Array<Scalars["String"]>>;
	fileContentTypeContains?: InputMaybe<Scalars["String"]>;
	fileContentTypeEQ?: InputMaybe<Scalars["String"]>;
	fileContentTypeHasPrefix?: InputMaybe<Scalars["String"]>;
	fileContentTypeHasSuffix?: InputMaybe<Scalars["String"]>;
	fileContentTypeIn?: InputMaybe<Array<Scalars["String"]>>;
	fileContentTypeIsNull?: InputMaybe<Scalars["Boolean"]>;
	fileContentTypeNEQ?: InputMaybe<Scalars["String"]>;
	fileContentTypeNotContains?: InputMaybe<Scalars["String"]>;
	fileContentTypeNotHasPrefix?: InputMaybe<Scalars["String"]>;
	fileContentTypeNotHasSuffix?: InputMaybe<Scalars["String"]>;
	fileContentTypeNotIn?: InputMaybe<Array<Scalars["String"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	imageContentTypeContains?: InputMaybe<Scalars["String"]>;
	imageContentTypeEQ?: InputMaybe<Scalars["String"]>;
	imageContentTypeHasPrefix?: InputMaybe<Scalars["String"]>;
	imageContentTypeHasSuffix?: InputMaybe<Scalars["String"]>;
	imageContentTypeIn?: InputMaybe<Array<Scalars["String"]>>;
	imageContentTypeIsNull?: InputMaybe<Scalars["Boolean"]>;
	imageContentTypeNEQ?: InputMaybe<Scalars["String"]>;
	imageContentTypeNotContains?: InputMaybe<Scalars["String"]>;
	imageContentTypeNotHasPrefix?: InputMaybe<Scalars["String"]>;
	imageContentTypeNotHasSuffix?: InputMaybe<Scalars["String"]>;
	imageContentTypeNotIn?: InputMaybe<Array<Scalars["String"]>>;
	nameContains?: InputMaybe<Scalars["String"]>;
	nameEQ?: InputMaybe<Scalars["String"]>;
	nameHasPrefix?: InputMaybe<Scalars["String"]>;
	nameHasSuffix?: InputMaybe<Scalars["String"]>;
	nameIn?: InputMaybe<Array<Scalars["String"]>>;
	nameIsNull?: InputMaybe<Scalars["Boolean"]>;
	nameNEQ?: InputMaybe<Scalars["String"]>;
	nameNotContains?: InputMaybe<Scalars["String"]>;
	nameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	nameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	nameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	not?: InputMaybe<DocumentResourceHistoryFilterInput>;
	or?: InputMaybe<Array<DocumentResourceHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	resourceURLContains?: InputMaybe<Scalars["String"]>;
	resourceURLEQ?: InputMaybe<Scalars["String"]>;
	resourceURLHasPrefix?: InputMaybe<Scalars["String"]>;
	resourceURLHasSuffix?: InputMaybe<Scalars["String"]>;
	resourceURLIn?: InputMaybe<Array<Scalars["String"]>>;
	resourceURLIsNull?: InputMaybe<Scalars["Boolean"]>;
	resourceURLNEQ?: InputMaybe<Scalars["String"]>;
	resourceURLNotContains?: InputMaybe<Scalars["String"]>;
	resourceURLNotHasPrefix?: InputMaybe<Scalars["String"]>;
	resourceURLNotHasSuffix?: InputMaybe<Scalars["String"]>;
	resourceURLNotIn?: InputMaybe<Array<Scalars["String"]>>;
	search?: InputMaybe<Scalars["String"]>;
};

export type DocumentResourceHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<DocumentResourceHistoryOrderField>;
};

export enum DocumentResourceHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type DocumentResourceInAuthorCreateInput = {
	bookmarkingUserIDs?: InputMaybe<Array<Scalars["ID"]>>;
	description?: InputMaybe<Scalars["String"]>;
	documentResourceFolderIDs?: InputMaybe<Array<Scalars["ID"]>>;
	documentResourceUserViewRecords?: InputMaybe<Array<DocumentResourceUserViewRecordInDocumentResourceCreateInput>>;
	file?: InputMaybe<Scalars["Upload"]>;
	image?: InputMaybe<Scalars["Upload"]>;
	name: Scalars["String"];
	resourceURL?: InputMaybe<Scalars["String"]>;
};

export type DocumentResourceOrder = {
	direction: OrderDirection;
	field?: InputMaybe<DocumentResourceOrderField>;
};

export enum DocumentResourceOrderField {
	CreatedAt = "CREATED_AT",
	Name = "NAME",
}

export type DocumentResourceUpdateBulkInput = {
	addBookmarkingUserIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addDocumentResourceFolderIDs?: InputMaybe<Array<Scalars["ID"]>>;
	clearFile?: InputMaybe<Scalars["Boolean"]>;
	clearImage?: InputMaybe<Scalars["Boolean"]>;
	description?: InputMaybe<Scalars["String"]>;
	file?: InputMaybe<Scalars["Upload"]>;
	image?: InputMaybe<Scalars["Upload"]>;
	name?: InputMaybe<Scalars["String"]>;
	removeBookmarkingUserIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeDocumentResourceFolderIDs?: InputMaybe<Array<Scalars["ID"]>>;
	resourceURL?: InputMaybe<Scalars["String"]>;
};

export type DocumentResourceUpdateInput = {
	addBookmarkingUserIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addDocumentResourceFolderIDs?: InputMaybe<Array<Scalars["ID"]>>;
	clearFile?: InputMaybe<Scalars["Boolean"]>;
	clearImage?: InputMaybe<Scalars["Boolean"]>;
	description?: InputMaybe<Scalars["String"]>;
	file?: InputMaybe<Scalars["Upload"]>;
	image?: InputMaybe<Scalars["Upload"]>;
	name?: InputMaybe<Scalars["String"]>;
	removeBookmarkingUserIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeDocumentResourceFolderIDs?: InputMaybe<Array<Scalars["ID"]>>;
	resourceURL?: InputMaybe<Scalars["String"]>;
};

export type DocumentResourceUserViewRecord = Node & {
	__typename?: "DocumentResourceUserViewRecord";
	documentResource: DocumentResource;
	documentResourceID: Scalars["ID"];
	id: Scalars["ID"];
	user: Maybe<User>;
	userID: Maybe<Scalars["ID"]>;
	viewedAt: Scalars["Time"];
};

export type DocumentResourceUserViewRecordConnection = {
	__typename?: "DocumentResourceUserViewRecordConnection";
	edges: Array<DocumentResourceUserViewRecordEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type DocumentResourceUserViewRecordCreateInput = {
	documentResourceID: Scalars["ID"];
};

export type DocumentResourceUserViewRecordEdge = {
	__typename?: "DocumentResourceUserViewRecordEdge";
	cursor: Scalars["Cursor"];
	node: DocumentResourceUserViewRecord;
};

export type DocumentResourceUserViewRecordFilterInput = {
	and?: InputMaybe<Array<DocumentResourceUserViewRecordFilterInput>>;
	documentResource?: InputMaybe<Array<DocumentResourceFilterInput>>;
	documentResourceIDEQ?: InputMaybe<Scalars["ID"]>;
	documentResourceIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	documentResourceIDNEQ?: InputMaybe<Scalars["ID"]>;
	documentResourceIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	hasUser?: InputMaybe<Scalars["Boolean"]>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<DocumentResourceUserViewRecordFilterInput>;
	or?: InputMaybe<Array<DocumentResourceUserViewRecordFilterInput>>;
	user?: InputMaybe<Array<UserFilterInput>>;
	userIDEQ?: InputMaybe<Scalars["ID"]>;
	userIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	userIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	userIDNEQ?: InputMaybe<Scalars["ID"]>;
	userIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	viewedAtEQ?: InputMaybe<Scalars["Time"]>;
	viewedAtGT?: InputMaybe<Scalars["Time"]>;
	viewedAtGTE?: InputMaybe<Scalars["Time"]>;
	viewedAtIn?: InputMaybe<Array<Scalars["Time"]>>;
	viewedAtLT?: InputMaybe<Scalars["Time"]>;
	viewedAtLTE?: InputMaybe<Scalars["Time"]>;
	viewedAtNEQ?: InputMaybe<Scalars["Time"]>;
	viewedAtNotIn?: InputMaybe<Array<Scalars["Time"]>>;
};

export type DocumentResourceUserViewRecordInDocumentResourceCreateInput = {
	_filler?: InputMaybe<Scalars["Boolean"]>;
};

export type DocumentResourceUserViewRecordInUserCreateInput = {
	documentResourceID: Scalars["ID"];
};

export type DocumentResourceUserViewRecordOrder = {
	direction: OrderDirection;
	field?: InputMaybe<DocumentResourceUserViewRecordOrderField>;
};

export enum DocumentResourceUserViewRecordOrderField {
	ViewedAt = "VIEWED_AT",
}

export type EdgePagination = {
	first?: InputMaybe<Scalars["Int"]>;
};

export type EmailAddress = Node & {
	__typename?: "EmailAddress";
	email: Scalars["String"];
	id: Scalars["ID"];
	person: Maybe<Person>;
	personID: Maybe<Scalars["ID"]>;
};

export type EmailAddressConnection = {
	__typename?: "EmailAddressConnection";
	edges: Array<EmailAddressEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type EmailAddressCreateInput = {
	email: Scalars["String"];
	personID?: InputMaybe<Scalars["ID"]>;
};

export type EmailAddressEdge = {
	__typename?: "EmailAddressEdge";
	cursor: Scalars["Cursor"];
	node: EmailAddress;
};

export type EmailAddressFilterInput = {
	and?: InputMaybe<Array<EmailAddressFilterInput>>;
	emailContains?: InputMaybe<Scalars["String"]>;
	emailEQ?: InputMaybe<Scalars["String"]>;
	emailHasPrefix?: InputMaybe<Scalars["String"]>;
	emailHasSuffix?: InputMaybe<Scalars["String"]>;
	emailIn?: InputMaybe<Array<Scalars["String"]>>;
	emailNEQ?: InputMaybe<Scalars["String"]>;
	emailNotContains?: InputMaybe<Scalars["String"]>;
	emailNotHasPrefix?: InputMaybe<Scalars["String"]>;
	emailNotHasSuffix?: InputMaybe<Scalars["String"]>;
	emailNotIn?: InputMaybe<Array<Scalars["String"]>>;
	hasPerson?: InputMaybe<Scalars["Boolean"]>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<EmailAddressFilterInput>;
	or?: InputMaybe<Array<EmailAddressFilterInput>>;
	person?: InputMaybe<Array<PersonFilterInput>>;
	personIDEQ?: InputMaybe<Scalars["ID"]>;
	personIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	personIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	personIDNEQ?: InputMaybe<Scalars["ID"]>;
	personIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	search?: InputMaybe<Scalars["String"]>;
};

export type EmailAddressHistory = Node & {
	__typename?: "EmailAddressHistory";
	email: Maybe<Scalars["String"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	originalID: Maybe<Scalars["ID"]>;
	personID: Maybe<Scalars["ID"]>;
};

export type EmailAddressHistoryConnection = {
	__typename?: "EmailAddressHistoryConnection";
	edges: Array<EmailAddressHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type EmailAddressHistoryEdge = {
	__typename?: "EmailAddressHistoryEdge";
	cursor: Scalars["Cursor"];
	node: EmailAddressHistory;
};

export type EmailAddressHistoryFilterInput = {
	and?: InputMaybe<Array<EmailAddressHistoryFilterInput>>;
	emailContains?: InputMaybe<Scalars["String"]>;
	emailEQ?: InputMaybe<Scalars["String"]>;
	emailHasPrefix?: InputMaybe<Scalars["String"]>;
	emailHasSuffix?: InputMaybe<Scalars["String"]>;
	emailIn?: InputMaybe<Array<Scalars["String"]>>;
	emailIsNull?: InputMaybe<Scalars["Boolean"]>;
	emailNEQ?: InputMaybe<Scalars["String"]>;
	emailNotContains?: InputMaybe<Scalars["String"]>;
	emailNotHasPrefix?: InputMaybe<Scalars["String"]>;
	emailNotHasSuffix?: InputMaybe<Scalars["String"]>;
	emailNotIn?: InputMaybe<Array<Scalars["String"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<EmailAddressHistoryFilterInput>;
	or?: InputMaybe<Array<EmailAddressHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	personIDEQ?: InputMaybe<Scalars["ID"]>;
	personIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	personIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	personIDNEQ?: InputMaybe<Scalars["ID"]>;
	personIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	search?: InputMaybe<Scalars["String"]>;
};

export type EmailAddressHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<EmailAddressHistoryOrderField>;
};

export enum EmailAddressHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type EmailAddressInPersonCreateInput = {
	email: Scalars["String"];
};

export type EmailAddressOrder = {
	direction: OrderDirection;
	field?: InputMaybe<EmailAddressOrderField>;
};

export enum EmailAddressOrderField {
	Email = "EMAIL",
}

export type EmailAddressUpdateBulkInput = {
	clearPerson?: InputMaybe<Scalars["Boolean"]>;
	email?: InputMaybe<Scalars["String"]>;
	personID?: InputMaybe<Scalars["ID"]>;
};

export type EmailAddressUpdateInput = {
	clearPerson?: InputMaybe<Scalars["Boolean"]>;
	email?: InputMaybe<Scalars["String"]>;
	personID?: InputMaybe<Scalars["ID"]>;
};

export type Employee = Node & {
	__typename?: "Employee";
	campuses: Array<Campus>;
	courseSectionTeacherEnrollments: Array<CourseSectionTeacherEnrollment>;
	email: Scalars["String"];
	endDate: Maybe<Scalars["Time"]>;
	homeRoomSectionTeacherEnrollments: Array<HomeRoomSectionTeacherEnrollment>;
	id: Scalars["ID"];
	jobTitle: Scalars["String"];
	notes: Array<Note>;
	person: Person;
	personID: Scalars["ID"];
	role: EmployeeRole;
	startDate: Scalars["Time"];
};

export type EmployeeCampusesArgs = {
	filters?: InputMaybe<Array<CampusFilterInput>>;
	order?: InputMaybe<CampusOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type EmployeeCourseSectionTeacherEnrollmentsArgs = {
	filters?: InputMaybe<Array<CourseSectionTeacherEnrollmentFilterInput>>;
	order?: InputMaybe<CourseSectionTeacherEnrollmentOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type EmployeeHomeRoomSectionTeacherEnrollmentsArgs = {
	filters?: InputMaybe<Array<HomeRoomSectionTeacherEnrollmentFilterInput>>;
	order?: InputMaybe<HomeRoomSectionTeacherEnrollmentOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type EmployeeNotesArgs = {
	filters?: InputMaybe<Array<NoteFilterInput>>;
	order?: InputMaybe<NoteOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type EmployeeCampusHistory = Node & {
	__typename?: "EmployeeCampusHistory";
	campusID: Maybe<Scalars["ID"]>;
	employeeID: Maybe<Scalars["ID"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
};

export type EmployeeCampusHistoryConnection = {
	__typename?: "EmployeeCampusHistoryConnection";
	edges: Array<EmployeeCampusHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type EmployeeCampusHistoryEdge = {
	__typename?: "EmployeeCampusHistoryEdge";
	cursor: Scalars["Cursor"];
	node: EmployeeCampusHistory;
};

export type EmployeeCampusHistoryFilterInput = {
	and?: InputMaybe<Array<EmployeeCampusHistoryFilterInput>>;
	campusIDEQ?: InputMaybe<Scalars["ID"]>;
	campusIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	campusIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	campusIDNEQ?: InputMaybe<Scalars["ID"]>;
	campusIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	employeeIDEQ?: InputMaybe<Scalars["ID"]>;
	employeeIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	employeeIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	employeeIDNEQ?: InputMaybe<Scalars["ID"]>;
	employeeIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<EmployeeCampusHistoryFilterInput>;
	or?: InputMaybe<Array<EmployeeCampusHistoryFilterInput>>;
};

export type EmployeeCampusHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<EmployeeCampusHistoryOrderField>;
};

export enum EmployeeCampusHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type EmployeeConnection = {
	__typename?: "EmployeeConnection";
	edges: Array<EmployeeEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type EmployeeCreateInput = {
	campusIDs?: InputMaybe<Array<Scalars["ID"]>>;
	courseSectionTeacherEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	courseSectionTeacherEnrollments?: InputMaybe<Array<CourseSectionTeacherEnrollmentInTeacherCreateInput>>;
	email: Scalars["String"];
	endDate?: InputMaybe<Scalars["Time"]>;
	homeRoomSectionTeacherEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	homeRoomSectionTeacherEnrollments?: InputMaybe<Array<HomeRoomSectionTeacherEnrollmentInTeacherCreateInput>>;
	jobTitle: Scalars["String"];
	noteIDs?: InputMaybe<Array<Scalars["ID"]>>;
	person?: InputMaybe<PersonInEmployeeCreateInput>;
	personID?: InputMaybe<Scalars["ID"]>;
	role: EmployeeRole;
	startDate: Scalars["Time"];
};

export type EmployeeEdge = {
	__typename?: "EmployeeEdge";
	cursor: Scalars["Cursor"];
	node: Employee;
};

export type EmployeeFilterInput = {
	and?: InputMaybe<Array<EmployeeFilterInput>>;
	campuses?: InputMaybe<Array<CampusFilterInput>>;
	courseSectionTeacherEnrollments?: InputMaybe<Array<CourseSectionTeacherEnrollmentFilterInput>>;
	emailContains?: InputMaybe<Scalars["String"]>;
	emailEQ?: InputMaybe<Scalars["String"]>;
	emailHasPrefix?: InputMaybe<Scalars["String"]>;
	emailHasSuffix?: InputMaybe<Scalars["String"]>;
	emailIn?: InputMaybe<Array<Scalars["String"]>>;
	emailNEQ?: InputMaybe<Scalars["String"]>;
	emailNotContains?: InputMaybe<Scalars["String"]>;
	emailNotHasPrefix?: InputMaybe<Scalars["String"]>;
	emailNotHasSuffix?: InputMaybe<Scalars["String"]>;
	emailNotIn?: InputMaybe<Array<Scalars["String"]>>;
	endDateEQ?: InputMaybe<Scalars["Time"]>;
	endDateGT?: InputMaybe<Scalars["Time"]>;
	endDateGTE?: InputMaybe<Scalars["Time"]>;
	endDateIn?: InputMaybe<Array<Scalars["Time"]>>;
	endDateIsNull?: InputMaybe<Scalars["Boolean"]>;
	endDateLT?: InputMaybe<Scalars["Time"]>;
	endDateLTE?: InputMaybe<Scalars["Time"]>;
	endDateNEQ?: InputMaybe<Scalars["Time"]>;
	endDateNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	hasCampuses?: InputMaybe<Scalars["Boolean"]>;
	hasCourseSectionTeacherEnrollments?: InputMaybe<Scalars["Boolean"]>;
	hasHomeRoomSectionTeacherEnrollments?: InputMaybe<Scalars["Boolean"]>;
	hasNotes?: InputMaybe<Scalars["Boolean"]>;
	homeRoomSectionTeacherEnrollments?: InputMaybe<Array<HomeRoomSectionTeacherEnrollmentFilterInput>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	jobTitleContains?: InputMaybe<Scalars["String"]>;
	jobTitleEQ?: InputMaybe<Scalars["String"]>;
	jobTitleHasPrefix?: InputMaybe<Scalars["String"]>;
	jobTitleHasSuffix?: InputMaybe<Scalars["String"]>;
	jobTitleIn?: InputMaybe<Array<Scalars["String"]>>;
	jobTitleNEQ?: InputMaybe<Scalars["String"]>;
	jobTitleNotContains?: InputMaybe<Scalars["String"]>;
	jobTitleNotHasPrefix?: InputMaybe<Scalars["String"]>;
	jobTitleNotHasSuffix?: InputMaybe<Scalars["String"]>;
	jobTitleNotIn?: InputMaybe<Array<Scalars["String"]>>;
	not?: InputMaybe<EmployeeFilterInput>;
	notes?: InputMaybe<Array<NoteFilterInput>>;
	or?: InputMaybe<Array<EmployeeFilterInput>>;
	person?: InputMaybe<Array<PersonFilterInput>>;
	personIDEQ?: InputMaybe<Scalars["ID"]>;
	personIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	personIDNEQ?: InputMaybe<Scalars["ID"]>;
	personIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	roleEQ?: InputMaybe<EmployeeRole>;
	roleIn?: InputMaybe<Array<EmployeeRole>>;
	roleNEQ?: InputMaybe<EmployeeRole>;
	roleNotIn?: InputMaybe<Array<EmployeeRole>>;
	search?: InputMaybe<Scalars["String"]>;
	startDateEQ?: InputMaybe<Scalars["Time"]>;
	startDateGT?: InputMaybe<Scalars["Time"]>;
	startDateGTE?: InputMaybe<Scalars["Time"]>;
	startDateIn?: InputMaybe<Array<Scalars["Time"]>>;
	startDateLT?: InputMaybe<Scalars["Time"]>;
	startDateLTE?: InputMaybe<Scalars["Time"]>;
	startDateNEQ?: InputMaybe<Scalars["Time"]>;
	startDateNotIn?: InputMaybe<Array<Scalars["Time"]>>;
};

export type EmployeeHistory = Node & {
	__typename?: "EmployeeHistory";
	email: Maybe<Scalars["String"]>;
	endDate: Maybe<Scalars["Time"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	jobTitle: Maybe<Scalars["String"]>;
	originalID: Maybe<Scalars["ID"]>;
	personID: Maybe<Scalars["ID"]>;
	role: Maybe<EmployeeHistoryRole>;
	startDate: Maybe<Scalars["Time"]>;
};

export type EmployeeHistoryConnection = {
	__typename?: "EmployeeHistoryConnection";
	edges: Array<EmployeeHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type EmployeeHistoryEdge = {
	__typename?: "EmployeeHistoryEdge";
	cursor: Scalars["Cursor"];
	node: EmployeeHistory;
};

export type EmployeeHistoryFilterInput = {
	and?: InputMaybe<Array<EmployeeHistoryFilterInput>>;
	emailContains?: InputMaybe<Scalars["String"]>;
	emailEQ?: InputMaybe<Scalars["String"]>;
	emailHasPrefix?: InputMaybe<Scalars["String"]>;
	emailHasSuffix?: InputMaybe<Scalars["String"]>;
	emailIn?: InputMaybe<Array<Scalars["String"]>>;
	emailIsNull?: InputMaybe<Scalars["Boolean"]>;
	emailNEQ?: InputMaybe<Scalars["String"]>;
	emailNotContains?: InputMaybe<Scalars["String"]>;
	emailNotHasPrefix?: InputMaybe<Scalars["String"]>;
	emailNotHasSuffix?: InputMaybe<Scalars["String"]>;
	emailNotIn?: InputMaybe<Array<Scalars["String"]>>;
	endDateEQ?: InputMaybe<Scalars["Time"]>;
	endDateGT?: InputMaybe<Scalars["Time"]>;
	endDateGTE?: InputMaybe<Scalars["Time"]>;
	endDateIn?: InputMaybe<Array<Scalars["Time"]>>;
	endDateIsNull?: InputMaybe<Scalars["Boolean"]>;
	endDateLT?: InputMaybe<Scalars["Time"]>;
	endDateLTE?: InputMaybe<Scalars["Time"]>;
	endDateNEQ?: InputMaybe<Scalars["Time"]>;
	endDateNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	jobTitleContains?: InputMaybe<Scalars["String"]>;
	jobTitleEQ?: InputMaybe<Scalars["String"]>;
	jobTitleHasPrefix?: InputMaybe<Scalars["String"]>;
	jobTitleHasSuffix?: InputMaybe<Scalars["String"]>;
	jobTitleIn?: InputMaybe<Array<Scalars["String"]>>;
	jobTitleIsNull?: InputMaybe<Scalars["Boolean"]>;
	jobTitleNEQ?: InputMaybe<Scalars["String"]>;
	jobTitleNotContains?: InputMaybe<Scalars["String"]>;
	jobTitleNotHasPrefix?: InputMaybe<Scalars["String"]>;
	jobTitleNotHasSuffix?: InputMaybe<Scalars["String"]>;
	jobTitleNotIn?: InputMaybe<Array<Scalars["String"]>>;
	not?: InputMaybe<EmployeeHistoryFilterInput>;
	or?: InputMaybe<Array<EmployeeHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	personIDEQ?: InputMaybe<Scalars["ID"]>;
	personIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	personIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	personIDNEQ?: InputMaybe<Scalars["ID"]>;
	personIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	roleEQ?: InputMaybe<EmployeeHistoryRole>;
	roleIn?: InputMaybe<Array<EmployeeHistoryRole>>;
	roleIsNull?: InputMaybe<Scalars["Boolean"]>;
	roleNEQ?: InputMaybe<EmployeeHistoryRole>;
	roleNotIn?: InputMaybe<Array<EmployeeHistoryRole>>;
	search?: InputMaybe<Scalars["String"]>;
	startDateEQ?: InputMaybe<Scalars["Time"]>;
	startDateGT?: InputMaybe<Scalars["Time"]>;
	startDateGTE?: InputMaybe<Scalars["Time"]>;
	startDateIn?: InputMaybe<Array<Scalars["Time"]>>;
	startDateIsNull?: InputMaybe<Scalars["Boolean"]>;
	startDateLT?: InputMaybe<Scalars["Time"]>;
	startDateLTE?: InputMaybe<Scalars["Time"]>;
	startDateNEQ?: InputMaybe<Scalars["Time"]>;
	startDateNotIn?: InputMaybe<Array<Scalars["Time"]>>;
};

export type EmployeeHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<EmployeeHistoryOrderField>;
};

export enum EmployeeHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export enum EmployeeHistoryRole {
	EducationAdmin = "education_admin",
	Financial = "financial",
	GeneralAdmin = "general_admin",
	PrincipalAdmin = "principal_admin",
	Teacher = "teacher",
}

export type EmployeeInPersonCreateInput = {
	campusIDs?: InputMaybe<Array<Scalars["ID"]>>;
	courseSectionTeacherEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	courseSectionTeacherEnrollments?: InputMaybe<Array<CourseSectionTeacherEnrollmentInTeacherCreateInput>>;
	email: Scalars["String"];
	endDate?: InputMaybe<Scalars["Time"]>;
	homeRoomSectionTeacherEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	homeRoomSectionTeacherEnrollments?: InputMaybe<Array<HomeRoomSectionTeacherEnrollmentInTeacherCreateInput>>;
	jobTitle: Scalars["String"];
	noteIDs?: InputMaybe<Array<Scalars["ID"]>>;
	role: EmployeeRole;
	startDate: Scalars["Time"];
};

export type EmployeeOrder = {
	direction: OrderDirection;
	field?: InputMaybe<EmployeeOrderField>;
};

export enum EmployeeOrderField {
	Email = "EMAIL",
	EndDate = "END_DATE",
	JobTitle = "JOB_TITLE",
	Role = "ROLE",
	StartDate = "START_DATE",
}

export enum EmployeeRole {
	EducationAdmin = "education_admin",
	Financial = "financial",
	GeneralAdmin = "general_admin",
	PrincipalAdmin = "principal_admin",
	Teacher = "teacher",
}

export type EmployeeUpdateBulkInput = {
	addCampusIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addCourseSectionTeacherEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addHomeRoomSectionTeacherEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addNoteIDs?: InputMaybe<Array<Scalars["ID"]>>;
	clearEndDate?: InputMaybe<Scalars["Boolean"]>;
	email?: InputMaybe<Scalars["String"]>;
	endDate?: InputMaybe<Scalars["Time"]>;
	jobTitle?: InputMaybe<Scalars["String"]>;
	removeCampusIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeCourseSectionTeacherEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeHomeRoomSectionTeacherEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeNoteIDs?: InputMaybe<Array<Scalars["ID"]>>;
	role?: InputMaybe<EmployeeRole>;
	startDate?: InputMaybe<Scalars["Time"]>;
};

export type EmployeeUpdateInput = {
	addCampusIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addCourseSectionTeacherEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addHomeRoomSectionTeacherEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addNoteIDs?: InputMaybe<Array<Scalars["ID"]>>;
	clearEndDate?: InputMaybe<Scalars["Boolean"]>;
	email?: InputMaybe<Scalars["String"]>;
	endDate?: InputMaybe<Scalars["Time"]>;
	jobTitle?: InputMaybe<Scalars["String"]>;
	personID?: InputMaybe<Scalars["ID"]>;
	removeCampusIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeCourseSectionTeacherEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeHomeRoomSectionTeacherEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeNoteIDs?: InputMaybe<Array<Scalars["ID"]>>;
	role?: InputMaybe<EmployeeRole>;
	startDate?: InputMaybe<Scalars["Time"]>;
};

export type EnrollmentApplication = Node & {
	__typename?: "EnrollmentApplication";
	approvedEmailSentAt: Maybe<Scalars["Time"]>;
	createdAt: Scalars["Time"];
	enrollmentApplicationNotes: Array<EnrollmentApplicationNote>;
	enrollmentApplicationStudent: EnrollmentApplicationStudent;
	enrollmentApplicationStudentID: Scalars["ID"];
	enrollmentApplicationUserViewRecords: Array<EnrollmentApplicationUserViewRecord>;
	externalLink: Scalars["String"];
	id: Scalars["ID"];
	interviewMeetingTime: Maybe<Scalars["Time"]>;
	isArchived: Scalars["Boolean"];
	status: EnrollmentApplicationStatus;
	statusUpdatedAt: Scalars["Time"];
};

export type EnrollmentApplicationEnrollmentApplicationNotesArgs = {
	filters?: InputMaybe<Array<EnrollmentApplicationNoteFilterInput>>;
	order?: InputMaybe<EnrollmentApplicationNoteOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type EnrollmentApplicationEnrollmentApplicationUserViewRecordsArgs = {
	filters?: InputMaybe<Array<EnrollmentApplicationUserViewRecordFilterInput>>;
	order?: InputMaybe<EnrollmentApplicationUserViewRecordOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type EnrollmentApplicationConnection = {
	__typename?: "EnrollmentApplicationConnection";
	edges: Array<EnrollmentApplicationEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type EnrollmentApplicationEdge = {
	__typename?: "EnrollmentApplicationEdge";
	cursor: Scalars["Cursor"];
	node: EnrollmentApplication;
};

export type EnrollmentApplicationFilterInput = {
	and?: InputMaybe<Array<EnrollmentApplicationFilterInput>>;
	approvedEmailSentAtEQ?: InputMaybe<Scalars["Time"]>;
	approvedEmailSentAtGT?: InputMaybe<Scalars["Time"]>;
	approvedEmailSentAtGTE?: InputMaybe<Scalars["Time"]>;
	approvedEmailSentAtIn?: InputMaybe<Array<Scalars["Time"]>>;
	approvedEmailSentAtIsNull?: InputMaybe<Scalars["Boolean"]>;
	approvedEmailSentAtLT?: InputMaybe<Scalars["Time"]>;
	approvedEmailSentAtLTE?: InputMaybe<Scalars["Time"]>;
	approvedEmailSentAtNEQ?: InputMaybe<Scalars["Time"]>;
	approvedEmailSentAtNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	createdAtEQ?: InputMaybe<Scalars["Time"]>;
	createdAtGT?: InputMaybe<Scalars["Time"]>;
	createdAtGTE?: InputMaybe<Scalars["Time"]>;
	createdAtIn?: InputMaybe<Array<Scalars["Time"]>>;
	createdAtLT?: InputMaybe<Scalars["Time"]>;
	createdAtLTE?: InputMaybe<Scalars["Time"]>;
	createdAtNEQ?: InputMaybe<Scalars["Time"]>;
	createdAtNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	enrollmentApplicationNotes?: InputMaybe<Array<EnrollmentApplicationNoteFilterInput>>;
	enrollmentApplicationStudent?: InputMaybe<Array<EnrollmentApplicationStudentFilterInput>>;
	enrollmentApplicationStudentIDEQ?: InputMaybe<Scalars["ID"]>;
	enrollmentApplicationStudentIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	enrollmentApplicationStudentIDNEQ?: InputMaybe<Scalars["ID"]>;
	enrollmentApplicationStudentIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	enrollmentApplicationUserViewRecords?: InputMaybe<Array<EnrollmentApplicationUserViewRecordFilterInput>>;
	externalLinkContains?: InputMaybe<Scalars["String"]>;
	externalLinkEQ?: InputMaybe<Scalars["String"]>;
	externalLinkHasPrefix?: InputMaybe<Scalars["String"]>;
	externalLinkHasSuffix?: InputMaybe<Scalars["String"]>;
	externalLinkIn?: InputMaybe<Array<Scalars["String"]>>;
	externalLinkNEQ?: InputMaybe<Scalars["String"]>;
	externalLinkNotContains?: InputMaybe<Scalars["String"]>;
	externalLinkNotHasPrefix?: InputMaybe<Scalars["String"]>;
	externalLinkNotHasSuffix?: InputMaybe<Scalars["String"]>;
	externalLinkNotIn?: InputMaybe<Array<Scalars["String"]>>;
	hasEnrollmentApplicationNotes?: InputMaybe<Scalars["Boolean"]>;
	hasEnrollmentApplicationUserViewRecords?: InputMaybe<Scalars["Boolean"]>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	interviewMeetingTimeEQ?: InputMaybe<Scalars["Time"]>;
	interviewMeetingTimeGT?: InputMaybe<Scalars["Time"]>;
	interviewMeetingTimeGTE?: InputMaybe<Scalars["Time"]>;
	interviewMeetingTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	interviewMeetingTimeIsNull?: InputMaybe<Scalars["Boolean"]>;
	interviewMeetingTimeLT?: InputMaybe<Scalars["Time"]>;
	interviewMeetingTimeLTE?: InputMaybe<Scalars["Time"]>;
	interviewMeetingTimeNEQ?: InputMaybe<Scalars["Time"]>;
	interviewMeetingTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	isArchivedEQ?: InputMaybe<Scalars["Boolean"]>;
	isArchivedNEQ?: InputMaybe<Scalars["Boolean"]>;
	not?: InputMaybe<EnrollmentApplicationFilterInput>;
	or?: InputMaybe<Array<EnrollmentApplicationFilterInput>>;
	search?: InputMaybe<Scalars["String"]>;
	statusEQ?: InputMaybe<EnrollmentApplicationStatus>;
	statusIn?: InputMaybe<Array<EnrollmentApplicationStatus>>;
	statusNEQ?: InputMaybe<EnrollmentApplicationStatus>;
	statusNotIn?: InputMaybe<Array<EnrollmentApplicationStatus>>;
	statusUpdatedAtEQ?: InputMaybe<Scalars["Time"]>;
	statusUpdatedAtGT?: InputMaybe<Scalars["Time"]>;
	statusUpdatedAtGTE?: InputMaybe<Scalars["Time"]>;
	statusUpdatedAtIn?: InputMaybe<Array<Scalars["Time"]>>;
	statusUpdatedAtLT?: InputMaybe<Scalars["Time"]>;
	statusUpdatedAtLTE?: InputMaybe<Scalars["Time"]>;
	statusUpdatedAtNEQ?: InputMaybe<Scalars["Time"]>;
	statusUpdatedAtNotIn?: InputMaybe<Array<Scalars["Time"]>>;
};

export type EnrollmentApplicationHistory = Node & {
	__typename?: "EnrollmentApplicationHistory";
	approvedEmailSentAt: Maybe<Scalars["Time"]>;
	createdAt: Maybe<Scalars["Time"]>;
	enrollmentApplicationStudentID: Maybe<Scalars["ID"]>;
	externalLink: Maybe<Scalars["String"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	interviewMeetingTime: Maybe<Scalars["Time"]>;
	isArchived: Maybe<Scalars["Boolean"]>;
	originalID: Maybe<Scalars["ID"]>;
	status: Maybe<EnrollmentApplicationHistoryStatus>;
	statusUpdatedAt: Maybe<Scalars["Time"]>;
};

export type EnrollmentApplicationHistoryConnection = {
	__typename?: "EnrollmentApplicationHistoryConnection";
	edges: Array<EnrollmentApplicationHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type EnrollmentApplicationHistoryEdge = {
	__typename?: "EnrollmentApplicationHistoryEdge";
	cursor: Scalars["Cursor"];
	node: EnrollmentApplicationHistory;
};

export type EnrollmentApplicationHistoryFilterInput = {
	and?: InputMaybe<Array<EnrollmentApplicationHistoryFilterInput>>;
	approvedEmailSentAtEQ?: InputMaybe<Scalars["Time"]>;
	approvedEmailSentAtGT?: InputMaybe<Scalars["Time"]>;
	approvedEmailSentAtGTE?: InputMaybe<Scalars["Time"]>;
	approvedEmailSentAtIn?: InputMaybe<Array<Scalars["Time"]>>;
	approvedEmailSentAtIsNull?: InputMaybe<Scalars["Boolean"]>;
	approvedEmailSentAtLT?: InputMaybe<Scalars["Time"]>;
	approvedEmailSentAtLTE?: InputMaybe<Scalars["Time"]>;
	approvedEmailSentAtNEQ?: InputMaybe<Scalars["Time"]>;
	approvedEmailSentAtNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	createdAtEQ?: InputMaybe<Scalars["Time"]>;
	createdAtGT?: InputMaybe<Scalars["Time"]>;
	createdAtGTE?: InputMaybe<Scalars["Time"]>;
	createdAtIn?: InputMaybe<Array<Scalars["Time"]>>;
	createdAtIsNull?: InputMaybe<Scalars["Boolean"]>;
	createdAtLT?: InputMaybe<Scalars["Time"]>;
	createdAtLTE?: InputMaybe<Scalars["Time"]>;
	createdAtNEQ?: InputMaybe<Scalars["Time"]>;
	createdAtNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	enrollmentApplicationStudentIDEQ?: InputMaybe<Scalars["ID"]>;
	enrollmentApplicationStudentIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	enrollmentApplicationStudentIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	enrollmentApplicationStudentIDNEQ?: InputMaybe<Scalars["ID"]>;
	enrollmentApplicationStudentIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	externalLinkContains?: InputMaybe<Scalars["String"]>;
	externalLinkEQ?: InputMaybe<Scalars["String"]>;
	externalLinkHasPrefix?: InputMaybe<Scalars["String"]>;
	externalLinkHasSuffix?: InputMaybe<Scalars["String"]>;
	externalLinkIn?: InputMaybe<Array<Scalars["String"]>>;
	externalLinkIsNull?: InputMaybe<Scalars["Boolean"]>;
	externalLinkNEQ?: InputMaybe<Scalars["String"]>;
	externalLinkNotContains?: InputMaybe<Scalars["String"]>;
	externalLinkNotHasPrefix?: InputMaybe<Scalars["String"]>;
	externalLinkNotHasSuffix?: InputMaybe<Scalars["String"]>;
	externalLinkNotIn?: InputMaybe<Array<Scalars["String"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	interviewMeetingTimeEQ?: InputMaybe<Scalars["Time"]>;
	interviewMeetingTimeGT?: InputMaybe<Scalars["Time"]>;
	interviewMeetingTimeGTE?: InputMaybe<Scalars["Time"]>;
	interviewMeetingTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	interviewMeetingTimeIsNull?: InputMaybe<Scalars["Boolean"]>;
	interviewMeetingTimeLT?: InputMaybe<Scalars["Time"]>;
	interviewMeetingTimeLTE?: InputMaybe<Scalars["Time"]>;
	interviewMeetingTimeNEQ?: InputMaybe<Scalars["Time"]>;
	interviewMeetingTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	isArchivedEQ?: InputMaybe<Scalars["Boolean"]>;
	isArchivedIsNull?: InputMaybe<Scalars["Boolean"]>;
	isArchivedNEQ?: InputMaybe<Scalars["Boolean"]>;
	not?: InputMaybe<EnrollmentApplicationHistoryFilterInput>;
	or?: InputMaybe<Array<EnrollmentApplicationHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	search?: InputMaybe<Scalars["String"]>;
	statusEQ?: InputMaybe<EnrollmentApplicationHistoryStatus>;
	statusIn?: InputMaybe<Array<EnrollmentApplicationHistoryStatus>>;
	statusIsNull?: InputMaybe<Scalars["Boolean"]>;
	statusNEQ?: InputMaybe<EnrollmentApplicationHistoryStatus>;
	statusNotIn?: InputMaybe<Array<EnrollmentApplicationHistoryStatus>>;
	statusUpdatedAtEQ?: InputMaybe<Scalars["Time"]>;
	statusUpdatedAtGT?: InputMaybe<Scalars["Time"]>;
	statusUpdatedAtGTE?: InputMaybe<Scalars["Time"]>;
	statusUpdatedAtIn?: InputMaybe<Array<Scalars["Time"]>>;
	statusUpdatedAtIsNull?: InputMaybe<Scalars["Boolean"]>;
	statusUpdatedAtLT?: InputMaybe<Scalars["Time"]>;
	statusUpdatedAtLTE?: InputMaybe<Scalars["Time"]>;
	statusUpdatedAtNEQ?: InputMaybe<Scalars["Time"]>;
	statusUpdatedAtNotIn?: InputMaybe<Array<Scalars["Time"]>>;
};

export type EnrollmentApplicationHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<EnrollmentApplicationHistoryOrderField>;
};

export enum EnrollmentApplicationHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export enum EnrollmentApplicationHistoryStatus {
	Approved = "approved",
	Pending = "pending",
	Rejected = "rejected",
}

export type EnrollmentApplicationNote = Node & {
	__typename?: "EnrollmentApplicationNote";
	author: User;
	authorID: Scalars["ID"];
	body: Scalars["String"];
	createdAt: Scalars["Time"];
	enrollmentApplication: EnrollmentApplication;
	enrollmentApplicationID: Scalars["ID"];
	id: Scalars["ID"];
};

export type EnrollmentApplicationNoteConnection = {
	__typename?: "EnrollmentApplicationNoteConnection";
	edges: Array<EnrollmentApplicationNoteEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type EnrollmentApplicationNoteCreateInput = {
	body: Scalars["String"];
	enrollmentApplicationID: Scalars["ID"];
};

export type EnrollmentApplicationNoteEdge = {
	__typename?: "EnrollmentApplicationNoteEdge";
	cursor: Scalars["Cursor"];
	node: EnrollmentApplicationNote;
};

export type EnrollmentApplicationNoteFilterInput = {
	and?: InputMaybe<Array<EnrollmentApplicationNoteFilterInput>>;
	author?: InputMaybe<Array<UserFilterInput>>;
	authorIDEQ?: InputMaybe<Scalars["ID"]>;
	authorIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	authorIDNEQ?: InputMaybe<Scalars["ID"]>;
	authorIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	bodyContains?: InputMaybe<Scalars["String"]>;
	bodyEQ?: InputMaybe<Scalars["String"]>;
	bodyHasPrefix?: InputMaybe<Scalars["String"]>;
	bodyHasSuffix?: InputMaybe<Scalars["String"]>;
	bodyIn?: InputMaybe<Array<Scalars["String"]>>;
	bodyNEQ?: InputMaybe<Scalars["String"]>;
	bodyNotContains?: InputMaybe<Scalars["String"]>;
	bodyNotHasPrefix?: InputMaybe<Scalars["String"]>;
	bodyNotHasSuffix?: InputMaybe<Scalars["String"]>;
	bodyNotIn?: InputMaybe<Array<Scalars["String"]>>;
	createdAtEQ?: InputMaybe<Scalars["Time"]>;
	createdAtGT?: InputMaybe<Scalars["Time"]>;
	createdAtGTE?: InputMaybe<Scalars["Time"]>;
	createdAtIn?: InputMaybe<Array<Scalars["Time"]>>;
	createdAtLT?: InputMaybe<Scalars["Time"]>;
	createdAtLTE?: InputMaybe<Scalars["Time"]>;
	createdAtNEQ?: InputMaybe<Scalars["Time"]>;
	createdAtNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	enrollmentApplication?: InputMaybe<Array<EnrollmentApplicationFilterInput>>;
	enrollmentApplicationIDEQ?: InputMaybe<Scalars["ID"]>;
	enrollmentApplicationIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	enrollmentApplicationIDNEQ?: InputMaybe<Scalars["ID"]>;
	enrollmentApplicationIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<EnrollmentApplicationNoteFilterInput>;
	or?: InputMaybe<Array<EnrollmentApplicationNoteFilterInput>>;
	search?: InputMaybe<Scalars["String"]>;
};

export type EnrollmentApplicationNoteHistory = Node & {
	__typename?: "EnrollmentApplicationNoteHistory";
	authorID: Maybe<Scalars["ID"]>;
	body: Maybe<Scalars["String"]>;
	createdAt: Maybe<Scalars["Time"]>;
	enrollmentApplicationID: Maybe<Scalars["ID"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	originalID: Maybe<Scalars["ID"]>;
};

export type EnrollmentApplicationNoteHistoryConnection = {
	__typename?: "EnrollmentApplicationNoteHistoryConnection";
	edges: Array<EnrollmentApplicationNoteHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type EnrollmentApplicationNoteHistoryEdge = {
	__typename?: "EnrollmentApplicationNoteHistoryEdge";
	cursor: Scalars["Cursor"];
	node: EnrollmentApplicationNoteHistory;
};

export type EnrollmentApplicationNoteHistoryFilterInput = {
	and?: InputMaybe<Array<EnrollmentApplicationNoteHistoryFilterInput>>;
	authorIDEQ?: InputMaybe<Scalars["ID"]>;
	authorIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	authorIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	authorIDNEQ?: InputMaybe<Scalars["ID"]>;
	authorIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	bodyContains?: InputMaybe<Scalars["String"]>;
	bodyEQ?: InputMaybe<Scalars["String"]>;
	bodyHasPrefix?: InputMaybe<Scalars["String"]>;
	bodyHasSuffix?: InputMaybe<Scalars["String"]>;
	bodyIn?: InputMaybe<Array<Scalars["String"]>>;
	bodyIsNull?: InputMaybe<Scalars["Boolean"]>;
	bodyNEQ?: InputMaybe<Scalars["String"]>;
	bodyNotContains?: InputMaybe<Scalars["String"]>;
	bodyNotHasPrefix?: InputMaybe<Scalars["String"]>;
	bodyNotHasSuffix?: InputMaybe<Scalars["String"]>;
	bodyNotIn?: InputMaybe<Array<Scalars["String"]>>;
	createdAtEQ?: InputMaybe<Scalars["Time"]>;
	createdAtGT?: InputMaybe<Scalars["Time"]>;
	createdAtGTE?: InputMaybe<Scalars["Time"]>;
	createdAtIn?: InputMaybe<Array<Scalars["Time"]>>;
	createdAtIsNull?: InputMaybe<Scalars["Boolean"]>;
	createdAtLT?: InputMaybe<Scalars["Time"]>;
	createdAtLTE?: InputMaybe<Scalars["Time"]>;
	createdAtNEQ?: InputMaybe<Scalars["Time"]>;
	createdAtNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	enrollmentApplicationIDEQ?: InputMaybe<Scalars["ID"]>;
	enrollmentApplicationIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	enrollmentApplicationIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	enrollmentApplicationIDNEQ?: InputMaybe<Scalars["ID"]>;
	enrollmentApplicationIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<EnrollmentApplicationNoteHistoryFilterInput>;
	or?: InputMaybe<Array<EnrollmentApplicationNoteHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	search?: InputMaybe<Scalars["String"]>;
};

export type EnrollmentApplicationNoteHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<EnrollmentApplicationNoteHistoryOrderField>;
};

export enum EnrollmentApplicationNoteHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type EnrollmentApplicationNoteInAuthorCreateInput = {
	body: Scalars["String"];
	enrollmentApplicationID: Scalars["ID"];
};

export type EnrollmentApplicationNoteOrder = {
	direction: OrderDirection;
	field?: InputMaybe<EnrollmentApplicationNoteOrderField>;
};

export enum EnrollmentApplicationNoteOrderField {
	CreatedAt = "CREATED_AT",
}

export type EnrollmentApplicationNoteUpdateBulkInput = {
	body?: InputMaybe<Scalars["String"]>;
	enrollmentApplicationID?: InputMaybe<Scalars["ID"]>;
};

export type EnrollmentApplicationNoteUpdateInput = {
	body?: InputMaybe<Scalars["String"]>;
	enrollmentApplicationID?: InputMaybe<Scalars["ID"]>;
};

export type EnrollmentApplicationOrder = {
	direction: OrderDirection;
	field?: InputMaybe<EnrollmentApplicationOrderField>;
};

export enum EnrollmentApplicationOrderField {
	CreatedAt = "CREATED_AT",
	InterviewMeetingTime = "INTERVIEW_MEETING_TIME",
	IsArchived = "IS_ARCHIVED",
	Status = "STATUS",
	StatusUpdatedAt = "STATUS_UPDATED_AT",
}

export type EnrollmentApplicationParent = Node & {
	__typename?: "EnrollmentApplicationParent";
	addressLine1: Scalars["String"];
	addressLine2: Scalars["String"];
	addressSameAsFormIdentifier: Scalars["String"];
	city: Scalars["String"];
	country: Scalars["String"];
	email: Scalars["String"];
	enrollmentApplicationSubmission: EnrollmentApplicationSubmission;
	enrollmentApplicationSubmissionID: Scalars["ID"];
	firstName: Scalars["String"];
	formIdentifier: Scalars["String"];
	homePhone: Scalars["String"];
	id: Scalars["ID"];
	isAuthorizedForPickup: Scalars["Boolean"];
	isEmergencyContact: Scalars["Boolean"];
	isFinancialContact: Scalars["Boolean"];
	isPrimaryContact: Scalars["Boolean"];
	lastName: Scalars["String"];
	livesWithStudent: Scalars["Boolean"];
	mobilePhone: Scalars["String"];
	name: Scalars["String"];
	nationality: Scalars["String"];
	occupation: Scalars["String"];
	parent: Maybe<Parent>;
	parentID: Maybe<Scalars["ID"]>;
	postalCode: Scalars["String"];
	relationshipType: EnrollmentApplicationParentRelationshipType;
	relationshipTypeCustom: Scalars["String"];
	stateOrProvince: Scalars["String"];
	workPhone: Scalars["String"];
};

export type EnrollmentApplicationParentConnection = {
	__typename?: "EnrollmentApplicationParentConnection";
	edges: Array<EnrollmentApplicationParentEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type EnrollmentApplicationParentCreateInput = {
	addressLine1?: InputMaybe<Scalars["String"]>;
	addressLine2?: InputMaybe<Scalars["String"]>;
	addressSameAsFormIdentifier?: InputMaybe<Scalars["String"]>;
	city?: InputMaybe<Scalars["String"]>;
	country?: InputMaybe<Scalars["String"]>;
	email: Scalars["String"];
	enrollmentApplicationSubmissionID: Scalars["ID"];
	firstName: Scalars["String"];
	formIdentifier: Scalars["String"];
	homePhone?: InputMaybe<Scalars["String"]>;
	isAuthorizedForPickup?: InputMaybe<Scalars["Boolean"]>;
	isEmergencyContact?: InputMaybe<Scalars["Boolean"]>;
	isFinancialContact?: InputMaybe<Scalars["Boolean"]>;
	isPrimaryContact?: InputMaybe<Scalars["Boolean"]>;
	lastName: Scalars["String"];
	livesWithStudent?: InputMaybe<Scalars["Boolean"]>;
	mobilePhone?: InputMaybe<Scalars["String"]>;
	nationality?: InputMaybe<Scalars["String"]>;
	occupation?: InputMaybe<Scalars["String"]>;
	parent?: InputMaybe<ParentInEnrollmentApplicationParentCreateInput>;
	parentID?: InputMaybe<Scalars["ID"]>;
	postalCode?: InputMaybe<Scalars["String"]>;
	relationshipType: EnrollmentApplicationParentRelationshipType;
	relationshipTypeCustom?: InputMaybe<Scalars["String"]>;
	stateOrProvince?: InputMaybe<Scalars["String"]>;
	workPhone?: InputMaybe<Scalars["String"]>;
};

export type EnrollmentApplicationParentEdge = {
	__typename?: "EnrollmentApplicationParentEdge";
	cursor: Scalars["Cursor"];
	node: EnrollmentApplicationParent;
};

export type EnrollmentApplicationParentFilterInput = {
	addressLine1Contains?: InputMaybe<Scalars["String"]>;
	addressLine1EQ?: InputMaybe<Scalars["String"]>;
	addressLine1HasPrefix?: InputMaybe<Scalars["String"]>;
	addressLine1HasSuffix?: InputMaybe<Scalars["String"]>;
	addressLine1In?: InputMaybe<Array<Scalars["String"]>>;
	addressLine1NEQ?: InputMaybe<Scalars["String"]>;
	addressLine1NotContains?: InputMaybe<Scalars["String"]>;
	addressLine1NotHasPrefix?: InputMaybe<Scalars["String"]>;
	addressLine1NotHasSuffix?: InputMaybe<Scalars["String"]>;
	addressLine1NotIn?: InputMaybe<Array<Scalars["String"]>>;
	addressLine2Contains?: InputMaybe<Scalars["String"]>;
	addressLine2EQ?: InputMaybe<Scalars["String"]>;
	addressLine2HasPrefix?: InputMaybe<Scalars["String"]>;
	addressLine2HasSuffix?: InputMaybe<Scalars["String"]>;
	addressLine2In?: InputMaybe<Array<Scalars["String"]>>;
	addressLine2NEQ?: InputMaybe<Scalars["String"]>;
	addressLine2NotContains?: InputMaybe<Scalars["String"]>;
	addressLine2NotHasPrefix?: InputMaybe<Scalars["String"]>;
	addressLine2NotHasSuffix?: InputMaybe<Scalars["String"]>;
	addressLine2NotIn?: InputMaybe<Array<Scalars["String"]>>;
	addressSameAsFormIdentifierContains?: InputMaybe<Scalars["String"]>;
	addressSameAsFormIdentifierEQ?: InputMaybe<Scalars["String"]>;
	addressSameAsFormIdentifierHasPrefix?: InputMaybe<Scalars["String"]>;
	addressSameAsFormIdentifierHasSuffix?: InputMaybe<Scalars["String"]>;
	addressSameAsFormIdentifierIn?: InputMaybe<Array<Scalars["String"]>>;
	addressSameAsFormIdentifierNEQ?: InputMaybe<Scalars["String"]>;
	addressSameAsFormIdentifierNotContains?: InputMaybe<Scalars["String"]>;
	addressSameAsFormIdentifierNotHasPrefix?: InputMaybe<Scalars["String"]>;
	addressSameAsFormIdentifierNotHasSuffix?: InputMaybe<Scalars["String"]>;
	addressSameAsFormIdentifierNotIn?: InputMaybe<Array<Scalars["String"]>>;
	and?: InputMaybe<Array<EnrollmentApplicationParentFilterInput>>;
	cityContains?: InputMaybe<Scalars["String"]>;
	cityEQ?: InputMaybe<Scalars["String"]>;
	cityHasPrefix?: InputMaybe<Scalars["String"]>;
	cityHasSuffix?: InputMaybe<Scalars["String"]>;
	cityIn?: InputMaybe<Array<Scalars["String"]>>;
	cityNEQ?: InputMaybe<Scalars["String"]>;
	cityNotContains?: InputMaybe<Scalars["String"]>;
	cityNotHasPrefix?: InputMaybe<Scalars["String"]>;
	cityNotHasSuffix?: InputMaybe<Scalars["String"]>;
	cityNotIn?: InputMaybe<Array<Scalars["String"]>>;
	countryContains?: InputMaybe<Scalars["String"]>;
	countryEQ?: InputMaybe<Scalars["String"]>;
	countryHasPrefix?: InputMaybe<Scalars["String"]>;
	countryHasSuffix?: InputMaybe<Scalars["String"]>;
	countryIn?: InputMaybe<Array<Scalars["String"]>>;
	countryNEQ?: InputMaybe<Scalars["String"]>;
	countryNotContains?: InputMaybe<Scalars["String"]>;
	countryNotHasPrefix?: InputMaybe<Scalars["String"]>;
	countryNotHasSuffix?: InputMaybe<Scalars["String"]>;
	countryNotIn?: InputMaybe<Array<Scalars["String"]>>;
	emailContains?: InputMaybe<Scalars["String"]>;
	emailEQ?: InputMaybe<Scalars["String"]>;
	emailHasPrefix?: InputMaybe<Scalars["String"]>;
	emailHasSuffix?: InputMaybe<Scalars["String"]>;
	emailIn?: InputMaybe<Array<Scalars["String"]>>;
	emailNEQ?: InputMaybe<Scalars["String"]>;
	emailNotContains?: InputMaybe<Scalars["String"]>;
	emailNotHasPrefix?: InputMaybe<Scalars["String"]>;
	emailNotHasSuffix?: InputMaybe<Scalars["String"]>;
	emailNotIn?: InputMaybe<Array<Scalars["String"]>>;
	enrollmentApplicationSubmission?: InputMaybe<Array<EnrollmentApplicationSubmissionFilterInput>>;
	enrollmentApplicationSubmissionIDEQ?: InputMaybe<Scalars["ID"]>;
	enrollmentApplicationSubmissionIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	enrollmentApplicationSubmissionIDNEQ?: InputMaybe<Scalars["ID"]>;
	enrollmentApplicationSubmissionIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	firstNameContains?: InputMaybe<Scalars["String"]>;
	firstNameEQ?: InputMaybe<Scalars["String"]>;
	firstNameHasPrefix?: InputMaybe<Scalars["String"]>;
	firstNameHasSuffix?: InputMaybe<Scalars["String"]>;
	firstNameIn?: InputMaybe<Array<Scalars["String"]>>;
	firstNameNEQ?: InputMaybe<Scalars["String"]>;
	firstNameNotContains?: InputMaybe<Scalars["String"]>;
	firstNameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	firstNameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	firstNameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	formIdentifierContains?: InputMaybe<Scalars["String"]>;
	formIdentifierEQ?: InputMaybe<Scalars["String"]>;
	formIdentifierHasPrefix?: InputMaybe<Scalars["String"]>;
	formIdentifierHasSuffix?: InputMaybe<Scalars["String"]>;
	formIdentifierIn?: InputMaybe<Array<Scalars["String"]>>;
	formIdentifierNEQ?: InputMaybe<Scalars["String"]>;
	formIdentifierNotContains?: InputMaybe<Scalars["String"]>;
	formIdentifierNotHasPrefix?: InputMaybe<Scalars["String"]>;
	formIdentifierNotHasSuffix?: InputMaybe<Scalars["String"]>;
	formIdentifierNotIn?: InputMaybe<Array<Scalars["String"]>>;
	hasParent?: InputMaybe<Scalars["Boolean"]>;
	homePhoneContains?: InputMaybe<Scalars["String"]>;
	homePhoneEQ?: InputMaybe<Scalars["String"]>;
	homePhoneHasPrefix?: InputMaybe<Scalars["String"]>;
	homePhoneHasSuffix?: InputMaybe<Scalars["String"]>;
	homePhoneIn?: InputMaybe<Array<Scalars["String"]>>;
	homePhoneNEQ?: InputMaybe<Scalars["String"]>;
	homePhoneNotContains?: InputMaybe<Scalars["String"]>;
	homePhoneNotHasPrefix?: InputMaybe<Scalars["String"]>;
	homePhoneNotHasSuffix?: InputMaybe<Scalars["String"]>;
	homePhoneNotIn?: InputMaybe<Array<Scalars["String"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	isAuthorizedForPickupEQ?: InputMaybe<Scalars["Boolean"]>;
	isAuthorizedForPickupNEQ?: InputMaybe<Scalars["Boolean"]>;
	isEmergencyContactEQ?: InputMaybe<Scalars["Boolean"]>;
	isEmergencyContactNEQ?: InputMaybe<Scalars["Boolean"]>;
	isFinancialContactEQ?: InputMaybe<Scalars["Boolean"]>;
	isFinancialContactNEQ?: InputMaybe<Scalars["Boolean"]>;
	isPrimaryContactEQ?: InputMaybe<Scalars["Boolean"]>;
	isPrimaryContactNEQ?: InputMaybe<Scalars["Boolean"]>;
	lastNameContains?: InputMaybe<Scalars["String"]>;
	lastNameEQ?: InputMaybe<Scalars["String"]>;
	lastNameHasPrefix?: InputMaybe<Scalars["String"]>;
	lastNameHasSuffix?: InputMaybe<Scalars["String"]>;
	lastNameIn?: InputMaybe<Array<Scalars["String"]>>;
	lastNameNEQ?: InputMaybe<Scalars["String"]>;
	lastNameNotContains?: InputMaybe<Scalars["String"]>;
	lastNameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	lastNameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	lastNameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	livesWithStudentEQ?: InputMaybe<Scalars["Boolean"]>;
	livesWithStudentNEQ?: InputMaybe<Scalars["Boolean"]>;
	mobilePhoneContains?: InputMaybe<Scalars["String"]>;
	mobilePhoneEQ?: InputMaybe<Scalars["String"]>;
	mobilePhoneHasPrefix?: InputMaybe<Scalars["String"]>;
	mobilePhoneHasSuffix?: InputMaybe<Scalars["String"]>;
	mobilePhoneIn?: InputMaybe<Array<Scalars["String"]>>;
	mobilePhoneNEQ?: InputMaybe<Scalars["String"]>;
	mobilePhoneNotContains?: InputMaybe<Scalars["String"]>;
	mobilePhoneNotHasPrefix?: InputMaybe<Scalars["String"]>;
	mobilePhoneNotHasSuffix?: InputMaybe<Scalars["String"]>;
	mobilePhoneNotIn?: InputMaybe<Array<Scalars["String"]>>;
	nationalityContains?: InputMaybe<Scalars["String"]>;
	nationalityEQ?: InputMaybe<Scalars["String"]>;
	nationalityHasPrefix?: InputMaybe<Scalars["String"]>;
	nationalityHasSuffix?: InputMaybe<Scalars["String"]>;
	nationalityIn?: InputMaybe<Array<Scalars["String"]>>;
	nationalityNEQ?: InputMaybe<Scalars["String"]>;
	nationalityNotContains?: InputMaybe<Scalars["String"]>;
	nationalityNotHasPrefix?: InputMaybe<Scalars["String"]>;
	nationalityNotHasSuffix?: InputMaybe<Scalars["String"]>;
	nationalityNotIn?: InputMaybe<Array<Scalars["String"]>>;
	not?: InputMaybe<EnrollmentApplicationParentFilterInput>;
	occupationContains?: InputMaybe<Scalars["String"]>;
	occupationEQ?: InputMaybe<Scalars["String"]>;
	occupationHasPrefix?: InputMaybe<Scalars["String"]>;
	occupationHasSuffix?: InputMaybe<Scalars["String"]>;
	occupationIn?: InputMaybe<Array<Scalars["String"]>>;
	occupationNEQ?: InputMaybe<Scalars["String"]>;
	occupationNotContains?: InputMaybe<Scalars["String"]>;
	occupationNotHasPrefix?: InputMaybe<Scalars["String"]>;
	occupationNotHasSuffix?: InputMaybe<Scalars["String"]>;
	occupationNotIn?: InputMaybe<Array<Scalars["String"]>>;
	or?: InputMaybe<Array<EnrollmentApplicationParentFilterInput>>;
	parent?: InputMaybe<Array<ParentFilterInput>>;
	parentIDEQ?: InputMaybe<Scalars["ID"]>;
	parentIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	parentIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	parentIDNEQ?: InputMaybe<Scalars["ID"]>;
	parentIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	postalCodeContains?: InputMaybe<Scalars["String"]>;
	postalCodeEQ?: InputMaybe<Scalars["String"]>;
	postalCodeHasPrefix?: InputMaybe<Scalars["String"]>;
	postalCodeHasSuffix?: InputMaybe<Scalars["String"]>;
	postalCodeIn?: InputMaybe<Array<Scalars["String"]>>;
	postalCodeNEQ?: InputMaybe<Scalars["String"]>;
	postalCodeNotContains?: InputMaybe<Scalars["String"]>;
	postalCodeNotHasPrefix?: InputMaybe<Scalars["String"]>;
	postalCodeNotHasSuffix?: InputMaybe<Scalars["String"]>;
	postalCodeNotIn?: InputMaybe<Array<Scalars["String"]>>;
	relationshipTypeCustomContains?: InputMaybe<Scalars["String"]>;
	relationshipTypeCustomEQ?: InputMaybe<Scalars["String"]>;
	relationshipTypeCustomHasPrefix?: InputMaybe<Scalars["String"]>;
	relationshipTypeCustomHasSuffix?: InputMaybe<Scalars["String"]>;
	relationshipTypeCustomIn?: InputMaybe<Array<Scalars["String"]>>;
	relationshipTypeCustomNEQ?: InputMaybe<Scalars["String"]>;
	relationshipTypeCustomNotContains?: InputMaybe<Scalars["String"]>;
	relationshipTypeCustomNotHasPrefix?: InputMaybe<Scalars["String"]>;
	relationshipTypeCustomNotHasSuffix?: InputMaybe<Scalars["String"]>;
	relationshipTypeCustomNotIn?: InputMaybe<Array<Scalars["String"]>>;
	relationshipTypeEQ?: InputMaybe<EnrollmentApplicationParentRelationshipType>;
	relationshipTypeIn?: InputMaybe<Array<EnrollmentApplicationParentRelationshipType>>;
	relationshipTypeNEQ?: InputMaybe<EnrollmentApplicationParentRelationshipType>;
	relationshipTypeNotIn?: InputMaybe<Array<EnrollmentApplicationParentRelationshipType>>;
	search?: InputMaybe<Scalars["String"]>;
	stateOrProvinceContains?: InputMaybe<Scalars["String"]>;
	stateOrProvinceEQ?: InputMaybe<Scalars["String"]>;
	stateOrProvinceHasPrefix?: InputMaybe<Scalars["String"]>;
	stateOrProvinceHasSuffix?: InputMaybe<Scalars["String"]>;
	stateOrProvinceIn?: InputMaybe<Array<Scalars["String"]>>;
	stateOrProvinceNEQ?: InputMaybe<Scalars["String"]>;
	stateOrProvinceNotContains?: InputMaybe<Scalars["String"]>;
	stateOrProvinceNotHasPrefix?: InputMaybe<Scalars["String"]>;
	stateOrProvinceNotHasSuffix?: InputMaybe<Scalars["String"]>;
	stateOrProvinceNotIn?: InputMaybe<Array<Scalars["String"]>>;
	workPhoneContains?: InputMaybe<Scalars["String"]>;
	workPhoneEQ?: InputMaybe<Scalars["String"]>;
	workPhoneHasPrefix?: InputMaybe<Scalars["String"]>;
	workPhoneHasSuffix?: InputMaybe<Scalars["String"]>;
	workPhoneIn?: InputMaybe<Array<Scalars["String"]>>;
	workPhoneNEQ?: InputMaybe<Scalars["String"]>;
	workPhoneNotContains?: InputMaybe<Scalars["String"]>;
	workPhoneNotHasPrefix?: InputMaybe<Scalars["String"]>;
	workPhoneNotHasSuffix?: InputMaybe<Scalars["String"]>;
	workPhoneNotIn?: InputMaybe<Array<Scalars["String"]>>;
};

export type EnrollmentApplicationParentHistory = Node & {
	__typename?: "EnrollmentApplicationParentHistory";
	addressLine1: Maybe<Scalars["String"]>;
	addressLine2: Maybe<Scalars["String"]>;
	addressSameAsFormIdentifier: Maybe<Scalars["String"]>;
	city: Maybe<Scalars["String"]>;
	country: Maybe<Scalars["String"]>;
	email: Maybe<Scalars["String"]>;
	enrollmentApplicationSubmissionID: Maybe<Scalars["ID"]>;
	firstName: Maybe<Scalars["String"]>;
	formIdentifier: Maybe<Scalars["String"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	homePhone: Maybe<Scalars["String"]>;
	id: Scalars["ID"];
	isAuthorizedForPickup: Maybe<Scalars["Boolean"]>;
	isEmergencyContact: Maybe<Scalars["Boolean"]>;
	isFinancialContact: Maybe<Scalars["Boolean"]>;
	isPrimaryContact: Maybe<Scalars["Boolean"]>;
	lastName: Maybe<Scalars["String"]>;
	livesWithStudent: Maybe<Scalars["Boolean"]>;
	mobilePhone: Maybe<Scalars["String"]>;
	nationality: Maybe<Scalars["String"]>;
	occupation: Maybe<Scalars["String"]>;
	originalID: Maybe<Scalars["ID"]>;
	parentID: Maybe<Scalars["ID"]>;
	postalCode: Maybe<Scalars["String"]>;
	relationshipType: Maybe<EnrollmentApplicationParentHistoryRelationshipType>;
	relationshipTypeCustom: Maybe<Scalars["String"]>;
	stateOrProvince: Maybe<Scalars["String"]>;
	workPhone: Maybe<Scalars["String"]>;
};

export type EnrollmentApplicationParentHistoryConnection = {
	__typename?: "EnrollmentApplicationParentHistoryConnection";
	edges: Array<EnrollmentApplicationParentHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type EnrollmentApplicationParentHistoryEdge = {
	__typename?: "EnrollmentApplicationParentHistoryEdge";
	cursor: Scalars["Cursor"];
	node: EnrollmentApplicationParentHistory;
};

export type EnrollmentApplicationParentHistoryFilterInput = {
	addressLine1Contains?: InputMaybe<Scalars["String"]>;
	addressLine1EQ?: InputMaybe<Scalars["String"]>;
	addressLine1HasPrefix?: InputMaybe<Scalars["String"]>;
	addressLine1HasSuffix?: InputMaybe<Scalars["String"]>;
	addressLine1In?: InputMaybe<Array<Scalars["String"]>>;
	addressLine1IsNull?: InputMaybe<Scalars["Boolean"]>;
	addressLine1NEQ?: InputMaybe<Scalars["String"]>;
	addressLine1NotContains?: InputMaybe<Scalars["String"]>;
	addressLine1NotHasPrefix?: InputMaybe<Scalars["String"]>;
	addressLine1NotHasSuffix?: InputMaybe<Scalars["String"]>;
	addressLine1NotIn?: InputMaybe<Array<Scalars["String"]>>;
	addressLine2Contains?: InputMaybe<Scalars["String"]>;
	addressLine2EQ?: InputMaybe<Scalars["String"]>;
	addressLine2HasPrefix?: InputMaybe<Scalars["String"]>;
	addressLine2HasSuffix?: InputMaybe<Scalars["String"]>;
	addressLine2In?: InputMaybe<Array<Scalars["String"]>>;
	addressLine2IsNull?: InputMaybe<Scalars["Boolean"]>;
	addressLine2NEQ?: InputMaybe<Scalars["String"]>;
	addressLine2NotContains?: InputMaybe<Scalars["String"]>;
	addressLine2NotHasPrefix?: InputMaybe<Scalars["String"]>;
	addressLine2NotHasSuffix?: InputMaybe<Scalars["String"]>;
	addressLine2NotIn?: InputMaybe<Array<Scalars["String"]>>;
	addressSameAsFormIdentifierContains?: InputMaybe<Scalars["String"]>;
	addressSameAsFormIdentifierEQ?: InputMaybe<Scalars["String"]>;
	addressSameAsFormIdentifierHasPrefix?: InputMaybe<Scalars["String"]>;
	addressSameAsFormIdentifierHasSuffix?: InputMaybe<Scalars["String"]>;
	addressSameAsFormIdentifierIn?: InputMaybe<Array<Scalars["String"]>>;
	addressSameAsFormIdentifierIsNull?: InputMaybe<Scalars["Boolean"]>;
	addressSameAsFormIdentifierNEQ?: InputMaybe<Scalars["String"]>;
	addressSameAsFormIdentifierNotContains?: InputMaybe<Scalars["String"]>;
	addressSameAsFormIdentifierNotHasPrefix?: InputMaybe<Scalars["String"]>;
	addressSameAsFormIdentifierNotHasSuffix?: InputMaybe<Scalars["String"]>;
	addressSameAsFormIdentifierNotIn?: InputMaybe<Array<Scalars["String"]>>;
	and?: InputMaybe<Array<EnrollmentApplicationParentHistoryFilterInput>>;
	cityContains?: InputMaybe<Scalars["String"]>;
	cityEQ?: InputMaybe<Scalars["String"]>;
	cityHasPrefix?: InputMaybe<Scalars["String"]>;
	cityHasSuffix?: InputMaybe<Scalars["String"]>;
	cityIn?: InputMaybe<Array<Scalars["String"]>>;
	cityIsNull?: InputMaybe<Scalars["Boolean"]>;
	cityNEQ?: InputMaybe<Scalars["String"]>;
	cityNotContains?: InputMaybe<Scalars["String"]>;
	cityNotHasPrefix?: InputMaybe<Scalars["String"]>;
	cityNotHasSuffix?: InputMaybe<Scalars["String"]>;
	cityNotIn?: InputMaybe<Array<Scalars["String"]>>;
	countryContains?: InputMaybe<Scalars["String"]>;
	countryEQ?: InputMaybe<Scalars["String"]>;
	countryHasPrefix?: InputMaybe<Scalars["String"]>;
	countryHasSuffix?: InputMaybe<Scalars["String"]>;
	countryIn?: InputMaybe<Array<Scalars["String"]>>;
	countryIsNull?: InputMaybe<Scalars["Boolean"]>;
	countryNEQ?: InputMaybe<Scalars["String"]>;
	countryNotContains?: InputMaybe<Scalars["String"]>;
	countryNotHasPrefix?: InputMaybe<Scalars["String"]>;
	countryNotHasSuffix?: InputMaybe<Scalars["String"]>;
	countryNotIn?: InputMaybe<Array<Scalars["String"]>>;
	emailContains?: InputMaybe<Scalars["String"]>;
	emailEQ?: InputMaybe<Scalars["String"]>;
	emailHasPrefix?: InputMaybe<Scalars["String"]>;
	emailHasSuffix?: InputMaybe<Scalars["String"]>;
	emailIn?: InputMaybe<Array<Scalars["String"]>>;
	emailIsNull?: InputMaybe<Scalars["Boolean"]>;
	emailNEQ?: InputMaybe<Scalars["String"]>;
	emailNotContains?: InputMaybe<Scalars["String"]>;
	emailNotHasPrefix?: InputMaybe<Scalars["String"]>;
	emailNotHasSuffix?: InputMaybe<Scalars["String"]>;
	emailNotIn?: InputMaybe<Array<Scalars["String"]>>;
	enrollmentApplicationSubmissionIDEQ?: InputMaybe<Scalars["ID"]>;
	enrollmentApplicationSubmissionIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	enrollmentApplicationSubmissionIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	enrollmentApplicationSubmissionIDNEQ?: InputMaybe<Scalars["ID"]>;
	enrollmentApplicationSubmissionIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	firstNameContains?: InputMaybe<Scalars["String"]>;
	firstNameEQ?: InputMaybe<Scalars["String"]>;
	firstNameHasPrefix?: InputMaybe<Scalars["String"]>;
	firstNameHasSuffix?: InputMaybe<Scalars["String"]>;
	firstNameIn?: InputMaybe<Array<Scalars["String"]>>;
	firstNameIsNull?: InputMaybe<Scalars["Boolean"]>;
	firstNameNEQ?: InputMaybe<Scalars["String"]>;
	firstNameNotContains?: InputMaybe<Scalars["String"]>;
	firstNameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	firstNameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	firstNameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	formIdentifierContains?: InputMaybe<Scalars["String"]>;
	formIdentifierEQ?: InputMaybe<Scalars["String"]>;
	formIdentifierHasPrefix?: InputMaybe<Scalars["String"]>;
	formIdentifierHasSuffix?: InputMaybe<Scalars["String"]>;
	formIdentifierIn?: InputMaybe<Array<Scalars["String"]>>;
	formIdentifierIsNull?: InputMaybe<Scalars["Boolean"]>;
	formIdentifierNEQ?: InputMaybe<Scalars["String"]>;
	formIdentifierNotContains?: InputMaybe<Scalars["String"]>;
	formIdentifierNotHasPrefix?: InputMaybe<Scalars["String"]>;
	formIdentifierNotHasSuffix?: InputMaybe<Scalars["String"]>;
	formIdentifierNotIn?: InputMaybe<Array<Scalars["String"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	homePhoneContains?: InputMaybe<Scalars["String"]>;
	homePhoneEQ?: InputMaybe<Scalars["String"]>;
	homePhoneHasPrefix?: InputMaybe<Scalars["String"]>;
	homePhoneHasSuffix?: InputMaybe<Scalars["String"]>;
	homePhoneIn?: InputMaybe<Array<Scalars["String"]>>;
	homePhoneIsNull?: InputMaybe<Scalars["Boolean"]>;
	homePhoneNEQ?: InputMaybe<Scalars["String"]>;
	homePhoneNotContains?: InputMaybe<Scalars["String"]>;
	homePhoneNotHasPrefix?: InputMaybe<Scalars["String"]>;
	homePhoneNotHasSuffix?: InputMaybe<Scalars["String"]>;
	homePhoneNotIn?: InputMaybe<Array<Scalars["String"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	isAuthorizedForPickupEQ?: InputMaybe<Scalars["Boolean"]>;
	isAuthorizedForPickupIsNull?: InputMaybe<Scalars["Boolean"]>;
	isAuthorizedForPickupNEQ?: InputMaybe<Scalars["Boolean"]>;
	isEmergencyContactEQ?: InputMaybe<Scalars["Boolean"]>;
	isEmergencyContactIsNull?: InputMaybe<Scalars["Boolean"]>;
	isEmergencyContactNEQ?: InputMaybe<Scalars["Boolean"]>;
	isFinancialContactEQ?: InputMaybe<Scalars["Boolean"]>;
	isFinancialContactIsNull?: InputMaybe<Scalars["Boolean"]>;
	isFinancialContactNEQ?: InputMaybe<Scalars["Boolean"]>;
	isPrimaryContactEQ?: InputMaybe<Scalars["Boolean"]>;
	isPrimaryContactIsNull?: InputMaybe<Scalars["Boolean"]>;
	isPrimaryContactNEQ?: InputMaybe<Scalars["Boolean"]>;
	lastNameContains?: InputMaybe<Scalars["String"]>;
	lastNameEQ?: InputMaybe<Scalars["String"]>;
	lastNameHasPrefix?: InputMaybe<Scalars["String"]>;
	lastNameHasSuffix?: InputMaybe<Scalars["String"]>;
	lastNameIn?: InputMaybe<Array<Scalars["String"]>>;
	lastNameIsNull?: InputMaybe<Scalars["Boolean"]>;
	lastNameNEQ?: InputMaybe<Scalars["String"]>;
	lastNameNotContains?: InputMaybe<Scalars["String"]>;
	lastNameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	lastNameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	lastNameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	livesWithStudentEQ?: InputMaybe<Scalars["Boolean"]>;
	livesWithStudentIsNull?: InputMaybe<Scalars["Boolean"]>;
	livesWithStudentNEQ?: InputMaybe<Scalars["Boolean"]>;
	mobilePhoneContains?: InputMaybe<Scalars["String"]>;
	mobilePhoneEQ?: InputMaybe<Scalars["String"]>;
	mobilePhoneHasPrefix?: InputMaybe<Scalars["String"]>;
	mobilePhoneHasSuffix?: InputMaybe<Scalars["String"]>;
	mobilePhoneIn?: InputMaybe<Array<Scalars["String"]>>;
	mobilePhoneIsNull?: InputMaybe<Scalars["Boolean"]>;
	mobilePhoneNEQ?: InputMaybe<Scalars["String"]>;
	mobilePhoneNotContains?: InputMaybe<Scalars["String"]>;
	mobilePhoneNotHasPrefix?: InputMaybe<Scalars["String"]>;
	mobilePhoneNotHasSuffix?: InputMaybe<Scalars["String"]>;
	mobilePhoneNotIn?: InputMaybe<Array<Scalars["String"]>>;
	nationalityContains?: InputMaybe<Scalars["String"]>;
	nationalityEQ?: InputMaybe<Scalars["String"]>;
	nationalityHasPrefix?: InputMaybe<Scalars["String"]>;
	nationalityHasSuffix?: InputMaybe<Scalars["String"]>;
	nationalityIn?: InputMaybe<Array<Scalars["String"]>>;
	nationalityIsNull?: InputMaybe<Scalars["Boolean"]>;
	nationalityNEQ?: InputMaybe<Scalars["String"]>;
	nationalityNotContains?: InputMaybe<Scalars["String"]>;
	nationalityNotHasPrefix?: InputMaybe<Scalars["String"]>;
	nationalityNotHasSuffix?: InputMaybe<Scalars["String"]>;
	nationalityNotIn?: InputMaybe<Array<Scalars["String"]>>;
	not?: InputMaybe<EnrollmentApplicationParentHistoryFilterInput>;
	occupationContains?: InputMaybe<Scalars["String"]>;
	occupationEQ?: InputMaybe<Scalars["String"]>;
	occupationHasPrefix?: InputMaybe<Scalars["String"]>;
	occupationHasSuffix?: InputMaybe<Scalars["String"]>;
	occupationIn?: InputMaybe<Array<Scalars["String"]>>;
	occupationIsNull?: InputMaybe<Scalars["Boolean"]>;
	occupationNEQ?: InputMaybe<Scalars["String"]>;
	occupationNotContains?: InputMaybe<Scalars["String"]>;
	occupationNotHasPrefix?: InputMaybe<Scalars["String"]>;
	occupationNotHasSuffix?: InputMaybe<Scalars["String"]>;
	occupationNotIn?: InputMaybe<Array<Scalars["String"]>>;
	or?: InputMaybe<Array<EnrollmentApplicationParentHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	parentIDEQ?: InputMaybe<Scalars["ID"]>;
	parentIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	parentIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	parentIDNEQ?: InputMaybe<Scalars["ID"]>;
	parentIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	postalCodeContains?: InputMaybe<Scalars["String"]>;
	postalCodeEQ?: InputMaybe<Scalars["String"]>;
	postalCodeHasPrefix?: InputMaybe<Scalars["String"]>;
	postalCodeHasSuffix?: InputMaybe<Scalars["String"]>;
	postalCodeIn?: InputMaybe<Array<Scalars["String"]>>;
	postalCodeIsNull?: InputMaybe<Scalars["Boolean"]>;
	postalCodeNEQ?: InputMaybe<Scalars["String"]>;
	postalCodeNotContains?: InputMaybe<Scalars["String"]>;
	postalCodeNotHasPrefix?: InputMaybe<Scalars["String"]>;
	postalCodeNotHasSuffix?: InputMaybe<Scalars["String"]>;
	postalCodeNotIn?: InputMaybe<Array<Scalars["String"]>>;
	relationshipTypeCustomContains?: InputMaybe<Scalars["String"]>;
	relationshipTypeCustomEQ?: InputMaybe<Scalars["String"]>;
	relationshipTypeCustomHasPrefix?: InputMaybe<Scalars["String"]>;
	relationshipTypeCustomHasSuffix?: InputMaybe<Scalars["String"]>;
	relationshipTypeCustomIn?: InputMaybe<Array<Scalars["String"]>>;
	relationshipTypeCustomIsNull?: InputMaybe<Scalars["Boolean"]>;
	relationshipTypeCustomNEQ?: InputMaybe<Scalars["String"]>;
	relationshipTypeCustomNotContains?: InputMaybe<Scalars["String"]>;
	relationshipTypeCustomNotHasPrefix?: InputMaybe<Scalars["String"]>;
	relationshipTypeCustomNotHasSuffix?: InputMaybe<Scalars["String"]>;
	relationshipTypeCustomNotIn?: InputMaybe<Array<Scalars["String"]>>;
	relationshipTypeEQ?: InputMaybe<EnrollmentApplicationParentHistoryRelationshipType>;
	relationshipTypeIn?: InputMaybe<Array<EnrollmentApplicationParentHistoryRelationshipType>>;
	relationshipTypeIsNull?: InputMaybe<Scalars["Boolean"]>;
	relationshipTypeNEQ?: InputMaybe<EnrollmentApplicationParentHistoryRelationshipType>;
	relationshipTypeNotIn?: InputMaybe<Array<EnrollmentApplicationParentHistoryRelationshipType>>;
	search?: InputMaybe<Scalars["String"]>;
	stateOrProvinceContains?: InputMaybe<Scalars["String"]>;
	stateOrProvinceEQ?: InputMaybe<Scalars["String"]>;
	stateOrProvinceHasPrefix?: InputMaybe<Scalars["String"]>;
	stateOrProvinceHasSuffix?: InputMaybe<Scalars["String"]>;
	stateOrProvinceIn?: InputMaybe<Array<Scalars["String"]>>;
	stateOrProvinceIsNull?: InputMaybe<Scalars["Boolean"]>;
	stateOrProvinceNEQ?: InputMaybe<Scalars["String"]>;
	stateOrProvinceNotContains?: InputMaybe<Scalars["String"]>;
	stateOrProvinceNotHasPrefix?: InputMaybe<Scalars["String"]>;
	stateOrProvinceNotHasSuffix?: InputMaybe<Scalars["String"]>;
	stateOrProvinceNotIn?: InputMaybe<Array<Scalars["String"]>>;
	workPhoneContains?: InputMaybe<Scalars["String"]>;
	workPhoneEQ?: InputMaybe<Scalars["String"]>;
	workPhoneHasPrefix?: InputMaybe<Scalars["String"]>;
	workPhoneHasSuffix?: InputMaybe<Scalars["String"]>;
	workPhoneIn?: InputMaybe<Array<Scalars["String"]>>;
	workPhoneIsNull?: InputMaybe<Scalars["Boolean"]>;
	workPhoneNEQ?: InputMaybe<Scalars["String"]>;
	workPhoneNotContains?: InputMaybe<Scalars["String"]>;
	workPhoneNotHasPrefix?: InputMaybe<Scalars["String"]>;
	workPhoneNotHasSuffix?: InputMaybe<Scalars["String"]>;
	workPhoneNotIn?: InputMaybe<Array<Scalars["String"]>>;
};

export type EnrollmentApplicationParentHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<EnrollmentApplicationParentHistoryOrderField>;
};

export enum EnrollmentApplicationParentHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export enum EnrollmentApplicationParentHistoryRelationshipType {
	Father = "father",
	Mother = "mother",
	Other = "other",
}

export type EnrollmentApplicationParentInParentCreateInput = {
	addressLine1?: InputMaybe<Scalars["String"]>;
	addressLine2?: InputMaybe<Scalars["String"]>;
	addressSameAsFormIdentifier?: InputMaybe<Scalars["String"]>;
	city?: InputMaybe<Scalars["String"]>;
	country?: InputMaybe<Scalars["String"]>;
	email: Scalars["String"];
	enrollmentApplicationSubmissionID: Scalars["ID"];
	firstName: Scalars["String"];
	formIdentifier: Scalars["String"];
	homePhone?: InputMaybe<Scalars["String"]>;
	isAuthorizedForPickup?: InputMaybe<Scalars["Boolean"]>;
	isEmergencyContact?: InputMaybe<Scalars["Boolean"]>;
	isFinancialContact?: InputMaybe<Scalars["Boolean"]>;
	isPrimaryContact?: InputMaybe<Scalars["Boolean"]>;
	lastName: Scalars["String"];
	livesWithStudent?: InputMaybe<Scalars["Boolean"]>;
	mobilePhone?: InputMaybe<Scalars["String"]>;
	nationality?: InputMaybe<Scalars["String"]>;
	occupation?: InputMaybe<Scalars["String"]>;
	postalCode?: InputMaybe<Scalars["String"]>;
	relationshipType: EnrollmentApplicationParentRelationshipType;
	relationshipTypeCustom?: InputMaybe<Scalars["String"]>;
	stateOrProvince?: InputMaybe<Scalars["String"]>;
	workPhone?: InputMaybe<Scalars["String"]>;
};

export type EnrollmentApplicationParentOrder = {
	direction: OrderDirection;
	field?: InputMaybe<EnrollmentApplicationParentOrderField>;
};

export enum EnrollmentApplicationParentOrderField {
	FirstName = "FIRST_NAME",
	IsAuthorizedForPickup = "IS_AUTHORIZED_FOR_PICKUP",
	IsEmergencyContact = "IS_EMERGENCY_CONTACT",
	IsFinancialContact = "IS_FINANCIAL_CONTACT",
	IsPrimaryContact = "IS_PRIMARY_CONTACT",
	LastName = "LAST_NAME",
	LivesWithStudent = "LIVES_WITH_STUDENT",
	Nationality = "NATIONALITY",
	Occupation = "OCCUPATION",
	RelationshipType = "RELATIONSHIP_TYPE",
	RelationshipTypeCustom = "RELATIONSHIP_TYPE_CUSTOM",
}

export enum EnrollmentApplicationParentRelationshipType {
	Father = "father",
	Mother = "mother",
	Other = "other",
}

export type EnrollmentApplicationParentUpdateBulkInput = {
	addressLine1?: InputMaybe<Scalars["String"]>;
	addressLine2?: InputMaybe<Scalars["String"]>;
	addressSameAsFormIdentifier?: InputMaybe<Scalars["String"]>;
	city?: InputMaybe<Scalars["String"]>;
	country?: InputMaybe<Scalars["String"]>;
	email?: InputMaybe<Scalars["String"]>;
	enrollmentApplicationSubmissionID?: InputMaybe<Scalars["ID"]>;
	firstName?: InputMaybe<Scalars["String"]>;
	formIdentifier?: InputMaybe<Scalars["String"]>;
	homePhone?: InputMaybe<Scalars["String"]>;
	isAuthorizedForPickup?: InputMaybe<Scalars["Boolean"]>;
	isEmergencyContact?: InputMaybe<Scalars["Boolean"]>;
	isFinancialContact?: InputMaybe<Scalars["Boolean"]>;
	isPrimaryContact?: InputMaybe<Scalars["Boolean"]>;
	lastName?: InputMaybe<Scalars["String"]>;
	livesWithStudent?: InputMaybe<Scalars["Boolean"]>;
	mobilePhone?: InputMaybe<Scalars["String"]>;
	nationality?: InputMaybe<Scalars["String"]>;
	occupation?: InputMaybe<Scalars["String"]>;
	postalCode?: InputMaybe<Scalars["String"]>;
	relationshipType?: InputMaybe<EnrollmentApplicationParentRelationshipType>;
	relationshipTypeCustom?: InputMaybe<Scalars["String"]>;
	stateOrProvince?: InputMaybe<Scalars["String"]>;
	workPhone?: InputMaybe<Scalars["String"]>;
};

export type EnrollmentApplicationParentUpdateInput = {
	addressLine1?: InputMaybe<Scalars["String"]>;
	addressLine2?: InputMaybe<Scalars["String"]>;
	addressSameAsFormIdentifier?: InputMaybe<Scalars["String"]>;
	city?: InputMaybe<Scalars["String"]>;
	clearParent?: InputMaybe<Scalars["Boolean"]>;
	country?: InputMaybe<Scalars["String"]>;
	email?: InputMaybe<Scalars["String"]>;
	enrollmentApplicationSubmissionID?: InputMaybe<Scalars["ID"]>;
	firstName?: InputMaybe<Scalars["String"]>;
	formIdentifier?: InputMaybe<Scalars["String"]>;
	homePhone?: InputMaybe<Scalars["String"]>;
	isAuthorizedForPickup?: InputMaybe<Scalars["Boolean"]>;
	isEmergencyContact?: InputMaybe<Scalars["Boolean"]>;
	isFinancialContact?: InputMaybe<Scalars["Boolean"]>;
	isPrimaryContact?: InputMaybe<Scalars["Boolean"]>;
	lastName?: InputMaybe<Scalars["String"]>;
	livesWithStudent?: InputMaybe<Scalars["Boolean"]>;
	mobilePhone?: InputMaybe<Scalars["String"]>;
	nationality?: InputMaybe<Scalars["String"]>;
	occupation?: InputMaybe<Scalars["String"]>;
	parentID?: InputMaybe<Scalars["ID"]>;
	postalCode?: InputMaybe<Scalars["String"]>;
	relationshipType?: InputMaybe<EnrollmentApplicationParentRelationshipType>;
	relationshipTypeCustom?: InputMaybe<Scalars["String"]>;
	stateOrProvince?: InputMaybe<Scalars["String"]>;
	workPhone?: InputMaybe<Scalars["String"]>;
};

export enum EnrollmentApplicationStatus {
	Approved = "approved",
	Pending = "pending",
	Rejected = "rejected",
}

export type EnrollmentApplicationStudent = Node & {
	__typename?: "EnrollmentApplicationStudent";
	additionalInformation: Scalars["String"];
	behaviorDisciplineChallengesDescription: Scalars["String"];
	currentGradeLevel: EnrollmentApplicationStudentCurrentGradeLevel;
	dateOfBirth: Scalars["Time"];
	englishLanguageFluency: EnrollmentApplicationStudentEnglishLanguageFluency;
	enrollmentApplication: Maybe<EnrollmentApplication>;
	enrollmentApplicationSubmission: EnrollmentApplicationSubmission;
	enrollmentApplicationSubmissionID: Scalars["ID"];
	firstName: Scalars["String"];
	formIdentifier: Scalars["String"];
	gender: Maybe<EnrollmentApplicationStudentGender>;
	genderCustom: Scalars["String"];
	gradeLevelApplyingFor: EnrollmentApplicationStudentGradeLevelApplyingFor;
	hasBehaviorDisciplineChallenges: Scalars["Boolean"];
	hasLearningDifficulties: Scalars["Boolean"];
	hasMedicalPhysicalExceptionalities: Scalars["Boolean"];
	hasPreviousSchooling: Scalars["Boolean"];
	id: Scalars["ID"];
	imageContentType: Scalars["String"];
	imageURL: Scalars["String"];
	lastName: Scalars["String"];
	learningDifficultiesDescription: Scalars["String"];
	medicalPhysicalExceptionalitiesDescription: Scalars["String"];
	name: Scalars["String"];
	nationality: Scalars["String"];
	nickname: Scalars["String"];
	previousSchoolInformation: Scalars["String"];
	previousSchoolLocation: Scalars["String"];
	primaryLanguage: Scalars["String"];
	student: Maybe<Student>;
	studentID: Maybe<Scalars["ID"]>;
};

export type EnrollmentApplicationStudentConnection = {
	__typename?: "EnrollmentApplicationStudentConnection";
	edges: Array<EnrollmentApplicationStudentEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type EnrollmentApplicationStudentCreateInput = {
	additionalInformation?: InputMaybe<Scalars["String"]>;
	behaviorDisciplineChallengesDescription?: InputMaybe<Scalars["String"]>;
	currentGradeLevel: EnrollmentApplicationStudentCurrentGradeLevel;
	dateOfBirth: Scalars["Time"];
	englishLanguageFluency?: InputMaybe<EnrollmentApplicationStudentEnglishLanguageFluency>;
	enrollmentApplicationID?: InputMaybe<Scalars["ID"]>;
	enrollmentApplicationSubmissionID: Scalars["ID"];
	firstName: Scalars["String"];
	formIdentifier: Scalars["String"];
	gender?: InputMaybe<EnrollmentApplicationStudentGender>;
	genderCustom?: InputMaybe<Scalars["String"]>;
	gradeLevelApplyingFor: EnrollmentApplicationStudentGradeLevelApplyingFor;
	hasBehaviorDisciplineChallenges?: InputMaybe<Scalars["Boolean"]>;
	hasLearningDifficulties?: InputMaybe<Scalars["Boolean"]>;
	hasMedicalPhysicalExceptionalities?: InputMaybe<Scalars["Boolean"]>;
	hasPreviousSchooling?: InputMaybe<Scalars["Boolean"]>;
	image?: InputMaybe<Scalars["Upload"]>;
	lastName: Scalars["String"];
	learningDifficultiesDescription?: InputMaybe<Scalars["String"]>;
	medicalPhysicalExceptionalitiesDescription?: InputMaybe<Scalars["String"]>;
	nationality?: InputMaybe<Scalars["String"]>;
	nickname?: InputMaybe<Scalars["String"]>;
	previousSchoolInformation?: InputMaybe<Scalars["String"]>;
	previousSchoolLocation?: InputMaybe<Scalars["String"]>;
	primaryLanguage?: InputMaybe<Scalars["String"]>;
	student?: InputMaybe<StudentInEnrollmentApplicationStudentCreateInput>;
	studentID?: InputMaybe<Scalars["ID"]>;
};

export enum EnrollmentApplicationStudentCurrentGradeLevel {
	None = "none",
	Nursery = "nursery",
	Reception = "reception",
	Year1 = "year_1",
	Year2 = "year_2",
	Year3 = "year_3",
	Year4 = "year_4",
	Year5 = "year_5",
	Year6 = "year_6",
	Year7 = "year_7",
}

export type EnrollmentApplicationStudentEdge = {
	__typename?: "EnrollmentApplicationStudentEdge";
	cursor: Scalars["Cursor"];
	node: EnrollmentApplicationStudent;
};

export enum EnrollmentApplicationStudentEnglishLanguageFluency {
	Average = "average",
	Basic = "basic",
	Excellent = "excellent",
	None = "none",
	NotSpecified = "not_specified",
}

export type EnrollmentApplicationStudentFilterInput = {
	additionalInformationContains?: InputMaybe<Scalars["String"]>;
	additionalInformationEQ?: InputMaybe<Scalars["String"]>;
	additionalInformationHasPrefix?: InputMaybe<Scalars["String"]>;
	additionalInformationHasSuffix?: InputMaybe<Scalars["String"]>;
	additionalInformationIn?: InputMaybe<Array<Scalars["String"]>>;
	additionalInformationNEQ?: InputMaybe<Scalars["String"]>;
	additionalInformationNotContains?: InputMaybe<Scalars["String"]>;
	additionalInformationNotHasPrefix?: InputMaybe<Scalars["String"]>;
	additionalInformationNotHasSuffix?: InputMaybe<Scalars["String"]>;
	additionalInformationNotIn?: InputMaybe<Array<Scalars["String"]>>;
	and?: InputMaybe<Array<EnrollmentApplicationStudentFilterInput>>;
	behaviorDisciplineChallengesDescriptionContains?: InputMaybe<Scalars["String"]>;
	behaviorDisciplineChallengesDescriptionEQ?: InputMaybe<Scalars["String"]>;
	behaviorDisciplineChallengesDescriptionHasPrefix?: InputMaybe<Scalars["String"]>;
	behaviorDisciplineChallengesDescriptionHasSuffix?: InputMaybe<Scalars["String"]>;
	behaviorDisciplineChallengesDescriptionIn?: InputMaybe<Array<Scalars["String"]>>;
	behaviorDisciplineChallengesDescriptionNEQ?: InputMaybe<Scalars["String"]>;
	behaviorDisciplineChallengesDescriptionNotContains?: InputMaybe<Scalars["String"]>;
	behaviorDisciplineChallengesDescriptionNotHasPrefix?: InputMaybe<Scalars["String"]>;
	behaviorDisciplineChallengesDescriptionNotHasSuffix?: InputMaybe<Scalars["String"]>;
	behaviorDisciplineChallengesDescriptionNotIn?: InputMaybe<Array<Scalars["String"]>>;
	currentGradeLevelEQ?: InputMaybe<EnrollmentApplicationStudentCurrentGradeLevel>;
	currentGradeLevelIn?: InputMaybe<Array<EnrollmentApplicationStudentCurrentGradeLevel>>;
	currentGradeLevelNEQ?: InputMaybe<EnrollmentApplicationStudentCurrentGradeLevel>;
	currentGradeLevelNotIn?: InputMaybe<Array<EnrollmentApplicationStudentCurrentGradeLevel>>;
	dateOfBirthEQ?: InputMaybe<Scalars["Time"]>;
	dateOfBirthGT?: InputMaybe<Scalars["Time"]>;
	dateOfBirthGTE?: InputMaybe<Scalars["Time"]>;
	dateOfBirthIn?: InputMaybe<Array<Scalars["Time"]>>;
	dateOfBirthLT?: InputMaybe<Scalars["Time"]>;
	dateOfBirthLTE?: InputMaybe<Scalars["Time"]>;
	dateOfBirthNEQ?: InputMaybe<Scalars["Time"]>;
	dateOfBirthNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	englishLanguageFluencyEQ?: InputMaybe<EnrollmentApplicationStudentEnglishLanguageFluency>;
	englishLanguageFluencyIn?: InputMaybe<Array<EnrollmentApplicationStudentEnglishLanguageFluency>>;
	englishLanguageFluencyNEQ?: InputMaybe<EnrollmentApplicationStudentEnglishLanguageFluency>;
	englishLanguageFluencyNotIn?: InputMaybe<Array<EnrollmentApplicationStudentEnglishLanguageFluency>>;
	enrollmentApplication?: InputMaybe<Array<EnrollmentApplicationFilterInput>>;
	enrollmentApplicationSubmission?: InputMaybe<Array<EnrollmentApplicationSubmissionFilterInput>>;
	enrollmentApplicationSubmissionIDEQ?: InputMaybe<Scalars["ID"]>;
	enrollmentApplicationSubmissionIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	enrollmentApplicationSubmissionIDNEQ?: InputMaybe<Scalars["ID"]>;
	enrollmentApplicationSubmissionIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	firstNameContains?: InputMaybe<Scalars["String"]>;
	firstNameEQ?: InputMaybe<Scalars["String"]>;
	firstNameHasPrefix?: InputMaybe<Scalars["String"]>;
	firstNameHasSuffix?: InputMaybe<Scalars["String"]>;
	firstNameIn?: InputMaybe<Array<Scalars["String"]>>;
	firstNameNEQ?: InputMaybe<Scalars["String"]>;
	firstNameNotContains?: InputMaybe<Scalars["String"]>;
	firstNameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	firstNameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	firstNameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	formIdentifierContains?: InputMaybe<Scalars["String"]>;
	formIdentifierEQ?: InputMaybe<Scalars["String"]>;
	formIdentifierHasPrefix?: InputMaybe<Scalars["String"]>;
	formIdentifierHasSuffix?: InputMaybe<Scalars["String"]>;
	formIdentifierIn?: InputMaybe<Array<Scalars["String"]>>;
	formIdentifierNEQ?: InputMaybe<Scalars["String"]>;
	formIdentifierNotContains?: InputMaybe<Scalars["String"]>;
	formIdentifierNotHasPrefix?: InputMaybe<Scalars["String"]>;
	formIdentifierNotHasSuffix?: InputMaybe<Scalars["String"]>;
	formIdentifierNotIn?: InputMaybe<Array<Scalars["String"]>>;
	genderCustomContains?: InputMaybe<Scalars["String"]>;
	genderCustomEQ?: InputMaybe<Scalars["String"]>;
	genderCustomHasPrefix?: InputMaybe<Scalars["String"]>;
	genderCustomHasSuffix?: InputMaybe<Scalars["String"]>;
	genderCustomIn?: InputMaybe<Array<Scalars["String"]>>;
	genderCustomNEQ?: InputMaybe<Scalars["String"]>;
	genderCustomNotContains?: InputMaybe<Scalars["String"]>;
	genderCustomNotHasPrefix?: InputMaybe<Scalars["String"]>;
	genderCustomNotHasSuffix?: InputMaybe<Scalars["String"]>;
	genderCustomNotIn?: InputMaybe<Array<Scalars["String"]>>;
	genderEQ?: InputMaybe<EnrollmentApplicationStudentGender>;
	genderIn?: InputMaybe<Array<EnrollmentApplicationStudentGender>>;
	genderIsNull?: InputMaybe<Scalars["Boolean"]>;
	genderNEQ?: InputMaybe<EnrollmentApplicationStudentGender>;
	genderNotIn?: InputMaybe<Array<EnrollmentApplicationStudentGender>>;
	gradeLevelApplyingForEQ?: InputMaybe<EnrollmentApplicationStudentGradeLevelApplyingFor>;
	gradeLevelApplyingForIn?: InputMaybe<Array<EnrollmentApplicationStudentGradeLevelApplyingFor>>;
	gradeLevelApplyingForNEQ?: InputMaybe<EnrollmentApplicationStudentGradeLevelApplyingFor>;
	gradeLevelApplyingForNotIn?: InputMaybe<Array<EnrollmentApplicationStudentGradeLevelApplyingFor>>;
	hasBehaviorDisciplineChallengesEQ?: InputMaybe<Scalars["Boolean"]>;
	hasBehaviorDisciplineChallengesNEQ?: InputMaybe<Scalars["Boolean"]>;
	hasEnrollmentApplication?: InputMaybe<Scalars["Boolean"]>;
	hasLearningDifficultiesEQ?: InputMaybe<Scalars["Boolean"]>;
	hasLearningDifficultiesNEQ?: InputMaybe<Scalars["Boolean"]>;
	hasMedicalPhysicalExceptionalitiesEQ?: InputMaybe<Scalars["Boolean"]>;
	hasMedicalPhysicalExceptionalitiesNEQ?: InputMaybe<Scalars["Boolean"]>;
	hasPreviousSchoolingEQ?: InputMaybe<Scalars["Boolean"]>;
	hasPreviousSchoolingNEQ?: InputMaybe<Scalars["Boolean"]>;
	hasStudent?: InputMaybe<Scalars["Boolean"]>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	imageContentTypeContains?: InputMaybe<Scalars["String"]>;
	imageContentTypeEQ?: InputMaybe<Scalars["String"]>;
	imageContentTypeHasPrefix?: InputMaybe<Scalars["String"]>;
	imageContentTypeHasSuffix?: InputMaybe<Scalars["String"]>;
	imageContentTypeIn?: InputMaybe<Array<Scalars["String"]>>;
	imageContentTypeNEQ?: InputMaybe<Scalars["String"]>;
	imageContentTypeNotContains?: InputMaybe<Scalars["String"]>;
	imageContentTypeNotHasPrefix?: InputMaybe<Scalars["String"]>;
	imageContentTypeNotHasSuffix?: InputMaybe<Scalars["String"]>;
	imageContentTypeNotIn?: InputMaybe<Array<Scalars["String"]>>;
	imageIsNull?: InputMaybe<Scalars["Boolean"]>;
	lastNameContains?: InputMaybe<Scalars["String"]>;
	lastNameEQ?: InputMaybe<Scalars["String"]>;
	lastNameHasPrefix?: InputMaybe<Scalars["String"]>;
	lastNameHasSuffix?: InputMaybe<Scalars["String"]>;
	lastNameIn?: InputMaybe<Array<Scalars["String"]>>;
	lastNameNEQ?: InputMaybe<Scalars["String"]>;
	lastNameNotContains?: InputMaybe<Scalars["String"]>;
	lastNameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	lastNameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	lastNameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	learningDifficultiesDescriptionContains?: InputMaybe<Scalars["String"]>;
	learningDifficultiesDescriptionEQ?: InputMaybe<Scalars["String"]>;
	learningDifficultiesDescriptionHasPrefix?: InputMaybe<Scalars["String"]>;
	learningDifficultiesDescriptionHasSuffix?: InputMaybe<Scalars["String"]>;
	learningDifficultiesDescriptionIn?: InputMaybe<Array<Scalars["String"]>>;
	learningDifficultiesDescriptionNEQ?: InputMaybe<Scalars["String"]>;
	learningDifficultiesDescriptionNotContains?: InputMaybe<Scalars["String"]>;
	learningDifficultiesDescriptionNotHasPrefix?: InputMaybe<Scalars["String"]>;
	learningDifficultiesDescriptionNotHasSuffix?: InputMaybe<Scalars["String"]>;
	learningDifficultiesDescriptionNotIn?: InputMaybe<Array<Scalars["String"]>>;
	medicalPhysicalExceptionalitiesDescriptionContains?: InputMaybe<Scalars["String"]>;
	medicalPhysicalExceptionalitiesDescriptionEQ?: InputMaybe<Scalars["String"]>;
	medicalPhysicalExceptionalitiesDescriptionHasPrefix?: InputMaybe<Scalars["String"]>;
	medicalPhysicalExceptionalitiesDescriptionHasSuffix?: InputMaybe<Scalars["String"]>;
	medicalPhysicalExceptionalitiesDescriptionIn?: InputMaybe<Array<Scalars["String"]>>;
	medicalPhysicalExceptionalitiesDescriptionNEQ?: InputMaybe<Scalars["String"]>;
	medicalPhysicalExceptionalitiesDescriptionNotContains?: InputMaybe<Scalars["String"]>;
	medicalPhysicalExceptionalitiesDescriptionNotHasPrefix?: InputMaybe<Scalars["String"]>;
	medicalPhysicalExceptionalitiesDescriptionNotHasSuffix?: InputMaybe<Scalars["String"]>;
	medicalPhysicalExceptionalitiesDescriptionNotIn?: InputMaybe<Array<Scalars["String"]>>;
	nationalityContains?: InputMaybe<Scalars["String"]>;
	nationalityEQ?: InputMaybe<Scalars["String"]>;
	nationalityHasPrefix?: InputMaybe<Scalars["String"]>;
	nationalityHasSuffix?: InputMaybe<Scalars["String"]>;
	nationalityIn?: InputMaybe<Array<Scalars["String"]>>;
	nationalityNEQ?: InputMaybe<Scalars["String"]>;
	nationalityNotContains?: InputMaybe<Scalars["String"]>;
	nationalityNotHasPrefix?: InputMaybe<Scalars["String"]>;
	nationalityNotHasSuffix?: InputMaybe<Scalars["String"]>;
	nationalityNotIn?: InputMaybe<Array<Scalars["String"]>>;
	nicknameContains?: InputMaybe<Scalars["String"]>;
	nicknameEQ?: InputMaybe<Scalars["String"]>;
	nicknameHasPrefix?: InputMaybe<Scalars["String"]>;
	nicknameHasSuffix?: InputMaybe<Scalars["String"]>;
	nicknameIn?: InputMaybe<Array<Scalars["String"]>>;
	nicknameNEQ?: InputMaybe<Scalars["String"]>;
	nicknameNotContains?: InputMaybe<Scalars["String"]>;
	nicknameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	nicknameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	nicknameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	not?: InputMaybe<EnrollmentApplicationStudentFilterInput>;
	or?: InputMaybe<Array<EnrollmentApplicationStudentFilterInput>>;
	previousSchoolInformationContains?: InputMaybe<Scalars["String"]>;
	previousSchoolInformationEQ?: InputMaybe<Scalars["String"]>;
	previousSchoolInformationHasPrefix?: InputMaybe<Scalars["String"]>;
	previousSchoolInformationHasSuffix?: InputMaybe<Scalars["String"]>;
	previousSchoolInformationIn?: InputMaybe<Array<Scalars["String"]>>;
	previousSchoolInformationNEQ?: InputMaybe<Scalars["String"]>;
	previousSchoolInformationNotContains?: InputMaybe<Scalars["String"]>;
	previousSchoolInformationNotHasPrefix?: InputMaybe<Scalars["String"]>;
	previousSchoolInformationNotHasSuffix?: InputMaybe<Scalars["String"]>;
	previousSchoolInformationNotIn?: InputMaybe<Array<Scalars["String"]>>;
	previousSchoolLocationContains?: InputMaybe<Scalars["String"]>;
	previousSchoolLocationEQ?: InputMaybe<Scalars["String"]>;
	previousSchoolLocationHasPrefix?: InputMaybe<Scalars["String"]>;
	previousSchoolLocationHasSuffix?: InputMaybe<Scalars["String"]>;
	previousSchoolLocationIn?: InputMaybe<Array<Scalars["String"]>>;
	previousSchoolLocationNEQ?: InputMaybe<Scalars["String"]>;
	previousSchoolLocationNotContains?: InputMaybe<Scalars["String"]>;
	previousSchoolLocationNotHasPrefix?: InputMaybe<Scalars["String"]>;
	previousSchoolLocationNotHasSuffix?: InputMaybe<Scalars["String"]>;
	previousSchoolLocationNotIn?: InputMaybe<Array<Scalars["String"]>>;
	primaryLanguageContains?: InputMaybe<Scalars["String"]>;
	primaryLanguageEQ?: InputMaybe<Scalars["String"]>;
	primaryLanguageHasPrefix?: InputMaybe<Scalars["String"]>;
	primaryLanguageHasSuffix?: InputMaybe<Scalars["String"]>;
	primaryLanguageIn?: InputMaybe<Array<Scalars["String"]>>;
	primaryLanguageNEQ?: InputMaybe<Scalars["String"]>;
	primaryLanguageNotContains?: InputMaybe<Scalars["String"]>;
	primaryLanguageNotHasPrefix?: InputMaybe<Scalars["String"]>;
	primaryLanguageNotHasSuffix?: InputMaybe<Scalars["String"]>;
	primaryLanguageNotIn?: InputMaybe<Array<Scalars["String"]>>;
	search?: InputMaybe<Scalars["String"]>;
	student?: InputMaybe<Array<StudentFilterInput>>;
	studentIDEQ?: InputMaybe<Scalars["ID"]>;
	studentIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	studentIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	studentIDNEQ?: InputMaybe<Scalars["ID"]>;
	studentIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export enum EnrollmentApplicationStudentGender {
	Female = "female",
	Male = "male",
	Other = "other",
}

export enum EnrollmentApplicationStudentGradeLevelApplyingFor {
	Nursery = "nursery",
	Reception = "reception",
	Year1 = "year_1",
	Year2 = "year_2",
	Year3 = "year_3",
	Year4 = "year_4",
	Year5 = "year_5",
	Year6 = "year_6",
	Year7 = "year_7",
	Year8 = "year_8",
}

export type EnrollmentApplicationStudentHistory = Node & {
	__typename?: "EnrollmentApplicationStudentHistory";
	additionalInformation: Maybe<Scalars["String"]>;
	behaviorDisciplineChallengesDescription: Maybe<Scalars["String"]>;
	currentGradeLevel: Maybe<EnrollmentApplicationStudentHistoryCurrentGradeLevel>;
	dateOfBirth: Maybe<Scalars["Time"]>;
	englishLanguageFluency: Maybe<EnrollmentApplicationStudentHistoryEnglishLanguageFluency>;
	enrollmentApplicationSubmissionID: Maybe<Scalars["ID"]>;
	firstName: Maybe<Scalars["String"]>;
	formIdentifier: Maybe<Scalars["String"]>;
	gender: Maybe<EnrollmentApplicationStudentHistoryGender>;
	genderCustom: Maybe<Scalars["String"]>;
	gradeLevelApplyingFor: Maybe<EnrollmentApplicationStudentHistoryGradeLevelApplyingFor>;
	hasBehaviorDisciplineChallenges: Maybe<Scalars["Boolean"]>;
	hasLearningDifficulties: Maybe<Scalars["Boolean"]>;
	hasMedicalPhysicalExceptionalities: Maybe<Scalars["Boolean"]>;
	hasPreviousSchooling: Maybe<Scalars["Boolean"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	imageContentType: Maybe<Scalars["String"]>;
	lastName: Maybe<Scalars["String"]>;
	learningDifficultiesDescription: Maybe<Scalars["String"]>;
	medicalPhysicalExceptionalitiesDescription: Maybe<Scalars["String"]>;
	nationality: Maybe<Scalars["String"]>;
	nickname: Maybe<Scalars["String"]>;
	originalID: Maybe<Scalars["ID"]>;
	previousSchoolInformation: Maybe<Scalars["String"]>;
	previousSchoolLocation: Maybe<Scalars["String"]>;
	primaryLanguage: Maybe<Scalars["String"]>;
	studentID: Maybe<Scalars["ID"]>;
};

export type EnrollmentApplicationStudentHistoryConnection = {
	__typename?: "EnrollmentApplicationStudentHistoryConnection";
	edges: Array<EnrollmentApplicationStudentHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export enum EnrollmentApplicationStudentHistoryCurrentGradeLevel {
	None = "none",
	Nursery = "nursery",
	Reception = "reception",
	Year1 = "year_1",
	Year2 = "year_2",
	Year3 = "year_3",
	Year4 = "year_4",
	Year5 = "year_5",
	Year6 = "year_6",
	Year7 = "year_7",
}

export type EnrollmentApplicationStudentHistoryEdge = {
	__typename?: "EnrollmentApplicationStudentHistoryEdge";
	cursor: Scalars["Cursor"];
	node: EnrollmentApplicationStudentHistory;
};

export enum EnrollmentApplicationStudentHistoryEnglishLanguageFluency {
	Average = "average",
	Basic = "basic",
	Excellent = "excellent",
	None = "none",
	NotSpecified = "not_specified",
}

export type EnrollmentApplicationStudentHistoryFilterInput = {
	additionalInformationContains?: InputMaybe<Scalars["String"]>;
	additionalInformationEQ?: InputMaybe<Scalars["String"]>;
	additionalInformationHasPrefix?: InputMaybe<Scalars["String"]>;
	additionalInformationHasSuffix?: InputMaybe<Scalars["String"]>;
	additionalInformationIn?: InputMaybe<Array<Scalars["String"]>>;
	additionalInformationIsNull?: InputMaybe<Scalars["Boolean"]>;
	additionalInformationNEQ?: InputMaybe<Scalars["String"]>;
	additionalInformationNotContains?: InputMaybe<Scalars["String"]>;
	additionalInformationNotHasPrefix?: InputMaybe<Scalars["String"]>;
	additionalInformationNotHasSuffix?: InputMaybe<Scalars["String"]>;
	additionalInformationNotIn?: InputMaybe<Array<Scalars["String"]>>;
	and?: InputMaybe<Array<EnrollmentApplicationStudentHistoryFilterInput>>;
	behaviorDisciplineChallengesDescriptionContains?: InputMaybe<Scalars["String"]>;
	behaviorDisciplineChallengesDescriptionEQ?: InputMaybe<Scalars["String"]>;
	behaviorDisciplineChallengesDescriptionHasPrefix?: InputMaybe<Scalars["String"]>;
	behaviorDisciplineChallengesDescriptionHasSuffix?: InputMaybe<Scalars["String"]>;
	behaviorDisciplineChallengesDescriptionIn?: InputMaybe<Array<Scalars["String"]>>;
	behaviorDisciplineChallengesDescriptionIsNull?: InputMaybe<Scalars["Boolean"]>;
	behaviorDisciplineChallengesDescriptionNEQ?: InputMaybe<Scalars["String"]>;
	behaviorDisciplineChallengesDescriptionNotContains?: InputMaybe<Scalars["String"]>;
	behaviorDisciplineChallengesDescriptionNotHasPrefix?: InputMaybe<Scalars["String"]>;
	behaviorDisciplineChallengesDescriptionNotHasSuffix?: InputMaybe<Scalars["String"]>;
	behaviorDisciplineChallengesDescriptionNotIn?: InputMaybe<Array<Scalars["String"]>>;
	currentGradeLevelEQ?: InputMaybe<EnrollmentApplicationStudentHistoryCurrentGradeLevel>;
	currentGradeLevelIn?: InputMaybe<Array<EnrollmentApplicationStudentHistoryCurrentGradeLevel>>;
	currentGradeLevelIsNull?: InputMaybe<Scalars["Boolean"]>;
	currentGradeLevelNEQ?: InputMaybe<EnrollmentApplicationStudentHistoryCurrentGradeLevel>;
	currentGradeLevelNotIn?: InputMaybe<Array<EnrollmentApplicationStudentHistoryCurrentGradeLevel>>;
	dateOfBirthEQ?: InputMaybe<Scalars["Time"]>;
	dateOfBirthGT?: InputMaybe<Scalars["Time"]>;
	dateOfBirthGTE?: InputMaybe<Scalars["Time"]>;
	dateOfBirthIn?: InputMaybe<Array<Scalars["Time"]>>;
	dateOfBirthIsNull?: InputMaybe<Scalars["Boolean"]>;
	dateOfBirthLT?: InputMaybe<Scalars["Time"]>;
	dateOfBirthLTE?: InputMaybe<Scalars["Time"]>;
	dateOfBirthNEQ?: InputMaybe<Scalars["Time"]>;
	dateOfBirthNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	englishLanguageFluencyEQ?: InputMaybe<EnrollmentApplicationStudentHistoryEnglishLanguageFluency>;
	englishLanguageFluencyIn?: InputMaybe<Array<EnrollmentApplicationStudentHistoryEnglishLanguageFluency>>;
	englishLanguageFluencyIsNull?: InputMaybe<Scalars["Boolean"]>;
	englishLanguageFluencyNEQ?: InputMaybe<EnrollmentApplicationStudentHistoryEnglishLanguageFluency>;
	englishLanguageFluencyNotIn?: InputMaybe<Array<EnrollmentApplicationStudentHistoryEnglishLanguageFluency>>;
	enrollmentApplicationSubmissionIDEQ?: InputMaybe<Scalars["ID"]>;
	enrollmentApplicationSubmissionIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	enrollmentApplicationSubmissionIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	enrollmentApplicationSubmissionIDNEQ?: InputMaybe<Scalars["ID"]>;
	enrollmentApplicationSubmissionIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	firstNameContains?: InputMaybe<Scalars["String"]>;
	firstNameEQ?: InputMaybe<Scalars["String"]>;
	firstNameHasPrefix?: InputMaybe<Scalars["String"]>;
	firstNameHasSuffix?: InputMaybe<Scalars["String"]>;
	firstNameIn?: InputMaybe<Array<Scalars["String"]>>;
	firstNameIsNull?: InputMaybe<Scalars["Boolean"]>;
	firstNameNEQ?: InputMaybe<Scalars["String"]>;
	firstNameNotContains?: InputMaybe<Scalars["String"]>;
	firstNameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	firstNameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	firstNameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	formIdentifierContains?: InputMaybe<Scalars["String"]>;
	formIdentifierEQ?: InputMaybe<Scalars["String"]>;
	formIdentifierHasPrefix?: InputMaybe<Scalars["String"]>;
	formIdentifierHasSuffix?: InputMaybe<Scalars["String"]>;
	formIdentifierIn?: InputMaybe<Array<Scalars["String"]>>;
	formIdentifierIsNull?: InputMaybe<Scalars["Boolean"]>;
	formIdentifierNEQ?: InputMaybe<Scalars["String"]>;
	formIdentifierNotContains?: InputMaybe<Scalars["String"]>;
	formIdentifierNotHasPrefix?: InputMaybe<Scalars["String"]>;
	formIdentifierNotHasSuffix?: InputMaybe<Scalars["String"]>;
	formIdentifierNotIn?: InputMaybe<Array<Scalars["String"]>>;
	genderCustomContains?: InputMaybe<Scalars["String"]>;
	genderCustomEQ?: InputMaybe<Scalars["String"]>;
	genderCustomHasPrefix?: InputMaybe<Scalars["String"]>;
	genderCustomHasSuffix?: InputMaybe<Scalars["String"]>;
	genderCustomIn?: InputMaybe<Array<Scalars["String"]>>;
	genderCustomIsNull?: InputMaybe<Scalars["Boolean"]>;
	genderCustomNEQ?: InputMaybe<Scalars["String"]>;
	genderCustomNotContains?: InputMaybe<Scalars["String"]>;
	genderCustomNotHasPrefix?: InputMaybe<Scalars["String"]>;
	genderCustomNotHasSuffix?: InputMaybe<Scalars["String"]>;
	genderCustomNotIn?: InputMaybe<Array<Scalars["String"]>>;
	genderEQ?: InputMaybe<EnrollmentApplicationStudentHistoryGender>;
	genderIn?: InputMaybe<Array<EnrollmentApplicationStudentHistoryGender>>;
	genderIsNull?: InputMaybe<Scalars["Boolean"]>;
	genderNEQ?: InputMaybe<EnrollmentApplicationStudentHistoryGender>;
	genderNotIn?: InputMaybe<Array<EnrollmentApplicationStudentHistoryGender>>;
	gradeLevelApplyingForEQ?: InputMaybe<EnrollmentApplicationStudentHistoryGradeLevelApplyingFor>;
	gradeLevelApplyingForIn?: InputMaybe<Array<EnrollmentApplicationStudentHistoryGradeLevelApplyingFor>>;
	gradeLevelApplyingForIsNull?: InputMaybe<Scalars["Boolean"]>;
	gradeLevelApplyingForNEQ?: InputMaybe<EnrollmentApplicationStudentHistoryGradeLevelApplyingFor>;
	gradeLevelApplyingForNotIn?: InputMaybe<Array<EnrollmentApplicationStudentHistoryGradeLevelApplyingFor>>;
	hasBehaviorDisciplineChallengesEQ?: InputMaybe<Scalars["Boolean"]>;
	hasBehaviorDisciplineChallengesIsNull?: InputMaybe<Scalars["Boolean"]>;
	hasBehaviorDisciplineChallengesNEQ?: InputMaybe<Scalars["Boolean"]>;
	hasLearningDifficultiesEQ?: InputMaybe<Scalars["Boolean"]>;
	hasLearningDifficultiesIsNull?: InputMaybe<Scalars["Boolean"]>;
	hasLearningDifficultiesNEQ?: InputMaybe<Scalars["Boolean"]>;
	hasMedicalPhysicalExceptionalitiesEQ?: InputMaybe<Scalars["Boolean"]>;
	hasMedicalPhysicalExceptionalitiesIsNull?: InputMaybe<Scalars["Boolean"]>;
	hasMedicalPhysicalExceptionalitiesNEQ?: InputMaybe<Scalars["Boolean"]>;
	hasPreviousSchoolingEQ?: InputMaybe<Scalars["Boolean"]>;
	hasPreviousSchoolingIsNull?: InputMaybe<Scalars["Boolean"]>;
	hasPreviousSchoolingNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	imageContentTypeContains?: InputMaybe<Scalars["String"]>;
	imageContentTypeEQ?: InputMaybe<Scalars["String"]>;
	imageContentTypeHasPrefix?: InputMaybe<Scalars["String"]>;
	imageContentTypeHasSuffix?: InputMaybe<Scalars["String"]>;
	imageContentTypeIn?: InputMaybe<Array<Scalars["String"]>>;
	imageContentTypeIsNull?: InputMaybe<Scalars["Boolean"]>;
	imageContentTypeNEQ?: InputMaybe<Scalars["String"]>;
	imageContentTypeNotContains?: InputMaybe<Scalars["String"]>;
	imageContentTypeNotHasPrefix?: InputMaybe<Scalars["String"]>;
	imageContentTypeNotHasSuffix?: InputMaybe<Scalars["String"]>;
	imageContentTypeNotIn?: InputMaybe<Array<Scalars["String"]>>;
	lastNameContains?: InputMaybe<Scalars["String"]>;
	lastNameEQ?: InputMaybe<Scalars["String"]>;
	lastNameHasPrefix?: InputMaybe<Scalars["String"]>;
	lastNameHasSuffix?: InputMaybe<Scalars["String"]>;
	lastNameIn?: InputMaybe<Array<Scalars["String"]>>;
	lastNameIsNull?: InputMaybe<Scalars["Boolean"]>;
	lastNameNEQ?: InputMaybe<Scalars["String"]>;
	lastNameNotContains?: InputMaybe<Scalars["String"]>;
	lastNameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	lastNameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	lastNameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	learningDifficultiesDescriptionContains?: InputMaybe<Scalars["String"]>;
	learningDifficultiesDescriptionEQ?: InputMaybe<Scalars["String"]>;
	learningDifficultiesDescriptionHasPrefix?: InputMaybe<Scalars["String"]>;
	learningDifficultiesDescriptionHasSuffix?: InputMaybe<Scalars["String"]>;
	learningDifficultiesDescriptionIn?: InputMaybe<Array<Scalars["String"]>>;
	learningDifficultiesDescriptionIsNull?: InputMaybe<Scalars["Boolean"]>;
	learningDifficultiesDescriptionNEQ?: InputMaybe<Scalars["String"]>;
	learningDifficultiesDescriptionNotContains?: InputMaybe<Scalars["String"]>;
	learningDifficultiesDescriptionNotHasPrefix?: InputMaybe<Scalars["String"]>;
	learningDifficultiesDescriptionNotHasSuffix?: InputMaybe<Scalars["String"]>;
	learningDifficultiesDescriptionNotIn?: InputMaybe<Array<Scalars["String"]>>;
	medicalPhysicalExceptionalitiesDescriptionContains?: InputMaybe<Scalars["String"]>;
	medicalPhysicalExceptionalitiesDescriptionEQ?: InputMaybe<Scalars["String"]>;
	medicalPhysicalExceptionalitiesDescriptionHasPrefix?: InputMaybe<Scalars["String"]>;
	medicalPhysicalExceptionalitiesDescriptionHasSuffix?: InputMaybe<Scalars["String"]>;
	medicalPhysicalExceptionalitiesDescriptionIn?: InputMaybe<Array<Scalars["String"]>>;
	medicalPhysicalExceptionalitiesDescriptionIsNull?: InputMaybe<Scalars["Boolean"]>;
	medicalPhysicalExceptionalitiesDescriptionNEQ?: InputMaybe<Scalars["String"]>;
	medicalPhysicalExceptionalitiesDescriptionNotContains?: InputMaybe<Scalars["String"]>;
	medicalPhysicalExceptionalitiesDescriptionNotHasPrefix?: InputMaybe<Scalars["String"]>;
	medicalPhysicalExceptionalitiesDescriptionNotHasSuffix?: InputMaybe<Scalars["String"]>;
	medicalPhysicalExceptionalitiesDescriptionNotIn?: InputMaybe<Array<Scalars["String"]>>;
	nationalityContains?: InputMaybe<Scalars["String"]>;
	nationalityEQ?: InputMaybe<Scalars["String"]>;
	nationalityHasPrefix?: InputMaybe<Scalars["String"]>;
	nationalityHasSuffix?: InputMaybe<Scalars["String"]>;
	nationalityIn?: InputMaybe<Array<Scalars["String"]>>;
	nationalityIsNull?: InputMaybe<Scalars["Boolean"]>;
	nationalityNEQ?: InputMaybe<Scalars["String"]>;
	nationalityNotContains?: InputMaybe<Scalars["String"]>;
	nationalityNotHasPrefix?: InputMaybe<Scalars["String"]>;
	nationalityNotHasSuffix?: InputMaybe<Scalars["String"]>;
	nationalityNotIn?: InputMaybe<Array<Scalars["String"]>>;
	nicknameContains?: InputMaybe<Scalars["String"]>;
	nicknameEQ?: InputMaybe<Scalars["String"]>;
	nicknameHasPrefix?: InputMaybe<Scalars["String"]>;
	nicknameHasSuffix?: InputMaybe<Scalars["String"]>;
	nicknameIn?: InputMaybe<Array<Scalars["String"]>>;
	nicknameIsNull?: InputMaybe<Scalars["Boolean"]>;
	nicknameNEQ?: InputMaybe<Scalars["String"]>;
	nicknameNotContains?: InputMaybe<Scalars["String"]>;
	nicknameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	nicknameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	nicknameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	not?: InputMaybe<EnrollmentApplicationStudentHistoryFilterInput>;
	or?: InputMaybe<Array<EnrollmentApplicationStudentHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	previousSchoolInformationContains?: InputMaybe<Scalars["String"]>;
	previousSchoolInformationEQ?: InputMaybe<Scalars["String"]>;
	previousSchoolInformationHasPrefix?: InputMaybe<Scalars["String"]>;
	previousSchoolInformationHasSuffix?: InputMaybe<Scalars["String"]>;
	previousSchoolInformationIn?: InputMaybe<Array<Scalars["String"]>>;
	previousSchoolInformationIsNull?: InputMaybe<Scalars["Boolean"]>;
	previousSchoolInformationNEQ?: InputMaybe<Scalars["String"]>;
	previousSchoolInformationNotContains?: InputMaybe<Scalars["String"]>;
	previousSchoolInformationNotHasPrefix?: InputMaybe<Scalars["String"]>;
	previousSchoolInformationNotHasSuffix?: InputMaybe<Scalars["String"]>;
	previousSchoolInformationNotIn?: InputMaybe<Array<Scalars["String"]>>;
	previousSchoolLocationContains?: InputMaybe<Scalars["String"]>;
	previousSchoolLocationEQ?: InputMaybe<Scalars["String"]>;
	previousSchoolLocationHasPrefix?: InputMaybe<Scalars["String"]>;
	previousSchoolLocationHasSuffix?: InputMaybe<Scalars["String"]>;
	previousSchoolLocationIn?: InputMaybe<Array<Scalars["String"]>>;
	previousSchoolLocationIsNull?: InputMaybe<Scalars["Boolean"]>;
	previousSchoolLocationNEQ?: InputMaybe<Scalars["String"]>;
	previousSchoolLocationNotContains?: InputMaybe<Scalars["String"]>;
	previousSchoolLocationNotHasPrefix?: InputMaybe<Scalars["String"]>;
	previousSchoolLocationNotHasSuffix?: InputMaybe<Scalars["String"]>;
	previousSchoolLocationNotIn?: InputMaybe<Array<Scalars["String"]>>;
	primaryLanguageContains?: InputMaybe<Scalars["String"]>;
	primaryLanguageEQ?: InputMaybe<Scalars["String"]>;
	primaryLanguageHasPrefix?: InputMaybe<Scalars["String"]>;
	primaryLanguageHasSuffix?: InputMaybe<Scalars["String"]>;
	primaryLanguageIn?: InputMaybe<Array<Scalars["String"]>>;
	primaryLanguageIsNull?: InputMaybe<Scalars["Boolean"]>;
	primaryLanguageNEQ?: InputMaybe<Scalars["String"]>;
	primaryLanguageNotContains?: InputMaybe<Scalars["String"]>;
	primaryLanguageNotHasPrefix?: InputMaybe<Scalars["String"]>;
	primaryLanguageNotHasSuffix?: InputMaybe<Scalars["String"]>;
	primaryLanguageNotIn?: InputMaybe<Array<Scalars["String"]>>;
	search?: InputMaybe<Scalars["String"]>;
	studentIDEQ?: InputMaybe<Scalars["ID"]>;
	studentIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	studentIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	studentIDNEQ?: InputMaybe<Scalars["ID"]>;
	studentIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export enum EnrollmentApplicationStudentHistoryGender {
	Female = "female",
	Male = "male",
	Other = "other",
}

export enum EnrollmentApplicationStudentHistoryGradeLevelApplyingFor {
	Nursery = "nursery",
	Reception = "reception",
	Year1 = "year_1",
	Year2 = "year_2",
	Year3 = "year_3",
	Year4 = "year_4",
	Year5 = "year_5",
	Year6 = "year_6",
	Year7 = "year_7",
	Year8 = "year_8",
}

export type EnrollmentApplicationStudentHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<EnrollmentApplicationStudentHistoryOrderField>;
};

export enum EnrollmentApplicationStudentHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type EnrollmentApplicationStudentInStudentCreateInput = {
	additionalInformation?: InputMaybe<Scalars["String"]>;
	behaviorDisciplineChallengesDescription?: InputMaybe<Scalars["String"]>;
	currentGradeLevel: EnrollmentApplicationStudentCurrentGradeLevel;
	dateOfBirth: Scalars["Time"];
	englishLanguageFluency?: InputMaybe<EnrollmentApplicationStudentEnglishLanguageFluency>;
	enrollmentApplicationID?: InputMaybe<Scalars["ID"]>;
	enrollmentApplicationSubmissionID: Scalars["ID"];
	firstName: Scalars["String"];
	formIdentifier: Scalars["String"];
	gender?: InputMaybe<EnrollmentApplicationStudentGender>;
	genderCustom?: InputMaybe<Scalars["String"]>;
	gradeLevelApplyingFor: EnrollmentApplicationStudentGradeLevelApplyingFor;
	hasBehaviorDisciplineChallenges?: InputMaybe<Scalars["Boolean"]>;
	hasLearningDifficulties?: InputMaybe<Scalars["Boolean"]>;
	hasMedicalPhysicalExceptionalities?: InputMaybe<Scalars["Boolean"]>;
	hasPreviousSchooling?: InputMaybe<Scalars["Boolean"]>;
	image?: InputMaybe<Scalars["Upload"]>;
	lastName: Scalars["String"];
	learningDifficultiesDescription?: InputMaybe<Scalars["String"]>;
	medicalPhysicalExceptionalitiesDescription?: InputMaybe<Scalars["String"]>;
	nationality?: InputMaybe<Scalars["String"]>;
	nickname?: InputMaybe<Scalars["String"]>;
	previousSchoolInformation?: InputMaybe<Scalars["String"]>;
	previousSchoolLocation?: InputMaybe<Scalars["String"]>;
	primaryLanguage?: InputMaybe<Scalars["String"]>;
};

export type EnrollmentApplicationStudentOrder = {
	direction: OrderDirection;
	field?: InputMaybe<EnrollmentApplicationStudentOrderField>;
};

export enum EnrollmentApplicationStudentOrderField {
	CurrentGradeLevel = "CURRENT_GRADE_LEVEL",
	DateOfBirth = "DATE_OF_BIRTH",
	EnglishLanguageFluency = "ENGLISH_LANGUAGE_FLUENCY",
	FirstName = "FIRST_NAME",
	Gender = "GENDER",
	GenderCustom = "GENDER_CUSTOM",
	GradeLevelApplyingFor = "GRADE_LEVEL_APPLYING_FOR",
	HasBehaviorDisciplineChallenges = "HAS_BEHAVIOR_DISCIPLINE_CHALLENGES",
	HasLearningDifficulties = "HAS_LEARNING_DIFFICULTIES",
	HasMedicalPhysicalExceptionalities = "HAS_MEDICAL_PHYSICAL_EXCEPTIONALITIES",
	HasPreviousSchooling = "HAS_PREVIOUS_SCHOOLING",
	LastName = "LAST_NAME",
	Nationality = "NATIONALITY",
	Nickname = "NICKNAME",
	PrimaryLanguage = "PRIMARY_LANGUAGE",
}

export type EnrollmentApplicationStudentUpdateBulkInput = {
	additionalInformation?: InputMaybe<Scalars["String"]>;
	behaviorDisciplineChallengesDescription?: InputMaybe<Scalars["String"]>;
	clearGender?: InputMaybe<Scalars["Boolean"]>;
	clearImage?: InputMaybe<Scalars["Boolean"]>;
	currentGradeLevel?: InputMaybe<EnrollmentApplicationStudentCurrentGradeLevel>;
	dateOfBirth?: InputMaybe<Scalars["Time"]>;
	englishLanguageFluency?: InputMaybe<EnrollmentApplicationStudentEnglishLanguageFluency>;
	enrollmentApplicationSubmissionID?: InputMaybe<Scalars["ID"]>;
	firstName?: InputMaybe<Scalars["String"]>;
	formIdentifier?: InputMaybe<Scalars["String"]>;
	gender?: InputMaybe<EnrollmentApplicationStudentGender>;
	genderCustom?: InputMaybe<Scalars["String"]>;
	gradeLevelApplyingFor?: InputMaybe<EnrollmentApplicationStudentGradeLevelApplyingFor>;
	hasBehaviorDisciplineChallenges?: InputMaybe<Scalars["Boolean"]>;
	hasLearningDifficulties?: InputMaybe<Scalars["Boolean"]>;
	hasMedicalPhysicalExceptionalities?: InputMaybe<Scalars["Boolean"]>;
	hasPreviousSchooling?: InputMaybe<Scalars["Boolean"]>;
	image?: InputMaybe<Scalars["Upload"]>;
	lastName?: InputMaybe<Scalars["String"]>;
	learningDifficultiesDescription?: InputMaybe<Scalars["String"]>;
	medicalPhysicalExceptionalitiesDescription?: InputMaybe<Scalars["String"]>;
	nationality?: InputMaybe<Scalars["String"]>;
	nickname?: InputMaybe<Scalars["String"]>;
	previousSchoolInformation?: InputMaybe<Scalars["String"]>;
	previousSchoolLocation?: InputMaybe<Scalars["String"]>;
	primaryLanguage?: InputMaybe<Scalars["String"]>;
};

export type EnrollmentApplicationStudentUpdateInput = {
	additionalInformation?: InputMaybe<Scalars["String"]>;
	behaviorDisciplineChallengesDescription?: InputMaybe<Scalars["String"]>;
	clearEnrollmentApplication?: InputMaybe<Scalars["Boolean"]>;
	clearGender?: InputMaybe<Scalars["Boolean"]>;
	clearImage?: InputMaybe<Scalars["Boolean"]>;
	clearStudent?: InputMaybe<Scalars["Boolean"]>;
	currentGradeLevel?: InputMaybe<EnrollmentApplicationStudentCurrentGradeLevel>;
	dateOfBirth?: InputMaybe<Scalars["Time"]>;
	englishLanguageFluency?: InputMaybe<EnrollmentApplicationStudentEnglishLanguageFluency>;
	enrollmentApplicationID?: InputMaybe<Scalars["ID"]>;
	enrollmentApplicationSubmissionID?: InputMaybe<Scalars["ID"]>;
	firstName?: InputMaybe<Scalars["String"]>;
	formIdentifier?: InputMaybe<Scalars["String"]>;
	gender?: InputMaybe<EnrollmentApplicationStudentGender>;
	genderCustom?: InputMaybe<Scalars["String"]>;
	gradeLevelApplyingFor?: InputMaybe<EnrollmentApplicationStudentGradeLevelApplyingFor>;
	hasBehaviorDisciplineChallenges?: InputMaybe<Scalars["Boolean"]>;
	hasLearningDifficulties?: InputMaybe<Scalars["Boolean"]>;
	hasMedicalPhysicalExceptionalities?: InputMaybe<Scalars["Boolean"]>;
	hasPreviousSchooling?: InputMaybe<Scalars["Boolean"]>;
	image?: InputMaybe<Scalars["Upload"]>;
	lastName?: InputMaybe<Scalars["String"]>;
	learningDifficultiesDescription?: InputMaybe<Scalars["String"]>;
	medicalPhysicalExceptionalitiesDescription?: InputMaybe<Scalars["String"]>;
	nationality?: InputMaybe<Scalars["String"]>;
	nickname?: InputMaybe<Scalars["String"]>;
	previousSchoolInformation?: InputMaybe<Scalars["String"]>;
	previousSchoolLocation?: InputMaybe<Scalars["String"]>;
	primaryLanguage?: InputMaybe<Scalars["String"]>;
	studentID?: InputMaybe<Scalars["ID"]>;
};

export type EnrollmentApplicationSubmission = Node & {
	__typename?: "EnrollmentApplicationSubmission";
	createdAt: Scalars["Time"];
	doesConsentToDataUse: Scalars["Boolean"];
	enrollmentApplicationParents: Array<EnrollmentApplicationParent>;
	enrollmentApplicationStudents: Array<EnrollmentApplicationStudent>;
	id: Scalars["ID"];
	signatureDate: Scalars["Time"];
	signatureName: Scalars["String"];
	source: Scalars["String"];
};

export type EnrollmentApplicationSubmissionEnrollmentApplicationParentsArgs = {
	filters?: InputMaybe<Array<EnrollmentApplicationParentFilterInput>>;
	order?: InputMaybe<EnrollmentApplicationParentOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type EnrollmentApplicationSubmissionEnrollmentApplicationStudentsArgs = {
	filters?: InputMaybe<Array<EnrollmentApplicationStudentFilterInput>>;
	order?: InputMaybe<EnrollmentApplicationStudentOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type EnrollmentApplicationSubmissionConnection = {
	__typename?: "EnrollmentApplicationSubmissionConnection";
	edges: Array<EnrollmentApplicationSubmissionEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type EnrollmentApplicationSubmissionEdge = {
	__typename?: "EnrollmentApplicationSubmissionEdge";
	cursor: Scalars["Cursor"];
	node: EnrollmentApplicationSubmission;
};

export type EnrollmentApplicationSubmissionFilterInput = {
	and?: InputMaybe<Array<EnrollmentApplicationSubmissionFilterInput>>;
	createdAtEQ?: InputMaybe<Scalars["Time"]>;
	createdAtGT?: InputMaybe<Scalars["Time"]>;
	createdAtGTE?: InputMaybe<Scalars["Time"]>;
	createdAtIn?: InputMaybe<Array<Scalars["Time"]>>;
	createdAtLT?: InputMaybe<Scalars["Time"]>;
	createdAtLTE?: InputMaybe<Scalars["Time"]>;
	createdAtNEQ?: InputMaybe<Scalars["Time"]>;
	createdAtNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	doesConsentToDataUseEQ?: InputMaybe<Scalars["Boolean"]>;
	doesConsentToDataUseNEQ?: InputMaybe<Scalars["Boolean"]>;
	enrollmentApplicationParents?: InputMaybe<Array<EnrollmentApplicationParentFilterInput>>;
	enrollmentApplicationStudents?: InputMaybe<Array<EnrollmentApplicationStudentFilterInput>>;
	hasEnrollmentApplicationParents?: InputMaybe<Scalars["Boolean"]>;
	hasEnrollmentApplicationStudents?: InputMaybe<Scalars["Boolean"]>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<EnrollmentApplicationSubmissionFilterInput>;
	or?: InputMaybe<Array<EnrollmentApplicationSubmissionFilterInput>>;
	search?: InputMaybe<Scalars["String"]>;
	signatureDateEQ?: InputMaybe<Scalars["Time"]>;
	signatureDateGT?: InputMaybe<Scalars["Time"]>;
	signatureDateGTE?: InputMaybe<Scalars["Time"]>;
	signatureDateIn?: InputMaybe<Array<Scalars["Time"]>>;
	signatureDateLT?: InputMaybe<Scalars["Time"]>;
	signatureDateLTE?: InputMaybe<Scalars["Time"]>;
	signatureDateNEQ?: InputMaybe<Scalars["Time"]>;
	signatureDateNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	signatureNameContains?: InputMaybe<Scalars["String"]>;
	signatureNameEQ?: InputMaybe<Scalars["String"]>;
	signatureNameHasPrefix?: InputMaybe<Scalars["String"]>;
	signatureNameHasSuffix?: InputMaybe<Scalars["String"]>;
	signatureNameIn?: InputMaybe<Array<Scalars["String"]>>;
	signatureNameNEQ?: InputMaybe<Scalars["String"]>;
	signatureNameNotContains?: InputMaybe<Scalars["String"]>;
	signatureNameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	signatureNameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	signatureNameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	sourceContains?: InputMaybe<Scalars["String"]>;
	sourceEQ?: InputMaybe<Scalars["String"]>;
	sourceHasPrefix?: InputMaybe<Scalars["String"]>;
	sourceHasSuffix?: InputMaybe<Scalars["String"]>;
	sourceIn?: InputMaybe<Array<Scalars["String"]>>;
	sourceNEQ?: InputMaybe<Scalars["String"]>;
	sourceNotContains?: InputMaybe<Scalars["String"]>;
	sourceNotHasPrefix?: InputMaybe<Scalars["String"]>;
	sourceNotHasSuffix?: InputMaybe<Scalars["String"]>;
	sourceNotIn?: InputMaybe<Array<Scalars["String"]>>;
};

export type EnrollmentApplicationSubmissionHistory = Node & {
	__typename?: "EnrollmentApplicationSubmissionHistory";
	createdAt: Maybe<Scalars["Time"]>;
	doesConsentToDataUse: Maybe<Scalars["Boolean"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	originalID: Maybe<Scalars["ID"]>;
	signatureDate: Maybe<Scalars["Time"]>;
	signatureName: Maybe<Scalars["String"]>;
	source: Maybe<Scalars["String"]>;
};

export type EnrollmentApplicationSubmissionHistoryConnection = {
	__typename?: "EnrollmentApplicationSubmissionHistoryConnection";
	edges: Array<EnrollmentApplicationSubmissionHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type EnrollmentApplicationSubmissionHistoryEdge = {
	__typename?: "EnrollmentApplicationSubmissionHistoryEdge";
	cursor: Scalars["Cursor"];
	node: EnrollmentApplicationSubmissionHistory;
};

export type EnrollmentApplicationSubmissionHistoryFilterInput = {
	and?: InputMaybe<Array<EnrollmentApplicationSubmissionHistoryFilterInput>>;
	createdAtEQ?: InputMaybe<Scalars["Time"]>;
	createdAtGT?: InputMaybe<Scalars["Time"]>;
	createdAtGTE?: InputMaybe<Scalars["Time"]>;
	createdAtIn?: InputMaybe<Array<Scalars["Time"]>>;
	createdAtIsNull?: InputMaybe<Scalars["Boolean"]>;
	createdAtLT?: InputMaybe<Scalars["Time"]>;
	createdAtLTE?: InputMaybe<Scalars["Time"]>;
	createdAtNEQ?: InputMaybe<Scalars["Time"]>;
	createdAtNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	doesConsentToDataUseEQ?: InputMaybe<Scalars["Boolean"]>;
	doesConsentToDataUseIsNull?: InputMaybe<Scalars["Boolean"]>;
	doesConsentToDataUseNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<EnrollmentApplicationSubmissionHistoryFilterInput>;
	or?: InputMaybe<Array<EnrollmentApplicationSubmissionHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	search?: InputMaybe<Scalars["String"]>;
	signatureDateEQ?: InputMaybe<Scalars["Time"]>;
	signatureDateGT?: InputMaybe<Scalars["Time"]>;
	signatureDateGTE?: InputMaybe<Scalars["Time"]>;
	signatureDateIn?: InputMaybe<Array<Scalars["Time"]>>;
	signatureDateIsNull?: InputMaybe<Scalars["Boolean"]>;
	signatureDateLT?: InputMaybe<Scalars["Time"]>;
	signatureDateLTE?: InputMaybe<Scalars["Time"]>;
	signatureDateNEQ?: InputMaybe<Scalars["Time"]>;
	signatureDateNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	signatureNameContains?: InputMaybe<Scalars["String"]>;
	signatureNameEQ?: InputMaybe<Scalars["String"]>;
	signatureNameHasPrefix?: InputMaybe<Scalars["String"]>;
	signatureNameHasSuffix?: InputMaybe<Scalars["String"]>;
	signatureNameIn?: InputMaybe<Array<Scalars["String"]>>;
	signatureNameIsNull?: InputMaybe<Scalars["Boolean"]>;
	signatureNameNEQ?: InputMaybe<Scalars["String"]>;
	signatureNameNotContains?: InputMaybe<Scalars["String"]>;
	signatureNameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	signatureNameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	signatureNameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	sourceContains?: InputMaybe<Scalars["String"]>;
	sourceEQ?: InputMaybe<Scalars["String"]>;
	sourceHasPrefix?: InputMaybe<Scalars["String"]>;
	sourceHasSuffix?: InputMaybe<Scalars["String"]>;
	sourceIn?: InputMaybe<Array<Scalars["String"]>>;
	sourceIsNull?: InputMaybe<Scalars["Boolean"]>;
	sourceNEQ?: InputMaybe<Scalars["String"]>;
	sourceNotContains?: InputMaybe<Scalars["String"]>;
	sourceNotHasPrefix?: InputMaybe<Scalars["String"]>;
	sourceNotHasSuffix?: InputMaybe<Scalars["String"]>;
	sourceNotIn?: InputMaybe<Array<Scalars["String"]>>;
};

export type EnrollmentApplicationSubmissionHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<EnrollmentApplicationSubmissionHistoryOrderField>;
};

export enum EnrollmentApplicationSubmissionHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type EnrollmentApplicationSubmissionOrder = {
	direction: OrderDirection;
	field?: InputMaybe<EnrollmentApplicationSubmissionOrderField>;
};

export enum EnrollmentApplicationSubmissionOrderField {
	CreatedAt = "CREATED_AT",
	DoesConsentToDataUse = "DOES_CONSENT_TO_DATA_USE",
	SignatureDate = "SIGNATURE_DATE",
	SignatureName = "SIGNATURE_NAME",
	Source = "SOURCE",
}

export type EnrollmentApplicationSubmissionUpdateBulkInput = {
	addEnrollmentApplicationParentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addEnrollmentApplicationStudentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	doesConsentToDataUse?: InputMaybe<Scalars["Boolean"]>;
	removeEnrollmentApplicationParentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeEnrollmentApplicationStudentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	signatureName?: InputMaybe<Scalars["String"]>;
	source?: InputMaybe<Scalars["String"]>;
};

export type EnrollmentApplicationSubmissionUpdateInput = {
	addEnrollmentApplicationParentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addEnrollmentApplicationStudentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	doesConsentToDataUse?: InputMaybe<Scalars["Boolean"]>;
	removeEnrollmentApplicationParentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeEnrollmentApplicationStudentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	signatureName?: InputMaybe<Scalars["String"]>;
	source?: InputMaybe<Scalars["String"]>;
};

export type EnrollmentApplicationUpdateBulkInput = {
	addEnrollmentApplicationNoteIDs?: InputMaybe<Array<Scalars["ID"]>>;
	clearInterviewMeetingTime?: InputMaybe<Scalars["Boolean"]>;
	externalLink?: InputMaybe<Scalars["String"]>;
	interviewMeetingTime?: InputMaybe<Scalars["Time"]>;
	isArchived?: InputMaybe<Scalars["Boolean"]>;
	removeEnrollmentApplicationNoteIDs?: InputMaybe<Array<Scalars["ID"]>>;
	status?: InputMaybe<EnrollmentApplicationStatus>;
};

export type EnrollmentApplicationUpdateInput = {
	addEnrollmentApplicationNoteIDs?: InputMaybe<Array<Scalars["ID"]>>;
	clearInterviewMeetingTime?: InputMaybe<Scalars["Boolean"]>;
	enrollmentApplicationStudentID?: InputMaybe<Scalars["ID"]>;
	externalLink?: InputMaybe<Scalars["String"]>;
	interviewMeetingTime?: InputMaybe<Scalars["Time"]>;
	isArchived?: InputMaybe<Scalars["Boolean"]>;
	removeEnrollmentApplicationNoteIDs?: InputMaybe<Array<Scalars["ID"]>>;
	status?: InputMaybe<EnrollmentApplicationStatus>;
};

export type EnrollmentApplicationUserViewRecord = Node & {
	__typename?: "EnrollmentApplicationUserViewRecord";
	createdAt: Scalars["Time"];
	enrollmentApplication: EnrollmentApplication;
	enrollmentApplicationID: Scalars["ID"];
	id: Scalars["ID"];
	isDetail: Scalars["Boolean"];
	user: User;
	userID: Scalars["ID"];
};

export type EnrollmentApplicationUserViewRecordConnection = {
	__typename?: "EnrollmentApplicationUserViewRecordConnection";
	edges: Array<EnrollmentApplicationUserViewRecordEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type EnrollmentApplicationUserViewRecordEdge = {
	__typename?: "EnrollmentApplicationUserViewRecordEdge";
	cursor: Scalars["Cursor"];
	node: EnrollmentApplicationUserViewRecord;
};

export type EnrollmentApplicationUserViewRecordFilterInput = {
	and?: InputMaybe<Array<EnrollmentApplicationUserViewRecordFilterInput>>;
	createdAtEQ?: InputMaybe<Scalars["Time"]>;
	createdAtGT?: InputMaybe<Scalars["Time"]>;
	createdAtGTE?: InputMaybe<Scalars["Time"]>;
	createdAtIn?: InputMaybe<Array<Scalars["Time"]>>;
	createdAtLT?: InputMaybe<Scalars["Time"]>;
	createdAtLTE?: InputMaybe<Scalars["Time"]>;
	createdAtNEQ?: InputMaybe<Scalars["Time"]>;
	createdAtNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	enrollmentApplication?: InputMaybe<Array<EnrollmentApplicationFilterInput>>;
	enrollmentApplicationIDEQ?: InputMaybe<Scalars["ID"]>;
	enrollmentApplicationIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	enrollmentApplicationIDNEQ?: InputMaybe<Scalars["ID"]>;
	enrollmentApplicationIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	isDetailEQ?: InputMaybe<Scalars["Boolean"]>;
	isDetailNEQ?: InputMaybe<Scalars["Boolean"]>;
	not?: InputMaybe<EnrollmentApplicationUserViewRecordFilterInput>;
	or?: InputMaybe<Array<EnrollmentApplicationUserViewRecordFilterInput>>;
	user?: InputMaybe<Array<UserFilterInput>>;
	userIDEQ?: InputMaybe<Scalars["ID"]>;
	userIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	userIDNEQ?: InputMaybe<Scalars["ID"]>;
	userIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type EnrollmentApplicationUserViewRecordOrder = {
	direction: OrderDirection;
	field?: InputMaybe<EnrollmentApplicationUserViewRecordOrderField>;
};

export enum EnrollmentApplicationUserViewRecordOrderField {
	CreatedAt = "CREATED_AT",
	IsDetail = "IS_DETAIL",
}

export type EnrollmentInvitation = Node & {
	__typename?: "EnrollmentInvitation";
	createdAt: Scalars["Time"];
	id: Scalars["ID"];
	response: EnrollmentInvitationResponse;
	student: Maybe<Student>;
	studentID: Maybe<Scalars["ID"]>;
};

export type EnrollmentInvitationConnection = {
	__typename?: "EnrollmentInvitationConnection";
	edges: Array<EnrollmentInvitationEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type EnrollmentInvitationCreateInput = {
	response?: InputMaybe<EnrollmentInvitationResponse>;
	studentID?: InputMaybe<Scalars["ID"]>;
};

export type EnrollmentInvitationEdge = {
	__typename?: "EnrollmentInvitationEdge";
	cursor: Scalars["Cursor"];
	node: EnrollmentInvitation;
};

export type EnrollmentInvitationFilterInput = {
	and?: InputMaybe<Array<EnrollmentInvitationFilterInput>>;
	createdAtEQ?: InputMaybe<Scalars["Time"]>;
	createdAtGT?: InputMaybe<Scalars["Time"]>;
	createdAtGTE?: InputMaybe<Scalars["Time"]>;
	createdAtIn?: InputMaybe<Array<Scalars["Time"]>>;
	createdAtLT?: InputMaybe<Scalars["Time"]>;
	createdAtLTE?: InputMaybe<Scalars["Time"]>;
	createdAtNEQ?: InputMaybe<Scalars["Time"]>;
	createdAtNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	hasStudent?: InputMaybe<Scalars["Boolean"]>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<EnrollmentInvitationFilterInput>;
	or?: InputMaybe<Array<EnrollmentInvitationFilterInput>>;
	responseEQ?: InputMaybe<EnrollmentInvitationResponse>;
	responseIn?: InputMaybe<Array<EnrollmentInvitationResponse>>;
	responseNEQ?: InputMaybe<EnrollmentInvitationResponse>;
	responseNotIn?: InputMaybe<Array<EnrollmentInvitationResponse>>;
	student?: InputMaybe<Array<StudentFilterInput>>;
	studentIDEQ?: InputMaybe<Scalars["ID"]>;
	studentIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	studentIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	studentIDNEQ?: InputMaybe<Scalars["ID"]>;
	studentIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type EnrollmentInvitationHistory = Node & {
	__typename?: "EnrollmentInvitationHistory";
	createdAt: Maybe<Scalars["Time"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	originalID: Maybe<Scalars["ID"]>;
	response: Maybe<EnrollmentInvitationHistoryResponse>;
	studentID: Maybe<Scalars["ID"]>;
};

export type EnrollmentInvitationHistoryConnection = {
	__typename?: "EnrollmentInvitationHistoryConnection";
	edges: Array<EnrollmentInvitationHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type EnrollmentInvitationHistoryEdge = {
	__typename?: "EnrollmentInvitationHistoryEdge";
	cursor: Scalars["Cursor"];
	node: EnrollmentInvitationHistory;
};

export type EnrollmentInvitationHistoryFilterInput = {
	and?: InputMaybe<Array<EnrollmentInvitationHistoryFilterInput>>;
	createdAtEQ?: InputMaybe<Scalars["Time"]>;
	createdAtGT?: InputMaybe<Scalars["Time"]>;
	createdAtGTE?: InputMaybe<Scalars["Time"]>;
	createdAtIn?: InputMaybe<Array<Scalars["Time"]>>;
	createdAtIsNull?: InputMaybe<Scalars["Boolean"]>;
	createdAtLT?: InputMaybe<Scalars["Time"]>;
	createdAtLTE?: InputMaybe<Scalars["Time"]>;
	createdAtNEQ?: InputMaybe<Scalars["Time"]>;
	createdAtNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<EnrollmentInvitationHistoryFilterInput>;
	or?: InputMaybe<Array<EnrollmentInvitationHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	responseEQ?: InputMaybe<EnrollmentInvitationHistoryResponse>;
	responseIn?: InputMaybe<Array<EnrollmentInvitationHistoryResponse>>;
	responseIsNull?: InputMaybe<Scalars["Boolean"]>;
	responseNEQ?: InputMaybe<EnrollmentInvitationHistoryResponse>;
	responseNotIn?: InputMaybe<Array<EnrollmentInvitationHistoryResponse>>;
	studentIDEQ?: InputMaybe<Scalars["ID"]>;
	studentIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	studentIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	studentIDNEQ?: InputMaybe<Scalars["ID"]>;
	studentIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type EnrollmentInvitationHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<EnrollmentInvitationHistoryOrderField>;
};

export enum EnrollmentInvitationHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export enum EnrollmentInvitationHistoryResponse {
	No = "no",
	Unknown = "unknown",
	Yes = "yes",
}

export type EnrollmentInvitationInStudentCreateInput = {
	response?: InputMaybe<EnrollmentInvitationResponse>;
};

export type EnrollmentInvitationOrder = {
	direction: OrderDirection;
	field?: InputMaybe<EnrollmentInvitationOrderField>;
};

export enum EnrollmentInvitationOrderField {
	CreatedAt = "CREATED_AT",
	Response = "RESPONSE",
}

export enum EnrollmentInvitationResponse {
	No = "no",
	Unknown = "unknown",
	Yes = "yes",
}

export type EnrollmentInvitationUpdateBulkInput = {
	clearStudent?: InputMaybe<Scalars["Boolean"]>;
	response?: InputMaybe<EnrollmentInvitationResponse>;
	studentID?: InputMaybe<Scalars["ID"]>;
};

export type EnrollmentInvitationUpdateInput = {
	clearStudent?: InputMaybe<Scalars["Boolean"]>;
	response?: InputMaybe<EnrollmentInvitationResponse>;
	studentID?: InputMaybe<Scalars["ID"]>;
};

export type Expense = Node & {
	__typename?: "Expense";
	amount: Scalars["Int"];
	createdDate: Scalars["Time"];
	expenseCategory: ExpenseCategory;
	expenseCategoryID: Scalars["ID"];
	id: Scalars["ID"];
	notes: Scalars["String"];
};

export type ExpenseBudget = Node & {
	__typename?: "ExpenseBudget";
	amount: Scalars["Int"];
	budgetMonth: Scalars["Time"];
	expenseCategory: ExpenseCategory;
	expenseCategoryID: Scalars["ID"];
	id: Scalars["ID"];
};

export type ExpenseBudgetConnection = {
	__typename?: "ExpenseBudgetConnection";
	edges: Array<ExpenseBudgetEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type ExpenseBudgetCreateInput = {
	amount: Scalars["Int"];
	budgetMonth: Scalars["Time"];
	expenseCategoryID: Scalars["ID"];
};

export type ExpenseBudgetEdge = {
	__typename?: "ExpenseBudgetEdge";
	cursor: Scalars["Cursor"];
	node: ExpenseBudget;
};

export type ExpenseBudgetFilterInput = {
	amountEQ?: InputMaybe<Scalars["Int"]>;
	amountGT?: InputMaybe<Scalars["Int"]>;
	amountGTE?: InputMaybe<Scalars["Int"]>;
	amountIn?: InputMaybe<Array<Scalars["Int"]>>;
	amountLT?: InputMaybe<Scalars["Int"]>;
	amountLTE?: InputMaybe<Scalars["Int"]>;
	amountNEQ?: InputMaybe<Scalars["Int"]>;
	amountNotIn?: InputMaybe<Array<Scalars["Int"]>>;
	and?: InputMaybe<Array<ExpenseBudgetFilterInput>>;
	budgetMonthEQ?: InputMaybe<Scalars["Time"]>;
	budgetMonthGT?: InputMaybe<Scalars["Time"]>;
	budgetMonthGTE?: InputMaybe<Scalars["Time"]>;
	budgetMonthIn?: InputMaybe<Array<Scalars["Time"]>>;
	budgetMonthLT?: InputMaybe<Scalars["Time"]>;
	budgetMonthLTE?: InputMaybe<Scalars["Time"]>;
	budgetMonthNEQ?: InputMaybe<Scalars["Time"]>;
	budgetMonthNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	expenseCategory?: InputMaybe<Array<ExpenseCategoryFilterInput>>;
	expenseCategoryIDEQ?: InputMaybe<Scalars["ID"]>;
	expenseCategoryIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	expenseCategoryIDNEQ?: InputMaybe<Scalars["ID"]>;
	expenseCategoryIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<ExpenseBudgetFilterInput>;
	or?: InputMaybe<Array<ExpenseBudgetFilterInput>>;
};

export type ExpenseBudgetHistory = Node & {
	__typename?: "ExpenseBudgetHistory";
	amount: Maybe<Scalars["Int"]>;
	budgetMonth: Maybe<Scalars["Time"]>;
	expenseCategoryID: Maybe<Scalars["ID"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	originalID: Maybe<Scalars["ID"]>;
};

export type ExpenseBudgetHistoryConnection = {
	__typename?: "ExpenseBudgetHistoryConnection";
	edges: Array<ExpenseBudgetHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type ExpenseBudgetHistoryEdge = {
	__typename?: "ExpenseBudgetHistoryEdge";
	cursor: Scalars["Cursor"];
	node: ExpenseBudgetHistory;
};

export type ExpenseBudgetHistoryFilterInput = {
	amountEQ?: InputMaybe<Scalars["Int"]>;
	amountGT?: InputMaybe<Scalars["Int"]>;
	amountGTE?: InputMaybe<Scalars["Int"]>;
	amountIn?: InputMaybe<Array<Scalars["Int"]>>;
	amountIsNull?: InputMaybe<Scalars["Boolean"]>;
	amountLT?: InputMaybe<Scalars["Int"]>;
	amountLTE?: InputMaybe<Scalars["Int"]>;
	amountNEQ?: InputMaybe<Scalars["Int"]>;
	amountNotIn?: InputMaybe<Array<Scalars["Int"]>>;
	and?: InputMaybe<Array<ExpenseBudgetHistoryFilterInput>>;
	budgetMonthEQ?: InputMaybe<Scalars["Time"]>;
	budgetMonthGT?: InputMaybe<Scalars["Time"]>;
	budgetMonthGTE?: InputMaybe<Scalars["Time"]>;
	budgetMonthIn?: InputMaybe<Array<Scalars["Time"]>>;
	budgetMonthIsNull?: InputMaybe<Scalars["Boolean"]>;
	budgetMonthLT?: InputMaybe<Scalars["Time"]>;
	budgetMonthLTE?: InputMaybe<Scalars["Time"]>;
	budgetMonthNEQ?: InputMaybe<Scalars["Time"]>;
	budgetMonthNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	expenseCategoryIDEQ?: InputMaybe<Scalars["ID"]>;
	expenseCategoryIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	expenseCategoryIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	expenseCategoryIDNEQ?: InputMaybe<Scalars["ID"]>;
	expenseCategoryIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<ExpenseBudgetHistoryFilterInput>;
	or?: InputMaybe<Array<ExpenseBudgetHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type ExpenseBudgetHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<ExpenseBudgetHistoryOrderField>;
};

export enum ExpenseBudgetHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type ExpenseBudgetInExpenseCategoryCreateInput = {
	amount: Scalars["Int"];
	budgetMonth: Scalars["Time"];
};

export type ExpenseBudgetOrder = {
	direction: OrderDirection;
	field?: InputMaybe<ExpenseBudgetOrderField>;
};

export enum ExpenseBudgetOrderField {
	Amount = "AMOUNT",
	BudgetMonth = "BUDGET_MONTH",
}

export type ExpenseBudgetUpdateBulkInput = {
	amount?: InputMaybe<Scalars["Int"]>;
	budgetMonth?: InputMaybe<Scalars["Time"]>;
	expenseCategoryID?: InputMaybe<Scalars["ID"]>;
};

export type ExpenseBudgetUpdateInput = {
	amount?: InputMaybe<Scalars["Int"]>;
	budgetMonth?: InputMaybe<Scalars["Time"]>;
	expenseCategoryID?: InputMaybe<Scalars["ID"]>;
};

export type ExpenseCategory = Node & {
	__typename?: "ExpenseCategory";
	description: Scalars["String"];
	expenseBudgets: Array<ExpenseBudget>;
	expenses: Array<Expense>;
	id: Scalars["ID"];
	name: Scalars["String"];
};

export type ExpenseCategoryExpenseBudgetsArgs = {
	filters?: InputMaybe<Array<ExpenseBudgetFilterInput>>;
	order?: InputMaybe<ExpenseBudgetOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type ExpenseCategoryExpensesArgs = {
	filters?: InputMaybe<Array<ExpenseFilterInput>>;
	order?: InputMaybe<ExpenseOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type ExpenseCategoryConnection = {
	__typename?: "ExpenseCategoryConnection";
	edges: Array<ExpenseCategoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type ExpenseCategoryCreateInput = {
	description?: InputMaybe<Scalars["String"]>;
	expenseBudgetIDs?: InputMaybe<Array<Scalars["ID"]>>;
	expenseBudgets?: InputMaybe<Array<ExpenseBudgetInExpenseCategoryCreateInput>>;
	expenseIDs?: InputMaybe<Array<Scalars["ID"]>>;
	expenses?: InputMaybe<Array<ExpenseInExpenseCategoryCreateInput>>;
	name: Scalars["String"];
};

export type ExpenseCategoryEdge = {
	__typename?: "ExpenseCategoryEdge";
	cursor: Scalars["Cursor"];
	node: ExpenseCategory;
};

export type ExpenseCategoryFilterInput = {
	and?: InputMaybe<Array<ExpenseCategoryFilterInput>>;
	descriptionContains?: InputMaybe<Scalars["String"]>;
	descriptionEQ?: InputMaybe<Scalars["String"]>;
	descriptionHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionIn?: InputMaybe<Array<Scalars["String"]>>;
	descriptionNEQ?: InputMaybe<Scalars["String"]>;
	descriptionNotContains?: InputMaybe<Scalars["String"]>;
	descriptionNotHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionNotHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionNotIn?: InputMaybe<Array<Scalars["String"]>>;
	expenseBudgets?: InputMaybe<Array<ExpenseBudgetFilterInput>>;
	expenses?: InputMaybe<Array<ExpenseFilterInput>>;
	hasExpenseBudgets?: InputMaybe<Scalars["Boolean"]>;
	hasExpenses?: InputMaybe<Scalars["Boolean"]>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	nameContains?: InputMaybe<Scalars["String"]>;
	nameEQ?: InputMaybe<Scalars["String"]>;
	nameHasPrefix?: InputMaybe<Scalars["String"]>;
	nameHasSuffix?: InputMaybe<Scalars["String"]>;
	nameIn?: InputMaybe<Array<Scalars["String"]>>;
	nameNEQ?: InputMaybe<Scalars["String"]>;
	nameNotContains?: InputMaybe<Scalars["String"]>;
	nameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	nameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	nameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	not?: InputMaybe<ExpenseCategoryFilterInput>;
	or?: InputMaybe<Array<ExpenseCategoryFilterInput>>;
	search?: InputMaybe<Scalars["String"]>;
};

export type ExpenseCategoryHistory = Node & {
	__typename?: "ExpenseCategoryHistory";
	description: Maybe<Scalars["String"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	name: Maybe<Scalars["String"]>;
	originalID: Maybe<Scalars["ID"]>;
};

export type ExpenseCategoryHistoryConnection = {
	__typename?: "ExpenseCategoryHistoryConnection";
	edges: Array<ExpenseCategoryHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type ExpenseCategoryHistoryEdge = {
	__typename?: "ExpenseCategoryHistoryEdge";
	cursor: Scalars["Cursor"];
	node: ExpenseCategoryHistory;
};

export type ExpenseCategoryHistoryFilterInput = {
	and?: InputMaybe<Array<ExpenseCategoryHistoryFilterInput>>;
	descriptionContains?: InputMaybe<Scalars["String"]>;
	descriptionEQ?: InputMaybe<Scalars["String"]>;
	descriptionHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionIn?: InputMaybe<Array<Scalars["String"]>>;
	descriptionIsNull?: InputMaybe<Scalars["Boolean"]>;
	descriptionNEQ?: InputMaybe<Scalars["String"]>;
	descriptionNotContains?: InputMaybe<Scalars["String"]>;
	descriptionNotHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionNotHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionNotIn?: InputMaybe<Array<Scalars["String"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	nameContains?: InputMaybe<Scalars["String"]>;
	nameEQ?: InputMaybe<Scalars["String"]>;
	nameHasPrefix?: InputMaybe<Scalars["String"]>;
	nameHasSuffix?: InputMaybe<Scalars["String"]>;
	nameIn?: InputMaybe<Array<Scalars["String"]>>;
	nameIsNull?: InputMaybe<Scalars["Boolean"]>;
	nameNEQ?: InputMaybe<Scalars["String"]>;
	nameNotContains?: InputMaybe<Scalars["String"]>;
	nameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	nameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	nameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	not?: InputMaybe<ExpenseCategoryHistoryFilterInput>;
	or?: InputMaybe<Array<ExpenseCategoryHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	search?: InputMaybe<Scalars["String"]>;
};

export type ExpenseCategoryHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<ExpenseCategoryHistoryOrderField>;
};

export enum ExpenseCategoryHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type ExpenseCategoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<ExpenseCategoryOrderField>;
};

export enum ExpenseCategoryOrderField {
	Name = "NAME",
}

export type ExpenseCategoryUpdateBulkInput = {
	addExpenseBudgetIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addExpenseIDs?: InputMaybe<Array<Scalars["ID"]>>;
	description?: InputMaybe<Scalars["String"]>;
	removeExpenseBudgetIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeExpenseIDs?: InputMaybe<Array<Scalars["ID"]>>;
};

export type ExpenseCategoryUpdateInput = {
	addExpenseBudgetIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addExpenseIDs?: InputMaybe<Array<Scalars["ID"]>>;
	description?: InputMaybe<Scalars["String"]>;
	name?: InputMaybe<Scalars["String"]>;
	removeExpenseBudgetIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeExpenseIDs?: InputMaybe<Array<Scalars["ID"]>>;
};

export type ExpenseConnection = {
	__typename?: "ExpenseConnection";
	edges: Array<ExpenseEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type ExpenseCreateInput = {
	amount: Scalars["Int"];
	createdDate: Scalars["Time"];
	expenseCategoryID: Scalars["ID"];
	notes?: InputMaybe<Scalars["String"]>;
};

export type ExpenseEdge = {
	__typename?: "ExpenseEdge";
	cursor: Scalars["Cursor"];
	node: Expense;
};

export type ExpenseFilterInput = {
	amountEQ?: InputMaybe<Scalars["Int"]>;
	amountGT?: InputMaybe<Scalars["Int"]>;
	amountGTE?: InputMaybe<Scalars["Int"]>;
	amountIn?: InputMaybe<Array<Scalars["Int"]>>;
	amountLT?: InputMaybe<Scalars["Int"]>;
	amountLTE?: InputMaybe<Scalars["Int"]>;
	amountNEQ?: InputMaybe<Scalars["Int"]>;
	amountNotIn?: InputMaybe<Array<Scalars["Int"]>>;
	and?: InputMaybe<Array<ExpenseFilterInput>>;
	createdDateEQ?: InputMaybe<Scalars["Time"]>;
	createdDateGT?: InputMaybe<Scalars["Time"]>;
	createdDateGTE?: InputMaybe<Scalars["Time"]>;
	createdDateIn?: InputMaybe<Array<Scalars["Time"]>>;
	createdDateLT?: InputMaybe<Scalars["Time"]>;
	createdDateLTE?: InputMaybe<Scalars["Time"]>;
	createdDateNEQ?: InputMaybe<Scalars["Time"]>;
	createdDateNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	expenseCategory?: InputMaybe<Array<ExpenseCategoryFilterInput>>;
	expenseCategoryIDEQ?: InputMaybe<Scalars["ID"]>;
	expenseCategoryIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	expenseCategoryIDNEQ?: InputMaybe<Scalars["ID"]>;
	expenseCategoryIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<ExpenseFilterInput>;
	notesContains?: InputMaybe<Scalars["String"]>;
	notesEQ?: InputMaybe<Scalars["String"]>;
	notesHasPrefix?: InputMaybe<Scalars["String"]>;
	notesHasSuffix?: InputMaybe<Scalars["String"]>;
	notesIn?: InputMaybe<Array<Scalars["String"]>>;
	notesNEQ?: InputMaybe<Scalars["String"]>;
	notesNotContains?: InputMaybe<Scalars["String"]>;
	notesNotHasPrefix?: InputMaybe<Scalars["String"]>;
	notesNotHasSuffix?: InputMaybe<Scalars["String"]>;
	notesNotIn?: InputMaybe<Array<Scalars["String"]>>;
	or?: InputMaybe<Array<ExpenseFilterInput>>;
	search?: InputMaybe<Scalars["String"]>;
};

export type ExpenseHistory = Node & {
	__typename?: "ExpenseHistory";
	amount: Maybe<Scalars["Int"]>;
	createdDate: Maybe<Scalars["Time"]>;
	expenseCategoryID: Maybe<Scalars["ID"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	notes: Maybe<Scalars["String"]>;
	originalID: Maybe<Scalars["ID"]>;
};

export type ExpenseHistoryConnection = {
	__typename?: "ExpenseHistoryConnection";
	edges: Array<ExpenseHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type ExpenseHistoryEdge = {
	__typename?: "ExpenseHistoryEdge";
	cursor: Scalars["Cursor"];
	node: ExpenseHistory;
};

export type ExpenseHistoryFilterInput = {
	amountEQ?: InputMaybe<Scalars["Int"]>;
	amountGT?: InputMaybe<Scalars["Int"]>;
	amountGTE?: InputMaybe<Scalars["Int"]>;
	amountIn?: InputMaybe<Array<Scalars["Int"]>>;
	amountIsNull?: InputMaybe<Scalars["Boolean"]>;
	amountLT?: InputMaybe<Scalars["Int"]>;
	amountLTE?: InputMaybe<Scalars["Int"]>;
	amountNEQ?: InputMaybe<Scalars["Int"]>;
	amountNotIn?: InputMaybe<Array<Scalars["Int"]>>;
	and?: InputMaybe<Array<ExpenseHistoryFilterInput>>;
	createdDateEQ?: InputMaybe<Scalars["Time"]>;
	createdDateGT?: InputMaybe<Scalars["Time"]>;
	createdDateGTE?: InputMaybe<Scalars["Time"]>;
	createdDateIn?: InputMaybe<Array<Scalars["Time"]>>;
	createdDateIsNull?: InputMaybe<Scalars["Boolean"]>;
	createdDateLT?: InputMaybe<Scalars["Time"]>;
	createdDateLTE?: InputMaybe<Scalars["Time"]>;
	createdDateNEQ?: InputMaybe<Scalars["Time"]>;
	createdDateNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	expenseCategoryIDEQ?: InputMaybe<Scalars["ID"]>;
	expenseCategoryIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	expenseCategoryIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	expenseCategoryIDNEQ?: InputMaybe<Scalars["ID"]>;
	expenseCategoryIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<ExpenseHistoryFilterInput>;
	notesContains?: InputMaybe<Scalars["String"]>;
	notesEQ?: InputMaybe<Scalars["String"]>;
	notesHasPrefix?: InputMaybe<Scalars["String"]>;
	notesHasSuffix?: InputMaybe<Scalars["String"]>;
	notesIn?: InputMaybe<Array<Scalars["String"]>>;
	notesIsNull?: InputMaybe<Scalars["Boolean"]>;
	notesNEQ?: InputMaybe<Scalars["String"]>;
	notesNotContains?: InputMaybe<Scalars["String"]>;
	notesNotHasPrefix?: InputMaybe<Scalars["String"]>;
	notesNotHasSuffix?: InputMaybe<Scalars["String"]>;
	notesNotIn?: InputMaybe<Array<Scalars["String"]>>;
	or?: InputMaybe<Array<ExpenseHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	search?: InputMaybe<Scalars["String"]>;
};

export type ExpenseHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<ExpenseHistoryOrderField>;
};

export enum ExpenseHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type ExpenseInExpenseCategoryCreateInput = {
	amount: Scalars["Int"];
	createdDate: Scalars["Time"];
	notes?: InputMaybe<Scalars["String"]>;
};

export type ExpenseOrder = {
	direction: OrderDirection;
	field?: InputMaybe<ExpenseOrderField>;
};

export enum ExpenseOrderField {
	Amount = "AMOUNT",
	CreatedDate = "CREATED_DATE",
}

export type ExpenseUpdateBulkInput = {
	amount?: InputMaybe<Scalars["Int"]>;
	createdDate?: InputMaybe<Scalars["Time"]>;
	expenseCategoryID?: InputMaybe<Scalars["ID"]>;
	notes?: InputMaybe<Scalars["String"]>;
};

export type ExpenseUpdateInput = {
	amount?: InputMaybe<Scalars["Int"]>;
	createdDate?: InputMaybe<Scalars["Time"]>;
	expenseCategoryID?: InputMaybe<Scalars["ID"]>;
	notes?: InputMaybe<Scalars["String"]>;
};

export enum HistoryType {
	Create = "create",
	Delete = "delete",
	Update = "update",
}

export type HomeRoom = Node & {
	__typename?: "HomeRoom";
	classYear: ClassYear;
	classYearID: Scalars["ID"];
	courses: Array<Course>;
	description: Scalars["String"];
	documentResourceFolders: Array<DocumentResourceFolder>;
	homeRoomSections: Array<HomeRoomSection>;
	id: Scalars["ID"];
	name: Scalars["String"];
	studentCapacity: Maybe<Scalars["Int"]>;
};

export type HomeRoomCoursesArgs = {
	filters?: InputMaybe<Array<CourseFilterInput>>;
	order?: InputMaybe<CourseOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type HomeRoomDocumentResourceFoldersArgs = {
	filters?: InputMaybe<Array<DocumentResourceFolderFilterInput>>;
	order?: InputMaybe<DocumentResourceFolderOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type HomeRoomHomeRoomSectionsArgs = {
	filters?: InputMaybe<Array<HomeRoomSectionFilterInput>>;
	order?: InputMaybe<HomeRoomSectionOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type HomeRoomConnection = {
	__typename?: "HomeRoomConnection";
	edges: Array<HomeRoomEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type HomeRoomCreateInput = {
	classYearID: Scalars["ID"];
	courseIDs?: InputMaybe<Array<Scalars["ID"]>>;
	courses?: InputMaybe<Array<CourseInHomeRoomCreateInput>>;
	description?: InputMaybe<Scalars["String"]>;
	documentResourceFolderIDs?: InputMaybe<Array<Scalars["ID"]>>;
	homeRoomSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	homeRoomSections?: InputMaybe<Array<HomeRoomSectionInHomeRoomCreateInput>>;
	name: Scalars["String"];
	studentCapacity?: InputMaybe<Scalars["Int"]>;
};

export type HomeRoomEdge = {
	__typename?: "HomeRoomEdge";
	cursor: Scalars["Cursor"];
	node: HomeRoom;
};

export type HomeRoomFilterInput = {
	and?: InputMaybe<Array<HomeRoomFilterInput>>;
	classYear?: InputMaybe<Array<ClassYearFilterInput>>;
	classYearIDEQ?: InputMaybe<Scalars["ID"]>;
	classYearIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	classYearIDNEQ?: InputMaybe<Scalars["ID"]>;
	classYearIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	courses?: InputMaybe<Array<CourseFilterInput>>;
	descriptionContains?: InputMaybe<Scalars["String"]>;
	descriptionEQ?: InputMaybe<Scalars["String"]>;
	descriptionHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionIn?: InputMaybe<Array<Scalars["String"]>>;
	descriptionNEQ?: InputMaybe<Scalars["String"]>;
	descriptionNotContains?: InputMaybe<Scalars["String"]>;
	descriptionNotHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionNotHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionNotIn?: InputMaybe<Array<Scalars["String"]>>;
	documentResourceFolders?: InputMaybe<Array<DocumentResourceFolderFilterInput>>;
	hasCourses?: InputMaybe<Scalars["Boolean"]>;
	hasDocumentResourceFolders?: InputMaybe<Scalars["Boolean"]>;
	hasHomeRoomSections?: InputMaybe<Scalars["Boolean"]>;
	homeRoomSections?: InputMaybe<Array<HomeRoomSectionFilterInput>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	nameContains?: InputMaybe<Scalars["String"]>;
	nameEQ?: InputMaybe<Scalars["String"]>;
	nameHasPrefix?: InputMaybe<Scalars["String"]>;
	nameHasSuffix?: InputMaybe<Scalars["String"]>;
	nameIn?: InputMaybe<Array<Scalars["String"]>>;
	nameNEQ?: InputMaybe<Scalars["String"]>;
	nameNotContains?: InputMaybe<Scalars["String"]>;
	nameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	nameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	nameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	not?: InputMaybe<HomeRoomFilterInput>;
	or?: InputMaybe<Array<HomeRoomFilterInput>>;
	search?: InputMaybe<Scalars["String"]>;
	studentCapacityEQ?: InputMaybe<Scalars["Int"]>;
	studentCapacityGT?: InputMaybe<Scalars["Int"]>;
	studentCapacityGTE?: InputMaybe<Scalars["Int"]>;
	studentCapacityIn?: InputMaybe<Array<Scalars["Int"]>>;
	studentCapacityIsNull?: InputMaybe<Scalars["Boolean"]>;
	studentCapacityLT?: InputMaybe<Scalars["Int"]>;
	studentCapacityLTE?: InputMaybe<Scalars["Int"]>;
	studentCapacityNEQ?: InputMaybe<Scalars["Int"]>;
	studentCapacityNotIn?: InputMaybe<Array<Scalars["Int"]>>;
};

export type HomeRoomHistory = Node & {
	__typename?: "HomeRoomHistory";
	classYearID: Maybe<Scalars["ID"]>;
	description: Maybe<Scalars["String"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	name: Maybe<Scalars["String"]>;
	originalID: Maybe<Scalars["ID"]>;
	studentCapacity: Maybe<Scalars["Int"]>;
};

export type HomeRoomHistoryConnection = {
	__typename?: "HomeRoomHistoryConnection";
	edges: Array<HomeRoomHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type HomeRoomHistoryEdge = {
	__typename?: "HomeRoomHistoryEdge";
	cursor: Scalars["Cursor"];
	node: HomeRoomHistory;
};

export type HomeRoomHistoryFilterInput = {
	and?: InputMaybe<Array<HomeRoomHistoryFilterInput>>;
	classYearIDEQ?: InputMaybe<Scalars["ID"]>;
	classYearIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	classYearIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	classYearIDNEQ?: InputMaybe<Scalars["ID"]>;
	classYearIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	descriptionContains?: InputMaybe<Scalars["String"]>;
	descriptionEQ?: InputMaybe<Scalars["String"]>;
	descriptionHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionIn?: InputMaybe<Array<Scalars["String"]>>;
	descriptionIsNull?: InputMaybe<Scalars["Boolean"]>;
	descriptionNEQ?: InputMaybe<Scalars["String"]>;
	descriptionNotContains?: InputMaybe<Scalars["String"]>;
	descriptionNotHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionNotHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionNotIn?: InputMaybe<Array<Scalars["String"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	nameContains?: InputMaybe<Scalars["String"]>;
	nameEQ?: InputMaybe<Scalars["String"]>;
	nameHasPrefix?: InputMaybe<Scalars["String"]>;
	nameHasSuffix?: InputMaybe<Scalars["String"]>;
	nameIn?: InputMaybe<Array<Scalars["String"]>>;
	nameIsNull?: InputMaybe<Scalars["Boolean"]>;
	nameNEQ?: InputMaybe<Scalars["String"]>;
	nameNotContains?: InputMaybe<Scalars["String"]>;
	nameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	nameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	nameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	not?: InputMaybe<HomeRoomHistoryFilterInput>;
	or?: InputMaybe<Array<HomeRoomHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	search?: InputMaybe<Scalars["String"]>;
	studentCapacityEQ?: InputMaybe<Scalars["Int"]>;
	studentCapacityGT?: InputMaybe<Scalars["Int"]>;
	studentCapacityGTE?: InputMaybe<Scalars["Int"]>;
	studentCapacityIn?: InputMaybe<Array<Scalars["Int"]>>;
	studentCapacityIsNull?: InputMaybe<Scalars["Boolean"]>;
	studentCapacityLT?: InputMaybe<Scalars["Int"]>;
	studentCapacityLTE?: InputMaybe<Scalars["Int"]>;
	studentCapacityNEQ?: InputMaybe<Scalars["Int"]>;
	studentCapacityNotIn?: InputMaybe<Array<Scalars["Int"]>>;
};

export type HomeRoomHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<HomeRoomHistoryOrderField>;
};

export enum HomeRoomHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type HomeRoomInClassYearCreateInput = {
	courseIDs?: InputMaybe<Array<Scalars["ID"]>>;
	courses?: InputMaybe<Array<CourseInHomeRoomCreateInput>>;
	description?: InputMaybe<Scalars["String"]>;
	documentResourceFolderIDs?: InputMaybe<Array<Scalars["ID"]>>;
	homeRoomSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	homeRoomSections?: InputMaybe<Array<HomeRoomSectionInHomeRoomCreateInput>>;
	name: Scalars["String"];
	studentCapacity?: InputMaybe<Scalars["Int"]>;
};

export type HomeRoomOrder = {
	direction: OrderDirection;
	field?: InputMaybe<HomeRoomOrderField>;
};

export enum HomeRoomOrderField {
	Name = "NAME",
	StudentCapacity = "STUDENT_CAPACITY",
}

export type HomeRoomSection = Node & {
	__typename?: "HomeRoomSection";
	courseSections: Array<CourseSection>;
	description: Scalars["String"];
	documentResourceFolders: Array<DocumentResourceFolder>;
	homeRoom: HomeRoom;
	homeRoomID: Scalars["ID"];
	homeRoomSectionStudentEnrollments: Array<HomeRoomSectionStudentEnrollment>;
	homeRoomSectionTeacherEnrollments: Array<HomeRoomSectionTeacherEnrollment>;
	id: Scalars["ID"];
	name: Scalars["String"];
	notes: Array<Note>;
	room: Room;
	roomID: Scalars["ID"];
	term: Term;
	termID: Scalars["ID"];
};

export type HomeRoomSectionCourseSectionsArgs = {
	filters?: InputMaybe<Array<CourseSectionFilterInput>>;
	order?: InputMaybe<CourseSectionOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type HomeRoomSectionDocumentResourceFoldersArgs = {
	filters?: InputMaybe<Array<DocumentResourceFolderFilterInput>>;
	order?: InputMaybe<DocumentResourceFolderOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type HomeRoomSectionHomeRoomSectionStudentEnrollmentsArgs = {
	filters?: InputMaybe<Array<HomeRoomSectionStudentEnrollmentFilterInput>>;
	order?: InputMaybe<HomeRoomSectionStudentEnrollmentOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type HomeRoomSectionHomeRoomSectionTeacherEnrollmentsArgs = {
	filters?: InputMaybe<Array<HomeRoomSectionTeacherEnrollmentFilterInput>>;
	order?: InputMaybe<HomeRoomSectionTeacherEnrollmentOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type HomeRoomSectionNotesArgs = {
	filters?: InputMaybe<Array<NoteFilterInput>>;
	order?: InputMaybe<NoteOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type HomeRoomSectionConnection = {
	__typename?: "HomeRoomSectionConnection";
	edges: Array<HomeRoomSectionEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type HomeRoomSectionCreateInput = {
	courseSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	courseSections?: InputMaybe<Array<CourseSectionInHomeRoomSectionCreateInput>>;
	description?: InputMaybe<Scalars["String"]>;
	documentResourceFolderIDs?: InputMaybe<Array<Scalars["ID"]>>;
	homeRoomID: Scalars["ID"];
	homeRoomSectionStudentEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	homeRoomSectionStudentEnrollments?: InputMaybe<Array<HomeRoomSectionStudentEnrollmentInHomeRoomSectionCreateInput>>;
	homeRoomSectionTeacherEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	homeRoomSectionTeacherEnrollments?: InputMaybe<Array<HomeRoomSectionTeacherEnrollmentInHomeRoomSectionCreateInput>>;
	name: Scalars["String"];
	noteIDs?: InputMaybe<Array<Scalars["ID"]>>;
	roomID: Scalars["ID"];
	termID: Scalars["ID"];
};

export type HomeRoomSectionEdge = {
	__typename?: "HomeRoomSectionEdge";
	cursor: Scalars["Cursor"];
	node: HomeRoomSection;
};

export type HomeRoomSectionFilterInput = {
	and?: InputMaybe<Array<HomeRoomSectionFilterInput>>;
	courseSections?: InputMaybe<Array<CourseSectionFilterInput>>;
	descriptionContains?: InputMaybe<Scalars["String"]>;
	descriptionEQ?: InputMaybe<Scalars["String"]>;
	descriptionHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionIn?: InputMaybe<Array<Scalars["String"]>>;
	descriptionNEQ?: InputMaybe<Scalars["String"]>;
	descriptionNotContains?: InputMaybe<Scalars["String"]>;
	descriptionNotHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionNotHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionNotIn?: InputMaybe<Array<Scalars["String"]>>;
	documentResourceFolders?: InputMaybe<Array<DocumentResourceFolderFilterInput>>;
	hasCourseSections?: InputMaybe<Scalars["Boolean"]>;
	hasDocumentResourceFolders?: InputMaybe<Scalars["Boolean"]>;
	hasHomeRoomSectionStudentEnrollments?: InputMaybe<Scalars["Boolean"]>;
	hasHomeRoomSectionTeacherEnrollments?: InputMaybe<Scalars["Boolean"]>;
	hasNotes?: InputMaybe<Scalars["Boolean"]>;
	homeRoom?: InputMaybe<Array<HomeRoomFilterInput>>;
	homeRoomIDEQ?: InputMaybe<Scalars["ID"]>;
	homeRoomIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	homeRoomIDNEQ?: InputMaybe<Scalars["ID"]>;
	homeRoomIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	homeRoomSectionStudentEnrollments?: InputMaybe<Array<HomeRoomSectionStudentEnrollmentFilterInput>>;
	homeRoomSectionTeacherEnrollments?: InputMaybe<Array<HomeRoomSectionTeacherEnrollmentFilterInput>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	nameContains?: InputMaybe<Scalars["String"]>;
	nameEQ?: InputMaybe<Scalars["String"]>;
	nameHasPrefix?: InputMaybe<Scalars["String"]>;
	nameHasSuffix?: InputMaybe<Scalars["String"]>;
	nameIn?: InputMaybe<Array<Scalars["String"]>>;
	nameNEQ?: InputMaybe<Scalars["String"]>;
	nameNotContains?: InputMaybe<Scalars["String"]>;
	nameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	nameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	nameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	not?: InputMaybe<HomeRoomSectionFilterInput>;
	notes?: InputMaybe<Array<NoteFilterInput>>;
	or?: InputMaybe<Array<HomeRoomSectionFilterInput>>;
	room?: InputMaybe<Array<RoomFilterInput>>;
	roomIDEQ?: InputMaybe<Scalars["ID"]>;
	roomIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	roomIDNEQ?: InputMaybe<Scalars["ID"]>;
	roomIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	search?: InputMaybe<Scalars["String"]>;
	term?: InputMaybe<Array<TermFilterInput>>;
	termIDEQ?: InputMaybe<Scalars["ID"]>;
	termIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	termIDNEQ?: InputMaybe<Scalars["ID"]>;
	termIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type HomeRoomSectionHistory = Node & {
	__typename?: "HomeRoomSectionHistory";
	description: Maybe<Scalars["String"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	homeRoomID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	name: Maybe<Scalars["String"]>;
	originalID: Maybe<Scalars["ID"]>;
	roomID: Maybe<Scalars["ID"]>;
	termID: Maybe<Scalars["ID"]>;
};

export type HomeRoomSectionHistoryConnection = {
	__typename?: "HomeRoomSectionHistoryConnection";
	edges: Array<HomeRoomSectionHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type HomeRoomSectionHistoryEdge = {
	__typename?: "HomeRoomSectionHistoryEdge";
	cursor: Scalars["Cursor"];
	node: HomeRoomSectionHistory;
};

export type HomeRoomSectionHistoryFilterInput = {
	and?: InputMaybe<Array<HomeRoomSectionHistoryFilterInput>>;
	descriptionContains?: InputMaybe<Scalars["String"]>;
	descriptionEQ?: InputMaybe<Scalars["String"]>;
	descriptionHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionIn?: InputMaybe<Array<Scalars["String"]>>;
	descriptionIsNull?: InputMaybe<Scalars["Boolean"]>;
	descriptionNEQ?: InputMaybe<Scalars["String"]>;
	descriptionNotContains?: InputMaybe<Scalars["String"]>;
	descriptionNotHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionNotHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionNotIn?: InputMaybe<Array<Scalars["String"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	homeRoomIDEQ?: InputMaybe<Scalars["ID"]>;
	homeRoomIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	homeRoomIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	homeRoomIDNEQ?: InputMaybe<Scalars["ID"]>;
	homeRoomIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	nameContains?: InputMaybe<Scalars["String"]>;
	nameEQ?: InputMaybe<Scalars["String"]>;
	nameHasPrefix?: InputMaybe<Scalars["String"]>;
	nameHasSuffix?: InputMaybe<Scalars["String"]>;
	nameIn?: InputMaybe<Array<Scalars["String"]>>;
	nameIsNull?: InputMaybe<Scalars["Boolean"]>;
	nameNEQ?: InputMaybe<Scalars["String"]>;
	nameNotContains?: InputMaybe<Scalars["String"]>;
	nameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	nameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	nameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	not?: InputMaybe<HomeRoomSectionHistoryFilterInput>;
	or?: InputMaybe<Array<HomeRoomSectionHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	roomIDEQ?: InputMaybe<Scalars["ID"]>;
	roomIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	roomIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	roomIDNEQ?: InputMaybe<Scalars["ID"]>;
	roomIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	search?: InputMaybe<Scalars["String"]>;
	termIDEQ?: InputMaybe<Scalars["ID"]>;
	termIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	termIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	termIDNEQ?: InputMaybe<Scalars["ID"]>;
	termIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type HomeRoomSectionHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<HomeRoomSectionHistoryOrderField>;
};

export enum HomeRoomSectionHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type HomeRoomSectionInHomeRoomCreateInput = {
	courseSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	courseSections?: InputMaybe<Array<CourseSectionInHomeRoomSectionCreateInput>>;
	description?: InputMaybe<Scalars["String"]>;
	documentResourceFolderIDs?: InputMaybe<Array<Scalars["ID"]>>;
	homeRoomSectionStudentEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	homeRoomSectionStudentEnrollments?: InputMaybe<Array<HomeRoomSectionStudentEnrollmentInHomeRoomSectionCreateInput>>;
	homeRoomSectionTeacherEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	homeRoomSectionTeacherEnrollments?: InputMaybe<Array<HomeRoomSectionTeacherEnrollmentInHomeRoomSectionCreateInput>>;
	name: Scalars["String"];
	noteIDs?: InputMaybe<Array<Scalars["ID"]>>;
	roomID: Scalars["ID"];
	termID: Scalars["ID"];
};

export type HomeRoomSectionInRoomCreateInput = {
	courseSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	courseSections?: InputMaybe<Array<CourseSectionInHomeRoomSectionCreateInput>>;
	description?: InputMaybe<Scalars["String"]>;
	documentResourceFolderIDs?: InputMaybe<Array<Scalars["ID"]>>;
	homeRoomID: Scalars["ID"];
	homeRoomSectionStudentEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	homeRoomSectionStudentEnrollments?: InputMaybe<Array<HomeRoomSectionStudentEnrollmentInHomeRoomSectionCreateInput>>;
	homeRoomSectionTeacherEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	homeRoomSectionTeacherEnrollments?: InputMaybe<Array<HomeRoomSectionTeacherEnrollmentInHomeRoomSectionCreateInput>>;
	name: Scalars["String"];
	noteIDs?: InputMaybe<Array<Scalars["ID"]>>;
	termID: Scalars["ID"];
};

export type HomeRoomSectionInTermCreateInput = {
	courseSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	courseSections?: InputMaybe<Array<CourseSectionInHomeRoomSectionCreateInput>>;
	description?: InputMaybe<Scalars["String"]>;
	documentResourceFolderIDs?: InputMaybe<Array<Scalars["ID"]>>;
	homeRoomID: Scalars["ID"];
	homeRoomSectionStudentEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	homeRoomSectionStudentEnrollments?: InputMaybe<Array<HomeRoomSectionStudentEnrollmentInHomeRoomSectionCreateInput>>;
	homeRoomSectionTeacherEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	homeRoomSectionTeacherEnrollments?: InputMaybe<Array<HomeRoomSectionTeacherEnrollmentInHomeRoomSectionCreateInput>>;
	name: Scalars["String"];
	noteIDs?: InputMaybe<Array<Scalars["ID"]>>;
	roomID: Scalars["ID"];
};

export type HomeRoomSectionOrder = {
	direction: OrderDirection;
	field?: InputMaybe<HomeRoomSectionOrderField>;
};

export enum HomeRoomSectionOrderField {
	Name = "NAME",
}

export type HomeRoomSectionStudentEnrollment = Node & {
	__typename?: "HomeRoomSectionStudentEnrollment";
	earlyEndDate: Maybe<Scalars["Time"]>;
	homeRoomSection: HomeRoomSection;
	homeRoomSectionID: Scalars["ID"];
	id: Scalars["ID"];
	lateStartDate: Maybe<Scalars["Time"]>;
	student: Student;
	studentID: Scalars["ID"];
};

export type HomeRoomSectionStudentEnrollmentConnection = {
	__typename?: "HomeRoomSectionStudentEnrollmentConnection";
	edges: Array<HomeRoomSectionStudentEnrollmentEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type HomeRoomSectionStudentEnrollmentCreateInput = {
	earlyEndDate?: InputMaybe<Scalars["Time"]>;
	homeRoomSectionID: Scalars["ID"];
	lateStartDate?: InputMaybe<Scalars["Time"]>;
	studentID: Scalars["ID"];
};

export type HomeRoomSectionStudentEnrollmentEdge = {
	__typename?: "HomeRoomSectionStudentEnrollmentEdge";
	cursor: Scalars["Cursor"];
	node: HomeRoomSectionStudentEnrollment;
};

export type HomeRoomSectionStudentEnrollmentFilterInput = {
	and?: InputMaybe<Array<HomeRoomSectionStudentEnrollmentFilterInput>>;
	earlyEndDateEQ?: InputMaybe<Scalars["Time"]>;
	earlyEndDateGT?: InputMaybe<Scalars["Time"]>;
	earlyEndDateGTE?: InputMaybe<Scalars["Time"]>;
	earlyEndDateIn?: InputMaybe<Array<Scalars["Time"]>>;
	earlyEndDateIsNull?: InputMaybe<Scalars["Boolean"]>;
	earlyEndDateLT?: InputMaybe<Scalars["Time"]>;
	earlyEndDateLTE?: InputMaybe<Scalars["Time"]>;
	earlyEndDateNEQ?: InputMaybe<Scalars["Time"]>;
	earlyEndDateNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	homeRoomSection?: InputMaybe<Array<HomeRoomSectionFilterInput>>;
	homeRoomSectionIDEQ?: InputMaybe<Scalars["ID"]>;
	homeRoomSectionIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	homeRoomSectionIDNEQ?: InputMaybe<Scalars["ID"]>;
	homeRoomSectionIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	lateStartDateEQ?: InputMaybe<Scalars["Time"]>;
	lateStartDateGT?: InputMaybe<Scalars["Time"]>;
	lateStartDateGTE?: InputMaybe<Scalars["Time"]>;
	lateStartDateIn?: InputMaybe<Array<Scalars["Time"]>>;
	lateStartDateIsNull?: InputMaybe<Scalars["Boolean"]>;
	lateStartDateLT?: InputMaybe<Scalars["Time"]>;
	lateStartDateLTE?: InputMaybe<Scalars["Time"]>;
	lateStartDateNEQ?: InputMaybe<Scalars["Time"]>;
	lateStartDateNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	not?: InputMaybe<HomeRoomSectionStudentEnrollmentFilterInput>;
	or?: InputMaybe<Array<HomeRoomSectionStudentEnrollmentFilterInput>>;
	student?: InputMaybe<Array<StudentFilterInput>>;
	studentIDEQ?: InputMaybe<Scalars["ID"]>;
	studentIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	studentIDNEQ?: InputMaybe<Scalars["ID"]>;
	studentIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type HomeRoomSectionStudentEnrollmentHistory = Node & {
	__typename?: "HomeRoomSectionStudentEnrollmentHistory";
	earlyEndDate: Maybe<Scalars["Time"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	homeRoomSectionID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	lateStartDate: Maybe<Scalars["Time"]>;
	originalID: Maybe<Scalars["ID"]>;
	studentID: Maybe<Scalars["ID"]>;
};

export type HomeRoomSectionStudentEnrollmentHistoryConnection = {
	__typename?: "HomeRoomSectionStudentEnrollmentHistoryConnection";
	edges: Array<HomeRoomSectionStudentEnrollmentHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type HomeRoomSectionStudentEnrollmentHistoryEdge = {
	__typename?: "HomeRoomSectionStudentEnrollmentHistoryEdge";
	cursor: Scalars["Cursor"];
	node: HomeRoomSectionStudentEnrollmentHistory;
};

export type HomeRoomSectionStudentEnrollmentHistoryFilterInput = {
	and?: InputMaybe<Array<HomeRoomSectionStudentEnrollmentHistoryFilterInput>>;
	earlyEndDateEQ?: InputMaybe<Scalars["Time"]>;
	earlyEndDateGT?: InputMaybe<Scalars["Time"]>;
	earlyEndDateGTE?: InputMaybe<Scalars["Time"]>;
	earlyEndDateIn?: InputMaybe<Array<Scalars["Time"]>>;
	earlyEndDateIsNull?: InputMaybe<Scalars["Boolean"]>;
	earlyEndDateLT?: InputMaybe<Scalars["Time"]>;
	earlyEndDateLTE?: InputMaybe<Scalars["Time"]>;
	earlyEndDateNEQ?: InputMaybe<Scalars["Time"]>;
	earlyEndDateNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	homeRoomSectionIDEQ?: InputMaybe<Scalars["ID"]>;
	homeRoomSectionIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	homeRoomSectionIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	homeRoomSectionIDNEQ?: InputMaybe<Scalars["ID"]>;
	homeRoomSectionIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	lateStartDateEQ?: InputMaybe<Scalars["Time"]>;
	lateStartDateGT?: InputMaybe<Scalars["Time"]>;
	lateStartDateGTE?: InputMaybe<Scalars["Time"]>;
	lateStartDateIn?: InputMaybe<Array<Scalars["Time"]>>;
	lateStartDateIsNull?: InputMaybe<Scalars["Boolean"]>;
	lateStartDateLT?: InputMaybe<Scalars["Time"]>;
	lateStartDateLTE?: InputMaybe<Scalars["Time"]>;
	lateStartDateNEQ?: InputMaybe<Scalars["Time"]>;
	lateStartDateNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	not?: InputMaybe<HomeRoomSectionStudentEnrollmentHistoryFilterInput>;
	or?: InputMaybe<Array<HomeRoomSectionStudentEnrollmentHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	studentIDEQ?: InputMaybe<Scalars["ID"]>;
	studentIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	studentIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	studentIDNEQ?: InputMaybe<Scalars["ID"]>;
	studentIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type HomeRoomSectionStudentEnrollmentHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<HomeRoomSectionStudentEnrollmentHistoryOrderField>;
};

export enum HomeRoomSectionStudentEnrollmentHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type HomeRoomSectionStudentEnrollmentInHomeRoomSectionCreateInput = {
	earlyEndDate?: InputMaybe<Scalars["Time"]>;
	lateStartDate?: InputMaybe<Scalars["Time"]>;
	studentID: Scalars["ID"];
};

export type HomeRoomSectionStudentEnrollmentInStudentCreateInput = {
	earlyEndDate?: InputMaybe<Scalars["Time"]>;
	homeRoomSectionID: Scalars["ID"];
	lateStartDate?: InputMaybe<Scalars["Time"]>;
};

export type HomeRoomSectionStudentEnrollmentOrder = {
	direction: OrderDirection;
	field?: InputMaybe<HomeRoomSectionStudentEnrollmentOrderField>;
};

export enum HomeRoomSectionStudentEnrollmentOrderField {
	EarlyEndDate = "EARLY_END_DATE",
	LateStartDate = "LATE_START_DATE",
}

export type HomeRoomSectionStudentEnrollmentUpdateBulkInput = {
	clearEarlyEndDate?: InputMaybe<Scalars["Boolean"]>;
	clearLateStartDate?: InputMaybe<Scalars["Boolean"]>;
	earlyEndDate?: InputMaybe<Scalars["Time"]>;
	homeRoomSectionID?: InputMaybe<Scalars["ID"]>;
	lateStartDate?: InputMaybe<Scalars["Time"]>;
	studentID?: InputMaybe<Scalars["ID"]>;
};

export type HomeRoomSectionStudentEnrollmentUpdateInput = {
	clearEarlyEndDate?: InputMaybe<Scalars["Boolean"]>;
	clearLateStartDate?: InputMaybe<Scalars["Boolean"]>;
	earlyEndDate?: InputMaybe<Scalars["Time"]>;
	homeRoomSectionID?: InputMaybe<Scalars["ID"]>;
	lateStartDate?: InputMaybe<Scalars["Time"]>;
	studentID?: InputMaybe<Scalars["ID"]>;
};

export type HomeRoomSectionTeacherEnrollment = Node & {
	__typename?: "HomeRoomSectionTeacherEnrollment";
	earlyEndDate: Maybe<Scalars["Time"]>;
	homeRoomSection: HomeRoomSection;
	homeRoomSectionID: Scalars["ID"];
	id: Scalars["ID"];
	lateStartDate: Maybe<Scalars["Time"]>;
	teacher: Employee;
	teacherID: Scalars["ID"];
};

export type HomeRoomSectionTeacherEnrollmentConnection = {
	__typename?: "HomeRoomSectionTeacherEnrollmentConnection";
	edges: Array<HomeRoomSectionTeacherEnrollmentEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type HomeRoomSectionTeacherEnrollmentCreateInput = {
	earlyEndDate?: InputMaybe<Scalars["Time"]>;
	homeRoomSectionID: Scalars["ID"];
	lateStartDate?: InputMaybe<Scalars["Time"]>;
	teacherID: Scalars["ID"];
};

export type HomeRoomSectionTeacherEnrollmentEdge = {
	__typename?: "HomeRoomSectionTeacherEnrollmentEdge";
	cursor: Scalars["Cursor"];
	node: HomeRoomSectionTeacherEnrollment;
};

export type HomeRoomSectionTeacherEnrollmentFilterInput = {
	and?: InputMaybe<Array<HomeRoomSectionTeacherEnrollmentFilterInput>>;
	earlyEndDateEQ?: InputMaybe<Scalars["Time"]>;
	earlyEndDateGT?: InputMaybe<Scalars["Time"]>;
	earlyEndDateGTE?: InputMaybe<Scalars["Time"]>;
	earlyEndDateIn?: InputMaybe<Array<Scalars["Time"]>>;
	earlyEndDateIsNull?: InputMaybe<Scalars["Boolean"]>;
	earlyEndDateLT?: InputMaybe<Scalars["Time"]>;
	earlyEndDateLTE?: InputMaybe<Scalars["Time"]>;
	earlyEndDateNEQ?: InputMaybe<Scalars["Time"]>;
	earlyEndDateNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	homeRoomSection?: InputMaybe<Array<HomeRoomSectionFilterInput>>;
	homeRoomSectionIDEQ?: InputMaybe<Scalars["ID"]>;
	homeRoomSectionIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	homeRoomSectionIDNEQ?: InputMaybe<Scalars["ID"]>;
	homeRoomSectionIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	lateStartDateEQ?: InputMaybe<Scalars["Time"]>;
	lateStartDateGT?: InputMaybe<Scalars["Time"]>;
	lateStartDateGTE?: InputMaybe<Scalars["Time"]>;
	lateStartDateIn?: InputMaybe<Array<Scalars["Time"]>>;
	lateStartDateIsNull?: InputMaybe<Scalars["Boolean"]>;
	lateStartDateLT?: InputMaybe<Scalars["Time"]>;
	lateStartDateLTE?: InputMaybe<Scalars["Time"]>;
	lateStartDateNEQ?: InputMaybe<Scalars["Time"]>;
	lateStartDateNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	not?: InputMaybe<HomeRoomSectionTeacherEnrollmentFilterInput>;
	or?: InputMaybe<Array<HomeRoomSectionTeacherEnrollmentFilterInput>>;
	teacher?: InputMaybe<Array<EmployeeFilterInput>>;
	teacherIDEQ?: InputMaybe<Scalars["ID"]>;
	teacherIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	teacherIDNEQ?: InputMaybe<Scalars["ID"]>;
	teacherIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type HomeRoomSectionTeacherEnrollmentHistory = Node & {
	__typename?: "HomeRoomSectionTeacherEnrollmentHistory";
	earlyEndDate: Maybe<Scalars["Time"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	homeRoomSectionID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	lateStartDate: Maybe<Scalars["Time"]>;
	originalID: Maybe<Scalars["ID"]>;
	teacherID: Maybe<Scalars["ID"]>;
};

export type HomeRoomSectionTeacherEnrollmentHistoryConnection = {
	__typename?: "HomeRoomSectionTeacherEnrollmentHistoryConnection";
	edges: Array<HomeRoomSectionTeacherEnrollmentHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type HomeRoomSectionTeacherEnrollmentHistoryEdge = {
	__typename?: "HomeRoomSectionTeacherEnrollmentHistoryEdge";
	cursor: Scalars["Cursor"];
	node: HomeRoomSectionTeacherEnrollmentHistory;
};

export type HomeRoomSectionTeacherEnrollmentHistoryFilterInput = {
	and?: InputMaybe<Array<HomeRoomSectionTeacherEnrollmentHistoryFilterInput>>;
	earlyEndDateEQ?: InputMaybe<Scalars["Time"]>;
	earlyEndDateGT?: InputMaybe<Scalars["Time"]>;
	earlyEndDateGTE?: InputMaybe<Scalars["Time"]>;
	earlyEndDateIn?: InputMaybe<Array<Scalars["Time"]>>;
	earlyEndDateIsNull?: InputMaybe<Scalars["Boolean"]>;
	earlyEndDateLT?: InputMaybe<Scalars["Time"]>;
	earlyEndDateLTE?: InputMaybe<Scalars["Time"]>;
	earlyEndDateNEQ?: InputMaybe<Scalars["Time"]>;
	earlyEndDateNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	homeRoomSectionIDEQ?: InputMaybe<Scalars["ID"]>;
	homeRoomSectionIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	homeRoomSectionIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	homeRoomSectionIDNEQ?: InputMaybe<Scalars["ID"]>;
	homeRoomSectionIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	lateStartDateEQ?: InputMaybe<Scalars["Time"]>;
	lateStartDateGT?: InputMaybe<Scalars["Time"]>;
	lateStartDateGTE?: InputMaybe<Scalars["Time"]>;
	lateStartDateIn?: InputMaybe<Array<Scalars["Time"]>>;
	lateStartDateIsNull?: InputMaybe<Scalars["Boolean"]>;
	lateStartDateLT?: InputMaybe<Scalars["Time"]>;
	lateStartDateLTE?: InputMaybe<Scalars["Time"]>;
	lateStartDateNEQ?: InputMaybe<Scalars["Time"]>;
	lateStartDateNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	not?: InputMaybe<HomeRoomSectionTeacherEnrollmentHistoryFilterInput>;
	or?: InputMaybe<Array<HomeRoomSectionTeacherEnrollmentHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	teacherIDEQ?: InputMaybe<Scalars["ID"]>;
	teacherIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	teacherIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	teacherIDNEQ?: InputMaybe<Scalars["ID"]>;
	teacherIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type HomeRoomSectionTeacherEnrollmentHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<HomeRoomSectionTeacherEnrollmentHistoryOrderField>;
};

export enum HomeRoomSectionTeacherEnrollmentHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type HomeRoomSectionTeacherEnrollmentInHomeRoomSectionCreateInput = {
	earlyEndDate?: InputMaybe<Scalars["Time"]>;
	lateStartDate?: InputMaybe<Scalars["Time"]>;
	teacherID: Scalars["ID"];
};

export type HomeRoomSectionTeacherEnrollmentInTeacherCreateInput = {
	earlyEndDate?: InputMaybe<Scalars["Time"]>;
	homeRoomSectionID: Scalars["ID"];
	lateStartDate?: InputMaybe<Scalars["Time"]>;
};

export type HomeRoomSectionTeacherEnrollmentOrder = {
	direction: OrderDirection;
	field?: InputMaybe<HomeRoomSectionTeacherEnrollmentOrderField>;
};

export enum HomeRoomSectionTeacherEnrollmentOrderField {
	EarlyEndDate = "EARLY_END_DATE",
	LateStartDate = "LATE_START_DATE",
}

export type HomeRoomSectionTeacherEnrollmentUpdateBulkInput = {
	clearEarlyEndDate?: InputMaybe<Scalars["Boolean"]>;
	clearLateStartDate?: InputMaybe<Scalars["Boolean"]>;
	earlyEndDate?: InputMaybe<Scalars["Time"]>;
	homeRoomSectionID?: InputMaybe<Scalars["ID"]>;
	lateStartDate?: InputMaybe<Scalars["Time"]>;
	teacherID?: InputMaybe<Scalars["ID"]>;
};

export type HomeRoomSectionTeacherEnrollmentUpdateInput = {
	clearEarlyEndDate?: InputMaybe<Scalars["Boolean"]>;
	clearLateStartDate?: InputMaybe<Scalars["Boolean"]>;
	earlyEndDate?: InputMaybe<Scalars["Time"]>;
	homeRoomSectionID?: InputMaybe<Scalars["ID"]>;
	lateStartDate?: InputMaybe<Scalars["Time"]>;
	teacherID?: InputMaybe<Scalars["ID"]>;
};

export type HomeRoomSectionUpdateBulkInput = {
	addCourseSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addDocumentResourceFolderIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addHomeRoomSectionStudentEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addHomeRoomSectionTeacherEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addNoteIDs?: InputMaybe<Array<Scalars["ID"]>>;
	description?: InputMaybe<Scalars["String"]>;
	homeRoomID?: InputMaybe<Scalars["ID"]>;
	name?: InputMaybe<Scalars["String"]>;
	removeCourseSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeDocumentResourceFolderIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeHomeRoomSectionStudentEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeHomeRoomSectionTeacherEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeNoteIDs?: InputMaybe<Array<Scalars["ID"]>>;
	roomID?: InputMaybe<Scalars["ID"]>;
	termID?: InputMaybe<Scalars["ID"]>;
};

export type HomeRoomSectionUpdateInput = {
	addCourseSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addDocumentResourceFolderIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addHomeRoomSectionStudentEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addHomeRoomSectionTeacherEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addNoteIDs?: InputMaybe<Array<Scalars["ID"]>>;
	description?: InputMaybe<Scalars["String"]>;
	homeRoomID?: InputMaybe<Scalars["ID"]>;
	name?: InputMaybe<Scalars["String"]>;
	removeCourseSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeDocumentResourceFolderIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeHomeRoomSectionStudentEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeHomeRoomSectionTeacherEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeNoteIDs?: InputMaybe<Array<Scalars["ID"]>>;
	roomID?: InputMaybe<Scalars["ID"]>;
	termID?: InputMaybe<Scalars["ID"]>;
};

export type HomeRoomUpdateBulkInput = {
	addCourseIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addDocumentResourceFolderIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addHomeRoomSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	classYearID?: InputMaybe<Scalars["ID"]>;
	clearStudentCapacity?: InputMaybe<Scalars["Boolean"]>;
	description?: InputMaybe<Scalars["String"]>;
	name?: InputMaybe<Scalars["String"]>;
	removeCourseIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeDocumentResourceFolderIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeHomeRoomSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	studentCapacity?: InputMaybe<Scalars["Int"]>;
};

export type HomeRoomUpdateInput = {
	addCourseIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addDocumentResourceFolderIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addHomeRoomSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	classYearID?: InputMaybe<Scalars["ID"]>;
	clearStudentCapacity?: InputMaybe<Scalars["Boolean"]>;
	description?: InputMaybe<Scalars["String"]>;
	name?: InputMaybe<Scalars["String"]>;
	removeCourseIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeDocumentResourceFolderIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeHomeRoomSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	studentCapacity?: InputMaybe<Scalars["Int"]>;
};

export type IntegrationToken = Node & {
	__typename?: "IntegrationToken";
	id: Scalars["ID"];
	source: IntegrationTokenSource;
	user: User;
	userID: Scalars["ID"];
};

export type IntegrationTokenConnection = {
	__typename?: "IntegrationTokenConnection";
	edges: Array<IntegrationTokenEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type IntegrationTokenEdge = {
	__typename?: "IntegrationTokenEdge";
	cursor: Scalars["Cursor"];
	node: IntegrationToken;
};

export type IntegrationTokenFilterInput = {
	and?: InputMaybe<Array<IntegrationTokenFilterInput>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<IntegrationTokenFilterInput>;
	or?: InputMaybe<Array<IntegrationTokenFilterInput>>;
	sourceEQ?: InputMaybe<IntegrationTokenSource>;
	sourceIn?: InputMaybe<Array<IntegrationTokenSource>>;
	sourceNEQ?: InputMaybe<IntegrationTokenSource>;
	sourceNotIn?: InputMaybe<Array<IntegrationTokenSource>>;
	user?: InputMaybe<Array<UserFilterInput>>;
	userIDEQ?: InputMaybe<Scalars["ID"]>;
	userIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	userIDNEQ?: InputMaybe<Scalars["ID"]>;
	userIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type IntegrationTokenHistory = Node & {
	__typename?: "IntegrationTokenHistory";
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	originalID: Maybe<Scalars["ID"]>;
	source: Maybe<IntegrationTokenHistorySource>;
	userID: Maybe<Scalars["ID"]>;
};

export type IntegrationTokenHistoryConnection = {
	__typename?: "IntegrationTokenHistoryConnection";
	edges: Array<IntegrationTokenHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type IntegrationTokenHistoryEdge = {
	__typename?: "IntegrationTokenHistoryEdge";
	cursor: Scalars["Cursor"];
	node: IntegrationTokenHistory;
};

export type IntegrationTokenHistoryFilterInput = {
	and?: InputMaybe<Array<IntegrationTokenHistoryFilterInput>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<IntegrationTokenHistoryFilterInput>;
	or?: InputMaybe<Array<IntegrationTokenHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	sourceEQ?: InputMaybe<IntegrationTokenHistorySource>;
	sourceIn?: InputMaybe<Array<IntegrationTokenHistorySource>>;
	sourceIsNull?: InputMaybe<Scalars["Boolean"]>;
	sourceNEQ?: InputMaybe<IntegrationTokenHistorySource>;
	sourceNotIn?: InputMaybe<Array<IntegrationTokenHistorySource>>;
	userIDEQ?: InputMaybe<Scalars["ID"]>;
	userIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	userIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	userIDNEQ?: InputMaybe<Scalars["ID"]>;
	userIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type IntegrationTokenHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<IntegrationTokenHistoryOrderField>;
};

export enum IntegrationTokenHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export enum IntegrationTokenHistorySource {
	GoogleCalendar = "google_calendar",
}

export type IntegrationTokenOrder = {
	direction: OrderDirection;
};

export enum IntegrationTokenSource {
	GoogleCalendar = "google_calendar",
}

export type Invoice = Node & {
	__typename?: "Invoice";
	contractInstallment: ContractInstallment;
	contractInstallmentID: Scalars["ID"];
	createdDate: Scalars["Time"];
	description: Scalars["String"];
	dueDate: Scalars["Time"];
	id: Scalars["ID"];
	invoiceLineItems: Array<InvoiceLineItem>;
	invoiceNumber: Scalars["String"];
	paymentLineItems: Array<PaymentLineItem>;
};

export type InvoiceInvoiceLineItemsArgs = {
	filters?: InputMaybe<Array<InvoiceLineItemFilterInput>>;
	order?: InputMaybe<InvoiceLineItemOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type InvoicePaymentLineItemsArgs = {
	filters?: InputMaybe<Array<PaymentLineItemFilterInput>>;
	order?: InputMaybe<PaymentLineItemOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type InvoiceConnection = {
	__typename?: "InvoiceConnection";
	edges: Array<InvoiceEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type InvoiceCreateInput = {
	contractInstallment?: InputMaybe<ContractInstallmentInInvoiceCreateInput>;
	contractInstallmentID?: InputMaybe<Scalars["ID"]>;
	createdDate: Scalars["Time"];
	description?: InputMaybe<Scalars["String"]>;
	dueDate: Scalars["Time"];
	invoiceLineItemIDs?: InputMaybe<Array<Scalars["ID"]>>;
	invoiceLineItems?: InputMaybe<Array<InvoiceLineItemInInvoiceCreateInput>>;
	paymentLineItemIDs?: InputMaybe<Array<Scalars["ID"]>>;
	paymentLineItems?: InputMaybe<Array<PaymentLineItemInInvoiceCreateInput>>;
};

export type InvoiceEdge = {
	__typename?: "InvoiceEdge";
	cursor: Scalars["Cursor"];
	node: Invoice;
};

export type InvoiceFilterInput = {
	and?: InputMaybe<Array<InvoiceFilterInput>>;
	contractInstallment?: InputMaybe<Array<ContractInstallmentFilterInput>>;
	contractInstallmentIDEQ?: InputMaybe<Scalars["ID"]>;
	contractInstallmentIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	contractInstallmentIDNEQ?: InputMaybe<Scalars["ID"]>;
	contractInstallmentIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	createdDateEQ?: InputMaybe<Scalars["Time"]>;
	createdDateGT?: InputMaybe<Scalars["Time"]>;
	createdDateGTE?: InputMaybe<Scalars["Time"]>;
	createdDateIn?: InputMaybe<Array<Scalars["Time"]>>;
	createdDateLT?: InputMaybe<Scalars["Time"]>;
	createdDateLTE?: InputMaybe<Scalars["Time"]>;
	createdDateNEQ?: InputMaybe<Scalars["Time"]>;
	createdDateNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	descriptionContains?: InputMaybe<Scalars["String"]>;
	descriptionEQ?: InputMaybe<Scalars["String"]>;
	descriptionHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionIn?: InputMaybe<Array<Scalars["String"]>>;
	descriptionNEQ?: InputMaybe<Scalars["String"]>;
	descriptionNotContains?: InputMaybe<Scalars["String"]>;
	descriptionNotHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionNotHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionNotIn?: InputMaybe<Array<Scalars["String"]>>;
	dueDateEQ?: InputMaybe<Scalars["Time"]>;
	dueDateGT?: InputMaybe<Scalars["Time"]>;
	dueDateGTE?: InputMaybe<Scalars["Time"]>;
	dueDateIn?: InputMaybe<Array<Scalars["Time"]>>;
	dueDateLT?: InputMaybe<Scalars["Time"]>;
	dueDateLTE?: InputMaybe<Scalars["Time"]>;
	dueDateNEQ?: InputMaybe<Scalars["Time"]>;
	dueDateNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	hasInvoiceLineItems?: InputMaybe<Scalars["Boolean"]>;
	hasPaymentLineItems?: InputMaybe<Scalars["Boolean"]>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	invoiceLineItems?: InputMaybe<Array<InvoiceLineItemFilterInput>>;
	invoiceNumberContains?: InputMaybe<Scalars["String"]>;
	invoiceNumberEQ?: InputMaybe<Scalars["String"]>;
	invoiceNumberHasPrefix?: InputMaybe<Scalars["String"]>;
	invoiceNumberHasSuffix?: InputMaybe<Scalars["String"]>;
	invoiceNumberIn?: InputMaybe<Array<Scalars["String"]>>;
	invoiceNumberNEQ?: InputMaybe<Scalars["String"]>;
	invoiceNumberNotContains?: InputMaybe<Scalars["String"]>;
	invoiceNumberNotHasPrefix?: InputMaybe<Scalars["String"]>;
	invoiceNumberNotHasSuffix?: InputMaybe<Scalars["String"]>;
	invoiceNumberNotIn?: InputMaybe<Array<Scalars["String"]>>;
	not?: InputMaybe<InvoiceFilterInput>;
	or?: InputMaybe<Array<InvoiceFilterInput>>;
	paymentLineItems?: InputMaybe<Array<PaymentLineItemFilterInput>>;
	search?: InputMaybe<Scalars["String"]>;
};

export type InvoiceHistory = Node & {
	__typename?: "InvoiceHistory";
	contractInstallmentID: Maybe<Scalars["ID"]>;
	createdDate: Maybe<Scalars["Time"]>;
	description: Maybe<Scalars["String"]>;
	dueDate: Maybe<Scalars["Time"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	invoiceNumber: Maybe<Scalars["String"]>;
	originalID: Maybe<Scalars["ID"]>;
};

export type InvoiceHistoryConnection = {
	__typename?: "InvoiceHistoryConnection";
	edges: Array<InvoiceHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type InvoiceHistoryEdge = {
	__typename?: "InvoiceHistoryEdge";
	cursor: Scalars["Cursor"];
	node: InvoiceHistory;
};

export type InvoiceHistoryFilterInput = {
	and?: InputMaybe<Array<InvoiceHistoryFilterInput>>;
	contractInstallmentIDEQ?: InputMaybe<Scalars["ID"]>;
	contractInstallmentIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	contractInstallmentIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	contractInstallmentIDNEQ?: InputMaybe<Scalars["ID"]>;
	contractInstallmentIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	createdDateEQ?: InputMaybe<Scalars["Time"]>;
	createdDateGT?: InputMaybe<Scalars["Time"]>;
	createdDateGTE?: InputMaybe<Scalars["Time"]>;
	createdDateIn?: InputMaybe<Array<Scalars["Time"]>>;
	createdDateIsNull?: InputMaybe<Scalars["Boolean"]>;
	createdDateLT?: InputMaybe<Scalars["Time"]>;
	createdDateLTE?: InputMaybe<Scalars["Time"]>;
	createdDateNEQ?: InputMaybe<Scalars["Time"]>;
	createdDateNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	descriptionContains?: InputMaybe<Scalars["String"]>;
	descriptionEQ?: InputMaybe<Scalars["String"]>;
	descriptionHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionIn?: InputMaybe<Array<Scalars["String"]>>;
	descriptionIsNull?: InputMaybe<Scalars["Boolean"]>;
	descriptionNEQ?: InputMaybe<Scalars["String"]>;
	descriptionNotContains?: InputMaybe<Scalars["String"]>;
	descriptionNotHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionNotHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionNotIn?: InputMaybe<Array<Scalars["String"]>>;
	dueDateEQ?: InputMaybe<Scalars["Time"]>;
	dueDateGT?: InputMaybe<Scalars["Time"]>;
	dueDateGTE?: InputMaybe<Scalars["Time"]>;
	dueDateIn?: InputMaybe<Array<Scalars["Time"]>>;
	dueDateIsNull?: InputMaybe<Scalars["Boolean"]>;
	dueDateLT?: InputMaybe<Scalars["Time"]>;
	dueDateLTE?: InputMaybe<Scalars["Time"]>;
	dueDateNEQ?: InputMaybe<Scalars["Time"]>;
	dueDateNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	invoiceNumberContains?: InputMaybe<Scalars["String"]>;
	invoiceNumberEQ?: InputMaybe<Scalars["String"]>;
	invoiceNumberHasPrefix?: InputMaybe<Scalars["String"]>;
	invoiceNumberHasSuffix?: InputMaybe<Scalars["String"]>;
	invoiceNumberIn?: InputMaybe<Array<Scalars["String"]>>;
	invoiceNumberIsNull?: InputMaybe<Scalars["Boolean"]>;
	invoiceNumberNEQ?: InputMaybe<Scalars["String"]>;
	invoiceNumberNotContains?: InputMaybe<Scalars["String"]>;
	invoiceNumberNotHasPrefix?: InputMaybe<Scalars["String"]>;
	invoiceNumberNotHasSuffix?: InputMaybe<Scalars["String"]>;
	invoiceNumberNotIn?: InputMaybe<Array<Scalars["String"]>>;
	not?: InputMaybe<InvoiceHistoryFilterInput>;
	or?: InputMaybe<Array<InvoiceHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	search?: InputMaybe<Scalars["String"]>;
};

export type InvoiceHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<InvoiceHistoryOrderField>;
};

export enum InvoiceHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type InvoiceInContractInstallmentCreateInput = {
	createdDate: Scalars["Time"];
	description?: InputMaybe<Scalars["String"]>;
	dueDate: Scalars["Time"];
	invoiceLineItemIDs?: InputMaybe<Array<Scalars["ID"]>>;
	invoiceLineItems?: InputMaybe<Array<InvoiceLineItemInInvoiceCreateInput>>;
	paymentLineItemIDs?: InputMaybe<Array<Scalars["ID"]>>;
	paymentLineItems?: InputMaybe<Array<PaymentLineItemInInvoiceCreateInput>>;
};

export type InvoiceLineItem = Node & {
	__typename?: "InvoiceLineItem";
	amount: Scalars["Int"];
	description: Scalars["String"];
	id: Scalars["ID"];
	invoice: Invoice;
	invoiceID: Scalars["ID"];
	lineItemNumber: Scalars["Int"];
	serviceSubscription: Maybe<ServiceSubscription>;
	serviceSubscriptionID: Maybe<Scalars["ID"]>;
};

export type InvoiceLineItemConnection = {
	__typename?: "InvoiceLineItemConnection";
	edges: Array<InvoiceLineItemEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type InvoiceLineItemCreateInput = {
	amount: Scalars["Int"];
	description: Scalars["String"];
	invoiceID: Scalars["ID"];
	lineItemNumber: Scalars["Int"];
	serviceSubscriptionID?: InputMaybe<Scalars["ID"]>;
};

export type InvoiceLineItemEdge = {
	__typename?: "InvoiceLineItemEdge";
	cursor: Scalars["Cursor"];
	node: InvoiceLineItem;
};

export type InvoiceLineItemFilterInput = {
	amountEQ?: InputMaybe<Scalars["Int"]>;
	amountGT?: InputMaybe<Scalars["Int"]>;
	amountGTE?: InputMaybe<Scalars["Int"]>;
	amountIn?: InputMaybe<Array<Scalars["Int"]>>;
	amountLT?: InputMaybe<Scalars["Int"]>;
	amountLTE?: InputMaybe<Scalars["Int"]>;
	amountNEQ?: InputMaybe<Scalars["Int"]>;
	amountNotIn?: InputMaybe<Array<Scalars["Int"]>>;
	and?: InputMaybe<Array<InvoiceLineItemFilterInput>>;
	descriptionContains?: InputMaybe<Scalars["String"]>;
	descriptionEQ?: InputMaybe<Scalars["String"]>;
	descriptionHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionIn?: InputMaybe<Array<Scalars["String"]>>;
	descriptionNEQ?: InputMaybe<Scalars["String"]>;
	descriptionNotContains?: InputMaybe<Scalars["String"]>;
	descriptionNotHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionNotHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionNotIn?: InputMaybe<Array<Scalars["String"]>>;
	hasServiceSubscription?: InputMaybe<Scalars["Boolean"]>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	invoice?: InputMaybe<Array<InvoiceFilterInput>>;
	invoiceIDEQ?: InputMaybe<Scalars["ID"]>;
	invoiceIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	invoiceIDNEQ?: InputMaybe<Scalars["ID"]>;
	invoiceIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	lineItemNumberEQ?: InputMaybe<Scalars["Int"]>;
	lineItemNumberGT?: InputMaybe<Scalars["Int"]>;
	lineItemNumberGTE?: InputMaybe<Scalars["Int"]>;
	lineItemNumberIn?: InputMaybe<Array<Scalars["Int"]>>;
	lineItemNumberLT?: InputMaybe<Scalars["Int"]>;
	lineItemNumberLTE?: InputMaybe<Scalars["Int"]>;
	lineItemNumberNEQ?: InputMaybe<Scalars["Int"]>;
	lineItemNumberNotIn?: InputMaybe<Array<Scalars["Int"]>>;
	not?: InputMaybe<InvoiceLineItemFilterInput>;
	or?: InputMaybe<Array<InvoiceLineItemFilterInput>>;
	search?: InputMaybe<Scalars["String"]>;
	serviceSubscription?: InputMaybe<Array<ServiceSubscriptionFilterInput>>;
	serviceSubscriptionIDEQ?: InputMaybe<Scalars["ID"]>;
	serviceSubscriptionIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	serviceSubscriptionIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	serviceSubscriptionIDNEQ?: InputMaybe<Scalars["ID"]>;
	serviceSubscriptionIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type InvoiceLineItemHistory = Node & {
	__typename?: "InvoiceLineItemHistory";
	amount: Maybe<Scalars["Int"]>;
	description: Maybe<Scalars["String"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	invoiceID: Maybe<Scalars["ID"]>;
	lineItemNumber: Maybe<Scalars["Int"]>;
	originalID: Maybe<Scalars["ID"]>;
	serviceSubscriptionID: Maybe<Scalars["ID"]>;
};

export type InvoiceLineItemHistoryConnection = {
	__typename?: "InvoiceLineItemHistoryConnection";
	edges: Array<InvoiceLineItemHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type InvoiceLineItemHistoryEdge = {
	__typename?: "InvoiceLineItemHistoryEdge";
	cursor: Scalars["Cursor"];
	node: InvoiceLineItemHistory;
};

export type InvoiceLineItemHistoryFilterInput = {
	amountEQ?: InputMaybe<Scalars["Int"]>;
	amountGT?: InputMaybe<Scalars["Int"]>;
	amountGTE?: InputMaybe<Scalars["Int"]>;
	amountIn?: InputMaybe<Array<Scalars["Int"]>>;
	amountIsNull?: InputMaybe<Scalars["Boolean"]>;
	amountLT?: InputMaybe<Scalars["Int"]>;
	amountLTE?: InputMaybe<Scalars["Int"]>;
	amountNEQ?: InputMaybe<Scalars["Int"]>;
	amountNotIn?: InputMaybe<Array<Scalars["Int"]>>;
	and?: InputMaybe<Array<InvoiceLineItemHistoryFilterInput>>;
	descriptionContains?: InputMaybe<Scalars["String"]>;
	descriptionEQ?: InputMaybe<Scalars["String"]>;
	descriptionHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionIn?: InputMaybe<Array<Scalars["String"]>>;
	descriptionIsNull?: InputMaybe<Scalars["Boolean"]>;
	descriptionNEQ?: InputMaybe<Scalars["String"]>;
	descriptionNotContains?: InputMaybe<Scalars["String"]>;
	descriptionNotHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionNotHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionNotIn?: InputMaybe<Array<Scalars["String"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	invoiceIDEQ?: InputMaybe<Scalars["ID"]>;
	invoiceIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	invoiceIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	invoiceIDNEQ?: InputMaybe<Scalars["ID"]>;
	invoiceIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	lineItemNumberEQ?: InputMaybe<Scalars["Int"]>;
	lineItemNumberGT?: InputMaybe<Scalars["Int"]>;
	lineItemNumberGTE?: InputMaybe<Scalars["Int"]>;
	lineItemNumberIn?: InputMaybe<Array<Scalars["Int"]>>;
	lineItemNumberIsNull?: InputMaybe<Scalars["Boolean"]>;
	lineItemNumberLT?: InputMaybe<Scalars["Int"]>;
	lineItemNumberLTE?: InputMaybe<Scalars["Int"]>;
	lineItemNumberNEQ?: InputMaybe<Scalars["Int"]>;
	lineItemNumberNotIn?: InputMaybe<Array<Scalars["Int"]>>;
	not?: InputMaybe<InvoiceLineItemHistoryFilterInput>;
	or?: InputMaybe<Array<InvoiceLineItemHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	search?: InputMaybe<Scalars["String"]>;
	serviceSubscriptionIDEQ?: InputMaybe<Scalars["ID"]>;
	serviceSubscriptionIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	serviceSubscriptionIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	serviceSubscriptionIDNEQ?: InputMaybe<Scalars["ID"]>;
	serviceSubscriptionIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type InvoiceLineItemHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<InvoiceLineItemHistoryOrderField>;
};

export enum InvoiceLineItemHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type InvoiceLineItemInInvoiceCreateInput = {
	amount: Scalars["Int"];
	description: Scalars["String"];
	lineItemNumber: Scalars["Int"];
	serviceSubscriptionID?: InputMaybe<Scalars["ID"]>;
};

export type InvoiceLineItemInServiceSubscriptionCreateInput = {
	amount: Scalars["Int"];
	description: Scalars["String"];
	invoiceID: Scalars["ID"];
	lineItemNumber: Scalars["Int"];
};

export type InvoiceLineItemOrder = {
	direction: OrderDirection;
	field?: InputMaybe<InvoiceLineItemOrderField>;
};

export enum InvoiceLineItemOrderField {
	Amount = "AMOUNT",
	LineItemNumber = "LINE_ITEM_NUMBER",
}

export type InvoiceLineItemUpdateBulkInput = {
	amount?: InputMaybe<Scalars["Int"]>;
	clearServiceSubscription?: InputMaybe<Scalars["Boolean"]>;
	description?: InputMaybe<Scalars["String"]>;
	invoiceID?: InputMaybe<Scalars["ID"]>;
	lineItemNumber?: InputMaybe<Scalars["Int"]>;
	serviceSubscriptionID?: InputMaybe<Scalars["ID"]>;
};

export type InvoiceLineItemUpdateInput = {
	amount?: InputMaybe<Scalars["Int"]>;
	clearServiceSubscription?: InputMaybe<Scalars["Boolean"]>;
	description?: InputMaybe<Scalars["String"]>;
	invoiceID?: InputMaybe<Scalars["ID"]>;
	lineItemNumber?: InputMaybe<Scalars["Int"]>;
	serviceSubscriptionID?: InputMaybe<Scalars["ID"]>;
};

export type InvoiceOrder = {
	direction: OrderDirection;
	field?: InputMaybe<InvoiceOrderField>;
};

export enum InvoiceOrderField {
	CreatedDate = "CREATED_DATE",
	DueDate = "DUE_DATE",
	InvoiceNumber = "INVOICE_NUMBER",
}

export type InvoiceUpdateBulkInput = {
	addInvoiceLineItemIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addPaymentLineItemIDs?: InputMaybe<Array<Scalars["ID"]>>;
	createdDate?: InputMaybe<Scalars["Time"]>;
	description?: InputMaybe<Scalars["String"]>;
	dueDate?: InputMaybe<Scalars["Time"]>;
	removeInvoiceLineItemIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removePaymentLineItemIDs?: InputMaybe<Array<Scalars["ID"]>>;
};

export type InvoiceUpdateInput = {
	addInvoiceLineItemIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addPaymentLineItemIDs?: InputMaybe<Array<Scalars["ID"]>>;
	contractInstallmentID?: InputMaybe<Scalars["ID"]>;
	createdDate?: InputMaybe<Scalars["Time"]>;
	description?: InputMaybe<Scalars["String"]>;
	dueDate?: InputMaybe<Scalars["Time"]>;
	removeInvoiceLineItemIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removePaymentLineItemIDs?: InputMaybe<Array<Scalars["ID"]>>;
};

export type LearningObjective = Node & {
	__typename?: "LearningObjective";
	category: Scalars["String"];
	course: Course;
	courseID: Scalars["ID"];
	description: Scalars["String"];
	id: Scalars["ID"];
	isActive: Scalars["Boolean"];
	learningObjectiveMarks: Array<LearningObjectiveMark>;
	lessonPlans: Array<LessonPlan>;
};

export type LearningObjectiveLearningObjectiveMarksArgs = {
	filters?: InputMaybe<Array<LearningObjectiveMarkFilterInput>>;
	order?: InputMaybe<LearningObjectiveMarkOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type LearningObjectiveLessonPlansArgs = {
	filters?: InputMaybe<Array<LessonPlanFilterInput>>;
	order?: InputMaybe<LessonPlanOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type LearningObjectiveConnection = {
	__typename?: "LearningObjectiveConnection";
	edges: Array<LearningObjectiveEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type LearningObjectiveCreateInput = {
	category: Scalars["String"];
	courseID: Scalars["ID"];
	description: Scalars["String"];
	isActive?: InputMaybe<Scalars["Boolean"]>;
	learningObjectiveMarkIDs?: InputMaybe<Array<Scalars["ID"]>>;
	learningObjectiveMarks?: InputMaybe<Array<LearningObjectiveMarkInLearningObjectiveCreateInput>>;
	lessonPlanIDs?: InputMaybe<Array<Scalars["ID"]>>;
};

export type LearningObjectiveEdge = {
	__typename?: "LearningObjectiveEdge";
	cursor: Scalars["Cursor"];
	node: LearningObjective;
};

export type LearningObjectiveFilterInput = {
	and?: InputMaybe<Array<LearningObjectiveFilterInput>>;
	categoryContains?: InputMaybe<Scalars["String"]>;
	categoryEQ?: InputMaybe<Scalars["String"]>;
	categoryHasPrefix?: InputMaybe<Scalars["String"]>;
	categoryHasSuffix?: InputMaybe<Scalars["String"]>;
	categoryIn?: InputMaybe<Array<Scalars["String"]>>;
	categoryNEQ?: InputMaybe<Scalars["String"]>;
	categoryNotContains?: InputMaybe<Scalars["String"]>;
	categoryNotHasPrefix?: InputMaybe<Scalars["String"]>;
	categoryNotHasSuffix?: InputMaybe<Scalars["String"]>;
	categoryNotIn?: InputMaybe<Array<Scalars["String"]>>;
	course?: InputMaybe<Array<CourseFilterInput>>;
	courseIDEQ?: InputMaybe<Scalars["ID"]>;
	courseIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	courseIDNEQ?: InputMaybe<Scalars["ID"]>;
	courseIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	descriptionContains?: InputMaybe<Scalars["String"]>;
	descriptionEQ?: InputMaybe<Scalars["String"]>;
	descriptionHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionIn?: InputMaybe<Array<Scalars["String"]>>;
	descriptionNEQ?: InputMaybe<Scalars["String"]>;
	descriptionNotContains?: InputMaybe<Scalars["String"]>;
	descriptionNotHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionNotHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionNotIn?: InputMaybe<Array<Scalars["String"]>>;
	hasLearningObjectiveMarks?: InputMaybe<Scalars["Boolean"]>;
	hasLessonPlans?: InputMaybe<Scalars["Boolean"]>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	isActiveEQ?: InputMaybe<Scalars["Boolean"]>;
	isActiveNEQ?: InputMaybe<Scalars["Boolean"]>;
	learningObjectiveMarks?: InputMaybe<Array<LearningObjectiveMarkFilterInput>>;
	lessonPlans?: InputMaybe<Array<LessonPlanFilterInput>>;
	not?: InputMaybe<LearningObjectiveFilterInput>;
	or?: InputMaybe<Array<LearningObjectiveFilterInput>>;
	search?: InputMaybe<Scalars["String"]>;
};

export type LearningObjectiveHistory = Node & {
	__typename?: "LearningObjectiveHistory";
	category: Maybe<Scalars["String"]>;
	courseID: Maybe<Scalars["ID"]>;
	description: Maybe<Scalars["String"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	isActive: Maybe<Scalars["Boolean"]>;
	originalID: Maybe<Scalars["ID"]>;
};

export type LearningObjectiveHistoryConnection = {
	__typename?: "LearningObjectiveHistoryConnection";
	edges: Array<LearningObjectiveHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type LearningObjectiveHistoryEdge = {
	__typename?: "LearningObjectiveHistoryEdge";
	cursor: Scalars["Cursor"];
	node: LearningObjectiveHistory;
};

export type LearningObjectiveHistoryFilterInput = {
	and?: InputMaybe<Array<LearningObjectiveHistoryFilterInput>>;
	categoryContains?: InputMaybe<Scalars["String"]>;
	categoryEQ?: InputMaybe<Scalars["String"]>;
	categoryHasPrefix?: InputMaybe<Scalars["String"]>;
	categoryHasSuffix?: InputMaybe<Scalars["String"]>;
	categoryIn?: InputMaybe<Array<Scalars["String"]>>;
	categoryIsNull?: InputMaybe<Scalars["Boolean"]>;
	categoryNEQ?: InputMaybe<Scalars["String"]>;
	categoryNotContains?: InputMaybe<Scalars["String"]>;
	categoryNotHasPrefix?: InputMaybe<Scalars["String"]>;
	categoryNotHasSuffix?: InputMaybe<Scalars["String"]>;
	categoryNotIn?: InputMaybe<Array<Scalars["String"]>>;
	courseIDEQ?: InputMaybe<Scalars["ID"]>;
	courseIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	courseIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	courseIDNEQ?: InputMaybe<Scalars["ID"]>;
	courseIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	descriptionContains?: InputMaybe<Scalars["String"]>;
	descriptionEQ?: InputMaybe<Scalars["String"]>;
	descriptionHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionIn?: InputMaybe<Array<Scalars["String"]>>;
	descriptionIsNull?: InputMaybe<Scalars["Boolean"]>;
	descriptionNEQ?: InputMaybe<Scalars["String"]>;
	descriptionNotContains?: InputMaybe<Scalars["String"]>;
	descriptionNotHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionNotHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionNotIn?: InputMaybe<Array<Scalars["String"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	isActiveEQ?: InputMaybe<Scalars["Boolean"]>;
	isActiveIsNull?: InputMaybe<Scalars["Boolean"]>;
	isActiveNEQ?: InputMaybe<Scalars["Boolean"]>;
	not?: InputMaybe<LearningObjectiveHistoryFilterInput>;
	or?: InputMaybe<Array<LearningObjectiveHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	search?: InputMaybe<Scalars["String"]>;
};

export type LearningObjectiveHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<LearningObjectiveHistoryOrderField>;
};

export enum LearningObjectiveHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type LearningObjectiveInCourseCreateInput = {
	category: Scalars["String"];
	description: Scalars["String"];
	isActive?: InputMaybe<Scalars["Boolean"]>;
	learningObjectiveMarkIDs?: InputMaybe<Array<Scalars["ID"]>>;
	learningObjectiveMarks?: InputMaybe<Array<LearningObjectiveMarkInLearningObjectiveCreateInput>>;
	lessonPlanIDs?: InputMaybe<Array<Scalars["ID"]>>;
};

export type LearningObjectiveMark = Node & {
	__typename?: "LearningObjectiveMark";
	id: Scalars["ID"];
	learningObjective: LearningObjective;
	learningObjectiveID: Scalars["ID"];
	reportCard: ReportCard;
	reportCardID: Scalars["ID"];
	value: Maybe<LearningObjectiveMarkValue>;
};

export type LearningObjectiveMarkConnection = {
	__typename?: "LearningObjectiveMarkConnection";
	edges: Array<LearningObjectiveMarkEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type LearningObjectiveMarkCreateInput = {
	learningObjectiveID: Scalars["ID"];
	reportCardID: Scalars["ID"];
	value?: InputMaybe<LearningObjectiveMarkValue>;
};

export type LearningObjectiveMarkEdge = {
	__typename?: "LearningObjectiveMarkEdge";
	cursor: Scalars["Cursor"];
	node: LearningObjectiveMark;
};

export type LearningObjectiveMarkFilterInput = {
	and?: InputMaybe<Array<LearningObjectiveMarkFilterInput>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	learningObjective?: InputMaybe<Array<LearningObjectiveFilterInput>>;
	learningObjectiveIDEQ?: InputMaybe<Scalars["ID"]>;
	learningObjectiveIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	learningObjectiveIDNEQ?: InputMaybe<Scalars["ID"]>;
	learningObjectiveIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<LearningObjectiveMarkFilterInput>;
	or?: InputMaybe<Array<LearningObjectiveMarkFilterInput>>;
	reportCard?: InputMaybe<Array<ReportCardFilterInput>>;
	reportCardIDEQ?: InputMaybe<Scalars["ID"]>;
	reportCardIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	reportCardIDNEQ?: InputMaybe<Scalars["ID"]>;
	reportCardIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	valueEQ?: InputMaybe<LearningObjectiveMarkValue>;
	valueIn?: InputMaybe<Array<LearningObjectiveMarkValue>>;
	valueIsNull?: InputMaybe<Scalars["Boolean"]>;
	valueNEQ?: InputMaybe<LearningObjectiveMarkValue>;
	valueNotIn?: InputMaybe<Array<LearningObjectiveMarkValue>>;
};

export type LearningObjectiveMarkHistory = Node & {
	__typename?: "LearningObjectiveMarkHistory";
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	learningObjectiveID: Maybe<Scalars["ID"]>;
	originalID: Maybe<Scalars["ID"]>;
	reportCardID: Maybe<Scalars["ID"]>;
	value: Maybe<LearningObjectiveMarkHistoryValue>;
};

export type LearningObjectiveMarkHistoryConnection = {
	__typename?: "LearningObjectiveMarkHistoryConnection";
	edges: Array<LearningObjectiveMarkHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type LearningObjectiveMarkHistoryEdge = {
	__typename?: "LearningObjectiveMarkHistoryEdge";
	cursor: Scalars["Cursor"];
	node: LearningObjectiveMarkHistory;
};

export type LearningObjectiveMarkHistoryFilterInput = {
	and?: InputMaybe<Array<LearningObjectiveMarkHistoryFilterInput>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	learningObjectiveIDEQ?: InputMaybe<Scalars["ID"]>;
	learningObjectiveIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	learningObjectiveIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	learningObjectiveIDNEQ?: InputMaybe<Scalars["ID"]>;
	learningObjectiveIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<LearningObjectiveMarkHistoryFilterInput>;
	or?: InputMaybe<Array<LearningObjectiveMarkHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	reportCardIDEQ?: InputMaybe<Scalars["ID"]>;
	reportCardIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	reportCardIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	reportCardIDNEQ?: InputMaybe<Scalars["ID"]>;
	reportCardIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	valueEQ?: InputMaybe<LearningObjectiveMarkHistoryValue>;
	valueIn?: InputMaybe<Array<LearningObjectiveMarkHistoryValue>>;
	valueIsNull?: InputMaybe<Scalars["Boolean"]>;
	valueNEQ?: InputMaybe<LearningObjectiveMarkHistoryValue>;
	valueNotIn?: InputMaybe<Array<LearningObjectiveMarkHistoryValue>>;
};

export type LearningObjectiveMarkHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<LearningObjectiveMarkHistoryOrderField>;
};

export enum LearningObjectiveMarkHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export enum LearningObjectiveMarkHistoryValue {
	Developing = "developing",
	Exceeding = "exceeding",
	Meeting = "meeting",
}

export type LearningObjectiveMarkInLearningObjectiveCreateInput = {
	reportCardID: Scalars["ID"];
	value?: InputMaybe<LearningObjectiveMarkValue>;
};

export type LearningObjectiveMarkInReportCardCreateInput = {
	learningObjectiveID: Scalars["ID"];
	value?: InputMaybe<LearningObjectiveMarkValue>;
};

export type LearningObjectiveMarkOrder = {
	direction: OrderDirection;
	field?: InputMaybe<LearningObjectiveMarkOrderField>;
};

export enum LearningObjectiveMarkOrderField {
	Value = "VALUE",
}

export type LearningObjectiveMarkUpdateBulkInput = {
	clearValue?: InputMaybe<Scalars["Boolean"]>;
	learningObjectiveID?: InputMaybe<Scalars["ID"]>;
	reportCardID?: InputMaybe<Scalars["ID"]>;
	value?: InputMaybe<LearningObjectiveMarkValue>;
};

export type LearningObjectiveMarkUpdateInput = {
	clearValue?: InputMaybe<Scalars["Boolean"]>;
	learningObjectiveID?: InputMaybe<Scalars["ID"]>;
	reportCardID?: InputMaybe<Scalars["ID"]>;
	value?: InputMaybe<LearningObjectiveMarkValue>;
};

export enum LearningObjectiveMarkValue {
	Developing = "developing",
	Exceeding = "exceeding",
	Meeting = "meeting",
}

export type LearningObjectiveOrder = {
	direction: OrderDirection;
	field?: InputMaybe<LearningObjectiveOrderField>;
};

export enum LearningObjectiveOrderField {
	Category = "CATEGORY",
	Description = "DESCRIPTION",
	IsActive = "IS_ACTIVE",
}

export type LearningObjectiveUpdateBulkInput = {
	addLearningObjectiveMarkIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addLessonPlanIDs?: InputMaybe<Array<Scalars["ID"]>>;
	category?: InputMaybe<Scalars["String"]>;
	courseID?: InputMaybe<Scalars["ID"]>;
	description?: InputMaybe<Scalars["String"]>;
	isActive?: InputMaybe<Scalars["Boolean"]>;
	removeLearningObjectiveMarkIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeLessonPlanIDs?: InputMaybe<Array<Scalars["ID"]>>;
};

export type LearningObjectiveUpdateInput = {
	addLearningObjectiveMarkIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addLessonPlanIDs?: InputMaybe<Array<Scalars["ID"]>>;
	category?: InputMaybe<Scalars["String"]>;
	courseID?: InputMaybe<Scalars["ID"]>;
	description?: InputMaybe<Scalars["String"]>;
	isActive?: InputMaybe<Scalars["Boolean"]>;
	removeLearningObjectiveMarkIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeLessonPlanIDs?: InputMaybe<Array<Scalars["ID"]>>;
};

export type LessonPlan = Node & {
	__typename?: "LessonPlan";
	assessmentDescription: Scalars["String"];
	courseDate: Scalars["Time"];
	courseSection: CourseSection;
	courseSectionID: Scalars["ID"];
	differentiationDescription: Scalars["String"];
	id: Scalars["ID"];
	learningObjectives: Array<LearningObjective>;
	lessonDescription: Scalars["String"];
	notes: Scalars["String"];
	requiredResourcesAndMaterials: Scalars["String"];
	stationOneDescription: Scalars["String"];
	stationThreeDescription: Scalars["String"];
	stationTwoDescription: Scalars["String"];
};

export type LessonPlanLearningObjectivesArgs = {
	filters?: InputMaybe<Array<LearningObjectiveFilterInput>>;
	order?: InputMaybe<LearningObjectiveOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type LessonPlanConnection = {
	__typename?: "LessonPlanConnection";
	edges: Array<LessonPlanEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type LessonPlanCreateInput = {
	assessmentDescription?: InputMaybe<Scalars["String"]>;
	courseDate: Scalars["Time"];
	courseSectionID: Scalars["ID"];
	differentiationDescription?: InputMaybe<Scalars["String"]>;
	learningObjectiveIDs?: InputMaybe<Array<Scalars["ID"]>>;
	lessonDescription: Scalars["String"];
	notes?: InputMaybe<Scalars["String"]>;
	requiredResourcesAndMaterials?: InputMaybe<Scalars["String"]>;
	stationOneDescription?: InputMaybe<Scalars["String"]>;
	stationThreeDescription?: InputMaybe<Scalars["String"]>;
	stationTwoDescription?: InputMaybe<Scalars["String"]>;
};

export type LessonPlanEdge = {
	__typename?: "LessonPlanEdge";
	cursor: Scalars["Cursor"];
	node: LessonPlan;
};

export type LessonPlanFilterInput = {
	and?: InputMaybe<Array<LessonPlanFilterInput>>;
	assessmentDescriptionContains?: InputMaybe<Scalars["String"]>;
	assessmentDescriptionEQ?: InputMaybe<Scalars["String"]>;
	assessmentDescriptionHasPrefix?: InputMaybe<Scalars["String"]>;
	assessmentDescriptionHasSuffix?: InputMaybe<Scalars["String"]>;
	assessmentDescriptionIn?: InputMaybe<Array<Scalars["String"]>>;
	assessmentDescriptionNEQ?: InputMaybe<Scalars["String"]>;
	assessmentDescriptionNotContains?: InputMaybe<Scalars["String"]>;
	assessmentDescriptionNotHasPrefix?: InputMaybe<Scalars["String"]>;
	assessmentDescriptionNotHasSuffix?: InputMaybe<Scalars["String"]>;
	assessmentDescriptionNotIn?: InputMaybe<Array<Scalars["String"]>>;
	courseDateEQ?: InputMaybe<Scalars["Time"]>;
	courseDateGT?: InputMaybe<Scalars["Time"]>;
	courseDateGTE?: InputMaybe<Scalars["Time"]>;
	courseDateIn?: InputMaybe<Array<Scalars["Time"]>>;
	courseDateLT?: InputMaybe<Scalars["Time"]>;
	courseDateLTE?: InputMaybe<Scalars["Time"]>;
	courseDateNEQ?: InputMaybe<Scalars["Time"]>;
	courseDateNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	courseSection?: InputMaybe<Array<CourseSectionFilterInput>>;
	courseSectionIDEQ?: InputMaybe<Scalars["ID"]>;
	courseSectionIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	courseSectionIDNEQ?: InputMaybe<Scalars["ID"]>;
	courseSectionIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	differentiationDescriptionContains?: InputMaybe<Scalars["String"]>;
	differentiationDescriptionEQ?: InputMaybe<Scalars["String"]>;
	differentiationDescriptionHasPrefix?: InputMaybe<Scalars["String"]>;
	differentiationDescriptionHasSuffix?: InputMaybe<Scalars["String"]>;
	differentiationDescriptionIn?: InputMaybe<Array<Scalars["String"]>>;
	differentiationDescriptionNEQ?: InputMaybe<Scalars["String"]>;
	differentiationDescriptionNotContains?: InputMaybe<Scalars["String"]>;
	differentiationDescriptionNotHasPrefix?: InputMaybe<Scalars["String"]>;
	differentiationDescriptionNotHasSuffix?: InputMaybe<Scalars["String"]>;
	differentiationDescriptionNotIn?: InputMaybe<Array<Scalars["String"]>>;
	hasLearningObjectives?: InputMaybe<Scalars["Boolean"]>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	learningObjectives?: InputMaybe<Array<LearningObjectiveFilterInput>>;
	lessonDescriptionContains?: InputMaybe<Scalars["String"]>;
	lessonDescriptionEQ?: InputMaybe<Scalars["String"]>;
	lessonDescriptionHasPrefix?: InputMaybe<Scalars["String"]>;
	lessonDescriptionHasSuffix?: InputMaybe<Scalars["String"]>;
	lessonDescriptionIn?: InputMaybe<Array<Scalars["String"]>>;
	lessonDescriptionNEQ?: InputMaybe<Scalars["String"]>;
	lessonDescriptionNotContains?: InputMaybe<Scalars["String"]>;
	lessonDescriptionNotHasPrefix?: InputMaybe<Scalars["String"]>;
	lessonDescriptionNotHasSuffix?: InputMaybe<Scalars["String"]>;
	lessonDescriptionNotIn?: InputMaybe<Array<Scalars["String"]>>;
	not?: InputMaybe<LessonPlanFilterInput>;
	notesContains?: InputMaybe<Scalars["String"]>;
	notesEQ?: InputMaybe<Scalars["String"]>;
	notesHasPrefix?: InputMaybe<Scalars["String"]>;
	notesHasSuffix?: InputMaybe<Scalars["String"]>;
	notesIn?: InputMaybe<Array<Scalars["String"]>>;
	notesNEQ?: InputMaybe<Scalars["String"]>;
	notesNotContains?: InputMaybe<Scalars["String"]>;
	notesNotHasPrefix?: InputMaybe<Scalars["String"]>;
	notesNotHasSuffix?: InputMaybe<Scalars["String"]>;
	notesNotIn?: InputMaybe<Array<Scalars["String"]>>;
	or?: InputMaybe<Array<LessonPlanFilterInput>>;
	requiredResourcesAndMaterialsContains?: InputMaybe<Scalars["String"]>;
	requiredResourcesAndMaterialsEQ?: InputMaybe<Scalars["String"]>;
	requiredResourcesAndMaterialsHasPrefix?: InputMaybe<Scalars["String"]>;
	requiredResourcesAndMaterialsHasSuffix?: InputMaybe<Scalars["String"]>;
	requiredResourcesAndMaterialsIn?: InputMaybe<Array<Scalars["String"]>>;
	requiredResourcesAndMaterialsNEQ?: InputMaybe<Scalars["String"]>;
	requiredResourcesAndMaterialsNotContains?: InputMaybe<Scalars["String"]>;
	requiredResourcesAndMaterialsNotHasPrefix?: InputMaybe<Scalars["String"]>;
	requiredResourcesAndMaterialsNotHasSuffix?: InputMaybe<Scalars["String"]>;
	requiredResourcesAndMaterialsNotIn?: InputMaybe<Array<Scalars["String"]>>;
	search?: InputMaybe<Scalars["String"]>;
	stationOneDescriptionContains?: InputMaybe<Scalars["String"]>;
	stationOneDescriptionEQ?: InputMaybe<Scalars["String"]>;
	stationOneDescriptionHasPrefix?: InputMaybe<Scalars["String"]>;
	stationOneDescriptionHasSuffix?: InputMaybe<Scalars["String"]>;
	stationOneDescriptionIn?: InputMaybe<Array<Scalars["String"]>>;
	stationOneDescriptionNEQ?: InputMaybe<Scalars["String"]>;
	stationOneDescriptionNotContains?: InputMaybe<Scalars["String"]>;
	stationOneDescriptionNotHasPrefix?: InputMaybe<Scalars["String"]>;
	stationOneDescriptionNotHasSuffix?: InputMaybe<Scalars["String"]>;
	stationOneDescriptionNotIn?: InputMaybe<Array<Scalars["String"]>>;
	stationThreeDescriptionContains?: InputMaybe<Scalars["String"]>;
	stationThreeDescriptionEQ?: InputMaybe<Scalars["String"]>;
	stationThreeDescriptionHasPrefix?: InputMaybe<Scalars["String"]>;
	stationThreeDescriptionHasSuffix?: InputMaybe<Scalars["String"]>;
	stationThreeDescriptionIn?: InputMaybe<Array<Scalars["String"]>>;
	stationThreeDescriptionNEQ?: InputMaybe<Scalars["String"]>;
	stationThreeDescriptionNotContains?: InputMaybe<Scalars["String"]>;
	stationThreeDescriptionNotHasPrefix?: InputMaybe<Scalars["String"]>;
	stationThreeDescriptionNotHasSuffix?: InputMaybe<Scalars["String"]>;
	stationThreeDescriptionNotIn?: InputMaybe<Array<Scalars["String"]>>;
	stationTwoDescriptionContains?: InputMaybe<Scalars["String"]>;
	stationTwoDescriptionEQ?: InputMaybe<Scalars["String"]>;
	stationTwoDescriptionHasPrefix?: InputMaybe<Scalars["String"]>;
	stationTwoDescriptionHasSuffix?: InputMaybe<Scalars["String"]>;
	stationTwoDescriptionIn?: InputMaybe<Array<Scalars["String"]>>;
	stationTwoDescriptionNEQ?: InputMaybe<Scalars["String"]>;
	stationTwoDescriptionNotContains?: InputMaybe<Scalars["String"]>;
	stationTwoDescriptionNotHasPrefix?: InputMaybe<Scalars["String"]>;
	stationTwoDescriptionNotHasSuffix?: InputMaybe<Scalars["String"]>;
	stationTwoDescriptionNotIn?: InputMaybe<Array<Scalars["String"]>>;
};

export type LessonPlanHistory = Node & {
	__typename?: "LessonPlanHistory";
	assessmentDescription: Maybe<Scalars["String"]>;
	courseDate: Maybe<Scalars["Time"]>;
	courseSectionID: Maybe<Scalars["ID"]>;
	differentiationDescription: Maybe<Scalars["String"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	lessonDescription: Maybe<Scalars["String"]>;
	notes: Maybe<Scalars["String"]>;
	originalID: Maybe<Scalars["ID"]>;
	requiredResourcesAndMaterials: Maybe<Scalars["String"]>;
	stationOneDescription: Maybe<Scalars["String"]>;
	stationThreeDescription: Maybe<Scalars["String"]>;
	stationTwoDescription: Maybe<Scalars["String"]>;
};

export type LessonPlanHistoryConnection = {
	__typename?: "LessonPlanHistoryConnection";
	edges: Array<LessonPlanHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type LessonPlanHistoryEdge = {
	__typename?: "LessonPlanHistoryEdge";
	cursor: Scalars["Cursor"];
	node: LessonPlanHistory;
};

export type LessonPlanHistoryFilterInput = {
	and?: InputMaybe<Array<LessonPlanHistoryFilterInput>>;
	assessmentDescriptionContains?: InputMaybe<Scalars["String"]>;
	assessmentDescriptionEQ?: InputMaybe<Scalars["String"]>;
	assessmentDescriptionHasPrefix?: InputMaybe<Scalars["String"]>;
	assessmentDescriptionHasSuffix?: InputMaybe<Scalars["String"]>;
	assessmentDescriptionIn?: InputMaybe<Array<Scalars["String"]>>;
	assessmentDescriptionIsNull?: InputMaybe<Scalars["Boolean"]>;
	assessmentDescriptionNEQ?: InputMaybe<Scalars["String"]>;
	assessmentDescriptionNotContains?: InputMaybe<Scalars["String"]>;
	assessmentDescriptionNotHasPrefix?: InputMaybe<Scalars["String"]>;
	assessmentDescriptionNotHasSuffix?: InputMaybe<Scalars["String"]>;
	assessmentDescriptionNotIn?: InputMaybe<Array<Scalars["String"]>>;
	courseDateEQ?: InputMaybe<Scalars["Time"]>;
	courseDateGT?: InputMaybe<Scalars["Time"]>;
	courseDateGTE?: InputMaybe<Scalars["Time"]>;
	courseDateIn?: InputMaybe<Array<Scalars["Time"]>>;
	courseDateIsNull?: InputMaybe<Scalars["Boolean"]>;
	courseDateLT?: InputMaybe<Scalars["Time"]>;
	courseDateLTE?: InputMaybe<Scalars["Time"]>;
	courseDateNEQ?: InputMaybe<Scalars["Time"]>;
	courseDateNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	courseSectionIDEQ?: InputMaybe<Scalars["ID"]>;
	courseSectionIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	courseSectionIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	courseSectionIDNEQ?: InputMaybe<Scalars["ID"]>;
	courseSectionIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	differentiationDescriptionContains?: InputMaybe<Scalars["String"]>;
	differentiationDescriptionEQ?: InputMaybe<Scalars["String"]>;
	differentiationDescriptionHasPrefix?: InputMaybe<Scalars["String"]>;
	differentiationDescriptionHasSuffix?: InputMaybe<Scalars["String"]>;
	differentiationDescriptionIn?: InputMaybe<Array<Scalars["String"]>>;
	differentiationDescriptionIsNull?: InputMaybe<Scalars["Boolean"]>;
	differentiationDescriptionNEQ?: InputMaybe<Scalars["String"]>;
	differentiationDescriptionNotContains?: InputMaybe<Scalars["String"]>;
	differentiationDescriptionNotHasPrefix?: InputMaybe<Scalars["String"]>;
	differentiationDescriptionNotHasSuffix?: InputMaybe<Scalars["String"]>;
	differentiationDescriptionNotIn?: InputMaybe<Array<Scalars["String"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	lessonDescriptionContains?: InputMaybe<Scalars["String"]>;
	lessonDescriptionEQ?: InputMaybe<Scalars["String"]>;
	lessonDescriptionHasPrefix?: InputMaybe<Scalars["String"]>;
	lessonDescriptionHasSuffix?: InputMaybe<Scalars["String"]>;
	lessonDescriptionIn?: InputMaybe<Array<Scalars["String"]>>;
	lessonDescriptionIsNull?: InputMaybe<Scalars["Boolean"]>;
	lessonDescriptionNEQ?: InputMaybe<Scalars["String"]>;
	lessonDescriptionNotContains?: InputMaybe<Scalars["String"]>;
	lessonDescriptionNotHasPrefix?: InputMaybe<Scalars["String"]>;
	lessonDescriptionNotHasSuffix?: InputMaybe<Scalars["String"]>;
	lessonDescriptionNotIn?: InputMaybe<Array<Scalars["String"]>>;
	not?: InputMaybe<LessonPlanHistoryFilterInput>;
	notesContains?: InputMaybe<Scalars["String"]>;
	notesEQ?: InputMaybe<Scalars["String"]>;
	notesHasPrefix?: InputMaybe<Scalars["String"]>;
	notesHasSuffix?: InputMaybe<Scalars["String"]>;
	notesIn?: InputMaybe<Array<Scalars["String"]>>;
	notesIsNull?: InputMaybe<Scalars["Boolean"]>;
	notesNEQ?: InputMaybe<Scalars["String"]>;
	notesNotContains?: InputMaybe<Scalars["String"]>;
	notesNotHasPrefix?: InputMaybe<Scalars["String"]>;
	notesNotHasSuffix?: InputMaybe<Scalars["String"]>;
	notesNotIn?: InputMaybe<Array<Scalars["String"]>>;
	or?: InputMaybe<Array<LessonPlanHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	requiredResourcesAndMaterialsContains?: InputMaybe<Scalars["String"]>;
	requiredResourcesAndMaterialsEQ?: InputMaybe<Scalars["String"]>;
	requiredResourcesAndMaterialsHasPrefix?: InputMaybe<Scalars["String"]>;
	requiredResourcesAndMaterialsHasSuffix?: InputMaybe<Scalars["String"]>;
	requiredResourcesAndMaterialsIn?: InputMaybe<Array<Scalars["String"]>>;
	requiredResourcesAndMaterialsIsNull?: InputMaybe<Scalars["Boolean"]>;
	requiredResourcesAndMaterialsNEQ?: InputMaybe<Scalars["String"]>;
	requiredResourcesAndMaterialsNotContains?: InputMaybe<Scalars["String"]>;
	requiredResourcesAndMaterialsNotHasPrefix?: InputMaybe<Scalars["String"]>;
	requiredResourcesAndMaterialsNotHasSuffix?: InputMaybe<Scalars["String"]>;
	requiredResourcesAndMaterialsNotIn?: InputMaybe<Array<Scalars["String"]>>;
	search?: InputMaybe<Scalars["String"]>;
	stationOneDescriptionContains?: InputMaybe<Scalars["String"]>;
	stationOneDescriptionEQ?: InputMaybe<Scalars["String"]>;
	stationOneDescriptionHasPrefix?: InputMaybe<Scalars["String"]>;
	stationOneDescriptionHasSuffix?: InputMaybe<Scalars["String"]>;
	stationOneDescriptionIn?: InputMaybe<Array<Scalars["String"]>>;
	stationOneDescriptionIsNull?: InputMaybe<Scalars["Boolean"]>;
	stationOneDescriptionNEQ?: InputMaybe<Scalars["String"]>;
	stationOneDescriptionNotContains?: InputMaybe<Scalars["String"]>;
	stationOneDescriptionNotHasPrefix?: InputMaybe<Scalars["String"]>;
	stationOneDescriptionNotHasSuffix?: InputMaybe<Scalars["String"]>;
	stationOneDescriptionNotIn?: InputMaybe<Array<Scalars["String"]>>;
	stationThreeDescriptionContains?: InputMaybe<Scalars["String"]>;
	stationThreeDescriptionEQ?: InputMaybe<Scalars["String"]>;
	stationThreeDescriptionHasPrefix?: InputMaybe<Scalars["String"]>;
	stationThreeDescriptionHasSuffix?: InputMaybe<Scalars["String"]>;
	stationThreeDescriptionIn?: InputMaybe<Array<Scalars["String"]>>;
	stationThreeDescriptionIsNull?: InputMaybe<Scalars["Boolean"]>;
	stationThreeDescriptionNEQ?: InputMaybe<Scalars["String"]>;
	stationThreeDescriptionNotContains?: InputMaybe<Scalars["String"]>;
	stationThreeDescriptionNotHasPrefix?: InputMaybe<Scalars["String"]>;
	stationThreeDescriptionNotHasSuffix?: InputMaybe<Scalars["String"]>;
	stationThreeDescriptionNotIn?: InputMaybe<Array<Scalars["String"]>>;
	stationTwoDescriptionContains?: InputMaybe<Scalars["String"]>;
	stationTwoDescriptionEQ?: InputMaybe<Scalars["String"]>;
	stationTwoDescriptionHasPrefix?: InputMaybe<Scalars["String"]>;
	stationTwoDescriptionHasSuffix?: InputMaybe<Scalars["String"]>;
	stationTwoDescriptionIn?: InputMaybe<Array<Scalars["String"]>>;
	stationTwoDescriptionIsNull?: InputMaybe<Scalars["Boolean"]>;
	stationTwoDescriptionNEQ?: InputMaybe<Scalars["String"]>;
	stationTwoDescriptionNotContains?: InputMaybe<Scalars["String"]>;
	stationTwoDescriptionNotHasPrefix?: InputMaybe<Scalars["String"]>;
	stationTwoDescriptionNotHasSuffix?: InputMaybe<Scalars["String"]>;
	stationTwoDescriptionNotIn?: InputMaybe<Array<Scalars["String"]>>;
};

export type LessonPlanHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<LessonPlanHistoryOrderField>;
};

export enum LessonPlanHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type LessonPlanInCourseSectionCreateInput = {
	assessmentDescription?: InputMaybe<Scalars["String"]>;
	courseDate: Scalars["Time"];
	differentiationDescription?: InputMaybe<Scalars["String"]>;
	learningObjectiveIDs?: InputMaybe<Array<Scalars["ID"]>>;
	lessonDescription: Scalars["String"];
	notes?: InputMaybe<Scalars["String"]>;
	requiredResourcesAndMaterials?: InputMaybe<Scalars["String"]>;
	stationOneDescription?: InputMaybe<Scalars["String"]>;
	stationThreeDescription?: InputMaybe<Scalars["String"]>;
	stationTwoDescription?: InputMaybe<Scalars["String"]>;
};

export type LessonPlanLearningObjectiveHistory = Node & {
	__typename?: "LessonPlanLearningObjectiveHistory";
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	learningObjectiveID: Maybe<Scalars["ID"]>;
	lessonPlanID: Maybe<Scalars["ID"]>;
};

export type LessonPlanLearningObjectiveHistoryConnection = {
	__typename?: "LessonPlanLearningObjectiveHistoryConnection";
	edges: Array<LessonPlanLearningObjectiveHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type LessonPlanLearningObjectiveHistoryEdge = {
	__typename?: "LessonPlanLearningObjectiveHistoryEdge";
	cursor: Scalars["Cursor"];
	node: LessonPlanLearningObjectiveHistory;
};

export type LessonPlanLearningObjectiveHistoryFilterInput = {
	and?: InputMaybe<Array<LessonPlanLearningObjectiveHistoryFilterInput>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	learningObjectiveIDEQ?: InputMaybe<Scalars["ID"]>;
	learningObjectiveIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	learningObjectiveIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	learningObjectiveIDNEQ?: InputMaybe<Scalars["ID"]>;
	learningObjectiveIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	lessonPlanIDEQ?: InputMaybe<Scalars["ID"]>;
	lessonPlanIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	lessonPlanIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	lessonPlanIDNEQ?: InputMaybe<Scalars["ID"]>;
	lessonPlanIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<LessonPlanLearningObjectiveHistoryFilterInput>;
	or?: InputMaybe<Array<LessonPlanLearningObjectiveHistoryFilterInput>>;
};

export type LessonPlanLearningObjectiveHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<LessonPlanLearningObjectiveHistoryOrderField>;
};

export enum LessonPlanLearningObjectiveHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type LessonPlanOrder = {
	direction: OrderDirection;
	field?: InputMaybe<LessonPlanOrderField>;
};

export enum LessonPlanOrderField {
	CourseDate = "COURSE_DATE",
}

export type LessonPlanUpdateBulkInput = {
	addLearningObjectiveIDs?: InputMaybe<Array<Scalars["ID"]>>;
	assessmentDescription?: InputMaybe<Scalars["String"]>;
	courseDate?: InputMaybe<Scalars["Time"]>;
	courseSectionID?: InputMaybe<Scalars["ID"]>;
	differentiationDescription?: InputMaybe<Scalars["String"]>;
	lessonDescription?: InputMaybe<Scalars["String"]>;
	notes?: InputMaybe<Scalars["String"]>;
	removeLearningObjectiveIDs?: InputMaybe<Array<Scalars["ID"]>>;
	requiredResourcesAndMaterials?: InputMaybe<Scalars["String"]>;
	stationOneDescription?: InputMaybe<Scalars["String"]>;
	stationThreeDescription?: InputMaybe<Scalars["String"]>;
	stationTwoDescription?: InputMaybe<Scalars["String"]>;
};

export type LessonPlanUpdateInput = {
	addLearningObjectiveIDs?: InputMaybe<Array<Scalars["ID"]>>;
	assessmentDescription?: InputMaybe<Scalars["String"]>;
	courseDate?: InputMaybe<Scalars["Time"]>;
	courseSectionID?: InputMaybe<Scalars["ID"]>;
	differentiationDescription?: InputMaybe<Scalars["String"]>;
	lessonDescription?: InputMaybe<Scalars["String"]>;
	notes?: InputMaybe<Scalars["String"]>;
	removeLearningObjectiveIDs?: InputMaybe<Array<Scalars["ID"]>>;
	requiredResourcesAndMaterials?: InputMaybe<Scalars["String"]>;
	stationOneDescription?: InputMaybe<Scalars["String"]>;
	stationThreeDescription?: InputMaybe<Scalars["String"]>;
	stationTwoDescription?: InputMaybe<Scalars["String"]>;
};

export type LoginRecord = Node & {
	__typename?: "LoginRecord";
	createdAt: Scalars["Time"];
	id: Scalars["ID"];
	isTrusted: Scalars["Boolean"];
	session: Maybe<Session>;
	sessionID: Maybe<Scalars["ID"]>;
	user: Maybe<User>;
	userEmail: Scalars["String"];
	userID: Maybe<Scalars["ID"]>;
	wasBlocked: Scalars["Boolean"];
	wasSuccessful: Scalars["Boolean"];
};

export type LoginRecordConnection = {
	__typename?: "LoginRecordConnection";
	edges: Array<LoginRecordEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type LoginRecordEdge = {
	__typename?: "LoginRecordEdge";
	cursor: Scalars["Cursor"];
	node: LoginRecord;
};

export type LoginRecordFilterInput = {
	and?: InputMaybe<Array<LoginRecordFilterInput>>;
	createdAtEQ?: InputMaybe<Scalars["Time"]>;
	createdAtGT?: InputMaybe<Scalars["Time"]>;
	createdAtGTE?: InputMaybe<Scalars["Time"]>;
	createdAtIn?: InputMaybe<Array<Scalars["Time"]>>;
	createdAtLT?: InputMaybe<Scalars["Time"]>;
	createdAtLTE?: InputMaybe<Scalars["Time"]>;
	createdAtNEQ?: InputMaybe<Scalars["Time"]>;
	createdAtNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	hasSession?: InputMaybe<Scalars["Boolean"]>;
	hasUser?: InputMaybe<Scalars["Boolean"]>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	isTrustedEQ?: InputMaybe<Scalars["Boolean"]>;
	isTrustedNEQ?: InputMaybe<Scalars["Boolean"]>;
	not?: InputMaybe<LoginRecordFilterInput>;
	or?: InputMaybe<Array<LoginRecordFilterInput>>;
	search?: InputMaybe<Scalars["String"]>;
	session?: InputMaybe<Array<SessionFilterInput>>;
	sessionIDEQ?: InputMaybe<Scalars["ID"]>;
	sessionIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	sessionIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	sessionIDNEQ?: InputMaybe<Scalars["ID"]>;
	sessionIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	user?: InputMaybe<Array<UserFilterInput>>;
	userEmailContains?: InputMaybe<Scalars["String"]>;
	userEmailEQ?: InputMaybe<Scalars["String"]>;
	userEmailHasPrefix?: InputMaybe<Scalars["String"]>;
	userEmailHasSuffix?: InputMaybe<Scalars["String"]>;
	userEmailIn?: InputMaybe<Array<Scalars["String"]>>;
	userEmailNEQ?: InputMaybe<Scalars["String"]>;
	userEmailNotContains?: InputMaybe<Scalars["String"]>;
	userEmailNotHasPrefix?: InputMaybe<Scalars["String"]>;
	userEmailNotHasSuffix?: InputMaybe<Scalars["String"]>;
	userEmailNotIn?: InputMaybe<Array<Scalars["String"]>>;
	userIDEQ?: InputMaybe<Scalars["ID"]>;
	userIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	userIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	userIDNEQ?: InputMaybe<Scalars["ID"]>;
	userIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	wasBlockedEQ?: InputMaybe<Scalars["Boolean"]>;
	wasBlockedNEQ?: InputMaybe<Scalars["Boolean"]>;
	wasSuccessfulEQ?: InputMaybe<Scalars["Boolean"]>;
	wasSuccessfulNEQ?: InputMaybe<Scalars["Boolean"]>;
};

export type LoginRecordOrder = {
	direction: OrderDirection;
	field?: InputMaybe<LoginRecordOrderField>;
};

export enum LoginRecordOrderField {
	CreatedAt = "CREATED_AT",
	UserEmail = "USER_EMAIL",
}

export type MFAFactor = Node & {
	__typename?: "MFAFactor";
	count: Scalars["Int"];
	createdAt: Scalars["Time"];
	email: Scalars["String"];
	id: Scalars["ID"];
	isApp: Scalars["Boolean"];
	isVerified: Scalars["Boolean"];
	sms: Scalars["String"];
	user: User;
	userID: Scalars["ID"];
};

export type MFAFactorConnection = {
	__typename?: "MFAFactorConnection";
	edges: Array<MFAFactorEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type MFAFactorEdge = {
	__typename?: "MFAFactorEdge";
	cursor: Scalars["Cursor"];
	node: MFAFactor;
};

export type MFAFactorFilterInput = {
	and?: InputMaybe<Array<MFAFactorFilterInput>>;
	countEQ?: InputMaybe<Scalars["Int"]>;
	countGT?: InputMaybe<Scalars["Int"]>;
	countGTE?: InputMaybe<Scalars["Int"]>;
	countIn?: InputMaybe<Array<Scalars["Int"]>>;
	countLT?: InputMaybe<Scalars["Int"]>;
	countLTE?: InputMaybe<Scalars["Int"]>;
	countNEQ?: InputMaybe<Scalars["Int"]>;
	countNotIn?: InputMaybe<Array<Scalars["Int"]>>;
	createdAtEQ?: InputMaybe<Scalars["Time"]>;
	createdAtGT?: InputMaybe<Scalars["Time"]>;
	createdAtGTE?: InputMaybe<Scalars["Time"]>;
	createdAtIn?: InputMaybe<Array<Scalars["Time"]>>;
	createdAtLT?: InputMaybe<Scalars["Time"]>;
	createdAtLTE?: InputMaybe<Scalars["Time"]>;
	createdAtNEQ?: InputMaybe<Scalars["Time"]>;
	createdAtNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	emailContains?: InputMaybe<Scalars["String"]>;
	emailEQ?: InputMaybe<Scalars["String"]>;
	emailHasPrefix?: InputMaybe<Scalars["String"]>;
	emailHasSuffix?: InputMaybe<Scalars["String"]>;
	emailIn?: InputMaybe<Array<Scalars["String"]>>;
	emailNEQ?: InputMaybe<Scalars["String"]>;
	emailNotContains?: InputMaybe<Scalars["String"]>;
	emailNotHasPrefix?: InputMaybe<Scalars["String"]>;
	emailNotHasSuffix?: InputMaybe<Scalars["String"]>;
	emailNotIn?: InputMaybe<Array<Scalars["String"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	isAppEQ?: InputMaybe<Scalars["Boolean"]>;
	isAppNEQ?: InputMaybe<Scalars["Boolean"]>;
	isVerifiedEQ?: InputMaybe<Scalars["Boolean"]>;
	isVerifiedNEQ?: InputMaybe<Scalars["Boolean"]>;
	not?: InputMaybe<MFAFactorFilterInput>;
	or?: InputMaybe<Array<MFAFactorFilterInput>>;
	search?: InputMaybe<Scalars["String"]>;
	smsContains?: InputMaybe<Scalars["String"]>;
	smsEQ?: InputMaybe<Scalars["String"]>;
	smsHasPrefix?: InputMaybe<Scalars["String"]>;
	smsHasSuffix?: InputMaybe<Scalars["String"]>;
	smsIn?: InputMaybe<Array<Scalars["String"]>>;
	smsNEQ?: InputMaybe<Scalars["String"]>;
	smsNotContains?: InputMaybe<Scalars["String"]>;
	smsNotHasPrefix?: InputMaybe<Scalars["String"]>;
	smsNotHasSuffix?: InputMaybe<Scalars["String"]>;
	smsNotIn?: InputMaybe<Array<Scalars["String"]>>;
	user?: InputMaybe<Array<UserFilterInput>>;
	userIDEQ?: InputMaybe<Scalars["ID"]>;
	userIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	userIDNEQ?: InputMaybe<Scalars["ID"]>;
	userIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type MFAFactorOrder = {
	direction: OrderDirection;
	field?: InputMaybe<MFAFactorOrderField>;
};

export enum MFAFactorOrderField {
	CreatedAt = "CREATED_AT",
}

export type MFAInvalidOTPAttempt = Node & {
	__typename?: "MFAInvalidOTPAttempt";
	createdAt: Scalars["Time"];
	id: Scalars["ID"];
	session: Maybe<Session>;
	sessionID: Maybe<Scalars["ID"]>;
	user: User;
	userID: Scalars["ID"];
};

export type MFAInvalidOTPAttemptConnection = {
	__typename?: "MFAInvalidOTPAttemptConnection";
	edges: Array<MFAInvalidOTPAttemptEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type MFAInvalidOTPAttemptEdge = {
	__typename?: "MFAInvalidOTPAttemptEdge";
	cursor: Scalars["Cursor"];
	node: MFAInvalidOTPAttempt;
};

export type MFAInvalidOTPAttemptFilterInput = {
	and?: InputMaybe<Array<MFAInvalidOTPAttemptFilterInput>>;
	createdAtEQ?: InputMaybe<Scalars["Time"]>;
	createdAtGT?: InputMaybe<Scalars["Time"]>;
	createdAtGTE?: InputMaybe<Scalars["Time"]>;
	createdAtIn?: InputMaybe<Array<Scalars["Time"]>>;
	createdAtLT?: InputMaybe<Scalars["Time"]>;
	createdAtLTE?: InputMaybe<Scalars["Time"]>;
	createdAtNEQ?: InputMaybe<Scalars["Time"]>;
	createdAtNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	hasSession?: InputMaybe<Scalars["Boolean"]>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<MFAInvalidOTPAttemptFilterInput>;
	or?: InputMaybe<Array<MFAInvalidOTPAttemptFilterInput>>;
	session?: InputMaybe<Array<SessionFilterInput>>;
	sessionIDEQ?: InputMaybe<Scalars["ID"]>;
	sessionIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	sessionIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	sessionIDNEQ?: InputMaybe<Scalars["ID"]>;
	sessionIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	user?: InputMaybe<Array<UserFilterInput>>;
	userIDEQ?: InputMaybe<Scalars["ID"]>;
	userIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	userIDNEQ?: InputMaybe<Scalars["ID"]>;
	userIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type MFAInvalidOTPAttemptOrder = {
	direction: OrderDirection;
	field?: InputMaybe<MFAInvalidOTPAttemptOrderField>;
};

export enum MFAInvalidOTPAttemptOrderField {
	CreatedAt = "CREATED_AT",
}

export type MealMenu = Node & {
	__typename?: "MealMenu";
	breakfast: Scalars["String"];
	id: Scalars["ID"];
	lunch: Scalars["String"];
	menuDate: Scalars["Time"];
	snack: Scalars["String"];
	vegetarianBreakfast: Scalars["String"];
	vegetarianLunch: Scalars["String"];
	vegetarianSnack: Scalars["String"];
};

export type MealMenuConnection = {
	__typename?: "MealMenuConnection";
	edges: Array<MealMenuEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type MealMenuCreateInput = {
	breakfast: Scalars["String"];
	lunch: Scalars["String"];
	menuDate: Scalars["Time"];
	snack: Scalars["String"];
	vegetarianBreakfast?: InputMaybe<Scalars["String"]>;
	vegetarianLunch?: InputMaybe<Scalars["String"]>;
	vegetarianSnack?: InputMaybe<Scalars["String"]>;
};

export type MealMenuEdge = {
	__typename?: "MealMenuEdge";
	cursor: Scalars["Cursor"];
	node: MealMenu;
};

export type MealMenuFilterInput = {
	and?: InputMaybe<Array<MealMenuFilterInput>>;
	breakfastContains?: InputMaybe<Scalars["String"]>;
	breakfastEQ?: InputMaybe<Scalars["String"]>;
	breakfastHasPrefix?: InputMaybe<Scalars["String"]>;
	breakfastHasSuffix?: InputMaybe<Scalars["String"]>;
	breakfastIn?: InputMaybe<Array<Scalars["String"]>>;
	breakfastNEQ?: InputMaybe<Scalars["String"]>;
	breakfastNotContains?: InputMaybe<Scalars["String"]>;
	breakfastNotHasPrefix?: InputMaybe<Scalars["String"]>;
	breakfastNotHasSuffix?: InputMaybe<Scalars["String"]>;
	breakfastNotIn?: InputMaybe<Array<Scalars["String"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	lunchContains?: InputMaybe<Scalars["String"]>;
	lunchEQ?: InputMaybe<Scalars["String"]>;
	lunchHasPrefix?: InputMaybe<Scalars["String"]>;
	lunchHasSuffix?: InputMaybe<Scalars["String"]>;
	lunchIn?: InputMaybe<Array<Scalars["String"]>>;
	lunchNEQ?: InputMaybe<Scalars["String"]>;
	lunchNotContains?: InputMaybe<Scalars["String"]>;
	lunchNotHasPrefix?: InputMaybe<Scalars["String"]>;
	lunchNotHasSuffix?: InputMaybe<Scalars["String"]>;
	lunchNotIn?: InputMaybe<Array<Scalars["String"]>>;
	menuDateEQ?: InputMaybe<Scalars["Time"]>;
	menuDateGT?: InputMaybe<Scalars["Time"]>;
	menuDateGTE?: InputMaybe<Scalars["Time"]>;
	menuDateIn?: InputMaybe<Array<Scalars["Time"]>>;
	menuDateLT?: InputMaybe<Scalars["Time"]>;
	menuDateLTE?: InputMaybe<Scalars["Time"]>;
	menuDateNEQ?: InputMaybe<Scalars["Time"]>;
	menuDateNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	not?: InputMaybe<MealMenuFilterInput>;
	or?: InputMaybe<Array<MealMenuFilterInput>>;
	search?: InputMaybe<Scalars["String"]>;
	snackContains?: InputMaybe<Scalars["String"]>;
	snackEQ?: InputMaybe<Scalars["String"]>;
	snackHasPrefix?: InputMaybe<Scalars["String"]>;
	snackHasSuffix?: InputMaybe<Scalars["String"]>;
	snackIn?: InputMaybe<Array<Scalars["String"]>>;
	snackNEQ?: InputMaybe<Scalars["String"]>;
	snackNotContains?: InputMaybe<Scalars["String"]>;
	snackNotHasPrefix?: InputMaybe<Scalars["String"]>;
	snackNotHasSuffix?: InputMaybe<Scalars["String"]>;
	snackNotIn?: InputMaybe<Array<Scalars["String"]>>;
	vegetarianBreakfastContains?: InputMaybe<Scalars["String"]>;
	vegetarianBreakfastEQ?: InputMaybe<Scalars["String"]>;
	vegetarianBreakfastHasPrefix?: InputMaybe<Scalars["String"]>;
	vegetarianBreakfastHasSuffix?: InputMaybe<Scalars["String"]>;
	vegetarianBreakfastIn?: InputMaybe<Array<Scalars["String"]>>;
	vegetarianBreakfastNEQ?: InputMaybe<Scalars["String"]>;
	vegetarianBreakfastNotContains?: InputMaybe<Scalars["String"]>;
	vegetarianBreakfastNotHasPrefix?: InputMaybe<Scalars["String"]>;
	vegetarianBreakfastNotHasSuffix?: InputMaybe<Scalars["String"]>;
	vegetarianBreakfastNotIn?: InputMaybe<Array<Scalars["String"]>>;
	vegetarianLunchContains?: InputMaybe<Scalars["String"]>;
	vegetarianLunchEQ?: InputMaybe<Scalars["String"]>;
	vegetarianLunchHasPrefix?: InputMaybe<Scalars["String"]>;
	vegetarianLunchHasSuffix?: InputMaybe<Scalars["String"]>;
	vegetarianLunchIn?: InputMaybe<Array<Scalars["String"]>>;
	vegetarianLunchNEQ?: InputMaybe<Scalars["String"]>;
	vegetarianLunchNotContains?: InputMaybe<Scalars["String"]>;
	vegetarianLunchNotHasPrefix?: InputMaybe<Scalars["String"]>;
	vegetarianLunchNotHasSuffix?: InputMaybe<Scalars["String"]>;
	vegetarianLunchNotIn?: InputMaybe<Array<Scalars["String"]>>;
	vegetarianSnackContains?: InputMaybe<Scalars["String"]>;
	vegetarianSnackEQ?: InputMaybe<Scalars["String"]>;
	vegetarianSnackHasPrefix?: InputMaybe<Scalars["String"]>;
	vegetarianSnackHasSuffix?: InputMaybe<Scalars["String"]>;
	vegetarianSnackIn?: InputMaybe<Array<Scalars["String"]>>;
	vegetarianSnackNEQ?: InputMaybe<Scalars["String"]>;
	vegetarianSnackNotContains?: InputMaybe<Scalars["String"]>;
	vegetarianSnackNotHasPrefix?: InputMaybe<Scalars["String"]>;
	vegetarianSnackNotHasSuffix?: InputMaybe<Scalars["String"]>;
	vegetarianSnackNotIn?: InputMaybe<Array<Scalars["String"]>>;
};

export type MealMenuHistory = Node & {
	__typename?: "MealMenuHistory";
	breakfast: Maybe<Scalars["String"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	lunch: Maybe<Scalars["String"]>;
	menuDate: Maybe<Scalars["Time"]>;
	originalID: Maybe<Scalars["ID"]>;
	snack: Maybe<Scalars["String"]>;
	vegetarianBreakfast: Maybe<Scalars["String"]>;
	vegetarianLunch: Maybe<Scalars["String"]>;
	vegetarianSnack: Maybe<Scalars["String"]>;
};

export type MealMenuHistoryConnection = {
	__typename?: "MealMenuHistoryConnection";
	edges: Array<MealMenuHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type MealMenuHistoryEdge = {
	__typename?: "MealMenuHistoryEdge";
	cursor: Scalars["Cursor"];
	node: MealMenuHistory;
};

export type MealMenuHistoryFilterInput = {
	and?: InputMaybe<Array<MealMenuHistoryFilterInput>>;
	breakfastContains?: InputMaybe<Scalars["String"]>;
	breakfastEQ?: InputMaybe<Scalars["String"]>;
	breakfastHasPrefix?: InputMaybe<Scalars["String"]>;
	breakfastHasSuffix?: InputMaybe<Scalars["String"]>;
	breakfastIn?: InputMaybe<Array<Scalars["String"]>>;
	breakfastIsNull?: InputMaybe<Scalars["Boolean"]>;
	breakfastNEQ?: InputMaybe<Scalars["String"]>;
	breakfastNotContains?: InputMaybe<Scalars["String"]>;
	breakfastNotHasPrefix?: InputMaybe<Scalars["String"]>;
	breakfastNotHasSuffix?: InputMaybe<Scalars["String"]>;
	breakfastNotIn?: InputMaybe<Array<Scalars["String"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	lunchContains?: InputMaybe<Scalars["String"]>;
	lunchEQ?: InputMaybe<Scalars["String"]>;
	lunchHasPrefix?: InputMaybe<Scalars["String"]>;
	lunchHasSuffix?: InputMaybe<Scalars["String"]>;
	lunchIn?: InputMaybe<Array<Scalars["String"]>>;
	lunchIsNull?: InputMaybe<Scalars["Boolean"]>;
	lunchNEQ?: InputMaybe<Scalars["String"]>;
	lunchNotContains?: InputMaybe<Scalars["String"]>;
	lunchNotHasPrefix?: InputMaybe<Scalars["String"]>;
	lunchNotHasSuffix?: InputMaybe<Scalars["String"]>;
	lunchNotIn?: InputMaybe<Array<Scalars["String"]>>;
	menuDateEQ?: InputMaybe<Scalars["Time"]>;
	menuDateGT?: InputMaybe<Scalars["Time"]>;
	menuDateGTE?: InputMaybe<Scalars["Time"]>;
	menuDateIn?: InputMaybe<Array<Scalars["Time"]>>;
	menuDateIsNull?: InputMaybe<Scalars["Boolean"]>;
	menuDateLT?: InputMaybe<Scalars["Time"]>;
	menuDateLTE?: InputMaybe<Scalars["Time"]>;
	menuDateNEQ?: InputMaybe<Scalars["Time"]>;
	menuDateNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	not?: InputMaybe<MealMenuHistoryFilterInput>;
	or?: InputMaybe<Array<MealMenuHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	search?: InputMaybe<Scalars["String"]>;
	snackContains?: InputMaybe<Scalars["String"]>;
	snackEQ?: InputMaybe<Scalars["String"]>;
	snackHasPrefix?: InputMaybe<Scalars["String"]>;
	snackHasSuffix?: InputMaybe<Scalars["String"]>;
	snackIn?: InputMaybe<Array<Scalars["String"]>>;
	snackIsNull?: InputMaybe<Scalars["Boolean"]>;
	snackNEQ?: InputMaybe<Scalars["String"]>;
	snackNotContains?: InputMaybe<Scalars["String"]>;
	snackNotHasPrefix?: InputMaybe<Scalars["String"]>;
	snackNotHasSuffix?: InputMaybe<Scalars["String"]>;
	snackNotIn?: InputMaybe<Array<Scalars["String"]>>;
	vegetarianBreakfastContains?: InputMaybe<Scalars["String"]>;
	vegetarianBreakfastEQ?: InputMaybe<Scalars["String"]>;
	vegetarianBreakfastHasPrefix?: InputMaybe<Scalars["String"]>;
	vegetarianBreakfastHasSuffix?: InputMaybe<Scalars["String"]>;
	vegetarianBreakfastIn?: InputMaybe<Array<Scalars["String"]>>;
	vegetarianBreakfastIsNull?: InputMaybe<Scalars["Boolean"]>;
	vegetarianBreakfastNEQ?: InputMaybe<Scalars["String"]>;
	vegetarianBreakfastNotContains?: InputMaybe<Scalars["String"]>;
	vegetarianBreakfastNotHasPrefix?: InputMaybe<Scalars["String"]>;
	vegetarianBreakfastNotHasSuffix?: InputMaybe<Scalars["String"]>;
	vegetarianBreakfastNotIn?: InputMaybe<Array<Scalars["String"]>>;
	vegetarianLunchContains?: InputMaybe<Scalars["String"]>;
	vegetarianLunchEQ?: InputMaybe<Scalars["String"]>;
	vegetarianLunchHasPrefix?: InputMaybe<Scalars["String"]>;
	vegetarianLunchHasSuffix?: InputMaybe<Scalars["String"]>;
	vegetarianLunchIn?: InputMaybe<Array<Scalars["String"]>>;
	vegetarianLunchIsNull?: InputMaybe<Scalars["Boolean"]>;
	vegetarianLunchNEQ?: InputMaybe<Scalars["String"]>;
	vegetarianLunchNotContains?: InputMaybe<Scalars["String"]>;
	vegetarianLunchNotHasPrefix?: InputMaybe<Scalars["String"]>;
	vegetarianLunchNotHasSuffix?: InputMaybe<Scalars["String"]>;
	vegetarianLunchNotIn?: InputMaybe<Array<Scalars["String"]>>;
	vegetarianSnackContains?: InputMaybe<Scalars["String"]>;
	vegetarianSnackEQ?: InputMaybe<Scalars["String"]>;
	vegetarianSnackHasPrefix?: InputMaybe<Scalars["String"]>;
	vegetarianSnackHasSuffix?: InputMaybe<Scalars["String"]>;
	vegetarianSnackIn?: InputMaybe<Array<Scalars["String"]>>;
	vegetarianSnackIsNull?: InputMaybe<Scalars["Boolean"]>;
	vegetarianSnackNEQ?: InputMaybe<Scalars["String"]>;
	vegetarianSnackNotContains?: InputMaybe<Scalars["String"]>;
	vegetarianSnackNotHasPrefix?: InputMaybe<Scalars["String"]>;
	vegetarianSnackNotHasSuffix?: InputMaybe<Scalars["String"]>;
	vegetarianSnackNotIn?: InputMaybe<Array<Scalars["String"]>>;
};

export type MealMenuHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<MealMenuHistoryOrderField>;
};

export enum MealMenuHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type MealMenuOrder = {
	direction: OrderDirection;
	field?: InputMaybe<MealMenuOrderField>;
};

export enum MealMenuOrderField {
	MenuDate = "MENU_DATE",
}

export type MealMenuUpdateBulkInput = {
	breakfast?: InputMaybe<Scalars["String"]>;
	lunch?: InputMaybe<Scalars["String"]>;
	menuDate?: InputMaybe<Scalars["Time"]>;
	snack?: InputMaybe<Scalars["String"]>;
	vegetarianBreakfast?: InputMaybe<Scalars["String"]>;
	vegetarianLunch?: InputMaybe<Scalars["String"]>;
	vegetarianSnack?: InputMaybe<Scalars["String"]>;
};

export type MealMenuUpdateInput = {
	breakfast?: InputMaybe<Scalars["String"]>;
	lunch?: InputMaybe<Scalars["String"]>;
	menuDate?: InputMaybe<Scalars["Time"]>;
	snack?: InputMaybe<Scalars["String"]>;
	vegetarianBreakfast?: InputMaybe<Scalars["String"]>;
	vegetarianLunch?: InputMaybe<Scalars["String"]>;
	vegetarianSnack?: InputMaybe<Scalars["String"]>;
};

export type MealPlan = Node & {
	__typename?: "MealPlan";
	id: Scalars["ID"];
	notes: Scalars["String"];
	student: Student;
	studentID: Scalars["ID"];
};

export type MealPlanConnection = {
	__typename?: "MealPlanConnection";
	edges: Array<MealPlanEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type MealPlanCreateInput = {
	notes?: InputMaybe<Scalars["String"]>;
	studentID: Scalars["ID"];
};

export type MealPlanEdge = {
	__typename?: "MealPlanEdge";
	cursor: Scalars["Cursor"];
	node: MealPlan;
};

export type MealPlanFilterInput = {
	and?: InputMaybe<Array<MealPlanFilterInput>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<MealPlanFilterInput>;
	notesContains?: InputMaybe<Scalars["String"]>;
	notesEQ?: InputMaybe<Scalars["String"]>;
	notesHasPrefix?: InputMaybe<Scalars["String"]>;
	notesHasSuffix?: InputMaybe<Scalars["String"]>;
	notesIn?: InputMaybe<Array<Scalars["String"]>>;
	notesNEQ?: InputMaybe<Scalars["String"]>;
	notesNotContains?: InputMaybe<Scalars["String"]>;
	notesNotHasPrefix?: InputMaybe<Scalars["String"]>;
	notesNotHasSuffix?: InputMaybe<Scalars["String"]>;
	notesNotIn?: InputMaybe<Array<Scalars["String"]>>;
	or?: InputMaybe<Array<MealPlanFilterInput>>;
	search?: InputMaybe<Scalars["String"]>;
	student?: InputMaybe<Array<StudentFilterInput>>;
	studentIDEQ?: InputMaybe<Scalars["ID"]>;
	studentIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	studentIDNEQ?: InputMaybe<Scalars["ID"]>;
	studentIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type MealPlanHistory = Node & {
	__typename?: "MealPlanHistory";
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	notes: Maybe<Scalars["String"]>;
	originalID: Maybe<Scalars["ID"]>;
	studentID: Maybe<Scalars["ID"]>;
};

export type MealPlanHistoryConnection = {
	__typename?: "MealPlanHistoryConnection";
	edges: Array<MealPlanHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type MealPlanHistoryEdge = {
	__typename?: "MealPlanHistoryEdge";
	cursor: Scalars["Cursor"];
	node: MealPlanHistory;
};

export type MealPlanHistoryFilterInput = {
	and?: InputMaybe<Array<MealPlanHistoryFilterInput>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<MealPlanHistoryFilterInput>;
	notesContains?: InputMaybe<Scalars["String"]>;
	notesEQ?: InputMaybe<Scalars["String"]>;
	notesHasPrefix?: InputMaybe<Scalars["String"]>;
	notesHasSuffix?: InputMaybe<Scalars["String"]>;
	notesIn?: InputMaybe<Array<Scalars["String"]>>;
	notesIsNull?: InputMaybe<Scalars["Boolean"]>;
	notesNEQ?: InputMaybe<Scalars["String"]>;
	notesNotContains?: InputMaybe<Scalars["String"]>;
	notesNotHasPrefix?: InputMaybe<Scalars["String"]>;
	notesNotHasSuffix?: InputMaybe<Scalars["String"]>;
	notesNotIn?: InputMaybe<Array<Scalars["String"]>>;
	or?: InputMaybe<Array<MealPlanHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	search?: InputMaybe<Scalars["String"]>;
	studentIDEQ?: InputMaybe<Scalars["ID"]>;
	studentIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	studentIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	studentIDNEQ?: InputMaybe<Scalars["ID"]>;
	studentIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type MealPlanHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<MealPlanHistoryOrderField>;
};

export enum MealPlanHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type MealPlanInStudentCreateInput = {
	notes?: InputMaybe<Scalars["String"]>;
};

export type MealPlanOrder = {
	direction: OrderDirection;
};

export type MealPlanUpdateBulkInput = {
	notes?: InputMaybe<Scalars["String"]>;
	studentID?: InputMaybe<Scalars["ID"]>;
};

export type MealPlanUpdateInput = {
	notes?: InputMaybe<Scalars["String"]>;
	studentID?: InputMaybe<Scalars["ID"]>;
};

export type ModelIdInformation = {
	__typename?: "ModelIDInformation";
	maxID: Scalars["ID"];
	minID: Scalars["ID"];
	modelName: Scalars["String"];
};

export type Mutation = {
	__typename?: "Mutation";
	createAcademicYear: AcademicYear;
	createAcademicYearTuition: AcademicYearTuition;
	createAddress: Address;
	createBillingSetupSubmission: BillingSetupSubmission;
	createBulkAcademicYearTuitions: AcademicYearTuitionConnection;
	createBulkAcademicYears: AcademicYearConnection;
	createBulkAddresses: AddressConnection;
	createBulkBillingSetupSubmissions: BillingSetupSubmissionConnection;
	createBulkBusPlans: BusPlanConnection;
	createBulkBuses: BusConnection;
	createBulkCalendarEvents: CalendarEventConnection;
	createBulkCampuses: CampusConnection;
	createBulkClassYears: ClassYearConnection;
	createBulkContractInstallments: ContractInstallmentConnection;
	createBulkContracts: ContractConnection;
	createBulkCourseAttendanceRecords: CourseAttendanceRecordConnection;
	createBulkCourseSectionPeriods: CourseSectionPeriodConnection;
	createBulkCourseSectionStudentEnrollments: CourseSectionStudentEnrollmentConnection;
	createBulkCourseSectionTeacherEnrollments: CourseSectionTeacherEnrollmentConnection;
	createBulkCourseSections: CourseSectionConnection;
	createBulkCourses: CourseConnection;
	createBulkDiscounts: DiscountConnection;
	createBulkDocumentResourceFolders: DocumentResourceFolderConnection;
	createBulkDocumentResourceUserViewRecords: DocumentResourceUserViewRecordConnection;
	createBulkDocumentResources: DocumentResourceConnection;
	createBulkEmailAddresses: EmailAddressConnection;
	createBulkEmployees: EmployeeConnection;
	createBulkEnrollmentApplicationNotes: EnrollmentApplicationNoteConnection;
	createBulkEnrollmentApplicationParents: EnrollmentApplicationParentConnection;
	createBulkEnrollmentApplicationStudents: EnrollmentApplicationStudentConnection;
	createBulkEnrollmentInvitations: EnrollmentInvitationConnection;
	createBulkExpenseBudgets: ExpenseBudgetConnection;
	createBulkExpenseCategories: ExpenseCategoryConnection;
	createBulkExpenses: ExpenseConnection;
	createBulkHomeRoomSectionStudentEnrollments: HomeRoomSectionStudentEnrollmentConnection;
	createBulkHomeRoomSectionTeacherEnrollments: HomeRoomSectionTeacherEnrollmentConnection;
	createBulkHomeRoomSections: HomeRoomSectionConnection;
	createBulkHomeRooms: HomeRoomConnection;
	createBulkInvoiceLineItems: InvoiceLineItemConnection;
	createBulkInvoices: InvoiceConnection;
	createBulkLearningObjectiveMarks: LearningObjectiveMarkConnection;
	createBulkLearningObjectives: LearningObjectiveConnection;
	createBulkLessonPlans: LessonPlanConnection;
	createBulkMealMenus: MealMenuConnection;
	createBulkMealPlans: MealPlanConnection;
	createBulkNoteAttachments: NoteAttachmentConnection;
	createBulkNoteComments: NoteCommentConnection;
	createBulkNoteReactions: NoteReactionConnection;
	createBulkNotes: NoteConnection;
	createBulkParents: ParentConnection;
	createBulkPaymentLineItems: PaymentLineItemConnection;
	createBulkPayments: PaymentConnection;
	createBulkPeople: PersonConnection;
	createBulkPermissionGroups: PermissionGroupConnection;
	createBulkPhoneNumbers: PhoneNumberConnection;
	createBulkQuotations: QuotationConnection;
	createBulkRelationships: RelationshipConnection;
	createBulkReportCards: ReportCardConnection;
	createBulkReportCardsForTerm: Scalars["Boolean"];
	createBulkRooms: RoomConnection;
	createBulkSchoolAttendanceRecords: SchoolAttendanceRecordConnection;
	createBulkSchoolStudentEnrollments: SchoolStudentEnrollmentConnection;
	createBulkServiceSubscriptions: ServiceSubscriptionConnection;
	createBulkServices: ServiceConnection;
	createBulkStudentDocuments: StudentDocumentConnection;
	createBulkStudents: StudentConnection;
	createBulkTerms: TermConnection;
	createBulkTuitions: TuitionConnection;
	createBulkUserGroups: UserGroupConnection;
	createBulkUsers: UserConnection;
	createBus: Bus;
	createBusPlan: BusPlan;
	createCalendarEvent: CalendarEvent;
	createCampus: Campus;
	createClassYear: ClassYear;
	createContract: Contract;
	createContractInstallment: ContractInstallment;
	createCourse: Course;
	createCourseAttendanceRecord: CourseAttendanceRecord;
	createCourseSection: CourseSection;
	createCourseSectionPeriod: CourseSectionPeriod;
	createCourseSectionStudentEnrollment: CourseSectionStudentEnrollment;
	createCourseSectionTeacherEnrollment: CourseSectionTeacherEnrollment;
	createDiscount: Discount;
	createDocumentResource: DocumentResource;
	createDocumentResourceFolder: DocumentResourceFolder;
	createDocumentResourceUserViewRecord: DocumentResourceUserViewRecord;
	createEmailAddress: EmailAddress;
	createEmployee: Employee;
	createEnrollmentApplicationNote: EnrollmentApplicationNote;
	createEnrollmentApplicationParent: EnrollmentApplicationParent;
	createEnrollmentApplicationStudent: EnrollmentApplicationStudent;
	createEnrollmentInvitation: EnrollmentInvitation;
	createExpense: Expense;
	createExpenseBudget: ExpenseBudget;
	createExpenseCategory: ExpenseCategory;
	createHomeRoom: HomeRoom;
	createHomeRoomSection: HomeRoomSection;
	createHomeRoomSectionStudentEnrollment: HomeRoomSectionStudentEnrollment;
	createHomeRoomSectionTeacherEnrollment: HomeRoomSectionTeacherEnrollment;
	createInvoice: Invoice;
	createInvoiceLineItem: InvoiceLineItem;
	createLearningObjective: LearningObjective;
	createLearningObjectiveMark: LearningObjectiveMark;
	createLessonPlan: LessonPlan;
	createMealMenu: MealMenu;
	createMealPlan: MealPlan;
	createNote: Note;
	createNoteAttachment: NoteAttachment;
	createNoteComment: NoteComment;
	createNoteReaction: NoteReaction;
	createParent: Parent;
	createPayment: Payment;
	createPaymentLineItem: PaymentLineItem;
	createPermissionGroup: PermissionGroup;
	createPerson: Person;
	createPhoneNumber: PhoneNumber;
	createQuotation: Quotation;
	createRelationship: Relationship;
	createReportCard: ReportCard;
	createRoom: Room;
	createSchoolAttendanceRecord: SchoolAttendanceRecord;
	createSchoolStudentEnrollment: SchoolStudentEnrollment;
	createService: Service;
	createServiceSubscription: ServiceSubscription;
	createStudent: Student;
	createStudentDocument: StudentDocument;
	createTerm: Term;
	createTuition: Tuition;
	createUser: User;
	createUserGroup: UserGroup;
	deleteAcademicYear: Scalars["Boolean"];
	deleteAcademicYearTuition: Scalars["Boolean"];
	deleteAddress: Scalars["Boolean"];
	deleteBillingSetupSubmission: Scalars["Boolean"];
	deleteBulkAcademicYearTuitions: DeleteBulkResponse;
	deleteBulkAcademicYears: DeleteBulkResponse;
	deleteBulkAddresses: DeleteBulkResponse;
	deleteBulkBillingSetupSubmissions: DeleteBulkResponse;
	deleteBulkBusPlans: DeleteBulkResponse;
	deleteBulkBuses: DeleteBulkResponse;
	deleteBulkCalendarEvents: DeleteBulkResponse;
	deleteBulkCampuses: DeleteBulkResponse;
	deleteBulkClassYears: DeleteBulkResponse;
	deleteBulkContractInstallments: DeleteBulkResponse;
	deleteBulkContracts: DeleteBulkResponse;
	deleteBulkCourseAttendanceRecords: DeleteBulkResponse;
	deleteBulkCourseSectionPeriods: DeleteBulkResponse;
	deleteBulkCourseSectionStudentEnrollments: DeleteBulkResponse;
	deleteBulkCourseSectionTeacherEnrollments: DeleteBulkResponse;
	deleteBulkCourseSections: DeleteBulkResponse;
	deleteBulkCourses: DeleteBulkResponse;
	deleteBulkDiscounts: DeleteBulkResponse;
	deleteBulkDocumentResourceFolders: DeleteBulkResponse;
	deleteBulkDocumentResourceUserViewRecords: DeleteBulkResponse;
	deleteBulkDocumentResources: DeleteBulkResponse;
	deleteBulkEmailAddresses: DeleteBulkResponse;
	deleteBulkEmployees: DeleteBulkResponse;
	deleteBulkEnrollmentApplicationNotes: DeleteBulkResponse;
	deleteBulkEnrollmentApplicationParents: DeleteBulkResponse;
	deleteBulkEnrollmentApplicationStudents: DeleteBulkResponse;
	deleteBulkEnrollmentInvitations: DeleteBulkResponse;
	deleteBulkExpenseBudgets: DeleteBulkResponse;
	deleteBulkExpenseCategories: DeleteBulkResponse;
	deleteBulkExpenses: DeleteBulkResponse;
	deleteBulkHomeRoomSectionStudentEnrollments: DeleteBulkResponse;
	deleteBulkHomeRoomSectionTeacherEnrollments: DeleteBulkResponse;
	deleteBulkHomeRoomSections: DeleteBulkResponse;
	deleteBulkHomeRooms: DeleteBulkResponse;
	deleteBulkInvoiceLineItems: DeleteBulkResponse;
	deleteBulkInvoices: DeleteBulkResponse;
	deleteBulkLearningObjectiveMarks: DeleteBulkResponse;
	deleteBulkLearningObjectives: DeleteBulkResponse;
	deleteBulkLessonPlans: DeleteBulkResponse;
	deleteBulkMealMenus: DeleteBulkResponse;
	deleteBulkMealPlans: DeleteBulkResponse;
	deleteBulkNoteAttachments: DeleteBulkResponse;
	deleteBulkNoteComments: DeleteBulkResponse;
	deleteBulkNoteReactions: DeleteBulkResponse;
	deleteBulkNotes: DeleteBulkResponse;
	deleteBulkParents: DeleteBulkResponse;
	deleteBulkPaymentLineItems: DeleteBulkResponse;
	deleteBulkPayments: DeleteBulkResponse;
	deleteBulkPeople: DeleteBulkResponse;
	deleteBulkPermissionGroups: DeleteBulkResponse;
	deleteBulkPhoneNumbers: DeleteBulkResponse;
	deleteBulkQuotations: DeleteBulkResponse;
	deleteBulkRelationships: DeleteBulkResponse;
	deleteBulkReportCards: DeleteBulkResponse;
	deleteBulkRooms: DeleteBulkResponse;
	deleteBulkSchoolAttendanceRecords: DeleteBulkResponse;
	deleteBulkSchoolStudentEnrollments: DeleteBulkResponse;
	deleteBulkServiceSubscriptions: DeleteBulkResponse;
	deleteBulkServices: DeleteBulkResponse;
	deleteBulkStudentDocuments: DeleteBulkResponse;
	deleteBulkStudents: DeleteBulkResponse;
	deleteBulkTerms: DeleteBulkResponse;
	deleteBulkTuitions: DeleteBulkResponse;
	deleteBulkUserGroups: DeleteBulkResponse;
	deleteBulkUsers: DeleteBulkResponse;
	deleteBus: Scalars["Boolean"];
	deleteBusPlan: Scalars["Boolean"];
	deleteCalendarEvent: Scalars["Boolean"];
	deleteCampus: Scalars["Boolean"];
	deleteClassYear: Scalars["Boolean"];
	deleteContract: Scalars["Boolean"];
	deleteContractInstallment: Scalars["Boolean"];
	deleteCourse: Scalars["Boolean"];
	deleteCourseAttendanceRecord: Scalars["Boolean"];
	deleteCourseSection: Scalars["Boolean"];
	deleteCourseSectionPeriod: Scalars["Boolean"];
	deleteCourseSectionStudentEnrollment: Scalars["Boolean"];
	deleteCourseSectionTeacherEnrollment: Scalars["Boolean"];
	deleteDiscount: Scalars["Boolean"];
	deleteDocumentResource: Scalars["Boolean"];
	deleteDocumentResourceFolder: Scalars["Boolean"];
	deleteDocumentResourceUserViewRecord: Scalars["Boolean"];
	deleteEmailAddress: Scalars["Boolean"];
	deleteEmployee: Scalars["Boolean"];
	deleteEnrollmentApplicationNote: Scalars["Boolean"];
	deleteEnrollmentApplicationParent: Scalars["Boolean"];
	deleteEnrollmentApplicationStudent: Scalars["Boolean"];
	deleteEnrollmentInvitation: Scalars["Boolean"];
	deleteExpense: Scalars["Boolean"];
	deleteExpenseBudget: Scalars["Boolean"];
	deleteExpenseCategory: Scalars["Boolean"];
	deleteHomeRoom: Scalars["Boolean"];
	deleteHomeRoomSection: Scalars["Boolean"];
	deleteHomeRoomSectionStudentEnrollment: Scalars["Boolean"];
	deleteHomeRoomSectionTeacherEnrollment: Scalars["Boolean"];
	deleteInvoice: Scalars["Boolean"];
	deleteInvoiceLineItem: Scalars["Boolean"];
	deleteLearningObjective: Scalars["Boolean"];
	deleteLearningObjectiveMark: Scalars["Boolean"];
	deleteLessonPlan: Scalars["Boolean"];
	deleteMealMenu: Scalars["Boolean"];
	deleteMealPlan: Scalars["Boolean"];
	deleteNote: Scalars["Boolean"];
	deleteNoteAttachment: Scalars["Boolean"];
	deleteNoteComment: Scalars["Boolean"];
	deleteNoteReaction: Scalars["Boolean"];
	deleteParent: Scalars["Boolean"];
	deletePayment: Scalars["Boolean"];
	deletePaymentLineItem: Scalars["Boolean"];
	deletePermissionGroup: Scalars["Boolean"];
	deletePerson: Scalars["Boolean"];
	deletePhoneNumber: Scalars["Boolean"];
	deleteQuotation: Scalars["Boolean"];
	deleteRelationship: Scalars["Boolean"];
	deleteReportCard: Scalars["Boolean"];
	deleteRoom: Scalars["Boolean"];
	deleteSchoolAttendanceRecord: Scalars["Boolean"];
	deleteSchoolStudentEnrollment: Scalars["Boolean"];
	deleteService: Scalars["Boolean"];
	deleteServiceSubscription: Scalars["Boolean"];
	deleteStudent: Scalars["Boolean"];
	deleteStudentDocument: Scalars["Boolean"];
	deleteTerm: Scalars["Boolean"];
	deleteTuition: Scalars["Boolean"];
	deleteUser: Scalars["Boolean"];
	deleteUserGroup: Scalars["Boolean"];
	recordEnrollmentApplicationListView: Scalars["Boolean"];
	root: Scalars["Boolean"];
	sendEnrollmentApplicationApprovedEmail: Scalars["Boolean"];
	sendEnrollmentApplicationInviteEmail: Scalars["Boolean"];
	sendReenrollmentInviteEmails: Scalars["Int"];
	updateAcademicYear: AcademicYear;
	updateAcademicYearTuition: AcademicYearTuition;
	updateAddress: Address;
	updateBillingSetupSubmission: BillingSetupSubmission;
	updateBulkAcademicYearTuitions: AcademicYearTuitionConnection;
	updateBulkAcademicYears: AcademicYearConnection;
	updateBulkAddresses: AddressConnection;
	updateBulkBillingSetupSubmissions: BillingSetupSubmissionConnection;
	updateBulkBusPlans: BusPlanConnection;
	updateBulkBuses: BusConnection;
	updateBulkCalendarEvents: CalendarEventConnection;
	updateBulkCampuses: CampusConnection;
	updateBulkClassYears: ClassYearConnection;
	updateBulkContractInstallments: ContractInstallmentConnection;
	updateBulkContracts: ContractConnection;
	updateBulkCourseAttendanceRecords: CourseAttendanceRecordConnection;
	updateBulkCourseSectionPeriods: CourseSectionPeriodConnection;
	updateBulkCourseSectionStudentEnrollments: CourseSectionStudentEnrollmentConnection;
	updateBulkCourseSectionTeacherEnrollments: CourseSectionTeacherEnrollmentConnection;
	updateBulkCourseSections: CourseSectionConnection;
	updateBulkCourses: CourseConnection;
	updateBulkDiscounts: DiscountConnection;
	updateBulkDocumentResourceFolders: DocumentResourceFolderConnection;
	updateBulkDocumentResources: DocumentResourceConnection;
	updateBulkEmailAddresses: EmailAddressConnection;
	updateBulkEmployees: EmployeeConnection;
	updateBulkEnrollmentApplicationNotes: EnrollmentApplicationNoteConnection;
	updateBulkEnrollmentApplicationParents: EnrollmentApplicationParentConnection;
	updateBulkEnrollmentApplicationStudents: EnrollmentApplicationStudentConnection;
	updateBulkEnrollmentApplicationSubmissions: EnrollmentApplicationSubmissionConnection;
	updateBulkEnrollmentApplications: EnrollmentApplicationConnection;
	updateBulkEnrollmentInvitations: EnrollmentInvitationConnection;
	updateBulkExpenseBudgets: ExpenseBudgetConnection;
	updateBulkExpenseCategories: ExpenseCategoryConnection;
	updateBulkExpenses: ExpenseConnection;
	updateBulkHomeRoomSectionStudentEnrollments: HomeRoomSectionStudentEnrollmentConnection;
	updateBulkHomeRoomSectionTeacherEnrollments: HomeRoomSectionTeacherEnrollmentConnection;
	updateBulkHomeRoomSections: HomeRoomSectionConnection;
	updateBulkHomeRooms: HomeRoomConnection;
	updateBulkInvoiceLineItems: InvoiceLineItemConnection;
	updateBulkInvoices: InvoiceConnection;
	updateBulkLearningObjectiveMarks: LearningObjectiveMarkConnection;
	updateBulkLearningObjectives: LearningObjectiveConnection;
	updateBulkLessonPlans: LessonPlanConnection;
	updateBulkMealMenus: MealMenuConnection;
	updateBulkMealPlans: MealPlanConnection;
	updateBulkNoteAttachments: NoteAttachmentConnection;
	updateBulkNoteComments: NoteCommentConnection;
	updateBulkNoteReactions: NoteReactionConnection;
	updateBulkNotes: NoteConnection;
	updateBulkParents: ParentConnection;
	updateBulkPaymentLineItems: PaymentLineItemConnection;
	updateBulkPayments: PaymentConnection;
	updateBulkPeople: PersonConnection;
	updateBulkPermissionGroups: PermissionGroupConnection;
	updateBulkPermissions: PermissionConnection;
	updateBulkPhoneNumbers: PhoneNumberConnection;
	updateBulkQuotations: QuotationConnection;
	updateBulkRelationships: RelationshipConnection;
	updateBulkReportCards: ReportCardConnection;
	updateBulkRooms: RoomConnection;
	updateBulkSchoolAttendanceRecords: SchoolAttendanceRecordConnection;
	updateBulkSchoolStudentEnrollments: SchoolStudentEnrollmentConnection;
	updateBulkServiceSubscriptions: ServiceSubscriptionConnection;
	updateBulkServices: ServiceConnection;
	updateBulkStudentDocuments: StudentDocumentConnection;
	updateBulkStudents: StudentConnection;
	updateBulkTerms: TermConnection;
	updateBulkTuitions: TuitionConnection;
	updateBulkUserGroups: UserGroupConnection;
	updateBulkUsers: UserConnection;
	updateBus: Bus;
	updateBusPlan: BusPlan;
	updateCalendarEvent: CalendarEvent;
	updateCampus: Campus;
	updateClassYear: ClassYear;
	updateContract: Contract;
	updateContractInstallment: ContractInstallment;
	updateCourse: Course;
	updateCourseAttendanceRecord: CourseAttendanceRecord;
	updateCourseSection: CourseSection;
	updateCourseSectionPeriod: CourseSectionPeriod;
	updateCourseSectionStudentEnrollment: CourseSectionStudentEnrollment;
	updateCourseSectionTeacherEnrollment: CourseSectionTeacherEnrollment;
	updateDiscount: Discount;
	updateDocumentResource: DocumentResource;
	updateDocumentResourceFolder: DocumentResourceFolder;
	updateEmailAddress: EmailAddress;
	updateEmployee: Employee;
	updateEmployeeWithPerson: Employee;
	updateEnrollmentApplication: EnrollmentApplication;
	updateEnrollmentApplicationNote: EnrollmentApplicationNote;
	updateEnrollmentApplicationParent: EnrollmentApplicationParent;
	updateEnrollmentApplicationStudent: EnrollmentApplicationStudent;
	updateEnrollmentApplicationSubmission: EnrollmentApplicationSubmission;
	updateEnrollmentInvitation: EnrollmentInvitation;
	updateExpense: Expense;
	updateExpenseBudget: ExpenseBudget;
	updateExpenseCategory: ExpenseCategory;
	updateHomeRoom: HomeRoom;
	updateHomeRoomSection: HomeRoomSection;
	updateHomeRoomSectionStudentEnrollment: HomeRoomSectionStudentEnrollment;
	updateHomeRoomSectionTeacherEnrollment: HomeRoomSectionTeacherEnrollment;
	updateInvoice: Invoice;
	updateInvoiceLineItem: InvoiceLineItem;
	updateLearningObjective: LearningObjective;
	updateLearningObjectiveMark: LearningObjectiveMark;
	updateLessonPlan: LessonPlan;
	updateMealMenu: MealMenu;
	updateMealPlan: MealPlan;
	updateNote: Note;
	updateNoteAttachment: NoteAttachment;
	updateNoteComment: NoteComment;
	updateNoteReaction: NoteReaction;
	updateParent: Parent;
	updateParentWithPerson: Parent;
	updatePayment: Payment;
	updatePaymentLineItem: PaymentLineItem;
	updatePermission: Permission;
	updatePermissionGroup: PermissionGroup;
	updatePerson: Person;
	updatePhoneNumber: PhoneNumber;
	updateQuotation: Quotation;
	updateRelationship: Relationship;
	updateReportCard: ReportCard;
	updateRoom: Room;
	updateSchoolAttendanceRecord: SchoolAttendanceRecord;
	updateSchoolStudentEnrollment: SchoolStudentEnrollment;
	updateService: Service;
	updateServiceSubscription: ServiceSubscription;
	updateStudent: Student;
	updateStudentDocument: StudentDocument;
	updateStudentWithPerson: Student;
	updateTerm: Term;
	updateTuition: Tuition;
	updateUser: User;
	updateUserGroup: UserGroup;
};

export type MutationCreateAcademicYearArgs = {
	input: AcademicYearCreateInput;
};

export type MutationCreateAcademicYearTuitionArgs = {
	input: AcademicYearTuitionCreateInput;
};

export type MutationCreateAddressArgs = {
	input: AddressCreateInput;
};

export type MutationCreateBillingSetupSubmissionArgs = {
	input: BillingSetupSubmissionCreateInput;
};

export type MutationCreateBulkAcademicYearTuitionsArgs = {
	inputs: Array<AcademicYearTuitionCreateInput>;
};

export type MutationCreateBulkAcademicYearsArgs = {
	inputs: Array<AcademicYearCreateInput>;
};

export type MutationCreateBulkAddressesArgs = {
	inputs: Array<AddressCreateInput>;
};

export type MutationCreateBulkBillingSetupSubmissionsArgs = {
	inputs: Array<BillingSetupSubmissionCreateInput>;
};

export type MutationCreateBulkBusPlansArgs = {
	inputs: Array<BusPlanCreateInput>;
};

export type MutationCreateBulkBusesArgs = {
	inputs: Array<BusCreateInput>;
};

export type MutationCreateBulkCalendarEventsArgs = {
	inputs: Array<CalendarEventCreateInput>;
};

export type MutationCreateBulkCampusesArgs = {
	inputs: Array<CampusCreateInput>;
};

export type MutationCreateBulkClassYearsArgs = {
	inputs: Array<ClassYearCreateInput>;
};

export type MutationCreateBulkContractInstallmentsArgs = {
	inputs: Array<ContractInstallmentCreateInput>;
};

export type MutationCreateBulkContractsArgs = {
	inputs: Array<ContractCreateInput>;
};

export type MutationCreateBulkCourseAttendanceRecordsArgs = {
	inputs: Array<CourseAttendanceRecordCreateInput>;
};

export type MutationCreateBulkCourseSectionPeriodsArgs = {
	inputs: Array<CourseSectionPeriodCreateInput>;
};

export type MutationCreateBulkCourseSectionStudentEnrollmentsArgs = {
	inputs: Array<CourseSectionStudentEnrollmentCreateInput>;
};

export type MutationCreateBulkCourseSectionTeacherEnrollmentsArgs = {
	inputs: Array<CourseSectionTeacherEnrollmentCreateInput>;
};

export type MutationCreateBulkCourseSectionsArgs = {
	inputs: Array<CourseSectionCreateInput>;
};

export type MutationCreateBulkCoursesArgs = {
	inputs: Array<CourseCreateInput>;
};

export type MutationCreateBulkDiscountsArgs = {
	inputs: Array<DiscountCreateInput>;
};

export type MutationCreateBulkDocumentResourceFoldersArgs = {
	inputs: Array<DocumentResourceFolderCreateInput>;
};

export type MutationCreateBulkDocumentResourceUserViewRecordsArgs = {
	inputs: Array<DocumentResourceUserViewRecordCreateInput>;
};

export type MutationCreateBulkDocumentResourcesArgs = {
	inputs: Array<DocumentResourceCreateInput>;
};

export type MutationCreateBulkEmailAddressesArgs = {
	inputs: Array<EmailAddressCreateInput>;
};

export type MutationCreateBulkEmployeesArgs = {
	inputs: Array<EmployeeCreateInput>;
};

export type MutationCreateBulkEnrollmentApplicationNotesArgs = {
	inputs: Array<EnrollmentApplicationNoteCreateInput>;
};

export type MutationCreateBulkEnrollmentApplicationParentsArgs = {
	inputs: Array<EnrollmentApplicationParentCreateInput>;
};

export type MutationCreateBulkEnrollmentApplicationStudentsArgs = {
	inputs: Array<EnrollmentApplicationStudentCreateInput>;
};

export type MutationCreateBulkEnrollmentInvitationsArgs = {
	inputs: Array<EnrollmentInvitationCreateInput>;
};

export type MutationCreateBulkExpenseBudgetsArgs = {
	inputs: Array<ExpenseBudgetCreateInput>;
};

export type MutationCreateBulkExpenseCategoriesArgs = {
	inputs: Array<ExpenseCategoryCreateInput>;
};

export type MutationCreateBulkExpensesArgs = {
	inputs: Array<ExpenseCreateInput>;
};

export type MutationCreateBulkHomeRoomSectionStudentEnrollmentsArgs = {
	inputs: Array<HomeRoomSectionStudentEnrollmentCreateInput>;
};

export type MutationCreateBulkHomeRoomSectionTeacherEnrollmentsArgs = {
	inputs: Array<HomeRoomSectionTeacherEnrollmentCreateInput>;
};

export type MutationCreateBulkHomeRoomSectionsArgs = {
	inputs: Array<HomeRoomSectionCreateInput>;
};

export type MutationCreateBulkHomeRoomsArgs = {
	inputs: Array<HomeRoomCreateInput>;
};

export type MutationCreateBulkInvoiceLineItemsArgs = {
	inputs: Array<InvoiceLineItemCreateInput>;
};

export type MutationCreateBulkInvoicesArgs = {
	inputs: Array<InvoiceCreateInput>;
};

export type MutationCreateBulkLearningObjectiveMarksArgs = {
	inputs: Array<LearningObjectiveMarkCreateInput>;
};

export type MutationCreateBulkLearningObjectivesArgs = {
	inputs: Array<LearningObjectiveCreateInput>;
};

export type MutationCreateBulkLessonPlansArgs = {
	inputs: Array<LessonPlanCreateInput>;
};

export type MutationCreateBulkMealMenusArgs = {
	inputs: Array<MealMenuCreateInput>;
};

export type MutationCreateBulkMealPlansArgs = {
	inputs: Array<MealPlanCreateInput>;
};

export type MutationCreateBulkNoteAttachmentsArgs = {
	inputs: Array<NoteAttachmentCreateInput>;
};

export type MutationCreateBulkNoteCommentsArgs = {
	inputs: Array<NoteCommentCreateInput>;
};

export type MutationCreateBulkNoteReactionsArgs = {
	inputs: Array<NoteReactionCreateInput>;
};

export type MutationCreateBulkNotesArgs = {
	inputs: Array<NoteCreateInput>;
};

export type MutationCreateBulkParentsArgs = {
	inputs: Array<ParentCreateInput>;
};

export type MutationCreateBulkPaymentLineItemsArgs = {
	inputs: Array<PaymentLineItemCreateInput>;
};

export type MutationCreateBulkPaymentsArgs = {
	inputs: Array<PaymentCreateInput>;
};

export type MutationCreateBulkPeopleArgs = {
	inputs: Array<PersonCreateInput>;
};

export type MutationCreateBulkPermissionGroupsArgs = {
	inputs: Array<PermissionGroupCreateInput>;
};

export type MutationCreateBulkPhoneNumbersArgs = {
	inputs: Array<PhoneNumberCreateInput>;
};

export type MutationCreateBulkQuotationsArgs = {
	inputs: Array<QuotationCreateInput>;
};

export type MutationCreateBulkRelationshipsArgs = {
	inputs: Array<RelationshipCreateInput>;
};

export type MutationCreateBulkReportCardsArgs = {
	inputs: Array<ReportCardCreateInput>;
};

export type MutationCreateBulkReportCardsForTermArgs = {
	issueDate: Scalars["Time"];
	termID: Scalars["ID"];
};

export type MutationCreateBulkRoomsArgs = {
	inputs: Array<RoomCreateInput>;
};

export type MutationCreateBulkSchoolAttendanceRecordsArgs = {
	inputs: Array<SchoolAttendanceRecordCreateInput>;
};

export type MutationCreateBulkSchoolStudentEnrollmentsArgs = {
	inputs: Array<SchoolStudentEnrollmentCreateInput>;
};

export type MutationCreateBulkServiceSubscriptionsArgs = {
	inputs: Array<ServiceSubscriptionCreateInput>;
};

export type MutationCreateBulkServicesArgs = {
	inputs: Array<ServiceCreateInput>;
};

export type MutationCreateBulkStudentDocumentsArgs = {
	inputs: Array<StudentDocumentCreateInput>;
};

export type MutationCreateBulkStudentsArgs = {
	inputs: Array<StudentCreateInput>;
};

export type MutationCreateBulkTermsArgs = {
	inputs: Array<TermCreateInput>;
};

export type MutationCreateBulkTuitionsArgs = {
	inputs: Array<TuitionCreateInput>;
};

export type MutationCreateBulkUserGroupsArgs = {
	inputs: Array<UserGroupCreateInput>;
};

export type MutationCreateBulkUsersArgs = {
	inputs: Array<UserCreateInput>;
};

export type MutationCreateBusArgs = {
	input: BusCreateInput;
};

export type MutationCreateBusPlanArgs = {
	input: BusPlanCreateInput;
};

export type MutationCreateCalendarEventArgs = {
	input: CalendarEventCreateInput;
};

export type MutationCreateCampusArgs = {
	input: CampusCreateInput;
};

export type MutationCreateClassYearArgs = {
	input: ClassYearCreateInput;
};

export type MutationCreateContractArgs = {
	input: ContractCreateInput;
};

export type MutationCreateContractInstallmentArgs = {
	input: ContractInstallmentCreateInput;
};

export type MutationCreateCourseArgs = {
	input: CourseCreateInput;
};

export type MutationCreateCourseAttendanceRecordArgs = {
	input: CourseAttendanceRecordCreateInput;
};

export type MutationCreateCourseSectionArgs = {
	input: CourseSectionCreateInput;
};

export type MutationCreateCourseSectionPeriodArgs = {
	input: CourseSectionPeriodCreateInput;
};

export type MutationCreateCourseSectionStudentEnrollmentArgs = {
	input: CourseSectionStudentEnrollmentCreateInput;
};

export type MutationCreateCourseSectionTeacherEnrollmentArgs = {
	input: CourseSectionTeacherEnrollmentCreateInput;
};

export type MutationCreateDiscountArgs = {
	input: DiscountCreateInput;
};

export type MutationCreateDocumentResourceArgs = {
	input: DocumentResourceCreateInput;
};

export type MutationCreateDocumentResourceFolderArgs = {
	input: DocumentResourceFolderCreateInput;
};

export type MutationCreateDocumentResourceUserViewRecordArgs = {
	input: DocumentResourceUserViewRecordCreateInput;
};

export type MutationCreateEmailAddressArgs = {
	input: EmailAddressCreateInput;
};

export type MutationCreateEmployeeArgs = {
	input: EmployeeCreateInput;
};

export type MutationCreateEnrollmentApplicationNoteArgs = {
	input: EnrollmentApplicationNoteCreateInput;
};

export type MutationCreateEnrollmentApplicationParentArgs = {
	input: EnrollmentApplicationParentCreateInput;
};

export type MutationCreateEnrollmentApplicationStudentArgs = {
	input: EnrollmentApplicationStudentCreateInput;
};

export type MutationCreateEnrollmentInvitationArgs = {
	input: EnrollmentInvitationCreateInput;
};

export type MutationCreateExpenseArgs = {
	input: ExpenseCreateInput;
};

export type MutationCreateExpenseBudgetArgs = {
	input: ExpenseBudgetCreateInput;
};

export type MutationCreateExpenseCategoryArgs = {
	input: ExpenseCategoryCreateInput;
};

export type MutationCreateHomeRoomArgs = {
	input: HomeRoomCreateInput;
};

export type MutationCreateHomeRoomSectionArgs = {
	input: HomeRoomSectionCreateInput;
};

export type MutationCreateHomeRoomSectionStudentEnrollmentArgs = {
	input: HomeRoomSectionStudentEnrollmentCreateInput;
};

export type MutationCreateHomeRoomSectionTeacherEnrollmentArgs = {
	input: HomeRoomSectionTeacherEnrollmentCreateInput;
};

export type MutationCreateInvoiceArgs = {
	input: InvoiceCreateInput;
};

export type MutationCreateInvoiceLineItemArgs = {
	input: InvoiceLineItemCreateInput;
};

export type MutationCreateLearningObjectiveArgs = {
	input: LearningObjectiveCreateInput;
};

export type MutationCreateLearningObjectiveMarkArgs = {
	input: LearningObjectiveMarkCreateInput;
};

export type MutationCreateLessonPlanArgs = {
	input: LessonPlanCreateInput;
};

export type MutationCreateMealMenuArgs = {
	input: MealMenuCreateInput;
};

export type MutationCreateMealPlanArgs = {
	input: MealPlanCreateInput;
};

export type MutationCreateNoteArgs = {
	input: NoteCreateInput;
};

export type MutationCreateNoteAttachmentArgs = {
	input: NoteAttachmentCreateInput;
};

export type MutationCreateNoteCommentArgs = {
	input: NoteCommentCreateInput;
};

export type MutationCreateNoteReactionArgs = {
	input: NoteReactionCreateInput;
};

export type MutationCreateParentArgs = {
	input: ParentCreateInput;
};

export type MutationCreatePaymentArgs = {
	input: PaymentCreateInput;
};

export type MutationCreatePaymentLineItemArgs = {
	input: PaymentLineItemCreateInput;
};

export type MutationCreatePermissionGroupArgs = {
	input: PermissionGroupCreateInput;
};

export type MutationCreatePersonArgs = {
	input: PersonCreateInput;
};

export type MutationCreatePhoneNumberArgs = {
	input: PhoneNumberCreateInput;
};

export type MutationCreateQuotationArgs = {
	input: QuotationCreateInput;
};

export type MutationCreateRelationshipArgs = {
	input: RelationshipCreateInput;
};

export type MutationCreateReportCardArgs = {
	input: ReportCardCreateInput;
};

export type MutationCreateRoomArgs = {
	input: RoomCreateInput;
};

export type MutationCreateSchoolAttendanceRecordArgs = {
	input: SchoolAttendanceRecordCreateInput;
};

export type MutationCreateSchoolStudentEnrollmentArgs = {
	input: SchoolStudentEnrollmentCreateInput;
};

export type MutationCreateServiceArgs = {
	input: ServiceCreateInput;
};

export type MutationCreateServiceSubscriptionArgs = {
	input: ServiceSubscriptionCreateInput;
};

export type MutationCreateStudentArgs = {
	input: StudentCreateInput;
};

export type MutationCreateStudentDocumentArgs = {
	input: StudentDocumentCreateInput;
};

export type MutationCreateTermArgs = {
	input: TermCreateInput;
};

export type MutationCreateTuitionArgs = {
	input: TuitionCreateInput;
};

export type MutationCreateUserArgs = {
	input: UserCreateInput;
};

export type MutationCreateUserGroupArgs = {
	input: UserGroupCreateInput;
};

export type MutationDeleteAcademicYearArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteAcademicYearTuitionArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteAddressArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteBillingSetupSubmissionArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteBulkAcademicYearTuitionsArgs = {
	filters: Array<AcademicYearTuitionFilterInput>;
};

export type MutationDeleteBulkAcademicYearsArgs = {
	filters: Array<AcademicYearFilterInput>;
};

export type MutationDeleteBulkAddressesArgs = {
	filters: Array<AddressFilterInput>;
};

export type MutationDeleteBulkBillingSetupSubmissionsArgs = {
	filters: Array<BillingSetupSubmissionFilterInput>;
};

export type MutationDeleteBulkBusPlansArgs = {
	filters: Array<BusPlanFilterInput>;
};

export type MutationDeleteBulkBusesArgs = {
	filters: Array<BusFilterInput>;
};

export type MutationDeleteBulkCalendarEventsArgs = {
	filters: Array<CalendarEventFilterInput>;
};

export type MutationDeleteBulkCampusesArgs = {
	filters: Array<CampusFilterInput>;
};

export type MutationDeleteBulkClassYearsArgs = {
	filters: Array<ClassYearFilterInput>;
};

export type MutationDeleteBulkContractInstallmentsArgs = {
	filters: Array<ContractInstallmentFilterInput>;
};

export type MutationDeleteBulkContractsArgs = {
	filters: Array<ContractFilterInput>;
};

export type MutationDeleteBulkCourseAttendanceRecordsArgs = {
	filters: Array<CourseAttendanceRecordFilterInput>;
};

export type MutationDeleteBulkCourseSectionPeriodsArgs = {
	filters: Array<CourseSectionPeriodFilterInput>;
};

export type MutationDeleteBulkCourseSectionStudentEnrollmentsArgs = {
	filters: Array<CourseSectionStudentEnrollmentFilterInput>;
};

export type MutationDeleteBulkCourseSectionTeacherEnrollmentsArgs = {
	filters: Array<CourseSectionTeacherEnrollmentFilterInput>;
};

export type MutationDeleteBulkCourseSectionsArgs = {
	filters: Array<CourseSectionFilterInput>;
};

export type MutationDeleteBulkCoursesArgs = {
	filters: Array<CourseFilterInput>;
};

export type MutationDeleteBulkDiscountsArgs = {
	filters: Array<DiscountFilterInput>;
};

export type MutationDeleteBulkDocumentResourceFoldersArgs = {
	filters: Array<DocumentResourceFolderFilterInput>;
};

export type MutationDeleteBulkDocumentResourceUserViewRecordsArgs = {
	filters: Array<DocumentResourceUserViewRecordFilterInput>;
};

export type MutationDeleteBulkDocumentResourcesArgs = {
	filters: Array<DocumentResourceFilterInput>;
};

export type MutationDeleteBulkEmailAddressesArgs = {
	filters: Array<EmailAddressFilterInput>;
};

export type MutationDeleteBulkEmployeesArgs = {
	filters: Array<EmployeeFilterInput>;
};

export type MutationDeleteBulkEnrollmentApplicationNotesArgs = {
	filters: Array<EnrollmentApplicationNoteFilterInput>;
};

export type MutationDeleteBulkEnrollmentApplicationParentsArgs = {
	filters: Array<EnrollmentApplicationParentFilterInput>;
};

export type MutationDeleteBulkEnrollmentApplicationStudentsArgs = {
	filters: Array<EnrollmentApplicationStudentFilterInput>;
};

export type MutationDeleteBulkEnrollmentInvitationsArgs = {
	filters: Array<EnrollmentInvitationFilterInput>;
};

export type MutationDeleteBulkExpenseBudgetsArgs = {
	filters: Array<ExpenseBudgetFilterInput>;
};

export type MutationDeleteBulkExpenseCategoriesArgs = {
	filters: Array<ExpenseCategoryFilterInput>;
};

export type MutationDeleteBulkExpensesArgs = {
	filters: Array<ExpenseFilterInput>;
};

export type MutationDeleteBulkHomeRoomSectionStudentEnrollmentsArgs = {
	filters: Array<HomeRoomSectionStudentEnrollmentFilterInput>;
};

export type MutationDeleteBulkHomeRoomSectionTeacherEnrollmentsArgs = {
	filters: Array<HomeRoomSectionTeacherEnrollmentFilterInput>;
};

export type MutationDeleteBulkHomeRoomSectionsArgs = {
	filters: Array<HomeRoomSectionFilterInput>;
};

export type MutationDeleteBulkHomeRoomsArgs = {
	filters: Array<HomeRoomFilterInput>;
};

export type MutationDeleteBulkInvoiceLineItemsArgs = {
	filters: Array<InvoiceLineItemFilterInput>;
};

export type MutationDeleteBulkInvoicesArgs = {
	filters: Array<InvoiceFilterInput>;
};

export type MutationDeleteBulkLearningObjectiveMarksArgs = {
	filters: Array<LearningObjectiveMarkFilterInput>;
};

export type MutationDeleteBulkLearningObjectivesArgs = {
	filters: Array<LearningObjectiveFilterInput>;
};

export type MutationDeleteBulkLessonPlansArgs = {
	filters: Array<LessonPlanFilterInput>;
};

export type MutationDeleteBulkMealMenusArgs = {
	filters: Array<MealMenuFilterInput>;
};

export type MutationDeleteBulkMealPlansArgs = {
	filters: Array<MealPlanFilterInput>;
};

export type MutationDeleteBulkNoteAttachmentsArgs = {
	filters: Array<NoteAttachmentFilterInput>;
};

export type MutationDeleteBulkNoteCommentsArgs = {
	filters: Array<NoteCommentFilterInput>;
};

export type MutationDeleteBulkNoteReactionsArgs = {
	filters: Array<NoteReactionFilterInput>;
};

export type MutationDeleteBulkNotesArgs = {
	filters: Array<NoteFilterInput>;
};

export type MutationDeleteBulkParentsArgs = {
	filters: Array<ParentFilterInput>;
};

export type MutationDeleteBulkPaymentLineItemsArgs = {
	filters: Array<PaymentLineItemFilterInput>;
};

export type MutationDeleteBulkPaymentsArgs = {
	filters: Array<PaymentFilterInput>;
};

export type MutationDeleteBulkPeopleArgs = {
	filters: Array<PersonFilterInput>;
};

export type MutationDeleteBulkPermissionGroupsArgs = {
	filters: Array<PermissionGroupFilterInput>;
};

export type MutationDeleteBulkPhoneNumbersArgs = {
	filters: Array<PhoneNumberFilterInput>;
};

export type MutationDeleteBulkQuotationsArgs = {
	filters: Array<QuotationFilterInput>;
};

export type MutationDeleteBulkRelationshipsArgs = {
	filters: Array<RelationshipFilterInput>;
};

export type MutationDeleteBulkReportCardsArgs = {
	filters: Array<ReportCardFilterInput>;
};

export type MutationDeleteBulkRoomsArgs = {
	filters: Array<RoomFilterInput>;
};

export type MutationDeleteBulkSchoolAttendanceRecordsArgs = {
	filters: Array<SchoolAttendanceRecordFilterInput>;
};

export type MutationDeleteBulkSchoolStudentEnrollmentsArgs = {
	filters: Array<SchoolStudentEnrollmentFilterInput>;
};

export type MutationDeleteBulkServiceSubscriptionsArgs = {
	filters: Array<ServiceSubscriptionFilterInput>;
};

export type MutationDeleteBulkServicesArgs = {
	filters: Array<ServiceFilterInput>;
};

export type MutationDeleteBulkStudentDocumentsArgs = {
	filters: Array<StudentDocumentFilterInput>;
};

export type MutationDeleteBulkStudentsArgs = {
	filters: Array<StudentFilterInput>;
};

export type MutationDeleteBulkTermsArgs = {
	filters: Array<TermFilterInput>;
};

export type MutationDeleteBulkTuitionsArgs = {
	filters: Array<TuitionFilterInput>;
};

export type MutationDeleteBulkUserGroupsArgs = {
	filters: Array<UserGroupFilterInput>;
};

export type MutationDeleteBulkUsersArgs = {
	filters: Array<UserFilterInput>;
};

export type MutationDeleteBusArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteBusPlanArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteCalendarEventArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteCampusArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteClassYearArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteContractArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteContractInstallmentArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteCourseArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteCourseAttendanceRecordArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteCourseSectionArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteCourseSectionPeriodArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteCourseSectionStudentEnrollmentArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteCourseSectionTeacherEnrollmentArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteDiscountArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteDocumentResourceArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteDocumentResourceFolderArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteDocumentResourceUserViewRecordArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteEmailAddressArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteEmployeeArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteEnrollmentApplicationNoteArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteEnrollmentApplicationParentArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteEnrollmentApplicationStudentArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteEnrollmentInvitationArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteExpenseArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteExpenseBudgetArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteExpenseCategoryArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteHomeRoomArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteHomeRoomSectionArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteHomeRoomSectionStudentEnrollmentArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteHomeRoomSectionTeacherEnrollmentArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteInvoiceArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteInvoiceLineItemArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteLearningObjectiveArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteLearningObjectiveMarkArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteLessonPlanArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteMealMenuArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteMealPlanArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteNoteArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteNoteAttachmentArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteNoteCommentArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteNoteReactionArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteParentArgs = {
	id: Scalars["ID"];
};

export type MutationDeletePaymentArgs = {
	id: Scalars["ID"];
};

export type MutationDeletePaymentLineItemArgs = {
	id: Scalars["ID"];
};

export type MutationDeletePermissionGroupArgs = {
	id: Scalars["ID"];
};

export type MutationDeletePersonArgs = {
	id: Scalars["ID"];
};

export type MutationDeletePhoneNumberArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteQuotationArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteRelationshipArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteReportCardArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteRoomArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteSchoolAttendanceRecordArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteSchoolStudentEnrollmentArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteServiceArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteServiceSubscriptionArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteStudentArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteStudentDocumentArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteTermArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteTuitionArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteUserArgs = {
	id: Scalars["ID"];
};

export type MutationDeleteUserGroupArgs = {
	id: Scalars["ID"];
};

export type MutationRecordEnrollmentApplicationListViewArgs = {
	ids: Array<Scalars["ID"]>;
};

export type MutationSendEnrollmentApplicationApprovedEmailArgs = {
	enrollmentApplicationID: Scalars["ID"];
};

export type MutationSendEnrollmentApplicationInviteEmailArgs = {
	body: Scalars["String"];
	recipientAddresses: Array<Scalars["String"]>;
	subject: Scalars["String"];
};

export type MutationSendReenrollmentInviteEmailsArgs = {
	filters: Array<StudentFilterInput>;
	variant?: InputMaybe<ReenrollmentInviteEmailVariant>;
};

export type MutationUpdateAcademicYearArgs = {
	id: Scalars["ID"];
	input: AcademicYearUpdateInput;
};

export type MutationUpdateAcademicYearTuitionArgs = {
	id: Scalars["ID"];
	input: AcademicYearTuitionUpdateInput;
};

export type MutationUpdateAddressArgs = {
	id: Scalars["ID"];
	input: AddressUpdateInput;
};

export type MutationUpdateBillingSetupSubmissionArgs = {
	id: Scalars["ID"];
	input: BillingSetupSubmissionUpdateInput;
};

export type MutationUpdateBulkAcademicYearTuitionsArgs = {
	filters: Array<AcademicYearTuitionFilterInput>;
	input: AcademicYearTuitionUpdateBulkInput;
};

export type MutationUpdateBulkAcademicYearsArgs = {
	filters: Array<AcademicYearFilterInput>;
	input: AcademicYearUpdateBulkInput;
};

export type MutationUpdateBulkAddressesArgs = {
	filters: Array<AddressFilterInput>;
	input: AddressUpdateBulkInput;
};

export type MutationUpdateBulkBillingSetupSubmissionsArgs = {
	filters: Array<BillingSetupSubmissionFilterInput>;
	input: BillingSetupSubmissionUpdateBulkInput;
};

export type MutationUpdateBulkBusPlansArgs = {
	filters: Array<BusPlanFilterInput>;
	input: BusPlanUpdateBulkInput;
};

export type MutationUpdateBulkBusesArgs = {
	filters: Array<BusFilterInput>;
	input: BusUpdateBulkInput;
};

export type MutationUpdateBulkCalendarEventsArgs = {
	filters: Array<CalendarEventFilterInput>;
	input: CalendarEventUpdateBulkInput;
};

export type MutationUpdateBulkCampusesArgs = {
	filters: Array<CampusFilterInput>;
	input: CampusUpdateBulkInput;
};

export type MutationUpdateBulkClassYearsArgs = {
	filters: Array<ClassYearFilterInput>;
	input: ClassYearUpdateBulkInput;
};

export type MutationUpdateBulkContractInstallmentsArgs = {
	filters: Array<ContractInstallmentFilterInput>;
	input: ContractInstallmentUpdateBulkInput;
};

export type MutationUpdateBulkContractsArgs = {
	filters: Array<ContractFilterInput>;
	input: ContractUpdateBulkInput;
};

export type MutationUpdateBulkCourseAttendanceRecordsArgs = {
	filters: Array<CourseAttendanceRecordFilterInput>;
	input: CourseAttendanceRecordUpdateBulkInput;
};

export type MutationUpdateBulkCourseSectionPeriodsArgs = {
	filters: Array<CourseSectionPeriodFilterInput>;
	input: CourseSectionPeriodUpdateBulkInput;
};

export type MutationUpdateBulkCourseSectionStudentEnrollmentsArgs = {
	filters: Array<CourseSectionStudentEnrollmentFilterInput>;
	input: CourseSectionStudentEnrollmentUpdateBulkInput;
};

export type MutationUpdateBulkCourseSectionTeacherEnrollmentsArgs = {
	filters: Array<CourseSectionTeacherEnrollmentFilterInput>;
	input: CourseSectionTeacherEnrollmentUpdateBulkInput;
};

export type MutationUpdateBulkCourseSectionsArgs = {
	filters: Array<CourseSectionFilterInput>;
	input: CourseSectionUpdateBulkInput;
};

export type MutationUpdateBulkCoursesArgs = {
	filters: Array<CourseFilterInput>;
	input: CourseUpdateBulkInput;
};

export type MutationUpdateBulkDiscountsArgs = {
	filters: Array<DiscountFilterInput>;
	input: DiscountUpdateBulkInput;
};

export type MutationUpdateBulkDocumentResourceFoldersArgs = {
	filters: Array<DocumentResourceFolderFilterInput>;
	input: DocumentResourceFolderUpdateBulkInput;
};

export type MutationUpdateBulkDocumentResourcesArgs = {
	filters: Array<DocumentResourceFilterInput>;
	input: DocumentResourceUpdateBulkInput;
};

export type MutationUpdateBulkEmailAddressesArgs = {
	filters: Array<EmailAddressFilterInput>;
	input: EmailAddressUpdateBulkInput;
};

export type MutationUpdateBulkEmployeesArgs = {
	filters: Array<EmployeeFilterInput>;
	input: EmployeeUpdateBulkInput;
};

export type MutationUpdateBulkEnrollmentApplicationNotesArgs = {
	filters: Array<EnrollmentApplicationNoteFilterInput>;
	input: EnrollmentApplicationNoteUpdateBulkInput;
};

export type MutationUpdateBulkEnrollmentApplicationParentsArgs = {
	filters: Array<EnrollmentApplicationParentFilterInput>;
	input: EnrollmentApplicationParentUpdateBulkInput;
};

export type MutationUpdateBulkEnrollmentApplicationStudentsArgs = {
	filters: Array<EnrollmentApplicationStudentFilterInput>;
	input: EnrollmentApplicationStudentUpdateBulkInput;
};

export type MutationUpdateBulkEnrollmentApplicationSubmissionsArgs = {
	filters: Array<EnrollmentApplicationSubmissionFilterInput>;
	input: EnrollmentApplicationSubmissionUpdateBulkInput;
};

export type MutationUpdateBulkEnrollmentApplicationsArgs = {
	filters: Array<EnrollmentApplicationFilterInput>;
	input: EnrollmentApplicationUpdateBulkInput;
};

export type MutationUpdateBulkEnrollmentInvitationsArgs = {
	filters: Array<EnrollmentInvitationFilterInput>;
	input: EnrollmentInvitationUpdateBulkInput;
};

export type MutationUpdateBulkExpenseBudgetsArgs = {
	filters: Array<ExpenseBudgetFilterInput>;
	input: ExpenseBudgetUpdateBulkInput;
};

export type MutationUpdateBulkExpenseCategoriesArgs = {
	filters: Array<ExpenseCategoryFilterInput>;
	input: ExpenseCategoryUpdateBulkInput;
};

export type MutationUpdateBulkExpensesArgs = {
	filters: Array<ExpenseFilterInput>;
	input: ExpenseUpdateBulkInput;
};

export type MutationUpdateBulkHomeRoomSectionStudentEnrollmentsArgs = {
	filters: Array<HomeRoomSectionStudentEnrollmentFilterInput>;
	input: HomeRoomSectionStudentEnrollmentUpdateBulkInput;
};

export type MutationUpdateBulkHomeRoomSectionTeacherEnrollmentsArgs = {
	filters: Array<HomeRoomSectionTeacherEnrollmentFilterInput>;
	input: HomeRoomSectionTeacherEnrollmentUpdateBulkInput;
};

export type MutationUpdateBulkHomeRoomSectionsArgs = {
	filters: Array<HomeRoomSectionFilterInput>;
	input: HomeRoomSectionUpdateBulkInput;
};

export type MutationUpdateBulkHomeRoomsArgs = {
	filters: Array<HomeRoomFilterInput>;
	input: HomeRoomUpdateBulkInput;
};

export type MutationUpdateBulkInvoiceLineItemsArgs = {
	filters: Array<InvoiceLineItemFilterInput>;
	input: InvoiceLineItemUpdateBulkInput;
};

export type MutationUpdateBulkInvoicesArgs = {
	filters: Array<InvoiceFilterInput>;
	input: InvoiceUpdateBulkInput;
};

export type MutationUpdateBulkLearningObjectiveMarksArgs = {
	filters: Array<LearningObjectiveMarkFilterInput>;
	input: LearningObjectiveMarkUpdateBulkInput;
};

export type MutationUpdateBulkLearningObjectivesArgs = {
	filters: Array<LearningObjectiveFilterInput>;
	input: LearningObjectiveUpdateBulkInput;
};

export type MutationUpdateBulkLessonPlansArgs = {
	filters: Array<LessonPlanFilterInput>;
	input: LessonPlanUpdateBulkInput;
};

export type MutationUpdateBulkMealMenusArgs = {
	filters: Array<MealMenuFilterInput>;
	input: MealMenuUpdateBulkInput;
};

export type MutationUpdateBulkMealPlansArgs = {
	filters: Array<MealPlanFilterInput>;
	input: MealPlanUpdateBulkInput;
};

export type MutationUpdateBulkNoteAttachmentsArgs = {
	filters: Array<NoteAttachmentFilterInput>;
	input: NoteAttachmentUpdateBulkInput;
};

export type MutationUpdateBulkNoteCommentsArgs = {
	filters: Array<NoteCommentFilterInput>;
	input: NoteCommentUpdateBulkInput;
};

export type MutationUpdateBulkNoteReactionsArgs = {
	filters: Array<NoteReactionFilterInput>;
	input: NoteReactionUpdateBulkInput;
};

export type MutationUpdateBulkNotesArgs = {
	filters: Array<NoteFilterInput>;
	input: NoteUpdateBulkInput;
};

export type MutationUpdateBulkParentsArgs = {
	filters: Array<ParentFilterInput>;
	input: ParentUpdateBulkInput;
};

export type MutationUpdateBulkPaymentLineItemsArgs = {
	filters: Array<PaymentLineItemFilterInput>;
	input: PaymentLineItemUpdateBulkInput;
};

export type MutationUpdateBulkPaymentsArgs = {
	filters: Array<PaymentFilterInput>;
	input: PaymentUpdateBulkInput;
};

export type MutationUpdateBulkPeopleArgs = {
	filters: Array<PersonFilterInput>;
	input: PersonUpdateBulkInput;
};

export type MutationUpdateBulkPermissionGroupsArgs = {
	filters: Array<PermissionGroupFilterInput>;
	input: PermissionGroupUpdateBulkInput;
};

export type MutationUpdateBulkPermissionsArgs = {
	filters: Array<PermissionFilterInput>;
	input: PermissionUpdateBulkInput;
};

export type MutationUpdateBulkPhoneNumbersArgs = {
	filters: Array<PhoneNumberFilterInput>;
	input: PhoneNumberUpdateBulkInput;
};

export type MutationUpdateBulkQuotationsArgs = {
	filters: Array<QuotationFilterInput>;
	input: QuotationUpdateBulkInput;
};

export type MutationUpdateBulkRelationshipsArgs = {
	filters: Array<RelationshipFilterInput>;
	input: RelationshipUpdateBulkInput;
};

export type MutationUpdateBulkReportCardsArgs = {
	filters: Array<ReportCardFilterInput>;
	input: ReportCardUpdateBulkInput;
};

export type MutationUpdateBulkRoomsArgs = {
	filters: Array<RoomFilterInput>;
	input: RoomUpdateBulkInput;
};

export type MutationUpdateBulkSchoolAttendanceRecordsArgs = {
	filters: Array<SchoolAttendanceRecordFilterInput>;
	input: SchoolAttendanceRecordUpdateBulkInput;
};

export type MutationUpdateBulkSchoolStudentEnrollmentsArgs = {
	filters: Array<SchoolStudentEnrollmentFilterInput>;
	input: SchoolStudentEnrollmentUpdateBulkInput;
};

export type MutationUpdateBulkServiceSubscriptionsArgs = {
	filters: Array<ServiceSubscriptionFilterInput>;
	input: ServiceSubscriptionUpdateBulkInput;
};

export type MutationUpdateBulkServicesArgs = {
	filters: Array<ServiceFilterInput>;
	input: ServiceUpdateBulkInput;
};

export type MutationUpdateBulkStudentDocumentsArgs = {
	filters: Array<StudentDocumentFilterInput>;
	input: StudentDocumentUpdateBulkInput;
};

export type MutationUpdateBulkStudentsArgs = {
	filters: Array<StudentFilterInput>;
	input: StudentUpdateBulkInput;
};

export type MutationUpdateBulkTermsArgs = {
	filters: Array<TermFilterInput>;
	input: TermUpdateBulkInput;
};

export type MutationUpdateBulkTuitionsArgs = {
	filters: Array<TuitionFilterInput>;
	input: TuitionUpdateBulkInput;
};

export type MutationUpdateBulkUserGroupsArgs = {
	filters: Array<UserGroupFilterInput>;
	input: UserGroupUpdateBulkInput;
};

export type MutationUpdateBulkUsersArgs = {
	filters: Array<UserFilterInput>;
	input: UserUpdateBulkInput;
};

export type MutationUpdateBusArgs = {
	id: Scalars["ID"];
	input: BusUpdateInput;
};

export type MutationUpdateBusPlanArgs = {
	id: Scalars["ID"];
	input: BusPlanUpdateInput;
};

export type MutationUpdateCalendarEventArgs = {
	id: Scalars["ID"];
	input: CalendarEventUpdateInput;
};

export type MutationUpdateCampusArgs = {
	id: Scalars["ID"];
	input: CampusUpdateInput;
};

export type MutationUpdateClassYearArgs = {
	id: Scalars["ID"];
	input: ClassYearUpdateInput;
};

export type MutationUpdateContractArgs = {
	id: Scalars["ID"];
	input: ContractUpdateInput;
};

export type MutationUpdateContractInstallmentArgs = {
	id: Scalars["ID"];
	input: ContractInstallmentUpdateInput;
};

export type MutationUpdateCourseArgs = {
	id: Scalars["ID"];
	input: CourseUpdateInput;
};

export type MutationUpdateCourseAttendanceRecordArgs = {
	id: Scalars["ID"];
	input: CourseAttendanceRecordUpdateInput;
};

export type MutationUpdateCourseSectionArgs = {
	id: Scalars["ID"];
	input: CourseSectionUpdateInput;
};

export type MutationUpdateCourseSectionPeriodArgs = {
	id: Scalars["ID"];
	input: CourseSectionPeriodUpdateInput;
};

export type MutationUpdateCourseSectionStudentEnrollmentArgs = {
	id: Scalars["ID"];
	input: CourseSectionStudentEnrollmentUpdateInput;
};

export type MutationUpdateCourseSectionTeacherEnrollmentArgs = {
	id: Scalars["ID"];
	input: CourseSectionTeacherEnrollmentUpdateInput;
};

export type MutationUpdateDiscountArgs = {
	id: Scalars["ID"];
	input: DiscountUpdateInput;
};

export type MutationUpdateDocumentResourceArgs = {
	id: Scalars["ID"];
	input: DocumentResourceUpdateInput;
};

export type MutationUpdateDocumentResourceFolderArgs = {
	id: Scalars["ID"];
	input: DocumentResourceFolderUpdateInput;
};

export type MutationUpdateEmailAddressArgs = {
	id: Scalars["ID"];
	input: EmailAddressUpdateInput;
};

export type MutationUpdateEmployeeArgs = {
	id: Scalars["ID"];
	input: EmployeeUpdateInput;
};

export type MutationUpdateEmployeeWithPersonArgs = {
	id: Scalars["ID"];
	input: EmployeeUpdateInput;
	personInput: PersonUpdateInput;
};

export type MutationUpdateEnrollmentApplicationArgs = {
	id: Scalars["ID"];
	input: EnrollmentApplicationUpdateInput;
};

export type MutationUpdateEnrollmentApplicationNoteArgs = {
	id: Scalars["ID"];
	input: EnrollmentApplicationNoteUpdateInput;
};

export type MutationUpdateEnrollmentApplicationParentArgs = {
	id: Scalars["ID"];
	input: EnrollmentApplicationParentUpdateInput;
};

export type MutationUpdateEnrollmentApplicationStudentArgs = {
	id: Scalars["ID"];
	input: EnrollmentApplicationStudentUpdateInput;
};

export type MutationUpdateEnrollmentApplicationSubmissionArgs = {
	id: Scalars["ID"];
	input: EnrollmentApplicationSubmissionUpdateInput;
};

export type MutationUpdateEnrollmentInvitationArgs = {
	id: Scalars["ID"];
	input: EnrollmentInvitationUpdateInput;
};

export type MutationUpdateExpenseArgs = {
	id: Scalars["ID"];
	input: ExpenseUpdateInput;
};

export type MutationUpdateExpenseBudgetArgs = {
	id: Scalars["ID"];
	input: ExpenseBudgetUpdateInput;
};

export type MutationUpdateExpenseCategoryArgs = {
	id: Scalars["ID"];
	input: ExpenseCategoryUpdateInput;
};

export type MutationUpdateHomeRoomArgs = {
	id: Scalars["ID"];
	input: HomeRoomUpdateInput;
};

export type MutationUpdateHomeRoomSectionArgs = {
	id: Scalars["ID"];
	input: HomeRoomSectionUpdateInput;
};

export type MutationUpdateHomeRoomSectionStudentEnrollmentArgs = {
	id: Scalars["ID"];
	input: HomeRoomSectionStudentEnrollmentUpdateInput;
};

export type MutationUpdateHomeRoomSectionTeacherEnrollmentArgs = {
	id: Scalars["ID"];
	input: HomeRoomSectionTeacherEnrollmentUpdateInput;
};

export type MutationUpdateInvoiceArgs = {
	id: Scalars["ID"];
	input: InvoiceUpdateInput;
};

export type MutationUpdateInvoiceLineItemArgs = {
	id: Scalars["ID"];
	input: InvoiceLineItemUpdateInput;
};

export type MutationUpdateLearningObjectiveArgs = {
	id: Scalars["ID"];
	input: LearningObjectiveUpdateInput;
};

export type MutationUpdateLearningObjectiveMarkArgs = {
	id: Scalars["ID"];
	input: LearningObjectiveMarkUpdateInput;
};

export type MutationUpdateLessonPlanArgs = {
	id: Scalars["ID"];
	input: LessonPlanUpdateInput;
};

export type MutationUpdateMealMenuArgs = {
	id: Scalars["ID"];
	input: MealMenuUpdateInput;
};

export type MutationUpdateMealPlanArgs = {
	id: Scalars["ID"];
	input: MealPlanUpdateInput;
};

export type MutationUpdateNoteArgs = {
	id: Scalars["ID"];
	input: NoteUpdateInput;
};

export type MutationUpdateNoteAttachmentArgs = {
	id: Scalars["ID"];
	input: NoteAttachmentUpdateInput;
};

export type MutationUpdateNoteCommentArgs = {
	id: Scalars["ID"];
	input: NoteCommentUpdateInput;
};

export type MutationUpdateNoteReactionArgs = {
	id: Scalars["ID"];
	input: NoteReactionUpdateInput;
};

export type MutationUpdateParentArgs = {
	id: Scalars["ID"];
	input: ParentUpdateInput;
};

export type MutationUpdateParentWithPersonArgs = {
	id: Scalars["ID"];
	input: ParentUpdateInput;
	personInput: PersonUpdateInput;
};

export type MutationUpdatePaymentArgs = {
	id: Scalars["ID"];
	input: PaymentUpdateInput;
};

export type MutationUpdatePaymentLineItemArgs = {
	id: Scalars["ID"];
	input: PaymentLineItemUpdateInput;
};

export type MutationUpdatePermissionArgs = {
	id: Scalars["ID"];
	input: PermissionUpdateInput;
};

export type MutationUpdatePermissionGroupArgs = {
	id: Scalars["ID"];
	input: PermissionGroupUpdateInput;
};

export type MutationUpdatePersonArgs = {
	id: Scalars["ID"];
	input: PersonUpdateInput;
};

export type MutationUpdatePhoneNumberArgs = {
	id: Scalars["ID"];
	input: PhoneNumberUpdateInput;
};

export type MutationUpdateQuotationArgs = {
	id: Scalars["ID"];
	input: QuotationUpdateInput;
};

export type MutationUpdateRelationshipArgs = {
	id: Scalars["ID"];
	input: RelationshipUpdateInput;
};

export type MutationUpdateReportCardArgs = {
	id: Scalars["ID"];
	input: ReportCardUpdateInput;
};

export type MutationUpdateRoomArgs = {
	id: Scalars["ID"];
	input: RoomUpdateInput;
};

export type MutationUpdateSchoolAttendanceRecordArgs = {
	id: Scalars["ID"];
	input: SchoolAttendanceRecordUpdateInput;
};

export type MutationUpdateSchoolStudentEnrollmentArgs = {
	id: Scalars["ID"];
	input: SchoolStudentEnrollmentUpdateInput;
};

export type MutationUpdateServiceArgs = {
	id: Scalars["ID"];
	input: ServiceUpdateInput;
};

export type MutationUpdateServiceSubscriptionArgs = {
	id: Scalars["ID"];
	input: ServiceSubscriptionUpdateInput;
};

export type MutationUpdateStudentArgs = {
	id: Scalars["ID"];
	input: StudentUpdateInput;
};

export type MutationUpdateStudentDocumentArgs = {
	id: Scalars["ID"];
	input: StudentDocumentUpdateInput;
};

export type MutationUpdateStudentWithPersonArgs = {
	id: Scalars["ID"];
	input: StudentUpdateInput;
	personInput: PersonUpdateInput;
};

export type MutationUpdateTermArgs = {
	id: Scalars["ID"];
	input: TermUpdateInput;
};

export type MutationUpdateTuitionArgs = {
	id: Scalars["ID"];
	input: TuitionUpdateInput;
};

export type MutationUpdateUserArgs = {
	id: Scalars["ID"];
	input: UserUpdateInput;
};

export type MutationUpdateUserGroupArgs = {
	id: Scalars["ID"];
	input: UserGroupUpdateInput;
};

export type Node = {
	id: Scalars["ID"];
};

export type Note = Node & {
	__typename?: "Note";
	attachments: Array<NoteAttachment>;
	author: Maybe<User>;
	authorID: Maybe<Scalars["ID"]>;
	body: Scalars["String"];
	calendarEvents: Array<CalendarEvent>;
	comments: Array<NoteComment>;
	courseSections: Array<CourseSection>;
	createdAt: Scalars["Time"];
	details: Scalars["String"];
	employees: Array<Employee>;
	endDate: Maybe<Scalars["Time"]>;
	homeRoomSections: Array<HomeRoomSection>;
	id: Scalars["ID"];
	isPublic: Scalars["Boolean"];
	isTimeDependent: Scalars["Boolean"];
	noteType: NoteNoteType;
	parents: Array<Parent>;
	reactions: Array<NoteReaction>;
	sharingUsers: Array<User>;
	startDate: Maybe<Scalars["Time"]>;
	students: Array<Student>;
	title: Scalars["String"];
};

export type NoteAttachmentsArgs = {
	filters?: InputMaybe<Array<NoteAttachmentFilterInput>>;
	order?: InputMaybe<NoteAttachmentOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type NoteCalendarEventsArgs = {
	filters?: InputMaybe<Array<CalendarEventFilterInput>>;
	order?: InputMaybe<CalendarEventOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type NoteCommentsArgs = {
	filters?: InputMaybe<Array<NoteCommentFilterInput>>;
	order?: InputMaybe<NoteCommentOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type NoteCourseSectionsArgs = {
	filters?: InputMaybe<Array<CourseSectionFilterInput>>;
	order?: InputMaybe<CourseSectionOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type NoteEmployeesArgs = {
	filters?: InputMaybe<Array<EmployeeFilterInput>>;
	order?: InputMaybe<EmployeeOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type NoteHomeRoomSectionsArgs = {
	filters?: InputMaybe<Array<HomeRoomSectionFilterInput>>;
	order?: InputMaybe<HomeRoomSectionOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type NoteParentsArgs = {
	filters?: InputMaybe<Array<ParentFilterInput>>;
	order?: InputMaybe<ParentOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type NoteReactionsArgs = {
	filters?: InputMaybe<Array<NoteReactionFilterInput>>;
	order?: InputMaybe<NoteReactionOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type NoteSharingUsersArgs = {
	filters?: InputMaybe<Array<UserFilterInput>>;
	order?: InputMaybe<UserOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type NoteStudentsArgs = {
	filters?: InputMaybe<Array<StudentFilterInput>>;
	order?: InputMaybe<StudentOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type NoteAttachment = Node & {
	__typename?: "NoteAttachment";
	fileContentType: Scalars["String"];
	fileURL: Scalars["String"];
	id: Scalars["ID"];
	isFile: Scalars["Boolean"];
	linkURL: Scalars["String"];
	note: Note;
	noteID: Scalars["ID"];
	order: Scalars["Int"];
	url: Scalars["String"];
};

export type NoteAttachmentConnection = {
	__typename?: "NoteAttachmentConnection";
	edges: Array<NoteAttachmentEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type NoteAttachmentCreateInput = {
	file?: InputMaybe<Scalars["Upload"]>;
	linkURL?: InputMaybe<Scalars["String"]>;
	noteID: Scalars["ID"];
	order?: InputMaybe<Scalars["Int"]>;
};

export type NoteAttachmentEdge = {
	__typename?: "NoteAttachmentEdge";
	cursor: Scalars["Cursor"];
	node: NoteAttachment;
};

export type NoteAttachmentFilterInput = {
	and?: InputMaybe<Array<NoteAttachmentFilterInput>>;
	fileContentTypeContains?: InputMaybe<Scalars["String"]>;
	fileContentTypeEQ?: InputMaybe<Scalars["String"]>;
	fileContentTypeHasPrefix?: InputMaybe<Scalars["String"]>;
	fileContentTypeHasSuffix?: InputMaybe<Scalars["String"]>;
	fileContentTypeIn?: InputMaybe<Array<Scalars["String"]>>;
	fileContentTypeNEQ?: InputMaybe<Scalars["String"]>;
	fileContentTypeNotContains?: InputMaybe<Scalars["String"]>;
	fileContentTypeNotHasPrefix?: InputMaybe<Scalars["String"]>;
	fileContentTypeNotHasSuffix?: InputMaybe<Scalars["String"]>;
	fileContentTypeNotIn?: InputMaybe<Array<Scalars["String"]>>;
	fileIsNull?: InputMaybe<Scalars["Boolean"]>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	linkURLContains?: InputMaybe<Scalars["String"]>;
	linkURLEQ?: InputMaybe<Scalars["String"]>;
	linkURLHasPrefix?: InputMaybe<Scalars["String"]>;
	linkURLHasSuffix?: InputMaybe<Scalars["String"]>;
	linkURLIn?: InputMaybe<Array<Scalars["String"]>>;
	linkURLNEQ?: InputMaybe<Scalars["String"]>;
	linkURLNotContains?: InputMaybe<Scalars["String"]>;
	linkURLNotHasPrefix?: InputMaybe<Scalars["String"]>;
	linkURLNotHasSuffix?: InputMaybe<Scalars["String"]>;
	linkURLNotIn?: InputMaybe<Array<Scalars["String"]>>;
	not?: InputMaybe<NoteAttachmentFilterInput>;
	note?: InputMaybe<Array<NoteFilterInput>>;
	noteIDEQ?: InputMaybe<Scalars["ID"]>;
	noteIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	noteIDNEQ?: InputMaybe<Scalars["ID"]>;
	noteIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	or?: InputMaybe<Array<NoteAttachmentFilterInput>>;
	orderEQ?: InputMaybe<Scalars["Int"]>;
	orderGT?: InputMaybe<Scalars["Int"]>;
	orderGTE?: InputMaybe<Scalars["Int"]>;
	orderIn?: InputMaybe<Array<Scalars["Int"]>>;
	orderLT?: InputMaybe<Scalars["Int"]>;
	orderLTE?: InputMaybe<Scalars["Int"]>;
	orderNEQ?: InputMaybe<Scalars["Int"]>;
	orderNotIn?: InputMaybe<Array<Scalars["Int"]>>;
	search?: InputMaybe<Scalars["String"]>;
};

export type NoteAttachmentHistory = Node & {
	__typename?: "NoteAttachmentHistory";
	fileContentType: Maybe<Scalars["String"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	linkURL: Maybe<Scalars["String"]>;
	noteID: Maybe<Scalars["ID"]>;
	order: Maybe<Scalars["Int"]>;
	originalID: Maybe<Scalars["ID"]>;
};

export type NoteAttachmentHistoryConnection = {
	__typename?: "NoteAttachmentHistoryConnection";
	edges: Array<NoteAttachmentHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type NoteAttachmentHistoryEdge = {
	__typename?: "NoteAttachmentHistoryEdge";
	cursor: Scalars["Cursor"];
	node: NoteAttachmentHistory;
};

export type NoteAttachmentHistoryFilterInput = {
	and?: InputMaybe<Array<NoteAttachmentHistoryFilterInput>>;
	fileContentTypeContains?: InputMaybe<Scalars["String"]>;
	fileContentTypeEQ?: InputMaybe<Scalars["String"]>;
	fileContentTypeHasPrefix?: InputMaybe<Scalars["String"]>;
	fileContentTypeHasSuffix?: InputMaybe<Scalars["String"]>;
	fileContentTypeIn?: InputMaybe<Array<Scalars["String"]>>;
	fileContentTypeIsNull?: InputMaybe<Scalars["Boolean"]>;
	fileContentTypeNEQ?: InputMaybe<Scalars["String"]>;
	fileContentTypeNotContains?: InputMaybe<Scalars["String"]>;
	fileContentTypeNotHasPrefix?: InputMaybe<Scalars["String"]>;
	fileContentTypeNotHasSuffix?: InputMaybe<Scalars["String"]>;
	fileContentTypeNotIn?: InputMaybe<Array<Scalars["String"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	linkURLContains?: InputMaybe<Scalars["String"]>;
	linkURLEQ?: InputMaybe<Scalars["String"]>;
	linkURLHasPrefix?: InputMaybe<Scalars["String"]>;
	linkURLHasSuffix?: InputMaybe<Scalars["String"]>;
	linkURLIn?: InputMaybe<Array<Scalars["String"]>>;
	linkURLIsNull?: InputMaybe<Scalars["Boolean"]>;
	linkURLNEQ?: InputMaybe<Scalars["String"]>;
	linkURLNotContains?: InputMaybe<Scalars["String"]>;
	linkURLNotHasPrefix?: InputMaybe<Scalars["String"]>;
	linkURLNotHasSuffix?: InputMaybe<Scalars["String"]>;
	linkURLNotIn?: InputMaybe<Array<Scalars["String"]>>;
	not?: InputMaybe<NoteAttachmentHistoryFilterInput>;
	noteIDEQ?: InputMaybe<Scalars["ID"]>;
	noteIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	noteIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	noteIDNEQ?: InputMaybe<Scalars["ID"]>;
	noteIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	or?: InputMaybe<Array<NoteAttachmentHistoryFilterInput>>;
	orderEQ?: InputMaybe<Scalars["Int"]>;
	orderGT?: InputMaybe<Scalars["Int"]>;
	orderGTE?: InputMaybe<Scalars["Int"]>;
	orderIn?: InputMaybe<Array<Scalars["Int"]>>;
	orderIsNull?: InputMaybe<Scalars["Boolean"]>;
	orderLT?: InputMaybe<Scalars["Int"]>;
	orderLTE?: InputMaybe<Scalars["Int"]>;
	orderNEQ?: InputMaybe<Scalars["Int"]>;
	orderNotIn?: InputMaybe<Array<Scalars["Int"]>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	search?: InputMaybe<Scalars["String"]>;
};

export type NoteAttachmentHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<NoteAttachmentHistoryOrderField>;
};

export enum NoteAttachmentHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type NoteAttachmentInNoteCreateInput = {
	file?: InputMaybe<Scalars["Upload"]>;
	linkURL?: InputMaybe<Scalars["String"]>;
	order?: InputMaybe<Scalars["Int"]>;
};

export type NoteAttachmentOrder = {
	direction: OrderDirection;
};

export type NoteAttachmentUpdateBulkInput = {
	clearFile?: InputMaybe<Scalars["Boolean"]>;
	file?: InputMaybe<Scalars["Upload"]>;
	linkURL?: InputMaybe<Scalars["String"]>;
	order?: InputMaybe<Scalars["Int"]>;
};

export type NoteAttachmentUpdateInput = {
	clearFile?: InputMaybe<Scalars["Boolean"]>;
	file?: InputMaybe<Scalars["Upload"]>;
	linkURL?: InputMaybe<Scalars["String"]>;
	order?: InputMaybe<Scalars["Int"]>;
};

export type NoteCalendarEventHistory = Node & {
	__typename?: "NoteCalendarEventHistory";
	calendarEventID: Maybe<Scalars["ID"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	noteID: Maybe<Scalars["ID"]>;
};

export type NoteCalendarEventHistoryConnection = {
	__typename?: "NoteCalendarEventHistoryConnection";
	edges: Array<NoteCalendarEventHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type NoteCalendarEventHistoryEdge = {
	__typename?: "NoteCalendarEventHistoryEdge";
	cursor: Scalars["Cursor"];
	node: NoteCalendarEventHistory;
};

export type NoteCalendarEventHistoryFilterInput = {
	and?: InputMaybe<Array<NoteCalendarEventHistoryFilterInput>>;
	calendarEventIDEQ?: InputMaybe<Scalars["ID"]>;
	calendarEventIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	calendarEventIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	calendarEventIDNEQ?: InputMaybe<Scalars["ID"]>;
	calendarEventIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<NoteCalendarEventHistoryFilterInput>;
	noteIDEQ?: InputMaybe<Scalars["ID"]>;
	noteIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	noteIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	noteIDNEQ?: InputMaybe<Scalars["ID"]>;
	noteIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	or?: InputMaybe<Array<NoteCalendarEventHistoryFilterInput>>;
};

export type NoteCalendarEventHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<NoteCalendarEventHistoryOrderField>;
};

export enum NoteCalendarEventHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type NoteComment = Node & {
	__typename?: "NoteComment";
	author: Maybe<User>;
	authorID: Maybe<Scalars["ID"]>;
	body: Scalars["String"];
	createdAt: Scalars["Time"];
	id: Scalars["ID"];
	note: Note;
	noteID: Scalars["ID"];
};

export type NoteCommentConnection = {
	__typename?: "NoteCommentConnection";
	edges: Array<NoteCommentEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type NoteCommentCreateInput = {
	body: Scalars["String"];
	noteID: Scalars["ID"];
};

export type NoteCommentEdge = {
	__typename?: "NoteCommentEdge";
	cursor: Scalars["Cursor"];
	node: NoteComment;
};

export type NoteCommentFilterInput = {
	and?: InputMaybe<Array<NoteCommentFilterInput>>;
	author?: InputMaybe<Array<UserFilterInput>>;
	authorIDEQ?: InputMaybe<Scalars["ID"]>;
	authorIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	authorIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	authorIDNEQ?: InputMaybe<Scalars["ID"]>;
	authorIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	bodyContains?: InputMaybe<Scalars["String"]>;
	bodyEQ?: InputMaybe<Scalars["String"]>;
	bodyHasPrefix?: InputMaybe<Scalars["String"]>;
	bodyHasSuffix?: InputMaybe<Scalars["String"]>;
	bodyIn?: InputMaybe<Array<Scalars["String"]>>;
	bodyNEQ?: InputMaybe<Scalars["String"]>;
	bodyNotContains?: InputMaybe<Scalars["String"]>;
	bodyNotHasPrefix?: InputMaybe<Scalars["String"]>;
	bodyNotHasSuffix?: InputMaybe<Scalars["String"]>;
	bodyNotIn?: InputMaybe<Array<Scalars["String"]>>;
	createdAtEQ?: InputMaybe<Scalars["Time"]>;
	createdAtGT?: InputMaybe<Scalars["Time"]>;
	createdAtGTE?: InputMaybe<Scalars["Time"]>;
	createdAtIn?: InputMaybe<Array<Scalars["Time"]>>;
	createdAtLT?: InputMaybe<Scalars["Time"]>;
	createdAtLTE?: InputMaybe<Scalars["Time"]>;
	createdAtNEQ?: InputMaybe<Scalars["Time"]>;
	createdAtNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	hasAuthor?: InputMaybe<Scalars["Boolean"]>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<NoteCommentFilterInput>;
	note?: InputMaybe<Array<NoteFilterInput>>;
	noteIDEQ?: InputMaybe<Scalars["ID"]>;
	noteIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	noteIDNEQ?: InputMaybe<Scalars["ID"]>;
	noteIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	or?: InputMaybe<Array<NoteCommentFilterInput>>;
	search?: InputMaybe<Scalars["String"]>;
};

export type NoteCommentHistory = Node & {
	__typename?: "NoteCommentHistory";
	authorID: Maybe<Scalars["ID"]>;
	body: Maybe<Scalars["String"]>;
	createdAt: Maybe<Scalars["Time"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	noteID: Maybe<Scalars["ID"]>;
	originalID: Maybe<Scalars["ID"]>;
};

export type NoteCommentHistoryConnection = {
	__typename?: "NoteCommentHistoryConnection";
	edges: Array<NoteCommentHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type NoteCommentHistoryEdge = {
	__typename?: "NoteCommentHistoryEdge";
	cursor: Scalars["Cursor"];
	node: NoteCommentHistory;
};

export type NoteCommentHistoryFilterInput = {
	and?: InputMaybe<Array<NoteCommentHistoryFilterInput>>;
	authorIDEQ?: InputMaybe<Scalars["ID"]>;
	authorIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	authorIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	authorIDNEQ?: InputMaybe<Scalars["ID"]>;
	authorIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	bodyContains?: InputMaybe<Scalars["String"]>;
	bodyEQ?: InputMaybe<Scalars["String"]>;
	bodyHasPrefix?: InputMaybe<Scalars["String"]>;
	bodyHasSuffix?: InputMaybe<Scalars["String"]>;
	bodyIn?: InputMaybe<Array<Scalars["String"]>>;
	bodyIsNull?: InputMaybe<Scalars["Boolean"]>;
	bodyNEQ?: InputMaybe<Scalars["String"]>;
	bodyNotContains?: InputMaybe<Scalars["String"]>;
	bodyNotHasPrefix?: InputMaybe<Scalars["String"]>;
	bodyNotHasSuffix?: InputMaybe<Scalars["String"]>;
	bodyNotIn?: InputMaybe<Array<Scalars["String"]>>;
	createdAtEQ?: InputMaybe<Scalars["Time"]>;
	createdAtGT?: InputMaybe<Scalars["Time"]>;
	createdAtGTE?: InputMaybe<Scalars["Time"]>;
	createdAtIn?: InputMaybe<Array<Scalars["Time"]>>;
	createdAtIsNull?: InputMaybe<Scalars["Boolean"]>;
	createdAtLT?: InputMaybe<Scalars["Time"]>;
	createdAtLTE?: InputMaybe<Scalars["Time"]>;
	createdAtNEQ?: InputMaybe<Scalars["Time"]>;
	createdAtNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<NoteCommentHistoryFilterInput>;
	noteIDEQ?: InputMaybe<Scalars["ID"]>;
	noteIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	noteIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	noteIDNEQ?: InputMaybe<Scalars["ID"]>;
	noteIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	or?: InputMaybe<Array<NoteCommentHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	search?: InputMaybe<Scalars["String"]>;
};

export type NoteCommentHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<NoteCommentHistoryOrderField>;
};

export enum NoteCommentHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type NoteCommentInAuthorCreateInput = {
	body: Scalars["String"];
	noteID: Scalars["ID"];
};

export type NoteCommentInNoteCreateInput = {
	body: Scalars["String"];
};

export type NoteCommentOrder = {
	direction: OrderDirection;
	field?: InputMaybe<NoteCommentOrderField>;
};

export enum NoteCommentOrderField {
	CreatedAt = "CREATED_AT",
}

export type NoteCommentUpdateBulkInput = {
	body?: InputMaybe<Scalars["String"]>;
};

export type NoteCommentUpdateInput = {
	body?: InputMaybe<Scalars["String"]>;
};

export type NoteConnection = {
	__typename?: "NoteConnection";
	edges: Array<NoteEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type NoteCourseSectionHistory = Node & {
	__typename?: "NoteCourseSectionHistory";
	courseSectionID: Maybe<Scalars["ID"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	noteID: Maybe<Scalars["ID"]>;
};

export type NoteCourseSectionHistoryConnection = {
	__typename?: "NoteCourseSectionHistoryConnection";
	edges: Array<NoteCourseSectionHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type NoteCourseSectionHistoryEdge = {
	__typename?: "NoteCourseSectionHistoryEdge";
	cursor: Scalars["Cursor"];
	node: NoteCourseSectionHistory;
};

export type NoteCourseSectionHistoryFilterInput = {
	and?: InputMaybe<Array<NoteCourseSectionHistoryFilterInput>>;
	courseSectionIDEQ?: InputMaybe<Scalars["ID"]>;
	courseSectionIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	courseSectionIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	courseSectionIDNEQ?: InputMaybe<Scalars["ID"]>;
	courseSectionIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<NoteCourseSectionHistoryFilterInput>;
	noteIDEQ?: InputMaybe<Scalars["ID"]>;
	noteIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	noteIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	noteIDNEQ?: InputMaybe<Scalars["ID"]>;
	noteIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	or?: InputMaybe<Array<NoteCourseSectionHistoryFilterInput>>;
};

export type NoteCourseSectionHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<NoteCourseSectionHistoryOrderField>;
};

export enum NoteCourseSectionHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type NoteCreateInput = {
	attachments?: InputMaybe<Array<NoteAttachmentInNoteCreateInput>>;
	body: Scalars["String"];
	calendarEventIDs?: InputMaybe<Array<Scalars["ID"]>>;
	comments?: InputMaybe<Array<NoteCommentInNoteCreateInput>>;
	courseSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	details?: InputMaybe<Scalars["String"]>;
	employeeIDs?: InputMaybe<Array<Scalars["ID"]>>;
	endDate?: InputMaybe<Scalars["Time"]>;
	homeRoomSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	isPublic?: InputMaybe<Scalars["Boolean"]>;
	noteType: NoteNoteType;
	parentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	reactions?: InputMaybe<Array<NoteReactionInNoteCreateInput>>;
	sharingUserIDs?: InputMaybe<Array<Scalars["ID"]>>;
	startDate?: InputMaybe<Scalars["Time"]>;
	studentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	title?: InputMaybe<Scalars["String"]>;
};

export type NoteEdge = {
	__typename?: "NoteEdge";
	cursor: Scalars["Cursor"];
	node: Note;
};

export type NoteEmployeeHistory = Node & {
	__typename?: "NoteEmployeeHistory";
	employeeID: Maybe<Scalars["ID"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	noteID: Maybe<Scalars["ID"]>;
};

export type NoteEmployeeHistoryConnection = {
	__typename?: "NoteEmployeeHistoryConnection";
	edges: Array<NoteEmployeeHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type NoteEmployeeHistoryEdge = {
	__typename?: "NoteEmployeeHistoryEdge";
	cursor: Scalars["Cursor"];
	node: NoteEmployeeHistory;
};

export type NoteEmployeeHistoryFilterInput = {
	and?: InputMaybe<Array<NoteEmployeeHistoryFilterInput>>;
	employeeIDEQ?: InputMaybe<Scalars["ID"]>;
	employeeIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	employeeIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	employeeIDNEQ?: InputMaybe<Scalars["ID"]>;
	employeeIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<NoteEmployeeHistoryFilterInput>;
	noteIDEQ?: InputMaybe<Scalars["ID"]>;
	noteIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	noteIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	noteIDNEQ?: InputMaybe<Scalars["ID"]>;
	noteIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	or?: InputMaybe<Array<NoteEmployeeHistoryFilterInput>>;
};

export type NoteEmployeeHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<NoteEmployeeHistoryOrderField>;
};

export enum NoteEmployeeHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type NoteFilterInput = {
	and?: InputMaybe<Array<NoteFilterInput>>;
	attachments?: InputMaybe<Array<NoteAttachmentFilterInput>>;
	author?: InputMaybe<Array<UserFilterInput>>;
	authorIDEQ?: InputMaybe<Scalars["ID"]>;
	authorIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	authorIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	authorIDNEQ?: InputMaybe<Scalars["ID"]>;
	authorIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	bodyContains?: InputMaybe<Scalars["String"]>;
	bodyEQ?: InputMaybe<Scalars["String"]>;
	bodyHasPrefix?: InputMaybe<Scalars["String"]>;
	bodyHasSuffix?: InputMaybe<Scalars["String"]>;
	bodyIn?: InputMaybe<Array<Scalars["String"]>>;
	bodyNEQ?: InputMaybe<Scalars["String"]>;
	bodyNotContains?: InputMaybe<Scalars["String"]>;
	bodyNotHasPrefix?: InputMaybe<Scalars["String"]>;
	bodyNotHasSuffix?: InputMaybe<Scalars["String"]>;
	bodyNotIn?: InputMaybe<Array<Scalars["String"]>>;
	calendarEvents?: InputMaybe<Array<CalendarEventFilterInput>>;
	comments?: InputMaybe<Array<NoteCommentFilterInput>>;
	courseSections?: InputMaybe<Array<CourseSectionFilterInput>>;
	createdAtEQ?: InputMaybe<Scalars["Time"]>;
	createdAtGT?: InputMaybe<Scalars["Time"]>;
	createdAtGTE?: InputMaybe<Scalars["Time"]>;
	createdAtIn?: InputMaybe<Array<Scalars["Time"]>>;
	createdAtLT?: InputMaybe<Scalars["Time"]>;
	createdAtLTE?: InputMaybe<Scalars["Time"]>;
	createdAtNEQ?: InputMaybe<Scalars["Time"]>;
	createdAtNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	detailsContains?: InputMaybe<Scalars["String"]>;
	detailsEQ?: InputMaybe<Scalars["String"]>;
	detailsHasPrefix?: InputMaybe<Scalars["String"]>;
	detailsHasSuffix?: InputMaybe<Scalars["String"]>;
	detailsIn?: InputMaybe<Array<Scalars["String"]>>;
	detailsNEQ?: InputMaybe<Scalars["String"]>;
	detailsNotContains?: InputMaybe<Scalars["String"]>;
	detailsNotHasPrefix?: InputMaybe<Scalars["String"]>;
	detailsNotHasSuffix?: InputMaybe<Scalars["String"]>;
	detailsNotIn?: InputMaybe<Array<Scalars["String"]>>;
	employees?: InputMaybe<Array<EmployeeFilterInput>>;
	endDateEQ?: InputMaybe<Scalars["Time"]>;
	endDateGT?: InputMaybe<Scalars["Time"]>;
	endDateGTE?: InputMaybe<Scalars["Time"]>;
	endDateIn?: InputMaybe<Array<Scalars["Time"]>>;
	endDateIsNull?: InputMaybe<Scalars["Boolean"]>;
	endDateLT?: InputMaybe<Scalars["Time"]>;
	endDateLTE?: InputMaybe<Scalars["Time"]>;
	endDateNEQ?: InputMaybe<Scalars["Time"]>;
	endDateNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	hasAttachments?: InputMaybe<Scalars["Boolean"]>;
	hasAuthor?: InputMaybe<Scalars["Boolean"]>;
	hasCalendarEvents?: InputMaybe<Scalars["Boolean"]>;
	hasComments?: InputMaybe<Scalars["Boolean"]>;
	hasCourseSections?: InputMaybe<Scalars["Boolean"]>;
	hasEmployees?: InputMaybe<Scalars["Boolean"]>;
	hasHomeRoomSections?: InputMaybe<Scalars["Boolean"]>;
	hasParents?: InputMaybe<Scalars["Boolean"]>;
	hasReactions?: InputMaybe<Scalars["Boolean"]>;
	hasSharingUsers?: InputMaybe<Scalars["Boolean"]>;
	hasStudents?: InputMaybe<Scalars["Boolean"]>;
	homeRoomSections?: InputMaybe<Array<HomeRoomSectionFilterInput>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	isPublicEQ?: InputMaybe<Scalars["Boolean"]>;
	isPublicNEQ?: InputMaybe<Scalars["Boolean"]>;
	not?: InputMaybe<NoteFilterInput>;
	noteTypeEQ?: InputMaybe<NoteNoteType>;
	noteTypeIn?: InputMaybe<Array<NoteNoteType>>;
	noteTypeNEQ?: InputMaybe<NoteNoteType>;
	noteTypeNotIn?: InputMaybe<Array<NoteNoteType>>;
	or?: InputMaybe<Array<NoteFilterInput>>;
	parents?: InputMaybe<Array<ParentFilterInput>>;
	reactions?: InputMaybe<Array<NoteReactionFilterInput>>;
	search?: InputMaybe<Scalars["String"]>;
	sharingUsers?: InputMaybe<Array<UserFilterInput>>;
	startDateEQ?: InputMaybe<Scalars["Time"]>;
	startDateGT?: InputMaybe<Scalars["Time"]>;
	startDateGTE?: InputMaybe<Scalars["Time"]>;
	startDateIn?: InputMaybe<Array<Scalars["Time"]>>;
	startDateIsNull?: InputMaybe<Scalars["Boolean"]>;
	startDateLT?: InputMaybe<Scalars["Time"]>;
	startDateLTE?: InputMaybe<Scalars["Time"]>;
	startDateNEQ?: InputMaybe<Scalars["Time"]>;
	startDateNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	students?: InputMaybe<Array<StudentFilterInput>>;
	titleContains?: InputMaybe<Scalars["String"]>;
	titleEQ?: InputMaybe<Scalars["String"]>;
	titleHasPrefix?: InputMaybe<Scalars["String"]>;
	titleHasSuffix?: InputMaybe<Scalars["String"]>;
	titleIn?: InputMaybe<Array<Scalars["String"]>>;
	titleNEQ?: InputMaybe<Scalars["String"]>;
	titleNotContains?: InputMaybe<Scalars["String"]>;
	titleNotHasPrefix?: InputMaybe<Scalars["String"]>;
	titleNotHasSuffix?: InputMaybe<Scalars["String"]>;
	titleNotIn?: InputMaybe<Array<Scalars["String"]>>;
};

export type NoteHistory = Node & {
	__typename?: "NoteHistory";
	authorID: Maybe<Scalars["ID"]>;
	body: Maybe<Scalars["String"]>;
	createdAt: Maybe<Scalars["Time"]>;
	details: Maybe<Scalars["String"]>;
	endDate: Maybe<Scalars["Time"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	isPublic: Maybe<Scalars["Boolean"]>;
	noteType: Maybe<NoteHistoryNoteType>;
	originalID: Maybe<Scalars["ID"]>;
	startDate: Maybe<Scalars["Time"]>;
	title: Maybe<Scalars["String"]>;
};

export type NoteHistoryConnection = {
	__typename?: "NoteHistoryConnection";
	edges: Array<NoteHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type NoteHistoryEdge = {
	__typename?: "NoteHistoryEdge";
	cursor: Scalars["Cursor"];
	node: NoteHistory;
};

export type NoteHistoryFilterInput = {
	and?: InputMaybe<Array<NoteHistoryFilterInput>>;
	authorIDEQ?: InputMaybe<Scalars["ID"]>;
	authorIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	authorIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	authorIDNEQ?: InputMaybe<Scalars["ID"]>;
	authorIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	bodyContains?: InputMaybe<Scalars["String"]>;
	bodyEQ?: InputMaybe<Scalars["String"]>;
	bodyHasPrefix?: InputMaybe<Scalars["String"]>;
	bodyHasSuffix?: InputMaybe<Scalars["String"]>;
	bodyIn?: InputMaybe<Array<Scalars["String"]>>;
	bodyIsNull?: InputMaybe<Scalars["Boolean"]>;
	bodyNEQ?: InputMaybe<Scalars["String"]>;
	bodyNotContains?: InputMaybe<Scalars["String"]>;
	bodyNotHasPrefix?: InputMaybe<Scalars["String"]>;
	bodyNotHasSuffix?: InputMaybe<Scalars["String"]>;
	bodyNotIn?: InputMaybe<Array<Scalars["String"]>>;
	createdAtEQ?: InputMaybe<Scalars["Time"]>;
	createdAtGT?: InputMaybe<Scalars["Time"]>;
	createdAtGTE?: InputMaybe<Scalars["Time"]>;
	createdAtIn?: InputMaybe<Array<Scalars["Time"]>>;
	createdAtIsNull?: InputMaybe<Scalars["Boolean"]>;
	createdAtLT?: InputMaybe<Scalars["Time"]>;
	createdAtLTE?: InputMaybe<Scalars["Time"]>;
	createdAtNEQ?: InputMaybe<Scalars["Time"]>;
	createdAtNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	detailsContains?: InputMaybe<Scalars["String"]>;
	detailsEQ?: InputMaybe<Scalars["String"]>;
	detailsHasPrefix?: InputMaybe<Scalars["String"]>;
	detailsHasSuffix?: InputMaybe<Scalars["String"]>;
	detailsIn?: InputMaybe<Array<Scalars["String"]>>;
	detailsIsNull?: InputMaybe<Scalars["Boolean"]>;
	detailsNEQ?: InputMaybe<Scalars["String"]>;
	detailsNotContains?: InputMaybe<Scalars["String"]>;
	detailsNotHasPrefix?: InputMaybe<Scalars["String"]>;
	detailsNotHasSuffix?: InputMaybe<Scalars["String"]>;
	detailsNotIn?: InputMaybe<Array<Scalars["String"]>>;
	endDateEQ?: InputMaybe<Scalars["Time"]>;
	endDateGT?: InputMaybe<Scalars["Time"]>;
	endDateGTE?: InputMaybe<Scalars["Time"]>;
	endDateIn?: InputMaybe<Array<Scalars["Time"]>>;
	endDateIsNull?: InputMaybe<Scalars["Boolean"]>;
	endDateLT?: InputMaybe<Scalars["Time"]>;
	endDateLTE?: InputMaybe<Scalars["Time"]>;
	endDateNEQ?: InputMaybe<Scalars["Time"]>;
	endDateNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	isPublicEQ?: InputMaybe<Scalars["Boolean"]>;
	isPublicIsNull?: InputMaybe<Scalars["Boolean"]>;
	isPublicNEQ?: InputMaybe<Scalars["Boolean"]>;
	not?: InputMaybe<NoteHistoryFilterInput>;
	noteTypeEQ?: InputMaybe<NoteHistoryNoteType>;
	noteTypeIn?: InputMaybe<Array<NoteHistoryNoteType>>;
	noteTypeIsNull?: InputMaybe<Scalars["Boolean"]>;
	noteTypeNEQ?: InputMaybe<NoteHistoryNoteType>;
	noteTypeNotIn?: InputMaybe<Array<NoteHistoryNoteType>>;
	or?: InputMaybe<Array<NoteHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	search?: InputMaybe<Scalars["String"]>;
	startDateEQ?: InputMaybe<Scalars["Time"]>;
	startDateGT?: InputMaybe<Scalars["Time"]>;
	startDateGTE?: InputMaybe<Scalars["Time"]>;
	startDateIn?: InputMaybe<Array<Scalars["Time"]>>;
	startDateIsNull?: InputMaybe<Scalars["Boolean"]>;
	startDateLT?: InputMaybe<Scalars["Time"]>;
	startDateLTE?: InputMaybe<Scalars["Time"]>;
	startDateNEQ?: InputMaybe<Scalars["Time"]>;
	startDateNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	titleContains?: InputMaybe<Scalars["String"]>;
	titleEQ?: InputMaybe<Scalars["String"]>;
	titleHasPrefix?: InputMaybe<Scalars["String"]>;
	titleHasSuffix?: InputMaybe<Scalars["String"]>;
	titleIn?: InputMaybe<Array<Scalars["String"]>>;
	titleIsNull?: InputMaybe<Scalars["Boolean"]>;
	titleNEQ?: InputMaybe<Scalars["String"]>;
	titleNotContains?: InputMaybe<Scalars["String"]>;
	titleNotHasPrefix?: InputMaybe<Scalars["String"]>;
	titleNotHasSuffix?: InputMaybe<Scalars["String"]>;
	titleNotIn?: InputMaybe<Array<Scalars["String"]>>;
};

export enum NoteHistoryNoteType {
	Academic = "academic",
	Announcement = "announcement",
	Attendance = "attendance",
	Behavior = "behavior",
	Financial = "financial",
	General = "general",
	Health = "health",
	Incident = "incident",
	Injury = "injury",
	Post = "post",
	SocialEmotional = "social_emotional",
}

export type NoteHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<NoteHistoryOrderField>;
};

export enum NoteHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type NoteHomeRoomSectionHistory = Node & {
	__typename?: "NoteHomeRoomSectionHistory";
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	homeRoomSectionID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	noteID: Maybe<Scalars["ID"]>;
};

export type NoteHomeRoomSectionHistoryConnection = {
	__typename?: "NoteHomeRoomSectionHistoryConnection";
	edges: Array<NoteHomeRoomSectionHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type NoteHomeRoomSectionHistoryEdge = {
	__typename?: "NoteHomeRoomSectionHistoryEdge";
	cursor: Scalars["Cursor"];
	node: NoteHomeRoomSectionHistory;
};

export type NoteHomeRoomSectionHistoryFilterInput = {
	and?: InputMaybe<Array<NoteHomeRoomSectionHistoryFilterInput>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	homeRoomSectionIDEQ?: InputMaybe<Scalars["ID"]>;
	homeRoomSectionIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	homeRoomSectionIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	homeRoomSectionIDNEQ?: InputMaybe<Scalars["ID"]>;
	homeRoomSectionIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<NoteHomeRoomSectionHistoryFilterInput>;
	noteIDEQ?: InputMaybe<Scalars["ID"]>;
	noteIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	noteIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	noteIDNEQ?: InputMaybe<Scalars["ID"]>;
	noteIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	or?: InputMaybe<Array<NoteHomeRoomSectionHistoryFilterInput>>;
};

export type NoteHomeRoomSectionHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<NoteHomeRoomSectionHistoryOrderField>;
};

export enum NoteHomeRoomSectionHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type NoteInAuthorCreateInput = {
	attachments?: InputMaybe<Array<NoteAttachmentInNoteCreateInput>>;
	body: Scalars["String"];
	calendarEventIDs?: InputMaybe<Array<Scalars["ID"]>>;
	comments?: InputMaybe<Array<NoteCommentInNoteCreateInput>>;
	courseSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	details?: InputMaybe<Scalars["String"]>;
	employeeIDs?: InputMaybe<Array<Scalars["ID"]>>;
	endDate?: InputMaybe<Scalars["Time"]>;
	homeRoomSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	isPublic?: InputMaybe<Scalars["Boolean"]>;
	noteType: NoteNoteType;
	parentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	reactions?: InputMaybe<Array<NoteReactionInNoteCreateInput>>;
	sharingUserIDs?: InputMaybe<Array<Scalars["ID"]>>;
	startDate?: InputMaybe<Scalars["Time"]>;
	studentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	title?: InputMaybe<Scalars["String"]>;
};

export enum NoteNoteType {
	Academic = "academic",
	Announcement = "announcement",
	Attendance = "attendance",
	Behavior = "behavior",
	Financial = "financial",
	General = "general",
	Health = "health",
	Incident = "incident",
	Injury = "injury",
	Post = "post",
	SocialEmotional = "social_emotional",
}

export type NoteOrder = {
	direction: OrderDirection;
	field?: InputMaybe<NoteOrderField>;
};

export enum NoteOrderField {
	CreatedAt = "CREATED_AT",
	EndDate = "END_DATE",
	NoteType = "NOTE_TYPE",
	StartDate = "START_DATE",
}

export type NoteParentHistory = Node & {
	__typename?: "NoteParentHistory";
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	noteID: Maybe<Scalars["ID"]>;
	parentID: Maybe<Scalars["ID"]>;
};

export type NoteParentHistoryConnection = {
	__typename?: "NoteParentHistoryConnection";
	edges: Array<NoteParentHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type NoteParentHistoryEdge = {
	__typename?: "NoteParentHistoryEdge";
	cursor: Scalars["Cursor"];
	node: NoteParentHistory;
};

export type NoteParentHistoryFilterInput = {
	and?: InputMaybe<Array<NoteParentHistoryFilterInput>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<NoteParentHistoryFilterInput>;
	noteIDEQ?: InputMaybe<Scalars["ID"]>;
	noteIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	noteIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	noteIDNEQ?: InputMaybe<Scalars["ID"]>;
	noteIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	or?: InputMaybe<Array<NoteParentHistoryFilterInput>>;
	parentIDEQ?: InputMaybe<Scalars["ID"]>;
	parentIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	parentIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	parentIDNEQ?: InputMaybe<Scalars["ID"]>;
	parentIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type NoteParentHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<NoteParentHistoryOrderField>;
};

export enum NoteParentHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type NoteReaction = Node & {
	__typename?: "NoteReaction";
	author: Maybe<User>;
	authorID: Maybe<Scalars["ID"]>;
	createdAt: Scalars["Time"];
	id: Scalars["ID"];
	note: Note;
	noteID: Scalars["ID"];
	reactionType: NoteReactionReactionType;
};

export type NoteReactionConnection = {
	__typename?: "NoteReactionConnection";
	edges: Array<NoteReactionEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type NoteReactionCreateInput = {
	noteID: Scalars["ID"];
	reactionType?: InputMaybe<NoteReactionReactionType>;
};

export type NoteReactionEdge = {
	__typename?: "NoteReactionEdge";
	cursor: Scalars["Cursor"];
	node: NoteReaction;
};

export type NoteReactionFilterInput = {
	and?: InputMaybe<Array<NoteReactionFilterInput>>;
	author?: InputMaybe<Array<UserFilterInput>>;
	authorIDEQ?: InputMaybe<Scalars["ID"]>;
	authorIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	authorIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	authorIDNEQ?: InputMaybe<Scalars["ID"]>;
	authorIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	createdAtEQ?: InputMaybe<Scalars["Time"]>;
	createdAtGT?: InputMaybe<Scalars["Time"]>;
	createdAtGTE?: InputMaybe<Scalars["Time"]>;
	createdAtIn?: InputMaybe<Array<Scalars["Time"]>>;
	createdAtLT?: InputMaybe<Scalars["Time"]>;
	createdAtLTE?: InputMaybe<Scalars["Time"]>;
	createdAtNEQ?: InputMaybe<Scalars["Time"]>;
	createdAtNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	hasAuthor?: InputMaybe<Scalars["Boolean"]>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<NoteReactionFilterInput>;
	note?: InputMaybe<Array<NoteFilterInput>>;
	noteIDEQ?: InputMaybe<Scalars["ID"]>;
	noteIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	noteIDNEQ?: InputMaybe<Scalars["ID"]>;
	noteIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	or?: InputMaybe<Array<NoteReactionFilterInput>>;
	reactionTypeEQ?: InputMaybe<NoteReactionReactionType>;
	reactionTypeIn?: InputMaybe<Array<NoteReactionReactionType>>;
	reactionTypeNEQ?: InputMaybe<NoteReactionReactionType>;
	reactionTypeNotIn?: InputMaybe<Array<NoteReactionReactionType>>;
};

export type NoteReactionHistory = Node & {
	__typename?: "NoteReactionHistory";
	authorID: Maybe<Scalars["ID"]>;
	createdAt: Maybe<Scalars["Time"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	noteID: Maybe<Scalars["ID"]>;
	originalID: Maybe<Scalars["ID"]>;
	reactionType: Maybe<NoteReactionHistoryReactionType>;
};

export type NoteReactionHistoryConnection = {
	__typename?: "NoteReactionHistoryConnection";
	edges: Array<NoteReactionHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type NoteReactionHistoryEdge = {
	__typename?: "NoteReactionHistoryEdge";
	cursor: Scalars["Cursor"];
	node: NoteReactionHistory;
};

export type NoteReactionHistoryFilterInput = {
	and?: InputMaybe<Array<NoteReactionHistoryFilterInput>>;
	authorIDEQ?: InputMaybe<Scalars["ID"]>;
	authorIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	authorIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	authorIDNEQ?: InputMaybe<Scalars["ID"]>;
	authorIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	createdAtEQ?: InputMaybe<Scalars["Time"]>;
	createdAtGT?: InputMaybe<Scalars["Time"]>;
	createdAtGTE?: InputMaybe<Scalars["Time"]>;
	createdAtIn?: InputMaybe<Array<Scalars["Time"]>>;
	createdAtIsNull?: InputMaybe<Scalars["Boolean"]>;
	createdAtLT?: InputMaybe<Scalars["Time"]>;
	createdAtLTE?: InputMaybe<Scalars["Time"]>;
	createdAtNEQ?: InputMaybe<Scalars["Time"]>;
	createdAtNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<NoteReactionHistoryFilterInput>;
	noteIDEQ?: InputMaybe<Scalars["ID"]>;
	noteIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	noteIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	noteIDNEQ?: InputMaybe<Scalars["ID"]>;
	noteIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	or?: InputMaybe<Array<NoteReactionHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	reactionTypeEQ?: InputMaybe<NoteReactionHistoryReactionType>;
	reactionTypeIn?: InputMaybe<Array<NoteReactionHistoryReactionType>>;
	reactionTypeIsNull?: InputMaybe<Scalars["Boolean"]>;
	reactionTypeNEQ?: InputMaybe<NoteReactionHistoryReactionType>;
	reactionTypeNotIn?: InputMaybe<Array<NoteReactionHistoryReactionType>>;
};

export type NoteReactionHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<NoteReactionHistoryOrderField>;
};

export enum NoteReactionHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export enum NoteReactionHistoryReactionType {
	Like = "like",
}

export type NoteReactionInAuthorCreateInput = {
	noteID: Scalars["ID"];
	reactionType?: InputMaybe<NoteReactionReactionType>;
};

export type NoteReactionInNoteCreateInput = {
	reactionType?: InputMaybe<NoteReactionReactionType>;
};

export type NoteReactionOrder = {
	direction: OrderDirection;
	field?: InputMaybe<NoteReactionOrderField>;
};

export enum NoteReactionOrderField {
	CreatedAt = "CREATED_AT",
	ReactionType = "REACTION_TYPE",
}

export enum NoteReactionReactionType {
	Like = "like",
}

export type NoteReactionUpdateBulkInput = {
	reactionType?: InputMaybe<NoteReactionReactionType>;
};

export type NoteReactionUpdateInput = {
	reactionType?: InputMaybe<NoteReactionReactionType>;
};

export type NoteSharedNoteUserSharingUserHistory = Node & {
	__typename?: "NoteSharedNoteUserSharingUserHistory";
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	noteID: Maybe<Scalars["ID"]>;
	userID: Maybe<Scalars["ID"]>;
};

export type NoteSharedNoteUserSharingUserHistoryConnection = {
	__typename?: "NoteSharedNoteUserSharingUserHistoryConnection";
	edges: Array<NoteSharedNoteUserSharingUserHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type NoteSharedNoteUserSharingUserHistoryEdge = {
	__typename?: "NoteSharedNoteUserSharingUserHistoryEdge";
	cursor: Scalars["Cursor"];
	node: NoteSharedNoteUserSharingUserHistory;
};

export type NoteSharedNoteUserSharingUserHistoryFilterInput = {
	and?: InputMaybe<Array<NoteSharedNoteUserSharingUserHistoryFilterInput>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<NoteSharedNoteUserSharingUserHistoryFilterInput>;
	noteIDEQ?: InputMaybe<Scalars["ID"]>;
	noteIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	noteIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	noteIDNEQ?: InputMaybe<Scalars["ID"]>;
	noteIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	or?: InputMaybe<Array<NoteSharedNoteUserSharingUserHistoryFilterInput>>;
	userIDEQ?: InputMaybe<Scalars["ID"]>;
	userIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	userIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	userIDNEQ?: InputMaybe<Scalars["ID"]>;
	userIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type NoteSharedNoteUserSharingUserHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<NoteSharedNoteUserSharingUserHistoryOrderField>;
};

export enum NoteSharedNoteUserSharingUserHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type NoteStudentHistory = Node & {
	__typename?: "NoteStudentHistory";
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	noteID: Maybe<Scalars["ID"]>;
	studentID: Maybe<Scalars["ID"]>;
};

export type NoteStudentHistoryConnection = {
	__typename?: "NoteStudentHistoryConnection";
	edges: Array<NoteStudentHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type NoteStudentHistoryEdge = {
	__typename?: "NoteStudentHistoryEdge";
	cursor: Scalars["Cursor"];
	node: NoteStudentHistory;
};

export type NoteStudentHistoryFilterInput = {
	and?: InputMaybe<Array<NoteStudentHistoryFilterInput>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<NoteStudentHistoryFilterInput>;
	noteIDEQ?: InputMaybe<Scalars["ID"]>;
	noteIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	noteIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	noteIDNEQ?: InputMaybe<Scalars["ID"]>;
	noteIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	or?: InputMaybe<Array<NoteStudentHistoryFilterInput>>;
	studentIDEQ?: InputMaybe<Scalars["ID"]>;
	studentIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	studentIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	studentIDNEQ?: InputMaybe<Scalars["ID"]>;
	studentIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type NoteStudentHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<NoteStudentHistoryOrderField>;
};

export enum NoteStudentHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type NoteUpdateBulkInput = {
	addCalendarEventIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addCourseSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addEmployeeIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addHomeRoomSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addParentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addSharingUserIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addStudentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	body?: InputMaybe<Scalars["String"]>;
	clearEndDate?: InputMaybe<Scalars["Boolean"]>;
	clearStartDate?: InputMaybe<Scalars["Boolean"]>;
	details?: InputMaybe<Scalars["String"]>;
	endDate?: InputMaybe<Scalars["Time"]>;
	isPublic?: InputMaybe<Scalars["Boolean"]>;
	removeCalendarEventIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeCourseSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeEmployeeIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeHomeRoomSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeParentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeSharingUserIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeStudentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	startDate?: InputMaybe<Scalars["Time"]>;
	title?: InputMaybe<Scalars["String"]>;
};

export type NoteUpdateInput = {
	addCalendarEventIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addCourseSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addEmployeeIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addHomeRoomSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addParentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addSharingUserIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addStudentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	body?: InputMaybe<Scalars["String"]>;
	clearEndDate?: InputMaybe<Scalars["Boolean"]>;
	clearStartDate?: InputMaybe<Scalars["Boolean"]>;
	details?: InputMaybe<Scalars["String"]>;
	endDate?: InputMaybe<Scalars["Time"]>;
	isPublic?: InputMaybe<Scalars["Boolean"]>;
	removeCalendarEventIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeCourseSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeEmployeeIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeHomeRoomSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeParentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeSharingUserIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeStudentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	startDate?: InputMaybe<Scalars["Time"]>;
	title?: InputMaybe<Scalars["String"]>;
};

export enum OrderDirection {
	Asc = "ASC",
	Desc = "DESC",
}

export type PageInfo = {
	__typename?: "PageInfo";
	endCursor: Maybe<Scalars["Cursor"]>;
	hasNextPage: Scalars["Boolean"];
	hasPreviousPage: Scalars["Boolean"];
	startCursor: Maybe<Scalars["Cursor"]>;
};

export type Pagination = {
	after?: InputMaybe<Scalars["Cursor"]>;
	before?: InputMaybe<Scalars["Cursor"]>;
	first?: InputMaybe<Scalars["Int"]>;
	last?: InputMaybe<Scalars["Int"]>;
};

export type Parent = Node & {
	__typename?: "Parent";
	enrollmentApplicationParent: Maybe<EnrollmentApplicationParent>;
	id: Scalars["ID"];
	notes: Array<Note>;
	payments: Array<Payment>;
	person: Person;
	personID: Scalars["ID"];
	relationships: Array<Relationship>;
};

export type ParentNotesArgs = {
	filters?: InputMaybe<Array<NoteFilterInput>>;
	order?: InputMaybe<NoteOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type ParentPaymentsArgs = {
	filters?: InputMaybe<Array<PaymentFilterInput>>;
	order?: InputMaybe<PaymentOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type ParentRelationshipsArgs = {
	filters?: InputMaybe<Array<RelationshipFilterInput>>;
	order?: InputMaybe<RelationshipOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type ParentConnection = {
	__typename?: "ParentConnection";
	edges: Array<ParentEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type ParentCreateInput = {
	enrollmentApplicationParent?: InputMaybe<EnrollmentApplicationParentInParentCreateInput>;
	enrollmentApplicationParentID?: InputMaybe<Scalars["ID"]>;
	noteIDs?: InputMaybe<Array<Scalars["ID"]>>;
	paymentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	payments?: InputMaybe<Array<PaymentInParentCreateInput>>;
	person?: InputMaybe<PersonInParentCreateInput>;
	personID?: InputMaybe<Scalars["ID"]>;
	relationshipIDs?: InputMaybe<Array<Scalars["ID"]>>;
	relationships?: InputMaybe<Array<RelationshipInParentCreateInput>>;
};

export type ParentEdge = {
	__typename?: "ParentEdge";
	cursor: Scalars["Cursor"];
	node: Parent;
};

export type ParentFilterInput = {
	and?: InputMaybe<Array<ParentFilterInput>>;
	enrollmentApplicationParent?: InputMaybe<Array<EnrollmentApplicationParentFilterInput>>;
	hasEnrollmentApplicationParent?: InputMaybe<Scalars["Boolean"]>;
	hasNotes?: InputMaybe<Scalars["Boolean"]>;
	hasPayments?: InputMaybe<Scalars["Boolean"]>;
	hasRelationships?: InputMaybe<Scalars["Boolean"]>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<ParentFilterInput>;
	notes?: InputMaybe<Array<NoteFilterInput>>;
	or?: InputMaybe<Array<ParentFilterInput>>;
	payments?: InputMaybe<Array<PaymentFilterInput>>;
	person?: InputMaybe<Array<PersonFilterInput>>;
	personIDEQ?: InputMaybe<Scalars["ID"]>;
	personIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	personIDNEQ?: InputMaybe<Scalars["ID"]>;
	personIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	relationships?: InputMaybe<Array<RelationshipFilterInput>>;
};

export type ParentHistory = Node & {
	__typename?: "ParentHistory";
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	originalID: Maybe<Scalars["ID"]>;
	personID: Maybe<Scalars["ID"]>;
};

export type ParentHistoryConnection = {
	__typename?: "ParentHistoryConnection";
	edges: Array<ParentHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type ParentHistoryEdge = {
	__typename?: "ParentHistoryEdge";
	cursor: Scalars["Cursor"];
	node: ParentHistory;
};

export type ParentHistoryFilterInput = {
	and?: InputMaybe<Array<ParentHistoryFilterInput>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<ParentHistoryFilterInput>;
	or?: InputMaybe<Array<ParentHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	personIDEQ?: InputMaybe<Scalars["ID"]>;
	personIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	personIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	personIDNEQ?: InputMaybe<Scalars["ID"]>;
	personIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type ParentHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<ParentHistoryOrderField>;
};

export enum ParentHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type ParentInEnrollmentApplicationParentCreateInput = {
	noteIDs?: InputMaybe<Array<Scalars["ID"]>>;
	paymentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	payments?: InputMaybe<Array<PaymentInParentCreateInput>>;
	person?: InputMaybe<PersonInParentCreateInput>;
	personID?: InputMaybe<Scalars["ID"]>;
	relationshipIDs?: InputMaybe<Array<Scalars["ID"]>>;
	relationships?: InputMaybe<Array<RelationshipInParentCreateInput>>;
};

export type ParentInPersonCreateInput = {
	enrollmentApplicationParent?: InputMaybe<EnrollmentApplicationParentInParentCreateInput>;
	enrollmentApplicationParentID?: InputMaybe<Scalars["ID"]>;
	noteIDs?: InputMaybe<Array<Scalars["ID"]>>;
	paymentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	payments?: InputMaybe<Array<PaymentInParentCreateInput>>;
	relationshipIDs?: InputMaybe<Array<Scalars["ID"]>>;
	relationships?: InputMaybe<Array<RelationshipInParentCreateInput>>;
};

export type ParentOrder = {
	direction: OrderDirection;
};

export type ParentUpdateBulkInput = {
	addNoteIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addPaymentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addRelationshipIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeNoteIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removePaymentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeRelationshipIDs?: InputMaybe<Array<Scalars["ID"]>>;
};

export type ParentUpdateInput = {
	addNoteIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addPaymentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addRelationshipIDs?: InputMaybe<Array<Scalars["ID"]>>;
	clearEnrollmentApplicationParent?: InputMaybe<Scalars["Boolean"]>;
	enrollmentApplicationParentID?: InputMaybe<Scalars["ID"]>;
	personID?: InputMaybe<Scalars["ID"]>;
	removeNoteIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removePaymentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeRelationshipIDs?: InputMaybe<Array<Scalars["ID"]>>;
};

export type Payment = Node & {
	__typename?: "Payment";
	createdDate: Scalars["Time"];
	id: Scalars["ID"];
	notes: Scalars["String"];
	parent: Parent;
	parentID: Scalars["ID"];
	paymentLineItems: Array<PaymentLineItem>;
	paymentMethod: PaymentPaymentMethod;
};

export type PaymentPaymentLineItemsArgs = {
	filters?: InputMaybe<Array<PaymentLineItemFilterInput>>;
	order?: InputMaybe<PaymentLineItemOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type PaymentConnection = {
	__typename?: "PaymentConnection";
	edges: Array<PaymentEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type PaymentCreateInput = {
	createdDate: Scalars["Time"];
	notes?: InputMaybe<Scalars["String"]>;
	parentID: Scalars["ID"];
	paymentLineItemIDs?: InputMaybe<Array<Scalars["ID"]>>;
	paymentLineItems?: InputMaybe<Array<PaymentLineItemInPaymentCreateInput>>;
	paymentMethod: PaymentPaymentMethod;
};

export type PaymentEdge = {
	__typename?: "PaymentEdge";
	cursor: Scalars["Cursor"];
	node: Payment;
};

export type PaymentFilterInput = {
	and?: InputMaybe<Array<PaymentFilterInput>>;
	createdDateEQ?: InputMaybe<Scalars["Time"]>;
	createdDateGT?: InputMaybe<Scalars["Time"]>;
	createdDateGTE?: InputMaybe<Scalars["Time"]>;
	createdDateIn?: InputMaybe<Array<Scalars["Time"]>>;
	createdDateLT?: InputMaybe<Scalars["Time"]>;
	createdDateLTE?: InputMaybe<Scalars["Time"]>;
	createdDateNEQ?: InputMaybe<Scalars["Time"]>;
	createdDateNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	hasPaymentLineItems?: InputMaybe<Scalars["Boolean"]>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<PaymentFilterInput>;
	notesContains?: InputMaybe<Scalars["String"]>;
	notesEQ?: InputMaybe<Scalars["String"]>;
	notesHasPrefix?: InputMaybe<Scalars["String"]>;
	notesHasSuffix?: InputMaybe<Scalars["String"]>;
	notesIn?: InputMaybe<Array<Scalars["String"]>>;
	notesNEQ?: InputMaybe<Scalars["String"]>;
	notesNotContains?: InputMaybe<Scalars["String"]>;
	notesNotHasPrefix?: InputMaybe<Scalars["String"]>;
	notesNotHasSuffix?: InputMaybe<Scalars["String"]>;
	notesNotIn?: InputMaybe<Array<Scalars["String"]>>;
	or?: InputMaybe<Array<PaymentFilterInput>>;
	parent?: InputMaybe<Array<ParentFilterInput>>;
	parentIDEQ?: InputMaybe<Scalars["ID"]>;
	parentIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	parentIDNEQ?: InputMaybe<Scalars["ID"]>;
	parentIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	paymentLineItems?: InputMaybe<Array<PaymentLineItemFilterInput>>;
	paymentMethodEQ?: InputMaybe<PaymentPaymentMethod>;
	paymentMethodIn?: InputMaybe<Array<PaymentPaymentMethod>>;
	paymentMethodNEQ?: InputMaybe<PaymentPaymentMethod>;
	paymentMethodNotIn?: InputMaybe<Array<PaymentPaymentMethod>>;
	search?: InputMaybe<Scalars["String"]>;
};

export type PaymentHistory = Node & {
	__typename?: "PaymentHistory";
	createdDate: Maybe<Scalars["Time"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	notes: Maybe<Scalars["String"]>;
	originalID: Maybe<Scalars["ID"]>;
	parentID: Maybe<Scalars["ID"]>;
	paymentMethod: Maybe<PaymentHistoryPaymentMethod>;
};

export type PaymentHistoryConnection = {
	__typename?: "PaymentHistoryConnection";
	edges: Array<PaymentHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type PaymentHistoryEdge = {
	__typename?: "PaymentHistoryEdge";
	cursor: Scalars["Cursor"];
	node: PaymentHistory;
};

export type PaymentHistoryFilterInput = {
	and?: InputMaybe<Array<PaymentHistoryFilterInput>>;
	createdDateEQ?: InputMaybe<Scalars["Time"]>;
	createdDateGT?: InputMaybe<Scalars["Time"]>;
	createdDateGTE?: InputMaybe<Scalars["Time"]>;
	createdDateIn?: InputMaybe<Array<Scalars["Time"]>>;
	createdDateIsNull?: InputMaybe<Scalars["Boolean"]>;
	createdDateLT?: InputMaybe<Scalars["Time"]>;
	createdDateLTE?: InputMaybe<Scalars["Time"]>;
	createdDateNEQ?: InputMaybe<Scalars["Time"]>;
	createdDateNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<PaymentHistoryFilterInput>;
	notesContains?: InputMaybe<Scalars["String"]>;
	notesEQ?: InputMaybe<Scalars["String"]>;
	notesHasPrefix?: InputMaybe<Scalars["String"]>;
	notesHasSuffix?: InputMaybe<Scalars["String"]>;
	notesIn?: InputMaybe<Array<Scalars["String"]>>;
	notesIsNull?: InputMaybe<Scalars["Boolean"]>;
	notesNEQ?: InputMaybe<Scalars["String"]>;
	notesNotContains?: InputMaybe<Scalars["String"]>;
	notesNotHasPrefix?: InputMaybe<Scalars["String"]>;
	notesNotHasSuffix?: InputMaybe<Scalars["String"]>;
	notesNotIn?: InputMaybe<Array<Scalars["String"]>>;
	or?: InputMaybe<Array<PaymentHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	parentIDEQ?: InputMaybe<Scalars["ID"]>;
	parentIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	parentIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	parentIDNEQ?: InputMaybe<Scalars["ID"]>;
	parentIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	paymentMethodEQ?: InputMaybe<PaymentHistoryPaymentMethod>;
	paymentMethodIn?: InputMaybe<Array<PaymentHistoryPaymentMethod>>;
	paymentMethodIsNull?: InputMaybe<Scalars["Boolean"]>;
	paymentMethodNEQ?: InputMaybe<PaymentHistoryPaymentMethod>;
	paymentMethodNotIn?: InputMaybe<Array<PaymentHistoryPaymentMethod>>;
	search?: InputMaybe<Scalars["String"]>;
};

export type PaymentHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<PaymentHistoryOrderField>;
};

export enum PaymentHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export enum PaymentHistoryPaymentMethod {
	BankTransfer = "bank_transfer",
	Cash = "cash",
	Check = "check",
	CreditCard = "credit_card",
}

export type PaymentInParentCreateInput = {
	createdDate: Scalars["Time"];
	notes?: InputMaybe<Scalars["String"]>;
	paymentLineItemIDs?: InputMaybe<Array<Scalars["ID"]>>;
	paymentLineItems?: InputMaybe<Array<PaymentLineItemInPaymentCreateInput>>;
	paymentMethod: PaymentPaymentMethod;
};

export type PaymentLineItem = Node & {
	__typename?: "PaymentLineItem";
	amount: Scalars["Int"];
	id: Scalars["ID"];
	invoice: Invoice;
	invoiceID: Scalars["ID"];
	payment: Payment;
	paymentID: Scalars["ID"];
};

export type PaymentLineItemConnection = {
	__typename?: "PaymentLineItemConnection";
	edges: Array<PaymentLineItemEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type PaymentLineItemCreateInput = {
	amount: Scalars["Int"];
	invoiceID: Scalars["ID"];
	paymentID: Scalars["ID"];
};

export type PaymentLineItemEdge = {
	__typename?: "PaymentLineItemEdge";
	cursor: Scalars["Cursor"];
	node: PaymentLineItem;
};

export type PaymentLineItemFilterInput = {
	amountEQ?: InputMaybe<Scalars["Int"]>;
	amountGT?: InputMaybe<Scalars["Int"]>;
	amountGTE?: InputMaybe<Scalars["Int"]>;
	amountIn?: InputMaybe<Array<Scalars["Int"]>>;
	amountLT?: InputMaybe<Scalars["Int"]>;
	amountLTE?: InputMaybe<Scalars["Int"]>;
	amountNEQ?: InputMaybe<Scalars["Int"]>;
	amountNotIn?: InputMaybe<Array<Scalars["Int"]>>;
	and?: InputMaybe<Array<PaymentLineItemFilterInput>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	invoice?: InputMaybe<Array<InvoiceFilterInput>>;
	invoiceIDEQ?: InputMaybe<Scalars["ID"]>;
	invoiceIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	invoiceIDNEQ?: InputMaybe<Scalars["ID"]>;
	invoiceIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<PaymentLineItemFilterInput>;
	or?: InputMaybe<Array<PaymentLineItemFilterInput>>;
	payment?: InputMaybe<Array<PaymentFilterInput>>;
	paymentIDEQ?: InputMaybe<Scalars["ID"]>;
	paymentIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	paymentIDNEQ?: InputMaybe<Scalars["ID"]>;
	paymentIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type PaymentLineItemHistory = Node & {
	__typename?: "PaymentLineItemHistory";
	amount: Maybe<Scalars["Int"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	invoiceID: Maybe<Scalars["ID"]>;
	originalID: Maybe<Scalars["ID"]>;
	paymentID: Maybe<Scalars["ID"]>;
};

export type PaymentLineItemHistoryConnection = {
	__typename?: "PaymentLineItemHistoryConnection";
	edges: Array<PaymentLineItemHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type PaymentLineItemHistoryEdge = {
	__typename?: "PaymentLineItemHistoryEdge";
	cursor: Scalars["Cursor"];
	node: PaymentLineItemHistory;
};

export type PaymentLineItemHistoryFilterInput = {
	amountEQ?: InputMaybe<Scalars["Int"]>;
	amountGT?: InputMaybe<Scalars["Int"]>;
	amountGTE?: InputMaybe<Scalars["Int"]>;
	amountIn?: InputMaybe<Array<Scalars["Int"]>>;
	amountIsNull?: InputMaybe<Scalars["Boolean"]>;
	amountLT?: InputMaybe<Scalars["Int"]>;
	amountLTE?: InputMaybe<Scalars["Int"]>;
	amountNEQ?: InputMaybe<Scalars["Int"]>;
	amountNotIn?: InputMaybe<Array<Scalars["Int"]>>;
	and?: InputMaybe<Array<PaymentLineItemHistoryFilterInput>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	invoiceIDEQ?: InputMaybe<Scalars["ID"]>;
	invoiceIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	invoiceIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	invoiceIDNEQ?: InputMaybe<Scalars["ID"]>;
	invoiceIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<PaymentLineItemHistoryFilterInput>;
	or?: InputMaybe<Array<PaymentLineItemHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	paymentIDEQ?: InputMaybe<Scalars["ID"]>;
	paymentIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	paymentIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	paymentIDNEQ?: InputMaybe<Scalars["ID"]>;
	paymentIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type PaymentLineItemHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<PaymentLineItemHistoryOrderField>;
};

export enum PaymentLineItemHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type PaymentLineItemInInvoiceCreateInput = {
	amount: Scalars["Int"];
	paymentID: Scalars["ID"];
};

export type PaymentLineItemInPaymentCreateInput = {
	amount: Scalars["Int"];
	invoiceID: Scalars["ID"];
};

export type PaymentLineItemOrder = {
	direction: OrderDirection;
	field?: InputMaybe<PaymentLineItemOrderField>;
};

export enum PaymentLineItemOrderField {
	Amount = "AMOUNT",
}

export type PaymentLineItemUpdateBulkInput = {
	amount?: InputMaybe<Scalars["Int"]>;
	invoiceID?: InputMaybe<Scalars["ID"]>;
	paymentID?: InputMaybe<Scalars["ID"]>;
};

export type PaymentLineItemUpdateInput = {
	amount?: InputMaybe<Scalars["Int"]>;
	invoiceID?: InputMaybe<Scalars["ID"]>;
	paymentID?: InputMaybe<Scalars["ID"]>;
};

export type PaymentOrder = {
	direction: OrderDirection;
	field?: InputMaybe<PaymentOrderField>;
};

export enum PaymentOrderField {
	CreatedDate = "CREATED_DATE",
}

export enum PaymentPaymentMethod {
	BankTransfer = "bank_transfer",
	Cash = "cash",
	Check = "check",
	CreditCard = "credit_card",
}

export type PaymentUpdateBulkInput = {
	addPaymentLineItemIDs?: InputMaybe<Array<Scalars["ID"]>>;
	createdDate?: InputMaybe<Scalars["Time"]>;
	notes?: InputMaybe<Scalars["String"]>;
	parentID?: InputMaybe<Scalars["ID"]>;
	paymentMethod?: InputMaybe<PaymentPaymentMethod>;
	removePaymentLineItemIDs?: InputMaybe<Array<Scalars["ID"]>>;
};

export type PaymentUpdateInput = {
	addPaymentLineItemIDs?: InputMaybe<Array<Scalars["ID"]>>;
	createdDate?: InputMaybe<Scalars["Time"]>;
	notes?: InputMaybe<Scalars["String"]>;
	parentID?: InputMaybe<Scalars["ID"]>;
	paymentMethod?: InputMaybe<PaymentPaymentMethod>;
	removePaymentLineItemIDs?: InputMaybe<Array<Scalars["ID"]>>;
};

export type Permission = Node & {
	__typename?: "Permission";
	action: Scalars["String"];
	description: Scalars["String"];
	family: Scalars["String"];
	id: Scalars["ID"];
	level: Scalars["String"];
	name: Scalars["String"];
	permissionGroups: Array<PermissionGroup>;
};

export type PermissionPermissionGroupsArgs = {
	filters?: InputMaybe<Array<PermissionGroupFilterInput>>;
	order?: InputMaybe<PermissionGroupOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type PermissionConnection = {
	__typename?: "PermissionConnection";
	edges: Array<PermissionEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type PermissionEdge = {
	__typename?: "PermissionEdge";
	cursor: Scalars["Cursor"];
	node: Permission;
};

export type PermissionFilterInput = {
	actionContains?: InputMaybe<Scalars["String"]>;
	actionEQ?: InputMaybe<Scalars["String"]>;
	actionHasPrefix?: InputMaybe<Scalars["String"]>;
	actionHasSuffix?: InputMaybe<Scalars["String"]>;
	actionIn?: InputMaybe<Array<Scalars["String"]>>;
	actionNEQ?: InputMaybe<Scalars["String"]>;
	actionNotContains?: InputMaybe<Scalars["String"]>;
	actionNotHasPrefix?: InputMaybe<Scalars["String"]>;
	actionNotHasSuffix?: InputMaybe<Scalars["String"]>;
	actionNotIn?: InputMaybe<Array<Scalars["String"]>>;
	and?: InputMaybe<Array<PermissionFilterInput>>;
	familyContains?: InputMaybe<Scalars["String"]>;
	familyEQ?: InputMaybe<Scalars["String"]>;
	familyHasPrefix?: InputMaybe<Scalars["String"]>;
	familyHasSuffix?: InputMaybe<Scalars["String"]>;
	familyIn?: InputMaybe<Array<Scalars["String"]>>;
	familyNEQ?: InputMaybe<Scalars["String"]>;
	familyNotContains?: InputMaybe<Scalars["String"]>;
	familyNotHasPrefix?: InputMaybe<Scalars["String"]>;
	familyNotHasSuffix?: InputMaybe<Scalars["String"]>;
	familyNotIn?: InputMaybe<Array<Scalars["String"]>>;
	hasPermissionGroups?: InputMaybe<Scalars["Boolean"]>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	levelContains?: InputMaybe<Scalars["String"]>;
	levelEQ?: InputMaybe<Scalars["String"]>;
	levelHasPrefix?: InputMaybe<Scalars["String"]>;
	levelHasSuffix?: InputMaybe<Scalars["String"]>;
	levelIn?: InputMaybe<Array<Scalars["String"]>>;
	levelNEQ?: InputMaybe<Scalars["String"]>;
	levelNotContains?: InputMaybe<Scalars["String"]>;
	levelNotHasPrefix?: InputMaybe<Scalars["String"]>;
	levelNotHasSuffix?: InputMaybe<Scalars["String"]>;
	levelNotIn?: InputMaybe<Array<Scalars["String"]>>;
	not?: InputMaybe<PermissionFilterInput>;
	or?: InputMaybe<Array<PermissionFilterInput>>;
	permissionGroups?: InputMaybe<Array<PermissionGroupFilterInput>>;
	search?: InputMaybe<Scalars["String"]>;
};

export type PermissionGroup = Node & {
	__typename?: "PermissionGroup";
	description: Scalars["String"];
	id: Scalars["ID"];
	name: Scalars["String"];
	permissions: Array<Permission>;
	users: Array<User>;
};

export type PermissionGroupPermissionsArgs = {
	filters?: InputMaybe<Array<PermissionFilterInput>>;
	order?: InputMaybe<PermissionOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type PermissionGroupUsersArgs = {
	filters?: InputMaybe<Array<UserFilterInput>>;
	order?: InputMaybe<UserOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type PermissionGroupConnection = {
	__typename?: "PermissionGroupConnection";
	edges: Array<PermissionGroupEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type PermissionGroupCreateInput = {
	description?: InputMaybe<Scalars["String"]>;
	name: Scalars["String"];
	permissionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	userIDs?: InputMaybe<Array<Scalars["ID"]>>;
};

export type PermissionGroupEdge = {
	__typename?: "PermissionGroupEdge";
	cursor: Scalars["Cursor"];
	node: PermissionGroup;
};

export type PermissionGroupFilterInput = {
	and?: InputMaybe<Array<PermissionGroupFilterInput>>;
	descriptionContains?: InputMaybe<Scalars["String"]>;
	descriptionEQ?: InputMaybe<Scalars["String"]>;
	descriptionHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionIn?: InputMaybe<Array<Scalars["String"]>>;
	descriptionNEQ?: InputMaybe<Scalars["String"]>;
	descriptionNotContains?: InputMaybe<Scalars["String"]>;
	descriptionNotHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionNotHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionNotIn?: InputMaybe<Array<Scalars["String"]>>;
	hasPermissions?: InputMaybe<Scalars["Boolean"]>;
	hasUsers?: InputMaybe<Scalars["Boolean"]>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	nameContains?: InputMaybe<Scalars["String"]>;
	nameEQ?: InputMaybe<Scalars["String"]>;
	nameHasPrefix?: InputMaybe<Scalars["String"]>;
	nameHasSuffix?: InputMaybe<Scalars["String"]>;
	nameIn?: InputMaybe<Array<Scalars["String"]>>;
	nameNEQ?: InputMaybe<Scalars["String"]>;
	nameNotContains?: InputMaybe<Scalars["String"]>;
	nameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	nameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	nameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	not?: InputMaybe<PermissionGroupFilterInput>;
	or?: InputMaybe<Array<PermissionGroupFilterInput>>;
	permissions?: InputMaybe<Array<PermissionFilterInput>>;
	search?: InputMaybe<Scalars["String"]>;
	users?: InputMaybe<Array<UserFilterInput>>;
};

export type PermissionGroupHistory = Node & {
	__typename?: "PermissionGroupHistory";
	description: Maybe<Scalars["String"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	name: Maybe<Scalars["String"]>;
	originalID: Maybe<Scalars["ID"]>;
};

export type PermissionGroupHistoryConnection = {
	__typename?: "PermissionGroupHistoryConnection";
	edges: Array<PermissionGroupHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type PermissionGroupHistoryEdge = {
	__typename?: "PermissionGroupHistoryEdge";
	cursor: Scalars["Cursor"];
	node: PermissionGroupHistory;
};

export type PermissionGroupHistoryFilterInput = {
	and?: InputMaybe<Array<PermissionGroupHistoryFilterInput>>;
	descriptionContains?: InputMaybe<Scalars["String"]>;
	descriptionEQ?: InputMaybe<Scalars["String"]>;
	descriptionHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionIn?: InputMaybe<Array<Scalars["String"]>>;
	descriptionIsNull?: InputMaybe<Scalars["Boolean"]>;
	descriptionNEQ?: InputMaybe<Scalars["String"]>;
	descriptionNotContains?: InputMaybe<Scalars["String"]>;
	descriptionNotHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionNotHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionNotIn?: InputMaybe<Array<Scalars["String"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	nameContains?: InputMaybe<Scalars["String"]>;
	nameEQ?: InputMaybe<Scalars["String"]>;
	nameHasPrefix?: InputMaybe<Scalars["String"]>;
	nameHasSuffix?: InputMaybe<Scalars["String"]>;
	nameIn?: InputMaybe<Array<Scalars["String"]>>;
	nameIsNull?: InputMaybe<Scalars["Boolean"]>;
	nameNEQ?: InputMaybe<Scalars["String"]>;
	nameNotContains?: InputMaybe<Scalars["String"]>;
	nameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	nameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	nameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	not?: InputMaybe<PermissionGroupHistoryFilterInput>;
	or?: InputMaybe<Array<PermissionGroupHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	search?: InputMaybe<Scalars["String"]>;
};

export type PermissionGroupHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<PermissionGroupHistoryOrderField>;
};

export enum PermissionGroupHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type PermissionGroupOrder = {
	direction: OrderDirection;
	field?: InputMaybe<PermissionGroupOrderField>;
};

export enum PermissionGroupOrderField {
	Name = "NAME",
}

export type PermissionGroupPermissionHistory = Node & {
	__typename?: "PermissionGroupPermissionHistory";
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	permissionGroupID: Maybe<Scalars["ID"]>;
	permissionID: Maybe<Scalars["ID"]>;
};

export type PermissionGroupPermissionHistoryConnection = {
	__typename?: "PermissionGroupPermissionHistoryConnection";
	edges: Array<PermissionGroupPermissionHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type PermissionGroupPermissionHistoryEdge = {
	__typename?: "PermissionGroupPermissionHistoryEdge";
	cursor: Scalars["Cursor"];
	node: PermissionGroupPermissionHistory;
};

export type PermissionGroupPermissionHistoryFilterInput = {
	and?: InputMaybe<Array<PermissionGroupPermissionHistoryFilterInput>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<PermissionGroupPermissionHistoryFilterInput>;
	or?: InputMaybe<Array<PermissionGroupPermissionHistoryFilterInput>>;
	permissionGroupIDEQ?: InputMaybe<Scalars["ID"]>;
	permissionGroupIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	permissionGroupIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	permissionGroupIDNEQ?: InputMaybe<Scalars["ID"]>;
	permissionGroupIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	permissionIDEQ?: InputMaybe<Scalars["ID"]>;
	permissionIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	permissionIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	permissionIDNEQ?: InputMaybe<Scalars["ID"]>;
	permissionIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type PermissionGroupPermissionHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<PermissionGroupPermissionHistoryOrderField>;
};

export enum PermissionGroupPermissionHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type PermissionGroupUpdateBulkInput = {
	addPermissionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addUserIDs?: InputMaybe<Array<Scalars["ID"]>>;
	description?: InputMaybe<Scalars["String"]>;
	removePermissionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeUserIDs?: InputMaybe<Array<Scalars["ID"]>>;
};

export type PermissionGroupUpdateInput = {
	addPermissionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addUserIDs?: InputMaybe<Array<Scalars["ID"]>>;
	description?: InputMaybe<Scalars["String"]>;
	name?: InputMaybe<Scalars["String"]>;
	removePermissionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeUserIDs?: InputMaybe<Array<Scalars["ID"]>>;
};

export type PermissionGroupUserHistory = Node & {
	__typename?: "PermissionGroupUserHistory";
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	permissionGroupID: Maybe<Scalars["ID"]>;
	userID: Maybe<Scalars["ID"]>;
};

export type PermissionGroupUserHistoryConnection = {
	__typename?: "PermissionGroupUserHistoryConnection";
	edges: Array<PermissionGroupUserHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type PermissionGroupUserHistoryEdge = {
	__typename?: "PermissionGroupUserHistoryEdge";
	cursor: Scalars["Cursor"];
	node: PermissionGroupUserHistory;
};

export type PermissionGroupUserHistoryFilterInput = {
	and?: InputMaybe<Array<PermissionGroupUserHistoryFilterInput>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<PermissionGroupUserHistoryFilterInput>;
	or?: InputMaybe<Array<PermissionGroupUserHistoryFilterInput>>;
	permissionGroupIDEQ?: InputMaybe<Scalars["ID"]>;
	permissionGroupIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	permissionGroupIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	permissionGroupIDNEQ?: InputMaybe<Scalars["ID"]>;
	permissionGroupIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	userIDEQ?: InputMaybe<Scalars["ID"]>;
	userIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	userIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	userIDNEQ?: InputMaybe<Scalars["ID"]>;
	userIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type PermissionGroupUserHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<PermissionGroupUserHistoryOrderField>;
};

export enum PermissionGroupUserHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type PermissionHistory = Node & {
	__typename?: "PermissionHistory";
	action: Maybe<Scalars["String"]>;
	family: Maybe<Scalars["String"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	level: Maybe<Scalars["String"]>;
	originalID: Maybe<Scalars["ID"]>;
};

export type PermissionHistoryConnection = {
	__typename?: "PermissionHistoryConnection";
	edges: Array<PermissionHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type PermissionHistoryEdge = {
	__typename?: "PermissionHistoryEdge";
	cursor: Scalars["Cursor"];
	node: PermissionHistory;
};

export type PermissionHistoryFilterInput = {
	actionContains?: InputMaybe<Scalars["String"]>;
	actionEQ?: InputMaybe<Scalars["String"]>;
	actionHasPrefix?: InputMaybe<Scalars["String"]>;
	actionHasSuffix?: InputMaybe<Scalars["String"]>;
	actionIn?: InputMaybe<Array<Scalars["String"]>>;
	actionIsNull?: InputMaybe<Scalars["Boolean"]>;
	actionNEQ?: InputMaybe<Scalars["String"]>;
	actionNotContains?: InputMaybe<Scalars["String"]>;
	actionNotHasPrefix?: InputMaybe<Scalars["String"]>;
	actionNotHasSuffix?: InputMaybe<Scalars["String"]>;
	actionNotIn?: InputMaybe<Array<Scalars["String"]>>;
	and?: InputMaybe<Array<PermissionHistoryFilterInput>>;
	familyContains?: InputMaybe<Scalars["String"]>;
	familyEQ?: InputMaybe<Scalars["String"]>;
	familyHasPrefix?: InputMaybe<Scalars["String"]>;
	familyHasSuffix?: InputMaybe<Scalars["String"]>;
	familyIn?: InputMaybe<Array<Scalars["String"]>>;
	familyIsNull?: InputMaybe<Scalars["Boolean"]>;
	familyNEQ?: InputMaybe<Scalars["String"]>;
	familyNotContains?: InputMaybe<Scalars["String"]>;
	familyNotHasPrefix?: InputMaybe<Scalars["String"]>;
	familyNotHasSuffix?: InputMaybe<Scalars["String"]>;
	familyNotIn?: InputMaybe<Array<Scalars["String"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	levelContains?: InputMaybe<Scalars["String"]>;
	levelEQ?: InputMaybe<Scalars["String"]>;
	levelHasPrefix?: InputMaybe<Scalars["String"]>;
	levelHasSuffix?: InputMaybe<Scalars["String"]>;
	levelIn?: InputMaybe<Array<Scalars["String"]>>;
	levelIsNull?: InputMaybe<Scalars["Boolean"]>;
	levelNEQ?: InputMaybe<Scalars["String"]>;
	levelNotContains?: InputMaybe<Scalars["String"]>;
	levelNotHasPrefix?: InputMaybe<Scalars["String"]>;
	levelNotHasSuffix?: InputMaybe<Scalars["String"]>;
	levelNotIn?: InputMaybe<Array<Scalars["String"]>>;
	not?: InputMaybe<PermissionHistoryFilterInput>;
	or?: InputMaybe<Array<PermissionHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	search?: InputMaybe<Scalars["String"]>;
};

export type PermissionHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<PermissionHistoryOrderField>;
};

export enum PermissionHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type PermissionOrder = {
	direction: OrderDirection;
	field?: InputMaybe<PermissionOrderField>;
};

export enum PermissionOrderField {
	Action = "ACTION",
	Family = "FAMILY",
	Level = "LEVEL",
}

export type PermissionUpdateBulkInput = {
	addPermissionGroupIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removePermissionGroupIDs?: InputMaybe<Array<Scalars["ID"]>>;
};

export type PermissionUpdateInput = {
	addPermissionGroupIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removePermissionGroupIDs?: InputMaybe<Array<Scalars["ID"]>>;
};

export type Person = Node & {
	__typename?: "Person";
	addresses: Array<Address>;
	alternateNames: Scalars["String"];
	dateOfBirth: Maybe<Scalars["Time"]>;
	emailAddresses: Array<EmailAddress>;
	employee: Maybe<Employee>;
	englishLanguageFluency: PersonEnglishLanguageFluency;
	firstName: Scalars["String"];
	gender: Scalars["String"];
	id: Scalars["ID"];
	imageContentType: Scalars["String"];
	imageURL: Scalars["String"];
	lastName: Scalars["String"];
	name: Scalars["String"];
	nationality: Scalars["String"];
	nickname: Scalars["String"];
	occupation: Scalars["String"];
	parent: Maybe<Parent>;
	phoneNumbers: Array<PhoneNumber>;
	primaryLanguage: Scalars["String"];
	student: Maybe<Student>;
	user: Maybe<User>;
	userID: Maybe<Scalars["ID"]>;
};

export type PersonAddressesArgs = {
	filters?: InputMaybe<Array<AddressFilterInput>>;
	order?: InputMaybe<AddressOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type PersonEmailAddressesArgs = {
	filters?: InputMaybe<Array<EmailAddressFilterInput>>;
	order?: InputMaybe<EmailAddressOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type PersonPhoneNumbersArgs = {
	filters?: InputMaybe<Array<PhoneNumberFilterInput>>;
	order?: InputMaybe<PhoneNumberOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type PersonAddressHistory = Node & {
	__typename?: "PersonAddressHistory";
	addressID: Maybe<Scalars["ID"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	personID: Maybe<Scalars["ID"]>;
};

export type PersonAddressHistoryConnection = {
	__typename?: "PersonAddressHistoryConnection";
	edges: Array<PersonAddressHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type PersonAddressHistoryEdge = {
	__typename?: "PersonAddressHistoryEdge";
	cursor: Scalars["Cursor"];
	node: PersonAddressHistory;
};

export type PersonAddressHistoryFilterInput = {
	addressIDEQ?: InputMaybe<Scalars["ID"]>;
	addressIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	addressIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	addressIDNEQ?: InputMaybe<Scalars["ID"]>;
	addressIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	and?: InputMaybe<Array<PersonAddressHistoryFilterInput>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<PersonAddressHistoryFilterInput>;
	or?: InputMaybe<Array<PersonAddressHistoryFilterInput>>;
	personIDEQ?: InputMaybe<Scalars["ID"]>;
	personIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	personIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	personIDNEQ?: InputMaybe<Scalars["ID"]>;
	personIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type PersonAddressHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<PersonAddressHistoryOrderField>;
};

export enum PersonAddressHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type PersonConnection = {
	__typename?: "PersonConnection";
	edges: Array<PersonEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type PersonCreateInput = {
	addressIDs?: InputMaybe<Array<Scalars["ID"]>>;
	alternateNames?: InputMaybe<Scalars["String"]>;
	dateOfBirth?: InputMaybe<Scalars["Time"]>;
	emailAddressIDs?: InputMaybe<Array<Scalars["ID"]>>;
	emailAddresses?: InputMaybe<Array<EmailAddressInPersonCreateInput>>;
	employee?: InputMaybe<EmployeeInPersonCreateInput>;
	employeeID?: InputMaybe<Scalars["ID"]>;
	englishLanguageFluency?: InputMaybe<PersonEnglishLanguageFluency>;
	firstName: Scalars["String"];
	gender?: InputMaybe<Scalars["String"]>;
	image?: InputMaybe<Scalars["Upload"]>;
	lastName: Scalars["String"];
	nationality?: InputMaybe<Scalars["String"]>;
	nickname?: InputMaybe<Scalars["String"]>;
	occupation?: InputMaybe<Scalars["String"]>;
	parent?: InputMaybe<ParentInPersonCreateInput>;
	parentID?: InputMaybe<Scalars["ID"]>;
	phoneNumberIDs?: InputMaybe<Array<Scalars["ID"]>>;
	phoneNumbers?: InputMaybe<Array<PhoneNumberInPersonCreateInput>>;
	primaryLanguage?: InputMaybe<Scalars["String"]>;
	student?: InputMaybe<StudentInPersonCreateInput>;
	studentID?: InputMaybe<Scalars["ID"]>;
	user?: InputMaybe<UserInPersonCreateInput>;
	userID?: InputMaybe<Scalars["ID"]>;
};

export type PersonEdge = {
	__typename?: "PersonEdge";
	cursor: Scalars["Cursor"];
	node: Person;
};

export enum PersonEnglishLanguageFluency {
	Average = "average",
	Basic = "basic",
	Excellent = "excellent",
	None = "none",
	NotSpecified = "not_specified",
}

export type PersonFilterInput = {
	addresses?: InputMaybe<Array<AddressFilterInput>>;
	alternateNamesContains?: InputMaybe<Scalars["String"]>;
	alternateNamesEQ?: InputMaybe<Scalars["String"]>;
	alternateNamesHasPrefix?: InputMaybe<Scalars["String"]>;
	alternateNamesHasSuffix?: InputMaybe<Scalars["String"]>;
	alternateNamesIn?: InputMaybe<Array<Scalars["String"]>>;
	alternateNamesNEQ?: InputMaybe<Scalars["String"]>;
	alternateNamesNotContains?: InputMaybe<Scalars["String"]>;
	alternateNamesNotHasPrefix?: InputMaybe<Scalars["String"]>;
	alternateNamesNotHasSuffix?: InputMaybe<Scalars["String"]>;
	alternateNamesNotIn?: InputMaybe<Array<Scalars["String"]>>;
	and?: InputMaybe<Array<PersonFilterInput>>;
	dateOfBirthEQ?: InputMaybe<Scalars["Time"]>;
	dateOfBirthGT?: InputMaybe<Scalars["Time"]>;
	dateOfBirthGTE?: InputMaybe<Scalars["Time"]>;
	dateOfBirthIn?: InputMaybe<Array<Scalars["Time"]>>;
	dateOfBirthIsNull?: InputMaybe<Scalars["Boolean"]>;
	dateOfBirthLT?: InputMaybe<Scalars["Time"]>;
	dateOfBirthLTE?: InputMaybe<Scalars["Time"]>;
	dateOfBirthNEQ?: InputMaybe<Scalars["Time"]>;
	dateOfBirthNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	emailAddresses?: InputMaybe<Array<EmailAddressFilterInput>>;
	employee?: InputMaybe<Array<EmployeeFilterInput>>;
	englishLanguageFluencyEQ?: InputMaybe<PersonEnglishLanguageFluency>;
	englishLanguageFluencyIn?: InputMaybe<Array<PersonEnglishLanguageFluency>>;
	englishLanguageFluencyNEQ?: InputMaybe<PersonEnglishLanguageFluency>;
	englishLanguageFluencyNotIn?: InputMaybe<Array<PersonEnglishLanguageFluency>>;
	firstNameContains?: InputMaybe<Scalars["String"]>;
	firstNameEQ?: InputMaybe<Scalars["String"]>;
	firstNameHasPrefix?: InputMaybe<Scalars["String"]>;
	firstNameHasSuffix?: InputMaybe<Scalars["String"]>;
	firstNameIn?: InputMaybe<Array<Scalars["String"]>>;
	firstNameNEQ?: InputMaybe<Scalars["String"]>;
	firstNameNotContains?: InputMaybe<Scalars["String"]>;
	firstNameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	firstNameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	firstNameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	genderContains?: InputMaybe<Scalars["String"]>;
	genderEQ?: InputMaybe<Scalars["String"]>;
	genderHasPrefix?: InputMaybe<Scalars["String"]>;
	genderHasSuffix?: InputMaybe<Scalars["String"]>;
	genderIn?: InputMaybe<Array<Scalars["String"]>>;
	genderNEQ?: InputMaybe<Scalars["String"]>;
	genderNotContains?: InputMaybe<Scalars["String"]>;
	genderNotHasPrefix?: InputMaybe<Scalars["String"]>;
	genderNotHasSuffix?: InputMaybe<Scalars["String"]>;
	genderNotIn?: InputMaybe<Array<Scalars["String"]>>;
	hasAddresses?: InputMaybe<Scalars["Boolean"]>;
	hasEmailAddresses?: InputMaybe<Scalars["Boolean"]>;
	hasEmployee?: InputMaybe<Scalars["Boolean"]>;
	hasParent?: InputMaybe<Scalars["Boolean"]>;
	hasPhoneNumbers?: InputMaybe<Scalars["Boolean"]>;
	hasStudent?: InputMaybe<Scalars["Boolean"]>;
	hasUser?: InputMaybe<Scalars["Boolean"]>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	imageContentTypeContains?: InputMaybe<Scalars["String"]>;
	imageContentTypeEQ?: InputMaybe<Scalars["String"]>;
	imageContentTypeHasPrefix?: InputMaybe<Scalars["String"]>;
	imageContentTypeHasSuffix?: InputMaybe<Scalars["String"]>;
	imageContentTypeIn?: InputMaybe<Array<Scalars["String"]>>;
	imageContentTypeNEQ?: InputMaybe<Scalars["String"]>;
	imageContentTypeNotContains?: InputMaybe<Scalars["String"]>;
	imageContentTypeNotHasPrefix?: InputMaybe<Scalars["String"]>;
	imageContentTypeNotHasSuffix?: InputMaybe<Scalars["String"]>;
	imageContentTypeNotIn?: InputMaybe<Array<Scalars["String"]>>;
	imageIsNull?: InputMaybe<Scalars["Boolean"]>;
	lastNameContains?: InputMaybe<Scalars["String"]>;
	lastNameEQ?: InputMaybe<Scalars["String"]>;
	lastNameHasPrefix?: InputMaybe<Scalars["String"]>;
	lastNameHasSuffix?: InputMaybe<Scalars["String"]>;
	lastNameIn?: InputMaybe<Array<Scalars["String"]>>;
	lastNameNEQ?: InputMaybe<Scalars["String"]>;
	lastNameNotContains?: InputMaybe<Scalars["String"]>;
	lastNameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	lastNameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	lastNameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	nationalityContains?: InputMaybe<Scalars["String"]>;
	nationalityEQ?: InputMaybe<Scalars["String"]>;
	nationalityHasPrefix?: InputMaybe<Scalars["String"]>;
	nationalityHasSuffix?: InputMaybe<Scalars["String"]>;
	nationalityIn?: InputMaybe<Array<Scalars["String"]>>;
	nationalityNEQ?: InputMaybe<Scalars["String"]>;
	nationalityNotContains?: InputMaybe<Scalars["String"]>;
	nationalityNotHasPrefix?: InputMaybe<Scalars["String"]>;
	nationalityNotHasSuffix?: InputMaybe<Scalars["String"]>;
	nationalityNotIn?: InputMaybe<Array<Scalars["String"]>>;
	nicknameContains?: InputMaybe<Scalars["String"]>;
	nicknameEQ?: InputMaybe<Scalars["String"]>;
	nicknameHasPrefix?: InputMaybe<Scalars["String"]>;
	nicknameHasSuffix?: InputMaybe<Scalars["String"]>;
	nicknameIn?: InputMaybe<Array<Scalars["String"]>>;
	nicknameNEQ?: InputMaybe<Scalars["String"]>;
	nicknameNotContains?: InputMaybe<Scalars["String"]>;
	nicknameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	nicknameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	nicknameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	not?: InputMaybe<PersonFilterInput>;
	occupationContains?: InputMaybe<Scalars["String"]>;
	occupationEQ?: InputMaybe<Scalars["String"]>;
	occupationHasPrefix?: InputMaybe<Scalars["String"]>;
	occupationHasSuffix?: InputMaybe<Scalars["String"]>;
	occupationIn?: InputMaybe<Array<Scalars["String"]>>;
	occupationNEQ?: InputMaybe<Scalars["String"]>;
	occupationNotContains?: InputMaybe<Scalars["String"]>;
	occupationNotHasPrefix?: InputMaybe<Scalars["String"]>;
	occupationNotHasSuffix?: InputMaybe<Scalars["String"]>;
	occupationNotIn?: InputMaybe<Array<Scalars["String"]>>;
	or?: InputMaybe<Array<PersonFilterInput>>;
	parent?: InputMaybe<Array<ParentFilterInput>>;
	phoneNumbers?: InputMaybe<Array<PhoneNumberFilterInput>>;
	primaryLanguageContains?: InputMaybe<Scalars["String"]>;
	primaryLanguageEQ?: InputMaybe<Scalars["String"]>;
	primaryLanguageHasPrefix?: InputMaybe<Scalars["String"]>;
	primaryLanguageHasSuffix?: InputMaybe<Scalars["String"]>;
	primaryLanguageIn?: InputMaybe<Array<Scalars["String"]>>;
	primaryLanguageNEQ?: InputMaybe<Scalars["String"]>;
	primaryLanguageNotContains?: InputMaybe<Scalars["String"]>;
	primaryLanguageNotHasPrefix?: InputMaybe<Scalars["String"]>;
	primaryLanguageNotHasSuffix?: InputMaybe<Scalars["String"]>;
	primaryLanguageNotIn?: InputMaybe<Array<Scalars["String"]>>;
	search?: InputMaybe<Scalars["String"]>;
	student?: InputMaybe<Array<StudentFilterInput>>;
	user?: InputMaybe<Array<UserFilterInput>>;
	userIDEQ?: InputMaybe<Scalars["ID"]>;
	userIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	userIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	userIDNEQ?: InputMaybe<Scalars["ID"]>;
	userIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type PersonHistory = Node & {
	__typename?: "PersonHistory";
	alternateNames: Maybe<Scalars["String"]>;
	dateOfBirth: Maybe<Scalars["Time"]>;
	englishLanguageFluency: Maybe<PersonHistoryEnglishLanguageFluency>;
	firstName: Maybe<Scalars["String"]>;
	gender: Maybe<Scalars["String"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	imageContentType: Maybe<Scalars["String"]>;
	lastName: Maybe<Scalars["String"]>;
	nationality: Maybe<Scalars["String"]>;
	nickname: Maybe<Scalars["String"]>;
	occupation: Maybe<Scalars["String"]>;
	originalID: Maybe<Scalars["ID"]>;
	primaryLanguage: Maybe<Scalars["String"]>;
	userID: Maybe<Scalars["ID"]>;
};

export type PersonHistoryConnection = {
	__typename?: "PersonHistoryConnection";
	edges: Array<PersonHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type PersonHistoryEdge = {
	__typename?: "PersonHistoryEdge";
	cursor: Scalars["Cursor"];
	node: PersonHistory;
};

export enum PersonHistoryEnglishLanguageFluency {
	Average = "average",
	Basic = "basic",
	Excellent = "excellent",
	None = "none",
	NotSpecified = "not_specified",
}

export type PersonHistoryFilterInput = {
	alternateNamesContains?: InputMaybe<Scalars["String"]>;
	alternateNamesEQ?: InputMaybe<Scalars["String"]>;
	alternateNamesHasPrefix?: InputMaybe<Scalars["String"]>;
	alternateNamesHasSuffix?: InputMaybe<Scalars["String"]>;
	alternateNamesIn?: InputMaybe<Array<Scalars["String"]>>;
	alternateNamesIsNull?: InputMaybe<Scalars["Boolean"]>;
	alternateNamesNEQ?: InputMaybe<Scalars["String"]>;
	alternateNamesNotContains?: InputMaybe<Scalars["String"]>;
	alternateNamesNotHasPrefix?: InputMaybe<Scalars["String"]>;
	alternateNamesNotHasSuffix?: InputMaybe<Scalars["String"]>;
	alternateNamesNotIn?: InputMaybe<Array<Scalars["String"]>>;
	and?: InputMaybe<Array<PersonHistoryFilterInput>>;
	dateOfBirthEQ?: InputMaybe<Scalars["Time"]>;
	dateOfBirthGT?: InputMaybe<Scalars["Time"]>;
	dateOfBirthGTE?: InputMaybe<Scalars["Time"]>;
	dateOfBirthIn?: InputMaybe<Array<Scalars["Time"]>>;
	dateOfBirthIsNull?: InputMaybe<Scalars["Boolean"]>;
	dateOfBirthLT?: InputMaybe<Scalars["Time"]>;
	dateOfBirthLTE?: InputMaybe<Scalars["Time"]>;
	dateOfBirthNEQ?: InputMaybe<Scalars["Time"]>;
	dateOfBirthNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	englishLanguageFluencyEQ?: InputMaybe<PersonHistoryEnglishLanguageFluency>;
	englishLanguageFluencyIn?: InputMaybe<Array<PersonHistoryEnglishLanguageFluency>>;
	englishLanguageFluencyIsNull?: InputMaybe<Scalars["Boolean"]>;
	englishLanguageFluencyNEQ?: InputMaybe<PersonHistoryEnglishLanguageFluency>;
	englishLanguageFluencyNotIn?: InputMaybe<Array<PersonHistoryEnglishLanguageFluency>>;
	firstNameContains?: InputMaybe<Scalars["String"]>;
	firstNameEQ?: InputMaybe<Scalars["String"]>;
	firstNameHasPrefix?: InputMaybe<Scalars["String"]>;
	firstNameHasSuffix?: InputMaybe<Scalars["String"]>;
	firstNameIn?: InputMaybe<Array<Scalars["String"]>>;
	firstNameIsNull?: InputMaybe<Scalars["Boolean"]>;
	firstNameNEQ?: InputMaybe<Scalars["String"]>;
	firstNameNotContains?: InputMaybe<Scalars["String"]>;
	firstNameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	firstNameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	firstNameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	genderContains?: InputMaybe<Scalars["String"]>;
	genderEQ?: InputMaybe<Scalars["String"]>;
	genderHasPrefix?: InputMaybe<Scalars["String"]>;
	genderHasSuffix?: InputMaybe<Scalars["String"]>;
	genderIn?: InputMaybe<Array<Scalars["String"]>>;
	genderIsNull?: InputMaybe<Scalars["Boolean"]>;
	genderNEQ?: InputMaybe<Scalars["String"]>;
	genderNotContains?: InputMaybe<Scalars["String"]>;
	genderNotHasPrefix?: InputMaybe<Scalars["String"]>;
	genderNotHasSuffix?: InputMaybe<Scalars["String"]>;
	genderNotIn?: InputMaybe<Array<Scalars["String"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	imageContentTypeContains?: InputMaybe<Scalars["String"]>;
	imageContentTypeEQ?: InputMaybe<Scalars["String"]>;
	imageContentTypeHasPrefix?: InputMaybe<Scalars["String"]>;
	imageContentTypeHasSuffix?: InputMaybe<Scalars["String"]>;
	imageContentTypeIn?: InputMaybe<Array<Scalars["String"]>>;
	imageContentTypeIsNull?: InputMaybe<Scalars["Boolean"]>;
	imageContentTypeNEQ?: InputMaybe<Scalars["String"]>;
	imageContentTypeNotContains?: InputMaybe<Scalars["String"]>;
	imageContentTypeNotHasPrefix?: InputMaybe<Scalars["String"]>;
	imageContentTypeNotHasSuffix?: InputMaybe<Scalars["String"]>;
	imageContentTypeNotIn?: InputMaybe<Array<Scalars["String"]>>;
	lastNameContains?: InputMaybe<Scalars["String"]>;
	lastNameEQ?: InputMaybe<Scalars["String"]>;
	lastNameHasPrefix?: InputMaybe<Scalars["String"]>;
	lastNameHasSuffix?: InputMaybe<Scalars["String"]>;
	lastNameIn?: InputMaybe<Array<Scalars["String"]>>;
	lastNameIsNull?: InputMaybe<Scalars["Boolean"]>;
	lastNameNEQ?: InputMaybe<Scalars["String"]>;
	lastNameNotContains?: InputMaybe<Scalars["String"]>;
	lastNameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	lastNameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	lastNameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	nationalityContains?: InputMaybe<Scalars["String"]>;
	nationalityEQ?: InputMaybe<Scalars["String"]>;
	nationalityHasPrefix?: InputMaybe<Scalars["String"]>;
	nationalityHasSuffix?: InputMaybe<Scalars["String"]>;
	nationalityIn?: InputMaybe<Array<Scalars["String"]>>;
	nationalityIsNull?: InputMaybe<Scalars["Boolean"]>;
	nationalityNEQ?: InputMaybe<Scalars["String"]>;
	nationalityNotContains?: InputMaybe<Scalars["String"]>;
	nationalityNotHasPrefix?: InputMaybe<Scalars["String"]>;
	nationalityNotHasSuffix?: InputMaybe<Scalars["String"]>;
	nationalityNotIn?: InputMaybe<Array<Scalars["String"]>>;
	nicknameContains?: InputMaybe<Scalars["String"]>;
	nicknameEQ?: InputMaybe<Scalars["String"]>;
	nicknameHasPrefix?: InputMaybe<Scalars["String"]>;
	nicknameHasSuffix?: InputMaybe<Scalars["String"]>;
	nicknameIn?: InputMaybe<Array<Scalars["String"]>>;
	nicknameIsNull?: InputMaybe<Scalars["Boolean"]>;
	nicknameNEQ?: InputMaybe<Scalars["String"]>;
	nicknameNotContains?: InputMaybe<Scalars["String"]>;
	nicknameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	nicknameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	nicknameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	not?: InputMaybe<PersonHistoryFilterInput>;
	occupationContains?: InputMaybe<Scalars["String"]>;
	occupationEQ?: InputMaybe<Scalars["String"]>;
	occupationHasPrefix?: InputMaybe<Scalars["String"]>;
	occupationHasSuffix?: InputMaybe<Scalars["String"]>;
	occupationIn?: InputMaybe<Array<Scalars["String"]>>;
	occupationIsNull?: InputMaybe<Scalars["Boolean"]>;
	occupationNEQ?: InputMaybe<Scalars["String"]>;
	occupationNotContains?: InputMaybe<Scalars["String"]>;
	occupationNotHasPrefix?: InputMaybe<Scalars["String"]>;
	occupationNotHasSuffix?: InputMaybe<Scalars["String"]>;
	occupationNotIn?: InputMaybe<Array<Scalars["String"]>>;
	or?: InputMaybe<Array<PersonHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	primaryLanguageContains?: InputMaybe<Scalars["String"]>;
	primaryLanguageEQ?: InputMaybe<Scalars["String"]>;
	primaryLanguageHasPrefix?: InputMaybe<Scalars["String"]>;
	primaryLanguageHasSuffix?: InputMaybe<Scalars["String"]>;
	primaryLanguageIn?: InputMaybe<Array<Scalars["String"]>>;
	primaryLanguageIsNull?: InputMaybe<Scalars["Boolean"]>;
	primaryLanguageNEQ?: InputMaybe<Scalars["String"]>;
	primaryLanguageNotContains?: InputMaybe<Scalars["String"]>;
	primaryLanguageNotHasPrefix?: InputMaybe<Scalars["String"]>;
	primaryLanguageNotHasSuffix?: InputMaybe<Scalars["String"]>;
	primaryLanguageNotIn?: InputMaybe<Array<Scalars["String"]>>;
	search?: InputMaybe<Scalars["String"]>;
	userIDEQ?: InputMaybe<Scalars["ID"]>;
	userIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	userIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	userIDNEQ?: InputMaybe<Scalars["ID"]>;
	userIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type PersonHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<PersonHistoryOrderField>;
};

export enum PersonHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type PersonInEmployeeCreateInput = {
	addressIDs?: InputMaybe<Array<Scalars["ID"]>>;
	alternateNames?: InputMaybe<Scalars["String"]>;
	dateOfBirth?: InputMaybe<Scalars["Time"]>;
	emailAddressIDs?: InputMaybe<Array<Scalars["ID"]>>;
	emailAddresses?: InputMaybe<Array<EmailAddressInPersonCreateInput>>;
	englishLanguageFluency?: InputMaybe<PersonEnglishLanguageFluency>;
	firstName: Scalars["String"];
	gender?: InputMaybe<Scalars["String"]>;
	image?: InputMaybe<Scalars["Upload"]>;
	lastName: Scalars["String"];
	nationality?: InputMaybe<Scalars["String"]>;
	nickname?: InputMaybe<Scalars["String"]>;
	occupation?: InputMaybe<Scalars["String"]>;
	parent?: InputMaybe<ParentInPersonCreateInput>;
	parentID?: InputMaybe<Scalars["ID"]>;
	phoneNumberIDs?: InputMaybe<Array<Scalars["ID"]>>;
	phoneNumbers?: InputMaybe<Array<PhoneNumberInPersonCreateInput>>;
	primaryLanguage?: InputMaybe<Scalars["String"]>;
	student?: InputMaybe<StudentInPersonCreateInput>;
	studentID?: InputMaybe<Scalars["ID"]>;
	user?: InputMaybe<UserInPersonCreateInput>;
	userID?: InputMaybe<Scalars["ID"]>;
};

export type PersonInParentCreateInput = {
	addressIDs?: InputMaybe<Array<Scalars["ID"]>>;
	alternateNames?: InputMaybe<Scalars["String"]>;
	dateOfBirth?: InputMaybe<Scalars["Time"]>;
	emailAddressIDs?: InputMaybe<Array<Scalars["ID"]>>;
	emailAddresses?: InputMaybe<Array<EmailAddressInPersonCreateInput>>;
	employee?: InputMaybe<EmployeeInPersonCreateInput>;
	employeeID?: InputMaybe<Scalars["ID"]>;
	englishLanguageFluency?: InputMaybe<PersonEnglishLanguageFluency>;
	firstName: Scalars["String"];
	gender?: InputMaybe<Scalars["String"]>;
	image?: InputMaybe<Scalars["Upload"]>;
	lastName: Scalars["String"];
	nationality?: InputMaybe<Scalars["String"]>;
	nickname?: InputMaybe<Scalars["String"]>;
	occupation?: InputMaybe<Scalars["String"]>;
	phoneNumberIDs?: InputMaybe<Array<Scalars["ID"]>>;
	phoneNumbers?: InputMaybe<Array<PhoneNumberInPersonCreateInput>>;
	primaryLanguage?: InputMaybe<Scalars["String"]>;
	student?: InputMaybe<StudentInPersonCreateInput>;
	studentID?: InputMaybe<Scalars["ID"]>;
	user?: InputMaybe<UserInPersonCreateInput>;
	userID?: InputMaybe<Scalars["ID"]>;
};

export type PersonInStudentCreateInput = {
	addressIDs?: InputMaybe<Array<Scalars["ID"]>>;
	alternateNames?: InputMaybe<Scalars["String"]>;
	dateOfBirth?: InputMaybe<Scalars["Time"]>;
	emailAddressIDs?: InputMaybe<Array<Scalars["ID"]>>;
	emailAddresses?: InputMaybe<Array<EmailAddressInPersonCreateInput>>;
	employee?: InputMaybe<EmployeeInPersonCreateInput>;
	employeeID?: InputMaybe<Scalars["ID"]>;
	englishLanguageFluency?: InputMaybe<PersonEnglishLanguageFluency>;
	firstName: Scalars["String"];
	gender?: InputMaybe<Scalars["String"]>;
	image?: InputMaybe<Scalars["Upload"]>;
	lastName: Scalars["String"];
	nationality?: InputMaybe<Scalars["String"]>;
	nickname?: InputMaybe<Scalars["String"]>;
	occupation?: InputMaybe<Scalars["String"]>;
	parent?: InputMaybe<ParentInPersonCreateInput>;
	parentID?: InputMaybe<Scalars["ID"]>;
	phoneNumberIDs?: InputMaybe<Array<Scalars["ID"]>>;
	phoneNumbers?: InputMaybe<Array<PhoneNumberInPersonCreateInput>>;
	primaryLanguage?: InputMaybe<Scalars["String"]>;
	user?: InputMaybe<UserInPersonCreateInput>;
	userID?: InputMaybe<Scalars["ID"]>;
};

export type PersonInUserCreateInput = {
	addressIDs?: InputMaybe<Array<Scalars["ID"]>>;
	alternateNames?: InputMaybe<Scalars["String"]>;
	dateOfBirth?: InputMaybe<Scalars["Time"]>;
	emailAddressIDs?: InputMaybe<Array<Scalars["ID"]>>;
	emailAddresses?: InputMaybe<Array<EmailAddressInPersonCreateInput>>;
	employee?: InputMaybe<EmployeeInPersonCreateInput>;
	employeeID?: InputMaybe<Scalars["ID"]>;
	englishLanguageFluency?: InputMaybe<PersonEnglishLanguageFluency>;
	firstName: Scalars["String"];
	gender?: InputMaybe<Scalars["String"]>;
	image?: InputMaybe<Scalars["Upload"]>;
	lastName: Scalars["String"];
	nationality?: InputMaybe<Scalars["String"]>;
	nickname?: InputMaybe<Scalars["String"]>;
	occupation?: InputMaybe<Scalars["String"]>;
	parent?: InputMaybe<ParentInPersonCreateInput>;
	parentID?: InputMaybe<Scalars["ID"]>;
	phoneNumberIDs?: InputMaybe<Array<Scalars["ID"]>>;
	phoneNumbers?: InputMaybe<Array<PhoneNumberInPersonCreateInput>>;
	primaryLanguage?: InputMaybe<Scalars["String"]>;
	student?: InputMaybe<StudentInPersonCreateInput>;
	studentID?: InputMaybe<Scalars["ID"]>;
};

export type PersonOrder = {
	direction: OrderDirection;
	field?: InputMaybe<PersonOrderField>;
};

export enum PersonOrderField {
	DateOfBirth = "DATE_OF_BIRTH",
	EnglishLanguageFluency = "ENGLISH_LANGUAGE_FLUENCY",
	FirstName = "FIRST_NAME",
	Gender = "GENDER",
	LastName = "LAST_NAME",
	Nationality = "NATIONALITY",
	Nickname = "NICKNAME",
	PrimaryLanguage = "PRIMARY_LANGUAGE",
}

export type PersonUpdateBulkInput = {
	addAddressIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addEmailAddressIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addPhoneNumberIDs?: InputMaybe<Array<Scalars["ID"]>>;
	alternateNames?: InputMaybe<Scalars["String"]>;
	clearDateOfBirth?: InputMaybe<Scalars["Boolean"]>;
	clearImage?: InputMaybe<Scalars["Boolean"]>;
	dateOfBirth?: InputMaybe<Scalars["Time"]>;
	englishLanguageFluency?: InputMaybe<PersonEnglishLanguageFluency>;
	firstName?: InputMaybe<Scalars["String"]>;
	gender?: InputMaybe<Scalars["String"]>;
	image?: InputMaybe<Scalars["Upload"]>;
	lastName?: InputMaybe<Scalars["String"]>;
	nationality?: InputMaybe<Scalars["String"]>;
	nickname?: InputMaybe<Scalars["String"]>;
	occupation?: InputMaybe<Scalars["String"]>;
	primaryLanguage?: InputMaybe<Scalars["String"]>;
	removeAddressIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeEmailAddressIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removePhoneNumberIDs?: InputMaybe<Array<Scalars["ID"]>>;
};

export type PersonUpdateInput = {
	addAddressIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addEmailAddressIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addPhoneNumberIDs?: InputMaybe<Array<Scalars["ID"]>>;
	alternateNames?: InputMaybe<Scalars["String"]>;
	clearDateOfBirth?: InputMaybe<Scalars["Boolean"]>;
	clearEmployee?: InputMaybe<Scalars["Boolean"]>;
	clearImage?: InputMaybe<Scalars["Boolean"]>;
	clearParent?: InputMaybe<Scalars["Boolean"]>;
	clearStudent?: InputMaybe<Scalars["Boolean"]>;
	clearUser?: InputMaybe<Scalars["Boolean"]>;
	dateOfBirth?: InputMaybe<Scalars["Time"]>;
	employeeID?: InputMaybe<Scalars["ID"]>;
	englishLanguageFluency?: InputMaybe<PersonEnglishLanguageFluency>;
	firstName?: InputMaybe<Scalars["String"]>;
	gender?: InputMaybe<Scalars["String"]>;
	image?: InputMaybe<Scalars["Upload"]>;
	lastName?: InputMaybe<Scalars["String"]>;
	nationality?: InputMaybe<Scalars["String"]>;
	nickname?: InputMaybe<Scalars["String"]>;
	occupation?: InputMaybe<Scalars["String"]>;
	parentID?: InputMaybe<Scalars["ID"]>;
	primaryLanguage?: InputMaybe<Scalars["String"]>;
	removeAddressIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeEmailAddressIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removePhoneNumberIDs?: InputMaybe<Array<Scalars["ID"]>>;
	studentID?: InputMaybe<Scalars["ID"]>;
	userID?: InputMaybe<Scalars["ID"]>;
};

export type PhoneNumber = Node & {
	__typename?: "PhoneNumber";
	id: Scalars["ID"];
	person: Maybe<Person>;
	personID: Maybe<Scalars["ID"]>;
	phoneNumber: Scalars["String"];
	phoneType: PhoneNumberPhoneType;
};

export type PhoneNumberConnection = {
	__typename?: "PhoneNumberConnection";
	edges: Array<PhoneNumberEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type PhoneNumberCreateInput = {
	personID?: InputMaybe<Scalars["ID"]>;
	phoneNumber: Scalars["String"];
	phoneType: PhoneNumberPhoneType;
};

export type PhoneNumberEdge = {
	__typename?: "PhoneNumberEdge";
	cursor: Scalars["Cursor"];
	node: PhoneNumber;
};

export type PhoneNumberFilterInput = {
	and?: InputMaybe<Array<PhoneNumberFilterInput>>;
	hasPerson?: InputMaybe<Scalars["Boolean"]>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<PhoneNumberFilterInput>;
	or?: InputMaybe<Array<PhoneNumberFilterInput>>;
	person?: InputMaybe<Array<PersonFilterInput>>;
	personIDEQ?: InputMaybe<Scalars["ID"]>;
	personIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	personIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	personIDNEQ?: InputMaybe<Scalars["ID"]>;
	personIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	phoneNumberContains?: InputMaybe<Scalars["String"]>;
	phoneNumberEQ?: InputMaybe<Scalars["String"]>;
	phoneNumberHasPrefix?: InputMaybe<Scalars["String"]>;
	phoneNumberHasSuffix?: InputMaybe<Scalars["String"]>;
	phoneNumberIn?: InputMaybe<Array<Scalars["String"]>>;
	phoneNumberNEQ?: InputMaybe<Scalars["String"]>;
	phoneNumberNotContains?: InputMaybe<Scalars["String"]>;
	phoneNumberNotHasPrefix?: InputMaybe<Scalars["String"]>;
	phoneNumberNotHasSuffix?: InputMaybe<Scalars["String"]>;
	phoneNumberNotIn?: InputMaybe<Array<Scalars["String"]>>;
	phoneTypeEQ?: InputMaybe<PhoneNumberPhoneType>;
	phoneTypeIn?: InputMaybe<Array<PhoneNumberPhoneType>>;
	phoneTypeNEQ?: InputMaybe<PhoneNumberPhoneType>;
	phoneTypeNotIn?: InputMaybe<Array<PhoneNumberPhoneType>>;
	search?: InputMaybe<Scalars["String"]>;
};

export type PhoneNumberHistory = Node & {
	__typename?: "PhoneNumberHistory";
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	originalID: Maybe<Scalars["ID"]>;
	personID: Maybe<Scalars["ID"]>;
	phoneNumber: Maybe<Scalars["String"]>;
	phoneType: Maybe<PhoneNumberHistoryPhoneType>;
};

export type PhoneNumberHistoryConnection = {
	__typename?: "PhoneNumberHistoryConnection";
	edges: Array<PhoneNumberHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type PhoneNumberHistoryEdge = {
	__typename?: "PhoneNumberHistoryEdge";
	cursor: Scalars["Cursor"];
	node: PhoneNumberHistory;
};

export type PhoneNumberHistoryFilterInput = {
	and?: InputMaybe<Array<PhoneNumberHistoryFilterInput>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<PhoneNumberHistoryFilterInput>;
	or?: InputMaybe<Array<PhoneNumberHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	personIDEQ?: InputMaybe<Scalars["ID"]>;
	personIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	personIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	personIDNEQ?: InputMaybe<Scalars["ID"]>;
	personIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	phoneNumberContains?: InputMaybe<Scalars["String"]>;
	phoneNumberEQ?: InputMaybe<Scalars["String"]>;
	phoneNumberHasPrefix?: InputMaybe<Scalars["String"]>;
	phoneNumberHasSuffix?: InputMaybe<Scalars["String"]>;
	phoneNumberIn?: InputMaybe<Array<Scalars["String"]>>;
	phoneNumberIsNull?: InputMaybe<Scalars["Boolean"]>;
	phoneNumberNEQ?: InputMaybe<Scalars["String"]>;
	phoneNumberNotContains?: InputMaybe<Scalars["String"]>;
	phoneNumberNotHasPrefix?: InputMaybe<Scalars["String"]>;
	phoneNumberNotHasSuffix?: InputMaybe<Scalars["String"]>;
	phoneNumberNotIn?: InputMaybe<Array<Scalars["String"]>>;
	phoneTypeEQ?: InputMaybe<PhoneNumberHistoryPhoneType>;
	phoneTypeIn?: InputMaybe<Array<PhoneNumberHistoryPhoneType>>;
	phoneTypeIsNull?: InputMaybe<Scalars["Boolean"]>;
	phoneTypeNEQ?: InputMaybe<PhoneNumberHistoryPhoneType>;
	phoneTypeNotIn?: InputMaybe<Array<PhoneNumberHistoryPhoneType>>;
	search?: InputMaybe<Scalars["String"]>;
};

export type PhoneNumberHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<PhoneNumberHistoryOrderField>;
};

export enum PhoneNumberHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export enum PhoneNumberHistoryPhoneType {
	Home = "home",
	Mobile = "mobile",
	Work = "work",
}

export type PhoneNumberInPersonCreateInput = {
	phoneNumber: Scalars["String"];
	phoneType: PhoneNumberPhoneType;
};

export type PhoneNumberOrder = {
	direction: OrderDirection;
	field?: InputMaybe<PhoneNumberOrderField>;
};

export enum PhoneNumberOrderField {
	PhoneType = "PHONE_TYPE",
}

export enum PhoneNumberPhoneType {
	Home = "home",
	Mobile = "mobile",
	Work = "work",
}

export type PhoneNumberUpdateBulkInput = {
	clearPerson?: InputMaybe<Scalars["Boolean"]>;
	personID?: InputMaybe<Scalars["ID"]>;
	phoneNumber?: InputMaybe<Scalars["String"]>;
	phoneType?: InputMaybe<PhoneNumberPhoneType>;
};

export type PhoneNumberUpdateInput = {
	clearPerson?: InputMaybe<Scalars["Boolean"]>;
	personID?: InputMaybe<Scalars["ID"]>;
	phoneNumber?: InputMaybe<Scalars["String"]>;
	phoneType?: InputMaybe<PhoneNumberPhoneType>;
};

export type Query = {
	__typename?: "Query";
	academicYear: AcademicYear;
	academicYearConnection: AcademicYearConnection;
	academicYearHistory: AcademicYearHistory;
	academicYearHistoryConnection: AcademicYearHistoryConnection;
	academicYearTuition: AcademicYearTuition;
	academicYearTuitionConnection: AcademicYearTuitionConnection;
	academicYearTuitionHistory: AcademicYearTuitionHistory;
	academicYearTuitionHistoryConnection: AcademicYearTuitionHistoryConnection;
	address: Address;
	addressConnection: AddressConnection;
	addressHistory: AddressHistory;
	addressHistoryConnection: AddressHistoryConnection;
	admissionsApplicationDailyCountsReport: AdmissionsApplicationDailyCountsReport;
	admissionsApplicationsByGradeReport: AdmissionsApplicationsByGradeReport;
	admissionsEnrollmentsByGradeReport: AdmissionsEnrollmentsByGradeReport;
	admissionsReenrollmentsByGradeReport: AdmissionsReenrollmentsByGradeReport;
	billingSetupSubmission: BillingSetupSubmission;
	billingSetupSubmissionConnection: BillingSetupSubmissionConnection;
	billingSetupSubmissionHistory: BillingSetupSubmissionHistory;
	billingSetupSubmissionHistoryConnection: BillingSetupSubmissionHistoryConnection;
	bus: Bus;
	busConnection: BusConnection;
	busHistory: BusHistory;
	busHistoryConnection: BusHistoryConnection;
	busPlan: BusPlan;
	busPlanConnection: BusPlanConnection;
	busPlanHistory: BusPlanHistory;
	busPlanHistoryConnection: BusPlanHistoryConnection;
	calendarEvent: CalendarEvent;
	calendarEventConnection: CalendarEventConnection;
	calendarEventHistory: CalendarEventHistory;
	calendarEventHistoryConnection: CalendarEventHistoryConnection;
	calendarEventSharedCalendarEventUserGroupSharingUserGroupHistory: CalendarEventSharedCalendarEventUserGroupSharingUserGroupHistory;
	calendarEventSharedCalendarEventUserGroupSharingUserGroupHistoryConnection: CalendarEventSharedCalendarEventUserGroupSharingUserGroupHistoryConnection;
	campus: Campus;
	campusConnection: CampusConnection;
	campusHistory: CampusHistory;
	campusHistoryConnection: CampusHistoryConnection;
	classYear: ClassYear;
	classYearConnection: ClassYearConnection;
	classYearCourseHistory: ClassYearCourseHistory;
	classYearCourseHistoryConnection: ClassYearCourseHistoryConnection;
	classYearHistory: ClassYearHistory;
	classYearHistoryConnection: ClassYearHistoryConnection;
	contract: Contract;
	contractConnection: ContractConnection;
	contractHistory: ContractHistory;
	contractHistoryConnection: ContractHistoryConnection;
	contractInstallment: ContractInstallment;
	contractInstallmentConnection: ContractInstallmentConnection;
	contractInstallmentHistory: ContractInstallmentHistory;
	contractInstallmentHistoryConnection: ContractInstallmentHistoryConnection;
	course: Course;
	courseAttendanceRecord: CourseAttendanceRecord;
	courseAttendanceRecordConnection: CourseAttendanceRecordConnection;
	courseAttendanceRecordHistory: CourseAttendanceRecordHistory;
	courseAttendanceRecordHistoryConnection: CourseAttendanceRecordHistoryConnection;
	courseConnection: CourseConnection;
	courseHistory: CourseHistory;
	courseHistoryConnection: CourseHistoryConnection;
	courseSection: CourseSection;
	courseSectionConnection: CourseSectionConnection;
	courseSectionHistory: CourseSectionHistory;
	courseSectionHistoryConnection: CourseSectionHistoryConnection;
	courseSectionPeriod: CourseSectionPeriod;
	courseSectionPeriodConnection: CourseSectionPeriodConnection;
	courseSectionPeriodHistory: CourseSectionPeriodHistory;
	courseSectionPeriodHistoryConnection: CourseSectionPeriodHistoryConnection;
	courseSectionStudentEnrollment: CourseSectionStudentEnrollment;
	courseSectionStudentEnrollmentConnection: CourseSectionStudentEnrollmentConnection;
	courseSectionStudentEnrollmentHistory: CourseSectionStudentEnrollmentHistory;
	courseSectionStudentEnrollmentHistoryConnection: CourseSectionStudentEnrollmentHistoryConnection;
	courseSectionTeacherEnrollment: CourseSectionTeacherEnrollment;
	courseSectionTeacherEnrollmentConnection: CourseSectionTeacherEnrollmentConnection;
	courseSectionTeacherEnrollmentHistory: CourseSectionTeacherEnrollmentHistory;
	courseSectionTeacherEnrollmentHistoryConnection: CourseSectionTeacherEnrollmentHistoryConnection;
	dailyQuotation: Quotation;
	discount: Discount;
	discountConnection: DiscountConnection;
	discountHistory: DiscountHistory;
	discountHistoryConnection: DiscountHistoryConnection;
	documentResource: DocumentResource;
	documentResourceBookmarkedDocumentResourceUserBookmarkingUserHistory: DocumentResourceBookmarkedDocumentResourceUserBookmarkingUserHistory;
	documentResourceBookmarkedDocumentResourceUserBookmarkingUserHistoryConnection: DocumentResourceBookmarkedDocumentResourceUserBookmarkingUserHistoryConnection;
	documentResourceConnection: DocumentResourceConnection;
	documentResourceFolder: DocumentResourceFolder;
	documentResourceFolderConnection: DocumentResourceFolderConnection;
	documentResourceFolderCourseHistory: DocumentResourceFolderCourseHistory;
	documentResourceFolderCourseHistoryConnection: DocumentResourceFolderCourseHistoryConnection;
	documentResourceFolderCourseSectionHistory: DocumentResourceFolderCourseSectionHistory;
	documentResourceFolderCourseSectionHistoryConnection: DocumentResourceFolderCourseSectionHistoryConnection;
	documentResourceFolderDocumentResourceHistory: DocumentResourceFolderDocumentResourceHistory;
	documentResourceFolderDocumentResourceHistoryConnection: DocumentResourceFolderDocumentResourceHistoryConnection;
	documentResourceFolderHistory: DocumentResourceFolderHistory;
	documentResourceFolderHistoryConnection: DocumentResourceFolderHistoryConnection;
	documentResourceFolderHomeRoomHistory: DocumentResourceFolderHomeRoomHistory;
	documentResourceFolderHomeRoomHistoryConnection: DocumentResourceFolderHomeRoomHistoryConnection;
	documentResourceFolderHomeRoomSectionHistory: DocumentResourceFolderHomeRoomSectionHistory;
	documentResourceFolderHomeRoomSectionHistoryConnection: DocumentResourceFolderHomeRoomSectionHistoryConnection;
	documentResourceFolderSharedDocumentResourceFolderUserGroupSharingUserGroupHistory: DocumentResourceFolderSharedDocumentResourceFolderUserGroupSharingUserGroupHistory;
	documentResourceFolderSharedDocumentResourceFolderUserGroupSharingUserGroupHistoryConnection: DocumentResourceFolderSharedDocumentResourceFolderUserGroupSharingUserGroupHistoryConnection;
	documentResourceFolderSharedDocumentResourceFolderUserSharingUserHistory: DocumentResourceFolderSharedDocumentResourceFolderUserSharingUserHistory;
	documentResourceFolderSharedDocumentResourceFolderUserSharingUserHistoryConnection: DocumentResourceFolderSharedDocumentResourceFolderUserSharingUserHistoryConnection;
	documentResourceHistory: DocumentResourceHistory;
	documentResourceHistoryConnection: DocumentResourceHistoryConnection;
	documentResourceUserViewRecord: DocumentResourceUserViewRecord;
	documentResourceUserViewRecordConnection: DocumentResourceUserViewRecordConnection;
	documentResourceUserViewRecordConnectionRecents: DocumentResourceUserViewRecordConnection;
	emailAddress: EmailAddress;
	emailAddressConnection: EmailAddressConnection;
	emailAddressHistory: EmailAddressHistory;
	emailAddressHistoryConnection: EmailAddressHistoryConnection;
	employee: Employee;
	employeeCampusHistory: EmployeeCampusHistory;
	employeeCampusHistoryConnection: EmployeeCampusHistoryConnection;
	employeeConnection: EmployeeConnection;
	employeeHistory: EmployeeHistory;
	employeeHistoryConnection: EmployeeHistoryConnection;
	enrollmentApplication: EnrollmentApplication;
	enrollmentApplicationConnection: EnrollmentApplicationConnection;
	enrollmentApplicationHistory: EnrollmentApplicationHistory;
	enrollmentApplicationHistoryConnection: EnrollmentApplicationHistoryConnection;
	enrollmentApplicationNote: EnrollmentApplicationNote;
	enrollmentApplicationNoteConnection: EnrollmentApplicationNoteConnection;
	enrollmentApplicationNoteHistory: EnrollmentApplicationNoteHistory;
	enrollmentApplicationNoteHistoryConnection: EnrollmentApplicationNoteHistoryConnection;
	enrollmentApplicationParent: EnrollmentApplicationParent;
	enrollmentApplicationParentConnection: EnrollmentApplicationParentConnection;
	enrollmentApplicationParentHistory: EnrollmentApplicationParentHistory;
	enrollmentApplicationParentHistoryConnection: EnrollmentApplicationParentHistoryConnection;
	enrollmentApplicationStudent: EnrollmentApplicationStudent;
	enrollmentApplicationStudentConnection: EnrollmentApplicationStudentConnection;
	enrollmentApplicationStudentHistory: EnrollmentApplicationStudentHistory;
	enrollmentApplicationStudentHistoryConnection: EnrollmentApplicationStudentHistoryConnection;
	enrollmentApplicationSubmission: EnrollmentApplicationSubmission;
	enrollmentApplicationSubmissionConnection: EnrollmentApplicationSubmissionConnection;
	enrollmentApplicationSubmissionHistory: EnrollmentApplicationSubmissionHistory;
	enrollmentApplicationSubmissionHistoryConnection: EnrollmentApplicationSubmissionHistoryConnection;
	enrollmentApplicationUserViewRecord: EnrollmentApplicationUserViewRecord;
	enrollmentApplicationUserViewRecordConnection: EnrollmentApplicationUserViewRecordConnection;
	enrollmentInvitation: EnrollmentInvitation;
	enrollmentInvitationConnection: EnrollmentInvitationConnection;
	enrollmentInvitationHistory: EnrollmentInvitationHistory;
	enrollmentInvitationHistoryConnection: EnrollmentInvitationHistoryConnection;
	expense: Expense;
	expenseBudget: ExpenseBudget;
	expenseBudgetConnection: ExpenseBudgetConnection;
	expenseBudgetHistory: ExpenseBudgetHistory;
	expenseBudgetHistoryConnection: ExpenseBudgetHistoryConnection;
	expenseCategory: ExpenseCategory;
	expenseCategoryConnection: ExpenseCategoryConnection;
	expenseCategoryHistory: ExpenseCategoryHistory;
	expenseCategoryHistoryConnection: ExpenseCategoryHistoryConnection;
	expenseConnection: ExpenseConnection;
	expenseHistory: ExpenseHistory;
	expenseHistoryConnection: ExpenseHistoryConnection;
	homeRoom: HomeRoom;
	homeRoomConnection: HomeRoomConnection;
	homeRoomHistory: HomeRoomHistory;
	homeRoomHistoryConnection: HomeRoomHistoryConnection;
	homeRoomSection: HomeRoomSection;
	homeRoomSectionConnection: HomeRoomSectionConnection;
	homeRoomSectionHistory: HomeRoomSectionHistory;
	homeRoomSectionHistoryConnection: HomeRoomSectionHistoryConnection;
	homeRoomSectionStudentEnrollment: HomeRoomSectionStudentEnrollment;
	homeRoomSectionStudentEnrollmentConnection: HomeRoomSectionStudentEnrollmentConnection;
	homeRoomSectionStudentEnrollmentHistory: HomeRoomSectionStudentEnrollmentHistory;
	homeRoomSectionStudentEnrollmentHistoryConnection: HomeRoomSectionStudentEnrollmentHistoryConnection;
	homeRoomSectionTeacherEnrollment: HomeRoomSectionTeacherEnrollment;
	homeRoomSectionTeacherEnrollmentConnection: HomeRoomSectionTeacherEnrollmentConnection;
	homeRoomSectionTeacherEnrollmentHistory: HomeRoomSectionTeacherEnrollmentHistory;
	homeRoomSectionTeacherEnrollmentHistoryConnection: HomeRoomSectionTeacherEnrollmentHistoryConnection;
	integrationToken: IntegrationToken;
	integrationTokenConnection: IntegrationTokenConnection;
	integrationTokenHistory: IntegrationTokenHistory;
	integrationTokenHistoryConnection: IntegrationTokenHistoryConnection;
	invoice: Invoice;
	invoiceConnection: InvoiceConnection;
	invoiceHistory: InvoiceHistory;
	invoiceHistoryConnection: InvoiceHistoryConnection;
	invoiceLineItem: InvoiceLineItem;
	invoiceLineItemConnection: InvoiceLineItemConnection;
	invoiceLineItemHistory: InvoiceLineItemHistory;
	invoiceLineItemHistoryConnection: InvoiceLineItemHistoryConnection;
	learningObjective: LearningObjective;
	learningObjectiveConnection: LearningObjectiveConnection;
	learningObjectiveHistory: LearningObjectiveHistory;
	learningObjectiveHistoryConnection: LearningObjectiveHistoryConnection;
	learningObjectiveMark: LearningObjectiveMark;
	learningObjectiveMarkConnection: LearningObjectiveMarkConnection;
	learningObjectiveMarkHistory: LearningObjectiveMarkHistory;
	learningObjectiveMarkHistoryConnection: LearningObjectiveMarkHistoryConnection;
	lessonPlan: LessonPlan;
	lessonPlanConnection: LessonPlanConnection;
	lessonPlanHistory: LessonPlanHistory;
	lessonPlanHistoryConnection: LessonPlanHistoryConnection;
	lessonPlanLearningObjectiveHistory: LessonPlanLearningObjectiveHistory;
	lessonPlanLearningObjectiveHistoryConnection: LessonPlanLearningObjectiveHistoryConnection;
	loginRecord: LoginRecord;
	loginRecordConnection: LoginRecordConnection;
	mealMenu: MealMenu;
	mealMenuConnection: MealMenuConnection;
	mealMenuHistory: MealMenuHistory;
	mealMenuHistoryConnection: MealMenuHistoryConnection;
	mealPlan: MealPlan;
	mealPlanConnection: MealPlanConnection;
	mealPlanHistory: MealPlanHistory;
	mealPlanHistoryConnection: MealPlanHistoryConnection;
	mfaFactor: MFAFactor;
	mfaFactorConnection: MFAFactorConnection;
	mfaInvalidOTPAttempt: MFAInvalidOTPAttempt;
	mfaInvalidOTPAttemptConnection: MFAInvalidOTPAttemptConnection;
	modelIDs: Array<ModelIdInformation>;
	node: Maybe<Node>;
	note: Note;
	noteAttachment: NoteAttachment;
	noteAttachmentConnection: NoteAttachmentConnection;
	noteAttachmentHistory: NoteAttachmentHistory;
	noteAttachmentHistoryConnection: NoteAttachmentHistoryConnection;
	noteCalendarEventHistory: NoteCalendarEventHistory;
	noteCalendarEventHistoryConnection: NoteCalendarEventHistoryConnection;
	noteComment: NoteComment;
	noteCommentConnection: NoteCommentConnection;
	noteCommentHistory: NoteCommentHistory;
	noteCommentHistoryConnection: NoteCommentHistoryConnection;
	noteConnection: NoteConnection;
	noteCourseSectionHistory: NoteCourseSectionHistory;
	noteCourseSectionHistoryConnection: NoteCourseSectionHistoryConnection;
	noteEmployeeHistory: NoteEmployeeHistory;
	noteEmployeeHistoryConnection: NoteEmployeeHistoryConnection;
	noteHistory: NoteHistory;
	noteHistoryConnection: NoteHistoryConnection;
	noteHomeRoomSectionHistory: NoteHomeRoomSectionHistory;
	noteHomeRoomSectionHistoryConnection: NoteHomeRoomSectionHistoryConnection;
	noteParentHistory: NoteParentHistory;
	noteParentHistoryConnection: NoteParentHistoryConnection;
	noteReaction: NoteReaction;
	noteReactionConnection: NoteReactionConnection;
	noteReactionHistory: NoteReactionHistory;
	noteReactionHistoryConnection: NoteReactionHistoryConnection;
	noteSharedNoteUserSharingUserHistory: NoteSharedNoteUserSharingUserHistory;
	noteSharedNoteUserSharingUserHistoryConnection: NoteSharedNoteUserSharingUserHistoryConnection;
	noteStudentHistory: NoteStudentHistory;
	noteStudentHistoryConnection: NoteStudentHistoryConnection;
	parent: Parent;
	parentConnection: ParentConnection;
	parentHistory: ParentHistory;
	parentHistoryConnection: ParentHistoryConnection;
	payment: Payment;
	paymentConnection: PaymentConnection;
	paymentHistory: PaymentHistory;
	paymentHistoryConnection: PaymentHistoryConnection;
	paymentLineItem: PaymentLineItem;
	paymentLineItemConnection: PaymentLineItemConnection;
	paymentLineItemHistory: PaymentLineItemHistory;
	paymentLineItemHistoryConnection: PaymentLineItemHistoryConnection;
	permission: Permission;
	permissionConnection: PermissionConnection;
	permissionGroup: PermissionGroup;
	permissionGroupConnection: PermissionGroupConnection;
	permissionGroupHistory: PermissionGroupHistory;
	permissionGroupHistoryConnection: PermissionGroupHistoryConnection;
	permissionGroupPermissionHistory: PermissionGroupPermissionHistory;
	permissionGroupPermissionHistoryConnection: PermissionGroupPermissionHistoryConnection;
	permissionGroupUserHistory: PermissionGroupUserHistory;
	permissionGroupUserHistoryConnection: PermissionGroupUserHistoryConnection;
	permissionHistory: PermissionHistory;
	permissionHistoryConnection: PermissionHistoryConnection;
	person: Person;
	personAddressHistory: PersonAddressHistory;
	personAddressHistoryConnection: PersonAddressHistoryConnection;
	personConnection: PersonConnection;
	personConnectionByBirthday: PersonConnection;
	personHistory: PersonHistory;
	personHistoryConnection: PersonHistoryConnection;
	phoneNumber: PhoneNumber;
	phoneNumberConnection: PhoneNumberConnection;
	phoneNumberHistory: PhoneNumberHistory;
	phoneNumberHistoryConnection: PhoneNumberHistoryConnection;
	quotation: Quotation;
	quotationConnection: QuotationConnection;
	quotationHistory: QuotationHistory;
	quotationHistoryConnection: QuotationHistoryConnection;
	relationship: Relationship;
	relationshipConnection: RelationshipConnection;
	relationshipHistory: RelationshipHistory;
	relationshipHistoryConnection: RelationshipHistoryConnection;
	reportCard: ReportCard;
	reportCardConnection: ReportCardConnection;
	reportCardHistory: ReportCardHistory;
	reportCardHistoryConnection: ReportCardHistoryConnection;
	room: Room;
	roomConnection: RoomConnection;
	roomHistory: RoomHistory;
	roomHistoryConnection: RoomHistoryConnection;
	root: Scalars["Boolean"];
	schoolAttendanceRecord: SchoolAttendanceRecord;
	schoolAttendanceRecordConnection: SchoolAttendanceRecordConnection;
	schoolAttendanceRecordHistory: SchoolAttendanceRecordHistory;
	schoolAttendanceRecordHistoryConnection: SchoolAttendanceRecordHistoryConnection;
	schoolStudentEnrollment: SchoolStudentEnrollment;
	schoolStudentEnrollmentConnection: SchoolStudentEnrollmentConnection;
	schoolStudentEnrollmentHistory: SchoolStudentEnrollmentHistory;
	schoolStudentEnrollmentHistoryConnection: SchoolStudentEnrollmentHistoryConnection;
	searchResult: SearchResult;
	searchResultConnection: SearchResultConnection;
	service: Service;
	serviceConnection: ServiceConnection;
	serviceHistory: ServiceHistory;
	serviceHistoryConnection: ServiceHistoryConnection;
	serviceSubscription: ServiceSubscription;
	serviceSubscriptionConnection: ServiceSubscriptionConnection;
	serviceSubscriptionHistory: ServiceSubscriptionHistory;
	serviceSubscriptionHistoryConnection: ServiceSubscriptionHistoryConnection;
	session: Session;
	sessionConnection: SessionConnection;
	student: Student;
	studentConnection: StudentConnection;
	studentDocument: StudentDocument;
	studentDocumentConnection: StudentDocumentConnection;
	studentDocumentHistory: StudentDocumentHistory;
	studentDocumentHistoryConnection: StudentDocumentHistoryConnection;
	studentDocumentSharedStudentDocumentUserGroupSharingUserGroupHistory: StudentDocumentSharedStudentDocumentUserGroupSharingUserGroupHistory;
	studentDocumentSharedStudentDocumentUserGroupSharingUserGroupHistoryConnection: StudentDocumentSharedStudentDocumentUserGroupSharingUserGroupHistoryConnection;
	studentHistory: StudentHistory;
	studentHistoryConnection: StudentHistoryConnection;
	studentTimeline: Array<TimelineItem>;
	term: Term;
	termConnection: TermConnection;
	termHistory: TermHistory;
	termHistoryConnection: TermHistoryConnection;
	timeline: Array<TimelineItem>;
	trustedDevice: TrustedDevice;
	trustedDeviceConnection: TrustedDeviceConnection;
	tuition: Tuition;
	tuitionConnection: TuitionConnection;
	tuitionHistory: TuitionHistory;
	tuitionHistoryConnection: TuitionHistoryConnection;
	user: User;
	userConnection: UserConnection;
	userGroup: UserGroup;
	userGroupConnection: UserGroupConnection;
	userGroupHistory: UserGroupHistory;
	userGroupHistoryConnection: UserGroupHistoryConnection;
	userGroupUserHistory: UserGroupUserHistory;
	userGroupUserHistoryConnection: UserGroupUserHistoryConnection;
	userHistory: UserHistory;
	userHistoryConnection: UserHistoryConnection;
	viewer: User;
};

export type QueryAcademicYearArgs = {
	id: Scalars["ID"];
};

export type QueryAcademicYearConnectionArgs = {
	filters?: InputMaybe<Array<AcademicYearFilterInput>>;
	order?: InputMaybe<AcademicYearOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryAcademicYearHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryAcademicYearHistoryConnectionArgs = {
	filters?: InputMaybe<Array<AcademicYearHistoryFilterInput>>;
	order?: InputMaybe<AcademicYearHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryAcademicYearTuitionArgs = {
	id: Scalars["ID"];
};

export type QueryAcademicYearTuitionConnectionArgs = {
	filters?: InputMaybe<Array<AcademicYearTuitionFilterInput>>;
	order?: InputMaybe<AcademicYearTuitionOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryAcademicYearTuitionHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryAcademicYearTuitionHistoryConnectionArgs = {
	filters?: InputMaybe<Array<AcademicYearTuitionHistoryFilterInput>>;
	order?: InputMaybe<AcademicYearTuitionHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryAddressArgs = {
	id: Scalars["ID"];
};

export type QueryAddressConnectionArgs = {
	filters?: InputMaybe<Array<AddressFilterInput>>;
	order?: InputMaybe<AddressOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryAddressHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryAddressHistoryConnectionArgs = {
	filters?: InputMaybe<Array<AddressHistoryFilterInput>>;
	order?: InputMaybe<AddressHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryAdmissionsApplicationDailyCountsReportArgs = {
	filter: AdmissionsApplicationDailyCountsReportFilterInput;
};

export type QueryBillingSetupSubmissionArgs = {
	id: Scalars["ID"];
};

export type QueryBillingSetupSubmissionConnectionArgs = {
	filters?: InputMaybe<Array<BillingSetupSubmissionFilterInput>>;
	order?: InputMaybe<BillingSetupSubmissionOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryBillingSetupSubmissionHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryBillingSetupSubmissionHistoryConnectionArgs = {
	filters?: InputMaybe<Array<BillingSetupSubmissionHistoryFilterInput>>;
	order?: InputMaybe<BillingSetupSubmissionHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryBusArgs = {
	id: Scalars["ID"];
};

export type QueryBusConnectionArgs = {
	filters?: InputMaybe<Array<BusFilterInput>>;
	order?: InputMaybe<BusOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryBusHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryBusHistoryConnectionArgs = {
	filters?: InputMaybe<Array<BusHistoryFilterInput>>;
	order?: InputMaybe<BusHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryBusPlanArgs = {
	id: Scalars["ID"];
};

export type QueryBusPlanConnectionArgs = {
	filters?: InputMaybe<Array<BusPlanFilterInput>>;
	order?: InputMaybe<BusPlanOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryBusPlanHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryBusPlanHistoryConnectionArgs = {
	filters?: InputMaybe<Array<BusPlanHistoryFilterInput>>;
	order?: InputMaybe<BusPlanHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryCalendarEventArgs = {
	id: Scalars["ID"];
};

export type QueryCalendarEventConnectionArgs = {
	filters?: InputMaybe<Array<CalendarEventFilterInput>>;
	order?: InputMaybe<CalendarEventOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryCalendarEventHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryCalendarEventHistoryConnectionArgs = {
	filters?: InputMaybe<Array<CalendarEventHistoryFilterInput>>;
	order?: InputMaybe<CalendarEventHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryCalendarEventSharedCalendarEventUserGroupSharingUserGroupHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryCalendarEventSharedCalendarEventUserGroupSharingUserGroupHistoryConnectionArgs = {
	filters?: InputMaybe<Array<CalendarEventSharedCalendarEventUserGroupSharingUserGroupHistoryFilterInput>>;
	order?: InputMaybe<CalendarEventSharedCalendarEventUserGroupSharingUserGroupHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryCampusArgs = {
	id: Scalars["ID"];
};

export type QueryCampusConnectionArgs = {
	filters?: InputMaybe<Array<CampusFilterInput>>;
	order?: InputMaybe<CampusOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryCampusHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryCampusHistoryConnectionArgs = {
	filters?: InputMaybe<Array<CampusHistoryFilterInput>>;
	order?: InputMaybe<CampusHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryClassYearArgs = {
	id: Scalars["ID"];
};

export type QueryClassYearConnectionArgs = {
	filters?: InputMaybe<Array<ClassYearFilterInput>>;
	order?: InputMaybe<ClassYearOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryClassYearCourseHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryClassYearCourseHistoryConnectionArgs = {
	filters?: InputMaybe<Array<ClassYearCourseHistoryFilterInput>>;
	order?: InputMaybe<ClassYearCourseHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryClassYearHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryClassYearHistoryConnectionArgs = {
	filters?: InputMaybe<Array<ClassYearHistoryFilterInput>>;
	order?: InputMaybe<ClassYearHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryContractArgs = {
	id: Scalars["ID"];
};

export type QueryContractConnectionArgs = {
	filters?: InputMaybe<Array<ContractFilterInput>>;
	order?: InputMaybe<ContractOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryContractHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryContractHistoryConnectionArgs = {
	filters?: InputMaybe<Array<ContractHistoryFilterInput>>;
	order?: InputMaybe<ContractHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryContractInstallmentArgs = {
	id: Scalars["ID"];
};

export type QueryContractInstallmentConnectionArgs = {
	filters?: InputMaybe<Array<ContractInstallmentFilterInput>>;
	order?: InputMaybe<ContractInstallmentOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryContractInstallmentHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryContractInstallmentHistoryConnectionArgs = {
	filters?: InputMaybe<Array<ContractInstallmentHistoryFilterInput>>;
	order?: InputMaybe<ContractInstallmentHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryCourseArgs = {
	id: Scalars["ID"];
};

export type QueryCourseAttendanceRecordArgs = {
	id: Scalars["ID"];
};

export type QueryCourseAttendanceRecordConnectionArgs = {
	filters?: InputMaybe<Array<CourseAttendanceRecordFilterInput>>;
	order?: InputMaybe<CourseAttendanceRecordOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryCourseAttendanceRecordHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryCourseAttendanceRecordHistoryConnectionArgs = {
	filters?: InputMaybe<Array<CourseAttendanceRecordHistoryFilterInput>>;
	order?: InputMaybe<CourseAttendanceRecordHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryCourseConnectionArgs = {
	filters?: InputMaybe<Array<CourseFilterInput>>;
	order?: InputMaybe<CourseOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryCourseHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryCourseHistoryConnectionArgs = {
	filters?: InputMaybe<Array<CourseHistoryFilterInput>>;
	order?: InputMaybe<CourseHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryCourseSectionArgs = {
	id: Scalars["ID"];
};

export type QueryCourseSectionConnectionArgs = {
	filters?: InputMaybe<Array<CourseSectionFilterInput>>;
	order?: InputMaybe<CourseSectionOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryCourseSectionHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryCourseSectionHistoryConnectionArgs = {
	filters?: InputMaybe<Array<CourseSectionHistoryFilterInput>>;
	order?: InputMaybe<CourseSectionHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryCourseSectionPeriodArgs = {
	id: Scalars["ID"];
};

export type QueryCourseSectionPeriodConnectionArgs = {
	filters?: InputMaybe<Array<CourseSectionPeriodFilterInput>>;
	order?: InputMaybe<CourseSectionPeriodOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryCourseSectionPeriodHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryCourseSectionPeriodHistoryConnectionArgs = {
	filters?: InputMaybe<Array<CourseSectionPeriodHistoryFilterInput>>;
	order?: InputMaybe<CourseSectionPeriodHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryCourseSectionStudentEnrollmentArgs = {
	id: Scalars["ID"];
};

export type QueryCourseSectionStudentEnrollmentConnectionArgs = {
	filters?: InputMaybe<Array<CourseSectionStudentEnrollmentFilterInput>>;
	order?: InputMaybe<CourseSectionStudentEnrollmentOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryCourseSectionStudentEnrollmentHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryCourseSectionStudentEnrollmentHistoryConnectionArgs = {
	filters?: InputMaybe<Array<CourseSectionStudentEnrollmentHistoryFilterInput>>;
	order?: InputMaybe<CourseSectionStudentEnrollmentHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryCourseSectionTeacherEnrollmentArgs = {
	id: Scalars["ID"];
};

export type QueryCourseSectionTeacherEnrollmentConnectionArgs = {
	filters?: InputMaybe<Array<CourseSectionTeacherEnrollmentFilterInput>>;
	order?: InputMaybe<CourseSectionTeacherEnrollmentOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryCourseSectionTeacherEnrollmentHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryCourseSectionTeacherEnrollmentHistoryConnectionArgs = {
	filters?: InputMaybe<Array<CourseSectionTeacherEnrollmentHistoryFilterInput>>;
	order?: InputMaybe<CourseSectionTeacherEnrollmentHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryDailyQuotationArgs = {
	date: Scalars["Time"];
};

export type QueryDiscountArgs = {
	id: Scalars["ID"];
};

export type QueryDiscountConnectionArgs = {
	filters?: InputMaybe<Array<DiscountFilterInput>>;
	order?: InputMaybe<DiscountOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryDiscountHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryDiscountHistoryConnectionArgs = {
	filters?: InputMaybe<Array<DiscountHistoryFilterInput>>;
	order?: InputMaybe<DiscountHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryDocumentResourceArgs = {
	id: Scalars["ID"];
};

export type QueryDocumentResourceBookmarkedDocumentResourceUserBookmarkingUserHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryDocumentResourceBookmarkedDocumentResourceUserBookmarkingUserHistoryConnectionArgs = {
	filters?: InputMaybe<Array<DocumentResourceBookmarkedDocumentResourceUserBookmarkingUserHistoryFilterInput>>;
	order?: InputMaybe<DocumentResourceBookmarkedDocumentResourceUserBookmarkingUserHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryDocumentResourceConnectionArgs = {
	filters?: InputMaybe<Array<DocumentResourceFilterInput>>;
	order?: InputMaybe<DocumentResourceOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryDocumentResourceFolderArgs = {
	id: Scalars["ID"];
};

export type QueryDocumentResourceFolderConnectionArgs = {
	filters?: InputMaybe<Array<DocumentResourceFolderFilterInput>>;
	order?: InputMaybe<DocumentResourceFolderOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryDocumentResourceFolderCourseHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryDocumentResourceFolderCourseHistoryConnectionArgs = {
	filters?: InputMaybe<Array<DocumentResourceFolderCourseHistoryFilterInput>>;
	order?: InputMaybe<DocumentResourceFolderCourseHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryDocumentResourceFolderCourseSectionHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryDocumentResourceFolderCourseSectionHistoryConnectionArgs = {
	filters?: InputMaybe<Array<DocumentResourceFolderCourseSectionHistoryFilterInput>>;
	order?: InputMaybe<DocumentResourceFolderCourseSectionHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryDocumentResourceFolderDocumentResourceHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryDocumentResourceFolderDocumentResourceHistoryConnectionArgs = {
	filters?: InputMaybe<Array<DocumentResourceFolderDocumentResourceHistoryFilterInput>>;
	order?: InputMaybe<DocumentResourceFolderDocumentResourceHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryDocumentResourceFolderHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryDocumentResourceFolderHistoryConnectionArgs = {
	filters?: InputMaybe<Array<DocumentResourceFolderHistoryFilterInput>>;
	order?: InputMaybe<DocumentResourceFolderHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryDocumentResourceFolderHomeRoomHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryDocumentResourceFolderHomeRoomHistoryConnectionArgs = {
	filters?: InputMaybe<Array<DocumentResourceFolderHomeRoomHistoryFilterInput>>;
	order?: InputMaybe<DocumentResourceFolderHomeRoomHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryDocumentResourceFolderHomeRoomSectionHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryDocumentResourceFolderHomeRoomSectionHistoryConnectionArgs = {
	filters?: InputMaybe<Array<DocumentResourceFolderHomeRoomSectionHistoryFilterInput>>;
	order?: InputMaybe<DocumentResourceFolderHomeRoomSectionHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryDocumentResourceFolderSharedDocumentResourceFolderUserGroupSharingUserGroupHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryDocumentResourceFolderSharedDocumentResourceFolderUserGroupSharingUserGroupHistoryConnectionArgs = {
	filters?: InputMaybe<
		Array<DocumentResourceFolderSharedDocumentResourceFolderUserGroupSharingUserGroupHistoryFilterInput>
	>;
	order?: InputMaybe<DocumentResourceFolderSharedDocumentResourceFolderUserGroupSharingUserGroupHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryDocumentResourceFolderSharedDocumentResourceFolderUserSharingUserHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryDocumentResourceFolderSharedDocumentResourceFolderUserSharingUserHistoryConnectionArgs = {
	filters?: InputMaybe<Array<DocumentResourceFolderSharedDocumentResourceFolderUserSharingUserHistoryFilterInput>>;
	order?: InputMaybe<DocumentResourceFolderSharedDocumentResourceFolderUserSharingUserHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryDocumentResourceHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryDocumentResourceHistoryConnectionArgs = {
	filters?: InputMaybe<Array<DocumentResourceHistoryFilterInput>>;
	order?: InputMaybe<DocumentResourceHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryDocumentResourceUserViewRecordArgs = {
	id: Scalars["ID"];
};

export type QueryDocumentResourceUserViewRecordConnectionArgs = {
	filters?: InputMaybe<Array<DocumentResourceUserViewRecordFilterInput>>;
	order?: InputMaybe<DocumentResourceUserViewRecordOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryDocumentResourceUserViewRecordConnectionRecentsArgs = {
	filters?: InputMaybe<Array<DocumentResourceUserViewRecordFilterInput>>;
	order?: InputMaybe<DocumentResourceUserViewRecordOrder>;
	pagination?: InputMaybe<Pagination>;
	userID: Scalars["ID"];
};

export type QueryEmailAddressArgs = {
	id: Scalars["ID"];
};

export type QueryEmailAddressConnectionArgs = {
	filters?: InputMaybe<Array<EmailAddressFilterInput>>;
	order?: InputMaybe<EmailAddressOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryEmailAddressHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryEmailAddressHistoryConnectionArgs = {
	filters?: InputMaybe<Array<EmailAddressHistoryFilterInput>>;
	order?: InputMaybe<EmailAddressHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryEmployeeArgs = {
	id: Scalars["ID"];
};

export type QueryEmployeeCampusHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryEmployeeCampusHistoryConnectionArgs = {
	filters?: InputMaybe<Array<EmployeeCampusHistoryFilterInput>>;
	order?: InputMaybe<EmployeeCampusHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryEmployeeConnectionArgs = {
	filters?: InputMaybe<Array<EmployeeFilterInput>>;
	order?: InputMaybe<EmployeeOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryEmployeeHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryEmployeeHistoryConnectionArgs = {
	filters?: InputMaybe<Array<EmployeeHistoryFilterInput>>;
	order?: InputMaybe<EmployeeHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryEnrollmentApplicationArgs = {
	id: Scalars["ID"];
};

export type QueryEnrollmentApplicationConnectionArgs = {
	filters?: InputMaybe<Array<EnrollmentApplicationFilterInput>>;
	order?: InputMaybe<EnrollmentApplicationOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryEnrollmentApplicationHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryEnrollmentApplicationHistoryConnectionArgs = {
	filters?: InputMaybe<Array<EnrollmentApplicationHistoryFilterInput>>;
	order?: InputMaybe<EnrollmentApplicationHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryEnrollmentApplicationNoteArgs = {
	id: Scalars["ID"];
};

export type QueryEnrollmentApplicationNoteConnectionArgs = {
	filters?: InputMaybe<Array<EnrollmentApplicationNoteFilterInput>>;
	order?: InputMaybe<EnrollmentApplicationNoteOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryEnrollmentApplicationNoteHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryEnrollmentApplicationNoteHistoryConnectionArgs = {
	filters?: InputMaybe<Array<EnrollmentApplicationNoteHistoryFilterInput>>;
	order?: InputMaybe<EnrollmentApplicationNoteHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryEnrollmentApplicationParentArgs = {
	id: Scalars["ID"];
};

export type QueryEnrollmentApplicationParentConnectionArgs = {
	filters?: InputMaybe<Array<EnrollmentApplicationParentFilterInput>>;
	order?: InputMaybe<EnrollmentApplicationParentOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryEnrollmentApplicationParentHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryEnrollmentApplicationParentHistoryConnectionArgs = {
	filters?: InputMaybe<Array<EnrollmentApplicationParentHistoryFilterInput>>;
	order?: InputMaybe<EnrollmentApplicationParentHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryEnrollmentApplicationStudentArgs = {
	id: Scalars["ID"];
};

export type QueryEnrollmentApplicationStudentConnectionArgs = {
	filters?: InputMaybe<Array<EnrollmentApplicationStudentFilterInput>>;
	order?: InputMaybe<EnrollmentApplicationStudentOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryEnrollmentApplicationStudentHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryEnrollmentApplicationStudentHistoryConnectionArgs = {
	filters?: InputMaybe<Array<EnrollmentApplicationStudentHistoryFilterInput>>;
	order?: InputMaybe<EnrollmentApplicationStudentHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryEnrollmentApplicationSubmissionArgs = {
	id: Scalars["ID"];
};

export type QueryEnrollmentApplicationSubmissionConnectionArgs = {
	filters?: InputMaybe<Array<EnrollmentApplicationSubmissionFilterInput>>;
	order?: InputMaybe<EnrollmentApplicationSubmissionOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryEnrollmentApplicationSubmissionHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryEnrollmentApplicationSubmissionHistoryConnectionArgs = {
	filters?: InputMaybe<Array<EnrollmentApplicationSubmissionHistoryFilterInput>>;
	order?: InputMaybe<EnrollmentApplicationSubmissionHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryEnrollmentApplicationUserViewRecordArgs = {
	id: Scalars["ID"];
};

export type QueryEnrollmentApplicationUserViewRecordConnectionArgs = {
	filters?: InputMaybe<Array<EnrollmentApplicationUserViewRecordFilterInput>>;
	order?: InputMaybe<EnrollmentApplicationUserViewRecordOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryEnrollmentInvitationArgs = {
	id: Scalars["ID"];
};

export type QueryEnrollmentInvitationConnectionArgs = {
	filters?: InputMaybe<Array<EnrollmentInvitationFilterInput>>;
	order?: InputMaybe<EnrollmentInvitationOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryEnrollmentInvitationHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryEnrollmentInvitationHistoryConnectionArgs = {
	filters?: InputMaybe<Array<EnrollmentInvitationHistoryFilterInput>>;
	order?: InputMaybe<EnrollmentInvitationHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryExpenseArgs = {
	id: Scalars["ID"];
};

export type QueryExpenseBudgetArgs = {
	id: Scalars["ID"];
};

export type QueryExpenseBudgetConnectionArgs = {
	filters?: InputMaybe<Array<ExpenseBudgetFilterInput>>;
	order?: InputMaybe<ExpenseBudgetOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryExpenseBudgetHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryExpenseBudgetHistoryConnectionArgs = {
	filters?: InputMaybe<Array<ExpenseBudgetHistoryFilterInput>>;
	order?: InputMaybe<ExpenseBudgetHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryExpenseCategoryArgs = {
	id: Scalars["ID"];
};

export type QueryExpenseCategoryConnectionArgs = {
	filters?: InputMaybe<Array<ExpenseCategoryFilterInput>>;
	order?: InputMaybe<ExpenseCategoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryExpenseCategoryHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryExpenseCategoryHistoryConnectionArgs = {
	filters?: InputMaybe<Array<ExpenseCategoryHistoryFilterInput>>;
	order?: InputMaybe<ExpenseCategoryHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryExpenseConnectionArgs = {
	filters?: InputMaybe<Array<ExpenseFilterInput>>;
	order?: InputMaybe<ExpenseOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryExpenseHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryExpenseHistoryConnectionArgs = {
	filters?: InputMaybe<Array<ExpenseHistoryFilterInput>>;
	order?: InputMaybe<ExpenseHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryHomeRoomArgs = {
	id: Scalars["ID"];
};

export type QueryHomeRoomConnectionArgs = {
	filters?: InputMaybe<Array<HomeRoomFilterInput>>;
	order?: InputMaybe<HomeRoomOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryHomeRoomHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryHomeRoomHistoryConnectionArgs = {
	filters?: InputMaybe<Array<HomeRoomHistoryFilterInput>>;
	order?: InputMaybe<HomeRoomHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryHomeRoomSectionArgs = {
	id: Scalars["ID"];
};

export type QueryHomeRoomSectionConnectionArgs = {
	filters?: InputMaybe<Array<HomeRoomSectionFilterInput>>;
	order?: InputMaybe<HomeRoomSectionOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryHomeRoomSectionHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryHomeRoomSectionHistoryConnectionArgs = {
	filters?: InputMaybe<Array<HomeRoomSectionHistoryFilterInput>>;
	order?: InputMaybe<HomeRoomSectionHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryHomeRoomSectionStudentEnrollmentArgs = {
	id: Scalars["ID"];
};

export type QueryHomeRoomSectionStudentEnrollmentConnectionArgs = {
	filters?: InputMaybe<Array<HomeRoomSectionStudentEnrollmentFilterInput>>;
	order?: InputMaybe<HomeRoomSectionStudentEnrollmentOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryHomeRoomSectionStudentEnrollmentHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryHomeRoomSectionStudentEnrollmentHistoryConnectionArgs = {
	filters?: InputMaybe<Array<HomeRoomSectionStudentEnrollmentHistoryFilterInput>>;
	order?: InputMaybe<HomeRoomSectionStudentEnrollmentHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryHomeRoomSectionTeacherEnrollmentArgs = {
	id: Scalars["ID"];
};

export type QueryHomeRoomSectionTeacherEnrollmentConnectionArgs = {
	filters?: InputMaybe<Array<HomeRoomSectionTeacherEnrollmentFilterInput>>;
	order?: InputMaybe<HomeRoomSectionTeacherEnrollmentOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryHomeRoomSectionTeacherEnrollmentHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryHomeRoomSectionTeacherEnrollmentHistoryConnectionArgs = {
	filters?: InputMaybe<Array<HomeRoomSectionTeacherEnrollmentHistoryFilterInput>>;
	order?: InputMaybe<HomeRoomSectionTeacherEnrollmentHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryIntegrationTokenArgs = {
	id: Scalars["ID"];
};

export type QueryIntegrationTokenConnectionArgs = {
	filters?: InputMaybe<Array<IntegrationTokenFilterInput>>;
	order?: InputMaybe<IntegrationTokenOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryIntegrationTokenHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryIntegrationTokenHistoryConnectionArgs = {
	filters?: InputMaybe<Array<IntegrationTokenHistoryFilterInput>>;
	order?: InputMaybe<IntegrationTokenHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryInvoiceArgs = {
	id: Scalars["ID"];
};

export type QueryInvoiceConnectionArgs = {
	filters?: InputMaybe<Array<InvoiceFilterInput>>;
	order?: InputMaybe<InvoiceOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryInvoiceHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryInvoiceHistoryConnectionArgs = {
	filters?: InputMaybe<Array<InvoiceHistoryFilterInput>>;
	order?: InputMaybe<InvoiceHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryInvoiceLineItemArgs = {
	id: Scalars["ID"];
};

export type QueryInvoiceLineItemConnectionArgs = {
	filters?: InputMaybe<Array<InvoiceLineItemFilterInput>>;
	order?: InputMaybe<InvoiceLineItemOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryInvoiceLineItemHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryInvoiceLineItemHistoryConnectionArgs = {
	filters?: InputMaybe<Array<InvoiceLineItemHistoryFilterInput>>;
	order?: InputMaybe<InvoiceLineItemHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryLearningObjectiveArgs = {
	id: Scalars["ID"];
};

export type QueryLearningObjectiveConnectionArgs = {
	filters?: InputMaybe<Array<LearningObjectiveFilterInput>>;
	order?: InputMaybe<LearningObjectiveOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryLearningObjectiveHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryLearningObjectiveHistoryConnectionArgs = {
	filters?: InputMaybe<Array<LearningObjectiveHistoryFilterInput>>;
	order?: InputMaybe<LearningObjectiveHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryLearningObjectiveMarkArgs = {
	id: Scalars["ID"];
};

export type QueryLearningObjectiveMarkConnectionArgs = {
	filters?: InputMaybe<Array<LearningObjectiveMarkFilterInput>>;
	order?: InputMaybe<LearningObjectiveMarkOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryLearningObjectiveMarkHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryLearningObjectiveMarkHistoryConnectionArgs = {
	filters?: InputMaybe<Array<LearningObjectiveMarkHistoryFilterInput>>;
	order?: InputMaybe<LearningObjectiveMarkHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryLessonPlanArgs = {
	id: Scalars["ID"];
};

export type QueryLessonPlanConnectionArgs = {
	filters?: InputMaybe<Array<LessonPlanFilterInput>>;
	order?: InputMaybe<LessonPlanOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryLessonPlanHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryLessonPlanHistoryConnectionArgs = {
	filters?: InputMaybe<Array<LessonPlanHistoryFilterInput>>;
	order?: InputMaybe<LessonPlanHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryLessonPlanLearningObjectiveHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryLessonPlanLearningObjectiveHistoryConnectionArgs = {
	filters?: InputMaybe<Array<LessonPlanLearningObjectiveHistoryFilterInput>>;
	order?: InputMaybe<LessonPlanLearningObjectiveHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryLoginRecordArgs = {
	id: Scalars["ID"];
};

export type QueryLoginRecordConnectionArgs = {
	filters?: InputMaybe<Array<LoginRecordFilterInput>>;
	order?: InputMaybe<LoginRecordOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryMealMenuArgs = {
	id: Scalars["ID"];
};

export type QueryMealMenuConnectionArgs = {
	filters?: InputMaybe<Array<MealMenuFilterInput>>;
	order?: InputMaybe<MealMenuOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryMealMenuHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryMealMenuHistoryConnectionArgs = {
	filters?: InputMaybe<Array<MealMenuHistoryFilterInput>>;
	order?: InputMaybe<MealMenuHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryMealPlanArgs = {
	id: Scalars["ID"];
};

export type QueryMealPlanConnectionArgs = {
	filters?: InputMaybe<Array<MealPlanFilterInput>>;
	order?: InputMaybe<MealPlanOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryMealPlanHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryMealPlanHistoryConnectionArgs = {
	filters?: InputMaybe<Array<MealPlanHistoryFilterInput>>;
	order?: InputMaybe<MealPlanHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryMFAFactorArgs = {
	id: Scalars["ID"];
};

export type QueryMFAFactorConnectionArgs = {
	filters?: InputMaybe<Array<MFAFactorFilterInput>>;
	order?: InputMaybe<MFAFactorOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryMFAInvalidOTPAttemptArgs = {
	id: Scalars["ID"];
};

export type QueryMFAInvalidOTPAttemptConnectionArgs = {
	filters?: InputMaybe<Array<MFAInvalidOTPAttemptFilterInput>>;
	order?: InputMaybe<MFAInvalidOTPAttemptOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryNodeArgs = {
	id: Scalars["ID"];
};

export type QueryNoteArgs = {
	id: Scalars["ID"];
};

export type QueryNoteAttachmentArgs = {
	id: Scalars["ID"];
};

export type QueryNoteAttachmentConnectionArgs = {
	filters?: InputMaybe<Array<NoteAttachmentFilterInput>>;
	order?: InputMaybe<NoteAttachmentOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryNoteAttachmentHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryNoteAttachmentHistoryConnectionArgs = {
	filters?: InputMaybe<Array<NoteAttachmentHistoryFilterInput>>;
	order?: InputMaybe<NoteAttachmentHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryNoteCalendarEventHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryNoteCalendarEventHistoryConnectionArgs = {
	filters?: InputMaybe<Array<NoteCalendarEventHistoryFilterInput>>;
	order?: InputMaybe<NoteCalendarEventHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryNoteCommentArgs = {
	id: Scalars["ID"];
};

export type QueryNoteCommentConnectionArgs = {
	filters?: InputMaybe<Array<NoteCommentFilterInput>>;
	order?: InputMaybe<NoteCommentOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryNoteCommentHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryNoteCommentHistoryConnectionArgs = {
	filters?: InputMaybe<Array<NoteCommentHistoryFilterInput>>;
	order?: InputMaybe<NoteCommentHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryNoteConnectionArgs = {
	filters?: InputMaybe<Array<NoteFilterInput>>;
	order?: InputMaybe<NoteOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryNoteCourseSectionHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryNoteCourseSectionHistoryConnectionArgs = {
	filters?: InputMaybe<Array<NoteCourseSectionHistoryFilterInput>>;
	order?: InputMaybe<NoteCourseSectionHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryNoteEmployeeHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryNoteEmployeeHistoryConnectionArgs = {
	filters?: InputMaybe<Array<NoteEmployeeHistoryFilterInput>>;
	order?: InputMaybe<NoteEmployeeHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryNoteHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryNoteHistoryConnectionArgs = {
	filters?: InputMaybe<Array<NoteHistoryFilterInput>>;
	order?: InputMaybe<NoteHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryNoteHomeRoomSectionHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryNoteHomeRoomSectionHistoryConnectionArgs = {
	filters?: InputMaybe<Array<NoteHomeRoomSectionHistoryFilterInput>>;
	order?: InputMaybe<NoteHomeRoomSectionHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryNoteParentHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryNoteParentHistoryConnectionArgs = {
	filters?: InputMaybe<Array<NoteParentHistoryFilterInput>>;
	order?: InputMaybe<NoteParentHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryNoteReactionArgs = {
	id: Scalars["ID"];
};

export type QueryNoteReactionConnectionArgs = {
	filters?: InputMaybe<Array<NoteReactionFilterInput>>;
	order?: InputMaybe<NoteReactionOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryNoteReactionHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryNoteReactionHistoryConnectionArgs = {
	filters?: InputMaybe<Array<NoteReactionHistoryFilterInput>>;
	order?: InputMaybe<NoteReactionHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryNoteSharedNoteUserSharingUserHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryNoteSharedNoteUserSharingUserHistoryConnectionArgs = {
	filters?: InputMaybe<Array<NoteSharedNoteUserSharingUserHistoryFilterInput>>;
	order?: InputMaybe<NoteSharedNoteUserSharingUserHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryNoteStudentHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryNoteStudentHistoryConnectionArgs = {
	filters?: InputMaybe<Array<NoteStudentHistoryFilterInput>>;
	order?: InputMaybe<NoteStudentHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryParentArgs = {
	id: Scalars["ID"];
};

export type QueryParentConnectionArgs = {
	filters?: InputMaybe<Array<ParentFilterInput>>;
	order?: InputMaybe<ParentOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryParentHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryParentHistoryConnectionArgs = {
	filters?: InputMaybe<Array<ParentHistoryFilterInput>>;
	order?: InputMaybe<ParentHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryPaymentArgs = {
	id: Scalars["ID"];
};

export type QueryPaymentConnectionArgs = {
	filters?: InputMaybe<Array<PaymentFilterInput>>;
	order?: InputMaybe<PaymentOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryPaymentHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryPaymentHistoryConnectionArgs = {
	filters?: InputMaybe<Array<PaymentHistoryFilterInput>>;
	order?: InputMaybe<PaymentHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryPaymentLineItemArgs = {
	id: Scalars["ID"];
};

export type QueryPaymentLineItemConnectionArgs = {
	filters?: InputMaybe<Array<PaymentLineItemFilterInput>>;
	order?: InputMaybe<PaymentLineItemOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryPaymentLineItemHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryPaymentLineItemHistoryConnectionArgs = {
	filters?: InputMaybe<Array<PaymentLineItemHistoryFilterInput>>;
	order?: InputMaybe<PaymentLineItemHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryPermissionArgs = {
	id: Scalars["ID"];
};

export type QueryPermissionConnectionArgs = {
	filters?: InputMaybe<Array<PermissionFilterInput>>;
	order?: InputMaybe<PermissionOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryPermissionGroupArgs = {
	id: Scalars["ID"];
};

export type QueryPermissionGroupConnectionArgs = {
	filters?: InputMaybe<Array<PermissionGroupFilterInput>>;
	order?: InputMaybe<PermissionGroupOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryPermissionGroupHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryPermissionGroupHistoryConnectionArgs = {
	filters?: InputMaybe<Array<PermissionGroupHistoryFilterInput>>;
	order?: InputMaybe<PermissionGroupHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryPermissionGroupPermissionHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryPermissionGroupPermissionHistoryConnectionArgs = {
	filters?: InputMaybe<Array<PermissionGroupPermissionHistoryFilterInput>>;
	order?: InputMaybe<PermissionGroupPermissionHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryPermissionGroupUserHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryPermissionGroupUserHistoryConnectionArgs = {
	filters?: InputMaybe<Array<PermissionGroupUserHistoryFilterInput>>;
	order?: InputMaybe<PermissionGroupUserHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryPermissionHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryPermissionHistoryConnectionArgs = {
	filters?: InputMaybe<Array<PermissionHistoryFilterInput>>;
	order?: InputMaybe<PermissionHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryPersonArgs = {
	id: Scalars["ID"];
};

export type QueryPersonAddressHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryPersonAddressHistoryConnectionArgs = {
	filters?: InputMaybe<Array<PersonAddressHistoryFilterInput>>;
	order?: InputMaybe<PersonAddressHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryPersonConnectionArgs = {
	filters?: InputMaybe<Array<PersonFilterInput>>;
	order?: InputMaybe<PersonOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryPersonConnectionByBirthdayArgs = {
	dates: Array<Scalars["Time"]>;
	filters?: InputMaybe<Array<PersonFilterInput>>;
	order?: InputMaybe<PersonOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryPersonHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryPersonHistoryConnectionArgs = {
	filters?: InputMaybe<Array<PersonHistoryFilterInput>>;
	order?: InputMaybe<PersonHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryPhoneNumberArgs = {
	id: Scalars["ID"];
};

export type QueryPhoneNumberConnectionArgs = {
	filters?: InputMaybe<Array<PhoneNumberFilterInput>>;
	order?: InputMaybe<PhoneNumberOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryPhoneNumberHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryPhoneNumberHistoryConnectionArgs = {
	filters?: InputMaybe<Array<PhoneNumberHistoryFilterInput>>;
	order?: InputMaybe<PhoneNumberHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryQuotationArgs = {
	id: Scalars["ID"];
};

export type QueryQuotationConnectionArgs = {
	filters?: InputMaybe<Array<QuotationFilterInput>>;
	order?: InputMaybe<QuotationOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryQuotationHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryQuotationHistoryConnectionArgs = {
	filters?: InputMaybe<Array<QuotationHistoryFilterInput>>;
	order?: InputMaybe<QuotationHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryRelationshipArgs = {
	id: Scalars["ID"];
};

export type QueryRelationshipConnectionArgs = {
	filters?: InputMaybe<Array<RelationshipFilterInput>>;
	order?: InputMaybe<RelationshipOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryRelationshipHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryRelationshipHistoryConnectionArgs = {
	filters?: InputMaybe<Array<RelationshipHistoryFilterInput>>;
	order?: InputMaybe<RelationshipHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryReportCardArgs = {
	id: Scalars["ID"];
};

export type QueryReportCardConnectionArgs = {
	filters?: InputMaybe<Array<ReportCardFilterInput>>;
	order?: InputMaybe<ReportCardOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryReportCardHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryReportCardHistoryConnectionArgs = {
	filters?: InputMaybe<Array<ReportCardHistoryFilterInput>>;
	order?: InputMaybe<ReportCardHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryRoomArgs = {
	id: Scalars["ID"];
};

export type QueryRoomConnectionArgs = {
	filters?: InputMaybe<Array<RoomFilterInput>>;
	order?: InputMaybe<RoomOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryRoomHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryRoomHistoryConnectionArgs = {
	filters?: InputMaybe<Array<RoomHistoryFilterInput>>;
	order?: InputMaybe<RoomHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QuerySchoolAttendanceRecordArgs = {
	id: Scalars["ID"];
};

export type QuerySchoolAttendanceRecordConnectionArgs = {
	filters?: InputMaybe<Array<SchoolAttendanceRecordFilterInput>>;
	order?: InputMaybe<SchoolAttendanceRecordOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QuerySchoolAttendanceRecordHistoryArgs = {
	id: Scalars["ID"];
};

export type QuerySchoolAttendanceRecordHistoryConnectionArgs = {
	filters?: InputMaybe<Array<SchoolAttendanceRecordHistoryFilterInput>>;
	order?: InputMaybe<SchoolAttendanceRecordHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QuerySchoolStudentEnrollmentArgs = {
	id: Scalars["ID"];
};

export type QuerySchoolStudentEnrollmentConnectionArgs = {
	filters?: InputMaybe<Array<SchoolStudentEnrollmentFilterInput>>;
	order?: InputMaybe<SchoolStudentEnrollmentOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QuerySchoolStudentEnrollmentHistoryArgs = {
	id: Scalars["ID"];
};

export type QuerySchoolStudentEnrollmentHistoryConnectionArgs = {
	filters?: InputMaybe<Array<SchoolStudentEnrollmentHistoryFilterInput>>;
	order?: InputMaybe<SchoolStudentEnrollmentHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QuerySearchResultArgs = {
	id: Scalars["ID"];
};

export type QuerySearchResultConnectionArgs = {
	filters?: InputMaybe<Array<SearchResultFilterInput>>;
	order?: InputMaybe<SearchResultOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryServiceArgs = {
	id: Scalars["ID"];
};

export type QueryServiceConnectionArgs = {
	filters?: InputMaybe<Array<ServiceFilterInput>>;
	order?: InputMaybe<ServiceOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryServiceHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryServiceHistoryConnectionArgs = {
	filters?: InputMaybe<Array<ServiceHistoryFilterInput>>;
	order?: InputMaybe<ServiceHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryServiceSubscriptionArgs = {
	id: Scalars["ID"];
};

export type QueryServiceSubscriptionConnectionArgs = {
	filters?: InputMaybe<Array<ServiceSubscriptionFilterInput>>;
	order?: InputMaybe<ServiceSubscriptionOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryServiceSubscriptionHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryServiceSubscriptionHistoryConnectionArgs = {
	filters?: InputMaybe<Array<ServiceSubscriptionHistoryFilterInput>>;
	order?: InputMaybe<ServiceSubscriptionHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QuerySessionArgs = {
	id: Scalars["ID"];
};

export type QuerySessionConnectionArgs = {
	filters?: InputMaybe<Array<SessionFilterInput>>;
	order?: InputMaybe<SessionOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryStudentArgs = {
	id: Scalars["ID"];
};

export type QueryStudentConnectionArgs = {
	filters?: InputMaybe<Array<StudentFilterInput>>;
	order?: InputMaybe<StudentOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryStudentDocumentArgs = {
	id: Scalars["ID"];
};

export type QueryStudentDocumentConnectionArgs = {
	filters?: InputMaybe<Array<StudentDocumentFilterInput>>;
	order?: InputMaybe<StudentDocumentOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryStudentDocumentHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryStudentDocumentHistoryConnectionArgs = {
	filters?: InputMaybe<Array<StudentDocumentHistoryFilterInput>>;
	order?: InputMaybe<StudentDocumentHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryStudentDocumentSharedStudentDocumentUserGroupSharingUserGroupHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryStudentDocumentSharedStudentDocumentUserGroupSharingUserGroupHistoryConnectionArgs = {
	filters?: InputMaybe<Array<StudentDocumentSharedStudentDocumentUserGroupSharingUserGroupHistoryFilterInput>>;
	order?: InputMaybe<StudentDocumentSharedStudentDocumentUserGroupSharingUserGroupHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryStudentHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryStudentHistoryConnectionArgs = {
	filters?: InputMaybe<Array<StudentHistoryFilterInput>>;
	order?: InputMaybe<StudentHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryStudentTimelineArgs = {
	filter?: InputMaybe<TimelineFilterInput>;
	studentID: Scalars["ID"];
};

export type QueryTermArgs = {
	id: Scalars["ID"];
};

export type QueryTermConnectionArgs = {
	filters?: InputMaybe<Array<TermFilterInput>>;
	order?: InputMaybe<TermOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryTermHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryTermHistoryConnectionArgs = {
	filters?: InputMaybe<Array<TermHistoryFilterInput>>;
	order?: InputMaybe<TermHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryTimelineArgs = {
	filter?: InputMaybe<TimelineFilterInput>;
};

export type QueryTrustedDeviceArgs = {
	id: Scalars["ID"];
};

export type QueryTrustedDeviceConnectionArgs = {
	filters?: InputMaybe<Array<TrustedDeviceFilterInput>>;
	order?: InputMaybe<TrustedDeviceOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryTuitionArgs = {
	id: Scalars["ID"];
};

export type QueryTuitionConnectionArgs = {
	filters?: InputMaybe<Array<TuitionFilterInput>>;
	order?: InputMaybe<TuitionOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryTuitionHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryTuitionHistoryConnectionArgs = {
	filters?: InputMaybe<Array<TuitionHistoryFilterInput>>;
	order?: InputMaybe<TuitionHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryUserArgs = {
	id: Scalars["ID"];
};

export type QueryUserConnectionArgs = {
	filters?: InputMaybe<Array<UserFilterInput>>;
	order?: InputMaybe<UserOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryUserGroupArgs = {
	id: Scalars["ID"];
};

export type QueryUserGroupConnectionArgs = {
	filters?: InputMaybe<Array<UserGroupFilterInput>>;
	order?: InputMaybe<UserGroupOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryUserGroupHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryUserGroupHistoryConnectionArgs = {
	filters?: InputMaybe<Array<UserGroupHistoryFilterInput>>;
	order?: InputMaybe<UserGroupHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryUserGroupUserHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryUserGroupUserHistoryConnectionArgs = {
	filters?: InputMaybe<Array<UserGroupUserHistoryFilterInput>>;
	order?: InputMaybe<UserGroupUserHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type QueryUserHistoryArgs = {
	id: Scalars["ID"];
};

export type QueryUserHistoryConnectionArgs = {
	filters?: InputMaybe<Array<UserHistoryFilterInput>>;
	order?: InputMaybe<UserHistoryOrder>;
	pagination?: InputMaybe<Pagination>;
};

export type Quotation = Node & {
	__typename?: "Quotation";
	attribution: Scalars["String"];
	body: Scalars["String"];
	id: Scalars["ID"];
};

export type QuotationConnection = {
	__typename?: "QuotationConnection";
	edges: Array<QuotationEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type QuotationCreateInput = {
	attribution: Scalars["String"];
	body: Scalars["String"];
};

export type QuotationEdge = {
	__typename?: "QuotationEdge";
	cursor: Scalars["Cursor"];
	node: Quotation;
};

export type QuotationFilterInput = {
	and?: InputMaybe<Array<QuotationFilterInput>>;
	attributionContains?: InputMaybe<Scalars["String"]>;
	attributionEQ?: InputMaybe<Scalars["String"]>;
	attributionHasPrefix?: InputMaybe<Scalars["String"]>;
	attributionHasSuffix?: InputMaybe<Scalars["String"]>;
	attributionIn?: InputMaybe<Array<Scalars["String"]>>;
	attributionNEQ?: InputMaybe<Scalars["String"]>;
	attributionNotContains?: InputMaybe<Scalars["String"]>;
	attributionNotHasPrefix?: InputMaybe<Scalars["String"]>;
	attributionNotHasSuffix?: InputMaybe<Scalars["String"]>;
	attributionNotIn?: InputMaybe<Array<Scalars["String"]>>;
	bodyContains?: InputMaybe<Scalars["String"]>;
	bodyEQ?: InputMaybe<Scalars["String"]>;
	bodyHasPrefix?: InputMaybe<Scalars["String"]>;
	bodyHasSuffix?: InputMaybe<Scalars["String"]>;
	bodyIn?: InputMaybe<Array<Scalars["String"]>>;
	bodyNEQ?: InputMaybe<Scalars["String"]>;
	bodyNotContains?: InputMaybe<Scalars["String"]>;
	bodyNotHasPrefix?: InputMaybe<Scalars["String"]>;
	bodyNotHasSuffix?: InputMaybe<Scalars["String"]>;
	bodyNotIn?: InputMaybe<Array<Scalars["String"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<QuotationFilterInput>;
	or?: InputMaybe<Array<QuotationFilterInput>>;
	search?: InputMaybe<Scalars["String"]>;
};

export type QuotationHistory = Node & {
	__typename?: "QuotationHistory";
	attribution: Maybe<Scalars["String"]>;
	body: Maybe<Scalars["String"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	originalID: Maybe<Scalars["ID"]>;
};

export type QuotationHistoryConnection = {
	__typename?: "QuotationHistoryConnection";
	edges: Array<QuotationHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type QuotationHistoryEdge = {
	__typename?: "QuotationHistoryEdge";
	cursor: Scalars["Cursor"];
	node: QuotationHistory;
};

export type QuotationHistoryFilterInput = {
	and?: InputMaybe<Array<QuotationHistoryFilterInput>>;
	attributionContains?: InputMaybe<Scalars["String"]>;
	attributionEQ?: InputMaybe<Scalars["String"]>;
	attributionHasPrefix?: InputMaybe<Scalars["String"]>;
	attributionHasSuffix?: InputMaybe<Scalars["String"]>;
	attributionIn?: InputMaybe<Array<Scalars["String"]>>;
	attributionIsNull?: InputMaybe<Scalars["Boolean"]>;
	attributionNEQ?: InputMaybe<Scalars["String"]>;
	attributionNotContains?: InputMaybe<Scalars["String"]>;
	attributionNotHasPrefix?: InputMaybe<Scalars["String"]>;
	attributionNotHasSuffix?: InputMaybe<Scalars["String"]>;
	attributionNotIn?: InputMaybe<Array<Scalars["String"]>>;
	bodyContains?: InputMaybe<Scalars["String"]>;
	bodyEQ?: InputMaybe<Scalars["String"]>;
	bodyHasPrefix?: InputMaybe<Scalars["String"]>;
	bodyHasSuffix?: InputMaybe<Scalars["String"]>;
	bodyIn?: InputMaybe<Array<Scalars["String"]>>;
	bodyIsNull?: InputMaybe<Scalars["Boolean"]>;
	bodyNEQ?: InputMaybe<Scalars["String"]>;
	bodyNotContains?: InputMaybe<Scalars["String"]>;
	bodyNotHasPrefix?: InputMaybe<Scalars["String"]>;
	bodyNotHasSuffix?: InputMaybe<Scalars["String"]>;
	bodyNotIn?: InputMaybe<Array<Scalars["String"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<QuotationHistoryFilterInput>;
	or?: InputMaybe<Array<QuotationHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	search?: InputMaybe<Scalars["String"]>;
};

export type QuotationHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<QuotationHistoryOrderField>;
};

export enum QuotationHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type QuotationOrder = {
	direction: OrderDirection;
	field?: InputMaybe<QuotationOrderField>;
};

export enum QuotationOrderField {
	Attribution = "ATTRIBUTION",
	Body = "BODY",
}

export type QuotationUpdateBulkInput = {
	attribution?: InputMaybe<Scalars["String"]>;
	body?: InputMaybe<Scalars["String"]>;
};

export type QuotationUpdateInput = {
	attribution?: InputMaybe<Scalars["String"]>;
	body?: InputMaybe<Scalars["String"]>;
};

export enum ReenrollmentInviteEmailVariant {
	Final = "final",
	Initial = "initial",
	Reminder = "reminder",
}

export type Relationship = Node & {
	__typename?: "Relationship";
	id: Scalars["ID"];
	isAuthorizedForPickup: Scalars["Boolean"];
	isEmergencyContact: Scalars["Boolean"];
	isFinancialContact: Scalars["Boolean"];
	isPrimary: Scalars["Boolean"];
	livesWithStudent: Scalars["Boolean"];
	parent: Parent;
	parentID: Scalars["ID"];
	relationshipType: RelationshipRelationshipType;
	student: Student;
	studentID: Scalars["ID"];
};

export type RelationshipConnection = {
	__typename?: "RelationshipConnection";
	edges: Array<RelationshipEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type RelationshipCreateInput = {
	isAuthorizedForPickup?: InputMaybe<Scalars["Boolean"]>;
	isEmergencyContact?: InputMaybe<Scalars["Boolean"]>;
	isFinancialContact?: InputMaybe<Scalars["Boolean"]>;
	isPrimary?: InputMaybe<Scalars["Boolean"]>;
	livesWithStudent?: InputMaybe<Scalars["Boolean"]>;
	parentID: Scalars["ID"];
	relationshipType: RelationshipRelationshipType;
	studentID: Scalars["ID"];
};

export type RelationshipEdge = {
	__typename?: "RelationshipEdge";
	cursor: Scalars["Cursor"];
	node: Relationship;
};

export type RelationshipFilterInput = {
	and?: InputMaybe<Array<RelationshipFilterInput>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	isAuthorizedForPickupEQ?: InputMaybe<Scalars["Boolean"]>;
	isAuthorizedForPickupNEQ?: InputMaybe<Scalars["Boolean"]>;
	isEmergencyContactEQ?: InputMaybe<Scalars["Boolean"]>;
	isEmergencyContactNEQ?: InputMaybe<Scalars["Boolean"]>;
	isFinancialContactEQ?: InputMaybe<Scalars["Boolean"]>;
	isFinancialContactNEQ?: InputMaybe<Scalars["Boolean"]>;
	isPrimaryEQ?: InputMaybe<Scalars["Boolean"]>;
	isPrimaryNEQ?: InputMaybe<Scalars["Boolean"]>;
	livesWithStudentEQ?: InputMaybe<Scalars["Boolean"]>;
	livesWithStudentNEQ?: InputMaybe<Scalars["Boolean"]>;
	not?: InputMaybe<RelationshipFilterInput>;
	or?: InputMaybe<Array<RelationshipFilterInput>>;
	parent?: InputMaybe<Array<ParentFilterInput>>;
	parentIDEQ?: InputMaybe<Scalars["ID"]>;
	parentIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	parentIDNEQ?: InputMaybe<Scalars["ID"]>;
	parentIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	relationshipTypeEQ?: InputMaybe<RelationshipRelationshipType>;
	relationshipTypeIn?: InputMaybe<Array<RelationshipRelationshipType>>;
	relationshipTypeNEQ?: InputMaybe<RelationshipRelationshipType>;
	relationshipTypeNotIn?: InputMaybe<Array<RelationshipRelationshipType>>;
	student?: InputMaybe<Array<StudentFilterInput>>;
	studentIDEQ?: InputMaybe<Scalars["ID"]>;
	studentIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	studentIDNEQ?: InputMaybe<Scalars["ID"]>;
	studentIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type RelationshipHistory = Node & {
	__typename?: "RelationshipHistory";
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	isAuthorizedForPickup: Maybe<Scalars["Boolean"]>;
	isEmergencyContact: Maybe<Scalars["Boolean"]>;
	isFinancialContact: Maybe<Scalars["Boolean"]>;
	isPrimary: Maybe<Scalars["Boolean"]>;
	livesWithStudent: Maybe<Scalars["Boolean"]>;
	originalID: Maybe<Scalars["ID"]>;
	parentID: Maybe<Scalars["ID"]>;
	relationshipType: Maybe<RelationshipHistoryRelationshipType>;
	studentID: Maybe<Scalars["ID"]>;
};

export type RelationshipHistoryConnection = {
	__typename?: "RelationshipHistoryConnection";
	edges: Array<RelationshipHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type RelationshipHistoryEdge = {
	__typename?: "RelationshipHistoryEdge";
	cursor: Scalars["Cursor"];
	node: RelationshipHistory;
};

export type RelationshipHistoryFilterInput = {
	and?: InputMaybe<Array<RelationshipHistoryFilterInput>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	isAuthorizedForPickupEQ?: InputMaybe<Scalars["Boolean"]>;
	isAuthorizedForPickupIsNull?: InputMaybe<Scalars["Boolean"]>;
	isAuthorizedForPickupNEQ?: InputMaybe<Scalars["Boolean"]>;
	isEmergencyContactEQ?: InputMaybe<Scalars["Boolean"]>;
	isEmergencyContactIsNull?: InputMaybe<Scalars["Boolean"]>;
	isEmergencyContactNEQ?: InputMaybe<Scalars["Boolean"]>;
	isFinancialContactEQ?: InputMaybe<Scalars["Boolean"]>;
	isFinancialContactIsNull?: InputMaybe<Scalars["Boolean"]>;
	isFinancialContactNEQ?: InputMaybe<Scalars["Boolean"]>;
	isPrimaryEQ?: InputMaybe<Scalars["Boolean"]>;
	isPrimaryIsNull?: InputMaybe<Scalars["Boolean"]>;
	isPrimaryNEQ?: InputMaybe<Scalars["Boolean"]>;
	livesWithStudentEQ?: InputMaybe<Scalars["Boolean"]>;
	livesWithStudentIsNull?: InputMaybe<Scalars["Boolean"]>;
	livesWithStudentNEQ?: InputMaybe<Scalars["Boolean"]>;
	not?: InputMaybe<RelationshipHistoryFilterInput>;
	or?: InputMaybe<Array<RelationshipHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	parentIDEQ?: InputMaybe<Scalars["ID"]>;
	parentIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	parentIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	parentIDNEQ?: InputMaybe<Scalars["ID"]>;
	parentIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	relationshipTypeEQ?: InputMaybe<RelationshipHistoryRelationshipType>;
	relationshipTypeIn?: InputMaybe<Array<RelationshipHistoryRelationshipType>>;
	relationshipTypeIsNull?: InputMaybe<Scalars["Boolean"]>;
	relationshipTypeNEQ?: InputMaybe<RelationshipHistoryRelationshipType>;
	relationshipTypeNotIn?: InputMaybe<Array<RelationshipHistoryRelationshipType>>;
	studentIDEQ?: InputMaybe<Scalars["ID"]>;
	studentIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	studentIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	studentIDNEQ?: InputMaybe<Scalars["ID"]>;
	studentIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type RelationshipHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<RelationshipHistoryOrderField>;
};

export enum RelationshipHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export enum RelationshipHistoryRelationshipType {
	Father = "father",
	Mother = "mother",
	Other = "other",
}

export type RelationshipInParentCreateInput = {
	isAuthorizedForPickup?: InputMaybe<Scalars["Boolean"]>;
	isEmergencyContact?: InputMaybe<Scalars["Boolean"]>;
	isFinancialContact?: InputMaybe<Scalars["Boolean"]>;
	isPrimary?: InputMaybe<Scalars["Boolean"]>;
	livesWithStudent?: InputMaybe<Scalars["Boolean"]>;
	relationshipType: RelationshipRelationshipType;
	studentID: Scalars["ID"];
};

export type RelationshipInStudentCreateInput = {
	isAuthorizedForPickup?: InputMaybe<Scalars["Boolean"]>;
	isEmergencyContact?: InputMaybe<Scalars["Boolean"]>;
	isFinancialContact?: InputMaybe<Scalars["Boolean"]>;
	isPrimary?: InputMaybe<Scalars["Boolean"]>;
	livesWithStudent?: InputMaybe<Scalars["Boolean"]>;
	parentID: Scalars["ID"];
	relationshipType: RelationshipRelationshipType;
};

export type RelationshipOrder = {
	direction: OrderDirection;
	field?: InputMaybe<RelationshipOrderField>;
};

export enum RelationshipOrderField {
	IsAuthorizedForPickup = "IS_AUTHORIZED_FOR_PICKUP",
	IsEmergencyContact = "IS_EMERGENCY_CONTACT",
	IsFinancialContact = "IS_FINANCIAL_CONTACT",
	IsPrimary = "IS_PRIMARY",
	LivesWithStudent = "LIVES_WITH_STUDENT",
	RelationshipType = "RELATIONSHIP_TYPE",
}

export enum RelationshipRelationshipType {
	Father = "father",
	Mother = "mother",
	Other = "other",
}

export type RelationshipUpdateBulkInput = {
	isAuthorizedForPickup?: InputMaybe<Scalars["Boolean"]>;
	isEmergencyContact?: InputMaybe<Scalars["Boolean"]>;
	isFinancialContact?: InputMaybe<Scalars["Boolean"]>;
	isPrimary?: InputMaybe<Scalars["Boolean"]>;
	livesWithStudent?: InputMaybe<Scalars["Boolean"]>;
	parentID?: InputMaybe<Scalars["ID"]>;
	relationshipType?: InputMaybe<RelationshipRelationshipType>;
	studentID?: InputMaybe<Scalars["ID"]>;
};

export type RelationshipUpdateInput = {
	isAuthorizedForPickup?: InputMaybe<Scalars["Boolean"]>;
	isEmergencyContact?: InputMaybe<Scalars["Boolean"]>;
	isFinancialContact?: InputMaybe<Scalars["Boolean"]>;
	isPrimary?: InputMaybe<Scalars["Boolean"]>;
	livesWithStudent?: InputMaybe<Scalars["Boolean"]>;
	parentID?: InputMaybe<Scalars["ID"]>;
	relationshipType?: InputMaybe<RelationshipRelationshipType>;
	studentID?: InputMaybe<Scalars["ID"]>;
};

export type ReportCard = Node & {
	__typename?: "ReportCard";
	id: Scalars["ID"];
	issueDate: Scalars["Time"];
	learningObjectiveMarks: Array<LearningObjectiveMark>;
	student: Student;
	studentID: Scalars["ID"];
	term: Term;
	termID: Scalars["ID"];
};

export type ReportCardLearningObjectiveMarksArgs = {
	filters?: InputMaybe<Array<LearningObjectiveMarkFilterInput>>;
	order?: InputMaybe<LearningObjectiveMarkOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type ReportCardConnection = {
	__typename?: "ReportCardConnection";
	edges: Array<ReportCardEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type ReportCardCreateInput = {
	issueDate: Scalars["Time"];
	learningObjectiveMarkIDs?: InputMaybe<Array<Scalars["ID"]>>;
	learningObjectiveMarks?: InputMaybe<Array<LearningObjectiveMarkInReportCardCreateInput>>;
	studentID: Scalars["ID"];
	termID: Scalars["ID"];
};

export type ReportCardEdge = {
	__typename?: "ReportCardEdge";
	cursor: Scalars["Cursor"];
	node: ReportCard;
};

export type ReportCardFilterInput = {
	and?: InputMaybe<Array<ReportCardFilterInput>>;
	hasLearningObjectiveMarks?: InputMaybe<Scalars["Boolean"]>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	issueDateEQ?: InputMaybe<Scalars["Time"]>;
	issueDateGT?: InputMaybe<Scalars["Time"]>;
	issueDateGTE?: InputMaybe<Scalars["Time"]>;
	issueDateIn?: InputMaybe<Array<Scalars["Time"]>>;
	issueDateLT?: InputMaybe<Scalars["Time"]>;
	issueDateLTE?: InputMaybe<Scalars["Time"]>;
	issueDateNEQ?: InputMaybe<Scalars["Time"]>;
	issueDateNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	learningObjectiveMarks?: InputMaybe<Array<LearningObjectiveMarkFilterInput>>;
	not?: InputMaybe<ReportCardFilterInput>;
	or?: InputMaybe<Array<ReportCardFilterInput>>;
	student?: InputMaybe<Array<StudentFilterInput>>;
	studentIDEQ?: InputMaybe<Scalars["ID"]>;
	studentIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	studentIDNEQ?: InputMaybe<Scalars["ID"]>;
	studentIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	term?: InputMaybe<Array<TermFilterInput>>;
	termIDEQ?: InputMaybe<Scalars["ID"]>;
	termIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	termIDNEQ?: InputMaybe<Scalars["ID"]>;
	termIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type ReportCardHistory = Node & {
	__typename?: "ReportCardHistory";
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	issueDate: Maybe<Scalars["Time"]>;
	originalID: Maybe<Scalars["ID"]>;
	studentID: Maybe<Scalars["ID"]>;
	termID: Maybe<Scalars["ID"]>;
};

export type ReportCardHistoryConnection = {
	__typename?: "ReportCardHistoryConnection";
	edges: Array<ReportCardHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type ReportCardHistoryEdge = {
	__typename?: "ReportCardHistoryEdge";
	cursor: Scalars["Cursor"];
	node: ReportCardHistory;
};

export type ReportCardHistoryFilterInput = {
	and?: InputMaybe<Array<ReportCardHistoryFilterInput>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	issueDateEQ?: InputMaybe<Scalars["Time"]>;
	issueDateGT?: InputMaybe<Scalars["Time"]>;
	issueDateGTE?: InputMaybe<Scalars["Time"]>;
	issueDateIn?: InputMaybe<Array<Scalars["Time"]>>;
	issueDateIsNull?: InputMaybe<Scalars["Boolean"]>;
	issueDateLT?: InputMaybe<Scalars["Time"]>;
	issueDateLTE?: InputMaybe<Scalars["Time"]>;
	issueDateNEQ?: InputMaybe<Scalars["Time"]>;
	issueDateNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	not?: InputMaybe<ReportCardHistoryFilterInput>;
	or?: InputMaybe<Array<ReportCardHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	studentIDEQ?: InputMaybe<Scalars["ID"]>;
	studentIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	studentIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	studentIDNEQ?: InputMaybe<Scalars["ID"]>;
	studentIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	termIDEQ?: InputMaybe<Scalars["ID"]>;
	termIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	termIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	termIDNEQ?: InputMaybe<Scalars["ID"]>;
	termIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type ReportCardHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<ReportCardHistoryOrderField>;
};

export enum ReportCardHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type ReportCardInStudentCreateInput = {
	issueDate: Scalars["Time"];
	learningObjectiveMarkIDs?: InputMaybe<Array<Scalars["ID"]>>;
	learningObjectiveMarks?: InputMaybe<Array<LearningObjectiveMarkInReportCardCreateInput>>;
	termID: Scalars["ID"];
};

export type ReportCardInTermCreateInput = {
	issueDate: Scalars["Time"];
	learningObjectiveMarkIDs?: InputMaybe<Array<Scalars["ID"]>>;
	learningObjectiveMarks?: InputMaybe<Array<LearningObjectiveMarkInReportCardCreateInput>>;
	studentID: Scalars["ID"];
};

export type ReportCardOrder = {
	direction: OrderDirection;
	field?: InputMaybe<ReportCardOrderField>;
};

export enum ReportCardOrderField {
	IssueDate = "ISSUE_DATE",
}

export type ReportCardUpdateBulkInput = {
	addLearningObjectiveMarkIDs?: InputMaybe<Array<Scalars["ID"]>>;
	issueDate?: InputMaybe<Scalars["Time"]>;
	removeLearningObjectiveMarkIDs?: InputMaybe<Array<Scalars["ID"]>>;
	studentID?: InputMaybe<Scalars["ID"]>;
	termID?: InputMaybe<Scalars["ID"]>;
};

export type ReportCardUpdateInput = {
	addLearningObjectiveMarkIDs?: InputMaybe<Array<Scalars["ID"]>>;
	issueDate?: InputMaybe<Scalars["Time"]>;
	removeLearningObjectiveMarkIDs?: InputMaybe<Array<Scalars["ID"]>>;
	studentID?: InputMaybe<Scalars["ID"]>;
	termID?: InputMaybe<Scalars["ID"]>;
};

export type Room = Node & {
	__typename?: "Room";
	campus: Campus;
	campusID: Scalars["ID"];
	courseSectionPeriods: Array<CourseSectionPeriod>;
	homeRoomSections: Array<HomeRoomSection>;
	id: Scalars["ID"];
	name: Scalars["String"];
	number: Scalars["String"];
};

export type RoomCourseSectionPeriodsArgs = {
	filters?: InputMaybe<Array<CourseSectionPeriodFilterInput>>;
	order?: InputMaybe<CourseSectionPeriodOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type RoomHomeRoomSectionsArgs = {
	filters?: InputMaybe<Array<HomeRoomSectionFilterInput>>;
	order?: InputMaybe<HomeRoomSectionOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type RoomConnection = {
	__typename?: "RoomConnection";
	edges: Array<RoomEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type RoomCreateInput = {
	campusID: Scalars["ID"];
	courseSectionPeriodIDs?: InputMaybe<Array<Scalars["ID"]>>;
	courseSectionPeriods?: InputMaybe<Array<CourseSectionPeriodInRoomCreateInput>>;
	homeRoomSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	homeRoomSections?: InputMaybe<Array<HomeRoomSectionInRoomCreateInput>>;
	name: Scalars["String"];
	number: Scalars["String"];
};

export type RoomEdge = {
	__typename?: "RoomEdge";
	cursor: Scalars["Cursor"];
	node: Room;
};

export type RoomFilterInput = {
	and?: InputMaybe<Array<RoomFilterInput>>;
	campus?: InputMaybe<Array<CampusFilterInput>>;
	campusIDEQ?: InputMaybe<Scalars["ID"]>;
	campusIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	campusIDNEQ?: InputMaybe<Scalars["ID"]>;
	campusIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	courseSectionPeriods?: InputMaybe<Array<CourseSectionPeriodFilterInput>>;
	hasCourseSectionPeriods?: InputMaybe<Scalars["Boolean"]>;
	hasHomeRoomSections?: InputMaybe<Scalars["Boolean"]>;
	homeRoomSections?: InputMaybe<Array<HomeRoomSectionFilterInput>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	nameContains?: InputMaybe<Scalars["String"]>;
	nameEQ?: InputMaybe<Scalars["String"]>;
	nameHasPrefix?: InputMaybe<Scalars["String"]>;
	nameHasSuffix?: InputMaybe<Scalars["String"]>;
	nameIn?: InputMaybe<Array<Scalars["String"]>>;
	nameNEQ?: InputMaybe<Scalars["String"]>;
	nameNotContains?: InputMaybe<Scalars["String"]>;
	nameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	nameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	nameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	not?: InputMaybe<RoomFilterInput>;
	numberContains?: InputMaybe<Scalars["String"]>;
	numberEQ?: InputMaybe<Scalars["String"]>;
	numberHasPrefix?: InputMaybe<Scalars["String"]>;
	numberHasSuffix?: InputMaybe<Scalars["String"]>;
	numberIn?: InputMaybe<Array<Scalars["String"]>>;
	numberNEQ?: InputMaybe<Scalars["String"]>;
	numberNotContains?: InputMaybe<Scalars["String"]>;
	numberNotHasPrefix?: InputMaybe<Scalars["String"]>;
	numberNotHasSuffix?: InputMaybe<Scalars["String"]>;
	numberNotIn?: InputMaybe<Array<Scalars["String"]>>;
	or?: InputMaybe<Array<RoomFilterInput>>;
	search?: InputMaybe<Scalars["String"]>;
};

export type RoomHistory = Node & {
	__typename?: "RoomHistory";
	campusID: Maybe<Scalars["ID"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	name: Maybe<Scalars["String"]>;
	number: Maybe<Scalars["String"]>;
	originalID: Maybe<Scalars["ID"]>;
};

export type RoomHistoryConnection = {
	__typename?: "RoomHistoryConnection";
	edges: Array<RoomHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type RoomHistoryEdge = {
	__typename?: "RoomHistoryEdge";
	cursor: Scalars["Cursor"];
	node: RoomHistory;
};

export type RoomHistoryFilterInput = {
	and?: InputMaybe<Array<RoomHistoryFilterInput>>;
	campusIDEQ?: InputMaybe<Scalars["ID"]>;
	campusIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	campusIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	campusIDNEQ?: InputMaybe<Scalars["ID"]>;
	campusIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	nameContains?: InputMaybe<Scalars["String"]>;
	nameEQ?: InputMaybe<Scalars["String"]>;
	nameHasPrefix?: InputMaybe<Scalars["String"]>;
	nameHasSuffix?: InputMaybe<Scalars["String"]>;
	nameIn?: InputMaybe<Array<Scalars["String"]>>;
	nameIsNull?: InputMaybe<Scalars["Boolean"]>;
	nameNEQ?: InputMaybe<Scalars["String"]>;
	nameNotContains?: InputMaybe<Scalars["String"]>;
	nameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	nameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	nameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	not?: InputMaybe<RoomHistoryFilterInput>;
	numberContains?: InputMaybe<Scalars["String"]>;
	numberEQ?: InputMaybe<Scalars["String"]>;
	numberHasPrefix?: InputMaybe<Scalars["String"]>;
	numberHasSuffix?: InputMaybe<Scalars["String"]>;
	numberIn?: InputMaybe<Array<Scalars["String"]>>;
	numberIsNull?: InputMaybe<Scalars["Boolean"]>;
	numberNEQ?: InputMaybe<Scalars["String"]>;
	numberNotContains?: InputMaybe<Scalars["String"]>;
	numberNotHasPrefix?: InputMaybe<Scalars["String"]>;
	numberNotHasSuffix?: InputMaybe<Scalars["String"]>;
	numberNotIn?: InputMaybe<Array<Scalars["String"]>>;
	or?: InputMaybe<Array<RoomHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	search?: InputMaybe<Scalars["String"]>;
};

export type RoomHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<RoomHistoryOrderField>;
};

export enum RoomHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type RoomInCampusCreateInput = {
	courseSectionPeriodIDs?: InputMaybe<Array<Scalars["ID"]>>;
	courseSectionPeriods?: InputMaybe<Array<CourseSectionPeriodInRoomCreateInput>>;
	homeRoomSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	homeRoomSections?: InputMaybe<Array<HomeRoomSectionInRoomCreateInput>>;
	name: Scalars["String"];
	number: Scalars["String"];
};

export type RoomOrder = {
	direction: OrderDirection;
	field?: InputMaybe<RoomOrderField>;
};

export enum RoomOrderField {
	Name = "NAME",
	Number = "NUMBER",
}

export type RoomUpdateBulkInput = {
	addCourseSectionPeriodIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addHomeRoomSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	campusID?: InputMaybe<Scalars["ID"]>;
	name?: InputMaybe<Scalars["String"]>;
	number?: InputMaybe<Scalars["String"]>;
	removeCourseSectionPeriodIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeHomeRoomSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
};

export type RoomUpdateInput = {
	addCourseSectionPeriodIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addHomeRoomSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	campusID?: InputMaybe<Scalars["ID"]>;
	name?: InputMaybe<Scalars["String"]>;
	number?: InputMaybe<Scalars["String"]>;
	removeCourseSectionPeriodIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeHomeRoomSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
};

export type SchoolAttendanceRecord = Node & {
	__typename?: "SchoolAttendanceRecord";
	checkInTime: Scalars["Time"];
	checkOutTime: Maybe<Scalars["Time"]>;
	id: Scalars["ID"];
	isExcused: Scalars["Boolean"];
	isLate: Scalars["Boolean"];
	isPresent: Scalars["Boolean"];
	notes: Scalars["String"];
	student: Student;
	studentID: Scalars["ID"];
};

export type SchoolAttendanceRecordConnection = {
	__typename?: "SchoolAttendanceRecordConnection";
	edges: Array<SchoolAttendanceRecordEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type SchoolAttendanceRecordCreateInput = {
	checkInTime: Scalars["Time"];
	checkOutTime?: InputMaybe<Scalars["Time"]>;
	isExcused?: InputMaybe<Scalars["Boolean"]>;
	isLate?: InputMaybe<Scalars["Boolean"]>;
	isPresent?: InputMaybe<Scalars["Boolean"]>;
	notes?: InputMaybe<Scalars["String"]>;
	studentID: Scalars["ID"];
};

export type SchoolAttendanceRecordEdge = {
	__typename?: "SchoolAttendanceRecordEdge";
	cursor: Scalars["Cursor"];
	node: SchoolAttendanceRecord;
};

export type SchoolAttendanceRecordFilterInput = {
	and?: InputMaybe<Array<SchoolAttendanceRecordFilterInput>>;
	checkInTimeEQ?: InputMaybe<Scalars["Time"]>;
	checkInTimeGT?: InputMaybe<Scalars["Time"]>;
	checkInTimeGTE?: InputMaybe<Scalars["Time"]>;
	checkInTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	checkInTimeLT?: InputMaybe<Scalars["Time"]>;
	checkInTimeLTE?: InputMaybe<Scalars["Time"]>;
	checkInTimeNEQ?: InputMaybe<Scalars["Time"]>;
	checkInTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	checkOutTimeEQ?: InputMaybe<Scalars["Time"]>;
	checkOutTimeGT?: InputMaybe<Scalars["Time"]>;
	checkOutTimeGTE?: InputMaybe<Scalars["Time"]>;
	checkOutTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	checkOutTimeIsNull?: InputMaybe<Scalars["Boolean"]>;
	checkOutTimeLT?: InputMaybe<Scalars["Time"]>;
	checkOutTimeLTE?: InputMaybe<Scalars["Time"]>;
	checkOutTimeNEQ?: InputMaybe<Scalars["Time"]>;
	checkOutTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	isExcusedEQ?: InputMaybe<Scalars["Boolean"]>;
	isExcusedNEQ?: InputMaybe<Scalars["Boolean"]>;
	isLateEQ?: InputMaybe<Scalars["Boolean"]>;
	isLateNEQ?: InputMaybe<Scalars["Boolean"]>;
	isPresentEQ?: InputMaybe<Scalars["Boolean"]>;
	isPresentNEQ?: InputMaybe<Scalars["Boolean"]>;
	not?: InputMaybe<SchoolAttendanceRecordFilterInput>;
	notesContains?: InputMaybe<Scalars["String"]>;
	notesEQ?: InputMaybe<Scalars["String"]>;
	notesHasPrefix?: InputMaybe<Scalars["String"]>;
	notesHasSuffix?: InputMaybe<Scalars["String"]>;
	notesIn?: InputMaybe<Array<Scalars["String"]>>;
	notesNEQ?: InputMaybe<Scalars["String"]>;
	notesNotContains?: InputMaybe<Scalars["String"]>;
	notesNotHasPrefix?: InputMaybe<Scalars["String"]>;
	notesNotHasSuffix?: InputMaybe<Scalars["String"]>;
	notesNotIn?: InputMaybe<Array<Scalars["String"]>>;
	or?: InputMaybe<Array<SchoolAttendanceRecordFilterInput>>;
	search?: InputMaybe<Scalars["String"]>;
	student?: InputMaybe<Array<StudentFilterInput>>;
	studentIDEQ?: InputMaybe<Scalars["ID"]>;
	studentIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	studentIDNEQ?: InputMaybe<Scalars["ID"]>;
	studentIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type SchoolAttendanceRecordHistory = Node & {
	__typename?: "SchoolAttendanceRecordHistory";
	checkInTime: Maybe<Scalars["Time"]>;
	checkOutTime: Maybe<Scalars["Time"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	isExcused: Maybe<Scalars["Boolean"]>;
	isLate: Maybe<Scalars["Boolean"]>;
	isPresent: Maybe<Scalars["Boolean"]>;
	notes: Maybe<Scalars["String"]>;
	originalID: Maybe<Scalars["ID"]>;
	studentID: Maybe<Scalars["ID"]>;
};

export type SchoolAttendanceRecordHistoryConnection = {
	__typename?: "SchoolAttendanceRecordHistoryConnection";
	edges: Array<SchoolAttendanceRecordHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type SchoolAttendanceRecordHistoryEdge = {
	__typename?: "SchoolAttendanceRecordHistoryEdge";
	cursor: Scalars["Cursor"];
	node: SchoolAttendanceRecordHistory;
};

export type SchoolAttendanceRecordHistoryFilterInput = {
	and?: InputMaybe<Array<SchoolAttendanceRecordHistoryFilterInput>>;
	checkInTimeEQ?: InputMaybe<Scalars["Time"]>;
	checkInTimeGT?: InputMaybe<Scalars["Time"]>;
	checkInTimeGTE?: InputMaybe<Scalars["Time"]>;
	checkInTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	checkInTimeIsNull?: InputMaybe<Scalars["Boolean"]>;
	checkInTimeLT?: InputMaybe<Scalars["Time"]>;
	checkInTimeLTE?: InputMaybe<Scalars["Time"]>;
	checkInTimeNEQ?: InputMaybe<Scalars["Time"]>;
	checkInTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	checkOutTimeEQ?: InputMaybe<Scalars["Time"]>;
	checkOutTimeGT?: InputMaybe<Scalars["Time"]>;
	checkOutTimeGTE?: InputMaybe<Scalars["Time"]>;
	checkOutTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	checkOutTimeIsNull?: InputMaybe<Scalars["Boolean"]>;
	checkOutTimeLT?: InputMaybe<Scalars["Time"]>;
	checkOutTimeLTE?: InputMaybe<Scalars["Time"]>;
	checkOutTimeNEQ?: InputMaybe<Scalars["Time"]>;
	checkOutTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	isExcusedEQ?: InputMaybe<Scalars["Boolean"]>;
	isExcusedIsNull?: InputMaybe<Scalars["Boolean"]>;
	isExcusedNEQ?: InputMaybe<Scalars["Boolean"]>;
	isLateEQ?: InputMaybe<Scalars["Boolean"]>;
	isLateIsNull?: InputMaybe<Scalars["Boolean"]>;
	isLateNEQ?: InputMaybe<Scalars["Boolean"]>;
	isPresentEQ?: InputMaybe<Scalars["Boolean"]>;
	isPresentIsNull?: InputMaybe<Scalars["Boolean"]>;
	isPresentNEQ?: InputMaybe<Scalars["Boolean"]>;
	not?: InputMaybe<SchoolAttendanceRecordHistoryFilterInput>;
	notesContains?: InputMaybe<Scalars["String"]>;
	notesEQ?: InputMaybe<Scalars["String"]>;
	notesHasPrefix?: InputMaybe<Scalars["String"]>;
	notesHasSuffix?: InputMaybe<Scalars["String"]>;
	notesIn?: InputMaybe<Array<Scalars["String"]>>;
	notesIsNull?: InputMaybe<Scalars["Boolean"]>;
	notesNEQ?: InputMaybe<Scalars["String"]>;
	notesNotContains?: InputMaybe<Scalars["String"]>;
	notesNotHasPrefix?: InputMaybe<Scalars["String"]>;
	notesNotHasSuffix?: InputMaybe<Scalars["String"]>;
	notesNotIn?: InputMaybe<Array<Scalars["String"]>>;
	or?: InputMaybe<Array<SchoolAttendanceRecordHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	search?: InputMaybe<Scalars["String"]>;
	studentIDEQ?: InputMaybe<Scalars["ID"]>;
	studentIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	studentIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	studentIDNEQ?: InputMaybe<Scalars["ID"]>;
	studentIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type SchoolAttendanceRecordHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<SchoolAttendanceRecordHistoryOrderField>;
};

export enum SchoolAttendanceRecordHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type SchoolAttendanceRecordInStudentCreateInput = {
	checkInTime: Scalars["Time"];
	checkOutTime?: InputMaybe<Scalars["Time"]>;
	isExcused?: InputMaybe<Scalars["Boolean"]>;
	isLate?: InputMaybe<Scalars["Boolean"]>;
	isPresent?: InputMaybe<Scalars["Boolean"]>;
	notes?: InputMaybe<Scalars["String"]>;
};

export type SchoolAttendanceRecordOrder = {
	direction: OrderDirection;
	field?: InputMaybe<SchoolAttendanceRecordOrderField>;
};

export enum SchoolAttendanceRecordOrderField {
	CheckInTime = "CHECK_IN_TIME",
	CheckOutTime = "CHECK_OUT_TIME",
	IsExcused = "IS_EXCUSED",
	IsLate = "IS_LATE",
	IsPresent = "IS_PRESENT",
}

export type SchoolAttendanceRecordUpdateBulkInput = {
	checkInTime?: InputMaybe<Scalars["Time"]>;
	checkOutTime?: InputMaybe<Scalars["Time"]>;
	clearCheckOutTime?: InputMaybe<Scalars["Boolean"]>;
	isExcused?: InputMaybe<Scalars["Boolean"]>;
	isLate?: InputMaybe<Scalars["Boolean"]>;
	isPresent?: InputMaybe<Scalars["Boolean"]>;
	notes?: InputMaybe<Scalars["String"]>;
	studentID?: InputMaybe<Scalars["ID"]>;
};

export type SchoolAttendanceRecordUpdateInput = {
	checkInTime?: InputMaybe<Scalars["Time"]>;
	checkOutTime?: InputMaybe<Scalars["Time"]>;
	clearCheckOutTime?: InputMaybe<Scalars["Boolean"]>;
	isExcused?: InputMaybe<Scalars["Boolean"]>;
	isLate?: InputMaybe<Scalars["Boolean"]>;
	isPresent?: InputMaybe<Scalars["Boolean"]>;
	notes?: InputMaybe<Scalars["String"]>;
	studentID?: InputMaybe<Scalars["ID"]>;
};

export type SchoolStudentEnrollment = Node & {
	__typename?: "SchoolStudentEnrollment";
	academicYear: AcademicYear;
	academicYearID: Scalars["ID"];
	classYear: ClassYear;
	classYearID: Scalars["ID"];
	createdDate: Scalars["Time"];
	hasPaidFee: Scalars["Boolean"];
	id: Scalars["ID"];
	isVoided: Scalars["Boolean"];
	paymentDueDate: Scalars["Time"];
	student: Student;
	studentID: Scalars["ID"];
};

export type SchoolStudentEnrollmentConnection = {
	__typename?: "SchoolStudentEnrollmentConnection";
	edges: Array<SchoolStudentEnrollmentEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type SchoolStudentEnrollmentCreateInput = {
	academicYearID: Scalars["ID"];
	classYearID: Scalars["ID"];
	createdDate?: InputMaybe<Scalars["Time"]>;
	hasPaidFee?: InputMaybe<Scalars["Boolean"]>;
	isVoided?: InputMaybe<Scalars["Boolean"]>;
	paymentDueDate: Scalars["Time"];
	studentID: Scalars["ID"];
};

export type SchoolStudentEnrollmentEdge = {
	__typename?: "SchoolStudentEnrollmentEdge";
	cursor: Scalars["Cursor"];
	node: SchoolStudentEnrollment;
};

export type SchoolStudentEnrollmentFilterInput = {
	academicYear?: InputMaybe<Array<AcademicYearFilterInput>>;
	academicYearIDEQ?: InputMaybe<Scalars["ID"]>;
	academicYearIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	academicYearIDNEQ?: InputMaybe<Scalars["ID"]>;
	academicYearIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	and?: InputMaybe<Array<SchoolStudentEnrollmentFilterInput>>;
	classYear?: InputMaybe<Array<ClassYearFilterInput>>;
	classYearIDEQ?: InputMaybe<Scalars["ID"]>;
	classYearIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	classYearIDNEQ?: InputMaybe<Scalars["ID"]>;
	classYearIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	createdDateEQ?: InputMaybe<Scalars["Time"]>;
	createdDateGT?: InputMaybe<Scalars["Time"]>;
	createdDateGTE?: InputMaybe<Scalars["Time"]>;
	createdDateIn?: InputMaybe<Array<Scalars["Time"]>>;
	createdDateLT?: InputMaybe<Scalars["Time"]>;
	createdDateLTE?: InputMaybe<Scalars["Time"]>;
	createdDateNEQ?: InputMaybe<Scalars["Time"]>;
	createdDateNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	hasPaidFeeEQ?: InputMaybe<Scalars["Boolean"]>;
	hasPaidFeeNEQ?: InputMaybe<Scalars["Boolean"]>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	isVoidedEQ?: InputMaybe<Scalars["Boolean"]>;
	isVoidedNEQ?: InputMaybe<Scalars["Boolean"]>;
	not?: InputMaybe<SchoolStudentEnrollmentFilterInput>;
	or?: InputMaybe<Array<SchoolStudentEnrollmentFilterInput>>;
	paymentDueDateEQ?: InputMaybe<Scalars["Time"]>;
	paymentDueDateGT?: InputMaybe<Scalars["Time"]>;
	paymentDueDateGTE?: InputMaybe<Scalars["Time"]>;
	paymentDueDateIn?: InputMaybe<Array<Scalars["Time"]>>;
	paymentDueDateLT?: InputMaybe<Scalars["Time"]>;
	paymentDueDateLTE?: InputMaybe<Scalars["Time"]>;
	paymentDueDateNEQ?: InputMaybe<Scalars["Time"]>;
	paymentDueDateNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	student?: InputMaybe<Array<StudentFilterInput>>;
	studentIDEQ?: InputMaybe<Scalars["ID"]>;
	studentIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	studentIDNEQ?: InputMaybe<Scalars["ID"]>;
	studentIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type SchoolStudentEnrollmentHistory = Node & {
	__typename?: "SchoolStudentEnrollmentHistory";
	academicYearID: Maybe<Scalars["ID"]>;
	classYearID: Maybe<Scalars["ID"]>;
	createdDate: Maybe<Scalars["Time"]>;
	hasPaidFee: Maybe<Scalars["Boolean"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	isVoided: Maybe<Scalars["Boolean"]>;
	originalID: Maybe<Scalars["ID"]>;
	paymentDueDate: Maybe<Scalars["Time"]>;
	studentID: Maybe<Scalars["ID"]>;
};

export type SchoolStudentEnrollmentHistoryConnection = {
	__typename?: "SchoolStudentEnrollmentHistoryConnection";
	edges: Array<SchoolStudentEnrollmentHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type SchoolStudentEnrollmentHistoryEdge = {
	__typename?: "SchoolStudentEnrollmentHistoryEdge";
	cursor: Scalars["Cursor"];
	node: SchoolStudentEnrollmentHistory;
};

export type SchoolStudentEnrollmentHistoryFilterInput = {
	academicYearIDEQ?: InputMaybe<Scalars["ID"]>;
	academicYearIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	academicYearIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	academicYearIDNEQ?: InputMaybe<Scalars["ID"]>;
	academicYearIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	and?: InputMaybe<Array<SchoolStudentEnrollmentHistoryFilterInput>>;
	classYearIDEQ?: InputMaybe<Scalars["ID"]>;
	classYearIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	classYearIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	classYearIDNEQ?: InputMaybe<Scalars["ID"]>;
	classYearIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	createdDateEQ?: InputMaybe<Scalars["Time"]>;
	createdDateGT?: InputMaybe<Scalars["Time"]>;
	createdDateGTE?: InputMaybe<Scalars["Time"]>;
	createdDateIn?: InputMaybe<Array<Scalars["Time"]>>;
	createdDateIsNull?: InputMaybe<Scalars["Boolean"]>;
	createdDateLT?: InputMaybe<Scalars["Time"]>;
	createdDateLTE?: InputMaybe<Scalars["Time"]>;
	createdDateNEQ?: InputMaybe<Scalars["Time"]>;
	createdDateNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	hasPaidFeeEQ?: InputMaybe<Scalars["Boolean"]>;
	hasPaidFeeIsNull?: InputMaybe<Scalars["Boolean"]>;
	hasPaidFeeNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	isVoidedEQ?: InputMaybe<Scalars["Boolean"]>;
	isVoidedIsNull?: InputMaybe<Scalars["Boolean"]>;
	isVoidedNEQ?: InputMaybe<Scalars["Boolean"]>;
	not?: InputMaybe<SchoolStudentEnrollmentHistoryFilterInput>;
	or?: InputMaybe<Array<SchoolStudentEnrollmentHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	paymentDueDateEQ?: InputMaybe<Scalars["Time"]>;
	paymentDueDateGT?: InputMaybe<Scalars["Time"]>;
	paymentDueDateGTE?: InputMaybe<Scalars["Time"]>;
	paymentDueDateIn?: InputMaybe<Array<Scalars["Time"]>>;
	paymentDueDateIsNull?: InputMaybe<Scalars["Boolean"]>;
	paymentDueDateLT?: InputMaybe<Scalars["Time"]>;
	paymentDueDateLTE?: InputMaybe<Scalars["Time"]>;
	paymentDueDateNEQ?: InputMaybe<Scalars["Time"]>;
	paymentDueDateNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	studentIDEQ?: InputMaybe<Scalars["ID"]>;
	studentIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	studentIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	studentIDNEQ?: InputMaybe<Scalars["ID"]>;
	studentIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type SchoolStudentEnrollmentHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<SchoolStudentEnrollmentHistoryOrderField>;
};

export enum SchoolStudentEnrollmentHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type SchoolStudentEnrollmentInAcademicYearCreateInput = {
	classYearID: Scalars["ID"];
	createdDate?: InputMaybe<Scalars["Time"]>;
	hasPaidFee?: InputMaybe<Scalars["Boolean"]>;
	isVoided?: InputMaybe<Scalars["Boolean"]>;
	paymentDueDate: Scalars["Time"];
	studentID: Scalars["ID"];
};

export type SchoolStudentEnrollmentInClassYearCreateInput = {
	academicYearID: Scalars["ID"];
	createdDate?: InputMaybe<Scalars["Time"]>;
	hasPaidFee?: InputMaybe<Scalars["Boolean"]>;
	isVoided?: InputMaybe<Scalars["Boolean"]>;
	paymentDueDate: Scalars["Time"];
	studentID: Scalars["ID"];
};

export type SchoolStudentEnrollmentInStudentCreateInput = {
	academicYearID: Scalars["ID"];
	classYearID: Scalars["ID"];
	createdDate?: InputMaybe<Scalars["Time"]>;
	hasPaidFee?: InputMaybe<Scalars["Boolean"]>;
	isVoided?: InputMaybe<Scalars["Boolean"]>;
	paymentDueDate: Scalars["Time"];
};

export type SchoolStudentEnrollmentOrder = {
	direction: OrderDirection;
	field?: InputMaybe<SchoolStudentEnrollmentOrderField>;
};

export enum SchoolStudentEnrollmentOrderField {
	CreatedDate = "CREATED_DATE",
	HasPaidFee = "HAS_PAID_FEE",
	IsVoided = "IS_VOIDED",
	PaymentDueDate = "PAYMENT_DUE_DATE",
}

export type SchoolStudentEnrollmentUpdateBulkInput = {
	academicYearID?: InputMaybe<Scalars["ID"]>;
	classYearID?: InputMaybe<Scalars["ID"]>;
	createdDate?: InputMaybe<Scalars["Time"]>;
	hasPaidFee?: InputMaybe<Scalars["Boolean"]>;
	isVoided?: InputMaybe<Scalars["Boolean"]>;
	paymentDueDate?: InputMaybe<Scalars["Time"]>;
	studentID?: InputMaybe<Scalars["ID"]>;
};

export type SchoolStudentEnrollmentUpdateInput = {
	academicYearID?: InputMaybe<Scalars["ID"]>;
	classYearID?: InputMaybe<Scalars["ID"]>;
	createdDate?: InputMaybe<Scalars["Time"]>;
	hasPaidFee?: InputMaybe<Scalars["Boolean"]>;
	isVoided?: InputMaybe<Scalars["Boolean"]>;
	paymentDueDate?: InputMaybe<Scalars["Time"]>;
	studentID?: InputMaybe<Scalars["ID"]>;
};

export type SearchResult = Node & {
	__typename?: "SearchResult";
	id: Scalars["ID"];
	modelName: Scalars["String"];
	name: Scalars["String"];
	searchText: Scalars["String"];
};

export type SearchResultConnection = {
	__typename?: "SearchResultConnection";
	edges: Array<SearchResultEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type SearchResultEdge = {
	__typename?: "SearchResultEdge";
	cursor: Scalars["Cursor"];
	node: SearchResult;
};

export type SearchResultFilterInput = {
	and?: InputMaybe<Array<SearchResultFilterInput>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	modelNameContains?: InputMaybe<Scalars["String"]>;
	modelNameEQ?: InputMaybe<Scalars["String"]>;
	modelNameHasPrefix?: InputMaybe<Scalars["String"]>;
	modelNameHasSuffix?: InputMaybe<Scalars["String"]>;
	modelNameIn?: InputMaybe<Array<Scalars["String"]>>;
	modelNameNEQ?: InputMaybe<Scalars["String"]>;
	modelNameNotContains?: InputMaybe<Scalars["String"]>;
	modelNameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	modelNameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	modelNameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	nameContains?: InputMaybe<Scalars["String"]>;
	nameEQ?: InputMaybe<Scalars["String"]>;
	nameHasPrefix?: InputMaybe<Scalars["String"]>;
	nameHasSuffix?: InputMaybe<Scalars["String"]>;
	nameIn?: InputMaybe<Array<Scalars["String"]>>;
	nameNEQ?: InputMaybe<Scalars["String"]>;
	nameNotContains?: InputMaybe<Scalars["String"]>;
	nameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	nameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	nameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	not?: InputMaybe<SearchResultFilterInput>;
	or?: InputMaybe<Array<SearchResultFilterInput>>;
	search?: InputMaybe<Scalars["String"]>;
	searchTextContains?: InputMaybe<Scalars["String"]>;
	searchTextEQ?: InputMaybe<Scalars["String"]>;
	searchTextHasPrefix?: InputMaybe<Scalars["String"]>;
	searchTextHasSuffix?: InputMaybe<Scalars["String"]>;
	searchTextIn?: InputMaybe<Array<Scalars["String"]>>;
	searchTextNEQ?: InputMaybe<Scalars["String"]>;
	searchTextNotContains?: InputMaybe<Scalars["String"]>;
	searchTextNotHasPrefix?: InputMaybe<Scalars["String"]>;
	searchTextNotHasSuffix?: InputMaybe<Scalars["String"]>;
	searchTextNotIn?: InputMaybe<Array<Scalars["String"]>>;
};

export type SearchResultOrder = {
	direction: OrderDirection;
	field?: InputMaybe<SearchResultOrderField>;
};

export enum SearchResultOrderField {
	ModelName = "MODEL_NAME",
	Name = "NAME",
}

export type Service = Node & {
	__typename?: "Service";
	description: Scalars["String"];
	id: Scalars["ID"];
	name: Scalars["String"];
	price: Scalars["Int"];
	serviceSubscriptions: Array<ServiceSubscription>;
};

export type ServiceServiceSubscriptionsArgs = {
	filters?: InputMaybe<Array<ServiceSubscriptionFilterInput>>;
	order?: InputMaybe<ServiceSubscriptionOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type ServiceConnection = {
	__typename?: "ServiceConnection";
	edges: Array<ServiceEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type ServiceCreateInput = {
	description?: InputMaybe<Scalars["String"]>;
	name: Scalars["String"];
	price: Scalars["Int"];
	serviceSubscriptionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	serviceSubscriptions?: InputMaybe<Array<ServiceSubscriptionInServiceCreateInput>>;
};

export type ServiceEdge = {
	__typename?: "ServiceEdge";
	cursor: Scalars["Cursor"];
	node: Service;
};

export type ServiceFilterInput = {
	and?: InputMaybe<Array<ServiceFilterInput>>;
	descriptionContains?: InputMaybe<Scalars["String"]>;
	descriptionEQ?: InputMaybe<Scalars["String"]>;
	descriptionHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionIn?: InputMaybe<Array<Scalars["String"]>>;
	descriptionNEQ?: InputMaybe<Scalars["String"]>;
	descriptionNotContains?: InputMaybe<Scalars["String"]>;
	descriptionNotHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionNotHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionNotIn?: InputMaybe<Array<Scalars["String"]>>;
	hasServiceSubscriptions?: InputMaybe<Scalars["Boolean"]>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	nameContains?: InputMaybe<Scalars["String"]>;
	nameEQ?: InputMaybe<Scalars["String"]>;
	nameHasPrefix?: InputMaybe<Scalars["String"]>;
	nameHasSuffix?: InputMaybe<Scalars["String"]>;
	nameIn?: InputMaybe<Array<Scalars["String"]>>;
	nameNEQ?: InputMaybe<Scalars["String"]>;
	nameNotContains?: InputMaybe<Scalars["String"]>;
	nameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	nameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	nameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	not?: InputMaybe<ServiceFilterInput>;
	or?: InputMaybe<Array<ServiceFilterInput>>;
	priceEQ?: InputMaybe<Scalars["Int"]>;
	priceGT?: InputMaybe<Scalars["Int"]>;
	priceGTE?: InputMaybe<Scalars["Int"]>;
	priceIn?: InputMaybe<Array<Scalars["Int"]>>;
	priceLT?: InputMaybe<Scalars["Int"]>;
	priceLTE?: InputMaybe<Scalars["Int"]>;
	priceNEQ?: InputMaybe<Scalars["Int"]>;
	priceNotIn?: InputMaybe<Array<Scalars["Int"]>>;
	search?: InputMaybe<Scalars["String"]>;
	serviceSubscriptions?: InputMaybe<Array<ServiceSubscriptionFilterInput>>;
};

export type ServiceHistory = Node & {
	__typename?: "ServiceHistory";
	description: Maybe<Scalars["String"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	name: Maybe<Scalars["String"]>;
	originalID: Maybe<Scalars["ID"]>;
	price: Maybe<Scalars["Int"]>;
};

export type ServiceHistoryConnection = {
	__typename?: "ServiceHistoryConnection";
	edges: Array<ServiceHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type ServiceHistoryEdge = {
	__typename?: "ServiceHistoryEdge";
	cursor: Scalars["Cursor"];
	node: ServiceHistory;
};

export type ServiceHistoryFilterInput = {
	and?: InputMaybe<Array<ServiceHistoryFilterInput>>;
	descriptionContains?: InputMaybe<Scalars["String"]>;
	descriptionEQ?: InputMaybe<Scalars["String"]>;
	descriptionHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionIn?: InputMaybe<Array<Scalars["String"]>>;
	descriptionIsNull?: InputMaybe<Scalars["Boolean"]>;
	descriptionNEQ?: InputMaybe<Scalars["String"]>;
	descriptionNotContains?: InputMaybe<Scalars["String"]>;
	descriptionNotHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionNotHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionNotIn?: InputMaybe<Array<Scalars["String"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	nameContains?: InputMaybe<Scalars["String"]>;
	nameEQ?: InputMaybe<Scalars["String"]>;
	nameHasPrefix?: InputMaybe<Scalars["String"]>;
	nameHasSuffix?: InputMaybe<Scalars["String"]>;
	nameIn?: InputMaybe<Array<Scalars["String"]>>;
	nameIsNull?: InputMaybe<Scalars["Boolean"]>;
	nameNEQ?: InputMaybe<Scalars["String"]>;
	nameNotContains?: InputMaybe<Scalars["String"]>;
	nameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	nameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	nameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	not?: InputMaybe<ServiceHistoryFilterInput>;
	or?: InputMaybe<Array<ServiceHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	priceEQ?: InputMaybe<Scalars["Int"]>;
	priceGT?: InputMaybe<Scalars["Int"]>;
	priceGTE?: InputMaybe<Scalars["Int"]>;
	priceIn?: InputMaybe<Array<Scalars["Int"]>>;
	priceIsNull?: InputMaybe<Scalars["Boolean"]>;
	priceLT?: InputMaybe<Scalars["Int"]>;
	priceLTE?: InputMaybe<Scalars["Int"]>;
	priceNEQ?: InputMaybe<Scalars["Int"]>;
	priceNotIn?: InputMaybe<Array<Scalars["Int"]>>;
	search?: InputMaybe<Scalars["String"]>;
};

export type ServiceHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<ServiceHistoryOrderField>;
};

export enum ServiceHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type ServiceOrder = {
	direction: OrderDirection;
	field?: InputMaybe<ServiceOrderField>;
};

export enum ServiceOrderField {
	Name = "NAME",
	Price = "PRICE",
}

export type ServiceSubscription = Node & {
	__typename?: "ServiceSubscription";
	contract: Contract;
	contractID: Scalars["ID"];
	discounts: Array<Discount>;
	id: Scalars["ID"];
	invoiceLineItems: Array<InvoiceLineItem>;
	price: Scalars["Int"];
	service: Service;
	serviceID: Scalars["ID"];
};

export type ServiceSubscriptionDiscountsArgs = {
	filters?: InputMaybe<Array<DiscountFilterInput>>;
	order?: InputMaybe<DiscountOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type ServiceSubscriptionInvoiceLineItemsArgs = {
	filters?: InputMaybe<Array<InvoiceLineItemFilterInput>>;
	order?: InputMaybe<InvoiceLineItemOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type ServiceSubscriptionConnection = {
	__typename?: "ServiceSubscriptionConnection";
	edges: Array<ServiceSubscriptionEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type ServiceSubscriptionCreateInput = {
	contractID: Scalars["ID"];
	discountIDs?: InputMaybe<Array<Scalars["ID"]>>;
	discounts?: InputMaybe<Array<DiscountInServiceSubscriptionCreateInput>>;
	invoiceLineItemIDs?: InputMaybe<Array<Scalars["ID"]>>;
	invoiceLineItems?: InputMaybe<Array<InvoiceLineItemInServiceSubscriptionCreateInput>>;
	price: Scalars["Int"];
	serviceID: Scalars["ID"];
};

export type ServiceSubscriptionEdge = {
	__typename?: "ServiceSubscriptionEdge";
	cursor: Scalars["Cursor"];
	node: ServiceSubscription;
};

export type ServiceSubscriptionFilterInput = {
	and?: InputMaybe<Array<ServiceSubscriptionFilterInput>>;
	contract?: InputMaybe<Array<ContractFilterInput>>;
	contractIDEQ?: InputMaybe<Scalars["ID"]>;
	contractIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	contractIDNEQ?: InputMaybe<Scalars["ID"]>;
	contractIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	discounts?: InputMaybe<Array<DiscountFilterInput>>;
	hasDiscounts?: InputMaybe<Scalars["Boolean"]>;
	hasInvoiceLineItems?: InputMaybe<Scalars["Boolean"]>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	invoiceLineItems?: InputMaybe<Array<InvoiceLineItemFilterInput>>;
	not?: InputMaybe<ServiceSubscriptionFilterInput>;
	or?: InputMaybe<Array<ServiceSubscriptionFilterInput>>;
	priceEQ?: InputMaybe<Scalars["Int"]>;
	priceGT?: InputMaybe<Scalars["Int"]>;
	priceGTE?: InputMaybe<Scalars["Int"]>;
	priceIn?: InputMaybe<Array<Scalars["Int"]>>;
	priceLT?: InputMaybe<Scalars["Int"]>;
	priceLTE?: InputMaybe<Scalars["Int"]>;
	priceNEQ?: InputMaybe<Scalars["Int"]>;
	priceNotIn?: InputMaybe<Array<Scalars["Int"]>>;
	service?: InputMaybe<Array<ServiceFilterInput>>;
	serviceIDEQ?: InputMaybe<Scalars["ID"]>;
	serviceIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	serviceIDNEQ?: InputMaybe<Scalars["ID"]>;
	serviceIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type ServiceSubscriptionHistory = Node & {
	__typename?: "ServiceSubscriptionHistory";
	contractID: Maybe<Scalars["ID"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	originalID: Maybe<Scalars["ID"]>;
	price: Maybe<Scalars["Int"]>;
	serviceID: Maybe<Scalars["ID"]>;
};

export type ServiceSubscriptionHistoryConnection = {
	__typename?: "ServiceSubscriptionHistoryConnection";
	edges: Array<ServiceSubscriptionHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type ServiceSubscriptionHistoryEdge = {
	__typename?: "ServiceSubscriptionHistoryEdge";
	cursor: Scalars["Cursor"];
	node: ServiceSubscriptionHistory;
};

export type ServiceSubscriptionHistoryFilterInput = {
	and?: InputMaybe<Array<ServiceSubscriptionHistoryFilterInput>>;
	contractIDEQ?: InputMaybe<Scalars["ID"]>;
	contractIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	contractIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	contractIDNEQ?: InputMaybe<Scalars["ID"]>;
	contractIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<ServiceSubscriptionHistoryFilterInput>;
	or?: InputMaybe<Array<ServiceSubscriptionHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	priceEQ?: InputMaybe<Scalars["Int"]>;
	priceGT?: InputMaybe<Scalars["Int"]>;
	priceGTE?: InputMaybe<Scalars["Int"]>;
	priceIn?: InputMaybe<Array<Scalars["Int"]>>;
	priceIsNull?: InputMaybe<Scalars["Boolean"]>;
	priceLT?: InputMaybe<Scalars["Int"]>;
	priceLTE?: InputMaybe<Scalars["Int"]>;
	priceNEQ?: InputMaybe<Scalars["Int"]>;
	priceNotIn?: InputMaybe<Array<Scalars["Int"]>>;
	serviceIDEQ?: InputMaybe<Scalars["ID"]>;
	serviceIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	serviceIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	serviceIDNEQ?: InputMaybe<Scalars["ID"]>;
	serviceIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type ServiceSubscriptionHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<ServiceSubscriptionHistoryOrderField>;
};

export enum ServiceSubscriptionHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type ServiceSubscriptionInContractCreateInput = {
	discountIDs?: InputMaybe<Array<Scalars["ID"]>>;
	discounts?: InputMaybe<Array<DiscountInServiceSubscriptionCreateInput>>;
	invoiceLineItemIDs?: InputMaybe<Array<Scalars["ID"]>>;
	invoiceLineItems?: InputMaybe<Array<InvoiceLineItemInServiceSubscriptionCreateInput>>;
	price: Scalars["Int"];
	serviceID: Scalars["ID"];
};

export type ServiceSubscriptionInServiceCreateInput = {
	contractID: Scalars["ID"];
	discountIDs?: InputMaybe<Array<Scalars["ID"]>>;
	discounts?: InputMaybe<Array<DiscountInServiceSubscriptionCreateInput>>;
	invoiceLineItemIDs?: InputMaybe<Array<Scalars["ID"]>>;
	invoiceLineItems?: InputMaybe<Array<InvoiceLineItemInServiceSubscriptionCreateInput>>;
	price: Scalars["Int"];
};

export type ServiceSubscriptionOrder = {
	direction: OrderDirection;
	field?: InputMaybe<ServiceSubscriptionOrderField>;
};

export enum ServiceSubscriptionOrderField {
	Price = "PRICE",
}

export type ServiceSubscriptionUpdateBulkInput = {
	addDiscountIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addInvoiceLineItemIDs?: InputMaybe<Array<Scalars["ID"]>>;
	contractID?: InputMaybe<Scalars["ID"]>;
	price?: InputMaybe<Scalars["Int"]>;
	removeDiscountIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeInvoiceLineItemIDs?: InputMaybe<Array<Scalars["ID"]>>;
	serviceID?: InputMaybe<Scalars["ID"]>;
};

export type ServiceSubscriptionUpdateInput = {
	addDiscountIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addInvoiceLineItemIDs?: InputMaybe<Array<Scalars["ID"]>>;
	contractID?: InputMaybe<Scalars["ID"]>;
	price?: InputMaybe<Scalars["Int"]>;
	removeDiscountIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeInvoiceLineItemIDs?: InputMaybe<Array<Scalars["ID"]>>;
	serviceID?: InputMaybe<Scalars["ID"]>;
};

export type ServiceUpdateBulkInput = {
	addServiceSubscriptionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	description?: InputMaybe<Scalars["String"]>;
	name?: InputMaybe<Scalars["String"]>;
	price?: InputMaybe<Scalars["Int"]>;
	removeServiceSubscriptionIDs?: InputMaybe<Array<Scalars["ID"]>>;
};

export type ServiceUpdateInput = {
	addServiceSubscriptionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	description?: InputMaybe<Scalars["String"]>;
	name?: InputMaybe<Scalars["String"]>;
	price?: InputMaybe<Scalars["Int"]>;
	removeServiceSubscriptionIDs?: InputMaybe<Array<Scalars["ID"]>>;
};

export type Session = Node & {
	__typename?: "Session";
	createdAt: Scalars["Time"];
	expiresAt: Scalars["Time"];
	id: Scalars["ID"];
	isCurrent: Scalars["Boolean"];
	isVerified: Scalars["Boolean"];
	loginRecord: Maybe<LoginRecord>;
	longestInactiveSec: Scalars["Int"];
	mfaInvalidOTPAttempts: Array<MFAInvalidOTPAttempt>;
	trustedDevice: Maybe<TrustedDevice>;
	trustedDeviceID: Maybe<Scalars["ID"]>;
	updatedAt: Scalars["Time"];
	user: User;
	userID: Scalars["ID"];
};

export type SessionMFAInvalidOTPAttemptsArgs = {
	filters?: InputMaybe<Array<MFAInvalidOTPAttemptFilterInput>>;
	order?: InputMaybe<MFAInvalidOTPAttemptOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type SessionConnection = {
	__typename?: "SessionConnection";
	edges: Array<SessionEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type SessionEdge = {
	__typename?: "SessionEdge";
	cursor: Scalars["Cursor"];
	node: Session;
};

export type SessionFilterInput = {
	and?: InputMaybe<Array<SessionFilterInput>>;
	createdAtEQ?: InputMaybe<Scalars["Time"]>;
	createdAtGT?: InputMaybe<Scalars["Time"]>;
	createdAtGTE?: InputMaybe<Scalars["Time"]>;
	createdAtIn?: InputMaybe<Array<Scalars["Time"]>>;
	createdAtLT?: InputMaybe<Scalars["Time"]>;
	createdAtLTE?: InputMaybe<Scalars["Time"]>;
	createdAtNEQ?: InputMaybe<Scalars["Time"]>;
	createdAtNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	expiresAtEQ?: InputMaybe<Scalars["Time"]>;
	expiresAtGT?: InputMaybe<Scalars["Time"]>;
	expiresAtGTE?: InputMaybe<Scalars["Time"]>;
	expiresAtIn?: InputMaybe<Array<Scalars["Time"]>>;
	expiresAtLT?: InputMaybe<Scalars["Time"]>;
	expiresAtLTE?: InputMaybe<Scalars["Time"]>;
	expiresAtNEQ?: InputMaybe<Scalars["Time"]>;
	expiresAtNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	hasLoginRecord?: InputMaybe<Scalars["Boolean"]>;
	hasMFAInvalidOTPAttempts?: InputMaybe<Scalars["Boolean"]>;
	hasTrustedDevice?: InputMaybe<Scalars["Boolean"]>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	isVerifiedEQ?: InputMaybe<Scalars["Boolean"]>;
	isVerifiedNEQ?: InputMaybe<Scalars["Boolean"]>;
	loginRecord?: InputMaybe<Array<LoginRecordFilterInput>>;
	longestInactiveSecEQ?: InputMaybe<Scalars["Int"]>;
	longestInactiveSecGT?: InputMaybe<Scalars["Int"]>;
	longestInactiveSecGTE?: InputMaybe<Scalars["Int"]>;
	longestInactiveSecIn?: InputMaybe<Array<Scalars["Int"]>>;
	longestInactiveSecLT?: InputMaybe<Scalars["Int"]>;
	longestInactiveSecLTE?: InputMaybe<Scalars["Int"]>;
	longestInactiveSecNEQ?: InputMaybe<Scalars["Int"]>;
	longestInactiveSecNotIn?: InputMaybe<Array<Scalars["Int"]>>;
	mfaInvalidOTPAttempts?: InputMaybe<Array<MFAInvalidOTPAttemptFilterInput>>;
	not?: InputMaybe<SessionFilterInput>;
	or?: InputMaybe<Array<SessionFilterInput>>;
	trustedDevice?: InputMaybe<Array<TrustedDeviceFilterInput>>;
	trustedDeviceIDEQ?: InputMaybe<Scalars["ID"]>;
	trustedDeviceIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	trustedDeviceIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	trustedDeviceIDNEQ?: InputMaybe<Scalars["ID"]>;
	trustedDeviceIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	updatedAtEQ?: InputMaybe<Scalars["Time"]>;
	updatedAtGT?: InputMaybe<Scalars["Time"]>;
	updatedAtGTE?: InputMaybe<Scalars["Time"]>;
	updatedAtIn?: InputMaybe<Array<Scalars["Time"]>>;
	updatedAtLT?: InputMaybe<Scalars["Time"]>;
	updatedAtLTE?: InputMaybe<Scalars["Time"]>;
	updatedAtNEQ?: InputMaybe<Scalars["Time"]>;
	updatedAtNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	user?: InputMaybe<Array<UserFilterInput>>;
	userIDEQ?: InputMaybe<Scalars["ID"]>;
	userIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	userIDNEQ?: InputMaybe<Scalars["ID"]>;
	userIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type SessionOrder = {
	direction: OrderDirection;
	field?: InputMaybe<SessionOrderField>;
};

export enum SessionOrderField {
	CreatedAt = "CREATED_AT",
	ExpiresAt = "EXPIRES_AT",
	UpdatedAt = "UPDATED_AT",
}

export type Student = Node & {
	__typename?: "Student";
	allergies: Scalars["String"];
	asthma: StudentAsthma;
	billingSetupSubmissions: Array<BillingSetupSubmission>;
	busPlans: Array<BusPlan>;
	contracts: Array<Contract>;
	courseAttendanceRecords: Array<CourseAttendanceRecord>;
	courseSectionStudentEnrollments: Array<CourseSectionStudentEnrollment>;
	dietaryRestrictions: Scalars["String"];
	doctorName: Scalars["String"];
	doctorPhoneNumber: Scalars["String"];
	emergencyContactInformation: Scalars["String"];
	enrollmentApplicationStudent: Maybe<EnrollmentApplicationStudent>;
	enrollmentInvitations: Array<EnrollmentInvitation>;
	graduatingYear: Scalars["Int"];
	hasPreviousSchooling: Scalars["Boolean"];
	healthNotes: Scalars["String"];
	homeRoomSectionStudentEnrollments: Array<HomeRoomSectionStudentEnrollment>;
	id: Scalars["ID"];
	mealPlans: Array<MealPlan>;
	medication: Scalars["String"];
	medicationSchedule: Scalars["String"];
	notes: Array<Note>;
	person: Person;
	personID: Scalars["ID"];
	previousSchoolInformation: Scalars["String"];
	previousSchoolLocation: Scalars["String"];
	relationships: Array<Relationship>;
	reportCards: Array<ReportCard>;
	schoolAttendanceRecords: Array<SchoolAttendanceRecord>;
	schoolStudentEnrollments: Array<SchoolStudentEnrollment>;
	studentDocuments: Array<StudentDocument>;
	studentIDNumber: Scalars["String"];
	tuition: Maybe<Tuition>;
	tuitionID: Maybe<Scalars["ID"]>;
};

export type StudentBillingSetupSubmissionsArgs = {
	filters?: InputMaybe<Array<BillingSetupSubmissionFilterInput>>;
	order?: InputMaybe<BillingSetupSubmissionOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type StudentBusPlansArgs = {
	filters?: InputMaybe<Array<BusPlanFilterInput>>;
	order?: InputMaybe<BusPlanOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type StudentContractsArgs = {
	filters?: InputMaybe<Array<ContractFilterInput>>;
	order?: InputMaybe<ContractOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type StudentCourseAttendanceRecordsArgs = {
	filters?: InputMaybe<Array<CourseAttendanceRecordFilterInput>>;
	order?: InputMaybe<CourseAttendanceRecordOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type StudentCourseSectionStudentEnrollmentsArgs = {
	filters?: InputMaybe<Array<CourseSectionStudentEnrollmentFilterInput>>;
	order?: InputMaybe<CourseSectionStudentEnrollmentOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type StudentEnrollmentInvitationsArgs = {
	filters?: InputMaybe<Array<EnrollmentInvitationFilterInput>>;
	order?: InputMaybe<EnrollmentInvitationOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type StudentHomeRoomSectionStudentEnrollmentsArgs = {
	filters?: InputMaybe<Array<HomeRoomSectionStudentEnrollmentFilterInput>>;
	order?: InputMaybe<HomeRoomSectionStudentEnrollmentOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type StudentMealPlansArgs = {
	filters?: InputMaybe<Array<MealPlanFilterInput>>;
	order?: InputMaybe<MealPlanOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type StudentNotesArgs = {
	filters?: InputMaybe<Array<NoteFilterInput>>;
	order?: InputMaybe<NoteOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type StudentRelationshipsArgs = {
	filters?: InputMaybe<Array<RelationshipFilterInput>>;
	order?: InputMaybe<RelationshipOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type StudentReportCardsArgs = {
	filters?: InputMaybe<Array<ReportCardFilterInput>>;
	order?: InputMaybe<ReportCardOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type StudentSchoolAttendanceRecordsArgs = {
	filters?: InputMaybe<Array<SchoolAttendanceRecordFilterInput>>;
	order?: InputMaybe<SchoolAttendanceRecordOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type StudentSchoolStudentEnrollmentsArgs = {
	filters?: InputMaybe<Array<SchoolStudentEnrollmentFilterInput>>;
	order?: InputMaybe<SchoolStudentEnrollmentOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type StudentStudentDocumentsArgs = {
	filters?: InputMaybe<Array<StudentDocumentFilterInput>>;
	order?: InputMaybe<StudentDocumentOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export enum StudentAsthma {
	Mild = "mild",
	Moderate = "moderate",
	None = "none",
	Severe = "severe",
}

export type StudentConnection = {
	__typename?: "StudentConnection";
	edges: Array<StudentEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type StudentCreateInput = {
	allergies?: InputMaybe<Scalars["String"]>;
	asthma?: InputMaybe<StudentAsthma>;
	billingSetupSubmissionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	billingSetupSubmissions?: InputMaybe<Array<BillingSetupSubmissionInStudentCreateInput>>;
	busPlanIDs?: InputMaybe<Array<Scalars["ID"]>>;
	busPlans?: InputMaybe<Array<BusPlanInStudentCreateInput>>;
	contractIDs?: InputMaybe<Array<Scalars["ID"]>>;
	contracts?: InputMaybe<Array<ContractInStudentCreateInput>>;
	courseAttendanceRecordIDs?: InputMaybe<Array<Scalars["ID"]>>;
	courseAttendanceRecords?: InputMaybe<Array<CourseAttendanceRecordInStudentCreateInput>>;
	courseSectionStudentEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	courseSectionStudentEnrollments?: InputMaybe<Array<CourseSectionStudentEnrollmentInStudentCreateInput>>;
	dietaryRestrictions?: InputMaybe<Scalars["String"]>;
	doctorName?: InputMaybe<Scalars["String"]>;
	doctorPhoneNumber?: InputMaybe<Scalars["String"]>;
	emergencyContactInformation?: InputMaybe<Scalars["String"]>;
	enrollmentApplicationStudent?: InputMaybe<EnrollmentApplicationStudentInStudentCreateInput>;
	enrollmentApplicationStudentID?: InputMaybe<Scalars["ID"]>;
	enrollmentInvitationIDs?: InputMaybe<Array<Scalars["ID"]>>;
	enrollmentInvitations?: InputMaybe<Array<EnrollmentInvitationInStudentCreateInput>>;
	graduatingYear: Scalars["Int"];
	hasPreviousSchooling?: InputMaybe<Scalars["Boolean"]>;
	healthNotes?: InputMaybe<Scalars["String"]>;
	homeRoomSectionStudentEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	homeRoomSectionStudentEnrollments?: InputMaybe<Array<HomeRoomSectionStudentEnrollmentInStudentCreateInput>>;
	mealPlanIDs?: InputMaybe<Array<Scalars["ID"]>>;
	mealPlans?: InputMaybe<Array<MealPlanInStudentCreateInput>>;
	medication?: InputMaybe<Scalars["String"]>;
	medicationSchedule?: InputMaybe<Scalars["String"]>;
	noteIDs?: InputMaybe<Array<Scalars["ID"]>>;
	person?: InputMaybe<PersonInStudentCreateInput>;
	personID?: InputMaybe<Scalars["ID"]>;
	previousSchoolInformation?: InputMaybe<Scalars["String"]>;
	previousSchoolLocation?: InputMaybe<Scalars["String"]>;
	relationshipIDs?: InputMaybe<Array<Scalars["ID"]>>;
	relationships?: InputMaybe<Array<RelationshipInStudentCreateInput>>;
	reportCardIDs?: InputMaybe<Array<Scalars["ID"]>>;
	reportCards?: InputMaybe<Array<ReportCardInStudentCreateInput>>;
	schoolAttendanceRecordIDs?: InputMaybe<Array<Scalars["ID"]>>;
	schoolAttendanceRecords?: InputMaybe<Array<SchoolAttendanceRecordInStudentCreateInput>>;
	schoolStudentEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	schoolStudentEnrollments?: InputMaybe<Array<SchoolStudentEnrollmentInStudentCreateInput>>;
	studentDocumentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	studentDocuments?: InputMaybe<Array<StudentDocumentInStudentCreateInput>>;
	tuitionID?: InputMaybe<Scalars["ID"]>;
};

export type StudentDocument = Node & {
	__typename?: "StudentDocument";
	author: Maybe<User>;
	authorID: Maybe<Scalars["ID"]>;
	createdAt: Scalars["Time"];
	description: Scalars["String"];
	fileContentType: Scalars["String"];
	fileURL: Scalars["String"];
	id: Scalars["ID"];
	isPublic: Scalars["Boolean"];
	name: Scalars["String"];
	sharingUserGroups: Array<UserGroup>;
	student: Student;
	studentID: Scalars["ID"];
};

export type StudentDocumentSharingUserGroupsArgs = {
	filters?: InputMaybe<Array<UserGroupFilterInput>>;
	order?: InputMaybe<UserGroupOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type StudentDocumentConnection = {
	__typename?: "StudentDocumentConnection";
	edges: Array<StudentDocumentEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type StudentDocumentCreateInput = {
	description?: InputMaybe<Scalars["String"]>;
	file?: InputMaybe<Scalars["Upload"]>;
	isPublic?: InputMaybe<Scalars["Boolean"]>;
	name: Scalars["String"];
	sharingUserGroupIDs?: InputMaybe<Array<Scalars["ID"]>>;
	studentID: Scalars["ID"];
};

export type StudentDocumentEdge = {
	__typename?: "StudentDocumentEdge";
	cursor: Scalars["Cursor"];
	node: StudentDocument;
};

export type StudentDocumentFilterInput = {
	and?: InputMaybe<Array<StudentDocumentFilterInput>>;
	author?: InputMaybe<Array<UserFilterInput>>;
	authorIDEQ?: InputMaybe<Scalars["ID"]>;
	authorIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	authorIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	authorIDNEQ?: InputMaybe<Scalars["ID"]>;
	authorIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	createdAtEQ?: InputMaybe<Scalars["Time"]>;
	createdAtGT?: InputMaybe<Scalars["Time"]>;
	createdAtGTE?: InputMaybe<Scalars["Time"]>;
	createdAtIn?: InputMaybe<Array<Scalars["Time"]>>;
	createdAtLT?: InputMaybe<Scalars["Time"]>;
	createdAtLTE?: InputMaybe<Scalars["Time"]>;
	createdAtNEQ?: InputMaybe<Scalars["Time"]>;
	createdAtNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	descriptionContains?: InputMaybe<Scalars["String"]>;
	descriptionEQ?: InputMaybe<Scalars["String"]>;
	descriptionHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionIn?: InputMaybe<Array<Scalars["String"]>>;
	descriptionNEQ?: InputMaybe<Scalars["String"]>;
	descriptionNotContains?: InputMaybe<Scalars["String"]>;
	descriptionNotHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionNotHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionNotIn?: InputMaybe<Array<Scalars["String"]>>;
	fileContentTypeContains?: InputMaybe<Scalars["String"]>;
	fileContentTypeEQ?: InputMaybe<Scalars["String"]>;
	fileContentTypeHasPrefix?: InputMaybe<Scalars["String"]>;
	fileContentTypeHasSuffix?: InputMaybe<Scalars["String"]>;
	fileContentTypeIn?: InputMaybe<Array<Scalars["String"]>>;
	fileContentTypeNEQ?: InputMaybe<Scalars["String"]>;
	fileContentTypeNotContains?: InputMaybe<Scalars["String"]>;
	fileContentTypeNotHasPrefix?: InputMaybe<Scalars["String"]>;
	fileContentTypeNotHasSuffix?: InputMaybe<Scalars["String"]>;
	fileContentTypeNotIn?: InputMaybe<Array<Scalars["String"]>>;
	fileIsNull?: InputMaybe<Scalars["Boolean"]>;
	hasAuthor?: InputMaybe<Scalars["Boolean"]>;
	hasSharingUserGroups?: InputMaybe<Scalars["Boolean"]>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	isPublicEQ?: InputMaybe<Scalars["Boolean"]>;
	isPublicNEQ?: InputMaybe<Scalars["Boolean"]>;
	nameContains?: InputMaybe<Scalars["String"]>;
	nameEQ?: InputMaybe<Scalars["String"]>;
	nameHasPrefix?: InputMaybe<Scalars["String"]>;
	nameHasSuffix?: InputMaybe<Scalars["String"]>;
	nameIn?: InputMaybe<Array<Scalars["String"]>>;
	nameNEQ?: InputMaybe<Scalars["String"]>;
	nameNotContains?: InputMaybe<Scalars["String"]>;
	nameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	nameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	nameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	not?: InputMaybe<StudentDocumentFilterInput>;
	or?: InputMaybe<Array<StudentDocumentFilterInput>>;
	search?: InputMaybe<Scalars["String"]>;
	sharingUserGroups?: InputMaybe<Array<UserGroupFilterInput>>;
	student?: InputMaybe<Array<StudentFilterInput>>;
	studentIDEQ?: InputMaybe<Scalars["ID"]>;
	studentIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	studentIDNEQ?: InputMaybe<Scalars["ID"]>;
	studentIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type StudentDocumentHistory = Node & {
	__typename?: "StudentDocumentHistory";
	authorID: Maybe<Scalars["ID"]>;
	createdAt: Maybe<Scalars["Time"]>;
	description: Maybe<Scalars["String"]>;
	fileContentType: Maybe<Scalars["String"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	isPublic: Maybe<Scalars["Boolean"]>;
	name: Maybe<Scalars["String"]>;
	originalID: Maybe<Scalars["ID"]>;
	studentID: Maybe<Scalars["ID"]>;
};

export type StudentDocumentHistoryConnection = {
	__typename?: "StudentDocumentHistoryConnection";
	edges: Array<StudentDocumentHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type StudentDocumentHistoryEdge = {
	__typename?: "StudentDocumentHistoryEdge";
	cursor: Scalars["Cursor"];
	node: StudentDocumentHistory;
};

export type StudentDocumentHistoryFilterInput = {
	and?: InputMaybe<Array<StudentDocumentHistoryFilterInput>>;
	authorIDEQ?: InputMaybe<Scalars["ID"]>;
	authorIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	authorIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	authorIDNEQ?: InputMaybe<Scalars["ID"]>;
	authorIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	createdAtEQ?: InputMaybe<Scalars["Time"]>;
	createdAtGT?: InputMaybe<Scalars["Time"]>;
	createdAtGTE?: InputMaybe<Scalars["Time"]>;
	createdAtIn?: InputMaybe<Array<Scalars["Time"]>>;
	createdAtIsNull?: InputMaybe<Scalars["Boolean"]>;
	createdAtLT?: InputMaybe<Scalars["Time"]>;
	createdAtLTE?: InputMaybe<Scalars["Time"]>;
	createdAtNEQ?: InputMaybe<Scalars["Time"]>;
	createdAtNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	descriptionContains?: InputMaybe<Scalars["String"]>;
	descriptionEQ?: InputMaybe<Scalars["String"]>;
	descriptionHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionIn?: InputMaybe<Array<Scalars["String"]>>;
	descriptionIsNull?: InputMaybe<Scalars["Boolean"]>;
	descriptionNEQ?: InputMaybe<Scalars["String"]>;
	descriptionNotContains?: InputMaybe<Scalars["String"]>;
	descriptionNotHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionNotHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionNotIn?: InputMaybe<Array<Scalars["String"]>>;
	fileContentTypeContains?: InputMaybe<Scalars["String"]>;
	fileContentTypeEQ?: InputMaybe<Scalars["String"]>;
	fileContentTypeHasPrefix?: InputMaybe<Scalars["String"]>;
	fileContentTypeHasSuffix?: InputMaybe<Scalars["String"]>;
	fileContentTypeIn?: InputMaybe<Array<Scalars["String"]>>;
	fileContentTypeIsNull?: InputMaybe<Scalars["Boolean"]>;
	fileContentTypeNEQ?: InputMaybe<Scalars["String"]>;
	fileContentTypeNotContains?: InputMaybe<Scalars["String"]>;
	fileContentTypeNotHasPrefix?: InputMaybe<Scalars["String"]>;
	fileContentTypeNotHasSuffix?: InputMaybe<Scalars["String"]>;
	fileContentTypeNotIn?: InputMaybe<Array<Scalars["String"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	isPublicEQ?: InputMaybe<Scalars["Boolean"]>;
	isPublicIsNull?: InputMaybe<Scalars["Boolean"]>;
	isPublicNEQ?: InputMaybe<Scalars["Boolean"]>;
	nameContains?: InputMaybe<Scalars["String"]>;
	nameEQ?: InputMaybe<Scalars["String"]>;
	nameHasPrefix?: InputMaybe<Scalars["String"]>;
	nameHasSuffix?: InputMaybe<Scalars["String"]>;
	nameIn?: InputMaybe<Array<Scalars["String"]>>;
	nameIsNull?: InputMaybe<Scalars["Boolean"]>;
	nameNEQ?: InputMaybe<Scalars["String"]>;
	nameNotContains?: InputMaybe<Scalars["String"]>;
	nameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	nameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	nameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	not?: InputMaybe<StudentDocumentHistoryFilterInput>;
	or?: InputMaybe<Array<StudentDocumentHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	search?: InputMaybe<Scalars["String"]>;
	studentIDEQ?: InputMaybe<Scalars["ID"]>;
	studentIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	studentIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	studentIDNEQ?: InputMaybe<Scalars["ID"]>;
	studentIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type StudentDocumentHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<StudentDocumentHistoryOrderField>;
};

export enum StudentDocumentHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type StudentDocumentInAuthorCreateInput = {
	description?: InputMaybe<Scalars["String"]>;
	file?: InputMaybe<Scalars["Upload"]>;
	isPublic?: InputMaybe<Scalars["Boolean"]>;
	name: Scalars["String"];
	sharingUserGroupIDs?: InputMaybe<Array<Scalars["ID"]>>;
	studentID: Scalars["ID"];
};

export type StudentDocumentInStudentCreateInput = {
	description?: InputMaybe<Scalars["String"]>;
	file?: InputMaybe<Scalars["Upload"]>;
	isPublic?: InputMaybe<Scalars["Boolean"]>;
	name: Scalars["String"];
	sharingUserGroupIDs?: InputMaybe<Array<Scalars["ID"]>>;
};

export type StudentDocumentOrder = {
	direction: OrderDirection;
	field?: InputMaybe<StudentDocumentOrderField>;
};

export enum StudentDocumentOrderField {
	CreatedAt = "CREATED_AT",
	IsPublic = "IS_PUBLIC",
	Name = "NAME",
}

export type StudentDocumentSharedStudentDocumentUserGroupSharingUserGroupHistory = Node & {
	__typename?: "StudentDocumentSharedStudentDocumentUserGroupSharingUserGroupHistory";
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	studentDocumentID: Maybe<Scalars["ID"]>;
	userGroupID: Maybe<Scalars["ID"]>;
};

export type StudentDocumentSharedStudentDocumentUserGroupSharingUserGroupHistoryConnection = {
	__typename?: "StudentDocumentSharedStudentDocumentUserGroupSharingUserGroupHistoryConnection";
	edges: Array<StudentDocumentSharedStudentDocumentUserGroupSharingUserGroupHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type StudentDocumentSharedStudentDocumentUserGroupSharingUserGroupHistoryEdge = {
	__typename?: "StudentDocumentSharedStudentDocumentUserGroupSharingUserGroupHistoryEdge";
	cursor: Scalars["Cursor"];
	node: StudentDocumentSharedStudentDocumentUserGroupSharingUserGroupHistory;
};

export type StudentDocumentSharedStudentDocumentUserGroupSharingUserGroupHistoryFilterInput = {
	and?: InputMaybe<Array<StudentDocumentSharedStudentDocumentUserGroupSharingUserGroupHistoryFilterInput>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<StudentDocumentSharedStudentDocumentUserGroupSharingUserGroupHistoryFilterInput>;
	or?: InputMaybe<Array<StudentDocumentSharedStudentDocumentUserGroupSharingUserGroupHistoryFilterInput>>;
	studentDocumentIDEQ?: InputMaybe<Scalars["ID"]>;
	studentDocumentIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	studentDocumentIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	studentDocumentIDNEQ?: InputMaybe<Scalars["ID"]>;
	studentDocumentIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	userGroupIDEQ?: InputMaybe<Scalars["ID"]>;
	userGroupIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	userGroupIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	userGroupIDNEQ?: InputMaybe<Scalars["ID"]>;
	userGroupIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type StudentDocumentSharedStudentDocumentUserGroupSharingUserGroupHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<StudentDocumentSharedStudentDocumentUserGroupSharingUserGroupHistoryOrderField>;
};

export enum StudentDocumentSharedStudentDocumentUserGroupSharingUserGroupHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type StudentDocumentUpdateBulkInput = {
	addSharingUserGroupIDs?: InputMaybe<Array<Scalars["ID"]>>;
	clearFile?: InputMaybe<Scalars["Boolean"]>;
	description?: InputMaybe<Scalars["String"]>;
	file?: InputMaybe<Scalars["Upload"]>;
	isPublic?: InputMaybe<Scalars["Boolean"]>;
	name?: InputMaybe<Scalars["String"]>;
	removeSharingUserGroupIDs?: InputMaybe<Array<Scalars["ID"]>>;
	studentID?: InputMaybe<Scalars["ID"]>;
};

export type StudentDocumentUpdateInput = {
	addSharingUserGroupIDs?: InputMaybe<Array<Scalars["ID"]>>;
	clearFile?: InputMaybe<Scalars["Boolean"]>;
	description?: InputMaybe<Scalars["String"]>;
	file?: InputMaybe<Scalars["Upload"]>;
	isPublic?: InputMaybe<Scalars["Boolean"]>;
	name?: InputMaybe<Scalars["String"]>;
	removeSharingUserGroupIDs?: InputMaybe<Array<Scalars["ID"]>>;
	studentID?: InputMaybe<Scalars["ID"]>;
};

export type StudentEdge = {
	__typename?: "StudentEdge";
	cursor: Scalars["Cursor"];
	node: Student;
};

export type StudentFilterInput = {
	allergiesContains?: InputMaybe<Scalars["String"]>;
	allergiesEQ?: InputMaybe<Scalars["String"]>;
	allergiesHasPrefix?: InputMaybe<Scalars["String"]>;
	allergiesHasSuffix?: InputMaybe<Scalars["String"]>;
	allergiesIn?: InputMaybe<Array<Scalars["String"]>>;
	allergiesNEQ?: InputMaybe<Scalars["String"]>;
	allergiesNotContains?: InputMaybe<Scalars["String"]>;
	allergiesNotHasPrefix?: InputMaybe<Scalars["String"]>;
	allergiesNotHasSuffix?: InputMaybe<Scalars["String"]>;
	allergiesNotIn?: InputMaybe<Array<Scalars["String"]>>;
	and?: InputMaybe<Array<StudentFilterInput>>;
	asthmaEQ?: InputMaybe<StudentAsthma>;
	asthmaIn?: InputMaybe<Array<StudentAsthma>>;
	asthmaNEQ?: InputMaybe<StudentAsthma>;
	asthmaNotIn?: InputMaybe<Array<StudentAsthma>>;
	billingSetupSubmissions?: InputMaybe<Array<BillingSetupSubmissionFilterInput>>;
	busPlans?: InputMaybe<Array<BusPlanFilterInput>>;
	contracts?: InputMaybe<Array<ContractFilterInput>>;
	courseAttendanceRecords?: InputMaybe<Array<CourseAttendanceRecordFilterInput>>;
	courseSectionStudentEnrollments?: InputMaybe<Array<CourseSectionStudentEnrollmentFilterInput>>;
	dietaryRestrictionsContains?: InputMaybe<Scalars["String"]>;
	dietaryRestrictionsEQ?: InputMaybe<Scalars["String"]>;
	dietaryRestrictionsHasPrefix?: InputMaybe<Scalars["String"]>;
	dietaryRestrictionsHasSuffix?: InputMaybe<Scalars["String"]>;
	dietaryRestrictionsIn?: InputMaybe<Array<Scalars["String"]>>;
	dietaryRestrictionsNEQ?: InputMaybe<Scalars["String"]>;
	dietaryRestrictionsNotContains?: InputMaybe<Scalars["String"]>;
	dietaryRestrictionsNotHasPrefix?: InputMaybe<Scalars["String"]>;
	dietaryRestrictionsNotHasSuffix?: InputMaybe<Scalars["String"]>;
	dietaryRestrictionsNotIn?: InputMaybe<Array<Scalars["String"]>>;
	doctorNameContains?: InputMaybe<Scalars["String"]>;
	doctorNameEQ?: InputMaybe<Scalars["String"]>;
	doctorNameHasPrefix?: InputMaybe<Scalars["String"]>;
	doctorNameHasSuffix?: InputMaybe<Scalars["String"]>;
	doctorNameIn?: InputMaybe<Array<Scalars["String"]>>;
	doctorNameNEQ?: InputMaybe<Scalars["String"]>;
	doctorNameNotContains?: InputMaybe<Scalars["String"]>;
	doctorNameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	doctorNameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	doctorNameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	doctorPhoneNumberContains?: InputMaybe<Scalars["String"]>;
	doctorPhoneNumberEQ?: InputMaybe<Scalars["String"]>;
	doctorPhoneNumberHasPrefix?: InputMaybe<Scalars["String"]>;
	doctorPhoneNumberHasSuffix?: InputMaybe<Scalars["String"]>;
	doctorPhoneNumberIn?: InputMaybe<Array<Scalars["String"]>>;
	doctorPhoneNumberNEQ?: InputMaybe<Scalars["String"]>;
	doctorPhoneNumberNotContains?: InputMaybe<Scalars["String"]>;
	doctorPhoneNumberNotHasPrefix?: InputMaybe<Scalars["String"]>;
	doctorPhoneNumberNotHasSuffix?: InputMaybe<Scalars["String"]>;
	doctorPhoneNumberNotIn?: InputMaybe<Array<Scalars["String"]>>;
	emergencyContactInformationContains?: InputMaybe<Scalars["String"]>;
	emergencyContactInformationEQ?: InputMaybe<Scalars["String"]>;
	emergencyContactInformationHasPrefix?: InputMaybe<Scalars["String"]>;
	emergencyContactInformationHasSuffix?: InputMaybe<Scalars["String"]>;
	emergencyContactInformationIn?: InputMaybe<Array<Scalars["String"]>>;
	emergencyContactInformationNEQ?: InputMaybe<Scalars["String"]>;
	emergencyContactInformationNotContains?: InputMaybe<Scalars["String"]>;
	emergencyContactInformationNotHasPrefix?: InputMaybe<Scalars["String"]>;
	emergencyContactInformationNotHasSuffix?: InputMaybe<Scalars["String"]>;
	emergencyContactInformationNotIn?: InputMaybe<Array<Scalars["String"]>>;
	enrollmentApplicationStudent?: InputMaybe<Array<EnrollmentApplicationStudentFilterInput>>;
	enrollmentInvitations?: InputMaybe<Array<EnrollmentInvitationFilterInput>>;
	graduatingYearEQ?: InputMaybe<Scalars["Int"]>;
	graduatingYearGT?: InputMaybe<Scalars["Int"]>;
	graduatingYearGTE?: InputMaybe<Scalars["Int"]>;
	graduatingYearIn?: InputMaybe<Array<Scalars["Int"]>>;
	graduatingYearLT?: InputMaybe<Scalars["Int"]>;
	graduatingYearLTE?: InputMaybe<Scalars["Int"]>;
	graduatingYearNEQ?: InputMaybe<Scalars["Int"]>;
	graduatingYearNotIn?: InputMaybe<Array<Scalars["Int"]>>;
	hasBillingSetupSubmissions?: InputMaybe<Scalars["Boolean"]>;
	hasBusPlans?: InputMaybe<Scalars["Boolean"]>;
	hasContracts?: InputMaybe<Scalars["Boolean"]>;
	hasCourseAttendanceRecords?: InputMaybe<Scalars["Boolean"]>;
	hasCourseSectionStudentEnrollments?: InputMaybe<Scalars["Boolean"]>;
	hasEnrollmentApplicationStudent?: InputMaybe<Scalars["Boolean"]>;
	hasEnrollmentInvitations?: InputMaybe<Scalars["Boolean"]>;
	hasHomeRoomSectionStudentEnrollments?: InputMaybe<Scalars["Boolean"]>;
	hasMealPlans?: InputMaybe<Scalars["Boolean"]>;
	hasNotes?: InputMaybe<Scalars["Boolean"]>;
	hasPreviousSchoolingEQ?: InputMaybe<Scalars["Boolean"]>;
	hasPreviousSchoolingNEQ?: InputMaybe<Scalars["Boolean"]>;
	hasRelationships?: InputMaybe<Scalars["Boolean"]>;
	hasReportCards?: InputMaybe<Scalars["Boolean"]>;
	hasSchoolAttendanceRecords?: InputMaybe<Scalars["Boolean"]>;
	hasSchoolStudentEnrollments?: InputMaybe<Scalars["Boolean"]>;
	hasStudentDocuments?: InputMaybe<Scalars["Boolean"]>;
	hasTuition?: InputMaybe<Scalars["Boolean"]>;
	healthNotesContains?: InputMaybe<Scalars["String"]>;
	healthNotesEQ?: InputMaybe<Scalars["String"]>;
	healthNotesHasPrefix?: InputMaybe<Scalars["String"]>;
	healthNotesHasSuffix?: InputMaybe<Scalars["String"]>;
	healthNotesIn?: InputMaybe<Array<Scalars["String"]>>;
	healthNotesNEQ?: InputMaybe<Scalars["String"]>;
	healthNotesNotContains?: InputMaybe<Scalars["String"]>;
	healthNotesNotHasPrefix?: InputMaybe<Scalars["String"]>;
	healthNotesNotHasSuffix?: InputMaybe<Scalars["String"]>;
	healthNotesNotIn?: InputMaybe<Array<Scalars["String"]>>;
	homeRoomSectionStudentEnrollments?: InputMaybe<Array<HomeRoomSectionStudentEnrollmentFilterInput>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	mealPlans?: InputMaybe<Array<MealPlanFilterInput>>;
	medicationContains?: InputMaybe<Scalars["String"]>;
	medicationEQ?: InputMaybe<Scalars["String"]>;
	medicationHasPrefix?: InputMaybe<Scalars["String"]>;
	medicationHasSuffix?: InputMaybe<Scalars["String"]>;
	medicationIn?: InputMaybe<Array<Scalars["String"]>>;
	medicationNEQ?: InputMaybe<Scalars["String"]>;
	medicationNotContains?: InputMaybe<Scalars["String"]>;
	medicationNotHasPrefix?: InputMaybe<Scalars["String"]>;
	medicationNotHasSuffix?: InputMaybe<Scalars["String"]>;
	medicationNotIn?: InputMaybe<Array<Scalars["String"]>>;
	medicationScheduleContains?: InputMaybe<Scalars["String"]>;
	medicationScheduleEQ?: InputMaybe<Scalars["String"]>;
	medicationScheduleHasPrefix?: InputMaybe<Scalars["String"]>;
	medicationScheduleHasSuffix?: InputMaybe<Scalars["String"]>;
	medicationScheduleIn?: InputMaybe<Array<Scalars["String"]>>;
	medicationScheduleNEQ?: InputMaybe<Scalars["String"]>;
	medicationScheduleNotContains?: InputMaybe<Scalars["String"]>;
	medicationScheduleNotHasPrefix?: InputMaybe<Scalars["String"]>;
	medicationScheduleNotHasSuffix?: InputMaybe<Scalars["String"]>;
	medicationScheduleNotIn?: InputMaybe<Array<Scalars["String"]>>;
	not?: InputMaybe<StudentFilterInput>;
	notes?: InputMaybe<Array<NoteFilterInput>>;
	or?: InputMaybe<Array<StudentFilterInput>>;
	person?: InputMaybe<Array<PersonFilterInput>>;
	personIDEQ?: InputMaybe<Scalars["ID"]>;
	personIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	personIDNEQ?: InputMaybe<Scalars["ID"]>;
	personIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	previousSchoolInformationContains?: InputMaybe<Scalars["String"]>;
	previousSchoolInformationEQ?: InputMaybe<Scalars["String"]>;
	previousSchoolInformationHasPrefix?: InputMaybe<Scalars["String"]>;
	previousSchoolInformationHasSuffix?: InputMaybe<Scalars["String"]>;
	previousSchoolInformationIn?: InputMaybe<Array<Scalars["String"]>>;
	previousSchoolInformationNEQ?: InputMaybe<Scalars["String"]>;
	previousSchoolInformationNotContains?: InputMaybe<Scalars["String"]>;
	previousSchoolInformationNotHasPrefix?: InputMaybe<Scalars["String"]>;
	previousSchoolInformationNotHasSuffix?: InputMaybe<Scalars["String"]>;
	previousSchoolInformationNotIn?: InputMaybe<Array<Scalars["String"]>>;
	previousSchoolLocationContains?: InputMaybe<Scalars["String"]>;
	previousSchoolLocationEQ?: InputMaybe<Scalars["String"]>;
	previousSchoolLocationHasPrefix?: InputMaybe<Scalars["String"]>;
	previousSchoolLocationHasSuffix?: InputMaybe<Scalars["String"]>;
	previousSchoolLocationIn?: InputMaybe<Array<Scalars["String"]>>;
	previousSchoolLocationNEQ?: InputMaybe<Scalars["String"]>;
	previousSchoolLocationNotContains?: InputMaybe<Scalars["String"]>;
	previousSchoolLocationNotHasPrefix?: InputMaybe<Scalars["String"]>;
	previousSchoolLocationNotHasSuffix?: InputMaybe<Scalars["String"]>;
	previousSchoolLocationNotIn?: InputMaybe<Array<Scalars["String"]>>;
	relationships?: InputMaybe<Array<RelationshipFilterInput>>;
	reportCards?: InputMaybe<Array<ReportCardFilterInput>>;
	schoolAttendanceRecords?: InputMaybe<Array<SchoolAttendanceRecordFilterInput>>;
	schoolStudentEnrollments?: InputMaybe<Array<SchoolStudentEnrollmentFilterInput>>;
	search?: InputMaybe<Scalars["String"]>;
	studentDocuments?: InputMaybe<Array<StudentDocumentFilterInput>>;
	tuition?: InputMaybe<Array<TuitionFilterInput>>;
	tuitionIDEQ?: InputMaybe<Scalars["ID"]>;
	tuitionIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	tuitionIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	tuitionIDNEQ?: InputMaybe<Scalars["ID"]>;
	tuitionIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type StudentHistory = Node & {
	__typename?: "StudentHistory";
	allergies: Maybe<Scalars["String"]>;
	asthma: Maybe<StudentHistoryAsthma>;
	dietaryRestrictions: Maybe<Scalars["String"]>;
	doctorName: Maybe<Scalars["String"]>;
	doctorPhoneNumber: Maybe<Scalars["String"]>;
	emergencyContactInformation: Maybe<Scalars["String"]>;
	graduatingYear: Maybe<Scalars["Int"]>;
	hasPreviousSchooling: Maybe<Scalars["Boolean"]>;
	healthNotes: Maybe<Scalars["String"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	medication: Maybe<Scalars["String"]>;
	medicationSchedule: Maybe<Scalars["String"]>;
	originalID: Maybe<Scalars["ID"]>;
	personID: Maybe<Scalars["ID"]>;
	previousSchoolInformation: Maybe<Scalars["String"]>;
	previousSchoolLocation: Maybe<Scalars["String"]>;
	tuitionID: Maybe<Scalars["ID"]>;
};

export enum StudentHistoryAsthma {
	Mild = "mild",
	Moderate = "moderate",
	None = "none",
	Severe = "severe",
}

export type StudentHistoryConnection = {
	__typename?: "StudentHistoryConnection";
	edges: Array<StudentHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type StudentHistoryEdge = {
	__typename?: "StudentHistoryEdge";
	cursor: Scalars["Cursor"];
	node: StudentHistory;
};

export type StudentHistoryFilterInput = {
	allergiesContains?: InputMaybe<Scalars["String"]>;
	allergiesEQ?: InputMaybe<Scalars["String"]>;
	allergiesHasPrefix?: InputMaybe<Scalars["String"]>;
	allergiesHasSuffix?: InputMaybe<Scalars["String"]>;
	allergiesIn?: InputMaybe<Array<Scalars["String"]>>;
	allergiesIsNull?: InputMaybe<Scalars["Boolean"]>;
	allergiesNEQ?: InputMaybe<Scalars["String"]>;
	allergiesNotContains?: InputMaybe<Scalars["String"]>;
	allergiesNotHasPrefix?: InputMaybe<Scalars["String"]>;
	allergiesNotHasSuffix?: InputMaybe<Scalars["String"]>;
	allergiesNotIn?: InputMaybe<Array<Scalars["String"]>>;
	and?: InputMaybe<Array<StudentHistoryFilterInput>>;
	asthmaEQ?: InputMaybe<StudentHistoryAsthma>;
	asthmaIn?: InputMaybe<Array<StudentHistoryAsthma>>;
	asthmaIsNull?: InputMaybe<Scalars["Boolean"]>;
	asthmaNEQ?: InputMaybe<StudentHistoryAsthma>;
	asthmaNotIn?: InputMaybe<Array<StudentHistoryAsthma>>;
	dietaryRestrictionsContains?: InputMaybe<Scalars["String"]>;
	dietaryRestrictionsEQ?: InputMaybe<Scalars["String"]>;
	dietaryRestrictionsHasPrefix?: InputMaybe<Scalars["String"]>;
	dietaryRestrictionsHasSuffix?: InputMaybe<Scalars["String"]>;
	dietaryRestrictionsIn?: InputMaybe<Array<Scalars["String"]>>;
	dietaryRestrictionsIsNull?: InputMaybe<Scalars["Boolean"]>;
	dietaryRestrictionsNEQ?: InputMaybe<Scalars["String"]>;
	dietaryRestrictionsNotContains?: InputMaybe<Scalars["String"]>;
	dietaryRestrictionsNotHasPrefix?: InputMaybe<Scalars["String"]>;
	dietaryRestrictionsNotHasSuffix?: InputMaybe<Scalars["String"]>;
	dietaryRestrictionsNotIn?: InputMaybe<Array<Scalars["String"]>>;
	doctorNameContains?: InputMaybe<Scalars["String"]>;
	doctorNameEQ?: InputMaybe<Scalars["String"]>;
	doctorNameHasPrefix?: InputMaybe<Scalars["String"]>;
	doctorNameHasSuffix?: InputMaybe<Scalars["String"]>;
	doctorNameIn?: InputMaybe<Array<Scalars["String"]>>;
	doctorNameIsNull?: InputMaybe<Scalars["Boolean"]>;
	doctorNameNEQ?: InputMaybe<Scalars["String"]>;
	doctorNameNotContains?: InputMaybe<Scalars["String"]>;
	doctorNameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	doctorNameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	doctorNameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	doctorPhoneNumberContains?: InputMaybe<Scalars["String"]>;
	doctorPhoneNumberEQ?: InputMaybe<Scalars["String"]>;
	doctorPhoneNumberHasPrefix?: InputMaybe<Scalars["String"]>;
	doctorPhoneNumberHasSuffix?: InputMaybe<Scalars["String"]>;
	doctorPhoneNumberIn?: InputMaybe<Array<Scalars["String"]>>;
	doctorPhoneNumberIsNull?: InputMaybe<Scalars["Boolean"]>;
	doctorPhoneNumberNEQ?: InputMaybe<Scalars["String"]>;
	doctorPhoneNumberNotContains?: InputMaybe<Scalars["String"]>;
	doctorPhoneNumberNotHasPrefix?: InputMaybe<Scalars["String"]>;
	doctorPhoneNumberNotHasSuffix?: InputMaybe<Scalars["String"]>;
	doctorPhoneNumberNotIn?: InputMaybe<Array<Scalars["String"]>>;
	emergencyContactInformationContains?: InputMaybe<Scalars["String"]>;
	emergencyContactInformationEQ?: InputMaybe<Scalars["String"]>;
	emergencyContactInformationHasPrefix?: InputMaybe<Scalars["String"]>;
	emergencyContactInformationHasSuffix?: InputMaybe<Scalars["String"]>;
	emergencyContactInformationIn?: InputMaybe<Array<Scalars["String"]>>;
	emergencyContactInformationIsNull?: InputMaybe<Scalars["Boolean"]>;
	emergencyContactInformationNEQ?: InputMaybe<Scalars["String"]>;
	emergencyContactInformationNotContains?: InputMaybe<Scalars["String"]>;
	emergencyContactInformationNotHasPrefix?: InputMaybe<Scalars["String"]>;
	emergencyContactInformationNotHasSuffix?: InputMaybe<Scalars["String"]>;
	emergencyContactInformationNotIn?: InputMaybe<Array<Scalars["String"]>>;
	graduatingYearEQ?: InputMaybe<Scalars["Int"]>;
	graduatingYearGT?: InputMaybe<Scalars["Int"]>;
	graduatingYearGTE?: InputMaybe<Scalars["Int"]>;
	graduatingYearIn?: InputMaybe<Array<Scalars["Int"]>>;
	graduatingYearIsNull?: InputMaybe<Scalars["Boolean"]>;
	graduatingYearLT?: InputMaybe<Scalars["Int"]>;
	graduatingYearLTE?: InputMaybe<Scalars["Int"]>;
	graduatingYearNEQ?: InputMaybe<Scalars["Int"]>;
	graduatingYearNotIn?: InputMaybe<Array<Scalars["Int"]>>;
	hasPreviousSchoolingEQ?: InputMaybe<Scalars["Boolean"]>;
	hasPreviousSchoolingIsNull?: InputMaybe<Scalars["Boolean"]>;
	hasPreviousSchoolingNEQ?: InputMaybe<Scalars["Boolean"]>;
	healthNotesContains?: InputMaybe<Scalars["String"]>;
	healthNotesEQ?: InputMaybe<Scalars["String"]>;
	healthNotesHasPrefix?: InputMaybe<Scalars["String"]>;
	healthNotesHasSuffix?: InputMaybe<Scalars["String"]>;
	healthNotesIn?: InputMaybe<Array<Scalars["String"]>>;
	healthNotesIsNull?: InputMaybe<Scalars["Boolean"]>;
	healthNotesNEQ?: InputMaybe<Scalars["String"]>;
	healthNotesNotContains?: InputMaybe<Scalars["String"]>;
	healthNotesNotHasPrefix?: InputMaybe<Scalars["String"]>;
	healthNotesNotHasSuffix?: InputMaybe<Scalars["String"]>;
	healthNotesNotIn?: InputMaybe<Array<Scalars["String"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	medicationContains?: InputMaybe<Scalars["String"]>;
	medicationEQ?: InputMaybe<Scalars["String"]>;
	medicationHasPrefix?: InputMaybe<Scalars["String"]>;
	medicationHasSuffix?: InputMaybe<Scalars["String"]>;
	medicationIn?: InputMaybe<Array<Scalars["String"]>>;
	medicationIsNull?: InputMaybe<Scalars["Boolean"]>;
	medicationNEQ?: InputMaybe<Scalars["String"]>;
	medicationNotContains?: InputMaybe<Scalars["String"]>;
	medicationNotHasPrefix?: InputMaybe<Scalars["String"]>;
	medicationNotHasSuffix?: InputMaybe<Scalars["String"]>;
	medicationNotIn?: InputMaybe<Array<Scalars["String"]>>;
	medicationScheduleContains?: InputMaybe<Scalars["String"]>;
	medicationScheduleEQ?: InputMaybe<Scalars["String"]>;
	medicationScheduleHasPrefix?: InputMaybe<Scalars["String"]>;
	medicationScheduleHasSuffix?: InputMaybe<Scalars["String"]>;
	medicationScheduleIn?: InputMaybe<Array<Scalars["String"]>>;
	medicationScheduleIsNull?: InputMaybe<Scalars["Boolean"]>;
	medicationScheduleNEQ?: InputMaybe<Scalars["String"]>;
	medicationScheduleNotContains?: InputMaybe<Scalars["String"]>;
	medicationScheduleNotHasPrefix?: InputMaybe<Scalars["String"]>;
	medicationScheduleNotHasSuffix?: InputMaybe<Scalars["String"]>;
	medicationScheduleNotIn?: InputMaybe<Array<Scalars["String"]>>;
	not?: InputMaybe<StudentHistoryFilterInput>;
	or?: InputMaybe<Array<StudentHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	personIDEQ?: InputMaybe<Scalars["ID"]>;
	personIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	personIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	personIDNEQ?: InputMaybe<Scalars["ID"]>;
	personIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	previousSchoolInformationContains?: InputMaybe<Scalars["String"]>;
	previousSchoolInformationEQ?: InputMaybe<Scalars["String"]>;
	previousSchoolInformationHasPrefix?: InputMaybe<Scalars["String"]>;
	previousSchoolInformationHasSuffix?: InputMaybe<Scalars["String"]>;
	previousSchoolInformationIn?: InputMaybe<Array<Scalars["String"]>>;
	previousSchoolInformationIsNull?: InputMaybe<Scalars["Boolean"]>;
	previousSchoolInformationNEQ?: InputMaybe<Scalars["String"]>;
	previousSchoolInformationNotContains?: InputMaybe<Scalars["String"]>;
	previousSchoolInformationNotHasPrefix?: InputMaybe<Scalars["String"]>;
	previousSchoolInformationNotHasSuffix?: InputMaybe<Scalars["String"]>;
	previousSchoolInformationNotIn?: InputMaybe<Array<Scalars["String"]>>;
	previousSchoolLocationContains?: InputMaybe<Scalars["String"]>;
	previousSchoolLocationEQ?: InputMaybe<Scalars["String"]>;
	previousSchoolLocationHasPrefix?: InputMaybe<Scalars["String"]>;
	previousSchoolLocationHasSuffix?: InputMaybe<Scalars["String"]>;
	previousSchoolLocationIn?: InputMaybe<Array<Scalars["String"]>>;
	previousSchoolLocationIsNull?: InputMaybe<Scalars["Boolean"]>;
	previousSchoolLocationNEQ?: InputMaybe<Scalars["String"]>;
	previousSchoolLocationNotContains?: InputMaybe<Scalars["String"]>;
	previousSchoolLocationNotHasPrefix?: InputMaybe<Scalars["String"]>;
	previousSchoolLocationNotHasSuffix?: InputMaybe<Scalars["String"]>;
	previousSchoolLocationNotIn?: InputMaybe<Array<Scalars["String"]>>;
	search?: InputMaybe<Scalars["String"]>;
	tuitionIDEQ?: InputMaybe<Scalars["ID"]>;
	tuitionIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	tuitionIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	tuitionIDNEQ?: InputMaybe<Scalars["ID"]>;
	tuitionIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type StudentHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<StudentHistoryOrderField>;
};

export enum StudentHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type StudentInEnrollmentApplicationStudentCreateInput = {
	allergies?: InputMaybe<Scalars["String"]>;
	asthma?: InputMaybe<StudentAsthma>;
	billingSetupSubmissionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	billingSetupSubmissions?: InputMaybe<Array<BillingSetupSubmissionInStudentCreateInput>>;
	busPlanIDs?: InputMaybe<Array<Scalars["ID"]>>;
	busPlans?: InputMaybe<Array<BusPlanInStudentCreateInput>>;
	contractIDs?: InputMaybe<Array<Scalars["ID"]>>;
	contracts?: InputMaybe<Array<ContractInStudentCreateInput>>;
	courseAttendanceRecordIDs?: InputMaybe<Array<Scalars["ID"]>>;
	courseAttendanceRecords?: InputMaybe<Array<CourseAttendanceRecordInStudentCreateInput>>;
	courseSectionStudentEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	courseSectionStudentEnrollments?: InputMaybe<Array<CourseSectionStudentEnrollmentInStudentCreateInput>>;
	dietaryRestrictions?: InputMaybe<Scalars["String"]>;
	doctorName?: InputMaybe<Scalars["String"]>;
	doctorPhoneNumber?: InputMaybe<Scalars["String"]>;
	emergencyContactInformation?: InputMaybe<Scalars["String"]>;
	enrollmentInvitationIDs?: InputMaybe<Array<Scalars["ID"]>>;
	enrollmentInvitations?: InputMaybe<Array<EnrollmentInvitationInStudentCreateInput>>;
	graduatingYear: Scalars["Int"];
	hasPreviousSchooling?: InputMaybe<Scalars["Boolean"]>;
	healthNotes?: InputMaybe<Scalars["String"]>;
	homeRoomSectionStudentEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	homeRoomSectionStudentEnrollments?: InputMaybe<Array<HomeRoomSectionStudentEnrollmentInStudentCreateInput>>;
	mealPlanIDs?: InputMaybe<Array<Scalars["ID"]>>;
	mealPlans?: InputMaybe<Array<MealPlanInStudentCreateInput>>;
	medication?: InputMaybe<Scalars["String"]>;
	medicationSchedule?: InputMaybe<Scalars["String"]>;
	noteIDs?: InputMaybe<Array<Scalars["ID"]>>;
	person?: InputMaybe<PersonInStudentCreateInput>;
	personID?: InputMaybe<Scalars["ID"]>;
	previousSchoolInformation?: InputMaybe<Scalars["String"]>;
	previousSchoolLocation?: InputMaybe<Scalars["String"]>;
	relationshipIDs?: InputMaybe<Array<Scalars["ID"]>>;
	relationships?: InputMaybe<Array<RelationshipInStudentCreateInput>>;
	reportCardIDs?: InputMaybe<Array<Scalars["ID"]>>;
	reportCards?: InputMaybe<Array<ReportCardInStudentCreateInput>>;
	schoolAttendanceRecordIDs?: InputMaybe<Array<Scalars["ID"]>>;
	schoolAttendanceRecords?: InputMaybe<Array<SchoolAttendanceRecordInStudentCreateInput>>;
	schoolStudentEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	schoolStudentEnrollments?: InputMaybe<Array<SchoolStudentEnrollmentInStudentCreateInput>>;
	studentDocumentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	studentDocuments?: InputMaybe<Array<StudentDocumentInStudentCreateInput>>;
	tuitionID?: InputMaybe<Scalars["ID"]>;
};

export type StudentInPersonCreateInput = {
	allergies?: InputMaybe<Scalars["String"]>;
	asthma?: InputMaybe<StudentAsthma>;
	billingSetupSubmissionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	billingSetupSubmissions?: InputMaybe<Array<BillingSetupSubmissionInStudentCreateInput>>;
	busPlanIDs?: InputMaybe<Array<Scalars["ID"]>>;
	busPlans?: InputMaybe<Array<BusPlanInStudentCreateInput>>;
	contractIDs?: InputMaybe<Array<Scalars["ID"]>>;
	contracts?: InputMaybe<Array<ContractInStudentCreateInput>>;
	courseAttendanceRecordIDs?: InputMaybe<Array<Scalars["ID"]>>;
	courseAttendanceRecords?: InputMaybe<Array<CourseAttendanceRecordInStudentCreateInput>>;
	courseSectionStudentEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	courseSectionStudentEnrollments?: InputMaybe<Array<CourseSectionStudentEnrollmentInStudentCreateInput>>;
	dietaryRestrictions?: InputMaybe<Scalars["String"]>;
	doctorName?: InputMaybe<Scalars["String"]>;
	doctorPhoneNumber?: InputMaybe<Scalars["String"]>;
	emergencyContactInformation?: InputMaybe<Scalars["String"]>;
	enrollmentApplicationStudent?: InputMaybe<EnrollmentApplicationStudentInStudentCreateInput>;
	enrollmentApplicationStudentID?: InputMaybe<Scalars["ID"]>;
	enrollmentInvitationIDs?: InputMaybe<Array<Scalars["ID"]>>;
	enrollmentInvitations?: InputMaybe<Array<EnrollmentInvitationInStudentCreateInput>>;
	graduatingYear: Scalars["Int"];
	hasPreviousSchooling?: InputMaybe<Scalars["Boolean"]>;
	healthNotes?: InputMaybe<Scalars["String"]>;
	homeRoomSectionStudentEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	homeRoomSectionStudentEnrollments?: InputMaybe<Array<HomeRoomSectionStudentEnrollmentInStudentCreateInput>>;
	mealPlanIDs?: InputMaybe<Array<Scalars["ID"]>>;
	mealPlans?: InputMaybe<Array<MealPlanInStudentCreateInput>>;
	medication?: InputMaybe<Scalars["String"]>;
	medicationSchedule?: InputMaybe<Scalars["String"]>;
	noteIDs?: InputMaybe<Array<Scalars["ID"]>>;
	previousSchoolInformation?: InputMaybe<Scalars["String"]>;
	previousSchoolLocation?: InputMaybe<Scalars["String"]>;
	relationshipIDs?: InputMaybe<Array<Scalars["ID"]>>;
	relationships?: InputMaybe<Array<RelationshipInStudentCreateInput>>;
	reportCardIDs?: InputMaybe<Array<Scalars["ID"]>>;
	reportCards?: InputMaybe<Array<ReportCardInStudentCreateInput>>;
	schoolAttendanceRecordIDs?: InputMaybe<Array<Scalars["ID"]>>;
	schoolAttendanceRecords?: InputMaybe<Array<SchoolAttendanceRecordInStudentCreateInput>>;
	schoolStudentEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	schoolStudentEnrollments?: InputMaybe<Array<SchoolStudentEnrollmentInStudentCreateInput>>;
	studentDocumentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	studentDocuments?: InputMaybe<Array<StudentDocumentInStudentCreateInput>>;
	tuitionID?: InputMaybe<Scalars["ID"]>;
};

export type StudentInTuitionCreateInput = {
	allergies?: InputMaybe<Scalars["String"]>;
	asthma?: InputMaybe<StudentAsthma>;
	billingSetupSubmissionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	billingSetupSubmissions?: InputMaybe<Array<BillingSetupSubmissionInStudentCreateInput>>;
	busPlanIDs?: InputMaybe<Array<Scalars["ID"]>>;
	busPlans?: InputMaybe<Array<BusPlanInStudentCreateInput>>;
	contractIDs?: InputMaybe<Array<Scalars["ID"]>>;
	contracts?: InputMaybe<Array<ContractInStudentCreateInput>>;
	courseAttendanceRecordIDs?: InputMaybe<Array<Scalars["ID"]>>;
	courseAttendanceRecords?: InputMaybe<Array<CourseAttendanceRecordInStudentCreateInput>>;
	courseSectionStudentEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	courseSectionStudentEnrollments?: InputMaybe<Array<CourseSectionStudentEnrollmentInStudentCreateInput>>;
	dietaryRestrictions?: InputMaybe<Scalars["String"]>;
	doctorName?: InputMaybe<Scalars["String"]>;
	doctorPhoneNumber?: InputMaybe<Scalars["String"]>;
	emergencyContactInformation?: InputMaybe<Scalars["String"]>;
	enrollmentApplicationStudent?: InputMaybe<EnrollmentApplicationStudentInStudentCreateInput>;
	enrollmentApplicationStudentID?: InputMaybe<Scalars["ID"]>;
	enrollmentInvitationIDs?: InputMaybe<Array<Scalars["ID"]>>;
	enrollmentInvitations?: InputMaybe<Array<EnrollmentInvitationInStudentCreateInput>>;
	graduatingYear: Scalars["Int"];
	hasPreviousSchooling?: InputMaybe<Scalars["Boolean"]>;
	healthNotes?: InputMaybe<Scalars["String"]>;
	homeRoomSectionStudentEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	homeRoomSectionStudentEnrollments?: InputMaybe<Array<HomeRoomSectionStudentEnrollmentInStudentCreateInput>>;
	mealPlanIDs?: InputMaybe<Array<Scalars["ID"]>>;
	mealPlans?: InputMaybe<Array<MealPlanInStudentCreateInput>>;
	medication?: InputMaybe<Scalars["String"]>;
	medicationSchedule?: InputMaybe<Scalars["String"]>;
	noteIDs?: InputMaybe<Array<Scalars["ID"]>>;
	person?: InputMaybe<PersonInStudentCreateInput>;
	personID?: InputMaybe<Scalars["ID"]>;
	previousSchoolInformation?: InputMaybe<Scalars["String"]>;
	previousSchoolLocation?: InputMaybe<Scalars["String"]>;
	relationshipIDs?: InputMaybe<Array<Scalars["ID"]>>;
	relationships?: InputMaybe<Array<RelationshipInStudentCreateInput>>;
	reportCardIDs?: InputMaybe<Array<Scalars["ID"]>>;
	reportCards?: InputMaybe<Array<ReportCardInStudentCreateInput>>;
	schoolAttendanceRecordIDs?: InputMaybe<Array<Scalars["ID"]>>;
	schoolAttendanceRecords?: InputMaybe<Array<SchoolAttendanceRecordInStudentCreateInput>>;
	schoolStudentEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	schoolStudentEnrollments?: InputMaybe<Array<SchoolStudentEnrollmentInStudentCreateInput>>;
	studentDocumentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	studentDocuments?: InputMaybe<Array<StudentDocumentInStudentCreateInput>>;
};

export type StudentOrder = {
	direction: OrderDirection;
	field?: InputMaybe<StudentOrderField>;
};

export enum StudentOrderField {
	GraduatingYear = "GRADUATING_YEAR",
	HasPreviousSchooling = "HAS_PREVIOUS_SCHOOLING",
}

export type StudentUpdateBulkInput = {
	addBillingSetupSubmissionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addBusPlanIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addContractIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addCourseAttendanceRecordIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addCourseSectionStudentEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addEnrollmentInvitationIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addHomeRoomSectionStudentEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addMealPlanIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addNoteIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addRelationshipIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addReportCardIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addSchoolAttendanceRecordIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addSchoolStudentEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addStudentDocumentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	allergies?: InputMaybe<Scalars["String"]>;
	asthma?: InputMaybe<StudentAsthma>;
	clearTuition?: InputMaybe<Scalars["Boolean"]>;
	dietaryRestrictions?: InputMaybe<Scalars["String"]>;
	doctorName?: InputMaybe<Scalars["String"]>;
	doctorPhoneNumber?: InputMaybe<Scalars["String"]>;
	emergencyContactInformation?: InputMaybe<Scalars["String"]>;
	graduatingYear?: InputMaybe<Scalars["Int"]>;
	hasPreviousSchooling?: InputMaybe<Scalars["Boolean"]>;
	healthNotes?: InputMaybe<Scalars["String"]>;
	medication?: InputMaybe<Scalars["String"]>;
	medicationSchedule?: InputMaybe<Scalars["String"]>;
	previousSchoolInformation?: InputMaybe<Scalars["String"]>;
	previousSchoolLocation?: InputMaybe<Scalars["String"]>;
	removeBillingSetupSubmissionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeBusPlanIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeContractIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeCourseAttendanceRecordIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeCourseSectionStudentEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeEnrollmentInvitationIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeHomeRoomSectionStudentEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeMealPlanIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeNoteIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeRelationshipIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeReportCardIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeSchoolAttendanceRecordIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeSchoolStudentEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeStudentDocumentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	tuitionID?: InputMaybe<Scalars["ID"]>;
};

export type StudentUpdateInput = {
	addBillingSetupSubmissionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addBusPlanIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addContractIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addCourseAttendanceRecordIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addCourseSectionStudentEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addEnrollmentInvitationIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addHomeRoomSectionStudentEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addMealPlanIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addNoteIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addRelationshipIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addReportCardIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addSchoolAttendanceRecordIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addSchoolStudentEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addStudentDocumentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	allergies?: InputMaybe<Scalars["String"]>;
	asthma?: InputMaybe<StudentAsthma>;
	clearEnrollmentApplicationStudent?: InputMaybe<Scalars["Boolean"]>;
	clearTuition?: InputMaybe<Scalars["Boolean"]>;
	dietaryRestrictions?: InputMaybe<Scalars["String"]>;
	doctorName?: InputMaybe<Scalars["String"]>;
	doctorPhoneNumber?: InputMaybe<Scalars["String"]>;
	emergencyContactInformation?: InputMaybe<Scalars["String"]>;
	enrollmentApplicationStudentID?: InputMaybe<Scalars["ID"]>;
	graduatingYear?: InputMaybe<Scalars["Int"]>;
	hasPreviousSchooling?: InputMaybe<Scalars["Boolean"]>;
	healthNotes?: InputMaybe<Scalars["String"]>;
	medication?: InputMaybe<Scalars["String"]>;
	medicationSchedule?: InputMaybe<Scalars["String"]>;
	personID?: InputMaybe<Scalars["ID"]>;
	previousSchoolInformation?: InputMaybe<Scalars["String"]>;
	previousSchoolLocation?: InputMaybe<Scalars["String"]>;
	removeBillingSetupSubmissionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeBusPlanIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeContractIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeCourseAttendanceRecordIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeCourseSectionStudentEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeEnrollmentInvitationIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeHomeRoomSectionStudentEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeMealPlanIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeNoteIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeRelationshipIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeReportCardIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeSchoolAttendanceRecordIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeSchoolStudentEnrollmentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeStudentDocumentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	tuitionID?: InputMaybe<Scalars["ID"]>;
};

export type Term = Node & {
	__typename?: "Term";
	academicYear: AcademicYear;
	academicYearID: Scalars["ID"];
	courseSections: Array<CourseSection>;
	endDate: Scalars["Time"];
	homeRoomSections: Array<HomeRoomSection>;
	id: Scalars["ID"];
	name: Scalars["String"];
	reportCards: Array<ReportCard>;
	startDate: Scalars["Time"];
};

export type TermCourseSectionsArgs = {
	filters?: InputMaybe<Array<CourseSectionFilterInput>>;
	order?: InputMaybe<CourseSectionOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type TermHomeRoomSectionsArgs = {
	filters?: InputMaybe<Array<HomeRoomSectionFilterInput>>;
	order?: InputMaybe<HomeRoomSectionOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type TermReportCardsArgs = {
	filters?: InputMaybe<Array<ReportCardFilterInput>>;
	order?: InputMaybe<ReportCardOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type TermConnection = {
	__typename?: "TermConnection";
	edges: Array<TermEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type TermCreateInput = {
	academicYearID: Scalars["ID"];
	courseSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	courseSections?: InputMaybe<Array<CourseSectionInTermCreateInput>>;
	endDate: Scalars["Time"];
	homeRoomSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	homeRoomSections?: InputMaybe<Array<HomeRoomSectionInTermCreateInput>>;
	name: Scalars["String"];
	reportCardIDs?: InputMaybe<Array<Scalars["ID"]>>;
	reportCards?: InputMaybe<Array<ReportCardInTermCreateInput>>;
	startDate: Scalars["Time"];
};

export type TermEdge = {
	__typename?: "TermEdge";
	cursor: Scalars["Cursor"];
	node: Term;
};

export type TermFilterInput = {
	academicYear?: InputMaybe<Array<AcademicYearFilterInput>>;
	academicYearIDEQ?: InputMaybe<Scalars["ID"]>;
	academicYearIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	academicYearIDNEQ?: InputMaybe<Scalars["ID"]>;
	academicYearIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	and?: InputMaybe<Array<TermFilterInput>>;
	courseSections?: InputMaybe<Array<CourseSectionFilterInput>>;
	endDateEQ?: InputMaybe<Scalars["Time"]>;
	endDateGT?: InputMaybe<Scalars["Time"]>;
	endDateGTE?: InputMaybe<Scalars["Time"]>;
	endDateIn?: InputMaybe<Array<Scalars["Time"]>>;
	endDateLT?: InputMaybe<Scalars["Time"]>;
	endDateLTE?: InputMaybe<Scalars["Time"]>;
	endDateNEQ?: InputMaybe<Scalars["Time"]>;
	endDateNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	hasCourseSections?: InputMaybe<Scalars["Boolean"]>;
	hasHomeRoomSections?: InputMaybe<Scalars["Boolean"]>;
	hasReportCards?: InputMaybe<Scalars["Boolean"]>;
	homeRoomSections?: InputMaybe<Array<HomeRoomSectionFilterInput>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	nameContains?: InputMaybe<Scalars["String"]>;
	nameEQ?: InputMaybe<Scalars["String"]>;
	nameHasPrefix?: InputMaybe<Scalars["String"]>;
	nameHasSuffix?: InputMaybe<Scalars["String"]>;
	nameIn?: InputMaybe<Array<Scalars["String"]>>;
	nameNEQ?: InputMaybe<Scalars["String"]>;
	nameNotContains?: InputMaybe<Scalars["String"]>;
	nameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	nameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	nameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	not?: InputMaybe<TermFilterInput>;
	or?: InputMaybe<Array<TermFilterInput>>;
	reportCards?: InputMaybe<Array<ReportCardFilterInput>>;
	search?: InputMaybe<Scalars["String"]>;
	startDateEQ?: InputMaybe<Scalars["Time"]>;
	startDateGT?: InputMaybe<Scalars["Time"]>;
	startDateGTE?: InputMaybe<Scalars["Time"]>;
	startDateIn?: InputMaybe<Array<Scalars["Time"]>>;
	startDateLT?: InputMaybe<Scalars["Time"]>;
	startDateLTE?: InputMaybe<Scalars["Time"]>;
	startDateNEQ?: InputMaybe<Scalars["Time"]>;
	startDateNotIn?: InputMaybe<Array<Scalars["Time"]>>;
};

export type TermHistory = Node & {
	__typename?: "TermHistory";
	academicYearID: Maybe<Scalars["ID"]>;
	endDate: Maybe<Scalars["Time"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	name: Maybe<Scalars["String"]>;
	originalID: Maybe<Scalars["ID"]>;
	startDate: Maybe<Scalars["Time"]>;
};

export type TermHistoryConnection = {
	__typename?: "TermHistoryConnection";
	edges: Array<TermHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type TermHistoryEdge = {
	__typename?: "TermHistoryEdge";
	cursor: Scalars["Cursor"];
	node: TermHistory;
};

export type TermHistoryFilterInput = {
	academicYearIDEQ?: InputMaybe<Scalars["ID"]>;
	academicYearIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	academicYearIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	academicYearIDNEQ?: InputMaybe<Scalars["ID"]>;
	academicYearIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	and?: InputMaybe<Array<TermHistoryFilterInput>>;
	endDateEQ?: InputMaybe<Scalars["Time"]>;
	endDateGT?: InputMaybe<Scalars["Time"]>;
	endDateGTE?: InputMaybe<Scalars["Time"]>;
	endDateIn?: InputMaybe<Array<Scalars["Time"]>>;
	endDateIsNull?: InputMaybe<Scalars["Boolean"]>;
	endDateLT?: InputMaybe<Scalars["Time"]>;
	endDateLTE?: InputMaybe<Scalars["Time"]>;
	endDateNEQ?: InputMaybe<Scalars["Time"]>;
	endDateNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	nameContains?: InputMaybe<Scalars["String"]>;
	nameEQ?: InputMaybe<Scalars["String"]>;
	nameHasPrefix?: InputMaybe<Scalars["String"]>;
	nameHasSuffix?: InputMaybe<Scalars["String"]>;
	nameIn?: InputMaybe<Array<Scalars["String"]>>;
	nameIsNull?: InputMaybe<Scalars["Boolean"]>;
	nameNEQ?: InputMaybe<Scalars["String"]>;
	nameNotContains?: InputMaybe<Scalars["String"]>;
	nameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	nameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	nameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	not?: InputMaybe<TermHistoryFilterInput>;
	or?: InputMaybe<Array<TermHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	search?: InputMaybe<Scalars["String"]>;
	startDateEQ?: InputMaybe<Scalars["Time"]>;
	startDateGT?: InputMaybe<Scalars["Time"]>;
	startDateGTE?: InputMaybe<Scalars["Time"]>;
	startDateIn?: InputMaybe<Array<Scalars["Time"]>>;
	startDateIsNull?: InputMaybe<Scalars["Boolean"]>;
	startDateLT?: InputMaybe<Scalars["Time"]>;
	startDateLTE?: InputMaybe<Scalars["Time"]>;
	startDateNEQ?: InputMaybe<Scalars["Time"]>;
	startDateNotIn?: InputMaybe<Array<Scalars["Time"]>>;
};

export type TermHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<TermHistoryOrderField>;
};

export enum TermHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type TermInAcademicYearCreateInput = {
	courseSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	courseSections?: InputMaybe<Array<CourseSectionInTermCreateInput>>;
	endDate: Scalars["Time"];
	homeRoomSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	homeRoomSections?: InputMaybe<Array<HomeRoomSectionInTermCreateInput>>;
	name: Scalars["String"];
	reportCardIDs?: InputMaybe<Array<Scalars["ID"]>>;
	reportCards?: InputMaybe<Array<ReportCardInTermCreateInput>>;
	startDate: Scalars["Time"];
};

export type TermOrder = {
	direction: OrderDirection;
	field?: InputMaybe<TermOrderField>;
};

export enum TermOrderField {
	EndDate = "END_DATE",
	Name = "NAME",
	StartDate = "START_DATE",
}

export type TermUpdateBulkInput = {
	academicYearID?: InputMaybe<Scalars["ID"]>;
	addCourseSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addHomeRoomSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addReportCardIDs?: InputMaybe<Array<Scalars["ID"]>>;
	endDate?: InputMaybe<Scalars["Time"]>;
	name?: InputMaybe<Scalars["String"]>;
	removeCourseSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeHomeRoomSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeReportCardIDs?: InputMaybe<Array<Scalars["ID"]>>;
	startDate?: InputMaybe<Scalars["Time"]>;
};

export type TermUpdateInput = {
	academicYearID?: InputMaybe<Scalars["ID"]>;
	addCourseSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addHomeRoomSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addReportCardIDs?: InputMaybe<Array<Scalars["ID"]>>;
	endDate?: InputMaybe<Scalars["Time"]>;
	name?: InputMaybe<Scalars["String"]>;
	removeCourseSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeHomeRoomSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeReportCardIDs?: InputMaybe<Array<Scalars["ID"]>>;
	startDate?: InputMaybe<Scalars["Time"]>;
};

export type TimelineFilterInput = {
	endDate?: InputMaybe<Scalars["Time"]>;
	homeRoomSectionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	limit?: InputMaybe<Scalars["Int"]>;
	objectTypes?: InputMaybe<Array<TimelineObjectType>>;
	offset?: InputMaybe<Scalars["Int"]>;
	startDate?: InputMaybe<Scalars["Time"]>;
};

export type TimelineItem = {
	__typename?: "TimelineItem";
	actorID: Scalars["ID"];
	actorImageURL: Scalars["String"];
	actorName: Scalars["String"];
	eventDate: Scalars["Time"];
	eventTime: Maybe<Scalars["Time"]>;
	objectDetails: Maybe<Scalars["Map"]>;
	objectID: Scalars["ID"];
	objectName: Scalars["String"];
	objectType: TimelineObjectType;
};

export enum TimelineObjectType {
	Contract = "CONTRACT",
	Invoice = "INVOICE",
	Note = "NOTE",
	Payment = "PAYMENT",
	ReportCard = "REPORT_CARD",
	StudentDocument = "STUDENT_DOCUMENT",
	Term = "TERM",
}

export type TrustedDevice = Node & {
	__typename?: "TrustedDevice";
	createdAt: Scalars["Time"];
	id: Scalars["ID"];
	isCurrent: Scalars["Boolean"];
	name: Scalars["String"];
	sessions: Array<Session>;
	user: User;
	userID: Scalars["ID"];
};

export type TrustedDeviceSessionsArgs = {
	filters?: InputMaybe<Array<SessionFilterInput>>;
	order?: InputMaybe<SessionOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type TrustedDeviceConnection = {
	__typename?: "TrustedDeviceConnection";
	edges: Array<TrustedDeviceEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type TrustedDeviceEdge = {
	__typename?: "TrustedDeviceEdge";
	cursor: Scalars["Cursor"];
	node: TrustedDevice;
};

export type TrustedDeviceFilterInput = {
	and?: InputMaybe<Array<TrustedDeviceFilterInput>>;
	createdAtEQ?: InputMaybe<Scalars["Time"]>;
	createdAtGT?: InputMaybe<Scalars["Time"]>;
	createdAtGTE?: InputMaybe<Scalars["Time"]>;
	createdAtIn?: InputMaybe<Array<Scalars["Time"]>>;
	createdAtLT?: InputMaybe<Scalars["Time"]>;
	createdAtLTE?: InputMaybe<Scalars["Time"]>;
	createdAtNEQ?: InputMaybe<Scalars["Time"]>;
	createdAtNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	hasSessions?: InputMaybe<Scalars["Boolean"]>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	nameContains?: InputMaybe<Scalars["String"]>;
	nameEQ?: InputMaybe<Scalars["String"]>;
	nameHasPrefix?: InputMaybe<Scalars["String"]>;
	nameHasSuffix?: InputMaybe<Scalars["String"]>;
	nameIn?: InputMaybe<Array<Scalars["String"]>>;
	nameNEQ?: InputMaybe<Scalars["String"]>;
	nameNotContains?: InputMaybe<Scalars["String"]>;
	nameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	nameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	nameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	not?: InputMaybe<TrustedDeviceFilterInput>;
	or?: InputMaybe<Array<TrustedDeviceFilterInput>>;
	search?: InputMaybe<Scalars["String"]>;
	sessions?: InputMaybe<Array<SessionFilterInput>>;
	user?: InputMaybe<Array<UserFilterInput>>;
	userIDEQ?: InputMaybe<Scalars["ID"]>;
	userIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	userIDNEQ?: InputMaybe<Scalars["ID"]>;
	userIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type TrustedDeviceOrder = {
	direction: OrderDirection;
	field?: InputMaybe<TrustedDeviceOrderField>;
};

export enum TrustedDeviceOrderField {
	CreatedAt = "CREATED_AT",
}

export type Tuition = Node & {
	__typename?: "Tuition";
	academicYearTuitions: Array<AcademicYearTuition>;
	id: Scalars["ID"];
	initialYear: Scalars["Int"];
	name: Scalars["String"];
	students: Array<Student>;
};

export type TuitionAcademicYearTuitionsArgs = {
	filters?: InputMaybe<Array<AcademicYearTuitionFilterInput>>;
	order?: InputMaybe<AcademicYearTuitionOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type TuitionStudentsArgs = {
	filters?: InputMaybe<Array<StudentFilterInput>>;
	order?: InputMaybe<StudentOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type TuitionConnection = {
	__typename?: "TuitionConnection";
	edges: Array<TuitionEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type TuitionCreateInput = {
	academicYearTuitionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	academicYearTuitions?: InputMaybe<Array<AcademicYearTuitionInTuitionCreateInput>>;
	initialYear: Scalars["Int"];
	name: Scalars["String"];
	studentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	students?: InputMaybe<Array<StudentInTuitionCreateInput>>;
};

export type TuitionEdge = {
	__typename?: "TuitionEdge";
	cursor: Scalars["Cursor"];
	node: Tuition;
};

export type TuitionFilterInput = {
	academicYearTuitions?: InputMaybe<Array<AcademicYearTuitionFilterInput>>;
	and?: InputMaybe<Array<TuitionFilterInput>>;
	hasAcademicYearTuitions?: InputMaybe<Scalars["Boolean"]>;
	hasStudents?: InputMaybe<Scalars["Boolean"]>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	initialYearEQ?: InputMaybe<Scalars["Int"]>;
	initialYearGT?: InputMaybe<Scalars["Int"]>;
	initialYearGTE?: InputMaybe<Scalars["Int"]>;
	initialYearIn?: InputMaybe<Array<Scalars["Int"]>>;
	initialYearLT?: InputMaybe<Scalars["Int"]>;
	initialYearLTE?: InputMaybe<Scalars["Int"]>;
	initialYearNEQ?: InputMaybe<Scalars["Int"]>;
	initialYearNotIn?: InputMaybe<Array<Scalars["Int"]>>;
	nameContains?: InputMaybe<Scalars["String"]>;
	nameEQ?: InputMaybe<Scalars["String"]>;
	nameHasPrefix?: InputMaybe<Scalars["String"]>;
	nameHasSuffix?: InputMaybe<Scalars["String"]>;
	nameIn?: InputMaybe<Array<Scalars["String"]>>;
	nameNEQ?: InputMaybe<Scalars["String"]>;
	nameNotContains?: InputMaybe<Scalars["String"]>;
	nameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	nameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	nameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	not?: InputMaybe<TuitionFilterInput>;
	or?: InputMaybe<Array<TuitionFilterInput>>;
	search?: InputMaybe<Scalars["String"]>;
	students?: InputMaybe<Array<StudentFilterInput>>;
};

export type TuitionHistory = Node & {
	__typename?: "TuitionHistory";
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	initialYear: Maybe<Scalars["Int"]>;
	name: Maybe<Scalars["String"]>;
	originalID: Maybe<Scalars["ID"]>;
};

export type TuitionHistoryConnection = {
	__typename?: "TuitionHistoryConnection";
	edges: Array<TuitionHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type TuitionHistoryEdge = {
	__typename?: "TuitionHistoryEdge";
	cursor: Scalars["Cursor"];
	node: TuitionHistory;
};

export type TuitionHistoryFilterInput = {
	and?: InputMaybe<Array<TuitionHistoryFilterInput>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	initialYearEQ?: InputMaybe<Scalars["Int"]>;
	initialYearGT?: InputMaybe<Scalars["Int"]>;
	initialYearGTE?: InputMaybe<Scalars["Int"]>;
	initialYearIn?: InputMaybe<Array<Scalars["Int"]>>;
	initialYearIsNull?: InputMaybe<Scalars["Boolean"]>;
	initialYearLT?: InputMaybe<Scalars["Int"]>;
	initialYearLTE?: InputMaybe<Scalars["Int"]>;
	initialYearNEQ?: InputMaybe<Scalars["Int"]>;
	initialYearNotIn?: InputMaybe<Array<Scalars["Int"]>>;
	nameContains?: InputMaybe<Scalars["String"]>;
	nameEQ?: InputMaybe<Scalars["String"]>;
	nameHasPrefix?: InputMaybe<Scalars["String"]>;
	nameHasSuffix?: InputMaybe<Scalars["String"]>;
	nameIn?: InputMaybe<Array<Scalars["String"]>>;
	nameIsNull?: InputMaybe<Scalars["Boolean"]>;
	nameNEQ?: InputMaybe<Scalars["String"]>;
	nameNotContains?: InputMaybe<Scalars["String"]>;
	nameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	nameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	nameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	not?: InputMaybe<TuitionHistoryFilterInput>;
	or?: InputMaybe<Array<TuitionHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	search?: InputMaybe<Scalars["String"]>;
};

export type TuitionHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<TuitionHistoryOrderField>;
};

export enum TuitionHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type TuitionOrder = {
	direction: OrderDirection;
	field?: InputMaybe<TuitionOrderField>;
};

export enum TuitionOrderField {
	Name = "NAME",
}

export type TuitionUpdateBulkInput = {
	addAcademicYearTuitionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addStudentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	initialYear?: InputMaybe<Scalars["Int"]>;
	name?: InputMaybe<Scalars["String"]>;
	removeAcademicYearTuitionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeStudentIDs?: InputMaybe<Array<Scalars["ID"]>>;
};

export type TuitionUpdateInput = {
	addAcademicYearTuitionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addStudentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	initialYear?: InputMaybe<Scalars["Int"]>;
	name?: InputMaybe<Scalars["String"]>;
	removeAcademicYearTuitionIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeStudentIDs?: InputMaybe<Array<Scalars["ID"]>>;
};

export type User = Node & {
	__typename?: "User";
	authoredCalendarEvents: Array<CalendarEvent>;
	authoredDocumentResourceFolders: Array<DocumentResourceFolder>;
	authoredDocumentResources: Array<DocumentResource>;
	authoredEnrollmentApplicationNotes: Array<EnrollmentApplicationNote>;
	authoredNoteComments: Array<NoteComment>;
	authoredNoteReactions: Array<NoteReaction>;
	authoredNotes: Array<Note>;
	bookmarkedDocumentResources: Array<DocumentResource>;
	documentResourceUserViewRecords: Array<DocumentResourceUserViewRecord>;
	email: Scalars["String"];
	enrollmentApplicationUserViewRecords: Array<EnrollmentApplicationUserViewRecord>;
	id: Scalars["ID"];
	integrationTokens: Array<IntegrationToken>;
	loginRecords: Array<LoginRecord>;
	mfaFactor: Maybe<MFAFactor>;
	mfaInvalidOTPAttempts: Array<MFAInvalidOTPAttempt>;
	passwordUpdatedAt: Scalars["Time"];
	permissionGroups: Array<PermissionGroup>;
	person: Maybe<Person>;
	sessions: Array<Session>;
	sharedDocumentResourceFolders: Array<DocumentResourceFolder>;
	sharedNotes: Array<Note>;
	status: UserStatus;
	studentDocuments: Array<StudentDocument>;
	trustedDevices: Array<TrustedDevice>;
	userGroups: Array<UserGroup>;
};

export type UserAuthoredCalendarEventsArgs = {
	filters?: InputMaybe<Array<CalendarEventFilterInput>>;
	order?: InputMaybe<CalendarEventOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type UserAuthoredDocumentResourceFoldersArgs = {
	filters?: InputMaybe<Array<DocumentResourceFolderFilterInput>>;
	order?: InputMaybe<DocumentResourceFolderOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type UserAuthoredDocumentResourcesArgs = {
	filters?: InputMaybe<Array<DocumentResourceFilterInput>>;
	order?: InputMaybe<DocumentResourceOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type UserAuthoredEnrollmentApplicationNotesArgs = {
	filters?: InputMaybe<Array<EnrollmentApplicationNoteFilterInput>>;
	order?: InputMaybe<EnrollmentApplicationNoteOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type UserAuthoredNoteCommentsArgs = {
	filters?: InputMaybe<Array<NoteCommentFilterInput>>;
	order?: InputMaybe<NoteCommentOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type UserAuthoredNoteReactionsArgs = {
	filters?: InputMaybe<Array<NoteReactionFilterInput>>;
	order?: InputMaybe<NoteReactionOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type UserAuthoredNotesArgs = {
	filters?: InputMaybe<Array<NoteFilterInput>>;
	order?: InputMaybe<NoteOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type UserBookmarkedDocumentResourcesArgs = {
	filters?: InputMaybe<Array<DocumentResourceFilterInput>>;
	order?: InputMaybe<DocumentResourceOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type UserDocumentResourceUserViewRecordsArgs = {
	filters?: InputMaybe<Array<DocumentResourceUserViewRecordFilterInput>>;
	order?: InputMaybe<DocumentResourceUserViewRecordOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type UserEnrollmentApplicationUserViewRecordsArgs = {
	filters?: InputMaybe<Array<EnrollmentApplicationUserViewRecordFilterInput>>;
	order?: InputMaybe<EnrollmentApplicationUserViewRecordOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type UserIntegrationTokensArgs = {
	filters?: InputMaybe<Array<IntegrationTokenFilterInput>>;
	order?: InputMaybe<IntegrationTokenOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type UserLoginRecordsArgs = {
	filters?: InputMaybe<Array<LoginRecordFilterInput>>;
	order?: InputMaybe<LoginRecordOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type UserMFAInvalidOTPAttemptsArgs = {
	filters?: InputMaybe<Array<MFAInvalidOTPAttemptFilterInput>>;
	order?: InputMaybe<MFAInvalidOTPAttemptOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type UserPermissionGroupsArgs = {
	filters?: InputMaybe<Array<PermissionGroupFilterInput>>;
	order?: InputMaybe<PermissionGroupOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type UserSessionsArgs = {
	filters?: InputMaybe<Array<SessionFilterInput>>;
	order?: InputMaybe<SessionOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type UserSharedDocumentResourceFoldersArgs = {
	filters?: InputMaybe<Array<DocumentResourceFolderFilterInput>>;
	order?: InputMaybe<DocumentResourceFolderOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type UserSharedNotesArgs = {
	filters?: InputMaybe<Array<NoteFilterInput>>;
	order?: InputMaybe<NoteOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type UserStudentDocumentsArgs = {
	filters?: InputMaybe<Array<StudentDocumentFilterInput>>;
	order?: InputMaybe<StudentDocumentOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type UserTrustedDevicesArgs = {
	filters?: InputMaybe<Array<TrustedDeviceFilterInput>>;
	order?: InputMaybe<TrustedDeviceOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type UserUserGroupsArgs = {
	filters?: InputMaybe<Array<UserGroupFilterInput>>;
	order?: InputMaybe<UserGroupOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type UserConnection = {
	__typename?: "UserConnection";
	edges: Array<UserEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type UserCreateInput = {
	bookmarkedDocumentResourceIDs?: InputMaybe<Array<Scalars["ID"]>>;
	email: Scalars["String"];
	permissionGroupIDs?: InputMaybe<Array<Scalars["ID"]>>;
	person?: InputMaybe<PersonInUserCreateInput>;
	personID?: InputMaybe<Scalars["ID"]>;
	sharedDocumentResourceFolderIDs?: InputMaybe<Array<Scalars["ID"]>>;
	sharedNoteIDs?: InputMaybe<Array<Scalars["ID"]>>;
	userGroupIDs?: InputMaybe<Array<Scalars["ID"]>>;
};

export type UserEdge = {
	__typename?: "UserEdge";
	cursor: Scalars["Cursor"];
	node: User;
};

export type UserFilterInput = {
	and?: InputMaybe<Array<UserFilterInput>>;
	authoredCalendarEvents?: InputMaybe<Array<CalendarEventFilterInput>>;
	authoredDocumentResourceFolders?: InputMaybe<Array<DocumentResourceFolderFilterInput>>;
	authoredDocumentResources?: InputMaybe<Array<DocumentResourceFilterInput>>;
	authoredEnrollmentApplicationNotes?: InputMaybe<Array<EnrollmentApplicationNoteFilterInput>>;
	authoredNoteComments?: InputMaybe<Array<NoteCommentFilterInput>>;
	authoredNoteReactions?: InputMaybe<Array<NoteReactionFilterInput>>;
	authoredNotes?: InputMaybe<Array<NoteFilterInput>>;
	bookmarkedDocumentResources?: InputMaybe<Array<DocumentResourceFilterInput>>;
	documentResourceUserViewRecords?: InputMaybe<Array<DocumentResourceUserViewRecordFilterInput>>;
	emailContains?: InputMaybe<Scalars["String"]>;
	emailEQ?: InputMaybe<Scalars["String"]>;
	emailHasPrefix?: InputMaybe<Scalars["String"]>;
	emailHasSuffix?: InputMaybe<Scalars["String"]>;
	emailIn?: InputMaybe<Array<Scalars["String"]>>;
	emailNEQ?: InputMaybe<Scalars["String"]>;
	emailNotContains?: InputMaybe<Scalars["String"]>;
	emailNotHasPrefix?: InputMaybe<Scalars["String"]>;
	emailNotHasSuffix?: InputMaybe<Scalars["String"]>;
	emailNotIn?: InputMaybe<Array<Scalars["String"]>>;
	enrollmentApplicationUserViewRecords?: InputMaybe<Array<EnrollmentApplicationUserViewRecordFilterInput>>;
	hasAuthoredCalendarEvents?: InputMaybe<Scalars["Boolean"]>;
	hasAuthoredDocumentResourceFolders?: InputMaybe<Scalars["Boolean"]>;
	hasAuthoredDocumentResources?: InputMaybe<Scalars["Boolean"]>;
	hasAuthoredEnrollmentApplicationNotes?: InputMaybe<Scalars["Boolean"]>;
	hasAuthoredNoteComments?: InputMaybe<Scalars["Boolean"]>;
	hasAuthoredNoteReactions?: InputMaybe<Scalars["Boolean"]>;
	hasAuthoredNotes?: InputMaybe<Scalars["Boolean"]>;
	hasBookmarkedDocumentResources?: InputMaybe<Scalars["Boolean"]>;
	hasDocumentResourceUserViewRecords?: InputMaybe<Scalars["Boolean"]>;
	hasEnrollmentApplicationUserViewRecords?: InputMaybe<Scalars["Boolean"]>;
	hasIntegrationTokens?: InputMaybe<Scalars["Boolean"]>;
	hasLoginRecords?: InputMaybe<Scalars["Boolean"]>;
	hasMFAFactor?: InputMaybe<Scalars["Boolean"]>;
	hasMFAInvalidOTPAttempts?: InputMaybe<Scalars["Boolean"]>;
	hasPermissionGroups?: InputMaybe<Scalars["Boolean"]>;
	hasPerson?: InputMaybe<Scalars["Boolean"]>;
	hasSessions?: InputMaybe<Scalars["Boolean"]>;
	hasSharedDocumentResourceFolders?: InputMaybe<Scalars["Boolean"]>;
	hasSharedNotes?: InputMaybe<Scalars["Boolean"]>;
	hasStudentDocuments?: InputMaybe<Scalars["Boolean"]>;
	hasTrustedDevices?: InputMaybe<Scalars["Boolean"]>;
	hasUserGroups?: InputMaybe<Scalars["Boolean"]>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	integrationTokens?: InputMaybe<Array<IntegrationTokenFilterInput>>;
	loginRecords?: InputMaybe<Array<LoginRecordFilterInput>>;
	mfaFactor?: InputMaybe<Array<MFAFactorFilterInput>>;
	mfaInvalidOTPAttempts?: InputMaybe<Array<MFAInvalidOTPAttemptFilterInput>>;
	not?: InputMaybe<UserFilterInput>;
	or?: InputMaybe<Array<UserFilterInput>>;
	passwordUpdatedAtEQ?: InputMaybe<Scalars["Time"]>;
	passwordUpdatedAtGT?: InputMaybe<Scalars["Time"]>;
	passwordUpdatedAtGTE?: InputMaybe<Scalars["Time"]>;
	passwordUpdatedAtIn?: InputMaybe<Array<Scalars["Time"]>>;
	passwordUpdatedAtLT?: InputMaybe<Scalars["Time"]>;
	passwordUpdatedAtLTE?: InputMaybe<Scalars["Time"]>;
	passwordUpdatedAtNEQ?: InputMaybe<Scalars["Time"]>;
	passwordUpdatedAtNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	permissionGroups?: InputMaybe<Array<PermissionGroupFilterInput>>;
	person?: InputMaybe<Array<PersonFilterInput>>;
	search?: InputMaybe<Scalars["String"]>;
	sessions?: InputMaybe<Array<SessionFilterInput>>;
	sharedDocumentResourceFolders?: InputMaybe<Array<DocumentResourceFolderFilterInput>>;
	sharedNotes?: InputMaybe<Array<NoteFilterInput>>;
	statusEQ?: InputMaybe<UserStatus>;
	statusIn?: InputMaybe<Array<UserStatus>>;
	statusNEQ?: InputMaybe<UserStatus>;
	statusNotIn?: InputMaybe<Array<UserStatus>>;
	studentDocuments?: InputMaybe<Array<StudentDocumentFilterInput>>;
	trustedDevices?: InputMaybe<Array<TrustedDeviceFilterInput>>;
	userGroups?: InputMaybe<Array<UserGroupFilterInput>>;
};

export type UserGroup = Node & {
	__typename?: "UserGroup";
	color: Scalars["String"];
	description: Scalars["String"];
	id: Scalars["ID"];
	imageContentType: Scalars["String"];
	imageURL: Scalars["String"];
	name: Scalars["String"];
	sharedCalendarEvents: Array<CalendarEvent>;
	sharedDocumentResourceFolders: Array<DocumentResourceFolder>;
	sharedStudentDocuments: Array<StudentDocument>;
	users: Array<User>;
};

export type UserGroupSharedCalendarEventsArgs = {
	filters?: InputMaybe<Array<CalendarEventFilterInput>>;
	order?: InputMaybe<CalendarEventOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type UserGroupSharedDocumentResourceFoldersArgs = {
	filters?: InputMaybe<Array<DocumentResourceFolderFilterInput>>;
	order?: InputMaybe<DocumentResourceFolderOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type UserGroupSharedStudentDocumentsArgs = {
	filters?: InputMaybe<Array<StudentDocumentFilterInput>>;
	order?: InputMaybe<StudentDocumentOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type UserGroupUsersArgs = {
	filters?: InputMaybe<Array<UserFilterInput>>;
	order?: InputMaybe<UserOrder>;
	pagination?: InputMaybe<EdgePagination>;
};

export type UserGroupConnection = {
	__typename?: "UserGroupConnection";
	edges: Array<UserGroupEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type UserGroupCreateInput = {
	color?: InputMaybe<Scalars["String"]>;
	description?: InputMaybe<Scalars["String"]>;
	image?: InputMaybe<Scalars["Upload"]>;
	name: Scalars["String"];
	sharedCalendarEventIDs?: InputMaybe<Array<Scalars["ID"]>>;
	sharedDocumentResourceFolderIDs?: InputMaybe<Array<Scalars["ID"]>>;
	sharedStudentDocumentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	userIDs?: InputMaybe<Array<Scalars["ID"]>>;
};

export type UserGroupEdge = {
	__typename?: "UserGroupEdge";
	cursor: Scalars["Cursor"];
	node: UserGroup;
};

export type UserGroupFilterInput = {
	and?: InputMaybe<Array<UserGroupFilterInput>>;
	colorContains?: InputMaybe<Scalars["String"]>;
	colorEQ?: InputMaybe<Scalars["String"]>;
	colorHasPrefix?: InputMaybe<Scalars["String"]>;
	colorHasSuffix?: InputMaybe<Scalars["String"]>;
	colorIn?: InputMaybe<Array<Scalars["String"]>>;
	colorNEQ?: InputMaybe<Scalars["String"]>;
	colorNotContains?: InputMaybe<Scalars["String"]>;
	colorNotHasPrefix?: InputMaybe<Scalars["String"]>;
	colorNotHasSuffix?: InputMaybe<Scalars["String"]>;
	colorNotIn?: InputMaybe<Array<Scalars["String"]>>;
	descriptionContains?: InputMaybe<Scalars["String"]>;
	descriptionEQ?: InputMaybe<Scalars["String"]>;
	descriptionHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionIn?: InputMaybe<Array<Scalars["String"]>>;
	descriptionNEQ?: InputMaybe<Scalars["String"]>;
	descriptionNotContains?: InputMaybe<Scalars["String"]>;
	descriptionNotHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionNotHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionNotIn?: InputMaybe<Array<Scalars["String"]>>;
	hasSharedCalendarEvents?: InputMaybe<Scalars["Boolean"]>;
	hasSharedDocumentResourceFolders?: InputMaybe<Scalars["Boolean"]>;
	hasSharedStudentDocuments?: InputMaybe<Scalars["Boolean"]>;
	hasUsers?: InputMaybe<Scalars["Boolean"]>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	imageContentTypeContains?: InputMaybe<Scalars["String"]>;
	imageContentTypeEQ?: InputMaybe<Scalars["String"]>;
	imageContentTypeHasPrefix?: InputMaybe<Scalars["String"]>;
	imageContentTypeHasSuffix?: InputMaybe<Scalars["String"]>;
	imageContentTypeIn?: InputMaybe<Array<Scalars["String"]>>;
	imageContentTypeNEQ?: InputMaybe<Scalars["String"]>;
	imageContentTypeNotContains?: InputMaybe<Scalars["String"]>;
	imageContentTypeNotHasPrefix?: InputMaybe<Scalars["String"]>;
	imageContentTypeNotHasSuffix?: InputMaybe<Scalars["String"]>;
	imageContentTypeNotIn?: InputMaybe<Array<Scalars["String"]>>;
	imageIsNull?: InputMaybe<Scalars["Boolean"]>;
	nameContains?: InputMaybe<Scalars["String"]>;
	nameEQ?: InputMaybe<Scalars["String"]>;
	nameHasPrefix?: InputMaybe<Scalars["String"]>;
	nameHasSuffix?: InputMaybe<Scalars["String"]>;
	nameIn?: InputMaybe<Array<Scalars["String"]>>;
	nameNEQ?: InputMaybe<Scalars["String"]>;
	nameNotContains?: InputMaybe<Scalars["String"]>;
	nameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	nameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	nameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	not?: InputMaybe<UserGroupFilterInput>;
	or?: InputMaybe<Array<UserGroupFilterInput>>;
	search?: InputMaybe<Scalars["String"]>;
	sharedCalendarEvents?: InputMaybe<Array<CalendarEventFilterInput>>;
	sharedDocumentResourceFolders?: InputMaybe<Array<DocumentResourceFolderFilterInput>>;
	sharedStudentDocuments?: InputMaybe<Array<StudentDocumentFilterInput>>;
	users?: InputMaybe<Array<UserFilterInput>>;
};

export type UserGroupHistory = Node & {
	__typename?: "UserGroupHistory";
	color: Maybe<Scalars["String"]>;
	description: Maybe<Scalars["String"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	imageContentType: Maybe<Scalars["String"]>;
	name: Maybe<Scalars["String"]>;
	originalID: Maybe<Scalars["ID"]>;
};

export type UserGroupHistoryConnection = {
	__typename?: "UserGroupHistoryConnection";
	edges: Array<UserGroupHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type UserGroupHistoryEdge = {
	__typename?: "UserGroupHistoryEdge";
	cursor: Scalars["Cursor"];
	node: UserGroupHistory;
};

export type UserGroupHistoryFilterInput = {
	and?: InputMaybe<Array<UserGroupHistoryFilterInput>>;
	colorContains?: InputMaybe<Scalars["String"]>;
	colorEQ?: InputMaybe<Scalars["String"]>;
	colorHasPrefix?: InputMaybe<Scalars["String"]>;
	colorHasSuffix?: InputMaybe<Scalars["String"]>;
	colorIn?: InputMaybe<Array<Scalars["String"]>>;
	colorIsNull?: InputMaybe<Scalars["Boolean"]>;
	colorNEQ?: InputMaybe<Scalars["String"]>;
	colorNotContains?: InputMaybe<Scalars["String"]>;
	colorNotHasPrefix?: InputMaybe<Scalars["String"]>;
	colorNotHasSuffix?: InputMaybe<Scalars["String"]>;
	colorNotIn?: InputMaybe<Array<Scalars["String"]>>;
	descriptionContains?: InputMaybe<Scalars["String"]>;
	descriptionEQ?: InputMaybe<Scalars["String"]>;
	descriptionHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionIn?: InputMaybe<Array<Scalars["String"]>>;
	descriptionIsNull?: InputMaybe<Scalars["Boolean"]>;
	descriptionNEQ?: InputMaybe<Scalars["String"]>;
	descriptionNotContains?: InputMaybe<Scalars["String"]>;
	descriptionNotHasPrefix?: InputMaybe<Scalars["String"]>;
	descriptionNotHasSuffix?: InputMaybe<Scalars["String"]>;
	descriptionNotIn?: InputMaybe<Array<Scalars["String"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	imageContentTypeContains?: InputMaybe<Scalars["String"]>;
	imageContentTypeEQ?: InputMaybe<Scalars["String"]>;
	imageContentTypeHasPrefix?: InputMaybe<Scalars["String"]>;
	imageContentTypeHasSuffix?: InputMaybe<Scalars["String"]>;
	imageContentTypeIn?: InputMaybe<Array<Scalars["String"]>>;
	imageContentTypeIsNull?: InputMaybe<Scalars["Boolean"]>;
	imageContentTypeNEQ?: InputMaybe<Scalars["String"]>;
	imageContentTypeNotContains?: InputMaybe<Scalars["String"]>;
	imageContentTypeNotHasPrefix?: InputMaybe<Scalars["String"]>;
	imageContentTypeNotHasSuffix?: InputMaybe<Scalars["String"]>;
	imageContentTypeNotIn?: InputMaybe<Array<Scalars["String"]>>;
	nameContains?: InputMaybe<Scalars["String"]>;
	nameEQ?: InputMaybe<Scalars["String"]>;
	nameHasPrefix?: InputMaybe<Scalars["String"]>;
	nameHasSuffix?: InputMaybe<Scalars["String"]>;
	nameIn?: InputMaybe<Array<Scalars["String"]>>;
	nameIsNull?: InputMaybe<Scalars["Boolean"]>;
	nameNEQ?: InputMaybe<Scalars["String"]>;
	nameNotContains?: InputMaybe<Scalars["String"]>;
	nameNotHasPrefix?: InputMaybe<Scalars["String"]>;
	nameNotHasSuffix?: InputMaybe<Scalars["String"]>;
	nameNotIn?: InputMaybe<Array<Scalars["String"]>>;
	not?: InputMaybe<UserGroupHistoryFilterInput>;
	or?: InputMaybe<Array<UserGroupHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	search?: InputMaybe<Scalars["String"]>;
};

export type UserGroupHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<UserGroupHistoryOrderField>;
};

export enum UserGroupHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type UserGroupOrder = {
	direction: OrderDirection;
	field?: InputMaybe<UserGroupOrderField>;
};

export enum UserGroupOrderField {
	Name = "NAME",
}

export type UserGroupUpdateBulkInput = {
	addSharedCalendarEventIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addSharedDocumentResourceFolderIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addSharedStudentDocumentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addUserIDs?: InputMaybe<Array<Scalars["ID"]>>;
	clearImage?: InputMaybe<Scalars["Boolean"]>;
	color?: InputMaybe<Scalars["String"]>;
	description?: InputMaybe<Scalars["String"]>;
	image?: InputMaybe<Scalars["Upload"]>;
	removeSharedCalendarEventIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeSharedDocumentResourceFolderIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeSharedStudentDocumentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeUserIDs?: InputMaybe<Array<Scalars["ID"]>>;
};

export type UserGroupUpdateInput = {
	addSharedCalendarEventIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addSharedDocumentResourceFolderIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addSharedStudentDocumentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addUserIDs?: InputMaybe<Array<Scalars["ID"]>>;
	clearImage?: InputMaybe<Scalars["Boolean"]>;
	color?: InputMaybe<Scalars["String"]>;
	description?: InputMaybe<Scalars["String"]>;
	image?: InputMaybe<Scalars["Upload"]>;
	name?: InputMaybe<Scalars["String"]>;
	removeSharedCalendarEventIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeSharedDocumentResourceFolderIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeSharedStudentDocumentIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeUserIDs?: InputMaybe<Array<Scalars["ID"]>>;
};

export type UserGroupUserHistory = Node & {
	__typename?: "UserGroupUserHistory";
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	userGroupID: Maybe<Scalars["ID"]>;
	userID: Maybe<Scalars["ID"]>;
};

export type UserGroupUserHistoryConnection = {
	__typename?: "UserGroupUserHistoryConnection";
	edges: Array<UserGroupUserHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type UserGroupUserHistoryEdge = {
	__typename?: "UserGroupUserHistoryEdge";
	cursor: Scalars["Cursor"];
	node: UserGroupUserHistory;
};

export type UserGroupUserHistoryFilterInput = {
	and?: InputMaybe<Array<UserGroupUserHistoryFilterInput>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<UserGroupUserHistoryFilterInput>;
	or?: InputMaybe<Array<UserGroupUserHistoryFilterInput>>;
	userGroupIDEQ?: InputMaybe<Scalars["ID"]>;
	userGroupIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	userGroupIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	userGroupIDNEQ?: InputMaybe<Scalars["ID"]>;
	userGroupIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	userIDEQ?: InputMaybe<Scalars["ID"]>;
	userIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	userIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	userIDNEQ?: InputMaybe<Scalars["ID"]>;
	userIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type UserGroupUserHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<UserGroupUserHistoryOrderField>;
};

export enum UserGroupUserHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export type UserHistory = Node & {
	__typename?: "UserHistory";
	email: Maybe<Scalars["String"]>;
	historyIsSystem: Scalars["Boolean"];
	historyTime: Scalars["Time"];
	historyType: HistoryType;
	historyUserID: Maybe<Scalars["ID"]>;
	id: Scalars["ID"];
	originalID: Maybe<Scalars["ID"]>;
	passwordUpdatedAt: Maybe<Scalars["Time"]>;
	status: Maybe<UserHistoryStatus>;
};

export type UserHistoryConnection = {
	__typename?: "UserHistoryConnection";
	edges: Array<UserHistoryEdge>;
	pageInfo: PageInfo;
	totalCount: Scalars["Int"];
};

export type UserHistoryEdge = {
	__typename?: "UserHistoryEdge";
	cursor: Scalars["Cursor"];
	node: UserHistory;
};

export type UserHistoryFilterInput = {
	and?: InputMaybe<Array<UserHistoryFilterInput>>;
	emailContains?: InputMaybe<Scalars["String"]>;
	emailEQ?: InputMaybe<Scalars["String"]>;
	emailHasPrefix?: InputMaybe<Scalars["String"]>;
	emailHasSuffix?: InputMaybe<Scalars["String"]>;
	emailIn?: InputMaybe<Array<Scalars["String"]>>;
	emailIsNull?: InputMaybe<Scalars["Boolean"]>;
	emailNEQ?: InputMaybe<Scalars["String"]>;
	emailNotContains?: InputMaybe<Scalars["String"]>;
	emailNotHasPrefix?: InputMaybe<Scalars["String"]>;
	emailNotHasSuffix?: InputMaybe<Scalars["String"]>;
	emailNotIn?: InputMaybe<Array<Scalars["String"]>>;
	historyIsSystemEQ?: InputMaybe<Scalars["Boolean"]>;
	historyIsSystemNEQ?: InputMaybe<Scalars["Boolean"]>;
	historyTimeEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeGT?: InputMaybe<Scalars["Time"]>;
	historyTimeGTE?: InputMaybe<Scalars["Time"]>;
	historyTimeIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTimeLT?: InputMaybe<Scalars["Time"]>;
	historyTimeLTE?: InputMaybe<Scalars["Time"]>;
	historyTimeNEQ?: InputMaybe<Scalars["Time"]>;
	historyTimeNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	historyTypeEQ?: InputMaybe<HistoryType>;
	historyTypeIn?: InputMaybe<Array<HistoryType>>;
	historyTypeNEQ?: InputMaybe<HistoryType>;
	historyTypeNotIn?: InputMaybe<Array<HistoryType>>;
	historyUserIDEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	historyUserIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	historyUserIDNEQ?: InputMaybe<Scalars["ID"]>;
	historyUserIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	idEQ?: InputMaybe<Scalars["ID"]>;
	idIn?: InputMaybe<Array<Scalars["ID"]>>;
	idNEQ?: InputMaybe<Scalars["ID"]>;
	idNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	not?: InputMaybe<UserHistoryFilterInput>;
	or?: InputMaybe<Array<UserHistoryFilterInput>>;
	originalIDEQ?: InputMaybe<Scalars["ID"]>;
	originalIDIn?: InputMaybe<Array<Scalars["ID"]>>;
	originalIDIsNull?: InputMaybe<Scalars["Boolean"]>;
	originalIDNEQ?: InputMaybe<Scalars["ID"]>;
	originalIDNotIn?: InputMaybe<Array<Scalars["ID"]>>;
	passwordUpdatedAtEQ?: InputMaybe<Scalars["Time"]>;
	passwordUpdatedAtGT?: InputMaybe<Scalars["Time"]>;
	passwordUpdatedAtGTE?: InputMaybe<Scalars["Time"]>;
	passwordUpdatedAtIn?: InputMaybe<Array<Scalars["Time"]>>;
	passwordUpdatedAtIsNull?: InputMaybe<Scalars["Boolean"]>;
	passwordUpdatedAtLT?: InputMaybe<Scalars["Time"]>;
	passwordUpdatedAtLTE?: InputMaybe<Scalars["Time"]>;
	passwordUpdatedAtNEQ?: InputMaybe<Scalars["Time"]>;
	passwordUpdatedAtNotIn?: InputMaybe<Array<Scalars["Time"]>>;
	search?: InputMaybe<Scalars["String"]>;
	statusEQ?: InputMaybe<UserHistoryStatus>;
	statusIn?: InputMaybe<Array<UserHistoryStatus>>;
	statusIsNull?: InputMaybe<Scalars["Boolean"]>;
	statusNEQ?: InputMaybe<UserHistoryStatus>;
	statusNotIn?: InputMaybe<Array<UserHistoryStatus>>;
};

export type UserHistoryOrder = {
	direction: OrderDirection;
	field?: InputMaybe<UserHistoryOrderField>;
};

export enum UserHistoryOrderField {
	HistoryTime = "HISTORY_TIME",
}

export enum UserHistoryStatus {
	Active = "active",
	Created = "created",
	Disabled = "disabled",
	Invited = "invited",
}

export type UserInPersonCreateInput = {
	bookmarkedDocumentResourceIDs?: InputMaybe<Array<Scalars["ID"]>>;
	email: Scalars["String"];
	permissionGroupIDs?: InputMaybe<Array<Scalars["ID"]>>;
	sharedDocumentResourceFolderIDs?: InputMaybe<Array<Scalars["ID"]>>;
	sharedNoteIDs?: InputMaybe<Array<Scalars["ID"]>>;
	userGroupIDs?: InputMaybe<Array<Scalars["ID"]>>;
};

export type UserOrder = {
	direction: OrderDirection;
	field?: InputMaybe<UserOrderField>;
};

export enum UserOrderField {
	Email = "EMAIL",
	PasswordUpdatedAt = "PASSWORD_UPDATED_AT",
	Status = "STATUS",
}

export enum UserStatus {
	Active = "active",
	Created = "created",
	Disabled = "disabled",
	Invited = "invited",
}

export type UserUpdateBulkInput = {
	addBookmarkedDocumentResourceIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addPermissionGroupIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addSharedDocumentResourceFolderIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addSharedNoteIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addUserGroupIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeBookmarkedDocumentResourceIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removePermissionGroupIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeSharedDocumentResourceFolderIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeSharedNoteIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeUserGroupIDs?: InputMaybe<Array<Scalars["ID"]>>;
	status?: InputMaybe<UserStatus>;
};

export type UserUpdateInput = {
	addBookmarkedDocumentResourceIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addPermissionGroupIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addSharedDocumentResourceFolderIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addSharedNoteIDs?: InputMaybe<Array<Scalars["ID"]>>;
	addUserGroupIDs?: InputMaybe<Array<Scalars["ID"]>>;
	clearPerson?: InputMaybe<Scalars["Boolean"]>;
	email?: InputMaybe<Scalars["String"]>;
	personID?: InputMaybe<Scalars["ID"]>;
	removeBookmarkedDocumentResourceIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removePermissionGroupIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeSharedDocumentResourceFolderIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeSharedNoteIDs?: InputMaybe<Array<Scalars["ID"]>>;
	removeUserGroupIDs?: InputMaybe<Array<Scalars["ID"]>>;
	status?: InputMaybe<UserStatus>;
};

export type AcademicYearCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<AcademicYearFilterInput> | AcademicYearFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<AcademicYearOrder>;
}>;

export type AcademicYearCountQuery = {
	__typename?: "Query";
	academicYearConnection: { __typename?: "AcademicYearConnection"; totalCount: number };
};

export type AcademicYearCreateMutationVariables = Exact<{
	input: AcademicYearCreateInput;
}>;

export type AcademicYearCreateMutation = {
	__typename?: "Mutation";
	createAcademicYear: {
		__typename?: "AcademicYear";
		id: string;
		name: string;
		billingSetupSubmissions: Array<{ __typename?: "BillingSetupSubmission"; id: string }>;
		schoolStudentEnrollments: Array<{ __typename?: "SchoolStudentEnrollment"; id: string }>;
		terms: Array<{ __typename?: "Term"; id: string; name: string }>;
	};
};

export type AcademicYearDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type AcademicYearDeleteMutation = { __typename?: "Mutation"; deleteAcademicYear: boolean };

export type AcademicYearDetailFragment = {
	__typename?: "AcademicYear";
	id: string;
	name: string;
	billingSetupSubmissions: Array<{ __typename?: "BillingSetupSubmission"; id: string }>;
	schoolStudentEnrollments: Array<{ __typename?: "SchoolStudentEnrollment"; id: string }>;
	terms: Array<{ __typename?: "Term"; id: string; name: string }>;
};

export type AcademicYearDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type AcademicYearDetailQuery = {
	__typename?: "Query";
	academicYear: {
		__typename?: "AcademicYear";
		id: string;
		name: string;
		billingSetupSubmissions: Array<{ __typename?: "BillingSetupSubmission"; id: string }>;
		schoolStudentEnrollments: Array<{ __typename?: "SchoolStudentEnrollment"; id: string }>;
		terms: Array<{ __typename?: "Term"; id: string; name: string }>;
	};
};

export type AcademicYearIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<AcademicYearFilterInput> | AcademicYearFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<AcademicYearOrder>;
}>;

export type AcademicYearIndexQuery = {
	__typename?: "Query";
	academicYearConnection: {
		__typename?: "AcademicYearConnection";
		edges: Array<{ __typename?: "AcademicYearEdge"; node: { __typename?: "AcademicYear"; id: string; name: string } }>;
	};
};

export type AcademicYearNameFragment = { __typename?: "AcademicYear"; name: string };

export type AcademicYearNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type AcademicYearNameQuery = {
	__typename?: "Query";
	academicYear: { __typename?: "AcademicYear"; id: string; name: string };
};

export type AcademicYearNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<AcademicYearFilterInput> | AcademicYearFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<AcademicYearOrder>;
}>;

export type AcademicYearNamesQuery = {
	__typename?: "Query";
	academicYearConnection: {
		__typename?: "AcademicYearConnection";
		edges: Array<{ __typename?: "AcademicYearEdge"; node: { __typename?: "AcademicYear"; id: string; name: string } }>;
	};
};

export type AcademicYearPaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<AcademicYearFilterInput> | AcademicYearFilterInput>;
	order?: InputMaybe<AcademicYearOrder>;
}>;

export type AcademicYearPaginationQuery = {
	__typename?: "Query";
	academicYearConnection: {
		__typename?: "AcademicYearConnection";
		totalCount: number;
		edges: Array<{ __typename?: "AcademicYearEdge"; cursor: string }>;
	};
};

export type AcademicYearSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<AcademicYearFilterInput> | AcademicYearFilterInput>;
	order?: InputMaybe<AcademicYearOrder>;
}>;

export type AcademicYearSelectQuery = {
	__typename?: "Query";
	academicYearConnection: {
		__typename?: "AcademicYearConnection";
		edges: Array<{ __typename?: "AcademicYearEdge"; node: { __typename?: "AcademicYear"; id: string; name: string } }>;
	};
};

export type AcademicYearUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: AcademicYearUpdateInput;
}>;

export type AcademicYearUpdateMutation = {
	__typename?: "Mutation";
	updateAcademicYear: {
		__typename?: "AcademicYear";
		id: string;
		name: string;
		billingSetupSubmissions: Array<{ __typename?: "BillingSetupSubmission"; id: string }>;
		schoolStudentEnrollments: Array<{ __typename?: "SchoolStudentEnrollment"; id: string }>;
		terms: Array<{ __typename?: "Term"; id: string; name: string }>;
	};
};

export type AcademicYearTuitionCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<AcademicYearTuitionFilterInput> | AcademicYearTuitionFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<AcademicYearTuitionOrder>;
}>;

export type AcademicYearTuitionCountQuery = {
	__typename?: "Query";
	academicYearTuitionConnection: { __typename?: "AcademicYearTuitionConnection"; totalCount: number };
};

export type AcademicYearTuitionCreateMutationVariables = Exact<{
	input: AcademicYearTuitionCreateInput;
}>;

export type AcademicYearTuitionCreateMutation = {
	__typename?: "Mutation";
	createAcademicYearTuition: {
		__typename?: "AcademicYearTuition";
		id: string;
		year: number;
		nurseryPrice: number;
		receptionPrice: number;
		primaryPrice: number;
		secondaryPrice: number;
		tuition: { __typename?: "Tuition"; id: string; name: string };
	};
};

export type AcademicYearTuitionDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type AcademicYearTuitionDeleteMutation = { __typename?: "Mutation"; deleteAcademicYearTuition: boolean };

export type AcademicYearTuitionDetailFieldsFragment = {
	__typename?: "AcademicYearTuition";
	id: string;
	year: number;
	nurseryPrice: number;
	receptionPrice: number;
	primaryPrice: number;
	secondaryPrice: number;
	tuition: { __typename?: "Tuition"; id: string; name: string };
};

export type AcademicYearTuitionDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type AcademicYearTuitionDetailQuery = {
	__typename?: "Query";
	academicYearTuition: {
		__typename?: "AcademicYearTuition";
		id: string;
		year: number;
		nurseryPrice: number;
		receptionPrice: number;
		primaryPrice: number;
		secondaryPrice: number;
		tuition: { __typename?: "Tuition"; id: string; name: string };
	};
};

export type AcademicYearTuitionIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<AcademicYearTuitionFilterInput> | AcademicYearTuitionFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<AcademicYearTuitionOrder>;
}>;

export type AcademicYearTuitionIndexQuery = {
	__typename?: "Query";
	academicYearTuitionConnection: {
		__typename?: "AcademicYearTuitionConnection";
		edges: Array<{
			__typename?: "AcademicYearTuitionEdge";
			node: {
				__typename?: "AcademicYearTuition";
				id: string;
				year: number;
				nurseryPrice: number;
				receptionPrice: number;
				primaryPrice: number;
				secondaryPrice: number;
			};
		}>;
	};
};

export type AcademicYearTuitionNameFragment = { __typename?: "AcademicYearTuition"; id: string; year: number };

export type AcademicYearTuitionNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type AcademicYearTuitionNameQuery = {
	__typename?: "Query";
	academicYearTuition: { __typename?: "AcademicYearTuition"; id: string; year: number };
};

export type AcademicYearTuitionNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<AcademicYearTuitionFilterInput> | AcademicYearTuitionFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<AcademicYearTuitionOrder>;
}>;

export type AcademicYearTuitionNamesQuery = {
	__typename?: "Query";
	academicYearTuitionConnection: {
		__typename?: "AcademicYearTuitionConnection";
		edges: Array<{
			__typename?: "AcademicYearTuitionEdge";
			node: { __typename?: "AcademicYearTuition"; id: string; year: number };
		}>;
	};
};

export type AcademicYearTuitionPaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<AcademicYearTuitionFilterInput> | AcademicYearTuitionFilterInput>;
	order?: InputMaybe<AcademicYearTuitionOrder>;
}>;

export type AcademicYearTuitionPaginationQuery = {
	__typename?: "Query";
	academicYearTuitionConnection: {
		__typename?: "AcademicYearTuitionConnection";
		totalCount: number;
		edges: Array<{ __typename?: "AcademicYearTuitionEdge"; cursor: string }>;
	};
};

export type AcademicYearTuitionSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<AcademicYearTuitionFilterInput> | AcademicYearTuitionFilterInput>;
	order?: InputMaybe<AcademicYearTuitionOrder>;
}>;

export type AcademicYearTuitionSelectQuery = {
	__typename?: "Query";
	academicYearTuitionConnection: {
		__typename?: "AcademicYearTuitionConnection";
		edges: Array<{ __typename?: "AcademicYearTuitionEdge"; node: { __typename?: "AcademicYearTuition"; id: string } }>;
	};
};

export type AcademicYearTuitionUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: AcademicYearTuitionUpdateInput;
}>;

export type AcademicYearTuitionUpdateMutation = {
	__typename?: "Mutation";
	updateAcademicYearTuition: {
		__typename?: "AcademicYearTuition";
		id: string;
		year: number;
		nurseryPrice: number;
		receptionPrice: number;
		primaryPrice: number;
		secondaryPrice: number;
		tuition: { __typename?: "Tuition"; id: string; name: string };
	};
};

export type AddressCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<AddressFilterInput> | AddressFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<AddressOrder>;
}>;

export type AddressCountQuery = {
	__typename?: "Query";
	addressConnection: { __typename?: "AddressConnection"; totalCount: number };
};

export type AddressCreateMutationVariables = Exact<{
	input: AddressCreateInput;
}>;

export type AddressCreateMutation = {
	__typename?: "Mutation";
	createAddress: {
		__typename?: "Address";
		id: string;
		shortAddress: string;
		fullAddress: string;
		addressLine1: string;
		addressLine2: string;
		city: string;
		postalCode: string;
		stateOrProvince: string;
		country: string;
		people: Array<{ __typename?: "Person"; id: string; name: string }>;
	};
};

export type AddressDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type AddressDeleteMutation = { __typename?: "Mutation"; deleteAddress: boolean };

export type AddressDetailFragment = {
	__typename?: "Address";
	id: string;
	shortAddress: string;
	fullAddress: string;
	addressLine1: string;
	addressLine2: string;
	city: string;
	postalCode: string;
	stateOrProvince: string;
	country: string;
	people: Array<{ __typename?: "Person"; id: string; name: string }>;
};

export type AddressDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type AddressDetailQuery = {
	__typename?: "Query";
	address: {
		__typename?: "Address";
		id: string;
		shortAddress: string;
		fullAddress: string;
		addressLine1: string;
		addressLine2: string;
		city: string;
		postalCode: string;
		stateOrProvince: string;
		country: string;
		people: Array<{ __typename?: "Person"; id: string; name: string }>;
	};
};

export type AddressIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<AddressFilterInput> | AddressFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<AddressOrder>;
}>;

export type AddressIndexQuery = {
	__typename?: "Query";
	addressConnection: {
		__typename?: "AddressConnection";
		edges: Array<{
			__typename?: "AddressEdge";
			node: {
				__typename?: "Address";
				id: string;
				shortAddress: string;
				fullAddress: string;
				addressLine1: string;
				addressLine2: string;
				city: string;
				postalCode: string;
				stateOrProvince: string;
				country: string;
			};
		}>;
	};
};

export type AddressNameFragment = { __typename?: "Address"; shortAddress: string };

export type AddressNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type AddressNameQuery = {
	__typename?: "Query";
	address: { __typename?: "Address"; id: string; shortAddress: string };
};

export type AddressNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<AddressFilterInput> | AddressFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<AddressOrder>;
}>;

export type AddressNamesQuery = {
	__typename?: "Query";
	addressConnection: {
		__typename?: "AddressConnection";
		edges: Array<{ __typename?: "AddressEdge"; node: { __typename?: "Address"; id: string; shortAddress: string } }>;
	};
};

export type AddressPaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<AddressFilterInput> | AddressFilterInput>;
	order?: InputMaybe<AddressOrder>;
}>;

export type AddressPaginationQuery = {
	__typename?: "Query";
	addressConnection: {
		__typename?: "AddressConnection";
		totalCount: number;
		edges: Array<{ __typename?: "AddressEdge"; cursor: string }>;
	};
};

export type AddressSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<AddressFilterInput> | AddressFilterInput>;
	order?: InputMaybe<AddressOrder>;
}>;

export type AddressSelectQuery = {
	__typename?: "Query";
	addressConnection: {
		__typename?: "AddressConnection";
		edges: Array<{ __typename?: "AddressEdge"; node: { __typename?: "Address"; id: string; shortAddress: string } }>;
	};
};

export type StudentAddressListQueryVariables = Exact<{
	studentID: Scalars["ID"];
}>;

export type StudentAddressListQuery = {
	__typename?: "Query";
	addressConnection: {
		__typename?: "AddressConnection";
		edges: Array<{
			__typename?: "AddressEdge";
			node: {
				__typename?: "Address";
				id: string;
				fullAddress: string;
				addressLine1: string;
				addressLine2: string;
				city: string;
				postalCode: string;
				stateOrProvince: string;
				country: string;
			};
		}>;
	};
};

export type AddressUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: AddressUpdateInput;
}>;

export type AddressUpdateMutation = {
	__typename?: "Mutation";
	updateAddress: {
		__typename?: "Address";
		id: string;
		shortAddress: string;
		fullAddress: string;
		addressLine1: string;
		addressLine2: string;
		city: string;
		postalCode: string;
		stateOrProvince: string;
		country: string;
		people: Array<{ __typename?: "Person"; id: string; name: string }>;
	};
};

export type BillingSetupSubmissionCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<BillingSetupSubmissionFilterInput> | BillingSetupSubmissionFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<BillingSetupSubmissionOrder>;
}>;

export type BillingSetupSubmissionCountQuery = {
	__typename?: "Query";
	billingSetupSubmissionConnection: { __typename?: "BillingSetupSubmissionConnection"; totalCount: number };
};

export type BillingSetupSubmissionCreateMutationVariables = Exact<{
	input: BillingSetupSubmissionCreateInput;
}>;

export type BillingSetupSubmissionCreateMutation = {
	__typename?: "Mutation";
	createBillingSetupSubmission: {
		__typename?: "BillingSetupSubmission";
		id: string;
		numPayments: number;
		isInterestedInBusPlan: boolean;
		isInterestedInMealPlan: boolean;
		authorName: string;
		authorEmail: string;
		authorPhone: string;
		academicYear: { __typename?: "AcademicYear"; id: string; name: string };
		student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
	};
};

export type BillingSetupSubmissionDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type BillingSetupSubmissionDeleteMutation = { __typename?: "Mutation"; deleteBillingSetupSubmission: boolean };

export type BillingSetupSubmissionDetailFragment = {
	__typename?: "BillingSetupSubmission";
	id: string;
	numPayments: number;
	isInterestedInBusPlan: boolean;
	isInterestedInMealPlan: boolean;
	authorName: string;
	authorEmail: string;
	authorPhone: string;
	academicYear: { __typename?: "AcademicYear"; id: string; name: string };
	student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
};

export type BillingSetupSubmissionDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type BillingSetupSubmissionDetailQuery = {
	__typename?: "Query";
	billingSetupSubmission: {
		__typename?: "BillingSetupSubmission";
		id: string;
		numPayments: number;
		isInterestedInBusPlan: boolean;
		isInterestedInMealPlan: boolean;
		authorName: string;
		authorEmail: string;
		authorPhone: string;
		academicYear: { __typename?: "AcademicYear"; id: string; name: string };
		student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
	};
};

export type BillingSetupSubmissionIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<BillingSetupSubmissionFilterInput> | BillingSetupSubmissionFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<BillingSetupSubmissionOrder>;
}>;

export type BillingSetupSubmissionIndexQuery = {
	__typename?: "Query";
	billingSetupSubmissionConnection: {
		__typename?: "BillingSetupSubmissionConnection";
		edges: Array<{
			__typename?: "BillingSetupSubmissionEdge";
			node: {
				__typename?: "BillingSetupSubmission";
				id: string;
				numPayments: number;
				isInterestedInBusPlan: boolean;
				isInterestedInMealPlan: boolean;
				authorName: string;
				authorEmail: string;
				authorPhone: string;
			};
		}>;
	};
};

export type BillingSetupSubmissionNameFragment = { __typename?: "BillingSetupSubmission"; id: string };

export type BillingSetupSubmissionNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type BillingSetupSubmissionNameQuery = {
	__typename?: "Query";
	billingSetupSubmission: { __typename?: "BillingSetupSubmission"; id: string };
};

export type BillingSetupSubmissionNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<BillingSetupSubmissionFilterInput> | BillingSetupSubmissionFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<BillingSetupSubmissionOrder>;
}>;

export type BillingSetupSubmissionNamesQuery = {
	__typename?: "Query";
	billingSetupSubmissionConnection: {
		__typename?: "BillingSetupSubmissionConnection";
		edges: Array<{
			__typename?: "BillingSetupSubmissionEdge";
			node: { __typename?: "BillingSetupSubmission"; id: string };
		}>;
	};
};

export type BillingSetupSubmissionPaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<BillingSetupSubmissionFilterInput> | BillingSetupSubmissionFilterInput>;
	order?: InputMaybe<BillingSetupSubmissionOrder>;
}>;

export type BillingSetupSubmissionPaginationQuery = {
	__typename?: "Query";
	billingSetupSubmissionConnection: {
		__typename?: "BillingSetupSubmissionConnection";
		totalCount: number;
		edges: Array<{ __typename?: "BillingSetupSubmissionEdge"; cursor: string }>;
	};
};

export type BillingSetupSubmissionSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<BillingSetupSubmissionFilterInput> | BillingSetupSubmissionFilterInput>;
	order?: InputMaybe<BillingSetupSubmissionOrder>;
}>;

export type BillingSetupSubmissionSelectQuery = {
	__typename?: "Query";
	billingSetupSubmissionConnection: {
		__typename?: "BillingSetupSubmissionConnection";
		edges: Array<{
			__typename?: "BillingSetupSubmissionEdge";
			node: { __typename?: "BillingSetupSubmission"; id: string };
		}>;
	};
};

export type BillingSetupSubmissionUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: BillingSetupSubmissionUpdateInput;
}>;

export type BillingSetupSubmissionUpdateMutation = {
	__typename?: "Mutation";
	updateBillingSetupSubmission: {
		__typename?: "BillingSetupSubmission";
		id: string;
		numPayments: number;
		isInterestedInBusPlan: boolean;
		isInterestedInMealPlan: boolean;
		authorName: string;
		authorEmail: string;
		authorPhone: string;
		academicYear: { __typename?: "AcademicYear"; id: string; name: string };
		student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
	};
};

export type BusCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<BusFilterInput> | BusFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<BusOrder>;
}>;

export type BusCountQuery = {
	__typename?: "Query";
	busConnection: { __typename?: "BusConnection"; totalCount: number };
};

export type BusCreateMutationVariables = Exact<{
	input: BusCreateInput;
}>;

export type BusCreateMutation = {
	__typename?: "Mutation";
	createBus: {
		__typename?: "Bus";
		id: string;
		name: string;
		description: string;
		dropoffBusPlans: Array<{
			__typename?: "BusPlan";
			id: string;
			student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
		}>;
		pickupBusPlans: Array<{
			__typename?: "BusPlan";
			id: string;
			student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
		}>;
	};
};

export type BusDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type BusDeleteMutation = { __typename?: "Mutation"; deleteBus: boolean };

export type BusDetailFragment = {
	__typename?: "Bus";
	id: string;
	name: string;
	description: string;
	dropoffBusPlans: Array<{
		__typename?: "BusPlan";
		id: string;
		student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
	}>;
	pickupBusPlans: Array<{
		__typename?: "BusPlan";
		id: string;
		student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
	}>;
};

export type BusDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type BusDetailQuery = {
	__typename?: "Query";
	bus: {
		__typename?: "Bus";
		id: string;
		name: string;
		description: string;
		dropoffBusPlans: Array<{
			__typename?: "BusPlan";
			id: string;
			student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
		}>;
		pickupBusPlans: Array<{
			__typename?: "BusPlan";
			id: string;
			student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
		}>;
	};
};

export type BusIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<BusFilterInput> | BusFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<BusOrder>;
}>;

export type BusIndexQuery = {
	__typename?: "Query";
	busConnection: {
		__typename?: "BusConnection";
		edges: Array<{
			__typename?: "BusEdge";
			node: { __typename?: "Bus"; id: string; name: string; description: string };
		}>;
	};
};

export type BusNameFragment = { __typename?: "Bus"; name: string };

export type BusNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type BusNameQuery = { __typename?: "Query"; bus: { __typename?: "Bus"; id: string; name: string } };

export type BusNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<BusFilterInput> | BusFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<BusOrder>;
}>;

export type BusNamesQuery = {
	__typename?: "Query";
	busConnection: {
		__typename?: "BusConnection";
		edges: Array<{ __typename?: "BusEdge"; node: { __typename?: "Bus"; id: string; name: string } }>;
	};
};

export type BusPaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<BusFilterInput> | BusFilterInput>;
	order?: InputMaybe<BusOrder>;
}>;

export type BusPaginationQuery = {
	__typename?: "Query";
	busConnection: {
		__typename?: "BusConnection";
		totalCount: number;
		edges: Array<{ __typename?: "BusEdge"; cursor: string }>;
	};
};

export type BusSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<BusFilterInput> | BusFilterInput>;
	order?: InputMaybe<BusOrder>;
}>;

export type BusSelectQuery = {
	__typename?: "Query";
	busConnection: {
		__typename?: "BusConnection";
		edges: Array<{ __typename?: "BusEdge"; node: { __typename?: "Bus"; id: string; name: string } }>;
	};
};

export type BusUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: BusUpdateInput;
}>;

export type BusUpdateMutation = {
	__typename?: "Mutation";
	updateBus: {
		__typename?: "Bus";
		id: string;
		name: string;
		description: string;
		dropoffBusPlans: Array<{
			__typename?: "BusPlan";
			id: string;
			student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
		}>;
		pickupBusPlans: Array<{
			__typename?: "BusPlan";
			id: string;
			student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
		}>;
	};
};

export type BusPlanPickupBusListQueryVariables = Exact<{ [key: string]: never }>;

export type BusPlanPickupBusListQuery = {
	__typename?: "Query";
	busPlanConnection: {
		__typename?: "BusPlanConnection";
		edges: Array<{
			__typename?: "BusPlanEdge";
			node: {
				__typename?: "BusPlan";
				id: string;
				pickupTimeMinutes: number | null;
				pickupLocation: string;
				student: {
					__typename?: "Student";
					id: string;
					person: { __typename?: "Person"; id: string; name: string };
					homeRoomSectionStudentEnrollments: Array<{
						__typename?: "HomeRoomSectionStudentEnrollment";
						id: string;
						homeRoomSection: { __typename?: "HomeRoomSection"; id: string; name: string };
					}>;
				};
				pickupBus: { __typename?: "Bus"; id: string; name: string } | null;
			};
		}>;
	};
};

export type BusPlanDropoffBusListQueryVariables = Exact<{ [key: string]: never }>;

export type BusPlanDropoffBusListQuery = {
	__typename?: "Query";
	busPlanConnection: {
		__typename?: "BusPlanConnection";
		edges: Array<{
			__typename?: "BusPlanEdge";
			node: {
				__typename?: "BusPlan";
				id: string;
				dropoffTimeMinutes: number | null;
				dropoffLocation: string;
				student: {
					__typename?: "Student";
					id: string;
					person: { __typename?: "Person"; id: string; name: string };
					homeRoomSectionStudentEnrollments: Array<{
						__typename?: "HomeRoomSectionStudentEnrollment";
						id: string;
						homeRoomSection: { __typename?: "HomeRoomSection"; id: string; name: string };
					}>;
				};
				dropoffBus: { __typename?: "Bus"; id: string; name: string } | null;
			};
		}>;
	};
};

export type BusPlanCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<BusPlanFilterInput> | BusPlanFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<BusPlanOrder>;
}>;

export type BusPlanCountQuery = {
	__typename?: "Query";
	busPlanConnection: { __typename?: "BusPlanConnection"; totalCount: number };
};

export type BusPlanCreateMutationVariables = Exact<{
	input: BusPlanCreateInput;
}>;

export type BusPlanCreateMutation = {
	__typename?: "Mutation";
	createBusPlan: {
		__typename?: "BusPlan";
		id: string;
		hasPickup: boolean;
		pickupTimeMinutes: number | null;
		pickupLocation: string;
		hasDropoff: boolean;
		dropoffTimeMinutes: number | null;
		dropoffLocation: string;
		dropoffBus: { __typename?: "Bus"; id: string; name: string } | null;
		pickupBus: { __typename?: "Bus"; id: string; name: string } | null;
		student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
	};
};

export type BusPlanDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type BusPlanDeleteMutation = { __typename?: "Mutation"; deleteBusPlan: boolean };

export type BusPlanDetailFragment = {
	__typename?: "BusPlan";
	id: string;
	hasPickup: boolean;
	pickupTimeMinutes: number | null;
	pickupLocation: string;
	hasDropoff: boolean;
	dropoffTimeMinutes: number | null;
	dropoffLocation: string;
	dropoffBus: { __typename?: "Bus"; id: string; name: string } | null;
	pickupBus: { __typename?: "Bus"; id: string; name: string } | null;
	student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
};

export type BusPlanDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type BusPlanDetailQuery = {
	__typename?: "Query";
	busPlan: {
		__typename?: "BusPlan";
		id: string;
		hasPickup: boolean;
		pickupTimeMinutes: number | null;
		pickupLocation: string;
		hasDropoff: boolean;
		dropoffTimeMinutes: number | null;
		dropoffLocation: string;
		dropoffBus: { __typename?: "Bus"; id: string; name: string } | null;
		pickupBus: { __typename?: "Bus"; id: string; name: string } | null;
		student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
	};
};

export type BusPlanIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<BusPlanFilterInput> | BusPlanFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<BusPlanOrder>;
}>;

export type BusPlanIndexQuery = {
	__typename?: "Query";
	busPlanConnection: {
		__typename?: "BusPlanConnection";
		edges: Array<{
			__typename?: "BusPlanEdge";
			node: {
				__typename?: "BusPlan";
				id: string;
				hasPickup: boolean;
				pickupTimeMinutes: number | null;
				pickupLocation: string;
				hasDropoff: boolean;
				dropoffTimeMinutes: number | null;
				dropoffLocation: string;
				student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
				pickupBus: { __typename?: "Bus"; id: string; name: string } | null;
				dropoffBus: { __typename?: "Bus"; id: string; name: string } | null;
			};
		}>;
	};
};

export type BusPlanNameFragment = {
	__typename?: "BusPlan";
	student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
};

export type BusPlanNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type BusPlanNameQuery = {
	__typename?: "Query";
	busPlan: {
		__typename?: "BusPlan";
		id: string;
		student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
	};
};

export type BusPlanNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<BusPlanFilterInput> | BusPlanFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<BusPlanOrder>;
}>;

export type BusPlanNamesQuery = {
	__typename?: "Query";
	busPlanConnection: {
		__typename?: "BusPlanConnection";
		edges: Array<{
			__typename?: "BusPlanEdge";
			node: {
				__typename?: "BusPlan";
				id: string;
				student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
			};
		}>;
	};
};

export type BusPlanPaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<BusPlanFilterInput> | BusPlanFilterInput>;
	order?: InputMaybe<BusPlanOrder>;
}>;

export type BusPlanPaginationQuery = {
	__typename?: "Query";
	busPlanConnection: {
		__typename?: "BusPlanConnection";
		totalCount: number;
		edges: Array<{ __typename?: "BusPlanEdge"; cursor: string }>;
	};
};

export type BusPlanSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<BusPlanFilterInput> | BusPlanFilterInput>;
	order?: InputMaybe<BusPlanOrder>;
}>;

export type BusPlanSelectQuery = {
	__typename?: "Query";
	busPlanConnection: {
		__typename?: "BusPlanConnection";
		edges: Array<{
			__typename?: "BusPlanEdge";
			node: {
				__typename?: "BusPlan";
				id: string;
				student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
			};
		}>;
	};
};

export type BusPlanUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: BusPlanUpdateInput;
}>;

export type BusPlanUpdateMutation = {
	__typename?: "Mutation";
	updateBusPlan: {
		__typename?: "BusPlan";
		id: string;
		hasPickup: boolean;
		pickupTimeMinutes: number | null;
		pickupLocation: string;
		hasDropoff: boolean;
		dropoffTimeMinutes: number | null;
		dropoffLocation: string;
		dropoffBus: { __typename?: "Bus"; id: string; name: string } | null;
		pickupBus: { __typename?: "Bus"; id: string; name: string } | null;
		student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
	};
};

export type CalendarEventCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<CalendarEventFilterInput> | CalendarEventFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<CalendarEventOrder>;
}>;

export type CalendarEventCountQuery = {
	__typename?: "Query";
	calendarEventConnection: { __typename?: "CalendarEventConnection"; totalCount: number };
};

export type CalendarEventCreateMutationVariables = Exact<{
	input: CalendarEventCreateInput;
}>;

export type CalendarEventCreateMutation = {
	__typename?: "Mutation";
	createCalendarEvent: {
		__typename?: "CalendarEvent";
		id: string;
		name: string;
		description: string;
		isAllDay: boolean;
		startTime: string;
		endTime: string;
		isPublic: boolean;
		createdAt: string;
		author: {
			__typename?: "User";
			id: string;
			email: string;
			person: { __typename?: "Person"; id: string; name: string } | null;
		} | null;
		sharingUserGroups: Array<{ __typename?: "UserGroup"; id: string; name: string }>;
	};
};

export type CalendarEventDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type CalendarEventDeleteMutation = { __typename?: "Mutation"; deleteCalendarEvent: boolean };

export type CalendarEventDetailFragment = {
	__typename?: "CalendarEvent";
	id: string;
	name: string;
	description: string;
	isAllDay: boolean;
	startTime: string;
	endTime: string;
	isPublic: boolean;
	createdAt: string;
	author: {
		__typename?: "User";
		id: string;
		email: string;
		person: { __typename?: "Person"; id: string; name: string } | null;
	} | null;
	sharingUserGroups: Array<{ __typename?: "UserGroup"; id: string; name: string }>;
};

export type CalendarEventDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type CalendarEventDetailQuery = {
	__typename?: "Query";
	calendarEvent: {
		__typename?: "CalendarEvent";
		id: string;
		name: string;
		description: string;
		isAllDay: boolean;
		startTime: string;
		endTime: string;
		isPublic: boolean;
		createdAt: string;
		author: {
			__typename?: "User";
			id: string;
			email: string;
			person: { __typename?: "Person"; id: string; name: string } | null;
		} | null;
		sharingUserGroups: Array<{ __typename?: "UserGroup"; id: string; name: string }>;
	};
};

export type CalendarEventIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<CalendarEventFilterInput> | CalendarEventFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<CalendarEventOrder>;
}>;

export type CalendarEventIndexQuery = {
	__typename?: "Query";
	calendarEventConnection: {
		__typename?: "CalendarEventConnection";
		edges: Array<{
			__typename?: "CalendarEventEdge";
			node: {
				__typename?: "CalendarEvent";
				id: string;
				name: string;
				description: string;
				isAllDay: boolean;
				startTime: string;
				endTime: string;
				isPublic: boolean;
				createdAt: string;
				sharingUserGroups: Array<{ __typename?: "UserGroup"; id: string; name: string; color: string }>;
			};
		}>;
	};
};

export type CalendarEventNameFragment = { __typename?: "CalendarEvent"; name: string };

export type CalendarEventNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type CalendarEventNameQuery = {
	__typename?: "Query";
	calendarEvent: { __typename?: "CalendarEvent"; id: string; name: string };
};

export type CalendarEventNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<CalendarEventFilterInput> | CalendarEventFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<CalendarEventOrder>;
}>;

export type CalendarEventNamesQuery = {
	__typename?: "Query";
	calendarEventConnection: {
		__typename?: "CalendarEventConnection";
		edges: Array<{
			__typename?: "CalendarEventEdge";
			node: { __typename?: "CalendarEvent"; id: string; name: string };
		}>;
	};
};

export type CalendarEventPaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<CalendarEventFilterInput> | CalendarEventFilterInput>;
	order?: InputMaybe<CalendarEventOrder>;
}>;

export type CalendarEventPaginationQuery = {
	__typename?: "Query";
	calendarEventConnection: {
		__typename?: "CalendarEventConnection";
		totalCount: number;
		edges: Array<{ __typename?: "CalendarEventEdge"; cursor: string }>;
	};
};

export type CalendarEventSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<CalendarEventFilterInput> | CalendarEventFilterInput>;
	order?: InputMaybe<CalendarEventOrder>;
}>;

export type CalendarEventSelectQuery = {
	__typename?: "Query";
	calendarEventConnection: {
		__typename?: "CalendarEventConnection";
		edges: Array<{
			__typename?: "CalendarEventEdge";
			node: { __typename?: "CalendarEvent"; id: string; name: string };
		}>;
	};
};

export type CalendarEventUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: CalendarEventUpdateInput;
}>;

export type CalendarEventUpdateMutation = {
	__typename?: "Mutation";
	updateCalendarEvent: {
		__typename?: "CalendarEvent";
		id: string;
		name: string;
		description: string;
		isAllDay: boolean;
		startTime: string;
		endTime: string;
		isPublic: boolean;
		createdAt: string;
		author: {
			__typename?: "User";
			id: string;
			email: string;
			person: { __typename?: "Person"; id: string; name: string } | null;
		} | null;
		sharingUserGroups: Array<{ __typename?: "UserGroup"; id: string; name: string }>;
	};
};

export type CampusCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<CampusFilterInput> | CampusFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<CampusOrder>;
}>;

export type CampusCountQuery = {
	__typename?: "Query";
	campusConnection: { __typename?: "CampusConnection"; totalCount: number };
};

export type CampusCreateMutationVariables = Exact<{
	input: CampusCreateInput;
}>;

export type CampusCreateMutation = {
	__typename?: "Mutation";
	createCampus: {
		__typename?: "Campus";
		id: string;
		name: string;
		addressLine1: string;
		addressLine2: string;
		postalCode: string;
		city: string;
		stateOrProvince: string;
		country: string;
		rooms: Array<{ __typename?: "Room"; id: string; name: string }>;
		employees: Array<{
			__typename?: "Employee";
			id: string;
			person: { __typename?: "Person"; id: string; name: string; imageURL: string };
		}>;
	};
};

export type CampusDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type CampusDeleteMutation = { __typename?: "Mutation"; deleteCampus: boolean };

export type CampusDetailFragment = {
	__typename?: "Campus";
	id: string;
	name: string;
	addressLine1: string;
	addressLine2: string;
	postalCode: string;
	city: string;
	stateOrProvince: string;
	country: string;
	rooms: Array<{ __typename?: "Room"; id: string; name: string }>;
	employees: Array<{
		__typename?: "Employee";
		id: string;
		person: { __typename?: "Person"; id: string; name: string; imageURL: string };
	}>;
};

export type CampusDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type CampusDetailQuery = {
	__typename?: "Query";
	campus: {
		__typename?: "Campus";
		id: string;
		name: string;
		addressLine1: string;
		addressLine2: string;
		postalCode: string;
		city: string;
		stateOrProvince: string;
		country: string;
		rooms: Array<{ __typename?: "Room"; id: string; name: string }>;
		employees: Array<{
			__typename?: "Employee";
			id: string;
			person: { __typename?: "Person"; id: string; name: string; imageURL: string };
		}>;
	};
};

export type CampusIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<CampusFilterInput> | CampusFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<CampusOrder>;
}>;

export type CampusIndexQuery = {
	__typename?: "Query";
	campusConnection: {
		__typename?: "CampusConnection";
		edges: Array<{
			__typename?: "CampusEdge";
			node: {
				__typename?: "Campus";
				id: string;
				name: string;
				addressLine1: string;
				addressLine2: string;
				postalCode: string;
				city: string;
				stateOrProvince: string;
				country: string;
			};
		}>;
	};
};

export type CampusNameFragment = { __typename?: "Campus"; name: string };

export type CampusNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type CampusNameQuery = { __typename?: "Query"; campus: { __typename?: "Campus"; id: string; name: string } };

export type CampusNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<CampusFilterInput> | CampusFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<CampusOrder>;
}>;

export type CampusNamesQuery = {
	__typename?: "Query";
	campusConnection: {
		__typename?: "CampusConnection";
		edges: Array<{ __typename?: "CampusEdge"; node: { __typename?: "Campus"; id: string; name: string } }>;
	};
};

export type CampusObjectCountsQueryVariables = Exact<{
	studentFilters?: InputMaybe<Array<StudentFilterInput> | StudentFilterInput>;
	homeRoomSectionFilters?: InputMaybe<Array<HomeRoomSectionFilterInput> | HomeRoomSectionFilterInput>;
	employeeFilters?: InputMaybe<Array<EmployeeFilterInput> | EmployeeFilterInput>;
}>;

export type CampusObjectCountsQuery = {
	__typename?: "Query";
	studentConnection: { __typename?: "StudentConnection"; totalCount: number };
	homeRoomSectionConnection: { __typename?: "HomeRoomSectionConnection"; totalCount: number };
	employeeConnection: { __typename?: "EmployeeConnection"; totalCount: number };
};

export type CampusPaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<CampusFilterInput> | CampusFilterInput>;
	order?: InputMaybe<CampusOrder>;
}>;

export type CampusPaginationQuery = {
	__typename?: "Query";
	campusConnection: {
		__typename?: "CampusConnection";
		totalCount: number;
		edges: Array<{ __typename?: "CampusEdge"; cursor: string }>;
	};
};

export type CampusSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<CampusFilterInput> | CampusFilterInput>;
	order?: InputMaybe<CampusOrder>;
}>;

export type CampusSelectQuery = {
	__typename?: "Query";
	campusConnection: {
		__typename?: "CampusConnection";
		edges: Array<{ __typename?: "CampusEdge"; node: { __typename?: "Campus"; id: string; name: string } }>;
	};
};

export type CampusUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: CampusUpdateInput;
}>;

export type CampusUpdateMutation = {
	__typename?: "Mutation";
	updateCampus: {
		__typename?: "Campus";
		id: string;
		name: string;
		addressLine1: string;
		addressLine2: string;
		postalCode: string;
		city: string;
		stateOrProvince: string;
		country: string;
		rooms: Array<{ __typename?: "Room"; id: string; name: string }>;
		employees: Array<{
			__typename?: "Employee";
			id: string;
			person: { __typename?: "Person"; id: string; name: string; imageURL: string };
		}>;
	};
};

export type ClassYearCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<ClassYearFilterInput> | ClassYearFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<ClassYearOrder>;
}>;

export type ClassYearCountQuery = {
	__typename?: "Query";
	classYearConnection: { __typename?: "ClassYearConnection"; totalCount: number };
};

export type ClassYearCreateMutationVariables = Exact<{
	input: ClassYearCreateInput;
}>;

export type ClassYearCreateMutation = {
	__typename?: "Mutation";
	createClassYear: {
		__typename?: "ClassYear";
		id: string;
		name: string;
		yearsFromGraduation: number;
		courses: Array<{
			__typename?: "Course";
			id: string;
			name: string;
			subject: CourseSubject;
			homeRoom: { __typename?: "HomeRoom"; id: string; name: string };
		}>;
		homeRooms: Array<{ __typename?: "HomeRoom"; id: string; name: string }>;
	};
};

export type ClassYearDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type ClassYearDeleteMutation = { __typename?: "Mutation"; deleteClassYear: boolean };

export type ClassYearDetailFragment = {
	__typename?: "ClassYear";
	id: string;
	name: string;
	yearsFromGraduation: number;
	courses: Array<{
		__typename?: "Course";
		id: string;
		name: string;
		subject: CourseSubject;
		homeRoom: { __typename?: "HomeRoom"; id: string; name: string };
	}>;
	homeRooms: Array<{ __typename?: "HomeRoom"; id: string; name: string }>;
};

export type ClassYearDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type ClassYearDetailQuery = {
	__typename?: "Query";
	classYear: {
		__typename?: "ClassYear";
		id: string;
		name: string;
		yearsFromGraduation: number;
		courses: Array<{
			__typename?: "Course";
			id: string;
			name: string;
			subject: CourseSubject;
			homeRoom: { __typename?: "HomeRoom"; id: string; name: string };
		}>;
		homeRooms: Array<{ __typename?: "HomeRoom"; id: string; name: string }>;
	};
};

export type ClassYearIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<ClassYearFilterInput> | ClassYearFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<ClassYearOrder>;
}>;

export type ClassYearIndexQuery = {
	__typename?: "Query";
	classYearConnection: {
		__typename?: "ClassYearConnection";
		edges: Array<{
			__typename?: "ClassYearEdge";
			node: { __typename?: "ClassYear"; id: string; name: string; yearsFromGraduation: number };
		}>;
	};
};

export type ClassYearNameFragment = { __typename?: "ClassYear"; name: string };

export type ClassYearNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type ClassYearNameQuery = {
	__typename?: "Query";
	classYear: { __typename?: "ClassYear"; id: string; name: string };
};

export type ClassYearNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<ClassYearFilterInput> | ClassYearFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<ClassYearOrder>;
}>;

export type ClassYearNamesQuery = {
	__typename?: "Query";
	classYearConnection: {
		__typename?: "ClassYearConnection";
		edges: Array<{ __typename?: "ClassYearEdge"; node: { __typename?: "ClassYear"; id: string; name: string } }>;
	};
};

export type ClassYearPaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<ClassYearFilterInput> | ClassYearFilterInput>;
	order?: InputMaybe<ClassYearOrder>;
}>;

export type ClassYearPaginationQuery = {
	__typename?: "Query";
	classYearConnection: {
		__typename?: "ClassYearConnection";
		totalCount: number;
		edges: Array<{ __typename?: "ClassYearEdge"; cursor: string }>;
	};
};

export type ClassYearSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<ClassYearFilterInput> | ClassYearFilterInput>;
	order?: InputMaybe<ClassYearOrder>;
}>;

export type ClassYearSelectQuery = {
	__typename?: "Query";
	classYearConnection: {
		__typename?: "ClassYearConnection";
		edges: Array<{ __typename?: "ClassYearEdge"; node: { __typename?: "ClassYear"; id: string; name: string } }>;
	};
};

export type ClassYearUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: ClassYearUpdateInput;
}>;

export type ClassYearUpdateMutation = {
	__typename?: "Mutation";
	updateClassYear: {
		__typename?: "ClassYear";
		id: string;
		name: string;
		yearsFromGraduation: number;
		courses: Array<{
			__typename?: "Course";
			id: string;
			name: string;
			subject: CourseSubject;
			homeRoom: { __typename?: "HomeRoom"; id: string; name: string };
		}>;
		homeRooms: Array<{ __typename?: "HomeRoom"; id: string; name: string }>;
	};
};

export type ContractCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<ContractFilterInput> | ContractFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<ContractOrder>;
}>;

export type ContractCountQuery = {
	__typename?: "Query";
	contractConnection: { __typename?: "ContractConnection"; totalCount: number };
};

export type ContractCreateMutationVariables = Exact<{
	input: ContractCreateInput;
}>;

export type ContractCreateMutation = {
	__typename?: "Mutation";
	createContract: {
		__typename?: "Contract";
		id: string;
		agreementDate: string;
		status: ContractStatus;
		contractInstallments: Array<{ __typename?: "ContractInstallment"; id: string }>;
		serviceSubscriptions: Array<{ __typename?: "ServiceSubscription"; id: string }>;
		student: {
			__typename?: "Student";
			id: string;
			person: { __typename?: "Person"; id: string; name: string; imageURL: string };
		};
	};
};

export type ContractDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type ContractDeleteMutation = { __typename?: "Mutation"; deleteContract: boolean };

export type ContractDetailFragment = {
	__typename?: "Contract";
	id: string;
	agreementDate: string;
	status: ContractStatus;
	contractInstallments: Array<{ __typename?: "ContractInstallment"; id: string }>;
	serviceSubscriptions: Array<{ __typename?: "ServiceSubscription"; id: string }>;
	student: {
		__typename?: "Student";
		id: string;
		person: { __typename?: "Person"; id: string; name: string; imageURL: string };
	};
};

export type ContractDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type ContractDetailQuery = {
	__typename?: "Query";
	contract: {
		__typename?: "Contract";
		id: string;
		agreementDate: string;
		status: ContractStatus;
		contractInstallments: Array<{ __typename?: "ContractInstallment"; id: string }>;
		serviceSubscriptions: Array<{ __typename?: "ServiceSubscription"; id: string }>;
		student: {
			__typename?: "Student";
			id: string;
			person: { __typename?: "Person"; id: string; name: string; imageURL: string };
		};
	};
};

export type ContractIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<ContractFilterInput> | ContractFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<ContractOrder>;
}>;

export type ContractIndexQuery = {
	__typename?: "Query";
	contractConnection: {
		__typename?: "ContractConnection";
		edges: Array<{
			__typename?: "ContractEdge";
			node: {
				__typename?: "Contract";
				id: string;
				agreementDate: string;
				status: ContractStatus;
				student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
			};
		}>;
	};
};

export type ContractListCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<ContractFilterInput> | ContractFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<ContractOrder>;
}>;

export type ContractListCountQuery = {
	__typename?: "Query";
	contractConnection: { __typename?: "ContractConnection"; totalCount: number };
};

export type ContractNameFragment = { __typename?: "Contract"; agreementDate: string; status: ContractStatus };

export type ContractNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type ContractNameQuery = {
	__typename?: "Query";
	contract: { __typename?: "Contract"; id: string; agreementDate: string; status: ContractStatus };
};

export type ContractNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<ContractFilterInput> | ContractFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<ContractOrder>;
}>;

export type ContractNamesQuery = {
	__typename?: "Query";
	contractConnection: {
		__typename?: "ContractConnection";
		edges: Array<{
			__typename?: "ContractEdge";
			node: { __typename?: "Contract"; id: string; agreementDate: string; status: ContractStatus };
		}>;
	};
};

export type ContractPaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<ContractFilterInput> | ContractFilterInput>;
	order?: InputMaybe<ContractOrder>;
}>;

export type ContractPaginationQuery = {
	__typename?: "Query";
	contractConnection: {
		__typename?: "ContractConnection";
		totalCount: number;
		edges: Array<{ __typename?: "ContractEdge"; cursor: string }>;
	};
};

export type ContractPaymentScheduleListQueryVariables = Exact<{
	filters?: InputMaybe<Array<ContractFilterInput> | ContractFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<ContractOrder>;
}>;

export type ContractPaymentScheduleListQuery = {
	__typename?: "Query";
	contractConnection: {
		__typename?: "ContractConnection";
		edges: Array<{
			__typename?: "ContractEdge";
			node: {
				__typename?: "Contract";
				id: string;
				status: ContractStatus;
				serviceSubscriptions: Array<{
					__typename?: "ServiceSubscription";
					id: string;
					price: number;
					discounts: Array<{ __typename?: "Discount"; id: string; amount: number }>;
				}>;
				contractInstallments: Array<{
					__typename?: "ContractInstallment";
					id: string;
					installmentDate: string;
					portion: number;
				}>;
			};
		}>;
	};
};

export type ContractSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<ContractFilterInput> | ContractFilterInput>;
	order?: InputMaybe<ContractOrder>;
}>;

export type ContractSelectQuery = {
	__typename?: "Query";
	contractConnection: {
		__typename?: "ContractConnection";
		edges: Array<{
			__typename?: "ContractEdge";
			node: { __typename?: "Contract"; id: string; agreementDate: string; status: ContractStatus };
		}>;
	};
};

export type StudentContractsServiceSubscriptionsListQueryVariables = Exact<{
	filters?: InputMaybe<Array<ContractFilterInput> | ContractFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<ContractOrder>;
}>;

export type StudentContractsServiceSubscriptionsListQuery = {
	__typename?: "Query";
	contractConnection: {
		__typename?: "ContractConnection";
		edges: Array<{
			__typename?: "ContractEdge";
			node: {
				__typename?: "Contract";
				id: string;
				agreementDate: string;
				status: ContractStatus;
				serviceSubscriptions: Array<{
					__typename?: "ServiceSubscription";
					id: string;
					price: number;
					service: { __typename?: "Service"; id: string; name: string };
					discounts: Array<{ __typename?: "Discount"; id: string; description: string; amount: number }>;
				}>;
			};
		}>;
	};
};

export type StudentContractsPaymentProgressListQueryVariables = Exact<{
	filters?: InputMaybe<Array<ContractFilterInput> | ContractFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<ContractOrder>;
}>;

export type StudentContractsPaymentProgressListQuery = {
	__typename?: "Query";
	contractConnection: {
		__typename?: "ContractConnection";
		edges: Array<{
			__typename?: "ContractEdge";
			node: {
				__typename?: "Contract";
				id: string;
				agreementDate: string;
				status: ContractStatus;
				serviceSubscriptions: Array<{
					__typename?: "ServiceSubscription";
					id: string;
					price: number;
					service: { __typename?: "Service"; id: string; name: string };
					discounts: Array<{ __typename?: "Discount"; id: string; description: string; amount: number }>;
				}>;
				contractInstallments: Array<{
					__typename?: "ContractInstallment";
					id: string;
					installmentDate: string;
					portion: number;
					invoice: { __typename?: "Invoice"; id: string; invoiceNumber: string; dueDate: string } | null;
				}>;
			};
		}>;
	};
};

export type StudentContractDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type StudentContractDetailQuery = {
	__typename?: "Query";
	contract: {
		__typename?: "Contract";
		id: string;
		agreementDate: string;
		status: ContractStatus;
		serviceSubscriptions: Array<{
			__typename?: "ServiceSubscription";
			id: string;
			price: number;
			service: { __typename?: "Service"; id: string; name: string };
			discounts: Array<{ __typename?: "Discount"; id: string; description: string; amount: number }>;
		}>;
		contractInstallments: Array<{
			__typename?: "ContractInstallment";
			id: string;
			installmentDate: string;
			portion: number;
			invoice: { __typename?: "Invoice"; id: string; invoiceNumber: string; dueDate: string } | null;
		}>;
	};
};

export type ContractUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: ContractUpdateInput;
}>;

export type ContractUpdateMutation = {
	__typename?: "Mutation";
	updateContract: {
		__typename?: "Contract";
		id: string;
		agreementDate: string;
		status: ContractStatus;
		contractInstallments: Array<{ __typename?: "ContractInstallment"; id: string }>;
		serviceSubscriptions: Array<{ __typename?: "ServiceSubscription"; id: string }>;
		student: {
			__typename?: "Student";
			id: string;
			person: { __typename?: "Person"; id: string; name: string; imageURL: string };
		};
	};
};

export type ContractInstallmentCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<ContractInstallmentFilterInput> | ContractInstallmentFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<ContractInstallmentOrder>;
}>;

export type ContractInstallmentCountQuery = {
	__typename?: "Query";
	contractInstallmentConnection: { __typename?: "ContractInstallmentConnection"; totalCount: number };
};

export type ContractInstallmentCreateMutationVariables = Exact<{
	input: ContractInstallmentCreateInput;
}>;

export type ContractInstallmentCreateMutation = {
	__typename?: "Mutation";
	createContractInstallment: {
		__typename?: "ContractInstallment";
		id: string;
		installmentDate: string;
		portion: number;
		contract: { __typename?: "Contract"; id: string; agreementDate: string; status: ContractStatus };
		invoice: { __typename?: "Invoice"; id: string; invoiceNumber: string; dueDate: string } | null;
	};
};

export type ContractInstallmentDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type ContractInstallmentDeleteMutation = { __typename?: "Mutation"; deleteContractInstallment: boolean };

export type ContractInstallmentDetailFragment = {
	__typename?: "ContractInstallment";
	id: string;
	installmentDate: string;
	portion: number;
	contract: { __typename?: "Contract"; id: string; agreementDate: string; status: ContractStatus };
	invoice: { __typename?: "Invoice"; id: string; invoiceNumber: string; dueDate: string } | null;
};

export type ContractInstallmentDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type ContractInstallmentDetailQuery = {
	__typename?: "Query";
	contractInstallment: {
		__typename?: "ContractInstallment";
		id: string;
		installmentDate: string;
		portion: number;
		contract: { __typename?: "Contract"; id: string; agreementDate: string; status: ContractStatus };
		invoice: { __typename?: "Invoice"; id: string; invoiceNumber: string; dueDate: string } | null;
	};
};

export type ContractInstallmentIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<ContractInstallmentFilterInput> | ContractInstallmentFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<ContractInstallmentOrder>;
}>;

export type ContractInstallmentIndexQuery = {
	__typename?: "Query";
	contractInstallmentConnection: {
		__typename?: "ContractInstallmentConnection";
		edges: Array<{
			__typename?: "ContractInstallmentEdge";
			node: { __typename?: "ContractInstallment"; id: string; installmentDate: string; portion: number };
		}>;
	};
};

export type ContractInstallmentNameFragment = { __typename?: "ContractInstallment"; id: string };

export type ContractInstallmentNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type ContractInstallmentNameQuery = {
	__typename?: "Query";
	contractInstallment: { __typename?: "ContractInstallment"; id: string };
};

export type ContractInstallmentNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<ContractInstallmentFilterInput> | ContractInstallmentFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<ContractInstallmentOrder>;
}>;

export type ContractInstallmentNamesQuery = {
	__typename?: "Query";
	contractInstallmentConnection: {
		__typename?: "ContractInstallmentConnection";
		edges: Array<{ __typename?: "ContractInstallmentEdge"; node: { __typename?: "ContractInstallment"; id: string } }>;
	};
};

export type ContractInstallmentPaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<ContractInstallmentFilterInput> | ContractInstallmentFilterInput>;
	order?: InputMaybe<ContractInstallmentOrder>;
}>;

export type ContractInstallmentPaginationQuery = {
	__typename?: "Query";
	contractInstallmentConnection: {
		__typename?: "ContractInstallmentConnection";
		totalCount: number;
		edges: Array<{ __typename?: "ContractInstallmentEdge"; cursor: string }>;
	};
};

export type ContractInstallmentSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<ContractInstallmentFilterInput> | ContractInstallmentFilterInput>;
	order?: InputMaybe<ContractInstallmentOrder>;
}>;

export type ContractInstallmentSelectQuery = {
	__typename?: "Query";
	contractInstallmentConnection: {
		__typename?: "ContractInstallmentConnection";
		edges: Array<{ __typename?: "ContractInstallmentEdge"; node: { __typename?: "ContractInstallment"; id: string } }>;
	};
};

export type ContractInstallmentUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: ContractInstallmentUpdateInput;
}>;

export type ContractInstallmentUpdateMutation = {
	__typename?: "Mutation";
	updateContractInstallment: {
		__typename?: "ContractInstallment";
		id: string;
		installmentDate: string;
		portion: number;
		contract: { __typename?: "Contract"; id: string; agreementDate: string; status: ContractStatus };
		invoice: { __typename?: "Invoice"; id: string; invoiceNumber: string; dueDate: string } | null;
	};
};

export type CourseCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<CourseFilterInput> | CourseFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<CourseOrder>;
}>;

export type CourseCountQuery = {
	__typename?: "Query";
	courseConnection: { __typename?: "CourseConnection"; totalCount: number };
};

export type CourseCreateMutationVariables = Exact<{
	input: CourseCreateInput;
}>;

export type CourseCreateMutation = {
	__typename?: "Mutation";
	createCourse: {
		__typename?: "Course";
		id: string;
		name: string;
		description: string;
		subject: CourseSubject;
		studentCapacity: number | null;
		classYears: Array<{ __typename?: "ClassYear"; id: string; name: string }>;
		courseSections: Array<{
			__typename?: "CourseSection";
			id: string;
			name: string;
			course: { __typename?: "Course"; id: string; subject: CourseSubject };
			homeRoomSection: { __typename?: "HomeRoomSection"; id: string; name: string };
		}>;
		homeRoom: { __typename?: "HomeRoom"; id: string; name: string };
		learningObjectives: Array<{ __typename?: "LearningObjective"; id: string; category: string; description: string }>;
	};
};

export type CourseDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type CourseDeleteMutation = { __typename?: "Mutation"; deleteCourse: boolean };

export type CourseDetailFragment = {
	__typename?: "Course";
	id: string;
	name: string;
	description: string;
	subject: CourseSubject;
	studentCapacity: number | null;
	classYears: Array<{ __typename?: "ClassYear"; id: string; name: string }>;
	courseSections: Array<{
		__typename?: "CourseSection";
		id: string;
		name: string;
		course: { __typename?: "Course"; id: string; subject: CourseSubject };
		homeRoomSection: { __typename?: "HomeRoomSection"; id: string; name: string };
	}>;
	homeRoom: { __typename?: "HomeRoom"; id: string; name: string };
	learningObjectives: Array<{ __typename?: "LearningObjective"; id: string; category: string; description: string }>;
};

export type CourseDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type CourseDetailQuery = {
	__typename?: "Query";
	course: {
		__typename?: "Course";
		id: string;
		name: string;
		description: string;
		subject: CourseSubject;
		studentCapacity: number | null;
		classYears: Array<{ __typename?: "ClassYear"; id: string; name: string }>;
		courseSections: Array<{
			__typename?: "CourseSection";
			id: string;
			name: string;
			course: { __typename?: "Course"; id: string; subject: CourseSubject };
			homeRoomSection: { __typename?: "HomeRoomSection"; id: string; name: string };
		}>;
		homeRoom: { __typename?: "HomeRoom"; id: string; name: string };
		learningObjectives: Array<{ __typename?: "LearningObjective"; id: string; category: string; description: string }>;
	};
};

export type CourseIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<CourseFilterInput> | CourseFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<CourseOrder>;
}>;

export type CourseIndexQuery = {
	__typename?: "Query";
	courseConnection: {
		__typename?: "CourseConnection";
		edges: Array<{
			__typename?: "CourseEdge";
			node: {
				__typename?: "Course";
				id: string;
				name: string;
				description: string;
				subject: CourseSubject;
				studentCapacity: number | null;
			};
		}>;
	};
};

export type CourseNameFragment = {
	__typename?: "Course";
	name: string;
	subject: CourseSubject;
	homeRoom: { __typename?: "HomeRoom"; id: string; name: string };
};

export type CourseNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type CourseNameQuery = {
	__typename?: "Query";
	course: {
		__typename?: "Course";
		id: string;
		name: string;
		subject: CourseSubject;
		homeRoom: { __typename?: "HomeRoom"; id: string; name: string };
	};
};

export type CourseNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<CourseFilterInput> | CourseFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<CourseOrder>;
}>;

export type CourseNamesQuery = {
	__typename?: "Query";
	courseConnection: {
		__typename?: "CourseConnection";
		edges: Array<{
			__typename?: "CourseEdge";
			node: {
				__typename?: "Course";
				id: string;
				name: string;
				subject: CourseSubject;
				homeRoom: { __typename?: "HomeRoom"; id: string; name: string };
			};
		}>;
	};
};

export type CoursePaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<CourseFilterInput> | CourseFilterInput>;
	order?: InputMaybe<CourseOrder>;
}>;

export type CoursePaginationQuery = {
	__typename?: "Query";
	courseConnection: {
		__typename?: "CourseConnection";
		totalCount: number;
		edges: Array<{ __typename?: "CourseEdge"; cursor: string }>;
	};
};

export type CourseSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<CourseFilterInput> | CourseFilterInput>;
	order?: InputMaybe<CourseOrder>;
}>;

export type CourseSelectQuery = {
	__typename?: "Query";
	courseConnection: {
		__typename?: "CourseConnection";
		edges: Array<{
			__typename?: "CourseEdge";
			node: {
				__typename?: "Course";
				id: string;
				name: string;
				subject: CourseSubject;
				homeRoom: { __typename?: "HomeRoom"; id: string; name: string };
			};
		}>;
	};
};

export type CourseUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: CourseUpdateInput;
}>;

export type CourseUpdateMutation = {
	__typename?: "Mutation";
	updateCourse: {
		__typename?: "Course";
		id: string;
		name: string;
		description: string;
		subject: CourseSubject;
		studentCapacity: number | null;
		classYears: Array<{ __typename?: "ClassYear"; id: string; name: string }>;
		courseSections: Array<{
			__typename?: "CourseSection";
			id: string;
			name: string;
			course: { __typename?: "Course"; id: string; subject: CourseSubject };
			homeRoomSection: { __typename?: "HomeRoomSection"; id: string; name: string };
		}>;
		homeRoom: { __typename?: "HomeRoom"; id: string; name: string };
		learningObjectives: Array<{ __typename?: "LearningObjective"; id: string; category: string; description: string }>;
	};
};

export type CourseAttendanceRecordCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<CourseAttendanceRecordFilterInput> | CourseAttendanceRecordFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<CourseAttendanceRecordOrder>;
}>;

export type CourseAttendanceRecordCountQuery = {
	__typename?: "Query";
	courseAttendanceRecordConnection: { __typename?: "CourseAttendanceRecordConnection"; totalCount: number };
};

export type CourseAttendanceRecordListForCoursePeriodQueryVariables = Exact<{
	courseSectionPeriodID: Scalars["ID"];
	date: Scalars["Time"];
}>;

export type CourseAttendanceRecordListForCoursePeriodQuery = {
	__typename?: "Query";
	courseAttendanceRecordConnection: {
		__typename?: "CourseAttendanceRecordConnection";
		edges: Array<{
			__typename?: "CourseAttendanceRecordEdge";
			node: {
				__typename?: "CourseAttendanceRecord";
				id: string;
				attendanceDate: string;
				isPresent: boolean;
				isLate: boolean;
				isExcused: boolean;
				notes: string;
				studentID: string;
			};
		}>;
	};
};

export type CourseAttendanceRecordsCreateBulkMutationVariables = Exact<{
	inputs: Array<CourseAttendanceRecordCreateInput> | CourseAttendanceRecordCreateInput;
}>;

export type CourseAttendanceRecordsCreateBulkMutation = {
	__typename?: "Mutation";
	createBulkCourseAttendanceRecords: {
		__typename?: "CourseAttendanceRecordConnection";
		edges: Array<{
			__typename?: "CourseAttendanceRecordEdge";
			node: {
				__typename?: "CourseAttendanceRecord";
				id: string;
				attendanceDate: string;
				isPresent: boolean;
				isLate: boolean;
				isExcused: boolean;
				notes: string;
				courseSectionPeriod: {
					__typename?: "CourseSectionPeriod";
					id: string;
					dayOfWeek: number;
					startTimeMinutes: number;
					endTimeMinutes: number;
				};
				student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
			};
		}>;
	};
};

export type CourseAttendanceRecordCreateMutationVariables = Exact<{
	input: CourseAttendanceRecordCreateInput;
}>;

export type CourseAttendanceRecordCreateMutation = {
	__typename?: "Mutation";
	createCourseAttendanceRecord: {
		__typename?: "CourseAttendanceRecord";
		id: string;
		attendanceDate: string;
		isPresent: boolean;
		isLate: boolean;
		isExcused: boolean;
		notes: string;
		courseSectionPeriod: {
			__typename?: "CourseSectionPeriod";
			id: string;
			dayOfWeek: number;
			startTimeMinutes: number;
			endTimeMinutes: number;
		};
		student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
	};
};

export type CourseAttendanceRecordDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type CourseAttendanceRecordDeleteMutation = { __typename?: "Mutation"; deleteCourseAttendanceRecord: boolean };

export type CourseAttendanceRecordDetailFragment = {
	__typename?: "CourseAttendanceRecord";
	id: string;
	attendanceDate: string;
	isPresent: boolean;
	isLate: boolean;
	isExcused: boolean;
	notes: string;
	courseSectionPeriod: {
		__typename?: "CourseSectionPeriod";
		id: string;
		dayOfWeek: number;
		startTimeMinutes: number;
		endTimeMinutes: number;
	};
	student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
};

export type CourseAttendanceRecordDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type CourseAttendanceRecordDetailQuery = {
	__typename?: "Query";
	courseAttendanceRecord: {
		__typename?: "CourseAttendanceRecord";
		id: string;
		attendanceDate: string;
		isPresent: boolean;
		isLate: boolean;
		isExcused: boolean;
		notes: string;
		courseSectionPeriod: {
			__typename?: "CourseSectionPeriod";
			id: string;
			dayOfWeek: number;
			startTimeMinutes: number;
			endTimeMinutes: number;
		};
		student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
	};
};

export type CourseAttendanceRecordIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<CourseAttendanceRecordFilterInput> | CourseAttendanceRecordFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<CourseAttendanceRecordOrder>;
}>;

export type CourseAttendanceRecordIndexQuery = {
	__typename?: "Query";
	courseAttendanceRecordConnection: {
		__typename?: "CourseAttendanceRecordConnection";
		edges: Array<{
			__typename?: "CourseAttendanceRecordEdge";
			node: {
				__typename?: "CourseAttendanceRecord";
				id: string;
				attendanceDate: string;
				isPresent: boolean;
				isLate: boolean;
				isExcused: boolean;
				notes: string;
			};
		}>;
	};
};

export type CourseAttendanceRecordNameFragment = { __typename?: "CourseAttendanceRecord"; id: string };

export type CourseAttendanceRecordNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type CourseAttendanceRecordNameQuery = {
	__typename?: "Query";
	courseAttendanceRecord: { __typename?: "CourseAttendanceRecord"; id: string };
};

export type CourseAttendanceRecordNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<CourseAttendanceRecordFilterInput> | CourseAttendanceRecordFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<CourseAttendanceRecordOrder>;
}>;

export type CourseAttendanceRecordNamesQuery = {
	__typename?: "Query";
	courseAttendanceRecordConnection: {
		__typename?: "CourseAttendanceRecordConnection";
		edges: Array<{
			__typename?: "CourseAttendanceRecordEdge";
			node: { __typename?: "CourseAttendanceRecord"; id: string };
		}>;
	};
};

export type CourseAttendanceRecordPaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<CourseAttendanceRecordFilterInput> | CourseAttendanceRecordFilterInput>;
	order?: InputMaybe<CourseAttendanceRecordOrder>;
}>;

export type CourseAttendanceRecordPaginationQuery = {
	__typename?: "Query";
	courseAttendanceRecordConnection: {
		__typename?: "CourseAttendanceRecordConnection";
		totalCount: number;
		edges: Array<{ __typename?: "CourseAttendanceRecordEdge"; cursor: string }>;
	};
};

export type CourseAttendanceRecordSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<CourseAttendanceRecordFilterInput> | CourseAttendanceRecordFilterInput>;
	order?: InputMaybe<CourseAttendanceRecordOrder>;
}>;

export type CourseAttendanceRecordSelectQuery = {
	__typename?: "Query";
	courseAttendanceRecordConnection: {
		__typename?: "CourseAttendanceRecordConnection";
		edges: Array<{
			__typename?: "CourseAttendanceRecordEdge";
			node: { __typename?: "CourseAttendanceRecord"; id: string };
		}>;
	};
};

export type CourseAttendanceRecordUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: CourseAttendanceRecordUpdateInput;
}>;

export type CourseAttendanceRecordUpdateMutation = {
	__typename?: "Mutation";
	updateCourseAttendanceRecord: {
		__typename?: "CourseAttendanceRecord";
		id: string;
		attendanceDate: string;
		isPresent: boolean;
		isLate: boolean;
		isExcused: boolean;
		notes: string;
		courseSectionPeriod: {
			__typename?: "CourseSectionPeriod";
			id: string;
			dayOfWeek: number;
			startTimeMinutes: number;
			endTimeMinutes: number;
		};
		student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
	};
};

export type CourseSectionCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<CourseSectionFilterInput> | CourseSectionFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<CourseSectionOrder>;
}>;

export type CourseSectionCountQuery = {
	__typename?: "Query";
	courseSectionConnection: { __typename?: "CourseSectionConnection"; totalCount: number };
};

export type CourseSectionsCreateBulkMutationVariables = Exact<{
	inputs: Array<CourseSectionCreateInput> | CourseSectionCreateInput;
}>;

export type CourseSectionsCreateBulkMutation = {
	__typename?: "Mutation";
	createBulkCourseSections: {
		__typename?: "CourseSectionConnection";
		edges: Array<{
			__typename?: "CourseSectionEdge";
			node: {
				__typename?: "CourseSection";
				id: string;
				name: string;
				description: string;
				course: {
					__typename?: "Course";
					id: string;
					name: string;
					subject: CourseSubject;
					homeRoom: { __typename?: "HomeRoom"; id: string; name: string };
				};
				courseSectionPeriods: Array<{
					__typename?: "CourseSectionPeriod";
					id: string;
					dayOfWeek: number;
					startTimeMinutes: number;
					endTimeMinutes: number;
				}>;
				homeRoomSection: { __typename?: "HomeRoomSection"; id: string; name: string };
				courseSectionStudentEnrollments: Array<{
					__typename?: "CourseSectionStudentEnrollment";
					id: string;
					student: {
						__typename?: "Student";
						id: string;
						person: { __typename?: "Person"; id: string; name: string; imageURL: string };
					};
				}>;
				courseSectionTeacherEnrollments: Array<{
					__typename?: "CourseSectionTeacherEnrollment";
					id: string;
					teacher: {
						__typename?: "Employee";
						id: string;
						person: { __typename?: "Person"; id: string; name: string; imageURL: string };
					};
				}>;
				term: { __typename?: "Term"; id: string; name: string };
			};
		}>;
	};
};

export type CourseSectionCreateMutationVariables = Exact<{
	input: CourseSectionCreateInput;
}>;

export type CourseSectionCreateMutation = {
	__typename?: "Mutation";
	createCourseSection: {
		__typename?: "CourseSection";
		id: string;
		name: string;
		description: string;
		course: {
			__typename?: "Course";
			id: string;
			name: string;
			subject: CourseSubject;
			homeRoom: { __typename?: "HomeRoom"; id: string; name: string };
		};
		courseSectionPeriods: Array<{
			__typename?: "CourseSectionPeriod";
			id: string;
			dayOfWeek: number;
			startTimeMinutes: number;
			endTimeMinutes: number;
		}>;
		homeRoomSection: { __typename?: "HomeRoomSection"; id: string; name: string };
		courseSectionStudentEnrollments: Array<{
			__typename?: "CourseSectionStudentEnrollment";
			id: string;
			student: {
				__typename?: "Student";
				id: string;
				person: { __typename?: "Person"; id: string; name: string; imageURL: string };
			};
		}>;
		courseSectionTeacherEnrollments: Array<{
			__typename?: "CourseSectionTeacherEnrollment";
			id: string;
			teacher: {
				__typename?: "Employee";
				id: string;
				person: { __typename?: "Person"; id: string; name: string; imageURL: string };
			};
		}>;
		term: { __typename?: "Term"; id: string; name: string };
	};
};

export type CourseSectionDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type CourseSectionDeleteMutation = { __typename?: "Mutation"; deleteCourseSection: boolean };

export type CourseSectionDetailFragment = {
	__typename?: "CourseSection";
	id: string;
	name: string;
	description: string;
	course: {
		__typename?: "Course";
		id: string;
		name: string;
		subject: CourseSubject;
		homeRoom: { __typename?: "HomeRoom"; id: string; name: string };
	};
	courseSectionPeriods: Array<{
		__typename?: "CourseSectionPeriod";
		id: string;
		dayOfWeek: number;
		startTimeMinutes: number;
		endTimeMinutes: number;
	}>;
	homeRoomSection: { __typename?: "HomeRoomSection"; id: string; name: string };
	courseSectionStudentEnrollments: Array<{
		__typename?: "CourseSectionStudentEnrollment";
		id: string;
		student: {
			__typename?: "Student";
			id: string;
			person: { __typename?: "Person"; id: string; name: string; imageURL: string };
		};
	}>;
	courseSectionTeacherEnrollments: Array<{
		__typename?: "CourseSectionTeacherEnrollment";
		id: string;
		teacher: {
			__typename?: "Employee";
			id: string;
			person: { __typename?: "Person"; id: string; name: string; imageURL: string };
		};
	}>;
	term: { __typename?: "Term"; id: string; name: string };
};

export type CourseSectionDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type CourseSectionDetailQuery = {
	__typename?: "Query";
	courseSection: {
		__typename?: "CourseSection";
		id: string;
		name: string;
		description: string;
		course: {
			__typename?: "Course";
			id: string;
			name: string;
			subject: CourseSubject;
			homeRoom: { __typename?: "HomeRoom"; id: string; name: string };
		};
		courseSectionPeriods: Array<{
			__typename?: "CourseSectionPeriod";
			id: string;
			dayOfWeek: number;
			startTimeMinutes: number;
			endTimeMinutes: number;
		}>;
		homeRoomSection: { __typename?: "HomeRoomSection"; id: string; name: string };
		courseSectionStudentEnrollments: Array<{
			__typename?: "CourseSectionStudentEnrollment";
			id: string;
			student: {
				__typename?: "Student";
				id: string;
				person: { __typename?: "Person"; id: string; name: string; imageURL: string };
			};
		}>;
		courseSectionTeacherEnrollments: Array<{
			__typename?: "CourseSectionTeacherEnrollment";
			id: string;
			teacher: {
				__typename?: "Employee";
				id: string;
				person: { __typename?: "Person"; id: string; name: string; imageURL: string };
			};
		}>;
		term: { __typename?: "Term"; id: string; name: string };
	};
};

export type CourseSectionIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<CourseSectionFilterInput> | CourseSectionFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<CourseSectionOrder>;
}>;

export type CourseSectionIndexQuery = {
	__typename?: "Query";
	courseSectionConnection: {
		__typename?: "CourseSectionConnection";
		edges: Array<{
			__typename?: "CourseSectionEdge";
			node: {
				__typename?: "CourseSection";
				id: string;
				name: string;
				description: string;
				term: { __typename?: "Term"; id: string; name: string };
			};
		}>;
	};
};

export type CourseSectionTeacherLessonPlanOverviewListQueryVariables = Exact<{
	teacherID: Scalars["ID"];
	startDate: Scalars["Time"];
	endDate: Scalars["Time"];
}>;

export type CourseSectionTeacherLessonPlanOverviewListQuery = {
	__typename?: "Query";
	courseSectionConnection: {
		__typename?: "CourseSectionConnection";
		edges: Array<{
			__typename?: "CourseSectionEdge";
			node: {
				__typename?: "CourseSection";
				id: string;
				name: string;
				course: { __typename?: "Course"; id: string; name: string; subject: CourseSubject };
				homeRoomSection: { __typename?: "HomeRoomSection"; id: string; name: string };
				courseSectionPeriods: Array<{ __typename?: "CourseSectionPeriod"; id: string; dayOfWeek: number }>;
				lessonPlans: Array<{ __typename?: "LessonPlan"; id: string; courseDate: string }>;
			};
		}>;
	};
};

export type CourseSectionHomeRoomSectionLessonPlanOverviewListQueryVariables = Exact<{
	homeRoomSectionID: Scalars["ID"];
	startDate: Scalars["Time"];
	endDate: Scalars["Time"];
}>;

export type CourseSectionHomeRoomSectionLessonPlanOverviewListQuery = {
	__typename?: "Query";
	courseSectionConnection: {
		__typename?: "CourseSectionConnection";
		edges: Array<{
			__typename?: "CourseSectionEdge";
			node: {
				__typename?: "CourseSection";
				id: string;
				name: string;
				course: { __typename?: "Course"; id: string; name: string; subject: CourseSubject };
				homeRoomSection: { __typename?: "HomeRoomSection"; id: string; name: string };
				courseSectionPeriods: Array<{ __typename?: "CourseSectionPeriod"; id: string; dayOfWeek: number }>;
				lessonPlans: Array<{ __typename?: "LessonPlan"; id: string; courseDate: string }>;
			};
		}>;
	};
};

export type CourseSectionNameFragment = {
	__typename?: "CourseSection";
	name: string;
	course: { __typename?: "Course"; id: string; subject: CourseSubject };
	homeRoomSection: { __typename?: "HomeRoomSection"; id: string; name: string };
};

export type CourseSectionNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type CourseSectionNameQuery = {
	__typename?: "Query";
	courseSection: {
		__typename?: "CourseSection";
		id: string;
		name: string;
		course: { __typename?: "Course"; id: string; subject: CourseSubject };
		homeRoomSection: { __typename?: "HomeRoomSection"; id: string; name: string };
	};
};

export type CourseSectionNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<CourseSectionFilterInput> | CourseSectionFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<CourseSectionOrder>;
}>;

export type CourseSectionNamesQuery = {
	__typename?: "Query";
	courseSectionConnection: {
		__typename?: "CourseSectionConnection";
		edges: Array<{
			__typename?: "CourseSectionEdge";
			node: {
				__typename?: "CourseSection";
				id: string;
				name: string;
				course: { __typename?: "Course"; id: string; subject: CourseSubject };
				homeRoomSection: { __typename?: "HomeRoomSection"; id: string; name: string };
			};
		}>;
	};
};

export type CourseSectionPaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<CourseSectionFilterInput> | CourseSectionFilterInput>;
	order?: InputMaybe<CourseSectionOrder>;
}>;

export type CourseSectionPaginationQuery = {
	__typename?: "Query";
	courseSectionConnection: {
		__typename?: "CourseSectionConnection";
		totalCount: number;
		edges: Array<{ __typename?: "CourseSectionEdge"; cursor: string }>;
	};
};

export type CourseSectionSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<CourseSectionFilterInput> | CourseSectionFilterInput>;
	order?: InputMaybe<CourseSectionOrder>;
}>;

export type CourseSectionSelectQuery = {
	__typename?: "Query";
	courseSectionConnection: {
		__typename?: "CourseSectionConnection";
		edges: Array<{
			__typename?: "CourseSectionEdge";
			node: {
				__typename?: "CourseSection";
				id: string;
				name: string;
				course: { __typename?: "Course"; id: string; subject: CourseSubject };
				homeRoomSection: { __typename?: "HomeRoomSection"; id: string; name: string };
			};
		}>;
	};
};

export type CourseSectionUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: CourseSectionUpdateInput;
}>;

export type CourseSectionUpdateMutation = {
	__typename?: "Mutation";
	updateCourseSection: {
		__typename?: "CourseSection";
		id: string;
		name: string;
		description: string;
		course: {
			__typename?: "Course";
			id: string;
			name: string;
			subject: CourseSubject;
			homeRoom: { __typename?: "HomeRoom"; id: string; name: string };
		};
		courseSectionPeriods: Array<{
			__typename?: "CourseSectionPeriod";
			id: string;
			dayOfWeek: number;
			startTimeMinutes: number;
			endTimeMinutes: number;
		}>;
		homeRoomSection: { __typename?: "HomeRoomSection"; id: string; name: string };
		courseSectionStudentEnrollments: Array<{
			__typename?: "CourseSectionStudentEnrollment";
			id: string;
			student: {
				__typename?: "Student";
				id: string;
				person: { __typename?: "Person"; id: string; name: string; imageURL: string };
			};
		}>;
		courseSectionTeacherEnrollments: Array<{
			__typename?: "CourseSectionTeacherEnrollment";
			id: string;
			teacher: {
				__typename?: "Employee";
				id: string;
				person: { __typename?: "Person"; id: string; name: string; imageURL: string };
			};
		}>;
		term: { __typename?: "Term"; id: string; name: string };
	};
};

export type WeeklyCourseScheduleQueryVariables = Exact<{
	filters?: InputMaybe<Array<CourseSectionFilterInput> | CourseSectionFilterInput>;
}>;

export type WeeklyCourseScheduleQuery = {
	__typename?: "Query";
	courseSectionConnection: {
		__typename?: "CourseSectionConnection";
		edges: Array<{
			__typename?: "CourseSectionEdge";
			node: {
				__typename?: "CourseSection";
				id: string;
				name: string;
				course: { __typename?: "Course"; id: string; subject: CourseSubject };
				homeRoomSection: { __typename?: "HomeRoomSection"; id: string; name: string };
				courseSectionPeriods: Array<{
					__typename?: "CourseSectionPeriod";
					id: string;
					dayOfWeek: number;
					startTimeMinutes: number;
					endTimeMinutes: number;
				}>;
			};
		}>;
	};
};

export type CourseSectionPeriodCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<CourseSectionPeriodFilterInput> | CourseSectionPeriodFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<CourseSectionPeriodOrder>;
}>;

export type CourseSectionPeriodCountQuery = {
	__typename?: "Query";
	courseSectionPeriodConnection: { __typename?: "CourseSectionPeriodConnection"; totalCount: number };
};

export type CourseSectionPeriodCreateMutationVariables = Exact<{
	input: CourseSectionPeriodCreateInput;
}>;

export type CourseSectionPeriodCreateMutation = {
	__typename?: "Mutation";
	createCourseSectionPeriod: {
		__typename?: "CourseSectionPeriod";
		id: string;
		dayOfWeek: number;
		startTimeMinutes: number;
		endTimeMinutes: number;
		courseSection: {
			__typename?: "CourseSection";
			id: string;
			name: string;
			course: { __typename?: "Course"; id: string; subject: CourseSubject };
			homeRoomSection: { __typename?: "HomeRoomSection"; id: string; name: string };
		};
		room: { __typename?: "Room"; id: string; name: string };
	};
};

export type CourseSectionPeriodDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type CourseSectionPeriodDeleteMutation = { __typename?: "Mutation"; deleteCourseSectionPeriod: boolean };

export type CourseSectionPeriodDetailFragment = {
	__typename?: "CourseSectionPeriod";
	id: string;
	dayOfWeek: number;
	startTimeMinutes: number;
	endTimeMinutes: number;
	courseSection: {
		__typename?: "CourseSection";
		id: string;
		name: string;
		course: { __typename?: "Course"; id: string; subject: CourseSubject };
		homeRoomSection: { __typename?: "HomeRoomSection"; id: string; name: string };
	};
	room: { __typename?: "Room"; id: string; name: string };
};

export type CourseSectionPeriodDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type CourseSectionPeriodDetailQuery = {
	__typename?: "Query";
	courseSectionPeriod: {
		__typename?: "CourseSectionPeriod";
		id: string;
		dayOfWeek: number;
		startTimeMinutes: number;
		endTimeMinutes: number;
		courseSection: {
			__typename?: "CourseSection";
			id: string;
			name: string;
			course: { __typename?: "Course"; id: string; subject: CourseSubject };
			homeRoomSection: { __typename?: "HomeRoomSection"; id: string; name: string };
		};
		room: { __typename?: "Room"; id: string; name: string };
	};
};

export type CourseSectionPeriodHomeRoomSectionDailyScheduleQueryVariables = Exact<{
	homeRoomSectionID: Scalars["ID"];
	dateDOW: Scalars["Int"];
}>;

export type CourseSectionPeriodHomeRoomSectionDailyScheduleQuery = {
	__typename?: "Query";
	courseSectionPeriodConnection: {
		__typename?: "CourseSectionPeriodConnection";
		edges: Array<{
			__typename?: "CourseSectionPeriodEdge";
			node: {
				__typename?: "CourseSectionPeriod";
				id: string;
				dayOfWeek: number;
				startTimeMinutes: number;
				endTimeMinutes: number;
				courseSection: {
					__typename?: "CourseSection";
					id: string;
					name: string;
					course: { __typename?: "Course"; id: string; name: string; subject: CourseSubject };
				};
				room: { __typename?: "Room"; id: string; name: string; number: string };
			};
		}>;
	};
};

export type CourseSectionPeriodIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<CourseSectionPeriodFilterInput> | CourseSectionPeriodFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<CourseSectionPeriodOrder>;
}>;

export type CourseSectionPeriodIndexQuery = {
	__typename?: "Query";
	courseSectionPeriodConnection: {
		__typename?: "CourseSectionPeriodConnection";
		edges: Array<{
			__typename?: "CourseSectionPeriodEdge";
			node: {
				__typename?: "CourseSectionPeriod";
				id: string;
				dayOfWeek: number;
				startTimeMinutes: number;
				endTimeMinutes: number;
			};
		}>;
	};
};

export type CourseSectionPeriodNameFragment = {
	__typename?: "CourseSectionPeriod";
	dayOfWeek: number;
	startTimeMinutes: number;
	endTimeMinutes: number;
};

export type CourseSectionPeriodNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type CourseSectionPeriodNameQuery = {
	__typename?: "Query";
	courseSectionPeriod: {
		__typename?: "CourseSectionPeriod";
		id: string;
		dayOfWeek: number;
		startTimeMinutes: number;
		endTimeMinutes: number;
	};
};

export type CourseSectionPeriodNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<CourseSectionPeriodFilterInput> | CourseSectionPeriodFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<CourseSectionPeriodOrder>;
}>;

export type CourseSectionPeriodNamesQuery = {
	__typename?: "Query";
	courseSectionPeriodConnection: {
		__typename?: "CourseSectionPeriodConnection";
		edges: Array<{
			__typename?: "CourseSectionPeriodEdge";
			node: {
				__typename?: "CourseSectionPeriod";
				id: string;
				dayOfWeek: number;
				startTimeMinutes: number;
				endTimeMinutes: number;
			};
		}>;
	};
};

export type CourseSectionPeriodPaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<CourseSectionPeriodFilterInput> | CourseSectionPeriodFilterInput>;
	order?: InputMaybe<CourseSectionPeriodOrder>;
}>;

export type CourseSectionPeriodPaginationQuery = {
	__typename?: "Query";
	courseSectionPeriodConnection: {
		__typename?: "CourseSectionPeriodConnection";
		totalCount: number;
		edges: Array<{ __typename?: "CourseSectionPeriodEdge"; cursor: string }>;
	};
};

export type CourseSectionPeriodSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<CourseSectionPeriodFilterInput> | CourseSectionPeriodFilterInput>;
	order?: InputMaybe<CourseSectionPeriodOrder>;
}>;

export type CourseSectionPeriodSelectQuery = {
	__typename?: "Query";
	courseSectionPeriodConnection: {
		__typename?: "CourseSectionPeriodConnection";
		edges: Array<{
			__typename?: "CourseSectionPeriodEdge";
			node: {
				__typename?: "CourseSectionPeriod";
				id: string;
				dayOfWeek: number;
				startTimeMinutes: number;
				endTimeMinutes: number;
			};
		}>;
	};
};

export type CourseSectionPeriodStudentDailyScheduleQueryVariables = Exact<{
	studentID: Scalars["ID"];
	date: Scalars["Time"];
	dateDOW: Scalars["Int"];
}>;

export type CourseSectionPeriodStudentDailyScheduleQuery = {
	__typename?: "Query";
	courseSectionPeriodConnection: {
		__typename?: "CourseSectionPeriodConnection";
		edges: Array<{
			__typename?: "CourseSectionPeriodEdge";
			node: {
				__typename?: "CourseSectionPeriod";
				id: string;
				dayOfWeek: number;
				startTimeMinutes: number;
				endTimeMinutes: number;
				courseSection: {
					__typename?: "CourseSection";
					id: string;
					name: string;
					course: { __typename?: "Course"; id: string; name: string; subject: CourseSubject };
				};
				room: { __typename?: "Room"; id: string; name: string; number: string };
			};
		}>;
	};
};

export type CourseSectionPeriodTeacherDailyScheduleQueryVariables = Exact<{
	teacherID: Scalars["ID"];
	date: Scalars["Time"];
	dateDOW: Scalars["Int"];
}>;

export type CourseSectionPeriodTeacherDailyScheduleQuery = {
	__typename?: "Query";
	courseSectionPeriodConnection: {
		__typename?: "CourseSectionPeriodConnection";
		edges: Array<{
			__typename?: "CourseSectionPeriodEdge";
			node: {
				__typename?: "CourseSectionPeriod";
				id: string;
				dayOfWeek: number;
				startTimeMinutes: number;
				endTimeMinutes: number;
				courseSection: {
					__typename?: "CourseSection";
					id: string;
					name: string;
					course: { __typename?: "Course"; id: string; name: string; subject: CourseSubject };
					homeRoomSection: { __typename?: "HomeRoomSection"; id: string; name: string };
					courseSectionTeacherEnrollments: Array<{
						__typename?: "CourseSectionTeacherEnrollment";
						id: string;
						teacher: { __typename?: "Employee"; id: string };
					}>;
				};
				room: { __typename?: "Room"; id: string; name: string; number: string };
			};
		}>;
	};
};

export type CourseSectionPeriodTeacherDailyScheduleWithLessonPlansQueryVariables = Exact<{
	teacherID: Scalars["ID"];
	date: Scalars["Time"];
	dateDOW: Scalars["Int"];
}>;

export type CourseSectionPeriodTeacherDailyScheduleWithLessonPlansQuery = {
	__typename?: "Query";
	courseSectionPeriodConnection: {
		__typename?: "CourseSectionPeriodConnection";
		edges: Array<{
			__typename?: "CourseSectionPeriodEdge";
			node: {
				__typename?: "CourseSectionPeriod";
				id: string;
				dayOfWeek: number;
				startTimeMinutes: number;
				endTimeMinutes: number;
				courseSection: {
					__typename?: "CourseSection";
					id: string;
					name: string;
					course: { __typename?: "Course"; id: string; name: string; subject: CourseSubject };
					homeRoomSection: { __typename?: "HomeRoomSection"; id: string; name: string };
					lessonPlans: Array<{ __typename?: "LessonPlan"; id: string; lessonDescription: string }>;
				};
				room: { __typename?: "Room"; id: string; name: string; number: string };
			};
		}>;
	};
};

export type CourseSectionPeriodTeacherDetailQueryVariables = Exact<{
	id: Scalars["ID"];
	date: Scalars["Time"];
}>;

export type CourseSectionPeriodTeacherDetailQuery = {
	__typename?: "Query";
	courseSectionPeriod: {
		__typename?: "CourseSectionPeriod";
		id: string;
		dayOfWeek: number;
		startTimeMinutes: number;
		endTimeMinutes: number;
		courseSection: {
			__typename?: "CourseSection";
			id: string;
			name: string;
			course: {
				__typename?: "Course";
				id: string;
				name: string;
				subject: CourseSubject;
				homeRoom: { __typename?: "HomeRoom"; id: string; name: string };
			};
			homeRoomSection: { __typename?: "HomeRoomSection"; id: string; name: string };
			courseSectionTeacherEnrollments: Array<{
				__typename?: "CourseSectionTeacherEnrollment";
				id: string;
				teacher: {
					__typename?: "Employee";
					id: string;
					person: { __typename?: "Person"; id: string; name: string; nickname: string; imageURL: string };
				};
			}>;
			courseSectionStudentEnrollments: Array<{
				__typename?: "CourseSectionStudentEnrollment";
				id: string;
				student: {
					__typename?: "Student";
					id: string;
					person: { __typename?: "Person"; id: string; name: string; nickname: string; imageURL: string };
				};
			}>;
			lessonPlans: Array<{
				__typename?: "LessonPlan";
				id: string;
				courseDate: string;
				lessonDescription: string;
				requiredResourcesAndMaterials: string;
				stationOneDescription: string;
				stationTwoDescription: string;
				stationThreeDescription: string;
				differentiationDescription: string;
				assessmentDescription: string;
				notes: string;
				learningObjectives: Array<{
					__typename?: "LearningObjective";
					id: string;
					category: string;
					description: string;
				}>;
			}>;
		};
		room: { __typename?: "Room"; id: string; name: string; number: string };
	};
};

export type CourseSectionPeriodUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: CourseSectionPeriodUpdateInput;
}>;

export type CourseSectionPeriodUpdateMutation = {
	__typename?: "Mutation";
	updateCourseSectionPeriod: {
		__typename?: "CourseSectionPeriod";
		id: string;
		dayOfWeek: number;
		startTimeMinutes: number;
		endTimeMinutes: number;
		courseSection: {
			__typename?: "CourseSection";
			id: string;
			name: string;
			course: { __typename?: "Course"; id: string; subject: CourseSubject };
			homeRoomSection: { __typename?: "HomeRoomSection"; id: string; name: string };
		};
		room: { __typename?: "Room"; id: string; name: string };
	};
};

export type DiscountCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<DiscountFilterInput> | DiscountFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<DiscountOrder>;
}>;

export type DiscountCountQuery = {
	__typename?: "Query";
	discountConnection: { __typename?: "DiscountConnection"; totalCount: number };
};

export type DiscountCreateMutationVariables = Exact<{
	input: DiscountCreateInput;
}>;

export type DiscountCreateMutation = {
	__typename?: "Mutation";
	createDiscount: {
		__typename?: "Discount";
		id: string;
		amount: number;
		description: string;
		serviceSubscription: { __typename?: "ServiceSubscription"; id: string };
	};
};

export type DiscountDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type DiscountDeleteMutation = { __typename?: "Mutation"; deleteDiscount: boolean };

export type DiscountDetailFragment = {
	__typename?: "Discount";
	id: string;
	amount: number;
	description: string;
	serviceSubscription: { __typename?: "ServiceSubscription"; id: string };
};

export type DiscountDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type DiscountDetailQuery = {
	__typename?: "Query";
	discount: {
		__typename?: "Discount";
		id: string;
		amount: number;
		description: string;
		serviceSubscription: { __typename?: "ServiceSubscription"; id: string };
	};
};

export type DiscountIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<DiscountFilterInput> | DiscountFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<DiscountOrder>;
}>;

export type DiscountIndexQuery = {
	__typename?: "Query";
	discountConnection: {
		__typename?: "DiscountConnection";
		edges: Array<{
			__typename?: "DiscountEdge";
			node: { __typename?: "Discount"; id: string; amount: number; description: string };
		}>;
	};
};

export type DiscountNameFragment = { __typename?: "Discount"; id: string };

export type DiscountNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type DiscountNameQuery = { __typename?: "Query"; discount: { __typename?: "Discount"; id: string } };

export type DiscountNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<DiscountFilterInput> | DiscountFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<DiscountOrder>;
}>;

export type DiscountNamesQuery = {
	__typename?: "Query";
	discountConnection: {
		__typename?: "DiscountConnection";
		edges: Array<{ __typename?: "DiscountEdge"; node: { __typename?: "Discount"; id: string } }>;
	};
};

export type DiscountPaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<DiscountFilterInput> | DiscountFilterInput>;
	order?: InputMaybe<DiscountOrder>;
}>;

export type DiscountPaginationQuery = {
	__typename?: "Query";
	discountConnection: {
		__typename?: "DiscountConnection";
		totalCount: number;
		edges: Array<{ __typename?: "DiscountEdge"; cursor: string }>;
	};
};

export type DiscountSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<DiscountFilterInput> | DiscountFilterInput>;
	order?: InputMaybe<DiscountOrder>;
}>;

export type DiscountSelectQuery = {
	__typename?: "Query";
	discountConnection: {
		__typename?: "DiscountConnection";
		edges: Array<{ __typename?: "DiscountEdge"; node: { __typename?: "Discount"; id: string } }>;
	};
};

export type DiscountUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: DiscountUpdateInput;
}>;

export type DiscountUpdateMutation = {
	__typename?: "Mutation";
	updateDiscount: {
		__typename?: "Discount";
		id: string;
		amount: number;
		description: string;
		serviceSubscription: { __typename?: "ServiceSubscription"; id: string };
	};
};

export type DocumentResourceBookmarkCheckQueryVariables = Exact<{
	documentResourceID: Scalars["ID"];
	userID: Scalars["ID"];
}>;

export type DocumentResourceBookmarkCheckQuery = {
	__typename?: "Query";
	documentResourceConnection: { __typename?: "DocumentResourceConnection"; totalCount: number };
};

export type BookmarkDocumentResourceMutationVariables = Exact<{
	documentResourceID: Scalars["ID"];
	userID: Scalars["ID"];
}>;

export type BookmarkDocumentResourceMutation = {
	__typename?: "Mutation";
	updateUser: { __typename?: "User"; id: string };
};

export type UnbookmarkDocumentResourceMutationVariables = Exact<{
	documentResourceID: Scalars["ID"];
	userID: Scalars["ID"];
}>;

export type UnbookmarkDocumentResourceMutation = {
	__typename?: "Mutation";
	updateUser: { __typename?: "User"; id: string };
};

export type DocumentResourceCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<DocumentResourceFilterInput> | DocumentResourceFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<DocumentResourceOrder>;
}>;

export type DocumentResourceCountQuery = {
	__typename?: "Query";
	documentResourceConnection: { __typename?: "DocumentResourceConnection"; totalCount: number };
};

export type DocumentResourceCreateMutationVariables = Exact<{
	input: DocumentResourceCreateInput;
}>;

export type DocumentResourceCreateMutation = {
	__typename?: "Mutation";
	createDocumentResource: {
		__typename?: "DocumentResource";
		id: string;
		name: string;
		description: string;
		hasImage: boolean;
		imageURL: string;
		isFile: boolean;
		fileURL: string;
		fileContentType: string;
		resourceURL: string;
		createdAt: string;
		author: { __typename?: "User"; id: string; email: string } | null;
		bookmarkingUsers: Array<{ __typename?: "User"; id: string; email: string }>;
		documentResourceFolders: Array<{ __typename?: "DocumentResourceFolder"; id: string; name: string }>;
	};
};

export type DocumentResourceDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type DocumentResourceDeleteMutation = { __typename?: "Mutation"; deleteDocumentResource: boolean };

export type DocumentResourceDetailFragment = {
	__typename?: "DocumentResource";
	id: string;
	name: string;
	description: string;
	hasImage: boolean;
	imageURL: string;
	isFile: boolean;
	fileURL: string;
	fileContentType: string;
	resourceURL: string;
	createdAt: string;
	author: { __typename?: "User"; id: string; email: string } | null;
	bookmarkingUsers: Array<{ __typename?: "User"; id: string; email: string }>;
	documentResourceFolders: Array<{ __typename?: "DocumentResourceFolder"; id: string; name: string }>;
};

export type DocumentResourceDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type DocumentResourceDetailQuery = {
	__typename?: "Query";
	documentResource: {
		__typename?: "DocumentResource";
		id: string;
		name: string;
		description: string;
		hasImage: boolean;
		imageURL: string;
		isFile: boolean;
		fileURL: string;
		fileContentType: string;
		resourceURL: string;
		createdAt: string;
		author: { __typename?: "User"; id: string; email: string } | null;
		bookmarkingUsers: Array<{ __typename?: "User"; id: string; email: string }>;
		documentResourceFolders: Array<{ __typename?: "DocumentResourceFolder"; id: string; name: string }>;
	};
};

export type DocumentResourceIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<DocumentResourceFilterInput> | DocumentResourceFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<DocumentResourceOrder>;
}>;

export type DocumentResourceIndexQuery = {
	__typename?: "Query";
	documentResourceConnection: {
		__typename?: "DocumentResourceConnection";
		edges: Array<{
			__typename?: "DocumentResourceEdge";
			node: {
				__typename?: "DocumentResource";
				id: string;
				name: string;
				description: string;
				hasImage: boolean;
				imageURL: string;
				isFile: boolean;
				fileURL: string;
				fileContentType: string;
				resourceURL: string;
			};
		}>;
	};
};

export type DocumentResourceNameFragment = { __typename?: "DocumentResource"; name: string };

export type DocumentResourceNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type DocumentResourceNameQuery = {
	__typename?: "Query";
	documentResource: { __typename?: "DocumentResource"; id: string; name: string };
};

export type DocumentResourceNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<DocumentResourceFilterInput> | DocumentResourceFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<DocumentResourceOrder>;
}>;

export type DocumentResourceNamesQuery = {
	__typename?: "Query";
	documentResourceConnection: {
		__typename?: "DocumentResourceConnection";
		edges: Array<{
			__typename?: "DocumentResourceEdge";
			node: { __typename?: "DocumentResource"; id: string; name: string };
		}>;
	};
};

export type DocumentResourcePaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<DocumentResourceFilterInput> | DocumentResourceFilterInput>;
	order?: InputMaybe<DocumentResourceOrder>;
}>;

export type DocumentResourcePaginationQuery = {
	__typename?: "Query";
	documentResourceConnection: {
		__typename?: "DocumentResourceConnection";
		totalCount: number;
		edges: Array<{ __typename?: "DocumentResourceEdge"; cursor: string }>;
	};
};

export type DocumentResourceSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<DocumentResourceFilterInput> | DocumentResourceFilterInput>;
	order?: InputMaybe<DocumentResourceOrder>;
}>;

export type DocumentResourceSelectQuery = {
	__typename?: "Query";
	documentResourceConnection: {
		__typename?: "DocumentResourceConnection";
		edges: Array<{
			__typename?: "DocumentResourceEdge";
			node: { __typename?: "DocumentResource"; id: string; name: string };
		}>;
	};
};

export type DocumentResourceUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: DocumentResourceUpdateInput;
}>;

export type DocumentResourceUpdateMutation = {
	__typename?: "Mutation";
	updateDocumentResource: {
		__typename?: "DocumentResource";
		id: string;
		name: string;
		description: string;
		hasImage: boolean;
		imageURL: string;
		isFile: boolean;
		fileURL: string;
		fileContentType: string;
		resourceURL: string;
		createdAt: string;
		author: { __typename?: "User"; id: string; email: string } | null;
		bookmarkingUsers: Array<{ __typename?: "User"; id: string; email: string }>;
		documentResourceFolders: Array<{ __typename?: "DocumentResourceFolder"; id: string; name: string }>;
	};
};

export type DocumentResourceFolderCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<DocumentResourceFolderFilterInput> | DocumentResourceFolderFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<DocumentResourceFolderOrder>;
}>;

export type DocumentResourceFolderCountQuery = {
	__typename?: "Query";
	documentResourceFolderConnection: { __typename?: "DocumentResourceFolderConnection"; totalCount: number };
};

export type DocumentResourceFolderCreateMutationVariables = Exact<{
	input: DocumentResourceFolderCreateInput;
}>;

export type DocumentResourceFolderCreateMutation = {
	__typename?: "Mutation";
	createDocumentResourceFolder: {
		__typename?: "DocumentResourceFolder";
		id: string;
		name: string;
		description: string;
		category: DocumentResourceFolderCategory;
		isPublic: boolean;
		createdAt: string;
		author: { __typename?: "User"; id: string; email: string } | null;
		childDocumentResourceFolders: Array<{ __typename?: "DocumentResourceFolder"; id: string; name: string }>;
		documentResources: Array<{
			__typename?: "DocumentResource";
			id: string;
			name: string;
			description: string;
			imageURL: string;
			isFile: boolean;
			fileURL: string;
			fileContentType: string;
			resourceURL: string;
			createdAt: string;
		}>;
		parentDocumentResourceFolder: { __typename?: "DocumentResourceFolder"; id: string; name: string } | null;
		homeRooms: Array<{ __typename?: "HomeRoom"; id: string; name: string }>;
		homeRoomSections: Array<{ __typename?: "HomeRoomSection"; id: string; name: string }>;
		courses: Array<{
			__typename?: "Course";
			id: string;
			name: string;
			subject: CourseSubject;
			homeRoom: { __typename?: "HomeRoom"; id: string; name: string };
		}>;
		courseSections: Array<{
			__typename?: "CourseSection";
			id: string;
			name: string;
			course: { __typename?: "Course"; id: string; subject: CourseSubject };
			homeRoomSection: { __typename?: "HomeRoomSection"; id: string; name: string };
		}>;
		sharingUserGroups: Array<{ __typename?: "UserGroup"; id: string; name: string }>;
		sharingUsers: Array<{ __typename?: "User"; id: string; email: string }>;
	};
};

export type DocumentResourceFolderDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type DocumentResourceFolderDeleteMutation = { __typename?: "Mutation"; deleteDocumentResourceFolder: boolean };

export type DocumentResourceFolderDetailFragment = {
	__typename?: "DocumentResourceFolder";
	id: string;
	name: string;
	description: string;
	category: DocumentResourceFolderCategory;
	isPublic: boolean;
	createdAt: string;
	author: { __typename?: "User"; id: string; email: string } | null;
	childDocumentResourceFolders: Array<{ __typename?: "DocumentResourceFolder"; id: string; name: string }>;
	documentResources: Array<{
		__typename?: "DocumentResource";
		id: string;
		name: string;
		description: string;
		imageURL: string;
		isFile: boolean;
		fileURL: string;
		fileContentType: string;
		resourceURL: string;
		createdAt: string;
	}>;
	parentDocumentResourceFolder: { __typename?: "DocumentResourceFolder"; id: string; name: string } | null;
	homeRooms: Array<{ __typename?: "HomeRoom"; id: string; name: string }>;
	homeRoomSections: Array<{ __typename?: "HomeRoomSection"; id: string; name: string }>;
	courses: Array<{
		__typename?: "Course";
		id: string;
		name: string;
		subject: CourseSubject;
		homeRoom: { __typename?: "HomeRoom"; id: string; name: string };
	}>;
	courseSections: Array<{
		__typename?: "CourseSection";
		id: string;
		name: string;
		course: { __typename?: "Course"; id: string; subject: CourseSubject };
		homeRoomSection: { __typename?: "HomeRoomSection"; id: string; name: string };
	}>;
	sharingUserGroups: Array<{ __typename?: "UserGroup"; id: string; name: string }>;
	sharingUsers: Array<{ __typename?: "User"; id: string; email: string }>;
};

export type DocumentResourceFolderDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type DocumentResourceFolderDetailQuery = {
	__typename?: "Query";
	documentResourceFolder: {
		__typename?: "DocumentResourceFolder";
		id: string;
		name: string;
		description: string;
		category: DocumentResourceFolderCategory;
		isPublic: boolean;
		createdAt: string;
		author: { __typename?: "User"; id: string; email: string } | null;
		childDocumentResourceFolders: Array<{ __typename?: "DocumentResourceFolder"; id: string; name: string }>;
		documentResources: Array<{
			__typename?: "DocumentResource";
			id: string;
			name: string;
			description: string;
			imageURL: string;
			isFile: boolean;
			fileURL: string;
			fileContentType: string;
			resourceURL: string;
			createdAt: string;
		}>;
		parentDocumentResourceFolder: { __typename?: "DocumentResourceFolder"; id: string; name: string } | null;
		homeRooms: Array<{ __typename?: "HomeRoom"; id: string; name: string }>;
		homeRoomSections: Array<{ __typename?: "HomeRoomSection"; id: string; name: string }>;
		courses: Array<{
			__typename?: "Course";
			id: string;
			name: string;
			subject: CourseSubject;
			homeRoom: { __typename?: "HomeRoom"; id: string; name: string };
		}>;
		courseSections: Array<{
			__typename?: "CourseSection";
			id: string;
			name: string;
			course: { __typename?: "Course"; id: string; subject: CourseSubject };
			homeRoomSection: { __typename?: "HomeRoomSection"; id: string; name: string };
		}>;
		sharingUserGroups: Array<{ __typename?: "UserGroup"; id: string; name: string }>;
		sharingUsers: Array<{ __typename?: "User"; id: string; email: string }>;
	};
};

export type DocumentResourceFolderIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<DocumentResourceFolderFilterInput> | DocumentResourceFolderFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<DocumentResourceFolderOrder>;
}>;

export type DocumentResourceFolderIndexQuery = {
	__typename?: "Query";
	documentResourceFolderConnection: {
		__typename?: "DocumentResourceFolderConnection";
		edges: Array<{
			__typename?: "DocumentResourceFolderEdge";
			node: {
				__typename?: "DocumentResourceFolder";
				id: string;
				name: string;
				description: string;
				category: DocumentResourceFolderCategory;
				isPublic: boolean;
				createdAt: string;
			};
		}>;
	};
};

export type DocumentResourceFolderNameFragment = { __typename?: "DocumentResourceFolder"; name: string };

export type DocumentResourceFolderNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type DocumentResourceFolderNameQuery = {
	__typename?: "Query";
	documentResourceFolder: { __typename?: "DocumentResourceFolder"; id: string; name: string };
};

export type DocumentResourceFolderNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<DocumentResourceFolderFilterInput> | DocumentResourceFolderFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<DocumentResourceFolderOrder>;
}>;

export type DocumentResourceFolderNamesQuery = {
	__typename?: "Query";
	documentResourceFolderConnection: {
		__typename?: "DocumentResourceFolderConnection";
		edges: Array<{
			__typename?: "DocumentResourceFolderEdge";
			node: { __typename?: "DocumentResourceFolder"; id: string; name: string };
		}>;
	};
};

export type DocumentResourceFolderPaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<DocumentResourceFolderFilterInput> | DocumentResourceFolderFilterInput>;
	order?: InputMaybe<DocumentResourceFolderOrder>;
}>;

export type DocumentResourceFolderPaginationQuery = {
	__typename?: "Query";
	documentResourceFolderConnection: {
		__typename?: "DocumentResourceFolderConnection";
		totalCount: number;
		edges: Array<{ __typename?: "DocumentResourceFolderEdge"; cursor: string }>;
	};
};

export type DocumentResourceFolderSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<DocumentResourceFolderFilterInput> | DocumentResourceFolderFilterInput>;
	order?: InputMaybe<DocumentResourceFolderOrder>;
}>;

export type DocumentResourceFolderSelectQuery = {
	__typename?: "Query";
	documentResourceFolderConnection: {
		__typename?: "DocumentResourceFolderConnection";
		edges: Array<{
			__typename?: "DocumentResourceFolderEdge";
			node: { __typename?: "DocumentResourceFolder"; id: string; name: string };
		}>;
	};
};

export type DocumentResourceFolderUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: DocumentResourceFolderUpdateInput;
}>;

export type DocumentResourceFolderUpdateMutation = {
	__typename?: "Mutation";
	updateDocumentResourceFolder: {
		__typename?: "DocumentResourceFolder";
		id: string;
		name: string;
		description: string;
		category: DocumentResourceFolderCategory;
		isPublic: boolean;
		createdAt: string;
		author: { __typename?: "User"; id: string; email: string } | null;
		childDocumentResourceFolders: Array<{ __typename?: "DocumentResourceFolder"; id: string; name: string }>;
		documentResources: Array<{
			__typename?: "DocumentResource";
			id: string;
			name: string;
			description: string;
			imageURL: string;
			isFile: boolean;
			fileURL: string;
			fileContentType: string;
			resourceURL: string;
			createdAt: string;
		}>;
		parentDocumentResourceFolder: { __typename?: "DocumentResourceFolder"; id: string; name: string } | null;
		homeRooms: Array<{ __typename?: "HomeRoom"; id: string; name: string }>;
		homeRoomSections: Array<{ __typename?: "HomeRoomSection"; id: string; name: string }>;
		courses: Array<{
			__typename?: "Course";
			id: string;
			name: string;
			subject: CourseSubject;
			homeRoom: { __typename?: "HomeRoom"; id: string; name: string };
		}>;
		courseSections: Array<{
			__typename?: "CourseSection";
			id: string;
			name: string;
			course: { __typename?: "Course"; id: string; subject: CourseSubject };
			homeRoomSection: { __typename?: "HomeRoomSection"; id: string; name: string };
		}>;
		sharingUserGroups: Array<{ __typename?: "UserGroup"; id: string; name: string }>;
		sharingUsers: Array<{ __typename?: "User"; id: string; email: string }>;
	};
};

export type DocumentResourceUserViewRecordCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<DocumentResourceUserViewRecordFilterInput> | DocumentResourceUserViewRecordFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<DocumentResourceUserViewRecordOrder>;
}>;

export type DocumentResourceUserViewRecordCountQuery = {
	__typename?: "Query";
	documentResourceUserViewRecordConnection: {
		__typename?: "DocumentResourceUserViewRecordConnection";
		totalCount: number;
	};
};

export type DocumentResourceUserViewRecordCreateMutationVariables = Exact<{
	input: DocumentResourceUserViewRecordCreateInput;
}>;

export type DocumentResourceUserViewRecordCreateMutation = {
	__typename?: "Mutation";
	createDocumentResourceUserViewRecord: {
		__typename?: "DocumentResourceUserViewRecord";
		id: string;
		viewedAt: string;
		documentResource: { __typename?: "DocumentResource"; id: string; name: string };
		user: { __typename?: "User"; id: string; email: string } | null;
	};
};

export type DocumentResourceUserViewRecordDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type DocumentResourceUserViewRecordDeleteMutation = {
	__typename?: "Mutation";
	deleteDocumentResourceUserViewRecord: boolean;
};

export type DocumentResourceUserViewRecordDetailFragment = {
	__typename?: "DocumentResourceUserViewRecord";
	id: string;
	viewedAt: string;
	documentResource: { __typename?: "DocumentResource"; id: string; name: string };
	user: { __typename?: "User"; id: string; email: string } | null;
};

export type DocumentResourceUserViewRecordDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type DocumentResourceUserViewRecordDetailQuery = {
	__typename?: "Query";
	documentResourceUserViewRecord: {
		__typename?: "DocumentResourceUserViewRecord";
		id: string;
		viewedAt: string;
		documentResource: { __typename?: "DocumentResource"; id: string; name: string };
		user: { __typename?: "User"; id: string; email: string } | null;
	};
};

export type DocumentResourceUserViewRecordIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<DocumentResourceUserViewRecordFilterInput> | DocumentResourceUserViewRecordFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<DocumentResourceUserViewRecordOrder>;
}>;

export type DocumentResourceUserViewRecordIndexQuery = {
	__typename?: "Query";
	documentResourceUserViewRecordConnection: {
		__typename?: "DocumentResourceUserViewRecordConnection";
		edges: Array<{
			__typename?: "DocumentResourceUserViewRecordEdge";
			node: { __typename?: "DocumentResourceUserViewRecord"; id: string; viewedAt: string };
		}>;
	};
};

export type DocumentResourceUserViewRecordNameFragment = { __typename?: "DocumentResourceUserViewRecord"; id: string };

export type DocumentResourceUserViewRecordNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type DocumentResourceUserViewRecordNameQuery = {
	__typename?: "Query";
	documentResourceUserViewRecord: { __typename?: "DocumentResourceUserViewRecord"; id: string };
};

export type DocumentResourceUserViewRecordNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<DocumentResourceUserViewRecordFilterInput> | DocumentResourceUserViewRecordFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<DocumentResourceUserViewRecordOrder>;
}>;

export type DocumentResourceUserViewRecordNamesQuery = {
	__typename?: "Query";
	documentResourceUserViewRecordConnection: {
		__typename?: "DocumentResourceUserViewRecordConnection";
		edges: Array<{
			__typename?: "DocumentResourceUserViewRecordEdge";
			node: { __typename?: "DocumentResourceUserViewRecord"; id: string };
		}>;
	};
};

export type DocumentResourceUserViewRecordPaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<DocumentResourceUserViewRecordFilterInput> | DocumentResourceUserViewRecordFilterInput>;
	order?: InputMaybe<DocumentResourceUserViewRecordOrder>;
}>;

export type DocumentResourceUserViewRecordPaginationQuery = {
	__typename?: "Query";
	documentResourceUserViewRecordConnection: {
		__typename?: "DocumentResourceUserViewRecordConnection";
		totalCount: number;
		edges: Array<{ __typename?: "DocumentResourceUserViewRecordEdge"; cursor: string }>;
	};
};

export type DocumentResourceUserViewRecordRecentsQueryVariables = Exact<{
	userID: Scalars["ID"];
	filters?: InputMaybe<Array<DocumentResourceUserViewRecordFilterInput> | DocumentResourceUserViewRecordFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<DocumentResourceUserViewRecordOrder>;
}>;

export type DocumentResourceUserViewRecordRecentsQuery = {
	__typename?: "Query";
	documentResourceUserViewRecordConnectionRecents: {
		__typename?: "DocumentResourceUserViewRecordConnection";
		edges: Array<{
			__typename?: "DocumentResourceUserViewRecordEdge";
			node: {
				__typename?: "DocumentResourceUserViewRecord";
				id: string;
				viewedAt: string;
				documentResource: {
					__typename?: "DocumentResource";
					id: string;
					name: string;
					description: string;
					hasImage: boolean;
					imageURL: string;
					isFile: boolean;
					fileURL: string;
					fileContentType: string;
					resourceURL: string;
				};
			};
		}>;
	};
};

export type DocumentResourceUserViewRecordSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<DocumentResourceUserViewRecordFilterInput> | DocumentResourceUserViewRecordFilterInput>;
	order?: InputMaybe<DocumentResourceUserViewRecordOrder>;
}>;

export type DocumentResourceUserViewRecordSelectQuery = {
	__typename?: "Query";
	documentResourceUserViewRecordConnection: {
		__typename?: "DocumentResourceUserViewRecordConnection";
		edges: Array<{
			__typename?: "DocumentResourceUserViewRecordEdge";
			node: { __typename?: "DocumentResourceUserViewRecord"; id: string };
		}>;
	};
};

export type EmailAddressCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<EmailAddressFilterInput> | EmailAddressFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<EmailAddressOrder>;
}>;

export type EmailAddressCountQuery = {
	__typename?: "Query";
	emailAddressConnection: { __typename?: "EmailAddressConnection"; totalCount: number };
};

export type EmailAddressCreateMutationVariables = Exact<{
	input: EmailAddressCreateInput;
}>;

export type EmailAddressCreateMutation = {
	__typename?: "Mutation";
	createEmailAddress: {
		__typename?: "EmailAddress";
		id: string;
		email: string;
		person: { __typename?: "Person"; id: string; name: string } | null;
	};
};

export type EmailAddressDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type EmailAddressDeleteMutation = { __typename?: "Mutation"; deleteEmailAddress: boolean };

export type EmailAddressDetailFragment = {
	__typename?: "EmailAddress";
	id: string;
	email: string;
	person: { __typename?: "Person"; id: string; name: string } | null;
};

export type EmailAddressDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type EmailAddressDetailQuery = {
	__typename?: "Query";
	emailAddress: {
		__typename?: "EmailAddress";
		id: string;
		email: string;
		person: { __typename?: "Person"; id: string; name: string } | null;
	};
};

export type EmailAddressIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<EmailAddressFilterInput> | EmailAddressFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<EmailAddressOrder>;
}>;

export type EmailAddressIndexQuery = {
	__typename?: "Query";
	emailAddressConnection: {
		__typename?: "EmailAddressConnection";
		edges: Array<{ __typename?: "EmailAddressEdge"; node: { __typename?: "EmailAddress"; id: string; email: string } }>;
	};
};

export type EmailAddressNameFragment = { __typename?: "EmailAddress"; email: string };

export type EmailAddressNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type EmailAddressNameQuery = {
	__typename?: "Query";
	emailAddress: { __typename?: "EmailAddress"; id: string; email: string };
};

export type EmailAddressNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<EmailAddressFilterInput> | EmailAddressFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<EmailAddressOrder>;
}>;

export type EmailAddressNamesQuery = {
	__typename?: "Query";
	emailAddressConnection: {
		__typename?: "EmailAddressConnection";
		edges: Array<{ __typename?: "EmailAddressEdge"; node: { __typename?: "EmailAddress"; id: string; email: string } }>;
	};
};

export type EmailAddressPaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<EmailAddressFilterInput> | EmailAddressFilterInput>;
	order?: InputMaybe<EmailAddressOrder>;
}>;

export type EmailAddressPaginationQuery = {
	__typename?: "Query";
	emailAddressConnection: {
		__typename?: "EmailAddressConnection";
		totalCount: number;
		edges: Array<{ __typename?: "EmailAddressEdge"; cursor: string }>;
	};
};

export type EmailAddressSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<EmailAddressFilterInput> | EmailAddressFilterInput>;
	order?: InputMaybe<EmailAddressOrder>;
}>;

export type EmailAddressSelectQuery = {
	__typename?: "Query";
	emailAddressConnection: {
		__typename?: "EmailAddressConnection";
		edges: Array<{ __typename?: "EmailAddressEdge"; node: { __typename?: "EmailAddress"; id: string; email: string } }>;
	};
};

export type EmailAddressUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: EmailAddressUpdateInput;
}>;

export type EmailAddressUpdateMutation = {
	__typename?: "Mutation";
	updateEmailAddress: {
		__typename?: "EmailAddress";
		id: string;
		email: string;
		person: { __typename?: "Person"; id: string; name: string } | null;
	};
};

export type EmployeeContextQueryVariables = Exact<{ [key: string]: never }>;

export type EmployeeContextQuery = {
	__typename?: "Query";
	viewer: {
		__typename?: "User";
		id: string;
		person: {
			__typename?: "Person";
			id: string;
			name: string;
			nickname: string;
			employee: {
				__typename?: "Employee";
				id: string;
				email: string;
				role: EmployeeRole;
				jobTitle: string;
				campuses: Array<{ __typename?: "Campus"; id: string; name: string }>;
			} | null;
		} | null;
	};
};

export type EmployeeCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<EmployeeFilterInput> | EmployeeFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<EmployeeOrder>;
}>;

export type EmployeeCountQuery = {
	__typename?: "Query";
	employeeConnection: { __typename?: "EmployeeConnection"; totalCount: number };
};

export type EmployeeCreateMutationVariables = Exact<{
	input: EmployeeCreateInput;
}>;

export type EmployeeCreateMutation = {
	__typename?: "Mutation";
	createEmployee: {
		__typename?: "Employee";
		id: string;
		email: string;
		jobTitle: string;
		role: EmployeeRole;
		startDate: string;
		endDate: string | null;
		person: {
			__typename?: "Person";
			id: string;
			name: string;
			firstName: string;
			lastName: string;
			nickname: string;
			alternateNames: string;
			imageURL: string;
			gender: string;
			dateOfBirth: string | null;
			occupation: string;
			nationality: string;
			primaryLanguage: string;
			englishLanguageFluency: PersonEnglishLanguageFluency;
			addresses: Array<{ __typename?: "Address"; id: string; shortAddress: string }>;
			emailAddresses: Array<{ __typename?: "EmailAddress"; id: string; email: string }>;
			employee: {
				__typename?: "Employee";
				id: string;
				person: { __typename?: "Person"; id: string; name: string };
			} | null;
			parent: { __typename?: "Parent"; id: string; person: { __typename?: "Person"; id: string; name: string } } | null;
			phoneNumbers: Array<{
				__typename?: "PhoneNumber";
				id: string;
				phoneNumber: string;
				phoneType: PhoneNumberPhoneType;
			}>;
			student: {
				__typename?: "Student";
				id: string;
				person: { __typename?: "Person"; id: string; name: string };
			} | null;
			user: { __typename?: "User"; id: string; email: string } | null;
		};
		campuses: Array<{ __typename?: "Campus"; id: string; name: string }>;
	};
};

export type EmployeeDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type EmployeeDeleteMutation = { __typename?: "Mutation"; deleteEmployee: boolean };

export type EmployeeDetailFragment = {
	__typename?: "Employee";
	id: string;
	email: string;
	jobTitle: string;
	role: EmployeeRole;
	startDate: string;
	endDate: string | null;
	person: {
		__typename?: "Person";
		id: string;
		name: string;
		firstName: string;
		lastName: string;
		nickname: string;
		alternateNames: string;
		imageURL: string;
		gender: string;
		dateOfBirth: string | null;
		occupation: string;
		nationality: string;
		primaryLanguage: string;
		englishLanguageFluency: PersonEnglishLanguageFluency;
		addresses: Array<{ __typename?: "Address"; id: string; shortAddress: string }>;
		emailAddresses: Array<{ __typename?: "EmailAddress"; id: string; email: string }>;
		employee: {
			__typename?: "Employee";
			id: string;
			person: { __typename?: "Person"; id: string; name: string };
		} | null;
		parent: { __typename?: "Parent"; id: string; person: { __typename?: "Person"; id: string; name: string } } | null;
		phoneNumbers: Array<{
			__typename?: "PhoneNumber";
			id: string;
			phoneNumber: string;
			phoneType: PhoneNumberPhoneType;
		}>;
		student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } } | null;
		user: { __typename?: "User"; id: string; email: string } | null;
	};
	campuses: Array<{ __typename?: "Campus"; id: string; name: string }>;
};

export type EmployeeDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type EmployeeDetailQuery = {
	__typename?: "Query";
	employee: {
		__typename?: "Employee";
		id: string;
		email: string;
		jobTitle: string;
		role: EmployeeRole;
		startDate: string;
		endDate: string | null;
		person: {
			__typename?: "Person";
			id: string;
			name: string;
			firstName: string;
			lastName: string;
			nickname: string;
			alternateNames: string;
			imageURL: string;
			gender: string;
			dateOfBirth: string | null;
			occupation: string;
			nationality: string;
			primaryLanguage: string;
			englishLanguageFluency: PersonEnglishLanguageFluency;
			addresses: Array<{ __typename?: "Address"; id: string; shortAddress: string }>;
			emailAddresses: Array<{ __typename?: "EmailAddress"; id: string; email: string }>;
			employee: {
				__typename?: "Employee";
				id: string;
				person: { __typename?: "Person"; id: string; name: string };
			} | null;
			parent: { __typename?: "Parent"; id: string; person: { __typename?: "Person"; id: string; name: string } } | null;
			phoneNumbers: Array<{
				__typename?: "PhoneNumber";
				id: string;
				phoneNumber: string;
				phoneType: PhoneNumberPhoneType;
			}>;
			student: {
				__typename?: "Student";
				id: string;
				person: { __typename?: "Person"; id: string; name: string };
			} | null;
			user: { __typename?: "User"; id: string; email: string } | null;
		};
		campuses: Array<{ __typename?: "Campus"; id: string; name: string }>;
	};
};

export type EmployeeIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<EmployeeFilterInput> | EmployeeFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<EmployeeOrder>;
}>;

export type EmployeeIndexQuery = {
	__typename?: "Query";
	employeeConnection: {
		__typename?: "EmployeeConnection";
		edges: Array<{
			__typename?: "EmployeeEdge";
			node: {
				__typename?: "Employee";
				id: string;
				email: string;
				jobTitle: string;
				role: EmployeeRole;
				startDate: string;
				endDate: string | null;
				person: { __typename?: "Person"; id: string; name: string; imageURL: string };
			};
		}>;
	};
};

export type EmployeeNameFragment = {
	__typename?: "Employee";
	person: { __typename?: "Person"; id: string; name: string };
};

export type EmployeeNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type EmployeeNameQuery = {
	__typename?: "Query";
	employee: { __typename?: "Employee"; id: string; person: { __typename?: "Person"; id: string; name: string } };
};

export type EmployeeNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<EmployeeFilterInput> | EmployeeFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<EmployeeOrder>;
}>;

export type EmployeeNamesQuery = {
	__typename?: "Query";
	employeeConnection: {
		__typename?: "EmployeeConnection";
		edges: Array<{
			__typename?: "EmployeeEdge";
			node: { __typename?: "Employee"; id: string; person: { __typename?: "Person"; id: string; name: string } };
		}>;
	};
};

export type EmployeePaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<EmployeeFilterInput> | EmployeeFilterInput>;
	order?: InputMaybe<EmployeeOrder>;
}>;

export type EmployeePaginationQuery = {
	__typename?: "Query";
	employeeConnection: {
		__typename?: "EmployeeConnection";
		totalCount: number;
		edges: Array<{ __typename?: "EmployeeEdge"; cursor: string }>;
	};
};

export type EmployeePreviewQueryVariables = Exact<{
	id: Scalars["ID"];
	date: Scalars["Time"];
}>;

export type EmployeePreviewQuery = {
	__typename?: "Query";
	employee: {
		__typename?: "Employee";
		id: string;
		jobTitle: string;
		role: EmployeeRole;
		person: { __typename?: "Person"; id: string; name: string; imageURL: string };
		homeRoomSectionTeacherEnrollments: Array<{
			__typename?: "HomeRoomSectionTeacherEnrollment";
			id: string;
			homeRoomSection: { __typename?: "HomeRoomSection"; id: string; name: string };
		}>;
		courseSectionTeacherEnrollments: Array<{
			__typename?: "CourseSectionTeacherEnrollment";
			id: string;
			courseSection: {
				__typename?: "CourseSection";
				id: string;
				name: string;
				course: { __typename?: "Course"; id: string; subject: CourseSubject };
				homeRoomSection: { __typename?: "HomeRoomSection"; id: string; name: string };
			};
		}>;
		campuses: Array<{ __typename?: "Campus"; id: string; name: string }>;
	};
};

export type EmployeeSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<EmployeeFilterInput> | EmployeeFilterInput>;
	order?: InputMaybe<EmployeeOrder>;
}>;

export type EmployeeSelectQuery = {
	__typename?: "Query";
	employeeConnection: {
		__typename?: "EmployeeConnection";
		edges: Array<{
			__typename?: "EmployeeEdge";
			node: { __typename?: "Employee"; id: string; person: { __typename?: "Person"; id: string; name: string } };
		}>;
	};
};

export type EmployeeUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: EmployeeUpdateInput;
}>;

export type EmployeeUpdateMutation = {
	__typename?: "Mutation";
	updateEmployee: {
		__typename?: "Employee";
		id: string;
		email: string;
		jobTitle: string;
		role: EmployeeRole;
		startDate: string;
		endDate: string | null;
		person: {
			__typename?: "Person";
			id: string;
			name: string;
			firstName: string;
			lastName: string;
			nickname: string;
			alternateNames: string;
			imageURL: string;
			gender: string;
			dateOfBirth: string | null;
			occupation: string;
			nationality: string;
			primaryLanguage: string;
			englishLanguageFluency: PersonEnglishLanguageFluency;
			addresses: Array<{ __typename?: "Address"; id: string; shortAddress: string }>;
			emailAddresses: Array<{ __typename?: "EmailAddress"; id: string; email: string }>;
			employee: {
				__typename?: "Employee";
				id: string;
				person: { __typename?: "Person"; id: string; name: string };
			} | null;
			parent: { __typename?: "Parent"; id: string; person: { __typename?: "Person"; id: string; name: string } } | null;
			phoneNumbers: Array<{
				__typename?: "PhoneNumber";
				id: string;
				phoneNumber: string;
				phoneType: PhoneNumberPhoneType;
			}>;
			student: {
				__typename?: "Student";
				id: string;
				person: { __typename?: "Person"; id: string; name: string };
			} | null;
			user: { __typename?: "User"; id: string; email: string } | null;
		};
		campuses: Array<{ __typename?: "Campus"; id: string; name: string }>;
	};
};

export type EmployeeUpdateWithPersonMutationVariables = Exact<{
	id: Scalars["ID"];
	input: EmployeeUpdateInput;
	personInput: PersonUpdateInput;
}>;

export type EmployeeUpdateWithPersonMutation = {
	__typename?: "Mutation";
	updateEmployeeWithPerson: {
		__typename?: "Employee";
		id: string;
		email: string;
		jobTitle: string;
		role: EmployeeRole;
		startDate: string;
		endDate: string | null;
		person: {
			__typename?: "Person";
			id: string;
			name: string;
			firstName: string;
			lastName: string;
			nickname: string;
			alternateNames: string;
			imageURL: string;
			gender: string;
			dateOfBirth: string | null;
			occupation: string;
			nationality: string;
			primaryLanguage: string;
			englishLanguageFluency: PersonEnglishLanguageFluency;
			addresses: Array<{ __typename?: "Address"; id: string; shortAddress: string }>;
			emailAddresses: Array<{ __typename?: "EmailAddress"; id: string; email: string }>;
			employee: {
				__typename?: "Employee";
				id: string;
				person: { __typename?: "Person"; id: string; name: string };
			} | null;
			parent: { __typename?: "Parent"; id: string; person: { __typename?: "Person"; id: string; name: string } } | null;
			phoneNumbers: Array<{
				__typename?: "PhoneNumber";
				id: string;
				phoneNumber: string;
				phoneType: PhoneNumberPhoneType;
			}>;
			student: {
				__typename?: "Student";
				id: string;
				person: { __typename?: "Person"; id: string; name: string };
			} | null;
			user: { __typename?: "User"; id: string; email: string } | null;
		};
		campuses: Array<{ __typename?: "Campus"; id: string; name: string }>;
	};
};

export type EnrollmentApplicationCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<EnrollmentApplicationFilterInput> | EnrollmentApplicationFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<EnrollmentApplicationOrder>;
}>;

export type EnrollmentApplicationCountQuery = {
	__typename?: "Query";
	enrollmentApplicationConnection: { __typename?: "EnrollmentApplicationConnection"; totalCount: number };
};

export type EnrollmentApplicationDetailFragment = {
	__typename?: "EnrollmentApplication";
	id: string;
	createdAt: string;
	status: EnrollmentApplicationStatus;
	statusUpdatedAt: string;
	approvedEmailSentAt: string | null;
	interviewMeetingTime: string | null;
	externalLink: string;
	enrollmentApplicationStudent: {
		__typename?: "EnrollmentApplicationStudent";
		id: string;
		formIdentifier: string;
		imageURL: string;
		name: string;
		firstName: string;
		lastName: string;
		nickname: string;
		dateOfBirth: string;
		gender: EnrollmentApplicationStudentGender | null;
		genderCustom: string;
		nationality: string;
		primaryLanguage: string;
		englishLanguageFluency: EnrollmentApplicationStudentEnglishLanguageFluency;
		currentGradeLevel: EnrollmentApplicationStudentCurrentGradeLevel;
		gradeLevelApplyingFor: EnrollmentApplicationStudentGradeLevelApplyingFor;
		hasPreviousSchooling: boolean;
		previousSchoolInformation: string;
		previousSchoolLocation: string;
		hasLearningDifficulties: boolean;
		learningDifficultiesDescription: string;
		hasMedicalPhysicalExceptionalities: boolean;
		medicalPhysicalExceptionalitiesDescription: string;
		hasBehaviorDisciplineChallenges: boolean;
		behaviorDisciplineChallengesDescription: string;
		additionalInformation: string;
		enrollmentApplication: { __typename?: "EnrollmentApplication"; id: string } | null;
		enrollmentApplicationSubmission: {
			__typename?: "EnrollmentApplicationSubmission";
			id: string;
			doesConsentToDataUse: boolean;
			signatureName: string;
			signatureDate: string;
			enrollmentApplicationStudents: Array<{
				__typename?: "EnrollmentApplicationStudent";
				id: string;
				name: string;
				imageURL: string;
				enrollmentApplication: { __typename?: "EnrollmentApplication"; id: string } | null;
			}>;
			enrollmentApplicationParents: Array<{
				__typename?: "EnrollmentApplicationParent";
				id: string;
				formIdentifier: string;
				name: string;
				firstName: string;
				lastName: string;
				nationality: string;
				occupation: string;
				relationshipType: EnrollmentApplicationParentRelationshipType;
				relationshipTypeCustom: string;
				isPrimaryContact: boolean;
				livesWithStudent: boolean;
				isAuthorizedForPickup: boolean;
				isEmergencyContact: boolean;
				isFinancialContact: boolean;
				addressSameAsFormIdentifier: string;
				addressLine1: string;
				addressLine2: string;
				city: string;
				stateOrProvince: string;
				postalCode: string;
				country: string;
				homePhone: string;
				workPhone: string;
				mobilePhone: string;
				email: string;
				enrollmentApplicationSubmission: { __typename?: "EnrollmentApplicationSubmission"; id: string };
			}>;
		};
		student: {
			__typename?: "Student";
			id: string;
			schoolStudentEnrollments: Array<{
				__typename?: "SchoolStudentEnrollment";
				id: string;
				createdDate: string;
				hasPaidFee: boolean;
				academicYear: { __typename?: "AcademicYear"; id: string; name: string };
			}>;
		} | null;
	};
	enrollmentApplicationNotes: Array<{
		__typename?: "EnrollmentApplicationNote";
		id: string;
		createdAt: string;
		body: string;
		author: {
			__typename?: "User";
			id: string;
			email: string;
			person: {
				__typename?: "Person";
				id: string;
				name: string;
				imageURL: string;
				employee: { __typename?: "Employee"; id: string } | null;
			} | null;
		};
		enrollmentApplication: { __typename?: "EnrollmentApplication"; id: string };
	}>;
	enrollmentApplicationUserViewRecords: Array<{
		__typename?: "EnrollmentApplicationUserViewRecord";
		id: string;
		user: {
			__typename?: "User";
			id: string;
			person: { __typename?: "Person"; id: string; name: string; imageURL: string } | null;
		};
	}>;
};

export type EnrollmentApplicationDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type EnrollmentApplicationDetailQuery = {
	__typename?: "Query";
	enrollmentApplication: {
		__typename?: "EnrollmentApplication";
		id: string;
		createdAt: string;
		status: EnrollmentApplicationStatus;
		statusUpdatedAt: string;
		approvedEmailSentAt: string | null;
		interviewMeetingTime: string | null;
		externalLink: string;
		enrollmentApplicationStudent: {
			__typename?: "EnrollmentApplicationStudent";
			id: string;
			formIdentifier: string;
			imageURL: string;
			name: string;
			firstName: string;
			lastName: string;
			nickname: string;
			dateOfBirth: string;
			gender: EnrollmentApplicationStudentGender | null;
			genderCustom: string;
			nationality: string;
			primaryLanguage: string;
			englishLanguageFluency: EnrollmentApplicationStudentEnglishLanguageFluency;
			currentGradeLevel: EnrollmentApplicationStudentCurrentGradeLevel;
			gradeLevelApplyingFor: EnrollmentApplicationStudentGradeLevelApplyingFor;
			hasPreviousSchooling: boolean;
			previousSchoolInformation: string;
			previousSchoolLocation: string;
			hasLearningDifficulties: boolean;
			learningDifficultiesDescription: string;
			hasMedicalPhysicalExceptionalities: boolean;
			medicalPhysicalExceptionalitiesDescription: string;
			hasBehaviorDisciplineChallenges: boolean;
			behaviorDisciplineChallengesDescription: string;
			additionalInformation: string;
			enrollmentApplication: { __typename?: "EnrollmentApplication"; id: string } | null;
			enrollmentApplicationSubmission: {
				__typename?: "EnrollmentApplicationSubmission";
				id: string;
				doesConsentToDataUse: boolean;
				signatureName: string;
				signatureDate: string;
				enrollmentApplicationStudents: Array<{
					__typename?: "EnrollmentApplicationStudent";
					id: string;
					name: string;
					imageURL: string;
					enrollmentApplication: { __typename?: "EnrollmentApplication"; id: string } | null;
				}>;
				enrollmentApplicationParents: Array<{
					__typename?: "EnrollmentApplicationParent";
					id: string;
					formIdentifier: string;
					name: string;
					firstName: string;
					lastName: string;
					nationality: string;
					occupation: string;
					relationshipType: EnrollmentApplicationParentRelationshipType;
					relationshipTypeCustom: string;
					isPrimaryContact: boolean;
					livesWithStudent: boolean;
					isAuthorizedForPickup: boolean;
					isEmergencyContact: boolean;
					isFinancialContact: boolean;
					addressSameAsFormIdentifier: string;
					addressLine1: string;
					addressLine2: string;
					city: string;
					stateOrProvince: string;
					postalCode: string;
					country: string;
					homePhone: string;
					workPhone: string;
					mobilePhone: string;
					email: string;
					enrollmentApplicationSubmission: { __typename?: "EnrollmentApplicationSubmission"; id: string };
				}>;
			};
			student: {
				__typename?: "Student";
				id: string;
				schoolStudentEnrollments: Array<{
					__typename?: "SchoolStudentEnrollment";
					id: string;
					createdDate: string;
					hasPaidFee: boolean;
					academicYear: { __typename?: "AcademicYear"; id: string; name: string };
				}>;
			} | null;
		};
		enrollmentApplicationNotes: Array<{
			__typename?: "EnrollmentApplicationNote";
			id: string;
			createdAt: string;
			body: string;
			author: {
				__typename?: "User";
				id: string;
				email: string;
				person: {
					__typename?: "Person";
					id: string;
					name: string;
					imageURL: string;
					employee: { __typename?: "Employee"; id: string } | null;
				} | null;
			};
			enrollmentApplication: { __typename?: "EnrollmentApplication"; id: string };
		}>;
		enrollmentApplicationUserViewRecords: Array<{
			__typename?: "EnrollmentApplicationUserViewRecord";
			id: string;
			user: {
				__typename?: "User";
				id: string;
				person: { __typename?: "Person"; id: string; name: string; imageURL: string } | null;
			};
		}>;
	};
};

export type EnrollmentApplicationIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<EnrollmentApplicationFilterInput> | EnrollmentApplicationFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<EnrollmentApplicationOrder>;
	userID: Scalars["ID"];
}>;

export type EnrollmentApplicationIndexQuery = {
	__typename?: "Query";
	enrollmentApplicationConnection: {
		__typename?: "EnrollmentApplicationConnection";
		edges: Array<{
			__typename?: "EnrollmentApplicationEdge";
			node: {
				__typename?: "EnrollmentApplication";
				id: string;
				createdAt: string;
				status: EnrollmentApplicationStatus;
				interviewMeetingTime: string | null;
				enrollmentApplicationStudent: {
					__typename?: "EnrollmentApplicationStudent";
					id: string;
					name: string;
					imageURL: string;
					dateOfBirth: string;
					gradeLevelApplyingFor: EnrollmentApplicationStudentGradeLevelApplyingFor;
					studentID: string | null;
					enrollmentApplicationSubmission: {
						__typename?: "EnrollmentApplicationSubmission";
						id: string;
						enrollmentApplicationParents: Array<{
							__typename?: "EnrollmentApplicationParent";
							id: string;
							name: string;
						}>;
					};
				};
				enrollmentApplicationUserViewRecords: Array<{
					__typename?: "EnrollmentApplicationUserViewRecord";
					id: string;
					isDetail: boolean;
				}>;
			};
		}>;
	};
};

export type EnrollmentApplicationNameFragment = { __typename?: "EnrollmentApplication"; id: string };

export type EnrollmentApplicationNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type EnrollmentApplicationNameQuery = {
	__typename?: "Query";
	enrollmentApplication: { __typename?: "EnrollmentApplication"; id: string };
};

export type EnrollmentApplicationNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<EnrollmentApplicationFilterInput> | EnrollmentApplicationFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<EnrollmentApplicationOrder>;
}>;

export type EnrollmentApplicationNamesQuery = {
	__typename?: "Query";
	enrollmentApplicationConnection: {
		__typename?: "EnrollmentApplicationConnection";
		edges: Array<{
			__typename?: "EnrollmentApplicationEdge";
			node: { __typename?: "EnrollmentApplication"; id: string };
		}>;
	};
};

export type EnrollmentApplicationNotificationListQueryVariables = Exact<{
	userID: Scalars["ID"];
}>;

export type EnrollmentApplicationNotificationListQuery = {
	__typename?: "Query";
	enrollmentApplicationConnection: {
		__typename?: "EnrollmentApplicationConnection";
		edges: Array<{
			__typename?: "EnrollmentApplicationEdge";
			node: {
				__typename?: "EnrollmentApplication";
				id: string;
				enrollmentApplicationUserViewRecords: Array<{
					__typename?: "EnrollmentApplicationUserViewRecord";
					id: string;
					isDetail: boolean;
				}>;
			};
		}>;
	};
};

export type EnrollmentApplicationPaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<EnrollmentApplicationFilterInput> | EnrollmentApplicationFilterInput>;
	order?: InputMaybe<EnrollmentApplicationOrder>;
}>;

export type EnrollmentApplicationPaginationQuery = {
	__typename?: "Query";
	enrollmentApplicationConnection: {
		__typename?: "EnrollmentApplicationConnection";
		totalCount: number;
		edges: Array<{ __typename?: "EnrollmentApplicationEdge"; cursor: string }>;
	};
};

export type EnrollmentApplicationSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<EnrollmentApplicationFilterInput> | EnrollmentApplicationFilterInput>;
	order?: InputMaybe<EnrollmentApplicationOrder>;
}>;

export type EnrollmentApplicationSelectQuery = {
	__typename?: "Query";
	enrollmentApplicationConnection: {
		__typename?: "EnrollmentApplicationConnection";
		edges: Array<{
			__typename?: "EnrollmentApplicationEdge";
			node: { __typename?: "EnrollmentApplication"; id: string };
		}>;
	};
};

export type SendEnrollmentApplicationApprovedEmailMutationVariables = Exact<{
	enrollmentApplicationID: Scalars["ID"];
}>;

export type SendEnrollmentApplicationApprovedEmailMutation = {
	__typename?: "Mutation";
	sendEnrollmentApplicationApprovedEmail: boolean;
};

export type EnrollmentApplicationUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: EnrollmentApplicationUpdateInput;
}>;

export type EnrollmentApplicationUpdateMutation = {
	__typename?: "Mutation";
	updateEnrollmentApplication: {
		__typename?: "EnrollmentApplication";
		id: string;
		createdAt: string;
		status: EnrollmentApplicationStatus;
		statusUpdatedAt: string;
		approvedEmailSentAt: string | null;
		interviewMeetingTime: string | null;
		externalLink: string;
		enrollmentApplicationStudent: {
			__typename?: "EnrollmentApplicationStudent";
			id: string;
			formIdentifier: string;
			imageURL: string;
			name: string;
			firstName: string;
			lastName: string;
			nickname: string;
			dateOfBirth: string;
			gender: EnrollmentApplicationStudentGender | null;
			genderCustom: string;
			nationality: string;
			primaryLanguage: string;
			englishLanguageFluency: EnrollmentApplicationStudentEnglishLanguageFluency;
			currentGradeLevel: EnrollmentApplicationStudentCurrentGradeLevel;
			gradeLevelApplyingFor: EnrollmentApplicationStudentGradeLevelApplyingFor;
			hasPreviousSchooling: boolean;
			previousSchoolInformation: string;
			previousSchoolLocation: string;
			hasLearningDifficulties: boolean;
			learningDifficultiesDescription: string;
			hasMedicalPhysicalExceptionalities: boolean;
			medicalPhysicalExceptionalitiesDescription: string;
			hasBehaviorDisciplineChallenges: boolean;
			behaviorDisciplineChallengesDescription: string;
			additionalInformation: string;
			enrollmentApplication: { __typename?: "EnrollmentApplication"; id: string } | null;
			enrollmentApplicationSubmission: {
				__typename?: "EnrollmentApplicationSubmission";
				id: string;
				doesConsentToDataUse: boolean;
				signatureName: string;
				signatureDate: string;
				enrollmentApplicationStudents: Array<{
					__typename?: "EnrollmentApplicationStudent";
					id: string;
					name: string;
					imageURL: string;
					enrollmentApplication: { __typename?: "EnrollmentApplication"; id: string } | null;
				}>;
				enrollmentApplicationParents: Array<{
					__typename?: "EnrollmentApplicationParent";
					id: string;
					formIdentifier: string;
					name: string;
					firstName: string;
					lastName: string;
					nationality: string;
					occupation: string;
					relationshipType: EnrollmentApplicationParentRelationshipType;
					relationshipTypeCustom: string;
					isPrimaryContact: boolean;
					livesWithStudent: boolean;
					isAuthorizedForPickup: boolean;
					isEmergencyContact: boolean;
					isFinancialContact: boolean;
					addressSameAsFormIdentifier: string;
					addressLine1: string;
					addressLine2: string;
					city: string;
					stateOrProvince: string;
					postalCode: string;
					country: string;
					homePhone: string;
					workPhone: string;
					mobilePhone: string;
					email: string;
					enrollmentApplicationSubmission: { __typename?: "EnrollmentApplicationSubmission"; id: string };
				}>;
			};
			student: {
				__typename?: "Student";
				id: string;
				schoolStudentEnrollments: Array<{
					__typename?: "SchoolStudentEnrollment";
					id: string;
					createdDate: string;
					hasPaidFee: boolean;
					academicYear: { __typename?: "AcademicYear"; id: string; name: string };
				}>;
			} | null;
		};
		enrollmentApplicationNotes: Array<{
			__typename?: "EnrollmentApplicationNote";
			id: string;
			createdAt: string;
			body: string;
			author: {
				__typename?: "User";
				id: string;
				email: string;
				person: {
					__typename?: "Person";
					id: string;
					name: string;
					imageURL: string;
					employee: { __typename?: "Employee"; id: string } | null;
				} | null;
			};
			enrollmentApplication: { __typename?: "EnrollmentApplication"; id: string };
		}>;
		enrollmentApplicationUserViewRecords: Array<{
			__typename?: "EnrollmentApplicationUserViewRecord";
			id: string;
			user: {
				__typename?: "User";
				id: string;
				person: { __typename?: "Person"; id: string; name: string; imageURL: string } | null;
			};
		}>;
	};
};

export type EnrollmentApplicationNoteCreateMutationVariables = Exact<{
	input: EnrollmentApplicationNoteCreateInput;
}>;

export type EnrollmentApplicationNoteCreateMutation = {
	__typename?: "Mutation";
	createEnrollmentApplicationNote: {
		__typename?: "EnrollmentApplicationNote";
		id: string;
		createdAt: string;
		body: string;
		author: {
			__typename?: "User";
			id: string;
			email: string;
			person: {
				__typename?: "Person";
				id: string;
				name: string;
				imageURL: string;
				employee: { __typename?: "Employee"; id: string } | null;
			} | null;
		};
		enrollmentApplication: { __typename?: "EnrollmentApplication"; id: string };
	};
};

export type EnrollmentApplicationNoteDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type EnrollmentApplicationNoteDeleteMutation = {
	__typename?: "Mutation";
	deleteEnrollmentApplicationNote: boolean;
};

export type EnrollmentApplicationNoteDetailFragment = {
	__typename?: "EnrollmentApplicationNote";
	id: string;
	createdAt: string;
	body: string;
	author: {
		__typename?: "User";
		id: string;
		email: string;
		person: {
			__typename?: "Person";
			id: string;
			name: string;
			imageURL: string;
			employee: { __typename?: "Employee"; id: string } | null;
		} | null;
	};
	enrollmentApplication: { __typename?: "EnrollmentApplication"; id: string };
};

export type EnrollmentApplicationNoteDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type EnrollmentApplicationNoteDetailQuery = {
	__typename?: "Query";
	enrollmentApplicationNote: {
		__typename?: "EnrollmentApplicationNote";
		id: string;
		createdAt: string;
		body: string;
		author: {
			__typename?: "User";
			id: string;
			email: string;
			person: {
				__typename?: "Person";
				id: string;
				name: string;
				imageURL: string;
				employee: { __typename?: "Employee"; id: string } | null;
			} | null;
		};
		enrollmentApplication: { __typename?: "EnrollmentApplication"; id: string };
	};
};

export type EnrollmentApplicationNoteIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<EnrollmentApplicationNoteFilterInput> | EnrollmentApplicationNoteFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<EnrollmentApplicationNoteOrder>;
}>;

export type EnrollmentApplicationNoteIndexQuery = {
	__typename?: "Query";
	enrollmentApplicationNoteConnection: {
		__typename?: "EnrollmentApplicationNoteConnection";
		edges: Array<{
			__typename?: "EnrollmentApplicationNoteEdge";
			node: { __typename?: "EnrollmentApplicationNote"; id: string; createdAt: string; body: string };
		}>;
	};
};

export type EnrollmentApplicationNoteNameFragment = { __typename?: "EnrollmentApplicationNote"; id: string };

export type EnrollmentApplicationNoteNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type EnrollmentApplicationNoteNameQuery = {
	__typename?: "Query";
	enrollmentApplicationNote: { __typename?: "EnrollmentApplicationNote"; id: string };
};

export type EnrollmentApplicationNoteNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<EnrollmentApplicationNoteFilterInput> | EnrollmentApplicationNoteFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<EnrollmentApplicationNoteOrder>;
}>;

export type EnrollmentApplicationNoteNamesQuery = {
	__typename?: "Query";
	enrollmentApplicationNoteConnection: {
		__typename?: "EnrollmentApplicationNoteConnection";
		edges: Array<{
			__typename?: "EnrollmentApplicationNoteEdge";
			node: { __typename?: "EnrollmentApplicationNote"; id: string };
		}>;
	};
};

export type EnrollmentApplicationNotePaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<EnrollmentApplicationNoteFilterInput> | EnrollmentApplicationNoteFilterInput>;
	order?: InputMaybe<EnrollmentApplicationNoteOrder>;
}>;

export type EnrollmentApplicationNotePaginationQuery = {
	__typename?: "Query";
	enrollmentApplicationNoteConnection: {
		__typename?: "EnrollmentApplicationNoteConnection";
		totalCount: number;
		edges: Array<{ __typename?: "EnrollmentApplicationNoteEdge"; cursor: string }>;
	};
};

export type EnrollmentApplicationNoteSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<EnrollmentApplicationNoteFilterInput> | EnrollmentApplicationNoteFilterInput>;
	order?: InputMaybe<EnrollmentApplicationNoteOrder>;
}>;

export type EnrollmentApplicationNoteSelectQuery = {
	__typename?: "Query";
	enrollmentApplicationNoteConnection: {
		__typename?: "EnrollmentApplicationNoteConnection";
		edges: Array<{
			__typename?: "EnrollmentApplicationNoteEdge";
			node: { __typename?: "EnrollmentApplicationNote"; id: string };
		}>;
	};
};

export type EnrollmentApplicationNoteUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: EnrollmentApplicationNoteUpdateInput;
}>;

export type EnrollmentApplicationNoteUpdateMutation = {
	__typename?: "Mutation";
	updateEnrollmentApplicationNote: {
		__typename?: "EnrollmentApplicationNote";
		id: string;
		createdAt: string;
		body: string;
		author: {
			__typename?: "User";
			id: string;
			email: string;
			person: {
				__typename?: "Person";
				id: string;
				name: string;
				imageURL: string;
				employee: { __typename?: "Employee"; id: string } | null;
			} | null;
		};
		enrollmentApplication: { __typename?: "EnrollmentApplication"; id: string };
	};
};

export type EnrollmentApplicationParentCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<EnrollmentApplicationParentFilterInput> | EnrollmentApplicationParentFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<EnrollmentApplicationParentOrder>;
}>;

export type EnrollmentApplicationParentCountQuery = {
	__typename?: "Query";
	enrollmentApplicationParentConnection: { __typename?: "EnrollmentApplicationParentConnection"; totalCount: number };
};

export type EnrollmentApplicationParentCreateMutationVariables = Exact<{
	input: EnrollmentApplicationParentCreateInput;
}>;

export type EnrollmentApplicationParentCreateMutation = {
	__typename?: "Mutation";
	createEnrollmentApplicationParent: {
		__typename?: "EnrollmentApplicationParent";
		id: string;
		formIdentifier: string;
		name: string;
		firstName: string;
		lastName: string;
		nationality: string;
		occupation: string;
		relationshipType: EnrollmentApplicationParentRelationshipType;
		relationshipTypeCustom: string;
		isPrimaryContact: boolean;
		livesWithStudent: boolean;
		isAuthorizedForPickup: boolean;
		isEmergencyContact: boolean;
		isFinancialContact: boolean;
		addressSameAsFormIdentifier: string;
		addressLine1: string;
		addressLine2: string;
		city: string;
		stateOrProvince: string;
		postalCode: string;
		country: string;
		homePhone: string;
		workPhone: string;
		mobilePhone: string;
		email: string;
		enrollmentApplicationSubmission: { __typename?: "EnrollmentApplicationSubmission"; id: string };
	};
};

export type EnrollmentApplicationParentDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type EnrollmentApplicationParentDeleteMutation = {
	__typename?: "Mutation";
	deleteEnrollmentApplicationParent: boolean;
};

export type EnrollmentApplicationParentDetailFragment = {
	__typename?: "EnrollmentApplicationParent";
	id: string;
	formIdentifier: string;
	name: string;
	firstName: string;
	lastName: string;
	nationality: string;
	occupation: string;
	relationshipType: EnrollmentApplicationParentRelationshipType;
	relationshipTypeCustom: string;
	isPrimaryContact: boolean;
	livesWithStudent: boolean;
	isAuthorizedForPickup: boolean;
	isEmergencyContact: boolean;
	isFinancialContact: boolean;
	addressSameAsFormIdentifier: string;
	addressLine1: string;
	addressLine2: string;
	city: string;
	stateOrProvince: string;
	postalCode: string;
	country: string;
	homePhone: string;
	workPhone: string;
	mobilePhone: string;
	email: string;
	enrollmentApplicationSubmission: { __typename?: "EnrollmentApplicationSubmission"; id: string };
};

export type EnrollmentApplicationParentDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type EnrollmentApplicationParentDetailQuery = {
	__typename?: "Query";
	enrollmentApplicationParent: {
		__typename?: "EnrollmentApplicationParent";
		id: string;
		formIdentifier: string;
		name: string;
		firstName: string;
		lastName: string;
		nationality: string;
		occupation: string;
		relationshipType: EnrollmentApplicationParentRelationshipType;
		relationshipTypeCustom: string;
		isPrimaryContact: boolean;
		livesWithStudent: boolean;
		isAuthorizedForPickup: boolean;
		isEmergencyContact: boolean;
		isFinancialContact: boolean;
		addressSameAsFormIdentifier: string;
		addressLine1: string;
		addressLine2: string;
		city: string;
		stateOrProvince: string;
		postalCode: string;
		country: string;
		homePhone: string;
		workPhone: string;
		mobilePhone: string;
		email: string;
		enrollmentApplicationSubmission: { __typename?: "EnrollmentApplicationSubmission"; id: string };
	};
};

export type EnrollmentApplicationParentIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<EnrollmentApplicationParentFilterInput> | EnrollmentApplicationParentFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<EnrollmentApplicationParentOrder>;
}>;

export type EnrollmentApplicationParentIndexQuery = {
	__typename?: "Query";
	enrollmentApplicationParentConnection: {
		__typename?: "EnrollmentApplicationParentConnection";
		edges: Array<{
			__typename?: "EnrollmentApplicationParentEdge";
			node: {
				__typename?: "EnrollmentApplicationParent";
				id: string;
				formIdentifier: string;
				name: string;
				firstName: string;
				lastName: string;
				nationality: string;
				occupation: string;
				relationshipType: EnrollmentApplicationParentRelationshipType;
				relationshipTypeCustom: string;
				isPrimaryContact: boolean;
				livesWithStudent: boolean;
				isAuthorizedForPickup: boolean;
				isEmergencyContact: boolean;
				isFinancialContact: boolean;
				addressSameAsFormIdentifier: string;
				addressLine1: string;
				addressLine2: string;
				city: string;
				stateOrProvince: string;
				postalCode: string;
				country: string;
				homePhone: string;
				workPhone: string;
				mobilePhone: string;
				email: string;
			};
		}>;
	};
};

export type EnrollmentApplicationParentNameFragment = { __typename?: "EnrollmentApplicationParent"; name: string };

export type EnrollmentApplicationParentNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type EnrollmentApplicationParentNameQuery = {
	__typename?: "Query";
	enrollmentApplicationParent: { __typename?: "EnrollmentApplicationParent"; id: string; name: string };
};

export type EnrollmentApplicationParentNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<EnrollmentApplicationParentFilterInput> | EnrollmentApplicationParentFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<EnrollmentApplicationParentOrder>;
}>;

export type EnrollmentApplicationParentNamesQuery = {
	__typename?: "Query";
	enrollmentApplicationParentConnection: {
		__typename?: "EnrollmentApplicationParentConnection";
		edges: Array<{
			__typename?: "EnrollmentApplicationParentEdge";
			node: { __typename?: "EnrollmentApplicationParent"; id: string; name: string };
		}>;
	};
};

export type EnrollmentApplicationParentPaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<EnrollmentApplicationParentFilterInput> | EnrollmentApplicationParentFilterInput>;
	order?: InputMaybe<EnrollmentApplicationParentOrder>;
}>;

export type EnrollmentApplicationParentPaginationQuery = {
	__typename?: "Query";
	enrollmentApplicationParentConnection: {
		__typename?: "EnrollmentApplicationParentConnection";
		totalCount: number;
		edges: Array<{ __typename?: "EnrollmentApplicationParentEdge"; cursor: string }>;
	};
};

export type EnrollmentApplicationParentSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<EnrollmentApplicationParentFilterInput> | EnrollmentApplicationParentFilterInput>;
	order?: InputMaybe<EnrollmentApplicationParentOrder>;
}>;

export type EnrollmentApplicationParentSelectQuery = {
	__typename?: "Query";
	enrollmentApplicationParentConnection: {
		__typename?: "EnrollmentApplicationParentConnection";
		edges: Array<{
			__typename?: "EnrollmentApplicationParentEdge";
			node: { __typename?: "EnrollmentApplicationParent"; id: string; name: string };
		}>;
	};
};

export type EnrollmentApplicationParentUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: EnrollmentApplicationParentUpdateInput;
}>;

export type EnrollmentApplicationParentUpdateMutation = {
	__typename?: "Mutation";
	updateEnrollmentApplicationParent: {
		__typename?: "EnrollmentApplicationParent";
		id: string;
		formIdentifier: string;
		name: string;
		firstName: string;
		lastName: string;
		nationality: string;
		occupation: string;
		relationshipType: EnrollmentApplicationParentRelationshipType;
		relationshipTypeCustom: string;
		isPrimaryContact: boolean;
		livesWithStudent: boolean;
		isAuthorizedForPickup: boolean;
		isEmergencyContact: boolean;
		isFinancialContact: boolean;
		addressSameAsFormIdentifier: string;
		addressLine1: string;
		addressLine2: string;
		city: string;
		stateOrProvince: string;
		postalCode: string;
		country: string;
		homePhone: string;
		workPhone: string;
		mobilePhone: string;
		email: string;
		enrollmentApplicationSubmission: { __typename?: "EnrollmentApplicationSubmission"; id: string };
	};
};

export type EnrollmentApplicationStudentCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<EnrollmentApplicationStudentFilterInput> | EnrollmentApplicationStudentFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<EnrollmentApplicationStudentOrder>;
}>;

export type EnrollmentApplicationStudentCountQuery = {
	__typename?: "Query";
	enrollmentApplicationStudentConnection: { __typename?: "EnrollmentApplicationStudentConnection"; totalCount: number };
};

export type EnrollmentApplicationStudentCreateMutationVariables = Exact<{
	input: EnrollmentApplicationStudentCreateInput;
}>;

export type EnrollmentApplicationStudentCreateMutation = {
	__typename?: "Mutation";
	createEnrollmentApplicationStudent: {
		__typename?: "EnrollmentApplicationStudent";
		id: string;
		formIdentifier: string;
		imageURL: string;
		name: string;
		firstName: string;
		lastName: string;
		nickname: string;
		dateOfBirth: string;
		gender: EnrollmentApplicationStudentGender | null;
		genderCustom: string;
		nationality: string;
		primaryLanguage: string;
		englishLanguageFluency: EnrollmentApplicationStudentEnglishLanguageFluency;
		currentGradeLevel: EnrollmentApplicationStudentCurrentGradeLevel;
		gradeLevelApplyingFor: EnrollmentApplicationStudentGradeLevelApplyingFor;
		hasPreviousSchooling: boolean;
		previousSchoolInformation: string;
		previousSchoolLocation: string;
		hasLearningDifficulties: boolean;
		learningDifficultiesDescription: string;
		hasMedicalPhysicalExceptionalities: boolean;
		medicalPhysicalExceptionalitiesDescription: string;
		hasBehaviorDisciplineChallenges: boolean;
		behaviorDisciplineChallengesDescription: string;
		additionalInformation: string;
		enrollmentApplication: { __typename?: "EnrollmentApplication"; id: string } | null;
		enrollmentApplicationSubmission: { __typename?: "EnrollmentApplicationSubmission"; id: string };
	};
};

export type EnrollmentApplicationStudentDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type EnrollmentApplicationStudentDeleteMutation = {
	__typename?: "Mutation";
	deleteEnrollmentApplicationStudent: boolean;
};

export type EnrollmentApplicationStudentDetailFragment = {
	__typename?: "EnrollmentApplicationStudent";
	id: string;
	formIdentifier: string;
	imageURL: string;
	name: string;
	firstName: string;
	lastName: string;
	nickname: string;
	dateOfBirth: string;
	gender: EnrollmentApplicationStudentGender | null;
	genderCustom: string;
	nationality: string;
	primaryLanguage: string;
	englishLanguageFluency: EnrollmentApplicationStudentEnglishLanguageFluency;
	currentGradeLevel: EnrollmentApplicationStudentCurrentGradeLevel;
	gradeLevelApplyingFor: EnrollmentApplicationStudentGradeLevelApplyingFor;
	hasPreviousSchooling: boolean;
	previousSchoolInformation: string;
	previousSchoolLocation: string;
	hasLearningDifficulties: boolean;
	learningDifficultiesDescription: string;
	hasMedicalPhysicalExceptionalities: boolean;
	medicalPhysicalExceptionalitiesDescription: string;
	hasBehaviorDisciplineChallenges: boolean;
	behaviorDisciplineChallengesDescription: string;
	additionalInformation: string;
	enrollmentApplication: { __typename?: "EnrollmentApplication"; id: string } | null;
	enrollmentApplicationSubmission: { __typename?: "EnrollmentApplicationSubmission"; id: string };
};

export type EnrollmentApplicationStudentDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type EnrollmentApplicationStudentDetailQuery = {
	__typename?: "Query";
	enrollmentApplicationStudent: {
		__typename?: "EnrollmentApplicationStudent";
		id: string;
		formIdentifier: string;
		imageURL: string;
		name: string;
		firstName: string;
		lastName: string;
		nickname: string;
		dateOfBirth: string;
		gender: EnrollmentApplicationStudentGender | null;
		genderCustom: string;
		nationality: string;
		primaryLanguage: string;
		englishLanguageFluency: EnrollmentApplicationStudentEnglishLanguageFluency;
		currentGradeLevel: EnrollmentApplicationStudentCurrentGradeLevel;
		gradeLevelApplyingFor: EnrollmentApplicationStudentGradeLevelApplyingFor;
		hasPreviousSchooling: boolean;
		previousSchoolInformation: string;
		previousSchoolLocation: string;
		hasLearningDifficulties: boolean;
		learningDifficultiesDescription: string;
		hasMedicalPhysicalExceptionalities: boolean;
		medicalPhysicalExceptionalitiesDescription: string;
		hasBehaviorDisciplineChallenges: boolean;
		behaviorDisciplineChallengesDescription: string;
		additionalInformation: string;
		enrollmentApplication: { __typename?: "EnrollmentApplication"; id: string } | null;
		enrollmentApplicationSubmission: { __typename?: "EnrollmentApplicationSubmission"; id: string };
	};
};

export type EnrollmentApplicationStudentIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<EnrollmentApplicationStudentFilterInput> | EnrollmentApplicationStudentFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<EnrollmentApplicationStudentOrder>;
}>;

export type EnrollmentApplicationStudentIndexQuery = {
	__typename?: "Query";
	enrollmentApplicationStudentConnection: {
		__typename?: "EnrollmentApplicationStudentConnection";
		edges: Array<{
			__typename?: "EnrollmentApplicationStudentEdge";
			node: {
				__typename?: "EnrollmentApplicationStudent";
				id: string;
				imageURL: string;
				name: string;
				firstName: string;
				lastName: string;
				nickname: string;
				dateOfBirth: string;
				gender: EnrollmentApplicationStudentGender | null;
				genderCustom: string;
				nationality: string;
				primaryLanguage: string;
				englishLanguageFluency: EnrollmentApplicationStudentEnglishLanguageFluency;
				currentGradeLevel: EnrollmentApplicationStudentCurrentGradeLevel;
				gradeLevelApplyingFor: EnrollmentApplicationStudentGradeLevelApplyingFor;
				hasPreviousSchooling: boolean;
				previousSchoolInformation: string;
				previousSchoolLocation: string;
				hasLearningDifficulties: boolean;
				learningDifficultiesDescription: string;
				hasMedicalPhysicalExceptionalities: boolean;
				medicalPhysicalExceptionalitiesDescription: string;
				hasBehaviorDisciplineChallenges: boolean;
				behaviorDisciplineChallengesDescription: string;
				additionalInformation: string;
			};
		}>;
	};
};

export type EnrollmentApplicationStudentNameFragment = { __typename?: "EnrollmentApplicationStudent"; name: string };

export type EnrollmentApplicationStudentNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type EnrollmentApplicationStudentNameQuery = {
	__typename?: "Query";
	enrollmentApplicationStudent: { __typename?: "EnrollmentApplicationStudent"; id: string; name: string };
};

export type EnrollmentApplicationStudentNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<EnrollmentApplicationStudentFilterInput> | EnrollmentApplicationStudentFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<EnrollmentApplicationStudentOrder>;
}>;

export type EnrollmentApplicationStudentNamesQuery = {
	__typename?: "Query";
	enrollmentApplicationStudentConnection: {
		__typename?: "EnrollmentApplicationStudentConnection";
		edges: Array<{
			__typename?: "EnrollmentApplicationStudentEdge";
			node: { __typename?: "EnrollmentApplicationStudent"; id: string; name: string };
		}>;
	};
};

export type EnrollmentApplicationStudentPaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<EnrollmentApplicationStudentFilterInput> | EnrollmentApplicationStudentFilterInput>;
	order?: InputMaybe<EnrollmentApplicationStudentOrder>;
}>;

export type EnrollmentApplicationStudentPaginationQuery = {
	__typename?: "Query";
	enrollmentApplicationStudentConnection: {
		__typename?: "EnrollmentApplicationStudentConnection";
		totalCount: number;
		edges: Array<{ __typename?: "EnrollmentApplicationStudentEdge"; cursor: string }>;
	};
};

export type EnrollmentApplicationStudentSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<EnrollmentApplicationStudentFilterInput> | EnrollmentApplicationStudentFilterInput>;
	order?: InputMaybe<EnrollmentApplicationStudentOrder>;
}>;

export type EnrollmentApplicationStudentSelectQuery = {
	__typename?: "Query";
	enrollmentApplicationStudentConnection: {
		__typename?: "EnrollmentApplicationStudentConnection";
		edges: Array<{
			__typename?: "EnrollmentApplicationStudentEdge";
			node: { __typename?: "EnrollmentApplicationStudent"; id: string; name: string };
		}>;
	};
};

export type EnrollmentApplicationStudentUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: EnrollmentApplicationStudentUpdateInput;
}>;

export type EnrollmentApplicationStudentUpdateMutation = {
	__typename?: "Mutation";
	updateEnrollmentApplicationStudent: {
		__typename?: "EnrollmentApplicationStudent";
		id: string;
		formIdentifier: string;
		imageURL: string;
		name: string;
		firstName: string;
		lastName: string;
		nickname: string;
		dateOfBirth: string;
		gender: EnrollmentApplicationStudentGender | null;
		genderCustom: string;
		nationality: string;
		primaryLanguage: string;
		englishLanguageFluency: EnrollmentApplicationStudentEnglishLanguageFluency;
		currentGradeLevel: EnrollmentApplicationStudentCurrentGradeLevel;
		gradeLevelApplyingFor: EnrollmentApplicationStudentGradeLevelApplyingFor;
		hasPreviousSchooling: boolean;
		previousSchoolInformation: string;
		previousSchoolLocation: string;
		hasLearningDifficulties: boolean;
		learningDifficultiesDescription: string;
		hasMedicalPhysicalExceptionalities: boolean;
		medicalPhysicalExceptionalitiesDescription: string;
		hasBehaviorDisciplineChallenges: boolean;
		behaviorDisciplineChallengesDescription: string;
		additionalInformation: string;
		enrollmentApplication: { __typename?: "EnrollmentApplication"; id: string } | null;
		enrollmentApplicationSubmission: { __typename?: "EnrollmentApplicationSubmission"; id: string };
	};
};

export type EnrollmentApplicationSubmissionCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<EnrollmentApplicationSubmissionFilterInput> | EnrollmentApplicationSubmissionFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<EnrollmentApplicationSubmissionOrder>;
}>;

export type EnrollmentApplicationSubmissionCountQuery = {
	__typename?: "Query";
	enrollmentApplicationSubmissionConnection: {
		__typename?: "EnrollmentApplicationSubmissionConnection";
		totalCount: number;
	};
};

export type EnrollmentApplicationSubmissionDetailFragment = {
	__typename?: "EnrollmentApplicationSubmission";
	id: string;
	createdAt: string;
	doesConsentToDataUse: boolean;
	signatureName: string;
	signatureDate: string;
	source: string;
	enrollmentApplicationStudents: Array<{
		__typename?: "EnrollmentApplicationStudent";
		id: string;
		formIdentifier: string;
		imageURL: string;
		name: string;
		firstName: string;
		lastName: string;
		nickname: string;
		dateOfBirth: string;
		gender: EnrollmentApplicationStudentGender | null;
		genderCustom: string;
		nationality: string;
		primaryLanguage: string;
		englishLanguageFluency: EnrollmentApplicationStudentEnglishLanguageFluency;
		currentGradeLevel: EnrollmentApplicationStudentCurrentGradeLevel;
		gradeLevelApplyingFor: EnrollmentApplicationStudentGradeLevelApplyingFor;
		hasPreviousSchooling: boolean;
		previousSchoolInformation: string;
		previousSchoolLocation: string;
		hasLearningDifficulties: boolean;
		learningDifficultiesDescription: string;
		hasMedicalPhysicalExceptionalities: boolean;
		medicalPhysicalExceptionalitiesDescription: string;
		hasBehaviorDisciplineChallenges: boolean;
		behaviorDisciplineChallengesDescription: string;
		additionalInformation: string;
		enrollmentApplication: { __typename?: "EnrollmentApplication"; id: string } | null;
		enrollmentApplicationSubmission: { __typename?: "EnrollmentApplicationSubmission"; id: string };
	}>;
	enrollmentApplicationParents: Array<{
		__typename?: "EnrollmentApplicationParent";
		id: string;
		formIdentifier: string;
		name: string;
		firstName: string;
		lastName: string;
		nationality: string;
		occupation: string;
		relationshipType: EnrollmentApplicationParentRelationshipType;
		relationshipTypeCustom: string;
		isPrimaryContact: boolean;
		livesWithStudent: boolean;
		isAuthorizedForPickup: boolean;
		isEmergencyContact: boolean;
		isFinancialContact: boolean;
		addressSameAsFormIdentifier: string;
		addressLine1: string;
		addressLine2: string;
		city: string;
		stateOrProvince: string;
		postalCode: string;
		country: string;
		homePhone: string;
		workPhone: string;
		mobilePhone: string;
		email: string;
		enrollmentApplicationSubmission: { __typename?: "EnrollmentApplicationSubmission"; id: string };
	}>;
};

export type EnrollmentApplicationSubmissionDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type EnrollmentApplicationSubmissionDetailQuery = {
	__typename?: "Query";
	enrollmentApplicationSubmission: {
		__typename?: "EnrollmentApplicationSubmission";
		id: string;
		createdAt: string;
		doesConsentToDataUse: boolean;
		signatureName: string;
		signatureDate: string;
		source: string;
		enrollmentApplicationStudents: Array<{
			__typename?: "EnrollmentApplicationStudent";
			id: string;
			formIdentifier: string;
			imageURL: string;
			name: string;
			firstName: string;
			lastName: string;
			nickname: string;
			dateOfBirth: string;
			gender: EnrollmentApplicationStudentGender | null;
			genderCustom: string;
			nationality: string;
			primaryLanguage: string;
			englishLanguageFluency: EnrollmentApplicationStudentEnglishLanguageFluency;
			currentGradeLevel: EnrollmentApplicationStudentCurrentGradeLevel;
			gradeLevelApplyingFor: EnrollmentApplicationStudentGradeLevelApplyingFor;
			hasPreviousSchooling: boolean;
			previousSchoolInformation: string;
			previousSchoolLocation: string;
			hasLearningDifficulties: boolean;
			learningDifficultiesDescription: string;
			hasMedicalPhysicalExceptionalities: boolean;
			medicalPhysicalExceptionalitiesDescription: string;
			hasBehaviorDisciplineChallenges: boolean;
			behaviorDisciplineChallengesDescription: string;
			additionalInformation: string;
			enrollmentApplication: { __typename?: "EnrollmentApplication"; id: string } | null;
			enrollmentApplicationSubmission: { __typename?: "EnrollmentApplicationSubmission"; id: string };
		}>;
		enrollmentApplicationParents: Array<{
			__typename?: "EnrollmentApplicationParent";
			id: string;
			formIdentifier: string;
			name: string;
			firstName: string;
			lastName: string;
			nationality: string;
			occupation: string;
			relationshipType: EnrollmentApplicationParentRelationshipType;
			relationshipTypeCustom: string;
			isPrimaryContact: boolean;
			livesWithStudent: boolean;
			isAuthorizedForPickup: boolean;
			isEmergencyContact: boolean;
			isFinancialContact: boolean;
			addressSameAsFormIdentifier: string;
			addressLine1: string;
			addressLine2: string;
			city: string;
			stateOrProvince: string;
			postalCode: string;
			country: string;
			homePhone: string;
			workPhone: string;
			mobilePhone: string;
			email: string;
			enrollmentApplicationSubmission: { __typename?: "EnrollmentApplicationSubmission"; id: string };
		}>;
	};
};

export type EnrollmentApplicationSubmissionIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<EnrollmentApplicationSubmissionFilterInput> | EnrollmentApplicationSubmissionFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<EnrollmentApplicationSubmissionOrder>;
}>;

export type EnrollmentApplicationSubmissionIndexQuery = {
	__typename?: "Query";
	enrollmentApplicationSubmissionConnection: {
		__typename?: "EnrollmentApplicationSubmissionConnection";
		edges: Array<{
			__typename?: "EnrollmentApplicationSubmissionEdge";
			node: { __typename?: "EnrollmentApplicationSubmission"; id: string; createdAt: string };
		}>;
	};
};

export type EnrollmentApplicationSubmissionNameFragment = {
	__typename?: "EnrollmentApplicationSubmission";
	id: string;
};

export type EnrollmentApplicationSubmissionNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type EnrollmentApplicationSubmissionNameQuery = {
	__typename?: "Query";
	enrollmentApplicationSubmission: { __typename?: "EnrollmentApplicationSubmission"; id: string };
};

export type EnrollmentApplicationSubmissionNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<EnrollmentApplicationSubmissionFilterInput> | EnrollmentApplicationSubmissionFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<EnrollmentApplicationSubmissionOrder>;
}>;

export type EnrollmentApplicationSubmissionNamesQuery = {
	__typename?: "Query";
	enrollmentApplicationSubmissionConnection: {
		__typename?: "EnrollmentApplicationSubmissionConnection";
		edges: Array<{
			__typename?: "EnrollmentApplicationSubmissionEdge";
			node: { __typename?: "EnrollmentApplicationSubmission"; id: string };
		}>;
	};
};

export type EnrollmentApplicationSubmissionPaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<EnrollmentApplicationSubmissionFilterInput> | EnrollmentApplicationSubmissionFilterInput>;
	order?: InputMaybe<EnrollmentApplicationSubmissionOrder>;
}>;

export type EnrollmentApplicationSubmissionPaginationQuery = {
	__typename?: "Query";
	enrollmentApplicationSubmissionConnection: {
		__typename?: "EnrollmentApplicationSubmissionConnection";
		totalCount: number;
		edges: Array<{ __typename?: "EnrollmentApplicationSubmissionEdge"; cursor: string }>;
	};
};

export type EnrollmentApplicationSubmissionSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<EnrollmentApplicationSubmissionFilterInput> | EnrollmentApplicationSubmissionFilterInput>;
	order?: InputMaybe<EnrollmentApplicationSubmissionOrder>;
}>;

export type EnrollmentApplicationSubmissionSelectQuery = {
	__typename?: "Query";
	enrollmentApplicationSubmissionConnection: {
		__typename?: "EnrollmentApplicationSubmissionConnection";
		edges: Array<{
			__typename?: "EnrollmentApplicationSubmissionEdge";
			node: { __typename?: "EnrollmentApplicationSubmission"; id: string };
		}>;
	};
};

export type EnrollmentApplicationSubmissionUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: EnrollmentApplicationSubmissionUpdateInput;
}>;

export type EnrollmentApplicationSubmissionUpdateMutation = {
	__typename?: "Mutation";
	updateEnrollmentApplicationSubmission: {
		__typename?: "EnrollmentApplicationSubmission";
		id: string;
		createdAt: string;
		doesConsentToDataUse: boolean;
		signatureName: string;
		signatureDate: string;
		source: string;
		enrollmentApplicationStudents: Array<{
			__typename?: "EnrollmentApplicationStudent";
			id: string;
			formIdentifier: string;
			imageURL: string;
			name: string;
			firstName: string;
			lastName: string;
			nickname: string;
			dateOfBirth: string;
			gender: EnrollmentApplicationStudentGender | null;
			genderCustom: string;
			nationality: string;
			primaryLanguage: string;
			englishLanguageFluency: EnrollmentApplicationStudentEnglishLanguageFluency;
			currentGradeLevel: EnrollmentApplicationStudentCurrentGradeLevel;
			gradeLevelApplyingFor: EnrollmentApplicationStudentGradeLevelApplyingFor;
			hasPreviousSchooling: boolean;
			previousSchoolInformation: string;
			previousSchoolLocation: string;
			hasLearningDifficulties: boolean;
			learningDifficultiesDescription: string;
			hasMedicalPhysicalExceptionalities: boolean;
			medicalPhysicalExceptionalitiesDescription: string;
			hasBehaviorDisciplineChallenges: boolean;
			behaviorDisciplineChallengesDescription: string;
			additionalInformation: string;
			enrollmentApplication: { __typename?: "EnrollmentApplication"; id: string } | null;
			enrollmentApplicationSubmission: { __typename?: "EnrollmentApplicationSubmission"; id: string };
		}>;
		enrollmentApplicationParents: Array<{
			__typename?: "EnrollmentApplicationParent";
			id: string;
			formIdentifier: string;
			name: string;
			firstName: string;
			lastName: string;
			nationality: string;
			occupation: string;
			relationshipType: EnrollmentApplicationParentRelationshipType;
			relationshipTypeCustom: string;
			isPrimaryContact: boolean;
			livesWithStudent: boolean;
			isAuthorizedForPickup: boolean;
			isEmergencyContact: boolean;
			isFinancialContact: boolean;
			addressSameAsFormIdentifier: string;
			addressLine1: string;
			addressLine2: string;
			city: string;
			stateOrProvince: string;
			postalCode: string;
			country: string;
			homePhone: string;
			workPhone: string;
			mobilePhone: string;
			email: string;
			enrollmentApplicationSubmission: { __typename?: "EnrollmentApplicationSubmission"; id: string };
		}>;
	};
};

export type EnrollmentApplicationUserViewRecordCountQueryVariables = Exact<{
	filters?: InputMaybe<
		Array<EnrollmentApplicationUserViewRecordFilterInput> | EnrollmentApplicationUserViewRecordFilterInput
	>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<EnrollmentApplicationUserViewRecordOrder>;
}>;

export type EnrollmentApplicationUserViewRecordCountQuery = {
	__typename?: "Query";
	enrollmentApplicationUserViewRecordConnection: {
		__typename?: "EnrollmentApplicationUserViewRecordConnection";
		totalCount: number;
	};
};

export type EnrollmentApplicationUserViewRecordDetailFragment = {
	__typename?: "EnrollmentApplicationUserViewRecord";
	id: string;
	createdAt: string;
	isDetail: boolean;
	enrollmentApplication: { __typename?: "EnrollmentApplication"; id: string };
	user: { __typename?: "User"; id: string; email: string };
};

export type EnrollmentApplicationUserViewRecordDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type EnrollmentApplicationUserViewRecordDetailQuery = {
	__typename?: "Query";
	enrollmentApplicationUserViewRecord: {
		__typename?: "EnrollmentApplicationUserViewRecord";
		id: string;
		createdAt: string;
		isDetail: boolean;
		enrollmentApplication: { __typename?: "EnrollmentApplication"; id: string };
		user: { __typename?: "User"; id: string; email: string };
	};
};

export type EnrollmentApplicationUserViewRecordIndexQueryVariables = Exact<{
	filters?: InputMaybe<
		Array<EnrollmentApplicationUserViewRecordFilterInput> | EnrollmentApplicationUserViewRecordFilterInput
	>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<EnrollmentApplicationUserViewRecordOrder>;
}>;

export type EnrollmentApplicationUserViewRecordIndexQuery = {
	__typename?: "Query";
	enrollmentApplicationUserViewRecordConnection: {
		__typename?: "EnrollmentApplicationUserViewRecordConnection";
		edges: Array<{
			__typename?: "EnrollmentApplicationUserViewRecordEdge";
			node: { __typename?: "EnrollmentApplicationUserViewRecord"; id: string; createdAt: string; isDetail: boolean };
		}>;
	};
};

export type EnrollmentApplicationUserViewRecordNameFragment = {
	__typename?: "EnrollmentApplicationUserViewRecord";
	id: string;
};

export type EnrollmentApplicationUserViewRecordNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type EnrollmentApplicationUserViewRecordNameQuery = {
	__typename?: "Query";
	enrollmentApplicationUserViewRecord: { __typename?: "EnrollmentApplicationUserViewRecord"; id: string };
};

export type EnrollmentApplicationUserViewRecordNamesQueryVariables = Exact<{
	filters?: InputMaybe<
		Array<EnrollmentApplicationUserViewRecordFilterInput> | EnrollmentApplicationUserViewRecordFilterInput
	>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<EnrollmentApplicationUserViewRecordOrder>;
}>;

export type EnrollmentApplicationUserViewRecordNamesQuery = {
	__typename?: "Query";
	enrollmentApplicationUserViewRecordConnection: {
		__typename?: "EnrollmentApplicationUserViewRecordConnection";
		edges: Array<{
			__typename?: "EnrollmentApplicationUserViewRecordEdge";
			node: { __typename?: "EnrollmentApplicationUserViewRecord"; id: string };
		}>;
	};
};

export type EnrollmentApplicationUserViewRecordPaginationQueryVariables = Exact<{
	filters?: InputMaybe<
		Array<EnrollmentApplicationUserViewRecordFilterInput> | EnrollmentApplicationUserViewRecordFilterInput
	>;
	order?: InputMaybe<EnrollmentApplicationUserViewRecordOrder>;
}>;

export type EnrollmentApplicationUserViewRecordPaginationQuery = {
	__typename?: "Query";
	enrollmentApplicationUserViewRecordConnection: {
		__typename?: "EnrollmentApplicationUserViewRecordConnection";
		totalCount: number;
		edges: Array<{ __typename?: "EnrollmentApplicationUserViewRecordEdge"; cursor: string }>;
	};
};

export type RecordEnrollmentApplicationListViewMutationVariables = Exact<{
	ids: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type RecordEnrollmentApplicationListViewMutation = {
	__typename?: "Mutation";
	recordEnrollmentApplicationListView: boolean;
};

export type EnrollmentApplicationUserViewRecordSelectQueryVariables = Exact<{
	filters?: InputMaybe<
		Array<EnrollmentApplicationUserViewRecordFilterInput> | EnrollmentApplicationUserViewRecordFilterInput
	>;
	order?: InputMaybe<EnrollmentApplicationUserViewRecordOrder>;
}>;

export type EnrollmentApplicationUserViewRecordSelectQuery = {
	__typename?: "Query";
	enrollmentApplicationUserViewRecordConnection: {
		__typename?: "EnrollmentApplicationUserViewRecordConnection";
		edges: Array<{
			__typename?: "EnrollmentApplicationUserViewRecordEdge";
			node: { __typename?: "EnrollmentApplicationUserViewRecord"; id: string };
		}>;
	};
};

export type EnrollmentInvitationCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<EnrollmentInvitationFilterInput> | EnrollmentInvitationFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<EnrollmentInvitationOrder>;
}>;

export type EnrollmentInvitationCountQuery = {
	__typename?: "Query";
	enrollmentInvitationConnection: { __typename?: "EnrollmentInvitationConnection"; totalCount: number };
};

export type EnrollmentInvitationCreateMutationVariables = Exact<{
	input: EnrollmentInvitationCreateInput;
}>;

export type EnrollmentInvitationCreateMutation = {
	__typename?: "Mutation";
	createEnrollmentInvitation: {
		__typename?: "EnrollmentInvitation";
		id: string;
		createdAt: string;
		response: EnrollmentInvitationResponse;
		student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } } | null;
	};
};

export type EnrollmentInvitationDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type EnrollmentInvitationDeleteMutation = { __typename?: "Mutation"; deleteEnrollmentInvitation: boolean };

export type EnrollmentInvitationDetailFieldsFragment = {
	__typename?: "EnrollmentInvitation";
	id: string;
	createdAt: string;
	response: EnrollmentInvitationResponse;
	student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } } | null;
};

export type EnrollmentInvitationDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type EnrollmentInvitationDetailQuery = {
	__typename?: "Query";
	enrollmentInvitation: {
		__typename?: "EnrollmentInvitation";
		id: string;
		createdAt: string;
		response: EnrollmentInvitationResponse;
		student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } } | null;
	};
};

export type EnrollmentInvitationIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<EnrollmentInvitationFilterInput> | EnrollmentInvitationFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<EnrollmentInvitationOrder>;
}>;

export type EnrollmentInvitationIndexQuery = {
	__typename?: "Query";
	enrollmentInvitationConnection: {
		__typename?: "EnrollmentInvitationConnection";
		edges: Array<{
			__typename?: "EnrollmentInvitationEdge";
			node: {
				__typename?: "EnrollmentInvitation";
				id: string;
				createdAt: string;
				response: EnrollmentInvitationResponse;
				student: {
					__typename?: "Student";
					id: string;
					person: { __typename?: "Person"; id: string; name: string; imageURL: string };
				} | null;
			};
		}>;
	};
};

export type EnrollmentInvitationNameFragment = { __typename?: "EnrollmentInvitation"; id: string };

export type EnrollmentInvitationNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type EnrollmentInvitationNameQuery = {
	__typename?: "Query";
	enrollmentInvitation: { __typename?: "EnrollmentInvitation"; id: string };
};

export type EnrollmentInvitationNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<EnrollmentInvitationFilterInput> | EnrollmentInvitationFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<EnrollmentInvitationOrder>;
}>;

export type EnrollmentInvitationNamesQuery = {
	__typename?: "Query";
	enrollmentInvitationConnection: {
		__typename?: "EnrollmentInvitationConnection";
		edges: Array<{
			__typename?: "EnrollmentInvitationEdge";
			node: { __typename?: "EnrollmentInvitation"; id: string };
		}>;
	};
};

export type EnrollmentInvitationPaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<EnrollmentInvitationFilterInput> | EnrollmentInvitationFilterInput>;
	order?: InputMaybe<EnrollmentInvitationOrder>;
}>;

export type EnrollmentInvitationPaginationQuery = {
	__typename?: "Query";
	enrollmentInvitationConnection: {
		__typename?: "EnrollmentInvitationConnection";
		totalCount: number;
		edges: Array<{ __typename?: "EnrollmentInvitationEdge"; cursor: string }>;
	};
};

export type EnrollmentInvitationSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<EnrollmentInvitationFilterInput> | EnrollmentInvitationFilterInput>;
	order?: InputMaybe<EnrollmentInvitationOrder>;
}>;

export type EnrollmentInvitationSelectQuery = {
	__typename?: "Query";
	enrollmentInvitationConnection: {
		__typename?: "EnrollmentInvitationConnection";
		edges: Array<{
			__typename?: "EnrollmentInvitationEdge";
			node: { __typename?: "EnrollmentInvitation"; id: string };
		}>;
	};
};

export type EnrollmentInvitationUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: EnrollmentInvitationUpdateInput;
}>;

export type EnrollmentInvitationUpdateMutation = {
	__typename?: "Mutation";
	updateEnrollmentInvitation: {
		__typename?: "EnrollmentInvitation";
		id: string;
		createdAt: string;
		response: EnrollmentInvitationResponse;
		student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } } | null;
	};
};

export type ExpenseCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<ExpenseFilterInput> | ExpenseFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<ExpenseOrder>;
}>;

export type ExpenseCountQuery = {
	__typename?: "Query";
	expenseConnection: { __typename?: "ExpenseConnection"; totalCount: number };
};

export type ExpenseCreateMutationVariables = Exact<{
	input: ExpenseCreateInput;
}>;

export type ExpenseCreateMutation = {
	__typename?: "Mutation";
	createExpense: {
		__typename?: "Expense";
		id: string;
		createdDate: string;
		amount: number;
		notes: string;
		expenseCategory: { __typename?: "ExpenseCategory"; id: string; name: string };
	};
};

export type ExpenseDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type ExpenseDeleteMutation = { __typename?: "Mutation"; deleteExpense: boolean };

export type ExpenseDetailFragment = {
	__typename?: "Expense";
	id: string;
	createdDate: string;
	amount: number;
	notes: string;
	expenseCategory: { __typename?: "ExpenseCategory"; id: string; name: string };
};

export type ExpenseDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type ExpenseDetailQuery = {
	__typename?: "Query";
	expense: {
		__typename?: "Expense";
		id: string;
		createdDate: string;
		amount: number;
		notes: string;
		expenseCategory: { __typename?: "ExpenseCategory"; id: string; name: string };
	};
};

export type ExpenseIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<ExpenseFilterInput> | ExpenseFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<ExpenseOrder>;
}>;

export type ExpenseIndexQuery = {
	__typename?: "Query";
	expenseConnection: {
		__typename?: "ExpenseConnection";
		edges: Array<{
			__typename?: "ExpenseEdge";
			node: {
				__typename?: "Expense";
				id: string;
				createdDate: string;
				amount: number;
				notes: string;
				expenseCategory: { __typename?: "ExpenseCategory"; id: string; name: string };
			};
		}>;
	};
};

export type ExpenseNameFragment = { __typename?: "Expense"; id: string };

export type ExpenseNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type ExpenseNameQuery = { __typename?: "Query"; expense: { __typename?: "Expense"; id: string } };

export type ExpenseNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<ExpenseFilterInput> | ExpenseFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<ExpenseOrder>;
}>;

export type ExpenseNamesQuery = {
	__typename?: "Query";
	expenseConnection: {
		__typename?: "ExpenseConnection";
		edges: Array<{ __typename?: "ExpenseEdge"; node: { __typename?: "Expense"; id: string } }>;
	};
};

export type ExpensePaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<ExpenseFilterInput> | ExpenseFilterInput>;
	order?: InputMaybe<ExpenseOrder>;
}>;

export type ExpensePaginationQuery = {
	__typename?: "Query";
	expenseConnection: {
		__typename?: "ExpenseConnection";
		totalCount: number;
		edges: Array<{ __typename?: "ExpenseEdge"; cursor: string }>;
	};
};

export type ExpenseSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<ExpenseFilterInput> | ExpenseFilterInput>;
	order?: InputMaybe<ExpenseOrder>;
}>;

export type ExpenseSelectQuery = {
	__typename?: "Query";
	expenseConnection: {
		__typename?: "ExpenseConnection";
		edges: Array<{ __typename?: "ExpenseEdge"; node: { __typename?: "Expense"; id: string } }>;
	};
};

export type ExpenseUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: ExpenseUpdateInput;
}>;

export type ExpenseUpdateMutation = {
	__typename?: "Mutation";
	updateExpense: {
		__typename?: "Expense";
		id: string;
		createdDate: string;
		amount: number;
		notes: string;
		expenseCategory: { __typename?: "ExpenseCategory"; id: string; name: string };
	};
};

export type ExpenseBudgetCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<ExpenseBudgetFilterInput> | ExpenseBudgetFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<ExpenseBudgetOrder>;
}>;

export type ExpenseBudgetCountQuery = {
	__typename?: "Query";
	expenseBudgetConnection: { __typename?: "ExpenseBudgetConnection"; totalCount: number };
};

export type ExpenseBudgetsCreateBulkMutationVariables = Exact<{
	inputs: Array<ExpenseBudgetCreateInput> | ExpenseBudgetCreateInput;
}>;

export type ExpenseBudgetsCreateBulkMutation = {
	__typename?: "Mutation";
	createBulkExpenseBudgets: {
		__typename?: "ExpenseBudgetConnection";
		edges: Array<{
			__typename?: "ExpenseBudgetEdge";
			node: {
				__typename?: "ExpenseBudget";
				id: string;
				budgetMonth: string;
				amount: number;
				expenseCategory: { __typename?: "ExpenseCategory"; id: string; name: string };
			};
		}>;
	};
};

export type ExpenseBudgetCreateMutationVariables = Exact<{
	input: ExpenseBudgetCreateInput;
}>;

export type ExpenseBudgetCreateMutation = {
	__typename?: "Mutation";
	createExpenseBudget: {
		__typename?: "ExpenseBudget";
		id: string;
		budgetMonth: string;
		amount: number;
		expenseCategory: { __typename?: "ExpenseCategory"; id: string; name: string };
	};
};

export type ExpenseBudgetDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type ExpenseBudgetDeleteMutation = { __typename?: "Mutation"; deleteExpenseBudget: boolean };

export type ExpenseBudgetDetailFragment = {
	__typename?: "ExpenseBudget";
	id: string;
	budgetMonth: string;
	amount: number;
	expenseCategory: { __typename?: "ExpenseCategory"; id: string; name: string };
};

export type ExpenseBudgetDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type ExpenseBudgetDetailQuery = {
	__typename?: "Query";
	expenseBudget: {
		__typename?: "ExpenseBudget";
		id: string;
		budgetMonth: string;
		amount: number;
		expenseCategory: { __typename?: "ExpenseCategory"; id: string; name: string };
	};
};

export type ExpenseBudgetIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<ExpenseBudgetFilterInput> | ExpenseBudgetFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<ExpenseBudgetOrder>;
}>;

export type ExpenseBudgetIndexQuery = {
	__typename?: "Query";
	expenseBudgetConnection: {
		__typename?: "ExpenseBudgetConnection";
		edges: Array<{
			__typename?: "ExpenseBudgetEdge";
			node: {
				__typename?: "ExpenseBudget";
				id: string;
				budgetMonth: string;
				amount: number;
				expenseCategory: { __typename?: "ExpenseCategory"; id: string; name: string };
			};
		}>;
	};
};

export type ExpenseBudgetNameFragment = { __typename?: "ExpenseBudget"; id: string };

export type ExpenseBudgetNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type ExpenseBudgetNameQuery = {
	__typename?: "Query";
	expenseBudget: { __typename?: "ExpenseBudget"; id: string };
};

export type ExpenseBudgetNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<ExpenseBudgetFilterInput> | ExpenseBudgetFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<ExpenseBudgetOrder>;
}>;

export type ExpenseBudgetNamesQuery = {
	__typename?: "Query";
	expenseBudgetConnection: {
		__typename?: "ExpenseBudgetConnection";
		edges: Array<{ __typename?: "ExpenseBudgetEdge"; node: { __typename?: "ExpenseBudget"; id: string } }>;
	};
};

export type ExpenseBudgetPaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<ExpenseBudgetFilterInput> | ExpenseBudgetFilterInput>;
	order?: InputMaybe<ExpenseBudgetOrder>;
}>;

export type ExpenseBudgetPaginationQuery = {
	__typename?: "Query";
	expenseBudgetConnection: {
		__typename?: "ExpenseBudgetConnection";
		totalCount: number;
		edges: Array<{ __typename?: "ExpenseBudgetEdge"; cursor: string }>;
	};
};

export type ExpenseBudgetSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<ExpenseBudgetFilterInput> | ExpenseBudgetFilterInput>;
	order?: InputMaybe<ExpenseBudgetOrder>;
}>;

export type ExpenseBudgetSelectQuery = {
	__typename?: "Query";
	expenseBudgetConnection: {
		__typename?: "ExpenseBudgetConnection";
		edges: Array<{ __typename?: "ExpenseBudgetEdge"; node: { __typename?: "ExpenseBudget"; id: string } }>;
	};
};

export type ExpenseBudgetUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: ExpenseBudgetUpdateInput;
}>;

export type ExpenseBudgetUpdateMutation = {
	__typename?: "Mutation";
	updateExpenseBudget: {
		__typename?: "ExpenseBudget";
		id: string;
		budgetMonth: string;
		amount: number;
		expenseCategory: { __typename?: "ExpenseCategory"; id: string; name: string };
	};
};

export type ExpenseCategoryCheckUniqueNameQueryVariables = Exact<{
	ignoreID?: InputMaybe<Scalars["ID"]>;
	name: Scalars["String"];
}>;

export type ExpenseCategoryCheckUniqueNameQuery = {
	__typename?: "Query";
	expenseCategoryConnection: { __typename?: "ExpenseCategoryConnection"; totalCount: number };
};

export type ExpenseCategoryCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<ExpenseCategoryFilterInput> | ExpenseCategoryFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<ExpenseCategoryOrder>;
}>;

export type ExpenseCategoryCountQuery = {
	__typename?: "Query";
	expenseCategoryConnection: { __typename?: "ExpenseCategoryConnection"; totalCount: number };
};

export type ExpenseCategoryCreateMutationVariables = Exact<{
	input: ExpenseCategoryCreateInput;
}>;

export type ExpenseCategoryCreateMutation = {
	__typename?: "Mutation";
	createExpenseCategory: {
		__typename?: "ExpenseCategory";
		id: string;
		name: string;
		description: string;
		expenseBudgets: Array<{ __typename?: "ExpenseBudget"; id: string }>;
		expenses: Array<{ __typename?: "Expense"; id: string }>;
	};
};

export type ExpenseCategoryDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type ExpenseCategoryDeleteMutation = { __typename?: "Mutation"; deleteExpenseCategory: boolean };

export type ExpenseCategoryDetailFragment = {
	__typename?: "ExpenseCategory";
	id: string;
	name: string;
	description: string;
	expenseBudgets: Array<{ __typename?: "ExpenseBudget"; id: string }>;
	expenses: Array<{ __typename?: "Expense"; id: string }>;
};

export type ExpenseCategoryDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type ExpenseCategoryDetailQuery = {
	__typename?: "Query";
	expenseCategory: {
		__typename?: "ExpenseCategory";
		id: string;
		name: string;
		description: string;
		expenseBudgets: Array<{ __typename?: "ExpenseBudget"; id: string }>;
		expenses: Array<{ __typename?: "Expense"; id: string }>;
	};
};

export type ExpenseCategoryIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<ExpenseCategoryFilterInput> | ExpenseCategoryFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<ExpenseCategoryOrder>;
}>;

export type ExpenseCategoryIndexQuery = {
	__typename?: "Query";
	expenseCategoryConnection: {
		__typename?: "ExpenseCategoryConnection";
		edges: Array<{
			__typename?: "ExpenseCategoryEdge";
			node: { __typename?: "ExpenseCategory"; id: string; name: string; description: string };
		}>;
	};
};

export type ExpenseCategoryNameFragment = { __typename?: "ExpenseCategory"; name: string };

export type ExpenseCategoryNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type ExpenseCategoryNameQuery = {
	__typename?: "Query";
	expenseCategory: { __typename?: "ExpenseCategory"; id: string; name: string };
};

export type ExpenseCategoryNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<ExpenseCategoryFilterInput> | ExpenseCategoryFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<ExpenseCategoryOrder>;
}>;

export type ExpenseCategoryNamesQuery = {
	__typename?: "Query";
	expenseCategoryConnection: {
		__typename?: "ExpenseCategoryConnection";
		edges: Array<{
			__typename?: "ExpenseCategoryEdge";
			node: { __typename?: "ExpenseCategory"; id: string; name: string };
		}>;
	};
};

export type ExpenseCategoryPaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<ExpenseCategoryFilterInput> | ExpenseCategoryFilterInput>;
	order?: InputMaybe<ExpenseCategoryOrder>;
}>;

export type ExpenseCategoryPaginationQuery = {
	__typename?: "Query";
	expenseCategoryConnection: {
		__typename?: "ExpenseCategoryConnection";
		totalCount: number;
		edges: Array<{ __typename?: "ExpenseCategoryEdge"; cursor: string }>;
	};
};

export type ExpenseCategorySelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<ExpenseCategoryFilterInput> | ExpenseCategoryFilterInput>;
	order?: InputMaybe<ExpenseCategoryOrder>;
}>;

export type ExpenseCategorySelectQuery = {
	__typename?: "Query";
	expenseCategoryConnection: {
		__typename?: "ExpenseCategoryConnection";
		edges: Array<{
			__typename?: "ExpenseCategoryEdge";
			node: { __typename?: "ExpenseCategory"; id: string; name: string };
		}>;
	};
};

export type ExpenseCategoryUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: ExpenseCategoryUpdateInput;
}>;

export type ExpenseCategoryUpdateMutation = {
	__typename?: "Mutation";
	updateExpenseCategory: {
		__typename?: "ExpenseCategory";
		id: string;
		name: string;
		description: string;
		expenseBudgets: Array<{ __typename?: "ExpenseBudget"; id: string }>;
		expenses: Array<{ __typename?: "Expense"; id: string }>;
	};
};

export type HomeRoomCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<HomeRoomFilterInput> | HomeRoomFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<HomeRoomOrder>;
}>;

export type HomeRoomCountQuery = {
	__typename?: "Query";
	homeRoomConnection: { __typename?: "HomeRoomConnection"; totalCount: number };
};

export type HomeRoomCreateMutationVariables = Exact<{
	input: HomeRoomCreateInput;
}>;

export type HomeRoomCreateMutation = {
	__typename?: "Mutation";
	createHomeRoom: {
		__typename?: "HomeRoom";
		id: string;
		name: string;
		description: string;
		studentCapacity: number | null;
		classYear: { __typename?: "ClassYear"; id: string; name: string; yearsFromGraduation: number };
		courses: Array<{
			__typename?: "Course";
			id: string;
			name: string;
			subject: CourseSubject;
			homeRoom: { __typename?: "HomeRoom"; id: string; name: string };
		}>;
		homeRoomSections: Array<{ __typename?: "HomeRoomSection"; id: string; name: string }>;
	};
};

export type HomeRoomDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type HomeRoomDeleteMutation = { __typename?: "Mutation"; deleteHomeRoom: boolean };

export type HomeRoomDetailFragment = {
	__typename?: "HomeRoom";
	id: string;
	name: string;
	description: string;
	studentCapacity: number | null;
	classYear: { __typename?: "ClassYear"; id: string; name: string; yearsFromGraduation: number };
	courses: Array<{
		__typename?: "Course";
		id: string;
		name: string;
		subject: CourseSubject;
		homeRoom: { __typename?: "HomeRoom"; id: string; name: string };
	}>;
	homeRoomSections: Array<{ __typename?: "HomeRoomSection"; id: string; name: string }>;
};

export type HomeRoomDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type HomeRoomDetailQuery = {
	__typename?: "Query";
	homeRoom: {
		__typename?: "HomeRoom";
		id: string;
		name: string;
		description: string;
		studentCapacity: number | null;
		classYear: { __typename?: "ClassYear"; id: string; name: string; yearsFromGraduation: number };
		courses: Array<{
			__typename?: "Course";
			id: string;
			name: string;
			subject: CourseSubject;
			homeRoom: { __typename?: "HomeRoom"; id: string; name: string };
		}>;
		homeRoomSections: Array<{ __typename?: "HomeRoomSection"; id: string; name: string }>;
	};
};

export type HomeRoomIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<HomeRoomFilterInput> | HomeRoomFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<HomeRoomOrder>;
}>;

export type HomeRoomIndexQuery = {
	__typename?: "Query";
	homeRoomConnection: {
		__typename?: "HomeRoomConnection";
		edges: Array<{
			__typename?: "HomeRoomEdge";
			node: { __typename?: "HomeRoom"; id: string; name: string; description: string; studentCapacity: number | null };
		}>;
	};
};

export type HomeRoomNameFragment = { __typename?: "HomeRoom"; name: string };

export type HomeRoomNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type HomeRoomNameQuery = {
	__typename?: "Query";
	homeRoom: { __typename?: "HomeRoom"; id: string; name: string };
};

export type HomeRoomNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<HomeRoomFilterInput> | HomeRoomFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<HomeRoomOrder>;
}>;

export type HomeRoomNamesQuery = {
	__typename?: "Query";
	homeRoomConnection: {
		__typename?: "HomeRoomConnection";
		edges: Array<{ __typename?: "HomeRoomEdge"; node: { __typename?: "HomeRoom"; id: string; name: string } }>;
	};
};

export type HomeRoomPaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<HomeRoomFilterInput> | HomeRoomFilterInput>;
	order?: InputMaybe<HomeRoomOrder>;
}>;

export type HomeRoomPaginationQuery = {
	__typename?: "Query";
	homeRoomConnection: {
		__typename?: "HomeRoomConnection";
		totalCount: number;
		edges: Array<{ __typename?: "HomeRoomEdge"; cursor: string }>;
	};
};

export type HomeRoomWithSectionsSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<HomeRoomFilterInput> | HomeRoomFilterInput>;
	order?: InputMaybe<HomeRoomOrder>;
	sectionFilters?: InputMaybe<Array<HomeRoomSectionFilterInput> | HomeRoomSectionFilterInput>;
	sectionOrder?: InputMaybe<HomeRoomSectionOrder>;
}>;

export type HomeRoomWithSectionsSelectQuery = {
	__typename?: "Query";
	homeRoomConnection: {
		__typename?: "HomeRoomConnection";
		edges: Array<{
			__typename?: "HomeRoomEdge";
			node: {
				__typename?: "HomeRoom";
				id: string;
				name: string;
				homeRoomSections: Array<{ __typename?: "HomeRoomSection"; id: string; name: string }>;
			};
		}>;
	};
};

export type HomeRoomSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<HomeRoomFilterInput> | HomeRoomFilterInput>;
	order?: InputMaybe<HomeRoomOrder>;
}>;

export type HomeRoomSelectQuery = {
	__typename?: "Query";
	homeRoomConnection: {
		__typename?: "HomeRoomConnection";
		edges: Array<{ __typename?: "HomeRoomEdge"; node: { __typename?: "HomeRoom"; id: string; name: string } }>;
	};
};

export type HomeRoomUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: HomeRoomUpdateInput;
}>;

export type HomeRoomUpdateMutation = {
	__typename?: "Mutation";
	updateHomeRoom: {
		__typename?: "HomeRoom";
		id: string;
		name: string;
		description: string;
		studentCapacity: number | null;
		classYear: { __typename?: "ClassYear"; id: string; name: string; yearsFromGraduation: number };
		courses: Array<{
			__typename?: "Course";
			id: string;
			name: string;
			subject: CourseSubject;
			homeRoom: { __typename?: "HomeRoom"; id: string; name: string };
		}>;
		homeRoomSections: Array<{ __typename?: "HomeRoomSection"; id: string; name: string }>;
	};
};

export type HomeRoomSectionCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<HomeRoomSectionFilterInput> | HomeRoomSectionFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<HomeRoomSectionOrder>;
}>;

export type HomeRoomSectionCountQuery = {
	__typename?: "Query";
	homeRoomSectionConnection: { __typename?: "HomeRoomSectionConnection"; totalCount: number };
};

export type HomeRoomSectionsCreateBulkMutationVariables = Exact<{
	inputs: Array<HomeRoomSectionCreateInput> | HomeRoomSectionCreateInput;
}>;

export type HomeRoomSectionsCreateBulkMutation = {
	__typename?: "Mutation";
	createBulkHomeRoomSections: {
		__typename?: "HomeRoomSectionConnection";
		edges: Array<{
			__typename?: "HomeRoomSectionEdge";
			node: {
				__typename?: "HomeRoomSection";
				id: string;
				name: string;
				description: string;
				courseSections: Array<{
					__typename?: "CourseSection";
					id: string;
					name: string;
					course: { __typename?: "Course"; id: string; subject: CourseSubject };
					homeRoomSection: { __typename?: "HomeRoomSection"; id: string; name: string };
				}>;
				homeRoom: { __typename?: "HomeRoom"; id: string; name: string };
				room: {
					__typename?: "Room";
					id: string;
					name: string;
					campus: { __typename?: "Campus"; id: string; name: string };
				};
				homeRoomSectionStudentEnrollments: Array<{
					__typename?: "HomeRoomSectionStudentEnrollment";
					id: string;
					student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
				}>;
				homeRoomSectionTeacherEnrollments: Array<{
					__typename?: "HomeRoomSectionTeacherEnrollment";
					id: string;
					teacher: { __typename?: "Employee"; id: string; person: { __typename?: "Person"; id: string; name: string } };
				}>;
				term: { __typename?: "Term"; id: string; startDate: string; endDate: string; name: string };
			};
		}>;
	};
};

export type HomeRoomSectionCreateMutationVariables = Exact<{
	input: HomeRoomSectionCreateInput;
}>;

export type HomeRoomSectionCreateMutation = {
	__typename?: "Mutation";
	createHomeRoomSection: {
		__typename?: "HomeRoomSection";
		id: string;
		name: string;
		description: string;
		courseSections: Array<{
			__typename?: "CourseSection";
			id: string;
			name: string;
			course: { __typename?: "Course"; id: string; subject: CourseSubject };
			homeRoomSection: { __typename?: "HomeRoomSection"; id: string; name: string };
		}>;
		homeRoom: { __typename?: "HomeRoom"; id: string; name: string };
		room: {
			__typename?: "Room";
			id: string;
			name: string;
			campus: { __typename?: "Campus"; id: string; name: string };
		};
		homeRoomSectionStudentEnrollments: Array<{
			__typename?: "HomeRoomSectionStudentEnrollment";
			id: string;
			student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
		}>;
		homeRoomSectionTeacherEnrollments: Array<{
			__typename?: "HomeRoomSectionTeacherEnrollment";
			id: string;
			teacher: { __typename?: "Employee"; id: string; person: { __typename?: "Person"; id: string; name: string } };
		}>;
		term: { __typename?: "Term"; id: string; startDate: string; endDate: string; name: string };
	};
};

export type HomeRoomSectionDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type HomeRoomSectionDeleteMutation = { __typename?: "Mutation"; deleteHomeRoomSection: boolean };

export type HomeRoomSectionDetailFragment = {
	__typename?: "HomeRoomSection";
	id: string;
	name: string;
	description: string;
	courseSections: Array<{
		__typename?: "CourseSection";
		id: string;
		name: string;
		course: { __typename?: "Course"; id: string; subject: CourseSubject };
		homeRoomSection: { __typename?: "HomeRoomSection"; id: string; name: string };
	}>;
	homeRoom: { __typename?: "HomeRoom"; id: string; name: string };
	room: { __typename?: "Room"; id: string; name: string; campus: { __typename?: "Campus"; id: string; name: string } };
	homeRoomSectionStudentEnrollments: Array<{
		__typename?: "HomeRoomSectionStudentEnrollment";
		id: string;
		student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
	}>;
	homeRoomSectionTeacherEnrollments: Array<{
		__typename?: "HomeRoomSectionTeacherEnrollment";
		id: string;
		teacher: { __typename?: "Employee"; id: string; person: { __typename?: "Person"; id: string; name: string } };
	}>;
	term: { __typename?: "Term"; id: string; startDate: string; endDate: string; name: string };
};

export type HomeRoomSectionDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type HomeRoomSectionDetailQuery = {
	__typename?: "Query";
	homeRoomSection: {
		__typename?: "HomeRoomSection";
		id: string;
		name: string;
		description: string;
		courseSections: Array<{
			__typename?: "CourseSection";
			id: string;
			name: string;
			course: { __typename?: "Course"; id: string; subject: CourseSubject };
			homeRoomSection: { __typename?: "HomeRoomSection"; id: string; name: string };
		}>;
		homeRoom: { __typename?: "HomeRoom"; id: string; name: string };
		room: {
			__typename?: "Room";
			id: string;
			name: string;
			campus: { __typename?: "Campus"; id: string; name: string };
		};
		homeRoomSectionStudentEnrollments: Array<{
			__typename?: "HomeRoomSectionStudentEnrollment";
			id: string;
			student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
		}>;
		homeRoomSectionTeacherEnrollments: Array<{
			__typename?: "HomeRoomSectionTeacherEnrollment";
			id: string;
			teacher: { __typename?: "Employee"; id: string; person: { __typename?: "Person"; id: string; name: string } };
		}>;
		term: { __typename?: "Term"; id: string; startDate: string; endDate: string; name: string };
	};
};

export type HomeRoomSectionIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<HomeRoomSectionFilterInput> | HomeRoomSectionFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<HomeRoomSectionOrder>;
}>;

export type HomeRoomSectionIndexQuery = {
	__typename?: "Query";
	homeRoomSectionConnection: {
		__typename?: "HomeRoomSectionConnection";
		edges: Array<{
			__typename?: "HomeRoomSectionEdge";
			node: {
				__typename?: "HomeRoomSection";
				id: string;
				name: string;
				description: string;
				term: { __typename?: "Term"; id: string; name: string };
			};
		}>;
	};
};

export type HomeRoomSectionNameFragment = { __typename?: "HomeRoomSection"; name: string };

export type HomeRoomSectionNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type HomeRoomSectionNameQuery = {
	__typename?: "Query";
	homeRoomSection: { __typename?: "HomeRoomSection"; id: string; name: string };
};

export type HomeRoomSectionNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<HomeRoomSectionFilterInput> | HomeRoomSectionFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<HomeRoomSectionOrder>;
}>;

export type HomeRoomSectionNamesQuery = {
	__typename?: "Query";
	homeRoomSectionConnection: {
		__typename?: "HomeRoomSectionConnection";
		edges: Array<{
			__typename?: "HomeRoomSectionEdge";
			node: { __typename?: "HomeRoomSection"; id: string; name: string };
		}>;
	};
};

export type HomeRoomSectionPaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<HomeRoomSectionFilterInput> | HomeRoomSectionFilterInput>;
	order?: InputMaybe<HomeRoomSectionOrder>;
}>;

export type HomeRoomSectionPaginationQuery = {
	__typename?: "Query";
	homeRoomSectionConnection: {
		__typename?: "HomeRoomSectionConnection";
		totalCount: number;
		edges: Array<{ __typename?: "HomeRoomSectionEdge"; cursor: string }>;
	};
};

export type HomeRoomSectionPeopleListQueryVariables = Exact<{
	filters?: InputMaybe<Array<HomeRoomSectionFilterInput> | HomeRoomSectionFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<HomeRoomSectionOrder>;
}>;

export type HomeRoomSectionPeopleListQuery = {
	__typename?: "Query";
	homeRoomSectionConnection: {
		__typename?: "HomeRoomSectionConnection";
		edges: Array<{
			__typename?: "HomeRoomSectionEdge";
			node: {
				__typename?: "HomeRoomSection";
				id: string;
				name: string;
				description: string;
				homeRoomSectionTeacherEnrollments: Array<{
					__typename?: "HomeRoomSectionTeacherEnrollment";
					id: string;
					teacher: {
						__typename?: "Employee";
						id: string;
						person: { __typename?: "Person"; id: string; name: string; imageURL: string };
					};
				}>;
				homeRoomSectionStudentEnrollments: Array<{
					__typename?: "HomeRoomSectionStudentEnrollment";
					id: string;
					student: {
						__typename?: "Student";
						id: string;
						person: { __typename?: "Person"; id: string; name: string; imageURL: string };
					};
				}>;
			};
		}>;
	};
};

export type HomeRoomSectionSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<HomeRoomSectionFilterInput> | HomeRoomSectionFilterInput>;
	order?: InputMaybe<HomeRoomSectionOrder>;
}>;

export type HomeRoomSectionSelectQuery = {
	__typename?: "Query";
	homeRoomSectionConnection: {
		__typename?: "HomeRoomSectionConnection";
		edges: Array<{
			__typename?: "HomeRoomSectionEdge";
			node: { __typename?: "HomeRoomSection"; id: string; name: string };
		}>;
	};
};

export type HomeRoomSectionUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: HomeRoomSectionUpdateInput;
}>;

export type HomeRoomSectionUpdateMutation = {
	__typename?: "Mutation";
	updateHomeRoomSection: {
		__typename?: "HomeRoomSection";
		id: string;
		name: string;
		description: string;
		courseSections: Array<{
			__typename?: "CourseSection";
			id: string;
			name: string;
			course: { __typename?: "Course"; id: string; subject: CourseSubject };
			homeRoomSection: { __typename?: "HomeRoomSection"; id: string; name: string };
		}>;
		homeRoom: { __typename?: "HomeRoom"; id: string; name: string };
		room: {
			__typename?: "Room";
			id: string;
			name: string;
			campus: { __typename?: "Campus"; id: string; name: string };
		};
		homeRoomSectionStudentEnrollments: Array<{
			__typename?: "HomeRoomSectionStudentEnrollment";
			id: string;
			student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
		}>;
		homeRoomSectionTeacherEnrollments: Array<{
			__typename?: "HomeRoomSectionTeacherEnrollment";
			id: string;
			teacher: { __typename?: "Employee"; id: string; person: { __typename?: "Person"; id: string; name: string } };
		}>;
		term: { __typename?: "Term"; id: string; startDate: string; endDate: string; name: string };
	};
};

export type HomeRoomSectionWithHomeRoomListQueryVariables = Exact<{
	filters?: InputMaybe<Array<HomeRoomSectionFilterInput> | HomeRoomSectionFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<HomeRoomSectionOrder>;
}>;

export type HomeRoomSectionWithHomeRoomListQuery = {
	__typename?: "Query";
	homeRoomSectionConnection: {
		__typename?: "HomeRoomSectionConnection";
		edges: Array<{
			__typename?: "HomeRoomSectionEdge";
			node: {
				__typename?: "HomeRoomSection";
				id: string;
				name: string;
				description: string;
				homeRoom: { __typename?: "HomeRoom"; id: string; name: string };
			};
		}>;
	};
};

export type InvoiceCheckUniqueInvoiceNumberQueryVariables = Exact<{
	ignoreID?: InputMaybe<Scalars["ID"]>;
	invoiceNumber: Scalars["String"];
}>;

export type InvoiceCheckUniqueInvoiceNumberQuery = {
	__typename?: "Query";
	invoiceConnection: { __typename?: "InvoiceConnection"; totalCount: number };
};

export type InvoiceCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<InvoiceFilterInput> | InvoiceFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<InvoiceOrder>;
}>;

export type InvoiceCountQuery = {
	__typename?: "Query";
	invoiceConnection: { __typename?: "InvoiceConnection"; totalCount: number };
};

export type InvoiceCreateMutationVariables = Exact<{
	input: InvoiceCreateInput;
}>;

export type InvoiceCreateMutation = {
	__typename?: "Mutation";
	createInvoice: {
		__typename?: "Invoice";
		id: string;
		invoiceNumber: string;
		createdDate: string;
		dueDate: string;
		description: string;
		invoiceLineItems: Array<{
			__typename?: "InvoiceLineItem";
			id: string;
			lineItemNumber: number;
			amount: number;
			description: string;
		}>;
		paymentLineItems: Array<{
			__typename?: "PaymentLineItem";
			id: string;
			amount: number;
			payment: { __typename?: "Payment"; id: string; createdDate: string; paymentMethod: PaymentPaymentMethod };
		}>;
		contractInstallment: { __typename?: "ContractInstallment"; id: string; installmentDate: string };
	};
};

export type InvoiceDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type InvoiceDeleteMutation = { __typename?: "Mutation"; deleteInvoice: boolean };

export type InvoiceDetailFragment = {
	__typename?: "Invoice";
	id: string;
	invoiceNumber: string;
	createdDate: string;
	dueDate: string;
	description: string;
	invoiceLineItems: Array<{
		__typename?: "InvoiceLineItem";
		id: string;
		lineItemNumber: number;
		amount: number;
		description: string;
	}>;
	paymentLineItems: Array<{
		__typename?: "PaymentLineItem";
		id: string;
		amount: number;
		payment: { __typename?: "Payment"; id: string; createdDate: string; paymentMethod: PaymentPaymentMethod };
	}>;
	contractInstallment: { __typename?: "ContractInstallment"; id: string; installmentDate: string };
};

export type InvoiceDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type InvoiceDetailQuery = {
	__typename?: "Query";
	invoice: {
		__typename?: "Invoice";
		id: string;
		invoiceNumber: string;
		createdDate: string;
		dueDate: string;
		description: string;
		invoiceLineItems: Array<{
			__typename?: "InvoiceLineItem";
			id: string;
			lineItemNumber: number;
			amount: number;
			description: string;
		}>;
		paymentLineItems: Array<{
			__typename?: "PaymentLineItem";
			id: string;
			amount: number;
			payment: { __typename?: "Payment"; id: string; createdDate: string; paymentMethod: PaymentPaymentMethod };
		}>;
		contractInstallment: { __typename?: "ContractInstallment"; id: string; installmentDate: string };
	};
};

export type InvoiceIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<InvoiceFilterInput> | InvoiceFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<InvoiceOrder>;
}>;

export type InvoiceIndexQuery = {
	__typename?: "Query";
	invoiceConnection: {
		__typename?: "InvoiceConnection";
		edges: Array<{
			__typename?: "InvoiceEdge";
			node: {
				__typename?: "Invoice";
				id: string;
				invoiceNumber: string;
				createdDate: string;
				dueDate: string;
				description: string;
			};
		}>;
	};
};

export type InvoiceNameFragment = { __typename?: "Invoice"; invoiceNumber: string };

export type InvoiceNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type InvoiceNameQuery = {
	__typename?: "Query";
	invoice: { __typename?: "Invoice"; id: string; invoiceNumber: string };
};

export type InvoiceNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<InvoiceFilterInput> | InvoiceFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<InvoiceOrder>;
}>;

export type InvoiceNamesQuery = {
	__typename?: "Query";
	invoiceConnection: {
		__typename?: "InvoiceConnection";
		edges: Array<{ __typename?: "InvoiceEdge"; node: { __typename?: "Invoice"; id: string; invoiceNumber: string } }>;
	};
};

export type InvoicePaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<InvoiceFilterInput> | InvoiceFilterInput>;
	order?: InputMaybe<InvoiceOrder>;
}>;

export type InvoicePaginationQuery = {
	__typename?: "Query";
	invoiceConnection: {
		__typename?: "InvoiceConnection";
		totalCount: number;
		edges: Array<{ __typename?: "InvoiceEdge"; cursor: string }>;
	};
};

export type InvoiceSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<InvoiceFilterInput> | InvoiceFilterInput>;
	order?: InputMaybe<InvoiceOrder>;
}>;

export type InvoiceSelectQuery = {
	__typename?: "Query";
	invoiceConnection: {
		__typename?: "InvoiceConnection";
		edges: Array<{
			__typename?: "InvoiceEdge";
			node: {
				__typename?: "Invoice";
				id: string;
				dueDate: string;
				invoiceNumber: string;
				invoiceLineItems: Array<{ __typename?: "InvoiceLineItem"; id: string; amount: number }>;
			};
		}>;
	};
};

export type StudentInvoicesListQueryVariables = Exact<{
	filters?: InputMaybe<Array<InvoiceFilterInput> | InvoiceFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<InvoiceOrder>;
}>;

export type StudentInvoicesListQuery = {
	__typename?: "Query";
	invoiceConnection: {
		__typename?: "InvoiceConnection";
		edges: Array<{
			__typename?: "InvoiceEdge";
			node: {
				__typename?: "Invoice";
				id: string;
				invoiceNumber: string;
				createdDate: string;
				dueDate: string;
				contractInstallment: {
					__typename?: "ContractInstallment";
					id: string;
					installmentDate: string;
					portion: number;
					contract: { __typename?: "Contract"; id: string; agreementDate: string; status: ContractStatus };
				};
				invoiceLineItems: Array<{ __typename?: "InvoiceLineItem"; id: string; amount: number }>;
				paymentLineItems: Array<{
					__typename?: "PaymentLineItem";
					id: string;
					amount: number;
					payment: { __typename?: "Payment"; id: string; createdDate: string };
				}>;
			};
		}>;
	};
};

export type InvoiceUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: InvoiceUpdateInput;
}>;

export type InvoiceUpdateMutation = {
	__typename?: "Mutation";
	updateInvoice: {
		__typename?: "Invoice";
		id: string;
		invoiceNumber: string;
		createdDate: string;
		dueDate: string;
		description: string;
		invoiceLineItems: Array<{
			__typename?: "InvoiceLineItem";
			id: string;
			lineItemNumber: number;
			amount: number;
			description: string;
		}>;
		paymentLineItems: Array<{
			__typename?: "PaymentLineItem";
			id: string;
			amount: number;
			payment: { __typename?: "Payment"; id: string; createdDate: string; paymentMethod: PaymentPaymentMethod };
		}>;
		contractInstallment: { __typename?: "ContractInstallment"; id: string; installmentDate: string };
	};
};

export type InvoiceLineItemCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<InvoiceLineItemFilterInput> | InvoiceLineItemFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<InvoiceLineItemOrder>;
}>;

export type InvoiceLineItemCountQuery = {
	__typename?: "Query";
	invoiceLineItemConnection: { __typename?: "InvoiceLineItemConnection"; totalCount: number };
};

export type InvoiceLineItemCreateMutationVariables = Exact<{
	input: InvoiceLineItemCreateInput;
}>;

export type InvoiceLineItemCreateMutation = {
	__typename?: "Mutation";
	createInvoiceLineItem: {
		__typename?: "InvoiceLineItem";
		id: string;
		lineItemNumber: number;
		amount: number;
		description: string;
		invoice: {
			__typename?: "Invoice";
			id: string;
			invoiceNumber: string;
			dueDate: string;
			invoiceLineItems: Array<{ __typename?: "InvoiceLineItem"; id: string; amount: number }>;
		};
		serviceSubscription: {
			__typename?: "ServiceSubscription";
			id: string;
			service: { __typename?: "Service"; id: string; name: string };
		} | null;
	};
};

export type InvoiceLineItemDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type InvoiceLineItemDeleteMutation = { __typename?: "Mutation"; deleteInvoiceLineItem: boolean };

export type InvoiceLineItemDetailFragment = {
	__typename?: "InvoiceLineItem";
	id: string;
	lineItemNumber: number;
	amount: number;
	description: string;
	invoice: {
		__typename?: "Invoice";
		id: string;
		invoiceNumber: string;
		dueDate: string;
		invoiceLineItems: Array<{ __typename?: "InvoiceLineItem"; id: string; amount: number }>;
	};
	serviceSubscription: {
		__typename?: "ServiceSubscription";
		id: string;
		service: { __typename?: "Service"; id: string; name: string };
	} | null;
};

export type InvoiceLineItemDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type InvoiceLineItemDetailQuery = {
	__typename?: "Query";
	invoiceLineItem: {
		__typename?: "InvoiceLineItem";
		id: string;
		lineItemNumber: number;
		amount: number;
		description: string;
		invoice: {
			__typename?: "Invoice";
			id: string;
			invoiceNumber: string;
			dueDate: string;
			invoiceLineItems: Array<{ __typename?: "InvoiceLineItem"; id: string; amount: number }>;
		};
		serviceSubscription: {
			__typename?: "ServiceSubscription";
			id: string;
			service: { __typename?: "Service"; id: string; name: string };
		} | null;
	};
};

export type InvoiceLineItemIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<InvoiceLineItemFilterInput> | InvoiceLineItemFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<InvoiceLineItemOrder>;
}>;

export type InvoiceLineItemIndexQuery = {
	__typename?: "Query";
	invoiceLineItemConnection: {
		__typename?: "InvoiceLineItemConnection";
		edges: Array<{
			__typename?: "InvoiceLineItemEdge";
			node: { __typename?: "InvoiceLineItem"; id: string; lineItemNumber: number; amount: number; description: string };
		}>;
	};
};

export type InvoiceLineItemNameFragment = { __typename?: "InvoiceLineItem"; id: string };

export type InvoiceLineItemNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type InvoiceLineItemNameQuery = {
	__typename?: "Query";
	invoiceLineItem: { __typename?: "InvoiceLineItem"; id: string };
};

export type InvoiceLineItemNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<InvoiceLineItemFilterInput> | InvoiceLineItemFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<InvoiceLineItemOrder>;
}>;

export type InvoiceLineItemNamesQuery = {
	__typename?: "Query";
	invoiceLineItemConnection: {
		__typename?: "InvoiceLineItemConnection";
		edges: Array<{ __typename?: "InvoiceLineItemEdge"; node: { __typename?: "InvoiceLineItem"; id: string } }>;
	};
};

export type InvoiceLineItemPaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<InvoiceLineItemFilterInput> | InvoiceLineItemFilterInput>;
	order?: InputMaybe<InvoiceLineItemOrder>;
}>;

export type InvoiceLineItemPaginationQuery = {
	__typename?: "Query";
	invoiceLineItemConnection: {
		__typename?: "InvoiceLineItemConnection";
		totalCount: number;
		edges: Array<{ __typename?: "InvoiceLineItemEdge"; cursor: string }>;
	};
};

export type InvoiceLineItemSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<InvoiceLineItemFilterInput> | InvoiceLineItemFilterInput>;
	order?: InputMaybe<InvoiceLineItemOrder>;
}>;

export type InvoiceLineItemSelectQuery = {
	__typename?: "Query";
	invoiceLineItemConnection: {
		__typename?: "InvoiceLineItemConnection";
		edges: Array<{ __typename?: "InvoiceLineItemEdge"; node: { __typename?: "InvoiceLineItem"; id: string } }>;
	};
};

export type InvoiceLineItemUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: InvoiceLineItemUpdateInput;
}>;

export type InvoiceLineItemUpdateMutation = {
	__typename?: "Mutation";
	updateInvoiceLineItem: {
		__typename?: "InvoiceLineItem";
		id: string;
		lineItemNumber: number;
		amount: number;
		description: string;
		invoice: {
			__typename?: "Invoice";
			id: string;
			invoiceNumber: string;
			dueDate: string;
			invoiceLineItems: Array<{ __typename?: "InvoiceLineItem"; id: string; amount: number }>;
		};
		serviceSubscription: {
			__typename?: "ServiceSubscription";
			id: string;
			service: { __typename?: "Service"; id: string; name: string };
		} | null;
	};
};

export type LearningObjectiveCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<LearningObjectiveFilterInput> | LearningObjectiveFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<LearningObjectiveOrder>;
}>;

export type LearningObjectiveCountQuery = {
	__typename?: "Query";
	learningObjectiveConnection: { __typename?: "LearningObjectiveConnection"; totalCount: number };
};

export type LearningObjectiveCreateMutationVariables = Exact<{
	input: LearningObjectiveCreateInput;
}>;

export type LearningObjectiveCreateMutation = {
	__typename?: "Mutation";
	createLearningObjective: {
		__typename?: "LearningObjective";
		id: string;
		category: string;
		description: string;
		isActive: boolean;
		course: {
			__typename?: "Course";
			id: string;
			name: string;
			subject: CourseSubject;
			homeRoom: { __typename?: "HomeRoom"; id: string; name: string };
		};
		learningObjectiveMarks: Array<{ __typename?: "LearningObjectiveMark"; id: string }>;
	};
};

export type LearningObjectiveDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type LearningObjectiveDeleteMutation = { __typename?: "Mutation"; deleteLearningObjective: boolean };

export type LearningObjectiveDetailFragment = {
	__typename?: "LearningObjective";
	id: string;
	category: string;
	description: string;
	isActive: boolean;
	course: {
		__typename?: "Course";
		id: string;
		name: string;
		subject: CourseSubject;
		homeRoom: { __typename?: "HomeRoom"; id: string; name: string };
	};
	learningObjectiveMarks: Array<{ __typename?: "LearningObjectiveMark"; id: string }>;
};

export type LearningObjectiveDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type LearningObjectiveDetailQuery = {
	__typename?: "Query";
	learningObjective: {
		__typename?: "LearningObjective";
		id: string;
		category: string;
		description: string;
		isActive: boolean;
		course: {
			__typename?: "Course";
			id: string;
			name: string;
			subject: CourseSubject;
			homeRoom: { __typename?: "HomeRoom"; id: string; name: string };
		};
		learningObjectiveMarks: Array<{ __typename?: "LearningObjectiveMark"; id: string }>;
	};
};

export type LearningObjectiveIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<LearningObjectiveFilterInput> | LearningObjectiveFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<LearningObjectiveOrder>;
}>;

export type LearningObjectiveIndexQuery = {
	__typename?: "Query";
	learningObjectiveConnection: {
		__typename?: "LearningObjectiveConnection";
		edges: Array<{
			__typename?: "LearningObjectiveEdge";
			node: { __typename?: "LearningObjective"; id: string; category: string; description: string; isActive: boolean };
		}>;
	};
};

export type LearningObjectiveNameFragment = { __typename?: "LearningObjective"; category: string; description: string };

export type LearningObjectiveNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type LearningObjectiveNameQuery = {
	__typename?: "Query";
	learningObjective: { __typename?: "LearningObjective"; id: string; category: string; description: string };
};

export type LearningObjectiveNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<LearningObjectiveFilterInput> | LearningObjectiveFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<LearningObjectiveOrder>;
}>;

export type LearningObjectiveNamesQuery = {
	__typename?: "Query";
	learningObjectiveConnection: {
		__typename?: "LearningObjectiveConnection";
		edges: Array<{
			__typename?: "LearningObjectiveEdge";
			node: { __typename?: "LearningObjective"; id: string; category: string; description: string };
		}>;
	};
};

export type LearningObjectivePaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<LearningObjectiveFilterInput> | LearningObjectiveFilterInput>;
	order?: InputMaybe<LearningObjectiveOrder>;
}>;

export type LearningObjectivePaginationQuery = {
	__typename?: "Query";
	learningObjectiveConnection: {
		__typename?: "LearningObjectiveConnection";
		totalCount: number;
		edges: Array<{ __typename?: "LearningObjectiveEdge"; cursor: string }>;
	};
};

export type LearningObjectiveSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<LearningObjectiveFilterInput> | LearningObjectiveFilterInput>;
	order?: InputMaybe<LearningObjectiveOrder>;
}>;

export type LearningObjectiveSelectQuery = {
	__typename?: "Query";
	learningObjectiveConnection: {
		__typename?: "LearningObjectiveConnection";
		edges: Array<{
			__typename?: "LearningObjectiveEdge";
			node: { __typename?: "LearningObjective"; id: string; category: string; description: string };
		}>;
	};
};

export type LearningObjectiveUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: LearningObjectiveUpdateInput;
}>;

export type LearningObjectiveUpdateMutation = {
	__typename?: "Mutation";
	updateLearningObjective: {
		__typename?: "LearningObjective";
		id: string;
		category: string;
		description: string;
		isActive: boolean;
		course: {
			__typename?: "Course";
			id: string;
			name: string;
			subject: CourseSubject;
			homeRoom: { __typename?: "HomeRoom"; id: string; name: string };
		};
		learningObjectiveMarks: Array<{ __typename?: "LearningObjectiveMark"; id: string }>;
	};
};

export type LearningObjectiveMarkUpdateBulkValuesMutationVariables = Exact<{
	developingIDs: Array<Scalars["ID"]> | Scalars["ID"];
	meetingIDs: Array<Scalars["ID"]> | Scalars["ID"];
	exceedingIDs: Array<Scalars["ID"]> | Scalars["ID"];
	clearIDs: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type LearningObjectiveMarkUpdateBulkValuesMutation = {
	__typename?: "Mutation";
	developing: {
		__typename?: "LearningObjectiveMarkConnection";
		edges: Array<{
			__typename?: "LearningObjectiveMarkEdge";
			node: { __typename?: "LearningObjectiveMark"; id: string; value: LearningObjectiveMarkValue | null };
		}>;
	};
	meeting: {
		__typename?: "LearningObjectiveMarkConnection";
		edges: Array<{
			__typename?: "LearningObjectiveMarkEdge";
			node: { __typename?: "LearningObjectiveMark"; id: string; value: LearningObjectiveMarkValue | null };
		}>;
	};
	exceeding: {
		__typename?: "LearningObjectiveMarkConnection";
		edges: Array<{
			__typename?: "LearningObjectiveMarkEdge";
			node: { __typename?: "LearningObjectiveMark"; id: string; value: LearningObjectiveMarkValue | null };
		}>;
	};
	clear: {
		__typename?: "LearningObjectiveMarkConnection";
		edges: Array<{
			__typename?: "LearningObjectiveMarkEdge";
			node: { __typename?: "LearningObjectiveMark"; id: string; value: LearningObjectiveMarkValue | null };
		}>;
	};
};

export type LearningObjectiveMarkCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<LearningObjectiveMarkFilterInput> | LearningObjectiveMarkFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<LearningObjectiveMarkOrder>;
}>;

export type LearningObjectiveMarkCountQuery = {
	__typename?: "Query";
	learningObjectiveMarkConnection: { __typename?: "LearningObjectiveMarkConnection"; totalCount: number };
};

export type LearningObjectiveMarkCreateMutationVariables = Exact<{
	input: LearningObjectiveMarkCreateInput;
}>;

export type LearningObjectiveMarkCreateMutation = {
	__typename?: "Mutation";
	createLearningObjectiveMark: {
		__typename?: "LearningObjectiveMark";
		id: string;
		value: LearningObjectiveMarkValue | null;
		learningObjective: { __typename?: "LearningObjective"; id: string; category: string; description: string };
		reportCard: { __typename?: "ReportCard"; id: string };
	};
};

export type LearningObjectiveMarkDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type LearningObjectiveMarkDeleteMutation = { __typename?: "Mutation"; deleteLearningObjectiveMark: boolean };

export type LearningObjectiveMarkDetailFragment = {
	__typename?: "LearningObjectiveMark";
	id: string;
	value: LearningObjectiveMarkValue | null;
	learningObjective: { __typename?: "LearningObjective"; id: string; category: string; description: string };
	reportCard: { __typename?: "ReportCard"; id: string };
};

export type LearningObjectiveMarkDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type LearningObjectiveMarkDetailQuery = {
	__typename?: "Query";
	learningObjectiveMark: {
		__typename?: "LearningObjectiveMark";
		id: string;
		value: LearningObjectiveMarkValue | null;
		learningObjective: { __typename?: "LearningObjective"; id: string; category: string; description: string };
		reportCard: { __typename?: "ReportCard"; id: string };
	};
};

export type LearningObjectiveMarkIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<LearningObjectiveMarkFilterInput> | LearningObjectiveMarkFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<LearningObjectiveMarkOrder>;
}>;

export type LearningObjectiveMarkIndexQuery = {
	__typename?: "Query";
	learningObjectiveMarkConnection: {
		__typename?: "LearningObjectiveMarkConnection";
		edges: Array<{
			__typename?: "LearningObjectiveMarkEdge";
			node: { __typename?: "LearningObjectiveMark"; id: string; value: LearningObjectiveMarkValue | null };
		}>;
	};
};

export type LearningObjectiveMarkNameFragment = { __typename?: "LearningObjectiveMark"; id: string };

export type LearningObjectiveMarkNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type LearningObjectiveMarkNameQuery = {
	__typename?: "Query";
	learningObjectiveMark: { __typename?: "LearningObjectiveMark"; id: string };
};

export type LearningObjectiveMarkNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<LearningObjectiveMarkFilterInput> | LearningObjectiveMarkFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<LearningObjectiveMarkOrder>;
}>;

export type LearningObjectiveMarkNamesQuery = {
	__typename?: "Query";
	learningObjectiveMarkConnection: {
		__typename?: "LearningObjectiveMarkConnection";
		edges: Array<{
			__typename?: "LearningObjectiveMarkEdge";
			node: { __typename?: "LearningObjectiveMark"; id: string };
		}>;
	};
};

export type LearningObjectiveMarkPaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<LearningObjectiveMarkFilterInput> | LearningObjectiveMarkFilterInput>;
	order?: InputMaybe<LearningObjectiveMarkOrder>;
}>;

export type LearningObjectiveMarkPaginationQuery = {
	__typename?: "Query";
	learningObjectiveMarkConnection: {
		__typename?: "LearningObjectiveMarkConnection";
		totalCount: number;
		edges: Array<{ __typename?: "LearningObjectiveMarkEdge"; cursor: string }>;
	};
};

export type LearningObjectiveMarkSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<LearningObjectiveMarkFilterInput> | LearningObjectiveMarkFilterInput>;
	order?: InputMaybe<LearningObjectiveMarkOrder>;
}>;

export type LearningObjectiveMarkSelectQuery = {
	__typename?: "Query";
	learningObjectiveMarkConnection: {
		__typename?: "LearningObjectiveMarkConnection";
		edges: Array<{
			__typename?: "LearningObjectiveMarkEdge";
			node: { __typename?: "LearningObjectiveMark"; id: string };
		}>;
	};
};

export type LearningObjectiveMarkUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: LearningObjectiveMarkUpdateInput;
}>;

export type LearningObjectiveMarkUpdateMutation = {
	__typename?: "Mutation";
	updateLearningObjectiveMark: {
		__typename?: "LearningObjectiveMark";
		id: string;
		value: LearningObjectiveMarkValue | null;
		learningObjective: { __typename?: "LearningObjective"; id: string; category: string; description: string };
		reportCard: { __typename?: "ReportCard"; id: string };
	};
};

export type LessonPlanCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<LessonPlanFilterInput> | LessonPlanFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<LessonPlanOrder>;
}>;

export type LessonPlanCountQuery = {
	__typename?: "Query";
	lessonPlanConnection: { __typename?: "LessonPlanConnection"; totalCount: number };
};

export type LessonPlanCreateMutationVariables = Exact<{
	input: LessonPlanCreateInput;
}>;

export type LessonPlanCreateMutation = {
	__typename?: "Mutation";
	createLessonPlan: {
		__typename?: "LessonPlan";
		id: string;
		courseDate: string;
		lessonDescription: string;
		requiredResourcesAndMaterials: string;
		stationOneDescription: string;
		stationTwoDescription: string;
		stationThreeDescription: string;
		differentiationDescription: string;
		assessmentDescription: string;
		notes: string;
		courseSection: {
			__typename?: "CourseSection";
			id: string;
			name: string;
			course: { __typename?: "Course"; id: string; subject: CourseSubject };
			homeRoomSection: { __typename?: "HomeRoomSection"; id: string; name: string };
		};
		learningObjectives: Array<{ __typename?: "LearningObjective"; id: string; category: string; description: string }>;
	};
};

export type LessonPlanDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type LessonPlanDeleteMutation = { __typename?: "Mutation"; deleteLessonPlan: boolean };

export type LessonPlanDetailFragment = {
	__typename?: "LessonPlan";
	id: string;
	courseDate: string;
	lessonDescription: string;
	requiredResourcesAndMaterials: string;
	stationOneDescription: string;
	stationTwoDescription: string;
	stationThreeDescription: string;
	differentiationDescription: string;
	assessmentDescription: string;
	notes: string;
	courseSection: {
		__typename?: "CourseSection";
		id: string;
		name: string;
		course: { __typename?: "Course"; id: string; subject: CourseSubject };
		homeRoomSection: { __typename?: "HomeRoomSection"; id: string; name: string };
	};
	learningObjectives: Array<{ __typename?: "LearningObjective"; id: string; category: string; description: string }>;
};

export type LessonPlanDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type LessonPlanDetailQuery = {
	__typename?: "Query";
	lessonPlan: {
		__typename?: "LessonPlan";
		id: string;
		courseDate: string;
		lessonDescription: string;
		requiredResourcesAndMaterials: string;
		stationOneDescription: string;
		stationTwoDescription: string;
		stationThreeDescription: string;
		differentiationDescription: string;
		assessmentDescription: string;
		notes: string;
		courseSection: {
			__typename?: "CourseSection";
			id: string;
			name: string;
			course: { __typename?: "Course"; id: string; subject: CourseSubject };
			homeRoomSection: { __typename?: "HomeRoomSection"; id: string; name: string };
		};
		learningObjectives: Array<{ __typename?: "LearningObjective"; id: string; category: string; description: string }>;
	};
};

export type LessonPlanIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<LessonPlanFilterInput> | LessonPlanFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<LessonPlanOrder>;
}>;

export type LessonPlanIndexQuery = {
	__typename?: "Query";
	lessonPlanConnection: {
		__typename?: "LessonPlanConnection";
		edges: Array<{
			__typename?: "LessonPlanEdge";
			node: {
				__typename?: "LessonPlan";
				id: string;
				courseDate: string;
				lessonDescription: string;
				requiredResourcesAndMaterials: string;
				stationOneDescription: string;
				stationTwoDescription: string;
				stationThreeDescription: string;
				differentiationDescription: string;
				assessmentDescription: string;
				notes: string;
			};
		}>;
	};
};

export type LessonPlanNameFragment = { __typename?: "LessonPlan"; id: string };

export type LessonPlanNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type LessonPlanNameQuery = { __typename?: "Query"; lessonPlan: { __typename?: "LessonPlan"; id: string } };

export type LessonPlanNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<LessonPlanFilterInput> | LessonPlanFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<LessonPlanOrder>;
}>;

export type LessonPlanNamesQuery = {
	__typename?: "Query";
	lessonPlanConnection: {
		__typename?: "LessonPlanConnection";
		edges: Array<{ __typename?: "LessonPlanEdge"; node: { __typename?: "LessonPlan"; id: string } }>;
	};
};

export type LessonPlansOverviewQueryVariables = Exact<{
	startDate: Scalars["Time"];
	endDate: Scalars["Time"];
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<HomeRoomSectionOrder>;
}>;

export type LessonPlansOverviewQuery = {
	__typename?: "Query";
	homeRoomSectionConnection: {
		__typename?: "HomeRoomSectionConnection";
		edges: Array<{
			__typename?: "HomeRoomSectionEdge";
			node: {
				__typename?: "HomeRoomSection";
				id: string;
				name: string;
				homeRoomSectionTeacherEnrollments: Array<{
					__typename?: "HomeRoomSectionTeacherEnrollment";
					id: string;
					teacher: {
						__typename?: "Employee";
						id: string;
						person: { __typename?: "Person"; id: string; name: string; imageURL: string };
					};
				}>;
				courseSections: Array<{
					__typename?: "CourseSection";
					id: string;
					name: string;
					course: { __typename?: "Course"; id: string; subject: CourseSubject };
					courseSectionPeriods: Array<{ __typename?: "CourseSectionPeriod"; id: string; dayOfWeek: number }>;
					lessonPlans: Array<{ __typename?: "LessonPlan"; id: string; courseDate: string }>;
				}>;
			};
		}>;
	};
};

export type LessonPlanPaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<LessonPlanFilterInput> | LessonPlanFilterInput>;
	order?: InputMaybe<LessonPlanOrder>;
}>;

export type LessonPlanPaginationQuery = {
	__typename?: "Query";
	lessonPlanConnection: {
		__typename?: "LessonPlanConnection";
		totalCount: number;
		edges: Array<{ __typename?: "LessonPlanEdge"; cursor: string }>;
	};
};

export type LessonPlanSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<LessonPlanFilterInput> | LessonPlanFilterInput>;
	order?: InputMaybe<LessonPlanOrder>;
}>;

export type LessonPlanSelectQuery = {
	__typename?: "Query";
	lessonPlanConnection: {
		__typename?: "LessonPlanConnection";
		edges: Array<{ __typename?: "LessonPlanEdge"; node: { __typename?: "LessonPlan"; id: string } }>;
	};
};

export type LessonPlanUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: LessonPlanUpdateInput;
}>;

export type LessonPlanUpdateMutation = {
	__typename?: "Mutation";
	updateLessonPlan: {
		__typename?: "LessonPlan";
		id: string;
		courseDate: string;
		lessonDescription: string;
		requiredResourcesAndMaterials: string;
		stationOneDescription: string;
		stationTwoDescription: string;
		stationThreeDescription: string;
		differentiationDescription: string;
		assessmentDescription: string;
		notes: string;
		courseSection: {
			__typename?: "CourseSection";
			id: string;
			name: string;
			course: { __typename?: "Course"; id: string; subject: CourseSubject };
			homeRoomSection: { __typename?: "HomeRoomSection"; id: string; name: string };
		};
		learningObjectives: Array<{ __typename?: "LearningObjective"; id: string; category: string; description: string }>;
	};
};

export type LoginRecordCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<LoginRecordFilterInput> | LoginRecordFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<LoginRecordOrder>;
}>;

export type LoginRecordCountQuery = {
	__typename?: "Query";
	loginRecordConnection: { __typename?: "LoginRecordConnection"; totalCount: number };
};

export type LoginRecordDetailFragment = {
	__typename?: "LoginRecord";
	id: string;
	createdAt: string;
	userEmail: string;
	wasBlocked: boolean;
	wasSuccessful: boolean;
	isTrusted: boolean;
	session: { __typename?: "Session"; id: string } | null;
	user: { __typename?: "User"; id: string; email: string } | null;
};

export type LoginRecordDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type LoginRecordDetailQuery = {
	__typename?: "Query";
	loginRecord: {
		__typename?: "LoginRecord";
		id: string;
		createdAt: string;
		userEmail: string;
		wasBlocked: boolean;
		wasSuccessful: boolean;
		isTrusted: boolean;
		session: { __typename?: "Session"; id: string } | null;
		user: { __typename?: "User"; id: string; email: string } | null;
	};
};

export type LoginRecordIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<LoginRecordFilterInput> | LoginRecordFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<LoginRecordOrder>;
}>;

export type LoginRecordIndexQuery = {
	__typename?: "Query";
	loginRecordConnection: {
		__typename?: "LoginRecordConnection";
		edges: Array<{
			__typename?: "LoginRecordEdge";
			node: {
				__typename?: "LoginRecord";
				id: string;
				createdAt: string;
				userEmail: string;
				wasBlocked: boolean;
				wasSuccessful: boolean;
				isTrusted: boolean;
			};
		}>;
	};
};

export type LoginRecordNameFragment = { __typename?: "LoginRecord"; id: string };

export type LoginRecordNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type LoginRecordNameQuery = { __typename?: "Query"; loginRecord: { __typename?: "LoginRecord"; id: string } };

export type LoginRecordNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<LoginRecordFilterInput> | LoginRecordFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<LoginRecordOrder>;
}>;

export type LoginRecordNamesQuery = {
	__typename?: "Query";
	loginRecordConnection: {
		__typename?: "LoginRecordConnection";
		edges: Array<{ __typename?: "LoginRecordEdge"; node: { __typename?: "LoginRecord"; id: string } }>;
	};
};

export type LoginRecordPaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<LoginRecordFilterInput> | LoginRecordFilterInput>;
	order?: InputMaybe<LoginRecordOrder>;
}>;

export type LoginRecordPaginationQuery = {
	__typename?: "Query";
	loginRecordConnection: {
		__typename?: "LoginRecordConnection";
		totalCount: number;
		edges: Array<{ __typename?: "LoginRecordEdge"; cursor: string }>;
	};
};

export type LoginRecordSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<LoginRecordFilterInput> | LoginRecordFilterInput>;
	order?: InputMaybe<LoginRecordOrder>;
}>;

export type LoginRecordSelectQuery = {
	__typename?: "Query";
	loginRecordConnection: {
		__typename?: "LoginRecordConnection";
		edges: Array<{ __typename?: "LoginRecordEdge"; node: { __typename?: "LoginRecord"; id: string } }>;
	};
};

export type MFAFactorCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<MFAFactorFilterInput> | MFAFactorFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<MFAFactorOrder>;
}>;

export type MFAFactorCountQuery = {
	__typename?: "Query";
	mfaFactorConnection: { __typename?: "MFAFactorConnection"; totalCount: number };
};

export type MFAFactorDetailFragment = {
	__typename?: "MFAFactor";
	id: string;
	createdAt: string;
	count: number;
	isApp: boolean;
	email: string;
	sms: string;
	isVerified: boolean;
	user: { __typename?: "User"; id: string; email: string };
};

export type MFAFactorDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type MFAFactorDetailQuery = {
	__typename?: "Query";
	mfaFactor: {
		__typename?: "MFAFactor";
		id: string;
		createdAt: string;
		count: number;
		isApp: boolean;
		email: string;
		sms: string;
		isVerified: boolean;
		user: { __typename?: "User"; id: string; email: string };
	};
};

export type MFAFactorIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<MFAFactorFilterInput> | MFAFactorFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<MFAFactorOrder>;
}>;

export type MFAFactorIndexQuery = {
	__typename?: "Query";
	mfaFactorConnection: {
		__typename?: "MFAFactorConnection";
		edges: Array<{
			__typename?: "MFAFactorEdge";
			node: {
				__typename?: "MFAFactor";
				id: string;
				createdAt: string;
				count: number;
				isApp: boolean;
				email: string;
				sms: string;
				isVerified: boolean;
			};
		}>;
	};
};

export type MFAFactorNameFragment = { __typename?: "MFAFactor"; email: string };

export type MFAFactorNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type MFAFactorNameQuery = {
	__typename?: "Query";
	mfaFactor: { __typename?: "MFAFactor"; id: string; email: string };
};

export type MFAFactorNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<MFAFactorFilterInput> | MFAFactorFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<MFAFactorOrder>;
}>;

export type MFAFactorNamesQuery = {
	__typename?: "Query";
	mfaFactorConnection: {
		__typename?: "MFAFactorConnection";
		edges: Array<{ __typename?: "MFAFactorEdge"; node: { __typename?: "MFAFactor"; id: string; email: string } }>;
	};
};

export type MFAFactorPaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<MFAFactorFilterInput> | MFAFactorFilterInput>;
	order?: InputMaybe<MFAFactorOrder>;
}>;

export type MFAFactorPaginationQuery = {
	__typename?: "Query";
	mfaFactorConnection: {
		__typename?: "MFAFactorConnection";
		totalCount: number;
		edges: Array<{ __typename?: "MFAFactorEdge"; cursor: string }>;
	};
};

export type MFAFactorSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<MFAFactorFilterInput> | MFAFactorFilterInput>;
	order?: InputMaybe<MFAFactorOrder>;
}>;

export type MFAFactorSelectQuery = {
	__typename?: "Query";
	mfaFactorConnection: {
		__typename?: "MFAFactorConnection";
		edges: Array<{ __typename?: "MFAFactorEdge"; node: { __typename?: "MFAFactor"; id: string; email: string } }>;
	};
};

export type MFAInvalidOTPAttemptCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<MFAInvalidOTPAttemptFilterInput> | MFAInvalidOTPAttemptFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<MFAInvalidOTPAttemptOrder>;
}>;

export type MFAInvalidOTPAttemptCountQuery = {
	__typename?: "Query";
	mfaInvalidOTPAttemptConnection: { __typename?: "MFAInvalidOTPAttemptConnection"; totalCount: number };
};

export type MFAInvalidOTPAttemptDetailFragment = {
	__typename?: "MFAInvalidOTPAttempt";
	id: string;
	createdAt: string;
	session: { __typename?: "Session"; id: string } | null;
	user: { __typename?: "User"; id: string; email: string };
};

export type MFAInvalidOTPAttemptDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type MFAInvalidOTPAttemptDetailQuery = {
	__typename?: "Query";
	mfaInvalidOTPAttempt: {
		__typename?: "MFAInvalidOTPAttempt";
		id: string;
		createdAt: string;
		session: { __typename?: "Session"; id: string } | null;
		user: { __typename?: "User"; id: string; email: string };
	};
};

export type MFAInvalidOTPAttemptIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<MFAInvalidOTPAttemptFilterInput> | MFAInvalidOTPAttemptFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<MFAInvalidOTPAttemptOrder>;
}>;

export type MFAInvalidOTPAttemptIndexQuery = {
	__typename?: "Query";
	mfaInvalidOTPAttemptConnection: {
		__typename?: "MFAInvalidOTPAttemptConnection";
		edges: Array<{
			__typename?: "MFAInvalidOTPAttemptEdge";
			node: { __typename?: "MFAInvalidOTPAttempt"; id: string; createdAt: string };
		}>;
	};
};

export type MFAInvalidOTPAttemptNameFragment = { __typename?: "MFAInvalidOTPAttempt"; id: string };

export type MFAInvalidOTPAttemptNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type MFAInvalidOTPAttemptNameQuery = {
	__typename?: "Query";
	mfaInvalidOTPAttempt: { __typename?: "MFAInvalidOTPAttempt"; id: string };
};

export type MFAInvalidOTPAttemptNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<MFAInvalidOTPAttemptFilterInput> | MFAInvalidOTPAttemptFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<MFAInvalidOTPAttemptOrder>;
}>;

export type MFAInvalidOTPAttemptNamesQuery = {
	__typename?: "Query";
	mfaInvalidOTPAttemptConnection: {
		__typename?: "MFAInvalidOTPAttemptConnection";
		edges: Array<{
			__typename?: "MFAInvalidOTPAttemptEdge";
			node: { __typename?: "MFAInvalidOTPAttempt"; id: string };
		}>;
	};
};

export type MFAInvalidOTPAttemptPaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<MFAInvalidOTPAttemptFilterInput> | MFAInvalidOTPAttemptFilterInput>;
	order?: InputMaybe<MFAInvalidOTPAttemptOrder>;
}>;

export type MFAInvalidOTPAttemptPaginationQuery = {
	__typename?: "Query";
	mfaInvalidOTPAttemptConnection: {
		__typename?: "MFAInvalidOTPAttemptConnection";
		totalCount: number;
		edges: Array<{ __typename?: "MFAInvalidOTPAttemptEdge"; cursor: string }>;
	};
};

export type MFAInvalidOTPAttemptSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<MFAInvalidOTPAttemptFilterInput> | MFAInvalidOTPAttemptFilterInput>;
	order?: InputMaybe<MFAInvalidOTPAttemptOrder>;
}>;

export type MFAInvalidOTPAttemptSelectQuery = {
	__typename?: "Query";
	mfaInvalidOTPAttemptConnection: {
		__typename?: "MFAInvalidOTPAttemptConnection";
		edges: Array<{
			__typename?: "MFAInvalidOTPAttemptEdge";
			node: { __typename?: "MFAInvalidOTPAttempt"; id: string };
		}>;
	};
};

export type MealMenuCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<MealMenuFilterInput> | MealMenuFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<MealMenuOrder>;
}>;

export type MealMenuCountQuery = {
	__typename?: "Query";
	mealMenuConnection: { __typename?: "MealMenuConnection"; totalCount: number };
};

export type MealMenuCreateMutationVariables = Exact<{
	input: MealMenuCreateInput;
}>;

export type MealMenuCreateMutation = {
	__typename?: "Mutation";
	createMealMenu: {
		__typename?: "MealMenu";
		id: string;
		menuDate: string;
		breakfast: string;
		lunch: string;
		snack: string;
		vegetarianBreakfast: string;
		vegetarianLunch: string;
		vegetarianSnack: string;
	};
};

export type MealMenuCreateBulkMutationVariables = Exact<{
	inputs: Array<MealMenuCreateInput> | MealMenuCreateInput;
}>;

export type MealMenuCreateBulkMutation = {
	__typename?: "Mutation";
	createBulkMealMenus: {
		__typename?: "MealMenuConnection";
		edges: Array<{
			__typename?: "MealMenuEdge";
			node: {
				__typename?: "MealMenu";
				id: string;
				menuDate: string;
				breakfast: string;
				lunch: string;
				snack: string;
				vegetarianBreakfast: string;
				vegetarianLunch: string;
				vegetarianSnack: string;
			};
		}>;
	};
};

export type MealMenuDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type MealMenuDeleteMutation = { __typename?: "Mutation"; deleteMealMenu: boolean };

export type MealMenuDetailFragment = {
	__typename?: "MealMenu";
	id: string;
	menuDate: string;
	breakfast: string;
	lunch: string;
	snack: string;
	vegetarianBreakfast: string;
	vegetarianLunch: string;
	vegetarianSnack: string;
};

export type MealMenuDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type MealMenuDetailQuery = {
	__typename?: "Query";
	mealMenu: {
		__typename?: "MealMenu";
		id: string;
		menuDate: string;
		breakfast: string;
		lunch: string;
		snack: string;
		vegetarianBreakfast: string;
		vegetarianLunch: string;
		vegetarianSnack: string;
	};
};

export type MealMenuIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<MealMenuFilterInput> | MealMenuFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<MealMenuOrder>;
}>;

export type MealMenuIndexQuery = {
	__typename?: "Query";
	mealMenuConnection: {
		__typename?: "MealMenuConnection";
		edges: Array<{
			__typename?: "MealMenuEdge";
			node: {
				__typename?: "MealMenu";
				id: string;
				menuDate: string;
				breakfast: string;
				lunch: string;
				snack: string;
				vegetarianBreakfast: string;
				vegetarianLunch: string;
				vegetarianSnack: string;
			};
		}>;
	};
};

export type MealMenuNameFragment = { __typename?: "MealMenu"; id: string };

export type MealMenuNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type MealMenuNameQuery = { __typename?: "Query"; mealMenu: { __typename?: "MealMenu"; id: string } };

export type MealMenuNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<MealMenuFilterInput> | MealMenuFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<MealMenuOrder>;
}>;

export type MealMenuNamesQuery = {
	__typename?: "Query";
	mealMenuConnection: {
		__typename?: "MealMenuConnection";
		edges: Array<{ __typename?: "MealMenuEdge"; node: { __typename?: "MealMenu"; id: string } }>;
	};
};

export type MealMenuPaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<MealMenuFilterInput> | MealMenuFilterInput>;
	order?: InputMaybe<MealMenuOrder>;
}>;

export type MealMenuPaginationQuery = {
	__typename?: "Query";
	mealMenuConnection: {
		__typename?: "MealMenuConnection";
		totalCount: number;
		edges: Array<{ __typename?: "MealMenuEdge"; cursor: string }>;
	};
};

export type MealMenuSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<MealMenuFilterInput> | MealMenuFilterInput>;
	order?: InputMaybe<MealMenuOrder>;
}>;

export type MealMenuSelectQuery = {
	__typename?: "Query";
	mealMenuConnection: {
		__typename?: "MealMenuConnection";
		edges: Array<{ __typename?: "MealMenuEdge"; node: { __typename?: "MealMenu"; id: string } }>;
	};
};

export type MealMenuUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: MealMenuUpdateInput;
}>;

export type MealMenuUpdateMutation = {
	__typename?: "Mutation";
	updateMealMenu: {
		__typename?: "MealMenu";
		id: string;
		menuDate: string;
		breakfast: string;
		lunch: string;
		snack: string;
		vegetarianBreakfast: string;
		vegetarianLunch: string;
		vegetarianSnack: string;
	};
};

export type MealPlanCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<MealPlanFilterInput> | MealPlanFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<MealPlanOrder>;
}>;

export type MealPlanCountQuery = {
	__typename?: "Query";
	mealPlanConnection: { __typename?: "MealPlanConnection"; totalCount: number };
};

export type MealPlanCreateMutationVariables = Exact<{
	input: MealPlanCreateInput;
}>;

export type MealPlanCreateMutation = {
	__typename?: "Mutation";
	createMealPlan: {
		__typename?: "MealPlan";
		id: string;
		notes: string;
		student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
	};
};

export type MealPlanDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type MealPlanDeleteMutation = { __typename?: "Mutation"; deleteMealPlan: boolean };

export type MealPlanDetailFragment = {
	__typename?: "MealPlan";
	id: string;
	notes: string;
	student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
};

export type MealPlanDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type MealPlanDetailQuery = {
	__typename?: "Query";
	mealPlan: {
		__typename?: "MealPlan";
		id: string;
		notes: string;
		student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
	};
};

export type MealPlanIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<MealPlanFilterInput> | MealPlanFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<MealPlanOrder>;
}>;

export type MealPlanIndexQuery = {
	__typename?: "Query";
	mealPlanConnection: {
		__typename?: "MealPlanConnection";
		edges: Array<{
			__typename?: "MealPlanEdge";
			node: {
				__typename?: "MealPlan";
				id: string;
				notes: string;
				student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
			};
		}>;
	};
};

export type MealPlanNameFragment = {
	__typename?: "MealPlan";
	student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
};

export type MealPlanNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type MealPlanNameQuery = {
	__typename?: "Query";
	mealPlan: {
		__typename?: "MealPlan";
		id: string;
		student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
	};
};

export type MealPlanNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<MealPlanFilterInput> | MealPlanFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<MealPlanOrder>;
}>;

export type MealPlanNamesQuery = {
	__typename?: "Query";
	mealPlanConnection: {
		__typename?: "MealPlanConnection";
		edges: Array<{
			__typename?: "MealPlanEdge";
			node: {
				__typename?: "MealPlan";
				id: string;
				student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
			};
		}>;
	};
};

export type MealPlanPaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<MealPlanFilterInput> | MealPlanFilterInput>;
	order?: InputMaybe<MealPlanOrder>;
}>;

export type MealPlanPaginationQuery = {
	__typename?: "Query";
	mealPlanConnection: {
		__typename?: "MealPlanConnection";
		totalCount: number;
		edges: Array<{ __typename?: "MealPlanEdge"; cursor: string }>;
	};
};

export type MealPlanSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<MealPlanFilterInput> | MealPlanFilterInput>;
	order?: InputMaybe<MealPlanOrder>;
}>;

export type MealPlanSelectQuery = {
	__typename?: "Query";
	mealPlanConnection: {
		__typename?: "MealPlanConnection";
		edges: Array<{
			__typename?: "MealPlanEdge";
			node: {
				__typename?: "MealPlan";
				id: string;
				student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
			};
		}>;
	};
};

export type MealPlanUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: MealPlanUpdateInput;
}>;

export type MealPlanUpdateMutation = {
	__typename?: "Mutation";
	updateMealPlan: {
		__typename?: "MealPlan";
		id: string;
		notes: string;
		student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
	};
};

export type AnnouncementsQueryVariables = Exact<{
	filters?: InputMaybe<Array<NoteFilterInput> | NoteFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<NoteOrder>;
}>;

export type AnnouncementsQuery = {
	__typename?: "Query";
	noteConnection: {
		__typename?: "NoteConnection";
		edges: Array<{
			__typename?: "NoteEdge";
			node: {
				__typename?: "Note";
				id: string;
				noteType: NoteNoteType;
				title: string;
				body: string;
				createdAt: string;
				isTimeDependent: boolean;
				startDate: string | null;
				endDate: string | null;
				author: {
					__typename?: "User";
					id: string;
					person: { __typename?: "Person"; id: string; name: string; imageURL: string } | null;
				} | null;
				attachments: Array<{
					__typename?: "NoteAttachment";
					id: string;
					order: number;
					url: string;
					isFile: boolean;
					fileContentType: string;
				}>;
			};
		}>;
	};
};

export type NoteCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<NoteFilterInput> | NoteFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<NoteOrder>;
}>;

export type NoteCountQuery = {
	__typename?: "Query";
	noteConnection: { __typename?: "NoteConnection"; totalCount: number };
};

export type NoteCreateMutationVariables = Exact<{
	input: NoteCreateInput;
}>;

export type NoteCreateMutation = {
	__typename?: "Mutation";
	createNote: {
		__typename?: "Note";
		id: string;
		noteType: NoteNoteType;
		title: string;
		body: string;
		details: string;
		createdAt: string;
		isTimeDependent: boolean;
		startDate: string | null;
		endDate: string | null;
		isPublic: boolean;
		attachments: Array<{ __typename?: "NoteAttachment"; id: string; order: number; isFile: boolean; url: string }>;
		author: {
			__typename?: "User";
			id: string;
			email: string;
			person: {
				__typename?: "Person";
				id: string;
				name: string;
				imageURL: string;
				employee: { __typename?: "Employee"; id: string } | null;
			} | null;
		} | null;
		calendarEvents: Array<{ __typename?: "CalendarEvent"; id: string; name: string }>;
		comments: Array<{ __typename?: "NoteComment"; id: string }>;
		courseSections: Array<{ __typename?: "CourseSection"; id: string; name: string }>;
		employees: Array<{
			__typename?: "Employee";
			id: string;
			person: { __typename?: "Person"; id: string; name: string; imageURL: string };
		}>;
		homeRoomSections: Array<{ __typename?: "HomeRoomSection"; id: string; name: string }>;
		parents: Array<{
			__typename?: "Parent";
			id: string;
			person: { __typename?: "Person"; id: string; name: string; imageURL: string };
		}>;
		reactions: Array<{ __typename?: "NoteReaction"; id: string }>;
		sharingUsers: Array<{
			__typename?: "User";
			id: string;
			email: string;
			person: { __typename?: "Person"; id: string; name: string; imageURL: string } | null;
		}>;
		students: Array<{
			__typename?: "Student";
			id: string;
			person: { __typename?: "Person"; id: string; name: string; imageURL: string };
		}>;
	};
};

export type NoteDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type NoteDeleteMutation = { __typename?: "Mutation"; deleteNote: boolean };

export type NoteDetailFragment = {
	__typename?: "Note";
	id: string;
	noteType: NoteNoteType;
	title: string;
	body: string;
	details: string;
	createdAt: string;
	isTimeDependent: boolean;
	startDate: string | null;
	endDate: string | null;
	isPublic: boolean;
	attachments: Array<{ __typename?: "NoteAttachment"; id: string; order: number; isFile: boolean; url: string }>;
	author: {
		__typename?: "User";
		id: string;
		email: string;
		person: {
			__typename?: "Person";
			id: string;
			name: string;
			imageURL: string;
			employee: { __typename?: "Employee"; id: string } | null;
		} | null;
	} | null;
	calendarEvents: Array<{ __typename?: "CalendarEvent"; id: string; name: string }>;
	comments: Array<{ __typename?: "NoteComment"; id: string }>;
	courseSections: Array<{ __typename?: "CourseSection"; id: string; name: string }>;
	employees: Array<{
		__typename?: "Employee";
		id: string;
		person: { __typename?: "Person"; id: string; name: string; imageURL: string };
	}>;
	homeRoomSections: Array<{ __typename?: "HomeRoomSection"; id: string; name: string }>;
	parents: Array<{
		__typename?: "Parent";
		id: string;
		person: { __typename?: "Person"; id: string; name: string; imageURL: string };
	}>;
	reactions: Array<{ __typename?: "NoteReaction"; id: string }>;
	sharingUsers: Array<{
		__typename?: "User";
		id: string;
		email: string;
		person: { __typename?: "Person"; id: string; name: string; imageURL: string } | null;
	}>;
	students: Array<{
		__typename?: "Student";
		id: string;
		person: { __typename?: "Person"; id: string; name: string; imageURL: string };
	}>;
};

export type NoteDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type NoteDetailQuery = {
	__typename?: "Query";
	note: {
		__typename?: "Note";
		id: string;
		noteType: NoteNoteType;
		title: string;
		body: string;
		details: string;
		createdAt: string;
		isTimeDependent: boolean;
		startDate: string | null;
		endDate: string | null;
		isPublic: boolean;
		attachments: Array<{ __typename?: "NoteAttachment"; id: string; order: number; isFile: boolean; url: string }>;
		author: {
			__typename?: "User";
			id: string;
			email: string;
			person: {
				__typename?: "Person";
				id: string;
				name: string;
				imageURL: string;
				employee: { __typename?: "Employee"; id: string } | null;
			} | null;
		} | null;
		calendarEvents: Array<{ __typename?: "CalendarEvent"; id: string; name: string }>;
		comments: Array<{ __typename?: "NoteComment"; id: string }>;
		courseSections: Array<{ __typename?: "CourseSection"; id: string; name: string }>;
		employees: Array<{
			__typename?: "Employee";
			id: string;
			person: { __typename?: "Person"; id: string; name: string; imageURL: string };
		}>;
		homeRoomSections: Array<{ __typename?: "HomeRoomSection"; id: string; name: string }>;
		parents: Array<{
			__typename?: "Parent";
			id: string;
			person: { __typename?: "Person"; id: string; name: string; imageURL: string };
		}>;
		reactions: Array<{ __typename?: "NoteReaction"; id: string }>;
		sharingUsers: Array<{
			__typename?: "User";
			id: string;
			email: string;
			person: { __typename?: "Person"; id: string; name: string; imageURL: string } | null;
		}>;
		students: Array<{
			__typename?: "Student";
			id: string;
			person: { __typename?: "Person"; id: string; name: string; imageURL: string };
		}>;
	};
};

export type NoteIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<NoteFilterInput> | NoteFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<NoteOrder>;
}>;

export type NoteIndexQuery = {
	__typename?: "Query";
	noteConnection: {
		__typename?: "NoteConnection";
		edges: Array<{
			__typename?: "NoteEdge";
			node: {
				__typename?: "Note";
				id: string;
				noteType: NoteNoteType;
				title: string;
				body: string;
				details: string;
				createdAt: string;
				isTimeDependent: boolean;
				startDate: string | null;
				endDate: string | null;
				isPublic: boolean;
				author: {
					__typename?: "User";
					id: string;
					person: { __typename?: "Person"; id: string; name: string; imageURL: string } | null;
				} | null;
				sharingUsers: Array<{
					__typename?: "User";
					id: string;
					email: string;
					person: { __typename?: "Person"; id: string; name: string; imageURL: string } | null;
				}>;
			};
		}>;
	};
};

export type NoteNameFragment = { __typename?: "Note"; title: string };

export type NoteNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type NoteNameQuery = { __typename?: "Query"; note: { __typename?: "Note"; id: string; title: string } };

export type NoteNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<NoteFilterInput> | NoteFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<NoteOrder>;
}>;

export type NoteNamesQuery = {
	__typename?: "Query";
	noteConnection: {
		__typename?: "NoteConnection";
		edges: Array<{ __typename?: "NoteEdge"; node: { __typename?: "Note"; id: string; title: string } }>;
	};
};

export type NotePaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<NoteFilterInput> | NoteFilterInput>;
	order?: InputMaybe<NoteOrder>;
}>;

export type NotePaginationQuery = {
	__typename?: "Query";
	noteConnection: {
		__typename?: "NoteConnection";
		totalCount: number;
		edges: Array<{ __typename?: "NoteEdge"; cursor: string }>;
	};
};

export type NoteSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<NoteFilterInput> | NoteFilterInput>;
	order?: InputMaybe<NoteOrder>;
}>;

export type NoteSelectQuery = {
	__typename?: "Query";
	noteConnection: {
		__typename?: "NoteConnection";
		edges: Array<{ __typename?: "NoteEdge"; node: { __typename?: "Note"; id: string; title: string } }>;
	};
};

export type StudentAttendanceNoteSlimListQueryVariables = Exact<{
	filters?: InputMaybe<Array<NoteFilterInput> | NoteFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<NoteOrder>;
}>;

export type StudentAttendanceNoteSlimListQuery = {
	__typename?: "Query";
	noteConnection: {
		__typename?: "NoteConnection";
		edges: Array<{
			__typename?: "NoteEdge";
			node: {
				__typename?: "Note";
				id: string;
				noteType: NoteNoteType;
				body: string;
				startDate: string | null;
				endDate: string | null;
			};
		}>;
	};
};

export type NoteStudentNotesSlimListQueryVariables = Exact<{
	filters?: InputMaybe<Array<NoteFilterInput> | NoteFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<NoteOrder>;
}>;

export type NoteStudentNotesSlimListQuery = {
	__typename?: "Query";
	noteConnection: {
		__typename?: "NoteConnection";
		edges: Array<{
			__typename?: "NoteEdge";
			node: {
				__typename?: "Note";
				id: string;
				noteType: NoteNoteType;
				title: string;
				body: string;
				createdAt: string;
				isTimeDependent: boolean;
				startDate: string | null;
				endDate: string | null;
				students: Array<{
					__typename?: "Student";
					id: string;
					person: { __typename?: "Person"; id: string; name: string; imageURL: string };
				}>;
				author: {
					__typename?: "User";
					id: string;
					email: string;
					person: {
						__typename?: "Person";
						id: string;
						name: string;
						imageURL: string;
						employee: { __typename?: "Employee"; id: string } | null;
					} | null;
				} | null;
			};
		}>;
	};
};

export type NoteStudentNotesListQueryVariables = Exact<{
	filters?: InputMaybe<Array<NoteFilterInput> | NoteFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<NoteOrder>;
}>;

export type NoteStudentNotesListQuery = {
	__typename?: "Query";
	noteConnection: {
		__typename?: "NoteConnection";
		edges: Array<{
			__typename?: "NoteEdge";
			node: {
				__typename?: "Note";
				id: string;
				noteType: NoteNoteType;
				title: string;
				body: string;
				details: string;
				createdAt: string;
				isTimeDependent: boolean;
				startDate: string | null;
				endDate: string | null;
				isPublic: boolean;
				students: Array<{
					__typename?: "Student";
					id: string;
					person: { __typename?: "Person"; id: string; name: string; imageURL: string };
				}>;
				author: {
					__typename?: "User";
					id: string;
					email: string;
					person: {
						__typename?: "Person";
						id: string;
						name: string;
						imageURL: string;
						employee: { __typename?: "Employee"; id: string } | null;
					} | null;
				} | null;
				sharingUsers: Array<{
					__typename?: "User";
					id: string;
					email: string;
					person: { __typename?: "Person"; id: string; name: string; imageURL: string } | null;
				}>;
			};
		}>;
	};
};

export type StudentNotesListQueryVariables = Exact<{
	filters?: InputMaybe<Array<NoteFilterInput> | NoteFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<NoteOrder>;
}>;

export type StudentNotesListQuery = {
	__typename?: "Query";
	noteConnection: {
		__typename?: "NoteConnection";
		edges: Array<{
			__typename?: "NoteEdge";
			node: {
				__typename?: "Note";
				id: string;
				noteType: NoteNoteType;
				title: string;
				body: string;
				details: string;
				createdAt: string;
				isTimeDependent: boolean;
				startDate: string | null;
				endDate: string | null;
				isPublic: boolean;
				students: Array<{
					__typename?: "Student";
					id: string;
					person: { __typename?: "Person"; id: string; name: string };
				}>;
				author: {
					__typename?: "User";
					id: string;
					email: string;
					person: { __typename?: "Person"; id: string; name: string; imageURL: string } | null;
				} | null;
				sharingUsers: Array<{
					__typename?: "User";
					id: string;
					email: string;
					person: { __typename?: "Person"; id: string; name: string; imageURL: string } | null;
				}>;
			};
		}>;
	};
};

export type NoteUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: NoteUpdateInput;
}>;

export type NoteUpdateMutation = {
	__typename?: "Mutation";
	updateNote: {
		__typename?: "Note";
		id: string;
		noteType: NoteNoteType;
		title: string;
		body: string;
		details: string;
		createdAt: string;
		isTimeDependent: boolean;
		startDate: string | null;
		endDate: string | null;
		isPublic: boolean;
		attachments: Array<{ __typename?: "NoteAttachment"; id: string; order: number; isFile: boolean; url: string }>;
		author: {
			__typename?: "User";
			id: string;
			email: string;
			person: {
				__typename?: "Person";
				id: string;
				name: string;
				imageURL: string;
				employee: { __typename?: "Employee"; id: string } | null;
			} | null;
		} | null;
		calendarEvents: Array<{ __typename?: "CalendarEvent"; id: string; name: string }>;
		comments: Array<{ __typename?: "NoteComment"; id: string }>;
		courseSections: Array<{ __typename?: "CourseSection"; id: string; name: string }>;
		employees: Array<{
			__typename?: "Employee";
			id: string;
			person: { __typename?: "Person"; id: string; name: string; imageURL: string };
		}>;
		homeRoomSections: Array<{ __typename?: "HomeRoomSection"; id: string; name: string }>;
		parents: Array<{
			__typename?: "Parent";
			id: string;
			person: { __typename?: "Person"; id: string; name: string; imageURL: string };
		}>;
		reactions: Array<{ __typename?: "NoteReaction"; id: string }>;
		sharingUsers: Array<{
			__typename?: "User";
			id: string;
			email: string;
			person: { __typename?: "Person"; id: string; name: string; imageURL: string } | null;
		}>;
		students: Array<{
			__typename?: "Student";
			id: string;
			person: { __typename?: "Person"; id: string; name: string; imageURL: string };
		}>;
	};
};

export type NoteAttachmentCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<NoteAttachmentFilterInput> | NoteAttachmentFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<NoteAttachmentOrder>;
}>;

export type NoteAttachmentCountQuery = {
	__typename?: "Query";
	noteAttachmentConnection: { __typename?: "NoteAttachmentConnection"; totalCount: number };
};

export type NoteAttachmentCreateMutationVariables = Exact<{
	input: NoteAttachmentCreateInput;
}>;

export type NoteAttachmentCreateMutation = {
	__typename?: "Mutation";
	createNoteAttachment: {
		__typename?: "NoteAttachment";
		id: string;
		order: number;
		isFile: boolean;
		fileURL: string;
		linkURL: string;
		url: string;
		note: { __typename?: "Note"; id: string; title: string };
	};
};

export type NoteAttachmentDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type NoteAttachmentDeleteMutation = { __typename?: "Mutation"; deleteNoteAttachment: boolean };

export type NoteAttachmentDetailFragment = {
	__typename?: "NoteAttachment";
	id: string;
	order: number;
	isFile: boolean;
	fileURL: string;
	linkURL: string;
	url: string;
	note: { __typename?: "Note"; id: string; title: string };
};

export type NoteAttachmentDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type NoteAttachmentDetailQuery = {
	__typename?: "Query";
	noteAttachment: {
		__typename?: "NoteAttachment";
		id: string;
		order: number;
		isFile: boolean;
		fileURL: string;
		linkURL: string;
		url: string;
		note: { __typename?: "Note"; id: string; title: string };
	};
};

export type NoteAttachmentIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<NoteAttachmentFilterInput> | NoteAttachmentFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<NoteAttachmentOrder>;
}>;

export type NoteAttachmentIndexQuery = {
	__typename?: "Query";
	noteAttachmentConnection: {
		__typename?: "NoteAttachmentConnection";
		edges: Array<{
			__typename?: "NoteAttachmentEdge";
			node: {
				__typename?: "NoteAttachment";
				id: string;
				order: number;
				isFile: boolean;
				fileURL: string;
				linkURL: string;
				url: string;
			};
		}>;
	};
};

export type NoteAttachmentNameFragment = { __typename?: "NoteAttachment"; id: string };

export type NoteAttachmentNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type NoteAttachmentNameQuery = {
	__typename?: "Query";
	noteAttachment: { __typename?: "NoteAttachment"; id: string };
};

export type NoteAttachmentNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<NoteAttachmentFilterInput> | NoteAttachmentFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<NoteAttachmentOrder>;
}>;

export type NoteAttachmentNamesQuery = {
	__typename?: "Query";
	noteAttachmentConnection: {
		__typename?: "NoteAttachmentConnection";
		edges: Array<{ __typename?: "NoteAttachmentEdge"; node: { __typename?: "NoteAttachment"; id: string } }>;
	};
};

export type NoteAttachmentPaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<NoteAttachmentFilterInput> | NoteAttachmentFilterInput>;
	order?: InputMaybe<NoteAttachmentOrder>;
}>;

export type NoteAttachmentPaginationQuery = {
	__typename?: "Query";
	noteAttachmentConnection: {
		__typename?: "NoteAttachmentConnection";
		totalCount: number;
		edges: Array<{ __typename?: "NoteAttachmentEdge"; cursor: string }>;
	};
};

export type NoteAttachmentSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<NoteAttachmentFilterInput> | NoteAttachmentFilterInput>;
	order?: InputMaybe<NoteAttachmentOrder>;
}>;

export type NoteAttachmentSelectQuery = {
	__typename?: "Query";
	noteAttachmentConnection: {
		__typename?: "NoteAttachmentConnection";
		edges: Array<{ __typename?: "NoteAttachmentEdge"; node: { __typename?: "NoteAttachment"; id: string } }>;
	};
};

export type NoteAttachmentUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: NoteAttachmentUpdateInput;
}>;

export type NoteAttachmentUpdateMutation = {
	__typename?: "Mutation";
	updateNoteAttachment: {
		__typename?: "NoteAttachment";
		id: string;
		order: number;
		isFile: boolean;
		fileURL: string;
		linkURL: string;
		url: string;
		note: { __typename?: "Note"; id: string; title: string };
	};
};

export type NoteCommentCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<NoteCommentFilterInput> | NoteCommentFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<NoteCommentOrder>;
}>;

export type NoteCommentCountQuery = {
	__typename?: "Query";
	noteCommentConnection: { __typename?: "NoteCommentConnection"; totalCount: number };
};

export type NoteCommentCreateMutationVariables = Exact<{
	input: NoteCommentCreateInput;
}>;

export type NoteCommentCreateMutation = {
	__typename?: "Mutation";
	createNoteComment: {
		__typename?: "NoteComment";
		id: string;
		body: string;
		createdAt: string;
		author: { __typename?: "User"; id: string; email: string } | null;
		note: { __typename?: "Note"; id: string; title: string };
	};
};

export type NoteCommentDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type NoteCommentDeleteMutation = { __typename?: "Mutation"; deleteNoteComment: boolean };

export type NoteCommentDetailFragment = {
	__typename?: "NoteComment";
	id: string;
	body: string;
	createdAt: string;
	author: { __typename?: "User"; id: string; email: string } | null;
	note: { __typename?: "Note"; id: string; title: string };
};

export type NoteCommentDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type NoteCommentDetailQuery = {
	__typename?: "Query";
	noteComment: {
		__typename?: "NoteComment";
		id: string;
		body: string;
		createdAt: string;
		author: { __typename?: "User"; id: string; email: string } | null;
		note: { __typename?: "Note"; id: string; title: string };
	};
};

export type NoteCommentIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<NoteCommentFilterInput> | NoteCommentFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<NoteCommentOrder>;
}>;

export type NoteCommentIndexQuery = {
	__typename?: "Query";
	noteCommentConnection: {
		__typename?: "NoteCommentConnection";
		edges: Array<{
			__typename?: "NoteCommentEdge";
			node: { __typename?: "NoteComment"; id: string; body: string; createdAt: string };
		}>;
	};
};

export type NoteCommentNameFragment = { __typename?: "NoteComment"; id: string };

export type NoteCommentNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type NoteCommentNameQuery = { __typename?: "Query"; noteComment: { __typename?: "NoteComment"; id: string } };

export type NoteCommentNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<NoteCommentFilterInput> | NoteCommentFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<NoteCommentOrder>;
}>;

export type NoteCommentNamesQuery = {
	__typename?: "Query";
	noteCommentConnection: {
		__typename?: "NoteCommentConnection";
		edges: Array<{ __typename?: "NoteCommentEdge"; node: { __typename?: "NoteComment"; id: string } }>;
	};
};

export type NoteCommentPaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<NoteCommentFilterInput> | NoteCommentFilterInput>;
	order?: InputMaybe<NoteCommentOrder>;
}>;

export type NoteCommentPaginationQuery = {
	__typename?: "Query";
	noteCommentConnection: {
		__typename?: "NoteCommentConnection";
		totalCount: number;
		edges: Array<{ __typename?: "NoteCommentEdge"; cursor: string }>;
	};
};

export type NoteCommentSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<NoteCommentFilterInput> | NoteCommentFilterInput>;
	order?: InputMaybe<NoteCommentOrder>;
}>;

export type NoteCommentSelectQuery = {
	__typename?: "Query";
	noteCommentConnection: {
		__typename?: "NoteCommentConnection";
		edges: Array<{ __typename?: "NoteCommentEdge"; node: { __typename?: "NoteComment"; id: string } }>;
	};
};

export type NoteCommentUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: NoteCommentUpdateInput;
}>;

export type NoteCommentUpdateMutation = {
	__typename?: "Mutation";
	updateNoteComment: {
		__typename?: "NoteComment";
		id: string;
		body: string;
		createdAt: string;
		author: { __typename?: "User"; id: string; email: string } | null;
		note: { __typename?: "Note"; id: string; title: string };
	};
};

export type NoteReactionCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<NoteReactionFilterInput> | NoteReactionFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<NoteReactionOrder>;
}>;

export type NoteReactionCountQuery = {
	__typename?: "Query";
	noteReactionConnection: { __typename?: "NoteReactionConnection"; totalCount: number };
};

export type NoteReactionCreateMutationVariables = Exact<{
	input: NoteReactionCreateInput;
}>;

export type NoteReactionCreateMutation = {
	__typename?: "Mutation";
	createNoteReaction: {
		__typename?: "NoteReaction";
		id: string;
		reactionType: NoteReactionReactionType;
		createdAt: string;
		author: { __typename?: "User"; id: string; email: string } | null;
		note: { __typename?: "Note"; id: string; title: string };
	};
};

export type NoteReactionDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type NoteReactionDeleteMutation = { __typename?: "Mutation"; deleteNoteReaction: boolean };

export type NoteReactionDetailFragment = {
	__typename?: "NoteReaction";
	id: string;
	reactionType: NoteReactionReactionType;
	createdAt: string;
	author: { __typename?: "User"; id: string; email: string } | null;
	note: { __typename?: "Note"; id: string; title: string };
};

export type NoteReactionDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type NoteReactionDetailQuery = {
	__typename?: "Query";
	noteReaction: {
		__typename?: "NoteReaction";
		id: string;
		reactionType: NoteReactionReactionType;
		createdAt: string;
		author: { __typename?: "User"; id: string; email: string } | null;
		note: { __typename?: "Note"; id: string; title: string };
	};
};

export type NoteReactionIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<NoteReactionFilterInput> | NoteReactionFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<NoteReactionOrder>;
}>;

export type NoteReactionIndexQuery = {
	__typename?: "Query";
	noteReactionConnection: {
		__typename?: "NoteReactionConnection";
		edges: Array<{
			__typename?: "NoteReactionEdge";
			node: { __typename?: "NoteReaction"; id: string; reactionType: NoteReactionReactionType; createdAt: string };
		}>;
	};
};

export type NoteReactionNameFragment = { __typename?: "NoteReaction"; id: string };

export type NoteReactionNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type NoteReactionNameQuery = { __typename?: "Query"; noteReaction: { __typename?: "NoteReaction"; id: string } };

export type NoteReactionNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<NoteReactionFilterInput> | NoteReactionFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<NoteReactionOrder>;
}>;

export type NoteReactionNamesQuery = {
	__typename?: "Query";
	noteReactionConnection: {
		__typename?: "NoteReactionConnection";
		edges: Array<{ __typename?: "NoteReactionEdge"; node: { __typename?: "NoteReaction"; id: string } }>;
	};
};

export type NoteReactionPaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<NoteReactionFilterInput> | NoteReactionFilterInput>;
	order?: InputMaybe<NoteReactionOrder>;
}>;

export type NoteReactionPaginationQuery = {
	__typename?: "Query";
	noteReactionConnection: {
		__typename?: "NoteReactionConnection";
		totalCount: number;
		edges: Array<{ __typename?: "NoteReactionEdge"; cursor: string }>;
	};
};

export type NoteReactionSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<NoteReactionFilterInput> | NoteReactionFilterInput>;
	order?: InputMaybe<NoteReactionOrder>;
}>;

export type NoteReactionSelectQuery = {
	__typename?: "Query";
	noteReactionConnection: {
		__typename?: "NoteReactionConnection";
		edges: Array<{ __typename?: "NoteReactionEdge"; node: { __typename?: "NoteReaction"; id: string } }>;
	};
};

export type NoteReactionUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: NoteReactionUpdateInput;
}>;

export type NoteReactionUpdateMutation = {
	__typename?: "Mutation";
	updateNoteReaction: {
		__typename?: "NoteReaction";
		id: string;
		reactionType: NoteReactionReactionType;
		createdAt: string;
		author: { __typename?: "User"; id: string; email: string } | null;
		note: { __typename?: "Note"; id: string; title: string };
	};
};

export type ParentCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<ParentFilterInput> | ParentFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<ParentOrder>;
}>;

export type ParentCountQuery = {
	__typename?: "Query";
	parentConnection: { __typename?: "ParentConnection"; totalCount: number };
};

export type ParentCreateMutationVariables = Exact<{
	input: ParentCreateInput;
}>;

export type ParentCreateMutation = {
	__typename?: "Mutation";
	createParent: {
		__typename?: "Parent";
		id: string;
		payments: Array<{ __typename?: "Payment"; id: string }>;
		person: {
			__typename?: "Person";
			id: string;
			name: string;
			firstName: string;
			lastName: string;
			nickname: string;
			alternateNames: string;
			imageURL: string;
			gender: string;
			dateOfBirth: string | null;
			occupation: string;
			nationality: string;
			primaryLanguage: string;
			englishLanguageFluency: PersonEnglishLanguageFluency;
			addresses: Array<{ __typename?: "Address"; id: string; shortAddress: string }>;
			emailAddresses: Array<{ __typename?: "EmailAddress"; id: string; email: string }>;
			employee: {
				__typename?: "Employee";
				id: string;
				person: { __typename?: "Person"; id: string; name: string };
			} | null;
			parent: { __typename?: "Parent"; id: string; person: { __typename?: "Person"; id: string; name: string } } | null;
			phoneNumbers: Array<{
				__typename?: "PhoneNumber";
				id: string;
				phoneNumber: string;
				phoneType: PhoneNumberPhoneType;
			}>;
			student: {
				__typename?: "Student";
				id: string;
				person: { __typename?: "Person"; id: string; name: string };
			} | null;
			user: { __typename?: "User"; id: string; email: string } | null;
		};
		relationships: Array<{ __typename?: "Relationship"; id: string }>;
	};
};

export type ParentDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type ParentDeleteMutation = { __typename?: "Mutation"; deleteParent: boolean };

export type ParentDetailFragment = {
	__typename?: "Parent";
	id: string;
	payments: Array<{ __typename?: "Payment"; id: string }>;
	person: {
		__typename?: "Person";
		id: string;
		name: string;
		firstName: string;
		lastName: string;
		nickname: string;
		alternateNames: string;
		imageURL: string;
		gender: string;
		dateOfBirth: string | null;
		occupation: string;
		nationality: string;
		primaryLanguage: string;
		englishLanguageFluency: PersonEnglishLanguageFluency;
		addresses: Array<{ __typename?: "Address"; id: string; shortAddress: string }>;
		emailAddresses: Array<{ __typename?: "EmailAddress"; id: string; email: string }>;
		employee: {
			__typename?: "Employee";
			id: string;
			person: { __typename?: "Person"; id: string; name: string };
		} | null;
		parent: { __typename?: "Parent"; id: string; person: { __typename?: "Person"; id: string; name: string } } | null;
		phoneNumbers: Array<{
			__typename?: "PhoneNumber";
			id: string;
			phoneNumber: string;
			phoneType: PhoneNumberPhoneType;
		}>;
		student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } } | null;
		user: { __typename?: "User"; id: string; email: string } | null;
	};
	relationships: Array<{ __typename?: "Relationship"; id: string }>;
};

export type ParentDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type ParentDetailQuery = {
	__typename?: "Query";
	parent: {
		__typename?: "Parent";
		id: string;
		payments: Array<{ __typename?: "Payment"; id: string }>;
		person: {
			__typename?: "Person";
			id: string;
			name: string;
			firstName: string;
			lastName: string;
			nickname: string;
			alternateNames: string;
			imageURL: string;
			gender: string;
			dateOfBirth: string | null;
			occupation: string;
			nationality: string;
			primaryLanguage: string;
			englishLanguageFluency: PersonEnglishLanguageFluency;
			addresses: Array<{ __typename?: "Address"; id: string; shortAddress: string }>;
			emailAddresses: Array<{ __typename?: "EmailAddress"; id: string; email: string }>;
			employee: {
				__typename?: "Employee";
				id: string;
				person: { __typename?: "Person"; id: string; name: string };
			} | null;
			parent: { __typename?: "Parent"; id: string; person: { __typename?: "Person"; id: string; name: string } } | null;
			phoneNumbers: Array<{
				__typename?: "PhoneNumber";
				id: string;
				phoneNumber: string;
				phoneType: PhoneNumberPhoneType;
			}>;
			student: {
				__typename?: "Student";
				id: string;
				person: { __typename?: "Person"; id: string; name: string };
			} | null;
			user: { __typename?: "User"; id: string; email: string } | null;
		};
		relationships: Array<{ __typename?: "Relationship"; id: string }>;
	};
};

export type ParentIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<ParentFilterInput> | ParentFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<ParentOrder>;
}>;

export type ParentIndexQuery = {
	__typename?: "Query";
	parentConnection: {
		__typename?: "ParentConnection";
		edges: Array<{
			__typename?: "ParentEdge";
			node: {
				__typename?: "Parent";
				id: string;
				person: { __typename?: "Person"; id: string; name: string; imageURL: string };
			};
		}>;
	};
};

export type ParentNameFragment = { __typename?: "Parent"; person: { __typename?: "Person"; id: string; name: string } };

export type ParentNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type ParentNameQuery = {
	__typename?: "Query";
	parent: { __typename?: "Parent"; id: string; person: { __typename?: "Person"; id: string; name: string } };
};

export type ParentNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<ParentFilterInput> | ParentFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<ParentOrder>;
}>;

export type ParentNamesQuery = {
	__typename?: "Query";
	parentConnection: {
		__typename?: "ParentConnection";
		edges: Array<{
			__typename?: "ParentEdge";
			node: { __typename?: "Parent"; id: string; person: { __typename?: "Person"; id: string; name: string } };
		}>;
	};
};

export type ParentPaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<ParentFilterInput> | ParentFilterInput>;
	order?: InputMaybe<ParentOrder>;
}>;

export type ParentPaginationQuery = {
	__typename?: "Query";
	parentConnection: {
		__typename?: "ParentConnection";
		totalCount: number;
		edges: Array<{ __typename?: "ParentEdge"; cursor: string }>;
	};
};

export type ParentSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<ParentFilterInput> | ParentFilterInput>;
	order?: InputMaybe<ParentOrder>;
}>;

export type ParentSelectQuery = {
	__typename?: "Query";
	parentConnection: {
		__typename?: "ParentConnection";
		edges: Array<{
			__typename?: "ParentEdge";
			node: { __typename?: "Parent"; id: string; person: { __typename?: "Person"; id: string; name: string } };
		}>;
	};
};

export type ParentUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: ParentUpdateInput;
}>;

export type ParentUpdateMutation = {
	__typename?: "Mutation";
	updateParent: {
		__typename?: "Parent";
		id: string;
		payments: Array<{ __typename?: "Payment"; id: string }>;
		person: {
			__typename?: "Person";
			id: string;
			name: string;
			firstName: string;
			lastName: string;
			nickname: string;
			alternateNames: string;
			imageURL: string;
			gender: string;
			dateOfBirth: string | null;
			occupation: string;
			nationality: string;
			primaryLanguage: string;
			englishLanguageFluency: PersonEnglishLanguageFluency;
			addresses: Array<{ __typename?: "Address"; id: string; shortAddress: string }>;
			emailAddresses: Array<{ __typename?: "EmailAddress"; id: string; email: string }>;
			employee: {
				__typename?: "Employee";
				id: string;
				person: { __typename?: "Person"; id: string; name: string };
			} | null;
			parent: { __typename?: "Parent"; id: string; person: { __typename?: "Person"; id: string; name: string } } | null;
			phoneNumbers: Array<{
				__typename?: "PhoneNumber";
				id: string;
				phoneNumber: string;
				phoneType: PhoneNumberPhoneType;
			}>;
			student: {
				__typename?: "Student";
				id: string;
				person: { __typename?: "Person"; id: string; name: string };
			} | null;
			user: { __typename?: "User"; id: string; email: string } | null;
		};
		relationships: Array<{ __typename?: "Relationship"; id: string }>;
	};
};

export type ParentUpdateWithPersonMutationVariables = Exact<{
	id: Scalars["ID"];
	input: ParentUpdateInput;
	personInput: PersonUpdateInput;
}>;

export type ParentUpdateWithPersonMutation = {
	__typename?: "Mutation";
	updateParentWithPerson: {
		__typename?: "Parent";
		id: string;
		payments: Array<{ __typename?: "Payment"; id: string }>;
		person: {
			__typename?: "Person";
			id: string;
			name: string;
			firstName: string;
			lastName: string;
			nickname: string;
			alternateNames: string;
			imageURL: string;
			gender: string;
			dateOfBirth: string | null;
			occupation: string;
			nationality: string;
			primaryLanguage: string;
			englishLanguageFluency: PersonEnglishLanguageFluency;
			addresses: Array<{ __typename?: "Address"; id: string; shortAddress: string }>;
			emailAddresses: Array<{ __typename?: "EmailAddress"; id: string; email: string }>;
			employee: {
				__typename?: "Employee";
				id: string;
				person: { __typename?: "Person"; id: string; name: string };
			} | null;
			parent: { __typename?: "Parent"; id: string; person: { __typename?: "Person"; id: string; name: string } } | null;
			phoneNumbers: Array<{
				__typename?: "PhoneNumber";
				id: string;
				phoneNumber: string;
				phoneType: PhoneNumberPhoneType;
			}>;
			student: {
				__typename?: "Student";
				id: string;
				person: { __typename?: "Person"; id: string; name: string };
			} | null;
			user: { __typename?: "User"; id: string; email: string } | null;
		};
		relationships: Array<{ __typename?: "Relationship"; id: string }>;
	};
};

export type PaymentCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<PaymentFilterInput> | PaymentFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<PaymentOrder>;
}>;

export type PaymentCountQuery = {
	__typename?: "Query";
	paymentConnection: { __typename?: "PaymentConnection"; totalCount: number };
};

export type PaymentCreateMutationVariables = Exact<{
	input: PaymentCreateInput;
}>;

export type PaymentCreateMutation = {
	__typename?: "Mutation";
	createPayment: {
		__typename?: "Payment";
		id: string;
		createdDate: string;
		paymentMethod: PaymentPaymentMethod;
		notes: string;
		parent: { __typename?: "Parent"; id: string; person: { __typename?: "Person"; id: string; name: string } };
		paymentLineItems: Array<{
			__typename?: "PaymentLineItem";
			id: string;
			amount: number;
			invoice: { __typename?: "Invoice"; id: string; invoiceNumber: string };
		}>;
	};
};

export type PaymentDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type PaymentDeleteMutation = { __typename?: "Mutation"; deletePayment: boolean };

export type PaymentDetailFragment = {
	__typename?: "Payment";
	id: string;
	createdDate: string;
	paymentMethod: PaymentPaymentMethod;
	notes: string;
	parent: { __typename?: "Parent"; id: string; person: { __typename?: "Person"; id: string; name: string } };
	paymentLineItems: Array<{
		__typename?: "PaymentLineItem";
		id: string;
		amount: number;
		invoice: { __typename?: "Invoice"; id: string; invoiceNumber: string };
	}>;
};

export type PaymentDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type PaymentDetailQuery = {
	__typename?: "Query";
	payment: {
		__typename?: "Payment";
		id: string;
		createdDate: string;
		paymentMethod: PaymentPaymentMethod;
		notes: string;
		parent: { __typename?: "Parent"; id: string; person: { __typename?: "Person"; id: string; name: string } };
		paymentLineItems: Array<{
			__typename?: "PaymentLineItem";
			id: string;
			amount: number;
			invoice: { __typename?: "Invoice"; id: string; invoiceNumber: string };
		}>;
	};
};

export type PaymentIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<PaymentFilterInput> | PaymentFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<PaymentOrder>;
}>;

export type PaymentIndexQuery = {
	__typename?: "Query";
	paymentConnection: {
		__typename?: "PaymentConnection";
		edges: Array<{
			__typename?: "PaymentEdge";
			node: { __typename?: "Payment"; id: string; createdDate: string; notes: string };
		}>;
	};
};

export type PaymentNameFragment = { __typename?: "Payment"; id: string };

export type PaymentNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type PaymentNameQuery = { __typename?: "Query"; payment: { __typename?: "Payment"; id: string } };

export type PaymentNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<PaymentFilterInput> | PaymentFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<PaymentOrder>;
}>;

export type PaymentNamesQuery = {
	__typename?: "Query";
	paymentConnection: {
		__typename?: "PaymentConnection";
		edges: Array<{ __typename?: "PaymentEdge"; node: { __typename?: "Payment"; id: string } }>;
	};
};

export type PaymentPaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<PaymentFilterInput> | PaymentFilterInput>;
	order?: InputMaybe<PaymentOrder>;
}>;

export type PaymentPaginationQuery = {
	__typename?: "Query";
	paymentConnection: {
		__typename?: "PaymentConnection";
		totalCount: number;
		edges: Array<{ __typename?: "PaymentEdge"; cursor: string }>;
	};
};

export type PaymentSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<PaymentFilterInput> | PaymentFilterInput>;
	order?: InputMaybe<PaymentOrder>;
}>;

export type PaymentSelectQuery = {
	__typename?: "Query";
	paymentConnection: {
		__typename?: "PaymentConnection";
		edges: Array<{ __typename?: "PaymentEdge"; node: { __typename?: "Payment"; id: string } }>;
	};
};

export type PaymentUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: PaymentUpdateInput;
}>;

export type PaymentUpdateMutation = {
	__typename?: "Mutation";
	updatePayment: {
		__typename?: "Payment";
		id: string;
		createdDate: string;
		paymentMethod: PaymentPaymentMethod;
		notes: string;
		parent: { __typename?: "Parent"; id: string; person: { __typename?: "Person"; id: string; name: string } };
		paymentLineItems: Array<{
			__typename?: "PaymentLineItem";
			id: string;
			amount: number;
			invoice: { __typename?: "Invoice"; id: string; invoiceNumber: string };
		}>;
	};
};

export type PaymentLineItemCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<PaymentLineItemFilterInput> | PaymentLineItemFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<PaymentLineItemOrder>;
}>;

export type PaymentLineItemCountQuery = {
	__typename?: "Query";
	paymentLineItemConnection: { __typename?: "PaymentLineItemConnection"; totalCount: number };
};

export type PaymentLineItemCreateMutationVariables = Exact<{
	input: PaymentLineItemCreateInput;
}>;

export type PaymentLineItemCreateMutation = {
	__typename?: "Mutation";
	createPaymentLineItem: {
		__typename?: "PaymentLineItem";
		id: string;
		amount: number;
		invoice: {
			__typename?: "Invoice";
			id: string;
			invoiceNumber: string;
			dueDate: string;
			invoiceLineItems: Array<{ __typename?: "InvoiceLineItem"; id: string; amount: number }>;
		};
		payment: { __typename?: "Payment"; id: string };
	};
};

export type PaymentLineItemDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type PaymentLineItemDeleteMutation = { __typename?: "Mutation"; deletePaymentLineItem: boolean };

export type PaymentLineItemDetailFragment = {
	__typename?: "PaymentLineItem";
	id: string;
	amount: number;
	invoice: {
		__typename?: "Invoice";
		id: string;
		invoiceNumber: string;
		dueDate: string;
		invoiceLineItems: Array<{ __typename?: "InvoiceLineItem"; id: string; amount: number }>;
	};
	payment: { __typename?: "Payment"; id: string };
};

export type PaymentLineItemDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type PaymentLineItemDetailQuery = {
	__typename?: "Query";
	paymentLineItem: {
		__typename?: "PaymentLineItem";
		id: string;
		amount: number;
		invoice: {
			__typename?: "Invoice";
			id: string;
			invoiceNumber: string;
			dueDate: string;
			invoiceLineItems: Array<{ __typename?: "InvoiceLineItem"; id: string; amount: number }>;
		};
		payment: { __typename?: "Payment"; id: string };
	};
};

export type PaymentLineItemIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<PaymentLineItemFilterInput> | PaymentLineItemFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<PaymentLineItemOrder>;
}>;

export type PaymentLineItemIndexQuery = {
	__typename?: "Query";
	paymentLineItemConnection: {
		__typename?: "PaymentLineItemConnection";
		edges: Array<{
			__typename?: "PaymentLineItemEdge";
			node: { __typename?: "PaymentLineItem"; id: string; amount: number };
		}>;
	};
};

export type PaymentLineItemNameFragment = { __typename?: "PaymentLineItem"; id: string };

export type PaymentLineItemNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type PaymentLineItemNameQuery = {
	__typename?: "Query";
	paymentLineItem: { __typename?: "PaymentLineItem"; id: string };
};

export type PaymentLineItemNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<PaymentLineItemFilterInput> | PaymentLineItemFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<PaymentLineItemOrder>;
}>;

export type PaymentLineItemNamesQuery = {
	__typename?: "Query";
	paymentLineItemConnection: {
		__typename?: "PaymentLineItemConnection";
		edges: Array<{ __typename?: "PaymentLineItemEdge"; node: { __typename?: "PaymentLineItem"; id: string } }>;
	};
};

export type PaymentLineItemPaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<PaymentLineItemFilterInput> | PaymentLineItemFilterInput>;
	order?: InputMaybe<PaymentLineItemOrder>;
}>;

export type PaymentLineItemPaginationQuery = {
	__typename?: "Query";
	paymentLineItemConnection: {
		__typename?: "PaymentLineItemConnection";
		totalCount: number;
		edges: Array<{ __typename?: "PaymentLineItemEdge"; cursor: string }>;
	};
};

export type PaymentLineItemSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<PaymentLineItemFilterInput> | PaymentLineItemFilterInput>;
	order?: InputMaybe<PaymentLineItemOrder>;
}>;

export type PaymentLineItemSelectQuery = {
	__typename?: "Query";
	paymentLineItemConnection: {
		__typename?: "PaymentLineItemConnection";
		edges: Array<{ __typename?: "PaymentLineItemEdge"; node: { __typename?: "PaymentLineItem"; id: string } }>;
	};
};

export type PaymentLineItemUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: PaymentLineItemUpdateInput;
}>;

export type PaymentLineItemUpdateMutation = {
	__typename?: "Mutation";
	updatePaymentLineItem: {
		__typename?: "PaymentLineItem";
		id: string;
		amount: number;
		invoice: {
			__typename?: "Invoice";
			id: string;
			invoiceNumber: string;
			dueDate: string;
			invoiceLineItems: Array<{ __typename?: "InvoiceLineItem"; id: string; amount: number }>;
		};
		payment: { __typename?: "Payment"; id: string };
	};
};

export type PermissionCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<PermissionFilterInput> | PermissionFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<PermissionOrder>;
}>;

export type PermissionCountQuery = {
	__typename?: "Query";
	permissionConnection: { __typename?: "PermissionConnection"; totalCount: number };
};

export type PermissionDetailFragment = {
	__typename?: "Permission";
	id: string;
	name: string;
	family: string;
	action: string;
	level: string;
	description: string;
	permissionGroups: Array<{ __typename?: "PermissionGroup"; id: string; name: string }>;
};

export type PermissionDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type PermissionDetailQuery = {
	__typename?: "Query";
	permission: {
		__typename?: "Permission";
		id: string;
		name: string;
		family: string;
		action: string;
		level: string;
		description: string;
		permissionGroups: Array<{ __typename?: "PermissionGroup"; id: string; name: string }>;
	};
};

export type PermissionIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<PermissionFilterInput> | PermissionFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<PermissionOrder>;
}>;

export type PermissionIndexQuery = {
	__typename?: "Query";
	permissionConnection: {
		__typename?: "PermissionConnection";
		edges: Array<{
			__typename?: "PermissionEdge";
			node: {
				__typename?: "Permission";
				id: string;
				name: string;
				family: string;
				action: string;
				level: string;
				description: string;
			};
		}>;
	};
};

export type PermissionNameFragment = { __typename?: "Permission"; name: string };

export type PermissionNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type PermissionNameQuery = {
	__typename?: "Query";
	permission: { __typename?: "Permission"; id: string; name: string };
};

export type PermissionNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<PermissionFilterInput> | PermissionFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<PermissionOrder>;
}>;

export type PermissionNamesQuery = {
	__typename?: "Query";
	permissionConnection: {
		__typename?: "PermissionConnection";
		edges: Array<{ __typename?: "PermissionEdge"; node: { __typename?: "Permission"; id: string; name: string } }>;
	};
};

export type PermissionPaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<PermissionFilterInput> | PermissionFilterInput>;
	order?: InputMaybe<PermissionOrder>;
}>;

export type PermissionPaginationQuery = {
	__typename?: "Query";
	permissionConnection: {
		__typename?: "PermissionConnection";
		totalCount: number;
		edges: Array<{ __typename?: "PermissionEdge"; cursor: string }>;
	};
};

export type PermissionSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<PermissionFilterInput> | PermissionFilterInput>;
	order?: InputMaybe<PermissionOrder>;
}>;

export type PermissionSelectQuery = {
	__typename?: "Query";
	permissionConnection: {
		__typename?: "PermissionConnection";
		edges: Array<{ __typename?: "PermissionEdge"; node: { __typename?: "Permission"; id: string; name: string } }>;
	};
};

export type PermissionUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: PermissionUpdateInput;
}>;

export type PermissionUpdateMutation = {
	__typename?: "Mutation";
	updatePermission: {
		__typename?: "Permission";
		id: string;
		name: string;
		family: string;
		action: string;
		level: string;
		description: string;
		permissionGroups: Array<{ __typename?: "PermissionGroup"; id: string; name: string }>;
	};
};

export type PermissionGroupCheckUniqueNameQueryVariables = Exact<{
	ignoreID?: InputMaybe<Scalars["ID"]>;
	name: Scalars["String"];
}>;

export type PermissionGroupCheckUniqueNameQuery = {
	__typename?: "Query";
	permissionGroupConnection: { __typename?: "PermissionGroupConnection"; totalCount: number };
};

export type PermissionGroupCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<PermissionGroupFilterInput> | PermissionGroupFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<PermissionGroupOrder>;
}>;

export type PermissionGroupCountQuery = {
	__typename?: "Query";
	permissionGroupConnection: { __typename?: "PermissionGroupConnection"; totalCount: number };
};

export type PermissionGroupCreateMutationVariables = Exact<{
	input: PermissionGroupCreateInput;
}>;

export type PermissionGroupCreateMutation = {
	__typename?: "Mutation";
	createPermissionGroup: {
		__typename?: "PermissionGroup";
		id: string;
		name: string;
		description: string;
		permissions: Array<{ __typename?: "Permission"; id: string; name: string }>;
		users: Array<{ __typename?: "User"; id: string; email: string }>;
	};
};

export type PermissionGroupDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type PermissionGroupDeleteMutation = { __typename?: "Mutation"; deletePermissionGroup: boolean };

export type PermissionGroupDetailFragment = {
	__typename?: "PermissionGroup";
	id: string;
	name: string;
	description: string;
	permissions: Array<{ __typename?: "Permission"; id: string; name: string }>;
	users: Array<{ __typename?: "User"; id: string; email: string }>;
};

export type PermissionGroupDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type PermissionGroupDetailQuery = {
	__typename?: "Query";
	permissionGroup: {
		__typename?: "PermissionGroup";
		id: string;
		name: string;
		description: string;
		permissions: Array<{ __typename?: "Permission"; id: string; name: string }>;
		users: Array<{ __typename?: "User"; id: string; email: string }>;
	};
};

export type PermissionGroupIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<PermissionGroupFilterInput> | PermissionGroupFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<PermissionGroupOrder>;
}>;

export type PermissionGroupIndexQuery = {
	__typename?: "Query";
	permissionGroupConnection: {
		__typename?: "PermissionGroupConnection";
		edges: Array<{
			__typename?: "PermissionGroupEdge";
			node: { __typename?: "PermissionGroup"; id: string; name: string; description: string };
		}>;
	};
};

export type PermissionGroupNameFragment = { __typename?: "PermissionGroup"; name: string };

export type PermissionGroupNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type PermissionGroupNameQuery = {
	__typename?: "Query";
	permissionGroup: { __typename?: "PermissionGroup"; id: string; name: string };
};

export type PermissionGroupNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<PermissionGroupFilterInput> | PermissionGroupFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<PermissionGroupOrder>;
}>;

export type PermissionGroupNamesQuery = {
	__typename?: "Query";
	permissionGroupConnection: {
		__typename?: "PermissionGroupConnection";
		edges: Array<{
			__typename?: "PermissionGroupEdge";
			node: { __typename?: "PermissionGroup"; id: string; name: string };
		}>;
	};
};

export type PermissionGroupPaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<PermissionGroupFilterInput> | PermissionGroupFilterInput>;
	order?: InputMaybe<PermissionGroupOrder>;
}>;

export type PermissionGroupPaginationQuery = {
	__typename?: "Query";
	permissionGroupConnection: {
		__typename?: "PermissionGroupConnection";
		totalCount: number;
		edges: Array<{ __typename?: "PermissionGroupEdge"; cursor: string }>;
	};
};

export type PermissionGroupSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<PermissionGroupFilterInput> | PermissionGroupFilterInput>;
	order?: InputMaybe<PermissionGroupOrder>;
}>;

export type PermissionGroupSelectQuery = {
	__typename?: "Query";
	permissionGroupConnection: {
		__typename?: "PermissionGroupConnection";
		edges: Array<{
			__typename?: "PermissionGroupEdge";
			node: { __typename?: "PermissionGroup"; id: string; name: string };
		}>;
	};
};

export type PermissionGroupUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: PermissionGroupUpdateInput;
}>;

export type PermissionGroupUpdateMutation = {
	__typename?: "Mutation";
	updatePermissionGroup: {
		__typename?: "PermissionGroup";
		id: string;
		name: string;
		description: string;
		permissions: Array<{ __typename?: "Permission"; id: string; name: string }>;
		users: Array<{ __typename?: "User"; id: string; email: string }>;
	};
};

export type BirthdaysQueryVariables = Exact<{
	dates: Array<Scalars["Time"]> | Scalars["Time"];
	filters?: InputMaybe<Array<PersonFilterInput> | PersonFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<PersonOrder>;
}>;

export type BirthdaysQuery = {
	__typename?: "Query";
	personConnectionByBirthday: {
		__typename?: "PersonConnection";
		edges: Array<{
			__typename?: "PersonEdge";
			node: {
				__typename?: "Person";
				id: string;
				name: string;
				firstName: string;
				lastName: string;
				imageURL: string;
				dateOfBirth: string | null;
				student: { __typename?: "Student"; id: string } | null;
				employee: { __typename?: "Employee"; id: string } | null;
				parent: { __typename?: "Parent"; id: string } | null;
			};
		}>;
	};
};

export type PersonCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<PersonFilterInput> | PersonFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<PersonOrder>;
}>;

export type PersonCountQuery = {
	__typename?: "Query";
	personConnection: { __typename?: "PersonConnection"; totalCount: number };
};

export type PersonCreateMutationVariables = Exact<{
	input: PersonCreateInput;
}>;

export type PersonCreateMutation = {
	__typename?: "Mutation";
	createPerson: {
		__typename?: "Person";
		id: string;
		name: string;
		firstName: string;
		lastName: string;
		nickname: string;
		alternateNames: string;
		imageURL: string;
		gender: string;
		dateOfBirth: string | null;
		occupation: string;
		nationality: string;
		primaryLanguage: string;
		englishLanguageFluency: PersonEnglishLanguageFluency;
		addresses: Array<{ __typename?: "Address"; id: string; shortAddress: string }>;
		emailAddresses: Array<{ __typename?: "EmailAddress"; id: string; email: string }>;
		employee: {
			__typename?: "Employee";
			id: string;
			person: { __typename?: "Person"; id: string; name: string };
		} | null;
		parent: { __typename?: "Parent"; id: string; person: { __typename?: "Person"; id: string; name: string } } | null;
		phoneNumbers: Array<{
			__typename?: "PhoneNumber";
			id: string;
			phoneNumber: string;
			phoneType: PhoneNumberPhoneType;
		}>;
		student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } } | null;
		user: { __typename?: "User"; id: string; email: string } | null;
	};
};

export type PersonDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type PersonDeleteMutation = { __typename?: "Mutation"; deletePerson: boolean };

export type PersonDetailFragment = {
	__typename?: "Person";
	id: string;
	name: string;
	firstName: string;
	lastName: string;
	nickname: string;
	alternateNames: string;
	imageURL: string;
	gender: string;
	dateOfBirth: string | null;
	occupation: string;
	nationality: string;
	primaryLanguage: string;
	englishLanguageFluency: PersonEnglishLanguageFluency;
	addresses: Array<{ __typename?: "Address"; id: string; shortAddress: string }>;
	emailAddresses: Array<{ __typename?: "EmailAddress"; id: string; email: string }>;
	employee: { __typename?: "Employee"; id: string; person: { __typename?: "Person"; id: string; name: string } } | null;
	parent: { __typename?: "Parent"; id: string; person: { __typename?: "Person"; id: string; name: string } } | null;
	phoneNumbers: Array<{ __typename?: "PhoneNumber"; id: string; phoneNumber: string; phoneType: PhoneNumberPhoneType }>;
	student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } } | null;
	user: { __typename?: "User"; id: string; email: string } | null;
};

export type PersonDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type PersonDetailQuery = {
	__typename?: "Query";
	person: {
		__typename?: "Person";
		id: string;
		name: string;
		firstName: string;
		lastName: string;
		nickname: string;
		alternateNames: string;
		imageURL: string;
		gender: string;
		dateOfBirth: string | null;
		occupation: string;
		nationality: string;
		primaryLanguage: string;
		englishLanguageFluency: PersonEnglishLanguageFluency;
		addresses: Array<{ __typename?: "Address"; id: string; shortAddress: string }>;
		emailAddresses: Array<{ __typename?: "EmailAddress"; id: string; email: string }>;
		employee: {
			__typename?: "Employee";
			id: string;
			person: { __typename?: "Person"; id: string; name: string };
		} | null;
		parent: { __typename?: "Parent"; id: string; person: { __typename?: "Person"; id: string; name: string } } | null;
		phoneNumbers: Array<{
			__typename?: "PhoneNumber";
			id: string;
			phoneNumber: string;
			phoneType: PhoneNumberPhoneType;
		}>;
		student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } } | null;
		user: { __typename?: "User"; id: string; email: string } | null;
	};
};

export type PersonIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<PersonFilterInput> | PersonFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<PersonOrder>;
}>;

export type PersonIndexQuery = {
	__typename?: "Query";
	personConnection: {
		__typename?: "PersonConnection";
		edges: Array<{
			__typename?: "PersonEdge";
			node: {
				__typename?: "Person";
				id: string;
				name: string;
				firstName: string;
				lastName: string;
				nickname: string;
				gender: string;
				dateOfBirth: string | null;
				occupation: string;
				nationality: string;
				primaryLanguage: string;
			};
		}>;
	};
};

export type PersonNameFragment = { __typename?: "Person"; name: string };

export type PersonNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type PersonNameQuery = { __typename?: "Query"; person: { __typename?: "Person"; id: string; name: string } };

export type PersonNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<PersonFilterInput> | PersonFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<PersonOrder>;
}>;

export type PersonNamesQuery = {
	__typename?: "Query";
	personConnection: {
		__typename?: "PersonConnection";
		edges: Array<{ __typename?: "PersonEdge"; node: { __typename?: "Person"; id: string; name: string } }>;
	};
};

export type PersonPaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<PersonFilterInput> | PersonFilterInput>;
	order?: InputMaybe<PersonOrder>;
}>;

export type PersonPaginationQuery = {
	__typename?: "Query";
	personConnection: {
		__typename?: "PersonConnection";
		totalCount: number;
		edges: Array<{ __typename?: "PersonEdge"; cursor: string }>;
	};
};

export type PersonSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<PersonFilterInput> | PersonFilterInput>;
	order?: InputMaybe<PersonOrder>;
}>;

export type PersonSelectQuery = {
	__typename?: "Query";
	personConnection: {
		__typename?: "PersonConnection";
		edges: Array<{ __typename?: "PersonEdge"; node: { __typename?: "Person"; id: string; name: string } }>;
	};
};

export type PersonUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: PersonUpdateInput;
}>;

export type PersonUpdateMutation = {
	__typename?: "Mutation";
	updatePerson: {
		__typename?: "Person";
		id: string;
		name: string;
		firstName: string;
		lastName: string;
		nickname: string;
		alternateNames: string;
		imageURL: string;
		gender: string;
		dateOfBirth: string | null;
		occupation: string;
		nationality: string;
		primaryLanguage: string;
		englishLanguageFluency: PersonEnglishLanguageFluency;
		addresses: Array<{ __typename?: "Address"; id: string; shortAddress: string }>;
		emailAddresses: Array<{ __typename?: "EmailAddress"; id: string; email: string }>;
		employee: {
			__typename?: "Employee";
			id: string;
			person: { __typename?: "Person"; id: string; name: string };
		} | null;
		parent: { __typename?: "Parent"; id: string; person: { __typename?: "Person"; id: string; name: string } } | null;
		phoneNumbers: Array<{
			__typename?: "PhoneNumber";
			id: string;
			phoneNumber: string;
			phoneType: PhoneNumberPhoneType;
		}>;
		student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } } | null;
		user: { __typename?: "User"; id: string; email: string } | null;
	};
};

export type PhoneNumberCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<PhoneNumberFilterInput> | PhoneNumberFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<PhoneNumberOrder>;
}>;

export type PhoneNumberCountQuery = {
	__typename?: "Query";
	phoneNumberConnection: { __typename?: "PhoneNumberConnection"; totalCount: number };
};

export type PhoneNumberCreateMutationVariables = Exact<{
	input: PhoneNumberCreateInput;
}>;

export type PhoneNumberCreateMutation = {
	__typename?: "Mutation";
	createPhoneNumber: {
		__typename?: "PhoneNumber";
		id: string;
		phoneNumber: string;
		phoneType: PhoneNumberPhoneType;
		person: { __typename?: "Person"; id: string; name: string } | null;
	};
};

export type PhoneNumberDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type PhoneNumberDeleteMutation = { __typename?: "Mutation"; deletePhoneNumber: boolean };

export type PhoneNumberDetailFragment = {
	__typename?: "PhoneNumber";
	id: string;
	phoneNumber: string;
	phoneType: PhoneNumberPhoneType;
	person: { __typename?: "Person"; id: string; name: string } | null;
};

export type PhoneNumberDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type PhoneNumberDetailQuery = {
	__typename?: "Query";
	phoneNumber: {
		__typename?: "PhoneNumber";
		id: string;
		phoneNumber: string;
		phoneType: PhoneNumberPhoneType;
		person: { __typename?: "Person"; id: string; name: string } | null;
	};
};

export type PhoneNumberIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<PhoneNumberFilterInput> | PhoneNumberFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<PhoneNumberOrder>;
}>;

export type PhoneNumberIndexQuery = {
	__typename?: "Query";
	phoneNumberConnection: {
		__typename?: "PhoneNumberConnection";
		edges: Array<{
			__typename?: "PhoneNumberEdge";
			node: { __typename?: "PhoneNumber"; id: string; phoneNumber: string; phoneType: PhoneNumberPhoneType };
		}>;
	};
};

export type PhoneNumberNameFragment = {
	__typename?: "PhoneNumber";
	phoneNumber: string;
	phoneType: PhoneNumberPhoneType;
};

export type PhoneNumberNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type PhoneNumberNameQuery = {
	__typename?: "Query";
	phoneNumber: { __typename?: "PhoneNumber"; id: string; phoneNumber: string; phoneType: PhoneNumberPhoneType };
};

export type PhoneNumberNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<PhoneNumberFilterInput> | PhoneNumberFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<PhoneNumberOrder>;
}>;

export type PhoneNumberNamesQuery = {
	__typename?: "Query";
	phoneNumberConnection: {
		__typename?: "PhoneNumberConnection";
		edges: Array<{
			__typename?: "PhoneNumberEdge";
			node: { __typename?: "PhoneNumber"; id: string; phoneNumber: string; phoneType: PhoneNumberPhoneType };
		}>;
	};
};

export type PhoneNumberPaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<PhoneNumberFilterInput> | PhoneNumberFilterInput>;
	order?: InputMaybe<PhoneNumberOrder>;
}>;

export type PhoneNumberPaginationQuery = {
	__typename?: "Query";
	phoneNumberConnection: {
		__typename?: "PhoneNumberConnection";
		totalCount: number;
		edges: Array<{ __typename?: "PhoneNumberEdge"; cursor: string }>;
	};
};

export type PhoneNumberSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<PhoneNumberFilterInput> | PhoneNumberFilterInput>;
	order?: InputMaybe<PhoneNumberOrder>;
}>;

export type PhoneNumberSelectQuery = {
	__typename?: "Query";
	phoneNumberConnection: {
		__typename?: "PhoneNumberConnection";
		edges: Array<{
			__typename?: "PhoneNumberEdge";
			node: { __typename?: "PhoneNumber"; id: string; phoneNumber: string; phoneType: PhoneNumberPhoneType };
		}>;
	};
};

export type PhoneNumberUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: PhoneNumberUpdateInput;
}>;

export type PhoneNumberUpdateMutation = {
	__typename?: "Mutation";
	updatePhoneNumber: {
		__typename?: "PhoneNumber";
		id: string;
		phoneNumber: string;
		phoneType: PhoneNumberPhoneType;
		person: { __typename?: "Person"; id: string; name: string } | null;
	};
};

export type QuotationCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<QuotationFilterInput> | QuotationFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<QuotationOrder>;
}>;

export type QuotationCountQuery = {
	__typename?: "Query";
	quotationConnection: { __typename?: "QuotationConnection"; totalCount: number };
};

export type QuotationCreateMutationVariables = Exact<{
	input: QuotationCreateInput;
}>;

export type QuotationCreateMutation = {
	__typename?: "Mutation";
	createQuotation: { __typename?: "Quotation"; id: string; body: string; attribution: string };
};

export type DailyQuotationQueryVariables = Exact<{
	date: Scalars["Time"];
}>;

export type DailyQuotationQuery = {
	__typename?: "Query";
	dailyQuotation: { __typename?: "Quotation"; id: string; body: string; attribution: string };
};

export type QuotationDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type QuotationDeleteMutation = { __typename?: "Mutation"; deleteQuotation: boolean };

export type QuotationDetailFragment = { __typename?: "Quotation"; id: string; body: string; attribution: string };

export type QuotationDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type QuotationDetailQuery = {
	__typename?: "Query";
	quotation: { __typename?: "Quotation"; id: string; body: string; attribution: string };
};

export type QuotationIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<QuotationFilterInput> | QuotationFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<QuotationOrder>;
}>;

export type QuotationIndexQuery = {
	__typename?: "Query";
	quotationConnection: {
		__typename?: "QuotationConnection";
		edges: Array<{
			__typename?: "QuotationEdge";
			node: { __typename?: "Quotation"; id: string; body: string; attribution: string };
		}>;
	};
};

export type QuotationNameFragment = { __typename?: "Quotation"; id: string };

export type QuotationNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type QuotationNameQuery = { __typename?: "Query"; quotation: { __typename?: "Quotation"; id: string } };

export type QuotationNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<QuotationFilterInput> | QuotationFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<QuotationOrder>;
}>;

export type QuotationNamesQuery = {
	__typename?: "Query";
	quotationConnection: {
		__typename?: "QuotationConnection";
		edges: Array<{ __typename?: "QuotationEdge"; node: { __typename?: "Quotation"; id: string } }>;
	};
};

export type QuotationPaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<QuotationFilterInput> | QuotationFilterInput>;
	order?: InputMaybe<QuotationOrder>;
}>;

export type QuotationPaginationQuery = {
	__typename?: "Query";
	quotationConnection: {
		__typename?: "QuotationConnection";
		totalCount: number;
		edges: Array<{ __typename?: "QuotationEdge"; cursor: string }>;
	};
};

export type QuotationSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<QuotationFilterInput> | QuotationFilterInput>;
	order?: InputMaybe<QuotationOrder>;
}>;

export type QuotationSelectQuery = {
	__typename?: "Query";
	quotationConnection: {
		__typename?: "QuotationConnection";
		edges: Array<{ __typename?: "QuotationEdge"; node: { __typename?: "Quotation"; id: string } }>;
	};
};

export type QuotationUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: QuotationUpdateInput;
}>;

export type QuotationUpdateMutation = {
	__typename?: "Mutation";
	updateQuotation: { __typename?: "Quotation"; id: string; body: string; attribution: string };
};

export type RelationshipCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<RelationshipFilterInput> | RelationshipFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<RelationshipOrder>;
}>;

export type RelationshipCountQuery = {
	__typename?: "Query";
	relationshipConnection: { __typename?: "RelationshipConnection"; totalCount: number };
};

export type RelationshipCreateMutationVariables = Exact<{
	input: RelationshipCreateInput;
}>;

export type RelationshipCreateMutation = {
	__typename?: "Mutation";
	createRelationship: {
		__typename?: "Relationship";
		id: string;
		relationshipType: RelationshipRelationshipType;
		isAuthorizedForPickup: boolean;
		isEmergencyContact: boolean;
		isFinancialContact: boolean;
		isPrimary: boolean;
		livesWithStudent: boolean;
		parent: { __typename?: "Parent"; id: string; person: { __typename?: "Person"; id: string; name: string } };
		student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
	};
};

export type RelationshipDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type RelationshipDeleteMutation = { __typename?: "Mutation"; deleteRelationship: boolean };

export type RelationshipDetailFragment = {
	__typename?: "Relationship";
	id: string;
	relationshipType: RelationshipRelationshipType;
	isAuthorizedForPickup: boolean;
	isEmergencyContact: boolean;
	isFinancialContact: boolean;
	isPrimary: boolean;
	livesWithStudent: boolean;
	parent: { __typename?: "Parent"; id: string; person: { __typename?: "Person"; id: string; name: string } };
	student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
};

export type RelationshipDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type RelationshipDetailQuery = {
	__typename?: "Query";
	relationship: {
		__typename?: "Relationship";
		id: string;
		relationshipType: RelationshipRelationshipType;
		isAuthorizedForPickup: boolean;
		isEmergencyContact: boolean;
		isFinancialContact: boolean;
		isPrimary: boolean;
		livesWithStudent: boolean;
		parent: { __typename?: "Parent"; id: string; person: { __typename?: "Person"; id: string; name: string } };
		student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
	};
};

export type RelationshipIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<RelationshipFilterInput> | RelationshipFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<RelationshipOrder>;
}>;

export type RelationshipIndexQuery = {
	__typename?: "Query";
	relationshipConnection: {
		__typename?: "RelationshipConnection";
		edges: Array<{
			__typename?: "RelationshipEdge";
			node: {
				__typename?: "Relationship";
				id: string;
				relationshipType: RelationshipRelationshipType;
				isAuthorizedForPickup: boolean;
				isEmergencyContact: boolean;
				isFinancialContact: boolean;
				isPrimary: boolean;
				livesWithStudent: boolean;
			};
		}>;
	};
};

export type RelationshipNameFragment = { __typename?: "Relationship"; id: string };

export type RelationshipNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type RelationshipNameQuery = { __typename?: "Query"; relationship: { __typename?: "Relationship"; id: string } };

export type RelationshipNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<RelationshipFilterInput> | RelationshipFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<RelationshipOrder>;
}>;

export type RelationshipNamesQuery = {
	__typename?: "Query";
	relationshipConnection: {
		__typename?: "RelationshipConnection";
		edges: Array<{ __typename?: "RelationshipEdge"; node: { __typename?: "Relationship"; id: string } }>;
	};
};

export type RelationshipPaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<RelationshipFilterInput> | RelationshipFilterInput>;
	order?: InputMaybe<RelationshipOrder>;
}>;

export type RelationshipPaginationQuery = {
	__typename?: "Query";
	relationshipConnection: {
		__typename?: "RelationshipConnection";
		totalCount: number;
		edges: Array<{ __typename?: "RelationshipEdge"; cursor: string }>;
	};
};

export type RelationshipSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<RelationshipFilterInput> | RelationshipFilterInput>;
	order?: InputMaybe<RelationshipOrder>;
}>;

export type RelationshipSelectQuery = {
	__typename?: "Query";
	relationshipConnection: {
		__typename?: "RelationshipConnection";
		edges: Array<{ __typename?: "RelationshipEdge"; node: { __typename?: "Relationship"; id: string } }>;
	};
};

export type StudentParentListQueryVariables = Exact<{
	filters?: InputMaybe<Array<RelationshipFilterInput> | RelationshipFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<RelationshipOrder>;
}>;

export type StudentParentListQuery = {
	__typename?: "Query";
	relationshipConnection: {
		__typename?: "RelationshipConnection";
		edges: Array<{
			__typename?: "RelationshipEdge";
			node: {
				__typename?: "Relationship";
				id: string;
				relationshipType: RelationshipRelationshipType;
				isAuthorizedForPickup: boolean;
				isEmergencyContact: boolean;
				isFinancialContact: boolean;
				isPrimary: boolean;
				livesWithStudent: boolean;
				parent: {
					__typename?: "Parent";
					id: string;
					person: {
						__typename?: "Person";
						id: string;
						name: string;
						imageURL: string;
						emailAddresses: Array<{ __typename?: "EmailAddress"; id: string; email: string }>;
						phoneNumbers: Array<{
							__typename?: "PhoneNumber";
							id: string;
							phoneType: PhoneNumberPhoneType;
							phoneNumber: string;
						}>;
					};
				};
			};
		}>;
	};
};

export type RelationshipUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: RelationshipUpdateInput;
}>;

export type RelationshipUpdateMutation = {
	__typename?: "Mutation";
	updateRelationship: {
		__typename?: "Relationship";
		id: string;
		relationshipType: RelationshipRelationshipType;
		isAuthorizedForPickup: boolean;
		isEmergencyContact: boolean;
		isFinancialContact: boolean;
		isPrimary: boolean;
		livesWithStudent: boolean;
		parent: { __typename?: "Parent"; id: string; person: { __typename?: "Person"; id: string; name: string } };
		student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
	};
};

export type ReportCardCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<ReportCardFilterInput> | ReportCardFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<ReportCardOrder>;
}>;

export type ReportCardCountQuery = {
	__typename?: "Query";
	reportCardConnection: { __typename?: "ReportCardConnection"; totalCount: number };
};

export type ReportCardCreateMutationVariables = Exact<{
	input: ReportCardCreateInput;
}>;

export type ReportCardCreateMutation = {
	__typename?: "Mutation";
	createReportCard: {
		__typename?: "ReportCard";
		id: string;
		issueDate: string;
		learningObjectiveMarks: Array<{ __typename?: "LearningObjectiveMark"; id: string }>;
		student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
		term: { __typename?: "Term"; id: string; name: string };
	};
};

export type ReportCardDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type ReportCardDeleteMutation = { __typename?: "Mutation"; deleteReportCard: boolean };

export type ReportCardDetailFragment = {
	__typename?: "ReportCard";
	id: string;
	issueDate: string;
	learningObjectiveMarks: Array<{ __typename?: "LearningObjectiveMark"; id: string }>;
	student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
	term: { __typename?: "Term"; id: string; name: string };
};

export type ReportCardDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type ReportCardDetailQuery = {
	__typename?: "Query";
	reportCard: {
		__typename?: "ReportCard";
		id: string;
		issueDate: string;
		learningObjectiveMarks: Array<{ __typename?: "LearningObjectiveMark"; id: string }>;
		student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
		term: { __typename?: "Term"; id: string; name: string };
	};
};

export type ReportCardIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<ReportCardFilterInput> | ReportCardFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<ReportCardOrder>;
}>;

export type ReportCardIndexQuery = {
	__typename?: "Query";
	reportCardConnection: {
		__typename?: "ReportCardConnection";
		edges: Array<{ __typename?: "ReportCardEdge"; node: { __typename?: "ReportCard"; id: string; issueDate: string } }>;
	};
};

export type ReportCardNameFragment = { __typename?: "ReportCard"; id: string };

export type ReportCardNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type ReportCardNameQuery = { __typename?: "Query"; reportCard: { __typename?: "ReportCard"; id: string } };

export type ReportCardNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<ReportCardFilterInput> | ReportCardFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<ReportCardOrder>;
}>;

export type ReportCardNamesQuery = {
	__typename?: "Query";
	reportCardConnection: {
		__typename?: "ReportCardConnection";
		edges: Array<{ __typename?: "ReportCardEdge"; node: { __typename?: "ReportCard"; id: string } }>;
	};
};

export type ReportCardPaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<ReportCardFilterInput> | ReportCardFilterInput>;
	order?: InputMaybe<ReportCardOrder>;
}>;

export type ReportCardPaginationQuery = {
	__typename?: "Query";
	reportCardConnection: {
		__typename?: "ReportCardConnection";
		totalCount: number;
		edges: Array<{ __typename?: "ReportCardEdge"; cursor: string }>;
	};
};

export type ReportCardSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<ReportCardFilterInput> | ReportCardFilterInput>;
	order?: InputMaybe<ReportCardOrder>;
}>;

export type ReportCardSelectQuery = {
	__typename?: "Query";
	reportCardConnection: {
		__typename?: "ReportCardConnection";
		edges: Array<{ __typename?: "ReportCardEdge"; node: { __typename?: "ReportCard"; id: string } }>;
	};
};

export type ReportCardStudentCourseSectionListQueryVariables = Exact<{
	studentID: Scalars["ID"];
	courseSectionID: Scalars["ID"];
}>;

export type ReportCardStudentCourseSectionListQuery = {
	__typename?: "Query";
	reportCardConnection: {
		__typename?: "ReportCardConnection";
		edges: Array<{
			__typename?: "ReportCardEdge";
			node: {
				__typename?: "ReportCard";
				id: string;
				learningObjectiveMarks: Array<{
					__typename?: "LearningObjectiveMark";
					id: string;
					value: LearningObjectiveMarkValue | null;
					learningObjective: { __typename?: "LearningObjective"; id: string; category: string; description: string };
				}>;
			};
		}>;
	};
};

export type StudentCourseSectionTeacherReportCardsCourseSectionOverviewListQueryVariables = Exact<{
	courseSectionID: Scalars["ID"];
}>;

export type StudentCourseSectionTeacherReportCardsCourseSectionOverviewListQuery = {
	__typename?: "Query";
	courseSection: {
		__typename?: "CourseSection";
		id: string;
		name: string;
		course: {
			__typename?: "Course";
			id: string;
			learningObjectives: Array<{
				__typename?: "LearningObjective";
				id: string;
				category: string;
				description: string;
			}>;
		};
		courseSectionStudentEnrollments: Array<{
			__typename?: "CourseSectionStudentEnrollment";
			id: string;
			student: {
				__typename?: "Student";
				id: string;
				person: { __typename?: "Person"; id: string; name: string };
				reportCards: Array<{
					__typename?: "ReportCard";
					id: string;
					issueDate: string;
					learningObjectiveMarks: Array<{
						__typename?: "LearningObjectiveMark";
						id: string;
						value: LearningObjectiveMarkValue | null;
					}>;
				}>;
			};
		}>;
	};
};

export type StudentCourseSectionTeacherReportCardsTermOverviewByCoursesListQueryVariables = Exact<{
	termID: Scalars["ID"];
	teacherID: Scalars["ID"];
}>;

export type StudentCourseSectionTeacherReportCardsTermOverviewByCoursesListQuery = {
	__typename?: "Query";
	courseSectionConnection: {
		__typename?: "CourseSectionConnection";
		edges: Array<{
			__typename?: "CourseSectionEdge";
			node: {
				__typename?: "CourseSection";
				id: string;
				name: string;
				course: {
					__typename?: "Course";
					id: string;
					learningObjectives: Array<{ __typename?: "LearningObjective"; id: string }>;
				};
				courseSectionStudentEnrollments: Array<{
					__typename?: "CourseSectionStudentEnrollment";
					id: string;
					student: { __typename?: "Student"; id: string };
				}>;
			};
		}>;
	};
	studentConnection: {
		__typename?: "StudentConnection";
		edges: Array<{
			__typename?: "StudentEdge";
			node: {
				__typename?: "Student";
				id: string;
				person: { __typename?: "Person"; id: string; name: string };
				reportCards: Array<{
					__typename?: "ReportCard";
					id: string;
					issueDate: string;
					learningObjectiveMarks: Array<{
						__typename?: "LearningObjectiveMark";
						id: string;
						learningObjectiveID: string;
						value: LearningObjectiveMarkValue | null;
					}>;
				}>;
			};
		}>;
	};
};

export type ReportCardUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: ReportCardUpdateInput;
}>;

export type ReportCardUpdateMutation = {
	__typename?: "Mutation";
	updateReportCard: {
		__typename?: "ReportCard";
		id: string;
		issueDate: string;
		learningObjectiveMarks: Array<{ __typename?: "LearningObjectiveMark"; id: string }>;
		student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
		term: { __typename?: "Term"; id: string; name: string };
	};
};

export type RoomCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<RoomFilterInput> | RoomFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<RoomOrder>;
}>;

export type RoomCountQuery = {
	__typename?: "Query";
	roomConnection: { __typename?: "RoomConnection"; totalCount: number };
};

export type RoomCreateMutationVariables = Exact<{
	input: RoomCreateInput;
}>;

export type RoomCreateMutation = {
	__typename?: "Mutation";
	createRoom: {
		__typename?: "Room";
		id: string;
		name: string;
		number: string;
		campus: { __typename?: "Campus"; id: string; name: string };
		courseSectionPeriods: Array<{
			__typename?: "CourseSectionPeriod";
			id: string;
			dayOfWeek: number;
			startTimeMinutes: number;
			endTimeMinutes: number;
		}>;
	};
};

export type RoomDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type RoomDeleteMutation = { __typename?: "Mutation"; deleteRoom: boolean };

export type RoomDetailFragment = {
	__typename?: "Room";
	id: string;
	name: string;
	number: string;
	campus: { __typename?: "Campus"; id: string; name: string };
	courseSectionPeriods: Array<{
		__typename?: "CourseSectionPeriod";
		id: string;
		dayOfWeek: number;
		startTimeMinutes: number;
		endTimeMinutes: number;
	}>;
};

export type RoomDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type RoomDetailQuery = {
	__typename?: "Query";
	room: {
		__typename?: "Room";
		id: string;
		name: string;
		number: string;
		campus: { __typename?: "Campus"; id: string; name: string };
		courseSectionPeriods: Array<{
			__typename?: "CourseSectionPeriod";
			id: string;
			dayOfWeek: number;
			startTimeMinutes: number;
			endTimeMinutes: number;
		}>;
	};
};

export type RoomIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<RoomFilterInput> | RoomFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<RoomOrder>;
}>;

export type RoomIndexQuery = {
	__typename?: "Query";
	roomConnection: {
		__typename?: "RoomConnection";
		edges: Array<{ __typename?: "RoomEdge"; node: { __typename?: "Room"; id: string; name: string; number: string } }>;
	};
};

export type RoomNameFragment = { __typename?: "Room"; name: string };

export type RoomNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type RoomNameQuery = { __typename?: "Query"; room: { __typename?: "Room"; id: string; name: string } };

export type RoomNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<RoomFilterInput> | RoomFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<RoomOrder>;
}>;

export type RoomNamesQuery = {
	__typename?: "Query";
	roomConnection: {
		__typename?: "RoomConnection";
		edges: Array<{ __typename?: "RoomEdge"; node: { __typename?: "Room"; id: string; name: string } }>;
	};
};

export type RoomPaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<RoomFilterInput> | RoomFilterInput>;
	order?: InputMaybe<RoomOrder>;
}>;

export type RoomPaginationQuery = {
	__typename?: "Query";
	roomConnection: {
		__typename?: "RoomConnection";
		totalCount: number;
		edges: Array<{ __typename?: "RoomEdge"; cursor: string }>;
	};
};

export type RoomSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<RoomFilterInput> | RoomFilterInput>;
	order?: InputMaybe<RoomOrder>;
}>;

export type RoomSelectQuery = {
	__typename?: "Query";
	roomConnection: {
		__typename?: "RoomConnection";
		edges: Array<{ __typename?: "RoomEdge"; node: { __typename?: "Room"; id: string; name: string } }>;
	};
};

export type RoomUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: RoomUpdateInput;
}>;

export type RoomUpdateMutation = {
	__typename?: "Mutation";
	updateRoom: {
		__typename?: "Room";
		id: string;
		name: string;
		number: string;
		campus: { __typename?: "Campus"; id: string; name: string };
		courseSectionPeriods: Array<{
			__typename?: "CourseSectionPeriod";
			id: string;
			dayOfWeek: number;
			startTimeMinutes: number;
			endTimeMinutes: number;
		}>;
	};
};

export type SchoolAttendanceRecordCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<SchoolAttendanceRecordFilterInput> | SchoolAttendanceRecordFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<SchoolAttendanceRecordOrder>;
}>;

export type SchoolAttendanceRecordCountQuery = {
	__typename?: "Query";
	schoolAttendanceRecordConnection: { __typename?: "SchoolAttendanceRecordConnection"; totalCount: number };
};

export type SchoolAttendanceRecordCreateMutationVariables = Exact<{
	input: SchoolAttendanceRecordCreateInput;
}>;

export type SchoolAttendanceRecordCreateMutation = {
	__typename?: "Mutation";
	createSchoolAttendanceRecord: {
		__typename?: "SchoolAttendanceRecord";
		id: string;
		checkInTime: string;
		checkOutTime: string | null;
		isPresent: boolean;
		isLate: boolean;
		isExcused: boolean;
		notes: string;
		student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
	};
};

export type SchoolAttendanceRecordDailyReportListQueryVariables = Exact<{
	filters?: InputMaybe<Array<StudentFilterInput> | StudentFilterInput>;
	attendanceFilters?: InputMaybe<Array<SchoolAttendanceRecordFilterInput> | SchoolAttendanceRecordFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<StudentOrder>;
}>;

export type SchoolAttendanceRecordDailyReportListQuery = {
	__typename?: "Query";
	studentConnection: {
		__typename?: "StudentConnection";
		edges: Array<{
			__typename?: "StudentEdge";
			node: {
				__typename?: "Student";
				id: string;
				person: { __typename?: "Person"; id: string; name: string; imageURL: string };
				schoolAttendanceRecords: Array<{
					__typename?: "SchoolAttendanceRecord";
					id: string;
					isPresent: boolean;
					isLate: boolean;
				}>;
			};
		}>;
	};
};

export type SchoolAttendanceRecordDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type SchoolAttendanceRecordDeleteMutation = { __typename?: "Mutation"; deleteSchoolAttendanceRecord: boolean };

export type SchoolAttendanceRecordDetailFragment = {
	__typename?: "SchoolAttendanceRecord";
	id: string;
	checkInTime: string;
	checkOutTime: string | null;
	isPresent: boolean;
	isLate: boolean;
	isExcused: boolean;
	notes: string;
	student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
};

export type SchoolAttendanceRecordDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type SchoolAttendanceRecordDetailQuery = {
	__typename?: "Query";
	schoolAttendanceRecord: {
		__typename?: "SchoolAttendanceRecord";
		id: string;
		checkInTime: string;
		checkOutTime: string | null;
		isPresent: boolean;
		isLate: boolean;
		isExcused: boolean;
		notes: string;
		student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
	};
};

export type SchoolAttendanceRecordIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<SchoolAttendanceRecordFilterInput> | SchoolAttendanceRecordFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<SchoolAttendanceRecordOrder>;
}>;

export type SchoolAttendanceRecordIndexQuery = {
	__typename?: "Query";
	schoolAttendanceRecordConnection: {
		__typename?: "SchoolAttendanceRecordConnection";
		edges: Array<{
			__typename?: "SchoolAttendanceRecordEdge";
			node: {
				__typename?: "SchoolAttendanceRecord";
				id: string;
				checkInTime: string;
				checkOutTime: string | null;
				isPresent: boolean;
				isLate: boolean;
				isExcused: boolean;
				notes: string;
				student: {
					__typename?: "Student";
					id: string;
					person: { __typename?: "Person"; id: string; name: string; imageURL: string };
				};
			};
		}>;
	};
};

export type SchoolAttendanceRecordNameFragment = { __typename?: "SchoolAttendanceRecord"; id: string };

export type SchoolAttendanceRecordNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type SchoolAttendanceRecordNameQuery = {
	__typename?: "Query";
	schoolAttendanceRecord: { __typename?: "SchoolAttendanceRecord"; id: string };
};

export type SchoolAttendanceRecordNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<SchoolAttendanceRecordFilterInput> | SchoolAttendanceRecordFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<SchoolAttendanceRecordOrder>;
}>;

export type SchoolAttendanceRecordNamesQuery = {
	__typename?: "Query";
	schoolAttendanceRecordConnection: {
		__typename?: "SchoolAttendanceRecordConnection";
		edges: Array<{
			__typename?: "SchoolAttendanceRecordEdge";
			node: { __typename?: "SchoolAttendanceRecord"; id: string };
		}>;
	};
};

export type SchoolAttendanceRecordPaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<SchoolAttendanceRecordFilterInput> | SchoolAttendanceRecordFilterInput>;
	order?: InputMaybe<SchoolAttendanceRecordOrder>;
}>;

export type SchoolAttendanceRecordPaginationQuery = {
	__typename?: "Query";
	schoolAttendanceRecordConnection: {
		__typename?: "SchoolAttendanceRecordConnection";
		totalCount: number;
		edges: Array<{ __typename?: "SchoolAttendanceRecordEdge"; cursor: string }>;
	};
};

export type SchoolAttendanceRecordSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<SchoolAttendanceRecordFilterInput> | SchoolAttendanceRecordFilterInput>;
	order?: InputMaybe<SchoolAttendanceRecordOrder>;
}>;

export type SchoolAttendanceRecordSelectQuery = {
	__typename?: "Query";
	schoolAttendanceRecordConnection: {
		__typename?: "SchoolAttendanceRecordConnection";
		edges: Array<{
			__typename?: "SchoolAttendanceRecordEdge";
			node: { __typename?: "SchoolAttendanceRecord"; id: string };
		}>;
	};
};

export type SchoolAttendanceRecordStudentProfileListQueryVariables = Exact<{
	studentID: Scalars["ID"];
	startTime: Scalars["Time"];
	endTime: Scalars["Time"];
}>;

export type SchoolAttendanceRecordStudentProfileListQuery = {
	__typename?: "Query";
	schoolAttendanceRecordConnection: {
		__typename?: "SchoolAttendanceRecordConnection";
		edges: Array<{
			__typename?: "SchoolAttendanceRecordEdge";
			node: {
				__typename?: "SchoolAttendanceRecord";
				id: string;
				checkInTime: string;
				checkOutTime: string | null;
				isPresent: boolean;
				isLate: boolean;
				isExcused: boolean;
				notes: string;
			};
		}>;
	};
};

export type SchoolAttendanceRecordUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: SchoolAttendanceRecordUpdateInput;
}>;

export type SchoolAttendanceRecordUpdateMutation = {
	__typename?: "Mutation";
	updateSchoolAttendanceRecord: {
		__typename?: "SchoolAttendanceRecord";
		id: string;
		checkInTime: string;
		checkOutTime: string | null;
		isPresent: boolean;
		isLate: boolean;
		isExcused: boolean;
		notes: string;
		student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
	};
};

export type SchoolStudentEnrollmentCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<SchoolStudentEnrollmentFilterInput> | SchoolStudentEnrollmentFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<SchoolStudentEnrollmentOrder>;
}>;

export type SchoolStudentEnrollmentCountQuery = {
	__typename?: "Query";
	schoolStudentEnrollmentConnection: { __typename?: "SchoolStudentEnrollmentConnection"; totalCount: number };
};

export type SchoolStudentEnrollmentCreateMutationVariables = Exact<{
	input: SchoolStudentEnrollmentCreateInput;
}>;

export type SchoolStudentEnrollmentCreateMutation = {
	__typename?: "Mutation";
	createSchoolStudentEnrollment: {
		__typename?: "SchoolStudentEnrollment";
		id: string;
		createdDate: string;
		paymentDueDate: string;
		hasPaidFee: boolean;
		isVoided: boolean;
		academicYear: { __typename?: "AcademicYear"; id: string; name: string };
		classYear: { __typename?: "ClassYear"; id: string; name: string };
		student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
	};
};

export type SchoolStudentEnrollmentDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type SchoolStudentEnrollmentDeleteMutation = { __typename?: "Mutation"; deleteSchoolStudentEnrollment: boolean };

export type SchoolStudentEnrollmentDetailFragment = {
	__typename?: "SchoolStudentEnrollment";
	id: string;
	createdDate: string;
	paymentDueDate: string;
	hasPaidFee: boolean;
	isVoided: boolean;
	academicYear: { __typename?: "AcademicYear"; id: string; name: string };
	classYear: { __typename?: "ClassYear"; id: string; name: string };
	student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
};

export type SchoolStudentEnrollmentDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type SchoolStudentEnrollmentDetailQuery = {
	__typename?: "Query";
	schoolStudentEnrollment: {
		__typename?: "SchoolStudentEnrollment";
		id: string;
		createdDate: string;
		paymentDueDate: string;
		hasPaidFee: boolean;
		isVoided: boolean;
		academicYear: { __typename?: "AcademicYear"; id: string; name: string };
		classYear: { __typename?: "ClassYear"; id: string; name: string };
		student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
	};
};

export type SchoolStudentEnrollmentIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<SchoolStudentEnrollmentFilterInput> | SchoolStudentEnrollmentFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<SchoolStudentEnrollmentOrder>;
}>;

export type SchoolStudentEnrollmentIndexQuery = {
	__typename?: "Query";
	schoolStudentEnrollmentConnection: {
		__typename?: "SchoolStudentEnrollmentConnection";
		edges: Array<{
			__typename?: "SchoolStudentEnrollmentEdge";
			node: {
				__typename?: "SchoolStudentEnrollment";
				id: string;
				createdDate: string;
				paymentDueDate: string;
				hasPaidFee: boolean;
				isVoided: boolean;
				academicYear: { __typename?: "AcademicYear"; id: string; name: string };
				classYear: { __typename?: "ClassYear"; id: string; name: string };
				student: {
					__typename?: "Student";
					id: string;
					person: { __typename?: "Person"; id: string; name: string; imageURL: string };
				};
			};
		}>;
	};
};

export type SchoolStudentEnrollmentNameFragment = { __typename?: "SchoolStudentEnrollment"; id: string };

export type SchoolStudentEnrollmentNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type SchoolStudentEnrollmentNameQuery = {
	__typename?: "Query";
	schoolStudentEnrollment: { __typename?: "SchoolStudentEnrollment"; id: string };
};

export type SchoolStudentEnrollmentNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<SchoolStudentEnrollmentFilterInput> | SchoolStudentEnrollmentFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<SchoolStudentEnrollmentOrder>;
}>;

export type SchoolStudentEnrollmentNamesQuery = {
	__typename?: "Query";
	schoolStudentEnrollmentConnection: {
		__typename?: "SchoolStudentEnrollmentConnection";
		edges: Array<{
			__typename?: "SchoolStudentEnrollmentEdge";
			node: { __typename?: "SchoolStudentEnrollment"; id: string };
		}>;
	};
};

export type SchoolStudentEnrollmentPaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<SchoolStudentEnrollmentFilterInput> | SchoolStudentEnrollmentFilterInput>;
	order?: InputMaybe<SchoolStudentEnrollmentOrder>;
}>;

export type SchoolStudentEnrollmentPaginationQuery = {
	__typename?: "Query";
	schoolStudentEnrollmentConnection: {
		__typename?: "SchoolStudentEnrollmentConnection";
		totalCount: number;
		edges: Array<{ __typename?: "SchoolStudentEnrollmentEdge"; cursor: string }>;
	};
};

export type SchoolStudentEnrollmentSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<SchoolStudentEnrollmentFilterInput> | SchoolStudentEnrollmentFilterInput>;
	order?: InputMaybe<SchoolStudentEnrollmentOrder>;
}>;

export type SchoolStudentEnrollmentSelectQuery = {
	__typename?: "Query";
	schoolStudentEnrollmentConnection: {
		__typename?: "SchoolStudentEnrollmentConnection";
		edges: Array<{
			__typename?: "SchoolStudentEnrollmentEdge";
			node: { __typename?: "SchoolStudentEnrollment"; id: string };
		}>;
	};
};

export type SchoolStudentEnrollmentUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: SchoolStudentEnrollmentUpdateInput;
}>;

export type SchoolStudentEnrollmentUpdateMutation = {
	__typename?: "Mutation";
	updateSchoolStudentEnrollment: {
		__typename?: "SchoolStudentEnrollment";
		id: string;
		createdDate: string;
		paymentDueDate: string;
		hasPaidFee: boolean;
		isVoided: boolean;
		academicYear: { __typename?: "AcademicYear"; id: string; name: string };
		classYear: { __typename?: "ClassYear"; id: string; name: string };
		student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
	};
};

export type ServiceCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<ServiceFilterInput> | ServiceFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<ServiceOrder>;
}>;

export type ServiceCountQuery = {
	__typename?: "Query";
	serviceConnection: { __typename?: "ServiceConnection"; totalCount: number };
};

export type ServiceCreateMutationVariables = Exact<{
	input: ServiceCreateInput;
}>;

export type ServiceCreateMutation = {
	__typename?: "Mutation";
	createService: {
		__typename?: "Service";
		id: string;
		name: string;
		description: string;
		price: number;
		serviceSubscriptions: Array<{ __typename?: "ServiceSubscription"; id: string }>;
	};
};

export type ServiceDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type ServiceDeleteMutation = { __typename?: "Mutation"; deleteService: boolean };

export type ServiceDetailFragment = {
	__typename?: "Service";
	id: string;
	name: string;
	description: string;
	price: number;
	serviceSubscriptions: Array<{ __typename?: "ServiceSubscription"; id: string }>;
};

export type ServiceDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type ServiceDetailQuery = {
	__typename?: "Query";
	service: {
		__typename?: "Service";
		id: string;
		name: string;
		description: string;
		price: number;
		serviceSubscriptions: Array<{ __typename?: "ServiceSubscription"; id: string }>;
	};
};

export type ServiceIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<ServiceFilterInput> | ServiceFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<ServiceOrder>;
}>;

export type ServiceIndexQuery = {
	__typename?: "Query";
	serviceConnection: {
		__typename?: "ServiceConnection";
		edges: Array<{
			__typename?: "ServiceEdge";
			node: { __typename?: "Service"; id: string; name: string; description: string; price: number };
		}>;
	};
};

export type ServiceNameFragment = { __typename?: "Service"; name: string };

export type ServiceNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type ServiceNameQuery = { __typename?: "Query"; service: { __typename?: "Service"; id: string; name: string } };

export type ServiceNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<ServiceFilterInput> | ServiceFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<ServiceOrder>;
}>;

export type ServiceNamesQuery = {
	__typename?: "Query";
	serviceConnection: {
		__typename?: "ServiceConnection";
		edges: Array<{ __typename?: "ServiceEdge"; node: { __typename?: "Service"; id: string; name: string } }>;
	};
};

export type ServicePaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<ServiceFilterInput> | ServiceFilterInput>;
	order?: InputMaybe<ServiceOrder>;
}>;

export type ServicePaginationQuery = {
	__typename?: "Query";
	serviceConnection: {
		__typename?: "ServiceConnection";
		totalCount: number;
		edges: Array<{ __typename?: "ServiceEdge"; cursor: string }>;
	};
};

export type ServiceSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<ServiceFilterInput> | ServiceFilterInput>;
	order?: InputMaybe<ServiceOrder>;
}>;

export type ServiceSelectQuery = {
	__typename?: "Query";
	serviceConnection: {
		__typename?: "ServiceConnection";
		edges: Array<{
			__typename?: "ServiceEdge";
			node: { __typename?: "Service"; id: string; price: number; name: string };
		}>;
	};
};

export type ServiceUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: ServiceUpdateInput;
}>;

export type ServiceUpdateMutation = {
	__typename?: "Mutation";
	updateService: {
		__typename?: "Service";
		id: string;
		name: string;
		description: string;
		price: number;
		serviceSubscriptions: Array<{ __typename?: "ServiceSubscription"; id: string }>;
	};
};

export type ServiceSubscriptionCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<ServiceSubscriptionFilterInput> | ServiceSubscriptionFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<ServiceSubscriptionOrder>;
}>;

export type ServiceSubscriptionCountQuery = {
	__typename?: "Query";
	serviceSubscriptionConnection: { __typename?: "ServiceSubscriptionConnection"; totalCount: number };
};

export type ServiceSubscriptionsCreateBulkMutationVariables = Exact<{
	inputs: Array<ServiceSubscriptionCreateInput> | ServiceSubscriptionCreateInput;
}>;

export type ServiceSubscriptionsCreateBulkMutation = {
	__typename?: "Mutation";
	createBulkServiceSubscriptions: {
		__typename?: "ServiceSubscriptionConnection";
		edges: Array<{
			__typename?: "ServiceSubscriptionEdge";
			node: {
				__typename?: "ServiceSubscription";
				id: string;
				price: number;
				discounts: Array<{ __typename?: "Discount"; id: string }>;
				service: { __typename?: "Service"; id: string; name: string };
				contract: { __typename?: "Contract"; id: string; agreementDate: string; status: ContractStatus };
			};
		}>;
	};
};

export type ServiceSubscriptionCreateMutationVariables = Exact<{
	input: ServiceSubscriptionCreateInput;
}>;

export type ServiceSubscriptionCreateMutation = {
	__typename?: "Mutation";
	createServiceSubscription: {
		__typename?: "ServiceSubscription";
		id: string;
		price: number;
		discounts: Array<{ __typename?: "Discount"; id: string }>;
		service: { __typename?: "Service"; id: string; name: string };
		contract: { __typename?: "Contract"; id: string; agreementDate: string; status: ContractStatus };
	};
};

export type ServiceSubscriptionDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type ServiceSubscriptionDeleteMutation = { __typename?: "Mutation"; deleteServiceSubscription: boolean };

export type ServiceSubscriptionDetailFragment = {
	__typename?: "ServiceSubscription";
	id: string;
	price: number;
	discounts: Array<{ __typename?: "Discount"; id: string }>;
	service: { __typename?: "Service"; id: string; name: string };
	contract: { __typename?: "Contract"; id: string; agreementDate: string; status: ContractStatus };
};

export type ServiceSubscriptionDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type ServiceSubscriptionDetailQuery = {
	__typename?: "Query";
	serviceSubscription: {
		__typename?: "ServiceSubscription";
		id: string;
		price: number;
		discounts: Array<{ __typename?: "Discount"; id: string }>;
		service: { __typename?: "Service"; id: string; name: string };
		contract: { __typename?: "Contract"; id: string; agreementDate: string; status: ContractStatus };
	};
};

export type ServiceSubscriptionIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<ServiceSubscriptionFilterInput> | ServiceSubscriptionFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<ServiceSubscriptionOrder>;
}>;

export type ServiceSubscriptionIndexQuery = {
	__typename?: "Query";
	serviceSubscriptionConnection: {
		__typename?: "ServiceSubscriptionConnection";
		edges: Array<{
			__typename?: "ServiceSubscriptionEdge";
			node: {
				__typename?: "ServiceSubscription";
				id: string;
				price: number;
				service: { __typename?: "Service"; id: string; name: string };
			};
		}>;
	};
};

export type ServiceSubscriptionNameFragment = { __typename?: "ServiceSubscription"; id: string };

export type ServiceSubscriptionNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type ServiceSubscriptionNameQuery = {
	__typename?: "Query";
	serviceSubscription: { __typename?: "ServiceSubscription"; id: string };
};

export type ServiceSubscriptionNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<ServiceSubscriptionFilterInput> | ServiceSubscriptionFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<ServiceSubscriptionOrder>;
}>;

export type ServiceSubscriptionNamesQuery = {
	__typename?: "Query";
	serviceSubscriptionConnection: {
		__typename?: "ServiceSubscriptionConnection";
		edges: Array<{ __typename?: "ServiceSubscriptionEdge"; node: { __typename?: "ServiceSubscription"; id: string } }>;
	};
};

export type ServiceSubscriptionPaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<ServiceSubscriptionFilterInput> | ServiceSubscriptionFilterInput>;
	order?: InputMaybe<ServiceSubscriptionOrder>;
}>;

export type ServiceSubscriptionPaginationQuery = {
	__typename?: "Query";
	serviceSubscriptionConnection: {
		__typename?: "ServiceSubscriptionConnection";
		totalCount: number;
		edges: Array<{ __typename?: "ServiceSubscriptionEdge"; cursor: string }>;
	};
};

export type ServiceSubscriptionSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<ServiceSubscriptionFilterInput> | ServiceSubscriptionFilterInput>;
	order?: InputMaybe<ServiceSubscriptionOrder>;
}>;

export type ServiceSubscriptionSelectQuery = {
	__typename?: "Query";
	serviceSubscriptionConnection: {
		__typename?: "ServiceSubscriptionConnection";
		edges: Array<{ __typename?: "ServiceSubscriptionEdge"; node: { __typename?: "ServiceSubscription"; id: string } }>;
	};
};

export type ServiceSubscriptionUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: ServiceSubscriptionUpdateInput;
}>;

export type ServiceSubscriptionUpdateMutation = {
	__typename?: "Mutation";
	updateServiceSubscription: {
		__typename?: "ServiceSubscription";
		id: string;
		price: number;
		discounts: Array<{ __typename?: "Discount"; id: string }>;
		service: { __typename?: "Service"; id: string; name: string };
		contract: { __typename?: "Contract"; id: string; agreementDate: string; status: ContractStatus };
	};
};

export type SessionCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<SessionFilterInput> | SessionFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<SessionOrder>;
}>;

export type SessionCountQuery = {
	__typename?: "Query";
	sessionConnection: { __typename?: "SessionConnection"; totalCount: number };
};

export type SessionDetailFragment = {
	__typename?: "Session";
	id: string;
	createdAt: string;
	updatedAt: string;
	expiresAt: string;
	longestInactiveSec: number;
	isVerified: boolean;
	isCurrent: boolean;
	loginRecord: { __typename?: "LoginRecord"; id: string } | null;
	mfaInvalidOTPAttempts: Array<{ __typename?: "MFAInvalidOTPAttempt"; id: string }>;
	trustedDevice: { __typename?: "TrustedDevice"; id: string; name: string } | null;
	user: { __typename?: "User"; id: string; email: string };
};

export type SessionDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type SessionDetailQuery = {
	__typename?: "Query";
	session: {
		__typename?: "Session";
		id: string;
		createdAt: string;
		updatedAt: string;
		expiresAt: string;
		longestInactiveSec: number;
		isVerified: boolean;
		isCurrent: boolean;
		loginRecord: { __typename?: "LoginRecord"; id: string } | null;
		mfaInvalidOTPAttempts: Array<{ __typename?: "MFAInvalidOTPAttempt"; id: string }>;
		trustedDevice: { __typename?: "TrustedDevice"; id: string; name: string } | null;
		user: { __typename?: "User"; id: string; email: string };
	};
};

export type SessionIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<SessionFilterInput> | SessionFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<SessionOrder>;
}>;

export type SessionIndexQuery = {
	__typename?: "Query";
	sessionConnection: {
		__typename?: "SessionConnection";
		edges: Array<{
			__typename?: "SessionEdge";
			node: {
				__typename?: "Session";
				id: string;
				createdAt: string;
				updatedAt: string;
				expiresAt: string;
				longestInactiveSec: number;
				isVerified: boolean;
				isCurrent: boolean;
			};
		}>;
	};
};

export type SessionNameFragment = { __typename?: "Session"; id: string };

export type SessionNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type SessionNameQuery = { __typename?: "Query"; session: { __typename?: "Session"; id: string } };

export type SessionNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<SessionFilterInput> | SessionFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<SessionOrder>;
}>;

export type SessionNamesQuery = {
	__typename?: "Query";
	sessionConnection: {
		__typename?: "SessionConnection";
		edges: Array<{ __typename?: "SessionEdge"; node: { __typename?: "Session"; id: string } }>;
	};
};

export type SessionPaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<SessionFilterInput> | SessionFilterInput>;
	order?: InputMaybe<SessionOrder>;
}>;

export type SessionPaginationQuery = {
	__typename?: "Query";
	sessionConnection: {
		__typename?: "SessionConnection";
		totalCount: number;
		edges: Array<{ __typename?: "SessionEdge"; cursor: string }>;
	};
};

export type SessionSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<SessionFilterInput> | SessionFilterInput>;
	order?: InputMaybe<SessionOrder>;
}>;

export type SessionSelectQuery = {
	__typename?: "Query";
	sessionConnection: {
		__typename?: "SessionConnection";
		edges: Array<{ __typename?: "SessionEdge"; node: { __typename?: "Session"; id: string } }>;
	};
};

export type StudentCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<StudentFilterInput> | StudentFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<StudentOrder>;
}>;

export type StudentCountQuery = {
	__typename?: "Query";
	studentConnection: { __typename?: "StudentConnection"; totalCount: number };
};

export type StudentCreateMutationVariables = Exact<{
	input: StudentCreateInput;
}>;

export type StudentCreateMutation = {
	__typename?: "Mutation";
	createStudent: {
		__typename?: "Student";
		id: string;
		studentIDNumber: string;
		graduatingYear: number;
		hasPreviousSchooling: boolean;
		previousSchoolInformation: string;
		previousSchoolLocation: string;
		allergies: string;
		asthma: StudentAsthma;
		medication: string;
		medicationSchedule: string;
		dietaryRestrictions: string;
		healthNotes: string;
		doctorName: string;
		doctorPhoneNumber: string;
		emergencyContactInformation: string;
		person: {
			__typename?: "Person";
			id: string;
			name: string;
			firstName: string;
			lastName: string;
			nickname: string;
			alternateNames: string;
			imageURL: string;
			gender: string;
			dateOfBirth: string | null;
			occupation: string;
			nationality: string;
			primaryLanguage: string;
			englishLanguageFluency: PersonEnglishLanguageFluency;
			addresses: Array<{ __typename?: "Address"; id: string; shortAddress: string }>;
			emailAddresses: Array<{ __typename?: "EmailAddress"; id: string; email: string }>;
			employee: {
				__typename?: "Employee";
				id: string;
				person: { __typename?: "Person"; id: string; name: string };
			} | null;
			parent: { __typename?: "Parent"; id: string; person: { __typename?: "Person"; id: string; name: string } } | null;
			phoneNumbers: Array<{
				__typename?: "PhoneNumber";
				id: string;
				phoneNumber: string;
				phoneType: PhoneNumberPhoneType;
			}>;
			student: {
				__typename?: "Student";
				id: string;
				person: { __typename?: "Person"; id: string; name: string };
			} | null;
			user: { __typename?: "User"; id: string; email: string } | null;
		};
		homeRoomSectionStudentEnrollments: Array<{
			__typename?: "HomeRoomSectionStudentEnrollment";
			id: string;
			homeRoomSection: {
				__typename?: "HomeRoomSection";
				id: string;
				name: string;
				homeRoom: { __typename?: "HomeRoom"; id: string; name: string };
			};
		}>;
		tuition: { __typename?: "Tuition"; id: string; name: string } | null;
	};
};

export type StudentDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type StudentDeleteMutation = { __typename?: "Mutation"; deleteStudent: boolean };

export type StudentDetailFragment = {
	__typename?: "Student";
	id: string;
	studentIDNumber: string;
	graduatingYear: number;
	hasPreviousSchooling: boolean;
	previousSchoolInformation: string;
	previousSchoolLocation: string;
	allergies: string;
	asthma: StudentAsthma;
	medication: string;
	medicationSchedule: string;
	dietaryRestrictions: string;
	healthNotes: string;
	doctorName: string;
	doctorPhoneNumber: string;
	emergencyContactInformation: string;
	person: {
		__typename?: "Person";
		id: string;
		name: string;
		firstName: string;
		lastName: string;
		nickname: string;
		alternateNames: string;
		imageURL: string;
		gender: string;
		dateOfBirth: string | null;
		occupation: string;
		nationality: string;
		primaryLanguage: string;
		englishLanguageFluency: PersonEnglishLanguageFluency;
		addresses: Array<{ __typename?: "Address"; id: string; shortAddress: string }>;
		emailAddresses: Array<{ __typename?: "EmailAddress"; id: string; email: string }>;
		employee: {
			__typename?: "Employee";
			id: string;
			person: { __typename?: "Person"; id: string; name: string };
		} | null;
		parent: { __typename?: "Parent"; id: string; person: { __typename?: "Person"; id: string; name: string } } | null;
		phoneNumbers: Array<{
			__typename?: "PhoneNumber";
			id: string;
			phoneNumber: string;
			phoneType: PhoneNumberPhoneType;
		}>;
		student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } } | null;
		user: { __typename?: "User"; id: string; email: string } | null;
	};
	homeRoomSectionStudentEnrollments: Array<{
		__typename?: "HomeRoomSectionStudentEnrollment";
		id: string;
		homeRoomSection: {
			__typename?: "HomeRoomSection";
			id: string;
			name: string;
			homeRoom: { __typename?: "HomeRoom"; id: string; name: string };
		};
	}>;
	tuition: { __typename?: "Tuition"; id: string; name: string } | null;
};

export type StudentDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type StudentDetailQuery = {
	__typename?: "Query";
	student: {
		__typename?: "Student";
		id: string;
		studentIDNumber: string;
		graduatingYear: number;
		hasPreviousSchooling: boolean;
		previousSchoolInformation: string;
		previousSchoolLocation: string;
		allergies: string;
		asthma: StudentAsthma;
		medication: string;
		medicationSchedule: string;
		dietaryRestrictions: string;
		healthNotes: string;
		doctorName: string;
		doctorPhoneNumber: string;
		emergencyContactInformation: string;
		person: {
			__typename?: "Person";
			id: string;
			name: string;
			firstName: string;
			lastName: string;
			nickname: string;
			alternateNames: string;
			imageURL: string;
			gender: string;
			dateOfBirth: string | null;
			occupation: string;
			nationality: string;
			primaryLanguage: string;
			englishLanguageFluency: PersonEnglishLanguageFluency;
			addresses: Array<{ __typename?: "Address"; id: string; shortAddress: string }>;
			emailAddresses: Array<{ __typename?: "EmailAddress"; id: string; email: string }>;
			employee: {
				__typename?: "Employee";
				id: string;
				person: { __typename?: "Person"; id: string; name: string };
			} | null;
			parent: { __typename?: "Parent"; id: string; person: { __typename?: "Person"; id: string; name: string } } | null;
			phoneNumbers: Array<{
				__typename?: "PhoneNumber";
				id: string;
				phoneNumber: string;
				phoneType: PhoneNumberPhoneType;
			}>;
			student: {
				__typename?: "Student";
				id: string;
				person: { __typename?: "Person"; id: string; name: string };
			} | null;
			user: { __typename?: "User"; id: string; email: string } | null;
		};
		homeRoomSectionStudentEnrollments: Array<{
			__typename?: "HomeRoomSectionStudentEnrollment";
			id: string;
			homeRoomSection: {
				__typename?: "HomeRoomSection";
				id: string;
				name: string;
				homeRoom: { __typename?: "HomeRoom"; id: string; name: string };
			};
		}>;
		tuition: { __typename?: "Tuition"; id: string; name: string } | null;
	};
};

export type StudentIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<StudentFilterInput> | StudentFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<StudentOrder>;
}>;

export type StudentIndexQuery = {
	__typename?: "Query";
	studentConnection: {
		__typename?: "StudentConnection";
		edges: Array<{
			__typename?: "StudentEdge";
			node: {
				__typename?: "Student";
				id: string;
				graduatingYear: number;
				hasPreviousSchooling: boolean;
				previousSchoolInformation: string;
				previousSchoolLocation: string;
				allergies: string;
				asthma: StudentAsthma;
				medication: string;
				medicationSchedule: string;
				dietaryRestrictions: string;
				healthNotes: string;
				doctorName: string;
				doctorPhoneNumber: string;
				emergencyContactInformation: string;
				person: { __typename?: "Person"; id: string; name: string; imageURL: string; dateOfBirth: string | null };
				homeRoomSectionStudentEnrollments: Array<{
					__typename?: "HomeRoomSectionStudentEnrollment";
					id: string;
					homeRoomSection: {
						__typename?: "HomeRoomSection";
						id: string;
						name: string;
						homeRoom: { __typename?: "HomeRoom"; id: string; name: string };
					};
				}>;
				busPlans: Array<{ __typename?: "BusPlan"; id: string }>;
				mealPlans: Array<{ __typename?: "MealPlan"; id: string }>;
				relationships: Array<{
					__typename?: "Relationship";
					id: string;
					relationshipType: RelationshipRelationshipType;
					parent: {
						__typename?: "Parent";
						id: string;
						person: {
							__typename?: "Person";
							id: string;
							name: string;
							phoneNumbers: Array<{ __typename?: "PhoneNumber"; id: string; phoneNumber: string }>;
							emailAddresses: Array<{ __typename?: "EmailAddress"; id: string; email: string }>;
						};
					};
				}>;
			};
		}>;
	};
};

export type StudentListQueryVariables = Exact<{
	filters?: InputMaybe<Array<StudentFilterInput> | StudentFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<StudentOrder>;
}>;

export type StudentListQuery = {
	__typename?: "Query";
	studentConnection: {
		__typename?: "StudentConnection";
		edges: Array<{
			__typename?: "StudentEdge";
			node: {
				__typename?: "Student";
				id: string;
				person: { __typename?: "Person"; id: string; name: string; imageURL: string };
				homeRoomSectionStudentEnrollments: Array<{
					__typename?: "HomeRoomSectionStudentEnrollment";
					id: string;
					homeRoomSection: {
						__typename?: "HomeRoomSection";
						id: string;
						name: string;
						homeRoom: { __typename?: "HomeRoom"; id: string; name: string };
					};
				}>;
			};
		}>;
	};
};

export type StudentNameFragment = {
	__typename?: "Student";
	person: { __typename?: "Person"; id: string; name: string };
};

export type StudentNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type StudentNameQuery = {
	__typename?: "Query";
	student: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
};

export type StudentNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<StudentFilterInput> | StudentFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<StudentOrder>;
}>;

export type StudentNamesQuery = {
	__typename?: "Query";
	studentConnection: {
		__typename?: "StudentConnection";
		edges: Array<{
			__typename?: "StudentEdge";
			node: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
		}>;
	};
};

export type StudentPaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<StudentFilterInput> | StudentFilterInput>;
	order?: InputMaybe<StudentOrder>;
}>;

export type StudentPaginationQuery = {
	__typename?: "Query";
	studentConnection: {
		__typename?: "StudentConnection";
		totalCount: number;
		edges: Array<{ __typename?: "StudentEdge"; cursor: string }>;
	};
};

export type StudentPreviewQueryVariables = Exact<{
	id: Scalars["ID"];
	date: Scalars["Time"];
}>;

export type StudentPreviewQuery = {
	__typename?: "Query";
	student: {
		__typename?: "Student";
		id: string;
		person: {
			__typename?: "Person";
			id: string;
			name: string;
			imageURL: string;
			dateOfBirth: string | null;
			gender: string;
			nationality: string;
		};
		homeRoomSectionStudentEnrollments: Array<{
			__typename?: "HomeRoomSectionStudentEnrollment";
			id: string;
			homeRoomSection: { __typename?: "HomeRoomSection"; id: string; name: string };
		}>;
	};
};

export type SectionsActiveForStudentListQueryVariables = Exact<{
	studentID: Scalars["ID"];
	activeDate: Scalars["Time"];
}>;

export type SectionsActiveForStudentListQuery = {
	__typename?: "Query";
	student: {
		__typename?: "Student";
		id: string;
		homeRoomSectionStudentEnrollments: Array<{
			__typename?: "HomeRoomSectionStudentEnrollment";
			id: string;
			homeRoomSection: {
				__typename?: "HomeRoomSection";
				id: string;
				name: string;
				homeRoom: { __typename?: "HomeRoom"; id: string; name: string };
				homeRoomSectionTeacherEnrollments: Array<{
					__typename?: "HomeRoomSectionTeacherEnrollment";
					id: string;
					teacher: {
						__typename?: "Employee";
						id: string;
						person: { __typename?: "Person"; id: string; name: string; imageURL: string };
					};
				}>;
			};
		}>;
		courseSectionStudentEnrollments: Array<{
			__typename?: "CourseSectionStudentEnrollment";
			id: string;
			courseSection: {
				__typename?: "CourseSection";
				id: string;
				name: string;
				course: { __typename?: "Course"; id: string; name: string; subject: CourseSubject };
				courseSectionTeacherEnrollments: Array<{
					__typename?: "CourseSectionTeacherEnrollment";
					id: string;
					teacher: {
						__typename?: "Employee";
						id: string;
						person: { __typename?: "Person"; id: string; name: string; imageURL: string };
					};
				}>;
			};
		}>;
	};
};

export type StudentSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<StudentFilterInput> | StudentFilterInput>;
	order?: InputMaybe<StudentOrder>;
}>;

export type StudentSelectQuery = {
	__typename?: "Query";
	studentConnection: {
		__typename?: "StudentConnection";
		edges: Array<{
			__typename?: "StudentEdge";
			node: { __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } };
		}>;
	};
};

export type StudentSiblingListQueryVariables = Exact<{
	studentID: Scalars["ID"];
}>;

export type StudentSiblingListQuery = {
	__typename?: "Query";
	studentConnection: {
		__typename?: "StudentConnection";
		edges: Array<{
			__typename?: "StudentEdge";
			node: {
				__typename?: "Student";
				id: string;
				person: { __typename?: "Person"; id: string; name: string; imageURL: string };
			};
		}>;
	};
};

export type StudentUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: StudentUpdateInput;
}>;

export type StudentUpdateMutation = {
	__typename?: "Mutation";
	updateStudent: {
		__typename?: "Student";
		id: string;
		studentIDNumber: string;
		graduatingYear: number;
		hasPreviousSchooling: boolean;
		previousSchoolInformation: string;
		previousSchoolLocation: string;
		allergies: string;
		asthma: StudentAsthma;
		medication: string;
		medicationSchedule: string;
		dietaryRestrictions: string;
		healthNotes: string;
		doctorName: string;
		doctorPhoneNumber: string;
		emergencyContactInformation: string;
		person: {
			__typename?: "Person";
			id: string;
			name: string;
			firstName: string;
			lastName: string;
			nickname: string;
			alternateNames: string;
			imageURL: string;
			gender: string;
			dateOfBirth: string | null;
			occupation: string;
			nationality: string;
			primaryLanguage: string;
			englishLanguageFluency: PersonEnglishLanguageFluency;
			addresses: Array<{ __typename?: "Address"; id: string; shortAddress: string }>;
			emailAddresses: Array<{ __typename?: "EmailAddress"; id: string; email: string }>;
			employee: {
				__typename?: "Employee";
				id: string;
				person: { __typename?: "Person"; id: string; name: string };
			} | null;
			parent: { __typename?: "Parent"; id: string; person: { __typename?: "Person"; id: string; name: string } } | null;
			phoneNumbers: Array<{
				__typename?: "PhoneNumber";
				id: string;
				phoneNumber: string;
				phoneType: PhoneNumberPhoneType;
			}>;
			student: {
				__typename?: "Student";
				id: string;
				person: { __typename?: "Person"; id: string; name: string };
			} | null;
			user: { __typename?: "User"; id: string; email: string } | null;
		};
		homeRoomSectionStudentEnrollments: Array<{
			__typename?: "HomeRoomSectionStudentEnrollment";
			id: string;
			homeRoomSection: {
				__typename?: "HomeRoomSection";
				id: string;
				name: string;
				homeRoom: { __typename?: "HomeRoom"; id: string; name: string };
			};
		}>;
		tuition: { __typename?: "Tuition"; id: string; name: string } | null;
	};
};

export type StudentUpdateWithPersonMutationVariables = Exact<{
	id: Scalars["ID"];
	input: StudentUpdateInput;
	personInput: PersonUpdateInput;
}>;

export type StudentUpdateWithPersonMutation = {
	__typename?: "Mutation";
	updateStudentWithPerson: {
		__typename?: "Student";
		id: string;
		studentIDNumber: string;
		graduatingYear: number;
		hasPreviousSchooling: boolean;
		previousSchoolInformation: string;
		previousSchoolLocation: string;
		allergies: string;
		asthma: StudentAsthma;
		medication: string;
		medicationSchedule: string;
		dietaryRestrictions: string;
		healthNotes: string;
		doctorName: string;
		doctorPhoneNumber: string;
		emergencyContactInformation: string;
		person: {
			__typename?: "Person";
			id: string;
			name: string;
			firstName: string;
			lastName: string;
			nickname: string;
			alternateNames: string;
			imageURL: string;
			gender: string;
			dateOfBirth: string | null;
			occupation: string;
			nationality: string;
			primaryLanguage: string;
			englishLanguageFluency: PersonEnglishLanguageFluency;
			addresses: Array<{ __typename?: "Address"; id: string; shortAddress: string }>;
			emailAddresses: Array<{ __typename?: "EmailAddress"; id: string; email: string }>;
			employee: {
				__typename?: "Employee";
				id: string;
				person: { __typename?: "Person"; id: string; name: string };
			} | null;
			parent: { __typename?: "Parent"; id: string; person: { __typename?: "Person"; id: string; name: string } } | null;
			phoneNumbers: Array<{
				__typename?: "PhoneNumber";
				id: string;
				phoneNumber: string;
				phoneType: PhoneNumberPhoneType;
			}>;
			student: {
				__typename?: "Student";
				id: string;
				person: { __typename?: "Person"; id: string; name: string };
			} | null;
			user: { __typename?: "User"; id: string; email: string } | null;
		};
		homeRoomSectionStudentEnrollments: Array<{
			__typename?: "HomeRoomSectionStudentEnrollment";
			id: string;
			homeRoomSection: {
				__typename?: "HomeRoomSection";
				id: string;
				name: string;
				homeRoom: { __typename?: "HomeRoom"; id: string; name: string };
			};
		}>;
		tuition: { __typename?: "Tuition"; id: string; name: string } | null;
	};
};

export type StudentDocumentCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<StudentDocumentFilterInput> | StudentDocumentFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<StudentDocumentOrder>;
}>;

export type StudentDocumentCountQuery = {
	__typename?: "Query";
	studentDocumentConnection: { __typename?: "StudentDocumentConnection"; totalCount: number };
};

export type StudentDocumentCreateMutationVariables = Exact<{
	input: StudentDocumentCreateInput;
}>;

export type StudentDocumentCreateMutation = {
	__typename?: "Mutation";
	createStudentDocument: {
		__typename?: "StudentDocument";
		id: string;
		name: string;
		description: string;
		fileURL: string;
		isPublic: boolean;
		createdAt: string;
		author: { __typename?: "User"; id: string; email: string } | null;
		sharingUserGroups: Array<{ __typename?: "UserGroup"; id: string; name: string }>;
		student: {
			__typename?: "Student";
			id: string;
			person: { __typename?: "Person"; id: string; name: string; imageURL: string };
		};
	};
};

export type StudentDocumentDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type StudentDocumentDeleteMutation = { __typename?: "Mutation"; deleteStudentDocument: boolean };

export type StudentDocumentDetailFragment = {
	__typename?: "StudentDocument";
	id: string;
	name: string;
	description: string;
	fileURL: string;
	isPublic: boolean;
	createdAt: string;
	author: { __typename?: "User"; id: string; email: string } | null;
	sharingUserGroups: Array<{ __typename?: "UserGroup"; id: string; name: string }>;
	student: {
		__typename?: "Student";
		id: string;
		person: { __typename?: "Person"; id: string; name: string; imageURL: string };
	};
};

export type StudentDocumentDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type StudentDocumentDetailQuery = {
	__typename?: "Query";
	studentDocument: {
		__typename?: "StudentDocument";
		id: string;
		name: string;
		description: string;
		fileURL: string;
		isPublic: boolean;
		createdAt: string;
		author: { __typename?: "User"; id: string; email: string } | null;
		sharingUserGroups: Array<{ __typename?: "UserGroup"; id: string; name: string }>;
		student: {
			__typename?: "Student";
			id: string;
			person: { __typename?: "Person"; id: string; name: string; imageURL: string };
		};
	};
};

export type StudentDocumentIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<StudentDocumentFilterInput> | StudentDocumentFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<StudentDocumentOrder>;
}>;

export type StudentDocumentIndexQuery = {
	__typename?: "Query";
	studentDocumentConnection: {
		__typename?: "StudentDocumentConnection";
		edges: Array<{
			__typename?: "StudentDocumentEdge";
			node: {
				__typename?: "StudentDocument";
				id: string;
				name: string;
				description: string;
				fileURL: string;
				isPublic: boolean;
				createdAt: string;
			};
		}>;
	};
};

export type StudentDocumentNameFragment = { __typename?: "StudentDocument"; name: string };

export type StudentDocumentNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type StudentDocumentNameQuery = {
	__typename?: "Query";
	studentDocument: { __typename?: "StudentDocument"; id: string; name: string };
};

export type StudentDocumentNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<StudentDocumentFilterInput> | StudentDocumentFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<StudentDocumentOrder>;
}>;

export type StudentDocumentNamesQuery = {
	__typename?: "Query";
	studentDocumentConnection: {
		__typename?: "StudentDocumentConnection";
		edges: Array<{
			__typename?: "StudentDocumentEdge";
			node: { __typename?: "StudentDocument"; id: string; name: string };
		}>;
	};
};

export type StudentDocumentPaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<StudentDocumentFilterInput> | StudentDocumentFilterInput>;
	order?: InputMaybe<StudentDocumentOrder>;
}>;

export type StudentDocumentPaginationQuery = {
	__typename?: "Query";
	studentDocumentConnection: {
		__typename?: "StudentDocumentConnection";
		totalCount: number;
		edges: Array<{ __typename?: "StudentDocumentEdge"; cursor: string }>;
	};
};

export type StudentDocumentSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<StudentDocumentFilterInput> | StudentDocumentFilterInput>;
	order?: InputMaybe<StudentDocumentOrder>;
}>;

export type StudentDocumentSelectQuery = {
	__typename?: "Query";
	studentDocumentConnection: {
		__typename?: "StudentDocumentConnection";
		edges: Array<{
			__typename?: "StudentDocumentEdge";
			node: { __typename?: "StudentDocument"; id: string; name: string };
		}>;
	};
};

export type StudentDocumentStudentListQueryVariables = Exact<{
	filters?: InputMaybe<Array<StudentDocumentFilterInput> | StudentDocumentFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<StudentDocumentOrder>;
}>;

export type StudentDocumentStudentListQuery = {
	__typename?: "Query";
	studentDocumentConnection: {
		__typename?: "StudentDocumentConnection";
		edges: Array<{
			__typename?: "StudentDocumentEdge";
			node: { __typename?: "StudentDocument"; id: string; name: string; fileURL: string; fileContentType: string };
		}>;
	};
};

export type StudentDocumentUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: StudentDocumentUpdateInput;
}>;

export type StudentDocumentUpdateMutation = {
	__typename?: "Mutation";
	updateStudentDocument: {
		__typename?: "StudentDocument";
		id: string;
		name: string;
		description: string;
		fileURL: string;
		isPublic: boolean;
		createdAt: string;
		author: { __typename?: "User"; id: string; email: string } | null;
		sharingUserGroups: Array<{ __typename?: "UserGroup"; id: string; name: string }>;
		student: {
			__typename?: "Student";
			id: string;
			person: { __typename?: "Person"; id: string; name: string; imageURL: string };
		};
	};
};

export type TermCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<TermFilterInput> | TermFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<TermOrder>;
}>;

export type TermCountQuery = {
	__typename?: "Query";
	termConnection: { __typename?: "TermConnection"; totalCount: number };
};

export type TermCreateMutationVariables = Exact<{
	input: TermCreateInput;
}>;

export type TermCreateMutation = {
	__typename?: "Mutation";
	createTerm: {
		__typename?: "Term";
		id: string;
		name: string;
		startDate: string;
		endDate: string;
		academicYear: { __typename?: "AcademicYear"; id: string; name: string };
		courseSections: Array<{
			__typename?: "CourseSection";
			id: string;
			name: string;
			course: { __typename?: "Course"; id: string; subject: CourseSubject };
			homeRoomSection: { __typename?: "HomeRoomSection"; id: string; name: string };
		}>;
		homeRoomSections: Array<{ __typename?: "HomeRoomSection"; id: string; name: string }>;
	};
};

export type TermDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type TermDeleteMutation = { __typename?: "Mutation"; deleteTerm: boolean };

export type TermDetailListQueryVariables = Exact<{
	filters?: InputMaybe<Array<TermFilterInput> | TermFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<TermOrder>;
}>;

export type TermDetailListQuery = {
	__typename?: "Query";
	termConnection: {
		__typename?: "TermConnection";
		edges: Array<{
			__typename?: "TermEdge";
			node: {
				__typename?: "Term";
				id: string;
				name: string;
				startDate: string;
				endDate: string;
				academicYear: { __typename?: "AcademicYear"; id: string; name: string };
				courseSections: Array<{
					__typename?: "CourseSection";
					id: string;
					name: string;
					course: { __typename?: "Course"; id: string; subject: CourseSubject };
					homeRoomSection: { __typename?: "HomeRoomSection"; id: string; name: string };
				}>;
				homeRoomSections: Array<{ __typename?: "HomeRoomSection"; id: string; name: string }>;
			};
		}>;
	};
};

export type TermDetailFragment = {
	__typename?: "Term";
	id: string;
	name: string;
	startDate: string;
	endDate: string;
	academicYear: { __typename?: "AcademicYear"; id: string; name: string };
	courseSections: Array<{
		__typename?: "CourseSection";
		id: string;
		name: string;
		course: { __typename?: "Course"; id: string; subject: CourseSubject };
		homeRoomSection: { __typename?: "HomeRoomSection"; id: string; name: string };
	}>;
	homeRoomSections: Array<{ __typename?: "HomeRoomSection"; id: string; name: string }>;
};

export type TermDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type TermDetailQuery = {
	__typename?: "Query";
	term: {
		__typename?: "Term";
		id: string;
		name: string;
		startDate: string;
		endDate: string;
		academicYear: { __typename?: "AcademicYear"; id: string; name: string };
		courseSections: Array<{
			__typename?: "CourseSection";
			id: string;
			name: string;
			course: { __typename?: "Course"; id: string; subject: CourseSubject };
			homeRoomSection: { __typename?: "HomeRoomSection"; id: string; name: string };
		}>;
		homeRoomSections: Array<{ __typename?: "HomeRoomSection"; id: string; name: string }>;
	};
};

export type TermIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<TermFilterInput> | TermFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<TermOrder>;
}>;

export type TermIndexQuery = {
	__typename?: "Query";
	termConnection: {
		__typename?: "TermConnection";
		edges: Array<{
			__typename?: "TermEdge";
			node: { __typename?: "Term"; id: string; name: string; startDate: string; endDate: string };
		}>;
	};
};

export type TermNameFragment = { __typename?: "Term"; name: string };

export type TermNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type TermNameQuery = { __typename?: "Query"; term: { __typename?: "Term"; id: string; name: string } };

export type TermNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<TermFilterInput> | TermFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<TermOrder>;
}>;

export type TermNamesQuery = {
	__typename?: "Query";
	termConnection: {
		__typename?: "TermConnection";
		edges: Array<{ __typename?: "TermEdge"; node: { __typename?: "Term"; id: string; name: string } }>;
	};
};

export type TermPaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<TermFilterInput> | TermFilterInput>;
	order?: InputMaybe<TermOrder>;
}>;

export type TermPaginationQuery = {
	__typename?: "Query";
	termConnection: {
		__typename?: "TermConnection";
		totalCount: number;
		edges: Array<{ __typename?: "TermEdge"; cursor: string }>;
	};
};

export type TermSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<TermFilterInput> | TermFilterInput>;
	order?: InputMaybe<TermOrder>;
}>;

export type TermSelectQuery = {
	__typename?: "Query";
	termConnection: {
		__typename?: "TermConnection";
		edges: Array<{ __typename?: "TermEdge"; node: { __typename?: "Term"; id: string; name: string } }>;
	};
};

export type TermUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: TermUpdateInput;
}>;

export type TermUpdateMutation = {
	__typename?: "Mutation";
	updateTerm: {
		__typename?: "Term";
		id: string;
		name: string;
		startDate: string;
		endDate: string;
		academicYear: { __typename?: "AcademicYear"; id: string; name: string };
		courseSections: Array<{
			__typename?: "CourseSection";
			id: string;
			name: string;
			course: { __typename?: "Course"; id: string; subject: CourseSubject };
			homeRoomSection: { __typename?: "HomeRoomSection"; id: string; name: string };
		}>;
		homeRoomSections: Array<{ __typename?: "HomeRoomSection"; id: string; name: string }>;
	};
};

export type TrustedDeviceCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<TrustedDeviceFilterInput> | TrustedDeviceFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<TrustedDeviceOrder>;
}>;

export type TrustedDeviceCountQuery = {
	__typename?: "Query";
	trustedDeviceConnection: { __typename?: "TrustedDeviceConnection"; totalCount: number };
};

export type TrustedDeviceDetailFragment = {
	__typename?: "TrustedDevice";
	id: string;
	createdAt: string;
	name: string;
	isCurrent: boolean;
	sessions: Array<{ __typename?: "Session"; id: string }>;
	user: { __typename?: "User"; id: string; email: string };
};

export type TrustedDeviceDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type TrustedDeviceDetailQuery = {
	__typename?: "Query";
	trustedDevice: {
		__typename?: "TrustedDevice";
		id: string;
		createdAt: string;
		name: string;
		isCurrent: boolean;
		sessions: Array<{ __typename?: "Session"; id: string }>;
		user: { __typename?: "User"; id: string; email: string };
	};
};

export type TrustedDeviceIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<TrustedDeviceFilterInput> | TrustedDeviceFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<TrustedDeviceOrder>;
}>;

export type TrustedDeviceIndexQuery = {
	__typename?: "Query";
	trustedDeviceConnection: {
		__typename?: "TrustedDeviceConnection";
		edges: Array<{
			__typename?: "TrustedDeviceEdge";
			node: { __typename?: "TrustedDevice"; id: string; createdAt: string; name: string; isCurrent: boolean };
		}>;
	};
};

export type TrustedDeviceNameFragment = { __typename?: "TrustedDevice"; name: string };

export type TrustedDeviceNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type TrustedDeviceNameQuery = {
	__typename?: "Query";
	trustedDevice: { __typename?: "TrustedDevice"; id: string; name: string };
};

export type TrustedDeviceNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<TrustedDeviceFilterInput> | TrustedDeviceFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<TrustedDeviceOrder>;
}>;

export type TrustedDeviceNamesQuery = {
	__typename?: "Query";
	trustedDeviceConnection: {
		__typename?: "TrustedDeviceConnection";
		edges: Array<{
			__typename?: "TrustedDeviceEdge";
			node: { __typename?: "TrustedDevice"; id: string; name: string };
		}>;
	};
};

export type TrustedDevicePaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<TrustedDeviceFilterInput> | TrustedDeviceFilterInput>;
	order?: InputMaybe<TrustedDeviceOrder>;
}>;

export type TrustedDevicePaginationQuery = {
	__typename?: "Query";
	trustedDeviceConnection: {
		__typename?: "TrustedDeviceConnection";
		totalCount: number;
		edges: Array<{ __typename?: "TrustedDeviceEdge"; cursor: string }>;
	};
};

export type TrustedDeviceSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<TrustedDeviceFilterInput> | TrustedDeviceFilterInput>;
	order?: InputMaybe<TrustedDeviceOrder>;
}>;

export type TrustedDeviceSelectQuery = {
	__typename?: "Query";
	trustedDeviceConnection: {
		__typename?: "TrustedDeviceConnection";
		edges: Array<{
			__typename?: "TrustedDeviceEdge";
			node: { __typename?: "TrustedDevice"; id: string; name: string };
		}>;
	};
};

export type TuitionCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<TuitionFilterInput> | TuitionFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<TuitionOrder>;
}>;

export type TuitionCountQuery = {
	__typename?: "Query";
	tuitionConnection: { __typename?: "TuitionConnection"; totalCount: number };
};

export type TuitionCreateMutationVariables = Exact<{
	input: TuitionCreateInput;
}>;

export type TuitionCreateMutation = {
	__typename?: "Mutation";
	createTuition: {
		__typename?: "Tuition";
		id: string;
		name: string;
		initialYear: number;
		academicYearTuitions: Array<{ __typename?: "AcademicYearTuition"; id: string; year: number }>;
		students: Array<{
			__typename?: "Student";
			id: string;
			person: { __typename?: "Person"; id: string; name: string };
		}>;
	};
};

export type TuitionDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type TuitionDeleteMutation = { __typename?: "Mutation"; deleteTuition: boolean };

export type TuitionDetailFieldsFragment = {
	__typename?: "Tuition";
	id: string;
	name: string;
	initialYear: number;
	academicYearTuitions: Array<{ __typename?: "AcademicYearTuition"; id: string; year: number }>;
	students: Array<{ __typename?: "Student"; id: string; person: { __typename?: "Person"; id: string; name: string } }>;
};

export type TuitionDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type TuitionDetailQuery = {
	__typename?: "Query";
	tuition: {
		__typename?: "Tuition";
		id: string;
		name: string;
		initialYear: number;
		academicYearTuitions: Array<{ __typename?: "AcademicYearTuition"; id: string; year: number }>;
		students: Array<{
			__typename?: "Student";
			id: string;
			person: { __typename?: "Person"; id: string; name: string };
		}>;
	};
};

export type TuitionIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<TuitionFilterInput> | TuitionFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<TuitionOrder>;
}>;

export type TuitionIndexQuery = {
	__typename?: "Query";
	tuitionConnection: {
		__typename?: "TuitionConnection";
		edges: Array<{
			__typename?: "TuitionEdge";
			node: { __typename?: "Tuition"; id: string; name: string; initialYear: number };
		}>;
	};
};

export type TuitionNameFragment = { __typename?: "Tuition"; name: string };

export type TuitionNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type TuitionNameQuery = { __typename?: "Query"; tuition: { __typename?: "Tuition"; id: string; name: string } };

export type TuitionNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<TuitionFilterInput> | TuitionFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<TuitionOrder>;
}>;

export type TuitionNamesQuery = {
	__typename?: "Query";
	tuitionConnection: {
		__typename?: "TuitionConnection";
		edges: Array<{ __typename?: "TuitionEdge"; node: { __typename?: "Tuition"; id: string; name: string } }>;
	};
};

export type TuitionPaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<TuitionFilterInput> | TuitionFilterInput>;
	order?: InputMaybe<TuitionOrder>;
}>;

export type TuitionPaginationQuery = {
	__typename?: "Query";
	tuitionConnection: {
		__typename?: "TuitionConnection";
		totalCount: number;
		edges: Array<{ __typename?: "TuitionEdge"; cursor: string }>;
	};
};

export type TuitionSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<TuitionFilterInput> | TuitionFilterInput>;
	order?: InputMaybe<TuitionOrder>;
}>;

export type TuitionSelectQuery = {
	__typename?: "Query";
	tuitionConnection: {
		__typename?: "TuitionConnection";
		edges: Array<{ __typename?: "TuitionEdge"; node: { __typename?: "Tuition"; id: string; name: string } }>;
	};
};

export type TuitionUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: TuitionUpdateInput;
}>;

export type TuitionUpdateMutation = {
	__typename?: "Mutation";
	updateTuition: {
		__typename?: "Tuition";
		id: string;
		name: string;
		initialYear: number;
		academicYearTuitions: Array<{ __typename?: "AcademicYearTuition"; id: string; year: number }>;
		students: Array<{
			__typename?: "Student";
			id: string;
			person: { __typename?: "Person"; id: string; name: string };
		}>;
	};
};

export type UserCheckUniqueEmailQueryVariables = Exact<{
	ignoreID?: InputMaybe<Scalars["ID"]>;
	email: Scalars["String"];
}>;

export type UserCheckUniqueEmailQuery = {
	__typename?: "Query";
	userConnection: { __typename?: "UserConnection"; totalCount: number };
};

export type UserCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<UserFilterInput> | UserFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<UserOrder>;
}>;

export type UserCountQuery = {
	__typename?: "Query";
	userConnection: { __typename?: "UserConnection"; totalCount: number };
};

export type UserCreateMutationVariables = Exact<{
	input: UserCreateInput;
}>;

export type UserCreateMutation = {
	__typename?: "Mutation";
	createUser: {
		__typename?: "User";
		id: string;
		email: string;
		status: UserStatus;
		passwordUpdatedAt: string;
		loginRecords: Array<{ __typename?: "LoginRecord"; id: string }>;
		mfaFactor: { __typename?: "MFAFactor"; id: string; email: string } | null;
		mfaInvalidOTPAttempts: Array<{ __typename?: "MFAInvalidOTPAttempt"; id: string }>;
		permissionGroups: Array<{ __typename?: "PermissionGroup"; id: string; name: string }>;
		person: { __typename?: "Person"; id: string; name: string } | null;
		sessions: Array<{ __typename?: "Session"; id: string }>;
		trustedDevices: Array<{ __typename?: "TrustedDevice"; id: string; name: string }>;
		userGroups: Array<{ __typename?: "UserGroup"; id: string; name: string; imageURL: string }>;
	};
};

export type CurrentUserPersonQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentUserPersonQuery = {
	__typename?: "Query";
	viewer: {
		__typename?: "User";
		id: string;
		person: { __typename?: "Person"; id: string; name: string; imageURL: string } | null;
	};
};

export type UserDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type UserDeleteMutation = { __typename?: "Mutation"; deleteUser: boolean };

export type UserDetailFragment = {
	__typename?: "User";
	id: string;
	email: string;
	status: UserStatus;
	passwordUpdatedAt: string;
	loginRecords: Array<{ __typename?: "LoginRecord"; id: string }>;
	mfaFactor: { __typename?: "MFAFactor"; id: string; email: string } | null;
	mfaInvalidOTPAttempts: Array<{ __typename?: "MFAInvalidOTPAttempt"; id: string }>;
	permissionGroups: Array<{ __typename?: "PermissionGroup"; id: string; name: string }>;
	person: { __typename?: "Person"; id: string; name: string } | null;
	sessions: Array<{ __typename?: "Session"; id: string }>;
	trustedDevices: Array<{ __typename?: "TrustedDevice"; id: string; name: string }>;
	userGroups: Array<{ __typename?: "UserGroup"; id: string; name: string; imageURL: string }>;
};

export type UserDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type UserDetailQuery = {
	__typename?: "Query";
	user: {
		__typename?: "User";
		id: string;
		email: string;
		status: UserStatus;
		passwordUpdatedAt: string;
		loginRecords: Array<{ __typename?: "LoginRecord"; id: string }>;
		mfaFactor: { __typename?: "MFAFactor"; id: string; email: string } | null;
		mfaInvalidOTPAttempts: Array<{ __typename?: "MFAInvalidOTPAttempt"; id: string }>;
		permissionGroups: Array<{ __typename?: "PermissionGroup"; id: string; name: string }>;
		person: { __typename?: "Person"; id: string; name: string } | null;
		sessions: Array<{ __typename?: "Session"; id: string }>;
		trustedDevices: Array<{ __typename?: "TrustedDevice"; id: string; name: string }>;
		userGroups: Array<{ __typename?: "UserGroup"; id: string; name: string; imageURL: string }>;
	};
};

export type UserIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<UserFilterInput> | UserFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<UserOrder>;
}>;

export type UserIndexQuery = {
	__typename?: "Query";
	userConnection: {
		__typename?: "UserConnection";
		edges: Array<{
			__typename?: "UserEdge";
			node: { __typename?: "User"; id: string; email: string; status: UserStatus; passwordUpdatedAt: string };
		}>;
	};
};

export type UserNameFragment = { __typename?: "User"; email: string };

export type UserNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type UserNameQuery = { __typename?: "Query"; user: { __typename?: "User"; id: string; email: string } };

export type UserNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<UserFilterInput> | UserFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<UserOrder>;
}>;

export type UserNamesQuery = {
	__typename?: "Query";
	userConnection: {
		__typename?: "UserConnection";
		edges: Array<{ __typename?: "UserEdge"; node: { __typename?: "User"; id: string; email: string } }>;
	};
};

export type UserPaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<UserFilterInput> | UserFilterInput>;
	order?: InputMaybe<UserOrder>;
}>;

export type UserPaginationQuery = {
	__typename?: "Query";
	userConnection: {
		__typename?: "UserConnection";
		totalCount: number;
		edges: Array<{ __typename?: "UserEdge"; cursor: string }>;
	};
};

export type UserSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<UserFilterInput> | UserFilterInput>;
	order?: InputMaybe<UserOrder>;
}>;

export type UserSelectQuery = {
	__typename?: "Query";
	userConnection: {
		__typename?: "UserConnection";
		edges: Array<{ __typename?: "UserEdge"; node: { __typename?: "User"; id: string; email: string } }>;
	};
};

export type UserUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: UserUpdateInput;
}>;

export type UserUpdateMutation = {
	__typename?: "Mutation";
	updateUser: {
		__typename?: "User";
		id: string;
		email: string;
		status: UserStatus;
		passwordUpdatedAt: string;
		loginRecords: Array<{ __typename?: "LoginRecord"; id: string }>;
		mfaFactor: { __typename?: "MFAFactor"; id: string; email: string } | null;
		mfaInvalidOTPAttempts: Array<{ __typename?: "MFAInvalidOTPAttempt"; id: string }>;
		permissionGroups: Array<{ __typename?: "PermissionGroup"; id: string; name: string }>;
		person: { __typename?: "Person"; id: string; name: string } | null;
		sessions: Array<{ __typename?: "Session"; id: string }>;
		trustedDevices: Array<{ __typename?: "TrustedDevice"; id: string; name: string }>;
		userGroups: Array<{ __typename?: "UserGroup"; id: string; name: string; imageURL: string }>;
	};
};

export type UserGroupCheckUniqueNameQueryVariables = Exact<{
	ignoreID?: InputMaybe<Scalars["ID"]>;
	name: Scalars["String"];
}>;

export type UserGroupCheckUniqueNameQuery = {
	__typename?: "Query";
	userGroupConnection: { __typename?: "UserGroupConnection"; totalCount: number };
};

export type UserGroupCountQueryVariables = Exact<{
	filters?: InputMaybe<Array<UserGroupFilterInput> | UserGroupFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<UserGroupOrder>;
}>;

export type UserGroupCountQuery = {
	__typename?: "Query";
	userGroupConnection: { __typename?: "UserGroupConnection"; totalCount: number };
};

export type UserGroupCreateMutationVariables = Exact<{
	input: UserGroupCreateInput;
}>;

export type UserGroupCreateMutation = {
	__typename?: "Mutation";
	createUserGroup: {
		__typename?: "UserGroup";
		id: string;
		name: string;
		description: string;
		imageURL: string;
		color: string;
		users: Array<{
			__typename?: "User";
			id: string;
			email: string;
			person: { __typename?: "Person"; id: string; name: string } | null;
		}>;
	};
};

export type UserGroupDeleteMutationVariables = Exact<{
	id: Scalars["ID"];
}>;

export type UserGroupDeleteMutation = { __typename?: "Mutation"; deleteUserGroup: boolean };

export type UserGroupDetailFragment = {
	__typename?: "UserGroup";
	id: string;
	name: string;
	description: string;
	imageURL: string;
	color: string;
	users: Array<{
		__typename?: "User";
		id: string;
		email: string;
		person: { __typename?: "Person"; id: string; name: string } | null;
	}>;
};

export type UserGroupDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type UserGroupDetailQuery = {
	__typename?: "Query";
	userGroup: {
		__typename?: "UserGroup";
		id: string;
		name: string;
		description: string;
		imageURL: string;
		color: string;
		users: Array<{
			__typename?: "User";
			id: string;
			email: string;
			person: { __typename?: "Person"; id: string; name: string } | null;
		}>;
	};
};

export type UserGroupIndexQueryVariables = Exact<{
	filters?: InputMaybe<Array<UserGroupFilterInput> | UserGroupFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<UserGroupOrder>;
}>;

export type UserGroupIndexQuery = {
	__typename?: "Query";
	userGroupConnection: {
		__typename?: "UserGroupConnection";
		edges: Array<{
			__typename?: "UserGroupEdge";
			node: {
				__typename?: "UserGroup";
				id: string;
				name: string;
				description: string;
				imageURL: string;
				color: string;
			};
		}>;
	};
};

export type UserGroupNameFragment = { __typename?: "UserGroup"; name: string };

export type UserGroupNameQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type UserGroupNameQuery = {
	__typename?: "Query";
	userGroup: { __typename?: "UserGroup"; id: string; name: string };
};

export type UserGroupNamesQueryVariables = Exact<{
	filters?: InputMaybe<Array<UserGroupFilterInput> | UserGroupFilterInput>;
	pagination?: InputMaybe<Pagination>;
	order?: InputMaybe<UserGroupOrder>;
}>;

export type UserGroupNamesQuery = {
	__typename?: "Query";
	userGroupConnection: {
		__typename?: "UserGroupConnection";
		edges: Array<{ __typename?: "UserGroupEdge"; node: { __typename?: "UserGroup"; id: string; name: string } }>;
	};
};

export type UserGroupPaginationQueryVariables = Exact<{
	filters?: InputMaybe<Array<UserGroupFilterInput> | UserGroupFilterInput>;
	order?: InputMaybe<UserGroupOrder>;
}>;

export type UserGroupPaginationQuery = {
	__typename?: "Query";
	userGroupConnection: {
		__typename?: "UserGroupConnection";
		totalCount: number;
		edges: Array<{ __typename?: "UserGroupEdge"; cursor: string }>;
	};
};

export type UserGroupSelectQueryVariables = Exact<{
	filters?: InputMaybe<Array<UserGroupFilterInput> | UserGroupFilterInput>;
	order?: InputMaybe<UserGroupOrder>;
}>;

export type UserGroupSelectQuery = {
	__typename?: "Query";
	userGroupConnection: {
		__typename?: "UserGroupConnection";
		edges: Array<{ __typename?: "UserGroupEdge"; node: { __typename?: "UserGroup"; id: string; name: string } }>;
	};
};

export type UserGroupUpdateMutationVariables = Exact<{
	id: Scalars["ID"];
	input: UserGroupUpdateInput;
}>;

export type UserGroupUpdateMutation = {
	__typename?: "Mutation";
	updateUserGroup: {
		__typename?: "UserGroup";
		id: string;
		name: string;
		description: string;
		imageURL: string;
		color: string;
		users: Array<{
			__typename?: "User";
			id: string;
			email: string;
			person: { __typename?: "Person"; id: string; name: string } | null;
		}>;
	};
};

export type SendEnrollmentApplicationInviteEmailMutationVariables = Exact<{
	recipientAddresses: Array<Scalars["String"]> | Scalars["String"];
	subject: Scalars["String"];
	body: Scalars["String"];
}>;

export type SendEnrollmentApplicationInviteEmailMutation = {
	__typename?: "Mutation";
	sendEnrollmentApplicationInviteEmail: boolean;
};

export type GlobalSearchDetailQueryVariables = Exact<{
	id: Scalars["ID"];
}>;

export type GlobalSearchDetailQuery = {
	__typename?: "Query";
	searchResult: { __typename?: "SearchResult"; id: string; name: string; modelName: string; searchText: string };
};

export type GlobalSearchQueryVariables = Exact<{
	filters: Array<SearchResultFilterInput> | SearchResultFilterInput;
}>;

export type GlobalSearchQuery = {
	__typename?: "Query";
	searchResultConnection: {
		__typename?: "SearchResultConnection";
		edges: Array<{
			__typename?: "SearchResultEdge";
			node: { __typename?: "SearchResult"; id: string; name: string; modelName: string; searchText: string };
		}>;
	};
};

export type ModelIDsQueryVariables = Exact<{ [key: string]: never }>;

export type ModelIDsQuery = {
	__typename?: "Query";
	modelIDs: Array<{ __typename?: "ModelIDInformation"; modelName: string; minID: string; maxID: string }>;
};

export type AdmissionsApplicationDailyCountsReportQueryVariables = Exact<{
	filter: AdmissionsApplicationDailyCountsReportFilterInput;
}>;

export type AdmissionsApplicationDailyCountsReportQuery = {
	__typename?: "Query";
	admissionsApplicationDailyCountsReport: {
		__typename?: "AdmissionsApplicationDailyCountsReport";
		data: Array<{ __typename?: "AdmissionsApplicationDailyCountsReportPoint"; date: string; value: number }>;
	};
};

export type AdmissionsApplicationsByGradeReportQueryVariables = Exact<{ [key: string]: never }>;

export type AdmissionsApplicationsByGradeReportQuery = {
	__typename?: "Query";
	admissionsApplicationsByGradeReport: {
		__typename?: "AdmissionsApplicationsByGradeReport";
		data: Array<{ __typename?: "AdmissionsApplicationsByGradeReportPoint"; grade: string; value: number }>;
	};
};

export type AdmissionsEnrollmentsByGradeReportQueryVariables = Exact<{ [key: string]: never }>;

export type AdmissionsEnrollmentsByGradeReportQuery = {
	__typename?: "Query";
	admissionsEnrollmentsByGradeReport: {
		__typename?: "AdmissionsEnrollmentsByGradeReport";
		data: Array<{
			__typename?: "AdmissionsEnrollmentsByGradeReportPoint";
			grade: string;
			value: number;
			reenrollmentValue: number;
			newEnrollmentValue: number;
		}>;
	};
};

export type AdmissionsReenrollmentsByGradeReportQueryVariables = Exact<{ [key: string]: never }>;

export type AdmissionsReenrollmentsByGradeReportQuery = {
	__typename?: "Query";
	admissionsReenrollmentsByGradeReport: {
		__typename?: "AdmissionsReenrollmentsByGradeReport";
		data: Array<{
			__typename?: "AdmissionsReenrollmentsByGradeReportPoint";
			grade: string;
			enrolledValue: number;
			reenrolledValue: number;
		}>;
	};
};

export type StudentTimelineQueryVariables = Exact<{
	studentID: Scalars["ID"];
	filter?: InputMaybe<TimelineFilterInput>;
}>;

export type StudentTimelineQuery = {
	__typename?: "Query";
	studentTimeline: Array<{
		__typename?: "TimelineItem";
		eventDate: string;
		eventTime: string | null;
		actorID: string;
		actorName: string;
		actorImageURL: string;
		objectType: TimelineObjectType;
		objectID: string;
		objectName: string;
		objectDetails: Record<string, any> | null;
	}>;
};

export type TimelineQueryVariables = Exact<{
	filter?: InputMaybe<TimelineFilterInput>;
}>;

export type TimelineQuery = {
	__typename?: "Query";
	timeline: Array<{
		__typename?: "TimelineItem";
		eventDate: string;
		eventTime: string | null;
		actorID: string;
		actorName: string;
		actorImageURL: string;
		objectType: TimelineObjectType;
		objectID: string;
		objectName: string;
		objectDetails: Record<string, any> | null;
	}>;
};

export type ViewerWithPermissionsQueryVariables = Exact<{ [key: string]: never }>;

export type ViewerWithPermissionsQuery = {
	__typename?: "Query";
	viewer: {
		__typename?: "User";
		id: string;
		email: string;
		status: UserStatus;
		permissionGroups: Array<{
			__typename?: "PermissionGroup";
			permissions: Array<{ __typename?: "Permission"; name: string }>;
		}>;
	};
};

export const BillingSetupSubmissionNameFragmentDoc = gql`
	fragment BillingSetupSubmissionName on BillingSetupSubmission {
		id
	}
`;
export const SchoolStudentEnrollmentNameFragmentDoc = gql`
	fragment SchoolStudentEnrollmentName on SchoolStudentEnrollment {
		id
	}
`;
export const TermNameFragmentDoc = gql`
	fragment TermName on Term {
		name
	}
`;
export const AcademicYearDetailFragmentDoc = gql`
	fragment AcademicYearDetail on AcademicYear {
		id
		name
		billingSetupSubmissions {
			id
			...BillingSetupSubmissionName
		}
		schoolStudentEnrollments {
			id
			...SchoolStudentEnrollmentName
		}
		terms {
			id
			...TermName
		}
	}
	${BillingSetupSubmissionNameFragmentDoc}
	${SchoolStudentEnrollmentNameFragmentDoc}
	${TermNameFragmentDoc}
`;
export const AcademicYearTuitionDetailFieldsFragmentDoc = gql`
	fragment academicYearTuitionDetailFields on AcademicYearTuition {
		id
		year
		nurseryPrice
		receptionPrice
		primaryPrice
		secondaryPrice
		tuition {
			id
			name
		}
	}
`;
export const AddressDetailFragmentDoc = gql`
	fragment AddressDetail on Address {
		id
		shortAddress
		fullAddress
		addressLine1
		addressLine2
		city
		postalCode
		stateOrProvince
		country
		people {
			id
			name
		}
	}
`;
export const AddressNameFragmentDoc = gql`
	fragment AddressName on Address {
		shortAddress
	}
`;
export const AcademicYearNameFragmentDoc = gql`
	fragment AcademicYearName on AcademicYear {
		name
	}
`;
export const PersonNameFragmentDoc = gql`
	fragment PersonName on Person {
		name
	}
`;
export const StudentNameFragmentDoc = gql`
	fragment StudentName on Student {
		person {
			id
			...PersonName
		}
	}
	${PersonNameFragmentDoc}
`;
export const BillingSetupSubmissionDetailFragmentDoc = gql`
	fragment BillingSetupSubmissionDetail on BillingSetupSubmission {
		id
		numPayments
		isInterestedInBusPlan
		isInterestedInMealPlan
		authorName
		authorEmail
		authorPhone
		academicYear {
			id
			...AcademicYearName
		}
		student {
			id
			...StudentName
		}
	}
	${AcademicYearNameFragmentDoc}
	${StudentNameFragmentDoc}
`;
export const BusDetailFragmentDoc = gql`
	fragment BusDetail on Bus {
		id
		name
		description
		dropoffBusPlans {
			id
			student {
				id
				person {
					id
					name
				}
			}
		}
		pickupBusPlans {
			id
			student {
				id
				person {
					id
					name
				}
			}
		}
	}
`;
export const BusNameFragmentDoc = gql`
	fragment BusName on Bus {
		name
	}
`;
export const BusPlanDetailFragmentDoc = gql`
	fragment BusPlanDetail on BusPlan {
		id
		hasPickup
		pickupTimeMinutes
		pickupLocation
		hasDropoff
		dropoffTimeMinutes
		dropoffLocation
		dropoffBus {
			id
			name
		}
		pickupBus {
			id
			name
		}
		student {
			id
			person {
				id
				name
			}
		}
	}
`;
export const BusPlanNameFragmentDoc = gql`
	fragment BusPlanName on BusPlan {
		student {
			id
			...StudentName
		}
	}
	${StudentNameFragmentDoc}
`;
export const CalendarEventDetailFragmentDoc = gql`
	fragment CalendarEventDetail on CalendarEvent {
		id
		name
		description
		isAllDay
		startTime
		endTime
		isPublic
		createdAt
		author {
			id
			email
			person {
				id
				name
			}
		}
		sharingUserGroups {
			id
			name
		}
	}
`;
export const CalendarEventNameFragmentDoc = gql`
	fragment CalendarEventName on CalendarEvent {
		name
	}
`;
export const CampusDetailFragmentDoc = gql`
	fragment CampusDetail on Campus {
		id
		name
		addressLine1
		addressLine2
		postalCode
		city
		stateOrProvince
		country
		rooms {
			id
			name
		}
		employees {
			id
			person {
				id
				name
				imageURL
			}
		}
	}
`;
export const HomeRoomNameFragmentDoc = gql`
	fragment HomeRoomName on HomeRoom {
		name
	}
`;
export const CourseNameFragmentDoc = gql`
	fragment CourseName on Course {
		name
		subject
		homeRoom {
			id
			...HomeRoomName
		}
	}
	${HomeRoomNameFragmentDoc}
`;
export const ClassYearDetailFragmentDoc = gql`
	fragment ClassYearDetail on ClassYear {
		id
		name
		yearsFromGraduation
		courses {
			id
			...CourseName
		}
		homeRooms {
			id
			...HomeRoomName
		}
	}
	${CourseNameFragmentDoc}
	${HomeRoomNameFragmentDoc}
`;
export const ContractDetailFragmentDoc = gql`
	fragment ContractDetail on Contract {
		id
		agreementDate
		status
		contractInstallments {
			id
		}
		serviceSubscriptions {
			id
		}
		student {
			id
			person {
				id
				name
				imageURL
			}
		}
	}
`;
export const ContractNameFragmentDoc = gql`
	fragment ContractName on Contract {
		agreementDate
		status
	}
`;
export const ContractInstallmentDetailFragmentDoc = gql`
	fragment ContractInstallmentDetail on ContractInstallment {
		id
		installmentDate
		portion
		contract {
			id
			agreementDate
			status
		}
		invoice {
			id
			invoiceNumber
			dueDate
		}
	}
`;
export const ContractInstallmentNameFragmentDoc = gql`
	fragment ContractInstallmentName on ContractInstallment {
		id
	}
`;
export const ClassYearNameFragmentDoc = gql`
	fragment ClassYearName on ClassYear {
		name
	}
`;
export const HomeRoomSectionNameFragmentDoc = gql`
	fragment HomeRoomSectionName on HomeRoomSection {
		name
	}
`;
export const CourseSectionNameFragmentDoc = gql`
	fragment CourseSectionName on CourseSection {
		name
		course {
			id
			subject
		}
		homeRoomSection {
			id
			...HomeRoomSectionName
		}
	}
	${HomeRoomSectionNameFragmentDoc}
`;
export const LearningObjectiveNameFragmentDoc = gql`
	fragment LearningObjectiveName on LearningObjective {
		category
		description
	}
`;
export const CourseDetailFragmentDoc = gql`
	fragment CourseDetail on Course {
		id
		name
		description
		subject
		studentCapacity
		classYears {
			id
			...ClassYearName
		}
		courseSections {
			id
			...CourseSectionName
		}
		homeRoom {
			id
			...HomeRoomName
		}
		learningObjectives {
			id
			...LearningObjectiveName
		}
	}
	${ClassYearNameFragmentDoc}
	${CourseSectionNameFragmentDoc}
	${HomeRoomNameFragmentDoc}
	${LearningObjectiveNameFragmentDoc}
`;
export const CourseAttendanceRecordDetailFragmentDoc = gql`
	fragment CourseAttendanceRecordDetail on CourseAttendanceRecord {
		id
		attendanceDate
		isPresent
		isLate
		isExcused
		notes
		courseSectionPeriod {
			id
			dayOfWeek
			startTimeMinutes
			endTimeMinutes
		}
		student {
			id
			person {
				id
				name
			}
		}
	}
`;
export const CourseAttendanceRecordNameFragmentDoc = gql`
	fragment CourseAttendanceRecordName on CourseAttendanceRecord {
		id
	}
`;
export const CourseSectionDetailFragmentDoc = gql`
	fragment CourseSectionDetail on CourseSection {
		id
		name
		description
		course {
			id
			name
			subject
			homeRoom {
				id
				name
			}
		}
		courseSectionPeriods {
			id
			dayOfWeek
			startTimeMinutes
			endTimeMinutes
		}
		homeRoomSection {
			id
			name
		}
		courseSectionStudentEnrollments {
			id
			student {
				id
				person {
					id
					name
					imageURL
				}
			}
		}
		courseSectionTeacherEnrollments {
			id
			teacher {
				id
				person {
					id
					name
					imageURL
				}
			}
		}
		term {
			id
			name
		}
	}
`;
export const RoomNameFragmentDoc = gql`
	fragment RoomName on Room {
		name
	}
`;
export const CourseSectionPeriodDetailFragmentDoc = gql`
	fragment CourseSectionPeriodDetail on CourseSectionPeriod {
		id
		dayOfWeek
		startTimeMinutes
		endTimeMinutes
		courseSection {
			id
			...CourseSectionName
		}
		room {
			id
			...RoomName
		}
	}
	${CourseSectionNameFragmentDoc}
	${RoomNameFragmentDoc}
`;
export const CourseSectionPeriodNameFragmentDoc = gql`
	fragment CourseSectionPeriodName on CourseSectionPeriod {
		dayOfWeek
		startTimeMinutes
		endTimeMinutes
	}
`;
export const DiscountDetailFragmentDoc = gql`
	fragment DiscountDetail on Discount {
		id
		amount
		description
		serviceSubscription {
			id
		}
	}
`;
export const DiscountNameFragmentDoc = gql`
	fragment DiscountName on Discount {
		id
	}
`;
export const DocumentResourceDetailFragmentDoc = gql`
	fragment DocumentResourceDetail on DocumentResource {
		id
		name
		description
		hasImage
		imageURL
		isFile
		fileURL
		fileContentType
		resourceURL
		createdAt
		author {
			id
			email
		}
		bookmarkingUsers {
			id
			email
		}
		documentResourceFolders {
			id
			name
		}
	}
`;
export const DocumentResourceNameFragmentDoc = gql`
	fragment DocumentResourceName on DocumentResource {
		name
	}
`;
export const DocumentResourceFolderDetailFragmentDoc = gql`
	fragment DocumentResourceFolderDetail on DocumentResourceFolder {
		id
		name
		description
		category
		isPublic
		createdAt
		author {
			id
			email
		}
		childDocumentResourceFolders {
			id
			name
		}
		documentResources {
			id
			name
			description
			imageURL
			isFile
			fileURL
			fileContentType
			resourceURL
			createdAt
		}
		parentDocumentResourceFolder {
			id
			name
		}
		homeRooms {
			id
			...HomeRoomName
		}
		homeRoomSections {
			id
			...HomeRoomSectionName
		}
		courses {
			id
			...CourseName
		}
		courseSections {
			id
			...CourseSectionName
		}
		sharingUserGroups {
			id
			name
		}
		sharingUsers {
			id
			email
		}
	}
	${HomeRoomNameFragmentDoc}
	${HomeRoomSectionNameFragmentDoc}
	${CourseNameFragmentDoc}
	${CourseSectionNameFragmentDoc}
`;
export const DocumentResourceFolderNameFragmentDoc = gql`
	fragment DocumentResourceFolderName on DocumentResourceFolder {
		name
	}
`;
export const DocumentResourceUserViewRecordDetailFragmentDoc = gql`
	fragment DocumentResourceUserViewRecordDetail on DocumentResourceUserViewRecord {
		id
		viewedAt
		documentResource {
			id
			name
		}
		user {
			id
			email
		}
	}
`;
export const DocumentResourceUserViewRecordNameFragmentDoc = gql`
	fragment DocumentResourceUserViewRecordName on DocumentResourceUserViewRecord {
		id
	}
`;
export const EmailAddressDetailFragmentDoc = gql`
	fragment EmailAddressDetail on EmailAddress {
		id
		email
		person {
			id
			name
		}
	}
`;
export const EmailAddressNameFragmentDoc = gql`
	fragment EmailAddressName on EmailAddress {
		email
	}
`;
export const PersonDetailFragmentDoc = gql`
	fragment PersonDetail on Person {
		id
		name
		firstName
		lastName
		nickname
		alternateNames
		imageURL
		gender
		dateOfBirth
		occupation
		nationality
		primaryLanguage
		englishLanguageFluency
		addresses {
			id
			shortAddress
		}
		emailAddresses {
			id
			email
		}
		employee {
			id
			person {
				id
				name
			}
		}
		parent {
			id
			person {
				id
				name
			}
		}
		phoneNumbers {
			id
			phoneNumber
			phoneType
		}
		student {
			id
			person {
				id
				name
			}
		}
		user {
			id
			email
		}
	}
`;
export const EmployeeDetailFragmentDoc = gql`
	fragment EmployeeDetail on Employee {
		id
		email
		jobTitle
		role
		startDate
		endDate
		person {
			...PersonDetail
		}
		campuses {
			id
			name
		}
	}
	${PersonDetailFragmentDoc}
`;
export const EnrollmentApplicationNoteDetailFragmentDoc = gql`
	fragment EnrollmentApplicationNoteDetail on EnrollmentApplicationNote {
		id
		createdAt
		body
		author {
			id
			email
			person {
				id
				name
				imageURL
				employee {
					id
				}
			}
		}
		enrollmentApplication {
			id
		}
	}
`;
export const EnrollmentApplicationDetailFragmentDoc = gql`
	fragment EnrollmentApplicationDetail on EnrollmentApplication {
		id
		createdAt
		status
		statusUpdatedAt
		approvedEmailSentAt
		interviewMeetingTime
		externalLink
		enrollmentApplicationStudent {
			id
			formIdentifier
			imageURL
			name
			firstName
			lastName
			nickname
			dateOfBirth
			gender
			genderCustom
			nationality
			primaryLanguage
			englishLanguageFluency
			currentGradeLevel
			gradeLevelApplyingFor
			hasPreviousSchooling
			previousSchoolInformation
			previousSchoolLocation
			hasLearningDifficulties
			learningDifficultiesDescription
			hasMedicalPhysicalExceptionalities
			medicalPhysicalExceptionalitiesDescription
			hasBehaviorDisciplineChallenges
			behaviorDisciplineChallengesDescription
			additionalInformation
			enrollmentApplication {
				id
			}
			enrollmentApplicationSubmission {
				id
				doesConsentToDataUse
				signatureName
				signatureDate
				enrollmentApplicationStudents {
					id
					name
					imageURL
					enrollmentApplication {
						id
					}
				}
				enrollmentApplicationParents {
					id
					formIdentifier
					name
					firstName
					lastName
					nationality
					occupation
					relationshipType
					relationshipTypeCustom
					isPrimaryContact
					livesWithStudent
					isAuthorizedForPickup
					isEmergencyContact
					isFinancialContact
					addressSameAsFormIdentifier
					addressLine1
					addressLine2
					city
					stateOrProvince
					postalCode
					country
					homePhone
					workPhone
					mobilePhone
					email
					enrollmentApplicationSubmission {
						id
					}
				}
			}
			student {
				id
				schoolStudentEnrollments {
					id
					createdDate
					hasPaidFee
					academicYear {
						id
						name
					}
				}
			}
		}
		enrollmentApplicationNotes(order: { field: CREATED_AT, direction: ASC }) {
			...EnrollmentApplicationNoteDetail
		}
		enrollmentApplicationUserViewRecords(filters: { isDetailEQ: true }) {
			id
			user {
				id
				person {
					id
					name
					imageURL
				}
			}
		}
	}
	${EnrollmentApplicationNoteDetailFragmentDoc}
`;
export const EnrollmentApplicationNameFragmentDoc = gql`
	fragment EnrollmentApplicationName on EnrollmentApplication {
		id
	}
`;
export const EnrollmentApplicationNoteNameFragmentDoc = gql`
	fragment EnrollmentApplicationNoteName on EnrollmentApplicationNote {
		id
	}
`;
export const EnrollmentApplicationParentNameFragmentDoc = gql`
	fragment EnrollmentApplicationParentName on EnrollmentApplicationParent {
		name
	}
`;
export const EnrollmentApplicationStudentNameFragmentDoc = gql`
	fragment EnrollmentApplicationStudentName on EnrollmentApplicationStudent {
		name
	}
`;
export const EnrollmentApplicationStudentDetailFragmentDoc = gql`
	fragment EnrollmentApplicationStudentDetail on EnrollmentApplicationStudent {
		id
		formIdentifier
		imageURL
		name
		firstName
		lastName
		nickname
		dateOfBirth
		gender
		genderCustom
		nationality
		primaryLanguage
		englishLanguageFluency
		currentGradeLevel
		gradeLevelApplyingFor
		hasPreviousSchooling
		previousSchoolInformation
		previousSchoolLocation
		hasLearningDifficulties
		learningDifficultiesDescription
		hasMedicalPhysicalExceptionalities
		medicalPhysicalExceptionalitiesDescription
		hasBehaviorDisciplineChallenges
		behaviorDisciplineChallengesDescription
		additionalInformation
		enrollmentApplication {
			id
		}
		enrollmentApplicationSubmission {
			id
		}
	}
`;
export const EnrollmentApplicationParentDetailFragmentDoc = gql`
	fragment EnrollmentApplicationParentDetail on EnrollmentApplicationParent {
		id
		formIdentifier
		name
		firstName
		lastName
		nationality
		occupation
		relationshipType
		relationshipTypeCustom
		isPrimaryContact
		livesWithStudent
		isAuthorizedForPickup
		isEmergencyContact
		isFinancialContact
		addressSameAsFormIdentifier
		addressLine1
		addressLine2
		city
		stateOrProvince
		postalCode
		country
		homePhone
		workPhone
		mobilePhone
		email
		enrollmentApplicationSubmission {
			id
		}
	}
`;
export const EnrollmentApplicationSubmissionDetailFragmentDoc = gql`
	fragment EnrollmentApplicationSubmissionDetail on EnrollmentApplicationSubmission {
		id
		createdAt
		enrollmentApplicationStudents {
			...EnrollmentApplicationStudentDetail
		}
		enrollmentApplicationParents {
			...EnrollmentApplicationParentDetail
		}
		doesConsentToDataUse
		signatureName
		signatureDate
		source
	}
	${EnrollmentApplicationStudentDetailFragmentDoc}
	${EnrollmentApplicationParentDetailFragmentDoc}
`;
export const EnrollmentApplicationSubmissionNameFragmentDoc = gql`
	fragment EnrollmentApplicationSubmissionName on EnrollmentApplicationSubmission {
		id
	}
`;
export const EnrollmentApplicationUserViewRecordDetailFragmentDoc = gql`
	fragment EnrollmentApplicationUserViewRecordDetail on EnrollmentApplicationUserViewRecord {
		id
		createdAt
		isDetail
		enrollmentApplication {
			id
		}
		user {
			id
			email
		}
	}
`;
export const EnrollmentApplicationUserViewRecordNameFragmentDoc = gql`
	fragment EnrollmentApplicationUserViewRecordName on EnrollmentApplicationUserViewRecord {
		id
	}
`;
export const EnrollmentInvitationDetailFieldsFragmentDoc = gql`
	fragment enrollmentInvitationDetailFields on EnrollmentInvitation {
		id
		createdAt
		response
		student {
			id
			...StudentName
		}
	}
	${StudentNameFragmentDoc}
`;
export const EnrollmentInvitationNameFragmentDoc = gql`
	fragment EnrollmentInvitationName on EnrollmentInvitation {
		id
	}
`;
export const ExpenseDetailFragmentDoc = gql`
	fragment ExpenseDetail on Expense {
		id
		createdDate
		amount
		notes
		expenseCategory {
			id
			name
		}
	}
`;
export const ExpenseNameFragmentDoc = gql`
	fragment ExpenseName on Expense {
		id
	}
`;
export const ExpenseBudgetDetailFragmentDoc = gql`
	fragment ExpenseBudgetDetail on ExpenseBudget {
		id
		budgetMonth
		amount
		expenseCategory {
			id
			name
		}
	}
`;
export const ExpenseBudgetNameFragmentDoc = gql`
	fragment ExpenseBudgetName on ExpenseBudget {
		id
	}
`;
export const ExpenseCategoryDetailFragmentDoc = gql`
	fragment ExpenseCategoryDetail on ExpenseCategory {
		id
		name
		description
		expenseBudgets {
			id
		}
		expenses {
			id
		}
	}
`;
export const ExpenseCategoryNameFragmentDoc = gql`
	fragment ExpenseCategoryName on ExpenseCategory {
		name
	}
`;
export const HomeRoomDetailFragmentDoc = gql`
	fragment HomeRoomDetail on HomeRoom {
		id
		name
		description
		studentCapacity
		classYear {
			id
			name
			yearsFromGraduation
		}
		courses {
			id
			...CourseName
		}
		homeRoomSections {
			id
			...HomeRoomSectionName
		}
	}
	${CourseNameFragmentDoc}
	${HomeRoomSectionNameFragmentDoc}
`;
export const CampusNameFragmentDoc = gql`
	fragment CampusName on Campus {
		name
	}
`;
export const EmployeeNameFragmentDoc = gql`
	fragment EmployeeName on Employee {
		person {
			id
			...PersonName
		}
	}
	${PersonNameFragmentDoc}
`;
export const HomeRoomSectionDetailFragmentDoc = gql`
	fragment HomeRoomSectionDetail on HomeRoomSection {
		id
		name
		description
		courseSections {
			id
			...CourseSectionName
		}
		homeRoom {
			id
			...HomeRoomName
		}
		room {
			id
			...RoomName
			campus {
				id
				...CampusName
			}
		}
		homeRoomSectionStudentEnrollments {
			id
			student {
				id
				...StudentName
			}
		}
		homeRoomSectionTeacherEnrollments {
			id
			teacher {
				id
				...EmployeeName
			}
		}
		term {
			id
			...TermName
			startDate
			endDate
		}
	}
	${CourseSectionNameFragmentDoc}
	${HomeRoomNameFragmentDoc}
	${RoomNameFragmentDoc}
	${CampusNameFragmentDoc}
	${StudentNameFragmentDoc}
	${EmployeeNameFragmentDoc}
	${TermNameFragmentDoc}
`;
export const InvoiceDetailFragmentDoc = gql`
	fragment InvoiceDetail on Invoice {
		id
		invoiceNumber
		createdDate
		dueDate
		description
		invoiceLineItems {
			id
			lineItemNumber
			amount
			description
		}
		paymentLineItems {
			id
			amount
			payment {
				id
				createdDate
				paymentMethod
			}
		}
		contractInstallment {
			id
			installmentDate
		}
	}
`;
export const InvoiceNameFragmentDoc = gql`
	fragment InvoiceName on Invoice {
		invoiceNumber
	}
`;
export const InvoiceLineItemDetailFragmentDoc = gql`
	fragment InvoiceLineItemDetail on InvoiceLineItem {
		id
		lineItemNumber
		amount
		description
		invoice {
			id
			invoiceNumber
			dueDate
			invoiceLineItems {
				id
				amount
			}
		}
		serviceSubscription {
			id
			service {
				id
				name
			}
		}
	}
`;
export const InvoiceLineItemNameFragmentDoc = gql`
	fragment InvoiceLineItemName on InvoiceLineItem {
		id
	}
`;
export const LearningObjectiveDetailFragmentDoc = gql`
	fragment LearningObjectiveDetail on LearningObjective {
		id
		category
		description
		isActive
		course {
			id
			...CourseName
		}
		learningObjectiveMarks {
			id
		}
	}
	${CourseNameFragmentDoc}
`;
export const LearningObjectiveMarkDetailFragmentDoc = gql`
	fragment LearningObjectiveMarkDetail on LearningObjectiveMark {
		id
		value
		learningObjective {
			id
			category
			description
		}
		reportCard {
			id
		}
	}
`;
export const LearningObjectiveMarkNameFragmentDoc = gql`
	fragment LearningObjectiveMarkName on LearningObjectiveMark {
		id
	}
`;
export const LessonPlanDetailFragmentDoc = gql`
	fragment LessonPlanDetail on LessonPlan {
		id
		courseDate
		lessonDescription
		requiredResourcesAndMaterials
		stationOneDescription
		stationTwoDescription
		stationThreeDescription
		differentiationDescription
		assessmentDescription
		notes
		courseSection {
			id
			...CourseSectionName
		}
		learningObjectives {
			id
			...LearningObjectiveName
		}
	}
	${CourseSectionNameFragmentDoc}
	${LearningObjectiveNameFragmentDoc}
`;
export const LessonPlanNameFragmentDoc = gql`
	fragment LessonPlanName on LessonPlan {
		id
	}
`;
export const LoginRecordDetailFragmentDoc = gql`
	fragment LoginRecordDetail on LoginRecord {
		id
		createdAt
		userEmail
		wasBlocked
		wasSuccessful
		isTrusted
		session {
			id
		}
		user {
			id
			email
		}
	}
`;
export const LoginRecordNameFragmentDoc = gql`
	fragment LoginRecordName on LoginRecord {
		id
	}
`;
export const MFAFactorDetailFragmentDoc = gql`
	fragment MFAFactorDetail on MFAFactor {
		id
		createdAt
		count
		isApp
		email
		sms
		isVerified
		user {
			id
			email
		}
	}
`;
export const MFAFactorNameFragmentDoc = gql`
	fragment MFAFactorName on MFAFactor {
		email
	}
`;
export const MFAInvalidOTPAttemptDetailFragmentDoc = gql`
	fragment MfaInvalidOTPAttemptDetail on MFAInvalidOTPAttempt {
		id
		createdAt
		session {
			id
		}
		user {
			id
			email
		}
	}
`;
export const MFAInvalidOTPAttemptNameFragmentDoc = gql`
	fragment MFAInvalidOTPAttemptName on MFAInvalidOTPAttempt {
		id
	}
`;
export const MealMenuDetailFragmentDoc = gql`
	fragment MealMenuDetail on MealMenu {
		id
		menuDate
		breakfast
		lunch
		snack
		vegetarianBreakfast
		vegetarianLunch
		vegetarianSnack
	}
`;
export const MealMenuNameFragmentDoc = gql`
	fragment MealMenuName on MealMenu {
		id
	}
`;
export const MealPlanDetailFragmentDoc = gql`
	fragment MealPlanDetail on MealPlan {
		id
		notes
		student {
			id
			person {
				id
				name
			}
		}
	}
`;
export const MealPlanNameFragmentDoc = gql`
	fragment MealPlanName on MealPlan {
		student {
			id
			...StudentName
		}
	}
	${StudentNameFragmentDoc}
`;
export const NoteDetailFragmentDoc = gql`
	fragment NoteDetail on Note {
		id
		noteType
		title
		body
		details
		createdAt
		isTimeDependent
		startDate
		endDate
		isPublic
		attachments {
			id
			order
			isFile
			url
		}
		author {
			id
			email
			person {
				id
				name
				imageURL
				employee {
					id
				}
			}
		}
		calendarEvents {
			id
			name
		}
		comments {
			id
		}
		courseSections {
			id
			name
		}
		employees {
			id
			person {
				id
				name
				imageURL
			}
		}
		homeRoomSections {
			id
			name
		}
		parents {
			id
			person {
				id
				name
				imageURL
			}
		}
		reactions {
			id
		}
		sharingUsers {
			id
			email
			person {
				id
				name
				imageURL
			}
		}
		students {
			id
			person {
				id
				name
				imageURL
			}
		}
	}
`;
export const NoteNameFragmentDoc = gql`
	fragment NoteName on Note {
		title
	}
`;
export const NoteAttachmentDetailFragmentDoc = gql`
	fragment NoteAttachmentDetail on NoteAttachment {
		id
		order
		isFile
		fileURL
		linkURL
		url
		note {
			id
			title
		}
	}
`;
export const NoteAttachmentNameFragmentDoc = gql`
	fragment NoteAttachmentName on NoteAttachment {
		id
	}
`;
export const NoteCommentDetailFragmentDoc = gql`
	fragment NoteCommentDetail on NoteComment {
		id
		body
		createdAt
		author {
			id
			email
		}
		note {
			id
			title
		}
	}
`;
export const NoteCommentNameFragmentDoc = gql`
	fragment NoteCommentName on NoteComment {
		id
	}
`;
export const NoteReactionDetailFragmentDoc = gql`
	fragment NoteReactionDetail on NoteReaction {
		id
		reactionType
		createdAt
		author {
			id
			email
		}
		note {
			id
			title
		}
	}
`;
export const NoteReactionNameFragmentDoc = gql`
	fragment NoteReactionName on NoteReaction {
		id
	}
`;
export const ParentDetailFragmentDoc = gql`
	fragment ParentDetail on Parent {
		id
		payments {
			id
		}
		person {
			...PersonDetail
		}
		relationships {
			id
		}
	}
	${PersonDetailFragmentDoc}
`;
export const ParentNameFragmentDoc = gql`
	fragment ParentName on Parent {
		person {
			id
			...PersonName
		}
	}
	${PersonNameFragmentDoc}
`;
export const PaymentDetailFragmentDoc = gql`
	fragment PaymentDetail on Payment {
		id
		createdDate
		paymentMethod
		notes
		parent {
			id
			person {
				id
				name
			}
		}
		paymentLineItems {
			id
			amount
			invoice {
				id
				invoiceNumber
			}
		}
	}
`;
export const PaymentNameFragmentDoc = gql`
	fragment PaymentName on Payment {
		id
	}
`;
export const PaymentLineItemDetailFragmentDoc = gql`
	fragment PaymentLineItemDetail on PaymentLineItem {
		id
		amount
		invoice {
			id
			invoiceNumber
			dueDate
			invoiceLineItems {
				id
				amount
			}
		}
		payment {
			id
		}
	}
`;
export const PaymentLineItemNameFragmentDoc = gql`
	fragment PaymentLineItemName on PaymentLineItem {
		id
	}
`;
export const PermissionDetailFragmentDoc = gql`
	fragment PermissionDetail on Permission {
		id
		name
		family
		action
		level
		description
		permissionGroups {
			id
			name
		}
	}
`;
export const PermissionNameFragmentDoc = gql`
	fragment PermissionName on Permission {
		name
	}
`;
export const PermissionGroupDetailFragmentDoc = gql`
	fragment PermissionGroupDetail on PermissionGroup {
		id
		name
		description
		permissions {
			id
			name
		}
		users {
			id
			email
		}
	}
`;
export const PermissionGroupNameFragmentDoc = gql`
	fragment PermissionGroupName on PermissionGroup {
		name
	}
`;
export const PhoneNumberDetailFragmentDoc = gql`
	fragment PhoneNumberDetail on PhoneNumber {
		id
		phoneNumber
		phoneType
		person {
			id
			name
		}
	}
`;
export const PhoneNumberNameFragmentDoc = gql`
	fragment PhoneNumberName on PhoneNumber {
		phoneNumber
		phoneType
	}
`;
export const QuotationDetailFragmentDoc = gql`
	fragment QuotationDetail on Quotation {
		id
		body
		attribution
	}
`;
export const QuotationNameFragmentDoc = gql`
	fragment QuotationName on Quotation {
		id
	}
`;
export const RelationshipDetailFragmentDoc = gql`
	fragment RelationshipDetail on Relationship {
		id
		relationshipType
		isAuthorizedForPickup
		isEmergencyContact
		isFinancialContact
		isPrimary
		livesWithStudent
		parent {
			id
			person {
				id
				name
			}
		}
		student {
			id
			person {
				id
				name
			}
		}
	}
`;
export const RelationshipNameFragmentDoc = gql`
	fragment RelationshipName on Relationship {
		id
	}
`;
export const ReportCardDetailFragmentDoc = gql`
	fragment ReportCardDetail on ReportCard {
		id
		issueDate
		learningObjectiveMarks {
			id
		}
		student {
			id
			person {
				id
				name
			}
		}
		term {
			id
			name
		}
	}
`;
export const ReportCardNameFragmentDoc = gql`
	fragment ReportCardName on ReportCard {
		id
	}
`;
export const RoomDetailFragmentDoc = gql`
	fragment RoomDetail on Room {
		id
		name
		number
		campus {
			id
			name
		}
		courseSectionPeriods {
			id
			dayOfWeek
			startTimeMinutes
			endTimeMinutes
		}
	}
`;
export const SchoolAttendanceRecordDetailFragmentDoc = gql`
	fragment SchoolAttendanceRecordDetail on SchoolAttendanceRecord {
		id
		checkInTime
		checkOutTime
		isPresent
		isLate
		isExcused
		notes
		student {
			id
			person {
				id
				name
			}
		}
	}
`;
export const SchoolAttendanceRecordNameFragmentDoc = gql`
	fragment SchoolAttendanceRecordName on SchoolAttendanceRecord {
		id
	}
`;
export const SchoolStudentEnrollmentDetailFragmentDoc = gql`
	fragment SchoolStudentEnrollmentDetail on SchoolStudentEnrollment {
		id
		createdDate
		paymentDueDate
		hasPaidFee
		isVoided
		academicYear {
			id
			...AcademicYearName
		}
		classYear {
			id
			...ClassYearName
		}
		student {
			id
			...StudentName
		}
	}
	${AcademicYearNameFragmentDoc}
	${ClassYearNameFragmentDoc}
	${StudentNameFragmentDoc}
`;
export const ServiceDetailFragmentDoc = gql`
	fragment ServiceDetail on Service {
		id
		name
		description
		price
		serviceSubscriptions {
			id
		}
	}
`;
export const ServiceNameFragmentDoc = gql`
	fragment ServiceName on Service {
		name
	}
`;
export const ServiceSubscriptionDetailFragmentDoc = gql`
	fragment ServiceSubscriptionDetail on ServiceSubscription {
		id
		price
		discounts {
			id
		}
		service {
			id
			name
		}
		contract {
			id
			agreementDate
			status
		}
	}
`;
export const ServiceSubscriptionNameFragmentDoc = gql`
	fragment ServiceSubscriptionName on ServiceSubscription {
		id
	}
`;
export const SessionDetailFragmentDoc = gql`
	fragment SessionDetail on Session {
		id
		createdAt
		updatedAt
		expiresAt
		longestInactiveSec
		isVerified
		isCurrent
		loginRecord {
			id
		}
		mfaInvalidOTPAttempts {
			id
		}
		trustedDevice {
			id
			name
		}
		user {
			id
			email
		}
	}
`;
export const SessionNameFragmentDoc = gql`
	fragment SessionName on Session {
		id
	}
`;
export const StudentDetailFragmentDoc = gql`
	fragment StudentDetail on Student {
		id
		studentIDNumber
		graduatingYear
		hasPreviousSchooling
		previousSchoolInformation
		previousSchoolLocation
		allergies
		asthma
		medication
		medicationSchedule
		dietaryRestrictions
		healthNotes
		doctorName
		doctorPhoneNumber
		emergencyContactInformation
		person {
			...PersonDetail
		}
		homeRoomSectionStudentEnrollments {
			id
			homeRoomSection {
				id
				name
				homeRoom {
					id
					name
				}
			}
		}
		tuition {
			id
			name
		}
	}
	${PersonDetailFragmentDoc}
`;
export const StudentDocumentDetailFragmentDoc = gql`
	fragment StudentDocumentDetail on StudentDocument {
		id
		name
		description
		fileURL
		isPublic
		createdAt
		author {
			id
			email
		}
		sharingUserGroups {
			id
			name
		}
		student {
			id
			person {
				id
				name
				imageURL
			}
		}
	}
`;
export const StudentDocumentNameFragmentDoc = gql`
	fragment StudentDocumentName on StudentDocument {
		name
	}
`;
export const TermDetailFragmentDoc = gql`
	fragment TermDetail on Term {
		id
		name
		startDate
		endDate
		academicYear {
			id
			...AcademicYearName
		}
		courseSections {
			id
			...CourseSectionName
		}
		homeRoomSections {
			id
			...HomeRoomSectionName
		}
	}
	${AcademicYearNameFragmentDoc}
	${CourseSectionNameFragmentDoc}
	${HomeRoomSectionNameFragmentDoc}
`;
export const TrustedDeviceDetailFragmentDoc = gql`
	fragment TrustedDeviceDetail on TrustedDevice {
		id
		createdAt
		name
		isCurrent
		sessions {
			id
		}
		user {
			id
			email
		}
	}
`;
export const TrustedDeviceNameFragmentDoc = gql`
	fragment TrustedDeviceName on TrustedDevice {
		name
	}
`;
export const AcademicYearTuitionNameFragmentDoc = gql`
	fragment AcademicYearTuitionName on AcademicYearTuition {
		id
		year
	}
`;
export const TuitionDetailFieldsFragmentDoc = gql`
	fragment tuitionDetailFields on Tuition {
		id
		name
		initialYear
		academicYearTuitions {
			id
			...AcademicYearTuitionName
		}
		students {
			id
			...StudentName
		}
	}
	${AcademicYearTuitionNameFragmentDoc}
	${StudentNameFragmentDoc}
`;
export const TuitionNameFragmentDoc = gql`
	fragment TuitionName on Tuition {
		name
	}
`;
export const UserDetailFragmentDoc = gql`
	fragment UserDetail on User {
		id
		email
		status
		passwordUpdatedAt
		loginRecords {
			id
		}
		mfaFactor {
			id
			email
		}
		mfaInvalidOTPAttempts {
			id
		}
		permissionGroups {
			id
			name
		}
		person {
			id
			name
		}
		sessions {
			id
		}
		trustedDevices {
			id
			name
		}
		userGroups {
			id
			name
			imageURL
		}
	}
`;
export const UserNameFragmentDoc = gql`
	fragment UserName on User {
		email
	}
`;
export const UserGroupDetailFragmentDoc = gql`
	fragment UserGroupDetail on UserGroup {
		id
		name
		description
		imageURL
		color
		users {
			id
			email
			person {
				id
				name
			}
		}
	}
`;
export const UserGroupNameFragmentDoc = gql`
	fragment UserGroupName on UserGroup {
		name
	}
`;
export const AcademicYearCountDocument = gql`
	query academicYearCount($filters: [AcademicYearFilterInput!], $pagination: Pagination, $order: AcademicYearOrder) {
		academicYearConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useAcademicYearCountQuery__
 *
 * To run a query within a React component, call `useAcademicYearCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcademicYearCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcademicYearCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useAcademicYearCountQuery(
	baseOptions?: Apollo.QueryHookOptions<AcademicYearCountQuery, AcademicYearCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<AcademicYearCountQuery, AcademicYearCountQueryVariables>(AcademicYearCountDocument, options);
}
export function useAcademicYearCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<AcademicYearCountQuery, AcademicYearCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<AcademicYearCountQuery, AcademicYearCountQueryVariables>(
		AcademicYearCountDocument,
		options,
	);
}
export type AcademicYearCountQueryHookResult = ReturnType<typeof useAcademicYearCountQuery>;
export type AcademicYearCountLazyQueryHookResult = ReturnType<typeof useAcademicYearCountLazyQuery>;
export type AcademicYearCountQueryResult = Apollo.QueryResult<AcademicYearCountQuery, AcademicYearCountQueryVariables>;
export const AcademicYearCreateDocument = gql`
	mutation academicYearCreate($input: AcademicYearCreateInput!) {
		createAcademicYear(input: $input) {
			...AcademicYearDetail
		}
	}
	${AcademicYearDetailFragmentDoc}
`;
export type AcademicYearCreateMutationFn = Apollo.MutationFunction<
	AcademicYearCreateMutation,
	AcademicYearCreateMutationVariables
>;

/**
 * __useAcademicYearCreateMutation__
 *
 * To run a mutation, you first call `useAcademicYearCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcademicYearCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [academicYearCreateMutation, { data, loading, error }] = useAcademicYearCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcademicYearCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<AcademicYearCreateMutation, AcademicYearCreateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<AcademicYearCreateMutation, AcademicYearCreateMutationVariables>(
		AcademicYearCreateDocument,
		options,
	);
}
export type AcademicYearCreateMutationHookResult = ReturnType<typeof useAcademicYearCreateMutation>;
export type AcademicYearCreateMutationResult = Apollo.MutationResult<AcademicYearCreateMutation>;
export type AcademicYearCreateMutationOptions = Apollo.BaseMutationOptions<
	AcademicYearCreateMutation,
	AcademicYearCreateMutationVariables
>;
export const AcademicYearDeleteDocument = gql`
	mutation academicYearDelete($id: ID!) {
		deleteAcademicYear(id: $id)
	}
`;
export type AcademicYearDeleteMutationFn = Apollo.MutationFunction<
	AcademicYearDeleteMutation,
	AcademicYearDeleteMutationVariables
>;

/**
 * __useAcademicYearDeleteMutation__
 *
 * To run a mutation, you first call `useAcademicYearDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcademicYearDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [academicYearDeleteMutation, { data, loading, error }] = useAcademicYearDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAcademicYearDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<AcademicYearDeleteMutation, AcademicYearDeleteMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<AcademicYearDeleteMutation, AcademicYearDeleteMutationVariables>(
		AcademicYearDeleteDocument,
		options,
	);
}
export type AcademicYearDeleteMutationHookResult = ReturnType<typeof useAcademicYearDeleteMutation>;
export type AcademicYearDeleteMutationResult = Apollo.MutationResult<AcademicYearDeleteMutation>;
export type AcademicYearDeleteMutationOptions = Apollo.BaseMutationOptions<
	AcademicYearDeleteMutation,
	AcademicYearDeleteMutationVariables
>;
export const AcademicYearDetailDocument = gql`
	query academicYearDetail($id: ID!) {
		academicYear(id: $id) {
			...AcademicYearDetail
		}
	}
	${AcademicYearDetailFragmentDoc}
`;

/**
 * __useAcademicYearDetailQuery__
 *
 * To run a query within a React component, call `useAcademicYearDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcademicYearDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcademicYearDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAcademicYearDetailQuery(
	baseOptions: Apollo.QueryHookOptions<AcademicYearDetailQuery, AcademicYearDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<AcademicYearDetailQuery, AcademicYearDetailQueryVariables>(
		AcademicYearDetailDocument,
		options,
	);
}
export function useAcademicYearDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<AcademicYearDetailQuery, AcademicYearDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<AcademicYearDetailQuery, AcademicYearDetailQueryVariables>(
		AcademicYearDetailDocument,
		options,
	);
}
export type AcademicYearDetailQueryHookResult = ReturnType<typeof useAcademicYearDetailQuery>;
export type AcademicYearDetailLazyQueryHookResult = ReturnType<typeof useAcademicYearDetailLazyQuery>;
export type AcademicYearDetailQueryResult = Apollo.QueryResult<
	AcademicYearDetailQuery,
	AcademicYearDetailQueryVariables
>;
export const AcademicYearIndexDocument = gql`
	query academicYearIndex($filters: [AcademicYearFilterInput!], $pagination: Pagination, $order: AcademicYearOrder) {
		academicYearConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					name
				}
			}
		}
	}
`;

/**
 * __useAcademicYearIndexQuery__
 *
 * To run a query within a React component, call `useAcademicYearIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcademicYearIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcademicYearIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useAcademicYearIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<AcademicYearIndexQuery, AcademicYearIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<AcademicYearIndexQuery, AcademicYearIndexQueryVariables>(AcademicYearIndexDocument, options);
}
export function useAcademicYearIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<AcademicYearIndexQuery, AcademicYearIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<AcademicYearIndexQuery, AcademicYearIndexQueryVariables>(
		AcademicYearIndexDocument,
		options,
	);
}
export type AcademicYearIndexQueryHookResult = ReturnType<typeof useAcademicYearIndexQuery>;
export type AcademicYearIndexLazyQueryHookResult = ReturnType<typeof useAcademicYearIndexLazyQuery>;
export type AcademicYearIndexQueryResult = Apollo.QueryResult<AcademicYearIndexQuery, AcademicYearIndexQueryVariables>;
export const AcademicYearNameDocument = gql`
	query academicYearName($id: ID!) {
		academicYear(id: $id) {
			id
			...AcademicYearName
		}
	}
	${AcademicYearNameFragmentDoc}
`;

/**
 * __useAcademicYearNameQuery__
 *
 * To run a query within a React component, call `useAcademicYearNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcademicYearNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcademicYearNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAcademicYearNameQuery(
	baseOptions: Apollo.QueryHookOptions<AcademicYearNameQuery, AcademicYearNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<AcademicYearNameQuery, AcademicYearNameQueryVariables>(AcademicYearNameDocument, options);
}
export function useAcademicYearNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<AcademicYearNameQuery, AcademicYearNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<AcademicYearNameQuery, AcademicYearNameQueryVariables>(AcademicYearNameDocument, options);
}
export type AcademicYearNameQueryHookResult = ReturnType<typeof useAcademicYearNameQuery>;
export type AcademicYearNameLazyQueryHookResult = ReturnType<typeof useAcademicYearNameLazyQuery>;
export type AcademicYearNameQueryResult = Apollo.QueryResult<AcademicYearNameQuery, AcademicYearNameQueryVariables>;
export const AcademicYearNamesDocument = gql`
	query academicYearNames($filters: [AcademicYearFilterInput!], $pagination: Pagination, $order: AcademicYearOrder) {
		academicYearConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...AcademicYearName
				}
			}
		}
	}
	${AcademicYearNameFragmentDoc}
`;

/**
 * __useAcademicYearNamesQuery__
 *
 * To run a query within a React component, call `useAcademicYearNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcademicYearNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcademicYearNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useAcademicYearNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<AcademicYearNamesQuery, AcademicYearNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<AcademicYearNamesQuery, AcademicYearNamesQueryVariables>(AcademicYearNamesDocument, options);
}
export function useAcademicYearNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<AcademicYearNamesQuery, AcademicYearNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<AcademicYearNamesQuery, AcademicYearNamesQueryVariables>(
		AcademicYearNamesDocument,
		options,
	);
}
export type AcademicYearNamesQueryHookResult = ReturnType<typeof useAcademicYearNamesQuery>;
export type AcademicYearNamesLazyQueryHookResult = ReturnType<typeof useAcademicYearNamesLazyQuery>;
export type AcademicYearNamesQueryResult = Apollo.QueryResult<AcademicYearNamesQuery, AcademicYearNamesQueryVariables>;
export const AcademicYearPaginationDocument = gql`
	query academicYearPagination($filters: [AcademicYearFilterInput!], $order: AcademicYearOrder) {
		academicYearConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useAcademicYearPaginationQuery__
 *
 * To run a query within a React component, call `useAcademicYearPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcademicYearPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcademicYearPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useAcademicYearPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<AcademicYearPaginationQuery, AcademicYearPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<AcademicYearPaginationQuery, AcademicYearPaginationQueryVariables>(
		AcademicYearPaginationDocument,
		options,
	);
}
export function useAcademicYearPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<AcademicYearPaginationQuery, AcademicYearPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<AcademicYearPaginationQuery, AcademicYearPaginationQueryVariables>(
		AcademicYearPaginationDocument,
		options,
	);
}
export type AcademicYearPaginationQueryHookResult = ReturnType<typeof useAcademicYearPaginationQuery>;
export type AcademicYearPaginationLazyQueryHookResult = ReturnType<typeof useAcademicYearPaginationLazyQuery>;
export type AcademicYearPaginationQueryResult = Apollo.QueryResult<
	AcademicYearPaginationQuery,
	AcademicYearPaginationQueryVariables
>;
export const AcademicYearSelectDocument = gql`
	query academicYearSelect($filters: [AcademicYearFilterInput!], $order: AcademicYearOrder) {
		academicYearConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...AcademicYearName
				}
			}
		}
	}
	${AcademicYearNameFragmentDoc}
`;

/**
 * __useAcademicYearSelectQuery__
 *
 * To run a query within a React component, call `useAcademicYearSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcademicYearSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcademicYearSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useAcademicYearSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<AcademicYearSelectQuery, AcademicYearSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<AcademicYearSelectQuery, AcademicYearSelectQueryVariables>(
		AcademicYearSelectDocument,
		options,
	);
}
export function useAcademicYearSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<AcademicYearSelectQuery, AcademicYearSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<AcademicYearSelectQuery, AcademicYearSelectQueryVariables>(
		AcademicYearSelectDocument,
		options,
	);
}
export type AcademicYearSelectQueryHookResult = ReturnType<typeof useAcademicYearSelectQuery>;
export type AcademicYearSelectLazyQueryHookResult = ReturnType<typeof useAcademicYearSelectLazyQuery>;
export type AcademicYearSelectQueryResult = Apollo.QueryResult<
	AcademicYearSelectQuery,
	AcademicYearSelectQueryVariables
>;
export const AcademicYearUpdateDocument = gql`
	mutation academicYearUpdate($id: ID!, $input: AcademicYearUpdateInput!) {
		updateAcademicYear(id: $id, input: $input) {
			...AcademicYearDetail
		}
	}
	${AcademicYearDetailFragmentDoc}
`;
export type AcademicYearUpdateMutationFn = Apollo.MutationFunction<
	AcademicYearUpdateMutation,
	AcademicYearUpdateMutationVariables
>;

/**
 * __useAcademicYearUpdateMutation__
 *
 * To run a mutation, you first call `useAcademicYearUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcademicYearUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [academicYearUpdateMutation, { data, loading, error }] = useAcademicYearUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcademicYearUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<AcademicYearUpdateMutation, AcademicYearUpdateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<AcademicYearUpdateMutation, AcademicYearUpdateMutationVariables>(
		AcademicYearUpdateDocument,
		options,
	);
}
export type AcademicYearUpdateMutationHookResult = ReturnType<typeof useAcademicYearUpdateMutation>;
export type AcademicYearUpdateMutationResult = Apollo.MutationResult<AcademicYearUpdateMutation>;
export type AcademicYearUpdateMutationOptions = Apollo.BaseMutationOptions<
	AcademicYearUpdateMutation,
	AcademicYearUpdateMutationVariables
>;
export const AcademicYearTuitionCountDocument = gql`
	query academicYearTuitionCount(
		$filters: [AcademicYearTuitionFilterInput!]
		$pagination: Pagination
		$order: AcademicYearTuitionOrder
	) {
		academicYearTuitionConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useAcademicYearTuitionCountQuery__
 *
 * To run a query within a React component, call `useAcademicYearTuitionCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcademicYearTuitionCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcademicYearTuitionCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useAcademicYearTuitionCountQuery(
	baseOptions?: Apollo.QueryHookOptions<AcademicYearTuitionCountQuery, AcademicYearTuitionCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<AcademicYearTuitionCountQuery, AcademicYearTuitionCountQueryVariables>(
		AcademicYearTuitionCountDocument,
		options,
	);
}
export function useAcademicYearTuitionCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<AcademicYearTuitionCountQuery, AcademicYearTuitionCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<AcademicYearTuitionCountQuery, AcademicYearTuitionCountQueryVariables>(
		AcademicYearTuitionCountDocument,
		options,
	);
}
export type AcademicYearTuitionCountQueryHookResult = ReturnType<typeof useAcademicYearTuitionCountQuery>;
export type AcademicYearTuitionCountLazyQueryHookResult = ReturnType<typeof useAcademicYearTuitionCountLazyQuery>;
export type AcademicYearTuitionCountQueryResult = Apollo.QueryResult<
	AcademicYearTuitionCountQuery,
	AcademicYearTuitionCountQueryVariables
>;
export const AcademicYearTuitionCreateDocument = gql`
	mutation academicYearTuitionCreate($input: AcademicYearTuitionCreateInput!) {
		createAcademicYearTuition(input: $input) {
			...academicYearTuitionDetailFields
		}
	}
	${AcademicYearTuitionDetailFieldsFragmentDoc}
`;
export type AcademicYearTuitionCreateMutationFn = Apollo.MutationFunction<
	AcademicYearTuitionCreateMutation,
	AcademicYearTuitionCreateMutationVariables
>;

/**
 * __useAcademicYearTuitionCreateMutation__
 *
 * To run a mutation, you first call `useAcademicYearTuitionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcademicYearTuitionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [academicYearTuitionCreateMutation, { data, loading, error }] = useAcademicYearTuitionCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcademicYearTuitionCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<
		AcademicYearTuitionCreateMutation,
		AcademicYearTuitionCreateMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<AcademicYearTuitionCreateMutation, AcademicYearTuitionCreateMutationVariables>(
		AcademicYearTuitionCreateDocument,
		options,
	);
}
export type AcademicYearTuitionCreateMutationHookResult = ReturnType<typeof useAcademicYearTuitionCreateMutation>;
export type AcademicYearTuitionCreateMutationResult = Apollo.MutationResult<AcademicYearTuitionCreateMutation>;
export type AcademicYearTuitionCreateMutationOptions = Apollo.BaseMutationOptions<
	AcademicYearTuitionCreateMutation,
	AcademicYearTuitionCreateMutationVariables
>;
export const AcademicYearTuitionDeleteDocument = gql`
	mutation academicYearTuitionDelete($id: ID!) {
		deleteAcademicYearTuition(id: $id)
	}
`;
export type AcademicYearTuitionDeleteMutationFn = Apollo.MutationFunction<
	AcademicYearTuitionDeleteMutation,
	AcademicYearTuitionDeleteMutationVariables
>;

/**
 * __useAcademicYearTuitionDeleteMutation__
 *
 * To run a mutation, you first call `useAcademicYearTuitionDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcademicYearTuitionDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [academicYearTuitionDeleteMutation, { data, loading, error }] = useAcademicYearTuitionDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAcademicYearTuitionDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<
		AcademicYearTuitionDeleteMutation,
		AcademicYearTuitionDeleteMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<AcademicYearTuitionDeleteMutation, AcademicYearTuitionDeleteMutationVariables>(
		AcademicYearTuitionDeleteDocument,
		options,
	);
}
export type AcademicYearTuitionDeleteMutationHookResult = ReturnType<typeof useAcademicYearTuitionDeleteMutation>;
export type AcademicYearTuitionDeleteMutationResult = Apollo.MutationResult<AcademicYearTuitionDeleteMutation>;
export type AcademicYearTuitionDeleteMutationOptions = Apollo.BaseMutationOptions<
	AcademicYearTuitionDeleteMutation,
	AcademicYearTuitionDeleteMutationVariables
>;
export const AcademicYearTuitionDetailDocument = gql`
	query academicYearTuitionDetail($id: ID!) {
		academicYearTuition(id: $id) {
			...academicYearTuitionDetailFields
		}
	}
	${AcademicYearTuitionDetailFieldsFragmentDoc}
`;

/**
 * __useAcademicYearTuitionDetailQuery__
 *
 * To run a query within a React component, call `useAcademicYearTuitionDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcademicYearTuitionDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcademicYearTuitionDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAcademicYearTuitionDetailQuery(
	baseOptions: Apollo.QueryHookOptions<AcademicYearTuitionDetailQuery, AcademicYearTuitionDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<AcademicYearTuitionDetailQuery, AcademicYearTuitionDetailQueryVariables>(
		AcademicYearTuitionDetailDocument,
		options,
	);
}
export function useAcademicYearTuitionDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<AcademicYearTuitionDetailQuery, AcademicYearTuitionDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<AcademicYearTuitionDetailQuery, AcademicYearTuitionDetailQueryVariables>(
		AcademicYearTuitionDetailDocument,
		options,
	);
}
export type AcademicYearTuitionDetailQueryHookResult = ReturnType<typeof useAcademicYearTuitionDetailQuery>;
export type AcademicYearTuitionDetailLazyQueryHookResult = ReturnType<typeof useAcademicYearTuitionDetailLazyQuery>;
export type AcademicYearTuitionDetailQueryResult = Apollo.QueryResult<
	AcademicYearTuitionDetailQuery,
	AcademicYearTuitionDetailQueryVariables
>;
export const AcademicYearTuitionIndexDocument = gql`
	query academicYearTuitionIndex(
		$filters: [AcademicYearTuitionFilterInput!]
		$pagination: Pagination
		$order: AcademicYearTuitionOrder
	) {
		academicYearTuitionConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					year
					nurseryPrice
					receptionPrice
					primaryPrice
					secondaryPrice
				}
			}
		}
	}
`;

/**
 * __useAcademicYearTuitionIndexQuery__
 *
 * To run a query within a React component, call `useAcademicYearTuitionIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcademicYearTuitionIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcademicYearTuitionIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useAcademicYearTuitionIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<AcademicYearTuitionIndexQuery, AcademicYearTuitionIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<AcademicYearTuitionIndexQuery, AcademicYearTuitionIndexQueryVariables>(
		AcademicYearTuitionIndexDocument,
		options,
	);
}
export function useAcademicYearTuitionIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<AcademicYearTuitionIndexQuery, AcademicYearTuitionIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<AcademicYearTuitionIndexQuery, AcademicYearTuitionIndexQueryVariables>(
		AcademicYearTuitionIndexDocument,
		options,
	);
}
export type AcademicYearTuitionIndexQueryHookResult = ReturnType<typeof useAcademicYearTuitionIndexQuery>;
export type AcademicYearTuitionIndexLazyQueryHookResult = ReturnType<typeof useAcademicYearTuitionIndexLazyQuery>;
export type AcademicYearTuitionIndexQueryResult = Apollo.QueryResult<
	AcademicYearTuitionIndexQuery,
	AcademicYearTuitionIndexQueryVariables
>;
export const AcademicYearTuitionNameDocument = gql`
	query academicYearTuitionName($id: ID!) {
		academicYearTuition(id: $id) {
			id
			...AcademicYearTuitionName
		}
	}
	${AcademicYearTuitionNameFragmentDoc}
`;

/**
 * __useAcademicYearTuitionNameQuery__
 *
 * To run a query within a React component, call `useAcademicYearTuitionNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcademicYearTuitionNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcademicYearTuitionNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAcademicYearTuitionNameQuery(
	baseOptions: Apollo.QueryHookOptions<AcademicYearTuitionNameQuery, AcademicYearTuitionNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<AcademicYearTuitionNameQuery, AcademicYearTuitionNameQueryVariables>(
		AcademicYearTuitionNameDocument,
		options,
	);
}
export function useAcademicYearTuitionNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<AcademicYearTuitionNameQuery, AcademicYearTuitionNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<AcademicYearTuitionNameQuery, AcademicYearTuitionNameQueryVariables>(
		AcademicYearTuitionNameDocument,
		options,
	);
}
export type AcademicYearTuitionNameQueryHookResult = ReturnType<typeof useAcademicYearTuitionNameQuery>;
export type AcademicYearTuitionNameLazyQueryHookResult = ReturnType<typeof useAcademicYearTuitionNameLazyQuery>;
export type AcademicYearTuitionNameQueryResult = Apollo.QueryResult<
	AcademicYearTuitionNameQuery,
	AcademicYearTuitionNameQueryVariables
>;
export const AcademicYearTuitionNamesDocument = gql`
	query academicYearTuitionNames(
		$filters: [AcademicYearTuitionFilterInput!]
		$pagination: Pagination
		$order: AcademicYearTuitionOrder
	) {
		academicYearTuitionConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...AcademicYearTuitionName
				}
			}
		}
	}
	${AcademicYearTuitionNameFragmentDoc}
`;

/**
 * __useAcademicYearTuitionNamesQuery__
 *
 * To run a query within a React component, call `useAcademicYearTuitionNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcademicYearTuitionNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcademicYearTuitionNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useAcademicYearTuitionNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<AcademicYearTuitionNamesQuery, AcademicYearTuitionNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<AcademicYearTuitionNamesQuery, AcademicYearTuitionNamesQueryVariables>(
		AcademicYearTuitionNamesDocument,
		options,
	);
}
export function useAcademicYearTuitionNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<AcademicYearTuitionNamesQuery, AcademicYearTuitionNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<AcademicYearTuitionNamesQuery, AcademicYearTuitionNamesQueryVariables>(
		AcademicYearTuitionNamesDocument,
		options,
	);
}
export type AcademicYearTuitionNamesQueryHookResult = ReturnType<typeof useAcademicYearTuitionNamesQuery>;
export type AcademicYearTuitionNamesLazyQueryHookResult = ReturnType<typeof useAcademicYearTuitionNamesLazyQuery>;
export type AcademicYearTuitionNamesQueryResult = Apollo.QueryResult<
	AcademicYearTuitionNamesQuery,
	AcademicYearTuitionNamesQueryVariables
>;
export const AcademicYearTuitionPaginationDocument = gql`
	query academicYearTuitionPagination($filters: [AcademicYearTuitionFilterInput!], $order: AcademicYearTuitionOrder) {
		academicYearTuitionConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useAcademicYearTuitionPaginationQuery__
 *
 * To run a query within a React component, call `useAcademicYearTuitionPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcademicYearTuitionPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcademicYearTuitionPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useAcademicYearTuitionPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<
		AcademicYearTuitionPaginationQuery,
		AcademicYearTuitionPaginationQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<AcademicYearTuitionPaginationQuery, AcademicYearTuitionPaginationQueryVariables>(
		AcademicYearTuitionPaginationDocument,
		options,
	);
}
export function useAcademicYearTuitionPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		AcademicYearTuitionPaginationQuery,
		AcademicYearTuitionPaginationQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<AcademicYearTuitionPaginationQuery, AcademicYearTuitionPaginationQueryVariables>(
		AcademicYearTuitionPaginationDocument,
		options,
	);
}
export type AcademicYearTuitionPaginationQueryHookResult = ReturnType<typeof useAcademicYearTuitionPaginationQuery>;
export type AcademicYearTuitionPaginationLazyQueryHookResult = ReturnType<
	typeof useAcademicYearTuitionPaginationLazyQuery
>;
export type AcademicYearTuitionPaginationQueryResult = Apollo.QueryResult<
	AcademicYearTuitionPaginationQuery,
	AcademicYearTuitionPaginationQueryVariables
>;
export const AcademicYearTuitionSelectDocument = gql`
	query academicYearTuitionSelect($filters: [AcademicYearTuitionFilterInput!], $order: AcademicYearTuitionOrder) {
		academicYearTuitionConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
				}
			}
		}
	}
`;

/**
 * __useAcademicYearTuitionSelectQuery__
 *
 * To run a query within a React component, call `useAcademicYearTuitionSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcademicYearTuitionSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcademicYearTuitionSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useAcademicYearTuitionSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<AcademicYearTuitionSelectQuery, AcademicYearTuitionSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<AcademicYearTuitionSelectQuery, AcademicYearTuitionSelectQueryVariables>(
		AcademicYearTuitionSelectDocument,
		options,
	);
}
export function useAcademicYearTuitionSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<AcademicYearTuitionSelectQuery, AcademicYearTuitionSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<AcademicYearTuitionSelectQuery, AcademicYearTuitionSelectQueryVariables>(
		AcademicYearTuitionSelectDocument,
		options,
	);
}
export type AcademicYearTuitionSelectQueryHookResult = ReturnType<typeof useAcademicYearTuitionSelectQuery>;
export type AcademicYearTuitionSelectLazyQueryHookResult = ReturnType<typeof useAcademicYearTuitionSelectLazyQuery>;
export type AcademicYearTuitionSelectQueryResult = Apollo.QueryResult<
	AcademicYearTuitionSelectQuery,
	AcademicYearTuitionSelectQueryVariables
>;
export const AcademicYearTuitionUpdateDocument = gql`
	mutation academicYearTuitionUpdate($id: ID!, $input: AcademicYearTuitionUpdateInput!) {
		updateAcademicYearTuition(id: $id, input: $input) {
			...academicYearTuitionDetailFields
		}
	}
	${AcademicYearTuitionDetailFieldsFragmentDoc}
`;
export type AcademicYearTuitionUpdateMutationFn = Apollo.MutationFunction<
	AcademicYearTuitionUpdateMutation,
	AcademicYearTuitionUpdateMutationVariables
>;

/**
 * __useAcademicYearTuitionUpdateMutation__
 *
 * To run a mutation, you first call `useAcademicYearTuitionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcademicYearTuitionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [academicYearTuitionUpdateMutation, { data, loading, error }] = useAcademicYearTuitionUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcademicYearTuitionUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<
		AcademicYearTuitionUpdateMutation,
		AcademicYearTuitionUpdateMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<AcademicYearTuitionUpdateMutation, AcademicYearTuitionUpdateMutationVariables>(
		AcademicYearTuitionUpdateDocument,
		options,
	);
}
export type AcademicYearTuitionUpdateMutationHookResult = ReturnType<typeof useAcademicYearTuitionUpdateMutation>;
export type AcademicYearTuitionUpdateMutationResult = Apollo.MutationResult<AcademicYearTuitionUpdateMutation>;
export type AcademicYearTuitionUpdateMutationOptions = Apollo.BaseMutationOptions<
	AcademicYearTuitionUpdateMutation,
	AcademicYearTuitionUpdateMutationVariables
>;
export const AddressCountDocument = gql`
	query addressCount($filters: [AddressFilterInput!], $pagination: Pagination, $order: AddressOrder) {
		addressConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useAddressCountQuery__
 *
 * To run a query within a React component, call `useAddressCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddressCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddressCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useAddressCountQuery(
	baseOptions?: Apollo.QueryHookOptions<AddressCountQuery, AddressCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<AddressCountQuery, AddressCountQueryVariables>(AddressCountDocument, options);
}
export function useAddressCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<AddressCountQuery, AddressCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<AddressCountQuery, AddressCountQueryVariables>(AddressCountDocument, options);
}
export type AddressCountQueryHookResult = ReturnType<typeof useAddressCountQuery>;
export type AddressCountLazyQueryHookResult = ReturnType<typeof useAddressCountLazyQuery>;
export type AddressCountQueryResult = Apollo.QueryResult<AddressCountQuery, AddressCountQueryVariables>;
export const AddressCreateDocument = gql`
	mutation addressCreate($input: AddressCreateInput!) {
		createAddress(input: $input) {
			...AddressDetail
		}
	}
	${AddressDetailFragmentDoc}
`;
export type AddressCreateMutationFn = Apollo.MutationFunction<AddressCreateMutation, AddressCreateMutationVariables>;

/**
 * __useAddressCreateMutation__
 *
 * To run a mutation, you first call `useAddressCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddressCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addressCreateMutation, { data, loading, error }] = useAddressCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddressCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<AddressCreateMutation, AddressCreateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<AddressCreateMutation, AddressCreateMutationVariables>(AddressCreateDocument, options);
}
export type AddressCreateMutationHookResult = ReturnType<typeof useAddressCreateMutation>;
export type AddressCreateMutationResult = Apollo.MutationResult<AddressCreateMutation>;
export type AddressCreateMutationOptions = Apollo.BaseMutationOptions<
	AddressCreateMutation,
	AddressCreateMutationVariables
>;
export const AddressDeleteDocument = gql`
	mutation addressDelete($id: ID!) {
		deleteAddress(id: $id)
	}
`;
export type AddressDeleteMutationFn = Apollo.MutationFunction<AddressDeleteMutation, AddressDeleteMutationVariables>;

/**
 * __useAddressDeleteMutation__
 *
 * To run a mutation, you first call `useAddressDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddressDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addressDeleteMutation, { data, loading, error }] = useAddressDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAddressDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<AddressDeleteMutation, AddressDeleteMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<AddressDeleteMutation, AddressDeleteMutationVariables>(AddressDeleteDocument, options);
}
export type AddressDeleteMutationHookResult = ReturnType<typeof useAddressDeleteMutation>;
export type AddressDeleteMutationResult = Apollo.MutationResult<AddressDeleteMutation>;
export type AddressDeleteMutationOptions = Apollo.BaseMutationOptions<
	AddressDeleteMutation,
	AddressDeleteMutationVariables
>;
export const AddressDetailDocument = gql`
	query addressDetail($id: ID!) {
		address(id: $id) {
			...AddressDetail
		}
	}
	${AddressDetailFragmentDoc}
`;

/**
 * __useAddressDetailQuery__
 *
 * To run a query within a React component, call `useAddressDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddressDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddressDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAddressDetailQuery(
	baseOptions: Apollo.QueryHookOptions<AddressDetailQuery, AddressDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<AddressDetailQuery, AddressDetailQueryVariables>(AddressDetailDocument, options);
}
export function useAddressDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<AddressDetailQuery, AddressDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<AddressDetailQuery, AddressDetailQueryVariables>(AddressDetailDocument, options);
}
export type AddressDetailQueryHookResult = ReturnType<typeof useAddressDetailQuery>;
export type AddressDetailLazyQueryHookResult = ReturnType<typeof useAddressDetailLazyQuery>;
export type AddressDetailQueryResult = Apollo.QueryResult<AddressDetailQuery, AddressDetailQueryVariables>;
export const AddressIndexDocument = gql`
	query addressIndex($filters: [AddressFilterInput!], $pagination: Pagination, $order: AddressOrder) {
		addressConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					shortAddress
					fullAddress
					addressLine1
					addressLine2
					city
					postalCode
					stateOrProvince
					country
				}
			}
		}
	}
`;

/**
 * __useAddressIndexQuery__
 *
 * To run a query within a React component, call `useAddressIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddressIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddressIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useAddressIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<AddressIndexQuery, AddressIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<AddressIndexQuery, AddressIndexQueryVariables>(AddressIndexDocument, options);
}
export function useAddressIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<AddressIndexQuery, AddressIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<AddressIndexQuery, AddressIndexQueryVariables>(AddressIndexDocument, options);
}
export type AddressIndexQueryHookResult = ReturnType<typeof useAddressIndexQuery>;
export type AddressIndexLazyQueryHookResult = ReturnType<typeof useAddressIndexLazyQuery>;
export type AddressIndexQueryResult = Apollo.QueryResult<AddressIndexQuery, AddressIndexQueryVariables>;
export const AddressNameDocument = gql`
	query addressName($id: ID!) {
		address(id: $id) {
			id
			...AddressName
		}
	}
	${AddressNameFragmentDoc}
`;

/**
 * __useAddressNameQuery__
 *
 * To run a query within a React component, call `useAddressNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddressNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddressNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAddressNameQuery(baseOptions: Apollo.QueryHookOptions<AddressNameQuery, AddressNameQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<AddressNameQuery, AddressNameQueryVariables>(AddressNameDocument, options);
}
export function useAddressNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<AddressNameQuery, AddressNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<AddressNameQuery, AddressNameQueryVariables>(AddressNameDocument, options);
}
export type AddressNameQueryHookResult = ReturnType<typeof useAddressNameQuery>;
export type AddressNameLazyQueryHookResult = ReturnType<typeof useAddressNameLazyQuery>;
export type AddressNameQueryResult = Apollo.QueryResult<AddressNameQuery, AddressNameQueryVariables>;
export const AddressNamesDocument = gql`
	query addressNames($filters: [AddressFilterInput!], $pagination: Pagination, $order: AddressOrder) {
		addressConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...AddressName
				}
			}
		}
	}
	${AddressNameFragmentDoc}
`;

/**
 * __useAddressNamesQuery__
 *
 * To run a query within a React component, call `useAddressNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddressNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddressNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useAddressNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<AddressNamesQuery, AddressNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<AddressNamesQuery, AddressNamesQueryVariables>(AddressNamesDocument, options);
}
export function useAddressNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<AddressNamesQuery, AddressNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<AddressNamesQuery, AddressNamesQueryVariables>(AddressNamesDocument, options);
}
export type AddressNamesQueryHookResult = ReturnType<typeof useAddressNamesQuery>;
export type AddressNamesLazyQueryHookResult = ReturnType<typeof useAddressNamesLazyQuery>;
export type AddressNamesQueryResult = Apollo.QueryResult<AddressNamesQuery, AddressNamesQueryVariables>;
export const AddressPaginationDocument = gql`
	query addressPagination($filters: [AddressFilterInput!], $order: AddressOrder) {
		addressConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useAddressPaginationQuery__
 *
 * To run a query within a React component, call `useAddressPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddressPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddressPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useAddressPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<AddressPaginationQuery, AddressPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<AddressPaginationQuery, AddressPaginationQueryVariables>(AddressPaginationDocument, options);
}
export function useAddressPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<AddressPaginationQuery, AddressPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<AddressPaginationQuery, AddressPaginationQueryVariables>(
		AddressPaginationDocument,
		options,
	);
}
export type AddressPaginationQueryHookResult = ReturnType<typeof useAddressPaginationQuery>;
export type AddressPaginationLazyQueryHookResult = ReturnType<typeof useAddressPaginationLazyQuery>;
export type AddressPaginationQueryResult = Apollo.QueryResult<AddressPaginationQuery, AddressPaginationQueryVariables>;
export const AddressSelectDocument = gql`
	query addressSelect($filters: [AddressFilterInput!], $order: AddressOrder) {
		addressConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...AddressName
				}
			}
		}
	}
	${AddressNameFragmentDoc}
`;

/**
 * __useAddressSelectQuery__
 *
 * To run a query within a React component, call `useAddressSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddressSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddressSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useAddressSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<AddressSelectQuery, AddressSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<AddressSelectQuery, AddressSelectQueryVariables>(AddressSelectDocument, options);
}
export function useAddressSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<AddressSelectQuery, AddressSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<AddressSelectQuery, AddressSelectQueryVariables>(AddressSelectDocument, options);
}
export type AddressSelectQueryHookResult = ReturnType<typeof useAddressSelectQuery>;
export type AddressSelectLazyQueryHookResult = ReturnType<typeof useAddressSelectLazyQuery>;
export type AddressSelectQueryResult = Apollo.QueryResult<AddressSelectQuery, AddressSelectQueryVariables>;
export const StudentAddressListDocument = gql`
	query studentAddressList($studentID: ID!) {
		addressConnection(
			filters: [
				{ people: { student: { idEQ: $studentID } } }
				{ people: { parent: { relationships: { studentIDEQ: $studentID } } } }
			]
		) {
			edges {
				node {
					id
					fullAddress
					addressLine1
					addressLine2
					city
					postalCode
					stateOrProvince
					country
				}
			}
		}
	}
`;

/**
 * __useStudentAddressListQuery__
 *
 * To run a query within a React component, call `useStudentAddressListQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentAddressListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentAddressListQuery({
 *   variables: {
 *      studentID: // value for 'studentID'
 *   },
 * });
 */
export function useStudentAddressListQuery(
	baseOptions: Apollo.QueryHookOptions<StudentAddressListQuery, StudentAddressListQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<StudentAddressListQuery, StudentAddressListQueryVariables>(
		StudentAddressListDocument,
		options,
	);
}
export function useStudentAddressListLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<StudentAddressListQuery, StudentAddressListQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<StudentAddressListQuery, StudentAddressListQueryVariables>(
		StudentAddressListDocument,
		options,
	);
}
export type StudentAddressListQueryHookResult = ReturnType<typeof useStudentAddressListQuery>;
export type StudentAddressListLazyQueryHookResult = ReturnType<typeof useStudentAddressListLazyQuery>;
export type StudentAddressListQueryResult = Apollo.QueryResult<
	StudentAddressListQuery,
	StudentAddressListQueryVariables
>;
export const AddressUpdateDocument = gql`
	mutation addressUpdate($id: ID!, $input: AddressUpdateInput!) {
		updateAddress(id: $id, input: $input) {
			...AddressDetail
		}
	}
	${AddressDetailFragmentDoc}
`;
export type AddressUpdateMutationFn = Apollo.MutationFunction<AddressUpdateMutation, AddressUpdateMutationVariables>;

/**
 * __useAddressUpdateMutation__
 *
 * To run a mutation, you first call `useAddressUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddressUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addressUpdateMutation, { data, loading, error }] = useAddressUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddressUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<AddressUpdateMutation, AddressUpdateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<AddressUpdateMutation, AddressUpdateMutationVariables>(AddressUpdateDocument, options);
}
export type AddressUpdateMutationHookResult = ReturnType<typeof useAddressUpdateMutation>;
export type AddressUpdateMutationResult = Apollo.MutationResult<AddressUpdateMutation>;
export type AddressUpdateMutationOptions = Apollo.BaseMutationOptions<
	AddressUpdateMutation,
	AddressUpdateMutationVariables
>;
export const BillingSetupSubmissionCountDocument = gql`
	query billingSetupSubmissionCount(
		$filters: [BillingSetupSubmissionFilterInput!]
		$pagination: Pagination
		$order: BillingSetupSubmissionOrder
	) {
		billingSetupSubmissionConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useBillingSetupSubmissionCountQuery__
 *
 * To run a query within a React component, call `useBillingSetupSubmissionCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillingSetupSubmissionCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingSetupSubmissionCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useBillingSetupSubmissionCountQuery(
	baseOptions?: Apollo.QueryHookOptions<BillingSetupSubmissionCountQuery, BillingSetupSubmissionCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<BillingSetupSubmissionCountQuery, BillingSetupSubmissionCountQueryVariables>(
		BillingSetupSubmissionCountDocument,
		options,
	);
}
export function useBillingSetupSubmissionCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		BillingSetupSubmissionCountQuery,
		BillingSetupSubmissionCountQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<BillingSetupSubmissionCountQuery, BillingSetupSubmissionCountQueryVariables>(
		BillingSetupSubmissionCountDocument,
		options,
	);
}
export type BillingSetupSubmissionCountQueryHookResult = ReturnType<typeof useBillingSetupSubmissionCountQuery>;
export type BillingSetupSubmissionCountLazyQueryHookResult = ReturnType<typeof useBillingSetupSubmissionCountLazyQuery>;
export type BillingSetupSubmissionCountQueryResult = Apollo.QueryResult<
	BillingSetupSubmissionCountQuery,
	BillingSetupSubmissionCountQueryVariables
>;
export const BillingSetupSubmissionCreateDocument = gql`
	mutation billingSetupSubmissionCreate($input: BillingSetupSubmissionCreateInput!) {
		createBillingSetupSubmission(input: $input) {
			...BillingSetupSubmissionDetail
		}
	}
	${BillingSetupSubmissionDetailFragmentDoc}
`;
export type BillingSetupSubmissionCreateMutationFn = Apollo.MutationFunction<
	BillingSetupSubmissionCreateMutation,
	BillingSetupSubmissionCreateMutationVariables
>;

/**
 * __useBillingSetupSubmissionCreateMutation__
 *
 * To run a mutation, you first call `useBillingSetupSubmissionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBillingSetupSubmissionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [billingSetupSubmissionCreateMutation, { data, loading, error }] = useBillingSetupSubmissionCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBillingSetupSubmissionCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<
		BillingSetupSubmissionCreateMutation,
		BillingSetupSubmissionCreateMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<BillingSetupSubmissionCreateMutation, BillingSetupSubmissionCreateMutationVariables>(
		BillingSetupSubmissionCreateDocument,
		options,
	);
}
export type BillingSetupSubmissionCreateMutationHookResult = ReturnType<typeof useBillingSetupSubmissionCreateMutation>;
export type BillingSetupSubmissionCreateMutationResult = Apollo.MutationResult<BillingSetupSubmissionCreateMutation>;
export type BillingSetupSubmissionCreateMutationOptions = Apollo.BaseMutationOptions<
	BillingSetupSubmissionCreateMutation,
	BillingSetupSubmissionCreateMutationVariables
>;
export const BillingSetupSubmissionDeleteDocument = gql`
	mutation billingSetupSubmissionDelete($id: ID!) {
		deleteBillingSetupSubmission(id: $id)
	}
`;
export type BillingSetupSubmissionDeleteMutationFn = Apollo.MutationFunction<
	BillingSetupSubmissionDeleteMutation,
	BillingSetupSubmissionDeleteMutationVariables
>;

/**
 * __useBillingSetupSubmissionDeleteMutation__
 *
 * To run a mutation, you first call `useBillingSetupSubmissionDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBillingSetupSubmissionDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [billingSetupSubmissionDeleteMutation, { data, loading, error }] = useBillingSetupSubmissionDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBillingSetupSubmissionDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<
		BillingSetupSubmissionDeleteMutation,
		BillingSetupSubmissionDeleteMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<BillingSetupSubmissionDeleteMutation, BillingSetupSubmissionDeleteMutationVariables>(
		BillingSetupSubmissionDeleteDocument,
		options,
	);
}
export type BillingSetupSubmissionDeleteMutationHookResult = ReturnType<typeof useBillingSetupSubmissionDeleteMutation>;
export type BillingSetupSubmissionDeleteMutationResult = Apollo.MutationResult<BillingSetupSubmissionDeleteMutation>;
export type BillingSetupSubmissionDeleteMutationOptions = Apollo.BaseMutationOptions<
	BillingSetupSubmissionDeleteMutation,
	BillingSetupSubmissionDeleteMutationVariables
>;
export const BillingSetupSubmissionDetailDocument = gql`
	query billingSetupSubmissionDetail($id: ID!) {
		billingSetupSubmission(id: $id) {
			...BillingSetupSubmissionDetail
		}
	}
	${BillingSetupSubmissionDetailFragmentDoc}
`;

/**
 * __useBillingSetupSubmissionDetailQuery__
 *
 * To run a query within a React component, call `useBillingSetupSubmissionDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillingSetupSubmissionDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingSetupSubmissionDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBillingSetupSubmissionDetailQuery(
	baseOptions: Apollo.QueryHookOptions<BillingSetupSubmissionDetailQuery, BillingSetupSubmissionDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<BillingSetupSubmissionDetailQuery, BillingSetupSubmissionDetailQueryVariables>(
		BillingSetupSubmissionDetailDocument,
		options,
	);
}
export function useBillingSetupSubmissionDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		BillingSetupSubmissionDetailQuery,
		BillingSetupSubmissionDetailQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<BillingSetupSubmissionDetailQuery, BillingSetupSubmissionDetailQueryVariables>(
		BillingSetupSubmissionDetailDocument,
		options,
	);
}
export type BillingSetupSubmissionDetailQueryHookResult = ReturnType<typeof useBillingSetupSubmissionDetailQuery>;
export type BillingSetupSubmissionDetailLazyQueryHookResult = ReturnType<
	typeof useBillingSetupSubmissionDetailLazyQuery
>;
export type BillingSetupSubmissionDetailQueryResult = Apollo.QueryResult<
	BillingSetupSubmissionDetailQuery,
	BillingSetupSubmissionDetailQueryVariables
>;
export const BillingSetupSubmissionIndexDocument = gql`
	query billingSetupSubmissionIndex(
		$filters: [BillingSetupSubmissionFilterInput!]
		$pagination: Pagination
		$order: BillingSetupSubmissionOrder
	) {
		billingSetupSubmissionConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					numPayments
					isInterestedInBusPlan
					isInterestedInMealPlan
					authorName
					authorEmail
					authorPhone
				}
			}
		}
	}
`;

/**
 * __useBillingSetupSubmissionIndexQuery__
 *
 * To run a query within a React component, call `useBillingSetupSubmissionIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillingSetupSubmissionIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingSetupSubmissionIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useBillingSetupSubmissionIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<BillingSetupSubmissionIndexQuery, BillingSetupSubmissionIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<BillingSetupSubmissionIndexQuery, BillingSetupSubmissionIndexQueryVariables>(
		BillingSetupSubmissionIndexDocument,
		options,
	);
}
export function useBillingSetupSubmissionIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		BillingSetupSubmissionIndexQuery,
		BillingSetupSubmissionIndexQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<BillingSetupSubmissionIndexQuery, BillingSetupSubmissionIndexQueryVariables>(
		BillingSetupSubmissionIndexDocument,
		options,
	);
}
export type BillingSetupSubmissionIndexQueryHookResult = ReturnType<typeof useBillingSetupSubmissionIndexQuery>;
export type BillingSetupSubmissionIndexLazyQueryHookResult = ReturnType<typeof useBillingSetupSubmissionIndexLazyQuery>;
export type BillingSetupSubmissionIndexQueryResult = Apollo.QueryResult<
	BillingSetupSubmissionIndexQuery,
	BillingSetupSubmissionIndexQueryVariables
>;
export const BillingSetupSubmissionNameDocument = gql`
	query billingSetupSubmissionName($id: ID!) {
		billingSetupSubmission(id: $id) {
			id
			...BillingSetupSubmissionName
		}
	}
	${BillingSetupSubmissionNameFragmentDoc}
`;

/**
 * __useBillingSetupSubmissionNameQuery__
 *
 * To run a query within a React component, call `useBillingSetupSubmissionNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillingSetupSubmissionNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingSetupSubmissionNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBillingSetupSubmissionNameQuery(
	baseOptions: Apollo.QueryHookOptions<BillingSetupSubmissionNameQuery, BillingSetupSubmissionNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<BillingSetupSubmissionNameQuery, BillingSetupSubmissionNameQueryVariables>(
		BillingSetupSubmissionNameDocument,
		options,
	);
}
export function useBillingSetupSubmissionNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<BillingSetupSubmissionNameQuery, BillingSetupSubmissionNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<BillingSetupSubmissionNameQuery, BillingSetupSubmissionNameQueryVariables>(
		BillingSetupSubmissionNameDocument,
		options,
	);
}
export type BillingSetupSubmissionNameQueryHookResult = ReturnType<typeof useBillingSetupSubmissionNameQuery>;
export type BillingSetupSubmissionNameLazyQueryHookResult = ReturnType<typeof useBillingSetupSubmissionNameLazyQuery>;
export type BillingSetupSubmissionNameQueryResult = Apollo.QueryResult<
	BillingSetupSubmissionNameQuery,
	BillingSetupSubmissionNameQueryVariables
>;
export const BillingSetupSubmissionNamesDocument = gql`
	query billingSetupSubmissionNames(
		$filters: [BillingSetupSubmissionFilterInput!]
		$pagination: Pagination
		$order: BillingSetupSubmissionOrder
	) {
		billingSetupSubmissionConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...BillingSetupSubmissionName
				}
			}
		}
	}
	${BillingSetupSubmissionNameFragmentDoc}
`;

/**
 * __useBillingSetupSubmissionNamesQuery__
 *
 * To run a query within a React component, call `useBillingSetupSubmissionNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillingSetupSubmissionNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingSetupSubmissionNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useBillingSetupSubmissionNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<BillingSetupSubmissionNamesQuery, BillingSetupSubmissionNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<BillingSetupSubmissionNamesQuery, BillingSetupSubmissionNamesQueryVariables>(
		BillingSetupSubmissionNamesDocument,
		options,
	);
}
export function useBillingSetupSubmissionNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		BillingSetupSubmissionNamesQuery,
		BillingSetupSubmissionNamesQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<BillingSetupSubmissionNamesQuery, BillingSetupSubmissionNamesQueryVariables>(
		BillingSetupSubmissionNamesDocument,
		options,
	);
}
export type BillingSetupSubmissionNamesQueryHookResult = ReturnType<typeof useBillingSetupSubmissionNamesQuery>;
export type BillingSetupSubmissionNamesLazyQueryHookResult = ReturnType<typeof useBillingSetupSubmissionNamesLazyQuery>;
export type BillingSetupSubmissionNamesQueryResult = Apollo.QueryResult<
	BillingSetupSubmissionNamesQuery,
	BillingSetupSubmissionNamesQueryVariables
>;
export const BillingSetupSubmissionPaginationDocument = gql`
	query billingSetupSubmissionPagination(
		$filters: [BillingSetupSubmissionFilterInput!]
		$order: BillingSetupSubmissionOrder
	) {
		billingSetupSubmissionConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useBillingSetupSubmissionPaginationQuery__
 *
 * To run a query within a React component, call `useBillingSetupSubmissionPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillingSetupSubmissionPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingSetupSubmissionPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useBillingSetupSubmissionPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<
		BillingSetupSubmissionPaginationQuery,
		BillingSetupSubmissionPaginationQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<BillingSetupSubmissionPaginationQuery, BillingSetupSubmissionPaginationQueryVariables>(
		BillingSetupSubmissionPaginationDocument,
		options,
	);
}
export function useBillingSetupSubmissionPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		BillingSetupSubmissionPaginationQuery,
		BillingSetupSubmissionPaginationQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<BillingSetupSubmissionPaginationQuery, BillingSetupSubmissionPaginationQueryVariables>(
		BillingSetupSubmissionPaginationDocument,
		options,
	);
}
export type BillingSetupSubmissionPaginationQueryHookResult = ReturnType<
	typeof useBillingSetupSubmissionPaginationQuery
>;
export type BillingSetupSubmissionPaginationLazyQueryHookResult = ReturnType<
	typeof useBillingSetupSubmissionPaginationLazyQuery
>;
export type BillingSetupSubmissionPaginationQueryResult = Apollo.QueryResult<
	BillingSetupSubmissionPaginationQuery,
	BillingSetupSubmissionPaginationQueryVariables
>;
export const BillingSetupSubmissionSelectDocument = gql`
	query billingSetupSubmissionSelect(
		$filters: [BillingSetupSubmissionFilterInput!]
		$order: BillingSetupSubmissionOrder
	) {
		billingSetupSubmissionConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...BillingSetupSubmissionName
				}
			}
		}
	}
	${BillingSetupSubmissionNameFragmentDoc}
`;

/**
 * __useBillingSetupSubmissionSelectQuery__
 *
 * To run a query within a React component, call `useBillingSetupSubmissionSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillingSetupSubmissionSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingSetupSubmissionSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useBillingSetupSubmissionSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<BillingSetupSubmissionSelectQuery, BillingSetupSubmissionSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<BillingSetupSubmissionSelectQuery, BillingSetupSubmissionSelectQueryVariables>(
		BillingSetupSubmissionSelectDocument,
		options,
	);
}
export function useBillingSetupSubmissionSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		BillingSetupSubmissionSelectQuery,
		BillingSetupSubmissionSelectQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<BillingSetupSubmissionSelectQuery, BillingSetupSubmissionSelectQueryVariables>(
		BillingSetupSubmissionSelectDocument,
		options,
	);
}
export type BillingSetupSubmissionSelectQueryHookResult = ReturnType<typeof useBillingSetupSubmissionSelectQuery>;
export type BillingSetupSubmissionSelectLazyQueryHookResult = ReturnType<
	typeof useBillingSetupSubmissionSelectLazyQuery
>;
export type BillingSetupSubmissionSelectQueryResult = Apollo.QueryResult<
	BillingSetupSubmissionSelectQuery,
	BillingSetupSubmissionSelectQueryVariables
>;
export const BillingSetupSubmissionUpdateDocument = gql`
	mutation billingSetupSubmissionUpdate($id: ID!, $input: BillingSetupSubmissionUpdateInput!) {
		updateBillingSetupSubmission(id: $id, input: $input) {
			...BillingSetupSubmissionDetail
		}
	}
	${BillingSetupSubmissionDetailFragmentDoc}
`;
export type BillingSetupSubmissionUpdateMutationFn = Apollo.MutationFunction<
	BillingSetupSubmissionUpdateMutation,
	BillingSetupSubmissionUpdateMutationVariables
>;

/**
 * __useBillingSetupSubmissionUpdateMutation__
 *
 * To run a mutation, you first call `useBillingSetupSubmissionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBillingSetupSubmissionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [billingSetupSubmissionUpdateMutation, { data, loading, error }] = useBillingSetupSubmissionUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBillingSetupSubmissionUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<
		BillingSetupSubmissionUpdateMutation,
		BillingSetupSubmissionUpdateMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<BillingSetupSubmissionUpdateMutation, BillingSetupSubmissionUpdateMutationVariables>(
		BillingSetupSubmissionUpdateDocument,
		options,
	);
}
export type BillingSetupSubmissionUpdateMutationHookResult = ReturnType<typeof useBillingSetupSubmissionUpdateMutation>;
export type BillingSetupSubmissionUpdateMutationResult = Apollo.MutationResult<BillingSetupSubmissionUpdateMutation>;
export type BillingSetupSubmissionUpdateMutationOptions = Apollo.BaseMutationOptions<
	BillingSetupSubmissionUpdateMutation,
	BillingSetupSubmissionUpdateMutationVariables
>;
export const BusCountDocument = gql`
	query busCount($filters: [BusFilterInput!], $pagination: Pagination, $order: BusOrder) {
		busConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useBusCountQuery__
 *
 * To run a query within a React component, call `useBusCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useBusCountQuery(baseOptions?: Apollo.QueryHookOptions<BusCountQuery, BusCountQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<BusCountQuery, BusCountQueryVariables>(BusCountDocument, options);
}
export function useBusCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusCountQuery, BusCountQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<BusCountQuery, BusCountQueryVariables>(BusCountDocument, options);
}
export type BusCountQueryHookResult = ReturnType<typeof useBusCountQuery>;
export type BusCountLazyQueryHookResult = ReturnType<typeof useBusCountLazyQuery>;
export type BusCountQueryResult = Apollo.QueryResult<BusCountQuery, BusCountQueryVariables>;
export const BusCreateDocument = gql`
	mutation busCreate($input: BusCreateInput!) {
		createBus(input: $input) {
			...BusDetail
		}
	}
	${BusDetailFragmentDoc}
`;
export type BusCreateMutationFn = Apollo.MutationFunction<BusCreateMutation, BusCreateMutationVariables>;

/**
 * __useBusCreateMutation__
 *
 * To run a mutation, you first call `useBusCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBusCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [busCreateMutation, { data, loading, error }] = useBusCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBusCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<BusCreateMutation, BusCreateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<BusCreateMutation, BusCreateMutationVariables>(BusCreateDocument, options);
}
export type BusCreateMutationHookResult = ReturnType<typeof useBusCreateMutation>;
export type BusCreateMutationResult = Apollo.MutationResult<BusCreateMutation>;
export type BusCreateMutationOptions = Apollo.BaseMutationOptions<BusCreateMutation, BusCreateMutationVariables>;
export const BusDeleteDocument = gql`
	mutation busDelete($id: ID!) {
		deleteBus(id: $id)
	}
`;
export type BusDeleteMutationFn = Apollo.MutationFunction<BusDeleteMutation, BusDeleteMutationVariables>;

/**
 * __useBusDeleteMutation__
 *
 * To run a mutation, you first call `useBusDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBusDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [busDeleteMutation, { data, loading, error }] = useBusDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBusDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<BusDeleteMutation, BusDeleteMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<BusDeleteMutation, BusDeleteMutationVariables>(BusDeleteDocument, options);
}
export type BusDeleteMutationHookResult = ReturnType<typeof useBusDeleteMutation>;
export type BusDeleteMutationResult = Apollo.MutationResult<BusDeleteMutation>;
export type BusDeleteMutationOptions = Apollo.BaseMutationOptions<BusDeleteMutation, BusDeleteMutationVariables>;
export const BusDetailDocument = gql`
	query busDetail($id: ID!) {
		bus(id: $id) {
			...BusDetail
		}
	}
	${BusDetailFragmentDoc}
`;

/**
 * __useBusDetailQuery__
 *
 * To run a query within a React component, call `useBusDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBusDetailQuery(baseOptions: Apollo.QueryHookOptions<BusDetailQuery, BusDetailQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<BusDetailQuery, BusDetailQueryVariables>(BusDetailDocument, options);
}
export function useBusDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<BusDetailQuery, BusDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<BusDetailQuery, BusDetailQueryVariables>(BusDetailDocument, options);
}
export type BusDetailQueryHookResult = ReturnType<typeof useBusDetailQuery>;
export type BusDetailLazyQueryHookResult = ReturnType<typeof useBusDetailLazyQuery>;
export type BusDetailQueryResult = Apollo.QueryResult<BusDetailQuery, BusDetailQueryVariables>;
export const BusIndexDocument = gql`
	query busIndex($filters: [BusFilterInput!], $pagination: Pagination, $order: BusOrder) {
		busConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					name
					description
				}
			}
		}
	}
`;

/**
 * __useBusIndexQuery__
 *
 * To run a query within a React component, call `useBusIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useBusIndexQuery(baseOptions?: Apollo.QueryHookOptions<BusIndexQuery, BusIndexQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<BusIndexQuery, BusIndexQueryVariables>(BusIndexDocument, options);
}
export function useBusIndexLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusIndexQuery, BusIndexQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<BusIndexQuery, BusIndexQueryVariables>(BusIndexDocument, options);
}
export type BusIndexQueryHookResult = ReturnType<typeof useBusIndexQuery>;
export type BusIndexLazyQueryHookResult = ReturnType<typeof useBusIndexLazyQuery>;
export type BusIndexQueryResult = Apollo.QueryResult<BusIndexQuery, BusIndexQueryVariables>;
export const BusNameDocument = gql`
	query busName($id: ID!) {
		bus(id: $id) {
			id
			...BusName
		}
	}
	${BusNameFragmentDoc}
`;

/**
 * __useBusNameQuery__
 *
 * To run a query within a React component, call `useBusNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBusNameQuery(baseOptions: Apollo.QueryHookOptions<BusNameQuery, BusNameQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<BusNameQuery, BusNameQueryVariables>(BusNameDocument, options);
}
export function useBusNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusNameQuery, BusNameQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<BusNameQuery, BusNameQueryVariables>(BusNameDocument, options);
}
export type BusNameQueryHookResult = ReturnType<typeof useBusNameQuery>;
export type BusNameLazyQueryHookResult = ReturnType<typeof useBusNameLazyQuery>;
export type BusNameQueryResult = Apollo.QueryResult<BusNameQuery, BusNameQueryVariables>;
export const BusNamesDocument = gql`
	query busNames($filters: [BusFilterInput!], $pagination: Pagination, $order: BusOrder) {
		busConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...BusName
				}
			}
		}
	}
	${BusNameFragmentDoc}
`;

/**
 * __useBusNamesQuery__
 *
 * To run a query within a React component, call `useBusNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useBusNamesQuery(baseOptions?: Apollo.QueryHookOptions<BusNamesQuery, BusNamesQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<BusNamesQuery, BusNamesQueryVariables>(BusNamesDocument, options);
}
export function useBusNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusNamesQuery, BusNamesQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<BusNamesQuery, BusNamesQueryVariables>(BusNamesDocument, options);
}
export type BusNamesQueryHookResult = ReturnType<typeof useBusNamesQuery>;
export type BusNamesLazyQueryHookResult = ReturnType<typeof useBusNamesLazyQuery>;
export type BusNamesQueryResult = Apollo.QueryResult<BusNamesQuery, BusNamesQueryVariables>;
export const BusPaginationDocument = gql`
	query busPagination($filters: [BusFilterInput!], $order: BusOrder) {
		busConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useBusPaginationQuery__
 *
 * To run a query within a React component, call `useBusPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useBusPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<BusPaginationQuery, BusPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<BusPaginationQuery, BusPaginationQueryVariables>(BusPaginationDocument, options);
}
export function useBusPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<BusPaginationQuery, BusPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<BusPaginationQuery, BusPaginationQueryVariables>(BusPaginationDocument, options);
}
export type BusPaginationQueryHookResult = ReturnType<typeof useBusPaginationQuery>;
export type BusPaginationLazyQueryHookResult = ReturnType<typeof useBusPaginationLazyQuery>;
export type BusPaginationQueryResult = Apollo.QueryResult<BusPaginationQuery, BusPaginationQueryVariables>;
export const BusSelectDocument = gql`
	query busSelect($filters: [BusFilterInput!], $order: BusOrder) {
		busConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...BusName
				}
			}
		}
	}
	${BusNameFragmentDoc}
`;

/**
 * __useBusSelectQuery__
 *
 * To run a query within a React component, call `useBusSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useBusSelectQuery(baseOptions?: Apollo.QueryHookOptions<BusSelectQuery, BusSelectQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<BusSelectQuery, BusSelectQueryVariables>(BusSelectDocument, options);
}
export function useBusSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<BusSelectQuery, BusSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<BusSelectQuery, BusSelectQueryVariables>(BusSelectDocument, options);
}
export type BusSelectQueryHookResult = ReturnType<typeof useBusSelectQuery>;
export type BusSelectLazyQueryHookResult = ReturnType<typeof useBusSelectLazyQuery>;
export type BusSelectQueryResult = Apollo.QueryResult<BusSelectQuery, BusSelectQueryVariables>;
export const BusUpdateDocument = gql`
	mutation busUpdate($id: ID!, $input: BusUpdateInput!) {
		updateBus(id: $id, input: $input) {
			...BusDetail
		}
	}
	${BusDetailFragmentDoc}
`;
export type BusUpdateMutationFn = Apollo.MutationFunction<BusUpdateMutation, BusUpdateMutationVariables>;

/**
 * __useBusUpdateMutation__
 *
 * To run a mutation, you first call `useBusUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBusUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [busUpdateMutation, { data, loading, error }] = useBusUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBusUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<BusUpdateMutation, BusUpdateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<BusUpdateMutation, BusUpdateMutationVariables>(BusUpdateDocument, options);
}
export type BusUpdateMutationHookResult = ReturnType<typeof useBusUpdateMutation>;
export type BusUpdateMutationResult = Apollo.MutationResult<BusUpdateMutation>;
export type BusUpdateMutationOptions = Apollo.BaseMutationOptions<BusUpdateMutation, BusUpdateMutationVariables>;
export const BusPlanPickupBusListDocument = gql`
	query busPlanPickupBusList {
		busPlanConnection(filters: { hasPickupBus: true }, order: { field: PICKUP_TIME_MINUTES, direction: ASC }) {
			edges {
				node {
					id
					student {
						id
						person {
							id
							name
						}
						homeRoomSectionStudentEnrollments {
							id
							homeRoomSection {
								id
								name
							}
						}
					}
					pickupBus {
						id
						name
					}
					pickupTimeMinutes
					pickupLocation
				}
			}
		}
	}
`;

/**
 * __useBusPlanPickupBusListQuery__
 *
 * To run a query within a React component, call `useBusPlanPickupBusListQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusPlanPickupBusListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusPlanPickupBusListQuery({
 *   variables: {
 *   },
 * });
 */
export function useBusPlanPickupBusListQuery(
	baseOptions?: Apollo.QueryHookOptions<BusPlanPickupBusListQuery, BusPlanPickupBusListQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<BusPlanPickupBusListQuery, BusPlanPickupBusListQueryVariables>(
		BusPlanPickupBusListDocument,
		options,
	);
}
export function useBusPlanPickupBusListLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<BusPlanPickupBusListQuery, BusPlanPickupBusListQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<BusPlanPickupBusListQuery, BusPlanPickupBusListQueryVariables>(
		BusPlanPickupBusListDocument,
		options,
	);
}
export type BusPlanPickupBusListQueryHookResult = ReturnType<typeof useBusPlanPickupBusListQuery>;
export type BusPlanPickupBusListLazyQueryHookResult = ReturnType<typeof useBusPlanPickupBusListLazyQuery>;
export type BusPlanPickupBusListQueryResult = Apollo.QueryResult<
	BusPlanPickupBusListQuery,
	BusPlanPickupBusListQueryVariables
>;
export const BusPlanDropoffBusListDocument = gql`
	query busPlanDropoffBusList {
		busPlanConnection(filters: { hasDropoffBus: true }, order: { field: DROPOFF_TIME_MINUTES, direction: ASC }) {
			edges {
				node {
					id
					student {
						id
						person {
							id
							name
						}
						homeRoomSectionStudentEnrollments {
							id
							homeRoomSection {
								id
								name
							}
						}
					}
					dropoffBus {
						id
						name
					}
					dropoffTimeMinutes
					dropoffLocation
				}
			}
		}
	}
`;

/**
 * __useBusPlanDropoffBusListQuery__
 *
 * To run a query within a React component, call `useBusPlanDropoffBusListQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusPlanDropoffBusListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusPlanDropoffBusListQuery({
 *   variables: {
 *   },
 * });
 */
export function useBusPlanDropoffBusListQuery(
	baseOptions?: Apollo.QueryHookOptions<BusPlanDropoffBusListQuery, BusPlanDropoffBusListQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<BusPlanDropoffBusListQuery, BusPlanDropoffBusListQueryVariables>(
		BusPlanDropoffBusListDocument,
		options,
	);
}
export function useBusPlanDropoffBusListLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<BusPlanDropoffBusListQuery, BusPlanDropoffBusListQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<BusPlanDropoffBusListQuery, BusPlanDropoffBusListQueryVariables>(
		BusPlanDropoffBusListDocument,
		options,
	);
}
export type BusPlanDropoffBusListQueryHookResult = ReturnType<typeof useBusPlanDropoffBusListQuery>;
export type BusPlanDropoffBusListLazyQueryHookResult = ReturnType<typeof useBusPlanDropoffBusListLazyQuery>;
export type BusPlanDropoffBusListQueryResult = Apollo.QueryResult<
	BusPlanDropoffBusListQuery,
	BusPlanDropoffBusListQueryVariables
>;
export const BusPlanCountDocument = gql`
	query busPlanCount($filters: [BusPlanFilterInput!], $pagination: Pagination, $order: BusPlanOrder) {
		busPlanConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useBusPlanCountQuery__
 *
 * To run a query within a React component, call `useBusPlanCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusPlanCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusPlanCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useBusPlanCountQuery(
	baseOptions?: Apollo.QueryHookOptions<BusPlanCountQuery, BusPlanCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<BusPlanCountQuery, BusPlanCountQueryVariables>(BusPlanCountDocument, options);
}
export function useBusPlanCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<BusPlanCountQuery, BusPlanCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<BusPlanCountQuery, BusPlanCountQueryVariables>(BusPlanCountDocument, options);
}
export type BusPlanCountQueryHookResult = ReturnType<typeof useBusPlanCountQuery>;
export type BusPlanCountLazyQueryHookResult = ReturnType<typeof useBusPlanCountLazyQuery>;
export type BusPlanCountQueryResult = Apollo.QueryResult<BusPlanCountQuery, BusPlanCountQueryVariables>;
export const BusPlanCreateDocument = gql`
	mutation busPlanCreate($input: BusPlanCreateInput!) {
		createBusPlan(input: $input) {
			...BusPlanDetail
		}
	}
	${BusPlanDetailFragmentDoc}
`;
export type BusPlanCreateMutationFn = Apollo.MutationFunction<BusPlanCreateMutation, BusPlanCreateMutationVariables>;

/**
 * __useBusPlanCreateMutation__
 *
 * To run a mutation, you first call `useBusPlanCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBusPlanCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [busPlanCreateMutation, { data, loading, error }] = useBusPlanCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBusPlanCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<BusPlanCreateMutation, BusPlanCreateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<BusPlanCreateMutation, BusPlanCreateMutationVariables>(BusPlanCreateDocument, options);
}
export type BusPlanCreateMutationHookResult = ReturnType<typeof useBusPlanCreateMutation>;
export type BusPlanCreateMutationResult = Apollo.MutationResult<BusPlanCreateMutation>;
export type BusPlanCreateMutationOptions = Apollo.BaseMutationOptions<
	BusPlanCreateMutation,
	BusPlanCreateMutationVariables
>;
export const BusPlanDeleteDocument = gql`
	mutation busPlanDelete($id: ID!) {
		deleteBusPlan(id: $id)
	}
`;
export type BusPlanDeleteMutationFn = Apollo.MutationFunction<BusPlanDeleteMutation, BusPlanDeleteMutationVariables>;

/**
 * __useBusPlanDeleteMutation__
 *
 * To run a mutation, you first call `useBusPlanDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBusPlanDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [busPlanDeleteMutation, { data, loading, error }] = useBusPlanDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBusPlanDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<BusPlanDeleteMutation, BusPlanDeleteMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<BusPlanDeleteMutation, BusPlanDeleteMutationVariables>(BusPlanDeleteDocument, options);
}
export type BusPlanDeleteMutationHookResult = ReturnType<typeof useBusPlanDeleteMutation>;
export type BusPlanDeleteMutationResult = Apollo.MutationResult<BusPlanDeleteMutation>;
export type BusPlanDeleteMutationOptions = Apollo.BaseMutationOptions<
	BusPlanDeleteMutation,
	BusPlanDeleteMutationVariables
>;
export const BusPlanDetailDocument = gql`
	query busPlanDetail($id: ID!) {
		busPlan(id: $id) {
			...BusPlanDetail
		}
	}
	${BusPlanDetailFragmentDoc}
`;

/**
 * __useBusPlanDetailQuery__
 *
 * To run a query within a React component, call `useBusPlanDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusPlanDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusPlanDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBusPlanDetailQuery(
	baseOptions: Apollo.QueryHookOptions<BusPlanDetailQuery, BusPlanDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<BusPlanDetailQuery, BusPlanDetailQueryVariables>(BusPlanDetailDocument, options);
}
export function useBusPlanDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<BusPlanDetailQuery, BusPlanDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<BusPlanDetailQuery, BusPlanDetailQueryVariables>(BusPlanDetailDocument, options);
}
export type BusPlanDetailQueryHookResult = ReturnType<typeof useBusPlanDetailQuery>;
export type BusPlanDetailLazyQueryHookResult = ReturnType<typeof useBusPlanDetailLazyQuery>;
export type BusPlanDetailQueryResult = Apollo.QueryResult<BusPlanDetailQuery, BusPlanDetailQueryVariables>;
export const BusPlanIndexDocument = gql`
	query busPlanIndex($filters: [BusPlanFilterInput!], $pagination: Pagination, $order: BusPlanOrder) {
		busPlanConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					student {
						id
						person {
							id
							name
						}
					}
					hasPickup
					pickupBus {
						id
						name
					}
					pickupTimeMinutes
					pickupLocation
					hasDropoff
					dropoffBus {
						id
						name
					}
					dropoffTimeMinutes
					dropoffLocation
				}
			}
		}
	}
`;

/**
 * __useBusPlanIndexQuery__
 *
 * To run a query within a React component, call `useBusPlanIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusPlanIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusPlanIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useBusPlanIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<BusPlanIndexQuery, BusPlanIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<BusPlanIndexQuery, BusPlanIndexQueryVariables>(BusPlanIndexDocument, options);
}
export function useBusPlanIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<BusPlanIndexQuery, BusPlanIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<BusPlanIndexQuery, BusPlanIndexQueryVariables>(BusPlanIndexDocument, options);
}
export type BusPlanIndexQueryHookResult = ReturnType<typeof useBusPlanIndexQuery>;
export type BusPlanIndexLazyQueryHookResult = ReturnType<typeof useBusPlanIndexLazyQuery>;
export type BusPlanIndexQueryResult = Apollo.QueryResult<BusPlanIndexQuery, BusPlanIndexQueryVariables>;
export const BusPlanNameDocument = gql`
	query busPlanName($id: ID!) {
		busPlan(id: $id) {
			id
			...BusPlanName
		}
	}
	${BusPlanNameFragmentDoc}
`;

/**
 * __useBusPlanNameQuery__
 *
 * To run a query within a React component, call `useBusPlanNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusPlanNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusPlanNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBusPlanNameQuery(baseOptions: Apollo.QueryHookOptions<BusPlanNameQuery, BusPlanNameQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<BusPlanNameQuery, BusPlanNameQueryVariables>(BusPlanNameDocument, options);
}
export function useBusPlanNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<BusPlanNameQuery, BusPlanNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<BusPlanNameQuery, BusPlanNameQueryVariables>(BusPlanNameDocument, options);
}
export type BusPlanNameQueryHookResult = ReturnType<typeof useBusPlanNameQuery>;
export type BusPlanNameLazyQueryHookResult = ReturnType<typeof useBusPlanNameLazyQuery>;
export type BusPlanNameQueryResult = Apollo.QueryResult<BusPlanNameQuery, BusPlanNameQueryVariables>;
export const BusPlanNamesDocument = gql`
	query busPlanNames($filters: [BusPlanFilterInput!], $pagination: Pagination, $order: BusPlanOrder) {
		busPlanConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...BusPlanName
				}
			}
		}
	}
	${BusPlanNameFragmentDoc}
`;

/**
 * __useBusPlanNamesQuery__
 *
 * To run a query within a React component, call `useBusPlanNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusPlanNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusPlanNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useBusPlanNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<BusPlanNamesQuery, BusPlanNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<BusPlanNamesQuery, BusPlanNamesQueryVariables>(BusPlanNamesDocument, options);
}
export function useBusPlanNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<BusPlanNamesQuery, BusPlanNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<BusPlanNamesQuery, BusPlanNamesQueryVariables>(BusPlanNamesDocument, options);
}
export type BusPlanNamesQueryHookResult = ReturnType<typeof useBusPlanNamesQuery>;
export type BusPlanNamesLazyQueryHookResult = ReturnType<typeof useBusPlanNamesLazyQuery>;
export type BusPlanNamesQueryResult = Apollo.QueryResult<BusPlanNamesQuery, BusPlanNamesQueryVariables>;
export const BusPlanPaginationDocument = gql`
	query busPlanPagination($filters: [BusPlanFilterInput!], $order: BusPlanOrder) {
		busPlanConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useBusPlanPaginationQuery__
 *
 * To run a query within a React component, call `useBusPlanPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusPlanPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusPlanPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useBusPlanPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<BusPlanPaginationQuery, BusPlanPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<BusPlanPaginationQuery, BusPlanPaginationQueryVariables>(BusPlanPaginationDocument, options);
}
export function useBusPlanPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<BusPlanPaginationQuery, BusPlanPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<BusPlanPaginationQuery, BusPlanPaginationQueryVariables>(
		BusPlanPaginationDocument,
		options,
	);
}
export type BusPlanPaginationQueryHookResult = ReturnType<typeof useBusPlanPaginationQuery>;
export type BusPlanPaginationLazyQueryHookResult = ReturnType<typeof useBusPlanPaginationLazyQuery>;
export type BusPlanPaginationQueryResult = Apollo.QueryResult<BusPlanPaginationQuery, BusPlanPaginationQueryVariables>;
export const BusPlanSelectDocument = gql`
	query busPlanSelect($filters: [BusPlanFilterInput!], $order: BusPlanOrder) {
		busPlanConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...BusPlanName
				}
			}
		}
	}
	${BusPlanNameFragmentDoc}
`;

/**
 * __useBusPlanSelectQuery__
 *
 * To run a query within a React component, call `useBusPlanSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusPlanSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusPlanSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useBusPlanSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<BusPlanSelectQuery, BusPlanSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<BusPlanSelectQuery, BusPlanSelectQueryVariables>(BusPlanSelectDocument, options);
}
export function useBusPlanSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<BusPlanSelectQuery, BusPlanSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<BusPlanSelectQuery, BusPlanSelectQueryVariables>(BusPlanSelectDocument, options);
}
export type BusPlanSelectQueryHookResult = ReturnType<typeof useBusPlanSelectQuery>;
export type BusPlanSelectLazyQueryHookResult = ReturnType<typeof useBusPlanSelectLazyQuery>;
export type BusPlanSelectQueryResult = Apollo.QueryResult<BusPlanSelectQuery, BusPlanSelectQueryVariables>;
export const BusPlanUpdateDocument = gql`
	mutation busPlanUpdate($id: ID!, $input: BusPlanUpdateInput!) {
		updateBusPlan(id: $id, input: $input) {
			...BusPlanDetail
		}
	}
	${BusPlanDetailFragmentDoc}
`;
export type BusPlanUpdateMutationFn = Apollo.MutationFunction<BusPlanUpdateMutation, BusPlanUpdateMutationVariables>;

/**
 * __useBusPlanUpdateMutation__
 *
 * To run a mutation, you first call `useBusPlanUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBusPlanUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [busPlanUpdateMutation, { data, loading, error }] = useBusPlanUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBusPlanUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<BusPlanUpdateMutation, BusPlanUpdateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<BusPlanUpdateMutation, BusPlanUpdateMutationVariables>(BusPlanUpdateDocument, options);
}
export type BusPlanUpdateMutationHookResult = ReturnType<typeof useBusPlanUpdateMutation>;
export type BusPlanUpdateMutationResult = Apollo.MutationResult<BusPlanUpdateMutation>;
export type BusPlanUpdateMutationOptions = Apollo.BaseMutationOptions<
	BusPlanUpdateMutation,
	BusPlanUpdateMutationVariables
>;
export const CalendarEventCountDocument = gql`
	query calendarEventCount($filters: [CalendarEventFilterInput!], $pagination: Pagination, $order: CalendarEventOrder) {
		calendarEventConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useCalendarEventCountQuery__
 *
 * To run a query within a React component, call `useCalendarEventCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarEventCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarEventCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useCalendarEventCountQuery(
	baseOptions?: Apollo.QueryHookOptions<CalendarEventCountQuery, CalendarEventCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<CalendarEventCountQuery, CalendarEventCountQueryVariables>(
		CalendarEventCountDocument,
		options,
	);
}
export function useCalendarEventCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<CalendarEventCountQuery, CalendarEventCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<CalendarEventCountQuery, CalendarEventCountQueryVariables>(
		CalendarEventCountDocument,
		options,
	);
}
export type CalendarEventCountQueryHookResult = ReturnType<typeof useCalendarEventCountQuery>;
export type CalendarEventCountLazyQueryHookResult = ReturnType<typeof useCalendarEventCountLazyQuery>;
export type CalendarEventCountQueryResult = Apollo.QueryResult<
	CalendarEventCountQuery,
	CalendarEventCountQueryVariables
>;
export const CalendarEventCreateDocument = gql`
	mutation calendarEventCreate($input: CalendarEventCreateInput!) {
		createCalendarEvent(input: $input) {
			...CalendarEventDetail
		}
	}
	${CalendarEventDetailFragmentDoc}
`;
export type CalendarEventCreateMutationFn = Apollo.MutationFunction<
	CalendarEventCreateMutation,
	CalendarEventCreateMutationVariables
>;

/**
 * __useCalendarEventCreateMutation__
 *
 * To run a mutation, you first call `useCalendarEventCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCalendarEventCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [calendarEventCreateMutation, { data, loading, error }] = useCalendarEventCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCalendarEventCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<CalendarEventCreateMutation, CalendarEventCreateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<CalendarEventCreateMutation, CalendarEventCreateMutationVariables>(
		CalendarEventCreateDocument,
		options,
	);
}
export type CalendarEventCreateMutationHookResult = ReturnType<typeof useCalendarEventCreateMutation>;
export type CalendarEventCreateMutationResult = Apollo.MutationResult<CalendarEventCreateMutation>;
export type CalendarEventCreateMutationOptions = Apollo.BaseMutationOptions<
	CalendarEventCreateMutation,
	CalendarEventCreateMutationVariables
>;
export const CalendarEventDeleteDocument = gql`
	mutation calendarEventDelete($id: ID!) {
		deleteCalendarEvent(id: $id)
	}
`;
export type CalendarEventDeleteMutationFn = Apollo.MutationFunction<
	CalendarEventDeleteMutation,
	CalendarEventDeleteMutationVariables
>;

/**
 * __useCalendarEventDeleteMutation__
 *
 * To run a mutation, you first call `useCalendarEventDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCalendarEventDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [calendarEventDeleteMutation, { data, loading, error }] = useCalendarEventDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCalendarEventDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<CalendarEventDeleteMutation, CalendarEventDeleteMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<CalendarEventDeleteMutation, CalendarEventDeleteMutationVariables>(
		CalendarEventDeleteDocument,
		options,
	);
}
export type CalendarEventDeleteMutationHookResult = ReturnType<typeof useCalendarEventDeleteMutation>;
export type CalendarEventDeleteMutationResult = Apollo.MutationResult<CalendarEventDeleteMutation>;
export type CalendarEventDeleteMutationOptions = Apollo.BaseMutationOptions<
	CalendarEventDeleteMutation,
	CalendarEventDeleteMutationVariables
>;
export const CalendarEventDetailDocument = gql`
	query calendarEventDetail($id: ID!) {
		calendarEvent(id: $id) {
			...CalendarEventDetail
		}
	}
	${CalendarEventDetailFragmentDoc}
`;

/**
 * __useCalendarEventDetailQuery__
 *
 * To run a query within a React component, call `useCalendarEventDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarEventDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarEventDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCalendarEventDetailQuery(
	baseOptions: Apollo.QueryHookOptions<CalendarEventDetailQuery, CalendarEventDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<CalendarEventDetailQuery, CalendarEventDetailQueryVariables>(
		CalendarEventDetailDocument,
		options,
	);
}
export function useCalendarEventDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<CalendarEventDetailQuery, CalendarEventDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<CalendarEventDetailQuery, CalendarEventDetailQueryVariables>(
		CalendarEventDetailDocument,
		options,
	);
}
export type CalendarEventDetailQueryHookResult = ReturnType<typeof useCalendarEventDetailQuery>;
export type CalendarEventDetailLazyQueryHookResult = ReturnType<typeof useCalendarEventDetailLazyQuery>;
export type CalendarEventDetailQueryResult = Apollo.QueryResult<
	CalendarEventDetailQuery,
	CalendarEventDetailQueryVariables
>;
export const CalendarEventIndexDocument = gql`
	query calendarEventIndex($filters: [CalendarEventFilterInput!], $pagination: Pagination, $order: CalendarEventOrder) {
		calendarEventConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					name
					description
					isAllDay
					startTime
					endTime
					isPublic
					createdAt
					sharingUserGroups {
						id
						name
						color
					}
				}
			}
		}
	}
`;

/**
 * __useCalendarEventIndexQuery__
 *
 * To run a query within a React component, call `useCalendarEventIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarEventIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarEventIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useCalendarEventIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<CalendarEventIndexQuery, CalendarEventIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<CalendarEventIndexQuery, CalendarEventIndexQueryVariables>(
		CalendarEventIndexDocument,
		options,
	);
}
export function useCalendarEventIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<CalendarEventIndexQuery, CalendarEventIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<CalendarEventIndexQuery, CalendarEventIndexQueryVariables>(
		CalendarEventIndexDocument,
		options,
	);
}
export type CalendarEventIndexQueryHookResult = ReturnType<typeof useCalendarEventIndexQuery>;
export type CalendarEventIndexLazyQueryHookResult = ReturnType<typeof useCalendarEventIndexLazyQuery>;
export type CalendarEventIndexQueryResult = Apollo.QueryResult<
	CalendarEventIndexQuery,
	CalendarEventIndexQueryVariables
>;
export const CalendarEventNameDocument = gql`
	query calendarEventName($id: ID!) {
		calendarEvent(id: $id) {
			id
			...CalendarEventName
		}
	}
	${CalendarEventNameFragmentDoc}
`;

/**
 * __useCalendarEventNameQuery__
 *
 * To run a query within a React component, call `useCalendarEventNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarEventNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarEventNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCalendarEventNameQuery(
	baseOptions: Apollo.QueryHookOptions<CalendarEventNameQuery, CalendarEventNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<CalendarEventNameQuery, CalendarEventNameQueryVariables>(CalendarEventNameDocument, options);
}
export function useCalendarEventNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<CalendarEventNameQuery, CalendarEventNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<CalendarEventNameQuery, CalendarEventNameQueryVariables>(
		CalendarEventNameDocument,
		options,
	);
}
export type CalendarEventNameQueryHookResult = ReturnType<typeof useCalendarEventNameQuery>;
export type CalendarEventNameLazyQueryHookResult = ReturnType<typeof useCalendarEventNameLazyQuery>;
export type CalendarEventNameQueryResult = Apollo.QueryResult<CalendarEventNameQuery, CalendarEventNameQueryVariables>;
export const CalendarEventNamesDocument = gql`
	query calendarEventNames($filters: [CalendarEventFilterInput!], $pagination: Pagination, $order: CalendarEventOrder) {
		calendarEventConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...CalendarEventName
				}
			}
		}
	}
	${CalendarEventNameFragmentDoc}
`;

/**
 * __useCalendarEventNamesQuery__
 *
 * To run a query within a React component, call `useCalendarEventNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarEventNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarEventNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useCalendarEventNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<CalendarEventNamesQuery, CalendarEventNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<CalendarEventNamesQuery, CalendarEventNamesQueryVariables>(
		CalendarEventNamesDocument,
		options,
	);
}
export function useCalendarEventNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<CalendarEventNamesQuery, CalendarEventNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<CalendarEventNamesQuery, CalendarEventNamesQueryVariables>(
		CalendarEventNamesDocument,
		options,
	);
}
export type CalendarEventNamesQueryHookResult = ReturnType<typeof useCalendarEventNamesQuery>;
export type CalendarEventNamesLazyQueryHookResult = ReturnType<typeof useCalendarEventNamesLazyQuery>;
export type CalendarEventNamesQueryResult = Apollo.QueryResult<
	CalendarEventNamesQuery,
	CalendarEventNamesQueryVariables
>;
export const CalendarEventPaginationDocument = gql`
	query calendarEventPagination($filters: [CalendarEventFilterInput!], $order: CalendarEventOrder) {
		calendarEventConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useCalendarEventPaginationQuery__
 *
 * To run a query within a React component, call `useCalendarEventPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarEventPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarEventPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useCalendarEventPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<CalendarEventPaginationQuery, CalendarEventPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<CalendarEventPaginationQuery, CalendarEventPaginationQueryVariables>(
		CalendarEventPaginationDocument,
		options,
	);
}
export function useCalendarEventPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<CalendarEventPaginationQuery, CalendarEventPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<CalendarEventPaginationQuery, CalendarEventPaginationQueryVariables>(
		CalendarEventPaginationDocument,
		options,
	);
}
export type CalendarEventPaginationQueryHookResult = ReturnType<typeof useCalendarEventPaginationQuery>;
export type CalendarEventPaginationLazyQueryHookResult = ReturnType<typeof useCalendarEventPaginationLazyQuery>;
export type CalendarEventPaginationQueryResult = Apollo.QueryResult<
	CalendarEventPaginationQuery,
	CalendarEventPaginationQueryVariables
>;
export const CalendarEventSelectDocument = gql`
	query calendarEventSelect($filters: [CalendarEventFilterInput!], $order: CalendarEventOrder) {
		calendarEventConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...CalendarEventName
				}
			}
		}
	}
	${CalendarEventNameFragmentDoc}
`;

/**
 * __useCalendarEventSelectQuery__
 *
 * To run a query within a React component, call `useCalendarEventSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarEventSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarEventSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useCalendarEventSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<CalendarEventSelectQuery, CalendarEventSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<CalendarEventSelectQuery, CalendarEventSelectQueryVariables>(
		CalendarEventSelectDocument,
		options,
	);
}
export function useCalendarEventSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<CalendarEventSelectQuery, CalendarEventSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<CalendarEventSelectQuery, CalendarEventSelectQueryVariables>(
		CalendarEventSelectDocument,
		options,
	);
}
export type CalendarEventSelectQueryHookResult = ReturnType<typeof useCalendarEventSelectQuery>;
export type CalendarEventSelectLazyQueryHookResult = ReturnType<typeof useCalendarEventSelectLazyQuery>;
export type CalendarEventSelectQueryResult = Apollo.QueryResult<
	CalendarEventSelectQuery,
	CalendarEventSelectQueryVariables
>;
export const CalendarEventUpdateDocument = gql`
	mutation calendarEventUpdate($id: ID!, $input: CalendarEventUpdateInput!) {
		updateCalendarEvent(id: $id, input: $input) {
			...CalendarEventDetail
		}
	}
	${CalendarEventDetailFragmentDoc}
`;
export type CalendarEventUpdateMutationFn = Apollo.MutationFunction<
	CalendarEventUpdateMutation,
	CalendarEventUpdateMutationVariables
>;

/**
 * __useCalendarEventUpdateMutation__
 *
 * To run a mutation, you first call `useCalendarEventUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCalendarEventUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [calendarEventUpdateMutation, { data, loading, error }] = useCalendarEventUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCalendarEventUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<CalendarEventUpdateMutation, CalendarEventUpdateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<CalendarEventUpdateMutation, CalendarEventUpdateMutationVariables>(
		CalendarEventUpdateDocument,
		options,
	);
}
export type CalendarEventUpdateMutationHookResult = ReturnType<typeof useCalendarEventUpdateMutation>;
export type CalendarEventUpdateMutationResult = Apollo.MutationResult<CalendarEventUpdateMutation>;
export type CalendarEventUpdateMutationOptions = Apollo.BaseMutationOptions<
	CalendarEventUpdateMutation,
	CalendarEventUpdateMutationVariables
>;
export const CampusCountDocument = gql`
	query campusCount($filters: [CampusFilterInput!], $pagination: Pagination, $order: CampusOrder) {
		campusConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useCampusCountQuery__
 *
 * To run a query within a React component, call `useCampusCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampusCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampusCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useCampusCountQuery(
	baseOptions?: Apollo.QueryHookOptions<CampusCountQuery, CampusCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<CampusCountQuery, CampusCountQueryVariables>(CampusCountDocument, options);
}
export function useCampusCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<CampusCountQuery, CampusCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<CampusCountQuery, CampusCountQueryVariables>(CampusCountDocument, options);
}
export type CampusCountQueryHookResult = ReturnType<typeof useCampusCountQuery>;
export type CampusCountLazyQueryHookResult = ReturnType<typeof useCampusCountLazyQuery>;
export type CampusCountQueryResult = Apollo.QueryResult<CampusCountQuery, CampusCountQueryVariables>;
export const CampusCreateDocument = gql`
	mutation campusCreate($input: CampusCreateInput!) {
		createCampus(input: $input) {
			...CampusDetail
		}
	}
	${CampusDetailFragmentDoc}
`;
export type CampusCreateMutationFn = Apollo.MutationFunction<CampusCreateMutation, CampusCreateMutationVariables>;

/**
 * __useCampusCreateMutation__
 *
 * To run a mutation, you first call `useCampusCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampusCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campusCreateMutation, { data, loading, error }] = useCampusCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCampusCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<CampusCreateMutation, CampusCreateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<CampusCreateMutation, CampusCreateMutationVariables>(CampusCreateDocument, options);
}
export type CampusCreateMutationHookResult = ReturnType<typeof useCampusCreateMutation>;
export type CampusCreateMutationResult = Apollo.MutationResult<CampusCreateMutation>;
export type CampusCreateMutationOptions = Apollo.BaseMutationOptions<
	CampusCreateMutation,
	CampusCreateMutationVariables
>;
export const CampusDeleteDocument = gql`
	mutation campusDelete($id: ID!) {
		deleteCampus(id: $id)
	}
`;
export type CampusDeleteMutationFn = Apollo.MutationFunction<CampusDeleteMutation, CampusDeleteMutationVariables>;

/**
 * __useCampusDeleteMutation__
 *
 * To run a mutation, you first call `useCampusDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampusDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campusDeleteMutation, { data, loading, error }] = useCampusDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCampusDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<CampusDeleteMutation, CampusDeleteMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<CampusDeleteMutation, CampusDeleteMutationVariables>(CampusDeleteDocument, options);
}
export type CampusDeleteMutationHookResult = ReturnType<typeof useCampusDeleteMutation>;
export type CampusDeleteMutationResult = Apollo.MutationResult<CampusDeleteMutation>;
export type CampusDeleteMutationOptions = Apollo.BaseMutationOptions<
	CampusDeleteMutation,
	CampusDeleteMutationVariables
>;
export const CampusDetailDocument = gql`
	query campusDetail($id: ID!) {
		campus(id: $id) {
			...CampusDetail
		}
	}
	${CampusDetailFragmentDoc}
`;

/**
 * __useCampusDetailQuery__
 *
 * To run a query within a React component, call `useCampusDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampusDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampusDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCampusDetailQuery(
	baseOptions: Apollo.QueryHookOptions<CampusDetailQuery, CampusDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<CampusDetailQuery, CampusDetailQueryVariables>(CampusDetailDocument, options);
}
export function useCampusDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<CampusDetailQuery, CampusDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<CampusDetailQuery, CampusDetailQueryVariables>(CampusDetailDocument, options);
}
export type CampusDetailQueryHookResult = ReturnType<typeof useCampusDetailQuery>;
export type CampusDetailLazyQueryHookResult = ReturnType<typeof useCampusDetailLazyQuery>;
export type CampusDetailQueryResult = Apollo.QueryResult<CampusDetailQuery, CampusDetailQueryVariables>;
export const CampusIndexDocument = gql`
	query campusIndex($filters: [CampusFilterInput!], $pagination: Pagination, $order: CampusOrder) {
		campusConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					name
					addressLine1
					addressLine2
					postalCode
					city
					stateOrProvince
					country
				}
			}
		}
	}
`;

/**
 * __useCampusIndexQuery__
 *
 * To run a query within a React component, call `useCampusIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampusIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampusIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useCampusIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<CampusIndexQuery, CampusIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<CampusIndexQuery, CampusIndexQueryVariables>(CampusIndexDocument, options);
}
export function useCampusIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<CampusIndexQuery, CampusIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<CampusIndexQuery, CampusIndexQueryVariables>(CampusIndexDocument, options);
}
export type CampusIndexQueryHookResult = ReturnType<typeof useCampusIndexQuery>;
export type CampusIndexLazyQueryHookResult = ReturnType<typeof useCampusIndexLazyQuery>;
export type CampusIndexQueryResult = Apollo.QueryResult<CampusIndexQuery, CampusIndexQueryVariables>;
export const CampusNameDocument = gql`
	query campusName($id: ID!) {
		campus(id: $id) {
			id
			...CampusName
		}
	}
	${CampusNameFragmentDoc}
`;

/**
 * __useCampusNameQuery__
 *
 * To run a query within a React component, call `useCampusNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampusNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampusNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCampusNameQuery(baseOptions: Apollo.QueryHookOptions<CampusNameQuery, CampusNameQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<CampusNameQuery, CampusNameQueryVariables>(CampusNameDocument, options);
}
export function useCampusNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<CampusNameQuery, CampusNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<CampusNameQuery, CampusNameQueryVariables>(CampusNameDocument, options);
}
export type CampusNameQueryHookResult = ReturnType<typeof useCampusNameQuery>;
export type CampusNameLazyQueryHookResult = ReturnType<typeof useCampusNameLazyQuery>;
export type CampusNameQueryResult = Apollo.QueryResult<CampusNameQuery, CampusNameQueryVariables>;
export const CampusNamesDocument = gql`
	query campusNames($filters: [CampusFilterInput!], $pagination: Pagination, $order: CampusOrder) {
		campusConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...CampusName
				}
			}
		}
	}
	${CampusNameFragmentDoc}
`;

/**
 * __useCampusNamesQuery__
 *
 * To run a query within a React component, call `useCampusNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampusNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampusNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useCampusNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<CampusNamesQuery, CampusNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<CampusNamesQuery, CampusNamesQueryVariables>(CampusNamesDocument, options);
}
export function useCampusNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<CampusNamesQuery, CampusNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<CampusNamesQuery, CampusNamesQueryVariables>(CampusNamesDocument, options);
}
export type CampusNamesQueryHookResult = ReturnType<typeof useCampusNamesQuery>;
export type CampusNamesLazyQueryHookResult = ReturnType<typeof useCampusNamesLazyQuery>;
export type CampusNamesQueryResult = Apollo.QueryResult<CampusNamesQuery, CampusNamesQueryVariables>;
export const CampusObjectCountsDocument = gql`
	query campusObjectCounts(
		$studentFilters: [StudentFilterInput!]
		$homeRoomSectionFilters: [HomeRoomSectionFilterInput!]
		$employeeFilters: [EmployeeFilterInput!]
	) {
		studentConnection(filters: $studentFilters) {
			totalCount
		}
		homeRoomSectionConnection(filters: $homeRoomSectionFilters) {
			totalCount
		}
		employeeConnection(filters: $employeeFilters) {
			totalCount
		}
	}
`;

/**
 * __useCampusObjectCountsQuery__
 *
 * To run a query within a React component, call `useCampusObjectCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampusObjectCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampusObjectCountsQuery({
 *   variables: {
 *      studentFilters: // value for 'studentFilters'
 *      homeRoomSectionFilters: // value for 'homeRoomSectionFilters'
 *      employeeFilters: // value for 'employeeFilters'
 *   },
 * });
 */
export function useCampusObjectCountsQuery(
	baseOptions?: Apollo.QueryHookOptions<CampusObjectCountsQuery, CampusObjectCountsQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<CampusObjectCountsQuery, CampusObjectCountsQueryVariables>(
		CampusObjectCountsDocument,
		options,
	);
}
export function useCampusObjectCountsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<CampusObjectCountsQuery, CampusObjectCountsQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<CampusObjectCountsQuery, CampusObjectCountsQueryVariables>(
		CampusObjectCountsDocument,
		options,
	);
}
export type CampusObjectCountsQueryHookResult = ReturnType<typeof useCampusObjectCountsQuery>;
export type CampusObjectCountsLazyQueryHookResult = ReturnType<typeof useCampusObjectCountsLazyQuery>;
export type CampusObjectCountsQueryResult = Apollo.QueryResult<
	CampusObjectCountsQuery,
	CampusObjectCountsQueryVariables
>;
export const CampusPaginationDocument = gql`
	query campusPagination($filters: [CampusFilterInput!], $order: CampusOrder) {
		campusConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useCampusPaginationQuery__
 *
 * To run a query within a React component, call `useCampusPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampusPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampusPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useCampusPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<CampusPaginationQuery, CampusPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<CampusPaginationQuery, CampusPaginationQueryVariables>(CampusPaginationDocument, options);
}
export function useCampusPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<CampusPaginationQuery, CampusPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<CampusPaginationQuery, CampusPaginationQueryVariables>(CampusPaginationDocument, options);
}
export type CampusPaginationQueryHookResult = ReturnType<typeof useCampusPaginationQuery>;
export type CampusPaginationLazyQueryHookResult = ReturnType<typeof useCampusPaginationLazyQuery>;
export type CampusPaginationQueryResult = Apollo.QueryResult<CampusPaginationQuery, CampusPaginationQueryVariables>;
export const CampusSelectDocument = gql`
	query campusSelect($filters: [CampusFilterInput!], $order: CampusOrder) {
		campusConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...CampusName
				}
			}
		}
	}
	${CampusNameFragmentDoc}
`;

/**
 * __useCampusSelectQuery__
 *
 * To run a query within a React component, call `useCampusSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampusSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampusSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useCampusSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<CampusSelectQuery, CampusSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<CampusSelectQuery, CampusSelectQueryVariables>(CampusSelectDocument, options);
}
export function useCampusSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<CampusSelectQuery, CampusSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<CampusSelectQuery, CampusSelectQueryVariables>(CampusSelectDocument, options);
}
export type CampusSelectQueryHookResult = ReturnType<typeof useCampusSelectQuery>;
export type CampusSelectLazyQueryHookResult = ReturnType<typeof useCampusSelectLazyQuery>;
export type CampusSelectQueryResult = Apollo.QueryResult<CampusSelectQuery, CampusSelectQueryVariables>;
export const CampusUpdateDocument = gql`
	mutation campusUpdate($id: ID!, $input: CampusUpdateInput!) {
		updateCampus(id: $id, input: $input) {
			...CampusDetail
		}
	}
	${CampusDetailFragmentDoc}
`;
export type CampusUpdateMutationFn = Apollo.MutationFunction<CampusUpdateMutation, CampusUpdateMutationVariables>;

/**
 * __useCampusUpdateMutation__
 *
 * To run a mutation, you first call `useCampusUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampusUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campusUpdateMutation, { data, loading, error }] = useCampusUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCampusUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<CampusUpdateMutation, CampusUpdateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<CampusUpdateMutation, CampusUpdateMutationVariables>(CampusUpdateDocument, options);
}
export type CampusUpdateMutationHookResult = ReturnType<typeof useCampusUpdateMutation>;
export type CampusUpdateMutationResult = Apollo.MutationResult<CampusUpdateMutation>;
export type CampusUpdateMutationOptions = Apollo.BaseMutationOptions<
	CampusUpdateMutation,
	CampusUpdateMutationVariables
>;
export const ClassYearCountDocument = gql`
	query classYearCount($filters: [ClassYearFilterInput!], $pagination: Pagination, $order: ClassYearOrder) {
		classYearConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useClassYearCountQuery__
 *
 * To run a query within a React component, call `useClassYearCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassYearCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassYearCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useClassYearCountQuery(
	baseOptions?: Apollo.QueryHookOptions<ClassYearCountQuery, ClassYearCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ClassYearCountQuery, ClassYearCountQueryVariables>(ClassYearCountDocument, options);
}
export function useClassYearCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ClassYearCountQuery, ClassYearCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ClassYearCountQuery, ClassYearCountQueryVariables>(ClassYearCountDocument, options);
}
export type ClassYearCountQueryHookResult = ReturnType<typeof useClassYearCountQuery>;
export type ClassYearCountLazyQueryHookResult = ReturnType<typeof useClassYearCountLazyQuery>;
export type ClassYearCountQueryResult = Apollo.QueryResult<ClassYearCountQuery, ClassYearCountQueryVariables>;
export const ClassYearCreateDocument = gql`
	mutation classYearCreate($input: ClassYearCreateInput!) {
		createClassYear(input: $input) {
			...ClassYearDetail
		}
	}
	${ClassYearDetailFragmentDoc}
`;
export type ClassYearCreateMutationFn = Apollo.MutationFunction<
	ClassYearCreateMutation,
	ClassYearCreateMutationVariables
>;

/**
 * __useClassYearCreateMutation__
 *
 * To run a mutation, you first call `useClassYearCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClassYearCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [classYearCreateMutation, { data, loading, error }] = useClassYearCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClassYearCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<ClassYearCreateMutation, ClassYearCreateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<ClassYearCreateMutation, ClassYearCreateMutationVariables>(
		ClassYearCreateDocument,
		options,
	);
}
export type ClassYearCreateMutationHookResult = ReturnType<typeof useClassYearCreateMutation>;
export type ClassYearCreateMutationResult = Apollo.MutationResult<ClassYearCreateMutation>;
export type ClassYearCreateMutationOptions = Apollo.BaseMutationOptions<
	ClassYearCreateMutation,
	ClassYearCreateMutationVariables
>;
export const ClassYearDeleteDocument = gql`
	mutation classYearDelete($id: ID!) {
		deleteClassYear(id: $id)
	}
`;
export type ClassYearDeleteMutationFn = Apollo.MutationFunction<
	ClassYearDeleteMutation,
	ClassYearDeleteMutationVariables
>;

/**
 * __useClassYearDeleteMutation__
 *
 * To run a mutation, you first call `useClassYearDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClassYearDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [classYearDeleteMutation, { data, loading, error }] = useClassYearDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClassYearDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<ClassYearDeleteMutation, ClassYearDeleteMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<ClassYearDeleteMutation, ClassYearDeleteMutationVariables>(
		ClassYearDeleteDocument,
		options,
	);
}
export type ClassYearDeleteMutationHookResult = ReturnType<typeof useClassYearDeleteMutation>;
export type ClassYearDeleteMutationResult = Apollo.MutationResult<ClassYearDeleteMutation>;
export type ClassYearDeleteMutationOptions = Apollo.BaseMutationOptions<
	ClassYearDeleteMutation,
	ClassYearDeleteMutationVariables
>;
export const ClassYearDetailDocument = gql`
	query classYearDetail($id: ID!) {
		classYear(id: $id) {
			...ClassYearDetail
		}
	}
	${ClassYearDetailFragmentDoc}
`;

/**
 * __useClassYearDetailQuery__
 *
 * To run a query within a React component, call `useClassYearDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassYearDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassYearDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClassYearDetailQuery(
	baseOptions: Apollo.QueryHookOptions<ClassYearDetailQuery, ClassYearDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ClassYearDetailQuery, ClassYearDetailQueryVariables>(ClassYearDetailDocument, options);
}
export function useClassYearDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ClassYearDetailQuery, ClassYearDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ClassYearDetailQuery, ClassYearDetailQueryVariables>(ClassYearDetailDocument, options);
}
export type ClassYearDetailQueryHookResult = ReturnType<typeof useClassYearDetailQuery>;
export type ClassYearDetailLazyQueryHookResult = ReturnType<typeof useClassYearDetailLazyQuery>;
export type ClassYearDetailQueryResult = Apollo.QueryResult<ClassYearDetailQuery, ClassYearDetailQueryVariables>;
export const ClassYearIndexDocument = gql`
	query classYearIndex($filters: [ClassYearFilterInput!], $pagination: Pagination, $order: ClassYearOrder) {
		classYearConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					name
					yearsFromGraduation
				}
			}
		}
	}
`;

/**
 * __useClassYearIndexQuery__
 *
 * To run a query within a React component, call `useClassYearIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassYearIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassYearIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useClassYearIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<ClassYearIndexQuery, ClassYearIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ClassYearIndexQuery, ClassYearIndexQueryVariables>(ClassYearIndexDocument, options);
}
export function useClassYearIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ClassYearIndexQuery, ClassYearIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ClassYearIndexQuery, ClassYearIndexQueryVariables>(ClassYearIndexDocument, options);
}
export type ClassYearIndexQueryHookResult = ReturnType<typeof useClassYearIndexQuery>;
export type ClassYearIndexLazyQueryHookResult = ReturnType<typeof useClassYearIndexLazyQuery>;
export type ClassYearIndexQueryResult = Apollo.QueryResult<ClassYearIndexQuery, ClassYearIndexQueryVariables>;
export const ClassYearNameDocument = gql`
	query classYearName($id: ID!) {
		classYear(id: $id) {
			id
			...ClassYearName
		}
	}
	${ClassYearNameFragmentDoc}
`;

/**
 * __useClassYearNameQuery__
 *
 * To run a query within a React component, call `useClassYearNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassYearNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassYearNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClassYearNameQuery(
	baseOptions: Apollo.QueryHookOptions<ClassYearNameQuery, ClassYearNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ClassYearNameQuery, ClassYearNameQueryVariables>(ClassYearNameDocument, options);
}
export function useClassYearNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ClassYearNameQuery, ClassYearNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ClassYearNameQuery, ClassYearNameQueryVariables>(ClassYearNameDocument, options);
}
export type ClassYearNameQueryHookResult = ReturnType<typeof useClassYearNameQuery>;
export type ClassYearNameLazyQueryHookResult = ReturnType<typeof useClassYearNameLazyQuery>;
export type ClassYearNameQueryResult = Apollo.QueryResult<ClassYearNameQuery, ClassYearNameQueryVariables>;
export const ClassYearNamesDocument = gql`
	query classYearNames($filters: [ClassYearFilterInput!], $pagination: Pagination, $order: ClassYearOrder) {
		classYearConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...ClassYearName
				}
			}
		}
	}
	${ClassYearNameFragmentDoc}
`;

/**
 * __useClassYearNamesQuery__
 *
 * To run a query within a React component, call `useClassYearNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassYearNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassYearNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useClassYearNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<ClassYearNamesQuery, ClassYearNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ClassYearNamesQuery, ClassYearNamesQueryVariables>(ClassYearNamesDocument, options);
}
export function useClassYearNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ClassYearNamesQuery, ClassYearNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ClassYearNamesQuery, ClassYearNamesQueryVariables>(ClassYearNamesDocument, options);
}
export type ClassYearNamesQueryHookResult = ReturnType<typeof useClassYearNamesQuery>;
export type ClassYearNamesLazyQueryHookResult = ReturnType<typeof useClassYearNamesLazyQuery>;
export type ClassYearNamesQueryResult = Apollo.QueryResult<ClassYearNamesQuery, ClassYearNamesQueryVariables>;
export const ClassYearPaginationDocument = gql`
	query classYearPagination($filters: [ClassYearFilterInput!], $order: ClassYearOrder) {
		classYearConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useClassYearPaginationQuery__
 *
 * To run a query within a React component, call `useClassYearPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassYearPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassYearPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useClassYearPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<ClassYearPaginationQuery, ClassYearPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ClassYearPaginationQuery, ClassYearPaginationQueryVariables>(
		ClassYearPaginationDocument,
		options,
	);
}
export function useClassYearPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ClassYearPaginationQuery, ClassYearPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ClassYearPaginationQuery, ClassYearPaginationQueryVariables>(
		ClassYearPaginationDocument,
		options,
	);
}
export type ClassYearPaginationQueryHookResult = ReturnType<typeof useClassYearPaginationQuery>;
export type ClassYearPaginationLazyQueryHookResult = ReturnType<typeof useClassYearPaginationLazyQuery>;
export type ClassYearPaginationQueryResult = Apollo.QueryResult<
	ClassYearPaginationQuery,
	ClassYearPaginationQueryVariables
>;
export const ClassYearSelectDocument = gql`
	query classYearSelect($filters: [ClassYearFilterInput!], $order: ClassYearOrder) {
		classYearConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...ClassYearName
				}
			}
		}
	}
	${ClassYearNameFragmentDoc}
`;

/**
 * __useClassYearSelectQuery__
 *
 * To run a query within a React component, call `useClassYearSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassYearSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassYearSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useClassYearSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<ClassYearSelectQuery, ClassYearSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ClassYearSelectQuery, ClassYearSelectQueryVariables>(ClassYearSelectDocument, options);
}
export function useClassYearSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ClassYearSelectQuery, ClassYearSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ClassYearSelectQuery, ClassYearSelectQueryVariables>(ClassYearSelectDocument, options);
}
export type ClassYearSelectQueryHookResult = ReturnType<typeof useClassYearSelectQuery>;
export type ClassYearSelectLazyQueryHookResult = ReturnType<typeof useClassYearSelectLazyQuery>;
export type ClassYearSelectQueryResult = Apollo.QueryResult<ClassYearSelectQuery, ClassYearSelectQueryVariables>;
export const ClassYearUpdateDocument = gql`
	mutation classYearUpdate($id: ID!, $input: ClassYearUpdateInput!) {
		updateClassYear(id: $id, input: $input) {
			...ClassYearDetail
		}
	}
	${ClassYearDetailFragmentDoc}
`;
export type ClassYearUpdateMutationFn = Apollo.MutationFunction<
	ClassYearUpdateMutation,
	ClassYearUpdateMutationVariables
>;

/**
 * __useClassYearUpdateMutation__
 *
 * To run a mutation, you first call `useClassYearUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClassYearUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [classYearUpdateMutation, { data, loading, error }] = useClassYearUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClassYearUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<ClassYearUpdateMutation, ClassYearUpdateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<ClassYearUpdateMutation, ClassYearUpdateMutationVariables>(
		ClassYearUpdateDocument,
		options,
	);
}
export type ClassYearUpdateMutationHookResult = ReturnType<typeof useClassYearUpdateMutation>;
export type ClassYearUpdateMutationResult = Apollo.MutationResult<ClassYearUpdateMutation>;
export type ClassYearUpdateMutationOptions = Apollo.BaseMutationOptions<
	ClassYearUpdateMutation,
	ClassYearUpdateMutationVariables
>;
export const ContractCountDocument = gql`
	query contractCount($filters: [ContractFilterInput!], $pagination: Pagination, $order: ContractOrder) {
		contractConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useContractCountQuery__
 *
 * To run a query within a React component, call `useContractCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useContractCountQuery(
	baseOptions?: Apollo.QueryHookOptions<ContractCountQuery, ContractCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ContractCountQuery, ContractCountQueryVariables>(ContractCountDocument, options);
}
export function useContractCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ContractCountQuery, ContractCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ContractCountQuery, ContractCountQueryVariables>(ContractCountDocument, options);
}
export type ContractCountQueryHookResult = ReturnType<typeof useContractCountQuery>;
export type ContractCountLazyQueryHookResult = ReturnType<typeof useContractCountLazyQuery>;
export type ContractCountQueryResult = Apollo.QueryResult<ContractCountQuery, ContractCountQueryVariables>;
export const ContractCreateDocument = gql`
	mutation contractCreate($input: ContractCreateInput!) {
		createContract(input: $input) {
			...ContractDetail
		}
	}
	${ContractDetailFragmentDoc}
`;
export type ContractCreateMutationFn = Apollo.MutationFunction<ContractCreateMutation, ContractCreateMutationVariables>;

/**
 * __useContractCreateMutation__
 *
 * To run a mutation, you first call `useContractCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContractCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contractCreateMutation, { data, loading, error }] = useContractCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContractCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<ContractCreateMutation, ContractCreateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<ContractCreateMutation, ContractCreateMutationVariables>(ContractCreateDocument, options);
}
export type ContractCreateMutationHookResult = ReturnType<typeof useContractCreateMutation>;
export type ContractCreateMutationResult = Apollo.MutationResult<ContractCreateMutation>;
export type ContractCreateMutationOptions = Apollo.BaseMutationOptions<
	ContractCreateMutation,
	ContractCreateMutationVariables
>;
export const ContractDeleteDocument = gql`
	mutation contractDelete($id: ID!) {
		deleteContract(id: $id)
	}
`;
export type ContractDeleteMutationFn = Apollo.MutationFunction<ContractDeleteMutation, ContractDeleteMutationVariables>;

/**
 * __useContractDeleteMutation__
 *
 * To run a mutation, you first call `useContractDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContractDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contractDeleteMutation, { data, loading, error }] = useContractDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContractDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<ContractDeleteMutation, ContractDeleteMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<ContractDeleteMutation, ContractDeleteMutationVariables>(ContractDeleteDocument, options);
}
export type ContractDeleteMutationHookResult = ReturnType<typeof useContractDeleteMutation>;
export type ContractDeleteMutationResult = Apollo.MutationResult<ContractDeleteMutation>;
export type ContractDeleteMutationOptions = Apollo.BaseMutationOptions<
	ContractDeleteMutation,
	ContractDeleteMutationVariables
>;
export const ContractDetailDocument = gql`
	query contractDetail($id: ID!) {
		contract(id: $id) {
			...ContractDetail
		}
	}
	${ContractDetailFragmentDoc}
`;

/**
 * __useContractDetailQuery__
 *
 * To run a query within a React component, call `useContractDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContractDetailQuery(
	baseOptions: Apollo.QueryHookOptions<ContractDetailQuery, ContractDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ContractDetailQuery, ContractDetailQueryVariables>(ContractDetailDocument, options);
}
export function useContractDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ContractDetailQuery, ContractDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ContractDetailQuery, ContractDetailQueryVariables>(ContractDetailDocument, options);
}
export type ContractDetailQueryHookResult = ReturnType<typeof useContractDetailQuery>;
export type ContractDetailLazyQueryHookResult = ReturnType<typeof useContractDetailLazyQuery>;
export type ContractDetailQueryResult = Apollo.QueryResult<ContractDetailQuery, ContractDetailQueryVariables>;
export const ContractIndexDocument = gql`
	query contractIndex($filters: [ContractFilterInput!], $pagination: Pagination, $order: ContractOrder) {
		contractConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					agreementDate
					status
					student {
						id
						person {
							id
							name
						}
					}
				}
			}
		}
	}
`;

/**
 * __useContractIndexQuery__
 *
 * To run a query within a React component, call `useContractIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useContractIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<ContractIndexQuery, ContractIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ContractIndexQuery, ContractIndexQueryVariables>(ContractIndexDocument, options);
}
export function useContractIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ContractIndexQuery, ContractIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ContractIndexQuery, ContractIndexQueryVariables>(ContractIndexDocument, options);
}
export type ContractIndexQueryHookResult = ReturnType<typeof useContractIndexQuery>;
export type ContractIndexLazyQueryHookResult = ReturnType<typeof useContractIndexLazyQuery>;
export type ContractIndexQueryResult = Apollo.QueryResult<ContractIndexQuery, ContractIndexQueryVariables>;
export const ContractListCountDocument = gql`
	query contractListCount($filters: [ContractFilterInput!], $pagination: Pagination, $order: ContractOrder) {
		contractConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useContractListCountQuery__
 *
 * To run a query within a React component, call `useContractListCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractListCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractListCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useContractListCountQuery(
	baseOptions?: Apollo.QueryHookOptions<ContractListCountQuery, ContractListCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ContractListCountQuery, ContractListCountQueryVariables>(ContractListCountDocument, options);
}
export function useContractListCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ContractListCountQuery, ContractListCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ContractListCountQuery, ContractListCountQueryVariables>(
		ContractListCountDocument,
		options,
	);
}
export type ContractListCountQueryHookResult = ReturnType<typeof useContractListCountQuery>;
export type ContractListCountLazyQueryHookResult = ReturnType<typeof useContractListCountLazyQuery>;
export type ContractListCountQueryResult = Apollo.QueryResult<ContractListCountQuery, ContractListCountQueryVariables>;
export const ContractNameDocument = gql`
	query contractName($id: ID!) {
		contract(id: $id) {
			id
			...ContractName
		}
	}
	${ContractNameFragmentDoc}
`;

/**
 * __useContractNameQuery__
 *
 * To run a query within a React component, call `useContractNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContractNameQuery(
	baseOptions: Apollo.QueryHookOptions<ContractNameQuery, ContractNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ContractNameQuery, ContractNameQueryVariables>(ContractNameDocument, options);
}
export function useContractNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ContractNameQuery, ContractNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ContractNameQuery, ContractNameQueryVariables>(ContractNameDocument, options);
}
export type ContractNameQueryHookResult = ReturnType<typeof useContractNameQuery>;
export type ContractNameLazyQueryHookResult = ReturnType<typeof useContractNameLazyQuery>;
export type ContractNameQueryResult = Apollo.QueryResult<ContractNameQuery, ContractNameQueryVariables>;
export const ContractNamesDocument = gql`
	query contractNames($filters: [ContractFilterInput!], $pagination: Pagination, $order: ContractOrder) {
		contractConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...ContractName
				}
			}
		}
	}
	${ContractNameFragmentDoc}
`;

/**
 * __useContractNamesQuery__
 *
 * To run a query within a React component, call `useContractNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useContractNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<ContractNamesQuery, ContractNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ContractNamesQuery, ContractNamesQueryVariables>(ContractNamesDocument, options);
}
export function useContractNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ContractNamesQuery, ContractNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ContractNamesQuery, ContractNamesQueryVariables>(ContractNamesDocument, options);
}
export type ContractNamesQueryHookResult = ReturnType<typeof useContractNamesQuery>;
export type ContractNamesLazyQueryHookResult = ReturnType<typeof useContractNamesLazyQuery>;
export type ContractNamesQueryResult = Apollo.QueryResult<ContractNamesQuery, ContractNamesQueryVariables>;
export const ContractPaginationDocument = gql`
	query contractPagination($filters: [ContractFilterInput!], $order: ContractOrder) {
		contractConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useContractPaginationQuery__
 *
 * To run a query within a React component, call `useContractPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useContractPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<ContractPaginationQuery, ContractPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ContractPaginationQuery, ContractPaginationQueryVariables>(
		ContractPaginationDocument,
		options,
	);
}
export function useContractPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ContractPaginationQuery, ContractPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ContractPaginationQuery, ContractPaginationQueryVariables>(
		ContractPaginationDocument,
		options,
	);
}
export type ContractPaginationQueryHookResult = ReturnType<typeof useContractPaginationQuery>;
export type ContractPaginationLazyQueryHookResult = ReturnType<typeof useContractPaginationLazyQuery>;
export type ContractPaginationQueryResult = Apollo.QueryResult<
	ContractPaginationQuery,
	ContractPaginationQueryVariables
>;
export const ContractPaymentScheduleListDocument = gql`
	query contractPaymentScheduleList($filters: [ContractFilterInput!], $pagination: Pagination, $order: ContractOrder) {
		contractConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					status
					serviceSubscriptions {
						id
						price
						discounts {
							id
							amount
						}
					}
					contractInstallments(order: { field: INSTALLMENT_DATE, direction: ASC }) {
						id
						installmentDate
						portion
					}
				}
			}
		}
	}
`;

/**
 * __useContractPaymentScheduleListQuery__
 *
 * To run a query within a React component, call `useContractPaymentScheduleListQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractPaymentScheduleListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractPaymentScheduleListQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useContractPaymentScheduleListQuery(
	baseOptions?: Apollo.QueryHookOptions<ContractPaymentScheduleListQuery, ContractPaymentScheduleListQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ContractPaymentScheduleListQuery, ContractPaymentScheduleListQueryVariables>(
		ContractPaymentScheduleListDocument,
		options,
	);
}
export function useContractPaymentScheduleListLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ContractPaymentScheduleListQuery,
		ContractPaymentScheduleListQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ContractPaymentScheduleListQuery, ContractPaymentScheduleListQueryVariables>(
		ContractPaymentScheduleListDocument,
		options,
	);
}
export type ContractPaymentScheduleListQueryHookResult = ReturnType<typeof useContractPaymentScheduleListQuery>;
export type ContractPaymentScheduleListLazyQueryHookResult = ReturnType<typeof useContractPaymentScheduleListLazyQuery>;
export type ContractPaymentScheduleListQueryResult = Apollo.QueryResult<
	ContractPaymentScheduleListQuery,
	ContractPaymentScheduleListQueryVariables
>;
export const ContractSelectDocument = gql`
	query contractSelect($filters: [ContractFilterInput!], $order: ContractOrder) {
		contractConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...ContractName
				}
			}
		}
	}
	${ContractNameFragmentDoc}
`;

/**
 * __useContractSelectQuery__
 *
 * To run a query within a React component, call `useContractSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useContractSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<ContractSelectQuery, ContractSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ContractSelectQuery, ContractSelectQueryVariables>(ContractSelectDocument, options);
}
export function useContractSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ContractSelectQuery, ContractSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ContractSelectQuery, ContractSelectQueryVariables>(ContractSelectDocument, options);
}
export type ContractSelectQueryHookResult = ReturnType<typeof useContractSelectQuery>;
export type ContractSelectLazyQueryHookResult = ReturnType<typeof useContractSelectLazyQuery>;
export type ContractSelectQueryResult = Apollo.QueryResult<ContractSelectQuery, ContractSelectQueryVariables>;
export const StudentContractsServiceSubscriptionsListDocument = gql`
	query studentContractsServiceSubscriptionsList(
		$filters: [ContractFilterInput!]
		$pagination: Pagination
		$order: ContractOrder
	) {
		contractConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					agreementDate
					status
					serviceSubscriptions {
						id
						service {
							id
							name
						}
						price
						discounts {
							id
							description
							amount
						}
					}
				}
			}
		}
	}
`;

/**
 * __useStudentContractsServiceSubscriptionsListQuery__
 *
 * To run a query within a React component, call `useStudentContractsServiceSubscriptionsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentContractsServiceSubscriptionsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentContractsServiceSubscriptionsListQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useStudentContractsServiceSubscriptionsListQuery(
	baseOptions?: Apollo.QueryHookOptions<
		StudentContractsServiceSubscriptionsListQuery,
		StudentContractsServiceSubscriptionsListQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		StudentContractsServiceSubscriptionsListQuery,
		StudentContractsServiceSubscriptionsListQueryVariables
	>(StudentContractsServiceSubscriptionsListDocument, options);
}
export function useStudentContractsServiceSubscriptionsListLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		StudentContractsServiceSubscriptionsListQuery,
		StudentContractsServiceSubscriptionsListQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		StudentContractsServiceSubscriptionsListQuery,
		StudentContractsServiceSubscriptionsListQueryVariables
	>(StudentContractsServiceSubscriptionsListDocument, options);
}
export type StudentContractsServiceSubscriptionsListQueryHookResult = ReturnType<
	typeof useStudentContractsServiceSubscriptionsListQuery
>;
export type StudentContractsServiceSubscriptionsListLazyQueryHookResult = ReturnType<
	typeof useStudentContractsServiceSubscriptionsListLazyQuery
>;
export type StudentContractsServiceSubscriptionsListQueryResult = Apollo.QueryResult<
	StudentContractsServiceSubscriptionsListQuery,
	StudentContractsServiceSubscriptionsListQueryVariables
>;
export const StudentContractsPaymentProgressListDocument = gql`
	query studentContractsPaymentProgressList(
		$filters: [ContractFilterInput!]
		$pagination: Pagination
		$order: ContractOrder
	) {
		contractConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					agreementDate
					status
					serviceSubscriptions {
						id
						service {
							id
							name
						}
						price
						discounts {
							id
							description
							amount
						}
					}
					contractInstallments(order: { field: INSTALLMENT_DATE, direction: ASC }) {
						id
						installmentDate
						portion
						invoice {
							id
							invoiceNumber
							dueDate
						}
					}
				}
			}
		}
	}
`;

/**
 * __useStudentContractsPaymentProgressListQuery__
 *
 * To run a query within a React component, call `useStudentContractsPaymentProgressListQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentContractsPaymentProgressListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentContractsPaymentProgressListQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useStudentContractsPaymentProgressListQuery(
	baseOptions?: Apollo.QueryHookOptions<
		StudentContractsPaymentProgressListQuery,
		StudentContractsPaymentProgressListQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<StudentContractsPaymentProgressListQuery, StudentContractsPaymentProgressListQueryVariables>(
		StudentContractsPaymentProgressListDocument,
		options,
	);
}
export function useStudentContractsPaymentProgressListLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		StudentContractsPaymentProgressListQuery,
		StudentContractsPaymentProgressListQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		StudentContractsPaymentProgressListQuery,
		StudentContractsPaymentProgressListQueryVariables
	>(StudentContractsPaymentProgressListDocument, options);
}
export type StudentContractsPaymentProgressListQueryHookResult = ReturnType<
	typeof useStudentContractsPaymentProgressListQuery
>;
export type StudentContractsPaymentProgressListLazyQueryHookResult = ReturnType<
	typeof useStudentContractsPaymentProgressListLazyQuery
>;
export type StudentContractsPaymentProgressListQueryResult = Apollo.QueryResult<
	StudentContractsPaymentProgressListQuery,
	StudentContractsPaymentProgressListQueryVariables
>;
export const StudentContractDetailDocument = gql`
	query studentContractDetail($id: ID!) {
		contract(id: $id) {
			id
			agreementDate
			status
			serviceSubscriptions {
				id
				service {
					id
					name
				}
				price
				discounts {
					id
					description
					amount
				}
			}
			contractInstallments(order: { field: INSTALLMENT_DATE, direction: ASC }) {
				id
				installmentDate
				portion
				invoice {
					id
					invoiceNumber
					dueDate
				}
			}
		}
	}
`;

/**
 * __useStudentContractDetailQuery__
 *
 * To run a query within a React component, call `useStudentContractDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentContractDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentContractDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStudentContractDetailQuery(
	baseOptions: Apollo.QueryHookOptions<StudentContractDetailQuery, StudentContractDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<StudentContractDetailQuery, StudentContractDetailQueryVariables>(
		StudentContractDetailDocument,
		options,
	);
}
export function useStudentContractDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<StudentContractDetailQuery, StudentContractDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<StudentContractDetailQuery, StudentContractDetailQueryVariables>(
		StudentContractDetailDocument,
		options,
	);
}
export type StudentContractDetailQueryHookResult = ReturnType<typeof useStudentContractDetailQuery>;
export type StudentContractDetailLazyQueryHookResult = ReturnType<typeof useStudentContractDetailLazyQuery>;
export type StudentContractDetailQueryResult = Apollo.QueryResult<
	StudentContractDetailQuery,
	StudentContractDetailQueryVariables
>;
export const ContractUpdateDocument = gql`
	mutation contractUpdate($id: ID!, $input: ContractUpdateInput!) {
		updateContract(id: $id, input: $input) {
			...ContractDetail
		}
	}
	${ContractDetailFragmentDoc}
`;
export type ContractUpdateMutationFn = Apollo.MutationFunction<ContractUpdateMutation, ContractUpdateMutationVariables>;

/**
 * __useContractUpdateMutation__
 *
 * To run a mutation, you first call `useContractUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContractUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contractUpdateMutation, { data, loading, error }] = useContractUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContractUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<ContractUpdateMutation, ContractUpdateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<ContractUpdateMutation, ContractUpdateMutationVariables>(ContractUpdateDocument, options);
}
export type ContractUpdateMutationHookResult = ReturnType<typeof useContractUpdateMutation>;
export type ContractUpdateMutationResult = Apollo.MutationResult<ContractUpdateMutation>;
export type ContractUpdateMutationOptions = Apollo.BaseMutationOptions<
	ContractUpdateMutation,
	ContractUpdateMutationVariables
>;
export const ContractInstallmentCountDocument = gql`
	query contractInstallmentCount(
		$filters: [ContractInstallmentFilterInput!]
		$pagination: Pagination
		$order: ContractInstallmentOrder
	) {
		contractInstallmentConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useContractInstallmentCountQuery__
 *
 * To run a query within a React component, call `useContractInstallmentCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractInstallmentCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractInstallmentCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useContractInstallmentCountQuery(
	baseOptions?: Apollo.QueryHookOptions<ContractInstallmentCountQuery, ContractInstallmentCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ContractInstallmentCountQuery, ContractInstallmentCountQueryVariables>(
		ContractInstallmentCountDocument,
		options,
	);
}
export function useContractInstallmentCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ContractInstallmentCountQuery, ContractInstallmentCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ContractInstallmentCountQuery, ContractInstallmentCountQueryVariables>(
		ContractInstallmentCountDocument,
		options,
	);
}
export type ContractInstallmentCountQueryHookResult = ReturnType<typeof useContractInstallmentCountQuery>;
export type ContractInstallmentCountLazyQueryHookResult = ReturnType<typeof useContractInstallmentCountLazyQuery>;
export type ContractInstallmentCountQueryResult = Apollo.QueryResult<
	ContractInstallmentCountQuery,
	ContractInstallmentCountQueryVariables
>;
export const ContractInstallmentCreateDocument = gql`
	mutation contractInstallmentCreate($input: ContractInstallmentCreateInput!) {
		createContractInstallment(input: $input) {
			...ContractInstallmentDetail
		}
	}
	${ContractInstallmentDetailFragmentDoc}
`;
export type ContractInstallmentCreateMutationFn = Apollo.MutationFunction<
	ContractInstallmentCreateMutation,
	ContractInstallmentCreateMutationVariables
>;

/**
 * __useContractInstallmentCreateMutation__
 *
 * To run a mutation, you first call `useContractInstallmentCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContractInstallmentCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contractInstallmentCreateMutation, { data, loading, error }] = useContractInstallmentCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContractInstallmentCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<
		ContractInstallmentCreateMutation,
		ContractInstallmentCreateMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<ContractInstallmentCreateMutation, ContractInstallmentCreateMutationVariables>(
		ContractInstallmentCreateDocument,
		options,
	);
}
export type ContractInstallmentCreateMutationHookResult = ReturnType<typeof useContractInstallmentCreateMutation>;
export type ContractInstallmentCreateMutationResult = Apollo.MutationResult<ContractInstallmentCreateMutation>;
export type ContractInstallmentCreateMutationOptions = Apollo.BaseMutationOptions<
	ContractInstallmentCreateMutation,
	ContractInstallmentCreateMutationVariables
>;
export const ContractInstallmentDeleteDocument = gql`
	mutation contractInstallmentDelete($id: ID!) {
		deleteContractInstallment(id: $id)
	}
`;
export type ContractInstallmentDeleteMutationFn = Apollo.MutationFunction<
	ContractInstallmentDeleteMutation,
	ContractInstallmentDeleteMutationVariables
>;

/**
 * __useContractInstallmentDeleteMutation__
 *
 * To run a mutation, you first call `useContractInstallmentDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContractInstallmentDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contractInstallmentDeleteMutation, { data, loading, error }] = useContractInstallmentDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContractInstallmentDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<
		ContractInstallmentDeleteMutation,
		ContractInstallmentDeleteMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<ContractInstallmentDeleteMutation, ContractInstallmentDeleteMutationVariables>(
		ContractInstallmentDeleteDocument,
		options,
	);
}
export type ContractInstallmentDeleteMutationHookResult = ReturnType<typeof useContractInstallmentDeleteMutation>;
export type ContractInstallmentDeleteMutationResult = Apollo.MutationResult<ContractInstallmentDeleteMutation>;
export type ContractInstallmentDeleteMutationOptions = Apollo.BaseMutationOptions<
	ContractInstallmentDeleteMutation,
	ContractInstallmentDeleteMutationVariables
>;
export const ContractInstallmentDetailDocument = gql`
	query contractInstallmentDetail($id: ID!) {
		contractInstallment(id: $id) {
			...ContractInstallmentDetail
		}
	}
	${ContractInstallmentDetailFragmentDoc}
`;

/**
 * __useContractInstallmentDetailQuery__
 *
 * To run a query within a React component, call `useContractInstallmentDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractInstallmentDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractInstallmentDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContractInstallmentDetailQuery(
	baseOptions: Apollo.QueryHookOptions<ContractInstallmentDetailQuery, ContractInstallmentDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ContractInstallmentDetailQuery, ContractInstallmentDetailQueryVariables>(
		ContractInstallmentDetailDocument,
		options,
	);
}
export function useContractInstallmentDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ContractInstallmentDetailQuery, ContractInstallmentDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ContractInstallmentDetailQuery, ContractInstallmentDetailQueryVariables>(
		ContractInstallmentDetailDocument,
		options,
	);
}
export type ContractInstallmentDetailQueryHookResult = ReturnType<typeof useContractInstallmentDetailQuery>;
export type ContractInstallmentDetailLazyQueryHookResult = ReturnType<typeof useContractInstallmentDetailLazyQuery>;
export type ContractInstallmentDetailQueryResult = Apollo.QueryResult<
	ContractInstallmentDetailQuery,
	ContractInstallmentDetailQueryVariables
>;
export const ContractInstallmentIndexDocument = gql`
	query contractInstallmentIndex(
		$filters: [ContractInstallmentFilterInput!]
		$pagination: Pagination
		$order: ContractInstallmentOrder
	) {
		contractInstallmentConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					installmentDate
					portion
				}
			}
		}
	}
`;

/**
 * __useContractInstallmentIndexQuery__
 *
 * To run a query within a React component, call `useContractInstallmentIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractInstallmentIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractInstallmentIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useContractInstallmentIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<ContractInstallmentIndexQuery, ContractInstallmentIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ContractInstallmentIndexQuery, ContractInstallmentIndexQueryVariables>(
		ContractInstallmentIndexDocument,
		options,
	);
}
export function useContractInstallmentIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ContractInstallmentIndexQuery, ContractInstallmentIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ContractInstallmentIndexQuery, ContractInstallmentIndexQueryVariables>(
		ContractInstallmentIndexDocument,
		options,
	);
}
export type ContractInstallmentIndexQueryHookResult = ReturnType<typeof useContractInstallmentIndexQuery>;
export type ContractInstallmentIndexLazyQueryHookResult = ReturnType<typeof useContractInstallmentIndexLazyQuery>;
export type ContractInstallmentIndexQueryResult = Apollo.QueryResult<
	ContractInstallmentIndexQuery,
	ContractInstallmentIndexQueryVariables
>;
export const ContractInstallmentNameDocument = gql`
	query contractInstallmentName($id: ID!) {
		contractInstallment(id: $id) {
			id
			...ContractInstallmentName
		}
	}
	${ContractInstallmentNameFragmentDoc}
`;

/**
 * __useContractInstallmentNameQuery__
 *
 * To run a query within a React component, call `useContractInstallmentNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractInstallmentNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractInstallmentNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContractInstallmentNameQuery(
	baseOptions: Apollo.QueryHookOptions<ContractInstallmentNameQuery, ContractInstallmentNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ContractInstallmentNameQuery, ContractInstallmentNameQueryVariables>(
		ContractInstallmentNameDocument,
		options,
	);
}
export function useContractInstallmentNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ContractInstallmentNameQuery, ContractInstallmentNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ContractInstallmentNameQuery, ContractInstallmentNameQueryVariables>(
		ContractInstallmentNameDocument,
		options,
	);
}
export type ContractInstallmentNameQueryHookResult = ReturnType<typeof useContractInstallmentNameQuery>;
export type ContractInstallmentNameLazyQueryHookResult = ReturnType<typeof useContractInstallmentNameLazyQuery>;
export type ContractInstallmentNameQueryResult = Apollo.QueryResult<
	ContractInstallmentNameQuery,
	ContractInstallmentNameQueryVariables
>;
export const ContractInstallmentNamesDocument = gql`
	query contractInstallmentNames(
		$filters: [ContractInstallmentFilterInput!]
		$pagination: Pagination
		$order: ContractInstallmentOrder
	) {
		contractInstallmentConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...ContractInstallmentName
				}
			}
		}
	}
	${ContractInstallmentNameFragmentDoc}
`;

/**
 * __useContractInstallmentNamesQuery__
 *
 * To run a query within a React component, call `useContractInstallmentNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractInstallmentNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractInstallmentNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useContractInstallmentNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<ContractInstallmentNamesQuery, ContractInstallmentNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ContractInstallmentNamesQuery, ContractInstallmentNamesQueryVariables>(
		ContractInstallmentNamesDocument,
		options,
	);
}
export function useContractInstallmentNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ContractInstallmentNamesQuery, ContractInstallmentNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ContractInstallmentNamesQuery, ContractInstallmentNamesQueryVariables>(
		ContractInstallmentNamesDocument,
		options,
	);
}
export type ContractInstallmentNamesQueryHookResult = ReturnType<typeof useContractInstallmentNamesQuery>;
export type ContractInstallmentNamesLazyQueryHookResult = ReturnType<typeof useContractInstallmentNamesLazyQuery>;
export type ContractInstallmentNamesQueryResult = Apollo.QueryResult<
	ContractInstallmentNamesQuery,
	ContractInstallmentNamesQueryVariables
>;
export const ContractInstallmentPaginationDocument = gql`
	query contractInstallmentPagination($filters: [ContractInstallmentFilterInput!], $order: ContractInstallmentOrder) {
		contractInstallmentConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useContractInstallmentPaginationQuery__
 *
 * To run a query within a React component, call `useContractInstallmentPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractInstallmentPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractInstallmentPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useContractInstallmentPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<
		ContractInstallmentPaginationQuery,
		ContractInstallmentPaginationQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ContractInstallmentPaginationQuery, ContractInstallmentPaginationQueryVariables>(
		ContractInstallmentPaginationDocument,
		options,
	);
}
export function useContractInstallmentPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ContractInstallmentPaginationQuery,
		ContractInstallmentPaginationQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ContractInstallmentPaginationQuery, ContractInstallmentPaginationQueryVariables>(
		ContractInstallmentPaginationDocument,
		options,
	);
}
export type ContractInstallmentPaginationQueryHookResult = ReturnType<typeof useContractInstallmentPaginationQuery>;
export type ContractInstallmentPaginationLazyQueryHookResult = ReturnType<
	typeof useContractInstallmentPaginationLazyQuery
>;
export type ContractInstallmentPaginationQueryResult = Apollo.QueryResult<
	ContractInstallmentPaginationQuery,
	ContractInstallmentPaginationQueryVariables
>;
export const ContractInstallmentSelectDocument = gql`
	query contractInstallmentSelect($filters: [ContractInstallmentFilterInput!], $order: ContractInstallmentOrder) {
		contractInstallmentConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...ContractInstallmentName
				}
			}
		}
	}
	${ContractInstallmentNameFragmentDoc}
`;

/**
 * __useContractInstallmentSelectQuery__
 *
 * To run a query within a React component, call `useContractInstallmentSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractInstallmentSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractInstallmentSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useContractInstallmentSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<ContractInstallmentSelectQuery, ContractInstallmentSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ContractInstallmentSelectQuery, ContractInstallmentSelectQueryVariables>(
		ContractInstallmentSelectDocument,
		options,
	);
}
export function useContractInstallmentSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ContractInstallmentSelectQuery, ContractInstallmentSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ContractInstallmentSelectQuery, ContractInstallmentSelectQueryVariables>(
		ContractInstallmentSelectDocument,
		options,
	);
}
export type ContractInstallmentSelectQueryHookResult = ReturnType<typeof useContractInstallmentSelectQuery>;
export type ContractInstallmentSelectLazyQueryHookResult = ReturnType<typeof useContractInstallmentSelectLazyQuery>;
export type ContractInstallmentSelectQueryResult = Apollo.QueryResult<
	ContractInstallmentSelectQuery,
	ContractInstallmentSelectQueryVariables
>;
export const ContractInstallmentUpdateDocument = gql`
	mutation contractInstallmentUpdate($id: ID!, $input: ContractInstallmentUpdateInput!) {
		updateContractInstallment(id: $id, input: $input) {
			...ContractInstallmentDetail
		}
	}
	${ContractInstallmentDetailFragmentDoc}
`;
export type ContractInstallmentUpdateMutationFn = Apollo.MutationFunction<
	ContractInstallmentUpdateMutation,
	ContractInstallmentUpdateMutationVariables
>;

/**
 * __useContractInstallmentUpdateMutation__
 *
 * To run a mutation, you first call `useContractInstallmentUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContractInstallmentUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contractInstallmentUpdateMutation, { data, loading, error }] = useContractInstallmentUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContractInstallmentUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<
		ContractInstallmentUpdateMutation,
		ContractInstallmentUpdateMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<ContractInstallmentUpdateMutation, ContractInstallmentUpdateMutationVariables>(
		ContractInstallmentUpdateDocument,
		options,
	);
}
export type ContractInstallmentUpdateMutationHookResult = ReturnType<typeof useContractInstallmentUpdateMutation>;
export type ContractInstallmentUpdateMutationResult = Apollo.MutationResult<ContractInstallmentUpdateMutation>;
export type ContractInstallmentUpdateMutationOptions = Apollo.BaseMutationOptions<
	ContractInstallmentUpdateMutation,
	ContractInstallmentUpdateMutationVariables
>;
export const CourseCountDocument = gql`
	query courseCount($filters: [CourseFilterInput!], $pagination: Pagination, $order: CourseOrder) {
		courseConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useCourseCountQuery__
 *
 * To run a query within a React component, call `useCourseCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useCourseCountQuery(
	baseOptions?: Apollo.QueryHookOptions<CourseCountQuery, CourseCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<CourseCountQuery, CourseCountQueryVariables>(CourseCountDocument, options);
}
export function useCourseCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<CourseCountQuery, CourseCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<CourseCountQuery, CourseCountQueryVariables>(CourseCountDocument, options);
}
export type CourseCountQueryHookResult = ReturnType<typeof useCourseCountQuery>;
export type CourseCountLazyQueryHookResult = ReturnType<typeof useCourseCountLazyQuery>;
export type CourseCountQueryResult = Apollo.QueryResult<CourseCountQuery, CourseCountQueryVariables>;
export const CourseCreateDocument = gql`
	mutation courseCreate($input: CourseCreateInput!) {
		createCourse(input: $input) {
			...CourseDetail
		}
	}
	${CourseDetailFragmentDoc}
`;
export type CourseCreateMutationFn = Apollo.MutationFunction<CourseCreateMutation, CourseCreateMutationVariables>;

/**
 * __useCourseCreateMutation__
 *
 * To run a mutation, you first call `useCourseCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCourseCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [courseCreateMutation, { data, loading, error }] = useCourseCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCourseCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<CourseCreateMutation, CourseCreateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<CourseCreateMutation, CourseCreateMutationVariables>(CourseCreateDocument, options);
}
export type CourseCreateMutationHookResult = ReturnType<typeof useCourseCreateMutation>;
export type CourseCreateMutationResult = Apollo.MutationResult<CourseCreateMutation>;
export type CourseCreateMutationOptions = Apollo.BaseMutationOptions<
	CourseCreateMutation,
	CourseCreateMutationVariables
>;
export const CourseDeleteDocument = gql`
	mutation courseDelete($id: ID!) {
		deleteCourse(id: $id)
	}
`;
export type CourseDeleteMutationFn = Apollo.MutationFunction<CourseDeleteMutation, CourseDeleteMutationVariables>;

/**
 * __useCourseDeleteMutation__
 *
 * To run a mutation, you first call `useCourseDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCourseDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [courseDeleteMutation, { data, loading, error }] = useCourseDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCourseDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<CourseDeleteMutation, CourseDeleteMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<CourseDeleteMutation, CourseDeleteMutationVariables>(CourseDeleteDocument, options);
}
export type CourseDeleteMutationHookResult = ReturnType<typeof useCourseDeleteMutation>;
export type CourseDeleteMutationResult = Apollo.MutationResult<CourseDeleteMutation>;
export type CourseDeleteMutationOptions = Apollo.BaseMutationOptions<
	CourseDeleteMutation,
	CourseDeleteMutationVariables
>;
export const CourseDetailDocument = gql`
	query courseDetail($id: ID!) {
		course(id: $id) {
			...CourseDetail
		}
	}
	${CourseDetailFragmentDoc}
`;

/**
 * __useCourseDetailQuery__
 *
 * To run a query within a React component, call `useCourseDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCourseDetailQuery(
	baseOptions: Apollo.QueryHookOptions<CourseDetailQuery, CourseDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<CourseDetailQuery, CourseDetailQueryVariables>(CourseDetailDocument, options);
}
export function useCourseDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<CourseDetailQuery, CourseDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<CourseDetailQuery, CourseDetailQueryVariables>(CourseDetailDocument, options);
}
export type CourseDetailQueryHookResult = ReturnType<typeof useCourseDetailQuery>;
export type CourseDetailLazyQueryHookResult = ReturnType<typeof useCourseDetailLazyQuery>;
export type CourseDetailQueryResult = Apollo.QueryResult<CourseDetailQuery, CourseDetailQueryVariables>;
export const CourseIndexDocument = gql`
	query courseIndex($filters: [CourseFilterInput!], $pagination: Pagination, $order: CourseOrder) {
		courseConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					name
					description
					subject
					studentCapacity
				}
			}
		}
	}
`;

/**
 * __useCourseIndexQuery__
 *
 * To run a query within a React component, call `useCourseIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useCourseIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<CourseIndexQuery, CourseIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<CourseIndexQuery, CourseIndexQueryVariables>(CourseIndexDocument, options);
}
export function useCourseIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<CourseIndexQuery, CourseIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<CourseIndexQuery, CourseIndexQueryVariables>(CourseIndexDocument, options);
}
export type CourseIndexQueryHookResult = ReturnType<typeof useCourseIndexQuery>;
export type CourseIndexLazyQueryHookResult = ReturnType<typeof useCourseIndexLazyQuery>;
export type CourseIndexQueryResult = Apollo.QueryResult<CourseIndexQuery, CourseIndexQueryVariables>;
export const CourseNameDocument = gql`
	query courseName($id: ID!) {
		course(id: $id) {
			id
			...CourseName
		}
	}
	${CourseNameFragmentDoc}
`;

/**
 * __useCourseNameQuery__
 *
 * To run a query within a React component, call `useCourseNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCourseNameQuery(baseOptions: Apollo.QueryHookOptions<CourseNameQuery, CourseNameQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<CourseNameQuery, CourseNameQueryVariables>(CourseNameDocument, options);
}
export function useCourseNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<CourseNameQuery, CourseNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<CourseNameQuery, CourseNameQueryVariables>(CourseNameDocument, options);
}
export type CourseNameQueryHookResult = ReturnType<typeof useCourseNameQuery>;
export type CourseNameLazyQueryHookResult = ReturnType<typeof useCourseNameLazyQuery>;
export type CourseNameQueryResult = Apollo.QueryResult<CourseNameQuery, CourseNameQueryVariables>;
export const CourseNamesDocument = gql`
	query courseNames($filters: [CourseFilterInput!], $pagination: Pagination, $order: CourseOrder) {
		courseConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...CourseName
				}
			}
		}
	}
	${CourseNameFragmentDoc}
`;

/**
 * __useCourseNamesQuery__
 *
 * To run a query within a React component, call `useCourseNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useCourseNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<CourseNamesQuery, CourseNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<CourseNamesQuery, CourseNamesQueryVariables>(CourseNamesDocument, options);
}
export function useCourseNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<CourseNamesQuery, CourseNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<CourseNamesQuery, CourseNamesQueryVariables>(CourseNamesDocument, options);
}
export type CourseNamesQueryHookResult = ReturnType<typeof useCourseNamesQuery>;
export type CourseNamesLazyQueryHookResult = ReturnType<typeof useCourseNamesLazyQuery>;
export type CourseNamesQueryResult = Apollo.QueryResult<CourseNamesQuery, CourseNamesQueryVariables>;
export const CoursePaginationDocument = gql`
	query coursePagination($filters: [CourseFilterInput!], $order: CourseOrder) {
		courseConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useCoursePaginationQuery__
 *
 * To run a query within a React component, call `useCoursePaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoursePaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoursePaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useCoursePaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<CoursePaginationQuery, CoursePaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<CoursePaginationQuery, CoursePaginationQueryVariables>(CoursePaginationDocument, options);
}
export function useCoursePaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<CoursePaginationQuery, CoursePaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<CoursePaginationQuery, CoursePaginationQueryVariables>(CoursePaginationDocument, options);
}
export type CoursePaginationQueryHookResult = ReturnType<typeof useCoursePaginationQuery>;
export type CoursePaginationLazyQueryHookResult = ReturnType<typeof useCoursePaginationLazyQuery>;
export type CoursePaginationQueryResult = Apollo.QueryResult<CoursePaginationQuery, CoursePaginationQueryVariables>;
export const CourseSelectDocument = gql`
	query courseSelect($filters: [CourseFilterInput!], $order: CourseOrder) {
		courseConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...CourseName
				}
			}
		}
	}
	${CourseNameFragmentDoc}
`;

/**
 * __useCourseSelectQuery__
 *
 * To run a query within a React component, call `useCourseSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useCourseSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<CourseSelectQuery, CourseSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<CourseSelectQuery, CourseSelectQueryVariables>(CourseSelectDocument, options);
}
export function useCourseSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<CourseSelectQuery, CourseSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<CourseSelectQuery, CourseSelectQueryVariables>(CourseSelectDocument, options);
}
export type CourseSelectQueryHookResult = ReturnType<typeof useCourseSelectQuery>;
export type CourseSelectLazyQueryHookResult = ReturnType<typeof useCourseSelectLazyQuery>;
export type CourseSelectQueryResult = Apollo.QueryResult<CourseSelectQuery, CourseSelectQueryVariables>;
export const CourseUpdateDocument = gql`
	mutation courseUpdate($id: ID!, $input: CourseUpdateInput!) {
		updateCourse(id: $id, input: $input) {
			...CourseDetail
		}
	}
	${CourseDetailFragmentDoc}
`;
export type CourseUpdateMutationFn = Apollo.MutationFunction<CourseUpdateMutation, CourseUpdateMutationVariables>;

/**
 * __useCourseUpdateMutation__
 *
 * To run a mutation, you first call `useCourseUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCourseUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [courseUpdateMutation, { data, loading, error }] = useCourseUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCourseUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<CourseUpdateMutation, CourseUpdateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<CourseUpdateMutation, CourseUpdateMutationVariables>(CourseUpdateDocument, options);
}
export type CourseUpdateMutationHookResult = ReturnType<typeof useCourseUpdateMutation>;
export type CourseUpdateMutationResult = Apollo.MutationResult<CourseUpdateMutation>;
export type CourseUpdateMutationOptions = Apollo.BaseMutationOptions<
	CourseUpdateMutation,
	CourseUpdateMutationVariables
>;
export const CourseAttendanceRecordCountDocument = gql`
	query courseAttendanceRecordCount(
		$filters: [CourseAttendanceRecordFilterInput!]
		$pagination: Pagination
		$order: CourseAttendanceRecordOrder
	) {
		courseAttendanceRecordConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useCourseAttendanceRecordCountQuery__
 *
 * To run a query within a React component, call `useCourseAttendanceRecordCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseAttendanceRecordCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseAttendanceRecordCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useCourseAttendanceRecordCountQuery(
	baseOptions?: Apollo.QueryHookOptions<CourseAttendanceRecordCountQuery, CourseAttendanceRecordCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<CourseAttendanceRecordCountQuery, CourseAttendanceRecordCountQueryVariables>(
		CourseAttendanceRecordCountDocument,
		options,
	);
}
export function useCourseAttendanceRecordCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		CourseAttendanceRecordCountQuery,
		CourseAttendanceRecordCountQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<CourseAttendanceRecordCountQuery, CourseAttendanceRecordCountQueryVariables>(
		CourseAttendanceRecordCountDocument,
		options,
	);
}
export type CourseAttendanceRecordCountQueryHookResult = ReturnType<typeof useCourseAttendanceRecordCountQuery>;
export type CourseAttendanceRecordCountLazyQueryHookResult = ReturnType<typeof useCourseAttendanceRecordCountLazyQuery>;
export type CourseAttendanceRecordCountQueryResult = Apollo.QueryResult<
	CourseAttendanceRecordCountQuery,
	CourseAttendanceRecordCountQueryVariables
>;
export const CourseAttendanceRecordListForCoursePeriodDocument = gql`
	query courseAttendanceRecordListForCoursePeriod($courseSectionPeriodID: ID!, $date: Time!) {
		courseAttendanceRecordConnection(
			filters: { courseSectionPeriodIDEQ: $courseSectionPeriodID, attendanceDateEQ: $date }
		) {
			edges {
				node {
					id
					attendanceDate
					isPresent
					isLate
					isExcused
					notes
					studentID
				}
			}
		}
	}
`;

/**
 * __useCourseAttendanceRecordListForCoursePeriodQuery__
 *
 * To run a query within a React component, call `useCourseAttendanceRecordListForCoursePeriodQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseAttendanceRecordListForCoursePeriodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseAttendanceRecordListForCoursePeriodQuery({
 *   variables: {
 *      courseSectionPeriodID: // value for 'courseSectionPeriodID'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useCourseAttendanceRecordListForCoursePeriodQuery(
	baseOptions: Apollo.QueryHookOptions<
		CourseAttendanceRecordListForCoursePeriodQuery,
		CourseAttendanceRecordListForCoursePeriodQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		CourseAttendanceRecordListForCoursePeriodQuery,
		CourseAttendanceRecordListForCoursePeriodQueryVariables
	>(CourseAttendanceRecordListForCoursePeriodDocument, options);
}
export function useCourseAttendanceRecordListForCoursePeriodLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		CourseAttendanceRecordListForCoursePeriodQuery,
		CourseAttendanceRecordListForCoursePeriodQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		CourseAttendanceRecordListForCoursePeriodQuery,
		CourseAttendanceRecordListForCoursePeriodQueryVariables
	>(CourseAttendanceRecordListForCoursePeriodDocument, options);
}
export type CourseAttendanceRecordListForCoursePeriodQueryHookResult = ReturnType<
	typeof useCourseAttendanceRecordListForCoursePeriodQuery
>;
export type CourseAttendanceRecordListForCoursePeriodLazyQueryHookResult = ReturnType<
	typeof useCourseAttendanceRecordListForCoursePeriodLazyQuery
>;
export type CourseAttendanceRecordListForCoursePeriodQueryResult = Apollo.QueryResult<
	CourseAttendanceRecordListForCoursePeriodQuery,
	CourseAttendanceRecordListForCoursePeriodQueryVariables
>;
export const CourseAttendanceRecordsCreateBulkDocument = gql`
	mutation courseAttendanceRecordsCreateBulk($inputs: [CourseAttendanceRecordCreateInput!]!) {
		createBulkCourseAttendanceRecords(inputs: $inputs) {
			edges {
				node {
					...CourseAttendanceRecordDetail
				}
			}
		}
	}
	${CourseAttendanceRecordDetailFragmentDoc}
`;
export type CourseAttendanceRecordsCreateBulkMutationFn = Apollo.MutationFunction<
	CourseAttendanceRecordsCreateBulkMutation,
	CourseAttendanceRecordsCreateBulkMutationVariables
>;

/**
 * __useCourseAttendanceRecordsCreateBulkMutation__
 *
 * To run a mutation, you first call `useCourseAttendanceRecordsCreateBulkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCourseAttendanceRecordsCreateBulkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [courseAttendanceRecordsCreateBulkMutation, { data, loading, error }] = useCourseAttendanceRecordsCreateBulkMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useCourseAttendanceRecordsCreateBulkMutation(
	baseOptions?: Apollo.MutationHookOptions<
		CourseAttendanceRecordsCreateBulkMutation,
		CourseAttendanceRecordsCreateBulkMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		CourseAttendanceRecordsCreateBulkMutation,
		CourseAttendanceRecordsCreateBulkMutationVariables
	>(CourseAttendanceRecordsCreateBulkDocument, options);
}
export type CourseAttendanceRecordsCreateBulkMutationHookResult = ReturnType<
	typeof useCourseAttendanceRecordsCreateBulkMutation
>;
export type CourseAttendanceRecordsCreateBulkMutationResult =
	Apollo.MutationResult<CourseAttendanceRecordsCreateBulkMutation>;
export type CourseAttendanceRecordsCreateBulkMutationOptions = Apollo.BaseMutationOptions<
	CourseAttendanceRecordsCreateBulkMutation,
	CourseAttendanceRecordsCreateBulkMutationVariables
>;
export const CourseAttendanceRecordCreateDocument = gql`
	mutation courseAttendanceRecordCreate($input: CourseAttendanceRecordCreateInput!) {
		createCourseAttendanceRecord(input: $input) {
			...CourseAttendanceRecordDetail
		}
	}
	${CourseAttendanceRecordDetailFragmentDoc}
`;
export type CourseAttendanceRecordCreateMutationFn = Apollo.MutationFunction<
	CourseAttendanceRecordCreateMutation,
	CourseAttendanceRecordCreateMutationVariables
>;

/**
 * __useCourseAttendanceRecordCreateMutation__
 *
 * To run a mutation, you first call `useCourseAttendanceRecordCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCourseAttendanceRecordCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [courseAttendanceRecordCreateMutation, { data, loading, error }] = useCourseAttendanceRecordCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCourseAttendanceRecordCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<
		CourseAttendanceRecordCreateMutation,
		CourseAttendanceRecordCreateMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<CourseAttendanceRecordCreateMutation, CourseAttendanceRecordCreateMutationVariables>(
		CourseAttendanceRecordCreateDocument,
		options,
	);
}
export type CourseAttendanceRecordCreateMutationHookResult = ReturnType<typeof useCourseAttendanceRecordCreateMutation>;
export type CourseAttendanceRecordCreateMutationResult = Apollo.MutationResult<CourseAttendanceRecordCreateMutation>;
export type CourseAttendanceRecordCreateMutationOptions = Apollo.BaseMutationOptions<
	CourseAttendanceRecordCreateMutation,
	CourseAttendanceRecordCreateMutationVariables
>;
export const CourseAttendanceRecordDeleteDocument = gql`
	mutation courseAttendanceRecordDelete($id: ID!) {
		deleteCourseAttendanceRecord(id: $id)
	}
`;
export type CourseAttendanceRecordDeleteMutationFn = Apollo.MutationFunction<
	CourseAttendanceRecordDeleteMutation,
	CourseAttendanceRecordDeleteMutationVariables
>;

/**
 * __useCourseAttendanceRecordDeleteMutation__
 *
 * To run a mutation, you first call `useCourseAttendanceRecordDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCourseAttendanceRecordDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [courseAttendanceRecordDeleteMutation, { data, loading, error }] = useCourseAttendanceRecordDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCourseAttendanceRecordDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<
		CourseAttendanceRecordDeleteMutation,
		CourseAttendanceRecordDeleteMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<CourseAttendanceRecordDeleteMutation, CourseAttendanceRecordDeleteMutationVariables>(
		CourseAttendanceRecordDeleteDocument,
		options,
	);
}
export type CourseAttendanceRecordDeleteMutationHookResult = ReturnType<typeof useCourseAttendanceRecordDeleteMutation>;
export type CourseAttendanceRecordDeleteMutationResult = Apollo.MutationResult<CourseAttendanceRecordDeleteMutation>;
export type CourseAttendanceRecordDeleteMutationOptions = Apollo.BaseMutationOptions<
	CourseAttendanceRecordDeleteMutation,
	CourseAttendanceRecordDeleteMutationVariables
>;
export const CourseAttendanceRecordDetailDocument = gql`
	query courseAttendanceRecordDetail($id: ID!) {
		courseAttendanceRecord(id: $id) {
			...CourseAttendanceRecordDetail
		}
	}
	${CourseAttendanceRecordDetailFragmentDoc}
`;

/**
 * __useCourseAttendanceRecordDetailQuery__
 *
 * To run a query within a React component, call `useCourseAttendanceRecordDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseAttendanceRecordDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseAttendanceRecordDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCourseAttendanceRecordDetailQuery(
	baseOptions: Apollo.QueryHookOptions<CourseAttendanceRecordDetailQuery, CourseAttendanceRecordDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<CourseAttendanceRecordDetailQuery, CourseAttendanceRecordDetailQueryVariables>(
		CourseAttendanceRecordDetailDocument,
		options,
	);
}
export function useCourseAttendanceRecordDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		CourseAttendanceRecordDetailQuery,
		CourseAttendanceRecordDetailQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<CourseAttendanceRecordDetailQuery, CourseAttendanceRecordDetailQueryVariables>(
		CourseAttendanceRecordDetailDocument,
		options,
	);
}
export type CourseAttendanceRecordDetailQueryHookResult = ReturnType<typeof useCourseAttendanceRecordDetailQuery>;
export type CourseAttendanceRecordDetailLazyQueryHookResult = ReturnType<
	typeof useCourseAttendanceRecordDetailLazyQuery
>;
export type CourseAttendanceRecordDetailQueryResult = Apollo.QueryResult<
	CourseAttendanceRecordDetailQuery,
	CourseAttendanceRecordDetailQueryVariables
>;
export const CourseAttendanceRecordIndexDocument = gql`
	query courseAttendanceRecordIndex(
		$filters: [CourseAttendanceRecordFilterInput!]
		$pagination: Pagination
		$order: CourseAttendanceRecordOrder
	) {
		courseAttendanceRecordConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					attendanceDate
					isPresent
					isLate
					isExcused
					notes
				}
			}
		}
	}
`;

/**
 * __useCourseAttendanceRecordIndexQuery__
 *
 * To run a query within a React component, call `useCourseAttendanceRecordIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseAttendanceRecordIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseAttendanceRecordIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useCourseAttendanceRecordIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<CourseAttendanceRecordIndexQuery, CourseAttendanceRecordIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<CourseAttendanceRecordIndexQuery, CourseAttendanceRecordIndexQueryVariables>(
		CourseAttendanceRecordIndexDocument,
		options,
	);
}
export function useCourseAttendanceRecordIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		CourseAttendanceRecordIndexQuery,
		CourseAttendanceRecordIndexQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<CourseAttendanceRecordIndexQuery, CourseAttendanceRecordIndexQueryVariables>(
		CourseAttendanceRecordIndexDocument,
		options,
	);
}
export type CourseAttendanceRecordIndexQueryHookResult = ReturnType<typeof useCourseAttendanceRecordIndexQuery>;
export type CourseAttendanceRecordIndexLazyQueryHookResult = ReturnType<typeof useCourseAttendanceRecordIndexLazyQuery>;
export type CourseAttendanceRecordIndexQueryResult = Apollo.QueryResult<
	CourseAttendanceRecordIndexQuery,
	CourseAttendanceRecordIndexQueryVariables
>;
export const CourseAttendanceRecordNameDocument = gql`
	query courseAttendanceRecordName($id: ID!) {
		courseAttendanceRecord(id: $id) {
			id
			...CourseAttendanceRecordName
		}
	}
	${CourseAttendanceRecordNameFragmentDoc}
`;

/**
 * __useCourseAttendanceRecordNameQuery__
 *
 * To run a query within a React component, call `useCourseAttendanceRecordNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseAttendanceRecordNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseAttendanceRecordNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCourseAttendanceRecordNameQuery(
	baseOptions: Apollo.QueryHookOptions<CourseAttendanceRecordNameQuery, CourseAttendanceRecordNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<CourseAttendanceRecordNameQuery, CourseAttendanceRecordNameQueryVariables>(
		CourseAttendanceRecordNameDocument,
		options,
	);
}
export function useCourseAttendanceRecordNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<CourseAttendanceRecordNameQuery, CourseAttendanceRecordNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<CourseAttendanceRecordNameQuery, CourseAttendanceRecordNameQueryVariables>(
		CourseAttendanceRecordNameDocument,
		options,
	);
}
export type CourseAttendanceRecordNameQueryHookResult = ReturnType<typeof useCourseAttendanceRecordNameQuery>;
export type CourseAttendanceRecordNameLazyQueryHookResult = ReturnType<typeof useCourseAttendanceRecordNameLazyQuery>;
export type CourseAttendanceRecordNameQueryResult = Apollo.QueryResult<
	CourseAttendanceRecordNameQuery,
	CourseAttendanceRecordNameQueryVariables
>;
export const CourseAttendanceRecordNamesDocument = gql`
	query courseAttendanceRecordNames(
		$filters: [CourseAttendanceRecordFilterInput!]
		$pagination: Pagination
		$order: CourseAttendanceRecordOrder
	) {
		courseAttendanceRecordConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...CourseAttendanceRecordName
				}
			}
		}
	}
	${CourseAttendanceRecordNameFragmentDoc}
`;

/**
 * __useCourseAttendanceRecordNamesQuery__
 *
 * To run a query within a React component, call `useCourseAttendanceRecordNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseAttendanceRecordNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseAttendanceRecordNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useCourseAttendanceRecordNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<CourseAttendanceRecordNamesQuery, CourseAttendanceRecordNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<CourseAttendanceRecordNamesQuery, CourseAttendanceRecordNamesQueryVariables>(
		CourseAttendanceRecordNamesDocument,
		options,
	);
}
export function useCourseAttendanceRecordNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		CourseAttendanceRecordNamesQuery,
		CourseAttendanceRecordNamesQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<CourseAttendanceRecordNamesQuery, CourseAttendanceRecordNamesQueryVariables>(
		CourseAttendanceRecordNamesDocument,
		options,
	);
}
export type CourseAttendanceRecordNamesQueryHookResult = ReturnType<typeof useCourseAttendanceRecordNamesQuery>;
export type CourseAttendanceRecordNamesLazyQueryHookResult = ReturnType<typeof useCourseAttendanceRecordNamesLazyQuery>;
export type CourseAttendanceRecordNamesQueryResult = Apollo.QueryResult<
	CourseAttendanceRecordNamesQuery,
	CourseAttendanceRecordNamesQueryVariables
>;
export const CourseAttendanceRecordPaginationDocument = gql`
	query courseAttendanceRecordPagination(
		$filters: [CourseAttendanceRecordFilterInput!]
		$order: CourseAttendanceRecordOrder
	) {
		courseAttendanceRecordConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useCourseAttendanceRecordPaginationQuery__
 *
 * To run a query within a React component, call `useCourseAttendanceRecordPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseAttendanceRecordPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseAttendanceRecordPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useCourseAttendanceRecordPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<
		CourseAttendanceRecordPaginationQuery,
		CourseAttendanceRecordPaginationQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<CourseAttendanceRecordPaginationQuery, CourseAttendanceRecordPaginationQueryVariables>(
		CourseAttendanceRecordPaginationDocument,
		options,
	);
}
export function useCourseAttendanceRecordPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		CourseAttendanceRecordPaginationQuery,
		CourseAttendanceRecordPaginationQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<CourseAttendanceRecordPaginationQuery, CourseAttendanceRecordPaginationQueryVariables>(
		CourseAttendanceRecordPaginationDocument,
		options,
	);
}
export type CourseAttendanceRecordPaginationQueryHookResult = ReturnType<
	typeof useCourseAttendanceRecordPaginationQuery
>;
export type CourseAttendanceRecordPaginationLazyQueryHookResult = ReturnType<
	typeof useCourseAttendanceRecordPaginationLazyQuery
>;
export type CourseAttendanceRecordPaginationQueryResult = Apollo.QueryResult<
	CourseAttendanceRecordPaginationQuery,
	CourseAttendanceRecordPaginationQueryVariables
>;
export const CourseAttendanceRecordSelectDocument = gql`
	query courseAttendanceRecordSelect(
		$filters: [CourseAttendanceRecordFilterInput!]
		$order: CourseAttendanceRecordOrder
	) {
		courseAttendanceRecordConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...CourseAttendanceRecordName
				}
			}
		}
	}
	${CourseAttendanceRecordNameFragmentDoc}
`;

/**
 * __useCourseAttendanceRecordSelectQuery__
 *
 * To run a query within a React component, call `useCourseAttendanceRecordSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseAttendanceRecordSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseAttendanceRecordSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useCourseAttendanceRecordSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<CourseAttendanceRecordSelectQuery, CourseAttendanceRecordSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<CourseAttendanceRecordSelectQuery, CourseAttendanceRecordSelectQueryVariables>(
		CourseAttendanceRecordSelectDocument,
		options,
	);
}
export function useCourseAttendanceRecordSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		CourseAttendanceRecordSelectQuery,
		CourseAttendanceRecordSelectQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<CourseAttendanceRecordSelectQuery, CourseAttendanceRecordSelectQueryVariables>(
		CourseAttendanceRecordSelectDocument,
		options,
	);
}
export type CourseAttendanceRecordSelectQueryHookResult = ReturnType<typeof useCourseAttendanceRecordSelectQuery>;
export type CourseAttendanceRecordSelectLazyQueryHookResult = ReturnType<
	typeof useCourseAttendanceRecordSelectLazyQuery
>;
export type CourseAttendanceRecordSelectQueryResult = Apollo.QueryResult<
	CourseAttendanceRecordSelectQuery,
	CourseAttendanceRecordSelectQueryVariables
>;
export const CourseAttendanceRecordUpdateDocument = gql`
	mutation courseAttendanceRecordUpdate($id: ID!, $input: CourseAttendanceRecordUpdateInput!) {
		updateCourseAttendanceRecord(id: $id, input: $input) {
			...CourseAttendanceRecordDetail
		}
	}
	${CourseAttendanceRecordDetailFragmentDoc}
`;
export type CourseAttendanceRecordUpdateMutationFn = Apollo.MutationFunction<
	CourseAttendanceRecordUpdateMutation,
	CourseAttendanceRecordUpdateMutationVariables
>;

/**
 * __useCourseAttendanceRecordUpdateMutation__
 *
 * To run a mutation, you first call `useCourseAttendanceRecordUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCourseAttendanceRecordUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [courseAttendanceRecordUpdateMutation, { data, loading, error }] = useCourseAttendanceRecordUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCourseAttendanceRecordUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<
		CourseAttendanceRecordUpdateMutation,
		CourseAttendanceRecordUpdateMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<CourseAttendanceRecordUpdateMutation, CourseAttendanceRecordUpdateMutationVariables>(
		CourseAttendanceRecordUpdateDocument,
		options,
	);
}
export type CourseAttendanceRecordUpdateMutationHookResult = ReturnType<typeof useCourseAttendanceRecordUpdateMutation>;
export type CourseAttendanceRecordUpdateMutationResult = Apollo.MutationResult<CourseAttendanceRecordUpdateMutation>;
export type CourseAttendanceRecordUpdateMutationOptions = Apollo.BaseMutationOptions<
	CourseAttendanceRecordUpdateMutation,
	CourseAttendanceRecordUpdateMutationVariables
>;
export const CourseSectionCountDocument = gql`
	query courseSectionCount($filters: [CourseSectionFilterInput!], $pagination: Pagination, $order: CourseSectionOrder) {
		courseSectionConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useCourseSectionCountQuery__
 *
 * To run a query within a React component, call `useCourseSectionCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseSectionCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseSectionCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useCourseSectionCountQuery(
	baseOptions?: Apollo.QueryHookOptions<CourseSectionCountQuery, CourseSectionCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<CourseSectionCountQuery, CourseSectionCountQueryVariables>(
		CourseSectionCountDocument,
		options,
	);
}
export function useCourseSectionCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<CourseSectionCountQuery, CourseSectionCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<CourseSectionCountQuery, CourseSectionCountQueryVariables>(
		CourseSectionCountDocument,
		options,
	);
}
export type CourseSectionCountQueryHookResult = ReturnType<typeof useCourseSectionCountQuery>;
export type CourseSectionCountLazyQueryHookResult = ReturnType<typeof useCourseSectionCountLazyQuery>;
export type CourseSectionCountQueryResult = Apollo.QueryResult<
	CourseSectionCountQuery,
	CourseSectionCountQueryVariables
>;
export const CourseSectionsCreateBulkDocument = gql`
	mutation courseSectionsCreateBulk($inputs: [CourseSectionCreateInput!]!) {
		createBulkCourseSections(inputs: $inputs) {
			edges {
				node {
					...CourseSectionDetail
				}
			}
		}
	}
	${CourseSectionDetailFragmentDoc}
`;
export type CourseSectionsCreateBulkMutationFn = Apollo.MutationFunction<
	CourseSectionsCreateBulkMutation,
	CourseSectionsCreateBulkMutationVariables
>;

/**
 * __useCourseSectionsCreateBulkMutation__
 *
 * To run a mutation, you first call `useCourseSectionsCreateBulkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCourseSectionsCreateBulkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [courseSectionsCreateBulkMutation, { data, loading, error }] = useCourseSectionsCreateBulkMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useCourseSectionsCreateBulkMutation(
	baseOptions?: Apollo.MutationHookOptions<CourseSectionsCreateBulkMutation, CourseSectionsCreateBulkMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<CourseSectionsCreateBulkMutation, CourseSectionsCreateBulkMutationVariables>(
		CourseSectionsCreateBulkDocument,
		options,
	);
}
export type CourseSectionsCreateBulkMutationHookResult = ReturnType<typeof useCourseSectionsCreateBulkMutation>;
export type CourseSectionsCreateBulkMutationResult = Apollo.MutationResult<CourseSectionsCreateBulkMutation>;
export type CourseSectionsCreateBulkMutationOptions = Apollo.BaseMutationOptions<
	CourseSectionsCreateBulkMutation,
	CourseSectionsCreateBulkMutationVariables
>;
export const CourseSectionCreateDocument = gql`
	mutation courseSectionCreate($input: CourseSectionCreateInput!) {
		createCourseSection(input: $input) {
			...CourseSectionDetail
		}
	}
	${CourseSectionDetailFragmentDoc}
`;
export type CourseSectionCreateMutationFn = Apollo.MutationFunction<
	CourseSectionCreateMutation,
	CourseSectionCreateMutationVariables
>;

/**
 * __useCourseSectionCreateMutation__
 *
 * To run a mutation, you first call `useCourseSectionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCourseSectionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [courseSectionCreateMutation, { data, loading, error }] = useCourseSectionCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCourseSectionCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<CourseSectionCreateMutation, CourseSectionCreateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<CourseSectionCreateMutation, CourseSectionCreateMutationVariables>(
		CourseSectionCreateDocument,
		options,
	);
}
export type CourseSectionCreateMutationHookResult = ReturnType<typeof useCourseSectionCreateMutation>;
export type CourseSectionCreateMutationResult = Apollo.MutationResult<CourseSectionCreateMutation>;
export type CourseSectionCreateMutationOptions = Apollo.BaseMutationOptions<
	CourseSectionCreateMutation,
	CourseSectionCreateMutationVariables
>;
export const CourseSectionDeleteDocument = gql`
	mutation courseSectionDelete($id: ID!) {
		deleteCourseSection(id: $id)
	}
`;
export type CourseSectionDeleteMutationFn = Apollo.MutationFunction<
	CourseSectionDeleteMutation,
	CourseSectionDeleteMutationVariables
>;

/**
 * __useCourseSectionDeleteMutation__
 *
 * To run a mutation, you first call `useCourseSectionDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCourseSectionDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [courseSectionDeleteMutation, { data, loading, error }] = useCourseSectionDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCourseSectionDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<CourseSectionDeleteMutation, CourseSectionDeleteMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<CourseSectionDeleteMutation, CourseSectionDeleteMutationVariables>(
		CourseSectionDeleteDocument,
		options,
	);
}
export type CourseSectionDeleteMutationHookResult = ReturnType<typeof useCourseSectionDeleteMutation>;
export type CourseSectionDeleteMutationResult = Apollo.MutationResult<CourseSectionDeleteMutation>;
export type CourseSectionDeleteMutationOptions = Apollo.BaseMutationOptions<
	CourseSectionDeleteMutation,
	CourseSectionDeleteMutationVariables
>;
export const CourseSectionDetailDocument = gql`
	query courseSectionDetail($id: ID!) {
		courseSection(id: $id) {
			...CourseSectionDetail
		}
	}
	${CourseSectionDetailFragmentDoc}
`;

/**
 * __useCourseSectionDetailQuery__
 *
 * To run a query within a React component, call `useCourseSectionDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseSectionDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseSectionDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCourseSectionDetailQuery(
	baseOptions: Apollo.QueryHookOptions<CourseSectionDetailQuery, CourseSectionDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<CourseSectionDetailQuery, CourseSectionDetailQueryVariables>(
		CourseSectionDetailDocument,
		options,
	);
}
export function useCourseSectionDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<CourseSectionDetailQuery, CourseSectionDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<CourseSectionDetailQuery, CourseSectionDetailQueryVariables>(
		CourseSectionDetailDocument,
		options,
	);
}
export type CourseSectionDetailQueryHookResult = ReturnType<typeof useCourseSectionDetailQuery>;
export type CourseSectionDetailLazyQueryHookResult = ReturnType<typeof useCourseSectionDetailLazyQuery>;
export type CourseSectionDetailQueryResult = Apollo.QueryResult<
	CourseSectionDetailQuery,
	CourseSectionDetailQueryVariables
>;
export const CourseSectionIndexDocument = gql`
	query courseSectionIndex($filters: [CourseSectionFilterInput!], $pagination: Pagination, $order: CourseSectionOrder) {
		courseSectionConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					name
					description
					term {
						id
						name
					}
				}
			}
		}
	}
`;

/**
 * __useCourseSectionIndexQuery__
 *
 * To run a query within a React component, call `useCourseSectionIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseSectionIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseSectionIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useCourseSectionIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<CourseSectionIndexQuery, CourseSectionIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<CourseSectionIndexQuery, CourseSectionIndexQueryVariables>(
		CourseSectionIndexDocument,
		options,
	);
}
export function useCourseSectionIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<CourseSectionIndexQuery, CourseSectionIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<CourseSectionIndexQuery, CourseSectionIndexQueryVariables>(
		CourseSectionIndexDocument,
		options,
	);
}
export type CourseSectionIndexQueryHookResult = ReturnType<typeof useCourseSectionIndexQuery>;
export type CourseSectionIndexLazyQueryHookResult = ReturnType<typeof useCourseSectionIndexLazyQuery>;
export type CourseSectionIndexQueryResult = Apollo.QueryResult<
	CourseSectionIndexQuery,
	CourseSectionIndexQueryVariables
>;
export const CourseSectionTeacherLessonPlanOverviewListDocument = gql`
	query courseSectionTeacherLessonPlanOverviewList($teacherID: ID!, $startDate: Time!, $endDate: Time!) {
		courseSectionConnection(
			filters: {
				courseSectionTeacherEnrollments: [{ teacherIDEQ: $teacherID }]
				term: [{ startDateLTE: $startDate, endDateGT: $startDate }, { startDateLTE: $endDate, endDateGT: $endDate }]
			}
			order: { field: NAME, direction: ASC }
		) {
			edges {
				node {
					id
					name
					course {
						id
						name
						subject
					}
					homeRoomSection {
						id
						name
					}
					courseSectionPeriods(order: { field: DAY_OF_WEEK, direction: ASC }) {
						id
						dayOfWeek
					}
					lessonPlans(
						filters: { courseDateGTE: $startDate, courseDateLTE: $endDate }
						order: { field: COURSE_DATE, direction: ASC }
					) {
						id
						courseDate
					}
				}
			}
		}
	}
`;

/**
 * __useCourseSectionTeacherLessonPlanOverviewListQuery__
 *
 * To run a query within a React component, call `useCourseSectionTeacherLessonPlanOverviewListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseSectionTeacherLessonPlanOverviewListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseSectionTeacherLessonPlanOverviewListQuery({
 *   variables: {
 *      teacherID: // value for 'teacherID'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useCourseSectionTeacherLessonPlanOverviewListQuery(
	baseOptions: Apollo.QueryHookOptions<
		CourseSectionTeacherLessonPlanOverviewListQuery,
		CourseSectionTeacherLessonPlanOverviewListQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		CourseSectionTeacherLessonPlanOverviewListQuery,
		CourseSectionTeacherLessonPlanOverviewListQueryVariables
	>(CourseSectionTeacherLessonPlanOverviewListDocument, options);
}
export function useCourseSectionTeacherLessonPlanOverviewListLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		CourseSectionTeacherLessonPlanOverviewListQuery,
		CourseSectionTeacherLessonPlanOverviewListQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		CourseSectionTeacherLessonPlanOverviewListQuery,
		CourseSectionTeacherLessonPlanOverviewListQueryVariables
	>(CourseSectionTeacherLessonPlanOverviewListDocument, options);
}
export type CourseSectionTeacherLessonPlanOverviewListQueryHookResult = ReturnType<
	typeof useCourseSectionTeacherLessonPlanOverviewListQuery
>;
export type CourseSectionTeacherLessonPlanOverviewListLazyQueryHookResult = ReturnType<
	typeof useCourseSectionTeacherLessonPlanOverviewListLazyQuery
>;
export type CourseSectionTeacherLessonPlanOverviewListQueryResult = Apollo.QueryResult<
	CourseSectionTeacherLessonPlanOverviewListQuery,
	CourseSectionTeacherLessonPlanOverviewListQueryVariables
>;
export const CourseSectionHomeRoomSectionLessonPlanOverviewListDocument = gql`
	query courseSectionHomeRoomSectionLessonPlanOverviewList(
		$homeRoomSectionID: ID!
		$startDate: Time!
		$endDate: Time!
	) {
		courseSectionConnection(
			filters: { homeRoomSectionIDEQ: $homeRoomSectionID }
			order: { field: NAME, direction: ASC }
		) {
			edges {
				node {
					id
					name
					course {
						id
						name
						subject
					}
					homeRoomSection {
						id
						name
					}
					courseSectionPeriods(order: { field: DAY_OF_WEEK, direction: ASC }) {
						id
						dayOfWeek
					}
					lessonPlans(
						filters: { courseDateGTE: $startDate, courseDateLTE: $endDate }
						order: { field: COURSE_DATE, direction: ASC }
					) {
						id
						courseDate
					}
				}
			}
		}
	}
`;

/**
 * __useCourseSectionHomeRoomSectionLessonPlanOverviewListQuery__
 *
 * To run a query within a React component, call `useCourseSectionHomeRoomSectionLessonPlanOverviewListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseSectionHomeRoomSectionLessonPlanOverviewListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseSectionHomeRoomSectionLessonPlanOverviewListQuery({
 *   variables: {
 *      homeRoomSectionID: // value for 'homeRoomSectionID'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useCourseSectionHomeRoomSectionLessonPlanOverviewListQuery(
	baseOptions: Apollo.QueryHookOptions<
		CourseSectionHomeRoomSectionLessonPlanOverviewListQuery,
		CourseSectionHomeRoomSectionLessonPlanOverviewListQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		CourseSectionHomeRoomSectionLessonPlanOverviewListQuery,
		CourseSectionHomeRoomSectionLessonPlanOverviewListQueryVariables
	>(CourseSectionHomeRoomSectionLessonPlanOverviewListDocument, options);
}
export function useCourseSectionHomeRoomSectionLessonPlanOverviewListLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		CourseSectionHomeRoomSectionLessonPlanOverviewListQuery,
		CourseSectionHomeRoomSectionLessonPlanOverviewListQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		CourseSectionHomeRoomSectionLessonPlanOverviewListQuery,
		CourseSectionHomeRoomSectionLessonPlanOverviewListQueryVariables
	>(CourseSectionHomeRoomSectionLessonPlanOverviewListDocument, options);
}
export type CourseSectionHomeRoomSectionLessonPlanOverviewListQueryHookResult = ReturnType<
	typeof useCourseSectionHomeRoomSectionLessonPlanOverviewListQuery
>;
export type CourseSectionHomeRoomSectionLessonPlanOverviewListLazyQueryHookResult = ReturnType<
	typeof useCourseSectionHomeRoomSectionLessonPlanOverviewListLazyQuery
>;
export type CourseSectionHomeRoomSectionLessonPlanOverviewListQueryResult = Apollo.QueryResult<
	CourseSectionHomeRoomSectionLessonPlanOverviewListQuery,
	CourseSectionHomeRoomSectionLessonPlanOverviewListQueryVariables
>;
export const CourseSectionNameDocument = gql`
	query courseSectionName($id: ID!) {
		courseSection(id: $id) {
			id
			...CourseSectionName
		}
	}
	${CourseSectionNameFragmentDoc}
`;

/**
 * __useCourseSectionNameQuery__
 *
 * To run a query within a React component, call `useCourseSectionNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseSectionNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseSectionNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCourseSectionNameQuery(
	baseOptions: Apollo.QueryHookOptions<CourseSectionNameQuery, CourseSectionNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<CourseSectionNameQuery, CourseSectionNameQueryVariables>(CourseSectionNameDocument, options);
}
export function useCourseSectionNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<CourseSectionNameQuery, CourseSectionNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<CourseSectionNameQuery, CourseSectionNameQueryVariables>(
		CourseSectionNameDocument,
		options,
	);
}
export type CourseSectionNameQueryHookResult = ReturnType<typeof useCourseSectionNameQuery>;
export type CourseSectionNameLazyQueryHookResult = ReturnType<typeof useCourseSectionNameLazyQuery>;
export type CourseSectionNameQueryResult = Apollo.QueryResult<CourseSectionNameQuery, CourseSectionNameQueryVariables>;
export const CourseSectionNamesDocument = gql`
	query courseSectionNames($filters: [CourseSectionFilterInput!], $pagination: Pagination, $order: CourseSectionOrder) {
		courseSectionConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...CourseSectionName
				}
			}
		}
	}
	${CourseSectionNameFragmentDoc}
`;

/**
 * __useCourseSectionNamesQuery__
 *
 * To run a query within a React component, call `useCourseSectionNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseSectionNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseSectionNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useCourseSectionNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<CourseSectionNamesQuery, CourseSectionNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<CourseSectionNamesQuery, CourseSectionNamesQueryVariables>(
		CourseSectionNamesDocument,
		options,
	);
}
export function useCourseSectionNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<CourseSectionNamesQuery, CourseSectionNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<CourseSectionNamesQuery, CourseSectionNamesQueryVariables>(
		CourseSectionNamesDocument,
		options,
	);
}
export type CourseSectionNamesQueryHookResult = ReturnType<typeof useCourseSectionNamesQuery>;
export type CourseSectionNamesLazyQueryHookResult = ReturnType<typeof useCourseSectionNamesLazyQuery>;
export type CourseSectionNamesQueryResult = Apollo.QueryResult<
	CourseSectionNamesQuery,
	CourseSectionNamesQueryVariables
>;
export const CourseSectionPaginationDocument = gql`
	query courseSectionPagination($filters: [CourseSectionFilterInput!], $order: CourseSectionOrder) {
		courseSectionConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useCourseSectionPaginationQuery__
 *
 * To run a query within a React component, call `useCourseSectionPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseSectionPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseSectionPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useCourseSectionPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<CourseSectionPaginationQuery, CourseSectionPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<CourseSectionPaginationQuery, CourseSectionPaginationQueryVariables>(
		CourseSectionPaginationDocument,
		options,
	);
}
export function useCourseSectionPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<CourseSectionPaginationQuery, CourseSectionPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<CourseSectionPaginationQuery, CourseSectionPaginationQueryVariables>(
		CourseSectionPaginationDocument,
		options,
	);
}
export type CourseSectionPaginationQueryHookResult = ReturnType<typeof useCourseSectionPaginationQuery>;
export type CourseSectionPaginationLazyQueryHookResult = ReturnType<typeof useCourseSectionPaginationLazyQuery>;
export type CourseSectionPaginationQueryResult = Apollo.QueryResult<
	CourseSectionPaginationQuery,
	CourseSectionPaginationQueryVariables
>;
export const CourseSectionSelectDocument = gql`
	query courseSectionSelect($filters: [CourseSectionFilterInput!], $order: CourseSectionOrder) {
		courseSectionConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...CourseSectionName
				}
			}
		}
	}
	${CourseSectionNameFragmentDoc}
`;

/**
 * __useCourseSectionSelectQuery__
 *
 * To run a query within a React component, call `useCourseSectionSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseSectionSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseSectionSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useCourseSectionSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<CourseSectionSelectQuery, CourseSectionSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<CourseSectionSelectQuery, CourseSectionSelectQueryVariables>(
		CourseSectionSelectDocument,
		options,
	);
}
export function useCourseSectionSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<CourseSectionSelectQuery, CourseSectionSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<CourseSectionSelectQuery, CourseSectionSelectQueryVariables>(
		CourseSectionSelectDocument,
		options,
	);
}
export type CourseSectionSelectQueryHookResult = ReturnType<typeof useCourseSectionSelectQuery>;
export type CourseSectionSelectLazyQueryHookResult = ReturnType<typeof useCourseSectionSelectLazyQuery>;
export type CourseSectionSelectQueryResult = Apollo.QueryResult<
	CourseSectionSelectQuery,
	CourseSectionSelectQueryVariables
>;
export const CourseSectionUpdateDocument = gql`
	mutation courseSectionUpdate($id: ID!, $input: CourseSectionUpdateInput!) {
		updateCourseSection(id: $id, input: $input) {
			...CourseSectionDetail
		}
	}
	${CourseSectionDetailFragmentDoc}
`;
export type CourseSectionUpdateMutationFn = Apollo.MutationFunction<
	CourseSectionUpdateMutation,
	CourseSectionUpdateMutationVariables
>;

/**
 * __useCourseSectionUpdateMutation__
 *
 * To run a mutation, you first call `useCourseSectionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCourseSectionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [courseSectionUpdateMutation, { data, loading, error }] = useCourseSectionUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCourseSectionUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<CourseSectionUpdateMutation, CourseSectionUpdateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<CourseSectionUpdateMutation, CourseSectionUpdateMutationVariables>(
		CourseSectionUpdateDocument,
		options,
	);
}
export type CourseSectionUpdateMutationHookResult = ReturnType<typeof useCourseSectionUpdateMutation>;
export type CourseSectionUpdateMutationResult = Apollo.MutationResult<CourseSectionUpdateMutation>;
export type CourseSectionUpdateMutationOptions = Apollo.BaseMutationOptions<
	CourseSectionUpdateMutation,
	CourseSectionUpdateMutationVariables
>;
export const WeeklyCourseScheduleDocument = gql`
	query weeklyCourseSchedule($filters: [CourseSectionFilterInput!]) {
		courseSectionConnection(filters: $filters) {
			edges {
				node {
					id
					name
					course {
						id
						subject
					}
					homeRoomSection {
						id
						name
					}
					courseSectionPeriods {
						id
						dayOfWeek
						startTimeMinutes
						endTimeMinutes
					}
				}
			}
		}
	}
`;

/**
 * __useWeeklyCourseScheduleQuery__
 *
 * To run a query within a React component, call `useWeeklyCourseScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useWeeklyCourseScheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWeeklyCourseScheduleQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useWeeklyCourseScheduleQuery(
	baseOptions?: Apollo.QueryHookOptions<WeeklyCourseScheduleQuery, WeeklyCourseScheduleQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<WeeklyCourseScheduleQuery, WeeklyCourseScheduleQueryVariables>(
		WeeklyCourseScheduleDocument,
		options,
	);
}
export function useWeeklyCourseScheduleLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<WeeklyCourseScheduleQuery, WeeklyCourseScheduleQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<WeeklyCourseScheduleQuery, WeeklyCourseScheduleQueryVariables>(
		WeeklyCourseScheduleDocument,
		options,
	);
}
export type WeeklyCourseScheduleQueryHookResult = ReturnType<typeof useWeeklyCourseScheduleQuery>;
export type WeeklyCourseScheduleLazyQueryHookResult = ReturnType<typeof useWeeklyCourseScheduleLazyQuery>;
export type WeeklyCourseScheduleQueryResult = Apollo.QueryResult<
	WeeklyCourseScheduleQuery,
	WeeklyCourseScheduleQueryVariables
>;
export const CourseSectionPeriodCountDocument = gql`
	query courseSectionPeriodCount(
		$filters: [CourseSectionPeriodFilterInput!]
		$pagination: Pagination
		$order: CourseSectionPeriodOrder
	) {
		courseSectionPeriodConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useCourseSectionPeriodCountQuery__
 *
 * To run a query within a React component, call `useCourseSectionPeriodCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseSectionPeriodCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseSectionPeriodCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useCourseSectionPeriodCountQuery(
	baseOptions?: Apollo.QueryHookOptions<CourseSectionPeriodCountQuery, CourseSectionPeriodCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<CourseSectionPeriodCountQuery, CourseSectionPeriodCountQueryVariables>(
		CourseSectionPeriodCountDocument,
		options,
	);
}
export function useCourseSectionPeriodCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<CourseSectionPeriodCountQuery, CourseSectionPeriodCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<CourseSectionPeriodCountQuery, CourseSectionPeriodCountQueryVariables>(
		CourseSectionPeriodCountDocument,
		options,
	);
}
export type CourseSectionPeriodCountQueryHookResult = ReturnType<typeof useCourseSectionPeriodCountQuery>;
export type CourseSectionPeriodCountLazyQueryHookResult = ReturnType<typeof useCourseSectionPeriodCountLazyQuery>;
export type CourseSectionPeriodCountQueryResult = Apollo.QueryResult<
	CourseSectionPeriodCountQuery,
	CourseSectionPeriodCountQueryVariables
>;
export const CourseSectionPeriodCreateDocument = gql`
	mutation courseSectionPeriodCreate($input: CourseSectionPeriodCreateInput!) {
		createCourseSectionPeriod(input: $input) {
			...CourseSectionPeriodDetail
		}
	}
	${CourseSectionPeriodDetailFragmentDoc}
`;
export type CourseSectionPeriodCreateMutationFn = Apollo.MutationFunction<
	CourseSectionPeriodCreateMutation,
	CourseSectionPeriodCreateMutationVariables
>;

/**
 * __useCourseSectionPeriodCreateMutation__
 *
 * To run a mutation, you first call `useCourseSectionPeriodCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCourseSectionPeriodCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [courseSectionPeriodCreateMutation, { data, loading, error }] = useCourseSectionPeriodCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCourseSectionPeriodCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<
		CourseSectionPeriodCreateMutation,
		CourseSectionPeriodCreateMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<CourseSectionPeriodCreateMutation, CourseSectionPeriodCreateMutationVariables>(
		CourseSectionPeriodCreateDocument,
		options,
	);
}
export type CourseSectionPeriodCreateMutationHookResult = ReturnType<typeof useCourseSectionPeriodCreateMutation>;
export type CourseSectionPeriodCreateMutationResult = Apollo.MutationResult<CourseSectionPeriodCreateMutation>;
export type CourseSectionPeriodCreateMutationOptions = Apollo.BaseMutationOptions<
	CourseSectionPeriodCreateMutation,
	CourseSectionPeriodCreateMutationVariables
>;
export const CourseSectionPeriodDeleteDocument = gql`
	mutation courseSectionPeriodDelete($id: ID!) {
		deleteCourseSectionPeriod(id: $id)
	}
`;
export type CourseSectionPeriodDeleteMutationFn = Apollo.MutationFunction<
	CourseSectionPeriodDeleteMutation,
	CourseSectionPeriodDeleteMutationVariables
>;

/**
 * __useCourseSectionPeriodDeleteMutation__
 *
 * To run a mutation, you first call `useCourseSectionPeriodDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCourseSectionPeriodDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [courseSectionPeriodDeleteMutation, { data, loading, error }] = useCourseSectionPeriodDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCourseSectionPeriodDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<
		CourseSectionPeriodDeleteMutation,
		CourseSectionPeriodDeleteMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<CourseSectionPeriodDeleteMutation, CourseSectionPeriodDeleteMutationVariables>(
		CourseSectionPeriodDeleteDocument,
		options,
	);
}
export type CourseSectionPeriodDeleteMutationHookResult = ReturnType<typeof useCourseSectionPeriodDeleteMutation>;
export type CourseSectionPeriodDeleteMutationResult = Apollo.MutationResult<CourseSectionPeriodDeleteMutation>;
export type CourseSectionPeriodDeleteMutationOptions = Apollo.BaseMutationOptions<
	CourseSectionPeriodDeleteMutation,
	CourseSectionPeriodDeleteMutationVariables
>;
export const CourseSectionPeriodDetailDocument = gql`
	query courseSectionPeriodDetail($id: ID!) {
		courseSectionPeriod(id: $id) {
			...CourseSectionPeriodDetail
		}
	}
	${CourseSectionPeriodDetailFragmentDoc}
`;

/**
 * __useCourseSectionPeriodDetailQuery__
 *
 * To run a query within a React component, call `useCourseSectionPeriodDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseSectionPeriodDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseSectionPeriodDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCourseSectionPeriodDetailQuery(
	baseOptions: Apollo.QueryHookOptions<CourseSectionPeriodDetailQuery, CourseSectionPeriodDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<CourseSectionPeriodDetailQuery, CourseSectionPeriodDetailQueryVariables>(
		CourseSectionPeriodDetailDocument,
		options,
	);
}
export function useCourseSectionPeriodDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<CourseSectionPeriodDetailQuery, CourseSectionPeriodDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<CourseSectionPeriodDetailQuery, CourseSectionPeriodDetailQueryVariables>(
		CourseSectionPeriodDetailDocument,
		options,
	);
}
export type CourseSectionPeriodDetailQueryHookResult = ReturnType<typeof useCourseSectionPeriodDetailQuery>;
export type CourseSectionPeriodDetailLazyQueryHookResult = ReturnType<typeof useCourseSectionPeriodDetailLazyQuery>;
export type CourseSectionPeriodDetailQueryResult = Apollo.QueryResult<
	CourseSectionPeriodDetailQuery,
	CourseSectionPeriodDetailQueryVariables
>;
export const CourseSectionPeriodHomeRoomSectionDailyScheduleDocument = gql`
	query courseSectionPeriodHomeRoomSectionDailySchedule($homeRoomSectionID: ID!, $dateDOW: Int!) {
		courseSectionPeriodConnection(
			filters: { dayOfWeekEQ: $dateDOW, courseSection: { homeRoomSection: [{ idEQ: $homeRoomSectionID }] } }
			order: { field: START_TIME_MINUTES, direction: ASC }
		) {
			edges {
				node {
					id
					dayOfWeek
					startTimeMinutes
					endTimeMinutes
					courseSection {
						id
						name
						course {
							id
							name
							subject
						}
					}
					room {
						id
						name
						number
					}
				}
			}
		}
	}
`;

/**
 * __useCourseSectionPeriodHomeRoomSectionDailyScheduleQuery__
 *
 * To run a query within a React component, call `useCourseSectionPeriodHomeRoomSectionDailyScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseSectionPeriodHomeRoomSectionDailyScheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseSectionPeriodHomeRoomSectionDailyScheduleQuery({
 *   variables: {
 *      homeRoomSectionID: // value for 'homeRoomSectionID'
 *      dateDOW: // value for 'dateDOW'
 *   },
 * });
 */
export function useCourseSectionPeriodHomeRoomSectionDailyScheduleQuery(
	baseOptions: Apollo.QueryHookOptions<
		CourseSectionPeriodHomeRoomSectionDailyScheduleQuery,
		CourseSectionPeriodHomeRoomSectionDailyScheduleQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		CourseSectionPeriodHomeRoomSectionDailyScheduleQuery,
		CourseSectionPeriodHomeRoomSectionDailyScheduleQueryVariables
	>(CourseSectionPeriodHomeRoomSectionDailyScheduleDocument, options);
}
export function useCourseSectionPeriodHomeRoomSectionDailyScheduleLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		CourseSectionPeriodHomeRoomSectionDailyScheduleQuery,
		CourseSectionPeriodHomeRoomSectionDailyScheduleQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		CourseSectionPeriodHomeRoomSectionDailyScheduleQuery,
		CourseSectionPeriodHomeRoomSectionDailyScheduleQueryVariables
	>(CourseSectionPeriodHomeRoomSectionDailyScheduleDocument, options);
}
export type CourseSectionPeriodHomeRoomSectionDailyScheduleQueryHookResult = ReturnType<
	typeof useCourseSectionPeriodHomeRoomSectionDailyScheduleQuery
>;
export type CourseSectionPeriodHomeRoomSectionDailyScheduleLazyQueryHookResult = ReturnType<
	typeof useCourseSectionPeriodHomeRoomSectionDailyScheduleLazyQuery
>;
export type CourseSectionPeriodHomeRoomSectionDailyScheduleQueryResult = Apollo.QueryResult<
	CourseSectionPeriodHomeRoomSectionDailyScheduleQuery,
	CourseSectionPeriodHomeRoomSectionDailyScheduleQueryVariables
>;
export const CourseSectionPeriodIndexDocument = gql`
	query courseSectionPeriodIndex(
		$filters: [CourseSectionPeriodFilterInput!]
		$pagination: Pagination
		$order: CourseSectionPeriodOrder
	) {
		courseSectionPeriodConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					dayOfWeek
					startTimeMinutes
					endTimeMinutes
				}
			}
		}
	}
`;

/**
 * __useCourseSectionPeriodIndexQuery__
 *
 * To run a query within a React component, call `useCourseSectionPeriodIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseSectionPeriodIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseSectionPeriodIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useCourseSectionPeriodIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<CourseSectionPeriodIndexQuery, CourseSectionPeriodIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<CourseSectionPeriodIndexQuery, CourseSectionPeriodIndexQueryVariables>(
		CourseSectionPeriodIndexDocument,
		options,
	);
}
export function useCourseSectionPeriodIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<CourseSectionPeriodIndexQuery, CourseSectionPeriodIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<CourseSectionPeriodIndexQuery, CourseSectionPeriodIndexQueryVariables>(
		CourseSectionPeriodIndexDocument,
		options,
	);
}
export type CourseSectionPeriodIndexQueryHookResult = ReturnType<typeof useCourseSectionPeriodIndexQuery>;
export type CourseSectionPeriodIndexLazyQueryHookResult = ReturnType<typeof useCourseSectionPeriodIndexLazyQuery>;
export type CourseSectionPeriodIndexQueryResult = Apollo.QueryResult<
	CourseSectionPeriodIndexQuery,
	CourseSectionPeriodIndexQueryVariables
>;
export const CourseSectionPeriodNameDocument = gql`
	query courseSectionPeriodName($id: ID!) {
		courseSectionPeriod(id: $id) {
			id
			...CourseSectionPeriodName
		}
	}
	${CourseSectionPeriodNameFragmentDoc}
`;

/**
 * __useCourseSectionPeriodNameQuery__
 *
 * To run a query within a React component, call `useCourseSectionPeriodNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseSectionPeriodNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseSectionPeriodNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCourseSectionPeriodNameQuery(
	baseOptions: Apollo.QueryHookOptions<CourseSectionPeriodNameQuery, CourseSectionPeriodNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<CourseSectionPeriodNameQuery, CourseSectionPeriodNameQueryVariables>(
		CourseSectionPeriodNameDocument,
		options,
	);
}
export function useCourseSectionPeriodNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<CourseSectionPeriodNameQuery, CourseSectionPeriodNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<CourseSectionPeriodNameQuery, CourseSectionPeriodNameQueryVariables>(
		CourseSectionPeriodNameDocument,
		options,
	);
}
export type CourseSectionPeriodNameQueryHookResult = ReturnType<typeof useCourseSectionPeriodNameQuery>;
export type CourseSectionPeriodNameLazyQueryHookResult = ReturnType<typeof useCourseSectionPeriodNameLazyQuery>;
export type CourseSectionPeriodNameQueryResult = Apollo.QueryResult<
	CourseSectionPeriodNameQuery,
	CourseSectionPeriodNameQueryVariables
>;
export const CourseSectionPeriodNamesDocument = gql`
	query courseSectionPeriodNames(
		$filters: [CourseSectionPeriodFilterInput!]
		$pagination: Pagination
		$order: CourseSectionPeriodOrder
	) {
		courseSectionPeriodConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...CourseSectionPeriodName
				}
			}
		}
	}
	${CourseSectionPeriodNameFragmentDoc}
`;

/**
 * __useCourseSectionPeriodNamesQuery__
 *
 * To run a query within a React component, call `useCourseSectionPeriodNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseSectionPeriodNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseSectionPeriodNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useCourseSectionPeriodNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<CourseSectionPeriodNamesQuery, CourseSectionPeriodNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<CourseSectionPeriodNamesQuery, CourseSectionPeriodNamesQueryVariables>(
		CourseSectionPeriodNamesDocument,
		options,
	);
}
export function useCourseSectionPeriodNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<CourseSectionPeriodNamesQuery, CourseSectionPeriodNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<CourseSectionPeriodNamesQuery, CourseSectionPeriodNamesQueryVariables>(
		CourseSectionPeriodNamesDocument,
		options,
	);
}
export type CourseSectionPeriodNamesQueryHookResult = ReturnType<typeof useCourseSectionPeriodNamesQuery>;
export type CourseSectionPeriodNamesLazyQueryHookResult = ReturnType<typeof useCourseSectionPeriodNamesLazyQuery>;
export type CourseSectionPeriodNamesQueryResult = Apollo.QueryResult<
	CourseSectionPeriodNamesQuery,
	CourseSectionPeriodNamesQueryVariables
>;
export const CourseSectionPeriodPaginationDocument = gql`
	query courseSectionPeriodPagination($filters: [CourseSectionPeriodFilterInput!], $order: CourseSectionPeriodOrder) {
		courseSectionPeriodConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useCourseSectionPeriodPaginationQuery__
 *
 * To run a query within a React component, call `useCourseSectionPeriodPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseSectionPeriodPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseSectionPeriodPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useCourseSectionPeriodPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<
		CourseSectionPeriodPaginationQuery,
		CourseSectionPeriodPaginationQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<CourseSectionPeriodPaginationQuery, CourseSectionPeriodPaginationQueryVariables>(
		CourseSectionPeriodPaginationDocument,
		options,
	);
}
export function useCourseSectionPeriodPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		CourseSectionPeriodPaginationQuery,
		CourseSectionPeriodPaginationQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<CourseSectionPeriodPaginationQuery, CourseSectionPeriodPaginationQueryVariables>(
		CourseSectionPeriodPaginationDocument,
		options,
	);
}
export type CourseSectionPeriodPaginationQueryHookResult = ReturnType<typeof useCourseSectionPeriodPaginationQuery>;
export type CourseSectionPeriodPaginationLazyQueryHookResult = ReturnType<
	typeof useCourseSectionPeriodPaginationLazyQuery
>;
export type CourseSectionPeriodPaginationQueryResult = Apollo.QueryResult<
	CourseSectionPeriodPaginationQuery,
	CourseSectionPeriodPaginationQueryVariables
>;
export const CourseSectionPeriodSelectDocument = gql`
	query courseSectionPeriodSelect($filters: [CourseSectionPeriodFilterInput!], $order: CourseSectionPeriodOrder) {
		courseSectionPeriodConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...CourseSectionPeriodName
				}
			}
		}
	}
	${CourseSectionPeriodNameFragmentDoc}
`;

/**
 * __useCourseSectionPeriodSelectQuery__
 *
 * To run a query within a React component, call `useCourseSectionPeriodSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseSectionPeriodSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseSectionPeriodSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useCourseSectionPeriodSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<CourseSectionPeriodSelectQuery, CourseSectionPeriodSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<CourseSectionPeriodSelectQuery, CourseSectionPeriodSelectQueryVariables>(
		CourseSectionPeriodSelectDocument,
		options,
	);
}
export function useCourseSectionPeriodSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<CourseSectionPeriodSelectQuery, CourseSectionPeriodSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<CourseSectionPeriodSelectQuery, CourseSectionPeriodSelectQueryVariables>(
		CourseSectionPeriodSelectDocument,
		options,
	);
}
export type CourseSectionPeriodSelectQueryHookResult = ReturnType<typeof useCourseSectionPeriodSelectQuery>;
export type CourseSectionPeriodSelectLazyQueryHookResult = ReturnType<typeof useCourseSectionPeriodSelectLazyQuery>;
export type CourseSectionPeriodSelectQueryResult = Apollo.QueryResult<
	CourseSectionPeriodSelectQuery,
	CourseSectionPeriodSelectQueryVariables
>;
export const CourseSectionPeriodStudentDailyScheduleDocument = gql`
	query courseSectionPeriodStudentDailySchedule($studentID: ID!, $date: Time!, $dateDOW: Int!) {
		courseSectionPeriodConnection(
			filters: {
				dayOfWeekEQ: $dateDOW
				courseSection: {
					courseSectionStudentEnrollments: [{ studentIDEQ: $studentID }]
					term: [{ startDateLTE: $date, endDateGTE: $date }]
				}
			}
			order: { field: START_TIME_MINUTES, direction: ASC }
		) {
			edges {
				node {
					id
					dayOfWeek
					startTimeMinutes
					endTimeMinutes
					courseSection {
						id
						name
						course {
							id
							name
							subject
						}
					}
					room {
						id
						name
						number
					}
				}
			}
		}
	}
`;

/**
 * __useCourseSectionPeriodStudentDailyScheduleQuery__
 *
 * To run a query within a React component, call `useCourseSectionPeriodStudentDailyScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseSectionPeriodStudentDailyScheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseSectionPeriodStudentDailyScheduleQuery({
 *   variables: {
 *      studentID: // value for 'studentID'
 *      date: // value for 'date'
 *      dateDOW: // value for 'dateDOW'
 *   },
 * });
 */
export function useCourseSectionPeriodStudentDailyScheduleQuery(
	baseOptions: Apollo.QueryHookOptions<
		CourseSectionPeriodStudentDailyScheduleQuery,
		CourseSectionPeriodStudentDailyScheduleQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		CourseSectionPeriodStudentDailyScheduleQuery,
		CourseSectionPeriodStudentDailyScheduleQueryVariables
	>(CourseSectionPeriodStudentDailyScheduleDocument, options);
}
export function useCourseSectionPeriodStudentDailyScheduleLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		CourseSectionPeriodStudentDailyScheduleQuery,
		CourseSectionPeriodStudentDailyScheduleQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		CourseSectionPeriodStudentDailyScheduleQuery,
		CourseSectionPeriodStudentDailyScheduleQueryVariables
	>(CourseSectionPeriodStudentDailyScheduleDocument, options);
}
export type CourseSectionPeriodStudentDailyScheduleQueryHookResult = ReturnType<
	typeof useCourseSectionPeriodStudentDailyScheduleQuery
>;
export type CourseSectionPeriodStudentDailyScheduleLazyQueryHookResult = ReturnType<
	typeof useCourseSectionPeriodStudentDailyScheduleLazyQuery
>;
export type CourseSectionPeriodStudentDailyScheduleQueryResult = Apollo.QueryResult<
	CourseSectionPeriodStudentDailyScheduleQuery,
	CourseSectionPeriodStudentDailyScheduleQueryVariables
>;
export const CourseSectionPeriodTeacherDailyScheduleDocument = gql`
	query courseSectionPeriodTeacherDailySchedule($teacherID: ID!, $date: Time!, $dateDOW: Int!) {
		courseSectionPeriodConnection(
			filters: [
				{
					dayOfWeekEQ: $dateDOW
					courseSection: {
						courseSectionTeacherEnrollments: [{ teacherIDEQ: $teacherID }]
						term: [{ startDateLTE: $date, endDateGTE: $date }]
					}
				}
				{
					dayOfWeekEQ: $dateDOW
					courseSection: {
						homeRoomSection: {
							homeRoomSectionTeacherEnrollments: [{ teacherIDEQ: $teacherID }]
							term: [{ startDateLTE: $date, endDateGTE: $date }]
						}
					}
				}
			]
			order: { field: START_TIME_MINUTES, direction: ASC }
		) {
			edges {
				node {
					id
					dayOfWeek
					startTimeMinutes
					endTimeMinutes
					courseSection {
						id
						name
						course {
							id
							name
							subject
						}
						homeRoomSection {
							id
							name
						}
						courseSectionTeacherEnrollments {
							id
							teacher {
								id
							}
						}
					}
					room {
						id
						name
						number
					}
				}
			}
		}
	}
`;

/**
 * __useCourseSectionPeriodTeacherDailyScheduleQuery__
 *
 * To run a query within a React component, call `useCourseSectionPeriodTeacherDailyScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseSectionPeriodTeacherDailyScheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseSectionPeriodTeacherDailyScheduleQuery({
 *   variables: {
 *      teacherID: // value for 'teacherID'
 *      date: // value for 'date'
 *      dateDOW: // value for 'dateDOW'
 *   },
 * });
 */
export function useCourseSectionPeriodTeacherDailyScheduleQuery(
	baseOptions: Apollo.QueryHookOptions<
		CourseSectionPeriodTeacherDailyScheduleQuery,
		CourseSectionPeriodTeacherDailyScheduleQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		CourseSectionPeriodTeacherDailyScheduleQuery,
		CourseSectionPeriodTeacherDailyScheduleQueryVariables
	>(CourseSectionPeriodTeacherDailyScheduleDocument, options);
}
export function useCourseSectionPeriodTeacherDailyScheduleLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		CourseSectionPeriodTeacherDailyScheduleQuery,
		CourseSectionPeriodTeacherDailyScheduleQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		CourseSectionPeriodTeacherDailyScheduleQuery,
		CourseSectionPeriodTeacherDailyScheduleQueryVariables
	>(CourseSectionPeriodTeacherDailyScheduleDocument, options);
}
export type CourseSectionPeriodTeacherDailyScheduleQueryHookResult = ReturnType<
	typeof useCourseSectionPeriodTeacherDailyScheduleQuery
>;
export type CourseSectionPeriodTeacherDailyScheduleLazyQueryHookResult = ReturnType<
	typeof useCourseSectionPeriodTeacherDailyScheduleLazyQuery
>;
export type CourseSectionPeriodTeacherDailyScheduleQueryResult = Apollo.QueryResult<
	CourseSectionPeriodTeacherDailyScheduleQuery,
	CourseSectionPeriodTeacherDailyScheduleQueryVariables
>;
export const CourseSectionPeriodTeacherDailyScheduleWithLessonPlansDocument = gql`
	query courseSectionPeriodTeacherDailyScheduleWithLessonPlans($teacherID: ID!, $date: Time!, $dateDOW: Int!) {
		courseSectionPeriodConnection(
			filters: {
				dayOfWeekEQ: $dateDOW
				courseSection: {
					courseSectionTeacherEnrollments: [{ teacherIDEQ: $teacherID }]
					term: [{ startDateLTE: $date, endDateGTE: $date }]
				}
			}
			order: { field: START_TIME_MINUTES, direction: ASC }
		) {
			edges {
				node {
					id
					dayOfWeek
					startTimeMinutes
					endTimeMinutes
					courseSection {
						id
						name
						course {
							id
							name
							subject
						}
						homeRoomSection {
							id
							name
						}
						lessonPlans(filters: { courseDateEQ: $date }) {
							id
							lessonDescription
						}
					}
					room {
						id
						name
						number
					}
				}
			}
		}
	}
`;

/**
 * __useCourseSectionPeriodTeacherDailyScheduleWithLessonPlansQuery__
 *
 * To run a query within a React component, call `useCourseSectionPeriodTeacherDailyScheduleWithLessonPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseSectionPeriodTeacherDailyScheduleWithLessonPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseSectionPeriodTeacherDailyScheduleWithLessonPlansQuery({
 *   variables: {
 *      teacherID: // value for 'teacherID'
 *      date: // value for 'date'
 *      dateDOW: // value for 'dateDOW'
 *   },
 * });
 */
export function useCourseSectionPeriodTeacherDailyScheduleWithLessonPlansQuery(
	baseOptions: Apollo.QueryHookOptions<
		CourseSectionPeriodTeacherDailyScheduleWithLessonPlansQuery,
		CourseSectionPeriodTeacherDailyScheduleWithLessonPlansQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		CourseSectionPeriodTeacherDailyScheduleWithLessonPlansQuery,
		CourseSectionPeriodTeacherDailyScheduleWithLessonPlansQueryVariables
	>(CourseSectionPeriodTeacherDailyScheduleWithLessonPlansDocument, options);
}
export function useCourseSectionPeriodTeacherDailyScheduleWithLessonPlansLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		CourseSectionPeriodTeacherDailyScheduleWithLessonPlansQuery,
		CourseSectionPeriodTeacherDailyScheduleWithLessonPlansQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		CourseSectionPeriodTeacherDailyScheduleWithLessonPlansQuery,
		CourseSectionPeriodTeacherDailyScheduleWithLessonPlansQueryVariables
	>(CourseSectionPeriodTeacherDailyScheduleWithLessonPlansDocument, options);
}
export type CourseSectionPeriodTeacherDailyScheduleWithLessonPlansQueryHookResult = ReturnType<
	typeof useCourseSectionPeriodTeacherDailyScheduleWithLessonPlansQuery
>;
export type CourseSectionPeriodTeacherDailyScheduleWithLessonPlansLazyQueryHookResult = ReturnType<
	typeof useCourseSectionPeriodTeacherDailyScheduleWithLessonPlansLazyQuery
>;
export type CourseSectionPeriodTeacherDailyScheduleWithLessonPlansQueryResult = Apollo.QueryResult<
	CourseSectionPeriodTeacherDailyScheduleWithLessonPlansQuery,
	CourseSectionPeriodTeacherDailyScheduleWithLessonPlansQueryVariables
>;
export const CourseSectionPeriodTeacherDetailDocument = gql`
	query courseSectionPeriodTeacherDetail($id: ID!, $date: Time!) {
		courseSectionPeriod(id: $id) {
			id
			dayOfWeek
			startTimeMinutes
			endTimeMinutes
			courseSection {
				id
				name
				course {
					id
					name
					subject
					homeRoom {
						id
						name
					}
				}
				homeRoomSection {
					id
					name
				}
				courseSectionTeacherEnrollments {
					id
					teacher {
						id
						person {
							id
							name
							nickname
							imageURL
						}
					}
				}
				courseSectionStudentEnrollments {
					id
					student {
						id
						person {
							id
							name
							nickname
							imageURL
						}
					}
				}
				lessonPlans(filters: { courseDateEQ: $date }) {
					id
					courseDate
					lessonDescription
					requiredResourcesAndMaterials
					stationOneDescription
					stationTwoDescription
					stationThreeDescription
					differentiationDescription
					assessmentDescription
					notes
					learningObjectives {
						id
						category
						description
					}
				}
			}
			room {
				id
				name
				number
			}
		}
	}
`;

/**
 * __useCourseSectionPeriodTeacherDetailQuery__
 *
 * To run a query within a React component, call `useCourseSectionPeriodTeacherDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseSectionPeriodTeacherDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseSectionPeriodTeacherDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useCourseSectionPeriodTeacherDetailQuery(
	baseOptions: Apollo.QueryHookOptions<
		CourseSectionPeriodTeacherDetailQuery,
		CourseSectionPeriodTeacherDetailQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<CourseSectionPeriodTeacherDetailQuery, CourseSectionPeriodTeacherDetailQueryVariables>(
		CourseSectionPeriodTeacherDetailDocument,
		options,
	);
}
export function useCourseSectionPeriodTeacherDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		CourseSectionPeriodTeacherDetailQuery,
		CourseSectionPeriodTeacherDetailQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<CourseSectionPeriodTeacherDetailQuery, CourseSectionPeriodTeacherDetailQueryVariables>(
		CourseSectionPeriodTeacherDetailDocument,
		options,
	);
}
export type CourseSectionPeriodTeacherDetailQueryHookResult = ReturnType<
	typeof useCourseSectionPeriodTeacherDetailQuery
>;
export type CourseSectionPeriodTeacherDetailLazyQueryHookResult = ReturnType<
	typeof useCourseSectionPeriodTeacherDetailLazyQuery
>;
export type CourseSectionPeriodTeacherDetailQueryResult = Apollo.QueryResult<
	CourseSectionPeriodTeacherDetailQuery,
	CourseSectionPeriodTeacherDetailQueryVariables
>;
export const CourseSectionPeriodUpdateDocument = gql`
	mutation courseSectionPeriodUpdate($id: ID!, $input: CourseSectionPeriodUpdateInput!) {
		updateCourseSectionPeriod(id: $id, input: $input) {
			...CourseSectionPeriodDetail
		}
	}
	${CourseSectionPeriodDetailFragmentDoc}
`;
export type CourseSectionPeriodUpdateMutationFn = Apollo.MutationFunction<
	CourseSectionPeriodUpdateMutation,
	CourseSectionPeriodUpdateMutationVariables
>;

/**
 * __useCourseSectionPeriodUpdateMutation__
 *
 * To run a mutation, you first call `useCourseSectionPeriodUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCourseSectionPeriodUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [courseSectionPeriodUpdateMutation, { data, loading, error }] = useCourseSectionPeriodUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCourseSectionPeriodUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<
		CourseSectionPeriodUpdateMutation,
		CourseSectionPeriodUpdateMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<CourseSectionPeriodUpdateMutation, CourseSectionPeriodUpdateMutationVariables>(
		CourseSectionPeriodUpdateDocument,
		options,
	);
}
export type CourseSectionPeriodUpdateMutationHookResult = ReturnType<typeof useCourseSectionPeriodUpdateMutation>;
export type CourseSectionPeriodUpdateMutationResult = Apollo.MutationResult<CourseSectionPeriodUpdateMutation>;
export type CourseSectionPeriodUpdateMutationOptions = Apollo.BaseMutationOptions<
	CourseSectionPeriodUpdateMutation,
	CourseSectionPeriodUpdateMutationVariables
>;
export const DiscountCountDocument = gql`
	query discountCount($filters: [DiscountFilterInput!], $pagination: Pagination, $order: DiscountOrder) {
		discountConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useDiscountCountQuery__
 *
 * To run a query within a React component, call `useDiscountCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiscountCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiscountCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useDiscountCountQuery(
	baseOptions?: Apollo.QueryHookOptions<DiscountCountQuery, DiscountCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<DiscountCountQuery, DiscountCountQueryVariables>(DiscountCountDocument, options);
}
export function useDiscountCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<DiscountCountQuery, DiscountCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<DiscountCountQuery, DiscountCountQueryVariables>(DiscountCountDocument, options);
}
export type DiscountCountQueryHookResult = ReturnType<typeof useDiscountCountQuery>;
export type DiscountCountLazyQueryHookResult = ReturnType<typeof useDiscountCountLazyQuery>;
export type DiscountCountQueryResult = Apollo.QueryResult<DiscountCountQuery, DiscountCountQueryVariables>;
export const DiscountCreateDocument = gql`
	mutation discountCreate($input: DiscountCreateInput!) {
		createDiscount(input: $input) {
			...DiscountDetail
		}
	}
	${DiscountDetailFragmentDoc}
`;
export type DiscountCreateMutationFn = Apollo.MutationFunction<DiscountCreateMutation, DiscountCreateMutationVariables>;

/**
 * __useDiscountCreateMutation__
 *
 * To run a mutation, you first call `useDiscountCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDiscountCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [discountCreateMutation, { data, loading, error }] = useDiscountCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDiscountCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<DiscountCreateMutation, DiscountCreateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<DiscountCreateMutation, DiscountCreateMutationVariables>(DiscountCreateDocument, options);
}
export type DiscountCreateMutationHookResult = ReturnType<typeof useDiscountCreateMutation>;
export type DiscountCreateMutationResult = Apollo.MutationResult<DiscountCreateMutation>;
export type DiscountCreateMutationOptions = Apollo.BaseMutationOptions<
	DiscountCreateMutation,
	DiscountCreateMutationVariables
>;
export const DiscountDeleteDocument = gql`
	mutation discountDelete($id: ID!) {
		deleteDiscount(id: $id)
	}
`;
export type DiscountDeleteMutationFn = Apollo.MutationFunction<DiscountDeleteMutation, DiscountDeleteMutationVariables>;

/**
 * __useDiscountDeleteMutation__
 *
 * To run a mutation, you first call `useDiscountDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDiscountDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [discountDeleteMutation, { data, loading, error }] = useDiscountDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDiscountDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<DiscountDeleteMutation, DiscountDeleteMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<DiscountDeleteMutation, DiscountDeleteMutationVariables>(DiscountDeleteDocument, options);
}
export type DiscountDeleteMutationHookResult = ReturnType<typeof useDiscountDeleteMutation>;
export type DiscountDeleteMutationResult = Apollo.MutationResult<DiscountDeleteMutation>;
export type DiscountDeleteMutationOptions = Apollo.BaseMutationOptions<
	DiscountDeleteMutation,
	DiscountDeleteMutationVariables
>;
export const DiscountDetailDocument = gql`
	query discountDetail($id: ID!) {
		discount(id: $id) {
			...DiscountDetail
		}
	}
	${DiscountDetailFragmentDoc}
`;

/**
 * __useDiscountDetailQuery__
 *
 * To run a query within a React component, call `useDiscountDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiscountDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiscountDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDiscountDetailQuery(
	baseOptions: Apollo.QueryHookOptions<DiscountDetailQuery, DiscountDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<DiscountDetailQuery, DiscountDetailQueryVariables>(DiscountDetailDocument, options);
}
export function useDiscountDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<DiscountDetailQuery, DiscountDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<DiscountDetailQuery, DiscountDetailQueryVariables>(DiscountDetailDocument, options);
}
export type DiscountDetailQueryHookResult = ReturnType<typeof useDiscountDetailQuery>;
export type DiscountDetailLazyQueryHookResult = ReturnType<typeof useDiscountDetailLazyQuery>;
export type DiscountDetailQueryResult = Apollo.QueryResult<DiscountDetailQuery, DiscountDetailQueryVariables>;
export const DiscountIndexDocument = gql`
	query discountIndex($filters: [DiscountFilterInput!], $pagination: Pagination, $order: DiscountOrder) {
		discountConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					amount
					description
				}
			}
		}
	}
`;

/**
 * __useDiscountIndexQuery__
 *
 * To run a query within a React component, call `useDiscountIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiscountIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiscountIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useDiscountIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<DiscountIndexQuery, DiscountIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<DiscountIndexQuery, DiscountIndexQueryVariables>(DiscountIndexDocument, options);
}
export function useDiscountIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<DiscountIndexQuery, DiscountIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<DiscountIndexQuery, DiscountIndexQueryVariables>(DiscountIndexDocument, options);
}
export type DiscountIndexQueryHookResult = ReturnType<typeof useDiscountIndexQuery>;
export type DiscountIndexLazyQueryHookResult = ReturnType<typeof useDiscountIndexLazyQuery>;
export type DiscountIndexQueryResult = Apollo.QueryResult<DiscountIndexQuery, DiscountIndexQueryVariables>;
export const DiscountNameDocument = gql`
	query discountName($id: ID!) {
		discount(id: $id) {
			id
			...DiscountName
		}
	}
	${DiscountNameFragmentDoc}
`;

/**
 * __useDiscountNameQuery__
 *
 * To run a query within a React component, call `useDiscountNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiscountNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiscountNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDiscountNameQuery(
	baseOptions: Apollo.QueryHookOptions<DiscountNameQuery, DiscountNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<DiscountNameQuery, DiscountNameQueryVariables>(DiscountNameDocument, options);
}
export function useDiscountNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<DiscountNameQuery, DiscountNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<DiscountNameQuery, DiscountNameQueryVariables>(DiscountNameDocument, options);
}
export type DiscountNameQueryHookResult = ReturnType<typeof useDiscountNameQuery>;
export type DiscountNameLazyQueryHookResult = ReturnType<typeof useDiscountNameLazyQuery>;
export type DiscountNameQueryResult = Apollo.QueryResult<DiscountNameQuery, DiscountNameQueryVariables>;
export const DiscountNamesDocument = gql`
	query discountNames($filters: [DiscountFilterInput!], $pagination: Pagination, $order: DiscountOrder) {
		discountConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...DiscountName
				}
			}
		}
	}
	${DiscountNameFragmentDoc}
`;

/**
 * __useDiscountNamesQuery__
 *
 * To run a query within a React component, call `useDiscountNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiscountNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiscountNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useDiscountNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<DiscountNamesQuery, DiscountNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<DiscountNamesQuery, DiscountNamesQueryVariables>(DiscountNamesDocument, options);
}
export function useDiscountNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<DiscountNamesQuery, DiscountNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<DiscountNamesQuery, DiscountNamesQueryVariables>(DiscountNamesDocument, options);
}
export type DiscountNamesQueryHookResult = ReturnType<typeof useDiscountNamesQuery>;
export type DiscountNamesLazyQueryHookResult = ReturnType<typeof useDiscountNamesLazyQuery>;
export type DiscountNamesQueryResult = Apollo.QueryResult<DiscountNamesQuery, DiscountNamesQueryVariables>;
export const DiscountPaginationDocument = gql`
	query discountPagination($filters: [DiscountFilterInput!], $order: DiscountOrder) {
		discountConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useDiscountPaginationQuery__
 *
 * To run a query within a React component, call `useDiscountPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiscountPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiscountPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useDiscountPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<DiscountPaginationQuery, DiscountPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<DiscountPaginationQuery, DiscountPaginationQueryVariables>(
		DiscountPaginationDocument,
		options,
	);
}
export function useDiscountPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<DiscountPaginationQuery, DiscountPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<DiscountPaginationQuery, DiscountPaginationQueryVariables>(
		DiscountPaginationDocument,
		options,
	);
}
export type DiscountPaginationQueryHookResult = ReturnType<typeof useDiscountPaginationQuery>;
export type DiscountPaginationLazyQueryHookResult = ReturnType<typeof useDiscountPaginationLazyQuery>;
export type DiscountPaginationQueryResult = Apollo.QueryResult<
	DiscountPaginationQuery,
	DiscountPaginationQueryVariables
>;
export const DiscountSelectDocument = gql`
	query discountSelect($filters: [DiscountFilterInput!], $order: DiscountOrder) {
		discountConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...DiscountName
				}
			}
		}
	}
	${DiscountNameFragmentDoc}
`;

/**
 * __useDiscountSelectQuery__
 *
 * To run a query within a React component, call `useDiscountSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiscountSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiscountSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useDiscountSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<DiscountSelectQuery, DiscountSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<DiscountSelectQuery, DiscountSelectQueryVariables>(DiscountSelectDocument, options);
}
export function useDiscountSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<DiscountSelectQuery, DiscountSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<DiscountSelectQuery, DiscountSelectQueryVariables>(DiscountSelectDocument, options);
}
export type DiscountSelectQueryHookResult = ReturnType<typeof useDiscountSelectQuery>;
export type DiscountSelectLazyQueryHookResult = ReturnType<typeof useDiscountSelectLazyQuery>;
export type DiscountSelectQueryResult = Apollo.QueryResult<DiscountSelectQuery, DiscountSelectQueryVariables>;
export const DiscountUpdateDocument = gql`
	mutation discountUpdate($id: ID!, $input: DiscountUpdateInput!) {
		updateDiscount(id: $id, input: $input) {
			...DiscountDetail
		}
	}
	${DiscountDetailFragmentDoc}
`;
export type DiscountUpdateMutationFn = Apollo.MutationFunction<DiscountUpdateMutation, DiscountUpdateMutationVariables>;

/**
 * __useDiscountUpdateMutation__
 *
 * To run a mutation, you first call `useDiscountUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDiscountUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [discountUpdateMutation, { data, loading, error }] = useDiscountUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDiscountUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<DiscountUpdateMutation, DiscountUpdateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<DiscountUpdateMutation, DiscountUpdateMutationVariables>(DiscountUpdateDocument, options);
}
export type DiscountUpdateMutationHookResult = ReturnType<typeof useDiscountUpdateMutation>;
export type DiscountUpdateMutationResult = Apollo.MutationResult<DiscountUpdateMutation>;
export type DiscountUpdateMutationOptions = Apollo.BaseMutationOptions<
	DiscountUpdateMutation,
	DiscountUpdateMutationVariables
>;
export const DocumentResourceBookmarkCheckDocument = gql`
	query documentResourceBookmarkCheck($documentResourceID: ID!, $userID: ID!) {
		documentResourceConnection(filters: { idEQ: $documentResourceID, bookmarkingUsers: { idEQ: $userID } }) {
			totalCount
		}
	}
`;

/**
 * __useDocumentResourceBookmarkCheckQuery__
 *
 * To run a query within a React component, call `useDocumentResourceBookmarkCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentResourceBookmarkCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentResourceBookmarkCheckQuery({
 *   variables: {
 *      documentResourceID: // value for 'documentResourceID'
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useDocumentResourceBookmarkCheckQuery(
	baseOptions: Apollo.QueryHookOptions<DocumentResourceBookmarkCheckQuery, DocumentResourceBookmarkCheckQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<DocumentResourceBookmarkCheckQuery, DocumentResourceBookmarkCheckQueryVariables>(
		DocumentResourceBookmarkCheckDocument,
		options,
	);
}
export function useDocumentResourceBookmarkCheckLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		DocumentResourceBookmarkCheckQuery,
		DocumentResourceBookmarkCheckQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<DocumentResourceBookmarkCheckQuery, DocumentResourceBookmarkCheckQueryVariables>(
		DocumentResourceBookmarkCheckDocument,
		options,
	);
}
export type DocumentResourceBookmarkCheckQueryHookResult = ReturnType<typeof useDocumentResourceBookmarkCheckQuery>;
export type DocumentResourceBookmarkCheckLazyQueryHookResult = ReturnType<
	typeof useDocumentResourceBookmarkCheckLazyQuery
>;
export type DocumentResourceBookmarkCheckQueryResult = Apollo.QueryResult<
	DocumentResourceBookmarkCheckQuery,
	DocumentResourceBookmarkCheckQueryVariables
>;
export const BookmarkDocumentResourceDocument = gql`
	mutation bookmarkDocumentResource($documentResourceID: ID!, $userID: ID!) {
		updateUser(id: $userID, input: { addBookmarkedDocumentResourceIDs: [$documentResourceID] }) {
			id
		}
	}
`;
export type BookmarkDocumentResourceMutationFn = Apollo.MutationFunction<
	BookmarkDocumentResourceMutation,
	BookmarkDocumentResourceMutationVariables
>;

/**
 * __useBookmarkDocumentResourceMutation__
 *
 * To run a mutation, you first call `useBookmarkDocumentResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookmarkDocumentResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookmarkDocumentResourceMutation, { data, loading, error }] = useBookmarkDocumentResourceMutation({
 *   variables: {
 *      documentResourceID: // value for 'documentResourceID'
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useBookmarkDocumentResourceMutation(
	baseOptions?: Apollo.MutationHookOptions<BookmarkDocumentResourceMutation, BookmarkDocumentResourceMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<BookmarkDocumentResourceMutation, BookmarkDocumentResourceMutationVariables>(
		BookmarkDocumentResourceDocument,
		options,
	);
}
export type BookmarkDocumentResourceMutationHookResult = ReturnType<typeof useBookmarkDocumentResourceMutation>;
export type BookmarkDocumentResourceMutationResult = Apollo.MutationResult<BookmarkDocumentResourceMutation>;
export type BookmarkDocumentResourceMutationOptions = Apollo.BaseMutationOptions<
	BookmarkDocumentResourceMutation,
	BookmarkDocumentResourceMutationVariables
>;
export const UnbookmarkDocumentResourceDocument = gql`
	mutation unbookmarkDocumentResource($documentResourceID: ID!, $userID: ID!) {
		updateUser(id: $userID, input: { removeBookmarkedDocumentResourceIDs: [$documentResourceID] }) {
			id
		}
	}
`;
export type UnbookmarkDocumentResourceMutationFn = Apollo.MutationFunction<
	UnbookmarkDocumentResourceMutation,
	UnbookmarkDocumentResourceMutationVariables
>;

/**
 * __useUnbookmarkDocumentResourceMutation__
 *
 * To run a mutation, you first call `useUnbookmarkDocumentResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnbookmarkDocumentResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unbookmarkDocumentResourceMutation, { data, loading, error }] = useUnbookmarkDocumentResourceMutation({
 *   variables: {
 *      documentResourceID: // value for 'documentResourceID'
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useUnbookmarkDocumentResourceMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UnbookmarkDocumentResourceMutation,
		UnbookmarkDocumentResourceMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<UnbookmarkDocumentResourceMutation, UnbookmarkDocumentResourceMutationVariables>(
		UnbookmarkDocumentResourceDocument,
		options,
	);
}
export type UnbookmarkDocumentResourceMutationHookResult = ReturnType<typeof useUnbookmarkDocumentResourceMutation>;
export type UnbookmarkDocumentResourceMutationResult = Apollo.MutationResult<UnbookmarkDocumentResourceMutation>;
export type UnbookmarkDocumentResourceMutationOptions = Apollo.BaseMutationOptions<
	UnbookmarkDocumentResourceMutation,
	UnbookmarkDocumentResourceMutationVariables
>;
export const DocumentResourceCountDocument = gql`
	query documentResourceCount(
		$filters: [DocumentResourceFilterInput!]
		$pagination: Pagination
		$order: DocumentResourceOrder
	) {
		documentResourceConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useDocumentResourceCountQuery__
 *
 * To run a query within a React component, call `useDocumentResourceCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentResourceCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentResourceCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useDocumentResourceCountQuery(
	baseOptions?: Apollo.QueryHookOptions<DocumentResourceCountQuery, DocumentResourceCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<DocumentResourceCountQuery, DocumentResourceCountQueryVariables>(
		DocumentResourceCountDocument,
		options,
	);
}
export function useDocumentResourceCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<DocumentResourceCountQuery, DocumentResourceCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<DocumentResourceCountQuery, DocumentResourceCountQueryVariables>(
		DocumentResourceCountDocument,
		options,
	);
}
export type DocumentResourceCountQueryHookResult = ReturnType<typeof useDocumentResourceCountQuery>;
export type DocumentResourceCountLazyQueryHookResult = ReturnType<typeof useDocumentResourceCountLazyQuery>;
export type DocumentResourceCountQueryResult = Apollo.QueryResult<
	DocumentResourceCountQuery,
	DocumentResourceCountQueryVariables
>;
export const DocumentResourceCreateDocument = gql`
	mutation documentResourceCreate($input: DocumentResourceCreateInput!) {
		createDocumentResource(input: $input) {
			...DocumentResourceDetail
		}
	}
	${DocumentResourceDetailFragmentDoc}
`;
export type DocumentResourceCreateMutationFn = Apollo.MutationFunction<
	DocumentResourceCreateMutation,
	DocumentResourceCreateMutationVariables
>;

/**
 * __useDocumentResourceCreateMutation__
 *
 * To run a mutation, you first call `useDocumentResourceCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentResourceCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentResourceCreateMutation, { data, loading, error }] = useDocumentResourceCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDocumentResourceCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<DocumentResourceCreateMutation, DocumentResourceCreateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<DocumentResourceCreateMutation, DocumentResourceCreateMutationVariables>(
		DocumentResourceCreateDocument,
		options,
	);
}
export type DocumentResourceCreateMutationHookResult = ReturnType<typeof useDocumentResourceCreateMutation>;
export type DocumentResourceCreateMutationResult = Apollo.MutationResult<DocumentResourceCreateMutation>;
export type DocumentResourceCreateMutationOptions = Apollo.BaseMutationOptions<
	DocumentResourceCreateMutation,
	DocumentResourceCreateMutationVariables
>;
export const DocumentResourceDeleteDocument = gql`
	mutation documentResourceDelete($id: ID!) {
		deleteDocumentResource(id: $id)
	}
`;
export type DocumentResourceDeleteMutationFn = Apollo.MutationFunction<
	DocumentResourceDeleteMutation,
	DocumentResourceDeleteMutationVariables
>;

/**
 * __useDocumentResourceDeleteMutation__
 *
 * To run a mutation, you first call `useDocumentResourceDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentResourceDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentResourceDeleteMutation, { data, loading, error }] = useDocumentResourceDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDocumentResourceDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<DocumentResourceDeleteMutation, DocumentResourceDeleteMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<DocumentResourceDeleteMutation, DocumentResourceDeleteMutationVariables>(
		DocumentResourceDeleteDocument,
		options,
	);
}
export type DocumentResourceDeleteMutationHookResult = ReturnType<typeof useDocumentResourceDeleteMutation>;
export type DocumentResourceDeleteMutationResult = Apollo.MutationResult<DocumentResourceDeleteMutation>;
export type DocumentResourceDeleteMutationOptions = Apollo.BaseMutationOptions<
	DocumentResourceDeleteMutation,
	DocumentResourceDeleteMutationVariables
>;
export const DocumentResourceDetailDocument = gql`
	query documentResourceDetail($id: ID!) {
		documentResource(id: $id) {
			...DocumentResourceDetail
		}
	}
	${DocumentResourceDetailFragmentDoc}
`;

/**
 * __useDocumentResourceDetailQuery__
 *
 * To run a query within a React component, call `useDocumentResourceDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentResourceDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentResourceDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDocumentResourceDetailQuery(
	baseOptions: Apollo.QueryHookOptions<DocumentResourceDetailQuery, DocumentResourceDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<DocumentResourceDetailQuery, DocumentResourceDetailQueryVariables>(
		DocumentResourceDetailDocument,
		options,
	);
}
export function useDocumentResourceDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<DocumentResourceDetailQuery, DocumentResourceDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<DocumentResourceDetailQuery, DocumentResourceDetailQueryVariables>(
		DocumentResourceDetailDocument,
		options,
	);
}
export type DocumentResourceDetailQueryHookResult = ReturnType<typeof useDocumentResourceDetailQuery>;
export type DocumentResourceDetailLazyQueryHookResult = ReturnType<typeof useDocumentResourceDetailLazyQuery>;
export type DocumentResourceDetailQueryResult = Apollo.QueryResult<
	DocumentResourceDetailQuery,
	DocumentResourceDetailQueryVariables
>;
export const DocumentResourceIndexDocument = gql`
	query documentResourceIndex(
		$filters: [DocumentResourceFilterInput!]
		$pagination: Pagination
		$order: DocumentResourceOrder
	) {
		documentResourceConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					name
					description
					hasImage
					imageURL
					isFile
					fileURL
					fileContentType
					resourceURL
				}
			}
		}
	}
`;

/**
 * __useDocumentResourceIndexQuery__
 *
 * To run a query within a React component, call `useDocumentResourceIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentResourceIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentResourceIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useDocumentResourceIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<DocumentResourceIndexQuery, DocumentResourceIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<DocumentResourceIndexQuery, DocumentResourceIndexQueryVariables>(
		DocumentResourceIndexDocument,
		options,
	);
}
export function useDocumentResourceIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<DocumentResourceIndexQuery, DocumentResourceIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<DocumentResourceIndexQuery, DocumentResourceIndexQueryVariables>(
		DocumentResourceIndexDocument,
		options,
	);
}
export type DocumentResourceIndexQueryHookResult = ReturnType<typeof useDocumentResourceIndexQuery>;
export type DocumentResourceIndexLazyQueryHookResult = ReturnType<typeof useDocumentResourceIndexLazyQuery>;
export type DocumentResourceIndexQueryResult = Apollo.QueryResult<
	DocumentResourceIndexQuery,
	DocumentResourceIndexQueryVariables
>;
export const DocumentResourceNameDocument = gql`
	query documentResourceName($id: ID!) {
		documentResource(id: $id) {
			id
			...DocumentResourceName
		}
	}
	${DocumentResourceNameFragmentDoc}
`;

/**
 * __useDocumentResourceNameQuery__
 *
 * To run a query within a React component, call `useDocumentResourceNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentResourceNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentResourceNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDocumentResourceNameQuery(
	baseOptions: Apollo.QueryHookOptions<DocumentResourceNameQuery, DocumentResourceNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<DocumentResourceNameQuery, DocumentResourceNameQueryVariables>(
		DocumentResourceNameDocument,
		options,
	);
}
export function useDocumentResourceNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<DocumentResourceNameQuery, DocumentResourceNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<DocumentResourceNameQuery, DocumentResourceNameQueryVariables>(
		DocumentResourceNameDocument,
		options,
	);
}
export type DocumentResourceNameQueryHookResult = ReturnType<typeof useDocumentResourceNameQuery>;
export type DocumentResourceNameLazyQueryHookResult = ReturnType<typeof useDocumentResourceNameLazyQuery>;
export type DocumentResourceNameQueryResult = Apollo.QueryResult<
	DocumentResourceNameQuery,
	DocumentResourceNameQueryVariables
>;
export const DocumentResourceNamesDocument = gql`
	query documentResourceNames(
		$filters: [DocumentResourceFilterInput!]
		$pagination: Pagination
		$order: DocumentResourceOrder
	) {
		documentResourceConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...DocumentResourceName
				}
			}
		}
	}
	${DocumentResourceNameFragmentDoc}
`;

/**
 * __useDocumentResourceNamesQuery__
 *
 * To run a query within a React component, call `useDocumentResourceNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentResourceNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentResourceNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useDocumentResourceNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<DocumentResourceNamesQuery, DocumentResourceNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<DocumentResourceNamesQuery, DocumentResourceNamesQueryVariables>(
		DocumentResourceNamesDocument,
		options,
	);
}
export function useDocumentResourceNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<DocumentResourceNamesQuery, DocumentResourceNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<DocumentResourceNamesQuery, DocumentResourceNamesQueryVariables>(
		DocumentResourceNamesDocument,
		options,
	);
}
export type DocumentResourceNamesQueryHookResult = ReturnType<typeof useDocumentResourceNamesQuery>;
export type DocumentResourceNamesLazyQueryHookResult = ReturnType<typeof useDocumentResourceNamesLazyQuery>;
export type DocumentResourceNamesQueryResult = Apollo.QueryResult<
	DocumentResourceNamesQuery,
	DocumentResourceNamesQueryVariables
>;
export const DocumentResourcePaginationDocument = gql`
	query documentResourcePagination($filters: [DocumentResourceFilterInput!], $order: DocumentResourceOrder) {
		documentResourceConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useDocumentResourcePaginationQuery__
 *
 * To run a query within a React component, call `useDocumentResourcePaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentResourcePaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentResourcePaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useDocumentResourcePaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<DocumentResourcePaginationQuery, DocumentResourcePaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<DocumentResourcePaginationQuery, DocumentResourcePaginationQueryVariables>(
		DocumentResourcePaginationDocument,
		options,
	);
}
export function useDocumentResourcePaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<DocumentResourcePaginationQuery, DocumentResourcePaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<DocumentResourcePaginationQuery, DocumentResourcePaginationQueryVariables>(
		DocumentResourcePaginationDocument,
		options,
	);
}
export type DocumentResourcePaginationQueryHookResult = ReturnType<typeof useDocumentResourcePaginationQuery>;
export type DocumentResourcePaginationLazyQueryHookResult = ReturnType<typeof useDocumentResourcePaginationLazyQuery>;
export type DocumentResourcePaginationQueryResult = Apollo.QueryResult<
	DocumentResourcePaginationQuery,
	DocumentResourcePaginationQueryVariables
>;
export const DocumentResourceSelectDocument = gql`
	query documentResourceSelect($filters: [DocumentResourceFilterInput!], $order: DocumentResourceOrder) {
		documentResourceConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...DocumentResourceName
				}
			}
		}
	}
	${DocumentResourceNameFragmentDoc}
`;

/**
 * __useDocumentResourceSelectQuery__
 *
 * To run a query within a React component, call `useDocumentResourceSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentResourceSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentResourceSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useDocumentResourceSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<DocumentResourceSelectQuery, DocumentResourceSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<DocumentResourceSelectQuery, DocumentResourceSelectQueryVariables>(
		DocumentResourceSelectDocument,
		options,
	);
}
export function useDocumentResourceSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<DocumentResourceSelectQuery, DocumentResourceSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<DocumentResourceSelectQuery, DocumentResourceSelectQueryVariables>(
		DocumentResourceSelectDocument,
		options,
	);
}
export type DocumentResourceSelectQueryHookResult = ReturnType<typeof useDocumentResourceSelectQuery>;
export type DocumentResourceSelectLazyQueryHookResult = ReturnType<typeof useDocumentResourceSelectLazyQuery>;
export type DocumentResourceSelectQueryResult = Apollo.QueryResult<
	DocumentResourceSelectQuery,
	DocumentResourceSelectQueryVariables
>;
export const DocumentResourceUpdateDocument = gql`
	mutation documentResourceUpdate($id: ID!, $input: DocumentResourceUpdateInput!) {
		updateDocumentResource(id: $id, input: $input) {
			...DocumentResourceDetail
		}
	}
	${DocumentResourceDetailFragmentDoc}
`;
export type DocumentResourceUpdateMutationFn = Apollo.MutationFunction<
	DocumentResourceUpdateMutation,
	DocumentResourceUpdateMutationVariables
>;

/**
 * __useDocumentResourceUpdateMutation__
 *
 * To run a mutation, you first call `useDocumentResourceUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentResourceUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentResourceUpdateMutation, { data, loading, error }] = useDocumentResourceUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDocumentResourceUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<DocumentResourceUpdateMutation, DocumentResourceUpdateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<DocumentResourceUpdateMutation, DocumentResourceUpdateMutationVariables>(
		DocumentResourceUpdateDocument,
		options,
	);
}
export type DocumentResourceUpdateMutationHookResult = ReturnType<typeof useDocumentResourceUpdateMutation>;
export type DocumentResourceUpdateMutationResult = Apollo.MutationResult<DocumentResourceUpdateMutation>;
export type DocumentResourceUpdateMutationOptions = Apollo.BaseMutationOptions<
	DocumentResourceUpdateMutation,
	DocumentResourceUpdateMutationVariables
>;
export const DocumentResourceFolderCountDocument = gql`
	query documentResourceFolderCount(
		$filters: [DocumentResourceFolderFilterInput!]
		$pagination: Pagination
		$order: DocumentResourceFolderOrder
	) {
		documentResourceFolderConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useDocumentResourceFolderCountQuery__
 *
 * To run a query within a React component, call `useDocumentResourceFolderCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentResourceFolderCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentResourceFolderCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useDocumentResourceFolderCountQuery(
	baseOptions?: Apollo.QueryHookOptions<DocumentResourceFolderCountQuery, DocumentResourceFolderCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<DocumentResourceFolderCountQuery, DocumentResourceFolderCountQueryVariables>(
		DocumentResourceFolderCountDocument,
		options,
	);
}
export function useDocumentResourceFolderCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		DocumentResourceFolderCountQuery,
		DocumentResourceFolderCountQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<DocumentResourceFolderCountQuery, DocumentResourceFolderCountQueryVariables>(
		DocumentResourceFolderCountDocument,
		options,
	);
}
export type DocumentResourceFolderCountQueryHookResult = ReturnType<typeof useDocumentResourceFolderCountQuery>;
export type DocumentResourceFolderCountLazyQueryHookResult = ReturnType<typeof useDocumentResourceFolderCountLazyQuery>;
export type DocumentResourceFolderCountQueryResult = Apollo.QueryResult<
	DocumentResourceFolderCountQuery,
	DocumentResourceFolderCountQueryVariables
>;
export const DocumentResourceFolderCreateDocument = gql`
	mutation documentResourceFolderCreate($input: DocumentResourceFolderCreateInput!) {
		createDocumentResourceFolder(input: $input) {
			...DocumentResourceFolderDetail
		}
	}
	${DocumentResourceFolderDetailFragmentDoc}
`;
export type DocumentResourceFolderCreateMutationFn = Apollo.MutationFunction<
	DocumentResourceFolderCreateMutation,
	DocumentResourceFolderCreateMutationVariables
>;

/**
 * __useDocumentResourceFolderCreateMutation__
 *
 * To run a mutation, you first call `useDocumentResourceFolderCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentResourceFolderCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentResourceFolderCreateMutation, { data, loading, error }] = useDocumentResourceFolderCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDocumentResourceFolderCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<
		DocumentResourceFolderCreateMutation,
		DocumentResourceFolderCreateMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<DocumentResourceFolderCreateMutation, DocumentResourceFolderCreateMutationVariables>(
		DocumentResourceFolderCreateDocument,
		options,
	);
}
export type DocumentResourceFolderCreateMutationHookResult = ReturnType<typeof useDocumentResourceFolderCreateMutation>;
export type DocumentResourceFolderCreateMutationResult = Apollo.MutationResult<DocumentResourceFolderCreateMutation>;
export type DocumentResourceFolderCreateMutationOptions = Apollo.BaseMutationOptions<
	DocumentResourceFolderCreateMutation,
	DocumentResourceFolderCreateMutationVariables
>;
export const DocumentResourceFolderDeleteDocument = gql`
	mutation documentResourceFolderDelete($id: ID!) {
		deleteDocumentResourceFolder(id: $id)
	}
`;
export type DocumentResourceFolderDeleteMutationFn = Apollo.MutationFunction<
	DocumentResourceFolderDeleteMutation,
	DocumentResourceFolderDeleteMutationVariables
>;

/**
 * __useDocumentResourceFolderDeleteMutation__
 *
 * To run a mutation, you first call `useDocumentResourceFolderDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentResourceFolderDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentResourceFolderDeleteMutation, { data, loading, error }] = useDocumentResourceFolderDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDocumentResourceFolderDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<
		DocumentResourceFolderDeleteMutation,
		DocumentResourceFolderDeleteMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<DocumentResourceFolderDeleteMutation, DocumentResourceFolderDeleteMutationVariables>(
		DocumentResourceFolderDeleteDocument,
		options,
	);
}
export type DocumentResourceFolderDeleteMutationHookResult = ReturnType<typeof useDocumentResourceFolderDeleteMutation>;
export type DocumentResourceFolderDeleteMutationResult = Apollo.MutationResult<DocumentResourceFolderDeleteMutation>;
export type DocumentResourceFolderDeleteMutationOptions = Apollo.BaseMutationOptions<
	DocumentResourceFolderDeleteMutation,
	DocumentResourceFolderDeleteMutationVariables
>;
export const DocumentResourceFolderDetailDocument = gql`
	query documentResourceFolderDetail($id: ID!) {
		documentResourceFolder(id: $id) {
			...DocumentResourceFolderDetail
		}
	}
	${DocumentResourceFolderDetailFragmentDoc}
`;

/**
 * __useDocumentResourceFolderDetailQuery__
 *
 * To run a query within a React component, call `useDocumentResourceFolderDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentResourceFolderDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentResourceFolderDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDocumentResourceFolderDetailQuery(
	baseOptions: Apollo.QueryHookOptions<DocumentResourceFolderDetailQuery, DocumentResourceFolderDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<DocumentResourceFolderDetailQuery, DocumentResourceFolderDetailQueryVariables>(
		DocumentResourceFolderDetailDocument,
		options,
	);
}
export function useDocumentResourceFolderDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		DocumentResourceFolderDetailQuery,
		DocumentResourceFolderDetailQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<DocumentResourceFolderDetailQuery, DocumentResourceFolderDetailQueryVariables>(
		DocumentResourceFolderDetailDocument,
		options,
	);
}
export type DocumentResourceFolderDetailQueryHookResult = ReturnType<typeof useDocumentResourceFolderDetailQuery>;
export type DocumentResourceFolderDetailLazyQueryHookResult = ReturnType<
	typeof useDocumentResourceFolderDetailLazyQuery
>;
export type DocumentResourceFolderDetailQueryResult = Apollo.QueryResult<
	DocumentResourceFolderDetailQuery,
	DocumentResourceFolderDetailQueryVariables
>;
export const DocumentResourceFolderIndexDocument = gql`
	query documentResourceFolderIndex(
		$filters: [DocumentResourceFolderFilterInput!]
		$pagination: Pagination
		$order: DocumentResourceFolderOrder
	) {
		documentResourceFolderConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					name
					description
					category
					isPublic
					createdAt
				}
			}
		}
	}
`;

/**
 * __useDocumentResourceFolderIndexQuery__
 *
 * To run a query within a React component, call `useDocumentResourceFolderIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentResourceFolderIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentResourceFolderIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useDocumentResourceFolderIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<DocumentResourceFolderIndexQuery, DocumentResourceFolderIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<DocumentResourceFolderIndexQuery, DocumentResourceFolderIndexQueryVariables>(
		DocumentResourceFolderIndexDocument,
		options,
	);
}
export function useDocumentResourceFolderIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		DocumentResourceFolderIndexQuery,
		DocumentResourceFolderIndexQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<DocumentResourceFolderIndexQuery, DocumentResourceFolderIndexQueryVariables>(
		DocumentResourceFolderIndexDocument,
		options,
	);
}
export type DocumentResourceFolderIndexQueryHookResult = ReturnType<typeof useDocumentResourceFolderIndexQuery>;
export type DocumentResourceFolderIndexLazyQueryHookResult = ReturnType<typeof useDocumentResourceFolderIndexLazyQuery>;
export type DocumentResourceFolderIndexQueryResult = Apollo.QueryResult<
	DocumentResourceFolderIndexQuery,
	DocumentResourceFolderIndexQueryVariables
>;
export const DocumentResourceFolderNameDocument = gql`
	query documentResourceFolderName($id: ID!) {
		documentResourceFolder(id: $id) {
			id
			...DocumentResourceFolderName
		}
	}
	${DocumentResourceFolderNameFragmentDoc}
`;

/**
 * __useDocumentResourceFolderNameQuery__
 *
 * To run a query within a React component, call `useDocumentResourceFolderNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentResourceFolderNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentResourceFolderNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDocumentResourceFolderNameQuery(
	baseOptions: Apollo.QueryHookOptions<DocumentResourceFolderNameQuery, DocumentResourceFolderNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<DocumentResourceFolderNameQuery, DocumentResourceFolderNameQueryVariables>(
		DocumentResourceFolderNameDocument,
		options,
	);
}
export function useDocumentResourceFolderNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<DocumentResourceFolderNameQuery, DocumentResourceFolderNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<DocumentResourceFolderNameQuery, DocumentResourceFolderNameQueryVariables>(
		DocumentResourceFolderNameDocument,
		options,
	);
}
export type DocumentResourceFolderNameQueryHookResult = ReturnType<typeof useDocumentResourceFolderNameQuery>;
export type DocumentResourceFolderNameLazyQueryHookResult = ReturnType<typeof useDocumentResourceFolderNameLazyQuery>;
export type DocumentResourceFolderNameQueryResult = Apollo.QueryResult<
	DocumentResourceFolderNameQuery,
	DocumentResourceFolderNameQueryVariables
>;
export const DocumentResourceFolderNamesDocument = gql`
	query documentResourceFolderNames(
		$filters: [DocumentResourceFolderFilterInput!]
		$pagination: Pagination
		$order: DocumentResourceFolderOrder
	) {
		documentResourceFolderConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...DocumentResourceFolderName
				}
			}
		}
	}
	${DocumentResourceFolderNameFragmentDoc}
`;

/**
 * __useDocumentResourceFolderNamesQuery__
 *
 * To run a query within a React component, call `useDocumentResourceFolderNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentResourceFolderNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentResourceFolderNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useDocumentResourceFolderNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<DocumentResourceFolderNamesQuery, DocumentResourceFolderNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<DocumentResourceFolderNamesQuery, DocumentResourceFolderNamesQueryVariables>(
		DocumentResourceFolderNamesDocument,
		options,
	);
}
export function useDocumentResourceFolderNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		DocumentResourceFolderNamesQuery,
		DocumentResourceFolderNamesQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<DocumentResourceFolderNamesQuery, DocumentResourceFolderNamesQueryVariables>(
		DocumentResourceFolderNamesDocument,
		options,
	);
}
export type DocumentResourceFolderNamesQueryHookResult = ReturnType<typeof useDocumentResourceFolderNamesQuery>;
export type DocumentResourceFolderNamesLazyQueryHookResult = ReturnType<typeof useDocumentResourceFolderNamesLazyQuery>;
export type DocumentResourceFolderNamesQueryResult = Apollo.QueryResult<
	DocumentResourceFolderNamesQuery,
	DocumentResourceFolderNamesQueryVariables
>;
export const DocumentResourceFolderPaginationDocument = gql`
	query documentResourceFolderPagination(
		$filters: [DocumentResourceFolderFilterInput!]
		$order: DocumentResourceFolderOrder
	) {
		documentResourceFolderConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useDocumentResourceFolderPaginationQuery__
 *
 * To run a query within a React component, call `useDocumentResourceFolderPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentResourceFolderPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentResourceFolderPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useDocumentResourceFolderPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<
		DocumentResourceFolderPaginationQuery,
		DocumentResourceFolderPaginationQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<DocumentResourceFolderPaginationQuery, DocumentResourceFolderPaginationQueryVariables>(
		DocumentResourceFolderPaginationDocument,
		options,
	);
}
export function useDocumentResourceFolderPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		DocumentResourceFolderPaginationQuery,
		DocumentResourceFolderPaginationQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<DocumentResourceFolderPaginationQuery, DocumentResourceFolderPaginationQueryVariables>(
		DocumentResourceFolderPaginationDocument,
		options,
	);
}
export type DocumentResourceFolderPaginationQueryHookResult = ReturnType<
	typeof useDocumentResourceFolderPaginationQuery
>;
export type DocumentResourceFolderPaginationLazyQueryHookResult = ReturnType<
	typeof useDocumentResourceFolderPaginationLazyQuery
>;
export type DocumentResourceFolderPaginationQueryResult = Apollo.QueryResult<
	DocumentResourceFolderPaginationQuery,
	DocumentResourceFolderPaginationQueryVariables
>;
export const DocumentResourceFolderSelectDocument = gql`
	query documentResourceFolderSelect(
		$filters: [DocumentResourceFolderFilterInput!]
		$order: DocumentResourceFolderOrder
	) {
		documentResourceFolderConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...DocumentResourceFolderName
				}
			}
		}
	}
	${DocumentResourceFolderNameFragmentDoc}
`;

/**
 * __useDocumentResourceFolderSelectQuery__
 *
 * To run a query within a React component, call `useDocumentResourceFolderSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentResourceFolderSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentResourceFolderSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useDocumentResourceFolderSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<DocumentResourceFolderSelectQuery, DocumentResourceFolderSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<DocumentResourceFolderSelectQuery, DocumentResourceFolderSelectQueryVariables>(
		DocumentResourceFolderSelectDocument,
		options,
	);
}
export function useDocumentResourceFolderSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		DocumentResourceFolderSelectQuery,
		DocumentResourceFolderSelectQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<DocumentResourceFolderSelectQuery, DocumentResourceFolderSelectQueryVariables>(
		DocumentResourceFolderSelectDocument,
		options,
	);
}
export type DocumentResourceFolderSelectQueryHookResult = ReturnType<typeof useDocumentResourceFolderSelectQuery>;
export type DocumentResourceFolderSelectLazyQueryHookResult = ReturnType<
	typeof useDocumentResourceFolderSelectLazyQuery
>;
export type DocumentResourceFolderSelectQueryResult = Apollo.QueryResult<
	DocumentResourceFolderSelectQuery,
	DocumentResourceFolderSelectQueryVariables
>;
export const DocumentResourceFolderUpdateDocument = gql`
	mutation documentResourceFolderUpdate($id: ID!, $input: DocumentResourceFolderUpdateInput!) {
		updateDocumentResourceFolder(id: $id, input: $input) {
			...DocumentResourceFolderDetail
		}
	}
	${DocumentResourceFolderDetailFragmentDoc}
`;
export type DocumentResourceFolderUpdateMutationFn = Apollo.MutationFunction<
	DocumentResourceFolderUpdateMutation,
	DocumentResourceFolderUpdateMutationVariables
>;

/**
 * __useDocumentResourceFolderUpdateMutation__
 *
 * To run a mutation, you first call `useDocumentResourceFolderUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentResourceFolderUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentResourceFolderUpdateMutation, { data, loading, error }] = useDocumentResourceFolderUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDocumentResourceFolderUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<
		DocumentResourceFolderUpdateMutation,
		DocumentResourceFolderUpdateMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<DocumentResourceFolderUpdateMutation, DocumentResourceFolderUpdateMutationVariables>(
		DocumentResourceFolderUpdateDocument,
		options,
	);
}
export type DocumentResourceFolderUpdateMutationHookResult = ReturnType<typeof useDocumentResourceFolderUpdateMutation>;
export type DocumentResourceFolderUpdateMutationResult = Apollo.MutationResult<DocumentResourceFolderUpdateMutation>;
export type DocumentResourceFolderUpdateMutationOptions = Apollo.BaseMutationOptions<
	DocumentResourceFolderUpdateMutation,
	DocumentResourceFolderUpdateMutationVariables
>;
export const DocumentResourceUserViewRecordCountDocument = gql`
	query documentResourceUserViewRecordCount(
		$filters: [DocumentResourceUserViewRecordFilterInput!]
		$pagination: Pagination
		$order: DocumentResourceUserViewRecordOrder
	) {
		documentResourceUserViewRecordConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useDocumentResourceUserViewRecordCountQuery__
 *
 * To run a query within a React component, call `useDocumentResourceUserViewRecordCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentResourceUserViewRecordCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentResourceUserViewRecordCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useDocumentResourceUserViewRecordCountQuery(
	baseOptions?: Apollo.QueryHookOptions<
		DocumentResourceUserViewRecordCountQuery,
		DocumentResourceUserViewRecordCountQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<DocumentResourceUserViewRecordCountQuery, DocumentResourceUserViewRecordCountQueryVariables>(
		DocumentResourceUserViewRecordCountDocument,
		options,
	);
}
export function useDocumentResourceUserViewRecordCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		DocumentResourceUserViewRecordCountQuery,
		DocumentResourceUserViewRecordCountQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		DocumentResourceUserViewRecordCountQuery,
		DocumentResourceUserViewRecordCountQueryVariables
	>(DocumentResourceUserViewRecordCountDocument, options);
}
export type DocumentResourceUserViewRecordCountQueryHookResult = ReturnType<
	typeof useDocumentResourceUserViewRecordCountQuery
>;
export type DocumentResourceUserViewRecordCountLazyQueryHookResult = ReturnType<
	typeof useDocumentResourceUserViewRecordCountLazyQuery
>;
export type DocumentResourceUserViewRecordCountQueryResult = Apollo.QueryResult<
	DocumentResourceUserViewRecordCountQuery,
	DocumentResourceUserViewRecordCountQueryVariables
>;
export const DocumentResourceUserViewRecordCreateDocument = gql`
	mutation documentResourceUserViewRecordCreate($input: DocumentResourceUserViewRecordCreateInput!) {
		createDocumentResourceUserViewRecord(input: $input) {
			...DocumentResourceUserViewRecordDetail
		}
	}
	${DocumentResourceUserViewRecordDetailFragmentDoc}
`;
export type DocumentResourceUserViewRecordCreateMutationFn = Apollo.MutationFunction<
	DocumentResourceUserViewRecordCreateMutation,
	DocumentResourceUserViewRecordCreateMutationVariables
>;

/**
 * __useDocumentResourceUserViewRecordCreateMutation__
 *
 * To run a mutation, you first call `useDocumentResourceUserViewRecordCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentResourceUserViewRecordCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentResourceUserViewRecordCreateMutation, { data, loading, error }] = useDocumentResourceUserViewRecordCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDocumentResourceUserViewRecordCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<
		DocumentResourceUserViewRecordCreateMutation,
		DocumentResourceUserViewRecordCreateMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		DocumentResourceUserViewRecordCreateMutation,
		DocumentResourceUserViewRecordCreateMutationVariables
	>(DocumentResourceUserViewRecordCreateDocument, options);
}
export type DocumentResourceUserViewRecordCreateMutationHookResult = ReturnType<
	typeof useDocumentResourceUserViewRecordCreateMutation
>;
export type DocumentResourceUserViewRecordCreateMutationResult =
	Apollo.MutationResult<DocumentResourceUserViewRecordCreateMutation>;
export type DocumentResourceUserViewRecordCreateMutationOptions = Apollo.BaseMutationOptions<
	DocumentResourceUserViewRecordCreateMutation,
	DocumentResourceUserViewRecordCreateMutationVariables
>;
export const DocumentResourceUserViewRecordDeleteDocument = gql`
	mutation documentResourceUserViewRecordDelete($id: ID!) {
		deleteDocumentResourceUserViewRecord(id: $id)
	}
`;
export type DocumentResourceUserViewRecordDeleteMutationFn = Apollo.MutationFunction<
	DocumentResourceUserViewRecordDeleteMutation,
	DocumentResourceUserViewRecordDeleteMutationVariables
>;

/**
 * __useDocumentResourceUserViewRecordDeleteMutation__
 *
 * To run a mutation, you first call `useDocumentResourceUserViewRecordDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentResourceUserViewRecordDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentResourceUserViewRecordDeleteMutation, { data, loading, error }] = useDocumentResourceUserViewRecordDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDocumentResourceUserViewRecordDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<
		DocumentResourceUserViewRecordDeleteMutation,
		DocumentResourceUserViewRecordDeleteMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		DocumentResourceUserViewRecordDeleteMutation,
		DocumentResourceUserViewRecordDeleteMutationVariables
	>(DocumentResourceUserViewRecordDeleteDocument, options);
}
export type DocumentResourceUserViewRecordDeleteMutationHookResult = ReturnType<
	typeof useDocumentResourceUserViewRecordDeleteMutation
>;
export type DocumentResourceUserViewRecordDeleteMutationResult =
	Apollo.MutationResult<DocumentResourceUserViewRecordDeleteMutation>;
export type DocumentResourceUserViewRecordDeleteMutationOptions = Apollo.BaseMutationOptions<
	DocumentResourceUserViewRecordDeleteMutation,
	DocumentResourceUserViewRecordDeleteMutationVariables
>;
export const DocumentResourceUserViewRecordDetailDocument = gql`
	query documentResourceUserViewRecordDetail($id: ID!) {
		documentResourceUserViewRecord(id: $id) {
			...DocumentResourceUserViewRecordDetail
		}
	}
	${DocumentResourceUserViewRecordDetailFragmentDoc}
`;

/**
 * __useDocumentResourceUserViewRecordDetailQuery__
 *
 * To run a query within a React component, call `useDocumentResourceUserViewRecordDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentResourceUserViewRecordDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentResourceUserViewRecordDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDocumentResourceUserViewRecordDetailQuery(
	baseOptions: Apollo.QueryHookOptions<
		DocumentResourceUserViewRecordDetailQuery,
		DocumentResourceUserViewRecordDetailQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<DocumentResourceUserViewRecordDetailQuery, DocumentResourceUserViewRecordDetailQueryVariables>(
		DocumentResourceUserViewRecordDetailDocument,
		options,
	);
}
export function useDocumentResourceUserViewRecordDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		DocumentResourceUserViewRecordDetailQuery,
		DocumentResourceUserViewRecordDetailQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		DocumentResourceUserViewRecordDetailQuery,
		DocumentResourceUserViewRecordDetailQueryVariables
	>(DocumentResourceUserViewRecordDetailDocument, options);
}
export type DocumentResourceUserViewRecordDetailQueryHookResult = ReturnType<
	typeof useDocumentResourceUserViewRecordDetailQuery
>;
export type DocumentResourceUserViewRecordDetailLazyQueryHookResult = ReturnType<
	typeof useDocumentResourceUserViewRecordDetailLazyQuery
>;
export type DocumentResourceUserViewRecordDetailQueryResult = Apollo.QueryResult<
	DocumentResourceUserViewRecordDetailQuery,
	DocumentResourceUserViewRecordDetailQueryVariables
>;
export const DocumentResourceUserViewRecordIndexDocument = gql`
	query documentResourceUserViewRecordIndex(
		$filters: [DocumentResourceUserViewRecordFilterInput!]
		$pagination: Pagination
		$order: DocumentResourceUserViewRecordOrder
	) {
		documentResourceUserViewRecordConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					viewedAt
				}
			}
		}
	}
`;

/**
 * __useDocumentResourceUserViewRecordIndexQuery__
 *
 * To run a query within a React component, call `useDocumentResourceUserViewRecordIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentResourceUserViewRecordIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentResourceUserViewRecordIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useDocumentResourceUserViewRecordIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<
		DocumentResourceUserViewRecordIndexQuery,
		DocumentResourceUserViewRecordIndexQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<DocumentResourceUserViewRecordIndexQuery, DocumentResourceUserViewRecordIndexQueryVariables>(
		DocumentResourceUserViewRecordIndexDocument,
		options,
	);
}
export function useDocumentResourceUserViewRecordIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		DocumentResourceUserViewRecordIndexQuery,
		DocumentResourceUserViewRecordIndexQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		DocumentResourceUserViewRecordIndexQuery,
		DocumentResourceUserViewRecordIndexQueryVariables
	>(DocumentResourceUserViewRecordIndexDocument, options);
}
export type DocumentResourceUserViewRecordIndexQueryHookResult = ReturnType<
	typeof useDocumentResourceUserViewRecordIndexQuery
>;
export type DocumentResourceUserViewRecordIndexLazyQueryHookResult = ReturnType<
	typeof useDocumentResourceUserViewRecordIndexLazyQuery
>;
export type DocumentResourceUserViewRecordIndexQueryResult = Apollo.QueryResult<
	DocumentResourceUserViewRecordIndexQuery,
	DocumentResourceUserViewRecordIndexQueryVariables
>;
export const DocumentResourceUserViewRecordNameDocument = gql`
	query documentResourceUserViewRecordName($id: ID!) {
		documentResourceUserViewRecord(id: $id) {
			id
			...DocumentResourceUserViewRecordName
		}
	}
	${DocumentResourceUserViewRecordNameFragmentDoc}
`;

/**
 * __useDocumentResourceUserViewRecordNameQuery__
 *
 * To run a query within a React component, call `useDocumentResourceUserViewRecordNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentResourceUserViewRecordNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentResourceUserViewRecordNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDocumentResourceUserViewRecordNameQuery(
	baseOptions: Apollo.QueryHookOptions<
		DocumentResourceUserViewRecordNameQuery,
		DocumentResourceUserViewRecordNameQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<DocumentResourceUserViewRecordNameQuery, DocumentResourceUserViewRecordNameQueryVariables>(
		DocumentResourceUserViewRecordNameDocument,
		options,
	);
}
export function useDocumentResourceUserViewRecordNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		DocumentResourceUserViewRecordNameQuery,
		DocumentResourceUserViewRecordNameQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<DocumentResourceUserViewRecordNameQuery, DocumentResourceUserViewRecordNameQueryVariables>(
		DocumentResourceUserViewRecordNameDocument,
		options,
	);
}
export type DocumentResourceUserViewRecordNameQueryHookResult = ReturnType<
	typeof useDocumentResourceUserViewRecordNameQuery
>;
export type DocumentResourceUserViewRecordNameLazyQueryHookResult = ReturnType<
	typeof useDocumentResourceUserViewRecordNameLazyQuery
>;
export type DocumentResourceUserViewRecordNameQueryResult = Apollo.QueryResult<
	DocumentResourceUserViewRecordNameQuery,
	DocumentResourceUserViewRecordNameQueryVariables
>;
export const DocumentResourceUserViewRecordNamesDocument = gql`
	query documentResourceUserViewRecordNames(
		$filters: [DocumentResourceUserViewRecordFilterInput!]
		$pagination: Pagination
		$order: DocumentResourceUserViewRecordOrder
	) {
		documentResourceUserViewRecordConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...DocumentResourceUserViewRecordName
				}
			}
		}
	}
	${DocumentResourceUserViewRecordNameFragmentDoc}
`;

/**
 * __useDocumentResourceUserViewRecordNamesQuery__
 *
 * To run a query within a React component, call `useDocumentResourceUserViewRecordNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentResourceUserViewRecordNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentResourceUserViewRecordNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useDocumentResourceUserViewRecordNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<
		DocumentResourceUserViewRecordNamesQuery,
		DocumentResourceUserViewRecordNamesQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<DocumentResourceUserViewRecordNamesQuery, DocumentResourceUserViewRecordNamesQueryVariables>(
		DocumentResourceUserViewRecordNamesDocument,
		options,
	);
}
export function useDocumentResourceUserViewRecordNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		DocumentResourceUserViewRecordNamesQuery,
		DocumentResourceUserViewRecordNamesQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		DocumentResourceUserViewRecordNamesQuery,
		DocumentResourceUserViewRecordNamesQueryVariables
	>(DocumentResourceUserViewRecordNamesDocument, options);
}
export type DocumentResourceUserViewRecordNamesQueryHookResult = ReturnType<
	typeof useDocumentResourceUserViewRecordNamesQuery
>;
export type DocumentResourceUserViewRecordNamesLazyQueryHookResult = ReturnType<
	typeof useDocumentResourceUserViewRecordNamesLazyQuery
>;
export type DocumentResourceUserViewRecordNamesQueryResult = Apollo.QueryResult<
	DocumentResourceUserViewRecordNamesQuery,
	DocumentResourceUserViewRecordNamesQueryVariables
>;
export const DocumentResourceUserViewRecordPaginationDocument = gql`
	query documentResourceUserViewRecordPagination(
		$filters: [DocumentResourceUserViewRecordFilterInput!]
		$order: DocumentResourceUserViewRecordOrder
	) {
		documentResourceUserViewRecordConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useDocumentResourceUserViewRecordPaginationQuery__
 *
 * To run a query within a React component, call `useDocumentResourceUserViewRecordPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentResourceUserViewRecordPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentResourceUserViewRecordPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useDocumentResourceUserViewRecordPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<
		DocumentResourceUserViewRecordPaginationQuery,
		DocumentResourceUserViewRecordPaginationQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		DocumentResourceUserViewRecordPaginationQuery,
		DocumentResourceUserViewRecordPaginationQueryVariables
	>(DocumentResourceUserViewRecordPaginationDocument, options);
}
export function useDocumentResourceUserViewRecordPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		DocumentResourceUserViewRecordPaginationQuery,
		DocumentResourceUserViewRecordPaginationQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		DocumentResourceUserViewRecordPaginationQuery,
		DocumentResourceUserViewRecordPaginationQueryVariables
	>(DocumentResourceUserViewRecordPaginationDocument, options);
}
export type DocumentResourceUserViewRecordPaginationQueryHookResult = ReturnType<
	typeof useDocumentResourceUserViewRecordPaginationQuery
>;
export type DocumentResourceUserViewRecordPaginationLazyQueryHookResult = ReturnType<
	typeof useDocumentResourceUserViewRecordPaginationLazyQuery
>;
export type DocumentResourceUserViewRecordPaginationQueryResult = Apollo.QueryResult<
	DocumentResourceUserViewRecordPaginationQuery,
	DocumentResourceUserViewRecordPaginationQueryVariables
>;
export const DocumentResourceUserViewRecordRecentsDocument = gql`
	query documentResourceUserViewRecordRecents(
		$userID: ID!
		$filters: [DocumentResourceUserViewRecordFilterInput!]
		$pagination: Pagination
		$order: DocumentResourceUserViewRecordOrder
	) {
		documentResourceUserViewRecordConnectionRecents(
			userID: $userID
			filters: $filters
			pagination: $pagination
			order: $order
		) {
			edges {
				node {
					id
					viewedAt
					documentResource {
						id
						name
						description
						hasImage
						imageURL
						isFile
						fileURL
						fileContentType
						resourceURL
					}
				}
			}
		}
	}
`;

/**
 * __useDocumentResourceUserViewRecordRecentsQuery__
 *
 * To run a query within a React component, call `useDocumentResourceUserViewRecordRecentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentResourceUserViewRecordRecentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentResourceUserViewRecordRecentsQuery({
 *   variables: {
 *      userID: // value for 'userID'
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useDocumentResourceUserViewRecordRecentsQuery(
	baseOptions: Apollo.QueryHookOptions<
		DocumentResourceUserViewRecordRecentsQuery,
		DocumentResourceUserViewRecordRecentsQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		DocumentResourceUserViewRecordRecentsQuery,
		DocumentResourceUserViewRecordRecentsQueryVariables
	>(DocumentResourceUserViewRecordRecentsDocument, options);
}
export function useDocumentResourceUserViewRecordRecentsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		DocumentResourceUserViewRecordRecentsQuery,
		DocumentResourceUserViewRecordRecentsQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		DocumentResourceUserViewRecordRecentsQuery,
		DocumentResourceUserViewRecordRecentsQueryVariables
	>(DocumentResourceUserViewRecordRecentsDocument, options);
}
export type DocumentResourceUserViewRecordRecentsQueryHookResult = ReturnType<
	typeof useDocumentResourceUserViewRecordRecentsQuery
>;
export type DocumentResourceUserViewRecordRecentsLazyQueryHookResult = ReturnType<
	typeof useDocumentResourceUserViewRecordRecentsLazyQuery
>;
export type DocumentResourceUserViewRecordRecentsQueryResult = Apollo.QueryResult<
	DocumentResourceUserViewRecordRecentsQuery,
	DocumentResourceUserViewRecordRecentsQueryVariables
>;
export const DocumentResourceUserViewRecordSelectDocument = gql`
	query documentResourceUserViewRecordSelect(
		$filters: [DocumentResourceUserViewRecordFilterInput!]
		$order: DocumentResourceUserViewRecordOrder
	) {
		documentResourceUserViewRecordConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...DocumentResourceUserViewRecordName
				}
			}
		}
	}
	${DocumentResourceUserViewRecordNameFragmentDoc}
`;

/**
 * __useDocumentResourceUserViewRecordSelectQuery__
 *
 * To run a query within a React component, call `useDocumentResourceUserViewRecordSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentResourceUserViewRecordSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentResourceUserViewRecordSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useDocumentResourceUserViewRecordSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<
		DocumentResourceUserViewRecordSelectQuery,
		DocumentResourceUserViewRecordSelectQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<DocumentResourceUserViewRecordSelectQuery, DocumentResourceUserViewRecordSelectQueryVariables>(
		DocumentResourceUserViewRecordSelectDocument,
		options,
	);
}
export function useDocumentResourceUserViewRecordSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		DocumentResourceUserViewRecordSelectQuery,
		DocumentResourceUserViewRecordSelectQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		DocumentResourceUserViewRecordSelectQuery,
		DocumentResourceUserViewRecordSelectQueryVariables
	>(DocumentResourceUserViewRecordSelectDocument, options);
}
export type DocumentResourceUserViewRecordSelectQueryHookResult = ReturnType<
	typeof useDocumentResourceUserViewRecordSelectQuery
>;
export type DocumentResourceUserViewRecordSelectLazyQueryHookResult = ReturnType<
	typeof useDocumentResourceUserViewRecordSelectLazyQuery
>;
export type DocumentResourceUserViewRecordSelectQueryResult = Apollo.QueryResult<
	DocumentResourceUserViewRecordSelectQuery,
	DocumentResourceUserViewRecordSelectQueryVariables
>;
export const EmailAddressCountDocument = gql`
	query emailAddressCount($filters: [EmailAddressFilterInput!], $pagination: Pagination, $order: EmailAddressOrder) {
		emailAddressConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useEmailAddressCountQuery__
 *
 * To run a query within a React component, call `useEmailAddressCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailAddressCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailAddressCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useEmailAddressCountQuery(
	baseOptions?: Apollo.QueryHookOptions<EmailAddressCountQuery, EmailAddressCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<EmailAddressCountQuery, EmailAddressCountQueryVariables>(EmailAddressCountDocument, options);
}
export function useEmailAddressCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<EmailAddressCountQuery, EmailAddressCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<EmailAddressCountQuery, EmailAddressCountQueryVariables>(
		EmailAddressCountDocument,
		options,
	);
}
export type EmailAddressCountQueryHookResult = ReturnType<typeof useEmailAddressCountQuery>;
export type EmailAddressCountLazyQueryHookResult = ReturnType<typeof useEmailAddressCountLazyQuery>;
export type EmailAddressCountQueryResult = Apollo.QueryResult<EmailAddressCountQuery, EmailAddressCountQueryVariables>;
export const EmailAddressCreateDocument = gql`
	mutation emailAddressCreate($input: EmailAddressCreateInput!) {
		createEmailAddress(input: $input) {
			...EmailAddressDetail
		}
	}
	${EmailAddressDetailFragmentDoc}
`;
export type EmailAddressCreateMutationFn = Apollo.MutationFunction<
	EmailAddressCreateMutation,
	EmailAddressCreateMutationVariables
>;

/**
 * __useEmailAddressCreateMutation__
 *
 * To run a mutation, you first call `useEmailAddressCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailAddressCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailAddressCreateMutation, { data, loading, error }] = useEmailAddressCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEmailAddressCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<EmailAddressCreateMutation, EmailAddressCreateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<EmailAddressCreateMutation, EmailAddressCreateMutationVariables>(
		EmailAddressCreateDocument,
		options,
	);
}
export type EmailAddressCreateMutationHookResult = ReturnType<typeof useEmailAddressCreateMutation>;
export type EmailAddressCreateMutationResult = Apollo.MutationResult<EmailAddressCreateMutation>;
export type EmailAddressCreateMutationOptions = Apollo.BaseMutationOptions<
	EmailAddressCreateMutation,
	EmailAddressCreateMutationVariables
>;
export const EmailAddressDeleteDocument = gql`
	mutation emailAddressDelete($id: ID!) {
		deleteEmailAddress(id: $id)
	}
`;
export type EmailAddressDeleteMutationFn = Apollo.MutationFunction<
	EmailAddressDeleteMutation,
	EmailAddressDeleteMutationVariables
>;

/**
 * __useEmailAddressDeleteMutation__
 *
 * To run a mutation, you first call `useEmailAddressDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailAddressDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailAddressDeleteMutation, { data, loading, error }] = useEmailAddressDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEmailAddressDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<EmailAddressDeleteMutation, EmailAddressDeleteMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<EmailAddressDeleteMutation, EmailAddressDeleteMutationVariables>(
		EmailAddressDeleteDocument,
		options,
	);
}
export type EmailAddressDeleteMutationHookResult = ReturnType<typeof useEmailAddressDeleteMutation>;
export type EmailAddressDeleteMutationResult = Apollo.MutationResult<EmailAddressDeleteMutation>;
export type EmailAddressDeleteMutationOptions = Apollo.BaseMutationOptions<
	EmailAddressDeleteMutation,
	EmailAddressDeleteMutationVariables
>;
export const EmailAddressDetailDocument = gql`
	query emailAddressDetail($id: ID!) {
		emailAddress(id: $id) {
			...EmailAddressDetail
		}
	}
	${EmailAddressDetailFragmentDoc}
`;

/**
 * __useEmailAddressDetailQuery__
 *
 * To run a query within a React component, call `useEmailAddressDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailAddressDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailAddressDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEmailAddressDetailQuery(
	baseOptions: Apollo.QueryHookOptions<EmailAddressDetailQuery, EmailAddressDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<EmailAddressDetailQuery, EmailAddressDetailQueryVariables>(
		EmailAddressDetailDocument,
		options,
	);
}
export function useEmailAddressDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<EmailAddressDetailQuery, EmailAddressDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<EmailAddressDetailQuery, EmailAddressDetailQueryVariables>(
		EmailAddressDetailDocument,
		options,
	);
}
export type EmailAddressDetailQueryHookResult = ReturnType<typeof useEmailAddressDetailQuery>;
export type EmailAddressDetailLazyQueryHookResult = ReturnType<typeof useEmailAddressDetailLazyQuery>;
export type EmailAddressDetailQueryResult = Apollo.QueryResult<
	EmailAddressDetailQuery,
	EmailAddressDetailQueryVariables
>;
export const EmailAddressIndexDocument = gql`
	query emailAddressIndex($filters: [EmailAddressFilterInput!], $pagination: Pagination, $order: EmailAddressOrder) {
		emailAddressConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					email
				}
			}
		}
	}
`;

/**
 * __useEmailAddressIndexQuery__
 *
 * To run a query within a React component, call `useEmailAddressIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailAddressIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailAddressIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useEmailAddressIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<EmailAddressIndexQuery, EmailAddressIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<EmailAddressIndexQuery, EmailAddressIndexQueryVariables>(EmailAddressIndexDocument, options);
}
export function useEmailAddressIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<EmailAddressIndexQuery, EmailAddressIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<EmailAddressIndexQuery, EmailAddressIndexQueryVariables>(
		EmailAddressIndexDocument,
		options,
	);
}
export type EmailAddressIndexQueryHookResult = ReturnType<typeof useEmailAddressIndexQuery>;
export type EmailAddressIndexLazyQueryHookResult = ReturnType<typeof useEmailAddressIndexLazyQuery>;
export type EmailAddressIndexQueryResult = Apollo.QueryResult<EmailAddressIndexQuery, EmailAddressIndexQueryVariables>;
export const EmailAddressNameDocument = gql`
	query emailAddressName($id: ID!) {
		emailAddress(id: $id) {
			id
			...EmailAddressName
		}
	}
	${EmailAddressNameFragmentDoc}
`;

/**
 * __useEmailAddressNameQuery__
 *
 * To run a query within a React component, call `useEmailAddressNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailAddressNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailAddressNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEmailAddressNameQuery(
	baseOptions: Apollo.QueryHookOptions<EmailAddressNameQuery, EmailAddressNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<EmailAddressNameQuery, EmailAddressNameQueryVariables>(EmailAddressNameDocument, options);
}
export function useEmailAddressNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<EmailAddressNameQuery, EmailAddressNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<EmailAddressNameQuery, EmailAddressNameQueryVariables>(EmailAddressNameDocument, options);
}
export type EmailAddressNameQueryHookResult = ReturnType<typeof useEmailAddressNameQuery>;
export type EmailAddressNameLazyQueryHookResult = ReturnType<typeof useEmailAddressNameLazyQuery>;
export type EmailAddressNameQueryResult = Apollo.QueryResult<EmailAddressNameQuery, EmailAddressNameQueryVariables>;
export const EmailAddressNamesDocument = gql`
	query emailAddressNames($filters: [EmailAddressFilterInput!], $pagination: Pagination, $order: EmailAddressOrder) {
		emailAddressConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...EmailAddressName
				}
			}
		}
	}
	${EmailAddressNameFragmentDoc}
`;

/**
 * __useEmailAddressNamesQuery__
 *
 * To run a query within a React component, call `useEmailAddressNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailAddressNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailAddressNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useEmailAddressNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<EmailAddressNamesQuery, EmailAddressNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<EmailAddressNamesQuery, EmailAddressNamesQueryVariables>(EmailAddressNamesDocument, options);
}
export function useEmailAddressNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<EmailAddressNamesQuery, EmailAddressNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<EmailAddressNamesQuery, EmailAddressNamesQueryVariables>(
		EmailAddressNamesDocument,
		options,
	);
}
export type EmailAddressNamesQueryHookResult = ReturnType<typeof useEmailAddressNamesQuery>;
export type EmailAddressNamesLazyQueryHookResult = ReturnType<typeof useEmailAddressNamesLazyQuery>;
export type EmailAddressNamesQueryResult = Apollo.QueryResult<EmailAddressNamesQuery, EmailAddressNamesQueryVariables>;
export const EmailAddressPaginationDocument = gql`
	query emailAddressPagination($filters: [EmailAddressFilterInput!], $order: EmailAddressOrder) {
		emailAddressConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useEmailAddressPaginationQuery__
 *
 * To run a query within a React component, call `useEmailAddressPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailAddressPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailAddressPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useEmailAddressPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<EmailAddressPaginationQuery, EmailAddressPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<EmailAddressPaginationQuery, EmailAddressPaginationQueryVariables>(
		EmailAddressPaginationDocument,
		options,
	);
}
export function useEmailAddressPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<EmailAddressPaginationQuery, EmailAddressPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<EmailAddressPaginationQuery, EmailAddressPaginationQueryVariables>(
		EmailAddressPaginationDocument,
		options,
	);
}
export type EmailAddressPaginationQueryHookResult = ReturnType<typeof useEmailAddressPaginationQuery>;
export type EmailAddressPaginationLazyQueryHookResult = ReturnType<typeof useEmailAddressPaginationLazyQuery>;
export type EmailAddressPaginationQueryResult = Apollo.QueryResult<
	EmailAddressPaginationQuery,
	EmailAddressPaginationQueryVariables
>;
export const EmailAddressSelectDocument = gql`
	query emailAddressSelect($filters: [EmailAddressFilterInput!], $order: EmailAddressOrder) {
		emailAddressConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...EmailAddressName
				}
			}
		}
	}
	${EmailAddressNameFragmentDoc}
`;

/**
 * __useEmailAddressSelectQuery__
 *
 * To run a query within a React component, call `useEmailAddressSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailAddressSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailAddressSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useEmailAddressSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<EmailAddressSelectQuery, EmailAddressSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<EmailAddressSelectQuery, EmailAddressSelectQueryVariables>(
		EmailAddressSelectDocument,
		options,
	);
}
export function useEmailAddressSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<EmailAddressSelectQuery, EmailAddressSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<EmailAddressSelectQuery, EmailAddressSelectQueryVariables>(
		EmailAddressSelectDocument,
		options,
	);
}
export type EmailAddressSelectQueryHookResult = ReturnType<typeof useEmailAddressSelectQuery>;
export type EmailAddressSelectLazyQueryHookResult = ReturnType<typeof useEmailAddressSelectLazyQuery>;
export type EmailAddressSelectQueryResult = Apollo.QueryResult<
	EmailAddressSelectQuery,
	EmailAddressSelectQueryVariables
>;
export const EmailAddressUpdateDocument = gql`
	mutation emailAddressUpdate($id: ID!, $input: EmailAddressUpdateInput!) {
		updateEmailAddress(id: $id, input: $input) {
			...EmailAddressDetail
		}
	}
	${EmailAddressDetailFragmentDoc}
`;
export type EmailAddressUpdateMutationFn = Apollo.MutationFunction<
	EmailAddressUpdateMutation,
	EmailAddressUpdateMutationVariables
>;

/**
 * __useEmailAddressUpdateMutation__
 *
 * To run a mutation, you first call `useEmailAddressUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailAddressUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailAddressUpdateMutation, { data, loading, error }] = useEmailAddressUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEmailAddressUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<EmailAddressUpdateMutation, EmailAddressUpdateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<EmailAddressUpdateMutation, EmailAddressUpdateMutationVariables>(
		EmailAddressUpdateDocument,
		options,
	);
}
export type EmailAddressUpdateMutationHookResult = ReturnType<typeof useEmailAddressUpdateMutation>;
export type EmailAddressUpdateMutationResult = Apollo.MutationResult<EmailAddressUpdateMutation>;
export type EmailAddressUpdateMutationOptions = Apollo.BaseMutationOptions<
	EmailAddressUpdateMutation,
	EmailAddressUpdateMutationVariables
>;
export const EmployeeContextDocument = gql`
	query employeeContext {
		viewer {
			id
			person {
				id
				name
				nickname
				employee {
					id
					email
					role
					jobTitle
					role
					campuses {
						id
						name
					}
				}
			}
		}
	}
`;

/**
 * __useEmployeeContextQuery__
 *
 * To run a query within a React component, call `useEmployeeContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeContextQuery({
 *   variables: {
 *   },
 * });
 */
export function useEmployeeContextQuery(
	baseOptions?: Apollo.QueryHookOptions<EmployeeContextQuery, EmployeeContextQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<EmployeeContextQuery, EmployeeContextQueryVariables>(EmployeeContextDocument, options);
}
export function useEmployeeContextLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<EmployeeContextQuery, EmployeeContextQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<EmployeeContextQuery, EmployeeContextQueryVariables>(EmployeeContextDocument, options);
}
export type EmployeeContextQueryHookResult = ReturnType<typeof useEmployeeContextQuery>;
export type EmployeeContextLazyQueryHookResult = ReturnType<typeof useEmployeeContextLazyQuery>;
export type EmployeeContextQueryResult = Apollo.QueryResult<EmployeeContextQuery, EmployeeContextQueryVariables>;
export const EmployeeCountDocument = gql`
	query employeeCount($filters: [EmployeeFilterInput!], $pagination: Pagination, $order: EmployeeOrder) {
		employeeConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useEmployeeCountQuery__
 *
 * To run a query within a React component, call `useEmployeeCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useEmployeeCountQuery(
	baseOptions?: Apollo.QueryHookOptions<EmployeeCountQuery, EmployeeCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<EmployeeCountQuery, EmployeeCountQueryVariables>(EmployeeCountDocument, options);
}
export function useEmployeeCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<EmployeeCountQuery, EmployeeCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<EmployeeCountQuery, EmployeeCountQueryVariables>(EmployeeCountDocument, options);
}
export type EmployeeCountQueryHookResult = ReturnType<typeof useEmployeeCountQuery>;
export type EmployeeCountLazyQueryHookResult = ReturnType<typeof useEmployeeCountLazyQuery>;
export type EmployeeCountQueryResult = Apollo.QueryResult<EmployeeCountQuery, EmployeeCountQueryVariables>;
export const EmployeeCreateDocument = gql`
	mutation employeeCreate($input: EmployeeCreateInput!) {
		createEmployee(input: $input) {
			...EmployeeDetail
		}
	}
	${EmployeeDetailFragmentDoc}
`;
export type EmployeeCreateMutationFn = Apollo.MutationFunction<EmployeeCreateMutation, EmployeeCreateMutationVariables>;

/**
 * __useEmployeeCreateMutation__
 *
 * To run a mutation, you first call `useEmployeeCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmployeeCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [employeeCreateMutation, { data, loading, error }] = useEmployeeCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEmployeeCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<EmployeeCreateMutation, EmployeeCreateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<EmployeeCreateMutation, EmployeeCreateMutationVariables>(EmployeeCreateDocument, options);
}
export type EmployeeCreateMutationHookResult = ReturnType<typeof useEmployeeCreateMutation>;
export type EmployeeCreateMutationResult = Apollo.MutationResult<EmployeeCreateMutation>;
export type EmployeeCreateMutationOptions = Apollo.BaseMutationOptions<
	EmployeeCreateMutation,
	EmployeeCreateMutationVariables
>;
export const EmployeeDeleteDocument = gql`
	mutation employeeDelete($id: ID!) {
		deleteEmployee(id: $id)
	}
`;
export type EmployeeDeleteMutationFn = Apollo.MutationFunction<EmployeeDeleteMutation, EmployeeDeleteMutationVariables>;

/**
 * __useEmployeeDeleteMutation__
 *
 * To run a mutation, you first call `useEmployeeDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmployeeDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [employeeDeleteMutation, { data, loading, error }] = useEmployeeDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEmployeeDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<EmployeeDeleteMutation, EmployeeDeleteMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<EmployeeDeleteMutation, EmployeeDeleteMutationVariables>(EmployeeDeleteDocument, options);
}
export type EmployeeDeleteMutationHookResult = ReturnType<typeof useEmployeeDeleteMutation>;
export type EmployeeDeleteMutationResult = Apollo.MutationResult<EmployeeDeleteMutation>;
export type EmployeeDeleteMutationOptions = Apollo.BaseMutationOptions<
	EmployeeDeleteMutation,
	EmployeeDeleteMutationVariables
>;
export const EmployeeDetailDocument = gql`
	query employeeDetail($id: ID!) {
		employee(id: $id) {
			...EmployeeDetail
		}
	}
	${EmployeeDetailFragmentDoc}
`;

/**
 * __useEmployeeDetailQuery__
 *
 * To run a query within a React component, call `useEmployeeDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEmployeeDetailQuery(
	baseOptions: Apollo.QueryHookOptions<EmployeeDetailQuery, EmployeeDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<EmployeeDetailQuery, EmployeeDetailQueryVariables>(EmployeeDetailDocument, options);
}
export function useEmployeeDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<EmployeeDetailQuery, EmployeeDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<EmployeeDetailQuery, EmployeeDetailQueryVariables>(EmployeeDetailDocument, options);
}
export type EmployeeDetailQueryHookResult = ReturnType<typeof useEmployeeDetailQuery>;
export type EmployeeDetailLazyQueryHookResult = ReturnType<typeof useEmployeeDetailLazyQuery>;
export type EmployeeDetailQueryResult = Apollo.QueryResult<EmployeeDetailQuery, EmployeeDetailQueryVariables>;
export const EmployeeIndexDocument = gql`
	query employeeIndex($filters: [EmployeeFilterInput!], $pagination: Pagination, $order: EmployeeOrder) {
		employeeConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					email
					jobTitle
					role
					startDate
					endDate
					person {
						id
						name
						imageURL
					}
				}
			}
		}
	}
`;

/**
 * __useEmployeeIndexQuery__
 *
 * To run a query within a React component, call `useEmployeeIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useEmployeeIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<EmployeeIndexQuery, EmployeeIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<EmployeeIndexQuery, EmployeeIndexQueryVariables>(EmployeeIndexDocument, options);
}
export function useEmployeeIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<EmployeeIndexQuery, EmployeeIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<EmployeeIndexQuery, EmployeeIndexQueryVariables>(EmployeeIndexDocument, options);
}
export type EmployeeIndexQueryHookResult = ReturnType<typeof useEmployeeIndexQuery>;
export type EmployeeIndexLazyQueryHookResult = ReturnType<typeof useEmployeeIndexLazyQuery>;
export type EmployeeIndexQueryResult = Apollo.QueryResult<EmployeeIndexQuery, EmployeeIndexQueryVariables>;
export const EmployeeNameDocument = gql`
	query employeeName($id: ID!) {
		employee(id: $id) {
			id
			...EmployeeName
		}
	}
	${EmployeeNameFragmentDoc}
`;

/**
 * __useEmployeeNameQuery__
 *
 * To run a query within a React component, call `useEmployeeNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEmployeeNameQuery(
	baseOptions: Apollo.QueryHookOptions<EmployeeNameQuery, EmployeeNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<EmployeeNameQuery, EmployeeNameQueryVariables>(EmployeeNameDocument, options);
}
export function useEmployeeNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<EmployeeNameQuery, EmployeeNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<EmployeeNameQuery, EmployeeNameQueryVariables>(EmployeeNameDocument, options);
}
export type EmployeeNameQueryHookResult = ReturnType<typeof useEmployeeNameQuery>;
export type EmployeeNameLazyQueryHookResult = ReturnType<typeof useEmployeeNameLazyQuery>;
export type EmployeeNameQueryResult = Apollo.QueryResult<EmployeeNameQuery, EmployeeNameQueryVariables>;
export const EmployeeNamesDocument = gql`
	query employeeNames($filters: [EmployeeFilterInput!], $pagination: Pagination, $order: EmployeeOrder) {
		employeeConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...EmployeeName
				}
			}
		}
	}
	${EmployeeNameFragmentDoc}
`;

/**
 * __useEmployeeNamesQuery__
 *
 * To run a query within a React component, call `useEmployeeNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useEmployeeNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<EmployeeNamesQuery, EmployeeNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<EmployeeNamesQuery, EmployeeNamesQueryVariables>(EmployeeNamesDocument, options);
}
export function useEmployeeNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<EmployeeNamesQuery, EmployeeNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<EmployeeNamesQuery, EmployeeNamesQueryVariables>(EmployeeNamesDocument, options);
}
export type EmployeeNamesQueryHookResult = ReturnType<typeof useEmployeeNamesQuery>;
export type EmployeeNamesLazyQueryHookResult = ReturnType<typeof useEmployeeNamesLazyQuery>;
export type EmployeeNamesQueryResult = Apollo.QueryResult<EmployeeNamesQuery, EmployeeNamesQueryVariables>;
export const EmployeePaginationDocument = gql`
	query employeePagination($filters: [EmployeeFilterInput!], $order: EmployeeOrder) {
		employeeConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useEmployeePaginationQuery__
 *
 * To run a query within a React component, call `useEmployeePaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeePaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeePaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useEmployeePaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<EmployeePaginationQuery, EmployeePaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<EmployeePaginationQuery, EmployeePaginationQueryVariables>(
		EmployeePaginationDocument,
		options,
	);
}
export function useEmployeePaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<EmployeePaginationQuery, EmployeePaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<EmployeePaginationQuery, EmployeePaginationQueryVariables>(
		EmployeePaginationDocument,
		options,
	);
}
export type EmployeePaginationQueryHookResult = ReturnType<typeof useEmployeePaginationQuery>;
export type EmployeePaginationLazyQueryHookResult = ReturnType<typeof useEmployeePaginationLazyQuery>;
export type EmployeePaginationQueryResult = Apollo.QueryResult<
	EmployeePaginationQuery,
	EmployeePaginationQueryVariables
>;
export const EmployeePreviewDocument = gql`
	query employeePreview($id: ID!, $date: Time!) {
		employee(id: $id) {
			id
			person {
				id
				name
				imageURL
			}
			jobTitle
			role
			homeRoomSectionTeacherEnrollments(
				filters: { homeRoomSection: { term: { startDateLTE: $date, endDateGTE: $date } } }
			) {
				id
				homeRoomSection {
					id
					...HomeRoomSectionName
				}
			}
			courseSectionTeacherEnrollments(
				filters: { courseSection: { term: { startDateLTE: $date, endDateGTE: $date } } }
			) {
				id
				courseSection {
					id
					...CourseSectionName
				}
			}
			campuses {
				id
				...CampusName
			}
		}
	}
	${HomeRoomSectionNameFragmentDoc}
	${CourseSectionNameFragmentDoc}
	${CampusNameFragmentDoc}
`;

/**
 * __useEmployeePreviewQuery__
 *
 * To run a query within a React component, call `useEmployeePreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeePreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeePreviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useEmployeePreviewQuery(
	baseOptions: Apollo.QueryHookOptions<EmployeePreviewQuery, EmployeePreviewQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<EmployeePreviewQuery, EmployeePreviewQueryVariables>(EmployeePreviewDocument, options);
}
export function useEmployeePreviewLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<EmployeePreviewQuery, EmployeePreviewQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<EmployeePreviewQuery, EmployeePreviewQueryVariables>(EmployeePreviewDocument, options);
}
export type EmployeePreviewQueryHookResult = ReturnType<typeof useEmployeePreviewQuery>;
export type EmployeePreviewLazyQueryHookResult = ReturnType<typeof useEmployeePreviewLazyQuery>;
export type EmployeePreviewQueryResult = Apollo.QueryResult<EmployeePreviewQuery, EmployeePreviewQueryVariables>;
export const EmployeeSelectDocument = gql`
	query employeeSelect($filters: [EmployeeFilterInput!], $order: EmployeeOrder) {
		employeeConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...EmployeeName
				}
			}
		}
	}
	${EmployeeNameFragmentDoc}
`;

/**
 * __useEmployeeSelectQuery__
 *
 * To run a query within a React component, call `useEmployeeSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useEmployeeSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<EmployeeSelectQuery, EmployeeSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<EmployeeSelectQuery, EmployeeSelectQueryVariables>(EmployeeSelectDocument, options);
}
export function useEmployeeSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<EmployeeSelectQuery, EmployeeSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<EmployeeSelectQuery, EmployeeSelectQueryVariables>(EmployeeSelectDocument, options);
}
export type EmployeeSelectQueryHookResult = ReturnType<typeof useEmployeeSelectQuery>;
export type EmployeeSelectLazyQueryHookResult = ReturnType<typeof useEmployeeSelectLazyQuery>;
export type EmployeeSelectQueryResult = Apollo.QueryResult<EmployeeSelectQuery, EmployeeSelectQueryVariables>;
export const EmployeeUpdateDocument = gql`
	mutation employeeUpdate($id: ID!, $input: EmployeeUpdateInput!) {
		updateEmployee(id: $id, input: $input) {
			...EmployeeDetail
		}
	}
	${EmployeeDetailFragmentDoc}
`;
export type EmployeeUpdateMutationFn = Apollo.MutationFunction<EmployeeUpdateMutation, EmployeeUpdateMutationVariables>;

/**
 * __useEmployeeUpdateMutation__
 *
 * To run a mutation, you first call `useEmployeeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmployeeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [employeeUpdateMutation, { data, loading, error }] = useEmployeeUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEmployeeUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<EmployeeUpdateMutation, EmployeeUpdateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<EmployeeUpdateMutation, EmployeeUpdateMutationVariables>(EmployeeUpdateDocument, options);
}
export type EmployeeUpdateMutationHookResult = ReturnType<typeof useEmployeeUpdateMutation>;
export type EmployeeUpdateMutationResult = Apollo.MutationResult<EmployeeUpdateMutation>;
export type EmployeeUpdateMutationOptions = Apollo.BaseMutationOptions<
	EmployeeUpdateMutation,
	EmployeeUpdateMutationVariables
>;
export const EmployeeUpdateWithPersonDocument = gql`
	mutation employeeUpdateWithPerson($id: ID!, $input: EmployeeUpdateInput!, $personInput: PersonUpdateInput!) {
		updateEmployeeWithPerson(id: $id, input: $input, personInput: $personInput) {
			...EmployeeDetail
		}
	}
	${EmployeeDetailFragmentDoc}
`;
export type EmployeeUpdateWithPersonMutationFn = Apollo.MutationFunction<
	EmployeeUpdateWithPersonMutation,
	EmployeeUpdateWithPersonMutationVariables
>;

/**
 * __useEmployeeUpdateWithPersonMutation__
 *
 * To run a mutation, you first call `useEmployeeUpdateWithPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmployeeUpdateWithPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [employeeUpdateWithPersonMutation, { data, loading, error }] = useEmployeeUpdateWithPersonMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *      personInput: // value for 'personInput'
 *   },
 * });
 */
export function useEmployeeUpdateWithPersonMutation(
	baseOptions?: Apollo.MutationHookOptions<EmployeeUpdateWithPersonMutation, EmployeeUpdateWithPersonMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<EmployeeUpdateWithPersonMutation, EmployeeUpdateWithPersonMutationVariables>(
		EmployeeUpdateWithPersonDocument,
		options,
	);
}
export type EmployeeUpdateWithPersonMutationHookResult = ReturnType<typeof useEmployeeUpdateWithPersonMutation>;
export type EmployeeUpdateWithPersonMutationResult = Apollo.MutationResult<EmployeeUpdateWithPersonMutation>;
export type EmployeeUpdateWithPersonMutationOptions = Apollo.BaseMutationOptions<
	EmployeeUpdateWithPersonMutation,
	EmployeeUpdateWithPersonMutationVariables
>;
export const EnrollmentApplicationCountDocument = gql`
	query enrollmentApplicationCount(
		$filters: [EnrollmentApplicationFilterInput!]
		$pagination: Pagination
		$order: EnrollmentApplicationOrder
	) {
		enrollmentApplicationConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useEnrollmentApplicationCountQuery__
 *
 * To run a query within a React component, call `useEnrollmentApplicationCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentApplicationCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollmentApplicationCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useEnrollmentApplicationCountQuery(
	baseOptions?: Apollo.QueryHookOptions<EnrollmentApplicationCountQuery, EnrollmentApplicationCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<EnrollmentApplicationCountQuery, EnrollmentApplicationCountQueryVariables>(
		EnrollmentApplicationCountDocument,
		options,
	);
}
export function useEnrollmentApplicationCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<EnrollmentApplicationCountQuery, EnrollmentApplicationCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<EnrollmentApplicationCountQuery, EnrollmentApplicationCountQueryVariables>(
		EnrollmentApplicationCountDocument,
		options,
	);
}
export type EnrollmentApplicationCountQueryHookResult = ReturnType<typeof useEnrollmentApplicationCountQuery>;
export type EnrollmentApplicationCountLazyQueryHookResult = ReturnType<typeof useEnrollmentApplicationCountLazyQuery>;
export type EnrollmentApplicationCountQueryResult = Apollo.QueryResult<
	EnrollmentApplicationCountQuery,
	EnrollmentApplicationCountQueryVariables
>;
export const EnrollmentApplicationDetailDocument = gql`
	query enrollmentApplicationDetail($id: ID!) {
		enrollmentApplication(id: $id) {
			...EnrollmentApplicationDetail
		}
	}
	${EnrollmentApplicationDetailFragmentDoc}
`;

/**
 * __useEnrollmentApplicationDetailQuery__
 *
 * To run a query within a React component, call `useEnrollmentApplicationDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentApplicationDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollmentApplicationDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEnrollmentApplicationDetailQuery(
	baseOptions: Apollo.QueryHookOptions<EnrollmentApplicationDetailQuery, EnrollmentApplicationDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<EnrollmentApplicationDetailQuery, EnrollmentApplicationDetailQueryVariables>(
		EnrollmentApplicationDetailDocument,
		options,
	);
}
export function useEnrollmentApplicationDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		EnrollmentApplicationDetailQuery,
		EnrollmentApplicationDetailQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<EnrollmentApplicationDetailQuery, EnrollmentApplicationDetailQueryVariables>(
		EnrollmentApplicationDetailDocument,
		options,
	);
}
export type EnrollmentApplicationDetailQueryHookResult = ReturnType<typeof useEnrollmentApplicationDetailQuery>;
export type EnrollmentApplicationDetailLazyQueryHookResult = ReturnType<typeof useEnrollmentApplicationDetailLazyQuery>;
export type EnrollmentApplicationDetailQueryResult = Apollo.QueryResult<
	EnrollmentApplicationDetailQuery,
	EnrollmentApplicationDetailQueryVariables
>;
export const EnrollmentApplicationIndexDocument = gql`
	query enrollmentApplicationIndex(
		$filters: [EnrollmentApplicationFilterInput!]
		$pagination: Pagination
		$order: EnrollmentApplicationOrder
		$userID: ID!
	) {
		enrollmentApplicationConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					createdAt
					status
					interviewMeetingTime
					enrollmentApplicationStudent {
						id
						name
						imageURL
						dateOfBirth
						gradeLevelApplyingFor
						studentID
						enrollmentApplicationSubmission {
							id
							enrollmentApplicationParents {
								id
								name
							}
						}
					}
					enrollmentApplicationUserViewRecords(filters: { userIDEQ: $userID }) {
						id
						isDetail
					}
				}
			}
		}
	}
`;

/**
 * __useEnrollmentApplicationIndexQuery__
 *
 * To run a query within a React component, call `useEnrollmentApplicationIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentApplicationIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollmentApplicationIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useEnrollmentApplicationIndexQuery(
	baseOptions: Apollo.QueryHookOptions<EnrollmentApplicationIndexQuery, EnrollmentApplicationIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<EnrollmentApplicationIndexQuery, EnrollmentApplicationIndexQueryVariables>(
		EnrollmentApplicationIndexDocument,
		options,
	);
}
export function useEnrollmentApplicationIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<EnrollmentApplicationIndexQuery, EnrollmentApplicationIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<EnrollmentApplicationIndexQuery, EnrollmentApplicationIndexQueryVariables>(
		EnrollmentApplicationIndexDocument,
		options,
	);
}
export type EnrollmentApplicationIndexQueryHookResult = ReturnType<typeof useEnrollmentApplicationIndexQuery>;
export type EnrollmentApplicationIndexLazyQueryHookResult = ReturnType<typeof useEnrollmentApplicationIndexLazyQuery>;
export type EnrollmentApplicationIndexQueryResult = Apollo.QueryResult<
	EnrollmentApplicationIndexQuery,
	EnrollmentApplicationIndexQueryVariables
>;
export const EnrollmentApplicationNameDocument = gql`
	query enrollmentApplicationName($id: ID!) {
		enrollmentApplication(id: $id) {
			id
			...EnrollmentApplicationName
		}
	}
	${EnrollmentApplicationNameFragmentDoc}
`;

/**
 * __useEnrollmentApplicationNameQuery__
 *
 * To run a query within a React component, call `useEnrollmentApplicationNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentApplicationNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollmentApplicationNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEnrollmentApplicationNameQuery(
	baseOptions: Apollo.QueryHookOptions<EnrollmentApplicationNameQuery, EnrollmentApplicationNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<EnrollmentApplicationNameQuery, EnrollmentApplicationNameQueryVariables>(
		EnrollmentApplicationNameDocument,
		options,
	);
}
export function useEnrollmentApplicationNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<EnrollmentApplicationNameQuery, EnrollmentApplicationNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<EnrollmentApplicationNameQuery, EnrollmentApplicationNameQueryVariables>(
		EnrollmentApplicationNameDocument,
		options,
	);
}
export type EnrollmentApplicationNameQueryHookResult = ReturnType<typeof useEnrollmentApplicationNameQuery>;
export type EnrollmentApplicationNameLazyQueryHookResult = ReturnType<typeof useEnrollmentApplicationNameLazyQuery>;
export type EnrollmentApplicationNameQueryResult = Apollo.QueryResult<
	EnrollmentApplicationNameQuery,
	EnrollmentApplicationNameQueryVariables
>;
export const EnrollmentApplicationNamesDocument = gql`
	query enrollmentApplicationNames(
		$filters: [EnrollmentApplicationFilterInput!]
		$pagination: Pagination
		$order: EnrollmentApplicationOrder
	) {
		enrollmentApplicationConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...EnrollmentApplicationName
				}
			}
		}
	}
	${EnrollmentApplicationNameFragmentDoc}
`;

/**
 * __useEnrollmentApplicationNamesQuery__
 *
 * To run a query within a React component, call `useEnrollmentApplicationNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentApplicationNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollmentApplicationNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useEnrollmentApplicationNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<EnrollmentApplicationNamesQuery, EnrollmentApplicationNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<EnrollmentApplicationNamesQuery, EnrollmentApplicationNamesQueryVariables>(
		EnrollmentApplicationNamesDocument,
		options,
	);
}
export function useEnrollmentApplicationNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<EnrollmentApplicationNamesQuery, EnrollmentApplicationNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<EnrollmentApplicationNamesQuery, EnrollmentApplicationNamesQueryVariables>(
		EnrollmentApplicationNamesDocument,
		options,
	);
}
export type EnrollmentApplicationNamesQueryHookResult = ReturnType<typeof useEnrollmentApplicationNamesQuery>;
export type EnrollmentApplicationNamesLazyQueryHookResult = ReturnType<typeof useEnrollmentApplicationNamesLazyQuery>;
export type EnrollmentApplicationNamesQueryResult = Apollo.QueryResult<
	EnrollmentApplicationNamesQuery,
	EnrollmentApplicationNamesQueryVariables
>;
export const EnrollmentApplicationNotificationListDocument = gql`
	query enrollmentApplicationNotificationList($userID: ID!) {
		enrollmentApplicationConnection {
			edges {
				node {
					id
					enrollmentApplicationUserViewRecords(filters: { userIDEQ: $userID }) {
						id
						isDetail
					}
				}
			}
		}
	}
`;

/**
 * __useEnrollmentApplicationNotificationListQuery__
 *
 * To run a query within a React component, call `useEnrollmentApplicationNotificationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentApplicationNotificationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollmentApplicationNotificationListQuery({
 *   variables: {
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useEnrollmentApplicationNotificationListQuery(
	baseOptions: Apollo.QueryHookOptions<
		EnrollmentApplicationNotificationListQuery,
		EnrollmentApplicationNotificationListQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		EnrollmentApplicationNotificationListQuery,
		EnrollmentApplicationNotificationListQueryVariables
	>(EnrollmentApplicationNotificationListDocument, options);
}
export function useEnrollmentApplicationNotificationListLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		EnrollmentApplicationNotificationListQuery,
		EnrollmentApplicationNotificationListQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		EnrollmentApplicationNotificationListQuery,
		EnrollmentApplicationNotificationListQueryVariables
	>(EnrollmentApplicationNotificationListDocument, options);
}
export type EnrollmentApplicationNotificationListQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationNotificationListQuery
>;
export type EnrollmentApplicationNotificationListLazyQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationNotificationListLazyQuery
>;
export type EnrollmentApplicationNotificationListQueryResult = Apollo.QueryResult<
	EnrollmentApplicationNotificationListQuery,
	EnrollmentApplicationNotificationListQueryVariables
>;
export const EnrollmentApplicationPaginationDocument = gql`
	query enrollmentApplicationPagination(
		$filters: [EnrollmentApplicationFilterInput!]
		$order: EnrollmentApplicationOrder
	) {
		enrollmentApplicationConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useEnrollmentApplicationPaginationQuery__
 *
 * To run a query within a React component, call `useEnrollmentApplicationPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentApplicationPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollmentApplicationPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useEnrollmentApplicationPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<
		EnrollmentApplicationPaginationQuery,
		EnrollmentApplicationPaginationQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<EnrollmentApplicationPaginationQuery, EnrollmentApplicationPaginationQueryVariables>(
		EnrollmentApplicationPaginationDocument,
		options,
	);
}
export function useEnrollmentApplicationPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		EnrollmentApplicationPaginationQuery,
		EnrollmentApplicationPaginationQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<EnrollmentApplicationPaginationQuery, EnrollmentApplicationPaginationQueryVariables>(
		EnrollmentApplicationPaginationDocument,
		options,
	);
}
export type EnrollmentApplicationPaginationQueryHookResult = ReturnType<typeof useEnrollmentApplicationPaginationQuery>;
export type EnrollmentApplicationPaginationLazyQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationPaginationLazyQuery
>;
export type EnrollmentApplicationPaginationQueryResult = Apollo.QueryResult<
	EnrollmentApplicationPaginationQuery,
	EnrollmentApplicationPaginationQueryVariables
>;
export const EnrollmentApplicationSelectDocument = gql`
	query enrollmentApplicationSelect($filters: [EnrollmentApplicationFilterInput!], $order: EnrollmentApplicationOrder) {
		enrollmentApplicationConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...EnrollmentApplicationName
				}
			}
		}
	}
	${EnrollmentApplicationNameFragmentDoc}
`;

/**
 * __useEnrollmentApplicationSelectQuery__
 *
 * To run a query within a React component, call `useEnrollmentApplicationSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentApplicationSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollmentApplicationSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useEnrollmentApplicationSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<EnrollmentApplicationSelectQuery, EnrollmentApplicationSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<EnrollmentApplicationSelectQuery, EnrollmentApplicationSelectQueryVariables>(
		EnrollmentApplicationSelectDocument,
		options,
	);
}
export function useEnrollmentApplicationSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		EnrollmentApplicationSelectQuery,
		EnrollmentApplicationSelectQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<EnrollmentApplicationSelectQuery, EnrollmentApplicationSelectQueryVariables>(
		EnrollmentApplicationSelectDocument,
		options,
	);
}
export type EnrollmentApplicationSelectQueryHookResult = ReturnType<typeof useEnrollmentApplicationSelectQuery>;
export type EnrollmentApplicationSelectLazyQueryHookResult = ReturnType<typeof useEnrollmentApplicationSelectLazyQuery>;
export type EnrollmentApplicationSelectQueryResult = Apollo.QueryResult<
	EnrollmentApplicationSelectQuery,
	EnrollmentApplicationSelectQueryVariables
>;
export const SendEnrollmentApplicationApprovedEmailDocument = gql`
	mutation sendEnrollmentApplicationApprovedEmail($enrollmentApplicationID: ID!) {
		sendEnrollmentApplicationApprovedEmail(enrollmentApplicationID: $enrollmentApplicationID)
	}
`;
export type SendEnrollmentApplicationApprovedEmailMutationFn = Apollo.MutationFunction<
	SendEnrollmentApplicationApprovedEmailMutation,
	SendEnrollmentApplicationApprovedEmailMutationVariables
>;

/**
 * __useSendEnrollmentApplicationApprovedEmailMutation__
 *
 * To run a mutation, you first call `useSendEnrollmentApplicationApprovedEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEnrollmentApplicationApprovedEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEnrollmentApplicationApprovedEmailMutation, { data, loading, error }] = useSendEnrollmentApplicationApprovedEmailMutation({
 *   variables: {
 *      enrollmentApplicationID: // value for 'enrollmentApplicationID'
 *   },
 * });
 */
export function useSendEnrollmentApplicationApprovedEmailMutation(
	baseOptions?: Apollo.MutationHookOptions<
		SendEnrollmentApplicationApprovedEmailMutation,
		SendEnrollmentApplicationApprovedEmailMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		SendEnrollmentApplicationApprovedEmailMutation,
		SendEnrollmentApplicationApprovedEmailMutationVariables
	>(SendEnrollmentApplicationApprovedEmailDocument, options);
}
export type SendEnrollmentApplicationApprovedEmailMutationHookResult = ReturnType<
	typeof useSendEnrollmentApplicationApprovedEmailMutation
>;
export type SendEnrollmentApplicationApprovedEmailMutationResult =
	Apollo.MutationResult<SendEnrollmentApplicationApprovedEmailMutation>;
export type SendEnrollmentApplicationApprovedEmailMutationOptions = Apollo.BaseMutationOptions<
	SendEnrollmentApplicationApprovedEmailMutation,
	SendEnrollmentApplicationApprovedEmailMutationVariables
>;
export const EnrollmentApplicationUpdateDocument = gql`
	mutation enrollmentApplicationUpdate($id: ID!, $input: EnrollmentApplicationUpdateInput!) {
		updateEnrollmentApplication(id: $id, input: $input) {
			...EnrollmentApplicationDetail
		}
	}
	${EnrollmentApplicationDetailFragmentDoc}
`;
export type EnrollmentApplicationUpdateMutationFn = Apollo.MutationFunction<
	EnrollmentApplicationUpdateMutation,
	EnrollmentApplicationUpdateMutationVariables
>;

/**
 * __useEnrollmentApplicationUpdateMutation__
 *
 * To run a mutation, you first call `useEnrollmentApplicationUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentApplicationUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enrollmentApplicationUpdateMutation, { data, loading, error }] = useEnrollmentApplicationUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEnrollmentApplicationUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<
		EnrollmentApplicationUpdateMutation,
		EnrollmentApplicationUpdateMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<EnrollmentApplicationUpdateMutation, EnrollmentApplicationUpdateMutationVariables>(
		EnrollmentApplicationUpdateDocument,
		options,
	);
}
export type EnrollmentApplicationUpdateMutationHookResult = ReturnType<typeof useEnrollmentApplicationUpdateMutation>;
export type EnrollmentApplicationUpdateMutationResult = Apollo.MutationResult<EnrollmentApplicationUpdateMutation>;
export type EnrollmentApplicationUpdateMutationOptions = Apollo.BaseMutationOptions<
	EnrollmentApplicationUpdateMutation,
	EnrollmentApplicationUpdateMutationVariables
>;
export const EnrollmentApplicationNoteCreateDocument = gql`
	mutation enrollmentApplicationNoteCreate($input: EnrollmentApplicationNoteCreateInput!) {
		createEnrollmentApplicationNote(input: $input) {
			...EnrollmentApplicationNoteDetail
		}
	}
	${EnrollmentApplicationNoteDetailFragmentDoc}
`;
export type EnrollmentApplicationNoteCreateMutationFn = Apollo.MutationFunction<
	EnrollmentApplicationNoteCreateMutation,
	EnrollmentApplicationNoteCreateMutationVariables
>;

/**
 * __useEnrollmentApplicationNoteCreateMutation__
 *
 * To run a mutation, you first call `useEnrollmentApplicationNoteCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentApplicationNoteCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enrollmentApplicationNoteCreateMutation, { data, loading, error }] = useEnrollmentApplicationNoteCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEnrollmentApplicationNoteCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<
		EnrollmentApplicationNoteCreateMutation,
		EnrollmentApplicationNoteCreateMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<EnrollmentApplicationNoteCreateMutation, EnrollmentApplicationNoteCreateMutationVariables>(
		EnrollmentApplicationNoteCreateDocument,
		options,
	);
}
export type EnrollmentApplicationNoteCreateMutationHookResult = ReturnType<
	typeof useEnrollmentApplicationNoteCreateMutation
>;
export type EnrollmentApplicationNoteCreateMutationResult =
	Apollo.MutationResult<EnrollmentApplicationNoteCreateMutation>;
export type EnrollmentApplicationNoteCreateMutationOptions = Apollo.BaseMutationOptions<
	EnrollmentApplicationNoteCreateMutation,
	EnrollmentApplicationNoteCreateMutationVariables
>;
export const EnrollmentApplicationNoteDeleteDocument = gql`
	mutation enrollmentApplicationNoteDelete($id: ID!) {
		deleteEnrollmentApplicationNote(id: $id)
	}
`;
export type EnrollmentApplicationNoteDeleteMutationFn = Apollo.MutationFunction<
	EnrollmentApplicationNoteDeleteMutation,
	EnrollmentApplicationNoteDeleteMutationVariables
>;

/**
 * __useEnrollmentApplicationNoteDeleteMutation__
 *
 * To run a mutation, you first call `useEnrollmentApplicationNoteDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentApplicationNoteDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enrollmentApplicationNoteDeleteMutation, { data, loading, error }] = useEnrollmentApplicationNoteDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEnrollmentApplicationNoteDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<
		EnrollmentApplicationNoteDeleteMutation,
		EnrollmentApplicationNoteDeleteMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<EnrollmentApplicationNoteDeleteMutation, EnrollmentApplicationNoteDeleteMutationVariables>(
		EnrollmentApplicationNoteDeleteDocument,
		options,
	);
}
export type EnrollmentApplicationNoteDeleteMutationHookResult = ReturnType<
	typeof useEnrollmentApplicationNoteDeleteMutation
>;
export type EnrollmentApplicationNoteDeleteMutationResult =
	Apollo.MutationResult<EnrollmentApplicationNoteDeleteMutation>;
export type EnrollmentApplicationNoteDeleteMutationOptions = Apollo.BaseMutationOptions<
	EnrollmentApplicationNoteDeleteMutation,
	EnrollmentApplicationNoteDeleteMutationVariables
>;
export const EnrollmentApplicationNoteDetailDocument = gql`
	query enrollmentApplicationNoteDetail($id: ID!) {
		enrollmentApplicationNote(id: $id) {
			...EnrollmentApplicationNoteDetail
		}
	}
	${EnrollmentApplicationNoteDetailFragmentDoc}
`;

/**
 * __useEnrollmentApplicationNoteDetailQuery__
 *
 * To run a query within a React component, call `useEnrollmentApplicationNoteDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentApplicationNoteDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollmentApplicationNoteDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEnrollmentApplicationNoteDetailQuery(
	baseOptions: Apollo.QueryHookOptions<
		EnrollmentApplicationNoteDetailQuery,
		EnrollmentApplicationNoteDetailQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<EnrollmentApplicationNoteDetailQuery, EnrollmentApplicationNoteDetailQueryVariables>(
		EnrollmentApplicationNoteDetailDocument,
		options,
	);
}
export function useEnrollmentApplicationNoteDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		EnrollmentApplicationNoteDetailQuery,
		EnrollmentApplicationNoteDetailQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<EnrollmentApplicationNoteDetailQuery, EnrollmentApplicationNoteDetailQueryVariables>(
		EnrollmentApplicationNoteDetailDocument,
		options,
	);
}
export type EnrollmentApplicationNoteDetailQueryHookResult = ReturnType<typeof useEnrollmentApplicationNoteDetailQuery>;
export type EnrollmentApplicationNoteDetailLazyQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationNoteDetailLazyQuery
>;
export type EnrollmentApplicationNoteDetailQueryResult = Apollo.QueryResult<
	EnrollmentApplicationNoteDetailQuery,
	EnrollmentApplicationNoteDetailQueryVariables
>;
export const EnrollmentApplicationNoteIndexDocument = gql`
	query enrollmentApplicationNoteIndex(
		$filters: [EnrollmentApplicationNoteFilterInput!]
		$pagination: Pagination
		$order: EnrollmentApplicationNoteOrder
	) {
		enrollmentApplicationNoteConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					createdAt
					body
				}
			}
		}
	}
`;

/**
 * __useEnrollmentApplicationNoteIndexQuery__
 *
 * To run a query within a React component, call `useEnrollmentApplicationNoteIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentApplicationNoteIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollmentApplicationNoteIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useEnrollmentApplicationNoteIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<
		EnrollmentApplicationNoteIndexQuery,
		EnrollmentApplicationNoteIndexQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<EnrollmentApplicationNoteIndexQuery, EnrollmentApplicationNoteIndexQueryVariables>(
		EnrollmentApplicationNoteIndexDocument,
		options,
	);
}
export function useEnrollmentApplicationNoteIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		EnrollmentApplicationNoteIndexQuery,
		EnrollmentApplicationNoteIndexQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<EnrollmentApplicationNoteIndexQuery, EnrollmentApplicationNoteIndexQueryVariables>(
		EnrollmentApplicationNoteIndexDocument,
		options,
	);
}
export type EnrollmentApplicationNoteIndexQueryHookResult = ReturnType<typeof useEnrollmentApplicationNoteIndexQuery>;
export type EnrollmentApplicationNoteIndexLazyQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationNoteIndexLazyQuery
>;
export type EnrollmentApplicationNoteIndexQueryResult = Apollo.QueryResult<
	EnrollmentApplicationNoteIndexQuery,
	EnrollmentApplicationNoteIndexQueryVariables
>;
export const EnrollmentApplicationNoteNameDocument = gql`
	query enrollmentApplicationNoteName($id: ID!) {
		enrollmentApplicationNote(id: $id) {
			id
			...EnrollmentApplicationNoteName
		}
	}
	${EnrollmentApplicationNoteNameFragmentDoc}
`;

/**
 * __useEnrollmentApplicationNoteNameQuery__
 *
 * To run a query within a React component, call `useEnrollmentApplicationNoteNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentApplicationNoteNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollmentApplicationNoteNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEnrollmentApplicationNoteNameQuery(
	baseOptions: Apollo.QueryHookOptions<EnrollmentApplicationNoteNameQuery, EnrollmentApplicationNoteNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<EnrollmentApplicationNoteNameQuery, EnrollmentApplicationNoteNameQueryVariables>(
		EnrollmentApplicationNoteNameDocument,
		options,
	);
}
export function useEnrollmentApplicationNoteNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		EnrollmentApplicationNoteNameQuery,
		EnrollmentApplicationNoteNameQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<EnrollmentApplicationNoteNameQuery, EnrollmentApplicationNoteNameQueryVariables>(
		EnrollmentApplicationNoteNameDocument,
		options,
	);
}
export type EnrollmentApplicationNoteNameQueryHookResult = ReturnType<typeof useEnrollmentApplicationNoteNameQuery>;
export type EnrollmentApplicationNoteNameLazyQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationNoteNameLazyQuery
>;
export type EnrollmentApplicationNoteNameQueryResult = Apollo.QueryResult<
	EnrollmentApplicationNoteNameQuery,
	EnrollmentApplicationNoteNameQueryVariables
>;
export const EnrollmentApplicationNoteNamesDocument = gql`
	query enrollmentApplicationNoteNames(
		$filters: [EnrollmentApplicationNoteFilterInput!]
		$pagination: Pagination
		$order: EnrollmentApplicationNoteOrder
	) {
		enrollmentApplicationNoteConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...EnrollmentApplicationNoteName
				}
			}
		}
	}
	${EnrollmentApplicationNoteNameFragmentDoc}
`;

/**
 * __useEnrollmentApplicationNoteNamesQuery__
 *
 * To run a query within a React component, call `useEnrollmentApplicationNoteNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentApplicationNoteNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollmentApplicationNoteNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useEnrollmentApplicationNoteNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<
		EnrollmentApplicationNoteNamesQuery,
		EnrollmentApplicationNoteNamesQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<EnrollmentApplicationNoteNamesQuery, EnrollmentApplicationNoteNamesQueryVariables>(
		EnrollmentApplicationNoteNamesDocument,
		options,
	);
}
export function useEnrollmentApplicationNoteNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		EnrollmentApplicationNoteNamesQuery,
		EnrollmentApplicationNoteNamesQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<EnrollmentApplicationNoteNamesQuery, EnrollmentApplicationNoteNamesQueryVariables>(
		EnrollmentApplicationNoteNamesDocument,
		options,
	);
}
export type EnrollmentApplicationNoteNamesQueryHookResult = ReturnType<typeof useEnrollmentApplicationNoteNamesQuery>;
export type EnrollmentApplicationNoteNamesLazyQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationNoteNamesLazyQuery
>;
export type EnrollmentApplicationNoteNamesQueryResult = Apollo.QueryResult<
	EnrollmentApplicationNoteNamesQuery,
	EnrollmentApplicationNoteNamesQueryVariables
>;
export const EnrollmentApplicationNotePaginationDocument = gql`
	query enrollmentApplicationNotePagination(
		$filters: [EnrollmentApplicationNoteFilterInput!]
		$order: EnrollmentApplicationNoteOrder
	) {
		enrollmentApplicationNoteConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useEnrollmentApplicationNotePaginationQuery__
 *
 * To run a query within a React component, call `useEnrollmentApplicationNotePaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentApplicationNotePaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollmentApplicationNotePaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useEnrollmentApplicationNotePaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<
		EnrollmentApplicationNotePaginationQuery,
		EnrollmentApplicationNotePaginationQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<EnrollmentApplicationNotePaginationQuery, EnrollmentApplicationNotePaginationQueryVariables>(
		EnrollmentApplicationNotePaginationDocument,
		options,
	);
}
export function useEnrollmentApplicationNotePaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		EnrollmentApplicationNotePaginationQuery,
		EnrollmentApplicationNotePaginationQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		EnrollmentApplicationNotePaginationQuery,
		EnrollmentApplicationNotePaginationQueryVariables
	>(EnrollmentApplicationNotePaginationDocument, options);
}
export type EnrollmentApplicationNotePaginationQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationNotePaginationQuery
>;
export type EnrollmentApplicationNotePaginationLazyQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationNotePaginationLazyQuery
>;
export type EnrollmentApplicationNotePaginationQueryResult = Apollo.QueryResult<
	EnrollmentApplicationNotePaginationQuery,
	EnrollmentApplicationNotePaginationQueryVariables
>;
export const EnrollmentApplicationNoteSelectDocument = gql`
	query enrollmentApplicationNoteSelect(
		$filters: [EnrollmentApplicationNoteFilterInput!]
		$order: EnrollmentApplicationNoteOrder
	) {
		enrollmentApplicationNoteConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...EnrollmentApplicationNoteName
				}
			}
		}
	}
	${EnrollmentApplicationNoteNameFragmentDoc}
`;

/**
 * __useEnrollmentApplicationNoteSelectQuery__
 *
 * To run a query within a React component, call `useEnrollmentApplicationNoteSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentApplicationNoteSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollmentApplicationNoteSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useEnrollmentApplicationNoteSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<
		EnrollmentApplicationNoteSelectQuery,
		EnrollmentApplicationNoteSelectQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<EnrollmentApplicationNoteSelectQuery, EnrollmentApplicationNoteSelectQueryVariables>(
		EnrollmentApplicationNoteSelectDocument,
		options,
	);
}
export function useEnrollmentApplicationNoteSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		EnrollmentApplicationNoteSelectQuery,
		EnrollmentApplicationNoteSelectQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<EnrollmentApplicationNoteSelectQuery, EnrollmentApplicationNoteSelectQueryVariables>(
		EnrollmentApplicationNoteSelectDocument,
		options,
	);
}
export type EnrollmentApplicationNoteSelectQueryHookResult = ReturnType<typeof useEnrollmentApplicationNoteSelectQuery>;
export type EnrollmentApplicationNoteSelectLazyQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationNoteSelectLazyQuery
>;
export type EnrollmentApplicationNoteSelectQueryResult = Apollo.QueryResult<
	EnrollmentApplicationNoteSelectQuery,
	EnrollmentApplicationNoteSelectQueryVariables
>;
export const EnrollmentApplicationNoteUpdateDocument = gql`
	mutation enrollmentApplicationNoteUpdate($id: ID!, $input: EnrollmentApplicationNoteUpdateInput!) {
		updateEnrollmentApplicationNote(id: $id, input: $input) {
			...EnrollmentApplicationNoteDetail
		}
	}
	${EnrollmentApplicationNoteDetailFragmentDoc}
`;
export type EnrollmentApplicationNoteUpdateMutationFn = Apollo.MutationFunction<
	EnrollmentApplicationNoteUpdateMutation,
	EnrollmentApplicationNoteUpdateMutationVariables
>;

/**
 * __useEnrollmentApplicationNoteUpdateMutation__
 *
 * To run a mutation, you first call `useEnrollmentApplicationNoteUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentApplicationNoteUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enrollmentApplicationNoteUpdateMutation, { data, loading, error }] = useEnrollmentApplicationNoteUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEnrollmentApplicationNoteUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<
		EnrollmentApplicationNoteUpdateMutation,
		EnrollmentApplicationNoteUpdateMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<EnrollmentApplicationNoteUpdateMutation, EnrollmentApplicationNoteUpdateMutationVariables>(
		EnrollmentApplicationNoteUpdateDocument,
		options,
	);
}
export type EnrollmentApplicationNoteUpdateMutationHookResult = ReturnType<
	typeof useEnrollmentApplicationNoteUpdateMutation
>;
export type EnrollmentApplicationNoteUpdateMutationResult =
	Apollo.MutationResult<EnrollmentApplicationNoteUpdateMutation>;
export type EnrollmentApplicationNoteUpdateMutationOptions = Apollo.BaseMutationOptions<
	EnrollmentApplicationNoteUpdateMutation,
	EnrollmentApplicationNoteUpdateMutationVariables
>;
export const EnrollmentApplicationParentCountDocument = gql`
	query enrollmentApplicationParentCount(
		$filters: [EnrollmentApplicationParentFilterInput!]
		$pagination: Pagination
		$order: EnrollmentApplicationParentOrder
	) {
		enrollmentApplicationParentConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useEnrollmentApplicationParentCountQuery__
 *
 * To run a query within a React component, call `useEnrollmentApplicationParentCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentApplicationParentCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollmentApplicationParentCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useEnrollmentApplicationParentCountQuery(
	baseOptions?: Apollo.QueryHookOptions<
		EnrollmentApplicationParentCountQuery,
		EnrollmentApplicationParentCountQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<EnrollmentApplicationParentCountQuery, EnrollmentApplicationParentCountQueryVariables>(
		EnrollmentApplicationParentCountDocument,
		options,
	);
}
export function useEnrollmentApplicationParentCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		EnrollmentApplicationParentCountQuery,
		EnrollmentApplicationParentCountQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<EnrollmentApplicationParentCountQuery, EnrollmentApplicationParentCountQueryVariables>(
		EnrollmentApplicationParentCountDocument,
		options,
	);
}
export type EnrollmentApplicationParentCountQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationParentCountQuery
>;
export type EnrollmentApplicationParentCountLazyQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationParentCountLazyQuery
>;
export type EnrollmentApplicationParentCountQueryResult = Apollo.QueryResult<
	EnrollmentApplicationParentCountQuery,
	EnrollmentApplicationParentCountQueryVariables
>;
export const EnrollmentApplicationParentCreateDocument = gql`
	mutation enrollmentApplicationParentCreate($input: EnrollmentApplicationParentCreateInput!) {
		createEnrollmentApplicationParent(input: $input) {
			...EnrollmentApplicationParentDetail
		}
	}
	${EnrollmentApplicationParentDetailFragmentDoc}
`;
export type EnrollmentApplicationParentCreateMutationFn = Apollo.MutationFunction<
	EnrollmentApplicationParentCreateMutation,
	EnrollmentApplicationParentCreateMutationVariables
>;

/**
 * __useEnrollmentApplicationParentCreateMutation__
 *
 * To run a mutation, you first call `useEnrollmentApplicationParentCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentApplicationParentCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enrollmentApplicationParentCreateMutation, { data, loading, error }] = useEnrollmentApplicationParentCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEnrollmentApplicationParentCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<
		EnrollmentApplicationParentCreateMutation,
		EnrollmentApplicationParentCreateMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		EnrollmentApplicationParentCreateMutation,
		EnrollmentApplicationParentCreateMutationVariables
	>(EnrollmentApplicationParentCreateDocument, options);
}
export type EnrollmentApplicationParentCreateMutationHookResult = ReturnType<
	typeof useEnrollmentApplicationParentCreateMutation
>;
export type EnrollmentApplicationParentCreateMutationResult =
	Apollo.MutationResult<EnrollmentApplicationParentCreateMutation>;
export type EnrollmentApplicationParentCreateMutationOptions = Apollo.BaseMutationOptions<
	EnrollmentApplicationParentCreateMutation,
	EnrollmentApplicationParentCreateMutationVariables
>;
export const EnrollmentApplicationParentDeleteDocument = gql`
	mutation enrollmentApplicationParentDelete($id: ID!) {
		deleteEnrollmentApplicationParent(id: $id)
	}
`;
export type EnrollmentApplicationParentDeleteMutationFn = Apollo.MutationFunction<
	EnrollmentApplicationParentDeleteMutation,
	EnrollmentApplicationParentDeleteMutationVariables
>;

/**
 * __useEnrollmentApplicationParentDeleteMutation__
 *
 * To run a mutation, you first call `useEnrollmentApplicationParentDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentApplicationParentDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enrollmentApplicationParentDeleteMutation, { data, loading, error }] = useEnrollmentApplicationParentDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEnrollmentApplicationParentDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<
		EnrollmentApplicationParentDeleteMutation,
		EnrollmentApplicationParentDeleteMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		EnrollmentApplicationParentDeleteMutation,
		EnrollmentApplicationParentDeleteMutationVariables
	>(EnrollmentApplicationParentDeleteDocument, options);
}
export type EnrollmentApplicationParentDeleteMutationHookResult = ReturnType<
	typeof useEnrollmentApplicationParentDeleteMutation
>;
export type EnrollmentApplicationParentDeleteMutationResult =
	Apollo.MutationResult<EnrollmentApplicationParentDeleteMutation>;
export type EnrollmentApplicationParentDeleteMutationOptions = Apollo.BaseMutationOptions<
	EnrollmentApplicationParentDeleteMutation,
	EnrollmentApplicationParentDeleteMutationVariables
>;
export const EnrollmentApplicationParentDetailDocument = gql`
	query enrollmentApplicationParentDetail($id: ID!) {
		enrollmentApplicationParent(id: $id) {
			...EnrollmentApplicationParentDetail
		}
	}
	${EnrollmentApplicationParentDetailFragmentDoc}
`;

/**
 * __useEnrollmentApplicationParentDetailQuery__
 *
 * To run a query within a React component, call `useEnrollmentApplicationParentDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentApplicationParentDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollmentApplicationParentDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEnrollmentApplicationParentDetailQuery(
	baseOptions: Apollo.QueryHookOptions<
		EnrollmentApplicationParentDetailQuery,
		EnrollmentApplicationParentDetailQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<EnrollmentApplicationParentDetailQuery, EnrollmentApplicationParentDetailQueryVariables>(
		EnrollmentApplicationParentDetailDocument,
		options,
	);
}
export function useEnrollmentApplicationParentDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		EnrollmentApplicationParentDetailQuery,
		EnrollmentApplicationParentDetailQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<EnrollmentApplicationParentDetailQuery, EnrollmentApplicationParentDetailQueryVariables>(
		EnrollmentApplicationParentDetailDocument,
		options,
	);
}
export type EnrollmentApplicationParentDetailQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationParentDetailQuery
>;
export type EnrollmentApplicationParentDetailLazyQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationParentDetailLazyQuery
>;
export type EnrollmentApplicationParentDetailQueryResult = Apollo.QueryResult<
	EnrollmentApplicationParentDetailQuery,
	EnrollmentApplicationParentDetailQueryVariables
>;
export const EnrollmentApplicationParentIndexDocument = gql`
	query enrollmentApplicationParentIndex(
		$filters: [EnrollmentApplicationParentFilterInput!]
		$pagination: Pagination
		$order: EnrollmentApplicationParentOrder
	) {
		enrollmentApplicationParentConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					formIdentifier
					name
					firstName
					lastName
					nationality
					occupation
					relationshipType
					relationshipTypeCustom
					isPrimaryContact
					livesWithStudent
					isAuthorizedForPickup
					isEmergencyContact
					isFinancialContact
					addressSameAsFormIdentifier
					addressLine1
					addressLine2
					city
					stateOrProvince
					postalCode
					country
					homePhone
					workPhone
					mobilePhone
					email
				}
			}
		}
	}
`;

/**
 * __useEnrollmentApplicationParentIndexQuery__
 *
 * To run a query within a React component, call `useEnrollmentApplicationParentIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentApplicationParentIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollmentApplicationParentIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useEnrollmentApplicationParentIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<
		EnrollmentApplicationParentIndexQuery,
		EnrollmentApplicationParentIndexQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<EnrollmentApplicationParentIndexQuery, EnrollmentApplicationParentIndexQueryVariables>(
		EnrollmentApplicationParentIndexDocument,
		options,
	);
}
export function useEnrollmentApplicationParentIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		EnrollmentApplicationParentIndexQuery,
		EnrollmentApplicationParentIndexQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<EnrollmentApplicationParentIndexQuery, EnrollmentApplicationParentIndexQueryVariables>(
		EnrollmentApplicationParentIndexDocument,
		options,
	);
}
export type EnrollmentApplicationParentIndexQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationParentIndexQuery
>;
export type EnrollmentApplicationParentIndexLazyQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationParentIndexLazyQuery
>;
export type EnrollmentApplicationParentIndexQueryResult = Apollo.QueryResult<
	EnrollmentApplicationParentIndexQuery,
	EnrollmentApplicationParentIndexQueryVariables
>;
export const EnrollmentApplicationParentNameDocument = gql`
	query enrollmentApplicationParentName($id: ID!) {
		enrollmentApplicationParent(id: $id) {
			id
			...EnrollmentApplicationParentName
		}
	}
	${EnrollmentApplicationParentNameFragmentDoc}
`;

/**
 * __useEnrollmentApplicationParentNameQuery__
 *
 * To run a query within a React component, call `useEnrollmentApplicationParentNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentApplicationParentNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollmentApplicationParentNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEnrollmentApplicationParentNameQuery(
	baseOptions: Apollo.QueryHookOptions<
		EnrollmentApplicationParentNameQuery,
		EnrollmentApplicationParentNameQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<EnrollmentApplicationParentNameQuery, EnrollmentApplicationParentNameQueryVariables>(
		EnrollmentApplicationParentNameDocument,
		options,
	);
}
export function useEnrollmentApplicationParentNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		EnrollmentApplicationParentNameQuery,
		EnrollmentApplicationParentNameQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<EnrollmentApplicationParentNameQuery, EnrollmentApplicationParentNameQueryVariables>(
		EnrollmentApplicationParentNameDocument,
		options,
	);
}
export type EnrollmentApplicationParentNameQueryHookResult = ReturnType<typeof useEnrollmentApplicationParentNameQuery>;
export type EnrollmentApplicationParentNameLazyQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationParentNameLazyQuery
>;
export type EnrollmentApplicationParentNameQueryResult = Apollo.QueryResult<
	EnrollmentApplicationParentNameQuery,
	EnrollmentApplicationParentNameQueryVariables
>;
export const EnrollmentApplicationParentNamesDocument = gql`
	query enrollmentApplicationParentNames(
		$filters: [EnrollmentApplicationParentFilterInput!]
		$pagination: Pagination
		$order: EnrollmentApplicationParentOrder
	) {
		enrollmentApplicationParentConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...EnrollmentApplicationParentName
				}
			}
		}
	}
	${EnrollmentApplicationParentNameFragmentDoc}
`;

/**
 * __useEnrollmentApplicationParentNamesQuery__
 *
 * To run a query within a React component, call `useEnrollmentApplicationParentNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentApplicationParentNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollmentApplicationParentNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useEnrollmentApplicationParentNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<
		EnrollmentApplicationParentNamesQuery,
		EnrollmentApplicationParentNamesQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<EnrollmentApplicationParentNamesQuery, EnrollmentApplicationParentNamesQueryVariables>(
		EnrollmentApplicationParentNamesDocument,
		options,
	);
}
export function useEnrollmentApplicationParentNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		EnrollmentApplicationParentNamesQuery,
		EnrollmentApplicationParentNamesQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<EnrollmentApplicationParentNamesQuery, EnrollmentApplicationParentNamesQueryVariables>(
		EnrollmentApplicationParentNamesDocument,
		options,
	);
}
export type EnrollmentApplicationParentNamesQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationParentNamesQuery
>;
export type EnrollmentApplicationParentNamesLazyQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationParentNamesLazyQuery
>;
export type EnrollmentApplicationParentNamesQueryResult = Apollo.QueryResult<
	EnrollmentApplicationParentNamesQuery,
	EnrollmentApplicationParentNamesQueryVariables
>;
export const EnrollmentApplicationParentPaginationDocument = gql`
	query enrollmentApplicationParentPagination(
		$filters: [EnrollmentApplicationParentFilterInput!]
		$order: EnrollmentApplicationParentOrder
	) {
		enrollmentApplicationParentConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useEnrollmentApplicationParentPaginationQuery__
 *
 * To run a query within a React component, call `useEnrollmentApplicationParentPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentApplicationParentPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollmentApplicationParentPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useEnrollmentApplicationParentPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<
		EnrollmentApplicationParentPaginationQuery,
		EnrollmentApplicationParentPaginationQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		EnrollmentApplicationParentPaginationQuery,
		EnrollmentApplicationParentPaginationQueryVariables
	>(EnrollmentApplicationParentPaginationDocument, options);
}
export function useEnrollmentApplicationParentPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		EnrollmentApplicationParentPaginationQuery,
		EnrollmentApplicationParentPaginationQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		EnrollmentApplicationParentPaginationQuery,
		EnrollmentApplicationParentPaginationQueryVariables
	>(EnrollmentApplicationParentPaginationDocument, options);
}
export type EnrollmentApplicationParentPaginationQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationParentPaginationQuery
>;
export type EnrollmentApplicationParentPaginationLazyQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationParentPaginationLazyQuery
>;
export type EnrollmentApplicationParentPaginationQueryResult = Apollo.QueryResult<
	EnrollmentApplicationParentPaginationQuery,
	EnrollmentApplicationParentPaginationQueryVariables
>;
export const EnrollmentApplicationParentSelectDocument = gql`
	query enrollmentApplicationParentSelect(
		$filters: [EnrollmentApplicationParentFilterInput!]
		$order: EnrollmentApplicationParentOrder
	) {
		enrollmentApplicationParentConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...EnrollmentApplicationParentName
				}
			}
		}
	}
	${EnrollmentApplicationParentNameFragmentDoc}
`;

/**
 * __useEnrollmentApplicationParentSelectQuery__
 *
 * To run a query within a React component, call `useEnrollmentApplicationParentSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentApplicationParentSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollmentApplicationParentSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useEnrollmentApplicationParentSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<
		EnrollmentApplicationParentSelectQuery,
		EnrollmentApplicationParentSelectQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<EnrollmentApplicationParentSelectQuery, EnrollmentApplicationParentSelectQueryVariables>(
		EnrollmentApplicationParentSelectDocument,
		options,
	);
}
export function useEnrollmentApplicationParentSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		EnrollmentApplicationParentSelectQuery,
		EnrollmentApplicationParentSelectQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<EnrollmentApplicationParentSelectQuery, EnrollmentApplicationParentSelectQueryVariables>(
		EnrollmentApplicationParentSelectDocument,
		options,
	);
}
export type EnrollmentApplicationParentSelectQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationParentSelectQuery
>;
export type EnrollmentApplicationParentSelectLazyQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationParentSelectLazyQuery
>;
export type EnrollmentApplicationParentSelectQueryResult = Apollo.QueryResult<
	EnrollmentApplicationParentSelectQuery,
	EnrollmentApplicationParentSelectQueryVariables
>;
export const EnrollmentApplicationParentUpdateDocument = gql`
	mutation enrollmentApplicationParentUpdate($id: ID!, $input: EnrollmentApplicationParentUpdateInput!) {
		updateEnrollmentApplicationParent(id: $id, input: $input) {
			...EnrollmentApplicationParentDetail
		}
	}
	${EnrollmentApplicationParentDetailFragmentDoc}
`;
export type EnrollmentApplicationParentUpdateMutationFn = Apollo.MutationFunction<
	EnrollmentApplicationParentUpdateMutation,
	EnrollmentApplicationParentUpdateMutationVariables
>;

/**
 * __useEnrollmentApplicationParentUpdateMutation__
 *
 * To run a mutation, you first call `useEnrollmentApplicationParentUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentApplicationParentUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enrollmentApplicationParentUpdateMutation, { data, loading, error }] = useEnrollmentApplicationParentUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEnrollmentApplicationParentUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<
		EnrollmentApplicationParentUpdateMutation,
		EnrollmentApplicationParentUpdateMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		EnrollmentApplicationParentUpdateMutation,
		EnrollmentApplicationParentUpdateMutationVariables
	>(EnrollmentApplicationParentUpdateDocument, options);
}
export type EnrollmentApplicationParentUpdateMutationHookResult = ReturnType<
	typeof useEnrollmentApplicationParentUpdateMutation
>;
export type EnrollmentApplicationParentUpdateMutationResult =
	Apollo.MutationResult<EnrollmentApplicationParentUpdateMutation>;
export type EnrollmentApplicationParentUpdateMutationOptions = Apollo.BaseMutationOptions<
	EnrollmentApplicationParentUpdateMutation,
	EnrollmentApplicationParentUpdateMutationVariables
>;
export const EnrollmentApplicationStudentCountDocument = gql`
	query enrollmentApplicationStudentCount(
		$filters: [EnrollmentApplicationStudentFilterInput!]
		$pagination: Pagination
		$order: EnrollmentApplicationStudentOrder
	) {
		enrollmentApplicationStudentConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useEnrollmentApplicationStudentCountQuery__
 *
 * To run a query within a React component, call `useEnrollmentApplicationStudentCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentApplicationStudentCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollmentApplicationStudentCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useEnrollmentApplicationStudentCountQuery(
	baseOptions?: Apollo.QueryHookOptions<
		EnrollmentApplicationStudentCountQuery,
		EnrollmentApplicationStudentCountQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<EnrollmentApplicationStudentCountQuery, EnrollmentApplicationStudentCountQueryVariables>(
		EnrollmentApplicationStudentCountDocument,
		options,
	);
}
export function useEnrollmentApplicationStudentCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		EnrollmentApplicationStudentCountQuery,
		EnrollmentApplicationStudentCountQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<EnrollmentApplicationStudentCountQuery, EnrollmentApplicationStudentCountQueryVariables>(
		EnrollmentApplicationStudentCountDocument,
		options,
	);
}
export type EnrollmentApplicationStudentCountQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationStudentCountQuery
>;
export type EnrollmentApplicationStudentCountLazyQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationStudentCountLazyQuery
>;
export type EnrollmentApplicationStudentCountQueryResult = Apollo.QueryResult<
	EnrollmentApplicationStudentCountQuery,
	EnrollmentApplicationStudentCountQueryVariables
>;
export const EnrollmentApplicationStudentCreateDocument = gql`
	mutation enrollmentApplicationStudentCreate($input: EnrollmentApplicationStudentCreateInput!) {
		createEnrollmentApplicationStudent(input: $input) {
			...EnrollmentApplicationStudentDetail
		}
	}
	${EnrollmentApplicationStudentDetailFragmentDoc}
`;
export type EnrollmentApplicationStudentCreateMutationFn = Apollo.MutationFunction<
	EnrollmentApplicationStudentCreateMutation,
	EnrollmentApplicationStudentCreateMutationVariables
>;

/**
 * __useEnrollmentApplicationStudentCreateMutation__
 *
 * To run a mutation, you first call `useEnrollmentApplicationStudentCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentApplicationStudentCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enrollmentApplicationStudentCreateMutation, { data, loading, error }] = useEnrollmentApplicationStudentCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEnrollmentApplicationStudentCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<
		EnrollmentApplicationStudentCreateMutation,
		EnrollmentApplicationStudentCreateMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		EnrollmentApplicationStudentCreateMutation,
		EnrollmentApplicationStudentCreateMutationVariables
	>(EnrollmentApplicationStudentCreateDocument, options);
}
export type EnrollmentApplicationStudentCreateMutationHookResult = ReturnType<
	typeof useEnrollmentApplicationStudentCreateMutation
>;
export type EnrollmentApplicationStudentCreateMutationResult =
	Apollo.MutationResult<EnrollmentApplicationStudentCreateMutation>;
export type EnrollmentApplicationStudentCreateMutationOptions = Apollo.BaseMutationOptions<
	EnrollmentApplicationStudentCreateMutation,
	EnrollmentApplicationStudentCreateMutationVariables
>;
export const EnrollmentApplicationStudentDeleteDocument = gql`
	mutation enrollmentApplicationStudentDelete($id: ID!) {
		deleteEnrollmentApplicationStudent(id: $id)
	}
`;
export type EnrollmentApplicationStudentDeleteMutationFn = Apollo.MutationFunction<
	EnrollmentApplicationStudentDeleteMutation,
	EnrollmentApplicationStudentDeleteMutationVariables
>;

/**
 * __useEnrollmentApplicationStudentDeleteMutation__
 *
 * To run a mutation, you first call `useEnrollmentApplicationStudentDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentApplicationStudentDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enrollmentApplicationStudentDeleteMutation, { data, loading, error }] = useEnrollmentApplicationStudentDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEnrollmentApplicationStudentDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<
		EnrollmentApplicationStudentDeleteMutation,
		EnrollmentApplicationStudentDeleteMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		EnrollmentApplicationStudentDeleteMutation,
		EnrollmentApplicationStudentDeleteMutationVariables
	>(EnrollmentApplicationStudentDeleteDocument, options);
}
export type EnrollmentApplicationStudentDeleteMutationHookResult = ReturnType<
	typeof useEnrollmentApplicationStudentDeleteMutation
>;
export type EnrollmentApplicationStudentDeleteMutationResult =
	Apollo.MutationResult<EnrollmentApplicationStudentDeleteMutation>;
export type EnrollmentApplicationStudentDeleteMutationOptions = Apollo.BaseMutationOptions<
	EnrollmentApplicationStudentDeleteMutation,
	EnrollmentApplicationStudentDeleteMutationVariables
>;
export const EnrollmentApplicationStudentDetailDocument = gql`
	query enrollmentApplicationStudentDetail($id: ID!) {
		enrollmentApplicationStudent(id: $id) {
			...EnrollmentApplicationStudentDetail
		}
	}
	${EnrollmentApplicationStudentDetailFragmentDoc}
`;

/**
 * __useEnrollmentApplicationStudentDetailQuery__
 *
 * To run a query within a React component, call `useEnrollmentApplicationStudentDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentApplicationStudentDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollmentApplicationStudentDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEnrollmentApplicationStudentDetailQuery(
	baseOptions: Apollo.QueryHookOptions<
		EnrollmentApplicationStudentDetailQuery,
		EnrollmentApplicationStudentDetailQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<EnrollmentApplicationStudentDetailQuery, EnrollmentApplicationStudentDetailQueryVariables>(
		EnrollmentApplicationStudentDetailDocument,
		options,
	);
}
export function useEnrollmentApplicationStudentDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		EnrollmentApplicationStudentDetailQuery,
		EnrollmentApplicationStudentDetailQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<EnrollmentApplicationStudentDetailQuery, EnrollmentApplicationStudentDetailQueryVariables>(
		EnrollmentApplicationStudentDetailDocument,
		options,
	);
}
export type EnrollmentApplicationStudentDetailQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationStudentDetailQuery
>;
export type EnrollmentApplicationStudentDetailLazyQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationStudentDetailLazyQuery
>;
export type EnrollmentApplicationStudentDetailQueryResult = Apollo.QueryResult<
	EnrollmentApplicationStudentDetailQuery,
	EnrollmentApplicationStudentDetailQueryVariables
>;
export const EnrollmentApplicationStudentIndexDocument = gql`
	query enrollmentApplicationStudentIndex(
		$filters: [EnrollmentApplicationStudentFilterInput!]
		$pagination: Pagination
		$order: EnrollmentApplicationStudentOrder
	) {
		enrollmentApplicationStudentConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					imageURL
					name
					firstName
					lastName
					nickname
					dateOfBirth
					gender
					genderCustom
					nationality
					primaryLanguage
					englishLanguageFluency
					currentGradeLevel
					gradeLevelApplyingFor
					hasPreviousSchooling
					previousSchoolInformation
					previousSchoolLocation
					hasLearningDifficulties
					learningDifficultiesDescription
					hasMedicalPhysicalExceptionalities
					medicalPhysicalExceptionalitiesDescription
					hasBehaviorDisciplineChallenges
					behaviorDisciplineChallengesDescription
					additionalInformation
				}
			}
		}
	}
`;

/**
 * __useEnrollmentApplicationStudentIndexQuery__
 *
 * To run a query within a React component, call `useEnrollmentApplicationStudentIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentApplicationStudentIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollmentApplicationStudentIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useEnrollmentApplicationStudentIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<
		EnrollmentApplicationStudentIndexQuery,
		EnrollmentApplicationStudentIndexQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<EnrollmentApplicationStudentIndexQuery, EnrollmentApplicationStudentIndexQueryVariables>(
		EnrollmentApplicationStudentIndexDocument,
		options,
	);
}
export function useEnrollmentApplicationStudentIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		EnrollmentApplicationStudentIndexQuery,
		EnrollmentApplicationStudentIndexQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<EnrollmentApplicationStudentIndexQuery, EnrollmentApplicationStudentIndexQueryVariables>(
		EnrollmentApplicationStudentIndexDocument,
		options,
	);
}
export type EnrollmentApplicationStudentIndexQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationStudentIndexQuery
>;
export type EnrollmentApplicationStudentIndexLazyQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationStudentIndexLazyQuery
>;
export type EnrollmentApplicationStudentIndexQueryResult = Apollo.QueryResult<
	EnrollmentApplicationStudentIndexQuery,
	EnrollmentApplicationStudentIndexQueryVariables
>;
export const EnrollmentApplicationStudentNameDocument = gql`
	query enrollmentApplicationStudentName($id: ID!) {
		enrollmentApplicationStudent(id: $id) {
			id
			...EnrollmentApplicationStudentName
		}
	}
	${EnrollmentApplicationStudentNameFragmentDoc}
`;

/**
 * __useEnrollmentApplicationStudentNameQuery__
 *
 * To run a query within a React component, call `useEnrollmentApplicationStudentNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentApplicationStudentNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollmentApplicationStudentNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEnrollmentApplicationStudentNameQuery(
	baseOptions: Apollo.QueryHookOptions<
		EnrollmentApplicationStudentNameQuery,
		EnrollmentApplicationStudentNameQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<EnrollmentApplicationStudentNameQuery, EnrollmentApplicationStudentNameQueryVariables>(
		EnrollmentApplicationStudentNameDocument,
		options,
	);
}
export function useEnrollmentApplicationStudentNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		EnrollmentApplicationStudentNameQuery,
		EnrollmentApplicationStudentNameQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<EnrollmentApplicationStudentNameQuery, EnrollmentApplicationStudentNameQueryVariables>(
		EnrollmentApplicationStudentNameDocument,
		options,
	);
}
export type EnrollmentApplicationStudentNameQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationStudentNameQuery
>;
export type EnrollmentApplicationStudentNameLazyQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationStudentNameLazyQuery
>;
export type EnrollmentApplicationStudentNameQueryResult = Apollo.QueryResult<
	EnrollmentApplicationStudentNameQuery,
	EnrollmentApplicationStudentNameQueryVariables
>;
export const EnrollmentApplicationStudentNamesDocument = gql`
	query enrollmentApplicationStudentNames(
		$filters: [EnrollmentApplicationStudentFilterInput!]
		$pagination: Pagination
		$order: EnrollmentApplicationStudentOrder
	) {
		enrollmentApplicationStudentConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...EnrollmentApplicationStudentName
				}
			}
		}
	}
	${EnrollmentApplicationStudentNameFragmentDoc}
`;

/**
 * __useEnrollmentApplicationStudentNamesQuery__
 *
 * To run a query within a React component, call `useEnrollmentApplicationStudentNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentApplicationStudentNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollmentApplicationStudentNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useEnrollmentApplicationStudentNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<
		EnrollmentApplicationStudentNamesQuery,
		EnrollmentApplicationStudentNamesQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<EnrollmentApplicationStudentNamesQuery, EnrollmentApplicationStudentNamesQueryVariables>(
		EnrollmentApplicationStudentNamesDocument,
		options,
	);
}
export function useEnrollmentApplicationStudentNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		EnrollmentApplicationStudentNamesQuery,
		EnrollmentApplicationStudentNamesQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<EnrollmentApplicationStudentNamesQuery, EnrollmentApplicationStudentNamesQueryVariables>(
		EnrollmentApplicationStudentNamesDocument,
		options,
	);
}
export type EnrollmentApplicationStudentNamesQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationStudentNamesQuery
>;
export type EnrollmentApplicationStudentNamesLazyQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationStudentNamesLazyQuery
>;
export type EnrollmentApplicationStudentNamesQueryResult = Apollo.QueryResult<
	EnrollmentApplicationStudentNamesQuery,
	EnrollmentApplicationStudentNamesQueryVariables
>;
export const EnrollmentApplicationStudentPaginationDocument = gql`
	query enrollmentApplicationStudentPagination(
		$filters: [EnrollmentApplicationStudentFilterInput!]
		$order: EnrollmentApplicationStudentOrder
	) {
		enrollmentApplicationStudentConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useEnrollmentApplicationStudentPaginationQuery__
 *
 * To run a query within a React component, call `useEnrollmentApplicationStudentPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentApplicationStudentPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollmentApplicationStudentPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useEnrollmentApplicationStudentPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<
		EnrollmentApplicationStudentPaginationQuery,
		EnrollmentApplicationStudentPaginationQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		EnrollmentApplicationStudentPaginationQuery,
		EnrollmentApplicationStudentPaginationQueryVariables
	>(EnrollmentApplicationStudentPaginationDocument, options);
}
export function useEnrollmentApplicationStudentPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		EnrollmentApplicationStudentPaginationQuery,
		EnrollmentApplicationStudentPaginationQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		EnrollmentApplicationStudentPaginationQuery,
		EnrollmentApplicationStudentPaginationQueryVariables
	>(EnrollmentApplicationStudentPaginationDocument, options);
}
export type EnrollmentApplicationStudentPaginationQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationStudentPaginationQuery
>;
export type EnrollmentApplicationStudentPaginationLazyQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationStudentPaginationLazyQuery
>;
export type EnrollmentApplicationStudentPaginationQueryResult = Apollo.QueryResult<
	EnrollmentApplicationStudentPaginationQuery,
	EnrollmentApplicationStudentPaginationQueryVariables
>;
export const EnrollmentApplicationStudentSelectDocument = gql`
	query enrollmentApplicationStudentSelect(
		$filters: [EnrollmentApplicationStudentFilterInput!]
		$order: EnrollmentApplicationStudentOrder
	) {
		enrollmentApplicationStudentConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...EnrollmentApplicationStudentName
				}
			}
		}
	}
	${EnrollmentApplicationStudentNameFragmentDoc}
`;

/**
 * __useEnrollmentApplicationStudentSelectQuery__
 *
 * To run a query within a React component, call `useEnrollmentApplicationStudentSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentApplicationStudentSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollmentApplicationStudentSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useEnrollmentApplicationStudentSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<
		EnrollmentApplicationStudentSelectQuery,
		EnrollmentApplicationStudentSelectQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<EnrollmentApplicationStudentSelectQuery, EnrollmentApplicationStudentSelectQueryVariables>(
		EnrollmentApplicationStudentSelectDocument,
		options,
	);
}
export function useEnrollmentApplicationStudentSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		EnrollmentApplicationStudentSelectQuery,
		EnrollmentApplicationStudentSelectQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<EnrollmentApplicationStudentSelectQuery, EnrollmentApplicationStudentSelectQueryVariables>(
		EnrollmentApplicationStudentSelectDocument,
		options,
	);
}
export type EnrollmentApplicationStudentSelectQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationStudentSelectQuery
>;
export type EnrollmentApplicationStudentSelectLazyQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationStudentSelectLazyQuery
>;
export type EnrollmentApplicationStudentSelectQueryResult = Apollo.QueryResult<
	EnrollmentApplicationStudentSelectQuery,
	EnrollmentApplicationStudentSelectQueryVariables
>;
export const EnrollmentApplicationStudentUpdateDocument = gql`
	mutation enrollmentApplicationStudentUpdate($id: ID!, $input: EnrollmentApplicationStudentUpdateInput!) {
		updateEnrollmentApplicationStudent(id: $id, input: $input) {
			...EnrollmentApplicationStudentDetail
		}
	}
	${EnrollmentApplicationStudentDetailFragmentDoc}
`;
export type EnrollmentApplicationStudentUpdateMutationFn = Apollo.MutationFunction<
	EnrollmentApplicationStudentUpdateMutation,
	EnrollmentApplicationStudentUpdateMutationVariables
>;

/**
 * __useEnrollmentApplicationStudentUpdateMutation__
 *
 * To run a mutation, you first call `useEnrollmentApplicationStudentUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentApplicationStudentUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enrollmentApplicationStudentUpdateMutation, { data, loading, error }] = useEnrollmentApplicationStudentUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEnrollmentApplicationStudentUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<
		EnrollmentApplicationStudentUpdateMutation,
		EnrollmentApplicationStudentUpdateMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		EnrollmentApplicationStudentUpdateMutation,
		EnrollmentApplicationStudentUpdateMutationVariables
	>(EnrollmentApplicationStudentUpdateDocument, options);
}
export type EnrollmentApplicationStudentUpdateMutationHookResult = ReturnType<
	typeof useEnrollmentApplicationStudentUpdateMutation
>;
export type EnrollmentApplicationStudentUpdateMutationResult =
	Apollo.MutationResult<EnrollmentApplicationStudentUpdateMutation>;
export type EnrollmentApplicationStudentUpdateMutationOptions = Apollo.BaseMutationOptions<
	EnrollmentApplicationStudentUpdateMutation,
	EnrollmentApplicationStudentUpdateMutationVariables
>;
export const EnrollmentApplicationSubmissionCountDocument = gql`
	query enrollmentApplicationSubmissionCount(
		$filters: [EnrollmentApplicationSubmissionFilterInput!]
		$pagination: Pagination
		$order: EnrollmentApplicationSubmissionOrder
	) {
		enrollmentApplicationSubmissionConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useEnrollmentApplicationSubmissionCountQuery__
 *
 * To run a query within a React component, call `useEnrollmentApplicationSubmissionCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentApplicationSubmissionCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollmentApplicationSubmissionCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useEnrollmentApplicationSubmissionCountQuery(
	baseOptions?: Apollo.QueryHookOptions<
		EnrollmentApplicationSubmissionCountQuery,
		EnrollmentApplicationSubmissionCountQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<EnrollmentApplicationSubmissionCountQuery, EnrollmentApplicationSubmissionCountQueryVariables>(
		EnrollmentApplicationSubmissionCountDocument,
		options,
	);
}
export function useEnrollmentApplicationSubmissionCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		EnrollmentApplicationSubmissionCountQuery,
		EnrollmentApplicationSubmissionCountQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		EnrollmentApplicationSubmissionCountQuery,
		EnrollmentApplicationSubmissionCountQueryVariables
	>(EnrollmentApplicationSubmissionCountDocument, options);
}
export type EnrollmentApplicationSubmissionCountQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationSubmissionCountQuery
>;
export type EnrollmentApplicationSubmissionCountLazyQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationSubmissionCountLazyQuery
>;
export type EnrollmentApplicationSubmissionCountQueryResult = Apollo.QueryResult<
	EnrollmentApplicationSubmissionCountQuery,
	EnrollmentApplicationSubmissionCountQueryVariables
>;
export const EnrollmentApplicationSubmissionDetailDocument = gql`
	query enrollmentApplicationSubmissionDetail($id: ID!) {
		enrollmentApplicationSubmission(id: $id) {
			...EnrollmentApplicationSubmissionDetail
		}
	}
	${EnrollmentApplicationSubmissionDetailFragmentDoc}
`;

/**
 * __useEnrollmentApplicationSubmissionDetailQuery__
 *
 * To run a query within a React component, call `useEnrollmentApplicationSubmissionDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentApplicationSubmissionDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollmentApplicationSubmissionDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEnrollmentApplicationSubmissionDetailQuery(
	baseOptions: Apollo.QueryHookOptions<
		EnrollmentApplicationSubmissionDetailQuery,
		EnrollmentApplicationSubmissionDetailQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		EnrollmentApplicationSubmissionDetailQuery,
		EnrollmentApplicationSubmissionDetailQueryVariables
	>(EnrollmentApplicationSubmissionDetailDocument, options);
}
export function useEnrollmentApplicationSubmissionDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		EnrollmentApplicationSubmissionDetailQuery,
		EnrollmentApplicationSubmissionDetailQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		EnrollmentApplicationSubmissionDetailQuery,
		EnrollmentApplicationSubmissionDetailQueryVariables
	>(EnrollmentApplicationSubmissionDetailDocument, options);
}
export type EnrollmentApplicationSubmissionDetailQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationSubmissionDetailQuery
>;
export type EnrollmentApplicationSubmissionDetailLazyQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationSubmissionDetailLazyQuery
>;
export type EnrollmentApplicationSubmissionDetailQueryResult = Apollo.QueryResult<
	EnrollmentApplicationSubmissionDetailQuery,
	EnrollmentApplicationSubmissionDetailQueryVariables
>;
export const EnrollmentApplicationSubmissionIndexDocument = gql`
	query enrollmentApplicationSubmissionIndex(
		$filters: [EnrollmentApplicationSubmissionFilterInput!]
		$pagination: Pagination
		$order: EnrollmentApplicationSubmissionOrder
	) {
		enrollmentApplicationSubmissionConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					createdAt
				}
			}
		}
	}
`;

/**
 * __useEnrollmentApplicationSubmissionIndexQuery__
 *
 * To run a query within a React component, call `useEnrollmentApplicationSubmissionIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentApplicationSubmissionIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollmentApplicationSubmissionIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useEnrollmentApplicationSubmissionIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<
		EnrollmentApplicationSubmissionIndexQuery,
		EnrollmentApplicationSubmissionIndexQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<EnrollmentApplicationSubmissionIndexQuery, EnrollmentApplicationSubmissionIndexQueryVariables>(
		EnrollmentApplicationSubmissionIndexDocument,
		options,
	);
}
export function useEnrollmentApplicationSubmissionIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		EnrollmentApplicationSubmissionIndexQuery,
		EnrollmentApplicationSubmissionIndexQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		EnrollmentApplicationSubmissionIndexQuery,
		EnrollmentApplicationSubmissionIndexQueryVariables
	>(EnrollmentApplicationSubmissionIndexDocument, options);
}
export type EnrollmentApplicationSubmissionIndexQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationSubmissionIndexQuery
>;
export type EnrollmentApplicationSubmissionIndexLazyQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationSubmissionIndexLazyQuery
>;
export type EnrollmentApplicationSubmissionIndexQueryResult = Apollo.QueryResult<
	EnrollmentApplicationSubmissionIndexQuery,
	EnrollmentApplicationSubmissionIndexQueryVariables
>;
export const EnrollmentApplicationSubmissionNameDocument = gql`
	query enrollmentApplicationSubmissionName($id: ID!) {
		enrollmentApplicationSubmission(id: $id) {
			id
			...EnrollmentApplicationSubmissionName
		}
	}
	${EnrollmentApplicationSubmissionNameFragmentDoc}
`;

/**
 * __useEnrollmentApplicationSubmissionNameQuery__
 *
 * To run a query within a React component, call `useEnrollmentApplicationSubmissionNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentApplicationSubmissionNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollmentApplicationSubmissionNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEnrollmentApplicationSubmissionNameQuery(
	baseOptions: Apollo.QueryHookOptions<
		EnrollmentApplicationSubmissionNameQuery,
		EnrollmentApplicationSubmissionNameQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<EnrollmentApplicationSubmissionNameQuery, EnrollmentApplicationSubmissionNameQueryVariables>(
		EnrollmentApplicationSubmissionNameDocument,
		options,
	);
}
export function useEnrollmentApplicationSubmissionNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		EnrollmentApplicationSubmissionNameQuery,
		EnrollmentApplicationSubmissionNameQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		EnrollmentApplicationSubmissionNameQuery,
		EnrollmentApplicationSubmissionNameQueryVariables
	>(EnrollmentApplicationSubmissionNameDocument, options);
}
export type EnrollmentApplicationSubmissionNameQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationSubmissionNameQuery
>;
export type EnrollmentApplicationSubmissionNameLazyQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationSubmissionNameLazyQuery
>;
export type EnrollmentApplicationSubmissionNameQueryResult = Apollo.QueryResult<
	EnrollmentApplicationSubmissionNameQuery,
	EnrollmentApplicationSubmissionNameQueryVariables
>;
export const EnrollmentApplicationSubmissionNamesDocument = gql`
	query enrollmentApplicationSubmissionNames(
		$filters: [EnrollmentApplicationSubmissionFilterInput!]
		$pagination: Pagination
		$order: EnrollmentApplicationSubmissionOrder
	) {
		enrollmentApplicationSubmissionConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...EnrollmentApplicationSubmissionName
				}
			}
		}
	}
	${EnrollmentApplicationSubmissionNameFragmentDoc}
`;

/**
 * __useEnrollmentApplicationSubmissionNamesQuery__
 *
 * To run a query within a React component, call `useEnrollmentApplicationSubmissionNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentApplicationSubmissionNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollmentApplicationSubmissionNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useEnrollmentApplicationSubmissionNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<
		EnrollmentApplicationSubmissionNamesQuery,
		EnrollmentApplicationSubmissionNamesQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<EnrollmentApplicationSubmissionNamesQuery, EnrollmentApplicationSubmissionNamesQueryVariables>(
		EnrollmentApplicationSubmissionNamesDocument,
		options,
	);
}
export function useEnrollmentApplicationSubmissionNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		EnrollmentApplicationSubmissionNamesQuery,
		EnrollmentApplicationSubmissionNamesQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		EnrollmentApplicationSubmissionNamesQuery,
		EnrollmentApplicationSubmissionNamesQueryVariables
	>(EnrollmentApplicationSubmissionNamesDocument, options);
}
export type EnrollmentApplicationSubmissionNamesQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationSubmissionNamesQuery
>;
export type EnrollmentApplicationSubmissionNamesLazyQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationSubmissionNamesLazyQuery
>;
export type EnrollmentApplicationSubmissionNamesQueryResult = Apollo.QueryResult<
	EnrollmentApplicationSubmissionNamesQuery,
	EnrollmentApplicationSubmissionNamesQueryVariables
>;
export const EnrollmentApplicationSubmissionPaginationDocument = gql`
	query enrollmentApplicationSubmissionPagination(
		$filters: [EnrollmentApplicationSubmissionFilterInput!]
		$order: EnrollmentApplicationSubmissionOrder
	) {
		enrollmentApplicationSubmissionConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useEnrollmentApplicationSubmissionPaginationQuery__
 *
 * To run a query within a React component, call `useEnrollmentApplicationSubmissionPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentApplicationSubmissionPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollmentApplicationSubmissionPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useEnrollmentApplicationSubmissionPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<
		EnrollmentApplicationSubmissionPaginationQuery,
		EnrollmentApplicationSubmissionPaginationQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		EnrollmentApplicationSubmissionPaginationQuery,
		EnrollmentApplicationSubmissionPaginationQueryVariables
	>(EnrollmentApplicationSubmissionPaginationDocument, options);
}
export function useEnrollmentApplicationSubmissionPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		EnrollmentApplicationSubmissionPaginationQuery,
		EnrollmentApplicationSubmissionPaginationQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		EnrollmentApplicationSubmissionPaginationQuery,
		EnrollmentApplicationSubmissionPaginationQueryVariables
	>(EnrollmentApplicationSubmissionPaginationDocument, options);
}
export type EnrollmentApplicationSubmissionPaginationQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationSubmissionPaginationQuery
>;
export type EnrollmentApplicationSubmissionPaginationLazyQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationSubmissionPaginationLazyQuery
>;
export type EnrollmentApplicationSubmissionPaginationQueryResult = Apollo.QueryResult<
	EnrollmentApplicationSubmissionPaginationQuery,
	EnrollmentApplicationSubmissionPaginationQueryVariables
>;
export const EnrollmentApplicationSubmissionSelectDocument = gql`
	query enrollmentApplicationSubmissionSelect(
		$filters: [EnrollmentApplicationSubmissionFilterInput!]
		$order: EnrollmentApplicationSubmissionOrder
	) {
		enrollmentApplicationSubmissionConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...EnrollmentApplicationSubmissionName
				}
			}
		}
	}
	${EnrollmentApplicationSubmissionNameFragmentDoc}
`;

/**
 * __useEnrollmentApplicationSubmissionSelectQuery__
 *
 * To run a query within a React component, call `useEnrollmentApplicationSubmissionSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentApplicationSubmissionSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollmentApplicationSubmissionSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useEnrollmentApplicationSubmissionSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<
		EnrollmentApplicationSubmissionSelectQuery,
		EnrollmentApplicationSubmissionSelectQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		EnrollmentApplicationSubmissionSelectQuery,
		EnrollmentApplicationSubmissionSelectQueryVariables
	>(EnrollmentApplicationSubmissionSelectDocument, options);
}
export function useEnrollmentApplicationSubmissionSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		EnrollmentApplicationSubmissionSelectQuery,
		EnrollmentApplicationSubmissionSelectQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		EnrollmentApplicationSubmissionSelectQuery,
		EnrollmentApplicationSubmissionSelectQueryVariables
	>(EnrollmentApplicationSubmissionSelectDocument, options);
}
export type EnrollmentApplicationSubmissionSelectQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationSubmissionSelectQuery
>;
export type EnrollmentApplicationSubmissionSelectLazyQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationSubmissionSelectLazyQuery
>;
export type EnrollmentApplicationSubmissionSelectQueryResult = Apollo.QueryResult<
	EnrollmentApplicationSubmissionSelectQuery,
	EnrollmentApplicationSubmissionSelectQueryVariables
>;
export const EnrollmentApplicationSubmissionUpdateDocument = gql`
	mutation enrollmentApplicationSubmissionUpdate($id: ID!, $input: EnrollmentApplicationSubmissionUpdateInput!) {
		updateEnrollmentApplicationSubmission(id: $id, input: $input) {
			...EnrollmentApplicationSubmissionDetail
		}
	}
	${EnrollmentApplicationSubmissionDetailFragmentDoc}
`;
export type EnrollmentApplicationSubmissionUpdateMutationFn = Apollo.MutationFunction<
	EnrollmentApplicationSubmissionUpdateMutation,
	EnrollmentApplicationSubmissionUpdateMutationVariables
>;

/**
 * __useEnrollmentApplicationSubmissionUpdateMutation__
 *
 * To run a mutation, you first call `useEnrollmentApplicationSubmissionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentApplicationSubmissionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enrollmentApplicationSubmissionUpdateMutation, { data, loading, error }] = useEnrollmentApplicationSubmissionUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEnrollmentApplicationSubmissionUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<
		EnrollmentApplicationSubmissionUpdateMutation,
		EnrollmentApplicationSubmissionUpdateMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		EnrollmentApplicationSubmissionUpdateMutation,
		EnrollmentApplicationSubmissionUpdateMutationVariables
	>(EnrollmentApplicationSubmissionUpdateDocument, options);
}
export type EnrollmentApplicationSubmissionUpdateMutationHookResult = ReturnType<
	typeof useEnrollmentApplicationSubmissionUpdateMutation
>;
export type EnrollmentApplicationSubmissionUpdateMutationResult =
	Apollo.MutationResult<EnrollmentApplicationSubmissionUpdateMutation>;
export type EnrollmentApplicationSubmissionUpdateMutationOptions = Apollo.BaseMutationOptions<
	EnrollmentApplicationSubmissionUpdateMutation,
	EnrollmentApplicationSubmissionUpdateMutationVariables
>;
export const EnrollmentApplicationUserViewRecordCountDocument = gql`
	query enrollmentApplicationUserViewRecordCount(
		$filters: [EnrollmentApplicationUserViewRecordFilterInput!]
		$pagination: Pagination
		$order: EnrollmentApplicationUserViewRecordOrder
	) {
		enrollmentApplicationUserViewRecordConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useEnrollmentApplicationUserViewRecordCountQuery__
 *
 * To run a query within a React component, call `useEnrollmentApplicationUserViewRecordCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentApplicationUserViewRecordCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollmentApplicationUserViewRecordCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useEnrollmentApplicationUserViewRecordCountQuery(
	baseOptions?: Apollo.QueryHookOptions<
		EnrollmentApplicationUserViewRecordCountQuery,
		EnrollmentApplicationUserViewRecordCountQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		EnrollmentApplicationUserViewRecordCountQuery,
		EnrollmentApplicationUserViewRecordCountQueryVariables
	>(EnrollmentApplicationUserViewRecordCountDocument, options);
}
export function useEnrollmentApplicationUserViewRecordCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		EnrollmentApplicationUserViewRecordCountQuery,
		EnrollmentApplicationUserViewRecordCountQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		EnrollmentApplicationUserViewRecordCountQuery,
		EnrollmentApplicationUserViewRecordCountQueryVariables
	>(EnrollmentApplicationUserViewRecordCountDocument, options);
}
export type EnrollmentApplicationUserViewRecordCountQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationUserViewRecordCountQuery
>;
export type EnrollmentApplicationUserViewRecordCountLazyQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationUserViewRecordCountLazyQuery
>;
export type EnrollmentApplicationUserViewRecordCountQueryResult = Apollo.QueryResult<
	EnrollmentApplicationUserViewRecordCountQuery,
	EnrollmentApplicationUserViewRecordCountQueryVariables
>;
export const EnrollmentApplicationUserViewRecordDetailDocument = gql`
	query enrollmentApplicationUserViewRecordDetail($id: ID!) {
		enrollmentApplicationUserViewRecord(id: $id) {
			...EnrollmentApplicationUserViewRecordDetail
		}
	}
	${EnrollmentApplicationUserViewRecordDetailFragmentDoc}
`;

/**
 * __useEnrollmentApplicationUserViewRecordDetailQuery__
 *
 * To run a query within a React component, call `useEnrollmentApplicationUserViewRecordDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentApplicationUserViewRecordDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollmentApplicationUserViewRecordDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEnrollmentApplicationUserViewRecordDetailQuery(
	baseOptions: Apollo.QueryHookOptions<
		EnrollmentApplicationUserViewRecordDetailQuery,
		EnrollmentApplicationUserViewRecordDetailQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		EnrollmentApplicationUserViewRecordDetailQuery,
		EnrollmentApplicationUserViewRecordDetailQueryVariables
	>(EnrollmentApplicationUserViewRecordDetailDocument, options);
}
export function useEnrollmentApplicationUserViewRecordDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		EnrollmentApplicationUserViewRecordDetailQuery,
		EnrollmentApplicationUserViewRecordDetailQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		EnrollmentApplicationUserViewRecordDetailQuery,
		EnrollmentApplicationUserViewRecordDetailQueryVariables
	>(EnrollmentApplicationUserViewRecordDetailDocument, options);
}
export type EnrollmentApplicationUserViewRecordDetailQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationUserViewRecordDetailQuery
>;
export type EnrollmentApplicationUserViewRecordDetailLazyQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationUserViewRecordDetailLazyQuery
>;
export type EnrollmentApplicationUserViewRecordDetailQueryResult = Apollo.QueryResult<
	EnrollmentApplicationUserViewRecordDetailQuery,
	EnrollmentApplicationUserViewRecordDetailQueryVariables
>;
export const EnrollmentApplicationUserViewRecordIndexDocument = gql`
	query enrollmentApplicationUserViewRecordIndex(
		$filters: [EnrollmentApplicationUserViewRecordFilterInput!]
		$pagination: Pagination
		$order: EnrollmentApplicationUserViewRecordOrder
	) {
		enrollmentApplicationUserViewRecordConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					createdAt
					isDetail
				}
			}
		}
	}
`;

/**
 * __useEnrollmentApplicationUserViewRecordIndexQuery__
 *
 * To run a query within a React component, call `useEnrollmentApplicationUserViewRecordIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentApplicationUserViewRecordIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollmentApplicationUserViewRecordIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useEnrollmentApplicationUserViewRecordIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<
		EnrollmentApplicationUserViewRecordIndexQuery,
		EnrollmentApplicationUserViewRecordIndexQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		EnrollmentApplicationUserViewRecordIndexQuery,
		EnrollmentApplicationUserViewRecordIndexQueryVariables
	>(EnrollmentApplicationUserViewRecordIndexDocument, options);
}
export function useEnrollmentApplicationUserViewRecordIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		EnrollmentApplicationUserViewRecordIndexQuery,
		EnrollmentApplicationUserViewRecordIndexQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		EnrollmentApplicationUserViewRecordIndexQuery,
		EnrollmentApplicationUserViewRecordIndexQueryVariables
	>(EnrollmentApplicationUserViewRecordIndexDocument, options);
}
export type EnrollmentApplicationUserViewRecordIndexQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationUserViewRecordIndexQuery
>;
export type EnrollmentApplicationUserViewRecordIndexLazyQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationUserViewRecordIndexLazyQuery
>;
export type EnrollmentApplicationUserViewRecordIndexQueryResult = Apollo.QueryResult<
	EnrollmentApplicationUserViewRecordIndexQuery,
	EnrollmentApplicationUserViewRecordIndexQueryVariables
>;
export const EnrollmentApplicationUserViewRecordNameDocument = gql`
	query enrollmentApplicationUserViewRecordName($id: ID!) {
		enrollmentApplicationUserViewRecord(id: $id) {
			id
			...EnrollmentApplicationUserViewRecordName
		}
	}
	${EnrollmentApplicationUserViewRecordNameFragmentDoc}
`;

/**
 * __useEnrollmentApplicationUserViewRecordNameQuery__
 *
 * To run a query within a React component, call `useEnrollmentApplicationUserViewRecordNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentApplicationUserViewRecordNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollmentApplicationUserViewRecordNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEnrollmentApplicationUserViewRecordNameQuery(
	baseOptions: Apollo.QueryHookOptions<
		EnrollmentApplicationUserViewRecordNameQuery,
		EnrollmentApplicationUserViewRecordNameQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		EnrollmentApplicationUserViewRecordNameQuery,
		EnrollmentApplicationUserViewRecordNameQueryVariables
	>(EnrollmentApplicationUserViewRecordNameDocument, options);
}
export function useEnrollmentApplicationUserViewRecordNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		EnrollmentApplicationUserViewRecordNameQuery,
		EnrollmentApplicationUserViewRecordNameQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		EnrollmentApplicationUserViewRecordNameQuery,
		EnrollmentApplicationUserViewRecordNameQueryVariables
	>(EnrollmentApplicationUserViewRecordNameDocument, options);
}
export type EnrollmentApplicationUserViewRecordNameQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationUserViewRecordNameQuery
>;
export type EnrollmentApplicationUserViewRecordNameLazyQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationUserViewRecordNameLazyQuery
>;
export type EnrollmentApplicationUserViewRecordNameQueryResult = Apollo.QueryResult<
	EnrollmentApplicationUserViewRecordNameQuery,
	EnrollmentApplicationUserViewRecordNameQueryVariables
>;
export const EnrollmentApplicationUserViewRecordNamesDocument = gql`
	query enrollmentApplicationUserViewRecordNames(
		$filters: [EnrollmentApplicationUserViewRecordFilterInput!]
		$pagination: Pagination
		$order: EnrollmentApplicationUserViewRecordOrder
	) {
		enrollmentApplicationUserViewRecordConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...EnrollmentApplicationUserViewRecordName
				}
			}
		}
	}
	${EnrollmentApplicationUserViewRecordNameFragmentDoc}
`;

/**
 * __useEnrollmentApplicationUserViewRecordNamesQuery__
 *
 * To run a query within a React component, call `useEnrollmentApplicationUserViewRecordNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentApplicationUserViewRecordNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollmentApplicationUserViewRecordNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useEnrollmentApplicationUserViewRecordNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<
		EnrollmentApplicationUserViewRecordNamesQuery,
		EnrollmentApplicationUserViewRecordNamesQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		EnrollmentApplicationUserViewRecordNamesQuery,
		EnrollmentApplicationUserViewRecordNamesQueryVariables
	>(EnrollmentApplicationUserViewRecordNamesDocument, options);
}
export function useEnrollmentApplicationUserViewRecordNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		EnrollmentApplicationUserViewRecordNamesQuery,
		EnrollmentApplicationUserViewRecordNamesQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		EnrollmentApplicationUserViewRecordNamesQuery,
		EnrollmentApplicationUserViewRecordNamesQueryVariables
	>(EnrollmentApplicationUserViewRecordNamesDocument, options);
}
export type EnrollmentApplicationUserViewRecordNamesQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationUserViewRecordNamesQuery
>;
export type EnrollmentApplicationUserViewRecordNamesLazyQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationUserViewRecordNamesLazyQuery
>;
export type EnrollmentApplicationUserViewRecordNamesQueryResult = Apollo.QueryResult<
	EnrollmentApplicationUserViewRecordNamesQuery,
	EnrollmentApplicationUserViewRecordNamesQueryVariables
>;
export const EnrollmentApplicationUserViewRecordPaginationDocument = gql`
	query enrollmentApplicationUserViewRecordPagination(
		$filters: [EnrollmentApplicationUserViewRecordFilterInput!]
		$order: EnrollmentApplicationUserViewRecordOrder
	) {
		enrollmentApplicationUserViewRecordConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useEnrollmentApplicationUserViewRecordPaginationQuery__
 *
 * To run a query within a React component, call `useEnrollmentApplicationUserViewRecordPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentApplicationUserViewRecordPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollmentApplicationUserViewRecordPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useEnrollmentApplicationUserViewRecordPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<
		EnrollmentApplicationUserViewRecordPaginationQuery,
		EnrollmentApplicationUserViewRecordPaginationQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		EnrollmentApplicationUserViewRecordPaginationQuery,
		EnrollmentApplicationUserViewRecordPaginationQueryVariables
	>(EnrollmentApplicationUserViewRecordPaginationDocument, options);
}
export function useEnrollmentApplicationUserViewRecordPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		EnrollmentApplicationUserViewRecordPaginationQuery,
		EnrollmentApplicationUserViewRecordPaginationQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		EnrollmentApplicationUserViewRecordPaginationQuery,
		EnrollmentApplicationUserViewRecordPaginationQueryVariables
	>(EnrollmentApplicationUserViewRecordPaginationDocument, options);
}
export type EnrollmentApplicationUserViewRecordPaginationQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationUserViewRecordPaginationQuery
>;
export type EnrollmentApplicationUserViewRecordPaginationLazyQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationUserViewRecordPaginationLazyQuery
>;
export type EnrollmentApplicationUserViewRecordPaginationQueryResult = Apollo.QueryResult<
	EnrollmentApplicationUserViewRecordPaginationQuery,
	EnrollmentApplicationUserViewRecordPaginationQueryVariables
>;
export const RecordEnrollmentApplicationListViewDocument = gql`
	mutation recordEnrollmentApplicationListView($ids: [ID!]!) {
		recordEnrollmentApplicationListView(ids: $ids)
	}
`;
export type RecordEnrollmentApplicationListViewMutationFn = Apollo.MutationFunction<
	RecordEnrollmentApplicationListViewMutation,
	RecordEnrollmentApplicationListViewMutationVariables
>;

/**
 * __useRecordEnrollmentApplicationListViewMutation__
 *
 * To run a mutation, you first call `useRecordEnrollmentApplicationListViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecordEnrollmentApplicationListViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recordEnrollmentApplicationListViewMutation, { data, loading, error }] = useRecordEnrollmentApplicationListViewMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useRecordEnrollmentApplicationListViewMutation(
	baseOptions?: Apollo.MutationHookOptions<
		RecordEnrollmentApplicationListViewMutation,
		RecordEnrollmentApplicationListViewMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		RecordEnrollmentApplicationListViewMutation,
		RecordEnrollmentApplicationListViewMutationVariables
	>(RecordEnrollmentApplicationListViewDocument, options);
}
export type RecordEnrollmentApplicationListViewMutationHookResult = ReturnType<
	typeof useRecordEnrollmentApplicationListViewMutation
>;
export type RecordEnrollmentApplicationListViewMutationResult =
	Apollo.MutationResult<RecordEnrollmentApplicationListViewMutation>;
export type RecordEnrollmentApplicationListViewMutationOptions = Apollo.BaseMutationOptions<
	RecordEnrollmentApplicationListViewMutation,
	RecordEnrollmentApplicationListViewMutationVariables
>;
export const EnrollmentApplicationUserViewRecordSelectDocument = gql`
	query enrollmentApplicationUserViewRecordSelect(
		$filters: [EnrollmentApplicationUserViewRecordFilterInput!]
		$order: EnrollmentApplicationUserViewRecordOrder
	) {
		enrollmentApplicationUserViewRecordConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...EnrollmentApplicationUserViewRecordName
				}
			}
		}
	}
	${EnrollmentApplicationUserViewRecordNameFragmentDoc}
`;

/**
 * __useEnrollmentApplicationUserViewRecordSelectQuery__
 *
 * To run a query within a React component, call `useEnrollmentApplicationUserViewRecordSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentApplicationUserViewRecordSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollmentApplicationUserViewRecordSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useEnrollmentApplicationUserViewRecordSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<
		EnrollmentApplicationUserViewRecordSelectQuery,
		EnrollmentApplicationUserViewRecordSelectQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		EnrollmentApplicationUserViewRecordSelectQuery,
		EnrollmentApplicationUserViewRecordSelectQueryVariables
	>(EnrollmentApplicationUserViewRecordSelectDocument, options);
}
export function useEnrollmentApplicationUserViewRecordSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		EnrollmentApplicationUserViewRecordSelectQuery,
		EnrollmentApplicationUserViewRecordSelectQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		EnrollmentApplicationUserViewRecordSelectQuery,
		EnrollmentApplicationUserViewRecordSelectQueryVariables
	>(EnrollmentApplicationUserViewRecordSelectDocument, options);
}
export type EnrollmentApplicationUserViewRecordSelectQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationUserViewRecordSelectQuery
>;
export type EnrollmentApplicationUserViewRecordSelectLazyQueryHookResult = ReturnType<
	typeof useEnrollmentApplicationUserViewRecordSelectLazyQuery
>;
export type EnrollmentApplicationUserViewRecordSelectQueryResult = Apollo.QueryResult<
	EnrollmentApplicationUserViewRecordSelectQuery,
	EnrollmentApplicationUserViewRecordSelectQueryVariables
>;
export const EnrollmentInvitationCountDocument = gql`
	query enrollmentInvitationCount(
		$filters: [EnrollmentInvitationFilterInput!]
		$pagination: Pagination
		$order: EnrollmentInvitationOrder
	) {
		enrollmentInvitationConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useEnrollmentInvitationCountQuery__
 *
 * To run a query within a React component, call `useEnrollmentInvitationCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentInvitationCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollmentInvitationCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useEnrollmentInvitationCountQuery(
	baseOptions?: Apollo.QueryHookOptions<EnrollmentInvitationCountQuery, EnrollmentInvitationCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<EnrollmentInvitationCountQuery, EnrollmentInvitationCountQueryVariables>(
		EnrollmentInvitationCountDocument,
		options,
	);
}
export function useEnrollmentInvitationCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<EnrollmentInvitationCountQuery, EnrollmentInvitationCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<EnrollmentInvitationCountQuery, EnrollmentInvitationCountQueryVariables>(
		EnrollmentInvitationCountDocument,
		options,
	);
}
export type EnrollmentInvitationCountQueryHookResult = ReturnType<typeof useEnrollmentInvitationCountQuery>;
export type EnrollmentInvitationCountLazyQueryHookResult = ReturnType<typeof useEnrollmentInvitationCountLazyQuery>;
export type EnrollmentInvitationCountQueryResult = Apollo.QueryResult<
	EnrollmentInvitationCountQuery,
	EnrollmentInvitationCountQueryVariables
>;
export const EnrollmentInvitationCreateDocument = gql`
	mutation enrollmentInvitationCreate($input: EnrollmentInvitationCreateInput!) {
		createEnrollmentInvitation(input: $input) {
			...enrollmentInvitationDetailFields
		}
	}
	${EnrollmentInvitationDetailFieldsFragmentDoc}
`;
export type EnrollmentInvitationCreateMutationFn = Apollo.MutationFunction<
	EnrollmentInvitationCreateMutation,
	EnrollmentInvitationCreateMutationVariables
>;

/**
 * __useEnrollmentInvitationCreateMutation__
 *
 * To run a mutation, you first call `useEnrollmentInvitationCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentInvitationCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enrollmentInvitationCreateMutation, { data, loading, error }] = useEnrollmentInvitationCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEnrollmentInvitationCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<
		EnrollmentInvitationCreateMutation,
		EnrollmentInvitationCreateMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<EnrollmentInvitationCreateMutation, EnrollmentInvitationCreateMutationVariables>(
		EnrollmentInvitationCreateDocument,
		options,
	);
}
export type EnrollmentInvitationCreateMutationHookResult = ReturnType<typeof useEnrollmentInvitationCreateMutation>;
export type EnrollmentInvitationCreateMutationResult = Apollo.MutationResult<EnrollmentInvitationCreateMutation>;
export type EnrollmentInvitationCreateMutationOptions = Apollo.BaseMutationOptions<
	EnrollmentInvitationCreateMutation,
	EnrollmentInvitationCreateMutationVariables
>;
export const EnrollmentInvitationDeleteDocument = gql`
	mutation enrollmentInvitationDelete($id: ID!) {
		deleteEnrollmentInvitation(id: $id)
	}
`;
export type EnrollmentInvitationDeleteMutationFn = Apollo.MutationFunction<
	EnrollmentInvitationDeleteMutation,
	EnrollmentInvitationDeleteMutationVariables
>;

/**
 * __useEnrollmentInvitationDeleteMutation__
 *
 * To run a mutation, you first call `useEnrollmentInvitationDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentInvitationDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enrollmentInvitationDeleteMutation, { data, loading, error }] = useEnrollmentInvitationDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEnrollmentInvitationDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<
		EnrollmentInvitationDeleteMutation,
		EnrollmentInvitationDeleteMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<EnrollmentInvitationDeleteMutation, EnrollmentInvitationDeleteMutationVariables>(
		EnrollmentInvitationDeleteDocument,
		options,
	);
}
export type EnrollmentInvitationDeleteMutationHookResult = ReturnType<typeof useEnrollmentInvitationDeleteMutation>;
export type EnrollmentInvitationDeleteMutationResult = Apollo.MutationResult<EnrollmentInvitationDeleteMutation>;
export type EnrollmentInvitationDeleteMutationOptions = Apollo.BaseMutationOptions<
	EnrollmentInvitationDeleteMutation,
	EnrollmentInvitationDeleteMutationVariables
>;
export const EnrollmentInvitationDetailDocument = gql`
	query enrollmentInvitationDetail($id: ID!) {
		enrollmentInvitation(id: $id) {
			...enrollmentInvitationDetailFields
		}
	}
	${EnrollmentInvitationDetailFieldsFragmentDoc}
`;

/**
 * __useEnrollmentInvitationDetailQuery__
 *
 * To run a query within a React component, call `useEnrollmentInvitationDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentInvitationDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollmentInvitationDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEnrollmentInvitationDetailQuery(
	baseOptions: Apollo.QueryHookOptions<EnrollmentInvitationDetailQuery, EnrollmentInvitationDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<EnrollmentInvitationDetailQuery, EnrollmentInvitationDetailQueryVariables>(
		EnrollmentInvitationDetailDocument,
		options,
	);
}
export function useEnrollmentInvitationDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<EnrollmentInvitationDetailQuery, EnrollmentInvitationDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<EnrollmentInvitationDetailQuery, EnrollmentInvitationDetailQueryVariables>(
		EnrollmentInvitationDetailDocument,
		options,
	);
}
export type EnrollmentInvitationDetailQueryHookResult = ReturnType<typeof useEnrollmentInvitationDetailQuery>;
export type EnrollmentInvitationDetailLazyQueryHookResult = ReturnType<typeof useEnrollmentInvitationDetailLazyQuery>;
export type EnrollmentInvitationDetailQueryResult = Apollo.QueryResult<
	EnrollmentInvitationDetailQuery,
	EnrollmentInvitationDetailQueryVariables
>;
export const EnrollmentInvitationIndexDocument = gql`
	query enrollmentInvitationIndex(
		$filters: [EnrollmentInvitationFilterInput!]
		$pagination: Pagination
		$order: EnrollmentInvitationOrder
	) {
		enrollmentInvitationConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					createdAt
					response
					student {
						id
						person {
							id
							name
							imageURL
						}
					}
				}
			}
		}
	}
`;

/**
 * __useEnrollmentInvitationIndexQuery__
 *
 * To run a query within a React component, call `useEnrollmentInvitationIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentInvitationIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollmentInvitationIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useEnrollmentInvitationIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<EnrollmentInvitationIndexQuery, EnrollmentInvitationIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<EnrollmentInvitationIndexQuery, EnrollmentInvitationIndexQueryVariables>(
		EnrollmentInvitationIndexDocument,
		options,
	);
}
export function useEnrollmentInvitationIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<EnrollmentInvitationIndexQuery, EnrollmentInvitationIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<EnrollmentInvitationIndexQuery, EnrollmentInvitationIndexQueryVariables>(
		EnrollmentInvitationIndexDocument,
		options,
	);
}
export type EnrollmentInvitationIndexQueryHookResult = ReturnType<typeof useEnrollmentInvitationIndexQuery>;
export type EnrollmentInvitationIndexLazyQueryHookResult = ReturnType<typeof useEnrollmentInvitationIndexLazyQuery>;
export type EnrollmentInvitationIndexQueryResult = Apollo.QueryResult<
	EnrollmentInvitationIndexQuery,
	EnrollmentInvitationIndexQueryVariables
>;
export const EnrollmentInvitationNameDocument = gql`
	query enrollmentInvitationName($id: ID!) {
		enrollmentInvitation(id: $id) {
			id
			...EnrollmentInvitationName
		}
	}
	${EnrollmentInvitationNameFragmentDoc}
`;

/**
 * __useEnrollmentInvitationNameQuery__
 *
 * To run a query within a React component, call `useEnrollmentInvitationNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentInvitationNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollmentInvitationNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEnrollmentInvitationNameQuery(
	baseOptions: Apollo.QueryHookOptions<EnrollmentInvitationNameQuery, EnrollmentInvitationNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<EnrollmentInvitationNameQuery, EnrollmentInvitationNameQueryVariables>(
		EnrollmentInvitationNameDocument,
		options,
	);
}
export function useEnrollmentInvitationNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<EnrollmentInvitationNameQuery, EnrollmentInvitationNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<EnrollmentInvitationNameQuery, EnrollmentInvitationNameQueryVariables>(
		EnrollmentInvitationNameDocument,
		options,
	);
}
export type EnrollmentInvitationNameQueryHookResult = ReturnType<typeof useEnrollmentInvitationNameQuery>;
export type EnrollmentInvitationNameLazyQueryHookResult = ReturnType<typeof useEnrollmentInvitationNameLazyQuery>;
export type EnrollmentInvitationNameQueryResult = Apollo.QueryResult<
	EnrollmentInvitationNameQuery,
	EnrollmentInvitationNameQueryVariables
>;
export const EnrollmentInvitationNamesDocument = gql`
	query enrollmentInvitationNames(
		$filters: [EnrollmentInvitationFilterInput!]
		$pagination: Pagination
		$order: EnrollmentInvitationOrder
	) {
		enrollmentInvitationConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...EnrollmentInvitationName
				}
			}
		}
	}
	${EnrollmentInvitationNameFragmentDoc}
`;

/**
 * __useEnrollmentInvitationNamesQuery__
 *
 * To run a query within a React component, call `useEnrollmentInvitationNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentInvitationNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollmentInvitationNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useEnrollmentInvitationNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<EnrollmentInvitationNamesQuery, EnrollmentInvitationNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<EnrollmentInvitationNamesQuery, EnrollmentInvitationNamesQueryVariables>(
		EnrollmentInvitationNamesDocument,
		options,
	);
}
export function useEnrollmentInvitationNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<EnrollmentInvitationNamesQuery, EnrollmentInvitationNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<EnrollmentInvitationNamesQuery, EnrollmentInvitationNamesQueryVariables>(
		EnrollmentInvitationNamesDocument,
		options,
	);
}
export type EnrollmentInvitationNamesQueryHookResult = ReturnType<typeof useEnrollmentInvitationNamesQuery>;
export type EnrollmentInvitationNamesLazyQueryHookResult = ReturnType<typeof useEnrollmentInvitationNamesLazyQuery>;
export type EnrollmentInvitationNamesQueryResult = Apollo.QueryResult<
	EnrollmentInvitationNamesQuery,
	EnrollmentInvitationNamesQueryVariables
>;
export const EnrollmentInvitationPaginationDocument = gql`
	query enrollmentInvitationPagination(
		$filters: [EnrollmentInvitationFilterInput!]
		$order: EnrollmentInvitationOrder
	) {
		enrollmentInvitationConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useEnrollmentInvitationPaginationQuery__
 *
 * To run a query within a React component, call `useEnrollmentInvitationPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentInvitationPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollmentInvitationPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useEnrollmentInvitationPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<
		EnrollmentInvitationPaginationQuery,
		EnrollmentInvitationPaginationQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<EnrollmentInvitationPaginationQuery, EnrollmentInvitationPaginationQueryVariables>(
		EnrollmentInvitationPaginationDocument,
		options,
	);
}
export function useEnrollmentInvitationPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		EnrollmentInvitationPaginationQuery,
		EnrollmentInvitationPaginationQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<EnrollmentInvitationPaginationQuery, EnrollmentInvitationPaginationQueryVariables>(
		EnrollmentInvitationPaginationDocument,
		options,
	);
}
export type EnrollmentInvitationPaginationQueryHookResult = ReturnType<typeof useEnrollmentInvitationPaginationQuery>;
export type EnrollmentInvitationPaginationLazyQueryHookResult = ReturnType<
	typeof useEnrollmentInvitationPaginationLazyQuery
>;
export type EnrollmentInvitationPaginationQueryResult = Apollo.QueryResult<
	EnrollmentInvitationPaginationQuery,
	EnrollmentInvitationPaginationQueryVariables
>;
export const EnrollmentInvitationSelectDocument = gql`
	query enrollmentInvitationSelect($filters: [EnrollmentInvitationFilterInput!], $order: EnrollmentInvitationOrder) {
		enrollmentInvitationConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
				}
			}
		}
	}
`;

/**
 * __useEnrollmentInvitationSelectQuery__
 *
 * To run a query within a React component, call `useEnrollmentInvitationSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentInvitationSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollmentInvitationSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useEnrollmentInvitationSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<EnrollmentInvitationSelectQuery, EnrollmentInvitationSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<EnrollmentInvitationSelectQuery, EnrollmentInvitationSelectQueryVariables>(
		EnrollmentInvitationSelectDocument,
		options,
	);
}
export function useEnrollmentInvitationSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<EnrollmentInvitationSelectQuery, EnrollmentInvitationSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<EnrollmentInvitationSelectQuery, EnrollmentInvitationSelectQueryVariables>(
		EnrollmentInvitationSelectDocument,
		options,
	);
}
export type EnrollmentInvitationSelectQueryHookResult = ReturnType<typeof useEnrollmentInvitationSelectQuery>;
export type EnrollmentInvitationSelectLazyQueryHookResult = ReturnType<typeof useEnrollmentInvitationSelectLazyQuery>;
export type EnrollmentInvitationSelectQueryResult = Apollo.QueryResult<
	EnrollmentInvitationSelectQuery,
	EnrollmentInvitationSelectQueryVariables
>;
export const EnrollmentInvitationUpdateDocument = gql`
	mutation enrollmentInvitationUpdate($id: ID!, $input: EnrollmentInvitationUpdateInput!) {
		updateEnrollmentInvitation(id: $id, input: $input) {
			...enrollmentInvitationDetailFields
		}
	}
	${EnrollmentInvitationDetailFieldsFragmentDoc}
`;
export type EnrollmentInvitationUpdateMutationFn = Apollo.MutationFunction<
	EnrollmentInvitationUpdateMutation,
	EnrollmentInvitationUpdateMutationVariables
>;

/**
 * __useEnrollmentInvitationUpdateMutation__
 *
 * To run a mutation, you first call `useEnrollmentInvitationUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentInvitationUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enrollmentInvitationUpdateMutation, { data, loading, error }] = useEnrollmentInvitationUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEnrollmentInvitationUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<
		EnrollmentInvitationUpdateMutation,
		EnrollmentInvitationUpdateMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<EnrollmentInvitationUpdateMutation, EnrollmentInvitationUpdateMutationVariables>(
		EnrollmentInvitationUpdateDocument,
		options,
	);
}
export type EnrollmentInvitationUpdateMutationHookResult = ReturnType<typeof useEnrollmentInvitationUpdateMutation>;
export type EnrollmentInvitationUpdateMutationResult = Apollo.MutationResult<EnrollmentInvitationUpdateMutation>;
export type EnrollmentInvitationUpdateMutationOptions = Apollo.BaseMutationOptions<
	EnrollmentInvitationUpdateMutation,
	EnrollmentInvitationUpdateMutationVariables
>;
export const ExpenseCountDocument = gql`
	query expenseCount($filters: [ExpenseFilterInput!], $pagination: Pagination, $order: ExpenseOrder) {
		expenseConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useExpenseCountQuery__
 *
 * To run a query within a React component, call `useExpenseCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpenseCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpenseCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useExpenseCountQuery(
	baseOptions?: Apollo.QueryHookOptions<ExpenseCountQuery, ExpenseCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ExpenseCountQuery, ExpenseCountQueryVariables>(ExpenseCountDocument, options);
}
export function useExpenseCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ExpenseCountQuery, ExpenseCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ExpenseCountQuery, ExpenseCountQueryVariables>(ExpenseCountDocument, options);
}
export type ExpenseCountQueryHookResult = ReturnType<typeof useExpenseCountQuery>;
export type ExpenseCountLazyQueryHookResult = ReturnType<typeof useExpenseCountLazyQuery>;
export type ExpenseCountQueryResult = Apollo.QueryResult<ExpenseCountQuery, ExpenseCountQueryVariables>;
export const ExpenseCreateDocument = gql`
	mutation expenseCreate($input: ExpenseCreateInput!) {
		createExpense(input: $input) {
			...ExpenseDetail
		}
	}
	${ExpenseDetailFragmentDoc}
`;
export type ExpenseCreateMutationFn = Apollo.MutationFunction<ExpenseCreateMutation, ExpenseCreateMutationVariables>;

/**
 * __useExpenseCreateMutation__
 *
 * To run a mutation, you first call `useExpenseCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExpenseCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [expenseCreateMutation, { data, loading, error }] = useExpenseCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExpenseCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<ExpenseCreateMutation, ExpenseCreateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<ExpenseCreateMutation, ExpenseCreateMutationVariables>(ExpenseCreateDocument, options);
}
export type ExpenseCreateMutationHookResult = ReturnType<typeof useExpenseCreateMutation>;
export type ExpenseCreateMutationResult = Apollo.MutationResult<ExpenseCreateMutation>;
export type ExpenseCreateMutationOptions = Apollo.BaseMutationOptions<
	ExpenseCreateMutation,
	ExpenseCreateMutationVariables
>;
export const ExpenseDeleteDocument = gql`
	mutation expenseDelete($id: ID!) {
		deleteExpense(id: $id)
	}
`;
export type ExpenseDeleteMutationFn = Apollo.MutationFunction<ExpenseDeleteMutation, ExpenseDeleteMutationVariables>;

/**
 * __useExpenseDeleteMutation__
 *
 * To run a mutation, you first call `useExpenseDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExpenseDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [expenseDeleteMutation, { data, loading, error }] = useExpenseDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExpenseDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<ExpenseDeleteMutation, ExpenseDeleteMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<ExpenseDeleteMutation, ExpenseDeleteMutationVariables>(ExpenseDeleteDocument, options);
}
export type ExpenseDeleteMutationHookResult = ReturnType<typeof useExpenseDeleteMutation>;
export type ExpenseDeleteMutationResult = Apollo.MutationResult<ExpenseDeleteMutation>;
export type ExpenseDeleteMutationOptions = Apollo.BaseMutationOptions<
	ExpenseDeleteMutation,
	ExpenseDeleteMutationVariables
>;
export const ExpenseDetailDocument = gql`
	query expenseDetail($id: ID!) {
		expense(id: $id) {
			...ExpenseDetail
		}
	}
	${ExpenseDetailFragmentDoc}
`;

/**
 * __useExpenseDetailQuery__
 *
 * To run a query within a React component, call `useExpenseDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpenseDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpenseDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExpenseDetailQuery(
	baseOptions: Apollo.QueryHookOptions<ExpenseDetailQuery, ExpenseDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ExpenseDetailQuery, ExpenseDetailQueryVariables>(ExpenseDetailDocument, options);
}
export function useExpenseDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ExpenseDetailQuery, ExpenseDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ExpenseDetailQuery, ExpenseDetailQueryVariables>(ExpenseDetailDocument, options);
}
export type ExpenseDetailQueryHookResult = ReturnType<typeof useExpenseDetailQuery>;
export type ExpenseDetailLazyQueryHookResult = ReturnType<typeof useExpenseDetailLazyQuery>;
export type ExpenseDetailQueryResult = Apollo.QueryResult<ExpenseDetailQuery, ExpenseDetailQueryVariables>;
export const ExpenseIndexDocument = gql`
	query expenseIndex($filters: [ExpenseFilterInput!], $pagination: Pagination, $order: ExpenseOrder) {
		expenseConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					createdDate
					amount
					notes
					expenseCategory {
						id
						name
					}
				}
			}
		}
	}
`;

/**
 * __useExpenseIndexQuery__
 *
 * To run a query within a React component, call `useExpenseIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpenseIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpenseIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useExpenseIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<ExpenseIndexQuery, ExpenseIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ExpenseIndexQuery, ExpenseIndexQueryVariables>(ExpenseIndexDocument, options);
}
export function useExpenseIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ExpenseIndexQuery, ExpenseIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ExpenseIndexQuery, ExpenseIndexQueryVariables>(ExpenseIndexDocument, options);
}
export type ExpenseIndexQueryHookResult = ReturnType<typeof useExpenseIndexQuery>;
export type ExpenseIndexLazyQueryHookResult = ReturnType<typeof useExpenseIndexLazyQuery>;
export type ExpenseIndexQueryResult = Apollo.QueryResult<ExpenseIndexQuery, ExpenseIndexQueryVariables>;
export const ExpenseNameDocument = gql`
	query expenseName($id: ID!) {
		expense(id: $id) {
			id
			...ExpenseName
		}
	}
	${ExpenseNameFragmentDoc}
`;

/**
 * __useExpenseNameQuery__
 *
 * To run a query within a React component, call `useExpenseNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpenseNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpenseNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExpenseNameQuery(baseOptions: Apollo.QueryHookOptions<ExpenseNameQuery, ExpenseNameQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ExpenseNameQuery, ExpenseNameQueryVariables>(ExpenseNameDocument, options);
}
export function useExpenseNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ExpenseNameQuery, ExpenseNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ExpenseNameQuery, ExpenseNameQueryVariables>(ExpenseNameDocument, options);
}
export type ExpenseNameQueryHookResult = ReturnType<typeof useExpenseNameQuery>;
export type ExpenseNameLazyQueryHookResult = ReturnType<typeof useExpenseNameLazyQuery>;
export type ExpenseNameQueryResult = Apollo.QueryResult<ExpenseNameQuery, ExpenseNameQueryVariables>;
export const ExpenseNamesDocument = gql`
	query expenseNames($filters: [ExpenseFilterInput!], $pagination: Pagination, $order: ExpenseOrder) {
		expenseConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...ExpenseName
				}
			}
		}
	}
	${ExpenseNameFragmentDoc}
`;

/**
 * __useExpenseNamesQuery__
 *
 * To run a query within a React component, call `useExpenseNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpenseNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpenseNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useExpenseNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<ExpenseNamesQuery, ExpenseNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ExpenseNamesQuery, ExpenseNamesQueryVariables>(ExpenseNamesDocument, options);
}
export function useExpenseNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ExpenseNamesQuery, ExpenseNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ExpenseNamesQuery, ExpenseNamesQueryVariables>(ExpenseNamesDocument, options);
}
export type ExpenseNamesQueryHookResult = ReturnType<typeof useExpenseNamesQuery>;
export type ExpenseNamesLazyQueryHookResult = ReturnType<typeof useExpenseNamesLazyQuery>;
export type ExpenseNamesQueryResult = Apollo.QueryResult<ExpenseNamesQuery, ExpenseNamesQueryVariables>;
export const ExpensePaginationDocument = gql`
	query expensePagination($filters: [ExpenseFilterInput!], $order: ExpenseOrder) {
		expenseConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useExpensePaginationQuery__
 *
 * To run a query within a React component, call `useExpensePaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpensePaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpensePaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useExpensePaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<ExpensePaginationQuery, ExpensePaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ExpensePaginationQuery, ExpensePaginationQueryVariables>(ExpensePaginationDocument, options);
}
export function useExpensePaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ExpensePaginationQuery, ExpensePaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ExpensePaginationQuery, ExpensePaginationQueryVariables>(
		ExpensePaginationDocument,
		options,
	);
}
export type ExpensePaginationQueryHookResult = ReturnType<typeof useExpensePaginationQuery>;
export type ExpensePaginationLazyQueryHookResult = ReturnType<typeof useExpensePaginationLazyQuery>;
export type ExpensePaginationQueryResult = Apollo.QueryResult<ExpensePaginationQuery, ExpensePaginationQueryVariables>;
export const ExpenseSelectDocument = gql`
	query expenseSelect($filters: [ExpenseFilterInput!], $order: ExpenseOrder) {
		expenseConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...ExpenseName
				}
			}
		}
	}
	${ExpenseNameFragmentDoc}
`;

/**
 * __useExpenseSelectQuery__
 *
 * To run a query within a React component, call `useExpenseSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpenseSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpenseSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useExpenseSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<ExpenseSelectQuery, ExpenseSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ExpenseSelectQuery, ExpenseSelectQueryVariables>(ExpenseSelectDocument, options);
}
export function useExpenseSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ExpenseSelectQuery, ExpenseSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ExpenseSelectQuery, ExpenseSelectQueryVariables>(ExpenseSelectDocument, options);
}
export type ExpenseSelectQueryHookResult = ReturnType<typeof useExpenseSelectQuery>;
export type ExpenseSelectLazyQueryHookResult = ReturnType<typeof useExpenseSelectLazyQuery>;
export type ExpenseSelectQueryResult = Apollo.QueryResult<ExpenseSelectQuery, ExpenseSelectQueryVariables>;
export const ExpenseUpdateDocument = gql`
	mutation expenseUpdate($id: ID!, $input: ExpenseUpdateInput!) {
		updateExpense(id: $id, input: $input) {
			...ExpenseDetail
		}
	}
	${ExpenseDetailFragmentDoc}
`;
export type ExpenseUpdateMutationFn = Apollo.MutationFunction<ExpenseUpdateMutation, ExpenseUpdateMutationVariables>;

/**
 * __useExpenseUpdateMutation__
 *
 * To run a mutation, you first call `useExpenseUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExpenseUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [expenseUpdateMutation, { data, loading, error }] = useExpenseUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExpenseUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<ExpenseUpdateMutation, ExpenseUpdateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<ExpenseUpdateMutation, ExpenseUpdateMutationVariables>(ExpenseUpdateDocument, options);
}
export type ExpenseUpdateMutationHookResult = ReturnType<typeof useExpenseUpdateMutation>;
export type ExpenseUpdateMutationResult = Apollo.MutationResult<ExpenseUpdateMutation>;
export type ExpenseUpdateMutationOptions = Apollo.BaseMutationOptions<
	ExpenseUpdateMutation,
	ExpenseUpdateMutationVariables
>;
export const ExpenseBudgetCountDocument = gql`
	query expenseBudgetCount($filters: [ExpenseBudgetFilterInput!], $pagination: Pagination, $order: ExpenseBudgetOrder) {
		expenseBudgetConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useExpenseBudgetCountQuery__
 *
 * To run a query within a React component, call `useExpenseBudgetCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpenseBudgetCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpenseBudgetCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useExpenseBudgetCountQuery(
	baseOptions?: Apollo.QueryHookOptions<ExpenseBudgetCountQuery, ExpenseBudgetCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ExpenseBudgetCountQuery, ExpenseBudgetCountQueryVariables>(
		ExpenseBudgetCountDocument,
		options,
	);
}
export function useExpenseBudgetCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ExpenseBudgetCountQuery, ExpenseBudgetCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ExpenseBudgetCountQuery, ExpenseBudgetCountQueryVariables>(
		ExpenseBudgetCountDocument,
		options,
	);
}
export type ExpenseBudgetCountQueryHookResult = ReturnType<typeof useExpenseBudgetCountQuery>;
export type ExpenseBudgetCountLazyQueryHookResult = ReturnType<typeof useExpenseBudgetCountLazyQuery>;
export type ExpenseBudgetCountQueryResult = Apollo.QueryResult<
	ExpenseBudgetCountQuery,
	ExpenseBudgetCountQueryVariables
>;
export const ExpenseBudgetsCreateBulkDocument = gql`
	mutation expenseBudgetsCreateBulk($inputs: [ExpenseBudgetCreateInput!]!) {
		createBulkExpenseBudgets(inputs: $inputs) {
			edges {
				node {
					...ExpenseBudgetDetail
				}
			}
		}
	}
	${ExpenseBudgetDetailFragmentDoc}
`;
export type ExpenseBudgetsCreateBulkMutationFn = Apollo.MutationFunction<
	ExpenseBudgetsCreateBulkMutation,
	ExpenseBudgetsCreateBulkMutationVariables
>;

/**
 * __useExpenseBudgetsCreateBulkMutation__
 *
 * To run a mutation, you first call `useExpenseBudgetsCreateBulkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExpenseBudgetsCreateBulkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [expenseBudgetsCreateBulkMutation, { data, loading, error }] = useExpenseBudgetsCreateBulkMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useExpenseBudgetsCreateBulkMutation(
	baseOptions?: Apollo.MutationHookOptions<ExpenseBudgetsCreateBulkMutation, ExpenseBudgetsCreateBulkMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<ExpenseBudgetsCreateBulkMutation, ExpenseBudgetsCreateBulkMutationVariables>(
		ExpenseBudgetsCreateBulkDocument,
		options,
	);
}
export type ExpenseBudgetsCreateBulkMutationHookResult = ReturnType<typeof useExpenseBudgetsCreateBulkMutation>;
export type ExpenseBudgetsCreateBulkMutationResult = Apollo.MutationResult<ExpenseBudgetsCreateBulkMutation>;
export type ExpenseBudgetsCreateBulkMutationOptions = Apollo.BaseMutationOptions<
	ExpenseBudgetsCreateBulkMutation,
	ExpenseBudgetsCreateBulkMutationVariables
>;
export const ExpenseBudgetCreateDocument = gql`
	mutation expenseBudgetCreate($input: ExpenseBudgetCreateInput!) {
		createExpenseBudget(input: $input) {
			...ExpenseBudgetDetail
		}
	}
	${ExpenseBudgetDetailFragmentDoc}
`;
export type ExpenseBudgetCreateMutationFn = Apollo.MutationFunction<
	ExpenseBudgetCreateMutation,
	ExpenseBudgetCreateMutationVariables
>;

/**
 * __useExpenseBudgetCreateMutation__
 *
 * To run a mutation, you first call `useExpenseBudgetCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExpenseBudgetCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [expenseBudgetCreateMutation, { data, loading, error }] = useExpenseBudgetCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExpenseBudgetCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<ExpenseBudgetCreateMutation, ExpenseBudgetCreateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<ExpenseBudgetCreateMutation, ExpenseBudgetCreateMutationVariables>(
		ExpenseBudgetCreateDocument,
		options,
	);
}
export type ExpenseBudgetCreateMutationHookResult = ReturnType<typeof useExpenseBudgetCreateMutation>;
export type ExpenseBudgetCreateMutationResult = Apollo.MutationResult<ExpenseBudgetCreateMutation>;
export type ExpenseBudgetCreateMutationOptions = Apollo.BaseMutationOptions<
	ExpenseBudgetCreateMutation,
	ExpenseBudgetCreateMutationVariables
>;
export const ExpenseBudgetDeleteDocument = gql`
	mutation expenseBudgetDelete($id: ID!) {
		deleteExpenseBudget(id: $id)
	}
`;
export type ExpenseBudgetDeleteMutationFn = Apollo.MutationFunction<
	ExpenseBudgetDeleteMutation,
	ExpenseBudgetDeleteMutationVariables
>;

/**
 * __useExpenseBudgetDeleteMutation__
 *
 * To run a mutation, you first call `useExpenseBudgetDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExpenseBudgetDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [expenseBudgetDeleteMutation, { data, loading, error }] = useExpenseBudgetDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExpenseBudgetDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<ExpenseBudgetDeleteMutation, ExpenseBudgetDeleteMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<ExpenseBudgetDeleteMutation, ExpenseBudgetDeleteMutationVariables>(
		ExpenseBudgetDeleteDocument,
		options,
	);
}
export type ExpenseBudgetDeleteMutationHookResult = ReturnType<typeof useExpenseBudgetDeleteMutation>;
export type ExpenseBudgetDeleteMutationResult = Apollo.MutationResult<ExpenseBudgetDeleteMutation>;
export type ExpenseBudgetDeleteMutationOptions = Apollo.BaseMutationOptions<
	ExpenseBudgetDeleteMutation,
	ExpenseBudgetDeleteMutationVariables
>;
export const ExpenseBudgetDetailDocument = gql`
	query expenseBudgetDetail($id: ID!) {
		expenseBudget(id: $id) {
			...ExpenseBudgetDetail
		}
	}
	${ExpenseBudgetDetailFragmentDoc}
`;

/**
 * __useExpenseBudgetDetailQuery__
 *
 * To run a query within a React component, call `useExpenseBudgetDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpenseBudgetDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpenseBudgetDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExpenseBudgetDetailQuery(
	baseOptions: Apollo.QueryHookOptions<ExpenseBudgetDetailQuery, ExpenseBudgetDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ExpenseBudgetDetailQuery, ExpenseBudgetDetailQueryVariables>(
		ExpenseBudgetDetailDocument,
		options,
	);
}
export function useExpenseBudgetDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ExpenseBudgetDetailQuery, ExpenseBudgetDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ExpenseBudgetDetailQuery, ExpenseBudgetDetailQueryVariables>(
		ExpenseBudgetDetailDocument,
		options,
	);
}
export type ExpenseBudgetDetailQueryHookResult = ReturnType<typeof useExpenseBudgetDetailQuery>;
export type ExpenseBudgetDetailLazyQueryHookResult = ReturnType<typeof useExpenseBudgetDetailLazyQuery>;
export type ExpenseBudgetDetailQueryResult = Apollo.QueryResult<
	ExpenseBudgetDetailQuery,
	ExpenseBudgetDetailQueryVariables
>;
export const ExpenseBudgetIndexDocument = gql`
	query expenseBudgetIndex($filters: [ExpenseBudgetFilterInput!], $pagination: Pagination, $order: ExpenseBudgetOrder) {
		expenseBudgetConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					budgetMonth
					amount
					expenseCategory {
						id
						name
					}
				}
			}
		}
	}
`;

/**
 * __useExpenseBudgetIndexQuery__
 *
 * To run a query within a React component, call `useExpenseBudgetIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpenseBudgetIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpenseBudgetIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useExpenseBudgetIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<ExpenseBudgetIndexQuery, ExpenseBudgetIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ExpenseBudgetIndexQuery, ExpenseBudgetIndexQueryVariables>(
		ExpenseBudgetIndexDocument,
		options,
	);
}
export function useExpenseBudgetIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ExpenseBudgetIndexQuery, ExpenseBudgetIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ExpenseBudgetIndexQuery, ExpenseBudgetIndexQueryVariables>(
		ExpenseBudgetIndexDocument,
		options,
	);
}
export type ExpenseBudgetIndexQueryHookResult = ReturnType<typeof useExpenseBudgetIndexQuery>;
export type ExpenseBudgetIndexLazyQueryHookResult = ReturnType<typeof useExpenseBudgetIndexLazyQuery>;
export type ExpenseBudgetIndexQueryResult = Apollo.QueryResult<
	ExpenseBudgetIndexQuery,
	ExpenseBudgetIndexQueryVariables
>;
export const ExpenseBudgetNameDocument = gql`
	query expenseBudgetName($id: ID!) {
		expenseBudget(id: $id) {
			id
			...ExpenseBudgetName
		}
	}
	${ExpenseBudgetNameFragmentDoc}
`;

/**
 * __useExpenseBudgetNameQuery__
 *
 * To run a query within a React component, call `useExpenseBudgetNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpenseBudgetNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpenseBudgetNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExpenseBudgetNameQuery(
	baseOptions: Apollo.QueryHookOptions<ExpenseBudgetNameQuery, ExpenseBudgetNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ExpenseBudgetNameQuery, ExpenseBudgetNameQueryVariables>(ExpenseBudgetNameDocument, options);
}
export function useExpenseBudgetNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ExpenseBudgetNameQuery, ExpenseBudgetNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ExpenseBudgetNameQuery, ExpenseBudgetNameQueryVariables>(
		ExpenseBudgetNameDocument,
		options,
	);
}
export type ExpenseBudgetNameQueryHookResult = ReturnType<typeof useExpenseBudgetNameQuery>;
export type ExpenseBudgetNameLazyQueryHookResult = ReturnType<typeof useExpenseBudgetNameLazyQuery>;
export type ExpenseBudgetNameQueryResult = Apollo.QueryResult<ExpenseBudgetNameQuery, ExpenseBudgetNameQueryVariables>;
export const ExpenseBudgetNamesDocument = gql`
	query expenseBudgetNames($filters: [ExpenseBudgetFilterInput!], $pagination: Pagination, $order: ExpenseBudgetOrder) {
		expenseBudgetConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...ExpenseBudgetName
				}
			}
		}
	}
	${ExpenseBudgetNameFragmentDoc}
`;

/**
 * __useExpenseBudgetNamesQuery__
 *
 * To run a query within a React component, call `useExpenseBudgetNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpenseBudgetNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpenseBudgetNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useExpenseBudgetNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<ExpenseBudgetNamesQuery, ExpenseBudgetNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ExpenseBudgetNamesQuery, ExpenseBudgetNamesQueryVariables>(
		ExpenseBudgetNamesDocument,
		options,
	);
}
export function useExpenseBudgetNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ExpenseBudgetNamesQuery, ExpenseBudgetNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ExpenseBudgetNamesQuery, ExpenseBudgetNamesQueryVariables>(
		ExpenseBudgetNamesDocument,
		options,
	);
}
export type ExpenseBudgetNamesQueryHookResult = ReturnType<typeof useExpenseBudgetNamesQuery>;
export type ExpenseBudgetNamesLazyQueryHookResult = ReturnType<typeof useExpenseBudgetNamesLazyQuery>;
export type ExpenseBudgetNamesQueryResult = Apollo.QueryResult<
	ExpenseBudgetNamesQuery,
	ExpenseBudgetNamesQueryVariables
>;
export const ExpenseBudgetPaginationDocument = gql`
	query expenseBudgetPagination($filters: [ExpenseBudgetFilterInput!], $order: ExpenseBudgetOrder) {
		expenseBudgetConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useExpenseBudgetPaginationQuery__
 *
 * To run a query within a React component, call `useExpenseBudgetPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpenseBudgetPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpenseBudgetPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useExpenseBudgetPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<ExpenseBudgetPaginationQuery, ExpenseBudgetPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ExpenseBudgetPaginationQuery, ExpenseBudgetPaginationQueryVariables>(
		ExpenseBudgetPaginationDocument,
		options,
	);
}
export function useExpenseBudgetPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ExpenseBudgetPaginationQuery, ExpenseBudgetPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ExpenseBudgetPaginationQuery, ExpenseBudgetPaginationQueryVariables>(
		ExpenseBudgetPaginationDocument,
		options,
	);
}
export type ExpenseBudgetPaginationQueryHookResult = ReturnType<typeof useExpenseBudgetPaginationQuery>;
export type ExpenseBudgetPaginationLazyQueryHookResult = ReturnType<typeof useExpenseBudgetPaginationLazyQuery>;
export type ExpenseBudgetPaginationQueryResult = Apollo.QueryResult<
	ExpenseBudgetPaginationQuery,
	ExpenseBudgetPaginationQueryVariables
>;
export const ExpenseBudgetSelectDocument = gql`
	query expenseBudgetSelect($filters: [ExpenseBudgetFilterInput!], $order: ExpenseBudgetOrder) {
		expenseBudgetConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...ExpenseBudgetName
				}
			}
		}
	}
	${ExpenseBudgetNameFragmentDoc}
`;

/**
 * __useExpenseBudgetSelectQuery__
 *
 * To run a query within a React component, call `useExpenseBudgetSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpenseBudgetSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpenseBudgetSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useExpenseBudgetSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<ExpenseBudgetSelectQuery, ExpenseBudgetSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ExpenseBudgetSelectQuery, ExpenseBudgetSelectQueryVariables>(
		ExpenseBudgetSelectDocument,
		options,
	);
}
export function useExpenseBudgetSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ExpenseBudgetSelectQuery, ExpenseBudgetSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ExpenseBudgetSelectQuery, ExpenseBudgetSelectQueryVariables>(
		ExpenseBudgetSelectDocument,
		options,
	);
}
export type ExpenseBudgetSelectQueryHookResult = ReturnType<typeof useExpenseBudgetSelectQuery>;
export type ExpenseBudgetSelectLazyQueryHookResult = ReturnType<typeof useExpenseBudgetSelectLazyQuery>;
export type ExpenseBudgetSelectQueryResult = Apollo.QueryResult<
	ExpenseBudgetSelectQuery,
	ExpenseBudgetSelectQueryVariables
>;
export const ExpenseBudgetUpdateDocument = gql`
	mutation expenseBudgetUpdate($id: ID!, $input: ExpenseBudgetUpdateInput!) {
		updateExpenseBudget(id: $id, input: $input) {
			...ExpenseBudgetDetail
		}
	}
	${ExpenseBudgetDetailFragmentDoc}
`;
export type ExpenseBudgetUpdateMutationFn = Apollo.MutationFunction<
	ExpenseBudgetUpdateMutation,
	ExpenseBudgetUpdateMutationVariables
>;

/**
 * __useExpenseBudgetUpdateMutation__
 *
 * To run a mutation, you first call `useExpenseBudgetUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExpenseBudgetUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [expenseBudgetUpdateMutation, { data, loading, error }] = useExpenseBudgetUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExpenseBudgetUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<ExpenseBudgetUpdateMutation, ExpenseBudgetUpdateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<ExpenseBudgetUpdateMutation, ExpenseBudgetUpdateMutationVariables>(
		ExpenseBudgetUpdateDocument,
		options,
	);
}
export type ExpenseBudgetUpdateMutationHookResult = ReturnType<typeof useExpenseBudgetUpdateMutation>;
export type ExpenseBudgetUpdateMutationResult = Apollo.MutationResult<ExpenseBudgetUpdateMutation>;
export type ExpenseBudgetUpdateMutationOptions = Apollo.BaseMutationOptions<
	ExpenseBudgetUpdateMutation,
	ExpenseBudgetUpdateMutationVariables
>;
export const ExpenseCategoryCheckUniqueNameDocument = gql`
	query expenseCategoryCheckUniqueName($ignoreID: ID, $name: String!) {
		expenseCategoryConnection(filters: { idNEQ: $ignoreID, nameEQ: $name }) {
			totalCount
		}
	}
`;

/**
 * __useExpenseCategoryCheckUniqueNameQuery__
 *
 * To run a query within a React component, call `useExpenseCategoryCheckUniqueNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpenseCategoryCheckUniqueNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpenseCategoryCheckUniqueNameQuery({
 *   variables: {
 *      ignoreID: // value for 'ignoreID'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useExpenseCategoryCheckUniqueNameQuery(
	baseOptions: Apollo.QueryHookOptions<
		ExpenseCategoryCheckUniqueNameQuery,
		ExpenseCategoryCheckUniqueNameQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ExpenseCategoryCheckUniqueNameQuery, ExpenseCategoryCheckUniqueNameQueryVariables>(
		ExpenseCategoryCheckUniqueNameDocument,
		options,
	);
}
export function useExpenseCategoryCheckUniqueNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ExpenseCategoryCheckUniqueNameQuery,
		ExpenseCategoryCheckUniqueNameQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ExpenseCategoryCheckUniqueNameQuery, ExpenseCategoryCheckUniqueNameQueryVariables>(
		ExpenseCategoryCheckUniqueNameDocument,
		options,
	);
}
export type ExpenseCategoryCheckUniqueNameQueryHookResult = ReturnType<typeof useExpenseCategoryCheckUniqueNameQuery>;
export type ExpenseCategoryCheckUniqueNameLazyQueryHookResult = ReturnType<
	typeof useExpenseCategoryCheckUniqueNameLazyQuery
>;
export type ExpenseCategoryCheckUniqueNameQueryResult = Apollo.QueryResult<
	ExpenseCategoryCheckUniqueNameQuery,
	ExpenseCategoryCheckUniqueNameQueryVariables
>;
export const ExpenseCategoryCountDocument = gql`
	query expenseCategoryCount(
		$filters: [ExpenseCategoryFilterInput!]
		$pagination: Pagination
		$order: ExpenseCategoryOrder
	) {
		expenseCategoryConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useExpenseCategoryCountQuery__
 *
 * To run a query within a React component, call `useExpenseCategoryCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpenseCategoryCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpenseCategoryCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useExpenseCategoryCountQuery(
	baseOptions?: Apollo.QueryHookOptions<ExpenseCategoryCountQuery, ExpenseCategoryCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ExpenseCategoryCountQuery, ExpenseCategoryCountQueryVariables>(
		ExpenseCategoryCountDocument,
		options,
	);
}
export function useExpenseCategoryCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ExpenseCategoryCountQuery, ExpenseCategoryCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ExpenseCategoryCountQuery, ExpenseCategoryCountQueryVariables>(
		ExpenseCategoryCountDocument,
		options,
	);
}
export type ExpenseCategoryCountQueryHookResult = ReturnType<typeof useExpenseCategoryCountQuery>;
export type ExpenseCategoryCountLazyQueryHookResult = ReturnType<typeof useExpenseCategoryCountLazyQuery>;
export type ExpenseCategoryCountQueryResult = Apollo.QueryResult<
	ExpenseCategoryCountQuery,
	ExpenseCategoryCountQueryVariables
>;
export const ExpenseCategoryCreateDocument = gql`
	mutation expenseCategoryCreate($input: ExpenseCategoryCreateInput!) {
		createExpenseCategory(input: $input) {
			...ExpenseCategoryDetail
		}
	}
	${ExpenseCategoryDetailFragmentDoc}
`;
export type ExpenseCategoryCreateMutationFn = Apollo.MutationFunction<
	ExpenseCategoryCreateMutation,
	ExpenseCategoryCreateMutationVariables
>;

/**
 * __useExpenseCategoryCreateMutation__
 *
 * To run a mutation, you first call `useExpenseCategoryCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExpenseCategoryCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [expenseCategoryCreateMutation, { data, loading, error }] = useExpenseCategoryCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExpenseCategoryCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<ExpenseCategoryCreateMutation, ExpenseCategoryCreateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<ExpenseCategoryCreateMutation, ExpenseCategoryCreateMutationVariables>(
		ExpenseCategoryCreateDocument,
		options,
	);
}
export type ExpenseCategoryCreateMutationHookResult = ReturnType<typeof useExpenseCategoryCreateMutation>;
export type ExpenseCategoryCreateMutationResult = Apollo.MutationResult<ExpenseCategoryCreateMutation>;
export type ExpenseCategoryCreateMutationOptions = Apollo.BaseMutationOptions<
	ExpenseCategoryCreateMutation,
	ExpenseCategoryCreateMutationVariables
>;
export const ExpenseCategoryDeleteDocument = gql`
	mutation expenseCategoryDelete($id: ID!) {
		deleteExpenseCategory(id: $id)
	}
`;
export type ExpenseCategoryDeleteMutationFn = Apollo.MutationFunction<
	ExpenseCategoryDeleteMutation,
	ExpenseCategoryDeleteMutationVariables
>;

/**
 * __useExpenseCategoryDeleteMutation__
 *
 * To run a mutation, you first call `useExpenseCategoryDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExpenseCategoryDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [expenseCategoryDeleteMutation, { data, loading, error }] = useExpenseCategoryDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExpenseCategoryDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<ExpenseCategoryDeleteMutation, ExpenseCategoryDeleteMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<ExpenseCategoryDeleteMutation, ExpenseCategoryDeleteMutationVariables>(
		ExpenseCategoryDeleteDocument,
		options,
	);
}
export type ExpenseCategoryDeleteMutationHookResult = ReturnType<typeof useExpenseCategoryDeleteMutation>;
export type ExpenseCategoryDeleteMutationResult = Apollo.MutationResult<ExpenseCategoryDeleteMutation>;
export type ExpenseCategoryDeleteMutationOptions = Apollo.BaseMutationOptions<
	ExpenseCategoryDeleteMutation,
	ExpenseCategoryDeleteMutationVariables
>;
export const ExpenseCategoryDetailDocument = gql`
	query expenseCategoryDetail($id: ID!) {
		expenseCategory(id: $id) {
			...ExpenseCategoryDetail
		}
	}
	${ExpenseCategoryDetailFragmentDoc}
`;

/**
 * __useExpenseCategoryDetailQuery__
 *
 * To run a query within a React component, call `useExpenseCategoryDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpenseCategoryDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpenseCategoryDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExpenseCategoryDetailQuery(
	baseOptions: Apollo.QueryHookOptions<ExpenseCategoryDetailQuery, ExpenseCategoryDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ExpenseCategoryDetailQuery, ExpenseCategoryDetailQueryVariables>(
		ExpenseCategoryDetailDocument,
		options,
	);
}
export function useExpenseCategoryDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ExpenseCategoryDetailQuery, ExpenseCategoryDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ExpenseCategoryDetailQuery, ExpenseCategoryDetailQueryVariables>(
		ExpenseCategoryDetailDocument,
		options,
	);
}
export type ExpenseCategoryDetailQueryHookResult = ReturnType<typeof useExpenseCategoryDetailQuery>;
export type ExpenseCategoryDetailLazyQueryHookResult = ReturnType<typeof useExpenseCategoryDetailLazyQuery>;
export type ExpenseCategoryDetailQueryResult = Apollo.QueryResult<
	ExpenseCategoryDetailQuery,
	ExpenseCategoryDetailQueryVariables
>;
export const ExpenseCategoryIndexDocument = gql`
	query expenseCategoryIndex(
		$filters: [ExpenseCategoryFilterInput!]
		$pagination: Pagination
		$order: ExpenseCategoryOrder
	) {
		expenseCategoryConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					name
					description
				}
			}
		}
	}
`;

/**
 * __useExpenseCategoryIndexQuery__
 *
 * To run a query within a React component, call `useExpenseCategoryIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpenseCategoryIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpenseCategoryIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useExpenseCategoryIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<ExpenseCategoryIndexQuery, ExpenseCategoryIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ExpenseCategoryIndexQuery, ExpenseCategoryIndexQueryVariables>(
		ExpenseCategoryIndexDocument,
		options,
	);
}
export function useExpenseCategoryIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ExpenseCategoryIndexQuery, ExpenseCategoryIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ExpenseCategoryIndexQuery, ExpenseCategoryIndexQueryVariables>(
		ExpenseCategoryIndexDocument,
		options,
	);
}
export type ExpenseCategoryIndexQueryHookResult = ReturnType<typeof useExpenseCategoryIndexQuery>;
export type ExpenseCategoryIndexLazyQueryHookResult = ReturnType<typeof useExpenseCategoryIndexLazyQuery>;
export type ExpenseCategoryIndexQueryResult = Apollo.QueryResult<
	ExpenseCategoryIndexQuery,
	ExpenseCategoryIndexQueryVariables
>;
export const ExpenseCategoryNameDocument = gql`
	query expenseCategoryName($id: ID!) {
		expenseCategory(id: $id) {
			id
			...ExpenseCategoryName
		}
	}
	${ExpenseCategoryNameFragmentDoc}
`;

/**
 * __useExpenseCategoryNameQuery__
 *
 * To run a query within a React component, call `useExpenseCategoryNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpenseCategoryNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpenseCategoryNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExpenseCategoryNameQuery(
	baseOptions: Apollo.QueryHookOptions<ExpenseCategoryNameQuery, ExpenseCategoryNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ExpenseCategoryNameQuery, ExpenseCategoryNameQueryVariables>(
		ExpenseCategoryNameDocument,
		options,
	);
}
export function useExpenseCategoryNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ExpenseCategoryNameQuery, ExpenseCategoryNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ExpenseCategoryNameQuery, ExpenseCategoryNameQueryVariables>(
		ExpenseCategoryNameDocument,
		options,
	);
}
export type ExpenseCategoryNameQueryHookResult = ReturnType<typeof useExpenseCategoryNameQuery>;
export type ExpenseCategoryNameLazyQueryHookResult = ReturnType<typeof useExpenseCategoryNameLazyQuery>;
export type ExpenseCategoryNameQueryResult = Apollo.QueryResult<
	ExpenseCategoryNameQuery,
	ExpenseCategoryNameQueryVariables
>;
export const ExpenseCategoryNamesDocument = gql`
	query expenseCategoryNames(
		$filters: [ExpenseCategoryFilterInput!]
		$pagination: Pagination
		$order: ExpenseCategoryOrder
	) {
		expenseCategoryConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...ExpenseCategoryName
				}
			}
		}
	}
	${ExpenseCategoryNameFragmentDoc}
`;

/**
 * __useExpenseCategoryNamesQuery__
 *
 * To run a query within a React component, call `useExpenseCategoryNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpenseCategoryNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpenseCategoryNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useExpenseCategoryNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<ExpenseCategoryNamesQuery, ExpenseCategoryNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ExpenseCategoryNamesQuery, ExpenseCategoryNamesQueryVariables>(
		ExpenseCategoryNamesDocument,
		options,
	);
}
export function useExpenseCategoryNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ExpenseCategoryNamesQuery, ExpenseCategoryNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ExpenseCategoryNamesQuery, ExpenseCategoryNamesQueryVariables>(
		ExpenseCategoryNamesDocument,
		options,
	);
}
export type ExpenseCategoryNamesQueryHookResult = ReturnType<typeof useExpenseCategoryNamesQuery>;
export type ExpenseCategoryNamesLazyQueryHookResult = ReturnType<typeof useExpenseCategoryNamesLazyQuery>;
export type ExpenseCategoryNamesQueryResult = Apollo.QueryResult<
	ExpenseCategoryNamesQuery,
	ExpenseCategoryNamesQueryVariables
>;
export const ExpenseCategoryPaginationDocument = gql`
	query expenseCategoryPagination($filters: [ExpenseCategoryFilterInput!], $order: ExpenseCategoryOrder) {
		expenseCategoryConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useExpenseCategoryPaginationQuery__
 *
 * To run a query within a React component, call `useExpenseCategoryPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpenseCategoryPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpenseCategoryPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useExpenseCategoryPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<ExpenseCategoryPaginationQuery, ExpenseCategoryPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ExpenseCategoryPaginationQuery, ExpenseCategoryPaginationQueryVariables>(
		ExpenseCategoryPaginationDocument,
		options,
	);
}
export function useExpenseCategoryPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ExpenseCategoryPaginationQuery, ExpenseCategoryPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ExpenseCategoryPaginationQuery, ExpenseCategoryPaginationQueryVariables>(
		ExpenseCategoryPaginationDocument,
		options,
	);
}
export type ExpenseCategoryPaginationQueryHookResult = ReturnType<typeof useExpenseCategoryPaginationQuery>;
export type ExpenseCategoryPaginationLazyQueryHookResult = ReturnType<typeof useExpenseCategoryPaginationLazyQuery>;
export type ExpenseCategoryPaginationQueryResult = Apollo.QueryResult<
	ExpenseCategoryPaginationQuery,
	ExpenseCategoryPaginationQueryVariables
>;
export const ExpenseCategorySelectDocument = gql`
	query expenseCategorySelect($filters: [ExpenseCategoryFilterInput!], $order: ExpenseCategoryOrder) {
		expenseCategoryConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...ExpenseCategoryName
				}
			}
		}
	}
	${ExpenseCategoryNameFragmentDoc}
`;

/**
 * __useExpenseCategorySelectQuery__
 *
 * To run a query within a React component, call `useExpenseCategorySelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpenseCategorySelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpenseCategorySelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useExpenseCategorySelectQuery(
	baseOptions?: Apollo.QueryHookOptions<ExpenseCategorySelectQuery, ExpenseCategorySelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ExpenseCategorySelectQuery, ExpenseCategorySelectQueryVariables>(
		ExpenseCategorySelectDocument,
		options,
	);
}
export function useExpenseCategorySelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ExpenseCategorySelectQuery, ExpenseCategorySelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ExpenseCategorySelectQuery, ExpenseCategorySelectQueryVariables>(
		ExpenseCategorySelectDocument,
		options,
	);
}
export type ExpenseCategorySelectQueryHookResult = ReturnType<typeof useExpenseCategorySelectQuery>;
export type ExpenseCategorySelectLazyQueryHookResult = ReturnType<typeof useExpenseCategorySelectLazyQuery>;
export type ExpenseCategorySelectQueryResult = Apollo.QueryResult<
	ExpenseCategorySelectQuery,
	ExpenseCategorySelectQueryVariables
>;
export const ExpenseCategoryUpdateDocument = gql`
	mutation expenseCategoryUpdate($id: ID!, $input: ExpenseCategoryUpdateInput!) {
		updateExpenseCategory(id: $id, input: $input) {
			...ExpenseCategoryDetail
		}
	}
	${ExpenseCategoryDetailFragmentDoc}
`;
export type ExpenseCategoryUpdateMutationFn = Apollo.MutationFunction<
	ExpenseCategoryUpdateMutation,
	ExpenseCategoryUpdateMutationVariables
>;

/**
 * __useExpenseCategoryUpdateMutation__
 *
 * To run a mutation, you first call `useExpenseCategoryUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExpenseCategoryUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [expenseCategoryUpdateMutation, { data, loading, error }] = useExpenseCategoryUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExpenseCategoryUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<ExpenseCategoryUpdateMutation, ExpenseCategoryUpdateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<ExpenseCategoryUpdateMutation, ExpenseCategoryUpdateMutationVariables>(
		ExpenseCategoryUpdateDocument,
		options,
	);
}
export type ExpenseCategoryUpdateMutationHookResult = ReturnType<typeof useExpenseCategoryUpdateMutation>;
export type ExpenseCategoryUpdateMutationResult = Apollo.MutationResult<ExpenseCategoryUpdateMutation>;
export type ExpenseCategoryUpdateMutationOptions = Apollo.BaseMutationOptions<
	ExpenseCategoryUpdateMutation,
	ExpenseCategoryUpdateMutationVariables
>;
export const HomeRoomCountDocument = gql`
	query homeRoomCount($filters: [HomeRoomFilterInput!], $pagination: Pagination, $order: HomeRoomOrder) {
		homeRoomConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useHomeRoomCountQuery__
 *
 * To run a query within a React component, call `useHomeRoomCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeRoomCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeRoomCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useHomeRoomCountQuery(
	baseOptions?: Apollo.QueryHookOptions<HomeRoomCountQuery, HomeRoomCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<HomeRoomCountQuery, HomeRoomCountQueryVariables>(HomeRoomCountDocument, options);
}
export function useHomeRoomCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<HomeRoomCountQuery, HomeRoomCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<HomeRoomCountQuery, HomeRoomCountQueryVariables>(HomeRoomCountDocument, options);
}
export type HomeRoomCountQueryHookResult = ReturnType<typeof useHomeRoomCountQuery>;
export type HomeRoomCountLazyQueryHookResult = ReturnType<typeof useHomeRoomCountLazyQuery>;
export type HomeRoomCountQueryResult = Apollo.QueryResult<HomeRoomCountQuery, HomeRoomCountQueryVariables>;
export const HomeRoomCreateDocument = gql`
	mutation homeRoomCreate($input: HomeRoomCreateInput!) {
		createHomeRoom(input: $input) {
			...HomeRoomDetail
		}
	}
	${HomeRoomDetailFragmentDoc}
`;
export type HomeRoomCreateMutationFn = Apollo.MutationFunction<HomeRoomCreateMutation, HomeRoomCreateMutationVariables>;

/**
 * __useHomeRoomCreateMutation__
 *
 * To run a mutation, you first call `useHomeRoomCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHomeRoomCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [homeRoomCreateMutation, { data, loading, error }] = useHomeRoomCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHomeRoomCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<HomeRoomCreateMutation, HomeRoomCreateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<HomeRoomCreateMutation, HomeRoomCreateMutationVariables>(HomeRoomCreateDocument, options);
}
export type HomeRoomCreateMutationHookResult = ReturnType<typeof useHomeRoomCreateMutation>;
export type HomeRoomCreateMutationResult = Apollo.MutationResult<HomeRoomCreateMutation>;
export type HomeRoomCreateMutationOptions = Apollo.BaseMutationOptions<
	HomeRoomCreateMutation,
	HomeRoomCreateMutationVariables
>;
export const HomeRoomDeleteDocument = gql`
	mutation homeRoomDelete($id: ID!) {
		deleteHomeRoom(id: $id)
	}
`;
export type HomeRoomDeleteMutationFn = Apollo.MutationFunction<HomeRoomDeleteMutation, HomeRoomDeleteMutationVariables>;

/**
 * __useHomeRoomDeleteMutation__
 *
 * To run a mutation, you first call `useHomeRoomDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHomeRoomDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [homeRoomDeleteMutation, { data, loading, error }] = useHomeRoomDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHomeRoomDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<HomeRoomDeleteMutation, HomeRoomDeleteMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<HomeRoomDeleteMutation, HomeRoomDeleteMutationVariables>(HomeRoomDeleteDocument, options);
}
export type HomeRoomDeleteMutationHookResult = ReturnType<typeof useHomeRoomDeleteMutation>;
export type HomeRoomDeleteMutationResult = Apollo.MutationResult<HomeRoomDeleteMutation>;
export type HomeRoomDeleteMutationOptions = Apollo.BaseMutationOptions<
	HomeRoomDeleteMutation,
	HomeRoomDeleteMutationVariables
>;
export const HomeRoomDetailDocument = gql`
	query homeRoomDetail($id: ID!) {
		homeRoom(id: $id) {
			...HomeRoomDetail
		}
	}
	${HomeRoomDetailFragmentDoc}
`;

/**
 * __useHomeRoomDetailQuery__
 *
 * To run a query within a React component, call `useHomeRoomDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeRoomDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeRoomDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHomeRoomDetailQuery(
	baseOptions: Apollo.QueryHookOptions<HomeRoomDetailQuery, HomeRoomDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<HomeRoomDetailQuery, HomeRoomDetailQueryVariables>(HomeRoomDetailDocument, options);
}
export function useHomeRoomDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<HomeRoomDetailQuery, HomeRoomDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<HomeRoomDetailQuery, HomeRoomDetailQueryVariables>(HomeRoomDetailDocument, options);
}
export type HomeRoomDetailQueryHookResult = ReturnType<typeof useHomeRoomDetailQuery>;
export type HomeRoomDetailLazyQueryHookResult = ReturnType<typeof useHomeRoomDetailLazyQuery>;
export type HomeRoomDetailQueryResult = Apollo.QueryResult<HomeRoomDetailQuery, HomeRoomDetailQueryVariables>;
export const HomeRoomIndexDocument = gql`
	query homeRoomIndex($filters: [HomeRoomFilterInput!], $pagination: Pagination, $order: HomeRoomOrder) {
		homeRoomConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					name
					description
					studentCapacity
				}
			}
		}
	}
`;

/**
 * __useHomeRoomIndexQuery__
 *
 * To run a query within a React component, call `useHomeRoomIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeRoomIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeRoomIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useHomeRoomIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<HomeRoomIndexQuery, HomeRoomIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<HomeRoomIndexQuery, HomeRoomIndexQueryVariables>(HomeRoomIndexDocument, options);
}
export function useHomeRoomIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<HomeRoomIndexQuery, HomeRoomIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<HomeRoomIndexQuery, HomeRoomIndexQueryVariables>(HomeRoomIndexDocument, options);
}
export type HomeRoomIndexQueryHookResult = ReturnType<typeof useHomeRoomIndexQuery>;
export type HomeRoomIndexLazyQueryHookResult = ReturnType<typeof useHomeRoomIndexLazyQuery>;
export type HomeRoomIndexQueryResult = Apollo.QueryResult<HomeRoomIndexQuery, HomeRoomIndexQueryVariables>;
export const HomeRoomNameDocument = gql`
	query homeRoomName($id: ID!) {
		homeRoom(id: $id) {
			id
			...HomeRoomName
		}
	}
	${HomeRoomNameFragmentDoc}
`;

/**
 * __useHomeRoomNameQuery__
 *
 * To run a query within a React component, call `useHomeRoomNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeRoomNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeRoomNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHomeRoomNameQuery(
	baseOptions: Apollo.QueryHookOptions<HomeRoomNameQuery, HomeRoomNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<HomeRoomNameQuery, HomeRoomNameQueryVariables>(HomeRoomNameDocument, options);
}
export function useHomeRoomNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<HomeRoomNameQuery, HomeRoomNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<HomeRoomNameQuery, HomeRoomNameQueryVariables>(HomeRoomNameDocument, options);
}
export type HomeRoomNameQueryHookResult = ReturnType<typeof useHomeRoomNameQuery>;
export type HomeRoomNameLazyQueryHookResult = ReturnType<typeof useHomeRoomNameLazyQuery>;
export type HomeRoomNameQueryResult = Apollo.QueryResult<HomeRoomNameQuery, HomeRoomNameQueryVariables>;
export const HomeRoomNamesDocument = gql`
	query homeRoomNames($filters: [HomeRoomFilterInput!], $pagination: Pagination, $order: HomeRoomOrder) {
		homeRoomConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...HomeRoomName
				}
			}
		}
	}
	${HomeRoomNameFragmentDoc}
`;

/**
 * __useHomeRoomNamesQuery__
 *
 * To run a query within a React component, call `useHomeRoomNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeRoomNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeRoomNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useHomeRoomNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<HomeRoomNamesQuery, HomeRoomNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<HomeRoomNamesQuery, HomeRoomNamesQueryVariables>(HomeRoomNamesDocument, options);
}
export function useHomeRoomNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<HomeRoomNamesQuery, HomeRoomNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<HomeRoomNamesQuery, HomeRoomNamesQueryVariables>(HomeRoomNamesDocument, options);
}
export type HomeRoomNamesQueryHookResult = ReturnType<typeof useHomeRoomNamesQuery>;
export type HomeRoomNamesLazyQueryHookResult = ReturnType<typeof useHomeRoomNamesLazyQuery>;
export type HomeRoomNamesQueryResult = Apollo.QueryResult<HomeRoomNamesQuery, HomeRoomNamesQueryVariables>;
export const HomeRoomPaginationDocument = gql`
	query homeRoomPagination($filters: [HomeRoomFilterInput!], $order: HomeRoomOrder) {
		homeRoomConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useHomeRoomPaginationQuery__
 *
 * To run a query within a React component, call `useHomeRoomPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeRoomPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeRoomPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useHomeRoomPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<HomeRoomPaginationQuery, HomeRoomPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<HomeRoomPaginationQuery, HomeRoomPaginationQueryVariables>(
		HomeRoomPaginationDocument,
		options,
	);
}
export function useHomeRoomPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<HomeRoomPaginationQuery, HomeRoomPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<HomeRoomPaginationQuery, HomeRoomPaginationQueryVariables>(
		HomeRoomPaginationDocument,
		options,
	);
}
export type HomeRoomPaginationQueryHookResult = ReturnType<typeof useHomeRoomPaginationQuery>;
export type HomeRoomPaginationLazyQueryHookResult = ReturnType<typeof useHomeRoomPaginationLazyQuery>;
export type HomeRoomPaginationQueryResult = Apollo.QueryResult<
	HomeRoomPaginationQuery,
	HomeRoomPaginationQueryVariables
>;
export const HomeRoomWithSectionsSelectDocument = gql`
	query homeRoomWithSectionsSelect(
		$filters: [HomeRoomFilterInput!]
		$order: HomeRoomOrder
		$sectionFilters: [HomeRoomSectionFilterInput!]
		$sectionOrder: HomeRoomSectionOrder
	) {
		homeRoomConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					name
					homeRoomSections(filters: $sectionFilters, order: $sectionOrder) {
						id
						name
					}
				}
			}
		}
	}
`;

/**
 * __useHomeRoomWithSectionsSelectQuery__
 *
 * To run a query within a React component, call `useHomeRoomWithSectionsSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeRoomWithSectionsSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeRoomWithSectionsSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *      sectionFilters: // value for 'sectionFilters'
 *      sectionOrder: // value for 'sectionOrder'
 *   },
 * });
 */
export function useHomeRoomWithSectionsSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<HomeRoomWithSectionsSelectQuery, HomeRoomWithSectionsSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<HomeRoomWithSectionsSelectQuery, HomeRoomWithSectionsSelectQueryVariables>(
		HomeRoomWithSectionsSelectDocument,
		options,
	);
}
export function useHomeRoomWithSectionsSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<HomeRoomWithSectionsSelectQuery, HomeRoomWithSectionsSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<HomeRoomWithSectionsSelectQuery, HomeRoomWithSectionsSelectQueryVariables>(
		HomeRoomWithSectionsSelectDocument,
		options,
	);
}
export type HomeRoomWithSectionsSelectQueryHookResult = ReturnType<typeof useHomeRoomWithSectionsSelectQuery>;
export type HomeRoomWithSectionsSelectLazyQueryHookResult = ReturnType<typeof useHomeRoomWithSectionsSelectLazyQuery>;
export type HomeRoomWithSectionsSelectQueryResult = Apollo.QueryResult<
	HomeRoomWithSectionsSelectQuery,
	HomeRoomWithSectionsSelectQueryVariables
>;
export const HomeRoomSelectDocument = gql`
	query homeRoomSelect($filters: [HomeRoomFilterInput!], $order: HomeRoomOrder) {
		homeRoomConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...HomeRoomName
				}
			}
		}
	}
	${HomeRoomNameFragmentDoc}
`;

/**
 * __useHomeRoomSelectQuery__
 *
 * To run a query within a React component, call `useHomeRoomSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeRoomSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeRoomSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useHomeRoomSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<HomeRoomSelectQuery, HomeRoomSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<HomeRoomSelectQuery, HomeRoomSelectQueryVariables>(HomeRoomSelectDocument, options);
}
export function useHomeRoomSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<HomeRoomSelectQuery, HomeRoomSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<HomeRoomSelectQuery, HomeRoomSelectQueryVariables>(HomeRoomSelectDocument, options);
}
export type HomeRoomSelectQueryHookResult = ReturnType<typeof useHomeRoomSelectQuery>;
export type HomeRoomSelectLazyQueryHookResult = ReturnType<typeof useHomeRoomSelectLazyQuery>;
export type HomeRoomSelectQueryResult = Apollo.QueryResult<HomeRoomSelectQuery, HomeRoomSelectQueryVariables>;
export const HomeRoomUpdateDocument = gql`
	mutation homeRoomUpdate($id: ID!, $input: HomeRoomUpdateInput!) {
		updateHomeRoom(id: $id, input: $input) {
			...HomeRoomDetail
		}
	}
	${HomeRoomDetailFragmentDoc}
`;
export type HomeRoomUpdateMutationFn = Apollo.MutationFunction<HomeRoomUpdateMutation, HomeRoomUpdateMutationVariables>;

/**
 * __useHomeRoomUpdateMutation__
 *
 * To run a mutation, you first call `useHomeRoomUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHomeRoomUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [homeRoomUpdateMutation, { data, loading, error }] = useHomeRoomUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHomeRoomUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<HomeRoomUpdateMutation, HomeRoomUpdateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<HomeRoomUpdateMutation, HomeRoomUpdateMutationVariables>(HomeRoomUpdateDocument, options);
}
export type HomeRoomUpdateMutationHookResult = ReturnType<typeof useHomeRoomUpdateMutation>;
export type HomeRoomUpdateMutationResult = Apollo.MutationResult<HomeRoomUpdateMutation>;
export type HomeRoomUpdateMutationOptions = Apollo.BaseMutationOptions<
	HomeRoomUpdateMutation,
	HomeRoomUpdateMutationVariables
>;
export const HomeRoomSectionCountDocument = gql`
	query homeRoomSectionCount(
		$filters: [HomeRoomSectionFilterInput!]
		$pagination: Pagination
		$order: HomeRoomSectionOrder
	) {
		homeRoomSectionConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useHomeRoomSectionCountQuery__
 *
 * To run a query within a React component, call `useHomeRoomSectionCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeRoomSectionCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeRoomSectionCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useHomeRoomSectionCountQuery(
	baseOptions?: Apollo.QueryHookOptions<HomeRoomSectionCountQuery, HomeRoomSectionCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<HomeRoomSectionCountQuery, HomeRoomSectionCountQueryVariables>(
		HomeRoomSectionCountDocument,
		options,
	);
}
export function useHomeRoomSectionCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<HomeRoomSectionCountQuery, HomeRoomSectionCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<HomeRoomSectionCountQuery, HomeRoomSectionCountQueryVariables>(
		HomeRoomSectionCountDocument,
		options,
	);
}
export type HomeRoomSectionCountQueryHookResult = ReturnType<typeof useHomeRoomSectionCountQuery>;
export type HomeRoomSectionCountLazyQueryHookResult = ReturnType<typeof useHomeRoomSectionCountLazyQuery>;
export type HomeRoomSectionCountQueryResult = Apollo.QueryResult<
	HomeRoomSectionCountQuery,
	HomeRoomSectionCountQueryVariables
>;
export const HomeRoomSectionsCreateBulkDocument = gql`
	mutation homeRoomSectionsCreateBulk($inputs: [HomeRoomSectionCreateInput!]!) {
		createBulkHomeRoomSections(inputs: $inputs) {
			edges {
				node {
					...HomeRoomSectionDetail
				}
			}
		}
	}
	${HomeRoomSectionDetailFragmentDoc}
`;
export type HomeRoomSectionsCreateBulkMutationFn = Apollo.MutationFunction<
	HomeRoomSectionsCreateBulkMutation,
	HomeRoomSectionsCreateBulkMutationVariables
>;

/**
 * __useHomeRoomSectionsCreateBulkMutation__
 *
 * To run a mutation, you first call `useHomeRoomSectionsCreateBulkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHomeRoomSectionsCreateBulkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [homeRoomSectionsCreateBulkMutation, { data, loading, error }] = useHomeRoomSectionsCreateBulkMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useHomeRoomSectionsCreateBulkMutation(
	baseOptions?: Apollo.MutationHookOptions<
		HomeRoomSectionsCreateBulkMutation,
		HomeRoomSectionsCreateBulkMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<HomeRoomSectionsCreateBulkMutation, HomeRoomSectionsCreateBulkMutationVariables>(
		HomeRoomSectionsCreateBulkDocument,
		options,
	);
}
export type HomeRoomSectionsCreateBulkMutationHookResult = ReturnType<typeof useHomeRoomSectionsCreateBulkMutation>;
export type HomeRoomSectionsCreateBulkMutationResult = Apollo.MutationResult<HomeRoomSectionsCreateBulkMutation>;
export type HomeRoomSectionsCreateBulkMutationOptions = Apollo.BaseMutationOptions<
	HomeRoomSectionsCreateBulkMutation,
	HomeRoomSectionsCreateBulkMutationVariables
>;
export const HomeRoomSectionCreateDocument = gql`
	mutation homeRoomSectionCreate($input: HomeRoomSectionCreateInput!) {
		createHomeRoomSection(input: $input) {
			...HomeRoomSectionDetail
		}
	}
	${HomeRoomSectionDetailFragmentDoc}
`;
export type HomeRoomSectionCreateMutationFn = Apollo.MutationFunction<
	HomeRoomSectionCreateMutation,
	HomeRoomSectionCreateMutationVariables
>;

/**
 * __useHomeRoomSectionCreateMutation__
 *
 * To run a mutation, you first call `useHomeRoomSectionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHomeRoomSectionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [homeRoomSectionCreateMutation, { data, loading, error }] = useHomeRoomSectionCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHomeRoomSectionCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<HomeRoomSectionCreateMutation, HomeRoomSectionCreateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<HomeRoomSectionCreateMutation, HomeRoomSectionCreateMutationVariables>(
		HomeRoomSectionCreateDocument,
		options,
	);
}
export type HomeRoomSectionCreateMutationHookResult = ReturnType<typeof useHomeRoomSectionCreateMutation>;
export type HomeRoomSectionCreateMutationResult = Apollo.MutationResult<HomeRoomSectionCreateMutation>;
export type HomeRoomSectionCreateMutationOptions = Apollo.BaseMutationOptions<
	HomeRoomSectionCreateMutation,
	HomeRoomSectionCreateMutationVariables
>;
export const HomeRoomSectionDeleteDocument = gql`
	mutation homeRoomSectionDelete($id: ID!) {
		deleteHomeRoomSection(id: $id)
	}
`;
export type HomeRoomSectionDeleteMutationFn = Apollo.MutationFunction<
	HomeRoomSectionDeleteMutation,
	HomeRoomSectionDeleteMutationVariables
>;

/**
 * __useHomeRoomSectionDeleteMutation__
 *
 * To run a mutation, you first call `useHomeRoomSectionDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHomeRoomSectionDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [homeRoomSectionDeleteMutation, { data, loading, error }] = useHomeRoomSectionDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHomeRoomSectionDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<HomeRoomSectionDeleteMutation, HomeRoomSectionDeleteMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<HomeRoomSectionDeleteMutation, HomeRoomSectionDeleteMutationVariables>(
		HomeRoomSectionDeleteDocument,
		options,
	);
}
export type HomeRoomSectionDeleteMutationHookResult = ReturnType<typeof useHomeRoomSectionDeleteMutation>;
export type HomeRoomSectionDeleteMutationResult = Apollo.MutationResult<HomeRoomSectionDeleteMutation>;
export type HomeRoomSectionDeleteMutationOptions = Apollo.BaseMutationOptions<
	HomeRoomSectionDeleteMutation,
	HomeRoomSectionDeleteMutationVariables
>;
export const HomeRoomSectionDetailDocument = gql`
	query homeRoomSectionDetail($id: ID!) {
		homeRoomSection(id: $id) {
			...HomeRoomSectionDetail
		}
	}
	${HomeRoomSectionDetailFragmentDoc}
`;

/**
 * __useHomeRoomSectionDetailQuery__
 *
 * To run a query within a React component, call `useHomeRoomSectionDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeRoomSectionDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeRoomSectionDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHomeRoomSectionDetailQuery(
	baseOptions: Apollo.QueryHookOptions<HomeRoomSectionDetailQuery, HomeRoomSectionDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<HomeRoomSectionDetailQuery, HomeRoomSectionDetailQueryVariables>(
		HomeRoomSectionDetailDocument,
		options,
	);
}
export function useHomeRoomSectionDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<HomeRoomSectionDetailQuery, HomeRoomSectionDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<HomeRoomSectionDetailQuery, HomeRoomSectionDetailQueryVariables>(
		HomeRoomSectionDetailDocument,
		options,
	);
}
export type HomeRoomSectionDetailQueryHookResult = ReturnType<typeof useHomeRoomSectionDetailQuery>;
export type HomeRoomSectionDetailLazyQueryHookResult = ReturnType<typeof useHomeRoomSectionDetailLazyQuery>;
export type HomeRoomSectionDetailQueryResult = Apollo.QueryResult<
	HomeRoomSectionDetailQuery,
	HomeRoomSectionDetailQueryVariables
>;
export const HomeRoomSectionIndexDocument = gql`
	query homeRoomSectionIndex(
		$filters: [HomeRoomSectionFilterInput!]
		$pagination: Pagination
		$order: HomeRoomSectionOrder
	) {
		homeRoomSectionConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					name
					description
					term {
						id
						name
					}
				}
			}
		}
	}
`;

/**
 * __useHomeRoomSectionIndexQuery__
 *
 * To run a query within a React component, call `useHomeRoomSectionIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeRoomSectionIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeRoomSectionIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useHomeRoomSectionIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<HomeRoomSectionIndexQuery, HomeRoomSectionIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<HomeRoomSectionIndexQuery, HomeRoomSectionIndexQueryVariables>(
		HomeRoomSectionIndexDocument,
		options,
	);
}
export function useHomeRoomSectionIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<HomeRoomSectionIndexQuery, HomeRoomSectionIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<HomeRoomSectionIndexQuery, HomeRoomSectionIndexQueryVariables>(
		HomeRoomSectionIndexDocument,
		options,
	);
}
export type HomeRoomSectionIndexQueryHookResult = ReturnType<typeof useHomeRoomSectionIndexQuery>;
export type HomeRoomSectionIndexLazyQueryHookResult = ReturnType<typeof useHomeRoomSectionIndexLazyQuery>;
export type HomeRoomSectionIndexQueryResult = Apollo.QueryResult<
	HomeRoomSectionIndexQuery,
	HomeRoomSectionIndexQueryVariables
>;
export const HomeRoomSectionNameDocument = gql`
	query homeRoomSectionName($id: ID!) {
		homeRoomSection(id: $id) {
			id
			...HomeRoomSectionName
		}
	}
	${HomeRoomSectionNameFragmentDoc}
`;

/**
 * __useHomeRoomSectionNameQuery__
 *
 * To run a query within a React component, call `useHomeRoomSectionNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeRoomSectionNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeRoomSectionNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHomeRoomSectionNameQuery(
	baseOptions: Apollo.QueryHookOptions<HomeRoomSectionNameQuery, HomeRoomSectionNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<HomeRoomSectionNameQuery, HomeRoomSectionNameQueryVariables>(
		HomeRoomSectionNameDocument,
		options,
	);
}
export function useHomeRoomSectionNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<HomeRoomSectionNameQuery, HomeRoomSectionNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<HomeRoomSectionNameQuery, HomeRoomSectionNameQueryVariables>(
		HomeRoomSectionNameDocument,
		options,
	);
}
export type HomeRoomSectionNameQueryHookResult = ReturnType<typeof useHomeRoomSectionNameQuery>;
export type HomeRoomSectionNameLazyQueryHookResult = ReturnType<typeof useHomeRoomSectionNameLazyQuery>;
export type HomeRoomSectionNameQueryResult = Apollo.QueryResult<
	HomeRoomSectionNameQuery,
	HomeRoomSectionNameQueryVariables
>;
export const HomeRoomSectionNamesDocument = gql`
	query homeRoomSectionNames(
		$filters: [HomeRoomSectionFilterInput!]
		$pagination: Pagination
		$order: HomeRoomSectionOrder
	) {
		homeRoomSectionConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...HomeRoomSectionName
				}
			}
		}
	}
	${HomeRoomSectionNameFragmentDoc}
`;

/**
 * __useHomeRoomSectionNamesQuery__
 *
 * To run a query within a React component, call `useHomeRoomSectionNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeRoomSectionNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeRoomSectionNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useHomeRoomSectionNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<HomeRoomSectionNamesQuery, HomeRoomSectionNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<HomeRoomSectionNamesQuery, HomeRoomSectionNamesQueryVariables>(
		HomeRoomSectionNamesDocument,
		options,
	);
}
export function useHomeRoomSectionNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<HomeRoomSectionNamesQuery, HomeRoomSectionNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<HomeRoomSectionNamesQuery, HomeRoomSectionNamesQueryVariables>(
		HomeRoomSectionNamesDocument,
		options,
	);
}
export type HomeRoomSectionNamesQueryHookResult = ReturnType<typeof useHomeRoomSectionNamesQuery>;
export type HomeRoomSectionNamesLazyQueryHookResult = ReturnType<typeof useHomeRoomSectionNamesLazyQuery>;
export type HomeRoomSectionNamesQueryResult = Apollo.QueryResult<
	HomeRoomSectionNamesQuery,
	HomeRoomSectionNamesQueryVariables
>;
export const HomeRoomSectionPaginationDocument = gql`
	query homeRoomSectionPagination($filters: [HomeRoomSectionFilterInput!], $order: HomeRoomSectionOrder) {
		homeRoomSectionConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useHomeRoomSectionPaginationQuery__
 *
 * To run a query within a React component, call `useHomeRoomSectionPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeRoomSectionPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeRoomSectionPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useHomeRoomSectionPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<HomeRoomSectionPaginationQuery, HomeRoomSectionPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<HomeRoomSectionPaginationQuery, HomeRoomSectionPaginationQueryVariables>(
		HomeRoomSectionPaginationDocument,
		options,
	);
}
export function useHomeRoomSectionPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<HomeRoomSectionPaginationQuery, HomeRoomSectionPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<HomeRoomSectionPaginationQuery, HomeRoomSectionPaginationQueryVariables>(
		HomeRoomSectionPaginationDocument,
		options,
	);
}
export type HomeRoomSectionPaginationQueryHookResult = ReturnType<typeof useHomeRoomSectionPaginationQuery>;
export type HomeRoomSectionPaginationLazyQueryHookResult = ReturnType<typeof useHomeRoomSectionPaginationLazyQuery>;
export type HomeRoomSectionPaginationQueryResult = Apollo.QueryResult<
	HomeRoomSectionPaginationQuery,
	HomeRoomSectionPaginationQueryVariables
>;
export const HomeRoomSectionPeopleListDocument = gql`
	query homeRoomSectionPeopleList(
		$filters: [HomeRoomSectionFilterInput!]
		$pagination: Pagination
		$order: HomeRoomSectionOrder
	) {
		homeRoomSectionConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					name
					description
					homeRoomSectionTeacherEnrollments {
						id
						teacher {
							id
							person {
								id
								name
								imageURL
							}
						}
					}
					homeRoomSectionStudentEnrollments {
						id
						student {
							id
							person {
								id
								name
								imageURL
							}
						}
					}
				}
			}
		}
	}
`;

/**
 * __useHomeRoomSectionPeopleListQuery__
 *
 * To run a query within a React component, call `useHomeRoomSectionPeopleListQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeRoomSectionPeopleListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeRoomSectionPeopleListQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useHomeRoomSectionPeopleListQuery(
	baseOptions?: Apollo.QueryHookOptions<HomeRoomSectionPeopleListQuery, HomeRoomSectionPeopleListQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<HomeRoomSectionPeopleListQuery, HomeRoomSectionPeopleListQueryVariables>(
		HomeRoomSectionPeopleListDocument,
		options,
	);
}
export function useHomeRoomSectionPeopleListLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<HomeRoomSectionPeopleListQuery, HomeRoomSectionPeopleListQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<HomeRoomSectionPeopleListQuery, HomeRoomSectionPeopleListQueryVariables>(
		HomeRoomSectionPeopleListDocument,
		options,
	);
}
export type HomeRoomSectionPeopleListQueryHookResult = ReturnType<typeof useHomeRoomSectionPeopleListQuery>;
export type HomeRoomSectionPeopleListLazyQueryHookResult = ReturnType<typeof useHomeRoomSectionPeopleListLazyQuery>;
export type HomeRoomSectionPeopleListQueryResult = Apollo.QueryResult<
	HomeRoomSectionPeopleListQuery,
	HomeRoomSectionPeopleListQueryVariables
>;
export const HomeRoomSectionSelectDocument = gql`
	query homeRoomSectionSelect($filters: [HomeRoomSectionFilterInput!], $order: HomeRoomSectionOrder) {
		homeRoomSectionConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...HomeRoomSectionName
				}
			}
		}
	}
	${HomeRoomSectionNameFragmentDoc}
`;

/**
 * __useHomeRoomSectionSelectQuery__
 *
 * To run a query within a React component, call `useHomeRoomSectionSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeRoomSectionSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeRoomSectionSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useHomeRoomSectionSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<HomeRoomSectionSelectQuery, HomeRoomSectionSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<HomeRoomSectionSelectQuery, HomeRoomSectionSelectQueryVariables>(
		HomeRoomSectionSelectDocument,
		options,
	);
}
export function useHomeRoomSectionSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<HomeRoomSectionSelectQuery, HomeRoomSectionSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<HomeRoomSectionSelectQuery, HomeRoomSectionSelectQueryVariables>(
		HomeRoomSectionSelectDocument,
		options,
	);
}
export type HomeRoomSectionSelectQueryHookResult = ReturnType<typeof useHomeRoomSectionSelectQuery>;
export type HomeRoomSectionSelectLazyQueryHookResult = ReturnType<typeof useHomeRoomSectionSelectLazyQuery>;
export type HomeRoomSectionSelectQueryResult = Apollo.QueryResult<
	HomeRoomSectionSelectQuery,
	HomeRoomSectionSelectQueryVariables
>;
export const HomeRoomSectionUpdateDocument = gql`
	mutation homeRoomSectionUpdate($id: ID!, $input: HomeRoomSectionUpdateInput!) {
		updateHomeRoomSection(id: $id, input: $input) {
			...HomeRoomSectionDetail
		}
	}
	${HomeRoomSectionDetailFragmentDoc}
`;
export type HomeRoomSectionUpdateMutationFn = Apollo.MutationFunction<
	HomeRoomSectionUpdateMutation,
	HomeRoomSectionUpdateMutationVariables
>;

/**
 * __useHomeRoomSectionUpdateMutation__
 *
 * To run a mutation, you first call `useHomeRoomSectionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHomeRoomSectionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [homeRoomSectionUpdateMutation, { data, loading, error }] = useHomeRoomSectionUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHomeRoomSectionUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<HomeRoomSectionUpdateMutation, HomeRoomSectionUpdateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<HomeRoomSectionUpdateMutation, HomeRoomSectionUpdateMutationVariables>(
		HomeRoomSectionUpdateDocument,
		options,
	);
}
export type HomeRoomSectionUpdateMutationHookResult = ReturnType<typeof useHomeRoomSectionUpdateMutation>;
export type HomeRoomSectionUpdateMutationResult = Apollo.MutationResult<HomeRoomSectionUpdateMutation>;
export type HomeRoomSectionUpdateMutationOptions = Apollo.BaseMutationOptions<
	HomeRoomSectionUpdateMutation,
	HomeRoomSectionUpdateMutationVariables
>;
export const HomeRoomSectionWithHomeRoomListDocument = gql`
	query homeRoomSectionWithHomeRoomList(
		$filters: [HomeRoomSectionFilterInput!]
		$pagination: Pagination
		$order: HomeRoomSectionOrder
	) {
		homeRoomSectionConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					name
					description
					homeRoom {
						id
						name
					}
				}
			}
		}
	}
`;

/**
 * __useHomeRoomSectionWithHomeRoomListQuery__
 *
 * To run a query within a React component, call `useHomeRoomSectionWithHomeRoomListQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeRoomSectionWithHomeRoomListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeRoomSectionWithHomeRoomListQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useHomeRoomSectionWithHomeRoomListQuery(
	baseOptions?: Apollo.QueryHookOptions<
		HomeRoomSectionWithHomeRoomListQuery,
		HomeRoomSectionWithHomeRoomListQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<HomeRoomSectionWithHomeRoomListQuery, HomeRoomSectionWithHomeRoomListQueryVariables>(
		HomeRoomSectionWithHomeRoomListDocument,
		options,
	);
}
export function useHomeRoomSectionWithHomeRoomListLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		HomeRoomSectionWithHomeRoomListQuery,
		HomeRoomSectionWithHomeRoomListQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<HomeRoomSectionWithHomeRoomListQuery, HomeRoomSectionWithHomeRoomListQueryVariables>(
		HomeRoomSectionWithHomeRoomListDocument,
		options,
	);
}
export type HomeRoomSectionWithHomeRoomListQueryHookResult = ReturnType<typeof useHomeRoomSectionWithHomeRoomListQuery>;
export type HomeRoomSectionWithHomeRoomListLazyQueryHookResult = ReturnType<
	typeof useHomeRoomSectionWithHomeRoomListLazyQuery
>;
export type HomeRoomSectionWithHomeRoomListQueryResult = Apollo.QueryResult<
	HomeRoomSectionWithHomeRoomListQuery,
	HomeRoomSectionWithHomeRoomListQueryVariables
>;
export const InvoiceCheckUniqueInvoiceNumberDocument = gql`
	query invoiceCheckUniqueInvoiceNumber($ignoreID: ID, $invoiceNumber: String!) {
		invoiceConnection(filters: { idNEQ: $ignoreID, invoiceNumberEQ: $invoiceNumber }) {
			totalCount
		}
	}
`;

/**
 * __useInvoiceCheckUniqueInvoiceNumberQuery__
 *
 * To run a query within a React component, call `useInvoiceCheckUniqueInvoiceNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceCheckUniqueInvoiceNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceCheckUniqueInvoiceNumberQuery({
 *   variables: {
 *      ignoreID: // value for 'ignoreID'
 *      invoiceNumber: // value for 'invoiceNumber'
 *   },
 * });
 */
export function useInvoiceCheckUniqueInvoiceNumberQuery(
	baseOptions: Apollo.QueryHookOptions<
		InvoiceCheckUniqueInvoiceNumberQuery,
		InvoiceCheckUniqueInvoiceNumberQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<InvoiceCheckUniqueInvoiceNumberQuery, InvoiceCheckUniqueInvoiceNumberQueryVariables>(
		InvoiceCheckUniqueInvoiceNumberDocument,
		options,
	);
}
export function useInvoiceCheckUniqueInvoiceNumberLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		InvoiceCheckUniqueInvoiceNumberQuery,
		InvoiceCheckUniqueInvoiceNumberQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<InvoiceCheckUniqueInvoiceNumberQuery, InvoiceCheckUniqueInvoiceNumberQueryVariables>(
		InvoiceCheckUniqueInvoiceNumberDocument,
		options,
	);
}
export type InvoiceCheckUniqueInvoiceNumberQueryHookResult = ReturnType<typeof useInvoiceCheckUniqueInvoiceNumberQuery>;
export type InvoiceCheckUniqueInvoiceNumberLazyQueryHookResult = ReturnType<
	typeof useInvoiceCheckUniqueInvoiceNumberLazyQuery
>;
export type InvoiceCheckUniqueInvoiceNumberQueryResult = Apollo.QueryResult<
	InvoiceCheckUniqueInvoiceNumberQuery,
	InvoiceCheckUniqueInvoiceNumberQueryVariables
>;
export const InvoiceCountDocument = gql`
	query invoiceCount($filters: [InvoiceFilterInput!], $pagination: Pagination, $order: InvoiceOrder) {
		invoiceConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useInvoiceCountQuery__
 *
 * To run a query within a React component, call `useInvoiceCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useInvoiceCountQuery(
	baseOptions?: Apollo.QueryHookOptions<InvoiceCountQuery, InvoiceCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<InvoiceCountQuery, InvoiceCountQueryVariables>(InvoiceCountDocument, options);
}
export function useInvoiceCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<InvoiceCountQuery, InvoiceCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<InvoiceCountQuery, InvoiceCountQueryVariables>(InvoiceCountDocument, options);
}
export type InvoiceCountQueryHookResult = ReturnType<typeof useInvoiceCountQuery>;
export type InvoiceCountLazyQueryHookResult = ReturnType<typeof useInvoiceCountLazyQuery>;
export type InvoiceCountQueryResult = Apollo.QueryResult<InvoiceCountQuery, InvoiceCountQueryVariables>;
export const InvoiceCreateDocument = gql`
	mutation invoiceCreate($input: InvoiceCreateInput!) {
		createInvoice(input: $input) {
			...InvoiceDetail
		}
	}
	${InvoiceDetailFragmentDoc}
`;
export type InvoiceCreateMutationFn = Apollo.MutationFunction<InvoiceCreateMutation, InvoiceCreateMutationVariables>;

/**
 * __useInvoiceCreateMutation__
 *
 * To run a mutation, you first call `useInvoiceCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvoiceCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invoiceCreateMutation, { data, loading, error }] = useInvoiceCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInvoiceCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<InvoiceCreateMutation, InvoiceCreateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<InvoiceCreateMutation, InvoiceCreateMutationVariables>(InvoiceCreateDocument, options);
}
export type InvoiceCreateMutationHookResult = ReturnType<typeof useInvoiceCreateMutation>;
export type InvoiceCreateMutationResult = Apollo.MutationResult<InvoiceCreateMutation>;
export type InvoiceCreateMutationOptions = Apollo.BaseMutationOptions<
	InvoiceCreateMutation,
	InvoiceCreateMutationVariables
>;
export const InvoiceDeleteDocument = gql`
	mutation invoiceDelete($id: ID!) {
		deleteInvoice(id: $id)
	}
`;
export type InvoiceDeleteMutationFn = Apollo.MutationFunction<InvoiceDeleteMutation, InvoiceDeleteMutationVariables>;

/**
 * __useInvoiceDeleteMutation__
 *
 * To run a mutation, you first call `useInvoiceDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvoiceDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invoiceDeleteMutation, { data, loading, error }] = useInvoiceDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInvoiceDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<InvoiceDeleteMutation, InvoiceDeleteMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<InvoiceDeleteMutation, InvoiceDeleteMutationVariables>(InvoiceDeleteDocument, options);
}
export type InvoiceDeleteMutationHookResult = ReturnType<typeof useInvoiceDeleteMutation>;
export type InvoiceDeleteMutationResult = Apollo.MutationResult<InvoiceDeleteMutation>;
export type InvoiceDeleteMutationOptions = Apollo.BaseMutationOptions<
	InvoiceDeleteMutation,
	InvoiceDeleteMutationVariables
>;
export const InvoiceDetailDocument = gql`
	query invoiceDetail($id: ID!) {
		invoice(id: $id) {
			...InvoiceDetail
		}
	}
	${InvoiceDetailFragmentDoc}
`;

/**
 * __useInvoiceDetailQuery__
 *
 * To run a query within a React component, call `useInvoiceDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInvoiceDetailQuery(
	baseOptions: Apollo.QueryHookOptions<InvoiceDetailQuery, InvoiceDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<InvoiceDetailQuery, InvoiceDetailQueryVariables>(InvoiceDetailDocument, options);
}
export function useInvoiceDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<InvoiceDetailQuery, InvoiceDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<InvoiceDetailQuery, InvoiceDetailQueryVariables>(InvoiceDetailDocument, options);
}
export type InvoiceDetailQueryHookResult = ReturnType<typeof useInvoiceDetailQuery>;
export type InvoiceDetailLazyQueryHookResult = ReturnType<typeof useInvoiceDetailLazyQuery>;
export type InvoiceDetailQueryResult = Apollo.QueryResult<InvoiceDetailQuery, InvoiceDetailQueryVariables>;
export const InvoiceIndexDocument = gql`
	query invoiceIndex($filters: [InvoiceFilterInput!], $pagination: Pagination, $order: InvoiceOrder) {
		invoiceConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					invoiceNumber
					createdDate
					dueDate
					description
				}
			}
		}
	}
`;

/**
 * __useInvoiceIndexQuery__
 *
 * To run a query within a React component, call `useInvoiceIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useInvoiceIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<InvoiceIndexQuery, InvoiceIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<InvoiceIndexQuery, InvoiceIndexQueryVariables>(InvoiceIndexDocument, options);
}
export function useInvoiceIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<InvoiceIndexQuery, InvoiceIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<InvoiceIndexQuery, InvoiceIndexQueryVariables>(InvoiceIndexDocument, options);
}
export type InvoiceIndexQueryHookResult = ReturnType<typeof useInvoiceIndexQuery>;
export type InvoiceIndexLazyQueryHookResult = ReturnType<typeof useInvoiceIndexLazyQuery>;
export type InvoiceIndexQueryResult = Apollo.QueryResult<InvoiceIndexQuery, InvoiceIndexQueryVariables>;
export const InvoiceNameDocument = gql`
	query invoiceName($id: ID!) {
		invoice(id: $id) {
			id
			...InvoiceName
		}
	}
	${InvoiceNameFragmentDoc}
`;

/**
 * __useInvoiceNameQuery__
 *
 * To run a query within a React component, call `useInvoiceNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInvoiceNameQuery(baseOptions: Apollo.QueryHookOptions<InvoiceNameQuery, InvoiceNameQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<InvoiceNameQuery, InvoiceNameQueryVariables>(InvoiceNameDocument, options);
}
export function useInvoiceNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<InvoiceNameQuery, InvoiceNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<InvoiceNameQuery, InvoiceNameQueryVariables>(InvoiceNameDocument, options);
}
export type InvoiceNameQueryHookResult = ReturnType<typeof useInvoiceNameQuery>;
export type InvoiceNameLazyQueryHookResult = ReturnType<typeof useInvoiceNameLazyQuery>;
export type InvoiceNameQueryResult = Apollo.QueryResult<InvoiceNameQuery, InvoiceNameQueryVariables>;
export const InvoiceNamesDocument = gql`
	query invoiceNames($filters: [InvoiceFilterInput!], $pagination: Pagination, $order: InvoiceOrder) {
		invoiceConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...InvoiceName
				}
			}
		}
	}
	${InvoiceNameFragmentDoc}
`;

/**
 * __useInvoiceNamesQuery__
 *
 * To run a query within a React component, call `useInvoiceNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useInvoiceNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<InvoiceNamesQuery, InvoiceNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<InvoiceNamesQuery, InvoiceNamesQueryVariables>(InvoiceNamesDocument, options);
}
export function useInvoiceNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<InvoiceNamesQuery, InvoiceNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<InvoiceNamesQuery, InvoiceNamesQueryVariables>(InvoiceNamesDocument, options);
}
export type InvoiceNamesQueryHookResult = ReturnType<typeof useInvoiceNamesQuery>;
export type InvoiceNamesLazyQueryHookResult = ReturnType<typeof useInvoiceNamesLazyQuery>;
export type InvoiceNamesQueryResult = Apollo.QueryResult<InvoiceNamesQuery, InvoiceNamesQueryVariables>;
export const InvoicePaginationDocument = gql`
	query invoicePagination($filters: [InvoiceFilterInput!], $order: InvoiceOrder) {
		invoiceConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useInvoicePaginationQuery__
 *
 * To run a query within a React component, call `useInvoicePaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoicePaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoicePaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useInvoicePaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<InvoicePaginationQuery, InvoicePaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<InvoicePaginationQuery, InvoicePaginationQueryVariables>(InvoicePaginationDocument, options);
}
export function useInvoicePaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<InvoicePaginationQuery, InvoicePaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<InvoicePaginationQuery, InvoicePaginationQueryVariables>(
		InvoicePaginationDocument,
		options,
	);
}
export type InvoicePaginationQueryHookResult = ReturnType<typeof useInvoicePaginationQuery>;
export type InvoicePaginationLazyQueryHookResult = ReturnType<typeof useInvoicePaginationLazyQuery>;
export type InvoicePaginationQueryResult = Apollo.QueryResult<InvoicePaginationQuery, InvoicePaginationQueryVariables>;
export const InvoiceSelectDocument = gql`
	query invoiceSelect($filters: [InvoiceFilterInput!], $order: InvoiceOrder) {
		invoiceConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...InvoiceName
					dueDate
					invoiceLineItems {
						id
						amount
					}
				}
			}
		}
	}
	${InvoiceNameFragmentDoc}
`;

/**
 * __useInvoiceSelectQuery__
 *
 * To run a query within a React component, call `useInvoiceSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useInvoiceSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<InvoiceSelectQuery, InvoiceSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<InvoiceSelectQuery, InvoiceSelectQueryVariables>(InvoiceSelectDocument, options);
}
export function useInvoiceSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<InvoiceSelectQuery, InvoiceSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<InvoiceSelectQuery, InvoiceSelectQueryVariables>(InvoiceSelectDocument, options);
}
export type InvoiceSelectQueryHookResult = ReturnType<typeof useInvoiceSelectQuery>;
export type InvoiceSelectLazyQueryHookResult = ReturnType<typeof useInvoiceSelectLazyQuery>;
export type InvoiceSelectQueryResult = Apollo.QueryResult<InvoiceSelectQuery, InvoiceSelectQueryVariables>;
export const StudentInvoicesListDocument = gql`
	query studentInvoicesList($filters: [InvoiceFilterInput!], $pagination: Pagination, $order: InvoiceOrder) {
		invoiceConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					invoiceNumber
					createdDate
					dueDate
					contractInstallment {
						id
						installmentDate
						portion
						contract {
							id
							agreementDate
							status
						}
					}
					invoiceLineItems {
						id
						amount
					}
					paymentLineItems {
						id
						amount
						payment {
							id
							createdDate
						}
					}
				}
			}
		}
	}
`;

/**
 * __useStudentInvoicesListQuery__
 *
 * To run a query within a React component, call `useStudentInvoicesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentInvoicesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentInvoicesListQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useStudentInvoicesListQuery(
	baseOptions?: Apollo.QueryHookOptions<StudentInvoicesListQuery, StudentInvoicesListQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<StudentInvoicesListQuery, StudentInvoicesListQueryVariables>(
		StudentInvoicesListDocument,
		options,
	);
}
export function useStudentInvoicesListLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<StudentInvoicesListQuery, StudentInvoicesListQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<StudentInvoicesListQuery, StudentInvoicesListQueryVariables>(
		StudentInvoicesListDocument,
		options,
	);
}
export type StudentInvoicesListQueryHookResult = ReturnType<typeof useStudentInvoicesListQuery>;
export type StudentInvoicesListLazyQueryHookResult = ReturnType<typeof useStudentInvoicesListLazyQuery>;
export type StudentInvoicesListQueryResult = Apollo.QueryResult<
	StudentInvoicesListQuery,
	StudentInvoicesListQueryVariables
>;
export const InvoiceUpdateDocument = gql`
	mutation invoiceUpdate($id: ID!, $input: InvoiceUpdateInput!) {
		updateInvoice(id: $id, input: $input) {
			...InvoiceDetail
		}
	}
	${InvoiceDetailFragmentDoc}
`;
export type InvoiceUpdateMutationFn = Apollo.MutationFunction<InvoiceUpdateMutation, InvoiceUpdateMutationVariables>;

/**
 * __useInvoiceUpdateMutation__
 *
 * To run a mutation, you first call `useInvoiceUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvoiceUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invoiceUpdateMutation, { data, loading, error }] = useInvoiceUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInvoiceUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<InvoiceUpdateMutation, InvoiceUpdateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<InvoiceUpdateMutation, InvoiceUpdateMutationVariables>(InvoiceUpdateDocument, options);
}
export type InvoiceUpdateMutationHookResult = ReturnType<typeof useInvoiceUpdateMutation>;
export type InvoiceUpdateMutationResult = Apollo.MutationResult<InvoiceUpdateMutation>;
export type InvoiceUpdateMutationOptions = Apollo.BaseMutationOptions<
	InvoiceUpdateMutation,
	InvoiceUpdateMutationVariables
>;
export const InvoiceLineItemCountDocument = gql`
	query invoiceLineItemCount(
		$filters: [InvoiceLineItemFilterInput!]
		$pagination: Pagination
		$order: InvoiceLineItemOrder
	) {
		invoiceLineItemConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useInvoiceLineItemCountQuery__
 *
 * To run a query within a React component, call `useInvoiceLineItemCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceLineItemCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceLineItemCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useInvoiceLineItemCountQuery(
	baseOptions?: Apollo.QueryHookOptions<InvoiceLineItemCountQuery, InvoiceLineItemCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<InvoiceLineItemCountQuery, InvoiceLineItemCountQueryVariables>(
		InvoiceLineItemCountDocument,
		options,
	);
}
export function useInvoiceLineItemCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<InvoiceLineItemCountQuery, InvoiceLineItemCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<InvoiceLineItemCountQuery, InvoiceLineItemCountQueryVariables>(
		InvoiceLineItemCountDocument,
		options,
	);
}
export type InvoiceLineItemCountQueryHookResult = ReturnType<typeof useInvoiceLineItemCountQuery>;
export type InvoiceLineItemCountLazyQueryHookResult = ReturnType<typeof useInvoiceLineItemCountLazyQuery>;
export type InvoiceLineItemCountQueryResult = Apollo.QueryResult<
	InvoiceLineItemCountQuery,
	InvoiceLineItemCountQueryVariables
>;
export const InvoiceLineItemCreateDocument = gql`
	mutation invoiceLineItemCreate($input: InvoiceLineItemCreateInput!) {
		createInvoiceLineItem(input: $input) {
			...InvoiceLineItemDetail
		}
	}
	${InvoiceLineItemDetailFragmentDoc}
`;
export type InvoiceLineItemCreateMutationFn = Apollo.MutationFunction<
	InvoiceLineItemCreateMutation,
	InvoiceLineItemCreateMutationVariables
>;

/**
 * __useInvoiceLineItemCreateMutation__
 *
 * To run a mutation, you first call `useInvoiceLineItemCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvoiceLineItemCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invoiceLineItemCreateMutation, { data, loading, error }] = useInvoiceLineItemCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInvoiceLineItemCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<InvoiceLineItemCreateMutation, InvoiceLineItemCreateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<InvoiceLineItemCreateMutation, InvoiceLineItemCreateMutationVariables>(
		InvoiceLineItemCreateDocument,
		options,
	);
}
export type InvoiceLineItemCreateMutationHookResult = ReturnType<typeof useInvoiceLineItemCreateMutation>;
export type InvoiceLineItemCreateMutationResult = Apollo.MutationResult<InvoiceLineItemCreateMutation>;
export type InvoiceLineItemCreateMutationOptions = Apollo.BaseMutationOptions<
	InvoiceLineItemCreateMutation,
	InvoiceLineItemCreateMutationVariables
>;
export const InvoiceLineItemDeleteDocument = gql`
	mutation invoiceLineItemDelete($id: ID!) {
		deleteInvoiceLineItem(id: $id)
	}
`;
export type InvoiceLineItemDeleteMutationFn = Apollo.MutationFunction<
	InvoiceLineItemDeleteMutation,
	InvoiceLineItemDeleteMutationVariables
>;

/**
 * __useInvoiceLineItemDeleteMutation__
 *
 * To run a mutation, you first call `useInvoiceLineItemDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvoiceLineItemDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invoiceLineItemDeleteMutation, { data, loading, error }] = useInvoiceLineItemDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInvoiceLineItemDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<InvoiceLineItemDeleteMutation, InvoiceLineItemDeleteMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<InvoiceLineItemDeleteMutation, InvoiceLineItemDeleteMutationVariables>(
		InvoiceLineItemDeleteDocument,
		options,
	);
}
export type InvoiceLineItemDeleteMutationHookResult = ReturnType<typeof useInvoiceLineItemDeleteMutation>;
export type InvoiceLineItemDeleteMutationResult = Apollo.MutationResult<InvoiceLineItemDeleteMutation>;
export type InvoiceLineItemDeleteMutationOptions = Apollo.BaseMutationOptions<
	InvoiceLineItemDeleteMutation,
	InvoiceLineItemDeleteMutationVariables
>;
export const InvoiceLineItemDetailDocument = gql`
	query invoiceLineItemDetail($id: ID!) {
		invoiceLineItem(id: $id) {
			...InvoiceLineItemDetail
		}
	}
	${InvoiceLineItemDetailFragmentDoc}
`;

/**
 * __useInvoiceLineItemDetailQuery__
 *
 * To run a query within a React component, call `useInvoiceLineItemDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceLineItemDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceLineItemDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInvoiceLineItemDetailQuery(
	baseOptions: Apollo.QueryHookOptions<InvoiceLineItemDetailQuery, InvoiceLineItemDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<InvoiceLineItemDetailQuery, InvoiceLineItemDetailQueryVariables>(
		InvoiceLineItemDetailDocument,
		options,
	);
}
export function useInvoiceLineItemDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<InvoiceLineItemDetailQuery, InvoiceLineItemDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<InvoiceLineItemDetailQuery, InvoiceLineItemDetailQueryVariables>(
		InvoiceLineItemDetailDocument,
		options,
	);
}
export type InvoiceLineItemDetailQueryHookResult = ReturnType<typeof useInvoiceLineItemDetailQuery>;
export type InvoiceLineItemDetailLazyQueryHookResult = ReturnType<typeof useInvoiceLineItemDetailLazyQuery>;
export type InvoiceLineItemDetailQueryResult = Apollo.QueryResult<
	InvoiceLineItemDetailQuery,
	InvoiceLineItemDetailQueryVariables
>;
export const InvoiceLineItemIndexDocument = gql`
	query invoiceLineItemIndex(
		$filters: [InvoiceLineItemFilterInput!]
		$pagination: Pagination
		$order: InvoiceLineItemOrder
	) {
		invoiceLineItemConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					lineItemNumber
					amount
					description
				}
			}
		}
	}
`;

/**
 * __useInvoiceLineItemIndexQuery__
 *
 * To run a query within a React component, call `useInvoiceLineItemIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceLineItemIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceLineItemIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useInvoiceLineItemIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<InvoiceLineItemIndexQuery, InvoiceLineItemIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<InvoiceLineItemIndexQuery, InvoiceLineItemIndexQueryVariables>(
		InvoiceLineItemIndexDocument,
		options,
	);
}
export function useInvoiceLineItemIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<InvoiceLineItemIndexQuery, InvoiceLineItemIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<InvoiceLineItemIndexQuery, InvoiceLineItemIndexQueryVariables>(
		InvoiceLineItemIndexDocument,
		options,
	);
}
export type InvoiceLineItemIndexQueryHookResult = ReturnType<typeof useInvoiceLineItemIndexQuery>;
export type InvoiceLineItemIndexLazyQueryHookResult = ReturnType<typeof useInvoiceLineItemIndexLazyQuery>;
export type InvoiceLineItemIndexQueryResult = Apollo.QueryResult<
	InvoiceLineItemIndexQuery,
	InvoiceLineItemIndexQueryVariables
>;
export const InvoiceLineItemNameDocument = gql`
	query invoiceLineItemName($id: ID!) {
		invoiceLineItem(id: $id) {
			id
			...InvoiceLineItemName
		}
	}
	${InvoiceLineItemNameFragmentDoc}
`;

/**
 * __useInvoiceLineItemNameQuery__
 *
 * To run a query within a React component, call `useInvoiceLineItemNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceLineItemNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceLineItemNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInvoiceLineItemNameQuery(
	baseOptions: Apollo.QueryHookOptions<InvoiceLineItemNameQuery, InvoiceLineItemNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<InvoiceLineItemNameQuery, InvoiceLineItemNameQueryVariables>(
		InvoiceLineItemNameDocument,
		options,
	);
}
export function useInvoiceLineItemNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<InvoiceLineItemNameQuery, InvoiceLineItemNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<InvoiceLineItemNameQuery, InvoiceLineItemNameQueryVariables>(
		InvoiceLineItemNameDocument,
		options,
	);
}
export type InvoiceLineItemNameQueryHookResult = ReturnType<typeof useInvoiceLineItemNameQuery>;
export type InvoiceLineItemNameLazyQueryHookResult = ReturnType<typeof useInvoiceLineItemNameLazyQuery>;
export type InvoiceLineItemNameQueryResult = Apollo.QueryResult<
	InvoiceLineItemNameQuery,
	InvoiceLineItemNameQueryVariables
>;
export const InvoiceLineItemNamesDocument = gql`
	query invoiceLineItemNames(
		$filters: [InvoiceLineItemFilterInput!]
		$pagination: Pagination
		$order: InvoiceLineItemOrder
	) {
		invoiceLineItemConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...InvoiceLineItemName
				}
			}
		}
	}
	${InvoiceLineItemNameFragmentDoc}
`;

/**
 * __useInvoiceLineItemNamesQuery__
 *
 * To run a query within a React component, call `useInvoiceLineItemNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceLineItemNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceLineItemNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useInvoiceLineItemNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<InvoiceLineItemNamesQuery, InvoiceLineItemNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<InvoiceLineItemNamesQuery, InvoiceLineItemNamesQueryVariables>(
		InvoiceLineItemNamesDocument,
		options,
	);
}
export function useInvoiceLineItemNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<InvoiceLineItemNamesQuery, InvoiceLineItemNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<InvoiceLineItemNamesQuery, InvoiceLineItemNamesQueryVariables>(
		InvoiceLineItemNamesDocument,
		options,
	);
}
export type InvoiceLineItemNamesQueryHookResult = ReturnType<typeof useInvoiceLineItemNamesQuery>;
export type InvoiceLineItemNamesLazyQueryHookResult = ReturnType<typeof useInvoiceLineItemNamesLazyQuery>;
export type InvoiceLineItemNamesQueryResult = Apollo.QueryResult<
	InvoiceLineItemNamesQuery,
	InvoiceLineItemNamesQueryVariables
>;
export const InvoiceLineItemPaginationDocument = gql`
	query invoiceLineItemPagination($filters: [InvoiceLineItemFilterInput!], $order: InvoiceLineItemOrder) {
		invoiceLineItemConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useInvoiceLineItemPaginationQuery__
 *
 * To run a query within a React component, call `useInvoiceLineItemPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceLineItemPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceLineItemPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useInvoiceLineItemPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<InvoiceLineItemPaginationQuery, InvoiceLineItemPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<InvoiceLineItemPaginationQuery, InvoiceLineItemPaginationQueryVariables>(
		InvoiceLineItemPaginationDocument,
		options,
	);
}
export function useInvoiceLineItemPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<InvoiceLineItemPaginationQuery, InvoiceLineItemPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<InvoiceLineItemPaginationQuery, InvoiceLineItemPaginationQueryVariables>(
		InvoiceLineItemPaginationDocument,
		options,
	);
}
export type InvoiceLineItemPaginationQueryHookResult = ReturnType<typeof useInvoiceLineItemPaginationQuery>;
export type InvoiceLineItemPaginationLazyQueryHookResult = ReturnType<typeof useInvoiceLineItemPaginationLazyQuery>;
export type InvoiceLineItemPaginationQueryResult = Apollo.QueryResult<
	InvoiceLineItemPaginationQuery,
	InvoiceLineItemPaginationQueryVariables
>;
export const InvoiceLineItemSelectDocument = gql`
	query invoiceLineItemSelect($filters: [InvoiceLineItemFilterInput!], $order: InvoiceLineItemOrder) {
		invoiceLineItemConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...InvoiceLineItemName
				}
			}
		}
	}
	${InvoiceLineItemNameFragmentDoc}
`;

/**
 * __useInvoiceLineItemSelectQuery__
 *
 * To run a query within a React component, call `useInvoiceLineItemSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceLineItemSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceLineItemSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useInvoiceLineItemSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<InvoiceLineItemSelectQuery, InvoiceLineItemSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<InvoiceLineItemSelectQuery, InvoiceLineItemSelectQueryVariables>(
		InvoiceLineItemSelectDocument,
		options,
	);
}
export function useInvoiceLineItemSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<InvoiceLineItemSelectQuery, InvoiceLineItemSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<InvoiceLineItemSelectQuery, InvoiceLineItemSelectQueryVariables>(
		InvoiceLineItemSelectDocument,
		options,
	);
}
export type InvoiceLineItemSelectQueryHookResult = ReturnType<typeof useInvoiceLineItemSelectQuery>;
export type InvoiceLineItemSelectLazyQueryHookResult = ReturnType<typeof useInvoiceLineItemSelectLazyQuery>;
export type InvoiceLineItemSelectQueryResult = Apollo.QueryResult<
	InvoiceLineItemSelectQuery,
	InvoiceLineItemSelectQueryVariables
>;
export const InvoiceLineItemUpdateDocument = gql`
	mutation invoiceLineItemUpdate($id: ID!, $input: InvoiceLineItemUpdateInput!) {
		updateInvoiceLineItem(id: $id, input: $input) {
			...InvoiceLineItemDetail
		}
	}
	${InvoiceLineItemDetailFragmentDoc}
`;
export type InvoiceLineItemUpdateMutationFn = Apollo.MutationFunction<
	InvoiceLineItemUpdateMutation,
	InvoiceLineItemUpdateMutationVariables
>;

/**
 * __useInvoiceLineItemUpdateMutation__
 *
 * To run a mutation, you first call `useInvoiceLineItemUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvoiceLineItemUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invoiceLineItemUpdateMutation, { data, loading, error }] = useInvoiceLineItemUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInvoiceLineItemUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<InvoiceLineItemUpdateMutation, InvoiceLineItemUpdateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<InvoiceLineItemUpdateMutation, InvoiceLineItemUpdateMutationVariables>(
		InvoiceLineItemUpdateDocument,
		options,
	);
}
export type InvoiceLineItemUpdateMutationHookResult = ReturnType<typeof useInvoiceLineItemUpdateMutation>;
export type InvoiceLineItemUpdateMutationResult = Apollo.MutationResult<InvoiceLineItemUpdateMutation>;
export type InvoiceLineItemUpdateMutationOptions = Apollo.BaseMutationOptions<
	InvoiceLineItemUpdateMutation,
	InvoiceLineItemUpdateMutationVariables
>;
export const LearningObjectiveCountDocument = gql`
	query learningObjectiveCount(
		$filters: [LearningObjectiveFilterInput!]
		$pagination: Pagination
		$order: LearningObjectiveOrder
	) {
		learningObjectiveConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useLearningObjectiveCountQuery__
 *
 * To run a query within a React component, call `useLearningObjectiveCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useLearningObjectiveCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearningObjectiveCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useLearningObjectiveCountQuery(
	baseOptions?: Apollo.QueryHookOptions<LearningObjectiveCountQuery, LearningObjectiveCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<LearningObjectiveCountQuery, LearningObjectiveCountQueryVariables>(
		LearningObjectiveCountDocument,
		options,
	);
}
export function useLearningObjectiveCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<LearningObjectiveCountQuery, LearningObjectiveCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<LearningObjectiveCountQuery, LearningObjectiveCountQueryVariables>(
		LearningObjectiveCountDocument,
		options,
	);
}
export type LearningObjectiveCountQueryHookResult = ReturnType<typeof useLearningObjectiveCountQuery>;
export type LearningObjectiveCountLazyQueryHookResult = ReturnType<typeof useLearningObjectiveCountLazyQuery>;
export type LearningObjectiveCountQueryResult = Apollo.QueryResult<
	LearningObjectiveCountQuery,
	LearningObjectiveCountQueryVariables
>;
export const LearningObjectiveCreateDocument = gql`
	mutation learningObjectiveCreate($input: LearningObjectiveCreateInput!) {
		createLearningObjective(input: $input) {
			...LearningObjectiveDetail
		}
	}
	${LearningObjectiveDetailFragmentDoc}
`;
export type LearningObjectiveCreateMutationFn = Apollo.MutationFunction<
	LearningObjectiveCreateMutation,
	LearningObjectiveCreateMutationVariables
>;

/**
 * __useLearningObjectiveCreateMutation__
 *
 * To run a mutation, you first call `useLearningObjectiveCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLearningObjectiveCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [learningObjectiveCreateMutation, { data, loading, error }] = useLearningObjectiveCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLearningObjectiveCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<LearningObjectiveCreateMutation, LearningObjectiveCreateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<LearningObjectiveCreateMutation, LearningObjectiveCreateMutationVariables>(
		LearningObjectiveCreateDocument,
		options,
	);
}
export type LearningObjectiveCreateMutationHookResult = ReturnType<typeof useLearningObjectiveCreateMutation>;
export type LearningObjectiveCreateMutationResult = Apollo.MutationResult<LearningObjectiveCreateMutation>;
export type LearningObjectiveCreateMutationOptions = Apollo.BaseMutationOptions<
	LearningObjectiveCreateMutation,
	LearningObjectiveCreateMutationVariables
>;
export const LearningObjectiveDeleteDocument = gql`
	mutation learningObjectiveDelete($id: ID!) {
		deleteLearningObjective(id: $id)
	}
`;
export type LearningObjectiveDeleteMutationFn = Apollo.MutationFunction<
	LearningObjectiveDeleteMutation,
	LearningObjectiveDeleteMutationVariables
>;

/**
 * __useLearningObjectiveDeleteMutation__
 *
 * To run a mutation, you first call `useLearningObjectiveDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLearningObjectiveDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [learningObjectiveDeleteMutation, { data, loading, error }] = useLearningObjectiveDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLearningObjectiveDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<LearningObjectiveDeleteMutation, LearningObjectiveDeleteMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<LearningObjectiveDeleteMutation, LearningObjectiveDeleteMutationVariables>(
		LearningObjectiveDeleteDocument,
		options,
	);
}
export type LearningObjectiveDeleteMutationHookResult = ReturnType<typeof useLearningObjectiveDeleteMutation>;
export type LearningObjectiveDeleteMutationResult = Apollo.MutationResult<LearningObjectiveDeleteMutation>;
export type LearningObjectiveDeleteMutationOptions = Apollo.BaseMutationOptions<
	LearningObjectiveDeleteMutation,
	LearningObjectiveDeleteMutationVariables
>;
export const LearningObjectiveDetailDocument = gql`
	query learningObjectiveDetail($id: ID!) {
		learningObjective(id: $id) {
			...LearningObjectiveDetail
		}
	}
	${LearningObjectiveDetailFragmentDoc}
`;

/**
 * __useLearningObjectiveDetailQuery__
 *
 * To run a query within a React component, call `useLearningObjectiveDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useLearningObjectiveDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearningObjectiveDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLearningObjectiveDetailQuery(
	baseOptions: Apollo.QueryHookOptions<LearningObjectiveDetailQuery, LearningObjectiveDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<LearningObjectiveDetailQuery, LearningObjectiveDetailQueryVariables>(
		LearningObjectiveDetailDocument,
		options,
	);
}
export function useLearningObjectiveDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<LearningObjectiveDetailQuery, LearningObjectiveDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<LearningObjectiveDetailQuery, LearningObjectiveDetailQueryVariables>(
		LearningObjectiveDetailDocument,
		options,
	);
}
export type LearningObjectiveDetailQueryHookResult = ReturnType<typeof useLearningObjectiveDetailQuery>;
export type LearningObjectiveDetailLazyQueryHookResult = ReturnType<typeof useLearningObjectiveDetailLazyQuery>;
export type LearningObjectiveDetailQueryResult = Apollo.QueryResult<
	LearningObjectiveDetailQuery,
	LearningObjectiveDetailQueryVariables
>;
export const LearningObjectiveIndexDocument = gql`
	query learningObjectiveIndex(
		$filters: [LearningObjectiveFilterInput!]
		$pagination: Pagination
		$order: LearningObjectiveOrder
	) {
		learningObjectiveConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					category
					description
					isActive
				}
			}
		}
	}
`;

/**
 * __useLearningObjectiveIndexQuery__
 *
 * To run a query within a React component, call `useLearningObjectiveIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useLearningObjectiveIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearningObjectiveIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useLearningObjectiveIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<LearningObjectiveIndexQuery, LearningObjectiveIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<LearningObjectiveIndexQuery, LearningObjectiveIndexQueryVariables>(
		LearningObjectiveIndexDocument,
		options,
	);
}
export function useLearningObjectiveIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<LearningObjectiveIndexQuery, LearningObjectiveIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<LearningObjectiveIndexQuery, LearningObjectiveIndexQueryVariables>(
		LearningObjectiveIndexDocument,
		options,
	);
}
export type LearningObjectiveIndexQueryHookResult = ReturnType<typeof useLearningObjectiveIndexQuery>;
export type LearningObjectiveIndexLazyQueryHookResult = ReturnType<typeof useLearningObjectiveIndexLazyQuery>;
export type LearningObjectiveIndexQueryResult = Apollo.QueryResult<
	LearningObjectiveIndexQuery,
	LearningObjectiveIndexQueryVariables
>;
export const LearningObjectiveNameDocument = gql`
	query learningObjectiveName($id: ID!) {
		learningObjective(id: $id) {
			id
			...LearningObjectiveName
		}
	}
	${LearningObjectiveNameFragmentDoc}
`;

/**
 * __useLearningObjectiveNameQuery__
 *
 * To run a query within a React component, call `useLearningObjectiveNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useLearningObjectiveNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearningObjectiveNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLearningObjectiveNameQuery(
	baseOptions: Apollo.QueryHookOptions<LearningObjectiveNameQuery, LearningObjectiveNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<LearningObjectiveNameQuery, LearningObjectiveNameQueryVariables>(
		LearningObjectiveNameDocument,
		options,
	);
}
export function useLearningObjectiveNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<LearningObjectiveNameQuery, LearningObjectiveNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<LearningObjectiveNameQuery, LearningObjectiveNameQueryVariables>(
		LearningObjectiveNameDocument,
		options,
	);
}
export type LearningObjectiveNameQueryHookResult = ReturnType<typeof useLearningObjectiveNameQuery>;
export type LearningObjectiveNameLazyQueryHookResult = ReturnType<typeof useLearningObjectiveNameLazyQuery>;
export type LearningObjectiveNameQueryResult = Apollo.QueryResult<
	LearningObjectiveNameQuery,
	LearningObjectiveNameQueryVariables
>;
export const LearningObjectiveNamesDocument = gql`
	query learningObjectiveNames(
		$filters: [LearningObjectiveFilterInput!]
		$pagination: Pagination
		$order: LearningObjectiveOrder
	) {
		learningObjectiveConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...LearningObjectiveName
				}
			}
		}
	}
	${LearningObjectiveNameFragmentDoc}
`;

/**
 * __useLearningObjectiveNamesQuery__
 *
 * To run a query within a React component, call `useLearningObjectiveNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLearningObjectiveNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearningObjectiveNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useLearningObjectiveNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<LearningObjectiveNamesQuery, LearningObjectiveNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<LearningObjectiveNamesQuery, LearningObjectiveNamesQueryVariables>(
		LearningObjectiveNamesDocument,
		options,
	);
}
export function useLearningObjectiveNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<LearningObjectiveNamesQuery, LearningObjectiveNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<LearningObjectiveNamesQuery, LearningObjectiveNamesQueryVariables>(
		LearningObjectiveNamesDocument,
		options,
	);
}
export type LearningObjectiveNamesQueryHookResult = ReturnType<typeof useLearningObjectiveNamesQuery>;
export type LearningObjectiveNamesLazyQueryHookResult = ReturnType<typeof useLearningObjectiveNamesLazyQuery>;
export type LearningObjectiveNamesQueryResult = Apollo.QueryResult<
	LearningObjectiveNamesQuery,
	LearningObjectiveNamesQueryVariables
>;
export const LearningObjectivePaginationDocument = gql`
	query learningObjectivePagination($filters: [LearningObjectiveFilterInput!], $order: LearningObjectiveOrder) {
		learningObjectiveConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useLearningObjectivePaginationQuery__
 *
 * To run a query within a React component, call `useLearningObjectivePaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useLearningObjectivePaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearningObjectivePaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useLearningObjectivePaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<LearningObjectivePaginationQuery, LearningObjectivePaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<LearningObjectivePaginationQuery, LearningObjectivePaginationQueryVariables>(
		LearningObjectivePaginationDocument,
		options,
	);
}
export function useLearningObjectivePaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		LearningObjectivePaginationQuery,
		LearningObjectivePaginationQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<LearningObjectivePaginationQuery, LearningObjectivePaginationQueryVariables>(
		LearningObjectivePaginationDocument,
		options,
	);
}
export type LearningObjectivePaginationQueryHookResult = ReturnType<typeof useLearningObjectivePaginationQuery>;
export type LearningObjectivePaginationLazyQueryHookResult = ReturnType<typeof useLearningObjectivePaginationLazyQuery>;
export type LearningObjectivePaginationQueryResult = Apollo.QueryResult<
	LearningObjectivePaginationQuery,
	LearningObjectivePaginationQueryVariables
>;
export const LearningObjectiveSelectDocument = gql`
	query learningObjectiveSelect($filters: [LearningObjectiveFilterInput!], $order: LearningObjectiveOrder) {
		learningObjectiveConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...LearningObjectiveName
				}
			}
		}
	}
	${LearningObjectiveNameFragmentDoc}
`;

/**
 * __useLearningObjectiveSelectQuery__
 *
 * To run a query within a React component, call `useLearningObjectiveSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useLearningObjectiveSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearningObjectiveSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useLearningObjectiveSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<LearningObjectiveSelectQuery, LearningObjectiveSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<LearningObjectiveSelectQuery, LearningObjectiveSelectQueryVariables>(
		LearningObjectiveSelectDocument,
		options,
	);
}
export function useLearningObjectiveSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<LearningObjectiveSelectQuery, LearningObjectiveSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<LearningObjectiveSelectQuery, LearningObjectiveSelectQueryVariables>(
		LearningObjectiveSelectDocument,
		options,
	);
}
export type LearningObjectiveSelectQueryHookResult = ReturnType<typeof useLearningObjectiveSelectQuery>;
export type LearningObjectiveSelectLazyQueryHookResult = ReturnType<typeof useLearningObjectiveSelectLazyQuery>;
export type LearningObjectiveSelectQueryResult = Apollo.QueryResult<
	LearningObjectiveSelectQuery,
	LearningObjectiveSelectQueryVariables
>;
export const LearningObjectiveUpdateDocument = gql`
	mutation learningObjectiveUpdate($id: ID!, $input: LearningObjectiveUpdateInput!) {
		updateLearningObjective(id: $id, input: $input) {
			...LearningObjectiveDetail
		}
	}
	${LearningObjectiveDetailFragmentDoc}
`;
export type LearningObjectiveUpdateMutationFn = Apollo.MutationFunction<
	LearningObjectiveUpdateMutation,
	LearningObjectiveUpdateMutationVariables
>;

/**
 * __useLearningObjectiveUpdateMutation__
 *
 * To run a mutation, you first call `useLearningObjectiveUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLearningObjectiveUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [learningObjectiveUpdateMutation, { data, loading, error }] = useLearningObjectiveUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLearningObjectiveUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<LearningObjectiveUpdateMutation, LearningObjectiveUpdateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<LearningObjectiveUpdateMutation, LearningObjectiveUpdateMutationVariables>(
		LearningObjectiveUpdateDocument,
		options,
	);
}
export type LearningObjectiveUpdateMutationHookResult = ReturnType<typeof useLearningObjectiveUpdateMutation>;
export type LearningObjectiveUpdateMutationResult = Apollo.MutationResult<LearningObjectiveUpdateMutation>;
export type LearningObjectiveUpdateMutationOptions = Apollo.BaseMutationOptions<
	LearningObjectiveUpdateMutation,
	LearningObjectiveUpdateMutationVariables
>;
export const LearningObjectiveMarkUpdateBulkValuesDocument = gql`
	mutation learningObjectiveMarkUpdateBulkValues(
		$developingIDs: [ID!]!
		$meetingIDs: [ID!]!
		$exceedingIDs: [ID!]!
		$clearIDs: [ID!]!
	) {
		developing: updateBulkLearningObjectiveMarks(filters: { idIn: $developingIDs }, input: { value: developing }) {
			edges {
				node {
					id
					value
				}
			}
		}
		meeting: updateBulkLearningObjectiveMarks(filters: { idIn: $meetingIDs }, input: { value: meeting }) {
			edges {
				node {
					id
					value
				}
			}
		}
		exceeding: updateBulkLearningObjectiveMarks(filters: { idIn: $exceedingIDs }, input: { value: exceeding }) {
			edges {
				node {
					id
					value
				}
			}
		}
		clear: updateBulkLearningObjectiveMarks(filters: { idIn: $clearIDs }, input: { clearValue: true }) {
			edges {
				node {
					id
					value
				}
			}
		}
	}
`;
export type LearningObjectiveMarkUpdateBulkValuesMutationFn = Apollo.MutationFunction<
	LearningObjectiveMarkUpdateBulkValuesMutation,
	LearningObjectiveMarkUpdateBulkValuesMutationVariables
>;

/**
 * __useLearningObjectiveMarkUpdateBulkValuesMutation__
 *
 * To run a mutation, you first call `useLearningObjectiveMarkUpdateBulkValuesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLearningObjectiveMarkUpdateBulkValuesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [learningObjectiveMarkUpdateBulkValuesMutation, { data, loading, error }] = useLearningObjectiveMarkUpdateBulkValuesMutation({
 *   variables: {
 *      developingIDs: // value for 'developingIDs'
 *      meetingIDs: // value for 'meetingIDs'
 *      exceedingIDs: // value for 'exceedingIDs'
 *      clearIDs: // value for 'clearIDs'
 *   },
 * });
 */
export function useLearningObjectiveMarkUpdateBulkValuesMutation(
	baseOptions?: Apollo.MutationHookOptions<
		LearningObjectiveMarkUpdateBulkValuesMutation,
		LearningObjectiveMarkUpdateBulkValuesMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		LearningObjectiveMarkUpdateBulkValuesMutation,
		LearningObjectiveMarkUpdateBulkValuesMutationVariables
	>(LearningObjectiveMarkUpdateBulkValuesDocument, options);
}
export type LearningObjectiveMarkUpdateBulkValuesMutationHookResult = ReturnType<
	typeof useLearningObjectiveMarkUpdateBulkValuesMutation
>;
export type LearningObjectiveMarkUpdateBulkValuesMutationResult =
	Apollo.MutationResult<LearningObjectiveMarkUpdateBulkValuesMutation>;
export type LearningObjectiveMarkUpdateBulkValuesMutationOptions = Apollo.BaseMutationOptions<
	LearningObjectiveMarkUpdateBulkValuesMutation,
	LearningObjectiveMarkUpdateBulkValuesMutationVariables
>;
export const LearningObjectiveMarkCountDocument = gql`
	query learningObjectiveMarkCount(
		$filters: [LearningObjectiveMarkFilterInput!]
		$pagination: Pagination
		$order: LearningObjectiveMarkOrder
	) {
		learningObjectiveMarkConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useLearningObjectiveMarkCountQuery__
 *
 * To run a query within a React component, call `useLearningObjectiveMarkCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useLearningObjectiveMarkCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearningObjectiveMarkCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useLearningObjectiveMarkCountQuery(
	baseOptions?: Apollo.QueryHookOptions<LearningObjectiveMarkCountQuery, LearningObjectiveMarkCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<LearningObjectiveMarkCountQuery, LearningObjectiveMarkCountQueryVariables>(
		LearningObjectiveMarkCountDocument,
		options,
	);
}
export function useLearningObjectiveMarkCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<LearningObjectiveMarkCountQuery, LearningObjectiveMarkCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<LearningObjectiveMarkCountQuery, LearningObjectiveMarkCountQueryVariables>(
		LearningObjectiveMarkCountDocument,
		options,
	);
}
export type LearningObjectiveMarkCountQueryHookResult = ReturnType<typeof useLearningObjectiveMarkCountQuery>;
export type LearningObjectiveMarkCountLazyQueryHookResult = ReturnType<typeof useLearningObjectiveMarkCountLazyQuery>;
export type LearningObjectiveMarkCountQueryResult = Apollo.QueryResult<
	LearningObjectiveMarkCountQuery,
	LearningObjectiveMarkCountQueryVariables
>;
export const LearningObjectiveMarkCreateDocument = gql`
	mutation learningObjectiveMarkCreate($input: LearningObjectiveMarkCreateInput!) {
		createLearningObjectiveMark(input: $input) {
			...LearningObjectiveMarkDetail
		}
	}
	${LearningObjectiveMarkDetailFragmentDoc}
`;
export type LearningObjectiveMarkCreateMutationFn = Apollo.MutationFunction<
	LearningObjectiveMarkCreateMutation,
	LearningObjectiveMarkCreateMutationVariables
>;

/**
 * __useLearningObjectiveMarkCreateMutation__
 *
 * To run a mutation, you first call `useLearningObjectiveMarkCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLearningObjectiveMarkCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [learningObjectiveMarkCreateMutation, { data, loading, error }] = useLearningObjectiveMarkCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLearningObjectiveMarkCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<
		LearningObjectiveMarkCreateMutation,
		LearningObjectiveMarkCreateMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<LearningObjectiveMarkCreateMutation, LearningObjectiveMarkCreateMutationVariables>(
		LearningObjectiveMarkCreateDocument,
		options,
	);
}
export type LearningObjectiveMarkCreateMutationHookResult = ReturnType<typeof useLearningObjectiveMarkCreateMutation>;
export type LearningObjectiveMarkCreateMutationResult = Apollo.MutationResult<LearningObjectiveMarkCreateMutation>;
export type LearningObjectiveMarkCreateMutationOptions = Apollo.BaseMutationOptions<
	LearningObjectiveMarkCreateMutation,
	LearningObjectiveMarkCreateMutationVariables
>;
export const LearningObjectiveMarkDeleteDocument = gql`
	mutation learningObjectiveMarkDelete($id: ID!) {
		deleteLearningObjectiveMark(id: $id)
	}
`;
export type LearningObjectiveMarkDeleteMutationFn = Apollo.MutationFunction<
	LearningObjectiveMarkDeleteMutation,
	LearningObjectiveMarkDeleteMutationVariables
>;

/**
 * __useLearningObjectiveMarkDeleteMutation__
 *
 * To run a mutation, you first call `useLearningObjectiveMarkDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLearningObjectiveMarkDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [learningObjectiveMarkDeleteMutation, { data, loading, error }] = useLearningObjectiveMarkDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLearningObjectiveMarkDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<
		LearningObjectiveMarkDeleteMutation,
		LearningObjectiveMarkDeleteMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<LearningObjectiveMarkDeleteMutation, LearningObjectiveMarkDeleteMutationVariables>(
		LearningObjectiveMarkDeleteDocument,
		options,
	);
}
export type LearningObjectiveMarkDeleteMutationHookResult = ReturnType<typeof useLearningObjectiveMarkDeleteMutation>;
export type LearningObjectiveMarkDeleteMutationResult = Apollo.MutationResult<LearningObjectiveMarkDeleteMutation>;
export type LearningObjectiveMarkDeleteMutationOptions = Apollo.BaseMutationOptions<
	LearningObjectiveMarkDeleteMutation,
	LearningObjectiveMarkDeleteMutationVariables
>;
export const LearningObjectiveMarkDetailDocument = gql`
	query learningObjectiveMarkDetail($id: ID!) {
		learningObjectiveMark(id: $id) {
			...LearningObjectiveMarkDetail
		}
	}
	${LearningObjectiveMarkDetailFragmentDoc}
`;

/**
 * __useLearningObjectiveMarkDetailQuery__
 *
 * To run a query within a React component, call `useLearningObjectiveMarkDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useLearningObjectiveMarkDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearningObjectiveMarkDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLearningObjectiveMarkDetailQuery(
	baseOptions: Apollo.QueryHookOptions<LearningObjectiveMarkDetailQuery, LearningObjectiveMarkDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<LearningObjectiveMarkDetailQuery, LearningObjectiveMarkDetailQueryVariables>(
		LearningObjectiveMarkDetailDocument,
		options,
	);
}
export function useLearningObjectiveMarkDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		LearningObjectiveMarkDetailQuery,
		LearningObjectiveMarkDetailQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<LearningObjectiveMarkDetailQuery, LearningObjectiveMarkDetailQueryVariables>(
		LearningObjectiveMarkDetailDocument,
		options,
	);
}
export type LearningObjectiveMarkDetailQueryHookResult = ReturnType<typeof useLearningObjectiveMarkDetailQuery>;
export type LearningObjectiveMarkDetailLazyQueryHookResult = ReturnType<typeof useLearningObjectiveMarkDetailLazyQuery>;
export type LearningObjectiveMarkDetailQueryResult = Apollo.QueryResult<
	LearningObjectiveMarkDetailQuery,
	LearningObjectiveMarkDetailQueryVariables
>;
export const LearningObjectiveMarkIndexDocument = gql`
	query learningObjectiveMarkIndex(
		$filters: [LearningObjectiveMarkFilterInput!]
		$pagination: Pagination
		$order: LearningObjectiveMarkOrder
	) {
		learningObjectiveMarkConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					value
				}
			}
		}
	}
`;

/**
 * __useLearningObjectiveMarkIndexQuery__
 *
 * To run a query within a React component, call `useLearningObjectiveMarkIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useLearningObjectiveMarkIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearningObjectiveMarkIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useLearningObjectiveMarkIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<LearningObjectiveMarkIndexQuery, LearningObjectiveMarkIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<LearningObjectiveMarkIndexQuery, LearningObjectiveMarkIndexQueryVariables>(
		LearningObjectiveMarkIndexDocument,
		options,
	);
}
export function useLearningObjectiveMarkIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<LearningObjectiveMarkIndexQuery, LearningObjectiveMarkIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<LearningObjectiveMarkIndexQuery, LearningObjectiveMarkIndexQueryVariables>(
		LearningObjectiveMarkIndexDocument,
		options,
	);
}
export type LearningObjectiveMarkIndexQueryHookResult = ReturnType<typeof useLearningObjectiveMarkIndexQuery>;
export type LearningObjectiveMarkIndexLazyQueryHookResult = ReturnType<typeof useLearningObjectiveMarkIndexLazyQuery>;
export type LearningObjectiveMarkIndexQueryResult = Apollo.QueryResult<
	LearningObjectiveMarkIndexQuery,
	LearningObjectiveMarkIndexQueryVariables
>;
export const LearningObjectiveMarkNameDocument = gql`
	query learningObjectiveMarkName($id: ID!) {
		learningObjectiveMark(id: $id) {
			id
			...LearningObjectiveMarkName
		}
	}
	${LearningObjectiveMarkNameFragmentDoc}
`;

/**
 * __useLearningObjectiveMarkNameQuery__
 *
 * To run a query within a React component, call `useLearningObjectiveMarkNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useLearningObjectiveMarkNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearningObjectiveMarkNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLearningObjectiveMarkNameQuery(
	baseOptions: Apollo.QueryHookOptions<LearningObjectiveMarkNameQuery, LearningObjectiveMarkNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<LearningObjectiveMarkNameQuery, LearningObjectiveMarkNameQueryVariables>(
		LearningObjectiveMarkNameDocument,
		options,
	);
}
export function useLearningObjectiveMarkNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<LearningObjectiveMarkNameQuery, LearningObjectiveMarkNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<LearningObjectiveMarkNameQuery, LearningObjectiveMarkNameQueryVariables>(
		LearningObjectiveMarkNameDocument,
		options,
	);
}
export type LearningObjectiveMarkNameQueryHookResult = ReturnType<typeof useLearningObjectiveMarkNameQuery>;
export type LearningObjectiveMarkNameLazyQueryHookResult = ReturnType<typeof useLearningObjectiveMarkNameLazyQuery>;
export type LearningObjectiveMarkNameQueryResult = Apollo.QueryResult<
	LearningObjectiveMarkNameQuery,
	LearningObjectiveMarkNameQueryVariables
>;
export const LearningObjectiveMarkNamesDocument = gql`
	query learningObjectiveMarkNames(
		$filters: [LearningObjectiveMarkFilterInput!]
		$pagination: Pagination
		$order: LearningObjectiveMarkOrder
	) {
		learningObjectiveMarkConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...LearningObjectiveMarkName
				}
			}
		}
	}
	${LearningObjectiveMarkNameFragmentDoc}
`;

/**
 * __useLearningObjectiveMarkNamesQuery__
 *
 * To run a query within a React component, call `useLearningObjectiveMarkNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLearningObjectiveMarkNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearningObjectiveMarkNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useLearningObjectiveMarkNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<LearningObjectiveMarkNamesQuery, LearningObjectiveMarkNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<LearningObjectiveMarkNamesQuery, LearningObjectiveMarkNamesQueryVariables>(
		LearningObjectiveMarkNamesDocument,
		options,
	);
}
export function useLearningObjectiveMarkNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<LearningObjectiveMarkNamesQuery, LearningObjectiveMarkNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<LearningObjectiveMarkNamesQuery, LearningObjectiveMarkNamesQueryVariables>(
		LearningObjectiveMarkNamesDocument,
		options,
	);
}
export type LearningObjectiveMarkNamesQueryHookResult = ReturnType<typeof useLearningObjectiveMarkNamesQuery>;
export type LearningObjectiveMarkNamesLazyQueryHookResult = ReturnType<typeof useLearningObjectiveMarkNamesLazyQuery>;
export type LearningObjectiveMarkNamesQueryResult = Apollo.QueryResult<
	LearningObjectiveMarkNamesQuery,
	LearningObjectiveMarkNamesQueryVariables
>;
export const LearningObjectiveMarkPaginationDocument = gql`
	query learningObjectiveMarkPagination(
		$filters: [LearningObjectiveMarkFilterInput!]
		$order: LearningObjectiveMarkOrder
	) {
		learningObjectiveMarkConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useLearningObjectiveMarkPaginationQuery__
 *
 * To run a query within a React component, call `useLearningObjectiveMarkPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useLearningObjectiveMarkPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearningObjectiveMarkPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useLearningObjectiveMarkPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<
		LearningObjectiveMarkPaginationQuery,
		LearningObjectiveMarkPaginationQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<LearningObjectiveMarkPaginationQuery, LearningObjectiveMarkPaginationQueryVariables>(
		LearningObjectiveMarkPaginationDocument,
		options,
	);
}
export function useLearningObjectiveMarkPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		LearningObjectiveMarkPaginationQuery,
		LearningObjectiveMarkPaginationQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<LearningObjectiveMarkPaginationQuery, LearningObjectiveMarkPaginationQueryVariables>(
		LearningObjectiveMarkPaginationDocument,
		options,
	);
}
export type LearningObjectiveMarkPaginationQueryHookResult = ReturnType<typeof useLearningObjectiveMarkPaginationQuery>;
export type LearningObjectiveMarkPaginationLazyQueryHookResult = ReturnType<
	typeof useLearningObjectiveMarkPaginationLazyQuery
>;
export type LearningObjectiveMarkPaginationQueryResult = Apollo.QueryResult<
	LearningObjectiveMarkPaginationQuery,
	LearningObjectiveMarkPaginationQueryVariables
>;
export const LearningObjectiveMarkSelectDocument = gql`
	query learningObjectiveMarkSelect($filters: [LearningObjectiveMarkFilterInput!], $order: LearningObjectiveMarkOrder) {
		learningObjectiveMarkConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...LearningObjectiveMarkName
				}
			}
		}
	}
	${LearningObjectiveMarkNameFragmentDoc}
`;

/**
 * __useLearningObjectiveMarkSelectQuery__
 *
 * To run a query within a React component, call `useLearningObjectiveMarkSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useLearningObjectiveMarkSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearningObjectiveMarkSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useLearningObjectiveMarkSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<LearningObjectiveMarkSelectQuery, LearningObjectiveMarkSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<LearningObjectiveMarkSelectQuery, LearningObjectiveMarkSelectQueryVariables>(
		LearningObjectiveMarkSelectDocument,
		options,
	);
}
export function useLearningObjectiveMarkSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		LearningObjectiveMarkSelectQuery,
		LearningObjectiveMarkSelectQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<LearningObjectiveMarkSelectQuery, LearningObjectiveMarkSelectQueryVariables>(
		LearningObjectiveMarkSelectDocument,
		options,
	);
}
export type LearningObjectiveMarkSelectQueryHookResult = ReturnType<typeof useLearningObjectiveMarkSelectQuery>;
export type LearningObjectiveMarkSelectLazyQueryHookResult = ReturnType<typeof useLearningObjectiveMarkSelectLazyQuery>;
export type LearningObjectiveMarkSelectQueryResult = Apollo.QueryResult<
	LearningObjectiveMarkSelectQuery,
	LearningObjectiveMarkSelectQueryVariables
>;
export const LearningObjectiveMarkUpdateDocument = gql`
	mutation learningObjectiveMarkUpdate($id: ID!, $input: LearningObjectiveMarkUpdateInput!) {
		updateLearningObjectiveMark(id: $id, input: $input) {
			...LearningObjectiveMarkDetail
		}
	}
	${LearningObjectiveMarkDetailFragmentDoc}
`;
export type LearningObjectiveMarkUpdateMutationFn = Apollo.MutationFunction<
	LearningObjectiveMarkUpdateMutation,
	LearningObjectiveMarkUpdateMutationVariables
>;

/**
 * __useLearningObjectiveMarkUpdateMutation__
 *
 * To run a mutation, you first call `useLearningObjectiveMarkUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLearningObjectiveMarkUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [learningObjectiveMarkUpdateMutation, { data, loading, error }] = useLearningObjectiveMarkUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLearningObjectiveMarkUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<
		LearningObjectiveMarkUpdateMutation,
		LearningObjectiveMarkUpdateMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<LearningObjectiveMarkUpdateMutation, LearningObjectiveMarkUpdateMutationVariables>(
		LearningObjectiveMarkUpdateDocument,
		options,
	);
}
export type LearningObjectiveMarkUpdateMutationHookResult = ReturnType<typeof useLearningObjectiveMarkUpdateMutation>;
export type LearningObjectiveMarkUpdateMutationResult = Apollo.MutationResult<LearningObjectiveMarkUpdateMutation>;
export type LearningObjectiveMarkUpdateMutationOptions = Apollo.BaseMutationOptions<
	LearningObjectiveMarkUpdateMutation,
	LearningObjectiveMarkUpdateMutationVariables
>;
export const LessonPlanCountDocument = gql`
	query lessonPlanCount($filters: [LessonPlanFilterInput!], $pagination: Pagination, $order: LessonPlanOrder) {
		lessonPlanConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useLessonPlanCountQuery__
 *
 * To run a query within a React component, call `useLessonPlanCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useLessonPlanCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLessonPlanCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useLessonPlanCountQuery(
	baseOptions?: Apollo.QueryHookOptions<LessonPlanCountQuery, LessonPlanCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<LessonPlanCountQuery, LessonPlanCountQueryVariables>(LessonPlanCountDocument, options);
}
export function useLessonPlanCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<LessonPlanCountQuery, LessonPlanCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<LessonPlanCountQuery, LessonPlanCountQueryVariables>(LessonPlanCountDocument, options);
}
export type LessonPlanCountQueryHookResult = ReturnType<typeof useLessonPlanCountQuery>;
export type LessonPlanCountLazyQueryHookResult = ReturnType<typeof useLessonPlanCountLazyQuery>;
export type LessonPlanCountQueryResult = Apollo.QueryResult<LessonPlanCountQuery, LessonPlanCountQueryVariables>;
export const LessonPlanCreateDocument = gql`
	mutation lessonPlanCreate($input: LessonPlanCreateInput!) {
		createLessonPlan(input: $input) {
			...LessonPlanDetail
		}
	}
	${LessonPlanDetailFragmentDoc}
`;
export type LessonPlanCreateMutationFn = Apollo.MutationFunction<
	LessonPlanCreateMutation,
	LessonPlanCreateMutationVariables
>;

/**
 * __useLessonPlanCreateMutation__
 *
 * To run a mutation, you first call `useLessonPlanCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLessonPlanCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [lessonPlanCreateMutation, { data, loading, error }] = useLessonPlanCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLessonPlanCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<LessonPlanCreateMutation, LessonPlanCreateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<LessonPlanCreateMutation, LessonPlanCreateMutationVariables>(
		LessonPlanCreateDocument,
		options,
	);
}
export type LessonPlanCreateMutationHookResult = ReturnType<typeof useLessonPlanCreateMutation>;
export type LessonPlanCreateMutationResult = Apollo.MutationResult<LessonPlanCreateMutation>;
export type LessonPlanCreateMutationOptions = Apollo.BaseMutationOptions<
	LessonPlanCreateMutation,
	LessonPlanCreateMutationVariables
>;
export const LessonPlanDeleteDocument = gql`
	mutation lessonPlanDelete($id: ID!) {
		deleteLessonPlan(id: $id)
	}
`;
export type LessonPlanDeleteMutationFn = Apollo.MutationFunction<
	LessonPlanDeleteMutation,
	LessonPlanDeleteMutationVariables
>;

/**
 * __useLessonPlanDeleteMutation__
 *
 * To run a mutation, you first call `useLessonPlanDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLessonPlanDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [lessonPlanDeleteMutation, { data, loading, error }] = useLessonPlanDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLessonPlanDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<LessonPlanDeleteMutation, LessonPlanDeleteMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<LessonPlanDeleteMutation, LessonPlanDeleteMutationVariables>(
		LessonPlanDeleteDocument,
		options,
	);
}
export type LessonPlanDeleteMutationHookResult = ReturnType<typeof useLessonPlanDeleteMutation>;
export type LessonPlanDeleteMutationResult = Apollo.MutationResult<LessonPlanDeleteMutation>;
export type LessonPlanDeleteMutationOptions = Apollo.BaseMutationOptions<
	LessonPlanDeleteMutation,
	LessonPlanDeleteMutationVariables
>;
export const LessonPlanDetailDocument = gql`
	query lessonPlanDetail($id: ID!) {
		lessonPlan(id: $id) {
			...LessonPlanDetail
		}
	}
	${LessonPlanDetailFragmentDoc}
`;

/**
 * __useLessonPlanDetailQuery__
 *
 * To run a query within a React component, call `useLessonPlanDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useLessonPlanDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLessonPlanDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLessonPlanDetailQuery(
	baseOptions: Apollo.QueryHookOptions<LessonPlanDetailQuery, LessonPlanDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<LessonPlanDetailQuery, LessonPlanDetailQueryVariables>(LessonPlanDetailDocument, options);
}
export function useLessonPlanDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<LessonPlanDetailQuery, LessonPlanDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<LessonPlanDetailQuery, LessonPlanDetailQueryVariables>(LessonPlanDetailDocument, options);
}
export type LessonPlanDetailQueryHookResult = ReturnType<typeof useLessonPlanDetailQuery>;
export type LessonPlanDetailLazyQueryHookResult = ReturnType<typeof useLessonPlanDetailLazyQuery>;
export type LessonPlanDetailQueryResult = Apollo.QueryResult<LessonPlanDetailQuery, LessonPlanDetailQueryVariables>;
export const LessonPlanIndexDocument = gql`
	query lessonPlanIndex($filters: [LessonPlanFilterInput!], $pagination: Pagination, $order: LessonPlanOrder) {
		lessonPlanConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					courseDate
					lessonDescription
					requiredResourcesAndMaterials
					stationOneDescription
					stationTwoDescription
					stationThreeDescription
					differentiationDescription
					assessmentDescription
					notes
				}
			}
		}
	}
`;

/**
 * __useLessonPlanIndexQuery__
 *
 * To run a query within a React component, call `useLessonPlanIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useLessonPlanIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLessonPlanIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useLessonPlanIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<LessonPlanIndexQuery, LessonPlanIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<LessonPlanIndexQuery, LessonPlanIndexQueryVariables>(LessonPlanIndexDocument, options);
}
export function useLessonPlanIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<LessonPlanIndexQuery, LessonPlanIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<LessonPlanIndexQuery, LessonPlanIndexQueryVariables>(LessonPlanIndexDocument, options);
}
export type LessonPlanIndexQueryHookResult = ReturnType<typeof useLessonPlanIndexQuery>;
export type LessonPlanIndexLazyQueryHookResult = ReturnType<typeof useLessonPlanIndexLazyQuery>;
export type LessonPlanIndexQueryResult = Apollo.QueryResult<LessonPlanIndexQuery, LessonPlanIndexQueryVariables>;
export const LessonPlanNameDocument = gql`
	query lessonPlanName($id: ID!) {
		lessonPlan(id: $id) {
			id
			...LessonPlanName
		}
	}
	${LessonPlanNameFragmentDoc}
`;

/**
 * __useLessonPlanNameQuery__
 *
 * To run a query within a React component, call `useLessonPlanNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useLessonPlanNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLessonPlanNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLessonPlanNameQuery(
	baseOptions: Apollo.QueryHookOptions<LessonPlanNameQuery, LessonPlanNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<LessonPlanNameQuery, LessonPlanNameQueryVariables>(LessonPlanNameDocument, options);
}
export function useLessonPlanNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<LessonPlanNameQuery, LessonPlanNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<LessonPlanNameQuery, LessonPlanNameQueryVariables>(LessonPlanNameDocument, options);
}
export type LessonPlanNameQueryHookResult = ReturnType<typeof useLessonPlanNameQuery>;
export type LessonPlanNameLazyQueryHookResult = ReturnType<typeof useLessonPlanNameLazyQuery>;
export type LessonPlanNameQueryResult = Apollo.QueryResult<LessonPlanNameQuery, LessonPlanNameQueryVariables>;
export const LessonPlanNamesDocument = gql`
	query lessonPlanNames($filters: [LessonPlanFilterInput!], $pagination: Pagination, $order: LessonPlanOrder) {
		lessonPlanConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...LessonPlanName
				}
			}
		}
	}
	${LessonPlanNameFragmentDoc}
`;

/**
 * __useLessonPlanNamesQuery__
 *
 * To run a query within a React component, call `useLessonPlanNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLessonPlanNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLessonPlanNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useLessonPlanNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<LessonPlanNamesQuery, LessonPlanNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<LessonPlanNamesQuery, LessonPlanNamesQueryVariables>(LessonPlanNamesDocument, options);
}
export function useLessonPlanNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<LessonPlanNamesQuery, LessonPlanNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<LessonPlanNamesQuery, LessonPlanNamesQueryVariables>(LessonPlanNamesDocument, options);
}
export type LessonPlanNamesQueryHookResult = ReturnType<typeof useLessonPlanNamesQuery>;
export type LessonPlanNamesLazyQueryHookResult = ReturnType<typeof useLessonPlanNamesLazyQuery>;
export type LessonPlanNamesQueryResult = Apollo.QueryResult<LessonPlanNamesQuery, LessonPlanNamesQueryVariables>;
export const LessonPlansOverviewDocument = gql`
	query lessonPlansOverview($startDate: Time!, $endDate: Time!, $pagination: Pagination, $order: HomeRoomSectionOrder) {
		homeRoomSectionConnection(
			filters: {
				term: [
					{ startDateLTE: $startDate, endDateGTE: $startDate }
					{ startDateLTE: $endDate, endDateGTE: $endDate }
					{ startDateGTE: $startDate, endDateLTE: $startDate }
				]
			}
			pagination: $pagination
			order: $order
		) {
			edges {
				node {
					id
					name
					homeRoomSectionTeacherEnrollments {
						id
						teacher {
							id
							person {
								id
								name
								imageURL
							}
						}
					}
					courseSections {
						id
						name
						course {
							id
							subject
						}
						courseSectionPeriods {
							id
							dayOfWeek
						}
						lessonPlans(filters: { courseDateGTE: $startDate, courseDateLTE: $endDate }) {
							id
							courseDate
						}
					}
				}
			}
		}
	}
`;

/**
 * __useLessonPlansOverviewQuery__
 *
 * To run a query within a React component, call `useLessonPlansOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useLessonPlansOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLessonPlansOverviewQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useLessonPlansOverviewQuery(
	baseOptions: Apollo.QueryHookOptions<LessonPlansOverviewQuery, LessonPlansOverviewQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<LessonPlansOverviewQuery, LessonPlansOverviewQueryVariables>(
		LessonPlansOverviewDocument,
		options,
	);
}
export function useLessonPlansOverviewLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<LessonPlansOverviewQuery, LessonPlansOverviewQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<LessonPlansOverviewQuery, LessonPlansOverviewQueryVariables>(
		LessonPlansOverviewDocument,
		options,
	);
}
export type LessonPlansOverviewQueryHookResult = ReturnType<typeof useLessonPlansOverviewQuery>;
export type LessonPlansOverviewLazyQueryHookResult = ReturnType<typeof useLessonPlansOverviewLazyQuery>;
export type LessonPlansOverviewQueryResult = Apollo.QueryResult<
	LessonPlansOverviewQuery,
	LessonPlansOverviewQueryVariables
>;
export const LessonPlanPaginationDocument = gql`
	query lessonPlanPagination($filters: [LessonPlanFilterInput!], $order: LessonPlanOrder) {
		lessonPlanConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useLessonPlanPaginationQuery__
 *
 * To run a query within a React component, call `useLessonPlanPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useLessonPlanPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLessonPlanPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useLessonPlanPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<LessonPlanPaginationQuery, LessonPlanPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<LessonPlanPaginationQuery, LessonPlanPaginationQueryVariables>(
		LessonPlanPaginationDocument,
		options,
	);
}
export function useLessonPlanPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<LessonPlanPaginationQuery, LessonPlanPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<LessonPlanPaginationQuery, LessonPlanPaginationQueryVariables>(
		LessonPlanPaginationDocument,
		options,
	);
}
export type LessonPlanPaginationQueryHookResult = ReturnType<typeof useLessonPlanPaginationQuery>;
export type LessonPlanPaginationLazyQueryHookResult = ReturnType<typeof useLessonPlanPaginationLazyQuery>;
export type LessonPlanPaginationQueryResult = Apollo.QueryResult<
	LessonPlanPaginationQuery,
	LessonPlanPaginationQueryVariables
>;
export const LessonPlanSelectDocument = gql`
	query lessonPlanSelect($filters: [LessonPlanFilterInput!], $order: LessonPlanOrder) {
		lessonPlanConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...LessonPlanName
				}
			}
		}
	}
	${LessonPlanNameFragmentDoc}
`;

/**
 * __useLessonPlanSelectQuery__
 *
 * To run a query within a React component, call `useLessonPlanSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useLessonPlanSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLessonPlanSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useLessonPlanSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<LessonPlanSelectQuery, LessonPlanSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<LessonPlanSelectQuery, LessonPlanSelectQueryVariables>(LessonPlanSelectDocument, options);
}
export function useLessonPlanSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<LessonPlanSelectQuery, LessonPlanSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<LessonPlanSelectQuery, LessonPlanSelectQueryVariables>(LessonPlanSelectDocument, options);
}
export type LessonPlanSelectQueryHookResult = ReturnType<typeof useLessonPlanSelectQuery>;
export type LessonPlanSelectLazyQueryHookResult = ReturnType<typeof useLessonPlanSelectLazyQuery>;
export type LessonPlanSelectQueryResult = Apollo.QueryResult<LessonPlanSelectQuery, LessonPlanSelectQueryVariables>;
export const LessonPlanUpdateDocument = gql`
	mutation lessonPlanUpdate($id: ID!, $input: LessonPlanUpdateInput!) {
		updateLessonPlan(id: $id, input: $input) {
			...LessonPlanDetail
		}
	}
	${LessonPlanDetailFragmentDoc}
`;
export type LessonPlanUpdateMutationFn = Apollo.MutationFunction<
	LessonPlanUpdateMutation,
	LessonPlanUpdateMutationVariables
>;

/**
 * __useLessonPlanUpdateMutation__
 *
 * To run a mutation, you first call `useLessonPlanUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLessonPlanUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [lessonPlanUpdateMutation, { data, loading, error }] = useLessonPlanUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLessonPlanUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<LessonPlanUpdateMutation, LessonPlanUpdateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<LessonPlanUpdateMutation, LessonPlanUpdateMutationVariables>(
		LessonPlanUpdateDocument,
		options,
	);
}
export type LessonPlanUpdateMutationHookResult = ReturnType<typeof useLessonPlanUpdateMutation>;
export type LessonPlanUpdateMutationResult = Apollo.MutationResult<LessonPlanUpdateMutation>;
export type LessonPlanUpdateMutationOptions = Apollo.BaseMutationOptions<
	LessonPlanUpdateMutation,
	LessonPlanUpdateMutationVariables
>;
export const LoginRecordCountDocument = gql`
	query loginRecordCount($filters: [LoginRecordFilterInput!], $pagination: Pagination, $order: LoginRecordOrder) {
		loginRecordConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useLoginRecordCountQuery__
 *
 * To run a query within a React component, call `useLoginRecordCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoginRecordCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoginRecordCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useLoginRecordCountQuery(
	baseOptions?: Apollo.QueryHookOptions<LoginRecordCountQuery, LoginRecordCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<LoginRecordCountQuery, LoginRecordCountQueryVariables>(LoginRecordCountDocument, options);
}
export function useLoginRecordCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<LoginRecordCountQuery, LoginRecordCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<LoginRecordCountQuery, LoginRecordCountQueryVariables>(LoginRecordCountDocument, options);
}
export type LoginRecordCountQueryHookResult = ReturnType<typeof useLoginRecordCountQuery>;
export type LoginRecordCountLazyQueryHookResult = ReturnType<typeof useLoginRecordCountLazyQuery>;
export type LoginRecordCountQueryResult = Apollo.QueryResult<LoginRecordCountQuery, LoginRecordCountQueryVariables>;
export const LoginRecordDetailDocument = gql`
	query loginRecordDetail($id: ID!) {
		loginRecord(id: $id) {
			...LoginRecordDetail
		}
	}
	${LoginRecordDetailFragmentDoc}
`;

/**
 * __useLoginRecordDetailQuery__
 *
 * To run a query within a React component, call `useLoginRecordDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoginRecordDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoginRecordDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoginRecordDetailQuery(
	baseOptions: Apollo.QueryHookOptions<LoginRecordDetailQuery, LoginRecordDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<LoginRecordDetailQuery, LoginRecordDetailQueryVariables>(LoginRecordDetailDocument, options);
}
export function useLoginRecordDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<LoginRecordDetailQuery, LoginRecordDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<LoginRecordDetailQuery, LoginRecordDetailQueryVariables>(
		LoginRecordDetailDocument,
		options,
	);
}
export type LoginRecordDetailQueryHookResult = ReturnType<typeof useLoginRecordDetailQuery>;
export type LoginRecordDetailLazyQueryHookResult = ReturnType<typeof useLoginRecordDetailLazyQuery>;
export type LoginRecordDetailQueryResult = Apollo.QueryResult<LoginRecordDetailQuery, LoginRecordDetailQueryVariables>;
export const LoginRecordIndexDocument = gql`
	query loginRecordIndex($filters: [LoginRecordFilterInput!], $pagination: Pagination, $order: LoginRecordOrder) {
		loginRecordConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					createdAt
					userEmail
					wasBlocked
					wasSuccessful
					isTrusted
				}
			}
		}
	}
`;

/**
 * __useLoginRecordIndexQuery__
 *
 * To run a query within a React component, call `useLoginRecordIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoginRecordIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoginRecordIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useLoginRecordIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<LoginRecordIndexQuery, LoginRecordIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<LoginRecordIndexQuery, LoginRecordIndexQueryVariables>(LoginRecordIndexDocument, options);
}
export function useLoginRecordIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<LoginRecordIndexQuery, LoginRecordIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<LoginRecordIndexQuery, LoginRecordIndexQueryVariables>(LoginRecordIndexDocument, options);
}
export type LoginRecordIndexQueryHookResult = ReturnType<typeof useLoginRecordIndexQuery>;
export type LoginRecordIndexLazyQueryHookResult = ReturnType<typeof useLoginRecordIndexLazyQuery>;
export type LoginRecordIndexQueryResult = Apollo.QueryResult<LoginRecordIndexQuery, LoginRecordIndexQueryVariables>;
export const LoginRecordNameDocument = gql`
	query loginRecordName($id: ID!) {
		loginRecord(id: $id) {
			id
			...LoginRecordName
		}
	}
	${LoginRecordNameFragmentDoc}
`;

/**
 * __useLoginRecordNameQuery__
 *
 * To run a query within a React component, call `useLoginRecordNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoginRecordNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoginRecordNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoginRecordNameQuery(
	baseOptions: Apollo.QueryHookOptions<LoginRecordNameQuery, LoginRecordNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<LoginRecordNameQuery, LoginRecordNameQueryVariables>(LoginRecordNameDocument, options);
}
export function useLoginRecordNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<LoginRecordNameQuery, LoginRecordNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<LoginRecordNameQuery, LoginRecordNameQueryVariables>(LoginRecordNameDocument, options);
}
export type LoginRecordNameQueryHookResult = ReturnType<typeof useLoginRecordNameQuery>;
export type LoginRecordNameLazyQueryHookResult = ReturnType<typeof useLoginRecordNameLazyQuery>;
export type LoginRecordNameQueryResult = Apollo.QueryResult<LoginRecordNameQuery, LoginRecordNameQueryVariables>;
export const LoginRecordNamesDocument = gql`
	query loginRecordNames($filters: [LoginRecordFilterInput!], $pagination: Pagination, $order: LoginRecordOrder) {
		loginRecordConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...LoginRecordName
				}
			}
		}
	}
	${LoginRecordNameFragmentDoc}
`;

/**
 * __useLoginRecordNamesQuery__
 *
 * To run a query within a React component, call `useLoginRecordNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoginRecordNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoginRecordNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useLoginRecordNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<LoginRecordNamesQuery, LoginRecordNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<LoginRecordNamesQuery, LoginRecordNamesQueryVariables>(LoginRecordNamesDocument, options);
}
export function useLoginRecordNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<LoginRecordNamesQuery, LoginRecordNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<LoginRecordNamesQuery, LoginRecordNamesQueryVariables>(LoginRecordNamesDocument, options);
}
export type LoginRecordNamesQueryHookResult = ReturnType<typeof useLoginRecordNamesQuery>;
export type LoginRecordNamesLazyQueryHookResult = ReturnType<typeof useLoginRecordNamesLazyQuery>;
export type LoginRecordNamesQueryResult = Apollo.QueryResult<LoginRecordNamesQuery, LoginRecordNamesQueryVariables>;
export const LoginRecordPaginationDocument = gql`
	query loginRecordPagination($filters: [LoginRecordFilterInput!], $order: LoginRecordOrder) {
		loginRecordConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useLoginRecordPaginationQuery__
 *
 * To run a query within a React component, call `useLoginRecordPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoginRecordPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoginRecordPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useLoginRecordPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<LoginRecordPaginationQuery, LoginRecordPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<LoginRecordPaginationQuery, LoginRecordPaginationQueryVariables>(
		LoginRecordPaginationDocument,
		options,
	);
}
export function useLoginRecordPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<LoginRecordPaginationQuery, LoginRecordPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<LoginRecordPaginationQuery, LoginRecordPaginationQueryVariables>(
		LoginRecordPaginationDocument,
		options,
	);
}
export type LoginRecordPaginationQueryHookResult = ReturnType<typeof useLoginRecordPaginationQuery>;
export type LoginRecordPaginationLazyQueryHookResult = ReturnType<typeof useLoginRecordPaginationLazyQuery>;
export type LoginRecordPaginationQueryResult = Apollo.QueryResult<
	LoginRecordPaginationQuery,
	LoginRecordPaginationQueryVariables
>;
export const LoginRecordSelectDocument = gql`
	query loginRecordSelect($filters: [LoginRecordFilterInput!], $order: LoginRecordOrder) {
		loginRecordConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...LoginRecordName
				}
			}
		}
	}
	${LoginRecordNameFragmentDoc}
`;

/**
 * __useLoginRecordSelectQuery__
 *
 * To run a query within a React component, call `useLoginRecordSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoginRecordSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoginRecordSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useLoginRecordSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<LoginRecordSelectQuery, LoginRecordSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<LoginRecordSelectQuery, LoginRecordSelectQueryVariables>(LoginRecordSelectDocument, options);
}
export function useLoginRecordSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<LoginRecordSelectQuery, LoginRecordSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<LoginRecordSelectQuery, LoginRecordSelectQueryVariables>(
		LoginRecordSelectDocument,
		options,
	);
}
export type LoginRecordSelectQueryHookResult = ReturnType<typeof useLoginRecordSelectQuery>;
export type LoginRecordSelectLazyQueryHookResult = ReturnType<typeof useLoginRecordSelectLazyQuery>;
export type LoginRecordSelectQueryResult = Apollo.QueryResult<LoginRecordSelectQuery, LoginRecordSelectQueryVariables>;
export const MFAFactorCountDocument = gql`
	query mfaFactorCount($filters: [MFAFactorFilterInput!], $pagination: Pagination, $order: MFAFactorOrder) {
		mfaFactorConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useMFAFactorCountQuery__
 *
 * To run a query within a React component, call `useMFAFactorCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useMFAFactorCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMFAFactorCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useMFAFactorCountQuery(
	baseOptions?: Apollo.QueryHookOptions<MFAFactorCountQuery, MFAFactorCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<MFAFactorCountQuery, MFAFactorCountQueryVariables>(MFAFactorCountDocument, options);
}
export function useMFAFactorCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<MFAFactorCountQuery, MFAFactorCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<MFAFactorCountQuery, MFAFactorCountQueryVariables>(MFAFactorCountDocument, options);
}
export type MFAFactorCountQueryHookResult = ReturnType<typeof useMFAFactorCountQuery>;
export type MFAFactorCountLazyQueryHookResult = ReturnType<typeof useMFAFactorCountLazyQuery>;
export type MFAFactorCountQueryResult = Apollo.QueryResult<MFAFactorCountQuery, MFAFactorCountQueryVariables>;
export const MFAFactorDetailDocument = gql`
	query mfaFactorDetail($id: ID!) {
		mfaFactor(id: $id) {
			...MFAFactorDetail
		}
	}
	${MFAFactorDetailFragmentDoc}
`;

/**
 * __useMFAFactorDetailQuery__
 *
 * To run a query within a React component, call `useMFAFactorDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useMFAFactorDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMFAFactorDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMFAFactorDetailQuery(
	baseOptions: Apollo.QueryHookOptions<MFAFactorDetailQuery, MFAFactorDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<MFAFactorDetailQuery, MFAFactorDetailQueryVariables>(MFAFactorDetailDocument, options);
}
export function useMFAFactorDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<MFAFactorDetailQuery, MFAFactorDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<MFAFactorDetailQuery, MFAFactorDetailQueryVariables>(MFAFactorDetailDocument, options);
}
export type MFAFactorDetailQueryHookResult = ReturnType<typeof useMFAFactorDetailQuery>;
export type MFAFactorDetailLazyQueryHookResult = ReturnType<typeof useMFAFactorDetailLazyQuery>;
export type MFAFactorDetailQueryResult = Apollo.QueryResult<MFAFactorDetailQuery, MFAFactorDetailQueryVariables>;
export const MFAFactorIndexDocument = gql`
	query mfaFactorIndex($filters: [MFAFactorFilterInput!], $pagination: Pagination, $order: MFAFactorOrder) {
		mfaFactorConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					createdAt
					count
					isApp
					email
					sms
					isVerified
				}
			}
		}
	}
`;

/**
 * __useMFAFactorIndexQuery__
 *
 * To run a query within a React component, call `useMFAFactorIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useMFAFactorIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMFAFactorIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useMFAFactorIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<MFAFactorIndexQuery, MFAFactorIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<MFAFactorIndexQuery, MFAFactorIndexQueryVariables>(MFAFactorIndexDocument, options);
}
export function useMFAFactorIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<MFAFactorIndexQuery, MFAFactorIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<MFAFactorIndexQuery, MFAFactorIndexQueryVariables>(MFAFactorIndexDocument, options);
}
export type MFAFactorIndexQueryHookResult = ReturnType<typeof useMFAFactorIndexQuery>;
export type MFAFactorIndexLazyQueryHookResult = ReturnType<typeof useMFAFactorIndexLazyQuery>;
export type MFAFactorIndexQueryResult = Apollo.QueryResult<MFAFactorIndexQuery, MFAFactorIndexQueryVariables>;
export const MFAFactorNameDocument = gql`
	query mfaFactorName($id: ID!) {
		mfaFactor(id: $id) {
			id
			...MFAFactorName
		}
	}
	${MFAFactorNameFragmentDoc}
`;

/**
 * __useMFAFactorNameQuery__
 *
 * To run a query within a React component, call `useMFAFactorNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useMFAFactorNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMFAFactorNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMFAFactorNameQuery(
	baseOptions: Apollo.QueryHookOptions<MFAFactorNameQuery, MFAFactorNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<MFAFactorNameQuery, MFAFactorNameQueryVariables>(MFAFactorNameDocument, options);
}
export function useMFAFactorNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<MFAFactorNameQuery, MFAFactorNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<MFAFactorNameQuery, MFAFactorNameQueryVariables>(MFAFactorNameDocument, options);
}
export type MFAFactorNameQueryHookResult = ReturnType<typeof useMFAFactorNameQuery>;
export type MFAFactorNameLazyQueryHookResult = ReturnType<typeof useMFAFactorNameLazyQuery>;
export type MFAFactorNameQueryResult = Apollo.QueryResult<MFAFactorNameQuery, MFAFactorNameQueryVariables>;
export const MFAFactorNamesDocument = gql`
	query mfaFactorNames($filters: [MFAFactorFilterInput!], $pagination: Pagination, $order: MFAFactorOrder) {
		mfaFactorConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...MFAFactorName
				}
			}
		}
	}
	${MFAFactorNameFragmentDoc}
`;

/**
 * __useMFAFactorNamesQuery__
 *
 * To run a query within a React component, call `useMFAFactorNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMFAFactorNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMFAFactorNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useMFAFactorNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<MFAFactorNamesQuery, MFAFactorNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<MFAFactorNamesQuery, MFAFactorNamesQueryVariables>(MFAFactorNamesDocument, options);
}
export function useMFAFactorNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<MFAFactorNamesQuery, MFAFactorNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<MFAFactorNamesQuery, MFAFactorNamesQueryVariables>(MFAFactorNamesDocument, options);
}
export type MFAFactorNamesQueryHookResult = ReturnType<typeof useMFAFactorNamesQuery>;
export type MFAFactorNamesLazyQueryHookResult = ReturnType<typeof useMFAFactorNamesLazyQuery>;
export type MFAFactorNamesQueryResult = Apollo.QueryResult<MFAFactorNamesQuery, MFAFactorNamesQueryVariables>;
export const MFAFactorPaginationDocument = gql`
	query mfaFactorPagination($filters: [MFAFactorFilterInput!], $order: MFAFactorOrder) {
		mfaFactorConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useMFAFactorPaginationQuery__
 *
 * To run a query within a React component, call `useMFAFactorPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useMFAFactorPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMFAFactorPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useMFAFactorPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<MFAFactorPaginationQuery, MFAFactorPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<MFAFactorPaginationQuery, MFAFactorPaginationQueryVariables>(
		MFAFactorPaginationDocument,
		options,
	);
}
export function useMFAFactorPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<MFAFactorPaginationQuery, MFAFactorPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<MFAFactorPaginationQuery, MFAFactorPaginationQueryVariables>(
		MFAFactorPaginationDocument,
		options,
	);
}
export type MFAFactorPaginationQueryHookResult = ReturnType<typeof useMFAFactorPaginationQuery>;
export type MFAFactorPaginationLazyQueryHookResult = ReturnType<typeof useMFAFactorPaginationLazyQuery>;
export type MFAFactorPaginationQueryResult = Apollo.QueryResult<
	MFAFactorPaginationQuery,
	MFAFactorPaginationQueryVariables
>;
export const MFAFactorSelectDocument = gql`
	query mfaFactorSelect($filters: [MFAFactorFilterInput!], $order: MFAFactorOrder) {
		mfaFactorConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...MFAFactorName
				}
			}
		}
	}
	${MFAFactorNameFragmentDoc}
`;

/**
 * __useMFAFactorSelectQuery__
 *
 * To run a query within a React component, call `useMFAFactorSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useMFAFactorSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMFAFactorSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useMFAFactorSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<MFAFactorSelectQuery, MFAFactorSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<MFAFactorSelectQuery, MFAFactorSelectQueryVariables>(MFAFactorSelectDocument, options);
}
export function useMFAFactorSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<MFAFactorSelectQuery, MFAFactorSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<MFAFactorSelectQuery, MFAFactorSelectQueryVariables>(MFAFactorSelectDocument, options);
}
export type MFAFactorSelectQueryHookResult = ReturnType<typeof useMFAFactorSelectQuery>;
export type MFAFactorSelectLazyQueryHookResult = ReturnType<typeof useMFAFactorSelectLazyQuery>;
export type MFAFactorSelectQueryResult = Apollo.QueryResult<MFAFactorSelectQuery, MFAFactorSelectQueryVariables>;
export const MFAInvalidOTPAttemptCountDocument = gql`
	query mfaInvalidOTPAttemptCount(
		$filters: [MFAInvalidOTPAttemptFilterInput!]
		$pagination: Pagination
		$order: MFAInvalidOTPAttemptOrder
	) {
		mfaInvalidOTPAttemptConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useMFAInvalidOTPAttemptCountQuery__
 *
 * To run a query within a React component, call `useMFAInvalidOTPAttemptCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useMFAInvalidOTPAttemptCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMFAInvalidOTPAttemptCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useMFAInvalidOTPAttemptCountQuery(
	baseOptions?: Apollo.QueryHookOptions<MFAInvalidOTPAttemptCountQuery, MFAInvalidOTPAttemptCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<MFAInvalidOTPAttemptCountQuery, MFAInvalidOTPAttemptCountQueryVariables>(
		MFAInvalidOTPAttemptCountDocument,
		options,
	);
}
export function useMFAInvalidOTPAttemptCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<MFAInvalidOTPAttemptCountQuery, MFAInvalidOTPAttemptCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<MFAInvalidOTPAttemptCountQuery, MFAInvalidOTPAttemptCountQueryVariables>(
		MFAInvalidOTPAttemptCountDocument,
		options,
	);
}
export type MFAInvalidOTPAttemptCountQueryHookResult = ReturnType<typeof useMFAInvalidOTPAttemptCountQuery>;
export type MFAInvalidOTPAttemptCountLazyQueryHookResult = ReturnType<typeof useMFAInvalidOTPAttemptCountLazyQuery>;
export type MFAInvalidOTPAttemptCountQueryResult = Apollo.QueryResult<
	MFAInvalidOTPAttemptCountQuery,
	MFAInvalidOTPAttemptCountQueryVariables
>;
export const MFAInvalidOTPAttemptDetailDocument = gql`
	query mfaInvalidOTPAttemptDetail($id: ID!) {
		mfaInvalidOTPAttempt(id: $id) {
			...MfaInvalidOTPAttemptDetail
		}
	}
	${MFAInvalidOTPAttemptDetailFragmentDoc}
`;

/**
 * __useMFAInvalidOTPAttemptDetailQuery__
 *
 * To run a query within a React component, call `useMFAInvalidOTPAttemptDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useMFAInvalidOTPAttemptDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMFAInvalidOTPAttemptDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMFAInvalidOTPAttemptDetailQuery(
	baseOptions: Apollo.QueryHookOptions<MFAInvalidOTPAttemptDetailQuery, MFAInvalidOTPAttemptDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<MFAInvalidOTPAttemptDetailQuery, MFAInvalidOTPAttemptDetailQueryVariables>(
		MFAInvalidOTPAttemptDetailDocument,
		options,
	);
}
export function useMFAInvalidOTPAttemptDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<MFAInvalidOTPAttemptDetailQuery, MFAInvalidOTPAttemptDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<MFAInvalidOTPAttemptDetailQuery, MFAInvalidOTPAttemptDetailQueryVariables>(
		MFAInvalidOTPAttemptDetailDocument,
		options,
	);
}
export type MFAInvalidOTPAttemptDetailQueryHookResult = ReturnType<typeof useMFAInvalidOTPAttemptDetailQuery>;
export type MFAInvalidOTPAttemptDetailLazyQueryHookResult = ReturnType<typeof useMFAInvalidOTPAttemptDetailLazyQuery>;
export type MFAInvalidOTPAttemptDetailQueryResult = Apollo.QueryResult<
	MFAInvalidOTPAttemptDetailQuery,
	MFAInvalidOTPAttemptDetailQueryVariables
>;
export const MFAInvalidOTPAttemptIndexDocument = gql`
	query mfaInvalidOTPAttemptIndex(
		$filters: [MFAInvalidOTPAttemptFilterInput!]
		$pagination: Pagination
		$order: MFAInvalidOTPAttemptOrder
	) {
		mfaInvalidOTPAttemptConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					createdAt
				}
			}
		}
	}
`;

/**
 * __useMFAInvalidOTPAttemptIndexQuery__
 *
 * To run a query within a React component, call `useMFAInvalidOTPAttemptIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useMFAInvalidOTPAttemptIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMFAInvalidOTPAttemptIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useMFAInvalidOTPAttemptIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<MFAInvalidOTPAttemptIndexQuery, MFAInvalidOTPAttemptIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<MFAInvalidOTPAttemptIndexQuery, MFAInvalidOTPAttemptIndexQueryVariables>(
		MFAInvalidOTPAttemptIndexDocument,
		options,
	);
}
export function useMFAInvalidOTPAttemptIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<MFAInvalidOTPAttemptIndexQuery, MFAInvalidOTPAttemptIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<MFAInvalidOTPAttemptIndexQuery, MFAInvalidOTPAttemptIndexQueryVariables>(
		MFAInvalidOTPAttemptIndexDocument,
		options,
	);
}
export type MFAInvalidOTPAttemptIndexQueryHookResult = ReturnType<typeof useMFAInvalidOTPAttemptIndexQuery>;
export type MFAInvalidOTPAttemptIndexLazyQueryHookResult = ReturnType<typeof useMFAInvalidOTPAttemptIndexLazyQuery>;
export type MFAInvalidOTPAttemptIndexQueryResult = Apollo.QueryResult<
	MFAInvalidOTPAttemptIndexQuery,
	MFAInvalidOTPAttemptIndexQueryVariables
>;
export const MFAInvalidOTPAttemptNameDocument = gql`
	query mfaInvalidOTPAttemptName($id: ID!) {
		mfaInvalidOTPAttempt(id: $id) {
			id
			...MFAInvalidOTPAttemptName
		}
	}
	${MFAInvalidOTPAttemptNameFragmentDoc}
`;

/**
 * __useMFAInvalidOTPAttemptNameQuery__
 *
 * To run a query within a React component, call `useMFAInvalidOTPAttemptNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useMFAInvalidOTPAttemptNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMFAInvalidOTPAttemptNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMFAInvalidOTPAttemptNameQuery(
	baseOptions: Apollo.QueryHookOptions<MFAInvalidOTPAttemptNameQuery, MFAInvalidOTPAttemptNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<MFAInvalidOTPAttemptNameQuery, MFAInvalidOTPAttemptNameQueryVariables>(
		MFAInvalidOTPAttemptNameDocument,
		options,
	);
}
export function useMFAInvalidOTPAttemptNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<MFAInvalidOTPAttemptNameQuery, MFAInvalidOTPAttemptNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<MFAInvalidOTPAttemptNameQuery, MFAInvalidOTPAttemptNameQueryVariables>(
		MFAInvalidOTPAttemptNameDocument,
		options,
	);
}
export type MFAInvalidOTPAttemptNameQueryHookResult = ReturnType<typeof useMFAInvalidOTPAttemptNameQuery>;
export type MFAInvalidOTPAttemptNameLazyQueryHookResult = ReturnType<typeof useMFAInvalidOTPAttemptNameLazyQuery>;
export type MFAInvalidOTPAttemptNameQueryResult = Apollo.QueryResult<
	MFAInvalidOTPAttemptNameQuery,
	MFAInvalidOTPAttemptNameQueryVariables
>;
export const MFAInvalidOTPAttemptNamesDocument = gql`
	query mfaInvalidOTPAttemptNames(
		$filters: [MFAInvalidOTPAttemptFilterInput!]
		$pagination: Pagination
		$order: MFAInvalidOTPAttemptOrder
	) {
		mfaInvalidOTPAttemptConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...MFAInvalidOTPAttemptName
				}
			}
		}
	}
	${MFAInvalidOTPAttemptNameFragmentDoc}
`;

/**
 * __useMFAInvalidOTPAttemptNamesQuery__
 *
 * To run a query within a React component, call `useMFAInvalidOTPAttemptNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMFAInvalidOTPAttemptNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMFAInvalidOTPAttemptNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useMFAInvalidOTPAttemptNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<MFAInvalidOTPAttemptNamesQuery, MFAInvalidOTPAttemptNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<MFAInvalidOTPAttemptNamesQuery, MFAInvalidOTPAttemptNamesQueryVariables>(
		MFAInvalidOTPAttemptNamesDocument,
		options,
	);
}
export function useMFAInvalidOTPAttemptNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<MFAInvalidOTPAttemptNamesQuery, MFAInvalidOTPAttemptNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<MFAInvalidOTPAttemptNamesQuery, MFAInvalidOTPAttemptNamesQueryVariables>(
		MFAInvalidOTPAttemptNamesDocument,
		options,
	);
}
export type MFAInvalidOTPAttemptNamesQueryHookResult = ReturnType<typeof useMFAInvalidOTPAttemptNamesQuery>;
export type MFAInvalidOTPAttemptNamesLazyQueryHookResult = ReturnType<typeof useMFAInvalidOTPAttemptNamesLazyQuery>;
export type MFAInvalidOTPAttemptNamesQueryResult = Apollo.QueryResult<
	MFAInvalidOTPAttemptNamesQuery,
	MFAInvalidOTPAttemptNamesQueryVariables
>;
export const MFAInvalidOTPAttemptPaginationDocument = gql`
	query mfaInvalidOTPAttemptPagination(
		$filters: [MFAInvalidOTPAttemptFilterInput!]
		$order: MFAInvalidOTPAttemptOrder
	) {
		mfaInvalidOTPAttemptConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useMFAInvalidOTPAttemptPaginationQuery__
 *
 * To run a query within a React component, call `useMFAInvalidOTPAttemptPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useMFAInvalidOTPAttemptPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMFAInvalidOTPAttemptPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useMFAInvalidOTPAttemptPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<
		MFAInvalidOTPAttemptPaginationQuery,
		MFAInvalidOTPAttemptPaginationQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<MFAInvalidOTPAttemptPaginationQuery, MFAInvalidOTPAttemptPaginationQueryVariables>(
		MFAInvalidOTPAttemptPaginationDocument,
		options,
	);
}
export function useMFAInvalidOTPAttemptPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		MFAInvalidOTPAttemptPaginationQuery,
		MFAInvalidOTPAttemptPaginationQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<MFAInvalidOTPAttemptPaginationQuery, MFAInvalidOTPAttemptPaginationQueryVariables>(
		MFAInvalidOTPAttemptPaginationDocument,
		options,
	);
}
export type MFAInvalidOTPAttemptPaginationQueryHookResult = ReturnType<typeof useMFAInvalidOTPAttemptPaginationQuery>;
export type MFAInvalidOTPAttemptPaginationLazyQueryHookResult = ReturnType<
	typeof useMFAInvalidOTPAttemptPaginationLazyQuery
>;
export type MFAInvalidOTPAttemptPaginationQueryResult = Apollo.QueryResult<
	MFAInvalidOTPAttemptPaginationQuery,
	MFAInvalidOTPAttemptPaginationQueryVariables
>;
export const MFAInvalidOTPAttemptSelectDocument = gql`
	query mfaInvalidOTPAttemptSelect($filters: [MFAInvalidOTPAttemptFilterInput!], $order: MFAInvalidOTPAttemptOrder) {
		mfaInvalidOTPAttemptConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...MFAInvalidOTPAttemptName
				}
			}
		}
	}
	${MFAInvalidOTPAttemptNameFragmentDoc}
`;

/**
 * __useMFAInvalidOTPAttemptSelectQuery__
 *
 * To run a query within a React component, call `useMFAInvalidOTPAttemptSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useMFAInvalidOTPAttemptSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMFAInvalidOTPAttemptSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useMFAInvalidOTPAttemptSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<MFAInvalidOTPAttemptSelectQuery, MFAInvalidOTPAttemptSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<MFAInvalidOTPAttemptSelectQuery, MFAInvalidOTPAttemptSelectQueryVariables>(
		MFAInvalidOTPAttemptSelectDocument,
		options,
	);
}
export function useMFAInvalidOTPAttemptSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<MFAInvalidOTPAttemptSelectQuery, MFAInvalidOTPAttemptSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<MFAInvalidOTPAttemptSelectQuery, MFAInvalidOTPAttemptSelectQueryVariables>(
		MFAInvalidOTPAttemptSelectDocument,
		options,
	);
}
export type MFAInvalidOTPAttemptSelectQueryHookResult = ReturnType<typeof useMFAInvalidOTPAttemptSelectQuery>;
export type MFAInvalidOTPAttemptSelectLazyQueryHookResult = ReturnType<typeof useMFAInvalidOTPAttemptSelectLazyQuery>;
export type MFAInvalidOTPAttemptSelectQueryResult = Apollo.QueryResult<
	MFAInvalidOTPAttemptSelectQuery,
	MFAInvalidOTPAttemptSelectQueryVariables
>;
export const MealMenuCountDocument = gql`
	query mealMenuCount($filters: [MealMenuFilterInput!], $pagination: Pagination, $order: MealMenuOrder) {
		mealMenuConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useMealMenuCountQuery__
 *
 * To run a query within a React component, call `useMealMenuCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useMealMenuCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMealMenuCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useMealMenuCountQuery(
	baseOptions?: Apollo.QueryHookOptions<MealMenuCountQuery, MealMenuCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<MealMenuCountQuery, MealMenuCountQueryVariables>(MealMenuCountDocument, options);
}
export function useMealMenuCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<MealMenuCountQuery, MealMenuCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<MealMenuCountQuery, MealMenuCountQueryVariables>(MealMenuCountDocument, options);
}
export type MealMenuCountQueryHookResult = ReturnType<typeof useMealMenuCountQuery>;
export type MealMenuCountLazyQueryHookResult = ReturnType<typeof useMealMenuCountLazyQuery>;
export type MealMenuCountQueryResult = Apollo.QueryResult<MealMenuCountQuery, MealMenuCountQueryVariables>;
export const MealMenuCreateDocument = gql`
	mutation mealMenuCreate($input: MealMenuCreateInput!) {
		createMealMenu(input: $input) {
			...MealMenuDetail
		}
	}
	${MealMenuDetailFragmentDoc}
`;
export type MealMenuCreateMutationFn = Apollo.MutationFunction<MealMenuCreateMutation, MealMenuCreateMutationVariables>;

/**
 * __useMealMenuCreateMutation__
 *
 * To run a mutation, you first call `useMealMenuCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMealMenuCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mealMenuCreateMutation, { data, loading, error }] = useMealMenuCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMealMenuCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<MealMenuCreateMutation, MealMenuCreateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<MealMenuCreateMutation, MealMenuCreateMutationVariables>(MealMenuCreateDocument, options);
}
export type MealMenuCreateMutationHookResult = ReturnType<typeof useMealMenuCreateMutation>;
export type MealMenuCreateMutationResult = Apollo.MutationResult<MealMenuCreateMutation>;
export type MealMenuCreateMutationOptions = Apollo.BaseMutationOptions<
	MealMenuCreateMutation,
	MealMenuCreateMutationVariables
>;
export const MealMenuCreateBulkDocument = gql`
	mutation mealMenuCreateBulk($inputs: [MealMenuCreateInput!]!) {
		createBulkMealMenus(inputs: $inputs) {
			edges {
				node {
					...MealMenuDetail
				}
			}
		}
	}
	${MealMenuDetailFragmentDoc}
`;
export type MealMenuCreateBulkMutationFn = Apollo.MutationFunction<
	MealMenuCreateBulkMutation,
	MealMenuCreateBulkMutationVariables
>;

/**
 * __useMealMenuCreateBulkMutation__
 *
 * To run a mutation, you first call `useMealMenuCreateBulkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMealMenuCreateBulkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mealMenuCreateBulkMutation, { data, loading, error }] = useMealMenuCreateBulkMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useMealMenuCreateBulkMutation(
	baseOptions?: Apollo.MutationHookOptions<MealMenuCreateBulkMutation, MealMenuCreateBulkMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<MealMenuCreateBulkMutation, MealMenuCreateBulkMutationVariables>(
		MealMenuCreateBulkDocument,
		options,
	);
}
export type MealMenuCreateBulkMutationHookResult = ReturnType<typeof useMealMenuCreateBulkMutation>;
export type MealMenuCreateBulkMutationResult = Apollo.MutationResult<MealMenuCreateBulkMutation>;
export type MealMenuCreateBulkMutationOptions = Apollo.BaseMutationOptions<
	MealMenuCreateBulkMutation,
	MealMenuCreateBulkMutationVariables
>;
export const MealMenuDeleteDocument = gql`
	mutation mealMenuDelete($id: ID!) {
		deleteMealMenu(id: $id)
	}
`;
export type MealMenuDeleteMutationFn = Apollo.MutationFunction<MealMenuDeleteMutation, MealMenuDeleteMutationVariables>;

/**
 * __useMealMenuDeleteMutation__
 *
 * To run a mutation, you first call `useMealMenuDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMealMenuDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mealMenuDeleteMutation, { data, loading, error }] = useMealMenuDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMealMenuDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<MealMenuDeleteMutation, MealMenuDeleteMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<MealMenuDeleteMutation, MealMenuDeleteMutationVariables>(MealMenuDeleteDocument, options);
}
export type MealMenuDeleteMutationHookResult = ReturnType<typeof useMealMenuDeleteMutation>;
export type MealMenuDeleteMutationResult = Apollo.MutationResult<MealMenuDeleteMutation>;
export type MealMenuDeleteMutationOptions = Apollo.BaseMutationOptions<
	MealMenuDeleteMutation,
	MealMenuDeleteMutationVariables
>;
export const MealMenuDetailDocument = gql`
	query mealMenuDetail($id: ID!) {
		mealMenu(id: $id) {
			...MealMenuDetail
		}
	}
	${MealMenuDetailFragmentDoc}
`;

/**
 * __useMealMenuDetailQuery__
 *
 * To run a query within a React component, call `useMealMenuDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useMealMenuDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMealMenuDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMealMenuDetailQuery(
	baseOptions: Apollo.QueryHookOptions<MealMenuDetailQuery, MealMenuDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<MealMenuDetailQuery, MealMenuDetailQueryVariables>(MealMenuDetailDocument, options);
}
export function useMealMenuDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<MealMenuDetailQuery, MealMenuDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<MealMenuDetailQuery, MealMenuDetailQueryVariables>(MealMenuDetailDocument, options);
}
export type MealMenuDetailQueryHookResult = ReturnType<typeof useMealMenuDetailQuery>;
export type MealMenuDetailLazyQueryHookResult = ReturnType<typeof useMealMenuDetailLazyQuery>;
export type MealMenuDetailQueryResult = Apollo.QueryResult<MealMenuDetailQuery, MealMenuDetailQueryVariables>;
export const MealMenuIndexDocument = gql`
	query mealMenuIndex($filters: [MealMenuFilterInput!], $pagination: Pagination, $order: MealMenuOrder) {
		mealMenuConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					menuDate
					breakfast
					lunch
					snack
					vegetarianBreakfast
					vegetarianLunch
					vegetarianSnack
				}
			}
		}
	}
`;

/**
 * __useMealMenuIndexQuery__
 *
 * To run a query within a React component, call `useMealMenuIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useMealMenuIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMealMenuIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useMealMenuIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<MealMenuIndexQuery, MealMenuIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<MealMenuIndexQuery, MealMenuIndexQueryVariables>(MealMenuIndexDocument, options);
}
export function useMealMenuIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<MealMenuIndexQuery, MealMenuIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<MealMenuIndexQuery, MealMenuIndexQueryVariables>(MealMenuIndexDocument, options);
}
export type MealMenuIndexQueryHookResult = ReturnType<typeof useMealMenuIndexQuery>;
export type MealMenuIndexLazyQueryHookResult = ReturnType<typeof useMealMenuIndexLazyQuery>;
export type MealMenuIndexQueryResult = Apollo.QueryResult<MealMenuIndexQuery, MealMenuIndexQueryVariables>;
export const MealMenuNameDocument = gql`
	query mealMenuName($id: ID!) {
		mealMenu(id: $id) {
			id
			...MealMenuName
		}
	}
	${MealMenuNameFragmentDoc}
`;

/**
 * __useMealMenuNameQuery__
 *
 * To run a query within a React component, call `useMealMenuNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useMealMenuNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMealMenuNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMealMenuNameQuery(
	baseOptions: Apollo.QueryHookOptions<MealMenuNameQuery, MealMenuNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<MealMenuNameQuery, MealMenuNameQueryVariables>(MealMenuNameDocument, options);
}
export function useMealMenuNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<MealMenuNameQuery, MealMenuNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<MealMenuNameQuery, MealMenuNameQueryVariables>(MealMenuNameDocument, options);
}
export type MealMenuNameQueryHookResult = ReturnType<typeof useMealMenuNameQuery>;
export type MealMenuNameLazyQueryHookResult = ReturnType<typeof useMealMenuNameLazyQuery>;
export type MealMenuNameQueryResult = Apollo.QueryResult<MealMenuNameQuery, MealMenuNameQueryVariables>;
export const MealMenuNamesDocument = gql`
	query mealMenuNames($filters: [MealMenuFilterInput!], $pagination: Pagination, $order: MealMenuOrder) {
		mealMenuConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...MealMenuName
				}
			}
		}
	}
	${MealMenuNameFragmentDoc}
`;

/**
 * __useMealMenuNamesQuery__
 *
 * To run a query within a React component, call `useMealMenuNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMealMenuNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMealMenuNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useMealMenuNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<MealMenuNamesQuery, MealMenuNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<MealMenuNamesQuery, MealMenuNamesQueryVariables>(MealMenuNamesDocument, options);
}
export function useMealMenuNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<MealMenuNamesQuery, MealMenuNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<MealMenuNamesQuery, MealMenuNamesQueryVariables>(MealMenuNamesDocument, options);
}
export type MealMenuNamesQueryHookResult = ReturnType<typeof useMealMenuNamesQuery>;
export type MealMenuNamesLazyQueryHookResult = ReturnType<typeof useMealMenuNamesLazyQuery>;
export type MealMenuNamesQueryResult = Apollo.QueryResult<MealMenuNamesQuery, MealMenuNamesQueryVariables>;
export const MealMenuPaginationDocument = gql`
	query mealMenuPagination($filters: [MealMenuFilterInput!], $order: MealMenuOrder) {
		mealMenuConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useMealMenuPaginationQuery__
 *
 * To run a query within a React component, call `useMealMenuPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useMealMenuPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMealMenuPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useMealMenuPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<MealMenuPaginationQuery, MealMenuPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<MealMenuPaginationQuery, MealMenuPaginationQueryVariables>(
		MealMenuPaginationDocument,
		options,
	);
}
export function useMealMenuPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<MealMenuPaginationQuery, MealMenuPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<MealMenuPaginationQuery, MealMenuPaginationQueryVariables>(
		MealMenuPaginationDocument,
		options,
	);
}
export type MealMenuPaginationQueryHookResult = ReturnType<typeof useMealMenuPaginationQuery>;
export type MealMenuPaginationLazyQueryHookResult = ReturnType<typeof useMealMenuPaginationLazyQuery>;
export type MealMenuPaginationQueryResult = Apollo.QueryResult<
	MealMenuPaginationQuery,
	MealMenuPaginationQueryVariables
>;
export const MealMenuSelectDocument = gql`
	query mealMenuSelect($filters: [MealMenuFilterInput!], $order: MealMenuOrder) {
		mealMenuConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...MealMenuName
				}
			}
		}
	}
	${MealMenuNameFragmentDoc}
`;

/**
 * __useMealMenuSelectQuery__
 *
 * To run a query within a React component, call `useMealMenuSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useMealMenuSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMealMenuSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useMealMenuSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<MealMenuSelectQuery, MealMenuSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<MealMenuSelectQuery, MealMenuSelectQueryVariables>(MealMenuSelectDocument, options);
}
export function useMealMenuSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<MealMenuSelectQuery, MealMenuSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<MealMenuSelectQuery, MealMenuSelectQueryVariables>(MealMenuSelectDocument, options);
}
export type MealMenuSelectQueryHookResult = ReturnType<typeof useMealMenuSelectQuery>;
export type MealMenuSelectLazyQueryHookResult = ReturnType<typeof useMealMenuSelectLazyQuery>;
export type MealMenuSelectQueryResult = Apollo.QueryResult<MealMenuSelectQuery, MealMenuSelectQueryVariables>;
export const MealMenuUpdateDocument = gql`
	mutation mealMenuUpdate($id: ID!, $input: MealMenuUpdateInput!) {
		updateMealMenu(id: $id, input: $input) {
			...MealMenuDetail
		}
	}
	${MealMenuDetailFragmentDoc}
`;
export type MealMenuUpdateMutationFn = Apollo.MutationFunction<MealMenuUpdateMutation, MealMenuUpdateMutationVariables>;

/**
 * __useMealMenuUpdateMutation__
 *
 * To run a mutation, you first call `useMealMenuUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMealMenuUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mealMenuUpdateMutation, { data, loading, error }] = useMealMenuUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMealMenuUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<MealMenuUpdateMutation, MealMenuUpdateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<MealMenuUpdateMutation, MealMenuUpdateMutationVariables>(MealMenuUpdateDocument, options);
}
export type MealMenuUpdateMutationHookResult = ReturnType<typeof useMealMenuUpdateMutation>;
export type MealMenuUpdateMutationResult = Apollo.MutationResult<MealMenuUpdateMutation>;
export type MealMenuUpdateMutationOptions = Apollo.BaseMutationOptions<
	MealMenuUpdateMutation,
	MealMenuUpdateMutationVariables
>;
export const MealPlanCountDocument = gql`
	query mealPlanCount($filters: [MealPlanFilterInput!], $pagination: Pagination, $order: MealPlanOrder) {
		mealPlanConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useMealPlanCountQuery__
 *
 * To run a query within a React component, call `useMealPlanCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useMealPlanCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMealPlanCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useMealPlanCountQuery(
	baseOptions?: Apollo.QueryHookOptions<MealPlanCountQuery, MealPlanCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<MealPlanCountQuery, MealPlanCountQueryVariables>(MealPlanCountDocument, options);
}
export function useMealPlanCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<MealPlanCountQuery, MealPlanCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<MealPlanCountQuery, MealPlanCountQueryVariables>(MealPlanCountDocument, options);
}
export type MealPlanCountQueryHookResult = ReturnType<typeof useMealPlanCountQuery>;
export type MealPlanCountLazyQueryHookResult = ReturnType<typeof useMealPlanCountLazyQuery>;
export type MealPlanCountQueryResult = Apollo.QueryResult<MealPlanCountQuery, MealPlanCountQueryVariables>;
export const MealPlanCreateDocument = gql`
	mutation mealPlanCreate($input: MealPlanCreateInput!) {
		createMealPlan(input: $input) {
			...MealPlanDetail
		}
	}
	${MealPlanDetailFragmentDoc}
`;
export type MealPlanCreateMutationFn = Apollo.MutationFunction<MealPlanCreateMutation, MealPlanCreateMutationVariables>;

/**
 * __useMealPlanCreateMutation__
 *
 * To run a mutation, you first call `useMealPlanCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMealPlanCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mealPlanCreateMutation, { data, loading, error }] = useMealPlanCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMealPlanCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<MealPlanCreateMutation, MealPlanCreateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<MealPlanCreateMutation, MealPlanCreateMutationVariables>(MealPlanCreateDocument, options);
}
export type MealPlanCreateMutationHookResult = ReturnType<typeof useMealPlanCreateMutation>;
export type MealPlanCreateMutationResult = Apollo.MutationResult<MealPlanCreateMutation>;
export type MealPlanCreateMutationOptions = Apollo.BaseMutationOptions<
	MealPlanCreateMutation,
	MealPlanCreateMutationVariables
>;
export const MealPlanDeleteDocument = gql`
	mutation mealPlanDelete($id: ID!) {
		deleteMealPlan(id: $id)
	}
`;
export type MealPlanDeleteMutationFn = Apollo.MutationFunction<MealPlanDeleteMutation, MealPlanDeleteMutationVariables>;

/**
 * __useMealPlanDeleteMutation__
 *
 * To run a mutation, you first call `useMealPlanDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMealPlanDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mealPlanDeleteMutation, { data, loading, error }] = useMealPlanDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMealPlanDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<MealPlanDeleteMutation, MealPlanDeleteMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<MealPlanDeleteMutation, MealPlanDeleteMutationVariables>(MealPlanDeleteDocument, options);
}
export type MealPlanDeleteMutationHookResult = ReturnType<typeof useMealPlanDeleteMutation>;
export type MealPlanDeleteMutationResult = Apollo.MutationResult<MealPlanDeleteMutation>;
export type MealPlanDeleteMutationOptions = Apollo.BaseMutationOptions<
	MealPlanDeleteMutation,
	MealPlanDeleteMutationVariables
>;
export const MealPlanDetailDocument = gql`
	query mealPlanDetail($id: ID!) {
		mealPlan(id: $id) {
			...MealPlanDetail
		}
	}
	${MealPlanDetailFragmentDoc}
`;

/**
 * __useMealPlanDetailQuery__
 *
 * To run a query within a React component, call `useMealPlanDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useMealPlanDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMealPlanDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMealPlanDetailQuery(
	baseOptions: Apollo.QueryHookOptions<MealPlanDetailQuery, MealPlanDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<MealPlanDetailQuery, MealPlanDetailQueryVariables>(MealPlanDetailDocument, options);
}
export function useMealPlanDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<MealPlanDetailQuery, MealPlanDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<MealPlanDetailQuery, MealPlanDetailQueryVariables>(MealPlanDetailDocument, options);
}
export type MealPlanDetailQueryHookResult = ReturnType<typeof useMealPlanDetailQuery>;
export type MealPlanDetailLazyQueryHookResult = ReturnType<typeof useMealPlanDetailLazyQuery>;
export type MealPlanDetailQueryResult = Apollo.QueryResult<MealPlanDetailQuery, MealPlanDetailQueryVariables>;
export const MealPlanIndexDocument = gql`
	query mealPlanIndex($filters: [MealPlanFilterInput!], $pagination: Pagination, $order: MealPlanOrder) {
		mealPlanConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					student {
						id
						person {
							id
							name
						}
					}
					notes
				}
			}
		}
	}
`;

/**
 * __useMealPlanIndexQuery__
 *
 * To run a query within a React component, call `useMealPlanIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useMealPlanIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMealPlanIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useMealPlanIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<MealPlanIndexQuery, MealPlanIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<MealPlanIndexQuery, MealPlanIndexQueryVariables>(MealPlanIndexDocument, options);
}
export function useMealPlanIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<MealPlanIndexQuery, MealPlanIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<MealPlanIndexQuery, MealPlanIndexQueryVariables>(MealPlanIndexDocument, options);
}
export type MealPlanIndexQueryHookResult = ReturnType<typeof useMealPlanIndexQuery>;
export type MealPlanIndexLazyQueryHookResult = ReturnType<typeof useMealPlanIndexLazyQuery>;
export type MealPlanIndexQueryResult = Apollo.QueryResult<MealPlanIndexQuery, MealPlanIndexQueryVariables>;
export const MealPlanNameDocument = gql`
	query mealPlanName($id: ID!) {
		mealPlan(id: $id) {
			id
			...MealPlanName
		}
	}
	${MealPlanNameFragmentDoc}
`;

/**
 * __useMealPlanNameQuery__
 *
 * To run a query within a React component, call `useMealPlanNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useMealPlanNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMealPlanNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMealPlanNameQuery(
	baseOptions: Apollo.QueryHookOptions<MealPlanNameQuery, MealPlanNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<MealPlanNameQuery, MealPlanNameQueryVariables>(MealPlanNameDocument, options);
}
export function useMealPlanNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<MealPlanNameQuery, MealPlanNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<MealPlanNameQuery, MealPlanNameQueryVariables>(MealPlanNameDocument, options);
}
export type MealPlanNameQueryHookResult = ReturnType<typeof useMealPlanNameQuery>;
export type MealPlanNameLazyQueryHookResult = ReturnType<typeof useMealPlanNameLazyQuery>;
export type MealPlanNameQueryResult = Apollo.QueryResult<MealPlanNameQuery, MealPlanNameQueryVariables>;
export const MealPlanNamesDocument = gql`
	query mealPlanNames($filters: [MealPlanFilterInput!], $pagination: Pagination, $order: MealPlanOrder) {
		mealPlanConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...MealPlanName
				}
			}
		}
	}
	${MealPlanNameFragmentDoc}
`;

/**
 * __useMealPlanNamesQuery__
 *
 * To run a query within a React component, call `useMealPlanNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMealPlanNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMealPlanNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useMealPlanNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<MealPlanNamesQuery, MealPlanNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<MealPlanNamesQuery, MealPlanNamesQueryVariables>(MealPlanNamesDocument, options);
}
export function useMealPlanNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<MealPlanNamesQuery, MealPlanNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<MealPlanNamesQuery, MealPlanNamesQueryVariables>(MealPlanNamesDocument, options);
}
export type MealPlanNamesQueryHookResult = ReturnType<typeof useMealPlanNamesQuery>;
export type MealPlanNamesLazyQueryHookResult = ReturnType<typeof useMealPlanNamesLazyQuery>;
export type MealPlanNamesQueryResult = Apollo.QueryResult<MealPlanNamesQuery, MealPlanNamesQueryVariables>;
export const MealPlanPaginationDocument = gql`
	query mealPlanPagination($filters: [MealPlanFilterInput!], $order: MealPlanOrder) {
		mealPlanConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useMealPlanPaginationQuery__
 *
 * To run a query within a React component, call `useMealPlanPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useMealPlanPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMealPlanPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useMealPlanPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<MealPlanPaginationQuery, MealPlanPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<MealPlanPaginationQuery, MealPlanPaginationQueryVariables>(
		MealPlanPaginationDocument,
		options,
	);
}
export function useMealPlanPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<MealPlanPaginationQuery, MealPlanPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<MealPlanPaginationQuery, MealPlanPaginationQueryVariables>(
		MealPlanPaginationDocument,
		options,
	);
}
export type MealPlanPaginationQueryHookResult = ReturnType<typeof useMealPlanPaginationQuery>;
export type MealPlanPaginationLazyQueryHookResult = ReturnType<typeof useMealPlanPaginationLazyQuery>;
export type MealPlanPaginationQueryResult = Apollo.QueryResult<
	MealPlanPaginationQuery,
	MealPlanPaginationQueryVariables
>;
export const MealPlanSelectDocument = gql`
	query mealPlanSelect($filters: [MealPlanFilterInput!], $order: MealPlanOrder) {
		mealPlanConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...MealPlanName
				}
			}
		}
	}
	${MealPlanNameFragmentDoc}
`;

/**
 * __useMealPlanSelectQuery__
 *
 * To run a query within a React component, call `useMealPlanSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useMealPlanSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMealPlanSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useMealPlanSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<MealPlanSelectQuery, MealPlanSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<MealPlanSelectQuery, MealPlanSelectQueryVariables>(MealPlanSelectDocument, options);
}
export function useMealPlanSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<MealPlanSelectQuery, MealPlanSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<MealPlanSelectQuery, MealPlanSelectQueryVariables>(MealPlanSelectDocument, options);
}
export type MealPlanSelectQueryHookResult = ReturnType<typeof useMealPlanSelectQuery>;
export type MealPlanSelectLazyQueryHookResult = ReturnType<typeof useMealPlanSelectLazyQuery>;
export type MealPlanSelectQueryResult = Apollo.QueryResult<MealPlanSelectQuery, MealPlanSelectQueryVariables>;
export const MealPlanUpdateDocument = gql`
	mutation mealPlanUpdate($id: ID!, $input: MealPlanUpdateInput!) {
		updateMealPlan(id: $id, input: $input) {
			...MealPlanDetail
		}
	}
	${MealPlanDetailFragmentDoc}
`;
export type MealPlanUpdateMutationFn = Apollo.MutationFunction<MealPlanUpdateMutation, MealPlanUpdateMutationVariables>;

/**
 * __useMealPlanUpdateMutation__
 *
 * To run a mutation, you first call `useMealPlanUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMealPlanUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mealPlanUpdateMutation, { data, loading, error }] = useMealPlanUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMealPlanUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<MealPlanUpdateMutation, MealPlanUpdateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<MealPlanUpdateMutation, MealPlanUpdateMutationVariables>(MealPlanUpdateDocument, options);
}
export type MealPlanUpdateMutationHookResult = ReturnType<typeof useMealPlanUpdateMutation>;
export type MealPlanUpdateMutationResult = Apollo.MutationResult<MealPlanUpdateMutation>;
export type MealPlanUpdateMutationOptions = Apollo.BaseMutationOptions<
	MealPlanUpdateMutation,
	MealPlanUpdateMutationVariables
>;
export const AnnouncementsDocument = gql`
	query announcements($filters: [NoteFilterInput!], $pagination: Pagination, $order: NoteOrder) {
		noteConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					noteType
					title
					body
					createdAt
					isTimeDependent
					startDate
					endDate
					author {
						id
						person {
							id
							name
							imageURL
						}
					}
					attachments {
						id
						order
						url
						isFile
						fileContentType
					}
				}
			}
		}
	}
`;

/**
 * __useAnnouncementsQuery__
 *
 * To run a query within a React component, call `useAnnouncementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnnouncementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnnouncementsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useAnnouncementsQuery(
	baseOptions?: Apollo.QueryHookOptions<AnnouncementsQuery, AnnouncementsQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<AnnouncementsQuery, AnnouncementsQueryVariables>(AnnouncementsDocument, options);
}
export function useAnnouncementsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<AnnouncementsQuery, AnnouncementsQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<AnnouncementsQuery, AnnouncementsQueryVariables>(AnnouncementsDocument, options);
}
export type AnnouncementsQueryHookResult = ReturnType<typeof useAnnouncementsQuery>;
export type AnnouncementsLazyQueryHookResult = ReturnType<typeof useAnnouncementsLazyQuery>;
export type AnnouncementsQueryResult = Apollo.QueryResult<AnnouncementsQuery, AnnouncementsQueryVariables>;
export const NoteCountDocument = gql`
	query noteCount($filters: [NoteFilterInput!], $pagination: Pagination, $order: NoteOrder) {
		noteConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useNoteCountQuery__
 *
 * To run a query within a React component, call `useNoteCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoteCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoteCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useNoteCountQuery(baseOptions?: Apollo.QueryHookOptions<NoteCountQuery, NoteCountQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<NoteCountQuery, NoteCountQueryVariables>(NoteCountDocument, options);
}
export function useNoteCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<NoteCountQuery, NoteCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<NoteCountQuery, NoteCountQueryVariables>(NoteCountDocument, options);
}
export type NoteCountQueryHookResult = ReturnType<typeof useNoteCountQuery>;
export type NoteCountLazyQueryHookResult = ReturnType<typeof useNoteCountLazyQuery>;
export type NoteCountQueryResult = Apollo.QueryResult<NoteCountQuery, NoteCountQueryVariables>;
export const NoteCreateDocument = gql`
	mutation noteCreate($input: NoteCreateInput!) {
		createNote(input: $input) {
			...NoteDetail
		}
	}
	${NoteDetailFragmentDoc}
`;
export type NoteCreateMutationFn = Apollo.MutationFunction<NoteCreateMutation, NoteCreateMutationVariables>;

/**
 * __useNoteCreateMutation__
 *
 * To run a mutation, you first call `useNoteCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNoteCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [noteCreateMutation, { data, loading, error }] = useNoteCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNoteCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<NoteCreateMutation, NoteCreateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<NoteCreateMutation, NoteCreateMutationVariables>(NoteCreateDocument, options);
}
export type NoteCreateMutationHookResult = ReturnType<typeof useNoteCreateMutation>;
export type NoteCreateMutationResult = Apollo.MutationResult<NoteCreateMutation>;
export type NoteCreateMutationOptions = Apollo.BaseMutationOptions<NoteCreateMutation, NoteCreateMutationVariables>;
export const NoteDeleteDocument = gql`
	mutation noteDelete($id: ID!) {
		deleteNote(id: $id)
	}
`;
export type NoteDeleteMutationFn = Apollo.MutationFunction<NoteDeleteMutation, NoteDeleteMutationVariables>;

/**
 * __useNoteDeleteMutation__
 *
 * To run a mutation, you first call `useNoteDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNoteDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [noteDeleteMutation, { data, loading, error }] = useNoteDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNoteDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<NoteDeleteMutation, NoteDeleteMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<NoteDeleteMutation, NoteDeleteMutationVariables>(NoteDeleteDocument, options);
}
export type NoteDeleteMutationHookResult = ReturnType<typeof useNoteDeleteMutation>;
export type NoteDeleteMutationResult = Apollo.MutationResult<NoteDeleteMutation>;
export type NoteDeleteMutationOptions = Apollo.BaseMutationOptions<NoteDeleteMutation, NoteDeleteMutationVariables>;
export const NoteDetailDocument = gql`
	query noteDetail($id: ID!) {
		note(id: $id) {
			...NoteDetail
		}
	}
	${NoteDetailFragmentDoc}
`;

/**
 * __useNoteDetailQuery__
 *
 * To run a query within a React component, call `useNoteDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoteDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoteDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNoteDetailQuery(baseOptions: Apollo.QueryHookOptions<NoteDetailQuery, NoteDetailQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<NoteDetailQuery, NoteDetailQueryVariables>(NoteDetailDocument, options);
}
export function useNoteDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<NoteDetailQuery, NoteDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<NoteDetailQuery, NoteDetailQueryVariables>(NoteDetailDocument, options);
}
export type NoteDetailQueryHookResult = ReturnType<typeof useNoteDetailQuery>;
export type NoteDetailLazyQueryHookResult = ReturnType<typeof useNoteDetailLazyQuery>;
export type NoteDetailQueryResult = Apollo.QueryResult<NoteDetailQuery, NoteDetailQueryVariables>;
export const NoteIndexDocument = gql`
	query noteIndex($filters: [NoteFilterInput!], $pagination: Pagination, $order: NoteOrder) {
		noteConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					noteType
					title
					body
					details
					createdAt
					isTimeDependent
					startDate
					endDate
					isPublic
					author {
						id
						person {
							id
							name
							imageURL
						}
					}
					sharingUsers {
						id
						email
						person {
							id
							name
							imageURL
						}
					}
				}
			}
		}
	}
`;

/**
 * __useNoteIndexQuery__
 *
 * To run a query within a React component, call `useNoteIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoteIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoteIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useNoteIndexQuery(baseOptions?: Apollo.QueryHookOptions<NoteIndexQuery, NoteIndexQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<NoteIndexQuery, NoteIndexQueryVariables>(NoteIndexDocument, options);
}
export function useNoteIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<NoteIndexQuery, NoteIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<NoteIndexQuery, NoteIndexQueryVariables>(NoteIndexDocument, options);
}
export type NoteIndexQueryHookResult = ReturnType<typeof useNoteIndexQuery>;
export type NoteIndexLazyQueryHookResult = ReturnType<typeof useNoteIndexLazyQuery>;
export type NoteIndexQueryResult = Apollo.QueryResult<NoteIndexQuery, NoteIndexQueryVariables>;
export const NoteNameDocument = gql`
	query noteName($id: ID!) {
		note(id: $id) {
			id
			...NoteName
		}
	}
	${NoteNameFragmentDoc}
`;

/**
 * __useNoteNameQuery__
 *
 * To run a query within a React component, call `useNoteNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoteNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoteNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNoteNameQuery(baseOptions: Apollo.QueryHookOptions<NoteNameQuery, NoteNameQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<NoteNameQuery, NoteNameQueryVariables>(NoteNameDocument, options);
}
export function useNoteNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NoteNameQuery, NoteNameQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<NoteNameQuery, NoteNameQueryVariables>(NoteNameDocument, options);
}
export type NoteNameQueryHookResult = ReturnType<typeof useNoteNameQuery>;
export type NoteNameLazyQueryHookResult = ReturnType<typeof useNoteNameLazyQuery>;
export type NoteNameQueryResult = Apollo.QueryResult<NoteNameQuery, NoteNameQueryVariables>;
export const NoteNamesDocument = gql`
	query noteNames($filters: [NoteFilterInput!], $pagination: Pagination, $order: NoteOrder) {
		noteConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...NoteName
				}
			}
		}
	}
	${NoteNameFragmentDoc}
`;

/**
 * __useNoteNamesQuery__
 *
 * To run a query within a React component, call `useNoteNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoteNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoteNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useNoteNamesQuery(baseOptions?: Apollo.QueryHookOptions<NoteNamesQuery, NoteNamesQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<NoteNamesQuery, NoteNamesQueryVariables>(NoteNamesDocument, options);
}
export function useNoteNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<NoteNamesQuery, NoteNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<NoteNamesQuery, NoteNamesQueryVariables>(NoteNamesDocument, options);
}
export type NoteNamesQueryHookResult = ReturnType<typeof useNoteNamesQuery>;
export type NoteNamesLazyQueryHookResult = ReturnType<typeof useNoteNamesLazyQuery>;
export type NoteNamesQueryResult = Apollo.QueryResult<NoteNamesQuery, NoteNamesQueryVariables>;
export const NotePaginationDocument = gql`
	query notePagination($filters: [NoteFilterInput!], $order: NoteOrder) {
		noteConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useNotePaginationQuery__
 *
 * To run a query within a React component, call `useNotePaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotePaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotePaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useNotePaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<NotePaginationQuery, NotePaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<NotePaginationQuery, NotePaginationQueryVariables>(NotePaginationDocument, options);
}
export function useNotePaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<NotePaginationQuery, NotePaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<NotePaginationQuery, NotePaginationQueryVariables>(NotePaginationDocument, options);
}
export type NotePaginationQueryHookResult = ReturnType<typeof useNotePaginationQuery>;
export type NotePaginationLazyQueryHookResult = ReturnType<typeof useNotePaginationLazyQuery>;
export type NotePaginationQueryResult = Apollo.QueryResult<NotePaginationQuery, NotePaginationQueryVariables>;
export const NoteSelectDocument = gql`
	query noteSelect($filters: [NoteFilterInput!], $order: NoteOrder) {
		noteConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...NoteName
				}
			}
		}
	}
	${NoteNameFragmentDoc}
`;

/**
 * __useNoteSelectQuery__
 *
 * To run a query within a React component, call `useNoteSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoteSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoteSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useNoteSelectQuery(baseOptions?: Apollo.QueryHookOptions<NoteSelectQuery, NoteSelectQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<NoteSelectQuery, NoteSelectQueryVariables>(NoteSelectDocument, options);
}
export function useNoteSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<NoteSelectQuery, NoteSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<NoteSelectQuery, NoteSelectQueryVariables>(NoteSelectDocument, options);
}
export type NoteSelectQueryHookResult = ReturnType<typeof useNoteSelectQuery>;
export type NoteSelectLazyQueryHookResult = ReturnType<typeof useNoteSelectLazyQuery>;
export type NoteSelectQueryResult = Apollo.QueryResult<NoteSelectQuery, NoteSelectQueryVariables>;
export const StudentAttendanceNoteSlimListDocument = gql`
	query studentAttendanceNoteSlimList($filters: [NoteFilterInput!], $pagination: Pagination, $order: NoteOrder) {
		noteConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					noteType
					body
					startDate
					endDate
				}
			}
		}
	}
`;

/**
 * __useStudentAttendanceNoteSlimListQuery__
 *
 * To run a query within a React component, call `useStudentAttendanceNoteSlimListQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentAttendanceNoteSlimListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentAttendanceNoteSlimListQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useStudentAttendanceNoteSlimListQuery(
	baseOptions?: Apollo.QueryHookOptions<
		StudentAttendanceNoteSlimListQuery,
		StudentAttendanceNoteSlimListQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<StudentAttendanceNoteSlimListQuery, StudentAttendanceNoteSlimListQueryVariables>(
		StudentAttendanceNoteSlimListDocument,
		options,
	);
}
export function useStudentAttendanceNoteSlimListLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		StudentAttendanceNoteSlimListQuery,
		StudentAttendanceNoteSlimListQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<StudentAttendanceNoteSlimListQuery, StudentAttendanceNoteSlimListQueryVariables>(
		StudentAttendanceNoteSlimListDocument,
		options,
	);
}
export type StudentAttendanceNoteSlimListQueryHookResult = ReturnType<typeof useStudentAttendanceNoteSlimListQuery>;
export type StudentAttendanceNoteSlimListLazyQueryHookResult = ReturnType<
	typeof useStudentAttendanceNoteSlimListLazyQuery
>;
export type StudentAttendanceNoteSlimListQueryResult = Apollo.QueryResult<
	StudentAttendanceNoteSlimListQuery,
	StudentAttendanceNoteSlimListQueryVariables
>;
export const NoteStudentNotesSlimListDocument = gql`
	query noteStudentNotesSlimList($filters: [NoteFilterInput!], $pagination: Pagination, $order: NoteOrder) {
		noteConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					noteType
					title
					body
					createdAt
					isTimeDependent
					startDate
					endDate
					students {
						id
						person {
							id
							name
							imageURL
						}
					}
					author {
						id
						email
						person {
							id
							name
							imageURL
							employee {
								id
							}
						}
					}
				}
			}
		}
	}
`;

/**
 * __useNoteStudentNotesSlimListQuery__
 *
 * To run a query within a React component, call `useNoteStudentNotesSlimListQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoteStudentNotesSlimListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoteStudentNotesSlimListQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useNoteStudentNotesSlimListQuery(
	baseOptions?: Apollo.QueryHookOptions<NoteStudentNotesSlimListQuery, NoteStudentNotesSlimListQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<NoteStudentNotesSlimListQuery, NoteStudentNotesSlimListQueryVariables>(
		NoteStudentNotesSlimListDocument,
		options,
	);
}
export function useNoteStudentNotesSlimListLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<NoteStudentNotesSlimListQuery, NoteStudentNotesSlimListQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<NoteStudentNotesSlimListQuery, NoteStudentNotesSlimListQueryVariables>(
		NoteStudentNotesSlimListDocument,
		options,
	);
}
export type NoteStudentNotesSlimListQueryHookResult = ReturnType<typeof useNoteStudentNotesSlimListQuery>;
export type NoteStudentNotesSlimListLazyQueryHookResult = ReturnType<typeof useNoteStudentNotesSlimListLazyQuery>;
export type NoteStudentNotesSlimListQueryResult = Apollo.QueryResult<
	NoteStudentNotesSlimListQuery,
	NoteStudentNotesSlimListQueryVariables
>;
export const NoteStudentNotesListDocument = gql`
	query noteStudentNotesList($filters: [NoteFilterInput!], $pagination: Pagination, $order: NoteOrder) {
		noteConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					noteType
					title
					body
					details
					createdAt
					isTimeDependent
					startDate
					endDate
					isPublic
					students {
						id
						person {
							id
							name
							imageURL
						}
					}
					author {
						id
						email
						person {
							id
							name
							imageURL
							employee {
								id
							}
						}
					}
					sharingUsers {
						id
						email
						person {
							id
							name
							imageURL
						}
					}
				}
			}
		}
	}
`;

/**
 * __useNoteStudentNotesListQuery__
 *
 * To run a query within a React component, call `useNoteStudentNotesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoteStudentNotesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoteStudentNotesListQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useNoteStudentNotesListQuery(
	baseOptions?: Apollo.QueryHookOptions<NoteStudentNotesListQuery, NoteStudentNotesListQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<NoteStudentNotesListQuery, NoteStudentNotesListQueryVariables>(
		NoteStudentNotesListDocument,
		options,
	);
}
export function useNoteStudentNotesListLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<NoteStudentNotesListQuery, NoteStudentNotesListQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<NoteStudentNotesListQuery, NoteStudentNotesListQueryVariables>(
		NoteStudentNotesListDocument,
		options,
	);
}
export type NoteStudentNotesListQueryHookResult = ReturnType<typeof useNoteStudentNotesListQuery>;
export type NoteStudentNotesListLazyQueryHookResult = ReturnType<typeof useNoteStudentNotesListLazyQuery>;
export type NoteStudentNotesListQueryResult = Apollo.QueryResult<
	NoteStudentNotesListQuery,
	NoteStudentNotesListQueryVariables
>;
export const StudentNotesListDocument = gql`
	query studentNotesList($filters: [NoteFilterInput!], $pagination: Pagination, $order: NoteOrder) {
		noteConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					noteType
					title
					body
					details
					createdAt
					isTimeDependent
					startDate
					endDate
					isPublic
					students {
						id
						person {
							id
							name
						}
					}
					author {
						id
						email
						person {
							id
							name
							imageURL
						}
					}
					sharingUsers {
						id
						email
						person {
							id
							name
							imageURL
						}
					}
				}
			}
		}
	}
`;

/**
 * __useStudentNotesListQuery__
 *
 * To run a query within a React component, call `useStudentNotesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentNotesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentNotesListQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useStudentNotesListQuery(
	baseOptions?: Apollo.QueryHookOptions<StudentNotesListQuery, StudentNotesListQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<StudentNotesListQuery, StudentNotesListQueryVariables>(StudentNotesListDocument, options);
}
export function useStudentNotesListLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<StudentNotesListQuery, StudentNotesListQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<StudentNotesListQuery, StudentNotesListQueryVariables>(StudentNotesListDocument, options);
}
export type StudentNotesListQueryHookResult = ReturnType<typeof useStudentNotesListQuery>;
export type StudentNotesListLazyQueryHookResult = ReturnType<typeof useStudentNotesListLazyQuery>;
export type StudentNotesListQueryResult = Apollo.QueryResult<StudentNotesListQuery, StudentNotesListQueryVariables>;
export const NoteUpdateDocument = gql`
	mutation noteUpdate($id: ID!, $input: NoteUpdateInput!) {
		updateNote(id: $id, input: $input) {
			...NoteDetail
		}
	}
	${NoteDetailFragmentDoc}
`;
export type NoteUpdateMutationFn = Apollo.MutationFunction<NoteUpdateMutation, NoteUpdateMutationVariables>;

/**
 * __useNoteUpdateMutation__
 *
 * To run a mutation, you first call `useNoteUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNoteUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [noteUpdateMutation, { data, loading, error }] = useNoteUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNoteUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<NoteUpdateMutation, NoteUpdateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<NoteUpdateMutation, NoteUpdateMutationVariables>(NoteUpdateDocument, options);
}
export type NoteUpdateMutationHookResult = ReturnType<typeof useNoteUpdateMutation>;
export type NoteUpdateMutationResult = Apollo.MutationResult<NoteUpdateMutation>;
export type NoteUpdateMutationOptions = Apollo.BaseMutationOptions<NoteUpdateMutation, NoteUpdateMutationVariables>;
export const NoteAttachmentCountDocument = gql`
	query noteAttachmentCount(
		$filters: [NoteAttachmentFilterInput!]
		$pagination: Pagination
		$order: NoteAttachmentOrder
	) {
		noteAttachmentConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useNoteAttachmentCountQuery__
 *
 * To run a query within a React component, call `useNoteAttachmentCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoteAttachmentCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoteAttachmentCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useNoteAttachmentCountQuery(
	baseOptions?: Apollo.QueryHookOptions<NoteAttachmentCountQuery, NoteAttachmentCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<NoteAttachmentCountQuery, NoteAttachmentCountQueryVariables>(
		NoteAttachmentCountDocument,
		options,
	);
}
export function useNoteAttachmentCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<NoteAttachmentCountQuery, NoteAttachmentCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<NoteAttachmentCountQuery, NoteAttachmentCountQueryVariables>(
		NoteAttachmentCountDocument,
		options,
	);
}
export type NoteAttachmentCountQueryHookResult = ReturnType<typeof useNoteAttachmentCountQuery>;
export type NoteAttachmentCountLazyQueryHookResult = ReturnType<typeof useNoteAttachmentCountLazyQuery>;
export type NoteAttachmentCountQueryResult = Apollo.QueryResult<
	NoteAttachmentCountQuery,
	NoteAttachmentCountQueryVariables
>;
export const NoteAttachmentCreateDocument = gql`
	mutation noteAttachmentCreate($input: NoteAttachmentCreateInput!) {
		createNoteAttachment(input: $input) {
			...NoteAttachmentDetail
		}
	}
	${NoteAttachmentDetailFragmentDoc}
`;
export type NoteAttachmentCreateMutationFn = Apollo.MutationFunction<
	NoteAttachmentCreateMutation,
	NoteAttachmentCreateMutationVariables
>;

/**
 * __useNoteAttachmentCreateMutation__
 *
 * To run a mutation, you first call `useNoteAttachmentCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNoteAttachmentCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [noteAttachmentCreateMutation, { data, loading, error }] = useNoteAttachmentCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNoteAttachmentCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<NoteAttachmentCreateMutation, NoteAttachmentCreateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<NoteAttachmentCreateMutation, NoteAttachmentCreateMutationVariables>(
		NoteAttachmentCreateDocument,
		options,
	);
}
export type NoteAttachmentCreateMutationHookResult = ReturnType<typeof useNoteAttachmentCreateMutation>;
export type NoteAttachmentCreateMutationResult = Apollo.MutationResult<NoteAttachmentCreateMutation>;
export type NoteAttachmentCreateMutationOptions = Apollo.BaseMutationOptions<
	NoteAttachmentCreateMutation,
	NoteAttachmentCreateMutationVariables
>;
export const NoteAttachmentDeleteDocument = gql`
	mutation noteAttachmentDelete($id: ID!) {
		deleteNoteAttachment(id: $id)
	}
`;
export type NoteAttachmentDeleteMutationFn = Apollo.MutationFunction<
	NoteAttachmentDeleteMutation,
	NoteAttachmentDeleteMutationVariables
>;

/**
 * __useNoteAttachmentDeleteMutation__
 *
 * To run a mutation, you first call `useNoteAttachmentDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNoteAttachmentDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [noteAttachmentDeleteMutation, { data, loading, error }] = useNoteAttachmentDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNoteAttachmentDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<NoteAttachmentDeleteMutation, NoteAttachmentDeleteMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<NoteAttachmentDeleteMutation, NoteAttachmentDeleteMutationVariables>(
		NoteAttachmentDeleteDocument,
		options,
	);
}
export type NoteAttachmentDeleteMutationHookResult = ReturnType<typeof useNoteAttachmentDeleteMutation>;
export type NoteAttachmentDeleteMutationResult = Apollo.MutationResult<NoteAttachmentDeleteMutation>;
export type NoteAttachmentDeleteMutationOptions = Apollo.BaseMutationOptions<
	NoteAttachmentDeleteMutation,
	NoteAttachmentDeleteMutationVariables
>;
export const NoteAttachmentDetailDocument = gql`
	query noteAttachmentDetail($id: ID!) {
		noteAttachment(id: $id) {
			...NoteAttachmentDetail
		}
	}
	${NoteAttachmentDetailFragmentDoc}
`;

/**
 * __useNoteAttachmentDetailQuery__
 *
 * To run a query within a React component, call `useNoteAttachmentDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoteAttachmentDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoteAttachmentDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNoteAttachmentDetailQuery(
	baseOptions: Apollo.QueryHookOptions<NoteAttachmentDetailQuery, NoteAttachmentDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<NoteAttachmentDetailQuery, NoteAttachmentDetailQueryVariables>(
		NoteAttachmentDetailDocument,
		options,
	);
}
export function useNoteAttachmentDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<NoteAttachmentDetailQuery, NoteAttachmentDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<NoteAttachmentDetailQuery, NoteAttachmentDetailQueryVariables>(
		NoteAttachmentDetailDocument,
		options,
	);
}
export type NoteAttachmentDetailQueryHookResult = ReturnType<typeof useNoteAttachmentDetailQuery>;
export type NoteAttachmentDetailLazyQueryHookResult = ReturnType<typeof useNoteAttachmentDetailLazyQuery>;
export type NoteAttachmentDetailQueryResult = Apollo.QueryResult<
	NoteAttachmentDetailQuery,
	NoteAttachmentDetailQueryVariables
>;
export const NoteAttachmentIndexDocument = gql`
	query noteAttachmentIndex(
		$filters: [NoteAttachmentFilterInput!]
		$pagination: Pagination
		$order: NoteAttachmentOrder
	) {
		noteAttachmentConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					order
					isFile
					fileURL
					linkURL
					url
				}
			}
		}
	}
`;

/**
 * __useNoteAttachmentIndexQuery__
 *
 * To run a query within a React component, call `useNoteAttachmentIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoteAttachmentIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoteAttachmentIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useNoteAttachmentIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<NoteAttachmentIndexQuery, NoteAttachmentIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<NoteAttachmentIndexQuery, NoteAttachmentIndexQueryVariables>(
		NoteAttachmentIndexDocument,
		options,
	);
}
export function useNoteAttachmentIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<NoteAttachmentIndexQuery, NoteAttachmentIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<NoteAttachmentIndexQuery, NoteAttachmentIndexQueryVariables>(
		NoteAttachmentIndexDocument,
		options,
	);
}
export type NoteAttachmentIndexQueryHookResult = ReturnType<typeof useNoteAttachmentIndexQuery>;
export type NoteAttachmentIndexLazyQueryHookResult = ReturnType<typeof useNoteAttachmentIndexLazyQuery>;
export type NoteAttachmentIndexQueryResult = Apollo.QueryResult<
	NoteAttachmentIndexQuery,
	NoteAttachmentIndexQueryVariables
>;
export const NoteAttachmentNameDocument = gql`
	query noteAttachmentName($id: ID!) {
		noteAttachment(id: $id) {
			id
			...NoteAttachmentName
		}
	}
	${NoteAttachmentNameFragmentDoc}
`;

/**
 * __useNoteAttachmentNameQuery__
 *
 * To run a query within a React component, call `useNoteAttachmentNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoteAttachmentNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoteAttachmentNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNoteAttachmentNameQuery(
	baseOptions: Apollo.QueryHookOptions<NoteAttachmentNameQuery, NoteAttachmentNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<NoteAttachmentNameQuery, NoteAttachmentNameQueryVariables>(
		NoteAttachmentNameDocument,
		options,
	);
}
export function useNoteAttachmentNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<NoteAttachmentNameQuery, NoteAttachmentNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<NoteAttachmentNameQuery, NoteAttachmentNameQueryVariables>(
		NoteAttachmentNameDocument,
		options,
	);
}
export type NoteAttachmentNameQueryHookResult = ReturnType<typeof useNoteAttachmentNameQuery>;
export type NoteAttachmentNameLazyQueryHookResult = ReturnType<typeof useNoteAttachmentNameLazyQuery>;
export type NoteAttachmentNameQueryResult = Apollo.QueryResult<
	NoteAttachmentNameQuery,
	NoteAttachmentNameQueryVariables
>;
export const NoteAttachmentNamesDocument = gql`
	query noteAttachmentNames(
		$filters: [NoteAttachmentFilterInput!]
		$pagination: Pagination
		$order: NoteAttachmentOrder
	) {
		noteAttachmentConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...NoteAttachmentName
				}
			}
		}
	}
	${NoteAttachmentNameFragmentDoc}
`;

/**
 * __useNoteAttachmentNamesQuery__
 *
 * To run a query within a React component, call `useNoteAttachmentNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoteAttachmentNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoteAttachmentNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useNoteAttachmentNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<NoteAttachmentNamesQuery, NoteAttachmentNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<NoteAttachmentNamesQuery, NoteAttachmentNamesQueryVariables>(
		NoteAttachmentNamesDocument,
		options,
	);
}
export function useNoteAttachmentNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<NoteAttachmentNamesQuery, NoteAttachmentNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<NoteAttachmentNamesQuery, NoteAttachmentNamesQueryVariables>(
		NoteAttachmentNamesDocument,
		options,
	);
}
export type NoteAttachmentNamesQueryHookResult = ReturnType<typeof useNoteAttachmentNamesQuery>;
export type NoteAttachmentNamesLazyQueryHookResult = ReturnType<typeof useNoteAttachmentNamesLazyQuery>;
export type NoteAttachmentNamesQueryResult = Apollo.QueryResult<
	NoteAttachmentNamesQuery,
	NoteAttachmentNamesQueryVariables
>;
export const NoteAttachmentPaginationDocument = gql`
	query noteAttachmentPagination($filters: [NoteAttachmentFilterInput!], $order: NoteAttachmentOrder) {
		noteAttachmentConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useNoteAttachmentPaginationQuery__
 *
 * To run a query within a React component, call `useNoteAttachmentPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoteAttachmentPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoteAttachmentPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useNoteAttachmentPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<NoteAttachmentPaginationQuery, NoteAttachmentPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<NoteAttachmentPaginationQuery, NoteAttachmentPaginationQueryVariables>(
		NoteAttachmentPaginationDocument,
		options,
	);
}
export function useNoteAttachmentPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<NoteAttachmentPaginationQuery, NoteAttachmentPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<NoteAttachmentPaginationQuery, NoteAttachmentPaginationQueryVariables>(
		NoteAttachmentPaginationDocument,
		options,
	);
}
export type NoteAttachmentPaginationQueryHookResult = ReturnType<typeof useNoteAttachmentPaginationQuery>;
export type NoteAttachmentPaginationLazyQueryHookResult = ReturnType<typeof useNoteAttachmentPaginationLazyQuery>;
export type NoteAttachmentPaginationQueryResult = Apollo.QueryResult<
	NoteAttachmentPaginationQuery,
	NoteAttachmentPaginationQueryVariables
>;
export const NoteAttachmentSelectDocument = gql`
	query noteAttachmentSelect($filters: [NoteAttachmentFilterInput!], $order: NoteAttachmentOrder) {
		noteAttachmentConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...NoteAttachmentName
				}
			}
		}
	}
	${NoteAttachmentNameFragmentDoc}
`;

/**
 * __useNoteAttachmentSelectQuery__
 *
 * To run a query within a React component, call `useNoteAttachmentSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoteAttachmentSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoteAttachmentSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useNoteAttachmentSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<NoteAttachmentSelectQuery, NoteAttachmentSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<NoteAttachmentSelectQuery, NoteAttachmentSelectQueryVariables>(
		NoteAttachmentSelectDocument,
		options,
	);
}
export function useNoteAttachmentSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<NoteAttachmentSelectQuery, NoteAttachmentSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<NoteAttachmentSelectQuery, NoteAttachmentSelectQueryVariables>(
		NoteAttachmentSelectDocument,
		options,
	);
}
export type NoteAttachmentSelectQueryHookResult = ReturnType<typeof useNoteAttachmentSelectQuery>;
export type NoteAttachmentSelectLazyQueryHookResult = ReturnType<typeof useNoteAttachmentSelectLazyQuery>;
export type NoteAttachmentSelectQueryResult = Apollo.QueryResult<
	NoteAttachmentSelectQuery,
	NoteAttachmentSelectQueryVariables
>;
export const NoteAttachmentUpdateDocument = gql`
	mutation noteAttachmentUpdate($id: ID!, $input: NoteAttachmentUpdateInput!) {
		updateNoteAttachment(id: $id, input: $input) {
			...NoteAttachmentDetail
		}
	}
	${NoteAttachmentDetailFragmentDoc}
`;
export type NoteAttachmentUpdateMutationFn = Apollo.MutationFunction<
	NoteAttachmentUpdateMutation,
	NoteAttachmentUpdateMutationVariables
>;

/**
 * __useNoteAttachmentUpdateMutation__
 *
 * To run a mutation, you first call `useNoteAttachmentUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNoteAttachmentUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [noteAttachmentUpdateMutation, { data, loading, error }] = useNoteAttachmentUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNoteAttachmentUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<NoteAttachmentUpdateMutation, NoteAttachmentUpdateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<NoteAttachmentUpdateMutation, NoteAttachmentUpdateMutationVariables>(
		NoteAttachmentUpdateDocument,
		options,
	);
}
export type NoteAttachmentUpdateMutationHookResult = ReturnType<typeof useNoteAttachmentUpdateMutation>;
export type NoteAttachmentUpdateMutationResult = Apollo.MutationResult<NoteAttachmentUpdateMutation>;
export type NoteAttachmentUpdateMutationOptions = Apollo.BaseMutationOptions<
	NoteAttachmentUpdateMutation,
	NoteAttachmentUpdateMutationVariables
>;
export const NoteCommentCountDocument = gql`
	query noteCommentCount($filters: [NoteCommentFilterInput!], $pagination: Pagination, $order: NoteCommentOrder) {
		noteCommentConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useNoteCommentCountQuery__
 *
 * To run a query within a React component, call `useNoteCommentCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoteCommentCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoteCommentCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useNoteCommentCountQuery(
	baseOptions?: Apollo.QueryHookOptions<NoteCommentCountQuery, NoteCommentCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<NoteCommentCountQuery, NoteCommentCountQueryVariables>(NoteCommentCountDocument, options);
}
export function useNoteCommentCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<NoteCommentCountQuery, NoteCommentCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<NoteCommentCountQuery, NoteCommentCountQueryVariables>(NoteCommentCountDocument, options);
}
export type NoteCommentCountQueryHookResult = ReturnType<typeof useNoteCommentCountQuery>;
export type NoteCommentCountLazyQueryHookResult = ReturnType<typeof useNoteCommentCountLazyQuery>;
export type NoteCommentCountQueryResult = Apollo.QueryResult<NoteCommentCountQuery, NoteCommentCountQueryVariables>;
export const NoteCommentCreateDocument = gql`
	mutation noteCommentCreate($input: NoteCommentCreateInput!) {
		createNoteComment(input: $input) {
			...NoteCommentDetail
		}
	}
	${NoteCommentDetailFragmentDoc}
`;
export type NoteCommentCreateMutationFn = Apollo.MutationFunction<
	NoteCommentCreateMutation,
	NoteCommentCreateMutationVariables
>;

/**
 * __useNoteCommentCreateMutation__
 *
 * To run a mutation, you first call `useNoteCommentCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNoteCommentCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [noteCommentCreateMutation, { data, loading, error }] = useNoteCommentCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNoteCommentCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<NoteCommentCreateMutation, NoteCommentCreateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<NoteCommentCreateMutation, NoteCommentCreateMutationVariables>(
		NoteCommentCreateDocument,
		options,
	);
}
export type NoteCommentCreateMutationHookResult = ReturnType<typeof useNoteCommentCreateMutation>;
export type NoteCommentCreateMutationResult = Apollo.MutationResult<NoteCommentCreateMutation>;
export type NoteCommentCreateMutationOptions = Apollo.BaseMutationOptions<
	NoteCommentCreateMutation,
	NoteCommentCreateMutationVariables
>;
export const NoteCommentDeleteDocument = gql`
	mutation noteCommentDelete($id: ID!) {
		deleteNoteComment(id: $id)
	}
`;
export type NoteCommentDeleteMutationFn = Apollo.MutationFunction<
	NoteCommentDeleteMutation,
	NoteCommentDeleteMutationVariables
>;

/**
 * __useNoteCommentDeleteMutation__
 *
 * To run a mutation, you first call `useNoteCommentDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNoteCommentDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [noteCommentDeleteMutation, { data, loading, error }] = useNoteCommentDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNoteCommentDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<NoteCommentDeleteMutation, NoteCommentDeleteMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<NoteCommentDeleteMutation, NoteCommentDeleteMutationVariables>(
		NoteCommentDeleteDocument,
		options,
	);
}
export type NoteCommentDeleteMutationHookResult = ReturnType<typeof useNoteCommentDeleteMutation>;
export type NoteCommentDeleteMutationResult = Apollo.MutationResult<NoteCommentDeleteMutation>;
export type NoteCommentDeleteMutationOptions = Apollo.BaseMutationOptions<
	NoteCommentDeleteMutation,
	NoteCommentDeleteMutationVariables
>;
export const NoteCommentDetailDocument = gql`
	query noteCommentDetail($id: ID!) {
		noteComment(id: $id) {
			...NoteCommentDetail
		}
	}
	${NoteCommentDetailFragmentDoc}
`;

/**
 * __useNoteCommentDetailQuery__
 *
 * To run a query within a React component, call `useNoteCommentDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoteCommentDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoteCommentDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNoteCommentDetailQuery(
	baseOptions: Apollo.QueryHookOptions<NoteCommentDetailQuery, NoteCommentDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<NoteCommentDetailQuery, NoteCommentDetailQueryVariables>(NoteCommentDetailDocument, options);
}
export function useNoteCommentDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<NoteCommentDetailQuery, NoteCommentDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<NoteCommentDetailQuery, NoteCommentDetailQueryVariables>(
		NoteCommentDetailDocument,
		options,
	);
}
export type NoteCommentDetailQueryHookResult = ReturnType<typeof useNoteCommentDetailQuery>;
export type NoteCommentDetailLazyQueryHookResult = ReturnType<typeof useNoteCommentDetailLazyQuery>;
export type NoteCommentDetailQueryResult = Apollo.QueryResult<NoteCommentDetailQuery, NoteCommentDetailQueryVariables>;
export const NoteCommentIndexDocument = gql`
	query noteCommentIndex($filters: [NoteCommentFilterInput!], $pagination: Pagination, $order: NoteCommentOrder) {
		noteCommentConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					body
					createdAt
				}
			}
		}
	}
`;

/**
 * __useNoteCommentIndexQuery__
 *
 * To run a query within a React component, call `useNoteCommentIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoteCommentIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoteCommentIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useNoteCommentIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<NoteCommentIndexQuery, NoteCommentIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<NoteCommentIndexQuery, NoteCommentIndexQueryVariables>(NoteCommentIndexDocument, options);
}
export function useNoteCommentIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<NoteCommentIndexQuery, NoteCommentIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<NoteCommentIndexQuery, NoteCommentIndexQueryVariables>(NoteCommentIndexDocument, options);
}
export type NoteCommentIndexQueryHookResult = ReturnType<typeof useNoteCommentIndexQuery>;
export type NoteCommentIndexLazyQueryHookResult = ReturnType<typeof useNoteCommentIndexLazyQuery>;
export type NoteCommentIndexQueryResult = Apollo.QueryResult<NoteCommentIndexQuery, NoteCommentIndexQueryVariables>;
export const NoteCommentNameDocument = gql`
	query noteCommentName($id: ID!) {
		noteComment(id: $id) {
			id
			...NoteCommentName
		}
	}
	${NoteCommentNameFragmentDoc}
`;

/**
 * __useNoteCommentNameQuery__
 *
 * To run a query within a React component, call `useNoteCommentNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoteCommentNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoteCommentNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNoteCommentNameQuery(
	baseOptions: Apollo.QueryHookOptions<NoteCommentNameQuery, NoteCommentNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<NoteCommentNameQuery, NoteCommentNameQueryVariables>(NoteCommentNameDocument, options);
}
export function useNoteCommentNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<NoteCommentNameQuery, NoteCommentNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<NoteCommentNameQuery, NoteCommentNameQueryVariables>(NoteCommentNameDocument, options);
}
export type NoteCommentNameQueryHookResult = ReturnType<typeof useNoteCommentNameQuery>;
export type NoteCommentNameLazyQueryHookResult = ReturnType<typeof useNoteCommentNameLazyQuery>;
export type NoteCommentNameQueryResult = Apollo.QueryResult<NoteCommentNameQuery, NoteCommentNameQueryVariables>;
export const NoteCommentNamesDocument = gql`
	query noteCommentNames($filters: [NoteCommentFilterInput!], $pagination: Pagination, $order: NoteCommentOrder) {
		noteCommentConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...NoteCommentName
				}
			}
		}
	}
	${NoteCommentNameFragmentDoc}
`;

/**
 * __useNoteCommentNamesQuery__
 *
 * To run a query within a React component, call `useNoteCommentNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoteCommentNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoteCommentNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useNoteCommentNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<NoteCommentNamesQuery, NoteCommentNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<NoteCommentNamesQuery, NoteCommentNamesQueryVariables>(NoteCommentNamesDocument, options);
}
export function useNoteCommentNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<NoteCommentNamesQuery, NoteCommentNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<NoteCommentNamesQuery, NoteCommentNamesQueryVariables>(NoteCommentNamesDocument, options);
}
export type NoteCommentNamesQueryHookResult = ReturnType<typeof useNoteCommentNamesQuery>;
export type NoteCommentNamesLazyQueryHookResult = ReturnType<typeof useNoteCommentNamesLazyQuery>;
export type NoteCommentNamesQueryResult = Apollo.QueryResult<NoteCommentNamesQuery, NoteCommentNamesQueryVariables>;
export const NoteCommentPaginationDocument = gql`
	query noteCommentPagination($filters: [NoteCommentFilterInput!], $order: NoteCommentOrder) {
		noteCommentConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useNoteCommentPaginationQuery__
 *
 * To run a query within a React component, call `useNoteCommentPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoteCommentPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoteCommentPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useNoteCommentPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<NoteCommentPaginationQuery, NoteCommentPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<NoteCommentPaginationQuery, NoteCommentPaginationQueryVariables>(
		NoteCommentPaginationDocument,
		options,
	);
}
export function useNoteCommentPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<NoteCommentPaginationQuery, NoteCommentPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<NoteCommentPaginationQuery, NoteCommentPaginationQueryVariables>(
		NoteCommentPaginationDocument,
		options,
	);
}
export type NoteCommentPaginationQueryHookResult = ReturnType<typeof useNoteCommentPaginationQuery>;
export type NoteCommentPaginationLazyQueryHookResult = ReturnType<typeof useNoteCommentPaginationLazyQuery>;
export type NoteCommentPaginationQueryResult = Apollo.QueryResult<
	NoteCommentPaginationQuery,
	NoteCommentPaginationQueryVariables
>;
export const NoteCommentSelectDocument = gql`
	query noteCommentSelect($filters: [NoteCommentFilterInput!], $order: NoteCommentOrder) {
		noteCommentConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...NoteCommentName
				}
			}
		}
	}
	${NoteCommentNameFragmentDoc}
`;

/**
 * __useNoteCommentSelectQuery__
 *
 * To run a query within a React component, call `useNoteCommentSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoteCommentSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoteCommentSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useNoteCommentSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<NoteCommentSelectQuery, NoteCommentSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<NoteCommentSelectQuery, NoteCommentSelectQueryVariables>(NoteCommentSelectDocument, options);
}
export function useNoteCommentSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<NoteCommentSelectQuery, NoteCommentSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<NoteCommentSelectQuery, NoteCommentSelectQueryVariables>(
		NoteCommentSelectDocument,
		options,
	);
}
export type NoteCommentSelectQueryHookResult = ReturnType<typeof useNoteCommentSelectQuery>;
export type NoteCommentSelectLazyQueryHookResult = ReturnType<typeof useNoteCommentSelectLazyQuery>;
export type NoteCommentSelectQueryResult = Apollo.QueryResult<NoteCommentSelectQuery, NoteCommentSelectQueryVariables>;
export const NoteCommentUpdateDocument = gql`
	mutation noteCommentUpdate($id: ID!, $input: NoteCommentUpdateInput!) {
		updateNoteComment(id: $id, input: $input) {
			...NoteCommentDetail
		}
	}
	${NoteCommentDetailFragmentDoc}
`;
export type NoteCommentUpdateMutationFn = Apollo.MutationFunction<
	NoteCommentUpdateMutation,
	NoteCommentUpdateMutationVariables
>;

/**
 * __useNoteCommentUpdateMutation__
 *
 * To run a mutation, you first call `useNoteCommentUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNoteCommentUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [noteCommentUpdateMutation, { data, loading, error }] = useNoteCommentUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNoteCommentUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<NoteCommentUpdateMutation, NoteCommentUpdateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<NoteCommentUpdateMutation, NoteCommentUpdateMutationVariables>(
		NoteCommentUpdateDocument,
		options,
	);
}
export type NoteCommentUpdateMutationHookResult = ReturnType<typeof useNoteCommentUpdateMutation>;
export type NoteCommentUpdateMutationResult = Apollo.MutationResult<NoteCommentUpdateMutation>;
export type NoteCommentUpdateMutationOptions = Apollo.BaseMutationOptions<
	NoteCommentUpdateMutation,
	NoteCommentUpdateMutationVariables
>;
export const NoteReactionCountDocument = gql`
	query noteReactionCount($filters: [NoteReactionFilterInput!], $pagination: Pagination, $order: NoteReactionOrder) {
		noteReactionConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useNoteReactionCountQuery__
 *
 * To run a query within a React component, call `useNoteReactionCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoteReactionCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoteReactionCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useNoteReactionCountQuery(
	baseOptions?: Apollo.QueryHookOptions<NoteReactionCountQuery, NoteReactionCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<NoteReactionCountQuery, NoteReactionCountQueryVariables>(NoteReactionCountDocument, options);
}
export function useNoteReactionCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<NoteReactionCountQuery, NoteReactionCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<NoteReactionCountQuery, NoteReactionCountQueryVariables>(
		NoteReactionCountDocument,
		options,
	);
}
export type NoteReactionCountQueryHookResult = ReturnType<typeof useNoteReactionCountQuery>;
export type NoteReactionCountLazyQueryHookResult = ReturnType<typeof useNoteReactionCountLazyQuery>;
export type NoteReactionCountQueryResult = Apollo.QueryResult<NoteReactionCountQuery, NoteReactionCountQueryVariables>;
export const NoteReactionCreateDocument = gql`
	mutation noteReactionCreate($input: NoteReactionCreateInput!) {
		createNoteReaction(input: $input) {
			...NoteReactionDetail
		}
	}
	${NoteReactionDetailFragmentDoc}
`;
export type NoteReactionCreateMutationFn = Apollo.MutationFunction<
	NoteReactionCreateMutation,
	NoteReactionCreateMutationVariables
>;

/**
 * __useNoteReactionCreateMutation__
 *
 * To run a mutation, you first call `useNoteReactionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNoteReactionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [noteReactionCreateMutation, { data, loading, error }] = useNoteReactionCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNoteReactionCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<NoteReactionCreateMutation, NoteReactionCreateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<NoteReactionCreateMutation, NoteReactionCreateMutationVariables>(
		NoteReactionCreateDocument,
		options,
	);
}
export type NoteReactionCreateMutationHookResult = ReturnType<typeof useNoteReactionCreateMutation>;
export type NoteReactionCreateMutationResult = Apollo.MutationResult<NoteReactionCreateMutation>;
export type NoteReactionCreateMutationOptions = Apollo.BaseMutationOptions<
	NoteReactionCreateMutation,
	NoteReactionCreateMutationVariables
>;
export const NoteReactionDeleteDocument = gql`
	mutation noteReactionDelete($id: ID!) {
		deleteNoteReaction(id: $id)
	}
`;
export type NoteReactionDeleteMutationFn = Apollo.MutationFunction<
	NoteReactionDeleteMutation,
	NoteReactionDeleteMutationVariables
>;

/**
 * __useNoteReactionDeleteMutation__
 *
 * To run a mutation, you first call `useNoteReactionDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNoteReactionDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [noteReactionDeleteMutation, { data, loading, error }] = useNoteReactionDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNoteReactionDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<NoteReactionDeleteMutation, NoteReactionDeleteMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<NoteReactionDeleteMutation, NoteReactionDeleteMutationVariables>(
		NoteReactionDeleteDocument,
		options,
	);
}
export type NoteReactionDeleteMutationHookResult = ReturnType<typeof useNoteReactionDeleteMutation>;
export type NoteReactionDeleteMutationResult = Apollo.MutationResult<NoteReactionDeleteMutation>;
export type NoteReactionDeleteMutationOptions = Apollo.BaseMutationOptions<
	NoteReactionDeleteMutation,
	NoteReactionDeleteMutationVariables
>;
export const NoteReactionDetailDocument = gql`
	query noteReactionDetail($id: ID!) {
		noteReaction(id: $id) {
			...NoteReactionDetail
		}
	}
	${NoteReactionDetailFragmentDoc}
`;

/**
 * __useNoteReactionDetailQuery__
 *
 * To run a query within a React component, call `useNoteReactionDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoteReactionDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoteReactionDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNoteReactionDetailQuery(
	baseOptions: Apollo.QueryHookOptions<NoteReactionDetailQuery, NoteReactionDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<NoteReactionDetailQuery, NoteReactionDetailQueryVariables>(
		NoteReactionDetailDocument,
		options,
	);
}
export function useNoteReactionDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<NoteReactionDetailQuery, NoteReactionDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<NoteReactionDetailQuery, NoteReactionDetailQueryVariables>(
		NoteReactionDetailDocument,
		options,
	);
}
export type NoteReactionDetailQueryHookResult = ReturnType<typeof useNoteReactionDetailQuery>;
export type NoteReactionDetailLazyQueryHookResult = ReturnType<typeof useNoteReactionDetailLazyQuery>;
export type NoteReactionDetailQueryResult = Apollo.QueryResult<
	NoteReactionDetailQuery,
	NoteReactionDetailQueryVariables
>;
export const NoteReactionIndexDocument = gql`
	query noteReactionIndex($filters: [NoteReactionFilterInput!], $pagination: Pagination, $order: NoteReactionOrder) {
		noteReactionConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					reactionType
					createdAt
				}
			}
		}
	}
`;

/**
 * __useNoteReactionIndexQuery__
 *
 * To run a query within a React component, call `useNoteReactionIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoteReactionIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoteReactionIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useNoteReactionIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<NoteReactionIndexQuery, NoteReactionIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<NoteReactionIndexQuery, NoteReactionIndexQueryVariables>(NoteReactionIndexDocument, options);
}
export function useNoteReactionIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<NoteReactionIndexQuery, NoteReactionIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<NoteReactionIndexQuery, NoteReactionIndexQueryVariables>(
		NoteReactionIndexDocument,
		options,
	);
}
export type NoteReactionIndexQueryHookResult = ReturnType<typeof useNoteReactionIndexQuery>;
export type NoteReactionIndexLazyQueryHookResult = ReturnType<typeof useNoteReactionIndexLazyQuery>;
export type NoteReactionIndexQueryResult = Apollo.QueryResult<NoteReactionIndexQuery, NoteReactionIndexQueryVariables>;
export const NoteReactionNameDocument = gql`
	query noteReactionName($id: ID!) {
		noteReaction(id: $id) {
			id
			...NoteReactionName
		}
	}
	${NoteReactionNameFragmentDoc}
`;

/**
 * __useNoteReactionNameQuery__
 *
 * To run a query within a React component, call `useNoteReactionNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoteReactionNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoteReactionNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNoteReactionNameQuery(
	baseOptions: Apollo.QueryHookOptions<NoteReactionNameQuery, NoteReactionNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<NoteReactionNameQuery, NoteReactionNameQueryVariables>(NoteReactionNameDocument, options);
}
export function useNoteReactionNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<NoteReactionNameQuery, NoteReactionNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<NoteReactionNameQuery, NoteReactionNameQueryVariables>(NoteReactionNameDocument, options);
}
export type NoteReactionNameQueryHookResult = ReturnType<typeof useNoteReactionNameQuery>;
export type NoteReactionNameLazyQueryHookResult = ReturnType<typeof useNoteReactionNameLazyQuery>;
export type NoteReactionNameQueryResult = Apollo.QueryResult<NoteReactionNameQuery, NoteReactionNameQueryVariables>;
export const NoteReactionNamesDocument = gql`
	query noteReactionNames($filters: [NoteReactionFilterInput!], $pagination: Pagination, $order: NoteReactionOrder) {
		noteReactionConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...NoteReactionName
				}
			}
		}
	}
	${NoteReactionNameFragmentDoc}
`;

/**
 * __useNoteReactionNamesQuery__
 *
 * To run a query within a React component, call `useNoteReactionNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoteReactionNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoteReactionNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useNoteReactionNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<NoteReactionNamesQuery, NoteReactionNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<NoteReactionNamesQuery, NoteReactionNamesQueryVariables>(NoteReactionNamesDocument, options);
}
export function useNoteReactionNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<NoteReactionNamesQuery, NoteReactionNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<NoteReactionNamesQuery, NoteReactionNamesQueryVariables>(
		NoteReactionNamesDocument,
		options,
	);
}
export type NoteReactionNamesQueryHookResult = ReturnType<typeof useNoteReactionNamesQuery>;
export type NoteReactionNamesLazyQueryHookResult = ReturnType<typeof useNoteReactionNamesLazyQuery>;
export type NoteReactionNamesQueryResult = Apollo.QueryResult<NoteReactionNamesQuery, NoteReactionNamesQueryVariables>;
export const NoteReactionPaginationDocument = gql`
	query noteReactionPagination($filters: [NoteReactionFilterInput!], $order: NoteReactionOrder) {
		noteReactionConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useNoteReactionPaginationQuery__
 *
 * To run a query within a React component, call `useNoteReactionPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoteReactionPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoteReactionPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useNoteReactionPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<NoteReactionPaginationQuery, NoteReactionPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<NoteReactionPaginationQuery, NoteReactionPaginationQueryVariables>(
		NoteReactionPaginationDocument,
		options,
	);
}
export function useNoteReactionPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<NoteReactionPaginationQuery, NoteReactionPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<NoteReactionPaginationQuery, NoteReactionPaginationQueryVariables>(
		NoteReactionPaginationDocument,
		options,
	);
}
export type NoteReactionPaginationQueryHookResult = ReturnType<typeof useNoteReactionPaginationQuery>;
export type NoteReactionPaginationLazyQueryHookResult = ReturnType<typeof useNoteReactionPaginationLazyQuery>;
export type NoteReactionPaginationQueryResult = Apollo.QueryResult<
	NoteReactionPaginationQuery,
	NoteReactionPaginationQueryVariables
>;
export const NoteReactionSelectDocument = gql`
	query noteReactionSelect($filters: [NoteReactionFilterInput!], $order: NoteReactionOrder) {
		noteReactionConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...NoteReactionName
				}
			}
		}
	}
	${NoteReactionNameFragmentDoc}
`;

/**
 * __useNoteReactionSelectQuery__
 *
 * To run a query within a React component, call `useNoteReactionSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoteReactionSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoteReactionSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useNoteReactionSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<NoteReactionSelectQuery, NoteReactionSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<NoteReactionSelectQuery, NoteReactionSelectQueryVariables>(
		NoteReactionSelectDocument,
		options,
	);
}
export function useNoteReactionSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<NoteReactionSelectQuery, NoteReactionSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<NoteReactionSelectQuery, NoteReactionSelectQueryVariables>(
		NoteReactionSelectDocument,
		options,
	);
}
export type NoteReactionSelectQueryHookResult = ReturnType<typeof useNoteReactionSelectQuery>;
export type NoteReactionSelectLazyQueryHookResult = ReturnType<typeof useNoteReactionSelectLazyQuery>;
export type NoteReactionSelectQueryResult = Apollo.QueryResult<
	NoteReactionSelectQuery,
	NoteReactionSelectQueryVariables
>;
export const NoteReactionUpdateDocument = gql`
	mutation noteReactionUpdate($id: ID!, $input: NoteReactionUpdateInput!) {
		updateNoteReaction(id: $id, input: $input) {
			...NoteReactionDetail
		}
	}
	${NoteReactionDetailFragmentDoc}
`;
export type NoteReactionUpdateMutationFn = Apollo.MutationFunction<
	NoteReactionUpdateMutation,
	NoteReactionUpdateMutationVariables
>;

/**
 * __useNoteReactionUpdateMutation__
 *
 * To run a mutation, you first call `useNoteReactionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNoteReactionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [noteReactionUpdateMutation, { data, loading, error }] = useNoteReactionUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNoteReactionUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<NoteReactionUpdateMutation, NoteReactionUpdateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<NoteReactionUpdateMutation, NoteReactionUpdateMutationVariables>(
		NoteReactionUpdateDocument,
		options,
	);
}
export type NoteReactionUpdateMutationHookResult = ReturnType<typeof useNoteReactionUpdateMutation>;
export type NoteReactionUpdateMutationResult = Apollo.MutationResult<NoteReactionUpdateMutation>;
export type NoteReactionUpdateMutationOptions = Apollo.BaseMutationOptions<
	NoteReactionUpdateMutation,
	NoteReactionUpdateMutationVariables
>;
export const ParentCountDocument = gql`
	query parentCount($filters: [ParentFilterInput!], $pagination: Pagination, $order: ParentOrder) {
		parentConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useParentCountQuery__
 *
 * To run a query within a React component, call `useParentCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useParentCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParentCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useParentCountQuery(
	baseOptions?: Apollo.QueryHookOptions<ParentCountQuery, ParentCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ParentCountQuery, ParentCountQueryVariables>(ParentCountDocument, options);
}
export function useParentCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ParentCountQuery, ParentCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ParentCountQuery, ParentCountQueryVariables>(ParentCountDocument, options);
}
export type ParentCountQueryHookResult = ReturnType<typeof useParentCountQuery>;
export type ParentCountLazyQueryHookResult = ReturnType<typeof useParentCountLazyQuery>;
export type ParentCountQueryResult = Apollo.QueryResult<ParentCountQuery, ParentCountQueryVariables>;
export const ParentCreateDocument = gql`
	mutation parentCreate($input: ParentCreateInput!) {
		createParent(input: $input) {
			...ParentDetail
		}
	}
	${ParentDetailFragmentDoc}
`;
export type ParentCreateMutationFn = Apollo.MutationFunction<ParentCreateMutation, ParentCreateMutationVariables>;

/**
 * __useParentCreateMutation__
 *
 * To run a mutation, you first call `useParentCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useParentCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [parentCreateMutation, { data, loading, error }] = useParentCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useParentCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<ParentCreateMutation, ParentCreateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<ParentCreateMutation, ParentCreateMutationVariables>(ParentCreateDocument, options);
}
export type ParentCreateMutationHookResult = ReturnType<typeof useParentCreateMutation>;
export type ParentCreateMutationResult = Apollo.MutationResult<ParentCreateMutation>;
export type ParentCreateMutationOptions = Apollo.BaseMutationOptions<
	ParentCreateMutation,
	ParentCreateMutationVariables
>;
export const ParentDeleteDocument = gql`
	mutation parentDelete($id: ID!) {
		deleteParent(id: $id)
	}
`;
export type ParentDeleteMutationFn = Apollo.MutationFunction<ParentDeleteMutation, ParentDeleteMutationVariables>;

/**
 * __useParentDeleteMutation__
 *
 * To run a mutation, you first call `useParentDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useParentDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [parentDeleteMutation, { data, loading, error }] = useParentDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useParentDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<ParentDeleteMutation, ParentDeleteMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<ParentDeleteMutation, ParentDeleteMutationVariables>(ParentDeleteDocument, options);
}
export type ParentDeleteMutationHookResult = ReturnType<typeof useParentDeleteMutation>;
export type ParentDeleteMutationResult = Apollo.MutationResult<ParentDeleteMutation>;
export type ParentDeleteMutationOptions = Apollo.BaseMutationOptions<
	ParentDeleteMutation,
	ParentDeleteMutationVariables
>;
export const ParentDetailDocument = gql`
	query parentDetail($id: ID!) {
		parent(id: $id) {
			...ParentDetail
		}
	}
	${ParentDetailFragmentDoc}
`;

/**
 * __useParentDetailQuery__
 *
 * To run a query within a React component, call `useParentDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useParentDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParentDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useParentDetailQuery(
	baseOptions: Apollo.QueryHookOptions<ParentDetailQuery, ParentDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ParentDetailQuery, ParentDetailQueryVariables>(ParentDetailDocument, options);
}
export function useParentDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ParentDetailQuery, ParentDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ParentDetailQuery, ParentDetailQueryVariables>(ParentDetailDocument, options);
}
export type ParentDetailQueryHookResult = ReturnType<typeof useParentDetailQuery>;
export type ParentDetailLazyQueryHookResult = ReturnType<typeof useParentDetailLazyQuery>;
export type ParentDetailQueryResult = Apollo.QueryResult<ParentDetailQuery, ParentDetailQueryVariables>;
export const ParentIndexDocument = gql`
	query parentIndex($filters: [ParentFilterInput!], $pagination: Pagination, $order: ParentOrder) {
		parentConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					person {
						id
						name
						imageURL
					}
				}
			}
		}
	}
`;

/**
 * __useParentIndexQuery__
 *
 * To run a query within a React component, call `useParentIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useParentIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParentIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useParentIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<ParentIndexQuery, ParentIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ParentIndexQuery, ParentIndexQueryVariables>(ParentIndexDocument, options);
}
export function useParentIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ParentIndexQuery, ParentIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ParentIndexQuery, ParentIndexQueryVariables>(ParentIndexDocument, options);
}
export type ParentIndexQueryHookResult = ReturnType<typeof useParentIndexQuery>;
export type ParentIndexLazyQueryHookResult = ReturnType<typeof useParentIndexLazyQuery>;
export type ParentIndexQueryResult = Apollo.QueryResult<ParentIndexQuery, ParentIndexQueryVariables>;
export const ParentNameDocument = gql`
	query parentName($id: ID!) {
		parent(id: $id) {
			id
			...ParentName
		}
	}
	${ParentNameFragmentDoc}
`;

/**
 * __useParentNameQuery__
 *
 * To run a query within a React component, call `useParentNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useParentNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParentNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useParentNameQuery(baseOptions: Apollo.QueryHookOptions<ParentNameQuery, ParentNameQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ParentNameQuery, ParentNameQueryVariables>(ParentNameDocument, options);
}
export function useParentNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ParentNameQuery, ParentNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ParentNameQuery, ParentNameQueryVariables>(ParentNameDocument, options);
}
export type ParentNameQueryHookResult = ReturnType<typeof useParentNameQuery>;
export type ParentNameLazyQueryHookResult = ReturnType<typeof useParentNameLazyQuery>;
export type ParentNameQueryResult = Apollo.QueryResult<ParentNameQuery, ParentNameQueryVariables>;
export const ParentNamesDocument = gql`
	query parentNames($filters: [ParentFilterInput!], $pagination: Pagination, $order: ParentOrder) {
		parentConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...ParentName
				}
			}
		}
	}
	${ParentNameFragmentDoc}
`;

/**
 * __useParentNamesQuery__
 *
 * To run a query within a React component, call `useParentNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useParentNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParentNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useParentNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<ParentNamesQuery, ParentNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ParentNamesQuery, ParentNamesQueryVariables>(ParentNamesDocument, options);
}
export function useParentNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ParentNamesQuery, ParentNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ParentNamesQuery, ParentNamesQueryVariables>(ParentNamesDocument, options);
}
export type ParentNamesQueryHookResult = ReturnType<typeof useParentNamesQuery>;
export type ParentNamesLazyQueryHookResult = ReturnType<typeof useParentNamesLazyQuery>;
export type ParentNamesQueryResult = Apollo.QueryResult<ParentNamesQuery, ParentNamesQueryVariables>;
export const ParentPaginationDocument = gql`
	query parentPagination($filters: [ParentFilterInput!], $order: ParentOrder) {
		parentConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useParentPaginationQuery__
 *
 * To run a query within a React component, call `useParentPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useParentPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParentPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useParentPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<ParentPaginationQuery, ParentPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ParentPaginationQuery, ParentPaginationQueryVariables>(ParentPaginationDocument, options);
}
export function useParentPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ParentPaginationQuery, ParentPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ParentPaginationQuery, ParentPaginationQueryVariables>(ParentPaginationDocument, options);
}
export type ParentPaginationQueryHookResult = ReturnType<typeof useParentPaginationQuery>;
export type ParentPaginationLazyQueryHookResult = ReturnType<typeof useParentPaginationLazyQuery>;
export type ParentPaginationQueryResult = Apollo.QueryResult<ParentPaginationQuery, ParentPaginationQueryVariables>;
export const ParentSelectDocument = gql`
	query parentSelect($filters: [ParentFilterInput!], $order: ParentOrder) {
		parentConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...ParentName
				}
			}
		}
	}
	${ParentNameFragmentDoc}
`;

/**
 * __useParentSelectQuery__
 *
 * To run a query within a React component, call `useParentSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useParentSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParentSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useParentSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<ParentSelectQuery, ParentSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ParentSelectQuery, ParentSelectQueryVariables>(ParentSelectDocument, options);
}
export function useParentSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ParentSelectQuery, ParentSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ParentSelectQuery, ParentSelectQueryVariables>(ParentSelectDocument, options);
}
export type ParentSelectQueryHookResult = ReturnType<typeof useParentSelectQuery>;
export type ParentSelectLazyQueryHookResult = ReturnType<typeof useParentSelectLazyQuery>;
export type ParentSelectQueryResult = Apollo.QueryResult<ParentSelectQuery, ParentSelectQueryVariables>;
export const ParentUpdateDocument = gql`
	mutation parentUpdate($id: ID!, $input: ParentUpdateInput!) {
		updateParent(id: $id, input: $input) {
			...ParentDetail
		}
	}
	${ParentDetailFragmentDoc}
`;
export type ParentUpdateMutationFn = Apollo.MutationFunction<ParentUpdateMutation, ParentUpdateMutationVariables>;

/**
 * __useParentUpdateMutation__
 *
 * To run a mutation, you first call `useParentUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useParentUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [parentUpdateMutation, { data, loading, error }] = useParentUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useParentUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<ParentUpdateMutation, ParentUpdateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<ParentUpdateMutation, ParentUpdateMutationVariables>(ParentUpdateDocument, options);
}
export type ParentUpdateMutationHookResult = ReturnType<typeof useParentUpdateMutation>;
export type ParentUpdateMutationResult = Apollo.MutationResult<ParentUpdateMutation>;
export type ParentUpdateMutationOptions = Apollo.BaseMutationOptions<
	ParentUpdateMutation,
	ParentUpdateMutationVariables
>;
export const ParentUpdateWithPersonDocument = gql`
	mutation parentUpdateWithPerson($id: ID!, $input: ParentUpdateInput!, $personInput: PersonUpdateInput!) {
		updateParentWithPerson(id: $id, input: $input, personInput: $personInput) {
			...ParentDetail
		}
	}
	${ParentDetailFragmentDoc}
`;
export type ParentUpdateWithPersonMutationFn = Apollo.MutationFunction<
	ParentUpdateWithPersonMutation,
	ParentUpdateWithPersonMutationVariables
>;

/**
 * __useParentUpdateWithPersonMutation__
 *
 * To run a mutation, you first call `useParentUpdateWithPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useParentUpdateWithPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [parentUpdateWithPersonMutation, { data, loading, error }] = useParentUpdateWithPersonMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *      personInput: // value for 'personInput'
 *   },
 * });
 */
export function useParentUpdateWithPersonMutation(
	baseOptions?: Apollo.MutationHookOptions<ParentUpdateWithPersonMutation, ParentUpdateWithPersonMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<ParentUpdateWithPersonMutation, ParentUpdateWithPersonMutationVariables>(
		ParentUpdateWithPersonDocument,
		options,
	);
}
export type ParentUpdateWithPersonMutationHookResult = ReturnType<typeof useParentUpdateWithPersonMutation>;
export type ParentUpdateWithPersonMutationResult = Apollo.MutationResult<ParentUpdateWithPersonMutation>;
export type ParentUpdateWithPersonMutationOptions = Apollo.BaseMutationOptions<
	ParentUpdateWithPersonMutation,
	ParentUpdateWithPersonMutationVariables
>;
export const PaymentCountDocument = gql`
	query paymentCount($filters: [PaymentFilterInput!], $pagination: Pagination, $order: PaymentOrder) {
		paymentConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __usePaymentCountQuery__
 *
 * To run a query within a React component, call `usePaymentCountQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function usePaymentCountQuery(
	baseOptions?: Apollo.QueryHookOptions<PaymentCountQuery, PaymentCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<PaymentCountQuery, PaymentCountQueryVariables>(PaymentCountDocument, options);
}
export function usePaymentCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<PaymentCountQuery, PaymentCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<PaymentCountQuery, PaymentCountQueryVariables>(PaymentCountDocument, options);
}
export type PaymentCountQueryHookResult = ReturnType<typeof usePaymentCountQuery>;
export type PaymentCountLazyQueryHookResult = ReturnType<typeof usePaymentCountLazyQuery>;
export type PaymentCountQueryResult = Apollo.QueryResult<PaymentCountQuery, PaymentCountQueryVariables>;
export const PaymentCreateDocument = gql`
	mutation paymentCreate($input: PaymentCreateInput!) {
		createPayment(input: $input) {
			...PaymentDetail
		}
	}
	${PaymentDetailFragmentDoc}
`;
export type PaymentCreateMutationFn = Apollo.MutationFunction<PaymentCreateMutation, PaymentCreateMutationVariables>;

/**
 * __usePaymentCreateMutation__
 *
 * To run a mutation, you first call `usePaymentCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePaymentCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [paymentCreateMutation, { data, loading, error }] = usePaymentCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePaymentCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<PaymentCreateMutation, PaymentCreateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<PaymentCreateMutation, PaymentCreateMutationVariables>(PaymentCreateDocument, options);
}
export type PaymentCreateMutationHookResult = ReturnType<typeof usePaymentCreateMutation>;
export type PaymentCreateMutationResult = Apollo.MutationResult<PaymentCreateMutation>;
export type PaymentCreateMutationOptions = Apollo.BaseMutationOptions<
	PaymentCreateMutation,
	PaymentCreateMutationVariables
>;
export const PaymentDeleteDocument = gql`
	mutation paymentDelete($id: ID!) {
		deletePayment(id: $id)
	}
`;
export type PaymentDeleteMutationFn = Apollo.MutationFunction<PaymentDeleteMutation, PaymentDeleteMutationVariables>;

/**
 * __usePaymentDeleteMutation__
 *
 * To run a mutation, you first call `usePaymentDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePaymentDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [paymentDeleteMutation, { data, loading, error }] = usePaymentDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePaymentDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<PaymentDeleteMutation, PaymentDeleteMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<PaymentDeleteMutation, PaymentDeleteMutationVariables>(PaymentDeleteDocument, options);
}
export type PaymentDeleteMutationHookResult = ReturnType<typeof usePaymentDeleteMutation>;
export type PaymentDeleteMutationResult = Apollo.MutationResult<PaymentDeleteMutation>;
export type PaymentDeleteMutationOptions = Apollo.BaseMutationOptions<
	PaymentDeleteMutation,
	PaymentDeleteMutationVariables
>;
export const PaymentDetailDocument = gql`
	query paymentDetail($id: ID!) {
		payment(id: $id) {
			...PaymentDetail
		}
	}
	${PaymentDetailFragmentDoc}
`;

/**
 * __usePaymentDetailQuery__
 *
 * To run a query within a React component, call `usePaymentDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePaymentDetailQuery(
	baseOptions: Apollo.QueryHookOptions<PaymentDetailQuery, PaymentDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<PaymentDetailQuery, PaymentDetailQueryVariables>(PaymentDetailDocument, options);
}
export function usePaymentDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<PaymentDetailQuery, PaymentDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<PaymentDetailQuery, PaymentDetailQueryVariables>(PaymentDetailDocument, options);
}
export type PaymentDetailQueryHookResult = ReturnType<typeof usePaymentDetailQuery>;
export type PaymentDetailLazyQueryHookResult = ReturnType<typeof usePaymentDetailLazyQuery>;
export type PaymentDetailQueryResult = Apollo.QueryResult<PaymentDetailQuery, PaymentDetailQueryVariables>;
export const PaymentIndexDocument = gql`
	query paymentIndex($filters: [PaymentFilterInput!], $pagination: Pagination, $order: PaymentOrder) {
		paymentConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					createdDate
					notes
				}
			}
		}
	}
`;

/**
 * __usePaymentIndexQuery__
 *
 * To run a query within a React component, call `usePaymentIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function usePaymentIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<PaymentIndexQuery, PaymentIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<PaymentIndexQuery, PaymentIndexQueryVariables>(PaymentIndexDocument, options);
}
export function usePaymentIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<PaymentIndexQuery, PaymentIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<PaymentIndexQuery, PaymentIndexQueryVariables>(PaymentIndexDocument, options);
}
export type PaymentIndexQueryHookResult = ReturnType<typeof usePaymentIndexQuery>;
export type PaymentIndexLazyQueryHookResult = ReturnType<typeof usePaymentIndexLazyQuery>;
export type PaymentIndexQueryResult = Apollo.QueryResult<PaymentIndexQuery, PaymentIndexQueryVariables>;
export const PaymentNameDocument = gql`
	query paymentName($id: ID!) {
		payment(id: $id) {
			id
			...PaymentName
		}
	}
	${PaymentNameFragmentDoc}
`;

/**
 * __usePaymentNameQuery__
 *
 * To run a query within a React component, call `usePaymentNameQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePaymentNameQuery(baseOptions: Apollo.QueryHookOptions<PaymentNameQuery, PaymentNameQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<PaymentNameQuery, PaymentNameQueryVariables>(PaymentNameDocument, options);
}
export function usePaymentNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<PaymentNameQuery, PaymentNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<PaymentNameQuery, PaymentNameQueryVariables>(PaymentNameDocument, options);
}
export type PaymentNameQueryHookResult = ReturnType<typeof usePaymentNameQuery>;
export type PaymentNameLazyQueryHookResult = ReturnType<typeof usePaymentNameLazyQuery>;
export type PaymentNameQueryResult = Apollo.QueryResult<PaymentNameQuery, PaymentNameQueryVariables>;
export const PaymentNamesDocument = gql`
	query paymentNames($filters: [PaymentFilterInput!], $pagination: Pagination, $order: PaymentOrder) {
		paymentConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...PaymentName
				}
			}
		}
	}
	${PaymentNameFragmentDoc}
`;

/**
 * __usePaymentNamesQuery__
 *
 * To run a query within a React component, call `usePaymentNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function usePaymentNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<PaymentNamesQuery, PaymentNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<PaymentNamesQuery, PaymentNamesQueryVariables>(PaymentNamesDocument, options);
}
export function usePaymentNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<PaymentNamesQuery, PaymentNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<PaymentNamesQuery, PaymentNamesQueryVariables>(PaymentNamesDocument, options);
}
export type PaymentNamesQueryHookResult = ReturnType<typeof usePaymentNamesQuery>;
export type PaymentNamesLazyQueryHookResult = ReturnType<typeof usePaymentNamesLazyQuery>;
export type PaymentNamesQueryResult = Apollo.QueryResult<PaymentNamesQuery, PaymentNamesQueryVariables>;
export const PaymentPaginationDocument = gql`
	query paymentPagination($filters: [PaymentFilterInput!], $order: PaymentOrder) {
		paymentConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __usePaymentPaginationQuery__
 *
 * To run a query within a React component, call `usePaymentPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function usePaymentPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<PaymentPaginationQuery, PaymentPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<PaymentPaginationQuery, PaymentPaginationQueryVariables>(PaymentPaginationDocument, options);
}
export function usePaymentPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<PaymentPaginationQuery, PaymentPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<PaymentPaginationQuery, PaymentPaginationQueryVariables>(
		PaymentPaginationDocument,
		options,
	);
}
export type PaymentPaginationQueryHookResult = ReturnType<typeof usePaymentPaginationQuery>;
export type PaymentPaginationLazyQueryHookResult = ReturnType<typeof usePaymentPaginationLazyQuery>;
export type PaymentPaginationQueryResult = Apollo.QueryResult<PaymentPaginationQuery, PaymentPaginationQueryVariables>;
export const PaymentSelectDocument = gql`
	query paymentSelect($filters: [PaymentFilterInput!], $order: PaymentOrder) {
		paymentConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...PaymentName
				}
			}
		}
	}
	${PaymentNameFragmentDoc}
`;

/**
 * __usePaymentSelectQuery__
 *
 * To run a query within a React component, call `usePaymentSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function usePaymentSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<PaymentSelectQuery, PaymentSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<PaymentSelectQuery, PaymentSelectQueryVariables>(PaymentSelectDocument, options);
}
export function usePaymentSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<PaymentSelectQuery, PaymentSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<PaymentSelectQuery, PaymentSelectQueryVariables>(PaymentSelectDocument, options);
}
export type PaymentSelectQueryHookResult = ReturnType<typeof usePaymentSelectQuery>;
export type PaymentSelectLazyQueryHookResult = ReturnType<typeof usePaymentSelectLazyQuery>;
export type PaymentSelectQueryResult = Apollo.QueryResult<PaymentSelectQuery, PaymentSelectQueryVariables>;
export const PaymentUpdateDocument = gql`
	mutation paymentUpdate($id: ID!, $input: PaymentUpdateInput!) {
		updatePayment(id: $id, input: $input) {
			...PaymentDetail
		}
	}
	${PaymentDetailFragmentDoc}
`;
export type PaymentUpdateMutationFn = Apollo.MutationFunction<PaymentUpdateMutation, PaymentUpdateMutationVariables>;

/**
 * __usePaymentUpdateMutation__
 *
 * To run a mutation, you first call `usePaymentUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePaymentUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [paymentUpdateMutation, { data, loading, error }] = usePaymentUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePaymentUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<PaymentUpdateMutation, PaymentUpdateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<PaymentUpdateMutation, PaymentUpdateMutationVariables>(PaymentUpdateDocument, options);
}
export type PaymentUpdateMutationHookResult = ReturnType<typeof usePaymentUpdateMutation>;
export type PaymentUpdateMutationResult = Apollo.MutationResult<PaymentUpdateMutation>;
export type PaymentUpdateMutationOptions = Apollo.BaseMutationOptions<
	PaymentUpdateMutation,
	PaymentUpdateMutationVariables
>;
export const PaymentLineItemCountDocument = gql`
	query paymentLineItemCount(
		$filters: [PaymentLineItemFilterInput!]
		$pagination: Pagination
		$order: PaymentLineItemOrder
	) {
		paymentLineItemConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __usePaymentLineItemCountQuery__
 *
 * To run a query within a React component, call `usePaymentLineItemCountQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentLineItemCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentLineItemCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function usePaymentLineItemCountQuery(
	baseOptions?: Apollo.QueryHookOptions<PaymentLineItemCountQuery, PaymentLineItemCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<PaymentLineItemCountQuery, PaymentLineItemCountQueryVariables>(
		PaymentLineItemCountDocument,
		options,
	);
}
export function usePaymentLineItemCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<PaymentLineItemCountQuery, PaymentLineItemCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<PaymentLineItemCountQuery, PaymentLineItemCountQueryVariables>(
		PaymentLineItemCountDocument,
		options,
	);
}
export type PaymentLineItemCountQueryHookResult = ReturnType<typeof usePaymentLineItemCountQuery>;
export type PaymentLineItemCountLazyQueryHookResult = ReturnType<typeof usePaymentLineItemCountLazyQuery>;
export type PaymentLineItemCountQueryResult = Apollo.QueryResult<
	PaymentLineItemCountQuery,
	PaymentLineItemCountQueryVariables
>;
export const PaymentLineItemCreateDocument = gql`
	mutation paymentLineItemCreate($input: PaymentLineItemCreateInput!) {
		createPaymentLineItem(input: $input) {
			...PaymentLineItemDetail
		}
	}
	${PaymentLineItemDetailFragmentDoc}
`;
export type PaymentLineItemCreateMutationFn = Apollo.MutationFunction<
	PaymentLineItemCreateMutation,
	PaymentLineItemCreateMutationVariables
>;

/**
 * __usePaymentLineItemCreateMutation__
 *
 * To run a mutation, you first call `usePaymentLineItemCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePaymentLineItemCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [paymentLineItemCreateMutation, { data, loading, error }] = usePaymentLineItemCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePaymentLineItemCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<PaymentLineItemCreateMutation, PaymentLineItemCreateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<PaymentLineItemCreateMutation, PaymentLineItemCreateMutationVariables>(
		PaymentLineItemCreateDocument,
		options,
	);
}
export type PaymentLineItemCreateMutationHookResult = ReturnType<typeof usePaymentLineItemCreateMutation>;
export type PaymentLineItemCreateMutationResult = Apollo.MutationResult<PaymentLineItemCreateMutation>;
export type PaymentLineItemCreateMutationOptions = Apollo.BaseMutationOptions<
	PaymentLineItemCreateMutation,
	PaymentLineItemCreateMutationVariables
>;
export const PaymentLineItemDeleteDocument = gql`
	mutation paymentLineItemDelete($id: ID!) {
		deletePaymentLineItem(id: $id)
	}
`;
export type PaymentLineItemDeleteMutationFn = Apollo.MutationFunction<
	PaymentLineItemDeleteMutation,
	PaymentLineItemDeleteMutationVariables
>;

/**
 * __usePaymentLineItemDeleteMutation__
 *
 * To run a mutation, you first call `usePaymentLineItemDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePaymentLineItemDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [paymentLineItemDeleteMutation, { data, loading, error }] = usePaymentLineItemDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePaymentLineItemDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<PaymentLineItemDeleteMutation, PaymentLineItemDeleteMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<PaymentLineItemDeleteMutation, PaymentLineItemDeleteMutationVariables>(
		PaymentLineItemDeleteDocument,
		options,
	);
}
export type PaymentLineItemDeleteMutationHookResult = ReturnType<typeof usePaymentLineItemDeleteMutation>;
export type PaymentLineItemDeleteMutationResult = Apollo.MutationResult<PaymentLineItemDeleteMutation>;
export type PaymentLineItemDeleteMutationOptions = Apollo.BaseMutationOptions<
	PaymentLineItemDeleteMutation,
	PaymentLineItemDeleteMutationVariables
>;
export const PaymentLineItemDetailDocument = gql`
	query paymentLineItemDetail($id: ID!) {
		paymentLineItem(id: $id) {
			...PaymentLineItemDetail
		}
	}
	${PaymentLineItemDetailFragmentDoc}
`;

/**
 * __usePaymentLineItemDetailQuery__
 *
 * To run a query within a React component, call `usePaymentLineItemDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentLineItemDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentLineItemDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePaymentLineItemDetailQuery(
	baseOptions: Apollo.QueryHookOptions<PaymentLineItemDetailQuery, PaymentLineItemDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<PaymentLineItemDetailQuery, PaymentLineItemDetailQueryVariables>(
		PaymentLineItemDetailDocument,
		options,
	);
}
export function usePaymentLineItemDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<PaymentLineItemDetailQuery, PaymentLineItemDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<PaymentLineItemDetailQuery, PaymentLineItemDetailQueryVariables>(
		PaymentLineItemDetailDocument,
		options,
	);
}
export type PaymentLineItemDetailQueryHookResult = ReturnType<typeof usePaymentLineItemDetailQuery>;
export type PaymentLineItemDetailLazyQueryHookResult = ReturnType<typeof usePaymentLineItemDetailLazyQuery>;
export type PaymentLineItemDetailQueryResult = Apollo.QueryResult<
	PaymentLineItemDetailQuery,
	PaymentLineItemDetailQueryVariables
>;
export const PaymentLineItemIndexDocument = gql`
	query paymentLineItemIndex(
		$filters: [PaymentLineItemFilterInput!]
		$pagination: Pagination
		$order: PaymentLineItemOrder
	) {
		paymentLineItemConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					amount
				}
			}
		}
	}
`;

/**
 * __usePaymentLineItemIndexQuery__
 *
 * To run a query within a React component, call `usePaymentLineItemIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentLineItemIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentLineItemIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function usePaymentLineItemIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<PaymentLineItemIndexQuery, PaymentLineItemIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<PaymentLineItemIndexQuery, PaymentLineItemIndexQueryVariables>(
		PaymentLineItemIndexDocument,
		options,
	);
}
export function usePaymentLineItemIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<PaymentLineItemIndexQuery, PaymentLineItemIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<PaymentLineItemIndexQuery, PaymentLineItemIndexQueryVariables>(
		PaymentLineItemIndexDocument,
		options,
	);
}
export type PaymentLineItemIndexQueryHookResult = ReturnType<typeof usePaymentLineItemIndexQuery>;
export type PaymentLineItemIndexLazyQueryHookResult = ReturnType<typeof usePaymentLineItemIndexLazyQuery>;
export type PaymentLineItemIndexQueryResult = Apollo.QueryResult<
	PaymentLineItemIndexQuery,
	PaymentLineItemIndexQueryVariables
>;
export const PaymentLineItemNameDocument = gql`
	query paymentLineItemName($id: ID!) {
		paymentLineItem(id: $id) {
			id
			...PaymentLineItemName
		}
	}
	${PaymentLineItemNameFragmentDoc}
`;

/**
 * __usePaymentLineItemNameQuery__
 *
 * To run a query within a React component, call `usePaymentLineItemNameQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentLineItemNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentLineItemNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePaymentLineItemNameQuery(
	baseOptions: Apollo.QueryHookOptions<PaymentLineItemNameQuery, PaymentLineItemNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<PaymentLineItemNameQuery, PaymentLineItemNameQueryVariables>(
		PaymentLineItemNameDocument,
		options,
	);
}
export function usePaymentLineItemNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<PaymentLineItemNameQuery, PaymentLineItemNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<PaymentLineItemNameQuery, PaymentLineItemNameQueryVariables>(
		PaymentLineItemNameDocument,
		options,
	);
}
export type PaymentLineItemNameQueryHookResult = ReturnType<typeof usePaymentLineItemNameQuery>;
export type PaymentLineItemNameLazyQueryHookResult = ReturnType<typeof usePaymentLineItemNameLazyQuery>;
export type PaymentLineItemNameQueryResult = Apollo.QueryResult<
	PaymentLineItemNameQuery,
	PaymentLineItemNameQueryVariables
>;
export const PaymentLineItemNamesDocument = gql`
	query paymentLineItemNames(
		$filters: [PaymentLineItemFilterInput!]
		$pagination: Pagination
		$order: PaymentLineItemOrder
	) {
		paymentLineItemConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...PaymentLineItemName
				}
			}
		}
	}
	${PaymentLineItemNameFragmentDoc}
`;

/**
 * __usePaymentLineItemNamesQuery__
 *
 * To run a query within a React component, call `usePaymentLineItemNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentLineItemNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentLineItemNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function usePaymentLineItemNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<PaymentLineItemNamesQuery, PaymentLineItemNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<PaymentLineItemNamesQuery, PaymentLineItemNamesQueryVariables>(
		PaymentLineItemNamesDocument,
		options,
	);
}
export function usePaymentLineItemNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<PaymentLineItemNamesQuery, PaymentLineItemNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<PaymentLineItemNamesQuery, PaymentLineItemNamesQueryVariables>(
		PaymentLineItemNamesDocument,
		options,
	);
}
export type PaymentLineItemNamesQueryHookResult = ReturnType<typeof usePaymentLineItemNamesQuery>;
export type PaymentLineItemNamesLazyQueryHookResult = ReturnType<typeof usePaymentLineItemNamesLazyQuery>;
export type PaymentLineItemNamesQueryResult = Apollo.QueryResult<
	PaymentLineItemNamesQuery,
	PaymentLineItemNamesQueryVariables
>;
export const PaymentLineItemPaginationDocument = gql`
	query paymentLineItemPagination($filters: [PaymentLineItemFilterInput!], $order: PaymentLineItemOrder) {
		paymentLineItemConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __usePaymentLineItemPaginationQuery__
 *
 * To run a query within a React component, call `usePaymentLineItemPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentLineItemPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentLineItemPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function usePaymentLineItemPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<PaymentLineItemPaginationQuery, PaymentLineItemPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<PaymentLineItemPaginationQuery, PaymentLineItemPaginationQueryVariables>(
		PaymentLineItemPaginationDocument,
		options,
	);
}
export function usePaymentLineItemPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<PaymentLineItemPaginationQuery, PaymentLineItemPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<PaymentLineItemPaginationQuery, PaymentLineItemPaginationQueryVariables>(
		PaymentLineItemPaginationDocument,
		options,
	);
}
export type PaymentLineItemPaginationQueryHookResult = ReturnType<typeof usePaymentLineItemPaginationQuery>;
export type PaymentLineItemPaginationLazyQueryHookResult = ReturnType<typeof usePaymentLineItemPaginationLazyQuery>;
export type PaymentLineItemPaginationQueryResult = Apollo.QueryResult<
	PaymentLineItemPaginationQuery,
	PaymentLineItemPaginationQueryVariables
>;
export const PaymentLineItemSelectDocument = gql`
	query paymentLineItemSelect($filters: [PaymentLineItemFilterInput!], $order: PaymentLineItemOrder) {
		paymentLineItemConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...PaymentLineItemName
				}
			}
		}
	}
	${PaymentLineItemNameFragmentDoc}
`;

/**
 * __usePaymentLineItemSelectQuery__
 *
 * To run a query within a React component, call `usePaymentLineItemSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentLineItemSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentLineItemSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function usePaymentLineItemSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<PaymentLineItemSelectQuery, PaymentLineItemSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<PaymentLineItemSelectQuery, PaymentLineItemSelectQueryVariables>(
		PaymentLineItemSelectDocument,
		options,
	);
}
export function usePaymentLineItemSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<PaymentLineItemSelectQuery, PaymentLineItemSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<PaymentLineItemSelectQuery, PaymentLineItemSelectQueryVariables>(
		PaymentLineItemSelectDocument,
		options,
	);
}
export type PaymentLineItemSelectQueryHookResult = ReturnType<typeof usePaymentLineItemSelectQuery>;
export type PaymentLineItemSelectLazyQueryHookResult = ReturnType<typeof usePaymentLineItemSelectLazyQuery>;
export type PaymentLineItemSelectQueryResult = Apollo.QueryResult<
	PaymentLineItemSelectQuery,
	PaymentLineItemSelectQueryVariables
>;
export const PaymentLineItemUpdateDocument = gql`
	mutation paymentLineItemUpdate($id: ID!, $input: PaymentLineItemUpdateInput!) {
		updatePaymentLineItem(id: $id, input: $input) {
			...PaymentLineItemDetail
		}
	}
	${PaymentLineItemDetailFragmentDoc}
`;
export type PaymentLineItemUpdateMutationFn = Apollo.MutationFunction<
	PaymentLineItemUpdateMutation,
	PaymentLineItemUpdateMutationVariables
>;

/**
 * __usePaymentLineItemUpdateMutation__
 *
 * To run a mutation, you first call `usePaymentLineItemUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePaymentLineItemUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [paymentLineItemUpdateMutation, { data, loading, error }] = usePaymentLineItemUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePaymentLineItemUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<PaymentLineItemUpdateMutation, PaymentLineItemUpdateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<PaymentLineItemUpdateMutation, PaymentLineItemUpdateMutationVariables>(
		PaymentLineItemUpdateDocument,
		options,
	);
}
export type PaymentLineItemUpdateMutationHookResult = ReturnType<typeof usePaymentLineItemUpdateMutation>;
export type PaymentLineItemUpdateMutationResult = Apollo.MutationResult<PaymentLineItemUpdateMutation>;
export type PaymentLineItemUpdateMutationOptions = Apollo.BaseMutationOptions<
	PaymentLineItemUpdateMutation,
	PaymentLineItemUpdateMutationVariables
>;
export const PermissionCountDocument = gql`
	query permissionCount($filters: [PermissionFilterInput!], $pagination: Pagination, $order: PermissionOrder) {
		permissionConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __usePermissionCountQuery__
 *
 * To run a query within a React component, call `usePermissionCountQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function usePermissionCountQuery(
	baseOptions?: Apollo.QueryHookOptions<PermissionCountQuery, PermissionCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<PermissionCountQuery, PermissionCountQueryVariables>(PermissionCountDocument, options);
}
export function usePermissionCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<PermissionCountQuery, PermissionCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<PermissionCountQuery, PermissionCountQueryVariables>(PermissionCountDocument, options);
}
export type PermissionCountQueryHookResult = ReturnType<typeof usePermissionCountQuery>;
export type PermissionCountLazyQueryHookResult = ReturnType<typeof usePermissionCountLazyQuery>;
export type PermissionCountQueryResult = Apollo.QueryResult<PermissionCountQuery, PermissionCountQueryVariables>;
export const PermissionDetailDocument = gql`
	query permissionDetail($id: ID!) {
		permission(id: $id) {
			...PermissionDetail
		}
	}
	${PermissionDetailFragmentDoc}
`;

/**
 * __usePermissionDetailQuery__
 *
 * To run a query within a React component, call `usePermissionDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePermissionDetailQuery(
	baseOptions: Apollo.QueryHookOptions<PermissionDetailQuery, PermissionDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<PermissionDetailQuery, PermissionDetailQueryVariables>(PermissionDetailDocument, options);
}
export function usePermissionDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<PermissionDetailQuery, PermissionDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<PermissionDetailQuery, PermissionDetailQueryVariables>(PermissionDetailDocument, options);
}
export type PermissionDetailQueryHookResult = ReturnType<typeof usePermissionDetailQuery>;
export type PermissionDetailLazyQueryHookResult = ReturnType<typeof usePermissionDetailLazyQuery>;
export type PermissionDetailQueryResult = Apollo.QueryResult<PermissionDetailQuery, PermissionDetailQueryVariables>;
export const PermissionIndexDocument = gql`
	query permissionIndex($filters: [PermissionFilterInput!], $pagination: Pagination, $order: PermissionOrder) {
		permissionConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					name
					family
					action
					level
					description
				}
			}
		}
	}
`;

/**
 * __usePermissionIndexQuery__
 *
 * To run a query within a React component, call `usePermissionIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function usePermissionIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<PermissionIndexQuery, PermissionIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<PermissionIndexQuery, PermissionIndexQueryVariables>(PermissionIndexDocument, options);
}
export function usePermissionIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<PermissionIndexQuery, PermissionIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<PermissionIndexQuery, PermissionIndexQueryVariables>(PermissionIndexDocument, options);
}
export type PermissionIndexQueryHookResult = ReturnType<typeof usePermissionIndexQuery>;
export type PermissionIndexLazyQueryHookResult = ReturnType<typeof usePermissionIndexLazyQuery>;
export type PermissionIndexQueryResult = Apollo.QueryResult<PermissionIndexQuery, PermissionIndexQueryVariables>;
export const PermissionNameDocument = gql`
	query permissionName($id: ID!) {
		permission(id: $id) {
			id
			...PermissionName
		}
	}
	${PermissionNameFragmentDoc}
`;

/**
 * __usePermissionNameQuery__
 *
 * To run a query within a React component, call `usePermissionNameQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePermissionNameQuery(
	baseOptions: Apollo.QueryHookOptions<PermissionNameQuery, PermissionNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<PermissionNameQuery, PermissionNameQueryVariables>(PermissionNameDocument, options);
}
export function usePermissionNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<PermissionNameQuery, PermissionNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<PermissionNameQuery, PermissionNameQueryVariables>(PermissionNameDocument, options);
}
export type PermissionNameQueryHookResult = ReturnType<typeof usePermissionNameQuery>;
export type PermissionNameLazyQueryHookResult = ReturnType<typeof usePermissionNameLazyQuery>;
export type PermissionNameQueryResult = Apollo.QueryResult<PermissionNameQuery, PermissionNameQueryVariables>;
export const PermissionNamesDocument = gql`
	query permissionNames($filters: [PermissionFilterInput!], $pagination: Pagination, $order: PermissionOrder) {
		permissionConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...PermissionName
				}
			}
		}
	}
	${PermissionNameFragmentDoc}
`;

/**
 * __usePermissionNamesQuery__
 *
 * To run a query within a React component, call `usePermissionNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function usePermissionNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<PermissionNamesQuery, PermissionNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<PermissionNamesQuery, PermissionNamesQueryVariables>(PermissionNamesDocument, options);
}
export function usePermissionNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<PermissionNamesQuery, PermissionNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<PermissionNamesQuery, PermissionNamesQueryVariables>(PermissionNamesDocument, options);
}
export type PermissionNamesQueryHookResult = ReturnType<typeof usePermissionNamesQuery>;
export type PermissionNamesLazyQueryHookResult = ReturnType<typeof usePermissionNamesLazyQuery>;
export type PermissionNamesQueryResult = Apollo.QueryResult<PermissionNamesQuery, PermissionNamesQueryVariables>;
export const PermissionPaginationDocument = gql`
	query permissionPagination($filters: [PermissionFilterInput!], $order: PermissionOrder) {
		permissionConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __usePermissionPaginationQuery__
 *
 * To run a query within a React component, call `usePermissionPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function usePermissionPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<PermissionPaginationQuery, PermissionPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<PermissionPaginationQuery, PermissionPaginationQueryVariables>(
		PermissionPaginationDocument,
		options,
	);
}
export function usePermissionPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<PermissionPaginationQuery, PermissionPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<PermissionPaginationQuery, PermissionPaginationQueryVariables>(
		PermissionPaginationDocument,
		options,
	);
}
export type PermissionPaginationQueryHookResult = ReturnType<typeof usePermissionPaginationQuery>;
export type PermissionPaginationLazyQueryHookResult = ReturnType<typeof usePermissionPaginationLazyQuery>;
export type PermissionPaginationQueryResult = Apollo.QueryResult<
	PermissionPaginationQuery,
	PermissionPaginationQueryVariables
>;
export const PermissionSelectDocument = gql`
	query permissionSelect($filters: [PermissionFilterInput!], $order: PermissionOrder) {
		permissionConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...PermissionName
				}
			}
		}
	}
	${PermissionNameFragmentDoc}
`;

/**
 * __usePermissionSelectQuery__
 *
 * To run a query within a React component, call `usePermissionSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function usePermissionSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<PermissionSelectQuery, PermissionSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<PermissionSelectQuery, PermissionSelectQueryVariables>(PermissionSelectDocument, options);
}
export function usePermissionSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<PermissionSelectQuery, PermissionSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<PermissionSelectQuery, PermissionSelectQueryVariables>(PermissionSelectDocument, options);
}
export type PermissionSelectQueryHookResult = ReturnType<typeof usePermissionSelectQuery>;
export type PermissionSelectLazyQueryHookResult = ReturnType<typeof usePermissionSelectLazyQuery>;
export type PermissionSelectQueryResult = Apollo.QueryResult<PermissionSelectQuery, PermissionSelectQueryVariables>;
export const PermissionUpdateDocument = gql`
	mutation permissionUpdate($id: ID!, $input: PermissionUpdateInput!) {
		updatePermission(id: $id, input: $input) {
			...PermissionDetail
		}
	}
	${PermissionDetailFragmentDoc}
`;
export type PermissionUpdateMutationFn = Apollo.MutationFunction<
	PermissionUpdateMutation,
	PermissionUpdateMutationVariables
>;

/**
 * __usePermissionUpdateMutation__
 *
 * To run a mutation, you first call `usePermissionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePermissionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [permissionUpdateMutation, { data, loading, error }] = usePermissionUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePermissionUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<PermissionUpdateMutation, PermissionUpdateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<PermissionUpdateMutation, PermissionUpdateMutationVariables>(
		PermissionUpdateDocument,
		options,
	);
}
export type PermissionUpdateMutationHookResult = ReturnType<typeof usePermissionUpdateMutation>;
export type PermissionUpdateMutationResult = Apollo.MutationResult<PermissionUpdateMutation>;
export type PermissionUpdateMutationOptions = Apollo.BaseMutationOptions<
	PermissionUpdateMutation,
	PermissionUpdateMutationVariables
>;
export const PermissionGroupCheckUniqueNameDocument = gql`
	query permissionGroupCheckUniqueName($ignoreID: ID, $name: String!) {
		permissionGroupConnection(filters: { idNEQ: $ignoreID, nameEQ: $name }) {
			totalCount
		}
	}
`;

/**
 * __usePermissionGroupCheckUniqueNameQuery__
 *
 * To run a query within a React component, call `usePermissionGroupCheckUniqueNameQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionGroupCheckUniqueNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionGroupCheckUniqueNameQuery({
 *   variables: {
 *      ignoreID: // value for 'ignoreID'
 *      name: // value for 'name'
 *   },
 * });
 */
export function usePermissionGroupCheckUniqueNameQuery(
	baseOptions: Apollo.QueryHookOptions<
		PermissionGroupCheckUniqueNameQuery,
		PermissionGroupCheckUniqueNameQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<PermissionGroupCheckUniqueNameQuery, PermissionGroupCheckUniqueNameQueryVariables>(
		PermissionGroupCheckUniqueNameDocument,
		options,
	);
}
export function usePermissionGroupCheckUniqueNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		PermissionGroupCheckUniqueNameQuery,
		PermissionGroupCheckUniqueNameQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<PermissionGroupCheckUniqueNameQuery, PermissionGroupCheckUniqueNameQueryVariables>(
		PermissionGroupCheckUniqueNameDocument,
		options,
	);
}
export type PermissionGroupCheckUniqueNameQueryHookResult = ReturnType<typeof usePermissionGroupCheckUniqueNameQuery>;
export type PermissionGroupCheckUniqueNameLazyQueryHookResult = ReturnType<
	typeof usePermissionGroupCheckUniqueNameLazyQuery
>;
export type PermissionGroupCheckUniqueNameQueryResult = Apollo.QueryResult<
	PermissionGroupCheckUniqueNameQuery,
	PermissionGroupCheckUniqueNameQueryVariables
>;
export const PermissionGroupCountDocument = gql`
	query permissionGroupCount(
		$filters: [PermissionGroupFilterInput!]
		$pagination: Pagination
		$order: PermissionGroupOrder
	) {
		permissionGroupConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __usePermissionGroupCountQuery__
 *
 * To run a query within a React component, call `usePermissionGroupCountQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionGroupCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionGroupCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function usePermissionGroupCountQuery(
	baseOptions?: Apollo.QueryHookOptions<PermissionGroupCountQuery, PermissionGroupCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<PermissionGroupCountQuery, PermissionGroupCountQueryVariables>(
		PermissionGroupCountDocument,
		options,
	);
}
export function usePermissionGroupCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<PermissionGroupCountQuery, PermissionGroupCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<PermissionGroupCountQuery, PermissionGroupCountQueryVariables>(
		PermissionGroupCountDocument,
		options,
	);
}
export type PermissionGroupCountQueryHookResult = ReturnType<typeof usePermissionGroupCountQuery>;
export type PermissionGroupCountLazyQueryHookResult = ReturnType<typeof usePermissionGroupCountLazyQuery>;
export type PermissionGroupCountQueryResult = Apollo.QueryResult<
	PermissionGroupCountQuery,
	PermissionGroupCountQueryVariables
>;
export const PermissionGroupCreateDocument = gql`
	mutation permissionGroupCreate($input: PermissionGroupCreateInput!) {
		createPermissionGroup(input: $input) {
			...PermissionGroupDetail
		}
	}
	${PermissionGroupDetailFragmentDoc}
`;
export type PermissionGroupCreateMutationFn = Apollo.MutationFunction<
	PermissionGroupCreateMutation,
	PermissionGroupCreateMutationVariables
>;

/**
 * __usePermissionGroupCreateMutation__
 *
 * To run a mutation, you first call `usePermissionGroupCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePermissionGroupCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [permissionGroupCreateMutation, { data, loading, error }] = usePermissionGroupCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePermissionGroupCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<PermissionGroupCreateMutation, PermissionGroupCreateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<PermissionGroupCreateMutation, PermissionGroupCreateMutationVariables>(
		PermissionGroupCreateDocument,
		options,
	);
}
export type PermissionGroupCreateMutationHookResult = ReturnType<typeof usePermissionGroupCreateMutation>;
export type PermissionGroupCreateMutationResult = Apollo.MutationResult<PermissionGroupCreateMutation>;
export type PermissionGroupCreateMutationOptions = Apollo.BaseMutationOptions<
	PermissionGroupCreateMutation,
	PermissionGroupCreateMutationVariables
>;
export const PermissionGroupDeleteDocument = gql`
	mutation permissionGroupDelete($id: ID!) {
		deletePermissionGroup(id: $id)
	}
`;
export type PermissionGroupDeleteMutationFn = Apollo.MutationFunction<
	PermissionGroupDeleteMutation,
	PermissionGroupDeleteMutationVariables
>;

/**
 * __usePermissionGroupDeleteMutation__
 *
 * To run a mutation, you first call `usePermissionGroupDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePermissionGroupDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [permissionGroupDeleteMutation, { data, loading, error }] = usePermissionGroupDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePermissionGroupDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<PermissionGroupDeleteMutation, PermissionGroupDeleteMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<PermissionGroupDeleteMutation, PermissionGroupDeleteMutationVariables>(
		PermissionGroupDeleteDocument,
		options,
	);
}
export type PermissionGroupDeleteMutationHookResult = ReturnType<typeof usePermissionGroupDeleteMutation>;
export type PermissionGroupDeleteMutationResult = Apollo.MutationResult<PermissionGroupDeleteMutation>;
export type PermissionGroupDeleteMutationOptions = Apollo.BaseMutationOptions<
	PermissionGroupDeleteMutation,
	PermissionGroupDeleteMutationVariables
>;
export const PermissionGroupDetailDocument = gql`
	query permissionGroupDetail($id: ID!) {
		permissionGroup(id: $id) {
			...PermissionGroupDetail
		}
	}
	${PermissionGroupDetailFragmentDoc}
`;

/**
 * __usePermissionGroupDetailQuery__
 *
 * To run a query within a React component, call `usePermissionGroupDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionGroupDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionGroupDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePermissionGroupDetailQuery(
	baseOptions: Apollo.QueryHookOptions<PermissionGroupDetailQuery, PermissionGroupDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<PermissionGroupDetailQuery, PermissionGroupDetailQueryVariables>(
		PermissionGroupDetailDocument,
		options,
	);
}
export function usePermissionGroupDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<PermissionGroupDetailQuery, PermissionGroupDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<PermissionGroupDetailQuery, PermissionGroupDetailQueryVariables>(
		PermissionGroupDetailDocument,
		options,
	);
}
export type PermissionGroupDetailQueryHookResult = ReturnType<typeof usePermissionGroupDetailQuery>;
export type PermissionGroupDetailLazyQueryHookResult = ReturnType<typeof usePermissionGroupDetailLazyQuery>;
export type PermissionGroupDetailQueryResult = Apollo.QueryResult<
	PermissionGroupDetailQuery,
	PermissionGroupDetailQueryVariables
>;
export const PermissionGroupIndexDocument = gql`
	query permissionGroupIndex(
		$filters: [PermissionGroupFilterInput!]
		$pagination: Pagination
		$order: PermissionGroupOrder
	) {
		permissionGroupConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					name
					description
				}
			}
		}
	}
`;

/**
 * __usePermissionGroupIndexQuery__
 *
 * To run a query within a React component, call `usePermissionGroupIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionGroupIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionGroupIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function usePermissionGroupIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<PermissionGroupIndexQuery, PermissionGroupIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<PermissionGroupIndexQuery, PermissionGroupIndexQueryVariables>(
		PermissionGroupIndexDocument,
		options,
	);
}
export function usePermissionGroupIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<PermissionGroupIndexQuery, PermissionGroupIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<PermissionGroupIndexQuery, PermissionGroupIndexQueryVariables>(
		PermissionGroupIndexDocument,
		options,
	);
}
export type PermissionGroupIndexQueryHookResult = ReturnType<typeof usePermissionGroupIndexQuery>;
export type PermissionGroupIndexLazyQueryHookResult = ReturnType<typeof usePermissionGroupIndexLazyQuery>;
export type PermissionGroupIndexQueryResult = Apollo.QueryResult<
	PermissionGroupIndexQuery,
	PermissionGroupIndexQueryVariables
>;
export const PermissionGroupNameDocument = gql`
	query permissionGroupName($id: ID!) {
		permissionGroup(id: $id) {
			id
			...PermissionGroupName
		}
	}
	${PermissionGroupNameFragmentDoc}
`;

/**
 * __usePermissionGroupNameQuery__
 *
 * To run a query within a React component, call `usePermissionGroupNameQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionGroupNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionGroupNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePermissionGroupNameQuery(
	baseOptions: Apollo.QueryHookOptions<PermissionGroupNameQuery, PermissionGroupNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<PermissionGroupNameQuery, PermissionGroupNameQueryVariables>(
		PermissionGroupNameDocument,
		options,
	);
}
export function usePermissionGroupNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<PermissionGroupNameQuery, PermissionGroupNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<PermissionGroupNameQuery, PermissionGroupNameQueryVariables>(
		PermissionGroupNameDocument,
		options,
	);
}
export type PermissionGroupNameQueryHookResult = ReturnType<typeof usePermissionGroupNameQuery>;
export type PermissionGroupNameLazyQueryHookResult = ReturnType<typeof usePermissionGroupNameLazyQuery>;
export type PermissionGroupNameQueryResult = Apollo.QueryResult<
	PermissionGroupNameQuery,
	PermissionGroupNameQueryVariables
>;
export const PermissionGroupNamesDocument = gql`
	query permissionGroupNames(
		$filters: [PermissionGroupFilterInput!]
		$pagination: Pagination
		$order: PermissionGroupOrder
	) {
		permissionGroupConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...PermissionGroupName
				}
			}
		}
	}
	${PermissionGroupNameFragmentDoc}
`;

/**
 * __usePermissionGroupNamesQuery__
 *
 * To run a query within a React component, call `usePermissionGroupNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionGroupNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionGroupNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function usePermissionGroupNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<PermissionGroupNamesQuery, PermissionGroupNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<PermissionGroupNamesQuery, PermissionGroupNamesQueryVariables>(
		PermissionGroupNamesDocument,
		options,
	);
}
export function usePermissionGroupNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<PermissionGroupNamesQuery, PermissionGroupNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<PermissionGroupNamesQuery, PermissionGroupNamesQueryVariables>(
		PermissionGroupNamesDocument,
		options,
	);
}
export type PermissionGroupNamesQueryHookResult = ReturnType<typeof usePermissionGroupNamesQuery>;
export type PermissionGroupNamesLazyQueryHookResult = ReturnType<typeof usePermissionGroupNamesLazyQuery>;
export type PermissionGroupNamesQueryResult = Apollo.QueryResult<
	PermissionGroupNamesQuery,
	PermissionGroupNamesQueryVariables
>;
export const PermissionGroupPaginationDocument = gql`
	query permissionGroupPagination($filters: [PermissionGroupFilterInput!], $order: PermissionGroupOrder) {
		permissionGroupConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __usePermissionGroupPaginationQuery__
 *
 * To run a query within a React component, call `usePermissionGroupPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionGroupPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionGroupPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function usePermissionGroupPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<PermissionGroupPaginationQuery, PermissionGroupPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<PermissionGroupPaginationQuery, PermissionGroupPaginationQueryVariables>(
		PermissionGroupPaginationDocument,
		options,
	);
}
export function usePermissionGroupPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<PermissionGroupPaginationQuery, PermissionGroupPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<PermissionGroupPaginationQuery, PermissionGroupPaginationQueryVariables>(
		PermissionGroupPaginationDocument,
		options,
	);
}
export type PermissionGroupPaginationQueryHookResult = ReturnType<typeof usePermissionGroupPaginationQuery>;
export type PermissionGroupPaginationLazyQueryHookResult = ReturnType<typeof usePermissionGroupPaginationLazyQuery>;
export type PermissionGroupPaginationQueryResult = Apollo.QueryResult<
	PermissionGroupPaginationQuery,
	PermissionGroupPaginationQueryVariables
>;
export const PermissionGroupSelectDocument = gql`
	query permissionGroupSelect($filters: [PermissionGroupFilterInput!], $order: PermissionGroupOrder) {
		permissionGroupConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...PermissionGroupName
				}
			}
		}
	}
	${PermissionGroupNameFragmentDoc}
`;

/**
 * __usePermissionGroupSelectQuery__
 *
 * To run a query within a React component, call `usePermissionGroupSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionGroupSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionGroupSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function usePermissionGroupSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<PermissionGroupSelectQuery, PermissionGroupSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<PermissionGroupSelectQuery, PermissionGroupSelectQueryVariables>(
		PermissionGroupSelectDocument,
		options,
	);
}
export function usePermissionGroupSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<PermissionGroupSelectQuery, PermissionGroupSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<PermissionGroupSelectQuery, PermissionGroupSelectQueryVariables>(
		PermissionGroupSelectDocument,
		options,
	);
}
export type PermissionGroupSelectQueryHookResult = ReturnType<typeof usePermissionGroupSelectQuery>;
export type PermissionGroupSelectLazyQueryHookResult = ReturnType<typeof usePermissionGroupSelectLazyQuery>;
export type PermissionGroupSelectQueryResult = Apollo.QueryResult<
	PermissionGroupSelectQuery,
	PermissionGroupSelectQueryVariables
>;
export const PermissionGroupUpdateDocument = gql`
	mutation permissionGroupUpdate($id: ID!, $input: PermissionGroupUpdateInput!) {
		updatePermissionGroup(id: $id, input: $input) {
			...PermissionGroupDetail
		}
	}
	${PermissionGroupDetailFragmentDoc}
`;
export type PermissionGroupUpdateMutationFn = Apollo.MutationFunction<
	PermissionGroupUpdateMutation,
	PermissionGroupUpdateMutationVariables
>;

/**
 * __usePermissionGroupUpdateMutation__
 *
 * To run a mutation, you first call `usePermissionGroupUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePermissionGroupUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [permissionGroupUpdateMutation, { data, loading, error }] = usePermissionGroupUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePermissionGroupUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<PermissionGroupUpdateMutation, PermissionGroupUpdateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<PermissionGroupUpdateMutation, PermissionGroupUpdateMutationVariables>(
		PermissionGroupUpdateDocument,
		options,
	);
}
export type PermissionGroupUpdateMutationHookResult = ReturnType<typeof usePermissionGroupUpdateMutation>;
export type PermissionGroupUpdateMutationResult = Apollo.MutationResult<PermissionGroupUpdateMutation>;
export type PermissionGroupUpdateMutationOptions = Apollo.BaseMutationOptions<
	PermissionGroupUpdateMutation,
	PermissionGroupUpdateMutationVariables
>;
export const BirthdaysDocument = gql`
	query birthdays($dates: [Time!]!, $filters: [PersonFilterInput!], $pagination: Pagination, $order: PersonOrder) {
		personConnectionByBirthday(dates: $dates, filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					name
					firstName
					lastName
					imageURL
					dateOfBirth
					student {
						id
					}
					employee {
						id
					}
					parent {
						id
					}
				}
			}
		}
	}
`;

/**
 * __useBirthdaysQuery__
 *
 * To run a query within a React component, call `useBirthdaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useBirthdaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBirthdaysQuery({
 *   variables: {
 *      dates: // value for 'dates'
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useBirthdaysQuery(baseOptions: Apollo.QueryHookOptions<BirthdaysQuery, BirthdaysQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<BirthdaysQuery, BirthdaysQueryVariables>(BirthdaysDocument, options);
}
export function useBirthdaysLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<BirthdaysQuery, BirthdaysQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<BirthdaysQuery, BirthdaysQueryVariables>(BirthdaysDocument, options);
}
export type BirthdaysQueryHookResult = ReturnType<typeof useBirthdaysQuery>;
export type BirthdaysLazyQueryHookResult = ReturnType<typeof useBirthdaysLazyQuery>;
export type BirthdaysQueryResult = Apollo.QueryResult<BirthdaysQuery, BirthdaysQueryVariables>;
export const PersonCountDocument = gql`
	query personCount($filters: [PersonFilterInput!], $pagination: Pagination, $order: PersonOrder) {
		personConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __usePersonCountQuery__
 *
 * To run a query within a React component, call `usePersonCountQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function usePersonCountQuery(
	baseOptions?: Apollo.QueryHookOptions<PersonCountQuery, PersonCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<PersonCountQuery, PersonCountQueryVariables>(PersonCountDocument, options);
}
export function usePersonCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<PersonCountQuery, PersonCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<PersonCountQuery, PersonCountQueryVariables>(PersonCountDocument, options);
}
export type PersonCountQueryHookResult = ReturnType<typeof usePersonCountQuery>;
export type PersonCountLazyQueryHookResult = ReturnType<typeof usePersonCountLazyQuery>;
export type PersonCountQueryResult = Apollo.QueryResult<PersonCountQuery, PersonCountQueryVariables>;
export const PersonCreateDocument = gql`
	mutation personCreate($input: PersonCreateInput!) {
		createPerson(input: $input) {
			...PersonDetail
		}
	}
	${PersonDetailFragmentDoc}
`;
export type PersonCreateMutationFn = Apollo.MutationFunction<PersonCreateMutation, PersonCreateMutationVariables>;

/**
 * __usePersonCreateMutation__
 *
 * To run a mutation, you first call `usePersonCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePersonCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [personCreateMutation, { data, loading, error }] = usePersonCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePersonCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<PersonCreateMutation, PersonCreateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<PersonCreateMutation, PersonCreateMutationVariables>(PersonCreateDocument, options);
}
export type PersonCreateMutationHookResult = ReturnType<typeof usePersonCreateMutation>;
export type PersonCreateMutationResult = Apollo.MutationResult<PersonCreateMutation>;
export type PersonCreateMutationOptions = Apollo.BaseMutationOptions<
	PersonCreateMutation,
	PersonCreateMutationVariables
>;
export const PersonDeleteDocument = gql`
	mutation personDelete($id: ID!) {
		deletePerson(id: $id)
	}
`;
export type PersonDeleteMutationFn = Apollo.MutationFunction<PersonDeleteMutation, PersonDeleteMutationVariables>;

/**
 * __usePersonDeleteMutation__
 *
 * To run a mutation, you first call `usePersonDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePersonDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [personDeleteMutation, { data, loading, error }] = usePersonDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePersonDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<PersonDeleteMutation, PersonDeleteMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<PersonDeleteMutation, PersonDeleteMutationVariables>(PersonDeleteDocument, options);
}
export type PersonDeleteMutationHookResult = ReturnType<typeof usePersonDeleteMutation>;
export type PersonDeleteMutationResult = Apollo.MutationResult<PersonDeleteMutation>;
export type PersonDeleteMutationOptions = Apollo.BaseMutationOptions<
	PersonDeleteMutation,
	PersonDeleteMutationVariables
>;
export const PersonDetailDocument = gql`
	query personDetail($id: ID!) {
		person(id: $id) {
			...PersonDetail
		}
	}
	${PersonDetailFragmentDoc}
`;

/**
 * __usePersonDetailQuery__
 *
 * To run a query within a React component, call `usePersonDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePersonDetailQuery(
	baseOptions: Apollo.QueryHookOptions<PersonDetailQuery, PersonDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<PersonDetailQuery, PersonDetailQueryVariables>(PersonDetailDocument, options);
}
export function usePersonDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<PersonDetailQuery, PersonDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<PersonDetailQuery, PersonDetailQueryVariables>(PersonDetailDocument, options);
}
export type PersonDetailQueryHookResult = ReturnType<typeof usePersonDetailQuery>;
export type PersonDetailLazyQueryHookResult = ReturnType<typeof usePersonDetailLazyQuery>;
export type PersonDetailQueryResult = Apollo.QueryResult<PersonDetailQuery, PersonDetailQueryVariables>;
export const PersonIndexDocument = gql`
	query personIndex($filters: [PersonFilterInput!], $pagination: Pagination, $order: PersonOrder) {
		personConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					name
					firstName
					lastName
					nickname
					gender
					dateOfBirth
					occupation
					nationality
					primaryLanguage
				}
			}
		}
	}
`;

/**
 * __usePersonIndexQuery__
 *
 * To run a query within a React component, call `usePersonIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function usePersonIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<PersonIndexQuery, PersonIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<PersonIndexQuery, PersonIndexQueryVariables>(PersonIndexDocument, options);
}
export function usePersonIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<PersonIndexQuery, PersonIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<PersonIndexQuery, PersonIndexQueryVariables>(PersonIndexDocument, options);
}
export type PersonIndexQueryHookResult = ReturnType<typeof usePersonIndexQuery>;
export type PersonIndexLazyQueryHookResult = ReturnType<typeof usePersonIndexLazyQuery>;
export type PersonIndexQueryResult = Apollo.QueryResult<PersonIndexQuery, PersonIndexQueryVariables>;
export const PersonNameDocument = gql`
	query personName($id: ID!) {
		person(id: $id) {
			id
			...PersonName
		}
	}
	${PersonNameFragmentDoc}
`;

/**
 * __usePersonNameQuery__
 *
 * To run a query within a React component, call `usePersonNameQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePersonNameQuery(baseOptions: Apollo.QueryHookOptions<PersonNameQuery, PersonNameQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<PersonNameQuery, PersonNameQueryVariables>(PersonNameDocument, options);
}
export function usePersonNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<PersonNameQuery, PersonNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<PersonNameQuery, PersonNameQueryVariables>(PersonNameDocument, options);
}
export type PersonNameQueryHookResult = ReturnType<typeof usePersonNameQuery>;
export type PersonNameLazyQueryHookResult = ReturnType<typeof usePersonNameLazyQuery>;
export type PersonNameQueryResult = Apollo.QueryResult<PersonNameQuery, PersonNameQueryVariables>;
export const PersonNamesDocument = gql`
	query personNames($filters: [PersonFilterInput!], $pagination: Pagination, $order: PersonOrder) {
		personConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...PersonName
				}
			}
		}
	}
	${PersonNameFragmentDoc}
`;

/**
 * __usePersonNamesQuery__
 *
 * To run a query within a React component, call `usePersonNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function usePersonNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<PersonNamesQuery, PersonNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<PersonNamesQuery, PersonNamesQueryVariables>(PersonNamesDocument, options);
}
export function usePersonNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<PersonNamesQuery, PersonNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<PersonNamesQuery, PersonNamesQueryVariables>(PersonNamesDocument, options);
}
export type PersonNamesQueryHookResult = ReturnType<typeof usePersonNamesQuery>;
export type PersonNamesLazyQueryHookResult = ReturnType<typeof usePersonNamesLazyQuery>;
export type PersonNamesQueryResult = Apollo.QueryResult<PersonNamesQuery, PersonNamesQueryVariables>;
export const PersonPaginationDocument = gql`
	query personPagination($filters: [PersonFilterInput!], $order: PersonOrder) {
		personConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __usePersonPaginationQuery__
 *
 * To run a query within a React component, call `usePersonPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function usePersonPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<PersonPaginationQuery, PersonPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<PersonPaginationQuery, PersonPaginationQueryVariables>(PersonPaginationDocument, options);
}
export function usePersonPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<PersonPaginationQuery, PersonPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<PersonPaginationQuery, PersonPaginationQueryVariables>(PersonPaginationDocument, options);
}
export type PersonPaginationQueryHookResult = ReturnType<typeof usePersonPaginationQuery>;
export type PersonPaginationLazyQueryHookResult = ReturnType<typeof usePersonPaginationLazyQuery>;
export type PersonPaginationQueryResult = Apollo.QueryResult<PersonPaginationQuery, PersonPaginationQueryVariables>;
export const PersonSelectDocument = gql`
	query personSelect($filters: [PersonFilterInput!], $order: PersonOrder) {
		personConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...PersonName
				}
			}
		}
	}
	${PersonNameFragmentDoc}
`;

/**
 * __usePersonSelectQuery__
 *
 * To run a query within a React component, call `usePersonSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function usePersonSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<PersonSelectQuery, PersonSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<PersonSelectQuery, PersonSelectQueryVariables>(PersonSelectDocument, options);
}
export function usePersonSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<PersonSelectQuery, PersonSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<PersonSelectQuery, PersonSelectQueryVariables>(PersonSelectDocument, options);
}
export type PersonSelectQueryHookResult = ReturnType<typeof usePersonSelectQuery>;
export type PersonSelectLazyQueryHookResult = ReturnType<typeof usePersonSelectLazyQuery>;
export type PersonSelectQueryResult = Apollo.QueryResult<PersonSelectQuery, PersonSelectQueryVariables>;
export const PersonUpdateDocument = gql`
	mutation personUpdate($id: ID!, $input: PersonUpdateInput!) {
		updatePerson(id: $id, input: $input) {
			...PersonDetail
		}
	}
	${PersonDetailFragmentDoc}
`;
export type PersonUpdateMutationFn = Apollo.MutationFunction<PersonUpdateMutation, PersonUpdateMutationVariables>;

/**
 * __usePersonUpdateMutation__
 *
 * To run a mutation, you first call `usePersonUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePersonUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [personUpdateMutation, { data, loading, error }] = usePersonUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePersonUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<PersonUpdateMutation, PersonUpdateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<PersonUpdateMutation, PersonUpdateMutationVariables>(PersonUpdateDocument, options);
}
export type PersonUpdateMutationHookResult = ReturnType<typeof usePersonUpdateMutation>;
export type PersonUpdateMutationResult = Apollo.MutationResult<PersonUpdateMutation>;
export type PersonUpdateMutationOptions = Apollo.BaseMutationOptions<
	PersonUpdateMutation,
	PersonUpdateMutationVariables
>;
export const PhoneNumberCountDocument = gql`
	query phoneNumberCount($filters: [PhoneNumberFilterInput!], $pagination: Pagination, $order: PhoneNumberOrder) {
		phoneNumberConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __usePhoneNumberCountQuery__
 *
 * To run a query within a React component, call `usePhoneNumberCountQuery` and pass it any options that fit your needs.
 * When your component renders, `usePhoneNumberCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhoneNumberCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function usePhoneNumberCountQuery(
	baseOptions?: Apollo.QueryHookOptions<PhoneNumberCountQuery, PhoneNumberCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<PhoneNumberCountQuery, PhoneNumberCountQueryVariables>(PhoneNumberCountDocument, options);
}
export function usePhoneNumberCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<PhoneNumberCountQuery, PhoneNumberCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<PhoneNumberCountQuery, PhoneNumberCountQueryVariables>(PhoneNumberCountDocument, options);
}
export type PhoneNumberCountQueryHookResult = ReturnType<typeof usePhoneNumberCountQuery>;
export type PhoneNumberCountLazyQueryHookResult = ReturnType<typeof usePhoneNumberCountLazyQuery>;
export type PhoneNumberCountQueryResult = Apollo.QueryResult<PhoneNumberCountQuery, PhoneNumberCountQueryVariables>;
export const PhoneNumberCreateDocument = gql`
	mutation phoneNumberCreate($input: PhoneNumberCreateInput!) {
		createPhoneNumber(input: $input) {
			...PhoneNumberDetail
		}
	}
	${PhoneNumberDetailFragmentDoc}
`;
export type PhoneNumberCreateMutationFn = Apollo.MutationFunction<
	PhoneNumberCreateMutation,
	PhoneNumberCreateMutationVariables
>;

/**
 * __usePhoneNumberCreateMutation__
 *
 * To run a mutation, you first call `usePhoneNumberCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePhoneNumberCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [phoneNumberCreateMutation, { data, loading, error }] = usePhoneNumberCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePhoneNumberCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<PhoneNumberCreateMutation, PhoneNumberCreateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<PhoneNumberCreateMutation, PhoneNumberCreateMutationVariables>(
		PhoneNumberCreateDocument,
		options,
	);
}
export type PhoneNumberCreateMutationHookResult = ReturnType<typeof usePhoneNumberCreateMutation>;
export type PhoneNumberCreateMutationResult = Apollo.MutationResult<PhoneNumberCreateMutation>;
export type PhoneNumberCreateMutationOptions = Apollo.BaseMutationOptions<
	PhoneNumberCreateMutation,
	PhoneNumberCreateMutationVariables
>;
export const PhoneNumberDeleteDocument = gql`
	mutation phoneNumberDelete($id: ID!) {
		deletePhoneNumber(id: $id)
	}
`;
export type PhoneNumberDeleteMutationFn = Apollo.MutationFunction<
	PhoneNumberDeleteMutation,
	PhoneNumberDeleteMutationVariables
>;

/**
 * __usePhoneNumberDeleteMutation__
 *
 * To run a mutation, you first call `usePhoneNumberDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePhoneNumberDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [phoneNumberDeleteMutation, { data, loading, error }] = usePhoneNumberDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePhoneNumberDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<PhoneNumberDeleteMutation, PhoneNumberDeleteMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<PhoneNumberDeleteMutation, PhoneNumberDeleteMutationVariables>(
		PhoneNumberDeleteDocument,
		options,
	);
}
export type PhoneNumberDeleteMutationHookResult = ReturnType<typeof usePhoneNumberDeleteMutation>;
export type PhoneNumberDeleteMutationResult = Apollo.MutationResult<PhoneNumberDeleteMutation>;
export type PhoneNumberDeleteMutationOptions = Apollo.BaseMutationOptions<
	PhoneNumberDeleteMutation,
	PhoneNumberDeleteMutationVariables
>;
export const PhoneNumberDetailDocument = gql`
	query phoneNumberDetail($id: ID!) {
		phoneNumber(id: $id) {
			...PhoneNumberDetail
		}
	}
	${PhoneNumberDetailFragmentDoc}
`;

/**
 * __usePhoneNumberDetailQuery__
 *
 * To run a query within a React component, call `usePhoneNumberDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `usePhoneNumberDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhoneNumberDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePhoneNumberDetailQuery(
	baseOptions: Apollo.QueryHookOptions<PhoneNumberDetailQuery, PhoneNumberDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<PhoneNumberDetailQuery, PhoneNumberDetailQueryVariables>(PhoneNumberDetailDocument, options);
}
export function usePhoneNumberDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<PhoneNumberDetailQuery, PhoneNumberDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<PhoneNumberDetailQuery, PhoneNumberDetailQueryVariables>(
		PhoneNumberDetailDocument,
		options,
	);
}
export type PhoneNumberDetailQueryHookResult = ReturnType<typeof usePhoneNumberDetailQuery>;
export type PhoneNumberDetailLazyQueryHookResult = ReturnType<typeof usePhoneNumberDetailLazyQuery>;
export type PhoneNumberDetailQueryResult = Apollo.QueryResult<PhoneNumberDetailQuery, PhoneNumberDetailQueryVariables>;
export const PhoneNumberIndexDocument = gql`
	query phoneNumberIndex($filters: [PhoneNumberFilterInput!], $pagination: Pagination, $order: PhoneNumberOrder) {
		phoneNumberConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					phoneNumber
					phoneType
				}
			}
		}
	}
`;

/**
 * __usePhoneNumberIndexQuery__
 *
 * To run a query within a React component, call `usePhoneNumberIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `usePhoneNumberIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhoneNumberIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function usePhoneNumberIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<PhoneNumberIndexQuery, PhoneNumberIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<PhoneNumberIndexQuery, PhoneNumberIndexQueryVariables>(PhoneNumberIndexDocument, options);
}
export function usePhoneNumberIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<PhoneNumberIndexQuery, PhoneNumberIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<PhoneNumberIndexQuery, PhoneNumberIndexQueryVariables>(PhoneNumberIndexDocument, options);
}
export type PhoneNumberIndexQueryHookResult = ReturnType<typeof usePhoneNumberIndexQuery>;
export type PhoneNumberIndexLazyQueryHookResult = ReturnType<typeof usePhoneNumberIndexLazyQuery>;
export type PhoneNumberIndexQueryResult = Apollo.QueryResult<PhoneNumberIndexQuery, PhoneNumberIndexQueryVariables>;
export const PhoneNumberNameDocument = gql`
	query phoneNumberName($id: ID!) {
		phoneNumber(id: $id) {
			id
			...PhoneNumberName
		}
	}
	${PhoneNumberNameFragmentDoc}
`;

/**
 * __usePhoneNumberNameQuery__
 *
 * To run a query within a React component, call `usePhoneNumberNameQuery` and pass it any options that fit your needs.
 * When your component renders, `usePhoneNumberNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhoneNumberNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePhoneNumberNameQuery(
	baseOptions: Apollo.QueryHookOptions<PhoneNumberNameQuery, PhoneNumberNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<PhoneNumberNameQuery, PhoneNumberNameQueryVariables>(PhoneNumberNameDocument, options);
}
export function usePhoneNumberNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<PhoneNumberNameQuery, PhoneNumberNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<PhoneNumberNameQuery, PhoneNumberNameQueryVariables>(PhoneNumberNameDocument, options);
}
export type PhoneNumberNameQueryHookResult = ReturnType<typeof usePhoneNumberNameQuery>;
export type PhoneNumberNameLazyQueryHookResult = ReturnType<typeof usePhoneNumberNameLazyQuery>;
export type PhoneNumberNameQueryResult = Apollo.QueryResult<PhoneNumberNameQuery, PhoneNumberNameQueryVariables>;
export const PhoneNumberNamesDocument = gql`
	query phoneNumberNames($filters: [PhoneNumberFilterInput!], $pagination: Pagination, $order: PhoneNumberOrder) {
		phoneNumberConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...PhoneNumberName
				}
			}
		}
	}
	${PhoneNumberNameFragmentDoc}
`;

/**
 * __usePhoneNumberNamesQuery__
 *
 * To run a query within a React component, call `usePhoneNumberNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePhoneNumberNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhoneNumberNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function usePhoneNumberNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<PhoneNumberNamesQuery, PhoneNumberNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<PhoneNumberNamesQuery, PhoneNumberNamesQueryVariables>(PhoneNumberNamesDocument, options);
}
export function usePhoneNumberNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<PhoneNumberNamesQuery, PhoneNumberNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<PhoneNumberNamesQuery, PhoneNumberNamesQueryVariables>(PhoneNumberNamesDocument, options);
}
export type PhoneNumberNamesQueryHookResult = ReturnType<typeof usePhoneNumberNamesQuery>;
export type PhoneNumberNamesLazyQueryHookResult = ReturnType<typeof usePhoneNumberNamesLazyQuery>;
export type PhoneNumberNamesQueryResult = Apollo.QueryResult<PhoneNumberNamesQuery, PhoneNumberNamesQueryVariables>;
export const PhoneNumberPaginationDocument = gql`
	query phoneNumberPagination($filters: [PhoneNumberFilterInput!], $order: PhoneNumberOrder) {
		phoneNumberConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __usePhoneNumberPaginationQuery__
 *
 * To run a query within a React component, call `usePhoneNumberPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `usePhoneNumberPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhoneNumberPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function usePhoneNumberPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<PhoneNumberPaginationQuery, PhoneNumberPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<PhoneNumberPaginationQuery, PhoneNumberPaginationQueryVariables>(
		PhoneNumberPaginationDocument,
		options,
	);
}
export function usePhoneNumberPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<PhoneNumberPaginationQuery, PhoneNumberPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<PhoneNumberPaginationQuery, PhoneNumberPaginationQueryVariables>(
		PhoneNumberPaginationDocument,
		options,
	);
}
export type PhoneNumberPaginationQueryHookResult = ReturnType<typeof usePhoneNumberPaginationQuery>;
export type PhoneNumberPaginationLazyQueryHookResult = ReturnType<typeof usePhoneNumberPaginationLazyQuery>;
export type PhoneNumberPaginationQueryResult = Apollo.QueryResult<
	PhoneNumberPaginationQuery,
	PhoneNumberPaginationQueryVariables
>;
export const PhoneNumberSelectDocument = gql`
	query phoneNumberSelect($filters: [PhoneNumberFilterInput!], $order: PhoneNumberOrder) {
		phoneNumberConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...PhoneNumberName
				}
			}
		}
	}
	${PhoneNumberNameFragmentDoc}
`;

/**
 * __usePhoneNumberSelectQuery__
 *
 * To run a query within a React component, call `usePhoneNumberSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `usePhoneNumberSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhoneNumberSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function usePhoneNumberSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<PhoneNumberSelectQuery, PhoneNumberSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<PhoneNumberSelectQuery, PhoneNumberSelectQueryVariables>(PhoneNumberSelectDocument, options);
}
export function usePhoneNumberSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<PhoneNumberSelectQuery, PhoneNumberSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<PhoneNumberSelectQuery, PhoneNumberSelectQueryVariables>(
		PhoneNumberSelectDocument,
		options,
	);
}
export type PhoneNumberSelectQueryHookResult = ReturnType<typeof usePhoneNumberSelectQuery>;
export type PhoneNumberSelectLazyQueryHookResult = ReturnType<typeof usePhoneNumberSelectLazyQuery>;
export type PhoneNumberSelectQueryResult = Apollo.QueryResult<PhoneNumberSelectQuery, PhoneNumberSelectQueryVariables>;
export const PhoneNumberUpdateDocument = gql`
	mutation phoneNumberUpdate($id: ID!, $input: PhoneNumberUpdateInput!) {
		updatePhoneNumber(id: $id, input: $input) {
			...PhoneNumberDetail
		}
	}
	${PhoneNumberDetailFragmentDoc}
`;
export type PhoneNumberUpdateMutationFn = Apollo.MutationFunction<
	PhoneNumberUpdateMutation,
	PhoneNumberUpdateMutationVariables
>;

/**
 * __usePhoneNumberUpdateMutation__
 *
 * To run a mutation, you first call `usePhoneNumberUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePhoneNumberUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [phoneNumberUpdateMutation, { data, loading, error }] = usePhoneNumberUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePhoneNumberUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<PhoneNumberUpdateMutation, PhoneNumberUpdateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<PhoneNumberUpdateMutation, PhoneNumberUpdateMutationVariables>(
		PhoneNumberUpdateDocument,
		options,
	);
}
export type PhoneNumberUpdateMutationHookResult = ReturnType<typeof usePhoneNumberUpdateMutation>;
export type PhoneNumberUpdateMutationResult = Apollo.MutationResult<PhoneNumberUpdateMutation>;
export type PhoneNumberUpdateMutationOptions = Apollo.BaseMutationOptions<
	PhoneNumberUpdateMutation,
	PhoneNumberUpdateMutationVariables
>;
export const QuotationCountDocument = gql`
	query quotationCount($filters: [QuotationFilterInput!], $pagination: Pagination, $order: QuotationOrder) {
		quotationConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useQuotationCountQuery__
 *
 * To run a query within a React component, call `useQuotationCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuotationCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuotationCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useQuotationCountQuery(
	baseOptions?: Apollo.QueryHookOptions<QuotationCountQuery, QuotationCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<QuotationCountQuery, QuotationCountQueryVariables>(QuotationCountDocument, options);
}
export function useQuotationCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<QuotationCountQuery, QuotationCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<QuotationCountQuery, QuotationCountQueryVariables>(QuotationCountDocument, options);
}
export type QuotationCountQueryHookResult = ReturnType<typeof useQuotationCountQuery>;
export type QuotationCountLazyQueryHookResult = ReturnType<typeof useQuotationCountLazyQuery>;
export type QuotationCountQueryResult = Apollo.QueryResult<QuotationCountQuery, QuotationCountQueryVariables>;
export const QuotationCreateDocument = gql`
	mutation quotationCreate($input: QuotationCreateInput!) {
		createQuotation(input: $input) {
			...QuotationDetail
		}
	}
	${QuotationDetailFragmentDoc}
`;
export type QuotationCreateMutationFn = Apollo.MutationFunction<
	QuotationCreateMutation,
	QuotationCreateMutationVariables
>;

/**
 * __useQuotationCreateMutation__
 *
 * To run a mutation, you first call `useQuotationCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQuotationCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [quotationCreateMutation, { data, loading, error }] = useQuotationCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQuotationCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<QuotationCreateMutation, QuotationCreateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<QuotationCreateMutation, QuotationCreateMutationVariables>(
		QuotationCreateDocument,
		options,
	);
}
export type QuotationCreateMutationHookResult = ReturnType<typeof useQuotationCreateMutation>;
export type QuotationCreateMutationResult = Apollo.MutationResult<QuotationCreateMutation>;
export type QuotationCreateMutationOptions = Apollo.BaseMutationOptions<
	QuotationCreateMutation,
	QuotationCreateMutationVariables
>;
export const DailyQuotationDocument = gql`
	query dailyQuotation($date: Time!) {
		dailyQuotation(date: $date) {
			...QuotationDetail
		}
	}
	${QuotationDetailFragmentDoc}
`;

/**
 * __useDailyQuotationQuery__
 *
 * To run a query within a React component, call `useDailyQuotationQuery` and pass it any options that fit your needs.
 * When your component renders, `useDailyQuotationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDailyQuotationQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useDailyQuotationQuery(
	baseOptions: Apollo.QueryHookOptions<DailyQuotationQuery, DailyQuotationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<DailyQuotationQuery, DailyQuotationQueryVariables>(DailyQuotationDocument, options);
}
export function useDailyQuotationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<DailyQuotationQuery, DailyQuotationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<DailyQuotationQuery, DailyQuotationQueryVariables>(DailyQuotationDocument, options);
}
export type DailyQuotationQueryHookResult = ReturnType<typeof useDailyQuotationQuery>;
export type DailyQuotationLazyQueryHookResult = ReturnType<typeof useDailyQuotationLazyQuery>;
export type DailyQuotationQueryResult = Apollo.QueryResult<DailyQuotationQuery, DailyQuotationQueryVariables>;
export const QuotationDeleteDocument = gql`
	mutation quotationDelete($id: ID!) {
		deleteQuotation(id: $id)
	}
`;
export type QuotationDeleteMutationFn = Apollo.MutationFunction<
	QuotationDeleteMutation,
	QuotationDeleteMutationVariables
>;

/**
 * __useQuotationDeleteMutation__
 *
 * To run a mutation, you first call `useQuotationDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQuotationDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [quotationDeleteMutation, { data, loading, error }] = useQuotationDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useQuotationDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<QuotationDeleteMutation, QuotationDeleteMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<QuotationDeleteMutation, QuotationDeleteMutationVariables>(
		QuotationDeleteDocument,
		options,
	);
}
export type QuotationDeleteMutationHookResult = ReturnType<typeof useQuotationDeleteMutation>;
export type QuotationDeleteMutationResult = Apollo.MutationResult<QuotationDeleteMutation>;
export type QuotationDeleteMutationOptions = Apollo.BaseMutationOptions<
	QuotationDeleteMutation,
	QuotationDeleteMutationVariables
>;
export const QuotationDetailDocument = gql`
	query quotationDetail($id: ID!) {
		quotation(id: $id) {
			...QuotationDetail
		}
	}
	${QuotationDetailFragmentDoc}
`;

/**
 * __useQuotationDetailQuery__
 *
 * To run a query within a React component, call `useQuotationDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuotationDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuotationDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useQuotationDetailQuery(
	baseOptions: Apollo.QueryHookOptions<QuotationDetailQuery, QuotationDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<QuotationDetailQuery, QuotationDetailQueryVariables>(QuotationDetailDocument, options);
}
export function useQuotationDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<QuotationDetailQuery, QuotationDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<QuotationDetailQuery, QuotationDetailQueryVariables>(QuotationDetailDocument, options);
}
export type QuotationDetailQueryHookResult = ReturnType<typeof useQuotationDetailQuery>;
export type QuotationDetailLazyQueryHookResult = ReturnType<typeof useQuotationDetailLazyQuery>;
export type QuotationDetailQueryResult = Apollo.QueryResult<QuotationDetailQuery, QuotationDetailQueryVariables>;
export const QuotationIndexDocument = gql`
	query quotationIndex($filters: [QuotationFilterInput!], $pagination: Pagination, $order: QuotationOrder) {
		quotationConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					body
					attribution
				}
			}
		}
	}
`;

/**
 * __useQuotationIndexQuery__
 *
 * To run a query within a React component, call `useQuotationIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuotationIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuotationIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useQuotationIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<QuotationIndexQuery, QuotationIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<QuotationIndexQuery, QuotationIndexQueryVariables>(QuotationIndexDocument, options);
}
export function useQuotationIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<QuotationIndexQuery, QuotationIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<QuotationIndexQuery, QuotationIndexQueryVariables>(QuotationIndexDocument, options);
}
export type QuotationIndexQueryHookResult = ReturnType<typeof useQuotationIndexQuery>;
export type QuotationIndexLazyQueryHookResult = ReturnType<typeof useQuotationIndexLazyQuery>;
export type QuotationIndexQueryResult = Apollo.QueryResult<QuotationIndexQuery, QuotationIndexQueryVariables>;
export const QuotationNameDocument = gql`
	query quotationName($id: ID!) {
		quotation(id: $id) {
			id
			...QuotationName
		}
	}
	${QuotationNameFragmentDoc}
`;

/**
 * __useQuotationNameQuery__
 *
 * To run a query within a React component, call `useQuotationNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuotationNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuotationNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useQuotationNameQuery(
	baseOptions: Apollo.QueryHookOptions<QuotationNameQuery, QuotationNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<QuotationNameQuery, QuotationNameQueryVariables>(QuotationNameDocument, options);
}
export function useQuotationNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<QuotationNameQuery, QuotationNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<QuotationNameQuery, QuotationNameQueryVariables>(QuotationNameDocument, options);
}
export type QuotationNameQueryHookResult = ReturnType<typeof useQuotationNameQuery>;
export type QuotationNameLazyQueryHookResult = ReturnType<typeof useQuotationNameLazyQuery>;
export type QuotationNameQueryResult = Apollo.QueryResult<QuotationNameQuery, QuotationNameQueryVariables>;
export const QuotationNamesDocument = gql`
	query quotationNames($filters: [QuotationFilterInput!], $pagination: Pagination, $order: QuotationOrder) {
		quotationConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...QuotationName
				}
			}
		}
	}
	${QuotationNameFragmentDoc}
`;

/**
 * __useQuotationNamesQuery__
 *
 * To run a query within a React component, call `useQuotationNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuotationNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuotationNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useQuotationNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<QuotationNamesQuery, QuotationNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<QuotationNamesQuery, QuotationNamesQueryVariables>(QuotationNamesDocument, options);
}
export function useQuotationNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<QuotationNamesQuery, QuotationNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<QuotationNamesQuery, QuotationNamesQueryVariables>(QuotationNamesDocument, options);
}
export type QuotationNamesQueryHookResult = ReturnType<typeof useQuotationNamesQuery>;
export type QuotationNamesLazyQueryHookResult = ReturnType<typeof useQuotationNamesLazyQuery>;
export type QuotationNamesQueryResult = Apollo.QueryResult<QuotationNamesQuery, QuotationNamesQueryVariables>;
export const QuotationPaginationDocument = gql`
	query quotationPagination($filters: [QuotationFilterInput!], $order: QuotationOrder) {
		quotationConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useQuotationPaginationQuery__
 *
 * To run a query within a React component, call `useQuotationPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuotationPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuotationPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useQuotationPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<QuotationPaginationQuery, QuotationPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<QuotationPaginationQuery, QuotationPaginationQueryVariables>(
		QuotationPaginationDocument,
		options,
	);
}
export function useQuotationPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<QuotationPaginationQuery, QuotationPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<QuotationPaginationQuery, QuotationPaginationQueryVariables>(
		QuotationPaginationDocument,
		options,
	);
}
export type QuotationPaginationQueryHookResult = ReturnType<typeof useQuotationPaginationQuery>;
export type QuotationPaginationLazyQueryHookResult = ReturnType<typeof useQuotationPaginationLazyQuery>;
export type QuotationPaginationQueryResult = Apollo.QueryResult<
	QuotationPaginationQuery,
	QuotationPaginationQueryVariables
>;
export const QuotationSelectDocument = gql`
	query quotationSelect($filters: [QuotationFilterInput!], $order: QuotationOrder) {
		quotationConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...QuotationName
				}
			}
		}
	}
	${QuotationNameFragmentDoc}
`;

/**
 * __useQuotationSelectQuery__
 *
 * To run a query within a React component, call `useQuotationSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuotationSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuotationSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useQuotationSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<QuotationSelectQuery, QuotationSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<QuotationSelectQuery, QuotationSelectQueryVariables>(QuotationSelectDocument, options);
}
export function useQuotationSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<QuotationSelectQuery, QuotationSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<QuotationSelectQuery, QuotationSelectQueryVariables>(QuotationSelectDocument, options);
}
export type QuotationSelectQueryHookResult = ReturnType<typeof useQuotationSelectQuery>;
export type QuotationSelectLazyQueryHookResult = ReturnType<typeof useQuotationSelectLazyQuery>;
export type QuotationSelectQueryResult = Apollo.QueryResult<QuotationSelectQuery, QuotationSelectQueryVariables>;
export const QuotationUpdateDocument = gql`
	mutation quotationUpdate($id: ID!, $input: QuotationUpdateInput!) {
		updateQuotation(id: $id, input: $input) {
			...QuotationDetail
		}
	}
	${QuotationDetailFragmentDoc}
`;
export type QuotationUpdateMutationFn = Apollo.MutationFunction<
	QuotationUpdateMutation,
	QuotationUpdateMutationVariables
>;

/**
 * __useQuotationUpdateMutation__
 *
 * To run a mutation, you first call `useQuotationUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQuotationUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [quotationUpdateMutation, { data, loading, error }] = useQuotationUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQuotationUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<QuotationUpdateMutation, QuotationUpdateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<QuotationUpdateMutation, QuotationUpdateMutationVariables>(
		QuotationUpdateDocument,
		options,
	);
}
export type QuotationUpdateMutationHookResult = ReturnType<typeof useQuotationUpdateMutation>;
export type QuotationUpdateMutationResult = Apollo.MutationResult<QuotationUpdateMutation>;
export type QuotationUpdateMutationOptions = Apollo.BaseMutationOptions<
	QuotationUpdateMutation,
	QuotationUpdateMutationVariables
>;
export const RelationshipCountDocument = gql`
	query relationshipCount($filters: [RelationshipFilterInput!], $pagination: Pagination, $order: RelationshipOrder) {
		relationshipConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useRelationshipCountQuery__
 *
 * To run a query within a React component, call `useRelationshipCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useRelationshipCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRelationshipCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useRelationshipCountQuery(
	baseOptions?: Apollo.QueryHookOptions<RelationshipCountQuery, RelationshipCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<RelationshipCountQuery, RelationshipCountQueryVariables>(RelationshipCountDocument, options);
}
export function useRelationshipCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<RelationshipCountQuery, RelationshipCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<RelationshipCountQuery, RelationshipCountQueryVariables>(
		RelationshipCountDocument,
		options,
	);
}
export type RelationshipCountQueryHookResult = ReturnType<typeof useRelationshipCountQuery>;
export type RelationshipCountLazyQueryHookResult = ReturnType<typeof useRelationshipCountLazyQuery>;
export type RelationshipCountQueryResult = Apollo.QueryResult<RelationshipCountQuery, RelationshipCountQueryVariables>;
export const RelationshipCreateDocument = gql`
	mutation relationshipCreate($input: RelationshipCreateInput!) {
		createRelationship(input: $input) {
			...RelationshipDetail
		}
	}
	${RelationshipDetailFragmentDoc}
`;
export type RelationshipCreateMutationFn = Apollo.MutationFunction<
	RelationshipCreateMutation,
	RelationshipCreateMutationVariables
>;

/**
 * __useRelationshipCreateMutation__
 *
 * To run a mutation, you first call `useRelationshipCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRelationshipCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [relationshipCreateMutation, { data, loading, error }] = useRelationshipCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRelationshipCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<RelationshipCreateMutation, RelationshipCreateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<RelationshipCreateMutation, RelationshipCreateMutationVariables>(
		RelationshipCreateDocument,
		options,
	);
}
export type RelationshipCreateMutationHookResult = ReturnType<typeof useRelationshipCreateMutation>;
export type RelationshipCreateMutationResult = Apollo.MutationResult<RelationshipCreateMutation>;
export type RelationshipCreateMutationOptions = Apollo.BaseMutationOptions<
	RelationshipCreateMutation,
	RelationshipCreateMutationVariables
>;
export const RelationshipDeleteDocument = gql`
	mutation relationshipDelete($id: ID!) {
		deleteRelationship(id: $id)
	}
`;
export type RelationshipDeleteMutationFn = Apollo.MutationFunction<
	RelationshipDeleteMutation,
	RelationshipDeleteMutationVariables
>;

/**
 * __useRelationshipDeleteMutation__
 *
 * To run a mutation, you first call `useRelationshipDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRelationshipDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [relationshipDeleteMutation, { data, loading, error }] = useRelationshipDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRelationshipDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<RelationshipDeleteMutation, RelationshipDeleteMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<RelationshipDeleteMutation, RelationshipDeleteMutationVariables>(
		RelationshipDeleteDocument,
		options,
	);
}
export type RelationshipDeleteMutationHookResult = ReturnType<typeof useRelationshipDeleteMutation>;
export type RelationshipDeleteMutationResult = Apollo.MutationResult<RelationshipDeleteMutation>;
export type RelationshipDeleteMutationOptions = Apollo.BaseMutationOptions<
	RelationshipDeleteMutation,
	RelationshipDeleteMutationVariables
>;
export const RelationshipDetailDocument = gql`
	query relationshipDetail($id: ID!) {
		relationship(id: $id) {
			...RelationshipDetail
		}
	}
	${RelationshipDetailFragmentDoc}
`;

/**
 * __useRelationshipDetailQuery__
 *
 * To run a query within a React component, call `useRelationshipDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useRelationshipDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRelationshipDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRelationshipDetailQuery(
	baseOptions: Apollo.QueryHookOptions<RelationshipDetailQuery, RelationshipDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<RelationshipDetailQuery, RelationshipDetailQueryVariables>(
		RelationshipDetailDocument,
		options,
	);
}
export function useRelationshipDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<RelationshipDetailQuery, RelationshipDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<RelationshipDetailQuery, RelationshipDetailQueryVariables>(
		RelationshipDetailDocument,
		options,
	);
}
export type RelationshipDetailQueryHookResult = ReturnType<typeof useRelationshipDetailQuery>;
export type RelationshipDetailLazyQueryHookResult = ReturnType<typeof useRelationshipDetailLazyQuery>;
export type RelationshipDetailQueryResult = Apollo.QueryResult<
	RelationshipDetailQuery,
	RelationshipDetailQueryVariables
>;
export const RelationshipIndexDocument = gql`
	query relationshipIndex($filters: [RelationshipFilterInput!], $pagination: Pagination, $order: RelationshipOrder) {
		relationshipConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					relationshipType
					isAuthorizedForPickup
					isEmergencyContact
					isFinancialContact
					isPrimary
					livesWithStudent
				}
			}
		}
	}
`;

/**
 * __useRelationshipIndexQuery__
 *
 * To run a query within a React component, call `useRelationshipIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useRelationshipIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRelationshipIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useRelationshipIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<RelationshipIndexQuery, RelationshipIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<RelationshipIndexQuery, RelationshipIndexQueryVariables>(RelationshipIndexDocument, options);
}
export function useRelationshipIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<RelationshipIndexQuery, RelationshipIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<RelationshipIndexQuery, RelationshipIndexQueryVariables>(
		RelationshipIndexDocument,
		options,
	);
}
export type RelationshipIndexQueryHookResult = ReturnType<typeof useRelationshipIndexQuery>;
export type RelationshipIndexLazyQueryHookResult = ReturnType<typeof useRelationshipIndexLazyQuery>;
export type RelationshipIndexQueryResult = Apollo.QueryResult<RelationshipIndexQuery, RelationshipIndexQueryVariables>;
export const RelationshipNameDocument = gql`
	query relationshipName($id: ID!) {
		relationship(id: $id) {
			id
			...RelationshipName
		}
	}
	${RelationshipNameFragmentDoc}
`;

/**
 * __useRelationshipNameQuery__
 *
 * To run a query within a React component, call `useRelationshipNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useRelationshipNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRelationshipNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRelationshipNameQuery(
	baseOptions: Apollo.QueryHookOptions<RelationshipNameQuery, RelationshipNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<RelationshipNameQuery, RelationshipNameQueryVariables>(RelationshipNameDocument, options);
}
export function useRelationshipNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<RelationshipNameQuery, RelationshipNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<RelationshipNameQuery, RelationshipNameQueryVariables>(RelationshipNameDocument, options);
}
export type RelationshipNameQueryHookResult = ReturnType<typeof useRelationshipNameQuery>;
export type RelationshipNameLazyQueryHookResult = ReturnType<typeof useRelationshipNameLazyQuery>;
export type RelationshipNameQueryResult = Apollo.QueryResult<RelationshipNameQuery, RelationshipNameQueryVariables>;
export const RelationshipNamesDocument = gql`
	query relationshipNames($filters: [RelationshipFilterInput!], $pagination: Pagination, $order: RelationshipOrder) {
		relationshipConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...RelationshipName
				}
			}
		}
	}
	${RelationshipNameFragmentDoc}
`;

/**
 * __useRelationshipNamesQuery__
 *
 * To run a query within a React component, call `useRelationshipNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRelationshipNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRelationshipNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useRelationshipNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<RelationshipNamesQuery, RelationshipNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<RelationshipNamesQuery, RelationshipNamesQueryVariables>(RelationshipNamesDocument, options);
}
export function useRelationshipNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<RelationshipNamesQuery, RelationshipNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<RelationshipNamesQuery, RelationshipNamesQueryVariables>(
		RelationshipNamesDocument,
		options,
	);
}
export type RelationshipNamesQueryHookResult = ReturnType<typeof useRelationshipNamesQuery>;
export type RelationshipNamesLazyQueryHookResult = ReturnType<typeof useRelationshipNamesLazyQuery>;
export type RelationshipNamesQueryResult = Apollo.QueryResult<RelationshipNamesQuery, RelationshipNamesQueryVariables>;
export const RelationshipPaginationDocument = gql`
	query relationshipPagination($filters: [RelationshipFilterInput!], $order: RelationshipOrder) {
		relationshipConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useRelationshipPaginationQuery__
 *
 * To run a query within a React component, call `useRelationshipPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useRelationshipPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRelationshipPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useRelationshipPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<RelationshipPaginationQuery, RelationshipPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<RelationshipPaginationQuery, RelationshipPaginationQueryVariables>(
		RelationshipPaginationDocument,
		options,
	);
}
export function useRelationshipPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<RelationshipPaginationQuery, RelationshipPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<RelationshipPaginationQuery, RelationshipPaginationQueryVariables>(
		RelationshipPaginationDocument,
		options,
	);
}
export type RelationshipPaginationQueryHookResult = ReturnType<typeof useRelationshipPaginationQuery>;
export type RelationshipPaginationLazyQueryHookResult = ReturnType<typeof useRelationshipPaginationLazyQuery>;
export type RelationshipPaginationQueryResult = Apollo.QueryResult<
	RelationshipPaginationQuery,
	RelationshipPaginationQueryVariables
>;
export const RelationshipSelectDocument = gql`
	query relationshipSelect($filters: [RelationshipFilterInput!], $order: RelationshipOrder) {
		relationshipConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...RelationshipName
				}
			}
		}
	}
	${RelationshipNameFragmentDoc}
`;

/**
 * __useRelationshipSelectQuery__
 *
 * To run a query within a React component, call `useRelationshipSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useRelationshipSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRelationshipSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useRelationshipSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<RelationshipSelectQuery, RelationshipSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<RelationshipSelectQuery, RelationshipSelectQueryVariables>(
		RelationshipSelectDocument,
		options,
	);
}
export function useRelationshipSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<RelationshipSelectQuery, RelationshipSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<RelationshipSelectQuery, RelationshipSelectQueryVariables>(
		RelationshipSelectDocument,
		options,
	);
}
export type RelationshipSelectQueryHookResult = ReturnType<typeof useRelationshipSelectQuery>;
export type RelationshipSelectLazyQueryHookResult = ReturnType<typeof useRelationshipSelectLazyQuery>;
export type RelationshipSelectQueryResult = Apollo.QueryResult<
	RelationshipSelectQuery,
	RelationshipSelectQueryVariables
>;
export const StudentParentListDocument = gql`
	query studentParentList($filters: [RelationshipFilterInput!], $pagination: Pagination, $order: RelationshipOrder) {
		relationshipConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					relationshipType
					isAuthorizedForPickup
					isEmergencyContact
					isFinancialContact
					isPrimary
					livesWithStudent
					parent {
						id
						person {
							id
							name
							imageURL
							emailAddresses {
								id
								email
							}
							phoneNumbers {
								id
								phoneType
								phoneNumber
							}
						}
					}
				}
			}
		}
	}
`;

/**
 * __useStudentParentListQuery__
 *
 * To run a query within a React component, call `useStudentParentListQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentParentListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentParentListQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useStudentParentListQuery(
	baseOptions?: Apollo.QueryHookOptions<StudentParentListQuery, StudentParentListQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<StudentParentListQuery, StudentParentListQueryVariables>(StudentParentListDocument, options);
}
export function useStudentParentListLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<StudentParentListQuery, StudentParentListQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<StudentParentListQuery, StudentParentListQueryVariables>(
		StudentParentListDocument,
		options,
	);
}
export type StudentParentListQueryHookResult = ReturnType<typeof useStudentParentListQuery>;
export type StudentParentListLazyQueryHookResult = ReturnType<typeof useStudentParentListLazyQuery>;
export type StudentParentListQueryResult = Apollo.QueryResult<StudentParentListQuery, StudentParentListQueryVariables>;
export const RelationshipUpdateDocument = gql`
	mutation relationshipUpdate($id: ID!, $input: RelationshipUpdateInput!) {
		updateRelationship(id: $id, input: $input) {
			...RelationshipDetail
		}
	}
	${RelationshipDetailFragmentDoc}
`;
export type RelationshipUpdateMutationFn = Apollo.MutationFunction<
	RelationshipUpdateMutation,
	RelationshipUpdateMutationVariables
>;

/**
 * __useRelationshipUpdateMutation__
 *
 * To run a mutation, you first call `useRelationshipUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRelationshipUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [relationshipUpdateMutation, { data, loading, error }] = useRelationshipUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRelationshipUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<RelationshipUpdateMutation, RelationshipUpdateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<RelationshipUpdateMutation, RelationshipUpdateMutationVariables>(
		RelationshipUpdateDocument,
		options,
	);
}
export type RelationshipUpdateMutationHookResult = ReturnType<typeof useRelationshipUpdateMutation>;
export type RelationshipUpdateMutationResult = Apollo.MutationResult<RelationshipUpdateMutation>;
export type RelationshipUpdateMutationOptions = Apollo.BaseMutationOptions<
	RelationshipUpdateMutation,
	RelationshipUpdateMutationVariables
>;
export const ReportCardCountDocument = gql`
	query reportCardCount($filters: [ReportCardFilterInput!], $pagination: Pagination, $order: ReportCardOrder) {
		reportCardConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useReportCardCountQuery__
 *
 * To run a query within a React component, call `useReportCardCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportCardCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportCardCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useReportCardCountQuery(
	baseOptions?: Apollo.QueryHookOptions<ReportCardCountQuery, ReportCardCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ReportCardCountQuery, ReportCardCountQueryVariables>(ReportCardCountDocument, options);
}
export function useReportCardCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ReportCardCountQuery, ReportCardCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ReportCardCountQuery, ReportCardCountQueryVariables>(ReportCardCountDocument, options);
}
export type ReportCardCountQueryHookResult = ReturnType<typeof useReportCardCountQuery>;
export type ReportCardCountLazyQueryHookResult = ReturnType<typeof useReportCardCountLazyQuery>;
export type ReportCardCountQueryResult = Apollo.QueryResult<ReportCardCountQuery, ReportCardCountQueryVariables>;
export const ReportCardCreateDocument = gql`
	mutation reportCardCreate($input: ReportCardCreateInput!) {
		createReportCard(input: $input) {
			...ReportCardDetail
		}
	}
	${ReportCardDetailFragmentDoc}
`;
export type ReportCardCreateMutationFn = Apollo.MutationFunction<
	ReportCardCreateMutation,
	ReportCardCreateMutationVariables
>;

/**
 * __useReportCardCreateMutation__
 *
 * To run a mutation, you first call `useReportCardCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportCardCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportCardCreateMutation, { data, loading, error }] = useReportCardCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportCardCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<ReportCardCreateMutation, ReportCardCreateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<ReportCardCreateMutation, ReportCardCreateMutationVariables>(
		ReportCardCreateDocument,
		options,
	);
}
export type ReportCardCreateMutationHookResult = ReturnType<typeof useReportCardCreateMutation>;
export type ReportCardCreateMutationResult = Apollo.MutationResult<ReportCardCreateMutation>;
export type ReportCardCreateMutationOptions = Apollo.BaseMutationOptions<
	ReportCardCreateMutation,
	ReportCardCreateMutationVariables
>;
export const ReportCardDeleteDocument = gql`
	mutation reportCardDelete($id: ID!) {
		deleteReportCard(id: $id)
	}
`;
export type ReportCardDeleteMutationFn = Apollo.MutationFunction<
	ReportCardDeleteMutation,
	ReportCardDeleteMutationVariables
>;

/**
 * __useReportCardDeleteMutation__
 *
 * To run a mutation, you first call `useReportCardDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportCardDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportCardDeleteMutation, { data, loading, error }] = useReportCardDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReportCardDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<ReportCardDeleteMutation, ReportCardDeleteMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<ReportCardDeleteMutation, ReportCardDeleteMutationVariables>(
		ReportCardDeleteDocument,
		options,
	);
}
export type ReportCardDeleteMutationHookResult = ReturnType<typeof useReportCardDeleteMutation>;
export type ReportCardDeleteMutationResult = Apollo.MutationResult<ReportCardDeleteMutation>;
export type ReportCardDeleteMutationOptions = Apollo.BaseMutationOptions<
	ReportCardDeleteMutation,
	ReportCardDeleteMutationVariables
>;
export const ReportCardDetailDocument = gql`
	query reportCardDetail($id: ID!) {
		reportCard(id: $id) {
			...ReportCardDetail
		}
	}
	${ReportCardDetailFragmentDoc}
`;

/**
 * __useReportCardDetailQuery__
 *
 * To run a query within a React component, call `useReportCardDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportCardDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportCardDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReportCardDetailQuery(
	baseOptions: Apollo.QueryHookOptions<ReportCardDetailQuery, ReportCardDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ReportCardDetailQuery, ReportCardDetailQueryVariables>(ReportCardDetailDocument, options);
}
export function useReportCardDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ReportCardDetailQuery, ReportCardDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ReportCardDetailQuery, ReportCardDetailQueryVariables>(ReportCardDetailDocument, options);
}
export type ReportCardDetailQueryHookResult = ReturnType<typeof useReportCardDetailQuery>;
export type ReportCardDetailLazyQueryHookResult = ReturnType<typeof useReportCardDetailLazyQuery>;
export type ReportCardDetailQueryResult = Apollo.QueryResult<ReportCardDetailQuery, ReportCardDetailQueryVariables>;
export const ReportCardIndexDocument = gql`
	query reportCardIndex($filters: [ReportCardFilterInput!], $pagination: Pagination, $order: ReportCardOrder) {
		reportCardConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					issueDate
				}
			}
		}
	}
`;

/**
 * __useReportCardIndexQuery__
 *
 * To run a query within a React component, call `useReportCardIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportCardIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportCardIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useReportCardIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<ReportCardIndexQuery, ReportCardIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ReportCardIndexQuery, ReportCardIndexQueryVariables>(ReportCardIndexDocument, options);
}
export function useReportCardIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ReportCardIndexQuery, ReportCardIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ReportCardIndexQuery, ReportCardIndexQueryVariables>(ReportCardIndexDocument, options);
}
export type ReportCardIndexQueryHookResult = ReturnType<typeof useReportCardIndexQuery>;
export type ReportCardIndexLazyQueryHookResult = ReturnType<typeof useReportCardIndexLazyQuery>;
export type ReportCardIndexQueryResult = Apollo.QueryResult<ReportCardIndexQuery, ReportCardIndexQueryVariables>;
export const ReportCardNameDocument = gql`
	query reportCardName($id: ID!) {
		reportCard(id: $id) {
			id
			...ReportCardName
		}
	}
	${ReportCardNameFragmentDoc}
`;

/**
 * __useReportCardNameQuery__
 *
 * To run a query within a React component, call `useReportCardNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportCardNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportCardNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReportCardNameQuery(
	baseOptions: Apollo.QueryHookOptions<ReportCardNameQuery, ReportCardNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ReportCardNameQuery, ReportCardNameQueryVariables>(ReportCardNameDocument, options);
}
export function useReportCardNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ReportCardNameQuery, ReportCardNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ReportCardNameQuery, ReportCardNameQueryVariables>(ReportCardNameDocument, options);
}
export type ReportCardNameQueryHookResult = ReturnType<typeof useReportCardNameQuery>;
export type ReportCardNameLazyQueryHookResult = ReturnType<typeof useReportCardNameLazyQuery>;
export type ReportCardNameQueryResult = Apollo.QueryResult<ReportCardNameQuery, ReportCardNameQueryVariables>;
export const ReportCardNamesDocument = gql`
	query reportCardNames($filters: [ReportCardFilterInput!], $pagination: Pagination, $order: ReportCardOrder) {
		reportCardConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...ReportCardName
				}
			}
		}
	}
	${ReportCardNameFragmentDoc}
`;

/**
 * __useReportCardNamesQuery__
 *
 * To run a query within a React component, call `useReportCardNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportCardNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportCardNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useReportCardNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<ReportCardNamesQuery, ReportCardNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ReportCardNamesQuery, ReportCardNamesQueryVariables>(ReportCardNamesDocument, options);
}
export function useReportCardNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ReportCardNamesQuery, ReportCardNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ReportCardNamesQuery, ReportCardNamesQueryVariables>(ReportCardNamesDocument, options);
}
export type ReportCardNamesQueryHookResult = ReturnType<typeof useReportCardNamesQuery>;
export type ReportCardNamesLazyQueryHookResult = ReturnType<typeof useReportCardNamesLazyQuery>;
export type ReportCardNamesQueryResult = Apollo.QueryResult<ReportCardNamesQuery, ReportCardNamesQueryVariables>;
export const ReportCardPaginationDocument = gql`
	query reportCardPagination($filters: [ReportCardFilterInput!], $order: ReportCardOrder) {
		reportCardConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useReportCardPaginationQuery__
 *
 * To run a query within a React component, call `useReportCardPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportCardPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportCardPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useReportCardPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<ReportCardPaginationQuery, ReportCardPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ReportCardPaginationQuery, ReportCardPaginationQueryVariables>(
		ReportCardPaginationDocument,
		options,
	);
}
export function useReportCardPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ReportCardPaginationQuery, ReportCardPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ReportCardPaginationQuery, ReportCardPaginationQueryVariables>(
		ReportCardPaginationDocument,
		options,
	);
}
export type ReportCardPaginationQueryHookResult = ReturnType<typeof useReportCardPaginationQuery>;
export type ReportCardPaginationLazyQueryHookResult = ReturnType<typeof useReportCardPaginationLazyQuery>;
export type ReportCardPaginationQueryResult = Apollo.QueryResult<
	ReportCardPaginationQuery,
	ReportCardPaginationQueryVariables
>;
export const ReportCardSelectDocument = gql`
	query reportCardSelect($filters: [ReportCardFilterInput!], $order: ReportCardOrder) {
		reportCardConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...ReportCardName
				}
			}
		}
	}
	${ReportCardNameFragmentDoc}
`;

/**
 * __useReportCardSelectQuery__
 *
 * To run a query within a React component, call `useReportCardSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportCardSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportCardSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useReportCardSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<ReportCardSelectQuery, ReportCardSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ReportCardSelectQuery, ReportCardSelectQueryVariables>(ReportCardSelectDocument, options);
}
export function useReportCardSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ReportCardSelectQuery, ReportCardSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ReportCardSelectQuery, ReportCardSelectQueryVariables>(ReportCardSelectDocument, options);
}
export type ReportCardSelectQueryHookResult = ReturnType<typeof useReportCardSelectQuery>;
export type ReportCardSelectLazyQueryHookResult = ReturnType<typeof useReportCardSelectLazyQuery>;
export type ReportCardSelectQueryResult = Apollo.QueryResult<ReportCardSelectQuery, ReportCardSelectQueryVariables>;
export const ReportCardStudentCourseSectionListDocument = gql`
	query reportCardStudentCourseSectionList($studentID: ID!, $courseSectionID: ID!) {
		reportCardConnection(filters: { studentIDEQ: $studentID, term: { courseSections: { idEQ: $courseSectionID } } }) {
			edges {
				node {
					id
					learningObjectiveMarks(
						filters: { learningObjective: { course: { courseSections: { idEQ: $courseSectionID } } } }
					) {
						id
						learningObjective {
							id
							category
							description
						}
						value
					}
				}
			}
		}
	}
`;

/**
 * __useReportCardStudentCourseSectionListQuery__
 *
 * To run a query within a React component, call `useReportCardStudentCourseSectionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportCardStudentCourseSectionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportCardStudentCourseSectionListQuery({
 *   variables: {
 *      studentID: // value for 'studentID'
 *      courseSectionID: // value for 'courseSectionID'
 *   },
 * });
 */
export function useReportCardStudentCourseSectionListQuery(
	baseOptions: Apollo.QueryHookOptions<
		ReportCardStudentCourseSectionListQuery,
		ReportCardStudentCourseSectionListQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ReportCardStudentCourseSectionListQuery, ReportCardStudentCourseSectionListQueryVariables>(
		ReportCardStudentCourseSectionListDocument,
		options,
	);
}
export function useReportCardStudentCourseSectionListLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ReportCardStudentCourseSectionListQuery,
		ReportCardStudentCourseSectionListQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ReportCardStudentCourseSectionListQuery, ReportCardStudentCourseSectionListQueryVariables>(
		ReportCardStudentCourseSectionListDocument,
		options,
	);
}
export type ReportCardStudentCourseSectionListQueryHookResult = ReturnType<
	typeof useReportCardStudentCourseSectionListQuery
>;
export type ReportCardStudentCourseSectionListLazyQueryHookResult = ReturnType<
	typeof useReportCardStudentCourseSectionListLazyQuery
>;
export type ReportCardStudentCourseSectionListQueryResult = Apollo.QueryResult<
	ReportCardStudentCourseSectionListQuery,
	ReportCardStudentCourseSectionListQueryVariables
>;
export const StudentCourseSectionTeacherReportCardsCourseSectionOverviewListDocument = gql`
	query studentCourseSectionTeacherReportCardsCourseSectionOverviewList($courseSectionID: ID!) {
		courseSection(id: $courseSectionID) {
			id
			name
			course {
				id
				learningObjectives(order: { field: CATEGORY, direction: ASC }) {
					id
					category
					description
				}
			}
			courseSectionStudentEnrollments(order: { direction: ASC }) {
				id
				student {
					id
					person {
						id
						name
					}
					reportCards(filters: { term: { courseSections: { idEQ: $courseSectionID } } }) {
						id
						issueDate
						learningObjectiveMarks(
							filters: { learningObjective: { course: { courseSections: { idEQ: $courseSectionID } } } }
						) {
							id
							value
						}
					}
				}
			}
		}
	}
`;

/**
 * __useStudentCourseSectionTeacherReportCardsCourseSectionOverviewListQuery__
 *
 * To run a query within a React component, call `useStudentCourseSectionTeacherReportCardsCourseSectionOverviewListQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentCourseSectionTeacherReportCardsCourseSectionOverviewListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentCourseSectionTeacherReportCardsCourseSectionOverviewListQuery({
 *   variables: {
 *      courseSectionID: // value for 'courseSectionID'
 *   },
 * });
 */
export function useStudentCourseSectionTeacherReportCardsCourseSectionOverviewListQuery(
	baseOptions: Apollo.QueryHookOptions<
		StudentCourseSectionTeacherReportCardsCourseSectionOverviewListQuery,
		StudentCourseSectionTeacherReportCardsCourseSectionOverviewListQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		StudentCourseSectionTeacherReportCardsCourseSectionOverviewListQuery,
		StudentCourseSectionTeacherReportCardsCourseSectionOverviewListQueryVariables
	>(StudentCourseSectionTeacherReportCardsCourseSectionOverviewListDocument, options);
}
export function useStudentCourseSectionTeacherReportCardsCourseSectionOverviewListLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		StudentCourseSectionTeacherReportCardsCourseSectionOverviewListQuery,
		StudentCourseSectionTeacherReportCardsCourseSectionOverviewListQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		StudentCourseSectionTeacherReportCardsCourseSectionOverviewListQuery,
		StudentCourseSectionTeacherReportCardsCourseSectionOverviewListQueryVariables
	>(StudentCourseSectionTeacherReportCardsCourseSectionOverviewListDocument, options);
}
export type StudentCourseSectionTeacherReportCardsCourseSectionOverviewListQueryHookResult = ReturnType<
	typeof useStudentCourseSectionTeacherReportCardsCourseSectionOverviewListQuery
>;
export type StudentCourseSectionTeacherReportCardsCourseSectionOverviewListLazyQueryHookResult = ReturnType<
	typeof useStudentCourseSectionTeacherReportCardsCourseSectionOverviewListLazyQuery
>;
export type StudentCourseSectionTeacherReportCardsCourseSectionOverviewListQueryResult = Apollo.QueryResult<
	StudentCourseSectionTeacherReportCardsCourseSectionOverviewListQuery,
	StudentCourseSectionTeacherReportCardsCourseSectionOverviewListQueryVariables
>;
export const StudentCourseSectionTeacherReportCardsTermOverviewByCoursesListDocument = gql`
	query studentCourseSectionTeacherReportCardsTermOverviewByCoursesList($termID: ID!, $teacherID: ID!) {
		courseSectionConnection(
			filters: { termIDEQ: $termID, courseSectionTeacherEnrollments: [{ teacherIDEQ: $teacherID }] }
			order: { field: NAME, direction: ASC }
		) {
			edges {
				node {
					id
					name
					course {
						id
						learningObjectives(filters: { isActiveEQ: true }) {
							id
						}
					}
					courseSectionStudentEnrollments(order: { direction: ASC }) {
						id
						student {
							id
						}
					}
				}
			}
		}
		studentConnection(
			filters: {
				courseSectionStudentEnrollments: {
					courseSection: { termIDEQ: $termID, courseSectionTeacherEnrollments: [{ teacherIDEQ: $teacherID }] }
				}
			}
		) {
			edges {
				node {
					id
					person {
						id
						name
					}
					reportCards(filters: { termIDEQ: $termID }) {
						id
						issueDate
						learningObjectiveMarks {
							id
							learningObjectiveID
							value
						}
					}
				}
			}
		}
	}
`;

/**
 * __useStudentCourseSectionTeacherReportCardsTermOverviewByCoursesListQuery__
 *
 * To run a query within a React component, call `useStudentCourseSectionTeacherReportCardsTermOverviewByCoursesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentCourseSectionTeacherReportCardsTermOverviewByCoursesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentCourseSectionTeacherReportCardsTermOverviewByCoursesListQuery({
 *   variables: {
 *      termID: // value for 'termID'
 *      teacherID: // value for 'teacherID'
 *   },
 * });
 */
export function useStudentCourseSectionTeacherReportCardsTermOverviewByCoursesListQuery(
	baseOptions: Apollo.QueryHookOptions<
		StudentCourseSectionTeacherReportCardsTermOverviewByCoursesListQuery,
		StudentCourseSectionTeacherReportCardsTermOverviewByCoursesListQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		StudentCourseSectionTeacherReportCardsTermOverviewByCoursesListQuery,
		StudentCourseSectionTeacherReportCardsTermOverviewByCoursesListQueryVariables
	>(StudentCourseSectionTeacherReportCardsTermOverviewByCoursesListDocument, options);
}
export function useStudentCourseSectionTeacherReportCardsTermOverviewByCoursesListLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		StudentCourseSectionTeacherReportCardsTermOverviewByCoursesListQuery,
		StudentCourseSectionTeacherReportCardsTermOverviewByCoursesListQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		StudentCourseSectionTeacherReportCardsTermOverviewByCoursesListQuery,
		StudentCourseSectionTeacherReportCardsTermOverviewByCoursesListQueryVariables
	>(StudentCourseSectionTeacherReportCardsTermOverviewByCoursesListDocument, options);
}
export type StudentCourseSectionTeacherReportCardsTermOverviewByCoursesListQueryHookResult = ReturnType<
	typeof useStudentCourseSectionTeacherReportCardsTermOverviewByCoursesListQuery
>;
export type StudentCourseSectionTeacherReportCardsTermOverviewByCoursesListLazyQueryHookResult = ReturnType<
	typeof useStudentCourseSectionTeacherReportCardsTermOverviewByCoursesListLazyQuery
>;
export type StudentCourseSectionTeacherReportCardsTermOverviewByCoursesListQueryResult = Apollo.QueryResult<
	StudentCourseSectionTeacherReportCardsTermOverviewByCoursesListQuery,
	StudentCourseSectionTeacherReportCardsTermOverviewByCoursesListQueryVariables
>;
export const ReportCardUpdateDocument = gql`
	mutation reportCardUpdate($id: ID!, $input: ReportCardUpdateInput!) {
		updateReportCard(id: $id, input: $input) {
			...ReportCardDetail
		}
	}
	${ReportCardDetailFragmentDoc}
`;
export type ReportCardUpdateMutationFn = Apollo.MutationFunction<
	ReportCardUpdateMutation,
	ReportCardUpdateMutationVariables
>;

/**
 * __useReportCardUpdateMutation__
 *
 * To run a mutation, you first call `useReportCardUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportCardUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportCardUpdateMutation, { data, loading, error }] = useReportCardUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportCardUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<ReportCardUpdateMutation, ReportCardUpdateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<ReportCardUpdateMutation, ReportCardUpdateMutationVariables>(
		ReportCardUpdateDocument,
		options,
	);
}
export type ReportCardUpdateMutationHookResult = ReturnType<typeof useReportCardUpdateMutation>;
export type ReportCardUpdateMutationResult = Apollo.MutationResult<ReportCardUpdateMutation>;
export type ReportCardUpdateMutationOptions = Apollo.BaseMutationOptions<
	ReportCardUpdateMutation,
	ReportCardUpdateMutationVariables
>;
export const RoomCountDocument = gql`
	query roomCount($filters: [RoomFilterInput!], $pagination: Pagination, $order: RoomOrder) {
		roomConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useRoomCountQuery__
 *
 * To run a query within a React component, call `useRoomCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoomCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoomCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useRoomCountQuery(baseOptions?: Apollo.QueryHookOptions<RoomCountQuery, RoomCountQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<RoomCountQuery, RoomCountQueryVariables>(RoomCountDocument, options);
}
export function useRoomCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<RoomCountQuery, RoomCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<RoomCountQuery, RoomCountQueryVariables>(RoomCountDocument, options);
}
export type RoomCountQueryHookResult = ReturnType<typeof useRoomCountQuery>;
export type RoomCountLazyQueryHookResult = ReturnType<typeof useRoomCountLazyQuery>;
export type RoomCountQueryResult = Apollo.QueryResult<RoomCountQuery, RoomCountQueryVariables>;
export const RoomCreateDocument = gql`
	mutation roomCreate($input: RoomCreateInput!) {
		createRoom(input: $input) {
			...RoomDetail
		}
	}
	${RoomDetailFragmentDoc}
`;
export type RoomCreateMutationFn = Apollo.MutationFunction<RoomCreateMutation, RoomCreateMutationVariables>;

/**
 * __useRoomCreateMutation__
 *
 * To run a mutation, you first call `useRoomCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRoomCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [roomCreateMutation, { data, loading, error }] = useRoomCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRoomCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<RoomCreateMutation, RoomCreateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<RoomCreateMutation, RoomCreateMutationVariables>(RoomCreateDocument, options);
}
export type RoomCreateMutationHookResult = ReturnType<typeof useRoomCreateMutation>;
export type RoomCreateMutationResult = Apollo.MutationResult<RoomCreateMutation>;
export type RoomCreateMutationOptions = Apollo.BaseMutationOptions<RoomCreateMutation, RoomCreateMutationVariables>;
export const RoomDeleteDocument = gql`
	mutation roomDelete($id: ID!) {
		deleteRoom(id: $id)
	}
`;
export type RoomDeleteMutationFn = Apollo.MutationFunction<RoomDeleteMutation, RoomDeleteMutationVariables>;

/**
 * __useRoomDeleteMutation__
 *
 * To run a mutation, you first call `useRoomDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRoomDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [roomDeleteMutation, { data, loading, error }] = useRoomDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRoomDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<RoomDeleteMutation, RoomDeleteMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<RoomDeleteMutation, RoomDeleteMutationVariables>(RoomDeleteDocument, options);
}
export type RoomDeleteMutationHookResult = ReturnType<typeof useRoomDeleteMutation>;
export type RoomDeleteMutationResult = Apollo.MutationResult<RoomDeleteMutation>;
export type RoomDeleteMutationOptions = Apollo.BaseMutationOptions<RoomDeleteMutation, RoomDeleteMutationVariables>;
export const RoomDetailDocument = gql`
	query roomDetail($id: ID!) {
		room(id: $id) {
			...RoomDetail
		}
	}
	${RoomDetailFragmentDoc}
`;

/**
 * __useRoomDetailQuery__
 *
 * To run a query within a React component, call `useRoomDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoomDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoomDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRoomDetailQuery(baseOptions: Apollo.QueryHookOptions<RoomDetailQuery, RoomDetailQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<RoomDetailQuery, RoomDetailQueryVariables>(RoomDetailDocument, options);
}
export function useRoomDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<RoomDetailQuery, RoomDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<RoomDetailQuery, RoomDetailQueryVariables>(RoomDetailDocument, options);
}
export type RoomDetailQueryHookResult = ReturnType<typeof useRoomDetailQuery>;
export type RoomDetailLazyQueryHookResult = ReturnType<typeof useRoomDetailLazyQuery>;
export type RoomDetailQueryResult = Apollo.QueryResult<RoomDetailQuery, RoomDetailQueryVariables>;
export const RoomIndexDocument = gql`
	query roomIndex($filters: [RoomFilterInput!], $pagination: Pagination, $order: RoomOrder) {
		roomConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					name
					number
				}
			}
		}
	}
`;

/**
 * __useRoomIndexQuery__
 *
 * To run a query within a React component, call `useRoomIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoomIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoomIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useRoomIndexQuery(baseOptions?: Apollo.QueryHookOptions<RoomIndexQuery, RoomIndexQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<RoomIndexQuery, RoomIndexQueryVariables>(RoomIndexDocument, options);
}
export function useRoomIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<RoomIndexQuery, RoomIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<RoomIndexQuery, RoomIndexQueryVariables>(RoomIndexDocument, options);
}
export type RoomIndexQueryHookResult = ReturnType<typeof useRoomIndexQuery>;
export type RoomIndexLazyQueryHookResult = ReturnType<typeof useRoomIndexLazyQuery>;
export type RoomIndexQueryResult = Apollo.QueryResult<RoomIndexQuery, RoomIndexQueryVariables>;
export const RoomNameDocument = gql`
	query roomName($id: ID!) {
		room(id: $id) {
			id
			...RoomName
		}
	}
	${RoomNameFragmentDoc}
`;

/**
 * __useRoomNameQuery__
 *
 * To run a query within a React component, call `useRoomNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoomNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoomNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRoomNameQuery(baseOptions: Apollo.QueryHookOptions<RoomNameQuery, RoomNameQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<RoomNameQuery, RoomNameQueryVariables>(RoomNameDocument, options);
}
export function useRoomNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RoomNameQuery, RoomNameQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<RoomNameQuery, RoomNameQueryVariables>(RoomNameDocument, options);
}
export type RoomNameQueryHookResult = ReturnType<typeof useRoomNameQuery>;
export type RoomNameLazyQueryHookResult = ReturnType<typeof useRoomNameLazyQuery>;
export type RoomNameQueryResult = Apollo.QueryResult<RoomNameQuery, RoomNameQueryVariables>;
export const RoomNamesDocument = gql`
	query roomNames($filters: [RoomFilterInput!], $pagination: Pagination, $order: RoomOrder) {
		roomConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...RoomName
				}
			}
		}
	}
	${RoomNameFragmentDoc}
`;

/**
 * __useRoomNamesQuery__
 *
 * To run a query within a React component, call `useRoomNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoomNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoomNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useRoomNamesQuery(baseOptions?: Apollo.QueryHookOptions<RoomNamesQuery, RoomNamesQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<RoomNamesQuery, RoomNamesQueryVariables>(RoomNamesDocument, options);
}
export function useRoomNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<RoomNamesQuery, RoomNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<RoomNamesQuery, RoomNamesQueryVariables>(RoomNamesDocument, options);
}
export type RoomNamesQueryHookResult = ReturnType<typeof useRoomNamesQuery>;
export type RoomNamesLazyQueryHookResult = ReturnType<typeof useRoomNamesLazyQuery>;
export type RoomNamesQueryResult = Apollo.QueryResult<RoomNamesQuery, RoomNamesQueryVariables>;
export const RoomPaginationDocument = gql`
	query roomPagination($filters: [RoomFilterInput!], $order: RoomOrder) {
		roomConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useRoomPaginationQuery__
 *
 * To run a query within a React component, call `useRoomPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoomPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoomPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useRoomPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<RoomPaginationQuery, RoomPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<RoomPaginationQuery, RoomPaginationQueryVariables>(RoomPaginationDocument, options);
}
export function useRoomPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<RoomPaginationQuery, RoomPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<RoomPaginationQuery, RoomPaginationQueryVariables>(RoomPaginationDocument, options);
}
export type RoomPaginationQueryHookResult = ReturnType<typeof useRoomPaginationQuery>;
export type RoomPaginationLazyQueryHookResult = ReturnType<typeof useRoomPaginationLazyQuery>;
export type RoomPaginationQueryResult = Apollo.QueryResult<RoomPaginationQuery, RoomPaginationQueryVariables>;
export const RoomSelectDocument = gql`
	query roomSelect($filters: [RoomFilterInput!], $order: RoomOrder) {
		roomConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...RoomName
				}
			}
		}
	}
	${RoomNameFragmentDoc}
`;

/**
 * __useRoomSelectQuery__
 *
 * To run a query within a React component, call `useRoomSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoomSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoomSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useRoomSelectQuery(baseOptions?: Apollo.QueryHookOptions<RoomSelectQuery, RoomSelectQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<RoomSelectQuery, RoomSelectQueryVariables>(RoomSelectDocument, options);
}
export function useRoomSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<RoomSelectQuery, RoomSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<RoomSelectQuery, RoomSelectQueryVariables>(RoomSelectDocument, options);
}
export type RoomSelectQueryHookResult = ReturnType<typeof useRoomSelectQuery>;
export type RoomSelectLazyQueryHookResult = ReturnType<typeof useRoomSelectLazyQuery>;
export type RoomSelectQueryResult = Apollo.QueryResult<RoomSelectQuery, RoomSelectQueryVariables>;
export const RoomUpdateDocument = gql`
	mutation roomUpdate($id: ID!, $input: RoomUpdateInput!) {
		updateRoom(id: $id, input: $input) {
			...RoomDetail
		}
	}
	${RoomDetailFragmentDoc}
`;
export type RoomUpdateMutationFn = Apollo.MutationFunction<RoomUpdateMutation, RoomUpdateMutationVariables>;

/**
 * __useRoomUpdateMutation__
 *
 * To run a mutation, you first call `useRoomUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRoomUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [roomUpdateMutation, { data, loading, error }] = useRoomUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRoomUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<RoomUpdateMutation, RoomUpdateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<RoomUpdateMutation, RoomUpdateMutationVariables>(RoomUpdateDocument, options);
}
export type RoomUpdateMutationHookResult = ReturnType<typeof useRoomUpdateMutation>;
export type RoomUpdateMutationResult = Apollo.MutationResult<RoomUpdateMutation>;
export type RoomUpdateMutationOptions = Apollo.BaseMutationOptions<RoomUpdateMutation, RoomUpdateMutationVariables>;
export const SchoolAttendanceRecordCountDocument = gql`
	query schoolAttendanceRecordCount(
		$filters: [SchoolAttendanceRecordFilterInput!]
		$pagination: Pagination
		$order: SchoolAttendanceRecordOrder
	) {
		schoolAttendanceRecordConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useSchoolAttendanceRecordCountQuery__
 *
 * To run a query within a React component, call `useSchoolAttendanceRecordCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolAttendanceRecordCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolAttendanceRecordCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useSchoolAttendanceRecordCountQuery(
	baseOptions?: Apollo.QueryHookOptions<SchoolAttendanceRecordCountQuery, SchoolAttendanceRecordCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<SchoolAttendanceRecordCountQuery, SchoolAttendanceRecordCountQueryVariables>(
		SchoolAttendanceRecordCountDocument,
		options,
	);
}
export function useSchoolAttendanceRecordCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		SchoolAttendanceRecordCountQuery,
		SchoolAttendanceRecordCountQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<SchoolAttendanceRecordCountQuery, SchoolAttendanceRecordCountQueryVariables>(
		SchoolAttendanceRecordCountDocument,
		options,
	);
}
export type SchoolAttendanceRecordCountQueryHookResult = ReturnType<typeof useSchoolAttendanceRecordCountQuery>;
export type SchoolAttendanceRecordCountLazyQueryHookResult = ReturnType<typeof useSchoolAttendanceRecordCountLazyQuery>;
export type SchoolAttendanceRecordCountQueryResult = Apollo.QueryResult<
	SchoolAttendanceRecordCountQuery,
	SchoolAttendanceRecordCountQueryVariables
>;
export const SchoolAttendanceRecordCreateDocument = gql`
	mutation schoolAttendanceRecordCreate($input: SchoolAttendanceRecordCreateInput!) {
		createSchoolAttendanceRecord(input: $input) {
			...SchoolAttendanceRecordDetail
		}
	}
	${SchoolAttendanceRecordDetailFragmentDoc}
`;
export type SchoolAttendanceRecordCreateMutationFn = Apollo.MutationFunction<
	SchoolAttendanceRecordCreateMutation,
	SchoolAttendanceRecordCreateMutationVariables
>;

/**
 * __useSchoolAttendanceRecordCreateMutation__
 *
 * To run a mutation, you first call `useSchoolAttendanceRecordCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSchoolAttendanceRecordCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [schoolAttendanceRecordCreateMutation, { data, loading, error }] = useSchoolAttendanceRecordCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSchoolAttendanceRecordCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<
		SchoolAttendanceRecordCreateMutation,
		SchoolAttendanceRecordCreateMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<SchoolAttendanceRecordCreateMutation, SchoolAttendanceRecordCreateMutationVariables>(
		SchoolAttendanceRecordCreateDocument,
		options,
	);
}
export type SchoolAttendanceRecordCreateMutationHookResult = ReturnType<typeof useSchoolAttendanceRecordCreateMutation>;
export type SchoolAttendanceRecordCreateMutationResult = Apollo.MutationResult<SchoolAttendanceRecordCreateMutation>;
export type SchoolAttendanceRecordCreateMutationOptions = Apollo.BaseMutationOptions<
	SchoolAttendanceRecordCreateMutation,
	SchoolAttendanceRecordCreateMutationVariables
>;
export const SchoolAttendanceRecordDailyReportListDocument = gql`
	query schoolAttendanceRecordDailyReportList(
		$filters: [StudentFilterInput!]
		$attendanceFilters: [SchoolAttendanceRecordFilterInput!]
		$pagination: Pagination
		$order: StudentOrder
	) {
		studentConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					person {
						id
						name
						imageURL
					}
					schoolAttendanceRecords(filters: $attendanceFilters) {
						id
						isPresent
						isLate
					}
				}
			}
		}
	}
`;

/**
 * __useSchoolAttendanceRecordDailyReportListQuery__
 *
 * To run a query within a React component, call `useSchoolAttendanceRecordDailyReportListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolAttendanceRecordDailyReportListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolAttendanceRecordDailyReportListQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      attendanceFilters: // value for 'attendanceFilters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useSchoolAttendanceRecordDailyReportListQuery(
	baseOptions?: Apollo.QueryHookOptions<
		SchoolAttendanceRecordDailyReportListQuery,
		SchoolAttendanceRecordDailyReportListQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		SchoolAttendanceRecordDailyReportListQuery,
		SchoolAttendanceRecordDailyReportListQueryVariables
	>(SchoolAttendanceRecordDailyReportListDocument, options);
}
export function useSchoolAttendanceRecordDailyReportListLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		SchoolAttendanceRecordDailyReportListQuery,
		SchoolAttendanceRecordDailyReportListQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		SchoolAttendanceRecordDailyReportListQuery,
		SchoolAttendanceRecordDailyReportListQueryVariables
	>(SchoolAttendanceRecordDailyReportListDocument, options);
}
export type SchoolAttendanceRecordDailyReportListQueryHookResult = ReturnType<
	typeof useSchoolAttendanceRecordDailyReportListQuery
>;
export type SchoolAttendanceRecordDailyReportListLazyQueryHookResult = ReturnType<
	typeof useSchoolAttendanceRecordDailyReportListLazyQuery
>;
export type SchoolAttendanceRecordDailyReportListQueryResult = Apollo.QueryResult<
	SchoolAttendanceRecordDailyReportListQuery,
	SchoolAttendanceRecordDailyReportListQueryVariables
>;
export const SchoolAttendanceRecordDeleteDocument = gql`
	mutation schoolAttendanceRecordDelete($id: ID!) {
		deleteSchoolAttendanceRecord(id: $id)
	}
`;
export type SchoolAttendanceRecordDeleteMutationFn = Apollo.MutationFunction<
	SchoolAttendanceRecordDeleteMutation,
	SchoolAttendanceRecordDeleteMutationVariables
>;

/**
 * __useSchoolAttendanceRecordDeleteMutation__
 *
 * To run a mutation, you first call `useSchoolAttendanceRecordDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSchoolAttendanceRecordDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [schoolAttendanceRecordDeleteMutation, { data, loading, error }] = useSchoolAttendanceRecordDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSchoolAttendanceRecordDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<
		SchoolAttendanceRecordDeleteMutation,
		SchoolAttendanceRecordDeleteMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<SchoolAttendanceRecordDeleteMutation, SchoolAttendanceRecordDeleteMutationVariables>(
		SchoolAttendanceRecordDeleteDocument,
		options,
	);
}
export type SchoolAttendanceRecordDeleteMutationHookResult = ReturnType<typeof useSchoolAttendanceRecordDeleteMutation>;
export type SchoolAttendanceRecordDeleteMutationResult = Apollo.MutationResult<SchoolAttendanceRecordDeleteMutation>;
export type SchoolAttendanceRecordDeleteMutationOptions = Apollo.BaseMutationOptions<
	SchoolAttendanceRecordDeleteMutation,
	SchoolAttendanceRecordDeleteMutationVariables
>;
export const SchoolAttendanceRecordDetailDocument = gql`
	query schoolAttendanceRecordDetail($id: ID!) {
		schoolAttendanceRecord(id: $id) {
			...SchoolAttendanceRecordDetail
		}
	}
	${SchoolAttendanceRecordDetailFragmentDoc}
`;

/**
 * __useSchoolAttendanceRecordDetailQuery__
 *
 * To run a query within a React component, call `useSchoolAttendanceRecordDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolAttendanceRecordDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolAttendanceRecordDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSchoolAttendanceRecordDetailQuery(
	baseOptions: Apollo.QueryHookOptions<SchoolAttendanceRecordDetailQuery, SchoolAttendanceRecordDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<SchoolAttendanceRecordDetailQuery, SchoolAttendanceRecordDetailQueryVariables>(
		SchoolAttendanceRecordDetailDocument,
		options,
	);
}
export function useSchoolAttendanceRecordDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		SchoolAttendanceRecordDetailQuery,
		SchoolAttendanceRecordDetailQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<SchoolAttendanceRecordDetailQuery, SchoolAttendanceRecordDetailQueryVariables>(
		SchoolAttendanceRecordDetailDocument,
		options,
	);
}
export type SchoolAttendanceRecordDetailQueryHookResult = ReturnType<typeof useSchoolAttendanceRecordDetailQuery>;
export type SchoolAttendanceRecordDetailLazyQueryHookResult = ReturnType<
	typeof useSchoolAttendanceRecordDetailLazyQuery
>;
export type SchoolAttendanceRecordDetailQueryResult = Apollo.QueryResult<
	SchoolAttendanceRecordDetailQuery,
	SchoolAttendanceRecordDetailQueryVariables
>;
export const SchoolAttendanceRecordIndexDocument = gql`
	query schoolAttendanceRecordIndex(
		$filters: [SchoolAttendanceRecordFilterInput!]
		$pagination: Pagination
		$order: SchoolAttendanceRecordOrder
	) {
		schoolAttendanceRecordConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					checkInTime
					checkOutTime
					isPresent
					isLate
					isExcused
					notes
					student {
						id
						person {
							id
							name
							imageURL
						}
					}
				}
			}
		}
	}
`;

/**
 * __useSchoolAttendanceRecordIndexQuery__
 *
 * To run a query within a React component, call `useSchoolAttendanceRecordIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolAttendanceRecordIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolAttendanceRecordIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useSchoolAttendanceRecordIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<SchoolAttendanceRecordIndexQuery, SchoolAttendanceRecordIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<SchoolAttendanceRecordIndexQuery, SchoolAttendanceRecordIndexQueryVariables>(
		SchoolAttendanceRecordIndexDocument,
		options,
	);
}
export function useSchoolAttendanceRecordIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		SchoolAttendanceRecordIndexQuery,
		SchoolAttendanceRecordIndexQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<SchoolAttendanceRecordIndexQuery, SchoolAttendanceRecordIndexQueryVariables>(
		SchoolAttendanceRecordIndexDocument,
		options,
	);
}
export type SchoolAttendanceRecordIndexQueryHookResult = ReturnType<typeof useSchoolAttendanceRecordIndexQuery>;
export type SchoolAttendanceRecordIndexLazyQueryHookResult = ReturnType<typeof useSchoolAttendanceRecordIndexLazyQuery>;
export type SchoolAttendanceRecordIndexQueryResult = Apollo.QueryResult<
	SchoolAttendanceRecordIndexQuery,
	SchoolAttendanceRecordIndexQueryVariables
>;
export const SchoolAttendanceRecordNameDocument = gql`
	query schoolAttendanceRecordName($id: ID!) {
		schoolAttendanceRecord(id: $id) {
			id
			...SchoolAttendanceRecordName
		}
	}
	${SchoolAttendanceRecordNameFragmentDoc}
`;

/**
 * __useSchoolAttendanceRecordNameQuery__
 *
 * To run a query within a React component, call `useSchoolAttendanceRecordNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolAttendanceRecordNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolAttendanceRecordNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSchoolAttendanceRecordNameQuery(
	baseOptions: Apollo.QueryHookOptions<SchoolAttendanceRecordNameQuery, SchoolAttendanceRecordNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<SchoolAttendanceRecordNameQuery, SchoolAttendanceRecordNameQueryVariables>(
		SchoolAttendanceRecordNameDocument,
		options,
	);
}
export function useSchoolAttendanceRecordNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<SchoolAttendanceRecordNameQuery, SchoolAttendanceRecordNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<SchoolAttendanceRecordNameQuery, SchoolAttendanceRecordNameQueryVariables>(
		SchoolAttendanceRecordNameDocument,
		options,
	);
}
export type SchoolAttendanceRecordNameQueryHookResult = ReturnType<typeof useSchoolAttendanceRecordNameQuery>;
export type SchoolAttendanceRecordNameLazyQueryHookResult = ReturnType<typeof useSchoolAttendanceRecordNameLazyQuery>;
export type SchoolAttendanceRecordNameQueryResult = Apollo.QueryResult<
	SchoolAttendanceRecordNameQuery,
	SchoolAttendanceRecordNameQueryVariables
>;
export const SchoolAttendanceRecordNamesDocument = gql`
	query schoolAttendanceRecordNames(
		$filters: [SchoolAttendanceRecordFilterInput!]
		$pagination: Pagination
		$order: SchoolAttendanceRecordOrder
	) {
		schoolAttendanceRecordConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...SchoolAttendanceRecordName
				}
			}
		}
	}
	${SchoolAttendanceRecordNameFragmentDoc}
`;

/**
 * __useSchoolAttendanceRecordNamesQuery__
 *
 * To run a query within a React component, call `useSchoolAttendanceRecordNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolAttendanceRecordNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolAttendanceRecordNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useSchoolAttendanceRecordNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<SchoolAttendanceRecordNamesQuery, SchoolAttendanceRecordNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<SchoolAttendanceRecordNamesQuery, SchoolAttendanceRecordNamesQueryVariables>(
		SchoolAttendanceRecordNamesDocument,
		options,
	);
}
export function useSchoolAttendanceRecordNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		SchoolAttendanceRecordNamesQuery,
		SchoolAttendanceRecordNamesQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<SchoolAttendanceRecordNamesQuery, SchoolAttendanceRecordNamesQueryVariables>(
		SchoolAttendanceRecordNamesDocument,
		options,
	);
}
export type SchoolAttendanceRecordNamesQueryHookResult = ReturnType<typeof useSchoolAttendanceRecordNamesQuery>;
export type SchoolAttendanceRecordNamesLazyQueryHookResult = ReturnType<typeof useSchoolAttendanceRecordNamesLazyQuery>;
export type SchoolAttendanceRecordNamesQueryResult = Apollo.QueryResult<
	SchoolAttendanceRecordNamesQuery,
	SchoolAttendanceRecordNamesQueryVariables
>;
export const SchoolAttendanceRecordPaginationDocument = gql`
	query schoolAttendanceRecordPagination(
		$filters: [SchoolAttendanceRecordFilterInput!]
		$order: SchoolAttendanceRecordOrder
	) {
		schoolAttendanceRecordConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useSchoolAttendanceRecordPaginationQuery__
 *
 * To run a query within a React component, call `useSchoolAttendanceRecordPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolAttendanceRecordPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolAttendanceRecordPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useSchoolAttendanceRecordPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<
		SchoolAttendanceRecordPaginationQuery,
		SchoolAttendanceRecordPaginationQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<SchoolAttendanceRecordPaginationQuery, SchoolAttendanceRecordPaginationQueryVariables>(
		SchoolAttendanceRecordPaginationDocument,
		options,
	);
}
export function useSchoolAttendanceRecordPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		SchoolAttendanceRecordPaginationQuery,
		SchoolAttendanceRecordPaginationQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<SchoolAttendanceRecordPaginationQuery, SchoolAttendanceRecordPaginationQueryVariables>(
		SchoolAttendanceRecordPaginationDocument,
		options,
	);
}
export type SchoolAttendanceRecordPaginationQueryHookResult = ReturnType<
	typeof useSchoolAttendanceRecordPaginationQuery
>;
export type SchoolAttendanceRecordPaginationLazyQueryHookResult = ReturnType<
	typeof useSchoolAttendanceRecordPaginationLazyQuery
>;
export type SchoolAttendanceRecordPaginationQueryResult = Apollo.QueryResult<
	SchoolAttendanceRecordPaginationQuery,
	SchoolAttendanceRecordPaginationQueryVariables
>;
export const SchoolAttendanceRecordSelectDocument = gql`
	query schoolAttendanceRecordSelect(
		$filters: [SchoolAttendanceRecordFilterInput!]
		$order: SchoolAttendanceRecordOrder
	) {
		schoolAttendanceRecordConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...SchoolAttendanceRecordName
				}
			}
		}
	}
	${SchoolAttendanceRecordNameFragmentDoc}
`;

/**
 * __useSchoolAttendanceRecordSelectQuery__
 *
 * To run a query within a React component, call `useSchoolAttendanceRecordSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolAttendanceRecordSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolAttendanceRecordSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useSchoolAttendanceRecordSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<SchoolAttendanceRecordSelectQuery, SchoolAttendanceRecordSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<SchoolAttendanceRecordSelectQuery, SchoolAttendanceRecordSelectQueryVariables>(
		SchoolAttendanceRecordSelectDocument,
		options,
	);
}
export function useSchoolAttendanceRecordSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		SchoolAttendanceRecordSelectQuery,
		SchoolAttendanceRecordSelectQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<SchoolAttendanceRecordSelectQuery, SchoolAttendanceRecordSelectQueryVariables>(
		SchoolAttendanceRecordSelectDocument,
		options,
	);
}
export type SchoolAttendanceRecordSelectQueryHookResult = ReturnType<typeof useSchoolAttendanceRecordSelectQuery>;
export type SchoolAttendanceRecordSelectLazyQueryHookResult = ReturnType<
	typeof useSchoolAttendanceRecordSelectLazyQuery
>;
export type SchoolAttendanceRecordSelectQueryResult = Apollo.QueryResult<
	SchoolAttendanceRecordSelectQuery,
	SchoolAttendanceRecordSelectQueryVariables
>;
export const SchoolAttendanceRecordStudentProfileListDocument = gql`
	query schoolAttendanceRecordStudentProfileList($studentID: ID!, $startTime: Time!, $endTime: Time!) {
		schoolAttendanceRecordConnection(
			filters: { studentIDEQ: $studentID, checkInTimeGTE: $startTime, checkInTimeLT: $endTime }
			order: { field: CHECK_IN_TIME, direction: ASC }
		) {
			edges {
				node {
					id
					checkInTime
					checkOutTime
					isPresent
					isLate
					isExcused
					notes
				}
			}
		}
	}
`;

/**
 * __useSchoolAttendanceRecordStudentProfileListQuery__
 *
 * To run a query within a React component, call `useSchoolAttendanceRecordStudentProfileListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolAttendanceRecordStudentProfileListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolAttendanceRecordStudentProfileListQuery({
 *   variables: {
 *      studentID: // value for 'studentID'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *   },
 * });
 */
export function useSchoolAttendanceRecordStudentProfileListQuery(
	baseOptions: Apollo.QueryHookOptions<
		SchoolAttendanceRecordStudentProfileListQuery,
		SchoolAttendanceRecordStudentProfileListQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		SchoolAttendanceRecordStudentProfileListQuery,
		SchoolAttendanceRecordStudentProfileListQueryVariables
	>(SchoolAttendanceRecordStudentProfileListDocument, options);
}
export function useSchoolAttendanceRecordStudentProfileListLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		SchoolAttendanceRecordStudentProfileListQuery,
		SchoolAttendanceRecordStudentProfileListQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		SchoolAttendanceRecordStudentProfileListQuery,
		SchoolAttendanceRecordStudentProfileListQueryVariables
	>(SchoolAttendanceRecordStudentProfileListDocument, options);
}
export type SchoolAttendanceRecordStudentProfileListQueryHookResult = ReturnType<
	typeof useSchoolAttendanceRecordStudentProfileListQuery
>;
export type SchoolAttendanceRecordStudentProfileListLazyQueryHookResult = ReturnType<
	typeof useSchoolAttendanceRecordStudentProfileListLazyQuery
>;
export type SchoolAttendanceRecordStudentProfileListQueryResult = Apollo.QueryResult<
	SchoolAttendanceRecordStudentProfileListQuery,
	SchoolAttendanceRecordStudentProfileListQueryVariables
>;
export const SchoolAttendanceRecordUpdateDocument = gql`
	mutation schoolAttendanceRecordUpdate($id: ID!, $input: SchoolAttendanceRecordUpdateInput!) {
		updateSchoolAttendanceRecord(id: $id, input: $input) {
			...SchoolAttendanceRecordDetail
		}
	}
	${SchoolAttendanceRecordDetailFragmentDoc}
`;
export type SchoolAttendanceRecordUpdateMutationFn = Apollo.MutationFunction<
	SchoolAttendanceRecordUpdateMutation,
	SchoolAttendanceRecordUpdateMutationVariables
>;

/**
 * __useSchoolAttendanceRecordUpdateMutation__
 *
 * To run a mutation, you first call `useSchoolAttendanceRecordUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSchoolAttendanceRecordUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [schoolAttendanceRecordUpdateMutation, { data, loading, error }] = useSchoolAttendanceRecordUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSchoolAttendanceRecordUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<
		SchoolAttendanceRecordUpdateMutation,
		SchoolAttendanceRecordUpdateMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<SchoolAttendanceRecordUpdateMutation, SchoolAttendanceRecordUpdateMutationVariables>(
		SchoolAttendanceRecordUpdateDocument,
		options,
	);
}
export type SchoolAttendanceRecordUpdateMutationHookResult = ReturnType<typeof useSchoolAttendanceRecordUpdateMutation>;
export type SchoolAttendanceRecordUpdateMutationResult = Apollo.MutationResult<SchoolAttendanceRecordUpdateMutation>;
export type SchoolAttendanceRecordUpdateMutationOptions = Apollo.BaseMutationOptions<
	SchoolAttendanceRecordUpdateMutation,
	SchoolAttendanceRecordUpdateMutationVariables
>;
export const SchoolStudentEnrollmentCountDocument = gql`
	query schoolStudentEnrollmentCount(
		$filters: [SchoolStudentEnrollmentFilterInput!]
		$pagination: Pagination
		$order: SchoolStudentEnrollmentOrder
	) {
		schoolStudentEnrollmentConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useSchoolStudentEnrollmentCountQuery__
 *
 * To run a query within a React component, call `useSchoolStudentEnrollmentCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolStudentEnrollmentCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolStudentEnrollmentCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useSchoolStudentEnrollmentCountQuery(
	baseOptions?: Apollo.QueryHookOptions<SchoolStudentEnrollmentCountQuery, SchoolStudentEnrollmentCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<SchoolStudentEnrollmentCountQuery, SchoolStudentEnrollmentCountQueryVariables>(
		SchoolStudentEnrollmentCountDocument,
		options,
	);
}
export function useSchoolStudentEnrollmentCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		SchoolStudentEnrollmentCountQuery,
		SchoolStudentEnrollmentCountQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<SchoolStudentEnrollmentCountQuery, SchoolStudentEnrollmentCountQueryVariables>(
		SchoolStudentEnrollmentCountDocument,
		options,
	);
}
export type SchoolStudentEnrollmentCountQueryHookResult = ReturnType<typeof useSchoolStudentEnrollmentCountQuery>;
export type SchoolStudentEnrollmentCountLazyQueryHookResult = ReturnType<
	typeof useSchoolStudentEnrollmentCountLazyQuery
>;
export type SchoolStudentEnrollmentCountQueryResult = Apollo.QueryResult<
	SchoolStudentEnrollmentCountQuery,
	SchoolStudentEnrollmentCountQueryVariables
>;
export const SchoolStudentEnrollmentCreateDocument = gql`
	mutation schoolStudentEnrollmentCreate($input: SchoolStudentEnrollmentCreateInput!) {
		createSchoolStudentEnrollment(input: $input) {
			...SchoolStudentEnrollmentDetail
		}
	}
	${SchoolStudentEnrollmentDetailFragmentDoc}
`;
export type SchoolStudentEnrollmentCreateMutationFn = Apollo.MutationFunction<
	SchoolStudentEnrollmentCreateMutation,
	SchoolStudentEnrollmentCreateMutationVariables
>;

/**
 * __useSchoolStudentEnrollmentCreateMutation__
 *
 * To run a mutation, you first call `useSchoolStudentEnrollmentCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSchoolStudentEnrollmentCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [schoolStudentEnrollmentCreateMutation, { data, loading, error }] = useSchoolStudentEnrollmentCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSchoolStudentEnrollmentCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<
		SchoolStudentEnrollmentCreateMutation,
		SchoolStudentEnrollmentCreateMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<SchoolStudentEnrollmentCreateMutation, SchoolStudentEnrollmentCreateMutationVariables>(
		SchoolStudentEnrollmentCreateDocument,
		options,
	);
}
export type SchoolStudentEnrollmentCreateMutationHookResult = ReturnType<
	typeof useSchoolStudentEnrollmentCreateMutation
>;
export type SchoolStudentEnrollmentCreateMutationResult = Apollo.MutationResult<SchoolStudentEnrollmentCreateMutation>;
export type SchoolStudentEnrollmentCreateMutationOptions = Apollo.BaseMutationOptions<
	SchoolStudentEnrollmentCreateMutation,
	SchoolStudentEnrollmentCreateMutationVariables
>;
export const SchoolStudentEnrollmentDeleteDocument = gql`
	mutation schoolStudentEnrollmentDelete($id: ID!) {
		deleteSchoolStudentEnrollment(id: $id)
	}
`;
export type SchoolStudentEnrollmentDeleteMutationFn = Apollo.MutationFunction<
	SchoolStudentEnrollmentDeleteMutation,
	SchoolStudentEnrollmentDeleteMutationVariables
>;

/**
 * __useSchoolStudentEnrollmentDeleteMutation__
 *
 * To run a mutation, you first call `useSchoolStudentEnrollmentDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSchoolStudentEnrollmentDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [schoolStudentEnrollmentDeleteMutation, { data, loading, error }] = useSchoolStudentEnrollmentDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSchoolStudentEnrollmentDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<
		SchoolStudentEnrollmentDeleteMutation,
		SchoolStudentEnrollmentDeleteMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<SchoolStudentEnrollmentDeleteMutation, SchoolStudentEnrollmentDeleteMutationVariables>(
		SchoolStudentEnrollmentDeleteDocument,
		options,
	);
}
export type SchoolStudentEnrollmentDeleteMutationHookResult = ReturnType<
	typeof useSchoolStudentEnrollmentDeleteMutation
>;
export type SchoolStudentEnrollmentDeleteMutationResult = Apollo.MutationResult<SchoolStudentEnrollmentDeleteMutation>;
export type SchoolStudentEnrollmentDeleteMutationOptions = Apollo.BaseMutationOptions<
	SchoolStudentEnrollmentDeleteMutation,
	SchoolStudentEnrollmentDeleteMutationVariables
>;
export const SchoolStudentEnrollmentDetailDocument = gql`
	query schoolStudentEnrollmentDetail($id: ID!) {
		schoolStudentEnrollment(id: $id) {
			...SchoolStudentEnrollmentDetail
		}
	}
	${SchoolStudentEnrollmentDetailFragmentDoc}
`;

/**
 * __useSchoolStudentEnrollmentDetailQuery__
 *
 * To run a query within a React component, call `useSchoolStudentEnrollmentDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolStudentEnrollmentDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolStudentEnrollmentDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSchoolStudentEnrollmentDetailQuery(
	baseOptions: Apollo.QueryHookOptions<SchoolStudentEnrollmentDetailQuery, SchoolStudentEnrollmentDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<SchoolStudentEnrollmentDetailQuery, SchoolStudentEnrollmentDetailQueryVariables>(
		SchoolStudentEnrollmentDetailDocument,
		options,
	);
}
export function useSchoolStudentEnrollmentDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		SchoolStudentEnrollmentDetailQuery,
		SchoolStudentEnrollmentDetailQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<SchoolStudentEnrollmentDetailQuery, SchoolStudentEnrollmentDetailQueryVariables>(
		SchoolStudentEnrollmentDetailDocument,
		options,
	);
}
export type SchoolStudentEnrollmentDetailQueryHookResult = ReturnType<typeof useSchoolStudentEnrollmentDetailQuery>;
export type SchoolStudentEnrollmentDetailLazyQueryHookResult = ReturnType<
	typeof useSchoolStudentEnrollmentDetailLazyQuery
>;
export type SchoolStudentEnrollmentDetailQueryResult = Apollo.QueryResult<
	SchoolStudentEnrollmentDetailQuery,
	SchoolStudentEnrollmentDetailQueryVariables
>;
export const SchoolStudentEnrollmentIndexDocument = gql`
	query schoolStudentEnrollmentIndex(
		$filters: [SchoolStudentEnrollmentFilterInput!]
		$pagination: Pagination
		$order: SchoolStudentEnrollmentOrder
	) {
		schoolStudentEnrollmentConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					createdDate
					paymentDueDate
					hasPaidFee
					isVoided
					academicYear {
						id
						...AcademicYearName
					}
					classYear {
						id
						...ClassYearName
					}
					student {
						id
						person {
							id
							name
							imageURL
						}
					}
				}
			}
		}
	}
	${AcademicYearNameFragmentDoc}
	${ClassYearNameFragmentDoc}
`;

/**
 * __useSchoolStudentEnrollmentIndexQuery__
 *
 * To run a query within a React component, call `useSchoolStudentEnrollmentIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolStudentEnrollmentIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolStudentEnrollmentIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useSchoolStudentEnrollmentIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<SchoolStudentEnrollmentIndexQuery, SchoolStudentEnrollmentIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<SchoolStudentEnrollmentIndexQuery, SchoolStudentEnrollmentIndexQueryVariables>(
		SchoolStudentEnrollmentIndexDocument,
		options,
	);
}
export function useSchoolStudentEnrollmentIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		SchoolStudentEnrollmentIndexQuery,
		SchoolStudentEnrollmentIndexQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<SchoolStudentEnrollmentIndexQuery, SchoolStudentEnrollmentIndexQueryVariables>(
		SchoolStudentEnrollmentIndexDocument,
		options,
	);
}
export type SchoolStudentEnrollmentIndexQueryHookResult = ReturnType<typeof useSchoolStudentEnrollmentIndexQuery>;
export type SchoolStudentEnrollmentIndexLazyQueryHookResult = ReturnType<
	typeof useSchoolStudentEnrollmentIndexLazyQuery
>;
export type SchoolStudentEnrollmentIndexQueryResult = Apollo.QueryResult<
	SchoolStudentEnrollmentIndexQuery,
	SchoolStudentEnrollmentIndexQueryVariables
>;
export const SchoolStudentEnrollmentNameDocument = gql`
	query schoolStudentEnrollmentName($id: ID!) {
		schoolStudentEnrollment(id: $id) {
			id
			...SchoolStudentEnrollmentName
		}
	}
	${SchoolStudentEnrollmentNameFragmentDoc}
`;

/**
 * __useSchoolStudentEnrollmentNameQuery__
 *
 * To run a query within a React component, call `useSchoolStudentEnrollmentNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolStudentEnrollmentNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolStudentEnrollmentNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSchoolStudentEnrollmentNameQuery(
	baseOptions: Apollo.QueryHookOptions<SchoolStudentEnrollmentNameQuery, SchoolStudentEnrollmentNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<SchoolStudentEnrollmentNameQuery, SchoolStudentEnrollmentNameQueryVariables>(
		SchoolStudentEnrollmentNameDocument,
		options,
	);
}
export function useSchoolStudentEnrollmentNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		SchoolStudentEnrollmentNameQuery,
		SchoolStudentEnrollmentNameQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<SchoolStudentEnrollmentNameQuery, SchoolStudentEnrollmentNameQueryVariables>(
		SchoolStudentEnrollmentNameDocument,
		options,
	);
}
export type SchoolStudentEnrollmentNameQueryHookResult = ReturnType<typeof useSchoolStudentEnrollmentNameQuery>;
export type SchoolStudentEnrollmentNameLazyQueryHookResult = ReturnType<typeof useSchoolStudentEnrollmentNameLazyQuery>;
export type SchoolStudentEnrollmentNameQueryResult = Apollo.QueryResult<
	SchoolStudentEnrollmentNameQuery,
	SchoolStudentEnrollmentNameQueryVariables
>;
export const SchoolStudentEnrollmentNamesDocument = gql`
	query schoolStudentEnrollmentNames(
		$filters: [SchoolStudentEnrollmentFilterInput!]
		$pagination: Pagination
		$order: SchoolStudentEnrollmentOrder
	) {
		schoolStudentEnrollmentConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...SchoolStudentEnrollmentName
				}
			}
		}
	}
	${SchoolStudentEnrollmentNameFragmentDoc}
`;

/**
 * __useSchoolStudentEnrollmentNamesQuery__
 *
 * To run a query within a React component, call `useSchoolStudentEnrollmentNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolStudentEnrollmentNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolStudentEnrollmentNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useSchoolStudentEnrollmentNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<SchoolStudentEnrollmentNamesQuery, SchoolStudentEnrollmentNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<SchoolStudentEnrollmentNamesQuery, SchoolStudentEnrollmentNamesQueryVariables>(
		SchoolStudentEnrollmentNamesDocument,
		options,
	);
}
export function useSchoolStudentEnrollmentNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		SchoolStudentEnrollmentNamesQuery,
		SchoolStudentEnrollmentNamesQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<SchoolStudentEnrollmentNamesQuery, SchoolStudentEnrollmentNamesQueryVariables>(
		SchoolStudentEnrollmentNamesDocument,
		options,
	);
}
export type SchoolStudentEnrollmentNamesQueryHookResult = ReturnType<typeof useSchoolStudentEnrollmentNamesQuery>;
export type SchoolStudentEnrollmentNamesLazyQueryHookResult = ReturnType<
	typeof useSchoolStudentEnrollmentNamesLazyQuery
>;
export type SchoolStudentEnrollmentNamesQueryResult = Apollo.QueryResult<
	SchoolStudentEnrollmentNamesQuery,
	SchoolStudentEnrollmentNamesQueryVariables
>;
export const SchoolStudentEnrollmentPaginationDocument = gql`
	query schoolStudentEnrollmentPagination(
		$filters: [SchoolStudentEnrollmentFilterInput!]
		$order: SchoolStudentEnrollmentOrder
	) {
		schoolStudentEnrollmentConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useSchoolStudentEnrollmentPaginationQuery__
 *
 * To run a query within a React component, call `useSchoolStudentEnrollmentPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolStudentEnrollmentPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolStudentEnrollmentPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useSchoolStudentEnrollmentPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<
		SchoolStudentEnrollmentPaginationQuery,
		SchoolStudentEnrollmentPaginationQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<SchoolStudentEnrollmentPaginationQuery, SchoolStudentEnrollmentPaginationQueryVariables>(
		SchoolStudentEnrollmentPaginationDocument,
		options,
	);
}
export function useSchoolStudentEnrollmentPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		SchoolStudentEnrollmentPaginationQuery,
		SchoolStudentEnrollmentPaginationQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<SchoolStudentEnrollmentPaginationQuery, SchoolStudentEnrollmentPaginationQueryVariables>(
		SchoolStudentEnrollmentPaginationDocument,
		options,
	);
}
export type SchoolStudentEnrollmentPaginationQueryHookResult = ReturnType<
	typeof useSchoolStudentEnrollmentPaginationQuery
>;
export type SchoolStudentEnrollmentPaginationLazyQueryHookResult = ReturnType<
	typeof useSchoolStudentEnrollmentPaginationLazyQuery
>;
export type SchoolStudentEnrollmentPaginationQueryResult = Apollo.QueryResult<
	SchoolStudentEnrollmentPaginationQuery,
	SchoolStudentEnrollmentPaginationQueryVariables
>;
export const SchoolStudentEnrollmentSelectDocument = gql`
	query schoolStudentEnrollmentSelect(
		$filters: [SchoolStudentEnrollmentFilterInput!]
		$order: SchoolStudentEnrollmentOrder
	) {
		schoolStudentEnrollmentConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...SchoolStudentEnrollmentName
				}
			}
		}
	}
	${SchoolStudentEnrollmentNameFragmentDoc}
`;

/**
 * __useSchoolStudentEnrollmentSelectQuery__
 *
 * To run a query within a React component, call `useSchoolStudentEnrollmentSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolStudentEnrollmentSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolStudentEnrollmentSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useSchoolStudentEnrollmentSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<
		SchoolStudentEnrollmentSelectQuery,
		SchoolStudentEnrollmentSelectQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<SchoolStudentEnrollmentSelectQuery, SchoolStudentEnrollmentSelectQueryVariables>(
		SchoolStudentEnrollmentSelectDocument,
		options,
	);
}
export function useSchoolStudentEnrollmentSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		SchoolStudentEnrollmentSelectQuery,
		SchoolStudentEnrollmentSelectQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<SchoolStudentEnrollmentSelectQuery, SchoolStudentEnrollmentSelectQueryVariables>(
		SchoolStudentEnrollmentSelectDocument,
		options,
	);
}
export type SchoolStudentEnrollmentSelectQueryHookResult = ReturnType<typeof useSchoolStudentEnrollmentSelectQuery>;
export type SchoolStudentEnrollmentSelectLazyQueryHookResult = ReturnType<
	typeof useSchoolStudentEnrollmentSelectLazyQuery
>;
export type SchoolStudentEnrollmentSelectQueryResult = Apollo.QueryResult<
	SchoolStudentEnrollmentSelectQuery,
	SchoolStudentEnrollmentSelectQueryVariables
>;
export const SchoolStudentEnrollmentUpdateDocument = gql`
	mutation schoolStudentEnrollmentUpdate($id: ID!, $input: SchoolStudentEnrollmentUpdateInput!) {
		updateSchoolStudentEnrollment(id: $id, input: $input) {
			...SchoolStudentEnrollmentDetail
		}
	}
	${SchoolStudentEnrollmentDetailFragmentDoc}
`;
export type SchoolStudentEnrollmentUpdateMutationFn = Apollo.MutationFunction<
	SchoolStudentEnrollmentUpdateMutation,
	SchoolStudentEnrollmentUpdateMutationVariables
>;

/**
 * __useSchoolStudentEnrollmentUpdateMutation__
 *
 * To run a mutation, you first call `useSchoolStudentEnrollmentUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSchoolStudentEnrollmentUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [schoolStudentEnrollmentUpdateMutation, { data, loading, error }] = useSchoolStudentEnrollmentUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSchoolStudentEnrollmentUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<
		SchoolStudentEnrollmentUpdateMutation,
		SchoolStudentEnrollmentUpdateMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<SchoolStudentEnrollmentUpdateMutation, SchoolStudentEnrollmentUpdateMutationVariables>(
		SchoolStudentEnrollmentUpdateDocument,
		options,
	);
}
export type SchoolStudentEnrollmentUpdateMutationHookResult = ReturnType<
	typeof useSchoolStudentEnrollmentUpdateMutation
>;
export type SchoolStudentEnrollmentUpdateMutationResult = Apollo.MutationResult<SchoolStudentEnrollmentUpdateMutation>;
export type SchoolStudentEnrollmentUpdateMutationOptions = Apollo.BaseMutationOptions<
	SchoolStudentEnrollmentUpdateMutation,
	SchoolStudentEnrollmentUpdateMutationVariables
>;
export const ServiceCountDocument = gql`
	query serviceCount($filters: [ServiceFilterInput!], $pagination: Pagination, $order: ServiceOrder) {
		serviceConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useServiceCountQuery__
 *
 * To run a query within a React component, call `useServiceCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useServiceCountQuery(
	baseOptions?: Apollo.QueryHookOptions<ServiceCountQuery, ServiceCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ServiceCountQuery, ServiceCountQueryVariables>(ServiceCountDocument, options);
}
export function useServiceCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ServiceCountQuery, ServiceCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ServiceCountQuery, ServiceCountQueryVariables>(ServiceCountDocument, options);
}
export type ServiceCountQueryHookResult = ReturnType<typeof useServiceCountQuery>;
export type ServiceCountLazyQueryHookResult = ReturnType<typeof useServiceCountLazyQuery>;
export type ServiceCountQueryResult = Apollo.QueryResult<ServiceCountQuery, ServiceCountQueryVariables>;
export const ServiceCreateDocument = gql`
	mutation serviceCreate($input: ServiceCreateInput!) {
		createService(input: $input) {
			...ServiceDetail
		}
	}
	${ServiceDetailFragmentDoc}
`;
export type ServiceCreateMutationFn = Apollo.MutationFunction<ServiceCreateMutation, ServiceCreateMutationVariables>;

/**
 * __useServiceCreateMutation__
 *
 * To run a mutation, you first call `useServiceCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useServiceCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [serviceCreateMutation, { data, loading, error }] = useServiceCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useServiceCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<ServiceCreateMutation, ServiceCreateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<ServiceCreateMutation, ServiceCreateMutationVariables>(ServiceCreateDocument, options);
}
export type ServiceCreateMutationHookResult = ReturnType<typeof useServiceCreateMutation>;
export type ServiceCreateMutationResult = Apollo.MutationResult<ServiceCreateMutation>;
export type ServiceCreateMutationOptions = Apollo.BaseMutationOptions<
	ServiceCreateMutation,
	ServiceCreateMutationVariables
>;
export const ServiceDeleteDocument = gql`
	mutation serviceDelete($id: ID!) {
		deleteService(id: $id)
	}
`;
export type ServiceDeleteMutationFn = Apollo.MutationFunction<ServiceDeleteMutation, ServiceDeleteMutationVariables>;

/**
 * __useServiceDeleteMutation__
 *
 * To run a mutation, you first call `useServiceDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useServiceDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [serviceDeleteMutation, { data, loading, error }] = useServiceDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useServiceDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<ServiceDeleteMutation, ServiceDeleteMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<ServiceDeleteMutation, ServiceDeleteMutationVariables>(ServiceDeleteDocument, options);
}
export type ServiceDeleteMutationHookResult = ReturnType<typeof useServiceDeleteMutation>;
export type ServiceDeleteMutationResult = Apollo.MutationResult<ServiceDeleteMutation>;
export type ServiceDeleteMutationOptions = Apollo.BaseMutationOptions<
	ServiceDeleteMutation,
	ServiceDeleteMutationVariables
>;
export const ServiceDetailDocument = gql`
	query serviceDetail($id: ID!) {
		service(id: $id) {
			...ServiceDetail
		}
	}
	${ServiceDetailFragmentDoc}
`;

/**
 * __useServiceDetailQuery__
 *
 * To run a query within a React component, call `useServiceDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useServiceDetailQuery(
	baseOptions: Apollo.QueryHookOptions<ServiceDetailQuery, ServiceDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ServiceDetailQuery, ServiceDetailQueryVariables>(ServiceDetailDocument, options);
}
export function useServiceDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ServiceDetailQuery, ServiceDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ServiceDetailQuery, ServiceDetailQueryVariables>(ServiceDetailDocument, options);
}
export type ServiceDetailQueryHookResult = ReturnType<typeof useServiceDetailQuery>;
export type ServiceDetailLazyQueryHookResult = ReturnType<typeof useServiceDetailLazyQuery>;
export type ServiceDetailQueryResult = Apollo.QueryResult<ServiceDetailQuery, ServiceDetailQueryVariables>;
export const ServiceIndexDocument = gql`
	query serviceIndex($filters: [ServiceFilterInput!], $pagination: Pagination, $order: ServiceOrder) {
		serviceConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					name
					description
					price
				}
			}
		}
	}
`;

/**
 * __useServiceIndexQuery__
 *
 * To run a query within a React component, call `useServiceIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useServiceIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<ServiceIndexQuery, ServiceIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ServiceIndexQuery, ServiceIndexQueryVariables>(ServiceIndexDocument, options);
}
export function useServiceIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ServiceIndexQuery, ServiceIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ServiceIndexQuery, ServiceIndexQueryVariables>(ServiceIndexDocument, options);
}
export type ServiceIndexQueryHookResult = ReturnType<typeof useServiceIndexQuery>;
export type ServiceIndexLazyQueryHookResult = ReturnType<typeof useServiceIndexLazyQuery>;
export type ServiceIndexQueryResult = Apollo.QueryResult<ServiceIndexQuery, ServiceIndexQueryVariables>;
export const ServiceNameDocument = gql`
	query serviceName($id: ID!) {
		service(id: $id) {
			id
			...ServiceName
		}
	}
	${ServiceNameFragmentDoc}
`;

/**
 * __useServiceNameQuery__
 *
 * To run a query within a React component, call `useServiceNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useServiceNameQuery(baseOptions: Apollo.QueryHookOptions<ServiceNameQuery, ServiceNameQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ServiceNameQuery, ServiceNameQueryVariables>(ServiceNameDocument, options);
}
export function useServiceNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ServiceNameQuery, ServiceNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ServiceNameQuery, ServiceNameQueryVariables>(ServiceNameDocument, options);
}
export type ServiceNameQueryHookResult = ReturnType<typeof useServiceNameQuery>;
export type ServiceNameLazyQueryHookResult = ReturnType<typeof useServiceNameLazyQuery>;
export type ServiceNameQueryResult = Apollo.QueryResult<ServiceNameQuery, ServiceNameQueryVariables>;
export const ServiceNamesDocument = gql`
	query serviceNames($filters: [ServiceFilterInput!], $pagination: Pagination, $order: ServiceOrder) {
		serviceConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...ServiceName
				}
			}
		}
	}
	${ServiceNameFragmentDoc}
`;

/**
 * __useServiceNamesQuery__
 *
 * To run a query within a React component, call `useServiceNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useServiceNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<ServiceNamesQuery, ServiceNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ServiceNamesQuery, ServiceNamesQueryVariables>(ServiceNamesDocument, options);
}
export function useServiceNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ServiceNamesQuery, ServiceNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ServiceNamesQuery, ServiceNamesQueryVariables>(ServiceNamesDocument, options);
}
export type ServiceNamesQueryHookResult = ReturnType<typeof useServiceNamesQuery>;
export type ServiceNamesLazyQueryHookResult = ReturnType<typeof useServiceNamesLazyQuery>;
export type ServiceNamesQueryResult = Apollo.QueryResult<ServiceNamesQuery, ServiceNamesQueryVariables>;
export const ServicePaginationDocument = gql`
	query servicePagination($filters: [ServiceFilterInput!], $order: ServiceOrder) {
		serviceConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useServicePaginationQuery__
 *
 * To run a query within a React component, call `useServicePaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useServicePaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServicePaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useServicePaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<ServicePaginationQuery, ServicePaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ServicePaginationQuery, ServicePaginationQueryVariables>(ServicePaginationDocument, options);
}
export function useServicePaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ServicePaginationQuery, ServicePaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ServicePaginationQuery, ServicePaginationQueryVariables>(
		ServicePaginationDocument,
		options,
	);
}
export type ServicePaginationQueryHookResult = ReturnType<typeof useServicePaginationQuery>;
export type ServicePaginationLazyQueryHookResult = ReturnType<typeof useServicePaginationLazyQuery>;
export type ServicePaginationQueryResult = Apollo.QueryResult<ServicePaginationQuery, ServicePaginationQueryVariables>;
export const ServiceSelectDocument = gql`
	query serviceSelect($filters: [ServiceFilterInput!], $order: ServiceOrder) {
		serviceConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...ServiceName
					price
				}
			}
		}
	}
	${ServiceNameFragmentDoc}
`;

/**
 * __useServiceSelectQuery__
 *
 * To run a query within a React component, call `useServiceSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useServiceSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<ServiceSelectQuery, ServiceSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ServiceSelectQuery, ServiceSelectQueryVariables>(ServiceSelectDocument, options);
}
export function useServiceSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ServiceSelectQuery, ServiceSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ServiceSelectQuery, ServiceSelectQueryVariables>(ServiceSelectDocument, options);
}
export type ServiceSelectQueryHookResult = ReturnType<typeof useServiceSelectQuery>;
export type ServiceSelectLazyQueryHookResult = ReturnType<typeof useServiceSelectLazyQuery>;
export type ServiceSelectQueryResult = Apollo.QueryResult<ServiceSelectQuery, ServiceSelectQueryVariables>;
export const ServiceUpdateDocument = gql`
	mutation serviceUpdate($id: ID!, $input: ServiceUpdateInput!) {
		updateService(id: $id, input: $input) {
			...ServiceDetail
		}
	}
	${ServiceDetailFragmentDoc}
`;
export type ServiceUpdateMutationFn = Apollo.MutationFunction<ServiceUpdateMutation, ServiceUpdateMutationVariables>;

/**
 * __useServiceUpdateMutation__
 *
 * To run a mutation, you first call `useServiceUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useServiceUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [serviceUpdateMutation, { data, loading, error }] = useServiceUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useServiceUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<ServiceUpdateMutation, ServiceUpdateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<ServiceUpdateMutation, ServiceUpdateMutationVariables>(ServiceUpdateDocument, options);
}
export type ServiceUpdateMutationHookResult = ReturnType<typeof useServiceUpdateMutation>;
export type ServiceUpdateMutationResult = Apollo.MutationResult<ServiceUpdateMutation>;
export type ServiceUpdateMutationOptions = Apollo.BaseMutationOptions<
	ServiceUpdateMutation,
	ServiceUpdateMutationVariables
>;
export const ServiceSubscriptionCountDocument = gql`
	query serviceSubscriptionCount(
		$filters: [ServiceSubscriptionFilterInput!]
		$pagination: Pagination
		$order: ServiceSubscriptionOrder
	) {
		serviceSubscriptionConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useServiceSubscriptionCountQuery__
 *
 * To run a query within a React component, call `useServiceSubscriptionCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceSubscriptionCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceSubscriptionCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useServiceSubscriptionCountQuery(
	baseOptions?: Apollo.QueryHookOptions<ServiceSubscriptionCountQuery, ServiceSubscriptionCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ServiceSubscriptionCountQuery, ServiceSubscriptionCountQueryVariables>(
		ServiceSubscriptionCountDocument,
		options,
	);
}
export function useServiceSubscriptionCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ServiceSubscriptionCountQuery, ServiceSubscriptionCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ServiceSubscriptionCountQuery, ServiceSubscriptionCountQueryVariables>(
		ServiceSubscriptionCountDocument,
		options,
	);
}
export type ServiceSubscriptionCountQueryHookResult = ReturnType<typeof useServiceSubscriptionCountQuery>;
export type ServiceSubscriptionCountLazyQueryHookResult = ReturnType<typeof useServiceSubscriptionCountLazyQuery>;
export type ServiceSubscriptionCountQueryResult = Apollo.QueryResult<
	ServiceSubscriptionCountQuery,
	ServiceSubscriptionCountQueryVariables
>;
export const ServiceSubscriptionsCreateBulkDocument = gql`
	mutation serviceSubscriptionsCreateBulk($inputs: [ServiceSubscriptionCreateInput!]!) {
		createBulkServiceSubscriptions(inputs: $inputs) {
			edges {
				node {
					...ServiceSubscriptionDetail
				}
			}
		}
	}
	${ServiceSubscriptionDetailFragmentDoc}
`;
export type ServiceSubscriptionsCreateBulkMutationFn = Apollo.MutationFunction<
	ServiceSubscriptionsCreateBulkMutation,
	ServiceSubscriptionsCreateBulkMutationVariables
>;

/**
 * __useServiceSubscriptionsCreateBulkMutation__
 *
 * To run a mutation, you first call `useServiceSubscriptionsCreateBulkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useServiceSubscriptionsCreateBulkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [serviceSubscriptionsCreateBulkMutation, { data, loading, error }] = useServiceSubscriptionsCreateBulkMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useServiceSubscriptionsCreateBulkMutation(
	baseOptions?: Apollo.MutationHookOptions<
		ServiceSubscriptionsCreateBulkMutation,
		ServiceSubscriptionsCreateBulkMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<ServiceSubscriptionsCreateBulkMutation, ServiceSubscriptionsCreateBulkMutationVariables>(
		ServiceSubscriptionsCreateBulkDocument,
		options,
	);
}
export type ServiceSubscriptionsCreateBulkMutationHookResult = ReturnType<
	typeof useServiceSubscriptionsCreateBulkMutation
>;
export type ServiceSubscriptionsCreateBulkMutationResult =
	Apollo.MutationResult<ServiceSubscriptionsCreateBulkMutation>;
export type ServiceSubscriptionsCreateBulkMutationOptions = Apollo.BaseMutationOptions<
	ServiceSubscriptionsCreateBulkMutation,
	ServiceSubscriptionsCreateBulkMutationVariables
>;
export const ServiceSubscriptionCreateDocument = gql`
	mutation serviceSubscriptionCreate($input: ServiceSubscriptionCreateInput!) {
		createServiceSubscription(input: $input) {
			...ServiceSubscriptionDetail
		}
	}
	${ServiceSubscriptionDetailFragmentDoc}
`;
export type ServiceSubscriptionCreateMutationFn = Apollo.MutationFunction<
	ServiceSubscriptionCreateMutation,
	ServiceSubscriptionCreateMutationVariables
>;

/**
 * __useServiceSubscriptionCreateMutation__
 *
 * To run a mutation, you first call `useServiceSubscriptionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useServiceSubscriptionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [serviceSubscriptionCreateMutation, { data, loading, error }] = useServiceSubscriptionCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useServiceSubscriptionCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<
		ServiceSubscriptionCreateMutation,
		ServiceSubscriptionCreateMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<ServiceSubscriptionCreateMutation, ServiceSubscriptionCreateMutationVariables>(
		ServiceSubscriptionCreateDocument,
		options,
	);
}
export type ServiceSubscriptionCreateMutationHookResult = ReturnType<typeof useServiceSubscriptionCreateMutation>;
export type ServiceSubscriptionCreateMutationResult = Apollo.MutationResult<ServiceSubscriptionCreateMutation>;
export type ServiceSubscriptionCreateMutationOptions = Apollo.BaseMutationOptions<
	ServiceSubscriptionCreateMutation,
	ServiceSubscriptionCreateMutationVariables
>;
export const ServiceSubscriptionDeleteDocument = gql`
	mutation serviceSubscriptionDelete($id: ID!) {
		deleteServiceSubscription(id: $id)
	}
`;
export type ServiceSubscriptionDeleteMutationFn = Apollo.MutationFunction<
	ServiceSubscriptionDeleteMutation,
	ServiceSubscriptionDeleteMutationVariables
>;

/**
 * __useServiceSubscriptionDeleteMutation__
 *
 * To run a mutation, you first call `useServiceSubscriptionDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useServiceSubscriptionDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [serviceSubscriptionDeleteMutation, { data, loading, error }] = useServiceSubscriptionDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useServiceSubscriptionDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<
		ServiceSubscriptionDeleteMutation,
		ServiceSubscriptionDeleteMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<ServiceSubscriptionDeleteMutation, ServiceSubscriptionDeleteMutationVariables>(
		ServiceSubscriptionDeleteDocument,
		options,
	);
}
export type ServiceSubscriptionDeleteMutationHookResult = ReturnType<typeof useServiceSubscriptionDeleteMutation>;
export type ServiceSubscriptionDeleteMutationResult = Apollo.MutationResult<ServiceSubscriptionDeleteMutation>;
export type ServiceSubscriptionDeleteMutationOptions = Apollo.BaseMutationOptions<
	ServiceSubscriptionDeleteMutation,
	ServiceSubscriptionDeleteMutationVariables
>;
export const ServiceSubscriptionDetailDocument = gql`
	query serviceSubscriptionDetail($id: ID!) {
		serviceSubscription(id: $id) {
			...ServiceSubscriptionDetail
		}
	}
	${ServiceSubscriptionDetailFragmentDoc}
`;

/**
 * __useServiceSubscriptionDetailQuery__
 *
 * To run a query within a React component, call `useServiceSubscriptionDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceSubscriptionDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceSubscriptionDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useServiceSubscriptionDetailQuery(
	baseOptions: Apollo.QueryHookOptions<ServiceSubscriptionDetailQuery, ServiceSubscriptionDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ServiceSubscriptionDetailQuery, ServiceSubscriptionDetailQueryVariables>(
		ServiceSubscriptionDetailDocument,
		options,
	);
}
export function useServiceSubscriptionDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ServiceSubscriptionDetailQuery, ServiceSubscriptionDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ServiceSubscriptionDetailQuery, ServiceSubscriptionDetailQueryVariables>(
		ServiceSubscriptionDetailDocument,
		options,
	);
}
export type ServiceSubscriptionDetailQueryHookResult = ReturnType<typeof useServiceSubscriptionDetailQuery>;
export type ServiceSubscriptionDetailLazyQueryHookResult = ReturnType<typeof useServiceSubscriptionDetailLazyQuery>;
export type ServiceSubscriptionDetailQueryResult = Apollo.QueryResult<
	ServiceSubscriptionDetailQuery,
	ServiceSubscriptionDetailQueryVariables
>;
export const ServiceSubscriptionIndexDocument = gql`
	query serviceSubscriptionIndex(
		$filters: [ServiceSubscriptionFilterInput!]
		$pagination: Pagination
		$order: ServiceSubscriptionOrder
	) {
		serviceSubscriptionConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					price
					service {
						id
						name
					}
				}
			}
		}
	}
`;

/**
 * __useServiceSubscriptionIndexQuery__
 *
 * To run a query within a React component, call `useServiceSubscriptionIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceSubscriptionIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceSubscriptionIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useServiceSubscriptionIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<ServiceSubscriptionIndexQuery, ServiceSubscriptionIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ServiceSubscriptionIndexQuery, ServiceSubscriptionIndexQueryVariables>(
		ServiceSubscriptionIndexDocument,
		options,
	);
}
export function useServiceSubscriptionIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ServiceSubscriptionIndexQuery, ServiceSubscriptionIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ServiceSubscriptionIndexQuery, ServiceSubscriptionIndexQueryVariables>(
		ServiceSubscriptionIndexDocument,
		options,
	);
}
export type ServiceSubscriptionIndexQueryHookResult = ReturnType<typeof useServiceSubscriptionIndexQuery>;
export type ServiceSubscriptionIndexLazyQueryHookResult = ReturnType<typeof useServiceSubscriptionIndexLazyQuery>;
export type ServiceSubscriptionIndexQueryResult = Apollo.QueryResult<
	ServiceSubscriptionIndexQuery,
	ServiceSubscriptionIndexQueryVariables
>;
export const ServiceSubscriptionNameDocument = gql`
	query serviceSubscriptionName($id: ID!) {
		serviceSubscription(id: $id) {
			id
			...ServiceSubscriptionName
		}
	}
	${ServiceSubscriptionNameFragmentDoc}
`;

/**
 * __useServiceSubscriptionNameQuery__
 *
 * To run a query within a React component, call `useServiceSubscriptionNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceSubscriptionNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceSubscriptionNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useServiceSubscriptionNameQuery(
	baseOptions: Apollo.QueryHookOptions<ServiceSubscriptionNameQuery, ServiceSubscriptionNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ServiceSubscriptionNameQuery, ServiceSubscriptionNameQueryVariables>(
		ServiceSubscriptionNameDocument,
		options,
	);
}
export function useServiceSubscriptionNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ServiceSubscriptionNameQuery, ServiceSubscriptionNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ServiceSubscriptionNameQuery, ServiceSubscriptionNameQueryVariables>(
		ServiceSubscriptionNameDocument,
		options,
	);
}
export type ServiceSubscriptionNameQueryHookResult = ReturnType<typeof useServiceSubscriptionNameQuery>;
export type ServiceSubscriptionNameLazyQueryHookResult = ReturnType<typeof useServiceSubscriptionNameLazyQuery>;
export type ServiceSubscriptionNameQueryResult = Apollo.QueryResult<
	ServiceSubscriptionNameQuery,
	ServiceSubscriptionNameQueryVariables
>;
export const ServiceSubscriptionNamesDocument = gql`
	query serviceSubscriptionNames(
		$filters: [ServiceSubscriptionFilterInput!]
		$pagination: Pagination
		$order: ServiceSubscriptionOrder
	) {
		serviceSubscriptionConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...ServiceSubscriptionName
				}
			}
		}
	}
	${ServiceSubscriptionNameFragmentDoc}
`;

/**
 * __useServiceSubscriptionNamesQuery__
 *
 * To run a query within a React component, call `useServiceSubscriptionNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceSubscriptionNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceSubscriptionNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useServiceSubscriptionNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<ServiceSubscriptionNamesQuery, ServiceSubscriptionNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ServiceSubscriptionNamesQuery, ServiceSubscriptionNamesQueryVariables>(
		ServiceSubscriptionNamesDocument,
		options,
	);
}
export function useServiceSubscriptionNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ServiceSubscriptionNamesQuery, ServiceSubscriptionNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ServiceSubscriptionNamesQuery, ServiceSubscriptionNamesQueryVariables>(
		ServiceSubscriptionNamesDocument,
		options,
	);
}
export type ServiceSubscriptionNamesQueryHookResult = ReturnType<typeof useServiceSubscriptionNamesQuery>;
export type ServiceSubscriptionNamesLazyQueryHookResult = ReturnType<typeof useServiceSubscriptionNamesLazyQuery>;
export type ServiceSubscriptionNamesQueryResult = Apollo.QueryResult<
	ServiceSubscriptionNamesQuery,
	ServiceSubscriptionNamesQueryVariables
>;
export const ServiceSubscriptionPaginationDocument = gql`
	query serviceSubscriptionPagination($filters: [ServiceSubscriptionFilterInput!], $order: ServiceSubscriptionOrder) {
		serviceSubscriptionConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useServiceSubscriptionPaginationQuery__
 *
 * To run a query within a React component, call `useServiceSubscriptionPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceSubscriptionPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceSubscriptionPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useServiceSubscriptionPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<
		ServiceSubscriptionPaginationQuery,
		ServiceSubscriptionPaginationQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ServiceSubscriptionPaginationQuery, ServiceSubscriptionPaginationQueryVariables>(
		ServiceSubscriptionPaginationDocument,
		options,
	);
}
export function useServiceSubscriptionPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ServiceSubscriptionPaginationQuery,
		ServiceSubscriptionPaginationQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ServiceSubscriptionPaginationQuery, ServiceSubscriptionPaginationQueryVariables>(
		ServiceSubscriptionPaginationDocument,
		options,
	);
}
export type ServiceSubscriptionPaginationQueryHookResult = ReturnType<typeof useServiceSubscriptionPaginationQuery>;
export type ServiceSubscriptionPaginationLazyQueryHookResult = ReturnType<
	typeof useServiceSubscriptionPaginationLazyQuery
>;
export type ServiceSubscriptionPaginationQueryResult = Apollo.QueryResult<
	ServiceSubscriptionPaginationQuery,
	ServiceSubscriptionPaginationQueryVariables
>;
export const ServiceSubscriptionSelectDocument = gql`
	query serviceSubscriptionSelect($filters: [ServiceSubscriptionFilterInput!], $order: ServiceSubscriptionOrder) {
		serviceSubscriptionConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...ServiceSubscriptionName
				}
			}
		}
	}
	${ServiceSubscriptionNameFragmentDoc}
`;

/**
 * __useServiceSubscriptionSelectQuery__
 *
 * To run a query within a React component, call `useServiceSubscriptionSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceSubscriptionSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceSubscriptionSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useServiceSubscriptionSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<ServiceSubscriptionSelectQuery, ServiceSubscriptionSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ServiceSubscriptionSelectQuery, ServiceSubscriptionSelectQueryVariables>(
		ServiceSubscriptionSelectDocument,
		options,
	);
}
export function useServiceSubscriptionSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ServiceSubscriptionSelectQuery, ServiceSubscriptionSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ServiceSubscriptionSelectQuery, ServiceSubscriptionSelectQueryVariables>(
		ServiceSubscriptionSelectDocument,
		options,
	);
}
export type ServiceSubscriptionSelectQueryHookResult = ReturnType<typeof useServiceSubscriptionSelectQuery>;
export type ServiceSubscriptionSelectLazyQueryHookResult = ReturnType<typeof useServiceSubscriptionSelectLazyQuery>;
export type ServiceSubscriptionSelectQueryResult = Apollo.QueryResult<
	ServiceSubscriptionSelectQuery,
	ServiceSubscriptionSelectQueryVariables
>;
export const ServiceSubscriptionUpdateDocument = gql`
	mutation serviceSubscriptionUpdate($id: ID!, $input: ServiceSubscriptionUpdateInput!) {
		updateServiceSubscription(id: $id, input: $input) {
			...ServiceSubscriptionDetail
		}
	}
	${ServiceSubscriptionDetailFragmentDoc}
`;
export type ServiceSubscriptionUpdateMutationFn = Apollo.MutationFunction<
	ServiceSubscriptionUpdateMutation,
	ServiceSubscriptionUpdateMutationVariables
>;

/**
 * __useServiceSubscriptionUpdateMutation__
 *
 * To run a mutation, you first call `useServiceSubscriptionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useServiceSubscriptionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [serviceSubscriptionUpdateMutation, { data, loading, error }] = useServiceSubscriptionUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useServiceSubscriptionUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<
		ServiceSubscriptionUpdateMutation,
		ServiceSubscriptionUpdateMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<ServiceSubscriptionUpdateMutation, ServiceSubscriptionUpdateMutationVariables>(
		ServiceSubscriptionUpdateDocument,
		options,
	);
}
export type ServiceSubscriptionUpdateMutationHookResult = ReturnType<typeof useServiceSubscriptionUpdateMutation>;
export type ServiceSubscriptionUpdateMutationResult = Apollo.MutationResult<ServiceSubscriptionUpdateMutation>;
export type ServiceSubscriptionUpdateMutationOptions = Apollo.BaseMutationOptions<
	ServiceSubscriptionUpdateMutation,
	ServiceSubscriptionUpdateMutationVariables
>;
export const SessionCountDocument = gql`
	query sessionCount($filters: [SessionFilterInput!], $pagination: Pagination, $order: SessionOrder) {
		sessionConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useSessionCountQuery__
 *
 * To run a query within a React component, call `useSessionCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useSessionCountQuery(
	baseOptions?: Apollo.QueryHookOptions<SessionCountQuery, SessionCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<SessionCountQuery, SessionCountQueryVariables>(SessionCountDocument, options);
}
export function useSessionCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<SessionCountQuery, SessionCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<SessionCountQuery, SessionCountQueryVariables>(SessionCountDocument, options);
}
export type SessionCountQueryHookResult = ReturnType<typeof useSessionCountQuery>;
export type SessionCountLazyQueryHookResult = ReturnType<typeof useSessionCountLazyQuery>;
export type SessionCountQueryResult = Apollo.QueryResult<SessionCountQuery, SessionCountQueryVariables>;
export const SessionDetailDocument = gql`
	query sessionDetail($id: ID!) {
		session(id: $id) {
			...SessionDetail
		}
	}
	${SessionDetailFragmentDoc}
`;

/**
 * __useSessionDetailQuery__
 *
 * To run a query within a React component, call `useSessionDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSessionDetailQuery(
	baseOptions: Apollo.QueryHookOptions<SessionDetailQuery, SessionDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<SessionDetailQuery, SessionDetailQueryVariables>(SessionDetailDocument, options);
}
export function useSessionDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<SessionDetailQuery, SessionDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<SessionDetailQuery, SessionDetailQueryVariables>(SessionDetailDocument, options);
}
export type SessionDetailQueryHookResult = ReturnType<typeof useSessionDetailQuery>;
export type SessionDetailLazyQueryHookResult = ReturnType<typeof useSessionDetailLazyQuery>;
export type SessionDetailQueryResult = Apollo.QueryResult<SessionDetailQuery, SessionDetailQueryVariables>;
export const SessionIndexDocument = gql`
	query sessionIndex($filters: [SessionFilterInput!], $pagination: Pagination, $order: SessionOrder) {
		sessionConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					createdAt
					updatedAt
					expiresAt
					longestInactiveSec
					isVerified
					isCurrent
				}
			}
		}
	}
`;

/**
 * __useSessionIndexQuery__
 *
 * To run a query within a React component, call `useSessionIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useSessionIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<SessionIndexQuery, SessionIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<SessionIndexQuery, SessionIndexQueryVariables>(SessionIndexDocument, options);
}
export function useSessionIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<SessionIndexQuery, SessionIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<SessionIndexQuery, SessionIndexQueryVariables>(SessionIndexDocument, options);
}
export type SessionIndexQueryHookResult = ReturnType<typeof useSessionIndexQuery>;
export type SessionIndexLazyQueryHookResult = ReturnType<typeof useSessionIndexLazyQuery>;
export type SessionIndexQueryResult = Apollo.QueryResult<SessionIndexQuery, SessionIndexQueryVariables>;
export const SessionNameDocument = gql`
	query sessionName($id: ID!) {
		session(id: $id) {
			id
			...SessionName
		}
	}
	${SessionNameFragmentDoc}
`;

/**
 * __useSessionNameQuery__
 *
 * To run a query within a React component, call `useSessionNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSessionNameQuery(baseOptions: Apollo.QueryHookOptions<SessionNameQuery, SessionNameQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<SessionNameQuery, SessionNameQueryVariables>(SessionNameDocument, options);
}
export function useSessionNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<SessionNameQuery, SessionNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<SessionNameQuery, SessionNameQueryVariables>(SessionNameDocument, options);
}
export type SessionNameQueryHookResult = ReturnType<typeof useSessionNameQuery>;
export type SessionNameLazyQueryHookResult = ReturnType<typeof useSessionNameLazyQuery>;
export type SessionNameQueryResult = Apollo.QueryResult<SessionNameQuery, SessionNameQueryVariables>;
export const SessionNamesDocument = gql`
	query sessionNames($filters: [SessionFilterInput!], $pagination: Pagination, $order: SessionOrder) {
		sessionConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...SessionName
				}
			}
		}
	}
	${SessionNameFragmentDoc}
`;

/**
 * __useSessionNamesQuery__
 *
 * To run a query within a React component, call `useSessionNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useSessionNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<SessionNamesQuery, SessionNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<SessionNamesQuery, SessionNamesQueryVariables>(SessionNamesDocument, options);
}
export function useSessionNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<SessionNamesQuery, SessionNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<SessionNamesQuery, SessionNamesQueryVariables>(SessionNamesDocument, options);
}
export type SessionNamesQueryHookResult = ReturnType<typeof useSessionNamesQuery>;
export type SessionNamesLazyQueryHookResult = ReturnType<typeof useSessionNamesLazyQuery>;
export type SessionNamesQueryResult = Apollo.QueryResult<SessionNamesQuery, SessionNamesQueryVariables>;
export const SessionPaginationDocument = gql`
	query sessionPagination($filters: [SessionFilterInput!], $order: SessionOrder) {
		sessionConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useSessionPaginationQuery__
 *
 * To run a query within a React component, call `useSessionPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useSessionPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<SessionPaginationQuery, SessionPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<SessionPaginationQuery, SessionPaginationQueryVariables>(SessionPaginationDocument, options);
}
export function useSessionPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<SessionPaginationQuery, SessionPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<SessionPaginationQuery, SessionPaginationQueryVariables>(
		SessionPaginationDocument,
		options,
	);
}
export type SessionPaginationQueryHookResult = ReturnType<typeof useSessionPaginationQuery>;
export type SessionPaginationLazyQueryHookResult = ReturnType<typeof useSessionPaginationLazyQuery>;
export type SessionPaginationQueryResult = Apollo.QueryResult<SessionPaginationQuery, SessionPaginationQueryVariables>;
export const SessionSelectDocument = gql`
	query sessionSelect($filters: [SessionFilterInput!], $order: SessionOrder) {
		sessionConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...SessionName
				}
			}
		}
	}
	${SessionNameFragmentDoc}
`;

/**
 * __useSessionSelectQuery__
 *
 * To run a query within a React component, call `useSessionSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useSessionSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<SessionSelectQuery, SessionSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<SessionSelectQuery, SessionSelectQueryVariables>(SessionSelectDocument, options);
}
export function useSessionSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<SessionSelectQuery, SessionSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<SessionSelectQuery, SessionSelectQueryVariables>(SessionSelectDocument, options);
}
export type SessionSelectQueryHookResult = ReturnType<typeof useSessionSelectQuery>;
export type SessionSelectLazyQueryHookResult = ReturnType<typeof useSessionSelectLazyQuery>;
export type SessionSelectQueryResult = Apollo.QueryResult<SessionSelectQuery, SessionSelectQueryVariables>;
export const StudentCountDocument = gql`
	query studentCount($filters: [StudentFilterInput!], $pagination: Pagination, $order: StudentOrder) {
		studentConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useStudentCountQuery__
 *
 * To run a query within a React component, call `useStudentCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useStudentCountQuery(
	baseOptions?: Apollo.QueryHookOptions<StudentCountQuery, StudentCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<StudentCountQuery, StudentCountQueryVariables>(StudentCountDocument, options);
}
export function useStudentCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<StudentCountQuery, StudentCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<StudentCountQuery, StudentCountQueryVariables>(StudentCountDocument, options);
}
export type StudentCountQueryHookResult = ReturnType<typeof useStudentCountQuery>;
export type StudentCountLazyQueryHookResult = ReturnType<typeof useStudentCountLazyQuery>;
export type StudentCountQueryResult = Apollo.QueryResult<StudentCountQuery, StudentCountQueryVariables>;
export const StudentCreateDocument = gql`
	mutation studentCreate($input: StudentCreateInput!) {
		createStudent(input: $input) {
			...StudentDetail
		}
	}
	${StudentDetailFragmentDoc}
`;
export type StudentCreateMutationFn = Apollo.MutationFunction<StudentCreateMutation, StudentCreateMutationVariables>;

/**
 * __useStudentCreateMutation__
 *
 * To run a mutation, you first call `useStudentCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStudentCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [studentCreateMutation, { data, loading, error }] = useStudentCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStudentCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<StudentCreateMutation, StudentCreateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<StudentCreateMutation, StudentCreateMutationVariables>(StudentCreateDocument, options);
}
export type StudentCreateMutationHookResult = ReturnType<typeof useStudentCreateMutation>;
export type StudentCreateMutationResult = Apollo.MutationResult<StudentCreateMutation>;
export type StudentCreateMutationOptions = Apollo.BaseMutationOptions<
	StudentCreateMutation,
	StudentCreateMutationVariables
>;
export const StudentDeleteDocument = gql`
	mutation studentDelete($id: ID!) {
		deleteStudent(id: $id)
	}
`;
export type StudentDeleteMutationFn = Apollo.MutationFunction<StudentDeleteMutation, StudentDeleteMutationVariables>;

/**
 * __useStudentDeleteMutation__
 *
 * To run a mutation, you first call `useStudentDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStudentDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [studentDeleteMutation, { data, loading, error }] = useStudentDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStudentDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<StudentDeleteMutation, StudentDeleteMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<StudentDeleteMutation, StudentDeleteMutationVariables>(StudentDeleteDocument, options);
}
export type StudentDeleteMutationHookResult = ReturnType<typeof useStudentDeleteMutation>;
export type StudentDeleteMutationResult = Apollo.MutationResult<StudentDeleteMutation>;
export type StudentDeleteMutationOptions = Apollo.BaseMutationOptions<
	StudentDeleteMutation,
	StudentDeleteMutationVariables
>;
export const StudentDetailDocument = gql`
	query studentDetail($id: ID!) {
		student(id: $id) {
			...StudentDetail
		}
	}
	${StudentDetailFragmentDoc}
`;

/**
 * __useStudentDetailQuery__
 *
 * To run a query within a React component, call `useStudentDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStudentDetailQuery(
	baseOptions: Apollo.QueryHookOptions<StudentDetailQuery, StudentDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<StudentDetailQuery, StudentDetailQueryVariables>(StudentDetailDocument, options);
}
export function useStudentDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<StudentDetailQuery, StudentDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<StudentDetailQuery, StudentDetailQueryVariables>(StudentDetailDocument, options);
}
export type StudentDetailQueryHookResult = ReturnType<typeof useStudentDetailQuery>;
export type StudentDetailLazyQueryHookResult = ReturnType<typeof useStudentDetailLazyQuery>;
export type StudentDetailQueryResult = Apollo.QueryResult<StudentDetailQuery, StudentDetailQueryVariables>;
export const StudentIndexDocument = gql`
	query studentIndex($filters: [StudentFilterInput!], $pagination: Pagination, $order: StudentOrder) {
		studentConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					person {
						id
						name
						imageURL
						dateOfBirth
					}
					graduatingYear
					homeRoomSectionStudentEnrollments {
						id
						homeRoomSection {
							id
							name
							homeRoom {
								id
								name
							}
						}
					}
					busPlans {
						id
					}
					mealPlans {
						id
					}
					relationships(order: { field: IS_PRIMARY, direction: DESC }) {
						id
						relationshipType
						parent {
							id
							person {
								id
								name
								phoneNumbers {
									id
									phoneNumber
								}
								emailAddresses {
									id
									email
								}
							}
						}
					}
					hasPreviousSchooling
					previousSchoolInformation
					previousSchoolLocation
					allergies
					asthma
					medication
					medicationSchedule
					dietaryRestrictions
					healthNotes
					doctorName
					doctorPhoneNumber
					emergencyContactInformation
				}
			}
		}
	}
`;

/**
 * __useStudentIndexQuery__
 *
 * To run a query within a React component, call `useStudentIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useStudentIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<StudentIndexQuery, StudentIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<StudentIndexQuery, StudentIndexQueryVariables>(StudentIndexDocument, options);
}
export function useStudentIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<StudentIndexQuery, StudentIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<StudentIndexQuery, StudentIndexQueryVariables>(StudentIndexDocument, options);
}
export type StudentIndexQueryHookResult = ReturnType<typeof useStudentIndexQuery>;
export type StudentIndexLazyQueryHookResult = ReturnType<typeof useStudentIndexLazyQuery>;
export type StudentIndexQueryResult = Apollo.QueryResult<StudentIndexQuery, StudentIndexQueryVariables>;
export const StudentListDocument = gql`
	query studentList($filters: [StudentFilterInput!], $pagination: Pagination, $order: StudentOrder) {
		studentConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					person {
						id
						name
						imageURL
					}
					homeRoomSectionStudentEnrollments {
						id
						homeRoomSection {
							id
							name
							homeRoom {
								id
								name
							}
						}
					}
				}
			}
		}
	}
`;

/**
 * __useStudentListQuery__
 *
 * To run a query within a React component, call `useStudentListQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentListQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useStudentListQuery(
	baseOptions?: Apollo.QueryHookOptions<StudentListQuery, StudentListQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<StudentListQuery, StudentListQueryVariables>(StudentListDocument, options);
}
export function useStudentListLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<StudentListQuery, StudentListQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<StudentListQuery, StudentListQueryVariables>(StudentListDocument, options);
}
export type StudentListQueryHookResult = ReturnType<typeof useStudentListQuery>;
export type StudentListLazyQueryHookResult = ReturnType<typeof useStudentListLazyQuery>;
export type StudentListQueryResult = Apollo.QueryResult<StudentListQuery, StudentListQueryVariables>;
export const StudentNameDocument = gql`
	query studentName($id: ID!) {
		student(id: $id) {
			id
			...StudentName
		}
	}
	${StudentNameFragmentDoc}
`;

/**
 * __useStudentNameQuery__
 *
 * To run a query within a React component, call `useStudentNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStudentNameQuery(baseOptions: Apollo.QueryHookOptions<StudentNameQuery, StudentNameQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<StudentNameQuery, StudentNameQueryVariables>(StudentNameDocument, options);
}
export function useStudentNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<StudentNameQuery, StudentNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<StudentNameQuery, StudentNameQueryVariables>(StudentNameDocument, options);
}
export type StudentNameQueryHookResult = ReturnType<typeof useStudentNameQuery>;
export type StudentNameLazyQueryHookResult = ReturnType<typeof useStudentNameLazyQuery>;
export type StudentNameQueryResult = Apollo.QueryResult<StudentNameQuery, StudentNameQueryVariables>;
export const StudentNamesDocument = gql`
	query studentNames($filters: [StudentFilterInput!], $pagination: Pagination, $order: StudentOrder) {
		studentConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...StudentName
				}
			}
		}
	}
	${StudentNameFragmentDoc}
`;

/**
 * __useStudentNamesQuery__
 *
 * To run a query within a React component, call `useStudentNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useStudentNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<StudentNamesQuery, StudentNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<StudentNamesQuery, StudentNamesQueryVariables>(StudentNamesDocument, options);
}
export function useStudentNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<StudentNamesQuery, StudentNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<StudentNamesQuery, StudentNamesQueryVariables>(StudentNamesDocument, options);
}
export type StudentNamesQueryHookResult = ReturnType<typeof useStudentNamesQuery>;
export type StudentNamesLazyQueryHookResult = ReturnType<typeof useStudentNamesLazyQuery>;
export type StudentNamesQueryResult = Apollo.QueryResult<StudentNamesQuery, StudentNamesQueryVariables>;
export const StudentPaginationDocument = gql`
	query studentPagination($filters: [StudentFilterInput!], $order: StudentOrder) {
		studentConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useStudentPaginationQuery__
 *
 * To run a query within a React component, call `useStudentPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useStudentPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<StudentPaginationQuery, StudentPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<StudentPaginationQuery, StudentPaginationQueryVariables>(StudentPaginationDocument, options);
}
export function useStudentPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<StudentPaginationQuery, StudentPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<StudentPaginationQuery, StudentPaginationQueryVariables>(
		StudentPaginationDocument,
		options,
	);
}
export type StudentPaginationQueryHookResult = ReturnType<typeof useStudentPaginationQuery>;
export type StudentPaginationLazyQueryHookResult = ReturnType<typeof useStudentPaginationLazyQuery>;
export type StudentPaginationQueryResult = Apollo.QueryResult<StudentPaginationQuery, StudentPaginationQueryVariables>;
export const StudentPreviewDocument = gql`
	query studentPreview($id: ID!, $date: Time!) {
		student(id: $id) {
			id
			person {
				id
				name
				imageURL
				dateOfBirth
				gender
				nationality
			}
			homeRoomSectionStudentEnrollments(
				filters: { homeRoomSection: { term: { startDateLTE: $date, endDateGTE: $date } } }
			) {
				id
				homeRoomSection {
					id
					...HomeRoomSectionName
				}
			}
		}
	}
	${HomeRoomSectionNameFragmentDoc}
`;

/**
 * __useStudentPreviewQuery__
 *
 * To run a query within a React component, call `useStudentPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentPreviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useStudentPreviewQuery(
	baseOptions: Apollo.QueryHookOptions<StudentPreviewQuery, StudentPreviewQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<StudentPreviewQuery, StudentPreviewQueryVariables>(StudentPreviewDocument, options);
}
export function useStudentPreviewLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<StudentPreviewQuery, StudentPreviewQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<StudentPreviewQuery, StudentPreviewQueryVariables>(StudentPreviewDocument, options);
}
export type StudentPreviewQueryHookResult = ReturnType<typeof useStudentPreviewQuery>;
export type StudentPreviewLazyQueryHookResult = ReturnType<typeof useStudentPreviewLazyQuery>;
export type StudentPreviewQueryResult = Apollo.QueryResult<StudentPreviewQuery, StudentPreviewQueryVariables>;
export const SectionsActiveForStudentListDocument = gql`
	query sectionsActiveForStudentList($studentID: ID!, $activeDate: Time!) {
		student(id: $studentID) {
			id
			homeRoomSectionStudentEnrollments(
				filters: { homeRoomSection: { term: { startDateLTE: $activeDate, endDateGT: $activeDate } } }
			) {
				id
				homeRoomSection {
					id
					name
					homeRoom {
						id
						name
					}
					homeRoomSectionTeacherEnrollments {
						id
						teacher {
							id
							person {
								id
								name
								imageURL
							}
						}
					}
				}
			}
			courseSectionStudentEnrollments(
				filters: { courseSection: { term: { startDateLTE: $activeDate, endDateGT: $activeDate } } }
			) {
				id
				courseSection {
					id
					name
					course {
						id
						name
						subject
					}
					courseSectionTeacherEnrollments {
						id
						teacher {
							id
							person {
								id
								name
								imageURL
							}
						}
					}
				}
			}
		}
	}
`;

/**
 * __useSectionsActiveForStudentListQuery__
 *
 * To run a query within a React component, call `useSectionsActiveForStudentListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSectionsActiveForStudentListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSectionsActiveForStudentListQuery({
 *   variables: {
 *      studentID: // value for 'studentID'
 *      activeDate: // value for 'activeDate'
 *   },
 * });
 */
export function useSectionsActiveForStudentListQuery(
	baseOptions: Apollo.QueryHookOptions<SectionsActiveForStudentListQuery, SectionsActiveForStudentListQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<SectionsActiveForStudentListQuery, SectionsActiveForStudentListQueryVariables>(
		SectionsActiveForStudentListDocument,
		options,
	);
}
export function useSectionsActiveForStudentListLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		SectionsActiveForStudentListQuery,
		SectionsActiveForStudentListQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<SectionsActiveForStudentListQuery, SectionsActiveForStudentListQueryVariables>(
		SectionsActiveForStudentListDocument,
		options,
	);
}
export type SectionsActiveForStudentListQueryHookResult = ReturnType<typeof useSectionsActiveForStudentListQuery>;
export type SectionsActiveForStudentListLazyQueryHookResult = ReturnType<
	typeof useSectionsActiveForStudentListLazyQuery
>;
export type SectionsActiveForStudentListQueryResult = Apollo.QueryResult<
	SectionsActiveForStudentListQuery,
	SectionsActiveForStudentListQueryVariables
>;
export const StudentSelectDocument = gql`
	query studentSelect($filters: [StudentFilterInput!], $order: StudentOrder) {
		studentConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...StudentName
				}
			}
		}
	}
	${StudentNameFragmentDoc}
`;

/**
 * __useStudentSelectQuery__
 *
 * To run a query within a React component, call `useStudentSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useStudentSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<StudentSelectQuery, StudentSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<StudentSelectQuery, StudentSelectQueryVariables>(StudentSelectDocument, options);
}
export function useStudentSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<StudentSelectQuery, StudentSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<StudentSelectQuery, StudentSelectQueryVariables>(StudentSelectDocument, options);
}
export type StudentSelectQueryHookResult = ReturnType<typeof useStudentSelectQuery>;
export type StudentSelectLazyQueryHookResult = ReturnType<typeof useStudentSelectLazyQuery>;
export type StudentSelectQueryResult = Apollo.QueryResult<StudentSelectQuery, StudentSelectQueryVariables>;
export const StudentSiblingListDocument = gql`
	query studentSiblingList($studentID: ID!) {
		studentConnection(
			filters: { idNEQ: $studentID, relationships: { parent: { relationships: { studentIDEQ: $studentID } } } }
		) {
			edges {
				node {
					id
					person {
						id
						name
						imageURL
					}
				}
			}
		}
	}
`;

/**
 * __useStudentSiblingListQuery__
 *
 * To run a query within a React component, call `useStudentSiblingListQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentSiblingListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentSiblingListQuery({
 *   variables: {
 *      studentID: // value for 'studentID'
 *   },
 * });
 */
export function useStudentSiblingListQuery(
	baseOptions: Apollo.QueryHookOptions<StudentSiblingListQuery, StudentSiblingListQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<StudentSiblingListQuery, StudentSiblingListQueryVariables>(
		StudentSiblingListDocument,
		options,
	);
}
export function useStudentSiblingListLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<StudentSiblingListQuery, StudentSiblingListQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<StudentSiblingListQuery, StudentSiblingListQueryVariables>(
		StudentSiblingListDocument,
		options,
	);
}
export type StudentSiblingListQueryHookResult = ReturnType<typeof useStudentSiblingListQuery>;
export type StudentSiblingListLazyQueryHookResult = ReturnType<typeof useStudentSiblingListLazyQuery>;
export type StudentSiblingListQueryResult = Apollo.QueryResult<
	StudentSiblingListQuery,
	StudentSiblingListQueryVariables
>;
export const StudentUpdateDocument = gql`
	mutation studentUpdate($id: ID!, $input: StudentUpdateInput!) {
		updateStudent(id: $id, input: $input) {
			...StudentDetail
		}
	}
	${StudentDetailFragmentDoc}
`;
export type StudentUpdateMutationFn = Apollo.MutationFunction<StudentUpdateMutation, StudentUpdateMutationVariables>;

/**
 * __useStudentUpdateMutation__
 *
 * To run a mutation, you first call `useStudentUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStudentUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [studentUpdateMutation, { data, loading, error }] = useStudentUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStudentUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<StudentUpdateMutation, StudentUpdateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<StudentUpdateMutation, StudentUpdateMutationVariables>(StudentUpdateDocument, options);
}
export type StudentUpdateMutationHookResult = ReturnType<typeof useStudentUpdateMutation>;
export type StudentUpdateMutationResult = Apollo.MutationResult<StudentUpdateMutation>;
export type StudentUpdateMutationOptions = Apollo.BaseMutationOptions<
	StudentUpdateMutation,
	StudentUpdateMutationVariables
>;
export const StudentUpdateWithPersonDocument = gql`
	mutation studentUpdateWithPerson($id: ID!, $input: StudentUpdateInput!, $personInput: PersonUpdateInput!) {
		updateStudentWithPerson(id: $id, input: $input, personInput: $personInput) {
			...StudentDetail
		}
	}
	${StudentDetailFragmentDoc}
`;
export type StudentUpdateWithPersonMutationFn = Apollo.MutationFunction<
	StudentUpdateWithPersonMutation,
	StudentUpdateWithPersonMutationVariables
>;

/**
 * __useStudentUpdateWithPersonMutation__
 *
 * To run a mutation, you first call `useStudentUpdateWithPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStudentUpdateWithPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [studentUpdateWithPersonMutation, { data, loading, error }] = useStudentUpdateWithPersonMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *      personInput: // value for 'personInput'
 *   },
 * });
 */
export function useStudentUpdateWithPersonMutation(
	baseOptions?: Apollo.MutationHookOptions<StudentUpdateWithPersonMutation, StudentUpdateWithPersonMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<StudentUpdateWithPersonMutation, StudentUpdateWithPersonMutationVariables>(
		StudentUpdateWithPersonDocument,
		options,
	);
}
export type StudentUpdateWithPersonMutationHookResult = ReturnType<typeof useStudentUpdateWithPersonMutation>;
export type StudentUpdateWithPersonMutationResult = Apollo.MutationResult<StudentUpdateWithPersonMutation>;
export type StudentUpdateWithPersonMutationOptions = Apollo.BaseMutationOptions<
	StudentUpdateWithPersonMutation,
	StudentUpdateWithPersonMutationVariables
>;
export const StudentDocumentCountDocument = gql`
	query studentDocumentCount(
		$filters: [StudentDocumentFilterInput!]
		$pagination: Pagination
		$order: StudentDocumentOrder
	) {
		studentDocumentConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useStudentDocumentCountQuery__
 *
 * To run a query within a React component, call `useStudentDocumentCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentDocumentCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentDocumentCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useStudentDocumentCountQuery(
	baseOptions?: Apollo.QueryHookOptions<StudentDocumentCountQuery, StudentDocumentCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<StudentDocumentCountQuery, StudentDocumentCountQueryVariables>(
		StudentDocumentCountDocument,
		options,
	);
}
export function useStudentDocumentCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<StudentDocumentCountQuery, StudentDocumentCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<StudentDocumentCountQuery, StudentDocumentCountQueryVariables>(
		StudentDocumentCountDocument,
		options,
	);
}
export type StudentDocumentCountQueryHookResult = ReturnType<typeof useStudentDocumentCountQuery>;
export type StudentDocumentCountLazyQueryHookResult = ReturnType<typeof useStudentDocumentCountLazyQuery>;
export type StudentDocumentCountQueryResult = Apollo.QueryResult<
	StudentDocumentCountQuery,
	StudentDocumentCountQueryVariables
>;
export const StudentDocumentCreateDocument = gql`
	mutation studentDocumentCreate($input: StudentDocumentCreateInput!) {
		createStudentDocument(input: $input) {
			...StudentDocumentDetail
		}
	}
	${StudentDocumentDetailFragmentDoc}
`;
export type StudentDocumentCreateMutationFn = Apollo.MutationFunction<
	StudentDocumentCreateMutation,
	StudentDocumentCreateMutationVariables
>;

/**
 * __useStudentDocumentCreateMutation__
 *
 * To run a mutation, you first call `useStudentDocumentCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStudentDocumentCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [studentDocumentCreateMutation, { data, loading, error }] = useStudentDocumentCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStudentDocumentCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<StudentDocumentCreateMutation, StudentDocumentCreateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<StudentDocumentCreateMutation, StudentDocumentCreateMutationVariables>(
		StudentDocumentCreateDocument,
		options,
	);
}
export type StudentDocumentCreateMutationHookResult = ReturnType<typeof useStudentDocumentCreateMutation>;
export type StudentDocumentCreateMutationResult = Apollo.MutationResult<StudentDocumentCreateMutation>;
export type StudentDocumentCreateMutationOptions = Apollo.BaseMutationOptions<
	StudentDocumentCreateMutation,
	StudentDocumentCreateMutationVariables
>;
export const StudentDocumentDeleteDocument = gql`
	mutation studentDocumentDelete($id: ID!) {
		deleteStudentDocument(id: $id)
	}
`;
export type StudentDocumentDeleteMutationFn = Apollo.MutationFunction<
	StudentDocumentDeleteMutation,
	StudentDocumentDeleteMutationVariables
>;

/**
 * __useStudentDocumentDeleteMutation__
 *
 * To run a mutation, you first call `useStudentDocumentDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStudentDocumentDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [studentDocumentDeleteMutation, { data, loading, error }] = useStudentDocumentDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStudentDocumentDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<StudentDocumentDeleteMutation, StudentDocumentDeleteMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<StudentDocumentDeleteMutation, StudentDocumentDeleteMutationVariables>(
		StudentDocumentDeleteDocument,
		options,
	);
}
export type StudentDocumentDeleteMutationHookResult = ReturnType<typeof useStudentDocumentDeleteMutation>;
export type StudentDocumentDeleteMutationResult = Apollo.MutationResult<StudentDocumentDeleteMutation>;
export type StudentDocumentDeleteMutationOptions = Apollo.BaseMutationOptions<
	StudentDocumentDeleteMutation,
	StudentDocumentDeleteMutationVariables
>;
export const StudentDocumentDetailDocument = gql`
	query studentDocumentDetail($id: ID!) {
		studentDocument(id: $id) {
			...StudentDocumentDetail
		}
	}
	${StudentDocumentDetailFragmentDoc}
`;

/**
 * __useStudentDocumentDetailQuery__
 *
 * To run a query within a React component, call `useStudentDocumentDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentDocumentDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentDocumentDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStudentDocumentDetailQuery(
	baseOptions: Apollo.QueryHookOptions<StudentDocumentDetailQuery, StudentDocumentDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<StudentDocumentDetailQuery, StudentDocumentDetailQueryVariables>(
		StudentDocumentDetailDocument,
		options,
	);
}
export function useStudentDocumentDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<StudentDocumentDetailQuery, StudentDocumentDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<StudentDocumentDetailQuery, StudentDocumentDetailQueryVariables>(
		StudentDocumentDetailDocument,
		options,
	);
}
export type StudentDocumentDetailQueryHookResult = ReturnType<typeof useStudentDocumentDetailQuery>;
export type StudentDocumentDetailLazyQueryHookResult = ReturnType<typeof useStudentDocumentDetailLazyQuery>;
export type StudentDocumentDetailQueryResult = Apollo.QueryResult<
	StudentDocumentDetailQuery,
	StudentDocumentDetailQueryVariables
>;
export const StudentDocumentIndexDocument = gql`
	query studentDocumentIndex(
		$filters: [StudentDocumentFilterInput!]
		$pagination: Pagination
		$order: StudentDocumentOrder
	) {
		studentDocumentConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					name
					description
					fileURL
					isPublic
					createdAt
				}
			}
		}
	}
`;

/**
 * __useStudentDocumentIndexQuery__
 *
 * To run a query within a React component, call `useStudentDocumentIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentDocumentIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentDocumentIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useStudentDocumentIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<StudentDocumentIndexQuery, StudentDocumentIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<StudentDocumentIndexQuery, StudentDocumentIndexQueryVariables>(
		StudentDocumentIndexDocument,
		options,
	);
}
export function useStudentDocumentIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<StudentDocumentIndexQuery, StudentDocumentIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<StudentDocumentIndexQuery, StudentDocumentIndexQueryVariables>(
		StudentDocumentIndexDocument,
		options,
	);
}
export type StudentDocumentIndexQueryHookResult = ReturnType<typeof useStudentDocumentIndexQuery>;
export type StudentDocumentIndexLazyQueryHookResult = ReturnType<typeof useStudentDocumentIndexLazyQuery>;
export type StudentDocumentIndexQueryResult = Apollo.QueryResult<
	StudentDocumentIndexQuery,
	StudentDocumentIndexQueryVariables
>;
export const StudentDocumentNameDocument = gql`
	query studentDocumentName($id: ID!) {
		studentDocument(id: $id) {
			id
			...StudentDocumentName
		}
	}
	${StudentDocumentNameFragmentDoc}
`;

/**
 * __useStudentDocumentNameQuery__
 *
 * To run a query within a React component, call `useStudentDocumentNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentDocumentNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentDocumentNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStudentDocumentNameQuery(
	baseOptions: Apollo.QueryHookOptions<StudentDocumentNameQuery, StudentDocumentNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<StudentDocumentNameQuery, StudentDocumentNameQueryVariables>(
		StudentDocumentNameDocument,
		options,
	);
}
export function useStudentDocumentNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<StudentDocumentNameQuery, StudentDocumentNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<StudentDocumentNameQuery, StudentDocumentNameQueryVariables>(
		StudentDocumentNameDocument,
		options,
	);
}
export type StudentDocumentNameQueryHookResult = ReturnType<typeof useStudentDocumentNameQuery>;
export type StudentDocumentNameLazyQueryHookResult = ReturnType<typeof useStudentDocumentNameLazyQuery>;
export type StudentDocumentNameQueryResult = Apollo.QueryResult<
	StudentDocumentNameQuery,
	StudentDocumentNameQueryVariables
>;
export const StudentDocumentNamesDocument = gql`
	query studentDocumentNames(
		$filters: [StudentDocumentFilterInput!]
		$pagination: Pagination
		$order: StudentDocumentOrder
	) {
		studentDocumentConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...StudentDocumentName
				}
			}
		}
	}
	${StudentDocumentNameFragmentDoc}
`;

/**
 * __useStudentDocumentNamesQuery__
 *
 * To run a query within a React component, call `useStudentDocumentNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentDocumentNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentDocumentNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useStudentDocumentNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<StudentDocumentNamesQuery, StudentDocumentNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<StudentDocumentNamesQuery, StudentDocumentNamesQueryVariables>(
		StudentDocumentNamesDocument,
		options,
	);
}
export function useStudentDocumentNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<StudentDocumentNamesQuery, StudentDocumentNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<StudentDocumentNamesQuery, StudentDocumentNamesQueryVariables>(
		StudentDocumentNamesDocument,
		options,
	);
}
export type StudentDocumentNamesQueryHookResult = ReturnType<typeof useStudentDocumentNamesQuery>;
export type StudentDocumentNamesLazyQueryHookResult = ReturnType<typeof useStudentDocumentNamesLazyQuery>;
export type StudentDocumentNamesQueryResult = Apollo.QueryResult<
	StudentDocumentNamesQuery,
	StudentDocumentNamesQueryVariables
>;
export const StudentDocumentPaginationDocument = gql`
	query studentDocumentPagination($filters: [StudentDocumentFilterInput!], $order: StudentDocumentOrder) {
		studentDocumentConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useStudentDocumentPaginationQuery__
 *
 * To run a query within a React component, call `useStudentDocumentPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentDocumentPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentDocumentPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useStudentDocumentPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<StudentDocumentPaginationQuery, StudentDocumentPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<StudentDocumentPaginationQuery, StudentDocumentPaginationQueryVariables>(
		StudentDocumentPaginationDocument,
		options,
	);
}
export function useStudentDocumentPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<StudentDocumentPaginationQuery, StudentDocumentPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<StudentDocumentPaginationQuery, StudentDocumentPaginationQueryVariables>(
		StudentDocumentPaginationDocument,
		options,
	);
}
export type StudentDocumentPaginationQueryHookResult = ReturnType<typeof useStudentDocumentPaginationQuery>;
export type StudentDocumentPaginationLazyQueryHookResult = ReturnType<typeof useStudentDocumentPaginationLazyQuery>;
export type StudentDocumentPaginationQueryResult = Apollo.QueryResult<
	StudentDocumentPaginationQuery,
	StudentDocumentPaginationQueryVariables
>;
export const StudentDocumentSelectDocument = gql`
	query studentDocumentSelect($filters: [StudentDocumentFilterInput!], $order: StudentDocumentOrder) {
		studentDocumentConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...StudentDocumentName
				}
			}
		}
	}
	${StudentDocumentNameFragmentDoc}
`;

/**
 * __useStudentDocumentSelectQuery__
 *
 * To run a query within a React component, call `useStudentDocumentSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentDocumentSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentDocumentSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useStudentDocumentSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<StudentDocumentSelectQuery, StudentDocumentSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<StudentDocumentSelectQuery, StudentDocumentSelectQueryVariables>(
		StudentDocumentSelectDocument,
		options,
	);
}
export function useStudentDocumentSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<StudentDocumentSelectQuery, StudentDocumentSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<StudentDocumentSelectQuery, StudentDocumentSelectQueryVariables>(
		StudentDocumentSelectDocument,
		options,
	);
}
export type StudentDocumentSelectQueryHookResult = ReturnType<typeof useStudentDocumentSelectQuery>;
export type StudentDocumentSelectLazyQueryHookResult = ReturnType<typeof useStudentDocumentSelectLazyQuery>;
export type StudentDocumentSelectQueryResult = Apollo.QueryResult<
	StudentDocumentSelectQuery,
	StudentDocumentSelectQueryVariables
>;
export const StudentDocumentStudentListDocument = gql`
	query studentDocumentStudentList(
		$filters: [StudentDocumentFilterInput!]
		$pagination: Pagination
		$order: StudentDocumentOrder
	) {
		studentDocumentConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					name
					fileURL
					fileContentType
				}
			}
		}
	}
`;

/**
 * __useStudentDocumentStudentListQuery__
 *
 * To run a query within a React component, call `useStudentDocumentStudentListQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentDocumentStudentListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentDocumentStudentListQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useStudentDocumentStudentListQuery(
	baseOptions?: Apollo.QueryHookOptions<StudentDocumentStudentListQuery, StudentDocumentStudentListQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<StudentDocumentStudentListQuery, StudentDocumentStudentListQueryVariables>(
		StudentDocumentStudentListDocument,
		options,
	);
}
export function useStudentDocumentStudentListLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<StudentDocumentStudentListQuery, StudentDocumentStudentListQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<StudentDocumentStudentListQuery, StudentDocumentStudentListQueryVariables>(
		StudentDocumentStudentListDocument,
		options,
	);
}
export type StudentDocumentStudentListQueryHookResult = ReturnType<typeof useStudentDocumentStudentListQuery>;
export type StudentDocumentStudentListLazyQueryHookResult = ReturnType<typeof useStudentDocumentStudentListLazyQuery>;
export type StudentDocumentStudentListQueryResult = Apollo.QueryResult<
	StudentDocumentStudentListQuery,
	StudentDocumentStudentListQueryVariables
>;
export const StudentDocumentUpdateDocument = gql`
	mutation studentDocumentUpdate($id: ID!, $input: StudentDocumentUpdateInput!) {
		updateStudentDocument(id: $id, input: $input) {
			...StudentDocumentDetail
		}
	}
	${StudentDocumentDetailFragmentDoc}
`;
export type StudentDocumentUpdateMutationFn = Apollo.MutationFunction<
	StudentDocumentUpdateMutation,
	StudentDocumentUpdateMutationVariables
>;

/**
 * __useStudentDocumentUpdateMutation__
 *
 * To run a mutation, you first call `useStudentDocumentUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStudentDocumentUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [studentDocumentUpdateMutation, { data, loading, error }] = useStudentDocumentUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStudentDocumentUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<StudentDocumentUpdateMutation, StudentDocumentUpdateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<StudentDocumentUpdateMutation, StudentDocumentUpdateMutationVariables>(
		StudentDocumentUpdateDocument,
		options,
	);
}
export type StudentDocumentUpdateMutationHookResult = ReturnType<typeof useStudentDocumentUpdateMutation>;
export type StudentDocumentUpdateMutationResult = Apollo.MutationResult<StudentDocumentUpdateMutation>;
export type StudentDocumentUpdateMutationOptions = Apollo.BaseMutationOptions<
	StudentDocumentUpdateMutation,
	StudentDocumentUpdateMutationVariables
>;
export const TermCountDocument = gql`
	query termCount($filters: [TermFilterInput!], $pagination: Pagination, $order: TermOrder) {
		termConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useTermCountQuery__
 *
 * To run a query within a React component, call `useTermCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useTermCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTermCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useTermCountQuery(baseOptions?: Apollo.QueryHookOptions<TermCountQuery, TermCountQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<TermCountQuery, TermCountQueryVariables>(TermCountDocument, options);
}
export function useTermCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<TermCountQuery, TermCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<TermCountQuery, TermCountQueryVariables>(TermCountDocument, options);
}
export type TermCountQueryHookResult = ReturnType<typeof useTermCountQuery>;
export type TermCountLazyQueryHookResult = ReturnType<typeof useTermCountLazyQuery>;
export type TermCountQueryResult = Apollo.QueryResult<TermCountQuery, TermCountQueryVariables>;
export const TermCreateDocument = gql`
	mutation termCreate($input: TermCreateInput!) {
		createTerm(input: $input) {
			...TermDetail
		}
	}
	${TermDetailFragmentDoc}
`;
export type TermCreateMutationFn = Apollo.MutationFunction<TermCreateMutation, TermCreateMutationVariables>;

/**
 * __useTermCreateMutation__
 *
 * To run a mutation, you first call `useTermCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTermCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [termCreateMutation, { data, loading, error }] = useTermCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTermCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<TermCreateMutation, TermCreateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<TermCreateMutation, TermCreateMutationVariables>(TermCreateDocument, options);
}
export type TermCreateMutationHookResult = ReturnType<typeof useTermCreateMutation>;
export type TermCreateMutationResult = Apollo.MutationResult<TermCreateMutation>;
export type TermCreateMutationOptions = Apollo.BaseMutationOptions<TermCreateMutation, TermCreateMutationVariables>;
export const TermDeleteDocument = gql`
	mutation termDelete($id: ID!) {
		deleteTerm(id: $id)
	}
`;
export type TermDeleteMutationFn = Apollo.MutationFunction<TermDeleteMutation, TermDeleteMutationVariables>;

/**
 * __useTermDeleteMutation__
 *
 * To run a mutation, you first call `useTermDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTermDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [termDeleteMutation, { data, loading, error }] = useTermDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTermDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<TermDeleteMutation, TermDeleteMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<TermDeleteMutation, TermDeleteMutationVariables>(TermDeleteDocument, options);
}
export type TermDeleteMutationHookResult = ReturnType<typeof useTermDeleteMutation>;
export type TermDeleteMutationResult = Apollo.MutationResult<TermDeleteMutation>;
export type TermDeleteMutationOptions = Apollo.BaseMutationOptions<TermDeleteMutation, TermDeleteMutationVariables>;
export const TermDetailListDocument = gql`
	query termDetailList($filters: [TermFilterInput!], $pagination: Pagination, $order: TermOrder) {
		termConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					...TermDetail
				}
			}
		}
	}
	${TermDetailFragmentDoc}
`;

/**
 * __useTermDetailListQuery__
 *
 * To run a query within a React component, call `useTermDetailListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTermDetailListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTermDetailListQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useTermDetailListQuery(
	baseOptions?: Apollo.QueryHookOptions<TermDetailListQuery, TermDetailListQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<TermDetailListQuery, TermDetailListQueryVariables>(TermDetailListDocument, options);
}
export function useTermDetailListLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<TermDetailListQuery, TermDetailListQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<TermDetailListQuery, TermDetailListQueryVariables>(TermDetailListDocument, options);
}
export type TermDetailListQueryHookResult = ReturnType<typeof useTermDetailListQuery>;
export type TermDetailListLazyQueryHookResult = ReturnType<typeof useTermDetailListLazyQuery>;
export type TermDetailListQueryResult = Apollo.QueryResult<TermDetailListQuery, TermDetailListQueryVariables>;
export const TermDetailDocument = gql`
	query termDetail($id: ID!) {
		term(id: $id) {
			...TermDetail
		}
	}
	${TermDetailFragmentDoc}
`;

/**
 * __useTermDetailQuery__
 *
 * To run a query within a React component, call `useTermDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useTermDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTermDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTermDetailQuery(baseOptions: Apollo.QueryHookOptions<TermDetailQuery, TermDetailQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<TermDetailQuery, TermDetailQueryVariables>(TermDetailDocument, options);
}
export function useTermDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<TermDetailQuery, TermDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<TermDetailQuery, TermDetailQueryVariables>(TermDetailDocument, options);
}
export type TermDetailQueryHookResult = ReturnType<typeof useTermDetailQuery>;
export type TermDetailLazyQueryHookResult = ReturnType<typeof useTermDetailLazyQuery>;
export type TermDetailQueryResult = Apollo.QueryResult<TermDetailQuery, TermDetailQueryVariables>;
export const TermIndexDocument = gql`
	query termIndex($filters: [TermFilterInput!], $pagination: Pagination, $order: TermOrder) {
		termConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					name
					startDate
					endDate
				}
			}
		}
	}
`;

/**
 * __useTermIndexQuery__
 *
 * To run a query within a React component, call `useTermIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useTermIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTermIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useTermIndexQuery(baseOptions?: Apollo.QueryHookOptions<TermIndexQuery, TermIndexQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<TermIndexQuery, TermIndexQueryVariables>(TermIndexDocument, options);
}
export function useTermIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<TermIndexQuery, TermIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<TermIndexQuery, TermIndexQueryVariables>(TermIndexDocument, options);
}
export type TermIndexQueryHookResult = ReturnType<typeof useTermIndexQuery>;
export type TermIndexLazyQueryHookResult = ReturnType<typeof useTermIndexLazyQuery>;
export type TermIndexQueryResult = Apollo.QueryResult<TermIndexQuery, TermIndexQueryVariables>;
export const TermNameDocument = gql`
	query termName($id: ID!) {
		term(id: $id) {
			id
			...TermName
		}
	}
	${TermNameFragmentDoc}
`;

/**
 * __useTermNameQuery__
 *
 * To run a query within a React component, call `useTermNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useTermNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTermNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTermNameQuery(baseOptions: Apollo.QueryHookOptions<TermNameQuery, TermNameQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<TermNameQuery, TermNameQueryVariables>(TermNameDocument, options);
}
export function useTermNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TermNameQuery, TermNameQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<TermNameQuery, TermNameQueryVariables>(TermNameDocument, options);
}
export type TermNameQueryHookResult = ReturnType<typeof useTermNameQuery>;
export type TermNameLazyQueryHookResult = ReturnType<typeof useTermNameLazyQuery>;
export type TermNameQueryResult = Apollo.QueryResult<TermNameQuery, TermNameQueryVariables>;
export const TermNamesDocument = gql`
	query termNames($filters: [TermFilterInput!], $pagination: Pagination, $order: TermOrder) {
		termConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...TermName
				}
			}
		}
	}
	${TermNameFragmentDoc}
`;

/**
 * __useTermNamesQuery__
 *
 * To run a query within a React component, call `useTermNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTermNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTermNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useTermNamesQuery(baseOptions?: Apollo.QueryHookOptions<TermNamesQuery, TermNamesQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<TermNamesQuery, TermNamesQueryVariables>(TermNamesDocument, options);
}
export function useTermNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<TermNamesQuery, TermNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<TermNamesQuery, TermNamesQueryVariables>(TermNamesDocument, options);
}
export type TermNamesQueryHookResult = ReturnType<typeof useTermNamesQuery>;
export type TermNamesLazyQueryHookResult = ReturnType<typeof useTermNamesLazyQuery>;
export type TermNamesQueryResult = Apollo.QueryResult<TermNamesQuery, TermNamesQueryVariables>;
export const TermPaginationDocument = gql`
	query termPagination($filters: [TermFilterInput!], $order: TermOrder) {
		termConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useTermPaginationQuery__
 *
 * To run a query within a React component, call `useTermPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useTermPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTermPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useTermPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<TermPaginationQuery, TermPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<TermPaginationQuery, TermPaginationQueryVariables>(TermPaginationDocument, options);
}
export function useTermPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<TermPaginationQuery, TermPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<TermPaginationQuery, TermPaginationQueryVariables>(TermPaginationDocument, options);
}
export type TermPaginationQueryHookResult = ReturnType<typeof useTermPaginationQuery>;
export type TermPaginationLazyQueryHookResult = ReturnType<typeof useTermPaginationLazyQuery>;
export type TermPaginationQueryResult = Apollo.QueryResult<TermPaginationQuery, TermPaginationQueryVariables>;
export const TermSelectDocument = gql`
	query termSelect($filters: [TermFilterInput!], $order: TermOrder) {
		termConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...TermName
				}
			}
		}
	}
	${TermNameFragmentDoc}
`;

/**
 * __useTermSelectQuery__
 *
 * To run a query within a React component, call `useTermSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useTermSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTermSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useTermSelectQuery(baseOptions?: Apollo.QueryHookOptions<TermSelectQuery, TermSelectQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<TermSelectQuery, TermSelectQueryVariables>(TermSelectDocument, options);
}
export function useTermSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<TermSelectQuery, TermSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<TermSelectQuery, TermSelectQueryVariables>(TermSelectDocument, options);
}
export type TermSelectQueryHookResult = ReturnType<typeof useTermSelectQuery>;
export type TermSelectLazyQueryHookResult = ReturnType<typeof useTermSelectLazyQuery>;
export type TermSelectQueryResult = Apollo.QueryResult<TermSelectQuery, TermSelectQueryVariables>;
export const TermUpdateDocument = gql`
	mutation termUpdate($id: ID!, $input: TermUpdateInput!) {
		updateTerm(id: $id, input: $input) {
			...TermDetail
		}
	}
	${TermDetailFragmentDoc}
`;
export type TermUpdateMutationFn = Apollo.MutationFunction<TermUpdateMutation, TermUpdateMutationVariables>;

/**
 * __useTermUpdateMutation__
 *
 * To run a mutation, you first call `useTermUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTermUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [termUpdateMutation, { data, loading, error }] = useTermUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTermUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<TermUpdateMutation, TermUpdateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<TermUpdateMutation, TermUpdateMutationVariables>(TermUpdateDocument, options);
}
export type TermUpdateMutationHookResult = ReturnType<typeof useTermUpdateMutation>;
export type TermUpdateMutationResult = Apollo.MutationResult<TermUpdateMutation>;
export type TermUpdateMutationOptions = Apollo.BaseMutationOptions<TermUpdateMutation, TermUpdateMutationVariables>;
export const TrustedDeviceCountDocument = gql`
	query trustedDeviceCount($filters: [TrustedDeviceFilterInput!], $pagination: Pagination, $order: TrustedDeviceOrder) {
		trustedDeviceConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useTrustedDeviceCountQuery__
 *
 * To run a query within a React component, call `useTrustedDeviceCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrustedDeviceCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrustedDeviceCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useTrustedDeviceCountQuery(
	baseOptions?: Apollo.QueryHookOptions<TrustedDeviceCountQuery, TrustedDeviceCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<TrustedDeviceCountQuery, TrustedDeviceCountQueryVariables>(
		TrustedDeviceCountDocument,
		options,
	);
}
export function useTrustedDeviceCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<TrustedDeviceCountQuery, TrustedDeviceCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<TrustedDeviceCountQuery, TrustedDeviceCountQueryVariables>(
		TrustedDeviceCountDocument,
		options,
	);
}
export type TrustedDeviceCountQueryHookResult = ReturnType<typeof useTrustedDeviceCountQuery>;
export type TrustedDeviceCountLazyQueryHookResult = ReturnType<typeof useTrustedDeviceCountLazyQuery>;
export type TrustedDeviceCountQueryResult = Apollo.QueryResult<
	TrustedDeviceCountQuery,
	TrustedDeviceCountQueryVariables
>;
export const TrustedDeviceDetailDocument = gql`
	query trustedDeviceDetail($id: ID!) {
		trustedDevice(id: $id) {
			...TrustedDeviceDetail
		}
	}
	${TrustedDeviceDetailFragmentDoc}
`;

/**
 * __useTrustedDeviceDetailQuery__
 *
 * To run a query within a React component, call `useTrustedDeviceDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrustedDeviceDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrustedDeviceDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTrustedDeviceDetailQuery(
	baseOptions: Apollo.QueryHookOptions<TrustedDeviceDetailQuery, TrustedDeviceDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<TrustedDeviceDetailQuery, TrustedDeviceDetailQueryVariables>(
		TrustedDeviceDetailDocument,
		options,
	);
}
export function useTrustedDeviceDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<TrustedDeviceDetailQuery, TrustedDeviceDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<TrustedDeviceDetailQuery, TrustedDeviceDetailQueryVariables>(
		TrustedDeviceDetailDocument,
		options,
	);
}
export type TrustedDeviceDetailQueryHookResult = ReturnType<typeof useTrustedDeviceDetailQuery>;
export type TrustedDeviceDetailLazyQueryHookResult = ReturnType<typeof useTrustedDeviceDetailLazyQuery>;
export type TrustedDeviceDetailQueryResult = Apollo.QueryResult<
	TrustedDeviceDetailQuery,
	TrustedDeviceDetailQueryVariables
>;
export const TrustedDeviceIndexDocument = gql`
	query trustedDeviceIndex($filters: [TrustedDeviceFilterInput!], $pagination: Pagination, $order: TrustedDeviceOrder) {
		trustedDeviceConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					createdAt
					name
					isCurrent
				}
			}
		}
	}
`;

/**
 * __useTrustedDeviceIndexQuery__
 *
 * To run a query within a React component, call `useTrustedDeviceIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrustedDeviceIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrustedDeviceIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useTrustedDeviceIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<TrustedDeviceIndexQuery, TrustedDeviceIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<TrustedDeviceIndexQuery, TrustedDeviceIndexQueryVariables>(
		TrustedDeviceIndexDocument,
		options,
	);
}
export function useTrustedDeviceIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<TrustedDeviceIndexQuery, TrustedDeviceIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<TrustedDeviceIndexQuery, TrustedDeviceIndexQueryVariables>(
		TrustedDeviceIndexDocument,
		options,
	);
}
export type TrustedDeviceIndexQueryHookResult = ReturnType<typeof useTrustedDeviceIndexQuery>;
export type TrustedDeviceIndexLazyQueryHookResult = ReturnType<typeof useTrustedDeviceIndexLazyQuery>;
export type TrustedDeviceIndexQueryResult = Apollo.QueryResult<
	TrustedDeviceIndexQuery,
	TrustedDeviceIndexQueryVariables
>;
export const TrustedDeviceNameDocument = gql`
	query trustedDeviceName($id: ID!) {
		trustedDevice(id: $id) {
			id
			...TrustedDeviceName
		}
	}
	${TrustedDeviceNameFragmentDoc}
`;

/**
 * __useTrustedDeviceNameQuery__
 *
 * To run a query within a React component, call `useTrustedDeviceNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrustedDeviceNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrustedDeviceNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTrustedDeviceNameQuery(
	baseOptions: Apollo.QueryHookOptions<TrustedDeviceNameQuery, TrustedDeviceNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<TrustedDeviceNameQuery, TrustedDeviceNameQueryVariables>(TrustedDeviceNameDocument, options);
}
export function useTrustedDeviceNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<TrustedDeviceNameQuery, TrustedDeviceNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<TrustedDeviceNameQuery, TrustedDeviceNameQueryVariables>(
		TrustedDeviceNameDocument,
		options,
	);
}
export type TrustedDeviceNameQueryHookResult = ReturnType<typeof useTrustedDeviceNameQuery>;
export type TrustedDeviceNameLazyQueryHookResult = ReturnType<typeof useTrustedDeviceNameLazyQuery>;
export type TrustedDeviceNameQueryResult = Apollo.QueryResult<TrustedDeviceNameQuery, TrustedDeviceNameQueryVariables>;
export const TrustedDeviceNamesDocument = gql`
	query trustedDeviceNames($filters: [TrustedDeviceFilterInput!], $pagination: Pagination, $order: TrustedDeviceOrder) {
		trustedDeviceConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...TrustedDeviceName
				}
			}
		}
	}
	${TrustedDeviceNameFragmentDoc}
`;

/**
 * __useTrustedDeviceNamesQuery__
 *
 * To run a query within a React component, call `useTrustedDeviceNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrustedDeviceNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrustedDeviceNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useTrustedDeviceNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<TrustedDeviceNamesQuery, TrustedDeviceNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<TrustedDeviceNamesQuery, TrustedDeviceNamesQueryVariables>(
		TrustedDeviceNamesDocument,
		options,
	);
}
export function useTrustedDeviceNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<TrustedDeviceNamesQuery, TrustedDeviceNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<TrustedDeviceNamesQuery, TrustedDeviceNamesQueryVariables>(
		TrustedDeviceNamesDocument,
		options,
	);
}
export type TrustedDeviceNamesQueryHookResult = ReturnType<typeof useTrustedDeviceNamesQuery>;
export type TrustedDeviceNamesLazyQueryHookResult = ReturnType<typeof useTrustedDeviceNamesLazyQuery>;
export type TrustedDeviceNamesQueryResult = Apollo.QueryResult<
	TrustedDeviceNamesQuery,
	TrustedDeviceNamesQueryVariables
>;
export const TrustedDevicePaginationDocument = gql`
	query trustedDevicePagination($filters: [TrustedDeviceFilterInput!], $order: TrustedDeviceOrder) {
		trustedDeviceConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useTrustedDevicePaginationQuery__
 *
 * To run a query within a React component, call `useTrustedDevicePaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrustedDevicePaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrustedDevicePaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useTrustedDevicePaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<TrustedDevicePaginationQuery, TrustedDevicePaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<TrustedDevicePaginationQuery, TrustedDevicePaginationQueryVariables>(
		TrustedDevicePaginationDocument,
		options,
	);
}
export function useTrustedDevicePaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<TrustedDevicePaginationQuery, TrustedDevicePaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<TrustedDevicePaginationQuery, TrustedDevicePaginationQueryVariables>(
		TrustedDevicePaginationDocument,
		options,
	);
}
export type TrustedDevicePaginationQueryHookResult = ReturnType<typeof useTrustedDevicePaginationQuery>;
export type TrustedDevicePaginationLazyQueryHookResult = ReturnType<typeof useTrustedDevicePaginationLazyQuery>;
export type TrustedDevicePaginationQueryResult = Apollo.QueryResult<
	TrustedDevicePaginationQuery,
	TrustedDevicePaginationQueryVariables
>;
export const TrustedDeviceSelectDocument = gql`
	query trustedDeviceSelect($filters: [TrustedDeviceFilterInput!], $order: TrustedDeviceOrder) {
		trustedDeviceConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...TrustedDeviceName
				}
			}
		}
	}
	${TrustedDeviceNameFragmentDoc}
`;

/**
 * __useTrustedDeviceSelectQuery__
 *
 * To run a query within a React component, call `useTrustedDeviceSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrustedDeviceSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrustedDeviceSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useTrustedDeviceSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<TrustedDeviceSelectQuery, TrustedDeviceSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<TrustedDeviceSelectQuery, TrustedDeviceSelectQueryVariables>(
		TrustedDeviceSelectDocument,
		options,
	);
}
export function useTrustedDeviceSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<TrustedDeviceSelectQuery, TrustedDeviceSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<TrustedDeviceSelectQuery, TrustedDeviceSelectQueryVariables>(
		TrustedDeviceSelectDocument,
		options,
	);
}
export type TrustedDeviceSelectQueryHookResult = ReturnType<typeof useTrustedDeviceSelectQuery>;
export type TrustedDeviceSelectLazyQueryHookResult = ReturnType<typeof useTrustedDeviceSelectLazyQuery>;
export type TrustedDeviceSelectQueryResult = Apollo.QueryResult<
	TrustedDeviceSelectQuery,
	TrustedDeviceSelectQueryVariables
>;
export const TuitionCountDocument = gql`
	query tuitionCount($filters: [TuitionFilterInput!], $pagination: Pagination, $order: TuitionOrder) {
		tuitionConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useTuitionCountQuery__
 *
 * To run a query within a React component, call `useTuitionCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useTuitionCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTuitionCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useTuitionCountQuery(
	baseOptions?: Apollo.QueryHookOptions<TuitionCountQuery, TuitionCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<TuitionCountQuery, TuitionCountQueryVariables>(TuitionCountDocument, options);
}
export function useTuitionCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<TuitionCountQuery, TuitionCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<TuitionCountQuery, TuitionCountQueryVariables>(TuitionCountDocument, options);
}
export type TuitionCountQueryHookResult = ReturnType<typeof useTuitionCountQuery>;
export type TuitionCountLazyQueryHookResult = ReturnType<typeof useTuitionCountLazyQuery>;
export type TuitionCountQueryResult = Apollo.QueryResult<TuitionCountQuery, TuitionCountQueryVariables>;
export const TuitionCreateDocument = gql`
	mutation tuitionCreate($input: TuitionCreateInput!) {
		createTuition(input: $input) {
			...tuitionDetailFields
		}
	}
	${TuitionDetailFieldsFragmentDoc}
`;
export type TuitionCreateMutationFn = Apollo.MutationFunction<TuitionCreateMutation, TuitionCreateMutationVariables>;

/**
 * __useTuitionCreateMutation__
 *
 * To run a mutation, you first call `useTuitionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTuitionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tuitionCreateMutation, { data, loading, error }] = useTuitionCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTuitionCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<TuitionCreateMutation, TuitionCreateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<TuitionCreateMutation, TuitionCreateMutationVariables>(TuitionCreateDocument, options);
}
export type TuitionCreateMutationHookResult = ReturnType<typeof useTuitionCreateMutation>;
export type TuitionCreateMutationResult = Apollo.MutationResult<TuitionCreateMutation>;
export type TuitionCreateMutationOptions = Apollo.BaseMutationOptions<
	TuitionCreateMutation,
	TuitionCreateMutationVariables
>;
export const TuitionDeleteDocument = gql`
	mutation tuitionDelete($id: ID!) {
		deleteTuition(id: $id)
	}
`;
export type TuitionDeleteMutationFn = Apollo.MutationFunction<TuitionDeleteMutation, TuitionDeleteMutationVariables>;

/**
 * __useTuitionDeleteMutation__
 *
 * To run a mutation, you first call `useTuitionDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTuitionDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tuitionDeleteMutation, { data, loading, error }] = useTuitionDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTuitionDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<TuitionDeleteMutation, TuitionDeleteMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<TuitionDeleteMutation, TuitionDeleteMutationVariables>(TuitionDeleteDocument, options);
}
export type TuitionDeleteMutationHookResult = ReturnType<typeof useTuitionDeleteMutation>;
export type TuitionDeleteMutationResult = Apollo.MutationResult<TuitionDeleteMutation>;
export type TuitionDeleteMutationOptions = Apollo.BaseMutationOptions<
	TuitionDeleteMutation,
	TuitionDeleteMutationVariables
>;
export const TuitionDetailDocument = gql`
	query tuitionDetail($id: ID!) {
		tuition(id: $id) {
			...tuitionDetailFields
		}
	}
	${TuitionDetailFieldsFragmentDoc}
`;

/**
 * __useTuitionDetailQuery__
 *
 * To run a query within a React component, call `useTuitionDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useTuitionDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTuitionDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTuitionDetailQuery(
	baseOptions: Apollo.QueryHookOptions<TuitionDetailQuery, TuitionDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<TuitionDetailQuery, TuitionDetailQueryVariables>(TuitionDetailDocument, options);
}
export function useTuitionDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<TuitionDetailQuery, TuitionDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<TuitionDetailQuery, TuitionDetailQueryVariables>(TuitionDetailDocument, options);
}
export type TuitionDetailQueryHookResult = ReturnType<typeof useTuitionDetailQuery>;
export type TuitionDetailLazyQueryHookResult = ReturnType<typeof useTuitionDetailLazyQuery>;
export type TuitionDetailQueryResult = Apollo.QueryResult<TuitionDetailQuery, TuitionDetailQueryVariables>;
export const TuitionIndexDocument = gql`
	query tuitionIndex($filters: [TuitionFilterInput!], $pagination: Pagination, $order: TuitionOrder) {
		tuitionConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					name
					initialYear
				}
			}
		}
	}
`;

/**
 * __useTuitionIndexQuery__
 *
 * To run a query within a React component, call `useTuitionIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useTuitionIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTuitionIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useTuitionIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<TuitionIndexQuery, TuitionIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<TuitionIndexQuery, TuitionIndexQueryVariables>(TuitionIndexDocument, options);
}
export function useTuitionIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<TuitionIndexQuery, TuitionIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<TuitionIndexQuery, TuitionIndexQueryVariables>(TuitionIndexDocument, options);
}
export type TuitionIndexQueryHookResult = ReturnType<typeof useTuitionIndexQuery>;
export type TuitionIndexLazyQueryHookResult = ReturnType<typeof useTuitionIndexLazyQuery>;
export type TuitionIndexQueryResult = Apollo.QueryResult<TuitionIndexQuery, TuitionIndexQueryVariables>;
export const TuitionNameDocument = gql`
	query tuitionName($id: ID!) {
		tuition(id: $id) {
			id
			...TuitionName
		}
	}
	${TuitionNameFragmentDoc}
`;

/**
 * __useTuitionNameQuery__
 *
 * To run a query within a React component, call `useTuitionNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useTuitionNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTuitionNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTuitionNameQuery(baseOptions: Apollo.QueryHookOptions<TuitionNameQuery, TuitionNameQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<TuitionNameQuery, TuitionNameQueryVariables>(TuitionNameDocument, options);
}
export function useTuitionNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<TuitionNameQuery, TuitionNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<TuitionNameQuery, TuitionNameQueryVariables>(TuitionNameDocument, options);
}
export type TuitionNameQueryHookResult = ReturnType<typeof useTuitionNameQuery>;
export type TuitionNameLazyQueryHookResult = ReturnType<typeof useTuitionNameLazyQuery>;
export type TuitionNameQueryResult = Apollo.QueryResult<TuitionNameQuery, TuitionNameQueryVariables>;
export const TuitionNamesDocument = gql`
	query tuitionNames($filters: [TuitionFilterInput!], $pagination: Pagination, $order: TuitionOrder) {
		tuitionConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...TuitionName
				}
			}
		}
	}
	${TuitionNameFragmentDoc}
`;

/**
 * __useTuitionNamesQuery__
 *
 * To run a query within a React component, call `useTuitionNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTuitionNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTuitionNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useTuitionNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<TuitionNamesQuery, TuitionNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<TuitionNamesQuery, TuitionNamesQueryVariables>(TuitionNamesDocument, options);
}
export function useTuitionNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<TuitionNamesQuery, TuitionNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<TuitionNamesQuery, TuitionNamesQueryVariables>(TuitionNamesDocument, options);
}
export type TuitionNamesQueryHookResult = ReturnType<typeof useTuitionNamesQuery>;
export type TuitionNamesLazyQueryHookResult = ReturnType<typeof useTuitionNamesLazyQuery>;
export type TuitionNamesQueryResult = Apollo.QueryResult<TuitionNamesQuery, TuitionNamesQueryVariables>;
export const TuitionPaginationDocument = gql`
	query tuitionPagination($filters: [TuitionFilterInput!], $order: TuitionOrder) {
		tuitionConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useTuitionPaginationQuery__
 *
 * To run a query within a React component, call `useTuitionPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useTuitionPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTuitionPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useTuitionPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<TuitionPaginationQuery, TuitionPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<TuitionPaginationQuery, TuitionPaginationQueryVariables>(TuitionPaginationDocument, options);
}
export function useTuitionPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<TuitionPaginationQuery, TuitionPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<TuitionPaginationQuery, TuitionPaginationQueryVariables>(
		TuitionPaginationDocument,
		options,
	);
}
export type TuitionPaginationQueryHookResult = ReturnType<typeof useTuitionPaginationQuery>;
export type TuitionPaginationLazyQueryHookResult = ReturnType<typeof useTuitionPaginationLazyQuery>;
export type TuitionPaginationQueryResult = Apollo.QueryResult<TuitionPaginationQuery, TuitionPaginationQueryVariables>;
export const TuitionSelectDocument = gql`
	query tuitionSelect($filters: [TuitionFilterInput!], $order: TuitionOrder) {
		tuitionConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					name
				}
			}
		}
	}
`;

/**
 * __useTuitionSelectQuery__
 *
 * To run a query within a React component, call `useTuitionSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useTuitionSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTuitionSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useTuitionSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<TuitionSelectQuery, TuitionSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<TuitionSelectQuery, TuitionSelectQueryVariables>(TuitionSelectDocument, options);
}
export function useTuitionSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<TuitionSelectQuery, TuitionSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<TuitionSelectQuery, TuitionSelectQueryVariables>(TuitionSelectDocument, options);
}
export type TuitionSelectQueryHookResult = ReturnType<typeof useTuitionSelectQuery>;
export type TuitionSelectLazyQueryHookResult = ReturnType<typeof useTuitionSelectLazyQuery>;
export type TuitionSelectQueryResult = Apollo.QueryResult<TuitionSelectQuery, TuitionSelectQueryVariables>;
export const TuitionUpdateDocument = gql`
	mutation tuitionUpdate($id: ID!, $input: TuitionUpdateInput!) {
		updateTuition(id: $id, input: $input) {
			...tuitionDetailFields
		}
	}
	${TuitionDetailFieldsFragmentDoc}
`;
export type TuitionUpdateMutationFn = Apollo.MutationFunction<TuitionUpdateMutation, TuitionUpdateMutationVariables>;

/**
 * __useTuitionUpdateMutation__
 *
 * To run a mutation, you first call `useTuitionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTuitionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tuitionUpdateMutation, { data, loading, error }] = useTuitionUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTuitionUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<TuitionUpdateMutation, TuitionUpdateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<TuitionUpdateMutation, TuitionUpdateMutationVariables>(TuitionUpdateDocument, options);
}
export type TuitionUpdateMutationHookResult = ReturnType<typeof useTuitionUpdateMutation>;
export type TuitionUpdateMutationResult = Apollo.MutationResult<TuitionUpdateMutation>;
export type TuitionUpdateMutationOptions = Apollo.BaseMutationOptions<
	TuitionUpdateMutation,
	TuitionUpdateMutationVariables
>;
export const UserCheckUniqueEmailDocument = gql`
	query userCheckUniqueEmail($ignoreID: ID, $email: String!) {
		userConnection(filters: { idNEQ: $ignoreID, emailEQ: $email }) {
			totalCount
		}
	}
`;

/**
 * __useUserCheckUniqueEmailQuery__
 *
 * To run a query within a React component, call `useUserCheckUniqueEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCheckUniqueEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCheckUniqueEmailQuery({
 *   variables: {
 *      ignoreID: // value for 'ignoreID'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUserCheckUniqueEmailQuery(
	baseOptions: Apollo.QueryHookOptions<UserCheckUniqueEmailQuery, UserCheckUniqueEmailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<UserCheckUniqueEmailQuery, UserCheckUniqueEmailQueryVariables>(
		UserCheckUniqueEmailDocument,
		options,
	);
}
export function useUserCheckUniqueEmailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<UserCheckUniqueEmailQuery, UserCheckUniqueEmailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<UserCheckUniqueEmailQuery, UserCheckUniqueEmailQueryVariables>(
		UserCheckUniqueEmailDocument,
		options,
	);
}
export type UserCheckUniqueEmailQueryHookResult = ReturnType<typeof useUserCheckUniqueEmailQuery>;
export type UserCheckUniqueEmailLazyQueryHookResult = ReturnType<typeof useUserCheckUniqueEmailLazyQuery>;
export type UserCheckUniqueEmailQueryResult = Apollo.QueryResult<
	UserCheckUniqueEmailQuery,
	UserCheckUniqueEmailQueryVariables
>;
export const UserCountDocument = gql`
	query userCount($filters: [UserFilterInput!], $pagination: Pagination, $order: UserOrder) {
		userConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useUserCountQuery__
 *
 * To run a query within a React component, call `useUserCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useUserCountQuery(baseOptions?: Apollo.QueryHookOptions<UserCountQuery, UserCountQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<UserCountQuery, UserCountQueryVariables>(UserCountDocument, options);
}
export function useUserCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<UserCountQuery, UserCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<UserCountQuery, UserCountQueryVariables>(UserCountDocument, options);
}
export type UserCountQueryHookResult = ReturnType<typeof useUserCountQuery>;
export type UserCountLazyQueryHookResult = ReturnType<typeof useUserCountLazyQuery>;
export type UserCountQueryResult = Apollo.QueryResult<UserCountQuery, UserCountQueryVariables>;
export const UserCreateDocument = gql`
	mutation userCreate($input: UserCreateInput!) {
		createUser(input: $input) {
			...UserDetail
		}
	}
	${UserDetailFragmentDoc}
`;
export type UserCreateMutationFn = Apollo.MutationFunction<UserCreateMutation, UserCreateMutationVariables>;

/**
 * __useUserCreateMutation__
 *
 * To run a mutation, you first call `useUserCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userCreateMutation, { data, loading, error }] = useUserCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<UserCreateMutation, UserCreateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<UserCreateMutation, UserCreateMutationVariables>(UserCreateDocument, options);
}
export type UserCreateMutationHookResult = ReturnType<typeof useUserCreateMutation>;
export type UserCreateMutationResult = Apollo.MutationResult<UserCreateMutation>;
export type UserCreateMutationOptions = Apollo.BaseMutationOptions<UserCreateMutation, UserCreateMutationVariables>;
export const CurrentUserPersonDocument = gql`
	query currentUserPerson {
		viewer {
			id
			person {
				id
				name
				imageURL
			}
		}
	}
`;

/**
 * __useCurrentUserPersonQuery__
 *
 * To run a query within a React component, call `useCurrentUserPersonQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserPersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserPersonQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserPersonQuery(
	baseOptions?: Apollo.QueryHookOptions<CurrentUserPersonQuery, CurrentUserPersonQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<CurrentUserPersonQuery, CurrentUserPersonQueryVariables>(CurrentUserPersonDocument, options);
}
export function useCurrentUserPersonLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserPersonQuery, CurrentUserPersonQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<CurrentUserPersonQuery, CurrentUserPersonQueryVariables>(
		CurrentUserPersonDocument,
		options,
	);
}
export type CurrentUserPersonQueryHookResult = ReturnType<typeof useCurrentUserPersonQuery>;
export type CurrentUserPersonLazyQueryHookResult = ReturnType<typeof useCurrentUserPersonLazyQuery>;
export type CurrentUserPersonQueryResult = Apollo.QueryResult<CurrentUserPersonQuery, CurrentUserPersonQueryVariables>;
export const UserDeleteDocument = gql`
	mutation userDelete($id: ID!) {
		deleteUser(id: $id)
	}
`;
export type UserDeleteMutationFn = Apollo.MutationFunction<UserDeleteMutation, UserDeleteMutationVariables>;

/**
 * __useUserDeleteMutation__
 *
 * To run a mutation, you first call `useUserDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userDeleteMutation, { data, loading, error }] = useUserDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<UserDeleteMutation, UserDeleteMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<UserDeleteMutation, UserDeleteMutationVariables>(UserDeleteDocument, options);
}
export type UserDeleteMutationHookResult = ReturnType<typeof useUserDeleteMutation>;
export type UserDeleteMutationResult = Apollo.MutationResult<UserDeleteMutation>;
export type UserDeleteMutationOptions = Apollo.BaseMutationOptions<UserDeleteMutation, UserDeleteMutationVariables>;
export const UserDetailDocument = gql`
	query userDetail($id: ID!) {
		user(id: $id) {
			...UserDetail
		}
	}
	${UserDetailFragmentDoc}
`;

/**
 * __useUserDetailQuery__
 *
 * To run a query within a React component, call `useUserDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserDetailQuery(baseOptions: Apollo.QueryHookOptions<UserDetailQuery, UserDetailQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<UserDetailQuery, UserDetailQueryVariables>(UserDetailDocument, options);
}
export function useUserDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<UserDetailQuery, UserDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<UserDetailQuery, UserDetailQueryVariables>(UserDetailDocument, options);
}
export type UserDetailQueryHookResult = ReturnType<typeof useUserDetailQuery>;
export type UserDetailLazyQueryHookResult = ReturnType<typeof useUserDetailLazyQuery>;
export type UserDetailQueryResult = Apollo.QueryResult<UserDetailQuery, UserDetailQueryVariables>;
export const UserIndexDocument = gql`
	query userIndex($filters: [UserFilterInput!], $pagination: Pagination, $order: UserOrder) {
		userConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					email
					status
					passwordUpdatedAt
				}
			}
		}
	}
`;

/**
 * __useUserIndexQuery__
 *
 * To run a query within a React component, call `useUserIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useUserIndexQuery(baseOptions?: Apollo.QueryHookOptions<UserIndexQuery, UserIndexQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<UserIndexQuery, UserIndexQueryVariables>(UserIndexDocument, options);
}
export function useUserIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<UserIndexQuery, UserIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<UserIndexQuery, UserIndexQueryVariables>(UserIndexDocument, options);
}
export type UserIndexQueryHookResult = ReturnType<typeof useUserIndexQuery>;
export type UserIndexLazyQueryHookResult = ReturnType<typeof useUserIndexLazyQuery>;
export type UserIndexQueryResult = Apollo.QueryResult<UserIndexQuery, UserIndexQueryVariables>;
export const UserNameDocument = gql`
	query userName($id: ID!) {
		user(id: $id) {
			id
			...UserName
		}
	}
	${UserNameFragmentDoc}
`;

/**
 * __useUserNameQuery__
 *
 * To run a query within a React component, call `useUserNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserNameQuery(baseOptions: Apollo.QueryHookOptions<UserNameQuery, UserNameQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<UserNameQuery, UserNameQueryVariables>(UserNameDocument, options);
}
export function useUserNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserNameQuery, UserNameQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<UserNameQuery, UserNameQueryVariables>(UserNameDocument, options);
}
export type UserNameQueryHookResult = ReturnType<typeof useUserNameQuery>;
export type UserNameLazyQueryHookResult = ReturnType<typeof useUserNameLazyQuery>;
export type UserNameQueryResult = Apollo.QueryResult<UserNameQuery, UserNameQueryVariables>;
export const UserNamesDocument = gql`
	query userNames($filters: [UserFilterInput!], $pagination: Pagination, $order: UserOrder) {
		userConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...UserName
				}
			}
		}
	}
	${UserNameFragmentDoc}
`;

/**
 * __useUserNamesQuery__
 *
 * To run a query within a React component, call `useUserNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useUserNamesQuery(baseOptions?: Apollo.QueryHookOptions<UserNamesQuery, UserNamesQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<UserNamesQuery, UserNamesQueryVariables>(UserNamesDocument, options);
}
export function useUserNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<UserNamesQuery, UserNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<UserNamesQuery, UserNamesQueryVariables>(UserNamesDocument, options);
}
export type UserNamesQueryHookResult = ReturnType<typeof useUserNamesQuery>;
export type UserNamesLazyQueryHookResult = ReturnType<typeof useUserNamesLazyQuery>;
export type UserNamesQueryResult = Apollo.QueryResult<UserNamesQuery, UserNamesQueryVariables>;
export const UserPaginationDocument = gql`
	query userPagination($filters: [UserFilterInput!], $order: UserOrder) {
		userConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useUserPaginationQuery__
 *
 * To run a query within a React component, call `useUserPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useUserPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<UserPaginationQuery, UserPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<UserPaginationQuery, UserPaginationQueryVariables>(UserPaginationDocument, options);
}
export function useUserPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<UserPaginationQuery, UserPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<UserPaginationQuery, UserPaginationQueryVariables>(UserPaginationDocument, options);
}
export type UserPaginationQueryHookResult = ReturnType<typeof useUserPaginationQuery>;
export type UserPaginationLazyQueryHookResult = ReturnType<typeof useUserPaginationLazyQuery>;
export type UserPaginationQueryResult = Apollo.QueryResult<UserPaginationQuery, UserPaginationQueryVariables>;
export const UserSelectDocument = gql`
	query userSelect($filters: [UserFilterInput!], $order: UserOrder) {
		userConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...UserName
				}
			}
		}
	}
	${UserNameFragmentDoc}
`;

/**
 * __useUserSelectQuery__
 *
 * To run a query within a React component, call `useUserSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useUserSelectQuery(baseOptions?: Apollo.QueryHookOptions<UserSelectQuery, UserSelectQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<UserSelectQuery, UserSelectQueryVariables>(UserSelectDocument, options);
}
export function useUserSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<UserSelectQuery, UserSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<UserSelectQuery, UserSelectQueryVariables>(UserSelectDocument, options);
}
export type UserSelectQueryHookResult = ReturnType<typeof useUserSelectQuery>;
export type UserSelectLazyQueryHookResult = ReturnType<typeof useUserSelectLazyQuery>;
export type UserSelectQueryResult = Apollo.QueryResult<UserSelectQuery, UserSelectQueryVariables>;
export const UserUpdateDocument = gql`
	mutation userUpdate($id: ID!, $input: UserUpdateInput!) {
		updateUser(id: $id, input: $input) {
			...UserDetail
		}
	}
	${UserDetailFragmentDoc}
`;
export type UserUpdateMutationFn = Apollo.MutationFunction<UserUpdateMutation, UserUpdateMutationVariables>;

/**
 * __useUserUpdateMutation__
 *
 * To run a mutation, you first call `useUserUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdateMutation, { data, loading, error }] = useUserUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<UserUpdateMutation, UserUpdateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<UserUpdateMutation, UserUpdateMutationVariables>(UserUpdateDocument, options);
}
export type UserUpdateMutationHookResult = ReturnType<typeof useUserUpdateMutation>;
export type UserUpdateMutationResult = Apollo.MutationResult<UserUpdateMutation>;
export type UserUpdateMutationOptions = Apollo.BaseMutationOptions<UserUpdateMutation, UserUpdateMutationVariables>;
export const UserGroupCheckUniqueNameDocument = gql`
	query userGroupCheckUniqueName($ignoreID: ID, $name: String!) {
		userGroupConnection(filters: { idNEQ: $ignoreID, nameEQ: $name }) {
			totalCount
		}
	}
`;

/**
 * __useUserGroupCheckUniqueNameQuery__
 *
 * To run a query within a React component, call `useUserGroupCheckUniqueNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGroupCheckUniqueNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGroupCheckUniqueNameQuery({
 *   variables: {
 *      ignoreID: // value for 'ignoreID'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUserGroupCheckUniqueNameQuery(
	baseOptions: Apollo.QueryHookOptions<UserGroupCheckUniqueNameQuery, UserGroupCheckUniqueNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<UserGroupCheckUniqueNameQuery, UserGroupCheckUniqueNameQueryVariables>(
		UserGroupCheckUniqueNameDocument,
		options,
	);
}
export function useUserGroupCheckUniqueNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<UserGroupCheckUniqueNameQuery, UserGroupCheckUniqueNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<UserGroupCheckUniqueNameQuery, UserGroupCheckUniqueNameQueryVariables>(
		UserGroupCheckUniqueNameDocument,
		options,
	);
}
export type UserGroupCheckUniqueNameQueryHookResult = ReturnType<typeof useUserGroupCheckUniqueNameQuery>;
export type UserGroupCheckUniqueNameLazyQueryHookResult = ReturnType<typeof useUserGroupCheckUniqueNameLazyQuery>;
export type UserGroupCheckUniqueNameQueryResult = Apollo.QueryResult<
	UserGroupCheckUniqueNameQuery,
	UserGroupCheckUniqueNameQueryVariables
>;
export const UserGroupCountDocument = gql`
	query userGroupCount($filters: [UserGroupFilterInput!], $pagination: Pagination, $order: UserGroupOrder) {
		userGroupConnection(filters: $filters, pagination: $pagination, order: $order) {
			totalCount
		}
	}
`;

/**
 * __useUserGroupCountQuery__
 *
 * To run a query within a React component, call `useUserGroupCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGroupCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGroupCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useUserGroupCountQuery(
	baseOptions?: Apollo.QueryHookOptions<UserGroupCountQuery, UserGroupCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<UserGroupCountQuery, UserGroupCountQueryVariables>(UserGroupCountDocument, options);
}
export function useUserGroupCountLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<UserGroupCountQuery, UserGroupCountQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<UserGroupCountQuery, UserGroupCountQueryVariables>(UserGroupCountDocument, options);
}
export type UserGroupCountQueryHookResult = ReturnType<typeof useUserGroupCountQuery>;
export type UserGroupCountLazyQueryHookResult = ReturnType<typeof useUserGroupCountLazyQuery>;
export type UserGroupCountQueryResult = Apollo.QueryResult<UserGroupCountQuery, UserGroupCountQueryVariables>;
export const UserGroupCreateDocument = gql`
	mutation userGroupCreate($input: UserGroupCreateInput!) {
		createUserGroup(input: $input) {
			...UserGroupDetail
		}
	}
	${UserGroupDetailFragmentDoc}
`;
export type UserGroupCreateMutationFn = Apollo.MutationFunction<
	UserGroupCreateMutation,
	UserGroupCreateMutationVariables
>;

/**
 * __useUserGroupCreateMutation__
 *
 * To run a mutation, you first call `useUserGroupCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserGroupCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userGroupCreateMutation, { data, loading, error }] = useUserGroupCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserGroupCreateMutation(
	baseOptions?: Apollo.MutationHookOptions<UserGroupCreateMutation, UserGroupCreateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<UserGroupCreateMutation, UserGroupCreateMutationVariables>(
		UserGroupCreateDocument,
		options,
	);
}
export type UserGroupCreateMutationHookResult = ReturnType<typeof useUserGroupCreateMutation>;
export type UserGroupCreateMutationResult = Apollo.MutationResult<UserGroupCreateMutation>;
export type UserGroupCreateMutationOptions = Apollo.BaseMutationOptions<
	UserGroupCreateMutation,
	UserGroupCreateMutationVariables
>;
export const UserGroupDeleteDocument = gql`
	mutation userGroupDelete($id: ID!) {
		deleteUserGroup(id: $id)
	}
`;
export type UserGroupDeleteMutationFn = Apollo.MutationFunction<
	UserGroupDeleteMutation,
	UserGroupDeleteMutationVariables
>;

/**
 * __useUserGroupDeleteMutation__
 *
 * To run a mutation, you first call `useUserGroupDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserGroupDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userGroupDeleteMutation, { data, loading, error }] = useUserGroupDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserGroupDeleteMutation(
	baseOptions?: Apollo.MutationHookOptions<UserGroupDeleteMutation, UserGroupDeleteMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<UserGroupDeleteMutation, UserGroupDeleteMutationVariables>(
		UserGroupDeleteDocument,
		options,
	);
}
export type UserGroupDeleteMutationHookResult = ReturnType<typeof useUserGroupDeleteMutation>;
export type UserGroupDeleteMutationResult = Apollo.MutationResult<UserGroupDeleteMutation>;
export type UserGroupDeleteMutationOptions = Apollo.BaseMutationOptions<
	UserGroupDeleteMutation,
	UserGroupDeleteMutationVariables
>;
export const UserGroupDetailDocument = gql`
	query userGroupDetail($id: ID!) {
		userGroup(id: $id) {
			...UserGroupDetail
		}
	}
	${UserGroupDetailFragmentDoc}
`;

/**
 * __useUserGroupDetailQuery__
 *
 * To run a query within a React component, call `useUserGroupDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGroupDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGroupDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserGroupDetailQuery(
	baseOptions: Apollo.QueryHookOptions<UserGroupDetailQuery, UserGroupDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<UserGroupDetailQuery, UserGroupDetailQueryVariables>(UserGroupDetailDocument, options);
}
export function useUserGroupDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<UserGroupDetailQuery, UserGroupDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<UserGroupDetailQuery, UserGroupDetailQueryVariables>(UserGroupDetailDocument, options);
}
export type UserGroupDetailQueryHookResult = ReturnType<typeof useUserGroupDetailQuery>;
export type UserGroupDetailLazyQueryHookResult = ReturnType<typeof useUserGroupDetailLazyQuery>;
export type UserGroupDetailQueryResult = Apollo.QueryResult<UserGroupDetailQuery, UserGroupDetailQueryVariables>;
export const UserGroupIndexDocument = gql`
	query userGroupIndex($filters: [UserGroupFilterInput!], $pagination: Pagination, $order: UserGroupOrder) {
		userGroupConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					name
					description
					imageURL
					color
				}
			}
		}
	}
`;

/**
 * __useUserGroupIndexQuery__
 *
 * To run a query within a React component, call `useUserGroupIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGroupIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGroupIndexQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useUserGroupIndexQuery(
	baseOptions?: Apollo.QueryHookOptions<UserGroupIndexQuery, UserGroupIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<UserGroupIndexQuery, UserGroupIndexQueryVariables>(UserGroupIndexDocument, options);
}
export function useUserGroupIndexLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<UserGroupIndexQuery, UserGroupIndexQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<UserGroupIndexQuery, UserGroupIndexQueryVariables>(UserGroupIndexDocument, options);
}
export type UserGroupIndexQueryHookResult = ReturnType<typeof useUserGroupIndexQuery>;
export type UserGroupIndexLazyQueryHookResult = ReturnType<typeof useUserGroupIndexLazyQuery>;
export type UserGroupIndexQueryResult = Apollo.QueryResult<UserGroupIndexQuery, UserGroupIndexQueryVariables>;
export const UserGroupNameDocument = gql`
	query userGroupName($id: ID!) {
		userGroup(id: $id) {
			id
			...UserGroupName
		}
	}
	${UserGroupNameFragmentDoc}
`;

/**
 * __useUserGroupNameQuery__
 *
 * To run a query within a React component, call `useUserGroupNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGroupNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGroupNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserGroupNameQuery(
	baseOptions: Apollo.QueryHookOptions<UserGroupNameQuery, UserGroupNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<UserGroupNameQuery, UserGroupNameQueryVariables>(UserGroupNameDocument, options);
}
export function useUserGroupNameLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<UserGroupNameQuery, UserGroupNameQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<UserGroupNameQuery, UserGroupNameQueryVariables>(UserGroupNameDocument, options);
}
export type UserGroupNameQueryHookResult = ReturnType<typeof useUserGroupNameQuery>;
export type UserGroupNameLazyQueryHookResult = ReturnType<typeof useUserGroupNameLazyQuery>;
export type UserGroupNameQueryResult = Apollo.QueryResult<UserGroupNameQuery, UserGroupNameQueryVariables>;
export const UserGroupNamesDocument = gql`
	query userGroupNames($filters: [UserGroupFilterInput!], $pagination: Pagination, $order: UserGroupOrder) {
		userGroupConnection(filters: $filters, pagination: $pagination, order: $order) {
			edges {
				node {
					id
					...UserGroupName
				}
			}
		}
	}
	${UserGroupNameFragmentDoc}
`;

/**
 * __useUserGroupNamesQuery__
 *
 * To run a query within a React component, call `useUserGroupNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGroupNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGroupNamesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useUserGroupNamesQuery(
	baseOptions?: Apollo.QueryHookOptions<UserGroupNamesQuery, UserGroupNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<UserGroupNamesQuery, UserGroupNamesQueryVariables>(UserGroupNamesDocument, options);
}
export function useUserGroupNamesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<UserGroupNamesQuery, UserGroupNamesQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<UserGroupNamesQuery, UserGroupNamesQueryVariables>(UserGroupNamesDocument, options);
}
export type UserGroupNamesQueryHookResult = ReturnType<typeof useUserGroupNamesQuery>;
export type UserGroupNamesLazyQueryHookResult = ReturnType<typeof useUserGroupNamesLazyQuery>;
export type UserGroupNamesQueryResult = Apollo.QueryResult<UserGroupNamesQuery, UserGroupNamesQueryVariables>;
export const UserGroupPaginationDocument = gql`
	query userGroupPagination($filters: [UserGroupFilterInput!], $order: UserGroupOrder) {
		userGroupConnection(filters: $filters, order: $order) {
			totalCount
			edges {
				cursor
			}
		}
	}
`;

/**
 * __useUserGroupPaginationQuery__
 *
 * To run a query within a React component, call `useUserGroupPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGroupPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGroupPaginationQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useUserGroupPaginationQuery(
	baseOptions?: Apollo.QueryHookOptions<UserGroupPaginationQuery, UserGroupPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<UserGroupPaginationQuery, UserGroupPaginationQueryVariables>(
		UserGroupPaginationDocument,
		options,
	);
}
export function useUserGroupPaginationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<UserGroupPaginationQuery, UserGroupPaginationQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<UserGroupPaginationQuery, UserGroupPaginationQueryVariables>(
		UserGroupPaginationDocument,
		options,
	);
}
export type UserGroupPaginationQueryHookResult = ReturnType<typeof useUserGroupPaginationQuery>;
export type UserGroupPaginationLazyQueryHookResult = ReturnType<typeof useUserGroupPaginationLazyQuery>;
export type UserGroupPaginationQueryResult = Apollo.QueryResult<
	UserGroupPaginationQuery,
	UserGroupPaginationQueryVariables
>;
export const UserGroupSelectDocument = gql`
	query userGroupSelect($filters: [UserGroupFilterInput!], $order: UserGroupOrder) {
		userGroupConnection(filters: $filters, order: $order) {
			edges {
				node {
					id
					...UserGroupName
				}
			}
		}
	}
	${UserGroupNameFragmentDoc}
`;

/**
 * __useUserGroupSelectQuery__
 *
 * To run a query within a React component, call `useUserGroupSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGroupSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGroupSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useUserGroupSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<UserGroupSelectQuery, UserGroupSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<UserGroupSelectQuery, UserGroupSelectQueryVariables>(UserGroupSelectDocument, options);
}
export function useUserGroupSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<UserGroupSelectQuery, UserGroupSelectQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<UserGroupSelectQuery, UserGroupSelectQueryVariables>(UserGroupSelectDocument, options);
}
export type UserGroupSelectQueryHookResult = ReturnType<typeof useUserGroupSelectQuery>;
export type UserGroupSelectLazyQueryHookResult = ReturnType<typeof useUserGroupSelectLazyQuery>;
export type UserGroupSelectQueryResult = Apollo.QueryResult<UserGroupSelectQuery, UserGroupSelectQueryVariables>;
export const UserGroupUpdateDocument = gql`
	mutation userGroupUpdate($id: ID!, $input: UserGroupUpdateInput!) {
		updateUserGroup(id: $id, input: $input) {
			...UserGroupDetail
		}
	}
	${UserGroupDetailFragmentDoc}
`;
export type UserGroupUpdateMutationFn = Apollo.MutationFunction<
	UserGroupUpdateMutation,
	UserGroupUpdateMutationVariables
>;

/**
 * __useUserGroupUpdateMutation__
 *
 * To run a mutation, you first call `useUserGroupUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserGroupUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userGroupUpdateMutation, { data, loading, error }] = useUserGroupUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserGroupUpdateMutation(
	baseOptions?: Apollo.MutationHookOptions<UserGroupUpdateMutation, UserGroupUpdateMutationVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<UserGroupUpdateMutation, UserGroupUpdateMutationVariables>(
		UserGroupUpdateDocument,
		options,
	);
}
export type UserGroupUpdateMutationHookResult = ReturnType<typeof useUserGroupUpdateMutation>;
export type UserGroupUpdateMutationResult = Apollo.MutationResult<UserGroupUpdateMutation>;
export type UserGroupUpdateMutationOptions = Apollo.BaseMutationOptions<
	UserGroupUpdateMutation,
	UserGroupUpdateMutationVariables
>;
export const SendEnrollmentApplicationInviteEmailDocument = gql`
	mutation sendEnrollmentApplicationInviteEmail($recipientAddresses: [String!]!, $subject: String!, $body: String!) {
		sendEnrollmentApplicationInviteEmail(recipientAddresses: $recipientAddresses, subject: $subject, body: $body)
	}
`;
export type SendEnrollmentApplicationInviteEmailMutationFn = Apollo.MutationFunction<
	SendEnrollmentApplicationInviteEmailMutation,
	SendEnrollmentApplicationInviteEmailMutationVariables
>;

/**
 * __useSendEnrollmentApplicationInviteEmailMutation__
 *
 * To run a mutation, you first call `useSendEnrollmentApplicationInviteEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEnrollmentApplicationInviteEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEnrollmentApplicationInviteEmailMutation, { data, loading, error }] = useSendEnrollmentApplicationInviteEmailMutation({
 *   variables: {
 *      recipientAddresses: // value for 'recipientAddresses'
 *      subject: // value for 'subject'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useSendEnrollmentApplicationInviteEmailMutation(
	baseOptions?: Apollo.MutationHookOptions<
		SendEnrollmentApplicationInviteEmailMutation,
		SendEnrollmentApplicationInviteEmailMutationVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		SendEnrollmentApplicationInviteEmailMutation,
		SendEnrollmentApplicationInviteEmailMutationVariables
	>(SendEnrollmentApplicationInviteEmailDocument, options);
}
export type SendEnrollmentApplicationInviteEmailMutationHookResult = ReturnType<
	typeof useSendEnrollmentApplicationInviteEmailMutation
>;
export type SendEnrollmentApplicationInviteEmailMutationResult =
	Apollo.MutationResult<SendEnrollmentApplicationInviteEmailMutation>;
export type SendEnrollmentApplicationInviteEmailMutationOptions = Apollo.BaseMutationOptions<
	SendEnrollmentApplicationInviteEmailMutation,
	SendEnrollmentApplicationInviteEmailMutationVariables
>;
export const GlobalSearchDetailDocument = gql`
	query globalSearchDetail($id: ID!) {
		searchResult(id: $id) {
			id
			name
			modelName
			searchText
		}
	}
`;

/**
 * __useGlobalSearchDetailQuery__
 *
 * To run a query within a React component, call `useGlobalSearchDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlobalSearchDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobalSearchDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGlobalSearchDetailQuery(
	baseOptions: Apollo.QueryHookOptions<GlobalSearchDetailQuery, GlobalSearchDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<GlobalSearchDetailQuery, GlobalSearchDetailQueryVariables>(
		GlobalSearchDetailDocument,
		options,
	);
}
export function useGlobalSearchDetailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GlobalSearchDetailQuery, GlobalSearchDetailQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<GlobalSearchDetailQuery, GlobalSearchDetailQueryVariables>(
		GlobalSearchDetailDocument,
		options,
	);
}
export type GlobalSearchDetailQueryHookResult = ReturnType<typeof useGlobalSearchDetailQuery>;
export type GlobalSearchDetailLazyQueryHookResult = ReturnType<typeof useGlobalSearchDetailLazyQuery>;
export type GlobalSearchDetailQueryResult = Apollo.QueryResult<
	GlobalSearchDetailQuery,
	GlobalSearchDetailQueryVariables
>;
export const GlobalSearchDocument = gql`
	query globalSearch($filters: [SearchResultFilterInput!]!) {
		searchResultConnection(filters: $filters, pagination: { first: 100 }) {
			edges {
				node {
					id
					name
					modelName
					searchText
				}
			}
		}
	}
`;

/**
 * __useGlobalSearchQuery__
 *
 * To run a query within a React component, call `useGlobalSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlobalSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobalSearchQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGlobalSearchQuery(
	baseOptions: Apollo.QueryHookOptions<GlobalSearchQuery, GlobalSearchQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<GlobalSearchQuery, GlobalSearchQueryVariables>(GlobalSearchDocument, options);
}
export function useGlobalSearchLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GlobalSearchQuery, GlobalSearchQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<GlobalSearchQuery, GlobalSearchQueryVariables>(GlobalSearchDocument, options);
}
export type GlobalSearchQueryHookResult = ReturnType<typeof useGlobalSearchQuery>;
export type GlobalSearchLazyQueryHookResult = ReturnType<typeof useGlobalSearchLazyQuery>;
export type GlobalSearchQueryResult = Apollo.QueryResult<GlobalSearchQuery, GlobalSearchQueryVariables>;
export const ModelIDsDocument = gql`
	query modelIDs {
		modelIDs {
			modelName
			minID
			maxID
		}
	}
`;

/**
 * __useModelIDsQuery__
 *
 * To run a query within a React component, call `useModelIDsQuery` and pass it any options that fit your needs.
 * When your component renders, `useModelIDsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModelIDsQuery({
 *   variables: {
 *   },
 * });
 */
export function useModelIDsQuery(baseOptions?: Apollo.QueryHookOptions<ModelIDsQuery, ModelIDsQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ModelIDsQuery, ModelIDsQueryVariables>(ModelIDsDocument, options);
}
export function useModelIDsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ModelIDsQuery, ModelIDsQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ModelIDsQuery, ModelIDsQueryVariables>(ModelIDsDocument, options);
}
export type ModelIDsQueryHookResult = ReturnType<typeof useModelIDsQuery>;
export type ModelIDsLazyQueryHookResult = ReturnType<typeof useModelIDsLazyQuery>;
export type ModelIDsQueryResult = Apollo.QueryResult<ModelIDsQuery, ModelIDsQueryVariables>;
export const AdmissionsApplicationDailyCountsReportDocument = gql`
	query admissionsApplicationDailyCountsReport($filter: AdmissionsApplicationDailyCountsReportFilterInput!) {
		admissionsApplicationDailyCountsReport(filter: $filter) {
			data {
				date
				value
			}
		}
	}
`;

/**
 * __useAdmissionsApplicationDailyCountsReportQuery__
 *
 * To run a query within a React component, call `useAdmissionsApplicationDailyCountsReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdmissionsApplicationDailyCountsReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdmissionsApplicationDailyCountsReportQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAdmissionsApplicationDailyCountsReportQuery(
	baseOptions: Apollo.QueryHookOptions<
		AdmissionsApplicationDailyCountsReportQuery,
		AdmissionsApplicationDailyCountsReportQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		AdmissionsApplicationDailyCountsReportQuery,
		AdmissionsApplicationDailyCountsReportQueryVariables
	>(AdmissionsApplicationDailyCountsReportDocument, options);
}
export function useAdmissionsApplicationDailyCountsReportLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		AdmissionsApplicationDailyCountsReportQuery,
		AdmissionsApplicationDailyCountsReportQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		AdmissionsApplicationDailyCountsReportQuery,
		AdmissionsApplicationDailyCountsReportQueryVariables
	>(AdmissionsApplicationDailyCountsReportDocument, options);
}
export type AdmissionsApplicationDailyCountsReportQueryHookResult = ReturnType<
	typeof useAdmissionsApplicationDailyCountsReportQuery
>;
export type AdmissionsApplicationDailyCountsReportLazyQueryHookResult = ReturnType<
	typeof useAdmissionsApplicationDailyCountsReportLazyQuery
>;
export type AdmissionsApplicationDailyCountsReportQueryResult = Apollo.QueryResult<
	AdmissionsApplicationDailyCountsReportQuery,
	AdmissionsApplicationDailyCountsReportQueryVariables
>;
export const AdmissionsApplicationsByGradeReportDocument = gql`
	query admissionsApplicationsByGradeReport {
		admissionsApplicationsByGradeReport {
			data {
				grade
				value
			}
		}
	}
`;

/**
 * __useAdmissionsApplicationsByGradeReportQuery__
 *
 * To run a query within a React component, call `useAdmissionsApplicationsByGradeReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdmissionsApplicationsByGradeReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdmissionsApplicationsByGradeReportQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdmissionsApplicationsByGradeReportQuery(
	baseOptions?: Apollo.QueryHookOptions<
		AdmissionsApplicationsByGradeReportQuery,
		AdmissionsApplicationsByGradeReportQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<AdmissionsApplicationsByGradeReportQuery, AdmissionsApplicationsByGradeReportQueryVariables>(
		AdmissionsApplicationsByGradeReportDocument,
		options,
	);
}
export function useAdmissionsApplicationsByGradeReportLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		AdmissionsApplicationsByGradeReportQuery,
		AdmissionsApplicationsByGradeReportQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		AdmissionsApplicationsByGradeReportQuery,
		AdmissionsApplicationsByGradeReportQueryVariables
	>(AdmissionsApplicationsByGradeReportDocument, options);
}
export type AdmissionsApplicationsByGradeReportQueryHookResult = ReturnType<
	typeof useAdmissionsApplicationsByGradeReportQuery
>;
export type AdmissionsApplicationsByGradeReportLazyQueryHookResult = ReturnType<
	typeof useAdmissionsApplicationsByGradeReportLazyQuery
>;
export type AdmissionsApplicationsByGradeReportQueryResult = Apollo.QueryResult<
	AdmissionsApplicationsByGradeReportQuery,
	AdmissionsApplicationsByGradeReportQueryVariables
>;
export const AdmissionsEnrollmentsByGradeReportDocument = gql`
	query admissionsEnrollmentsByGradeReport {
		admissionsEnrollmentsByGradeReport {
			data {
				grade
				value
				reenrollmentValue
				newEnrollmentValue
			}
		}
	}
`;

/**
 * __useAdmissionsEnrollmentsByGradeReportQuery__
 *
 * To run a query within a React component, call `useAdmissionsEnrollmentsByGradeReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdmissionsEnrollmentsByGradeReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdmissionsEnrollmentsByGradeReportQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdmissionsEnrollmentsByGradeReportQuery(
	baseOptions?: Apollo.QueryHookOptions<
		AdmissionsEnrollmentsByGradeReportQuery,
		AdmissionsEnrollmentsByGradeReportQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<AdmissionsEnrollmentsByGradeReportQuery, AdmissionsEnrollmentsByGradeReportQueryVariables>(
		AdmissionsEnrollmentsByGradeReportDocument,
		options,
	);
}
export function useAdmissionsEnrollmentsByGradeReportLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		AdmissionsEnrollmentsByGradeReportQuery,
		AdmissionsEnrollmentsByGradeReportQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<AdmissionsEnrollmentsByGradeReportQuery, AdmissionsEnrollmentsByGradeReportQueryVariables>(
		AdmissionsEnrollmentsByGradeReportDocument,
		options,
	);
}
export type AdmissionsEnrollmentsByGradeReportQueryHookResult = ReturnType<
	typeof useAdmissionsEnrollmentsByGradeReportQuery
>;
export type AdmissionsEnrollmentsByGradeReportLazyQueryHookResult = ReturnType<
	typeof useAdmissionsEnrollmentsByGradeReportLazyQuery
>;
export type AdmissionsEnrollmentsByGradeReportQueryResult = Apollo.QueryResult<
	AdmissionsEnrollmentsByGradeReportQuery,
	AdmissionsEnrollmentsByGradeReportQueryVariables
>;
export const AdmissionsReenrollmentsByGradeReportDocument = gql`
	query admissionsReenrollmentsByGradeReport {
		admissionsReenrollmentsByGradeReport {
			data {
				grade
				enrolledValue
				reenrolledValue
			}
		}
	}
`;

/**
 * __useAdmissionsReenrollmentsByGradeReportQuery__
 *
 * To run a query within a React component, call `useAdmissionsReenrollmentsByGradeReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdmissionsReenrollmentsByGradeReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdmissionsReenrollmentsByGradeReportQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdmissionsReenrollmentsByGradeReportQuery(
	baseOptions?: Apollo.QueryHookOptions<
		AdmissionsReenrollmentsByGradeReportQuery,
		AdmissionsReenrollmentsByGradeReportQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<AdmissionsReenrollmentsByGradeReportQuery, AdmissionsReenrollmentsByGradeReportQueryVariables>(
		AdmissionsReenrollmentsByGradeReportDocument,
		options,
	);
}
export function useAdmissionsReenrollmentsByGradeReportLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		AdmissionsReenrollmentsByGradeReportQuery,
		AdmissionsReenrollmentsByGradeReportQueryVariables
	>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		AdmissionsReenrollmentsByGradeReportQuery,
		AdmissionsReenrollmentsByGradeReportQueryVariables
	>(AdmissionsReenrollmentsByGradeReportDocument, options);
}
export type AdmissionsReenrollmentsByGradeReportQueryHookResult = ReturnType<
	typeof useAdmissionsReenrollmentsByGradeReportQuery
>;
export type AdmissionsReenrollmentsByGradeReportLazyQueryHookResult = ReturnType<
	typeof useAdmissionsReenrollmentsByGradeReportLazyQuery
>;
export type AdmissionsReenrollmentsByGradeReportQueryResult = Apollo.QueryResult<
	AdmissionsReenrollmentsByGradeReportQuery,
	AdmissionsReenrollmentsByGradeReportQueryVariables
>;
export const StudentTimelineDocument = gql`
	query studentTimeline($studentID: ID!, $filter: TimelineFilterInput) {
		studentTimeline(studentID: $studentID, filter: $filter) {
			eventDate
			eventTime
			actorID
			actorName
			actorImageURL
			objectType
			objectID
			objectName
			objectDetails
		}
	}
`;

/**
 * __useStudentTimelineQuery__
 *
 * To run a query within a React component, call `useStudentTimelineQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentTimelineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentTimelineQuery({
 *   variables: {
 *      studentID: // value for 'studentID'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useStudentTimelineQuery(
	baseOptions: Apollo.QueryHookOptions<StudentTimelineQuery, StudentTimelineQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<StudentTimelineQuery, StudentTimelineQueryVariables>(StudentTimelineDocument, options);
}
export function useStudentTimelineLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<StudentTimelineQuery, StudentTimelineQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<StudentTimelineQuery, StudentTimelineQueryVariables>(StudentTimelineDocument, options);
}
export type StudentTimelineQueryHookResult = ReturnType<typeof useStudentTimelineQuery>;
export type StudentTimelineLazyQueryHookResult = ReturnType<typeof useStudentTimelineLazyQuery>;
export type StudentTimelineQueryResult = Apollo.QueryResult<StudentTimelineQuery, StudentTimelineQueryVariables>;
export const TimelineDocument = gql`
	query timeline($filter: TimelineFilterInput) {
		timeline(filter: $filter) {
			eventDate
			eventTime
			actorID
			actorName
			actorImageURL
			objectType
			objectID
			objectName
			objectDetails
		}
	}
`;

/**
 * __useTimelineQuery__
 *
 * To run a query within a React component, call `useTimelineQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimelineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimelineQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTimelineQuery(baseOptions?: Apollo.QueryHookOptions<TimelineQuery, TimelineQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<TimelineQuery, TimelineQueryVariables>(TimelineDocument, options);
}
export function useTimelineLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimelineQuery, TimelineQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<TimelineQuery, TimelineQueryVariables>(TimelineDocument, options);
}
export type TimelineQueryHookResult = ReturnType<typeof useTimelineQuery>;
export type TimelineLazyQueryHookResult = ReturnType<typeof useTimelineLazyQuery>;
export type TimelineQueryResult = Apollo.QueryResult<TimelineQuery, TimelineQueryVariables>;
export const ViewerWithPermissionsDocument = gql`
	query viewerWithPermissions {
		viewer {
			id
			email
			status
			permissionGroups {
				permissions {
					name
				}
			}
		}
	}
`;

/**
 * __useViewerWithPermissionsQuery__
 *
 * To run a query within a React component, call `useViewerWithPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerWithPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerWithPermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useViewerWithPermissionsQuery(
	baseOptions?: Apollo.QueryHookOptions<ViewerWithPermissionsQuery, ViewerWithPermissionsQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ViewerWithPermissionsQuery, ViewerWithPermissionsQueryVariables>(
		ViewerWithPermissionsDocument,
		options,
	);
}
export function useViewerWithPermissionsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ViewerWithPermissionsQuery, ViewerWithPermissionsQueryVariables>,
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ViewerWithPermissionsQuery, ViewerWithPermissionsQueryVariables>(
		ViewerWithPermissionsDocument,
		options,
	);
}
export type ViewerWithPermissionsQueryHookResult = ReturnType<typeof useViewerWithPermissionsQuery>;
export type ViewerWithPermissionsLazyQueryHookResult = ReturnType<typeof useViewerWithPermissionsLazyQuery>;
export type ViewerWithPermissionsQueryResult = Apollo.QueryResult<
	ViewerWithPermissionsQuery,
	ViewerWithPermissionsQueryVariables
>;
