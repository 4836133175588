import React from "react";
import { DateTimeField, FieldDisplayArgs, RadioButtonsInput, RadioField, SelectField } from "@hex-insights/forms";
import { SchoolAttendanceRecordFormValues, StudentSelect, useStudentSelectLazyQuery } from "../../../../Utilities";
import { StudentLink } from "../../../Links";
import { TextAreaField } from "../../../TextAreaField";
import { BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the SchoolAttendanceRecord model.
 */
type FieldProps<K extends keyof SchoolAttendanceRecordFormValues.Base = keyof SchoolAttendanceRecordFormValues.Base> =
	BaseFieldProps<Pick<SchoolAttendanceRecordFormValues.Base, K>>;

/**
 * Renders a field component for the `checkInTime` field of the SchoolAttendanceRecord model.
 */
export function CheckInTime({ formState }: FieldProps<"checkInTime">) {
	return <DateTimeField formState={formState} name="checkInTime" precision="minute" />;
}

/**
 * Renders a field component for the `checkOutTime` field of the SchoolAttendanceRecord model.
 */
export function CheckOutTime({ formState }: FieldProps<"checkOutTime">) {
	return <DateTimeField formState={formState} name="checkOutTime" optional precision="minute" />;
}

/**
 * Renders a field component for the `isPresent` field of the SchoolAttendanceRecord model.
 */
export function IsPresent({ formState }: FieldProps<"isPresent">) {
	return (
		<RadioField
			formState={formState}
			name="isPresent"
			label="Status"
			options={SchoolAttendanceRecordFormValues.isPresentOptions}
			Input={RadioButtonsInput}
		/>
	);
}

/**
 * Renders a field component for the `isLate` field of the SchoolAttendanceRecord model.
 */
export function IsLate({ formState }: FieldProps<"isLate">) {
	return (
		<RadioField
			formState={formState}
			name="isLate"
			label="Timing"
			options={SchoolAttendanceRecordFormValues.isLateOptions}
			Input={RadioButtonsInput}
		/>
	);
}

/**
 * Renders a field component for the `isExcused` field of the SchoolAttendanceRecord model.
 */
export function IsExcused({ formState }: FieldProps<"isExcused">) {
	return (
		<RadioField
			formState={formState}
			name="isExcused"
			label="Absence/Late Excuse"
			options={SchoolAttendanceRecordFormValues.isExcusedOptions}
			Input={RadioButtonsInput}
		/>
	);
}

/**
 * Renders a field component for the `notes` field of the SchoolAttendanceRecord model.
 */
export function Notes({ formState }: FieldProps<"notes">) {
	return <TextAreaField formState={formState} name="notes" optional />;
}

export type StudentProps = FieldProps<"studentID"> & {
	currentStudent?: StudentSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `student` edge of the SchoolAttendanceRecord model.
 */
export function Student({ formState, currentStudent }: StudentProps) {
	const [loadOptions, { loading, data }] = useStudentSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.studentID) {
			loadOptions();
		}
	}, [formState.formEditing.studentID, loadOptions]);
	const options = React.useMemo(
		() => StudentSelect.toOptions(data?.studentConnection.edges, currentStudent),
		[data, currentStudent],
	);

	return (
		<SelectField
			formState={formState}
			name="studentID"
			isLoading={loading}
			options={options}
			display={displayStudent}
			blankValue={null}
		/>
	);
}

function displayStudent({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <StudentLink instance={{ id }}>{formattedValue}</StudentLink>;
}
