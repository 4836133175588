import { IndexForms } from "@hex-insights/app-modules";
import { TypeFilterObject } from "@hex-insights/core";

export type StandardFilterFormValues = {}; // TODO define quick filters

export type FormValues = StandardFilterFormValues & IndexForms.SearchFormValues & IndexForms.FilterFormValues;

export const initialStandardFilters: StandardFilterFormValues = {};

export const initial: FormValues = {
	...initialStandardFilters,
	...IndexForms.initialSearchFormValues,
	...IndexForms.initialFilterFormValues,
};

export function getInitial(): FormValues {
	return initial;
}

export const quickFiltersTypeFilterObject: TypeFilterObject<StandardFilterFormValues> = {};

export const typeFilterObject: TypeFilterObject<FormValues> = {
	...quickFiltersTypeFilterObject,
	...IndexForms.searchFormValuesFilterObject,
	...IndexForms.filterFormValuesFilterObject,
};

export const advancedFilterInformation: IndexForms.AdvancedFilterInformation = {
	fieldOptions: [
		{ value: "createdAt" },
		{ value: "userEmail" },
		{ value: "wasBlocked" },
		{ value: "wasSuccessful" },
		{ value: "isTrusted" },
	],
	fieldComparisonOptions: {
		createdAt: IndexForms.timeComparisonOptions,
		userEmail: IndexForms.stringComparisonOptions,
		wasBlocked: IndexForms.booleanComparisonOptions,
		wasSuccessful: IndexForms.booleanComparisonOptions,
		isTrusted: IndexForms.booleanComparisonOptions,
	},
	fieldComparisonTranslations: {
		createdAt: IndexForms.timeComparisonTranslations,
		userEmail: IndexForms.stringComparisonTranslations,
		wasBlocked: IndexForms.booleanComparisonTranslations,
		wasSuccessful: IndexForms.booleanComparisonTranslations,
		isTrusted: IndexForms.booleanComparisonTranslations,
	},
};
