import React from "react";
import {
	BooleanField,
	DateTimeField,
	FieldDisplayArgs,
	FormType,
	MultiSelectField,
	RadioButtonsInput,
	RadioField,
	SelectField,
	TextField,
} from "@hex-insights/forms";
import {
	CalendarEventSelect,
	CourseSectionSelect,
	EmployeeSelect,
	HomeRoomSectionSelect,
	NoteFormValues,
	ParentSelect,
	Student,
	StudentSelect,
	useCalendarEventSelectLazyQuery,
	useCourseSectionSelectLazyQuery,
	useEmployeeSelectLazyQuery,
	useHomeRoomSectionSelectLazyQuery,
	useParentSelectLazyQuery,
	UserSelect,
	useStudentSelectLazyQuery,
	useUserSelectLazyQuery,
} from "../../../../Utilities";
import {
	CalendarEventLink,
	CourseSectionLink,
	EmployeeLink,
	HomeRoomSectionLink,
	ParentLink,
	StudentLink,
	UserLink,
} from "../../../Links";
import { TextAreaField } from "../../../TextAreaField";
import { BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the Note model.
 */
type FieldProps<K extends keyof NoteFormValues.Base = keyof NoteFormValues.Base> = BaseFieldProps<
	Pick<NoteFormValues.Base, K>
>;

/**
 * Generic props for fields of the Note model that only appear in the detail form.
 */
type DetailFieldProps<K extends keyof NoteFormValues.Detail = keyof NoteFormValues.Detail> = BaseFieldProps<
	Pick<NoteFormValues.Detail, K>
>;

/**
 * Renders a field component for the `noteType` field of the Note model.
 */
export function NoteType({ formState }: FieldProps<"noteType">) {
	return (
		<RadioField formState={formState} name="noteType" options={NoteFormValues.noteTypeOptions} blankValue={null} />
	);
}

/**
 * Renders a field component for the `title` field of the Note model.
 */
export function Title({ formState }: FieldProps<"title">) {
	return <TextField formState={formState} name="title" optional />;
}

/**
 * Renders a field component for the `body` field of the Note model.
 */
export function Body({ formState }: FieldProps<"body">) {
	return <TextAreaField formState={formState} name="body" minRows={3} />;
}

/**
 * Renders a field component for the `createdAt` field of the Note model.
 */
export function CreatedAt({ formState, formType = FormType.Update }: DetailFieldProps<"createdAt">) {
	return (
		<DateTimeField formState={formState} name="createdAt" optional={FormType.isCreate(formType)} precision="minute" />
	);
}

/**
 * Renders a field component for the `isTimeDependent` field of the Note model.
 */
export function IsTimeDependent({ formState }: FieldProps<"isTimeDependent">) {
	return <BooleanField formState={formState} name="isTimeDependent" />;
}

/**
 * Renders a field component for the `startDate` field of the Note model.
 */
export function StartDate({ formState }: FieldProps<"startDate">) {
	return <DateTimeField formState={formState} name="startDate" optional precision="day" />;
}

/**
 * Renders a field component for the `endDate` field of the Note model.
 */
export function EndDate({ formState }: FieldProps<"endDate">) {
	return <DateTimeField formState={formState} name="endDate" optional precision="day" />;
}

/**
 * Renders a field component for the `isPublic` field of the Note model.
 */
export function IsPublic({ formState }: FieldProps<"isPublic">) {
	return (
		<RadioField
			formState={formState}
			name="isPublic"
			label="Privacy"
			options={NoteFormValues.isPublicOptions}
			noClear
			Input={RadioButtonsInput}
			hint="Public folders can be viewed by anyone. Private folders can only be viewed by the creator and those who have been explicitly given access."
		/>
	);
}

export type AuthorProps = DetailFieldProps<"authorID"> & {
	currentAuthor?: UserSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `author` edge of the Note model.
 */
export function Author({ formState, currentAuthor }: AuthorProps) {
	const [loadOptions, { loading, data }] = useUserSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.authorID) {
			loadOptions();
		}
	}, [formState.formEditing.authorID, loadOptions]);
	const options = React.useMemo(
		() => UserSelect.toOptions(data?.userConnection.edges, currentAuthor),
		[data, currentAuthor],
	);

	return (
		<SelectField
			formState={formState}
			name="authorID"
			isLoading={loading}
			options={options}
			optional
			display={displayAuthor}
			blankValue={null}
		/>
	);
}

function displayAuthor({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <UserLink instance={{ id }}>{formattedValue}</UserLink>;
}

export type CalendarEventsProps = FieldProps<"calendarEventIDs"> & {
	currentCalendarEvents?: CalendarEventSelect.ModelForOption[];
};

/**
 * Renders a field component for the `calendarEvents` edge of the Note model.
 */
export function CalendarEvents({ formState, currentCalendarEvents }: CalendarEventsProps) {
	const [loadOptions, { loading, data }] = useCalendarEventSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.calendarEventIDs) {
			loadOptions();
		}
	}, [formState.formEditing.calendarEventIDs, loadOptions]);
	const options = React.useMemo(
		() => CalendarEventSelect.toMultiOptions(data?.calendarEventConnection.edges, currentCalendarEvents),
		[data, currentCalendarEvents],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="calendarEventIDs"
			isLoading={loading}
			options={options}
			displayInstance={displayCalendarEventInstance}
		/>
	);
}

function displayCalendarEventInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <CalendarEventLink instance={{ id }}>{formattedValue}</CalendarEventLink>;
}

export type CourseSectionsProps = FieldProps<"courseSectionIDs"> & {
	currentCourseSections?: CourseSectionSelect.ModelForOption[];
};

/**
 * Renders a field component for the `courseSections` edge of the Note model.
 */
export function CourseSections({ formState, currentCourseSections }: CourseSectionsProps) {
	const [loadOptions, { loading, data }] = useCourseSectionSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.courseSectionIDs) {
			loadOptions();
		}
	}, [formState.formEditing.courseSectionIDs, loadOptions]);
	const options = React.useMemo(
		() => CourseSectionSelect.toMultiOptions(data?.courseSectionConnection.edges, currentCourseSections),
		[data, currentCourseSections],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="courseSectionIDs"
			isLoading={loading}
			options={options}
			displayInstance={displayCourseSectionInstance}
		/>
	);
}

function displayCourseSectionInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <CourseSectionLink instance={{ id }}>{formattedValue}</CourseSectionLink>;
}

export type EmployeesProps = FieldProps<"employeeIDs"> & {
	currentEmployees?: EmployeeSelect.ModelForOption[];
};

/**
 * Renders a field component for the `employees` edge of the Note model.
 */
export function Employees({ formState, currentEmployees }: EmployeesProps) {
	const [loadOptions, { loading, data }] = useEmployeeSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.employeeIDs) {
			loadOptions();
		}
	}, [formState.formEditing.employeeIDs, loadOptions]);
	const options = React.useMemo(
		() => EmployeeSelect.toMultiOptions(data?.employeeConnection.edges, currentEmployees),
		[data, currentEmployees],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="employeeIDs"
			isLoading={loading}
			options={options}
			displayInstance={displayEmployeeInstance}
		/>
	);
}

function displayEmployeeInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <EmployeeLink instance={{ id }}>{formattedValue}</EmployeeLink>;
}

export type HomeRoomSectionsProps = FieldProps<"homeRoomSectionIDs"> & {
	currentHomeRoomSections?: HomeRoomSectionSelect.ModelForOption[];
};

/**
 * Renders a field component for the `homeRoomSections` edge of the Note model.
 */
export function HomeRoomSections({ formState, currentHomeRoomSections }: HomeRoomSectionsProps) {
	const [loadOptions, { loading, data }] = useHomeRoomSectionSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.homeRoomSectionIDs) {
			loadOptions();
		}
	}, [formState.formEditing.homeRoomSectionIDs, loadOptions]);
	const options = React.useMemo(
		() => HomeRoomSectionSelect.toMultiOptions(data?.homeRoomSectionConnection.edges, currentHomeRoomSections),
		[data, currentHomeRoomSections],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="homeRoomSectionIDs"
			isLoading={loading}
			options={options}
			displayInstance={displayHomeRoomSectionInstance}
		/>
	);
}

function displayHomeRoomSectionInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <HomeRoomSectionLink instance={{ id }}>{formattedValue}</HomeRoomSectionLink>;
}

export type ParentsProps = FieldProps<"parentIDs"> & {
	currentParents?: ParentSelect.ModelForOption[];
};

/**
 * Renders a field component for the `parents` edge of the Note model.
 */
export function Parents({ formState, currentParents }: ParentsProps) {
	const [loadOptions, { loading, data }] = useParentSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.parentIDs) {
			loadOptions();
		}
	}, [formState.formEditing.parentIDs, loadOptions]);
	const options = React.useMemo(
		() => ParentSelect.toMultiOptions(data?.parentConnection.edges, currentParents),
		[data, currentParents],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="parentIDs"
			isLoading={loading}
			options={options}
			displayInstance={displayParentInstance}
		/>
	);
}

function displayParentInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <ParentLink instance={{ id }}>{formattedValue}</ParentLink>;
}

export type StudentProps = BaseFieldProps<{ studentID: Student["id"] | null }> & {
	currentStudent?: StudentSelect.ModelForOption | null;
	optional?: boolean;
};

/**
 * Renders a field component for a `student`.
 */
function StudentField({ formState, currentStudent, optional }: StudentProps) {
	const [loadOptions, { loading, data }] = useStudentSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.studentID) {
			loadOptions();
		}
	}, [formState.formEditing.studentID, loadOptions]);
	const options = React.useMemo(
		() => StudentSelect.toOptions(data?.studentConnection.edges, currentStudent),
		[data, currentStudent],
	);

	return (
		<SelectField
			formState={formState}
			name="studentID"
			isLoading={loading}
			options={options}
			optional={optional}
			display={displayStudent}
			blankValue={null}
		/>
	);
}

export { StudentField as Student };

function displayStudent({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <StudentLink instance={{ id }}>{formattedValue}</StudentLink>;
}

export type StudentsProps = FieldProps<"studentIDs"> & {
	currentStudents?: StudentSelect.ModelForOption[];
	label?: string;
	minItems?: number;
};

/**
 * Renders a field component for the `students` edge of the Note model.
 */
export function Students({ formState, currentStudents, label, minItems }: StudentsProps) {
	const [loadOptions, { loading, data }] = useStudentSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.studentIDs) {
			loadOptions();
		}
	}, [formState.formEditing.studentIDs, loadOptions]);
	const options = React.useMemo(
		() => StudentSelect.toMultiOptions(data?.studentConnection.edges, currentStudents),
		[data, currentStudents],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="studentIDs"
			label={label}
			isLoading={loading}
			options={options}
			displayInstance={displayStudentInstance}
			minItems={minItems}
		/>
	);
}

function displayStudentInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <StudentLink instance={{ id }}>{formattedValue}</StudentLink>;
}

export type SharingUsersProps = FieldProps<"sharingUserIDs"> & {
	currentSharingUsers?: UserSelect.ModelForOption[];
};

/**
 * Renders a field component for the `sharingUsers` edge of the Note model.
 */
export function SharingUsers({ formState, formType, currentSharingUsers }: SharingUsersProps) {
	const [loadOptions, { loading, data }] = useUserSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.sharingUserIDs) {
			loadOptions();
		}
	}, [formState.formEditing.sharingUserIDs, loadOptions]);
	const options = React.useMemo(
		() => UserSelect.toMultiOptions(data?.userConnection.edges, currentSharingUsers),
		[data, currentSharingUsers],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="sharingUserIDs"
			label={formType === FormType.Create ? "Share with Users" : "Shared with Users"}
			isLoading={loading}
			options={options}
			displayInstance={displaySharingUserInstance}
		/>
	);
}

function displaySharingUserInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <UserLink instance={{ id }}>{formattedValue}</UserLink>;
}
