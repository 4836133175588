import React from "react";
import { ClassNameProps, forceUseHookDependencies, makeClassName, StyleProps, useSync } from "@hex-insights/core";
import styles from "./styles.module.css";

export type SquareImageContainerProps = {
	src: string;
	alt?: string;
	size?: string;
	onError?: () => void;
} & Partial<ClassNameProps & StyleProps>;

export function SquareImageContainer({ src, alt, onError, size, className, style }: SquareImageContainerProps) {
	const ref = React.useRef<HTMLImageElement | null>(null);

	const { syncID, synchronize } = useSync();

	const imageShape = React.useMemo(() => {
		forceUseHookDependencies(syncID);
		if (!ref.current) {
			return "wide";
		}
		const { width, height } = ref.current.getBoundingClientRect();
		return width >= height ? "wide" : "tall";
	}, [syncID]);

	return (
		<div
			className={makeClassName(styles["image-container"], styles[`image-container--${imageShape}`], className)}
			style={size ? { width: size, height: size, ...style } : style}
		>
			<img ref={ref} src={src} alt={alt} onLoad={synchronize} onError={onError} />
		</div>
	);
}
