import { Column, Heading, Paragraph } from "@hex-insights/core";
import { InternalLink } from "@hex-insights/router";
import { Main } from "../../../Components";
import { logoutPageInfo } from "../Logout/pageinfo";
import styles from "../authentication.module.css";

export function AccountDisabledPage() {
	return (
		<Main className={styles["auth-page__main"]}>
			<Column align="center" className={styles["auth-page__content-container"]}>
				<Heading level={1}>Account Disabled</Heading>

				<Paragraph>This account is disabled.</Paragraph>

				<Paragraph>
					<InternalLink to={logoutPageInfo.to}>Click to log out</InternalLink>
				</Paragraph>
			</Column>
		</Main>
	);
}
