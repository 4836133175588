import { Column } from "@hex-insights/core";
import { StaticURLs } from "@hex-insights/verita.shared";
import { Main } from "../../../Components";
import styles from "../authentication.module.css";

export function SplashPage() {
	return (
		<Main className={styles["auth-page__main"]}>
			<Column justify="center" align="center">
				<div style={{ width: "20vw", height: "fit-content" }}>
					<img
						src={StaticURLs.Images.veritaWingsLogoWhite}
						style={{ width: "100%", height: "auto" }}
						alt="Verita Portal"
					/>
				</div>
			</Column>
		</Main>
	);
}
