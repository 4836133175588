import React from "react";
import { FieldDisplayArgs, MultiSelectField, SelectField } from "@hex-insights/forms";
import {
	ParentFormValues,
	PaymentSelect,
	PersonSelect,
	RelationshipSelect,
	usePaymentSelectLazyQuery,
	usePersonSelectLazyQuery,
	useRelationshipSelectLazyQuery,
} from "../../../../Utilities";
import { PaymentLink, PersonLink, RelationshipLink } from "../../../Links";
import { BaseFieldProps } from "../Shared";

/**
 * Generic props for fields of the Parent model.
 */
type FieldProps<K extends keyof ParentFormValues.Base = keyof ParentFormValues.Base> = BaseFieldProps<
	Pick<ParentFormValues.Base, K>
>;

/**
 * Generic props for fields of the Parent model that only appear in the detail form.
 */
type DetailFieldProps<K extends keyof ParentFormValues.Detail = keyof ParentFormValues.Detail> = BaseFieldProps<
	Pick<ParentFormValues.Detail, K>
>;

export type PaymentsProps = DetailFieldProps<"paymentIDs"> & {
	currentPayments?: PaymentSelect.ModelForOption[];
};

/**
 * Renders a field component for the `payments` edge of the Parent model.
 */
export function Payments({ formState, currentPayments }: PaymentsProps) {
	const [loadOptions, { loading, data }] = usePaymentSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.paymentIDs) {
			loadOptions();
		}
	}, [formState.formEditing.paymentIDs, loadOptions]);
	const options = React.useMemo(
		() => PaymentSelect.toMultiOptions(data?.paymentConnection.edges, currentPayments),
		[data, currentPayments],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="paymentIDs"
			isLoading={loading}
			options={options}
			displayInstance={displayPaymentInstance}
		/>
	);
}

function displayPaymentInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <PaymentLink instance={{ id }}>{formattedValue}</PaymentLink>;
}

export type PersonProps = FieldProps<"personID"> & {
	currentPerson?: PersonSelect.ModelForOption | null;
};

/**
 * Renders a field component for the `person` edge of the Parent model.
 */
export function Person({ formState, currentPerson }: PersonProps) {
	const [loadOptions, { loading, data }] = usePersonSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.personID) {
			loadOptions();
		}
	}, [formState.formEditing.personID, loadOptions]);
	const options = React.useMemo(
		() => PersonSelect.toOptions(data?.personConnection.edges, currentPerson),
		[data, currentPerson],
	);

	return (
		<SelectField
			formState={formState}
			name="personID"
			label="Person Record"
			isLoading={loading}
			options={options}
			display={displayPerson}
			blankValue={null}
		/>
	);
}

function displayPerson({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <PersonLink instance={{ id }}>{formattedValue}</PersonLink>;
}

export type RelationshipsProps = DetailFieldProps<"relationshipIDs"> & {
	currentRelationships?: RelationshipSelect.ModelForOption[];
};

/**
 * Renders a field component for the `relationships` edge of the Parent model.
 */
export function Relationships({ formState, currentRelationships }: RelationshipsProps) {
	const [loadOptions, { loading, data }] = useRelationshipSelectLazyQuery();
	React.useEffect(() => {
		if (formState.formEditing.relationshipIDs) {
			loadOptions();
		}
	}, [formState.formEditing.relationshipIDs, loadOptions]);
	const options = React.useMemo(
		() => RelationshipSelect.toMultiOptions(data?.relationshipConnection.edges, currentRelationships),
		[data, currentRelationships],
	);

	return (
		<MultiSelectField
			formState={formState}
			name="relationshipIDs"
			isLoading={loading}
			options={options}
			displayInstance={displayRelationshipInstance}
		/>
	);
}

function displayRelationshipInstance({ value: id, formattedValue }: FieldDisplayArgs<string | null>) {
	if (id === null) {
		return formattedValue;
	}
	return <RelationshipLink instance={{ id }}>{formattedValue}</RelationshipLink>;
}
