import { Note, NoteReaction, NoteReactionReactionType, User } from "../../GraphQL";

export type Base = {
	reactionType: NoteReaction["reactionType"] | null;
	noteID: Note["id"] | null;
};

export type Create = Base;

export type Detail = Base & {
	createdAt: NoteReaction["createdAt"] | null;
	authorID: User["id"] | null;
};

export const initialCreate: Create = {
	reactionType: null,
	noteID: null,
};

export const reactionTypeOptions = [{ value: NoteReactionReactionType.Like }];
