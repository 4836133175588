import { IndexForms } from "@hex-insights/app-modules";
import { CaseStyle, convertCase } from "@hex-insights/core";
import { OrderDirection, SchoolStudentEnrollmentOrderField } from "../../GraphQL";

export type FormValues = IndexForms.OrderFormValues<SchoolStudentEnrollmentOrderField | null>;

export const initial: FormValues = {
	field: SchoolStudentEnrollmentOrderField.CreatedDate,
	direction: OrderDirection.Desc,
};

/**
 * Options for the "field" field in the order form of the SchoolStudentEnrollment model.
 */
export const fieldOptions = [
	{ label: "ID", value: null },
	{
		label: convertCase(SchoolStudentEnrollmentOrderField.CreatedDate.toLowerCase(), CaseStyle.Title),
		value: SchoolStudentEnrollmentOrderField.CreatedDate,
	},
	{
		label: convertCase(SchoolStudentEnrollmentOrderField.PaymentDueDate.toLowerCase(), CaseStyle.Title),
		value: SchoolStudentEnrollmentOrderField.PaymentDueDate,
	},
	{
		label: convertCase(SchoolStudentEnrollmentOrderField.HasPaidFee.toLowerCase(), CaseStyle.Title),
		value: SchoolStudentEnrollmentOrderField.HasPaidFee,
	},
	{
		label: convertCase(SchoolStudentEnrollmentOrderField.IsVoided.toLowerCase(), CaseStyle.Title),
		value: SchoolStudentEnrollmentOrderField.IsVoided,
	},
];

/**
 * Array of values for the "field" field in the order form of the SchoolStudentEnrollment model.
 */
export const fieldValues = fieldOptions.map((e) => e.value);

/**
 * Mapping from order fields of the SchoolStudentEnrollment model to their corresponding order type.
 */
export const fieldTypeMapping = {
	[SchoolStudentEnrollmentOrderField.CreatedDate]: IndexForms.OrderFieldType.Time,
	[SchoolStudentEnrollmentOrderField.PaymentDueDate]: IndexForms.OrderFieldType.Time,
	[SchoolStudentEnrollmentOrderField.HasPaidFee]: IndexForms.OrderFieldType.Boolean,
	[SchoolStudentEnrollmentOrderField.IsVoided]: IndexForms.OrderFieldType.Boolean,
};

/**
 * Mapping from order fields of the SchoolStudentEnrollment model to their corresponding field name.
 */
export const orderFieldToModelField = {
	[SchoolStudentEnrollmentOrderField.CreatedDate]: "createdDate",
	[SchoolStudentEnrollmentOrderField.PaymentDueDate]: "paymentDueDate",
	[SchoolStudentEnrollmentOrderField.HasPaidFee]: "hasPaidFee",
	[SchoolStudentEnrollmentOrderField.IsVoided]: "isVoided",
};

/**
 * Mapping from field names of the SchoolStudentEnrollment model to their corresponding order field.
 */
export const modelFieldToOrderField = {
	createdDate: SchoolStudentEnrollmentOrderField.CreatedDate,
	paymentDueDate: SchoolStudentEnrollmentOrderField.PaymentDueDate,
	hasPaidFee: SchoolStudentEnrollmentOrderField.HasPaidFee,
	isVoided: SchoolStudentEnrollmentOrderField.IsVoided,
};
