import { omitKeys } from "@hex-insights/core";
import {
	EnrollmentApplicationParent,
	EnrollmentApplicationParentRelationshipType,
	EnrollmentApplicationSubmission,
} from "../../GraphQL";

export type Base = {
	formIdentifier: EnrollmentApplicationParent["formIdentifier"];
	firstName: EnrollmentApplicationParent["firstName"];
	lastName: EnrollmentApplicationParent["lastName"];
	nationality: EnrollmentApplicationParent["nationality"];
	occupation: EnrollmentApplicationParent["occupation"];
	relationshipType: EnrollmentApplicationParent["relationshipType"] | null;
	relationshipTypeCustom: EnrollmentApplicationParent["relationshipTypeCustom"];
	isPrimaryContact: EnrollmentApplicationParent["isPrimaryContact"];
	livesWithStudent: EnrollmentApplicationParent["livesWithStudent"];
	isAuthorizedForPickup: EnrollmentApplicationParent["isAuthorizedForPickup"];
	isEmergencyContact: EnrollmentApplicationParent["isEmergencyContact"];
	isFinancialContact: EnrollmentApplicationParent["isFinancialContact"];
	addressSameAsFormIdentifier: EnrollmentApplicationParent["addressSameAsFormIdentifier"];
	addressLine1: EnrollmentApplicationParent["addressLine1"];
	addressLine2: EnrollmentApplicationParent["addressLine2"];
	city: EnrollmentApplicationParent["city"];
	stateOrProvince: EnrollmentApplicationParent["stateOrProvince"];
	postalCode: EnrollmentApplicationParent["postalCode"];
	country: EnrollmentApplicationParent["country"];
	homePhone: EnrollmentApplicationParent["homePhone"];
	workPhone: EnrollmentApplicationParent["workPhone"];
	mobilePhone: EnrollmentApplicationParent["mobilePhone"];
	email: EnrollmentApplicationParent["email"];
	enrollmentApplicationSubmissionID: EnrollmentApplicationSubmission["id"] | null;
};

export type Address = Pick<
	Base,
	| "addressSameAsFormIdentifier"
	| "addressLine1"
	| "addressLine2"
	| "city"
	| "stateOrProvince"
	| "postalCode"
	| "country"
>;

export type Create = Base;

export type EnrollmentApplicationCreate = Omit<Create, "nationality" | "enrollmentApplicationSubmissionID"> & {
	nationalityList: string[];
};

export type Detail = Base & {
	name: EnrollmentApplicationParent["name"];
};

export const initialCreate: Create = {
	formIdentifier: "",
	firstName: "",
	lastName: "",
	nationality: "",
	occupation: "",
	relationshipType: null,
	relationshipTypeCustom: "",
	isPrimaryContact: true,
	livesWithStudent: true,
	isAuthorizedForPickup: true,
	isEmergencyContact: true,
	isFinancialContact: false,
	addressSameAsFormIdentifier: "",
	addressLine1: "",
	addressLine2: "",
	city: "",
	stateOrProvince: "",
	postalCode: "",
	country: "",
	homePhone: "",
	workPhone: "",
	mobilePhone: "",
	email: "",
	enrollmentApplicationSubmissionID: null,
};

export const initialEnrollmentApplicationCreate: EnrollmentApplicationCreate = {
	...omitKeys(initialCreate, ["nationality", "enrollmentApplicationSubmissionID"]),
	nationalityList: [],
};

export const relationshipTypeOptions = [
	{ value: EnrollmentApplicationParentRelationshipType.Father },
	{ value: EnrollmentApplicationParentRelationshipType.Mother },
	{ value: EnrollmentApplicationParentRelationshipType.Other },
];
