import React from "react";
import { Authentication } from "@hex-insights/app-modules";
import { Column, Heading, If, Paragraph, Row } from "@hex-insights/core";
import { Form, PasswordField, SubmissionStatus, SubmitButton, TextField } from "@hex-insights/forms";
import { InternalLink, useActivePageRegistration } from "@hex-insights/router";
import { serverRequest, StaticURLs } from "@hex-insights/verita.shared";
import { Main } from "../../../Components";
import { AuthenticationContext } from "../../../Contexts";
import { recoverPageInfo } from "../Recover/pageinfo";
import { loginPageInfo } from "./pageinfo";
import styles from "../authentication.module.css";

export function LoginPage() {
	useActivePageRegistration(loginPageInfo);

	const onSuccess = React.useCallback(() => {}, []);

	return (
		<Main className={styles["auth-page__main"]}>
			<Column justify="spaced-center" align="center" className={styles["auth-page__content-container"]}>
				<div style={{ width: "10rem", height: "fit-content", margin: "0 auto" }}>
					<img
						src={StaticURLs.Images.veritaWingsLogoGradient}
						style={{ width: "100%", height: "auto" }}
						width="160"
						height="113"
						alt="Verita Portal"
					/>
				</div>

				<Heading level={1} style={{ textAlign: "center" }}>
					Verita Teacher Portal
				</Heading>
				<Heading level={2} noMargin>
					Login
				</Heading>

				<div>
					<LoginForm onSuccess={onSuccess} />
				</div>
			</Column>
		</Main>
	);
}

type LoginFormProps = {
	onSuccess: () => void;
};

function LoginForm({ onSuccess }: LoginFormProps) {
	const { formState, onSubmit, linkState } = Authentication.useLogin({
		AuthenticationContext,
		requestLogin,
		onSuccess,
	});

	return (
		<Form formState={formState} onSubmit={formState.onSubmitWrapper(onSubmit)} noNotifications noNavigationPrompt>
			<Column justify="spaced-start">
				<If condition={SubmissionStatus.isFailure(formState.submissionStatus)}>
					<Paragraph>{formState.formSubmissionErrors._.join(" ")}</Paragraph>
				</If>

				<TextField formState={formState} name="email" autoFocus />
				<PasswordField formState={formState} name="password" allowVisibility />

				<Row justify="space-between" align="flex-end">
					<InternalLink to={{ pathname: recoverPageInfo.to, state: linkState }}>Forgot Password</InternalLink>

					<SubmitButton submissionStatus={formState.submissionStatus} onClick={formState.onSubmitWrapper(onSubmit)}>
						Login
					</SubmitButton>
				</Row>
			</Column>
		</Form>
	);
}

function requestLogin(formValues: Authentication.LoginFormValues) {
	return serverRequest("/auth/login", {
		method: "POST",
		body: JSON.stringify(formValues),
	});
}
