import { Models } from "@hex-insights/app-modules";
import { DistributiveOmit } from "@hex-insights/core";
import { Campus, CampusFormat, CampusNameFragment } from "../../../../Utilities";

type ModelForLink = Pick<Campus, "id"> & CampusNameFragment;

export type CampusLinkProps = DistributiveOmit<Models.LinkProps<ModelForLink>, "pageInfoKey" | "format">;

/**
 * Renders a link to the given Campus instance.
 */
export function CampusLink(props: CampusLinkProps) {
	return <Models.Link<ModelForLink> pageInfoKey="Campus" format={CampusFormat.name} {...props} />;
}
