import { omitKeys } from "@hex-insights/core";
import { Person, PhoneNumber, PhoneNumberPhoneType } from "../../GraphQL";

export type Base = {
	phoneNumber: PhoneNumber["phoneNumber"];
	phoneType: PhoneNumber["phoneType"] | null;
	personID: Person["id"] | null;
};

export type Create = Base;

export type Detail = Base;

export const initialCreate: Create = {
	phoneNumber: "",
	phoneType: null,
	personID: null,
};

export const phoneTypeOptions = [
	{ value: PhoneNumberPhoneType.Home },
	{ value: PhoneNumberPhoneType.Mobile },
	{ value: PhoneNumberPhoneType.Work },
];

export type CreateInPerson = Omit<Create, "personID">;

export const initialCreateInPerson: CreateInPerson = omitKeys(initialCreate, ["personID"]);
