import { omitKeys } from "@hex-insights/core";
import { Payment, Person, Relationship } from "../../GraphQL";
import * as PersonFormValues from "./person";

export type Base = {
	personID: Person["id"] | null;
};

export type Create = Base;

export type Detail = Base & {
	paymentIDs: Payment["id"][];
	relationshipIDs: Relationship["id"][];
};

export const initialCreate: Create = {
	personID: null,
};

export type CreateWithPerson = Omit<Create, "personID"> & PersonFormValues.CreateInOther;

export const initialCreateWithPerson: CreateWithPerson = {
	...PersonFormValues.initialCreateInOther,
	...omitKeys(initialCreate, ["personID"]),
};

export function omitPersonCreateFormValues(
	formValues: CreateWithPerson,
): Omit<CreateWithPerson, keyof PersonFormValues.CreateInOther> {
	return omitKeys(formValues, PersonFormValues.createInOtherKeys);
}

export type DetailWithPerson = Omit<Detail, "personID"> & PersonFormValues.DetailInOther;

export function omitPersonDetailFormValues<T extends Partial<DetailWithPerson>>(
	formValues: T,
): Omit<T, keyof PersonFormValues.DetailInOther> {
	return omitKeys(formValues, PersonFormValues.detailInOtherKeys);
}
