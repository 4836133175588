import { IndexForms } from "@hex-insights/app-modules";
import { CaseStyle, convertCase } from "@hex-insights/core";
import { EnrollmentApplicationOrderField, OrderDirection } from "../../GraphQL";

export type FormValues = IndexForms.OrderFormValues<EnrollmentApplicationOrderField | null>;

export const initial: FormValues = {
	field: EnrollmentApplicationOrderField.CreatedAt,
	direction: OrderDirection.Desc,
};

/**
 * Options for the "field" field in the order form of the EnrollmentApplication model.
 */
export const fieldOptions = [
	{ label: "ID", value: null },
	{
		label: convertCase(EnrollmentApplicationOrderField.CreatedAt.toLowerCase(), CaseStyle.Title),
		value: EnrollmentApplicationOrderField.CreatedAt,
	},
	{
		label: convertCase(EnrollmentApplicationOrderField.Status.toLowerCase(), CaseStyle.Title),
		value: EnrollmentApplicationOrderField.Status,
	},
	{
		label: convertCase(EnrollmentApplicationOrderField.InterviewMeetingTime.toLowerCase(), CaseStyle.Title),
		value: EnrollmentApplicationOrderField.InterviewMeetingTime,
	},
];

/**
 * Array of values for the "field" field in the order form of the EnrollmentApplication model.
 */
export const fieldValues = fieldOptions.map((e) => e.value);

/**
 * Mapping from order fields of the EnrollmentApplication model to their corresponding order type.
 */
export const fieldTypeMapping = {
	[EnrollmentApplicationOrderField.CreatedAt]: IndexForms.OrderFieldType.Time,
	[EnrollmentApplicationOrderField.Status]: IndexForms.OrderFieldType.String,
	[EnrollmentApplicationOrderField.InterviewMeetingTime]: IndexForms.OrderFieldType.Time,
};

/**
 * Mapping from order fields of the EnrollmentApplication model to their corresponding field name.
 */
export const orderFieldToModelField = {
	[EnrollmentApplicationOrderField.CreatedAt]: "createdAt",
	[EnrollmentApplicationOrderField.Status]: "status",
	[EnrollmentApplicationOrderField.InterviewMeetingTime]: "interviewMeetingTime",
};

/**
 * Mapping from field names of the EnrollmentApplication model to their corresponding order field.
 */
export const modelFieldToOrderField = {
	createdAt: EnrollmentApplicationOrderField.CreatedAt,
	status: EnrollmentApplicationOrderField.Status,
	interviewMeetingTime: EnrollmentApplicationOrderField.InterviewMeetingTime,
};
