import { newPageInfo } from "@hex-insights/router";
import { rootPageInfo } from "../../../root.pageinfo";

export const setPasswordPageInfo = newPageInfo({
	title: "Set Password",
	description: "Set a new password from a recovery link.",
	exact: true,
	path: "/set-password",
	to: "/set-password",
	parent: rootPageInfo,
	noSearch: true,
});
