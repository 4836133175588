import React from "react";
import { GlobalSearch, Navigation, Runners } from "@hex-insights/app-modules";
import {
	booleanTypeFilter,
	Button,
	ClassNameProps,
	Column,
	Conditional,
	Drawer,
	Else,
	If,
	jsxJoin,
	makeClassName,
	Row,
	scrollToTop,
	Tooltip,
	useBodyClassName,
	useLocalStorage,
	useMaxStackIndex,
	useMediaQueries,
	useToggle,
} from "@hex-insights/core";
import { InternalLink, NavLink, PageInfo, useActivePageInfo, useLocation } from "@hex-insights/router";
import { StaticURLs } from "../../StaticURLs";
import { useCurrentUserPersonQuery } from "../../Utilities";
import { PersonIcon, PersonIconProps } from "../PersonIcon";
import styles from "./styles.module.css";

export type HeaderProps = {
	homePageInfo: PageInfo<any, string, any>;
	GlobalSearchBar?: (
		props: Pick<GlobalSearch.Basic.SearchBarProps, "onSelectResult" | "expands" | "withKeyboardShortcut"> &
			Partial<ClassNameProps>,
	) => JSX.Element | null;
	links: [JSX.Element | string, JSX.Element | string | null, PageInfo<any, string, any>][];
};

export function Header({ homePageInfo, GlobalSearchBar, links }: HeaderProps) {
	return (
		<React.Fragment>
			<Navigation.SkipToContentLink />
			<Runners.Header
				Bar={React.useCallback(
					(props) => (
						<HeaderBar {...props} homePageInfo={homePageInfo} GlobalSearchBar={GlobalSearchBar} links={links} />
					),
					[homePageInfo, GlobalSearchBar, links],
				)}
				Drawer={React.useCallback(
					(props: Runners.HeaderDrawerProps) => (
						<HeaderDrawerController {...props} GlobalSearchBar={GlobalSearchBar} links={links} />
					),
					[GlobalSearchBar, links],
				)}
				SideBar={HeaderSideBar}
				className="no-print"
			/>
		</React.Fragment>
	);
}

const bodyWithNavigationToolbarClassName = "body--with-navigation-toolbar";
const navigationToolbarIsOpenLocalStorageKey = "ui.header.navigationToolbar.isOpen";

type HeaderBarProps = Runners.HeaderBarProps & HeaderProps;

function HeaderBar({ toggleDrawer, homePageInfo, GlobalSearchBar, links }: HeaderBarProps) {
	const { getItem, setItem } = useLocalStorage();
	const { isOn: isNavigationToolbarOpen, toggle: toggleIsNavigationToolbarOpen } = useToggle(() =>
		getItem(navigationToolbarIsOpenLocalStorageKey, booleanTypeFilter()),
	);
	useBodyClassName(bodyWithNavigationToolbarClassName, isNavigationToolbarOpen);
	React.useEffect(() => {
		setItem(navigationToolbarIsOpenLocalStorageKey, isNavigationToolbarOpen);
	}, [setItem, isNavigationToolbarOpen]);

	return (
		<Row justify="space-between" align="center" className={styles["header__bar"]}>
			<Button variant="secondary" onClick={toggleDrawer} className={styles["header__bar__drawer-button"]}>
				&#8801;
			</Button>

			<Row justify="spaced-start" align="center" className={styles["header__bar__title-and-search"]}>
				<InternalLink to={homePageInfo.to} className={styles["header__bar__site-title"]}>
					<div style={{ width: "fit-content", height: "2.5rem" }}>
						<img src={StaticURLs.Images.veritaWingsLogoGradient} style={{ width: "auto", height: "100%" }} />
					</div>
				</InternalLink>

				{GlobalSearchBar && (
					<GlobalSearchBar expands withKeyboardShortcut className={styles["header__bar__global-search"]} />
				)}

				<div className={styles["header__bar__navigation-toolbar-button-container"]}>
					<Button
						variant="secondary"
						size="small"
						onClick={toggleIsNavigationToolbarOpen}
						title="Toggle navigation toolbar"
					>
						&#8943;
					</Button>
				</div>
			</Row>

			<Row justify="spaced-end" align="center">
				<nav className={styles["header__bar__nav"]}>
					<Row justify="spaced-start" align="center" horizontalSpacing="0.5rem">
						{links.map(([content, label, pageInfo], i) => (
							<React.Fragment key={pageInfo.key}>
								<If condition={i === links.length - 1}>
									<div style={{ height: "1.5rem", borderLeft: "2px solid #eee" }}></div>
								</If>

								<Tooltip.Container side="bottom" distanceOffset="-0.1rem" style={{ overflow: "visible" }}>
									<NavLink
										to={pageInfo.to}
										exact={pageInfo.exact}
										className={styles["header__nav__link"]}
										activeClassName={styles["header__nav__link--location-active"]}
									>
										{content}
									</NavLink>

									<If condition={label !== null}>
										<Tooltip noStem>
											<Tooltip.Body>
												<span className={styles["header__nav__link__tooltip__label"]}>{label}</span>
											</Tooltip.Body>
										</Tooltip>
									</If>
								</Tooltip.Container>
							</React.Fragment>
						))}
					</Row>
				</nav>

				<Button onClick={() => {}} style={{ padding: 0 }}>
					<CurrentUserIcon imageSize="3rem" />
				</Button>
			</Row>

			<If condition={isNavigationToolbarOpen}>
				<HeaderNavigationToolbar />
			</If>
		</Row>
	);
}

type CurrentUserIconProps = Omit<PersonIconProps, "person">;

function CurrentUserIcon(props: CurrentUserIconProps) {
	const { data: userData } = useCurrentUserPersonQuery();

	return (
		<PersonIcon
			person={userData && userData.viewer.person ? userData.viewer.person : { name: "M E", imageURL: "" }}
			{...props}
		/>
	);
}

export function HeaderNavigationToolbar() {
	const { activePagesList } = useActivePageInfo();
	const { pathname } = useLocation();
	const { breadCrumbPages, showBackLink, backTo } = Navigation.getBreadCrumbPages(activePagesList, pathname);

	return (
		<Row
			justify="spaced-start"
			horizontalSpacing="0.5rem"
			align="center"
			className={styles["header__bar__navigation-toolbar"]}
		>
			<Conditional>
				<If condition={showBackLink}>
					<InternalLink to={backTo} className={styles["header__bar__navigation-toolbar__back-link"]}>
						&#8249;
					</InternalLink>
				</If>
				<Else>
					<span
						className={makeClassName(
							styles["header__bar__navigation-toolbar__back-link"],
							styles["header__bar__navigation-toolbar__back-link--placeholder"],
						)}
					></span>
				</Else>
			</Conditional>

			<Row
				justify="spaced-start"
				horizontalSpacing="0.5rem"
				align="center"
				overflow="scroll"
				className={styles["header__bar__navigation-toolbar__bread-crumbs"]}
			>
				{jsxJoin(
					breadCrumbPages.map((e) => (
						<InternalLink key={e.url} to={e.url} onClick={e.url === pathname ? scrollToTop : undefined}>
							{e.stringTitle}
						</InternalLink>
					)),
					"/",
				)}
			</Row>
		</Row>
	);
}

function HeaderDrawerController({
	isOpen,
	onClose,
	GlobalSearchBar,
	links,
}: Runners.HeaderDrawerProps & HeaderDrawerProps) {
	if (!isOpen) {
		return null;
	}
	return <HeaderDrawer onClose={onClose} GlobalSearchBar={GlobalSearchBar} links={links} />;
}

const desktopMediaQuery = "(min-width: 800px)";
const mediaQueries = [desktopMediaQuery];

type HeaderDrawerProps = Pick<Runners.HeaderDrawerProps, "onClose"> & Pick<HeaderProps, "GlobalSearchBar" | "links">;

function HeaderDrawer({ onClose, GlobalSearchBar, links }: HeaderDrawerProps) {
	const media = useMediaQueries(mediaQueries);
	const zIndex = useMaxStackIndex();

	React.useEffect(() => {
		if (media[desktopMediaQuery]) {
			onClose();
		}
	}, [onClose, media]);

	const onNavigateClick = React.useCallback(
		(event: React.MouseEvent | React.KeyboardEvent) => {
			if (event.ctrlKey || event.metaKey) {
				return;
			}
			onClose();
		},
		[onClose],
	);

	const onSelectResult = React.useCallback(
		(_: any, event: React.MouseEvent | React.KeyboardEvent) => {
			onNavigateClick(event);
		},
		[onNavigateClick],
	);

	return (
		<Drawer location="left" isModal onClose={onClose} zIndex={zIndex} className={styles["header__drawer"]}>
			<Drawer.Body className={styles["header__drawer__body"]}>
				<Column justify="spaced-start">
					<div style={{ marginBottom: "2rem" }}>
						{GlobalSearchBar && (
							<GlobalSearchBar onSelectResult={onSelectResult} className={styles["header__drawer__global-search"]} />
						)}
					</div>

					<nav className={styles["header__drawer__nav"]}>
						<Column justify="spaced-start">
							{links.map(([content, label, pageInfo]) => (
								<Tooltip.Container key={pageInfo.key} side="bottom">
									<NavLink
										to={pageInfo.to}
										exact={pageInfo.exact}
										onClick={onNavigateClick}
										className={styles["header__nav__link"]}
										activeClassName={styles["header__nav__link--location-active"]}
									>
										{content}
									</NavLink>

									<If condition={label !== null}>
										<Tooltip>
											<Tooltip.Body>{label}</Tooltip.Body>
										</Tooltip>
									</If>
								</Tooltip.Container>
							))}
						</Column>
					</nav>
				</Column>
			</Drawer.Body>
		</Drawer>
	);
}

function HeaderSideBar({ isOpen, onClose }: Runners.HeaderSideBarProps) {
	if (!isOpen) {
		return null;
	}

	onClose();

	return (
		<Drawer
			location="right"
			zIndex={1}
			className={styles["header__side-bar"]}
			wrapperClassName={styles["header__side-bar__wrapper"]}
		>
			<Drawer.Body> </Drawer.Body>
		</Drawer>
	);
}
