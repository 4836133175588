import { EnrollmentApplication, EnrollmentApplicationStatus } from "../../GraphQL";

export type Base = {
	status: EnrollmentApplication["status"] | null;
	externalLink: EnrollmentApplication["externalLink"];
};

export type Create = Base;

export type Detail = Base & {
	createdAt: EnrollmentApplication["createdAt"] | null;
};

export const initialCreate: Create = {
	status: null,
	externalLink: "",
};

export const statusOptions = [
	{ value: EnrollmentApplicationStatus.Approved },
	{ value: EnrollmentApplicationStatus.Pending },
	{ value: EnrollmentApplicationStatus.Rejected },
];
