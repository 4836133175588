import React from "react";
import { IndexForms } from "@hex-insights/app-modules";
import { StandardFiltersProps } from "@hex-insights/app-modules/dist/Features/IndexForms";
import { asArray, Button, Column, Grid, If, Row, toLocalDateString } from "@hex-insights/core";
import { FormState, MultiSelectField, RadioButtonsInput, RadioField } from "@hex-insights/forms";
import {
	CampusFilterInput,
	CampusOrderField,
	CampusSelect,
	CourseSelect,
	EmployeeSelect,
	HomeRoomFilterInput,
	HomeRoomOrderField,
	HomeRoomSectionFilterInput,
	HomeRoomSectionOrderField,
	HomeRoomSectionSelect,
	HomeRoomSelect,
	HomeRoomWithSectionsSelectQuery,
	OrderDirection,
	StudentFilterFormValues,
	useCampusSelectQuery,
	useCourseSelectQuery,
	useEmployeeSelectQuery,
	useHomeRoomSectionSelectQuery,
	useHomeRoomSelectQuery,
	useHomeRoomWithSectionsSelectQuery,
} from "../../../../Utilities";
import styles from "./styles.module.css";

export type StudentSearchFormProps = Pick<IndexForms.SearchFormProps, "formState">;

/**
 * Renders the search form of the Student model.
 */
export function StudentSearchForm(props: StudentSearchFormProps) {
	return <IndexForms.SearchForm {...props} />;
}

export type StudentFilterFormControllerProps = Pick<
	IndexForms.FilterFormControllerProps<string, StudentFilterFormValues.FormValues>,
	"formState" | "numActiveFilters"
>;

/**
 * Renders the filter form of the Student model.
 */
export function StudentFilterFormController(props: StudentFilterFormControllerProps) {
	return (
		<IndexForms.FilterFormController
			{...props}
			advancedFilterInformation={StudentFilterFormValues.advancedFilterInformation}
			StandardFilters={StandardFilters}
		/>
	);
}

const hasHomeRoomSectionStudentEnrollmentOptions = [
	{ label: "All", value: null },
	{ label: "Enrolled", value: true },
	{ label: "Not Enrolled", value: false },
];

const hasMealPlansOptions = [
	{ label: "All", value: null },
	{ label: "Meal Plan", value: true },
	{ label: "No Meal Plan", value: false },
];

const hasBusPlansOptions = [
	{ label: "All", value: null },
	{ label: "Bus Plan", value: true },
	{ label: "No Bus Plan", value: false },
];

function StandardFilters({ formState }: StandardFiltersProps<StudentFilterFormValues.FormValues>) {
	const { loading: loadingCampuses, data: campusData } = useCampusSelectQuery();
	const campusOptions = React.useMemo(() => CampusSelect.toOptions(campusData?.campusConnection.edges), [campusData]);

	const { loading: loadingHomeRooms, data: homeRoomData } = useHomeRoomSelectQuery();
	const homeRoomOptions = React.useMemo(
		() => HomeRoomSelect.toOptions(homeRoomData?.homeRoomConnection.edges),
		[homeRoomData],
	);

	const { loading: loadingHomeRoomSections, data: homeRoomSectionData } = useHomeRoomSectionSelectQuery();
	const homeRoomSectionOptions = React.useMemo(
		() => HomeRoomSectionSelect.toOptions(homeRoomSectionData?.homeRoomSectionConnection.edges),
		[homeRoomSectionData],
	);

	const { loading: loadingCourses, data: courseData } = useCourseSelectQuery();
	const courseOptions = React.useMemo(() => CourseSelect.toOptions(courseData?.courseConnection.edges), [courseData]);

	const { loading: loadingEmployees, data: employeeData } = useEmployeeSelectQuery();
	const employeeOptions = React.useMemo(
		() => EmployeeSelect.toOptions(employeeData?.employeeConnection.edges),
		[employeeData],
	);

	return (
		<Column justify="spaced-start">
			<MultiSelectField formState={formState} name="campusIDs" isLoading={loadingCampuses} options={campusOptions} />
			<RadioField
				formState={formState}
				name="hasHomeRoomSectionStudentEnrollments"
				label="Enrollment Status"
				options={hasHomeRoomSectionStudentEnrollmentOptions}
				Input={RadioButtonsInput}
			/>
			<MultiSelectField
				formState={formState}
				name="homeRoomIDs"
				isLoading={loadingHomeRooms}
				options={homeRoomOptions}
			/>
			<MultiSelectField
				formState={formState}
				name="homeRoomSectionIDs"
				isLoading={loadingHomeRoomSections}
				options={homeRoomSectionOptions}
			/>
			<MultiSelectField formState={formState} name="courseIDs" isLoading={loadingCourses} options={courseOptions} />
			<MultiSelectField
				formState={formState}
				name="teacherIDs"
				isLoading={loadingEmployees}
				options={employeeOptions}
			/>
			<RadioField
				formState={formState}
				name="hasMealPlans"
				label="Meal Plan"
				options={hasMealPlansOptions}
				blankValue={null}
				Input={RadioButtonsInput}
			/>
			<RadioField
				formState={formState}
				name="hasBusPlans"
				label="Bus Plan"
				options={hasBusPlansOptions}
				blankValue={null}
				Input={RadioButtonsInput}
			/>
		</Column>
	);
}

export type StudentCampusFilterButtonsProps = {
	filters?: CampusFilterInput | CampusFilterInput[];
	formState: FormState<Pick<StudentFilterFormValues.FormValues, "campusIDs">>;
};

export function StudentCampusFilterButtons({ filters, formState }: StudentCampusFilterButtonsProps) {
	const { data } = useCampusSelectQuery({
		variables: { filters, order: { field: CampusOrderField.Name, direction: OrderDirection.Asc } },
	});

	return (
		<React.Fragment>
			{data?.campusConnection.edges.map(({ node: campus }) => (
				<Button
					key={campus.id}
					variant={formState.formValues.campusIDs.includes(campus.id) ? "primary" : "tertiary"}
					onClick={() => {
						formState.formValues.campusIDs.includes(campus.id)
							? formState.formSetFunctions.campusIDs((prev) => prev.filter((e) => e !== campus.id))
							: formState.formSetFunctions.campusIDs((prev) => [...prev, campus.id]);
					}}
					className={styles["filter-button"] + " " + styles["filter-button--campus"]}
				>
					{campus.name}
				</Button>
			))}
		</React.Fragment>
	);
}

export type StudentHomeRoomFilterButtonsProps = {
	filters?: HomeRoomFilterInput | HomeRoomFilterInput[];
	sectionFilters?: HomeRoomSectionFilterInput | HomeRoomSectionFilterInput[];
	formState: FormState<Pick<StudentFilterFormValues.FormValues, "homeRoomIDs" | "homeRoomSectionIDs">>;
};

export function StudentHomeRoomFilterButtons({
	filters,
	sectionFilters,
	formState,
}: StudentHomeRoomFilterButtonsProps) {
	// TODO hex-core update clean up filter creation
	const fullFilters = React.useMemo<HomeRoomFilterInput | HomeRoomFilterInput[]>(() => {
		const today = toLocalDateString(new Date());
		if (!filters || (Array.isArray(filters) && filters.length === 0)) {
			return {
				homeRoomSections: [{ term: [{ startDateLTE: today, endDateGTE: today }] }],
			};
		}
		const filtersArray = asArray(filters);
		return filtersArray.map((filter) => ({
			...filter,
			homeRoomSections:
				(filter.homeRoomSections?.length ?? 0) > 0
					? filter.homeRoomSections!.map((hrs) => ({
							...hrs,
							term:
								(hrs.term?.length ?? 0) > 0
									? hrs.term!.map((t) => ({ ...t, startDateLTE: today, endDateGTE: today }))
									: [{ startDateLTE: today, endDateGTE: today }],
					  }))
					: [{ term: [{ startDateLTE: today, endDateGTE: today }] }],
		}));
	}, [filters]);

	const fullSectionFilters = React.useMemo<HomeRoomSectionFilterInput | HomeRoomSectionFilterInput[]>(() => {
		const today = toLocalDateString(new Date());
		if (!sectionFilters || (Array.isArray(sectionFilters) && sectionFilters.length === 0)) {
			return { term: [{ startDateLTE: today, endDateGTE: today }] };
		}
		const filtersArray = asArray(sectionFilters);
		return filtersArray.map((filter) => ({
			...filter,
			term:
				(filter.term?.length ?? 0) > 0
					? filter.term!.map((t) => ({ ...t, startDateLTE: today, endDateGTE: today }))
					: [{ startDateLTE: today, endDateGTE: today }],
		}));
	}, [sectionFilters]);

	const { data } = useHomeRoomWithSectionsSelectQuery({
		variables: {
			filters: fullFilters,
			order: { field: HomeRoomOrderField.Name, direction: OrderDirection.Asc },
			sectionFilters: fullSectionFilters,
			sectionOrder: { field: HomeRoomSectionOrderField.Name, direction: OrderDirection.Asc },
		},
	});

	return (
		<React.Fragment>
			{data?.homeRoomConnection.edges.map(({ node: homeRoom }) => (
				<StudentHomeRoomFilterButton key={homeRoom.id} homeRoom={homeRoom} formState={formState} />
			))}
		</React.Fragment>
	);
}

type StudentHomeRoomFilterButtonProps = {
	homeRoom: HomeRoomWithSectionsSelectQuery["homeRoomConnection"]["edges"][0]["node"];
	formState: FormState<Pick<StudentFilterFormValues.FormValues, "homeRoomIDs" | "homeRoomSectionIDs">>;
};

function StudentHomeRoomFilterButton({ homeRoom, formState }: StudentHomeRoomFilterButtonProps) {
	const isSelected = formState.formValues.homeRoomIDs.includes(homeRoom.id);

	const { homeRoomIDs: setHomeRoomIDs, homeRoomSectionIDs: setHomeRoomSectionIDs } = formState.formSetFunctions;
	const onClick = React.useCallback(() => {
		const homeRoomSectionIDs = homeRoom.homeRoomSections.map((e) => e.id);
		if (isSelected) {
			setHomeRoomIDs((prev) => prev.filter((e) => e !== homeRoom.id));
			setHomeRoomSectionIDs((prev) => prev.filter((e) => !homeRoomSectionIDs.includes(e)));
		} else {
			setHomeRoomIDs((prev) => [...prev, homeRoom.id]);
		}
	}, [isSelected, setHomeRoomIDs, setHomeRoomSectionIDs, homeRoom]);

	return (
		<Row key={homeRoom.id} justify="spaced-start" align="center" horizontalSpacing="0.2rem">
			<Button variant={isSelected ? "primary" : "tertiary"} onClick={onClick} className={styles["filter-button"]}>
				{homeRoom.name}
			</Button>

			<If condition={isSelected && homeRoom.homeRoomSections.length > 1}>
				<Grid gap="0.1rem" templateRows="repeat(2, 1fr)" style={{ gridAutoFlow: "column" }}>
					{homeRoom.homeRoomSections.map((e) => (
						<StudentHomeRoomSectionFilterButton key={e.id} homeRoomSection={e} formState={formState} />
					))}
				</Grid>
			</If>
		</Row>
	);
}

type StudentHomeRoomSectionFilterButtonProps = {
	homeRoomSection: HomeRoomWithSectionsSelectQuery["homeRoomConnection"]["edges"][0]["node"]["homeRoomSections"][0];
	formState: FormState<Pick<StudentFilterFormValues.FormValues, "homeRoomSectionIDs">>;
};

function StudentHomeRoomSectionFilterButton({ homeRoomSection, formState }: StudentHomeRoomSectionFilterButtonProps) {
	const isSelected = formState.formValues.homeRoomSectionIDs.includes(homeRoomSection.id);

	const { homeRoomSectionIDs: setHomeRoomSectionIDs } = formState.formSetFunctions;
	const onClick = React.useCallback(() => {
		if (isSelected) {
			setHomeRoomSectionIDs((prev) => prev.filter((e) => e !== homeRoomSection.id));
		} else {
			setHomeRoomSectionIDs((prev) => [...prev, homeRoomSection.id]);
		}
	}, [isSelected, setHomeRoomSectionIDs, homeRoomSection]);

	return (
		<Button
			variant={isSelected ? "primary" : "tertiary"}
			size="small"
			onClick={onClick}
			className={styles["filter-button"] + " " + styles["filter-button--home-room-section"]}
		>
			{homeRoomSection.name}
		</Button>
	);
}
