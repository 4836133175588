import { newPageInfo } from "@hex-insights/router";
import { reportCardsTermCourseSectionPageInfo, ReportCardsTermCourseSectionPageRouteParams } from "../pageinfo";

export type ReportCardsTermCourseSectionIndexPageRouteParams = ReportCardsTermCourseSectionPageRouteParams;

export const reportCardsTermCourseSectionIndexPageInfo = newPageInfo({
	name: "Report Cards Term Course Section Index Page",
	title: "",
	description: "Report cards term course section overview.",
	exact: true,
	to: reportCardsTermCourseSectionPageInfo.to,
	path: reportCardsTermCourseSectionPageInfo.path,
	parent: reportCardsTermCourseSectionPageInfo,
});
