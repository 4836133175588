import { NullPartial, omitKeys } from "@hex-insights/core";
import { BusPlanCreateInput, BusPlanDetailQuery, BusPlanUpdateInput } from "../../GraphQL";
import { BusPlanFormValues } from "../ModelFormValues";

/**
 * Converts the given `formValues` for the create form to a GraphQL create input for the BusPlan model.
 *
 * @param formValues The form values to convert.
 */
export function toGQLCreateInput(formValues: BusPlanFormValues.Create): BusPlanCreateInput {
	const inputFormValues = omitKeys(formValues, ["hasPickup", "hasDropoff"]);
	const input: NullPartial<BusPlanCreateInput> = { ...inputFormValues };
	return input as BusPlanCreateInput;
}

/**
 * Converts the given `formValues` for the detail form to a GraphQL update input for the BusPlan model.
 *
 * @param formValues The form values to convert.
 * @param initialFormValues The initial values of the form.
 */
export function toGQLUpdateInput(
	formValues: Partial<BusPlanFormValues.Detail>,
	_initialFormValues: BusPlanFormValues.Detail,
): BusPlanUpdateInput {
	const inputFormValues = omitKeys(formValues, ["hasPickup", "hasDropoff"]);
	const input: BusPlanUpdateInput = { ...inputFormValues };

	if (formValues.pickupTimeMinutes === null) {
		input.clearPickupTimeMinutes = true;
	}

	if (formValues.dropoffTimeMinutes === null) {
		input.clearDropoffTimeMinutes = true;
	}

	if (formValues.dropoffBusID === null) {
		input.dropoffBusID = null;
		input.clearDropoffBus = true;
	}

	if (formValues.pickupBusID === null) {
		input.pickupBusID = null;
		input.clearPickupBus = true;
	}

	return input;
}

/**
 * Converts a BusPlan instance to its corresponding detail form values.
 *
 * @param busPlan The instance to convert.
 */
export function toFormValues(busPlan: BusPlanDetailQuery["busPlan"]): BusPlanFormValues.Detail {
	return {
		hasPickup: busPlan.hasPickup,
		pickupTimeMinutes: busPlan.pickupTimeMinutes,
		pickupLocation: busPlan.pickupLocation,
		hasDropoff: busPlan.hasDropoff,
		dropoffTimeMinutes: busPlan.dropoffTimeMinutes,
		dropoffLocation: busPlan.dropoffLocation,
		dropoffBusID: busPlan.dropoffBus?.id ?? null,
		pickupBusID: busPlan.pickupBus?.id ?? null,
		studentID: busPlan.student?.id ?? null,
	};
}
