import React from "react";
import {
	addTimeToDate,
	Button,
	Column,
	Conditional,
	dateTrunc,
	Else,
	formatDateTime,
	Grid,
	Heading,
	Icon,
	If,
	Modal,
	Paragraph,
	Row,
	Section,
	stringToLocalDate,
	toLocalDateString,
	Tooltip,
} from "@hex-insights/core";
import {
	InternalLink,
	Route,
	RouteSwitch,
	useActivePageRegistration,
	useHistory,
	useRouteParams,
} from "@hex-insights/router";
import {
	CourseSection,
	CourseSectionFormat,
	CourseSectionPeriod,
	CourseSectionTeacherLessonPlanOverviewListQuery,
	getDateLessonPlanIDMap,
	getWeekDateRangeForCourseSection,
	LessonPlan,
	LessonPlanPreview,
	LessonPlansWeekCourseSectionPreviewModal,
	Main,
	PersonIcon,
	StaticURLs,
	Tile,
} from "@hex-insights/verita.shared";
import { useCurrentTeacherLessonPlanOverviewForDateQuery } from "../../../../Utilities";
import { lessonPlansWeekCourseSectionDayPageInfo } from "../CourseSection/Day/pageinfo";
import { lessonPlansWeekCourseSectionPageInfo } from "../CourseSection/pageinfo";
import { lessonPlansWeekIndexPageInfo, LessonPlansWeekIndexPageRouteParams } from "./pageinfo";
import styles from "./styles.module.css";

export function LessonPlansWeekIndexPage() {
	useActivePageRegistration(lessonPlansWeekIndexPageInfo);
	const { date: dateString } = useRouteParams<LessonPlansWeekIndexPageRouteParams>();
	const date = stringToLocalDate(dateString, "day");

	const { loading, data } = useCurrentTeacherLessonPlanOverviewForDateQuery(date);
	const startDate = toLocalDateString(addTimeToDate(dateTrunc(date, "week"), [1, "day"]));
	const firstNeededLessonPlanTo = React.useMemo(() => {
		if (!data) {
			return "";
		}
		for (let i = 0; i < data.courseSectionConnection.edges.length; i++) {
			const courseSection = data.courseSectionConnection.edges[i].node;
			const { dateRange } = getWeekDateRangeForCourseSection(courseSection.courseSectionPeriods);
			if (courseSection.lessonPlans.length === dateRange.length) {
				continue;
			}
			for (let j = 0; j < dateRange.length; j++) {
				const dateISO = dateRange[j];
				if (courseSection.lessonPlans.length < j + 1) {
					const dow = stringToLocalDate(dateISO, "day").getDay();
					return lessonPlansWeekCourseSectionDayPageInfo.to(startDate, courseSection.id, dow);
				}

				if (courseSection.lessonPlans[j].courseDate !== dateISO) {
					const dow = stringToLocalDate(dateISO, "day").getDay();
					return lessonPlansWeekCourseSectionDayPageInfo.to(startDate, courseSection.id, dow);
				}
			}
		}
		return "";
	}, [data, startDate]);

	const history = useHistory();

	return (
		<Main className={styles["lesson-plans-main"]}>
			<Column
				justify="space-between"
				style={{
					height: "calc(100vh - var(--header---height) - 2 * var(--main---padding-top))",
				}}
			>
				<Row justify="space-between" align="flex-start" style={{ padding: "1rem 1rem 0" }}>
					<Column justify="spaced-start">
						<Heading level={1} noMargin style={{ fontSize: "2.5rem", lineHeight: 1 }}>
							Create Lesson Plans
						</Heading>

						<div style={{ padding: "0 1rem" }}>
							<Button
								variant="primary"
								size="large"
								disabled={firstNeededLessonPlanTo === ""}
								onClick={() => history.push(firstNeededLessonPlanTo)}
								className={styles["get-started-button"]}
							>
								<Row justify="spaced-start" horizontalSpacing="0.25rem" align="center">
									Get Started <Icon.ArrowRight className={styles["get-started-button__icon"]} />
								</Row>
							</Button>
						</div>
					</Column>

					<WeekNavigation date={date} />

					<QuoteBox />
				</Row>

				<Section>
					<Section.Body className={styles["main-tile__body"]}>
						<Column style={{ height: "100%" }}>
							<Conditional>
								<If condition={loading}>Loading...</If>
								<Else>
									<Column justify="spaced-start" className={styles["lesson-plans-container"]}>
										<Grid columns={3} gap="1rem">
											{data?.courseSectionConnection.edges.map(({ node }) => (
												<CourseSectionLessonPlanItem key={node.id} courseSection={node} date={date} />
											))}
										</Grid>
									</Column>
								</Else>
							</Conditional>
						</Column>
					</Section.Body>
				</Section>

				<Row
					justify="flex-end"
					style={{
						backgroundColor: "var(--verita-blue)",
						padding: "1rem 2rem",
						borderRadius: "1rem",
						marginBottom: "2rem",
					}}
				>
					<Button
						size="large"
						disabled={firstNeededLessonPlanTo === ""}
						onClick={() => history.push(firstNeededLessonPlanTo)}
						className={styles["get-started-button"]}
						style={{
							color: "var(--verita-blue)",
							backgroundColor: "#fff",
						}}
					>
						<Row justify="spaced-start" horizontalSpacing="0.25rem" align="center">
							Get Started <Icon.ArrowRight className={styles["get-started-button__icon"]} />
						</Row>
					</Button>
				</Row>
			</Column>
		</Main>
	);
}

type WeekNavigationProps = {
	date: Date;
};

function WeekNavigation({ date }: WeekNavigationProps) {
	const currentWeekISO = toLocalDateString(new Date());

	return (
		<Row justify="center" style={{ flexGrow: 1, paddingTop: "0.7rem" }}>
			<Column justify="spaced-start">
				<Heading level={2} noMargin style={{ lineHeight: 1 }}>
					Week of {formatDateTime(date, "D MMMM YYYY")}
				</Heading>

				<Row justify="spaced-center">
					<InternalLink to={lessonPlansWeekIndexPageInfo.to(toLocalDateString(addTimeToDate(date, [-6, "days"])))}>
						<Row justify="spaced-start" horizontalSpacing="0.25rem" align="center">
							<Icon.ArrowLeft size="0.9rem" /> Previous
						</Row>
					</InternalLink>

					<RouteSwitch>
						<Route path={lessonPlansWeekIndexPageInfo.to(currentWeekISO)}></Route>
						<Route>
							<InternalLink to={lessonPlansWeekIndexPageInfo.to(currentWeekISO)}>
								<Row justify="spaced-start" horizontalSpacing="0.25rem" align="center">
									To Current Week
								</Row>
							</InternalLink>
						</Route>
					</RouteSwitch>

					<InternalLink to={lessonPlansWeekIndexPageInfo.to(toLocalDateString(addTimeToDate(date, [8, "days"])))}>
						<Row justify="spaced-start" horizontalSpacing="0.25rem" align="center">
							Next <Icon.ArrowRight size="0.9rem" />
						</Row>
					</InternalLink>
				</Row>
			</Column>
		</Row>
	);
}

function QuoteBox() {
	return (
		<Row justify="spaced-start" horizontalSpacing="0.5rem" align="flex-start">
			<PersonIcon
				person={{ name: "Leslie Wolper", imageURL: StaticURLs.Images.leslieWolper }}
				className={styles["leslie-icon"]}
			/>

			<Column align="center">
				<Paragraph style={{ fontSize: "1.25rem", marginBottom: "0.5rem" }}>
					"We plan&nbsp;today so we
					<br />
					can succeed&nbsp;tomorrow."
				</Paragraph>
				<span style={{ fontWeight: "bold" }}>Leslie Wolper</span>
				<span style={{ fontSize: "0.8rem", color: "#888" }}>Director of Education</span>
			</Column>
		</Row>
	);
}

type CourseSectionLessonPlanItemProps = {
	courseSection: CourseSectionTeacherLessonPlanOverviewListQuery["courseSectionConnection"]["edges"][0]["node"];
	date: Date;
};

function CourseSectionLessonPlanItem({ courseSection, date }: CourseSectionLessonPlanItemProps) {
	const dateLessonPlanIDMap = React.useMemo(
		() => getDateLessonPlanIDMap(courseSection.lessonPlans),
		[courseSection.lessonPlans],
	);

	const { startDate, dateRange } = getWeekDateRangeForCourseSection(courseSection.courseSectionPeriods, date);

	return (
		<Tile className={styles["lesson-plan-item"]} style={{ width: "100%", minWidth: 0, boxShadow: "none" }}>
			<Tile.Header style={{ width: "100%", paddingTop: "0.25rem" }}>
				<Row justify="spaced-start" align="center" style={{ width: "100%" }}>
					<LessonPlansPreviewer
						courseSectionName={CourseSectionFormat.name(courseSection)}
						date={date}
						dates={dateRange}
						dateLessonPlanIDMap={dateLessonPlanIDMap}
					/>
				</Row>
			</Tile.Header>

			<Tile.Body style={{ paddingTop: 0, paddingBottom: 0 }}>
				<Row justify="center">
					<CourseSectionLessonPlanWeek
						courseSectionName={courseSection.name}
						courseSectionID={courseSection.id}
						periods={courseSection.courseSectionPeriods}
						dateLessonPlanIDMap={dateLessonPlanIDMap}
						date={date}
					/>
				</Row>
			</Tile.Body>

			<Tile.Footer style={{ padding: "0 1.5rem 0.5rem" }}>
				<Row justify="center">
					<InternalLink
						to={lessonPlansWeekCourseSectionPageInfo.to(startDate, courseSection.id)}
						style={{ fontSize: "0.9rem" }}
					>
						View / Edit
					</InternalLink>
				</Row>
			</Tile.Footer>
		</Tile>
	);
}

type CourseSectionLessonPlanWeekProps = {
	courseSectionID: CourseSection["id"];
	courseSectionName: CourseSection["name"];
	periods: Pick<CourseSectionPeriod, "dayOfWeek">[];
	dateLessonPlanIDMap: Record<string, LessonPlan["id"] | null>;
	date: Date;
};

function CourseSectionLessonPlanWeek({
	courseSectionID,
	courseSectionName,
	periods,
	dateLessonPlanIDMap,
	date,
}: CourseSectionLessonPlanWeekProps) {
	const { dateRange } = getWeekDateRangeForCourseSection(periods, date);

	return (
		<Row justify="spaced-start" horizontalSpacing="0.25rem" style={{ padding: "0.5rem 1rem" }}>
			{dateRange.map((e) => (
				<LessonPlanDayIcon
					key={e}
					courseSectionID={courseSectionID}
					courseSectionName={courseSectionName}
					date={e}
					lessonPlanID={dateLessonPlanIDMap[e]}
				/>
			))}
		</Row>
	);
}

type LessonPlanDayIconProps = {
	courseSectionID: CourseSection["id"];
	courseSectionName: CourseSection["name"];
	date: string;
	lessonPlanID?: LessonPlan["id"] | null;
};

function LessonPlanDayIcon({ courseSectionID, courseSectionName, date, lessonPlanID }: LessonPlanDayIconProps) {
	const hasLessonPlan = !!lessonPlanID;

	const lessonPlanTo = lessonPlansWeekCourseSectionDayPageInfo.to(
		date,
		courseSectionID,
		stringToLocalDate(date, "day").getDay(),
	);

	const { isModalOpen, toggleIsModalOpen } = Modal.useToggle();
	const history = useHistory();
	const onClick = React.useCallback(() => {
		if (hasLessonPlan) {
			toggleIsModalOpen();
		} else {
			history.push(lessonPlanTo);
		}
	}, [hasLessonPlan, toggleIsModalOpen, history, lessonPlanTo]);

	return (
		<React.Fragment>
			<Tooltip.Container style={{ padding: "0 0.25rem 0.25rem" }}>
				<Tooltip>
					<Tooltip.Body>
						<Column>
							<span style={{ fontSize: "1.1rem", whiteSpace: "nowrap" }}>
								{formatDateTime(stringToLocalDate(date, "day"), "dddd, D MMMM")}
							</span>
							<Paragraph style={{ whiteSpace: "nowrap" }}>
								{hasLessonPlan ? "Lesson plan created." : "No lesson plan created."}
							</Paragraph>
						</Column>
					</Tooltip.Body>
				</Tooltip>

				<div style={{ position: "relative" }}>
					<Button
						onClick={onClick}
						className={styles["lesson-plan__day-icon"]}
						style={{
							backgroundColor: hasLessonPlan ? "#0063ad40" : undefined,
						}}
					>
						<span className={styles["lesson-plan__day-icon__text"]}>
							{formatDateTime(stringToLocalDate(date, "day"), "dd").charAt(0)}
						</span>
					</Button>

					<If condition={hasLessonPlan}>
						<div className={styles["check-icon"] + " " + styles["lesson-plan__day-icon__check-icon"]}>
							<Icon.Check className={styles["check-icon__icon"]} size="0.75rem" />
						</div>
					</If>
				</div>
			</Tooltip.Container>

			<Modal.If condition={isModalOpen}>
				<Modal onClose={toggleIsModalOpen}>
					<Modal.Header>
						<Heading level={2} noMargin>
							{courseSectionName}: {formatDateTime(date, "dddd, D MMM YYYY")}
						</Heading>
					</Modal.Header>
					<Modal.Body style={{ width: "100%", overflow: "scroll" }}>
						<Row justify="spaced-start" className={styles["lesson-plans-previewer__items-row"]}>
							<LessonPlanPreview date={date} lessonPlanID={lessonPlanID ?? null} />
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Row justify="flex-end">
							<InternalLink to={lessonPlanTo}>
								<Row justify="spaced-start" horizontalSpacing="0.25rem" align="center">
									Go to Lesson Plan <Icon.ArrowRight size="0.9rem" />
								</Row>
							</InternalLink>
						</Row>
					</Modal.Footer>
				</Modal>
			</Modal.If>
		</React.Fragment>
	);
}

type LessonPlansPreviewerProps = {
	courseSectionName: string;
	date: Date;
	dates: string[];
	dateLessonPlanIDMap: Record<string, LessonPlan["id"] | null>;
};

function LessonPlansPreviewer({ courseSectionName, date, dates, dateLessonPlanIDMap }: LessonPlansPreviewerProps) {
	const { isModalOpen, toggleIsModalOpen } = Modal.useToggle(false);

	const hasAllLessonPlans = dates.length > 0 && dates.every((e) => dateLessonPlanIDMap[e]);

	return (
		<React.Fragment>
			<Button
				onClick={toggleIsModalOpen}
				style={{ fontSize: "0.9rem", width: "100%", padding: 0, border: "none", overflow: "hidden" }}
			>
				<Heading
					level={2}
					noMargin
					style={{
						whiteSpace: "nowrap",
						textOverflow: "ellipsis",
						maxWidth: "100%",
						overflow: "hidden",
					}}
				>
					<If condition={hasAllLessonPlans}>
						<div
							className={styles["check-icon"]}
							style={
								{
									"--size": "1.25rem",
									marginRight: "0.25rem",
									display: "inline-block",
								} as React.CSSProperties
							}
						>
							<Icon.Check className={styles["check-icon__icon"]} size="1rem" />
						</div>
					</If>
					{courseSectionName}
				</Heading>
			</Button>

			<Modal.If condition={isModalOpen}>
				<LessonPlansWeekCourseSectionPreviewModal
					courseSectionName={courseSectionName}
					date={date}
					dates={dates}
					dateLessonPlanIDMap={dateLessonPlanIDMap}
					onClose={toggleIsModalOpen}
				/>
			</Modal.If>
		</React.Fragment>
	);
}
