import { IndexForms } from "@hex-insights/app-modules";
import { CaseStyle, convertCase } from "@hex-insights/core";
import { OrderDirection, ServiceSubscriptionOrderField } from "../../GraphQL";

export type FormValues = IndexForms.OrderFormValues<ServiceSubscriptionOrderField | null>;

export const initial: FormValues = {
	field: ServiceSubscriptionOrderField.Price,
	direction: OrderDirection.Asc,
};

/**
 * Options for the "field" field in the order form of the ServiceSubscription model.
 */
export const fieldOptions = [
	{ label: "ID", value: null },
	{
		label: convertCase(ServiceSubscriptionOrderField.Price.toLowerCase(), CaseStyle.Title),
		value: ServiceSubscriptionOrderField.Price,
	},
];

/**
 * Array of values for the "field" field in the order form of the ServiceSubscription model.
 */
export const fieldValues = fieldOptions.map((e) => e.value);

/**
 * Mapping from order fields of the ServiceSubscription model to their corresponding order type.
 */
export const fieldTypeMapping = {
	[ServiceSubscriptionOrderField.Price]: IndexForms.OrderFieldType.Number,
};

/**
 * Mapping from order fields of the ServiceSubscription model to their corresponding field name.
 */
export const orderFieldToModelField = {
	[ServiceSubscriptionOrderField.Price]: "price",
};

/**
 * Mapping from field names of the ServiceSubscription model to their corresponding order field.
 */
export const modelFieldToOrderField = {
	price: ServiceSubscriptionOrderField.Price,
};
