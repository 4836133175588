import { CourseSection, CourseSectionNameFragment } from "../../../GraphQL";
import * as CourseFormat from "../Course";
import * as HomeRoomSectionFormat from "../HomeRoomSection";
import * as Fields from "./fields";

export { Fields };

/**
 * Formats the full name of the given instance.
 */
export function name(instance: CourseSectionNameFragment) {
	if (CourseFormat.specialtySubjects.has(instance.course.subject)) {
		return nameWithHomeRoomName(instance);
	}
	return Fields.name(instance.name);
}

export function nameWithHomeRoomName(instance: CourseSectionNameFragment) {
	return `${Fields.name(instance.name)} (${HomeRoomSectionFormat.name(instance.homeRoomSection)})`;
}

export function color(instance: { course: Pick<CourseSection["course"], "subject"> }) {
	return CourseFormat.color(instance.course);
}
