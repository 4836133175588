import { dateToDateMapKey } from "./date-ranges";
import { LessonPlan } from "./GraphQL";

export function getDateLessonPlanIDMap(lessonPlans: Pick<LessonPlan, "id" | "courseDate">[]) {
	const dateLessonPlanIDMap = {};
	for (let i = 0; i < lessonPlans.length; i++) {
		const lessonPlan = lessonPlans[i];
		dateLessonPlanIDMap[dateToDateMapKey(lessonPlan.courseDate)] = lessonPlan.id;
	}
	return dateLessonPlanIDMap;
}
