import { IndexForms } from "@hex-insights/app-modules";
import { CaseStyle, convertCase } from "@hex-insights/core";
import { BusOrderField, OrderDirection } from "../../GraphQL";

export type FormValues = IndexForms.OrderFormValues<BusOrderField | null>;

export const initial: FormValues = {
	field: BusOrderField.Name,
	direction: OrderDirection.Asc,
};

/**
 * Options for the "field" field in the order form of the Bus model.
 */
export const fieldOptions = [
	{ label: "ID", value: null },
	{ label: convertCase(BusOrderField.Name.toLowerCase(), CaseStyle.Title), value: BusOrderField.Name },
];

/**
 * Array of values for the "field" field in the order form of the Bus model.
 */
export const fieldValues = fieldOptions.map((e) => e.value);

/**
 * Mapping from order fields of the Bus model to their corresponding order type.
 */
export const fieldTypeMapping = {
	[BusOrderField.Name]: IndexForms.OrderFieldType.String,
};

/**
 * Mapping from order fields of the Bus model to their corresponding field name.
 */
export const orderFieldToModelField = {
	[BusOrderField.Name]: "name",
};

/**
 * Mapping from field names of the Bus model to their corresponding order field.
 */
export const modelFieldToOrderField = {
	name: BusOrderField.Name,
};
